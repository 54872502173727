import { Button } from "@libs/components/UI/Button";

export const MfaSetupComplete: React.FC<{
  onNext: Func;
}> = ({ onNext }) => {
  return (
    <div className="flex flex-col gap-8 items-center">
      <div className="text-xs text-center">
        You&apos;ll need to use your authenticator app any time you are asked for the verification code during
        log in.
      </div>
      <Button onClick={onNext} className="w-fit min-w-button">
        Next
      </Button>
    </div>
  );
};
