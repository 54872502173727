import { FC, FormEvent, useState } from "react";
import { WorktimeVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import {
  nowInTimezone,
  formatAsISOTime,
  formatAsISODate,
  addMinutesToISOTime,
  getLocalDate,
} from "@libs/utils/date";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { addWorktimeEntry } from "api/employee/mutations";
import { EditWorktimeModal } from "components/EmployeeProfile/Timesheet/EditWorktimeModal";
import { handleError } from "utils/handleError";
import { WorktimeComment } from "components/EmployeeProfile/Timesheet/WorktimeComment";

import { TimeInput } from "components/UI/TimeInput";
import { commentValidation, isoTimeValidation } from "components/EmployeeProfile/Timesheet/utils";

export interface AddClockInModalProps {
  employeeId: number;
  date: string;
  lastClockOut?: WorktimeVO;
  onRequestClose: Func;
}

const schema = {
  comment: [commentValidation],
  time: [isoTimeValidation],
};

export const AddClockInModal: FC<AddClockInModalProps> = ({
  date,
  lastClockOut,
  employeeId,
  onRequestClose,
}) => {
  const practice = useCurrentPractice();
  const [comment, setComment] = useState("");
  const [time, setTime] = useState(() => {
    const now = nowInTimezone(practice.timezoneId);

    if (formatAsISODate(now) === date) {
      return formatAsISOTime(now);
    }

    return lastClockOut?.time
      ? addMinutesToISOTime(lastClockOut.time, 1)
      : formatAsISOTime(getLocalDate(date, "08:00:00"));
  });
  const [addWorktimeEntryMutation] = useApiMutations([addWorktimeEntry]);
  const { result, validate } = useValidation({ comment, time }, schema);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validate().$isValid) {
      addWorktimeEntryMutation.mutate(
        {
          practiceId: practice.id,
          employeeId,
          data: {
            date,
            action: "CLOCK_IN",
            time,
            notes: comment,
          },
        },
        {
          onSuccess: onRequestClose,
          onError: handleError,
        }
      );
    }
  };

  return (
    <EditWorktimeModal
      formId="add-clock-in-form"
      onSubmit={handleSubmit}
      isLoading={addWorktimeEntryMutation.isLoading}
      onRequestClose={onRequestClose}
      date={date}
    >
      <TimeInput
        required={true}
        label="Add Clock-In"
        value={time}
        onChange={setTime}
        error={result.time.$error}
      />
      <WorktimeComment
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        error={result.comment.$error}
      />
    </EditWorktimeModal>
  );
};
