import { ChangeEvent, FC, Fragment, useMemo } from "react";
import { PatientProcedureVO, TreatmentPlanVO } from "@libs/api/generated-api";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { NO_PRIORITY, useRowTotals } from "components/Charting/useRowTotals";
import {
  ProceduresHeaderCell,
  ProceduresTableGrid,
  ProceduresTableRow,
  SubTotalRow,
  TotalRow,
} from "components/Charting/ProceduresTableComponents";
import { useNow } from "hooks/useNow";
import {
  CDTCodeCell,
  CdtCodeColumn,
  CheckColumn,
  ApptDateCell,
  DateColumn,
  DescriptionCell,
  DescriptionColumn,
  EditColumn,
  InsAmtColumn,
  InsuranceAmountCell,
  NegFeesColumn,
  NegotiatedAmountCell,
  PatientAmountCell,
  PreAuthColumn,
  PreAuthStatusCell,
  PriorityCell,
  PriorityColumn,
  ProcedureAreaColumn,
  ProviderCell,
  ProviderColumn,
  PtAmtColumn,
  ToothSurfaceCell,
  CreatedDateColumn,
  CreatedDateCell,
  TreatmentTypeCell,
  TreatmentTypeColumn,
  ActionsCell,
  LabCaseStatusCell,
  LabCaseStatusColumn,
  DecuctionsColumn,
  DeductibleStatusCell,
} from "components/Charting/ProcedureTableCells";
import { useLabCaseStatus } from "components/LabCases/hooks/useLabCaseStatus";
import { useItemModal } from "hooks/useItemModal";
import { handleError } from "utils/handleError";
import { LabCaseFlyover } from "components/LabCases/LabCaseFlyover";

export const ProceduresTableAlternate: FC<{
  patientId: number;
  treatmentPlan: TreatmentPlanVO;
  selectedRows: Set<number>;
  queryKey: unknown;
  isFiltered: boolean;
  onToggleRow: (e: ChangeEvent<HTMLInputElement>) => void;
  onPriorityChanged: (patientProcedure: PatientProcedureVO, value: number) => void;
  onSendPreAuthClick: (patientProcedure: PatientProcedureVO) => void;
  onSelectAllRows: Func;
  onDeselectAllRows: Func;
  onEditClick: (patientProcedure: PatientProcedureVO) => void;
  onEditEstimatesClick: (patientProcedure: PatientProcedureVO) => void;
  editProcedureId?: number;
}> = ({
  patientId,
  queryKey,
  treatmentPlan,
  selectedRows,
  isFiltered,
  onToggleRow,
  onEditClick,
  onEditEstimatesClick,
  onSendPreAuthClick,
  onPriorityChanged,
  onSelectAllRows,
  onDeselectAllRows,
  editProcedureId,
}) => {
  const now = useNow();
  const { subtotalRows, totalRow } = useRowTotals(treatmentPlan.patientProcedures);
  const { labCaseReturnReasons, onLabCaseStatusChange } = useLabCaseStatus();
  const labCaseFlyover = useItemModal<PatientProcedureVO>(null);

  const columns = useMemo(
    () => [
      CheckColumn,
      PriorityColumn,
      TreatmentTypeColumn,
      CreatedDateColumn,
      DateColumn,
      ProcedureAreaColumn,
      CdtCodeColumn,
      DescriptionColumn,
      ProviderColumn,
      NegFeesColumn,
      InsAmtColumn,
      PtAmtColumn,
      DecuctionsColumn,
      PreAuthColumn,
      LabCaseStatusColumn,
      EditColumn,
    ],
    []
  );
  const emptyMessage =
    treatmentPlan.patientProcedures.length === 0
      ? isFiltered
        ? "No procedures match the current filter."
        : "No procedures have been added to this treatment plan."
      : "";

  return (
    <PersistScrollPosition
      id="treatment-plan-scroll-container"
      resetScrollKey={queryKey}
      className="h-full overflow-y-auto"
    >
      <ProceduresTableGrid
        columns={columns}
        emptyMessage={emptyMessage}
        isEmpty={treatmentPlan.patientProcedures.length === 0}
      >
        {columns.map((col) => (
          <ProceduresHeaderCell
            key={col.id}
            column={col}
            totalRows={treatmentPlan.patientProcedures.length}
            selectedRows={selectedRows}
            onSelectAllRows={onSelectAllRows}
            onDeselectAllRows={onDeselectAllRows}
          />
        ))}
        {treatmentPlan.patientProcedures.map((procedure) => (
          <Fragment key={procedure.id}>
            <ProceduresTableRow
              now={now}
              selectedRows={selectedRows}
              procedure={procedure}
              onToggleRow={onToggleRow}
              isEditing={procedure.id === editProcedureId}
            >
              <PriorityCell
                onClick={onEditClick}
                procedure={procedure}
                onPriorityChanged={onPriorityChanged}
              />
              <TreatmentTypeCell onClick={onEditClick} procedure={procedure} />
              <CreatedDateCell onClick={onEditClick} procedure={procedure} />
              <ApptDateCell onClick={onEditClick} procedure={procedure} />
              <ToothSurfaceCell onClick={onEditClick} procedure={procedure} />
              <CDTCodeCell now={now} onClick={onEditClick} procedure={procedure} />
              <DescriptionCell onClick={onEditClick} procedure={procedure} />
              <ProviderCell onClick={onEditClick} procedure={procedure} />
              <NegotiatedAmountCell onClick={onEditClick} procedure={procedure} />
              <InsuranceAmountCell onClick={onEditClick} procedure={procedure} />
              <PatientAmountCell onClick={onEditClick} procedure={procedure} />
              <DeductibleStatusCell procedure={procedure} onClick={onEditClick} />
              <PreAuthStatusCell procedure={procedure} onClick={onEditClick} />
              <LabCaseStatusCell
                labCaseReturnReasons={labCaseReturnReasons}
                procedure={procedure}
                onClick={labCaseFlyover.open}
                onStatusChange={(...args) => {
                  procedure.labCaseStatus?.uuid &&
                    onLabCaseStatusChange(procedure.labCaseStatus.uuid, ...args).catch(handleError);
                }}
              />
              <ActionsCell
                procedure={procedure}
                onSendPreAuthClick={onSendPreAuthClick}
                onEditProcedureClick={onEditClick}
                onEditEstimatesClick={onEditEstimatesClick}
                onEditLabCaseClick={procedure.labCaseStatus ? labCaseFlyover.open : undefined}
              />
            </ProceduresTableRow>
            {subtotalRows?.[procedure.priority || NO_PRIORITY]?.lastId === procedure.id ? (
              <SubTotalRow
                isLast={!procedure.priority}
                label={`Subtotal (${procedure.priority ? `P${procedure.priority}` : "Others"})`}
                row={subtotalRows[procedure.priority || NO_PRIORITY]}
              />
            ) : null}
          </Fragment>
        ))}
        {totalRow ? <TotalRow label="Total" row={totalRow} /> : null}
      </ProceduresTableGrid>
      {labCaseFlyover.isOpen ? (
        <LabCaseFlyover
          labCaseUuid={labCaseFlyover.item.labCaseStatus?.uuid}
          procedureId={labCaseFlyover.item.id}
          patientId={patientId}
          onClose={labCaseFlyover.close}
          onSave={labCaseFlyover.close}
        />
      ) : null}
    </PersistScrollPosition>
  );
};
