/* eslint-disable @typescript-eslint/naming-convention */
import React from "react";
import { Checkbox } from "@libs/components/UI/Checkbox";

type Props = {
  isDefaultCard: boolean;
  onSetIsDefaultCard: (isDefaultCard: boolean) => void;
  isFsaCard: boolean;
  onSetIsFsaCard: (isFsaCard: boolean) => void;
};

export const NewCardFormOptions: React.FC<Props> = ({
  isDefaultCard,
  onSetIsDefaultCard,
  isFsaCard,
  onSetIsFsaCard,
}) => {
  return (
    <div className="flex">
      <Checkbox
        checked={isDefaultCard}
        disabled={isFsaCard}
        onChange={(e) => onSetIsDefaultCard(e.target.checked)}
      >
        Save as default payment method
      </Checkbox>
      <Checkbox
        className="ml-5"
        checked={isFsaCard}
        disabled={isDefaultCard}
        onChange={(e) => onSetIsFsaCard(e.target.checked)}
      >
        FSA card
      </Checkbox>
    </div>
  );
};
