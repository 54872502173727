import { FC, PropsWithChildren } from "react";
import { cx } from "@libs/utils/cx";
import { pluralize } from "@libs/utils/pluralize";
import { useBoolean } from "@libs/hooks/useBoolean";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as InfoSmallIcon } from "@libs/assets/icons/info-small.svg";
import { CollapsibleSection } from "components/UI/CollapsibleSection";
import { ToggleGroup } from "components/UI/ToggleGroup";
import { TimeLimitModal } from "components/Roles/TimeLimitModal";

export const PermissionList: FC<PropsWithChildren> = ({ children }) => (
  <div
    className={`
      flex
      flex-col
      ring-1
      ring-inset
      ring-slate-200
      first:rounded-t
      last:rounded-b
      divide-y
    `}
  >
    {children}
  </div>
);

const PermissionItemContainer: FC<PropsWithChildren<{ isDomainPermission: boolean }>> = ({
  isDomainPermission,
  children,
}) => (
  <div
    className={cx(
      "flex justify-between gap-x-2 items-center px-3",
      isDomainPermission ? "py-3" : "first:pb-3"
    )}
  >
    {children}
  </div>
);

export const SubPermissionItem: FC<{
  label: string;
  tooltip: string | undefined;
  isOn: boolean;
  withTimeLimit: boolean;
  disabled: boolean;
  timeLimitValue?: number;
  onToggle: (value: boolean) => void;
  onTimeLimitChange?: (value: number | undefined) => void;
}> = ({ label, tooltip, isOn, withTimeLimit, timeLimitValue, disabled, onToggle, onTimeLimitChange }) => {
  const timeLimitModal = useBoolean(false);

  return (
    <div>
      <PermissionItemContainer isDomainPermission={false}>
        <div className="flex items-center gap-x-1 text-xs">
          {label}
          {isOn && withTimeLimit && (
            <button type="button" className="flex items-center gap-x-1" onClick={timeLimitModal.on}>
              <span className="border-b border-dashed border-archyBlue-500">
                {timeLimitValue
                  ? `Within ${timeLimitValue} ${pluralize(timeLimitValue, "day", "days")}`
                  : "Any Time"}
              </span>
              <Icon SvgIcon={EditIcon} size="sm" theme="primary" />
            </button>
          )}
          {tooltip != null && <Icon SvgIcon={InfoSmallIcon} tooltip={{ content: tooltip }} />}
        </div>
        <PermissionOnOffToggle disabled={disabled} isOn={isOn} onToggle={onToggle} />
      </PermissionItemContainer>
      {timeLimitModal.isOn && (
        <TimeLimitModal
          value={timeLimitValue}
          onClose={timeLimitModal.off}
          onSave={(daysLimit) => {
            onTimeLimitChange?.(daysLimit);
            timeLimitModal.off();
          }}
        />
      )}
    </div>
  );
};

export const DomainPermissionItem: FC<
  PropsWithChildren<{
    label: string;
    description: string;
    isOn: boolean;
    disabled: boolean;
    tooltip?: string;
    onToggle: (value: boolean) => void;
  }>
> = ({ label: title, description, isOn, disabled, tooltip, children, onToggle }) => (
  <div>
    <PermissionItemContainer isDomainPermission={true}>
      <div className="flex flex-col gap-y-1">
        <div className="text-xs font-sansSemiBold">{title}</div>
        <div className="text-xs">{description}</div>
      </div>
      <PermissionOnOffToggle disabled={disabled} isOn={isOn} tooltip={tooltip} onToggle={onToggle} />
    </PermissionItemContainer>
    <CollapsibleSection isOpen={isOn}>{children}</CollapsibleSection>
  </div>
);

const PermissionOnOffToggle: FC<{
  isOn: boolean;
  disabled?: boolean;
  tooltip?: string;
  onToggle: (isOn: boolean) => void;
}> = ({ isOn, disabled, tooltip, onToggle }) => (
  <FloatingTooltip content={tooltip}>
    <div>
      <ToggleGroup
        size="md"
        selectedValue={isOn}
        disabled={disabled}
        options={[
          { label: "Off", value: false },
          { label: "On", value: true },
        ]}
        onChange={(e, option) => {
          onToggle(option.value);
        }}
      />
    </div>
  </FloatingTooltip>
);
