import { FC, PropsWithChildren, useCallback, useMemo } from "react";
import { useNavigate, Link } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { formatCurrency } from "@libs/utils/currency";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useFullUrl } from "@libs/utils/location";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Icon } from "@libs/components/UI/Icon";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as DiscountIcon } from "@libs/assets/icons/discount.svg";
import { ReactComponent as ShieldIcon } from "@libs/assets/icons/shield.svg";
import { ReactComponent as PeopleIcon } from "@libs/assets/icons/people.svg";
import { ReactComponent as CreditCardsMultipleIcon } from "@libs/assets/icons/credit-cards-multiple.svg";
import { ReactComponent as WalletIcon } from "@libs/assets/icons/wallet.svg";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { ReactComponent as UserIcon } from "@libs/assets/icons/user.svg";
import { ReactComponent as RequestMoneyIcon } from "@libs/assets/icons/request-money.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { QueryResult } from "@libs/components/UI/QueryResult";
import {
  FlyoverPanel,
  PatientPaymentMethodsFlyoverReel,
} from "components/PatientProfile/Billing/PatientPaymentMethodsFlyoverReel";
import { paths } from "utils/routing/paths";
import { ToggleCaret } from "components/UI/ToggleCaret";
import { CollapsibleSection } from "components/UI/CollapsibleSection";
import { Avatar } from "components/UI/Avatar";
import { getLedgerBalanceSummary, getPaymentProfilesByPatientId, getWallets } from "api/billing/queries";
import { getFamilyMembersV2Query } from "api/patients/queries";
import { getFallbackPatientWallet } from "components/PatientProfile/Billing/billingUtils";
import { BalanceBox } from "components/PatientProfile/Billing/BalanceBox";
import { fixFamilyMemberFullDisplayName, getPatientDisplayName } from "utils/names";

import { CollectionMode } from "utils/routing/patient";
import { RoleGuardClick } from "components/Main/RoleGuard";
import { useItemModal } from "hooks/useItemModal";

const cxStyles = {
  overviewContainer: `
    flex-none
    h-full
    flex
    flex-col
    bg-slate-50
    overflow-y-auto
    sticky
    shadow-[-2px_0px_2px_0px_rgba(51,65,85,0.08)]
    w-64
  `,
};

// eslint-disable-next-line complexity
export const PatientBalances: FC<{ patientId: number }> = ({ patientId }) => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const fullUrl = useFullUrl();
  const flyoverReelInitialPanel = useItemModal<FlyoverPanel>(null);

  const [paymentProfilesQuery, familyMembersQuery, patientBalanceQuery, patientWalletsQuery] = useApiQueries([
    getPaymentProfilesByPatientId({ args: { patientId, practiceId } }),
    getFamilyMembersV2Query({ args: { practiceId, patientId } }),
    getLedgerBalanceSummary({ args: { patientId, practiceId } }),
    getWallets({ args: { patientId, practiceId } }),
  ]);

  const wallet = getFallbackPatientWallet(patientId, practiceId, patientWalletsQuery.data);

  const totalPatientBalance = patientBalanceQuery.data?.entityBalance ?? 0;
  const insuranceBalance = patientBalanceQuery.data?.insuranceBalance ?? 0;
  const discountBalance = patientBalanceQuery.data?.totalDiscountAmount ?? 0;
  const totalFamilyBalance = useMemo(
    () =>
      familyMembersQuery.data?.linkedFamilyMembers.reduce((prev, curr) => prev + curr.balanceAmount, 0) ?? 0,
    [familyMembersQuery.data?.linkedFamilyMembers]
  );

  // Keep current patient at the top of the list of family members
  const sortedFamilyMembers = useMemo(
    () =>
      (familyMembersQuery.data?.linkedFamilyMembers ?? []).sort((fm) => (fm.relation === "SELF" ? -1 : 0)),
    [familyMembersQuery.data?.linkedFamilyMembers]
  );

  const expandFamily = useBoolean(false);
  const hasFamilyMembers = sortedFamilyMembers.length > 1;

  const handleCollectPaymentClick = useCallback(
    (mode: CollectionMode) => {
      navigate(paths.collectPayment({ patientId }, { from: fullUrl, mode }));
    },
    [fullUrl, navigate, patientId]
  );

  return (
    <div className={cxStyles.overviewContainer}>
      <QueryResult
        queries={[paymentProfilesQuery, familyMembersQuery, patientBalanceQuery, patientWalletsQuery]}
      >
        <BalanceContainer>
          <BalanceBox
            leftIcon={
              <RoleGuardCollectClick>
                <FloatingTooltip content="Collect" theme="SMALL">
                  <div>
                    <CollectButton onClick={() => handleCollectPaymentClick("individual")} />
                  </div>
                </FloatingTooltip>
              </RoleGuardCollectClick>
            }
            amount={
              <div className={cx(totalPatientBalance < 0 && "text-redDark")}>
                {formatCurrency(totalPatientBalance)}
              </div>
            }
            label={
              <div className="flex items-center gap-x-1">
                <Icon SvgIcon={UserIcon} size="sm" /> Patient Balance
              </div>
            }
            theme="LARGE"
          />
          <CollapsibleSection adjustVerticalGap isOpen={hasFamilyMembers}>
            <BalanceBox
              leftIcon={
                <RoleGuardCollectClick>
                  <FloatingTooltip content="Collect" theme="SMALL">
                    <div>
                      <CollectButton onClick={() => handleCollectPaymentClick("family")} />
                    </div>
                  </FloatingTooltip>
                </RoleGuardCollectClick>
              }
              amount={formatCurrency(totalFamilyBalance)}
              label={
                <div className="flex items-center gap-x-1">
                  <Icon SvgIcon={PeopleIcon} size="sm" /> Family Balance
                </div>
              }
              theme="LARGE"
              rightIcon={<ToggleCaret initialState="closed" size="md" onToggle={expandFamily.toggle} />}
            />
          </CollapsibleSection>
          <CollapsibleSection adjustVerticalGap isOpen={expandFamily.isOn}>
            <div className="max-h-96 overflow-y-auto">
              <div className="flex flex-col gap-y-6">
                {sortedFamilyMembers.map((familyMember) => (
                  <Link
                    key={familyMember.memberPatientId}
                    to={paths.patientBilling({ patientId: familyMember.memberPatientId })}
                  >
                    <BalanceBox
                      leftIcon={
                        <Avatar
                          imageUrl={familyMember.thumbnail}
                          name={fixFamilyMemberFullDisplayName(familyMember.name.fullDisplayName)}
                        />
                      }
                      amount={formatCurrency(familyMember.balanceAmount)}
                      label={getPatientDisplayName(familyMember)}
                      theme="SMALL"
                    />
                  </Link>
                ))}
              </div>
            </div>
          </CollapsibleSection>
          <DashedDivider />
          <BalanceBox
            leftIcon={<DiscountIcon className="h-6 w-6" />}
            amount={formatCurrency(Math.abs(discountBalance))}
            label="Total Patient Discounts"
            theme="SMALL"
          />
        </BalanceContainer>
        <BalanceContainer>
          <BalanceBox
            leftIcon={<ShieldIcon className={cx("h-6 w-6", insuranceBalance < 0 && "text-redDark")} />}
            amount={formatCurrency(insuranceBalance)}
            label="Insurance Balance"
            theme="LARGE"
          />
        </BalanceContainer>
        <BalanceContainer>
          <div className="flex flex-col gap-y-2">
            <BalanceBox
              key={wallet.uuid}
              leftIcon={<WalletIcon className="h-6 w-6" />}
              rightIcon={
                <FloatingTooltip
                  content={
                    <span>
                      The Wallet allows patients to pay in advance for future procedures. A patient can use
                      any payment method to add funds to the Wallet. To pay for an invoice with the Wallet,
                      select the Patient Wallet to apply Wallet funds to a specific invoice.
                    </span>
                  }
                >
                  <InfoIcon className="text-slate-500 h-5 w-5" />
                </FloatingTooltip>
              }
              amount={formatCurrency(wallet.balance)}
              label="Patient Wallet Balance"
              theme="LARGE"
            >
              <RoleGuardCollectClick>
                <ButtonIcon
                  SvgIcon={AddIcon}
                  onClick={() => flyoverReelInitialPanel.open({ id: "AddToWallet" })}
                  theme="primary"
                  size="sm"
                  tooltip={{ content: "Add Funds", theme: "SMALL" }}
                />
              </RoleGuardCollectClick>
            </BalanceBox>
          </div>
          <DashedDivider />
          <div className="flex flex-col gap-y-2">
            <BalanceBox
              leftIcon={<CreditCardsMultipleIcon className="h-6 w-6" />}
              amount={paymentProfilesQuery.data?.length ?? 0}
              label="Saved Cards"
              theme="LARGE"
            >
              <RoleGuardCollectClick>
                <ButtonIcon
                  SvgIcon={EditIcon}
                  onClick={() => flyoverReelInitialPanel.open({ id: "ManagePaymentMethods" })}
                  theme="primary"
                  size="sm"
                  tooltip={{ content: "Manage Payment Methods", theme: "SMALL" }}
                />
              </RoleGuardCollectClick>
            </BalanceBox>
          </div>
        </BalanceContainer>
      </QueryResult>
      {flyoverReelInitialPanel.item && (
        <PatientPaymentMethodsFlyoverReel
          initialPanel={flyoverReelInitialPanel.item}
          onClose={flyoverReelInitialPanel.close}
          patientId={patientId}
        />
      )}
    </div>
  );
};

const DashedDivider: FC = () => <div className="border-t border-dashed border-slate-300" />;

const BalanceContainer: FC<PropsWithChildren> = ({ children }) => {
  return <div className="flex flex-col gap-y-6 p-6 border-b border-slate-300">{children}</div>;
};

const CollectButton: FC<{
  onClick: Func;
}> = ({ onClick }) => <ButtonIcon size="lg" theme="primary" SvgIcon={RequestMoneyIcon} onClick={onClick} />;

const RoleGuardCollectClick: FC<PropsWithChildren> = ({ children }) => {
  return (
    <RoleGuardClick domain="BILLING" action="COLLECT_MANAGE">
      {children}
    </RoleGuardClick>
  );
};
