import { Button } from "@libs/components/UI/Button";
import { useObjectState } from "@libs/hooks/useObjectState";
import { FC, FormEvent, useMemo } from "react";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { FlyoverContent, FlyoverFooter, FlyoverForm, FlyoverHeader } from "components/UI/FlyoverComponents";
import { EmployeesQuery } from "utils/routing/employees";
import { EmployeeStatus } from "api/employee/types";

type FilterProps = {
  status: EmployeesQuery["status"];
};

type Props = {
  onRequestClose: Func;
  onSave: (updates: Partial<FilterProps>) => void;
} & FilterProps;

type DraftFilter = {
  status: Set<ListItem<FilterProps["status"]>>;
};

export const EmployeesFilterForm: FC<Props> = ({ onRequestClose, onSave, ...filterProps }) => {
  const [draftFilter, updateDraftFilter] = useObjectState<DraftFilter>(() => ({
    status: new Set(filterProps.status),
  }));

  const options: { label: string; value: EmployeeStatus }[] = useMemo(() => {
    return [
      {
        label: "Active",
        value: "ACTIVE",
      },
      {
        label: "Pending",
        value: "PENDING",
      },
      {
        label: "Inactive",
        value: "INACTIVE",
      },
      {
        label: "Furloughed",
        value: "FURLOUGHED",
      },
      {
        label: "Terminated",
        value: "ARCHIVED",
      },
    ];
  }, []);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSave({
      status: [...draftFilter.status],
    });
  };

  return (
    <FlyoverForm onSubmit={handleSubmit}>
      <FlyoverHeader size="sm" onClose={onRequestClose}>
        Filters
      </FlyoverHeader>
      <FlyoverContent>
        <div className="flex flex-col gap-y-6">
          <CheckboxList
            label="Status"
            selectedValues={draftFilter.status}
            layout="vert"
            options={options}
            onChange={(newSet) => updateDraftFilter({ status: newSet })}
          />
        </div>
      </FlyoverContent>
      <FlyoverFooter>
        <Button size="medium" className="min-w-button" theme="secondary" onClick={onRequestClose}>
          Cancel
        </Button>
        <Button size="medium" className="min-w-button" type="submit">
          Apply
        </Button>
      </FlyoverFooter>
    </FlyoverForm>
  );
};
