import { ProviderVO } from "@libs/api/generated-api";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { appointmentFormCxStyles } from "components/ScheduleAppointments/AppointmentForm";
import { AppointmentSlotFinderPreferences } from "components/ScheduleAppointments/AppointmentSlotFinderPreferences";
import { SlotPreferences } from "components/ScheduleAppointments/types";
import { Divider } from "components/UI/Divider";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";
import { BaseFormSection } from "components/UI/FormSection";

export const AppointmentAsapPreferences: React.FC<{
  dentists: ProviderVO[];
  hygienists: ProviderVO[];
  asapSlotPreferences: SlotPreferences;
  onAsapPreferencesChange: (partial: Partial<SlotPreferences>) => void;
  asapComment?: string;
  onAsapCommentChange: (comment: string) => void;
  onAsapListToggle: Func;
  addToAsapList?: boolean;
  disabled: boolean;
}> = ({
  asapSlotPreferences,
  onAsapPreferencesChange,
  asapComment,
  onAsapCommentChange,
  dentists,
  hygienists,
  onAsapListToggle,
  addToAsapList,
  disabled,
}) => {
  return (
    <div className={appointmentFormCxStyles.row}>
      <div className={appointmentFormCxStyles.contentContainer}>
        <Divider />
        <BaseFormSection className={appointmentFormCxStyles.padding} title="ASAP List">
          <Checkbox className="mb-3" disabled={disabled} onChange={onAsapListToggle} checked={addToAsapList}>
            Add Patient to ASAP List
          </Checkbox>
          {addToAsapList && (
            <AppointmentSlotFinderPreferences
              onUpdateSlotPreferences={onAsapPreferencesChange}
              slotPreferences={asapSlotPreferences}
              dentists={dentists}
              className="mr-6"
              disabled={disabled}
              hygienists={hygienists}
            >
              <div className="mt-4 mb-2 text-xxs">Comments</div>
              <FormFieldTextarea
                placeholder="Please enter any notes regarding the patients preferences here"
                value={asapComment}
                disabled={disabled}
                onChange={(e) => onAsapCommentChange(e.target.value)}
              />
            </AppointmentSlotFinderPreferences>
          )}
        </BaseFormSection>
      </div>
      <VerticalDivider />
      <div className={appointmentFormCxStyles.detailsContainer} />
    </div>
  );
};
