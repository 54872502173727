import { FC, Dispatch, SetStateAction } from "react";
import { produce } from "immer";

import { PatientCriteriaVO, PatientListCriteria } from "@libs/api/generated-api";
import { Button } from "@libs/components/UI/Button";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { FormFieldCurrencyInput } from "components/UI/FormFieldCurrencyInput";

import { FilterSection } from "components/Communications/Filters/FilterSection";
import { PatientListCriteriaValidationResult } from "components/Communications/Filters/types";

interface Props extends Pick<PatientCriteriaVO, "remainingBenefitAmountRange"> {
  validationResult: PatientListCriteriaValidationResult["patientCriteria"]["remainingBenefitAmountRange"];
  onUpdatePatientListCriteria: Dispatch<SetStateAction<PatientListCriteria>>;
}

export const RemainingBenefitAmountFilterSection: FC<Props> = ({
  remainingBenefitAmountRange,
  validationResult,
  onUpdatePatientListCriteria,
}) => (
  <FilterSection
    title="Remaining Benefit Amount"
    dataTestId="remaining-benefit-amount-filter-section"
    isOpen={Boolean(remainingBenefitAmountRange?.min != null || remainingBenefitAmountRange?.max != null)}
    hasError={Boolean(validationResult.min.$error)}
  >
    <div className="flex flex-col gap-y-2">
      <div className="flex items-center gap-x-2">
        <FormFieldCurrencyInput
          aria-label="Min Remaining Benefit Amount"
          className="w-24"
          placeholder="Min"
          decimalScale={0}
          value={remainingBenefitAmountRange?.min ?? ""}
          onValueChange={(value) =>
            onUpdatePatientListCriteria((last) =>
              produce(last, (draft) => {
                const min = value ? Number(value) : undefined;

                if (min == null && draft.patientCriteria?.remainingBenefitAmountRange?.max == null) {
                  delete draft.patientCriteria?.remainingBenefitAmountRange;
                } else if (draft.patientCriteria) {
                  draft.patientCriteria.remainingBenefitAmountRange = {
                    ...draft.patientCriteria.remainingBenefitAmountRange,
                    min,
                  };
                } else {
                  draft.patientCriteria = { remainingBenefitAmountRange: { min } };
                }
              })
            )
          }
          error={validationResult.min.$error}
          displayErrorMessage={false}
        />

        <span className="text-xs text-black">-</span>

        <FormFieldCurrencyInput
          aria-label="Max Remaining Benefit Amount"
          className="w-24"
          placeholder="Max"
          decimalScale={0}
          value={remainingBenefitAmountRange?.max ?? ""}
          onValueChange={(value) =>
            onUpdatePatientListCriteria((last) =>
              produce(last, (draft) => {
                const max = value ? Number(value) : undefined;

                if (max == null && draft.patientCriteria?.remainingBenefitAmountRange?.min == null) {
                  delete draft.patientCriteria?.remainingBenefitAmountRange;
                } else if (draft.patientCriteria) {
                  draft.patientCriteria.remainingBenefitAmountRange = {
                    ...draft.patientCriteria.remainingBenefitAmountRange,
                    max,
                  };
                } else {
                  draft.patientCriteria = { remainingBenefitAmountRange: { max } };
                }
              })
            )
          }
          error={validationResult.min.$error}
          displayErrorMessage={false}
        />
      </div>

      {validationResult.min.$error ? <FormFieldError>{validationResult.min.$error}</FormFieldError> : null}

      {remainingBenefitAmountRange?.min != null || remainingBenefitAmountRange?.max != null ? (
        <Button
          className="w-fit text-xs"
          onClick={() =>
            onUpdatePatientListCriteria((last) =>
              produce(last, (draft) => {
                if (draft.patientCriteria) {
                  delete draft.patientCriteria.remainingBenefitAmountRange;
                }
              })
            )
          }
          size="custom"
          theme="link"
        >
          Clear
        </Button>
      ) : null}
    </div>
  </FilterSection>
);
