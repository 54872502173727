import React from "react";
import { TimeSeriesDataPoint, TimeSeriesResponse } from "@libs/api/generated-api";
import { BarStackData } from "components/Dashboard/Charting/types";

import { getMapOfDimensionsFromTimeseries } from "components/Dashboard/Charting/utils";

export const useTimeseriesAsStacks = <T extends string>(
  timeline: TimeSeriesDataPoint[],
  keys: T[],
  data?: TimeSeriesResponse
): BarStackData<T>[] => {
  return React.useMemo(() => {
    if (keys.length === 0) {
      return [];
    }

    const mapOfDimensions = getMapOfDimensionsFromTimeseries(keys, timeline, data?.timeSeries);

    // All items have the same length, so let's just use the first one to map to a list of stack data:
    const oneOfTheSeries = mapOfDimensions[keys[0]];

    return oneOfTheSeries.map((seriesItem, i) => {
      const value: Record<string, string | number> = {
        date: seriesItem.startDate,
      };

      for (const key of keys) {
        value[key] = mapOfDimensions[key][i].value;
      }

      return value as BarStackData<T>;
    });
  }, [data?.timeSeries, keys, timeline]);
};
