import { UncollectedByCarrierEntryVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { formatISODate } from "@libs/utils/date";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { ReactComponent as NoteIcon } from "@libs/assets/icons/note.svg";
import { EMPTY_CELL, Row } from "@libs/components/UI/GridTableComponents";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";
import { paths } from "utils/routing/paths";
import { useOrdinalTitles } from "components/Patient/insuranceHooks";
import { SubscriberCell } from "components/Dashboard/Tables/SubscriberCell";
import { PatientNameLink } from "components/Dashboard/Tables/PatientNameLink";

type Props = {
  data: UncollectedByCarrierEntryVO;
  last?: boolean;
  practiceId: number;
};
export const ByCarrierClaimRow: React.FC<Props> = ({ data, last, practiceId }) => {
  const {
    patient,
    patientDob,
    primarySubscriberPatient,
    insuranceOrdinal,
    productionDate,
    claimSubmissionDate,
    claimAmount,
    insuranceUncollectedAmount,
    claimAge,
    claimUuid,
    patientInsuranceId,
  } = data;
  const cellProps = { last, to: paths.patient({ patientId: patient.id }) };
  const ordinalTitles = useOrdinalTitles();

  return (
    <Row>
      <DashboardLinkCell {...cellProps}>
        <PatientNameLink patient={patient} />
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        {patientDob ? formatISODate(patientDob, "MM/dd/yyyy") : EMPTY_CELL}{" "}
      </DashboardLinkCell>
      <SubscriberCell
        {...cellProps}
        patientInsuranceId={patientInsuranceId}
        primarySubscriberPatient={primarySubscriberPatient}
        practiceId={practiceId}
      />
      <DashboardLinkCell {...cellProps}>{ordinalTitles[insuranceOrdinal]}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{formatISODate(productionDate, "MM/dd/yyyy")}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        {claimSubmissionDate ? formatISODate(claimSubmissionDate, "MM/dd/yyyy") : EMPTY_CELL}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(claimAmount)}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(insuranceUncollectedAmount)}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {claimAge ?? EMPTY_CELL}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        <ButtonInternalLink
          className="hover:opacity-70"
          theme="link"
          onClick={(e) => e.stopPropagation()}
          to={paths.claim({ claimUuid })}
        >
          <NoteIcon className="w-5 h-5" />
        </ButtonInternalLink>
      </DashboardLinkCell>
    </Row>
  );
};
