import { FC, useMemo } from "react";
import { Route, useMatch } from "react-router-dom";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { getWallets } from "api/billing/queries";
import { usePathParams } from "hooks/usePathParams";
import { NotFound } from "components/NotFoundRoute";
import { getFallbackPatientWallet } from "components/PatientProfile/Billing/billingUtils";
import { CreateInvoicePage } from "components/PatientProfile/Billing/CreateInvoicePage";
import { ViewInvoiceRoute } from "components/PatientProfile/Billing/ViewInvoiceRoute";
import { ViewPaymentPage } from "components/PatientProfile/Billing/ViewPaymentPage";
import { AppointmentsAndAdjustmentsPage } from "components/PatientProfile/Billing/Ledger/AppointmentsAndAdjustmentsPage";
import { PatientAppHistoryProvider } from "contexts/PatientLinksContext";
import { InvoicesPage } from "components/PatientProfile/Billing/Ledger/InvoicesPage";
import {
  PatientBillingLinksProvider,
  getDefaultPatientBillingLinks,
} from "components/PatientProfile/Billing/PatientBillingLinksContex";
import { CollectPaymentRoute } from "components/PatientProfile/Billing/Payment/CollectPaymentRoute";
import { EditPaymentRoute } from "components/PatientProfile/Billing/Payment/EditPaymentRoute";
import { PaymentsPage } from "components/PatientProfile/Billing/Ledger/PaymentsPage";
import { WalletActivityPage } from "components/PatientProfile/Billing/Ledger/WalletActivityPage";
import { ImportedLedgerRoute } from "components/PatientProfile/Billing/ImportedLedger/ImportedLedgerRoute";
import { routesConfig } from "utils/routing/paths";
import { RoleGuardLock } from "components/Main/RoleGuard";
import { PatientBalances } from "./PatientBalances";

export const Billing: FC = () => {
  const { patientId } = usePathParams("patientBilling");
  const { practiceId } = useAccount();

  const [patientWalletsQuery] = useApiQueries([getWallets({ args: { patientId, practiceId } })]);

  const wallet = getFallbackPatientWallet(patientId, practiceId, patientWalletsQuery.data);

  const links = useMemo(
    () => getDefaultPatientBillingLinks(patientId, wallet.uuid),
    [patientId, wallet.uuid]
  );

  const importedLedgerRouteMatch = useMatch(routesConfig.importedLedger.path);
  const collectPaymentRouteMatch = useMatch(routesConfig.collectPayment.path);
  const editPaymentRouteMatch = useMatch(routesConfig.editPayment.path);
  const showPatientBalances =
    !importedLedgerRouteMatch && !collectPaymentRouteMatch && !editPaymentRouteMatch;

  return (
    <PatientAppHistoryProvider patientId={patientId} name="billing">
      <PatientBillingLinksProvider defaultLinks={links}>
        <div className="flex h-full min-w-[1300px]">
          <SentryRoutes>
            <Route index element={<AppointmentsAndAdjustmentsPage />} />
            <Route path="imported-ledger" element={<ImportedLedgerRoute />} />
            <Route path="invoices" element={<InvoicesPage />} />
            <Route path="payments" element={<PaymentsPage />} />
            <Route path="wallet/:walletUuid/activity" element={<WalletActivityPage />} />
            <Route
              path="invoice"
              element={
                <RoleGuardLock domain="BILLING" action="COLLECT_MANAGE">
                  <CreateInvoicePage />
                </RoleGuardLock>
              }
            />
            <Route
              path="invoice/:invoiceUuid"
              element={
                <RoleGuardLock domain="BILLING" action="ACCESS_ALL">
                  <ViewInvoiceRoute />
                </RoleGuardLock>
              }
            />
            <Route
              path="payment/collect"
              element={
                <RoleGuardLock domain="BILLING" action="COLLECT_MANAGE">
                  <CollectPaymentRoute />
                </RoleGuardLock>
              }
            />
            <Route path="payment/:paymentUuid" element={<ViewPaymentPage />} />
            <Route
              path="payment/:paymentUuid/edit"
              element={
                <RoleGuardLock domain="BILLING" action="EDIT_PAYMENT">
                  <EditPaymentRoute />
                </RoleGuardLock>
              }
            />
            <Route path="*" element={<NotFound />} />
          </SentryRoutes>
          {showPatientBalances && <PatientBalances patientId={patientId} />}
        </div>
      </PatientBillingLinksProvider>
    </PatientAppHistoryProvider>
  );
};
