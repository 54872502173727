import { FC } from "react";
import { format } from "date-fns";
import { MedicalConditionsAuditVO, MedicalHistoryAuditVO } from "@libs/api/generated-api";
import { formatDate, getLocalDate } from "@libs/utils/date";
import { ReactComponent as UserIcon } from "@libs/assets/icons/user.svg";
import { ReactComponent as MedicalDoctorIcon } from "@libs/assets/icons/medical-doctor.svg";
import EmptyMedicalData from "assets/images/empty-medical-data.svg";
import { splitOnUnescapedCommas } from "utils/csv";

interface Props {
  medicalAuditLog: MedicalHistoryAuditVO[];
}

const cxStyles = {
  removed: "text-red line-through",
  added: "text-green",
};

const Changes: FC<{ conditions: MedicalConditionsAuditVO; title: string }> = ({ conditions, title }) => {
  const removed = splitOnUnescapedCommas(conditions.removed)
    .filter(Boolean)
    .map((ele, index) => (
      <div key={`removed-${index}`} className={cxStyles.removed}>
        {ele}
      </div>
    ));
  const added = splitOnUnescapedCommas(conditions.added)
    .filter(Boolean)
    .map((ele, index) => (
      <div key={`added-${index}`} className={cxStyles.added}>
        {ele}
      </div>
    ));
  const changes = [...removed, ...added];

  return changes.length ? (
    <div className="flex flex-wrap mb-2 last:mb-0">
      <div>{title}</div>
      &nbsp;
      {changes.flatMap((item, index) => [
        item,
        index === changes.length - 1 ? null : (
          <div className="mr-1" key={index}>
            ,
          </div>
        ),
      ])}
    </div>
  ) : null;
};

export const MedicalAuditLog: FC<Props> = ({ medicalAuditLog }) => {
  return medicalAuditLog.length === 0 ? (
    <div className="py-6 px-8 flex flex-col items-center">
      <img alt="No Medical History Audit Log" src={EmptyMedicalData} />
      <p className="font-sans text-xs mt-3">No Medical History Audit Log Found</p>
    </div>
  ) : (
    <div className="text-xs">
      {medicalAuditLog.map((dataElement) => {
        const medicalConditions = dataElement.medicalConditions;
        const dentalConditions = dataElement.dentalConditions;
        const medicalQuestions = dataElement.medicalQuestions;
        const dentalQuestions = dataElement.dentalQuestions;

        const localDateTime = dataElement.date ? getLocalDate(dataElement.date, dataElement.time) : null;

        return (
          <div className="px-8 py-5 border-b border-b-greyLighter" key={dataElement.id}>
            <div className="flex text-xs">
              <div className="font-sansSemiBold">{localDateTime ? formatDate(localDateTime) : null}</div>
              <div className="mx-2 text-greyLight">|</div>
              <div>{localDateTime ? format(localDateTime, "p") : null}</div>
              <div className="mx-2 text-greyLight">|</div>

              <div className="flex text-xs">
                {dataElement.isEmployee ? (
                  <MedicalDoctorIcon className="w-4 h-4" />
                ) : (
                  <UserIcon className="w-4 h-4" />
                )}
                <div className="ml-1 truncate max-w-full">{dataElement.editorName}</div>
              </div>
            </div>

            <div className="font-sansSemiBold mt-4">Changes Done</div>

            <div className="flex items-end justify-between text-xs mt-2">
              <div>
                {medicalConditions ? (
                  <Changes conditions={medicalConditions} title="Medical conditions:" />
                ) : null}
                {dentalConditions ? (
                  <Changes conditions={dentalConditions} title="Dental conditions:" />
                ) : null}
                {medicalQuestions ? (
                  <Changes conditions={medicalQuestions} title="Medical questions:" />
                ) : null}
                {dentalQuestions ? <Changes conditions={dentalQuestions} title="Dental questions:" /> : null}
              </div>
            </div>
          </div>
        );
      })}
    </div>
  );
};
