import { FC, ReactNode, useState } from "react";
import { fromUnixTime } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { WalletActivityVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Button } from "@libs/components/UI/Button";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";
import { getPatientSummary } from "api/patients/queries";
import { getPayment, getPaymentUrl } from "api/billing/queries";
import { PrintButton } from "components/UI/PrintButton";
import { SummaryBanner, SummaryCell } from "components/PatientProfile/Billing/SummaryBanner";
import { getPaymentMethodToLabel } from "components/PatientProfile/Billing/billingUtils";
import { PracticeAndPatientAddresses } from "./PracticeAndPatientAddresses";

export const ViewPrePaymentReceiptModal: FC<{
  patientId: number;
  paymentUuid: string;
  walletActivityNote: WalletActivityVO["note"];
  onClose: Func;
}> = ({ patientId, paymentUuid, walletActivityNote, onClose }) => {
  const practice = useCurrentPractice();
  const [requestPrint, setRequestPrint] = useState(false);

  const [patientQuery, paymentQuery, paymentUrlQuery] = useApiQueries([
    getPatientSummary({ args: { patientId, practiceId: practice.id } }),
    getPayment({ args: { paymentUuid, practiceId: practice.id } }),
    getPaymentUrl({
      args: { patientId, practiceId: practice.id, paymentUuid },
      queryOptions: {
        enabled: requestPrint,
        onSuccess: (data) => window.open(data.data.data, "_target"),
      },
    }),
  ]);

  return (
    <Modal
      onClose={onClose}
      title={
        <div className="flex gap-x-3 items-center">
          <span>Receipt</span>
          <span className="font-sans">|</span>
          <span className="text-sm font-sans">
            {(paymentQuery.data?.currencyAmount.amount ?? 0) < 0 ? "Refund" : "Pre-payment"}
          </span>
        </div>
      }
    >
      <ModalContent padding="lg">
        <QueryResult queries={[patientQuery, paymentQuery]}>
          {patientQuery.data && paymentQuery.data && (
            <div className="flex flex-col gap-y-6">
              <PracticeAndPatientAddresses practiceData={practice} patientData={patientQuery.data} />
              <PrePaymentSummary
                receiptNumber={paymentQuery.data.receiptNumber}
                datePaid={formatInTimeZone(
                  fromUnixTime(paymentQuery.data.paymentCreatedAt),
                  practice.timezoneId,
                  "P"
                )}
                surchargeAmount={paymentQuery.data.surchargeAmount}
                totalPaymentAmount={paymentQuery.data.totalPaymentAmount}
                amount={paymentQuery.data.currencyAmount.amount}
                paymentMethod={getPaymentMethodToLabel(paymentQuery.data)}
              />
              <TitleAndParagraph title="Description">
                {walletActivityNote ?? <i>Not available.</i>}
              </TitleAndParagraph>
            </div>
          )}
        </QueryResult>
      </ModalContent>
      <ModalFooter>
        <Button theme="secondary" onClick={onClose}>
          Close
        </Button>
        <PrintButton
          printUrl={paymentUrlQuery.data}
          isLoadingPrintUrl={paymentUrlQuery.isInitialLoading}
          onClick={() => setRequestPrint(true)}
        />
      </ModalFooter>
    </Modal>
  );
};

const PrePaymentSummary: FC<{
  receiptNumber: string;
  datePaid: string;
  surchargeAmount: number;
  totalPaymentAmount: number;
  amount: number;
  paymentMethod: string;
}> = ({ receiptNumber, datePaid, amount, paymentMethod, surchargeAmount, totalPaymentAmount }) => {
  const hasSurcharge = surchargeAmount !== 0;

  return (
    <SummaryBanner>
      <SummaryCell header="Receipt #">{receiptNumber}</SummaryCell>
      <SummaryCell header="Date Paid">{datePaid}</SummaryCell>
      <SummaryCell header="Payment Method">{paymentMethod}</SummaryCell>
      {hasSurcharge && (
        <>
          <SummaryCell header="Subtotal">{formatCurrency(amount)}</SummaryCell>
          <SummaryCell header="Surcharge" className="text-right">
            {formatCurrency(surchargeAmount)}
          </SummaryCell>
        </>
      )}
      <SummaryCell header="Amount Paid" className="text-right">
        {formatCurrency(hasSurcharge ? totalPaymentAmount : amount)}
      </SummaryCell>
    </SummaryBanner>
  );
};

const TitleAndParagraph: FC<{ title: string; children: ReactNode }> = ({ title, children }) => {
  return (
    <div className="flex flex-col gap-y-1">
      <div className="text-sm font-sansSemiBold">{title}</div>
      <div className="text-xs">{children}</div>
    </div>
  );
};
