import React, { useState, useEffect, useCallback, useMemo } from "react";
import { ReactSortable } from "react-sortablejs";
import { RoomRequest, RoomVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Icon } from "@libs/components/UI/Icon";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as PlusCircle } from "@libs/assets/icons/plus-circle.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { HeaderCell, TableGrid, cxGridTableStyles } from "@libs/components/UI/GridTableComponents";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { handleError } from "utils/handleError";
import { upsertRooms } from "api/practice/mutations";
import { getPracticeRoomsQuery } from "api/scheduling/queries";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { useItemModal } from "hooks/useItemModal";
import { RoomRow } from "components/Settings/PracticeSetup/Sections/Rooms/RoomRow";
import { convertToRoomRequest } from "components/Settings/PracticeSetup/Sections/Rooms/utils";
import { RoomsFlyover } from "components/Settings/PracticeSetup/Sections/Rooms/RoomFlyover";

const headers = [
  { id: "draggable", width: "40px" },
  { id: "name", label: "Name", width: "minmax(12rem,21rem)" },
  { id: "description", label: "Description", width: "minmax(12rem,21rem)" },
  { id: "providers", label: "Providers", width: "minmax(10rem, 1fr)" },
  { id: "edit", label: null, width: "52px" },
];

export const Rooms: React.FC = () => {
  const { practiceId } = useAccount();

  const [dragItem, setDragItem] = useState<RoomVO>();

  // Undefined represents add mode.
  const editModal = useItemModal<RoomVO | undefined | null>(null);

  const [roomsQuery, providersQuery] = useApiQueries([
    getPracticeRoomsQuery({ args: { practiceId } }),
    getPracticeProvidersQuery({ args: { practiceId, statuses: ["ACTIVE", "PENDING"] } }),
  ]);

  const [saveRoomMutation, upsertRoomsMutation] = useApiMutations([upsertRooms, upsertRooms]);

  const [rooms, setRooms] = useState<RoomVO[]>(() => roomsQuery.data || []);

  useEffect(() => {
    if (roomsQuery.data) {
      setRooms(roomsQuery.data);
    }
  }, [roomsQuery.data]);

  const handleSave = useCallback(
    (data: RoomRequest[]) => {
      upsertRoomsMutation.mutate(
        { data: { rooms: data }, practiceId },
        {
          onSuccess: editModal.close,
          onError: handleError,
        }
      );
    },
    [editModal.close, practiceId, upsertRoomsMutation]
  );

  const handleSaveRoom = useCallback(
    (room: RoomRequest) => {
      let existingRooms = rooms;

      if (room.id == null) {
        // Make the room show up at the end.
        room.order = existingRooms.length;
      } else {
        existingRooms = rooms.filter((existingRoom) => existingRoom.id !== room.id);
      }

      const roomRequests = existingRooms.map((existingRoom) => convertToRoomRequest(existingRoom));

      saveRoomMutation.mutate(
        { practiceId, data: { rooms: [...roomRequests, room] } },
        {
          onSuccess: editModal.close,
          onError: handleError,
        }
      );
    },
    [rooms, editModal.close, practiceId, saveRoomMutation]
  );

  const handleDeleteRoom = useCallback(
    (roomToDelete: RoomVO) => {
      const requests = rooms
        .filter((existingRoom) => existingRoom.id !== roomToDelete.id)
        .map((room) => convertToRoomRequest(room));

      handleSave(requests);
    },
    [handleSave, rooms]
  );

  const handleDragEnd = useCallback(
    (oldIndex?: number, newIndex?: number) => {
      setDragItem(undefined);

      if (oldIndex == null || newIndex == null || oldIndex === newIndex) {
        return;
      }

      const reorderedRooms = [...rooms];
      const [removed] = reorderedRooms.splice(oldIndex, 1);

      reorderedRooms.splice(newIndex, 0, removed);

      const orderedRequests = reorderedRooms.map((room, index) =>
        convertToRoomRequest(room, { order: index })
      );

      handleSave(orderedRequests);
    },
    [handleSave, rooms]
  );

  const headerWidths = useMemo(() => headers.map(({ width }) => width), []);

  return (
    <div className="flex flex-col h-full">
      <TableGrid columnWidths={headerWidths}>
        {headers.map((item) => (
          <HeaderCell key={item.id}>{item.label}</HeaderCell>
        ))}
      </TableGrid>
      <QueryResult
        loading={
          <div className="h-full">
            <LoadingContent />
          </div>
        }
        queries={[roomsQuery, providersQuery]}
      >
        <ReactSortable
          animation={150}
          list={rooms}
          setList={setRooms}
          handle=".drag-handle"
          onEnd={(e) => handleDragEnd(e.oldDraggableIndex, e.newDraggableIndex)}
          onStart={(e) => setDragItem(e.oldIndex === undefined ? undefined : rooms[e.oldIndex])}
        >
          {rooms.map((room, index) => (
            <TableGrid
              key={room.id || index}
              columnWidths={headerWidths}
              className={dragItem && dragItem.id === room.id ? "invisible" : undefined}
            >
              <RoomRow room={room} onEdit={editModal.open} onDelete={handleDeleteRoom} />
            </TableGrid>
          ))}
        </ReactSortable>
        <div className="border-b">
          <Button
            theme="link"
            disabled={roomsQuery.isLoading}
            className={cx("group", cxGridTableStyles.cellPadding())}
            onClick={() => editModal.open(undefined)}
          >
            <div className="flex items-center gap-x-1 text-xs/4">
              <Icon
                SvgIcon={PlusCircle}
                className="group-hover:opacity-70 -ml-0.5 mr-2"
                theme="primary"
                disabled={roomsQuery.isLoading}
              />
              Room
            </div>
          </Button>
        </div>
      </QueryResult>
      {editModal.isOpen && editModal.item !== null && providersQuery.data != null && (
        <RoomsFlyover
          existingRoom={editModal.item}
          providers={providersQuery.data}
          onClose={editModal.close}
          onSave={handleSaveRoom}
          isSaving={saveRoomMutation.isLoading}
        />
      )}
    </div>
  );
};
