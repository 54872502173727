import React from "react";
import Skeleton from "react-loading-skeleton";
import { colors } from "@libs/design.config";

import { DentalProcedureFeeVO, DentalProcedureVO, FeeScheduleVO } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import {
  Cell,
  CurrencyCell,
  SubcategoryHeader,
} from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/FeeCategoryTable/TableItems";

type Props = {
  comparedFeeScheduleQuery: ApiQueryResult<FeeScheduleVO>;
  procedures: DentalProcedureVO[];
  subCategory: string;
  onFeeUpdated: (fee: DentalProcedureFeeVO) => void;
  feeScheduleDetailProcedureFees: Partial<DentalProcedureFeeVO>[];
  isPracticeUcr: boolean;
};

export const SubcategoryProcedures: React.FC<Props> = ({
  subCategory,
  procedures,
  isPracticeUcr,
  comparedFeeScheduleQuery,
  feeScheduleDetailProcedureFees,
  onFeeUpdated,
}) => {
  return (
    <>
      <SubcategoryHeader>{subCategory}</SubcategoryHeader>
      {procedures.map((procedure) => {
        const primaryProcedure = feeScheduleDetailProcedureFees.find(
          (item) => item.procedureId === procedure.id
        );

        return (
          <React.Fragment key={procedure.id}>
            <Cell>{procedure.cdtCode}</Cell>
            <Cell>{procedure.name}</Cell>
            <Cell className="break-words">{procedure.simpleName}</Cell>
            {!isPracticeUcr && <CurrencyCell edit={false} value={procedure.ucrFee} />}

            <CurrencyCell
              edit={true}
              onChange={(value) => {
                onFeeUpdated({
                  procedureId: procedure.id,
                  ucrFee: isPracticeUcr ? value : procedure.ucrFee,
                  fee: value,
                });
              }}
              value={primaryProcedure?.fee}
            />

            {comparedFeeScheduleQuery.isLoading ? (
              <Cell textAlign="right">
                <Skeleton
                  baseColor={colors.greyLight}
                  className="w-8 z-0"
                  highlightColor={colors.greyLighter}
                />
              </Cell>
            ) : (
              <CurrencyCell
                edit={false}
                value={
                  comparedFeeScheduleQuery.data?.dentalProcedureFees?.find(
                    (currentProcedure) => currentProcedure.procedureId === procedure.id
                  )?.fee
                }
              />
            )}
          </React.Fragment>
        );
      })}
    </>
  );
};
