import { ReactComponent as FemaleDoctor } from "@libs/assets/icons/doctor-female.svg";
import { ReactComponent as OpenDoor } from "@libs/assets/icons/open-door.svg";
import { IconSwitch } from "components/UI/IconSwitch";
import { AppointmentGrouping } from "utils/routing/scheduling";

interface Props {
  groupBy: AppointmentGrouping;
  className?: string;
  onSelectGrouping: (groupBy: AppointmentGrouping) => void;
}

export const SelectAppointmentGrouping: React.FC<Props> = ({ className, groupBy, onSelectGrouping }) => {
  return (
    <IconSwitch
      className={className}
      options={[
        { Icon: OpenDoor, value: "room", label: "Room Schedule" },
        { Icon: FemaleDoctor, value: "provider", label: "Provider Schedule" },
      ]}
      selectedValue={groupBy}
      onChange={(e, option) => {
        onSelectGrouping(option.value);
      }}
    />
  );
};
