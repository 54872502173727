import { useNavigate } from "react-router-dom";
import { ChangeEventHandler, KeyboardEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import Skeleton from "react-loading-skeleton";
import { DentalProcedureVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Form } from "@libs/components/UI/Form";
import { ModalFooter } from "@libs/components/UI/ModalComponents";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import {
  getFeeScheduleDetails,
  getInfiniteFeeSchedulesV2Query,
  getProvidersWithoutUcrQuery,
} from "api/feeSchedule/queries";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";
import { paths } from "utils/routing/paths";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { useEditableFeeSchedule } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/useEditableFeeSchedule";
import { FeeScheduleFields } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/FeeScheduleFields";
import { getAllInsuranceCarriersQuery } from "api/practiceInsurance/queries";
import { useSubmitHandler } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/useSubmitHandler";
import { Divider } from "components/UI/Divider";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { getDentalProcedureCategories, getDentalProceduresQuery } from "api/charting/queries";
import { FeeScheduleBreakdown } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/FeeScheduleBreakdown";
import EmptyList from "assets/images/empty-documents-records.svg";
import { Prompt } from "components/UI/Prompt";

const SEARCH_DEBOUNCE_MS = 350;

const useFilteredProcedures = (
  procedures: DentalProcedureVO[] | undefined,
  searchQuery: string,
  setCategoriesExpanded: (updatedSet: Set<string>) => void
) => {
  return useMemo(() => {
    if (!procedures) {
      return [];
    }

    const trimmed = searchQuery.trim().toLowerCase();

    if (trimmed === "") {
      setCategoriesExpanded(new Set());

      return procedures;
    }

    const filtered: DentalProcedureVO[] = [];
    const expandedCategories = new Set<string>();

    procedures.forEach((procedure) => {
      if (
        procedure.cdtCode.toLowerCase().includes(trimmed) ||
        procedure.name.toLowerCase().includes(trimmed) ||
        procedure.simpleName.toLowerCase().includes(trimmed)
      ) {
        filtered.push(procedure);
        expandedCategories.add(procedure.categoryType);
      }
    });

    setCategoriesExpanded(expandedCategories);

    return filtered;
  }, [procedures, searchQuery, setCategoriesExpanded]);
};

const PageLoadState = () => <Skeleton count={25} className="mb-2 h-10" />;

// eslint-disable-next-line complexity
export const FeeScheduleDetailsPage = () => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const { feeScheduleId } = usePathParams("feeScheduleDetails");
  const { query } = useQueryParams("feeScheduleDetails");
  const isCreating = feeScheduleId === "new";
  const copyId = query.copyId;
  const isCopying = Boolean(copyId);
  const from = query.from ?? paths.settingsSection({ section: "fee-schedules" });
  const [searchQuery, setSearchQuery] = useState("");
  const [filterSearchQuery, setFilterSearchQuery] = useState("");
  const debouncedOnSearchChanged = useDebouncedCallback(setFilterSearchQuery, SEARCH_DEBOUNCE_MS);
  const [categoriesExpanded, setCategoriesExpanded] = useState<Set<string>>(new Set<string>());

  const [lookupProvidersWithoutUcr, setLookupProvidersWithoutUcr] = useState<boolean | undefined>();

  const [
    carriersQuery,
    categoriesQuery,
    feeScheduleDetailsQuery,
    proceduresQuery,
    providersQuery,
    providersWithoutUcrQuery,
  ] = useApiQueries([
    getAllInsuranceCarriersQuery({ args: { practiceId } }),
    getDentalProcedureCategories(),
    getFeeScheduleDetails({
      args: { practiceId, feeScheduleId: feeScheduleId === "new" ? copyId ?? 0 : feeScheduleId },
      queryOptions: { enabled: Boolean(!isCreating || copyId) },
    }),
    getDentalProceduresQuery({ args: { practiceId } }),
    getPracticeProvidersQuery({
      args: { practiceId },
    }),
    getProvidersWithoutUcrQuery({
      args: {
        practiceId,
      },
      queryOptions: {
        enabled: Boolean(lookupProvidersWithoutUcr),
      },
    }),
  ]);

  const feeSchedulesQuery = useInfiniteApiQuery(
    getInfiniteFeeSchedulesV2Query({
      args: {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        practiceId,
      },
      queryOptions: { keepPreviousData: true },
    })
  );

  const {
    editableFeeSchedule: feeSchedule,
    handleFeeScheduleChange,
    hasEdits,
    hasEditsToFees,
    onFeeScheduleSaved,
    onFeeUpdated,
    turnOffEditing,
  } = useEditableFeeSchedule(feeScheduleDetailsQuery.data, isCreating, isCopying);

  useEffect(() => {
    setLookupProvidersWithoutUcr(feeSchedule?.isProviderUcr);
  }, [feeSchedule?.isProviderUcr]);

  const handleSearch = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      setSearchQuery(e.currentTarget.value);
      debouncedOnSearchChanged(e.currentTarget.value);
    },
    [debouncedOnSearchChanged]
  );

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>((e) => {
    if (e.key === "Enter") {
      e.preventDefault();
    }
  }, []);

  const filteredProcedures = useFilteredProcedures(
    proceduresQuery.data,
    filterSearchQuery,
    setCategoriesExpanded
  );

  const onCategoryToggle = useCallback(
    (category: string) => {
      if (categoriesExpanded.has(category)) {
        categoriesExpanded.delete(category);
        setCategoriesExpanded(new Set(categoriesExpanded));
      } else {
        setCategoriesExpanded(new Set(categoriesExpanded.add(category)));
      }
    },
    [categoriesExpanded]
  );

  const { handleSubmit, isSavingChanges, validation } = useSubmitHandler({
    allProviderIds: providersQuery.data?.map((provider) => provider.id),
    fields: feeSchedule,
    hasEditsToFees,
    practiceId,
    onSuccess: () => {
      onFeeScheduleSaved();
      navigate(paths.settingsSection({ section: "fee-schedules" }), { replace: true });
    },
    turnOffEditing,
  });

  const handleClose = useCallback(() => {
    navigate(from);
  }, [from, navigate]);
  const confirmLeave = useBoolean(false);
  const handleCloseOrConfirm = hasEdits ? confirmLeave.on : handleClose;

  return (
    <>
      {hasEdits && confirmLeave.isOff ? (
        <Prompt message="There are unsaved changes in this section. Are you sure you want to leave?" />
      ) : null}

      <SettingsPanel
        actions={<ButtonIcon SvgIcon={CloseIcon} onClick={handleCloseOrConfirm} theme="primary" />}
        includePadding={false}
        title="Fee Schedule"
      >
        <Form className="flex flex-col justify-between h-full" onSubmit={handleSubmit}>
          <div className="flex-1 flex flex-col p-6 gap-y-6 overflow-y-auto">
            <div className="flex flex-col max-w-xl gap-y-6">
              <span className="text-sm font-sansSemiBold">Fee Schedule Details</span>
              {feeSchedule?.isPracticeUcr ? (
                <FormFieldInput disabled label="Name" value={feeSchedule.name} />
              ) : (
                <QueryResult
                  loading={
                    <div className="flex flex-col gap-y-11 mt-4">
                      <Skeleton className="h-8" />
                      <Skeleton className="h-8" />
                      <Skeleton className="h-8" />
                      <Skeleton className="h-8" />
                      <Skeleton className="h-8" />
                    </div>
                  }
                  queries={[carriersQuery, feeScheduleDetailsQuery, providersQuery, providersWithoutUcrQuery]}
                >
                  {feeSchedule && (
                    <FeeScheduleFields
                      carriersQuery={carriersQuery}
                      editableFeeSchedule={feeSchedule}
                      isCreating={isCreating}
                      isCopying={isCopying}
                      onUpdate={handleFeeScheduleChange}
                      originallySelectedProviders={feeScheduleDetailsQuery.data?.providers}
                      providersQuery={providersQuery}
                      providersWithoutUcrQuery={providersWithoutUcrQuery}
                      validation={validation}
                    />
                  )}
                </QueryResult>
              )}
            </div>
            <div className="flex-1 flex flex-col max-w-7xl gap-y-6">
              <Divider className="border-dashed" />
              <div className="flex items-center justify-between">
                <div className="text-sm font-sansSemiBold">Fee Schedule</div>
                <div className="w-60">
                  <FormFieldInput
                    className="w-60"
                    Icon={SearchIcon}
                    onChange={handleSearch}
                    onKeyDown={handleKeyDown}
                    placeholder="Search CDT Code or Procedure"
                    value={searchQuery}
                  />
                </div>
              </div>
              {filteredProcedures.length === 0 && !proceduresQuery.isLoading && (
                <div className="flex flex-col items-center justify-center h-full text-sm">
                  <img alt="No Matching CDT Codes/Procedures" src={EmptyList} />
                  <div>No Matching CDT codes/procedures</div>
                </div>
              )}
              {isSavingChanges ? (
                <PageLoadState />
              ) : (
                <QueryResult
                  loading={<PageLoadState />}
                  queries={[categoriesQuery, feeScheduleDetailsQuery, proceduresQuery]}
                >
                  {categoriesQuery.data && filteredProcedures.length > 0 && feeSchedule && (
                    <FeeScheduleBreakdown
                      categories={categoriesQuery.data}
                      categoriesExpanded={categoriesExpanded}
                      feeSchedule={feeSchedule}
                      feeSchedulesQuery={feeSchedulesQuery}
                      onCategoryToggle={onCategoryToggle}
                      onFeeUpdated={onFeeUpdated}
                      procedures={filteredProcedures}
                    />
                  )}
                </QueryResult>
              )}
            </div>
          </div>
          <ModalFooter paddingClassName="p-3">
            <Button className="min-w-button" onClick={handleCloseOrConfirm} theme="secondary">
              Cancel
            </Button>
            <Button className="min-w-button" type="submit">
              Save
            </Button>
          </ModalFooter>
        </Form>
      </SettingsPanel>
      {confirmLeave.isOn && (
        <ConfirmationModal
          cancelText="No"
          confirmText="Yes"
          onCancel={confirmLeave.off}
          onConfirm={handleClose}
          primaryText="Changes Made"
          secondaryText="Changes will be lost, do you still want to leave?"
          size="2xs"
        />
      )}
    </>
  );
};
