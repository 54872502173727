import { formatAsISODate } from "@libs/utils/date";

const escapeCsvCell = (str: string) => {
  return str ? `"${str.replaceAll('"', '""')}"` : str;
};

export const formatCsvRow = (rows: string[]) => {
  return rows.map(escapeCsvCell).join(",");
};
export const EMPTY_ROW = formatCsvRow([""]);

export const getArchyCsvMetadata = (
  currentUrl: string,
  selectedTimeSegment?: { startDate: Date; endDate?: Date }
) => {
  // Time segments exclude the last day, so we subtract for the display in the csv
  let endDate = undefined;
  let startDate = undefined;

  if (selectedTimeSegment) {
    endDate = selectedTimeSegment.endDate ? formatAsISODate(selectedTimeSegment.endDate) : undefined;
    startDate = formatAsISODate(selectedTimeSegment.startDate);
  }

  return {
    headers: [
      formatCsvRow(["Date Exported", "Exported From", endDate ? "Date Range" : "Date"]),
      formatCsvRow([
        `${formatAsISODate(new Date())}`,
        currentUrl,
        endDate && startDate ? `${startDate} to ${endDate}` : startDate ?? "All Time",
      ]),
      EMPTY_ROW,
    ],
    fileNameTag: endDate && startDate ? `${startDate}_${endDate}` : startDate ?? "ALL_TIME",
  };
};

export const saveCsv = (csvRows: string[], filename: string) => {
  const blob = new Blob([`\uFEFF${csvRows.join("\n")}`], { type: "text/csv;charset=utf-8;" });
  const link = document.createElement("a");
  // Browsers that support HTML5 download attribute
  const url = URL.createObjectURL(blob);

  link.setAttribute("href", url);
  link.setAttribute("download", filename);
  link.style.visibility = "hidden";
  document.body.append(link);
  link.click();
  link.remove();

  // Clean up memory.
  window.URL.revokeObjectURL(url);
};

export const splitOnUnescapedCommas = (str?: string) => {
  if (str == null) {
    return [];
  }

  const parts = [];
  let currentPart = "";
  let isEscaped = false;

  for (const char of str) {
    if (char === "\\" && !isEscaped) {
      isEscaped = true;
      continue;
    }

    if (char === "," && !isEscaped) {
      parts.push(currentPart);
      currentPart = "";
    } else {
      currentPart += char;
    }

    isEscaped = false;
  }

  parts.push(currentPart); // Push the remaining part after the last comma

  return parts;
};
