import { FC, PropsWithChildren } from "react";
import { cx } from "@libs/utils/cx";

interface Props {
  className?: string;
}

export const Badge: FC<PropsWithChildren<Props>> = ({ children, className }) => {
  return <div className={cx("py-1 px-2 text-xs rounded-full", className)}>{children}</div>;
};
