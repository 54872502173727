import React from "react";
import { DashboardAdjustmentsQuery } from "utils/routing/dashboard";
import { paths } from "utils/routing/paths";

export const useTableTabsProps = ({
  handleRouteStateChange,
  query,
}: {
  handleRouteStateChange: (updates: Partial<DashboardAdjustmentsQuery>) => void;
  query: DashboardAdjustmentsQuery;
}) => {
  const tabs = React.useMemo(() => {
    const queryParams: Partial<DashboardAdjustmentsQuery> = {
      ...query,
      insuranceTableSort: undefined,
      collectionTableSort: undefined,
      filters: undefined,
    };

    return [
      {
        text: "Show Insurance Adjustments",
        to: paths.dashboardAdjustments({ tableTab: "insurance" }, queryParams),
      },
      {
        text: "Show Collection Adjustments",
        to: paths.dashboardAdjustments({ tableTab: "collection" }, queryParams),
      },
    ];
  }, [query]);

  return {
    onUpdateParams: handleRouteStateChange,
    tabs,
    query,
  };
};
