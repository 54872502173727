import React from "react";
import { DraftEobPaymentVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { FinixForm } from "@libs/components/UI/FinixForm";
import { RadioList } from "@libs/components/UI/RadioList";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldNumericInput } from "components/UI/FormFieldNumericInput";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { PaymentFormValues, SelectedPaymentType } from "components/Eob/useSubmitDraftEobPayment";
import { ActionModal } from "components/UI/ActionModal";
import { FinixScriptLoadErrorBanner } from "components/PatientProfile/Billing/FinixScriptLoadErrorBanner";
import { useNow } from "hooks/useNow";

interface Props {
  finixElementId: string;
  allowCCPayment: boolean;
  cardBrand: FinixBinInformation["cardBrand"] | undefined;
  disableFinalizeClaim: boolean;
  draftEobPayment: DraftEobPaymentVO;
  formValues: PaymentFormValues;
  hasFinixLoadingError: boolean;
  loadingFinix: boolean;
  handleFinixFormRef: (element: HTMLDivElement | null) => void;
  isMakingPayment: boolean;
  onClose: () => void;
  onDateFieldUpdate: (date: string, paymentType: SelectedPaymentType) => void;
  onFieldUpdate: (
    event: React.ChangeEvent<HTMLInputElement>,
    paymentType: SelectedPaymentType,
    fieldName: string
  ) => void;
  onFinalizePayment: () => Promise<void>;
  onSelectPaymentType: (event: React.ChangeEvent<HTMLInputElement>) => void;
  selectedPaymentType: SelectedPaymentType;
}

{
  /**
    VALIDATION RULES *****************
    Payment Date - only existence check, can be any date
    Check Number - max 20 char
    Bank Name - max 128 char
    EFT Number - max 20 char
    Name on Card - max 128 char
    Card Information - existence && use Finix for validation rules
 */
}

export const PaymentModal: React.FC<Props> = ({
  allowCCPayment,
  disableFinalizeClaim,
  draftEobPayment,
  formValues,
  hasFinixLoadingError,
  isMakingPayment,
  finixElementId,
  handleFinixFormRef,
  loadingFinix,
  onClose,
  onDateFieldUpdate,
  onFieldUpdate,
  onFinalizePayment,
  onSelectPaymentType,
  selectedPaymentType,
}) => {
  const now = useNow();

  return (
    <ActionModal
      disableClose={isMakingPayment}
      handleClose={onClose}
      handleSave={onFinalizePayment}
      isLoading={isMakingPayment}
      primaryButtonDisabled={disableFinalizeClaim}
      primaryButtonDisabledTooltip={
        disableFinalizeClaim
          ? selectedPaymentType === "EXTERNAL_POS"
            ? "Card Number must contain last 4 digits"
            : "Please fill out all required fields to finalize claim"
          : ""
      }
      primaryButtonText="Finalize Claim"
      secondaryButtonDisabled={isMakingPayment}
      secondaryButtonText="Back"
      size="sm"
      title="Process EOB Payment"
    >
      <div className="flex flex-col">
        {hasFinixLoadingError && selectedPaymentType === "VIRTUAL_CC" && (
          <FinixScriptLoadErrorBanner className="mb-2" />
        )}
        <div
          className={`
            flex
            justify-between
            bg-greyLightest
            text-greyDark
            text-xs
            p-4
          `}
        >
          <div className="flex items-center">
            <div className="mr-2">Total Claims Selected</div>
            <div className="text-base font-sansSemiBold">{draftEobPayment.claimPaymentSummaries.length}</div>
          </div>
          <div className="flex items-center">
            <div className="mr-2">Total Insurance Payment</div>
            <div className="text-base font-sansSemiBold">
              {formatCurrency(draftEobPayment.unpaidInsuranceAmount || 0)}
            </div>
          </div>
        </div>
        <div className="mt-6 mb-1 text-sm font-sansSemiBold">Payment Details</div>
        <RadioList
          onChange={(event) => onSelectPaymentType(event)}
          options={[
            { value: "EFT", label: "EFT" },
            { value: "CHECK", label: "Check" },
            { value: "VIRTUAL_CC", label: "Virtual CC", disabled: !allowCCPayment },
            { value: "EXTERNAL_POS", label: "External POS" },
          ]}
          selectedValue={selectedPaymentType}
        />
        <div className="mt-3">
          {selectedPaymentType === "EFT" ? (
            <div className="grid grid-cols-2 gap-3">
              <FormFieldSelectMenusDatepicker
                label="Payment Date"
                layout="labelIn"
                onChange={(date) => onDateFieldUpdate(formatAsISODate(date ?? now), "EFT")}
                required={true}
                selected={getLocalDate(formValues.EFT.paymentDate)}
              />
              <FormFieldInput
                label="Bank Name"
                layout="labelIn"
                maxLength={128}
                onChange={(event) => onFieldUpdate(event, "EFT", "bankName")}
                value={formValues.EFT.bankName}
              />
              <FormFieldInput
                label="EFT Number"
                layout="labelIn"
                maxLength={20}
                onChange={(event) => onFieldUpdate(event, "EFT", "eftNumber")}
                required={true}
                value={formValues.EFT.eftNumber}
              />
            </div>
          ) : selectedPaymentType === "CHECK" ? (
            <div className="grid grid-cols-2 gap-3">
              <FormFieldSelectMenusDatepicker
                label="Payment Date"
                layout="labelIn"
                onChange={(date) => onDateFieldUpdate(formatAsISODate(date ?? now), "CHECK")}
                required={true}
                selected={getLocalDate(formValues.CHECK.paymentDate)}
              />
              <FormFieldInput
                label="Bank Name"
                layout="labelIn"
                maxLength={128}
                onChange={(event) => onFieldUpdate(event, "CHECK", "bankName")}
                required={true}
                value={formValues.CHECK.bankName}
              />
              <FormFieldInput
                label="Check Number"
                layout="labelIn"
                maxLength={20}
                onChange={(event) => onFieldUpdate(event, "CHECK", "checkNumber")}
                required={true}
                value={formValues.CHECK.checkNumber}
              />
            </div>
          ) : selectedPaymentType === "VIRTUAL_CC" ? (
            <div className="flex flex-col mt-2">
              <FormFieldSelectMenusDatepicker
                label="Payment Date"
                layout="labelIn"
                onChange={(date) => onDateFieldUpdate(formatAsISODate(date ?? now), "VIRTUAL_CC")}
                required={true}
                selected={getLocalDate(formValues.VIRTUAL_CC.paymentDate)}
              />
              <div className="font-sansSemiBold text-sm mt-4">Card Details</div>
              <FinixForm
                elementId={finixElementId}
                handleFinixFormRef={handleFinixFormRef}
                loadingFinix={loadingFinix}
              />
            </div>
          ) : (
            <div className="grid grid-cols-2 gap-3">
              <FormFieldSelectMenusDatepicker
                label="Payment Date"
                layout="labelIn"
                onChange={(date) => onDateFieldUpdate(formatAsISODate(date ?? now), "EXTERNAL_POS")}
                required={true}
                selected={getLocalDate(formValues.EXTERNAL_POS.paymentDate)}
              />
              <FormFieldNumericInput
                label="Card Number (last 4 digits)"
                layout="labelIn"
                placeholder="XXXX"
                onChange={(event) => onFieldUpdate(event, "EXTERNAL_POS", "cardLastFour")}
                value={formValues.EXTERNAL_POS.cardLastFour}
                maxLength={4}
              />
            </div>
          )}
        </div>
      </div>
    </ActionModal>
  );
};
