import React, { FC } from "react";
import { ReactComponent as BellIcon } from "@libs/assets/icons/notification.svg";
import { Icon } from "@libs/components/UI/Icon";
import { AlertSettingsVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";

import { ALERT_PEEK_BTN_ATTR } from "components/PatientBlurbInfo/patientPeek";

interface Props {
  onOpenAlerts: Func;
  alertAppearance: AlertSettingsVO["appearance"] | undefined;
}

const alertPeekBtnAttr = { [ALERT_PEEK_BTN_ATTR]: true };

export const AlertPeekButton: FC<Props> = ({ onOpenAlerts, alertAppearance }) => {
  return (
    <button
      type="button"
      onClick={onOpenAlerts}
      {...alertPeekBtnAttr}
      className={cx(
        `size-6
         border
         border-slate-200
         rounded-full
         bg-red
         flex
         items-center
         justify-center
         absolute
         -top-1
         -right-1
         op`,
        alertAppearance === "NONE" && "opacity-0 group-hover:opacity-100 transition-opacity"
      )}
    >
      <Icon SvgIcon={BellIcon} theme="darkBackground" />
    </button>
  );
};
