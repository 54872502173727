import { FormEventHandler, useEffect, useState } from "react";
import { CurrencyInputProps } from "react-currency-input-field";
import { fromUnixTime, getUnixTime } from "date-fns";
import { ReactDatePickerProps } from "react-datepicker";
import { produce } from "immer";
import Skeleton from "react-loading-skeleton";
import { useQueryClient } from "@tanstack/react-query";
import {
  PaymentCorrectionRequest,
  PaymentVO,
  WalletActivityVO,
  WalletEditRequest,
} from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { centsToDollars, dollarsToCents, formatCurrency } from "@libs/utils/currency";
import { nowInTimezone } from "@libs/utils/date";
import { isOneOf } from "@libs/utils/isOneOf";
import { useValidation } from "@libs/hooks/useValidation";
import { eq, greaterOrEqualTo, lessOrEqualTo, maxLength, required } from "@libs/utils/validators";
import { getQueryKey } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { MutationResult } from "@libs/@types/apiMutations";
import { replaceCachedItemWithUpdatedItem } from "@libs/utils/queryCache";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Modal } from "@libs/components/UI/Modal";
import { Form } from "@libs/components/UI/Form";
import { ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import { getWalletActivityById } from "api/billing/queries";

import { FormFieldCurrencyInput } from "components/UI/FormFieldCurrencyInput";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { editWalletActivity } from "api/billing/mutations";
import { handleError } from "utils/handleError";
import { PaymentDescription } from "components/PatientProfile/Billing/PaymentDescription";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import { getPatientSummary } from "api/patients/queries";
import { getPaymentMethodToLabel } from "components/PatientProfile/Billing/billingUtils";
import { NOTE_MAX_LENGTH, Note } from "components/PatientProfile/Billing/FormComponents";
import { getPatientDisplayNameFromPatient } from "utils/names";
import { useNow } from "hooks/useNow";
import { WALLET_NOTE_MAX_LENGTH } from "components/PatientProfile/Billing/constants";

interface Props {
  patientId: number;
  walletUuid: string;
  walletActivityId: number;
  onSave: Func;
  onClose: Func;
}

const useEditWalletActivityModal = ({
  patientId,
  practiceId,
  walletActivityData,
  updatePayment,
  ...rest
}: Props & {
  walletActivityData?: WalletActivityVO;
  practiceId: number;
  updatePayment: MutationResult<typeof editWalletActivity>["mutate"];
}) => {
  const [newAmount, setNewAmount] = useState("");
  const [walletActivityDraft, setWalletActivityDraft] = useState<Required<WalletEditRequest>>(() => ({
    paymentCorrectionRequest: { paymentUuid: "", note: "" },
    note: "",
  }));

  const isRefund = walletActivityData?.walletActivityType === "REFUND";

  const validation = useValidation(walletActivityDraft, {
    note: {
      $validations: [
        { $v: required, $error: "A description is required." },
        {
          $v: maxLength(WALLET_NOTE_MAX_LENGTH),
          $error: `Description cannot exceed ${WALLET_NOTE_MAX_LENGTH} characters.`,
        },
      ],
    },
    paymentCorrectionRequest: {
      note: {
        $validations: [
          { $v: required, $error: "A note is required." },
          {
            $v: maxLength(NOTE_MAX_LENGTH),
            $error: `Note cannot exceed ${NOTE_MAX_LENGTH} characters.`,
          },
        ],
      },
      checkPayload: {
        $ignore: walletActivityData?.payment?.method !== "CHECK",
        bankName: { $validations: [{ $v: required, $error: "A bank name is required." }] },
        checkNumber: { $validations: [{ $v: required, $error: "A check number is required." }] },
      },
      currencyAmount: {
        amount: {
          $validations: [
            {
              $v: isRefund ? lessOrEqualTo(0) : greaterOrEqualTo(0),
              $error: "Amount should be greater or equal to 0.",
            },
          ],
        },
      },
      type: { $validations: [{ $v: eq("CHARGE") }] }, // This should never change
      paymentCreatedAt: {
        $ignore:
          walletActivityData?.payment?.method !== "CASH" && walletActivityData?.payment?.method !== "CHECK",
        $validations: [{ $v: required, $error: "A payment date is required." }],
      },
      paymentProfileUuid: {
        $ignore: walletActivityData?.payment?.method !== "STORED_PROFILE",
        $validations: [{ $v: required, $error: "Please select a credit card." }],
      },
      paymentDeviceUuid: {
        $ignore: walletActivityData?.payment?.method !== "STORED_POS",
        $validations: [{ $v: required, $error: "Please select a payment device." }],
      },
      externalPosPayload: {
        $ignore: walletActivityData?.payment?.method !== "EXTERNAL_POS",
        cardLastFour: {
          $validations: [
            { $v: required, $error: "The card's last four digits used for the payment is required." },
          ],
        },
        nameOnCard: {
          $validations: [{ $v: required, $error: "The name on card used for the payment is required." }],
        },
      },
    },
  });

  const handleAmountChange: CurrencyInputProps["onValueChange"] = (value) => {
    if (!value) {
      setNewAmount("");
      setWalletActivityDraft((last) =>
        produce(last, (draft) => {
          delete draft.paymentCorrectionRequest.currencyAmount;
        })
      );

      return;
    }

    // Remove minus sign for refunds
    const dollarAmount = value.replaceAll("-", "");

    setNewAmount(dollarAmount);
    setWalletActivityDraft((last) =>
      produce(last, (draft) => {
        const amount = dollarsToCents(Number(dollarAmount));

        draft.paymentCorrectionRequest.currencyAmount = {
          currency: "USD",
          amount: isRefund ? -amount : amount,
        };
      })
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const handleDateChange = (date: Parameters<ReactDatePickerProps["onChange"]>[0]) => {
    date &&
      setWalletActivityDraft((last) =>
        produce(last, (draft) => {
          draft.paymentCorrectionRequest.paymentCreatedAt = getUnixTime(date);
        })
      );
  };

  const handleCheckPayloadChange = (checkPayload: PaymentCorrectionRequest["checkPayload"]) => {
    setWalletActivityDraft((last) =>
      produce(last, (draft) => {
        draft.paymentCorrectionRequest.checkPayload = checkPayload;
      })
    );
  };

  const handleExternalPosPayloadChange = (
    externalPosPayload: PaymentCorrectionRequest["externalPosPayload"]
  ) => {
    setWalletActivityDraft((last) =>
      produce(last, (draft) => {
        draft.paymentCorrectionRequest.externalPosPayload = externalPosPayload;
      })
    );
  };

  const handleDescriptionChange = (note: NonNullable<WalletEditRequest["note"]>) => {
    setWalletActivityDraft((last) =>
      produce(last, (draft) => {
        draft.note = note;
      })
    );
  };

  const handleNoteChange = (note: NonNullable<PaymentCorrectionRequest["note"]>) => {
    setWalletActivityDraft((last) =>
      produce(last, (draft) => {
        draft.paymentCorrectionRequest.note = note;
      })
    );
  };

  const handlePaymentUuidChange = (paymentUuid: NonNullable<PaymentCorrectionRequest["paymentUuid"]>) => {
    setWalletActivityDraft((last) =>
      produce(last, (draft) => {
        draft.paymentCorrectionRequest.paymentUuid = paymentUuid;
      })
    );
  };

  useSyncOnce((walletActivity) => {
    walletActivity.note && handleDescriptionChange(walletActivity.note);

    if (walletActivity.payment) {
      handlePaymentUuidChange(walletActivity.payment.uuid);

      const date = fromUnixTime(walletActivity.payment.paymentCreatedAt);

      handleDateChange(date);
      handleAmountChange(String(centsToDollars(walletActivity.payment.currencyAmount.amount)));
      walletActivity.payment.note && handleNoteChange(walletActivity.payment.note);
      walletActivity.payment.method === "CHECK" &&
        walletActivity.payment.checkPayload &&
        handleCheckPayloadChange(walletActivity.payment.checkPayload);
      walletActivity.payment.method === "EXTERNAL_POS" &&
        walletActivity.payment.externalPosPayload &&
        handleExternalPosPayloadChange(walletActivity.payment.externalPosPayload);
    }
  }, walletActivityData);

  const queryClient = useQueryClient();

  const handleSave: FormEventHandler = (event) => {
    event.preventDefault();

    const result = validation.validate();

    if (!result.$isValid) {
      return;
    }

    walletActivityData?.id &&
      updatePayment(
        {
          practiceId,
          patientId,
          walletUuid: walletActivityData.walletUuid,
          walletActivityId: walletActivityData.id,
          data: walletActivityDraft,
        },
        {
          onSuccess: ({ data }) => {
            replaceCachedItemWithUpdatedItem(
              queryClient,
              {
                queryKey: [
                  getQueryKey("practices", "getWalletActivityById"),
                  { practiceId, patientId, walletActivityId: data.data.id },
                ],
              },
              data.data
            );
            rest.onSave();
          },
          onError: handleError,
        }
      );
  };

  return {
    ...rest,
    newAmount,
    isRefund,
    walletActivityDraft,
    handleAmountChange,
    handleDateChange,
    handleSave,
    handleCheckPayloadChange,
    handleExternalPosPayloadChange,
    handleDescriptionChange,
    handleNoteChange,
    validation,
  };
};

const FORM_ID = "edit-wallet-payment";

/* istanbul ignore next */
// eslint-disable-next-line complexity
export const EditWalletActivityModal: React.FC<Props> = (props) => {
  const now = useNow();
  const practice = useCurrentPractice();
  const [originalPaymentData, setOriginalPaymentData] = useState<PaymentVO>();
  const [walletActivityQuery, { data: patientData, isInitialLoading: isLoadingPatientData }] = useApiQueries([
    getWalletActivityById({
      args: {
        practiceId: practice.id,
        patientId: props.patientId,
        walletUuid: props.walletUuid,
        walletActivityId: props.walletActivityId,
      },
    }),
    getPatientSummary({ args: { practiceId: practice.id, patientId: props.patientId } }),
  ]);
  const [{ mutate: updatePayment, isLoading: isUpadingPayment }] = useApiMutations([editWalletActivity]);

  useEffect(() => {
    if (originalPaymentData == null && walletActivityQuery.data?.payment != null) {
      setOriginalPaymentData(walletActivityQuery.data.payment);
    }
  }, [originalPaymentData, walletActivityQuery.data?.payment]);

  const {
    onClose,
    newAmount,
    isRefund,
    walletActivityDraft,
    handleAmountChange,
    handleDateChange,
    handleSave,
    handleCheckPayloadChange,
    handleExternalPosPayloadChange,
    handleDescriptionChange,
    handleNoteChange,
    validation,
  } = useEditWalletActivityModal({
    ...props,
    practiceId: practice.id,
    walletActivityData: walletActivityQuery.data,
    updatePayment,
  });

  return (
    <Modal size="sm" title={isRefund ? "Edit Refund" : "Edit Pre-Payment"} onClose={onClose}>
      <ModalContent padding="lg">
        <QueryResult queries={[walletActivityQuery]}>
          <Form id={FORM_ID} className="flex flex-col gap-y-6" onSubmit={handleSave}>
            <div
              className={`
                flex
                bg-greyLightest
                p-2
                items-center
                font-sansSemiBold
                justify-between
                text-xs
              `}
            >
              <div className="flex gap-x-5">
                {walletActivityQuery.isInitialLoading ? (
                  <Skeleton className="w-24" />
                ) : (
                  <DateWithTooltip
                    format="P"
                    date={walletActivityQuery.data?.activityCreatedAt ?? 0}
                    dateAsSeconds={true}
                  />
                )}
                <span>|</span>
                {isLoadingPatientData ? (
                  <Skeleton className="w-24" />
                ) : patientData ? (
                  getPatientDisplayNameFromPatient(now, patientData)
                ) : undefined}
              </div>
            </div>
            <div className="flex gap-x-6 font-sansSemiBold">
              <div>
                <div className="text-xs">
                  {isRefund ? "Original Amount Refunded" : "Original Amount Collected"}
                </div>
                <div className="text-sm">
                  {formatCurrency(Math.abs(originalPaymentData?.currencyAmount.amount ?? 0))}
                </div>
              </div>
              <VerticalDivider size="full" />
              <div>
                <div className="text-xs">Payment Method</div>
                <div className="text-sm">
                  {originalPaymentData && getPaymentMethodToLabel(originalPaymentData)}
                </div>
              </div>
            </div>
            <div className="flex flex-col gap-y-3">
              <div className="text-xs font-sansSemiBold">Edit Payment Details</div>
              <div className="grid grid-cols-3 gap-3">
                <FormFieldCurrencyInput
                  label="New Amount"
                  layout="labelIn"
                  required={true}
                  error={validation.result.paymentCorrectionRequest.currencyAmount.amount.$error}
                  value={newAmount}
                  intlConfig={{ locale: "en-US", currency: "USD" }}
                  onValueChange={handleAmountChange}
                />
                <FormFieldSelectMenusDatepicker
                  label="Payment date"
                  layout="labelIn"
                  className={
                    originalPaymentData
                      ? cx(isOneOf(originalPaymentData.method, ["CHECK", "EXTERNAL_POS"]) && "col-start-1")
                      : undefined
                  }
                  required={true}
                  selected={
                    walletActivityDraft.paymentCorrectionRequest.paymentCreatedAt
                      ? fromUnixTime(walletActivityDraft.paymentCorrectionRequest.paymentCreatedAt)
                      : undefined
                  }
                  maxDate={nowInTimezone(practice.timezoneId)}
                  onChange={handleDateChange}
                />
                {originalPaymentData?.method === "EXTERNAL_POS" ? (
                  <>
                    <FormFieldInput
                      label="Name on card"
                      layout="labelIn"
                      placeholder="e.g. John Doe"
                      value={walletActivityDraft.paymentCorrectionRequest.externalPosPayload?.nameOnCard}
                      onChange={(e) =>
                        handleExternalPosPayloadChange({
                          nameOnCard: e.target.value,
                          cardLastFour:
                            walletActivityDraft.paymentCorrectionRequest.externalPosPayload?.cardLastFour ??
                            "",
                        })
                      }
                    />
                    <FormFieldInput
                      label="Card Number (last 4 digits)"
                      layout="labelIn"
                      placeholder="XXXX"
                      value={walletActivityDraft.paymentCorrectionRequest.externalPosPayload?.cardLastFour}
                      onChange={(e) =>
                        handleExternalPosPayloadChange({
                          cardLastFour: e.target.value,
                          nameOnCard:
                            walletActivityDraft.paymentCorrectionRequest.externalPosPayload?.nameOnCard ?? "",
                        })
                      }
                    />
                  </>
                ) : originalPaymentData?.method === "CHECK" ? (
                  <>
                    <FormFieldInput
                      label="Check number"
                      layout="labelIn"
                      placeholder="XXXXXXXXXXXX"
                      value={walletActivityDraft.paymentCorrectionRequest.checkPayload?.checkNumber}
                      onChange={(e) =>
                        handleCheckPayloadChange({
                          checkNumber: e.target.value,
                          bankName: walletActivityDraft.paymentCorrectionRequest.checkPayload?.bankName ?? "",
                        })
                      }
                    />
                    <FormFieldInput
                      label="Bank name"
                      layout="labelIn"
                      placeholder="e.g. Bank of America"
                      value={walletActivityDraft.paymentCorrectionRequest.checkPayload?.bankName}
                      onChange={(e) =>
                        handleCheckPayloadChange({
                          bankName: e.target.value,
                          checkNumber:
                            walletActivityDraft.paymentCorrectionRequest.checkPayload?.checkNumber ?? "",
                        })
                      }
                    />
                  </>
                ) : null}
              </div>
            </div>
            <div>
              <PaymentDescription
                value={walletActivityDraft.note}
                error={validation.result.note.$error}
                onChange={(e) => handleDescriptionChange(e.target.value)}
              />
            </div>
            <div>
              <Note
                required
                value={walletActivityDraft.paymentCorrectionRequest.note}
                onChange={(e) => handleNoteChange(e.target.value)}
                error={validation.result.paymentCorrectionRequest.note.$error}
              />
            </div>
          </Form>
        </QueryResult>
      </ModalContent>
      <ModalFooter>
        <AsyncButton isLoading={isUpadingPayment} className="min-w-button" type="submit" form={FORM_ID}>
          Save
        </AsyncButton>
      </ModalFooter>
    </Modal>
  );
};
