import { TreatmentPlanVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as Edit } from "@libs/assets/icons/edit.svg";
import { ReactComponent as MoveRight } from "@libs/assets/icons/move-right.svg";
import { ReactComponent as Archive } from "@libs/assets/icons/archive-folder.svg";
import { ReactComponent as Active } from "@libs/assets/icons/active.svg";
import { ReactComponent as Inactive } from "@libs/assets/icons/inactive.svg";
import { EditTreatmentPlanNameModal } from "components/Charting/EditTreatmentPlanNameModal";

interface Props {
  treatmentPlan: TreatmentPlanVO;
  onSaveTreatmentPlanName: (treatmentPlan: TreatmentPlanVO, draftPlanName: string) => void;

  onTreatmentPlanStateChange: (newState: TreatmentPlanVO["state"]) => void;
}

export const TreatmentPlanMenu: React.FC<Props> = ({
  treatmentPlan,
  onTreatmentPlanStateChange,
  onSaveTreatmentPlanName,
}) => {
  const editingPlanName = useBoolean(false);
  const handleNameChange = (newName: string) => {
    editingPlanName.off();
    onSaveTreatmentPlanName(treatmentPlan, newName);
  };

  return (
    <>
      <div className="flex items-center gap-x-6 pt-0.5">
        {treatmentPlan.state === "ACTIVE" ? (
          <ButtonIcon
            SvgIcon={Active}
            tooltip={{
              theme: "SMALL",
              content: "Set plan as inactive",
            }}
            theme="primary"
            onClick={() => onTreatmentPlanStateChange("INACTIVE")}
          />
        ) : (
          <ButtonIcon
            SvgIcon={Inactive}
            disabled={treatmentPlan.state === "ARCHIVED"}
            tooltip={{
              theme: "SMALL",
              content:
                treatmentPlan.state === "ARCHIVED"
                  ? "An achived plan cannot be the active plan"
                  : "Set plan as active",
            }}
            theme="primary"
            onClick={() => onTreatmentPlanStateChange("ACTIVE")}
          />
        )}

        <ButtonIcon
          SvgIcon={Edit}
          tooltip={{
            theme: "SMALL",
            content: "Edit plan name",
          }}
          theme="primary"
          onClick={editingPlanName.on}
        />
        {treatmentPlan.state === "ARCHIVED" ? (
          <ButtonIcon
            SvgIcon={MoveRight}
            tooltip={{
              theme: "SMALL",
              content: "Move to alternate plan",
            }}
            theme="primary"
            onClick={() => onTreatmentPlanStateChange("INACTIVE")}
          />
        ) : (
          <ButtonIcon
            SvgIcon={Archive}
            tooltip={{
              theme: "SMALL",
              content: "Archive plan",
            }}
            theme="primary"
            onClick={() => onTreatmentPlanStateChange("ARCHIVED")}
          />
        )}
      </div>
      {editingPlanName.isOn ? (
        <EditTreatmentPlanNameModal
          onRequestClose={editingPlanName.off}
          treatmentPlanName={treatmentPlan.name}
          onSaveTreatmentPlanName={handleNameChange}
        />
      ) : null}
    </>
  );
};
