import { FC } from "react";
import { AutomationActionVO, DentalProcedureVO, EmailConfigVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { ChannelsFields } from "components/Communications/Automations/ChannelsFields";
import { DraftAutomationAction } from "components/Communications/Automations/types";
import { ProcedureSelector } from "components/ProcedureSelector/ProcedureSelector";
import { WhenOrAfterTimeFields } from "components/Communications/Automations/WhenOrAfterTimeFields";
import { TimingField } from "components/Communications/Automations/TimingField";
import { AutomationActionValidation } from "components/Communications/Automations/utils";
import { ToggleButtonList } from "components/UI/ToggleButtonList";
import { TooltipLabel } from "components/UI/TooltipLabel";
import { FormFieldSelect } from "components/UI/FormFieldSelect";

interface Props {
  emailConfig: EmailConfigVO;
  action: DraftAutomationAction;
  dentalProcedures: DentalProcedureVO[];
  validation: AutomationActionValidation;
  onUpdate: (updates: Partial<DraftAutomationAction>) => void;
}

const eventOptions: { label: string; value: AutomationActionVO["event"] }[] = [
  {
    label: "Appointment Completed",
    value: "APPOINTMENT_COMPLETED",
  },
];

const proceduresContainsOptions: {
  label: string;
  description: string;
  value: NonNullable<AutomationActionVO["dentalProcedures"]>["contains"];
}[] = [
  {
    label: "Contains Any",
    description: "Will send if any of the selected procedures were performed",
    value: "ANY",
  },
  {
    label: "Contains All",
    description: "Will send if at least all the selected procedures were performed",
    value: "ALL",
  },
  {
    label: "Exactly Matches",
    description: "Will send if the procedures exactly match those that were performed",
    value: "EXACT",
  },
];

export const AutomationActionPostAppointmentForm: FC<Props> = ({
  action,
  dentalProcedures,
  validation,
  emailConfig,
  onUpdate,
}) => {
  return (
    <>
      <div>
        <ToggleButtonList
          type="radio"
          shape="mediumPill"
          onChange={(_, option) => onUpdate({ event: option.value })}
          selectedValue={action.event}
          options={eventOptions}
          label="Event"
          required
        />
      </div>
      <TimingField validation={validation}>
        <WhenOrAfterTimeFields validation={validation} action={action} onUpdate={onUpdate} />
      </TimingField>
      <ChannelsFields
        emailConfig={emailConfig}
        channels={action.channels}
        onChannelsChange={(value) => onUpdate({ channels: value })}
      />

      <div>
        <FormFieldLabel
          className="text-xs font-sansSemiBold"
          content={
            <TooltipLabel
              tooltip={{
                content:
                  "If two messages are due to send at the same time, only the message with procedures specified will be sent.",
              }}
            >
              Appointment Procedures
            </TooltipLabel>
          }
        />

        <div className="text-xs pt-1 pb-2 text-slate-700 tracking-[-0.005em]">
          If you would like this message to only send for appointments with specific procedures, specify that
          here.
        </div>
        <div className="flex gap-x-2">
          <FormFieldSelect
            className="flex-none w-40"
            value={action.dentalProcedures?.contains}
            isClearable={false}
            isSearchable={false}
            onItemSelected={(option) =>
              onUpdate({
                dentalProcedures: { contains: option, procedures: action.dentalProcedures?.procedures ?? [] },
              })
            }
            formatOptionLabel={(option, meta) => {
              if (meta.context === "value") {
                return option.label;
              }

              return (
                <div className="font-sans font-normal">
                  <div
                    className={cx(
                      "text-sm",
                      option.value === action.dentalProcedures?.contains && "text-primaryTheme"
                    )}
                  >
                    {option.label}
                  </div>
                  <div className="text-xs text-gray-700">{option.description}</div>
                </div>
              );
            }}
            options={proceduresContainsOptions}
          />
          <ProcedureSelector
            className="flex-1"
            aria-label="Procedures"
            values={action.dentalProcedures?.procedures}
            procedures={dentalProcedures}
            onChange={(newValues) =>
              onUpdate({
                dentalProcedures: { contains: action.dentalProcedures?.contains, procedures: newValues },
              })
            }
          />
        </div>
      </div>
    </>
  );
};
