import { FC, useId, useState } from "react";
import { TemplateVariableGroupVO } from "@libs/api/generated-api";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { TemplateVariablesEditor } from "components/UI/RichTextEditor/TemplateVariablesEditor";
import { handleError } from "utils/handleError";
import {
  templateStringToTemplateVariableNodes,
  templateVariablesToString,
} from "components/UI/RichTextEditor/templateVariables";

const config = {
  editable: true,
  namespace: "EmailMessageEditor",
  onError: handleError,
};

interface Props {
  emailTemplate: string | undefined;
  templateVariables: TemplateVariableGroupVO[];
  onTemplateChange: (emailTemplate: string) => void;
  error: string | undefined;
}

export const EmailMessageEditor: FC<Props> = ({
  emailTemplate,
  templateVariables,
  onTemplateChange,
  error,
}) => {
  const [convertedMessage] = useState(() =>
    emailTemplate
      ? JSON.stringify(templateStringToTemplateVariableNodes(emailTemplate, templateVariables))
      : emailTemplate
  );
  const errorId = useId();

  return (
    <>
      <TemplateVariablesEditor
        initialConfig={config}
        ariaLabel="Message"
        aria-invalid={error ? "true" : undefined}
        aria-errormessage={error ? errorId : undefined}
        templateVariables={templateVariables}
        placeholder="Type your message here..."
        initialEditorState={convertedMessage}
        className="h-full"
        onChange={({ content }) => onTemplateChange(templateVariablesToString(content))}
      />
      {error ? (
        <div className="mt-1 max-w-xl w-full">
          <FormFieldError id={errorId}>{error}</FormFieldError>
        </div>
      ) : null}
    </>
  );
};
