import { FC } from "react";

import { Spinner } from "@libs/components/UI/Spinner";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent } from "@libs/components/UI/ModalComponents";

export const PreAuthLoadingModal: FC = () => {
  return (
    <Modal size="fit">
      <ModalContent className="p-11 flex flex-col items-center justify-center">
        <p className="text-center text-base font-sansSemiBold mb-8">Preparing Pre-Auth claim</p>
        <Spinner animation="border" size="panel" variant="primary" />
      </ModalContent>
    </Modal>
  );
};
