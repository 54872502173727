import { FC, useId } from "react";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as CaretDoubleRight } from "@libs/assets/icons/double-right-chevron.svg";

import { FormFieldSelect } from "components/UI/FormFieldSelect";

interface Props {
  totalPages: number;
  totalElements: number;
  page: number;
  pageSize: number;
  onPageSizeChange: (newSize: number) => void;
  onPageChange: (page: number) => void;
}

const options = [
  { value: 10, label: "10" },
  { value: 20, label: "20" },
  { value: 25, label: "25" },
  { value: 30, label: "30" },
  { value: 50, label: "50" },
];

const PAGE_BUTTON_COUNT = 5;

const getPageButtons = (page: number, totalPages: number) => {
  const modulo = page % PAGE_BUTTON_COUNT;
  const start = modulo === 0 ? page - (PAGE_BUTTON_COUNT - 1) : page - (modulo - 1);
  const count = Math.min(PAGE_BUTTON_COUNT, totalPages - (start - 1));

  return Array.from({ length: count }, (_, index) => start + index);
};

export const TablePager: FC<Props> = ({
  page,
  pageSize,
  totalElements,
  totalPages,
  onPageChange,
  onPageSizeChange,
}) => {
  const pageStart = (page - 1) * pageSize + 1;
  const pageEnd = Math.min(pageStart + pageSize - 1, totalElements);

  const pageButtons = getPageButtons(page, totalPages);
  const labelId = useId();

  return (
    <div className="justify-between items-center flex text-xs">
      <div className="flex items-center flex-none w-60">
        <div id={labelId} className="mr-2">
          Results per page
        </div>
        <FormFieldSelect
          value={pageSize}
          aria-labelledby={labelId}
          options={options}
          isSearchable={false}
          isClearable={false}
          menuPlacement="top"
          onItemSelected={onPageSizeChange}
        />
      </div>
      <div>
        {pageStart === pageEnd
          ? `${pageStart} of ${totalElements}`
          : `${pageStart}-${pageEnd} of ${totalElements}`}
      </div>
      <div className="flex items-center justify-end text-sm flex-none w-60">
        <ButtonIcon
          onClick={() => onPageChange(page - 1)}
          SvgIcon={CaretDoubleRight}
          disabled={page === 1}
          theme="primary"
          size="sm"
          className="rotate-180 mr-4"
        />
        <div className="flex items-center">
          {pageButtons.map((pageNumber) => (
            <Button
              key={pageNumber}
              size="custom"
              theme={page === pageNumber ? "secondary" : "tertiary"}
              className="h-8 w-8 first:ml-0 ml-1"
              onClick={() => onPageChange(pageNumber)}
            >
              {pageNumber}
            </Button>
          ))}
        </div>
        <ButtonIcon
          onClick={() => onPageChange(page + 1)}
          SvgIcon={CaretDoubleRight}
          disabled={page === totalPages}
          size="sm"
          className="ml-4"
          theme="primary"
        />
      </div>
    </div>
  );
};
