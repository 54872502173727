import { ChangeEvent, FC } from "react";
import { EmployeeRolesVO, EmployeeVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatPhoneNumber } from "@libs/utils/phone";
import { ReactComponent as Clock } from "@libs/assets/icons/clock.svg";
import { ReactComponent as IsOwnerIcon } from "@libs/assets/icons/system-administrator-male.svg";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { CheckboxCell, EMPTY_CELL, LinkCell, Row } from "@libs/components/UI/GridTableComponents";
import { paths } from "utils/routing/paths";
import { labelForEmployeeStatus } from "components/Employee/utils";
import { Avatar } from "components/UI/Avatar";
import { findRoleById } from "components/Roles/roleUtils";

export const EmployeeRow: FC<{
  selectedRows: Set<number>;
  employee: EmployeeVO;
  roles: EmployeeRolesVO[];
  onToggleRow: (e: ChangeEvent<HTMLInputElement>) => void;
  currentUrl: string;
}> = ({ selectedRows, employee, roles, onToggleRow, currentUrl }) => {
  const profile = paths.employee({ id: employee.id }, { from: currentUrl });
  const roleName = findRoleById(employee.roleV2Id, roles)?.role.name;

  const commonProps = {
    to: profile,
    borderColor: "border-slate-200" as const,
    verticalPadding: "slim" as const,
  };

  return (
    <Row isSelected={selectedRows.has(employee.id)}>
      <CheckboxCell
        styleOptions={{ verticalPadding: "skinny", borderColor: "border-slate-200" }}
        checked={selectedRows.has(employee.id)}
        value={employee.id}
        onChange={onToggleRow}
      />
      <LinkCell {...commonProps}>
        <div className="flex items-center min-w-0 flex-nowrap">
          <Avatar
            imageUrl={employee.personalDetails.thumbnail}
            name={employee.personalDetails.displayName}
            size="sm"
          />
          <div className="truncate flex-1 ml-2">{employee.personalDetails.displayName}</div>
        </div>
      </LinkCell>

      <LinkCell {...commonProps}>{labelForEmployeeStatus(employee.employmentDetails.status)}</LinkCell>
      <LinkCell {...commonProps} className="flex items-center">
        {employee.employmentDetails.jobTitle ? (
          <>
            {employee.employmentDetails.jobTitle}
            {employee.isOwner ? <IsOwnerIcon className="text-greyMedium w-4 h-4 ml-1" /> : null}
          </>
        ) : (
          "-"
        )}
      </LinkCell>
      <LinkCell {...commonProps}>
        <FloatingTooltip content={roleName} theme="SMALL">
          <div className="truncate">{roleName}</div>
        </FloatingTooltip>
      </LinkCell>
      <LinkCell {...commonProps}>
        {employee.contactDetails.phone ? formatPhoneNumber(employee.contactDetails.phone) : EMPTY_CELL}
      </LinkCell>
      <LinkCell {...commonProps} className="flex items-center">
        {employee.worktimeDetails?.time ? (
          <>
            <Clock
              className={cx(
                "w-4 h-4 mr-2",
                employee.worktimeDetails.lastAction === "CLOCK_OUT"
                  ? "text-red"
                  : employee.worktimeDetails.lastAction === "CLOCK_IN"
                    ? "text-green"
                    : "text-greyLighter"
              )}
            />
            {employee.worktimeDetails.time}
          </>
        ) : (
          EMPTY_CELL
        )}
      </LinkCell>
    </Row>
  );
};
