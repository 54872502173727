import { QRCode, IProps as QRCodeProps } from "react-qrcode-logo";
import designConfig from "@libs/design.config";

const QR_RADIUS = 2;
const EYE_RADII: QRCodeProps["eyeRadius"] = [
  {
    inner: [QR_RADIUS, QR_RADIUS, 0, QR_RADIUS],
    outer: [QR_RADIUS, QR_RADIUS, 0, QR_RADIUS],
  },
  {
    inner: [QR_RADIUS, QR_RADIUS, QR_RADIUS, 0],
    outer: [QR_RADIUS, QR_RADIUS, QR_RADIUS, 0],
  },
  {
    inner: [QR_RADIUS, 0, QR_RADIUS, QR_RADIUS],
    outer: [QR_RADIUS, 0, QR_RADIUS, QR_RADIUS],
  },
];

export const ArchyQRCode: React.FC<QRCodeProps> = (props) => {
  return (
    <QRCode
      qrStyle="dots"
      fgColor={designConfig.colors.secondaryTheme}
      eyeColor={{
        inner: designConfig.colors.secondaryTheme,
        outer: designConfig.colors.primaryTheme,
      }}
      eyeRadius={EYE_RADII}
      {...props}
    />
  );
};
