import React from "react";
import { BarStackData } from "components/Dashboard/Charting/types";

export const useTimeseriesPreviousData = <T extends string>({
  isLoading,
  data,
}: {
  data: BarStackData<T>[];
  isLoading: boolean;
}) => {
  //We store last data, to be able to represent the last state while loading new one
  const prevDataRef = React.useRef<BarStackData<T>[]>();

  React.useEffect(() => {
    if (!isLoading) {
      prevDataRef.current = data;
    }
  }, [data, isLoading]);

  return {
    data: isLoading ? prevDataRef.current ?? data : data,
    isFirstLoad: isLoading && !prevDataRef.current,
  };
};
