/* eslint-disable @typescript-eslint/naming-convention */
import { produce } from "immer";
import {
  GenerateMountRequest,
  MedicalImageVO,
  PearlAnalysisRequest,
  PearlImageResponseGetEndpoints,
  TransferImagesRequest,
  TransferMountRequest,
  UpdateMountRequest,
  UploadMedicalImageV2Request,
} from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";
import { makeMutation } from "@libs/utils/mutations";
import { updateCachedData } from "@libs/utils/queryCache";
import { QueryClient } from "@tanstack/react-query";
import { updateCachedImage, updateMountCacheEntry } from "api/imaging/cache";
import { sortImagesByIndex } from "components/PatientProfile/Imaging/MountRoute/image-utils";

export const generateMount = makeMutation({
  mutationKey: ["practices", "generateMount"],
  formatParams: (args: { practiceId: number; patientId: number; data: GenerateMountRequest }) => [
    args.practiceId,
    args.patientId,
    args.data,
  ],
  mutationOptions: () => ({
    onSuccess: () => {
      // DO NOT invalidate queries.  Mount list is not edited until an image has been added to it
      // See uploadMedicalImage mutation
    },
  }),
});

export const updateMount = makeMutation({
  mutationKey: ["practices", "updateMount"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    mountId: number;
    invalidateCache?: boolean;
    data: UpdateMountRequest;
  }) => [args.practiceId, args.patientId, args.mountId, args.data],
  mutationOptions: (queryClient) => ({
    onMutate: (params) => {
      updateMountCacheEntry(queryClient, params, (data) => ({
        ...data,
        images: params.data.images ?? data.images,
        name: params.data.name ?? data.name,
        layout: params.data.layout ?? data.layout,
      }));
    },
    onSuccess: (_, params) => {
      // Do not update on success, as user may have already queued more changes (see onMutate)

      if (params.invalidateCache) {
        queryClient.invalidateQueries(
          [getQueryKey("practices", "getMountList"), { patientId: params.patientId }],
          undefined,
          { cancelRefetch: true }
        );
      }
    },
  }),
});

const imageTypeToName = (type: Required<MedicalImageVO>["type"]) => {
  if (type === "X_RAY") {
    return "X-Ray";
  }

  if (type === "PHOTO") {
    return "Photo";
  }

  return "External";
};

export const uploadMedicalImageV2 = makeMutation({
  mutationKey: ["practices", "uploadMedicalImageV2"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    mountId: number;
    data: UploadMedicalImageV2Request;
  }) => [args.practiceId, args.patientId, args.mountId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId, patientId, mountId }) => {
      updateMountCacheEntry(queryClient, { patientId, practiceId, mountId }, (mount) => {
        // Update the mount list types so we don't have to fetch it again.
        // Since multiple uploads can be happening simultaneously, we don't want to issue
        // tons of mount list queries when we can just handle it here.
        const image = data.data.data;
        const types = mount.types ?? [];
        const textType = imageTypeToName(image.type!);
        const existingEntry = types.find((type) => type.includes(textType));

        if (existingEntry) {
          const count = Number.parseInt(existingEntry.split(" ")[0]);

          // Don't pluralize External image types.
          types[types.indexOf(existingEntry)] = `${count + 1} ${textType}${
            image.type === "EXTERNAL" ? "" : "s"
          }`;
        } else {
          types.push(`1 ${textType}`);
        }

        return {
          ...mount,
          types,
          images: [...(mount.images ?? []), image].sort(sortImagesByIndex),
        };
      });
    },
  }),
});

export const updateMedicalImageDetails = makeMutation({
  mutationKey: ["practices", "updateMedicalImageDetails"],
  formatParams: (args: {
    mountId?: number;
    practiceId: number;
    patientId: number;
    imageId: number;
    data: MedicalImageVO;
  }) => [args.practiceId, args.patientId, args.imageId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, patientId, imageId, mountId, data }) => {
      const updates = produce(response.data.data, (draft) => {
        delete draft.filters;
        delete draft.transforms;
        delete draft.annotation;

        // When removing teeth, the server returns it as `undefined` vs. empty array
        draft.teeth = data.teeth;
      });

      updateCachedImage(
        queryClient,
        {
          patientId,
          practiceId,
          imageId,
          mountId,
        },
        updates
      );
    },
  }),
});

export const fetchMountWithId = makeMutation({
  mutationKey: ["practices", "getMount"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    mountId: number;
    query?: { includeArchived?: boolean };
  }) => [args.practiceId, args.patientId, args.mountId, args.query],
});

export const transferMount = makeMutation({
  mutationKey: ["practices", "transferMount"],
  formatParams: (args: { practiceId: number; data: TransferMountRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { data }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getMountList"),
        { patientId: data.sourcePatientId },
      ]);

      if (data.targetPatientId !== data.sourcePatientId) {
        queryClient.invalidateQueries([
          getQueryKey("practices", "getMountList"),
          { patientId: data.targetPatientId },
        ]);
      }
    },
  }),
});

export const transferImages = makeMutation({
  mutationKey: ["practices", "transferImages"],
  formatParams: (args: { practiceId: number; data: TransferImagesRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { data }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getMountList"),
        { patientId: data.sourcePatientId },
      ]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getMount"),
        { patientId: data.sourcePatientId, mountId: data.sourceMountId },
      ]);

      if (data.targetPatientId === data.sourcePatientId) {
        queryClient.invalidateQueries([
          getQueryKey("practices", "getMount"),
          { patientId: data.sourcePatientId, mountId: data.targetMountId },
        ]);
      } else {
        queryClient.invalidateQueries([
          getQueryKey("practices", "getMountList"),
          { patientId: data.targetPatientId },
        ]);
        queryClient.invalidateQueries([
          getQueryKey("practices", "getMount"),
          { patientId: data.targetPatientId, mountId: data.targetMountId },
        ]);
      }
    },
  }),
});

export const analyzeImagesWithPearl = makeMutation({
  mutationKey: ["practices", "analyzeImagesWithPearl"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    data: PearlAnalysisRequest;
    mountId?: number;
  }) => [args.practiceId, args.patientId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId, patientId, mountId }) => {
      for (const { medicalImageId, errorMessage } of data.data.data.medicalImageRequestStatuses) {
        updateCachedImage(
          queryClient,
          {
            patientId,
            practiceId,
            imageId: medicalImageId,
            mountId,
          },
          {
            pearlAnalysis: {
              conditions: [],
              status: "SUBMITTED",
              errorMessage,
              rotationDegrees: 0,
              mirror: [],
            },
          }
        );
      }
    },
  }),
});

const updatePearlCache = ({
  annotationId,
  queryClient,
  isAccepted,
  practiceId,
  patientId,
  imageId,
}: {
  annotationId: string;
  isAccepted?: boolean;
  queryClient: QueryClient;
  practiceId: number;
  patientId: number;
  imageId: number;
}) => {
  updateCachedData<PearlImageResponseGetEndpoints>(
    queryClient,
    {
      queryKey: [
        getQueryKey("practices", "getPearlAnalysisForImage"),
        {
          practiceId,
          patientId,
          imageId,
        },
      ],
      exact: true,
    },
    (analysis) => {
      return {
        ...analysis,
        result: {
          ...analysis.result,
          annotations:
            analysis.result?.annotations?.map((annotation) => {
              if (annotation.enml_annotation_id === annotationId) {
                return {
                  ...annotation,
                  is_accepted: isAccepted,
                  is_dismissed: !isAccepted,
                };
              }

              return annotation;
            }) ?? [],
        },
      };
    }
  );
};

export const acceptPearlAnnotation = makeMutation({
  mutationKey: ["practices", "acceptPearlAnnotation"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    imageId: number;
    annotationId: string;
    mountId?: number;
  }) => [args.practiceId, args.patientId, args.imageId, args.annotationId],
  mutationOptions: (queryClient) => ({
    onMutate: ({ practiceId, patientId, imageId, annotationId, mountId }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getMedicalImageDetails"),
        { practiceId, patientId, imageId },
      ]);

      if (mountId) {
        queryClient.invalidateQueries([
          getQueryKey("practices", "getMount"),
          { practiceId, patientId, mountId },
        ]);
      }

      updatePearlCache({
        queryClient,
        annotationId,
        isAccepted: true,
        practiceId,
        patientId,
        imageId,
      });
    },
  }),
});

export const dismissPearlAnnotation = makeMutation({
  mutationKey: ["practices", "dismissPearlAnnotation"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    imageId: number;
    annotationId: string;
    mountId?: number;
  }) => [args.practiceId, args.patientId, args.imageId, args.annotationId],
  mutationOptions: (queryClient) => ({
    onMutate: ({ practiceId, patientId, imageId, annotationId, mountId }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getMedicalImageDetails"),
        { practiceId, patientId, imageId },
      ]);

      if (mountId) {
        queryClient.invalidateQueries([
          getQueryKey("practices", "getMount"),
          { practiceId, patientId, mountId },
        ]);
      }

      updatePearlCache({
        queryClient,
        annotationId,
        isAccepted: false,
        practiceId,
        patientId,
        imageId,
      });
    },
  }),
});
