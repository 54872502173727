import React from "react";
import { cx } from "@libs/utils/cx";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon } from "@libs/components/UI/Icon";
import { FormFieldContainer } from "@libs/components/UI/FormFieldContainer";
import { ReactComponent as RightCaret } from "@libs/assets/icons/right-caret.svg";
import { ReactComponent as LeftCaret } from "@libs/assets/icons/left-caret.svg";
import { ReactComponent as Calendar } from "@libs/assets/icons/calendar.svg";

interface ReactDatePickerNavProps {
  onNextClick?: Func;
  onPrevClick?: Func;
  children: React.ReactNode;
  className?: string;
}

const cxStyles = {
  navButton: `
    aspect-square
    bg-white
    border-greyLighter
    border
    h-9
    enabled:hover:border-primaryTheme
    hover:relative
  `,
};

export const DatePickerNavButtons: React.FC<Omit<ReactDatePickerNavProps, "children">> = ({
  onPrevClick,
  onNextClick,
  className,
}) => {
  return (
    <div className={cx("flex", className)}>
      <ButtonIcon
        className={cx("rounded-l", cxStyles.navButton)}
        theme="primary"
        onClick={onPrevClick}
        disabled={!onPrevClick}
        SvgIcon={LeftCaret}
      />
      <ButtonIcon
        className={cx("-ml-px rounded-r", cxStyles.navButton)}
        theme="primary"
        onClick={onNextClick}
        disabled={!onNextClick}
        SvgIcon={RightCaret}
      />
    </div>
  );
};

export const ReactDatePickerNav: React.FC<ReactDatePickerNavProps> = ({
  onNextClick,
  onPrevClick,
  children,
  className,
}) => {
  return (
    <div className="flex items-center gap-x-2">
      <FormFieldContainer className={cx("flex items-stretch h-9 relative", className)}>
        {children}
      </FormFieldContainer>
      <DatePickerNavButtons onNextClick={onNextClick} onPrevClick={onPrevClick} />
    </div>
  );
};

interface ReactDatePickerNavInputProps {
  onClick?: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onFormatValue: (value?: string) => string;
  value?: string;
  className?: string;
}

export const ReactDatePickerNavInput = React.forwardRef<HTMLButtonElement, ReactDatePickerNavInputProps>(
  ({ value, onClick, onFormatValue, className }, ref) => {
    return (
      <button
        type="button"
        className={cx(
          `flex
           text-sm
           font-sansSemiBold
           items-center
           justify-center
           cursor-pointer`,
          className
        )}
        onClick={onClick}
        ref={ref}
      >
        <div className="flex items-center justify-center">
          <div className="whitespace-nowrap">{onFormatValue(value)}</div>
          <Icon SvgIcon={Calendar} theme="primary" className="ml-2" />
        </div>
      </button>
    );
  }
);
