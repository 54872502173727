import { FC, useMemo } from "react";
import { RecurringScheduleVO } from "@libs/api/generated-api";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { getRepeatMonthOptions } from "components/ScheduleAppointments/getRecurrenceOptions";

interface Props {
  recurrenceType: RecurringScheduleVO["recurrenceType"];
  date: string;
  onChange: (newRecurrence: RecurringScheduleVO) => void;
}

export const BlockMonthRepeatSelectMenu: FC<Props> = ({ recurrenceType, date, onChange }) => {
  const monthlyRepeatOptions = useMemo(() => getRepeatMonthOptions(date), [date]);

  const monthlyRecurrence = useMemo(() => {
    return (
      monthlyRepeatOptions.find((op) => op.recurrence?.recurrenceType === recurrenceType)?.value ??
      "MONTHLY_ON"
    );
  }, [monthlyRepeatOptions, recurrenceType]);

  return (
    <FormFieldSelect
      required
      label="Repeats on"
      isClearable={false}
      isSearchable={false}
      onChange={(newValue) => {
        const newRecurrence = newValue?.recurrence;

        if (newRecurrence) {
          onChange(newRecurrence);
        }
      }}
      value={monthlyRecurrence}
      options={monthlyRepeatOptions}
    />
  );
};
