import { FC, useMemo, useRef } from "react";
import { produce } from "immer";
import {
  FormBooleanConditionalElementRequest,
  FormBooleanInputElementRequest,
} from "@libs/api/generated-api";
import { required } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import { toggleSet } from "@libs/utils/toggleSet";
import { useObjectState } from "@libs/hooks/useObjectState";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormWrapper } from "components/Settings/Forms/FormWrapper";
import { EditElementFormProps } from "components/Settings/Forms/types";
import { InfoCheckbox } from "components/Settings/Forms/InfoCheckbox";
import { FormFieldSelect } from "components/UI/FormFieldSelect";

import { useYesNoAdditionalForm } from "components/Settings/Forms/YesNo/useYesNoAdditionalForms";
import { RequiredCheckbox } from "components/Settings/Forms/RequiredCheckbox";
import { ElementIcon } from "components/Settings/Forms/ElementIcon";
import { PaletteMap, getFormElement } from "components/Settings/Forms/utils";
import { ElementSummary } from "components/Settings/Forms/ElementSummary";
import {
  MultipleChoiceFormState,
  cleanMultipleChoiceFormState,
  filterEmptyOptions,
} from "components/Settings/Forms/MultipleChoice/schema";
import { DateFormContent } from "components/Settings/Forms/Date/FormContent";
import { MultipleChoiceFormContent } from "components/Settings/Forms/MultipleChoice/FormContent";
import { NumberFormContent } from "components/Settings/Forms/Number/FormContent";
import { TextInputFormContent } from "components/Settings/Forms/Text/FormContent";

const getSchema = ({ hasAdditionalQuestion }: { hasAdditionalQuestion: boolean }) => ({
  title: [{ $v: required, $error: "A title is required." }],
  conditionalElement: [
    { $v: required, $error: "An additional question type is required.", $ignore: !hasAdditionalQuestion },
  ],
});

export const YesNoForm: FC<EditElementFormProps<FormBooleanInputElementRequest>> = ({
  element,
  isEditing,
  formType,
  onInvalid,
  onSecondaryClick,
  onDraftChange,
  onSubmit,
}) => {
  const lastConditionalTypeRef = useRef<FormBooleanConditionalElementRequest["type"] | undefined>(
    element.conditionalElement?.type
  );
  const [draft, updateDraft, setDraft] = useObjectState(element);

  const additionalForm = useYesNoAdditionalForm(draft.conditionalElement);

  const validation = useValidation(
    draft,
    getSchema({ hasAdditionalQuestion: Boolean(draft.conditionalElement) })
  );

  const additionalState = additionalForm?.formState[0];

  const cleanedData = useMemo(() => {
    if (!draft.conditionalElement) {
      return draft;
    }

    const conditionalElement =
      additionalState?.draft.type === "SELECT_INPUT"
        ? cleanMultipleChoiceFormState(additionalState as MultipleChoiceFormState)
        : additionalState?.draft;

    return {
      ...draft,
      conditionalElement,
    };
  }, [draft, additionalState]);

  const handleSubmit = () => {
    const additionalValidation = additionalForm?.validation;
    const conditionalElement = cleanedData.conditionalElement;

    onSubmit(
      conditionalElement?.type === "SELECT_INPUT"
        ? { ...cleanedData, conditionalElement: filterEmptyOptions(conditionalElement) }
        : cleanedData
    );

    validation.reset();

    if (additionalValidation) {
      additionalValidation.reset();
    }
  };

  const handleToggleSetting = (value: ListItem<typeof element.settings>) => {
    setDraft((last) => ({ ...last, settings: [...toggleSet(new Set(last.settings), value)] }));
  };

  return (
    <FormWrapper
      element={cleanedData}
      isEditing={isEditing}
      onSubmit={handleSubmit}
      onCancel={onSecondaryClick}
      onDraftChange={onDraftChange}
      onInvalid={onInvalid}
      onValidate={() => {
        const additionalValidation = additionalForm ? additionalForm.validation.validate().$isValid : true;

        return validation.validate().$isValid && additionalValidation;
      }}
    >
      <div className="flex flex-col gap-y-4">
        <FormFieldInput
          id="title"
          label="Question"
          edit={!draft.tag}
          required={true}
          error={validation.result.title.$error}
          value={draft.title}
          onChange={(e) => updateDraft({ title: e.target.value })}
        />
        {formType === "INTAKE" ? (
          <RequiredCheckbox
            checked={draft.settings.includes("REQUIRED")}
            onChange={() => handleToggleSetting("REQUIRED")}
          />
        ) : null}
        <InfoCheckbox
          checked={Boolean(draft.conditionalElement)}
          onChange={() => {
            setDraft((last) =>
              produce(last, (mutable) => {
                if (mutable.conditionalElement) {
                  delete mutable.conditionalElement;
                } else {
                  mutable.conditionalElement = getFormElement(lastConditionalTypeRef.current ?? "TEXT_INPUT");
                }
              })
            );
          }}
          disabled={Boolean(draft.tag)}
          tooltip="If the response is Yes, an additional field will be presented."
          label="Request additional information if yes"
        />
        {draft.conditionalElement ? (
          <>
            <FormFieldSelect
              label="Additional Field Type"
              required={true}
              edit={!draft.tag}
              display="label"
              value={draft.conditionalElement.type}
              formatOptionLabel={(option, meta) => {
                if (meta.context === "value") {
                  return option.label;
                }

                const palette = PaletteMap[option.value];

                return (
                  <div className="flex items-center gap-x-5">
                    <ElementIcon Icon={palette.Icon} />
                    <ElementSummary
                      title={palette.title}
                      description={palette.description}
                      className="flex-1"
                    />
                  </div>
                );
              }}
              options={[
                {
                  value: "DATE_INPUT",
                  label: "Date",
                },
                {
                  value: "SELECT_INPUT",
                  label: "Multiple Choice",
                },
                {
                  value: "NUMBER_INPUT",
                  label: "Number",
                },
                {
                  value: "TEXT_INPUT",
                  label: "Text",
                },
              ]}
              isClearable={false}
              isSearchable={false}
              onItemSelected={(value) => {
                lastConditionalTypeRef.current = value;
                updateDraft({
                  conditionalElement: getFormElement(value),
                });
              }}
            />
            {additionalForm?.type === "DATE_INPUT" ? (
              <DateFormContent
                formState={additionalForm.formState}
                validation={additionalForm.validation.result}
                isLocked={Boolean(draft.tag)}
                formType={formType}
              />
            ) : additionalForm?.type === "NUMBER_INPUT" ? (
              <NumberFormContent
                formState={additionalForm.formState}
                validation={additionalForm.validation.result}
                formType={formType}
              />
            ) : additionalForm?.type === "TEXT_INPUT" ? (
              <TextInputFormContent
                formState={additionalForm.formState}
                validation={additionalForm.validation.result}
                isLocked={Boolean(draft.tag)}
                formType={formType}
              />
            ) : additionalForm?.type === "SELECT_INPUT" ? (
              <MultipleChoiceFormContent
                formState={additionalForm.formState}
                validation={additionalForm.validation.result}
                formType={formType}
              />
            ) : null}
          </>
        ) : null}
      </div>
    </FormWrapper>
  );
};
