import { FC, useMemo, useCallback } from "react";

import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as LeftArrowIcon } from "@libs/assets/icons/left-arrow.svg";
import { ReactComponent as DownloadIcon } from "@libs/assets/icons/download.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { PAGE_SIZE } from "@libs/utils/constants";

import { Panel } from "@libs/components/UI/Panel";
import { QueryFilters } from "@libs/components/UI/QueryFilters";

import { ImportedLedgerTable } from "components/PatientProfile/Billing/ImportedLedger/ImportedLedgerTable";
import { FiltersFlyover } from "components/PatientProfile/Billing/ImportedLedger/FiltersFlyover";
import { NoteFlyover } from "components/PatientProfile/Billing/ImportedLedger/NoteFlyover";

import { useDownloadImportedLedgerCsv } from "components/PatientProfile/Billing/ImportedLedger/useDownloadImportedLedgerCsv";
import {
  ImportedLedgerFilterQueryParams,
  getQueryFiltersProps,
} from "components/PatientProfile/Billing/ImportedLedger/utils";

import { listMigratedTransactions } from "api/billing/queries";
import { getEmployeeNames } from "api/employee/queries";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { useItemModal } from "hooks/useItemModal";
import { paths } from "utils/routing/paths";

export const ImportedLedgerRoute: FC = () => {
  const { practiceId } = useAccount();
  const { patientId } = usePathParams("importedLedger");
  const { query, updateQuery } = useQueryParams("importedLedger");
  const { from, sortDirection, ...filterQueryParams } = query;
  const fromLink = from ?? paths.patientBilling({ patientId });

  const filtersFlyover = useBoolean(false);
  const noteFlyover = useItemModal<{ note: string }>(null);

  const { downloadCsv, isDownloading } = useDownloadImportedLedgerCsv();

  const migratedTransactionsQuery = useInfiniteApiQuery(
    listMigratedTransactions({
      args: {
        patientId,
        practiceId,
        types: query.types,
        employeeIds: query.employeeIds,
        searchString: query.searchString,
        sortDirection,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
      },
    })
  );

  const totalElements = getInfiniteQueryPagingDetails(migratedTransactionsQuery.data)?.totalElements ?? 0;

  const [employeeNamesQuery] = useApiQueries([
    getEmployeeNames({
      args: { practiceId },
      queryOptions: { enabled: query.employeeIds && query.employeeIds.length > 0 },
    }),
  ]);

  const employeeNamesMap = useMemo(
    () => new Map(employeeNamesQuery.data?.map((employee) => [employee.id, employee])),
    [employeeNamesQuery.data]
  );

  const queryFiltersProps = useMemo(
    () => getQueryFiltersProps(filterQueryParams, employeeNamesMap),
    [filterQueryParams, employeeNamesMap]
  );

  const handleUpdateFilterQueryParams = useCallback(
    (params: Partial<ImportedLedgerFilterQueryParams>) => updateQuery("replaceIn", params),
    [updateQuery]
  );

  const handleSortChange = useCallback(
    () =>
      updateQuery("replaceIn", { sortDirection: sortDirection === "ASCENDING" ? "DESCENDING" : "ASCENDING" }),
    [sortDirection, updateQuery]
  );

  return (
    <Panel
      title={
        <div className="flex items-center gap-x-2">
          <LinkIcon SvgIcon={LeftArrowIcon} to={fromLink} theme="primary" />
          <span>Imported Ledger</span>
        </div>
      }
      className="h-full w-full"
      includePadding={false}
      actions={
        <ButtonIcon
          SvgIcon={DownloadIcon}
          tooltip={{ content: "Download CSV", theme: "SMALL" }}
          onClick={downloadCsv}
          loading={isDownloading}
          disabled={!totalElements}
          theme="primary"
        />
      }
    >
      <div className="flex flex-col h-full">
        <div data-testid="imported-ledger-query-filters">
          <QueryFilters
            queries={[migratedTransactionsQuery]}
            totalElements={totalElements}
            onUpdateParams={handleUpdateFilterQueryParams}
            onOpenFlyover={filtersFlyover.on}
            searchParamKey="searchString"
            {...queryFiltersProps}
          />
        </div>

        <ImportedLedgerTable
          migratedTransactionsQuery={migratedTransactionsQuery}
          sortDirection={sortDirection}
          onSortChange={handleSortChange}
          onNoteClick={noteFlyover.open}
        />
      </div>

      {filtersFlyover.isOn ? (
        <FiltersFlyover
          filterQueryParams={filterQueryParams}
          onApplyFilters={handleUpdateFilterQueryParams}
          onClose={filtersFlyover.off}
        />
      ) : null}

      {noteFlyover.isOpen ? <NoteFlyover note={noteFlyover.item.note} onClose={noteFlyover.close} /> : null}
    </Panel>
  );
};
