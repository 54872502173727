import { FC, useCallback } from "react";

import { CustomPatientFolderVO } from "@libs/api/generated-api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";

import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { AlertModal } from "@libs/components/UI/AlertModal";
import { SettingsPanel } from "components/Settings/SettingsPanel";

import { CustomPatientFolderFlyover } from "components/Settings/PracticeSetup/Sections/PatientFolders/CustomPatientFolderFlyover";
import { PatientFoldersTable } from "components/Settings/PracticeSetup/Sections/PatientFolders/PatientFoldersTable";
import { PatientFolder } from "components/Settings/PracticeSetup/Sections/PatientFolders/types";
import { getCustomPatientFolder } from "components/Settings/PracticeSetup/Sections/PatientFolders/utils";

import { getCustomPatientFolders } from "api/documents/queries";
import { deleteCustomPatientFolder } from "api/documents/mutations";

import { useItemModal } from "hooks/useItemModal";

import { handleError } from "utils/handleError";

export const PatientFoldersRoute: FC = () => {
  const { practiceId } = useAccount();
  const patientFolderModal = useItemModal<CustomPatientFolderVO | undefined>(null);
  const deleteFolderModal = useItemModal<CustomPatientFolderVO["id"]>(null);
  const saveFolderModal = useItemModal<{ isEditing: boolean }>(null);

  const [patientFoldersQuery] = useApiQueries([getCustomPatientFolders({ args: { practiceId } })]);
  const [deleteCustomPatientFolderMutation] = useApiMutations([deleteCustomPatientFolder]);

  const openEditFolderFlyover = useCallback(
    (patientFolder: PatientFolder) => {
      const customPatientFolder = getCustomPatientFolder(patientFolder);

      patientFolderModal.open(customPatientFolder);
    },
    [patientFolderModal]
  );

  const openDeleteFolderModal = useCallback(
    (customPatientFolderId: number) => {
      deleteFolderModal.open(customPatientFolderId);
    },
    [deleteFolderModal]
  );

  const handleDeleteFolder = useCallback(() => {
    if (!deleteFolderModal.item) {
      return;
    }

    deleteCustomPatientFolderMutation.mutate(
      {
        practiceId,
        customPatientFolderId: deleteFolderModal.item,
      },
      {
        onSuccess: deleteFolderModal.close,
        onError: handleError,
      }
    );
  }, [deleteCustomPatientFolderMutation, deleteFolderModal, practiceId]);

  return (
    <SettingsPanel
      title={
        <div className="flex items-center gap-x-1">
          <span>Patient Folders</span>
          <ButtonIcon
            SvgIcon={AddIcon}
            tooltip={{ content: "New Folder", theme: "SMALL" }}
            onClick={() => patientFolderModal.open(undefined)}
            theme="primary"
          />
        </div>
      }
      includePadding={false}
    >
      <QueryResult queries={[patientFoldersQuery]}>
        {patientFoldersQuery.data ? (
          <PatientFoldersTable
            customFolders={patientFoldersQuery.data.customPatientFolders}
            defaultFolders={patientFoldersQuery.data.defaultPatientFolders}
            onEditFolder={openEditFolderFlyover}
            onDeleteFolder={openDeleteFolderModal}
          />
        ) : null}

        {patientFolderModal.isOpen ? (
          <CustomPatientFolderFlyover
            patientFolder={patientFolderModal.item}
            onSaveSuccess={saveFolderModal.open}
            onClose={patientFolderModal.close}
          />
        ) : null}

        {deleteFolderModal.isOpen ? (
          <ConfirmationModal
            primaryText="Delete Folder"
            secondaryText="Existing folders with files in them will not be deleted"
            confirmText="Delete"
            onCancel={deleteFolderModal.close}
            onConfirm={handleDeleteFolder}
            isConfirming={deleteCustomPatientFolderMutation.isLoading}
            size="3xs"
          />
        ) : null}

        {saveFolderModal.isOpen ? (
          <AlertModal
            primaryText={saveFolderModal.item.isEditing ? "Folder Updated" : "Folder Created"}
            secondaryText="It may take a few minutes for this update to be applied to all patients."
            confirmText="Okay"
            onConfirm={saveFolderModal.close}
            size="3xs"
          />
        ) : null}
      </QueryResult>
    </SettingsPanel>
  );
};
