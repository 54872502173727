import React from "react";
import { WorkingHourItemVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ViewWorkingHours } from "components/WorkingHours/ViewWorkingHours";
import { getWorkingHoursQuery } from "api/scheduling/queries";
import { handleError } from "utils/handleError";
import { upsertWorkingHours } from "api/scheduling/mutations";
import { PracticeWorkingHoursForm } from "./PracticeWorkingHoursForm";

export const WorkingHours: React.FC<{ edit: boolean; onEdit: () => void }> = ({ edit, onEdit }) => {
  const { practiceId } = useAccount();
  const [practiceHoursQuery] = useApiQueries([getWorkingHoursQuery({ args: { practiceId } })]);
  const [updateWorkingHoursMutation] = useApiMutations([upsertWorkingHours]);

  const handleSave = (workingHourItems: WorkingHourItemVO[]) => {
    updateWorkingHoursMutation.mutate(
      { practiceId, workingHours: workingHourItems },
      {
        onSuccess: onEdit,
        onError: handleError,
      }
    );
  };

  return (
    <div className="flex flex-col mb-3">
      <QueryResult queries={[practiceHoursQuery]}>
        {edit ? (
          <PracticeWorkingHoursForm
            workingHours={practiceHoursQuery.data ?? []}
            onSave={handleSave}
            onCancel={onEdit}
            isSaving={updateWorkingHoursMutation.isLoading}
          />
        ) : (
          <ViewWorkingHours data={practiceHoursQuery.data ?? []} />
        )}
      </QueryResult>
    </div>
  );
};
