import { FC } from "react";

import { Button } from "@libs/components/UI/Button";

import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";
import { UploadDocument } from "components/UI/UploadDocument";

interface Props {
  onSelectPhoto: (file: File) => void;
  onRequestCapturePhoto: Func;
  onCancel: Func;
}

export const SelectPhotoModalContent: FC<Props> = ({ onSelectPhoto, onRequestCapturePhoto, onCancel }) => {
  return (
    <>
      <ModalContent padding="lgEven">
        <UploadDocument onFileDropped={onSelectPhoto} onRequestCapturePhoto={onRequestCapturePhoto} />
      </ModalContent>
      <ModalFooter>
        <Button className="min-w-button" onClick={onCancel} theme="secondary">
          Cancel
        </Button>
        <Button className="min-w-button" inactive>
          Save
        </Button>
      </ModalFooter>
    </>
  );
};
