import { FC, FormEvent, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { endOfMonth, isSameDay, startOfDay, startOfMonth } from "date-fns";
import { captureMessage } from "@sentry/react";
import { StatementItemCountPerDateVO } from "@libs/api/generated-api";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { isDefined } from "@libs/utils/types";
import { required } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import { noop } from "@libs/utils/noop";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Button } from "@libs/components/UI/Button";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter, ModalForm, ModalHeader } from "@libs/components/UI/ModalComponents";
import { Banner } from "@libs/components/UI/Banner";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { getStatementDateRange, getStatementItemCountPerDay } from "api/billing/queries";
import { createStatement } from "api/billing/mutations";
import { useWebSocketMessage } from "hooks/useWebSocketMessage";
import { ServerPushMessages } from "api/websocket/types";
import { handleError } from "utils/handleError";
import { useNow } from "hooks/useNow";

interface Props {
  onRequestClose: Func;
  patientId: number;
}

const validationSchema = {
  startDate: [{ $v: required, $error: "Start date required" }],
  endDate: [{ $v: required, $error: "End date required" }],
};

const STATEMENT_CREATION_TIMEOUT = 30_000;

const getEntriesByDateMap = (entriesPerDay: StatementItemCountPerDateVO[] | undefined) => {
  const map: Record<string, number | undefined> = {};

  if (!entriesPerDay) {
    return map;
  }

  for (const { date, count } of entriesPerDay) {
    map[date] = count;
  }

  return map;
};

// eslint-disable-next-line complexity
export const GenerateStatementModal: FC<PropsWithChildren<Props>> = ({ patientId, onRequestClose }) => {
  const { fetchData } = useContext(ApiClientContext);
  const [waitingState, setWaitingState] = useState<"waiting" | "timedout" | "printError" | undefined>(
    undefined
  );
  const { practiceId } = useAccount();
  const now = useNow();
  const today = useMemo(() => startOfDay(now), [now]);
  const [startOpenDate, setStartOpenDate] = useState<Date>(today);
  const [endOpenDate, setEndOpenDate] = useState<Date>(today);
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const statementEntryCountEnabled = Boolean(endDate && startDate);
  const validation = useValidation({ startDate, endDate }, validationSchema);
  const [dateRangeQuery, startOpenMonthQuery, endOpenMonthQuery, rangeCountQuery] = useApiQueries([
    getStatementDateRange({
      args: {
        practiceId,
        patientId,
      },
    }),
    getStatementItemCountPerDay({
      args: {
        practiceId,
        patientId,
        startDate: formatAsISODate(startOfMonth(startOpenDate)),
        endDate: formatAsISODate(endOfMonth(startOpenDate)),
      },
    }),
    getStatementItemCountPerDay({
      args: {
        practiceId,
        patientId,
        startDate: formatAsISODate(startOfMonth(endOpenDate)),
        endDate: formatAsISODate(endOfMonth(endOpenDate)),
      },
    }),
    getStatementItemCountPerDay({
      args: {
        practiceId,
        patientId,
        startDate: startDate ? formatAsISODate(startDate) : "",
        endDate: endDate ? formatAsISODate(endDate) : "",
      },
      queryOptions: {
        enabled: statementEntryCountEnabled,
      },
    }),
  ]);

  const [createStatementMutation] = useApiMutations([createStatement]);

  const { minDate, maxDate } = useMemo(() => {
    return {
      minDate: dateRangeQuery.data?.startDate
        ? startOfMonth(getLocalDate(dateRangeQuery.data.startDate))
        : null,
      maxDate: today,
    };
  }, [dateRangeQuery.data, today]);

  const startEntryDays = useMemo(() => {
    return getEntriesByDateMap(startOpenMonthQuery.data);
  }, [startOpenMonthQuery.data]);

  const endEntryDays = useMemo(() => {
    return getEntriesByDateMap(endOpenMonthQuery.data);
  }, [endOpenMonthQuery.data]);

  const entryCount = useMemo(() => {
    return rangeCountQuery.data?.reduce((next, val) => next + val.count, 0);
  }, [rangeCountQuery.data]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // A defined minDate implies that there is at least one printable entry.
    if (validation.validate().$isValid && minDate) {
      createStatementMutation.mutate(
        {
          practiceId,
          patientId,
          data: {
            startDate: startDate ? formatAsISODate(startDate) : "",
            endDate: endDate ? formatAsISODate(endDate) : "",
          },
        },
        {
          onError: handleError,
          onSuccess: () => setWaitingState("waiting"),
        }
      );
    }
  };

  const handleEvent = useMemo(
    () =>
      waitingState === "waiting"
        ? async (data: ServerPushMessages) => {
            if (
              data.type === "STATEMENT_UPDATED" &&
              data.payload.statementUuid === createStatementMutation.data?.data.data.uuid
            ) {
              try {
                const url = await fetchData<string>(
                  `/practices/${practiceId}/patients/${patientId}/statements/${data.payload.statementUuid}/print`
                );

                window.open(url);
                onRequestClose();
              } catch {
                setWaitingState("printError");
                captureMessage("Printing the statement failed", {
                  level: "error",
                  extra: { patientId, practiceId },
                });
              }
            }
          }
        : undefined,
    [practiceId, fetchData, patientId, createStatementMutation.data, waitingState, onRequestClose]
  );

  useWebSocketMessage(handleEvent);

  useEffect(() => {
    if (waitingState === "waiting") {
      const timeoutId = window.setTimeout(() => {
        captureMessage("Statement creation timed out", {
          level: "error",
          extra: { patientId, practiceId },
        });
        setWaitingState("timedout");
      }, STATEMENT_CREATION_TIMEOUT);

      return () => {
        window.clearTimeout(timeoutId);
      };
    }

    return noop;
  }, [waitingState, patientId, practiceId]);

  return (
    <Modal height={498} size="2xs">
      <ModalHeader onClose={onRequestClose}>Define the Statement Period</ModalHeader>
      <QueryResult queries={[dateRangeQuery]}>
        {minDate ? (
          <ModalForm className="relative" onSubmit={handleSubmit}>
            <ModalContent padding="lg">
              <div className="text-xs flex flex-col gap-y-4">
                <p>Generate a statement that includes all procedures and payments from appointments.</p>
                <p>Select the start and end date for entries to include in the statement.</p>
                <FormFieldSelectMenusDatepicker
                  placeholderText="MM/DD/YYYY"
                  error={validation.result.startDate.$error}
                  minDate={minDate}
                  maxDate={endDate ?? maxDate}
                  openToDate={startOpenDate}
                  label="Start Date"
                  onMonthChange={setStartOpenDate}
                  selected={startDate}
                  showMonthYearDropdown
                  showYearDropdown={false}
                  showMonthDropdown={false}
                  dayClassName={(date) => {
                    if (
                      (endDate && date > endDate) ||
                      date < minDate ||
                      (startDate && isSameDay(date, startDate))
                    ) {
                      return null;
                    }

                    const isoDate = formatAsISODate(date);

                    return isDefined(startEntryDays[isoDate]) ? "bg-greenLight" : null;
                  }}
                  onChange={(date) => {
                    setStartDate(date);
                    setWaitingState(undefined);
                  }}
                />
                <FormFieldSelectMenusDatepicker
                  placeholderText="MM/DD/YYYY"
                  error={validation.result.endDate.$error}
                  minDate={startDate ?? minDate}
                  maxDate={maxDate}
                  openToDate={endOpenDate}
                  onMonthChange={setEndOpenDate}
                  label="End Date"
                  selected={endDate}
                  showMonthYearDropdown
                  showYearDropdown={false}
                  showMonthDropdown={false}
                  onChange={(date) => {
                    setEndDate(date);
                    setWaitingState(undefined);
                  }}
                  dayClassName={(date) => {
                    if (
                      date > maxDate ||
                      (startDate && date < startDate) ||
                      (endDate && isSameDay(date, endDate))
                    ) {
                      return null;
                    }

                    const isoDate = formatAsISODate(date);

                    return isDefined(endEntryDays[isoDate]) ? "bg-greenLight" : null;
                  }}
                />
                <div className="min-h-12">
                  {startDate && endDate ? (
                    <QueryResult queries={[rangeCountQuery]}>
                      {entryCount ? (
                        <p>
                          This statement will include items from{" "}
                          <span className="font-sansSemiBold">
                            {entryCount} {entryCount > 1 ? "entries" : "entry"}
                          </span>
                        </p>
                      ) : (
                        <Banner theme="warning" className="rounded">
                          Based on these dates no specific entries will be included in this statement, only
                          the rollover balance.
                        </Banner>
                      )}
                      {waitingState === "printError" ? (
                        <Banner theme="error" className="rounded mt-4">
                          Sorry we had a problem generating your statement. Please try again shortly. If you
                          continue to have problems please contact our support team.
                        </Banner>
                      ) : null}
                      {waitingState === "timedout" ? (
                        <Banner theme="error" className="rounded mt-4">
                          Sorry we took longer than expected to generate your statement. Please try again
                          shortly. If you continue to have problems please contact our support team.
                        </Banner>
                      ) : null}
                    </QueryResult>
                  ) : null}
                </div>
              </div>
            </ModalContent>
            <ModalFooter>
              <Button className="min-w-button" theme="secondary" onClick={onRequestClose}>
                Cancel
              </Button>
              <Button className="min-w-button" type="submit" inactive={rangeCountQuery.isLoading}>
                Generate
              </Button>
            </ModalFooter>
            {createStatementMutation.isLoading || waitingState === "waiting" ? (
              <LoadingOverlaySpinner loadingSubText="Generating Statement" />
            ) : null}
          </ModalForm>
        ) : (
          <>
            <ModalContent padding="lg">
              <div className="h-full flex items-center justify-center">
                <p className="text-sm text-center">There are no entries to generate a statement.</p>
              </div>
            </ModalContent>
            <ModalFooter>
              <Button className="min-w-button" onClick={onRequestClose}>
                OK
              </Button>
            </ModalFooter>
          </>
        )}
      </QueryResult>
    </Modal>
  );
};
