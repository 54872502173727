import { QueryClient } from "@tanstack/react-query";
import {
  CreateDraftEobPaymentRequest,
  ClaimLineItemChangeRequest,
  DraftEobPaymentVO,
  ClaimPaymentRequest,
  UpdateDraftEobPaymentRequest,
  ClaimUpdateRequest,
  ClaimVO,
  CreatePreAuthClaimRequest,
  ClaimAttachmentDeleteRequest,
  ClaimAttachmentRequest,
  ClaimBatchSubmitRequest,
  ClaimsConfigRequest,
  ClaimAdjustmentRequest,
  ClaimUpdateAttachmentRequest,
  ClaimCreateRequest,
  OffsiteClaimPaymentRequest,
  ClaimCreateAccountRequest,
  ClaimsConfigVO,
} from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { updateCachedData } from "@libs/utils/queryCache";
import { getQueryKey } from "@libs/utils/queries";
import { invalidateClaimsList } from "api/claim/cache";

export const createClaim = makeMutation({
  mutationKey: ["practices", "createClaim"],
  formatParams: (args: { practiceId: number; data: ClaimCreateRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimSummaries"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimsByAppointment"), { practiceId }]);
    },
  }),
});

export const updateClaim = makeMutation({
  mutationKey: ["practices", "updateClaim"],
  formatParams: (args: { practiceId: number; claimUuid: string; data: ClaimUpdateRequest }) => [
    args.practiceId,
    args.claimUuid,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onError: (_, { practiceId, claimUuid }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getClaim"), { practiceId, claimUuid }]);
    },
    onSuccess: (response, { practiceId, claimUuid }) => {
      updateCachedData<ClaimVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getClaim"),
            {
              practiceId,
              claimUuid,
            },
          ],
          exact: true,
        },
        () => {
          return response.data.data;
        }
      );
      updateCachedData(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getDraftEobPayment"), { practiceId, claimUuid }],
          exact: true,
        },
        () => {
          return response;
        }
      );
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimSummaries"), { practiceId }]);
    },
  }),
});

export const voidClaim = makeMutation({
  mutationKey: ["practices", "voidClaim"],
  formatParams: (args: { practiceId: number; claimUuid: string }) => [args.practiceId, args.claimUuid],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, claimUuid }) => {
      updateCachedData<ClaimVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getClaim"),
            {
              practiceId,
              claimUuid,
            },
          ],
          exact: true,
        },
        () => {
          return response.data.data;
        }
      );
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimSummaries"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimsOverview"), { practiceId }]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getLedgerEntryByTypeAndId"),
        {
          practiceId,
          entryType: "APPOINTMENT",
          entryId: String(response.data.data.appointmentId),
        },
      ]);
    },
  }),
});

export const createClaimPaymentAdjustment = makeMutation({
  mutationKey: ["practices", "createClaimPaymentAdjustment"],
  formatParams: (args: { practiceId: number; claimUuid: string; data: ClaimAdjustmentRequest }) => [
    args.practiceId,
    args.claimUuid,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, claimUuid }) => {
      updateCachedData<ClaimVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getClaim"),
            {
              practiceId,
              claimUuid,
            },
          ],
          exact: true,
        },
        () => {
          return response.data.data;
        }
      );
    },
  }),
});

export const asyncValidateClaim = makeMutation({
  mutationKey: ["practices", "asyncValidateClaim"],
  formatParams: (args: { practiceId: number; claimUuid: string }) => [args.practiceId, args.claimUuid],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, claimUuid }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimSummaries"), { practiceId }]);
      updateCachedData<ClaimVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getClaim"),
            {
              practiceId,
              claimUuid,
            },
          ],
          exact: true,
        },
        () => {
          return response.data.data;
        }
      );
    },
  }),
});

export const batchSubmitClaims = makeMutation({
  mutationKey: ["practices", "batchSubmitClaims"],
  formatParams: (args: { practiceId: number; data: ClaimBatchSubmitRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId, data }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimSummaries"), { practiceId }]);
      data.uuids.forEach((claimUuid) => {
        queryClient.invalidateQueries([getQueryKey("practices", "getClaim"), { practiceId, claimUuid }]);
      });
    },
  }),
});

export const submitClaimManually = makeMutation({
  mutationKey: ["practices", "submitClaimManually"],
  formatParams: (args: { practiceId: number; claimUuid: string }) => [args.practiceId, args.claimUuid],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, claimUuid }) => {
      updateCachedData<ClaimVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getClaim"),
            {
              practiceId,
              claimUuid,
            },
          ],
          exact: true,
        },
        () => {
          return response.data.data;
        }
      );
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimSummaries"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimsOverview"), { practiceId }]);
    },
  }),
});

export const skipClaimPayment = makeMutation({
  mutationKey: ["practices", "skipClaimPayment"],
  formatParams: (args: { practiceId: number; claimUuid: string }) => [args.practiceId, args.claimUuid],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, claimUuid }) => {
      updateCachedData<ClaimVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getClaim"),
            {
              practiceId,
              claimUuid,
            },
          ],
          exact: true,
        },
        () => {
          return response.data.data;
        }
      );
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimSummaries"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimsOverview"), { practiceId }]);
    },
  }),
});

export const replaceClaim = makeMutation({
  mutationKey: ["practices", "replaceClaim"],
  formatParams: (args: { practiceId: number; claimUuid: string }) => [args.practiceId, args.claimUuid],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, claimUuid }) => {
      const newClaim = response.data.data;

      updateCachedData<ClaimVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getClaim"),
            {
              practiceId,
              claimUuid: newClaim.uuid,
            },
          ],
          exact: true,
        },
        () => newClaim
      );

      queryClient.invalidateQueries([getQueryKey("practices", "getClaim"), { practiceId, claimUuid }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimSummaries"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimsOverview"), { practiceId }]);
    },
  }),
});

export const deleteClaim = makeMutation({
  mutationKey: ["practices", "deleteClaim"],
  formatParams: (args: { practiceId: number; claimUuid: string }) => [args.practiceId, args.claimUuid],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimSummaries"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimsOverview"), { practiceId }]);
      // Invalidate all claims since the one deleted could have been a replacement. The replacement will need to be updated.
      queryClient.invalidateQueries([getQueryKey("practices", "getClaim")], { exact: false });
      queryClient.invalidateQueries([getQueryKey("practices", "getLedgerEntryByTypeAndId")], {
        exact: false,
      });
    },
  }),
});

export const createDraftEobPayment = makeMutation({
  mutationKey: ["practices", "createDraftEobPayment"],
  formatParams: (args: { practiceId: number; data: CreateDraftEobPaymentRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId, data }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimSummaries"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimsOverview"), { practiceId }]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getDraftEobPaymentSummaries"),
        { practiceId },
      ]);
      data.claimUuids.forEach((claimUuid) => {
        queryClient.invalidateQueries([getQueryKey("practices", "getClaim"), { practiceId, claimUuid }]);
      });
    },
  }),
});

export const uploadEobFile = makeMutation({
  mutationKey: ["practices", "uploadEobFileToDraftEobPayment"],
  formatParams: (args: { practiceId: number; draftEobPaymentUuid: string; data: { file: File } }) => [
    args.practiceId,
    args.draftEobPaymentUuid,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId, draftEobPaymentUuid }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getDraftEobPayment"),
        { practiceId, draftEobPaymentUuid },
      ]);
    },
  }),
});

export const deleteEobFile = makeMutation({
  mutationKey: ["practices", "deleteEobFileFromDraftEobPayment"],
  formatParams: (args: { practiceId: number; draftEobPaymentUuid: string; eobUuid: string }) => [
    args.practiceId,
    args.draftEobPaymentUuid,
    args.eobUuid,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId, draftEobPaymentUuid }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getDraftEobPayment"),
        { practiceId, draftEobPaymentUuid },
      ]);
    },
  }),
});

export const updateDraftEobPayment = makeMutation({
  mutationKey: ["practices", "updateDraftEobPayment"],
  formatParams: (args: {
    practiceId: number;
    draftEobPaymentUuid: string;
    data: UpdateDraftEobPaymentRequest;
  }) => [args.practiceId, args.draftEobPaymentUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId, draftEobPaymentUuid }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getDraftEobPayment"),
        { practiceId, draftEobPaymentUuid },
      ]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getDraftEobPaymentSummaries"),
        { practiceId },
      ]);

      queryClient.invalidateQueries([getQueryKey("practices", "getClaim"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimSummaries"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimsOverview"), { practiceId }]);
    },
  }),
});

export const updateEobClaimLineItem = makeMutation({
  mutationKey: ["practices", "updateClaimLineItems"],
  formatParams: (args: { practiceId: number; claimUuid: string; data: ClaimLineItemChangeRequest }) => [
    args.practiceId,
    args.claimUuid,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, claimUuid }) => {
      updateCachedData<DraftEobPaymentVO>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getDraftEobPayment"), { practiceId }], exact: true },
        (oldData: DraftEobPaymentVO) => {
          const newLocal = oldData.claimPaymentSummaries.find((summary) => summary.uuid === claimUuid);

          if (newLocal) {
            newLocal.unpaidPatientAmount = response.data.data.unpaidPatientAmount;
            newLocal.unpaidInsuranceAmount = response.data.data.unpaidInsuranceAmount;
          }

          return oldData;
        }
      );
      updateCachedData<ClaimVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getClaim"),
            {
              practiceId,
              claimUuid,
            },
          ],
          exact: true,
        },
        (_) => {
          return response.data.data;
        }
      );
      queryClient.invalidateQueries([getQueryKey("practices", "getDraftEobPayment"), { practiceId }]);
    },
  }),
});

export const updateEobVerified = makeMutation({
  mutationKey: ["practices", "updateEobVerified"],
  formatParams: (args: { practiceId: number; claimUuid: string; query: { verified: boolean } }) => [
    args.practiceId,
    args.claimUuid,
    args.query,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { claimUuid, practiceId, query: { verified } }) => {
      updateCachedData<DraftEobPaymentVO>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getDraftEobPayment"), { practiceId }], exact: true },
        (oldData: DraftEobPaymentVO) => {
          const newLocal = oldData.claimPaymentSummaries.find((summary) => summary.uuid === claimUuid);

          if (newLocal) {
            newLocal.eobVerified = verified;
          }

          return oldData;
        }
      );
    },
  }),
});

const invalidateReportingCache = (queryClient: QueryClient, { practiceId }: { practiceId: number }) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getTimeSeries"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getProductionByProvider"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getProviderRollup"), { practiceId }]);
};

export const payDraftEobPayment = makeMutation({
  mutationKey: ["practices", "payDraftEobPayment"],
  formatParams: (args: { practiceId: number; draftEobPaymentUuid: string; data: ClaimPaymentRequest }) => [
    args.practiceId,
    args.draftEobPaymentUuid,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId, data, draftEobPaymentUuid }) => {
      invalidateReportingCache(queryClient, { practiceId });
      queryClient.invalidateQueries([
        getQueryKey("practices", "getDraftEobPaymentSummaries"),
        { practiceId },
      ]);

      // Eobs are permantently deleted upon success and we navigate the user
      // away from the page. We only invalidate this for Finix payments b/c it
      // can take some time and the user can navigate back to the eob details
      // page while Finix payments are processing.
      if (data.creditCardPayload) {
        queryClient.invalidateQueries([
          getQueryKey("practices", "getDraftEobPayment"),
          { practiceId, draftEobPaymentUuid },
        ]);
      }

      queryClient.invalidateQueries([getQueryKey("practices", "getClaimsOverview"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimSummaries"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getClaim"), { practiceId }]);
    },
  }),
});

export const skipDraftEobPayment = makeMutation({
  mutationKey: ["practices", "skipDraftEobPayment"],
  formatParams: (args: { practiceId: number; draftEobPaymentUuid: string }) => [
    args.practiceId,
    args.draftEobPaymentUuid,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getDraftEobPaymentSummaries"),
        { practiceId },
      ]);
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimsOverview"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimSummaries"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getClaim"), { practiceId }]);
    },
  }),
});

export const deleteDraftEobPayment = makeMutation({
  mutationKey: ["practices", "deleteDraftEobPayment"],
  formatParams: (args: { practiceId: number; draftEobPaymentUuid: string }) => [
    args.practiceId,
    args.draftEobPaymentUuid,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getDraftEobPaymentSummaries"),
        { practiceId },
      ]);
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimsOverview"), { practiceId }]);
    },
  }),
});

export const createOffsiteClaimPayment = makeMutation({
  mutationKey: ["practices", "createOffsiteClaimPayment"],
  formatParams: (args: { practiceId: number; data: OffsiteClaimPaymentRequest }) => [
    args.practiceId,
    args.data,
  ],
});

export const createPreAuthClaim = makeMutation({
  mutationKey: ["practices", "createPreAuthClaim"],
  formatParams: (args: { practiceId: number; data: CreatePreAuthClaimRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: () => {
      invalidateClaimsList(queryClient);
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientProcedureInsuranceStatus")]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientProcedure")]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientProcedures")]);
    },
  }),
});

export const deleteAttachments = makeMutation({
  mutationKey: ["practices", "deleteClaimAttachment"],
  formatParams: (args: { practiceId: number; claimUuid: string; data: ClaimAttachmentDeleteRequest }) => [
    args.practiceId,
    args.claimUuid,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, claimUuid, data }) => {
      updateCachedData<ClaimVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getClaim"),
            {
              practiceId,
              claimUuid,
            },
          ],
          exact: true,
        },
        (oldData) => {
          data.attachmentUuids.forEach((attachmentUuid) => {
            const attachmentIndex = oldData.attachments.findIndex(
              (attachment) => attachment.uuid === attachmentUuid
            );

            if (attachmentIndex !== -1) {
              oldData.attachments.splice(attachmentIndex, 1);
            }
          });

          return oldData;
        }
      );
    },
  }),
});

export const setAttachments = makeMutation({
  mutationKey: ["practices", "setClaimAttachments"],
  formatParams: (args: { practiceId: number; claimUuid: string; data: ClaimAttachmentRequest }) => [
    args.practiceId,
    args.claimUuid,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, claimUuid }) => {
      updateCachedData<ClaimVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getClaim"),
            {
              practiceId,
              claimUuid,
            },
          ],
          exact: true,
        },
        () => {
          return response.data.data;
        }
      );
    },
  }),
});

export const updateAttachments = makeMutation({
  mutationKey: ["practices", "updateClaimAttachments"],
  formatParams: (args: { practiceId: number; claimUuid: string; data: ClaimUpdateAttachmentRequest }) => [
    args.practiceId,
    args.claimUuid,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, claimUuid }) => {
      updateCachedData<ClaimVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getClaim"),
            {
              practiceId,
              claimUuid,
            },
          ],
          exact: true,
        },
        () => {
          return response.data.data;
        }
      );
    },
  }),
});

export const saveClaimsConfig = makeMutation({
  mutationKey: ["practices", "saveClaimsConfig"],
  formatParams: (args: { practiceId: number; data: ClaimsConfigRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      updateCachedData<ClaimsConfigVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getClaimsConfig"),
            {
              practiceId,
            },
          ],
          exact: true,
        },
        () => response.data.data
      );
      queryClient.invalidateQueries([getQueryKey("practices", "getClaimsBillingProviders"), { practiceId }]);
    },
  }),
});

export const createReceiverAccount = makeMutation({
  mutationKey: ["practices", "createReceiverAccount"],
  formatParams: (args: { practiceId: number; data: ClaimCreateAccountRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      updateCachedData<ClaimsConfigVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getClaimsConfig"),
            {
              practiceId,
            },
          ],
          exact: true,
        },
        () => response.data.data
      );
    },
  }),
});
