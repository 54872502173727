import { cx } from "@libs/utils/cx";
import { cxTableStyles } from "components/Dashboard/Tables/cxTableStyles";

type Props = {
  children: React.ReactNode;
  className?: string;
};
export const DashboardPrintHeader: React.FC<Props> = ({ children, className }) => {
  return <div className={cx(cxTableStyles.tableHeadingText, className)}>{children}</div>;
};
