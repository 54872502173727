import React, { PropsWithChildren } from "react";

export const ClaimModalFooter: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className={`
        flex
        flex-none
        items-center
        justify-center
        h-16
        px-8
        border-t
        border-greyLighter
        dark:border-slate-500
        gap-3
      `}
    >
      {children}
    </div>
  );
};
