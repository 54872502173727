import { useMemo } from "react";
import { InsuranceCarrierDetailsVO } from "@libs/api/generated-api";
import { formatAddress } from "@libs/utils/address";
import { phoneNumberFormatter } from "@libs/utils/phone";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as ExternalLinkIcon } from "@libs/assets/icons/external-link.svg";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { Section } from "components/Insurance/Section";
import { planCxStyles } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/styles";

interface Props {
  carrier: InsuranceCarrierDetailsVO;
}

export const CarrierDetailsSection: React.FC<Props> = ({ carrier }) => {
  const { address, fax, phone, website } = useMemo(
    () => ({
      address: carrier.address && formatAddress(carrier.address),
      fax: carrier.fax ? phoneNumberFormatter(carrier.fax) : null,
      phone: carrier.phone ? phoneNumberFormatter(carrier.phone) : null,
      website: carrier.website ? websiteToUrl(carrier.website) : null,
    }),
    [carrier]
  );

  const sharedProps = {
    edit: false,
    layout: "labelOut" as const,
  };

  return (
    <Section
      className={planCxStyles.section}
      includePadding={false}
      title="Carrier Details"
      useCustomStyling={true}
    >
      <div className={planCxStyles.fieldsContainer}>
        <FormFieldInput {...sharedProps} label="Carrier" value={carrier.name} />
        <FormFieldInput {...sharedProps} label="Payer ID" value={carrier.payerId} />
        <div className="flex flex-col gap-y-1 text-xs">
          <FormFieldLabel className="font-sansSemiBold" content="Phone" />
          {phone && <a href={`tel:${phone}`}>{phone}</a>}
        </div>
        <FormFieldInput {...sharedProps} label="Fax" value={fax ?? EMPTY_CELL} />
        <div className="flex flex-col gap-y-1 text-xs">
          <FormFieldLabel className="font-sansSemiBold" content="Website" />
          {website ? (
            <a className={planCxStyles.linkFieldContainer()} href={website} target="_blank">
              <span className="truncate text-ellipsis overflow-hidden">{website.split("https://")[1]}</span>
              <Icon SvgIcon={ExternalLinkIcon} size="sm" theme="primary" />
            </a>
          ) : (
            EMPTY_CELL
          )}
        </div>
        <div className="flex flex-col gap-y-1 text-xs">
          <FormFieldLabel className="font-sansSemiBold" content="Address" />
          <div className="flex flex-col">
            <div>{address && `${address.lineOne}`}</div>
            <div>{address && `${address.lineTwo}`}</div>
          </div>
        </div>
      </div>
    </Section>
  );
};

const websiteToUrl = (website: string) => {
  return website.startsWith("https://www") ? website : `https://www.${website}`;
};
