import { useCallback, useEffect, useRef } from "react";
import {
  FeeScheduleVO,
  InsurancePlanVO,
  PatientInsuranceResponse,
  PatientInsuranceVO,
} from "@libs/api/generated-api";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { getInsurancePlanOverview } from "api/patientInsurance/queries";

export type PlanFields = Partial<InsurancePlanVO> & { carrierId?: number | undefined };
export type UsePlanFieldsResult = {
  handlePlanFieldChange: (partial: Partial<PlanFields>) => void;
  numOfPatients: number | undefined;
  onCreateNewPlan: Func;
  onDropSuccess: Func;
  onDuplicatePlan: Func;
  planFields: PlanFields;
};

const getPlanFieldDefaults = (
  insurance: PatientInsuranceVO,
  defaultFeeSchedule?: FeeScheduleVO
): PlanFields => {
  return {
    carrierContacted: false,
    carrierId: insurance.subscriber.carrierId,
    coordinationOfBenefits: "STANDARD",
    crownBridgesPaidOn: "SEAT_DATE",
    eligibilityCoverageLevel: "FAMILY",
    feeSchedule: defaultFeeSchedule
      ? {
          id: defaultFeeSchedule.id,
          name: defaultFeeSchedule.name,
        }
      : undefined,
    missingToothClause: false,
    outOfNetworkBenefits: true,
    planType: "PPO",
    renewalMonth: "JANUARY",
    waitingPeriodInMonths: 0,
  };
};

export const usePlanFields = (insurance?: PatientInsuranceResponse, feeSchedules?: FeeScheduleVO[]) => {
  const { practiceId } = useAccount();
  const [planFields, setPlanFields, setPlanFieldsObject] = useObjectState<PlanFields>(
    insurance?.insurancePlan
      ? { ...insurance.insurancePlan, carrierId: insurance.insurancePlan.carrier.id }
      : { carrierId: insurance?.patientInsurance.subscriber.carrierId }
  );

  const hasLoadedPlanFields = useRef(false);

  useEffect(() => {
    if (insurance && !hasLoadedPlanFields.current) {
      hasLoadedPlanFields.current = true;

      const insurancePlan = insurance.insurancePlan;
      const patientInsurance = insurance.patientInsurance;

      const subscriberEmployer = patientInsurance.subscriber.employer;

      if (insurancePlan) {
        setPlanFields(insurancePlan);

        if (!insurancePlan.employerName) {
          setPlanFields({ employerName: subscriberEmployer });
        }
      } else {
        // The first item fetched will be the ideal candidate -- either one including the carrier or UCR fees otherwise
        setPlanFields(getPlanFieldDefaults(patientInsurance, feeSchedules?.[0]));
      }
    }
  }, [insurance, feeSchedules, setPlanFields]);

  const [insurancePlanOverviewQuery] = useApiQueries([
    getInsurancePlanOverview({
      args: { practiceId, insurancePlanUuid: planFields.uuid ?? "" },
      queryOptions: { enabled: Boolean(planFields.uuid) },
    }),
  ]);

  const handleDuplicatePlan = useCallback(() => {
    setPlanFields({ uuid: undefined });
  }, [setPlanFields]);

  const handleDropPlanSuccess = useCallback(
    (patientInsurance: PatientInsuranceVO) => {
      setPlanFieldsObject(getPlanFieldDefaults(patientInsurance, feeSchedules?.[0]));
    },
    [feeSchedules, setPlanFieldsObject]
  );

  const handleCreateNewPlan = useCallback(() => {
    if (insurance) {
      setPlanFieldsObject(getPlanFieldDefaults(insurance.patientInsurance));
    }
  }, [insurance, setPlanFieldsObject]);

  return {
    handlePlanFieldChange: setPlanFields,
    numOfPatients: insurancePlanOverviewQuery.data?.patients,
    onCreateNewPlan: handleCreateNewPlan,
    onDropSuccess: handleDropPlanSuccess,
    onDuplicatePlan: handleDuplicatePlan,
    planFields,
  };
};
