import { FC, FormEvent, useCallback, useRef, useMemo } from "react";
import AvatarEditor from "react-avatar-editor";

import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as DownChevronIcon } from "@libs/assets/icons/down-chevron.svg";
import { ReactComponent as UploadIcon } from "@libs/assets/icons/upload.svg";
import { ReactComponent as CameraIcon } from "@libs/assets/icons/camera.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useBoolean } from "@libs/hooks/useBoolean";
import { dataURLtoFile } from "@libs/utils/dataUrl";

import { useAccount } from "@libs/contexts/AccountContext";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { ModalForm, ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";
import { UploadPhotoEditor } from "components/UI/UploadPhotoEditor";

import { uploadProfileImage, deleteProfileImage } from "api/user/mutations";
import { handleError } from "utils/handleError";

interface Props {
  userId: number;
  selectedImage: File;
  isExistingImage: boolean;
  onRequestSelectPhoto: Func;
  onRequestCapturePhoto: Func;
  onCancel: Func;
  onClose: Func;
}

export const EditPhotoModalContent: FC<Props> = ({
  userId,
  selectedImage,
  isExistingImage,
  onRequestSelectPhoto,
  onRequestCapturePhoto,
  onCancel,
  onClose,
}) => {
  const { practiceId } = useAccount();
  const canvasRef = useRef<AvatarEditor | null>(null);
  const menu = useBoolean(false);

  const [uploadProfileImageMutation, deleteProfileImageMutation] = useApiMutations([
    uploadProfileImage,
    deleteProfileImage,
  ]);
  const uploadProfileImageMutationMutate = uploadProfileImageMutation.mutate;
  const deleteProfileImageMutationMutate = deleteProfileImageMutation.mutate;

  const handleSavePhoto = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (canvasRef.current !== null) {
        const imageDataUrl = canvasRef.current.getImageScaledToCanvas().toDataURL("image/jpeg", 1);
        const imageFile = dataURLtoFile(imageDataUrl, "profile-image.jpeg");

        uploadProfileImageMutationMutate(
          { practiceId, userId, image: imageFile },
          {
            onSuccess: onClose,
            onError: handleError,
          }
        );
      }
    },
    [uploadProfileImageMutationMutate, practiceId, userId, onClose]
  );

  const handleRemovePhoto = useCallback(() => {
    deleteProfileImageMutationMutate(
      { practiceId, userId },
      {
        onSuccess: onClose,
        onError: handleError,
      }
    );
  }, [deleteProfileImageMutationMutate, practiceId, userId, onClose]);

  const menuOptions = useMemo(
    () =>
      createMenuOptions(
        { value: "upload", label: "Upload", SvgIcon: UploadIcon },
        { value: "camera", label: "Camera", SvgIcon: CameraIcon },
        ...(isExistingImage ? [{ value: "remove", label: "Remove", SvgIcon: DeleteIcon }] : [])
      ),
    [isExistingImage]
  );

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "upload": {
          onRequestSelectPhoto();
          break;
        }
        case "camera": {
          onRequestCapturePhoto();
          break;
        }
        case "remove": {
          handleRemovePhoto();
          break;
        }
        default: {
          break;
        }
      }

      menu.off();
    },
    [onRequestSelectPhoto, onRequestCapturePhoto, handleRemovePhoto, menu]
  );

  return (
    <ModalForm onSubmit={handleSavePhoto}>
      <ModalContent>
        <UploadPhotoEditor canvasRef={canvasRef} imageFile={selectedImage} />
      </ModalContent>
      <ModalFooter>
        <Button className="min-w-button" onClick={onCancel} theme="secondary">
          {isExistingImage ? "Close" : "Cancel"}
        </Button>

        <div className="flex divide-x divide-archyBlue-700 min-w-button">
          <AsyncButton
            className="flex-1 rounded-r-none"
            isLoading={uploadProfileImageMutation.isLoading || deleteProfileImageMutation.isLoading}
            type="submit"
          >
            Save
          </AsyncButton>
          <ButtonMenu
            menuContent={
              <div className="w-32">
                <MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />
              </div>
            }
            onRequestOpen={menu.on}
            onRequestClose={menu.off}
            isOpen={menu.isOn}
            placement="bottom-end"
          >
            {(props) => (
              <Button {...props} aria-label="Profile Photo Menu" className="rounded-l-none p-2" size="custom">
                <Icon SvgIcon={DownChevronIcon} theme="darkBackground" />
              </Button>
            )}
          </ButtonMenu>
        </div>
      </ModalFooter>
    </ModalForm>
  );
};
