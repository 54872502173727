import { useCallback } from "react";
import { MountVO, TransferImagesRequest } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { ImageTransferData } from "components/PatientProfile/Imaging/MountRoute/SelectPatientMount";
import { transferImages } from "api/imaging/mutations";
import { handleError } from "utils/handleError";
import { useItemModal } from "hooks/useItemModal";

export const useTransferMountModal = ({
  selectedImageIds,
  mount,
  sourcePatientId,
}: {
  selectedImageIds: Set<number>;
  mount: MountVO;
  sourcePatientId: number;
}) => {
  const { practiceId } = useAccount();

  const transferMountModal = useBoolean(false);
  const confirmTransferMountModal = useItemModal<{ mountId: number; patientId: number }>(null);

  const [transferImagesMutation] = useApiMutations([transferImages]);

  const handleTransferMount = useCallback(
    ({ targetMountId, targetPatientId }: ImageTransferData) => {
      if (targetPatientId) {
        const data: TransferImagesRequest = {
          imageIds: [...selectedImageIds],
          sourceMountId: mount.id,
          sourcePatientId,
          targetPatientId,
        };

        if (targetMountId) {
          data.targetMountId = targetMountId;
        }

        transferImagesMutation.mutate(
          {
            data,
            practiceId,
          },
          {
            onSuccess: (response) => {
              transferMountModal.off();
              confirmTransferMountModal.open({
                mountId: response.data.data.id,
                patientId: targetPatientId,
              });
            },
            onError: handleError,
          }
        );
      }
    },
    [
      confirmTransferMountModal,
      mount.id,
      practiceId,
      selectedImageIds,
      sourcePatientId,
      transferImagesMutation,
      transferMountModal,
    ]
  );

  return {
    handleTransferMount,
    transferMountModal,
    confirmTransferMountModal,
    isTransferring: transferImagesMutation.isLoading,
  };
};
