import React, { FormEvent } from "react";
import { FeeScheduleVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { useApiMutations } from "@libs/hooks/useApiMutations";

import { EditableFeeSchedule } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/useEditableFeeSchedule";
import { addFeeSchedule, updateFeeSchedule } from "api/feeSchedule/mutations";
import {
  getFeeSchedulePostData,
  getFeeSchedulePutData,
  getFeeScheduleSchema,
} from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/formData";
import { handleError } from "utils/handleError";

export const useSubmitHandler = (params: {
  allProviderIds: number[] | undefined;
  fields: EditableFeeSchedule | undefined;
  hasEditsToFees: React.MutableRefObject<boolean>;
  onSuccess: (savedFeeSchedule: FeeScheduleVO) => void;
  practiceId: number;
  turnOffEditing: Func;
}) => {
  const { allProviderIds, fields, hasEditsToFees, onSuccess, practiceId, turnOffEditing } = params;

  const [
    { mutate: addFeeScheduleMutate, isLoading: isLoadingNew },
    { mutate: updateFeeScheduleMutate, isLoading: isLoadingUpdated },
  ] = useApiMutations([addFeeSchedule, updateFeeSchedule]);

  const schema = React.useMemo(() => {
    return getFeeScheduleSchema(fields);
  }, [fields]);

  const { validate, result: validation } = useValidation(fields, schema);

  const handleSubmit = React.useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!validate().$isValid || !fields) {
        return;
      }

      turnOffEditing();

      if (fields.id) {
        const requestData = getFeeSchedulePutData({
          ...fields,
          hasEditsToFees: hasEditsToFees.current,
        });

        updateFeeScheduleMutate(
          {
            practiceId,
            data: requestData,
            feeScheduleId: fields.id,
          },
          {
            onSuccess: ({ data }) => {
              onSuccess(data.data);
            },
            onError: handleError,
          }
        );
      } else {
        const requestData = getFeeSchedulePostData({ data: fields, allProviderIds });

        addFeeScheduleMutate(
          {
            practiceId,
            data: requestData,
          },
          {
            onSuccess: ({ data }) => {
              onSuccess(data.data);
            },
            onError: handleError,
          }
        );
      }
    },
    [
      validate,
      fields,
      turnOffEditing,
      hasEditsToFees,
      updateFeeScheduleMutate,
      practiceId,
      onSuccess,
      allProviderIds,
      addFeeScheduleMutate,
    ]
  );

  return { handleSubmit, isSavingChanges: isLoadingNew || isLoadingUpdated, validation };
};
