import { useCallback } from "react";

import { CreateStatementRequest } from "@libs/api/generated-api";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { useAccount } from "@libs/contexts/AccountContext";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { noCache } from "@libs/utils/queryCache";
import { isDefined } from "@libs/utils/types";

import { Flyover } from "components/UI/Flyover";

import { MessagePatientsForm } from "components/Communications/MessagePatients/MessagePatientsForm";
import { SendMessageModal } from "components/Communications/MessagePatients/SendMessageModal";

import {
  MessageCampaignFilterType,
  MessageCampaignFilterCriteria,
  MessageCampaignSelectionCategory,
  SendMessageCampaignDraft,
} from "components/Communications/MessagePatients/types";
import {
  getSendMessageCampaignRequest,
  getCreateStatementRequest,
} from "components/Communications/MessagePatients/utils";

import {
  getPatientEngagementTemplateVariables,
  getTwilioRegistrationStatus,
} from "api/communications/queries";
import { getEmailConfig } from "api/settings/communications/queries";
import { sendMessageCampaign } from "api/communications/mutations";

import { useItemModal } from "hooks/useItemModal";
import { handleError } from "utils/handleError";

export interface MessagePatientsFlyoverProps<T extends MessageCampaignFilterType> {
  type: T;
  criteria: MessageCampaignFilterCriteria[T];
  selectionCategory: MessageCampaignSelectionCategory;
  selectedCount: number;
  filteredCount: number;
  selectedIds: Set<number>;
  deselectedIds: Set<number>;
  hasAllSelected: boolean;
  onGenerateStatements?: (createStatementRequest: CreateStatementRequest) => Promise<void>;
  onSendSuccess?: Func;
  onClose: Func;
}

export const MessagePatientsFlyover = <T extends MessageCampaignFilterType>({
  type,
  criteria,
  selectionCategory,
  selectedCount,
  filteredCount,
  selectedIds,
  deselectedIds,
  hasAllSelected,
  onGenerateStatements,
  onSendSuccess,
  onClose,
}: MessagePatientsFlyoverProps<T>) => {
  const { practiceId } = useAccount();

  const sendCount = selectedCount || filteredCount;
  const sendMessageModal = useItemModal<SendMessageCampaignDraft>(null);
  const sending = useBoolean(false);
  const sendingOn = sending.on;
  const sendingOff = sending.off;

  const canGenerateStatements = isDefined(onGenerateStatements);

  const [templateVariablesQuery, twilioRegistrationStatusQuery, emailConfigQuery] = useApiQueries([
    getPatientEngagementTemplateVariables({
      args: { practiceId, category: "MESSAGE_CAMPAIGN", messageCampaignSelectionCategory: selectionCategory },
    }),
    getTwilioRegistrationStatus({ args: { practiceId }, queryOptions: noCache }),
    getEmailConfig({ args: { practiceId }, queryOptions: noCache }),
  ]);

  const [sendMessageCampaignMutation] = useApiMutations([sendMessageCampaign]);
  const sendMessageCampaignMutateAsync = sendMessageCampaignMutation.mutateAsync;

  const handleSend = useCallback(
    async (messageCampaignDraft: SendMessageCampaignDraft) => {
      const { startDate, endDate } = messageCampaignDraft.statementDateRange;

      sendingOn();

      try {
        if (onGenerateStatements && startDate && endDate) {
          await onGenerateStatements(getCreateStatementRequest({ startDate, endDate }));
        }

        await sendMessageCampaignMutateAsync({
          practiceId,
          data: getSendMessageCampaignRequest(
            type,
            criteria,
            selectedIds,
            deselectedIds,
            hasAllSelected,
            messageCampaignDraft
          ),
        });

        onSendSuccess?.();
        onClose();
      } catch (error) {
        handleError(error);
      } finally {
        sendingOff();
      }
    },
    [
      sendingOn,
      sendingOff,
      onGenerateStatements,
      sendMessageCampaignMutateAsync,
      practiceId,
      type,
      criteria,
      selectedIds,
      deselectedIds,
      hasAllSelected,
      onSendSuccess,
      onClose,
    ]
  );

  return (
    <Flyover title="Message Patients" onClose={onClose} headerSize="sm" size="lg">
      <QueryResult queries={[templateVariablesQuery, twilioRegistrationStatusQuery, emailConfigQuery]}>
        {templateVariablesQuery.data && twilioRegistrationStatusQuery.data && emailConfigQuery.data ? (
          <MessagePatientsForm
            templateVariablesQuery={templateVariablesQuery}
            twilioOnboardingStatus={twilioRegistrationStatusQuery.data}
            emailConfig={emailConfigQuery.data}
            canGenerateStatements={canGenerateStatements}
            sendCount={sendCount}
            onRequestSend={sendMessageModal.open}
            onCancel={onClose}
          />
        ) : null}
      </QueryResult>

      {sendMessageModal.isOpen ? (
        <SendMessageModal
          type={type}
          sendCount={sendCount}
          isSending={sending.isOn}
          onSend={() => handleSend(sendMessageModal.item)}
          onCancel={sendMessageModal.close}
        />
      ) : null}
    </Flyover>
  );
};
