import { DentalProcedureFeeVO, DentalProcedureVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";

export const getProcedureCounts = (
  feeScheduleDetailProcedureFees: Partial<DentalProcedureFeeVO>[],
  procedures: DentalProcedureVO[]
) => {
  const countsTotal: { [s: string]: number | undefined } = {};
  const categoryCounts: { [s: string]: number | undefined } = {};

  const feeScheduleProcedureIdsWithFees = new Set(
    feeScheduleDetailProcedureFees.filter((item) => isDefined(item.fee)).map((item) => item.procedureId)
  );

  for (const procedure of procedures) {
    countsTotal[procedure.categoryType] = (countsTotal[procedure.categoryType] ?? 0) + 1;

    if (feeScheduleProcedureIdsWithFees.has(procedure.id)) {
      categoryCounts[procedure.categoryType] = (categoryCounts[procedure.categoryType] ?? 0) + 1;
    }
  }

  return { countsTotal, categoryCounts };
};
