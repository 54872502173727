import { FC } from "react";
import { LedgerV2AppointmentEntryVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { Pill, PillProps } from "@libs/components/UI/Pill";

export const InsuranceRunningBalancePill: FC<{ appointmentEntry: LedgerV2AppointmentEntryVO }> = ({
  appointmentEntry,
}) => {
  const text = `Insurance ${formatCurrency(appointmentEntry.insuranceRunningBalance)}`;
  const theme: PillProps["theme"] =
    appointmentEntry.insuranceRunningBalance < 0
      ? "orange"
      : appointmentEntry.insuranceRunningBalance > 0
        ? "red"
        : "green";

  return <Pill theme={theme}>{text}</Pill>;
};
