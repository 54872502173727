import { FC } from "react";
import { PatientSummaryVO, PracticeInfoVO } from "@libs/api/generated-api";
import { InvoiceAddress } from "components/PatientProfile/Billing/InvoicePreviewAddress";

export const PracticeAndPatientAddresses: FC<{
  practiceData: PracticeInfoVO;
  patientData: PatientSummaryVO;
}> = ({ practiceData, patientData }) => {
  const practiceDisplayName = practiceData.name;

  return (
    <div className="flex justify-between">
      <InvoiceAddress
        name={practiceDisplayName}
        address={practiceData.addressDetails}
        profileImage={practiceData.logo?.thumbnail}
      />
      <InvoiceAddress
        className="text-right"
        name={patientData.name.fullDisplayName}
        address={patientData.contact.address}
      />
    </div>
  );
};
