import { EmployeeVO, EmploymentDetailsVO } from "@libs/api/generated-api";
import { YEAR_IN_MONTHS, YEAR_IN_WORKING_HOURS } from "@libs/utils/date";
import { ValidationResult } from "@libs/hooks/useValidation";
import { email, phoneNumber, required } from "@libs/utils/validators";
import { isDefined } from "@libs/utils/types";
import { dollarsToCents, formatCurrency } from "@libs/utils/currency";

const NON_EXEMPT_THRESHOLD = 3_556_800;

export const getEmployeePersonalDetailsSchema = (params?: {
  isOwner: boolean | undefined;
  isIncludedInPayroll: boolean;
  isActive: boolean;
}) => {
  // if the employee is an owner or an active employee on payroll
  // more fields are required for integration with other services

  // If an employee is not active we want to make things more flexible because
  // the person doing the editing might not have all the information.
  const isOwner = Boolean(params?.isOwner);
  const isActiveOnPayroll = Boolean(params?.isActive && params.isIncludedInPayroll);

  return {
    personalDetails: {
      firstName: [
        {
          $v: required,
          $error: "First name is required",
        },
      ],
      lastName: [
        {
          $v: required,
          $error: "Last name is required",
        },
      ],
      dob: [
        {
          $v: required,
          $error: "Date of birth is required",
          $ignore: !isOwner,
        },
      ],
    },
    contactDetails: {
      workEmail: {
        $validations: [
          {
            $v: required,
            $error: "Email is required",
          },
          {
            $v: email,
            $error: "Please enter a valid email",
          },
        ],
      },
      personalEmail: [
        {
          $v: required,
          $error: "Personal email is required",
          $ignore: !isActiveOnPayroll,
        },
        {
          $v: email,
          $error: "Please enter valid email",
        },
      ],
      phone: [
        {
          $v: required,
          $error: "The owner is required to have a phone number",
          $ignore: !isOwner,
        },
        {
          $v: phoneNumber,
          $error: "Please enter valid phone number",
        },
      ],
      addressDetails: {
        address1: [
          {
            $v: required,
            $error: "Address is required",
          },
        ],
        city: [
          {
            $v: required,
            $error: "City is required",
          },
        ],
        state: [
          {
            $v: required,
            $error: "State is required",
          },
        ],
        zip: [
          {
            $v: required,
            $error: "Zip is required",
          },
        ],
        $ignore: !isActiveOnPayroll,
      },
    },
  };
};

export const getEmploymentSchema = ({
  status,
  period,
  amount,
  isExcludedFromPayroll,
}: {
  status?: EmploymentDetailsVO["status"];
  period?: NonNullable<EmploymentDetailsVO["compensationDetails"]>["period"];
  amount?: NonNullable<EmploymentDetailsVO["compensationDetails"]>["amount"];
  isExcludedFromPayroll: boolean;
}) => ({
  roleV2Id: [{ $v: required, $error: "Role is required" }],
  employmentDetails: {
    accessDate: [
      {
        $v: required,
        $error: "Access date is required",
      },
    ],
    startDate: [
      {
        $v: required,
        $error: "Start date is required",
        $ignore: isExcludedFromPayroll,
      },
    ],
    endDate: [
      {
        $v: required,
        $error: "End date is required",
        $ignore: status !== "ARCHIVED" || isExcludedFromPayroll,
      },
    ],
    jobTitleKey: [
      {
        $v: required,
        $error: "Job type is required",
      },
    ],
    employeeCategory: [
      {
        $v: required,
        $error: "Employee category is required",
        $ignore: isExcludedFromPayroll,
      },
    ],
    employmentType: [
      {
        $v: required,
        $error: "Employee type is required",
        $ignore: isExcludedFromPayroll,
      },
    ],
    flsaStatus: {
      $validations: [
        {
          $v: required,
          $error: "Overtime eligibility is required",
        },
        {
          $v: (value: unknown) => {
            if (!isDefined(period) || value !== "NON_EXEMPT") {
              return true;
            }

            return period !== "PER_DIEM";
          },
          $error: "Per diem compensation is exempt",
        },
        {
          $v: (value: unknown) => {
            if (!isDefined(amount) || !isDefined(period) || value !== "EXEMPT") {
              return true;
            }

            const amountInCents = dollarsToCents(amount);

            if (period === "PER_YEAR") {
              return amountInCents >= NON_EXEMPT_THRESHOLD;
            }

            if (period === "PER_MONTH") {
              return amountInCents * YEAR_IN_MONTHS >= NON_EXEMPT_THRESHOLD;
            }

            if (period === "PER_HOUR") {
              return amountInCents * YEAR_IN_WORKING_HOURS >= NON_EXEMPT_THRESHOLD;
            }

            return true;
          },
          $error: `Annual compensation less than ${formatCurrency(NON_EXEMPT_THRESHOLD)} is non-exempt.`,
        },
      ],
      $ignore: isExcludedFromPayroll,
    },
    compensationDetails: {
      amount: [
        {
          $v: required,
          $error: "Amount is required",
        },
      ],
      period: [
        {
          $v: required,
          $error: "Period is required",
        },
      ],
      $ignore: isExcludedFromPayroll,
    },
  },
});

export type EmploymentValidationResult = ValidationResult<EmployeeVO, ReturnType<typeof getEmploymentSchema>>;
export type EmployeePersonalDetailsValidationResult = ValidationResult<
  EmployeeVO,
  ReturnType<typeof getEmployeePersonalDetailsSchema>
>;

export const getCreateEmployeeSchema = (props: {
  isExcludedFromPayroll: boolean;
  period?: NonNullable<EmploymentDetailsVO["compensationDetails"]>["period"];
  amount?: NonNullable<EmploymentDetailsVO["compensationDetails"]>["amount"];
}) => {
  return {
    ...getEmployeePersonalDetailsSchema(),
    ...getEmploymentSchema(props),
  };
};
