import { FC } from "react";

import { FormTaskVO, PatientSummaryVO } from "@libs/api/generated-api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as SendIcon } from "@libs/assets/icons/send.svg";
import { ReactComponent as AttachIcon } from "@libs/assets/icons/attach.svg";
import { ReactComponent as PrintIcon } from "@libs/assets/icons/send-to-printer.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as KioskIcon } from "@libs/assets/icons/tablet.svg";
import { ButtonLink } from "@libs/components/UI/ButtonLink";
import { ActionsRow } from "@libs/components/UI/GridTableComponents";

import { AttachFormFilesModal } from "components/PatientProfile/Forms/AttachFormFilesModal";
import { KioskModal } from "components/PatientProfile/Forms/KioskModal";
import { SendFormsModal } from "components/PatientProfile/Forms/SendFormsModal";
import { DeleteFormsModal } from "components/PatientProfile/Forms/DeleteFormsModal";
import { useFormTaskActions } from "components/PatientProfile/Forms/FormTaskButtonMenu";

interface Props {
  patient: PatientSummaryVO;
  formTasks: FormTaskVO[];
  disabled: boolean;
}

// eslint-disable-next-line complexity
export const PatientFormActionRow: FC<Props> = ({ patient, formTasks, disabled }) => {
  const {
    selectedCount,
    attachFilesModal,
    kioskModal,
    sendFormsModal,
    deleteFormsModal,
    inPersonFormTitles,
    hasEverySelectedCompleted,
    hasSomeSelectedCompleted,
    printLink,
    invalidatePatientFormTasks,
    handleSendFormTasks,
    handleDeleteFormTasks,
    handleTasksToKiosk,
    isSendingToKiosk,
    isSending,
    isDeleting,
  } = useFormTaskActions(patient, formTasks);

  return (
    <ActionsRow contentClassName="flex items-center gap-x-6">
      <ButtonIcon
        SvgIcon={AttachIcon}
        tooltip={{ content: "Attach Forms", theme: "SMALL" }}
        onClick={attachFilesModal.on}
        disabled={disabled}
        size="sm"
      />
      <ButtonIcon
        SvgIcon={KioskIcon}
        tooltip={
          !disabled && hasSomeSelectedCompleted
            ? { content: "Completed forms cannot be sent to the kiosk", theme: "MEDIUM" }
            : { content: "Send to Kiosk", theme: "SMALL" }
        }
        onClick={handleTasksToKiosk}
        disabled={disabled || hasSomeSelectedCompleted}
        loading={isSendingToKiosk}
        size="sm"
      />
      {disabled ? (
        <Icon
          SvgIcon={PrintIcon}
          tooltip={{ content: "Print Forms", theme: "SMALL" }}
          disabled={disabled}
          size="sm"
        />
      ) : (
        <ButtonLink href={printLink} target="_blank" theme="link">
          <Icon SvgIcon={PrintIcon} tooltip={{ content: "Print Forms", theme: "SMALL" }} size="sm" />
        </ButtonLink>
      )}

      <ButtonIcon
        SvgIcon={SendIcon}
        tooltip={
          !disabled && hasEverySelectedCompleted
            ? { content: "Completed forms cannot be sent", theme: "MEDIUM" }
            : { content: "Send Forms", theme: "SMALL" }
        }
        onClick={sendFormsModal.on}
        disabled={disabled || hasEverySelectedCompleted}
        size="sm"
      />

      <ButtonIcon
        SvgIcon={DeleteIcon}
        tooltip={{ content: "Delete Forms", theme: "SMALL" }}
        onClick={deleteFormsModal.on}
        disabled={disabled}
        size="sm"
      />

      {attachFilesModal.isOn ? (
        <AttachFormFilesModal
          userId={patient.id}
          formTasks={formTasks}
          onUploadSuccess={invalidatePatientFormTasks}
          onRequestClose={attachFilesModal.off}
        />
      ) : null}

      {kioskModal.item ? <KioskModal onRequestClose={kioskModal.close} kioskCode={kioskModal.item} /> : null}

      {sendFormsModal.isOn ? (
        <SendFormsModal
          patientId={patient.id}
          inPersonFormTitles={inPersonFormTitles}
          hasSomeSelectedCompleted={hasSomeSelectedCompleted}
          isSending={isSending}
          onSendForms={handleSendFormTasks}
          onRequestClose={sendFormsModal.off}
        />
      ) : null}

      {deleteFormsModal.isOn ? (
        <DeleteFormsModal
          selectedCount={selectedCount}
          isDeleting={isDeleting}
          onDelete={handleDeleteFormTasks}
          onCancel={deleteFormsModal.off}
        />
      ) : null}
    </ActionsRow>
  );
};
