import { getNextPageParam, makeInfiniteQuery, makeQuery } from "@libs/utils/queries";
import { oneMinuteCache } from "@libs/utils/queryCache";

export interface MessagesQuery {
  patientId?: number;
  searchString?: string;
  type?: "CHAT" | "EMAIL" | "CALL" | "SMS";
  pageNumber: number;
  pageSize: number;
}

export const getPatientMessages = makeInfiniteQuery({
  queryKey: ["practices", "getPatientMessages"],
  formatParams: ({
    patientId,
    practiceId,
    ...rest
  }: { patientId: number; practiceId: number } & MessagesQuery) => [patientId, practiceId, rest],
  queryOptions: {
    getNextPageParam,
    ...oneMinuteCache,
  },
});

export const getInfinitePracticeMessages = makeInfiniteQuery({
  queryKey: ["practices", "getPracticeMessages"],
  formatParams: ({ practiceId, ...rest }: { practiceId: number } & MessagesQuery) => [practiceId, rest],
  queryOptions: {
    getNextPageParam,
    ...oneMinuteCache,
  },
});

export const getPracticeMessages = makeQuery({
  queryKey: ["practices", "getPracticeMessages"],
  formatParams: ({ practiceId, ...rest }: { practiceId: number } & MessagesQuery) => [practiceId, rest],
  queryOptions: oneMinuteCache,
});

export const getPatientMessagesOverview = makeQuery({
  queryKey: ["practices", "getPatientMessagesOverview"],
  formatParams: (args: { patientId: number; practiceId: number }) => [args.patientId, args.practiceId],
  queryOptions: oneMinuteCache,
});
