import { FC } from "react";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus.svg";
import { CurrentElement } from "components/Settings/Forms/types";
import { getAddingElementAtLocation, isElementBeingAdded } from "components/Settings/Forms/utils";
import { DraftElementStructure } from "components/Settings/Forms/FormStructureElement";

interface Props {
  onOpenPalette: Func;
  index: number;
  sectionIndex?: number;
  currentElement: CurrentElement | undefined;
  placeholderElement: CurrentElement | undefined;
}

export const ElementButtonAppend: FC<Props> = ({
  currentElement,
  placeholderElement,
  index,
  sectionIndex,
  onOpenPalette,
}) => {
  const elementToBeAddedHere = getAddingElementAtLocation(
    currentElement,
    placeholderElement,
    index,
    sectionIndex
  );
  const elementBeingAdded = isElementBeingAdded(currentElement, placeholderElement);

  return elementToBeAddedHere ? (
    <DraftElementStructure draftElement={elementToBeAddedHere} />
  ) : (
    <div className="flex items-center relative justify-center">
      <Button
        disabled={elementBeingAdded}
        size="small"
        className="gap-x-1 items-center justify-center flex"
        onClick={onOpenPalette}
      >
        <PlusIcon className="fill-white transition-transform h-3 w-3" />
        Element
      </Button>
    </div>
  );
};
