import { FC } from "react";
import { LabCaseReturnReasonVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Button } from "@libs/components/UI/Button";
import { useAccount } from "@libs/contexts/AccountContext";
import { Panel } from "@libs/components/UI/Panel";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { EmptyContent } from "@libs/components/UI/EmptyContent";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { getLabCaseReturnReasons } from "api/lab/queries";
import { useItemModal } from "hooks/useItemModal";
import { LabCaseReturnReasonsTable } from "components/Settings/LabCases/LabCaseReturnReasonsTable";
import { deleteLabCaseReturnReason } from "api/lab/mutations";
import { handleError } from "utils/handleError";
import { LabCaseReturnReasonFlyover } from "components/LabCases/LabCaseReturnReasonFlyover";
import { PanelTitle } from "components/Settings/LabCases/PanelTitle";

import tabletUrl from "assets/images/tablet.svg";

export const LabReturnReasonsSettingsRoute: FC = () => {
  const flyover = useItemModal<LabCaseReturnReasonVO["uuid"] | undefined>(null);
  const { practiceId } = useAccount();
  const [returnReasonsQuery] = useApiQueries([getLabCaseReturnReasons({ args: { practiceId } })]);
  const [deleteLabCaseReturnReasonMutation] = useApiMutations([deleteLabCaseReturnReason]);
  const deleteConfirmation = useItemModal<LabCaseReturnReasonVO["uuid"]>(null);

  const handleDelete = (labCaseReturnReasonUuid: LabCaseReturnReasonVO["uuid"]) => {
    deleteLabCaseReturnReasonMutation.mutate(
      { practiceId, labCaseReturnReasonUuid },
      { onError: handleError, onSuccess: deleteConfirmation.close }
    );
  };

  return (
    <Panel
      className="h-full"
      contentClassName="overflow-y-auto"
      includePadding={false}
      title={<PanelTitle onAdd={() => flyover.open(undefined)}>Lab Case Return Reasons</PanelTitle>}
    >
      <QueryResult queries={[returnReasonsQuery]}>
        {returnReasonsQuery.data?.length === 0 ? (
          <EmptyContent
            text={'At least one return reason is required to set a Lab Case status as "Returned"'}
            alt="No return reasons."
            size="md"
            src={tabletUrl}
          >
            <Button onClick={() => flyover.open(undefined)}>Add a Return Reason</Button>
          </EmptyContent>
        ) : (
          <LabCaseReturnReasonsTable
            returnReasons={returnReasonsQuery.data ?? []}
            onEdit={flyover.open}
            onDelete={deleteConfirmation.open}
          />
        )}
      </QueryResult>
      {flyover.isOpen && (
        <LabCaseReturnReasonFlyover
          labCaseReturnReasonUuid={flyover.item}
          onSave={flyover.close}
          onClose={flyover.close}
        />
      )}
      {deleteConfirmation.isOpen && (
        <ConfirmationModal
          primaryText="Are you sure you want to archive this Return Reason?"
          secondaryText="It won't be available for any new lab orders but will remain attached to any existing orders."
          onCancel={deleteConfirmation.close}
          onConfirm={() => handleDelete(deleteConfirmation.item)}
          isConfirming={false}
        />
      )}
    </Panel>
  );
};
