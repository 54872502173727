import { FC } from "react";
import { IPFilterVO } from "@libs/api/generated-api";
import { Banner } from "@libs/components/UI/Banner";
import { AfterAddingIp, FirstIp, GlobalIp } from "components/Settings/Security/IPAuthorization/Messages";
import { isGlobalIp } from "components/Settings/Security/IPAuthorization/utils";

interface Props {
  ipFilters: IPFilterVO[];
  isEditing: boolean;
}

export const ModalBannerMessaging: FC<Props> = ({ ipFilters, isEditing }) => {
  const hasGlobalFilter = ipFilters.some(isGlobalIp);

  return hasGlobalFilter ? null : (
    <div className="mb-5 text-xs">
      {ipFilters.length ? (
        isEditing ? null : (
          <Banner className="rounded" theme="info">
            <GlobalIp />
          </Banner>
        )
      ) : (
        <Banner className="rounded" theme="warning" contentAlignment="start">
          <div className="flex flex-col gap-y-3">
            <FirstIp />
            <AfterAddingIp />
          </div>
        </Banner>
      )}
    </div>
  );
};
