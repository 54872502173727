import { FC, PropsWithChildren } from "react";
import { cx } from "@libs/utils/cx";

export const FormSection: FC<PropsWithChildren<{ title?: string; cols: "2" | "3" }>> = ({
  title,
  cols,
  children,
}) => {
  return (
    <div className="flex flex-col gap-y-3 col-span-full">
      {title && <div className="text-sm font-sansSemiBold">{title}</div>}
      <div className={cx("grid gap-6", cols === "2" ? "grid-cols-2" : "grid-cols-3")}>{children}</div>
    </div>
  );
};
