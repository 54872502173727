import { ClaimUploadAttachmentsRequest, SuccessResponseClaimVO, ClaimVO } from "@libs/api/generated-api";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { getQueryKey } from "@libs/utils/queries";
import { updateCachedData } from "@libs/utils/queryCache";
import { useQueryClient } from "@tanstack/react-query";
import { useContext, useCallback } from "react";
import { useAccount } from "@libs/contexts/AccountContext";

/*
    This doesn't use makeMutation because generated-api file does not support forms
    with an array of files, so we had to handle it manually. See: generated-api.ts,
    contentFormatters for ContentType.FormData.
*/
export const useUploadAttachments = () => {
  const { fetchData } = useContext(ApiClientContext);
  const { practiceId } = useAccount();
  const queryClient = useQueryClient();

  return useCallback(
    async (claimUuid: string, metadata: ClaimUploadAttachmentsRequest, files: File[]) => {
      const formData = new FormData();

      files.forEach((file) => formData.append("file", file));
      formData.append("metadata", new Blob([JSON.stringify(metadata)], { type: "application/json " }));

      const result = await fetchData<SuccessResponseClaimVO["data"]>(
        `/practices/${practiceId}/claims/${claimUuid}/attachment`,
        {
          method: "POST",
          body: formData,
        }
      );

      updateCachedData<ClaimVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getClaim"),
            {
              practiceId,
              claimUuid,
            },
          ],
          exact: true,
        },
        () => result
      );
    },
    [fetchData, practiceId, queryClient]
  );
};
