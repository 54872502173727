import React from "react";
import { ACTIVE_NO_NEXT_APPT_FILTER, DEFAULT_PROCEDURE_TAB_FILTER } from "components/Dashboard/utils/filters";

import { RecallReportQuery } from "utils/routing/dashboard";
import { paths } from "utils/routing/paths";

export const useTableTabProps = ({
  handleRouteStateChange,
  query,
}: {
  handleRouteStateChange: (updates: Partial<RecallReportQuery>) => void;
  query: RecallReportQuery;
}) => {
  return React.useMemo(() => {
    const queryParams = {
      ...query,
      procedureTableSort: undefined,
      patientTableSort: undefined,
    };

    return {
      tabs: [
        {
          text: "Show by Patient",
          to: paths.dashboardRecall(
            { tableTab: "patients" },
            { ...queryParams, filters: ACTIVE_NO_NEXT_APPT_FILTER }
          ),
        },
        {
          text: "Show by Procedure",
          to: paths.dashboardRecall(
            { tableTab: "procedures" },
            { ...queryParams, filters: DEFAULT_PROCEDURE_TAB_FILTER }
          ),
        },
      ],
      query,
      onUpdateParams: handleRouteStateChange,
    };
  }, [handleRouteStateChange, query]);
};
