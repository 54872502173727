import React from "react";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useAccount } from "@libs/contexts/AccountContext";

import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { DashboardLayout } from "components/Dashboard/DashboardLayout";
import { SelectReportingQuestion } from "components/Dashboard/SelectReportingQuestion";
import { cxStyles } from "components/Dashboard/DashboardPageHeader";
import { getPatientWalletStatsQuery } from "api/reporting/queries";
import { MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";
import { PatientWalletTable } from "components/Dashboard/PatientWallet/PatientWalletTable";
import { useQueryParams } from "hooks/useQueryParams";
import { PatientWalletQuery } from "utils/routing/dashboard";

export const PatientWalletRoute: React.FC = () => {
  const { practiceId } = useAccount();
  const { query, updateQuery } = useQueryParams("dashboardPatientWallet");
  const handleRouteStateChange = React.useCallback(
    (updates: Partial<PatientWalletQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );
  const { search: debouncedSearch } = useDebouncedSearch(query.patientSearch ?? "");

  const patientWalletInfiniteQuery = useInfiniteApiQuery(
    getPatientWalletStatsQuery({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: MAX_PAGE_SIZE,
        data: {
          includeTotal: true,
          patientSearchString: debouncedSearch,
          sortOrders: query.tableSort,
        },
      },
    })
  );

  return (
    <DashboardLayout
      className="min-w-[500px]"
      contentClassName="flex flex-col"
      header={
        <div className={cxStyles.pageHeader}>
          <SelectReportingQuestion question="patient-wallet" />
        </div>
      }
    >
      <PatientWalletTable
        patientWalletInfiniteQuery={patientWalletInfiniteQuery}
        onUpdateParams={handleRouteStateChange}
        query={query}
      />
    </DashboardLayout>
  );
};
