import { useCallback, useMemo } from "react";
import {
  DentalProcedureVO,
  ProcedureShortcutCategoryVO,
  ProcedureShortcutVO,
  ProviderVO,
} from "@libs/api/generated-api";

import { cx } from "@libs/utils/cx";
import {
  selectablePatientProcedures,
  SelectableProcedureStatus,
} from "components/Charting/patientProcedureFieldOptions";
import { ProcedureSelector } from "components/ProcedureSelector/ProcedureSelector";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import {
  cxProcedureShortcutStyles,
  SkeletonShortcut,
  ViewShortcut,
} from "components/Settings/ChartingSettings/ProcedureShortcut";

interface Props {
  procedures: DentalProcedureVO[] | undefined;
  categories: ProcedureShortcutCategoryVO[] | undefined;
  shortcuts: ProcedureShortcutVO[] | undefined;
  providers: ProviderVO[] | undefined;
  selectedCategory?: ProcedureShortcutVO["category"];
  providerId: number | undefined;
  treatmentType: SelectableProcedureStatus | undefined;
  addingFromShortcut: string;
  areShortcutsLoading: boolean;
  onSelectCategory: (categoryId: ProcedureShortcutCategoryVO["category"]) => void;
  onShortcutClick: (shortcut: ProcedureShortcutVO) => void;
  onSelectProcedure: (procedure: DentalProcedureVO) => void;
  onProviderChange: (providerId?: number) => void;
  onTreatmentTypeChange: (treatmentType: SelectableProcedureStatus) => void;
}

export const AddProcedures: React.FC<Props> = ({
  providers,
  categories,
  shortcuts,
  procedures,
  areShortcutsLoading,
  providerId,
  addingFromShortcut,
  treatmentType,
  selectedCategory,
  onSelectCategory,
  onSelectProcedure,
  onShortcutClick,
  onTreatmentTypeChange,
  onProviderChange,
}) => {
  const providerOptions = useMemo(() => {
    return (
      providers
        ?.filter((provider) => provider.jobCategory === "DENTIST")
        .map((provider) => ({
          value: provider.id,
          label: provider.name.fullDisplayName,
        })) || []
    );
  }, [providers]);

  const handleTreatmentTypeChange = useCallback(
    (newValue: SelectableProcedureStatus) => {
      onTreatmentTypeChange(newValue);
    },
    [onTreatmentTypeChange]
  );

  const handleSelectProcedure = useCallback(
    (newValues: DentalProcedureVO[]) => {
      if (newValues.length) {
        onSelectProcedure(newValues[0]);
      }
    },
    [onSelectProcedure]
  );

  const isDisabled = (treatmentType !== "EXISTING_OTHER" && !providerId) || !treatmentType;

  return (
    <div className="h-full flex flex-col gap-y-3">
      <div className="flex-none">
        <h2 className="font-sansSemiBold text-sm">Add Procedures</h2>
        <div className="mt-6 grid grid-cols-2 gap-3">
          <FormFieldSelect
            required={true}
            placeholder="Select Treatment Type"
            value={treatmentType}
            options={selectablePatientProcedures}
            onItemSelected={handleTreatmentTypeChange}
            aria-label="Treatment Type"
            layout="labelIn"
          />
          <FormFieldSelect
            required={treatmentType !== "EXISTING_OTHER"}
            disabled={treatmentType === "EXISTING_OTHER"}
            placeholder="Select Provider"
            value={providerId}
            options={providerOptions}
            onItemChanged={onProviderChange}
            aria-label="Provider"
            layout="labelIn"
          />
        </div>
      </div>
      <div className="flex flex-1 min-h-0">
        <div className="flex-none w-32 mr-3 h-full flex flex-col">
          <div
            className={cx(
              `border
               border-slate-200
               rounded
               overflow-y-auto
               flex-grow
               flex-shrink
               basis-0
               min-h-0`,
              !isDisabled && "hover:border-primaryTheme focus-within:border-primaryTheme"
            )}
          >
            {categories?.map((cat) => (
              <button
                type="button"
                onClick={() => onSelectCategory(cat.category)}
                disabled={isDisabled}
                className={cx(
                  "text-left text-xs px-3 py-0.5 w-full whitespace-nowrap",
                  isDisabled ? "text-greyLight" : "hover:bg-slate-100 focus:bg-slate-100",
                  selectedCategory === cat.category && "bg-slate-100 font-sansSemiBold"
                )}
                key={cat.category}
              >
                {cat.displayName}
              </button>
            ))}
          </div>
        </div>
        <div className="flex-1 flex flex-col h-full">
          <div
            className={`
              flex-grow
              flex-shrink
              basis-0
              min-h-0
              overflow-y-auto
              p-[1px]
            `}
          >
            {areShortcutsLoading ? (
              <SkeletonShortcut />
            ) : (
              <div className={cxProcedureShortcutStyles.grid}>
                {shortcuts?.map((shortcut) => (
                  <ViewShortcut
                    isSaving={addingFromShortcut === shortcut.uuid}
                    key={shortcut.uuid}
                    shortcut={shortcut}
                    isDisabled={isDisabled}
                    onClick={() => onShortcutClick(shortcut)}
                  />
                ))}
              </div>
            )}
          </div>
        </div>
      </div>

      <ProcedureSelector
        disabled={isDisabled}
        layout="labelIn"
        procedures={procedures}
        aria-label="CDT Code"
        placeholder="Search/select procedure code"
        onChange={handleSelectProcedure}
      />
    </div>
  );
};
