import {
  CreatePatientNoteRequest,
  UpdatePatientNoteRequest,
  PatientNoteVO,
  UpsertClinicalNoteWithFormsRequest,
} from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import { updatePaginatedCachedData } from "@libs/utils/queryCache";
import { QueryClient } from "@tanstack/react-query";
import {
  invalidateAlerts,
  invalidateAppointmentLists,
  invalidateNoteAppointment,
  invalidateNoteDetails,
  invalidateNoteLists,
} from "api/notes/cache";
import { trackIniatedWebSocketEvents } from "storage/initiatedWebSocketEventTimestamps";

const handleUpdateNote = (
  queryClient: QueryClient,
  {
    practiceId,
    patientNoteUuid,
    patientId,
    oldIsAlert,
  }: { practiceId: number; patientId: number; patientNoteUuid: string; oldIsAlert?: boolean },
  updatedNote: PatientNoteVO
) => {
  trackIniatedWebSocketEvents("PATIENT_NOTE_UPDATED");
  invalidateNoteDetails({ queryClient, practiceId, patientNoteUuid });

  // we don't need to update alerts or appointment tag info if the note
  // being updated is not an alert and the alert status isn't changing
  if (oldIsAlert || updatedNote.isAlert) {
    invalidateAlerts({ queryClient, practiceId, patientId });
    invalidateAppointmentLists({ queryClient, practiceId, patientId });
  }

  invalidateNoteLists({ queryClient, practiceId, patientId });

  // only update appointment detail queries if the note is attached to an appointment
  if (updatedNote.appointment?.id) {
    invalidateNoteAppointment({
      queryClient,
      practiceId,
      patientId,
      appointmentId: updatedNote.appointment.id,
    });
  }
};

const handleCreateNote = (
  queryClient: QueryClient,
  { practiceId, patientId }: { practiceId: number; patientId: number },
  updatedNote: PatientNoteVO
) => {
  trackIniatedWebSocketEvents("PATIENT_NOTE_UPDATED");
  invalidateNoteLists({ queryClient, practiceId, patientId });

  if (updatedNote.isAlert) {
    invalidateAlerts({ queryClient, practiceId, patientId });
    invalidateAppointmentLists({ queryClient, practiceId, patientId });
  }
};

const handleArchiveChange = (
  queryClient: QueryClient,
  { practiceId, patientId }: { practiceId: number; patientId: number },
  updatedNote: PatientNoteVO
) => {
  trackIniatedWebSocketEvents("PATIENT_NOTE_UPDATED");

  // You can't both archive/unarchive and change the alert status of a note in the same mutation
  // So only invalidate alerts and appointment tags if the note is an alert
  if (updatedNote.isAlert) {
    invalidateAlerts({ queryClient, practiceId, patientId });
    invalidateAppointmentLists({ queryClient, practiceId, patientId });
  }

  invalidateNoteLists({ queryClient, practiceId, patientId });

  // can't archive a note attached to an appointment so no need to decache appointment details
};

export const createPatientNote = makeMutation({
  mutationKey: ["practices", "createPatientNote"],
  formatParams: (args: { practiceId: number; patientId: number; data: CreatePatientNoteRequest }) => [
    args.practiceId,
    args.patientId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, patientId }) => {
      handleCreateNote(queryClient, { practiceId, patientId }, response.data.data);
    },
  }),
});

export const updatePatientNote = makeMutation({
  mutationKey: ["practices", "updatePatientNote"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    patientNoteUuid: string;
    original: {
      isAlert?: boolean;
    };
    data: UpdatePatientNoteRequest;
  }) => [args.practiceId, args.patientId, args.patientNoteUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, original, patientId, patientNoteUuid }) => {
      handleUpdateNote(
        queryClient,
        { practiceId, patientId, patientNoteUuid, oldIsAlert: original.isAlert },
        response.data.data
      );
    },
  }),
});

export const archivePatientNote = makeMutation({
  mutationKey: ["practices", "archivePatientNote"],
  formatParams: (args: { practiceId: number; patientId: number; patientNoteUuid: string }) => [
    args.practiceId,
    args.patientId,
    args.patientNoteUuid,
  ],
  mutationOptions: (queryClient) => ({
    onMutate: ({ practiceId, patientId, patientNoteUuid }) => {
      updatePaginatedCachedData(
        queryClient,
        {
          queryKey: [
            getQueryKey("v2", "getPatientNotesV2"),
            { practiceId, patientId, includeArchived: undefined },
          ],
        },
        (data: PatientNoteVO[]) => {
          return data.filter((item) => item.uuid !== patientNoteUuid);
        }
      );
      updatePaginatedCachedData(
        queryClient,
        {
          queryKey: [
            getQueryKey("v2", "getPatientNotesV2"),
            { practiceId, patientId, includeArchived: true },
          ],
        },
        (notes: PatientNoteVO[]) =>
          notes.map((currentNote) =>
            currentNote.uuid === patientNoteUuid
              ? {
                  ...currentNote,
                  isArchived: true,
                }
              : currentNote
          )
      );
    },
    onError: (_, { practiceId, patientId }) => {
      queryClient.invalidateQueries([getQueryKey("v2", "getPatientNotesV2"), { practiceId, patientId }]);
    },
    onSuccess: (response, { practiceId, patientId }) => {
      handleArchiveChange(queryClient, { practiceId, patientId }, response.data.data);
    },
  }),
});

export const unarchivePatientNote = makeMutation({
  mutationKey: ["practices", "unarchivePatientNote"],
  formatParams: (args: { practiceId: number; patientId: number; patientNoteUuid: string }) => [
    args.practiceId,
    args.patientId,
    args.patientNoteUuid,
  ],
  mutationOptions: (queryClient) => ({
    onMutate: ({ practiceId, patientId, patientNoteUuid }) => {
      updatePaginatedCachedData(
        queryClient,
        {
          queryKey: [
            getQueryKey("v2", "getPatientNotesV2"),
            { practiceId, patientId, includeArchived: true },
          ],
        },
        (notes: PatientNoteVO[]) =>
          notes.map((currentNote) =>
            currentNote.uuid === patientNoteUuid
              ? {
                  ...currentNote,
                  isArchived: false,
                }
              : currentNote
          )
      );
    },
    onError: (_, { practiceId, patientId }) => {
      queryClient.invalidateQueries([getQueryKey("v2", "getPatientNotesV2"), { practiceId, patientId }]);
    },
    onSuccess: (response, { practiceId, patientId, patientNoteUuid }) => {
      handleArchiveChange(queryClient, { practiceId, patientId }, response.data.data);
      invalidateNoteDetails({ queryClient, practiceId, patientNoteUuid });
    },
  }),
});

export const updateClinicalNoteWithForms = makeMutation({
  mutationKey: ["practices", "updateClinicalNoteWithForms"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    clinicalNoteUuid: string;
    original: {
      isAlert?: boolean;
    };
    data: UpsertClinicalNoteWithFormsRequest;
  }) => [args.practiceId, args.patientId, args.clinicalNoteUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, original, patientId, clinicalNoteUuid }) => {
      handleUpdateNote(
        queryClient,
        { practiceId, patientId, patientNoteUuid: clinicalNoteUuid, oldIsAlert: original.isAlert },
        response.data.data
      );
    },
  }),
});

export const createClinicalNoteWithForms = makeMutation({
  mutationKey: ["practices", "createClinicalNoteWithForms"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    data: UpsertClinicalNoteWithFormsRequest;
  }) => [args.practiceId, args.patientId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, patientId }) => {
      handleCreateNote(queryClient, { practiceId, patientId }, response.data.data);
    },
  }),
});
