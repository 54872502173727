import {
  CreateIPFilterRequest,
  IPFilterVO,
  PearlOrganizationProcessAgreementsUrlsRequired,
  PracticeImagingSettingsVO,
  ProviderSchedulingRequest,
  UpdateIPFilterRequest,
} from "@libs/api/generated-api";
import { formatAsISODate } from "@libs/utils/date";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import { updateCachedData, updateCachedListWithUpdatedItem } from "@libs/utils/queryCache";

export const addIpFilter = makeMutation({
  mutationKey: ["practices", "addIpFilter"],
  formatParams: (args: { practiceId: number; data: CreateIPFilterRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      updateCachedData<IPFilterVO[]>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getIpFilters"), { practiceId }], exact: true },
        (oldData: IPFilterVO[]) => {
          oldData.push(response.data.data);

          return oldData;
        }
      );
    },
  }),
});

export const updateIpFilter = makeMutation({
  mutationKey: ["practices", "updateIpFilter"],
  formatParams: (args: { practiceId: number; ipFilterUuid: string; data: UpdateIPFilterRequest }) => [
    args.practiceId,
    args.ipFilterUuid,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { ipFilterUuid, practiceId }) => {
      updateCachedData<IPFilterVO[]>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getIpFilters"), { practiceId }], exact: true },
        (oldData: IPFilterVO[]) => {
          const index = oldData.findIndex((oldIpFilter) => oldIpFilter.uuid === ipFilterUuid);

          oldData.splice(index, 1, response.data.data);

          return oldData;
        }
      );
    },
  }),
});

export const deleteIpFilter = makeMutation({
  mutationKey: ["practices", "deleteIpFilter"],
  formatParams: (args: { practiceId: number; ipFilterUuid: string }) => [args.practiceId, args.ipFilterUuid],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { ipFilterUuid, practiceId }) => {
      updateCachedData<IPFilterVO[]>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getIpFilters"), { practiceId }], exact: true },
        (oldData: IPFilterVO[]) => {
          return oldData.filter((ipFilter) => ipFilter.uuid !== ipFilterUuid);
        }
      );
    },
  }),
});

export const updateProviderScheduling = makeMutation({
  mutationKey: ["practices", "updateProviderScheduling"],
  formatParams: (args: { practiceId: number; data: ProviderSchedulingRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      const newProviderData = response.data.data;

      updateCachedListWithUpdatedItem(
        queryClient,
        { queryKey: [["practices", "getAllProviders"], { practiceId }] },
        newProviderData,
        "id"
      );
    },
  }),
});

export const onboardPracticeWithPearl = makeMutation({
  mutationKey: ["practices", "onboardPracticeWithPearl"],
  formatParams: (args: { practiceId: number; data: PearlOrganizationProcessAgreementsUrlsRequired }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      updateCachedData<PracticeImagingSettingsVO>(
        queryClient,
        {
          exact: false,
          queryKey: [
            getQueryKey("practices", "getPracticeImagingSettings"),
            {
              practiceId,
            },
          ],
        },
        (settings) => {
          return {
            ...settings,
            dateOnboardedWithPearl: formatAsISODate(new Date()),
          };
        }
      );
    },
  }),
});
