import { FC } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import bankNotesImage from "assets/images/stack-of-banknotes-coins-and-credit-card.svg";
import { getFinixMerchantIdentity } from "api/billing/queries";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { FinixOnboardingStatus } from "components/Settings/Payments/FinixOnboardingStatus";
import { SettingsHeroContainer, SettingsHeroContent } from "components/Settings/SettingsHeroLayout";

export const BillingMerchantRoute: FC = () => {
  const { practiceId } = useAccount();

  const [finixMerchantIdentityQuery] = useApiQueries([getFinixMerchantIdentity({ args: { practiceId } })]);

  return (
    <SettingsPanel title="Billing Merchant">
      <QueryResult queries={[finixMerchantIdentityQuery]}>
        {finixMerchantIdentityQuery.data ? (
          <SettingsHeroContent className="flex flex-col gap-y-6">
            <SettingsHeroContainer
              image={<img src={bankNotesImage} alt="Stack of bank notes, coins, and credit card" />}
            >
              <FinixOnboardingStatus onboardingState={finixMerchantIdentityQuery.data.onboardingState} />
            </SettingsHeroContainer>
          </SettingsHeroContent>
        ) : null}
      </QueryResult>
    </SettingsPanel>
  );
};
