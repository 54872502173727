import { FC } from "react";
import { PatientNoteVO } from "@libs/api/generated-api";
import { Pill, PillProps } from "@libs/components/UI/Pill";

const statusMap: Record<
  NonNullable<PatientNoteVO["status"]>,
  { theme: NonNullable<PillProps["theme"]>; text: string; Icon?: IconComponent }
> = {
  INCOMPLETE: {
    theme: "red",
    text: "Incomplete",
  },
  COMPLETED: {
    theme: "green",
    text: "Completed",
  },
  DRAFT: {
    theme: "orange",
    text: "Saved",
  },
};

export const NoteStatusPill: FC<{
  status: NonNullable<PatientNoteVO["status"]>;
}> = ({ status }) => {
  const { theme, text } = statusMap[status];

  return (
    <Pill round={false} className="flex items-center gap-x-1" theme={theme}>
      {text}
    </Pill>
  );
};
