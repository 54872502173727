import { FC, FormEvent, useCallback } from "react";
import { AdditionalEarningVO } from "@libs/api/generated-api";
import { centsToCurrencyValue, currencyValueToCents } from "@libs/utils/currency";
import { useObjectState } from "@libs/hooks/useObjectState";
import { Button } from "@libs/components/UI/Button";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter, ModalForm } from "@libs/components/UI/ModalComponents";
import { FormFieldCurrencyInput } from "components/UI/FormFieldCurrencyInput";

interface Props {
  earnings: AdditionalEarningVO[];
  onSave: (updated: AdditionalEarningVO[]) => void;
  onClose: Func;
}

export const EarningsModal: FC<Props> = ({ earnings, onClose, onSave }) => {
  const [{ bonus, commission, reimbursements, corrections }, updateEarnings] = useObjectState(() => {
    const bonusItem = earnings.find((e) => e.type === "BONUS");
    const commissionItem = earnings.find((e) => e.type === "COMMISSION");
    const reimbursementsItem = earnings.find((e) => e.type === "REIMBURSEMENT");
    const correctionsItem = earnings.find((e) => e.type === "CORRECTION_PAYMENT");

    return {
      bonus: centsToCurrencyValue(bonusItem?.amount),
      commission: centsToCurrencyValue(commissionItem?.amount),
      reimbursements: centsToCurrencyValue(reimbursementsItem?.amount),
      corrections: centsToCurrencyValue(correctionsItem?.amount),
    };
  });

  const handleSave = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const items: AdditionalEarningVO[] = [
        { type: "BONUS", amount: currencyValueToCents(bonus) ?? 0 },
        { type: "COMMISSION", amount: currencyValueToCents(commission) ?? 0 },
        { type: "REIMBURSEMENT", amount: currencyValueToCents(reimbursements) ?? 0 },
        { type: "CORRECTION_PAYMENT", amount: currencyValueToCents(corrections) ?? 0 },
      ];

      return onSave(items.filter((i) => i.amount > 0));
    },
    [bonus, commission, reimbursements, corrections, onSave]
  );

  return (
    <Modal centerVertically={false} size="sm" title="Additional Earnings" onClose={onClose}>
      <ModalForm onSubmit={handleSave}>
        <ModalContent padding="lg">
          <div className="gap-6 grid grid-cols-3">
            <FormFieldCurrencyInput
              value={bonus}
              label="Bonus"
              onValueChange={(val) => updateEarnings({ bonus: val })}
            />
            <FormFieldCurrencyInput
              value={commission}
              label="Commission"
              onValueChange={(val) => updateEarnings({ commission: val })}
            />
            <FormFieldCurrencyInput
              value={reimbursements}
              label="Reimbursement"
              onValueChange={(val) => updateEarnings({ reimbursements: val })}
            />
            <FormFieldCurrencyInput
              value={corrections}
              label="Correction Payment"
              onValueChange={(val) => updateEarnings({ corrections: val })}
            />
          </div>
        </ModalContent>
        <ModalFooter>
          <Button onClick={onClose} className="min-w-button" theme="secondary">
            Cancel
          </Button>
          <Button type="submit" className="min-w-button">
            Save
          </Button>
        </ModalFooter>
      </ModalForm>
    </Modal>
  );
};
