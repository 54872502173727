import React from "react";
import { format } from "date-fns";
import { WorkingHourItemVO } from "@libs/api/generated-api";
import { ANY_DAY, getLocalDate } from "@libs/utils/date";
import { sentenceCase } from "@libs/utils/casing";
import {
  GroupedWorkingHourInterval,
  groupWorkingHoursByDay,
  sortGroupedWorkingHours,
} from "utils/workingHoursUtil";

const ViewWorkingHourInterval = ({
  dayOfWeek,
  index,
  hour,
}: {
  index: number;
  dayOfWeek: WorkingHourItemVO["dayOfWeek"];
  hour: GroupedWorkingHourInterval;
}) => (
  <div className="contents text-xs font-sansSemiBold text-greyMedium">
    {index === 0 ? <div>{sentenceCase(dayOfWeek)}</div> : <div />}

    <div>
      {hour.startTime === "00:00:00" ? (
        "-"
      ) : (
        <p>{format(getLocalDate(ANY_DAY, hour.startTime), "hh:mm aaa")}</p>
      )}
    </div>
    <div>
      {hour.endTime === "00:00:00" ? "-" : <p>{format(getLocalDate(ANY_DAY, hour.endTime), "hh:mm aaa")}</p>}
    </div>
    <div>{hour.isSelfBookable ? "Enabled" : "Disabled"}</div>
  </div>
);

export const ViewWorkingHours: React.FC<{ data: WorkingHourItemVO[] }> = ({ data }) => {
  let groupedWorkingHours = groupWorkingHoursByDay(data);

  groupedWorkingHours = sortGroupedWorkingHours(groupedWorkingHours);

  return (
    <div className="grid max-w-md gap-3 grid-cols-4">
      <div className="text-xs">Day</div>
      <div className="text-xs">Open From</div>
      <div className="text-xs">Open Until</div>
      <div className="text-xs">Online Booking</div>
      {groupedWorkingHours.map((item) =>
        item.hours.map((hour, index) => (
          <ViewWorkingHourInterval
            key={`${item.dayOfWeek}${hour.id}`}
            dayOfWeek={item.dayOfWeek}
            hour={hour}
            index={index}
          />
        ))
      )}
    </div>
  );
};
