import { differenceInDays } from "date-fns";
import { useMemo } from "react";
import { UncollectedByServiceEntryVO } from "@libs/api/generated-api";
import { formatISODate, getLocalDate } from "@libs/utils/date";
import { formatCurrency } from "@libs/utils/currency";
import { EMPTY_CELL, Row } from "@libs/components/UI/GridTableComponents";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";
import { paths } from "utils/routing/paths";
import { PatientNameLink } from "components/Dashboard/Tables/PatientNameLink";
import { AppointmentDateLinkCell } from "components/Dashboard/PracticeProduction/TableItems/AppointmentDateLinkCell";
import { labelForServiceEntryType } from "components/Dashboard/OutstandingCollections/utils";

type Props = {
  data: UncollectedByServiceEntryVO;
  last?: boolean;
};
export const ByServiceRow: React.FC<Props> = ({ data, last }) => {
  const {
    patient,
    provider,
    serviceDate,
    appointmentId,
    entryType,
    patientBalance,
    insuranceBalance,
    outstandingBalance,
    uninvoicedBalance,
    totalPatientBalance,
  } = data;
  const cellProps = { last, to: paths.patientBilling({ patientId: patient.id }) };
  const appointmentDetails = {
    appointmentId,
    patientId: patient.id,
  };
  const ageInDays = useMemo(
    () => differenceInDays(new Date(), getLocalDate(serviceDate)) || EMPTY_CELL,
    [serviceDate]
  );

  return (
    <Row>
      <DashboardLinkCell {...cellProps}>
        <PatientNameLink patient={patient} />
      </DashboardLinkCell>
      {appointmentId ? (
        <AppointmentDateLinkCell {...cellProps} {...appointmentDetails} date={serviceDate} />
      ) : (
        <DashboardLinkCell {...cellProps}> {formatISODate(serviceDate)}</DashboardLinkCell>
      )}
      <DashboardLinkCell {...cellProps}>{labelForServiceEntryType(entryType)}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{provider?.shortDisplayName ?? EMPTY_CELL}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{ageInDays}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(outstandingBalance)}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(patientBalance)}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(insuranceBalance)}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(uninvoicedBalance)}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(totalPatientBalance)}
      </DashboardLinkCell>
    </Row>
  );
};
