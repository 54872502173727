import { isAfter } from "date-fns";
import { AddFeeScheduleRequest, UpdateFeeScheduleRequest } from "@libs/api/generated-api";
import { ValidationResult } from "@libs/hooks/useValidation";
import { isString } from "@libs/utils/types";
import { minArrayLen, required } from "@libs/utils/validators";
import { EditableFeeSchedule } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/useEditableFeeSchedule";

type CreateFeeSchedulePostData = {
  data: EditableFeeSchedule;
  allProviderIds: number[] | undefined;
};

export const getFeeSchedulePostData = ({
  data,
  allProviderIds,
}: CreateFeeSchedulePostData): AddFeeScheduleRequest => {
  const { carrierIds, dentalProcedureFees, inNetwork, isProviderUcr, name, providerIds, startDate } = data;

  return {
    // Provider UCRs don't have carriers applicable
    carrierIds: isProviderUcr ? [] : carrierIds,
    // Clear out any fees that were added, then deleted:
    dentalProcedureFees: dentalProcedureFees.filter((item) => item.fee !== undefined),
    inNetwork,
    isProviderUcr: isProviderUcr as boolean,
    name: name as string,
    providerIds: providerIds.length === 0 && allProviderIds ? allProviderIds : providerIds,
    startDate: startDate as string,
  };
};

export const getFeeSchedulePutData = ({
  carrierIds,
  dentalProcedureFees,
  endDate,
  hasEditsToFees,
  inNetwork,
  name,
  providerIds,
  startDate,
}: EditableFeeSchedule & { hasEditsToFees: boolean }): UpdateFeeScheduleRequest => {
  const request: UpdateFeeScheduleRequest = {
    carrierIds,
    endDate,
    inNetwork,
    name: name as string,
    providerIds,
    startDate: startDate as string,
  };

  // Perf optimization, don't update fees unless there are edits
  if (hasEditsToFees) {
    // Clear out any fees that were added, then deleted:
    request.dentalProcedureFees = dentalProcedureFees.filter((item) => item.fee !== undefined);
    request.dentalProcedureFeeIdsToDelete = dentalProcedureFees
      .filter((item) => item.fee === undefined)
      .map((item) => item.procedureId);
  }

  return request;
};

const fieldRequired = [
  {
    $v: required,
    $error: "This field is required",
  },
];

export const getFeeScheduleSchema = (fields: EditableFeeSchedule | undefined) => {
  return {
    carrierIds: [
      {
        $v: minArrayLen(1),
        $error: "Select one or more carriers",
        $ignore: fields?.isProviderUcr || fields?.isPracticeUcr,
      },
    ],
    endDate: [
      {
        $v: (val: unknown) =>
          !val ||
          Boolean(isString(val) && fields?.startDate && isAfter(new Date(val), new Date(fields.startDate))),
        $error: "End date must be after start date",
      },
    ],
    name: fieldRequired,
    providerIds: [
      {
        $v: minArrayLen(1),
        $error: "Select one or more providers",
        $ignore: !fields?.isProviderUcr || fields.isPracticeUcr,
      },
    ],
    startDate: fieldRequired,
    // All other fields are set by default via initial or computed state
  };
};

export type FeeScheduleValidationResult = ValidationResult<
  EditableFeeSchedule,
  ReturnType<typeof getFeeScheduleSchema>
>;
