import { ComponentProps, FormEventHandler } from "react";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { Flyover } from "components/UI/Flyover";
import { FlyoverForm, FlyoverContent, FlyoverFooter } from "components/UI/FlyoverComponents";

interface Props {
  content: ComponentProps<typeof FlyoverContent>["children"];
  isSubmitting: boolean;
  paddingClassName?: ComponentProps<typeof FlyoverContent>["paddingClassName"];
  onClose: ComponentProps<typeof Flyover>["onClose"];
  onSubmit: FormEventHandler<HTMLFormElement>;
  title?: ComponentProps<typeof Flyover>["title"];
}

export const QueryFiltersFlyover: React.FC<Props> = ({
  content,
  isSubmitting,
  paddingClassName,
  onClose,
  onSubmit,
  title,
}) => {
  return (
    <Flyover onClose={onClose} title={title ?? "Filters"}>
      {({ close }) => (
        <FlyoverForm onSubmit={onSubmit}>
          <FlyoverContent className="text-xs" paddingClassName={paddingClassName}>
            {content}
          </FlyoverContent>
          <FlyoverFooter>
            <Button className="min-w-button" onClick={close} theme="secondary">
              Cancel
            </Button>
            <AsyncButton className="min-w-button" isLoading={isSubmitting} theme="primary" type="submit">
              Apply
            </AsyncButton>
          </FlyoverFooter>
        </FlyoverForm>
      )}
    </Flyover>
  );
};
