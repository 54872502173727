import { FC } from "react";
import { HeaderCheckboxCell } from "@libs/components/UI/GridTableComponents";
import { HeaderCell } from "components/Dashboard/Tables/HeaderCell";
import { useSortHandler } from "components/Dashboard/hooks/useSortHandler";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";
import { HeaderData } from "components/Dashboard/Tables/types";

interface Props {
  headers: HeaderData[];
  onSortClick?: (newSortOrders: ColumnSortOrder[]) => void;
  sortOrders?: ColumnSortOrder[];
  selectedCount?: number;
  totalRows?: number;
  onSelectAllRows?: Func;
  onDeselectAllRows?: Func;
}

export const DashboardHeaderRow: FC<Props> = ({
  headers,
  onSortClick,
  sortOrders,
  selectedCount,
  totalRows,
  onSelectAllRows,
  onDeselectAllRows,
}) => {
  const handleSorting = useSortHandler();
  const hasCheckboxProps = selectedCount != null && totalRows != null && onSelectAllRows && onDeselectAllRows;

  return (
    <>
      {headers.map((props) => {
        if (props.id === "checkbox" && hasCheckboxProps) {
          return (
            <HeaderCheckboxCell
              key={props.id}
              className="border-t border-greyLighter"
              selectedCount={selectedCount}
              totalRows={totalRows}
              onSelectAllRows={onSelectAllRows}
              onDeselectAllRows={onDeselectAllRows}
            />
          );
        }

        const sortOrder = sortOrders?.find((item) => item.field === props.sortField);

        return (
          <HeaderCell
            key={props.label}
            {...props}
            onClick={
              onSortClick && sortOrders
                ? (newSortField) => {
                    onSortClick(handleSorting(sortOrders, newSortField));
                  }
                : undefined
            }
            primarySortField={sortOrders ? sortOrders[0].field : undefined}
            tableSortDirection={sortOrder?.direction}
          />
        );
      })}
    </>
  );
};
