import { ClaimsList } from "components/Claims/Claims/ClaimsList";
import { usePatientClaimsTabs } from "components/PatientProfile/Claims/tabs";
import { usePathParams } from "hooks/usePathParams";
import { PatientClaimsAppHistoryProvider } from "components/PatientProfile/Claims/PatientClaimsLinksContext";

export const ClaimsTabRoute: React.FC = () => {
  const { patientId } = usePathParams("patientClaims");

  const tabs = usePatientClaimsTabs();

  return (
    <PatientClaimsAppHistoryProvider name="claims">
      <ClaimsList routeName="patientClaims" patientId={patientId} tabs={tabs} />
    </PatientClaimsAppHistoryProvider>
  );
};
