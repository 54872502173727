import { FC } from "react";
import { cx } from "@libs/utils/cx";
import { Divider } from "components/UI/Divider";

interface Props {
  step: "practice" | "phone";
  hasInitiated: boolean;
  hasPhoneNumber: boolean;
}

export const SetupTwilioProgress: FC<Props> = ({ step, hasInitiated, hasPhoneNumber }) => {
  return (
    <div className="flex items-center text-xs" data-testid="setup-twilio-progress">
      <Step number={1} isActive={step === "practice"} isComplete={hasInitiated} />
      <Divider />
      <Step number={2} isActive={step === "phone"} isComplete={hasPhoneNumber} />
    </div>
  );
};

const Step: FC<{ number: number; isActive: boolean; isComplete: boolean }> = ({
  number,
  isActive,
  isComplete,
}) => (
  <div
    className={cx(
      "flex items-center justify-center size-6 rounded-full border",
      isComplete
        ? "border-slate-200 bg-slate-200"
        : isActive
          ? "border-blueLight bg-blueLight text-blueDark"
          : "border-slate-200"
    )}
  >
    {number}
  </div>
);
