import { addMinutes } from "date-fns";
import { MbscCalendarEvent } from "@mobiscroll/react";
import { AppointmentVO, ScheduleBlockVO } from "@libs/api/generated-api";
import { getLocalDate, formatAsISODate } from "@libs/utils/date";
import { AppointmentGrouping } from "utils/routing/scheduling";

export interface BaseScheduleEvent extends Omit<MbscCalendarEvent, "id"> {
  id: string;
  resource: number;
  start: Date;
  end: Date;
}

export interface AppointmentEvent extends BaseScheduleEvent {
  appointment: AppointmentVO;
  cardType: "appointment";
}

export interface BlockEvent extends BaseScheduleEvent {
  block: ScheduleBlockVO;
  cardType: "block";
}

export interface HoldEvent extends Omit<MbscCalendarEvent, "id"> {
  appointment: AppointmentVO;
  id: number;
  cardType: "hold";
}

export const createAppointmentEvent = (
  appointment: AppointmentVO,
  groupBy: AppointmentGrouping
): AppointmentEvent => {
  const startDate = appointment.date || formatAsISODate(new Date());
  const startTime = appointment.startTime || "00:00:00";
  const start = getLocalDate(startDate, startTime);
  const end = addMinutes(start, appointment.duration || 0);

  return {
    cardType: "appointment",
    id: `appointment-${appointment.id}`,
    start,
    end,
    title: appointment.patient.shortDisplayName,
    editable: true,
    resource: groupBy === "provider" ? appointment.provider.id : appointment.room.id || 0,
    appointment,
  };
};

export const createBlockEvents = (block: ScheduleBlockVO, groupBy: AppointmentGrouping): BlockEvent[] => {
  const contextList = groupBy === "provider" ? block.providers : block.rooms;

  if (!contextList.length) {
    return [];
  }

  const start = getLocalDate(block.date, block.startTime);
  const end = getLocalDate(block.date, block.endTime);

  return contextList.map(({ id }) => ({
    cardType: "block",
    id: `block-${block.id}-${id}`,
    start,
    end,
    title: block.title,
    editable: true,
    resource: id,
    block,
  }));
};

export const createHoldEvent = (appointment: AppointmentVO): HoldEvent => {
  return {
    cardType: "hold",
    id: appointment.id,
    title: appointment.patient.shortDisplayName,
    editable: true,
    appointment,
    start: appointment.startTime,
    end: appointment.endTime,
  };
};
