import { useMemo } from "react";
import { usePatientClaimsLinks } from "components/PatientProfile/Claims/PatientClaimsLinksContext";

export const usePatientClaimsTabs = () => {
  const { links } = usePatientClaimsLinks();

  return useMemo(() => {
    return [
      {
        exact: true,
        label: "Pre-Auths",
        id: "pre-auths" as const,
        path: links.preAuths,
      },
      {
        exact: true,
        label: "Claims",
        id: "claims" as const,
        path: links.claims,
      },
    ];
  }, [links]);
};
