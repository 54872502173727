import { FC, useCallback, useMemo } from "react";
import { NameVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Modal } from "@libs/components/UI/Modal";
import { ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import { approveWorktimeEntries, deleteWorktimeEntry } from "api/employee/mutations";
import { getTimesheetData, getWorktimeOverview } from "api/employee/queries";
import { TimeSheetTable } from "components/EmployeeProfile/Timesheet/TimeSheetTable";
import { useSelectRows } from "hooks/useSelectRows";
import { handleError } from "utils/handleError";
import { HoursSummary } from "components/EmployeeProfile/Timesheet/HoursSummary";
import { practicePaysDoubleOvertime } from "components/Payroll/utils";

export interface TimeSheetModalProps {
  onClose: Func;
  startDate: string;
  endDate: string;
  employee: NameVO;
  payrollId: string;
}

export const TimeSheetModal: FC<TimeSheetModalProps> = ({
  onClose,
  payrollId,
  startDate,
  endDate,
  employee,
}) => {
  const practice = useCurrentPractice();

  const timesheetArgs = useMemo(
    () => ({
      practiceId: practice.id,
      employeeId: employee.id,
      query: {
        startDate,
        endDate,
      },
    }),
    [startDate, endDate, employee.id, practice.id]
  );

  const [timesheetQuery, worktimeQuery] = useApiQueries([
    getTimesheetData({
      args: timesheetArgs,
    }),
    getWorktimeOverview({
      args: timesheetArgs,
    }),
  ]);

  const selectableWorktimes = useMemo(
    () =>
      timesheetQuery.data?.flatMap((item) =>
        item.worktimeDetails
          .filter(
            ({ status, clockIn, clockOut }) => status !== "APPROVED" && Boolean(clockIn.time && clockOut.time)
          )
          .map(({ id }) => id)
      ),
    [timesheetQuery.data]
  );

  const { selectedRows, resetSelectedRows, handleCheckboxChange, selectAllRows } =
    useSelectRows(selectableWorktimes);

  const [bulkApproveMutation, approveMutation, deleteWorktimeEntryMutation] = useApiMutations([
    approveWorktimeEntries,
    approveWorktimeEntries,
    deleteWorktimeEntry,
  ]);
  const handleApprovals = useCallback(
    (ids: number[]) => {
      bulkApproveMutation.mutate(
        {
          practiceId: practice.id,
          employeeId: employee.id,
          payrollId,
          data: { worktimeIds: ids },
        },
        {
          onSuccess: onClose,
          onError: handleError,
        }
      );
    },
    [bulkApproveMutation, employee.id, payrollId, practice.id, onClose]
  );

  const handleApproval = useCallback(
    (id: number) => {
      approveMutation.mutate(
        {
          practiceId: practice.id,
          employeeId: employee.id,
          payrollId,
          data: { worktimeIds: [id] },
        },
        {
          onError: handleError,
        }
      );
    },
    [approveMutation, employee.id, payrollId, practice.id]
  );

  const handleDeleteWorktimeEntry = useCallback(
    (id: number) => {
      deleteWorktimeEntryMutation.mutate(
        {
          practiceId: practice.id,
          employeeId: employee.id,
          worktimeId: id,
        },
        {
          onError: handleError,
        }
      );
    },
    [deleteWorktimeEntryMutation, employee.id, practice.id]
  );

  return (
    <Modal height={900} title="Worktime Overview & Timesheet" size="2xl" onClose={onClose}>
      <ModalContent padding="lg">
        <QueryResult queries={[timesheetQuery, worktimeQuery]}>
          {timesheetQuery.data && worktimeQuery.data ? (
            <div className="flex flex-col h-full">
              <div className="flex-none mb-4">
                <div className="flex items-center justify-between mb-4">
                  <div className="font-sansSemiBold text-sm">
                    {employee.fullDisplayName} <span className="inline-block w-px h-4 mx-1 bg-greyLight" />{" "}
                    {formatISODate(startDate)} - {formatISODate(endDate)}
                  </div>
                  <HoursSummary
                    hoursInDecimal={false}
                    totalOvertimeHours={worktimeQuery.data.totalOvertimeHours}
                    totalWorkingHours={worktimeQuery.data.totalWorkingHours}
                  />
                </div>
                <div className="flex justify-end">
                  <AsyncButton
                    isLoading={bulkApproveMutation.isLoading}
                    disabled={selectedRows.size === 0}
                    onClick={() => handleApprovals([...selectedRows])}
                    className="w-28"
                  >
                    Approve
                  </AsyncButton>
                </div>
              </div>

              <div
                className={`
                  flex-1
                  min-h-0
                  overflow-y-auto
                  border
                  border-greyLighter
                  rounded
                `}
              >
                <TimeSheetTable
                  showDoubleOvertime={practicePaysDoubleOvertime(practice)}
                  timesheet={timesheetQuery.data}
                  hoursInDecimal={false}
                  employeeId={employee.id}
                  onApproveWorktime={handleApproval}
                  onDeleteWorktime={handleDeleteWorktimeEntry}
                  onSelectAllRows={selectAllRows}
                  onDeselectAllRows={resetSelectedRows}
                  onToggleEmployee={handleCheckboxChange}
                  hasEmployeesPermissions={true}
                  selectedRows={selectedRows}
                  selectableRows={selectableWorktimes}
                  stickyTopClassName="top-0"
                />
              </div>
            </div>
          ) : null}
        </QueryResult>
      </ModalContent>
      <ModalFooter>
        <Button onClick={onClose} className="w-28">
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};
