import { TagVO } from "@libs/api/generated-api";
import { FC, useMemo } from "react";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { getLightColor } from "@libs/domains/scheduling/colors";
import { FlexSelect } from "@libs/components/UI/FlexSelect";
import { Tag } from "components/Notes/Tag";

interface Props {
  selectedTagIds: number[] | undefined;
  tagOptions: TagVO[] | undefined;
  onItemsSelected: (newTagsIds: number[]) => void;
  canRemoveTag?: (tag: TagVO) => boolean;
}

const RemovablePillTag: FC<{
  tag: TagVO;
  canRemove: boolean;
  onRemove: (tag: TagVO) => void;
}> = ({ onRemove, canRemove, tag }) => {
  return (
    <div style={{ backgroundColor: getLightColor(tag.color) }} className="py-0.5 pl-1.5 pr-2.5 h-6 rounded">
      <div className="flex items-center gap-x-1">
        <Tag tag={tag} />
        {canRemove ? (
          <ButtonIcon SvgIcon={CloseIcon} onClick={() => onRemove(tag)} theme="slate900" size="xs" />
        ) : null}
      </div>
    </div>
  );
};

export const TagMultiSelect: FC<Props> = ({ selectedTagIds, tagOptions, onItemsSelected, canRemoveTag }) => {
  const filteredOptions = useMemo(() => {
    return (
      tagOptions
        ?.filter((option) => !selectedTagIds?.find((tagId) => tagId === option.id))
        .map((tag) => ({
          value: tag.id,
          label: <Tag tag={tag} />,
        })) ?? []
    );
  }, [tagOptions, selectedTagIds]);

  const handleRemoveTag = (removeTag: TagVO) => {
    onItemsSelected(selectedTagIds?.filter((id) => id !== removeTag.id) ?? []);
  };

  const handleSelectTag = (option: ListItem<typeof filteredOptions>) => {
    onItemsSelected([...(selectedTagIds ?? []), option.value]);
  };

  return (
    <FlexSelect
      className="min-h-6"
      ids={selectedTagIds}
      addTooltip={
        filteredOptions.length
          ? { content: "Add Tag", theme: "SMALL" }
          : { content: "All tags have been added.", theme: "MEDIUM" }
      }
      options={filteredOptions}
      onItemSelected={handleSelectTag}
      renderValue={(tagId) => {
        const tag = tagOptions?.find((tagOption) => tagOption.id === tagId);

        return tag ? (
          <RemovablePillTag onRemove={handleRemoveTag} canRemove={canRemoveTag?.(tag) ?? true} tag={tag} />
        ) : null;
      }}
    />
  );
};
