import { FC } from "react";
import { PatientNoteVO } from "@libs/api/generated-api";
import { LONG_DAY_OF_YEAR_FORMAT, formatISODate } from "@libs/utils/date";
import { NoteStatusPill } from "components/Notes/NoteStatusPill";

export const ClinicalNote: FC<{
  note: PatientNoteVO;
}> = ({ note }) => {
  return (
    <div className="flex flex-col w-full gap-y-3">
      <h3 className="font-sansSemiBold text-sm">
        {note.appointment ? (
          <div className="flex items-center gap-x-2">
            {formatISODate(note.appointment.date, LONG_DAY_OF_YEAR_FORMAT)}
          </div>
        ) : null}
      </h3>
      {note.text ? (
        <p className="whitespace-pre-wrap break-word text-xs">{note.text}</p>
      ) : (
        <p className="text-greyLight text-xs">Notes have not been added.</p>
      )}

      {note.type === "CHART" && note.status ? (
        <div className="flex">
          <NoteStatusPill status={note.status} />
        </div>
      ) : null}
    </div>
  );
};
