import { useCallback, useState } from "react";
import { OnboardingOptionsVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isDefined } from "@libs/utils/types";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { Button } from "@libs/components/UI/Button";
import { TableGrid, HeaderCell, Row } from "@libs/components/UI/GridTableComponents";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { ReferralsFlyover } from "components/Settings/Forms/ReferralOptions/ReferralsFlyover";
import { ReferralsRow } from "components/Settings/Forms/ReferralOptions/ReferralsRow";

interface Props {
  isSaving: boolean;
  onboardingOptions: OnboardingOptionsVO;
  onUpdateOptions: (updatedState: string[], onSuccess?: Func) => void;
}

const headers = [
  { id: "name", label: "Name", width: "1fr" },
  { id: "actions", label: "", width: "min-content" },
];

export const ReferralsTable: React.FC<Props> = ({ isSaving, onboardingOptions, onUpdateOptions }) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const flyover = useBoolean(false);
  const deleteModal = useBoolean(false);

  const handleClickEdit = useCallback(
    (index: number) => {
      setSelectedIndex(index);
      flyover.on();
    },
    [flyover]
  );

  const handleClickDelete = useCallback(
    (index: number) => {
      deleteModal.on();
      setSelectedIndex(index);
    },
    [deleteModal]
  );

  const deleteOption = useCallback(() => {
    const updatedOptions = onboardingOptions.referralOptions.filter((_, index) => index !== selectedIndex);

    onUpdateOptions(updatedOptions, () => {
      deleteModal.off();
      setSelectedIndex(null);
    });
  }, [deleteModal, onUpdateOptions, onboardingOptions.referralOptions, selectedIndex]);

  const handleClickAdd = useCallback(() => {
    setSelectedIndex(onboardingOptions.referralOptions.length);
    flyover.on();
  }, [flyover, onboardingOptions.referralOptions.length]);

  const handleCloseFlyover = useCallback(() => {
    flyover.off();
    setSelectedIndex(null);
  }, [flyover]);

  return (
    <div className="flex flex-col max-w-xl gap-y-6 w-full text-sm">
      <TableGrid
        className="rounded border border-greyLighter"
        columnWidths={headers.map(({ width }) => width)}
      >
        <Row>
          {headers.map((item) => (
            <HeaderCell key={item.id} rounded={true} size="short" sticky={false}>
              {item.label}
            </HeaderCell>
          ))}
        </Row>
        {onboardingOptions.referralOptions.map((option, index) => (
          <ReferralsRow
            key={index}
            onClickDelete={() => handleClickDelete(index)}
            onClickEdit={() => handleClickEdit(index)}
            referralName={option}
          />
        ))}
        <Row>
          <div className="col-span-full flex items-center h-10 px-3 text-xs border-b-0">
            <Button
              className={`
                flex
                items-center
                p-2
                gap-x-2
                rounded
                hover:bg-whiteLight
                disabled:text-greyLight
                disabled:bg-white
              `}
              onClick={handleClickAdd}
              theme="link"
            >
              <AddIcon className="w-4 h-4" />
              <span className="font-sans">Referral Option</span>
            </Button>
          </div>
        </Row>
      </TableGrid>
      {flyover.isOn && isDefined(selectedIndex) && (
        <ReferralsFlyover
          isEditing={selectedIndex < onboardingOptions.referralOptions.length}
          isSaving={isSaving}
          onClose={handleCloseFlyover}
          onUpdateOptions={onUpdateOptions}
          referralOptions={onboardingOptions.referralOptions}
          selectedIndex={selectedIndex}
        />
      )}
      {deleteModal.isOn && (
        <ConfirmationModal
          isConfirming={isSaving}
          onCancel={deleteModal.off}
          onConfirm={deleteOption}
          primaryText="Delete Referral Option"
          secondaryText="Are you sure you want to delete this referral option?"
          size="2xs"
        />
      )}
    </div>
  );
};
