import { FC, ReactNode, useMemo } from "react";
import { parseISO } from "date-fns";
import { DentalProcedureVO, PatientProcedureVO, PatientToothVO, ProviderVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatAsISODate } from "@libs/utils/date";
import { isOneOf } from "@libs/utils/isOneOf";
import { RadioList } from "@libs/components/UI/RadioList";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { mapSelectionsToOptions } from "@libs/utils/mapSelectOptions";
import {
  defaultAreaOptions,
  priorityOptions,
  implantInstallTypeOptions,
  prognosisOptions,
  getProcedureStatusOptions,
} from "components/Charting/patientProcedureFieldOptions";
import { PatientProcedureValidationResult } from "components/Charting/patientProcedureSchema";
import { BaseFormSectionProps } from "components/UI/FormSection";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { DentalProcedureToothOptions } from "components/Charting/DentalProcedureToothOptions";
import { QuadrantOptions } from "components/Charting/QuadrantOptions";
import { ArchOptions } from "components/Charting/ArchOptions";
import { CollapsibleSection } from "components/UI/CollapsibleSection";
import { TooltipLabel } from "components/UI/TooltipLabel";
import {
  DraftPatientProcedureRequest,
  getMaxImplantReplacementDate,
  getMaxProcedureDate,
} from "components/Charting/draftPatientProcedure";
import { Divider } from "components/UI/Divider";
import { useNow } from "hooks/useNow";

interface Props {
  dentalProcedure: DentalProcedureVO;
  draft: DraftPatientProcedureRequest;
  patientProcedure?: PatientProcedureVO;
  validation: PatientProcedureValidationResult;
  teeth: PatientToothVO[];
  providers: ProviderVO[];
  isExpanded: boolean;
  headerContent: ReactNode;
  validateOptions?: {
    provider?: boolean;
  };
  editStatus?: boolean;
  onUpdateDraft: (updates: Partial<DraftPatientProcedureRequest>) => void;
}

const Section: FC<BaseFormSectionProps> = (props) => (
  <div className={cx("my-6 last:mb-0", props.className)}>
    {props.title ? <div className="font-sansSemiBold text-sm mb-3">{props.title}</div> : null}
    {props.children}
  </div>
);

// eslint-disable-next-line complexity
export const AddProcedureForm: FC<Props> = ({
  dentalProcedure,
  patientProcedure,
  draft,
  validation,
  validateOptions,
  teeth,
  providers,
  isExpanded,
  headerContent,
  editStatus,
  onUpdateDraft,
}) => {
  const now = useNow();
  const providerOptions = useMemo(() => {
    const dentists = providers.filter(({ jobCategory }) => jobCategory === "DENTIST").map((p) => p.name);

    return mapSelectionsToOptions(dentists, patientProcedure?.provider, (name) => ({
      value: name.id,
      label: name.fullDisplayName,
    }));
  }, [providers, patientProcedure]);

  const areaOptions = useMemo(() => {
    const required = Boolean(dentalProcedure.areaSelection?.required);

    const dentalProcedureAreaOptions = dentalProcedure.areaSelection?.options || [];

    return defaultAreaOptions.map((option) => {
      if (option.value === "TOOTH") {
        return {
          ...option,
          disabled:
            !dentalProcedureAreaOptions.includes("TOOTH") &&
            !dentalProcedureAreaOptions.includes("TOOTH_RANGE"),
        };
      }

      if (option.value === "") {
        return {
          ...option,
          disabled: required,
        };
      }

      return {
        ...option,
        disabled: !dentalProcedureAreaOptions.includes(option.value),
      };
    });
  }, [dentalProcedure.areaSelection]);

  const treatmentTypeOptions = useMemo(() => {
    const rejectedOption = { value: "REJECTED" as const, label: "Rejected" };
    const option =
      draft.treatmentType === "DONE"
        ? { value: draft.treatmentType, label: "Completed" }
        : draft.treatmentType === "SCHEDULED"
          ? { value: draft.treatmentType, label: "Scheduled" }
          : undefined;

    return getProcedureStatusOptions(
      option ? [option, { value: "REJECTED", label: "Rejected" }] : [rejectedOption]
    );
  }, [draft.treatmentType]);

  const selectionError =
    validation.toothSelections.$error || validation.quadrant.$error || validation.arch.$error;

  return (
    <div className="even:bg-greyLightest">
      {headerContent}
      <CollapsibleSection isOpen={isExpanded} className="relative z-0">
        <div className="px-8 pb-6">
          <div className="border-b border-b-slate-200" />
          <Section title="Procedure Details" className="relative z-10">
            <div className="flex flex-col gap-y-3">
              <div className="grid grid-cols-4 gap-6">
                <FormFieldSelect
                  label="Treatment Type"
                  display="label"
                  placeholder="Select"
                  value={draft.treatmentType}
                  options={treatmentTypeOptions}
                  onItemSelected={(value) => onUpdateDraft({ treatmentType: value })}
                  disabled={isOneOf(draft.treatmentType, ["SCHEDULED", "DONE"]) || editStatus === false}
                  required
                />
                {draft.treatmentType === "EXISTING_OTHER" ? null : (
                  <FormFieldSelect
                    label="Provider"
                    display="label"
                    placeholder="Select"
                    value={draft.providerId}
                    options={providerOptions}
                    onItemSelected={(value) => onUpdateDraft({ providerId: value })}
                    disabled={
                      isOneOf(draft.treatmentType, ["SCHEDULED", "DONE"]) ||
                      validateOptions?.provider === false
                    }
                    error={validation.providerId.$error}
                    required
                  />
                )}
                {isOneOf(draft.treatmentType, ["EXISTING_CURRENT", "EXISTING_OTHER"]) ? (
                  <FormFieldSelectMenusDatepicker
                    label="Date of Procedure"
                    selected={draft.date ? parseISO(draft.date) : null}
                    onChange={(date) => onUpdateDraft({ date: date ? formatAsISODate(date) : undefined })}
                    maxDate={getMaxProcedureDate(now)}
                    error={validation.date.$error}
                    required
                  />
                ) : (
                  <FormFieldSelect
                    label="Priority"
                    display="label"
                    placeholder="Select"
                    value={draft.priority}
                    options={priorityOptions}
                    onItemChanged={(value) => onUpdateDraft({ priority: value })}
                  />
                )}
                <FormFieldSelect
                  label="Prognosis"
                  display="label"
                  placeholder="Select"
                  value={draft.prognosis}
                  options={prognosisOptions}
                  onItemChanged={(value) => onUpdateDraft({ prognosis: value })}
                />
              </div>

              <Checkbox
                checked={Boolean(draft.creditToPractice)}
                onChange={(e) => onUpdateDraft({ creditToPractice: e.target.checked })}
              >
                <TooltipLabel
                  tooltip={{
                    content:
                      "When selected, this procedure will not be credited to a specific hygienist or doctor.",
                  }}
                >
                  Credit to Practice
                </TooltipLabel>
              </Checkbox>
            </div>
          </Section>

          <Divider />

          <Section title="Select Area" className="relative z-0">
            <RadioList
              layout="horiz"
              error={validation.areaType.$error}
              selectedValue={draft.areaType}
              onChange={(_e, value) => onUpdateDraft({ areaType: value.value })}
              options={areaOptions}
            />
            <div className="mt-2">
              {draft.areaType === "TOOTH" ? (
                <DentalProcedureToothOptions
                  dentalProcedure={dentalProcedure}
                  teeth={teeth}
                  toothSelections={draft.toothSelections}
                  surfaceSelections={draft.surfaces}
                  onUpdateDraft={onUpdateDraft}
                />
              ) : draft.areaType === "QUADRANT" ? (
                <QuadrantOptions
                  dentalProcedure={dentalProcedure}
                  teeth={teeth}
                  quadrant={draft.quadrant}
                  onUpdateDraft={onUpdateDraft}
                />
              ) : draft.areaType === "ARCH" ? (
                <ArchOptions
                  dentalProcedure={dentalProcedure}
                  teeth={teeth}
                  arch={draft.arch}
                  onUpdateDraft={onUpdateDraft}
                />
              ) : null}
              <div className="flex justify-between items-center mt-2">
                {selectionError ? <FormFieldError>{selectionError}</FormFieldError> : null}
                {validation.surfaces.$error ? (
                  <FormFieldError className="flex-none w-36 ml-auto">
                    {validation.surfaces.$error}
                  </FormFieldError>
                ) : null}
              </div>
            </div>
          </Section>

          {dentalProcedure.prosthetic ? (
            <Section title="Crown, Bridge, Denture Details">
              <RadioList
                layout="horiz"
                error={validation.implantInstallType.$error}
                selectedValue={draft.implantInstallType}
                onChange={(_e, value) => onUpdateDraft({ implantInstallType: value.value })}
                options={implantInstallTypeOptions}
              />
              {draft.implantInstallType === "REPLACEMENT" ? (
                <FormFieldSelectMenusDatepicker
                  label="Prior Date of Placement"
                  error={validation.implantDate.$error}
                  selected={draft.implantDate ? parseISO(draft.implantDate) : null}
                  className="mt-2 w-1/4"
                  required={true}
                  maxDate={getMaxImplantReplacementDate(now)}
                  onChange={(date) =>
                    onUpdateDraft({ implantDate: date ? formatAsISODate(date) : undefined })
                  }
                />
              ) : null}
            </Section>
          ) : null}
        </div>
      </CollapsibleSection>
    </div>
  );
};
