import { FC } from "react";
import { FormVO } from "@libs/api/generated-api";
import { titleCaseConstant } from "@libs/utils/casing";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as Trash } from "@libs/assets/icons/delete.svg";
import { FormStateBadge } from "components/Settings/Forms/FormStateBadge";

interface Props {
  formState: FormVO["state"];
  onDiscard: Func;
  discardDisabled: boolean;
}

export const EditFormBadge: FC<Props> = ({ formState, discardDisabled, onDiscard }) => {
  return formState === "UNPUBLISHED_CHANGES" ? (
    <FormStateBadge formState={formState}>
      <div className="flex items-center">
        {titleCaseConstant(formState)}
        <ButtonIcon
          disabled={discardDisabled}
          tooltip={{ content: "Discard Changes" }}
          theme="warning"
          size="sm"
          className="ml-2"
          SvgIcon={Trash}
          onClick={onDiscard}
        />
      </div>
    </FormStateBadge>
  ) : (
    <FormStateBadge formState={formState} />
  );
};
