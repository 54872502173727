import React from "react";
import {
  UncollectedByCarrierEntryVO,
  UncollectedByCarrierResponse,
  UncollectedByCarrierSubtotalVO,
} from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { usePick } from "@libs/hooks/usePick";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { FooterCell } from "components/Dashboard/Tables/FooterCell";
import { ByCarrierClaimRow } from "components/Dashboard/OutstandingCollections/ByCarrierClaimRow";
import { ByCarrierClaimFooterRow } from "components/Dashboard/OutstandingCollections/ByCarrierClaimFooterRow";
import { useQueryParams } from "hooks/useQueryParams";
import { DashboardTableFilters } from "components/Dashboard/Tables/DashboardTableFilters";
import { useFilterTokenProps } from "components/Dashboard/hooks/useFilterTokenProps";
import { getClaimInsuranceCarriersQuery } from "api/patientInsurance/queries";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import { MAX_PAGE_SIZE, getTotals } from "components/Dashboard/Tables/utils";
import { flattenCollectionByCarrier } from "components/Dashboard/utils/flattenCollectionByCarrier";
import { CarrierHeaderRow } from "components/Dashboard/Tables/CarrierHeaderRow";
import { UNCOLLECTED_BY_CARRIER_COLUMNS } from "components/Dashboard/OutstandingCollections/constants";
import { DashboardTableTabs } from "components/Dashboard/Tables/DashboardTableTabs";
import { ByCarrierFilters } from "components/Dashboard/OutstandingCollections/ByCarrierFilters";
import { useTableTabProps } from "components/Dashboard/OutstandingCollections/hooks/useTableTabProps";
import { useOutstandingCollectionsSelectedTimeSegment } from "components/Dashboard/OutstandingCollections/hooks/useOutstandingCollectionsSelectedTimeSegment";
import { getUncollectedByCarrierQuery } from "api/reporting/queries";
import { dashboardFiltersForTables } from "components/Dashboard/utils/filters";
import { useDownloadOutstandingCollectionsByCarrierCSV } from "components/Dashboard/OutstandingCollections/hooks/useDownloadOutstandingCollectionsByCarrierCSV";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";

export const ByCarrierTableRoute: React.FC = () => {
  const { practiceId } = useAccount();
  const { updateQuery, query } = useQueryParams("dashboardPaymentOutstanding");

  const selectedTimeSegment = useOutstandingCollectionsSelectedTimeSegment();

  const { search, isWaiting } = useDebouncedSearch(query.patientSearch ?? "");
  const sortOrders = query.carrierTableSort;
  const outstandingCollectionsByCarrierQuery = useInfiniteApiQuery(
    getUncollectedByCarrierQuery({
      args: {
        practiceId,
        pageSize: MAX_PAGE_SIZE,
        pageNumber: 1,
        data: {
          includeTotal: true,
          ...selectedTimeSegment,
          patientSearchString: search,
          filters: dashboardFiltersForTables(query.filters),
          sortOrders,
        },
      },
    })
  );
  const isLoading = isWaiting || outstandingCollectionsByCarrierQuery.isLoading;
  const totals = getTotals(outstandingCollectionsByCarrierQuery.data);
  const [insuranceCarriersQuery] = useApiQueries([getClaimInsuranceCarriersQuery({ args: { practiceId } })]);
  const totalRows = getInfiniteQueryPagingDetails(outstandingCollectionsByCarrierQuery.data)?.totalElements;

  const data = React.useMemo(() => {
    return flattenCollectionByCarrier<
      UncollectedByCarrierEntryVO,
      UncollectedByCarrierSubtotalVO,
      UncollectedByCarrierResponse
    >(outstandingCollectionsByCarrierQuery.data);
  }, [outstandingCollectionsByCarrierQuery.data]);
  const filterParams = usePick(query, ["filters", "patientSearch"]);

  const filterProps = useFilterTokenProps({ options: filterParams, carriers: insuranceCarriersQuery.data });
  const isEmpty = !outstandingCollectionsByCarrierQuery.isLoading && data.length === 0;

  const handleSortClick = React.useCallback(
    (carrierTableSort: ColumnSortOrder[]) => {
      updateQuery("replaceIn", {
        carrierTableSort,
      });
    },
    [updateQuery]
  );
  const tableTabProps = useTableTabProps();
  const { isDownloading, downloadCSV } = useDownloadOutstandingCollectionsByCarrierCSV({
    outstandingCollectionsByCarrierQuery,
    selectedTimeSegment,
  });

  return (
    <>
      <DashboardTableTabs
        {...tableTabProps}
        onClickDownload={downloadCSV}
        isDownloading={isDownloading}
        query={query}
        id="outstanding-collections-table-tabs"
      >
        <ByCarrierFilters carriersQuery={insuranceCarriersQuery} />
      </DashboardTableTabs>
      <DashboardTableFilters {...filterParams}>
        <QueryFilterPills
          {...filterProps}
          numResults={totalRows}
          onUpdateParams={(updatedQuery) => {
            updateQuery("replaceIn", updatedQuery);
          }}
          onClearAll={() => {
            updateQuery("replaceIn", { filters: [], patientSearch: undefined });
          }}
        />
      </DashboardTableFilters>
      <DashboardInfiniteTable
        infiniteQuery={outstandingCollectionsByCarrierQuery}
        columnWidths={UNCOLLECTED_BY_CARRIER_COLUMNS.map(({ width }) => width)}
        id="outstandingCollectionsByCarrierTable"
        isEmpty={isEmpty}
        headerRow={
          <DashboardHeaderRow
            headers={UNCOLLECTED_BY_CARRIER_COLUMNS}
            onSortClick={handleSortClick}
            sortOrders={query.carrierTableSort}
          />
        }
        footerRow={
          <>
            <FooterCell>Totals</FooterCell>
            <FooterCell className="col-span-5" />
            <FooterCell loading={isLoading} align="right">
              {totals && formatCurrency(totals.totalClaimAmount)}
            </FooterCell>
            <FooterCell loading={isLoading} align="right">
              {totals && formatCurrency(totals.totalInsuranceUncollectedAmount)}
            </FooterCell>
            <FooterCell className="col-span-2" />
          </>
        }
      >
        {data.map(({ rows, subtotal }, i) => {
          return (
            <React.Fragment key={i}>
              <CarrierHeaderRow carrier={subtotal.carrier} />
              {rows.map((item, j) => (
                <ByCarrierClaimRow
                  practiceId={practiceId}
                  key={`${item.claimUuid}-${item.patient.id}-${j}`}
                  data={item}
                  last={j === rows.length - 1}
                />
              ))}
              <ByCarrierClaimFooterRow data={subtotal} />
            </React.Fragment>
          );
        })}
      </DashboardInfiniteTable>
    </>
  );
};
