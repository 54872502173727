import { useCallback, useRef } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Menu } from "@libs/components/UI/Menu";
import {
  ColumnSortIndicator,
  HeaderButtonCell,
  HeaderCell,
  HeaderCheckboxCell,
} from "@libs/components/UI/GridTableComponents";
import { IndeterminateCheckboxState, SelectAllType } from "components/Claims/Claims/useClaimsTableCheckboxes";
import { ClaimsQuery, ClaimsQueryUpdates, SortField } from "utils/routing/claims";
import { ClaimsTableHeaderItem } from "components/Claims/Claims/Table";

interface Props {
  indeterminateCheckboxState: IndeterminateCheckboxState;
  isPreAuth: boolean;
  onApplyFilters: (keyValues: ClaimsQueryUpdates) => void;
  onDeselectAll: () => void;
  onSelectAll: (type: SelectAllType) => void;
  queryStateApi: ClaimsQuery;
  selectedClaimUuids: Set<string>;
  tableHeaders: ClaimsTableHeaderItem[];
  totalClaims: number;
}

const SELECT_ALL_OPTIONS = [
  {
    label: "Select all draft",
    value: 0,
  },
  {
    label: "Select all sent",
    value: 1,
  },
];

export const ClaimsTableHeader: React.FC<Props> = ({
  indeterminateCheckboxState,
  isPreAuth,
  onApplyFilters,
  onDeselectAll,
  onSelectAll,
  queryStateApi,
  selectedClaimUuids,
  tableHeaders,
  totalClaims,
}) => {
  const selectTypeMenu = useBoolean(false);
  const triggerRef = useRef<HTMLInputElement | null>(null);

  const handleSelectAllType = useCallback(
    (optionValue: number) => {
      onSelectAll(optionValue === 0 ? "PENDING" : "SUBMITTED");
      selectTypeMenu.off();
    },
    [onSelectAll, selectTypeMenu]
  );

  const handleSelectIndeterminateCheckbox = useCallback(() => {
    if (isPreAuth) {
      handleSelectAllType(0);
    } else {
      selectTypeMenu.on();
    }
  }, [handleSelectAllType, isPreAuth, selectTypeMenu]);

  const handleSort = useCallback(
    (sortColumn: SortField | undefined) => {
      if (!sortColumn) {
        return;
      }

      const filtersToApply: ClaimsQueryUpdates = {};

      filtersToApply.sortField = sortColumn;

      if (queryStateApi.sortField !== sortColumn) {
        filtersToApply.sortDirection = "ASCENDING";
      } else if (queryStateApi.sortDirection === "ASCENDING") {
        filtersToApply.sortDirection = "DESCENDING";
      } else {
        filtersToApply.sortDirection = "ASCENDING";
      }

      onApplyFilters(filtersToApply);
    },
    [onApplyFilters, queryStateApi.sortDirection, queryStateApi.sortField]
  );

  return (
    <>
      {tableHeaders.map((item) =>
        item.id === "checkbox" ? (
          <div className="flex items-center sticky top-0 bg-white z-20" key={item.id}>
            <HeaderCheckboxCell
              onSelectAllRows={handleSelectIndeterminateCheckbox}
              onDeselectAllRows={onDeselectAll}
              tooltip={
                isPreAuth && indeterminateCheckboxState.disabled
                  ? { content: "No valid pre-auth claims for bulk send" }
                  : item.tooltip
              }
              totalRows={totalClaims}
              selectedCount={selectedClaimUuids.size}
              size="short"
              value="select-all"
              {...indeterminateCheckboxState}
              ref={triggerRef}
            >
              {item.label}
            </HeaderCheckboxCell>
            {selectTypeMenu.isOn && (
              <Menu
                theme="default"
                placement="bottom-start"
                className="flex w-28 mt-2"
                onRequestClose={selectTypeMenu.off}
                triggerRef={triggerRef}
              >
                <div className="flex flex-col">
                  {SELECT_ALL_OPTIONS.map((option) => (
                    <button
                      className="flex w-28 py-1.5 px-3 text-xs hover:bg-slate-100"
                      key={option.label}
                      onClick={() => handleSelectAllType(option.value)}
                      type="button"
                    >
                      {option.label}
                    </button>
                  ))}
                </div>
              </Menu>
            )}
          </div>
        ) : item.sortKey ? (
          <HeaderButtonCell
            className={item.className}
            key={item.id}
            onClick={() => handleSort(item.sortKey)}
            size="short"
            tooltip={item.tooltip}
          >
            {item.label}
            <ColumnSortIndicator
              className="ml-2"
              direction={queryStateApi.sortField === item.id ? queryStateApi.sortDirection : undefined}
            />
          </HeaderButtonCell>
        ) : (
          <HeaderCell className={item.className} key={item.id} size="short" tooltip={item.tooltip}>
            {item.label}
          </HeaderCell>
        )
      )}
    </>
  );
};
