import { useState } from "react";
import { BenefitCoverageVO } from "@libs/api/generated-api";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";

export type BenefitCoverageFields = Partial<BenefitCoverageVO>;

export const useBenefitCoverageFields = (savedCoverages?: BenefitCoverageFields[]) => {
  const [coverageFields, setCoverageFields] = useState(savedCoverages ?? []);

  useSyncOnce(setCoverageFields, savedCoverages);

  return {
    coverageFields,
    handleCoverageFieldsChanged: setCoverageFields,
  };
};
