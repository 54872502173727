import { useMemo, useCallback } from "react";
import { useDebounce } from "use-debounce";
import { isNullish } from "@libs/utils/types";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { SEARCH_DEBOUNCE_DELAY_MS } from "@libs/utils/constants";
import { useAccount } from "@libs/contexts/AccountContext";
import {
  getPracticeAvailableRoomsQuery,
  getAvailableProvidersQuery,
  AvailabilityFilterParams,
} from "api/scheduling/queries";
import { PreSelections } from "components/ScheduleAppointments/types";

// This hooks is to manage behavior when user is not finding a slot, they are setting a slot
export const useAppointmentSlotEditor = (
  preSelections: PreSelections | undefined,
  durationInMinutes: number
) => {
  const initialSelections = useMemo(
    () =>
      preSelections
        ? {
            ...preSelections,
            startTime: preSelections.startTime ?? "",
          }
        : {
            roomId: 0,
            hygienistId: 0,
            dentistId: 0,
            startTime: "",
          },
    [preSelections]
  );
  const [editableSlotParams, updateSelections] = useObjectState(initialSelections);

  // This value is editable via input so we should debounce to avoid superfluous api calls
  const [throttledDurationInMinutes] = useDebounce(durationInMinutes, SEARCH_DEBOUNCE_DELAY_MS);

  const availableQueryFilters = useMemo(() => {
    return {
      startDate: editableSlotParams.date,
      startTime: editableSlotParams.startTime,
      appointmentDurationInMinutes: throttledDurationInMinutes,
    } as AvailabilityFilterParams;
  }, [editableSlotParams.startTime, editableSlotParams.date, throttledDurationInMinutes]);

  const availabilityRequestsEnabled = Boolean(
    availableQueryFilters.startDate && availableQueryFilters.startTime && throttledDurationInMinutes
  );
  const { practiceId } = useAccount();
  const [availableRoomsQuery, availableProvidersQuery] = useApiQueries([
    // Use for edit and add from schedule
    getPracticeAvailableRoomsQuery({
      args: {
        practiceId,
        filters: availableQueryFilters,
      },
      queryOptions: {
        enabled: availabilityRequestsEnabled,
      },
    }),

    // Use for edit and add from schedule
    getAvailableProvidersQuery({
      args: {
        practiceId,
        filters: availableQueryFilters,
      },
      queryOptions: {
        enabled: availabilityRequestsEnabled,
      },
    }),
  ]);
  const selectedDate = editableSlotParams.date;

  return {
    availableRoomsQuery,
    availableProvidersQuery,
    selections: editableSlotParams,
    preSelections: initialSelections,
    // If user clears the date, we clear all of the fields
    updateSelections: useCallback(
      (
        partial: Partial<{
          startTime: string;
          roomId: number;
          hygienistId: number;
          dentistId: number;
          date?: string | undefined;
        }>
      ) => {
        let updates = partial;

        if (selectedDate && "date" in partial && isNullish(partial.date)) {
          // Date was cleared, clear other fields
          updates = { ...updates, startTime: undefined, dentistId: 0, hygienistId: 0, roomId: 0 };
        }

        updateSelections(updates);
      },
      [selectedDate, updateSelections]
    ),
  };
};

export type AppointmentSlotEditor = ReturnType<typeof useAppointmentSlotEditor>;
