import { ColumnSortIndicator, HeaderButtonCell, HeaderCell } from "@libs/components/UI/GridTableComponents";
import { EmployeesQuery } from "utils/routing/employees";

interface Props {
  sortColumnName?: string;
  text: string;
  queryState: EmployeesQuery;
  onSortChange: (sortColumn: string, orderBy: "ASCENDING" | "DESCENDING") => void;
}

export const EmployeeHeaderCell: React.FC<Props> = ({ sortColumnName, text, queryState, onSortChange }) => {
  return sortColumnName ? (
    <HeaderButtonCell
      cellPadding={{ vertical: "slim" }}
      size="short"
      borderColor="border-slate-200"
      onClick={() => {
        if (sortColumnName === queryState.sortColumn && queryState.orderBy === "DESCENDING") {
          onSortChange(sortColumnName, "ASCENDING");
        } else {
          onSortChange(sortColumnName, "DESCENDING");
        }
      }}
    >
      {text}
      <ColumnSortIndicator className="ml-2" direction={queryState.orderBy ?? undefined} />
    </HeaderButtonCell>
  ) : (
    <HeaderCell borderColor="border-slate-200" size="short" cellPadding={{ vertical: "slim" }}>
      {text}
    </HeaderCell>
  );
};
