import { FC, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { cxLayerColors } from "components/Charting/teeth";

const treatmentTypes = [
  { className: cxLayerColors.condition.menu, children: "Conditon" },
  { className: cxLayerColors.EXISTING_OTHER.menu, children: "Existing Other" },
  { className: cxLayerColors.EXISTING_CURRENT.menu, children: "Existing Current" },
  { className: cxLayerColors.PLANNED.menu, children: "Planned" },
  { className: cxLayerColors.REFERRED.menu, children: "Referred" },
  { className: cxLayerColors.DONE.menu, children: "Completed" },
];

const TreatmentType: FC<{ className: string; children?: ReactNode }> = ({ className, children }) => (
  <li className="flex items-center whitespace-nowrap mb-3">
    <span className={cx("rounded-full w-4 h-4 flex-none block mr-2", className)} /> {children}
  </li>
);

export const TreatmentTypesInfoMenu: FC = () => {
  return (
    <div className="text-xs">
      <div className="font-sansSemiBold mb-3">Treatment Types</div>
      <ul>
        {treatmentTypes.map((props, index) => (
          <TreatmentType key={index} {...props} />
        ))}
      </ul>
    </div>
  );
};
