import { FC, useCallback, useMemo, useState } from "react";
import { produce } from "immer";
import { EmployeeVO, OnboardEmployeeRequest, PracticeInfoVO, ProfileImageVO } from "@libs/api/generated-api";
import { email, phoneNumber, required, ssn } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { useAccount } from "@libs/contexts/AccountContext";
import { OnChangeHandler } from "components/Onboarding/types";
import { Avatar } from "components/UI/Avatar";
import { GeneralInformationScreen } from "./GeneralInformationScreen";
import { OnboardingCompletedScreen } from "./OnboardingCompletedScreen";
import { UploadDocumentsScreen } from "./UploadDocumentsScreen";

type Props = {
  employee: EmployeeVO;
  isCompletingOnboarding: boolean;
  onCompleteOnboarding: (onboarding: OnboardEmployeeRequest) => void;
  practice: PracticeInfoVO;
  practiceProfileImage: ProfileImageVO | undefined;
};

type OnboardingSteps = "GENERAL_INFO" | "UPLOAD_DOCUMENTS";

export const getOnboardingSchema = ({
  ssnLastFour,
  ssnValue,
  isExcludedFromPayroll,
}: {
  ssnLastFour?: string;
  ssnValue?: string;
  isExcludedFromPayroll: boolean;
}) => {
  // this means the employee already has an SSN on file.
  // once they edit it at all ssnValue will be a string and we can validate it
  const ignoreSSNValidation = Boolean(ssnLastFour && ssnValue === undefined);

  return {
    contactDetails: {
      personalEmail: [
        {
          $v: required,
          $error: "Personal email is required.",
        },
        {
          $v: email,
          $error: "Please enter valid email",
        },
      ],
      phone: [
        {
          $v: phoneNumber,
          $error: "Please enter valid phone number",
        },
      ],
      addressDetails: {
        address1: [
          {
            $v: required,
            $error: "Address is required",
          },
        ],
        city: [
          {
            $v: required,
            $error: "City is required",
          },
        ],
        state: [
          {
            $v: required,
            $error: "State is required",
          },
        ],
        zip: [
          {
            $v: required,
            $error: "Zip is required",
          },
        ],
        $ignore: isExcludedFromPayroll,
      },
    },
    personalDetails: {
      firstName: [
        {
          $v: required,
          $error: "First name required",
        },
      ],
      lastName: [
        {
          $v: required,
          $error: "Last name required",
        },
      ],
      ssn: [
        {
          $v: required,
          $error: "SSN required",
          $ignore: ignoreSSNValidation,
        },
        {
          $v: ssn,
          $error: "Please enter valid social security number",
          $ignore: ignoreSSNValidation,
        },
      ],
    },
  };
};

export const OnboardingWizard: FC<Props> = ({
  employee,
  practice,
  practiceProfileImage,
  isCompletingOnboarding,
  onCompleteOnboarding,
}) => {
  const { practiceId } = useAccount();

  const [step, setStep] = useState<OnboardingSteps>("GENERAL_INFO");
  const [draft, setDraft] = useState<OnboardEmployeeRequest>(employee);
  const schema = useMemo(() => {
    return getOnboardingSchema({
      ssnLastFour: draft.personalDetails.ssnLastFour,
      ssnValue: draft.personalDetails.ssn,
      isExcludedFromPayroll: Boolean(employee.employmentDetails.excludeFromPayroll),
    });
  }, [
    draft.personalDetails.ssnLastFour,
    draft.personalDetails.ssn,
    employee.employmentDetails.excludeFromPayroll,
  ]);
  const employeeForm = useValidation(draft, schema);

  const handleChange: OnChangeHandler = (update) => {
    employeeForm.reset();
    setDraft((last) => produce(last, (editable) => void update(editable)));
  };

  const handleClickProceed = useCallback(() => {
    const validate = employeeForm.validate();

    if (!validate.$isValid) {
      return;
    }

    setStep("UPLOAD_DOCUMENTS");
  }, [employeeForm]);

  const handleClickPrevious = useCallback(() => {
    setStep("GENERAL_INFO");
  }, []);

  const isFormValid = employeeForm.result.$isValid ?? true;

  return (
    <div
      className={`
        min-h-0
        flex-1
        flex
        flex-col
        items-center
        bg-white
        p-5
        h-full
        overflow-y-auto
      `}
    >
      <div className="flex items-center">
        <Avatar imageUrl={practiceProfileImage?.url} name={practice.name} size="xl" />
        <div className="font-sansSemiBold text-xl ml-2">{practice.name}</div>
      </div>
      <div className="my-8 w-[584px]">
        {employee.employmentDetails.status && employee.employmentDetails.status === "ACTIVE" ? (
          <OnboardingCompletedScreen employee={employee} practiceTimezoneId={practice.timezoneId} />
        ) : step === "GENERAL_INFO" ? (
          <GeneralInformationScreen
            employeeId={employee.id}
            onboardingData={draft}
            onChange={handleChange}
            practiceId={practiceId}
            validationResults={employeeForm.result}
            isExcludedFromPayroll={Boolean(employee.employmentDetails.excludeFromPayroll)}
          />
        ) : (
          <UploadDocumentsScreen practiceId={practiceId} employeeId={employee.id} />
        )}
      </div>
      {employee.employmentDetails.status === "PENDING" ? (
        <div className="mb-10">
          {step === "GENERAL_INFO" ? (
            <Button className="w-28" disabled={!isFormValid} onClick={handleClickProceed}>
              Proceed
            </Button>
          ) : (
            <>
              <Button className="w-28" theme="secondary" onClick={handleClickPrevious}>
                Previous
              </Button>
              <AsyncButton
                isLoading={isCompletingOnboarding}
                className="w-28 ml-3"
                onClick={() => onCompleteOnboarding(draft)}
              >
                Finish
              </AsyncButton>
            </>
          )}
        </div>
      ) : null}
    </div>
  );
};
