import React, { FC, PropsWithChildren } from "react";
import { ParentSize } from "@visx/responsive";

import { TimeSeriesResponse } from "@libs/api/generated-api";
import { CHART_COLORS, ColorScheme } from "components/Dashboard/Charting/theme";
import { useTimeseriesTemplateForResolution } from "components/Dashboard/hooks/useTimeseriesTemplateForResolution";
import { ChartContainer } from "components/Dashboard/Charting/ChartContainer";
import { TimeSeriesResolutionOption, PatientType } from "components/Dashboard/types";
import { labelForPatientType } from "components/Dashboard/PatientsTreated/utils";
import { useBarStackDecorators } from "components/Dashboard/hooks/useBarStackDecorators";
import { useTimeseriesAsStacks } from "components/Dashboard/Charting/hooks/useTimeseriesAsStacks";
import { TimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { DashboardChartDisplay, DashboardQueryWithDateNavigation } from "utils/routing/dashboard";
import { DashboardTimeseriesChart } from "components/Dashboard/Charting/DashboardTimeseriesChart";
import { useFocusDateClick } from "components/Dashboard/hooks/useFocusDateClick";
import { useTimeseriesPreviousData } from "components/Dashboard/Charting/hooks/useTimeseriesPreviousData";

const patientsTreatedKeys: PatientType[] = ["NEW", "EXISTING"];
const colorScheme: ColorScheme<PatientType> = {
  domain: patientsTreatedKeys,
  range: [CHART_COLORS.blue, CHART_COLORS.blueBright],
};

const useTimeSeriesNewVsExistingData = ({
  resolution,
  isLoading,
  timeSeries,
  dateWindow,
}: {
  isLoading: boolean;
  resolution: TimeSeriesResolutionOption;
  dateWindow: TimeSeriesPageSelections;
  timeSeries?: TimeSeriesResponse;
}) => {
  //We store last data, to be able to represent the last state while loading new one

  const timeline = useTimeseriesTemplateForResolution({ resolution, dateWindow });
  const data = useTimeseriesAsStacks(timeline, patientsTreatedKeys, isLoading ? undefined : timeSeries);
  const dataWithLoadingData = useTimeseriesPreviousData({
    data,
    isLoading,
  });

  return dataWithLoadingData;
};

type ContentProps = {
  width: number;
  height: number;
  resolution: TimeSeriesResolutionOption;
  timeSeries?: TimeSeriesResponse;
  isLoading: boolean;
  focusDate?: Date;
  dateWindow: TimeSeriesPageSelections;
  chartDisplay: DashboardChartDisplay;

  onUpdateParams: (update: Partial<DashboardQueryWithDateNavigation>) => void;
};
export const NewVsExistingChartContent: FC<ContentProps> = React.memo(
  ({
    width,
    height,
    resolution,
    isLoading,
    timeSeries,
    focusDate,
    dateWindow,
    chartDisplay,
    onUpdateParams,
  }) => {
    const { data, isFirstLoad } = useTimeSeriesNewVsExistingData({
      resolution,
      isLoading,
      dateWindow,
      timeSeries,
    });

    const barDecorators = useBarStackDecorators(data, focusDate, resolution);
    const handleDateClicked = useFocusDateClick({ handleParamsChange: onUpdateParams, focusDate });

    return (
      <DashboardTimeseriesChart
        data={data}
        chartDisplay={chartDisplay}
        randomizeLoadingBars={isFirstLoad}
        width={width}
        timeSegment={dateWindow}
        height={height}
        isLoading={isLoading}
        resolution={resolution}
        colorScheme={colorScheme}
        onClickDate={handleDateClicked}
        keys={patientsTreatedKeys}
        labelFormat={labelForPatientType}
        {...barDecorators}
      />
    );
  }
);
NewVsExistingChartContent.displayName = "NewVsExistingChartContent";

export const NewVsExistingChart: React.FC<PropsWithChildren<Omit<ContentProps, "width" | "height">>> = ({
  children,
  dateWindow,
  ...rest
}) => {
  return (
    <ChartContainer disabledMessage={dateWindow.invalidTimeSeriesParamsMessage}>
      <ParentSize className="flex-1 relative">
        {({ height, width }: { width: number; height: number }) => {
          return (
            <NewVsExistingChartContent width={width} height={height} dateWindow={dateWindow} {...rest} />
          );
        }}
      </ParentSize>
      {children}
    </ChartContainer>
  );
};
