import { animated, SpringValue } from "@react-spring/web";
import React from "react";
import { Text } from "@visx/text";
import { cx } from "@libs/utils/cx";
import { half } from "@libs/utils/math";
import { useOnRectMouseMoved } from "components/Dashboard/hooks/useRectMouseMoved";
import { useTextMouseMove } from "components/Dashboard/hooks/useTextMouseMove";

type Props = {
  barWidth: number;
  barHeight: number;
  barX: number;
  barY: number;
  maxY: number;
  testId?: string;
  onHideTooltip: Func;
  color: string;
  onClick: () => void;
  onShowTooltip: (params: { tooltipTop?: number; tooltipLeft?: number }) => void;
  borderTop: boolean;
  label?: string;
  className?: string;
  backdropHeight?: number;
  isTopStack: boolean;
  scale: SpringValue<number>;
};

const AnimatedText = animated(Text);

const TEXT_PADDING_BOTTOM = 6;
const TEXT_PADDING_SIDES = 80;

export const VisxChartBar: React.FC<Props> = React.memo(
  ({
    barX,
    barWidth,
    barY,
    color,
    barHeight,
    onHideTooltip,
    onShowTooltip,
    borderTop,
    className,
    maxY,
    testId,
    onClick,
    label,
    backdropHeight = 0,
    scale,
    isTopStack,
  }) => {
    const maximumYAnimated = scale.to(
      (s) => maxY + backdropHeight - backdropHeight * s - TEXT_PADDING_BOTTOM
    );
    const y = scale.to((s) => barY + barHeight - barHeight * s);
    const height = scale.to((s) => s * barHeight);
    const onRectMouseMove = useOnRectMouseMoved(onShowTooltip);
    const onTextMouseMove = useTextMouseMove(onShowTooltip);
    const showBackdrop = scale.idle && isTopStack;

    return (
      <>
        {showBackdrop && (
          <rect
            x={barX}
            y={maxY}
            height={backdropHeight}
            width={barWidth}
            className="cursor-pointer text-black"
          />
        )}
        {label && (
          <AnimatedText
            onMouseMove={onTextMouseMove}
            onMouseOut={onHideTooltip}
            onClick={onClick}
            textAnchor="middle"
            x={barX + half(barWidth)}
            y={maximumYAnimated}
            width={barWidth + TEXT_PADDING_SIDES}
            className={cx("text-xs", className)}
          >
            {label}
          </AnimatedText>
        )}
        {barHeight > 0 && (
          <>
            <animated.rect
              x={barX}
              y={y}
              onClick={onClick}
              height={height}
              width={barWidth}
              data-icon={testId}
              fill={color}
              className={className}
              onMouseLeave={onHideTooltip}
              onMouseMove={onRectMouseMove}
            />
            {/* Place a white border at the top of each bar */}
            {borderTop && (
              <animated.rect
                x={barX}
                y={y}
                onClick={onClick}
                width={barWidth}
                height={1.5}
                className={className}
                fill="white"
                strokeWidth={2}
              />
            )}
          </>
        )}
      </>
    );
  }
);
