import React, { useCallback, useMemo, useRef } from "react";
import { Link } from "react-router-dom";
import { AppointmentRequest } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getAppointmentCategoriesQuery, getPracticeRoomsQuery } from "api/scheduling/queries";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { paths } from "utils/routing/paths";
import { addAppointment } from "api/scheduling/mutations";
import { useQueryParams } from "hooks/useQueryParams";
import { EventPatientSnapshotLayout } from "components/ScheduleAppointments/EventPatientSnapshotLayout";
import { AddAppointmentForm } from "components/ScheduleAppointments/AddAppointmentForm";
import { useAppointmentRoute } from "components/ScheduleAppointments/useAppointmentRoute";
import { useNullablePathParams } from "hooks/usePathParams";
import { EventModalPage } from "components/ScheduleAppointments/EventModalPage";
import { EventModalTitleContent } from "components/ScheduleAppointments/EventModalTitleContent";
import { BenefitLimitationIgnoreConfirmationModal } from "components/ScheduleAppointments/BenefitLimitationIgnoreConfirmationModal";
import { getAppointmentMutatedLabel, isUnscheduledAsapAppt } from "components/ScheduleAppointments/utils";
import { getPatientChartV2 } from "api/charting/queries";
import { getPatientSummary } from "api/patients/queries";
import { usePatientAppointmentQueryState } from "contexts/PatientAppointmentContext";
import { getTags } from "api/settings/notes/queries";

const formId = "schedule-appointment-form";

// eslint-disable-next-line complexity
export const ScheduleAppointmentRoute: React.FC = () => {
  const { practiceId } = useAccount();
  const appointmentRoute = useAppointmentRoute();

  const { query } = useQueryParams("scheduleAppointment");
  const scheduleAppointmentParams = useNullablePathParams("scheduleAppointment");
  const addToAsapList = !scheduleAppointmentParams;
  const preSelections = useMemo(() => {
    return {
      date: scheduleAppointmentParams?.date,
      startTime: scheduleAppointmentParams?.startTime ?? "",
      hygienistId: query.hygienistId,
      dentistId: query.dentistId,
      roomId: query.roomId,
    };
  }, [query.hygienistId, query.dentistId, query.roomId, scheduleAppointmentParams]);

  const patientAppointment = usePatientAppointmentQueryState({ defaultPatientToMemory: false });
  const patientId = patientAppointment.patientId ?? 0;
  const initialPatientIdRef = useRef(patientId);

  const [categoriesQuery, providersQuery, roomsQuery, chartV2Query, patientQuery, tagsQuery] = useApiQueries([
    getAppointmentCategoriesQuery({ args: { practiceId } }),
    getPracticeProvidersQuery({ args: { practiceId } }),
    getPracticeRoomsQuery({ args: { practiceId } }),
    getPatientChartV2({
      args: { patientId, practiceId },
      queryOptions: {
        enabled: Boolean(patientId),
      },
    }),
    getPatientSummary({
      args: { practiceId, patientId: initialPatientIdRef.current },
      queryOptions: { enabled: Boolean(initialPatientIdRef.current) },
    }),
    getTags({ args: { practiceId } }),
  ]);

  const [addAppointmentMutation] = useApiMutations([addAppointment]);

  const backUrl = query.from ?? (scheduleAppointmentParams ? paths.schedule() : paths.scheduleAsapList());

  const addAppointmentMutate = addAppointmentMutation.mutate;
  const handleSaveError = appointmentRoute.handleSaveError;
  const handleSaveSuccess = appointmentRoute.handleSaveSuccess;

  const handleSubmit = useCallback(
    (data: AppointmentRequest) => {
      addAppointmentMutate(
        {
          practiceId,
          data,
        },
        {
          onSuccess: handleSaveSuccess,
          onError: handleSaveError(data),
        }
      );
    },
    [addAppointmentMutate, handleSaveSuccess, handleSaveError, practiceId]
  );

  const blockLink = useMemo(() => {
    return scheduleAppointmentParams
      ? paths.scheduleBlock(
          { date: scheduleAppointmentParams.date, startTime: scheduleAppointmentParams.startTime },
          {
            from: query.from,
            dentistId: query.dentistId,
            hygienistId: query.hygienistId,
            roomId: query.roomId,
            duration: query.duration,
          }
        )
      : "";
  }, [
    scheduleAppointmentParams,
    query.from,
    query.dentistId,
    query.hygienistId,
    query.roomId,
    query.duration,
  ]);
  const isSavedAppointmentUnscheduled = isUnscheduledAsapAppt(appointmentRoute.savedAppointment?.date);

  return (
    <EventPatientSnapshotLayout
      appointmentId={patientAppointment.appointmentId}
      onDeleteAppointment={patientAppointment.handleAppointmentDeleted}
      onSelectAppointment={patientAppointment.handleAppointmentSelected}
      patientId={patientAppointment.patientId}
      from={backUrl}
    >
      <QueryResult queries={[categoriesQuery, providersQuery, roomsQuery, patientQuery, tagsQuery]}>
        {(!initialPatientIdRef.current || patientQuery.data) &&
        categoriesQuery.data &&
        providersQuery.data &&
        roomsQuery.data &&
        tagsQuery.data ? (
          <EventModalPage
            formId={formId}
            backUrl={backUrl}
            title={
              blockLink ? (
                <EventModalTitleContent title="Add Appointment">
                  <Link to={blockLink} className="text-sm text-primaryTheme font-sansSemiBold">
                    Add Time Block
                  </Link>
                </EventModalTitleContent>
              ) : (
                <EventModalTitleContent title="Add Appointment" />
              )
            }
            hasSavedEvent={Boolean(appointmentRoute.savedAppointment)}
            eventUrl={appointmentRoute.appointmentUrl}
            isSaving={addAppointmentMutation.isLoading}
            isFormValid={appointmentRoute.formValid.isOn}
            isUnscheduled={isSavedAppointmentUnscheduled}
            isDirty={appointmentRoute.formDirty.isOn}
            savedValue={appointmentRoute.savedAppointment}
            savedMessage={getAppointmentMutatedLabel({
              isUnscheduled: isSavedAppointmentUnscheduled,
              wasCreated: true,
            })}
            promptMessage="Do you want to discard this appointment?"
          >
            <AddAppointmentForm
              providers={providersQuery.data}
              categories={categoriesQuery.data}
              initialDuration={query.duration}
              teeth={chartV2Query.data}
              rooms={roomsQuery.data}
              tags={tagsQuery.data}
              preSelections={preSelections}
              onSubmit={handleSubmit}
              formId={formId}
              patientId={patientId}
              onValidationChange={appointmentRoute.formValid.set}
              onDirty={appointmentRoute.formDirty.on}
              addToAsapList={addToAsapList}
              findFromDate={scheduleAppointmentParams?.date}
              onSelectedPatient={patientAppointment.handlePatientSelected}
              patientQuery={patientQuery}
            />
            {appointmentRoute.benefitLimitationModal.isOpen ? (
              <BenefitLimitationIgnoreConfirmationModal
                isSaving={addAppointmentMutation.isLoading}
                onCancel={appointmentRoute.benefitLimitationModal.close}
                onConfirm={handleSubmit}
                {...appointmentRoute.benefitLimitationModal.item}
              />
            ) : null}
          </EventModalPage>
        ) : null}
      </QueryResult>
    </EventPatientSnapshotLayout>
  );
};
