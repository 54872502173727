import { MedicalImageVO } from "@libs/api/generated-api";
import { LRUCache } from "lru-cache";

const MAX_SIZE_MB = 250;
const BYTES_PER_MB = 1_048_576;
const options: LRUCache.Options<
  number,
  {
    url: string;
    blob: Blob;
  },
  void
> = {
  // for use with tracking overall storage size
  maxSize: MAX_SIZE_MB * BYTES_PER_MB,
  sizeCalculation: (value) => {
    return value.blob.size;
  },

  // for use when you need to clean up something when objects
  // are evicted from the cache
  dispose: (value) => {
    URL.revokeObjectURL(value.url);
  },

  // return stale items before removing from cache?
  allowStale: false,

  updateAgeOnGet: true,
  updateAgeOnHas: true,
};

const cache = new LRUCache(options);

// Because each time a MedicalImageVO is fetched, there's a different url generated
// we use this image url cache to prevent multiple downloads of the same image with different urls
export const imagingUrlCache = {
  clear: () => {
    cache.clear();
  },
  add: async (image: MedicalImageVO) => {
    if (image.url && image.id && !cache.has(image.id)) {
      const blob = await fetch(image.url).then((resp) => resp.blob());
      const url = URL.createObjectURL(blob);

      cache.set(image.id, { url, blob });

      return url;
    }

    return cache.get(image.id!)?.url;
  },
  getImageUrl: (image: MedicalImageVO) => {
    if (image.id) {
      return cache.get(image.id)?.url;
    }

    return undefined;
  },
};
