import { Route } from "react-router-dom";
import { FC, PropsWithChildren, useMemo } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { isDefined } from "@libs/utils/types";
import { useAccount } from "@libs/contexts/AccountContext";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { useFeatureFlagsContext } from "contexts/FeatureFlagsContext";
import { NotFound } from "components/NotFoundRoute";
import { getSettingsRoutes } from "components/Settings/routes";
import { LeftAsideTemplate } from "components/UI/LeftAsideTemplate";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { PermissionAction } from "components/Roles/constants";
import { RoleGuardLock } from "components/Main/RoleGuard";
import { getPracticeImagingSettings } from "api/imaging/queries";
import { SettingsNavigation } from "./SettingsNavigation";

export const PRACTICE_SETTINGS_ID = "practice-settings";

const SettingsRoleGuardLock: FC<
  PropsWithChildren<{
    action: PermissionAction;
  }>
> = ({ children, action }) => {
  return (
    <RoleGuardLock domain="PRACTICE_SETTINGS" action={action}>
      {children}
    </RoleGuardLock>
  );
};

export const SettingApp = () => {
  const { practiceId } = useAccount();
  const currentUser = useCurrentUser();
  const featureFlags = useFeatureFlagsContext();

  const [practiceImagingSettingsQuery] = useApiQueries([
    getPracticeImagingSettings({ args: { practiceId } }),
  ]);
  const hasPearlAccess =
    isDefined(practiceImagingSettingsQuery.data) &&
    practiceImagingSettingsQuery.data.features.includes("PEARL");
  const { routes, menu } = useMemo(() => {
    return getSettingsRoutes(featureFlags, currentUser.roleV2, hasPearlAccess);
  }, [currentUser.roleV2, featureFlags, hasPearlAccess]);

  return (
    <LeftAsideTemplate id={PRACTICE_SETTINGS_ID} asideContent={<SettingsNavigation menu={menu} />}>
      <SentryRoutes>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={<SettingsRoleGuardLock action={route.action}>{route.element}</SettingsRoleGuardLock>}
          />
        ))}
        <Route path="*" element={<NotFound />} />
      </SentryRoutes>
    </LeftAsideTemplate>
  );
};
