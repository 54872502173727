import { FC, FormEvent, useState, useCallback } from "react";

import {
  CustomPatientFolderVO,
  CreateCustomPatientFolderRequest,
  UpdateCustomPatientFolderRequest,
} from "@libs/api/generated-api";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { isDefined } from "@libs/utils/types";

import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useAccount } from "@libs/contexts/AccountContext";
import { Flyover } from "components/UI/Flyover";
import { FlyoverForm, FlyoverContent, FlyoverFooter } from "components/UI/FlyoverComponents";

import { createCustomPatientFolder, updateCustomPatientFolder } from "api/documents/mutations";

import { handleError } from "utils/handleError";

interface Props {
  patientFolder: CustomPatientFolderVO | undefined;
  onSaveSuccess: ({ isEditing }: { isEditing: boolean }) => void;
  onClose: Func;
}

export const CustomPatientFolderFlyover: FC<Props> = ({ patientFolder, onSaveSuccess, onClose }) => {
  const { practiceId } = useAccount();
  const isEditing = isDefined(patientFolder);
  const [draftFolder, setDraftFolder] = useState<CreateCustomPatientFolderRequest>(() => ({
    name: isEditing ? patientFolder.name : "",
  }));

  const [createCustomPatientFolderMutation, updateCustomPatientFolderMutation] = useApiMutations([
    createCustomPatientFolder,
    updateCustomPatientFolder,
  ]);
  const createFolderMutate = createCustomPatientFolderMutation.mutate;
  const updateFolderMutate = updateCustomPatientFolderMutation.mutate;

  const { validate, result } = useValidation(draftFolder, {
    name: [
      {
        $v: required,
        $error: "Name is required",
      },
    ],
  });

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!validate().$isValid) {
        return;
      }

      const mutateOptions = {
        onSuccess: () => {
          onClose();
          onSaveSuccess({ isEditing });
        },
        onError: handleError,
      };

      if (isEditing) {
        updateFolderMutate(
          {
            practiceId,
            customPatientFolderId: patientFolder.id,
            data: draftFolder as UpdateCustomPatientFolderRequest,
          },
          mutateOptions
        );

        return;
      }

      createFolderMutate(
        {
          practiceId,
          data: draftFolder,
        },
        mutateOptions
      );
    },
    [
      validate,
      createFolderMutate,
      updateFolderMutate,
      onClose,
      onSaveSuccess,
      isEditing,
      practiceId,
      draftFolder,
      patientFolder,
    ]
  );

  return (
    <Flyover title={isEditing ? "Edit Folder" : "New Folder"} headerSize="sm" onClose={onClose}>
      {({ close }) => (
        <FlyoverForm onSubmit={handleSubmit}>
          <FlyoverContent paddingClassName="p-6">
            <FormFieldInput
              label="Name"
              value={draftFolder.name}
              onChange={(e) => setDraftFolder((last) => ({ ...last, name: e.target.value }))}
              error={result.name.$error}
              required
            />
          </FlyoverContent>

          <FlyoverFooter>
            <Button className="min-w-button" onClick={close} theme="secondary">
              Cancel
            </Button>
            <AsyncButton
              className="min-w-button"
              isLoading={
                createCustomPatientFolderMutation.isLoading || updateCustomPatientFolderMutation.isLoading
              }
              type="submit"
            >
              Save
            </AsyncButton>
          </FlyoverFooter>
        </FlyoverForm>
      )}
    </Flyover>
  );
};
