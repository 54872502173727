import { getDate } from "date-fns";
import { RecurringScheduleRequest, RecurringScheduleVO } from "@libs/api/generated-api";
import {
  MAX_DAY_OCCURRENCES_IN_MONTH,
  formatDayOfYear,
  getLocalDate,
  getOccurrenceOfDayOfWeekInMonth,
  isLastOccurenceOfWeekdayInMonth,
  weekdays,
} from "@libs/utils/date";
import { getRecurrenceLabel } from "components/ScheduleAppointments/getRecurrenceLabel";

type RepeatMonthOptionValue = "MONTHLY_ON" | "MONTHLY_ON_ORDINAL" | "MONTHLY_ON_LAST";

export type RepeatValue =
  | "NONE"
  | "DAILY"
  | "WEEKLY_ON"
  | RepeatMonthOptionValue
  | "ANNUALLY_ON"
  | "WEEKDAYS"
  | "CUSTOM";

type RepeatOption = {
  value: RepeatValue;
  recurrence?: RecurringScheduleVO;
};

export type LabelledRepeatOption = RepeatOption & { label: string };

const addLabel = (option: RepeatOption | LabelledRepeatOption): LabelledRepeatOption => ({
  ...option,
  label: "label" in option ? option.label : option.recurrence ? getRecurrenceLabel(option.recurrence) : "",
});

export const dailyOption: RepeatOption = {
  value: "DAILY",
  recurrence: {
    recurrenceType: "DAYS",
    every: 1,
  },
};

export const getWeeklyOption = (dateContext: string): RepeatOption => {
  const { dayOfWeek } = getOccurrenceOfDayOfWeekInMonth(getLocalDate(dateContext));
  const dayOfWeekUpper = dayOfWeek.toUpperCase() as ListItem<
    NonNullable<RecurringScheduleRequest["daysOfWeek"]>
  >;

  return {
    value: "WEEKLY_ON",
    recurrence: {
      recurrenceType: "DAYS_OF_WEEKS",
      daysOfWeek: [dayOfWeekUpper],
      every: 1,
    },
  };
};

export const getMonthlyOption = (dateContext: string): RepeatOption => {
  return {
    value: "MONTHLY_ON",
    recurrence: {
      recurrenceType: "DAYS_OF_MONTHS",
      daysOfMonth: [getDate(getLocalDate(dateContext))],
      every: 1,
    },
  };
};

export const getAnnuallyOption = (dateContext: string): RepeatOption => {
  return {
    value: "ANNUALLY_ON",
    recurrence: {
      recurrenceType: "DAYS_OF_YEAR",
      daysOfYear: [formatDayOfYear(getLocalDate(dateContext))],
      every: 1,
    },
  };
};

export const weekdaysOption: RepeatOption = {
  value: "WEEKDAYS",
  recurrence: {
    recurrenceType: "DAYS_OF_WEEKS",
    daysOfWeek: weekdays,
    every: 1,
  },
};

export const getRepeatMonthOptions = (dateContext: string) => {
  const date = getLocalDate(dateContext);
  const { occurrence, dayOfWeek } = getOccurrenceOfDayOfWeekInMonth(date);
  const dayOfWeekUpper = dayOfWeek.toUpperCase() as ListItem<
    NonNullable<RecurringScheduleRequest["daysOfWeek"]>
  >;
  const options = [getMonthlyOption(dateContext)];

  if (occurrence < MAX_DAY_OCCURRENCES_IN_MONTH) {
    options.push({
      value: "MONTHLY_ON_ORDINAL",
      recurrence: {
        recurrenceType: "DAYS_OF_WEEKS_OF_MONTH",
        daysOfWeek: [dayOfWeekUpper],
        ordinals: [occurrence - 1],
        every: 1,
      },
    });
  }

  if (isLastOccurenceOfWeekdayInMonth(date)) {
    options.push({
      value: "MONTHLY_ON_LAST",
      recurrence: {
        recurrenceType: "DAYS_OF_LAST_WEEKS_OF_MONTH",
        daysOfWeek: [dayOfWeekUpper],
        ordinals: [0],
        every: 1,
      },
    });
  }

  return options.map(addLabel);
};

export const getRecurrenceOptions = (dateContext: string | undefined) => {
  let options: (RepeatOption | LabelledRepeatOption)[] = [{ value: "NONE", label: "Does not repeat" }];

  if (dateContext) {
    options = [
      ...options,
      dailyOption,
      getWeeklyOption(dateContext),
      ...getRepeatMonthOptions(dateContext),
      getAnnuallyOption(dateContext),
      weekdaysOption,
      { value: "CUSTOM", label: "Custom" },
    ];
  }

  return options.map(addLabel);
};
