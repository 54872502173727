import { FC } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { ContactDetails } from "components/Settings/PracticeSetup/Sections/ContactDetails";

export const ContactInformationRoute: FC = () => {
  const isEditing = useBoolean(false);

  return (
    <SettingsPanel
      isEditing={isEditing.isOn}
      title="Contact Details"
      description="Contact information associated with your business"
      onToggleEdit={isEditing.toggle}
    >
      <ContactDetails isEditing={isEditing.isOn} onDoneEditing={isEditing.off} />
    </SettingsPanel>
  );
};
