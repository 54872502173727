import { FC } from "react";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle-filled.svg";
import {
  AppointmentProcedureOptions,
  AppointmentProcedureOptionsProps,
} from "components/ScheduleAppointments/AppointmentProcedureOptions";
import { AppointmentProcedures } from "components/ScheduleAppointments/useAppointmentCategoriesAndProcedures";

export interface AppointFormProcedurePickerProps
  extends Pick<
    AppointmentProcedureOptionsProps,
    "onRemoveProcedure" | "onEditProcedure" | "onEditEstimates" | "teeth"
  > {
  onAddProcedure: Func;
  procedures: AppointmentProcedures;
  disabled: boolean;
}

export const AppointFormProcedurePicker: FC<AppointFormProcedurePickerProps> = ({
  onRemoveProcedure,
  onEditProcedure,
  onEditEstimates,
  onAddProcedure,
  teeth,
  procedures,

  disabled,
}) => {
  return (
    <div className="mt-3">
      <div className="flex flex-wrap gap-y-1">
        {procedures.category.length ? (
          <div>
            <FormFieldLabel
              disabled={disabled}
              content="Category Procedures"
              required={true}
              className="mb-2 text-xs"
            />
            <AppointmentProcedureOptions
              teeth={teeth}
              onEditProcedure={onEditProcedure}
              onEditEstimates={onEditEstimates}
              disabled={disabled}
              onRemoveProcedure={onRemoveProcedure}
              procedures={procedures.category}
            />
          </div>
        ) : null}
        {procedures.category.length ? (
          <div className="h-5 w-px mx-3 bg-greyLighter self-center relative top-2" />
        ) : null}

        <div>
          <FormFieldLabel
            disabled={disabled}
            content="Addtl Procedures"
            required={true}
            className="mb-2 text-xs"
          />
          <AppointmentProcedureOptions
            teeth={teeth}
            onEditProcedure={onEditProcedure}
            onEditEstimates={onEditEstimates}
            disabled={disabled}
            onRemoveProcedure={onRemoveProcedure}
            procedures={procedures.additional}
          >
            <ButtonIcon theme="primary" SvgIcon={AddIcon} disabled={disabled} onClick={onAddProcedure} />
          </AppointmentProcedureOptions>
        </div>
      </div>
    </div>
  );
};
