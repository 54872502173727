/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useMemo } from "react";
import { PearlImageResponseGetEndpointsResult } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { PearlCategory, cxCategoryToColor } from "components/PatientProfile/Imaging/PearlAI/utils";
import { PearlConfig } from "components/PatientProfile/Imaging/types/pearl";
import { getLabelForAnnotationCategory } from "components/PatientProfile/Imaging/PearlAI/annotations";

const PATHOLOGIES: PearlCategory[] = ["calculus", "caries", "margin.discrepancy", "periapical.radiolucency"];

const NON_PATHOLOGIES: PearlCategory[] = ["bridge", "crown", "filling", "implant", "root.canal"];

const CategoryCheckboxes: React.FC<{
  categories: { count: number; category: PearlCategory }[];
  onChange: (updatedSet: Set<PearlCategory>) => void;
  selectedCategories: Set<PearlCategory>;
}> = ({ categories, onChange, selectedCategories }) => {
  return (
    <div className="flex flex-col gap-3 ml-2">
      {categories.map(({ category, count }) => {
        const bgColor = cxCategoryToColor[category];

        return (
          <Checkbox
            key={category}
            includeDarkMode
            checked={selectedCategories.has(category)}
            onChange={() => {
              onChange(
                selectedCategories.has(category)
                  ? new Set([...selectedCategories].filter((c) => c !== category))
                  : new Set([...selectedCategories, category])
              );
            }}
          >
            <div className="flex items-center justify-between">
              <div>{getLabelForAnnotationCategory(category)}</div>
              <div
                className={cx(
                  "rounded-full min-w-6 h-6 flex justify-center items-center",
                  bgColor ?? "bg-slate-300",
                  bgColor ? "text-white" : "text-greyDark"
                )}
              >
                {count}
              </div>
            </div>
          </Checkbox>
        );
      })}
    </div>
  );
};

export const PearlFlyoverPathologyCheckboxes: React.FC<{
  pearlConfig?: PearlConfig;
  pearlAnalysisResult?: PearlImageResponseGetEndpointsResult;
  onUpdatePearlConfig?: (config: Partial<PearlConfig>) => void;
}> = ({ pearlAnalysisResult, onUpdatePearlConfig, pearlConfig }) => {
  const pathologiesSelected = useMemo(() => {
    return new Set(PATHOLOGIES.filter((value) => pearlConfig?.layersAvailable.has(value)));
  }, [pearlConfig?.layersAvailable]);

  const { pathologyOptions, nonPathologyOptions } = useMemo(() => {
    const counts = (pearlAnalysisResult?.annotations ?? []).reduce(
      (acc, annotation) => {
        if (annotation.category) {
          acc[annotation.category] += 1;
        }

        return acc;
      },
      {
        crown: 0,
        filling: 0,
        anatomy: 0,
        caries: 0,
        "periapical.radiolucency": 0,
        "margin.discrepancy": 0,
        "root.canal": 0,
        implant: 0,
        bridge: 0,
        calculus: 0,
      }
    );

    return {
      pathologyOptions: PATHOLOGIES.map((category) => {
        return {
          category,
          count: counts[category],
        };
      }),
      nonPathologyOptions: NON_PATHOLOGIES.map((category) => {
        return {
          category,
          count: counts[category],
        };
      }),
    };
  }, [pearlAnalysisResult?.annotations]);
  const nonPathologiesSelected = useMemo(() => {
    return new Set(NON_PATHOLOGIES.filter((value) => pearlConfig?.layersAvailable.has(value)));
  }, [pearlConfig?.layersAvailable]);

  const togglePathologiesOn = useCallback(() => {
    const toolsOn = new Set(pearlConfig?.toolsOn);

    toolsOn.add("pathologies");

    return toolsOn;
  }, [pearlConfig?.toolsOn]);

  return (
    <div className="flex flex-col gap-6">
      <div>
        <Checkbox
          includeDarkMode
          indeterminate={pathologiesSelected.size < PATHOLOGIES.length - 1 && pathologiesSelected.size > 0}
          checked={pathologiesSelected.size === PATHOLOGIES.length}
          onChange={(e) => {
            onUpdatePearlConfig?.({
              toolsOn: togglePathologiesOn(),
              layersAvailable: e.target.checked
                ? new Set([...PATHOLOGIES, ...nonPathologiesSelected])
                : new Set(nonPathologiesSelected),
            });
          }}
        >
          Pathology
        </Checkbox>

        <div className="w-full border-b border-slate-600 border-dashed my-2" />
        <CategoryCheckboxes
          categories={pathologyOptions}
          selectedCategories={pathologiesSelected}
          onChange={(updatedSet) => {
            onUpdatePearlConfig?.({
              toolsOn: togglePathologiesOn(),
              layersAvailable: new Set([...updatedSet, ...nonPathologiesSelected]),
            });
          }}
        />
      </div>
      <div>
        <Checkbox
          indeterminate={
            nonPathologiesSelected.size < NON_PATHOLOGIES.length - 1 && nonPathologiesSelected.size > 0
          }
          includeDarkMode
          checked={nonPathologiesSelected.size === NON_PATHOLOGIES.length}
          onChange={(e) => {
            onUpdatePearlConfig?.({
              toolsOn: togglePathologiesOn(),
              layersAvailable: e.target.checked
                ? new Set([...NON_PATHOLOGIES, ...pathologiesSelected])
                : new Set(pathologiesSelected),
            });
          }}
        >
          Non-Pathology
        </Checkbox>
        <div className="w-full border-b border-slate-600 border-dashed my-2" />{" "}
        <CategoryCheckboxes
          categories={nonPathologyOptions}
          selectedCategories={nonPathologiesSelected}
          onChange={(updatedSet) => {
            onUpdatePearlConfig?.({
              toolsOn: togglePathologiesOn(),
              layersAvailable: new Set([...updatedSet, ...pathologiesSelected]),
            });
          }}
        />
      </div>
    </div>
  );
};
