import { ChangeEvent, FC, Fragment, useMemo } from "react";
import { DateWorktimeVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { HeaderCell, HeaderCheckboxCell, TableGrid } from "@libs/components/UI/GridTableComponents";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { AddClockInModal, AddClockInModalProps } from "components/EmployeeProfile/Timesheet/AddClockInModal";
import {
  AddClockOutModal,
  AddClockOutModalProps,
} from "components/EmployeeProfile/Timesheet/AddClockOutModal";
import {
  EditClockInModal,
  EditClockInModalProps,
} from "components/EmployeeProfile/Timesheet/EditClockInModal";
import {
  EditClockOutModal,
  EditClockOutModalProps,
} from "components/EmployeeProfile/Timesheet/EditClockOutModal";
import { useItemModal } from "hooks/useItemModal";
import {
  EditApprovedTimeModal,
  EditApprovedTimeModalProps,
} from "components/EmployeeProfile/Timesheet/EditApprovedTimeModal";
import { TimeSheetEmptyRow } from "components/EmployeeProfile/Timesheet/TimeSheetEmptyRow";
import { TimeSheetAddTimeRow } from "components/EmployeeProfile/Timesheet/TimeSheetAddTimeRow";
import { TimeSheetRow } from "components/EmployeeProfile/Timesheet/TimeSheetRow";

interface Props {
  timesheet: DateWorktimeVO[];
  employeeId: number;
  hasEmployeesPermissions: boolean;
  selectedRows: Set<number>;
  selectableRows: number[] | undefined;
  hoursInDecimal: boolean;
  stickyTopClassName: string;
  showDoubleOvertime: boolean;
  onSelectAllRows: Func;
  onDeselectAllRows: Func;
  onToggleEmployee: (e: ChangeEvent<HTMLInputElement>) => void;
  onApproveWorktime: (id: number) => void;
  onDeleteWorktime: (id: number) => void;
}

export const TimeSheetTable: FC<Props> = ({
  timesheet,
  employeeId,
  hasEmployeesPermissions,
  selectedRows,
  selectableRows,
  hoursInDecimal,
  stickyTopClassName,
  showDoubleOvertime,
  onSelectAllRows,
  onDeselectAllRows,
  onApproveWorktime,
  onDeleteWorktime,
  onToggleEmployee,
}) => {
  const columns = useMemo(
    () =>
      [
        { text: "Date", width: "1fr" },
        { text: "Regular Hours", width: "1fr" },
        { text: "Overtime", width: "1fr" },
        showDoubleOvertime ? { text: "Double OT", width: "1fr" } : undefined,
        hasEmployeesPermissions ? { text: "Checkbox", width: "60px" } : undefined,
        { text: "Clock in", width: "1fr" },
        { text: "Clock out", width: "1fr" },
        { text: "Hours", width: "1fr" },
        hasEmployeesPermissions ? { text: "Actions", width: "180px" } : undefined,
        { text: "Status", width: "1fr" },
      ].filter(isDefined),
    [hasEmployeesPermissions, showDoubleOvertime]
  );

  const addClockInModal = useItemModal<Omit<AddClockInModalProps, "onRequestClose" | "employeeId">>(null);
  const addClockOutModal = useItemModal<Omit<AddClockOutModalProps, "onRequestClose" | "employeeId">>(null);
  const editClockInModal = useItemModal<Omit<EditClockInModalProps, "onRequestClose" | "employeeId">>(null);
  const editClockOutModal = useItemModal<Omit<EditClockOutModalProps, "onRequestClose" | "employeeId">>(null);
  const editApprovalModal =
    useItemModal<Omit<EditApprovedTimeModalProps, "onRequestClose" | "employeeId">>(null);
  const confirmDeleteModal = useItemModal<number>(null);

  return (
    <>
      <TableGrid columnWidths={columns.map(({ width }) => width)}>
        {columns.map((item) =>
          item.text === "Checkbox" ? (
            <HeaderCheckboxCell
              key={item.text}
              selectedCount={selectedRows.size}
              totalRows={selectableRows?.length ?? 0}
              onSelectAllRows={onSelectAllRows}
              onDeselectAllRows={onDeselectAllRows}
              stickyTopClassName={stickyTopClassName}
            />
          ) : (
            <HeaderCell stickyTopClassName={stickyTopClassName} key={item.text}>
              {item.text}
            </HeaderCell>
          )
        )}

        {
          // eslint-disable-next-line complexity
          timesheet.map((hoursForDay) => {
            return hoursForDay.worktimeDetails.length === 0 ? (
              <TimeSheetEmptyRow
                key={hoursForDay.date}
                hoursForDay={hoursForDay}
                onAddTime={addClockInModal.open}
                selectedRows={selectedRows}
                hasEmployeesPermissions={hasEmployeesPermissions}
                showDoubleOvertime={showDoubleOvertime}
              />
            ) : (
              <Fragment key={hoursForDay.date}>
                {hoursForDay.worktimeDetails.map((item, index) => (
                  <TimeSheetRow
                    key={item.id}
                    hasEmployeesPermissions={hasEmployeesPermissions}
                    showDoubleOvertime={showDoubleOvertime}
                    selectableRows={selectableRows}
                    selectedRows={selectedRows}
                    employeeId={employeeId}
                    hoursForDay={hoursForDay}
                    index={index}
                    workTime={item}
                    hoursInDecimal={hoursInDecimal}
                    onAddClockOut={addClockOutModal.open}
                    onEditClockIn={editClockInModal.open}
                    onEditClockOut={editClockOutModal.open}
                    onToggleEmployee={onToggleEmployee}
                    onApproveWorktime={onApproveWorktime}
                    onDeleteWorktime={confirmDeleteModal.open}
                    onEditApproval={editApprovalModal.open}
                  />
                ))}
                {hoursForDay.worktimeDetails.at(-1)?.clockOut.time ? (
                  <TimeSheetAddTimeRow
                    key={`addTime-${hoursForDay.date}`}
                    hoursForDay={hoursForDay}
                    onAddTime={addClockInModal.open}
                    selectedRows={selectedRows}
                    hasEmployeesPermissions={hasEmployeesPermissions}
                    showDoubleOvertime={showDoubleOvertime}
                  />
                ) : null}
              </Fragment>
            );
          })
        }
      </TableGrid>
      {addClockInModal.isOpen ? (
        <AddClockInModal
          {...addClockInModal.item}
          employeeId={employeeId}
          onRequestClose={addClockInModal.close}
        />
      ) : null}
      {addClockOutModal.isOpen ? (
        <AddClockOutModal
          {...addClockOutModal.item}
          employeeId={employeeId}
          onRequestClose={addClockOutModal.close}
        />
      ) : null}
      {editClockInModal.isOpen ? (
        <EditClockInModal
          {...editClockInModal.item}
          employeeId={employeeId}
          onRequestClose={editClockInModal.close}
        />
      ) : null}
      {editClockOutModal.isOpen ? (
        <EditClockOutModal
          {...editClockOutModal.item}
          employeeId={employeeId}
          onRequestClose={editClockOutModal.close}
        />
      ) : null}
      {editApprovalModal.isOpen ? (
        <EditApprovedTimeModal
          {...editApprovalModal.item}
          employeeId={employeeId}
          onRequestClose={editApprovalModal.close}
        />
      ) : null}
      {confirmDeleteModal.isOpen ? (
        <ConfirmationModal
          primaryText="Are you sure you want to delete this time entry?"
          onConfirm={() => {
            onDeleteWorktime(confirmDeleteModal.item);
            confirmDeleteModal.close();
          }}
          onCancel={confirmDeleteModal.close}
        />
      ) : null}
    </>
  );
};
