import React from "react";
import { cx } from "@libs/utils/cx";

// Every one of these (except the default, where color is undefined) is a high contrast color on white.
// will need to update if we support dark theme.
export const cxPillColors = {
  blue: "bg-blue/10",
  magenta: "bg-magenta/10",
  green: "bg-green/10",
  red: "bg-red/10",
  orange: "bg-orange/10",
  greyDark: "bg-greyDark/10",
  primaryTheme: "bg-primaryTheme/10",
  greyMedium: "bg-greyMedium/10",
  secondaryTheme: "bg-secondaryTheme/10",

  // Default:
  white: "bg-white",
};
export type BadgeColor = keyof typeof cxPillColors;

export const cxTextColors: Record<BadgeColor, string> = {
  blue: "text-blue",
  magenta: "text-magenta",
  green: "text-green",
  red: "text-red",
  orange: "text-orange",
  greyDark: "text-greyDark",
  primaryTheme: "text-primaryTheme",
  greyMedium: "text-greyMedium",
  secondaryTheme: "text-secondaryTheme",

  // Default:
  white: "bg-white",
};

export interface BadgeProps extends React.HTMLProps<HTMLDivElement> {
  color?: BadgeColor;
  label: string;
  type?: "pill" | "stamp";
}

const cxBadgeStyles = {
  pill: (color: BadgeColor) =>
    cx(
      `text-xs
       rounded-full
       px-3
       text-center
       h-5
       flex
       items-center
       w-fit`,
      cxPillColors[color],
      // Color is inherited if background is white & add border
      color === "white" ? "border border-greyLighter" : cxTextColors[color]
    ),
  stamp: (color: BadgeColor) =>
    cx(
      `uppercase
       whitespace-nowrap
       border
       rounded
       px-2
       bg-white
       border-current
       w-fit`,
      cxTextColors[color]
    ),
};

export const Badge: React.FC<BadgeProps> = ({
  color = "white",
  label,
  className,
  type = "stamp",
  ...rest
}) => {
  return (
    <div className={cx(cxBadgeStyles[type](color), className)} {...rest}>
      {label}
    </div>
  );
};
