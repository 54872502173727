import { useCallback, useMemo } from "react";
import { ClaimVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as VerticalMenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { ClaimModalFooter } from "components/Claim/ModalFooter";

interface Props {
  claim: ClaimVO;
  isPrimaryClaim: boolean;
  isSubmitting: boolean;
  disableActions: boolean;
  onCancelClick: Func;
  onManuallySubmitClick: Func;
  onSkipPaymentClick: Func;
  onDeleteClick: Func;
  onDeleteAndCreateClick: Func;
  onSubmitClick: (overrideNeedsAttachment?: boolean) => void;
}

enum ButtonOptions {
  ManuallySubmit = "manual-submit",
  SkipPayment = "skip-payment",
  Delete = "delete",
  DeleteAndCreate = "delete-and-create",
}

export const FooterDraft: React.FC<Props> = ({
  claim,
  isPrimaryClaim,
  isSubmitting,
  disableActions,
  onCancelClick,
  onManuallySubmitClick,
  onSkipPaymentClick,
  onDeleteClick,
  onDeleteAndCreateClick,
  onSubmitClick,
}) => {
  const disableSubmitTooltip = useMemo(() => {
    const eobClippings = claim.attachments.filter((attachment) => attachment.type === "EOB");

    if (isSubmitting) {
      return "Submitting...";
    } else if (!isPrimaryClaim && !eobClippings.length) {
      return "Non-primary claims must have at least one primary claim EOB clipping attached";
    }

    return "";
  }, [claim.attachments, isPrimaryClaim, isSubmitting]);

  const handleSubmit = useCallback(() => {
    onSubmitClick();
  }, [onSubmitClick]);

  const disableButtons = useMemo(
    () => Boolean(disableSubmitTooltip) || disableActions,
    [disableActions, disableSubmitTooltip]
  );

  const footerMenu = useBoolean(false);
  const footerMenuOptions = useMemo(
    () =>
      createMenuOptions(
        {
          tooltip: {
            content:
              'Manually submit claim - this will NOT submit through DentalXChange. This will update the claim status to "Sent."',
          },
          label: "Manually Submit",
          value: ButtonOptions.ManuallySubmit,
          buttonProps: {
            disabled: disableButtons,
          },
        },
        {
          tooltip: {
            content: 'Skip recording insurance payment. This will update the claim status to "Completed."',
          },
          label: "Skip Payment",
          value: ButtonOptions.SkipPayment,
          buttonProps: {
            disabled: disableButtons,
          },
        },
        {
          tooltip: {
            content: "Delete this claim.",
          },
          label: "Delete",
          value: ButtonOptions.Delete,
          buttonProps: {
            disabled: disableButtons,
          },
        },
        {
          tooltip: {
            content:
              "Delete this claim and then navigate to create a claim for this patient and appointment.",
          },
          label: "Delete & Create New Draft",
          value: ButtonOptions.DeleteAndCreate,
          buttonProps: {
            disabled: disableButtons,
          },
        }
      ),
    [disableButtons]
  );

  const handleFooterOptionClick = useCallback(
    (option: ListItem<typeof footerMenuOptions>) => {
      switch (option.value) {
        case ButtonOptions.ManuallySubmit: {
          onManuallySubmitClick();
          break;
        }
        case ButtonOptions.SkipPayment: {
          onSkipPaymentClick();
          break;
        }
        case ButtonOptions.Delete: {
          onDeleteClick();
          break;
        }
        case ButtonOptions.DeleteAndCreate: {
          onDeleteAndCreateClick();
          break;
        }
        default: {
          break;
        }
      }
    },
    [onDeleteAndCreateClick, onDeleteClick, onManuallySubmitClick, onSkipPaymentClick]
  );

  return (
    <ClaimModalFooter>
      <Button className="w-[120px]" disabled={isSubmitting} onClick={onCancelClick} theme="secondary">
        Cancel
      </Button>
      <FloatingTooltip content={disableSubmitTooltip} theme={isSubmitting ? "SMALL" : "MEDIUM"}>
        <div>
          <AsyncButton
            className="w-[120px]"
            disabled={disableButtons}
            isLoading={isSubmitting}
            onClick={handleSubmit}
            theme="primary"
          >
            Submit
          </AsyncButton>
        </div>
      </FloatingTooltip>
      <ButtonMenu
        isOpen={footerMenu.isOn}
        menuContent={
          <div className="text-xs">
            <MenuOptions options={footerMenuOptions} onOptionClick={handleFooterOptionClick} />
          </div>
        }
        onRequestClose={footerMenu.off}
        onRequestOpen={footerMenu.on}
        placement="bottom-start"
      >
        {(props) => <ButtonIcon {...props} size="md" SvgIcon={VerticalMenuIcon} theme="primary" />}
      </ButtonMenu>
    </ClaimModalFooter>
  );
};
