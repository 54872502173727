import React from "react";
import { Link } from "react-router-dom";

import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { paths } from "utils/routing/paths";

export const PlanNotFound: React.FC<{ patientId: number; insuranceId: number | "new" }> = ({
  patientId,
  insuranceId,
}) => (
  <ErrorContent>
    <div className="flex flex-col items-center justify-center space-y-4">
      <div>Configure a plan first before editing this section</div>
      <Link
        to={paths.patientInsuranceStep({
          patientId,
          insuranceId,
          step: "carrier",
        })}
        className="text-sm font-sansSemiBold text-primaryTheme"
      >
        Configure Plan
      </Link>
    </div>
  </ErrorContent>
);
