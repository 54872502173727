import React, { useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { ProviderVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { Spinner } from "@libs/components/UI/Spinner";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { FormFieldColorPicker } from "@libs/components/UI/FormFieldColorPicker";
import { colorsByName } from "@libs/domains/scheduling/colors";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { BaseFormSection } from "components/UI/FormSection";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";

interface Props {
  onUpdateProviderColor: (providerId: number, color: string) => void;
  onUpdateProvider: (providerId: number) => void;
  onBookingLimitChanged: (providerId: number, bookingLimit?: number) => void;
  providers: ProviderVO[];
  isSavingColor: boolean;
  selectedProviderId: number;
  bookingLimit?: number;
  className?: string;
}

const DEBOUNCE_LIMITDAYS = 1000;

type LimitType = "M" | "D";

const bookingLimitTypeOptions: OptionInputOption<LimitType>[] = [
  { value: "M" as const, label: "Months" },
  { value: "D" as const, label: "Days" },
];

const THIRTY_DAYS = 30;
const isMonths = (limit: number) => limit % THIRTY_DAYS === 0;

type BookingLimitData = { limit?: number; type: LimitType };

const coerceBookingLimitData = (bookingLimit?: number): BookingLimitData => {
  {
    if (bookingLimit == null) {
      return { limit: undefined, type: "M" };
    }

    if (isMonths(bookingLimit)) {
      return { limit: bookingLimit / THIRTY_DAYS, type: "M" };
    }

    return { limit: bookingLimit, type: "D" };
  }
};

const convertToBookingLimit = (data: BookingLimitData) => {
  if (data.limit == null) {
    return undefined;
  }

  if (data.type === "M") {
    return data.limit * THIRTY_DAYS;
  }

  return data.limit;
};

export const SelectProvider: React.FC<Props> = ({
  selectedProviderId,
  onUpdateProvider,
  onUpdateProviderColor,
  onBookingLimitChanged,
  providers,
  isSavingColor,
  className,
}) => {
  const selectedProvider = providers.find((provider) => provider.id === selectedProviderId);
  const [color, setColor] = useState(() => providers[0]?.color ?? colorsByName["Blue Gray"].light);
  const [bookingData, setBookingData] = useState<BookingLimitData>({ type: "M" });

  useEffect(() => {
    setBookingData(coerceBookingLimitData(selectedProvider?.bookingLimitInDays));
  }, [selectedProvider?.bookingLimitInDays]);

  const debouncedOnBookingLimitChanged = useDebouncedCallback(onBookingLimitChanged, DEBOUNCE_LIMITDAYS);

  const handleBookingLimitChange = (data: BookingLimitData) => {
    setBookingData(data);
    debouncedOnBookingLimitChanged(selectedProviderId, convertToBookingLimit(data));
  };

  const handleUpdateProviderColor = (newColor: string) => {
    setColor(newColor);
    onUpdateProviderColor(selectedProviderId, newColor);
  };

  const handleUpdateProvider = (newProviderId: number) => {
    const newProvider = providers.find((p) => p.id === newProviderId) as ProviderVO;

    setColor(newProvider.color);
    onUpdateProvider(newProviderId);
  };

  const providerOptions = useMemo(
    () =>
      providers.map((provider) => ({
        value: provider.id,
        label: provider.name.fullDisplayName,
      })),
    [providers]
  );

  return (
    <div className={cx("flex flex-col", className)}>
      <FormFieldSelect
        label="Select Provider/Hygienist"
        layout="labelIn"
        isClearable={false}
        options={providerOptions}
        placeholder="Select Provider"
        onItemSelected={handleUpdateProvider}
        isSearchable={true}
        value={selectedProviderId}
        className="w-72 pb-3"
      />
      <BaseFormSection
        className="py-3 relative"
        title="Color"
        subTitle="This is the color that will be used on appointment cards for this provider"
      >
        <FormFieldColorPicker value={color} aria-label="color" onChange={handleUpdateProviderColor} />
        {isSavingColor ? (
          <Spinner size="sm" animation="border" variant="secondary" className="absolute -right-8 bottom-5" />
        ) : null}
      </BaseFormSection>
      <BaseFormSection
        className="py-3"
        title="Booking Limit"
        subTitle="Specify how far in the future you can be scheduled for an appointment"
      >
        <div className="flex flex-row gap-2">
          <FormFieldNumberInput
            className="w-full"
            aria-label="Booking Limit"
            min={1}
            clamp
            value={bookingData.limit}
            onChange={(e) =>
              handleBookingLimitChange({
                ...bookingData,
                limit: e.target.value === "" ? undefined : Number(e.target.value),
              })
            }
          />
          <FormFieldSelect
            className="w-full"
            aria-label="Booking Limit Type"
            value={bookingData.type}
            isClearable={false}
            options={bookingLimitTypeOptions}
            onChange={(e) =>
              e &&
              handleBookingLimitChange({
                ...bookingData,
                type: e.value,
              })
            }
          />
        </div>
      </BaseFormSection>
    </div>
  );
};
