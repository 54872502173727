import React, { useCallback } from "react";
import { EmployeeRolesVO, EmployeeVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { ApiQueryResult, UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import {
  TableGrid,
  HeaderButtonCell,
  HeaderCell,
  ColumnSortIndicator,
} from "@libs/components/UI/GridTableComponents";
import { Divider } from "components/UI/Divider";
import EmptyPatientList from "assets/images/empty-people-list.svg";
import { EmployeeRow } from "components/Settings/Security/IPAuthorization/EmployeeRow";
import { EmployeesQueryUpdates } from "components/Settings/Security/IPAuthorization/IpFilterModal";

interface Props {
  disabled: boolean;
  employees: EmployeeVO[];
  employeesQuery: UseInfiniteApiListQueryResult<EmployeeVO>;
  rolesQuery: ApiQueryResult<EmployeeRolesVO[]>;
  onSelectEmployee: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onSort: (keyValues: EmployeesQueryUpdates) => void;
  queryParams: EmployeesQueryUpdates;
  selectedEmployees: Set<number>;
}

const headers = [
  { id: "checkbox", label: "", width: "50px" },
  { id: "lastName", label: "Name", sortable: true, width: "2fr" },
  { id: "status", label: "Employee Status", sortable: true, width: "2fr" },
  { id: "title", label: "Job Title", width: "2fr" },
  // TODO: Sort by role when supported by BE
  // { id: "role", label: "Role", sortable: true, width: "1fr" },
  { id: "role", label: "Role", width: "1fr" },
];

export const EmployeesTable: React.FC<Props> = ({
  disabled,
  employees,
  employeesQuery,
  rolesQuery,
  onSelectEmployee,
  onSort,
  queryParams,
  selectedEmployees,
}) => {
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: employeesQuery });

  const handleSort = useCallback(
    (columnId: string) => {
      const keyValues = {} as EmployeesQueryUpdates;

      if (columnId === queryParams.sortColumn) {
        keyValues.orderBy = queryParams.orderBy === "ASCENDING" ? "DESCENDING" : "ASCENDING";
      } else {
        keyValues.orderBy = "ASCENDING";
        keyValues.sortColumn = columnId;
      }

      onSort(keyValues);
    },
    [onSort, queryParams.orderBy, queryParams.sortColumn]
  );

  return (
    <QueryResult queries={[employeesQuery, rolesQuery]}>
      <div className={cx("overflow-y-auto", disabled && "opacity-40")}>
        <div className="mb-3 font-sansSemiBold text-sm">{`Employees Assigned (${selectedEmployees.size})`}</div>
        <Divider />
        <div className="overflow-y-auto" ref={rootRef}>
          <ScrollableInfiniteQueryResult
            infiniteQuery={employeesQuery}
            noResults={
              <div className="flex flex-col items-center justify-center h-full">
                <img alt="No Employees" src={EmptyPatientList} />
                <div className="mt-5 text-sm text-greyDark">No Employees</div>
              </div>
            }
            scrollRef={scrollRef}
          >
            <TableGrid columnWidths={headers.map(({ width }) => width)}>
              {headers.map((item) =>
                item.sortable ? (
                  <HeaderButtonCell key={item.id} onClick={() => handleSort(item.id)}>
                    {item.label}
                    <ColumnSortIndicator
                      className="ml-2"
                      direction={queryParams.sortColumn === item.id ? queryParams.orderBy : undefined}
                    />
                  </HeaderButtonCell>
                ) : (
                  <HeaderCell key={item.id}>{item.label}</HeaderCell>
                )
              )}
              {employees.map((employee) => (
                <EmployeeRow
                  employee={employee}
                  roles={rolesQuery.data ?? []}
                  isSelected={selectedEmployees.has(employee.id)}
                  key={employee.id}
                  onSelect={onSelectEmployee}
                />
              ))}
            </TableGrid>
          </ScrollableInfiniteQueryResult>
        </div>
      </div>
    </QueryResult>
  );
};
