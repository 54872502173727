import React, { Dispatch, SetStateAction, useMemo } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { SlotSelections } from "components/ScheduleAppointments/types";
import {
  SlotAttributeOption,
  SlotAttributeOptionProps,
} from "components/ScheduleAppointments/SlotAttributeOption";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { getAvailableProvidersQuery } from "api/scheduling/queries";

type Props = {
  durationInMinutes: number;
  onSelectedSlotUpdated: Dispatch<SetStateAction<SlotSelections>>;
} & Required<SlotSelections>;

export const SelectDentist: React.FC<Props> = ({
  durationInMinutes,
  onSelectedSlotUpdated,
  ...selections
}) => {
  const { practiceId } = useAccount();

  const [availableProvidersQuery] = useApiQueries([
    getAvailableProvidersQuery({
      args: {
        practiceId,
        filters: {
          appointmentDurationInMinutes: durationInMinutes,
          startDate: selections.date,
          startTime: selections.startTime,
        },
      },
    }),
  ]);

  const dentistOptions = useMemo(() => {
    const options: SlotAttributeOptionProps[] = [];
    const availableProviders = availableProvidersQuery.data;

    if (availableProviders) {
      for (const provider of availableProviders) {
        if (provider.jobCategory === "DENTIST") {
          options.push({
            value: provider.id,
            label: provider.name.fullDisplayName,
            isAvailable: provider.isAvailable,
          });
        }
      }
    }

    return options;
  }, [availableProvidersQuery.data]);

  return (
    <div className="mb-2 min-h-24">
      <QueryResult
        queries={[availableProvidersQuery]}
        loadError={
          <ErrorContent layout="horiz">
            There was a problem loading the available dentists. In order to schedule this appointment, you
            must select a dentist above instead of a hygienist.
          </ErrorContent>
        }
      >
        <div className="bg-actionLight p-2 rounded">
          <div className="text-primaryTheme text-xs">
            You have selected a hygienist. Please select a provider as well.
          </div>
          <FormFieldSelect
            required={true}
            className="mt-2"
            layout="labelOut"
            options={dentistOptions}
            isSearchable={false}
            placeholder="Select Provider"
            formatOptionLabel={(props, meta) => (
              <SlotAttributeOption {...props} context={meta.context} selectedValue={selections.dentistId} />
            )}
            onItemSelected={(value) => onSelectedSlotUpdated((last) => ({ ...last, dentistId: value }))}
            value={selections.dentistId}
          />
        </div>
      </QueryResult>
    </div>
  );
};
