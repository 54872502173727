import { FC } from "react";
import { Link } from "react-router-dom";
import { AppointmentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { TextDivider } from "components/PatientProfile/PatientOverview/Divider";
import { AppointmentMenu, AppointmentMenuProps } from "components/ScheduleAppointments/AppointmentMenu";
import { paths } from "utils/routing/paths";
import {
  AppointmentDate,
  AppointmentPills,
  AppointmentProvidersRoom,
  AppointmentTimeSpan,
} from "components/ScheduleAppointments/AppointmentDetailComponents";

interface Props {
  onAppointmentDeleted: AppointmentMenuProps["onAppointmentDeleted"];
  className?: string;
  appointment: AppointmentVO;
}

export const Appointment: FC<Props> = ({ appointment, onAppointmentDeleted, className }) => {
  return (
    <div key={appointment.id} className={cx("border-b border-b-greyLighter", className)}>
      <div className="flex justify-between items-start">
        <div className="flex items-center flex-wrap text-xs">
          <Link
            to={
              appointment.state === "UNSCHEDULED"
                ? paths.schedule({
                    patientId: appointment.patient.id,
                    appointmentId: appointment.id,
                    showHolds: true,
                  })
                : paths.schedule({
                    patientId: appointment.patient.id,
                    date: appointment.date,
                    appointmentId: appointment.id,
                  })
            }
            className="text-sm font-sansSemiBold text-primaryTheme"
          >
            <AppointmentDate date={appointment.date} state={appointment.state} />
          </Link>
          <TextDivider />
          <AppointmentTimeSpan
            duration={appointment.duration}
            date={appointment.date}
            state={appointment.state}
            startTime={appointment.startTime}
            endTime={appointment.endTime}
          />
          <TextDivider />
          <AppointmentProvidersRoom
            dentist={appointment.dentist}
            provider={appointment.provider}
            date={appointment.date}
            state={appointment.state}
            room={appointment.room}
          />
        </div>
        <div className="flex-none">
          <AppointmentMenu appointment={appointment} onAppointmentDeleted={onAppointmentDeleted} />
        </div>
      </div>
      <div className="mt-2">
        <AppointmentPills appointment={appointment} />
      </div>
      {appointment.comments ? (
        <p className="whitespace-pre-wrap text-xs mt-2">{appointment.comments}</p>
      ) : null}
    </div>
  );
};
