export const getReleaseOfPatientInfoDisclosure = (
  releaseOfPatientInfo: boolean,
  practiceName = "The practice"
) => (releaseOfPatientInfo ? `${practiceName} will file claims for the patient.` : "");

export const getAssignmentOfBenefitsDisclosure = (
  assignedToPractice: boolean,
  practiceName = "The practice"
) => {
  return `${assignedToPractice ? practiceName : "The patient"} will receive payments from insurance.`;
};
