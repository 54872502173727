import { FC } from "react";
import { Route } from "react-router-dom";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { SettingApp } from "components/Settings/SettingsApp";
import { GustoAuthenticateRoute } from "components/Settings/GustoAuthenticateRoute";
import { GustoPracticeFlowRoute } from "components/Settings/GustoPracticeFlowRoute";
import { MainAppHistoryProvider } from "components/Main/MainLinksContext";

export const SettingsRoute: FC = () => {
  usePageTitle("Practice Settings");

  return (
    <MainAppHistoryProvider name="settings">
      <SentryRoutes>
        <Route path="authenticate-gusto" element={<GustoAuthenticateRoute />} />
        <Route path="gusto" element={<GustoPracticeFlowRoute />} />
        <Route path="*" element={<SettingApp />} />
      </SentryRoutes>
    </MainAppHistoryProvider>
  );
};
