import { FC, ReactNode, useMemo, useRef } from "react";
import { isDefined } from "@libs/utils/types";
import { Spinner } from "@libs/components/UI/Spinner";
import { QueryResult } from "@libs/components/UI/QueryResult";

type LoadingStateQueryResult = { isLoading: boolean; isFetching: boolean; isError: boolean; data: unknown };

export type ScheduleQueryMap = {
  schedulingConfigQuery: LoadingStateQueryResult;
  appointmentsQuery: LoadingStateQueryResult;
  scheduleBlocksQuery: LoadingStateQueryResult;
  roomsQuery: LoadingStateQueryResult;
  providersQuery: LoadingStateQueryResult;
  providerOpenHoursQuery: LoadingStateQueryResult;
  roomsOpenHoursQuery: LoadingStateQueryResult;
};

/**
 * useScheduleQueriesResult provides a custom loading experience for the schedule.
 * Typically we can say a whole section is loading if any of the queries are loading.
 * However, we want to show a loading state for the schedule only when it is structural
 * queries that are loading and not queries that change when the current day changes.
 * For those changes we want to show a non UI blocking loading state.
 *
 * @param isGroupedByRoom a boolean to indicate the schedule grouping context to know
 * which queries currently matter.
 *
 * All the queries that the schedule relies on for a loading state.
 * @params schedulingConfigQuery
 * @params appointmentsQuery
 * @params scheduleBlocksQuery
 * @params roomsQuery
 * @params providersQuery
 * @params practiceOpenHoursQuery
 * @params providerOpenHoursQuery
 * @returns {
 *  isFetching: boolean;
 *  queries: LoadingStateQueryResult[];
 * }
 *
 * "queries" is an array of the loading states where isLoading is only true if a given query
 * if it is the first time it is loading.
 *
 * "isFetching" gives us another loading state to provide the user with feedback that the schedule
 * is fetching data for some data that the schedule relies without blocking the UI
 *
 */

export interface ScheduleQueriesResultProps extends ScheduleQueryMap {
  isGroupedByRoom: boolean;
  children?: ReactNode;
}

export const useScheduleQueriesResult = ({
  isGroupedByRoom,
  schedulingConfigQuery,
  appointmentsQuery,
  scheduleBlocksQuery,
  roomsQuery,
  providersQuery,
  providerOpenHoursQuery,
  roomsOpenHoursQuery,
}: ScheduleQueriesResultProps) => {
  const dayQueries = useMemo(() => {
    return [
      appointmentsQuery,
      scheduleBlocksQuery,
      isGroupedByRoom ? roomsOpenHoursQuery : null,
      isGroupedByRoom ? null : providerOpenHoursQuery,
    ];
  }, [appointmentsQuery, scheduleBlocksQuery, isGroupedByRoom, providerOpenHoursQuery, roomsOpenHoursQuery]);

  // a ref to keep track of whether a query has loaded before
  const dayQueriesLoadedRef = useRef(dayQueries.map(() => false));

  const structureQueries = useMemo(() => {
    return [schedulingConfigQuery, isGroupedByRoom ? roomsQuery : providersQuery].map((query) => ({
      isFetching: query.isFetching,
      isLoading: query.isLoading,
      isError: query.isError,
    }));
  }, [schedulingConfigQuery, isGroupedByRoom, roomsQuery, providersQuery]);

  const scheduleLoadState = useMemo(() => {
    const mappedDayQueries = dayQueries.map((query, index) => {
      if (!query) {
        return query;
      }

      // a query can be considered loaded
      // if there is currently no error
      // the query has currently loaded
      // or has loaded before
      dayQueriesLoadedRef.current[index] =
        !query.isError && (dayQueriesLoadedRef.current[index] || Boolean(query.data));

      return {
        isFetching: query.isFetching,
        isLoading: Boolean(query.isLoading && !dayQueriesLoadedRef.current[index]),
        isError: query.isError,
      };
    });

    const combined = [...structureQueries, ...mappedDayQueries].filter(isDefined);

    return {
      queries: combined,
      isFetching: combined.some((result) => result.isFetching),
    };
  }, [structureQueries, dayQueries]);

  return scheduleLoadState;
};

export const ScheduleQueriesResult: FC<ScheduleQueriesResultProps> = ({ children, ...props }) => {
  const { queries, isFetching } = useScheduleQueriesResult(props);

  return (
    <QueryResult queries={queries}>
      {isFetching ? (
        <Spinner className="absolute top-3 left-6 z-10" size="md" variant="greyLighter" animation="border" />
      ) : null}
      {children}
    </QueryResult>
  );
};
