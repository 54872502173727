import { FC } from "react";
import { DraftAutomationAction } from "components/Communications/Automations/types";
import { ToggleGroup } from "components/UI/ToggleGroup";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { AutomationActionValidation, IntervalTypeOptions } from "components/Communications/Automations/utils";

interface Props {
  validation: AutomationActionValidation;
  action: DraftAutomationAction;
  onUpdate: (updates: Partial<DraftAutomationAction>) => void;
}

export const WhenOrAfterTimeFields: FC<Props> = ({ validation, action, onUpdate }) => {
  return (
    <div className="flex items-center gap-x-2">
      <ToggleGroup
        onChange={(_e, { value }) => {
          onUpdate({ eventTime: value });
        }}
        options={[
          { label: "Instant", value: "WHEN" },
          { label: "Wait", value: "AFTER" },
        ]}
        size="md"
        selectedValue={action.eventTime}
      />
      <FormFieldNumberInput
        onValueChange={(newValue) => onUpdate({ interval: newValue })}
        value={action.interval}
        min={1}
        step={1}
        clamp
        placeholder="0"
        disabled={action.eventTime === "WHEN"}
        error={validation.interval.$error}
        displayErrorMessage={false}
        className="flex-none w-14"
      />
      <FormFieldSelect
        options={IntervalTypeOptions}
        value={action.intervalType}
        isClearable={false}
        aria-label="Select Period"
        isSearchable={false}
        onItemSelected={(newValue) => onUpdate({ intervalType: newValue })}
        placeholder="Select Period"
        disabled={action.eventTime === "WHEN"}
        error={validation.intervalType.$error}
        displayErrorMessage={false}
        className="flex-none w-36"
      />
    </div>
  );
};
