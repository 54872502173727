import { useMemo, useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { PatientSummaryVO } from "@libs/api/generated-api";
import { parsePhoneNumber } from "@libs/utils/phone";
import { isOneOf } from "@libs/utils/isOneOf";
import { BAD_GATEWAY, INTERNAL_SERVER_ERROR, SERVICE_UNAVAILABLE } from "@libs/utils/statusCodes";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ApiErrorResponse } from "@libs/@types/api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as PhoneIcon } from "@libs/assets/icons/call.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { AlertModal } from "@libs/components/UI/AlertModal";
import { paths } from "utils/routing/paths";
import { useMangoContext } from "components/Mango/MangoContext";
import { handleError } from "utils/handleError";
import { ExtensionsModal } from "components/Settings/PracticeSetup/Sections/Phones/ExtensionsModalContent";
import {
  ContactMango,
  PHONE_OOPS_TITLE,
} from "components/Settings/PracticeSetup/Sections/Phones/FailedAuthKey";
import { getPhoneExtensions } from "api/settings/phones/queries";
import { isCallingDisabled } from "components/Patient/contactModes";

interface Props {
  patientId: number;
  patientContact: PatientSummaryVO["contact"];
}

export const MakePhoneCall: React.FC<Props> = ({ patientId, patientContact }) => {
  const { callPhone, contactModes } = patientContact;
  const { practiceId } = useAccount();
  const navigate = useNavigate();

  const { inboundCallsMap, makeCall, mode, outboundCallsMap, updateMode, allowInbound, ext, setMangoConfig } =
    useMangoContext();
  const [phoneExtensionsQuery] = useApiQueries([
    getPhoneExtensions({ args: { practiceId }, queryOptions: { enabled: mode === "calling" } }),
  ]);

  const ptCurrentlyOnCall = useMemo(() => {
    return callPhone
      ? Boolean(parsePhoneNumber(callPhone) in { ...inboundCallsMap, ...outboundCallsMap })
      : false;
  }, [inboundCallsMap, outboundCallsMap, callPhone]);

  const handleCallError = useCallback(
    (e: ApiErrorResponse) => {
      if (isOneOf(e.status, [INTERNAL_SERVER_ERROR, BAD_GATEWAY, SERVICE_UNAVAILABLE, SERVICE_UNAVAILABLE])) {
        updateMode("contactMango");
      } else {
        const errCode = e.error.errors?.[0].errorCode;

        if (errCode === "PHONE_EXTENSION") {
          updateMode("phoneSettings");
        } else if (errCode === "EXTERNAL_SERVICE") {
          updateMode("noConnection");
        } else {
          updateMode(undefined);
          handleError(e);
        }
      }
    },
    [updateMode]
  );

  useEffect(() => {
    if (mode === "calling" && phoneExtensionsQuery.isFetched) {
      if (!ext || !phoneExtensionsQuery.data) {
        updateMode("noConnection");
      } else if (ext === "None") {
        updateMode("phoneSettings");
      } else if (callPhone) {
        makeCall(ext, parsePhoneNumber(callPhone), patientId, handleCallError);
      }
    }
  }, [
    ext,
    handleCallError,
    mode,
    makeCall,
    patientId,
    callPhone,
    phoneExtensionsQuery.data,
    phoneExtensionsQuery.isFetched,
    updateMode,
  ]);

  const handleNavigateToSettings = useCallback(() => {
    navigate(paths.settingsSection({ section: "phones" }));
  }, [navigate]);

  return (
    <>
      <ButtonIcon
        SvgIcon={PhoneIcon}
        onClick={() => updateMode("calling")}
        disabled={isCallingDisabled(patientContact) || ptCurrentlyOnCall}
        tooltip={
          callPhone
            ? contactModes.call
              ? {
                  content: ptCurrentlyOnCall ? "Currently on a call" : "Call",
                  theme: ptCurrentlyOnCall ? "MEDIUM" : "SMALL",
                }
              : {
                  content: "Patient has not allowed calls",
                  theme: "SMALL",
                }
            : {
                content: "Patient has not added a phone number",
                theme: "SMALL",
              }
        }
      />

      {mode === "noConnection" ? (
        <ConfirmationModal
          confirmText="Go to Settings"
          onCancel={() => updateMode(undefined)}
          onConfirm={handleNavigateToSettings}
          primaryText="No Connection"
          secondaryText="Archy needs to be connected to Mango Voice to enable outbound calls. Go to Settings to establish the connection."
          size="2xs"
        />
      ) : mode === "phoneSettings" ? (
        <ExtensionsModal
          allowInbound={allowInbound}
          extensions={phoneExtensionsQuery.data ?? []}
          onClose={() => updateMode(undefined)}
          selectedExt={ext}
          onSave={setMangoConfig}
        />
      ) : mode === "contactMango" ? (
        <AlertModal
          size="2xs"
          onConfirm={() => updateMode(undefined)}
          primaryText={PHONE_OOPS_TITLE}
          secondaryText={<ContactMango />}
        />
      ) : null}
    </>
  );
};
