/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, {
  ComponentPropsWithRef,
  ComponentPropsWithoutRef,
  FC,
  Fragment,
  PropsWithChildren,
  ReactNode,
  forwardRef,
  useEffect,
  useMemo,
} from "react";
import Skeleton from "react-loading-skeleton";
import { LedgerV2AdjustmentEntryVO, LedgerV2AppointmentEntryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatCurrency } from "@libs/utils/currency";
import { getLocalDate } from "@libs/utils/date";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { flattenPages } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useAccount } from "@libs/contexts/AccountContext";
import { LayoutCard } from "@libs/components/UI/LayoutCard";

import { QueryResult } from "@libs/components/UI/QueryResult";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { usePathParams } from "hooks/usePathParams";

import { ToggleCaret } from "components/UI/ToggleCaret";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import {
  AccordionContainer,
  Col,
  PracticeAndPatientIds,
  Row,
} from "components/PatientProfile/Billing/Ledger/LedgerComponents";
import {
  getLedgerEntries,
  getLedgerEntryByTypeAndId,
  getPendingInvoiceEntries,
  listMigratedTransactions,
} from "api/billing/queries";

import { useExpandedLedgerItemsStorage } from "storage/billing";
import { invoiceEntryOrLineItemToId } from "components/PatientProfile/Billing/invoiceUtils";
import { PatientBillingAppHistoryProvider } from "components/PatientProfile/Billing/PatientBillingLinksContex";
import { ImportedLedgerBanner } from "components/PatientProfile/Billing/ImportedLedger/ImportedLedgerBanner";
import { DescriptionSticker } from "./DescriptionSticker";
import { PatientRunningBalancePill } from "./PatientRunningBalancePill";
import { InsuranceRunningBalancePill } from "./InsuranceRunningBalancePill";
import { AppointmentMenu } from "./AppointmentMenu";
import { LoadingMoreIndicator } from "./LoadingMoreIndicator";
import { Text } from "./Text";
import { LedgerTabsRow } from "./LedgerTabsRow";
import { AdjustmentMenu } from "./AdjustmentMenu";
import {
  isLedgerAppointmentEntry,
  isLedgerAdjustmentEntry,
  isInvoiceSubEntry,
  isClaimSubEntry,
  isClaimBalanceTransferSubEntry,
  isAppointmentEditSubEntry,
  isAppointmentSubEntry,
  ledgerEntryToId,
  ledgerSubEntryToId,
} from "./ledgerUtils";
import { usePersistExpandRow } from "./usePersistExpandRow";
import { DetailsTable } from "./DetailsTable";
import { AdjustmentPatientPill } from "./AdjustmentPatientPill";
import { ClaimExpandable } from "./ClaimExpandable";
import { ClaimBalanceTransferExpandable } from "./ClaimBalanceTransferExpandable";
import { AppointmentEditExpandable } from "./AppointmentEditExpandable";
import { InvoiceExpandable } from "./InvoiceExpandable";
import { AppointmentAdjustmentTableRow } from "./AppointmentAdjustmentTableRow";
import { ProviderInitials } from "./ProviderInitials";

export const AppointmentsAndAdjustmentsPage: FC = () => {
  const { practiceId } = useAccount();
  const { patientId } = usePathParams("patientBilling");

  const migratedTransactionsQuery = useInfiniteApiQuery(
    listMigratedTransactions({
      args: {
        patientId,
        practiceId,
        pageNumber: 1,
        pageSize: 1,
      },
    })
  );

  const hasImportedLedger = useMemo(
    () => (flattenPages(migratedTransactionsQuery.data) ?? []).length > 0,
    [migratedTransactionsQuery.data]
  );

  const ledgerOrdersQuery = useInfiniteApiQuery(
    getLedgerEntries({
      args: {
        patientId,
        practiceId,
        pageNumber: 1,
        pageSize: 20,
      },
    })
  );

  const { scrollRef, rootRef } = useInfiniteScrollQuery({ infiniteQuery: ledgerOrdersQuery });
  const ledgerOrders = flattenPages(ledgerOrdersQuery.data);

  const [invoiceableEntriesQuery] = useApiQueries([
    getPendingInvoiceEntries({ args: { patientId, practiceId } }),
  ]);

  const invoiceables = useMemo(
    () => new Set((invoiceableEntriesQuery.data ?? []).map(invoiceEntryOrLineItemToId)),
    [invoiceableEntriesQuery.data]
  );

  const { clearLedgerExpandables } = useExpandedLedgerItemsStorage();

  // Clear expanded items when the page is loading ledger orders for the first
  // time (they aren't in the cache yet). We want to show collapsed items on the
  // first visit.
  useEffect(() => {
    if (ledgerOrdersQuery.isInitialLoading) {
      clearLedgerExpandables();
    }
  }, [clearLedgerExpandables, ledgerOrdersQuery.isInitialLoading]);

  return (
    <PatientBillingAppHistoryProvider name="patientBilling">
      <LayoutCard className="relative flex flex-col divide-y w-full">
        <LedgerTabsRow patientId={patientId} practiceId={practiceId} selected="Ledger" />
        <LedgerHeader />
        <QueryResult queries={[ledgerOrdersQuery]} loading={<LoadingOverlaySpinner />}>
          <LedgerContainer ref={rootRef}>
            {ledgerOrders?.map((ledgerOrder, i) => {
              const ledgerEntryId = ledgerEntryToId(ledgerOrder);
              const isInvoiceable = invoiceables.has(ledgerEntryId);

              return (
                <Fragment key={ledgerEntryId}>
                  {isLedgerAppointmentEntry(ledgerOrder) ? (
                    <AppointmentLedgerItem
                      practiceId={practiceId}
                      patientId={patientId}
                      appointmentEntry={ledgerOrder}
                      isInvoiceable={isInvoiceable}
                    />
                  ) : isLedgerAdjustmentEntry(ledgerOrder) ? (
                    <AdjustmentLedgerItem
                      practiceId={practiceId}
                      patientId={patientId}
                      adjustmentEntry={ledgerOrder}
                      isInvoiceable={isInvoiceable}
                    />
                  ) : null}
                  {i === ledgerOrders.length - 1 && ledgerOrdersQuery.hasNextPage && (
                    <LoadingMoreIndicator ref={scrollRef}>
                      <LoadingSkeleton />
                    </LoadingMoreIndicator>
                  )}
                </Fragment>
              );
            })}

            {hasImportedLedger ? <ImportedLedgerBanner patientId={patientId} /> : null}
          </LedgerContainer>
        </QueryResult>
      </LayoutCard>
    </PatientBillingAppHistoryProvider>
  );
};

const LedgerHeader: FC = () => {
  return (
    <Row full={false} padding="billingHeader">
      <Columns
        indentation={0}
        icon={undefined}
        date={<Text bold>Date</Text>}
        details={<Text bold>Details</Text>}
        amount={<Text bold>Amount</Text>}
        outstanding={<Text bold>Outstanding</Text>}
        menu={undefined}
      />
    </Row>
  );
};

export const ExpandableRow: FC<{
  padding?: ComponentPropsWithoutRef<typeof Row>["padding"];
  indentation: number;
  date: ReactNode;
  details: ReactNode;
  amount: ReactNode;
  outstanding: ReactNode;
  menu: ReactNode;
  expandedContent: ReactNode;
  itemId: string;
  isExpanded: boolean;
  onToggleExpand: Func;
}> = ({
  padding,
  indentation,
  date,
  details,
  amount,
  outstanding,
  menu,
  expandedContent,
  isExpanded,
  onToggleExpand,
}) => {
  return (
    <>
      <Row padding={padding}>
        <Columns
          indentation={indentation}
          icon={
            <ToggleCaret
              initialState={isExpanded ? "opened" : "closed"}
              size="lg"
              onToggle={onToggleExpand}
            />
          }
          date={date}
          details={details}
          amount={amount}
          outstanding={outstanding}
          menu={menu}
        />
      </Row>
      {isExpanded && expandedContent}
    </>
  );
};

const Columns: FC<{
  indentation: number;
  icon: ReactNode;
  date: ReactNode;
  details: ReactNode;
  amount: ReactNode;
  outstanding: ReactNode;
  menu: ReactNode;
}> = ({ indentation, icon, date, details, amount, outstanding, menu }) => {
  return (
    <>
      <Col justify="center" width="xxs">
        {icon}
      </Col>
      <IndentOffset indentation={indentation} />
      <Col justify="left" width="lg" align="center">
        {date}
      </Col>
      <Col justify="left" width="full">
        {details}
      </Col>
      <Col justify="right" width="lg" align="center">
        {amount}
      </Col>
      <Col justify="right" width="xl" align="center">
        {outstanding}
      </Col>
      <IndentOffset indentation={indentation} />
      <Col justify="center" width="xxs" align="center">
        {menu}
      </Col>
    </>
  );
};

const IndentOffset: FC<{ indentation: number }> = ({ indentation }) => {
  const nestingOffset = {
    [0]: 20 + 9 + 9,
    [1]: 20 + 9,
    [2]: 20,
  }[indentation];

  return <div style={{ marginLeft: `${nestingOffset ?? 0}px` }} />;
};

const LedgerContainer: FC<PropsWithChildren<ComponentPropsWithRef<"div">>> = forwardRef(
  ({ children }, ref) => {
    return (
      <PersistScrollPosition
        id="ledger-appointments-page"
        data-testid="ledger-appointments-page"
        ref={ref}
        className="flex flex-col-reverse overflow-y-auto p-2 gap-y-2"
      >
        {children}
      </PersistScrollPosition>
    );
  }
);

const AdjustmentLedgerItem: FC<
  PracticeAndPatientIds & { adjustmentEntry: LedgerV2AdjustmentEntryVO; isInvoiceable: boolean }
> = ({ patientId, practiceId, isInvoiceable, adjustmentEntry }) => {
  const expandRow = usePersistExpandRow(ledgerEntryToId(adjustmentEntry));

  return (
    <LedgerItem isExpanded={expandRow.isOn}>
      <ExpandableRow
        itemId={ledgerEntryToId(adjustmentEntry)}
        indentation={1}
        date={
          <Text bold>
            <DateWithTooltip date={getLocalDate(adjustmentEntry.timestamp)} format="P" />
          </Text>
        }
        details={
          <DescriptionWithPatientInsuranceRow
            descriptionColumn={
              <AdjustmentDescription adjustmentName={adjustmentEntry.customAdjustmentType.name} />
            }
            patientColumn={<AdjustmentPatientPill adjustmentEntry={adjustmentEntry} />}
            insuranceColumn={undefined}
          />
        }
        amount={<Text bold>{formatCurrency(adjustmentEntry.collectionAmount)}</Text>}
        outstanding={<Text bold>{formatCurrency(adjustmentEntry.runningBalance)}</Text>}
        menu={
          <AdjustmentMenu
            patientId={patientId}
            adjustmentEntry={adjustmentEntry}
            canCreateInvoice={isInvoiceable}
          />
        }
        expandedContent={
          <AdjustmentExpandedContent
            patientId={patientId}
            practiceId={practiceId}
            adjustmentEntry={adjustmentEntry}
          />
        }
        isExpanded={expandRow.isOn}
        onToggleExpand={expandRow.toggle}
      />
    </LedgerItem>
  );
};

const AppointmentLedgerItem: FC<
  PracticeAndPatientIds & { appointmentEntry: LedgerV2AppointmentEntryVO; isInvoiceable: boolean }
> = ({ practiceId, patientId, isInvoiceable, appointmentEntry }) => {
  const expandRow = usePersistExpandRow(ledgerEntryToId(appointmentEntry));

  return (
    <LedgerItem isExpanded={expandRow.isOn}>
      <ExpandableRow
        itemId={ledgerEntryToId(appointmentEntry)}
        indentation={1}
        date={
          <Text bold>
            <DateWithTooltip date={getLocalDate(appointmentEntry.timestamp)} format="P" />
          </Text>
        }
        details={
          <DescriptionWithPatientInsuranceRow
            descriptionColumn={
              <AppointmentDescription
                providerNames={appointmentEntry.providers.map((provider) => provider.fullDisplayName)}
              />
            }
            patientColumn={<PatientRunningBalancePill appointmentEntry={appointmentEntry} />}
            insuranceColumn={<InsuranceRunningBalancePill appointmentEntry={appointmentEntry} />}
          />
        }
        amount={<Text bold>{formatCurrency(appointmentEntry.collectionAmount)}</Text>}
        outstanding={<Text bold>{formatCurrency(appointmentEntry.runningBalance)}</Text>}
        menu={
          <AppointmentMenu
            patientId={patientId}
            appointmentId={appointmentEntry.appointmentId}
            canCreateInvoice={isInvoiceable}
          />
        }
        expandedContent={
          <AppointmentExpandedContent
            practiceId={practiceId}
            patientId={patientId}
            appointmentId={appointmentEntry.appointmentId}
          />
        }
        isExpanded={expandRow.isOn}
        onToggleExpand={expandRow.toggle}
      />
    </LedgerItem>
  );
};

const AdjustmentExpandedContent: FC<
  PracticeAndPatientIds & { adjustmentEntry: LedgerV2AdjustmentEntryVO }
> = ({ patientId, practiceId, adjustmentEntry }) => {
  return (
    <>
      {adjustmentEntry.employee?.fullDisplayName && (
        <DetailsRow indentation={1}>
          <DetailsContent>
            <Text>Assigned to {adjustmentEntry.employee.fullDisplayName}</Text>
          </DetailsContent>
        </DetailsRow>
      )}
      <DetailsRow indentation={1}>
        <DetailsContent>
          <Text>{adjustmentEntry.note}</Text>
        </DetailsContent>
      </DetailsRow>
      {adjustmentEntry.subEntries.length > 0 && (
        <AccordionContainer>
          {adjustmentEntry.subEntries.map((subEntry) =>
            isInvoiceSubEntry(subEntry) ? (
              <InvoiceExpandable
                key={ledgerSubEntryToId(subEntry)}
                practiceId={practiceId}
                patientId={patientId}
                invoiceSubEntry={subEntry}
                adjustmentEntry={adjustmentEntry}
              />
            ) : null
          )}
        </AccordionContainer>
      )}
    </>
  );
};

const AppointmentExpandedContent: FC<
  PracticeAndPatientIds & { appointmentId: LedgerV2AppointmentEntryVO["appointmentId"] }
> = ({ practiceId, patientId, appointmentId }) => {
  const [ledgerEntryQuery] = useApiQueries([
    getLedgerEntryByTypeAndId({
      args: {
        patientId,
        practiceId,
        entryId: String(appointmentId),
        entryType: "APPOINTMENT",
      },
    }),
  ]);

  return (
    <QueryResult queries={[ledgerEntryQuery]} loading={<LoadingExpandedContent />}>
      {ledgerEntryQuery.data && isLedgerAppointmentEntry(ledgerEntryQuery.data) && (
        <AppointmentProceduresTable appointmentEntry={ledgerEntryQuery.data} />
      )}
      {(ledgerEntryQuery.data?.subEntries ?? []).length > 0 && (
        <AccordionContainer>
          {ledgerEntryQuery.data?.subEntries.map((subEntry) =>
            isInvoiceSubEntry(subEntry) ? (
              <InvoiceExpandable
                key={ledgerSubEntryToId(subEntry)}
                practiceId={practiceId}
                patientId={patientId}
                invoiceSubEntry={subEntry}
              />
            ) : isClaimSubEntry(subEntry) ? (
              <ClaimExpandable
                key={ledgerSubEntryToId(subEntry)}
                practiceId={practiceId}
                patientId={patientId}
                claimSubEntry={subEntry}
              />
            ) : isClaimBalanceTransferSubEntry(subEntry) ? (
              <ClaimBalanceTransferExpandable
                key={ledgerSubEntryToId(subEntry)}
                practiceId={practiceId}
                patientId={patientId}
                claimBalanceTransferSubEntry={subEntry}
              />
            ) : isAppointmentEditSubEntry(subEntry) ? (
              <AppointmentEditExpandable
                key={ledgerSubEntryToId(subEntry)}
                practiceId={practiceId}
                patientId={patientId}
                appointmentEditSubEntry={subEntry}
              />
            ) : null
          )}
        </AccordionContainer>
      )}
    </QueryResult>
  );
};

const AppointmentProceduresTable: FC<{
  appointmentEntry: LedgerV2AppointmentEntryVO;
}> = ({ appointmentEntry }) => {
  // There will always be exactly one APPOINTMENT type subEntry for any given LedgerV2AppointmentEntryVO
  const appointmentSubEntry = appointmentEntry.subEntries.find(isAppointmentSubEntry);
  const primaryClaimSubEntry = appointmentEntry.subEntries.filter(isClaimSubEntry).find((claimSubEntry) => {
    return claimSubEntry.insuranceOrdinal === "PRIMARY";
  });

  return (
    <>
      <DetailsRow indentation={1}>
        <DetailsContent>
          <AppointmentDetails
            ucrRate={formatCurrency(appointmentEntry.ucrRate)}
            writeOffType={primaryClaimSubEntry?.claimState === "COMPLETED" ? "FINAL" : "ESTIMATE"}
            writeOffAmount={formatCurrency(appointmentEntry.writeOffAmount)}
            negotiatedRate={formatCurrency(appointmentEntry.negotiatedRate)}
          />
        </DetailsContent>
      </DetailsRow>
      {appointmentSubEntry && isAppointmentSubEntry(appointmentSubEntry) && (
        <DetailsRow indentation={1}>
          <DetailsTable>
            <TableRow
              descriptionColumn={<Text bold>Procedures</Text>}
              patientColumn={<Text bold>Patient Resp</Text>}
              insuranceColumn={<Text bold>Insurance Pay</Text>}
            />
            {appointmentSubEntry.procedureItems.map((procedure) => (
              <ProcedureTableRow
                key={procedure.patientProcedureId}
                providerInitials={<ProviderInitials provider={procedure.provider} />}
                cdtCode={<Text>{procedure.cdtCode}</Text>}
                toothName={<Text breakAnywhere>{procedure.procedureArea}</Text>}
                procedureName={<Text>{procedure.procedureName}</Text>}
                patientColumn={<Text>{formatCurrency(procedure.patientAmount)}</Text>}
                insuranceColumn={<Text>{formatCurrency(procedure.insuranceAmount)}</Text>}
              />
            ))}
            {appointmentSubEntry.adjustments.map((adjustment) => (
              <AppointmentAdjustmentTableRow
                key={adjustment.appointmentId}
                adjustmentName={adjustment.customAdjustmentType.name}
                adjustmentAmount={adjustment.amount}
              />
            ))}
            <TableRow
              descriptionColumn={undefined}
              patientColumn={
                <div className="flex gap-x-6">
                  <Text bold>Total</Text>
                  <Text bold>{formatCurrency(appointmentSubEntry.patientAmount)}</Text>
                </div>
              }
              insuranceColumn={<Text bold>{formatCurrency(appointmentSubEntry.insuranceAmount)}</Text>}
            />
          </DetailsTable>
        </DetailsRow>
      )}
    </>
  );
};

export const DetailsRow: FC<PropsWithChildren<{ indentation: number }>> = ({ indentation, children }) => {
  return (
    <Row>
      <Columns
        indentation={indentation}
        icon={undefined}
        date={undefined}
        details={children}
        amount={undefined}
        outstanding={undefined}
        menu={undefined}
      />
    </Row>
  );
};

const DetailsContent: FC<PropsWithChildren> = ({ children }) => {
  return <div className="px-4">{children}</div>;
};

const AppointmentDetails: FC<{
  ucrRate: string;
  writeOffType: "ESTIMATE" | "FINAL";
  writeOffAmount: string;
  negotiatedRate: string;
}> = ({ ucrRate, writeOffType, writeOffAmount, negotiatedRate }) => {
  return (
    <div className="flex gap-x-2">
      <Text>UCR {ucrRate}</Text>
      <VerticalDivider size="sm" />
      <Text>
        {writeOffType === "ESTIMATE" ? "Est Writeoff" : "Writeoff"} {writeOffAmount}
      </Text>
      <VerticalDivider size="sm" />
      <Text>Neg Rate {negotiatedRate}</Text>
    </div>
  );
};

const DescriptionWithPatientInsuranceCols: FC<{
  descriptionColumn: ReactNode;
  patientColumn: ReactNode;
  insuranceColumn: ReactNode;
}> = ({ descriptionColumn, patientColumn, insuranceColumn }) => {
  return (
    <>
      <Col justify="left" width="full">
        {descriptionColumn}
      </Col>
      <Col justify="right" width="2xl">
        {patientColumn}
      </Col>
      <Col justify="right" width="2xl">
        {insuranceColumn}
      </Col>
    </>
  );
};

export const DescriptionWithPatientInsuranceRow: FC<{
  descriptionColumn: ReactNode;
  patientColumn: ReactNode;
  insuranceColumn: ReactNode;
}> = (props) => {
  return (
    <Row>
      <DescriptionWithPatientInsuranceCols {...props} />
    </Row>
  );
};

export const TableRow: FC<{
  descriptionColumn: ReactNode;
  patientColumn: ReactNode;
  insuranceColumn: ReactNode;
}> = (props) => {
  return (
    <Row padding="tableRow">
      <DescriptionWithPatientInsuranceCols {...props} />
    </Row>
  );
};

export const DoubleColumnTableRow: FC<{
  description: ReactNode;
  patientCol1: ReactNode;
  patientCol2: ReactNode;
  insuranceCol1: ReactNode;
  insuranceCol2: ReactNode;
}> = ({ description, patientCol1, patientCol2, insuranceCol1, insuranceCol2 }) => {
  return (
    <TableRow
      descriptionColumn={description}
      patientColumn={
        <>
          <Col justify="right" width="full">
            {patientCol1}
          </Col>
          <Col justify="right" width="full">
            {patientCol2}
          </Col>
        </>
      }
      insuranceColumn={
        <>
          <Col justify="right" width="full">
            {insuranceCol1}
          </Col>
          <Col justify="right" width="full">
            {insuranceCol2}
          </Col>
        </>
      }
    />
  );
};

const ProcedureDescription: FC<{
  providerInitials: ReactNode;
  cdtCode: ReactNode;
  toothName: ReactNode;
  procedureName: ReactNode;
}> = ({ providerInitials, cdtCode, toothName, procedureName }) => {
  return (
    <>
      <Col justify="center" width="xxs">
        {providerInitials}
      </Col>
      <Col justify="left" width="md">
        {cdtCode}
      </Col>
      <Col justify="left" width="md">
        {toothName}
      </Col>
      <Col justify="left" width="full">
        {procedureName}
      </Col>
    </>
  );
};

export const ProcedureDoubleColumnTableRow: FC<{
  providerInitials: ReactNode;
  cdtCode: ReactNode;
  toothName: ReactNode;
  procedureName: ReactNode;
  patientCol1: ReactNode;
  patientCol2: ReactNode;
  insuranceCol1: ReactNode;
  insuranceCol2: ReactNode;
}> = ({
  providerInitials,
  cdtCode,
  toothName,
  procedureName,
  patientCol1,
  patientCol2,
  insuranceCol1,
  insuranceCol2,
}) => {
  return (
    <DoubleColumnTableRow
      description={
        <ProcedureDescription
          providerInitials={providerInitials}
          cdtCode={cdtCode}
          toothName={toothName}
          procedureName={procedureName}
        />
      }
      patientCol1={patientCol1}
      patientCol2={patientCol2}
      insuranceCol1={insuranceCol1}
      insuranceCol2={insuranceCol2}
    />
  );
};

export const EobPaymentTableHeader: FC = () => {
  return (
    <TableRow
      descriptionColumn={<Text bold>EOB Payment</Text>}
      patientColumn={undefined}
      insuranceColumn={<Text bold>Amount</Text>}
    />
  );
};

export const EobPaymentTableRow: FC<{
  paymentDate: ReactNode;
  paymentDescription: ReactNode;
  paymentAmount: ReactNode;
}> = ({ paymentDate, paymentDescription, paymentAmount }) => {
  return (
    <TableRow
      descriptionColumn={
        <>
          <Col justify="left" width="lg">
            {paymentDate}
          </Col>
          <Col justify="left" width="full">
            {paymentDescription}
          </Col>
        </>
      }
      patientColumn={undefined}
      insuranceColumn={paymentAmount}
    />
  );
};

export const ProcedureTableRow: FC<{
  providerInitials: ReactNode;
  cdtCode: ReactNode;
  toothName: ReactNode;
  procedureName: ReactNode;
  patientColumn: ReactNode;
  insuranceColumn: ReactNode;
}> = ({ providerInitials, cdtCode, toothName, procedureName, patientColumn, insuranceColumn }) => {
  return (
    <TableRow
      descriptionColumn={
        <ProcedureDescription
          providerInitials={providerInitials}
          cdtCode={cdtCode}
          toothName={toothName}
          procedureName={procedureName}
        />
      }
      patientColumn={patientColumn}
      insuranceColumn={insuranceColumn}
    />
  );
};

const AppointmentDescription: FC<{ providerNames: string[] }> = ({ providerNames }) => {
  return (
    <div className="flex items-center gap-x-2">
      <DescriptionSticker color="appointment" />
      <Text bold>Appointment: </Text>
      <Text>{providerNames.join(", ")}</Text>
    </div>
  );
};

const AdjustmentDescription: FC<{ adjustmentName: string }> = ({ adjustmentName }) => {
  return (
    <div className="flex items-center gap-x-2">
      <DescriptionSticker color="adjustment" />
      <Text bold>Adjustment{adjustmentName ? ": " : null}</Text>
      {adjustmentName && <Text>{adjustmentName}</Text>}
    </div>
  );
};

const LedgerItem: FC<PropsWithChildren<{ isExpanded: boolean }>> = ({ isExpanded, children }) => {
  return (
    <div
      className={cx(
        `flex
         flex-wrap
         ring-1
         ring-slate-300
         ring-inset
         rounded
         basis-full
         gap-y-2
         px-2
         py-3.5`,
        isExpanded && "bg-slate-50"
      )}
    >
      {children}
    </div>
  );
};

const LoadingExpandedContent: FC = () => (
  <Row>
    <Col width="full" justify="center">
      <Text>
        <i>Loading...</i>
      </Text>
    </Col>
  </Row>
);

const LoadingSkeleton: FC = () => {
  return (
    <LedgerItem isExpanded={false}>
      <Columns
        indentation={1}
        icon={<Skeleton containerClassName="w-full" />}
        date={<Skeleton containerClassName="w-full pr-2" />}
        details={<Skeleton containerClassName="w-full pr-2" />}
        amount={<Skeleton containerClassName="w-full pr-2" />}
        outstanding={<Skeleton containerClassName="w-full" />}
        menu={<Skeleton containerClassName="w-full" />}
      />
    </LedgerItem>
  );
};
