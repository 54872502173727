import { FC } from "react";

import { TwilioOnboardingStatusVO } from "@libs/api/generated-api";
import { ReactComponent as MessagingServicesImage } from "@libs/assets/components/messaging-services.svg";

import { SettingsHeroContainer, SettingsHeroContent } from "components/Settings/SettingsHeroLayout";
import { TwilioOnboardingStatus } from "components/Settings/Communications/MessagingService/TwilioOnboardingStatus";

interface Props {
  twilioOnboardingStatus: TwilioOnboardingStatusVO;
}

export const MessagingServices: FC<Props> = ({ twilioOnboardingStatus }) => {
  const { status, practiceCommsNumber } = twilioOnboardingStatus;

  return (
    <SettingsHeroContent className="flex flex-col gap-y-6">
      <SettingsHeroContainer image={<MessagingServicesImage />}>
        <TwilioOnboardingStatus status={status} phoneNumber={practiceCommsNumber} />
      </SettingsHeroContainer>
    </SettingsHeroContent>
  );
};
