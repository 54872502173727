import { FC, ChangeEvent, Ref } from "react";
import AvatarEditor, { Position } from "react-avatar-editor";
import { useObjectState } from "@libs/hooks/useObjectState";

const AVATAR_EDITOR_OPACITY = 0.6;

interface Props {
  canvasRef: Ref<AvatarEditor>;
  imageFile: File;
}

export const UploadPhotoEditor: FC<Props> = ({ canvasRef, imageFile }) => {
  const [state, setState] = useObjectState({
    image: "",
    allowZoomOut: false,
    position: { x: 0.5, y: 0.5 },
    scale: 1,
    rotate: 0,
    preview: null,
    width: 398,
    height: 398,
    borderRadius: 224, // borderRadius*2 - 50 = width OR height for circular image.
  });

  const handleScale = (e: ChangeEvent<HTMLInputElement>) => {
    const scale = Number.parseFloat(e.target.value);

    setState({ scale });
  };

  const handlePositionChange = (position: Position) => {
    setState({ position });
  };

  return (
    <div className="flex flex-col items-center gap-y-3 pb-6 text-xs">
      <AvatarEditor
        ref={canvasRef}
        scale={state.scale}
        width={state.width}
        height={state.height}
        position={state.position}
        onPositionChange={handlePositionChange}
        rotate={state.rotate}
        borderRadius={state.borderRadius}
        color={[0, 0, 0, AVATAR_EDITOR_OPACITY]}
        image={imageFile}
      />

      <div className="flex items-center">
        <span className="w-14">Zoom</span>
        <input
          type="range"
          className="w-56 accent-archyBlue-500"
          value={state.scale}
          onChange={handleScale}
          min={state.allowZoomOut ? "0.1" : "1"}
          max="2"
          step="0.01"
        />
      </div>

      <div className="flex items-center">
        <span className="w-14">Rotate</span>
        <input
          type="range"
          className="w-56 accent-archyBlue-500"
          value={state.rotate}
          onChange={(e) => setState({ rotate: Number.parseInt(e.target.value) })}
          min={-180}
          max={180}
          step={1}
        />
      </div>
    </div>
  );
};
