import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useCallback } from "react";
import { ClaimBatchSubmitRequest } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { BAD_REQUEST } from "@libs/utils/statusCodes";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { isHttpResponseError } from "@libs/utils/isHttpResponseError";
import { useAccount } from "@libs/contexts/AccountContext";
import {
  replaceClaim,
  skipClaimPayment,
  batchSubmitClaims,
  submitClaimManually,
  asyncValidateClaim,
  deleteClaim as deleteClaimRequest,
} from "api/claim/mutations";
import { handleError } from "utils/handleError";
import { paths } from "utils/routing/paths";

export const useSubmitClaim = () => {
  const { practiceId } = useAccount();
  const navigate = useNavigate();
  const location = useLocation();

  const [
    batchSubmitClaimsMutation,
    skipClaimPaymentMutation,
    submitManuallyMutation,
    asyncValidateClaimMutation,
    deleteClaimMutation,
  ] = useApiMutations([
    batchSubmitClaims,
    skipClaimPayment,
    submitClaimManually,
    asyncValidateClaim,
    deleteClaimRequest,
  ]);

  const handleValidateError = useCallback((err: unknown) => {
    if (isHttpResponseError(err) && err.status === BAD_REQUEST) {
      err.error.errors?.forEach((error) => toast.error(error.message));
    } else {
      handleError(err);
    }
  }, []);

  const validate = useCallback(
    (claimUuid: string) => {
      if (asyncValidateClaimMutation.isLoading) {
        return Promise.resolve(asyncValidateClaimMutation.data);
      }

      return asyncValidateClaimMutation.mutateAsync({
        practiceId,
        claimUuid,
      });
    },
    [asyncValidateClaimMutation, practiceId]
  );

  const skipPayment = useCallback(
    async (claimUuid: string, from: string) => {
      await skipClaimPaymentMutation.mutateAsync({ practiceId, claimUuid });
      navigate(from, { replace: true });
    },
    [navigate, practiceId, skipClaimPaymentMutation]
  );

  const submitManually = useCallback(
    async (claimUuid: string, from: string) => {
      await submitManuallyMutation.mutateAsync({ practiceId, claimUuid });
      navigate(from, { replace: true });
    },
    [navigate, practiceId, submitManuallyMutation]
  );

  const [replaceClaimMutation] = useApiMutations([replaceClaim]);

  const replace = useCallback(
    async (claimUuid: string, from?: string) => {
      const response = await replaceClaimMutation.mutateAsync({ practiceId, claimUuid });

      toast.success("Replacement draft claim created");

      const fullUrl = getFullUrl(location);

      navigate(paths.claim({ claimUuid: response.data.data.uuid }, { from: from ?? fullUrl }));
    },
    [navigate, location, practiceId, replaceClaimMutation]
  );

  const bulkSubmit = useCallback(
    (data: ClaimBatchSubmitRequest) => batchSubmitClaimsMutation.mutateAsync({ practiceId, data }),
    [batchSubmitClaimsMutation, practiceId]
  );

  const deleteClaim = useCallback(
    (claimUuid: string, navigateTo?: string) => {
      deleteClaimMutation.mutate(
        { practiceId, claimUuid },
        {
          onError: (err) => handleError(err),
          onSuccess: () => {
            toast.success("Claim deleted");

            if (navigateTo) {
              navigate(navigateTo, { replace: true });
            }
          },
        }
      );
    },
    [deleteClaimMutation, navigate, practiceId]
  );

  return {
    bulkSubmit,
    replace,
    skipPayment,
    submitManually,
    validate,
    handleValidateError,
    deleteClaim,
    isExecutingRequest:
      asyncValidateClaimMutation.isLoading ||
      deleteClaimMutation.isLoading ||
      replaceClaimMutation.isLoading ||
      skipClaimPaymentMutation.isLoading ||
      submitManuallyMutation.isLoading,
  };
};
