import { useCallback } from "react";
import { PaymentProfileVO } from "@libs/api/generated-api";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { markPaymentProfileForDeletion, updatePaymentProfile } from "api/billing/mutations";

export const usePaymentProfileMutations = () => {
  const { practiceId } = useAccount();

  const [markPaymentProfileForDeletionMutation, updatePaymentProfileMutation] = useApiMutations([
    markPaymentProfileForDeletion,
    updatePaymentProfile,
  ]);

  const deleteCard = useCallback(
    (paymentProfile: PaymentProfileVO) =>
      markPaymentProfileForDeletionMutation.mutateAsync({
        practiceId,
        paymentProfileId: paymentProfile.uuid,
        ownerId: paymentProfile.owner.id ?? 0,
      }),
    [markPaymentProfileForDeletionMutation, practiceId]
  );

  const markCardAsDefault = (paymentProfile: PaymentProfileVO) =>
    updatePaymentProfileMutation.mutateAsync({
      practiceId,
      patientId: paymentProfile.owner.id ?? 0,
      paymentProfileUuid: paymentProfile.uuid,
      data: { isDefault: true },
    });

  const markCardAsFamily = (paymentProfile: PaymentProfileVO) =>
    updatePaymentProfileMutation.mutateAsync({
      practiceId,
      patientId: paymentProfile.owner.id ?? 0,
      paymentProfileUuid: paymentProfile.uuid,
      data: { isFamily: !paymentProfile.isFamily },
    });

  return {
    deleteCard,
    markCardAsDefault,
    markCardAsFamily,
    isDeletingCard: markPaymentProfileForDeletionMutation.isLoading,
    isUpdatingCard: updatePaymentProfileMutation.isLoading,
  };
};
