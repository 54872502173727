import React from "react";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { ImagingDeviceWithSettings } from "components/PatientProfile/Imaging/hooks/useImagingDevices";
import { SensorCaptureStatus, shouldShowStatus } from "components/PatientProfile/Imaging/SensorCaptureStatus";
import { UseSensorCapture } from "components/PatientProfile/Imaging/MountRoute/hooks/useSensorCapture";

type Props = {
  device: ImagingDeviceWithSettings;
  from: string;
  onClickCapture: Func;
  isCapturing?: boolean;
  captureDisabled?: boolean;
  captureState?: UseSensorCapture["captureState"];
};
export const CalibrationCaptureHeader: React.FC<Props> = ({
  device,
  from,
  onClickCapture,
  captureState,
  isCapturing = false,
  captureDisabled = false,
}) => {
  const shouldShowCaptureButton = !(captureState && shouldShowStatus(captureState));

  return (
    <div
      className={`
        shadow-main
        flex
        justify-between
        items-center
        p-3
        min-h-16
        text-white
        bg-slate-800
      `}
    >
      <div className="font-sansSemiBold text-sm basis-1/4">
        Calibrate Sensor - {device.settings.overriddenLabel ?? device.label}
      </div>
      <div
        className={`
          flex
          items-center
          justify-center
          gap-4
          text-sm
          text-center
          flex-1
        `}
      >
        <div>Capture an image with a reference object to measure distance</div>
        {shouldShowCaptureButton ? (
          <AsyncButton
            isLoading={isCapturing}
            onClick={onClickCapture}
            size="large"
            disabled={captureDisabled}
          >
            Capture
          </AsyncButton>
        ) : (
          <SensorCaptureStatus captureState={captureState} isRetaking={false} />
        )}
      </div>
      <div className="basis-1/4 flex justify-end">
        <ButtonInternalLink className="min-w-button" theme="secondary" to={from}>
          Cancel
        </ButtonInternalLink>
      </div>
    </div>
  );
};
