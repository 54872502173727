import { Button } from "@libs/components/UI/Button";
import { ReactComponent as SomethingWentWrongImage } from "assets/components/techny-something-went-wrong.svg";
import { ExternalLink } from "components/UI/ExternalLink";

interface Props {
  onConnect: Func;
  onGoBack: Func;
}

export const PHONE_OOPS_TITLE = "Oops, something didn't work";

export const ContactMango = () => {
  return (
    <div className="flex items-center text-sm">
      Contact&nbsp;
      <ExternalLink name="mangoSupport">Mango Voice</ExternalLink>
      &nbsp; for assistance.
    </div>
  );
};

export const FailedAuthKey: React.FC<Props> = ({ onGoBack, onConnect }) => {
  return (
    <div className="flex flex-col items-center w-full py-8 text-sm">
      <SomethingWentWrongImage />
      <div className="mt-6 mb-4 font-sansSemiBold">{PHONE_OOPS_TITLE}</div>
      <ContactMango />
      <div className="flex items-center mt-6 gap-x-12">
        <Button onClick={onGoBack} theme="link">
          Go Back
        </Button>
        <Button onClick={onConnect} theme="secondary">
          Retry Connection
        </Button>
      </div>
    </div>
  );
};
