/* eslint-disable @typescript-eslint/naming-convention */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import useScript from "react-script-hook";
import { colors } from "@libs/design.config";
import { useBoolean } from "@libs/hooks/useBoolean";

export const submitFinixForm = (
  form: FinixFormV1,
  environment: FinixEnvironment,
  applicationId: string,
  formSubmissionCallback: FinixFormSubmissionCallback
) => {
  form.submit(environment, applicationId, formSubmissionCallback);
};

const FINIX_ELEMENT_ID = "form-element";

export const DEFAULT_FORM_OPTIONS: Partial<TokenFormOptions> = {
  styles: {
    default: {
      fontFamily: "'Open Sans', 'Helvetica Neue', sans-serif",
      color: colors.greyDark,
      border: `1px solid ${colors.greyLightest}`,
      borderRadius: "0.375rem",
      padding: "0 16px",
      fontSize: "0.85rem",
    },
  },
  showAddress: true,
  hideFields: [
    "address_city",
    "address_line1",
    "address_line2",
    "address_region",
    "address_country",
    "address_state",
  ],
  placeholders: {
    number: "e.g. 1234 5678 9876 5432",
    expiration_date: "MM/YY",
  },
  hideErrorMessages: true,
};

export const HIDE_NAME_OPTIONS: Partial<TokenFormOptions> = {
  ...DEFAULT_FORM_OPTIONS,
  hideFields: [...(DEFAULT_FORM_OPTIONS.hideFields ?? []), "name"],
};

export const useFinixForm = ({
  finixEnvironment,
  applicationId,
  formSubmissionHandler,
  options,
}: {
  finixEnvironment: FinixEnvironment;
  applicationId: string;
  formSubmissionHandler: FinixFormSubmissionCallback;
  options?: Partial<TokenFormOptions>;
}) => {
  const elementId = FINIX_ELEMENT_ID;
  const finixFormRef = useRef<FinixFormV1 | null>(null);
  const domElementLoaded = useBoolean(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const [formState, setFormState] = useState<FinixFormState>();
  const [formInvalid, setFormInvalid] = useState(true);
  const [binInformation, setBinInformation] = useState<FinixBinInformation>();
  const [initialized, setInitialized] = useState(false);
  const setDomElementLoaded = domElementLoaded.set;
  const handleFinixFormRef = useCallback(
    (ref: HTMLDivElement | null) => {
      setDomElementLoaded(Boolean(ref));

      if (!ref) {
        setInitialized(false);
      }
    },
    [setDomElementLoaded]
  );
  // We make a ref out of options so caller doesn't have to
  const optionsRef = useRef(options);
  const formChangedHandler: FinixFormChangedHandler = useCallback(
    (updatedFormState, updatedBinInformation, formHasErrors) => {
      setFormState(updatedFormState);

      if (updatedBinInformation) {
        setBinInformation(updatedBinInformation);
      }

      setFormInvalid(formHasErrors);
    },
    []
  );
  const [loading, error] = useScript({
    src: "https://js.finix.com/v/1/finix.js",
    checkForExisting: true,
  });

  useEffect(() => {
    if (loading || error || initialized || domElementLoaded.isOff) {
      return;
    }

    finixFormRef.current = window.Finix.CardTokenForm(elementId, {
      ...DEFAULT_FORM_OPTIONS,
      ...optionsRef.current,
      onUpdate: formChangedHandler,
      onLoad: () => {
        setInitialized(true);
      },
    });
  }, [error, loading, formChangedHandler, initialized, domElementLoaded.isOff, elementId]);

  const isFormValid = Boolean(formState && !formInvalid);

  const submitFormWrapper = useCallback(() => {
    setIsSubmitting(true);
    submitFinixForm(finixFormRef.current as FinixFormV1, finixEnvironment, applicationId, (err, res) => {
      setIsSubmitting(false);
      formSubmissionHandler(err, res);
    });
  }, [applicationId, finixEnvironment, formSubmissionHandler]);

  return useMemo(
    () => ({
      submitForm: finixFormRef.current && initialized ? submitFormWrapper : undefined,
      formState,
      binInformation,
      isFormValid,
      loadingFinix: loading || !initialized || domElementLoaded.isOff,
      handleFinixFormRef,
      isSubmitting,
      elementId,
      failedLoadingFinixScript: error,
    }),
    [
      binInformation,
      handleFinixFormRef,
      domElementLoaded.isOff,
      elementId,
      error,
      formState,
      initialized,
      isFormValid,
      isSubmitting,
      loading,
      submitFormWrapper,
    ]
  );
};
export type UseFinixForm = ReturnType<typeof useFinixForm>;
