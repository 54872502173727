import { useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { FrequencyLimitationCategoryVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { Button } from "@libs/components/UI/Button";
import { useAccount } from "@libs/contexts/AccountContext";
import { TableGrid, Row, HeaderCell } from "@libs/components/UI/GridTableComponents";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { getFrequencyLimitationCategories } from "api/practiceInsurance/queries";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { useItemModal } from "hooks/useItemModal";
import { LimitationCategoryRow } from "components/Settings/InsurancePreferences/Row";
import { LimitationFlyover } from "components/Settings/InsurancePreferences/LimitationFlyover";
import { getDentalProceduresQuery } from "api/charting/queries";
import {
  deleteFrequencyLimitationCategory,
  hideUnhideDefaultFrequencyLimitationCategory,
} from "api/practiceInsurance/mutations";
import { handleError } from "utils/handleError";

export type EditableFrequencyLimitationCategory = Omit<
  FrequencyLimitationCategoryVO,
  "group" | "id" | "isDefault"
> & {
  id?: number;
  isDefault?: boolean;
};

const headers = [
  { id: "service", label: "Service", width: "2fr" },
  { id: "procedures", label: "Procedures", width: "4fr" },
  { id: "defaultFrequency", label: "Default Frequency", width: "2fr" },
  { id: "visibility", label: "Visibility", width: "1fr" },
  { id: "actions", label: "", width: "min-content" },
];

const NEW_LIMITATION: EditableFrequencyLimitationCategory = {
  dentalProcedures: [],
  isHidden: false,
  name: "",
};

export const InsurancePreferencesRoute: React.FC = () => {
  const { practiceId } = useAccount();
  const [proceduresQuery, frequencyLimitationCategoriesQuery] = useApiQueries([
    getDentalProceduresQuery({ args: { practiceId } }),
    getFrequencyLimitationCategories({ args: { practiceId } }),
  ]);

  // procedures cannot belong to more than one limitation category
  const unavailableProcecureIds = new Set(
    frequencyLimitationCategoriesQuery.data?.flatMap((limitation) =>
      limitation.dentalProcedures.map((procedure) => procedure.id)
    ) || []
  );

  const flyover = useItemModal<EditableFrequencyLimitationCategory>(null);

  const handleEdit = useCallback(
    (limitationId: number) => {
      const limitationCategory = frequencyLimitationCategoriesQuery.data?.find(
        (item) => item.id === limitationId
      );

      if (limitationCategory) {
        flyover.open(limitationCategory);
      }
    },
    [frequencyLimitationCategoriesQuery.data, flyover]
  );

  const [deleteFrequencyLimitationCategoryMutation, hideUnhideDefaultFrequencyLimitationCategoryMutation] =
    useApiMutations([deleteFrequencyLimitationCategory, hideUnhideDefaultFrequencyLimitationCategory]);

  const handleToggleVisiblity = useCallback(
    (limitationId: number, isHidden: boolean) => {
      hideUnhideDefaultFrequencyLimitationCategoryMutation.mutate({
        practiceId,
        frequencyLimitationCategoryId: limitationId,
        data: { hide: !isHidden },
      });
    },
    [hideUnhideDefaultFrequencyLimitationCategoryMutation, practiceId]
  );

  const deleteConfirmationModal = useItemModal<{ limitationId: number }>(null);

  const handleDelete = useCallback(() => {
    if (deleteConfirmationModal.item) {
      deleteFrequencyLimitationCategoryMutation.mutate(
        {
          practiceId,
          frequencyLimitationCategoryId: deleteConfirmationModal.item.limitationId,
        },
        {
          onSuccess: deleteConfirmationModal.close,
          onError: handleError,
        }
      );
    }
  }, [deleteConfirmationModal, deleteFrequencyLimitationCategoryMutation, practiceId]);

  return (
    <SettingsPanel title="Default Limitations">
      <div className="flex flex-col max-w-[992px] gap-y-6">
        <div className="flex flex-col gap-y-1 text-xs">
          <div className="flex font-sansSemiBold text-sm">Limitations Default List</div>
          Set the limitations you want insurance plans to display by default. Procedures assigned to a service
          share the same limitation frequency.
        </div>
        <QueryResult
          loading={<Skeleton className="h-[768px] w-full" />}
          queries={[frequencyLimitationCategoriesQuery]}
        >
          {frequencyLimitationCategoriesQuery.data && (
            <TableGrid
              className="rounded border border-greyLighter"
              columnWidths={headers.map(({ width }) => width)}
            >
              <Row>
                {headers.map((item) => (
                  <HeaderCell key={item.id} rounded={true} size="short" sticky={false}>
                    {item.label}
                  </HeaderCell>
                ))}
              </Row>
              {frequencyLimitationCategoriesQuery.data.map((limitationCategory) => (
                <LimitationCategoryRow
                  key={limitationCategory.id}
                  limitationCategory={limitationCategory}
                  onClickDelete={() => deleteConfirmationModal.open({ limitationId: limitationCategory.id })}
                  onClickEdit={() => handleEdit(limitationCategory.id)}
                  onClickToggleVisibility={handleToggleVisiblity}
                />
              ))}
              <Row>
                <div className="col-span-full flex items-center h-10 px-3 text-xs border-b-0">
                  <Button
                    className="flex items-center p-2 gap-x-2 rounded hover:bg-whiteLight"
                    onClick={() => flyover.open(NEW_LIMITATION)}
                    theme="link"
                  >
                    <AddIcon className="w-4 h-4" />
                    <span className="font-sans">Service</span>
                  </Button>
                </div>
              </Row>
            </TableGrid>
          )}
        </QueryResult>
        {flyover.isOpen && proceduresQuery.data && frequencyLimitationCategoriesQuery.data && (
          <LimitationFlyover
            dentalProcedures={proceduresQuery.data}
            limitationCategory={flyover.item}
            onClose={flyover.close}
            unavailableProcecureIds={unavailableProcecureIds}
          />
        )}
        {deleteConfirmationModal.isOpen && (
          <ConfirmationModal
            confirmText="Delete"
            isConfirming={deleteFrequencyLimitationCategoryMutation.isLoading}
            onCancel={deleteConfirmationModal.close}
            onConfirm={handleDelete}
            primaryText="Delete Default Limitation"
            secondaryText="This limitation will no longer appear by default when adding new insurance plans. Existing plans will not be affected."
            size="2xs"
          />
        )}
      </div>
    </SettingsPanel>
  );
};
