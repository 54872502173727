import { FC, useMemo, useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { millisecondsToSeconds } from "date-fns";

import { FormTaskVO, FormVO, PatientSummaryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { getQueryKey } from "@libs/utils/queries";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Spinner } from "@libs/components/UI/Spinner";

import { ReactComponent as SendIcon } from "@libs/assets/icons/send.svg";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as AttachIcon } from "@libs/assets/icons/attach.svg";
import { ReactComponent as KioskIcon } from "@libs/assets/icons/tablet.svg";
import { ReactComponent as PrintIcon } from "@libs/assets/icons/send-to-printer.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { MenuOptionButton, MenuOptionExternalLink } from "@libs/components/UI/MenuOptionButton";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { useAccount } from "@libs/contexts/AccountContext";
import { DeleteFormsModal } from "components/PatientProfile/Forms/DeleteFormsModal";
import { SendFormsModal } from "components/PatientProfile/Forms/SendFormsModal";
import { AttachFormFilesModal } from "components/PatientProfile/Forms/AttachFormFilesModal";

import {
  sendFormTasks,
  resetFormTask,
  archiveFormTask,
  generateFormTaskKioskCode,
} from "api/formTasks/mutations";
import { useItemModal } from "hooks/useItemModal";

import { handleError } from "utils/handleError";
import { KioskModal } from "components/PatientProfile/Forms/KioskModal";
import { usePrintForm } from "components/PatientProfile/Forms/hooks/usePrintForm";

const cxStyles = {
  menuOptionButton: "gap-x-2",
  menuOptionIcon: "w-5 h-5 text-greyMedium",
};

export const isExpiredFormTask = (formTask: FormTaskVO) => {
  return formTask.expiresAt && formTask.submittedAt
    ? formTask.expiresAt < millisecondsToSeconds(Date.now())
    : false;
};

export const useFormTaskActions = (patient: PatientSummaryVO, formTasks: FormTaskVO[]) => {
  const patientId = patient.id;
  const patientDob = patient.dob;

  const { practiceId } = useAccount();
  const queryClient = useQueryClient();

  const attachFilesModal = useBoolean(false);
  const kioskModal = useItemModal<string | null>(null);
  const sendFormsModal = useBoolean(false);
  const deleteFormsModal = useBoolean(false);
  const [sendFormTasksMutation, resetFormTaskMutation, archiveFormTaskMutation, generateKioskCodeMutation] =
    useApiMutations([sendFormTasks, resetFormTask, archiveFormTask, generateFormTaskKioskCode]);

  const {
    formTaskUuids,
    expiredFormTaskUuids,
    completedFormTaskUuids,
    inPersonFormTitles,
    hasEverySelectedCompleted,
    hasSomeSelectedCompleted,
  } = useMemo(() => {
    const selectedFormTasks: {
      formTaskUuids: FormTaskVO["uuid"][];
      expiredFormTaskUuids: FormTaskVO["uuid"][];
      completedFormTaskUuids: FormTaskVO["uuid"][];
      inPersonFormTitles: FormVO["title"][];
      hasEverySelectedCompleted: boolean;
      hasSomeSelectedCompleted: boolean;
    } = {
      formTaskUuids: [],
      expiredFormTaskUuids: [],
      completedFormTaskUuids: [],
      inPersonFormTitles: [],
      hasEverySelectedCompleted: false,
      hasSomeSelectedCompleted: false,
    };

    const inPersonFormTitlesSet = new Set<FormVO["title"]>();

    for (const formTask of formTasks) {
      selectedFormTasks.formTaskUuids.push(formTask.uuid);

      if (isExpiredFormTask(formTask)) {
        selectedFormTasks.expiredFormTaskUuids.push(formTask.uuid);
      } else if (formTask.state === "COMPLETED") {
        selectedFormTasks.completedFormTaskUuids.push(formTask.uuid);
      } else if (formTask.form.inPersonRequired) {
        inPersonFormTitlesSet.add(formTask.form.title);
      }
    }

    const completedFormTasksCount = selectedFormTasks.completedFormTaskUuids.length;

    // When every form task selected is completed, we disable the send forms
    // action as completed forms cannot be sent
    selectedFormTasks.hasEverySelectedCompleted = completedFormTasksCount === formTasks.length;
    // When some form task selected are completed, we warn the user that only
    // incomplete forms will be sent
    selectedFormTasks.hasSomeSelectedCompleted = completedFormTasksCount > 0;
    // When some form task selected are in-person, we warn the user which forms
    // are required to be signed in-person
    selectedFormTasks.inPersonFormTitles = [...inPersonFormTitlesSet];

    return selectedFormTasks;
  }, [formTasks]);

  const { getFormPrintLink } = usePrintForm();
  const printLink = useMemo(
    () => getFormPrintLink({ patientDob, formTaskUuids }),
    [getFormPrintLink, patientDob, formTaskUuids]
  );

  const invalidatePatientFormTasks = useCallback(() => {
    queryClient.invalidateQueries([getQueryKey("practices", "listFormTasks"), { practiceId, patientId }]);
  }, [queryClient, practiceId, patientId]);
  const resetExpiredTasks = useCallback(async () => {
    // Expired form tasks are reset to PENDING state before being sent
    if (expiredFormTaskUuids.length > 0) {
      await Promise.all(
        expiredFormTaskUuids.map((uuid) =>
          resetFormTaskMutation.mutateAsync({ practiceId, formTaskUuid: uuid })
        )
      );
      invalidatePatientFormTasks();
    }
  }, [resetFormTaskMutation, practiceId, expiredFormTaskUuids, invalidatePatientFormTasks]);
  const handleSendFormTasks = useCallback(
    async (message: string, { phoneOverride, onSuccess }: { phoneOverride?: string; onSuccess: Func }) => {
      try {
        await resetExpiredTasks();

        // Completed form tasks cannot be sent. If some form tasks selected are
        // completed, we do not want to prevent the user from sending the other
        // form tasks, so we filter them out from being sent.
        const formTaskUuidsToSend =
          completedFormTaskUuids.length > 0
            ? formTaskUuids.filter((uuid) => !completedFormTaskUuids.includes(uuid))
            : formTaskUuids;

        await sendFormTasksMutation.mutateAsync({
          practiceId,
          data: { formTaskUuids: formTaskUuidsToSend, message, phoneOverride },
        });
        onSuccess();
      } catch (error) {
        handleError(error);
      }
    },
    [completedFormTaskUuids, formTaskUuids, practiceId, resetExpiredTasks, sendFormTasksMutation]
  );

  const handleTasksToKiosk = useCallback(async () => {
    try {
      await resetExpiredTasks();

      const {
        data: { data: kioskCode },
      } = await generateKioskCodeMutation.mutateAsync({
        practiceId,
        formTaskUuids: formTasks.map(({ uuid }) => uuid),
      });

      if (kioskCode) {
        kioskModal.open(kioskCode);
      }
    } catch (e) {
      handleError(e);
    }
  }, [formTasks, generateKioskCodeMutation, kioskModal, practiceId, resetExpiredTasks]);

  const handleDeleteFormTasks = useCallback(async () => {
    try {
      await Promise.all(
        formTaskUuids.map((formTaskUuid) => archiveFormTaskMutation.mutateAsync({ practiceId, formTaskUuid }))
      );
      invalidatePatientFormTasks();
      deleteFormsModal.off();
    } catch (error) {
      handleError(error);
    }
  }, [archiveFormTaskMutation, practiceId, formTaskUuids, invalidatePatientFormTasks, deleteFormsModal]);

  return {
    selectedCount: formTaskUuids.length,
    attachFilesModal,
    kioskModal,
    handleTasksToKiosk,
    sendFormsModal,
    deleteFormsModal,
    inPersonFormTitles,
    hasEverySelectedCompleted,
    hasSomeSelectedCompleted,
    printLink,
    invalidatePatientFormTasks,
    handleSendFormTasks,
    handleDeleteFormTasks,
    isSending: sendFormTasksMutation.isLoading,
    isDeleting: archiveFormTaskMutation.isLoading,
    isSendingToKiosk: generateKioskCodeMutation.isLoading,
  };
};

interface Props {
  patient: PatientSummaryVO;
  formTasks: FormTaskVO[];
  disabled?: boolean;
  theme?: "circle";
}

export const FormTaskButtonMenu: FC<Props> = ({ patient, formTasks, disabled = false, theme }) => {
  const {
    selectedCount,
    attachFilesModal,
    kioskModal,
    sendFormsModal,
    deleteFormsModal,
    inPersonFormTitles,
    hasEverySelectedCompleted,
    hasSomeSelectedCompleted,
    printLink,
    invalidatePatientFormTasks,
    handleSendFormTasks,
    handleDeleteFormTasks,
    handleTasksToKiosk,
    isSending,
    isDeleting,
    isSendingToKiosk,
  } = useFormTaskActions(patient, formTasks);

  const menu = useBoolean(false);

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      onRequestClose={menu.off}
      placement="right"
      menuContent={
        <div className="w-32">
          <MenuOptionButton
            className={cxStyles.menuOptionButton}
            onClick={() => {
              attachFilesModal.on();
              menu.off();
            }}
          >
            <AttachIcon className={cxStyles.menuOptionIcon} />
            Attach
          </MenuOptionButton>
          <MenuOptionButton
            disabled={hasSomeSelectedCompleted}
            onClick={async () => {
              await handleTasksToKiosk();
              menu.off();
            }}
          >
            <FloatingTooltip
              content={hasSomeSelectedCompleted ? "Completed forms cannot be sent" : undefined}
              theme="MEDIUM"
            >
              <div className={cx("flex items-center", cxStyles.menuOptionButton)}>
                {isSendingToKiosk ? (
                  <Spinner
                    animation="border"
                    size="sm"
                    variant="primary"
                    className={cxStyles.menuOptionIcon}
                  />
                ) : (
                  <KioskIcon className={cxStyles.menuOptionIcon} />
                )}
                Kiosk
              </div>
            </FloatingTooltip>
          </MenuOptionButton>
          <MenuOptionExternalLink
            className={cxStyles.menuOptionButton}
            onClick={menu.off}
            href={printLink}
            target="_blank"
          >
            <PrintIcon className={cxStyles.menuOptionIcon} />
            Print
          </MenuOptionExternalLink>

          <MenuOptionButton
            onClick={() => {
              sendFormsModal.on();
              menu.off();
            }}
            disabled={hasEverySelectedCompleted}
          >
            <FloatingTooltip
              content={hasEverySelectedCompleted ? "Completed forms cannot be sent" : undefined}
              theme="MEDIUM"
            >
              <div className={cx("flex items-center", cxStyles.menuOptionButton)}>
                <SendIcon className={cxStyles.menuOptionIcon} />
                Send
              </div>
            </FloatingTooltip>
          </MenuOptionButton>

          <MenuOptionButton
            className={cx("border-t border-greyLighter", cxStyles.menuOptionButton)}
            onClick={() => {
              deleteFormsModal.on();
              menu.off();
            }}
          >
            <DeleteIcon className={cxStyles.menuOptionIcon} />
            Delete
          </MenuOptionButton>
        </div>
      }
    >
      {(props) => (
        <>
          <ButtonIcon
            aria-label="Form Task Menu"
            className={cx(theme === "circle" && "rounded-full p-0.5 bg-slate-200")}
            SvgIcon={MenuIcon}
            tooltip={{ content: disabled ? "Select forms to access actions" : undefined, theme: "MEDIUM" }}
            theme="slate700"
            disabled={disabled}
            {...props}
          />

          {attachFilesModal.isOn ? (
            <AttachFormFilesModal
              userId={patient.id}
              formTasks={formTasks}
              onUploadSuccess={invalidatePatientFormTasks}
              onRequestClose={attachFilesModal.off}
            />
          ) : null}
          {kioskModal.item ? (
            <KioskModal onRequestClose={kioskModal.close} kioskCode={kioskModal.item} />
          ) : null}
          {sendFormsModal.isOn ? (
            <SendFormsModal
              patientId={patient.id}
              inPersonFormTitles={inPersonFormTitles}
              hasSomeSelectedCompleted={hasSomeSelectedCompleted}
              isSending={isSending}
              onSendForms={handleSendFormTasks}
              onRequestClose={sendFormsModal.off}
            />
          ) : null}

          {deleteFormsModal.isOn ? (
            <DeleteFormsModal
              selectedCount={selectedCount}
              isDeleting={isDeleting}
              onDelete={handleDeleteFormTasks}
              onCancel={deleteFormsModal.off}
            />
          ) : null}
        </>
      )}
    </ButtonMenu>
  );
};
