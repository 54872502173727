import { FC } from "react";

import { PatientSummaryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import {
  TableGrid,
  HeaderButtonCell,
  ColumnSortIndicator,
  HeaderCell,
  TextCell,
  SortDirection,
} from "@libs/components/UI/GridTableComponents";

import { CampaignGroupRow } from "components/Communications/Campaigns/Campaign/CampaignGroupRow";

const tableHeaders = [
  { id: "name", label: "Name", width: "2fr" },
  { id: "email", label: "Email", width: "2fr" },
  { id: "age", label: "Age", width: "1fr" },
  { id: "gender", label: "Gender", width: "1fr" },
  { id: "status", label: "Status", width: "1fr" },
  { id: "insurance-carrier", label: "Insurance Carrier", width: "2fr" },
  { id: "insurance-status", label: "Insurance Status", width: "1fr" },
  { id: "next-appt", label: "Next Appt", width: "1fr" },
];

interface Props {
  patientsQuery: UseInfiniteApiListQueryResult<PatientSummaryVO>;
  sortDirection: SortDirection;
  onSortChange: Func;
}

export const CampaignGroupTable: FC<Props> = ({ patientsQuery, sortDirection, onSortChange }) => {
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: patientsQuery });
  const patients = useFlattenPages(patientsQuery.data);

  return (
    <PersistScrollPosition ref={rootRef} id="campaign-group-table" className="h-full overflow-y-auto">
      <ScrollableInfiniteQueryResult
        scrollRef={scrollRef}
        infiniteQuery={patientsQuery}
        loadMore={
          <TableGrid columnWidths={tableHeaders.map(({ width }) => width)}>
            {tableHeaders.map(({ id }) => (
              <TextCell key={id} border={false}>
                <div className="h-4">
                  <LoadingContent />
                </div>
              </TextCell>
            ))}
          </TableGrid>
        }
      >
        <TableGrid columnWidths={tableHeaders.map(({ width }) => width)}>
          {tableHeaders.map(({ id, label }, index) =>
            index === 0 ? (
              <HeaderButtonCell key={id} onClick={onSortChange} size="short">
                <div className="flex items-center gap-x-2 pl-1">
                  {label}
                  <ColumnSortIndicator direction={sortDirection} />
                </div>
              </HeaderButtonCell>
            ) : (
              <HeaderCell key={id} className={cx(id === "next-appt" && "justify-end")} size="short">
                <span className={cx(index === tableHeaders.length - 1 && "pr-1")}>{label}</span>
              </HeaderCell>
            )
          )}

          {patients?.map((patient) => <CampaignGroupRow key={patient.id} patient={patient} />)}
        </TableGrid>
      </ScrollableInfiniteQueryResult>
    </PersistScrollPosition>
  );
};
