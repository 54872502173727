import { FC, FormEvent, useMemo, useState } from "react";
import { useValidation } from "@libs/hooks/useValidation";
import { nowInTimezone, formatAsISOTime, formatAsISODate, addMinutesToISOTime } from "@libs/utils/date";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { addWorktimeEntry } from "api/employee/mutations";
import { EditWorktimeModal } from "components/EmployeeProfile/Timesheet/EditWorktimeModal";
import { handleError } from "utils/handleError";
import { WorktimeComment } from "components/EmployeeProfile/Timesheet/WorktimeComment";

import { TimeInput } from "components/UI/TimeInput";
import {
  commentValidation,
  getClockOutValidation,
  isoTimeValidation,
} from "components/EmployeeProfile/Timesheet/utils";

export interface AddClockOutModalProps {
  employeeId: number;
  date: string;
  clockInTime: string;
  onRequestClose: Func;
}

export const AddClockOutModal: FC<AddClockOutModalProps> = ({
  date,
  clockInTime,
  employeeId,
  onRequestClose,
}) => {
  const practice = useCurrentPractice();
  const [comment, setComment] = useState("");
  const [time, setTime] = useState(() => {
    const now = nowInTimezone(practice.timezoneId);

    if (formatAsISODate(now) === date) {
      return formatAsISOTime(now);
    }

    return addMinutesToISOTime(clockInTime, 1);
  });
  const [addWorktimeEntryMutation] = useApiMutations([addWorktimeEntry]);

  const schema = useMemo(() => {
    return {
      comment: [commentValidation],
      time: [isoTimeValidation, getClockOutValidation(clockInTime)],
    };
  }, [clockInTime]);

  const { result, validate } = useValidation({ comment, time }, schema);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validate().$isValid) {
      addWorktimeEntryMutation.mutate(
        {
          practiceId: practice.id,
          employeeId,
          data: {
            date,
            action: "CLOCK_OUT",
            time,
            notes: comment,
          },
        },
        {
          onSuccess: onRequestClose,
          onError: handleError,
        }
      );
    }
  };

  return (
    <EditWorktimeModal
      formId="add-clock-out-form"
      onSubmit={handleSubmit}
      isLoading={addWorktimeEntryMutation.isLoading}
      onRequestClose={onRequestClose}
      date={date}
    >
      <TimeInput
        required={true}
        label="Add Clock-Out"
        value={time}
        onChange={setTime}
        error={result.time.$error}
      />
      <WorktimeComment
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        error={result.comment.$error}
      />
    </EditWorktimeModal>
  );
};
