/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as PrintIcon } from "@libs/assets/icons/send-to-printer.svg";
import { ExpandTableButton } from "components/Dashboard/Tables/ExpandTableButton";
import { DashboardQuery } from "utils/routing/dashboard";
import { ExportCsvButton } from "components/Dashboard/ExportCsvButton";
import { RoleGuardClick } from "components/Main/RoleGuard";

interface Props {
  children?: React.ReactNode;
  onClickDownload?: Func;
  isDownloading?: boolean;
  includePatientSearch?: boolean;
  query?: Partial<DashboardQuery>;
  downloadCsvDisabled?: boolean;
  includeExpandTable?: boolean;
  onUpdateParams: (updates: Partial<DashboardQuery>) => void;
  onClickPrint?: Func;
  isPrinting?: boolean;
}
export const DashboardTableRightControls = ({
  query,
  children,
  onUpdateParams,
  includePatientSearch = true,
  onClickDownload,
  onClickPrint,
  downloadCsvDisabled = false,
  isDownloading = false,
  isPrinting = false,
  includeExpandTable = true,
}: Props) => {
  return (
    <div className="flex items-center gap-3">
      {children}
      {includePatientSearch && query && (
        <FormFieldInput
          layout="labelIn"
          inputClassName="pr-8"
          Icon={SearchIcon}
          placeholder="Search Patient"
          onChange={(e) => {
            onUpdateParams({ patientSearch: e.target.value });
          }}
          value={query["patientSearch"]}
        />
      )}
      {onClickPrint && (
        <RoleGuardClick domain="REPORTING" action="PRINT_DOWNLOAD">
          <ButtonIcon
            tooltip={{ content: "Print", theme: "SMALL" }}
            theme="primary"
            SvgIcon={PrintIcon}
            loading={isPrinting}
            onClick={onClickPrint}
          />
        </RoleGuardClick>
      )}
      {onClickDownload && (
        <ExportCsvButton
          disabled={downloadCsvDisabled}
          onClick={onClickDownload}
          isDownloading={isDownloading}
        />
      )}
      {includeExpandTable && (
        <ExpandTableButton
          onClick={() => onUpdateParams({ ["table.fullScreen"]: !query?.["table.fullScreen"] })}
          toggled={Boolean(query?.["table.fullScreen"])}
        />
      )}
    </div>
  );
};
