import {
  DentalProcedureVO,
  CreateCustomProcedureRequest,
  UpdateCustomProcedureRequest,
} from "@libs/api/generated-api";
import { required, alphaNumeric, hasValue, maxLength } from "@libs/utils/validators";
import { isString } from "@libs/utils/types";

import { getExpiredCDTCodeDate, getExpiredCDTCodeMessage } from "components/ScheduleAppointments/utils";

export const CODE_MAX_LENGTH = 15;
export const NAME_MAX_LENGTH = 255;
export const SIMPLE_NAME_MAX_LENGTH = 22;
export const LAYMAN_TERM_MAX_LENGTH = 22;

const MAX_DURATION = 30; // 150 minutes worth

export type CustomProcedureRequestState = CreateCustomProcedureRequest & UpdateCustomProcedureRequest;

export const getCustomProcedureRequestState = (
  procedure: DentalProcedureVO | undefined
): CustomProcedureRequestState => {
  if (procedure) {
    return {
      cdtCode: procedure.cdtCode,
      name: procedure.name,
      simpleName: procedure.simpleName,
      laymanTerm: procedure.laymanTerm,
      duration: procedure.duration,
      endDate: procedure.endDate,
      isHygiene: procedure.isHygiene,
      creditToPractice: procedure.creditToPractice,
    };
  }

  return {
    cdtCode: "",
    name: "",
    simpleName: "",
    laymanTerm: undefined,
    duration: "",
    isHygiene: undefined,
    creditToPractice: undefined,
  };
};

export const getExpiredCodeMessage = (endDate: string | undefined, now: Date) => {
  const expiredDate = getExpiredCDTCodeDate(endDate, now);

  return expiredDate ? getExpiredCDTCodeMessage(expiredDate) : undefined;
};

export const procedureSchema = {
  simpleName: [
    {
      $v: required,
      $error: "Simple name is required",
    },
  ],
  duration: [
    {
      $v: maxLength(MAX_DURATION),
      $error: "Max duration is 150 minutes",
    },
  ],
};

export const customProcedureSchema = {
  cdtCode: [
    {
      $v: required,
      $error: "Custom code is required",
    },
    {
      $v: alphaNumeric,
      $error: "Custom code must be alphanumeric",
    },
    {
      $v: (value: unknown) => hasValue(value) && isString(value) && !value.startsWith("D"),
      $error: "Custom code cannot start with 'D'",
    },
  ],
  name: [
    {
      $v: required,
      $error: "Description is required",
    },
  ],
  ...procedureSchema,
};
