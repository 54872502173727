import { MutableRefObject, Fragment, useMemo, useCallback, useState } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import {
  useBasicTypeaheadTriggerMatch,
  LexicalTypeaheadMenuPlugin,
  MenuOption,
} from "@lexical/react/LexicalTypeaheadMenuPlugin";
import { TextNode } from "lexical";

import { TemplateVariableGroupVO, TemplateVariableVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { titleCaseConstant } from "@libs/utils/casing";
import { noop } from "@libs/utils/noop";
import { useBoolean } from "@libs/hooks/useBoolean";

import { Menu, InteractiveElement } from "@libs/components/UI/Menu";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { $createTemplateVariableNode } from "components/UI/RichTextEditor/TemplateVariableNode";

class TemplateVariableOption extends MenuOption {
  key: TemplateVariableVO["key"];

  constructor(key: TemplateVariableVO["key"]) {
    super(key);
    this.key = key;
  }
}

export const TemplateVariablePlugin = ({
  templateVariables,
}: {
  templateVariables: TemplateVariableGroupVO[] | undefined;
}): JSX.Element | null => {
  const [editor] = useLexicalComposerContext();
  const [search, setSearch] = useState<string | undefined>();
  const menu = useBoolean(false);
  const slashTriggerMatch = useBasicTypeaheadTriggerMatch("/", { minLength: 0 });
  const atTriggerMatch = useBasicTypeaheadTriggerMatch("@", { minLength: 0 });
  const filteredVariables = useMemo(() => {
    if (search && templateVariables) {
      const newVariables: TemplateVariableGroupVO[] = [];

      for (const group of templateVariables) {
        const variables = group.variables.filter((tv) => {
          const title = tv.key.toLowerCase().replaceAll("_", "");

          return title.includes(search);
        });

        if (variables.length) {
          newVariables.push({ type: group.type, variables });
        }
      }

      return newVariables;
    }

    return templateVariables ?? [];
  }, [search, templateVariables]);
  const { options, optionsMetadata } = useMemo(() => {
    const templateVariableOptions = filteredVariables.flatMap(({ variables }) =>
      variables.map(({ key }) => new TemplateVariableOption(key))
    );
    const templateVariableOptionsMetadata = new Map<
      TemplateVariableOption["key"],
      { index: number; setRefElement: TemplateVariableOption["setRefElement"] }
    >();

    for (const [index, { key, setRefElement }] of templateVariableOptions.entries()) {
      // Since we use templateVariables to map render TemplateVariableGroupVO[]
      // to appropriately display the groupings, instead of the options set as
      // TemplateVariableOption[], we store each option index and setRefElement
      // function in a map. The option index and setRefElement allows the
      // LexicalTypeaheadMenuPlugin registered key commands to track and
      // scroll options into view with the up and down keys.
      templateVariableOptionsMetadata.set(key, { index, setRefElement });
    }

    return {
      options: templateVariableOptions,
      optionsMetadata: templateVariableOptionsMetadata,
    };
  }, [filteredVariables]);

  const handleSelectOption = useCallback(
    (selectedOption: TemplateVariableOption, nodeToReplace: TextNode | null, closeMenu: () => void) => {
      editor.update(() => {
        const templateVariableNode = $createTemplateVariableNode(selectedOption.key, true);

        if (nodeToReplace) {
          nodeToReplace.replace(templateVariableNode);
        }

        templateVariableNode.select();
        closeMenu();
        menu.off();
      });
    },
    [editor, menu]
  );

  const handleTriggerMatch = useCallback(
    (text: string) => {
      const match = slashTriggerMatch(text, editor) ?? atTriggerMatch(text, editor);

      if (match?.matchingString) {
        setSearch(match.matchingString.toLowerCase());
      } else {
        setSearch(undefined);
      }

      if (match) {
        menu.on();
      } else {
        menu.off();
      }

      return match;
    },
    [slashTriggerMatch, atTriggerMatch, editor, menu]
  );

  return (
    <LexicalTypeaheadMenuPlugin
      options={options}
      onQueryChange={noop}
      onSelectOption={handleSelectOption}
      triggerFn={handleTriggerMatch}
      menuRenderFn={(anchorElementRef, { selectedIndex, setHighlightedIndex, selectOptionAndCleanUp }) => {
        return anchorElementRef.current && filteredVariables.length && menu.isOn ? (
          <Menu
            className="w-64 max-h-80 overflow-y-auto"
            triggerRef={anchorElementRef as MutableRefObject<InteractiveElement>}
            onRequestClose={menu.off}
            placement="bottom-start"
            theme="default"
          >
            {filteredVariables.map(({ type, variables }) => (
              <Fragment key={type}>
                <span className="font-sansSemiBold text-xxs ml-3 my-2">{titleCaseConstant(type)}</span>
                {variables.map(({ key, exampleValue }) => {
                  const metadata = optionsMetadata.get(key);
                  const optionIndex = metadata?.index ?? 0;
                  const isSelected = selectedIndex === optionIndex;

                  return (
                    <div key={key} ref={metadata?.setRefElement} aria-selected={isSelected}>
                      <MenuOptionButton
                        interactionStyles={false}
                        className={cx("last:mb-1", isSelected && "bg-slate-100")}
                        onMouseEnter={() => setHighlightedIndex(optionIndex)}
                        onClick={() => {
                          setHighlightedIndex(optionIndex);
                          selectOptionAndCleanUp(new TemplateVariableOption(key));
                        }}
                      >
                        <div className="flex flex-col overflow-hidden">
                          <span className="text-xs">{titleCaseConstant(key)}</span>
                          <span className="text-xxs text-greyMedium truncate">{exampleValue}</span>
                        </div>
                      </MenuOptionButton>
                    </div>
                  );
                })}
              </Fragment>
            ))}
          </Menu>
        ) : null;
      }}
    />
  );
};
