import { FC } from "react";
import { useLocation } from "react-router-dom";
import { AppointmentVO } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { ApiQueryResult, UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { getInfiniteQueryPagingDetails, getPagingDetails } from "@libs/utils/queries";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { ReactComponent as PlusCircle } from "@libs/assets/icons/plus-circle.svg";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { TextDivider } from "components/PatientProfile/PatientOverview/Divider";
import { paths } from "utils/routing/paths";

interface Props {
  patientId: number;
  appointmentsQuery: UseInfiniteApiListQueryResult<AppointmentVO>;
  noShowQuery: ApiQueryResult<AppointmentVO[]>;
  canceledQuery: ApiQueryResult<AppointmentVO[]>;
}

const cxStyles = {
  warningCounts: "inline-block font-sans text-red text-sm",
};

export const AppointmentsPanelTitle: FC<Props> = ({
  patientId,
  appointmentsQuery,
  noShowQuery,
  canceledQuery,
}) => {
  const location = useLocation();
  const appointmentsCount = getInfiniteQueryPagingDetails(appointmentsQuery.data)?.totalElements || 0;
  const noShowCount = getPagingDetails(noShowQuery)?.totalElements || 0;
  const canceledCount = getPagingDetails(canceledQuery)?.totalElements || 0;

  return (
    <div className="min-h-5 min-w-36">
      <QueryResult queries={[appointmentsQuery, noShowQuery, canceledQuery]} loading={<LoadingContent />}>
        <div className="flex items-center">
          <span>{`${appointmentsCount ? `${appointmentsCount} ` : ""}Appointments`}</span>
          <LinkIcon
            SvgIcon={PlusCircle}
            to={paths.addPatientAppointment({ patientId, from: getFullUrl(location) })}
            tooltip={{ content: "Add Appointment", theme: "SMALL" }}
            className="mx-4"
            theme="primary"
          />
          {noShowCount ? <span className={cxStyles.warningCounts}>{`No Shows (${noShowCount})`}</span> : null}
          {canceledCount ? (
            <>
              {noShowCount ? <TextDivider /> : null}
              <span className={cxStyles.warningCounts}>{`Cancellations (${canceledCount})`}</span>
            </>
          ) : null}
        </div>
      </QueryResult>
    </div>
  );
};
