import { ProductionByProviderVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { Row } from "@libs/components/UI/GridTableComponents";
import { Cell } from "components/Dashboard/Tables/Cell";

type Props = {
  row: ProductionByProviderVO;
  last?: boolean;
};
export const ProductionByProviderRow: React.FC<Props> = ({ row, last }) => {
  const cellProps = { last };

  return (
    <Row>
      <Cell {...cellProps}>{row.provider.fullDisplayName}</Cell>
      <Cell {...cellProps}>{row.patientCount}</Cell>
      <Cell {...cellProps} align="right">
        {formatCurrency(row.totalProducedAmount / row.patientCount)}
      </Cell>
      <Cell {...cellProps} align="right">
        {formatCurrency(row.totalProducedAmount)}
      </Cell>
      {/* <ProducedVsScheduledCells
        {...cellProps}
        isScheduled={row.isScheduled}
        totalGrossProducedAmount={row.totalGrossProducedAmount}
        totalGrossScheduledAmount={row.totalGrossScheduledAmount}
        totalProducedAmount={row.totalProducedAmount}
      /> */}
      {/*
       TODO - patient ledger completed - comment back in:
      <Cell {...cellProps}>{formatCurrency(providerData.totalCollectedAmount)}</Cell> */}
    </Row>
  );
};
