import { useMemo } from "react";
import { PatientInsuranceResponse, PatientSummaryVO } from "@libs/api/generated-api";
import { TableGrid, HeaderCell } from "@libs/components/UI/GridTableComponents";
import { InsuranceDetailsRows } from "components/PatientProfile/Insurance/InsurancePlans/InsuranceDetailsRows";
import { PlanHeaderCell } from "components/PatientProfile/Insurance/InsurancePlans/TableItems";
import { MaximumsRows } from "components/PatientProfile/Insurance/InsurancePlans/MaximumsRows";
import { DeductibleRows } from "components/PatientProfile/Insurance/InsurancePlans/DeductibleRows";
import { CoverageTableRows } from "components/PatientProfile/Insurance/InsurancePlans/CoverageTableRows";
import { LimitationsRows } from "components/PatientProfile/Insurance/InsurancePlans/LimitationsRows";
import { HistoryRows } from "components/PatientProfile/Insurance/InsurancePlans/HistoryRows";
import { SubscriberNotesRows } from "components/PatientProfile/Insurance/InsurancePlans/SubscriberNotesRows";

interface Props {
  frequencyLimitationCategoryIds: Set<number>;
  insurances: PatientInsuranceResponse[];
  patient: PatientSummaryVO;
}

export const PlansTable: React.FC<Props> = ({ frequencyLimitationCategoryIds, insurances, patient }) => {
  const headers = useMemo(() => {
    const insuranceHeaders = insurances.map((insurance, index) => {
      return {
        id: index,
        label: (
          <PlanHeaderCell
            patientId={patient.id}
            hasMultipleInsurances={insurances.length > 1}
            insurance={insurance}
            key={index}
          />
        ),
        width: "340px",
      };
    });

    return [
      { id: "headers", label: "", width: "230px" },
      ...insuranceHeaders,
      { id: "end", label: "", width: "1fr" },
    ];
  }, [insurances, patient.id]);

  return (
    <TableGrid className="text-xs" columnWidths={headers.map(({ width }) => width)}>
      {headers.map((item) => (
        <HeaderCell
          className="border-r border-greyLighter last:border-r-0"
          key={item.id}
          size="short"
          sticky={false}
        >
          {item.label}
        </HeaderCell>
      ))}
      <InsuranceDetailsRows insurances={insurances} patient={patient} />
      <MaximumsRows insurances={insurances} />
      <DeductibleRows insurances={insurances} />
      <CoverageTableRows insurances={insurances} patientId={patient.id} />
      <LimitationsRows
        frequencyLimitationCategoryIds={frequencyLimitationCategoryIds}
        insurances={insurances}
        patientId={patient.id}
      />
      <HistoryRows insurances={insurances} />
      <SubscriberNotesRows insurances={insurances} />
    </TableGrid>
  );
};
