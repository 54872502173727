type SimpleSetValue = string | number;

export const areSetsEqual = <T>(set1: Set<T>, set2: Set<T>) => {
  if (set1.size !== set2.size) {
    return false;
  }

  for (const value of set1) {
    if (!set2.has(value)) {
      return false;
    }
  }

  return true;
};

export const isSameArraySet = <A extends SimpleSetValue>(left: A[] | undefined, right: A[] | undefined) => {
  if (!left && !right) {
    return true;
  }

  if (!left || !right) {
    return false;
  }

  // Check array sizes before converting to sets since sets aren't cheap to construct.
  if (left.length !== right.length) {
    return false;
  }

  return areSetsEqual(new Set(left), new Set(right));
};
