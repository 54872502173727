import { FC, ReactNode, useMemo } from "react";
import { PatientToothVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { getValidation } from "@libs/hooks/useValidation";
import { getLocalDate } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isOneOf } from "@libs/utils/isOneOf";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Icon } from "@libs/components/UI/Icon";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as AccountingIcon } from "@libs/assets/icons/accounting.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ButtonMenu, ButtonProps } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { MenuOptions, createMenuOption } from "@libs/components/UI/MenuOptions";
import { getPatientProcedureSchema } from "components/Charting/patientProcedureSchema";
import {
  AppointmentProcedure,
  isSavedProcedure,
} from "components/ScheduleAppointments/useAppointmentCategoriesAndProcedures";
import { cxTheme } from "components/UI/ToggleButton";
import { useNow } from "hooks/useNow";
import { getExpiredCDTCodeMessage } from "components/ScheduleAppointments/utils";

const TooltipContent = ({
  hasError,
  expiredDate,
  description,
}: {
  hasError: boolean;
  expiredDate: string | undefined;
  description: string;
}) => (
  <div className="flex flex-col gap-y-2">
    {hasError && <p>The procedure is missing required information. Click the procedure to edit</p>}
    {expiredDate && <p>{getExpiredCDTCodeMessage(expiredDate)}</p>}
    {description && <p>{description}</p>}
  </div>
);

export interface AppointmentProcedureOptionsProps {
  procedures: AppointmentProcedure[];
  onRemoveProcedure: (id: string | number) => void;
  onEditProcedure: (procedure: AppointmentProcedure) => void;
  onEditEstimates: (procedure: AppointmentProcedure) => void;
  teeth: PatientToothVO[] | undefined;
  disabled: boolean;
  children?: ReactNode;
}

const AppointmentProcedureOption: FC<
  {
    procedure: AppointmentProcedure;
  } & Omit<AppointmentProcedureOptionsProps, "procedures">
> = ({ procedure, disabled, onRemoveProcedure, onEditProcedure, onEditEstimates, teeth }) => {
  const now = useNow();
  const menu = useBoolean(false);
  const error = useMemo(
    () =>
      isSavedProcedure(procedure)
        ? ""
        : getValidation(
              procedure.draft,
              getPatientProcedureSchema(procedure.dentalProcedure, procedure.draft, teeth ?? [], {
                provider: false,
              })
            ).$isValid
          ? ""
          : "error",
    [procedure, teeth]
  );

  const expiredDate =
    procedure.dentalProcedure?.endDate && getLocalDate(procedure.dentalProcedure.endDate) < now
      ? procedure.dentalProcedure.endDate
      : undefined;

  return (
    <FloatingTooltip
      key={procedure.id}
      content={
        <TooltipContent
          description={isSavedProcedure(procedure) ? procedure.description : procedure.dentalProcedure.name}
          hasError={Boolean(error)}
          expiredDate={expiredDate}
        />
      }
    >
      <div
        className={cx(
          "flex flex-nowrap gap-x-1 border rounded-2xl h-6 box-border",

          cxTheme.primary({ disabled, error, checked: true })
        )}
      >
        {isSavedProcedure(procedure) ? (
          <ButtonMenu
            className="flex"
            placement="bottom-start"
            menuContent={
              <MenuOptions
                options={[
                  createMenuOption({
                    label: "Edit Procedure",
                    value: "edit-procedure",
                    SvgIcon: EditIcon,
                  }),
                  createMenuOption({
                    label: "Edit Estimates",
                    value: "edit-estimates",
                    SvgIcon: AccountingIcon,
                    disabled: !isOneOf(procedure.status, ["PLANNED", "SCHEDULED", "DONE"]),
                  }),
                ]}
                onOptionClick={(option) => {
                  switch (option.value) {
                    case "edit-procedure": {
                      onEditProcedure(procedure);
                      break;
                    }
                    case "edit-estimates": {
                      onEditEstimates(procedure);
                      break;
                    }
                    // no default
                  }

                  menu.off();
                }}
              />
            }
            isOpen={menu.isOn}
            onRequestOpen={menu.on}
            onRequestClose={menu.off}
          >
            {(buttonProps) => (
              <AppointmentProcedureOptionButton
                procedure={procedure}
                disabled={disabled}
                error={error}
                expiredDate={expiredDate}
                buttonProps={buttonProps}
              />
            )}
          </ButtonMenu>
        ) : (
          <AppointmentProcedureOptionButton
            procedure={procedure}
            disabled={disabled}
            onClick={onEditProcedure}
            expiredDate={expiredDate}
            error={error}
          />
        )}

        <ButtonIcon
          className="pr-2"
          disabled={disabled}
          SvgIcon={CancelIcon}
          size="sm"
          theme={error ? "error" : "primary"}
          onClick={() => onRemoveProcedure(procedure.id)}
        />
      </div>
    </FloatingTooltip>
  );
};

const AppointmentProcedureOptionButton: FC<{
  procedure: AppointmentProcedure;
  expiredDate: string | undefined;
  error: string;
  disabled: boolean;
  onClick?: (procedure: AppointmentProcedure) => void;
  buttonProps?: ButtonProps;
}> = ({ procedure, disabled, error, expiredDate, onClick, buttonProps }) => {
  return (
    <button
      {...buttonProps}
      className={cx(
        `text-xs
         truncate
         flex
         flex-nowrap
         items-center
         gap-x-0.5
         pl-2`,
        disabled ? "text-greyLight" : error ? "text-red" : "text-greyDark"
      )}
      type="button"
      disabled={disabled}
      onClick={() => onClick?.(procedure)}
    >
      {isSavedProcedure(procedure) ? procedure.displayName : procedure.dentalProcedure.simpleName}
      {expiredDate && <Icon SvgIcon={ErrorIcon} theme="error" size="sm" />}
    </button>
  );
};

export const AppointmentProcedureOptions: React.FC<AppointmentProcedureOptionsProps> = ({
  procedures,
  onRemoveProcedure,
  onEditProcedure,
  onEditEstimates,
  disabled,
  teeth,
  children,
}) => {
  return (
    <div className="flex flex-wrap items-center gap-2">
      {procedures.map((p) => (
        <AppointmentProcedureOption
          key={p.id}
          procedure={p}
          teeth={teeth}
          onEditProcedure={onEditProcedure}
          onEditEstimates={onEditEstimates}
          onRemoveProcedure={onRemoveProcedure}
          disabled={disabled}
        />
      ))}
      {children}
    </div>
  );
};
