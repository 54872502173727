import React from "react";
import { ParentSize } from "@visx/responsive";

import { TimeSeriesResponse } from "@libs/api/generated-api";
import { AppointmentsCompletedChartContent } from "components/Dashboard/AppointmentsCompleted/AppointmentsCompletedChartContent";
import { ChartContainer } from "components/Dashboard/Charting/ChartContainer";
import { TimeSeriesResolutionOption } from "components/Dashboard/types";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";

import { TimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { DashboardChartDisplay, DashboardQueryWithDateNavigation } from "utils/routing/dashboard";

interface Props {
  resolution: TimeSeriesResolutionOption;
  filters: DashboardFilter[];
  isLoading: boolean;
  timeSeries?: TimeSeriesResponse;
  focusDate?: Date;
  onUpdateParams: (update: Partial<DashboardQueryWithDateNavigation>) => void;
  dateWindow: TimeSeriesPageSelections;
  children?: React.ReactNode;
  chartDisplay: DashboardChartDisplay;
}

export const AppointmentsCompletedChart: React.FC<Props> = ({ children, dateWindow, ...rest }) => {
  return (
    <ChartContainer disabledMessage={dateWindow.invalidTimeSeriesParamsMessage}>
      <ParentSize className="flex-1 relative">
        {({ height, width }: { width: number; height: number }) => {
          return (
            <AppointmentsCompletedChartContent
              width={width}
              height={height}
              dateWindow={dateWindow}
              {...rest}
            />
          );
        }}
      </ParentSize>
      {children}
    </ChartContainer>
  );
};
