import { useState, useMemo, useCallback, useEffect } from "react";

import { blobToFile } from "@libs/utils/dataUrl";
import { useTwain, useCloseTwainOnExit, UseTwain } from "components/ImageCapturing/useTwain";

import { handleError } from "utils/handleError";
import { ScannerSettings } from "components/UserDocuments/useScannerSettings";

const applyDeviceIndexToTwain = (deviceIndex: number, twainSources: UseTwain["twainSources"]) => {
  if (!twainSources) {
    // Twain doesn't have sources loaded yet
    return;
  }

  if (!twainSources.items) {
    // Devices haven't loaded yet
    return;
  }

  twainSources.selectIndex(deviceIndex);
};

export const useDynamsoftScanner = ({
  scanAs,
  filename,
  onScanFiles,
  scannerSettings,
  onRequestClose,
}: {
  filename?: string;
  scanAs: "PDF" | "JPG";
  onScanFiles: (file: File[]) => void | Promise<void>;
  onRequestClose: Func;
  scannerSettings: ScannerSettings;
}) => {
  const [isScanning, setIsScanning] = useState(false);
  const { twainInstance, twainSources } = useTwain();

  useEffect(() => {
    // When user changes device, apply changes to Twain
    applyDeviceIndexToTwain(scannerSettings.preferredDeviceIndex, twainSources);
  }, [scannerSettings.preferredDeviceIndex, twainSources]);

  useCloseTwainOnExit();

  const { deviceOptions, hasSelectedDevice } = useMemo(() => {
    const devices: {
      deviceOptions: { label: string; value: number }[];
      hasSelectedDevice: boolean;
    } = {
      deviceOptions: [],
      hasSelectedDevice: false,
    };

    devices.deviceOptions =
      twainSources?.items?.map((source) => {
        if (source.index === scannerSettings.preferredDeviceIndex) {
          devices.hasSelectedDevice = true;
        }

        return { label: source.label, value: source.index };
      }) ?? [];

    return devices;
  }, [twainSources?.items, scannerSettings.preferredDeviceIndex]);

  const canScanDocument = !twainInstance.neededDownloadUrl && hasSelectedDevice;
  const handleScanDocument = useCallback((): {
    promise: Promise<void>;
    abortController?: AbortController;
  } => {
    setIsScanning(true);

    const runScan = async () => {
      try {
        await twainInstance.acquireImage({
          colorMode: scannerSettings.colorMode,
          useADF: scannerSettings.useFeeder,
          useDuplex: scannerSettings.useDuplex,
          autoDiscardBlankPages: scannerSettings.autoDiscardBlankPages,
          showUI: false,
        });

        let file: File;

        if (scanAs === "PDF") {
          const imageData = await twainInstance.saveAllAsPdf();

          file = blobToFile(imageData, filename ?? "Untitled.pdf");
        } else {
          const imageData = await twainInstance.saveAsJpeg();

          file = blobToFile(imageData, filename ?? "Untitled.jpg");
        }

        await onScanFiles([file]);
        onRequestClose();
      } catch (err) {
        handleError(err);
      } finally {
        setIsScanning(false);
        twainInstance.removeAllImagesFromBuffer();
      }
    };

    return {
      promise: runScan(),
    };
  }, [
    twainInstance,
    scannerSettings.colorMode,
    scannerSettings.useFeeder,
    scannerSettings.useDuplex,
    scannerSettings.autoDiscardBlankPages,
    scanAs,
    onScanFiles,
    onRequestClose,
    filename,
  ]);

  return {
    handleScanDocument,
    deviceOptions,
    isScanning,
    canScanDocument,
    isLoadingSources: !twainInstance.isReady || twainSources?.isRequesting,
    driverDownloadUrl: twainInstance.neededDownloadUrl,
  };
};
