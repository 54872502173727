import { CSSProperties } from "react";
import { MedicalImageVO, PearlImageSummaryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { isDefined } from "@libs/utils/types";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ImageContent } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ImageContent";
import { useContainedTransforms } from "components/PatientProfile/Imaging/hooks/useContainedTransforms";
import { cxCategoryToColor } from "components/PatientProfile/Imaging/PearlAI/utils";
import { getLabelForAnnotationCategory } from "components/PatientProfile/Imaging/PearlAI/annotations";
import { useImageListContext } from "components/PatientProfile/Imaging/shared/ImageListContext";

type Props<T extends MedicalImageVO> = {
  image: T;
  scale?: number;
  bounds: { width: number; height: number };
  onEditImage?: (image: Pick<MedicalImageVO, "id">) => void;
  children: React.ReactNode;
  className?: string;
  onClickImage?: (image: T) => void;
  style?: CSSProperties;
  onAnnotationLoaded?: Func;
  imageClasses?: string;
  actionsEnabled?: boolean;
  containOptions?: { canExceedImageSize: boolean };
  onImageLoaded?: Func;
  id?: string;
  isExportingImage?: boolean;
};

const PearlConditionSummary: React.FC<{ pearlConditions: PearlImageSummaryVO[]; scale?: number }> = ({
  pearlConditions,
  scale,
}) => {
  return (
    <div
      className={`
        flex
        gap-2
        absolute
        top-0
        left-0
        right-0
        items-center
        justify-end
        origin-right
        p-2
      `}
      style={scale ? { transform: `scale(${scale})` } : undefined}
    >
      <div>
        {pearlConditions
          .map(({ category, count }) => {
            if (category) {
              const categoryColor = cxCategoryToColor[category];

              return (
                categoryColor && (
                  <FloatingTooltip
                    key={category}
                    content={`${getLabelForAnnotationCategory(category)} (${count})`}
                    theme="SMALL"
                  >
                    <div className={cx("w-3 h-3 rounded-full hover:opacity-70", categoryColor)} />
                  </FloatingTooltip>
                )
              );
            }

            return undefined;
          })
          .filter(isDefined)}
      </div>
    </div>
  );
};

export const ImageWithBottomAttachment = <T extends MedicalImageVO>({
  image,
  bounds,
  onEditImage,
  children,
  className,
  onClickImage,
  onAnnotationLoaded,
  onImageLoaded,
  imageClasses,
  actionsEnabled = true,
  containOptions = { canExceedImageSize: false },
  style,
  scale,
  id,
}: Props<T>) => {
  const { layersShown } = useImageListContext();
  const stylesWithRotation = useContainedTransforms(bounds, image, containOptions);
  const content = (
    <ImageContent
      className="absolute cursor-move"
      image={image}
      onLoad={onImageLoaded}
      onAnnotationLoaded={onAnnotationLoaded}
      imageClasses={imageClasses}
      style={stylesWithRotation.styles}
    />
  );
  const pearlConditions = image.pearlAnalysis?.conditions;

  return (
    <div
      id={id}
      className={cx("flex flex-col relative", className)}
      style={{ ...style, width: stylesWithRotation.baseStyles.width }}
    >
      <div style={stylesWithRotation.baseStyles}>
        {actionsEnabled ? (
          <button
            type="button"
            onDoubleClick={() => onEditImage?.(image)}
            onClick={(e) => {
              e.stopPropagation();
              onClickImage?.(image);
            }}
          >
            {content}
          </button>
        ) : (
          content
        )}
      </div>
      {layersShown.has("pearl") && pearlConditions && (
        <PearlConditionSummary pearlConditions={pearlConditions} scale={scale} />
      )}
      {children}
    </div>
  );
};
