import { FC } from "react";
import { cx } from "@libs/utils/cx";
import { BaseOptionInputProps, OptionInput } from "@libs/components/UI/OptionInput";

type LabelPosition = "left" | "right";
type LabelSpacing = "between";

const cxSize = {
  lg: {
    switchContainer: "w-9 h-5 peer-hover:outline-[6px] peer-active:outline-[6px]",
    switchCheck: "w-2.5",
  },
  sm: {
    switchContainer: "w-8 h-4 peer-hover:outline-[5px] peer-active:outline-[5px]",
    switchCheck: "w-2",
  },
};

type Size = keyof typeof cxSize;

export interface SwitchProps extends Omit<BaseOptionInputProps, "size"> {
  widthFit?: true;
  labelPosition?: LabelPosition;
  size?: Size;
  labelSpacing?: LabelSpacing;
}

// eslint-disable-next-line complexity
export const Switch: FC<SwitchProps> = ({
  className: _,
  widthFit,
  labelPosition = "right",
  labelSpacing,
  size = "sm",
  ...props
}) => {
  return (
    <OptionInput
      type="checkbox"
      className={cx(
        "flex items-center gap-x-3 text-xs cursor-pointer",
        labelPosition === "left" ? "flex-row-reverse" : "flex-row",
        widthFit && "w-fit",
        labelSpacing === "between" && "justify-between",
        props.disabled && "text-greyLight cursor-default"
      )}
      customContent={
        <span
          className={cx(
            `block
             relative
             rounded-full
             bg-white
             border
             peer-hover:outline
             peer-hover:outline-greyLightest
             dark:peer-hover:outline-whiteLight
             peer-active:outline
             peer-active:outline-whiteLight
             dark:peer-active:outline-offWhite
             peer-focus:ring-2
             peer-focus:ring-primaryTheme
             peer-focus:ring-offset-2
             peer-focus:ring-offset-whiteLight
             peer-disabled:border-greyLightest
             peer-disabled:outline-none`,
            cxSize[size].switchContainer,
            props.disabled
              ? "border-greyLightest"
              : props.checked
                ? "border-primaryTheme"
                : "border-greyLighter"
          )}
        >
          <span
            className={cx(
              `block
               rounded-full
               aspect-square
               absolute
               left-1
               top-1/2
               -translate-y-1/2
               transition-transform
               ease-in-out`,
              cxSize[size].switchCheck,
              props.checked ? cx(size === "lg" ? "translate-x-4" : "translate-x-3.5") : "translate-x-0",
              props.disabled ? "bg-grey-400" : props.checked ? "bg-primaryTheme" : "bg-greyLight"
            )}
          />
        </span>
      }
      {...props}
    />
  );
};
