import { AppointmentVO, AppointmentClaimSummaryVO, PatientInsuranceVO } from "@libs/api/generated-api";

export type AppointmentSummary = Pick<
  AppointmentVO,
  "date" | "dentist" | "id" | "patientProcedures" | "provider" | "startTime"
> & {
  claims?: AppointmentClaimSummaryVO[];
  claimsLoaded: boolean;
  claimUuids?: Set<string>;
  patientInsurances?: PatientInsuranceVO[];
};

export enum CreateClaimContext {
  CREATE = "CREATE_CLAIM",
  SELECT = "SELECT_APPT",
}

export const CreateClaimButtonText: Record<string, string> = {
  CREATE: "Create claim",
  VIEW: "View claim",
};
