import React from "react";
import { type fabric } from "fabric";
import { clamp, round } from "@libs/utils/math";
import { noop } from "@libs/utils/noop";
import { ReactComponent as BrightnessIcon } from "@libs/assets/icons/brightness.svg";
import { ReactComponent as ContrastIcon } from "@libs/assets/icons/contrast.svg";
import { useImageEditorContext } from "components/PatientProfile/Imaging/ImageEditor/ImageEditorContext";
import { RangeAdjuster } from "components/PatientProfile/Imaging/ImageEditor/Tools/RangeAdjuster";

type Props = {
  brightness: number;
  contrast: number;
  onChangeBrightness: (value: number) => void;
  onChangeContrast: (value: number) => void;
};

const VALUE_RANGE = 1;

export const useBrightnessContrastMouseEvents = ({
  initialBrightness,
  initialContrast,
}: {
  initialBrightness?: number;
  initialContrast?: number;
}) => {
  const { editor, handleImageUpdate, imageSelected, drawSettings, isInitialized } = useImageEditorContext();
  const { mouseMode } = drawSettings;
  const onChangeFilter = React.useCallback(
    (val: number, key: "brightness" | "contrast") => {
      const instance = editor.current?.getInstance();

      if (instance && imageSelected) {
        handleImageUpdate(
          imageSelected,
          {
            filters: {
              [key]: val,
            },
          },
          true
        );
      }
    },
    [editor, handleImageUpdate, imageSelected]
  );
  const onChangeContrast = React.useCallback(
    (val: number) => onChangeFilter(val, "contrast"),
    [onChangeFilter]
  );
  const onChangeBrightness = React.useCallback(
    (val: number) => onChangeFilter(val, "brightness"),
    [onChangeFilter]
  );

  const brightnessRef = React.useRef(initialBrightness ?? 0);
  const contrastRef = React.useRef(initialContrast ?? 0);

  const [brightness, setBrightness] = React.useState(brightnessRef.current);
  const [contrast, setContrast] = React.useState(contrastRef.current);
  const mousedownStart = React.useRef<{ x: number; y: number } | null>(null);

  const handleBrightnessChanged = React.useCallback(
    (value: number) => {
      const canvas = editor.current?.getInstance();

      if (canvas) {
        setBrightness(value);
        onChangeBrightness(value);
        brightnessRef.current = value;
      }
    },
    [onChangeBrightness, setBrightness, brightnessRef, editor]
  );
  const handleContrastChanged = React.useCallback(
    (value: number) => {
      const canvas = editor.current?.getInstance();

      if (canvas) {
        setContrast(value);
        onChangeContrast(value);
        contrastRef.current = value;
      }
    },
    [onChangeContrast, setContrast, editor, contrastRef]
  );

  React.useEffect(() => {
    const canvas = editor.current?.getInstance();

    if (!canvas || mouseMode !== "filters") {
      return noop;
    }

    const originalContrast = contrastRef.current;
    const originalBrightness = brightnessRef.current;

    const dragHandler = (ev: fabric.IEvent<Event>) => {
      if (!mousedownStart.current || !isInitialized) {
        return;
      }

      // Dragging
      const mouseEvent = ev as fabric.IEvent<MouseEvent>;
      const throttleCoefficient = 500;

      const deltaX = mouseEvent.e.offsetX - mousedownStart.current.x;

      if (round(deltaX, 1) !== 0) {
        const _contrast = clamp(deltaX / throttleCoefficient + originalContrast, -1, 1);

        handleContrastChanged(_contrast);
      }

      const deltaY = mouseEvent.e.offsetY - mousedownStart.current.y;

      if (round(deltaY, 1) !== 0) {
        const _brightness = clamp(-deltaY / throttleCoefficient + originalBrightness, -1, 1);

        handleBrightnessChanged(_brightness);
      }
    };

    const onMouseUp = () => {
      mousedownStart.current = null;
      canvas.off("mouse:move", dragHandler);
      canvas.off("mouse:up", onMouseUp);
    };

    const onMouseDown = (e?: fabric.IEvent<Event>) => {
      const mouseEvent = e as fabric.IEvent<MouseEvent>;

      mousedownStart.current = {
        x: mouseEvent.e.offsetX,
        y: mouseEvent.e.offsetY,
      };
      canvas.on("mouse:move", dragHandler);
      canvas.on("mouse:up", onMouseUp);
    };

    canvas.on("mouse:down", onMouseDown);

    return () => {
      canvas.off("mouse:down", onMouseDown);
    };
  }, [mousedownStart, handleBrightnessChanged, handleContrastChanged, editor, mouseMode, isInitialized]);

  return {
    brightness,
    contrast,
    onChangeBrightness: handleBrightnessChanged,
    onChangeContrast: handleContrastChanged,
  };
};

export const BrightnessContrastAdjust: React.FC<Props> = ({
  brightness,
  contrast,
  onChangeBrightness,
  onChangeContrast,
}) => {
  return (
    <>
      <RangeAdjuster
        defaultValue={brightness}
        min={-VALUE_RANGE}
        max={VALUE_RANGE}
        onChange={(e) => onChangeBrightness(Number.parseFloat(e.target.value))}
        tooltip="Brightness"
        SvgIcon={BrightnessIcon}
        step={0.01}
        value={brightness}
      />

      <RangeAdjuster
        defaultValue={contrast}
        min={-VALUE_RANGE}
        max={VALUE_RANGE}
        value={contrast}
        step={0.01}
        tooltip="Contrast"
        SvgIcon={ContrastIcon}
        onChange={(e) => onChangeContrast(Number.parseFloat(e.target.value))}
      />
    </>
  );
};
