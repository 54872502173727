import { FC, Dispatch, SetStateAction, useState, useCallback } from "react";
import { produce } from "immer";

import { PatientProcedureVO, DentalProcedureVO } from "@libs/api/generated-api";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as RefreshIcon } from "@libs/assets/icons/refresh.svg";
import { useBoolean } from "@libs/hooks/useBoolean";

import { Icon } from "@libs/components/UI/Icon";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { FormFieldCurrencyInput } from "components/UI/FormFieldCurrencyInput";

import { PreAuthStatusPill } from "components/Charting/PreAuthStatusPill";
import {
  UpdatePatientProcedureFeeDraft,
  UpdatePatientProcedureFeeDraftValidationResult,
} from "components/Charting/patientProcedureUtils";
import { preAuthOptions } from "components/Charting/patientProcedureFieldOptions";

import { ProcedureSelector } from "components/ProcedureSelector/ProcedureSelector";

import { useItemModal } from "hooks/useItemModal";

interface Props {
  patientProcedure: PatientProcedureVO;
  patientProcedureFeeDraft: UpdatePatientProcedureFeeDraft;
  dentalProcedures: DentalProcedureVO[];
  onUpdateFeeDraft: Dispatch<SetStateAction<UpdatePatientProcedureFeeDraft>>;
  onChangeDowngradedDentalProcedure: (
    downgradeDentalProcedureId: number | undefined,
    options?: { onSuccess?: Func; onError?: Func }
  ) => void;
  onResetPrimaryEstimates: (options: { onSuccess: Func }) => void;
  isResettingPrimaryEstimates: boolean;
  isSaving: boolean;
  validationResult: UpdatePatientProcedureFeeDraftValidationResult;
  canEditPreAuth: boolean;
}

// eslint-disable-next-line complexity
export const EditEstimatesPrimary: FC<Props> = ({
  patientProcedure,
  patientProcedureFeeDraft,
  dentalProcedures,
  onUpdateFeeDraft,
  onChangeDowngradedDentalProcedure,
  onResetPrimaryEstimates,
  isResettingPrimaryEstimates,
  isSaving,
  validationResult,
  canEditPreAuth,
}) => {
  const [selectedDentalProcedure, setSelectedDentalProcedure] = useState(() =>
    dentalProcedures.find((dp) => dp.id === patientProcedureFeeDraft.downgradeDentalProcedureId)
  );

  const preAuthDowngradeStatusError = useBoolean(false);

  const downgradeProcedureModal = useItemModal<{ previousProcedure?: DentalProcedureVO }>(null);
  const removeDowngradeModal = useBoolean(false);
  const resetEstimatesModal = useBoolean(false);
  const dirtyEstimates = useBoolean(false);

  const handleChangeDowngradedDentalProcedure = useCallback(
    (value?: DentalProcedureVO) => {
      if (canEditPreAuth && !patientProcedureFeeDraft.preAuth?.status) {
        preAuthDowngradeStatusError.on();

        return;
      }

      if (value) {
        if (value.id === selectedDentalProcedure?.id) {
          return;
        }

        setSelectedDentalProcedure((last) => {
          // Open downgrade procedure modal with previous procedure, so that we
          // can reset it as the selected procedure if choosing to cancel
          // instead of continuing with the downgrade
          downgradeProcedureModal.open({ previousProcedure: last });

          // Set selected procedure to display in the procedure selector while
          // downgrade procedure modal is open for additional display context
          return value;
        });
      } else {
        removeDowngradeModal.on();
      }

      if (preAuthDowngradeStatusError.isOn) {
        preAuthDowngradeStatusError.off();
      }
    },
    [
      canEditPreAuth,
      patientProcedureFeeDraft.preAuth?.status,
      preAuthDowngradeStatusError,
      selectedDentalProcedure,
      downgradeProcedureModal,
      removeDowngradeModal,
    ]
  );

  const handleCancelDowngradeProcedure = useCallback(() => {
    const previousProcedure = downgradeProcedureModal.item?.previousProcedure;

    onUpdateFeeDraft((last) =>
      produce(last, (draft) => {
        draft.downgradeDentalProcedureId = previousProcedure?.id;
      })
    );

    setSelectedDentalProcedure(previousProcedure);

    downgradeProcedureModal.close();
  }, [downgradeProcedureModal, onUpdateFeeDraft]);

  const handleContinueDowngradeProcedure = useCallback(() => {
    if (selectedDentalProcedure?.id) {
      onChangeDowngradedDentalProcedure(selectedDentalProcedure.id, {
        onSuccess: () => {
          downgradeProcedureModal.close();
          dirtyEstimates.off();
        },
        onError: () => {
          setSelectedDentalProcedure(downgradeProcedureModal.item?.previousProcedure);
          downgradeProcedureModal.close();
        },
      });
    }
  }, [
    selectedDentalProcedure?.id,
    onChangeDowngradedDentalProcedure,
    downgradeProcedureModal,
    dirtyEstimates,
  ]);

  const handleRemoveDowngrade = useCallback(() => {
    onChangeDowngradedDentalProcedure(undefined, {
      onSuccess: () => {
        setSelectedDentalProcedure(undefined);
        removeDowngradeModal.off();
        dirtyEstimates.off();
      },
      onError: removeDowngradeModal.off,
    });
  }, [onChangeDowngradedDentalProcedure, removeDowngradeModal, dirtyEstimates]);

  const handleRequestResetPrimaryEstimates = useCallback(() => {
    if (canEditPreAuth && !patientProcedureFeeDraft.preAuth?.status) {
      preAuthDowngradeStatusError.on();

      return;
    }

    resetEstimatesModal.on();
  }, [
    canEditPreAuth,
    patientProcedureFeeDraft.preAuth?.status,
    preAuthDowngradeStatusError,
    resetEstimatesModal,
  ]);

  const handleDirtyEstimates = useCallback(() => {
    if (dirtyEstimates.isOff) {
      dirtyEstimates.on();
    }
  }, [dirtyEstimates]);

  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex items-center gap-x-1">
        <span className="font-sansSemiBold text-sm">Primary</span>

        <PreAuthStatusPill
          status={patientProcedure.preAuthStatus}
          submittedDate={patientProcedure.preAuthSubmittedDate}
          updatedDate={patientProcedure.preAuthUpdatedDate}
        />
      </div>

      <div className="grid grid-cols-2 gap-6">
        <FormFieldSelect
          label="Pre-Auth Status"
          placeholder="Select"
          options={preAuthOptions}
          value={patientProcedureFeeDraft.preAuth?.status}
          onItemSelected={(value) => {
            onUpdateFeeDraft((last) =>
              produce(last, (draft) => {
                if (draft.preAuth) {
                  draft.preAuth.status = value;
                } else {
                  draft.preAuth = { status: value };
                }
              })
            );

            if (preAuthDowngradeStatusError.isOn) {
              preAuthDowngradeStatusError.off();
            }
          }}
          error={
            validationResult.preAuth.status.$error ??
            (preAuthDowngradeStatusError.isOn ? "Pre-auth status is required" : undefined)
          }
          disabled={!canEditPreAuth}
          required
        />

        <FormFieldInput
          label="Pre-Auth Number"
          value={patientProcedureFeeDraft.preAuth?.number}
          onChange={(e) =>
            onUpdateFeeDraft((last) =>
              produce(last, (draft) => {
                const { value } = e.target;

                if (draft.preAuth) {
                  draft.preAuth.number = value;
                } else {
                  draft.preAuth = { number: value };
                }
              })
            )
          }
          disabled={!canEditPreAuth}
        />

        <ProcedureSelector
          label="Downgraded CDT Code"
          placeholder="Select one..."
          procedures={dentalProcedures}
          value={selectedDentalProcedure}
          onChange={handleChangeDowngradedDentalProcedure}
          // Span menu width across both columns including gap and borders
          styles={{ menu: () => ({ width: "calc(200% + 1.5rem + 2px)" }) }}
          mode="single"
        />

        {downgradeProcedureModal.isOpen ? (
          <ConfirmationModal
            primaryText="Downgrade Procedure"
            secondaryText="Downgrading a procedure will override the current estimates."
            confirmText="Continue"
            isConfirming={isSaving}
            onConfirm={handleContinueDowngradeProcedure}
            onCancel={handleCancelDowngradeProcedure}
            size="3xs"
          />
        ) : null}

        {removeDowngradeModal.isOn ? (
          <ConfirmationModal
            primaryText="Remove Downgrade"
            secondaryText="Removing a downgrade will override the current estimates."
            confirmText="Continue"
            isConfirming={isSaving}
            onConfirm={handleRemoveDowngrade}
            onCancel={removeDowngradeModal.off}
            size="3xs"
          />
        ) : null}

        <FormFieldCurrencyInput
          label="Insurance Estimate"
          value={
            canEditPreAuth
              ? patientProcedureFeeDraft.preAuth?.insuranceAmount
              : patientProcedureFeeDraft.primaryInsuranceAmount
          }
          onValueChange={(value) => {
            onUpdateFeeDraft((last) =>
              produce(last, (draft) => {
                if (canEditPreAuth && draft.preAuth) {
                  draft.preAuth.insuranceAmount = value;

                  if (Number(last.preAuth?.insuranceAmount) !== Number(draft.preAuth.insuranceAmount)) {
                    handleDirtyEstimates();
                  }
                } else {
                  draft.primaryInsuranceAmount = value ?? "";

                  if (Number(last.primaryInsuranceAmount) !== Number(draft.primaryInsuranceAmount)) {
                    handleDirtyEstimates();
                  }
                }
              })
            );
          }}
          error={
            validationResult.preAuth.insuranceAmount.$error || validationResult.primaryInsuranceAmount.$error
          }
          required
        />

        <FormFieldCurrencyInput
          label="Patient Estimate"
          value={
            canEditPreAuth
              ? patientProcedureFeeDraft.preAuth?.patientAmount
              : patientProcedureFeeDraft.primaryPatientAmount
          }
          onValueChange={(value) =>
            onUpdateFeeDraft((last) =>
              produce(last, (draft) => {
                if (canEditPreAuth && draft.preAuth) {
                  draft.preAuth.patientAmount = value;

                  if (Number(last.preAuth?.patientAmount) !== Number(draft.preAuth.patientAmount)) {
                    handleDirtyEstimates();
                  }
                } else {
                  draft.primaryPatientAmount = value ?? "";

                  if (Number(last.primaryPatientAmount) !== Number(draft.primaryPatientAmount)) {
                    handleDirtyEstimates();
                  }
                }
              })
            )
          }
          error={
            validationResult.preAuth.patientAmount.$error || validationResult.primaryPatientAmount.$error
          }
          required
        />

        <FormFieldCurrencyInput
          label="Deductible"
          value={
            canEditPreAuth
              ? patientProcedureFeeDraft.preAuth?.deductibleAmount
              : patientProcedureFeeDraft.primaryDeductibleAmount
          }
          onValueChange={(value) =>
            onUpdateFeeDraft((last) =>
              produce(last, (draft) => {
                if (canEditPreAuth && draft.preAuth) {
                  draft.preAuth.deductibleAmount = value;

                  if (Number(last.preAuth?.deductibleAmount) !== Number(draft.preAuth.deductibleAmount)) {
                    handleDirtyEstimates();
                  }
                } else {
                  draft.primaryDeductibleAmount = value ?? "";

                  if (Number(last.primaryDeductibleAmount) !== Number(draft.primaryDeductibleAmount)) {
                    handleDirtyEstimates();
                  }
                }
              })
            )
          }
          error={
            validationResult.preAuth.deductibleAmount.$error ||
            validationResult.primaryDeductibleAmount.$error
          }
          required
        />

        <Button
          className="flex items-center gap-x-1 text-xs w-fit"
          onClick={handleRequestResetPrimaryEstimates}
          disabled={dirtyEstimates.isOff}
          theme="link"
        >
          <Icon SvgIcon={RefreshIcon} />
          Reset Auto-Estimates
        </Button>

        {resetEstimatesModal.isOn ? (
          <ConfirmationModal
            primaryText="Reset Primary Estimates"
            secondaryText="This will restore the primary estimates to their previous state. This action can't be undone."
            isConfirming={isResettingPrimaryEstimates}
            onConfirm={() =>
              onResetPrimaryEstimates({
                onSuccess: () => {
                  resetEstimatesModal.off();
                  dirtyEstimates.off();
                },
              })
            }
            onCancel={resetEstimatesModal.off}
            size="3xs"
          />
        ) : null}
      </div>
    </div>
  );
};
