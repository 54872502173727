import { Link } from "react-router-dom";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { paths } from "utils/routing/paths";

type Props = {
  patientId: number;
  treatmentPlanUuid?: string;
  children?: React.ReactNode;
};
export const TreatmentPlanLink: React.FC<Props> = ({ children, treatmentPlanUuid, patientId }) => {
  if (!treatmentPlanUuid || !children) {
    // eslint-disable-next-line react/jsx-no-useless-fragment
    return <>{EMPTY_CELL}</>;
  }

  return (
    <FloatingTooltip content="View Treatment Plan" theme="SMALL">
      <Link
        className="hover:text-primaryTheme truncate"
        to={paths.charting({ patientId }, { treatmentPlanUuid })}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {children}
      </Link>
    </FloatingTooltip>
  );
};
