import { FC, useCallback, useState } from "react";
import { InvoiceVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { humanizeConstant, sentenceCaseConstant } from "@libs/utils/casing";
import { isOneOf } from "@libs/utils/isOneOf";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Button } from "@libs/components/UI/Button";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useAccount } from "@libs/contexts/AccountContext";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { Badge, BadgeColor } from "@libs/components/UI/Badge";
import { ModalPage } from "components/UI/ModalPage";
import { paths } from "utils/routing/paths";
import { getInvoiceByUuid, getInvoiceUrl, getPracticeBillingSetting } from "api/billing/queries";
import { PracticeAndPatientAddresses } from "components/PatientProfile/Billing/PracticeAndPatientAddresses";
import { getPatientSummary } from "api/patients/queries";
import { getAdjustmentTypesForPractice } from "api/settings/payments/queries";
import { usePathParams } from "hooks/usePathParams";
import { voidInvoice } from "api/billing/mutations";
import { handleError } from "utils/handleError";
import { invoiceEntryOrLineItemToId } from "components/PatientProfile/Billing/invoiceUtils";
import { InvoiceEntry } from "components/PatientProfile/Billing/InvoiceEntry";
import { InvoiceTotalRow } from "components/PatientProfile/Billing/InvoiceEntryComponents";
import { InvoiceNote } from "components/PatientProfile/Billing/InvoiceNote";
import { PrintButton } from "components/UI/PrintButton";
import { InvoiceSummary } from "components/PatientProfile/Billing/InvoiceSummary";
import { useQueryParams } from "hooks/useQueryParams";
import { PaymentHistory } from "components/PatientProfile/Billing/PaymentHistory";
import { RoleGuardClick } from "components/Main/RoleGuard";

type InvoiceStateTheme = Record<InvoiceVO["state"], BadgeColor>;

const invoiceStateTheme: InvoiceStateTheme = {
  PAID: "green",
  FINALIZED: "blue",
  PARTIALLY_PAID: "orange",
  VOID: "red",
};

// eslint-disable-next-line complexity
export const ViewInvoiceRoute: FC = () => {
  const { patientId, invoiceUuid } = usePathParams("viewInvoice");
  const {
    query: { from },
  } = useQueryParams("viewInvoice");
  const closeLink = from ?? paths.patientBilling({ patientId });
  const { practiceId } = useAccount();
  const practice = useCurrentPractice();
  const voidConfirmationModal = useBoolean(false);
  const [requestPrint, setRequestPrint] = useState(false);
  const [patientQuery, adjustmentQuery, invoiceQuery] = useApiQueries([
    getPatientSummary({ args: { patientId, practiceId } }),
    getAdjustmentTypesForPractice({ args: { practiceId } }),
    getInvoiceByUuid({
      args: {
        patientId,
        practiceId,
        invoiceUuid,
        query: { includeOrders: true, includePayments: true },
      },
    }),
    getPracticeBillingSetting({ args: { practiceId } }),
  ]);
  const isRefund = (invoiceQuery.data?.amount ?? 0) < 0;

  const [invoiceUrlQuery] = useApiQueries([
    getInvoiceUrl({
      args: {
        patientId: invoiceQuery.data?.payer.id as number,
        practiceId,
        invoiceUuid: invoiceQuery.data?.uuid as string,
      },
      queryOptions: {
        enabled: invoiceQuery.data?.invoiceNumber !== "PENDING" && requestPrint,
        onSuccess: (data) => window.open(data.data.data, "_target"),
      },
    }),
  ]);

  const [{ mutate, isLoading: isVoidingInvoice }] = useApiMutations([voidInvoice]);
  const handleVoidClick = useCallback(() => {
    mutate(
      { invoiceUuid, practiceId },
      {
        onError: handleError,
        onSuccess: voidConfirmationModal.off,
      }
    );
  }, [invoiceUuid, mutate, practiceId, voidConfirmationModal.off]);

  const paymentsToShow = (invoiceQuery.data?.payments ?? []).filter((invoicePayment) =>
    isOneOf(invoicePayment.state, ["SETTLED"])
  );

  return (
    <ModalPage
      title={<span className="font-sansSemiBold">Invoice</span>}
      closeLink={closeLink}
      className="flex-1"
      actions={
        invoiceQuery.data && (
          <div className="flex gap-x-3 justify-center">
            {isOneOf(invoiceQuery.data.state, ["PAID", "VOID"]) && (
              <ButtonInternalLink className="min-w-button" theme="primary" to={closeLink}>
                Done
              </ButtonInternalLink>
            )}

            {invoiceQuery.data.state === "FINALIZED" && (
              <>
                <Button onClick={voidConfirmationModal.on} theme="secondary" className="min-w-button">
                  Mark as Void
                </Button>
                {voidConfirmationModal.isOn && (
                  <ConfirmationModal
                    centerVertically={true}
                    primaryText="Are you sure you want to void this invoice?"
                    secondaryText="This action cannot be undone."
                    onCancel={voidConfirmationModal.off}
                    onConfirm={handleVoidClick}
                    disabled={isVoidingInvoice}
                  />
                )}
              </>
            )}
            {isOneOf(invoiceQuery.data.state, ["FINALIZED", "PARTIALLY_PAID"]) && (
              <RoleGuardClick domain="BILLING" action={isRefund ? "CREATE_REFUND" : "COLLECT_MANAGE"}>
                <ButtonInternalLink
                  replace
                  to={paths.collectPayment({ patientId }, { invoice: invoiceUuid, from: closeLink })}
                  className="min-w-button"
                >
                  {isRefund ? "Refund" : "Collect Payment"}
                </ButtonInternalLink>
              </RoleGuardClick>
            )}
          </div>
        )
      }
    >
      <div className="relative h-full w-full">
        <QueryResult
          queries={[patientQuery, adjustmentQuery, invoiceQuery]}
          nonCriticalQueries={[invoiceUrlQuery]}
          loading={<LoadingOverlaySpinner />}
        >
          {invoiceQuery.data && (
            <div className="p-5 flex flex-col gap-y-5">
              <div className="flex justify-between">
                <Badge
                  color={invoiceStateTheme[invoiceQuery.data.state]}
                  className="text-sm"
                  label={humanizeConstant(invoiceQuery.data.state)}
                />
                <PrintButton
                  className="text-primaryTheme text-sm font-sansSemiBold"
                  printUrl={invoiceUrlQuery.data}
                  theme="link"
                  isLoadingPrintUrl={invoiceUrlQuery.isInitialLoading}
                  onClick={() => setRequestPrint(true)}
                />
              </div>
              {patientQuery.data && (
                <PracticeAndPatientAddresses practiceData={practice} patientData={patientQuery.data} />
              )}
              <InvoiceSummary invoice={invoiceQuery.data} />
              {invoiceQuery.data.entries.map((entry) => (
                <div key={invoiceEntryOrLineItemToId(entry)} className="flex flex-col gap-y-2">
                  <div className="font-sansSemiBold text-sm">
                    {sentenceCaseConstant(entry.entryReference.type)}
                  </div>
                  <InvoiceEntry entry={entry} practiceAdjustments={adjustmentQuery.data} />
                </div>
              ))}
              <InvoiceTotalRow invoiceTotal={invoiceQuery.data.amount} />
              <InvoiceNote invoice={invoiceQuery.data} />
              {paymentsToShow.length > 0 && (
                <div>
                  <PaymentHistory invoiceAmount={invoiceQuery.data.amount} payments={paymentsToShow} />
                </div>
              )}
            </div>
          )}
        </QueryResult>
      </div>
    </ModalPage>
  );
};
