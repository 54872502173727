import { Link } from "react-router-dom";
import { PatientInsuranceVO, PatientVO } from "@libs/api/generated-api";
import { ValidationResult } from "@libs/hooks/useValidation";
import { getLocalDate } from "@libs/utils/date";
import { useFamilyRelations } from "@libs/hooks/useRelations";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as ExternalLink } from "@libs/assets/icons/external-link.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Banner } from "@libs/components/UI/Banner";
import { Section } from "components/Insurance/Section";
import {
  DependentPrimarySubscriber,
  PrimarySubscriber,
  SelectedPrimarySubscriber,
} from "components/Patient/types";
import { getInputValues } from "components/Patient/NewPrimarySubscriberFields";
import { SelectRelationship } from "components/Patient/SelectRelationship";
import { UpdateInsuranceFormState } from "components/Patient/formPostData";
import { getPatientInsuranceSchema } from "components/Patient/formSchemas";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { paths } from "utils/routing/paths";
import { NO_SELECTED_NUMERIC_ID } from "components/Patient/hooks";
import { planCxStyles } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/styles";

interface Props {
  dependentPrimarySubscriber: DependentPrimarySubscriber;
  isCreating: boolean;
  isEditing: boolean;
  onUpdateDependentPrimarySubscriber: (updates: Partial<DependentPrimarySubscriber>) => void;
  onUpdatePrimarySubscriber: (partial: Partial<PrimarySubscriber>) => void;
  onUpdateRelationship: (value: PatientInsuranceVO["relationshipWithSubscriber"]) => void;
  onUpdateSelectedPrimarySubscriber: (updates: Partial<SelectedPrimarySubscriber>) => void;
  selectedPrimarySubscriber: SelectedPrimarySubscriber;
  subscriberMatch: PatientVO | undefined;
  validation: ValidationResult<UpdateInsuranceFormState, ReturnType<typeof getPatientInsuranceSchema>>;
}

const SubscriberSectionTItle: React.FC<{
  edit: boolean;
  hasSubscriberMatch: boolean;
  onUpdateDependentPrimarySubscriber: (updates: Partial<DependentPrimarySubscriber>) => void;
  onUpdatePrimarySubscriber: (partial: Partial<PrimarySubscriber>) => void;
  onUpdateSelectedPrimarySubscriber: (updates: Partial<SelectedPrimarySubscriber>) => void;
}> = ({
  edit,
  hasSubscriberMatch,
  onUpdateDependentPrimarySubscriber,
  onUpdatePrimarySubscriber,
  onUpdateSelectedPrimarySubscriber,
}) => (
  <div className="flex justify-between">
    Subscriber Details
    {hasSubscriberMatch && edit ? (
      <button
        type="button"
        className="text-left font-sansSemiBold text-primaryTheme text-xs"
        onClick={() => {
          onUpdateDependentPrimarySubscriber({
            dob: undefined,
            firstName: "",
            lastName: "",
            ssn: "",
            subscriberId: "",
          });
          onUpdatePrimarySubscriber({
            ssn: "",
            subscriberId: "",
          });
          onUpdateSelectedPrimarySubscriber({
            patientId: NO_SELECTED_NUMERIC_ID,
            relationship: undefined,
          });
        }}
      >
        Select someone else
      </button>
    ) : null}
  </div>
);

export const SubscriberDetailsSection: React.FC<Props> = ({
  dependentPrimarySubscriber,
  isCreating,
  isEditing,
  onUpdateDependentPrimarySubscriber,
  onUpdatePrimarySubscriber,
  onUpdateRelationship,
  onUpdateSelectedPrimarySubscriber,
  selectedPrimarySubscriber,
  subscriberMatch,
  validation,
}) => {
  const values = getInputValues(subscriberMatch, dependentPrimarySubscriber, selectedPrimarySubscriber);
  const canEdit = !subscriberMatch && isEditing;
  const patientRelations = useFamilyRelations();

  return (
    <Section
      className={planCxStyles.section}
      includePadding={false}
      title={
        <SubscriberSectionTItle
          edit={isCreating}
          hasSubscriberMatch={Boolean(subscriberMatch)}
          onUpdateDependentPrimarySubscriber={onUpdateDependentPrimarySubscriber}
          onUpdatePrimarySubscriber={onUpdatePrimarySubscriber}
          onUpdateSelectedPrimarySubscriber={onUpdateSelectedPrimarySubscriber}
        />
      }
      useCustomStyling={true}
    >
      <div className={planCxStyles.fieldsContainer}>
        <FormFieldInput
          edit={canEdit}
          error={validation.dependentPrimarySubscriber.firstName.$error}
          label="First Name"
          layout="labelOut"
          onChange={(e) => onUpdateDependentPrimarySubscriber({ firstName: e.target.value })}
          required
          value={subscriberMatch?.personalDetails.firstName ?? values.firstName}
        />
        <FormFieldInput
          edit={canEdit}
          error={validation.dependentPrimarySubscriber.lastName.$error}
          label="Last Name"
          layout="labelOut"
          onChange={(e) => onUpdateDependentPrimarySubscriber({ lastName: e.target.value })}
          required
          value={subscriberMatch?.personalDetails.lastName ?? values.lastName}
        />
        <FormFieldSelectMenusDatepicker
          edit={canEdit}
          error={validation.dependentPrimarySubscriber.dob.$error}
          label="Date of Birth"
          layout="labelOut"
          onChange={(newDate) => onUpdateDependentPrimarySubscriber({ dob: newDate })}
          required
          selected={
            subscriberMatch?.personalDetails.dob
              ? getLocalDate(subscriberMatch.personalDetails.dob)
              : values.dob
          }
        />
        <SelectRelationship
          edit={isEditing}
          error={validation.dependentPrimarySubscriber.relationship.$error}
          layout="labelOut"
          onItemSelected={onUpdateRelationship}
          patientRelations={patientRelations}
          value={dependentPrimarySubscriber.relationship}
        />
      </div>

      {
        subscriberMatch && (
          <div className="col-span-2">
            <Banner
              className="text-xs font-sans rounded"
              paddingClassName="p-3"
              includeIcon={false}
              theme="info"
            >
              <div className="flex">
                This insurance plan is already registerd to&nbsp;
                <Link
                  className={`
                    flex
                    gap-x-2
                    items-center
                    text-primaryTheme
                    font-sansSemiBold
                  `}
                  to={paths.patient({ patientId: subscriberMatch.id })}
                  target="_blank"
                >
                  {`${subscriberMatch.personalDetails.firstName} ${subscriberMatch.personalDetails.lastName}`}
                  <Icon SvgIcon={ExternalLink} size="sm" theme="primary" />
                </Link>
              </div>
            </Banner>
          </div>
        )
        // ))
      }
    </Section>
  );
};
