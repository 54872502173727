import { FC, useState } from "react";
import { CurrencyInputProps } from "react-currency-input-field";
import { centsToDollars, dollarsToCents } from "@libs/utils/currency";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";
import { FormFieldCurrencyInput, FormFieldCurrencyInputProps } from "components/UI/FormFieldCurrencyInput";

export const PaymentAmountField: FC<
  Pick<FormFieldCurrencyInputProps, "layout" | "error" | "className"> & {
    onChange: (cents: number) => void;
    cents?: number;
  }
> = ({ cents, error, onChange, layout = "labelIn", className }) => {
  const [currencyInput, setCurrencyInput] = useState("");

  const handleOnValueChange: CurrencyInputProps["onValueChange"] = (value): void => {
    if (!value) {
      setCurrencyInput("");
      onChange(0);

      return;
    }

    setCurrencyInput(value);
    onChange(dollarsToCents(Number(value)));
  };

  useSyncOnce((centAmount) => {
    handleOnValueChange(String(centsToDollars(centAmount)));
  }, cents);

  return (
    <FormFieldCurrencyInput
      label="Amount"
      layout={layout}
      required
      value={currencyInput}
      intlConfig={{ locale: "en-US", currency: "USD" }}
      onValueChange={handleOnValueChange}
      className={className}
      placeholder="eg. $100"
      error={error}
    />
  );
};
