import { useMemo } from "react";
import { FamilyMemberVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { PatientDisc } from "components/PatientProfile/PatientOverview/Info/PatientDisc";
import { getRelationLabel } from "components/PatientProfile/PatientOverview/utils";
import { TooltipLabel } from "components/UI/TooltipLabel";

interface Props {
  familyMembers: FamilyMemberVO[];
  patientId: number;
  patientFirstName: string;
  edit: boolean;
}

export const ContactForList: React.FC<Props> = ({ edit, familyMembers, patientId, patientFirstName }) => {
  const contactFor = useMemo(
    () =>
      familyMembers.filter(
        (familyMember) => familyMember.contact.name.id === patientId && familyMember.relation !== "SELF"
      ),
    [familyMembers, patientId]
  );

  return (
    <>
      <TooltipLabel
        tooltip={{
          content:
            "This person is the designated contact for the following people. To remove them as a designated contact, visit the profile of the person they are a contact for.",
        }}
      >
        <div className={cx("text-xs", edit && "font-sansSemiBold")}>Contact Person For</div>
      </TooltipLabel>
      <div className="flex items-center mt-1">
        {contactFor.map((fm) => (
          <div key={fm.memberPatientId} className="-ml-0.5 first:ml-0">
            <PatientDisc
              name={fm.name}
              age={fm.age}
              gender={fm.gender}
              relationLabel={getRelationLabel(fm.relation, patientFirstName)}
            />
          </div>
        ))}
      </div>
    </>
  );
};
