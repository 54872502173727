import { FC, Dispatch, SetStateAction } from "react";
import { produce } from "immer";

import { PatientCriteriaVO, PatientListCriteria } from "@libs/api/generated-api";
import { FormFieldMultiSelect } from "components/UI/FormFieldMultiSelect";

import { FilterSection } from "components/Communications/Filters/FilterSection";
import { PREFERRED_LANGUAGE_OPTIONS } from "components/Communications/Filters/utils";

interface Props extends Pick<PatientCriteriaVO, "preferredLanguages" | "hasPreferredLanguage"> {
  onUpdatePatientListCriteria: Dispatch<SetStateAction<PatientListCriteria>>;
}

export const PreferredLanguageFilterSection: FC<Props> = ({
  preferredLanguages,
  hasPreferredLanguage,
  onUpdatePatientListCriteria,
}) => {
  return (
    <FilterSection
      title="Preferred Language"
      dataTestId="preferred-language-filter-section"
      gapClassName="gap-y-2"
      isOpen={(preferredLanguages && preferredLanguages.length > 0) || hasPreferredLanguage === false}
    >
      <div className="flex items-center gap-x-2 py-1 pr-1">
        <FormFieldMultiSelect
          aria-label="Preferred Language Selection"
          className="w-full"
          placeholder="Select Preferred Languages"
          // None is not a valid language option, and is inserted to only
          // represent when hasPreferredLanauge === false
          options={[...PREFERRED_LANGUAGE_OPTIONS, { label: "None", value: "NONE" }]}
          value={hasPreferredLanguage === false ? ["NONE"] : preferredLanguages}
          onChange={(newValue, actionMeta) =>
            onUpdatePatientListCriteria((last) =>
              // eslint-disable-next-line complexity
              produce(last, (draft) => {
                // When None is clicked to be removed or the clear button is
                // clicked, we remove the hasPreferredLanguage filter
                if (
                  (actionMeta.action === "remove-value" && actionMeta.removedValue.value === "NONE") ||
                  (actionMeta.action === "clear" && actionMeta.removedValues[0].value === "NONE")
                ) {
                  delete draft.patientCriteria?.hasPreferredLanguage;

                  return;
                }

                const selectedValues = newValue.map((op) => op.value);
                const noneIndex = selectedValues.indexOf("NONE");

                // When None is added, we set hasPreferredLanguage to false and
                // remove the preferredLanguages filter
                if (selectedValues.length > 0 && noneIndex === selectedValues.length - 1) {
                  if (draft.patientCriteria) {
                    draft.patientCriteria.hasPreferredLanguage = false;
                  } else {
                    draft.patientCriteria = { hasPreferredLanguage: false };
                  }

                  delete draft.patientCriteria.preferredLanguages;

                  return;
                }

                // When None is set and another language is added, we remove
                // None from selectedValues to prevent it from being saved to
                // preferredLanguages, as it is not a valid language option
                if (noneIndex === 0) {
                  selectedValues.splice(noneIndex, 1);
                }

                if (selectedValues.length > 0) {
                  if (draft.patientCriteria) {
                    draft.patientCriteria.preferredLanguages = selectedValues;
                  } else {
                    draft.patientCriteria = { preferredLanguages: selectedValues };
                  }
                } else {
                  delete draft.patientCriteria?.preferredLanguages;
                }

                delete draft.patientCriteria?.hasPreferredLanguage;
              })
            )
          }
        />
      </div>
    </FilterSection>
  );
};
