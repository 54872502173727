/* eslint-disable @typescript-eslint/naming-convention */
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { useMemo } from "react";

export const LivestormForm: React.FC<{ baseUrl: string }> = ({ baseUrl }) => {
  const practice = useCurrentPractice();
  const url = useMemo(() => {
    const searchParams = new URLSearchParams({
      utm_term: `${practice.id}`,
      utm_content: practice.name,
    }).toString();

    return new URL(`${baseUrl}?${searchParams}`).href;
  }, [baseUrl, practice.id, practice.name]);

  return (
    <div className="flex mx-auto max-w-2xl pt-6 px-3">
      <iframe title="Livestorm Form" className="w-full" height="700" src={url} />
    </div>
  );
};
