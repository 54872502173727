import { useMemo, useRef } from "react";
import { flushSync } from "react-dom";
import { ContactVO, PatientSummaryVO, PatientVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { ContactExistsBanner } from "components/Patient/ContactExistsBanner";
import { getContactSchema } from "components/Patient/formSchemas";
import { updatePatient } from "api/patients/mutations";
import { handleError } from "utils/handleError";
import { scrollToFirstError } from "utils/scrollToFirstError";
import { getContactPostData } from "components/Patient/formPostData";
import { ContactFormFields } from "components/Patient/ContactFormFields";
import { ContactForm } from "components/Patient/ContactForm";
import { useIsDirty } from "hooks/useIsDirty";
import { useContactDraft } from "components/Patient/hooks";

interface Props {
  patient: PatientVO;
  onMatchSelected: (patient: PatientSummaryVO) => void;
  onContactUpdated: (contact: ContactVO) => void;
  onCancel: () => void;
  onDirty: Func;
  patientFirstName: string;
}

export const EditContactPanelForm: React.FC<Props> = ({
  patient,
  onContactUpdated,
  onCancel,
  onDirty,
  onMatchSelected,
  patientFirstName,
}) => {
  const { practiceId } = useAccount();
  const formFieldsRef = useRef<HTMLDivElement>(null);
  const { contactDraft, handleUpdateContactDraft, patientMatch } = useContactDraft(patient);

  const [updatePatientMutation] = useApiMutations([updatePatient]);

  const schema = useMemo(
    () =>
      getContactSchema({
        contactModes: contactDraft.contactModes,
        preferredContactMode: contactDraft.preferredContactMode,
      }),
    [contactDraft.contactModes, contactDraft.preferredContactMode]
  );

  const validation = useValidation(contactDraft, schema);

  const handleSubmit = () => {
    const result = flushSync(() => validation.validate());

    if (result.$isValid) {
      const postData = getContactPostData(contactDraft);

      updatePatientMutation.mutate(
        {
          practiceId,
          patientId: patient.id,
          data: {
            additionalInformation: {
              ...patient.additionalInformation,
              ...postData.additionalInformation,
            },
            contactDetails: {
              ...patient.contactDetails,
              ...postData.contactDetails,
            },
            personalDetails: {
              ...patient.personalDetails,
              ...postData.personalDetails,
            },
          },
        },
        {
          onSuccess: (response) => {
            const updatedPatient = response.data.data;
            const { relationship } = contactDraft;

            if (updatedPatient.contact.relation === "SELF") {
              onContactUpdated({
                ...updatedPatient.contact,
                relation: relationship,
              });
            }
          },
          onError: handleError,
        }
      );
    } else {
      scrollToFirstError(formFieldsRef.current);
    }
  };

  useIsDirty(contactDraft, { onDirty });

  return (
    <ContactForm
      dataTestId="edit-contact-form"
      isSaving={updatePatientMutation.isLoading}
      onCancel={onCancel}
      disabled={Boolean(patientMatch)}
      onSave={handleSubmit}
    >
      <ContactFormFields
        onSelectPatientMatch={onMatchSelected}
        patientMatch={patientMatch}
        containerRef={formFieldsRef}
        onUpdate={handleUpdateContactDraft}
        contractDraft={contactDraft}
        relationshipLabel={patientFirstName ? `Relationship${` to ${patientFirstName}`}` : undefined}
        validations={validation.result}
      >
        <ContactExistsBanner />
      </ContactFormFields>
    </ContactForm>
  );
};
