import { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LedgerV2AdjustmentEntryVO, PatientVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getFullUrl } from "@libs/utils/location";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { AdjustmentModal } from "components/PatientProfile/Billing/AdjustmentModal";
import { paths } from "utils/routing/paths";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { checkPermission } from "components/Roles/roleUtils";

export const AdjustmentMenu: FC<{
  patientId: PatientVO["id"];
  adjustmentEntry: LedgerV2AdjustmentEntryVO;
  canCreateInvoice: boolean;
}> = ({ patientId, adjustmentEntry, canCreateInvoice }) => {
  const adjustmentModal = useBoolean(false);
  const navigate = useNavigate();
  const location = useLocation();
  const { roleV2 } = useCurrentUser();

  const canEditAdjustment =
    checkPermission(roleV2, "BILLING", "ADD_ADJUSTMENT_CHARGE").isPermitted ||
    checkPermission(roleV2, "BILLING", "ADD_ADJUSTMENT_DISCOUNT").isPermitted;

  const showMenu = canCreateInvoice || canEditAdjustment;

  return showMenu ? (
    <>
      <AdjustmentMenuDropdown
        onEditAdjustmentClick={adjustmentModal.on}
        canCreateInvoice={canCreateInvoice}
        canEditAdjustment={canEditAdjustment}
        onCreateInvoiceClick={() =>
          navigate(paths.createInvoice({ patientId }, { from: getFullUrl(location) }))
        }
      />
      {adjustmentModal.isOn && (
        <AdjustmentModal
          adjustmentUuid={adjustmentEntry.adjustmentUuid}
          onClose={adjustmentModal.off}
          onAdjustmentSave={adjustmentModal.off}
          patientId={patientId}
          attachedToInvoice={Boolean(adjustmentEntry.invoiceUuid)}
        />
      )}
    </>
  ) : null;
};

const AdjustmentMenuDropdown: FC<{
  canCreateInvoice: boolean;
  canEditAdjustment: boolean;
  onEditAdjustmentClick: Func;
  onCreateInvoiceClick: Func;
}> = ({ canCreateInvoice, canEditAdjustment, onEditAdjustmentClick, onCreateInvoiceClick }) => {
  const menu = useBoolean(false);

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      placement="bottom-start"
      menuContent={
        <>
          {canEditAdjustment && (
            <MenuOptionButton
              onClick={() => {
                onEditAdjustmentClick();
                menu.off();
              }}
            >
              Edit Adjustment
            </MenuOptionButton>
          )}
          {canCreateInvoice && (
            <MenuOptionButton
              onClick={() => {
                onCreateInvoiceClick();
                menu.off();
              }}
            >
              Create Invoice
            </MenuOptionButton>
          )}
        </>
      }
      onRequestClose={menu.off}
    >
      {(props) => <ButtonIcon {...props} size="md" SvgIcon={MenuIcon} />}
    </ButtonMenu>
  );
};
