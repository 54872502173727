import {
  AppointmentBookingRequest,
  CollectedPaymentRequest,
  CollectionAdjustmentRequest,
  InsuranceAdjustmentRequest,
  JournalEventRequest,
  PatientAppointmentStatsRequest,
  ProductionByAppointmentRequest,
  ProductionByProcedureRequest,
  ProductionByProviderRequest,
  ProviderRollupRequest,
  ReportingRecallRequest,
  TimeSeriesRequest,
  UncollectedByCarrierRequest,
  UncollectedByServiceRequest,
  ReportingRecallByPatientRequest,
  UnscheduledProcedureRequest,
  InboundReferralRequest,
  CollectedPaymentByProviderRequest,
  PatientBalanceAgingRequest,
  WorkingHourRequest,
  PatientWalletRequest,
} from "@libs/api/generated-api";
import { makeQuery, getNextPageParam, makeInfiniteQuery } from "@libs/utils/queries";
import { fiveMinuteCache } from "@libs/utils/queryCache";

type InfiniteQueryParams = {
  pageNumber: number;
  maxPageSize?: number;
  pageSize: number;
};
export const getTimeSeriesQuery = makeQuery({
  queryKey: ["practices", "getTimeSeries"],
  formatParams: (args: { practiceId: number; data: TimeSeriesRequest }) => [args.practiceId, args.data],
  queryOptions: fiveMinuteCache,
});

export const getProductionByProviderQuery = makeQuery({
  queryKey: ["practices", "getProductionByProvider"],
  formatParams: (args: { practiceId: number; data: ProductionByProviderRequest }) => [
    args.practiceId,
    args.data,
  ],
  queryOptions: fiveMinuteCache,
});
export const getUncollectedByCarrierQuery = makeInfiniteQuery({
  queryKey: ["practices", "getUncollectedByCarrier"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: UncollectedByCarrierRequest;
  } & InfiniteQueryParams) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});

export const getUncollectedByServiceInfiniteQuery = makeInfiniteQuery({
  queryKey: ["practices", "getUncollectedByService"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: UncollectedByServiceRequest;
  } & InfiniteQueryParams) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});

export const getProductionByAppointmentInfiniteQuery = makeInfiniteQuery({
  queryKey: ["practices", "getProductionByAppointment"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: ProductionByAppointmentRequest;
  } & InfiniteQueryParams) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});

export const getProductionByProcedureInfiniteQuery = makeInfiniteQuery({
  queryKey: ["practices", "getProductionByProcedure"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: ProductionByProcedureRequest;
  } & InfiniteQueryParams) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});

export const getProviderRollupQuery = makeQuery({
  queryKey: ["practices", "getProviderRollup"],
  formatParams: (args: { practiceId: number; data: ProviderRollupRequest }) => [args.practiceId, args.data],
  queryOptions: fiveMinuteCache,
});

export const getProductionByProviderComboQuery = makeQuery({
  queryKey: ["practices", "getProductionByProviderCombo"],
  formatParams: (args: { practiceId: number; data: ProductionByProviderRequest }) => [
    args.practiceId,
    args.data,
  ],
  queryOptions: fiveMinuteCache,
});

export const getCollectedPaymentQuery = makeInfiniteQuery({
  queryKey: ["practices", "getCollectedPayment"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: { practiceId: number; data: CollectedPaymentRequest } & InfiniteQueryParams) => [
    practiceId,
    query,
    data,
  ],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});

export const getCollectedByProviderInfiniteQuery = makeInfiniteQuery({
  queryKey: ["practices", "getCollectedPaymentByProvider"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: CollectedPaymentByProviderRequest;
  } & InfiniteQueryParams) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});

export const getPatientAppointmentStatsInfiniteQuery = makeInfiniteQuery({
  queryKey: ["practices", "getPatientAppointmentStats"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: PatientAppointmentStatsRequest;
  } & InfiniteQueryParams) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});
export const getEmployeeWorkingHourStats = makeInfiniteQuery({
  queryKey: ["practices", "getEmployeeWorkingHourStats"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: WorkingHourRequest;
  } & InfiniteQueryParams) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});
export const getAppointmentBookingInfiniteQuery = makeInfiniteQuery({
  queryKey: ["practices", "getAppointmentBooking"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: AppointmentBookingRequest;
  } & InfiniteQueryParams) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});

export const getCollectionAdjustmentStatsInfiniteQuery = makeInfiniteQuery({
  queryKey: ["practices", "getCollectionAdjustmentStats"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: CollectionAdjustmentRequest;
  } & InfiniteQueryParams) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});
export const getInsuranceAdjustmentStatsInfiniteQuery = makeInfiniteQuery({
  queryKey: ["practices", "getInsuranceAdjustmentStats"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: InsuranceAdjustmentRequest;
  } & InfiniteQueryParams) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});

export const getJournalEventsInfiniteQuery = makeInfiniteQuery({
  queryKey: ["practices", "getJournalEvents"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: JournalEventRequest;
  } & InfiniteQueryParams) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});

export const getRecallStats = makeInfiniteQuery({
  queryKey: ["practices", "getRecallStats"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: ReportingRecallRequest;
  } & InfiniteQueryParams) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});

export const getUnscheduledProcedures = makeInfiniteQuery({
  queryKey: ["practices", "getUnscheduledProcedures"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: UnscheduledProcedureRequest;
  } & InfiniteQueryParams) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});

export const getRecallByPatientStats = makeInfiniteQuery({
  queryKey: ["practices", "getRecallByPatientStats"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: ReportingRecallByPatientRequest;
  } & InfiniteQueryParams) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});

export const getInboundReferralStats = makeInfiniteQuery({
  queryKey: ["practices", "getInboundReferralStats"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: InboundReferralRequest;
  } & InfiniteQueryParams) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});

export const getPatientBalanceAgingQuery = makeInfiniteQuery({
  queryKey: ["practices", "getPatientBalanceAging"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: PatientBalanceAgingRequest;
  } & InfiniteQueryParams) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});

export const getCollectedPaymentByProviderComboQuery = makeQuery({
  queryKey: ["practices", "getCollectedPaymentByProviderCombo"],
  formatParams: (args: { practiceId: number; data: CollectedPaymentByProviderRequest }) => [
    args.practiceId,
    args.data,
  ],
  queryOptions: fiveMinuteCache,
});

export const getPatientWalletStatsQuery = makeInfiniteQuery({
  queryKey: ["practices", "getPatientWalletStats"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: PatientWalletRequest;
  } & InfiniteQueryParams) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});
