import { useCallback } from "react";
import { formatWorkingSeconds } from "components/Dashboard/PayrollHours/utils";

export const useFormatWorkingSeconds = ({ hoursInDecimal }: { hoursInDecimal: boolean }) => {
  return useCallback(
    (value: number) => {
      return formatWorkingSeconds(value, hoursInDecimal);
    },
    [hoursInDecimal]
  );
};
