import React, { useMemo } from "react";
import { PatientInsuranceResponse } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { formatQueryTitle, usePageTitle } from "@libs/hooks/usePageTitle";
import { isDefined } from "@libs/utils/types";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getClaimQuery, getPracticeClaimsBillingProviders } from "api/claim/queries";
import { getPatientInsurancesQuery, getPatientInsuranceQuery } from "api/patientInsurance/queries";
import { useCustomProviders, usePracticeProviders } from "api/practice/hooks";
import { ClaimDetailsPage } from "components/Claim/DetailsPage";
import { usePathParams } from "hooks/usePathParams";

// eslint-disable-next-line complexity
export const ClaimRoute: React.FC = () => {
  const { practiceId } = useAccount();

  const { claimUuid } = usePathParams("claim");
  const [claimQuery] = useApiQueries([getClaimQuery({ args: { practiceId, claimUuid } })]);
  const isPreAuth = claimQuery.data?.isPreAuth ?? false;

  const shouldFetchPatientInsurances = Boolean(isPreAuth && claimQuery.data?.patientName.id);
  const [patientInsurancesQuery] = useApiQueries([
    getPatientInsurancesQuery({
      args: {
        includeInsurancePlan: true,
        insuranceState: ["ACTIVE", "INACTIVE", "ARCHIVED"],
        patientId: claimQuery.data?.patientName.id || 0,
        practiceId,
      },
      // Only needed for pre-auth claims, so don't fetch on normal ones for optimization.
      queryOptions: { enabled: shouldFetchPatientInsurances },
    }),
  ]);

  const { data: patientInsurances } = patientInsurancesQuery;

  // This is a manual fetch of a patient insurance to make sure that if we
  // have a migration where the patient's insurance gets migrated to an `Unknown`
  // carrier, but the claim was migrated with an insurance carrier, we fetch
  // the corresponding insurance and add it to the options list
  const shouldFetchPatientInsuranceFromClaim = Boolean(
    patientInsurances &&
      !patientInsurances.some(
        (insurance: PatientInsuranceResponse) =>
          insurance.patientInsurance.id === claimQuery.data?.patientInsuranceId
      )
  );
  const [patientInsuranceFromClaimQuery] = useApiQueries([
    getPatientInsuranceQuery({
      args: {
        includeInsurancePlan: true,
        insuranceId: claimQuery.data?.patientInsuranceId || 0,
        patientId: claimQuery.data?.patientName.id || 0,
        practiceId,
      },
      queryOptions: {
        enabled: shouldFetchPatientInsuranceFromClaim,
      },
    }),
  ]);

  const { data: patientInsuranceFromClaim } = patientInsuranceFromClaimQuery;

  const insurances = useMemo(() => {
    return patientInsurances && patientInsuranceFromClaim
      ? [...patientInsurances, patientInsuranceFromClaim]
      : patientInsurances ?? [];
  }, [patientInsurances, patientInsuranceFromClaim]);

  const practiceBillingProvidersQuery = useCustomProviders(
    getPracticeClaimsBillingProviders({ args: { practiceId } }),
    claimQuery.data ? [claimQuery.data.billingProviderId] : undefined
  );

  const providersQuery = usePracticeProviders(
    claimQuery.data ? [claimQuery.data.treatingProviderId] : undefined
  );

  usePageTitle(
    formatQueryTitle(
      claimQuery,
      (claim) =>
        `${claim.isPreAuth ? "Pre-Auth" : "Claim"} #${claim.claimNumber} - ${
          claim.patientName.fullDisplayName
        }`
    )
  );

  return (
    <QueryResult
      queries={[
        claimQuery,
        practiceBillingProvidersQuery,
        shouldFetchPatientInsuranceFromClaim ? patientInsuranceFromClaimQuery : undefined,
        shouldFetchPatientInsurances ? patientInsurancesQuery : undefined,
        providersQuery,
      ].filter(isDefined)}
    >
      {claimQuery.data && (
        <ClaimDetailsPage
          claim={claimQuery.data}
          patientInsurances={insurances}
          practiceBillingProviders={practiceBillingProvidersQuery.data}
          practiceProviders={providersQuery.data}
        />
      )}
    </QueryResult>
  );
};
