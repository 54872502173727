import { makeQuery } from "@libs/utils/queries";
import { cacheForSession, fiveMinuteCache } from "@libs/utils/queryCache";

export const getEmailConfig = makeQuery({
  queryKey: ["practices", "getEmailConfig"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: fiveMinuteCache,
});

export const getUnlayerToken = makeQuery({
  queryKey: ["practices", "getUnlayerToken"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: cacheForSession,
});
