import { FC, PropsWithChildren } from "react";
import { AppointmentVO } from "@libs/api/generated-api";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as RightArrow } from "@libs/assets/icons/right-arrow.svg";
import { ScrollableInfiniteQuery } from "@libs/components/UI/ScrollableInfiniteQuery";
import { EmptyContent } from "@libs/components/UI/EmptyContent";

import tabletUrl from "assets/images/tablet.svg";
import { AppointmentSummary } from "components/PatientBlurbInfo/AppointmentSummary";

interface Props {
  patientId: number;
  appointmentsQuery: UseInfiniteApiQueryResult<AppointmentVO[]>;
  onSelectAppointment: (appointment: AppointmentVO) => void;
  onNoUpcomingAppointment: Func;
  hasNoUpcomingAppointment: boolean;
  onCloseAppointmentsList: Func;
}

export const SelectAppointmentPanel: FC<Props> = ({
  patientId,
  appointmentsQuery,
  onSelectAppointment,
  onNoUpcomingAppointment,
  hasNoUpcomingAppointment,
  onCloseAppointmentsList,
}) => {
  return (
    <ScrollableInfiniteQuery
      className="flex flex-col gap-y-1 px-6 pt-3 pb-4"
      id={`patient-appointments-${patientId}`}
      infiniteQuery={appointmentsQuery}
      noResults={
        <>
          <div className="flex justify-end">
            <ButtonIcon size="md" onClick={onCloseAppointmentsList} SvgIcon={RightArrow} />
          </div>
          <EmptyContent src={tabletUrl} alt="No Appointments" text="No Appointments" />
        </>
      }
    >
      {(appointments) => (
        <>
          <span className="text-xs font-sansSemiBold leading-6">All Appointments</span>

          {hasNoUpcomingAppointment ? (
            <AppointmentPanelButton onClick={onNoUpcomingAppointment}>
              <AppointmentSummary appointment={undefined} theme="slate100" />
            </AppointmentPanelButton>
          ) : null}

          {appointments.map((appointment) => (
            <AppointmentPanelButton key={appointment.id} onClick={() => onSelectAppointment(appointment)}>
              <AppointmentSummary appointment={appointment} theme="slate100" />
            </AppointmentPanelButton>
          ))}
        </>
      )}
    </ScrollableInfiniteQuery>
  );
};

const AppointmentPanelButton: FC<PropsWithChildren<{ onClick: Func }>> = ({ onClick, children }) => (
  <button className="text-left w-[calc(100%+1.5rem)] block -mx-3" onClick={onClick} type="button">
    {children}
  </button>
);
