import React, { PropsWithChildren } from "react";
import { cx } from "@libs/utils/cx";
import { useAttachmentsView } from "./AttachmentsViewContext";

export const tabs = ["Images", "Narratives", "Perio Chart", "Other"];

export const ClaimAttachmentsHeader: React.FC<PropsWithChildren> = ({ children }) => {
  const { activeTabIndex, setActiveTabIndex } = useAttachmentsView();

  return (
    <div className="flex gap-x-6 text-greyDark">
      {tabs.map((tab, index) => (
        <div className="relative" key={index}>
          <button
            className={cx(
              "flex items-center h-14",
              activeTabIndex === index && "text-primaryTheme font-sansSemiBold"
            )}
            key={tab}
            onClick={() => setActiveTabIndex(index)}
            type="button"
          >
            {tab}
          </button>
          {activeTabIndex === index && (
            <div
              className={`
                absolute
                h-[3px]
                w-full
                bg-primaryTheme
                rounded
                left-0
                right-0
                -bottom-px
              `}
            />
          )}
        </div>
      ))}
      {children}
    </div>
  );
};
