import { forwardRef, useEffect, useImperativeHandle, useRef } from "react";
import Skeleton from "react-loading-skeleton";
import { cx } from "@libs/utils/cx";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { FocusDirection, useFocusManager } from "contexts/FocusManagerContext";
import { PerioChartSummaryAndNotes } from "components/Charting/Perio/PerioChartSummaryAndNotes";
import { usePerioInputListeners } from "components/Charting/Perio/usePerioInputListeners";
import { PerioChartTeeth } from "./PerioChartTeeth";
import { PerioChartSequenceType } from "./PerioTypes";
import { usePerioChart } from "./PerioChartContext";
import { PerioChartPrintHeader } from "./PerioChartPrintHeader";
import { PerioChartExamRecord } from "./perioChartExamRecord";

const topFacial: PerioChartSequenceType[] = [
  "PROBING",
  "GINGMARGIN",
  "AUTOCAL",
  "MUCOGINGJUNC",
  "MOBILITY",
  "FURCATION",
  "PLAQUE",
  "CALCULUS",
  "BLEEDING",
  "SUPPURATION",
];

const topLingual: PerioChartSequenceType[] = [
  "SUPPURATION",
  "BLEEDING",
  "CALCULUS",
  "PLAQUE",
  "FURCATION",
  "AUTOCAL",
  "GINGMARGIN",
  "PROBING",
];

const bottomFacial: PerioChartSequenceType[] = [
  "SUPPURATION",
  "BLEEDING",
  "CALCULUS",
  "PLAQUE",
  "FURCATION",
  "MOBILITY",
  "MUCOGINGJUNC",
  "AUTOCAL",
  "GINGMARGIN",
  "PROBING",
];

const bottomLingual: PerioChartSequenceType[] = [
  "PROBING",
  "GINGMARGIN",
  "AUTOCAL",
  "MUCOGINGJUNC",
  "FURCATION",
  "PLAQUE",
  "CALCULUS",
  "BLEEDING",
  "SUPPURATION",
];

export const PerioChart = forwardRef<
  HTMLDivElement | null,
  {
    onRender?: (record: Readonly<PerioChartExamRecord>) => void;
    className?: string;
    scrollRef?: React.RefObject<HTMLDivElement>;
  }
>(({ onRender, className, scrollRef }, ref) => {
  const { currentExam, isLoading, isError, printing, settings, printOptions } = usePerioChart();

  const chartRef = useRef<HTMLDivElement>(null);

  useImperativeHandle(ref, () => chartRef.current as HTMLDivElement);

  const focusManager = useFocusManager();

  usePerioInputListeners({
    chartRef,
    scrollRef,
  });

  const uuid = currentExam?.getExamInfo().uuid;

  useEffect(() => {
    if (uuid && !isLoading) {
      // Focus the first element when the chart is first loaded,
      // or when the uuid changes.
      focusManager.focus(FocusDirection.FIRST);
    }
  }, [uuid, focusManager, isLoading]);

  useEffect(() => {
    if (!isLoading && currentExam) {
      onRender?.(currentExam);
    }
  }, [currentExam, isLoading, onRender]);

  return (
    <div
      className={cx("flex flex-col h-full print:m-0 bg-white", printing.isOn && "p-5", className)}
      ref={chartRef}
    >
      {currentExam && <PerioChartPrintHeader exam={currentExam} isPrinting={printing.isOn} />}
      <QueryResult queries={[{ isLoading, isError }]} loading={<Skeleton className="h-[368px]" />}>
        {printing.isOn && !printOptions.has("perio-chart") ? null : (
          <div className="break-after-page">
            <PerioChartTeeth facialSequences={topFacial} lingualSequences={topLingual} isTopTeeth />
            <div className="h-px w-full bg-greyDark mt-3 mb-3 max-w-[1209px]" />
            <PerioChartTeeth
              facialSequences={bottomFacial}
              lingualSequences={bottomLingual}
              isTopTeeth={false}
            />
          </div>
        )}

        {settings && currentExam && (
          <PerioChartSummaryAndNotes
            settings={settings}
            currentExam={currentExam}
            isPrinting={printing.isOn}
            printOptions={printOptions}
          />
        )}
      </QueryResult>
    </div>
  );
});
