import { FC, useEffect } from "react";
import { PatientSummaryVO, PaymentDeviceVO, PaymentProfileVO, PaymentVO } from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { calculateSurchargeOnPayment } from "api/billing/queries";
import { topupWallet } from "api/billing/mutations";
import { isPaymentMethodByCard } from "components/PatientProfile/Billing/billingUtils";
import { Note, SelectPaymentDate } from "components/PatientProfile/Billing/FormComponents";
import { FlyoverContent, FlyoverFooter, FlyoverForm } from "components/UI/FlyoverComponents";
import { SurchargeSummary } from "components/PatientProfile/Billing/SurchargeSummary";
import { ConfigurePaymentMethod } from "components/PatientProfile/Billing/PaymentMethods/ConfigurePaymentMethod";
import { PaymentDescription } from "components/PatientProfile/Billing/PaymentDescription";
import { PaymentAmountField } from "components/PatientProfile/Billing/PaymentAmountField";

import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { useAddFundsToWalletForm } from "components/PatientProfile/Billing/AddFundsToWallet/useAddFundsToWalletForm";

type Props = {
  patientId: number;
  paymentProfileUuid?: string;
  patientData?: PatientSummaryVO;
  onClose: Func;
  onClickAddCard: Func;
  onPaymentCollected: (payment: PaymentVO) => void;
  paymentProfiles: PaymentProfileVO[];
  paymentDevices: PaymentDeviceVO[];
  passCreditCardFees: boolean;
};

// eslint-disable-next-line complexity
export const AddFundsToWalletFlyoverForm: FC<Props> = (props) => {
  const { practiceId, practiceUuid } = useAccount();
  const [{ mutate: topupWalletMutate }] = useApiMutations([topupWallet]);
  const {
    paymentProfiles,
    paymentDevices,
    paymentMethodOptions,
    walletTopupDraft,
    validation,
    isPaying,
    paymentButtonLabel,
    handleWalletDraftChange,
    handlePaymentMethodChange,
    handleSubmit,
    debouncedUpdateWallet,
    isEditingPayment,
  } = useAddFundsToWalletForm({
    ...props,
    practiceId,
    topupWalletMutate,
  });

  const [surchargeQuery] = useApiQueries([
    calculateSurchargeOnPayment({
      args: {
        practiceUuid,
        data: {
          subtotal: walletTopupDraft.paymentAmount ?? 0,
          paymentProfileUuid: walletTopupDraft.selectedPaymentProfileUuid ?? "",
        },
      },
      queryOptions: {
        enabled: Boolean(
          (walletTopupDraft.paymentAmount ?? 0) > 0 && walletTopupDraft.selectedPaymentProfileUuid
        ),
      },
    }),
  ]);

  useEffect(() => {
    // User has added a card in another part of the flyover reel
    if (props.paymentProfileUuid) {
      handleWalletDraftChange({
        selectedPaymentProfileUuid: props.paymentProfileUuid,
        paymentMethod: "STORED_PROFILE",
      });
    }
  }, [handleWalletDraftChange, props.paymentProfileUuid]);

  return (
    <FlyoverForm onSubmit={handleSubmit}>
      <FlyoverContent className="flex flex-col gap-6">
        <PaymentDescription
          value={walletTopupDraft.walletNote}
          error={validation.result.walletNote.$error}
          onChange={(e) =>
            handleWalletDraftChange({
              walletNote: e.target.value,
            })
          }
        />
        <div className="grid grid-cols-2 gap-6">
          <PaymentAmountField
            layout="labelOut"
            error={validation.result.paymentAmount.$error}
            cents={walletTopupDraft.paymentAmount}
            onChange={(cents) =>
              debouncedUpdateWallet({
                paymentAmount: cents,
              })
            }
          />
          <FormFieldSelect
            label="Method"
            className="w-full max-w-[256px]"
            onItemSelected={handlePaymentMethodChange}
            required
            value={walletTopupDraft.paymentMethod}
            options={paymentMethodOptions}
          />
        </div>
        {!isPaymentMethodByCard(walletTopupDraft.paymentMethod) && (
          <SelectPaymentDate
            layout="labelOut"
            onChange={(paymentCreatedAt) => {
              if (paymentCreatedAt) {
                handleWalletDraftChange({
                  paymentDate: paymentCreatedAt,
                });
              }
            }}
            selected={walletTopupDraft.paymentDate}
            error={validation.result.paymentDate.$error}
          />
        )}

        <ConfigurePaymentMethod
          patientId={props.patientId}
          paymentProfiles={paymentProfiles}
          paymentDevices={paymentDevices}
          paymentDraft={walletTopupDraft}
          handleUpdatePaymentDraft={handleWalletDraftChange}
          onClickAddCard={props.onClickAddCard}
          errors={{
            checkNumber: validation.result.checkPayload.checkNumber.$error,
            checkBankName: validation.result.checkPayload.bankName.$error,
            paymentProfile: validation.result.selectedPaymentProfileUuid.$error,
            paymentDevice: validation.result.selectedPaymentPosUuid.$error,
            externalPosLastFour: validation.result.externalPosPayload.cardLastFour.$error,
            externalPosNameOnCard: validation.result.externalPosPayload.nameOnCard.$error,
          }}
          disabled={isPaying}
        />
        <Note
          value={walletTopupDraft.note}
          error={validation.result.note.$error}
          onChange={(e) =>
            handleWalletDraftChange({
              note: e.target.value,
            })
          }
        />
        {isOneOf(walletTopupDraft.paymentMethod, ["STORED_PROFILE", "REFUNDABLE_CARD"]) ? (
          <QueryResult queries={[surchargeQuery]}>
            {surchargeQuery.data && (
              <SurchargeSummary
                surchargeAmount={surchargeQuery.data.surchargeAmount}
                subtotal={surchargeQuery.data.subtotal}
                total={surchargeQuery.data.total}
              />
            )}
          </QueryResult>
        ) : (
          <SurchargeSummary
            total={walletTopupDraft.paymentAmount ?? 0}
            showSurchargeDisclosure={
              walletTopupDraft.paymentMethod === "STORED_POS" && props.passCreditCardFees
            }
          />
        )}
      </FlyoverContent>
      <FlyoverFooter actions>
        <AsyncButton
          displayLoadingText
          disabled={isPaying || isEditingPayment || surchargeQuery.isLoading}
          type="submit"
          isLoading={isPaying}
          className="min-w-button"
        >
          {paymentButtonLabel}
        </AsyncButton>
      </FlyoverFooter>
    </FlyoverForm>
  );
};
