import { ReactNode, useEffect, useId, useRef } from "react";
import { AppointmentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { noop } from "@libs/utils/noop";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as Arrow } from "@libs/assets/icons/left-caret.svg";
import { EmptyContent } from "@libs/components/UI/EmptyContent";
import { MainContent } from "@libs/components/UI/MainContent";
import { PatientLinksProvider } from "contexts/PatientLinksContext";
import { getPatientBlurbIsOpen, setPatientBlurbIsOpen } from "storage/patientBlurbUI";
import { PatientBlurb } from "components/PatientBlurbInfo/PatientBlurb";

import EmptyPatientSelected from "assets/images/empty-person-selected.svg";

export interface PatientSnapshotLayoutProps {
  afterAside?: ReactNode;
  patientId?: number;
  appointmentId?: number;
  children?: ReactNode;
  hipaaView?: boolean;
  emptyText?: string;
  onDeleteAppointment: (params: { patientId: number; appointmentId: number }) => void;
  onEditAppointment?: (appointmentId: number, patientId: number) => void;
  onAddAppointment?: (patientId: number) => void;
  onSelectAppointment: (appointment: AppointmentVO) => void;
  onViewAppointment?: (appointment: AppointmentVO) => void;
}

export const PatientSnapshotLayout: React.FC<PatientSnapshotLayoutProps> = ({
  children,
  afterAside,
  hipaaView,
  emptyText,
  patientId,
  appointmentId,
  onAddAppointment,
  onDeleteAppointment,
  onEditAppointment,
  onSelectAppointment,
  onViewAppointment,
}) => {
  const navAvatarId = useId();
  const isOpen = useBoolean(() => getPatientBlurbIsOpen());
  const showAvatar = isOpen.isOff;

  useEffect(() => {
    setPatientBlurbIsOpen(isOpen.isOn);
  }, [isOpen.isOn]);

  // We need to manually attach a listener because the
  // content inside the navAvatarId element is portaled
  // in. That means React event handling doesn't capture
  // events within this component so we need to use native
  // event handling
  const buttonRef = useRef<HTMLButtonElement | null>(null);

  const toggle = isOpen.toggle;

  useEffect(() => {
    const button = buttonRef.current;

    if (button) {
      button.addEventListener("click", toggle);

      return () => {
        button.removeEventListener("click", toggle);
      };
    }

    return noop;
  }, [toggle]);

  return (
    <PatientLinksProvider patientId={patientId}>
      <div className="h-full w-full flex relative">
        <div
          className={cx(
            "flex-none h-full transition-[width] will-change-[width]",
            isOpen.isOn ? "w-[296px]" : "w-0 overflow-hidden"
          )}
        >
          <div
            className={cx("h-full w-[296px] transition-opacity", isOpen.isOn ? "opacity-100" : "opacity-0")}
          >
            {patientId ? (
              <PatientBlurb
                patientId={patientId}
                appointmentId={appointmentId}
                navAvatarId={navAvatarId}
                hipaaView={hipaaView}
                onSelectAppointment={onSelectAppointment}
                onAddAppointment={onAddAppointment}
                onDeleteAppointment={onDeleteAppointment}
                onEditAppointment={onEditAppointment}
                onViewAppointment={onViewAppointment}
              />
            ) : (
              <div className="p-11">
                <EmptyContent
                  src={EmptyPatientSelected}
                  alt={emptyText || "No Patient Selected"}
                  text={emptyText || "No Patient Selected"}
                />
              </div>
            )}
          </div>
        </div>
        <div className="h-full flex-1 min-w-0 relative">
          <MainContent>
            <div className="h-full relative z-0">{children}</div>
          </MainContent>
        </div>
        {afterAside}
        <button
          ref={buttonRef}
          type="button"
          className={`
            absolute
            left-0
            bg-slate-200
            rounded-full
            h-6
            w-6
            flex
            items-center
            justify-center
            -translate-x-1/2
            top-[60px]
            z-10
            print:hidden
          `}
        >
          <div
            id={navAvatarId}
            className={cx(
              `absolute
               left-0
               -translate-x-full
               top-1/2
               -translate-y-1/2
               transition-opacity`,
              showAvatar ? "block opactity-100" : "opacity-0"
            )}
          />

          <Icon
            iconClassName={cx("transition-transform", isOpen.isOff && "-rotate-180")}
            size="xs"
            SvgIcon={Arrow}
          />
        </button>
      </div>
    </PatientLinksProvider>
  );
};
