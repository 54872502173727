import { FC } from "react";
import { FormConsentElementRequest, TemplateVariableGroupVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { useObjectState } from "@libs/hooks/useObjectState";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { EditElementFormProps } from "components/Settings/Forms/types";
import { FormWrapper } from "components/Settings/Forms/FormWrapper";
import { FormsRichTextEditor } from "components/Settings/Forms/FormsRichTextEditor";
import { InfoCheckbox } from "components/Settings/Forms/InfoCheckbox";
import { titleSchema } from "components/Settings/Forms/utils";

const consentSchema = {
  title: titleSchema,
  statement: [{ $v: required, $error: "Statement is required" }],
};

interface Props extends EditElementFormProps<FormConsentElementRequest> {
  templateVariables?: TemplateVariableGroupVO[];
}

export const ConsentForm: FC<Props> = ({
  element,
  templateVariables,
  isEditing,
  onDraftChange,
  onInvalid,
  onSubmit,
  onSecondaryClick,
}) => {
  const [draft, updateDraft, setDraft] = useObjectState(element);
  const validation = useValidation(draft, consentSchema);

  const handleSubmit = () => {
    onSubmit(draft);
    validation.reset();
  };

  return (
    <FormWrapper
      element={draft}
      isEditing={isEditing}
      contentClassName="flex flex-col"
      onValidate={() => validation.validate().$isValid}
      onSubmit={handleSubmit}
      onCancel={onSecondaryClick}
      onInvalid={onInvalid}
      onDraftChange={onDraftChange}
    >
      <div className="flex flex-col flex-1 min-h-0 gap-y-4">
        <FormFieldInput
          value={draft.title}
          required={true}
          error={validation.result.title.$error}
          label={
            <div>
              Title <span className="italic text-greyLight">Not visible to patients</span>
            </div>
          }
          onChange={(e) => updateDraft({ title: e.target.value })}
        />
        <FormsRichTextEditor
          className="flex-1 min-h-0"
          initialEditorState={draft.content}
          templateVariables={templateVariables}
          onChange={({ content }) => {
            updateDraft({ content });
          }}
        />
        <FormFieldInput
          label="Statement"
          value={draft.statement}
          required
          placeholder="e.g. I have read and understand the above."
          error={validation.result.statement.$error}
          onChange={(e) => updateDraft({ statement: e.target.value })}
        />
        <InfoCheckbox
          checked={Boolean(draft.acceptsInitials)}
          tooltip="By default the patient is required to enter their full name."
          label="Require initials only"
          onChange={() =>
            setDraft((last) => ({ ...last, acceptsInitials: last.acceptsInitials ? undefined : true }))
          }
        />
      </div>
    </FormWrapper>
  );
};
