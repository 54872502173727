import React, { useCallback } from "react";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as WarningIcon } from "@libs/assets/icons/warning.svg";
import { ButtonLink } from "@libs/components/UI/ButtonLink";
import { ReactComponent as DownloadIcon } from "@libs/assets/icons/download.svg";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isOneOf } from "@libs/utils/isOneOf";
import { ImagingHttpApi } from "api/imaging/imaging-hub";
import { useImagingHub } from "components/ImageCapturing/ImagingHubContext";
import { UpdateType } from "api/imaging/imaging-api";
import { handleError } from "utils/handleError";

type InternalProps = { updateType: UpdateType; onUpdate: Func };

export const AISUpdateWarning: React.FC<{ headerMode: boolean }> = ({ headerMode = false }) => {
  const { status: aisStatus } = useImagingHub();

  const updating = useBoolean(false);

  const handleUpdate = useCallback(async () => {
    try {
      await ImagingHttpApi.support.updateApp();
      updating.on();
    } catch (e) {
      handleError(e);
    }
  }, [updating]);

  const majorVer = Number(aisStatus.version[0]);

  // There is a bug prior to version 3, where the web request to update doesn't work, so don't show the warning
  // on those versions.
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const isVersionThreeOrLater = !Number.isNaN(majorVer) && majorVer >= 3;

  if (updating.isOn || isOneOf(aisStatus.updateType, ["None", "Unknown"]) || !isVersionThreeOrLater) {
    return null;
  }

  const Component = headerMode ? HeaderWarning : BannerWarning;

  return <Component updateType={aisStatus.updateType} onUpdate={handleUpdate} />;
};

const BannerWarning: React.FC<InternalProps> = ({ updateType, onUpdate }) => {
  const updateText = updateType === "Mandatory" ? "required" : "recommended";

  return (
    <div className="py-2 px-3 items-center text-xs bg-slate-100 flex gap-3">
      <Icon SvgIcon={WarningIcon} theme="slate700" />
      <div className="flex items-center justify-between w-full">
        <span>A {updateText} update is available for the Archy Imaging Service</span>
        <ButtonLink theme="link" onClick={onUpdate} target="_blank" size="small" className="cursor-pointer">
          <div className="flex items-center gap-3">
            Update
            <Icon SvgIcon={DownloadIcon} theme="primary" />
          </div>
        </ButtonLink>
      </div>
    </div>
  );
};

const HeaderWarning: React.FC<InternalProps> = ({ updateType, onUpdate }) => {
  const updateText = updateType === "Mandatory" ? "Required" : "Available";

  return (
    <div
      className={`
        py-1.5
        px-2
        items-center
        text-xs
        bg-slate-100
        flex
        rounded
        cursor-pointer
      `}
    >
      <ButtonLink theme="link" onClick={onUpdate} target="_blank" size="small">
        <div className="flex items-center">
          <Icon SvgIcon={WarningIcon} theme="primary" />
          <span className="mx-1">Update {updateText}</span>
        </div>
      </ButtonLink>
    </div>
  );
};
