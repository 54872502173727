import { compareDesc } from "date-fns";
import React, { useCallback } from "react";
import Skeleton from "react-loading-skeleton";
import { PatientInsuranceResponse, PatientInsuranceVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as ArchiveIcon } from "@libs/assets/icons/archive.svg";

type Props = {
  insurancesArchived: PatientInsuranceResponse[];
  isLoading: boolean;
  onClickArchivedInsurance: (insuranceId: number) => void;
  onClickViewArchived: () => void;
};

const LENGTH_OF_YEAR = 4;

type InsurancesByYear = Record<string, PatientInsuranceVO[] | undefined>;

const ARCHIVED_INSURANCE_NO_EXPIRATION = "9999";

export const ArchiveMenu: React.FC<Props> = ({
  insurancesArchived,
  isLoading,
  onClickArchivedInsurance,
  onClickViewArchived,
}) => {
  const archivedMenu = useBoolean(false);

  const { archivedYears, groupedInsurances } = React.useMemo(() => {
    const ordered = [...insurancesArchived];

    const groups: InsurancesByYear = {};

    ordered
      .sort((a: PatientInsuranceResponse, b: PatientInsuranceResponse) => {
        if (a.patientInsurance.expiryDate && b.patientInsurance.expiryDate) {
          return compareDesc(
            new Date(a.patientInsurance.expiryDate),
            new Date(b.patientInsurance.expiryDate)
          );
        } else if (a.patientInsurance.expiryDate) {
          return -1;
        }

        return 1;
      })
      .forEach((insurance) => {
        const year =
          insurance.patientInsurance.expiryDate?.slice(0, LENGTH_OF_YEAR) ?? ARCHIVED_INSURANCE_NO_EXPIRATION;

        groups[year]
          ? groups[year].push(insurance.patientInsurance)
          : (groups[year] = [insurance.patientInsurance]);
      });

    return { archivedYears: Object.keys(groups).map(Number).sort().reverse(), groupedInsurances: groups };
  }, [insurancesArchived]);

  const openMenu = useCallback(() => {
    onClickViewArchived();
    archivedMenu.on();
  }, [archivedMenu, onClickViewArchived]);

  return (
    <ButtonMenu
      isOpen={archivedMenu.isOn}
      onRequestClose={archivedMenu.off}
      onRequestOpen={openMenu}
      menuContent={
        <div
          className={`
            flex
            flex-col
            max-h-[calc(100vh-64px)]
            w-60
            text-slate-900
            overflow-y-auto
          `}
        >
          {isLoading ? (
            <Skeleton className="w-full" />
          ) : insurancesArchived.length ? (
            <>
              {archivedYears.map((year) =>
                groupedInsurances[year]?.length ? (
                  <div key={year}>
                    <div className="px-3 py-2 text-xxs font-sansSemiBold">
                      {year === Number(ARCHIVED_INSURANCE_NO_EXPIRATION) ? "No Eligibility End Date" : year}
                    </div>
                    {groupedInsurances[year].map((insurance) => (
                      <button
                        className={`
                          flex
                          flex-col
                          items-start
                          w-full
                          py-2
                          px-3
                          text-xs
                          hover:bg-slate-100
                        `}
                        key={insurance.id}
                        onClick={() => onClickArchivedInsurance(insurance.id)}
                        type="button"
                      >
                        <div className="text-left">{insurance.subscriber.carrier || ""}</div>
                        <div className="text-xxs text-slate-700">
                          {insurance.expiryDate ? formatISODate(insurance.expiryDate) : ""}
                        </div>
                      </button>
                    ))}
                  </div>
                ) : null
              )}
            </>
          ) : (
            <div className="py-2 px-3 text-xs">No Archived Insurance Plans</div>
          )}
        </div>
      }
    >
      {(props) => (
        <ButtonIcon
          {...props}
          SvgIcon={ArchiveIcon}
          tooltip={{ content: "Archived Insurance Plans", theme: "SMALL" }}
          theme="primary"
        />
      )}
    </ButtonMenu>
  );
};
