import { useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { MessageOverviewVO, MessageVO } from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { useAccount } from "@libs/contexts/AccountContext";
import { Panel } from "@libs/components/UI/Panel";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { MessagesTable } from "components/PatientProfile/Communication/MessagesTable";
import { MessageTab } from "components/PatientProfile/Communication/MessageTab";
import { CancelScheduledMessageModal } from "components/PatientProfile/Communication/CancelScheduledMessageModal";
import { MessagesQuery, getPatientMessagesOverview, getPatientMessages } from "api/messaging/queries";
import { usePathParams } from "hooks/usePathParams";
import { useItemModal } from "hooks/useItemModal";
import { PatientAppHistoryProvider } from "contexts/PatientLinksContext";

interface MessageTab {
  key: keyof MessageOverviewVO;
  type: MessagesQuery["type"];
  label: string;
}

const MessageTabs: MessageTab[] = [
  { key: "all", type: undefined, label: "All" },
  { key: "sms", type: "SMS", label: "SMS" },
  { key: "call", type: "CALL", label: "Call" },
  { key: "email", type: "EMAIL", label: "Email" },
];

export const PatientCommunicationRoute: React.FC = () => {
  const { practiceId } = useAccount();
  const { patientId } = usePathParams("patientCommunication");
  const [messageQueryType, setMessageQueryType] = useState<MessagesQuery["type"]>();
  const [patientMessagesOverviewQuery] = useApiQueries([
    getPatientMessagesOverview({ args: { practiceId, patientId } }),
  ]);

  const messagesQuery = useInfiniteApiQuery(
    getPatientMessages({
      args: {
        practiceId,
        patientId,
        type: messageQueryType,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
      },
    })
  );

  const queryClient = useQueryClient();
  const cancelScheduledMessageModal = useItemModal<MessageVO>(null);

  const handleCancelScheduledMessageSuccess = () => {
    queryClient.invalidateQueries([
      getQueryKey("practices", "getPatientMessages"),
      { practiceId, patientId },
    ]);
  };

  return (
    <PatientAppHistoryProvider patientId={patientId} name="communications">
      <Panel title="Communication" className="h-full overflow-hidden" includePadding={false}>
        <QueryResult queries={[patientMessagesOverviewQuery]}>
          <div className="flex items-center border-b border-b-greyLighter">
            {MessageTabs.map(({ key, type, label }) => (
              <MessageTab
                key={key}
                label={label}
                count={patientMessagesOverviewQuery.data?.[key] ?? 0}
                onTabSelect={() => setMessageQueryType(type)}
                isSelected={messageQueryType === type}
              />
            ))}
          </div>
        </QueryResult>

        <MessagesTable
          patientId={patientId}
          messageQueryType={messageQueryType}
          messagesQuery={messagesQuery}
          onRequestCancelScheduledMessage={cancelScheduledMessageModal.open}
        />

        {cancelScheduledMessageModal.isOpen ? (
          <CancelScheduledMessageModal
            message={cancelScheduledMessageModal.item}
            onRequestClose={cancelScheduledMessageModal.close}
            onCancelSuccess={handleCancelScheduledMessageSuccess}
          />
        ) : null}
      </Panel>
    </PatientAppHistoryProvider>
  );
};
