import React from "react";
import { ImageTransformDetails, MedicalImageVO } from "@libs/api/generated-api";
import { DEGREES_180, applyRotation } from "@libs/utils/math";
import { noop } from "@libs/utils/noop";
import { UseImageEditQueue } from "components/PatientProfile/Imaging/MountRoute/hooks/useImageEditQueue";

export const useImageManipulator = ({
  handleToggleImageArchived = noop,
  handleImageUpdate,
  persistToServer,
}: {
  handleToggleImageArchived?: (image: MedicalImageVO) => void;
  handleImageUpdate: UseImageEditQueue["handleImageUpdate"];
  persistToServer: boolean;
}) => {
  return React.useMemo(
    () => ({
      archiveImage: handleToggleImageArchived,
      rotateImage: (deg: number, image: MedicalImageVO) => {
        const mirror = image.transforms?.mirror ?? [];
        const newMirror = mirror.length > 0 ? (mirror.includes("X") ? ["Y"] : ["X"]) : mirror;

        handleImageUpdate(
          image,
          {
            transforms: {
              mirror: newMirror,
              rotationDegrees: applyRotation(deg, image.transforms?.rotationDegrees),
            },
          },
          persistToServer
        );
      },
      flipImage: (dimension: "X" | "Y", image: MedicalImageVO) => {
        const mirror = image.transforms?.mirror ?? [];
        const transforms: ImageTransformDetails = {
          rotationDegrees: image.transforms?.rotationDegrees ?? 0,
          mirror: mirror.includes(dimension)
            ? mirror.filter((item) => item !== dimension)
            : [...mirror, dimension],
        };

        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        if (transforms.mirror.length === 2) {
          // Double flip is the same as a 180 degree rotation
          transforms.rotationDegrees = applyRotation(DEGREES_180, image.transforms?.rotationDegrees);
          transforms.mirror = [];
        }

        handleImageUpdate(image, {
          transforms,
        });
      },
    }),
    [handleToggleImageArchived, handleImageUpdate, persistToServer]
  );
};
