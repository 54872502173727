import { useCallback, useState } from "react";
import { millisecondsToSeconds } from "date-fns";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { MedicalImage, useAttachments } from "components/Claim/Attachments/ClaimAttachmentsContext";
import { ImageWrapperComponentType } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ImageItem";
import {
  calculateOutlineSize,
  createImageWithRotation,
} from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { imagingUrlCache } from "components/PatientProfile/Imaging/utils/cache";

const CHECKBOX_OFFSET = 6;

export const ImageSelector: ImageWrapperComponentType = ({
  children,
  isDragging,
  image,
  isSelected,
  scale,
}) => {
  const { addMedicalImage, medicalImages, removeImage } = useAttachments();
  const isChecked = Boolean(medicalImages?.some((medicalImage) => medicalImage.sourceId === image.id));
  const [loaded, setLoaded] = useState(false);

  const inverseScale = 1 / scale;

  const rotation = image.transforms?.rotationDegrees || 0;
  const checkboxOffset = CHECKBOX_OFFSET / scale;

  const position = {
    top: checkboxOffset,
    right: checkboxOffset,
  };

  const showCheckbox = !isDragging && (isSelected || isChecked);

  // eslint-disable-next-line complexity
  const handleClick = useCallback(async () => {
    // Generate a new image if there is rotation applied. The same could be done
    // for filters if we support it later.
    let imageUrl = imagingUrlCache.getImageUrl(image);
    let uploadAsNew = false;

    if (!isChecked && imageUrl && rotation) {
      imageUrl = await createImageWithRotation(imageUrl, rotation, {
        width: image.w ?? 0,
        height: image.h ?? 0,
      });
      uploadAsNew = true;
    }

    const imageType = image.type === "PHOTO" ? "PHOTO" : "XRAY";

    const entryData: MedicalImage = {
      sourceId: image.id,
      sourceCreatedAt: millisecondsToSeconds(
        new Date(image.assignedDate || image.createdDate || Date.now()).getTime()
      ),
      data: imageUrl || "",
      isUploaded: uploadAsNew,
      type: imageType,
    };

    if (isChecked) {
      removeImage(entryData);
    } else {
      addMedicalImage(entryData);
    }
  }, [image, isChecked, rotation, removeImage, addMedicalImage]);

  return (
    <button
      onClick={loaded ? handleClick : undefined}
      type="button"
      className="w-full h-full outline-primaryTheme rounded"
      style={
        isChecked && !isDragging ? { outlineWidth: calculateOutlineSize(scale), outlineStyle: "solid" } : {}
      }
      // eslint-disable-next-line react/no-unknown-property
      onLoad={() => setLoaded(true)}
    >
      {children}
      {showCheckbox && loaded && (
        <div
          className="absolute bg-white rounded"
          style={{
            transform: `scale(${inverseScale})`,
            ...position,
          }}
        >
          <Checkbox
            className="w-3.5 h-3.5"
            readOnly
            checked={isChecked}
            onClick={(e) => e.stopPropagation()}
          />
        </div>
      )}
    </button>
  );
};
