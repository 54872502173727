import { FC, FormEvent, useMemo, useState } from "react";
import { DateWorktimeDetails } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { updateWorktimeEntry } from "api/employee/mutations";
import { EditWorktimeModal } from "components/EmployeeProfile/Timesheet/EditWorktimeModal";
import { handleError } from "utils/handleError";
import { WorktimeComment } from "components/EmployeeProfile/Timesheet/WorktimeComment";
import { TimeInput } from "components/UI/TimeInput";
import {
  commentValidation,
  getClockInValidation,
  getClockOutValidation,
  isoTimeValidation,
} from "components/EmployeeProfile/Timesheet/utils";

export interface EditApprovedTimeModalProps {
  employeeId: number;
  date: string;
  worktime: DateWorktimeDetails;
  onRequestClose: Func;
}

export const EditApprovedTimeModal: FC<EditApprovedTimeModalProps> = ({
  worktime,
  employeeId,
  onRequestClose,
  date,
}) => {
  const { practiceId } = useAccount();
  const [comment, setComment] = useState("");
  const [clockInTime, setClockInTime] = useState(worktime.clockIn.time);
  const [clockOutTime, setClockOutTime] = useState(worktime.clockOut.time);

  const schema = useMemo(() => {
    return {
      comment: [commentValidation],
      clockInTime: [isoTimeValidation, getClockInValidation(clockOutTime)],
      clockOutTime: [isoTimeValidation, getClockOutValidation(clockInTime)],
    };
  }, [clockInTime, clockOutTime]);

  const { result, validate } = useValidation({ comment, clockInTime, clockOutTime }, schema);

  const [updateWorktimeEntryMutation] = useApiMutations([updateWorktimeEntry]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validate().$isValid) {
      updateWorktimeEntryMutation.mutate(
        {
          practiceId,
          employeeId,
          worktimeId: worktime.id,
          data: {
            approvedClockIn: clockInTime,
            approvedClockOut: clockOutTime,
            approvalNotes: comment,
          },
        },
        {
          onSuccess: onRequestClose,
          onError: handleError,
        }
      );
    }
  };

  return (
    <EditWorktimeModal
      formId="edit-clock-in-out-form"
      onSubmit={handleSubmit}
      isLoading={updateWorktimeEntryMutation.isLoading}
      onRequestClose={onRequestClose}
      date={date}
      size="sm"
      confirmText="Approve"
    >
      <div className="grid grid-cols-2 gap-y-4">
        <TimeInput label="Original Clock-In" value={worktime.clockIn.orignalTime} edit={false} />
        <TimeInput label="Original Clock-Out" value={worktime.clockOut.orignalTime} edit={false} />
        <TimeInput label="Edited Clock-In" value={worktime.clockIn.time} edit={false} />
        <TimeInput label="Edited Clock-Out" value={worktime.clockOut.time} edit={false} />
        <TimeInput
          label="Approved Clock-In"
          required={true}
          value={clockInTime}
          onChange={setClockInTime}
          error={result.clockInTime.$error}
        />

        <TimeInput
          label="Approved Clock-Out"
          required={true}
          value={clockOutTime}
          onChange={setClockOutTime}
          error={result.clockOutTime.$error}
        />
      </div>

      <WorktimeComment
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        error={result.comment.$error}
      />
    </EditWorktimeModal>
  );
};
