import { cx } from "@libs/utils/cx";

export const EmptyDashboardTable: React.FC<{ className?: string; children: string }> = ({
  children,
  className,
}) => {
  return (
    <div
      className={cx(
        `h-32
         text-center
         flex
         items-center
         justify-center
         text-greyLight
         font-sansSemiBold`,
        className
      )}
    >
      {children}
    </div>
  );
};
