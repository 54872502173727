/*
This storage is used to store the params that are consumed to the post-sign-out
page. Cognito does not allow us to pass any query params to the sign-out
redirect, so we need to store values in local storage to be consumed by the
post-sign-out page.
*/
import { SECOND_IN_MS } from "@libs/utils/date";
import { wrapStorage } from "@libs/storage/wrapStorage";
import { StorageNamespaces } from "storage/namespaces";
import { PostSignOutParams } from "storage/types";

const version = "v1";

export const getPostSignOutStorage = (storage: Storage) => {
  const postSignOutStorage = wrapStorage<PostSignOutParams, StorageNamespaces>(
    StorageNamespaces.postSignOut,
    storage
  );

  return {
    getPostSignOutParams: () => {
      return postSignOutStorage.get("params", version);
    },
    setPostSignOutParams: (params: PostSignOutParams) => {
      return postSignOutStorage.set("params", params, {
        version,
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        expires: 10 * SECOND_IN_MS,
      });
    },
  };
};
