import { FC } from "react";

import { PatientSummaryVO } from "@libs/api/generated-api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as TextIcon } from "@libs/assets/icons/messages.svg";
import { ReactComponent as EmailIcon } from "@libs/assets/icons/email.svg";

import { VerticalDivider } from "@libs/components/UI/VerticalDivider";

import { RoleGuardHide } from "components/Main/RoleGuard";
import { MakePhoneCall } from "components/PatientBlurbInfo/MakePhoneCalls";
import { isTextingDisabled, isEmailingDisabled } from "components/Patient/contactModes";

interface Props {
  patientId: number;
  patientContact: PatientSummaryVO["contact"];
  onViewMessages: Func;
  onCopyEmail: Func;
}

const TextComponent: FC<Props> = ({ patientContact, onViewMessages }) => (
  <RoleGuardHide domain="COMMUNICATION" action="ACCESS_ALL">
    <ButtonIcon
      SvgIcon={TextIcon}
      onClick={onViewMessages}
      disabled={isTextingDisabled(patientContact)}
      tooltip={{
        content: patientContact.textPhone
          ? patientContact.contactModes.text
            ? "View Messages"
            : "Patient has not allowed text messages"
          : "Patient has not added a cell phone",
        theme: "SMALL",
      }}
    />
  </RoleGuardHide>
);

const CallComponent: FC<Props> = ({ patientId, patientContact }) => (
  <MakePhoneCall patientId={patientId} patientContact={patientContact} />
);

const EmailComponent: FC<Props> = ({ patientContact, onCopyEmail }) => (
  <ButtonIcon
    SvgIcon={EmailIcon}
    onClick={onCopyEmail}
    disabled={isEmailingDisabled(patientContact)}
    tooltip={{
      content: patientContact.email
        ? patientContact.contactModes.email
          ? "Copy Email"
          : "Patient has not allowed emails"
        : "Patient has not added an email address",
      theme: "SMALL",
    }}
  />
);

const preferredContactComponent = {
  TEXT: TextComponent,
  CALL: CallComponent,
  EMAIL: EmailComponent,
};

export const PatientContactButtons: FC<Props> = (props) => {
  const preferredContactMode = props.patientContact.preferredContactMode;
  const PreferredContactButton = preferredContactComponent[preferredContactMode];

  return (
    <div className="flex items-center gap-x-1.5">
      <PreferredContactButton {...props} />
      <VerticalDivider size="sm" />
      {preferredContactMode === "TEXT" ? null : <TextComponent {...props} />}
      {preferredContactMode === "CALL" ? null : <CallComponent {...props} />}
      {preferredContactMode === "EMAIL" ? null : <EmailComponent {...props} />}
    </div>
  );
};
