import { FC, MouseEventHandler, useId } from "react";
import { ReactDatePickerProps } from "react-datepicker";
import { format } from "date-fns";
import { cx } from "@libs/utils/cx";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { isDefined } from "@libs/utils/types";
import { useMergeFormContext, useFormContext } from "@libs/contexts/FormContext";
import { DatePickerSelectMenuHeader } from "@libs/components/UI/DatePickerSelectMenuHeader";
import { FloatingTooltipProps } from "@libs/components/UI/FloatingTooltip";
import { IconSizes } from "@libs/components/UI/Icon";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { FormField, FormFieldProps } from "@libs/components/UI/FormField";
import { cxFormFieldStyle } from "@libs/components/UI/formFieldStyle";
import { ReactComponent as CalendarIcon } from "@libs/assets/icons/calendar.svg";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { FormattedDateInput } from "@libs/components/UI/FormattedDateInput";
import { DatePickerBase } from "components/UI/DatePickerBase";

type FormFieldDatepickerProps = FormFieldProps & ReactDatePickerProps;

export interface FormFieldSelectMenusDatepickerProps extends Omit<FormFieldDatepickerProps, "value"> {
  readOnlyLabel?: (date: Date) => string | JSX.Element;
  Icon?: IconComponent;
  onClickIcon?: MouseEventHandler<HTMLButtonElement>;
  iconTooltip?: Omit<FloatingTooltipProps, "children">;
  iconSize?: IconSizes;
}

const MIN_YEAR = 1900;
const minDate = new Date(MIN_YEAR, 0, 1);
const maxValidDate = new Date("12/31/2099");
const isTest = process.env.NODE_ENV === "test";

// eslint-disable-next-line complexity
export const FormFieldSelectMenusDatepicker: FC<Omit<FormFieldSelectMenusDatepickerProps, "portalId">> = ({
  disabled,
  required,
  label,
  error,
  displayErrorMessage = true,
  isClearable,
  className,
  children,
  id,
  edit = true,
  layout,
  readOnlyLabel,
  maxDate,
  Icon,
  iconSize,
  onClickIcon,
  iconTooltip,
  ...datePickerOptions
}) => {
  const formContext = useFormContext();
  const fieldId = useEnsureId({ customId: id });
  const mergedFormContext = useMergeFormContext(formContext, { layout });
  const errorId = useId();

  return (
    <FormField
      disabled={disabled}
      required={required}
      label={label}
      errorId={errorId}
      error={error}
      displayErrorMessage={displayErrorMessage}
      edit={edit}
      layout={mergedFormContext.layout}
      className={cx(className)}
      id={fieldId}
    >
      <div className={cxFormFieldStyle.wrapper}>
        {edit ? (
          <>
            <DatePickerBase
              id={fieldId}
              className={cx(
                cxFormFieldStyle.control({
                  hasIcon: true,
                  hasLabel: Boolean(label),
                  layout: mergedFormContext.layout,
                }),
                cxFormFieldStyle.input
              )}
              placeholderText="__/__/____"
              calendarStartDay={0}
              showMonthDropdown
              showPopperArrow={false}
              showYearDropdown
              dropdownMode="select"
              ariaInvalid={error ? "true" : undefined}
              yearDropdownItemNumber={4}
              scrollableYearDropdown
              minDate={minDate}
              // We don't need to open the datepicker during tests because
              // we can just paste in date values we want to test without having to use
              // the UI which has hundreds of elements. When many date fields
              // are used in a form this can make a test run very slow
              {...(isTest ? { open: false } : undefined)}
              maxDate={maxDate || maxValidDate}
              {...datePickerOptions}
              disabled={disabled}
              popperClassName="react-datepicker-formfield"
              customInput={<FormattedDateInput aria-errormessage={error ? errorId : undefined} />}
              renderCustomHeader={(params) => (
                <DatePickerSelectMenuHeader
                  minDate={minDate}
                  maxDate={maxDate || maxValidDate}
                  params={params}
                />
              )}
            />
            {isClearable && isDefined(datePickerOptions.selected) && (
              <ButtonIcon
                className="absolute right-8 bottom-1 z-10"
                SvgIcon={CancelIcon}
                disabled={disabled}
                size="sm"
                theme="primary"
                onClick={() => datePickerOptions.onChange(null, undefined)}
              />
            )}
            <div
              className={cxFormFieldStyle.iconContainer({
                layout: mergedFormContext.layout,
                clickable: Boolean(onClickIcon),
              })}
            >
              {onClickIcon ? (
                <ButtonIcon
                  SvgIcon={Icon ?? CalendarIcon}
                  onClick={onClickIcon}
                  tooltip={iconTooltip}
                  disabled={disabled}
                  theme="primary"
                  size={iconSize ?? "sm"}
                />
              ) : Icon ? (
                <Icon className={cxFormFieldStyle.icon({ disabled })} />
              ) : (
                <CalendarIcon className={cxFormFieldStyle.icon({ disabled, size: iconSize ?? "sm" })} />
              )}
            </div>
            {children}
          </>
        ) : (
          <span
            aria-labelledby={fieldId}
            className={cxFormFieldStyle.controlValueOnly({ layout: mergedFormContext.layout })}
          >
            {datePickerOptions.selected
              ? readOnlyLabel
                ? readOnlyLabel(datePickerOptions.selected)
                : `${format(datePickerOptions.selected, "MM/dd/yyyy")}`
              : "-"}
          </span>
        )}
      </div>
    </FormField>
  );
};
