import { createLinksContext } from "contexts/AppLinksContext";
import { paths } from "utils/routing/paths";

export const getDefaultEmployeeLinks = (id: number) => ({
  personalInfo: paths.employee({ id }),
  timesheet: paths.employeeTimesheet({ id }),
  employment: paths.employeeEmployment({ id }),
  documents: paths.employeeDocuments({ id }),
  archyReferral: paths.archyReferral({ id }),
});

const { useLinks, LinksProvider, AppHistoryProvider } = createLinksContext(
  "Employee",
  getDefaultEmployeeLinks(0)
);

export const useEmployeeLinks = useLinks;
export const EmployeeLinksProvider = LinksProvider;
export const EmployeeAppHistoryProvider = AppHistoryProvider;
