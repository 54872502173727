import { MultiInvoicePaymentVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { cx } from "@libs/utils/cx";
import React, { useMemo } from "react";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { Icon } from "@libs/components/UI/Icon";
import { toMap } from "@libs/utils/array";

type Props = {
  multiInvoicePayment?: MultiInvoicePaymentVO;
  showSurchargeDisclosure?: boolean;
  className?: string;
  total: number;
  subtotal?: number;
  surchargeAmount?: number;
};

const Row: React.FC<{ label: string; value: string | number; bold?: boolean }> = ({ label, value, bold }) => {
  return (
    <div className={cx("flex flex-row justify-between text-xs w-full", bold && "font-sansSemiBold")}>
      <div>{label}</div>
      <div className="text-right">{value}</div>
    </div>
  );
};

export const SurchargeSummary: React.FC<Props> = ({
  multiInvoicePayment,
  surchargeAmount,
  subtotal,
  total,
  showSurchargeDisclosure,
}) => {
  const surchargeValue = surchargeAmount ?? 0;
  const subtotalValue = subtotal ?? 0;
  const invoiceMap = useMemo(() => {
    return toMap(multiInvoicePayment?.invoices ?? [], "uuid");
  }, [multiInvoicePayment?.invoices]);

  return (
    <div
      className={`
        pt-6
        border-t
        border-dotted
        border-slate-200
        flex
        flex-col
        gap-2
      `}
    >
      <div className="text-sm font-sansSemiBold pb-2">Summary</div>
      {multiInvoicePayment?.allocations?.map((allocation) => {
        const invoice = invoiceMap[allocation.invoiceUuid];

        return (
          <Row
            key={allocation.invoiceUuid}
            label={`Invoice #${invoice?.invoiceNumber}`}
            value={formatCurrency(allocation.paymentAllocationAmount)}
          />
        );
      })}

      {showSurchargeDisclosure ? (
        <>
          <Row bold label="Total" value={formatCurrency(subtotalValue)} />
          <div className="flex flex-row items-center gap-1 text-xs mt-3">
            <Icon SvgIcon={InfoIcon} size="sm" />
            Credit cards will incur an additional 3% surcharge.
          </div>
        </>
      ) : surchargeValue === 0 ? (
        <Row bold label="Total" value={formatCurrency(total)} />
      ) : (
        <>
          <Row label="Subtotal" value={formatCurrency(subtotalValue)} />
          <Row
            label={surchargeValue < 0 ? "Surcharge Refund" : "Surcharge Amount"}
            value={formatCurrency(surchargeValue)}
          />
          <Row bold label="Total" value={formatCurrency(total)} />
        </>
      )}
    </div>
  );
};
