import React from "react";
import { useNavigate } from "react-router-dom";
import { MountVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useAccount } from "@libs/contexts/AccountContext";
import { ModalFooterButtons } from "@libs/components/UI/ModalComponents";
import { generateMount } from "api/imaging/mutations";
import { ModalPage } from "components/UI/ModalPage";
import { paths } from "utils/routing/paths";
import { MountQueryUpdates } from "utils/routing/patient";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { handleError } from "utils/handleError";
import { MountModalSection } from "components/PatientProfile/Imaging/PatientMountsList/MountModalSection";
import {
  MountLayoutConfig,
  basicMounts,
  basicMounts2,
  bitewingMounts,
  fmxMounts,
} from "components/PatientProfile/Imaging/PatientMountsList/mountLayouts";
import { TWO_CLICKS } from "utils/handleDoubleClick";

const MountTypeRow: React.FC<{
  mounts: MountLayoutConfig[];
  selectedLayout?: MountLayoutConfig;
  onClick: (config: MountLayoutConfig) => void;
  onDoubleClick: (config: MountLayoutConfig) => void;
}> = ({ mounts, selectedLayout, onClick, onDoubleClick }) => {
  return (
    <div className="flex text-center gap-2">
      {mounts.map((mount) => {
        return (
          <button
            type="button"
            className={cx(
              "font-sansSemiBold text-xs outline-none",
              selectedLayout?.type === mount.type && "text-primaryTheme"
            )}
            onClick={(e) => {
              onClick(mount);

              if (e.detail === TWO_CLICKS) {
                onDoubleClick(mount);
              }
            }}
            onKeyUp={() => onClick(mount)}
            key={mount.type}
          >
            <img
              src={mount.icon}
              alt={`${mount.label}`}
              className={cx(
                "w-full mb-2 rounded-md",
                selectedLayout?.type === mount.type && "outline outline-4 outline-primaryTheme"
              )}
            />
            <div>{mount.label}</div>
          </button>
        );
      })}
    </div>
  );
};

export const NewMountModalPage: React.FC = () => {
  const { practiceId } = useAccount();
  const { patientId } = usePathParams("newMount");
  const { query } = useQueryParams("newMount");
  const navigate = useNavigate();
  const backUrl = paths.patientTab({ tab: "imaging", patientId });
  const [selectedLayout, setSelectedLayout] = React.useState<MountLayoutConfig | undefined>(undefined);
  const onMountCreated = (mount: MountVO) => {
    const params: MountQueryUpdates = {};

    if (query.deviceId) {
      params.deviceId = query.deviceId;
    } else {
      params.import = true;
    }

    navigate(paths.mountDetails({ mountId: mount.id, patientId }, params), { replace: true });
  };

  const [newMountName, setNewMountName] = React.useState<string>("");
  const [createMountMutation] = useApiMutations([generateMount]);

  const onSubmitCreate = (layout: MountLayoutConfig) => {
    createMountMutation.mutate(
      {
        practiceId,
        patientId,
        data: {
          layout: layout.type,
          name: newMountName === "" ? layout.label : newMountName,
        },
      },
      {
        onError: handleError,
        onSuccess: (data) => {
          onMountCreated(data.data.data);
        },
      }
    );
  };
  const handleTypeClicked = (config: MountLayoutConfig) => {
    setSelectedLayout(config);
  };
  const mountListProps = {
    onClick: handleTypeClicked,
    onDoubleClick: (config: MountLayoutConfig) => {
      handleTypeClicked(config);
      onSubmitCreate(config);
    },
    selectedLayout,
  };

  return (
    <ModalPage
      closeLink={backUrl}
      title="New Mount"
      actions={
        <ModalFooterButtons>
          <ButtonInternalLink className="w-24" replace theme="secondary" to={backUrl}>
            Cancel
          </ButtonInternalLink>
          <AsyncButton
            type="submit"
            className="w-24"
            theme="primary"
            disabled={!selectedLayout}
            isLoading={createMountMutation.isLoading}
            onClick={() => {
              if (selectedLayout) {
                onSubmitCreate(selectedLayout);
              }
            }}
          >
            Continue
          </AsyncButton>
        </ModalFooterButtons>
      }
    >
      <div className="overflow-x-auto">
        <div className="p-4 flex flex-col gap-4 min-w-[74rem]">
          <MountModalSection title="Name">
            <FormFieldInput
              layout="labelOut"
              placeholder="Optional"
              className="max-w-[24rem]"
              onChange={(event) => {
                setNewMountName(event.target.value);
              }}
              value={newMountName}
            />
          </MountModalSection>
          <MountModalSection title="Mount">
            <MountTypeRow mounts={basicMounts} {...mountListProps} />
            <MountTypeRow
              mounts={basicMounts2.filter((_, i) => i < basicMounts2.length)}
              {...mountListProps}
            />
            <MountTypeRow
              mounts={bitewingMounts.filter((_, i) => i < bitewingMounts.length)}
              {...mountListProps}
            />
            <MountTypeRow mounts={fmxMounts} {...mountListProps} />
          </MountModalSection>
        </div>
      </div>
    </ModalPage>
  );
};
