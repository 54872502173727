import { FC, FormEventHandler, useRef, useState } from "react";
import { PermissionActionVO, RoleRequest } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { flushSync } from "react-dom";
import { ModalContent, ModalForm } from "@libs/components/UI/ModalComponents";
import { scrollToFirstError } from "utils/scrollToFirstError";
import { RoleForm } from "./RoleForm";

export type RoleFormContentProps = {
  formId: string;
  getInitialState: () => RoleRequest;
  allPermissions: PermissionActionVO[];
  viewOnly: boolean;
  onSave: (request: RoleRequest) => void;
};

export const RoleFormContent: FC<RoleFormContentProps> = ({
  formId,
  allPermissions,
  getInitialState,
  viewOnly,
  onSave,
}) => {
  const [draftRole, setDraftRole] = useState(getInitialState);

  const formRef = useRef<HTMLDivElement>(null);

  const validation = useValidation(draftRole, {
    name: [{ $v: required, $error: "Name is required" }],
  });

  const handleSave: FormEventHandler = (e) => {
    e.preventDefault();

    const result = flushSync(() => validation.validate());

    if (result.$isValid) {
      onSave(draftRole);
    } else {
      scrollToFirstError(formRef.current);
    }
  };

  return (
    <div className="flex justify-center pt-6 pb-12">
      <ModalContent className="max-w-xl">
        <ModalForm id={formId} onSubmit={handleSave}>
          <div ref={formRef}>
            <RoleForm
              viewOnly={viewOnly}
              allPermissions={allPermissions}
              draftRole={draftRole}
              onDraftRoleChange={setDraftRole}
              errors={{ name: validation.result.name.$error }}
            />
          </div>
        </ModalForm>
      </ModalContent>
    </div>
  );
};
