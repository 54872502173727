import React from "react";
import {
  ProviderRollupEntryVO,
  ProviderRollupResponse,
  ProviderRollupSubtotalVO,
} from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { FilteredProvider } from "components/Dashboard/types";

export type ProviderFilterOption = {
  title: string;
  provider: FilteredProvider;
  value: number;
  items?: ProviderFilterOption[];
};

const makeProviderSection = ({
  title,
  jobCategory,
  entries,
  subtotals,
  selectedProvider,
}: {
  title: string;
  jobCategory: FilteredProvider["jobCategory"];
  entries?: ProviderRollupEntryVO[];
  subtotals?: ProviderRollupSubtotalVO[];
  selectedProvider?: ProviderRollupEntryVO;
  shortTitle?: string;
}) => {
  const sumValue = subtotals?.find((item) => item.jobCategory === jobCategory)?.value ?? 0;

  if (!entries || entries.length === 0) {
    return undefined;
  }

  const result = {
    title,
    value: sumValue,
    provider: {
      jobCategory,
    },
    items: entries
      .filter((item) => item.jobCategory === jobCategory)
      .map((item) => ({
        ...item,
        title: item.provider.shortDisplayName,
        provider: {
          jobCategory: item.jobCategory as FilteredProvider["jobCategory"],
          name: item.provider,
        },
      })) as ProviderFilterOption[],
  };

  // In some cases, no results will be available for the selected provider, when switching other parameters (date for example).
  // This will always inject the selected provider into the list of providers, if applicable
  if (
    selectedProvider?.jobCategory === jobCategory &&
    selectedProvider?.provider.id &&
    !result.items.some((item) => item.provider.name?.id === selectedProvider.provider.id)
  ) {
    result.items.push({
      title: selectedProvider.provider.shortDisplayName,
      provider: {
        jobCategory: selectedProvider.jobCategory as FilteredProvider["jobCategory"],
        name: selectedProvider.provider,
      },
      value: 0,
    });

    result.items.sort((a, b) => {
      return a.title > b.title ? 1 : b.title > a.title ? -1 : 0;
    });
  }

  return result.items.length > 0 ? result : undefined;
};

export const getDataByProvider = (
  providersInTimeWindow?: ProviderRollupResponse,
  selectedProvider?: ProviderRollupEntryVO
) => {
  const totalSection = {
    title: "Total",
    provider: { jobCategory: undefined },
    value: providersInTimeWindow?.total.value ?? 0,
  };

  const practiceEntry = providersInTimeWindow?.entries.find(({ jobCategory }) => jobCategory === "PRACTICE");
  const practiceSubtotal = providersInTimeWindow?.subtotals.find(
    ({ jobCategory }) => jobCategory === "PRACTICE"
  );
  const creditToPracticeSection =
    practiceEntry && practiceSubtotal
      ? {
          title: practiceEntry.provider.fullDisplayName,
          provider: { jobCategory: "PRACTICE" as const },
          value: practiceSubtotal.value,
        }
      : undefined;

  const sectionArgs = {
    selectedProvider,
    entries: providersInTimeWindow?.entries,
    subtotals: providersInTimeWindow?.subtotals,
  };
  const doctorsSection = makeProviderSection({
    ...sectionArgs,
    title: "All Doctors",
    shortTitle: "Doctors",
    jobCategory: "DENTIST",
  });
  const hygienistSection = makeProviderSection({
    ...sectionArgs,
    title: "All Hygienists",
    shortTitle: "Hygienists",
    jobCategory: "HYGIENIST",
  });
  const frontOfficeSection = makeProviderSection({
    ...sectionArgs,
    title: "All Front Office",
    shortTitle: "Front Office",
    jobCategory: "FRONT_OFFICE",
  });
  const backOfficeSection = makeProviderSection({
    ...sectionArgs,
    title: "All Back Office",
    shortTitle: "Back Office",
    jobCategory: "BACK_OFFICE",
  });

  const result: ProviderFilterOption[] = [
    totalSection,
    creditToPracticeSection,
    doctorsSection,
    hygienistSection,
    frontOfficeSection,
    backOfficeSection,
  ].filter(isDefined);

  return result;
};

export const useFormattedProviderRollups = (
  providersInTimeWindowQuery: ApiQueryResult<ProviderRollupResponse>,
  allProvidersQuery: ApiQueryResult<ProviderRollupResponse>,
  options: {
    providerId?: number;
  }
) => {
  const isLoading = providersInTimeWindowQuery.isLoading || allProvidersQuery.isLoading;

  const selectedProvider = React.useMemo(
    () => (allProvidersQuery.data?.entries ?? []).find((item) => item.provider.id === options.providerId),
    [allProvidersQuery.data?.entries, options.providerId]
  );
  const providersInTimeWindow = providersInTimeWindowQuery.data;
  const dataByProvider = React.useMemo(() => {
    return getDataByProvider(providersInTimeWindow, selectedProvider);
  }, [providersInTimeWindow, selectedProvider]);

  return {
    data: dataByProvider,
    isLoading,
    selectedProvider,
  };
};
