import React, { useMemo } from "react";
import { AnimatedLineSeries, LineSeries } from "@visx/xychart";
import { scaleOrdinal } from "@visx/scale";
import { ColorScheme, TIMESERIES_LINECHART_ACCESSORS } from "components/Dashboard/Charting/theme";
import { LineSeriesData } from "components/Dashboard/Charting/types";

export interface Props<T extends string> {
  data: LineSeriesData<T>;
  colorScheme: ColorScheme<T>;
  keys: T[];
}

const ANIMIATION_MAX_DATA_POINTS = 300;

export const DashboardLineChart = <T extends string>({ data, colorScheme, keys }: Props<T>) => {
  const colorScale = React.useMemo(() => {
    return scaleOrdinal<T, string>(colorScheme);
  }, [colorScheme]);

  const dataLength = useMemo(() => {
    return keys.length > 1 ? data[keys[0]].length : 1;
  }, [data, keys]);

  const LineComponent = dataLength > ANIMIATION_MAX_DATA_POINTS ? LineSeries : AnimatedLineSeries;

  return (
    <>
      {keys.map((key) => {
        const series = data[key];
        const color = colorScale(key);

        return (
          <LineComponent
            key={key}
            colorAccessor={() => color}
            dataKey={key}
            data={series}
            {...TIMESERIES_LINECHART_ACCESSORS}
          />
        );
      })}
    </>
  );
};
