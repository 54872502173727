import { JournalEventVO } from "@libs/api/generated-api";
import { labelForPaymentMethod } from "components/Dashboard/PaymentCollected/utils";

// eslint-disable-next-line complexity
export const getJournalRowDescription = (row: JournalEventVO): string => {
  const { dentalProcedure, carrier, adjustmentName, eventType, paymentMethod, adjustmentNote } = row;

  switch (eventType) {
    case "INSURANCE_ADJUSTMENT":
    case "PRODUCTION": {
      if (!dentalProcedure) {
        break;
      }

      return `${dentalProcedure.cdtCode} - ${dentalProcedure.name}`;
    }
    case "PATIENT_PAYMENT": {
      if (!paymentMethod) {
        break;
      }

      return labelForPaymentMethod(paymentMethod);
    }
    case "INSURANCE_PAYMENT": {
      if (!carrier) {
        break;
      }

      return carrier.name;
    }

    case "COLLECTION_ADJUSTMENT": {
      if (!adjustmentName) {
        break;
      }

      return `${adjustmentName}${adjustmentNote ? ` - ${adjustmentNote}` : ""}`;
    }
    default: {
      break;
    }
  }

  return "Unknown";
};
