import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { mergeRegister } from "@lexical/utils";
import { LineBreakNode } from "lexical";

import { FC, useEffect } from "react";

export const PreventNewLinesPlugin: FC = () => {
  const [editor] = useLexicalComposerContext();

  useEffect(() => {
    return mergeRegister(
      editor.registerNodeTransform(LineBreakNode, (node) => {
        node.remove();
      })
    );
  }, [editor]);

  return null;
};
