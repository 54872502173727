import { useEffect, useState } from "react";

/**
 * This hook helps prevent loading UI indicators from flickering when a set of
 * queries are run in sequence. For example, you might have a query that depends
 * on another to run. When the first query ends, spinners, overlays, and other
 * loading indicators will be turned off, only to be turned on immediately after
 * the second query starts on the next re-render, causing a flickering effect.
 * This hook prevents that by keeping the loading state true until all chained
 * queries are complete, as long as those queries run within a re-render from
 * each other.
 *
 * @param {boolean} isLoading - The current loading state.
 * @returns {boolean} The continued loading state.
 *
 * @example
 * +-----------+---------------+--------+-+------------------------------------------+
 * | isLoading | deferredValue | output | description                                |
 * +-----------+---------------+--------+--------------------------------------------+
 * | false     | false         | false  | no queries are running                     |
 * | true      | false         | true   | 1st query starts, deferred lags as false   |
 * | true      | true          | true   | next re-render occurs, deferred turns true |
 * | false     | true          | true   | 1st query ends, deferred lags as true      |
 * | true      | false         | true   | 2nd query starts, deferred lags as false   |
 * | true      | true          | true   | 2nd query runs, deferred is true again     |
 * | false     | true          | true   | 2nd query ends, deferred lags as true      |
 * | false     | false         | false  | next re-render occurs, deferred is false   |
 * |           |               |        | and loading is finally complete            |
 * +-----------+---------------+--------+--------------------------------------------+
 */
export const useContinuedLoading = (isLoading: boolean) => {
  const [deferredValue, setDeferredValue] = useState(isLoading);

  useEffect(() => {
    setDeferredValue(isLoading);
  }, [isLoading]);

  return isLoading || deferredValue;
};
