import { ClaimVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as VerticalMenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { ClaimModalFooter } from "components/Claim/ModalFooter";
import { canProcessAdditionalPaymentForClaim, isVoidedClaim } from "components/Claims/utils";

interface Props {
  claim: ClaimVO;
  isDraft: boolean;
  isPreAuth: boolean;
  isSubmitted: boolean;
  isSubmitting: boolean;
  disableActions: boolean;
  onCancelClick: Func;
  onCreateDraftEobPayment: Func;
  onResubmitClick: Func;
  onVoidClick: Func;
}

// eslint-disable-next-line complexity
export const FooterSent: React.FC<Props> = ({
  claim,
  isDraft,
  isPreAuth,
  isSubmitted,
  isSubmitting,
  disableActions,
  onCancelClick,
  onCreateDraftEobPayment,
  onResubmitClick,
  onVoidClick,
}) => {
  const menu = useBoolean(false);

  const canResubmit =
    !isDraft &&
    !isPreAuth &&
    !claim.replacementClaimUuid &&
    !claim.draftEobPaymentUuid &&
    !isVoidedClaim(claim);

  const canProcessEobPayment =
    isSubmitted && !isPreAuth && !claim.replacementClaimUuid && !claim.draftEobPaymentUuid;

  const canVoid = isSubmitted && !isPreAuth && !claim.replacementClaimUuid;

  const canProcessAdditionalPayment = canProcessAdditionalPaymentForClaim(claim);

  const hasExtendedMenu = canResubmit && canVoid;

  return canResubmit || canProcessEobPayment || canProcessAdditionalPayment || hasExtendedMenu ? (
    <ClaimModalFooter>
      <Button className="w-[120px]" onClick={onCancelClick} theme="secondary">
        Cancel
      </Button>
      {canResubmit && !hasExtendedMenu && (
        <Button
          className="w-[120px]"
          disabled={disableActions}
          onClick={onResubmitClick}
          theme={canProcessEobPayment ? "secondary" : "primary"}
        >
          Resubmit
        </Button>
      )}
      {canProcessEobPayment && (
        <AsyncButton
          disabled={disableActions}
          isLoading={isSubmitting}
          onClick={onCreateDraftEobPayment}
          theme="primary"
        >
          Process EOB Payment
        </AsyncButton>
      )}
      {hasExtendedMenu && (
        <ButtonMenu
          isOpen={menu.isOn}
          onRequestOpen={menu.on}
          placement="bottom-start"
          menuContent={
            <>
              <MenuOptionButton
                onClick={() => {
                  onResubmitClick();
                  menu.off();
                }}
                disabled={disableActions}
              >
                Resubmit
              </MenuOptionButton>
              <MenuOptionButton
                onClick={() => {
                  onVoidClick();
                  menu.off();
                }}
                disabled={disableActions}
              >
                Void
              </MenuOptionButton>
            </>
          }
          onRequestClose={menu.off}
        >
          {(props) => <ButtonIcon {...props} size="md" SvgIcon={VerticalMenuIcon} theme="primary" />}
        </ButtonMenu>
      )}
      {canProcessAdditionalPayment && (
        <div className="absolute right-0 mr-12 text-sm">
          <Button disabled={disableActions} onClick={onCreateDraftEobPayment} theme="link">
            Additional Payment
          </Button>
        </div>
      )}
    </ClaimModalFooter>
  ) : null;
};
