import { useMemo } from "react";
import { useLocation } from "react-router-dom";
import { YEAR_IN_MS } from "@libs/utils/date";
import { getFullUrl } from "@libs/utils/location";
import { wrapStorage } from "@libs/storage/wrapStorage";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { StorageNamespaces } from "storage/namespaces";

const STORED_VALUE = "";
const KEY = "persist-ledger-items";
const version = "v1";

type PersistLedgerItemsStorageData = {
  [key: string]: typeof STORED_VALUE;
};

const getExpandedLedgerItemsStorage = (pageKey: string, storage: Storage) => {
  const billingStorage = wrapStorage<PersistLedgerItemsStorageData, StorageNamespaces>(
    StorageNamespaces.billing,
    storage
  );

  const clearLedgerExpandables = () => {
    billingStorage.clear(pageKey);
  };

  const isLedgerItemExpanded = (expandableId: string) => {
    return expandableId in (billingStorage.get(pageKey, version) ?? {});
  };

  const expandLedgerItem = (ledgerItemId: string) => {
    const data = billingStorage.get(pageKey, version) ?? {};

    data[ledgerItemId] = STORED_VALUE;

    billingStorage.set(pageKey, data, { version, expires: YEAR_IN_MS });
  };

  const collapseLedgerItem = (ledgerItemId: string) => {
    const data = billingStorage.get(pageKey, version) ?? {};

    delete data[ledgerItemId];

    if (Object.keys(data).length === 0) {
      billingStorage.clear(pageKey);

      return;
    }

    billingStorage.set(pageKey, data, { version, expires: YEAR_IN_MS });
  };

  return { clearLedgerExpandables, isLedgerItemExpanded, expandLedgerItem, collapseLedgerItem };
};

export const useExpandedLedgerItemsStorage = () => {
  const location = useLocation();
  const pageUrl = getFullUrl(location);
  const { sessionStorage } = useStorageContext();

  return useMemo(() => {
    const pageKey = `${KEY}:${pageUrl}`;

    return getExpandedLedgerItemsStorage(pageKey, sessionStorage);
  }, [pageUrl, sessionStorage]);
};
