import { FormEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { FloatingTooltipProps } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as MergeIcon } from "@libs/assets/icons/merge.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryFilters } from "@libs/components/UI/QueryFilters";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import {
  InsurancePlanDetailsQuery,
  InsurancePlansQuery,
  InsurancePlansQueryUpdates,
  PlansSortKeys,
} from "utils/routing/settings";
import { getAllInsuranceCarriersQuery, getInfiniteInsurancePlans } from "api/practiceInsurance/queries";
import { getInsurancePlanFilterProps } from "components/Settings/InsurancePlans/insurancePlansQuery";
import { InsurancePlansTable } from "components/Settings/InsurancePlans/Table";
import { FiltersFlyoverContent } from "components/Settings/InsurancePlans/FiltersFlyoverContent";
import { QueryFiltersFlyover } from "components/UI/QueryFiltersFlyover";
import { SettingsListPanel } from "components/Settings/SettingsListPanel";
import { MergePlansModal } from "./MergePlansModal";

const MINIMUM_PLANS_TO_MERGE = 2;

export const InsurancePlansRoute: React.FC = () => {
  const { practiceId } = useAccount();
  const navigate = useNavigate();

  const { query, updateQuery } = useQueryParams("insurancePlans");
  const [queryForFilters, setQueryForFilters] = useState<InsurancePlansQuery>(query);
  const [selectedPlanUuids, setSelectedPlanUuids] = useState<Set<string>>(() => new Set());
  const mergeModal = useBoolean(false);

  const handleFiltersQueryParamChange = useCallback((keyValues: InsurancePlansQueryUpdates) => {
    setQueryForFilters((last) => ({ ...last, ...keyValues }));
  }, []);

  const handleQueryParamChange = useCallback(
    (keyValues: InsurancePlansQueryUpdates) => {
      updateQuery("replaceIn", keyValues);
      handleFiltersQueryParamChange(keyValues);
    },
    [handleFiltersQueryParamChange, updateQuery]
  );

  const initialLoadQueryRef = useRef(false);

  // The first time we go to the insurance page, we want to set the default
  // query params for hidePlansWithNoPatients to be true. However, if they paste
  // a URL w/ queries, we ignore this.
  useEffect(() => {
    if (!query.hidePlansWithNoPatients && !initialLoadQueryRef.current && !query.from) {
      handleQueryParamChange({ hidePlansWithNoPatients: true });
      initialLoadQueryRef.current = true;
    }
  }, [handleQueryParamChange, query]);

  const handleNavigateToDetailsPage = useCallback(
    (planUuid: string, queryParams?: InsurancePlanDetailsQuery) => {
      navigate(paths.insurancePlanDetails({ planUuid }, queryParams));
    },
    [navigate]
  );

  const [carriersQuery] = useApiQueries([getAllInsuranceCarriersQuery({ args: { practiceId } })]);

  const insurancePlansQueryKey = useMemo(
    () => ({
      ...query,
      orderBy: query.sortDirection,
    }),
    [query]
  );

  const insurancePlansInfiniteQuery = useInfiniteApiQuery(
    getInfiniteInsurancePlans({
      args: {
        ...insurancePlansQueryKey,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        practiceId,
      },
      queryOptions: { keepPreviousData: true },
    })
  );

  const totalElements = getInfiniteQueryPagingDetails(insurancePlansInfiniteQuery.data)?.totalElements ?? 0;

  const insurancePlans = useFlattenPages(insurancePlansInfiniteQuery.data);

  const filterProps = useMemo(() => {
    return getInsurancePlanFilterProps(carriersQuery.data, insurancePlans, query);
  }, [carriersQuery.data, insurancePlans, query]);

  const flyover = useBoolean(false);
  const closeFlyover = flyover.off;
  const applyFilters: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();

      handleQueryParamChange(queryForFilters);

      closeFlyover();
    },
    [handleQueryParamChange, queryForFilters, closeFlyover]
  );

  const handleCopyClick = useCallback(
    (planUuid: string) => {
      handleNavigateToDetailsPage("new", { copyUuid: planUuid });
    },
    [handleNavigateToDetailsPage]
  );

  const handleSort = useCallback(
    (sortColumn: PlansSortKeys) => {
      if (query.sortColumn !== sortColumn) {
        handleQueryParamChange({ sortDirection: "ASCENDING", sortColumn });
      } else if (query.sortDirection === "ASCENDING") {
        handleQueryParamChange({ sortDirection: "DESCENDING" });
      } else {
        handleQueryParamChange({ sortDirection: "ASCENDING" });
      }
    },
    [handleQueryParamChange, query.sortDirection, query.sortColumn]
  );

  const handleSelectToggle = useCallback(
    (planUuid: string) => {
      setSelectedPlanUuids((last) => {
        const newSet = new Set(last);

        if (newSet.has(planUuid)) {
          newSet.delete(planUuid);
        } else {
          newSet.add(planUuid);
        }

        return newSet;
      });
    },
    [setSelectedPlanUuids]
  );

  const handleSelectAllToggle = useCallback(() => {
    setSelectedPlanUuids((last) => {
      if (last.size === insurancePlans?.length) {
        return new Set();
      }

      return new Set(insurancePlans?.map((plan) => plan.uuid));
    });
  }, [insurancePlans, setSelectedPlanUuids]);

  const arePlansMergeable = useMemo(() => {
    // Iterate over each selected plan and ensure they all have the same carrier
    // and group number. If they do, we can merge them.
    if (selectedPlanUuids.size < MINIMUM_PLANS_TO_MERGE) {
      return false;
    }

    const selectedPlansUuidsArray = [...selectedPlanUuids];
    const firstPlan = insurancePlans?.find((plan) => plan.uuid === selectedPlansUuidsArray[0]);

    if (!firstPlan) {
      return false;
    }

    return selectedPlansUuidsArray.every((planUuid) => {
      const matchingPlan = insurancePlans?.find((plan) => plan.uuid === planUuid);

      return (
        matchingPlan?.carrier.id === firstPlan.carrier.id &&
        matchingPlan.groupNumber === firstPlan.groupNumber
      );
    });
  }, [insurancePlans, selectedPlanUuids]);

  const mergeTooltip: Omit<FloatingTooltipProps, "children"> = useMemo(
    () =>
      arePlansMergeable
        ? { content: "Merge Plans", theme: "SMALL" }
        : { content: "Select plans with the same carrier and group number to merge", theme: "MEDIUM" },
    [arePlansMergeable]
  );

  return (
    <SettingsListPanel title="Insurance Plans">
      <QueryFilters
        bulkActions={[
          <ButtonIcon
            tooltip={mergeTooltip}
            disabled={!arePlansMergeable}
            key="merge"
            SvgIcon={MergeIcon}
            onClick={mergeModal.on}
          />,
        ]}
        onOpenFlyover={flyover.on}
        onUpdateParams={handleQueryParamChange}
        queries={[insurancePlansInfiniteQuery]}
        searchParamKey="searchString"
        totalElements={totalElements}
        {...filterProps}
      />
      <InsurancePlansTable
        insurancePlansInfiniteQuery={insurancePlansInfiniteQuery}
        insurancePlansQueryKey={insurancePlansQueryKey}
        selectedPlans={selectedPlanUuids}
        onCopy={handleCopyClick}
        onEdit={handleNavigateToDetailsPage}
        onSort={handleSort}
        onSelectToggle={handleSelectToggle}
        onSelectAllToggle={handleSelectAllToggle}
        query={query}
      />
      {flyover.isOn && (
        <QueryFiltersFlyover
          content={
            <FiltersFlyoverContent
              carriersQuery={carriersQuery}
              onUpdateFilters={handleFiltersQueryParamChange}
              queryStateApi={queryForFilters}
            />
          }
          isSubmitting={insurancePlansInfiniteQuery.isFetching}
          onClose={closeFlyover}
          onSubmit={applyFilters}
        />
      )}
      {mergeModal.isOn && (
        <MergePlansModal
          onMerge={() => {
            mergeModal.off();
            setSelectedPlanUuids(new Set());
          }}
          onClose={mergeModal.off}
          plansToMerge={insurancePlans?.filter((plan) => selectedPlanUuids.has(plan.uuid)) ?? []}
        />
      )}
    </SettingsListPanel>
  );
};
