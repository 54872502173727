import React from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import {
  ArchyServiceSensorWithSettings,
  TwainDeviceWithSettings,
} from "components/PatientProfile/Imaging/hooks/useImagingDevices";
import { ImageCapturePlaceholder } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ImageCapturePlaceholder";
import { CalibrationCaptureHeader } from "components/ImageCapturing/Calibration/CalibrationCaptureHeader";

import { handleError } from "utils/handleError";
import { useSensorSampleImage } from "components/PatientProfile/Imaging/MountRoute/hooks/useSensorSampleImage";

type Props = {
  device: TwainDeviceWithSettings | ArchyServiceSensorWithSettings;
  onImageCaptured: (image: { url: string; w: number; h: number; cleanup?: Func }) => void;
  from: string;
};

export const CalibrationSensorCapture: React.FC<Props> = ({ device, onImageCaptured, from }) => {
  const sampleImageData = useSensorSampleImage();
  const capturing = useBoolean(false);
  const captureSampleImage = sampleImageData.captureSampleImage;
  const captureState = "captureState" in sampleImageData ? sampleImageData.captureState : undefined;

  const handleCaptureClicked = React.useCallback(async () => {
    try {
      capturing.on();

      const result = await captureSampleImage({ deviceId: device.label });

      if (result) {
        onImageCaptured(result);
      }
    } catch (e) {
      handleError(e);
    } finally {
      capturing.off();
    }
  }, [captureSampleImage, capturing, device.label, onImageCaptured]);

  return (
    <>
      <CalibrationCaptureHeader
        device={device}
        from={from}
        onClickCapture={handleCaptureClicked}
        isCapturing={capturing.isOn}
        captureDisabled={capturing.isOn}
        captureState={captureState}
      />
      <div className="h-full p-10 flex justify-center items-center">
        <ImageCapturePlaceholder
          className="rounded h-3/4 self-center aspect-video"
          index={0}
          isNextUpload={false}
        />
      </div>
    </>
  );
};
