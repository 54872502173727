import { FC, ReactNode } from "react";
import { cx } from "@libs/utils/cx";

export const PatientInfoCard: FC<{
  children?: ReactNode;
  layout?: "cols-3" | "cols-6" | "none";
  title: string;
}> = ({ children, layout = "cols-3", title }) => {
  return (
    <div>
      <div className="text-sm font-sansSemiBold mb-6">{title}</div>
      <div
        className={cx(
          "text-base",
          layout === "cols-3"
            ? "2xl:grid-cols-3 xl:grid-cols-2 grid-cols-1"
            : layout === "cols-6"
              ? "2xl:grid-cols-6 xl:grid-cols-2 grid-cols-1"
              : "",
          layout !== "none" && "grid gap-x-3 gap-y-5"
        )}
      >
        {children}
      </div>
    </div>
  );
};
