/* istanbul ignore file */
import { HeaderData } from "components/Dashboard/Tables/types";
import { LabelSet } from "components/Dashboard/hooks/useLongAgingOptions";

export const RECALL_BY_PROCEDURE_HEADERS: HeaderData[] = [
  { label: "Patient", width: "minmax(8rem, 1fr)", sortField: "patientLastName" },
  { label: "Patient Phone", width: "8rem" },
  { label: "Ins Primary Carrier", width: "minmax(10rem, 1fr)" },
  { label: "Remaining Ins Benefits", width: "minmax(11rem,13rem)" },
  { label: "Procedure", width: "minmax(11rem,13rem)" },
  {
    label: "Next Appt Date",
    width: "11.5rem",
    sortField: "scheduledDate",
    tooltip: {
      content: "The next appointment for the patient that includes the specific procedure.",
    },
  },
  { label: "Days Overdue", width: "11rem", sortField: "dueDate" },
  {
    label: "Recall Due",
    width: "10rem",
    sortField: "dueDate",
    tooltip: { content: "The date the next recall is due for the specific procedure." },
  },
  {
    label: "Last Recall",
    width: "9.5rem",
    sortField: "previousDate",
    tooltip: {
      content: "The date of the last appointment when a patient came in for the specific procedure.",
    },
  },
  { label: "", width: "3.75rem" },
];

export const RECALL_BY_PATIENT_HEADERS: HeaderData[] = [
  { label: "Patient", width: "minmax(8rem, 1fr)", sortField: "patientLastName" },
  { label: "Patient Phone", width: "8rem" },
  { label: "Ins Primary Carrier", width: "minmax(10rem, 1fr)" },
  { label: "Remaining Ins Benefits", width: "minmax(11rem,13rem)" },
  {
    label: "Next Appt Date",
    width: "11.5rem",
    sortField: "scheduledDate",
    tooltip: {
      content:
        "The next appointment for the patient, regardless of if the appointment is a recall or a different type of appointment.",
    },
  },
  { label: "Days Overdue", width: "11rem", sortField: "dueDate" },
  {
    label: "Recall Due",
    width: "9.5rem",
    sortField: "dueDate",
    tooltip: {
      content:
        "The date the next recall is due, based on the earliest date any recall procedure is due. For example, if a prophy is due in May and 4BW due in October, the recall due date will show May.",
    },
  },
  {
    label: "Last Recall",
    width: "9.5rem",
    sortField: "previousDate",
    tooltip: { content: "The date of the last appointment when a patient came in for any recall procedure." },
  },
  {
    label: "Last Appt Date",
    width: "12rem",
    sortField: "previousAppointmentDate",
    tooltip: {
      content:
        "The most recent date a patient came for an appointment, regardless of if the appointment was a recall or a different type of appointment.",
    },
  },
  { label: "", width: "3.75rem" },
];

export const RECALL_REPORT_AGING_OPTIONS: LabelSet[] = [
  {
    label: "Not Overdue",
    shortDisplayLabel: "Not Overdue",
  },
  {
    label: "< 30 Days Overdue",
    shortDisplayLabel: "< 30 days",
  },
  {
    label: "31-90 Days Overdue",
    shortDisplayLabel: "31-90 days",
  },
  {
    label: "91-180 Days Overdue",
    shortDisplayLabel: "91-180 days",
  },
  {
    label: "181-365 Days Overdue",
    shortDisplayLabel: "181-365 days",
  },
  {
    label: "365+ Days Overdue",
    shortDisplayLabel: "365+ days",
  },
];
