import { FC } from "react";
import { format, parseISO } from "date-fns";

import { RecallVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { TextDivider } from "components/PatientProfile/PatientOverview/Divider";
import { RecallDueDate } from "components/PatientProfile/PatientOverview/Recall/RecallDueDate";
import { RecallScheduleDate } from "components/PatientProfile/PatientOverview/Recall/RecallScheduleDate";

interface Props
  extends Pick<
    RecallVO,
    "dueDate" | "isDue" | "dueSoon" | "scheduledDate" | "scheduledAppointmentId" | "previousDate" | "note"
  > {
  onEdit: Func;
  disableEdit: boolean;
}

export const RecallInfo: FC<Props> = ({
  dueDate,
  isDue,
  dueSoon,
  scheduledDate,
  scheduledAppointmentId,
  previousDate,
  note,
  onEdit,
  disableEdit,
}) => {
  return (
    <div className="grid gap-y-2 w-full font-sans text-xs text-greyDark">
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          <RecallDueDate dueDate={dueDate} isDue={isDue} dueSoon={dueSoon} />
          <TextDivider />
          <RecallScheduleDate
            scheduledDate={scheduledDate}
            dueDate={dueDate}
            scheduledAppointmentId={scheduledAppointmentId}
          />
        </div>
        <ButtonIcon
          SvgIcon={EditIcon}
          className={cx("invisible", !disableEdit && "group-hover:visible")}
          onClick={onEdit}
          tooltip={{ content: "Edit Recall", theme: "SMALL" }}
          theme="primary"
        />
      </div>
      {previousDate && <p className="text-xxs">Prev {format(parseISO(previousDate), "MM/dd/yyyy")}</p>}
      {note && <p>{note}</p>}
    </div>
  );
};
