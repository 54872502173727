import { FC } from "react";

import { Spinner } from "@libs/components/UI/Spinner";
import { Pill } from "@libs/components/UI/Pill";

export const CampaignSavingPill: FC = () => (
  <Pill className="flex items-center gap-x-2" theme="blue" size="sm">
    Saving <Spinner size="xs" variant="primary" animation="border" />
  </Pill>
);
