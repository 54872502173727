import { BoolVal, Enum, NullableBoolVal, NumCSV, NumVal, StrVal, routesConfigHelper } from "@libs/router/url";
import { PatientNoteVO } from "@libs/api/generated-api";
import { lazyDefault } from "@libs/utils/lazyDefault";

const NoteFiltersQueryConfig = {
  showArchived: NullableBoolVal,
  tagIds: NumCSV,
};

const NoteListRoute = lazyDefault(
  () => import(/* webpackChunkName: "patient-notes-flyover" */ "components/Notes/NoteListRoute"),
  "NoteListRoute"
);

const NoteFilterListRoute = lazyDefault(
  () => import(/* webpackChunkName: "patient-notes-flyover" */ "components/Notes/NoteFilterListRoute"),
  "NoteFilterListRoute"
);

const CreateNoteRoute = lazyDefault(
  () => import(/* webpackChunkName: "patient-notes-flyover" */ "components/Notes/CreateNoteRoute"),
  "CreateNoteRoute"
);

const CreateNoteFromFormRoute = lazyDefault(
  () => import(/* webpackChunkName: "patient-notes-flyover" */ "components/Notes/CreateNoteFromFormRoute"),
  "CreateNoteFromFormRoute"
);

const EditNoteRoute = lazyDefault(
  () => import(/* webpackChunkName: "patient-notes-flyover" */ "components/Notes/EditNoteRoute"),
  "EditNoteRoute"
);

const NoteHistoryRoute = lazyDefault(
  () => import(/* webpackChunkName: "patient-notes-flyover" */ "components/Notes/NoteHistoryRoute"),
  "NoteHistoryRoute"
);

export const patientNoteRoutes = routesConfigHelper({
  list: {
    path: "/:patientId/notes",
    query: {
      ...NoteFiltersQueryConfig,
      clinicalNotes: BoolVal,
    },
    params: {
      patientId: NumVal,
    },
    Component: NoteListRoute,
  },
  filter: {
    path: "/:patientId/notes/filter",
    query: {
      ...NoteFiltersQueryConfig,
      from: StrVal,
    },
    params: {
      patientId: NumVal,
    },
    Component: NoteFilterListRoute,
  },
  create: {
    path: "/:patientId/notes/create",
    query: {
      type: Enum<PatientNoteVO["type"]>().Val(["CHART", "GENERAL"]),
      from: StrVal,
      alert: BoolVal,
    },
    params: {
      patientId: NumVal,
    },
    Component: CreateNoteRoute,
  },
  createFromForm: {
    path: "/:patientId/notes/create-from-form/:formUuid",
    params: {
      patientId: NumVal,
      formUuid: StrVal,
    },
    query: {
      from: StrVal,
    },
    Component: CreateNoteFromFormRoute,
  },
  edit: {
    path: "/:patientId/notes/:uuid/edit",
    params: {
      patientId: NumVal,
      uuid: StrVal,
    },
    query: {
      from: StrVal,
    },
    Component: EditNoteRoute,
  },
  history: {
    path: "/:patientId/notes/:uuid/history",
    params: {
      patientId: NumVal,
      uuid: StrVal,
    },
    query: {
      from: StrVal,
    },
    Component: NoteHistoryRoute,
  },
});

export type PatientNoteRoutes = typeof patientNoteRoutes;
