/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from "react";
import { localPoint } from "@visx/event";
import { Pie } from "@visx/shape";
import { Group } from "@visx/group";

import { animated, useTransition, config, to, SpringValues } from "@react-spring/web";
import { PieArcDatum, ProvidedProps } from "@visx/shape/lib/shapes/Pie";
import { useTooltip, useTooltipInPortal } from "@visx/tooltip";
import { UseTooltipParams } from "@visx/tooltip/lib/hooks/useTooltip";
import { ScaleSVG } from "@visx/responsive";
import { cx } from "@libs/utils/cx";
import { Spinner } from "@libs/components/UI/Spinner";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { PieDatumColored } from "components/Dashboard/Charting/types";

const fromLeaveTransition = {
  startAngle: 0,
  endAngle: 0,
  opacity: 0,
};

const enterUpdateTransition = <T extends Record<string, unknown>>({
  startAngle,
  endAngle,
}: PieArcDatum<PieDatumColored<T>>) => ({
  startAngle,
  endAngle,
  opacity: 1,
});

type AnimatedDonutProps<T extends Record<string, unknown>> = {
  animate?: boolean;
  onHideTooltip?: Func;
  onShowTooltip?: UseTooltipParams<PieDatumColored<T>>["showTooltip"];
  onClickDatum?: (datum: PieDatumColored<T>) => void;
} & ProvidedProps<PieDatumColored<T>>;

const AnimatedDonut = <T extends Record<string, unknown>>({
  animate,
  arcs,
  path,
  onShowTooltip,
  onHideTooltip,
  onClickDatum,
}: AnimatedDonutProps<T>) => {
  const transitions = useTransition(arcs, {
    keys: (arc) => arc.data.key,
    from: animate ? fromLeaveTransition : enterUpdateTransition,
    enter: enterUpdateTransition,
    update: enterUpdateTransition,
    leave: animate ? fromLeaveTransition : enterUpdateTransition,
    config: config.default,
  });

  return (
    <>
      {transitions(
        (values: SpringValues<{ opacity: number; startAngle: number; endAngle: number }>, item) => {
          return (
            <g key={item.data.key}>
              <animated.path
                opacity={values.opacity.to((val) => val)}
                // compute interpolated path d attribute from intermediate angle values
                d={to([values.startAngle, values.endAngle, values.opacity], (startAngle, endAngle) =>
                  path({
                    ...item,
                    startAngle,
                    endAngle,
                  })
                )}
                onClick={() => {
                  if (onClickDatum) {
                    onClickDatum(item.data);
                  }
                }}
                className={cx("hover:opacity-90", item.data.className)}
                onMouseOut={onHideTooltip}
                onMouseEnter={(e) => {
                  const point = localPoint(e);

                  if (point && onShowTooltip) {
                    onShowTooltip({ tooltipLeft: point.x, tooltipTop: point.y, tooltipData: item.data });
                  }
                }}
                fill={item.data.color}
              />
            </g>
          );
        }
      )}
    </>
  );
};

type TooltipContent<T extends Record<string, unknown>> = React.FunctionComponent<{
  datum: PieDatumColored<T>;
  percent: number;
}>;

export interface Props<T extends Record<string, unknown>> {
  width: number;
  height: number;
  animate?: boolean;
  data: PieDatumColored<T>[];
  isLoading?: boolean;
  error: boolean;
  Tooltip?: TooltipContent<T>;
  onClickDatum: (datum: PieDatumColored<T>) => void;
  emptyResultsMessage: string;
  title: string;
  subtitle?: string;
}

const CHART_MARGIN = { top: 0, left: 0, right: 0, bottom: 0 };

export const DashboardDonutChart = <T extends Record<string, unknown>>({
  data,
  width,
  height,
  animate = false,
  isLoading = false,
  error,
  Tooltip,
  onClickDatum,
  emptyResultsMessage,
  title,
  subtitle,
}: Props<T>) => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
    useTooltip<PieDatumColored<T>>();
  const margin = CHART_MARGIN;
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });
  const innerWidth = width - margin.left - margin.right;
  const innerHeight = height - margin.top - margin.bottom;
  const radius = Math.min(innerWidth, innerHeight) / 2;
  const centerY = innerHeight / 2;
  const centerX = innerWidth / 2;
  const top = centerY + margin.top;
  const left = centerX + margin.left;
  const totalValue = React.useMemo(() => data.reduce((prev, curr) => prev + curr.value, 0), [data]);
  const isEmpty = data.length === 0;

  return isLoading ? (
    <div className="absolute inset-0 flex items-center justify-center">
      <Spinner animation="border" variant="greyLighter" size="panel" />
    </div>
  ) : isEmpty ? (
    <div className="flex h-full items-center justify-center">
      <div className="text-greyMedium">{emptyResultsMessage}</div>
    </div>
  ) : error ? (
    <ErrorContent />
  ) : (
    <>
      <div
        className={`
          absolute
          inset-0
          flex
          items-center
          justify-center
          text-center
        `}
      >
        <div className="w-2/3">
          <div className="font-sansSemiBold text-lg">{title}</div>
          {subtitle && <div className="font-sansSemiBold text-2xl">{subtitle}</div>}
        </div>
      </div>
      <ScaleSVG width={width} height={height} innerRef={containerRef}>
        <Group top={top} left={left}>
          <Pie
            data={data}
            innerRadius={(arc) => {
              return arc.data.selected ? radius / 1.55 : radius / 1.4;
            }}
            outerRadius={radius}
            pieValue={(d) => d.value}
            startAngle={0}
            endAngle={360}
            pieSort={null}
          >
            {(pie) => (
              <AnimatedDonut
                {...pie}
                animate={animate}
                onClickDatum={onClickDatum}
                onHideTooltip={hideTooltip}
                onShowTooltip={showTooltip}
              />
            )}
          </Pie>
        </Group>
      </ScaleSVG>
      {tooltipOpen && tooltipData && Tooltip && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          className="space-y-1 bg-white text-greyDark py-4"
        >
          <Tooltip datum={tooltipData} percent={tooltipData.value / totalValue} />
        </TooltipInPortal>
      )}
    </>
  );
};
