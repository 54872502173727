/* eslint-disable @typescript-eslint/no-magic-numbers */
import { FC, useCallback, useState } from "react";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { hasFailedPin } from "@libs/utils/hasFailedPin";
import { useAccount } from "@libs/contexts/AccountContext";
import { verifyEmployeePin } from "api/employee/mutations";
import { handleError } from "utils/handleError";
import { INITIAL_PIN, Pin, PinModal } from "components/UI/PinModal";

export const EmployeePinModal: FC<{
  onPinSuccess: Func;
  onClose: Func;
}> = ({ onClose, onPinSuccess }) => {
  const { practiceId, id: employeeId } = useAccount();
  const [{ mutate: verifyPin, isLoading, error }] = useApiMutations([verifyEmployeePin]);
  const [pin, setPin] = useState<Pin>(() => INITIAL_PIN);

  const submitPin = useCallback(
    (pinToSubmit: string) => {
      verifyPin(
        { practiceId, employeeId, pin: pinToSubmit },
        {
          onSuccess: () => {
            onPinSuccess();
          },
          onError: (err) => {
            if (!hasFailedPin(err)) {
              handleError(err);
            }

            setPin(INITIAL_PIN);
          },
        }
      );
    },
    [employeeId, onPinSuccess, practiceId, verifyPin]
  );

  return (
    <PinModal
      onClose={onClose}
      onSubmit={submitPin}
      error={error}
      isVerifyingPin={isLoading}
      pin={pin}
      onChangePin={setPin}
    />
  );
};
