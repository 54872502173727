import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { Panel } from "@libs/components/UI/Panel";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getPatientInsurancesQuery } from "api/patientInsurance/queries";
import { paths } from "utils/routing/paths";
import { ArchiveMenu } from "components/PatientProfile/Insurance/InsurancePlans/ArchiveMenu";
import { EmptyInsurances } from "components/Insurance/EmptyInsurances";
import { PlansTable } from "components/PatientProfile/Insurance/InsurancePlans/Table";
import { getPatientSummary } from "api/patients/queries";
import { getFrequencyLimitationCategories } from "api/practiceInsurance/queries";
import { usePathParams } from "hooks/usePathParams";

export const PatientInsurancePlansRoute: React.FC = () => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const { patientId } = usePathParams("patientTab");
  const fetchArchivedInsurances = useBoolean(false);

  const [frequencyLimitationCategoriesQuery, activeInsuranceQuery, archivedInsuranceQuery, patientQuery] =
    useApiQueries([
      getFrequencyLimitationCategories({ args: { practiceId } }),
      getPatientInsurancesQuery({
        args: {
          includeBenefitCoverage: true,
          includeBenefitLimitation: true,
          includeInsurancePlan: true,
          includeProcedureHistory: true,
          insuranceState: ["ACTIVE"],
          patientId,
          practiceId,
        },
      }),
      getPatientInsurancesQuery({
        args: {
          insuranceState: ["ARCHIVED"],
          patientId,
          practiceId,
        },
        queryOptions: { enabled: fetchArchivedInsurances.isOn },
      }),
      getPatientSummary({ args: { practiceId, patientId } }),
    ]);

  const frequencyLimitationCategoryIds = useMemo(() => {
    return new Set(
      frequencyLimitationCategoriesQuery.data
        ?.filter((category) => !category.isHidden)
        .map((category) => category.id) ?? []
    );
  }, [frequencyLimitationCategoriesQuery.data]);

  const handleAddClick = useCallback(() => {
    navigate(paths.patientInsuranceStep({ insuranceId: "new", step: "info", patientId }));
  }, [patientId, navigate]);

  const handleNavigateToArchivedInsurance = useCallback(
    (insuranceId: number) => {
      navigate(paths.patientInsuranceStep({ insuranceId, patientId, step: "info" }));
    },
    [navigate, patientId]
  );

  return (
    <Panel
      actions={
        <ArchiveMenu
          insurancesArchived={archivedInsuranceQuery.data ?? []}
          isLoading={archivedInsuranceQuery.isLoading}
          onClickArchivedInsurance={handleNavigateToArchivedInsurance}
          onClickViewArchived={fetchArchivedInsurances.on}
        />
      }
      className="h-full overflow-hidden"
      includePadding={false}
      title={
        <div className="flex items-center gap-x-3">
          Insurance Plans
          <ButtonIcon SvgIcon={AddIcon} onClick={handleAddClick} theme="primary" />
        </div>
      }
    >
      <QueryResult queries={[frequencyLimitationCategoriesQuery, activeInsuranceQuery, patientQuery]}>
        {activeInsuranceQuery.data?.length && patientQuery.data ? (
          <div className="h-full w-full overflow-auto">
            <PlansTable
              frequencyLimitationCategoryIds={frequencyLimitationCategoryIds}
              insurances={activeInsuranceQuery.data}
              patient={patientQuery.data}
            />
          </div>
        ) : (
          <div className="flex items-center justify-center h-full">
            <div className="relative -top-14">
              <EmptyInsurances patientId={patientId} />
            </div>
          </div>
        )}
      </QueryResult>
    </Panel>
  );
};
