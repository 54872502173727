import { millisecondsToSeconds } from "date-fns";
import { ClaimAttachmentVO } from "@libs/api/generated-api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import {
  IMAGE_TYPES,
  MedicalImage,
  OtherImage,
  PerioChart,
} from "components/Claim/Attachments/ClaimAttachmentsContext";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { getAttachmentImagePreviewName } from "components/Claim/PendingEobsSection";
import { EobImage } from "components/Claim/Attachments/ClaimEobsRoute";

interface Props {
  editImage?: (imageData: string, type: ClaimAttachmentVO["type"]) => void;
  image: EobImage | MedicalImage | PerioChart | OtherImage;
  onRemovePerioChartImage: Func | undefined;
  removeImage?: (image: Omit<ClaimAttachmentVO, "uuid">) => void;
}

const IMAGE_OPTIONS: { label: string; value: "PHOTO" | "XRAY" }[] = [
  { label: "Photo", value: "PHOTO" },
  { label: "X-ray", value: "XRAY" },
];

const OTHER_OPTIONS: { label: string; value: "DIAGNOSTIC" | "MODEL" | "RADIOLOGY" | "REFERRAL" | "OTHER" }[] =
  [
    { label: "Diagnostic Report", value: "DIAGNOSTIC" },
    { label: "Dental Models", value: "MODEL" },
    { label: "Radiology Report", value: "RADIOLOGY" },
    { label: "Referral Form", value: "REFERRAL" },
    { label: "Other", value: "OTHER" },
  ];

export const ImagePreview: React.FC<Props> = ({ editImage, image, onRemovePerioChartImage, removeImage }) => {
  const practice = useCurrentPractice();
  const imageName = getAttachmentImagePreviewName(
    practice.timezoneId,
    image.sourceCreatedAt ? millisecondsToSeconds(image.sourceCreatedAt) : undefined
  );

  return (
    <div className="flex flex-col rounded border border-greyLighter w-52">
      <div className="flex items-center justify-between p-3">
        <span className="text-xs overflow-hidden whitespace-nowrap text-ellipsis">{imageName}</span>
        <ButtonIcon
          SvgIcon={DeleteIcon}
          size="md"
          onClick={() =>
            removeImage && image.type !== "CHART" ? removeImage(image) : onRemovePerioChartImage?.()
          }
          theme="primary"
        />
      </div>
      <div className="flex justify-center bg-greyLightest overflow-hidden">
        <img alt={imageName} className="h-24" src={image.data} />
      </div>
      {editImage && (
        <div className="flex items-center justify-center p-3">
          {IMAGE_TYPES.has(image.type) ? (
            <FormFieldSelect
              className="w-full"
              edit={true}
              onItemSelected={(value) => {
                editImage(image.data, value);
              }}
              options={IMAGE_OPTIONS}
              placeholder="Attachment Type"
              required={true}
              value={image.type}
            />
          ) : (
            <FormFieldSelect
              className="w-full"
              edit={true}
              onItemSelected={(value) => {
                editImage(image.data, value);
              }}
              options={OTHER_OPTIONS}
              placeholder="Attachment Type"
              required={true}
              value={image.type}
            />
          )}
        </div>
      )}
    </div>
  );
};
