import { useCallback } from "react";
import { cx } from "@libs/utils/cx";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { DurationIcon } from "components/Settings/Scheduling/Sections/ProcedureDurations/DurationIcon";

const cxStyles = {
  button: (disabled: boolean) =>
    cx(
      `flex
       items-center
       justify-center
       aspect-square
       h-8
       bg-white
       border-slate-200
       border
       hover:relative`,
      disabled ? "bg-greyLightest text-greyLight cursor-default" : "hover:border-primaryTheme"
    ),
};

export const AddProcedureDuration: React.FC<{
  onAddDuration: (durationType: "A" | "P") => void;
  error?: string;
  duration?: string;
}> = ({ onAddDuration, error, duration }) => {
  const handleAddAssistantTime = useCallback(() => onAddDuration("A"), [onAddDuration]);
  const handleAddProviderTime = useCallback(() => onAddDuration("P"), [onAddDuration]);

  // If (X) provider time has already been added, and then (/) hygenist, you cannot add more provider time.
  const cannotAddProvider = Boolean(duration?.endsWith("/") && duration.includes("X"));

  return (
    <div className="flex flex-col">
      <div className="flex mt-2 items-center">
        <button
          type="button"
          className={cx("rounded-l", cxStyles.button(false))}
          aria-label="Add 5 minutes of assistance time"
          onClick={handleAddAssistantTime}
        >
          <DurationIcon durationType="/" />
        </button>
        <FloatingTooltip
          content={
            cannotAddProvider
              ? "Assistant time can be at the beginning and/or end of a procedure. After adding assistant time at the end, you cannot add more provider time."
              : undefined
          }
        >
          <div>
            <button
              type="button"
              className={cx("-ml-px rounded-r", cxStyles.button(cannotAddProvider))}
              aria-label="Add 5 minutes of provider time"
              onClick={handleAddProviderTime}
              disabled={cannotAddProvider}
            >
              <DurationIcon durationType="X" disabled={cannotAddProvider} />
            </button>
          </div>
        </FloatingTooltip>
        <span className="ml-2 text-xs text-greyDark">Add Time</span>
      </div>
      {error && <FormFieldError className="mt-2">{error}</FormFieldError>}
    </div>
  );
};
