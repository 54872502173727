import React from "react";
import { Route } from "react-router-dom";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { ImagingSettingsModalPage } from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage";
import { PatientMountsList } from "components/PatientProfile/Imaging/PatientMountsList";
import { NotFound } from "components/NotFoundRoute";
import { NewMountModalPage } from "components/PatientProfile/Imaging/MountRoute/NewMountModalPage";
import { MountRoute } from "components/PatientProfile/Imaging/MountRoute/MountRoute";
import { PatientAppHistoryProvider } from "contexts/PatientLinksContext";
import { usePathParams } from "hooks/usePathParams";
import { CompareImagesRoute } from "components/PatientProfile/Imaging/CompareImages/CompareImagesRoute";
import { SelectMountImagesModalRoute } from "components/PatientProfile/Imaging/CompareImages/SelectMountImagesModalRoute";
import { EditImageRoute } from "components/PatientProfile/Imaging/EditImageRoute";

export const PatientMountsListRoute: React.FC = () => {
  const { patientId } = usePathParams("patientTab");

  return (
    <PatientAppHistoryProvider patientId={patientId} name="imaging">
      <SentryRoutes>
        <Route path="new" element={<NewMountModalPage />} />
        <Route path="settings" element={<ImagingSettingsModalPage />} />
        <Route path="compare" element={<CompareImagesRoute />} />
        <Route path="select" element={<SelectMountImagesModalRoute />} />
        <Route path="edit" element={<EditImageRoute />} />
        <Route path=":mountId" element={<MountRoute />} />
        <Route index element={<PatientMountsList />} />
        <Route path="*" element={<NotFound />} />
      </SentryRoutes>
    </PatientAppHistoryProvider>
  );
};
