import { half } from "@libs/utils/math";

const CLICKABLE_AXIS_LABEL_HEIGHT = 14;

type Props = {
  width: number;
  x: number;
  yMax: number;
  onClick: Func;
  index: number;
};

// This rect serves to allow the user to click the axis label:
export const ClickableAxisLabelHiddenBar: React.FC<Props> = ({ width, x, yMax, onClick }) => (
  <rect
    width={width}
    height={CLICKABLE_AXIS_LABEL_HEIGHT}
    x={x}
    y={yMax + half(CLICKABLE_AXIS_LABEL_HEIGHT)}
    fill="transparent"
    className="cursor-pointer"
    onClick={onClick}
  />
);
