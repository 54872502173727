import { FC, HTMLProps, useMemo, useCallback } from "react";
import { useDrop } from "react-dnd";

import { DocumentVO, FolderVO } from "@libs/api/generated-api";
import { Icon } from "@libs/components/UI/Icon";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { cx } from "@libs/utils/cx";

import { ReactComponent as FolderIcon } from "@libs/assets/icons/folder.svg";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";

import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { PinGuard } from "components/UI/PinGuard";

import { getFolderDocuments } from "api/documents/queries";

import { TreeViewFile } from "./TreeViewFile";
import { TreeViewDraggables } from "./TreeViewDraggables";

interface Props extends HTMLProps<HTMLDivElement> {
  userId: number;
  practiceId: number;
  folder: FolderVO;
  expanded: boolean;
  selectedDocId: number | undefined;
  onFolderClick: (folder: FolderVO) => void;
  onFolderEdit: (folder: FolderVO) => void;
  onFolderDelete: (folder: FolderVO) => void;
  onDocumentMove: (document: DocumentVO, destinationFolder: FolderVO) => void;
  onDocumentClick: (document: DocumentVO) => void;
}

export const TreeViewFolder: FC<Props> = ({
  practiceId,
  userId,
  folder,
  expanded,
  selectedDocId,
  onFolderClick,
  onFolderEdit,
  onFolderDelete,
  onDocumentMove,
  onDocumentClick,
}) => {
  const menu = useBoolean(false);
  const menuOptions = useMemo(
    () =>
      createMenuOptions(
        {
          value: "edit",
          label: "Edit",
          SvgIcon: EditIcon,
        },
        {
          value: "delete",
          label: "Delete",
          SvgIcon: DeleteIcon,
        }
      ),
    []
  );

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "edit": {
          onFolderEdit(folder);
          break;
        }
        case "delete": {
          onFolderDelete(folder);
          break;
        }
        default: {
          break;
        }
      }

      menu.off();
    },
    [folder, onFolderEdit, onFolderDelete, menu]
  );

  const [getFolderDocumentsQuery] = useApiQueries([
    getFolderDocuments({
      args: { practiceId, userId, folderId: folder.id, includeMetadata: true },
      queryOptions: { enabled: expanded },
    }),
  ]);

  const [{ isFileOver }, drop] = useDrop(
    () => ({
      accept: TreeViewDraggables.FILE,
      drop: (doc: DocumentVO) => (doc.folderId === folder.id ? undefined : onDocumentMove(doc, folder)),
      collect: (monitor) => ({
        isFileOver: Boolean(monitor.isOver()),
      }),
    }),
    [folder]
  );

  return (
    <div
      ref={drop}
      className={cx(
        "rounded",
        expanded && "bg-offWhite",
        isFileOver && "bg-actionLight ring-inset ring-primaryTheme ring-1"
      )}
    >
      <div className="relative">
        <button
          className={cx(
            "w-full rounded p-3 text-left hover:bg-slate-100",
            expanded && folder.fileCount > 0 && "hover:rounded-b-none"
          )}
          onClick={() => onFolderClick(folder)}
          type="button"
        >
          <div className="flex items-start gap-x-2 pr-5">
            <Icon SvgIcon={FolderIcon} theme={folder.fileCount > 0 ? "slate700" : "slate500"} />
            <div className={cx("flex-1 pr-2", folder.fileCount > 0 ? "font-sansSemiBold" : "text-slate-500")}>
              {folder.name} ({folder.fileCount})
            </div>
          </div>
        </button>

        <ButtonMenu
          className="absolute right-3 top-1/2 -translate-y-1/2"
          menuContent={
            <div className="w-36">
              <MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />
            </div>
          }
          onRequestClose={menu.off}
          onRequestOpen={menu.on}
          isOpen={menu.isOn}
          placement="bottom-start"
        >
          {(props) => (
            <ButtonIcon
              {...props}
              SvgIcon={MenuIcon}
              tooltip={{
                content: folder.isDefaultFolder ? "Archy folders cannot be edited" : "Folder Menu",
                theme: "SMALL",
              }}
              disabled={folder.isDefaultFolder}
              theme="slate700"
            />
          )}
        </ButtonMenu>
      </div>

      {expanded && (
        <PinGuard
          protectedQueries={[getFolderDocumentsQuery]}
          deniedContent={<div className="text-center">Access to this content is restricted.</div>}
        >
          <QueryResult queries={[getFolderDocumentsQuery]} loading={<div className="p-3">Loading...</div>}>
            {getFolderDocumentsQuery.data?.map((document) => {
              return (
                <TreeViewFile
                  key={document.id}
                  document={document}
                  isSelected={document.id === selectedDocId}
                  onDocumentClick={onDocumentClick}
                />
              );
            })}
          </QueryResult>
        </PinGuard>
      )}
    </div>
  );
};
