import { CreateInsurancePlanRequest, UpdateInsurancePlanRequest } from "@libs/api/generated-api";
import { UsePlanFieldsResult } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/usePlanFields";

export const getPlanUpdateSubmission = (
  planFields: UsePlanFieldsResult["planFields"],
  fallbackEmployerName: string
): UpdateInsurancePlanRequest => {
  const values: UpdateInsurancePlanRequest = {
    carrierContacted: planFields.carrierContacted ?? false,
    carrierContactedById: planFields.contactedBy?.id,
    carrierId: planFields.carrierId ?? planFields.carrier?.id,
    coordinationOfBenefits: planFields.coordinationOfBenefits ?? "STANDARD",
    crownBridgesPaidOn: planFields.crownBridgesPaidOn,
    dependentMaxAge: planFields.dependentMaxAge,
    description: planFields.description,
    eligibilityCoverageLevel: planFields.eligibilityCoverageLevel,
    employerName: planFields.employerName ?? fallbackEmployerName,
    familyAnnualDeductible: planFields.familyAnnualDeductible,
    familyAnnualMaximum: planFields.familyAnnualMaximum,
    feeScheduleId: planFields.feeSchedule?.id,
    groupName: planFields.groupName,
    groupNumber: planFields.groupNumber,
    individualAnnualDeductible: planFields.individualAnnualDeductible,
    individualAnnualMaximum: planFields.individualAnnualMaximum,
    individualLifetimeDeductible: planFields.individualLifetimeDeductible,
    individualLifetimeMaximum: planFields.individualLifetimeMaximum,
    insuranceRepresentative: planFields.insuranceRepresentative,
    missingToothClause: planFields.missingToothClause ?? false,
    orthoLifetimeMaximum: planFields.orthoLifetimeMaximum,
    orthoMaxAge: planFields.orthoMaxAge,
    outOfNetworkBenefits: planFields.outOfNetworkBenefits ?? false,
    planNotes: planFields.planNotes?.trim(),
    planType: planFields.planType as UpdateInsurancePlanRequest["planType"],
    renewalMonth: planFields.renewalMonth,
    waitingPeriodInMonths: planFields.waitingPeriodInMonths ?? 0,
  };

  return values;
};

export const getCreatePlanSubmission = (
  carrierId: number,
  planFields: UsePlanFieldsResult["planFields"],
  fallbackEmployerName: string
): CreateInsurancePlanRequest => {
  const values = getPlanUpdateSubmission(planFields, fallbackEmployerName);

  return {
    ...values,
    carrierId,
  };
};
