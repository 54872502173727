import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";

type Props = {
  tooltip?: string;
  children: React.ReactNode;
};
export const TruncatedCell: React.FC<Props> = ({ tooltip, children }) => {
  return (
    <FloatingTooltip content={tooltip} theme="MEDIUM">
      <div className="truncate">{children ?? EMPTY_CELL}</div>
    </FloatingTooltip>
  );
};
