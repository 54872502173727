import React, { useRef } from "react";
import { UseMutateAsyncFunction } from "@tanstack/react-query";
import {
  BatchPrepareFileUploadRequest,
  ErrorResponse,
  HttpResponse,
  PrepareFileUploadResponse,
  SuccessResponseBatchPrepareFileUploadResponse,
  SuccessResponseMedicalImageVO,
  UploadMedicalImageV2Request,
} from "@libs/api/generated-api";
import { filenameToMimeType } from "@libs/utils/mimeTypes";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ApiErrorResponse } from "@libs/@types/api";
import { handleError } from "utils/handleError";
import { useImagingDeviceSettings } from "components/PatientProfile/Imaging/hooks/useImagingDeviceSettings";
import { ImageUploadItem } from "components/PatientProfile/Imaging/MountRoute/types";
import { promiseAllProgress } from "utils/promise";

type UseApiMutationAsync<Response, Variables> = UseMutateAsyncFunction<
  HttpResponse<Response, ErrorResponse>,
  ApiErrorResponse,
  Variables,
  unknown
>;

type LinkImageRequestParams = {
  practiceId: number;
  patientId: number;
  mountId: number;
  data: UploadMedicalImageV2Request;
};
export type UploadResult = (
  | {
      status: "SUCCESS";
      response: HttpResponse<SuccessResponseMedicalImageVO, ErrorResponse>;
      archyImageServiceImageId?: string;
    }
  | {
      status: "FAILED";
      error: unknown;
    }
) & {
  linkImageParams: LinkImageRequestParams;
  imageFile: File;
};
export class MedicalImageUploadError extends Error {
  responseText;
  mountId;

  constructor({
    errorString,
    responseText,
    mountId,
  }: {
    errorString: string;
    responseText: string;
    mountId: number;
  }) {
    super(errorString);
    this.name = this.constructor.name;
    this.responseText = responseText;
    this.mountId = mountId;
  }
}

export const useUploadMedicalImages = ({
  practiceId,
  prepareUploadsMutateAsync,
  linkMedicalImageMutateAsync,
}: {
  practiceId: number;

  prepareUploadsMutateAsync: UseApiMutationAsync<
    SuccessResponseBatchPrepareFileUploadResponse,
    { practiceId: number; data: BatchPrepareFileUploadRequest }
  >;
  linkMedicalImageMutateAsync: UseApiMutationAsync<SuccessResponseMedicalImageVO, LinkImageRequestParams>;
}) => {
  const uploading = useBoolean(false);
  const uploadCountRef = useRef(0);

  const { sensorSettings, cameraSettings } = useImagingDeviceSettings();

  const setUploading = uploading.set;
  const uploadImages = React.useCallback(
    async ({
      images,
      deviceId,
      patientId,
      mountId,
      onProgress,
    }: {
      images: ImageUploadItem[];
      deviceId?: string;
      patientId: number;
      mountId: number;
      onProgress: (progress: number, result?: UploadResult) => void;
    }) => {
      let keys: PrepareFileUploadResponse[] = [];
      const deviceSetting = [...sensorSettings, ...cameraSettings].find((device) => deviceId === device.id);

      const selectedDeviceProduces = deviceSetting?.produces
        ? deviceSetting.produces === "Photo"
          ? "PHOTO"
          : "X_RAY"
        : undefined;

      setUploading(true);

      const imageCount = images.length;

      uploadCountRef.current += imageCount;

      try {
        const { data } = await prepareUploadsMutateAsync({
          practiceId,
          data: {
            requests: images.map((image) => ({ contentType: filenameToMimeType(image.imageFile.name) })),
          },
        });

        keys = data.data.responses ?? [];
      } catch (e) {
        uploadCountRef.current -= imageCount;
        setUploading(uploadCountRef.current > 0);
        handleError(e);

        return [];
      }

      if (keys.length === 0) {
        uploadCountRef.current -= imageCount;
        setUploading(uploadCountRef.current > 0);

        return [];
      }

      const results: UploadResult[] = await promiseAllProgress(
        keys.map((imageRequestParams, i) => {
          const { imageFile, type: captureMode, ...image } = images[i];
          const captureModeWithDeviceSettingOverride =
            captureMode === "EXTERNAL" ? captureMode : selectedDeviceProduces ?? captureMode;
          const linkImageParams: LinkImageRequestParams = {
            practiceId,
            patientId,
            mountId,
            data: {
              ...image,
              sensor: deviceId,
              contentType: filenameToMimeType(imageFile.name),
              type: captureModeWithDeviceSettingOverride,
              fileName: imageFile.name,
              encryptedFileKey: keys[i].encryptedFileKey,
            },
          };

          return fetch(imageRequestParams.url, {
            method: "PUT",
            body: imageFile,
          })
            .then(async (response) => {
              if (!response.ok) {
                const errorString = `Failed to upload image, error ${response.status} (${response.statusText})`;

                throw new MedicalImageUploadError({
                  errorString,
                  mountId,
                  responseText: await response.text(),
                });
              }

              const linkResponse = await linkMedicalImageMutateAsync(linkImageParams);

              return {
                status: "SUCCESS" as const,
                response: linkResponse,
                imageFile,
                linkImageParams,
                archyImageServiceImageId: image.archyImageServiceImageId,
              };
            })
            .catch((e) => {
              const error: unknown = e;

              handleError(error);

              return {
                status: "FAILED" as const,
                error,
                imageFile,
                linkImageParams,
              };
            });
        }),
        onProgress
      ).finally(() => {
        uploadCountRef.current -= imageCount;
        setUploading(uploadCountRef.current > 0);
      });

      return results;
    },
    [
      cameraSettings,
      linkMedicalImageMutateAsync,
      practiceId,
      prepareUploadsMutateAsync,
      sensorSettings,
      setUploading,
    ]
  );

  return { uploadImages, isUploading: uploading.isOn };
};

export type UploadImagesCallback = ReturnType<typeof useUploadMedicalImages>["uploadImages"];
