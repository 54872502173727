import React from "react";

export const MountModalSection: React.FC<{ children: React.ReactNode; title: string }> = ({
  children,
  title,
}) => {
  return (
    <div className="flex flex-col gap-2">
      <div className="text-sm font-sansSemiBold">{title}</div>
      {children}
    </div>
  );
};
