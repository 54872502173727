import React from "react";
import { PracticeProductionQuery } from "utils/routing/dashboard";
import { paths } from "utils/routing/paths";

export const useProductionTableTabProps = ({ query }: { query: PracticeProductionQuery }) => {
  const tabs = React.useMemo(() => {
    const queryParams: Partial<PracticeProductionQuery> = {
      ...query,
      apptTableSort: undefined,
      procedureTableSort: undefined,
      filters: undefined,
    };

    return [
      {
        text: "Show by Provider",
        to: paths.dashboardPracticeProduction({ tableTab: "providers" }, queryParams),
      },
      {
        text: "Show by Hygienist",
        to: paths.dashboardPracticeProduction({ tableTab: "hygienists" }, queryParams),
      },
      {
        text: "Show by Patient",
        to: paths.dashboardPracticeProduction({ tableTab: "appointments" }, queryParams),
      },
      {
        text: "Show by Procedure",
        to: paths.dashboardPracticeProduction({ tableTab: "procedures" }, queryParams),
      },
    ];
  }, [query]);

  return {
    tabs,
  };
};
