import { useCallback, useMemo } from "react";
import { DailyHuddleOverview } from "@libs/api/generated-api";
import { formatMonthDate } from "@libs/utils/date";
import { ToggleButtonList } from "components/UI/ToggleButtonList";
import { DailyHuddleDatePicker } from "components/DailyHuddle/DailyHuddleDatePicker";
import { DailyHuddleQuery, DailyHuddleQueryUpdates, DateQuickFilter } from "utils/routing/scheduling";

interface Props {
  dailyHuddleOverview: DailyHuddleOverview;
  onUpdateQueryParams: (updates: DailyHuddleQueryUpdates) => void;
  queryState: DailyHuddleQuery;
}

type DailyHuddleOverviewKey = keyof DailyHuddleOverview;

const getDateOptionLabel = (key: DailyHuddleOverviewKey, summaryKeys: DailyHuddleOverview) => {
  let wordLabel = "Today";

  switch (key) {
    case "yesterdayCount": {
      wordLabel = "Yesterday";
      break;
    }
    case "tomorrowCount": {
      wordLabel = "Tomorrow";
      break;
    }
    case "nextWeekCount": {
      wordLabel = "Next Week";
      break;
    }
    case "thisWeekCount": {
      wordLabel = "This Week";
      break;
    }
    default: {
      break;
    }
  }

  if (key === "todayCount") {
    wordLabel += `, ${formatMonthDate(new Date())}`;
  }

  return `${wordLabel} (${summaryKeys[key]})`;
};

export const DateFilters: React.FC<Props> = ({ dailyHuddleOverview, onUpdateQueryParams, queryState }) => {
  const options: { label: string; value: DateQuickFilter }[] = useMemo(() => {
    return [
      {
        label: getDateOptionLabel("yesterdayCount", dailyHuddleOverview),
        value: "YESTERDAY",
      },
      {
        label: getDateOptionLabel("todayCount", dailyHuddleOverview),
        value: "TODAY",
      },
      {
        label: getDateOptionLabel("tomorrowCount", dailyHuddleOverview),
        value: "TOMORROW",
      },
      {
        label: getDateOptionLabel("thisWeekCount", dailyHuddleOverview),
        value: "THIS_WEEK",
      },
      {
        label: getDateOptionLabel("nextWeekCount", dailyHuddleOverview),
        value: "NEXT_WEEK",
      },
    ];
  }, [dailyHuddleOverview]);

  const handleClickQuickFilter = useCallback(
    (value: DateQuickFilter) => {
      onUpdateQueryParams({ dateRange: undefined, quickDateFilter: value });
    },
    [onUpdateQueryParams]
  );

  const handleDateChange = useCallback(
    (dateRange: [Date | null, Date | null]) => {
      onUpdateQueryParams({
        dateRange,
        quickDateFilter: undefined,
      });
    },
    [onUpdateQueryParams]
  );

  return (
    <div className="flex-1 flex items-center justify-start gap-x-2 max-w-4xl">
      <ToggleButtonList
        listClassName="flex flex-row gap-x-2 flex-nowrap"
        optionClassName="min-w-16 px-5 py-5 text-sm truncate text-ellipsis"
        onChange={(_, item) => handleClickQuickFilter(item.target.value as DateQuickFilter)}
        options={options}
        selectedValues={
          queryState.dateRange
            ? new Set<DateQuickFilter>()
            : queryState.quickDateFilter
              ? new Set([queryState.quickDateFilter])
              : new Set<DateQuickFilter>(["TODAY"])
        }
        type="checkbox"
      />
      <DailyHuddleDatePicker dateRange={queryState.dateRange} onChange={handleDateChange} />
    </div>
  );
};
