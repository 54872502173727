import { FC, Fragment } from "react";
import { DateWorktimeVO, WorktimeVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as PlusCircle } from "@libs/assets/icons/plus-circle.svg";
import { ButtonCell, TextCell, EMPTY_CELL } from "@libs/components/UI/GridTableComponents";

interface Props {
  hoursForDay: DateWorktimeVO;
  showDoubleOvertime: boolean;
  hasEmployeesPermissions: boolean;
  selectedRows: Set<number>;
  onAddTime: (params: { date: string; lastClockOut: WorktimeVO | undefined }) => void;
}

export const TimeSheetAddTimeRow: FC<Props> = ({
  hasEmployeesPermissions,
  showDoubleOvertime,
  selectedRows,
  hoursForDay,
  onAddTime,
}) => {
  const hasSelections = selectedRows.size > 0;

  return (
    <>
      <TextCell />
      <TextCell />
      <TextCell />
      {showDoubleOvertime ? <TextCell /> : null}
      {hasEmployeesPermissions ? <TextCell /> : null}
      <ButtonCell
        className={cx("flex items-center", hasSelections ? "text-greyLight" : "text-primaryTheme")}
        onClick={() => {
          const lastAction = hoursForDay.worktimeDetails.at(-1);

          if (lastAction) {
            onAddTime({
              date: hoursForDay.worktimeDate,
              lastClockOut: lastAction.clockOut,
            });
          }
        }}
      >
        Add Time
        <Icon disabled={hasSelections} SvgIcon={PlusCircle} className="ml-1" theme="primary" />
      </ButtonCell>
      <TextCell>{EMPTY_CELL}</TextCell>
      <TextCell>{EMPTY_CELL}</TextCell>
      {hasEmployeesPermissions ? <TextCell>{EMPTY_CELL}</TextCell> : null}
      <TextCell>{EMPTY_CELL}</TextCell>
    </>
  );
};
