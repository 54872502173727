import { useValidation } from "@libs/hooks/useValidation";
import { formatCurrency } from "@libs/utils/currency";
import { absLessOrEqualTo, greaterThan, lessThan, required } from "@libs/utils/validators";
import { paymentNoteValidator } from "components/PatientProfile/Billing/Payment/validation";

const paymentAmountValidator = (maxAmountDue: number) => [
  {
    $v: maxAmountDue >= 0 ? greaterThan(0) : lessThan(0),
    $error: `Amount must be greater than ${formatCurrency(0)}`,
    $ignore: maxAmountDue === 0, // for when invoices cancel each other out
  },
  {
    $v: absLessOrEqualTo(maxAmountDue),
    $error: `Amount cannot be greater than ${formatCurrency(Math.abs(maxAmountDue))}`,
  },
];

export const useEditPaymentFormValidation = ({
  maxAmountDue,
  paymentAmount,
  paymentDate,
  paymentNote,
}: {
  maxAmountDue: number;
  paymentAmount: number | undefined;
  paymentDate?: Date;
  paymentNote: string | undefined;
}) => {
  const validation = useValidation(
    {
      paymentAmount,
      paymentDate,
      paymentNote,
    },
    {
      paymentDate: [{ $v: required, $error: "Payment Date required" }],
      paymentAmount: paymentAmountValidator(maxAmountDue),
      paymentNote: [{ $v: required, $error: "Note is required" }, ...paymentNoteValidator],
    }
  );

  return validation;
};
