import { cx } from "@libs/utils/cx";

const cxThemes = { darkBackground: "bg-slate-700", default: "bg-slate-200" };

type Themes = keyof typeof cxThemes;

const cxSizes = { sm: "h-4", md: "h-5", lg: "h-6", xxl: "h-14", full: "h-full", stretch: "self-stretch" };

type Sizes = keyof typeof cxSizes;

export const VerticalDivider = ({
  className,
  size = "full",
  theme = "default",
}: {
  className?: string;
  size?: Sizes;
  theme?: Themes;
}) => {
  return <div className={cx("w-px", cxSizes[size], cxThemes[theme], className)} />;
};
