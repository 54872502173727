/* eslint-disable @typescript-eslint/no-magic-numbers */
import { Editor, EmailEditorProps } from "react-email-editor";

export type ExportEmailEditorTemplateOptions = Parameters<Editor["exportHtml"]>[1];

export type ExportEmailEditorImageOptions = Parameters<Editor["exportImage"]>[1];

export type EmailEditorJSONTemplate = Parameters<Editor["loadDesign"]>[0];

export type EmailEditorMergeTags = NonNullable<EmailEditorProps["options"]>["mergeTags"];

export const isEmailEditorJSONTemplate = (
  json: ReturnType<typeof JSON.parse>
): json is EmailEditorJSONTemplate => {
  return "counters" in json && "body" in json;
};
