import { FC, useCallback } from "react";
import { PatientProcedureVO, TreatmentPlanVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { canDeleteEvery } from "components/Charting/patientProcedurePermissions";

type ConfirmDeleteModalProps = {
  onCancel: Func;
  onConfirm: (deleteFromAll: boolean) => void;
  treatmentPlan?: TreatmentPlanVO;
  procedures: PatientProcedureVO[];
};

export const ConfirmDeleteModal: FC<ConfirmDeleteModalProps> = ({
  onCancel,
  onConfirm,
  treatmentPlan,
  procedures,
}) => {
  const deleteFromAll = useBoolean(!treatmentPlan);
  const handleConfirm = useCallback(() => {
    onConfirm(deleteFromAll.isOn);
  }, [onConfirm, deleteFromAll.isOn]);

  return (
    <ConfirmationModal
      primaryText={
        treatmentPlan?.name
          ? `Are you sure you want to remove these procedures from "${treatmentPlan.name}"?`
          : "Are you sure you want to remove these procedures from all treatment plans?"
      }
      onCancel={onCancel}
      onConfirm={handleConfirm}
    >
      <ul className="text-xs list-disc list-inside flex flex-col mt-4">
        {procedures.map((proc) => (
          <li key={proc.id}>
            {proc.cdtCode} - {proc.description}
          </li>
        ))}
      </ul>
      {treatmentPlan && canDeleteEvery(procedures) ? (
        <div className="mt-4 mb-2 flex justify-center">
          <Checkbox checked={deleteFromAll.isOn} onChange={deleteFromAll.toggle}>
            Remove from all treatment plans
          </Checkbox>
        </div>
      ) : null}
    </ConfirmationModal>
  );
};
