import { AsyncSection } from "@libs/components/UI/AsyncSection";
import { Instructions } from "components/Settings/PracticeSetup/Sections/Phones/Instructions";
import { FailedAuthKey } from "components/Settings/PracticeSetup/Sections/Phones/FailedAuthKey";
import { Extensions } from "components/Settings/PracticeSetup/Sections/Phones/Extensions";

interface Props {
  allowInbound: boolean;
  authKey: string;
  authKeyFailed: boolean;
  extension: string;
  hasError: boolean;
  isLoading: boolean;
  isLoadingError: boolean;
  onConnect: Func;
  onGoBack: Func;
  onUpdateAuthKey: (value: string) => void;
}

export const Phones: React.FC<Props> = ({
  allowInbound,
  authKey,
  authKeyFailed,
  extension,
  hasError,
  isLoading,
  isLoadingError,
  onConnect,
  onGoBack,
  onUpdateAuthKey,
}) => {
  return (
    <div className="w-full h-full p-6">
      <AsyncSection isLoading={isLoading} isLoadingError={isLoadingError}>
        {hasError ? (
          authKeyFailed ? (
            <FailedAuthKey onConnect={onConnect} onGoBack={onGoBack} />
          ) : (
            <Instructions authKey={authKey} onConnect={onConnect} onUpdateAuthKey={onUpdateAuthKey} />
          )
        ) : (
          <Extensions allowInbound={allowInbound} extension={extension} />
        )}
      </AsyncSection>
    </div>
  );
};
