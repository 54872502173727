import React, { useCallback } from "react";
import { UpdatePatientInsuranceStateRequest } from "@libs/api/generated-api";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ActionModal } from "components/UI/ActionModal";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { updatePatientInsuranceState } from "api/patientInsurance/mutations";
import { handleError } from "utils/handleError";

type Props = {
  expirationDate: string | undefined;
  insuranceId: number;
  onAction: () => void;
  patientId: number;
  practiceId: number;
};

export const ConfirmArchiveModal: React.FC<Props> = ({
  expirationDate,
  insuranceId,
  onAction,
  patientId,
  practiceId,
}) => {
  const [expiredDate, setExpiredDate] = React.useState<Date | undefined>(
    expirationDate ? getLocalDate(expirationDate) : undefined
  );

  const [updateInsuranceStateMutation] = useApiMutations([updatePatientInsuranceState]);

  const handleUpdate = useCallback(async () => {
    const data: UpdatePatientInsuranceStateRequest = { state: "ARCHIVED" };

    if (expiredDate) {
      data.expiryDate = formatAsISODate(expiredDate);
    }

    try {
      await updateInsuranceStateMutation.mutateAsync({
        practiceId,
        patientId,
        insuranceId,
        data,
      });
      onAction();
    } catch (e) {
      handleError(e);
    }
  }, [expiredDate, insuranceId, onAction, patientId, practiceId, updateInsuranceStateMutation]);

  return (
    <ActionModal
      handleClose={onAction}
      handleSave={handleUpdate}
      primaryButtonText="Confirm"
      size="xs"
      title="Are you sure you want to archive this insurance?"
    >
      <FormFieldSelectMenusDatepicker
        label="Eligibility End Date"
        layout="labelIn"
        selected={expiredDate}
        onChange={(date) => {
          setExpiredDate(date === null ? undefined : date);
        }}
        placeholderText="MM/DD/YYYY"
      />
    </ActionModal>
  );
};
