import { FC } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { UpdateAlertSettingsRequest } from "@libs/api/generated-api";
import { Panel } from "@libs/components/UI/Panel";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getAlertSettings } from "api/settings/notes/queries";
import { updateAlertSettings } from "api/settings/notes/mutations";
import { handleError } from "utils/handleError";
import { AlertsPage } from "components/Settings/Notes/AlertsPage";

export const AlertsRoute: FC = () => {
  const { practiceId } = useAccount();
  const [alertSettingsQuery] = useApiQueries([getAlertSettings({ args: { practiceId } })]);

  const [updateAlertSettingsMutation] = useApiMutations([updateAlertSettings]);

  const handleUpdateAlertSettings = (updates: UpdateAlertSettingsRequest) => {
    updateAlertSettingsMutation.mutate(
      {
        practiceId,
        data: updates,
      },
      {
        onError: handleError,
      }
    );
  };

  return (
    <Panel className="h-full" title="Alerts" includePadding={false}>
      <QueryResult queries={[alertSettingsQuery]}>
        {alertSettingsQuery.data ? (
          <AlertsPage
            updateInProgress="Auto alerts are being updated."
            isSaving={updateAlertSettingsMutation.isLoading}
            isSaved={updateAlertSettingsMutation.isSuccess}
            alertSettings={alertSettingsQuery.data}
            onSave={handleUpdateAlertSettings}
          />
        ) : null}
      </QueryResult>
    </Panel>
  );
};
