import React from "react";
import { produce } from "immer";
import { MedicalImageVO, MountVO, PatientSummaryVO } from "@libs/api/generated-api";
import { ReactComponent as ArchiveIcon } from "@libs/assets/icons/archive.svg";
import { ReactComponent as UnarchiveIcon } from "@libs/assets/icons/unarchive.svg";
import { ReactComponent as TransferIcon } from "@libs/assets/icons/transfer.svg";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { useTransferMountModal } from "components/PatientProfile/Imaging/MountRoute/hooks/useTransferMountModal";
import { UseImageEditQueue } from "components/PatientProfile/Imaging/MountRoute/hooks/useImageEditQueue";

import { CompareImagesMenu } from "components/PatientProfile/Imaging/MountRoute/CompareImagesButtonMenu";
import { MountDetailsQuery } from "utils/routing/patient";
import { ExportMenu } from "components/PatientProfile/Imaging/ImageEditor/Tools/ExportMenu";
import { MountIconButton } from "components/PatientProfile/Imaging/MountRoute/MountIconButton";
import { TransferConfirmationModal } from "components/PatientProfile/Imaging/PatientMountsList/TransferConfirmationModal";
import { TransferImagesFlyover } from "components/PatientProfile/Imaging/MountRoute/TransferImagesFlyover";

type Props = {
  handleMountChanged: UseImageEditQueue["handleMountChanged"];
  mount: MountVO;
  onUpdateParams: (newParams: Partial<MountDetailsQuery>) => void;
  params: MountDetailsQuery;
  selectedImageIds: Set<number>;
  handlePrintMount: () => void;
  patient: PatientSummaryVO;
  handleExportMount: () => void;
};

export const SelectedImageOptionsToolbar: React.FC<Props> = ({
  handleMountChanged,
  mount,
  onUpdateParams,
  params,
  patient,
  selectedImageIds,
  ...rest
}) => {
  const { handleTransferMount, transferMountModal, confirmTransferMountModal, isTransferring } =
    useTransferMountModal({
      mount,
      selectedImageIds,
      sourcePatientId: patient.id,
    });
  const selectedImages = React.useMemo(
    () => mount.images?.filter((item) => selectedImageIds.has(item.id ?? -1)) ?? [],
    [mount.images, selectedImageIds]
  );
  const selectedImagesArchiveState = React.useMemo(
    () =>
      selectedImages.length === 0
        ? "mixed"
        : selectedImages.every((image) => image.isArchived)
          ? "archived"
          : selectedImages.every((image) => !image.isArchived)
            ? "notArchived"
            : "mixed",
    [selectedImages]
  );

  const canToggleArchive = selectedImagesArchiveState !== "mixed";
  const applyToSelectedImages = React.useCallback(
    (makeUpdates: (changes: MedicalImageVO) => MedicalImageVO) => {
      handleMountChanged({
        updates: {
          images: mount.images?.map((image: MedicalImageVO) => {
            if (selectedImageIds.has(image.id ?? -1)) {
              const updates = makeUpdates(image);

              return updates;
            }

            return image;
          }),
        },
      });
    },
    [handleMountChanged, mount.images, selectedImageIds]
  );

  const onArchiveToggle = React.useCallback(() => {
    applyToSelectedImages((image) => {
      return produce(image, (draft) => {
        draft.isArchived = !image.isArchived;
      });
    });
    onUpdateParams({ selectedImageIds: undefined });
  }, [applyToSelectedImages, onUpdateParams]);

  return (
    <>
      <div className="flex flex-wrap gap-3 md:gap-4 items-center">
        <CompareImagesMenu
          patientId={patient.id}
          selectedImages={selectedImages}
          params={params}
          onUpdateParams={onUpdateParams}
          mountId={mount.id}
        />

        <MountIconButton
          tooltip={
            canToggleArchive
              ? {
                  content: selectedImagesArchiveState === "archived" ? "Unarchive" : "Archive",
                  theme: "SMALL",
                }
              : undefined
          }
          onClick={onArchiveToggle}
          disabled={!canToggleArchive}
          SvgIcon={selectedImagesArchiveState === "archived" ? UnarchiveIcon : ArchiveIcon}
        />
        <MountIconButton
          tooltip={
            selectedImageIds.size > 0
              ? {
                  content: "Transfer Images",
                  theme: "SMALL",
                }
              : undefined
          }
          onClick={transferMountModal.on}
          disabled={selectedImageIds.size === 0}
          SvgIcon={TransferIcon}
        />
        <ExportMenu patient={patient} mount={mount} selectedImages={selectedImages} {...rest} />

        <VerticalDivider size="sm" theme="darkBackground" />
      </div>
      {transferMountModal.isOn && (
        <TransferImagesFlyover
          isLoading={isTransferring}
          onClose={transferMountModal.off}
          onTransfer={handleTransferMount}
          sourceMount={mount}
          sourcePatientId={patient.id}
        />
      )}
      {confirmTransferMountModal.isOpen && (
        <TransferConfirmationModal
          confirmationNavigateData={confirmTransferMountModal.item}
          onClose={confirmTransferMountModal.close}
          transferType="IMAGES"
        />
      )}
    </>
  );
};
