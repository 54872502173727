/*
  If the page you are navigating to will render the same patient in the patient blurb, the scroll position
  will remain.  If it is a different patient and that patient has already persisted state on that url it
  will be preserved

*/

let isOpen = true;

export const getPatientBlurbIsOpen = () => {
  return isOpen;
};

export const setPatientBlurbIsOpen = (value: boolean) => {
  isOpen = value;
};
