import React from "react";
import { ProductionByProviderComboVO, ProductionTotalVO, NameVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";
import { PRODUCTION_BY_HYGIENIST_COLUMNS } from "components/Dashboard/PracticeProduction/constants";
import { TimeSegment } from "utils/routing/dashboard";
import { useCurrentUrl } from "contexts/OriginContext";

type InputParams = {
  hygienistCombos: {
    hygienistName: NameVO;
    subtotals: ProductionByProviderComboVO[];
    total: number;
  }[];
  unassigned: {
    subtotals: ProductionByProviderComboVO[];
    total: number;
  };
  totals?: ProductionTotalVO;
};

const formatSection = ({
  sectionName,
  dentists,
}: {
  sectionName: string;
  dentists: ProductionByProviderComboVO[];
}) => {
  return dentists.map(({ dentistName, grossProductionPerPatient, patientCount, totalProducedAmount }) => {
    const row = formatCsvRow([
      sectionName,
      dentistName.shortDisplayName,
      `${patientCount}`,
      formatCurrency(grossProductionPerPatient),
      formatCurrency(totalProducedAmount),
    ]);

    return row;
  });
};
const formatProviderDataAsCsvUrl = ({ hygienistCombos, unassigned, totals }: InputParams) => {
  if (!totals) {
    return [];
  }

  const columnTitles = PRODUCTION_BY_HYGIENIST_COLUMNS.map(({ label }) => label).join(",");

  const sections = hygienistCombos.flatMap((combos) => {
    return formatSection({
      sectionName: combos.hygienistName.shortDisplayName,
      dentists: combos.subtotals,
    });
  });

  const unassignedSection = formatSection({
    sectionName: "No Hygienist",
    dentists: unassigned.subtotals,
  });

  const totalRow = formatCsvRow(["Total", "", "", "", formatCurrency(totals.totalProducedAmount)]);

  return [columnTitles, ...sections, ...unassignedSection, totalRow];
};

export const useDownloadProductionByHygienistCSV = ({
  selectedTimeSegment,
  hygienistCombos,
  unassigned,
  totals,
}: {
  selectedTimeSegment: TimeSegment;
} & InputParams) => {
  const currentUrl = useCurrentUrl();

  return {
    downloadCSV: React.useCallback(() => {
      const docMetadata = getArchyCsvMetadata(currentUrl, selectedTimeSegment);
      const csvRows = [
        ...docMetadata.headers,
        ...formatProviderDataAsCsvUrl({
          hygienistCombos,
          unassigned,
          totals,
        }),
      ];

      saveCsv(csvRows, `ProductionByHygienist_${docMetadata.fileNameTag}`);
    }, [selectedTimeSegment, hygienistCombos, unassigned, totals, currentUrl]),
  };
};
