import React, { useCallback, useMemo } from "react";
import { MedicalImageVO } from "@libs/api/generated-api";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { isDefined } from "@libs/utils/types";
import { noop } from "@libs/utils/noop";
import {
  ImageDetailProps,
  ImageSharedToolbarProps,
} from "components/PatientProfile/Imaging/MountRoute/types";
import { ImageSideBySideViewer } from "components/PatientProfile/Imaging/ImageSidebarNavigation/ImageSideBySideViewer";
import { SIDEBAR_NAVIGATION } from "components/PatientProfile/Imaging/ImageSidebarNavigation/constants";
import {
  MOUNT_FORMAT_NUMBER_SLOTS,
  UseImageSidebarNavigation,
} from "components/PatientProfile/Imaging/hooks/useImageSidebarNavigation";
import { CompareImagingSortType, MountFormat } from "utils/routing/patient";
import { Sidebar } from "components/PatientProfile/Imaging/ImageSidebarNavigation/Sidebar";
import { RevertChangesModal } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/RevertChangesModal";
import { useRevertChangesInSelectedImages } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/useRevertChangesInSelectedImages";
import { ImagingToolbar } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar";
import { PearlMountFlyoverContent } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/PearlFlyover/PearlMountFlyoverContent";
import { SandboxTipsContent } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ImagingTipsContent";
import { ImageSideBySideCapture } from "components/PatientProfile/Imaging/ImageSidebarNavigation/ImageSideBySideCapture";
import { useImageListContext } from "components/PatientProfile/Imaging/shared/ImageListContext";

type Props = {
  images: MedicalImageVO[];
  format: MountFormat;
  isShowingMetadata?: boolean;
  onSortImages?: (sortCriteria: CompareImagingSortType) => void;
  sortBy?: CompareImagingSortType;
  patientId: number;
  mountId?: number;
  onRevertImage: (image: MedicalImageVO) => Promise<void>;
  medicalImagesInfiniteQuery?: UseInfiniteApiQueryResult<MedicalImageVO[]>;
  onCaptureComplete?: (blob: Blob) => void;
} & UseImageSidebarNavigation &
  ImageDetailProps &
  ImageSharedToolbarProps;

export const ImageSidebarNavigation: React.FC<Props> = ({
  images,
  format,
  onEditImage,
  onImageUpdate,
  onRevertImage,
  isShowingMetadata = false,
  handleImageMounted,
  onSortImages,
  devices,
  selectedImageIds,
  selectedImages,
  patientId,
  medicalImagesInfiniteQuery,
  handleNewSelectedImagesIds,
  onClickTeeth,
  isOnboardedWithPearl,
  mountId,
  sortBy,
  onCaptureComplete,
}) => {
  const totalSelectionSize = MOUNT_FORMAT_NUMBER_SLOTS[format];
  const { layersShown, toggleLayer } = useImageListContext();

  const definedImagesSelected = useMemo(
    () => selectedImages.map((item) => item.image).filter(isDefined),
    [selectedImages]
  );
  const handleImagesReverted = useCallback(
    (revertedImages: MedicalImageVO[]) => {
      for (const image of revertedImages) {
        onRevertImage(image);
      }
    },
    [onRevertImage]
  );
  const { handleRevertChanges, revertChangesModal } = useRevertChangesInSelectedImages({
    handleImagesReverted,
    selectedImages: definedImagesSelected,
  });

  const toolsDisabled = useMemo(
    () =>
      new Set(
        [
          "draw" as const,
          "filter" as const,
          "transform" as const,
          selectedImages.length === 0 ? ("revert" as const) : undefined,
        ].filter(isDefined)
      ),
    [selectedImages.length]
  );

  React.useEffect(() => {
    const needsUpdate = selectedImageIds.filter((item) => item !== null).length > totalSelectionSize;

    if (!needsUpdate) {
      return;
    }

    const newSelections = [...selectedImageIds];

    for (let i = selectedImageIds.length - 1; i >= totalSelectionSize; i--) {
      newSelections[i] = null;
    }

    handleNewSelectedImagesIds(newSelections);
  }, [selectedImageIds, totalSelectionSize, handleNewSelectedImagesIds]);

  const selectedImageIdSet = useMemo(() => new Set(selectedImageIds.filter(isDefined)), [selectedImageIds]);
  const sharedProps = {
    onImageMounted: handleImageMounted,
    onImageUpdate,
    isShowingMetadata,
    devices,
    onClickTeeth,
  };
  const imagesForAI = useMemo(
    () =>
      definedImagesSelected.filter(
        (image) =>
          (image.type === "X_RAY" || image.type === "EXTERNAL") &&
          !image.isArchived &&
          !image.pearlAnalysis?.errorMessage
      ),
    [definedImagesSelected]
  );

  return (
    <>
      {revertChangesModal.isOn && (
        <RevertChangesModal
          onCancel={revertChangesModal.off}
          onConfirm={handleRevertChanges}
          imageCount={selectedImages.length}
        />
      )}
      <div className="flex flex-row justify-center flex-1 min-h-0 relative">
        <ImagingToolbar
          sectionsDisabled={toolsDisabled}
          disabledMessage="Open image to use"
          onMirror={noop}
          onRotate={noop}
          onToggleLayer={toggleLayer}
          menuAnimation="flyover"
          layersShown={layersShown}
          imagesForAI={imagesForAI}
          isOnboardedWithPearl={isOnboardedWithPearl}
          onRevertChanges={revertChangesModal.on}
          pearlFlyoverContent={
            <PearlMountFlyoverContent
              selectedImageIds={selectedImageIdSet}
              images={imagesForAI}
              patientId={patientId}
              mountId={mountId}
            />
          }
          tipsFlyoverContent={<SandboxTipsContent />}
        />
        <Sidebar
          {...sharedProps}
          images={images}
          sortBy={sortBy}
          selectedImageIds={selectedImageIds}
          onSortImages={onSortImages}
          medicalImagesInfiniteQuery={medicalImagesInfiniteQuery}
        />
        <ImageSideBySideViewer
          {...sharedProps}
          images={selectedImages}
          onEditImage={onEditImage}
          isShowingMetadata={isShowingMetadata}
          canDragFrom={totalSelectionSize > 0}
          format={format}
          dragDropType={SIDEBAR_NAVIGATION}
        />
      </div>
      {onCaptureComplete && (
        <ImageSideBySideCapture
          images={selectedImages}
          format={format}
          onCaptureComplete={(blob) => {
            if (blob) {
              onCaptureComplete(blob);
            }
          }}
        />
      )}
    </>
  );
};
