import React from "react";
import {
  InsuranceAdjustmentEntryVO,
  InsuranceAdjustmentResponse,
  InsuranceAdjustmentSubtotalVO,
} from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { usePick } from "@libs/hooks/usePick";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { useTableTabsProps } from "components/Dashboard/Adjustments/hooks/useTableTabsProps";
import { SelectInsuranceCarrierFilters } from "components/Dashboard/Tables/SelectInsuranceCarrierFilters";
import { useAdjustmentQueriesAsPieData } from "components/Dashboard/Adjustments/hooks/useAdjustmentQueriesAsPieData";
import { useFilterTokenProps } from "components/Dashboard/hooks/useFilterTokenProps";
import { flattenCollectionByCarrier } from "components/Dashboard/utils/flattenCollectionByCarrier";
import { useQueryParams } from "hooks/useQueryParams";
import { DashboardAdjustmentsQuery } from "utils/routing/dashboard";
import { getInsuranceAdjustmentStatsInfiniteQuery } from "api/reporting/queries";
import { getTotals, MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";
import { DashboardTableFilters } from "components/Dashboard/Tables/DashboardTableFilters";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import { INSURANCE_ADJUSTMENT_HEADERS } from "components/Dashboard/Adjustments/constants";
import { CarrierHeaderRow } from "components/Dashboard/Tables/CarrierHeaderRow";
import { InsuranceAdjustmentsTableRow } from "components/Dashboard/Adjustments/InsuranceAdjustmentsTableRow";
import { InsuranceAdjustmentsFooterRow } from "components/Dashboard/Adjustments/InsuranceAdjustmentsFooterRow";
import { FooterCell } from "components/Dashboard/Tables/FooterCell";
import { DashboardFilterFormFieldSelect } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";
import { useSelectedTimeWindowQueries } from "components/Dashboard/Adjustments/hooks/useSelectedTimeWindowQueries";
import { DashboardTableTabs } from "components/Dashboard/Tables/DashboardTableTabs";
import { useDownloadInsuranceAdjustmentsCsv } from "components/Dashboard/Adjustments/hooks/useDownloadInsuranceAdjustmentsCsv";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";

const ADJUST_STATUS_OPTIONS = [
  {
    value: "ESTIMATED" as const,
    label: "Estimated",
  },
  {
    value: "COLLECTED" as const,
    label: "Collected",
  },
];
const getRowKey = (row: InsuranceAdjustmentEntryVO, index: number, nestedIndex: number) => {
  return `${row.claimUuid ?? ""}-${row.serviceDate}-${row.insuranceCarrierId}-${
    row.patient.id
  }-${index}-${nestedIndex}`;
};

export const InsuranceAdjustmentsTableRoute = () => {
  const { practiceId } = useAccount();
  const { query, updateQuery } = useQueryParams("dashboardAdjustments");
  const handleRouteStateChange = React.useCallback(
    (updates: Partial<DashboardAdjustmentsQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );
  const { selectedTimeSegmentParams, selectedTimeSegment } = useTimeSeriesPageSelections(query);
  const tableTabProps = useTableTabsProps({ query, handleRouteStateChange });
  const {
    carrierAdjustmentsForSelectedTimePeriodQuery,
    collectionAdjustmentsForSelectedTimePeriodQuery,
    carriersQuery,
    adjustmentTypesQuery,
  } = useSelectedTimeWindowQueries();
  const pieData = useAdjustmentQueriesAsPieData(
    carrierAdjustmentsForSelectedTimePeriodQuery,
    collectionAdjustmentsForSelectedTimePeriodQuery,
    carriersQuery,
    adjustmentTypesQuery,
    query.filters
  );
  const carriersInSelectedSegment = React.useMemo(() => {
    return pieData
      .filter((item) => item.adjustmentCategory === "insurance")
      .map(({ key, color, actualValue }) => ({
        color,
        id: Number.parseInt(key, 10),
        valueCents: actualValue,
      }));
  }, [pieData]);

  const insuranceAdjustmentInfiniteQuery = useInfiniteApiQuery(
    getInsuranceAdjustmentStatsInfiniteQuery({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: MAX_PAGE_SIZE,
        data: {
          ...selectedTimeSegmentParams,
          filters: query.filters,
          includeTotal: true,
          patientSearchString: query.patientSearch,
          sortOrders: query.insuranceTableSort,
        },
      },
    })
  );
  const isLoadingResults =
    insuranceAdjustmentInfiniteQuery.isLoading || insuranceAdjustmentInfiniteQuery.isInitialLoading;
  const data = React.useMemo(() => {
    return flattenCollectionByCarrier<
      InsuranceAdjustmentEntryVO,
      InsuranceAdjustmentSubtotalVO,
      InsuranceAdjustmentResponse
    >(insuranceAdjustmentInfiniteQuery.data);
  }, [insuranceAdjustmentInfiniteQuery.data]);
  const isEmpty = !insuranceAdjustmentInfiniteQuery.error && !isLoadingResults && data.length === 0;

  const totalRows = getInfiniteQueryPagingDetails(insuranceAdjustmentInfiniteQuery.data)?.totalElements;

  const handleSortClick = React.useCallback(
    (insuranceTableSort: ColumnSortOrder[]) => {
      updateQuery("replaceIn", {
        insuranceTableSort,
      });
    },
    [updateQuery]
  );
  const filterParams = usePick(query, ["filters", "patientSearch"]);
  const filterProps = useFilterTokenProps({ options: filterParams, carriers: carriersQuery.data });
  const totals = React.useMemo(() => {
    return insuranceAdjustmentInfiniteQuery.data
      ? getTotals<InsuranceAdjustmentResponse>(insuranceAdjustmentInfiniteQuery.data)
      : undefined;
  }, [insuranceAdjustmentInfiniteQuery.data]);
  const { downloadCSV, isDownloading } = useDownloadInsuranceAdjustmentsCsv({
    insuranceAdjustmentInfiniteQuery,
    selectedTimeSegment,
  });

  return (
    <>
      <DashboardTableTabs
        {...tableTabProps}
        isDownloading={isDownloading}
        onClickDownload={downloadCSV}
        id="insurance-adjustments-table-tabs"
      >
        <SelectInsuranceCarrierFilters
          carriersQuery={carriersQuery}
          filters={query.filters}
          carriersInSelectedSegment={carriersInSelectedSegment}
          dashboardFilterType="insuranceCarrierId"
          onChange={({ filters }) => {
            updateQuery("replaceIn", {
              filters,
            });
          }}
        />
        <DashboardFilterFormFieldSelect
          placeholder="Adjust Status"
          isSearchable={false}
          dashboardFilters={query.filters}
          dashboardFilterType="insuranceAdjustmentState"
          options={ADJUST_STATUS_OPTIONS}
          className="min-w-[140px]"
          onChangeFilters={handleRouteStateChange}
        />
      </DashboardTableTabs>
      <DashboardTableFilters {...filterParams}>
        <QueryFilterPills
          {...filterProps}
          numResults={totalRows}
          onUpdateParams={(updatedQuery) => {
            updateQuery("replaceIn", updatedQuery);
          }}
          onClearAll={() => {
            updateQuery("replaceIn", { filters: [], patientSearch: undefined });
          }}
        />
      </DashboardTableFilters>
      <DashboardInfiniteTable
        infiniteQuery={insuranceAdjustmentInfiniteQuery}
        columnWidths={INSURANCE_ADJUSTMENT_HEADERS.map(({ width }) => width)}
        id="insuranceAdjustmentsTable"
        isEmpty={isEmpty}
        headerRow={
          <DashboardHeaderRow
            headers={INSURANCE_ADJUSTMENT_HEADERS}
            onSortClick={handleSortClick}
            sortOrders={query.insuranceTableSort}
          />
        }
        footerRow={
          <>
            <FooterCell>Totals</FooterCell>
            <FooterCell className="col-span-4" />
            <FooterCell loading={isLoadingResults} align="right">
              {totals && formatCurrency(totals.totalUcrFeeAmount)}
            </FooterCell>
            <FooterCell loading={isLoadingResults} align="right">
              {totals && formatCurrency(totals.totalAdjustmentAmount)}
            </FooterCell>
            <FooterCell loading={isLoadingResults} align="right">
              {totals && formatCurrency(totals.totalNetProductionAmount)}
            </FooterCell>
            <FooterCell />
          </>
        }
      >
        {data.map(({ rows, subtotal }, i) => {
          return (
            <React.Fragment key={i}>
              <CarrierHeaderRow carrier={subtotal.carrier} />
              {rows.map((item, j) => (
                <InsuranceAdjustmentsTableRow
                  practiceId={practiceId}
                  key={getRowKey(item, i, j)}
                  data={item}
                  last={j === rows.length - 1}
                />
              ))}
              <InsuranceAdjustmentsFooterRow data={subtotal} />
            </React.Fragment>
          );
        })}
      </DashboardInfiniteTable>
    </>
  );
};
