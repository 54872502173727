import { useMemo } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { formatISOTimeAsAmPm, isISOTime, formatISODate } from "@libs/utils/date";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";

import { FormFieldButton, FormFieldButtonProps } from "@libs/components/UI/FormFieldButton";
import {
  AutoCalcEndTimeRangeMenuProps,
  EditEndTimeTimeRangeMenuProps,
  TimeRangeMenu,
  getEndTime,
} from "components/UI/TimeRangeMenu";

type DisplayProps = {
  date?: string;
  buttonContent?: FormFieldButtonProps["buttonContent"];
  placeholder?: string;
};

type CustomFormFieldButtonProps = Omit<FormFieldButtonProps, "onChange" | "buttonContent">;
export type FormFieldAutoCalcEndTimeRangeMenuProps = CustomFormFieldButtonProps &
  AutoCalcEndTimeRangeMenuProps &
  DisplayProps;

export type FormFieldEditEndTimeTimeRangeMenuProps = CustomFormFieldButtonProps &
  EditEndTimeTimeRangeMenuProps &
  DisplayProps;

const separateProps = (
  props: FormFieldAutoCalcEndTimeRangeMenuProps | FormFieldEditEndTimeTimeRangeMenuProps
) => {
  const { startTime, validation, ...restProps } = props;
  const commonProps = { startTime, validation };

  if (restProps.rangeType === "durationInMinutes") {
    const { durationInMinutes, rangeType, onChange, ...formFieldProps } = restProps;

    return {
      formFieldProps,
      timeRangeMenuProps: {
        ...commonProps,
        rangeType,
        onChange,
        durationInMinutes,
      },
    };
  }

  const { endTime, rangeType, onChange, ...formFieldProps } = restProps;

  return {
    formFieldProps,
    timeRangeMenuProps: {
      ...commonProps,
      endTime,
      onChange,
      rangeType,
    },
  };
};

export const useFormFieldTimeRangeMenu = ({
  buttonContent,
  date,
  placeholder,
  ...props
}: FormFieldAutoCalcEndTimeRangeMenuProps | FormFieldEditEndTimeTimeRangeMenuProps) => {
  const dateTimeMenu = useBoolean(false);
  const endTime = getEndTime(props);
  const { timeRangeMenuProps, formFieldProps } = separateProps(props);

  const content = useMemo(() => {
    if (buttonContent) {
      return buttonContent;
    }

    let text = "";

    if (date) {
      text = formatISODate(date);
    }

    if (isISOTime(timeRangeMenuProps.startTime) && isISOTime(endTime)) {
      const timeString = `${formatISOTimeAsAmPm(timeRangeMenuProps.startTime)} - ${formatISOTimeAsAmPm(
        endTime
      )}`;

      if (date) {
        text = `${text} | ${timeString}`;

        return text;
      }

      return timeString;
    }

    return text;
  }, [buttonContent, date, timeRangeMenuProps.startTime, endTime]);
  const contentIsEmpty = content === "";

  return {
    content: contentIsEmpty ? <div className="text-slate-400">{placeholder}</div> : content,
    formFieldProps,
    timeRangeMenuProps,
    onRequestClose: dateTimeMenu.off,
    isOpen: dateTimeMenu.isOn,
    onRequestOpen: dateTimeMenu.on,
  };
};

export const FormFieldTimeRangeMenu: React.FC<
  FormFieldAutoCalcEndTimeRangeMenuProps | FormFieldEditEndTimeTimeRangeMenuProps
> = ({ className, Icon, iconSize, ...props }) => {
  const { onRequestClose, onRequestOpen, isOpen, formFieldProps, timeRangeMenuProps, content } =
    useFormFieldTimeRangeMenu(props);

  return (
    <ButtonMenu
      className={className}
      onRequestClose={onRequestClose}
      onRequestOpen={onRequestOpen}
      isOpen={isOpen}
      placement="bottom-start"
      menuContent={<TimeRangeMenu onRequestClose={onRequestClose} {...timeRangeMenuProps} />}
    >
      {(buttonMenuProps) => (
        <FormFieldButton
          {...buttonMenuProps}
          {...formFieldProps}
          Icon={Icon ?? EditIcon}
          iconSize={iconSize ?? "sm"}
          buttonContent={content}
        />
      )}
    </ButtonMenu>
  );
};
