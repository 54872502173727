import { Route } from "react-router-dom";
import { useMemo } from "react";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { NotFound } from "components/NotFoundRoute";
import { SubscriberRoute } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/SubscriberTab/SubscriberRoute";
import { CarrierAndPlanRoute } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/CarriersAndPlansTab/CarrierAndPlanRoute";
import { DeductiblesMaxCoverageRoute } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/DedMaxAndCoverageTab/DeductiblesMaxCoverageRoute";
import { LimitationsRoute } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/LimitationsRoute";

export const PatientInsurancePlanDetailsRoute: React.FC = () => {
  const InsurancePlanDetailsTabs = useMemo(
    () => [
      {
        element: <SubscriberRoute />,
        path: "info",
      },
      {
        element: <CarrierAndPlanRoute />,
        path: "carrier",
      },
      {
        element: <DeductiblesMaxCoverageRoute />,
        path: "coverage",
      },
      {
        element: <LimitationsRoute />,
        path: "limitations",
      },
    ],
    []
  );

  return (
    <SentryRoutes>
      {InsurancePlanDetailsTabs.map(({ element, path }, i) => (
        <Route element={element} key={i} path={path} />
      ))}
      <Route element={<NotFound />} path="*" />
    </SentryRoutes>
  );
};
