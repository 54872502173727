import { FC, PropsWithChildren, useMemo } from "react";

import { Pill } from "@libs/components/UI/Pill";
import { getLightColor } from "@libs/domains/scheduling/colors";

interface Props {
  name: string;
  color: string;
}

export const ProviderPill: FC<PropsWithChildren<Props>> = ({ name, color, children }) => {
  const lightColor = useMemo(() => getLightColor(color), [color]);

  return (
    <Pill className="w-fit relative" style={{ backgroundColor: lightColor }} theme="custom">
      {name}
      {children}
    </Pill>
  );
};
