import { FC, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { GustoLoadingOverlay } from "components/Settings/PracticeSetup/Sections/PayrollInformation/GustoLoadingOverlay";

import { gustoLinkPractice } from "api/payroll/mutations";

import { useQueryParams } from "hooks/useQueryParams";

import { handleError } from "utils/handleError";
import { paths } from "utils/routing/paths";

export const GustoAuthenticateRoute: FC = () => {
  const navigate = useNavigate();
  const { query } = useQueryParams("authenticateGusto");
  const { practiceId } = useAccount();

  const [{ mutate: link }] = useApiMutations([gustoLinkPractice]);

  useEffect(() => {
    if (query.code) {
      link(
        { practiceId, code: query.code },
        {
          onSuccess: () => {
            navigate(paths.settingsSection({ section: "payroll-information" }), { replace: true });
          },
          onError: (err) => {
            handleError(err);
            navigate(paths.settingsSection({ section: "payroll-information" }), { replace: true });
          },
        }
      );
    } else {
      navigate(paths.settingsSection({ section: "payroll-information" }), { replace: true });
    }
  }, [query.code, link, navigate, practiceId]);

  return <GustoLoadingOverlay />;
};
