import { QueryClient } from "@tanstack/react-query";
import {
  AddFeeScheduleRequest,
  ArchiveFeeScheduleRequest,
  UpdateFeeScheduleRequest,
} from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import { invalidateProceduresAndTreatmentPlan } from "api/charting/mutations";

const invalidateFeeSchedules = (queryClient: QueryClient, practiceId: number) => {
  queryClient.invalidateQueries([getQueryKey("v2", "getFeeSchedulesV2"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getPracticeFeeScheduleNames"), { practiceId }]);
};

export const addFeeSchedule = makeMutation({
  mutationKey: ["practices", "addFeeSchedule"],
  formatParams: (args: { practiceId: number; data: AddFeeScheduleRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      invalidateFeeSchedules(queryClient, practiceId);
      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId });
    },
  }),
});

export const updateFeeSchedule = makeMutation({
  mutationKey: ["practices", "updateFeeSchedule"],
  formatParams: (args: { practiceId: number; feeScheduleId: number; data: UpdateFeeScheduleRequest }) => [
    args.practiceId,
    args.feeScheduleId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId, feeScheduleId }) => {
      invalidateFeeSchedules(queryClient, practiceId);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPracticeInsuranceCarriers"),
        { practiceId },
      ]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getFeeScheduleDetails"),
        { practiceId, feeScheduleId },
      ]);
      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId });
    },
  }),
});

export const archiveFeeSchedule = makeMutation({
  mutationKey: ["practices", "archiveFeeSchedule"],
  formatParams: (args: { practiceId: number; feeScheduleId: number; data: ArchiveFeeScheduleRequest }) => [
    args.practiceId,
    args.feeScheduleId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      invalidateFeeSchedules(queryClient, practiceId);
      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId });
    },
  }),
});

export const unarchiveFeeSchedule = makeMutation({
  mutationKey: ["practices", "unarchiveFeeSchedule"],
  formatParams: (args: { practiceId: number; feeScheduleId: number }) => [
    args.practiceId,
    args.feeScheduleId,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      invalidateFeeSchedules(queryClient, practiceId);
      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId });
    },
  }),
});
