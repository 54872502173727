import { FC } from "react";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as SendIcon } from "@libs/assets/icons/send.svg";

interface Props {
  smsTemplate: string | undefined;
  onSendTest: Func;
}

export const SMSMessageActions: FC<Props> = ({ smsTemplate, onSendTest }) => {
  return (
    <ButtonIcon
      theme="primary"
      tooltip={
        smsTemplate
          ? { theme: "SMALL", content: "Send Test SMS" }
          : { theme: "MEDIUM", content: "Preview is available once you've entered a message." }
      }
      disabled={!smsTemplate}
      onClick={onSendTest}
      SvgIcon={SendIcon}
    />
  );
};
