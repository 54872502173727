// Provides a formatter for dates, given a selected date resolution

import { getYear, parseISO, differenceInDays } from "date-fns";
import React from "react";

const BEGINNING_OF_TIME = 1970;
const NINETY_ONE_DAYS = 91;
const SIXTY_ONE_DAYS = 61;
const THIRTY_ONE_DAYS = 31;

// if concise is true, smaller date strings are formatted
export const useCollectionsChartDateFormatter = () => {
  return React.useCallback((val: string) => {
    const date = parseISO(val);
    const now = new Date();

    if (getYear(date) === BEGINNING_OF_TIME) {
      return "121+ days";
    } else if (differenceInDays(now, date) >= NINETY_ONE_DAYS) {
      return "91-120 days";
    } else if (differenceInDays(now, date) >= SIXTY_ONE_DAYS) {
      return "61-90 days";
    } else if (differenceInDays(now, date) >= THIRTY_ONE_DAYS) {
      return "31-60 days";
    }

    return "0-30 days";
  }, []);
};
