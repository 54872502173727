import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { FeeScheduleListVO, ProviderVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatISODate } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Icon } from "@libs/components/UI/Icon";
import { Spinner } from "@libs/components/UI/Spinner";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EllipsisIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as CheckmarkIcon } from "@libs/assets/icons/check-circle-1.svg";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel-circle.svg";
import { ReactComponent as MinusIcon } from "@libs/assets/icons/minus.svg";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info-small.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as CopyIcon } from "@libs/assets/icons/copy.svg";
import { ReactComponent as DownloadIcon } from "@libs/assets/icons/download.svg";

import { ReactComponent as ExternalLinkIcon } from "@libs/assets/icons/external-link.svg";
import { ReactComponent as ArchiveIcon } from "@libs/assets/icons/archive.svg";
import { ReactComponent as UnarchiveIcon } from "@libs/assets/icons/unarchive.svg";
import { Pill } from "@libs/components/UI/Pill";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { getLightColor } from "@libs/domains/scheduling/colors";
import designConfig from "@libs/design.config";
import {
  ButtonCell,
  Cell,
  EMPTY_CELL,
  Row,
  cxGridTableStyles,
} from "@libs/components/UI/GridTableComponents";
import { Avatar } from "components/UI/Avatar";
import { paths } from "utils/routing/paths";
import { useDownloadFeeScheduleCSV } from "components/Settings/FeeSchedules/hooks/useDownloadFeeScheduleCSV";

interface Props {
  feeSchedule: FeeScheduleListVO;
  onArchive: (feeSchedule: FeeScheduleListVO) => void;
  onCopy: (feeScheduleToCopyId: number) => void;
  onEdit: (feeScheduleId: number | "new") => void;
  onUnarchive: (feeScheduleId: number) => void;
  providersByIdMap: Record<number, ProviderVO | undefined>;
}

const PRACTICE_UCR_TOOLTIP = "This fee schedule is the practice default fee schedule and cannot be archived.";

// eslint-disable-next-line complexity
export const FeeSchedulesTableRow: React.FC<Props> = ({
  feeSchedule,
  onArchive,
  onCopy,
  onEdit,
  onUnarchive,
  providersByIdMap,
}) => {
  const navigate = useNavigate();
  const handleNavigateToPage = useCallback(() => {
    onEdit(feeSchedule.id);
  }, [feeSchedule.id, onEdit]);

  const cellProps = {
    className: "flex items-center",
    onDoubleClick: handleNavigateToPage,
    verticalPadding: "slim" as const,
  };
  const menu = useBoolean(false);
  const isArchived = feeSchedule.state === "ARCHIVED";
  const { downloadFeeScheduleCSV, isDownloadingCSV } = useDownloadFeeScheduleCSV({
    feeScheduleId: feeSchedule.id,
  });

  const menuItems = useMemo(() => {
    return [
      {
        label: "Edit",
        onClick: () => {
          onEdit(feeSchedule.id);
          menu.off();
        },
        SvgIcon: EditIcon,
      },
      {
        label: "Copy",
        SvgIcon: CopyIcon,
        onClick: () => {
          onCopy(feeSchedule.id);
          menu.off();
        },
      },
      {
        label: isArchived ? "Unarchive" : "Archive",
        disabled: feeSchedule.type === "PRACTICE_UCR",
        SvgIcon: isArchived ? UnarchiveIcon : ArchiveIcon,
        onClick: () => {
          isArchived ? onUnarchive(feeSchedule.id) : onArchive(feeSchedule);
          menu.off();
        },
        tooltip: { content: feeSchedule.type === "PRACTICE_UCR" ? PRACTICE_UCR_TOOLTIP : "" },
      },
      {
        label: "Export as CSV",
        SvgIcon: DownloadIcon,
        onClick: async () => {
          await downloadFeeScheduleCSV();
          menu.off();
        },
        isLoading: isDownloadingCSV,
      },
    ];
  }, [
    downloadFeeScheduleCSV,
    feeSchedule,
    isArchived,
    isDownloadingCSV,
    menu,
    onArchive,
    onCopy,
    onEdit,
    onUnarchive,
  ]);

  return (
    <Row key={feeSchedule.id}>
      <ButtonCell {...cellProps} className={cx("gap-x-2", cellProps.className)}>
        {feeSchedule.name}
        {feeSchedule.type === "PRACTICE_UCR" && (
          <Icon
            SvgIcon={InfoIcon}
            tooltip={{
              content: PRACTICE_UCR_TOOLTIP,
            }}
          />
        )}
      </ButtonCell>
      <ButtonCell {...cellProps}>
        {feeSchedule.providers?.length ? (
          feeSchedule.providers.map((provider, index) => (
            <div className={cx(index !== 0 && "relative -left-0.5")} key={provider.id}>
              <Avatar
                customTheme={{
                  backgroundColor: getLightColor(
                    providersByIdMap[provider.id]?.color ?? designConfig.colors.grey["900"]
                  ),
                  color: designConfig.colors.grey["900"],
                }}
                name={provider.fullDisplayName}
                size="sm"
              />
            </div>
          ))
        ) : (
          <Pill theme="grey">All providers</Pill>
        )}
      </ButtonCell>
      <ButtonCell {...cellProps}>
        {feeSchedule.type === "PRACTICE_UCR"
          ? "Practice UCR"
          : feeSchedule.type === "CARRIER"
            ? "Fee Schedule"
            : "Provider UCR"}
      </ButtonCell>
      {feeSchedule.insurancePlans > 0 ? (
        <Cell {...cellProps} className={cx("px-3 gap-x-2", cellProps.className, cxGridTableStyles.dataCell)}>
          <div className="flex items-center gap-x-2">
            <span>{feeSchedule.insurancePlans}</span>
            <ButtonIcon
              SvgIcon={ExternalLinkIcon}
              onClick={(e) => {
                e.preventDefault();
                navigate(paths.insurancePlans({ feeScheduleId: feeSchedule.id }));
              }}
              theme="slate600"
            />
          </div>
        </Cell>
      ) : (
        <ButtonCell {...cellProps}>{feeSchedule.patients}</ButtonCell>
      )}
      {feeSchedule.patients > 0 ? (
        <Cell {...cellProps} className={cx("px-3 gap-x-2", cellProps.className, cxGridTableStyles.dataCell)}>
          <div className="flex items-center gap-x-2">
            <span>{feeSchedule.patients}</span>
            <ButtonIcon
              SvgIcon={ExternalLinkIcon}
              onClick={(e) => {
                e.preventDefault();
                // eslint-disable-next-line @typescript-eslint/naming-convention
                navigate(paths.patients({ "patientCriteria.feeScheduleId": feeSchedule.id }));
              }}
              theme="slate600"
            />
          </div>
        </Cell>
      ) : (
        <ButtonCell {...cellProps}>{feeSchedule.patients}</ButtonCell>
      )}
      <ButtonCell {...cellProps}>{formatISODate(feeSchedule.startDate)}</ButtonCell>
      <ButtonCell {...cellProps}>
        {feeSchedule.endDate ? formatISODate(feeSchedule.endDate) : EMPTY_CELL}
      </ButtonCell>
      <ButtonCell {...cellProps}>
        <Icon
          SvgIcon={
            feeSchedule.type === "CARRIER" ? (feeSchedule.inNetwork ? CheckmarkIcon : CancelIcon) : MinusIcon
          }
          tooltip={{
            content:
              feeSchedule.type === "CARRIER"
                ? feeSchedule.inNetwork
                  ? "In Network"
                  : "Out of Network"
                : "N/A",
            theme: "SMALL",
          }}
          theme={feeSchedule.type === "CARRIER" ? (feeSchedule.inNetwork ? "success" : "error") : "slate300"}
        />
      </ButtonCell>
      <ButtonCell {...cellProps}>
        <Pill theme={isArchived ? "grey" : "green"}>{isArchived ? "Archived" : "Active"}</Pill>
      </ButtonCell>
      <Cell className={cx("flex items-center pl-3 pr-5", cxGridTableStyles.dataCell)}>
        <ButtonMenu
          className={cellProps.className}
          isOpen={menu.isOn}
          menuContent={
            <div className="flex flex-col w-36">
              {menuItems.map(({ label, onClick, SvgIcon, disabled, tooltip, isLoading }) => (
                <MenuOptionButton
                  key={label}
                  className="gap-x-2"
                  onClick={onClick}
                  disabled={disabled}
                  tooltip={tooltip}
                >
                  {isLoading ? (
                    <Spinner size="sm" variant="secondary" animation="border" />
                  ) : (
                    <Icon SvgIcon={SvgIcon} />
                  )}

                  {label}
                </MenuOptionButton>
              ))}
            </div>
          }
          onRequestClose={menu.off}
          onRequestOpen={menu.on}
          placement="left-start"
        >
          {(props) => (
            <ButtonIcon
              {...props}
              SvgIcon={EllipsisIcon}
              onClick={(e) => {
                e.preventDefault();
                menu.on();
              }}
            />
          )}
        </ButtonMenu>
      </Cell>
    </Row>
  );
};
