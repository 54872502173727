import { FC, useMemo, useCallback } from "react";
import { pluralize } from "@libs/utils/pluralize";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { getFullUrl } from "@libs/utils/location";
import { useLocation } from "react-router-dom";
import { TableGrid } from "@libs/components/UI/GridTableComponents";
import { ScrollableInfiniteQuery } from "@libs/components/UI/ScrollableInfiniteQuery";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { getAsapAppointmentsInfiniteQuery, getWorkingHoursQuery } from "api/scheduling/queries";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { ModalPage } from "components/UI/ModalPage";
import { HeaderData } from "components/Dashboard/Tables/types";
import { EmptyRows, ScheduleAsapListRows } from "components/ScheduleAppointments/ScheduleAsapListTableItems";
import { batchUpdateAsapAppointments } from "api/scheduling/mutations";
import { handleError } from "utils/handleError";
import { useItemModal } from "hooks/useItemModal";
import { RequestsASAPTabs } from "components/ScheduleAppointments/RequestsASAPTabs";
import { PatientSnapshotLayout } from "components/UI/PatientSnapshotLayout";
import { usePatientAppointmentQueryState } from "contexts/PatientAppointmentContext";
import {
  ScheduleAppHistoryProvider,
  useScheduleLinks,
} from "components/ScheduleAppointments/ScheduleLinksContext";
import { useSelectRows } from "hooks/useSelectRows";
import { ScheduleAsapBulkActionsRow } from "components/ScheduleAppointments/ScheduleAsapBulkActionsRow";
import { MessagePatientsFlyover } from "components/Communications/MessagePatients/MessagePatientsFlyover";
import { isUnscheduledAsapAppt } from "components/ScheduleAppointments/utils";

const asapListHeaders: HeaderData[] = [
  {
    id: "checkbox",
    label: "",
    width: "min-content",
  },
  {
    label: "Date & Time",
    width: "min-content",
    sortField: "date",
  },
  {
    label: "Duration",
    width: "min-content",
    sortField: "duration",
  },
  {
    label: "Patient",
    width: "min-content",
  },
  {
    label: "Day Preferences",
    width: "min-content",
  },
  {
    label: "Time Preferences",
    width: "min-content",
  },
  {
    label: "Provider Preference",
    width: "minmax(10rem,14rem)",
  },
  {
    label: "Procedures",
    width: "minmax(10rem,1fr)",
  },
  {
    label: "",
    width: "min-content",
  },
  {
    id: "menu",
    label: "",
    width: "min-content",
  },
];

export const ScheduleAsapList: FC = () => {
  const location = useLocation();
  const { practiceId } = useAccount();
  const patientAppointment = usePatientAppointmentQueryState();
  const { query, updateQuery } = useQueryParams("scheduleAsapList");
  const { links } = useScheduleLinks();
  const backUrl = query.from ?? links.schedule;
  const deleteItemsModal = useItemModal<number[]>(null);

  usePageTitle("ASAP List");

  const asapAppointmentsQueryKey = useMemo(
    () => ({
      pageNumber: 1,
      pageSize: 25,
      sortColumn: query.sortField,
      sortDirection: query.sortDirection,
    }),
    [query.sortField, query.sortDirection]
  );

  const asapAppointmentsQuery = useInfiniteApiQuery(
    getAsapAppointmentsInfiniteQuery({
      args: {
        practiceId,
        ...asapAppointmentsQueryKey,
      },
    })
  );

  const [workingHoursQuery] = useApiQueries([getWorkingHoursQuery({ args: { practiceId } })]);

  const appointments = useFlattenPages(asapAppointmentsQuery.data);
  const appointmentIds = useMemo(() => appointments?.map((appt) => appt.id), [appointments]);
  const totalRows = getInfiniteQueryPagingDetails(asapAppointmentsQuery.data)?.totalElements ?? 0;
  const {
    selectedCount,
    selectedRows,
    deselectedRowsFromSelectAll,
    hasAllSelected,
    selectAllRows,
    resetSelectedRows,
    handleCheckboxChange,
  } = useSelectRows(appointmentIds, { totalItems: totalRows });

  const messagePatientsFlyover = useBoolean(false);
  const bulkMessageSelectionCategory = useMemo(() => {
    if (appointments && selectedRows.size > 0) {
      return appointments.some((appt) => selectedRows.has(appt.id) && isUnscheduledAsapAppt(appt.date))
        ? "ASAP_LIST_UNSCHEDULED"
        : "ASAP_LIST_SCHEDULED";
    }

    return "ASAP_LIST_ALL";
  }, [appointments, selectedRows]);

  const [batchUpdateAsapAppointmentsMutation] = useApiMutations([batchUpdateAsapAppointments]);
  const batchMutateAppointments = batchUpdateAsapAppointmentsMutation.mutate;
  const handleRemoveFromAsapList = useCallback(
    (apptIds: number[]) => {
      const appointmentsSelected = appointments?.filter((appt) => apptIds.includes(appt.id)) ?? [];

      if (appointmentsSelected.length === 0) {
        return;
      }

      batchMutateAppointments(
        {
          practiceId,
          data: {
            asap: false,
            appointments: appointmentsSelected.map(({ id, patient }) => ({ id, patientId: patient.id })),
          },
        },
        { onError: handleError }
      );
    },
    [appointments, batchMutateAppointments, practiceId]
  );

  const handleDeleteIntendedItems = useCallback(() => {
    // Called after user confirms they desire to delete items selected, OR a
    // single item from the right side of a row
    if (!deleteItemsModal.item) {
      return;
    }

    updateQuery("replaceIn", { appointmentId: undefined });
    handleRemoveFromAsapList(deleteItemsModal.item);
    resetSelectedRows();
    deleteItemsModal.close();
  }, [deleteItemsModal, updateQuery, handleRemoveFromAsapList, resetSelectedRows]);

  const deleteCount = deleteItemsModal.item === null ? 0 : deleteItemsModal.item.length;
  const itemsPlural = pluralize(deleteCount, "Item", "Items");

  return (
    <ScheduleAppHistoryProvider name="asapList">
      <PatientSnapshotLayout
        appointmentId={patientAppointment.appointmentId}
        patientId={patientAppointment.patientId}
        onSelectAppointment={patientAppointment.handleAppointmentSelected}
        onDeleteAppointment={patientAppointment.handleAppointmentDeleted}
        emptyText="No Appointment Selected"
      >
        <ModalPage
          title={
            <div className="flex flex-row justify-between">
              <RequestsASAPTabs />
              <LinkIcon
                SvgIcon={PlusIcon}
                size="lg"
                theme="primary"
                tooltip={{ content: "Add patient to ASAP List", theme: "SMALL" }}
                to={paths.scheduleAsap({
                  patientId: patientAppointment.patientId,
                  from: getFullUrl(location),
                })}
              />
            </div>
          }
          closeLink={backUrl}
        >
          <div className="flex flex-col h-full">
            <ScheduleAsapBulkActionsRow
              selectedCount={selectedCount}
              total={totalRows}
              onRequestMessagePatients={messagePatientsFlyover.on}
            />
            {/* TODO: Figure out how to handle bulk delete without all rows selected */}
            {/* <ActionsRow>
              <ButtonIcon
                SvgIcon={DeleteIcon}
                tooltip={{ content: "Remove selected from ASAP List", theme: "SMALL" }}
                onClick={() => deleteItemsModal.open([...selectedRows])}
                disabled={totalRows === 0 || selectedRows.size === 0}
                theme="primary"
              />
            </ActionsRow> */}

            <ScrollableInfiniteQuery
              id="schedulingAsapList"
              infiniteQuery={asapAppointmentsQuery}
              noResults={<EmptyRows type="ASAP" />}
              className="overflow-x-auto"
            >
              {(appts) => {
                return (
                  <TableGrid
                    className="min-w-[80rem]"
                    columnWidths={asapListHeaders.map(({ width }) => width)}
                  >
                    <QueryResult queries={[workingHoursQuery]}>
                      <ScheduleAsapListRows
                        appointments={appts}
                        headers={asapListHeaders}
                        currentAppointmentId={patientAppointment.appointmentId}
                        sortParams={{
                          direction: query.sortDirection === "ASC" ? "ASCENDING" : "DESCENDING",
                          field: query.sortField,
                        }}
                        selectedRows={selectedRows}
                        selectedCount={selectedCount}
                        totalRows={totalRows}
                        onSelectAllRows={selectAllRows}
                        onDeselectAllRows={resetSelectedRows}
                        onCheckboxChange={handleCheckboxChange}
                        onRowClick={(appt) => {
                          updateQuery("replaceIn", { patientId: appt.patient.id, appointmentId: appt.id });
                        }}
                        onRowDelete={(appointment) => deleteItemsModal.open([appointment.id])}
                        onSortToggled={(field) => {
                          if (field === query.sortField) {
                            updateQuery("replaceIn", {
                              sortDirection: query.sortDirection === "ASC" ? "DESC" : "ASC",
                            });
                          } else {
                            updateQuery("replaceIn", { sortField: field });
                          }
                        }}
                        workingHours={workingHoursQuery.data}
                      />
                    </QueryResult>
                  </TableGrid>
                );
              }}
            </ScrollableInfiniteQuery>
          </div>
        </ModalPage>
      </PatientSnapshotLayout>

      {deleteItemsModal.isOpen && (
        <ConfirmationModal
          primaryText={`Delete ${deleteCount} ${itemsPlural}?`}
          secondaryText={`Are you sure you want to delete ${pluralize(
            deleteCount,
            "this item",
            "these items"
          )} from the ASAP
          List?`}
          onCancel={deleteItemsModal.close}
          onConfirm={handleDeleteIntendedItems}
          confirmText={`Delete ${itemsPlural}`}
        />
      )}

      {messagePatientsFlyover.isOn ? (
        <MessagePatientsFlyover
          type="ASAP_LIST"
          criteria={asapAppointmentsQueryKey}
          selectionCategory={bulkMessageSelectionCategory}
          selectedCount={selectedCount}
          filteredCount={totalRows}
          selectedIds={selectedRows}
          deselectedIds={deselectedRowsFromSelectAll}
          hasAllSelected={hasAllSelected}
          onClose={messagePatientsFlyover.off}
        />
      ) : null}
    </ScheduleAppHistoryProvider>
  );
};
