import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { PatientSnapshotLayout, PatientSnapshotLayoutProps } from "components/UI/PatientSnapshotLayout";
import { paths } from "utils/routing/paths";

interface Props
  extends Pick<
    PatientSnapshotLayoutProps,
    "patientId" | "appointmentId" | "onDeleteAppointment" | "onSelectAppointment" | "children"
  > {
  from: string;
}

export const EventPatientSnapshotLayout: React.FC<Props> = ({ from, ...props }) => {
  const navigate = useNavigate();

  const handleAddAppointment = useCallback(
    (appointmentPatientId: number) => {
      const url = paths.addPatientAppointment({ patientId: appointmentPatientId, from });

      navigate(url, { replace: true });
    },
    [navigate, from]
  );

  const handleEditAppointment = useCallback(
    (editAppointmentId: number, appointmentPatientId: number) => {
      const appointmetParams = { appointmentId: editAppointmentId, patientId: appointmentPatientId };
      const url = paths.editAppointment(appointmetParams, {
        from,
      });

      navigate(url, { replace: true });
    },
    [navigate, from]
  );

  return (
    <PatientSnapshotLayout
      onEditAppointment={handleEditAppointment}
      onAddAppointment={handleAddAppointment}
      {...props}
    />
  );
};
