import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";

export const getTotals = <T extends { total?: unknown }>(
  data: UseInfiniteApiQueryResult<T>["data"]
): T["total"] => {
  return data?.pages[0].data.total;
};

export const MAX_PAGE_SIZE = 200;
export const MAX_PAGE_SIZE_DOWNLOAD = 25;
