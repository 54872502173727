import { FC } from "react";
import { BlockerFunction, useBlocker } from "react-router-dom";
import { getFullUrl } from "@libs/utils/location";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";

// By default if the route changes a confirmation message will be rendered
// You can customize this behavior by passing in a shouldBlock prop
export const Prompt: FC<{ message: string; shouldBlock?: BlockerFunction }> = ({ message, shouldBlock }) => {
  const blocker = useBlocker((args) => {
    const hasChangedUrl = getFullUrl(args.currentLocation) !== getFullUrl(args.nextLocation);

    return shouldBlock ? shouldBlock(args) : hasChangedUrl;
  });

  return blocker.state === "blocked" ? (
    <ConfirmationModal
      size="2xs"
      primaryText={message}
      onCancel={() => blocker.reset()}
      onConfirm={() => blocker.proceed()}
    />
  ) : null;
};
