import React, { Fragment, useMemo } from "react";
import { NavLink } from "react-router-dom";
import { ClaimsOverviewVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { QueryResult } from "@libs/components/UI/QueryResult";

type TabIds = "pre-auths" | "claims" | "eob";
export interface ClaimsTabsProps {
  claimsTabsTotalsQuery: ApiQueryResult<ClaimsOverviewVO>;
  tabs: {
    exact?: boolean;
    label: string;
    id: TabIds;
    path: string;
  }[];
}

const tabCounts: Record<TabIds, keyof ClaimsOverviewVO> = {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "pre-auths": "preAuthCount",
  claims: "claimCount",
  eob: "draftEobs",
};

export const ClaimsTabs: React.FC<ClaimsTabsProps> = ({ claimsTabsTotalsQuery, tabs }) => {
  const mappedTabs = useMemo(() => {
    const totals = claimsTabsTotalsQuery.data;

    if (!totals) {
      return tabs;
    }

    return tabs.map((tab) => {
      return {
        ...tab,
        label: `${tab.label} (${totals[tabCounts[tab.id]] ?? 0})`,
      };
    });
  }, [claimsTabsTotalsQuery.data, tabs]);

  return (
    <div
      className={`
        h-12
        px-5
        py-3
        text-base
        text-greyDark
        border-b
        border-greyLighter
      `}
    >
      <QueryResult loading={<LoadingContent className="h-6" />} queries={[claimsTabsTotalsQuery]}>
        {mappedTabs.map((tab, index) => (
          <Fragment key={index}>
            <NavLink
              className={({ isActive }) =>
                cx(
                  "pb-2.5 mr-9 last:mr-0",
                  isActive &&
                    `
              font-sansSemiBold
              text-primaryTheme
              border-b-[3px]
              border-primaryTheme
            `
                )
              }
              end={tab.exact}
              to={tab.path}
            >
              {tab.label}
            </NavLink>
          </Fragment>
        ))}
      </QueryResult>
    </div>
  );
};
