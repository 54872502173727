import { FC } from "react";
import { Link } from "react-router-dom";

import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";

import { getDocumentUrl } from "api/documents/queries";

import { paths } from "utils/routing/paths";

interface Props {
  patientId: number;
  folderId: number;
  documentId: number;
  altText: string;
  isVisible: boolean;
}

export const ChatImage: FC<Props> = ({ patientId, folderId, documentId, altText, isVisible }) => {
  const { practiceId } = useAccount();
  const [documentUrlQuery] = useApiQueries([
    getDocumentUrl({
      args: { practiceId, userId: patientId, folderId, documentId, download: false },
      queryOptions: { enabled: isVisible },
    }),
  ]);

  return (
    <QueryResult
      queries={[documentUrlQuery]}
      loading={<LoadingContent containerTestId="loading-document" className="w-96 h-72 rounded-xl" />}
    >
      {documentUrlQuery.data ? (
        <Link
          className="flex flex-col items-end gap-y-1"
          to={paths.patientDocuments({ patientId }, { folderId, docId: documentId })}
        >
          <img className="rounded-lg" src={documentUrlQuery.data} alt={altText} />
        </Link>
      ) : null}
    </QueryResult>
  );
};
