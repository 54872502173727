import { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { PatientProcedureVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getFullUrl } from "@libs/utils/location";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as HelpIcon } from "@libs/assets/icons/help.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import {
  TableGrid,
  HeaderCell,
  ButtonCell,
  CheckboxCell,
  Row,
} from "@libs/components/UI/GridTableComponents";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { ScrollableInfiniteQuery } from "@libs/components/UI/ScrollableInfiniteQuery";
import { Modal } from "@libs/components/UI/Modal";
import { ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import { getInfinitePatientProceduresQuery, getPatientTreatmentPlansQuery } from "api/charting/queries";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { filtersCxStyles } from "components/Claims/Claims/Filters";
import { EmptyProceduresList } from "components/Charting/EmptyProceduresList";
import { paths } from "utils/routing/paths";
import { createPreAuthClaim } from "api/claim/mutations";
import { handleError } from "utils/handleError";
import { PreAuthLoadingModal } from "components/Charting/PreAuthLoadingModal";

interface Props {
  onClose: () => void;
  patientId: number;
}

const headers = [
  { id: "checkbox", label: "", width: "30px" },
  { id: "code", label: "Code", width: "1fr" },
  { id: "surface", label: "Tooth - Surface", width: "2fr" },
  { id: "description", label: "Procedure Description", width: "4fr" },
  { id: "status", label: "Pre-AuthStatus", width: "2fr" },
];

const cxStyles = {
  searchCell: `
    flex
    items-center
    my-1.5
    px-3
    border-0
    first:pl-0
    group-hover:bg-slate-100
    group-hover:cursor-pointer
  `,
};

const ValidPreAuthStatuses: PatientProcedureVO["status"][] = ["PLANNED", "SCHEDULED"];

export const getToothSurfaceText = (procedure: PatientProcedureVO) => {
  return !procedure.toothName && !procedure.surfaces
    ? "-"
    : `${procedure.toothName ? `#${procedure.toothName}` : ""}${
        procedure.surfaces?.length ? ` - ${procedure.surfaces.join(", ")}` : ""
      }`;
};

export const useCreatePreAuthModal = ({ patientId, ...rest }: Props) => {
  const { practiceId } = useAccount();

  const [selectedTreatmentPlanUuid, setSelectedTreatmentPlanUuid] = useState<string>("");

  const [selectedProcedureIds, setSelectedProcedureIds] = useState<Set<number>>(new Set());

  const handleToggleProcedure = useCallback(
    (procedureId: number) => {
      const selectedProcedureIdsCopy = selectedProcedureIds;

      if (selectedProcedureIds.has(procedureId)) {
        selectedProcedureIdsCopy.delete(procedureId);
      } else {
        selectedProcedureIdsCopy.add(procedureId);
      }

      setSelectedProcedureIds(new Set(selectedProcedureIdsCopy));
    },
    [selectedProcedureIds]
  );

  const handleSelectTP = useCallback((value: string) => {
    setSelectedTreatmentPlanUuid(value);
    setSelectedProcedureIds(new Set());
  }, []);

  const isSending = useBoolean(false);

  return {
    isSending,
    onSelectTP: handleSelectTP,
    onToggleProcedure: handleToggleProcedure,
    patientId,
    practiceId,
    selectedProcedureIds,
    selectedTreatmentPlanUuid,
    ...rest,
  };
};

export const CreatePreAuthModal: React.FC<Props> = (props) => {
  const {
    isSending,
    onClose,
    onSelectTP,
    onToggleProcedure,
    patientId,
    practiceId,
    selectedProcedureIds,
    selectedTreatmentPlanUuid,
  } = useCreatePreAuthModal(props);

  const [treatmentPlansQuery] = useApiQueries([
    getPatientTreatmentPlansQuery({
      args: {
        includeStates: ["ACTIVE", "INACTIVE"],
        patientId,
        practiceId,
      },
    }),
  ]);

  const selectedTPPatientProceduresQuery = useInfiniteApiQuery(
    getInfinitePatientProceduresQuery({
      args: {
        includeStatuses: ValidPreAuthStatuses,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        patientId,
        practiceId,
        treatmentPlanUuid: selectedTreatmentPlanUuid,
      },
    })
  );

  const treatmentPlanOptions = useMemo(() => {
    if (!treatmentPlansQuery.data) {
      return [];
    }

    const options = treatmentPlansQuery.data.map((tp) => {
      return { label: tp.name, value: tp.uuid };
    });

    return [{ label: "Master TP", value: "" }, ...options];
  }, [treatmentPlansQuery.data]);

  const disabledSendTooltip = useMemo(() => {
    if (!selectedTPPatientProceduresQuery.data) {
      return "Loading procedures...";
    } else if (selectedProcedureIds.size === 0) {
      return "Select procedures";
    } else if (isSending.isOn) {
      return "Sending...";
    }

    return "";
  }, [isSending, selectedProcedureIds.size, selectedTPPatientProceduresQuery.data]);

  const navigate = useNavigate();
  const preAuthLoadingModal = useBoolean(false);
  const [createPreAuthClaimMutation] = useApiMutations([createPreAuthClaim]);

  const handleSendPreAuth = useCallback(() => {
    isSending.on();

    const data = {
      patientId,
      patientProcedureIds: [...selectedProcedureIds],
    };

    const fullUrl = getFullUrl(location);

    preAuthLoadingModal.on();
    createPreAuthClaimMutation.mutate(
      { practiceId, data },
      {
        onSuccess: (response) => {
          navigate(paths.claim({ claimUuid: response.data.data.uuid }, { from: fullUrl }));
          isSending.off();
        },
        onError: (err) => {
          preAuthLoadingModal.off();
          isSending.off();
          handleError(err);
        },
      }
    );
  }, [
    createPreAuthClaimMutation,
    navigate,
    isSending,
    patientId,
    practiceId,
    preAuthLoadingModal,
    selectedProcedureIds,
  ]);

  return (
    <>
      <Modal onClose={onClose} title="Create Pre-Auth">
        <ModalContent padding="lg">
          <div className="flex flex-col gap-y-4 text-sm">
            <QueryResult
              loading={
                <div className="h-8 w-[500px]">
                  <LoadingContent />
                </div>
              }
              queries={[treatmentPlansQuery]}
            >
              <div className="flex items-center gap-x-4">
                <span className="font-sansSemiBold">{`Select Procedures from Treatment Plan (${
                  getInfiniteQueryPagingDetails(selectedTPPatientProceduresQuery.data)?.totalElements ?? 0
                })`}</span>
                <FormFieldSelect
                  className={cx("z-30", filtersCxStyles.button)}
                  isClearable={false}
                  layout="labelIn"
                  onItemSelected={onSelectTP}
                  options={treatmentPlanOptions}
                  value={selectedTreatmentPlanUuid}
                />
                <FloatingTooltip content="Additional procedures can be added from the Charting & TX Plan tab.">
                  <HelpIcon className="h-4 w-4 text-primaryTheme" />
                </FloatingTooltip>
              </div>
            </QueryResult>
            <div className="h-[420px] overflow-y-auto">
              <ScrollableInfiniteQuery
                id="pre-auth-tp-procedures-scroll"
                infiniteQuery={selectedTPPatientProceduresQuery}
                loading={
                  <div className="w-full">
                    <Skeleton className="h-10" />
                    <Skeleton className="h-6" count={8} />
                  </div>
                }
                persistScroll={false}
              >
                {(selectedTPProcedures) =>
                  selectedTPProcedures.length ? (
                    <TableGrid columnWidths={headers.map(({ width }) => width)}>
                      {headers.map((item) => (
                        <HeaderCell className="h-10 border-0" key={item.id}>
                          {item.label}
                        </HeaderCell>
                      ))}
                      {selectedTPProcedures.map((procedure) => {
                        const selected = selectedProcedureIds.has(procedure.id);

                        return (
                          <Row isSelected={selected} key={procedure.id}>
                            <CheckboxCell
                              className={cxStyles.searchCell}
                              checked={selected}
                              onChange={() => onToggleProcedure(procedure.id)}
                              styleOptions={{ hasPadding: false }}
                              value={procedure.id}
                            />
                            <ButtonCell
                              className={cxStyles.searchCell}
                              hasPadding={false}
                              onClick={() => onToggleProcedure(procedure.id)}
                            >
                              {procedure.cdtCode}
                            </ButtonCell>
                            <ButtonCell
                              className={cxStyles.searchCell}
                              hasPadding={false}
                              onClick={() => onToggleProcedure(procedure.id)}
                            >
                              {getToothSurfaceText(procedure)}
                            </ButtonCell>
                            <ButtonCell
                              className={cxStyles.searchCell}
                              hasPadding={false}
                              onClick={() => onToggleProcedure(procedure.id)}
                            >
                              <FloatingTooltip
                                content={procedure.description}
                                placement="top-end"
                                theme="SMALL"
                              >
                                <div className="truncate">{procedure.description}</div>
                              </FloatingTooltip>
                            </ButtonCell>
                            <ButtonCell
                              className={cxStyles.searchCell}
                              hasPadding={false}
                              onClick={() => onToggleProcedure(procedure.id)}
                            >
                              {procedure.preAuthStatus
                                ? sentenceCaseConstant(procedure.preAuthStatus)
                                : "N/A"}
                            </ButtonCell>
                          </Row>
                        );
                      })}
                    </TableGrid>
                  ) : (
                    <EmptyProceduresList>
                      No planned or scheduled procedures have been added to this treatment plan.
                    </EmptyProceduresList>
                  )
                }
              </ScrollableInfiniteQuery>
            </div>
          </div>
        </ModalContent>
        <ModalFooter>
          <Button className="w-[120px]" disabled={isSending.isOn} onClick={onClose} theme="secondary">
            Cancel
          </Button>
          <FloatingTooltip content={disabledSendTooltip} theme="SMALL">
            <div>
              <AsyncButton
                className="w-[120px]"
                disabled={Boolean(disabledSendTooltip)}
                isLoading={isSending.isOn}
                onClick={handleSendPreAuth}
                theme="primary"
              >
                Send
              </AsyncButton>
            </div>
          </FloatingTooltip>
        </ModalFooter>
      </Modal>
      {preAuthLoadingModal.isOn ? <PreAuthLoadingModal /> : null}
    </>
  );
};
