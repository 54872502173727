import React, { FC } from "react";
import { LedgerV2AppointmentEditSubEntryVO } from "@libs/api/generated-api";
import { getLocalDate } from "@libs/utils/date";
import { currencyValueOrDash } from "@libs/utils/currency";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import {
  AccordionItem,
  PracticeAndPatientIds,
} from "components/PatientProfile/Billing/Ledger/LedgerComponents";
import { DescriptionSticker } from "components/PatientProfile/Billing/Ledger/DescriptionSticker";
import { Text } from "./Text";
import { ledgerSubEntryToId } from "./ledgerUtils";
import { usePersistExpandRow } from "./usePersistExpandRow";
import { DetailsTable } from "./DetailsTable";
import {
  ExpandableRow,
  DetailsRow,
  TableRow,
  ProcedureDoubleColumnTableRow,
  DescriptionWithPatientInsuranceRow,
} from "./AppointmentsAndAdjustmentsPage";
import { ProviderInitials } from "./ProviderInitials";

export const AppointmentEditExpandable: FC<
  PracticeAndPatientIds & { appointmentEditSubEntry: LedgerV2AppointmentEditSubEntryVO }
> = ({ appointmentEditSubEntry }) => {
  const expandRow = usePersistExpandRow(ledgerSubEntryToId(appointmentEditSubEntry));
  const patientAmountChange =
    appointmentEditSubEntry.patientAmount - appointmentEditSubEntry.previousPatientAmount;
  const insuranceAmountChange =
    appointmentEditSubEntry.insuranceAmount - appointmentEditSubEntry.previousInsuranceAmount;

  return (
    <AccordionItem>
      <ExpandableRow
        itemId={ledgerSubEntryToId(appointmentEditSubEntry)}
        indentation={2}
        date={
          <Text bold>
            <DateWithTooltip date={getLocalDate(appointmentEditSubEntry.timestamp)} format="P" />
          </Text>
        }
        details={
          <DescriptionWithPatientInsuranceRow
            descriptionColumn={<AppointmentEditDescription />}
            patientColumn={undefined}
            insuranceColumn={undefined}
          />
        }
        amount={undefined}
        outstanding={undefined}
        menu={undefined}
        expandedContent={
          appointmentEditSubEntry.procedures.length > 0 ? (
            <DetailsRow indentation={2}>
              <DetailsTable>
                <TableRow
                  descriptionColumn={<Text bold>Procedures</Text>}
                  patientColumn={<Text bold>Pt Resp Prev/New</Text>}
                  insuranceColumn={<Text bold>Ins Pay Prev/New</Text>}
                />
                {appointmentEditSubEntry.procedures.map((procedure) => (
                  <ProcedureDoubleColumnTableRow
                    key={procedure.patientProcedureId}
                    providerInitials={<ProviderInitials provider={procedure.provider} />}
                    cdtCode={<Text>{procedure.cdtCode}</Text>}
                    toothName={<Text>{procedure.procedureArea}</Text>}
                    procedureName={<Text>{procedure.procedureName}</Text>}
                    patientCol1={<Text>{currencyValueOrDash(procedure.previousPatientAmount)}</Text>}
                    patientCol2={<Text>{currencyValueOrDash(procedure.patientAmount)}</Text>}
                    insuranceCol1={<Text>{currencyValueOrDash(procedure.previousInsuranceAmount)}</Text>}
                    insuranceCol2={<Text>{currencyValueOrDash(procedure.insuranceAmount)}</Text>}
                  />
                ))}
                {appointmentEditSubEntry.procedures.length > 1 && (
                  <ProcedureDoubleColumnTableRow
                    providerInitials={undefined}
                    cdtCode={undefined}
                    toothName={undefined}
                    procedureName={undefined}
                    patientCol1={
                      <Text bold>{currencyValueOrDash(appointmentEditSubEntry.previousPatientAmount)}</Text>
                    }
                    patientCol2={
                      <Text bold>{currencyValueOrDash(appointmentEditSubEntry.patientAmount)}</Text>
                    }
                    insuranceCol1={
                      <Text bold>{currencyValueOrDash(appointmentEditSubEntry.previousInsuranceAmount)}</Text>
                    }
                    insuranceCol2={
                      <Text bold>{currencyValueOrDash(appointmentEditSubEntry.insuranceAmount)}</Text>
                    }
                  />
                )}
                <ProcedureDoubleColumnTableRow
                  providerInitials={undefined}
                  cdtCode={undefined}
                  toothName={undefined}
                  procedureName={undefined}
                  patientCol1={undefined}
                  patientCol2={
                    <Text color="orange" bold>
                      {currencyValueOrDash(patientAmountChange)}
                    </Text>
                  }
                  insuranceCol1={undefined}
                  insuranceCol2={
                    <Text color="orange" bold>
                      {currencyValueOrDash(insuranceAmountChange)}
                    </Text>
                  }
                />
              </DetailsTable>
            </DetailsRow>
          ) : (
            <DetailsRow indentation={2}>
              <TableRow
                descriptionColumn={
                  <Text>
                    <i>No procedures changed</i>
                  </Text>
                }
                patientColumn={undefined}
                insuranceColumn={undefined}
              />
            </DetailsRow>
          )
        }
        isExpanded={expandRow.isOn}
        onToggleExpand={expandRow.toggle}
      />
    </AccordionItem>
  );
};

const AppointmentEditDescription: FC = () => {
  return (
    <div className="flex items-center gap-x-2">
      <DescriptionSticker color="appointmentEdit" />
      <Text bold>Appointment Edit</Text>
    </div>
  );
};
