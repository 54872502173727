import { FC, useState } from "react";

import { DocumentVO } from "@libs/api/generated-api";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { StagedScanFile } from "components/UserDocuments/ScanDocumentFileModal";

import { FormFieldSelect } from "components/UI/FormFieldSelect";

interface Props {
  file: StagedScanFile;
  folderOptions: SelectOption<DocumentVO["id"]>[];
  onUpdateFile: (updatedFile: Partial<StagedScanFile>) => void;
}

export const ScanDocumentFileFields: FC<Props> = ({ file, folderOptions, onUpdateFile }) => {
  const [draftFilename, setDraftFilename] = useState("");

  return (
    <div className="flex items-center justify-between gap-x-4">
      <div className="flex-1 flex items-end gap-x-1">
        <FormFieldInput
          label={<span className="font-sansSemiBold pb-2">Filename</span>}
          layout="labelOut"
          className="w-full"
          value={draftFilename}
          onChange={(e) => {
            const filename = e.target.value;

            setDraftFilename(filename);
            onUpdateFile({ filename: filename ? `${filename}.pdf` : "" });
          }}
          autoFocus
          required
        />
        <span className="text-xs pb-2.5">.pdf</span>
      </div>

      <FormFieldSelect
        label={<span className="font-sansSemiBold pb-2">Folder</span>}
        layout="labelOut"
        className="flex-1"
        options={folderOptions}
        placeholder="Select Folder"
        value={file.folderId}
        onItemChanged={(value) => onUpdateFile({ folderId: value })}
        isSearchable={false}
        required
      />
    </div>
  );
};
