/* eslint-disable complexity */
import React from "react";
import { PatientWalletResponse } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import { DashboardTableHeader } from "components/Dashboard/Tables/DashboardTableHeader";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";
import { useFlattenEntries } from "components/Dashboard/hooks/useFlattenEntries";
import { PatientWalletTableRow } from "components/Dashboard/PatientWallet/PatientWalletTableRow";
import { PATIENT_WALLET_REPORT_HEADERS } from "components/Dashboard/PatientWallet/constants";
import { PatientWalletQuery } from "utils/routing/dashboard";
import { FooterCell } from "components/Dashboard/Tables/FooterCell";
import { getTotals } from "components/Dashboard/Tables/utils";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";

type Props = {
  patientWalletInfiniteQuery: UseInfiniteApiQueryResult<PatientWalletResponse>;
  onUpdateParams: (params: Partial<PatientWalletQuery>) => void;
  query: PatientWalletQuery;
};

export const PatientWalletTable: React.FC<Props> = ({
  patientWalletInfiniteQuery,
  onUpdateParams,
  query,
}) => {
  const rows = useFlattenEntries(patientWalletInfiniteQuery.data);
  const isEmpty = !patientWalletInfiniteQuery.isLoading && rows.length === 0;
  const totals = getTotals(patientWalletInfiniteQuery.data);
  const handleSortClick = React.useCallback(
    (newTableSort: ColumnSortOrder[]) => {
      onUpdateParams({
        tableSort: newTableSort,
      });
    },
    [onUpdateParams]
  );

  return (
    <>
      <DashboardTableHeader
        title="Patient Wallet Balances"
        id="patient-wallet"
        onUpdateParams={onUpdateParams}
        includePatientSearch
        query={query}
        includeExpandTable={false}
      />

      <DashboardInfiniteTable
        infiniteQuery={patientWalletInfiniteQuery}
        columnWidths={PATIENT_WALLET_REPORT_HEADERS.map(({ width }) => width)}
        id="patient-wallet-table"
        isEmpty={isEmpty}
        className="rounded-b-md"
        headerRow={
          <DashboardHeaderRow
            headers={PATIENT_WALLET_REPORT_HEADERS}
            sortOrders={query.tableSort}
            onSortClick={handleSortClick}
          />
        }
        footerRow={
          <>
            <FooterCell>Total Available Wallet Funds</FooterCell>
            <FooterCell loading={patientWalletInfiniteQuery.isLoading}>
              {formatCurrency(totals?.totalWalletBalance ?? 0)}
            </FooterCell>

            <FooterCell className="col-span-2" />
          </>
        }
      >
        {rows.map((row, i) => {
          return <PatientWalletTableRow key={row.patient.id} row={row} last={i === rows.length - 1} />;
        })}
      </DashboardInfiniteTable>
    </>
  );
};
