import { FC } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { PatientPortalSettings } from "components/Settings/Payments/PatientPortalSettings";

export const BillingPatientPortalRoute: FC = () => {
  const isEditing = useBoolean(false);

  return (
    <SettingsPanel
      isEditing={isEditing.isOn}
      title="Patient Portal"
      description="Setup your patient portal payment preferences"
      onToggleEdit={isEditing.toggle}
    >
      <PatientPortalSettings editSection={isEditing.isOn} onCancel={isEditing.off} onSave={isEditing.off} />
    </SettingsPanel>
  );
};
