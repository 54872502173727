import React, { FormEvent } from "react";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { CognitoErrorCode } from "@libs/utils/cognito";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Form } from "@libs/components/UI/Form";
import { getCognitoErrorMessage } from "utils/auth";
import { ForgotPasswordInputType, ForgotPasswordProps } from "components/SignIn/ForgotPassword";
import { ForgotPasswordValidationResults } from "components/SignIn/validationUtil";

interface Props {
  handleForgotPassword: (
    e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>;
  handleInputChange: (inputName: ForgotPasswordInputType, value: string) => void;
  forgotPasswordState: ForgotPasswordProps;
  validateResult: ForgotPasswordValidationResults;
}

const hasNotYetConfirmed = (cognitoErrorCode: CognitoErrorCode | null) => {
  return cognitoErrorCode === "NotAuthorizedException";
};
const getErrorMessage = (cognitoErrorCode: CognitoErrorCode) => {
  if (hasNotYetConfirmed(cognitoErrorCode)) {
    // NotAuthorizedException is returned when the account is in an invalid state (aka hasn't yet confirmed)
    return "The invite for this email has not yet been accepted. If the temporary password expired, please request that the practice resend the invite email.";
  }

  return getCognitoErrorMessage(cognitoErrorCode);
};

export const ForgotPasswordForm: React.FC<Props> = ({
  handleForgotPassword,
  handleInputChange,
  forgotPasswordState,
  validateResult,
}) => {
  return (
    <Form onSubmit={(e) => handleForgotPassword(e)} className="flex flex-col gap-4 items-center">
      <div className="font-sans text-sm">
        Please enter the email address linked to your Archy account below. We&apos;ll send you a verification
        code through email to help you reset your password.
      </div>
      <FormFieldInput
        error={
          validateResult.email.$error ||
          (forgotPasswordState.cognitoErrorCode ? getErrorMessage(forgotPasswordState.cognitoErrorCode) : "")
        }
        label="Email"
        className="w-full"
        layout="labelOut"
        name="email"
        onChange={(event) => handleInputChange("email", event.target.value)}
        placeholder="example@gmail.com"
        type="email"
        value={forgotPasswordState.email}
      />
      <AsyncButton
        className="min-w-button w-fit"
        displayLoadingText={true}
        isLoading={forgotPasswordState.isLoading}
        type="submit"
      >
        {forgotPasswordState.isLoading ? "Sending" : "Next"}
      </AsyncButton>
    </Form>
  );
};
