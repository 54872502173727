import { FC, ReactNode, useMemo } from "react";
import { FormSelectInputElementVO, FormSelectResponseVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormSubmissionResponses } from "components/PatientProfile/Forms/types";
import { MultiSelectValues } from "components/PatientProfile/Forms/MultiSelectValues";
import { OTHER_VALUE } from "components/PatientProfile/Forms/utils";

const OtherLabel: FC<{
  response?: string;
  elementUuid: string;
  onUpdate?: (updater: (last: FormSubmissionResponses) => void) => void;
}> = ({ response, elementUuid, onUpdate }) => {
  return (
    <FormFieldInput
      placeholder="Other"
      value={response}
      onChange={(e) => {
        onUpdate?.((currentResponses) => {
          const currentValue = currentResponses[elementUuid];

          if (currentValue?.type === "SELECT") {
            currentValue.other = e.target.value;
          } else {
            currentResponses[elementUuid] = {
              type: "SELECT",
              responses: {},
              other: e.target.value,
            };
          }
        });
      }}
    />
  );
};

export const SelectMultipleInput: FC<{
  element: FormSelectInputElementVO;
  response?: FormSelectResponseVO;
  onUpdate?: (updater: (last: FormSubmissionResponses) => void) => void;
}> = ({ element, onUpdate, response }) => {
  const edit = Boolean(onUpdate);
  const allowsOther = element.settings.includes("ALLOW_ADDITIONAL_OPTION");
  const otherText = response?.other;
  const options = useMemo(() => {
    const mapped: { label: ReactNode; value: string }[] = element.options.map((o) => ({
      label: o,
      value: o,
    }));

    if (allowsOther) {
      mapped.push({
        label: <OtherLabel response={otherText} elementUuid={element.uuid} onUpdate={onUpdate} />,
        value: OTHER_VALUE,
      });
    }

    return mapped;
  }, [element.options, allowsOther, element.uuid, onUpdate, otherText]);

  const selectedValues = useMemo(() => {
    const selections = Object.keys(response?.responses ?? {});

    if (isDefined(otherText)) {
      selections.push(OTHER_VALUE);
    }

    return new Set(selections);
  }, [response?.responses, otherText]);

  const readOnlyValues = useMemo(() => {
    const selections = element.options.filter((o) => response?.responses[o]);

    if (isDefined(otherText)) {
      selections.push(`Other${otherText ? ` - ${otherText}` : ""}`);
    }

    return selections;
  }, [response?.responses, otherText, element.options]);

  const handleSelectionChange = (newSet: Set<string>) => {
    onUpdate?.((currentResponses) => {
      const selections = [...newSet].filter((val) => val !== OTHER_VALUE);
      const responseValue = selections.reduce(
        (next, value) => {
          next[value] = true;

          return next;
        },
        {} as Record<string, boolean>
      );

      const newResponse: FormSelectResponseVO = {
        type: "SELECT",
        responses: responseValue,
      };

      const currentValue = currentResponses[element.uuid];

      if (currentValue?.type === "SELECT" && newSet.has(OTHER_VALUE)) {
        newResponse.other = currentValue.other ?? "";
      }

      currentResponses[element.uuid] = newResponse;
    });
  };

  return edit ? (
    <CheckboxList
      options={options}
      layout="custom"
      optionListClassName="columns-2 space-y-4 mt-4"
      selectedValues={selectedValues}
      onChange={handleSelectionChange}
      label={element.title}
    />
  ) : (
    <div>
      <div className="text-xs mb-2">{element.title}</div>
      <MultiSelectValues values={readOnlyValues} />
    </div>
  );
};
