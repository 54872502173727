import { FC, FormEvent, useState, useMemo, useCallback } from "react";
import { produce } from "immer";

import { PracticeVO } from "@libs/api/generated-api";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { isCompleteAddress, isEmptyAddress } from "@libs/utils/address";
import { titleCaseConstant } from "@libs/utils/casing";
import { useValidation } from "@libs/hooks/useValidation";

import { Form } from "@libs/components/UI/Form";
import { FormFieldAddressAutocomplete } from "@libs/components/UI/FormFieldAddressAutocomplete";
import { stateOptions } from "@libs/components/UI/stateOptions";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";
import { TooltipLabel } from "components/UI/TooltipLabel";

import { practiceDetailsSchema } from "components/Settings/Communications/MessagingService/utils";
import { useIsDirty } from "hooks/useIsDirty";
import { useEnvContext } from "contexts/EnvContext";

interface Props {
  formId: string;
  practice: PracticeVO;
  hasInitiated: boolean;
  onSubmit: (updatedPractice?: PracticeVO) => void;
  onDirty: Func;
}

export const PracticeDetailsForm: FC<Props> = ({ formId, practice, hasInitiated, onSubmit, onDirty }) => {
  const [editablePractice, setEditablePractice] = useState(() => practice);
  const { REACT_APP_GOOGLE_API_KEY } = useEnvContext();

  const fillableAddressInputsDisabled = useMemo(
    () =>
      isCompleteAddress(editablePractice.physicalAddress) || isEmptyAddress(editablePractice.physicalAddress),
    [editablePractice.physicalAddress]
  );

  const { validate, result } = useValidation(editablePractice, practiceDetailsSchema);

  const { isDirty } = useIsDirty(editablePractice, { onDirty });

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!hasInitiated && !validate().$isValid) {
        return;
      }

      onSubmit(isDirty ? editablePractice : undefined);
    },
    [hasInitiated, validate, onSubmit, isDirty, editablePractice]
  );

  return (
    <Form id={formId} className="flex flex-col gap-y-6" onSubmit={handleSubmit}>
      <h3 className="font-sansSemiBold text-sm text-black">Confirm Practice Details</h3>

      <div className="grid grid-cols-2 gap-6 items-center">
        <FormFieldInput
          label={
            <TooltipLabel
              tooltip={{
                content: "Business name must exactly match the name registered with the IRS.",
                theme: "SMALL",
              }}
              disabled={hasInitiated}
            >
              Legal Business Name
            </TooltipLabel>
          }
          value={editablePractice.name}
          onChange={(e) =>
            setEditablePractice((last) =>
              produce(last, (draft) => {
                draft.name = e.target.value;
              })
            )
          }
          error={result.name.$error}
          disabled={hasInitiated}
          required
        />

        <FormFieldInput
          label="Doing Business As"
          value={editablePractice.doingBusinessAs}
          onChange={(e) =>
            setEditablePractice((last) =>
              produce(last, (draft) => {
                draft.doingBusinessAs = e.target.value;
              })
            )
          }
          disabled={hasInitiated}
        />

        <FormFieldInput
          label="Employer Identification Number"
          value={editablePractice.taxIdLastFour ? `****${editablePractice.taxIdLastFour}` : undefined}
          disabled
        />

        <FormFieldInput
          label="Business Type"
          value={editablePractice.businessType ? titleCaseConstant(editablePractice.businessType) : undefined}
          error={result.businessType.$error}
          disabled
          required
        />

        <FormFieldPhoneInput
          label="Phone Number"
          value={editablePractice.contactDetails.phoneNumber}
          onValueChange={(value) =>
            setEditablePractice((last) =>
              produce(last, (draft) => {
                draft.contactDetails.phoneNumber = value;
              })
            )
          }
          error={result.contactDetails.phoneNumber.$error}
          disabled={hasInitiated}
          required
        />

        <FormFieldInput
          label={
            <TooltipLabel
              tooltip={{
                content: "Link to your website or public social media page.",
                theme: "SMALL",
              }}
              disabled={hasInitiated}
            >
              Website
            </TooltipLabel>
          }
          value={editablePractice.website}
          onChange={(e) =>
            setEditablePractice((last) =>
              produce(last, (draft) => {
                draft.website = e.target.value;
              })
            )
          }
          error={result.website.$error}
          disabled={hasInitiated}
          required
        />

        <FormFieldAddressAutocomplete
          apiKey={REACT_APP_GOOGLE_API_KEY}
          aria-label="Practice Address"
          label="Practice Address"
          placeholder="Search address..."
          defaultValue={editablePractice.physicalAddress.address1}
          onSelect={({ address: address1, locality: city, state, zip, country }) => {
            const selectedAddress = { address1, address2: "", city, state, zip, country };

            setEditablePractice((last) =>
              produce(last, (draft) => {
                draft.physicalAddress = selectedAddress;
              })
            );
          }}
          error={result.physicalAddress.address1.$error}
          disabled={hasInitiated}
          required
        />

        <FormFieldInput
          label="Apt, Suite, Bldg"
          value={editablePractice.physicalAddress.address2}
          onChange={(e) =>
            setEditablePractice((last) =>
              produce(last, (draft) => {
                draft.physicalAddress.address2 = e.target.value;
              })
            )
          }
          disabled={hasInitiated}
        />

        <FormFieldInput
          label="City"
          value={editablePractice.physicalAddress.city}
          onChange={(e) =>
            setEditablePractice((last) =>
              produce(last, (draft) => {
                draft.physicalAddress.city = e.target.value;
              })
            )
          }
          error={result.physicalAddress.city.$error}
          disabled={hasInitiated || fillableAddressInputsDisabled}
          required
        />

        <div className="grid grid-cols-2 gap-6 items-center">
          <FormFieldSelect
            aria-label="State"
            label="State"
            options={stateOptions}
            value={editablePractice.physicalAddress.state}
            onItemSelected={(selectedState) =>
              setEditablePractice((last) =>
                produce(last, (draft) => {
                  draft.physicalAddress.state = selectedState;
                })
              )
            }
            isSearchable={false}
            error={result.physicalAddress.state.$error}
            disabled={hasInitiated || fillableAddressInputsDisabled}
            required
          />

          <FormFieldInput
            label="Zip"
            value={editablePractice.physicalAddress.zip}
            onChange={(e) =>
              setEditablePractice((last) =>
                produce(last, (draft) => {
                  draft.physicalAddress.zip = e.target.value;
                })
              )
            }
            error={result.physicalAddress.zip.$error}
            disabled={hasInitiated || fillableAddressInputsDisabled}
            required
          />
        </div>
      </div>
    </Form>
  );
};
