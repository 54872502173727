import { FC, FormEvent, useState, useRef, useMemo, useCallback } from "react";
import { flushSync } from "react-dom";

import { TemplateVariableGroupVO, TwilioOnboardingStatusVO, EmailConfigVO } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { Button } from "@libs/components/UI/Button";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useValidation } from "@libs/hooks/useValidation";

import { FlyoverForm, FlyoverContent, FlyoverFooter } from "components/UI/FlyoverComponents";

import { MessagingSettingsBanner } from "components/Communications/MessagingSettingsBanner";
import { EmailSettingsBanner } from "components/Communications/EmailSettingsBanner";

import { SendCountExceededBanner } from "components/Communications/MessagePatients/SendCountExceededBanner";
import { StatementDateRangeField } from "components/Communications/MessagePatients/StatementDateRangeField";
import { ChannelsFields } from "components/Communications/MessagePatients/ChannelsFields";
import { MessageField } from "components/Communications/MessagePatients/MessageField";
import { MessagePatientsPanel } from "components/Communications/MessagePatients/MessagePatientsPanel";

import { SendMessageCampaignDraft, MessageView } from "components/Communications/MessagePatients/types";

import {
  MAX_SMS_SEND_COUNT,
  MAX_EMAIL_SEND_COUNT,
  getInitialMessageCampaignDraft,
  getInitialMessageView,
  filterTemplateVariables,
  getChannelsStatus,
  getSendMessageCampaignSchema,
} from "components/Communications/MessagePatients/utils";
import { isMassSmsConfigured, isEmailConfigured } from "components/Communications/utils";
import { scrollToFirstError } from "utils/scrollToFirstError";

interface Props {
  templateVariablesQuery: ApiQueryResult<TemplateVariableGroupVO[]>;
  twilioOnboardingStatus: TwilioOnboardingStatusVO;
  emailConfig: EmailConfigVO;
  canGenerateStatements: boolean;
  sendCount: number;
  onRequestSend: (messageCampaignDraft: SendMessageCampaignDraft) => void;
  onCancel: Func;
}

export const MessagePatientsForm: FC<Props> = ({
  templateVariablesQuery,
  twilioOnboardingStatus,
  emailConfig,
  canGenerateStatements,
  sendCount,
  onRequestSend,
  onCancel,
}) => {
  const hasMassSmsConfigured = isMassSmsConfigured(twilioOnboardingStatus);
  const hasEmailConfigured = isEmailConfigured(emailConfig);
  const isMaxSmsSendCount = sendCount > MAX_SMS_SEND_COUNT;
  const isMaxEmailSendCount = sendCount > MAX_EMAIL_SEND_COUNT;

  const canSendSms = hasMassSmsConfigured && !isMaxSmsSendCount;
  const canSendEmail = hasEmailConfigured && !isMaxEmailSendCount;
  const canSendPatientPreferred = canSendSms && hasEmailConfigured;

  const { onboardedWithPaymentProvider } = useCurrentPractice();

  const [messageCampaignDraft, updateMessageCampaignDraft] = useObjectState<SendMessageCampaignDraft>(() =>
    getInitialMessageCampaignDraft({
      canSendSms,
      canSendEmail,
      canGenerateStatements,
      onboardedWithPaymentProvider,
    })
  );
  const [messageView, setMessageView] = useState<MessageView>(() =>
    getInitialMessageView({ canSendSms, canSendEmail })
  );

  const formContainerRef = useRef<HTMLDivElement>(null);
  const sendTestModal = useBoolean(false);

  const templateVariables = useMemo(
    () => filterTemplateVariables(templateVariablesQuery, { onboardedWithPaymentProvider }),
    [templateVariablesQuery, onboardedWithPaymentProvider]
  );

  const templateVariablesSets = useMemo(
    () => ({
      content: new Set(
        templateVariables.content.data.flatMap((group) => group.variables.map((variable) => variable.key))
      ),
      subject: new Set(
        templateVariables.subject.data.flatMap((group) => group.variables.map((variable) => variable.key))
      ),
    }),
    [templateVariables.content, templateVariables.subject]
  );

  const messageCampaignSchema = useMemo(
    () =>
      getSendMessageCampaignSchema({
        channels: messageCampaignDraft.channels,
        hasCustomEmail: Boolean(messageCampaignDraft.customEmailTemplate),
        templateVariablesSets,
        onboardedWithPaymentProvider,
        canGenerateStatements,
      }),
    [
      messageCampaignDraft.channels,
      messageCampaignDraft.customEmailTemplate,
      templateVariablesSets,
      onboardedWithPaymentProvider,
      canGenerateStatements,
    ]
  );

  const { validate, result: validationResult } = useValidation(messageCampaignDraft, messageCampaignSchema);

  const handleStatementDateRangeChange = useCallback(
    (date: Partial<SendMessageCampaignDraft["statementDateRange"]>) => {
      updateMessageCampaignDraft({
        statementDateRange: { ...messageCampaignDraft.statementDateRange, ...date },
      });
    },
    [updateMessageCampaignDraft, messageCampaignDraft.statementDateRange]
  );

  const handleChannelsChange = useCallback(
    (channels: SendMessageCampaignDraft["channels"]) => {
      const { isSendingSms, isSendingEmails, isSendingMail } = getChannelsStatus(channels);
      const [initialChannel] = channels;

      if (isSendingSms) {
        if (initialChannel === "SMS" || messageView === "MAIL") {
          setMessageView("SMS");
        }
      } else if (isSendingEmails) {
        if (initialChannel === "EMAIL" || messageView === "MAIL") {
          setMessageView("EMAIL");
        }
      } else if (isSendingMail) {
        setMessageView("MAIL");
      }

      updateMessageCampaignDraft({ channels });
    },
    [messageView, updateMessageCampaignDraft]
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const result = flushSync(() => validate());

      if (result.$isValid) {
        onRequestSend(messageCampaignDraft);
      } else {
        scrollToFirstError(formContainerRef.current);

        if (result.smsTemplate.$error && !result.emailTemplate.$error && messageView === "EMAIL") {
          setMessageView("SMS");
        } else if (
          !result.smsTemplate.$error &&
          (result.emailTemplate.$error || result.subject.$error) &&
          messageView === "SMS"
        ) {
          setMessageView("EMAIL");
        }
      }
    },
    [validate, onRequestSend, messageCampaignDraft, messageView]
  );

  return (
    <FlyoverForm onSubmit={handleSubmit}>
      <FlyoverContent containerRef={formContainerRef} paddingClassName="flex flex-col gap-y-6 p-6">
        <MessagingSettingsBanner twilioStatus={twilioOnboardingStatus} />
        <EmailSettingsBanner emailConfig={emailConfig} />

        {(hasMassSmsConfigured && isMaxSmsSendCount) || (hasEmailConfigured && isMaxEmailSendCount) ? (
          <SendCountExceededBanner />
        ) : null}

        {canGenerateStatements ? (
          <StatementDateRangeField
            startDate={messageCampaignDraft.statementDateRange.startDate}
            endDate={messageCampaignDraft.statementDateRange.endDate}
            onDateRangeChange={handleStatementDateRangeChange}
            validationResult={validationResult.statementDateRange}
          />
        ) : null}

        <ChannelsFields
          channels={messageCampaignDraft.channels}
          onChannelsChange={handleChannelsChange}
          canSendPatientPreferred={canSendPatientPreferred}
          canSendSms={canSendSms}
          canSendEmail={canSendEmail}
          isMaxSmsSendCount={isMaxSmsSendCount}
          isMaxEmailSendCount={isMaxEmailSendCount}
        />

        <MessageField>
          <MessagePatientsPanel
            messageView={messageView}
            messageCampaign={messageCampaignDraft}
            contentTemplateVariablesQuery={templateVariables.content}
            subjectTemplateVariablesQuery={templateVariables.subject}
            onMessageViewChange={setMessageView}
            onUpdateMessageCampaign={updateMessageCampaignDraft}
            onSendTestModal={sendTestModal.on}
            validationResult={validationResult}
            canSendSms={canSendSms}
            canSendEmail={canSendEmail}
          />
        </MessageField>
      </FlyoverContent>

      <FlyoverFooter>
        <Button className="min-w-button" onClick={onCancel} theme="secondary" size="medium">
          Cancel
        </Button>
        <Button className="min-w-button" disabled={!canSendSms && !canSendEmail} size="medium" type="submit">
          Send
        </Button>
      </FlyoverFooter>
    </FlyoverForm>
  );
};
