import { FC, useCallback, useEffect } from "react";

import { useObjectState } from "@libs/hooks/useObjectState";
import { fetchImageAsBase64, dataURLtoFile } from "@libs/utils/dataUrl";
import { isDefined } from "@libs/utils/types";

import { Modal } from "@libs/components/UI/Modal";
import { SelectPhotoModalContent } from "components/UI/UploadPhotoModalContent/SelectPhotoModalContent";
import { CapturePhotoModalContent } from "components/UI/UploadPhotoModalContent/CapturePhotoModalContent";
import { EditPhotoModalContent } from "components/UI/UploadPhotoModalContent/EditPhotoModalContent";
import { LoadingPhotoModalContent } from "components/UI/UploadPhotoModalContent/LoadingPhotoModalContent";

import { handleError } from "utils/handleError";

type ProfilePhotoModalState = {
  modalContent: "select" | "capture" | "edit" | "init";
  selectedImage: File | null;
};

interface Props {
  userId: number;
  userImageUrl?: string;
  onRequestClose: Func;
}

export const UploadPhotoModal: FC<Props> = ({ userId, userImageUrl, onRequestClose }) => {
  const [{ modalContent, selectedImage }, updateModalState] = useObjectState<ProfilePhotoModalState>(() => ({
    modalContent: "init",
    selectedImage: null,
  }));

  const isExistingImage = isDefined(userImageUrl);

  const initializeModalContent = useCallback(async () => {
    if (modalContent !== "init") {
      return;
    }

    if (userImageUrl) {
      try {
        // Fetching the user image url must be done with the "no-cache" option
        // due to the way Chromium caches images without CORS headers when
        // initally rendered in an <img> element. Since the user image has
        // already been fetched and rendered this way, making another request
        // for the image is pulled from the cache by default, but it does not
        // include the CORS headers, resulting in a CORS error. The "no-cache"
        // option forces the image to be fetched from the server with the
        // appropriate CORS headers, and updates the cache, allowing us to fetch
        // the image as a base64 string to convert it as a File for the editor.
        const userImageDataUrl = await fetchImageAsBase64(userImageUrl, { cache: "no-cache" });
        const userImage = dataURLtoFile(userImageDataUrl, "profile-image.jpeg");

        updateModalState({ modalContent: "edit", selectedImage: userImage });
      } catch (error) {
        handleError(error);
        updateModalState({ modalContent: "select" });
      }

      return;
    }

    updateModalState({ modalContent: "select" });
  }, [modalContent, updateModalState, userImageUrl]);

  useEffect(() => {
    initializeModalContent();
  }, [initializeModalContent]);

  const handleClickOutside = useCallback(
    () => (modalContent === "edit" && isExistingImage ? onRequestClose() : undefined),
    [modalContent, isExistingImage, onRequestClose]
  );

  const handleCancelSelectPhoto = useCallback(
    () => (isExistingImage ? updateModalState({ modalContent: "edit" }) : onRequestClose()),
    [isExistingImage, updateModalState, onRequestClose]
  );

  const handleCancelCapturePhoto = useCallback(
    () => updateModalState({ modalContent: isExistingImage ? "edit" : "select" }),
    [isExistingImage, updateModalState]
  );

  const handleCancelEditPhoto = useCallback(
    () => (isExistingImage ? onRequestClose() : updateModalState({ modalContent: "select" })),
    [isExistingImage, updateModalState, onRequestClose]
  );

  const handleSelectAndEditPhoto = (file: File) => {
    updateModalState({ modalContent: "edit", selectedImage: file });
  };

  const handleRequestSelectPhoto = () => {
    updateModalState({ modalContent: "select" });
  };

  const handleRequestCapturePhoto = () => {
    updateModalState({ modalContent: "capture" });
  };

  return (
    <Modal title="Profile Picture" onClickOutside={handleClickOutside} onClose={onRequestClose} size="2xs">
      {modalContent === "select" ? (
        <SelectPhotoModalContent
          onSelectPhoto={handleSelectAndEditPhoto}
          onRequestCapturePhoto={handleRequestCapturePhoto}
          onCancel={handleCancelSelectPhoto}
        />
      ) : modalContent === "capture" ? (
        <CapturePhotoModalContent
          onCapturePhoto={handleSelectAndEditPhoto}
          onCancel={handleCancelCapturePhoto}
        />
      ) : modalContent === "edit" && selectedImage ? (
        <EditPhotoModalContent
          userId={userId}
          selectedImage={selectedImage}
          isExistingImage={isExistingImage}
          onRequestSelectPhoto={handleRequestSelectPhoto}
          onRequestCapturePhoto={handleRequestCapturePhoto}
          onCancel={handleCancelEditPhoto}
          onClose={onRequestClose}
        />
      ) : (
        <LoadingPhotoModalContent onClose={onRequestClose} />
      )}
    </Modal>
  );
};
