import React, { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AppointmentVO } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";

import { usePageTitle } from "@libs/hooks/usePageTitle";
import { useAccount } from "@libs/contexts/AccountContext";
import {
  ColumnSortIndicator,
  HeaderButtonCell,
  HeaderCell,
  TableGrid,
} from "@libs/components/UI/GridTableComponents";
import { ScrollableInfiniteQuery } from "@libs/components/UI/ScrollableInfiniteQuery";
import { getAppointmentRequestsInfiniteQuery } from "api/scheduling/queries";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { ModalPage } from "components/UI/ModalPage";
import { HeaderData } from "components/Dashboard/Tables/types";
import { EmptyRows } from "components/ScheduleAppointments/ScheduleAsapListTableItems";
import { RequestsASAPTabs } from "components/ScheduleAppointments/RequestsASAPTabs";
import { ScheduleRequestRow } from "components/ScheduleAppointments/ScheduleRequestRow";
import { PatientSnapshotLayout } from "components/UI/PatientSnapshotLayout";
import { usePatientAppointmentQueryState } from "contexts/PatientAppointmentContext";
import {
  ScheduleAppHistoryProvider,
  useScheduleLinks,
} from "components/ScheduleAppointments/ScheduleLinksContext";

const headers: HeaderData[] = [
  {
    label: "Date & Time",
    width: "auto",
    sortField: "date",
  },
  {
    label: "Duration",
    width: "auto",
  },
  {
    label: "Patient",
    width: "auto",
  },
  {
    label: "Provider",
    width: "auto",
  },
  {
    label: "Room",
    width: "auto",
  },
  {
    label: "Category",
    width: "auto",
  },
  {
    label: "",
    width: "min-content",
  },
  {
    label: "",
    width: "min-content",
  },
];

export const ScheduleRequestsList: React.FC = () => {
  const { practiceId } = useAccount();
  const { query, updateQuery } = useQueryParams("scheduleRequestsList");
  const navigate = useNavigate();
  const location = useLocation();
  const { links } = useScheduleLinks();

  const appointmentRequestsQuery = useInfiniteApiQuery(
    getAppointmentRequestsInfiniteQuery({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: 25,
        sortColumn: query.sortField,
        sortDirection: query.sortDirection,
      },
    })
  );

  const handleSort = useCallback(
    (field: string) => {
      if (field === query.sortField) {
        updateQuery("replaceIn", {
          sortDirection: query.sortDirection === "ASC" ? "DESC" : "ASC",
        });
      } else {
        updateQuery("replaceIn", { sortField: "date" });
      }
    },
    [query.sortDirection, query.sortField, updateQuery]
  );

  const handleDeleteAppointment = useCallback(() => {
    updateQuery("replaceIn", { appointmentId: undefined });
  }, [updateQuery]);

  const handleEditAppointment = useCallback(
    (appointment: AppointmentVO) => {
      const url = paths.editAppointment(
        { appointmentId: appointment.id, patientId: appointment.patient.id },
        { from: getFullUrl(location) }
      );

      navigate(url);
    },
    [navigate, location]
  );

  const sortDirection = query.sortDirection === "ASC" ? "ASCENDING" : "DESCENDING";

  const backUrl = query.from ?? links.schedule;

  usePageTitle("Online Booking Requests");

  const patientAppointment = usePatientAppointmentQueryState();

  return (
    <ScheduleAppHistoryProvider name="requests">
      <PatientSnapshotLayout
        patientId={patientAppointment.patientId}
        appointmentId={patientAppointment.appointmentId}
        onSelectAppointment={patientAppointment.handleAppointmentSelected}
        onDeleteAppointment={patientAppointment.handleAppointmentDeleted}
        emptyText="No Appointment Selected"
      >
        <ModalPage
          title={
            <div className="flex flex-row justify-between">
              <RequestsASAPTabs />
            </div>
          }
          closeLink={backUrl}
        >
          <ScrollableInfiniteQuery
            id="schedulingRequestsList"
            infiniteQuery={appointmentRequestsQuery}
            noResults={<EmptyRows type="Requests" />}
            className="overflow-x-auto"
          >
            {(appts) => (
              <TableGrid columnWidths={headers.map(({ width }) => width)}>
                {headers.map((header, i) => {
                  const Component = header.sortField ? HeaderButtonCell : HeaderCell;

                  return (
                    <Component
                      key={i}
                      onClick={() => {
                        if (header.sortField) {
                          handleSort(header.sortField);
                        }
                      }}
                      size="short"
                    >
                      {header.label}
                      {header.sortField && <ColumnSortIndicator className="ml-2" direction={sortDirection} />}
                    </Component>
                  );
                })}

                {appts.map((appointment) => (
                  <ScheduleRequestRow
                    onClick={(appt) =>
                      updateQuery("replaceIn", { patientId: appt.patient.id, appointmentId: appt.id })
                    }
                    onAppointmentDeleted={handleDeleteAppointment}
                    onEdit={handleEditAppointment}
                    key={appointment.id}
                    appointment={appointment}
                    isSelected={appointment.id === query.appointmentId}
                  />
                ))}
              </TableGrid>
            )}
          </ScrollableInfiniteQuery>
        </ModalPage>
      </PatientSnapshotLayout>
    </ScheduleAppHistoryProvider>
  );
};
