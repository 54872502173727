export const promiseAllProgress = <T>(
  promises: PromiseLike<T>[],
  progressCallback: (value: number, result?: T) => void
) => {
  let promisesComplete = 0;

  progressCallback(0);

  return Promise.all(
    promises.map((p) =>
      p.then((result) => {
        promisesComplete++;
        progressCallback(promisesComplete / promises.length, result);

        return result;
      })
    )
  );
};
