import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ClaimAttachmentVO, ClaimLineItemVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { getFullUrl } from "@libs/utils/location";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as AttachmentsIcon } from "@libs/assets/icons/attach.svg";
import { TableGrid, Cell, Row } from "@libs/components/UI/GridTableComponents";
import { ClaimSection } from "components/Claim/ClaimSection";

import { groupBy } from "utils/groupBy";
import { AttachmentsSummary } from "components/Claim/Attachments/AttachmentsSummary";
import { paths } from "utils/routing/paths";
import {
  MedicalImage,
  Narrative,
  OtherImage,
  PerioChart,
} from "components/Claim/Attachments/ClaimAttachmentsContext";

interface Props {
  claimAttachments: ClaimAttachmentVO[];
  claimUuid: string;
  claimLineItems: ClaimLineItemVO[];
  deleteAttachment: (attachmentUuid: string) => Promise<void>;
  isDraftClaim: boolean;
  supportsDigitalAttachments: boolean;
}

const headers = [
  { id: "code", label: "Code", width: "100px" },
  { id: "tthSurf", label: "Tth/Surf", width: "100px" },
  { id: "description", label: "Procedure Description", width: "500px" },
  { className: "text-center", id: "xRay", label: "X-ray", width: "100px" },
  { className: "text-center", id: "narrative", label: "Narrative", width: "100px" },
  { className: "text-center", id: "perioChart", label: "Perio Chart", width: "100px" },
];

const BlueDot: React.FC = () => {
  return <div className="h-1.5 w-1.5 bg-primaryTheme rounded-full" />;
};

// eslint-disable-next-line complexity
export const RequirementsSection: React.FC<Props> = ({
  claimAttachments,
  claimUuid,
  deleteAttachment,
  isDraftClaim,
  claimLineItems,
  supportsDigitalAttachments,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const fullUrl = getFullUrl(location);

  const handleOpenAttachments = useCallback(() => {
    navigate(paths.claimAttachments({ claimUuid }, { from: fullUrl }));
  }, [claimUuid, navigate, fullUrl]);

  const attachmentRequiredLineItems = useMemo(() => {
    return claimLineItems.filter(
      (lineItem) => lineItem.isIncluded && lineItem.requiredAttachmentTypes.some((type) => type !== "NONE")
    );
  }, [claimLineItems]);

  const unknownAttachmentRequired = useMemo(() => {
    return claimLineItems.some((item) => item.requiredAttachmentTypes.includes("UNKNOWN"));
  }, [claimLineItems]);

  const groupedAttachments = useMemo(() => groupBy(claimAttachments, "type"), [claimAttachments]);

  const narrative = useMemo(
    () => (groupedAttachments.NOTE ?? []).filter((note) => note.sourceId == null) as Narrative[],
    [groupedAttachments.NOTE]
  );

  const medicalImages = useMemo(
    () => [
      ...((groupedAttachments.PHOTO || []) as MedicalImage[]),
      ...((groupedAttachments.XRAY || []) as MedicalImage[]),
    ],
    [groupedAttachments.PHOTO, groupedAttachments.XRAY]
  );

  const other = useMemo(
    () => [
      ...((groupedAttachments.DIAGNOSTIC || []) as OtherImage[]),
      ...((groupedAttachments.MODEL || []) as OtherImage[]),
      ...((groupedAttachments.RADIOLOGY || []) as OtherImage[]),
      ...((groupedAttachments.REFERRAL || []) as OtherImage[]),
      ...((groupedAttachments.OTHER || []) as OtherImage[]),
    ],
    [
      groupedAttachments.DIAGNOSTIC,
      groupedAttachments.MODEL,
      groupedAttachments.OTHER,
      groupedAttachments.RADIOLOGY,
      groupedAttachments.REFERRAL,
    ]
  );

  const perioCharts = groupedAttachments.CHART as PerioChart[] | undefined;

  // This represents all attachments except EOBs since those are displayed
  // separately
  const hasAttachments =
    (perioCharts && perioCharts.length > 0) ||
    medicalImages.length > 0 ||
    other.length > 0 ||
    narrative.length > 0;

  return (
    <ClaimSection title="Attachments Required">
      <>
        {attachmentRequiredLineItems.length && supportsDigitalAttachments ? (
          <>
            <TableGrid columnWidths={headers.map(({ width }) => width)}>
              {headers.map((item) => (
                <Cell className={cx("py-2 text-greyDark font-sansSemiBold", item.className)} key={item.id}>
                  {item.label}
                </Cell>
              ))}

              {attachmentRequiredLineItems.map((lineItem) => (
                <Row className="text-greyDark" key={lineItem.patientProcedureId}>
                  <Cell className="py-2">{lineItem.procedureCode}</Cell>
                  <Cell className="py-2">{lineItem.procedureArea}</Cell>
                  <Cell className="py-2">{lineItem.procedureDescription}</Cell>
                  <Cell className="flex items-center justify-center py-2">
                    {lineItem.requiredAttachmentTypes.includes("XRAY") && <BlueDot />}
                  </Cell>
                  <Cell className="flex items-center justify-center py-2">
                    {lineItem.requiredAttachmentTypes.includes("NOTE") && <BlueDot />}
                  </Cell>
                  <Cell className="flex items-center justify-center py-2">
                    {lineItem.requiredAttachmentTypes.includes("CHART") && <BlueDot />}
                  </Cell>
                </Row>
              ))}
            </TableGrid>
            {unknownAttachmentRequired && (
              <div className="text-greyDark text-xs font-sans mt-2">
                <span className="font-sansSemiBold">{"Note: "}</span>Other attachments required. Please log in
                to DentalXChange for more info.
              </div>
            )}
          </>
        ) : (
          <span className="text-greyDark text-xs font-sans">None</span>
        )}
        {isDraftClaim && (
          <FloatingTooltip
            content={
              supportsDigitalAttachments
                ? ""
                : "The insurance carrier does not support electronic attachments."
            }
          >
            <div
              className={cx(
                "w-fit mt-5 text-sm font-sansSemiBold",
                supportsDigitalAttachments ? "text-primaryTheme" : "text-greyLight"
              )}
            >
              <button
                className="flex"
                disabled={!supportsDigitalAttachments}
                onClick={handleOpenAttachments}
                type="button"
              >
                <div className="flex items-center justify-center h-4 mr-2">
                  <AttachmentsIcon className="h-4 w-4" />
                </div>
                {hasAttachments ? "Modify" : "Add"} Attachments
              </button>
            </div>
          </FloatingTooltip>
        )}
        {claimAttachments.length > 0 && (
          <div className="flex flex-col">
            <div className="text-sm font-sansSemiBold mt-6">Attachments</div>
            <AttachmentsSummary
              deleteAttachment={deleteAttachment}
              isDraftClaim={isDraftClaim}
              medicalImages={medicalImages}
              narrative={narrative[0]}
              perioCharts={perioCharts}
              other={other}
            />
          </div>
        )}
      </>
    </ClaimSection>
  );
};
