import { FC, useCallback } from "react";
import { OnboardEmployeeRequest } from "@libs/api/generated-api";
import { ValidationResult } from "@libs/hooks/useValidation";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";

import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Form } from "@libs/components/UI/Form";
import { ImageUploadPopover } from "components/UI/ImageUploadPopover";
import { handleError } from "utils/handleError";
import { UploadPhotoModal } from "components/UI/UploadPhotoModal";
import { deleteProfileImage } from "api/user/mutations";
import { getProfileImageQuery } from "api/user/queries";
import { getOnboardingSchema } from "components/Onboarding/OnboardingWizard";
import { OnChangeHandler } from "components/Onboarding/types";
import { Avatar } from "components/UI/Avatar";
import { PersonalInfoForm } from "./PersonalInfoForm";
import { ContactInfoForm } from "./ContactInfoForm";
import { EmergencyInfoForm } from "./EmergencyInfoForm";

interface Props {
  employeeId: number;
  onboardingData?: OnboardEmployeeRequest;
  onChange: OnChangeHandler;
  practiceId: number;
  isExcludedFromPayroll: boolean;
  validationResults: ValidationResult<OnboardEmployeeRequest, ReturnType<typeof getOnboardingSchema>>;
}

export const GeneralInformationScreen: FC<Props> = ({
  employeeId,
  onboardingData,
  onChange,
  practiceId,
  isExcludedFromPayroll,
  validationResults,
}) => {
  const showProfilePictureModal = useBoolean(false);
  const [{ data: employeeProfileImage }] = useApiQueries([
    getProfileImageQuery({ args: { practiceId, userId: employeeId } }),
  ]);
  const [{ mutate: deleteImageMutate }] = useApiMutations([deleteProfileImage]);

  const deleteProfilePicture = useCallback(() => {
    if (practiceId) {
      deleteImageMutate(
        {
          userId: employeeId,
          practiceId,
        },
        { onError: (err) => handleError(err) }
      );
    }
  }, [deleteImageMutate, employeeId, practiceId]);

  return (
    <>
      <div className="text-center">
        <div className="text-primaryTheme text-3xl">General Information</div>
        <div className="text-xs">
          This will help your practice owner and colleagues identify you on the platform.
        </div>
      </div>
      <div className="my-8 flex justify-center">
        <div className="relative">
          <Avatar
            imageUrl={employeeProfileImage?.url}
            name={onboardingData?.personalDetails.displayName}
            size="2xl"
          />
          <div>
            <ImageUploadPopover
              onDeleteImage={deleteProfilePicture}
              onUploadImage={showProfilePictureModal.on}
              iconClassName={`
                p-0.5
                rounded-full
                border
                border-primaryTheme
                bottom-0
                right-0
              `}
            />
            {showProfilePictureModal.isOn && (
              <UploadPhotoModal userId={employeeId} onRequestClose={showProfilePictureModal.off} />
            )}
          </div>
        </div>
      </div>
      <Form fieldLayout="labelIn">
        <div className="grid grid-cols-1 gap-y-6">
          <FormSection title="Personal">
            <PersonalInfoForm
              employeeId={employeeId}
              onboardingData={onboardingData}
              onChange={onChange}
              validationResults={validationResults.personalDetails}
            />
          </FormSection>
          <FormSection title="Contact">
            <ContactInfoForm
              onboardingData={onboardingData}
              onChange={onChange}
              validationResults={validationResults.contactDetails}
              isExcludedFromPayroll={isExcludedFromPayroll}
            />
          </FormSection>
          <FormSection title="Emergency Contact">
            <EmergencyInfoForm onboardingData={onboardingData} onChange={onChange} />
          </FormSection>
        </div>
      </Form>
    </>
  );
};

const FormSection: FC<{ title: string; children: JSX.Element }> = ({ title, children }) => {
  return (
    <div>
      <div className="text-base font-sansSemiBold text-center mb-3">{title}</div>
      {children}
    </div>
  );
};
