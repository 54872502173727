import { forwardRef } from "react";
import { cx } from "@libs/utils/cx";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { NumberInput, NumberInputProps } from "@libs/components/UI/NumberInput";

const cxStyles = {
  button: (disabled: boolean) =>
    cx("w-4 h-4 text-white rounded text-center absolute", disabled ? "bg-greyLight" : "bg-primaryTheme"),
};

export const isValueInRange = (
  value: string,
  min: number | string | undefined,
  max: number | string | undefined
): { valid: boolean; error: string | undefined } => {
  // Check emptiness
  if (value.length === 0) {
    return { valid: true, error: undefined };
  }

  // Check max
  const isNotGreaterThanMax = max === undefined ? true : Number(value) <= Number(max);

  if (!isNotGreaterThanMax) {
    return { valid: false, error: `Value must be less than or equal to ${String(max)}` };
  }

  // Check min
  const isNotLessThanMin = min === undefined ? true : Number(value) >= Number(min);

  if (!isNotLessThanMin) {
    return { valid: false, error: `Value must be greater than or equal to ${String(min)}` };
  }

  // Value is valid
  return { valid: true, error: undefined };
};

export type CurrencyAdjustmentInputProps = NumberInputProps & {
  symbol?: string;
  symbolClickable?: boolean;
  error?: string;
  onSymbolClick?: () => void;
};

export const CurrencyAdjustmentInput = forwardRef<HTMLInputElement, CurrencyAdjustmentInputProps>(
  (
    { disabled, value, className, id, symbol, error, symbolClickable = false, onSymbolClick, ...rest },
    ref
  ) => {
    return (
      <div className="flex items-center text-xs relative">
        {symbolClickable ? (
          <button
            type="button"
            disabled={disabled}
            onClick={() => onSymbolClick?.()}
            className={cxStyles.button(Boolean(disabled))}
          >
            {symbol}
          </button>
        ) : symbol ? (
          <span className={cxStyles.button(Boolean(disabled))}>{symbol}</span>
        ) : null}
        <FloatingTooltip content={error}>
          <NumberInput
            id={id}
            ref={ref}
            className={cx(
              "bg-greyLightest text-right rounded h-4 w-full pr-1",
              disabled && "text-greyMedium",
              !disabled && error && "outline outline-red outline-2",
              className
            )}
            step={0.01}
            placeholder="0.00"
            disabled={disabled}
            value={value}
            {...rest}
          />
        </FloatingTooltip>
      </div>
    );
  }
);
