import { FC, PropsWithChildren, useEffect } from "react";

import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as DownChevronIcon } from "@libs/assets/icons/down-chevron.svg";
import { CollapsibleSection } from "components/UI/CollapsibleSection";

interface Props {
  title: string;
  className?: string;
  gapClassName?: string;
  isOpen?: boolean;
  hasError?: boolean;
  dataTestId?: string;
}

export const FilterSection: FC<PropsWithChildren<Props>> = ({
  title,
  className,
  gapClassName = "gap-y-3",
  isOpen = false,
  hasError = false,
  dataTestId,
  children,
}) => {
  const section = useBoolean(isOpen);

  useEffect(() => {
    if (section.isOff && hasError) {
      section.on();
    }
  }, [section, hasError]);

  return (
    <div className={cx("flex flex-col", className, gapClassName)}>
      <button
        className={`
          flex
          items-center
          justify-between
          gap-x-2
          font-sansSemiBold
          text-sm
        `}
        onClick={section.toggle}
        disabled={hasError}
        type="button"
      >
        {title}
        <Icon
          className={cx("transition-transform", section.isOn && "rotate-180")}
          SvgIcon={DownChevronIcon}
        />
      </button>
      <CollapsibleSection isOpen={section.isOn} dataTestId={dataTestId} adjustVerticalGap>
        {children}
      </CollapsibleSection>
    </div>
  );
};
