import { Link } from "react-router-dom";
import { ReactNode } from "react";
import { PatientInsuranceResponse } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { isDefined } from "@libs/utils/types";
import { formatCurrency } from "@libs/utils/currency";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as ExternalLinkIcon } from "@libs/assets/icons/external-link.svg";
import { Cell, EMPTY_CELL, Row } from "@libs/components/UI/GridTableComponents";
import { PatientInsuranceBadges } from "components/Insurance/PatientInsuranceBadges";
import { useOrdinalTitles } from "components/Patient/insuranceHooks";
import { PlanActionMenu } from "components/PatientProfile/Insurance/InsurancePlans/PlanActionMenu";
import { paths } from "utils/routing/paths";

export const cxTableStyles = {
  cell: "gap-x-2 border-r border-r-greyLighter last:border-r-0",
  cellMultipleValues: "flex gap-x-7",
  cellShared: "flex items-center min-h-10 px-6 py-3",
  cellSplitWidth: "w-20",
  sectionHeaderRow: "font-sansSemiBold bg-slate-50",
};

export const PlanHeaderCell: React.FC<{
  hasMultipleInsurances: boolean;
  insurance: PatientInsuranceResponse;
  patientId: number;
}> = ({ hasMultipleInsurances, patientId, insurance }) => {
  const ordinalTitles = useOrdinalTitles();

  return (
    <div className="flex flex-row justify-between items-center w-full gap-x-2">
      <Link
        className="flex-1 px-2 text-primaryTheme hover:opacity-50"
        to={paths.patientInsuranceStep({
          insuranceId: insurance.patientInsurance.id,
          patientId: insurance.patientInsurance.patientId,
          step: "info",
        })}
      >
        {ordinalTitles[insurance.patientInsurance.insuranceOrdinal]}
      </Link>
      <div className="flex items-center gap-x-2">
        <PatientInsuranceBadges networkBadgeType="large" primaryInsurance={insurance} />
        <PlanActionMenu
          patientId={patientId}
          hasMultipleInsurances={hasMultipleInsurances}
          insurance={insurance.patientInsurance}
        />
      </div>
    </div>
  );
};

export const SectionHeader: React.FC<{ numOfInsurances?: number; subtitles?: string[]; title: string }> = ({
  numOfInsurances,
  subtitles,
  title,
}) => {
  return isDefined(numOfInsurances) && subtitles ? (
    <Row>
      <Cell className={cx("w-[230px]", cxTableStyles.cellShared, cxTableStyles.sectionHeaderRow)}>
        {title}
      </Cell>
      {Array.from({ length: numOfInsurances }, (_, index) => (
        <Cell className={cx("gap-x-7", cxTableStyles.cellShared, cxTableStyles.sectionHeaderRow)} key={index}>
          {subtitles.map((subtitle) => (
            <div className={subtitles.length === 1 ? "w-full" : cxTableStyles.cellSplitWidth} key={subtitle}>
              {subtitle}
            </div>
          ))}
        </Cell>
      ))}
      {/* Empty cell to take up remainder of page */}
      <Cell className={cx(cxTableStyles.cellShared, cxTableStyles.sectionHeaderRow)} />
    </Row>
  ) : (
    <Row>
      <Cell className={cx("col-span-full", cxTableStyles.cellShared, cxTableStyles.sectionHeaderRow)}>
        {title}
      </Cell>
    </Row>
  );
};

export const SectionRow: React.FC<{
  insurances: PatientInsuranceResponse[];
  renderValue: (item: PatientInsuranceResponse, index: number) => ReactNode | undefined;
  title: string;
}> = ({ insurances, renderValue, title }) => {
  return (
    <Row>
      <Cell className={cx("w-[230px]", cxTableStyles.cellShared, cxTableStyles.cell)}>{title}</Cell>
      {insurances.map((item, index) => {
        return (
          <Cell
            className={cx(cxTableStyles.cellShared, cxTableStyles.cell)}
            key={`${item.patientInsurance.id}-${title}`}
          >
            {renderValue(item, index) ?? EMPTY_CELL}
          </Cell>
        );
      })}
      {/* Empty cell to take up remainder of page */}
      <Cell />
    </Row>
  );
};

export const LinkRow: React.FC<{ linkPaths: string[]; linkText: string }> = ({ linkPaths, linkText }) => {
  return (
    <Row>
      <Cell className={cx("w-[230px]", cxTableStyles.cellShared, cxTableStyles.cell)} />
      {linkPaths.map((path) => {
        return (
          <Cell className={cx(cxTableStyles.cellShared, cxTableStyles.cell)} key={path}>
            <Link className="flex gap-x-2 text-primaryTheme" to={path}>
              <Icon SvgIcon={ExternalLinkIcon} size="sm" theme="primary" />
              {linkText}
            </Link>
          </Cell>
        );
      })}
      {/* Empty cell to take up remainder of page */}
      <Cell />
    </Row>
  );
};

export const CurrencyCell: React.FC<{ value: number | undefined }> = ({ value }) => {
  return (
    <div className={cxTableStyles.cellSplitWidth}>
      {value === undefined ? EMPTY_CELL : `${formatCurrency(value)}`}
    </div>
  );
};
