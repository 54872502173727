import { Dispatch, SetStateAction, useMemo } from "react";
import { RoomVO } from "@libs/api/generated-api";
import { ToggleButtonList } from "components/UI/ToggleButtonList";
import { RoomOptionContent } from "components/ScheduleAppointments/RoomOption";
import { SlotSelections } from "components/ScheduleAppointments/types";

interface Props {
  rooms: RoomVO[] | undefined;
  selections: SlotSelections;
  disabled: boolean;
  onSelectedSlotUpdated: Dispatch<SetStateAction<SlotSelections>>;
}

const isRoomDisabled = (selections: SlotSelections) =>
  !(selections.date && selections.endTime && selections.startTime && selections.dentistId);

export const RoomOptions: React.FC<Props> = ({ rooms, disabled, selections, onSelectedSlotUpdated }) => {
  const options = useMemo(() => {
    return (
      rooms?.map((room) => ({
        value: room.id,
        label: <RoomOptionContent isAvailable={room.isAvailable} text={room.roomName} />,
        disabled: isRoomDisabled(selections),
      })) || []
    );
  }, [rooms, selections]);

  return (
    <ToggleButtonList
      disabled={disabled}
      options={options}
      type="radio"
      layout="custom"
      listClassName="grid gap-2 grid-cols-2"
      selectedValue={selections.roomId}
      onChange={(_e, item) =>
        onSelectedSlotUpdated((last) => ({
          ...last,
          roomId: selections.roomId === item.value ? 0 : item.value,
        }))
      }
    />
  );
};
