import { FC } from "react";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ReactComponent as DownCaretIcon } from "@libs/assets/icons/down-chevron.svg";

export type ToggleState = "opened" | "closed";

const cxStyles = {
  size: {
    xs: "w-3",
    sm: "w-4",
    md: "w-5",
    lg: "w-6",
  },
};

export const ToggleCaret: FC<{
  initialState: ToggleState;
  size: keyof typeof cxStyles.size;
  onToggle: (state: ToggleState) => void;
}> = ({ initialState = "closed", size, onToggle }) => {
  const expand = useBoolean(initialState === "opened");

  const handleToggle = () => {
    const isOpen = !expand.isOn;

    expand.set(isOpen);

    onToggle(isOpen ? "opened" : "closed");
  };

  return (
    <button type="button" className="h-fit" onClick={handleToggle}>
      <DownCaretIcon
        className={cx("transition-transform", cxStyles.size[size], expand.isOn && "-rotate-180")}
      />
    </button>
  );
};
