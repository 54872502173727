import { FC } from "react";

import { EmailConfigVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useFormContext, useMergeFormContext } from "@libs/contexts/FormContext";
import { cxFormFieldStyle } from "@libs/components/UI/formFieldStyle";
import { FormFieldInput, FormFieldInputProps } from "@libs/components/UI/FormFieldInput";

interface Props extends FormFieldInputProps {
  domain: EmailConfigVO["domain"];
}

export const SentFromEmailInput: FC<Props> = ({ domain, ...props }) => {
  const emailDomain = domain ?? "domain.com";
  const formContext = useFormContext();
  const mergedFormContext = useMergeFormContext(formContext, { layout: props.layout });
  const controlStyles = cxFormFieldStyle.control({
    hasIcon: Boolean(props.Icon),
    hasLabel: Boolean(props.label),
    layout: mergedFormContext.layout,
  });

  return (
    <FormFieldInput {...props}>
      <div
        className={cx(
          `flex
           items-center
           gap-x-px
           absolute
           inset-0
           pointer-events-none`,
          controlStyles
        )}
      >
        <span className="text-transparent truncate">{props.value}</span>
        <span className="h-full bg-white font-sansSemiBold text-slate-700">@{emailDomain}</span>
      </div>
    </FormFieldInput>
  );
};
