import React from "react";
import { DentalProcedureVO } from "@libs/api/generated-api";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";
import { useQueryParams } from "hooks/useQueryParams";
import { ProviderFilterOption } from "components/Dashboard/hooks/useFormattedProviderRollups";
import { TableSelectProvider } from "components/Dashboard/Tables/TableSelectProvider";
import { TableSelectProcedureFilters } from "components/Dashboard/Tables/TableSelectProcedureFilters";
import { DashboardFilterFormFieldSelect } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";

export type Props = {
  allProcedures?: DentalProcedureVO[];
  providerFilterOptions: ProviderFilterOption[];
  tab: "procedures" | "appointments";
  onFiltersChanged: (filters: DashboardFilter[]) => void;
  filters: DashboardFilter[];
  tableExpanded: boolean;
};

export const ProductionAppointmentAndProcedureFilters: React.FC<Props> = ({
  tab,
  allProcedures,
  providerFilterOptions,
  tableExpanded,
  filters,
  onFiltersChanged,
}) => {
  const { query } = useQueryParams("dashboardPracticeProduction");

  const appointmentStatusOptions = React.useMemo(() => {
    return [
      {
        label: "Incomplete",
        value: "true" as const,
      },
      {
        label: "Complete",
        value: "false" as const,
      },
    ];
  }, []);

  return (
    <div className="relative flex gap-3">
      <DashboardFilterFormFieldSelect
        isClearable
        isSearchable={false}
        placeholder="Appt Status"
        className="min-w-36"
        options={appointmentStatusOptions}
        dashboardFilters={filters}
        dashboardFilterType="isScheduled"
        onChangeFilters={({ filters: updated }) => onFiltersChanged(updated)}
      />
      {tab === "procedures" && (
        <TableSelectProcedureFilters
          procedures={allProcedures}
          // Values are populated elsewhere in PracticeProductionFilterTokens
          filters={query.filters}
          onFiltersChanged={onFiltersChanged}
        />
      )}
      {
        // Since they can select provider via the chart, only show provider selection when table full screen
      }
      {tableExpanded && (
        <TableSelectProvider
          filters={filters}
          onFiltersChanged={onFiltersChanged}
          providerFilterOptions={providerFilterOptions}
        />
      )}
    </div>
  );
};
