import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { ScheduleBlockVO } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { paths } from "utils/routing/paths";
import { AppointmentGrouping } from "utils/routing/scheduling";

interface Props {
  block: ScheduleBlockVO;
  isConfirming: boolean;
  groupedBy: AppointmentGrouping;
  onConfirm: Func;
  onCancel: Func;
}

export const BlockConfirmDelete: FC<Props> = ({ block, isConfirming, groupedBy, onCancel, onConfirm }) => {
  const location = useLocation();
  const primaryText = `Do you want to delete this time block for all ${
    block.providers.length && block.rooms.length
      ? "rooms and providers"
      : block.providers.length
        ? "providers"
        : "rooms"
  }?`;

  return (
    <ConfirmationModal
      primaryText={primaryText}
      secondaryText={
        <div className="my-3">
          To remove the time block for this {groupedBy} only,
          <Link
            className="text-primaryTheme px-1"
            to={paths.editBlock(
              { blockId: block.id, instanceDate: block.date },
              { from: getFullUrl(location) }
            )}
          >
            click here
          </Link>
          to edit.
        </div>
      }
      isConfirming={isConfirming}
      onConfirm={onConfirm}
      onCancel={onCancel}
    />
  );
};
