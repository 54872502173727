import { useCallback, useState } from "react";
import { isHttpResponseError } from "@libs/utils/isHttpResponseError";
import { useNotificationContext } from "contexts/NotificationsContext";
import { handleError } from "utils/handleError";

export type ErrorState<T> = Partial<Record<keyof T, string>>;

/**
 * Used for handling server errors and tracking error state for a specific request structure.
 * @returns An object containing the following properties.
 *  serverErrors       - The collection of field specific server errors.
 *                       Bind these properties to your form controls error propery.
 *  clearServerErrors  - Clears all or only specific error messages. If you want to clear specific
 *                       fields you can do so like the following: clearServerErrors("field1", "field2");
 *  handleException    - Handles a server exception by auto populating the serverErrors from it.
 */
const BAD_REQUEST = 400;

export const useServerErrorHandler = <T>() => {
  const [serverErrors, setServerErrors] = useState<ErrorState<T>>({});
  const notifications = useNotificationContext();

  const clearServerErrors = useCallback((...fields: (keyof T)[]) => {
    if (fields.length) {
      const errorsToClear = fields.reduce((accum, field) => {
        accum[field] = undefined;

        return accum;
      }, {} as ErrorState<T>);

      setServerErrors((last) => ({ ...last, ...errorsToClear }));
    } else {
      setServerErrors({});
    }
  }, []);

  const handleException = useCallback(
    (ex: unknown) => {
      if (isHttpResponseError(ex) && ex.status === BAD_REQUEST) {
        const fieldErrors: ErrorState<T> = {};

        ex.error.errors?.forEach((e) => {
          if (e.errorCode === "FIELD_VALIDATION_ERROR" && e.field) {
            fieldErrors[e.field as keyof ErrorState<T>] = e.message;
          } else {
            notifications.handleError(e.message);
          }
        });

        setServerErrors(fieldErrors);
      } else {
        handleError(ex);
      }
    },
    [notifications]
  );

  return {
    serverErrors,
    clearServerErrors,
    handleException,
  };
};
