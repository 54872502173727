import { FC, useState, useMemo, useCallback } from "react";

import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { Button } from "@libs/components/UI/Button";
import { useAccount } from "@libs/contexts/AccountContext";
import { useApiQueries } from "@libs/hooks/useApiQueries";

import { QueryResult } from "@libs/components/UI/QueryResult";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent, FlyoverFooter } from "components/UI/FlyoverComponents";
import { FormFieldMultiSelect } from "components/UI/FormFieldMultiSelect";
import { CheckboxRow } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/CheckboxRow";

import {
  ImportedLedgerFilterQueryParams,
  TYPE_OPTIONS,
} from "components/PatientProfile/Billing/ImportedLedger/utils";

import { getEmployeeNames } from "api/employee/queries";

interface Props {
  filterQueryParams: ImportedLedgerFilterQueryParams;
  onApplyFilters: (params: Partial<ImportedLedgerFilterQueryParams>) => void;
  onClose: Func;
}

export const FiltersFlyover: FC<Props> = ({ filterQueryParams, onApplyFilters, onClose }) => {
  const { practiceId } = useAccount();
  const [selectedTypes, setSelectedTypes] = useState(() => new Set(filterQueryParams.types));
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState(
    () => new Set(filterQueryParams.employeeIds)
  );

  const [employeeNamesQuery] = useApiQueries([getEmployeeNames({ args: { practiceId } })]);

  const employeeOptions = useMemo(
    () =>
      employeeNamesQuery.data?.map((employee) => ({
        label: employee.fullDisplayName,
        value: employee.id,
      })) ?? [],
    [employeeNamesQuery.data]
  );

  const handleApplyFilters = useCallback(() => {
    const types = selectedTypes.size > 0 ? [...selectedTypes] : undefined;
    const employeeIds = selectedEmployeeIds.size > 0 ? [...selectedEmployeeIds] : undefined;

    onApplyFilters({ types, employeeIds });
    onClose();
  }, [selectedTypes, selectedEmployeeIds, onApplyFilters, onClose]);

  return (
    <Flyover
      title="Filters"
      onClose={onClose}
      headerSize="sm"
      size="md"
      dataTestId="imported-ledger-filters-flyover"
    >
      {({ close }) => (
        <QueryResult queries={[employeeNamesQuery]}>
          <FlyoverContent className="flex flex-col gap-y-6 p-6">
            <CheckboxList
              label="Transaction Types"
              options={TYPE_OPTIONS}
              selectedValues={selectedTypes}
              onChange={(value) => setSelectedTypes(value)}
              layout="vert"
            />

            <FormFieldMultiSelect
              label="Providers"
              layout="labelOut"
              placeholder="Select providers..."
              options={employeeOptions}
              value={[...selectedEmployeeIds]}
              onItemsSelected={(value) => setSelectedEmployeeIds(new Set(value))}
              formatOptionLabel={({ label, value }) => (
                <CheckboxRow label={label} checked={selectedEmployeeIds.has(value)} />
              )}
              hideSelectedOptions={false}
              closeMenuOnSelect={false}
            />
          </FlyoverContent>

          <FlyoverFooter paddingClassName="py-3">
            <Button className="min-w-button" onClick={close} theme="secondary" size="medium">
              Close
            </Button>
            <Button className="min-w-button" onClick={handleApplyFilters} theme="primary" size="medium">
              Apply
            </Button>
          </FlyoverFooter>
        </QueryResult>
      )}
    </Flyover>
  );
};
