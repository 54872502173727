import { useMemo } from "react";
import { PatientInsuranceResponse } from "@libs/api/generated-api";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as CheckIcon } from "@libs/assets/icons/check-circle-1.svg";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import {
  CurrencyCell,
  LinkRow,
  SectionHeader,
  SectionRow,
  cxTableStyles,
} from "components/PatientProfile/Insurance/InsurancePlans/TableItems";
import { paths } from "utils/routing/paths";

interface Props {
  insurances: PatientInsuranceResponse[];
  patientId: number;
}

export const CoverageTableRows: React.FC<Props> = ({ insurances, patientId }) => {
  const benefitCategories = useMemo(() => {
    const coverages = new Set(
      insurances.flatMap((insurance) =>
        (insurance.benefitCoverages ?? []).map((category) => category.benefitCategory)
      )
    );

    return [...coverages].filter(Boolean);
  }, [insurances]);

  return (
    <>
      <SectionHeader
        numOfInsurances={insurances.length}
        subtitles={["Coverage", "Copay", "Deductible"]}
        title="Coverage Table"
      />
      {benefitCategories.map((category) => (
        <SectionRow
          insurances={insurances}
          key={category}
          renderValue={({ benefitCoverages }, index) => {
            const coverage = benefitCoverages?.find(
              (benefitCoverage) => benefitCoverage.benefitCategory === category
            );

            return (
              <div className={cxTableStyles.cellMultipleValues} key={`${index}-coverage`}>
                <div className={cxTableStyles.cellSplitWidth}>{coverage?.percentCoverage}%</div>
                <CurrencyCell value={coverage?.copayAmount} />
                <div className={cxTableStyles.cellSplitWidth}>
                  {coverage?.applyDeductible ? (
                    <Icon className="w-fit" SvgIcon={CheckIcon} theme="success" />
                  ) : (
                    EMPTY_CELL
                  )}
                </div>
              </div>
            );
          }}
          title={category}
        />
      ))}
      <LinkRow
        linkPaths={insurances.map((insurance) =>
          paths.patientInsuranceStep({
            insuranceId: insurance.patientInsurance.id,
            patientId,
            step: "coverage",
          })
        )}
        linkText="Complete Coverage"
      />
    </>
  );
};
