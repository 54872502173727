import { isDefined } from "@libs/utils/types";
import { ReactNode } from "react";
import { PermissionActionVO, RoleVO } from "@libs/api/generated-api";
import { InsuranceDetailsRoute } from "components/Settings/InsuranceCarriers/InsuranceDetails";
import { FeeSchedulesRoute } from "components/Settings/FeeSchedules/FeeSchedulesRoute";
import { FeeScheduleDetailsPage } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage";
import { BusinessInformationRoute } from "components/Settings/BusinessInformationRoute";
import { ContactInformationRoute } from "components/Settings/ContactInformationRoute";
import { PracticeWorkingHoursRoute } from "components/Settings/PracticeWorkingHoursRoute";
import { PracticeHolidaysRoute } from "components/Settings/PracticeHolidaysRoute";
import { PracticeRoomsRoute } from "components/Settings/PracticeRoomsRoute";
import { PayrollInformationRoute } from "components/Settings/PayrollInformationRoute";
import { InsuranceCarriersRoute } from "components/Settings/InsuranceCarriers/InsuranceCarriersRoute";
import { ProcedureCodesRoute } from "components/Settings/ProcedureCodesRoute";
import { AppointmentCardDesignRoute } from "components/Settings/AppointmentCardDesignRoute";
import { AppointmentCategoriesRoute } from "components/Settings/AppointmentCategoriesRoute";
import { ProviderSchedulingRoute } from "components/Settings/ProviderSchedulingRoute";
import { QuickSelectOptionsRoute } from "components/Settings/QuickSelectOptionsRoute";
import { POSRoute } from "components/Settings/POSRoute";
import { BillingMerchantRoute } from "components/Settings/BillingMerchantRoute";
import { BillingPatientPortalRoute } from "components/Settings/BillingPatientPortalRoute";
import { EmailAuthenticationRoute } from "components/Settings/EmailAuthenticationRoute";
import { MessagingServicesRoute } from "components/Settings/MessagingServicesRoute";
import { MessagingServicesSetupTwilioPracticeRoute } from "components/Settings/MessagingServicesSetupTwilioPracticeRoute";
import { MessagingServicesSetupTwilioPhoneRoute } from "components/Settings/MessagingServicesSetupTwilioPhoneRoute";
import { EmailPreferencesRoute } from "components/Settings/EmailPreferencesRoute";
import { FeatureFlags } from "contexts/FeatureFlagsContext";
import { FormsRoute } from "components/Settings/Forms/FormsRoute";
import { EditFormRoute } from "components/Settings/Forms/EditFormRoute";
import { BillingCustomAdjustmentsRoute } from "components/Settings/BillingCustomAdjustmentsRoute";
import { OnlineBookingRoute } from "components/Settings/OnlineBookingRoute";
import { PracticePhonesRoute } from "components/Settings/PracticePhonesRoute";
import { PatientFoldersRoute } from "components/Settings/PatientFoldersRoute";
import { ClinicalNoteFormsRoute } from "components/Settings/ChartingSettings/ClinicalNoteFormsRoute";
import { EditClinicalNoteFormRoute } from "components/Settings/ChartingSettings/EditClinicalNoteFormRoute";
import { ClinicalNoteFormPreviewRoute } from "components/Settings/ChartingSettings/ClinicalNoteFormPreviewRoute";
import { LazyFormsKioskRoute } from "components/Settings/Forms/FormsKiosk/LazyFormsKioskRoute";
import { ReferralOptionsRoute } from "components/Settings/Forms/ReferralOptions/ReferralOptionsRoute";
import { InsurancePlansRoute } from "components/Settings/InsurancePlans/InsurancePlansRoute";
import { InsurancePreferencesRoute } from "components/Settings/InsurancePreferences/InsurancePreferencesRoute";
import { IPAuthSettingsRoute } from "components/Settings/Security/IPAuthorization/IPAuthSettingsRoute";
import { MFASettingsRoute } from "components/Settings/Security/MFASettings/MFASettingsRoute";
import { ServerAccessRoute } from "components/Settings/OnboardingSettings/ServerAccess/ServerAccessRoute";
import { LabsSettingsRoute } from "components/Settings/LabsSettingsRoute";
import { LabCaseTypesSettingsRoute } from "components/Settings/LabCaseTypesSettingsRoute";
import { LabReturnReasonsSettingsRoute } from "components/Settings/LabReturnReasonsSettingsRoute";
import { checkPermission } from "components/Roles/roleUtils";
import { PermissionAction } from "components/Roles/constants";
import { ClaimSettingsRoute } from "components/Settings/ClaimSettingsRoute";
import { AlertsRoute } from "components/Settings/Notes/AlertsRoute";
import { TagsRoute } from "components/Settings/Notes/TagsRoute";
import { ImagingPearlSettingsRoute } from "components/Settings/ImagingPearlSettingsRoute";
import { BillingMerchantSetupFinixRoute } from "components/Settings/BillingMerchantSetupFinixRoute";
import { paths } from "utils/routing/paths";
import { OnboardingTrainingSessionsRoute } from "components/Settings/OnboardingSettings/OnboardingTrainingSessionsRoute";
import { OnboardingScheduleFormRoute } from "components/Settings/OnboardingSettings/OnboardingScheduleFormRoute";
import { OnboardingSensorTestingRoute } from "components/Settings/OnboardingSettings/OnboardingSensorTestingRoute";
import { OnboardingScheduleSensorTestingRoute } from "components/Settings/OnboardingSettings/OnboardingScheduleSensorTestingRoute";
import { ClaimSettingsActivateDentalXChangeRoute } from "components/Settings/ClaimSettingsActivateDentalXChangeRoute";
import { AppointmentTagsRoute } from "components/Settings/AppointmentTagsRoute";

export type SettingsSectionCategory =
  | "practice-setup"
  | "payroll"
  | "security"
  | "scheduling"
  | "insurance"
  | "clinical"
  | "claims-settings"
  | "lab-cases"
  | "payments"
  | "imaging"
  | "communications"
  | "notes"
  | "onboarding"
  | "forms";

type Permission = {
  action: PermissionAction;
};

export type SettingsNavigationItem = {
  label: string;
  to: string;
} & Permission;

export type SettingsNestedNavigationItem = {
  label: string;
  children: SettingsNavigationItem[];
} & Permission;

export const Menu: (SettingsNestedNavigationItem | SettingsNavigationItem)[] = [
  {
    label: "Practice Setup",
    action: "ACCESS_PRACTICE",
    children: [
      {
        label: "Business Information",
        to: paths.settingsSection({ section: "business-information" }),
        action: "ACCESS_PRACTICE",
      },
      {
        label: "Contact Details",
        to: paths.settingsSection({ section: "contact-information" }),
        action: "ACCESS_PRACTICE",
      },
      {
        label: "Working Hours",
        to: paths.settingsSection({ section: "working-hours" }),
        action: "ACCESS_PRACTICE",
      },
      {
        label: "Holiday Calendar",
        to: paths.settingsSection({ section: "holiday-calendar" }),
        action: "ACCESS_PRACTICE",
      },
      {
        label: "Rooms",
        to: paths.settingsSection({ section: "practice-rooms" }),
        action: "ACCESS_PRACTICE",
      },
      {
        label: "Phones",
        to: paths.settingsSection({ section: "phones" }),
        action: "ACCESS_PRACTICE",
      },
      {
        label: "Imaging",
        to: paths.settingsSection({ section: "imaging" }),
        action: "ACCESS_PRACTICE",
      },
    ],
  },
  {
    label: "Payroll",
    to: paths.settingsSection({ section: "payroll-information" }),
    action: "ACCESS_PAYROLL",
    children: [
      {
        label: "Setup",
        to: paths.settingsSection({ section: "payroll-information" }),
        action: "ACCESS_PAYROLL",
      },
    ],
  },
  {
    label: "Scheduling",
    action: "ACCESS_SCHEDULING",
    children: [
      {
        label: "Appointment Categories",
        to: paths.settingsSection({ section: "appointment-categories" }),
        action: "ACCESS_SCHEDULING",
      },
      {
        label: "Appointment Tags",
        to: paths.settingsSection({ section: "appointment-tags" }),
        action: "ACCESS_SCHEDULING",
      },
      {
        label: "Appointment Card Design",
        to: paths.settingsSection({ section: "appointment-card-design" }),
        action: "ACCESS_SCHEDULING",
      },
      {
        label: "Procedure Codes",
        to: paths.settingsSection({ section: "procedure-codes" }),
        action: "ACCESS_SCHEDULING",
      },
      {
        label: "Provider Scheduling",
        to: paths.settingsSection({ section: "provider-scheduling" }),
        action: "ACCESS_SCHEDULING",
      },
      {
        label: "Online Booking",
        to: paths.settingsSection({ section: "online-booking" }),
        action: "ACCESS_SCHEDULING",
      },
    ],
  },
  {
    label: "Insurance",
    action: "ACCESS_INSURANCE",
    children: [
      {
        label: "Fee Schedules",
        to: paths.settingsSection({ section: "fee-schedules" }),
        action: "ACCESS_INSURANCE",
      },
      {
        label: "Insurance Carriers",
        to: paths.settingsSection({ section: "insurances" }),
        action: "ACCESS_INSURANCE",
      },
      {
        label: "Insurance Plans",
        to: paths.settingsSection({ section: "insurance-plans" }),
        action: "ACCESS_INSURANCE",
      },
      {
        label: "Default Limitations",
        to: paths.settingsSection({ section: "default-limitations" }),
        action: "ACCESS_INSURANCE",
      },
    ],
  },
  {
    label: "Claims",
    to: paths.settingsSection({ section: "claim-settings" }),
    action: "ACCESS_CLAIMS",
  },
  {
    label: "Clinical",
    action: "ACCESS_CHARTING",
    children: [
      {
        label: "Note Templates",
        to: paths.settingsSection({ section: "clinical-note-forms" }),
        action: "ACCESS_CHARTING",
      },
      {
        label: "Quick Select Options",
        to: paths.settingsSection({ section: "procedure-shortcuts" }),
        action: "ACCESS_CHARTING",
      },
    ],
  },
  {
    label: "Lab Cases",
    action: "ACCESS_LAB_CASES",
    children: [
      {
        label: "Labs",
        to: paths.settingsSection({ section: "labs" }),
        action: "ACCESS_LAB_CASES",
      },
      {
        label: "Lab Case Types",
        to: paths.settingsSection({ section: "lab-case-types" }),
        action: "ACCESS_LAB_CASES",
      },
      {
        label: "Lab Case Return Reasons",
        to: paths.settingsSection({ section: "lab-return-reasons" }),
        action: "ACCESS_LAB_CASES",
      },
    ],
  },
  {
    label: "Payments",
    action: "ACCESS_PAYMENTS",
    children: [
      {
        label: "Billing Merchant",
        to: paths.settingsSection({ section: "billing-merchant" }),
        action: "ACCESS_PAYMENTS",
      },
      {
        label: "Point of Sale (POS) Devices",
        to: paths.settingsSection({ section: "pos-devices" }),
        action: "ACCESS_PAYMENTS",
      },
      {
        label: "Patient Portal",
        to: paths.settingsSection({ section: "payment-patient-portal" }),
        action: "ACCESS_PAYMENTS",
      },
      {
        label: "Custom Adjustments",
        to: paths.settingsSection({ section: "custom-adjustments" }),
        action: "ACCESS_PAYMENTS",
      },
    ],
  },
  {
    label: "Communications",
    action: "ACCESS_COMMS",
    children: [
      {
        label: "Email Services",
        to: paths.settingsSection({ section: "email-authentication" }),
        action: "ACCESS_COMMS",
      },
      {
        label: "Email Preferences",
        to: paths.settingsSection({ section: "email-preferences" }),
        action: "ACCESS_COMMS",
      },
      {
        label: "Messaging Services",
        to: "messaging-services",
        action: "ACCESS_COMMS",
      },
    ],
  },
  {
    label: "Patient",
    action: "ACCESS_FORMS",
    children: [
      { label: "Alerts", to: paths.noteAlerts(), action: "ACCESS_FORMS" },
      {
        label: "Folders",
        to: paths.settingsSection({ section: "patient-folders" }),
        action: "ACCESS_FORMS",
      },
      {
        label: "Forms",
        to: paths.settingsSection({ section: "forms" }),
        action: "ACCESS_FORMS",
      },
      {
        label: "Referral Options",
        to: paths.settingsSection({ section: "referral-options" }),
        action: "ACCESS_FORMS",
      },
      {
        label: "Kiosk",
        to: paths.settingsSection({ section: "setup-kiosk" }),
        action: "ACCESS_FORMS",
      },
      { label: "Tags", to: paths.noteTags(), action: "ACCESS_FORMS" },
    ],
  },
  {
    label: "Security",
    action: "ACCESS_SECURITY",
    children: [
      {
        label: "IP Authorization",
        to: paths.settingsSection({ section: "ip-auth" }),
        action: "ACCESS_SECURITY",
      },
      {
        label: "Multi Factor Authentication",
        to: paths.settingsSection({ section: "mfa" }),
        action: "ACCESS_SECURITY",
      },
    ],
  },
  {
    label: "Onboarding",
    action: "ACCESS_PRACTICE",
    children: [
      {
        label: "Training Sessions",
        to: paths.settingsSection({ section: "training-sessions" }),
        action: "ACCESS_PRACTICE",
      },
      {
        label: "Sensor Testing",
        to: paths.settingsSection({ section: "sensor-testing" }),
        action: "ACCESS_PRACTICE",
      },
      {
        label: "Server Access",
        to: paths.settingsSection({ section: "server-access" }),
        action: "ACCESS_SECURITY",
      },
    ],
  },
];

type RouteSetting = {
  path: string;
  element: ReactNode;
  action: PermissionAction;
};

const SETTINGS_ROUTES: RouteSetting[] = [
  {
    path: "training-sessions",
    element: <OnboardingTrainingSessionsRoute />,
    action: "ACCESS_PRACTICE",
  },
  {
    path: "training-sessions/:meetingId",
    element: <OnboardingScheduleFormRoute />,
    action: "ACCESS_PRACTICE",
  },
  {
    path: "sensor-testing",
    element: <OnboardingSensorTestingRoute />,
    action: "ACCESS_PRACTICE",
  },
  {
    path: "sensor-testing/schedule",
    element: <OnboardingScheduleSensorTestingRoute />,
    action: "ACCESS_PRACTICE",
  },
  {
    path: "business-information",
    element: <BusinessInformationRoute />,
    action: "ACCESS_PRACTICE",
  },
  {
    path: "contact-information",
    element: <ContactInformationRoute />,
    action: "ACCESS_PRACTICE",
  },
  {
    path: "working-hours",
    element: <PracticeWorkingHoursRoute />,
    action: "ACCESS_PRACTICE",
  },
  {
    path: "holiday-calendar",
    element: <PracticeHolidaysRoute />,
    action: "ACCESS_PRACTICE",
  },
  {
    path: "practice-rooms",
    element: <PracticeRoomsRoute />,
    action: "ACCESS_PRACTICE",
  },
  {
    path: "phones",
    element: <PracticePhonesRoute />,
    action: "ACCESS_PRACTICE",
  },
  {
    path: "patient-folders",
    element: <PatientFoldersRoute />,
    action: "ACCESS_FORMS",
  },
  {
    path: "payroll-information",
    element: <PayrollInformationRoute />,
    action: "ACCESS_PAYROLL",
  },
  {
    path: "procedure-codes",
    element: <ProcedureCodesRoute />,
    action: "ACCESS_SCHEDULING",
  },
  {
    path: "appointment-tags",
    element: <AppointmentTagsRoute />,
    action: "ACCESS_SCHEDULING",
  },
  {
    path: "appointment-card-design",
    element: <AppointmentCardDesignRoute />,
    action: "ACCESS_SCHEDULING",
  },
  {
    path: "appointment-categories",
    element: <AppointmentCategoriesRoute />,
    action: "ACCESS_SCHEDULING",
  },
  {
    path: "provider-scheduling",
    element: <ProviderSchedulingRoute />,
    action: "ACCESS_SCHEDULING",
  },
  {
    path: "online-booking",
    element: <OnlineBookingRoute />,
    action: "ACCESS_SCHEDULING",
  },
  {
    path: "notes/alerts",
    element: <AlertsRoute />,
    action: "ACCESS_FORMS",
  },
  {
    path: "notes/tags",
    element: <TagsRoute />,
    action: "ACCESS_FORMS",
  },
  {
    path: "fee-schedules",
    element: <FeeSchedulesRoute />,
    action: "ACCESS_INSURANCE",
  },
  {
    path: "fee-schedules/:feeScheduleId",
    element: <FeeScheduleDetailsPage />,
    action: "ACCESS_INSURANCE",
  },
  {
    path: "insurances",
    element: <InsuranceCarriersRoute />,
    action: "ACCESS_INSURANCE",
  },
  {
    path: "insurances/:carrierId",
    element: <InsuranceDetailsRoute />,
    action: "ACCESS_INSURANCE",
  },
  {
    path: "insurance-plans",
    element: <InsurancePlansRoute />,
    action: "ACCESS_INSURANCE",
  },
  {
    path: "default-limitations",
    element: <InsurancePreferencesRoute />,
    action: "ACCESS_INSURANCE",
  },
  {
    path: "claim-settings",
    element: <ClaimSettingsRoute />,
    action: "ACCESS_CLAIMS",
  },
  {
    path: "claim-settings/activate-dentalxchange",
    element: <ClaimSettingsActivateDentalXChangeRoute />,
    action: "ACCESS_CLAIMS",
  },
  {
    path: "clinical-note-forms",
    element: <ClinicalNoteFormsRoute />,
    action: "ACCESS_CHARTING",
  },
  {
    path: "clinical-note-forms/:formId/edit",
    element: <EditClinicalNoteFormRoute />,
    action: "ACCESS_CHARTING",
  },
  {
    path: "clinical-note-forms/:formId/preview",
    element: <ClinicalNoteFormPreviewRoute />,
    action: "ACCESS_CHARTING",
  },
  {
    path: "procedure-shortcuts",
    element: <QuickSelectOptionsRoute />,
    action: "ACCESS_CHARTING",
  },
  {
    path: "labs",
    element: <LabsSettingsRoute />,
    action: "ACCESS_LAB_CASES",
  },
  {
    path: "lab-case-types",
    element: <LabCaseTypesSettingsRoute />,
    action: "ACCESS_LAB_CASES",
  },
  {
    path: "lab-return-reasons",
    element: <LabReturnReasonsSettingsRoute />,
    action: "ACCESS_LAB_CASES",
  },
  {
    path: "pos-devices",
    element: <POSRoute />,
    action: "ACCESS_PAYMENTS",
  },
  {
    path: "billing-merchant",
    element: <BillingMerchantRoute />,
    action: "ACCESS_PAYMENTS",
  },
  {
    path: "billing-merchant/setup-finix",
    element: <BillingMerchantSetupFinixRoute />,
    action: "ACCESS_PAYMENTS",
  },
  {
    path: "payment-patient-portal",
    element: <BillingPatientPortalRoute />,
    action: "ACCESS_PAYMENTS",
  },
  {
    path: "custom-adjustments",
    element: <BillingCustomAdjustmentsRoute />,
    action: "ACCESS_PAYMENTS",
  },
  {
    path: "email-authentication",
    element: <EmailAuthenticationRoute />,
    action: "ACCESS_COMMS",
  },
  {
    path: "email-preferences",
    element: <EmailPreferencesRoute />,
    action: "ACCESS_COMMS",
  },
  {
    path: "messaging-services",
    element: <MessagingServicesRoute />,
    action: "ACCESS_COMMS",
  },
  {
    path: "messaging-services/setup-twilio/practice",
    element: <MessagingServicesSetupTwilioPracticeRoute />,
    action: "ACCESS_COMMS",
  },
  {
    path: "messaging-services/setup-twilio/phone",
    element: <MessagingServicesSetupTwilioPhoneRoute />,
    action: "ACCESS_COMMS",
  },
  {
    path: "imaging",
    element: <ImagingPearlSettingsRoute />,
    action: "ACCESS_PRACTICE",
  },
  {
    path: "forms",
    element: <FormsRoute />,
    action: "ACCESS_FORMS",
  },
  {
    path: "referral-options",
    element: <ReferralOptionsRoute />,
    action: "ACCESS_FORMS",
  },
  {
    path: "setup-kiosk",
    element: <LazyFormsKioskRoute />,
    action: "ACCESS_FORMS",
  },
  {
    path: "forms/:formId/edit",
    element: <EditFormRoute />,
    action: "ACCESS_FORMS",
  },
  {
    path: "ip-auth",
    element: <IPAuthSettingsRoute />,
    action: "ACCESS_SECURITY",
  },
  {
    path: "mfa",
    element: <MFASettingsRoute />,
    action: "ACCESS_SECURITY",
  },
  {
    path: "server-access",
    element: <ServerAccessRoute />,
    action: "ACCESS_SECURITY",
  },
];

const hasSettingsRoutePermissions = (
  employeeRoleV2: RoleVO,
  domain: PermissionActionVO["domain"],
  action: PermissionAction
) => {
  return checkPermission(employeeRoleV2, domain, action).isPermitted;
};

export const getSettingsRoutes = (featureFlags: FeatureFlags, roleV2: RoleVO, hasPearlAccess?: boolean) => {
  const routes = SETTINGS_ROUTES.filter((route) => {
    // Todo: update to has access to pearl from isOnboardedWithPearl
    if (route.path === "imaging" && !hasPearlAccess) {
      return false;
    }

    return hasSettingsRoutePermissions(roleV2, "PRACTICE_SETTINGS", route.action);
  });

  const filteredMenu = Menu.filter((menuItem) => {
    return checkPermission(roleV2, "PRACTICE_SETTINGS", menuItem.action).isPermitted;
  });

  const menu = filteredMenu
    .map((item) => {
      if ("children" in item) {
        return {
          ...item,
          children: item.children.filter((child) => {
            if (child.to === paths.settingsSection({ section: "imaging" }) && !hasPearlAccess) {
              return false;
            }

            return hasSettingsRoutePermissions(roleV2, "PRACTICE_SETTINGS", child.action);
          }),
        };
      }

      if (!hasSettingsRoutePermissions(roleV2, "PRACTICE_SETTINGS", item.action)) {
        return null;
      }

      return item;
    })
    .filter(isDefined);

  return {
    routes,
    menu,
  };
};
