import {
  PatientVO,
  PaymentCreationRequest,
  PaymentDeviceVO,
  PaymentProfileVO,
  PaymentVO,
} from "@libs/api/generated-api";

export const cxStyles = {
  sectionHeader: "font-sansSemiBold text-xs",
};

export type PaymentAmountType = "FULL" | "PARTIAL";
export type CollectionPaymentMethod = PaymentVO["method"] | "REFUNDABLE_CARD";

export const paymentMethodToLabel: Record<CollectionPaymentMethod, string> = {
  CARE_CREDIT: "CareCredit",
  CASH: "Cash",
  CHECK: "Check",
  EFT: "ACH",
  EXTERNAL_POS: "Card via External POS",
  STORED_POS: "Card via Archy POS",
  STORED_PROFILE: "Card on File",
  THIRD_PARTY_FINANCING: "Third Party Financing",
  WALLET: "Patient Wallet",
  BALANCE_TRANSFER: "Credit Applied",
  REFUNDABLE_CARD: "Refundable Card",
};

export type PaymentMethodOption = { value: keyof typeof paymentMethodToLabel; label: string };

export const PAYMENT_METHOD_OPTIONS: PaymentMethodOption[] = [
  { value: "STORED_PROFILE", label: paymentMethodToLabel.STORED_PROFILE },
  { value: "STORED_POS", label: paymentMethodToLabel.STORED_POS },
  { value: "CASH", label: paymentMethodToLabel.CASH },
  { value: "CHECK", label: paymentMethodToLabel.CHECK },
  { value: "EXTERNAL_POS", label: paymentMethodToLabel.EXTERNAL_POS },
  { value: "WALLET", label: paymentMethodToLabel.WALLET },
  { value: "CARE_CREDIT", label: paymentMethodToLabel.CARE_CREDIT },
  { value: "THIRD_PARTY_FINANCING", label: paymentMethodToLabel.THIRD_PARTY_FINANCING },
  { value: "EFT", label: paymentMethodToLabel.EFT },
];

export const getPaymentMethodOptions = ({
  showWallet,
  showCreditCard,
}: {
  showWallet: boolean;
  showCreditCard: boolean;
}) => {
  return PAYMENT_METHOD_OPTIONS.filter((option) => {
    if (!showWallet && option.value === "WALLET") {
      return false;
    }

    if (!showCreditCard && option.value === "STORED_PROFILE") {
      return false;
    }

    return true;
  });
};

export const REFUND_METHOD_OPTIONS: PaymentMethodOption[] = [
  { value: "CASH", label: paymentMethodToLabel.CASH },
  { value: "CHECK", label: paymentMethodToLabel.CHECK },
  { value: "EXTERNAL_POS", label: paymentMethodToLabel.EXTERNAL_POS },
  { value: "WALLET", label: paymentMethodToLabel.WALLET },
  { value: "CARE_CREDIT", label: paymentMethodToLabel.CARE_CREDIT },
  { value: "THIRD_PARTY_FINANCING", label: paymentMethodToLabel.THIRD_PARTY_FINANCING },
];

export const getRefundMethodOptions = ({ showRefundableCards }: { showRefundableCards: boolean }) => {
  const options = [...REFUND_METHOD_OPTIONS];

  if (showRefundableCards) {
    options.push({ value: "REFUNDABLE_CARD", label: paymentMethodToLabel.REFUNDABLE_CARD });
  }

  return options;
};

export type CheckPayload = PaymentCreationRequest["checkPayload"];
export type ExternalPosPayload = PaymentCreationRequest["externalPosPayload"];
export type EftPayload = PaymentCreationRequest["eftPayload"];
export type PaymentDateType = PaymentCreationRequest["paymentCreatedAt"];
export type PaymentProfileUuid = PaymentCreationRequest["paymentProfileUuid"];
export type PaymentDeviceUuid = PaymentCreationRequest["paymentDeviceUuid"];
export type WalletUuid = PaymentCreationRequest["walletUuid"];

export type BasePaymentMethodsProps = {
  paymentProfiles?: PaymentProfileVO[];
  paymentDevices?: PaymentDeviceVO[];
  paymentMethod: CollectionPaymentMethod;
  paymentMethodOptions: PaymentMethodOption[];
  paymentDate: PaymentDateType;
  checkPayload: CheckPayload;
  externalPosPayload: ExternalPosPayload;
  eftPayload: EftPayload;
  paymentDeviceUuid: PaymentDeviceUuid;
  paymentProfileUuid: PaymentProfileUuid;
  patientId: PatientVO["id"];
  disabled?: boolean;
  onPaymentMethodChange: (paymentMethod: CollectionPaymentMethod) => void;
  onCheckChange: (checkPayload: CheckPayload) => void;
  onEftChange: (eftPayload: EftPayload) => void;
  onExternalPosChange: (externalPosPayload: ExternalPosPayload) => void;
  onPaymentDateChange: (paymentDate: PaymentDateType) => void;
  onPaymentProfileUuidChange: (paymentProfileUuid: PaymentProfileUuid) => void;
  onPaymentDeviceUuidChange: (paymentDeviceUuid: PaymentDeviceUuid) => void;
};

export type BaseErrorCases = {
  checkNumber?: string;
  checkBankName?: string;
  eftNumber?: string;
  eftBankName?: string;
  paymentProfile?: string;
  paymentDevice?: string;
  externalPosLastFour?: string;
  externalPosNameOnCard?: string;
};
export type PaymentMethodsProps<MainProps, ErrorProps> = BasePaymentMethodsProps &
  MainProps & {
    errors: ErrorProps & BaseErrorCases;
  };
