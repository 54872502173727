import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ClaimPaymentRequest } from "@libs/api/generated-api";
import { formatAsISODate } from "@libs/utils/date";
import { HIDE_NAME_OPTIONS, useFinixForm } from "@libs/hooks/useFinixForm";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { payDraftEobPayment, skipDraftEobPayment } from "api/claim/mutations";
import { handleError } from "utils/handleError";
import { useEnvContext } from "contexts/EnvContext";

const CARD_LAST_FOUR_MAX_LENGTH = 4;

const SelectedPaymentTypeToFields = {
  EFT: ["paymentDate", "bankName", "eftNumber"],
  CHECK: ["paymentDate", "bankName", "checkNumber"],
  VIRTUAL_CC: ["paymentDate", "nameOnCard", "cardInformation"],
  EXTERNAL_POS: ["paymentDate", "cardLastFour"],
};

export type SelectedPaymentType = keyof typeof SelectedPaymentTypeToFields;

type FormValues = Record<string, string>;
export type PaymentFormValues = Record<SelectedPaymentType, FormValues>;

const SelectedPaymentTypeToRequiredFields = {
  EFT: ["paymentDate", "eftNumber"],
  CHECK: ["paymentDate", "bankName", "checkNumber"],
  VIRTUAL_CC: ["paymentDate", "cardInformation"],
  EXTERNAL_POS: ["paymentDate"],
};

export const useSubmitDraftEobPayment = (
  canSkipPayment: boolean,
  draftEobPaymentUuid: string,
  onSuccessfulPayment: (selectedPaymentType: SelectedPaymentType) => void
) => {
  const { practiceId } = useAccount();
  const [selectedPaymentType, setSelectedPaymentType] = useState<SelectedPaymentType>("EFT");

  const [formValues, setFormValues] = useState<PaymentFormValues>(() => ({
    EFT: resetValues("EFT"),
    CHECK: resetValues("CHECK"),
    VIRTUAL_CC: resetValues("VIRTUAL_CC"),
    EXTERNAL_POS: resetValues("EXTERNAL_POS"),
  }));

  const handleSelectPaymentType = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
    const selectedType = event.target.value as SelectedPaymentType;

    setSelectedPaymentType(selectedType);

    switch (selectedType) {
      case "EFT": {
        setFormValues((last) => ({
          ...last,
          CHECK: resetValues("CHECK"),
          VIRTUAL_CC: resetValues("VIRTUAL_CC"),
          EXTERNAL_POS: resetValues("EXTERNAL_POS"),
        }));

        break;
      }
      case "CHECK": {
        setFormValues((last) => ({
          ...last,
          EFT: resetValues("EFT"),
          VIRTUAL_CC: resetValues("VIRTUAL_CC"),
          EXTERNAL_POS: resetValues("EXTERNAL_POS"),
        }));

        break;
      }
      case "VIRTUAL_CC": {
        setFormValues((last) => ({
          ...last,
          EFT: resetValues("EFT"),
          CHECK: resetValues("CHECK"),
          EXTERNAL_POS: resetValues("EXTERNAL_POS"),
        }));

        break;
      }
      case "EXTERNAL_POS": {
        setFormValues((last) => ({
          ...last,
          EFT: resetValues("EFT"),
          CHECK: resetValues("CHECK"),
          VIRTUAL_CC: resetValues("VIRTUAL_CC"),
        }));

        break;
      }
      default: {
        break;
      }
    }
  }, []);

  const [{ mutateAsync: payDraftEobPaymentMutation }, { mutateAsync: skipDraftEobPaymentMutation }] =
    useApiMutations([payDraftEobPayment, skipDraftEobPayment]);

  const [isMakingPayment, setIsMakingPayment] = useState(false);

  const handleSubmitPayment: FinixFormSubmissionCallback = useCallback(
    async (error, response) => {
      if (error) {
        setIsMakingPayment(false);
        handleError(error);

        return;
      }

      const data = {
        idempotencyUuid: crypto.randomUUID(),
        paymentDate: formValues[selectedPaymentType].paymentDate,
        creditCardPayload: { paymentToken: response.data.id },
      } as ClaimPaymentRequest;

      try {
        await payDraftEobPaymentMutation({ practiceId, draftEobPaymentUuid, data });
        onSuccessfulPayment(selectedPaymentType);
      } catch (err) {
        handleError(err);
      } finally {
        setIsMakingPayment(false);
      }
    },
    [
      formValues,
      selectedPaymentType,
      payDraftEobPaymentMutation,
      practiceId,
      draftEobPaymentUuid,
      onSuccessfulPayment,
    ]
  );

  const { REACT_APP_FINIX_ENVIRONMENT, REACT_APP_FINIX_APPLICATION_ID } = useEnvContext();
  const {
    binInformation,
    failedLoadingFinixScript,
    isFormValid,
    isSubmitting,
    submitForm,
    elementId,
    loadingFinix,
    handleFinixFormRef,
  } = useFinixForm({
    finixEnvironment: REACT_APP_FINIX_ENVIRONMENT,
    applicationId: REACT_APP_FINIX_APPLICATION_ID,
    formSubmissionHandler: handleSubmitPayment,
    options: HIDE_NAME_OPTIONS,
  });

  const handleFinalizePayment = useCallback(async () => {
    setIsMakingPayment(true);

    if (canSkipPayment) {
      try {
        await skipDraftEobPaymentMutation({ practiceId, draftEobPaymentUuid });
        onSuccessfulPayment(selectedPaymentType);
      } catch (err) {
        handleError(err);

        return;
      }
    } else if (selectedPaymentType === "VIRTUAL_CC") {
      submitForm?.();
    } else {
      const data = {
        idempotencyUuid: crypto.randomUUID(),
        paymentDate: formValues[selectedPaymentType].paymentDate,
      } as ClaimPaymentRequest;

      if (selectedPaymentType === "EFT") {
        data.eftPayload = {
          eftNumber: formValues.EFT.eftNumber,
          bankName: formValues.EFT.bankName,
        };
      } else if (selectedPaymentType === "CHECK") {
        data.checkPayload = {
          checkNumber: formValues.CHECK.checkNumber,
          bankName: formValues.CHECK.bankName,
        };
      } else {
        data.externalPosPayload = {
          cardLastFour: formValues.EXTERNAL_POS.cardLastFour,
        };
      }

      try {
        await payDraftEobPaymentMutation({ practiceId, draftEobPaymentUuid, data });
        onSuccessfulPayment(selectedPaymentType);
      } catch (err) {
        handleError(err);
      }

      setIsMakingPayment(false);
    }
  }, [
    canSkipPayment,
    selectedPaymentType,
    skipDraftEobPaymentMutation,
    practiceId,
    draftEobPaymentUuid,
    onSuccessfulPayment,
    submitForm,
    formValues,
    payDraftEobPaymentMutation,
  ]);

  const errorMessages = useMemo(() => {
    const messages = [];
    const selectedFormFields = SelectedPaymentTypeToRequiredFields[selectedPaymentType];
    const unfilledFields = selectedFormFields.filter(
      (fieldValue) => !formValues[selectedPaymentType][fieldValue]
    );

    if (unfilledFields.length === 1 && unfilledFields[0] === "cardInformation") {
      if (selectedPaymentType === "VIRTUAL_CC" && !isFormValid) {
        messages.push("Credit card information is incorrect");
      }
    } else if (
      selectedPaymentType === "EXTERNAL_POS" &&
      formValues[selectedPaymentType].cardLastFour &&
      formValues[selectedPaymentType].cardLastFour.length < CARD_LAST_FOUR_MAX_LENGTH
    ) {
      messages.push("Card Number must contain last 4 digits");
    } else if (unfilledFields.length) {
      messages.push(`Missing required fields: ${unfilledFields.join(", ")}`);
    }

    return messages;
  }, [isFormValid, formValues, selectedPaymentType]);

  const [disableFinalizeClaim, setDisableFinalizeClaim] = useState(false);

  useEffect(() => {
    if (selectedPaymentType === "VIRTUAL_CC") {
      setDisableFinalizeClaim(Boolean(errorMessages.length) || isSubmitting || !isFormValid);
    } else {
      setDisableFinalizeClaim(Boolean(errorMessages.length));
    }
  }, [errorMessages, isFormValid, isSubmitting, formValues, selectedPaymentType, setDisableFinalizeClaim]);

  const handleFieldUpdate = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, paymentType: SelectedPaymentType, fieldName: string) => {
      setFormValues((last) => ({
        ...last,
        [paymentType]: { ...last[paymentType], [fieldName]: event.target.value },
      }));
    },
    []
  );

  const handleDateFieldUpdate = useCallback((date: string, paymentType: SelectedPaymentType) => {
    setFormValues((last) => ({
      ...last,
      [paymentType]: { ...last[paymentType], paymentDate: date },
    }));
  }, []);

  return {
    cardBrand: binInformation?.cardBrand,
    disableFinalizeClaim,
    failedLoadingFinixScript,
    formValues,
    handleDateFieldUpdate,
    loadingFinix,
    finixElementId: elementId,
    handleFinixFormRef,
    handleFieldUpdate,
    handleFinalizePayment,
    handleSelectPaymentType,
    isMakingPayment,
    onPaymentComplete: setIsMakingPayment,
    selectedPaymentType,
  };
};

const resetValues = (paymentType: SelectedPaymentType) => {
  const values = {} as FormValues;

  SelectedPaymentTypeToFields[paymentType].forEach((fieldName) => {
    if (fieldName === "paymentDate") {
      values["paymentDate"] = formatAsISODate(new Date());
    } else {
      values[fieldName] = "";
    }
  });

  return values;
};
