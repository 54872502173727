import { PatientProcedureVO, PreAuth, UpdatePatientProcedureFeeRequest } from "@libs/api/generated-api";
import { centsToDollarsString, dollarsToCents } from "@libs/utils/currency";
import { isOneOf } from "@libs/utils/isOneOf";
import { isDefined, isString } from "@libs/utils/types";
import { required, hasValue, hasNoValue } from "@libs/utils/validators";
import { ValidationResult } from "@libs/hooks/useValidation";

// PreAuth with currency values as string amounts
type PreAuthStringAmounts = {
  [K in keyof PreAuth]: K extends "insuranceAmount" | "patientAmount" | "deductibleAmount"
    ? string
    : PreAuth[K];
};

// UpdatePatientProcedureFeeRequest with currency values as string amounts
export type UpdatePatientProcedureFeeDraft = {
  [K in keyof UpdatePatientProcedureFeeRequest]: K extends "preAuth"
    ? PreAuthStringAmounts
    : K extends
          | "ucrRate"
          | "primaryInsuranceAmount"
          | "primaryPatientAmount"
          | "primaryDeductibleAmount"
          | "secondaryInsuranceAmount"
          | "secondaryPatientAmount"
          | "secondaryDeductibleAmount"
      ? string
      : UpdatePatientProcedureFeeRequest[K];
};

/**
 * Converts the `patientProcedureFeeDraft` object to an `UpdatePatientProcedureFeeRequest`
 * object. This function takes the `patientProcedureFeeDraft` object and maps its properties to
 * the corresponding properties in the `UpdatePatientProcedureFeeRequest` type.
 * It casts the numeric properties from strings to numbers to ensure
 * compatibility with the `UpdatePatientProcedureFeeRequest` type.
 *
 * @param patientProcedureFeeDraft - The draft fee object containing the fee information.
 * @returns An `UpdatePatientProcedureFeeRequest` object with the mapped
 * properties from `patientProcedureFeeDraft`.
 */
export const getUpdatePatientProcedureFeeRequest = (
  patientProcedureFeeDraft: UpdatePatientProcedureFeeDraft
): UpdatePatientProcedureFeeRequest => {
  return {
    preAuth: patientProcedureFeeDraft.preAuth
      ? {
          ...patientProcedureFeeDraft.preAuth,
          insuranceAmount: dollarsToCents(Number(patientProcedureFeeDraft.preAuth.insuranceAmount)),
          patientAmount: dollarsToCents(Number(patientProcedureFeeDraft.preAuth.patientAmount)),
          deductibleAmount: dollarsToCents(Number(patientProcedureFeeDraft.preAuth.deductibleAmount)),
        }
      : undefined,
    ucrRate: dollarsToCents(Number(patientProcedureFeeDraft.ucrRate)),
    primaryInsuranceAmount: dollarsToCents(Number(patientProcedureFeeDraft.primaryInsuranceAmount)),
    primaryPatientAmount: dollarsToCents(Number(patientProcedureFeeDraft.primaryPatientAmount)),
    primaryDeductibleAmount: dollarsToCents(Number(patientProcedureFeeDraft.primaryDeductibleAmount)),
    secondaryInsuranceAmount: isDefined(patientProcedureFeeDraft.secondaryInsuranceAmount)
      ? dollarsToCents(Number(patientProcedureFeeDraft.secondaryInsuranceAmount))
      : undefined,
    secondaryPatientAmount: isDefined(patientProcedureFeeDraft.secondaryPatientAmount)
      ? dollarsToCents(Number(patientProcedureFeeDraft.secondaryPatientAmount))
      : undefined,
    secondaryDeductibleAmount: isDefined(patientProcedureFeeDraft.secondaryDeductibleAmount)
      ? dollarsToCents(Number(patientProcedureFeeDraft.secondaryDeductibleAmount))
      : undefined,
    downgradeDentalProcedureId: patientProcedureFeeDraft.downgradeDentalProcedureId,
  };
};

export const getUpdatePatientProcedureFeeDraft = (
  patientProcedure: PatientProcedureVO
): UpdatePatientProcedureFeeDraft => {
  const hasPreAuth = [
    patientProcedure.preAuthStatus,
    patientProcedure.preAuthNumber,
    patientProcedure.primaryPreAuthInsuranceAmount,
    patientProcedure.primaryPreAuthPatientAmount,
    patientProcedure.primaryPreAuthDeductibleAmount,
  ].some(isDefined);

  return {
    // Pre-auth fields are only included if any of the fields are defined
    preAuth: hasPreAuth
      ? {
          status:
            patientProcedure.preAuthStatus && isOneOf(patientProcedure.preAuthStatus, ["APPROVED", "DENIED"])
              ? patientProcedure.preAuthStatus
              : undefined,
          number: patientProcedure.preAuthNumber,
          insuranceAmount: centsToDollarsString(patientProcedure.primaryPreAuthInsuranceAmount),
          patientAmount: centsToDollarsString(patientProcedure.primaryPreAuthPatientAmount),
          deductibleAmount: centsToDollarsString(patientProcedure.primaryPreAuthDeductibleAmount),
        }
      : undefined,

    // UCR rate is always included
    ucrRate: centsToDollarsString(patientProcedure.ucrRate),

    // Primary fields
    primaryInsuranceAmount: centsToDollarsString(patientProcedure.insuranceAmount),
    primaryPatientAmount: centsToDollarsString(patientProcedure.patientAmount),
    primaryDeductibleAmount: centsToDollarsString(patientProcedure.deductibleAmount),

    // Secondary fields are only included if defined
    secondaryInsuranceAmount: isDefined(patientProcedure.secondaryInsuranceAmount)
      ? centsToDollarsString(patientProcedure.secondaryInsuranceAmount)
      : undefined,
    secondaryPatientAmount: isDefined(patientProcedure.secondaryPatientAmount)
      ? centsToDollarsString(patientProcedure.secondaryPatientAmount)
      : undefined,
    secondaryDeductibleAmount: isDefined(patientProcedure.secondaryDeductibleAmount)
      ? centsToDollarsString(patientProcedure.secondaryDeductibleAmount)
      : undefined,

    // Downgrade procedure id used for both pre-auth and primary
    downgradeDentalProcedureId: patientProcedure.downgradeDentalProcedureId,
  };
};

export const getUpdatePatientProcedureFeeDraftSchema = (
  draftEstimates: UpdatePatientProcedureFeeDraft,
  options: { canEditPreAuth: boolean; canEditPrimary: boolean; canEditSecondary: boolean }
) => ({
  preAuth: {
    status: {
      $validations: [
        {
          $v: required,
          $error: "Pre-auth status is required",
          $ignore: !options.canEditPreAuth,
        },
      ],
    },
    insuranceAmount: {
      $validations: [
        {
          $v: required,
          $error: "Primary pre-auth insurance estimate is required",
          $ignore: !options.canEditPreAuth,
        },
      ],
    },
    patientAmount: {
      $validations: [
        {
          $v: required,
          $error: "Primary pre-auth patient estimate is required",
          $ignore: !options.canEditPreAuth,
        },
      ],
    },
    deductibleAmount: {
      $validations: [
        {
          $v: required,
          $error: "Primary pre-auth deductible is required",
          $ignore: !options.canEditPreAuth,
        },
        {
          $v: (value: unknown) => {
            const deductible = hasValue(value) && isString(value) ? Number(value) : 0;
            const patientEstimate = draftEstimates.preAuth?.patientAmount
              ? Number(draftEstimates.preAuth.patientAmount)
              : 0;

            return deductible <= patientEstimate;
          },
          $error: "Primary pre-auth deductible cannot be greater than patient estimate",
          $ignore: !options.canEditPreAuth,
        },
      ],
    },
  },
  primaryInsuranceAmount: {
    $validations: [
      {
        $v: required,
        $error: "Primary insurance estimate is required",
        $ignore: !options.canEditPrimary,
      },
    ],
  },
  primaryPatientAmount: {
    $validations: [
      {
        $v: required,
        $error: "Primary patient estimate is required",
        $ignore: !options.canEditPrimary,
      },
    ],
  },
  primaryDeductibleAmount: {
    $validations: [
      {
        $v: required,
        $error: "Primary deductible is required",
      },
      {
        $v: (value: unknown) => {
          const deductible = hasValue(value) && isString(value) ? Number(value) : 0;
          const patientEstimate = Number(draftEstimates.primaryPatientAmount);

          return deductible <= patientEstimate;
        },
        $error: "Primary deductible cannot be greater than patient estimate",
      },
    ],
  },
  secondaryInsuranceAmount: {
    $validations: [
      {
        $v: required,
        $error: "Secondary insurance estimate is required",
        $ignore:
          // Ignore if cannot edit secondary, or if all other primary fields are
          // blank (when one secondary field is filled, all others are required)
          !options.canEditSecondary ||
          [draftEstimates.secondaryPatientAmount, draftEstimates.secondaryDeductibleAmount].every(hasNoValue),
      },
    ],
  },
  secondaryPatientAmount: {
    $validations: [
      {
        $v: required,
        $error: "Secondary patient estimate is required",
        $ignore:
          // Ignore if cannot edit secondary, or if all other primary fields are
          // blank (when one secondary field is filled, all others are required)
          !options.canEditSecondary ||
          [draftEstimates.secondaryInsuranceAmount, draftEstimates.secondaryDeductibleAmount].every(
            hasNoValue
          ),
      },
    ],
  },
  secondaryDeductibleAmount: {
    $validations: [
      {
        $v: required,
        $error: "Secondary deductible is required",
        $ignore:
          // Ignore if cannot edit secondary, or if all other primary fields are
          // blank (when one secondary field is filled, all others are required)
          !options.canEditSecondary ||
          [draftEstimates.secondaryInsuranceAmount, draftEstimates.secondaryPatientAmount].every(hasNoValue),
      },
    ],
  },
});

export type UpdatePatientProcedureFeeDraftValidationResult = ValidationResult<
  UpdatePatientProcedureFeeDraft,
  ReturnType<typeof getUpdatePatientProcedureFeeDraftSchema>
>;
