import React from "react";
import { cx } from "@libs/utils/cx";
import { ClaimSection } from "components/Claim/ClaimSection";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";

interface Props {
  isEditing: boolean;
  remarks?: string;
  onChange?: (remarks: string) => void;
}

export const RemarksSection: React.FC<Props> = ({ isEditing: isEditing, remarks, onChange }) => {
  return isEditing || remarks ? (
    <ClaimSection title="Remarks">
      <div className={cx("max-w-[826px]", isEditing && "-mb-6")}>
        <FormFieldTextarea
          edit={isEditing}
          layout="tableValue"
          placeholder="Enter any important notes related to this claim..."
          value={remarks || ""}
          maxLength={400}
          disableResize={true}
          onChange={(e) => onChange?.(e.target.value)}
        />
        {isEditing && (
          <div className="text-xs text-greyMedium text-right mt-2 mr-2">
            {remarks?.length || 0}/400 characters
          </div>
        )}
      </div>
    </ClaimSection>
  ) : null;
};
