import { ChangeEventHandler, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { DailyHuddleAppointmentVO, LabCaseStatusVO, PatientNoteVO, TagVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { formatCurrency } from "@libs/utils/currency";
import { getFullUrl } from "@libs/utils/location";
import { formatISODate, formatISOTimeAsAmPmShortHr } from "@libs/utils/date";
import { titleCaseConstant } from "@libs/utils/casing";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { MenuOptions } from "@libs/components/UI/MenuOptions";
import {
  CheckboxCell,
  ButtonCell,
  Cell,
  Row,
  cxGridTableStyles,
} from "@libs/components/UI/GridTableComponents";
import { PatientTooltipContent } from "components/DailyHuddle/PatientTooltipContent";
import { TWO_CLICKS } from "utils/handleDoubleClick";
import { paths } from "utils/routing/paths";
import { resendPatientInvite } from "api/patients/mutations";
import { handleError } from "utils/handleError";
import { genderInitial } from "components/PatientProfile/PatientOverview/utils";
import { InsuranceTooltipContent } from "components/DailyHuddle/InsuranceTooltipContent";
import { PatientInsuranceBadges } from "components/Insurance/PatientInsuranceBadges";
import { AppointmentStatus } from "components/ScheduleAppointments/AppointmentStatus";
import { LabCaseMultiStatusIconMenu } from "components/DailyHuddle/LabCaseMultiStatusIcon";
import { useItemModal } from "hooks/useItemModal";
import { LabCaseFlyover } from "components/LabCases/LabCaseFlyover";
import { ProceduresWithOverflow } from "components/LabCases/LabComponents";
import { useLabCaseStatus } from "components/LabCases/hooks/useLabCaseStatus";
import { Avatar } from "components/UI/Avatar";
import { TagsCell } from "components/DailyHuddle/TagsCell";
import { ClinicalNoteStatus } from "components/ScheduleAppointments/ClinicalNoteStatus";

interface Props {
  entry: DailyHuddleAppointmentVO;
  currentAppointmentId?: number;
  checked: boolean;
  tagOrder: NonNullable<TagVO["slug"]>[];
  excludedTagIds: number[];
  onCheckboxChange: ChangeEventHandler<HTMLInputElement>;
  onRowClick: (appointmentId: number, patientId: number) => void;
  onOpenClinicalNote: (appointmentId: number, patientId: number, uuid: string) => void;
}

const verticalPadding = "slim" as const;

// eslint-disable-next-line complexity
export const DailyHuddleRow: React.FC<Props> = ({
  entry,
  currentAppointmentId,
  checked,
  tagOrder,
  excludedTagIds,
  onCheckboxChange,
  onRowClick,
  onOpenClinicalNote,
}) => {
  const { practiceId } = useAccount();
  const navigate = useNavigate();
  const location = useLocation();
  const ellipsisMenu = useBoolean(false);
  const editLabCase = useItemModal<LabCaseStatusVO["uuid"]>(null);
  const { labCaseReturnReasons, onLabCaseStatusChange } = useLabCaseStatus();

  const handleRowClick = useCallback(
    (e: React.MouseEvent<HTMLButtonElement>) => {
      if (e.detail === TWO_CLICKS) {
        navigate(
          paths.editAppointment(
            { appointmentId: entry.appointmentId, patientId: entry.patient.id },
            { from: getFullUrl(location) }
          )
        );
      } else {
        onRowClick(entry.appointmentId, entry.patient.id);
      }
    },
    [entry.appointmentId, entry.patient.id, navigate, location, onRowClick]
  );

  const handleOpenClinicalNote = (note: PatientNoteVO) => {
    onOpenClinicalNote(entry.appointmentId, entry.patient.id, note.uuid);
  };

  const handleEditAppointment = useCallback(() => {
    navigate(
      paths.editAppointment(
        { appointmentId: entry.appointmentId, patientId: entry.patient.id },
        { from: getFullUrl(location) }
      )
    );
  }, [entry.appointmentId, entry.patient.id, navigate, location]);

  const handleViewMessages = useCallback(() => {
    navigate(paths.messaging({ patientId: entry.patient.id }));
  }, [entry.patient.id, navigate]);

  const handleViewBilling = useCallback(() => {
    navigate(paths.patientBilling({ patientId: entry.patient.id }));
  }, [entry.patient.id, navigate]);

  const [{ mutate: resendPatientInviteMutate, isLoading: isLoadingResendInvite }] = useApiMutations([
    resendPatientInvite,
  ]);

  const confirmInviteResent = useBoolean(false);

  const handleResendInvite = useCallback(() => {
    if (entry.email) {
      resendPatientInviteMutate(
        { practiceId, patientId: entry.patient.id },
        { onError: handleError, onSuccess: confirmInviteResent.on }
      );
    }
  }, [confirmInviteResent.on, entry.email, entry.patient.id, practiceId, resendPatientInviteMutate]);

  return (
    <Row isSelected={entry.appointmentId === currentAppointmentId || checked}>
      <CheckboxCell
        styleOptions={{ verticalPadding }}
        value={entry.appointmentId}
        checked={checked}
        onChange={onCheckboxChange}
      />
      <ButtonCell
        verticalPadding={verticalPadding}
        className="gap-x-2 flex flex-row items-center"
        onClick={handleRowClick}
      >
        <FloatingTooltip content={titleCaseConstant(entry.appointmentState)} theme="SMALL">
          <div>
            <AppointmentStatus appointmentState={entry.appointmentState} isSelected={false} size="sm" />
          </div>
        </FloatingTooltip>
        {formatISOTimeAsAmPmShortHr(entry.appointmentStartTime)}, {entry.appointmentDuration} mins
      </ButtonCell>
      <ButtonCell verticalPadding={verticalPadding} onClick={handleRowClick}>
        <FloatingTooltip
          content={<PatientTooltipContent patientId={entry.patient.id} />}
          displayArrow={false}
          placement="bottom-start"
        >
          <div className="w-full">
            {`${entry.patient.fullDisplayName}${entry.gender ? `, ${genderInitial[entry.gender]}` : ""}${
              entry.dob ? `, ${formatISODate(entry.dob)}` : ""
            }`}
          </div>
        </FloatingTooltip>
      </ButtonCell>
      <ButtonCell verticalPadding={verticalPadding} onClick={handleRowClick}>
        <FloatingTooltip content={entry.room.roomName} theme="SMALL">
          <div className="text-ellipsis overflow-hidden whitespace-nowrap">{entry.room.roomName}</div>
        </FloatingTooltip>
      </ButtonCell>
      <ButtonCell verticalPadding={verticalPadding} onClick={handleRowClick}>
        <div className="flex items-center squeeze-children">
          <Avatar size="sm" borderColor="white" name={entry.dentist.fullDisplayName} />

          {entry.provider.id === entry.dentist.id ? null : (
            <Avatar size="sm" borderColor="white" name={entry.provider.fullDisplayName} />
          )}
        </div>
      </ButtonCell>
      <Cell
        className={cx(
          "flex items-center gap-x-1",
          cxGridTableStyles.cellPadding({ horizontal: "default", vertical: verticalPadding }),
          cxGridTableStyles.dataCell
        )}
      >
        {entry.clinicalNoteStatus ? (
          <ClinicalNoteStatus
            onNoteLoaded={handleOpenClinicalNote}
            patientId={entry.patient.id}
            appointmentId={entry.appointmentId}
            status={entry.clinicalNoteStatus}
          />
        ) : null}
        <LabCaseMultiStatusIconMenu
          labCaseStatuses={entry.labCaseStatuses}
          returnReasons={labCaseReturnReasons}
          onStatusChange={(...args) => onLabCaseStatusChange(...args).catch(handleError)}
        />
      </Cell>
      <ButtonCell verticalPadding={verticalPadding} onClick={handleRowClick}>
        <TagsCell excludedTagIds={excludedTagIds} tagOrder={tagOrder} entry={entry} />
      </ButtonCell>

      <ButtonCell verticalPadding={verticalPadding} onClick={handleRowClick}>
        {formatCurrency(entry.balanceAmount)}
      </ButtonCell>
      <ButtonCell verticalPadding={verticalPadding} onClick={handleRowClick}>
        {entry.dailyHuddleInsuranceInfo && (
          <FloatingTooltip
            content={
              <InsuranceTooltipContent
                insuranceId={entry.dailyHuddleInsuranceInfo.patientInsuranceId}
                patientId={entry.patient.id}
              />
            }
            displayArrow={false}
            placement="bottom-end"
          >
            <div className="flex w-full squeeze-children">
              <PatientInsuranceBadges
                badgeSize="lg"
                borderColor="white"
                displayDivider={false}
                isLoading={false}
                dailyHuddleInsuranceInfo={entry.dailyHuddleInsuranceInfo}
              />
            </div>
          </FloatingTooltip>
        )}
      </ButtonCell>
      <Cell className={cx("justify-center flex items-center", cxGridTableStyles.dataCell)}>
        <ButtonMenu
          isOpen={ellipsisMenu.isOn}
          onRequestClose={ellipsisMenu.off}
          onRequestOpen={() => {
            onRowClick(entry.appointmentId, entry.patient.id);
            ellipsisMenu.on();
          }}
          menuContent={
            <MenuOptions
              options={[
                {
                  label: "Edit Appointment",
                  value: "edit",
                },
                {
                  label: "Text Patient",
                  value: "text",
                },
                {
                  label: "View Billing",
                  value: "billing",
                },
                ...(entry.isPatientOnboarded
                  ? []
                  : [
                      {
                        disabled: !entry.email || isLoadingResendInvite || confirmInviteResent.isOn,
                        value: "invite" as const,
                        label: (
                          <FloatingTooltip content={entry.email ? "" : "No email"} theme="SMALL">
                            <div>
                              {confirmInviteResent.isOff
                                ? "Resend Patient Portal Invite"
                                : isLoadingResendInvite
                                  ? "Resending..."
                                  : "Invite Resent"}
                            </div>
                          </FloatingTooltip>
                        ),
                      },
                    ]),
                ...entry.labCaseStatuses.map((labCaseStatus) => ({
                  label: (
                    <div className="flex items-center gap-x-1 w-full">
                      <div className="whitespace-nowrap">Edit Lab Case:</div>
                      <div className="overflow-hidden">
                        <ProceduresWithOverflow procedures={labCaseStatus.patientProcedures ?? []} />
                      </div>
                    </div>
                  ),
                  value: "labCase" as const,
                  id: labCaseStatus.uuid,
                })),
              ]}
              onOptionClick={(option) => {
                switch (option.value) {
                  case "edit": {
                    handleEditAppointment();

                    break;
                  }
                  case "text": {
                    handleViewMessages();

                    break;
                  }
                  case "billing": {
                    handleViewBilling();

                    break;
                  }
                  case "invite": {
                    handleResendInvite();

                    break;
                  }
                  case "labCase": {
                    editLabCase.open(option.id);

                    break;
                  }
                  // No default
                }
              }}
            />
          }
          placement="bottom-end"
        >
          {(props) => <ButtonIcon SvgIcon={MenuIcon} {...props} />}
        </ButtonMenu>
      </Cell>
      {editLabCase.isOpen && (
        <LabCaseFlyover
          labCaseUuid={editLabCase.item}
          onClose={editLabCase.close}
          onSave={editLabCase.close}
        />
      )}
    </Row>
  );
};
