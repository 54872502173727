import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { InsurancePlanVO, PatientInsuranceVO } from "@libs/api/generated-api";
import { Button } from "@libs/components/UI/Button";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import EmptyInsuranceRecords from "assets/images/empty-claims-records.svg";
import { hasDeductiblesMaximumsEntered } from "components/PatientProfile/Insurance/utils";
import { paths } from "utils/routing/paths";

type Props = {
  actionStyle?: "button" | "link";
  allowAction?: boolean;
  insurancePlan?: InsurancePlanVO;
  patientId: number;
  patientInsurance?: PatientInsuranceVO;
};
export const EmptyInsurances: React.FC<Props> = ({
  actionStyle = "button",
  allowAction = true,
  insurancePlan,
  patientId,
  patientInsurance,
}) => {
  const navigate = useNavigate();

  let href = paths.patientInsuranceStep({ patientId, step: "info", insuranceId: "new" });
  let text = "Add Insurance";
  const needsCoverages =
    patientInsurance && insurancePlan && !hasDeductiblesMaximumsEntered(patientInsurance, insurancePlan);

  if (patientInsurance) {
    text = needsCoverages ? "Configure Coverage" : "Configure Plan";
    href = needsCoverages
      ? paths.patientInsuranceStep({ patientId, step: "coverage", insuranceId: patientInsurance.id })
      : paths.patientInsuranceStep({ patientId, step: "carrier", insuranceId: patientInsurance.id });
  }

  const navigateToPtInsurance = useCallback(() => {
    if (patientInsurance) {
      needsCoverages
        ? navigate(
            paths.patientInsuranceStep({ patientId, step: "coverage", insuranceId: patientInsurance.id })
          )
        : navigate(
            paths.patientInsuranceStep({ patientId, step: "carrier", insuranceId: patientInsurance.id })
          );
    } else {
      navigate(paths.patientInsuranceStep({ patientId, step: "info", insuranceId: "new" }));
    }
  }, [navigate, needsCoverages, patientId, patientInsurance]);

  return (
    <div className="text-center p-3 space-y-2">
      <div className="flex justify-center">
        <img alt="No Recorded Insurances" src={EmptyInsuranceRecords} />
      </div>
      <div className="text-xs mt-2.5">
        {patientInsurance ? "Insurance Plan Needs Configuration" : "No Recorded Insurance Details"}
      </div>
      {allowAction && (
        <div>
          {actionStyle === "button" ? (
            <ButtonInternalLink size="small" to={href}>
              {text}
            </ButtonInternalLink>
          ) : (
            <Button onClick={navigateToPtInsurance} size="small" theme="link">
              <div className="text-xs">{text}</div>
            </Button>
          )}
        </div>
      )}
    </div>
  );
};
