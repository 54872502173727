import { parseISO } from "date-fns";
import { InsuranceCarrierVO, InsuranceDetailsVO, PatientVO } from "@libs/api/generated-api";
import { formatAsISODate } from "@libs/utils/date";
import { ValidationResult } from "@libs/hooks/useValidation";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { CarrierSelector } from "components/Insurance/CarrierSelector/CarrierSelector";
import { SelectInsuranceSubscriberType } from "components/Patient/SelectInsuranceSubscriberType";
import { InsuranceState } from "components/Patient/hooks";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { Section } from "components/Insurance/Section";
import { ToggleGroup } from "components/UI/ToggleGroup";
import { DependentPrimarySubscriber, PrimarySubscriber } from "components/Patient/types";
import { SubscriberIDField } from "components/Patient/SubscriberIDField";
import { SubscriberSSNField } from "components/Patient/SubscriberSSNField";
import { getPatientInsuranceSchema } from "components/Patient/formSchemas";
import { UpdateInsuranceFormState } from "components/Patient/formPostData";
import { PatientMatch } from "components/Patient/PatientMatch";
import { planCxStyles } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/styles";

interface Props {
  autoEligibilityCarrierIds: Set<number> | undefined;
  carrierId: number;
  editing: boolean;
  hasLinkedPlan: boolean;
  insuranceCarriers: InsuranceCarrierVO[];
  insuranceState: InsuranceState;
  insuranceSubscriberType: InsuranceDetailsVO["type"];
  isCreating: boolean;
  isPrimary: boolean;
  onInsuranceChange: (partial: Partial<InsuranceState>) => void;
  onUpdateCarrier: (params: { carrierId: number }) => void;
  onUpdateDependentPrimarySubscriber: (partial: Partial<DependentPrimarySubscriber>) => void;
  onUpdateInsuranceType: (value: InsuranceDetailsVO["type"]) => void;
  onUpdatePrimarySubscriber: (updates: Partial<PrimarySubscriber>) => void;
  ssnLastFour: string | undefined;
  subscriber: PrimarySubscriber;
  subscriberMatch: PatientVO | undefined;
  validation: ValidationResult<UpdateInsuranceFormState, ReturnType<typeof getPatientInsuranceSchema>>;
}

const INCLUDED_SUBSCRIBER_TYPES: Set<InsuranceDetailsVO["type"]> = new Set([
  "PRIMARY_SUBSCRIBER",
  "DEPENDENT",
]);

// eslint-disable-next-line complexity
export const InsuranceDetailsSection: React.FC<Props> = ({
  autoEligibilityCarrierIds,
  carrierId,
  editing,
  hasLinkedPlan,
  insuranceCarriers,
  insuranceState,
  insuranceSubscriberType,
  isCreating,
  isPrimary,
  onInsuranceChange,
  onUpdateCarrier,
  onUpdateDependentPrimarySubscriber,
  onUpdateInsuranceType,
  onUpdatePrimarySubscriber,
  ssnLastFour,
  subscriber,
  subscriberMatch,
  validation,
}) => {
  return (
    <Section
      className={planCxStyles.section}
      includePadding={false}
      title="Insurance Details"
      useCustomStyling={true}
    >
      <div className={planCxStyles.fieldsContainer}>
        {isPrimary && subscriberMatch && isCreating && (
          <div className="col-span-2">
            <PatientMatch patient={subscriberMatch}>
              This insurance and subscriber already exist.
            </PatientMatch>
          </div>
        )}
        <CarrierSelector
          autoEligibilityCarrierIds={autoEligibilityCarrierIds}
          display="label"
          edit={isCreating || (editing && !hasLinkedPlan)}
          error={
            isPrimary
              ? validation.primarySubscriber.carrierId.$error
              : validation.dependentPrimarySubscriber.carrierId.$error
          }
          insuranceCarriers={insuranceCarriers}
          layout="labelOut"
          onUpdate={onUpdateCarrier}
          placeholder="Search by Carrier Name or Payer ID..."
          required
          value={carrierId}
        />
        <div />
        <SelectInsuranceSubscriberType
          disabled={!isCreating}
          formType="INSURANCE"
          includedOptions={INCLUDED_SUBSCRIBER_TYPES}
          insuranceSubscriberType={insuranceSubscriberType}
          onUpdate={onUpdateInsuranceType}
          patientInsuranceFlag={true}
        />
        <div className="flex flex-col w-full gap-y-1">
          <FormFieldLabel
            className="text-xs font-sansSemiBold"
            content={subscriber.subscriberIdType === "subscriberId" ? "Subscriber ID" : "Subscriber SSN"}
            required
          />
          <div className="flex items-start w-full gap-x-2">
            {editing && (
              <ToggleGroup
                onChange={(_e, { value }) =>
                  isPrimary
                    ? onUpdatePrimarySubscriber({ subscriberIdType: value })
                    : onUpdateDependentPrimarySubscriber({ subscriberIdType: value })
                }
                options={[
                  { label: "ID#", value: "subscriberId" },
                  { label: "SSN", value: "ssn" },
                ]}
                size="md"
                selectedValue={subscriber.subscriberIdType}
              />
            )}
            {subscriber.subscriberIdType === "subscriberId" ? (
              <SubscriberIDField
                displayLabel={false}
                displayPlaceholder={false}
                edit={editing}
                error={
                  isPrimary
                    ? validation.primarySubscriber.subscriberId.$error
                    : validation.dependentPrimarySubscriber.subscriberId.$error
                }
                isLoading={false}
                layout="labelOut"
                onChange={(val) => {
                  isPrimary
                    ? onUpdatePrimarySubscriber({
                        subscriberId: val,
                      })
                    : onUpdateDependentPrimarySubscriber({ subscriberId: val });
                }}
                value={subscriber.subscriberId ?? ""}
              />
            ) : (
              <SubscriberSSNField
                displayLabel={false}
                edit={editing}
                error={
                  isPrimary
                    ? validation.primarySubscriber.ssn.$error
                    : validation.dependentPrimarySubscriber.ssn.$error
                }
                isLoading={false}
                layout="labelOut"
                onValueChange={(val) => {
                  isPrimary
                    ? onUpdatePrimarySubscriber({ ssn: val })
                    : onUpdateDependentPrimarySubscriber({ ssn: val });
                }}
                ssnLastFour={isPrimary ? ssnLastFour : subscriber.ssnLastFour}
                userId={subscriber.patientId}
                value={subscriber.ssn}
              />
            )}
          </div>
        </div>
        <FormFieldSelectMenusDatepicker
          edit={editing || isCreating}
          label="Eligibility Start Date"
          layout="labelOut"
          onChange={(date) => {
            onInsuranceChange({ startDate: date ? formatAsISODate(date) : undefined });
          }}
          selected={insuranceState.startDate ? parseISO(insuranceState.startDate) : undefined}
        />
        <FormFieldSelectMenusDatepicker
          edit={editing || isCreating}
          label="Eligibility End Date"
          layout="labelOut"
          onChange={(date) => {
            onInsuranceChange({ expiryDate: date ? formatAsISODate(date) : undefined });
          }}
          selected={insuranceState.expiryDate ? parseISO(insuranceState.expiryDate) : undefined}
        />
      </div>
    </Section>
  );
};
