import React, { PropsWithChildren, useCallback, useEffect, useMemo, useRef } from "react";
import { asyncNoop, noop } from "@libs/utils/noop";
import { useBoolean } from "@libs/hooks/useBoolean";
import { MedicalImageVO, MountVO, PearlImageResponseGetEndpoints } from "@libs/api/generated-api";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { ApiQueryResult, UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { useBlocker } from "react-router-dom";
import { getFullUrl } from "@libs/utils/location";
import {
  DEFAULT_LINE_WIDTH,
  EDITOR_COLORS,
} from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/constants";
import { usePathParams } from "hooks/usePathParams";
import { useImageEditQueue } from "components/PatientProfile/Imaging/MountRoute/hooks/useImageEditQueue";
import { useImagingDeviceSettings } from "components/PatientProfile/Imaging/hooks/useImagingDeviceSettings";
import { usePersistedEditorSettings } from "components/PatientProfile/Imaging/MountRoute/hooks/usePersistedEditorSettings";
import { PearlConfig, PearlTool } from "components/PatientProfile/Imaging/types/pearl";
import {
  CanvasEditorRef,
  DrawLayer,
  DrawSettings,
  ImageLens,
  PageDirection,
  ReactEditorContext,
} from "components/PatientProfile/Imaging/types/imageEditor";
import { TeethSearchMode } from "utils/routing/patient";

const DEFAULT_DRAW_STATE: DrawSettings = {
  brushSize: DEFAULT_LINE_WIDTH,
  color: EDITOR_COLORS[0],
  mouseMode: "draw",
  drawMode: "free_draw",
};

const PEARL_DEFAULT_CONFIG: PearlConfig = {
  toolsOn: new Set<PearlTool>(["pathologies", "measurements"]),
  layersAvailable: new Set([
    "crown",
    "periapical.radiolucency",
    "filling",
    "anatomy",
    "caries",
    "margin.discrepancy",
    "implant",
    "root.canal",
    "bridge",
    "calculus",
  ]),
};
const DEFAULT_LAYERS_SHOWN = new Set<DrawLayer>(["annotations", "filters", "pearl"]);
const ImageEditorContext = React.createContext<ReactEditorContext>({
  editor: {
    current: null,
  },
  lensApplied: undefined,
  setLensApplied: noop,
  drawSettings: DEFAULT_DRAW_STATE,
  setDrawSettings: noop,
  isInitialized: false,
  revertImage: noop,
  handleFabricInit: noop,
  layersShown: DEFAULT_LAYERS_SHOWN,
  updateLayersShown: noop,
  handleImageUpdate: asyncNoop,
  handleAnnotation: asyncNoop,
  imageSelected: undefined,
  hasNextPage: false,
  hasPrevPage: false,
  handlePageChange: noop,
  updatePearlConfig: noop,
  pearlConfig: PEARL_DEFAULT_CONFIG,
});

export const ImageEditorProvider: React.FC<
  PropsWithChildren<{
    imageSelectedQuery: ApiQueryResult<MedicalImageVO>;
    pearlAnalysisQuery: ApiQueryResult<PearlImageResponseGetEndpoints>;
    mountQuery: ApiQueryResult<MountVO>;
    teethSearchMode?: TeethSearchMode;
    medicalImagesInfiniteQuery: UseInfiniteApiQueryResult<MedicalImageVO[]>;
    imageIds?: number[];
    selectedImageId?: number;
    mountId?: number;
    onChangeSelectedImage: (selectedImageId: number) => void;
  }>
> = ({
  children,
  imageSelectedQuery,
  teethSearchMode,
  pearlAnalysisQuery,
  medicalImagesInfiniteQuery,
  mountQuery,
  mountId,
  selectedImageId,
  imageIds,
  onChangeSelectedImage,
}) => {
  const editor = React.useRef<CanvasEditorRef | null>(null);
  const { drawSettings, setDrawSettings, pearlConfig, updatePearlConfig, layersShown, updateLayersShown } =
    usePersistedEditorSettings({ editor });
  const [lensApplied, setLensApplied] = React.useState<ImageLens | undefined>(undefined);
  const isRevertingCanvas = useRef(false);
  const { patientId } = usePathParams("imagingEditImage");

  const comparedImages = useFlattenPages(medicalImagesInfiniteQuery.data);

  const imageList = useMemo(() => {
    return (mountId ? mountQuery.data?.images : comparedImages) ?? [];
  }, [comparedImages, mountQuery.data?.images, mountId]);

  const fetchNextMedicalImages = medicalImagesInfiniteQuery.fetchNextPage;
  const handlePageChange = useCallback(
    (pageDelta: PageDirection) => {
      if (imageList.length > 0) {
        const currentIndex = imageList.findIndex((i) => i.id === selectedImageId);
        const newIndex = currentIndex + pageDelta;

        if (imageIds) {
          // For arbitrary imageIds, we don't need to fetch more data. We use getMedicalImageDetails to fetch the imageId directly
          const newImageId = imageIds[newIndex];

          onChangeSelectedImage(newImageId);
        } else {
          // In the case of user searching teeth, we need to paginate in images

          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          if (teethSearchMode && currentIndex >= imageList.length - 2) {
            fetchNextMedicalImages();
          }

          const newImage = imageList[newIndex] as MedicalImageVO | undefined;

          if (newImage?.id) {
            onChangeSelectedImage(newImage.id);
          }
        }
      }
    },
    [fetchNextMedicalImages, imageIds, imageList, onChangeSelectedImage, selectedImageId, teethSearchMode]
  );
  const hasPrevPage = useMemo(() => {
    if (imageList.length > 0) {
      const currentIndex = imageList.findIndex((i) => i.id === selectedImageId);

      return currentIndex > 0;
    }

    return false;
  }, [imageList, selectedImageId]);
  const hasNextPage = useMemo(() => {
    if (imageList.length > 0) {
      const currentIndex = imageList.findIndex((i) => i.id === selectedImageId);

      return currentIndex < imageList.length - 1;
    }

    return false;
  }, [imageList, selectedImageId]);

  const imageSelected = imageSelectedQuery.data;
  const { sensorSettings, archyServiceSensorSettings, cameraSettings } = useImagingDeviceSettings();
  const imageSensorSetting = React.useMemo(
    () =>
      [...sensorSettings, ...archyServiceSensorSettings, ...cameraSettings].find(
        (s) => s.label === imageSelected?.sensor
      ),
    [sensorSettings, archyServiceSensorSettings, cameraSettings, imageSelected?.sensor]
  );

  useEffect(() => {
    // If a user switches images, clear the select setting
    setDrawSettings((prev) => {
      if (prev.mouseMode === "select") {
        return {
          ...prev,
          mouseMode: undefined,
          drawMode: undefined,
        };
      } else if (prev.drawMode === "distance") {
        const pixelsPerMM = imageSensorSetting?.calibration?.pixelsPerMM;

        // Ensure the newly selected image has the correct calibration and ensure the tool can be used
        // by adjusting the mouseMode when we have calibration.
        return {
          ...prev,
          pixelsPerMM,
          mouseMode: pixelsPerMM ? "draw" : undefined,
        };
      }

      return prev;
    });
  }, [imageSensorSetting?.calibration?.pixelsPerMM, selectedImageId, setDrawSettings]);

  const { handleImageUpdate, revertImage, debouncedImageChanges } = useImageEditQueue({
    imageMount: mountQuery.data,
    patientId,
    imageEditor: editor,
  });

  const initialized = useBoolean(false);
  const blocker = useBlocker((args) => {
    const hasChangedUrl = getFullUrl(args.currentLocation) !== getFullUrl(args.nextLocation);

    if (hasChangedUrl) {
      // If the url has changed, we want to flush any pending changes
      debouncedImageChanges.flush();
    }

    return debouncedImageChanges.isPending();
  });

  const isSavingImage = debouncedImageChanges.isPending();

  useEffect(() => {
    if (!isSavingImage && blocker.state === "blocked") {
      // Block until changes have been submitted
      blocker.proceed();
    }
  }, [blocker, isSavingImage]);

  const val = React.useMemo(
    () => ({
      drawSettings,
      editor,
      setDrawSettings,
      imageSelected,
      imageSensorSetting,
      layersShown,
      updateLayersShown,
      handleFabricInit: (doneInitializing: boolean) => {
        initialized.set(doneInitializing);

        if (editor.current && doneInitializing) {
          editor.current.setDrawingConfig({ ...drawSettings, calibrationMode: false });
        }
      },
      isInitialized: initialized.isOn,
      handleImageUpdate,
      updatePearlConfig,
      pearlAnalysis: pearlAnalysisQuery.data?.result,
      pearlConfig,
      lensApplied,
      setLensApplied,
      handleAnnotation: imageSelected
        ? async () => {
            if (!isRevertingCanvas.current) {
              return handleImageUpdate(imageSelected, { newAnnotations: true });
            }

            return undefined;
          }
        : asyncNoop,
      handlePageChange,
      hasPrevPage,
      hasNextPage,
      revertImage: async () => {
        if (imageSelected) {
          // Reverting may trigger removal events.  While reverting, we don't want to write from these removal events
          isRevertingCanvas.current = true;
          await revertImage(imageSelected);
          isRevertingCanvas.current = false;
        }
      },
    }),
    [
      drawSettings,
      setDrawSettings,
      imageSelected,
      imageSensorSetting,
      layersShown,
      updateLayersShown,
      initialized,
      handleImageUpdate,
      updatePearlConfig,
      pearlAnalysisQuery.data?.result,
      pearlConfig,
      lensApplied,
      handlePageChange,
      hasPrevPage,
      hasNextPage,
      revertImage,
    ]
  );

  return <ImageEditorContext.Provider value={val}>{children}</ImageEditorContext.Provider>;
};
export const useImageEditorContext = () => React.useContext(ImageEditorContext);
