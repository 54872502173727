import React from "react";
import { AppointmentClaimSummaryVO, ClaimSummaryVO } from "@libs/api/generated-api";
import { Pill } from "@libs/components/UI/Pill";
import {
  colorsByClaimState,
  isInDraftEobPaymentClaim,
  paymentStatusToPillText,
  preAuthColorsByClaimState,
  preAuthStateToPillText,
  stateToPillText,
  themesByClaimInsuranceStatus,
} from "components/Claims/utils";

interface Props {
  claim: ClaimSummaryVO | AppointmentClaimSummaryVO;
}

export const ClaimStatePill: React.FC<Props> = ({ claim }) => {
  const stateToUse = isInDraftEobPaymentClaim(claim) ? "EOB_IN_PROGRESS" : claim.state;

  return "isPreAuth" in claim && claim.isPreAuth ? (
    <Pill theme={preAuthColorsByClaimState[claim.state]}>{preAuthStateToPillText[claim.state]}</Pill>
  ) : colorsByClaimState[claim.state] || isInDraftEobPaymentClaim(claim) ? (
    <Pill theme={colorsByClaimState[stateToUse]}>{stateToPillText[stateToUse]}</Pill>
  ) : themesByClaimInsuranceStatus[claim.insurancePaymentStatus] ? (
    <Pill theme={themesByClaimInsuranceStatus[claim.insurancePaymentStatus]}>
      {paymentStatusToPillText[claim.insurancePaymentStatus]}
    </Pill>
  ) : null;
};
