import React from "react";
import { SubscriberVO } from "@libs/api/generated-api";
import { formatSSNInput, getFormattedSSNHidden } from "@libs/utils/ssn";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { getSsn } from "api/user/queries";
import { SsnRevealButton } from "components/UI/SsnRevealButton";

export const SsnValue: React.FC<{
  userId?: number;
  ssnLastFour: string;
}> = ({ userId, ssnLastFour }) => {
  const { practiceId } = useAccount();
  const showFullSsn = useBoolean(false);
  const showSsn = userId !== undefined && showFullSsn.isOn;
  const [{ data: ssnData, isLoading: isSsnLoading }] = useApiQueries([
    getSsn({
      args: { userId: userId as number, practiceId },
      queryOptions: {
        enabled: showSsn,
      },
    }),
  ]);

  return (
    <div className="flex gap-3 items-center">
      <div>
        {showFullSsn.isOn && ssnData?.ssn ? formatSSNInput(ssnData.ssn) : getFormattedSSNHidden(ssnLastFour)}
      </div>
      <SsnRevealButton
        isSsnLoading={isSsnLoading}
        revealSsn={showFullSsn.isOn}
        onClick={showFullSsn.toggle}
      />
    </div>
  );
};
export const SubscriberOrSsnValue: React.FC<{ subscriber: SubscriberVO }> = ({ subscriber }) => {
  if (subscriber.ssnLastFour) {
    return <SsnValue ssnLastFour={subscriber.ssnLastFour} userId={subscriber.patientId} />;
  }

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{subscriber.externalMemberId}</>;
};
