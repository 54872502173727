import { FC, FormEvent, Fragment } from "react";
import { CustomHolidayVO } from "@libs/api/generated-api";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { Icon } from "@libs/components/UI/Icon";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ReactComponent as RemoveIcon } from "@libs/assets/icons/minus.svg";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";

import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Form } from "@libs/components/UI/Form";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { TimeInput } from "components/UI/TimeInput";

const cxStyles = {
  datePicker: "w-36 mr-3",
  content: "flex items-center max-h-9",
  label: "text-xs",
};

interface Props {
  selectedYear: string;
  customHolidays: EditableCustomHoliday[];
  isSaving: boolean;
  onCancel: Func;
  onSave: Func;
  onAddItem: Func;
  onRemoveItem: (index: number) => void;
  onChangeItem: (index: number, value: Partial<EditableCustomHoliday>) => void;
}

export type EditableCustomHoliday = Omit<CustomHolidayVO, "id"> & { id: string | number };

export const getEditTimeOffFormData = ({
  customHolidays,
  selectedYear,
}: Pick<Props, "selectedYear" | "customHolidays">) => {
  return {
    firstOfYear: getLocalDate(`${selectedYear}-01-01`),
    endOfYear: getLocalDate(`${selectedYear}-12-31`),
    isFormDisabled: customHolidays.some((item) => !item.startDate || !item.endDate),
  };
};

export const TimeOffForm: FC<Props> = ({
  customHolidays,
  selectedYear,
  isSaving,
  onCancel,
  onSave,
  onAddItem,
  onChangeItem,
  onRemoveItem,
}) => {
  const { firstOfYear, endOfYear, isFormDisabled } = getEditTimeOffFormData({ customHolidays, selectedYear });

  return (
    <Form
      onSubmit={(e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onSave();
      }}
    >
      {customHolidays.length ? (
        <div
          className={`
            grid
            grid-cols-[min-content_min-content_min-content]
            gap-x-5
            gap-y-3
          `}
        >
          <p className={cxStyles.label}>Time Off From</p>
          <p className={cxStyles.label}>Time Off Until</p>
          <p className={cxStyles.label}>Description</p>

          {customHolidays.map((item, index) => {
            return (
              <Fragment key={item.id}>
                <div className={cxStyles.content}>
                  <div className={cxStyles.datePicker}>
                    <FormFieldSelectMenusDatepicker
                      selected={item.startDate ? getLocalDate(item.startDate) : null}
                      onChange={(date) => {
                        onChangeItem(index, { startDate: date ? formatAsISODate(date) : "" });
                      }}
                      minDate={firstOfYear}
                      maxDate={endOfYear}
                      showMonthDropdown={true}
                      showYearDropdown={false}
                    />
                  </div>
                  <div className="w-56">
                    <TimeInput
                      layout="labelOut"
                      value={item.startTime}
                      onChange={(value) => {
                        onChangeItem(index, { startTime: value });
                      }}
                    />
                  </div>
                </div>
                <div className={cxStyles.content}>
                  <div className={cxStyles.datePicker}>
                    <FormFieldSelectMenusDatepicker
                      selected={item.endDate ? getLocalDate(item.endDate) : null}
                      onChange={(date) => {
                        onChangeItem(index, { endDate: date ? formatAsISODate(date) : "" });
                      }}
                      minDate={firstOfYear}
                      maxDate={endOfYear}
                      showMonthDropdown={true}
                      showYearDropdown={false}
                    />
                  </div>
                  <div className="w-56">
                    <TimeInput
                      layout="labelOut"
                      value={item.endTime}
                      onChange={(value) => {
                        onChangeItem(index, { endTime: value });
                      }}
                    />
                  </div>
                </div>
                <div className={cxStyles.content}>
                  <FormFieldInput
                    value={item.description ?? ""}
                    name="description"
                    inputClassName="h-[34px]"
                    className="w-[496px] mr-5"
                    onChange={(value) => onChangeItem(index, { description: value.target.value })}
                  />
                  <ButtonIcon
                    tooltip={{ content: "Remove", theme: "SMALL" }}
                    theme="primary"
                    SvgIcon={RemoveIcon}
                    onClick={() => {
                      onRemoveItem(index);
                    }}
                  />
                </div>
              </Fragment>
            );
          })}
        </div>
      ) : (
        <p className="text-xs mb-3 col-span-3">No Time Off Scheduled.</p>
      )}
      <Button theme="link" className="flex w-28 mt-4 items-center" onClick={onAddItem}>
        <Icon theme="primary" className="mr-2.5" SvgIcon={AddIcon} />
        <span className="text-xs select-none font-sansSemiBold text-primaryTheme">Add time off</span>
      </Button>
      <div className="mt-6 mb-5">
        <Button theme="secondary" size="large" onClick={onCancel} className="w-[100px]">
          Cancel
        </Button>
        <AsyncButton
          isLoading={isSaving}
          theme="primary"
          size="large"
          type="submit"
          className="w-[100px] ml-3"
          disabled={isFormDisabled}
        >
          Save
        </AsyncButton>
      </div>
    </Form>
  );
};
