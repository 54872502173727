import { BarStackData, BarStackValues } from "components/Dashboard/Charting/types";

export type DatapointTotalOptions = {
  findNegativeTotal: boolean;
};

export const getDatapointTotal = <T extends string>(
  datum: BarStackData<T> | Partial<BarStackValues<T>>,
  options?: DatapointTotalOptions
) => {
  const datapointOptions = options ?? { findNegativeTotal: false, nestedKey: undefined };
  const { findNegativeTotal } = datapointOptions;
  const pointValues: Record<string, number | undefined> = datum;
  const dataKeys = Object.keys(pointValues).filter((key) => key !== "date");

  return dataKeys
    .filter((key) => {
      const pointValue = pointValues[key] ?? 0;

      if (key === "date") {
        return false;
      }

      return findNegativeTotal ? pointValue < 0 : pointValue > 0;
    })
    .map((key) => {
      return pointValues[key] ?? 0;
    })
    .reduce((prev, curr) => prev + curr, 0);
};

export const getStackTotals = <T extends string>(
  data: BarStackData<T>[] | Partial<BarStackValues<T>>[],
  options?: DatapointTotalOptions
) => {
  const datapointOptions = options ?? { findNegativeTotal: false };

  return data.map((val) => getDatapointTotal(val, datapointOptions));
};
