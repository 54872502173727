import { FC } from "react";
import { PayrollVO } from "@libs/api/generated-api";
import { HeaderCell, TableGrid } from "@libs/components/UI/GridTableComponents";
import { PayrollDetailsCompanyPaysRow } from "components/Payroll/PayrollDetailsCompanyPaysRow";

interface Props {
  payroll: PayrollVO;
}

const columns = [
  { width: "1fr", text: "Employee" },
  { width: "1fr", text: "Gross Pay" },
  { width: "1fr", text: "Reimbursements" },
  { width: "1fr", text: "Company Taxes" },
  { width: "1fr", text: "Company Benefits" },
  { width: "min-content", text: "Subtotal" },
  { width: "min-content", text: "Paystub" },
];

export const PayrollDetailsCompanyPaysRoute: FC<Props> = ({ payroll }) => {
  return (
    <TableGrid className="border-t border-t-greyLighter" columnWidths={columns.map(({ width }) => width)}>
      {columns.map(({ text }) => (
        <HeaderCell size="medium" key={text}>
          {text}
        </HeaderCell>
      ))}
      {payroll.lineItems.map((lineItem) => (
        <PayrollDetailsCompanyPaysRow
          key={lineItem.employee.id}
          payrollId={payroll.uuid}
          lineItem={lineItem}
        />
      ))}
    </TableGrid>
  );
};
