import { FC, Dispatch, SetStateAction } from "react";
import { produce } from "immer";

import { PatientCriteriaVO, PatientListCriteria } from "@libs/api/generated-api";
import { CheckboxList } from "@libs/components/UI/CheckboxList";

import { FilterSection } from "components/Communications/Filters/FilterSection";
import { STATUS_OPTIONS } from "components/Communications/Filters/utils";

interface Props extends Pick<PatientCriteriaVO, "status"> {
  onUpdatePatientListCriteria: Dispatch<SetStateAction<PatientListCriteria>>;
}

export const StatusFilterSection: FC<Props> = ({ status, onUpdatePatientListCriteria }) => (
  <FilterSection title="Status" dataTestId="status-filter-section" isOpen>
    <CheckboxList
      layout="vert"
      selectedValues={new Set(status)}
      options={STATUS_OPTIONS}
      onChange={(newSet) =>
        onUpdatePatientListCriteria((last) =>
          produce(last, (draft) => {
            if (newSet.size > 0) {
              if (draft.patientCriteria) {
                draft.patientCriteria.status = [...newSet];
              } else {
                draft.patientCriteria = { status: [...newSet] };
              }
            } else {
              delete draft.patientCriteria?.status;
            }
          })
        )
      }
    />
  </FilterSection>
);
