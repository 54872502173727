import React from "react";
import { ActionModal } from "components/UI/ActionModal";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";

type Props = {
  isEditing: boolean;
  notes: string;
  onCancel: Func;
  onConfirm: (updatedNotes: string) => void;
};

export const LimitationNotesModal: React.FC<Props> = ({ isEditing, notes, onCancel, onConfirm }) => {
  const [currentNotes, setCurrentNotes] = React.useState<string>(notes);

  return (
    <ActionModal
      displaySecondaryButton={isEditing}
      handleClose={onCancel}
      handleSave={() => onConfirm(currentNotes.trim())}
      primaryButtonText="Done"
      size="md"
      title={`${isEditing ? "Edit" : "View"} Limitation Notes`}
    >
      <FormFieldTextarea
        className="text-left"
        edit={isEditing}
        layout="labelOut"
        onChange={(e) => {
          setCurrentNotes(e.target.value);
        }}
        placeholder="Enter notes here..."
        rows={10}
        value={currentNotes}
      />
    </ActionModal>
  );
};
