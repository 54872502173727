import { useState, useMemo, useCallback } from "react";
import { PhoneExtensionVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Icon } from "@libs/components/UI/Icon";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";
import { Switch } from "@libs/components/UI/Switch";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { MangoConfig } from "storage/mango";
import { EXTENSION_SELECTION_DIRECTIONS } from "components/Settings/PracticeSetup/Sections/Phones/Extensions";

interface Props {
  allowInbound: boolean;
  extensions: PhoneExtensionVO[];
  isSettingsPage?: boolean;
  onClose: Func;
  onSave: (config: MangoConfig) => void;
  selectedExt: string | undefined;
}

export const ExtensionsModal: React.FC<Props> = ({
  allowInbound,
  extensions,
  isSettingsPage = false,
  onClose,
  onSave,
  selectedExt,
}) => {
  const [extension, setExtension] = useState(selectedExt);
  const inbound = useBoolean(allowInbound);

  const handleSelect = useCallback(
    (ext: string) => {
      setExtension(ext);
      inbound.on();
    },
    [inbound]
  );

  const extensionOptions = useMemo(() => {
    const options = extensions.map((phoneExt) => {
      return { label: phoneExt.extension, value: phoneExt.extension };
    });

    return [{ label: "None", value: "None" }, ...options];
  }, [extensions]);

  const handleSaveExtension = useCallback(() => {
    if (extension) {
      onSave({ allowInbound: extension === "None" ? false : inbound.isOn, ext: extension });
    }

    onClose();
  }, [inbound.isOn, extension, onClose, onSave]);

  return (
    <Modal onClose={onClose} size="2xs" title="Phone Settings">
      <ModalContent className="flex flex-col gap-y-4" padding="lg">
        {!isSettingsPage && (
          <div className="flex items-start justify-between">
            <div className="text-sm">{EXTENSION_SELECTION_DIRECTIONS}</div>
            <Icon
              SvgIcon={InfoIcon}
              size="sm"
              tooltip={{
                content:
                  "These settings only apply to this computer. Please visit this page from other computers to configure them as well.",
              }}
            />
          </div>
        )}
        <FormFieldSelect
          className="w-32"
          isClearable={false}
          label="Select Extension"
          layout="labelOut"
          onItemSelected={handleSelect}
          options={extensionOptions}
          value={extension}
        />
        <Switch checked={inbound.isOn} labelPosition="right" onChange={inbound.toggle} size="lg">
          Allow inbound popups on this computer
        </Switch>
      </ModalContent>
      <ModalFooter>
        <Button className="min-w-button" onClick={onClose} theme="secondary">
          Cancel
        </Button>
        <Button className="min-w-button" onClick={handleSaveExtension}>
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
