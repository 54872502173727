import React, { useCallback } from "react";
import { sub } from "date-fns";
import { useReactToPrint } from "react-to-print";
import { formatDateWindow } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useAccount } from "@libs/contexts/AccountContext";
import { useQueryParams } from "hooks/useQueryParams";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { DashboardTableTabs } from "components/Dashboard/Tables/DashboardTableTabs";
import { useTableTabProps } from "components/Dashboard/PaymentCollected/hooks/useTableTabProps";
import { ByPaymentMethodTable } from "components/Dashboard/PaymentCollected/ByPaymentMethodTable";
import { getCollectedPaymentQuery } from "api/reporting/queries";
import { MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";
import { useDownloadQueryPages } from "components/Dashboard/hooks/useDownloadInfiniteQuery";
import { useDownloadPaymentCollectedByPaymentTypeCSV } from "components/Dashboard/PaymentCollected/hooks/useDownloadPaymentCollectedByPaymentTypeCSV";
import { handleError } from "utils/handleError";

export const ByPaymentMethodTableRoute: React.FC = () => {
  const { query } = useQueryParams("dashboardPaymentCollected");
  const dateWindow = useTimeSeriesPageSelections(query);
  const { selectedTimeSegmentParams, selectedTimeSegment } = dateWindow;
  const { practiceId } = useAccount();
  const collectedPaymentQuery = useInfiniteApiQuery(
    getCollectedPaymentQuery({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: MAX_PAGE_SIZE,
        data: {
          includeTotal: true,
          ...selectedTimeSegmentParams,
        },
      },
    })
  );
  const { startDownload } = useDownloadQueryPages(collectedPaymentQuery);

  const printRef = React.useRef<HTMLDivElement>(null);
  const printing = useBoolean(false);
  const printingSet = printing.set;
  const handlePrintTable = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Payments ${formatDateWindow(
      selectedTimeSegment.startDate,
      sub(selectedTimeSegment.endDate, { days: 1 }),
      "M-d-yyyy"
    )}`,
    onAfterPrint: printing.off,
    pageStyle: "@page { size: letter portrait; margin: 1.5rem; }",
  });
  const handlePrintClick = useCallback(async () => {
    try {
      printingSet(true);
      await startDownload();
      handlePrintTable();
    } catch (e) {
      handleError(e);
    } finally {
      printingSet(false);
    }
  }, [handlePrintTable, printingSet, startDownload]);
  const { downloadCSV, isDownloading: isDownloadingCSV } = useDownloadPaymentCollectedByPaymentTypeCSV({
    startDownload,
    selectedTimeSegment,
  });
  const tableTabProps = useTableTabProps();

  return (
    <div className="h-full flex-1 flex flex-col min-h-0">
      <DashboardTableTabs
        id="payment-collected-tabs-by-method"
        {...tableTabProps}
        includePatientSearch={false}
        isPrinting={printing.isOn}
        onClickPrint={handlePrintClick}
        onClickDownload={downloadCSV}
        isDownloading={isDownloadingCSV}
      />

      <ByPaymentMethodTable
        selectedTimeSegment={selectedTimeSegment}
        collectedPaymentQuery={collectedPaymentQuery}
      />
      {
        // Rendered off screen when printing
      }
      {printing.isOn && (
        <ByPaymentMethodTable
          selectedTimeSegment={selectedTimeSegment}
          collectedPaymentQuery={collectedPaymentQuery}
          ref={printRef}
        />
      )}
    </div>
  );
};
