/* eslint-disable @typescript-eslint/naming-convention */
import {
  PearlAnnotationsInner,
  PearlAnnotationsInnerContourBox,
  PearlAnnotationsInnerLineSegment,
  PearlAnnotationsInnerPolygonInner,
  PearlAnnotationsInnerRelationshipsInner,
  PearlImageSummaryVO,
} from "@libs/api/generated-api";
import {
  DEGREES_180,
  DEGREES_270,
  DEGREES_90,
  DecimalPlaces,
  Size,
  SquareDegree,
  getPositiveDegrees,
  half,
  round,
} from "@libs/utils/math";

export type PearlCategory = Exclude<PearlImageSummaryVO["category"], undefined>;
export type AnnotationPropValue = Exclude<PearlAnnotationsInnerRelationshipsInner["prop_value"], undefined>;

export const cxCategoryToColor: Record<PearlCategory, string | undefined> = {
  caries: "bg-magentaDark",
  calculus: "bg-green",
  "periapical.radiolucency": "bg-blue",
  "margin.discrepancy": "bg-orange",
  bridge: undefined,
  crown: undefined,
  filling: undefined,
  implant: undefined,
  "root.canal": undefined,
  anatomy: undefined,
};

type Annotation = {
  contour_box?: PearlAnnotationsInnerContourBox;
  polygon?: PearlAnnotationsInnerPolygonInner[];
  line_segment?: PearlAnnotationsInnerLineSegment;
};

type TransformParams = {
  imageSize: Size;
  aspectInverted: boolean;
  rotationDegrees: SquareDegree;
  center: { x: number; y: number };
  mirror?: "X" | "Y";
};

const offsetForAspect = ({
  imageSize,
  degrees,
  x,
  y,
  boxWidth = 0,
  boxHeight = 0,
}: {
  imageSize: { width: number; height: number };
  degrees: number;
  x: number;
  y: number;
  boxWidth?: number;
  boxHeight?: number;
}) => {
  if (degrees === 0) {
    return { x, y };
  }

  const centerX = half(imageSize.width);
  const centerY = half(imageSize.height);
  const aspectOffset = Math.floor(half(imageSize.width - imageSize.height));

  let finalX = Math.round(x + centerX);
  let finalY = Math.round(y + centerY);

  switch (degrees) {
    case DEGREES_90: {
      finalX -= aspectOffset + boxWidth;
      finalY += aspectOffset;

      break;
    }
    case DEGREES_270: {
      finalX -= aspectOffset;
      finalY += aspectOffset - boxHeight;

      break;
    }
    case DEGREES_180: {
      finalX -= boxWidth;
      finalY -= boxHeight;

      break;
    }
    // No default
  }

  return {
    x: finalX,
    y: finalY,
  };
};

const mirrorItem = (params: {
  pointOrBox: { x: number; y: number; width?: number; height?: number };
  mirror?: "X" | "Y";
  aspectInverted: boolean;
  imageSize: Size;
}): { x: number; y: number } => {
  const { mirror, aspectInverted, imageSize, pointOrBox } = params;

  if (!mirror) {
    return pointOrBox;
  }

  if (mirror === "X") {
    return {
      ...pointOrBox,
      x: (aspectInverted ? imageSize.height : imageSize.width) - pointOrBox.x - (pointOrBox.width ?? 0),
    };
  }

  return {
    ...pointOrBox,
    y: (aspectInverted ? imageSize.width : imageSize.height) - pointOrBox.y - (pointOrBox.height ?? 0),
  };
};

// Helper function to rotate a point around the center
const rotatePoint = (
  point: { x: number; y: number },
  angleDegrees: number,
  center: { x: number; y: number }
): { x: number; y: number } => {
  if (angleDegrees === 0 || !point.x || !point.y) {
    return point;
  }

  const angleRadians = (angleDegrees * Math.PI) / DEGREES_180;
  const centeredX = point.x - center.x;
  const centeredY = point.y - center.y;
  const newX = Math.cos(angleRadians) * centeredX - Math.sin(angleRadians) * centeredY;
  const newY = Math.sin(angleRadians) * centeredX + Math.cos(angleRadians) * centeredY;

  return {
    x: newX,
    y: newY,
  };
};

const transformContourBox = ({
  imageSize,
  contourBox,
  rotationDegrees,
  aspectInverted,
  center,
  mirror,
}: {
  contourBox?: PearlAnnotationsInnerContourBox;
} & TransformParams): PearlAnnotationsInnerContourBox | undefined => {
  if (!contourBox?.x || !contourBox.y) {
    return contourBox;
  }

  const { width: boxWidth, height: boxHeight, x: boxX, y: boxY } = contourBox;

  // Adjust coordinates relative to the center of the image

  // Rotate the adjusted coordinates
  const rotatedPoint = rotatePoint({ x: boxX, y: boxY }, rotationDegrees, center);

  // Translate the rotated coordinates back to their original position
  const { x: finalX, y: finalY } = offsetForAspect({
    imageSize,
    degrees: rotationDegrees,
    ...rotatedPoint,
    boxWidth,
    boxHeight,
  });

  const unmirroredContourBox = {
    x: rotationDegrees === DEGREES_90 ? finalX + (boxWidth ?? 0) - (boxHeight ?? 0) : finalX,
    y: finalY,
    width: aspectInverted ? boxHeight : boxWidth,
    height: aspectInverted ? boxWidth : boxHeight,
  };

  return mirrorItem({ pointOrBox: unmirroredContourBox, mirror, aspectInverted, imageSize });
};

const transformPolygon = ({
  polygon,
  imageSize,
  rotationDegrees,
  center,
  mirror,
  aspectInverted,
}: {
  polygon?: PearlAnnotationsInnerPolygonInner[];
} & TransformParams): PearlAnnotationsInnerPolygonInner[] | undefined => {
  if (polygon) {
    return polygon.map((point) => {
      // Calculate new coordinates based on rotated image dimensions
      const { x, y } = point;

      if (!x || !y) {
        return point;
      }

      // Calculate new coordinates based on rotated image dimensions
      const rotatedPoint = rotatePoint(
        { x: x * imageSize.width, y: y * imageSize.height },
        rotationDegrees,
        center
      );

      const unmirrored = offsetForAspect({
        imageSize,
        degrees: rotationDegrees,
        ...rotatedPoint,
      });
      const { x: finalX, y: finalY } = mirrorItem({
        pointOrBox: unmirrored,
        mirror,
        aspectInverted,
        imageSize,
      });

      return {
        x: finalX / (rotationDegrees % DEGREES_180 === 0 ? imageSize.width : imageSize.height),
        y: finalY / (rotationDegrees % DEGREES_180 === 0 ? imageSize.height : imageSize.width),
      };
    });
  }

  return polygon;
};

const transformLine = ({
  lineSegment,
  imageSize,
  rotationDegrees,
  center,
  mirror,
  aspectInverted,
}: {
  lineSegment?: PearlAnnotationsInnerLineSegment;
} & TransformParams): PearlAnnotationsInnerLineSegment | undefined => {
  if (lineSegment) {
    const { x1, y1, x2, y2, size } = lineSegment;

    if (x1 && y1 && x2 && y2) {
      const startPoint = rotatePoint({ x: x1, y: y1 }, rotationDegrees, center);
      const endPoint = rotatePoint({ x: x2, y: y2 }, rotationDegrees, center);
      const unmirroredStart = offsetForAspect({
        imageSize,
        degrees: rotationDegrees,
        ...startPoint,
      });
      const unmirroredEnd = offsetForAspect({
        imageSize,
        degrees: rotationDegrees,
        ...endPoint,
      });
      const { x: finalXStart, y: finalYStart } = mirrorItem({
        pointOrBox: unmirroredStart,
        aspectInverted,
        imageSize,
        mirror,
      });
      const { x: finalXEnd, y: finalYEnd } = mirrorItem({
        pointOrBox: unmirroredEnd,
        aspectInverted,
        imageSize,
        mirror,
      });

      return {
        x1: finalXStart,
        y1: finalYStart,
        x2: finalXEnd,
        y2: finalYEnd,
        size,
      };
    }
  }

  return lineSegment;
};

export const transformAnnotations = <T extends Annotation>(
  annotations: T[],
  originalImageSize: Size,
  rotationDegrees: SquareDegree,
  mirror?: "X" | "Y"
): T[] => {
  if (rotationDegrees === 0 && !mirror) {
    return annotations;
  }

  const positiveDegrees = getPositiveDegrees(rotationDegrees);
  const { width: imageWidth, height: imageHeight } = originalImageSize;
  const center = { x: half(imageWidth), y: half(imageHeight) };
  const inverted = rotationDegrees % DEGREES_180 !== 0;
  // Mirror gets flipped when rotation is 90 or 270 degrees
  const effectiveMirror = mirror ? (inverted ? (mirror === "X" ? "Y" : "X") : mirror) : undefined;
  const sharedParams = {
    imageSize: originalImageSize,
    aspectInverted: inverted,
    rotationDegrees: positiveDegrees,
    mirror: effectiveMirror,
    center,
  };
  // Transform each annotation
  const transformedAnnotations = annotations.map((annotation) => {
    const { contour_box, polygon, line_segment } = annotation;
    const transformedContourBox = transformContourBox({
      ...sharedParams,
      contourBox: contour_box,
    });

    const transformedPolygon = transformPolygon({
      ...sharedParams,
      polygon,
    });

    const transformedLine = transformLine({
      ...sharedParams,
      lineSegment: line_segment,
    });

    return {
      ...annotation,
      polygon: transformedPolygon,
      contour_box: transformedContourBox,
      line_segment: transformedLine,
    };
  });

  return transformedAnnotations;
};

const getOrigPxSize = (line: PearlAnnotationsInnerLineSegment) => {
  const { size, x1, x2, y1, y2 } = line;

  if (!x1 || !x2 || !y1 || !y2 || !size) {
    return 0;
  }

  const h = x2 - x1;
  const w = y2 - y1;

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  return (size / Math.hypot(h, w)) * 100;
};

export const getScaledLineSize = (annotation: PearlAnnotationsInner, pixelsPerMM?: number) => {
  const { size } = annotation.line_segment ?? {};

  if (!annotation.line_segment || !size || pixelsPerMM) {
    return annotation.text?.text;
  }

  const originalPixelSpacing = getOrigPxSize(annotation.line_segment);
  const pixelSpacing = 1;
  const scaledSize = `${round((size / originalPixelSpacing) * pixelSpacing, DecimalPlaces.hundredth)}mm`;

  return scaledSize;
};
