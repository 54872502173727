import { FC, useRef, useState } from "react";
import { CustomHolidayVO, ProviderVO, WorkingHourItemVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getQueryKey } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { updateProviderColor } from "api/practice/mutations";
import { handleError } from "utils/handleError";
import { getPracticeCustomHolidaysQuery, getWorkingHoursQuery } from "api/scheduling/queries";
import { upsertProviderCustomHolidays, upsertProviderWorkingHours } from "api/scheduling/mutations";
import { SelectProvider } from "components/Settings/Scheduling/Sections/ProviderScheduling/SelectProvider";
import { WorkingHours } from "components/Settings/Scheduling/Sections/ProviderScheduling/WorkingHours";
import { TimeOff } from "components/Settings/Scheduling/Sections/ProviderScheduling/TimeOff";
import { updateProviderScheduling } from "api/settings/practice/mutations";
import { queryClient } from "utils/queryClient";

export const useProviderSchedulingRoute = (providers: ProviderVO[] | undefined) => {
  const editWorkingHoursSection = useBoolean(false);
  const editCustomHoursSection = useBoolean(false);
  const hasInitializedSelectedProvider = useRef(false);
  const [selectedProviderId, setSelectedProviderId] = useState(() => {
    const id = providers?.[0]?.id ?? 0;

    hasInitializedSelectedProvider.current = Boolean(id);

    return id;
  });
  const handleUpdateProvider = (id: number) => {
    editCustomHoursSection.off();
    editWorkingHoursSection.off();

    setSelectedProviderId(id);
  };

  const handleProvidersLoaded = (newProviders: ProviderVO[]) => {
    const id = newProviders[0]?.id;

    if (id && !hasInitializedSelectedProvider.current) {
      hasInitializedSelectedProvider.current = true;
      setSelectedProviderId(id);
    }
  };

  return {
    editCustomHoursSection,
    editWorkingHoursSection,
    handleUpdateProvider,
    selectedProviderId,
    handleProvidersLoaded,
  };
};

export const ProviderSchedulingRoute: FC = () => {
  const practice = useCurrentPractice();

  const [providersQuery] = useApiQueries([
    getPracticeProvidersQuery({
      args: { practiceId: practice.id, statuses: ["ACTIVE", "PENDING"] },
      queryOptions: {
        onSuccess: (response) => {
          handleProvidersLoaded(response.data.data);
        },
      },
    }),
  ]);

  const {
    editCustomHoursSection,
    editWorkingHoursSection,
    handleUpdateProvider,
    selectedProviderId,
    handleProvidersLoaded,
  } = useProviderSchedulingRoute(providersQuery.data);

  const [practiceHoursQuery, providerHoursQuery, customHolidaysQuery] = useApiQueries([
    getWorkingHoursQuery({ args: { practiceId: practice.id } }),
    getWorkingHoursQuery({
      args: { practiceId: practice.id, providerId: selectedProviderId },
      queryOptions: { enabled: Boolean(selectedProviderId) },
    }),
    getPracticeCustomHolidaysQuery({
      args: { providerId: selectedProviderId, practiceId: practice.id },
      queryOptions: { enabled: Boolean(selectedProviderId) },
    }),
  ]);

  const [
    updateProviderColorMutation,
    updateProviderWorkingHoursMutation,
    upsertProviderCustomHolidaysMutation,
    updateProviderSchedulingMutation,
  ] = useApiMutations([
    updateProviderColor,
    upsertProviderWorkingHours,
    upsertProviderCustomHolidays,
    updateProviderScheduling,
  ]);

  const handleUpdateProviderColor = (providerId: number, newColor: string) => {
    updateProviderColorMutation.mutate(
      {
        practiceId: practice.id,
        data: {
          providerId,
          color: newColor,
        },
      },
      {
        onError: handleError,
      }
    );
  };

  const handleUpdateWorkingHours = (hours: WorkingHourItemVO[]) => {
    updateProviderWorkingHoursMutation.mutate(
      { practiceId: practice.id, employeeId: selectedProviderId, data: hours },
      {
        onSuccess: () => {
          editWorkingHoursSection.off();
          queryClient.invalidateQueries([
            getQueryKey("public", "getSelfBookableProviders"),
            { practiceUuid: practice.uuid },
          ]);
        },
        onError: handleError,
      }
    );
  };

  const handleUpdateCustomHolidays = (holidays: CustomHolidayVO[]) => {
    upsertProviderCustomHolidaysMutation.mutate(
      { practiceId: practice.id, data: holidays, employeeId: selectedProviderId },
      {
        onSuccess: editCustomHoursSection.off,
        onError: handleError,
      }
    );
  };

  const handleUpdateBookingLimit = (providerId: number, bookingLimit?: number) => {
    updateProviderSchedulingMutation.mutate(
      { practiceId: practice.id, data: { providerId, bookingLimitInDays: bookingLimit } },
      {
        onError: handleError,
      }
    );
  };

  return (
    <SettingsPanel
      title="Provider Scheduling"
      description="Set up working hours and planned time off for each provider at your practice"
    >
      <div className="min-w-[500px] w-fit">
        <div className="min-h-11 mb-6">
          <QueryResult queries={[providersQuery]}>
            {providersQuery.data && (
              <SelectProvider
                onUpdateProvider={handleUpdateProvider}
                onUpdateProviderColor={handleUpdateProviderColor}
                onBookingLimitChanged={handleUpdateBookingLimit}
                providers={providersQuery.data}
                isSavingColor={updateProviderColorMutation.isLoading}
                selectedProviderId={selectedProviderId}
              />
            )}
          </QueryResult>
        </div>
        <div className="min-h-60 mb-4">
          <QueryResult queries={[providersQuery, practiceHoursQuery, providerHoursQuery]}>
            {practiceHoursQuery.data && providerHoursQuery.data && (
              <WorkingHours
                key={selectedProviderId}
                providerId={selectedProviderId}
                providerWorkingHours={providerHoursQuery.data}
                practiceWorkingHours={practiceHoursQuery.data}
                isSaving={updateProviderWorkingHoursMutation.isLoading}
                isEditing={editWorkingHoursSection.isOn}
                onSave={handleUpdateWorkingHours}
                onRequestEdit={editWorkingHoursSection.on}
                onCloseEdit={editWorkingHoursSection.off}
              />
            )}
          </QueryResult>
        </div>
        <div className="min-h-24">
          <QueryResult queries={[providersQuery, customHolidaysQuery]}>
            {customHolidaysQuery.data && (
              <TimeOff
                key={selectedProviderId}
                customHolidays={customHolidaysQuery.data}
                isEditing={editCustomHoursSection.isOn}
                isSaving={upsertProviderCustomHolidaysMutation.isLoading}
                onRequestEdit={editCustomHoursSection.on}
                onCancelEdit={editCustomHoursSection.off}
                onSave={handleUpdateCustomHolidays}
              />
            )}
          </QueryResult>
        </div>
      </div>
    </SettingsPanel>
  );
};
