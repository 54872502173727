import React from "react";
import { ProviderVO } from "@libs/api/generated-api";
import { createSelectStyles } from "@libs/components/UI/selectStyles";
import { DASHBOARD_SELECT_STYLES } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";
import { useProviderFilterOptions } from "components/Dashboard/hooks/useProviderFilterOptions";
import { getFilterSelectedValues, upsertDashFilter } from "components/Dashboard/utils/filters";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";

export const DashboardSelectProviderFilter: React.FC<{
  filters: DashboardFilter[];
  includeNoProviderOption?: boolean;
  providerFilterType: "associatedProviderId" | "providerId";
  providers?: ProviderVO[];
  title?: string;
  onChangeFilters: (params: { filters: DashboardFilter[] }) => void;
}> = ({
  filters,
  onChangeFilters,
  includeNoProviderOption = false,
  providers,
  providerFilterType,
  title = "Provider",
}) => {
  const providerFilter = filters.find(({ type: currType }) => currType === providerFilterType);
  const styles = React.useMemo(() => {
    return createSelectStyles<string, SelectOption<string>>({
      option: (provided, optionProps) => {
        const baseProps = {
          ...provided,
          cursor: "pointer",
        };
        const selectedValues = getFilterSelectedValues({
          type: providerFilterType,
          filters,
        });

        if (!providerFilter) {
          return baseProps;
        }

        return {
          ...baseProps,
          fontWeight:
            selectedValues.has(optionProps.data.value) ||
            (optionProps.data.value === "-1" && selectedValues.has(null))
              ? "bold"
              : undefined,
        };
      },
      ...DASHBOARD_SELECT_STYLES,
    });
  }, [filters, providerFilter, providerFilterType]);
  const providerFilterOptions = useProviderFilterOptions(providers, {
    includeNoProviderOption,
  });

  return (
    <FormFieldSelect
      placeholder={title}
      aria-label={title}
      className="min-w-40"
      isSearchable={false}
      closeMenuOnSelect={false}
      styles={styles}
      onChange={(option) => {
        onChangeFilters({
          filters: upsertDashFilter(
            filters,
            {
              type: providerFilterType,
              values: option?.value === "-1" ? [null] : option ? [option.value] : [],
            } as DashboardFilter,
            {
              mergeWithExisting: true,
              toggle: true,
            }
          ),
        });
      }}
      options={providerFilterOptions}
    />
  );
};
