import { FC, FormEventHandler, useState } from "react";
import { CreatePaymentDeviceRequest, PaymentDeviceVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useAccount } from "@libs/contexts/AccountContext";
import { Form } from "@libs/components/UI/Form";
import { Modal } from "@libs/components/UI/Modal";
import { ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import { createPaymentDevice } from "api/settings/payments/mutations";
import { handleError } from "utils/handleError";
import { FormFieldSelect } from "components/UI/FormFieldSelect";

interface Props {
  onSave: (newPosDevice: PaymentDeviceVO) => void;
  onRequestClose: Func;
}

interface ModelOption {
  label: string;
  value: PaymentDeviceVO["model"];
}

const modelOptions: ModelOption[] = [
  { label: "Link 2500", value: "LINK_2500" },
  { label: "Lane 3000", value: "LANE_3000" },
  { label: "Lane 5000", value: "LANE_5000" },
  { label: "Lane 7000", value: "LANE_7000" },
  { label: "Lane 8000", value: "LANE_8000" },
  { label: "Pax A920Pro", value: "PAX_A920PRO" },
  { label: "Sunmi P2", value: "SUNMI_P2" },
];

const FORM_ID = "new-pos-form";
const POS_DEVICE_SCHEMA = {
  name: { $validations: [{ $v: required, $error: "Name is required" }] },
  model: { $validations: [{ $v: required, $error: "Model is required" }] },
  serialNumber: { $validations: [{ $v: required, $error: "Serial Number is required" }] },
};

export const PosDeviceModal: FC<Props> = ({ onSave, onRequestClose }) => {
  const { practiceId } = useAccount();
  const [draftPos, setDraftPos] = useState<CreatePaymentDeviceRequest>({
    model: "LINK_2500",
    name: "",
    serialNumber: "",
  });

  const validation = useValidation(draftPos, POS_DEVICE_SCHEMA);
  const [{ mutate: createPaymentDeviceMutate, isLoading: isCreatingPos }] = useApiMutations([
    createPaymentDevice,
  ]);

  const handleCreatePosDevice: FormEventHandler = (e) => {
    e.preventDefault();

    const result = validation.validate();

    if (!result.$isValid) {
      return;
    }

    createPaymentDeviceMutate(
      { practiceId, data: draftPos },
      {
        onSuccess: (data) => {
          onSave(data.data.data);
          onRequestClose();
        },
        onError: handleError,
      }
    );
  };

  return (
    <Modal size="xs" title="Add POS Device" onClose={onRequestClose}>
      <ModalContent padding="sm">
        <Form id={FORM_ID} onSubmit={handleCreatePosDevice}>
          <div className="grid grid-cols-2 gap-5">
            <FormFieldInput
              layout="labelOut"
              label="Name"
              required
              placeholder="E.g. Front Desk #1"
              value={draftPos.name}
              onChange={(e) => setDraftPos({ ...draftPos, name: e.target.value })}
              error={validation.result.name.$error}
            />
            <FormFieldSelect
              layout="labelOut"
              label="Model"
              required
              className="col-start-1"
              options={modelOptions}
              value={draftPos.model}
              onChange={(model) => model && setDraftPos({ ...draftPos, model: model.value })}
            />
            <FormFieldInput
              layout="labelOut"
              label="Serial Number"
              required
              value={draftPos.serialNumber}
              onChange={(e) => setDraftPos({ ...draftPos, serialNumber: e.target.value })}
              error={validation.result.serialNumber.$error}
            />
          </div>
        </Form>
      </ModalContent>
      <ModalFooter>
        <Button theme="secondary" className="w-28" onClick={onRequestClose}>
          Cancel
        </Button>
        <AsyncButton
          form={FORM_ID}
          type="submit"
          className="w-28"
          isLoading={isCreatingPos}
          disabled={!(validation.result.$isValid ?? true)}
        >
          Save & Next
        </AsyncButton>
      </ModalFooter>
    </Modal>
  );
};
