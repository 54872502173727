import { FC, useMemo, useCallback } from "react";

import { PatientListCriteria, InsuranceCarrierVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Spinner } from "@libs/components/UI/Spinner";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as FiltersIcon } from "@libs/assets/icons/adjust.svg";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";

import { convertPatientsUrlQueryParamsToPatientListCriteria } from "components/Communications/Campaigns/Campaign/utils";
import { getPatientListCriteriaFilterProps } from "components/Communications/Filters/utils";

import { PatientsQuery } from "utils/routing/patient";

interface Props {
  patientListCriteria: PatientListCriteria | undefined;
  insuranceCarriers: InsuranceCarrierVO[];
  onOpenFilters: Func;
  onRemoveFilter: (criteria: PatientListCriteria, options?: { onSuccess?: Func }) => void;
  disabled?: boolean;
}

export const CampaignFilters: FC<Props> = ({
  patientListCriteria,
  insuranceCarriers,
  onOpenFilters,
  onRemoveFilter,
  disabled = false,
}) => {
  const patientListCriteriaFilterProps = useMemo(
    () => patientListCriteria && getPatientListCriteriaFilterProps(patientListCriteria, insuranceCarriers),
    [patientListCriteria, insuranceCarriers]
  );

  const removing = useBoolean(false);

  const handleRemoveParams = useCallback(
    (removed: Partial<PatientsQuery>) => {
      if (!patientListCriteriaFilterProps?.params) {
        return;
      }

      removing.on();

      const criteria = convertPatientsUrlQueryParamsToPatientListCriteria({
        ...patientListCriteriaFilterProps.params,
        ...removed,
      });

      onRemoveFilter(criteria, { onSuccess: removing.off });
    },
    [patientListCriteriaFilterProps?.params, onRemoveFilter, removing]
  );

  return (
    <div
      className={`
        flex
        items-center
        justify-between
        gap-x-2
        rounded
        border
        border-slate-200
        pl-1
        pr-3
        py-1
      `}
    >
      <div className="flex-1">
        {patientListCriteriaFilterProps ? (
          <QueryFilterPills
            {...patientListCriteriaFilterProps}
            dataTestId="campaign-filters"
            onUpdateParams={handleRemoveParams}
            disabled={disabled}
            size="sm"
            version="v2"
          />
        ) : null}
      </div>

      <div className="flex items-center justify-center h-6">
        {removing.isOn ? (
          <Spinner variant="greyLighter" animation="border" size="sm" />
        ) : (
          <ButtonIcon
            SvgIcon={FiltersIcon}
            tooltip={{ content: "Campaign Filters", theme: "SMALL" }}
            onClick={onOpenFilters}
            disabled={disabled}
          />
        )}
      </div>
    </div>
  );
};
