import { UseCameraCaptureState } from "components/ImageCapturing/useCameraCapture";
import { TwainSources } from "components/ImageCapturing/useTwain";
import { MediaPermissionsStatus } from "utils/useMediaPermission";

export const getIsLoadingDevices = (
  twainReady: boolean,
  cameraCapture: UseCameraCaptureState,
  twainSources?: TwainSources
) => {
  const isLoadingSensors = !twainSources || twainSources.isRequesting || !twainReady;

  const isLoadingCameras =
    cameraCapture.permissionState.status === MediaPermissionsStatus.granted
      ? Boolean(cameraCapture.cameraSources?.isRequestingSources)
      : cameraCapture.permissionState.status === MediaPermissionsStatus.requesting;

  return isLoadingCameras || isLoadingSensors;
};

export const getIsLoadingDevicesV2 = (
  isLoadingArchyService: boolean,
  cameraCapture: UseCameraCaptureState
) => {
  const isLoadingCameras =
    cameraCapture.permissionState.status === MediaPermissionsStatus.granted
      ? Boolean(cameraCapture.cameraSources?.isRequestingSources)
      : cameraCapture.permissionState.status === MediaPermissionsStatus.requesting;

  return isLoadingCameras || isLoadingArchyService;
};
