import { PatientProcedureVO } from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { toFilterComponentsProps } from "@libs/components/UI/queryFilterPillsUtils";

export const getPatientProceduresFilterProps = (params: {
  patientProcedureStatuses: PatientProcedureVO["status"][];
}) => {
  return toFilterComponentsProps(params, [
    {
      type: "list",
      prop: "patientProcedureStatuses",
      format: (val) => (val === "DONE" ? "Completed" : sentenceCaseConstant(val)),
    },
  ]);
};
