import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { ColumnSortIndicator, HeaderButtonCell } from "@libs/components/UI/GridTableComponents";
import { cxTableStyles } from "components/Dashboard/Tables/cxTableStyles";
import { HeaderData } from "components/Dashboard/Tables/types";

import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";

export type HeaderProps = HeaderData & {
  isPrinting?: boolean;
  onClick?: (sortField: ColumnSortOrder["field"]) => void;
  tableSortDirection?: ColumnSortOrder["direction"];
  primarySortField?: string;
  totalRows?: number;
};

export const HeaderCell: React.FC<HeaderProps> = ({
  label,
  align = "left",
  isPrinting = false,
  onClick,
  sortField,
  tableSortDirection = "DESCENDING",
  tooltip,
  totalRows,
}) => {
  const className = cxTableStyles.header(align, isPrinting);
  const tipContent = tooltip ? (
    <FloatingTooltip theme="MEDIUM" {...tooltip}>
      <InfoIcon className="h-4 w-4 text-greyLight" />
    </FloatingTooltip>
  ) : null;

  return sortField && totalRows !== 0 ? (
    <HeaderButtonCell
      className={className}
      onClick={() => {
        if (sortField && onClick && totalRows !== 0) {
          onClick(sortField);
        }
      }}
    >
      {label}
      {tipContent}
      <ColumnSortIndicator className="ml-2" direction={tableSortDirection} />
    </HeaderButtonCell>
  ) : (
    <div className={className}>
      {label}
      {tipContent}

      {sortField && <ColumnSortIndicator className="ml-2" />}
    </div>
  );
};
