import { FC } from "react";

import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ReactComponent as DownChevronIcon } from "@libs/assets/icons/down-chevron.svg";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";

export const ApplyMenuButton: FC<{
  className?: string;
  isApplyingFilters: boolean;
  onApplyFilters: Func;
  onRequestSaveFilters: Func;
}> = ({ className, isApplyingFilters, onApplyFilters, onRequestSaveFilters }) => {
  const menu = useBoolean(false);

  return (
    <div className={cx("flex divide-x divide-archyBlue-900 min-w-button", className)}>
      <AsyncButton
        aria-label="Apply Filters"
        className="flex-1 rounded-r-none"
        isLoading={isApplyingFilters}
        onClick={onApplyFilters}
      >
        Apply
      </AsyncButton>
      <ButtonMenu
        menuContent={
          <div className="w-32">
            <MenuOptions
              options={createMenuOptions({ label: "Save Filters", value: "save-filters" })}
              onOptionClick={() => {
                onRequestSaveFilters();
                menu.off();
              }}
            />
          </div>
        }
        onRequestOpen={menu.on}
        onRequestClose={menu.off}
        isOpen={menu.isOn}
        placement="top-end"
      >
        {(props) => (
          <Button {...props} aria-label="Apply Filters Menu" className="rounded-l-none p-2" size="custom">
            <DownChevronIcon className="w-5 h-5 text-white" />
          </Button>
        )}
      </ButtonMenu>
    </div>
  );
};
