import { createContext, FC, PropsWithChildren, useContext, useEffect, useMemo, useState } from "react";
import { ProviderVO } from "@libs/api/generated-api";
import { noop } from "@libs/utils/noop";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { getPracticeProvidersQuery } from "api/practice/queries";

type ActiveProviderValue = {
  providerId?: number;
  providers?: ProviderVO[];
  changeProvider: (id: number | undefined) => void;
};

const Context = createContext<ActiveProviderValue>({
  changeProvider: noop,
});

Context.displayName = "FocusManagerContext";

export const ActiveProviderProvider: FC<PropsWithChildren> = ({ children }) => {
  const [providerId, setProviderId] = useState<number | undefined>();

  const { id: userId, practiceId } = useAccount();

  const [providersQuery] = useApiQueries([getPracticeProvidersQuery({ args: { practiceId } })]);

  const providerUserId = useMemo(() => {
    if (!providersQuery.data) {
      return undefined;
    }

    if (providersQuery.data.length === 1) {
      return providersQuery.data[0].id;
    }

    return providersQuery.data.find((p) => p.id === userId)?.id;
  }, [providersQuery.data, userId]);

  useEffect(() => {
    if (providerUserId) {
      setProviderId(providerUserId);
    }
  }, [providerUserId]);

  const value = useMemo<ActiveProviderValue>(
    () => ({
      providerId,
      providers: providersQuery.data,
      changeProvider: setProviderId,
    }),
    [providersQuery, providerId, setProviderId]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};

export const useActiveProvider = () => useContext(Context);
