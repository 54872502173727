type Props = {
  dateLabel?: string;
  items: {
    color: string;
    value: number;
    label: string;
  }[];
  isCurrency?: boolean;
  formatValue?: (val: number) => string;
};

export const DashboardChartTooltip: React.FC<Props> = ({ items, formatValue, dateLabel }) => {
  if (items.length === 0) {
    return null;
  }

  return (
    <div className="flex flex-col gap-2 p-1 text-sm">
      <div className="text-slate-500 font-sansSemiBold">{dateLabel}</div>
      <div className="flex">
        {items.map((item) => {
          return (
            <div
              key={item.label}
              className={`
                space-y-2
                border-r
                border-r-greyLighter
                mr-2
                pr-2
                last:border-r-0
              `}
            >
              <div>{formatValue ? formatValue(item.value) : item.value}</div>
              <div className="flex gap-x-1 items-center justify-center">
                <div className="h-3 w-3" style={{ backgroundColor: item.color }} />
                <div className="font-normal text-xs">{item.label}</div>
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};
