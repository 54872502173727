import { FC, ReactNode, useState, useCallback } from "react";
import { FolderVO, DocumentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatISODate, LONG_LOCALIZED_DATE_TIME } from "@libs/utils/date";
import { SupportedUploadMimeTypes, mimeTypesToExtensions } from "@libs/utils/mimeTypes";
import { pluralize } from "@libs/utils/pluralize";
import { isOneOf } from "@libs/utils/isOneOf";
import { listToText } from "@libs/utils/formatString";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as FolderIcon } from "@libs/assets/icons/folder.svg";
import { ReactComponent as FileIcon } from "@libs/assets/icons/upload-file.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";
import { getFolderDocuments } from "api/documents/queries";

interface Props {
  allowedMimeTypes?: SupportedUploadMimeTypes[];
  canSelectMultiple?: boolean;
  folders: FolderVO[];
  onRequestClose: Func;
  onSelectDocuments: (files: DocumentVO[]) => void;
  userId: number;
}

export const AttachDocumentModalContent: FC<Props> = ({
  allowedMimeTypes,
  canSelectMultiple = true,
  folders,
  onRequestClose,
  onSelectDocuments,
  userId,
}) => {
  const [selectedFolderId, setSelectedFolderId] = useState<FolderVO["id"]>(folders[0].id);
  const [selectedDocuments, setSelectedDocuments] = useState<Set<DocumentVO>>(new Set());

  const { practiceId } = useAccount();
  const [folderDocumentsQuery] = useApiQueries([
    getFolderDocuments({
      args: { practiceId, userId, folderId: selectedFolderId, includeMetadata: false },
    }),
  ]);

  const handleClickDocument = useCallback(
    (document: DocumentVO) => {
      if (selectedDocuments.has(document)) {
        setSelectedDocuments((last) =>
          canSelectMultiple ? new Set([...last].filter((d) => d.id !== document.id)) : new Set()
        );
      } else {
        setSelectedDocuments((last) =>
          canSelectMultiple ? new Set([...last, document]) : new Set([document])
        );
      }
    },
    [selectedDocuments, canSelectMultiple]
  );

  const handleSelectDocuments = useCallback(() => {
    onSelectDocuments([...selectedDocuments]);
    onRequestClose();
  }, [selectedDocuments, onSelectDocuments, onRequestClose]);

  return (
    <>
      <ModalContent className="flex max-h-80 divide-x divide-greyLighter" scrollClassName="overflow-hidden">
        <div className="flex-1 p-3 overflow-y-auto">
          {folders.map((folder) => (
            <ContentButton
              key={folder.id}
              Icon={FolderIcon}
              primaryText={folder.name}
              secondaryText={`${folder.fileCount} ${pluralize(folder.fileCount, "File", "Files")}`}
              isSelected={selectedFolderId === folder.id}
              onClick={() => setSelectedFolderId(folder.id)}
            />
          ))}
        </div>

        <div className="flex-1 p-3 overflow-y-auto">
          <QueryResult queries={[folderDocumentsQuery]}>
            {folderDocumentsQuery.data?.map((document) => (
              <ContentButton
                allowedMimeTypes={allowedMimeTypes}
                disabled={Boolean(
                  allowedMimeTypes
                    ? !isOneOf(document.type ?? "", mimeTypesToExtensions(allowedMimeTypes))
                    : false
                )}
                Icon={FileIcon}
                isSelected={selectedDocuments.has(document)}
                key={document.id}
                onClick={() => handleClickDocument(document)}
                primaryText={
                  <FloatingTooltip content={document.name} theme="SMALL" placement="top-start">
                    <span>{document.name}</span>
                  </FloatingTooltip>
                }
                secondaryText={formatISODate(document.creationDate, LONG_LOCALIZED_DATE_TIME)}
              />
            ))}
          </QueryResult>
        </div>
      </ModalContent>

      <ModalFooter>
        <Button className="min-w-button" onClick={onRequestClose} theme="secondary">
          Cancel
        </Button>
        <Button
          className="min-w-button"
          onClick={handleSelectDocuments}
          disabled={selectedDocuments.size === 0}
          theme="primary"
        >
          Select
        </Button>
      </ModalFooter>
    </>
  );
};

const ContentButton: FC<{
  allowedMimeTypes?: SupportedUploadMimeTypes[];
  disabled?: boolean;
  Icon: IconComponent;
  isSelected: boolean;
  onClick: Func;
  primaryText: string | ReactNode;
  secondaryText: string;
}> = ({ allowedMimeTypes, disabled = false, Icon, isSelected, onClick, primaryText, secondaryText }) => (
  <button
    className={cx(
      "flex items-center gap-x-4 px-4 py-2 w-full rounded",
      isSelected && "bg-grey-100",
      disabled ? "text-greyLight hover:bg-none" : "text-greyMedium hover:bg-slate-100"
    )}
    disabled={disabled}
    onClick={onClick}
    type="button"
  >
    <FloatingTooltip
      content={
        disabled && allowedMimeTypes
          ? `Can only select files of type ${listToText(mimeTypesToExtensions(allowedMimeTypes), "or")}.`
          : ""
      }
    >
      <Icon className={cx("flex-none w-5 h-5", isSelected && "text-archyBlue-500")} />
    </FloatingTooltip>
    <div className="text-left overflow-hidden">
      <div className={cx("text-sm truncate", isSelected && "text-archyBlue-500")}>{primaryText}</div>
      <div className="text-xs">{secondaryText}</div>
    </div>
  </button>
);
