import { PaymentDeviceVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { PosTerminal } from "components/PatientProfile/Billing/PosTerminal";

interface Props extends React.HTMLProps<HTMLButtonElement> {
  paymentPos: PaymentDeviceVO;
}

export const PosCard: React.FC<Props> = ({ paymentPos, className }) => {
  return (
    <div className={cx("grid grid-cols-[min-content_1fr] items-center gap-x-4", className)}>
      <div className="w-9 h-16">
        <PosTerminal model={paymentPos.model} className="w-9 h-16" />
      </div>
      <div className="text-xs text-left">
        <div className="font-sansSemiBold">{paymentPos.name}</div>
        <div>{sentenceCaseConstant(paymentPos.model)}</div>
      </div>
    </div>
  );
};
