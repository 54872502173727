import { useMemo } from "react";
import { ProviderVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { GetQueryOptions } from "@libs/@types/apiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { getInactiveProviders, getPracticeProvidersQuery } from "api/practice/queries";

/**
 * Fetches the standard practice providers and ensures the included providers are in the list by
 * fetching them from another endpoint in scenarios such as when they have been archived.
 * @param includeProviderIds The provider IDs to ensure are in the list.
 * @returns An array of ProviderVO objects.
 */
export const usePracticeProviders = (includeProviderIds?: number[]) => {
  const { practiceId } = useAccount();

  return useCustomProviders(getPracticeProvidersQuery({ args: { practiceId } }), includeProviderIds);
};

/**
 * Fetches a specific providers query, and eensures the include providers are in the list by
 * fetching them from another endpoint in scenarios such as when they have been archived.
 * @param query The query to fetch that returns a list of providers.
 * @param includeProviderIds The provider IDs to ensure are in the list.
 * @returns An array of ProviderVO objects.
 */
export const useCustomProviders = (query: GetQueryOptions<ProviderVO[]>, includeProviderIds?: number[]) => {
  const { practiceId } = useAccount();
  const [practiceProvidersQuery] = useApiQueries([query]);
  const missingProviders =
    (practiceProvidersQuery.data &&
      includeProviderIds?.filter(
        (providerId) => !practiceProvidersQuery.data?.some((provider) => provider.id === providerId)
      )) ||
    [];

  const [inactiveProvidersQuery] = useApiQueries([
    getInactiveProviders({
      args: { practiceId, providerIds: missingProviders },
      queryOptions: { enabled: missingProviders.length > 0 },
    }),
  ]);

  const data = useMemo(
    () => [...(inactiveProvidersQuery.data || []), ...(practiceProvidersQuery.data || [])],
    [practiceProvidersQuery.data, inactiveProvidersQuery.data]
  );

  return {
    isLoading: practiceProvidersQuery.isLoading || inactiveProvidersQuery.isLoading,
    isError: practiceProvidersQuery.isError || inactiveProvidersQuery.isError,
    data,
  };
};
