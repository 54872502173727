import { ProviderVO, RoomVO, TagVO } from "@libs/api/generated-api";
import { titleCaseConstant } from "@libs/utils/casing";
import { formatShortDayOfMonth } from "@libs/utils/date";
import { toFilterComponentsProps } from "@libs/components/UI/queryFilterPillsUtils";
import { DailyHuddleQuery } from "utils/routing/scheduling";

const getFormattedDateForPill = (dateRange: [Date | null, Date | null]) => {
  const startDateRange = dateRange[0];
  const endDateRange = dateRange[1];
  const start = startDateRange ? formatShortDayOfMonth(startDateRange) : undefined;
  const end = endDateRange ? formatShortDayOfMonth(endDateRange) : undefined;

  return start ? `Dates: ${start}${end && end !== start ? ` - ${end}` : ""}` : "";
};

const getFormattedProviderName = (value: number, practiceProviders: ProviderVO[] | undefined) => {
  return practiceProviders?.find((provider) => provider.id === value)?.name.fullDisplayName ?? "";
};

const getFormattedRoomName = (value: number, rooms: RoomVO[] | undefined) => {
  return rooms?.find((room) => room.id === value)?.roomName ?? "";
};

const getFormattedTagNames = (value: number, tags: TagVO[] | undefined) => {
  return tags?.find((tag) => tag.id === value)?.name ?? "";
};

export const getDailyHuddleFilterProps = (
  providers: ProviderVO[] | undefined,
  rooms: RoomVO[] | undefined,
  tags: TagVO[] | undefined,
  queryState: DailyHuddleQuery
) => {
  return toFilterComponentsProps({ ...queryState }, [
    {
      type: "default",
      prop: "patientName",
      format: (val) => `Search: ${val.trim()}`,
    },
    {
      type: "default",
      prop: "dateRange",
      format: (val) => getFormattedDateForPill(val),
    },
    {
      type: "list",
      prop: "statuses",
      format: (val) => titleCaseConstant(val),
    },
    {
      type: "list",
      prop: "providerIds",
      format: (val) => getFormattedProviderName(val, providers),
    },
    {
      type: "list",
      prop: "roomIds",
      format: (val) => getFormattedRoomName(val, rooms),
    },
    {
      type: "list",
      prop: "tagIds",
      format: (val) => `Tag: ${getFormattedTagNames(val, tags)}`,
    },
    {
      type: "list",
      prop: "clinicalNoteStatuses",
      format: (val) => `Clinical Note: ${titleCaseConstant(val)}`,
    },
  ]);
};
