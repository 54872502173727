import React from "react";
import { startOfDay } from "date-fns";
import { MedicalImageVO } from "@libs/api/generated-api";
import { formatAsISODate } from "@libs/utils/date";
import { keys } from "@libs/utils/object";
import { ActionModal } from "components/UI/ActionModal";
import { DatePickerBase } from "components/UI/DatePickerBase";
import { FormSection } from "components/UI/FormSection";
import { FormSectionTitle } from "components/UI/FormSectionTitle";
import { SelectSensor } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/SelectSensor";
import { ImagingDeviceWithSettings } from "components/PatientProfile/Imaging/hooks/useImagingDevices";
import { useNow } from "hooks/useNow";
import { useAppTheme } from "hooks/useAppTheme";

type MetadataChanges = Pick<MedicalImageVO, "assignedDate" | "sensor">;
export const EditImageMetadataModal: React.FC<{
  onDone: (changes?: MetadataChanges) => void;
  devices: ImagingDeviceWithSettings[];
}> = ({ onDone, devices }) => {
  const [selectedDate, setSelectedDate] = React.useState<null | Date>(null);
  const [sensorSelected, setSensorSelected] = React.useState<string | undefined>();
  const today = startOfDay(useNow());

  useAppTheme("light");

  return (
    <ActionModal
      handleClose={() => {
        onDone();
      }}
      primaryButtonDisabled={!selectedDate && !sensorSelected}
      handleSave={() => {
        const changes: MetadataChanges = {};

        if (selectedDate) {
          changes.assignedDate = formatAsISODate(selectedDate);
        }

        if (sensorSelected) {
          changes.sensor = sensorSelected;
        }

        if (keys(changes).length > 0) {
          onDone(changes);
        }
      }}
      size="xs"
      primaryButtonText="Save"
      title="Edit Images Data"
    >
      <div className="flex flex-col gap-5">
        <FormSection>
          <p className="text-xs">All currently visible images will be updated.</p>
        </FormSection>
        <FormSection>
          <FormSectionTitle>Date</FormSectionTitle>
          <div className="flex items-center justify-center">
            <DatePickerBase
              onChange={setSelectedDate}
              calendarStartDay={0}
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              dateFormat="M/d/yyyy"
              inline
              selected={selectedDate}
              yearDropdownItemNumber={10}
              maxDate={today}
              scrollableYearDropdown
            />
          </div>
        </FormSection>
        <FormSection>
          <FormSectionTitle>Sensor Assignment</FormSectionTitle>
          <SelectSensor value={sensorSelected} onChange={setSensorSelected} devices={devices} />
        </FormSection>
      </div>
    </ActionModal>
  );
};
