import React from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ImageLayoutItem } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";
import { useQueryParams } from "hooks/useQueryParams";

const isMultiSelect = (e: KeyboardEvent) => {
  const { ctrlKey, shiftKey, metaKey } = e;

  return ctrlKey || metaKey || shiftKey;
};

export const useSelectedImageIds = () => {
  const {
    query: { selectedImageIds },
    updateQuery,
  } = useQueryParams("mountDetails");
  const selectedSet = React.useMemo(() => new Set(selectedImageIds), [selectedImageIds]);

  const pressingShift = useBoolean(false);
  const setPressingShift = pressingShift.set;
  const clearSelectedImageIds = React.useCallback(
    () => updateQuery("replaceIn", { selectedImageIds: undefined }),
    [updateQuery]
  );

  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (isMultiSelect(e)) {
        setPressingShift(true);
      }
    };
    const onKeyUp = (e: KeyboardEvent) => {
      if (!isMultiSelect(e)) {
        setPressingShift(false);
      } else if (e.key === "Escape") {
        updateQuery("replaceIn", { selectedImageIds: [] });
      }
    };

    document.addEventListener("keyup", onKeyUp);
    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keyup", onKeyUp);
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [setPressingShift, updateQuery]);

  const handleImageSelected = React.useCallback(
    (image: ImageLayoutItem) => {
      const newItems = new Set(pressingShift.isOn ? [...selectedSet, image.id] : [image.id]);

      if (selectedSet.has(image.id)) {
        newItems.delete(image.id);
      }

      updateQuery("replaceIn", { selectedImageIds: [...newItems] });
    },
    [pressingShift.isOn, selectedSet, updateQuery]
  );

  return {
    selectedImageIds: selectedSet,
    handleImageSelected,
    clearSelectedImageIds,
  };
};
