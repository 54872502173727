import { useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ButtonIcon, ButtonIconProps } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as CloneIcon } from "@libs/assets/icons/copy.svg";
import { ReactComponent as HistoryIcon } from "@libs/assets/icons/history.svg";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus-circle.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { handleError } from "utils/handleError";

import { usePerioChart } from "./PerioChartContext";

const ONE_SECOND_IN_MILLISECONDS = 1000;

const cxStyles = {
  icon: "border border-slate-300 px-1.5 rounded-md",
};

type Props = {
  onClickHistory: Func;
};
export const PerioChartEditDetails: React.FC<Props> = ({ onClickHistory }) => {
  const { currentExam, updateExamInfo, isLoading, cloneExam, newExam } = usePerioChart();
  const examInfo = currentExam?.getExamInfo();

  const [providerId, setProviderId] = useState<number | undefined>(examInfo?.provider.id);
  const [date, setDate] = useState<Date | undefined>(() =>
    examInfo?.date ? getLocalDate(examInfo.date) : undefined
  );

  const { practiceId } = useAccount();

  const [{ data: providers }] = useApiQueries([getPracticeProvidersQuery({ args: { practiceId } })]);

  const providerOptions = useMemo(
    () =>
      providers
        ? providers.map((provider) => ({
            label: provider.name.fullDisplayName,
            shortLabel: provider.name.shortDisplayName,
            value: provider.id,
          }))
        : [],
    [providers]
  );

  useEffect(() => {
    setProviderId(examInfo?.provider.id);
    setDate(examInfo?.date ? getLocalDate(examInfo.date) : undefined);
  }, [examInfo]);

  const debounceUpdateExamInfo = useDebouncedCallback(updateExamInfo, ONE_SECOND_IN_MILLISECONDS);

  const buttonIconProps: Partial<ButtonIconProps> = {
    theme: "primary",
    className: cxStyles.icon,
  };

  return (
    <div className="flex flex-row font-sansSemiBold text-sm gap-2">
      <FormFieldSelectMenusDatepicker
        className="w-32"
        required={true}
        placeholderText="MM/DD/YYYY"
        selected={date}
        layout="labelOut"
        onChange={(newDate) => {
          setDate(newDate || undefined);
          debounceUpdateExamInfo({ date: newDate ? formatAsISODate(newDate) : undefined });
        }}
        disabled={isLoading}
      />
      <FormFieldSelect
        required={true}
        className="w-60"
        placeholder="Select Provider"
        options={providerOptions}
        layout="labelOut"
        onItemSelected={(selectedId) => {
          setProviderId(selectedId);
          updateExamInfo({ providerId: selectedId }).catch(handleError);
        }}
        value={providerId}
        disabled={isLoading}
      />
      <ButtonIcon
        SvgIcon={PlusIcon}
        theme="primary"
        className={cxStyles.icon}
        tooltip={{ content: "Create New", theme: "SMALL" }}
        onClick={() => newExam().catch(handleError)}
      />
      <ButtonIcon
        {...buttonIconProps}
        tooltip={{ content: "Clone", theme: "SMALL" }}
        SvgIcon={CloneIcon}
        onClick={() => cloneExam().catch(handleError)}
      />
      <ButtonIcon
        {...buttonIconProps}
        tooltip={{ content: "Chart History", theme: "SMALL" }}
        SvgIcon={HistoryIcon}
        onClick={onClickHistory}
      />
    </div>
  );
};
