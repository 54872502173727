import { useMemo, ReactNode } from "react";
import { PatientToothVO } from "@libs/api/generated-api";
import { ValueTypes } from "@libs/components/UI/OptionInput";
import { toggleSet } from "@libs/utils/toggleSet";

import {
  OptionInputList,
  OptionInputOption,
  CheckboxInputListProps,
  RadioInputListProps,
} from "@libs/components/UI/OptionInputList";
import { LabelledOptions } from "@libs/components/UI/LabelledOptions";
import { ToggleButton } from "components/UI/ToggleButton";
import { DraftPatientProcedureRequest } from "components/Charting/draftPatientProcedure";
import { TEETH_ROW_COUNT } from "components/Charting/teeth";

interface ToothButtonListProps {
  label?: ReactNode;
  required?: boolean;
  error?: string;
}

type ToothButtonCheckboxListProps<
  V extends ValueTypes,
  T extends OptionInputOption<V>,
> = CheckboxInputListProps<V, T> & ToothButtonListProps;

type ToothButtonRadioListProps<V extends ValueTypes, T extends OptionInputOption<V>> = RadioInputListProps<
  V,
  T
> &
  ToothButtonListProps;

const ToothButtonList = <V extends ValueTypes, T extends OptionInputOption<V>>({
  className,
  label,
  required,
  error,
  ...rest
}: ToothButtonCheckboxListProps<V, T> | ToothButtonRadioListProps<V, T>) => {
  return (
    <LabelledOptions
      label={label}
      error={error}
      required={required}
      layout="horiz"
      className={className}
      labelClassName="text-xxs"
    >
      <OptionInputList
        {...rest}
        className="grid grid-cols-teeth-options gap-3"
        renderOptionItem={(props) => <ToggleButton {...props} shape="smallSquare" />}
      />
    </LabelledOptions>
  );
};

interface Props {
  type: "radio" | "checkbox";
  teeth: PatientToothVO[];
  toothSelections: Set<string>;
  disabled?: boolean;
  isToothDisabled?: (tooth: PatientToothVO) => boolean;
  canDeselect?: boolean;
  onUpdateDraft?: (updates: Partial<DraftPatientProcedureRequest>) => void;
}

export const ToothOptionsList: React.FC<Props> = ({
  disabled,
  teeth,
  type,
  toothSelections,
  isToothDisabled,
  onUpdateDraft,
  canDeselect = false,
}) => {
  const options = useMemo(() => {
    const secondRow = teeth.slice(TEETH_ROW_COUNT);

    secondRow.reverse();

    const reordered = [...teeth.slice(0, TEETH_ROW_COUNT), ...secondRow];

    return reordered.map((tooth, index) => ({
      value: tooth.toothName || `placeholder-${index}`,
      label: tooth.toothName || "*",
      disabled: disabled || !tooth.toothName || Boolean(isToothDisabled?.(tooth)),
    }));
  }, [teeth, disabled, isToothDisabled]);

  const firstSelectedValue = [...toothSelections][0];

  return type === "radio" ? (
    <ToothButtonList
      options={options}
      type="radio"
      selectedValue={firstSelectedValue}
      onChange={(_e, item) => {
        const newValue = item.value;
        const deselect = canDeselect && newValue === [...toothSelections][0];

        onUpdateDraft?.({
          toothSelections: deselect ? new Set() : new Set([item.value]),
        });
      }}
    />
  ) : (
    <ToothButtonList
      options={options}
      type="checkbox"
      selectedValues={toothSelections}
      onChange={(_newSet, e) =>
        onUpdateDraft?.({ toothSelections: toggleSet(toothSelections, e.target.value) })
      }
    />
  );
};
