import { FC } from "react";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";

import { EditForm } from "components/Settings/Forms/EditForm";
import { EditFormSettingsFlyover } from "components/Settings/Forms/EditFormSettingsFlyover";
import { useEnvContext } from "contexts/EnvContext";

export const EditFormRoute: FC = () => {
  const { REACT_APP_PATIENT_PORTAL_HOST } = useEnvContext();
  const practice = useCurrentPractice();
  const { formId } = usePathParams("editForm");
  const { query } = useQueryParams("editForm");
  const from = query.from ?? paths.forms();

  return (
    <EditForm
      previewLink={`${REACT_APP_PATIENT_PORTAL_HOST}/p/${practice.uuid}/form-preview/${formId}`}
      from={from}
      formId={formId}
      SettingsFlyover={EditFormSettingsFlyover}
    />
  );
};
