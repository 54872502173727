import { DailyHuddleAppointmentVO, TagVO } from "@libs/api/generated-api";
import { FC } from "react";
import { sortAppointmentTagsWithOrder } from "components/ScheduleAppointments/tags";
import { PillTagNoLabel } from "components/Notes/PillTag";

interface Props {
  tagOrder: NonNullable<TagVO["slug"]>[];
  entry: DailyHuddleAppointmentVO;
  excludedTagIds: number[];
}

export const TagsCell: FC<Props> = ({ entry, tagOrder, excludedTagIds }) => {
  const excludedTagIdsSet = new Set(excludedTagIds);

  return (
    <div className="flex items-center gap-x-1">
      {sortAppointmentTagsWithOrder(entry.tags, tagOrder)
        .filter((tag) => !tag || !excludedTagIdsSet.has(tag.id))
        .map((tag, index) => (
          <div className="w-6" key={index}>
            {tag ? <PillTagNoLabel tag={tag} /> : null}
          </div>
        ))}
    </div>
  );
};
