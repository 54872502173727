import React from "react";
import { AppointmentVO } from "@libs/api/generated-api";
import { EmptyContent } from "@libs/components/UI/EmptyContent";
import { DraggableHolderCard } from "components/ScheduleAppointments/DraggableHolderCard";
import tabletUrl from "assets/images/tablet.svg";

interface Props {
  appointmentHolds: AppointmentVO[] | undefined;
  hipaaView: boolean;
  appointmentId?: number;
  onHolderClick: (appointment: AppointmentVO) => void;
  onHolderDoubleClick: (appointment: AppointmentVO) => void;
  onRequestHolderDelete: (appointment: AppointmentVO) => void;
}

export const HolderSection: React.FC<Props> = ({
  appointmentHolds,
  hipaaView,
  appointmentId,
  onHolderClick,
  onHolderDoubleClick,
  onRequestHolderDelete,
}) => {
  return appointmentHolds?.length ? (
    <div className="flex flex-col gap-y-3">
      {appointmentHolds.map((appointmentItem) => {
        return (
          <DraggableHolderCard
            hipaaView={hipaaView}
            isSelected={appointmentItem.id === appointmentId}
            key={appointmentItem.id}
            appointment={appointmentItem}
            onDoubleClick={() => onHolderDoubleClick(appointmentItem)}
            onClick={() => onHolderClick(appointmentItem)}
            onRequestDelete={() => onRequestHolderDelete(appointmentItem)}
          />
        );
      })}
    </div>
  ) : (
    <EmptyContent src={tabletUrl} alt="Tablet Planner App" text="No Appointment Holders" />
  );
};
