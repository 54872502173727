import React, { useCallback } from "react";
import { useNavigate, Link } from "react-router-dom";
import { InsuranceCarrierListEntryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getFullUrl } from "@libs/utils/location";
import { Icon } from "@libs/components/UI/Icon";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as ExternalLinkIcon } from "@libs/assets/icons/external-link.svg";
import { ReactComponent as CheckmarkIcon } from "@libs/assets/icons/check-circle-1.svg";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel-circle.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { Pill } from "@libs/components/UI/Pill";
import { ButtonCell, Cell, Row, cxGridTableStyles } from "@libs/components/UI/GridTableComponents";
import { paths } from "utils/routing/paths";
import { InsuranceCarrierDetailsQueryUpdates } from "utils/routing/settings";

type Props = {
  insurance: InsuranceCarrierListEntryVO;
  onEdit: (carrierId: number | "new", queryParams?: InsuranceCarrierDetailsQueryUpdates) => void;
};

export const FeeScheduleCellPill: React.FC<{ feeScheduleId: number; feeScheduleName: string }> = ({
  feeScheduleId,
  feeScheduleName,
}) => {
  return (
    <Link to={paths.feeScheduleDetails({ feeScheduleId }, { from: getFullUrl(location) })}>
      <FloatingTooltip content={feeScheduleName} theme="SMALL">
        <Pill>
          <div className="truncate max-w-xs">{feeScheduleName}</div>
        </Pill>
      </FloatingTooltip>
    </Link>
  );
};

export const InsuranceCarrierRow: React.FC<Props> = ({ onEdit, insurance }) => {
  const navigate = useNavigate();
  const handleNavigateToPage = useCallback(() => {
    const fullUrl = getFullUrl(location);

    onEdit(insurance.id, { from: fullUrl });
  }, [insurance.id, onEdit]);

  const cellProps = {
    className: "flex items-center min-h-10",
    onDoubleClick: handleNavigateToPage,
    verticalPadding: "slim" as const,
  };
  const menu = useBoolean(false);

  return (
    <Row>
      <ButtonCell {...cellProps}>
        <div className="truncate text-ellipsis">{insurance.name}</div>
      </ButtonCell>
      <ButtonCell {...cellProps}>{insurance.payerId}</ButtonCell>
      {insurance.planCount > 0 ? (
        <Cell {...cellProps} className={cx("px-3 gap-x-2", cellProps.className, cxGridTableStyles.dataCell)}>
          <div className="flex items-center gap-x-2">
            <span>{insurance.planCount}</span>
            <ButtonIcon
              SvgIcon={ExternalLinkIcon}
              onClick={(e) => {
                e.preventDefault();
                navigate(paths.insurancePlans({ carrierIds: [insurance.id], from: getFullUrl(location) }));
              }}
              theme="slate600"
            />
          </div>
        </Cell>
      ) : (
        <ButtonCell {...cellProps}>{insurance.planCount}</ButtonCell>
      )}
      {insurance.patientCount > 0 ? (
        <Cell {...cellProps} className={cx("px-3 gap-x-2", cellProps.className, cxGridTableStyles.dataCell)}>
          <div className="flex items-center gap-x-2">
            <span>{insurance.patientCount}</span>
            <ButtonIcon
              SvgIcon={ExternalLinkIcon}
              onClick={(e) => {
                e.preventDefault();
                navigate(
                  // eslint-disable-next-line @typescript-eslint/naming-convention
                  paths.patients({ "patientCriteria.carrierIds": [insurance.id] })
                );
              }}
              theme="slate600"
            />
          </div>
        </Cell>
      ) : (
        <ButtonCell {...cellProps}>{insurance.patientCount}</ButtonCell>
      )}
      <Cell
        {...cellProps}
        className={cx("flex flex-wrap px-3 py-2 gap-1", cellProps.className, cxGridTableStyles.dataCell)}
      >
        {insurance.feeSchedules.map((feeSchedule) => {
          return (
            <FeeScheduleCellPill
              key={feeSchedule.id}
              feeScheduleId={feeSchedule.id}
              feeScheduleName={feeSchedule.name}
            />
          );
        })}
      </Cell>
      <ButtonCell {...cellProps}>
        <Icon
          SvgIcon={insurance.inNetwork ? CheckmarkIcon : CancelIcon}
          tooltip={{
            content: insurance.inNetwork ? "In Network" : "Out of Network",
            theme: "SMALL",
          }}
          theme={insurance.inNetwork ? "success" : "error"}
        />
      </ButtonCell>
      <Cell className={cx("flex items-center pl-3 pr-5", cxGridTableStyles.dataCell)}>
        <ButtonMenu
          className={cellProps.className}
          isOpen={menu.isOn}
          menuContent={
            <div className="flex flex-col w-36">
              <MenuOptionButton
                className="gap-x-2"
                name="Edit"
                onClick={() => {
                  onEdit(insurance.id);
                  menu.off();
                }}
              >
                <Icon SvgIcon={EditIcon} />
                Edit
              </MenuOptionButton>
            </div>
          }
          onRequestClose={menu.off}
          onRequestOpen={menu.on}
          placement="left-start"
        >
          {(props) => (
            <ButtonIcon
              {...props}
              SvgIcon={MenuIcon}
              onClick={(e) => {
                e.preventDefault();
                menu.on();
              }}
            />
          )}
        </ButtonMenu>
      </Cell>
    </Row>
  );
};
