import React, { FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { ParsedParams } from "@libs/router/url";
import { Form } from "@libs/components/UI/Form";
import { MainSignInProps, SignInInputType } from "components/SignIn/MainSignIn";
import { FormFieldPassword } from "components/UI/FormFieldPassword";
import { MainSignInValidationResults } from "components/SignIn/validationUtil";
import { paths, routesConfig } from "utils/routing/paths";

interface Props {
  handleInputChange: (inputName: SignInInputType, value: string) => void;
  handleSignIn: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  signInState: MainSignInProps;
  updateSignInState: (updates: Partial<MainSignInProps>) => void;
  validateResult: MainSignInValidationResults;
  query: ParsedParams<typeof routesConfig.signIn.query>;
}

export const cxFormStyles = {
  submitButton: "min-w-button w-fit",
};

export const SignInForm: React.FC<Props> = ({
  handleInputChange,
  handleSignIn,
  signInState,
  updateSignInState,
  validateResult,
  query,
}) => {
  const navigate = useNavigate();

  return (
    <Form onSubmit={handleSignIn} className="flex flex-col gap-6">
      <FormFieldInput
        displayErrorMessage={!signInState.errorMessage}
        error={validateResult.email.$error || signInState.errorMessage}
        label="Email"
        layout="labelOut"
        name="email"
        onChange={(event) => handleInputChange("email", event.target.value)}
        placeholder="example@gmail.com"
        type="email"
        value={signInState.email}
        autoFocus
      />
      <FormFieldPassword
        error={validateResult.password.$error || signInState.errorMessage}
        layout="labelOut"
        label={query.resetPassword ? "Temporary Password" : "Password"}
        name="password"
        onChange={(event) => handleInputChange("password", event.target.value)}
        placeholder="Enter Password"
        value={signInState.password}
      />
      <div className="flex flex-col gap-6 items-center">
        <div className="w-full flex justify-between">
          <div className="flex">
            <Checkbox
              checked={signInState.rememberMe}
              onChange={() => updateSignInState({ rememberMe: !signInState.rememberMe })}
            >
              Remember Me
            </Checkbox>
          </div>
          <button
            className="pt-0.5 text-primaryTheme font-sansSemiBold text-xs pointer"
            onClick={() =>
              navigate(paths.forgotPassword({ returnUrl: query.returnUrl, lastUserId: query.lastUserId }))
            }
            type="button"
          >
            Forgot Password?
          </button>
        </div>
        <AsyncButton
          className={cxFormStyles.submitButton}
          displayLoadingText
          isLoading={signInState.isLoading}
          type="submit"
        >
          {signInState.isLoading ? "Signing in" : "Sign in"}
        </AsyncButton>
      </div>
    </Form>
  );
};
