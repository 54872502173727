import { FC, Fragment, useMemo, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { produce } from "immer";
import { FormVO } from "@libs/api/generated-api";
import { isNullish } from "@libs/utils/types";
import {
  CurrentElement,
  DragItem,
  SortableFormElement,
  SortableFormPage,
  SortableFormSectionElement,
} from "components/Settings/Forms/types";
import {
  DRAG_FORM_ELEMENT_HANDLE,
  FormStructureElement,
} from "components/Settings/Forms/FormStructureElement";
import { isDraggingWithingSection } from "components/Settings/Forms/utils";
import { FormSection } from "components/Settings/Forms/FormSection";
import { ElementButtonAppend } from "components/Settings/Forms/ElementButtonAppend";
import { ElementButtonInsert } from "components/Settings/Forms/ElementButtonInsert";

interface Props {
  form: FormVO;
  currentElement: CurrentElement | undefined;
  placeholderElement: CurrentElement | undefined;
  openSections: Set<string>;
  onToggleSection: (sectionId: string) => void;
  onEditElement: (element?: CurrentElement) => void;
  onDelete: (id: string) => void;
  onPageSorted: (id: string, elements: SortableFormElement[]) => void;
  onSectionSorted: (id: string, elements: SortableFormSectionElement[]) => void;
}

export const FormStructure: FC<Props> = ({
  form,
  currentElement,
  placeholderElement,
  openSections,
  onToggleSection,
  onEditElement,
  onPageSorted,
  onDelete,
  onSectionSorted,
}) => {
  const [dragItem, setDragItem] = useState<DragItem>();

  const sortableFormContent = useMemo(
    () =>
      produce(form.content as SortableFormPage[], (pages) => {
        for (const page of pages) {
          for (const element of page.content) {
            element.id = element.uuid;

            if (element.type === "SECTION") {
              for (const sectionElement of element.content) {
                sectionElement.id = sectionElement.uuid;
              }
            }
          }
        }
      }),
    [form.content]
  );

  return (
    <>
      {sortableFormContent.map((page) => (
        <Fragment key={page.uuid}>
          {page.content.length > 0 ? (
            <ElementButtonInsert
              isDragging={Boolean(dragItem)}
              placeholderElement={placeholderElement}
              currentElement={currentElement}
              index={0}
              onEditElement={onEditElement}
            />
          ) : null}
          <ReactSortable
            group="nested"
            animation={150}
            disabled={currentElement?.flow === "ADD"}
            swapThreshold={0.1}
            className="flex flex-col w-full"
            list={page.content}
            handle={`.${DRAG_FORM_ELEMENT_HANDLE}`}
            setList={(elements) => onPageSorted(page.uuid, elements)}
            onEnd={() => setDragItem(undefined)}
            onStart={(e) =>
              setDragItem(isNullish(e.oldIndex) ? undefined : { item: page.content[e.oldIndex] })
            }
            onMove={(e) => {
              const isWithinSection = isDraggingWithingSection(e);

              if (dragItem?.item.type === "SECTION" && isWithinSection) {
                return false;
              }

              return true;
            }}
          >
            {page.content.map((element, index) => (
              <div key={element.uuid}>
                {element.type === "SECTION" ? (
                  <FormSection
                    element={element}
                    isOpen={openSections.has(element.uuid)}
                    onToggleOpen={onToggleSection}
                    currentElement={currentElement}
                    placeholderElement={placeholderElement}
                    isDragging={Boolean(dragItem && dragItem.item.uuid === element.uuid)}
                    dragItem={dragItem}
                    index={index}
                    isSelected={currentElement?.element?.uuid === element.uuid}
                    onEditElement={onEditElement}
                    onSorted={onSectionSorted}
                    onDelete={onDelete}
                    onUpdateDragItem={setDragItem}
                  />
                ) : (
                  <FormStructureElement
                    isAddingElement={currentElement?.flow === "ADD"}
                    isDraggingElement={Boolean(dragItem && dragItem.item.uuid === element.uuid)}
                    isDragging={Boolean(dragItem)}
                    element={element}
                    isSelected={currentElement?.element?.uuid === element.uuid}
                    onDelete={() => onDelete(element.uuid)}
                    onClick={() => onEditElement({ flow: "EDIT", element })}
                  >
                    {element.title}
                  </FormStructureElement>
                )}

                <ElementButtonInsert
                  isLast={index === page.content.length - 1}
                  isDragging={Boolean(dragItem)}
                  placeholderElement={placeholderElement}
                  currentElement={currentElement}
                  index={index + 1}
                  onEditElement={onEditElement}
                />
              </div>
            ))}
          </ReactSortable>
          <ElementButtonAppend
            index={page.content.length}
            currentElement={currentElement}
            placeholderElement={placeholderElement}
            onOpenPalette={() => onEditElement({ flow: "ADD", index: page.content.length })}
          />
        </Fragment>
      ))}
    </>
  );
};
