import { FC, useCallback, useEffect, useState } from "react";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { MainContent } from "@libs/components/UI/MainContent";
import { fetchDosespotSingleSignOnUri } from "api/erx/mutations";
import { handleError } from "utils/handleError";
import { LoadingOverlay } from "components/UI/LoadingOverlay";
import { DoseSpotPanel } from "components/Erx/DoseSpotPanel";

export const ErxNotificationsRoute: FC = () => {
  const [{ mutate: getSsoUri, isLoading: isLoadingIframeUrl }] = useApiMutations([
    fetchDosespotSingleSignOnUri,
  ]);
  const { practiceId } = useAccount();
  const [iframeUrl, setIframeUrl] = useState<string>();

  const getIframeUrl = useCallback(() => {
    getSsoUri(
      { practiceId },
      {
        onSuccess: (data) => setIframeUrl(data.data.data.dosespotSingleSignOnUri),
        onError: handleError,
      }
    );
  }, [getSsoUri, practiceId]);

  const loadIframeUrl = useCallback(() => getIframeUrl(), [getIframeUrl]);

  useEffect(() => {
    loadIframeUrl();
  }, [loadIframeUrl]);

  return (
    <MainContent>
      <DoseSpotPanel>
        <LoadingOverlay isLoading={isLoadingIframeUrl} loadingText="Loading DoseSpot...">
          <div className="w-full h-full">
            {iframeUrl && (
              <iframe
                title="DoseSpot"
                src={iframeUrl}
                className={`
                  origin-[0_0]
                  scale-75
                  w-[calc(100%/0.75)]
                  h-[calc(100%/0.75)]
                `}
              />
            )}
          </div>
        </LoadingOverlay>
      </DoseSpotPanel>
    </MainContent>
  );
};
