import { Link } from "react-router-dom";
import { Icon } from "@libs/components/UI/Icon";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as UserIcon } from "@libs/assets/icons/user.svg";
import { paths } from "utils/routing/paths";
import { PatientTooltip, PatientTooltipProps } from "components/Patient/PatientTooltip";
import { getPatientDisplayName } from "utils/names";

export const PatientTag: React.FC<PatientTooltipProps> = (props) => {
  return (
    <FloatingTooltip content={<PatientTooltip {...props} />} displayArrow={false} placement="bottom-start">
      <Link
        className={`
          flex
          items-center
          px-2
          py-1
          w-fit
          max-w-full
          gap-x-1.5
          text-xs
          text-greyDark
          bg-greyLightest
          rounded
        `}
        to={paths.patient({ patientId: props.name.id })}
      >
        <Icon SvgIcon={UserIcon} size="sm" />
        <div className="min-w-0 truncate">{getPatientDisplayName(props)}</div>
      </Link>
    </FloatingTooltip>
  );
};
