import React from "react";
import useMeasure from "react-use-measure";
import { MedicalImageVO, PearlAnnotationsInner } from "@libs/api/generated-api";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { Backdrop } from "@libs/components/UI/Backdrop";
import {
  getFilters,
  getImageTransformStyles,
} from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { useImageEditorContext } from "components/PatientProfile/Imaging/ImageEditor/ImageEditorContext";
import { LazyReactImageEditor } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/components/LazyReactImageEditor";
import { MountFormat } from "utils/routing/patient";
import { MaxSizeWithAspect } from "components/ImageCapturing/Calibration/MaxSizeWithAspect";
import { acceptPearlAnnotation, dismissPearlAnnotation } from "api/imaging/mutations";
import { handleError } from "utils/handleError";

type Props<T extends MedicalImageVO> = {
  image: T;
  patientId: number;
  format?: MountFormat;
  children: React.ReactNode;
  onClose?: Func;
};

const getSizingSyles = (image: MedicalImageVO, sizeInverted: boolean) => {
  const sizeStyles = sizeInverted ? { width: image.h, height: image.w } : { width: image.w, height: image.h };

  return {
    maxWidth: sizeStyles.width,
    maxHeight: sizeStyles.height,
    aspectRatio: `${sizeStyles.width ?? 0} / ${sizeStyles.height ?? 0}`,
  };
};

export const ImageCanvas = <T extends MedicalImageVO>({ image, children, onClose, patientId }: Props<T>) => {
  const { practiceId } = useAccount();
  const [containerRef, containerBounds] = useMeasure({ debounce: 50 });
  const {
    editor,
    drawSettings,
    lensApplied,
    handleFabricInit,
    pearlConfig,
    pearlAnalysis,
    layersShown,
    imageSensorSetting,
  } = useImageEditorContext();
  const [acceptPearlAnnotationMutation, dismissPearlAnnotationMutation] = useApiMutations([
    acceptPearlAnnotation,
    dismissPearlAnnotation,
  ]);

  const handleAcceptAnnotation = React.useCallback(
    async (annotation: PearlAnnotationsInner) => {
      if (!image.id || !annotation.enml_annotation_id) {
        return;
      }

      try {
        await acceptPearlAnnotationMutation.mutateAsync({
          patientId,
          imageId: image.id,
          annotationId: annotation.enml_annotation_id,
          practiceId,
        });
      } catch (e) {
        handleError(e);
      }
    },
    [acceptPearlAnnotationMutation, image.id, patientId, practiceId]
  );
  const handleDismissAnnotation = React.useCallback(
    async (annotation: PearlAnnotationsInner) => {
      if (!image.id || !annotation.enml_annotation_id) {
        return;
      }

      try {
        await dismissPearlAnnotationMutation.mutateAsync({
          patientId,
          imageId: image.id,
          annotationId: annotation.enml_annotation_id,
          practiceId,
        });
      } catch (e) {
        handleError(e);
      }
    },
    [dismissPearlAnnotationMutation, image.id, patientId, practiceId]
  );

  const filter = React.useMemo(
    () =>
      getFilters({
        id: -1,
        filters: layersShown.has("filters") ? image.filters : undefined,
      }),
    [layersShown, image.filters]
  );

  if (!image.url || !image.id) {
    return null;
  }

  const showEditor = containerBounds.width > 0 && image.w;

  const { sizeInverted } = getImageTransformStyles(image.transforms);
  const sizingStyles = getSizingSyles(image, sizeInverted);

  return (
    <MaxSizeWithAspect
      maxWidth={sizingStyles.maxWidth ?? 0}
      maxHeight={sizingStyles.maxHeight ?? 0}
      backdrop={onClose ? <Backdrop className="cursor-pointer" onClick={onClose} /> : undefined}
    >
      <div
        className={`
          bg-black
          print:bg-white
          h-full
          w-full
          relative
          outline
          outline-primaryTheme
        `}
        ref={containerRef}
      >
        {children}

        {showEditor && (
          <LazyReactImageEditor
            ref={editor}
            image={image}
            cssFilters={filter}
            imageSensorSetting={imageSensorSetting}
            lensApplied={lensApplied}
            onFabricInit={handleFabricInit}
            layersShown={layersShown}
            bounds={containerBounds}
            pearlConfig={pearlConfig}
            pearlAnalysis={pearlAnalysis}
            onAcceptAnnotation={handleAcceptAnnotation}
            onDismissAnnotation={handleDismissAnnotation}
            activeLayer={drawSettings.mouseMode ? "canvas" : "pearl"}
          />
        )}
      </div>
    </MaxSizeWithAspect>
  );
};
