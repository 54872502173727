import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { AutomationActionRequest } from "@libs/api/generated-api";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { noCache } from "@libs/utils/queryCache";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { createAutomationAction } from "api/communications/mutations";
import { ActionModalPage, ActionModalPageProps } from "components/Communications/Automations/ActionModalPage";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { handleError } from "utils/handleError";
import { getDentalProceduresQuery } from "api/charting/queries";
import { isEmailConfigured } from "components/Communications/utils";
import {
  getDraftAutomationActionFromJourneyType,
  journeyNames,
} from "components/Communications/Automations/utils";
import { getPatientEngagementTemplateVariables } from "api/communications/queries";
import { getEmailConfig } from "api/settings/communications/queries";

const CreateAutomationContent: FC<
  Omit<ActionModalPageProps, "draftAction" | "templateVariablesQuery" | "onUpdate">
> = (props) => {
  const { practiceId } = useAccount();
  const [draftAction, updateDraftAction] = useObjectState(() => {
    return getDraftAutomationActionFromJourneyType(props.journeyType, isEmailConfigured(props.emailConfig));
  });

  const [templateVariablesQuery] = useApiQueries([
    getPatientEngagementTemplateVariables({
      args: { practiceId, eventType: draftAction.event, category: "ACTION" },
      // We want to keep previous date otherwise when switching between events
      // the message content flickers between loading and loaded state
      queryOptions: { enabled: Boolean(draftAction.event), keepPreviousData: true },
    }),
  ]);

  return (
    <ActionModalPage
      draftAction={draftAction}
      templateVariablesQuery={templateVariablesQuery}
      onUpdate={updateDraftAction}
      {...props}
    />
  );
};

export const CreateAutomationActionRoute: FC = () => {
  const navigate = useNavigate();
  const practice = useCurrentPractice();
  const { journeyType } = usePathParams("createAutomationAction");
  const { query } = useQueryParams("createAutomationAction");
  const from = query.from ?? paths.journey({ journeyType });
  const requiresDentalProcedures = journeyType === "POST_APPOINTMENT";

  const [emailConfigQuery, dentalProceduresQuery] = useApiQueries([
    getEmailConfig({ args: { practiceId: practice.id }, queryOptions: noCache }),
    getDentalProceduresQuery({
      args: { practiceId: practice.id },
      queryOptions: { enabled: requiresDentalProcedures },
    }),
  ]);

  const [createAutomationActionMutation] = useApiMutations([createAutomationAction]);

  const handleSave = (data: AutomationActionRequest) => {
    createAutomationActionMutation.mutate(
      { practiceId: practice.id, journeyType, data },
      { onError: handleError, onSuccess: () => navigate(from, { replace: true }) }
    );
  };

  return (
    <QueryResult queries={[dentalProceduresQuery, emailConfigQuery]}>
      {emailConfigQuery.data && (!requiresDentalProcedures || dentalProceduresQuery.data) ? (
        <CreateAutomationContent
          onSave={handleSave}
          from={from}
          emailConfig={emailConfigQuery.data}
          title={`New ${journeyNames[journeyType]} Message`}
          isSaving={createAutomationActionMutation.isLoading}
          journeyType={journeyType}
          dentalProcedures={dentalProceduresQuery.data}
          practice={practice}
        />
      ) : null}
    </QueryResult>
  );
};
