import { FC, useMemo, useCallback, useContext } from "react";

import { useLocation, useNavigate } from "react-router-dom";
import { MessageCampaignSummaryVO, MessageCampaignVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { formatUnixTimestamp, LONG_LOCALIZED_DATE } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getFullUrl } from "@libs/utils/location";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as VerticalMenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as EyeIcon } from "@libs/assets/icons/eye.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as CopyIcon } from "@libs/assets/icons/copy.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { MenuOptions, createMenuOptions, createMenuOption } from "@libs/components/UI/MenuOptions";
import { Row, ButtonCell, IconsCell, EMPTY_CELL } from "@libs/components/UI/GridTableComponents";

import { formatNumberWithCommas } from "@libs/utils/formatNumber";
import { CampaignStatusPill } from "components/Communications/Campaigns/CampaignStatusPill";

import { getCreateEmailCampaignRequest } from "components/Communications/Campaigns/Campaign/utils";

import { createMessageCampaign, deleteDraftMessageCampaign } from "api/communications/mutations";

import { paths } from "utils/routing/paths";
import { handleError } from "utils/handleError";

interface Props {
  campaign: MessageCampaignSummaryVO;
  isLastRow?: boolean;
}

export const EmailCampaignRow: FC<Props> = ({ campaign, isLastRow = false }) => {
  const { fetchData } = useContext(ApiClientContext);
  const practice = useCurrentPractice();

  const location = useLocation();
  const navigate = useNavigate();
  const menu = useBoolean(false);
  const copying = useBoolean(false);

  const isCopying = copying.isOn;
  const isDraft = campaign.status === "DRAFT";

  const [createMessageCampaignMutation, deleteDraftMessageCampaignMutation] = useApiMutations([
    createMessageCampaign,
    deleteDraftMessageCampaign,
  ]);

  const isDeleting = deleteDraftMessageCampaignMutation.isLoading;

  const menuOptions = useMemo(
    () =>
      [
        ...createMenuOptions(
          {
            label: isDraft ? "Edit" : "View",
            value: "edit-view",
            SvgIcon: isDraft ? EditIcon : EyeIcon,
          },
          {
            label: "Copy",
            value: "copy",
            SvgIcon: CopyIcon,
            isLoading: isCopying,
          }
          // TODO: Link to report when available
          // {
          //   label: "Peformance",
          //   value: "performance",
          //   SvgIcon: ChartLineIcon,
          // }
        ),
        isDraft
          ? createMenuOption({
              label: "Delete",
              value: "delete",
              SvgIcon: DeleteIcon,
              isLoading: isDeleting,
            })
          : null,
      ].filter(isDefined),
    [isDraft, isCopying, isDeleting]
  );
  const handleCopyEmailCampaign = useCallback(async () => {
    copying.on();

    try {
      const { name, template, emailTemplate, filter } = await fetchData<MessageCampaignVO>(
        `/practices/${practice.id}/message-campaign/${campaign.uuid}`
      );

      const data = getCreateEmailCampaignRequest({
        name: `${name ?? "Untitled Campaign"} (Copy)`,
        template,
        emailTemplate,
        filter,
      });

      const response = await createMessageCampaignMutation.mutateAsync({
        practiceId: practice.id,
        data,
      });

      navigate(paths.campaign({ campaignUuid: response.data.data.uuid }, { from: getFullUrl(location) }));
    } catch (error) {
      handleError(error);
    } finally {
      copying.off();
    }
  }, [fetchData, campaign.uuid, createMessageCampaignMutation, practice.id, copying, navigate, location]);

  const handleDeleteDraftEmailCampaign = useCallback(() => {
    deleteDraftMessageCampaignMutation.mutate(
      {
        practiceId: practice.id,
        messageCampaignUuid: campaign.uuid,
      },
      {
        onSuccess: menu.off,
        onError: handleError,
      }
    );
  }, [deleteDraftMessageCampaignMutation, practice.id, campaign.uuid, menu]);

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "edit-view": {
          navigate(paths.campaign({ campaignUuid: campaign.uuid }, { from: getFullUrl(location) }));

          break;
        }
        case "copy": {
          handleCopyEmailCampaign();
          break;
        }
        case "delete": {
          handleDeleteDraftEmailCampaign();
          break;
        }
        default: {
          break;
        }
      }
    },
    [navigate, location, campaign.uuid, handleCopyEmailCampaign, handleDeleteDraftEmailCampaign]
  );

  const handleRowClick = useCallback(
    () => navigate(paths.campaign({ campaignUuid: campaign.uuid }, { from: getFullUrl(location) })),
    [navigate, location, campaign.uuid]
  );

  const cellProps = { border: !isLastRow, borderColor: "border-slate-200" as const };
  const buttonCellProps = { ...cellProps, onDoubleClick: handleRowClick };
  const isSent = campaign.status === "SENT";
  const isScheduled = campaign.status === "SCHEDULED";

  return (
    <Row className="*:flex *:items-center" data-testid="campaign-row">
      <ButtonCell {...buttonCellProps}>
        <p className="truncate">{campaign.name}</p>
      </ButtonCell>
      <ButtonCell {...buttonCellProps}>
        {isSent
          ? formatNumberWithCommas(campaign.sentMessageCount)
          : formatNumberWithCommas(campaign.requestedMessageCount)}
      </ButtonCell>
      <ButtonCell {...buttonCellProps}>{isSent ? `${campaign.openRate}%` : EMPTY_CELL}</ButtonCell>
      <ButtonCell {...buttonCellProps}>{isSent ? `${campaign.clickRate}%` : EMPTY_CELL}</ButtonCell>
      <ButtonCell {...buttonCellProps}>
        {campaign.sentAt
          ? formatUnixTimestamp(campaign.sentAt, practice.timezoneId, LONG_LOCALIZED_DATE)
          : isScheduled && campaign.scheduledAt
            ? formatUnixTimestamp(campaign.scheduledAt, practice.timezoneId, LONG_LOCALIZED_DATE)
            : EMPTY_CELL}
      </ButtonCell>
      <ButtonCell {...buttonCellProps}>
        <CampaignStatusPill status={campaign.status} />
      </ButtonCell>
      <IconsCell {...cellProps}>
        <ButtonMenu
          menuContent={
            <div className="w-36">
              <MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />
            </div>
          }
          onRequestOpen={menu.on}
          onRequestClose={menu.off}
          isOpen={menu.isOn}
          placement="bottom-end"
        >
          {(props) => (
            <ButtonIcon aria-label="campaign-row-menu-button" SvgIcon={VerticalMenuIcon} {...props} />
          )}
        </ButtonMenu>
      </IconsCell>
    </Row>
  );
};
