import { Link } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { paths } from "utils/routing/paths";

type Props = {
  patient: {
    id: number;
    shortDisplayName: string;
  };
  to?: string;
  className?: string;
};
export const PatientNameLink: React.FC<Props> = ({ patient, className, to }) => {
  return (
    <FloatingTooltip content="View Patient" theme="SMALL">
      <Link
        className={cx("hover:text-primaryTheme", className)}
        to={to ?? paths.patient({ patientId: patient.id })}
        onClick={(e) => {
          e.stopPropagation();
        }}
      >
        {patient.shortDisplayName}
      </Link>
    </FloatingTooltip>
  );
};
