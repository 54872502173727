import { FC, FormEvent, PropsWithChildren } from "react";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { Modal, ModalProps } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter, ModalForm } from "@libs/components/UI/ModalComponents";

type Props = PropsWithChildren<
  Pick<ModalProps, "onClose" | "title"> & {
    isSaving: boolean;
    onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  }
>;

export const SendTestModal: FC<Props> = ({ onClose, title, children, isSaving, onSubmit }) => {
  return (
    <Modal title={title} onClose={onClose} size="3xs">
      <ModalForm onSubmit={onSubmit}>
        <ModalContent padding="sm">{children}</ModalContent>
        <ModalFooter>
          <Button className="min-w-button" theme="secondary" onClick={onClose}>
            Cancel
          </Button>
          <AsyncButton type="submit" isLoading={isSaving} className="min-w-button">
            Send
          </AsyncButton>
        </ModalFooter>
      </ModalForm>
    </Modal>
  );
};
