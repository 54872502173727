import { createContext, useContext, useState, useMemo, useEffect, useRef, PropsWithChildren } from "react";
import { noop } from "@libs/utils/noop";
import { ChangeTrackedRecord, createChangeTrackedRecord } from "utils/changeTrackedRecord";
import { getControlInfo } from "./perioChartUtils";

export interface PerioChartControlFeatures {
  plusTenActive: boolean;
  gingMargPlusActive: boolean;
}

export interface PerioChartControlValue {
  controlFeatures: ChangeTrackedRecord<PerioChartControlFeatures>;
  setPlusTen: (active: boolean) => void;
  setGingMargPlus: (active: boolean) => void;
}

const defaultControlFeatures: ChangeTrackedRecord<PerioChartControlFeatures> = {
  acceptChanges: noop,
  getRecord: () => defaultControlFeatures,
  setValue: noop,
  setData: noop,
  revert: noop,
  isModified: () => false,
  subscribe: () => noop,
  plusTenActive: false,
  gingMargPlusActive: false,
};

const Context = createContext<PerioChartControlValue>({
  controlFeatures: defaultControlFeatures,
  setPlusTen: noop,
  setGingMargPlus: noop,
});

Context.displayName = "PerioChartControlContext";

export const usePerioChartControlContext = () => useContext(Context);

export const PerioChartControlProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const focusedElement = useRef<HTMLElement>();

  const [controlFeatures] = useState<ChangeTrackedRecord<PerioChartControlFeatures>>(() =>
    createChangeTrackedRecord<PerioChartControlFeatures>(
      { plusTenActive: false, gingMargPlusActive: false },
      ["gingMargPlusActive", "plusTenActive"]
    )
  );

  const providerValue = useMemo<PerioChartControlValue>(
    () => ({
      controlFeatures,
      setPlusTen: (active: boolean) => {
        if (active) {
          const element = focusedElement.current;
          const controlInfo = getControlInfo(element);

          if (controlInfo && controlInfo.toothNum && controlInfo.fieldName) {
            controlFeatures.plusTenActive = active;
          }
        } else {
          controlFeatures.plusTenActive = false;
        }
      },
      setGingMargPlus: (active: boolean) => {
        // This intentionally does not trigger a state change for performance,
        // otherwise thousands of controls will re-render.
        controlFeatures.gingMargPlusActive = active;
      },
    }),
    [controlFeatures]
  );

  useEffect(() => {
    const focusHandler = (e: FocusEvent) => {
      const element = e.target as HTMLElement;
      const controlInfo = getControlInfo(element);

      // Don't set the focus if its not a perio chart field, otherwise buttons like skip tooth won't work.
      if (controlInfo && focusedElement.current !== element) {
        focusedElement.current = element;
        controlFeatures.plusTenActive = false;
      }
    };

    document.addEventListener("focus", focusHandler, true);

    return () => {
      document.removeEventListener("focus", focusHandler, true);
    };
  }, [controlFeatures]);

  return <Context.Provider value={providerValue}>{children}</Context.Provider>;
};
