import { FC } from "react";
import { FormNumberInputElementVO, FormNumberResponseVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { FormSubmissionResponses } from "components/PatientProfile/Forms/types";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";

export const NumberInput: FC<{
  element: FormNumberInputElementVO;
  response?: FormNumberResponseVO;
  onUpdate?: (updater: (last: FormSubmissionResponses) => void) => void;
}> = ({ element, onUpdate, response }) => {
  const edit = Boolean(onUpdate);
  const handleChange = (num: number | undefined) => {
    onUpdate?.((currentResponses) => {
      currentResponses[element.uuid] = {
        type: "NUMBER",
        response: num,
      };
    });
  };

  const acceptedValues =
    isDefined(element.min) && isDefined(element.max)
      ? `Accepted Range: ${element.min} - ${element.max}`
      : isDefined(element.min)
        ? `Min value: ${element.min}`
        : isDefined(element.max)
          ? `Max value: ${element.max}`
          : "";

  return (
    <FormFieldNumberInput
      edit={edit}
      clamp
      max={element.max}
      min={element.min}
      label={
        <div className="flex gap-x-2 justify-between items-end w-full">
          <div>{element.title}</div>{" "}
          {acceptedValues ? <span className="text-greyLight inline-block">{acceptedValues}</span> : null}
        </div>
      }
      value={response?.response}
      onValueChange={handleChange}
    />
  );
};
