import { RecurringScheduleVO } from "@libs/api/generated-api";
import { isSameArraySet } from "utils/isSameArraySet";

// eslint-disable-next-line complexity
export const isSameRecurrence = (
  recurrenceA: RecurringScheduleVO | undefined,
  recurrenceB: RecurringScheduleVO | undefined
) => {
  if (!recurrenceA && !recurrenceB) {
    return true;
  }

  if (!recurrenceB || !recurrenceA) {
    return false;
  }

  return (
    recurrenceA.recurrenceType === recurrenceB.recurrenceType &&
    recurrenceA.every === recurrenceB.every &&
    recurrenceA.endDate === recurrenceB.endDate &&
    isSameArraySet(recurrenceA.daysOfMonth, recurrenceB.daysOfMonth) &&
    isSameArraySet(recurrenceA.daysOfYear, recurrenceB.daysOfYear) &&
    isSameArraySet(recurrenceA.daysOfWeek, recurrenceB.daysOfWeek) &&
    isSameArraySet(recurrenceA.ordinals, recurrenceB.ordinals)
  );
};
