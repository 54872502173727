import { PatientCallCardVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { Avatar } from "components/UI/Avatar";

interface Props {
  isHovering: boolean;
  onDismissPtCard: Func;
  patientCallCard: PatientCallCardVO | undefined;
  patientName: string;
}

export const PatientCardProfileImage: React.FC<Props> = ({
  isHovering,
  onDismissPtCard,
  patientCallCard,
  patientName,
}) => {
  return (
    <div className="relative h-full">
      <button
        className={cx(
          isHovering
            ? "absolute top-3 -left-4 flex items-center justify-center w-6 h-6 bg-white rounded-full border border-greyLighter z-10"
            : "hidden"
        )}
        onClick={onDismissPtCard}
        type="button"
      >
        <Icon
          SvgIcon={CloseIcon}
          size="sm"
          theme="info"
          tooltip={{
            theme: "SMALL",
            content: "Dismiss",
          }}
        />
      </button>
      <Avatar imageUrl={patientCallCard?.summary.thumbnail} name={patientName} size="lg" />
    </div>
  );
};
