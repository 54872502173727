import { useCallback, useEffect, useState } from "react";
import { produce } from "immer";
import { addMonths, secondsToMilliseconds } from "date-fns";
import { useDebouncedCallback } from "use-debounce";
import {
  CreateAppointmentAdjustmentRequest,
  CreateInvoiceRequest,
  InvoiceEntryVO,
  InvoiceVO,
  PatientVO,
} from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { maxLength, required } from "@libs/utils/validators";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import {
  generateInvoicePayload,
  invoiceEntryOrLineItemToId,
} from "components/PatientProfile/Billing/invoiceUtils";
import { AdjustmentRequestWithDisplayValue } from "components/PatientProfile/Billing/InvoiceAdjustmentFormRow";
import { NOTE_MAX_LENGTH } from "components/PatientProfile/Billing/FormComponents";
import { createInvoice } from "api/billing/mutations";

export type AdjustmentsByEntityId = Partial<{
  [entityId: string]: AdjustmentRequestWithDisplayValue[];
}>;

export const useInvoicePreview = ({
  practiceId,
  patientId,
  invoiceableEntries,
  onError,
  onSuccess,
}: {
  practiceId: number;
  patientId: PatientVO["id"];
  invoiceableEntries?: InvoiceEntryVO[];
  onError: (error: unknown) => void;
  onSuccess: (invoiceUuid: InvoiceVO["uuid"]) => void;
}) => {
  const [{ isLoading, mutateAsync: createInvoiceAsync }] = useApiMutations([createInvoice]);
  const [invoiceDraft, setInvoiceDraft] = useState<InvoiceVO>();
  const [proposedEntries, setProposedEntries] = useState<InvoiceEntryVO[]>();
  const [proposedAdjustments, setProposedAdjustments] = useState<AdjustmentsByEntityId>({});
  const [invoiceDueDate, setInvoiceDueDate] = useState<Date | null>(() => addMonths(new Date(), 1));
  const [invoiceNote, setInvoiceNote] = useState("");

  const validation = useValidation(
    { invoiceNote, invoiceDueDate },
    {
      invoiceNote: {
        $validations: [
          {
            $v: maxLength(NOTE_MAX_LENGTH),
            $error: `Note must be less than ${NOTE_MAX_LENGTH} characters`,
          },
        ],
      },
      invoiceDueDate: {
        $validations: [
          {
            $v: required,
            $error: `A due date is required`,
          },
        ],
      },
    }
  );

  const submitInvoicePayload = useCallback(
    async (payload: CreateInvoiceRequest) => {
      try {
        const response = await createInvoiceAsync({ practiceId, data: payload });

        setInvoiceDraft(response.data.data);

        if (payload.commit) {
          onSuccess(response.data.data.uuid);
        }
      } catch (err) {
        onError(err);
      }
    },
    [createInvoiceAsync, onError, onSuccess, practiceId]
  );

  const preview = useCallback(
    (entries: InvoiceEntryVO[], appointmentAdjustments: CreateAppointmentAdjustmentRequest[]) => {
      if (!entries.length) {
        return;
      }

      const invoicePayload = generateInvoicePayload({
        // TODO: remove unnecessary inputs from `generateInvoicePayload()` after we
        // deprecate the original invoice creation flow.
        selectedEntries: new Set(entries.map((entry) => invoiceEntryOrLineItemToId(entry))),
        amountAdjustments: {},
        patientId,
        appointmentAdjustments,
        commit: false,
        invoiceableEntries: entries,
      });

      submitInvoicePayload(invoicePayload);
    },
    [patientId, submitInvoicePayload]
  );

  const debouncedPreview = useDebouncedCallback(preview, secondsToMilliseconds(1));

  const create = useCallback(
    ({
      entries,
      appointmentAdjustments,
      note,
      dueDate,
    }: {
      entries: InvoiceEntryVO[];
      appointmentAdjustments: CreateAppointmentAdjustmentRequest[];
      note: string;
      dueDate: Date;
    }) => {
      if (!entries.length) {
        return;
      }

      const invoicePayload = generateInvoicePayload({
        // TODO: remove unnecessary inputs from `generateInvoicePayload()` after we
        // deprecate the original invoice creation flow.
        selectedEntries: new Set(entries.map((entry) => invoiceEntryOrLineItemToId(entry))),
        amountAdjustments: {},
        patientId,
        commit: true,
        invoiceableEntries: entries,
        appointmentAdjustments,
        note,
        dueDate,
      });

      submitInvoicePayload(invoicePayload);
    },
    [patientId, submitInvoicePayload]
  );

  const handleRemoveEntry = useCallback(
    (entryToRemove: InvoiceEntryVO) => {
      const newEntries = proposedEntries?.filter(
        (oldEntry) => invoiceEntryOrLineItemToId(oldEntry) !== invoiceEntryOrLineItemToId(entryToRemove)
      );

      setProposedEntries(newEntries);
      // Use `debouncedPreview()` so we don't call `preview()` on every
      // consecutive entry removal.
      newEntries && debouncedPreview(newEntries, flattenAdjustments(proposedAdjustments));
    },
    [debouncedPreview, proposedAdjustments, proposedEntries]
  );

  const handleAddAdjustment = useCallback(
    (entry: InvoiceEntryVO, newAdjustment: AdjustmentRequestWithDisplayValue) => {
      const newAdjustments = produce(proposedAdjustments, (draft) => {
        const entryId = invoiceEntryOrLineItemToId(entry);

        if (entryId in draft) {
          (draft[entryId] ?? []).push(newAdjustment);
        } else {
          draft[entryId] = [newAdjustment];
        }
      });

      setProposedAdjustments(newAdjustments);
      proposedEntries && preview(proposedEntries, flattenAdjustments(newAdjustments));
    },
    [preview, proposedAdjustments, proposedEntries]
  );

  const handleRemoveAdjustment = useCallback(
    (entry: InvoiceEntryVO, adjustmentIndexToRemove: number) => {
      const newAdjustments = produce(proposedAdjustments, (draft) => {
        const entryId = invoiceEntryOrLineItemToId(entry);

        draft[entryId] = (draft[entryId] ?? []).filter((_, i) => {
          return i !== adjustmentIndexToRemove;
        });
      });

      setProposedAdjustments(newAdjustments);
      proposedEntries && preview(proposedEntries, flattenAdjustments(newAdjustments));
    },
    [preview, proposedAdjustments, proposedEntries]
  );

  const submitInvoiceCreation = useCallback(() => {
    const result = validation.validate();

    if (!result.$isValid) {
      return;
    }

    proposedEntries &&
      invoiceDueDate &&
      create({
        entries: proposedEntries,
        appointmentAdjustments: flattenAdjustments(proposedAdjustments),
        note: invoiceNote,
        dueDate: invoiceDueDate,
      });
  }, [create, invoiceDueDate, invoiceNote, proposedAdjustments, proposedEntries, validation]);

  // Initialize first invoice calculation.
  useEffect(() => {
    if (invoiceableEntries != null && proposedEntries == null) {
      setProposedEntries(invoiceableEntries);
      preview(invoiceableEntries, flattenAdjustments(proposedAdjustments));
    }
  }, [invoiceableEntries, preview, proposedAdjustments, proposedEntries]);

  return {
    invoiceDraft,
    proposedEntries,
    proposedAdjustments,
    invoiceDueDate,
    invoiceNote,
    isLoading,
    createInvoice: submitInvoiceCreation,
    handleRemoveEntry,
    handleAddAdjustment,
    handleRemoveAdjustment,
    handleDueDateChange: setInvoiceDueDate,
    handleNoteChange: setInvoiceNote,
    validation,
  };
};

const flattenAdjustments = (adjustments: AdjustmentsByEntityId) =>
  Object.values(adjustments).flat() as AdjustmentRequestWithDisplayValue[];
