import React, { useCallback, useEffect, useMemo, useState } from "react";
import { ReactSortable } from "react-sortablejs";
import { AppointmentCategoryRequest, AppointmentCategoryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Icon } from "@libs/components/UI/Icon";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as PlusCircle } from "@libs/assets/icons/plus-circle.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { HeaderCell, TableGrid, cxGridTableStyles } from "@libs/components/UI/GridTableComponents";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { handleError } from "utils/handleError";
import { getAppointmentCategoriesQuery } from "api/scheduling/queries";
import { upsertAppointmentCategories } from "api/scheduling/mutations";
import { useItemModal } from "hooks/useItemModal";
import { AppointmentCategoryFlyover } from "components/Settings/Scheduling/Sections/AppointmentCategories/AppointmentCategoryFlyover";
import { getDentalProceduresQuery } from "api/charting/queries";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { AppointmentCategoryRow } from "components/Settings/Scheduling/Sections/AppointmentCategories/AppointmentCategoryRow";
import { convertToAppointmentCategoryRequest } from "components/Settings/Scheduling/Sections/AppointmentCategories/utils";

const headers = [
  { id: "draggable", width: "40px" },
  { id: "color", label: "Color", width: "64px" },
  { id: "category", label: "Category Name", width: "224px" },
  { id: "procedures", label: "Procedures", width: "minmax(8rem, 1fr)" },
  { id: "providers", label: "Providers", width: "minmax(10rem, 1fr)" },
  { id: "duration", label: "Duration", width: "96px" },
  { id: "online-booking", label: "Online Booking", width: "144px" },
  { id: "edit", width: "52px" },
];

export const AppointmentCategories: React.FC = () => {
  const { practiceId } = useAccount();

  const [dragItem, setDragItem] = useState<AppointmentCategoryVO>();

  // Undefined represents add mode.
  const editModal = useItemModal<AppointmentCategoryVO | undefined | null>(null);

  const [appointmentCategoriesQuery, { data: dentalProcedures }, providersQuery] = useApiQueries([
    getAppointmentCategoriesQuery({ args: { practiceId } }),
    getDentalProceduresQuery({ args: { practiceId } }),
    getPracticeProvidersQuery({ args: { practiceId, statuses: ["ACTIVE", "PENDING"] } }),
  ]);

  const [saveAppointmentCategory, updateAppointmentCategories] = useApiMutations([
    upsertAppointmentCategories,
    upsertAppointmentCategories,
  ]);

  const [appointmentCategories, setAppointmentCategories] = useState<AppointmentCategoryVO[]>(
    appointmentCategoriesQuery.data || []
  );

  useEffect(() => {
    if (appointmentCategoriesQuery.data) {
      setAppointmentCategories(appointmentCategoriesQuery.data);
    }
  }, [appointmentCategoriesQuery.data]);

  const handleSave = useCallback(
    (data: AppointmentCategoryRequest[]) => {
      updateAppointmentCategories.mutate(
        { data, practiceId },
        {
          onSuccess: editModal.close,
          onError: handleError,
        }
      );
    },
    [editModal.close, practiceId, updateAppointmentCategories]
  );

  const saveAppointmentCategoryMutate = saveAppointmentCategory.mutate;
  const handleSaveCategory = useCallback(
    (category: AppointmentCategoryRequest) => {
      let existingCategories = appointmentCategories;

      if (category.id == null) {
        // Make the category show up at the end.
        category.order = existingCategories.length;
      } else {
        existingCategories = appointmentCategories.filter(
          (existingCategory) => existingCategory.id !== category.id
        );
      }

      const categoryRequests = existingCategories.map((existingCategory) =>
        convertToAppointmentCategoryRequest(existingCategory)
      );

      saveAppointmentCategoryMutate(
        { data: [...categoryRequests, category], practiceId },
        {
          onSuccess: editModal.close,
          onError: handleError,
        }
      );
    },
    [appointmentCategories, practiceId, editModal.close, saveAppointmentCategoryMutate]
  );

  const handleDeleteCategory = useCallback(
    (categoryToDelete: AppointmentCategoryVO) => {
      const requests = appointmentCategories
        .filter((existingCategory) => existingCategory.id !== categoryToDelete.id)
        .map((category) => convertToAppointmentCategoryRequest(category));

      handleSave(requests);
    },
    [appointmentCategories, handleSave]
  );

  const handleDragEnd = useCallback(
    (oldIndex?: number, newIndex?: number) => {
      setDragItem(undefined);

      if (oldIndex == null || newIndex == null || oldIndex === newIndex) {
        return;
      }

      const reorderedCategories = [...appointmentCategories];
      const [removed] = reorderedCategories.splice(oldIndex, 1);

      reorderedCategories.splice(newIndex, 0, removed);

      const orderedRequests = reorderedCategories.map((category, index) =>
        convertToAppointmentCategoryRequest(category, { order: index })
      );

      handleSave(orderedRequests);
    },
    [appointmentCategories, handleSave]
  );

  const headerWidths = useMemo(() => headers.map(({ width }) => width), []);

  return (
    <div className="flex flex-col h-full">
      <TableGrid columnWidths={headerWidths}>
        {headers.map((item) => (
          <HeaderCell key={item.id}>{item.label}</HeaderCell>
        ))}
      </TableGrid>
      <QueryResult
        loading={
          <div className="h-full">
            <LoadingContent />
          </div>
        }
        queries={[appointmentCategoriesQuery, providersQuery]}
      >
        <ReactSortable
          animation={150}
          list={appointmentCategories}
          setList={setAppointmentCategories}
          handle=".drag-handle"
          onEnd={(e) => handleDragEnd(e.oldDraggableIndex, e.newDraggableIndex)}
          onStart={(e) =>
            setDragItem(e.oldIndex === undefined ? undefined : appointmentCategories[e.oldIndex])
          }
        >
          {appointmentCategories.map((category) => (
            <TableGrid
              key={category.id}
              columnWidths={headerWidths}
              className={dragItem && dragItem.id === category.id ? "invisible" : undefined}
            >
              <AppointmentCategoryRow
                category={category}
                onEdit={editModal.open}
                onDelete={handleDeleteCategory}
              />
            </TableGrid>
          ))}
        </ReactSortable>
        <div className="border-b">
          <Button
            theme="link"
            disabled={appointmentCategoriesQuery.isLoading}
            className={cx("group", cxGridTableStyles.cellPadding())}
            onClick={() => editModal.open(undefined)}
          >
            <div className="flex items-center gap-x-1 text-xs/4">
              <Icon
                SvgIcon={PlusCircle}
                className="group-hover:opacity-70 -ml-0.5 mr-2"
                theme="primary"
                disabled={appointmentCategoriesQuery.isLoading}
              />
              Category
            </div>
          </Button>
        </div>
      </QueryResult>
      {editModal.isOpen &&
        editModal.item !== null &&
        dentalProcedures != null &&
        providersQuery.data != null && (
          <AppointmentCategoryFlyover
            existingCategory={editModal.item}
            dentalProcedures={dentalProcedures}
            providers={providersQuery.data}
            onClose={editModal.close}
            isSaving={saveAppointmentCategory.isLoading}
            onSave={handleSaveCategory}
          />
        )}
    </div>
  );
};
