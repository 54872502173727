import { FC, PropsWithChildren } from "react";
import { cx } from "@libs/utils/cx";

const cxStyles = {
  text: {
    color: {
      green: "text-greenDark",
      orange: "text-orange",
      red: "text-redDark",
      greyMedium: "text-greyMedium",
      primary: "text-primaryTheme",
      slate500: "text-slate-500",
    },
    background: { orange: "bg-orangeLight", red: "bg-redLight", green: "bg-greenLight" },
    size: {
      xxs: "text-xxs",
      xs: "text-xs",
      sm: "text-sm",
      md: "text-base",
      lg: "text-lg",
      xl: "text-xl",
    },
    justify: {
      left: "text-left",
      right: "text-right",
      center: "text-center",
    },
  },
};

export const Text: FC<
  PropsWithChildren<{
    bold?: boolean;
    color?: keyof typeof cxStyles.text.color;
    background?: keyof typeof cxStyles.text.background;
    size?: keyof typeof cxStyles.text.size;
    breakAnywhere?: boolean;
    truncate?: boolean;
    justify?: keyof typeof cxStyles.text.justify;
  }>
> = ({ bold, color, size = "xs", background, breakAnywhere, truncate, justify, children }) => {
  return (
    <span
      className={cx(
        truncate && "truncate",
        breakAnywhere && "[overflow-wrap:anywhere]",
        cxStyles.text.size[size],
        bold && "font-sansSemiBold",
        color && cxStyles.text.color[color],
        background && cxStyles.text.background[background],
        justify && cxStyles.text.justify[justify]
      )}
    >
      {children}
    </span>
  );
};
