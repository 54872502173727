import { FC, useId, useState } from "react";
import { TemplateVariableGroupVO } from "@libs/api/generated-api";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { TemplateVariablesEditor } from "components/UI/RichTextEditor/TemplateVariablesEditor";
import { handleError } from "utils/handleError";
import {
  templateStringToTemplateVariableNodes,
  templateVariablesToString,
} from "components/UI/RichTextEditor/templateVariables";

const config = {
  editable: true,
  namespace: "EmailSubjectEditor",
  onError: handleError,
};

interface Props {
  subject: string | undefined;
  templateVariables: TemplateVariableGroupVO[];
  onSubjectChange: (subject: string) => void;
  error: string | undefined;
}

export const EmailSubjectEditor: FC<Props> = ({ subject, templateVariables, onSubjectChange, error }) => {
  const [convertedSubject] = useState(() =>
    subject ? JSON.stringify(templateStringToTemplateVariableNodes(subject, templateVariables)) : subject
  );
  const errorId = useId();

  return (
    <>
      <TemplateVariablesEditor
        initialConfig={config}
        ariaLabel="Subject Line"
        aria-invalid={error ? "true" : undefined}
        aria-errormessage={error ? errorId : undefined}
        templateVariables={templateVariables}
        placeholder="Enter a subject line here..."
        initialEditorState={convertedSubject}
        className="h-full"
        multiline={false}
        onChange={({ content }) => onSubjectChange(templateVariablesToString(content))}
      />
      {error ? (
        <FormFieldError className="py-1" id={errorId}>
          {error}
        </FormFieldError>
      ) : null}
    </>
  );
};
