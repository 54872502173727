import { FC, useMemo, useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { PatientListRequest } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";

import { Campaign } from "components/Communications/Campaigns/Campaign/Campaign";

import { hasEmptyFilters } from "components/Communications/Filters/utils";

import { getMessageCampaign, getPatientEngagementTemplateVariables } from "api/communications/queries";
import { getAllInsuranceCarriersQuery } from "api/practiceInsurance/queries";
import { getInfinitePatientsV2 } from "api/patients/queries";

import { useNullablePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";

import { paths } from "utils/routing/paths";

export const CampaignRoute: FC = () => {
  const { practiceId } = useAccount();
  const navigate = useNavigate();
  const location = useLocation();

  const campaignParams = useNullablePathParams("campaign");
  const campaignUuid = campaignParams?.campaignUuid ?? "";

  const { query, updateQuery } = useQueryParams("campaign");
  const from = query.from ?? paths.communications();

  usePageTitle("Campaign");

  const [messageCampaignQuery, templateVariablesQuery, insuranceCarriersQuery] = useApiQueries([
    getMessageCampaign({
      args: { practiceId, messageCampaignUuid: campaignUuid },
      queryOptions: { enabled: Boolean(campaignParams) },
    }),
    getPatientEngagementTemplateVariables({
      args: {
        practiceId,
        category: "MESSAGE_CAMPAIGN",
        messageCampaignSelectionCategory: "PATIENTS",
      },
    }),
    getAllInsuranceCarriersQuery({ args: { practiceId } }),
  ]);

  const [messageCampaignSummaryQuery] = useApiQueries([
    getMessageCampaign({
      args: {
        practiceId,
        messageCampaignUuid: campaignUuid,
        query: { includeSummary: true },
      },
      queryOptions: {
        enabled: Boolean(messageCampaignQuery.data?.status === "SENT"),
      },
    }),
  ]);

  const patientListCriteria = useMemo(() => {
    if (
      messageCampaignQuery.data &&
      messageCampaignQuery.data.filter?.type === "PATIENT_LIST" &&
      messageCampaignQuery.data.filter.patientListCriteria
    ) {
      return messageCampaignQuery.data.filter.patientListCriteria;
    }

    return undefined;
  }, [messageCampaignQuery.data]);

  const hasNoFiltersApplied = useMemo(() => hasEmptyFilters(patientListCriteria), [patientListCriteria]);

  const patientsQueryArgs = useMemo(() => {
    if (patientListCriteria) {
      const { maxPageSize, pageNumber, pageSize: _, ...data } = patientListCriteria;

      return {
        maxPageSize,
        pageNumber,
        // Since We do not display any results on this page, and instead only
        // need to know the total count, we can can set page size to 1
        pageSize: 1,
        data,
      };
    }

    return {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      data: {} as PatientListRequest,
    };
  }, [patientListCriteria]);

  const patientsQuery = useInfiniteApiQuery(
    getInfinitePatientsV2({
      args: {
        practiceId,
        ...patientsQueryArgs,
      },
      queryOptions: {
        enabled: messageCampaignQuery.data && messageCampaignQuery.data.status !== "SENT",
      },
    })
  );

  const patientsFiltered = useMemo(
    () => (hasNoFiltersApplied ? 0 : getInfiniteQueryPagingDetails(patientsQuery.data)?.totalElements),
    [hasNoFiltersApplied, patientsQuery.data]
  );

  const handleRequestEmailTemplatePreviewUrl = (previewUrl: string) => {
    updateQuery("replaceIn", { previewUrl });
  };

  const handleRequestEditEmail = useCallback(() => {
    if (!messageCampaignQuery.data) {
      return;
    }

    const { uuid, emailTemplate } = messageCampaignQuery.data;
    const fullUrl = getFullUrl(location);

    if (emailTemplate?.json && emailTemplate.html) {
      navigate(paths.campaignEmail({ campaignUuid: uuid }, { from: fullUrl }));
    } else {
      navigate(paths.campaignTemplates({ campaignUuid: uuid }, { from: fullUrl }));
    }
  }, [messageCampaignQuery.data, navigate, location]);

  return (
    <QueryResult
      queries={[
        messageCampaignQuery,
        messageCampaignSummaryQuery,
        templateVariablesQuery,
        insuranceCarriersQuery,
      ]}
    >
      {messageCampaignQuery.data && templateVariablesQuery.data && insuranceCarriersQuery.data ? (
        <Campaign
          campaign={messageCampaignQuery.data}
          summary={messageCampaignSummaryQuery.data?.summary}
          templateVariables={templateVariablesQuery.data}
          patientListCriteria={patientListCriteria}
          patientsFiltered={patientsFiltered}
          insuranceCarriers={insuranceCarriersQuery.data}
          emailTemplatePreviewUrl={query.previewUrl}
          onRequestEmailTemplatePreviewUrl={handleRequestEmailTemplatePreviewUrl}
          onRequestEditEmail={handleRequestEditEmail}
          hasNoFiltersApplied={hasNoFiltersApplied}
          from={from}
        />
      ) : null}
    </QueryResult>
  );
};
