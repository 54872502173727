import { MedicalImageVO, MountVO } from "@libs/api/generated-api";
import React, { useCallback, useMemo } from "react";
import { produce } from "immer";
import { applyRotation } from "@libs/utils/math";
import { isDefined } from "@libs/utils/types";
import { ImagingToolbar } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar";
import { UseImageEditQueue } from "components/PatientProfile/Imaging/MountRoute/hooks/useImageEditQueue";
import { DEFAULT_TRANSFORMS } from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { ImageSharedToolbarProps } from "components/PatientProfile/Imaging/MountRoute/types";
import { RevertChangesModal } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/RevertChangesModal";
import { PearlMountFlyoverContent } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/PearlFlyover/PearlMountFlyoverContent";
import { SandboxTipsContent } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ImagingTipsContent";
import { useRevertChangesInSelectedImages } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/useRevertChangesInSelectedImages";
import { useImageListContext } from "components/PatientProfile/Imaging/shared/ImageListContext";

type Props = {
  selectedImageIds?: Set<number>;
  handleMountChanged: UseImageEditQueue["handleMountChanged"];
  mount: MountVO;
  patientId: number;
} & ImageSharedToolbarProps;

export const ImageSandboxToolbar: React.FC<Props> = ({
  handleMountChanged,
  selectedImageIds,
  mount,
  patientId,
  isOnboardedWithPearl,
}) => {
  const { layersShown, toggleLayer } = useImageListContext();
  const selectedImages = useMemo(() => {
    return mount.images?.filter((image) => selectedImageIds?.has(image.id ?? -1)) ?? [];
  }, [mount.images, selectedImageIds]);
  const handleImagesReverted = useCallback(
    (images: MedicalImageVO[]) => {
      return handleMountChanged({
        updates: {
          images: mount.images?.map((mountImage) => {
            const updatedImage = images.find((image) => image.id === mountImage.id);

            return updatedImage ?? mountImage;
          }),
        },
      });
    },
    [handleMountChanged, mount.images]
  );
  const { handleRevertChanges, revertChangesModal } = useRevertChangesInSelectedImages({
    handleImagesReverted,
    selectedImages,
  });
  const hasImagesSelected = (selectedImageIds?.size ?? 0) === 0;
  const toolsDisabled = useMemo(
    () =>
      new Set(
        [
          "draw" as const,
          "filter" as const,
          hasImagesSelected ? ("transform" as const) : undefined,
          hasImagesSelected ? ("revert" as const) : undefined,
        ].filter(isDefined)
      ),
    [hasImagesSelected]
  );
  const applyToSelectedImages = React.useCallback(
    (makeUpdates: (changes: MedicalImageVO) => MedicalImageVO) => {
      handleMountChanged({
        updates: {
          images: mount.images?.map((image: MedicalImageVO) => {
            if (selectedImageIds?.has(image.id ?? -1)) {
              const updates = makeUpdates(image);

              return updates;
            }

            return image;
          }),
        },
      });
    },
    [handleMountChanged, mount.images, selectedImageIds]
  );

  const applyRotationToMirror = React.useCallback((image: MedicalImageVO) => {
    const mirror = image.transforms?.mirror ?? [];

    return mirror.length > 0 ? (mirror.includes("X") ? ["Y"] : ["X"]) : mirror;
  }, []);
  const onRotate = React.useCallback(
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    (degrees: 270 | 90) => {
      applyToSelectedImages((image) => {
        return produce(image, (draft) => {
          draft.transforms = {
            mirror: applyRotationToMirror(image),
            rotationDegrees: applyRotation(degrees, image.transforms?.rotationDegrees),
          };
        });
      });
    },
    [applyToSelectedImages, applyRotationToMirror]
  );

  const onMirror = React.useCallback(
    (axis: "X" | "Y") => {
      applyToSelectedImages((image) => {
        return produce(image, (draft) => {
          const mirror = image.transforms?.mirror ?? [];

          draft.transforms = {
            ...DEFAULT_TRANSFORMS,
            ...image.transforms,
            // eslint-disable-next-line max-nested-callbacks
            mirror: mirror.includes(axis) ? mirror.filter((item) => item !== axis) : [...mirror, axis],
          };
        });
      });
    },
    [applyToSelectedImages]
  );

  const imagesForAI = useMemo(
    () =>
      mount.images?.filter(
        (image) =>
          (image.type === "X_RAY" || image.type === "EXTERNAL") &&
          !image.isArchived &&
          !image.pearlAnalysis?.errorMessage
      ) ?? [],
    [mount.images]
  );

  return (
    <>
      {revertChangesModal.isOn && (
        <RevertChangesModal
          onCancel={revertChangesModal.off}
          onConfirm={handleRevertChanges}
          imageCount={selectedImageIds?.size ?? 0}
        />
      )}
      <ImagingToolbar
        sectionsDisabled={toolsDisabled}
        disabledMessage="Open image to use"
        onMirror={onMirror}
        onRotate={onRotate}
        onToggleLayer={toggleLayer}
        layersShown={layersShown}
        imagesForAI={imagesForAI}
        isOnboardedWithPearl={isOnboardedWithPearl}
        onRevertChanges={revertChangesModal.on}
        pearlFlyoverContent={
          <PearlMountFlyoverContent
            selectedImageIds={selectedImageIds}
            images={imagesForAI}
            patientId={patientId}
            mountId={mount.id}
          />
        }
        tipsFlyoverContent={<SandboxTipsContent />}
      />
    </>
  );
};
