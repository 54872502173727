import { FC, useMemo } from "react";
import { QueryFilterPillItems } from "@libs/components/UI/QueryFilterPills";
import { getPatientProceduresFilterProps } from "components/Charting/patientProceduresFilterPills";
import { ChartingQuery, ChartingQueryUpdates } from "utils/routing/charting";

export const PatientProceduresQueryFilterPills: FC<{
  query: ChartingQuery;
  onUpdate: (update: ChartingQueryUpdates) => void;
}> = ({ query, onUpdate }) => {
  const filterProps = useMemo(() => {
    return getPatientProceduresFilterProps(query);
  }, [query]);

  const handleUpdateFilter = (params: ChartingQueryUpdates) => {
    if ("patientProcedureStatuses" in params) {
      onUpdate({
        ...params,
        patientProcedureStatuses: params.patientProcedureStatuses ?? [],
      });
    } else {
      onUpdate(params);
    }
  };

  return filterProps.filters.length ? (
    <QueryFilterPillItems
      {...filterProps}
      clearAllText="Clear all filters"
      onClearAll={handleUpdateFilter}
      onUpdateParams={handleUpdateFilter}
    />
  ) : null;
};
