import { FC, useCallback } from "react";
import { CreateEmployeeRequest, EmployeeVO } from "@libs/api/generated-api";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { createEmployee } from "api/employee/mutations";
import { CreateEmployeeForm } from "components/Employee/CreateEmployeeForm";
import { handleError } from "utils/handleError";
import { getAssignableRolesForUser } from "api/user/queries";
import { getCompanyOnboardingStatus } from "api/payroll/queries";
import { getEmployeeJobTitlesV2 } from "api/employee/queries";

interface Props {
  onRequestClose: Func;
  onSave: (created: EmployeeVO) => void;
}

const FORM_ID = "create-employee-form";

export const CreateEmployeeFormContainer: FC<Props> = ({ onRequestClose, onSave }) => {
  const { practiceId, id } = useAccount();
  const [assignableRolesQuery, practicePayrollOnboardingQuery, jobTitlesQuery] = useApiQueries([
    getAssignableRolesForUser({ args: { practiceId, userId: id } }),
    getCompanyOnboardingStatus({ args: { practiceId } }),
    getEmployeeJobTitlesV2(),
  ]);
  const [{ mutate, isLoading }] = useApiMutations([createEmployee]);
  const handleSubmit = useCallback(
    (data: CreateEmployeeRequest) => {
      mutate(
        {
          practiceId,
          data,
        },
        {
          onError: (err) => handleError(err),
          onSuccess: (response) => onSave(response.data.data),
        }
      );
    },
    [mutate, onSave, practiceId]
  );

  return (
    <QueryResult queries={[assignableRolesQuery, practicePayrollOnboardingQuery, jobTitlesQuery]}>
      {assignableRolesQuery.data && practicePayrollOnboardingQuery.data && jobTitlesQuery.data ? (
        <CreateEmployeeForm
          onRequestClose={onRequestClose}
          onSubmit={handleSubmit}
          formId={FORM_ID}
          isSaving={isLoading}
          isPayrollSetup={practicePayrollOnboardingQuery.data.status !== "NOT_SETUP"}
          assignableRoles={assignableRolesQuery.data}
          jobTitles={jobTitlesQuery.data}
        />
      ) : null}
    </QueryResult>
  );
};
