import { FC, ChangeEventHandler, useMemo, useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AppointmentVO, WorkingHourItemVO } from "@libs/api/generated-api";
import { abbreviatedDayOfWeek, formatISODate, formatISOTimeRangeAsAmPm } from "@libs/utils/date";
import { getFullUrl } from "@libs/utils/location";
import { useBoolean } from "@libs/hooks/useBoolean";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Icon } from "@libs/components/UI/Icon";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as MessageIcon } from "@libs/assets/icons/messages.svg";
import { ReactComponent as NoteIcon } from "@libs/assets/icons/note.svg";
import { ReactComponent as MenuVerticalIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { Pill } from "@libs/components/UI/Pill";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import {
  ButtonCell,
  ColumnSortIndicator,
  EMPTY_CELL,
  HeaderCheckboxCell,
  HeaderButtonCell,
  Row,
  CheckboxCell,
  IconsCell,
} from "@libs/components/UI/GridTableComponents";
import { HeaderData } from "components/Dashboard/Tables/types";
import { paths } from "utils/routing/paths";
import { isEmptyTime, isUnscheduledAsapAppt } from "components/ScheduleAppointments/utils";
import { useScheduleLinks } from "components/ScheduleAppointments/ScheduleLinksContext";

export type SortParams = {
  direction: "ASCENDING" | "DESCENDING";
  field: "date" | "duration";
};

type ApptRowProps = {
  appointment: AppointmentVO;
  currentAppointmentId?: number;
  checked: boolean;
  onCheckboxChange: ChangeEventHandler<HTMLInputElement>;
  onRowClick: (appointment: AppointmentVO) => void;
  onRowDelete: (appointment: AppointmentVO) => void;
  deleteDisabled: boolean;
  workingDaysOfWeek: string[];
};

// eslint-disable-next-line complexity
const AppointmentRow: FC<ApptRowProps> = ({
  appointment,
  currentAppointmentId,
  checked,
  onCheckboxChange,
  onRowClick,
  onRowDelete,
  deleteDisabled,
  workingDaysOfWeek,
}) => {
  const { preference, id, duration, patient, date, startTime, endTime, patientProcedures } = appointment;
  const daysPreferred = preference?.daysPreferred ?? [];
  const [preferenceTimeRange] = preference?.timeRanges ?? [undefined];
  const providers = preference?.providers ?? [];
  const isUnscheduled = isUnscheduledAsapAppt(date);
  const navigate = useNavigate();
  const location = useLocation();
  const menu = useBoolean(false);
  const menuOff = menu.off;

  const menuOptions = useMemo(() => {
    return createMenuOptions(
      {
        SvgIcon: MessageIcon,
        label: "Text Patient",
        value: "message",
      },
      {
        SvgIcon: EditIcon,
        label: "Edit Request",
        value: "edit",
      },
      {
        SvgIcon: DeleteIcon,
        label: "Delete",
        value: "delete",
        tooltip: {
          content: deleteDisabled ? "Uncheck rows to enable" : "Remove from ASAP List",
          theme: "SMALL",
        },
        disabled: deleteDisabled,
      }
    );
  }, [deleteDisabled]);

  const handleRowClick = useCallback(() => {
    onRowClick(appointment);
  }, [onRowClick, appointment]);

  const handleRowDelete = useCallback(() => {
    onRowDelete(appointment);
  }, [appointment, onRowDelete]);

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "message": {
          navigate(paths.messaging({ patientId: patient.id }));
          break;
        }
        case "edit": {
          navigate(
            paths.editAppointment(
              { appointmentId: id, patientId: patient.id },
              { from: getFullUrl(location) }
            )
          );
          break;
        }
        case "delete": {
          handleRowDelete();
          break;
        }
        default: {
          break;
        }
      }

      menuOff();
    },
    [navigate, location, patient.id, id, handleRowDelete, menuOff]
  );

  const baseProps = {
    className: "flex items-center gap-2 flex-wrap min-h-10",
    onClick: handleRowClick,
    verticalPadding: "slim" as const,
  };

  return (
    <Row highlightOnHover isSelected={currentAppointmentId === id || checked}>
      <CheckboxCell value={id} checked={checked} onChange={onCheckboxChange} />
      <ButtonCell {...baseProps} className="flex flex-col justify-center gap-2">
        {isUnscheduled
          ? "Unscheduled"
          : `${formatISODate(date)}, ${formatISOTimeRangeAsAmPm(startTime, endTime)}`}
      </ButtonCell>
      <ButtonCell {...baseProps}>{duration ? `${duration} Min` : EMPTY_CELL}</ButtonCell>
      <ButtonCell {...baseProps}>{patient.shortDisplayName}</ButtonCell>
      <ButtonCell {...baseProps}>
        {daysPreferred.length > 0
          ? daysPreferred.map((day) => {
              return <div key={day}>{abbreviatedDayOfWeek(day)}</div>;
            })
          : workingDaysOfWeek.join(", ")}
      </ButtonCell>
      <ButtonCell {...baseProps}>
        {!preferenceTimeRange || isEmptyTime(preferenceTimeRange.startTime, preferenceTimeRange.endTime)
          ? "No preference"
          : formatISOTimeRangeAsAmPm(preferenceTimeRange.startTime, preferenceTimeRange.endTime)}
      </ButtonCell>
      <ButtonCell {...baseProps} truncate={false}>
        {providers.length > 0
          ? providers.map((item) => item.name.shortDisplayName).join(", ")
          : "No preference"}
      </ButtonCell>
      <ButtonCell {...baseProps}>
        {patientProcedures.map((item) => (
          <FloatingTooltip key={item.id} content={item.description}>
            <Pill>{item.displayName}</Pill>
          </FloatingTooltip>
        ))}
      </ButtonCell>
      <ButtonCell {...baseProps}>
        {preference?.comment && (
          <Icon SvgIcon={NoteIcon} tooltip={{ content: preference.comment, theme: "MEDIUM" }} />
        )}
      </ButtonCell>
      <IconsCell>
        <ButtonMenu
          isOpen={menu.isOn}
          onRequestOpen={menu.on}
          onRequestClose={menu.off}
          placement="left-start"
          menuContent={
            <div className="w-48">
              <MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />
            </div>
          }
        >
          {(props) => <ButtonIcon {...props} SvgIcon={MenuVerticalIcon} />}
        </ButtonMenu>
      </IconsCell>
    </Row>
  );
};

type HeaderProps = {
  header: HeaderData;
  onSortToggled?: (field: SortParams["field"]) => void;
  sortDirection?: "ASCENDING" | "DESCENDING";
};

const HeaderRow: FC<HeaderProps> = ({ header, onSortToggled, sortDirection }) => {
  return (
    <HeaderButtonCell
      onClick={() => {
        if (header.sortField && onSortToggled) {
          onSortToggled(header.sortField as SortParams["field"]);
        }
      }}
      size="short"
    >
      {header.label}
      {header.sortField && <ColumnSortIndicator className="ml-2" direction={sortDirection} />}
    </HeaderButtonCell>
  );
};

type ListProps = {
  appointments: AppointmentVO[];
  currentAppointmentId?: number;
  headers: HeaderData[];
  sortParams: SortParams;
  selectedRows: Set<number>;
  selectedCount: number;
  totalRows: number;
  onSelectAllRows: Func;
  onDeselectAllRows: Func;
  onCheckboxChange: ApptRowProps["onCheckboxChange"];
  onRowClick: ApptRowProps["onRowClick"];
  onRowDelete: ApptRowProps["onRowDelete"];
  onSortToggled: HeaderProps["onSortToggled"];
  workingHours: WorkingHourItemVO[] | undefined;
};

const daysOfWeekSort: Record<string, number> = {
  mon: 0,
  tues: 1,
  wed: 2,
  thur: 3,
  fri: 4,
  sat: 5,
  sun: 7,
};

export const ScheduleAsapListRows: FC<ListProps> = ({
  appointments,
  currentAppointmentId,
  headers,
  sortParams,
  selectedRows,
  selectedCount,
  totalRows,
  onSelectAllRows,
  onDeselectAllRows,
  onCheckboxChange,
  onRowClick,
  onRowDelete,
  onSortToggled,
  workingHours,
}) => {
  const workingDaysOfWeek = useMemo(() => {
    return [...new Set(workingHours?.map((item) => abbreviatedDayOfWeek(item.dayOfWeek)) ?? [])].sort(
      (a, b) => daysOfWeekSort[a] - daysOfWeekSort[b]
    );
  }, [workingHours]);

  return (
    <>
      {headers.map((item, i) =>
        item.id === "checkbox" ? (
          <HeaderCheckboxCell
            key={item.id}
            selectedCount={selectedCount}
            totalRows={totalRows}
            onSelectAllRows={onSelectAllRows}
            onDeselectAllRows={onDeselectAllRows}
            size="short"
          />
        ) : (
          <HeaderRow
            key={i}
            header={item}
            onSortToggled={onSortToggled}
            sortDirection={item.sortField === sortParams.field ? sortParams.direction : undefined}
          />
        )
      )}
      {appointments.map((item) => {
        return (
          <AppointmentRow
            key={item.id}
            appointment={item}
            currentAppointmentId={currentAppointmentId}
            checked={selectedRows.has(item.id)}
            onCheckboxChange={onCheckboxChange}
            onRowClick={onRowClick}
            onRowDelete={onRowDelete}
            deleteDisabled={selectedCount > 0}
            workingDaysOfWeek={workingDaysOfWeek}
          />
        );
      })}
    </>
  );
};

export const EmptyRows: React.FC<{ type: "Requests" | "ASAP" }> = ({ type }) => {
  const { links } = useScheduleLinks();

  return (
    <div className="flex flex-col gap-6 items-center justify-center min-h-80">
      <div>No patients are currently on the {type} list</div>
      <ButtonInternalLink to={links.schedule}>Back to Schedule</ButtonInternalLink>
    </div>
  );
};
