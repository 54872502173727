import { FC, useCallback } from "react";

import { UpdateEmailConfigRequest } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";

import { SettingsPanel } from "components/Settings/SettingsPanel";
import { EmailAuthentication } from "components/Settings/Communications/EmailAuthentication";

import { getEmailConfig } from "api/settings/communications/queries";
import { updateEmailConfig, verifyDomain } from "api/settings/communications/mutations";

import { handleError } from "utils/handleError";

export const EmailAuthenticationRoute: FC = () => {
  const { practiceId } = useAccount();
  const [emailConfigQuery] = useApiQueries([getEmailConfig({ args: { practiceId } })]);

  const [updateEmailConfigMutation, verifyDomainMutation] = useApiMutations([
    updateEmailConfig,
    verifyDomain,
  ]);

  const updateConfigMutate = updateEmailConfigMutation.mutate;

  const handleGenerateDomain = useCallback(
    (domain: UpdateEmailConfigRequest["domain"]) => {
      updateConfigMutate(
        {
          practiceId,
          data: {
            domain,
            fromEmail:
              domain === emailConfigQuery.data?.domain ? emailConfigQuery.data?.fromEmail : undefined,
            replyToEmail: emailConfigQuery.data?.replyToEmail,
          },
        },
        {
          onError: handleError,
        }
      );
    },
    [
      emailConfigQuery.data?.fromEmail,
      emailConfigQuery.data?.replyToEmail,
      emailConfigQuery.data?.domain,
      updateConfigMutate,
      practiceId,
    ]
  );

  const handleResetDomain = useCallback(() => {
    updateConfigMutate(
      {
        practiceId,
        data: {
          domain: undefined,
          fromEmail: undefined,
          replyToEmail: emailConfigQuery.data?.replyToEmail,
        },
      },
      {
        onError: handleError,
      }
    );
  }, [updateConfigMutate, emailConfigQuery.data?.replyToEmail, practiceId]);

  const verifyDomainMutate = verifyDomainMutation.mutate;
  const handleVerifyDomain = useCallback(
    (checkingVerification: boolean) => {
      verifyDomainMutate(
        { practiceId, checkingVerification },
        {
          onError: handleError,
        }
      );
    },
    [verifyDomainMutate, practiceId]
  );

  return (
    <SettingsPanel title="Email Services">
      <QueryResult queries={[emailConfigQuery]}>
        {emailConfigQuery.data ? (
          <EmailAuthentication
            emailConfig={emailConfigQuery.data}
            onGenerateDomain={handleGenerateDomain}
            onVerifyDomain={handleVerifyDomain}
            onResetDomain={handleResetDomain}
            isResettingDomain={updateEmailConfigMutation.isLoading}
            isGeneratingDomain={updateEmailConfigMutation.isLoading}
            isVerifyingDomain={verifyDomainMutation.isLoading}
          />
        ) : null}
      </QueryResult>
    </SettingsPanel>
  );
};
