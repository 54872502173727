import {
  TemplateVariableGroupVO,
  TemplateVariableVO,
  MessageCampaignFilter,
  MessageCampaignPatientCriteria,
  SendMessageCampaignRequest,
  CreateStatementRequest,
} from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { required, maxLength, containsString } from "@libs/utils/validators";
import { unflattenKeys } from "@libs/utils/object";
import { formatAsISODate } from "@libs/utils/date";

import {
  MessageCampaignFilterType,
  MessageCampaignFilterCriteria,
  SendMessageCampaignDraft,
  MessageView,
} from "components/Communications/MessagePatients/types";

import { MAX_SMS_LENGTH, validateTemplateVariables } from "components/Communications/utils";

export const MAX_SMS_SEND_COUNT = 200;
export const MAX_EMAIL_SEND_COUNT = 10_000;

const DEFAULT_OUTSTANDING_BALANCE_PAYMENT_LINK_TEMPLATE =
  "You have an outstanding balance with {{PRACTICE_NAME}}. Click here to pay: {{PAYMENT_LINK}}";
const DEFAULT_OUTSTANDING_BALANCE_TEMPLATE =
  "You have an outstanding balance with {{PRACTICE_NAME}}. Contact us at {{PRACTICE_PHONE_NUMBER}} to pay.";
const DEFAULT_OUTSTANDING_BALANCE_SUBJECT = "You have an outstanding balance with {{PRACTICE_NAME}}";

export const getInitialMessageCampaignDraft = ({
  canSendSms,
  canSendEmail,
  canGenerateStatements,
  onboardedWithPaymentProvider,
}: {
  canSendSms: boolean;
  canSendEmail: boolean;
  canGenerateStatements: boolean;
  onboardedWithPaymentProvider: boolean;
}): SendMessageCampaignDraft => {
  const initialTemplate = canGenerateStatements
    ? onboardedWithPaymentProvider
      ? DEFAULT_OUTSTANDING_BALANCE_PAYMENT_LINK_TEMPLATE
      : DEFAULT_OUTSTANDING_BALANCE_TEMPLATE
    : undefined;

  return {
    channels: [
      canSendSms && canSendEmail ? "PATIENT_PREFERRED" : !canSendSms && canSendEmail ? "EMAIL" : "SMS",
    ],
    smsTemplate: initialTemplate,
    emailTemplate: initialTemplate,
    subject: canGenerateStatements ? DEFAULT_OUTSTANDING_BALANCE_SUBJECT : undefined,
    statementDateRange: {
      startDate: null,
      endDate: null,
    },
  };
};

export const getInitialMessageView = ({
  canSendSms,
  canSendEmail,
}: {
  canSendSms: boolean;
  canSendEmail: boolean;
}): MessageView => (!canSendSms && canSendEmail ? "EMAIL" : "SMS");

export const filterTemplateVariables = (
  templateVariablesQuery: ApiQueryResult<TemplateVariableGroupVO[]>,
  options: {
    onboardedWithPaymentProvider: boolean;
  }
) => {
  const templateVariables = templateVariablesQuery.data ?? [];

  let noPaymentLink = templateVariables.map((group) => ({
    ...group,
    variables: group.variables.filter((variable) => variable.key !== "PAYMENT_LINK"),
  }));

  noPaymentLink = noPaymentLink.filter((group) => group.variables.length);

  return {
    content: {
      data: options.onboardedWithPaymentProvider ? templateVariables : noPaymentLink,
      isError: templateVariablesQuery.isError,
      isLoading: templateVariablesQuery.isLoading,
      isPreviousData: templateVariablesQuery.isPreviousData,
    },
    subject: {
      data: templateVariables.filter((group) => group.type !== "LINKS"),
      isError: templateVariablesQuery.isError,
      isLoading: templateVariablesQuery.isLoading,
      isPreviousData: templateVariablesQuery.isPreviousData,
    },
  };
};

export const getChannelsStatus = (channels: SendMessageCampaignDraft["channels"]) => {
  const channelsSet = new Set(channels);

  return {
    isSendingSms: channelsSet.has("SMS") || channelsSet.has("PATIENT_PREFERRED"),
    isSendingEmails: channelsSet.has("EMAIL") || channelsSet.has("PATIENT_PREFERRED"),
    isSendingMail: channelsSet.has("MAIL"),
  };
};

const validatePaymentLink = () => ({
  $v: containsString("{{PAYMENT_LINK}}"),
  $error: "A Payment Link is required to message patients with outstanding collections",
});

export const getSendMessageCampaignSchema = ({
  channels,
  hasCustomEmail,
  templateVariablesSets,
  onboardedWithPaymentProvider,
  canGenerateStatements,
}: {
  channels: SendMessageCampaignDraft["channels"];
  hasCustomEmail: boolean;
  onboardedWithPaymentProvider: boolean;
  templateVariablesSets: {
    content: Set<TemplateVariableVO["key"]>;
    subject: Set<TemplateVariableVO["key"]>;
  };
  canGenerateStatements: boolean;
}) => {
  const { isSendingSms, isSendingEmails } = getChannelsStatus(channels);

  return {
    smsTemplate: [
      {
        $v: required,
        $error: "A message is required for sending SMS",
        $ignore: !isSendingSms,
      },
      {
        $v: maxLength(MAX_SMS_LENGTH),
        $error: `SMS messages must be ${MAX_SMS_LENGTH} characters or less`,
      },
      {
        ...validatePaymentLink(),
        $ignore:
          !onboardedWithPaymentProvider ||
          !canGenerateStatements ||
          (channels.length === 1 && channels[0] === "EMAIL"),
      },
      validateTemplateVariables(templateVariablesSets.content),
    ],
    emailTemplate: [
      {
        $v: required,
        $error: "A message is required for sending email",
        $ignore: !isSendingEmails || hasCustomEmail,
      },
      {
        ...validatePaymentLink(),
        $ignore:
          !onboardedWithPaymentProvider ||
          !canGenerateStatements ||
          (channels.length === 1 && channels[0] === "SMS"),
      },
      validateTemplateVariables(templateVariablesSets.content),
    ],
    subject: [
      {
        $v: required,
        $error: "A subject is required for sending email",
        $ignore: !isSendingEmails,
      },
      validateTemplateVariables(templateVariablesSets.subject),
    ],
    statementDateRange: {
      startDate: [
        {
          $v: required,
          $error: "Start date is required",
          $ignore: !canGenerateStatements,
        },
      ],
      endDate: [
        {
          $v: required,
          $error: "End date is required",
          $ignore: !canGenerateStatements,
        },
      ],
    },
  };
};

const getIdsFromSet = (ids: Set<number>) => (ids.size > 0 ? [...ids] : undefined);

// Patient list criteria is a flat object with keys like
// "patientListCriteria.ageGroup.min" and is structured this way for our
// getPatients query and patients query params. However, the
// SendMessageCampaignRequest expects the patient list criteria to be structured
// as an unflattened object.
const getPatientListCriteria = (
  criteria: MessageCampaignFilterCriteria["PATIENT_LIST"]
): MessageCampaignPatientCriteria["patientListCriteria"] => unflattenKeys(criteria);

const getMessageCampaignFilter = <T extends MessageCampaignFilterType>(
  type: T,
  criteria: MessageCampaignFilterCriteria[T],
  selectedIds: Set<number>,
  deselectedIds: Set<number>,
  hasAllSelected: boolean
): SendMessageCampaignRequest["filter"] => {
  const filter: MessageCampaignFilter = {
    type,
    selectedIds: hasAllSelected ? undefined : getIdsFromSet(selectedIds),
    deselectedIds: getIdsFromSet(deselectedIds),
  };

  if (!filter.selectedIds) {
    switch (type) {
      case "PATIENT_LIST": {
        return {
          ...filter,
          patientListCriteria: getPatientListCriteria(
            criteria as MessageCampaignFilterCriteria["PATIENT_LIST"]
          ),
        };
      }
      case "DAILY_HUDDLE": {
        return {
          ...filter,
          dailyHuddleRequest: criteria as MessageCampaignFilterCriteria["DAILY_HUDDLE"],
        };
      }
      case "ASAP_LIST": {
        return {
          ...filter,
          practiceAppointmentListCriteria: criteria as MessageCampaignFilterCriteria["ASAP_LIST"],
        };
      }
      case "REPORTING_UNCOLLECTED": {
        return {
          ...filter,
          patientBalanceAgingRequest: criteria as MessageCampaignFilterCriteria["REPORTING_UNCOLLECTED"],
        };
      }
      case "REPORTING_RECALL": {
        return {
          ...filter,
          reportingRecallByPatientRequest: criteria as MessageCampaignFilterCriteria["REPORTING_RECALL"],
        };
      }
      default: {
        break;
      }
    }
  }

  return filter;
};

export const getSendMessageCampaignRequest = <S extends MessageCampaignFilterType>(
  type: S,
  criteria: MessageCampaignFilterCriteria[S],
  selectedIds: Set<number>,
  deselectedIds: Set<number>,
  hasAllSelected: boolean,
  messageCampaignDraft: SendMessageCampaignDraft
): SendMessageCampaignRequest => ({
  messageCampaignUuid: crypto.randomUUID(),
  filter: getMessageCampaignFilter(type, criteria, selectedIds, deselectedIds, hasAllSelected),
  channels: messageCampaignDraft.channels,
  smsTemplate: messageCampaignDraft.smsTemplate,
  emailTemplate: messageCampaignDraft.emailTemplate,
  subject: messageCampaignDraft.subject,
});

export const getCreateStatementRequest = (statementDateRange: {
  startDate: Date;
  endDate: Date;
}): CreateStatementRequest => ({
  startDate: formatAsISODate(statementDateRange.startDate),
  endDate: formatAsISODate(statementDateRange.endDate),
});
