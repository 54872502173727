import { QueryClient } from "@tanstack/react-query";

import { DosespotEntityVO, EmployeeVO, UpdateEmployeeRequest } from "@libs/api/generated-api";
import { updateCachedData } from "@libs/utils/queryCache";
import { getQueryKey } from "@libs/utils/queries";

export const getPrescriberCost = ({ isProxy, isFirst }: { isProxy: boolean; isFirst: boolean }) => {
  if (isFirst) {
    return "Included";
  }

  return isProxy ? "$30 / month" : "$55 / month";
};

export const getUpdateEmployeeRequest = (employee: EmployeeVO): UpdateEmployeeRequest => ({
  contactDetails: employee.contactDetails,
  personalDetails: employee.personalDetails,
  employmentDetails: employee.employmentDetails,
});

export const updateCacheWithCreatedDoseSpotEntity = (
  queryClient: QueryClient,
  practiceId: number,
  employee: EmployeeVO,
  isProxy: boolean
) => {
  updateCachedData<DosespotEntityVO[]>(
    queryClient,
    { queryKey: [getQueryKey("practices", "getPracticeDosespotEntities"), { practiceId }] },
    (cachedEntries) => {
      return [
        // If there are no DoseSpot entities in the cache when adding the first
        // employee entity, then we must also add a practice entity to the
        // cache, in order to indicate DoseSpot is now active
        ...(cachedEntries.length === 0
          ? [
              {
                id: practiceId,
                type: "PRACTICE" as const,
                name: "",
                isConfirmed: true,
                isProxy: false,
              },
            ]
          : []),
        ...cachedEntries,
        {
          id: employee.id,
          type: "EMPLOYEE" as const,
          name: employee.personalDetails.displayName ?? "",
          isConfirmed: false,
          isProxy,
        },
      ];
    }
  );
};
