import React, { useState, useEffect, useCallback, PropsWithChildren } from "react";
import { cx } from "@libs/utils/cx";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel-small.svg";

export type PearlFlyoverProps = PropsWithChildren<{
  onClose: () => void;
  dataTestId?: string;
  triggerClose?: boolean;
  CloseIcon?: IconComponent;
  title?: React.ReactNode;
  animationType?: "slide-in" | "flyover";
  closeButtonClassName?: string;
}>;

export const ImagingToolbarFlyoverMenu: React.FC<PearlFlyoverProps> = ({
  onClose,
  triggerClose,
  dataTestId,
  children,
  closeButtonClassName,
  animationType = "slide-in",
  title,
  CloseIcon: CloseIconProp,
}) => {
  const [show, setShow] = useState(false);
  const close = useCallback(() => setShow(false), []);

  useEffect(() => {
    setShow(true);
  }, []);

  useEffect(() => {
    if (triggerClose) {
      close();
    }
  }, [triggerClose, close]);

  return (
    <div
      className={cx(
        `transition-transform
         will-change-transform
         ease-out
         overflow-hidden
         shadow-[0px_0px_24px_0px_#0000003D]
         flex
         flex-col
         h-full
         bg-slate-700
         text-white
         w-64
         p-4
         text-left`,
        show ? "translate-x-0" : "-translate-x-6",
        animationType === "flyover" && "absolute top-26 left-14 z-10"
      )}
      data-testid={dataTestId}
    >
      {title}
      <div className="flex justify-end">
        <ButtonIcon
          className={closeButtonClassName}
          theme="darkBackground"
          tooltip={{ content: "Dismiss", theme: "SMALL" }}
          SvgIcon={CloseIconProp ?? CloseIcon}
          onClick={onClose}
        />
      </div>
      <div className="h-full mt-4">{children}</div>
    </div>
  );
};
