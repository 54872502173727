import { FC, FormEvent, useRef, useCallback } from "react";
import Webcam from "react-webcam";

import { Button } from "@libs/components/UI/Button";
import { useObjectState } from "@libs/hooks/useObjectState";
import { dataURLtoFile } from "@libs/utils/dataUrl";

import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { ModalForm, ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";

interface Props {
  onCapturePhoto: (file: File) => void;
  onCancel: Func;
}

export const CapturePhotoModalContent: FC<Props> = ({ onCapturePhoto, onCancel }) => {
  const webcamRef = useRef<Webcam | null>(null);
  const [{ isLoading, hasAccessGranted }, updateWebcamState] = useObjectState(() => ({
    isLoading: true,
    hasAccessGranted: false,
  }));

  const handleCapturePhoto = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const imageDataUrl = webcamRef.current?.getScreenshot();

      if (imageDataUrl) {
        onCapturePhoto(dataURLtoFile(imageDataUrl, "profile-image.jpeg"));
      }
    },
    [onCapturePhoto]
  );

  return (
    <ModalForm onSubmit={handleCapturePhoto}>
      <ModalContent className="relative">
        {isLoading ? (
          <div className="absolute inset-0">
            <LoadingContent className="rounded-none" />
          </div>
        ) : hasAccessGranted ? null : (
          <div
            className={`
              flex
              flex-col
              items-center
              justify-center
              absolute
              inset-0
              text-xs
              text-slate-700
            `}
          >
            Take a photo by allowing Archy to use your camera.
          </div>
        )}

        <Webcam
          ref={webcamRef}
          onUserMedia={() => updateWebcamState({ isLoading: false, hasAccessGranted: true })}
          onUserMediaError={() => updateWebcamState({ isLoading: false })}
          screenshotFormat="image/jpeg"
          width={448}
          mirrored={true}
        />
      </ModalContent>
      <ModalFooter>
        <Button className="min-w-button" onClick={onCancel} theme="secondary">
          Cancel
        </Button>
        <Button className="min-w-button" type="submit">
          Capture
        </Button>
      </ModalFooter>
    </ModalForm>
  );
};
