import { FC, useEffect, useMemo, useState } from "react";
import { PaymentProfileVO, WalletVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { ReactComponent as WalletIcon } from "@libs/assets/icons/wallet.svg";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { Button } from "@libs/components/UI/Button";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useAccount } from "@libs/contexts/AccountContext";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { isDefined } from "@libs/utils/types";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { SavedCards } from "components/PatientProfile/Billing/SavedCards";
import { FlyoverContent, FlyoverFooter } from "components/UI/FlyoverComponents";
import { updateWallet } from "api/billing/mutations";
import { handleError } from "utils/handleError";
import { useItemModal } from "hooks/useItemModal";
import { Text } from "components/PatientProfile/Billing/Ledger/Text";
import { getPaymentProfilesByPatientId, getWallets } from "api/billing/queries";
import {
  getFallbackPatientWallet,
  isPlaceholderWallet,
} from "components/PatientProfile/Billing/billingUtils";
import { getPatientSummary } from "api/patients/queries";
import { usePaymentProfileMutations } from "./usePaymentProfileMutations";

export const ManagePaymentMethodsFlyoverPanel: FC<{
  patientId: number;
  onClickAddFundsToWallet: Func;
  onClickAddCard: Func;
  onRequestClose: Func;
}> = ({ onClickAddCard, patientId, onRequestClose, onClickAddFundsToWallet }) => {
  const { practiceId } = useAccount();

  const [patientQuery, patientWalletsQuery, paymentProfilesQuery] = useApiQueries([
    getPatientSummary({ args: { patientId, practiceId } }),
    getWallets({ args: { patientId, practiceId, includeFamily: false } }),
    getPaymentProfilesByPatientId({ args: { patientId, practiceId } }),
  ]);

  const { deleteCard, isDeletingCard, markCardAsDefault, markCardAsFamily } = usePaymentProfileMutations();

  const [originalSortingOrder, setOriginalSortingOrder] = useState<string[]>();

  // Store the original sorting order of payment profile as returned by the BE. The BE sorts payment
  // profiles by putting in front the ones marked as default. If the default card is changed, we
  // want to preserve the original sorting order so that the new default card doesn't jump to the
  // top when payment profiles are refetched, which would be confusing from a UX perspective.
  useEffect(() => {
    if (
      paymentProfilesQuery.data &&
      ((originalSortingOrder === undefined && !paymentProfilesQuery.isFetching) ||
        // If cards have been deleted or added, update original sorting
        paymentProfilesQuery.data.length !== originalSortingOrder?.length)
    ) {
      setOriginalSortingOrder(paymentProfilesQuery.data.map((profile) => profile.uuid));
    }
  }, [originalSortingOrder, paymentProfilesQuery.data, paymentProfilesQuery.isFetching]);

  const paymentProfiles = useMemo(
    () =>
      originalSortingOrder
        ?.map((uuid) => paymentProfilesQuery.data?.find((profile) => profile.uuid === uuid))
        .filter(isDefined) ?? [],
    [originalSortingOrder, paymentProfilesQuery.data]
  );

  const wallet = getFallbackPatientWallet(patientId, practiceId, patientWalletsQuery.data);

  const deleteConfirmation = useItemModal<PaymentProfileVO>(null);
  const [updateWalletMutation] = useApiMutations([updateWallet]);

  const handleFamilyWalletChange = () => {
    updateWalletMutation.mutate(
      {
        practiceId,
        patientId,
        walletUuid: wallet.uuid,
        data: { isFamily: !wallet.isFamily },
      },
      {
        onError: handleError,
      }
    );
  };

  return (
    <QueryResult
      queries={[patientQuery, paymentProfilesQuery, patientWalletsQuery]}
      loading={<LoadingOverlaySpinner />}
    >
      <FlyoverContent className="flex flex-col gap-y-6">
        <div className="flex items-center gap-2">
          <Text bold size="sm">
            Wallet
          </Text>
          <ButtonIcon
            SvgIcon={AddIcon}
            onClick={onClickAddFundsToWallet}
            theme="primary"
            size="sm"
            tooltip={{ content: "Add Funds", theme: "SMALL" }}
          />
        </div>
        <WalletItem
          patientFirstName={patientQuery.data?.name.firstName ?? ""}
          wallet={wallet}
          onFamilyUseClick={handleFamilyWalletChange}
        />

        <div className="flex items-center gap-2">
          <Text bold size="sm">
            Cards
          </Text>
          <ButtonIcon
            SvgIcon={AddIcon}
            onClick={() => {
              // When a new card is added, reset the original sorting order to undefined so
              // that a new sorting order can be computed when payment profiles are refetched.
              setOriginalSortingOrder(undefined);
              onClickAddCard();
            }}
            theme="primary"
            size="sm"
            tooltip={{ content: "Add Card", theme: "SMALL" }}
          />
        </div>
        <SavedCards
          paymentProfiles={paymentProfiles}
          onMarkAsDefaultClick={(paymentProfile) => markCardAsDefault(paymentProfile).catch(handleError)}
          onMarkAsFamilyClick={(paymentProfile) => markCardAsFamily(paymentProfile).catch(handleError)}
          onDeleteCardClick={deleteConfirmation.open}
        />
        {deleteConfirmation.isOpen && (
          <ConfirmationModal
            size="3xs"
            primaryText="Are you sure you want to delete this card?"
            secondaryText="This card won't be available as a payment method. This action cannot be undone."
            isConfirming={isDeletingCard}
            onConfirm={() => {
              deleteCard(deleteConfirmation.item).then(deleteConfirmation.close).catch(handleError);
            }}
            onCancel={deleteConfirmation.close}
          />
        )}
      </FlyoverContent>
      <FlyoverFooter actions>
        <Button className="min-w-button" onClick={onRequestClose}>
          Done
        </Button>
      </FlyoverFooter>
    </QueryResult>
  );
};

const WalletItem: FC<{
  patientFirstName: string;
  wallet: WalletVO;
  onFamilyUseClick: Func;
}> = ({ patientFirstName, wallet, onFamilyUseClick }) => {
  return (
    <div
      className={`
        p-4
        flex
        items-center
        gap-x-6
        border
        border-slate-300
        rounded
      `}
    >
      <div>
        <Icon SvgIcon={WalletIcon} size="lg" />
      </div>
      <div className="flex flex-col gap-y-2">
        <div>
          <div>
            <Text>{`${patientFirstName}'s Wallet Balance`}</Text>
          </div>
          <div>
            <Text bold size="xl">
              {formatCurrency(wallet.balance)}
            </Text>
          </div>
        </div>
        <div>
          <FloatingTooltip
            content={
              isPlaceholderWallet(wallet)
                ? "Deposit funds to this wallet before making it a Family Wallet."
                : undefined
            }
          >
            <div className="flex items-center gap-x-2">
              <Checkbox
                disabled={isPlaceholderWallet(wallet)}
                checked={wallet.isFamily}
                onChange={() => onFamilyUseClick()}
              >
                Family Wallet
              </Checkbox>
              <FloatingTooltip content="Family Wallets can be used by all family members.">
                <div>
                  <Icon SvgIcon={InfoIcon} theme="slate500" />
                </div>
              </FloatingTooltip>
            </div>
          </FloatingTooltip>
        </div>
      </div>
    </div>
  );
};
