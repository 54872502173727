import { blankTemplate } from "components/Communications/Campaigns/EmailTemplates/templates/blank";
import { specialOfferTemplate } from "components/Communications/Campaigns/EmailTemplates/templates/specialOffer";
import { happyHolidaysTemplate } from "components/Communications/Campaigns/EmailTemplates/templates/happyHolidays";
import { birthdayTemplate } from "components/Communications/Campaigns/EmailTemplates/templates/birthday";
import { unscheduledTreatmentTemplate } from "components/Communications/Campaigns/EmailTemplates/templates/unscheduledTreatment";
import { unusedBenefitsTemplate } from "components/Communications/Campaigns/EmailTemplates/templates/unusedBenefits";
import { newProviderTemplate } from "components/Communications/Campaigns/EmailTemplates/templates/newProvider";

import blankImage from "assets/images/email-template-blank.png";
import specialOfferImage from "assets/images/email-template-special-offer.png";
import happyHolidaysImage from "assets/images/email-template-happy-holidays.png";
import birthdayImage from "assets/images/email-template-birthday.png";
import unscheduledTreatmentImage from "assets/images/email-template-unscheduled-treatment.png";
import unusedBenefitsImage from "assets/images/email-template-unused-benefits.png";
import newProviderImage from "assets/images/email-template-new-provider.png";

// When a template is required to be updated, the following steps must be taken:
// 1. All templates currently exist in the Unlayer Archy Preprod project
//    (https://embed.unlayer.com/projects/172749/templates). The JSON must be
//    copied and pasted into the appropriate template file (e.g.
//    src/components/Communications/Campaigns/EmailTemplates/templates.ts).
// 2. Once the new template JSON has been added, create or update a campaign
//    with that template applied. After saving, a new preview image will be
//    generated that can be downloaded and renamed to replace the appropriate
//    file (e.g. assets/images/email-template.png).
export const templates = [
  {
    id: "blank",
    name: "Blank Template",
    description: "Start from scratch with a completely blank email template.",
    previewImage: blankImage,
    json: blankTemplate,
  },
  {
    id: "special-offer",
    name: "Special Offer",
    description: "Let your patients know why they should make an appointment with you this month!",
    previewImage: specialOfferImage,
    json: specialOfferTemplate,
  },
  {
    id: "happy-holidays",
    name: "Happy Holidays",
    description: "Spread the holiday cheer with this all seasons, all purpose happy holidays template.",
    previewImage: happyHolidaysImage,
    json: happyHolidaysTemplate,
  },
  {
    id: "birthday",
    name: "Birthday",
    description: "Let your patients know you're thinking of them on their special day.",
    previewImage: birthdayImage,
    json: birthdayTemplate,
  },
  {
    id: "unscheduled-treatment",
    name: "Unscheduled Treatment",
    description: "Follow up on patients who haven't completed their treatment.",
    previewImage: unscheduledTreatmentImage,
    json: unscheduledTreatmentTemplate,
  },
  {
    id: "unused-benefits",
    name: "Unused Benefits",
    description: "Remind your patients to use their benefits before end of year.",
    previewImage: unusedBenefitsImage,
    json: unusedBenefitsTemplate,
  },
  {
    id: "new-provider",
    name: "New Provider",
    description: "Let your patients know about a new provider and allow them to schedule online.",
    previewImage: newProviderImage,
    json: newProviderTemplate,
  },
];
