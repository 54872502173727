import React, { useCallback, useMemo, useState } from "react";
import { ClaimLineItemData, ClaimPaymentSummaryVO, NameVO, SortOrder } from "@libs/api/generated-api";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as DownCaretIcon } from "@libs/assets/icons/down-caret.svg";
import { ReactComponent as UpCaretIcon } from "@libs/assets/icons/up-caret.svg";
import {
  TableGrid,
  HeaderCell,
  HeaderButtonCell,
  ColumnSortIndicator,
} from "@libs/components/UI/GridTableComponents";
import { ClaimLineItemActions } from "components/Eob/EobRoute";
import { EobPaymentTableRow } from "components/Eob/EobPaymentTableRow";

interface Props {
  claims: ClaimPaymentSummaryVO[];
  disableActions: boolean;
  onDeleteClaim: (claimUuid: string) => void;
  onUpdateGroupedClaimLineItems: (
    claimUuid: string,
    action: ClaimLineItemActions,
    options?: {
      hasErrors: boolean;
      lineItemIndex: number;
      updates: ClaimLineItemData;
    }
  ) => void;
}

type ClaimPaymentSummaryKeys = keyof ClaimPaymentSummaryVO;
type IsExpandedByClaimUuidMap = Record<string, boolean>;

const tableHeaders = [
  { id: "toggle", label: "", width: "50px" },
  { id: "serviceDate", label: "Date of Service", sortable: true, width: "1fr" },
  { id: "firstName", label: "Pt First Name", sortable: true, width: "2fr" },
  { id: "lastName", label: "Pt Last Name", sortable: true, width: "2fr" },
  { id: "claimNumber", label: "Claim Number", sortable: true, width: "1fr" },
  { id: "insuranceOrdinal", label: "Claim Order", sortable: true, width: "1fr" },
  { id: "insuranceMemberId", label: "Subscriber ID", sortable: true, width: "1fr" },
  {
    className: "justify-end",
    id: "unpaidPatientAmount",
    label: "Pt Resp",
    sortable: true,
    width: "1fr",
  },
  {
    className: "justify-end",
    id: "unpaidInsuranceAmount",
    label: "Ins Pay",
    sortable: true,
    width: "1fr",
  },
  { className: "justify-end", id: "verified", label: "Verified", width: "70px" },
  { id: "remove", label: "", width: "50px" },
];

const getInitialIsExpandedByClaimUuidMap = (claims: ClaimPaymentSummaryVO[], isOpen: boolean) => {
  const claimUuidMap: IsExpandedByClaimUuidMap = {};

  claims.forEach((claim) => {
    claimUuidMap[claim.uuid] = isOpen;
  });

  return claimUuidMap;
};

export const EobPaymentTable: React.FC<Props> = ({
  claims,
  disableActions,
  onDeleteClaim,
  onUpdateGroupedClaimLineItems,
}) => {
  const [expandedClaimUuids, setExpandedClaimUuids] = useState<IsExpandedByClaimUuidMap>(
    getInitialIsExpandedByClaimUuidMap(claims, false)
  );

  const [tableSort, setTableSort] = useState<SortOrder>({
    direction: "ASCENDING",
    field: "serviceDate",
  });

  const sortedClaims = useMemo(() => {
    const eobClaims = [...claims];

    // eslint-disable-next-line complexity
    eobClaims.sort((claimA: ClaimPaymentSummaryVO, claimB: ClaimPaymentSummaryVO) => {
      let claimAVal: boolean | number | string | undefined | NameVO;
      let claimBVal: boolean | number | string | undefined | NameVO;

      if (tableSort.field === "firstName" || tableSort.field === "lastName") {
        const nameSortField = tableSort.field as keyof NameVO;

        claimAVal = claimA["patientName"][nameSortField] as string;
        claimBVal = claimB["patientName"][nameSortField] as string;
      } else {
        claimAVal = claimA[tableSort.field as ClaimPaymentSummaryKeys];
        claimBVal = claimB[tableSort.field as ClaimPaymentSummaryKeys];
      }

      if (claimAVal && claimBVal) {
        if (tableSort.direction === "ASCENDING") {
          return claimAVal > claimBVal ? 1 : -1;
        }

        return claimAVal < claimBVal ? 1 : -1;
      } else if (claimAVal && !claimBVal) {
        return tableSort.direction === "ASCENDING" ? 1 : -1;
      } else if (!claimAVal && claimBVal) {
        return tableSort.direction === "ASCENDING" ? -1 : 1;
      }

      return 1;
    });

    return eobClaims;
  }, [claims, tableSort.field, tableSort.direction]);

  const handleSort = useCallback((field: string) => {
    setTableSort((last) => ({
      direction:
        last.field === field ? (last.direction === "ASCENDING" ? "DESCENDING" : "ASCENDING") : "ASCENDING",
      field,
    }));
  }, []);

  const isAllRowsExpanded = Object.values(expandedClaimUuids).every((val) => val === true);

  const handleToggleRow = useCallback((claimUuid: string) => {
    setExpandedClaimUuids((last) => {
      return { ...last, [claimUuid]: !last[claimUuid] };
    });
  }, []);

  const handleToggleAllRows = useCallback(() => {
    setExpandedClaimUuids(getInitialIsExpandedByClaimUuidMap(claims, !isAllRowsExpanded));
  }, [isAllRowsExpanded, claims]);

  const handleDeleteClaim = useCallback(
    (claimUuid: string) => {
      if (expandedClaimUuids[claimUuid]) {
        handleToggleRow(claimUuid);
      }

      onDeleteClaim(claimUuid);
    },
    [expandedClaimUuids, handleToggleRow, onDeleteClaim]
  );

  return (
    <div className="flex-1 overflow-y-auto justify-start">
      <TableGrid columnWidths={tableHeaders.map(({ width }) => width)}>
        {tableHeaders.map((header) =>
          header.id === "toggle" ? (
            <HeaderButtonCell
              className={header.className}
              data-testid="draft-eob-claims-table-toggle"
              key={header.id}
              onClick={handleToggleAllRows}
              size="short"
            >
              <FloatingTooltip content={isAllRowsExpanded ? "Collapse all" : "Expand all"} theme="SMALL">
                {isAllRowsExpanded ? (
                  <UpCaretIcon className="fill-primaryTheme w-4 h-4" />
                ) : (
                  <DownCaretIcon className="fill-primaryTheme w-4 h-4" />
                )}
              </FloatingTooltip>
            </HeaderButtonCell>
          ) : header.sortable ? (
            <HeaderButtonCell
              className={header.className}
              key={header.id}
              onClick={() => handleSort(header.id)}
              size="short"
            >
              {header.label}
              <ColumnSortIndicator
                className="ml-2"
                direction={tableSort.field === header.id ? tableSort.direction : undefined}
              />
            </HeaderButtonCell>
          ) : (
            <HeaderCell className={header.className} key={header.id} size="short">
              {header.label}
            </HeaderCell>
          )
        )}
        {sortedClaims.map((claim) => (
          <EobPaymentTableRow
            claimPaymentSummary={claim}
            disableActions={disableActions}
            isExpanded={expandedClaimUuids[claim.uuid]}
            key={claim.uuid}
            onDeleteClaim={handleDeleteClaim}
            onUpdateGroupedClaimLineItems={onUpdateGroupedClaimLineItems}
            onToggleRow={handleToggleRow}
          />
        ))}
      </TableGrid>
    </div>
  );
};
