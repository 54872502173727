import { FC } from "react";
import { PayrollVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { TableGrid, HeaderCell, TextCell, Row } from "@libs/components/UI/GridTableComponents";

interface Props {
  payroll: PayrollVO;
}

const taxesColumns = [
  {
    text: "Tax Description",
    width: "1fr",
  },
  {
    text: "By Employees",
    width: "1fr",
  },
  {
    text: "By Company",
    width: "min-content",
  },
];

const debitedColumns = [
  {
    text: "Debited by Gusto",
    width: "1fr",
  },
  {
    text: "Total",
    width: "min-content",
  },
];

export const PayrollDetailsTaxesRoute: FC<Props> = ({ payroll }) => {
  const breakdown = payroll.breakdown;

  return breakdown ? (
    <div className="flex items-start w-full gap-x-5 px-4">
      <TableGrid
        className="flex-1 rounded border border-greyLighter overflow-hidden"
        columnWidths={taxesColumns.map(({ width }) => width)}
      >
        {taxesColumns.map(({ text }) => (
          <HeaderCell size="medium" key={text}>
            {text}
          </HeaderCell>
        ))}
        {breakdown.taxes.map((tax, index) => (
          <Row key={tax.name} highlightOnHover={false}>
            <TextCell border={index !== breakdown.taxes.length - 1}>{tax.name}</TextCell>
            <TextCell border={index !== breakdown.taxes.length - 1}>
              {formatCurrency(tax.amountByEmployee)}
            </TextCell>
            <TextCell border={index !== breakdown.taxes.length - 1}>
              {formatCurrency(tax.amountByCompany)}
            </TextCell>
          </Row>
        ))}
        <Row key="total" highlightOnHover={false}>
          <TextCell className="border-t border-t-greyLight font-sansSemiBold -mb-px">Totals</TextCell>
          <TextCell className="border-t border-t-greyLight font-sansSemiBold -mb-px">
            {formatCurrency(breakdown.totalTaxesByEmployees)}
          </TextCell>
          <TextCell className="border-t border-t-greyLight font-sansSemiBold -mb-px">
            {formatCurrency(breakdown.totalTaxesByCompany)}
          </TextCell>
        </Row>
      </TableGrid>
      <TableGrid
        className="flex-1 rounded border border-greyLighter overflow-hidden"
        columnWidths={debitedColumns.map(({ width }) => width)}
      >
        {debitedColumns.map(({ text }) => (
          <HeaderCell size="medium" key={text}>
            {text}
          </HeaderCell>
        ))}

        <Row highlightOnHover={false}>
          <TextCell>Direct Deposits</TextCell>
          <TextCell>{formatCurrency(breakdown.debits.directDeposits)}</TextCell>
        </Row>
        <Row highlightOnHover={false}>
          <TextCell>Reimbursements</TextCell>
          <TextCell>{formatCurrency(breakdown.debits.reimbursements)}</TextCell>
        </Row>
        <Row highlightOnHover={false}>
          <TextCell>Child Support Garnishments</TextCell>
          <TextCell>{formatCurrency(breakdown.debits.childSupportGarnishments)}</TextCell>
        </Row>
        <Row highlightOnHover={false}>
          <TextCell className="-mb-px">Taxes (Employees and Employers)</TextCell>
          <TextCell className="-mb-px">
            {formatCurrency(breakdown.debits.taxesByEmployeesAndEmployers)}
          </TextCell>
        </Row>
      </TableGrid>
    </div>
  ) : null;
};
