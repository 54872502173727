import { FC } from "react";

import { MessageCampaignSummaryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { TableGrid, HeaderCell } from "@libs/components/UI/GridTableComponents";
import { EmptyContent } from "@libs/components/UI/EmptyContent";

import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { EmailCampaignRow } from "components/Communications/Campaigns/CampaignRow";

import emptyCommunications from "assets/images/empty-communications.svg";

import { TablePagerPageDetails } from "components/UI/TablePagerPageDetails";

const tableHeaders = [
  { id: "name", label: "Name", width: "minmax(6rem, 1fr)" },
  { id: "recipients", label: "Recipients", width: "6rem" },
  { id: "openRate", label: "Open Rate", width: "6rem" },
  { id: "clickRate", label: "Click Rate", width: "6rem" },
  { id: "sendDate", label: "Send Date", width: "6rem" },
  { id: "status", label: "Status", width: "6rem" },
  { id: "menu", width: "3.25rem" },
];

interface Props {
  campaignsQuery: ApiQueryResult<MessageCampaignSummaryVO[]>;
  page: number;
  pageSize: number;
  onPageSizeChange: (newSize: number) => void;
  onPageChange: (newPage: number) => void;
}

export const CampaignsTable: FC<Props> = ({
  campaignsQuery,
  page,
  pageSize,
  onPageChange,
  onPageSizeChange,
}) => {
  return (
    <div
      className={`
        border
        border-slate-200
        rounded
        flex-1
        min-h-64
        flex
        flex-col
      `}
    >
      <QueryResult queries={[campaignsQuery]}>
        <PersistScrollPosition
          resetScrollKey={page}
          id="campaigns-table"
          className="flex-1 min-h-0 overflow-y-auto"
        >
          <TableGrid columnWidths={tableHeaders.map(({ width }) => width)}>
            {tableHeaders.map(({ id, label }, index) => (
              <HeaderCell
                borderColor="border-slate-200"
                key={id}
                className={cx("first:rounded-tl", index === tableHeaders.length - 1 && "rounded-tr")}
                size="medium"
              >
                {label}
              </HeaderCell>
            ))}

            {campaignsQuery.data?.length ? (
              <>
                {campaignsQuery.data.map((campaign) => (
                  <EmailCampaignRow key={campaign.uuid} campaign={campaign} />
                ))}
              </>
            ) : (
              <div className="flex justify-center col-span-full px-5 py-10">
                <EmptyContent text="No Campaigns" src={emptyCommunications} alt="No Campaigns" size="md" />
              </div>
            )}
          </TableGrid>
        </PersistScrollPosition>
      </QueryResult>

      <TablePagerPageDetails
        page={page}
        pageSize={pageSize}
        pageDetails={campaignsQuery.apiResponse?.data.pageDetails}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  );
};
