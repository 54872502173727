import { FC, useState, useCallback } from "react";

import { useCurrentPractice } from "@libs/contexts/PracticeContext";

import { MessagePanelLayout } from "components/Communications/MessagePanelLayout";
import {
  MessageViewToggleGroup,
  MessageViewToggleGroupProps,
} from "components/Communications/MessageViewToggleGroup";
import { SMSMessage } from "components/Communications/SMSMessage";
import { EmailMessageActions } from "components/Communications/EmailMessageActions";
import { EmailMessage } from "components/Communications/EmailMessage";
import { FilteredTemplateVariablesQuery } from "components/Communications/types";

import {
  SendMessageCampaignDraft,
  SendMessageCampaignValidationResult,
} from "components/Communications/MessagePatients/types";

interface Props {
  messageCampaign: SendMessageCampaignDraft;
  contentTemplateVariablesQuery: FilteredTemplateVariablesQuery;
  subjectTemplateVariablesQuery: FilteredTemplateVariablesQuery;
  onUpdateMessageCampaign: (updates: Partial<SendMessageCampaignDraft>) => void;
  onSendTestModal: Func;
  validationResult: SendMessageCampaignValidationResult;
}

export const MessagePatientsPanel: FC<Props & Omit<MessageViewToggleGroupProps, "channels">> = ({
  messageCampaign,
  contentTemplateVariablesQuery,
  subjectTemplateVariablesQuery,
  onUpdateMessageCampaign,
  validationResult,
  ...props
}) => {
  const { messageView } = props;
  const messageViewToggleGroupProps: MessageViewToggleGroupProps = {
    channels: messageCampaign.channels,
    ...props,
  };

  const practice = useCurrentPractice();

  const [copyCount, setCopyCount] = useState(0);

  const handleCopySms = useCallback(() => {
    setCopyCount((last) => last + 1);
    onUpdateMessageCampaign({ emailTemplate: messageCampaign.smsTemplate });
  }, [onUpdateMessageCampaign, messageCampaign.smsTemplate]);

  return messageView === "SMS" ? (
    <MessagePanelLayout
      primaryActions={<MessageViewToggleGroup {...messageViewToggleGroupProps} />}
      // TODO: Implement SMSMessageActions when PreviewSmsMessageCampaign API is available
      // secondaryActions={
      //   <SMSMessageActions smsTemplate={messageCampaign.smsTemplate} onSendTest={onSendTestModal} />
      // }
    >
      <SMSMessage
        smsTemplate={messageCampaign.smsTemplate}
        contentTemplateVariablesQuery={contentTemplateVariablesQuery}
        onTemplateChange={(smsTemplate) => onUpdateMessageCampaign({ smsTemplate })}
        validationResult={{
          smsTemplate: validationResult.smsTemplate,
        }}
      />
    </MessagePanelLayout>
  ) : messageView === "EMAIL" ? (
    <MessagePanelLayout
      primaryActions={<MessageViewToggleGroup {...messageViewToggleGroupProps} />}
      secondaryActions={
        <EmailMessageActions
          emailTemplate={messageCampaign.emailTemplate}
          smsTemplate={messageCampaign.smsTemplate}
          onCopySms={handleCopySms}
          // TODO: Implement onSendTest when PreviewEmailMessageCampaign API is available
          // onSendTest={onSendTestModal}
        />
      }
    >
      <EmailMessage
        // When the user wants to copy the SMS message into the email message we
        // need to force re-render so the email editor takes the new state.
        key={copyCount}
        emailTemplate={messageCampaign.emailTemplate}
        subject={messageCampaign.subject}
        onTemplateChange={(emailTemplate) => onUpdateMessageCampaign({ emailTemplate })}
        onSubjectChange={(subject) => onUpdateMessageCampaign({ subject })}
        contentTemplateVariablesQuery={contentTemplateVariablesQuery}
        subjectTemplateVariablesQuery={subjectTemplateVariablesQuery}
        validationResult={{
          emailTemplate: validationResult.emailTemplate,
          subject: validationResult.subject,
        }}
        practice={practice}
      />
    </MessagePanelLayout>
  ) : (
    <MessagePanelLayout
      primaryActions={
        <div
          className={`
            flex
            items-center
            justify-center
            bg-slate-200
            rounded
            text-xs
            h-8
            px-3
          `}
        >
          Example
        </div>
      }
    >
      <div />
    </MessagePanelLayout>
  );
};
