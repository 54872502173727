import { useMemo } from "react";

import { AppointmentCategoryVO, AppointmentPatientProcedureVO, AppointmentVO } from "@libs/api/generated-api";
import {
  TREATMENT_PLAN_CATEGORY_ID,
  useAppointmentCategoriesAndProcedures,
} from "components/ScheduleAppointments/useAppointmentCategoriesAndProcedures";
import { useAppointmentDuration } from "components/ScheduleAppointments/useAppointmentDuration";

export const useEditAppointmentForm = ({
  appointment,
  categories,
}: {
  appointment: AppointmentVO;
  categories: AppointmentCategoryVO[];
}) => {
  const { categoryProcedures, additionalProcedures } = useMemo(() => {
    const defaultValue = {
      categoryProcedures: [],
      additionalProcedures: appointment.patientProcedures,
    };

    const selectedCategory = categories.find((cat) => cat.id === appointment.categoryName?.id);

    if (!appointment.patientProcedures.length || !selectedCategory) {
      return defaultValue;
    }

    const additional = [...appointment.patientProcedures];
    const category: AppointmentPatientProcedureVO[] = [];

    for (const catProcedure of selectedCategory.procedures) {
      for (const [index, appProcedure] of additional.entries()) {
        if (appProcedure.dentalProcedureId === catProcedure.id) {
          category.push(appProcedure);
          additional.splice(index, 1);
          break;
        }
      }
    }

    return {
      categoryProcedures: category,
      additionalProcedures: additional,
    };
  }, [appointment.patientProcedures, categories, appointment.categoryName]);

  const {
    procedures,
    color,
    hasCustomColor,
    selectedCategory,
    selectedCategoryId,
    addProcedures,
    setColor,
    updateDraft,
    switchSavedProcedure,
    switchNewProcedure,
    removeProcedure,
    selectCategory,
  } = useAppointmentCategoriesAndProcedures({
    appointmentCategoryProcedures: categoryProcedures,
    categories,
    initialCategoryId: appointment.categoryName?.id ?? TREATMENT_PLAN_CATEGORY_ID,
    initialColor: appointment.color,
    patientProcedures: additionalProcedures,
  });

  const duration = useAppointmentDuration({
    initialDuration: appointment.duration,
    procedures,
  });

  return {
    procedures,
    color,
    hasCustomColor,
    selectedCategory,
    selectedCategoryId,
    duration,
    addProcedures,
    setColor,
    updateDraft,
    switchSavedProcedure,
    switchNewProcedure,
    removeProcedure,
    selectCategory,
  };
};
