import React from "react";
import { CollectedPaymentEntryVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { usePick } from "@libs/hooks/usePick";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { usePaymentCollectedQuery } from "components/Dashboard/PaymentCollected/hooks/usePaymentCollectedQuery";
import { FooterCell } from "components/Dashboard/Tables/FooterCell";
import { ByPatientTableRow } from "components/Dashboard/PaymentCollected/ByPatientTableRow";
import { useQueryParams } from "hooks/useQueryParams";
import { useDownloadPaymentCollectedByPatientCSV } from "components/Dashboard/PaymentCollected/hooks/useDownloadPaymentCollectedByPatientCSV";
import { ExportCsvButton } from "components/Dashboard/ExportCsvButton";
import { useFilterTokenProps } from "components/Dashboard/hooks/useFilterTokenProps";
import { DashboardTableFilters } from "components/Dashboard/Tables/DashboardTableFilters";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import { MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";
import { useFlattenEntries } from "components/Dashboard/hooks/useFlattenEntries";
import { DashboardFilterFormFieldSelect } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";
import { labelForPaymentMethod } from "components/Dashboard/PaymentCollected/utils";
import { DashboardTableTabs } from "components/Dashboard/Tables/DashboardTableTabs";
import { useTableTabProps } from "components/Dashboard/PaymentCollected/hooks/useTableTabProps";
import { BY_PATIENT_TABLE_HEADERS } from "components/Dashboard/PaymentCollected/constants";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";

const PAYMENT_METHODS: CollectedPaymentEntryVO["paymentMethod"][] = [
  "ACH",
  "CASH",
  "CARD",
  "EXTERNAL_CARD",
  "CHECK",
  "CARE_CREDIT",
  "THIRD_PARTY_FINANCING",
];
const PAYMENT_METHOD_OPTIONS = PAYMENT_METHODS.map((method) => ({
  label: labelForPaymentMethod(method),
  value: method,
}));

export const ByPatientTableRoute: React.FC = () => {
  const { updateQuery, query } = useQueryParams("dashboardPaymentCollected");
  const { collectedPaymentQuery, totals, baseRowKey } = usePaymentCollectedQuery({
    pageSize: MAX_PAGE_SIZE,
  });
  const { isLoading, data } = collectedPaymentQuery;
  const rows = useFlattenEntries(data);

  const isEmpty = !isLoading && rows.length === 0;
  const handleSortClick = React.useCallback(
    (patientTableSort: ColumnSortOrder[]) => {
      updateQuery("replaceIn", {
        patientTableSort,
      });
    },
    [updateQuery]
  );
  const filterParams = usePick(query, ["filters", "patientSearch"]);
  const filterProps = useFilterTokenProps({ options: filterParams });

  const { downloadCSV: downloadPaymentCollectedCsv, isDownloading } =
    useDownloadPaymentCollectedByPatientCSV();

  const tableTabProps = useTableTabProps();

  return (
    <div className="h-full flex-1 flex flex-col min-h-0">
      <DashboardTableTabs
        id="payment-collected-tabs-by-patient"
        {...tableTabProps}
        includePatientSearch={false}
      >
        <DashboardFilterFormFieldSelect
          placeholder="Payment Method"
          isSearchable={false}
          options={PAYMENT_METHOD_OPTIONS}
          className="min-w-[180px]"
          dashboardFilters={query.filters}
          dashboardFilterType="paymentMethod"
          onChangeFilters={({ filters }) => {
            updateQuery("replaceIn", {
              filters,
            });
          }}
        />
        <FormFieldInput
          layout="labelIn"
          inputClassName="pr-8"
          Icon={SearchIcon}
          placeholder="Search Patient"
          onChange={(e) => {
            updateQuery("replaceIn", {
              patientSearch: e.target.value,
            });
          }}
          value={query["patientSearch"]}
        />
        <ExportCsvButton onClick={downloadPaymentCollectedCsv} isDownloading={isDownloading} />
      </DashboardTableTabs>

      <DashboardTableFilters {...filterParams}>
        <QueryFilterPills
          {...filterProps}
          onUpdateParams={(updatedQuery) => {
            updateQuery("replaceIn", updatedQuery);
          }}
          onClearAll={() => {
            updateQuery("replaceIn", { filters: [], patientSearch: undefined });
          }}
        />
      </DashboardTableFilters>
      <DashboardInfiniteTable
        columnWidths={BY_PATIENT_TABLE_HEADERS.map(({ width }) => width)}
        id="paymentCollectedByPatientTable"
        isEmpty={isEmpty}
        infiniteQuery={collectedPaymentQuery}
        headerRow={
          <DashboardHeaderRow
            headers={BY_PATIENT_TABLE_HEADERS}
            onSortClick={handleSortClick}
            sortOrders={query.patientTableSort}
          />
        }
        footerRow={
          <>
            <FooterCell className="col-span-6">Totals</FooterCell>
            <FooterCell loading={isLoading} align="right">
              {totals ? formatCurrency(totals.totalPaymentAmount) : ""}
            </FooterCell>
          </>
        }
      >
        {rows.map((item, i) => {
          return <ByPatientTableRow key={`${baseRowKey}_${i}`} data={item} last={i === rows.length - 1} />;
        })}
      </DashboardInfiniteTable>
    </div>
  );
};
