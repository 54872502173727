import { FC, ReactNode, useCallback, useEffect, useState, useMemo } from "react";
import { createPortal } from "react-dom";
import { useNavigate, useLocation } from "react-router-dom";
import { AppointmentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { NOT_FOUND } from "@libs/utils/statusCodes";
import { getFullUrl } from "@libs/utils/location";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useQuerySelector } from "@libs/hooks/useQuerySelector";
import { flattenPages } from "@libs/utils/queries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getAppointmentQuery } from "api/scheduling/queries";
import {
  getNextPatientAppointmentQuery,
  getFamilyMembersV2Query,
  getInfiniteAppointmentsQuery,
  getPatientSummary,
} from "api/patients/queries";
import { getInfiniteFormSummaries } from "api/forms/queries";
import { listFormTasks } from "api/formTasks/queries";
import { handleError } from "utils/handleError";
import { getPatientInsurancesQuery } from "api/patientInsurance/queries";
import { getProfileImageQuery } from "api/user/queries";
import { useNotificationContext } from "contexts/NotificationsContext";
import { PatientBlurbHeader } from "components/PatientBlurbInfo/PatientBlurbHeader";
import { PatientNav } from "components/PatientBlurbInfo/PatientNav";
import { getClinicianPrescriptionStatus } from "api/erx/queries";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { PatientAppointment } from "components/PatientBlurbInfo/PatientAppointment";
import { RouteIds } from "components/PatientProfile/types";
import { PatientBlubPeeks } from "components/PatientBlurbInfo/PatientBlurbPeeks";
import { Divider } from "components/UI/Divider";
import { usePatientNotesRouter } from "components/Notes/usePatientNotesRouter";
import { Avatar } from "components/UI/Avatar";
import { paths } from "utils/routing/paths";
import { SelectAppointmentPanel } from "components/PatientBlurbInfo/SelectAppointmentPanel";
import { NoUpcomingAppointment } from "components/PatientBlurbInfo/NoUpcomingAppointment";
import { updateAppointment } from "api/scheduling/mutations";
import { getAppointmentRequest } from "components/ScheduleAppointments/utils";
import { AppointmentActions } from "components/ScheduleAppointments/AppointmentMenuActions";
import { getPatientNoteAlertsCount } from "api/notes/queries";
import { getAlertSettings } from "api/settings/notes/queries";
import { AlertsPanel } from "components/PatientBlurbInfo/AlertsPanel";
import { useShowAlerts } from "components/PatientBlurbInfo/useShowAlerts";
import { NotesFlyover } from "components/Notes/NotesFlyover";

interface Props {
  patientId: number;
  navAvatarId: string;
  appointmentId?: number;
  emptyText?: ReactNode;
  onDeleteAppointment: (params: { patientId: number; appointmentId: number }) => void;
  onSelectAppointment: (appointment: AppointmentVO) => void;
  onViewAppointment?: (appointment: AppointmentVO) => void;
  onEditAppointment?: (appointmentId: number, patientId: number) => void;
  onAddAppointment?: (patientId: number) => void;
  hipaaView?: boolean;
}

// eslint-disable-next-line complexity, max-statements
export const PatientBlurb: FC<Props> = ({
  onDeleteAppointment,
  onEditAppointment,
  onAddAppointment,
  onSelectAppointment,
  onViewAppointment,
  patientId,
  navAvatarId,
  appointmentId = 0,
  hipaaView = false,
}) => {
  const location = useLocation();
  const navigate = useNavigate();
  const notesFlyover = usePatientNotesRouter();
  const { practiceId, id } = useAccount();
  const showAppointmentsList = useBoolean(false);
  const [peek, setPeek] = useState<RouteIds | null>(null);
  const peekPanel = useBoolean(false);

  const notification = useNotificationContext();

  // This is used to handle situation where the selected appointment was deleted
  // but the appointmentId in the query string is still present (when going back
  // to a url). In this case a 404 will occur and we want to ignore the selected
  // appointment query and not display an error message.
  const selectedAppointmentNotFound = useBoolean(false);

  const ignoreSelectedAppointmentQuery = !appointmentId || selectedAppointmentNotFound.isOn;

  const [
    patientQuery,
    patientImageQuery,
    familyMembersQuery,
    nextAppointmentQuery,
    selectedAppointmentQuery,
    activeInsurancesQuery,
    prescriptionStatusQuery,
    alertSettingsQuery,
    alertsCountQuery,
  ] = useApiQueries([
    getPatientSummary({ args: { patientId, practiceId } }),
    getProfileImageQuery({
      args: { userId: patientId, practiceId },
    }),
    getFamilyMembersV2Query({
      args: { practiceId, patientId, includeUnlinked: false },
    }),
    getNextPatientAppointmentQuery({
      args: { practiceId, patientId },
    }),
    getAppointmentQuery({
      args: {
        practiceId,
        patientId,
        appointmentId,
      },
      queryOptions: {
        enabled: Boolean(appointmentId),
        onSettled: (_, err) => {
          if (err?.status === NOT_FOUND) {
            selectedAppointmentNotFound.on();
          } else {
            selectedAppointmentNotFound.off();
          }
        },
      },
    }),
    getPatientInsurancesQuery({
      args: {
        patientId,
        practiceId,
        includeInsurancePlan: true,
        includeBenefitCoverage: true,
        includeBenefitLimitation: true,
        includeProcedureHistory: true,
        insuranceState: ["ACTIVE"],
      },
    }),
    getClinicianPrescriptionStatus({ args: { practiceId, employeeId: id } }),
    getAlertSettings({ args: { practiceId } }),
    getPatientNoteAlertsCount({
      args: { practiceId, patientId },
    }),
  ]);

  const appointmentDetail = selectedAppointmentQuery.data || nextAppointmentQuery.data;

  const hasNoUpcomingAppointment = !nextAppointmentQuery.data;

  const [formTasksQuery, practiceProvidersQuery] = useApiQueries([
    listFormTasks({
      args: {
        practiceId,
        patientId,
        appointmentIds: [appointmentDetail?.id ?? 0],
        states: ["PENDING", "COMPLETED"],
        pageSize: PAGE_SIZE,
        pageNumber: 1,
      },
      queryOptions: { enabled: Boolean(appointmentDetail) },
    }),
    getPracticeProvidersQuery({
      args: { practiceId },
      queryOptions: { enabled: !appointmentDetail && Boolean(patientQuery.data?.primaryProviderId) },
    }),
  ]);

  const formsQuery = useInfiniteApiQuery(
    getInfiniteFormSummaries({
      args: {
        practiceId,
        pageNumber: 1,
        publishedOnly: true,
        types: ["INTAKE"],
      },
      queryOptions: { enabled: Boolean(appointmentDetail) },
    })
  );

  const appointmentsQuery = useInfiniteApiQuery(
    getInfiniteAppointmentsQuery({
      args: { patientId, practiceId, pageSize: 25, pageNumber: 1 },
      queryOptions: {
        enabled: showAppointmentsList.isOn,
      },
    })
  );

  const hasNoAppointments = useMemo(
    () => flattenPages(appointmentsQuery.data)?.length === 0,
    [appointmentsQuery.data]
  );

  const [updateAppointmentMutation] = useApiMutations([updateAppointment]);

  const patient = patientQuery.data;
  const familyMembers = familyMembersQuery.data;
  const [primaryInsurance] = activeInsurancesQuery.data ?? [undefined];
  const forms = useFlattenPages(formsQuery.data);

  const guardians = useMemo(() => {
    if (!familyMembers) {
      return [];
    }

    const currentFamilyMember = familyMembers.linkedFamilyMembers.find(
      (familyMember) => familyMember.memberPatientId === patientId
    );

    const guardiansPatientIds = new Set(
      currentFamilyMember?.linkedFamilyMembers
        .filter((linkedMember) => linkedMember.guardian)
        .map((linkedMember) => linkedMember.name.id)
    );

    return familyMembers.linkedFamilyMembers.filter((familyMember) =>
      guardiansPatientIds.has(familyMember.memberPatientId)
    );
  }, [patientId, familyMembers]);

  const primaryProvider = useMemo(
    () => practiceProvidersQuery.data?.find((p) => p.id === patient?.primaryProviderId),
    [practiceProvidersQuery.data, patient?.primaryProviderId]
  );

  const handleEmailCopy = useCallback(() => {
    if (patient?.contact.email) {
      navigator.clipboard.writeText(patient.contact.email);
    }

    notification.handleInfo("Copied to clipboard.");
  }, [patient, notification]);

  const handleViewMessages = (viewPatientId: number) => {
    const url = paths.messaging({ patientId: viewPatientId });

    navigate(url);
  };

  const handleAddAppointment = (appointmentPatientId: number) => {
    if (onAddAppointment) {
      onAddAppointment(appointmentPatientId);
    } else {
      const url = paths.addPatientAppointment({
        patientId: appointmentPatientId,
        from: getFullUrl(location),
      });

      navigate(url);
    }
  };

  const handleUpdateAppointmentState = useCallback(
    (appointment: AppointmentVO, newState: AppointmentVO["state"]) => {
      updateAppointmentMutation.mutate(
        {
          practiceId,
          appointmentId: appointment.id,
          original: {
            state: appointment.state,
            date: appointment.date,
            asap: appointment.asap,
          },
          data: {
            ...getAppointmentRequest(appointment),
            state: newState,
          },
        },
        {
          onError: handleError,
        }
      );
    },
    [updateAppointmentMutation, practiceId]
  );

  const openPeekPanel = peekPanel.on;
  const closePeekPanel = peekPanel.off;
  const handlePeekClick = useCallback(
    (newPeek: RouteIds) => {
      if (newPeek === peek && peekPanel.isOn) {
        closePeekPanel();
      } else {
        setPeek(newPeek);
        openPeekPanel();
      }
    },
    [openPeekPanel, closePeekPanel, peek, peekPanel.isOn]
  );

  const appointmentMenuActions = useMemo(() => {
    const actions: AppointmentActions = {
      edit: onEditAppointment
        ? (option) => onEditAppointment(option.appointment.id, option.appointment.patient.id)
        : undefined,
      view: onViewAppointment ? (option) => onViewAppointment(option.appointment) : undefined,
    };

    return actions;
  }, [onEditAppointment, onViewAppointment]);

  const showAppointmentDetailsPanel = showAppointmentsList.off;

  useEffect(() => {
    showAppointmentDetailsPanel();
  }, [patientId, appointmentId, showAppointmentDetailsPanel]);

  const handleSelectAppointmentFromList = useCallback(
    (selectedAppointment: AppointmentVO) => {
      onSelectAppointment(selectedAppointment);
      showAppointmentsList.off();
    },
    [onSelectAppointment, showAppointmentsList]
  );

  const handleNoUpcomingAppointment = useCallback(() => {
    onDeleteAppointment({ patientId, appointmentId });
    showAppointmentsList.off();
  }, [appointmentId, patientId, onDeleteAppointment, showAppointmentsList]);

  const navAvatarTarget = useQuerySelector(`#${CSS.escape(navAvatarId)}`);

  const alertsCount = alertsCountQuery.apiResponse?.data.pageDetails?.totalElements;
  const { hideAlerts, showAlerts, isShowingAlerts } = useShowAlerts({
    patientId,
    appearance: alertSettingsQuery.data?.appearance,
    alertsCount,
    areNotesShowing: notesFlyover.isOpen,
    hipaaView,
  });

  const closeNotesFlyover = notesFlyover.close;
  const parsePatientNotesParams = notesFlyover.parseCurrentUrlParams;

  useEffect(() => {
    const notesPatientId = parsePatientNotesParams()?.params.patientId;

    if (notesPatientId && patientId && notesPatientId !== patientId) {
      closeNotesFlyover();
    }
  }, [closeNotesFlyover, parsePatientNotesParams, patientId]);

  return (
    <>
      <div className="h-full flex flex-col relative">
        <div className="flex-none pt-6 px-6 pb-3">
          <div className="min-h-[356px]">
            <QueryResult
              queries={[patientQuery, patientImageQuery, familyMembersQuery]}
              nonCriticalQueries={[prescriptionStatusQuery]}
            >
              <div className="flex flex-col gap-y-3">
                {patient && patientImageQuery.data ? (
                  <PatientBlurbHeader
                    familyMembers={familyMembers}
                    isHipaaView={hipaaView}
                    onAddAppointment={() => handleAddAppointment(patientId)}
                    onCopyEmail={handleEmailCopy}
                    onViewMessages={() => handleViewMessages(patientId)}
                    patient={patient}
                    patientImage={patientImageQuery.data}
                  />
                ) : null}
              </div>
              <div className="pt-3 flex-none">
                <PatientNav
                  alertAppearance={alertSettingsQuery.data?.appearance}
                  hasAlerts={Boolean(alertsCount)}
                  showErx={prescriptionStatusQuery.data?.isEnabled}
                  onPeekClick={handlePeekClick}
                  onOpenAlerts={isShowingAlerts ? hideAlerts : showAlerts}
                  onOpenNotes={() => notesFlyover.open("list", { patientId })}
                  areNotesOpen={notesFlyover.isOpen}
                />
              </div>
            </QueryResult>
          </div>
        </div>

        <div className="flex flex-col justify-center h-2 px-6">
          <Divider />
        </div>

        <div className="flex-1 min-h-0 overflow-hidden">
          <div
            className={cx(
              "transition-transform h-full flex items-stretch w-[200%]",
              showAppointmentsList.isOff ? "-translate-x-1/2" : "translate-x-0"
            )}
          >
            <div className="w-1/2">
              <SelectAppointmentPanel
                appointmentsQuery={appointmentsQuery}
                patientId={patientId}
                onSelectAppointment={handleSelectAppointmentFromList}
                onNoUpcomingAppointment={handleNoUpcomingAppointment}
                hasNoUpcomingAppointment={hasNoUpcomingAppointment}
                onCloseAppointmentsList={showAppointmentsList.off}
              />
            </div>

            <div className="w-1/2 px-6 py-3 overflow-y-auto">
              <QueryResult
                queries={[
                  patientQuery,
                  nextAppointmentQuery,
                  ...(ignoreSelectedAppointmentQuery ? [] : [selectedAppointmentQuery]),
                  activeInsurancesQuery,
                  formTasksQuery,
                  familyMembersQuery,
                  practiceProvidersQuery,
                ]}
              >
                {appointmentDetail ? (
                  patient ? (
                    <PatientAppointment
                      appointment={appointmentDetail}
                      guardians={guardians}
                      primaryProvider={primaryProvider}
                      forms={forms}
                      formTasks={formTasksQuery.data}
                      insurance={primaryInsurance}
                      isHipaaView={hipaaView}
                      isNextAppointment={Boolean(!appointmentId && nextAppointmentQuery.data)}
                      appointmentActions={appointmentMenuActions}
                      onAppointmentDeleted={onDeleteAppointment}
                      onUpdateAppointmentState={handleUpdateAppointmentState}
                      onViewAppointments={showAppointmentsList.on}
                      patient={patient}
                    />
                  ) : null
                ) : patient ? (
                  <NoUpcomingAppointment
                    patientId={patientId}
                    insurance={primaryInsurance}
                    guardians={guardians}
                    primaryProvider={primaryProvider}
                    isHipaaView={hipaaView}
                    hasNoAppointments={hasNoAppointments}
                    onShowAppointments={showAppointmentsList.on}
                    onAddAppointment={() => handleAddAppointment(patientId)}
                  />
                ) : null}
              </QueryResult>
            </div>
          </div>
        </div>
        <PatientBlubPeeks
          patientId={patientId}
          peek={peek}
          isOpen={peekPanel.isOn}
          onRequestClose={peekPanel.off}
        />
        <AlertsPanel isOpen={isShowingAlerts} patientId={patientId} onRequestClose={hideAlerts} />
      </div>

      <NotesFlyover />

      {navAvatarTarget && patientQuery.data
        ? createPortal(
            <Avatar
              imageUrl={patientImageQuery.data?.url}
              isHipaaView={hipaaView}
              name={patientQuery.data.name.fullDisplayName}
              size="md"
            />,
            navAvatarTarget
          )
        : null}
    </>
  );
};
