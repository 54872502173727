import React from "react";
import { isDefined } from "@libs/utils/types";
import { findOveflowSpot } from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { ImageLayoutItem } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";

export const useImportImageSpot = ({
  sandboxImages,
  startingCaptureSpot,
}: {
  sandboxImages: ImageLayoutItem[];
  startingCaptureSpot?: ImageLayoutItem;
}) => {
  const offsetFromStartingIndex = React.useRef(0);
  const getNewUploadSpot = React.useCallback(() => {
    let nextInLayout: ImageLayoutItem | undefined;

    if (isDefined(startingCaptureSpot?.i)) {
      const firstIndexToUpload = sandboxImages.findIndex(
        (item) => startingCaptureSpot.i + offsetFromStartingIndex.current === item.i
      );

      nextInLayout = firstIndexToUpload >= 0 ? sandboxImages[firstIndexToUpload] : undefined;
    }

    const spotToPopulate = nextInLayout ?? findOveflowSpot(sandboxImages);

    offsetFromStartingIndex.current++;

    return spotToPopulate;
  }, [startingCaptureSpot?.i, sandboxImages]);

  return {
    getNewUploadSpot,
    resetSpotGenerator: React.useCallback(() => {
      offsetFromStartingIndex.current = 0;
    }, []),
  };
};
