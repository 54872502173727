import { useBoolean } from "@libs/hooks/useBoolean";
import { pluralize } from "@libs/utils/pluralize";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { AlertModal } from "@libs/components/UI/AlertModal";

type Props = {
  imageSubmissionCount: number;
  handleProceed: () => void;
  isLoading: boolean;
};
export const PearlRequestAnalysisButton: React.FC<Props> = ({
  imageSubmissionCount,
  handleProceed,
  isLoading,
}) => {
  const modalOpen = useBoolean(false);

  return (
    <div className="text-center min-w-button">
      <AsyncButton disabled={imageSubmissionCount === 0} isLoading={isLoading} onClick={modalOpen.on}>
        Request Analysis
      </AsyncButton>
      {modalOpen.isOn && (
        <AlertModal
          primaryText="Warning"
          secondaryText={`Make sure your ${pluralize(imageSubmissionCount, "image", "images")} ${pluralize(
            imageSubmissionCount,
            "is",
            "are"
          )} rotated and mirrored correctly before submitting the AI analysis request. This cannot be undone.`}
          size="xs"
          confirmText="Proceed"
          cancelText="Cancel"
          onCancel={modalOpen.off}
          onConfirm={() => {
            handleProceed();
            modalOpen.off();
          }}
        />
      )}
    </div>
  );
};
