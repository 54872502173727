import { mirroredOptions } from "@libs/utils/forms";
import { Icon } from "@libs/components/UI/Icon";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { RadioList } from "@libs/components/UI/RadioList";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { Switch } from "@libs/components/UI/Switch";
import { Section } from "components/Insurance/Section";
import { InsuranceState } from "components/Patient/hooks";
import { planCxStyles } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/styles";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";

interface Props {
  editing: boolean;
  insuranceState: InsuranceState;
  onChangeAssignmentOfBenefits: (val: boolean) => void;
  onChangeInfoRelease: (val: boolean) => void;
  onChangeSubscriberNotes: (val: string) => void;
}

const DEFAULT_NOTE_ROWS = 8;
const ASSIGNMENT_OPTIONS = mirroredOptions(["Practice", "Patient"]);

export const AdditionalInformationSection: React.FC<Props> = ({
  editing,
  insuranceState,
  onChangeAssignmentOfBenefits,
  onChangeInfoRelease,
  onChangeSubscriberNotes,
}) => {
  return (
    <Section className={planCxStyles.section} includePadding={false} title="Options" useCustomStyling={true}>
      <div className={planCxStyles.fieldsContainer}>
        <FormFieldTextarea
          className="col-span-2 font-sans"
          edit={editing}
          label="Subscriber Notes"
          layout="labelOut"
          rows={DEFAULT_NOTE_ROWS}
          onChange={(e) => onChangeSubscriberNotes(e.target.value)}
          value={insuranceState.notes || (editing ? "" : "None")}
        />
        <div className="flex flex-col gap-y-1 text-xs">
          <FormFieldLabel
            content={
              <div className="flex flex-row items-center gap-x-1.5 font-sansSemiBold">
                Release of Patient Information
                <Icon
                  SvgIcon={InfoIcon}
                  size="sm"
                  tooltip={{ content: "Release of Patient Information can be overridden by the patient." }}
                />
              </div>
            }
          />
          {editing ? (
            <>
              <div className="mb-1">Will the practice file claims for the patient?</div>
              <div className="flex flex-row items-center gap-x-1.5">
                <Switch
                  checked={insuranceState.releaseOfPatientInfo}
                  onChange={(e) => onChangeInfoRelease(e.target.checked)}
                />
                {insuranceState.releaseOfPatientInfo ? "Yes" : "No"}
              </div>
            </>
          ) : (
            <div>{insuranceState.releaseOfPatientInfo ? "Yes" : "No"}</div>
          )}
        </div>
        <div className="flex flex-col gap-y-1 text-xs">
          <FormFieldLabel className="font-sansSemiBold" content="Assignment of Benefits" />
          {editing ? (
            <>
              <div className="mb-1">Who will receive payments from insurance?</div>
              <RadioList
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  onChangeAssignmentOfBenefits(e.target.value === "Practice");
                }}
                options={ASSIGNMENT_OPTIONS}
                selectedValue={insuranceState.assignmentOfBenefitsToPractice ? "Practice" : "Patient"}
              />
            </>
          ) : (
            <div>{insuranceState.assignmentOfBenefitsToPractice ? "Practice" : "Patient"}</div>
          )}
        </div>
      </div>
    </Section>
  );
};
