import { PaymentDeviceVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { PosCard } from "components/PatientProfile/Billing/PosCard";

interface Props extends React.HTMLProps<HTMLButtonElement> {
  paymentPos: PaymentDeviceVO;
  isSelected: boolean;
  disabled?: boolean;
}

export const PosButton: React.FC<Props> = ({ paymentPos, isSelected, disabled, onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      disabled={disabled}
      className={cx(
        "relative rounded p-4 outline hover:bg-slate-100",
        isSelected ? "outline-primaryTheme outline-2" : "outline-greyLighter outline-1"
      )}
    >
      <PosCard paymentPos={paymentPos} />
    </button>
  );
};
