import { FC, useRef } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { formatQueryTitle, usePageTitle } from "@libs/hooks/usePageTitle";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getPatientQuery } from "api/patients/queries";
import { PatientProfileContent } from "components/PatientProfile/PatientProfileContent";
import { usePathParams } from "hooks/usePathParams";
import { getClinicianPrescriptionStatus } from "api/erx/queries";
import { usePatientAppointmentPathState } from "contexts/PatientAppointmentContext";
import { PatientSnapshotLayout } from "components/UI/PatientSnapshotLayout";

const useDecachePatient = (practiceId: number, patientId: number) => {
  const queryClient = useQueryClient();
  const lastDecacheId = useRef(0);

  if (lastDecacheId.current !== patientId) {
    queryClient.invalidateQueries([getQueryKey("practices", "getPatient"), { practiceId, patientId }]);
    lastDecacheId.current = patientId;
  }
};

export const PatientProfile: FC = () => {
  const { patientId } = usePathParams("patient");
  const queryClient = useQueryClient();
  const { practiceId, id: currentUserId } = useAccount();

  // we need to always make sure getPatient query is loaded on this page
  // because calling that api is what registers a new patient profile into recently viewed
  // profile search menu in the header.
  // On patient load success we decache the request for recently viewed profiles
  useDecachePatient(practiceId, patientId);

  const [patientQuery, prescriptionStatusQuery] = useApiQueries([
    getPatientQuery({
      args: { patientId, practiceId },
      queryOptions: {
        onSuccess: () => {
          queryClient.invalidateQueries([getQueryKey("v2", "getRecentSearchHistoryV2"), { practiceId }]);
        },
      },
    }),
    getClinicianPrescriptionStatus({ args: { practiceId, employeeId: currentUserId } }),
  ]);

  usePageTitle(formatQueryTitle(patientQuery, (patient) => patient.personalDetails.displayName ?? ""));

  const patientAppointment = usePatientAppointmentPathState({ patientId });

  return (
    <PatientSnapshotLayout
      onSelectAppointment={patientAppointment.handleAppointmentSelected}
      onDeleteAppointment={patientAppointment.handleAppointmentDeleted}
      appointmentId={patientAppointment.appointmentId}
      patientId={patientId}
    >
      <QueryResult queries={[prescriptionStatusQuery]}>
        {prescriptionStatusQuery.data ? (
          <PatientProfileContent showErx={prescriptionStatusQuery.data.isEnabled} />
        ) : null}
      </QueryResult>
    </PatientSnapshotLayout>
  );
};
