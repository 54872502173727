import React from "react";
import { TimeSeriesResponse } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { LongAgingOption } from "components/Dashboard/hooks/useLongAgingOptions";

export const useTimeSeriesAsAgingChartData = (
  query: ApiQueryResult<TimeSeriesResponse>,
  options: LongAgingOption[]
) => {
  const series = query.data?.timeSeries[0]?.dataPoints;
  const template = React.useMemo(() => {
    return options.map((item) => ({
      startDate: item.startDate,
    }));
  }, [options]);

  return React.useMemo(() => {
    const seriesItems = series ?? [];

    return template.map(({ startDate }) => {
      return {
        patientCount: seriesItems.find((item) => item.startDate === startDate)?.value ?? 0,
        date: startDate,
      };
    });
  }, [series, template]);
};
