import { createPortal } from "react-dom";
import { usePortalElement, PortalContext } from "@libs/contexts/PortalContext";
import { cx } from "@libs/utils/cx";
import { useQuerySelector } from "@libs/hooks/useQuerySelector";
import { ROOT_APP_LAYOUT } from "@libs/utils/constants";
import { useEffect } from "react";
import { useAppTheme } from "hooks/useAppTheme";
import { useIdAttribute } from "hooks/useIdAttribute";

export const FullScreenPortal: React.FC<{ children: React.ReactNode; isDark?: boolean }> = ({
  children,
  isDark,
}) => {
  const portal = usePortalElement();

  useAppTheme(isDark ? "dark" : "light");

  const { elementId, selectorId } = useIdAttribute("dark-room-inner-portal");
  const element = useQuerySelector(`#${ROOT_APP_LAYOUT}`);

  useEffect(() => {
    element?.classList.add("hidden");

    return () => {
      element?.classList.remove("hidden");
    };
  }, [element]);

  return (
    portal &&
    createPortal(
      <div className={cx("flex flex-col absolute z-10 inset-0 print:bg-white", isDark && "dark bg-black")}>
        <PortalContext.Provider value={selectorId}>{children}</PortalContext.Provider>
        <div id={elementId} />
      </div>,
      portal
    )
  );
};
