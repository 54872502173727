import React from "react";
import { TickLabelProps } from "@visx/axis";
import { TextProps } from "@visx/text";
import designConfig from "@libs/design.config";

export const CHART_COLORS = {
  textGreen: designConfig.colors.green,
  blueLight: "#AFD7F4",
  blue: "#007ED9",
  greenLight: "#C7E8C2",
  greenBright: "#96D995",
  greenDark: "#239284",
  blueBright: "#74D2E7",
  lavender: "#85BAF6",
};
export const BASE_CHART_MARGINS = { top: 80, right: 0, bottom: 66, left: 42 };
export const GRID_ROWS_DEFAULTS = {
  numTicks: 6,
  strokeWidth: 1,
  opacity: 0.5,
  shapeRendering: "geometricPrecision",
  stroke: designConfig.colors.greyLight,
  strokeDasharray: "3,2",
};
export const MAX_BAR_LABELS_X_ABBREVIATE = 12;
export const MAX_DAYS_X_AXIS = 31;
export const MAX_BAR_LABEL_COUNT = MAX_DAYS_X_AXIS;
export const MAX_MONTHS_X_AXIS = 14;
export const TIMESERIES_LINECHART_ACCESSORS = {
  xAccessor: (d: { date: string }) => d.date,
  yAccessor: (d: { value: number }) => d.value,
};
export const FONT_FAMILY = {
  normal: designConfig.fontFamilies.sans.join(","),
  bold: designConfig.fontFamilies.sansBold.join(","),
  semibold: designConfig.fontFamilies.sansSemiBold.join(","),
};
export const AXIS_COLORS = {
  ticks: designConfig.colors.greyLight,
  baseLine: designConfig.colors.greyLight,
};

export const useBottomTickLabelProps = <I>(params?: { clickable: boolean }) => {
  const { clickable } = params ?? { clickable: false };

  return React.useCallback(
    () => ({
      fill: designConfig.colors.greyDark,
      fontSize: 12,
      fontFamily: FONT_FAMILY.normal,
      textAnchor: "middle",
      // Must allow invisible layer below to be clicked, see rect in VisxBarStacks for clicks
      pointerEvents: clickable ? "none" : undefined,
    }),
    [clickable]
  ) as TickLabelProps<I>;
};

export const AXIS_LABEL_PROPS: Partial<TextProps> = {
  fill: designConfig.colors.greyDark,
  fontSize: 12,
  fontFamily: FONT_FAMILY.semibold,
};

export const useLeftAxisTickProps = <I>() => {
  return React.useCallback(
    () => ({
      fill: designConfig.colors.greyMedium,
      fontSize: 12,
      fontFamily: FONT_FAMILY.normal,
      textAnchor: "end",
      dy: 3,
    }),
    []
  ) as TickLabelProps<I>;
};

export type ColorScheme<T extends string> = {
  domain: T[];
  range: string[];
};

export const colorForKey = <T extends string>(colorScheme: ColorScheme<T>, key: T) => {
  return colorScheme.range[colorScheme.domain.indexOf(key)];
};
