import { FC, useMemo } from "react";
import { Link } from "react-router-dom";

import { CompanyOnboardingStatusVO, GustoErrorVO, GustoOnboardingErrorsVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { sentenceCase } from "@libs/utils/casing";
import { isOneOf } from "@libs/utils/isOneOf";
import { pluralize } from "@libs/utils/pluralize";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ReactComponent as RightArrowIcon } from "@libs/assets/icons/right-arrow.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Banner } from "@libs/components/UI/Banner";
import { MailToLink } from "components/UI/MailToLink";

import { getEmployeeNames } from "api/employee/queries";

import { paths } from "utils/routing/paths";

const cxStyles = {
  listContainer: "flex flex-col gap-y-1",
  list: (issues: number) => cx("list-inside text-red", issues > 1 ? "list-disc" : "list-none"),
};

export const PayrollIssues: FC<{
  onboardingStatus: CompanyOnboardingStatusVO["status"];
  onboardingErrors: GustoOnboardingErrorsVO;
}> = ({ onboardingStatus, onboardingErrors }) => {
  const { gustoErrorsFromOnboardingSteps, archyErrors } = onboardingErrors;

  const showPayrollIssues = useMemo(
    () =>
      isOneOf(onboardingStatus, ["ONBOARDED", "NEEDS_ONBOARDING"]) &&
      (gustoErrorsFromOnboardingSteps.length > 0 || archyErrors.length > 0),
    [onboardingStatus, gustoErrorsFromOnboardingSteps, archyErrors]
  );

  return showPayrollIssues ? (
    <div className="flex flex-col gap-y-7">
      {gustoErrorsFromOnboardingSteps.length > 0 && <GustoIssues errors={gustoErrorsFromOnboardingSteps} />}
      {archyErrors.length > 0 && <ArchyIssues errors={archyErrors} />}
      <p className="font-sansSemiBold text-sm text-greyDark4 mt-7">
        If you&apos;re still having issues, please contact Archy support at{" "}
        <MailToLink email="support@archy.com" />.
      </p>
    </div>
  ) : null;
};

const GustoIssues: FC<{ errors: GustoErrorVO[] }> = ({ errors }) => {
  const issues = pluralize(errors.length, "issue", "issues");
  const links = pluralize(errors.length, "link", "links");

  return (
    <IssuesContainer
      bannerText={`Review the Gusto ${issues} that prevent you from running payroll.`}
      actionText={`Click the ${links} below to resolve the Gusto issues required to run payroll.`}
      issuesText={`${sentenceCase(issues)} on Gusto Settings`}
      errors={errors}
    />
  );
};

const ArchyIssues: FC<{ errors: GustoErrorVO[] }> = ({ errors }) => {
  const issues = pluralize(errors.length, "issue", "issues");
  const links = pluralize(errors.length, "link", "links");

  const { practiceId } = useAccount();
  const [employeeNamesQuery] = useApiQueries([
    getEmployeeNames({ args: { practiceId, statuses: ["ACTIVE"] } }),
  ]);
  const employeeNames = employeeNamesQuery.data;

  const employeeErrors = useMemo(() => {
    if (!employeeNames) {
      return errors;
    }

    return errors.map((error) => {
      const employeeName = employeeNames.find((name) => name.id === error.employeeId);

      if (!employeeName) {
        return error;
      }

      return {
        ...error,
        message: `${employeeName.fullDisplayName}: ${error.message}`,
      };
    });
  }, [employeeNames, errors]);

  return (
    <QueryResult queries={[employeeNamesQuery]}>
      <IssuesContainer
        bannerText={`Review the Archy ${issues} that may make your payroll inaccurate.`}
        actionText={`Click the ${links} below to resolve your Archy issues and ensure your payroll is accurate. It might take a few moments to process but issues will be removed from this list as you resolve them.`}
        issuesText={`${sentenceCase(issues)} on Employee Settings`}
        errors={employeeErrors}
      />
    </QueryResult>
  );
};

const IssuesContainer: FC<{
  bannerText: string;
  actionText: string;
  issuesText: string;
  errors: GustoErrorVO[];
}> = ({ bannerText, actionText, issuesText, errors }) => {
  return (
    <div className="flex flex-col gap-y-3 font-sans text-sm text-greyDark">
      <Banner theme="error" className="text-sm">
        {bannerText}
      </Banner>
      <p className="max-w-lg">{actionText}</p>
      <div className={cx("pl-4", cxStyles.listContainer)}>
        <span className="font-sansSemiBold">{issuesText}</span>
        <ul className={cx(cxStyles.listContainer, cxStyles.list(errors.length))}>
          {errors.map((issue, index) => (
            <IssueItem key={index} issue={issue} />
          ))}
        </ul>
      </div>
    </div>
  );
};

const EMPLOYMENT_KEYWORDS = new Set([
  "start",
  "end",
  "job",
  "status",
  "w2",
  "w-2",
  "1099",
  "contractor",
  "full-time",
  "part-time",
  "temporary",
  "amount",
  "pay",
  "period",
  "overtime",
  "exempt",
  "non-exempt",
]);

const IssueItem: FC<{ issue: GustoErrorVO }> = ({ issue }) => {
  const linkToUpdate = useMemo(() => {
    const { employeeId, message } = issue;

    if (employeeId) {
      const hasEmploymentKeywords = message
        .toLowerCase()
        .split(" ")
        .some((word) => EMPLOYMENT_KEYWORDS.has(word));

      if (hasEmploymentKeywords) {
        return paths.employeeEmployment({ id: employeeId }, { edit: true });
      }

      return paths.employee({ id: employeeId }, { edit: true });
    }

    return paths.gusto();
  }, [issue]);

  return (
    <li>
      {issue.message}
      <Link
        className={`
          inline-flex
          items-center
          font-sansSemiBold
          text-primaryTheme
          gap-x-1.5
          ml-5
        `}
        to={linkToUpdate}
      >
        Update <RightArrowIcon className="w-5 h-5" />
      </Link>
    </li>
  );
};
