import { FC } from "react";
import { DentalProcedureVO } from "@libs/api/generated-api";
import { ExpiredCDTCodeIcon } from "components/Charting/ExpiredCDTCodeIcon";

interface Props {
  procedure: DentalProcedureVO;
  now: Date;
}

export const ProcedureOption: FC<Props> = ({ procedure, now }) => {
  return (
    <div className="flex items-baseline justify-stretch gap-x-2 w-full">
      <p className="flex items-center gap-x-1 min-w-[70px]">
        {procedure.cdtCode}
        <ExpiredCDTCodeIcon now={now} endDate={procedure.endDate} />
      </p>
      <p
        className={`
          flex-1
          min-w-0
          max-h-36
          line-clamp-3
          overflow-ellipsis
          overflow-hidden
        `}
      >
        {procedure.name}
      </p>
      <p className="justify-self-end">{procedure.simpleName}</p>
    </div>
  );
};
