import React, { useCallback, useMemo } from "react";
import { TransformWrapper } from "react-zoom-pan-pinch";
import { MountVO } from "@libs/api/generated-api";
import { noop } from "@libs/utils/noop";
import { ReactComponent as RefreshIcon } from "@libs/assets/icons/refresh.svg";
import { PanZoomContent } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/PanZoomContent";
import { ZoomWidget } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ZoomWidget";
import { DragLayer } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/DragLayer";
import { useMountLayout } from "components/PatientProfile/Imaging/MountRoute/hooks/useMountLayout";
import { ImagingDeviceWithSettings } from "components/PatientProfile/Imaging/hooks/useImagingDevices";
import { MIN_SCALE } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ImageSandboxLayout";
import { BaseMountImagesTableProps } from "components/UI/SelectMountImages/types";
import { ImageLayoutItem } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";

const WHEEL_STEP = 0.05;
const MAX_SCALE_ZOOM = 8;
const EMPTY_DEVICES: ImagingDeviceWithSettings[] = [];

export const TableMountImageViewerRow: React.FC<
  {
    imageMount: MountVO;
  } & Omit<BaseMountImagesTableProps, "showLink">
> = ({ ImageWrapper, onClickImage, selectedImageIds, imageMount }) => {
  // Disable all filters by removing them. This will need to be re-worked once we support
  // them as attachments. (Will need to generate a new image with it)
  const { sandboxImages } = useMountLayout({
    isViewingArchived: false,
    imageMount,
  });

  // Disable all filters by removing them. This will need to be re-worked once we support
  // them as attachments. (Will need to generate a new image with it)

  const sandboxImagesNoFilters = useMemo(
    () =>
      sandboxImages
        // eslint-disable-next-line unused-imports/no-unused-vars
        .map(({ filters, annotation, ...image }) => image),
    [sandboxImages]
  );

  const [movableImages, setMovableImages] = React.useState<ImageLayoutItem[]>(sandboxImagesNoFilters);
  const refreshMount = useCallback(() => {
    setMovableImages(sandboxImagesNoFilters);
  }, [sandboxImagesNoFilters]);

  return (
    <div className="h-[500px] w-full bg-black border-b col-span-full">
      <div
        className={`
          flex
          flex-col
          justify-center
          items-center
          relative
          h-full
          overflow-hidden
        `}
      >
        <TransformWrapper
          initialScale={0.35}
          panning={{ excluded: ["button", "draggable"] }}
          wheel={{ step: WHEEL_STEP, wheelDisabled: true }}
          minScale={MIN_SCALE}
          centerOnInit
          limitToBounds={false}
          doubleClick={{ disabled: true }}
        >
          {({ zoomIn, zoomOut, centerView }) => (
            <>
              <div className="grid grid-cols-3 mt-5 mb-2 w-full">
                <div />
                <div className="justify-self-center">
                  <ZoomWidget
                    zoomIn={zoomIn}
                    zoomOut={zoomOut}
                    show={true}
                    minScale={MIN_SCALE}
                    maxScale={MAX_SCALE_ZOOM}
                    centerView={() => centerView(MIN_SCALE)}
                  />
                </div>
                <div
                  className={`
                    flex
                    justify-right
                    items-center
                    bg-opacity-60
                    bg-black
                    text-greyLight
                    hover:bg-opacity-1
                    hover:text-white
                    justify-self-end
                    mr-6
                    cursor-pointer
                  `}
                >
                  <RefreshIcon onClick={refreshMount} />
                </div>
              </div>
              <DragLayer />
              <PanZoomContent
                key={movableImages.length}
                onEditImage={noop}
                onImageUpdate={noop}
                images={movableImages}
                zoomIn={zoomIn}
                zoomOut={zoomOut}
                isLoaded
                selectedImageIds={selectedImageIds}
                onImageOriginChanged={(changedImage, origin) => {
                  setMovableImages((prior) =>
                    prior.map((image) => {
                      return changedImage.id === image.id ? { ...image, x: origin.x, y: origin.y } : image;
                    })
                  );
                }}
                devices={EMPTY_DEVICES}
                onClickItem={onClickImage ?? noop}
                ImageWrapper={ImageWrapper}
              />
            </>
          )}
        </TransformWrapper>
      </div>
    </div>
  );
};
