import React, { ReactNode } from "react";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { StylesConfig } from "react-select";
import { DentalProcedureVO } from "@libs/api/generated-api";
import { FormFieldProps } from "@libs/components/UI/FormField";
import { ProcedureOption } from "components/ProcedureSelector/ProcedureOption";
import { ProcedureValue } from "components/ProcedureSelector/ProcedureValue";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { ProcedureDataCode } from "components/ProcedureSelector/ProcedureSelector";
import { useNow } from "hooks/useNow";

type ProcedureSelectorSingle = FormFieldProps & {
  codes: ProcedureDataCode[];
  handleFilterOption: (option: FilterOptionOption<ProcedureDataCode>, searchText: string) => boolean;
  onChange?: (newValue?: DentalProcedureVO) => void;
  placeholder?: ReactNode;
  openMenuOnFocus?: boolean;
  value?: DentalProcedureVO;
  styles?: StylesConfig<ProcedureDataCode, false, GroupedSelectOption<string, ProcedureDataCode>>;
};

export const ProcedureSelectorSingleSelect: React.FC<ProcedureSelectorSingle> = ({
  codes,
  handleFilterOption,
  onChange,
  placeholder,
  value,
  ...formFieldProps
}) => {
  const now = useNow();
  const selectedValue = value?.cdtCode;

  return (
    <FormFieldSelect
      {...formFieldProps}
      styles={{
        menu: () => ({
          width: "100%",
        }),
        ...formFieldProps.styles,
      }}
      placeholder={placeholder}
      options={codes}
      onChange={(val) => {
        onChange?.(val?.data);
      }}
      value={selectedValue}
      filterOption={handleFilterOption}
      hideSelectedOptions={false}
      formatOptionLabel={(option: ProcedureDataCode, meta) =>
        meta.context === "value" ? (
          <ProcedureValue procedure={option.data} now={now} />
        ) : (
          <ProcedureOption procedure={option.data} now={now} />
        )
      }
    />
  );
};
