import { Link } from "react-router-dom";
import { formatFullNameToInitials } from "@libs/utils/formatString";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { paths } from "utils/routing/paths";
import { PatientTooltip, PatientTooltipProps } from "components/Patient/PatientTooltip";

export const PatientDisc: React.FC<PatientTooltipProps> = (props) => {
  return (
    <FloatingTooltip content={<PatientTooltip {...props} />} displayArrow={false} placement="bottom-start">
      <Link
        className={`
          flex
          items-center
          justify-center
          bg-greyLightest
          rounded-full
          h-6
          w-6
          border
          border-white
          text-xxs
          font-sansSemiBold
        `}
        to={paths.patient({ patientId: props.name.id })}
        type="button"
      >
        {formatFullNameToInitials({ fullName: props.name.shortDisplayName })}
      </Link>
    </FloatingTooltip>
  );
};
