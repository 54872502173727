import React, { useCallback, useRef } from "react";
import { AppointmentRequest } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getAppointmentCategoriesQuery, getPracticeRoomsQuery } from "api/scheduling/queries";
import { getPatientSummary } from "api/patients/queries";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { paths } from "utils/routing/paths";
import { getPatientChartV2, getPatientProceduresQuery } from "api/charting/queries";
import { addAppointment } from "api/scheduling/mutations";
import { useQueryParams } from "hooks/useQueryParams";
import { EventPatientSnapshotLayout } from "components/ScheduleAppointments/EventPatientSnapshotLayout";
import { useAppointmentRoute } from "components/ScheduleAppointments/useAppointmentRoute";
import { EventModalPage } from "components/ScheduleAppointments/EventModalPage";
import { EventModalTitleContent } from "components/ScheduleAppointments/EventModalTitleContent";
import { BenefitLimitationIgnoreConfirmationModal } from "components/ScheduleAppointments/BenefitLimitationIgnoreConfirmationModal";
import { getAppointmentMutatedLabel, isUnscheduledAsapAppt } from "components/ScheduleAppointments/utils";
import { AddAppointmentForm } from "components/ScheduleAppointments/AddAppointmentForm";
import { getTags } from "api/settings/notes/queries";
import { usePatientAppointmentQueryState } from "contexts/PatientAppointmentContext";

const formId = "add-patient-appointment-form";

// eslint-disable-next-line complexity
export const AddPatientAppointmentRoute: React.FC = () => {
  const { practiceId } = useAccount();
  const appointmentRoute = useAppointmentRoute();
  const { query } = useQueryParams("addPatientAppointment");
  const patientAppointment = usePatientAppointmentQueryState({ defaultPatientToMemory: false });
  const patientId = patientAppointment.patientId ?? 0;
  const { from, patientProcedureIds, findFromDate } = query;

  const initialPatientIdRef = useRef(patientId);

  const [
    categoriesQuery,
    providersQuery,
    roomsQuery,
    patientProceduresQuery,
    tagsQuery,
    chartV2Query,
    patientQuery,
  ] = useApiQueries([
    getAppointmentCategoriesQuery({ args: { practiceId } }),
    getPracticeProvidersQuery({ args: { practiceId } }),
    getPracticeRoomsQuery({ args: { practiceId } }),
    getPatientProceduresQuery({
      args: {
        practiceId,
        patientId,
        ids: patientProcedureIds,
        pageSize: patientProcedureIds?.length ?? 0,
        pageNumber: 1,
      },
      queryOptions: { enabled: Boolean(patientProcedureIds && patientId) },
    }),
    getTags({ args: { practiceId } }),
    getPatientChartV2({
      args: { patientId, practiceId },
      queryOptions: {
        enabled: Boolean(patientId),
      },
    }),
    getPatientSummary({
      args: { practiceId, patientId: initialPatientIdRef.current },
      queryOptions: { enabled: Boolean(initialPatientIdRef.current) },
    }),
  ]);

  const [addAppointmentMutation] = useApiMutations([addAppointment]);

  const backUrl = from ?? paths.schedule();

  const handleSelectedPatient = (item: number | undefined) => {
    patientAppointment.handlePatientSelected(item);
  };

  const addAppointmentMutate = addAppointmentMutation.mutate;
  const handleSaveError = appointmentRoute.handleSaveError;
  const handleSaveSuccess = appointmentRoute.handleSaveSuccess;

  const handleSubmit = useCallback(
    (data: AppointmentRequest) => {
      addAppointmentMutate(
        {
          practiceId,
          data,
        },
        {
          onSuccess: handleSaveSuccess,
          onError: handleSaveError(data),
        }
      );
    },
    [addAppointmentMutate, practiceId, handleSaveSuccess, handleSaveError]
  );
  const isSavedAppointmentUnscheduled = isUnscheduledAsapAppt(appointmentRoute.savedAppointment?.date);

  return (
    <EventPatientSnapshotLayout
      appointmentId={patientAppointment.appointmentId}
      onDeleteAppointment={patientAppointment.handleAppointmentDeleted}
      onSelectAppointment={patientAppointment.handleAppointmentSelected}
      patientId={patientId}
      from={backUrl}
    >
      <QueryResult
        queries={[
          categoriesQuery,
          providersQuery,
          roomsQuery,
          patientProceduresQuery,
          patientQuery,
          tagsQuery,
        ]}
      >
        {(!initialPatientIdRef.current || patientQuery.data) &&
        (!patientProcedureIds || patientProceduresQuery.data) &&
        categoriesQuery.data &&
        providersQuery.data &&
        roomsQuery.data &&
        tagsQuery.data ? (
          <EventModalPage
            formId={formId}
            backUrl={backUrl}
            isSaving={addAppointmentMutation.isLoading}
            isFormValid={appointmentRoute.formValid.isOn}
            hasSavedEvent={Boolean(appointmentRoute.savedAppointment)}
            eventUrl={appointmentRoute.appointmentUrl}
            title={<EventModalTitleContent title="Add Appointment" />}
            isUnscheduled={isSavedAppointmentUnscheduled}
            savedValue={appointmentRoute.savedAppointment}
            isDirty={appointmentRoute.formDirty.isOn}
            savedMessage={getAppointmentMutatedLabel({
              isUnscheduled: isSavedAppointmentUnscheduled,
              wasCreated: true,
            })}
            promptMessage="Do you want to discard this appointment?"
          >
            <AddAppointmentForm
              initialTab="findAppointment"
              findFromDate={findFromDate}
              providers={providersQuery.data}
              categories={categoriesQuery.data}
              rooms={roomsQuery.data}
              formId={formId}
              teeth={chartV2Query.data}
              patientId={patientId}
              patientProcedures={patientProceduresQuery.data}
              onSubmit={handleSubmit}
              onValidationChange={appointmentRoute.formValid.set}
              onDirty={appointmentRoute.formDirty.on}
              tags={tagsQuery.data}
              onSelectedPatient={handleSelectedPatient}
              patientQuery={patientQuery}
            />
            {appointmentRoute.benefitLimitationModal.isOpen ? (
              <BenefitLimitationIgnoreConfirmationModal
                isSaving={addAppointmentMutation.isLoading}
                onCancel={appointmentRoute.benefitLimitationModal.close}
                onConfirm={handleSubmit}
                {...appointmentRoute.benefitLimitationModal.item}
              />
            ) : null}
          </EventModalPage>
        ) : null}
      </QueryResult>
    </EventPatientSnapshotLayout>
  );
};
