/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from "react";
import { ReactComponent as RotateLeft } from "@libs/assets/icons/rotate-left.svg";
import { ReactComponent as RotateRight } from "@libs/assets/icons/rotate-right.svg";
import { ReactComponent as MirrorX } from "@libs/assets/icons/flip-horizontal.svg";
import { ReactComponent as MirrorY } from "@libs/assets/icons/flip-vertical.svg";
import { ToolbarButton } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ToolbarButton";
import { ToolBoat } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ToolBoat";
import { META_KEY } from "utils/keyboard";

export type TransformToolProps = {
  onRotate: (deg: 90 | 270) => void;
  onMirror: (axis: "X" | "Y") => void;
};

const disabledTooltip = "Select an image first";

export const TransformToolboat: React.FC<TransformToolProps & { disabled?: boolean }> = ({
  onRotate,
  onMirror,
  disabled,
}) => {
  const props = { disabled };
  const onKeydown = React.useCallback(
    (e: KeyboardEvent) => {
      if (disabled) {
        return;
      }

      const { ctrlKey, key, metaKey } = e;

      const isModifierKey = ctrlKey || metaKey;

      if (isModifierKey && ["ArrowRight", "ArrowLeft"].includes(key)) {
        e.preventDefault();

        if (key === "ArrowRight") {
          onRotate(90);
        } else if (key === "ArrowLeft") {
          onRotate(270);
        }
      }
    },
    [disabled, onRotate]
  );

  React.useEffect(() => {
    document.addEventListener("keydown", onKeydown);

    return () => {
      document.removeEventListener("keydown", onKeydown);
    };
  }, [onKeydown]);

  return (
    <ToolBoat>
      <ToolbarButton
        {...props}
        tooltip={disabled ? disabledTooltip : `Rotate left (${META_KEY} + ←)`}
        SvgIcon={RotateLeft}
        size="md"
        onClick={() => onRotate(270)}
      />
      <ToolbarButton
        {...props}
        tooltip={disabled ? disabledTooltip : `Rotate right (${META_KEY} + →)`}
        SvgIcon={RotateRight}
        size="md"
        onClick={() => onRotate(90)}
      />
      <ToolbarButton
        {...props}
        tooltip={disabled ? disabledTooltip : "Mirror X"}
        SvgIcon={MirrorX}
        size="md"
        onClick={() => onMirror("X")}
      />
      <ToolbarButton
        {...props}
        tooltip={disabled ? disabledTooltip : "Mirror Y"}
        SvgIcon={MirrorY}
        size="md"
        onClick={() => onMirror("Y")}
      />
    </ToolBoat>
  );
};
