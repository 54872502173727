import { PatientInsuranceResponse } from "@libs/api/generated-api";
import { isNullish } from "@libs/utils/types";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { SectionHeader, SectionRow } from "components/PatientProfile/Insurance/InsurancePlans/TableItems";

interface Props {
  insurances: PatientInsuranceResponse[];
}

export const SubscriberNotesRows: React.FC<Props> = ({ insurances }) => {
  return (
    <>
      <SectionHeader title="Subscriber Notes" />
      <SectionRow
        insurances={insurances}
        renderValue={({ patientInsurance }) => {
          const note = patientInsurance.subscriber.notes;

          return <div>{isNullish(note) ? EMPTY_CELL : note}</div>;
        }}
        title=""
      />
    </>
  );
};
