import React, { ReactNode } from "react";
import { LayoutCard } from "@libs/components/UI/LayoutCard";

interface Props {
  children: ReactNode;
  title: string | ReactNode;
}

export const SettingsListPanel: React.FC<Props> = ({ children, title }) => {
  return (
    <LayoutCard className="flex flex-col h-full overflow-y-auto">
      <div
        className={`
          flex-none
          flex
          flex-row
          items-center
          h-14
          px-6
          font-sansSemiBold
          border-b
          border-greyLighter
        `}
      >
        {title}
      </div>
      {children}
    </LayoutCard>
  );
};
