import { FC, useCallback, useMemo } from "react";
import { offset, shift } from "@floating-ui/react-dom";
import { ScheduleBlockVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatISOTimeRangeAsAmPmShort, getISOTimeDiffInMinutes } from "@libs/utils/date";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { useMenu } from "@libs/components/UI/Menu";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { useBoolean } from "@libs/hooks/useBoolean";
import { handleDoubleClick } from "utils/handleDoubleClick";

import { BlockCardMenu } from "components/ScheduleAppointments/BlockCardMenu";
import { BlockCardDetails } from "components/ScheduleAppointments/BlockCardDetails";
import { BlockCardTooltip } from "components/ScheduleAppointments/BlockCardTooltip";
import { APPOINTMENT_DURATION_FOR_TWO_LINES } from "components/ScheduleAppointments/AppointmentCardDetails";

interface Props {
  isHovered?: boolean;
  block: ScheduleBlockVO;
  groupingContextDetail?: string;
  onRequestDeleteBlock: (block: ScheduleBlockVO) => void;
  onRequestEditBlock: (block: ScheduleBlockVO) => void;
}

const cxStyles = {
  container: `
    rounded
    h-full
    text-xxs
    text-greyDark
    relative
    left-0.5
    w-[98%]
    border
    border-greyLighter
  `,

  body: ({ isMultiLineHeight }: { isMultiLineHeight: boolean }) =>
    cx("absolute top-0 left-0 w-full px-4 flex flex-col h-full", isMultiLineHeight && "py-1"),
  truncate: "truncate w-full",
  patientName: ({ isNoShow }: { isNoShow: boolean }) =>
    cx("text-xs font-sansSemiBold truncate w-full", isNoShow && "pr-5"),
};

export const BlockCard: FC<Props> = ({
  block,
  groupingContextDetail,
  onRequestDeleteBlock,
  onRequestEditBlock,
  isHovered,
}) => {
  const tooltip = useBoolean(false);
  const actionsMenu = useMenu<HTMLButtonElement>();
  const duration = useMemo(() => getISOTimeDiffInMinutes(block.endTime, block.startTime), [block]);
  const timeSpan = useMemo(() => formatISOTimeRangeAsAmPmShort(block.startTime, block.endTime), [block]);
  const isMultiLineHeight = duration > APPOINTMENT_DURATION_FOR_TWO_LINES;

  const handleRequestEdit = useCallback(() => {
    onRequestEditBlock(block);
  }, [block, onRequestEditBlock]);

  return (
    <div
      style={{
        background: `
          repeating-linear-gradient(
            -22.5deg,
            ${block.color}55,
            ${block.color}55 2px,
            ${block.color}3e 2px,
            ${block.color}3e 4px
          ),
          #fff
        `,
      }}
      className={cx("min-h-1", cxStyles.container)}
    >
      <ButtonMenu
        // eslint-disable-next-line @typescript-eslint/no-magic-numbers
        middleware={[shift({ crossAxis: true }), offset(6)]}
        placement="right-start"
        onRequestOpen={actionsMenu.open}
        onRequestClose={actionsMenu.close}
        isOpen={actionsMenu.isOpen}
        className={cx("right-0 z-10 absolute", isMultiLineHeight ? "top-1" : "top-0")}
        menuContent={
          <BlockCardMenu
            onRequestEditBlock={() => {
              actionsMenu.close();
              onRequestEditBlock(block);
            }}
            onRequestDeleteBlock={() => {
              actionsMenu.close();
              onRequestDeleteBlock(block);
            }}
          />
        }
      >
        {(props) => <ButtonIcon {...props} SvgIcon={MenuIcon} theme="slate700" size="sm" />}
      </ButtonMenu>
      <FloatingTooltip
        forceOpen={isHovered || tooltip.isOn}
        content={
          <div onMouseEnter={tooltip.on} onMouseLeave={tooltip.off}>
            <BlockCardTooltip
              timeSpan={timeSpan}
              block={block}
              groupingContextDetail={groupingContextDetail}
            />
          </div>
        }
        placement="left"
      >
        <div className={cxStyles.body({ isMultiLineHeight })}>
          <BlockCardDetails
            duration={duration}
            timeSpan={timeSpan}
            block={block}
            groupingContextDetail={groupingContextDetail}
            onClick={handleDoubleClick(handleRequestEdit)}
            className="text-left w-full"
          />

          {isMultiLineHeight ? (
            <button
              type="button"
              onClick={handleDoubleClick(handleRequestEdit)}
              className="w-full flex-grow"
            />
          ) : null}
        </div>
      </FloatingTooltip>
    </div>
  );
};
