import React, { useMemo } from "react";
import { CollectedPaymentEntryVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { usePick } from "@libs/hooks/usePick";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { FooterCell } from "components/Dashboard/Tables/FooterCell";
import { useQueryParams } from "hooks/useQueryParams";
import { ExportCsvButton } from "components/Dashboard/ExportCsvButton";
import { useFilterTokenProps } from "components/Dashboard/hooks/useFilterTokenProps";
import { DashboardTableFilters } from "components/Dashboard/Tables/DashboardTableFilters";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import { MAX_PAGE_SIZE, MAX_PAGE_SIZE_DOWNLOAD, getTotals } from "components/Dashboard/Tables/utils";
import { useFlattenEntries } from "components/Dashboard/hooks/useFlattenEntries";
import { DashboardFilterFormFieldSelect } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";
import { labelForPaymentMethod } from "components/Dashboard/PaymentCollected/utils";
import { DashboardTableTabs } from "components/Dashboard/Tables/DashboardTableTabs";
import { useTableTabProps } from "components/Dashboard/PaymentCollected/hooks/useTableTabProps";
import { useDownloadPaymentCollectedByProviderCSV } from "components/Dashboard/PaymentCollected/hooks/useDownloadPaymentCollectedByProviderCSV";
import { getCollectedByProviderInfiniteQuery } from "api/reporting/queries";
import { useDashboardTableRequestParams } from "components/Dashboard/hooks/useDashboardTableRequestParams";
import { ByProviderTableRow } from "components/Dashboard/PaymentCollected/ByProviderTableRow";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";
import { DashboardSelectProviderFilter } from "components/Dashboard/Tables/DashboardSelectProviderFilter";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { PaymentCollectedQuery } from "utils/routing/dashboard";
import { BY_PROVIDER_TABLE_HEADERS } from "components/Dashboard/PaymentCollected/constants";

const PAYMENT_METHODS: CollectedPaymentEntryVO["paymentMethod"][] = [
  "ACH",
  "CASH",
  "CARD",
  "EXTERNAL_CARD",
  "CHECK",
  "CARE_CREDIT",
  "THIRD_PARTY_FINANCING",
  "WALLET",
];
const PAYMENT_METHOD_OPTIONS = PAYMENT_METHODS.map((method) => ({
  label: labelForPaymentMethod(method),
  value: method,
}));

export const ByProviderTableRoute: React.FC = () => {
  const { practiceId } = useAccount();
  const { updateQuery, query } = useQueryParams("dashboardPaymentCollected");
  const sortOrders = query.providerTableSort;
  const [providersQuery] = useApiQueries([getPracticeProvidersQuery({ args: { practiceId } })]);
  const { params } = useDashboardTableRequestParams(query);
  const queryParams = useMemo(
    () => ({
      practiceId,
      pageSize: MAX_PAGE_SIZE,
      pageNumber: 1,
      data: {
        ...params,
        sortOrders,
      },
    }),
    [params, practiceId, sortOrders]
  );
  const handleRouteStateChange = React.useCallback(
    (updates: Partial<PaymentCollectedQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );
  const collectedPaymentQuery = useInfiniteApiQuery(
    getCollectedByProviderInfiniteQuery({ args: queryParams })
  );
  const { isLoading, data } = collectedPaymentQuery;
  const pagingDetails = getInfiniteQueryPagingDetails(data);

  const totals = getTotals(data);

  const rows = useFlattenEntries(data);

  const isEmpty = !isLoading && rows.length === 0;

  const handleSortClick = React.useCallback(
    (providerTableSort: ColumnSortOrder[]) => {
      updateQuery("replaceIn", {
        providerTableSort,
      });
    },
    [updateQuery]
  );
  const filterParams = usePick(query, ["filters", "patientSearch"]);
  const filterProps = useFilterTokenProps({
    options: { ...filterParams, labelProviderAsPrimary: true },
    providers: providersQuery.data,
  });

  const { downloadCSV: downloadPaymentCollectedCsv, isDownloading } =
    useDownloadPaymentCollectedByProviderCSV(queryParams);

  const tableTabProps = useTableTabProps();
  const providerFilterProps = {
    providers: providersQuery.data,
    includeNoProviderOption: true,
    filters: query.filters,
    onChangeFilters: handleRouteStateChange,
  };

  return (
    <div className="h-full flex-1 flex flex-col min-h-0">
      <DashboardTableTabs
        id="payment-collected-tabs-by-patient"
        {...tableTabProps}
        includePatientSearch={false}
      >
        <DashboardSelectProviderFilter
          {...providerFilterProps}
          providerFilterType="providerId"
          title="Primary Provider"
        />
        <DashboardSelectProviderFilter
          {...providerFilterProps}
          providerFilterType="associatedProviderId"
          title="Associated Provider"
        />
        <DashboardFilterFormFieldSelect
          placeholder="Payment Method"
          isSearchable={false}
          options={PAYMENT_METHOD_OPTIONS}
          className="min-w-[180px]"
          dashboardFilters={query.filters}
          dashboardFilterType="paymentMethod"
          onChangeFilters={handleRouteStateChange}
        />
        <FormFieldInput
          layout="labelIn"
          inputClassName="pr-8"
          Icon={SearchIcon}
          placeholder="Search Patient"
          onChange={(e) => {
            updateQuery("replaceIn", {
              patientSearch: e.target.value,
            });
          }}
          value={query["patientSearch"]}
        />
        <ExportCsvButton
          onClick={downloadPaymentCollectedCsv}
          isDownloading={isDownloading}
          disabled={(pagingDetails?.totalPages ?? 0) > MAX_PAGE_SIZE_DOWNLOAD}
        />
      </DashboardTableTabs>

      <DashboardTableFilters {...filterParams}>
        <QueryFilterPills
          {...filterProps}
          onUpdateParams={handleRouteStateChange}
          onClearAll={() => {
            handleRouteStateChange({ filters: [], patientSearch: undefined });
          }}
        />
      </DashboardTableFilters>
      <DashboardInfiniteTable
        columnWidths={BY_PROVIDER_TABLE_HEADERS.map(({ width }) => width)}
        id="paymentCollectedByPatientTable"
        isEmpty={isEmpty}
        infiniteQuery={collectedPaymentQuery}
        headerRow={
          <DashboardHeaderRow
            headers={BY_PROVIDER_TABLE_HEADERS}
            onSortClick={handleSortClick}
            sortOrders={query.providerTableSort}
          />
        }
        footerRow={
          <>
            <FooterCell className="col-span-7">Totals</FooterCell>
            <FooterCell loading={isLoading} align="right">
              {totals ? formatCurrency(totals.totalPaymentAmount) : ""}
            </FooterCell>
          </>
        }
      >
        {rows.map((item, i) => {
          const key = `${item.provider?.id ?? "no-provider"}-${
            item.associatedProvider?.id ?? "no-associated-provider"
          }_${item.paymentUuid}_${i}`;

          return <ByProviderTableRow key={key} data={item} last={i === rows.length - 1} />;
        })}
      </DashboardInfiniteTable>
    </div>
  );
};
