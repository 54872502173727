import { ChangeEvent, FC, useMemo } from "react";
import { FormSelectInputElementVO, FormSelectResponseVO } from "@libs/api/generated-api";
import { isDefined, isNullish } from "@libs/utils/types";
import { Icon } from "@libs/components/UI/Icon";
import { RadioList } from "@libs/components/UI/RadioList";
import { ReactComponent as SubItem } from "@libs/assets/icons/sub-item.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormSubmissionResponses } from "components/PatientProfile/Forms/types";
import { OTHER_VALUE } from "components/PatientProfile/Forms/utils";

const otherResponse = {
  type: "SELECT" as const,
  responses: {},
  other: "",
};

const getValueResponse = (newValue: string) => ({
  type: "SELECT" as const,
  responses: {
    [newValue]: true,
  },
});

export const SelectSingleInput: FC<{
  element: FormSelectInputElementVO;
  response?: FormSelectResponseVO;
  onUpdate?: (updater: (last: FormSubmissionResponses) => void) => void;
}> = ({ element, onUpdate, response }) => {
  const edit = Boolean(onUpdate);
  const allowsOther = element.settings.includes("ALLOW_ADDITIONAL_OPTION");
  const otherText = response?.other;

  const options = useMemo(() => {
    const mapped = element.options.map((o) => ({ label: o, value: o }));

    if (allowsOther) {
      mapped.push({ label: "Other", value: OTHER_VALUE });
    }

    return mapped;
  }, [element.options, allowsOther]);

  const selectedValue = useMemo(() => {
    const selections = Object.keys(response?.responses ?? {});

    if (isDefined(otherText)) {
      selections.push(OTHER_VALUE);
    }

    return selections[0];
  }, [otherText, response?.responses]);

  const readOnlyValue = useMemo(() => {
    return isDefined(otherText) ? `Other${otherText ? ` - ${otherText}` : ""}` : selectedValue;
  }, [otherText, selectedValue]);

  const handleSelectionChange = (newValue: string) => {
    onUpdate?.((currentResponses) => {
      const currentResponse = currentResponses[element.uuid];

      if (currentResponse?.type === "SELECT") {
        if (newValue === OTHER_VALUE) {
          if (currentResponse.other === undefined) {
            currentResponses[element.uuid] = otherResponse;
          } else {
            delete currentResponses[element.uuid];
          }
        } else if (currentResponse.responses[newValue]) {
          delete currentResponses[element.uuid];
        } else {
          currentResponses[element.uuid] = getValueResponse(newValue);
        }
      } else if (!currentResponse) {
        currentResponses[element.uuid] =
          newValue === OTHER_VALUE ? otherResponse : getValueResponse(newValue);
      }
    });
  };

  const handleOtherChange = (e: ChangeEvent<HTMLInputElement>) => {
    onUpdate?.((currentResponses) => {
      const currentValue = currentResponses[element.uuid];

      if (currentValue?.type === "SELECT") {
        currentValue.other = e.target.value;
      }
    });
  };

  return edit ? (
    <>
      <RadioList
        options={options}
        layout="vert"
        label={element.title}
        selectedValue={selectedValue}
        onClick={(_, option) => handleSelectionChange(option.value)}
      />
      {isNullish(otherText) ? null : (
        <div className="flex items-center gap-x-2">
          <Icon size="lg" SvgIcon={SubItem} />
          <FormFieldInput className="flex-1" value={otherText} onChange={handleOtherChange} />
        </div>
      )}
    </>
  ) : (
    <FormFieldInput edit={false} label={element.title} value={readOnlyValue} />
  );
};
