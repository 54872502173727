import { FC } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { CustomAdjustmentsSettings } from "components/Settings/Payments/CustomAdjustmentsSettings";
import { getAdjustmentTypesForPractice } from "api/settings/payments/queries";

export const BillingCustomAdjustmentsRoute: FC = () => {
  const { practiceId } = useAccount();

  const [adjustmentsQuery] = useApiQueries([getAdjustmentTypesForPractice({ args: { practiceId } })]);

  return (
    <SettingsPanel
      title="Custom Adjustments"
      description="Set up custom adjustments to apply discounts or additional charges to an invoice."
    >
      <QueryResult queries={[adjustmentsQuery]}>
        <CustomAdjustmentsSettings adjustments={adjustmentsQuery.data} />
      </QueryResult>
    </SettingsPanel>
  );
};
