import { useCallback } from "react";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { useEnvContext } from "contexts/EnvContext";

export const usePrintForm = () => {
  const practice = useCurrentPractice();
  const { REACT_APP_PATIENT_PORTAL_HOST } = useEnvContext();
  const getFormPrintLink = useCallback(
    ({ patientDob, formTaskUuids }: { patientDob: string; formTaskUuids: string[] }) =>
      `${REACT_APP_PATIENT_PORTAL_HOST}/p/${practice.uuid}/form-tasks?formTaskUuids=${formTaskUuids.join(
        encodeURIComponent(",")
      )}&dob=${patientDob}&print=1`,
    [REACT_APP_PATIENT_PORTAL_HOST, practice.uuid]
  );

  return { getFormPrintLink };
};
