import { FC } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { RichTextPlugin } from "@lexical/react/LexicalRichTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { TabIndentationPlugin } from "@lexical/react/LexicalTabIndentationPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { TemplateVariableGroupVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { TemplateVariablePlugin } from "components/UI/RichTextEditor/TemplateVariablePlugin";
import { TemplateVariableNode } from "components/UI/RichTextEditor/TemplateVariableNode";
import { useSafeInitialState } from "components/UI/RichTextEditor/useSafeInitialState";
import { ToolbarNodes, ToolbarPlugin, ToolbarTheme } from "components/UI/RichTextEditor/ToolbarPlugin";
import { useDebouncedOnEditorStateChange } from "components/UI/RichTextEditor/useDebouncedOnChange";
import { EditorProps } from "components/UI/RichTextEditor/types";
import { Placeholder } from "components/UI/RichTextEditor/Placeholder";

export interface RichTextEditorProps extends EditorProps {
  templateVariables?: TemplateVariableGroupVO[];
}

export const RichTextEditor: FC<RichTextEditorProps> = ({
  initialConfig,
  onChange,
  placeholder,
  initialEditorState,
  templateVariables,
  ...contentEditableProps
}) => {
  const safeInitialState = useSafeInitialState(initialEditorState);
  const handleChange = useDebouncedOnEditorStateChange(onChange);

  return (
    <div className="flex flex-col gap-y-2 h-full">
      <LexicalComposer
        initialConfig={{
          ...initialConfig,
          nodes: [...ToolbarNodes, TemplateVariableNode],
          editorState: safeInitialState,
          theme: ToolbarTheme,
        }}
      >
        <ToolbarPlugin className="flex-0" />
        <div className="relative flex-1 min-h-0">
          <RichTextPlugin
            contentEditable={
              <ContentEditable
                {...contentEditableProps}
                className={cx(
                  `overflow-y-auto
                   h-full
                   text-left
                   text-xs
                   focus-visible:outline-none`,
                  contentEditableProps.className
                )}
              />
            }
            placeholder={<Placeholder content={placeholder} />}
            ErrorBoundary={LexicalErrorBoundary}
          />
        </div>
        <TabIndentationPlugin />
        <OnChangePlugin onChange={handleChange} />
        <HistoryPlugin />
        {templateVariables ? <TemplateVariablePlugin templateVariables={templateVariables} /> : <div />}
      </LexicalComposer>
    </div>
  );
};
