import { forwardRef } from "react";
import { cx } from "@libs/utils/cx";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { useMergeFormContext, useFormContext } from "@libs/contexts/FormContext";
import { FormField, FormFieldProps } from "@libs/components/UI/FormField";
import { cxFormFieldStyle } from "@libs/components/UI/formFieldStyle";
import { formatNumber, NumberInput, NumberInputProps } from "@libs/components/UI/NumberInput";

interface BaseProps {
  Icon?: IconComponent;
  displayErrorMessage?: boolean;
  inputClassName?: string;
  containerClassName?: string;
}

export type FormFieldNumberInputProps = BaseProps &
  FormFieldProps &
  Omit<NumberInputProps, keyof FormFieldProps>;

const formatDisplayValue = (value: FormFieldNumberInputProps["value"]) => {
  if (value || value === 0) {
    return formatNumber(`${value}`);
  }

  return "-";
};

export const FormFieldNumberInput = forwardRef<HTMLInputElement, FormFieldNumberInputProps>(
  (
    {
      disabled,
      required,
      label,
      error,
      Icon,
      className,
      children,
      layout,
      value,
      id,
      edit = true,
      displayErrorMessage = true,
      inputClassName,
      containerClassName,
      ...rest
    },
    ref
  ) => {
    const fieldId = useEnsureId({ customId: id });
    const formContext = useFormContext();
    const mergedFormContext = useMergeFormContext(formContext, { layout });
    const controlStyles = cxFormFieldStyle.control({
      hasIcon: Boolean(Icon),
      hasLabel: Boolean(label),
      layout: mergedFormContext.layout,
    });

    return (
      <FormField
        disabled={disabled}
        required={required}
        label={label}
        error={error}
        layout={mergedFormContext.layout}
        className={className}
        containerClassName={containerClassName}
        edit={edit}
        displayErrorMessage={displayErrorMessage}
        id={fieldId}
      >
        {edit ? (
          <div className={cxFormFieldStyle.wrapper}>
            <NumberInput
              id={fieldId}
              ref={ref}
              value={value}
              disabled={disabled}
              className={cx(controlStyles, cxFormFieldStyle.input, inputClassName)}
              {...rest}
            />
            {Icon ? (
              <div className={cxFormFieldStyle.iconContainer({ layout: mergedFormContext.layout })}>
                <Icon role="img" className={cxFormFieldStyle.icon({ disabled })} />
              </div>
            ) : null}
            {children}
          </div>
        ) : (
          <span className={cxFormFieldStyle.controlValueOnly({ layout: mergedFormContext.layout })}>
            {formatDisplayValue(value)}
          </span>
        )}
      </FormField>
    );
  }
);
