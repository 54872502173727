import React, { useCallback, useMemo } from "react";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { useQueryClient } from "@tanstack/react-query";
import { format } from "date-fns";
import { MedicalImageVO, PatientSummaryVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { blobToFile } from "@libs/utils/dataUrl";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { getQueryKey } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { useNavigate, useLocation } from "react-router-dom";
import { getFullUrl } from "@libs/utils/location";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { AlertModal } from "@libs/components/UI/AlertModal";
import { useQueryParams } from "hooks/useQueryParams";
import { FullScreenPortal } from "components/PatientProfile/Imaging/shared/FullScreenPortal";
import { usePathParams } from "hooks/usePathParams";
import { getMedicalImageDetails, getMedicalImages, getPracticeImagingSettings } from "api/imaging/queries";
import { CompareImagesHeader } from "components/PatientProfile/Imaging/CompareImages/CompareImagesHeader";
import { ImageSidebarNavigation } from "components/PatientProfile/Imaging/ImageSidebarNavigation";
import { useCameraCapture } from "components/ImageCapturing/useCameraCapture";
import { useImagingDevices } from "components/PatientProfile/Imaging/hooks/useImagingDevices";
import {
  UseImageEditQueue,
  useImageEditQueue,
} from "components/PatientProfile/Imaging/MountRoute/hooks/useImageEditQueue";
import {
  MOUNT_FORMAT_NUMBER_SLOTS,
  useImageSidebarNavigation,
} from "components/PatientProfile/Imaging/hooks/useImageSidebarNavigation";
import { ImageSideBySideCapture } from "components/PatientProfile/Imaging/ImageSidebarNavigation/ImageSideBySideCapture";
import { LoadingDarkRoom } from "components/PatientProfile/Imaging/LoadingDarkRoom";
import {
  CompareImagingSortType,
  ImagingCompareQuery,
  MountFormat,
  PatientDocumentsQuery,
} from "utils/routing/patient";
import { useDocumentUploader } from "components/UI/DocumentUploadManager";
import { createFolder } from "api/documents/mutations";
import { handleError } from "utils/handleError";
import { getPatientSummary } from "api/patients/queries";
import { paths } from "utils/routing/paths";
import { useItemModal } from "hooks/useItemModal";
import { ImageSharedToolbarProps } from "components/PatientProfile/Imaging/MountRoute/types";
import { ImageListContextProvider } from "components/PatientProfile/Imaging/shared/ImageListContext";

type Props = {
  medicalImagesInfiniteQuery: UseInfiniteApiQueryResult<MedicalImageVO[]>;
  patient: PatientSummaryVO;
  sortBy: CompareImagingSortType;
  onUpdateParams: (params: Partial<ImagingCompareQuery>) => void;
  params: ImagingCompareQuery;
  onEditImage: (image: Pick<MedicalImageVO, "id">) => void;
  imageSelected?: MedicalImageVO;
} & ImageSharedToolbarProps;

const getSideBySideCaptureFormat = (imageCount: number): MountFormat =>
  imageCount === MOUNT_FORMAT_NUMBER_SLOTS.double
    ? "double"
    : imageCount === MOUNT_FORMAT_NUMBER_SLOTS.single
      ? "single"
      : "quad";

// eslint-disable-next-line complexity
export const CompareImages: React.FC<Props> = ({
  medicalImagesInfiniteQuery,
  sortBy,
  onUpdateParams,
  patient,
  params,
  isOnboardedWithPearl,
  imageSelected,
  onEditImage,
}) => {
  const patientId = patient.id;
  const { practiceId } = useAccount();
  const { imageIds, from, fromSelection, teethSearchMode, teeth } = params;
  const savingImageLayout = useBoolean(false);
  const saveConfirmedModal = useItemModal<PatientDocumentsQuery>(null);
  const { documentUploader } = useDocumentUploader();
  const cameraCapture = useCameraCapture();
  const { devices } = useImagingDevices(cameraCapture);
  const { handleImageUpdate, revertImage } = useImageEditQueue({
    patientId,
  });

  const [{ mutateAsync: createFolderAsync }] = useApiMutations([createFolder]);

  const imagesData = useFlattenPages(medicalImagesInfiniteQuery.data);
  const images = useMemo(() => imagesData ?? [], [imagesData]);

  const sideBarProps = useImageSidebarNavigation({
    images,
    format: "quad",
  });
  const { selectedImages } = sideBarProps;
  const screenshotImages = React.useMemo(() => {
    const definedImages = selectedImages.filter((item) => item.image);

    if (definedImages.length > MOUNT_FORMAT_NUMBER_SLOTS.double) {
      return selectedImages;
    }

    return definedImages;
  }, [selectedImages]);

  const handleImageChanged: UseImageEditQueue["handleImageUpdate"] = React.useCallback(
    (image, changedParams) => {
      const persistToServer = "sensor" in changedParams || "assignedDate" in changedParams;

      return handleImageUpdate(image, changedParams, persistToServer);
    },
    [handleImageUpdate]
  );
  const patientName = useMemo(() => {
    return patient.name.fullDisplayName;
  }, [patient]);

  return images.length > 0 ? (
    <>
      <CompareImagesHeader
        teethSearchMode={teethSearchMode}
        teeth={(teethSearchMode ? imageSelected?.teeth : teeth) ?? []}
        imageCount={(imageIds ?? []).length}
        isSaving={savingImageLayout.isOn}
        onClickSave={screenshotImages.length > 0 ? savingImageLayout.on : undefined}
        editSelectionsUrl={fromSelection ?? paths.patientTab({ tab: "imaging", patientId })}
        fromUrl={from}
      />

      <DndProvider backend={HTML5Backend}>
        <ImageSidebarNavigation
          patientId={patientId}
          onRevertImage={revertImage}
          onEditImage={onEditImage}
          onImageUpdate={handleImageChanged}
          devices={devices}
          images={images}
          format="quad"
          sortBy={sortBy}
          isOnboardedWithPearl={isOnboardedWithPearl}
          onSortImages={(sortCriteria) => onUpdateParams({ sortBy: sortCriteria })}
          isShowingMetadata
          medicalImagesInfiniteQuery={medicalImagesInfiniteQuery}
          {...sideBarProps}
        />
      </DndProvider>
      {savingImageLayout.isOn && (
        <ImageSideBySideCapture
          images={screenshotImages}
          format={getSideBySideCaptureFormat(screenshotImages.length)}
          onCaptureComplete={async (blob) => {
            if (blob) {
              try {
                const file = blobToFile(blob, `${format(new Date(), "M-d-yyyy__h_mma")}.png`);
                const result = await createFolderAsync({
                  data: { name: "Image Comparisons", createDuplicateIfExists: false },
                  practiceId,
                  userId: patientId,
                });
                const folderId = result.data.data.id;
                const documentResult = await documentUploader({
                  practiceId,
                  userId: patientId,
                  // Set defaultFolderId from selected user documents folder, otherwise
                  // set as last added file folder if available
                  folderId,
                  filename: file.name,
                  fileData: file,
                });

                saveConfirmedModal.open({
                  docId: documentResult.id,
                  folderId,
                });
              } catch (e) {
                handleError(e);
              }
            }

            savingImageLayout.off();
          }}
        />
      )}
      {saveConfirmedModal.item ? (
        <AlertModal
          primaryText="Comparison Image Saved"
          secondaryText={
            <>
              An image of this comparison has been saved to {patientName}’s documents under the{" "}
              <ButtonInternalLink
                theme="link"
                to={paths.patientDocuments({ patientId }, saveConfirmedModal.item)}
              >
                Image Comparisons folder.
              </ButtonInternalLink>
            </>
          }
          onConfirm={saveConfirmedModal.close}
        />
      ) : null}
    </>
  ) : null;
};

export const CompareImagesRoute = () => {
  const { query, updateQuery } = useQueryParams("imagingCompare");
  const { imageIds, teethSearchMode, teeth, sortBy } = query;
  const { patientId } = usePathParams("imagingCompare");
  const { practiceId } = useAccount();
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const handleEditImage = useCallback(
    (image: Pick<MedicalImageVO, "id">) => {
      navigate(
        paths.imagingEditImage(
          { patientId },
          {
            selectedImageId: image.id,
            from: getFullUrl(location),
            teethSearchMode,
            sortBy,
            teeth,
            imageIds,
          }
        )
      );
    },
    [imageIds, location, navigate, patientId, sortBy, teeth, teethSearchMode]
  );
  const medicalImagesInfiniteQuery = useInfiniteApiQuery(
    getMedicalImages({
      args: {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        practiceId,
        patientId,
        imageIds: teethSearchMode ? undefined : imageIds,
        teeth,
        imageTeethSearchMode: teethSearchMode,
        sortColumn: sortBy,
      },
      queryOptions: {
        refetchOnWindowFocus: false,
      },
    })
  );
  const [selectedImageId] = imageIds ?? [];
  const [patientQuery, imageSelectedQuery, imagingSettingsQuery] = useApiQueries([
    getPatientSummary({ args: { patientId, practiceId } }),
    getMedicalImageDetails({
      args: { practiceId, patientId, imageId: selectedImageId },
      queryOptions: { enabled: Boolean(selectedImageId && teethSearchMode) },
    }),
    getPracticeImagingSettings({ args: { practiceId } }),
  ]);

  const handleRouteStateChange = React.useCallback(
    (updates: Partial<ImagingCompareQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );

  React.useEffect(() => {
    return () => {
      // Medical images are cached for session. We make transient edits to them, then discard them when they're done comparing
      queryClient.invalidateQueries([
        getQueryKey("practices", "getMedicalImages"),
        { practiceId, patientId, imageIds },
      ]);
    };
  }, [imageIds, patientId, practiceId, queryClient]);

  return (
    <FullScreenPortal isDark>
      {medicalImagesInfiniteQuery.isInitialLoading ? (
        <LoadingDarkRoom />
      ) : (
        <QueryResult queries={[patientQuery]}>
          {patientQuery.data && (
            <ImageListContextProvider>
              <CompareImages
                onEditImage={handleEditImage}
                medicalImagesInfiniteQuery={medicalImagesInfiniteQuery}
                sortBy={sortBy}
                onUpdateParams={handleRouteStateChange}
                patient={patientQuery.data}
                params={query}
                imageSelected={imageSelectedQuery.data}
                isOnboardedWithPearl={Boolean(imagingSettingsQuery.data?.dateOnboardedWithPearl)}
              />
            </ImageListContextProvider>
          )}
        </QueryResult>
      )}
    </FullScreenPortal>
  );
};
