import { FC, useMemo } from "react";
import { FormSelectInputElementRequest } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { FormWrapper } from "components/Settings/Forms/FormWrapper";
import { EditElementFormProps } from "components/Settings/Forms/types";
import { MultipleChoiceFormContent } from "components/Settings/Forms/MultipleChoice/FormContent";
import {
  cleanMultipleChoiceFormState,
  useMultipleChoiceState,
  getMultipleChoiceSchema,
  filterEmptyOptions,
} from "./schema";

export const MultipleChoiceForm: FC<EditElementFormProps<FormSelectInputElementRequest>> = ({
  element,
  isEditing,
  formType,
  onInvalid,
  onDraftChange,
  onSubmit,
  onSecondaryClick,
}) => {
  const state = useMultipleChoiceState(element);

  const [formState] = state;
  const schema = useMemo(
    () => getMultipleChoiceSchema({ options: formState.draft.options }),
    [formState.draft.options]
  );

  const cleanedDraft = useMemo(() => {
    return cleanMultipleChoiceFormState(formState);
  }, [formState]);

  const validation = useValidation(formState.draft, schema);

  const handleSubmit = () => {
    onSubmit(filterEmptyOptions(cleanedDraft));

    validation.reset();
  };

  return (
    <FormWrapper
      element={cleanedDraft}
      isEditing={isEditing}
      onDraftChange={onDraftChange}
      onSubmit={handleSubmit}
      onCancel={onSecondaryClick}
      onInvalid={onInvalid}
      onValidate={() => validation.validate().$isValid}
    >
      <MultipleChoiceFormContent formType={formType} formState={state} validation={validation.result} />
    </FormWrapper>
  );
};
