import { FC, useState } from "react";
import { ReactSortable, Sortable } from "react-sortablejs";
import { cx } from "@libs/utils/cx";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as Drag } from "@libs/assets/icons/drag-reorder-2.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { ChoicesValidation, EditableOption, createNewOption } from "./schema";

interface Props {
  immutable: boolean;
  choices: EditableOption[];
  errors: ChoicesValidation;
  onRemove: (removeIndex: number) => void;
  onAdd: (choice: EditableOption) => void;
  onUpdate: (index: number, value: string) => void;
  onSort: (sorted: EditableOption[]) => void;
}

const DRAG_HANDLE_CLASS = "sort-choices-drag";

export const EditChoices: FC<Props> = ({ immutable, choices, errors, onUpdate, onRemove, onAdd, onSort }) => {
  const [draggingIndex, setDraggingIndex] = useState(-1);
  const handleStartSort = (evt: Sortable.SortableEvent) => {
    setDraggingIndex(evt.oldIndex ?? -1);
  };
  const handleStopSort = () => {
    setDraggingIndex(-1);
  };

  const handleSort = (newState: EditableOption[]) => {
    if (newState.length !== choices.length || newState.some(({ id }, index) => id !== choices[index].id)) {
      onSort(newState);
    }
  };

  const handleUpdate = (index: number, value: string) => {
    onUpdate(index, value);

    if (index === choices.length - 1 && value) {
      onAdd(createNewOption());
    }
  };

  return (
    <div className="flex flex-col gap-y-4">
      {choices.length > 0 ? (
        <ReactSortable
          direction="vertical"
          className="flex flex-col gap-y-4 opaque-draggables"
          list={choices}
          handle={`.${DRAG_HANDLE_CLASS}`}
          setList={handleSort}
          onEnd={handleStopSort}
          onStart={handleStartSort}
        >
          {choices.map((choice, index) => (
            <FormFieldInput
              id={choice.id}
              readOnly={choice.new ? false : immutable}
              key={choice.id}
              value={choice.value}
              inputClassName="pl-6"
              error={errors.$items[index].$error || errors.$error}
              placeholder="Option Label"
              onChange={(e) => handleUpdate(index, e.target.value)}
              className={cx(draggingIndex === index && "invisible")}
            >
              <div
                className={cx(
                  `cursor-move
                   flex-0
                   px-1
                   h-full
                   flex
                   items-center
                   left-0
                   absolute
                   top-1/2
                   -translate-y-1/2`,
                  DRAG_HANDLE_CLASS
                )}
              >
                <Drag className="w-4 h-4" />
              </div>
              {index < choices.length - 1 ? (
                <ButtonIcon
                  tabIndex={-1}
                  size="sm"
                  className="flex-0 absolute right-4 top-1/2 -translate-y-1/2"
                  SvgIcon={DeleteIcon}
                  onClick={() => onRemove(index)}
                />
              ) : null}
            </FormFieldInput>
          ))}
        </ReactSortable>
      ) : null}
    </div>
  );
};
