import { FC, ReactNode } from "react";
import { ClickOutside, ClickOutsideProps } from "@libs/contexts/ClickOutsideListenerContext";
import { SpringValue, easings, animated, useTransition } from "@react-spring/web";

interface Props {
  onClickOutside: ClickOutsideProps["handler"];
  children?: ReactNode;
}

const PeekPanelAnimation: FC<
  Props & {
    styles: {
      opacity: SpringValue<number>;
      transform: SpringValue<string>;
    };
  }
> = ({ children, styles, onClickOutside }) => {
  return (
    <ClickOutside handler={onClickOutside}>
      {(props) => (
        <animated.div {...props} style={styles} className="absolute left-full top-0 h-full w-96 z-10">
          <div
            className={`
              h-full
              flex
              flex-col
              w-full
              bg-white
              border
              border-r-slate-300
            `}
          >
            {children}
          </div>
        </animated.div>
      )}
    </ClickOutside>
  );
};

export const PeekPanel: FC<Props & { isOpen: boolean }> = ({ isOpen, ...props }) => {
  const transition = useTransition(isOpen, {
    from: { opacity: 0, transform: "translateX(-20px)" },
    enter: { opacity: 1, transform: "translateX(0px)" },
    leave: { opacity: 0, transform: "translateX(-20px)" },
    config: {
      duration: 300,
      easing: easings.easeOutExpo,
    },
  });

  return transition((style, item) => (item ? <PeekPanelAnimation {...props} styles={style} /> : null));
};
