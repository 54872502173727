import { FC, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { Button } from "@libs/components/UI/Button";
import { useNow } from "hooks/useNow";

interface Props {
  selectedTab: string;
  onSelect: (value: string) => void;
  className?: string;
  tabsClassName?: string;
  children?: ReactNode;
}

export const YearTabs: FC<Props> = ({ children, className, tabsClassName, selectedTab, onSelect }) => {
  const now = useNow();
  const currentYear = now.getFullYear();
  const tabs = [currentYear, currentYear, currentYear].map((year, index) => `${year + index}`);

  return (
    <div className={cx("text-sm mb-5 mt-1", className)}>
      <div className={cx("flex my-0.5", tabsClassName)}>
        {tabs.map((tab) => (
          <Button
            theme="link"
            key={tab}
            onClick={() => onSelect(tab)}
            className={cx(
              `border-none
               p-0
               mr-6
               hover:text-primaryTheme
               hover:bg-transparent
               hover:font-sansSemiBold`,
              selectedTab === tab ? "text-primaryTheme bg-transparent font-sansSemiBold" : "text-greyDark"
            )}
          >
            {tab}
          </Button>
        ))}
      </div>

      {children}
    </div>
  );
};
