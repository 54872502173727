import React, { forwardRef } from "react";
import { cx } from "@libs/utils/cx";
import { ButtonIcon, ButtonIconProps } from "@libs/components/UI/ButtonIcon";

type Props = {
  selected?: boolean;
} & Omit<ButtonIconProps, "theme">;
export const MountIconButton = forwardRef<HTMLButtonElement, Props>(
  ({ selected, className, disabled, ...rest }, ref) => {
    return (
      <ButtonIcon
        ref={ref}
        {...rest}
        disabled={disabled}
        theme={selected ? "primary" : "darkBackground"}
        className={cx(!disabled && "hover:opacity-70", selected && "border-primaryTheme", className)}
        iconGlyphClassName={disabled ? undefined : "hover:opacity-70"}
      />
    );
  }
);
