import React from "react";
import { TimeSeries, TimeSeriesResponse } from "@libs/api/generated-api";
import { OutstandingCollectionType } from "components/Dashboard/OutstandingCollections/types";
import { useAgingTimeseriesTemplate } from "components/Dashboard/OutstandingCollections/hooks/useAgingTimeseriesTemplate";
import { mergeTimelines } from "components/Dashboard/Charting/utils";
import { BarStackData } from "components/Dashboard/Charting/types";

const mapFromDimensionsInSeries = (dimensions: string[], series?: TimeSeries[]) => {
  return dimensions.map(
    (dimension) => series?.find((item) => item.dimension === dimension)?.dataPoints ?? []
  );
};

export const useFormattedUncollectedAmounts = (
  insuranceUncollectedData?: TimeSeriesResponse,
  invoicedBalanceData?: TimeSeriesResponse,
  unInvoicedBalanceData?: TimeSeriesResponse
) => {
  const seriesTemplate = useAgingTimeseriesTemplate();

  return React.useMemo(() => {
    const [insuranceOwedSubmitted, insuranceOwedUnsubmitted] = mapFromDimensionsInSeries(
      ["true", "false"],
      insuranceUncollectedData?.timeSeries
    );

    const invoicedTimeSeries = invoicedBalanceData?.timeSeries[0]?.dataPoints ?? [];
    const unInvoicedTimeSeries = unInvoicedBalanceData?.timeSeries[0]?.dataPoints ?? [];

    const series = {
      ...seriesTemplate,

      insuranceOwedSubmitted: mergeTimelines(seriesTemplate.insuranceOwedSubmitted, insuranceOwedSubmitted),
      insuranceOwedUnsubmitted: mergeTimelines(
        seriesTemplate.insuranceOwedUnsubmitted,
        insuranceOwedUnsubmitted
      ),
      patientOwedInvoiceCreated: mergeTimelines(seriesTemplate.patientOwedInvoiceCreated, invoicedTimeSeries),
      patientOwedInvoiceNotCreated: mergeTimelines(
        seriesTemplate.patientOwedInvoiceNotCreated,
        unInvoicedTimeSeries
      ),
    };
    const result: BarStackData<OutstandingCollectionType>[] = series.insuranceOwedUnsubmitted.map(
      (item, i) => {
        return {
          date: item.startDate,
          insuranceOwedUnsubmitted: item.value,
          insuranceOwedSubmitted: series.insuranceOwedSubmitted[i].value,
          patientOwedInvoiceNotCreated: series.patientOwedInvoiceNotCreated[i].value,
          patientOwedInvoiceCreated: series.patientOwedInvoiceCreated[i].value,
        };
      }
    );

    return result;
  }, [
    insuranceUncollectedData?.timeSeries,
    invoicedBalanceData?.timeSeries,
    seriesTemplate,
    unInvoicedBalanceData?.timeSeries,
  ]);
};
