import { FC } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { IPAuthSettings } from "components/Settings/Security/IPAuthorization/IPAuthSettings";

export const IPAuthSettingsRoute: FC = () => {
  const isEditing = useBoolean(false);

  return (
    <SettingsPanel actions={null} isEditing={true} title="IP Authorization" onToggleEdit={isEditing.toggle}>
      <IPAuthSettings isEditing={true} />
    </SettingsPanel>
  );
};
