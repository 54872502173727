import { useCallback, useMemo, useState } from "react";
import { pluralize } from "@libs/utils/pluralize";
import { Button } from "@libs/components/UI/Button";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent } from "@libs/components/UI/ModalComponents";
import { keys } from "@libs/utils/object";
import { Prompt } from "components/UI/Prompt";

type Props = {
  totalCount?: number;
  onRequestClose?: Func;
  uploadComplete?: boolean;
  percentComplete: number;
};

const cxStyles = {
  titleText: "font-sansSemiBold text-sm text-center",
};

const InProgressContent: React.FC<Omit<Props, "onRequestClose">> = ({ totalCount, percentComplete }) => {
  return (
    <>
      <div className={cxStyles.titleText}>
        {totalCount
          ? `Uploading ${totalCount} ${pluralize(totalCount, "image", "images")}`
          : "Uploading images"}
      </div>
      <div className="flex flex-col w-full gap-4">
        <div
          className={`
            flex-start
            flex
            h-2
            w-full
            overflow-hidden
            rounded-full
            bg-slate-200
            font-sans
            text-xs
            font-medium
          `}
        >
          <div
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            style={{ width: `${percentComplete * 100}%` }}
            className={`
              flex
              items-center
              justify-center
              h-full
              overflow-hidden
              text-white
              break-all
              bg-archyBlue-500
              rounded-full
            `}
          />
        </div>
      </div>
      <Prompt message="Images are still uploading. Are you sure you want to cancel?" />
    </>
  );
};

// Returns callback that can be used to update progress by a given progress type
export const useUploadProgress = <T extends string>(progressType: T[]) => {
  const initialValue = useMemo(
    () => Object.fromEntries(progressType.map((key) => [key, 0])) as Record<T, number>,
    [progressType]
  );
  const [uploadState, setUploadState] = useState(initialValue);
  const handleProgress = useCallback(({ type, value }: { type: T; value: number }) => {
    setUploadState((prevState) => {
      return { ...prevState, [type]: value };
    });
  }, []);

  return useMemo(
    () => ({
      handleProgress,
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      percentComplete:
        keys(uploadState).reduce((acc, key) => acc + uploadState[key], 0) / keys(uploadState).length,
      clearProgress: () => setUploadState(initialValue),
    }),
    [handleProgress, initialValue, uploadState]
  );
};

export const UploadingImagesModal: React.FC<Props> = ({
  uploadComplete,
  percentComplete,
  totalCount,
  onRequestClose,
}) => {
  return (
    <Modal size="2xs">
      <ModalContent className="flex flex-col gap-4" padding="lgEven">
        {uploadComplete ? (
          <div className="flex flex-col items-center gap-10 pt-6">
            <div className={cxStyles.titleText}>All Images Uploaded</div>
            <Button className="min-w-button" onClick={onRequestClose}>
              Done
            </Button>
          </div>
        ) : (
          <InProgressContent totalCount={totalCount} percentComplete={percentComplete} />
        )}
      </ModalContent>
    </Modal>
  );
};
