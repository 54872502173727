import { MouseEventHandler, useCallback, useEffect } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { ReactComponent as RefreshIcon } from "@libs/assets/icons/refresh.svg";
import { Icon } from "@libs/components/UI/Icon";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { useSubmitClaim } from "components/Claims/Shared/useSubmitClaim";

export const ClaimError: React.FC<{
  claimUuid: string;
  isValidating: boolean;
  isDraftClaim: boolean;
  validationError?: string;
  submissionError?: string;
}> = ({ claimUuid, isValidating, isDraftClaim, validationError, submissionError }) => {
  const { validate, handleValidateError, isExecutingRequest } = useSubmitClaim();

  // Track it separate since we don't want it to flash while the request is going out and the claim is being refreshed.
  const validating = useBoolean(isValidating);

  useEffect(() => {
    if (!isExecutingRequest) {
      validating.set(isValidating);
    }
  }, [isExecutingRequest, isValidating, validating]);

  const handleClick = useCallback<MouseEventHandler>(
    (e) => {
      e.stopPropagation(); // Needed to prevent the claim grid row from expanding when clicking re-validate.

      if (validating.isOn || !validationError) {
        return;
      }

      validate(claimUuid).catch(handleValidateError);
    },
    [claimUuid, handleValidateError, validate, validating, validationError]
  );

  const hoveredOverError = useBoolean(false);

  return (
    <FloatingTooltip
      content={
        hoveredOverError.isOn ? (
          isValidating ? (
            "Claim is being re-validated."
          ) : (
            <div>
              <div className="font-sansSemiBold">
                {validationError ? "Validation failed" : "Submission error"}
              </div>
              <div className="whitespace-pre-line">{validationError ?? submissionError}</div>
              {isDraftClaim && (
                <div>
                  <br />
                  {validationError
                    ? "Click to re-validate."
                    : "Submission errors will not clear until the claim is updated or submitted again."}
                </div>
              )}
            </div>
          )
        ) : (
          ""
        )
      }
    >
      <div onMouseEnter={hoveredOverError.on} onMouseLeave={hoveredOverError.off}>
        {(hoveredOverError.isOn && validationError) || validating.isOn ? (
          <ButtonIcon SvgIcon={RefreshIcon} theme="primary" onClick={handleClick} loading={validating.isOn} />
        ) : (
          <Icon SvgIcon={ErrorIcon} theme="error" />
        )}
      </div>
    </FloatingTooltip>
  );
};
