import { FC, useMemo } from "react";

import { AppointmentVO, RecallVO } from "@libs/api/generated-api";
import { ApiQueryResult, UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { Button } from "@libs/components/UI/Button";
import { Panel } from "@libs/components/UI/Panel";
import { ScrollableInfiniteQuery } from "@libs/components/UI/ScrollableInfiniteQuery";
import { Appointment } from "components/PatientProfile/PatientOverview/Appointments/Appointment";

import { NoScheduledAppointments } from "components/PatientProfile/PatientOverview/Appointments/NoScheduledAppointments";
import { AppointmentsPanelTitle } from "components/PatientProfile/PatientOverview/Appointments/AppointmentsPanelTitle";
import { RecallBanner } from "components/PatientProfile/PatientOverview/Recall/RecallBanner";
import { AppointmentMenuProps } from "components/ScheduleAppointments/AppointmentMenu";

interface Props {
  patientId: number;
  appointmentsQuery: UseInfiniteApiListQueryResult<AppointmentVO>;
  noShowQuery: ApiQueryResult<AppointmentVO[]>;
  canceledQuery: ApiQueryResult<AppointmentVO[]>;
  recallsQuery: ApiQueryResult<RecallVO[]>;
  onRequestOpenRecall: Func;
  onAppointmentDeleted?: AppointmentMenuProps["onAppointmentDeleted"];
}

export const AppointmentsPanel: FC<Props> = ({
  patientId,
  appointmentsQuery,
  noShowQuery,
  canceledQuery,
  recallsQuery,
  onRequestOpenRecall,
  onAppointmentDeleted,
}) => {
  const showRecallBanner = useMemo(
    () => (recallsQuery.data ?? []).some(({ dueSoon, isDue }) => dueSoon || isDue),
    [recallsQuery]
  );

  return (
    <Panel
      className="h-full"
      contentClassName="flex flex-col"
      title={
        <AppointmentsPanelTitle
          patientId={patientId}
          appointmentsQuery={appointmentsQuery}
          noShowQuery={noShowQuery}
          canceledQuery={canceledQuery}
        />
      }
      includePadding={false}
      actions={
        <Button className="text-xs" onClick={onRequestOpenRecall} theme="link">
          View Recall Info
        </Button>
      }
    >
      {showRecallBanner && <RecallBanner onClick={onRequestOpenRecall} />}
      <ScrollableInfiniteQuery
        id="patient-overview-appointments-scroll"
        rootMargin="0px 0px 400px 0px"
        infiniteQuery={appointmentsQuery}
        noResults={<NoScheduledAppointments />}
      >
        {(appointmentsList) =>
          appointmentsList.map((appointment) => (
            <Appointment
              key={appointment.id}
              className="px-4 py-5"
              appointment={appointment}
              onAppointmentDeleted={onAppointmentDeleted}
            />
          ))
        }
      </ScrollableInfiniteQuery>
    </Panel>
  );
};
