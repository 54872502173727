import { ComponentProps, PropsWithChildren, ReactNode } from "react";
import { PatientInsuranceResponse } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { ModalContent } from "@libs/components/UI/ModalComponents";
import { ModalPage } from "components/UI/ModalPage";
import { InsurancePlanDetailsTabs } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/Tabs";
import { PatientInsuranceDetailsStep } from "utils/routing/patientInsurance";

interface Props extends Required<Pick<ComponentProps<typeof QueryResult>, "queries">> {
  actions?: ReactNode;
  backUrl: string;
  insuranceId: number | "new";
  patientId: number;
  patientInsuranceQuery: ApiQueryResult<PatientInsuranceResponse>;
  selectedTab: PatientInsuranceDetailsStep;
}

export const DetailsModalPage: React.FC<PropsWithChildren & Props> = ({
  actions,
  backUrl,
  children,
  insuranceId,
  patientId,
  patientInsuranceQuery,
  queries,
  selectedTab,
}) => {
  return (
    <ModalPage
      actions={actions}
      closeLink={backUrl}
      title={
        <InsurancePlanDetailsTabs
          insuranceId={insuranceId}
          patientId={patientId}
          patientInsuranceQuery={patientInsuranceQuery}
          selectedTab={selectedTab}
        />
      }
    >
      <QueryResult
        loading={
          <div className="h-full w-full border-b border-greyLightest">
            <LoadingContent />
          </div>
        }
        queries={queries}
      >
        <ModalContent scrollClassName="">{children}</ModalContent>
      </QueryResult>
    </ModalPage>
  );
};
