import React from "react";
import Dynamsoft from "dwt";
import { useDebouncedCallback, useThrottledCallback } from "use-debounce";
import { WebTwain } from "dwt/dist/types/WebTwain";
import { addBreadcrumb } from "@sentry/react";
import { dataURLtoFile } from "@libs/utils/dataUrl";
import { DEGREES_180, DEGREES_270, DEGREES_90, SquareDegree } from "@libs/utils/math";
import {
  ImageLayoutItem,
  ReservedLayoutItem,
  ReservedStatus,
} from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";
import { useLoadedTwainSensors, useTwain } from "components/ImageCapturing/useTwain";

import { TwainContext } from "components/ImageCapturing/TwainContext";
import { beginXRayImageCaptureMode, selectSource } from "components/ImageCapturing/twain";

import { mountItemMatchesLocation } from "components/PatientProfile/Imaging/MountRoute/image-utils";
import {
  CODE_SYSTEM_BUSY,
  CODE_USER_CANCELLED,
  checkTwainError,
  isTwainError,
  logTwainErrorToSentry,
  TwainError,
} from "components/ImageCapturing/twainErrors";
import { useImagingDeviceSettings } from "components/PatientProfile/Imaging/hooks/useImagingDeviceSettings";
import { isAspectCompatible } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/shapeUtils";
import { handleError } from "utils/handleError";
import { CaptureDeviceSettings } from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/types";
import { MountUploadParams } from "components/PatientProfile/Imaging/MountRoute/hooks/useArchyAgentUploader";
import { MountLayoutType } from "api/imaging/imaging-api";
import { CaptureStatus } from "api/imaging/imaging-hub";
import { useSandboxLayoutContext } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/SandboxLayoutContext";

const PROCESS_IMAGES_DEBOUNCE_MS = 1600;

const CLIO_SENSOR_LABEL = "Clio Digital X-Ray Sensor (TWAIN)";
const RVG_SENSOR_LABEL = "RVGTwain (TWAIN)";
const JAZZ_SENSOR_LABEL = "Jazz X-Ray TWAIN Data Source (TWAIN)";

const SHOULD_REOPEN_TWAIN_SENSORS = new Set([CLIO_SENSOR_LABEL, RVG_SENSOR_LABEL, JAZZ_SENSOR_LABEL]);

export const shouldReOpenTwainOnCapture = (deviceLabel: string) => {
  return SHOULD_REOPEN_TWAIN_SENSORS.has(deviceLabel);
};

export const importImageFromTwain = async ({
  webTwain,
  indexToProcess,
  onTwainError,
}: {
  webTwain: WebTwain;
  indexToProcess: number;
  onTwainError?: (errorCode: number, errorString: string) => void;
}) => {
  return new Promise<string>((resolve, reject) => {
    webTwain.ConvertToBase64(
      [indexToProcess],
      Dynamsoft.DWT.EnumDWT_ImageType.IT_PNG, // More available: https://www.dynamsoft.com/web-twain/docs/info/api/Dynamsoft_Enum.html#dynamsoftdwtenumdwt_imagetype
      (result) => {
        const base64ImageData = result.getData(0, result.getLength());
        const base64Url = `data:image/png;base64, ${base64ImageData}`;

        resolve(base64Url);
      },
      (errorCode: number, errorString: string) => {
        logTwainErrorToSentry(
          new TwainError({
            errorCode,
            errorString,
            api: "ConvertToBase64",
          })
        );

        if (onTwainError) {
          onTwainError(errorCode, errorString);
        } else {
          reject(new Error(`Failed to capture image ${errorString} - ${errorCode})`));
        }
      }
    );
  });
};

export const rotateImageInBuffer = ({
  webTwain,
  indexToProcess,
  degrees = DEGREES_90,
}: {
  indexToProcess: number;
  webTwain: WebTwain;
  degrees?: SquareDegree;
}) => {
  const imageWidth = webTwain.GetImageWidth(indexToProcess);
  const imageHeight = webTwain.GetImageHeight(indexToProcess);

  return new Promise<{ width: number; height: number }>((resolve, reject) => {
    webTwain.Rotate(
      indexToProcess,
      degrees,
      false,
      () => {
        resolve(
          degrees % DEGREES_180 === 0
            ? { width: imageWidth, height: imageHeight }
            : { width: imageHeight, height: imageWidth }
        );
      },
      reject
    );
  });
};

export const flipImageInBuffer = ({
  webTwain,
  indexToProcess,
  axis,
}: {
  indexToProcess: number;
  webTwain: WebTwain;
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  axis: "x" | "y";
}) => {
  return new Promise<void>((resolve, reject) => {
    // Flip is vertical, mirror is horizontal
    // Documentation here: https://www.dynamsoft.com/web-twain/docs/indepth/features/edit.html#rotating-flipping-and-mirroring
    if (axis === "x") {
      webTwain.Mirror(indexToProcess, resolve, reject);
    } else {
      webTwain.Flip(indexToProcess, resolve, reject);
    }
  });
};

const assignImageOrientation = async ({
  indexToProcess,
  nextCaptureSpot,
  mountLayout,
  webTwain,
  sensorSettings,
}: {
  indexToProcess: number;
  nextCaptureSpot: ImageLayoutItem;
  mountLayout: MountLayoutType;
  webTwain?: WebTwain;
  sensorSettings?: CaptureDeviceSettings;
}) => {
  if (!webTwain) {
    return { width: -1, height: -1 };
  }

  const imageWidth = webTwain.GetImageWidth(indexToProcess);
  const imageHeight = webTwain.GetImageHeight(indexToProcess);
  const rotateLocations = sensorSettings?.rotateOnCapture ?? [];
  const needsRotate = rotateLocations.some((location) =>
    mountItemMatchesLocation({
      mountLayout,
      index: nextCaptureSpot.i,
      location,
    })
  );
  const [flipAxis] = sensorSettings?.flipOnCapture ?? [undefined];
  let size = { width: imageWidth, height: imageHeight };

  if (
    !isAspectCompatible(
      { width: imageWidth, height: imageHeight },
      { width: nextCaptureSpot.sandbox.w, height: nextCaptureSpot.sandbox.h }
    )
  ) {
    // Bottom and left side of mouth images need to be rotated 180 degrees. After the initial 90 to align aspect, this is 270
    size = await rotateImageInBuffer({
      webTwain,
      indexToProcess,
      degrees: needsRotate ? DEGREES_270 : DEGREES_90,
    });
  } else if (needsRotate) {
    size = await rotateImageInBuffer({
      webTwain,
      indexToProcess,
      degrees: DEGREES_180,
    });
  }

  if (flipAxis) {
    await flipImageInBuffer({ webTwain, indexToProcess, axis: flipAxis });
  }

  return size;
};

export const useDynamsoftUploader = ({
  uploadImages,
  mountLayout,
  sandboxImages,
  mountId,
  patient,
}: MountUploadParams) => {
  const twain = useTwain();
  const sources = useLoadedTwainSensors();
  const { reserveImageSpot, updateReservedSpot, releaseReservedSpot } = useSandboxLayoutContext();

  const { getSensorSettings } = useImagingDeviceSettings();
  const isProcessingImages = React.useRef(false);
  const {
    twain: webTwain,
    onImageReadyRef,
    setTwainLoadError,
    twainLoadError,
  } = React.useContext(TwainContext);

  const processImages = React.useCallback(
    // eslint-disable-next-line max-statements, complexity
    async ({
      deviceId,
      sensorSettings,
      onUploadComplete,
    }: {
      deviceId: string;
      sensorSettings?: CaptureDeviceSettings;
      onUploadComplete: (stopContinuousCapture: boolean) => void;
    }) => {
      const reverseImageOrder = sensorSettings?.reverseImport === true;

      addBreadcrumb({
        level: "info",
        category: "imaging",
        message: "processImages called",
        data: {
          reverseImageOrder,
        },
      });

      if (!webTwain) {
        isProcessingImages.current = false;

        return;
      }

      const uploads: {
        request: {
          image: File;
          size: { width: number; height: number };
          position: { i: number; x: number; y: number };
        };
        reservedSpot: ReservedLayoutItem;
        bufferImageId: number;
      }[] = [];

      const bufferSize = webTwain.HowManyImagesInBuffer;
      let settledRequestCount = 0;

      for (let imageIndex = 0; imageIndex < bufferSize; imageIndex++) {
        const reservedSpot = reserveImageSpot();

        if (!reservedSpot) {
          continue;
        }

        const indexToProcess = reverseImageOrder ? bufferSize - imageIndex - 1 : imageIndex;

        const imageDimensions = await assignImageOrientation({
          indexToProcess,
          nextCaptureSpot: reservedSpot,
          mountLayout,
          sensorSettings,
          webTwain,
        });

        const imageBase64Url = await importImageFromTwain({
          webTwain,
          indexToProcess,
          onTwainError: (errorCode: number, errorString: string) => {
            logTwainErrorToSentry(
              new TwainError({
                errorCode,
                errorString,
                api: "ConvertToBase64",
              })
            );
            setTwainLoadError(
              new Error("Failed to collect x-ray", { cause: new Error(`${errorCode}: ${errorString}`) })
            );
          },
        });
        const imageFile = dataURLtoFile(imageBase64Url, "twainImage.png");

        // Show the image with the raw data while it uploads.
        reservedSpot.url = URL.createObjectURL(imageFile);
        reservedSpot.w = imageDimensions.width;
        reservedSpot.h = imageDimensions.height;
        // Assign a date so the metadata shows up.
        reservedSpot.createdDate = new Date().toISOString();

        // eslint-disable-next-line no-loop-func
        reservedSpot.uploadImage = async () => {
          // If upload failed, needs to be updated again to in progress:
          updateReservedSpot(reservedSpot.id, {
            status: ReservedStatus.Uploading,
          });
          addBreadcrumb({
            level: "info",
            category: "imaging",
            message: "Uploading image",
            data: {
              imageCount: 1,
              device: deviceId,
            },
          });

          const twainImageBufferId = webTwain.IndexToImageID(indexToProcess);
          const uploadResults = await uploadImages({
            images: [
              {
                imageFile,
                positionI: reservedSpot.i,
                positionX: reservedSpot.x,
                positionY: reservedSpot.y,
                height: imageDimensions.height,
                width: imageDimensions.width,
                type: "X_RAY",
              },
            ],
            deviceId,
            mountId,
            patientId: patient.id,
            onProgress: (_, uploadResult) => {
              if (uploadResult?.status === "SUCCESS") {
                const twainImageIndex = webTwain.ImageIDToIndex(twainImageBufferId);

                if (twainImageIndex >= 0) {
                  webTwain.RemoveImage(webTwain.ImageIDToIndex(twainImageBufferId));
                }

                releaseReservedSpot(reservedSpot.id, uploadResult.response.data.data);
                URL.revokeObjectURL(reservedSpot.url!);

                // Update cache using uploadResult.response.data
              } else if (uploadResult?.status === "FAILED") {
                updateReservedSpot(reservedSpot.id, {
                  status: ReservedStatus.Failed,
                });
              }

              if (["SUCCESS", "FAILED"].includes(uploadResult?.status ?? "")) {
                settledRequestCount++;
              }

              if (settledRequestCount === bufferSize) {
                isProcessingImages.current = false;
                setTwainLoadError(null);
                onUploadComplete(reservedSpot.i >= sandboxImages.length - 1);
              }
            },
          });

          // If it failed to prepare uploads, then it just returns []. We need to mark it as failed.
          if (uploadResults.length === 0 && reservedSpot.reservation.status !== ReservedStatus.Failed) {
            updateReservedSpot(reservedSpot.id, {
              status: ReservedStatus.Failed,
            });
          }
        };
        reservedSpot.uploadImage();
      }
      addBreadcrumb({
        level: "info",
        category: "imaging",
        message: "Uploading images",
        data: {
          imageCount: uploads.length,
        },
      });

      setTwainLoadError(null);
      isProcessingImages.current = false;
    },
    [
      webTwain,
      setTwainLoadError,
      reserveImageSpot,
      mountLayout,
      updateReservedSpot,
      uploadImages,
      mountId,
      patient.id,
      releaseReservedSpot,
      sandboxImages,
    ]
  );
  const handleImagesInBuffer = useDebouncedCallback(processImages, PROCESS_IMAGES_DEBOUNCE_MS, {
    leading: false,
    trailing: true,
  });

  const startSensorUploader = React.useCallback(
    async ({ deviceId }: { deviceId: string }) => {
      if (!webTwain || isProcessingImages.current) {
        return;
      }

      addBreadcrumb({
        level: "info",
        category: "imaging",
        message: "startSensorUploader called",
        data: {
          deviceId,
          agentType: "dynamsoft",
        },
      });

      const device = sources.find((item) => item.label === deviceId);
      let reOpenOnCapture = false;
      let sensorSettings: CaptureDeviceSettings | undefined;

      if (device && twain.twainSources) {
        await twain.twainSources.selectIndex(device.index);

        const allSensorSettings = getSensorSettings();

        sensorSettings = allSensorSettings.find((item) => item.id === device.label);

        reOpenOnCapture = shouldReOpenTwainOnCapture(device.label);
      }

      beginXRayImageCaptureMode(webTwain, sensorSettings?.produces === "Photo" ? "color" : "grey", () => {
        // We may want to process images even when user cancels. There can be x-rays taken that are in the buffer at this point
        // onImagesTransferred(reverseImport);
        // Do NOT clear the images when user has 'canceled' an import. Many sensors will auto close the native dialog when "Import" or "Get Image" is clicked. If we clear the images, then we will not have our callbacks be called properly (HowManyImagesInBuffer will be 0)
      });
      setTwainLoadError(null);

      onImageReadyRef.current = () => {
        isProcessingImages.current = true;
        handleImagesInBuffer({
          deviceId,
          sensorSettings,
          onUploadComplete: async (stopContinuousCapture) => {
            if (reOpenOnCapture && !stopContinuousCapture && !twainLoadError && device) {
              try {
                await selectSource(webTwain, device.index);
              } catch (e) {
                // Twain throws objects as errors, with code/message as keys
                const error = checkTwainError(e);

                if (isTwainError(error)) {
                  // Typically errors will be CODE_SYSTEM_BUSY, but they don't have any effect, so we don't want to warn the user
                  if (error.errorCode !== CODE_SYSTEM_BUSY && error.errorCode !== CODE_USER_CANCELLED) {
                    handleError(error);
                  }
                } else {
                  handleError(error);
                }
              }

              beginXRayImageCaptureMode(webTwain);
            }
          },
        });
      };
    },
    [
      webTwain,
      sources,
      twain.twainSources,
      setTwainLoadError,
      onImageReadyRef,
      getSensorSettings,
      handleImagesInBuffer,
      twainLoadError,
    ]
  );
  const THROTTLED_START_MS = 1500;

  // Prevents spamming of dynamsoft API from user clicks
  const startSensorUploaderThrottled = useThrottledCallback(startSensorUploader, THROTTLED_START_MS, {
    trailing: false,
  });

  return {
    startSensorUploader: startSensorUploaderThrottled,
    captureState: {
      lastCaptureStatus: undefined as CaptureStatus | undefined,
      hasStartedCapture: false,
      isProcessingImages: false,
    },
  };
};
