import { FC, useState } from "react";
import { HexColorInput, HexColorPicker } from "react-colorful";
import { useDebouncedCallback } from "use-debounce";
import { ColorOptions } from "@libs/domains/scheduling/colors";
import { cx } from "@libs/utils/cx";
import { FormFieldContainer } from "@libs/components/UI/FormFieldContainer";
import { cxFormFieldStyle } from "@libs/components/UI/formFieldStyle";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Button } from "@libs/components/UI/Button";
import { ColorPickerSelection } from "@libs/components/UI/ColorPickerSelection";

type Props = {
  color?: string;
  onCancel?: Func;
  onSelect?: (color: string) => void;
};

const SLIDER_DEBOUNCE = 200;

const Swatches: FC<{ color?: string; onClick: (color: string) => void }> = ({ onClick, color }) => {
  return (
    <div className="flex flex-wrap gap-1 w-full">
      {ColorOptions.map((c) => (
        <FloatingTooltip key={c.label} content={c.label} theme="SMALL">
          <button
            className={cx(
              "size-4 rounded-full",
              // If a user had previously chosen a light color mapping
              // for backwards compatibility let's resolve the light to
              // the darker version of the color.
              (color === c.value || color === c.light) &&
                "outline outline-primaryTheme outline-2  outline-offset-2"
            )}
            aria-label={c.label}
            style={{ background: c.value }}
            type="button"
            onClick={() => onClick(c.value)}
          />
        </FloatingTooltip>
      ))}
    </div>
  );
};

export const ColorPickerMenu: FC<Props> = ({ color: initialColor, onCancel, onSelect }) => {
  const [color, setColor] = useState(initialColor ?? "#000");

  const handleColorChange = useDebouncedCallback((newColor: string) => {
    setColor(newColor.toUpperCase());
  }, SLIDER_DEBOUNCE);

  return (
    <div className="p-3">
      <div className="archy-color-picker w-[296px] flex flex-col gap-y-6">
        <HexColorPicker color={color} onChange={handleColorChange} />
        <div className="flex items-center gap-x-4">
          <ColorPickerSelection color={color} />
          <FormFieldContainer className="flex-1">
            <HexColorInput
              className={cx(
                cxFormFieldStyle.control({ hasIcon: false, layout: "labelOut", hasLabel: false }),
                cxFormFieldStyle.input
              )}
              color={color}
              onChange={setColor}
              prefixed
            />
          </FormFieldContainer>
        </div>
        <Swatches color={color} onClick={setColor} />
        <div className="flex gap-x-3">
          <Button className="flex-1" size="small" theme="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <Button className="flex-1" size="small" onClick={() => onSelect?.(color)}>
            Select
          </Button>
        </div>
      </div>
    </div>
  );
};
