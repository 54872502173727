import { FC } from "react";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { FieldSchemaValidation } from "@libs/hooks/useValidation";
import { cx } from "@libs/utils/cx";

import { QueryResult } from "@libs/components/UI/QueryResult";
import notchUrl from "assets/images/Notch.svg";

import { FilteredTemplateVariablesQuery } from "components/Communications/types";
import { MAX_SMS_LENGTH } from "components/Communications/utils";
import { SMSEditor } from "components/Communications/SMSEditor";

interface Props {
  smsTemplate: string | undefined;
  contentTemplateVariablesQuery: FilteredTemplateVariablesQuery;
  onTemplateChange: (smsTemplate: string) => void;
  validationResult: {
    smsTemplate: FieldSchemaValidation;
  };
}

export const SMSMessage: FC<Props> = ({
  smsTemplate,
  contentTemplateVariablesQuery,
  onTemplateChange,
  validationResult,
}) => {
  const charCount = smsTemplate ? smsTemplate.length : 0;

  return (
    <div className="flex flex-col gap-y-3 max-h-full">
      <div className="flex flex-1 min-h-0 relative">
        <div
          className={`
            bg-white
            p-6
            rounded-2xl
            w-96
            drop-shadow-2xl
            h-fit
            max-h-full
            min-h-24
            overflow-y-auto
          `}
        >
          <QueryResult loading={<div />} queries={[contentTemplateVariablesQuery]}>
            <SMSEditor
              smsTemplate={smsTemplate}
              templateVariables={contentTemplateVariablesQuery.data}
              onTemplateChange={onTemplateChange}
            />
          </QueryResult>

          {validationResult.smsTemplate.$error ? (
            <div className="w-96 mt-1">
              <FormFieldError>{validationResult.smsTemplate.$error}</FormFieldError>
            </div>
          ) : null}
        </div>
        <img alt="Message bubble callout" src={notchUrl} className="absolute -left-2.5 bottom-0" />
      </div>
      <div
        className={`
          text-slate-500
          text-xs
          flex
          items-center
          justify-between
          w-96
        `}
      >
        <p>Type a &apos;/&apos; to insert things like patient names</p>
        <p className={cx(charCount > MAX_SMS_LENGTH ? "text-red" : "")}>{`${charCount} / 400`}</p>
      </div>
    </div>
  );
};
