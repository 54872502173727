import { DEGREES_180, SquareDegree } from "@libs/utils/math";
import { blobToFile } from "@libs/utils/dataUrl";
import { ImagingHttpApi } from "api/imaging/imaging-hub";
import { ImageCaptureResult } from "api/imaging/imaging-api";

const CLIO_SENSOR_LABEL = "Clio Digital X-Ray Sensor";
const RVG_SENSOR_LABEL = "RVGTwain";
const JAZZ_SENSOR_LABEL = "Jazz X-Ray TWAIN Data Source";

export const SHOULD_REOPEN_TWAIN_SENSORS = new Set([CLIO_SENSOR_LABEL, RVG_SENSOR_LABEL, JAZZ_SENSOR_LABEL]);

export const fetchImageWithAdjustments = async ({
  image,
  degrees,
  flipAxis,
}: {
  image: ImageCaptureResult;
  degrees?: SquareDegree;
  flipAxis?: "x" | "y";
}) => {
  const degreesRotated = degrees ?? 0;
  const newDimensions = {
    width: degreesRotated % DEGREES_180 === 0 ? image.width : image.height,
    height: degreesRotated % DEGREES_180 === 0 ? image.height : image.width,
  };
  const response = await ImagingHttpApi.images.getImageById(image.id, {
    Rotate: degrees,
    FlipHorizontal: flipAxis === "x",
    FlipVertical: flipAxis === "y",
  });

  if (response.error) {
    throw response.error;
  }

  const imageBlob = await response.blob();

  return {
    ...newDimensions,
    image: blobToFile(imageBlob, `${image.id}.png`),
  };
};
