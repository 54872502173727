import { FC } from "react";

import { FlyoverV2 } from "components/UI/FlyoverV2";
import { NotesFlyoverContent } from "components/Notes/NotesFlyoverContent";
import { usePatientNotesRouter } from "components/Notes/usePatientNotesRouter";

export const NotesFlyover: FC = () => {
  const patientNotes = usePatientNotesRouter();

  return (
    <FlyoverV2
      isOpen={patientNotes.isOpen}
      width="md"
      dataTestId="notes-flyover"
      overlay={<div onClick={patientNotes.close} role="presentation" className="absolute inset-0" />}
    >
      <NotesFlyoverContent url={patientNotes.location ?? ""} />
    </FlyoverV2>
  );
};
