/* eslint-disable @typescript-eslint/no-non-null-assertion */
import React, { useCallback } from "react";
import { PerioChartExamSettingsVO } from "@libs/api/generated-api";
import { useObjectState } from "@libs/hooks/useObjectState";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { RadioList } from "@libs/components/UI/RadioList";
import { Button } from "@libs/components/UI/Button";
import { Switch } from "@libs/components/UI/Switch";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent, FlyoverFooter, FlyoverForm } from "components/UI/FlyoverComponents";
import { PerioChartNumericInput } from "components/Charting/Perio/PerioChartNumericInput";
import {
  SEQUENCE_TYPES_ORDERED,
  sequenceToFullTitle,
  sequenceToThresholdSettings,
  sequenceToVisibility,
} from "components/Charting/Perio/perioChartUtils";
import { usePerioChart } from "components/Charting/Perio/PerioChartContext";
import { PerioChartAutoAdvance } from "components/Charting/Perio/PerioTypes";
import { handleError } from "utils/handleError";
import { BaseFormSection } from "components/UI/FormSection";

type Props = {
  onClose: Func;
  settings: PerioChartExamSettingsVO;
};

const autoAdvanceDirectionOptions: { value: PerioChartAutoAdvance; label: string }[] = [
  {
    value: "RIGHT",
    label: "Right",
  },
  {
    value: "LEFT",
    label: "Left",
  },
];

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
type BooleanNumVal = 0 | 1;

const autoAdvanceStopOptions: { value: BooleanNumVal; label: string }[] = [
  {
    value: 1,
    label: "Stop",
  },
  {
    value: 0,
    label: "Continue",
  },
];

const showHideOptions: { value: BooleanNumVal; label: string }[] = [
  {
    value: 1,
    label: "Show",
  },
  {
    value: 0,
    label: "Hide",
  },
];

const SettingsSection: React.FC<{
  children: React.ReactNode;
  title: string;
  description: string;
  className?: string;
}> = ({ children, title, description, className }) => {
  return (
    <BaseFormSection className={className} title={title}>
      <p className="mb-6 text-xs">{description}</p>
      {children}
    </BaseFormSection>
  );
};

const cxStyles = {
  borderedSection: "pb-6 mb-6 border-b border-slate-300 border-dashed",
};

export const PerioChartSettingsFlyover: React.FC<Props> = ({ onClose, settings }) => {
  const { saveSettings, isSavingSettings } = usePerioChart();

  const [settingsDraft, updateSettingsDraft] = useObjectState<PerioChartExamSettingsVO>(settings);

  const handleSubmit = useCallback(async () => {
    try {
      await saveSettings(settingsDraft);
      onClose();
    } catch (error) {
      handleError(error);
    }
  }, [onClose, saveSettings, settingsDraft]);

  return (
    <Flyover title="Perio Chart Settings" onClose={onClose}>
      <FlyoverForm className="h-full text-sm">
        <FlyoverContent paddingClassName="pt-6 px-6 pb-12">
          <SettingsSection
            title="Display Options"
            description="Select which rows to display and at which number colors change to red"
            className={cxStyles.borderedSection}
          >
            <div className="grid grid-cols-[10rem,1fr] gap-4 border border-slate-300">
              {SEQUENCE_TYPES_ORDERED.map((sequenceType) => {
                const title = sequenceToFullTitle[sequenceType];
                const thresholdSetting = sequenceToThresholdSettings[sequenceType];

                return (
                  <React.Fragment key={sequenceType}>
                    <div className="mt-0.5">
                      <Switch
                        checked={settingsDraft[sequenceToVisibility[sequenceType]]}
                        onChange={(event) => {
                          updateSettingsDraft({
                            [sequenceToVisibility[sequenceType]]: event.currentTarget.checked,
                          });
                        }}
                        labelPosition="left"
                        labelSpacing="between"
                      >
                        {title}
                      </Switch>
                    </div>
                    {thresholdSetting ? (
                      <div className="flex items-center text-xs gap-2">
                        <span>Change color when</span>
                        <span className="font-sansSemiBold">{`${
                          thresholdSetting.condition === "lte" ? "≤" : "≥"
                        }`}</span>
                        <PerioChartNumericInput
                          className="w-8 h-6"
                          theme="soft"
                          value={settingsDraft[thresholdSetting.setting]}
                          onChange={(event) => {
                            updateSettingsDraft({
                              [thresholdSetting.setting]: Number(event.currentTarget.value),
                            });
                          }}
                        />
                      </div>
                    ) : (
                      <div />
                    )}
                  </React.Fragment>
                );
              })}
            </div>
          </SettingsSection>

          <SettingsSection
            title="Summaries"
            description="Choose which summaries you would like below your perio chart"
            className={cxStyles.borderedSection}
          >
            <div className="flex justify-between w-full">
              <RadioList
                label="Dentition"
                layout="horiz"
                selectedValue={settingsDraft.showDentitionSummary ? 1 : 0}
                onChange={(_e, option) => {
                  updateSettingsDraft({ showDentitionSummary: Boolean(option.value) });
                }}
                options={showHideOptions}
                optionClassName="font-sans"
                className="flex-1"
              />
              <RadioList
                label="Implants"
                layout="horiz"
                selectedValue={Number(settingsDraft.showImplantSummary ? 1 : 0)}
                onChange={(_e, option) => {
                  updateSettingsDraft({ showImplantSummary: Boolean(option.value) });
                }}
                options={showHideOptions}
                optionClassName="font-sans"
                className="flex-1"
              />
            </div>
          </SettingsSection>
          <SettingsSection
            title="Auto Advance"
            description="Choose the default direction to advance in and when to stop."
          >
            <div className="flex flex-col gap-4">
              <div className="flex justify-between w-full">
                <RadioList
                  label="Maxillary Direction"
                  layout="horiz"
                  selectedValue={settingsDraft.autoAdvance}
                  onChange={(_e, option) => {
                    updateSettingsDraft({ autoAdvance: option.value });
                  }}
                  options={autoAdvanceDirectionOptions}
                  optionClassName="font-sans"
                  className="flex-1"
                />
                <RadioList
                  label="Mandibular Direction"
                  layout="horiz"
                  selectedValue={settingsDraft.autoAdvanceMandibular}
                  onChange={(_e, option) => {
                    updateSettingsDraft({ autoAdvanceMandibular: option.value });
                  }}
                  options={autoAdvanceDirectionOptions}
                  optionClassName="font-sans"
                  className="flex-1"
                />
              </div>
              <div className="flex justify-between w-full">
                <RadioList
                  label="Sequence End"
                  layout="horiz"
                  selectedValue={Number(settingsDraft.autoAdvanceStop ? 1 : 0)}
                  onChange={(_e, option) => {
                    updateSettingsDraft({ autoAdvanceStop: Boolean(option.value) });
                  }}
                  options={autoAdvanceStopOptions}
                  optionClassName="font-sans"
                  className="flex-1"
                />
              </div>
            </div>
          </SettingsSection>
        </FlyoverContent>
        <FlyoverFooter>
          <Button className="min-w-button" onClick={onClose} theme="secondary">
            Cancel
          </Button>
          <AsyncButton
            className="min-w-button"
            onClick={handleSubmit}
            theme="primary"
            isLoading={isSavingSettings}
          >
            Apply
          </AsyncButton>
        </FlyoverFooter>
      </FlyoverForm>
    </Flyover>
  );
};
