import { FC } from "react";
import { FormFieldTextarea, FormFieldTextareaProps } from "components/UI/FormFieldTextarea";

export const WorktimeComment: FC<Pick<FormFieldTextareaProps, "error" | "value" | "onChange">> = ({
  error,
  value,
  onChange,
}) => {
  return (
    <FormFieldTextarea
      className="mt-5"
      label="Comment"
      required={true}
      error={error}
      onChange={onChange}
      placeholder="Write a comment..."
      value={value}
    />
  );
};
