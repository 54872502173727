import { FC, FormEvent, useState } from "react";

import { PublicHolidayVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Form } from "@libs/components/UI/Form";

import { handleError } from "utils/handleError";
import { getWorkingHoursQuery } from "api/scheduling/queries";

import { upsertPublicHolidays } from "api/scheduling/mutations";
import { EditablePublicHoliday, EditUsHolidays } from "./EditUsHolidays";

interface Props {
  publicHolidays: PublicHolidayVO[];
  onClose: () => void;
  onYearSelect: (year: string) => void;
  selectedYear: string;
}

export const UsHolidaysForm: FC<Props> = ({ publicHolidays, onClose, onYearSelect, selectedYear }) => {
  const [editableHolidays, setEditableHolidays] = useState<EditablePublicHoliday[]>(() =>
    publicHolidays.map((item) => ({
      ...item,
      timeRanges: item.timeRanges?.map((range) => ({
        ...range,
        id: crypto.randomUUID(),
      })),
    }))
  );
  const { practiceId } = useAccount();
  const [workingHoursQuery] = useApiQueries([getWorkingHoursQuery({ args: { practiceId } })]);
  const [updatePublicHolidaysMutation] = useApiMutations([upsertPublicHolidays]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // strip of ids for local tracking
    const data = editableHolidays.map((item) => ({
      ...item,
      timeRanges: item.timeRanges?.map((range) => {
        const { id: _id, ...rest } = range;

        return rest;
      }),
    }));

    updatePublicHolidaysMutation.mutate(
      { practiceId, data },
      {
        onSuccess: onClose,
        onError: handleError,
      }
    );
  };

  return (
    <Form onSubmit={handleSubmit}>
      <QueryResult queries={[workingHoursQuery]}>
        <EditUsHolidays
          publicHolidays={editableHolidays}
          workingHourItems={workingHoursQuery.data ?? []}
          onChange={setEditableHolidays}
          onYearSelect={onYearSelect}
          selectedYear={selectedYear}
        />
      </QueryResult>

      <div className="mt-3 mb-4">
        <Button theme="secondary" size="large" onClick={onClose} type="button" className="w-24">
          Cancel
        </Button>
        <AsyncButton
          isLoading={updatePublicHolidaysMutation.isLoading}
          type="submit"
          theme="primary"
          size="large"
          className="w-24 ml-3"
        >
          Save
        </AsyncButton>
      </div>
    </Form>
  );
};
