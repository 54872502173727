import React from "react";
import { ClaimVO } from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { SelectInsuranceCarrierFilters } from "components/Dashboard/Tables/SelectInsuranceCarrierFilters";
import { useQueryParams } from "hooks/useQueryParams";
import { CarriersQuery } from "components/Dashboard/types";
import { DashboardFilterFormFieldSelect } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";

const CLAIM_STATES: ClaimVO["state"][] = ["SUBMITTED", "FAILED", "COMPLETED"];

const CLAIM_STATE_OPTIONS = CLAIM_STATES.map((value) => ({
  value,
  label: sentenceCaseConstant(value),
}));

export const ByCarrierFilters: React.FC<{
  carriersQuery: CarriersQuery;
}> = ({ carriersQuery }) => {
  const { query, updateQuery } = useQueryParams("dashboardPaymentOutstanding");

  return (
    <div className="relative flex gap-3">
      <SelectInsuranceCarrierFilters
        carriersQuery={carriersQuery}
        filters={query.filters}
        dashboardFilterType="insuranceCarrierId"
        onChange={({ filters }) => {
          updateQuery("replaceIn", {
            filters,
          });
        }}
      />

      <DashboardFilterFormFieldSelect
        isSearchable
        className="min-w-32"
        options={CLAIM_STATE_OPTIONS}
        placeholder="Claim Status"
        dashboardFilters={query.filters}
        dashboardFilterType="claimState"
        onChangeFilters={({ filters }) => {
          updateQuery("replaceIn", {
            filters,
          });
        }}
      />
    </div>
  );
};
