import { FC, HTMLProps, useState } from "react";

import { Button } from "@libs/components/UI/Button";

interface Props extends HTMLProps<HTMLDivElement> {
  disableExpand: boolean;
  onExpandAll: Func;
  onCollapseAll: Func;
}

export const HeaderTitle: FC<Props> = ({ disableExpand, onExpandAll, onCollapseAll }) => {
  const [showExpandAll, setShowExpandAll] = useState(true);

  return (
    <div
      className={`
        flex
        items-center
        justify-between
        h-14
        px-6
        border-slate-200
        border-r
        border-b
      `}
    >
      <div className="font-sansSemiBold">Documents</div>

      <Button
        className="text-xs"
        onClick={() => {
          showExpandAll ? onExpandAll() : onCollapseAll();
          setShowExpandAll(!showExpandAll);
        }}
        disabled={disableExpand}
        theme="link"
      >
        {showExpandAll ? "Expand All" : "Collapse All"}
      </Button>
    </div>
  );
};
