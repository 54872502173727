interface IRecord {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export interface PropertyChangeHandler<T> {
  (property: keyof T, value: T[keyof T]): void;
}

interface ChangeTrackedRecordTracker<T extends IRecord> {
  setValue: (property: keyof T, value: T[keyof T], dirty?: boolean) => void;
  setData: (record: Readonly<T>) => void;
  getRecord: () => Readonly<T>;
  acceptChanges: () => void;
  revert: () => void;
  isModified: (property?: keyof T) => boolean;
  /**
   * Subscribers to change events on the record.
   * @param The change event listener.
   * @returns The unsubscribe function for the listener.
   */
  subscribe: (handler: PropertyChangeHandler<T>) => () => void;
}

export type ChangeTrackedRecord<T extends IRecord> = ChangeTrackedRecordTracker<T> & T;

// Note: Some potential performance improvements if this ends up being slow.
// Use a linked list for the change listeners instead of an array.
// Change currentRec to only track different values, however, getting the combined record
// via `GetRecord` will result in a whole new record to merge them..

class ChangeTrackedRecordImpl<T extends IRecord> implements ChangeTrackedRecordTracker<T> {
  private _origRec: T;
  private _currentRec: T;
  private _changeListeners: PropertyChangeHandler<T>[] = [];
  private _changed = false;

  constructor(record: Readonly<T>, schema: (keyof T)[]) {
    this._origRec = record;
    this._currentRec = { ...record };

    schema.forEach((propertyName) => this._defineRecordProperty(propertyName));
  }

  public setValue(property: keyof T, value: T[keyof T], dirty = true) {
    if (value === this._currentRec[property]) {
      return;
    }

    this._currentRec[property] = value;

    // Sync original record with new value if it shouldn't make it a modified.
    if (dirty) {
      this._changed = true;
    } else {
      this._origRec[property] = value;
    }

    this._notifyListeners(property);
  }

  public setData(record: Readonly<T>) {
    this._origRec = record;
    this._currentRec = { ...record };
    this._changed = false;

    if (this._changeListeners.length > 0) {
      const properties = Object.keys(record) as (keyof T)[];

      properties.forEach((propertyName) => this._notifyListeners(propertyName));
    }
  }

  public getRecord(): Readonly<T> {
    return this._currentRec;
  }

  public acceptChanges() {
    this._origRec = { ...this._currentRec };
    this._changed = false;
  }

  public revert() {
    const properties = Object.keys(this._currentRec) as (keyof T)[];
    const oldRecord = this._currentRec;

    this._currentRec = { ...this._origRec };
    this._changed = false;

    if (this._changeListeners.length > 0) {
      const changedProperties = properties.filter(
        // eslint-disable-next-line eqeqeq
        (propertyName) => oldRecord[propertyName] != this._origRec[propertyName]
      );

      changedProperties.forEach((propertyName) => this._notifyListeners(propertyName));
    }
  }

  public isModified(property?: keyof T) {
    if (this._changed) {
      // If property is supplied, then only check that property.
      if (property) {
        // eslint-disable-next-line eqeqeq
        return this._currentRec[property] != this._origRec[property];
      }

      // Compare the values using JS type conversion so null and undefined are considered equal.
      for (const prop in this._currentRec) {
        // eslint-disable-next-line eqeqeq
        if (this._currentRec[prop] != this._origRec[prop]) {
          return true;
        }
      }
    }

    return false;
  }

  public subscribe(handler: PropertyChangeHandler<T>) {
    this._changeListeners.push(handler);

    let unsubscribed = false;

    return () => {
      if (!unsubscribed) {
        this._changeListeners.splice(this._changeListeners.indexOf(handler), 1);
        unsubscribed = true;
      }
    };
  }

  private _defineRecordProperty(propertyName: keyof T) {
    Object.defineProperty(this, propertyName, {
      enumerable: true,
      get: () => this._currentRec[propertyName] as unknown,
      set: (value: T[keyof T]) => this.setValue(propertyName, value, true),
    });
  }

  private _notifyListeners(propertyName: keyof T) {
    // Iterate from reverse order in case unsubsribes happen while iterating.
    for (let i = this._changeListeners.length - 1; i >= 0; --i) {
      this._changeListeners[i](propertyName, this._currentRec[propertyName]);
    }
  }
}

/**
 * Creates a change tracked record with the proper typings.
 * @param record The initial record values.
 * @param schema The property names of the record to track.
 * @returns A ChangedTrackedRecord instance.
 */
export const createChangeTrackedRecord = <T extends IRecord>(record: T, schema: (keyof T)[]) => {
  return new ChangeTrackedRecordImpl<T>(record, schema) as unknown as ChangeTrackedRecord<T>;
};
