import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { ReactComponent as OpenProfileIcon } from "@libs/assets/icons/open-profile.svg";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { paths } from "utils/routing/paths";
import { getPatientInsurancesQuery } from "api/patientInsurance/queries";
import { PatientInsuranceBadges } from "components/Insurance/PatientInsuranceBadges";
import { EmptyInsurances } from "components/Insurance/EmptyInsurances";
import {
  BenefitsTable,
  DeductiblesTable,
  HistoryTable,
  LimitationsTable,
} from "components/PatientBlurbInfo/InsurancePeekTables";
import { Divider } from "components/UI/Divider";
import { getInsuranceLabel } from "components/Insurance/utils";
import { FlyoverHeader } from "components/UI/FlyoverComponents";

interface Props {
  patientId: number;
  onRequestClose: Func;
}

export const InsuranceInfoPeek: React.FC<Props> = ({ patientId, onRequestClose }) => {
  const { practiceId } = useAccount();
  const [activeInsuranceQuery] = useApiQueries([
    getPatientInsurancesQuery({
      args: {
        practiceId,
        patientId,
        insuranceState: ["ACTIVE"],
        includeBenefitCoverage: true,
        includeBenefitLimitation: true,
        includeProcedureHistory: true,
        includeInsurancePlan: true,
      },
    }),
  ]);
  const { data: activeInsurances } = activeInsuranceQuery;
  const [primaryInsurance] = activeInsurances ?? [undefined];
  const insurancePlan = primaryInsurance?.insurancePlan;

  return (
    <>
      <FlyoverHeader size="sm">
        <div className="flex gap-x-2 items-center justify-between">
          <div className="flex-1">Primary Insurance</div>

          <LinkIcon
            className="text-primaryTheme font-sansSemiBold"
            to={paths.patientTab({ patientId, tab: "insurance" })}
            onClick={onRequestClose}
            SvgIcon={OpenProfileIcon}
            tooltip={{ content: "View More", theme: "SMALL" }}
          />
        </div>
      </FlyoverHeader>
      <div className="min-h-0 flex-1 overflow-y-auto p-6 gap-y-4 flex flex-col">
        <QueryResult queries={[activeInsuranceQuery]}>
          {primaryInsurance && insurancePlan ? (
            <>
              <div className="flex flex-col gap-y-2">
                <span className="text-xs font-sansSemiBold">{getInsuranceLabel(primaryInsurance)}</span>
                <div className="flex gap-x-1">
                  <PatientInsuranceBadges badgeSize="lg" primaryInsurance={primaryInsurance} />
                </div>
              </div>
              <BenefitsTable rows={primaryInsurance.benefitCoverages ?? []} />
              <div className="py-1">
                <Divider />
              </div>
              <DeductiblesTable primaryInsurance={primaryInsurance} />
              <div className="py-1">
                <Divider />
              </div>
              <LimitationsTable rows={primaryInsurance.benefitLimitations ?? []} />
              <div className="py-1">
                <Divider />
              </div>
              <HistoryTable rows={primaryInsurance.procedureHistory} />
            </>
          ) : (
            <EmptyInsurances
              actionStyle="link"
              allowAction={Boolean(primaryInsurance && !insurancePlan)}
              insurancePlan={insurancePlan}
              patientId={patientId}
              patientInsurance={primaryInsurance?.patientInsurance}
            />
          )}
        </QueryResult>
      </div>
    </>
  );
};
