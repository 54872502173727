type Props = {
  children: React.ReactNode;
};
export const ToolBoat: React.FC<Props> = ({ children }) => {
  return (
    <div
      className={`
        border
        border-slate-600
        w-full
        rounded-[4px]
        bg-slate-700
        divide-y
        divide-slate-600
      `}
    >
      {children}
    </div>
  );
};
