import React from "react";
import { FamilyMemberResponse, OnboardingOptionsVO, PatientVO, ProviderVO } from "@libs/api/generated-api";
import { PatientPersonalInformation } from "components/PatientProfile/PatientOverview/Info/PatientPersonalInformation";
import { PatientAdditionalInformation } from "components/PatientProfile/PatientOverview/Info/PatientAdditionalInformation";
import { PatientContactInformation } from "components/PatientProfile/PatientOverview/Info/PatientContactInformation";
import { Divider } from "components/UI/Divider";
import { EditablePatient, OnChangePatientHandler } from "components/PatientProfile/PatientOverview/types";
import { EditPatientValidation } from "components/Patient/formSchemas";
import { ResendInviteBanner } from "./ResendInviteBanner";

type Props = {
  familyMembers: FamilyMemberResponse;
  isEditing: boolean;
  patientId: number;
  onboardingState: PatientVO["onboardingState"];
  updatePatientRequest: EditablePatient;
  validation?: EditPatientValidation;
  onboarding?: OnboardingOptionsVO;
  providers?: ProviderVO[];
  onChange: OnChangePatientHandler;
  onAddContact: (patientId?: number) => void;
};

export const PatientInformation: React.FC<Props> = ({
  familyMembers,
  isEditing,
  patientId,
  onboardingState,
  updatePatientRequest,
  validation,
  onboarding,
  providers,
  onChange,
  onAddContact,
}) => {
  return (
    <div className="flex flex-col p-6 gap-y-6">
      {onboardingState !== "COMPLETED" && (
        <ResendInviteBanner rounded patientId={patientId} onboardingState={onboardingState} />
      )}
      <PatientPersonalInformation
        isEditing={isEditing}
        onChange={onChange}
        patient={updatePatientRequest}
        patientId={patientId}
        validation={validation}
      />
      <Divider className="border-dashed" />
      <PatientContactInformation
        edit={isEditing}
        onChange={onChange}
        patient={updatePatientRequest}
        patientId={patientId}
        familyMembers={familyMembers}
        validation={validation}
        onAddContact={onAddContact}
      />

      <Divider className="border-dashed" />
      <PatientAdditionalInformation
        isEditing={isEditing}
        onChange={onChange}
        patient={updatePatientRequest}
        providers={providers}
        onboarding={onboarding}
      />
    </div>
  );
};
