import React from "react";

import { PatientAppointmentStatsResponse } from "@libs/api/generated-api";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { PatientsTreatedQuery } from "utils/routing/dashboard";

import { FooterCell } from "components/Dashboard/Tables/FooterCell";

import { PatientsTreatedTableRow } from "components/Dashboard/PatientsTreated/PatientsTreatedTableRow";
import { getTotals } from "components/Dashboard/Tables/utils";
import { DashboardTableFilters } from "components/Dashboard/Tables/DashboardTableFilters";
import { CarriersQuery } from "components/Dashboard/types";

import { useFilterTokenProps } from "components/Dashboard/hooks/useFilterTokenProps";
import { SelectPatientType } from "components/Dashboard/Tables/SelectPatientType";
import { PATIENTS_TREATED_COLUMN_DATA } from "components/Dashboard/PatientsTreated/PatientsTreatedColumnData";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import { useFlattenEntries } from "components/Dashboard/hooks/useFlattenEntries";
import { DashboardTableHeader } from "components/Dashboard/Tables/DashboardTableHeader";
import { SelectInsuranceCarrierFilters } from "components/Dashboard/Tables/SelectInsuranceCarrierFilters";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";

export const PatientsTreatedTable: React.FC<{
  isFullScreen: boolean;
  onUpdateParams: (newParams: Partial<PatientsTreatedQuery>) => void;
  onClickDownload: Func;
  debouncedSearch: string;
  isDownloadingTable: boolean;
  patientSearch?: string;
  carriersQuery: CarriersQuery;
  query: PatientsTreatedQuery;
  appointmentStatsInfiniteQuery: UseInfiniteApiQueryResult<PatientAppointmentStatsResponse>;
}> = ({
  appointmentStatsInfiniteQuery,
  onUpdateParams,
  isDownloadingTable,
  onClickDownload,
  debouncedSearch,
  carriersQuery,
  query,
}) => {
  const { data: paginatedData, isLoading } = appointmentStatsInfiniteQuery;

  const totalRows = getInfiniteQueryPagingDetails(paginatedData)?.totalElements;
  const totals = getTotals(paginatedData);

  const rows = useFlattenEntries(paginatedData);
  const isEmpty = !isLoading && rows.length === 0;
  const filterParams = {
    patientSearch: debouncedSearch === "" ? undefined : debouncedSearch,
    filters: query.filters,
  };

  const filterProps = useFilterTokenProps({ options: filterParams, carriers: carriersQuery.data });
  const handleSortClick = React.useCallback(
    (newSortOrders: ColumnSortOrder[]) => {
      onUpdateParams({
        tableSort: newSortOrders,
      });
    },
    [onUpdateParams]
  );

  return (
    <div className="h-full w-full flex flex-col min-h-0">
      <DashboardTableHeader
        query={query}
        title="Show by Patient"
        onUpdateParams={onUpdateParams}
        isDownloading={isDownloadingTable}
        onClickDownload={onClickDownload}
        id="patients-treated-table-header"
      >
        <SelectPatientType onUpdateParams={onUpdateParams} filters={query.filters} />

        <SelectInsuranceCarrierFilters
          carriersQuery={carriersQuery}
          filters={query.filters}
          dashboardFilterType="primaryInsuranceCarrierId"
          onChange={({ filters }) => {
            onUpdateParams({
              filters,
            });
          }}
        />
      </DashboardTableHeader>
      <DashboardTableFilters {...filterParams}>
        <QueryFilterPills
          {...filterProps}
          numResults={totalRows}
          onUpdateParams={onUpdateParams}
          onClearAll={() => {
            onUpdateParams({ filters: [], patientSearch: undefined });
          }}
        />
      </DashboardTableFilters>
      <DashboardInfiniteTable
        id="patientsTreatedTable"
        isEmpty={isEmpty}
        infiniteQuery={appointmentStatsInfiniteQuery}
        columnWidths={PATIENTS_TREATED_COLUMN_DATA.map(({ width }) => width)}
        headerRow={
          <DashboardHeaderRow
            headers={PATIENTS_TREATED_COLUMN_DATA}
            onSortClick={handleSortClick}
            sortOrders={query.tableSort}
          />
        }
        footerRow={
          <>
            <FooterCell className="col-span-4">Totals</FooterCell>
            <FooterCell loading={isLoading}>{totals?.totalCompletedAppointments ?? ""}</FooterCell>
          </>
        }
      >
        {rows.map((item, i) => {
          return (
            <PatientsTreatedTableRow
              key={`${item.patient.id}_${item.patientDob}-${i}`}
              data={item}
              last={i === rows.length - 1}
            />
          );
        })}
      </DashboardInfiniteTable>
    </div>
  );
};
