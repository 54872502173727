import React, { useMemo } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { ReactComponent as SettingsIcon } from "@libs/assets/icons/settings.svg";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { useAccount } from "@libs/contexts/AccountContext";
import { lazyDefault } from "@libs/utils/lazyDefault";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ScheduleHeader } from "components/ScheduleAppointments/ScheduleHeader";
import { ScheduleTabs } from "components/ScheduleAppointments/ScheduleTabs";
import { DateFilters } from "components/DailyHuddle/DateFilters";
import { getPracticeRoomsQuery } from "api/scheduling/queries";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { DailyHuddleTable } from "components/DailyHuddle/Table";
import { getDailyHuddle, getDailyHuddleOverview } from "api/dailyHuddle/queries";
import { RECORDS_PER_PAGE } from "utils/words";
import { PatientSnapshotLayout } from "components/UI/PatientSnapshotLayout";
import { usePatientAppointmentQueryState } from "contexts/PatientAppointmentContext";
import { ScheduleAppHistoryProvider } from "components/ScheduleAppointments/ScheduleLinksContext";
import { useSelectRows } from "hooks/useSelectRows";
import { getTags } from "api/settings/notes/queries";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { DailyHuddleQueryUpdates } from "utils/routing/scheduling";
import { FlyoverV2 } from "components/UI/FlyoverV2";
import { useDailyHuddleQuery } from "components/DailyHuddle/useDailyHuddleQuery";
import { DailyHuddlePrintButton } from "components/DailyHuddle/DailyHuddlePrintButton";
import { HuddleEntryGroupsByDate } from "components/DailyHuddle/types";
import { DailyHuddleQueryFilters } from "components/DailyHuddle/DailyHuddleQueryFilters";

const DailyHuddleViewOptionsForm = lazyDefault(
  () => import("components/DailyHuddle/DailyHuddleviewOptionsForm"),
  "DailyHuddleViewOptionsForm"
);

export const DailyHuddleRoute: React.FC = () => {
  const { practiceId } = useAccount();
  const practice = useCurrentPractice();
  const user = useCurrentUser();

  usePageTitle("Daily Huddle");

  const viewOptionsFlyover = useBoolean(false);

  const { dailyHuddleQueryKey, query, updateQuery } = useDailyHuddleQuery(practice.timezoneId);

  const [providersQuery, roomsQuery, tagsQuery, dailyHuddleOverview] = useApiQueries([
    getPracticeProvidersQuery({ args: { practiceId } }),
    getPracticeRoomsQuery({ args: { practiceId } }),
    getTags({ args: { practiceId } }),
    getDailyHuddleOverview({
      args: {
        practiceId,
        data: { includeTagIds: query.tagIds, clinicalNoteStatuses: query.clinicalNoteStatuses },
      },
    }),
  ]);

  const dailyHuddleInfiniteQuery = useInfiniteApiQuery(
    getDailyHuddle({
      args: {
        ...dailyHuddleQueryKey,
        pageNumber: 1,
        pageSize: RECORDS_PER_PAGE,
        practiceId,
      },
    })
  );

  const totalRows = getInfiniteQueryPagingDetails(dailyHuddleInfiniteQuery.data)?.totalElements ?? 0;
  const huddleEntries = useFlattenPages(dailyHuddleInfiniteQuery.data);
  const excludedTagIds = useMemo(
    () => (user.type === "EMPLOYEE" ? user.employeeSettings?.excludedTagIds ?? [] : []),
    [user]
  );
  const appointmentIds = useMemo(() => huddleEntries?.map((entry) => entry.appointmentId), [huddleEntries]);
  const entryGroupsByDate = useMemo(() => {
    const groupsByDate: HuddleEntryGroupsByDate = {};

    huddleEntries?.forEach((entry) => {
      if (entry.appointmentDate in groupsByDate) {
        groupsByDate[entry.appointmentDate].push(entry);
      } else {
        groupsByDate[entry.appointmentDate] = [entry];
      }
    });

    return groupsByDate;
  }, [huddleEntries]);

  const {
    selectedCount,
    selectedRows,
    deselectedRowsFromSelectAll,
    hasAllSelected,
    selectAllRows,
    resetSelectedRows,
    handleCheckboxChange,
  } = useSelectRows(appointmentIds, { totalItems: totalRows });

  const handleQueryParamChange = React.useCallback(
    (updates: DailyHuddleQueryUpdates) => {
      updateQuery("replaceIn", updates);
      resetSelectedRows();
    },
    [updateQuery, resetSelectedRows]
  );

  const patientAppointment = usePatientAppointmentQueryState();

  return (
    <ScheduleAppHistoryProvider name="dailyHuddle">
      <PatientSnapshotLayout
        appointmentId={patientAppointment.appointmentId}
        patientId={patientAppointment.patientId}
        onSelectAppointment={patientAppointment.handleAppointmentSelected}
        onDeleteAppointment={patientAppointment.handleAppointmentDeleted}
        emptyText="No Appointment Selected"
      >
        <div className="flex flex-col h-full bg-white">
          <ScheduleHeader>
            <ScheduleTabs />
            <QueryResult queries={[dailyHuddleOverview]}>
              {dailyHuddleOverview.data && (
                <DateFilters
                  dailyHuddleOverview={dailyHuddleOverview.data}
                  onUpdateQueryParams={handleQueryParamChange}
                  queryState={query}
                />
              )}
            </QueryResult>
            <div className="flex items-center justify-end gap-x-4">
              <DailyHuddlePrintButton
                practice={practice}
                excludedTagIds={excludedTagIds}
                entryGroupsByDate={entryGroupsByDate}
              />
              {user.type === "SUPPORT_USER" ? (
                <ButtonIcon
                  disabled
                  tooltip={{ content: "View options are not supported for the support team" }}
                  SvgIcon={SettingsIcon}
                />
              ) : (
                <ButtonIcon
                  aria-label="settings-icon"
                  onClick={viewOptionsFlyover.on}
                  SvgIcon={SettingsIcon}
                />
              )}
            </div>
          </ScheduleHeader>
          <div className="border-t border-t-gray-200">
            <DailyHuddleQueryFilters
              totalRows={totalRows}
              selectedCount={selectedCount}
              selectedRows={selectedRows}
              hasAllSelected={hasAllSelected}
              deselectedRowsFromSelectAll={deselectedRowsFromSelectAll}
              onUpdateQuery={handleQueryParamChange}
              providers={providersQuery.data}
              rooms={roomsQuery.data}
              tags={tagsQuery.data}
              query={query}
              dailyHuddleInfiniteQuery={dailyHuddleInfiniteQuery}
              dailyHuddleQueryData={dailyHuddleQueryKey.data}
            />
          </div>
          <DailyHuddleTable
            huddleEntries={huddleEntries}
            currentAppointmentId={patientAppointment.appointmentId}
            dailyHuddleInfiniteQuery={dailyHuddleInfiniteQuery}
            dailyHuddleQueryKey={dailyHuddleQueryKey}
            entryGroupsByDate={entryGroupsByDate}
            excludedTagIds={excludedTagIds}
            selectedRows={selectedRows}
            selectedCount={selectedCount}
            totalRows={totalRows}
            onSelectAllRows={selectAllRows}
            onDeselectAllRows={resetSelectedRows}
            onCheckboxChange={handleCheckboxChange}
          />
        </div>

        {tagsQuery.data ? (
          <FlyoverV2 isOpen={viewOptionsFlyover.isOn} onClickAway={viewOptionsFlyover.off}>
            <DailyHuddleViewOptionsForm
              onRequestClose={viewOptionsFlyover.off}
              tagIds={excludedTagIds}
              tags={tagsQuery.data}
            />
          </FlyoverV2>
        ) : null}
      </PatientSnapshotLayout>
    </ScheduleAppHistoryProvider>
  );
};
