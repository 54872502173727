import React from "react";
import { RouterProvider, createBrowserRouter } from "react-router-dom";
import { enableMapSet, setAutoFreeze } from "immer";
import { ErrorBoundary as SentryErrorBoundary } from "@sentry/react";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { Amplify } from "aws-amplify";
import { SkeletonTheme } from "react-loading-skeleton";
import designConfig from "@libs/design.config";
import { LocalAndSessionStorage, StorageContextProvider } from "@libs/contexts/StorageContext";
import { initializeSentry } from "@libs/utils/sentry";
import { EnvContextProvider } from "contexts/EnvContext";
import { FeatureFlagsContextProvider } from "contexts/FeatureFlagsContext";
import type { EnvValues } from "env";
import { OriginContext } from "contexts/OriginContext";
import { RecoverError } from "components/Main/RecoverError";
import { getRoutes } from "router/routes";

export const getEntryPoint = ({
  createRouter,
  queryClient,
  storage,
  envValues,
  routerOptions,
  origin,
}: {
  envValues: EnvValues;
  storage: LocalAndSessionStorage;
  createRouter: typeof createBrowserRouter;
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  routerOptions?: Parameters<typeof createBrowserRouter>[1];
  queryClient: QueryClient;
  origin: string;
}) => {
  initializeSentry({
    appEnv: envValues.REACT_APP_ENVIRONMENT,
    release: envValues.REACT_APP_RELEASE_VERSION,
    dsn: "https://80ca96663d18469581a5e85f39a0ae3a@o1034289.ingest.sentry.io/6255026",
  });

  enableMapSet();

  // this is a workaround for https://github.com/SortableJS/react-sortablejs/issues/237
  setAutoFreeze(false);

  Amplify.configure({
    userPoolId: envValues.REACT_APP_COGNITO_USER_POOL_ID,
    userPoolWebClientId: envValues.REACT_APP_COGNITO_USER_POOL_WEB_CLIENT_ID,
    endpoint: envValues.REACT_APP_COGNITO_ENDPOINT,
    // OAuth for Archy Support (Archy Workspace via Google sign-in)
    oauth: {
      domain: envValues.REACT_APP_AUTH_DOMAIN,
      scope: ["email", "profile", "openid"],
      redirectSignIn: `${origin}/oauth/sign-in`,
      redirectSignOut: `${origin}/post-sign-out`,
      responseType: "code",
    },
  });

  const router = createRouter(
    getRoutes({ queryClient, baseUrl: envValues.REACT_APP_API_HOST, env: envValues, storage }),
    routerOptions
  );

  return {
    router,
    Component: () => {
      return (
        <SentryErrorBoundary fallback={<RecoverError />}>
          <QueryClientProvider client={queryClient}>
            <React.StrictMode>
              <OriginContext.Provider value={origin}>
                <EnvContextProvider value={envValues}>
                  <StorageContextProvider value={storage}>
                    <FeatureFlagsContextProvider>
                      <SkeletonTheme
                        baseColor={designConfig.colors.slate["200"]}
                        highlightColor={designConfig.colors.slate["100"]}
                      >
                        <RouterProvider router={router} />
                      </SkeletonTheme>
                    </FeatureFlagsContextProvider>
                  </StorageContextProvider>
                </EnvContextProvider>
              </OriginContext.Provider>
            </React.StrictMode>
            <ReactQueryDevtools initialIsOpen={false} position="bottom-right" />
          </QueryClientProvider>
        </SentryErrorBoundary>
      );
    },
  };
};
