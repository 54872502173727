import { HeaderData } from "components/Dashboard/Tables/types";

const TOOLTIPS = {
  grossScheduled: {
    content:
      "The gross production amount of all scheduled procedures, not yet completed. Gross production utilizes the UCR fees for each procedure and does not take into account the negotiated rates for procedures or any additional adjustments.",
  },
  grossProduction: {
    content:
      "The gross production amount of all completed procedures. Gross production utilizes the UCR fees for each procedure and does not take into account the negotiated rates for procedures or any additional adjustments.",
  },
  netProduced: {
    content:
      "The net production amount of all completed procedures. Net production utilizes the negotiated rates for each procedure, when applicable, to reflect the expected payment amount by both the insurance and patient as dictated by each insurance carrier. Additional collection adjustments are not included in the net production totals.",
  },
};

const GROSS_REVENUE_COLUMNS: HeaderData[] = [
  { label: "Gross Scheduled", width: "10rem", align: "center", tooltip: TOOLTIPS.grossScheduled },
  { label: "Gross Production", width: "11rem", align: "center", tooltip: TOOLTIPS.grossProduction },
  { label: "Net Produced", width: "10rem", align: "center", tooltip: TOOLTIPS.netProduced },
];

export const PRODUCTION_BY_APPT_COLUMNS: HeaderData[] = [
  { label: "Patient", width: "1fr", sortField: "patientLastName" },
  { label: "Appt Date", width: "1fr", sortField: "productionDate" },
  { label: "Provider", width: "1fr", sortField: "providerLastName" },
  { label: "Procedures", width: "minmax(10rem,15rem)" },
  ...GROSS_REVENUE_COLUMNS,
  { label: "", width: "3.5rem" },
];

export const PRODUCTION_BY_PROVIDER_COLUMNS: HeaderData[] = [
  { label: "Provider", width: "1fr" },
  { label: "Patients", width: "1fr" },
  { label: "Net Produced per Patient", width: "1fr", align: "right" },
  { label: "Net Produced", width: "1fr", align: "right" },
];
export const PRODUCTION_BY_HYGIENIST_COLUMNS: HeaderData[] = [
  { label: "Hygienist", width: "1fr" },
  { label: "Provider", width: "1fr" },
  {
    label: "Patients",
    width: "1fr",
    align: "right",
    tooltip: {
      content:
        "The number of patients with completed appointments, which factors into the Net Produced Per Patient amounts and the Net Produced Amounts shown.",
    },
  },
  {
    label: "Net Produced / Pt",
    width: "1fr",
    align: "right",
    tooltip: {
      content: "The average net production per patient with a completed appointment.",
    },
  },
  {
    label: "Net Produced",
    width: "1fr",
    align: "right",
    tooltip: {
      content:
        "The net production amount of all completed procedures. Net production utilizes the negotiated rates for each procedure, when applicable, to reflect the expected payment amount by both the insurance and patient as dictated by each insurance carrier. Additional collection adjustments are not included in the net production totals.",
    },
  },
];

export const PRODUCTION_BY_PROCEDURE_COLUMNS: HeaderData[] = [
  { label: "Patient", width: "1fr", sortField: "patientLastName" },
  { label: "Appt Date", width: "1fr", sortField: "productionDate" },
  { label: "Provider", width: "1fr", sortField: "providerLastName" },
  { label: "CDT Code", width: "1fr", sortField: "dentalProcedureCdtCode" },
  { label: "Procedure - Name", width: "1fr", sortField: "dentalProcedureSimpleName" },
  ...GROSS_REVENUE_COLUMNS,
  { label: "", width: "3.5rem" },
];

// Default Filters
export const NOT_SCHEDULED_FILTER = { type: "isScheduled", values: ["false"] };
