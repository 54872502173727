import { FC, RefObject, Dispatch, SetStateAction } from "react";

import { InsuranceCarrierVO, PatientListCriteria } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { FlyoverContent } from "components/UI/FlyoverComponents";

import { StatusFilterSection } from "components/Communications/Filters/FilterSections/StatusFilterSection";
import { AppointmentFilterSection } from "components/Communications/Filters/FilterSections/AppointmentFilterSection";
import { AgeFilterSection } from "components/Communications/Filters/FilterSections/AgeFilterSection";
import { GenderFilterSection } from "components/Communications/Filters/FilterSections/GenderFilterSection";
import { BirthdayFilterSection } from "components/Communications/Filters/FilterSections/BirthdayFilterSection";
import { InsuranceCarrierFilterSection } from "components/Communications/Filters/FilterSections/InsuranceCarrierFilterSection";
import { PatientBalanceFilterSection } from "components/Communications/Filters/FilterSections/PatientBalanceFilterSection";
import { InsuranceBalanceFilterSection } from "components/Communications/Filters/FilterSections/InsuranceBalanceFilterSection";
import { RemainingBenefitAmountFilterSection } from "components/Communications/Filters/FilterSections/RemainingBenefitAmountFilterSection";
import { PreferredLanguageFilterSection } from "components/Communications/Filters/FilterSections/PreferredLanguageFilterSection";

import { PatientListCriteriaValidationResult } from "components/Communications/Filters/types";

interface Props {
  containerRef: RefObject<HTMLDivElement>;
  patientListCriteria: PatientListCriteria;
  insuranceCarriersQuery: ApiQueryResult<InsuranceCarrierVO[]>;
  validationResult: PatientListCriteriaValidationResult;
  onUpdatePatientListCriteria: Dispatch<SetStateAction<PatientListCriteria>>;
}

export const FiltersFlyoverContent: FC<Props> = ({
  containerRef,
  patientListCriteria,
  insuranceCarriersQuery,
  validationResult,
  onUpdatePatientListCriteria,
}) => {
  const { patientCriteria, appointmentWithinDays, appointmentDateRange, hasNoAppointment } =
    patientListCriteria;

  return (
    <FlyoverContent containerRef={containerRef} paddingClassName="px-8 py-6">
      <div className="flex flex-col gap-y-6">
        <StatusFilterSection
          status={patientCriteria?.status}
          onUpdatePatientListCriteria={onUpdatePatientListCriteria}
        />

        <AppointmentFilterSection
          appointmentWithinDays={appointmentWithinDays}
          appointmentDateRange={appointmentDateRange}
          hasNoAppointment={hasNoAppointment}
          onUpdatePatientListCriteria={onUpdatePatientListCriteria}
        />

        <AgeFilterSection
          ageGroup={patientCriteria?.ageGroup}
          validationResult={validationResult.patientCriteria.ageGroup}
          onUpdatePatientListCriteria={onUpdatePatientListCriteria}
        />

        <GenderFilterSection
          genders={patientCriteria?.genders}
          onUpdatePatientListCriteria={onUpdatePatientListCriteria}
        />

        <BirthdayFilterSection
          birthdayWithinDays={patientCriteria?.birthdayWithinDays}
          birthdayRange={patientCriteria?.birthdayRange}
          onUpdatePatientListCriteria={onUpdatePatientListCriteria}
        />

        <InsuranceCarrierFilterSection
          containerRef={containerRef}
          carrierIds={patientCriteria?.carrierIds}
          insuranceCarriersQuery={insuranceCarriersQuery}
          onUpdatePatientListCriteria={onUpdatePatientListCriteria}
        />

        <PatientBalanceFilterSection
          patientBalanceRange={patientCriteria?.patientBalanceRange}
          validationResult={validationResult.patientCriteria.patientBalanceRange}
          onUpdatePatientListCriteria={onUpdatePatientListCriteria}
        />

        <InsuranceBalanceFilterSection
          insuranceBalanceRange={patientCriteria?.insuranceBalanceRange}
          validationResult={validationResult.patientCriteria.insuranceBalanceRange}
          onUpdatePatientListCriteria={onUpdatePatientListCriteria}
        />

        <RemainingBenefitAmountFilterSection
          remainingBenefitAmountRange={patientCriteria?.remainingBenefitAmountRange}
          validationResult={validationResult.patientCriteria.remainingBenefitAmountRange}
          onUpdatePatientListCriteria={onUpdatePatientListCriteria}
        />

        <PreferredLanguageFilterSection
          preferredLanguages={patientCriteria?.preferredLanguages}
          hasPreferredLanguage={patientCriteria?.hasPreferredLanguage}
          onUpdatePatientListCriteria={onUpdatePatientListCriteria}
        />
      </div>
    </FlyoverContent>
  );
};
