import { DentalProcedureVO, PatientToothVO } from "@libs/api/generated-api";
import { ValidationResult } from "@libs/hooks/useValidation";
import { isDefined, isString } from "@libs/utils/types";
import { exactSetSize, minSetSize, required } from "@libs/utils/validators";
import { isOneOf } from "@libs/utils/isOneOf";
import { pluralize } from "@libs/utils/pluralize";
import { DraftPatientProcedureRequest } from "components/Charting/draftPatientProcedure";
import { getSurfaceSelectionCount, SurfaceSelection } from "components/Charting/toothSurfaces";

type Positions = NonNullable<NonNullable<DentalProcedureVO["areaSelection"]>["position"]>;

const PositionLabels: Record<Positions, string> = {
  ANTERIOR: "anterior",
  POSTERIOR: "posterior",
  PRIMARY: "primary",
  PERMANENT: "permanent",
  ARCH_LOWER: "lower arch",
  ARCH_UPPER: "upper arch",
};

export const isToothDisabledByDentalProcedure = (
  tooth: PatientToothVO,
  dentalProcedure: DentalProcedureVO
) => {
  const position = dentalProcedure.areaSelection?.position;

  if (!position) {
    return false;
  }

  if (isOneOf(position, ["POSTERIOR", "ANTERIOR"])) {
    return tooth.position !== position;
  }

  if (isOneOf(position, ["ARCH_LOWER", "ARCH_UPPER"])) {
    return tooth.arch !== position;
  }

  if (position === "PERMANENT") {
    return !isOneOf(tooth.state, ["PERMANENT", "PERMANENT_UNERUPTED", "MISSING"]);
  }

  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  if (position === "PRIMARY") {
    return !isOneOf(tooth.state, ["PRIMARY", "PRIMARY_UNERUPTED"]);
  }

  return false;
};

export const isArchDisabledByDentalProcedure = (
  arch: PatientToothVO["arch"],
  dentalProcedure: DentalProcedureVO
) => {
  const position = dentalProcedure.areaSelection?.position;

  return position && isOneOf(position, ["ARCH_LOWER", "ARCH_UPPER"]) && position !== arch;
};

export const isQuadrantDisabledByDentalProcedure = (
  quadrant: PatientToothVO["quadrant"],
  dentalProcedure: DentalProcedureVO
) => {
  const position = dentalProcedure.areaSelection?.position;

  if (position === "ARCH_LOWER" && isOneOf(quadrant, ["QUAD_UPPER_LEFT", "QUAD_UPPER_RIGHT"])) {
    return true;
  }

  if (position === "ARCH_UPPER" && isOneOf(quadrant, ["QUAD_LOWER_LEFT", "QUAD_LOWER_RIGHT"])) {
    return true;
  }

  return false;
};

const isSurfaceSelection = (val: unknown): val is SurfaceSelection => {
  if (!val) {
    return false;
  }

  return typeof val === "object";
};

const minSurfaces = (surfaces: number) => (value: unknown) =>
  !isSurfaceSelection(value) || getSurfaceSelectionCount(value) >= surfaces;

const maxSurfaces = (surfaces: number) => (value: unknown) =>
  !isSurfaceSelection(value) || getSurfaceSelectionCount(value) <= surfaces;

const exactSurfaces = (surfaces: number) => (value: unknown) =>
  !isSurfaceSelection(value) || getSurfaceSelectionCount(value) === surfaces;

const getValidateToothPosition =
  (teeth: PatientToothVO[]) => (dentalProcedure: DentalProcedureVO) => (value: unknown) => {
    const position = dentalProcedure.areaSelection?.position;

    if (!(value instanceof Set)) {
      return true;
    }

    if (!position) {
      return true;
    }

    const selectedTeeth = [...value]
      .map((toothName) => teeth.find((tooth) => tooth.toothName === toothName))
      .filter(isDefined);

    return !selectedTeeth.some((tooth) => isToothDisabledByDentalProcedure(tooth, dentalProcedure));
  };

const validArchPosition = (dentalProcedure: DentalProcedureVO) => (value: unknown) => {
  return !value || !isArchDisabledByDentalProcedure(value as PatientToothVO["arch"], dentalProcedure);
};

const validQuadrantPosition = (dentalProcedure: DentalProcedureVO) => (value: unknown) => {
  return !value || !isQuadrantDisabledByDentalProcedure(value as PatientToothVO["quadrant"], dentalProcedure);
};

export const isAreaSelectionRequired = (areaSelection: DentalProcedureVO["areaSelection"]) =>
  Boolean(areaSelection?.required);

export const isAreaTypeDisabledByDentalProcedure = (
  area: DraftPatientProcedureRequest["areaType"],
  dp: DentalProcedureVO
) => {
  const options = dp.areaSelection?.options || [];

  return area
    ? area === "TOOTH"
      ? !options.includes(area) && !options.includes("TOOTH_RANGE")
      : !options.includes(area)
    : false;
};

const validAreaPosition = (dp: DentalProcedureVO) => (value: unknown) => {
  if (!isString(value) || !value) {
    return true;
  }

  return !isAreaTypeDisabledByDentalProcedure(value as DraftPatientProcedureRequest["areaType"], dp);
};

const getToothSelectionsSchema = (
  dp: DentalProcedureVO,
  draft: DraftPatientProcedureRequest,
  validToothPosition: (dentalProcedure: DentalProcedureVO) => (value: unknown) => boolean
) => {
  const areaOptions = new Set(dp.areaSelection?.options);

  const teethSelectionCount = draft.toothSelections.size;

  const position = dp.areaSelection?.position;
  const positionLabel = position ? PositionLabels[position] : "";

  const positionError = position
    ? isOneOf(position, ["ANTERIOR", "POSTERIOR", "PRIMARY", "PERMANENT"])
      ? `This procedure is only for ${positionLabel} teeth`
      : `This procedure is only for teeth in the ${positionLabel}`
    : "";

  return {
    $ignore: draft.areaType !== "TOOTH",
    $validations: [
      {
        $v: required,
        $error: "Please make a tooth selection for this procedure",
        $ignore: !isAreaSelectionRequired(dp.areaSelection),
      },
      {
        $v: minSetSize(1),
        $error: "Please make a tooth selection for this procedure",
        $ignore: !isAreaSelectionRequired(dp.areaSelection),
      },
      {
        $v: exactSetSize(1),
        $error: "Only one tooth can be selected for this procedure",
        $ignore: !teethSelectionCount || areaOptions.has("TOOTH_RANGE"),
      },
      {
        $v: validToothPosition(dp),
        $error: positionError,
      },
    ],
  };
};

const getArchSchema = (dp: DentalProcedureVO, draft: DraftPatientProcedureRequest) => {
  const position = dp.areaSelection?.position;
  const positionLabel = position ? PositionLabels[position] : "";

  const positionError =
    position && isOneOf(position, ["ARCH_LOWER", "ARCH_UPPER"])
      ? `This procedure is only for the ${positionLabel}`
      : "";

  return {
    $ignore: draft.areaType !== "ARCH",
    $validations: [
      {
        $v: required,
        $error: "Please select an arch for this procedure",
        $ignore: !isAreaSelectionRequired(dp.areaSelection),
      },
      {
        $v: validArchPosition(dp),
        $error: positionError,
      },
    ],
  };
};

const getQuadrantSchema = (dp: DentalProcedureVO, draft: DraftPatientProcedureRequest) => {
  const position = dp.areaSelection?.position;
  const positionLabel = position ? PositionLabels[position] : "";

  const positionError =
    position && isOneOf(position, ["ARCH_LOWER", "ARCH_UPPER"])
      ? `This procedure is only for the ${positionLabel}`
      : "";

  return {
    $ignore: draft.areaType !== "QUADRANT",
    $validations: [
      {
        $v: required,
        $error: "Please select a quadrant for this procedure",
        $ignore: !isAreaSelectionRequired(dp.areaSelection),
      },
      {
        $v: validQuadrantPosition(dp),
        $error: positionError,
      },
    ],
  };
};

const getToothSurfaceSchema = (dp: DentalProcedureVO, draft: DraftPatientProcedureRequest) => {
  const surfaceCriteria = dp.areaSelection?.surface;
  const surfaceRequired = surfaceCriteria?.required;
  const surfaceMax = surfaceCriteria?.max || 0;
  const surfaceMin = surfaceCriteria?.min || 0;
  const isSurfaceRange = surfaceMax !== surfaceMin;
  const selectionCount = getSurfaceSelectionCount(draft.surfaces);
  const nonToothSelection = draft.areaType !== "TOOTH" || draft.toothSelections.size > 1;

  return {
    // If the user hasn't made any surface selections and an area selection is not required ignore
    $ignore:
      !surfaceRequired ||
      nonToothSelection ||
      (!selectionCount && !isAreaSelectionRequired(dp.areaSelection)),
    $validations: [
      {
        $v: minSurfaces(surfaceMin),
        $error: `Please select ${surfaceMin}-${surfaceMax} surfaces`,
        $ignore: !isSurfaceRange,
      },
      {
        $v: maxSurfaces(surfaceMax),
        $error: `Please select ${surfaceMin}-${surfaceMax} surfaces`,
        $ignore: !isSurfaceRange,
      },
      {
        $v: exactSurfaces(surfaceMin),
        $error: pluralize(
          surfaceMin,
          `Please select ${surfaceMin} surface`,
          `Please select ${surfaceMin} surfaces`
        ),
        $ignore: isSurfaceRange,
      },
    ],
  };
};

export const getPatientProcedureSchema = (
  dp: DentalProcedureVO,
  draft: DraftPatientProcedureRequest,
  teeth: PatientToothVO[],
  validateOptions?: {
    prosthetic?: boolean;
    provider?: boolean;
  }
) => {
  const validToothPosition = getValidateToothPosition(teeth);

  return {
    areaType: [
      {
        $v: required,
        $error: "Please select an area for the procedure",
        $ignore: !isAreaSelectionRequired(dp.areaSelection),
      },
      {
        $v: validAreaPosition(dp),
        $error: "Please select a valid area for the procedure",
        $ignore: !draft.areaType,
      },
    ],
    providerId: [
      {
        $v: required,
        $error: "Please select a provider.",
        $ignore: draft.treatmentType === "EXISTING_OTHER" || validateOptions?.provider === false,
      },
    ],
    surfaces: getToothSurfaceSchema(dp, draft),
    toothSelections: getToothSelectionsSchema(dp, draft, validToothPosition),
    arch: getArchSchema(dp, draft),
    quadrant: getQuadrantSchema(dp, draft),
    implantInstallType: [
      {
        $v: required,
        $error: "Please select whether the prosthetic is new or a replacement.",
        $ignore: !dp.prosthetic || !validateOptions?.prosthetic,
      },
    ],
    implantDate: [
      {
        $v: required,
        $error: "Please select the prior date of replacement.",
        $ignore: draft.implantInstallType !== "REPLACEMENT",
      },
    ],
    date: [
      {
        $v: required,
        $error: "Please select the date the procedure took place.",
        $ignore: !isOneOf(draft.treatmentType, ["EXISTING_CURRENT", "EXISTING_OTHER"]),
      },
    ],
  };
};

export const getPatientProceduresSchema = (
  dentalProcedures: DentalProcedureVO[],
  drafts: DraftPatientProcedureRequest[],
  teeth: PatientToothVO[],
  validateOptions?: {
    prosthetic?: boolean;
    provider?: boolean;
  }
) => {
  return {
    $items: dentalProcedures.map((dp, index) => {
      const draft = drafts[index];

      return getPatientProcedureSchema(dp, draft, teeth, validateOptions);
    }),
  };
};

export type PatientProcedureValidationResult = ValidationResult<
  DraftPatientProcedureRequest,
  ReturnType<typeof getPatientProcedureSchema>
>;

// eslint-disable-next-line complexity
export const isIncompleteSelection = (
  draft: DraftPatientProcedureRequest,
  dentalProcedure: DentalProcedureVO
) => {
  if (!dentalProcedure.areaSelection) {
    return false;
  }

  if (!draft.areaType) {
    return true;
  }

  const options = dentalProcedure.areaSelection.options;

  if (draft.areaType === "TOOTH") {
    if (!options.includes(draft.areaType) && !options.includes("TOOTH_RANGE")) {
      return true;
    }
  } else if (!options.includes(draft.areaType)) {
    return true;
  }

  if (draft.areaType === "MOUTH") {
    return false;
  }

  if (draft.areaType === "ARCH") {
    return !draft.arch;
  }

  if (draft.areaType === "QUADRANT") {
    return !draft.quadrant;
  }

  if (!draft.toothSelections.size) {
    return true;
  }

  if (dentalProcedure.areaSelection.surface) {
    return getSurfaceSelectionCount(draft.surfaces) === 0;
  }

  return false;
};
