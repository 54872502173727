import { useCallback, useState } from "react";
import { produce } from "immer";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { RequiredAsterisk } from "@libs/components/UI/RequiredAsterisk";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent, FlyoverFooter, FlyoverForm } from "components/UI/FlyoverComponents";

interface Props {
  isEditing: boolean;
  isSaving: boolean;
  onClose: Func;
  onUpdateOptions: (updateOptions: string[], onSuccess?: Func) => void;
  referralOptions: string[];
  selectedIndex: number;
}

const schema = {
  name: [{ $v: required, $error: "Name required" }],
};

export const ReferralsFlyover: React.FC<Props> = ({
  isEditing,
  isSaving,
  onClose,
  onUpdateOptions,
  referralOptions,
  selectedIndex,
}) => {
  const [referralName, setReferralName] = useState(referralOptions[selectedIndex] || "");

  const { result, validate } = useValidation({ name: referralName }, schema);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!validate().$isValid) {
        return;
      }

      onUpdateOptions(
        produce(referralOptions, (draft: string[]) => {
          draft[selectedIndex] = referralName;
        }),
        onClose
      );
    },
    [validate, onUpdateOptions, referralOptions, onClose, selectedIndex, referralName]
  );

  return (
    <Flyover onClose={onClose} size="sm" title={isEditing ? "Edit Referral Option" : "Add Referral Option"}>
      <FlyoverForm onSubmit={handleSubmit}>
        <FlyoverContent className="text-xs">
          <div className="flex flex-col gap-y-2 text-xs">
            <div className="flex font-sansSemiBold">
              Option Name
              <RequiredAsterisk />
            </div>
            <FormFieldInput
              edit={true}
              error={result.name.$error}
              onChange={(e) => {
                setReferralName(e.target.value);
              }}
              required
              type="text"
              value={referralName}
            />
          </div>
        </FlyoverContent>
        <FlyoverFooter>
          <Button className="min-w-button" onClick={onClose} theme="secondary">
            Cancel
          </Button>
          <AsyncButton className="min-w-button" isLoading={isSaving} theme="primary" type="submit">
            Save
          </AsyncButton>
        </FlyoverFooter>
      </FlyoverForm>
    </Flyover>
  );
};
