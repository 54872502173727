import { FC } from "react";
import { cx } from "@libs/utils/cx";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as WalletIcon } from "@libs/assets/icons/wallet.svg";
import { PaymentMethodBadge } from "components/PatientProfile/Billing/PaymentMethodBadge";

export const Wallet: FC<{
  name: string;
  balance: string;
  isSelected: boolean;
  disabled?: boolean;
  type?: "family";
}> = ({ name, balance, isSelected, disabled, type }) => {
  return (
    <div
      className={cx(
        `grid
         grid-cols-[min-content_1fr]
         items-center
         gap-x-3
         outline
         px-4
         py-3.5
         rounded
         relative`,
        isSelected ? "outline-primaryTheme outline-2" : "outline-greyLighter outline-1",
        disabled && "text-greyLight"
      )}
    >
      <Icon SvgIcon={WalletIcon} size="lg" />
      <div className="text-xs text-left flex flex-col gap-y-1 overflow-hidden">
        <div title={name} className="font-sansSemiBold truncate">
          {name}
        </div>
        <div>{balance}</div>
      </div>
      {type === "family" ? <PaymentMethodBadge theme="violet">Family</PaymentMethodBadge> : null}
    </div>
  );
};
