export const cxSidebarNavigationStyles = {
  imageDrop: `
    flex
    flex-col
    border-2
    border-transparent
    relative
    justify-center
    items-center
    mx-auto
    h-full
    overflow-hidden
  `,
};
