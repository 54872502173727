import {
  Spread,
  DOMConversionMap,
  DOMConversionOutput,
  DOMExportOutput,
  EditorConfig,
  NodeKey,
  SerializedTextNode,
  TextNode,
} from "lexical";

import { TemplateVariableVO } from "@libs/api/generated-api";

import { titleCaseConstant } from "@libs/utils/casing";

export type SerializedTemplateVariableNode = Spread<
  {
    templateVariableKey: TemplateVariableVO["key"];
    isAvailable: boolean;
    type: "templateVariable";
    version: 1;
  },
  SerializedTextNode
>;

export const $createTemplateVariableNode = (
  templateVariableKey: TemplateVariableVO["key"],
  isAvailable: boolean
): TemplateVariableNode => {
  const text = titleCaseConstant(templateVariableKey);
  const templateVariableNode = new TemplateVariableNode(templateVariableKey, text, isAvailable);

  templateVariableNode.setMode("segmented").toggleDirectionless();

  return templateVariableNode;
};

// Lexical Serialization & Deserialization with exportDOM and importDOM
// https://lexical.dev/docs/concepts/serialization
const convertTemplateVariableElement = (domNode: HTMLElement): DOMConversionOutput | null => {
  const templateVariableKey = domNode.dataset.lexicalTemplateVariable;

  if (templateVariableKey) {
    return {
      node: $createTemplateVariableNode(
        templateVariableKey as TemplateVariableVO["key"],
        domNode.dataset.lexicalTemplateVariableUnavailable !== "true"
      ),
    };
  }

  return null;
};

export class TemplateVariableNode extends TextNode {
  // eslint-disable-next-line @typescript-eslint/naming-convention
  __templateVariable: TemplateVariableVO["key"];

  // eslint-disable-next-line @typescript-eslint/naming-convention
  __isAvailable: boolean;

  static getType(): string {
    return "templateVariable";
  }

  static clone(node: TemplateVariableNode): TemplateVariableNode {
    return new TemplateVariableNode(node.__templateVariable, node.__text, node.__isAvailable, node.__key);
  }

  static importJSON(serializedNode: SerializedTemplateVariableNode): TemplateVariableNode {
    const node = $createTemplateVariableNode(serializedNode.templateVariableKey, serializedNode.isAvailable);

    node.setTextContent(serializedNode.text);
    node.setFormat(serializedNode.format);
    node.setDetail(serializedNode.detail);
    node.setMode(serializedNode.mode);
    node.setStyle(serializedNode.style);

    return node;
  }

  constructor(
    templateVariableKey: TemplateVariableVO["key"],
    text: string,
    isAvailable: boolean,
    key?: NodeKey
  ) {
    super(text, key);
    this.__templateVariable = templateVariableKey;
    this.__isAvailable = isAvailable;
  }

  exportJSON(): SerializedTemplateVariableNode {
    return {
      ...super.exportJSON(),
      isAvailable: this.__isAvailable,
      templateVariableKey: this.__templateVariable,
      type: "templateVariable",
      version: 1,
    };
  }

  createDOM(config: EditorConfig): HTMLElement {
    const dom = super.createDOM(config);

    dom.dataset.lexicalTemplateVariable = "true";
    dom.classList.add("rounded", "px-1");

    if (this.__isAvailable) {
      dom.classList.add("text-archyBlue-500", "bg-archyBlue-50");
    } else {
      dom.classList.add("text-red", "bg-redLight");
      dom.dataset.lexicalTemplateVariableUnavailable = "true";
    }

    dom.setAttribute("spellcheck", "false");

    return dom;
  }
  exportDOM(): DOMExportOutput {
    const element = document.createElement("span");

    element.dataset.lexicalTemplateVariableUnavailable = String(!this.__isAvailable);
    element.dataset.lexicalTemplateVariable = this.__templateVariable;
    element.textContent = this.__text;

    return { element };
  }

  isSegmented(): false {
    return false;
  }

  static importDOM(): DOMConversionMap | null {
    return {
      span: (domNode: HTMLElement) => {
        if (!Object.hasOwn(domNode.dataset, "lexicalTemplateVariable")) {
          return null;
        }

        return {
          conversion: convertTemplateVariableElement,
          priority: 1,
        };
      },
    };
  }

  isTextEntity() {
    return true as const;
  }

  isToken() {
    return true as const;
  }
}
