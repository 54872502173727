import { FC } from "react";

import { ToggleGroup } from "components/UI/ToggleGroup";

export interface MessageViewToggleGroupProps {
  channels: ("PATIENT_PREFERRED" | "SMS" | "EMAIL" | "MAIL")[];
  messageView: "SMS" | "EMAIL" | "MAIL";
  onMessageViewChange: (view: "SMS" | "EMAIL") => void;
  canSendSms?: boolean;
  canSendEmail: boolean;
}

export const MessageViewToggleGroup: FC<MessageViewToggleGroupProps> = ({
  channels,
  messageView,
  onMessageViewChange,
  canSendSms = true,
  canSendEmail,
}) => {
  const hasSmsDisabled = (channels.length === 1 && channels[0] === "EMAIL") || !canSendSms;
  const hasEmailDisabled = (channels.length === 1 && channels[0] === "SMS") || !canSendEmail;

  return (
    <ToggleGroup
      options={[
        {
          label: "SMS",
          value: "SMS",
          disabled: hasSmsDisabled,
        },
        {
          label: "Email",
          value: "EMAIL",
          disabled: hasEmailDisabled,
        },
      ]}
      selectedValue={messageView}
      onChange={(_, { value }) => onMessageViewChange(value)}
      disabled={hasSmsDisabled && hasEmailDisabled}
      size="md"
    />
  );
};
