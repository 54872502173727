import { GetApiMethod } from "@libs/@types/api";
import { AutomationJourneyVO } from "@libs/api/generated-api";
import { getNextPageParam, makeInfiniteQuery, makeQuery } from "@libs/utils/queries";
import { cacheForSession, fiveMinuteCache } from "@libs/utils/queryCache";

export const getAutomationJourney = makeQuery({
  queryKey: ["practices", "getAutomationJourney"],
  formatParams: (args: { practiceId: number; journeyType: AutomationJourneyVO["type"] }) => [
    args.practiceId,
    args.journeyType,
  ],
  queryOptions: fiveMinuteCache,
});

export const getAutomationAction = makeQuery({
  queryKey: ["practices", "getAutomationAction"],
  formatParams: (args: {
    actionUuid: string;
    journeyType: AutomationJourneyVO["type"];
    practiceId: number;
  }) => [args.practiceId, args.journeyType, args.actionUuid],
  queryOptions: fiveMinuteCache,
});

export type TemplateVariablesQueryParams = Parameters<
  GetApiMethod<"practices", "getPatientEngagementTemplateVariables">
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
>[1];

export const getPatientEngagementTemplateVariables = makeQuery({
  queryKey: ["practices", "getPatientEngagementTemplateVariables"],
  formatParams: ({ practiceId, ...query }: { practiceId: number } & TemplateVariablesQueryParams) => [
    practiceId,
    query,
  ],
  queryOptions: cacheForSession,
});

export const getMessageCampaign = makeQuery({
  queryKey: ["practices", "getMessageCampaign"],
  formatParams: (args: {
    practiceId: number;
    messageCampaignUuid: string;
    query?: { includeSummary?: boolean };
  }) => [args.practiceId, args.messageCampaignUuid, args.query],
  queryOptions: fiveMinuteCache,
});

export const listMessageCampaigns = makeQuery({
  queryKey: ["practices", "listMessageCampaigns"],
  formatParams: ({
    practiceId,
    ...query
  }: {
    practiceId: number;
    statuses?: ("DRAFT" | "SCHEDULED" | "SENT" | "CANCELED")[];
    channels?: ("PATIENT_PREFERRED" | "SMS" | "EMAIL" | "MAIL")[];
    isNamedCampaign?: boolean;
    maxPageSize?: number;
    pageNumber: number;
    pageSize: number;
  }) => [practiceId, query],
  queryOptions: {
    ...fiveMinuteCache,
  },
});

export const listSavedFilters = makeInfiniteQuery({
  queryKey: ["practices", "listSavedFilters"],
  formatParams: ({
    practiceId,
    ...query
  }: {
    practiceId: number;
    maxPageSize?: number;
    pageNumber: number;
    pageSize: number;
  }) => [practiceId, query],
  queryOptions: {
    getNextPageParam,
    ...fiveMinuteCache,
  },
});

export const getTwilioRegistrationStatus = makeQuery({
  queryKey: ["practices", "getTwilioRegistrationStatus"],
  formatParams: ({ practiceId }: { practiceId: number }) => [practiceId],
  queryOptions: fiveMinuteCache,
});

export const fetchAvailableTwilioPhoneNumbers = makeQuery({
  queryKey: ["practices", "fetchAvailableTwilioPhoneNumbers"],
  formatParams: ({ practiceId, ...query }: { practiceId: number; startsWith: string }) => [practiceId, query],
  queryOptions: cacheForSession,
});
