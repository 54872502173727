import React, { FormEventHandler, useMemo } from "react";

import {
  CompensationDetailsVO,
  EmployeeJobTitleV2VO,
  EmployeeOnboardingStatusVO,
  EmploymentDetailsVO,
  RoleVO,
  TaxonomyCodeVO,
} from "@libs/api/generated-api";
import { getLocalDate, formatAsISODate } from "@libs/utils/date";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { CurrencyInputOnChangeValues } from "react-currency-input-field";
import { Form } from "@libs/components/UI/Form";
import { Divider } from "components/UI/Divider";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { TooltipLabel } from "components/UI/TooltipLabel";
import { Section } from "components/EmployeeProfile/Section";

import {
  EmploymentStatusOptions,
  EmployeeCategoryOptions,
  EmploymentTypeOptions,
} from "components/Employee/data";

import { FormFieldNumericInput } from "components/UI/FormFieldNumericInput";
import { CompensationField } from "components/Employee/CompensationField";
import { FormFieldFlsaSelect } from "components/Employee/FormFieldFlsaSelect";
import { EmploymentValidationResult } from "components/Employee/schema";
import { EmploymentDetailsFormState } from "components/EmployeeProfile/types";
import { sortJobTitles } from "components/Employee/utils";
import { GustoPartnerBanner } from "./GustoPartnerBanner";
import { ExcludeFromPayrollSelect } from "./ExcludeFromPayroll";

type EmploymentPanelBodyProps = {
  employeeId: number;
  formId: string;
  formState: EmploymentDetailsFormState;
  employeeRoleV2: RoleVO;
  amount: Omit<CurrencyInputOnChangeValues, "formatted">;
  assignableRoles: RoleVO[];
  taxonomyCodes: TaxonomyCodeVO[];
  onRoleChange?: (newRoleId: number) => void;
  onEmploymentChange?: (partial: Partial<EmploymentDetailsVO>) => void;
  onExcludeFromPayrollChange?: (value: boolean) => void;
  onCompensationChange?: (partial: Partial<CompensationDetailsVO>) => void;
  onAmountChange?: (values: CurrencyInputOnChangeValues | undefined) => void;
  onSubmit?: FormEventHandler;
  validationResult?: EmploymentValidationResult;
  serverErrors: Record<string, string | undefined>;
  isEditing: boolean;
  disabledStartDate?: boolean;
  disabledEndDate?: boolean;
  payrollEnabled: boolean;
  canEditPayrollSettings?: boolean;
  canEditAccess?: boolean;
  employeePayrollOnboardingQuery: ApiQueryResult<EmployeeOnboardingStatusVO>;
  jobTitles: EmployeeJobTitleV2VO[];
};

// eslint-disable-next-line complexity
export const EmploymentDetailsForm: React.FC<EmploymentPanelBodyProps> = ({
  employeeId,
  formId,
  formState,
  employeeRoleV2,
  amount,
  assignableRoles,
  taxonomyCodes,
  onEmploymentChange,
  onCompensationChange,
  onAmountChange,
  onSubmit,
  onRoleChange,
  validationResult,
  serverErrors,
  isEditing,
  disabledStartDate,
  disabledEndDate,
  payrollEnabled,
  canEditPayrollSettings,
  canEditAccess,
  employeePayrollOnboardingQuery,
  jobTitles,
}) => {
  const jobTitleOptions = useMemo(
    () => sortJobTitles(jobTitles).map((jt) => ({ value: jt.key, label: jt.name })),
    [jobTitles]
  );

  const roleOptions = useMemo(() => {
    const canAssignCurrentRole = assignableRoles.some((role) => role.roleId === employeeRoleV2.roleId);
    const allAssignableRoles = canAssignCurrentRole ? assignableRoles : [employeeRoleV2, ...assignableRoles];

    return allAssignableRoles.map((role) => ({ value: role.roleId, label: role.name }));
  }, [assignableRoles, employeeRoleV2]);

  const taxonomyCodeOptions = useMemo(() => {
    return taxonomyCodes.map(({ code, description }) => ({
      label: `${code} - ${description}`,
      value: code,
    }));
  }, [taxonomyCodes]);

  const isExcludedFromPayroll = Boolean(formState.employmentDetails.excludeFromPayroll);

  const sharedProps = { edit: isEditing };

  return (
    <div className="max-w-xl mx-auto">
      {canEditPayrollSettings ? (
        <GustoPartnerBanner
          employeeId={employeeId}
          employeePayrollOnboardingQuery={employeePayrollOnboardingQuery}
        />
      ) : null}
      <Form id={formId} fieldLayout="labelOut" className="gap-y-6 flex flex-col" onSubmit={onSubmit}>
        <Section title="Employment">
          <FormFieldSelect
            label="Job Type"
            display="label"
            required={true}
            error={validationResult?.employmentDetails.jobTitleKey.$error}
            value={formState.employmentDetails.jobTitleKey}
            options={jobTitleOptions}
            onChange={(option) => {
              if (option) {
                onEmploymentChange?.({
                  jobTitleKey: option.value,
                  jobTitle: option.value === "UNKNOWN" ? "" : option.label,
                });
              }
            }}
            {...sharedProps}
          />
          <FormFieldInput
            label="Job Title"
            value={formState.employmentDetails.jobTitle}
            onChange={(e) => onEmploymentChange?.({ jobTitle: e.target.value })}
            error={serverErrors["employmentDetails.jobTitle"]}
            {...sharedProps}
          />
          <FormFieldSelect
            label={
              <TooltipLabel
                tooltip={{
                  content:
                    "An employees role dictates which areas of Archy they can access. These can be modified in Roles.",
                }}
              >
                Role
              </TooltipLabel>
            }
            display="label"
            menuPlacement="top"
            placeholder="Select Role..."
            options={roleOptions}
            value={formState.roleV2Id}
            onItemSelected={onRoleChange}
            error={serverErrors["roleV2Id"] || validationResult?.roleV2Id.$error}
            edit={isEditing && canEditAccess}
            required
          />
          <FormFieldSelectMenusDatepicker
            label={
              <TooltipLabel
                tooltip={{ content: "Date for when this employee will be able to sign in to Archy." }}
              >
                Access Date
              </TooltipLabel>
            }
            selected={
              formState.employmentDetails.accessDate
                ? getLocalDate(formState.employmentDetails.accessDate)
                : undefined
            }
            onChange={(date) => date && onEmploymentChange?.({ accessDate: formatAsISODate(date) })}
            error={
              validationResult?.employmentDetails.accessDate.$error ??
              serverErrors["employmentDetails.accessDate"]
            }
            edit={isEditing && canEditAccess}
            required
          />

          <FormFieldSelect
            label="Status"
            display="label"
            options={EmploymentStatusOptions}
            value={formState.employmentDetails.status}
            onChange={(e) => onEmploymentChange?.({ status: e?.value })}
            error={serverErrors["employmentDetails.status"]}
            required
            {...sharedProps}
          />
        </Section>

        <Divider className="border-dashed" />

        <Section title="Licensing">
          <FormFieldNumericInput
            label="NPI Number"
            value={formState.employmentDetails.npiNumber}
            onValueChange={(value) => onEmploymentChange?.({ npiNumber: value })}
            error={serverErrors["employmentDetails.npiNumber"]}
            {...sharedProps}
          />
          <FormFieldInput
            label="State License ID Number"
            value={formState.employmentDetails.stateLicenseNumber}
            onChange={(e) => onEmploymentChange?.({ stateLicenseNumber: e.target.value })}
            error={serverErrors["employmentDetails.stateLicenseNumber"]}
            type="text"
            {...sharedProps}
          />
          <FormFieldSelect
            label="Taxonomy Code"
            display="label"
            options={taxonomyCodeOptions}
            value={formState.employmentDetails.taxonomyCode}
            onChange={(e) => onEmploymentChange?.({ taxonomyCode: e?.value })}
            error={serverErrors["employmentDetails.taxonomyCode"]}
            {...sharedProps}
          />
          <FormFieldInput
            label="DEA Number"
            value={formState.employmentDetails.deaNumber}
            onChange={(e) => onEmploymentChange?.({ deaNumber: e.target.value })}
            error={serverErrors["employmentDetails.deaNumber"]}
            type="text"
            {...sharedProps}
          />
          <FormFieldInput
            label="State Rx Number"
            value={formState.employmentDetails.stateRxNumber}
            onChange={(e) => onEmploymentChange?.({ stateRxNumber: e.target.value })}
            error={serverErrors["employmentDetails.stateRxNumber"]}
            type="text"
            {...sharedProps}
          />
        </Section>

        <Divider className="border-dashed" />

        <Section title="Payroll">
          <ExcludeFromPayrollSelect
            payrollEnabled={payrollEnabled}
            employeeOnboardedStatus={employeePayrollOnboardingQuery.data?.status}
            excludeFromPayroll={formState.employmentDetails.excludeFromPayroll}
            onExcludueFromPayroll={(value) => onEmploymentChange?.({ excludeFromPayroll: value })}
            {...sharedProps}
          />
          <FormFieldSelectMenusDatepicker
            label="Start Date"
            selected={
              formState.employmentDetails.startDate
                ? getLocalDate(formState.employmentDetails.startDate)
                : null
            }
            onChange={(date) => date && onEmploymentChange?.({ startDate: formatAsISODate(date) })}
            error={
              serverErrors["employmentDetails.startDate"] ||
              validationResult?.employmentDetails.startDate.$error
            }
            required={!isExcludedFromPayroll}
            disabled={disabledStartDate}
            {...sharedProps}
          />
          <FormFieldSelectMenusDatepicker
            label="End Date"
            selected={
              formState.employmentDetails.endDate
                ? getLocalDate(formState.employmentDetails.endDate)
                : undefined
            }
            onChange={(date) => onEmploymentChange?.({ endDate: date ? formatAsISODate(date) : undefined })}
            error={
              validationResult?.employmentDetails.endDate.$error ?? serverErrors["employmentDetails.endDate"]
            }
            required={formState.employmentDetails.status === "ARCHIVED"}
            disabled={disabledEndDate}
            {...sharedProps}
          />

          <FormFieldSelect
            label="Employee Category"
            display="label"
            options={EmployeeCategoryOptions}
            value={formState.employmentDetails.employeeCategory}
            onChange={(e) => onEmploymentChange?.({ employeeCategory: e?.value })}
            error={
              serverErrors["employmentDetails.employeeCategory"] ||
              validationResult?.employmentDetails.employeeCategory.$error
            }
            required={!isExcludedFromPayroll}
            disabled={isExcludedFromPayroll}
            {...sharedProps}
          />
          <FormFieldSelect
            label="Employee Type"
            display="label"
            options={EmploymentTypeOptions}
            value={formState.employmentDetails.employmentType}
            onChange={(e) => onEmploymentChange?.({ employmentType: e?.value })}
            error={
              serverErrors["employmentDetails.employeeType"] ||
              validationResult?.employmentDetails.employmentType.$error
            }
            required={!isExcludedFromPayroll}
            disabled={isExcludedFromPayroll}
            {...sharedProps}
          />

          <CompensationField
            onAmountChange={(value, name, values) => onAmountChange?.(values)}
            onPeriodChange={(val) => onCompensationChange?.({ period: val })}
            isExcludedFromPayroll={isExcludedFromPayroll}
            amount={amount.value}
            period={formState.employmentDetails.compensationDetails?.period}
            isEditing={isEditing}
            periodError={
              serverErrors["employmentDetails.compensationDetails.period"] ||
              validationResult?.employmentDetails.compensationDetails.period.$error
            }
            amountError={
              serverErrors["Compensation amount"] ||
              serverErrors["employmentDetails.compensationDetails.amount"] ||
              validationResult?.employmentDetails.compensationDetails.amount.$error
            }
          />
          <FormFieldFlsaSelect
            display="label"
            value={formState.employmentDetails.flsaStatus}
            onChange={(e) => onEmploymentChange?.({ flsaStatus: e?.value })}
            error={
              validationResult?.employmentDetails.flsaStatus.$error ??
              serverErrors["employmentDetails.flsaStatus"]
            }
            required={!isExcludedFromPayroll}
            disabled={isExcludedFromPayroll}
            {...sharedProps}
          />
        </Section>
      </Form>
    </div>
  );
};
