import { useCallback } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { isNullish } from "@libs/utils/types";
import { getFullUrl } from "@libs/utils/location";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { createDraftEobPayment } from "api/claim/mutations";
import { handleError } from "utils/handleError";
import { paths } from "utils/routing/paths";

export const useCreateDraftEobPayment = (from?: string) => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const location = useLocation();
  const fullUrl = getFullUrl(location);

  const [createDraftEobPaymentMutation] = useApiMutations([createDraftEobPayment]);

  const handleCreateDraftEobPayment = useCallback(
    (selectedCarrierId: number | null, selectedClaimUuids: string[], options?: { onSettled?: Func }) => {
      if (isNullish(selectedCarrierId)) {
        return;
      }

      const data = { claimUuids: selectedClaimUuids, insuranceCarrierId: selectedCarrierId };

      createDraftEobPaymentMutation.mutate(
        { practiceId, data },
        {
          onError: handleError,
          onSuccess: (response) => {
            navigate(paths.eob({ draftEobPaymentUuid: response.data.data.uuid }, { from: from ?? fullUrl }));
          },
          onSettled: () => {
            options?.onSettled?.();
          },
        }
      );
    },
    [createDraftEobPaymentMutation, practiceId, navigate, from, fullUrl]
  );

  return { handleCreateDraftEobPayment };
};
