import { PayrollVO } from "@libs/api/generated-api";
import { getNextPageParam, makeInfiniteQuery, makeQuery } from "@libs/utils/queries";
import { oneMinuteCache, cacheForSession } from "@libs/utils/queryCache";

export const getCompanyOnboardingStatus = makeQuery({
  queryKey: ["practices", "getCompanyOnboardingStatus"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: oneMinuteCache,
});

export const getEmployeeOnboardStatus = makeQuery({
  queryKey: ["practices", "getEmployeeOnboardStatus"],
  formatParams: (args: { practiceId: number; employeeId: number }) => [args.practiceId, args.employeeId],
  queryOptions: oneMinuteCache,
});

export const getEmployeeConsolidation = makeQuery({
  queryKey: ["practices", "getEmployeeConsolidation"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: cacheForSession,
});

export const getGustoOnboardingErrors = makeQuery({
  queryKey: ["practices", "getGustoOnboardingErrors"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: oneMinuteCache,
});

export const getPayrolls = makeQuery({
  queryKey: ["practices", "getPayrolls"],
  formatParams: ({
    practiceId,
    pageNumber,
    pageSize,
    type,
    year,
    unprocessed,
    processed,
  }: {
    practiceId: number;
    type?: PayrollVO["type"];
    year?: number;
    processed?: boolean;
    unprocessed?: boolean;
    pageNumber: number;
    pageSize: number;
  }) => [practiceId, { pageNumber, pageSize, type, year, unprocessed, processed }],
  queryOptions: oneMinuteCache,
});

export const getInfinitePayPeriods = makeInfiniteQuery({
  queryKey: ["practices", "getPayPeriods"],
  formatParams: ({
    practiceId,
    pageNumber,
    pageSize,
  }: {
    practiceId: number;
    pageNumber: number;
    pageSize: number;
  }) => [practiceId, { pageNumber, pageSize }],
  queryOptions: {
    ...oneMinuteCache,
    getNextPageParam,
  },
});

export const getPayroll = makeQuery({
  queryKey: ["practices", "getPayroll"],
  formatParams: ({ practiceId, payrollId }: { practiceId: number; payrollId: string }) => [
    practiceId,
    payrollId,
  ],
  queryOptions: oneMinuteCache,
});

export const printEmployeePayStub = makeQuery({
  queryKey: ["practices", "printEmployeePayStub"],
  formatParams: ({
    practiceId,
    employeeId,
    payrollId,
  }: {
    practiceId: number;
    employeeId: number;
    payrollId: string;
  }) => [practiceId, employeeId, payrollId],
  queryOptions: oneMinuteCache,
});
