import { LegendOrdinal } from "@visx/legend";
import { LegendProps } from "@visx/legend/lib/legends/Legend";
import { PickD3Scale } from "@visx/scale";

// eslint-disable-next-line @typescript-eslint/no-explicit-any
declare type AnyOrdinalScale = PickD3Scale<"ordinal", any, any>;

export const ChartLegend = <Scale extends AnyOrdinalScale>(props: LegendProps<Scale>) => {
  return (
    <div
      className={`
        capitalize
        absolute
        w-full
        justify-end
        flex
        font-normal
        text-xs
        top-0
      `}
    >
      <LegendOrdinal
        labelFormat={props.labelFormat}
        className="text-sm"
        direction="row"
        labelMargin="0 15px 0 0"
        {...props}
      />
    </div>
  );
};
