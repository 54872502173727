import { Navigate, useLocation } from "react-router-dom";
import { ReactNode } from "react";
import { paths } from "utils/routing/paths";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { checkPermission } from "components/Roles/roleUtils";

interface Props {
  employeeId: number;
  children?: ReactNode;
}

export const EmployeeGuard: React.FC<Props> = ({ employeeId, children }) => {
  const currentUser = useCurrentUser();
  const location = useLocation();
  const isEditEmployment = location.pathname.endsWith("/employment") && location.search.includes("edit=1");
  const hasEmployeeAccess =
    checkPermission(currentUser.roleV2, "EMPLOYEE_SETTINGS", "ACCESS_ALL").isPermitted ||
    (employeeId === currentUser.id && !isEditEmployment);

  return hasEmployeeAccess ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : (
    <Navigate
      replace
      to={
        isEditEmployment
          ? paths.employeeEmployment({ id: currentUser.id })
          : paths.employee({ id: currentUser.id })
      }
    />
  );
};
