import { ProviderVO } from "@libs/api/generated-api";
import { makeQuery } from "@libs/utils/queries";
import { cacheForSession, fiveMinuteCache, oneMinuteCache } from "@libs/utils/queryCache";

export const getPracticeProvidersQuery = makeQuery({
  queryKey: ["practices", "getAllProviders"],
  formatParams: (args: { practiceId: number; statuses?: ProviderVO["status"][] }) => [
    args.practiceId,
    { statuses: args.statuses },
  ],
  queryOptions: cacheForSession,
});

export const getPracticeDataQuery = makeQuery({
  queryKey: ["practices", "getPractice"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: cacheForSession,
});

export const getPracticeInfoById = makeQuery({
  queryKey: ["practices", "getPracticeInfoById"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: oneMinuteCache,
});

export const getPracticeProfileImage = makeQuery({
  queryKey: ["practices", "getProfileImage1"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: cacheForSession,
});

export const getPracticeCounters = makeQuery({
  queryKey: ["practices", "getPracticeCounters"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: cacheForSession,
});

export const getInactiveProviders = makeQuery({
  queryKey: ["practices", "getInactiveProviders"],
  formatParams: (args: { practiceId: number; providerIds: number[] }) => [args.practiceId, args.providerIds],
  queryOptions: cacheForSession,
});

export const getPracticeSecuritySettings = makeQuery({
  queryKey: ["practices", "getPracticeSecuritySettings"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: fiveMinuteCache,
});

export const getPermissionActions = makeQuery({
  queryKey: ["practices", "getPermissionActions"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
});

export const getPracticeRolesV2 = makeQuery({
  queryKey: ["practices", "getPracticeRolesV2"],
  formatParams: ({ practiceId }: { practiceId: number }) => [practiceId],
});

export const getRoleV2 = makeQuery({
  queryKey: ["practices", "getRoleV2"],
  formatParams: (args: { practiceId: number; roleId: number }) => [args.practiceId, args.roleId],
});

export const getPracticeConnectedRemoteSettings = makeQuery({
  queryKey: ["practices", "getPracticeConnectedRemoteSettings"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: oneMinuteCache,
});
