import { FC, useMemo } from "react";
import { FormSummaryVO } from "@libs/api/generated-api";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { flattenPages } from "@libs/utils/queries";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { Spinner } from "@libs/components/UI/Spinner";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus.svg";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { HeaderCell, TableGrid } from "@libs/components/UI/GridTableComponents";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { FormRow } from "components/Settings/Forms/FormRow";
import { ItemModal } from "hooks/useItemModal";

interface Props {
  title: string;
  tableHeaders: { id: string; label: string; width: string; sortable?: boolean }[];
  formsQuery: UseInfiniteApiQueryResult<FormSummaryVO[]>;
  onCreateForm: Func;
  onArchiveForm: (formUuid: string) => void;
  onDuplicateForm?: (formUuid: string) => void;
  confirmArchiveModal: ItemModal<FormSummaryVO>;
  isArchiving: boolean;
  isCreating: boolean;
}

export const FormsSettingsPanel: FC<Props> = ({
  title,
  formsQuery,
  tableHeaders,
  onCreateForm,
  onArchiveForm,
  onDuplicateForm,
  confirmArchiveModal,
  isArchiving,
  isCreating,
}) => {
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: formsQuery });

  const forms = useMemo(() => {
    return flattenPages(formsQuery.data);
  }, [formsQuery.data]);

  return (
    <SettingsPanel
      includePadding={false}
      title={
        <div className="flex items-center gap-x-1">
          <span>{title}</span>
          <div className="flex items-center min-h-5">
            {isCreating ? (
              <Spinner animation="border" variant="primary" size="sm" />
            ) : (
              <ButtonIcon
                SvgIcon={PlusIcon}
                tooltip={{ content: "New Form", theme: "SMALL" }}
                onClick={onCreateForm}
                theme="primary"
              />
            )}
          </div>
        </div>
      }
      titleBarClassName="min-h-14"
    >
      <PersistScrollPosition id="formsTable" className="h-full overflow-y-auto relative z-0" ref={rootRef}>
        <ScrollableInfiniteQueryResult
          infiniteQuery={formsQuery}
          loading={<LoadingContent className="flex rounded-none" />}
          scrollRef={scrollRef}
        >
          <TableGrid columnWidths={tableHeaders.map(({ width }) => width)}>
            {tableHeaders.map((item) => (
              <HeaderCell key={item.id} size="medium">
                {item.label}
              </HeaderCell>
            ))}

            {forms?.map((form) => (
              <FormRow
                key={form.uuid}
                form={form}
                onRequestArchive={confirmArchiveModal.open}
                onDuplicateForm={onDuplicateForm}
              />
            ))}
          </TableGrid>
        </ScrollableInfiniteQueryResult>
      </PersistScrollPosition>

      {confirmArchiveModal.isOpen ? (
        <ConfirmationModal
          primaryText={`${confirmArchiveModal.item.state === "DRAFT" ? "Delete" : "Archive"} Form`}
          secondaryText={
            confirmArchiveModal.item.state === "DRAFT"
              ? "Form in draft state will be permanently deleted."
              : "Any unublished changes will be discarded before being archived."
          }
          onCancel={confirmArchiveModal.close}
          onConfirm={() => onArchiveForm(confirmArchiveModal.item.uuid)}
          isConfirming={isArchiving}
          size="2xs"
        />
      ) : null}
    </SettingsPanel>
  );
};
