import { CollectedPaymentResponse } from "@libs/api/generated-api";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useAccount } from "@libs/contexts/AccountContext";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { getCollectedPaymentQuery } from "api/reporting/queries";
import { dashboardFiltersForTables } from "components/Dashboard/utils/filters";
import { usePaymentCollectedFilters } from "components/Dashboard/PaymentCollected/hooks/usePaymentCollectedFilters";
import { getTotals } from "components/Dashboard/Tables/utils";
import { useQueryParams } from "hooks/useQueryParams";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";

export const usePaymentCollectedQuery = ({
  enabled = true,
  pageSize,
}: {
  enabled?: boolean;
  pageSize: number;
}) => {
  const { query } = useQueryParams("dashboardPaymentCollected");
  const { practiceId } = useAccount();
  const { search, isWaiting } = useDebouncedSearch(query.patientSearch ?? "");
  const { selectedTimeSegmentParams } = useTimeSeriesPageSelections(query);

  const sortOrders = query.patientTableSort;
  const { paymentMethod: paymentMethodFilter } = usePaymentCollectedFilters();

  const collectedPaymentQuery = useInfiniteApiQuery(
    getCollectedPaymentQuery({
      args: {
        practiceId,
        pageSize,
        pageNumber: 1,
        data: {
          includeTotal: true,
          ...selectedTimeSegmentParams,
          patientSearchString: search,
          filters: dashboardFiltersForTables(query.filters),
          sortOrders,
        },
      },
      queryOptions: {
        enabled,
      },
    })
  );

  return {
    collectedPaymentQuery,
    isLoading: isWaiting || collectedPaymentQuery.isLoading,
    totals: getTotals<CollectedPaymentResponse>(collectedPaymentQuery.data),
    baseRowKey: `by_patient_${selectedTimeSegmentParams.startDate}-${
      selectedTimeSegmentParams.endDate
    }-${paymentMethodFilter.join(",")}`,
  };
};
