import React from "react";
import { CollectedPaymentResponse } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { formatCurrency } from "@libs/utils/currency";
import { useBoolean } from "@libs/hooks/useBoolean";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { saveCsv, formatCsvRow, EMPTY_ROW, getArchyCsvMetadata } from "utils/csv";
import { handleError } from "utils/handleError";

import { getPaymentTypeSections } from "components/Dashboard/PaymentCollected/hooks/usePaymentTypeSections";
import { TimeSegment } from "utils/routing/dashboard";
import { useCurrentUrl } from "contexts/OriginContext";

const formatToCsv = (data: UseInfiniteApiQueryResult<CollectedPaymentResponse>["data"]) => {
  const { sectionMap, keys, totals } = getPaymentTypeSections(data);
  const headers = formatCsvRow([
    "Payment Method",
    "Patient",
    "Date of Collection",
    "Payer Type",
    "Total Collected",
  ]);

  const rows = keys.flatMap((key) => {
    const values = sectionMap[key];
    const firstItem = values?.[0];

    if (!firstItem) {
      return [];
    }

    return values.map((entry) => {
      return formatCsvRow([
        entry.paymentMethod,
        entry.patient.shortDisplayName,
        formatISODate(entry.paymentDate),
        entry.payerType,
        formatCurrency(entry.paymentAmount),
      ]);
    });
  });

  const totalsContent = formatCsvRow(["", "", "", "Total:", formatCurrency(totals?.totalPaymentAmount ?? 0)]);

  return [headers, ...rows, EMPTY_ROW, totalsContent];
};

export const useDownloadPaymentCollectedByPaymentTypeCSV = ({
  startDownload,
  selectedTimeSegment,
}: {
  startDownload: () => Promise<UseInfiniteApiQueryResult<CollectedPaymentResponse>["data"]>;
  selectedTimeSegment: TimeSegment;
}) => {
  const downloading = useBoolean(false);
  const currentUrl = useCurrentUrl();

  return {
    isDownloading: downloading.isOn,
    downloadCSV: React.useCallback(async () => {
      downloading.on();

      try {
        const result = await startDownload();

        const metadata = getArchyCsvMetadata(currentUrl, selectedTimeSegment);
        const csvRows = formatToCsv(result);

        saveCsv([...metadata.headers, ...csvRows], `PaymentByPaymentMethod_${metadata.fileNameTag}`);
      } catch (e) {
        handleError(e);
      }

      downloading.off();
    }, [downloading, startDownload, selectedTimeSegment, currentUrl]),
  };
};
