import { FC, FormEvent, PropsWithChildren, useId, useMemo, useRef, useState } from "react";
import { flushSync } from "react-dom";
import {
  AutomationActionRequest,
  AutomationJourneyVO,
  DentalProcedureVO,
  EmailConfigVO,
  PracticeInfoVO,
  TemplateVariableGroupVO,
} from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { Form } from "@libs/components/UI/Form";
import { ModalPage } from "components/UI/ModalPage";
import { isEmailConfigured } from "components/Communications/utils";
import {
  filterTemplateVariables,
  getAutomationActionSchema,
  getChannelsStatus,
} from "components/Communications/Automations/utils";
import { scrollToFirstError } from "utils/scrollToFirstError";
import { AutomationActionAppointmentForm } from "components/Communications/Automations/AutomationActionAppointmentForm";
import { AutomationActionRecallForm } from "components/Communications/Automations/AutomationActionRecallForm";
import { AutomationActionPostAppointmentForm } from "components/Communications/Automations/AutomationActionPostAppointmentForm";
import { ActionMessagePanel } from "components/Communications/Automations/ActionMessagePanel";
import { DraftAutomationAction, MessageView } from "components/Communications/Automations/types";
import { AutomationActionFormsForm } from "components/Communications/Automations/AutomationActionFormsForm";
import { SendEmailTestModal } from "components/Communications/Automations/SendEmailTestModal";
import { SendSmsTestModal } from "components/Communications/Automations/SendSmsTestModal";
import { EmailSettingsBanner } from "components/Communications/EmailSettingsBanner";

export interface ActionModalPageProps {
  from: string;
  isSaving: boolean;
  title: string;
  onUpdate: (updates: Partial<DraftAutomationAction>) => void;
  onSave: (request: AutomationActionRequest) => void;
  journeyType: AutomationJourneyVO["type"];
  emailConfig: EmailConfigVO;
  draftAction: DraftAutomationAction;
  templateVariablesQuery: ApiQueryResult<TemplateVariableGroupVO[]>;
  dentalProcedures: DentalProcedureVO[] | undefined;
  practice: PracticeInfoVO;
}

// eslint-disable-next-line complexity
export const ActionModalPage: FC<PropsWithChildren<ActionModalPageProps>> = ({
  journeyType,
  emailConfig,
  dentalProcedures,
  draftAction,
  templateVariablesQuery,
  onUpdate,
  from,
  title,
  isSaving,
  practice,
  onSave,
}) => {
  const sendTestModal = useBoolean(false);
  const formId = useId();
  const [messageView, setMessageView] = useState<MessageView>(() =>
    isEmailConfigured(emailConfig) &&
    draftAction.channels.length &&
    !getChannelsStatus(draftAction.channels).isSendingSms
      ? "EMAIL"
      : "SMS"
  );
  const formRef = useRef<HTMLFormElement>(null);

  const templateVariables = useMemo(
    () => filterTemplateVariables(journeyType, templateVariablesQuery),
    [journeyType, templateVariablesQuery]
  );

  const templateVariablesSets = useMemo(() => {
    return {
      content: new Set(
        templateVariables.content.data.flatMap((group) => group.variables.map((variable) => variable.key))
      ),
      subject: new Set(
        templateVariables.subject.data.flatMap((group) => group.variables.map((variable) => variable.key))
      ),
    };
  }, [templateVariables.content, templateVariables.subject]);
  const schema = useMemo(
    () =>
      getAutomationActionSchema(
        draftAction.event,
        draftAction.eventTime,
        draftAction.channels,
        Boolean(draftAction.customEmailTemplate),
        templateVariablesSets
      ),
    [
      draftAction.event,
      draftAction.eventTime,
      draftAction.channels,
      draftAction.customEmailTemplate,
      templateVariablesSets,
    ]
  );
  const validation = useValidation(draftAction, schema);
  // eslint-disable-next-line complexity
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const result = flushSync(() => validation.validate());

    if (result.$isValid) {
      const { dentalProcedures: dps, ...rest } = draftAction;

      onSave({
        ...rest,
        interval: draftAction.eventTime === "WHEN" ? undefined : draftAction.interval,
        intervalType: draftAction.eventTime === "WHEN" ? undefined : draftAction.intervalType,
        sendTime: draftAction.eventTime === "BEFORE" ? draftAction.sendTime : undefined,
        ...(dps && dps.contains && dps.procedures.length
          ? { dentalProcedures: { ids: dps.procedures.map(({ id }) => id), contains: dps.contains } }
          : undefined),
      });
    } else {
      scrollToFirstError(formRef.current);

      if (result.smsTemplate.$error && !result.emailTemplate.$error && messageView === "EMAIL") {
        setMessageView("SMS");
      } else if (
        !result.smsTemplate.$error &&
        (result.emailTemplate.$error || result.subject.$error) &&
        messageView === "SMS"
      ) {
        setMessageView("EMAIL");
      }
    }
  };

  const commonFormProps = {
    emailConfig,
    action: draftAction,
    onUpdate: (updates: Partial<DraftAutomationAction>) => {
      if (updates.channels) {
        const { isSendingEmails, isSendingSms } = getChannelsStatus(updates.channels);

        if (!isSendingSms && messageView === "SMS") {
          setMessageView("EMAIL");
        } else if (!isSendingEmails && messageView === "EMAIL") {
          setMessageView("SMS");
        }
      }

      onUpdate(updates);
    },
    validation: validation.result,
    onSendTestModal: sendTestModal.on,
  };

  return (
    <ModalPage
      closeLink={from}
      title={<span className="font-sansSemiBold">{title}</span>}
      actions={
        <div className="flex items-center justify-center gap-x-3">
          <ButtonInternalLink className="min-w-button" replace to={from} theme="secondary">
            Close
          </ButtonInternalLink>
          <AsyncButton className="min-w-button" isLoading={isSaving} type="submit" form={formId}>
            Save
          </AsyncButton>
        </div>
      }
    >
      <Form ref={formRef} id={formId} className="h-full" onSubmit={handleSubmit}>
        <div className="h-full flex items-stretch">
          <div className="flex-1 flex flex-col items-stretch p-6">
            <div className="flex-none">
              <EmailSettingsBanner emailConfig={emailConfig} />
            </div>
            <div
              className={`
                flex-1
                flex
                items-center
                justify-center
                min-h-0
                overflow-y-auto
              `}
            >
              <div className="max-w-xl w-full flex flex-col gap-y-6">
                {journeyType === "APPOINTMENT" ? (
                  <AutomationActionAppointmentForm {...commonFormProps} />
                ) : journeyType === "POST_APPOINTMENT" ? (
                  dentalProcedures ? (
                    <AutomationActionPostAppointmentForm
                      {...commonFormProps}
                      dentalProcedures={dentalProcedures}
                    />
                  ) : null
                ) : journeyType === "RECALL" ? (
                  <AutomationActionRecallForm {...commonFormProps} />
                ) : (
                  <AutomationActionFormsForm {...commonFormProps} />
                )}
              </div>
            </div>
          </div>
          <div className="flex-1 bg-slate-50 border-l border-l-slate-200 p-6">
            <div className="h-full relative flex flex-col gap-y-3">
              <ActionMessagePanel
                {...commonFormProps}
                messageView={messageView}
                onMessageViewChange={setMessageView}
                subjectTemplateVariablesQuery={templateVariables.subject}
                contentTemplateVariablesQuery={templateVariables.content}
                practice={practice}
              />
            </div>
          </div>
        </div>
      </Form>
      {sendTestModal.isOn ? (
        messageView === "EMAIL" ? (
          <SendEmailTestModal
            emailTemplate={draftAction.emailTemplate ?? ""}
            subject={draftAction.subject ?? ""}
            journeyType={journeyType}
            onRequestClose={sendTestModal.off}
          />
        ) : (
          <SendSmsTestModal
            smsTemplate={draftAction.smsTemplate ?? ""}
            journeyType={journeyType}
            onRequestClose={sendTestModal.off}
          />
        )
      ) : null}
    </ModalPage>
  );
};
