import { FC, useMemo, useRef } from "react";
import { TablePager } from "components/UI/TablePager";

export const TablePagerPageDetails: FC<{
  page: number;
  pageSize: number;
  pageDetails?: { totalElements?: number; totalPages?: number };
  onPageChange: (newPage: number) => void;
  onPageSizeChange: (newSize: number) => void;
}> = ({ page, pageSize, pageDetails, onPageChange, onPageSizeChange }) => {
  const pagingDetailsRef = useRef<{ totalPages: number; totalElements: number } | null>(null);

  // When updating page requests we dont want the table pager disapper and reaappear
  // or have the contents jump around.
  const pagingDetails = useMemo(() => {
    if (pageDetails && pageDetails.totalElements !== undefined && pageDetails.totalPages !== undefined) {
      pagingDetailsRef.current = {
        totalElements: pageDetails.totalElements,
        totalPages: pageDetails.totalPages,
      };
    }

    return pagingDetailsRef.current;
  }, [pageDetails]);

  return pagingDetails && pagingDetails.totalElements > 0 ? (
    <div className="flex-0 -mt-px py-2 px-4 border-t border-t-greyLighter">
      <TablePager
        pageSize={pageSize}
        totalPages={pagingDetails.totalPages}
        totalElements={pagingDetails.totalElements}
        page={page}
        onPageChange={onPageChange}
        onPageSizeChange={onPageSizeChange}
      />
    </div>
  ) : null;
};
