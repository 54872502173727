import { isSameDay, startOfToday } from "date-fns";
import DatePicker from "react-datepicker";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { TWO_MONTHS, formatShortDayOfMonth } from "@libs/utils/date";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as CalendarIcon } from "@libs/assets/icons/calendar.svg";
import { ReactComponent as RightCaret } from "@libs/assets/icons/right-caret.svg";
import { ReactComponent as LeftCaret } from "@libs/assets/icons/left-caret.svg";
import { useMonthNavigation } from "components/ScheduleAppointments/useMonthNavigation";

interface Props {
  dateRange: [Date | null, Date | null] | undefined;
  onChange: (dateRange: [Date | null, Date | null]) => void;
}

// eslint-disable-next-line complexity
export const DailyHuddleDatePicker: React.FC<Props> = ({ dateRange, onChange, ...props }) => {
  const calendarMenu = useBoolean(false);
  const calendarHover = useBoolean(false);

  const selected = new Date();

  const { increaseMonths, decreaseMonths, handleOpenDatepicker, openToDate, openKey } = useMonthNavigation(
    selected,
    TWO_MONTHS
  );

  const handleInputClick = () => {
    handleOpenDatepicker(selected);
    calendarMenu.on();
  };

  const startDate = dateRange?.[0];
  const endDate = dateRange?.[1];

  const startDateString = startDate ? formatShortDayOfMonth(startDate) : "";
  const endDateString = endDate ? formatShortDayOfMonth(endDate) : "";

  return (
    <div className="relative z-50">
      <DatePicker
        {...props}
        calendarStartDay={0}
        customInput={
          <div
            className={cx(
              `flex
               items-center
               justify-center
               p-2.5
               border
               border-greyLighter
               hover:border-greyLight
               rounded`,
              startDate && "bg-accentLight border border-primaryTheme"
            )}
          >
            {startDate && (
              <div className="text-xs mr-10">{`${startDateString}${
                endDateString && startDateString !== endDateString ? ` - ${endDateString}` : ""
              }`}</div>
            )}
            <Icon
              SvgIcon={CalendarIcon}
              onMouseEnter={calendarHover.on}
              onMouseLeave={calendarHover.off}
              theme={calendarHover.isOn || startDate ? "primary" : "slate700"}
            />
          </div>
        }
        dayClassName={(data) => {
          const today = startOfToday();

          return cx(isSameDay(today, data) && "today", today > data && "past");
        }}
        endDate={endDate}
        key={openKey}
        monthsShown={TWO_MONTHS}
        onClickOutside={calendarMenu.off}
        onChange={(range) => {
          if (range[0] && range[1]) {
            calendarMenu.off();
          }

          onChange(range);
        }}
        onInputClick={handleInputClick}
        open={calendarMenu.isOn}
        openToDate={openToDate}
        calendarClassName="react-datepicker-archy-range react-datepicker-rangepicker-v1"
        popperClassName={`
          react-datepicker-archy-popper
          react-datepicker-archy-calendar
          react-datepicker-archy-huddle-calendar-popper
        `}
        popperPlacement="bottom"
        selectsRange
        selected={startDate}
        showPopperArrow={false}
        startDate={startDate}
        wrapperClassName="react-datepicker-archy-datepicker-wrapper"
      >
        <div
          className={`
            flex
            items-center
            justify-between
            absolute
            w-full
            top-8
            left-0
            px-8
          `}
        >
          <ButtonIcon onClick={decreaseMonths} theme="primary" SvgIcon={LeftCaret} />
          <ButtonIcon onClick={increaseMonths} theme="primary" SvgIcon={RightCaret} />
        </div>
      </DatePicker>
    </div>
  );
};
