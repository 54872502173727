import Skeleton from "react-loading-skeleton";
import { getAgeByDob } from "@libs/utils/formatString";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getPatientSummary } from "api/patients/queries";
import { PatientTooltip } from "components/Patient/PatientTooltip";
import { useNow } from "hooks/useNow";

interface Props {
  patientId: number;
}

export const PatientTooltipContent: React.FC<Props> = ({ patientId }) => {
  const now = useNow();
  const { practiceId } = useAccount();
  const [patientQuery] = useApiQueries([
    getPatientSummary({
      args: { patientId, practiceId },
    }),
  ]);

  return (
    <QueryResult queries={[patientQuery]} loading={<Skeleton className="w-56 h-20" />}>
      {patientQuery.data ? (
        <PatientTooltip
          contact={patientQuery.data.contact}
          name={patientQuery.data.name}
          age={getAgeByDob(now, patientQuery.data.dob)}
          gender={patientQuery.data.gender}
        />
      ) : null}
    </QueryResult>
  );
};
