import { cx } from "@libs/utils/cx";
import { cxGridTableStyles } from "@libs/components/UI/GridTableComponents";
import { cxTableStyles } from "components/Dashboard/Tables/cxTableStyles";

export type CellProps = {
  children?: React.ReactNode;
  className?: string;
  last?: boolean;
  align?: "center" | "left" | "right";
  hoverEffectsEnabled?: boolean;
};
export const Cell: React.FC<CellProps> = ({
  children,
  className,
  last = false,
  align = "left",
  hoverEffectsEnabled = true,
}) => {
  return (
    <div
      style={{ pageBreakAfter: "auto", pageBreakBefore: "auto", pageBreakInside: "avoid" }}
      className={cx(
        "flex",
        cxTableStyles.cell(last, align),
        hoverEffectsEnabled && cxGridTableStyles.dataCell,
        className
      )}
    >
      {children}
    </div>
  );
};
