/* eslint-disable complexity */
import React from "react";
import Skeleton from "react-loading-skeleton";
import { DentalProcedureVO, UnscheduledProcedureResponse } from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { UseInfiniteApiQueryResult, ApiQueryResult } from "@libs/@types/apiQueries";
import { usePick } from "@libs/hooks/usePick";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { useFlattenEntries } from "components/Dashboard/hooks/useFlattenEntries";
import { useFilterTokenProps } from "components/Dashboard/hooks/useFilterTokenProps";
import { DashboardTableFilters } from "components/Dashboard/Tables/DashboardTableFilters";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import { UnscheduledTreatmentQuery } from "utils/routing/dashboard";
import { SelectHasNextApptFilter } from "components/Dashboard/Tables/SelectHasNextApptFilter";
import { UnscheduledTreatmentByPatientRow } from "components/Dashboard/UnscheduledTreatment/UnscheduledTreatmentByPatientRow";
import { UNSCHEDULED_TREATMENT_HEADERS } from "components/Dashboard/UnscheduledTreatment/constants";
import { PatientStatusFilter } from "components/Dashboard/Tables/PatientStatusFilter";
import { DashboardTableHeader } from "components/Dashboard/Tables/DashboardTableHeader";
import { useDownloadUnscheduledTreatmentCsv } from "components/Dashboard/UnscheduledTreatment/hooks/useDownloadUnscheduledTreatmentCsv";
import { FooterCell } from "components/Dashboard/Tables/FooterCell";
import { getTotals } from "components/Dashboard/Tables/utils";

import { TableSelectProcedureFilters } from "components/Dashboard/Tables/TableSelectProcedureFilters";
import { DashboardFilterFormFieldSelect } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";

type Props = {
  unscheduledTreatmentQuery: UseInfiniteApiQueryResult<UnscheduledProcedureResponse>;
  startDate: string;
  endDate?: string;
  onUpdateParams: (updates: Partial<UnscheduledTreatmentQuery>) => void;
  query: UnscheduledTreatmentQuery;
  allDentalProceduresQuery: ApiQueryResult<DentalProcedureVO[]>;
};

const MASTER_TREATMENT_PLAN_OPTIONS = [
  { value: "true", label: "Is Master Plan" },
  { value: "false", label: "Not Master Plan" },
];
const IS_HYGIENE_OPTIONS = [
  { value: "true", label: "Include" },
  { value: "false", label: "Exclude" },
];
const TREATMENT_PLAN_STATE_OPTIONS = ["ACTIVE", "INACTIVE"].map((value) => ({
  value,
  label: sentenceCaseConstant(value),
}));

export const UnscheduledTreatmentByPatientTable: React.FC<Props> = ({
  unscheduledTreatmentQuery,
  allDentalProceduresQuery,
  onUpdateParams,
  query,
  startDate,
  endDate,
}) => {
  const { tableSort, filters } = query;
  const { data: paginatedData, isLoading } = unscheduledTreatmentQuery;
  const totals = getTotals(paginatedData);
  const handleSortClick = React.useCallback(
    (newSortOrders: ColumnSortOrder[]) => {
      onUpdateParams({
        tableSort: newSortOrders,
      });
    },
    [onUpdateParams]
  );
  const filterParams = usePick(query, ["filters", "patientSearch"]);

  const filterProps = useFilterTokenProps({
    options: filterParams,
    procedures: allDentalProceduresQuery.data,
  });
  const totalRows = getInfiniteQueryPagingDetails(paginatedData)?.totalElements;
  const rows = useFlattenEntries(paginatedData);

  const isEmpty = !isLoading && rows.length === 0;
  const { isDownloading, downloadCSV } = useDownloadUnscheduledTreatmentCsv({
    unscheduledTreatmentQuery,
    startDate,
    endDate,
  });

  return (
    <div className="h-full w-full flex flex-col min-h-0">
      <DashboardTableHeader
        query={query}
        title="Show by Patient"
        onUpdateParams={onUpdateParams}
        isDownloading={isDownloading}
        onClickDownload={downloadCSV}
        id="unscheduled-treatment-by-patient-filters"
      >
        <PatientStatusFilter query={query} onUpdateParams={onUpdateParams} />
        <SelectHasNextApptFilter query={query} onUpdateParams={onUpdateParams} />
        <DashboardFilterFormFieldSelect
          placeholder="Hygiene"
          aria-label="Hygiene"
          mergeWithExisting={false}
          isSearchable={false}
          dashboardFilters={filters}
          dashboardFilterType="isHygiene"
          options={IS_HYGIENE_OPTIONS}
          className="min-w-button"
          onChangeFilters={onUpdateParams}
        />
        <DashboardFilterFormFieldSelect
          placeholder="Master Plan"
          aria-label="Master Plan"
          isSearchable={false}
          dashboardFilters={filters}
          dashboardFilterType="isMasterTreatmentPlan"
          options={MASTER_TREATMENT_PLAN_OPTIONS}
          className="min-w-button"
          onChangeFilters={onUpdateParams}
        />
        <DashboardFilterFormFieldSelect
          placeholder="Plan Status"
          aria-label="Plan Status"
          isSearchable={false}
          dashboardFilters={filters}
          dashboardFilterType="treatmentPlanState"
          options={TREATMENT_PLAN_STATE_OPTIONS}
          className="min-w-button"
          onChangeFilters={onUpdateParams}
        />
        <TableSelectProcedureFilters
          procedures={allDentalProceduresQuery.data}
          // Values are populated elsewhere in PracticeProductionFilterTokens
          filters={filters}
          onFiltersChanged={(updatedFilters) => {
            onUpdateParams({
              filters: updatedFilters,
            });
          }}
        />
      </DashboardTableHeader>
      <DashboardTableFilters {...filterParams}>
        <QueryFilterPills
          {...filterProps}
          numResults={totalRows}
          onUpdateParams={onUpdateParams}
          onClearAll={() => {
            onUpdateParams({ filters: [], patientSearch: undefined });
          }}
        />
      </DashboardTableFilters>
      <DashboardInfiniteTable
        columnWidths={UNSCHEDULED_TREATMENT_HEADERS.map(({ width }) => width)}
        id="endOfDayReportTable"
        isEmpty={isEmpty}
        infiniteQuery={unscheduledTreatmentQuery}
        headerRow={
          <DashboardHeaderRow
            headers={UNSCHEDULED_TREATMENT_HEADERS}
            onSortClick={handleSortClick}
            sortOrders={tableSort}
          />
        }
        footerRow={
          <FooterCell className="flex justify-end gap-4 col-span-full">
            {totals ? (
              <>
                Total Patients {totals.totalDistinctUnscheduledPatients} <VerticalDivider /> Total Procedures{" "}
                {totals.totalDistinctUnscheduledProcedures}
              </>
            ) : (
              <Skeleton className="w-60" />
            )}
          </FooterCell>
        }
      >
        {rows.map((row, i) => {
          const key = `${row.patient.id}-${row.dentalProcedure.id}-${row.provider.id}-${i}-${
            row.nextAppointmentId ?? "-1"
          }-${row.previousAppointmentId ?? "-1"}}`;

          return <UnscheduledTreatmentByPatientRow key={key} row={row} last={i === rows.length - 1} />;
        })}
      </DashboardInfiniteTable>
    </div>
  );
};
