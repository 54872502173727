import { Link, useNavigate } from "react-router-dom";
import { InsurancePlanSummaryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { getFullUrl } from "@libs/utils/location";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as ExternalLinkIcon } from "@libs/assets/icons/external-link.svg";
import { Pill } from "@libs/components/UI/Pill";
import { Checkbox } from "@libs/components/UI/Checkbox";
import {
  ButtonCell,
  Cell,
  EMPTY_CELL,
  Row,
  cxGridTableStyles,
} from "@libs/components/UI/GridTableComponents";
import { paths } from "utils/routing/paths";
import { FeeScheduleCellPill } from "components/Settings/InsuranceCarriers/Row";

interface Props {
  onCopy: (planUuid: string) => void;
  onEdit: (planUuid: string) => void;
  onSelectToggle: Func;
  insurancePlan: InsurancePlanSummaryVO;
  isSelected: boolean;
}

export const InsurancePlanTableRow: React.FC<Props> = ({ insurancePlan, isSelected, onSelectToggle }) => {
  const navigate = useNavigate();

  const cellProps = {
    className: "flex items-center min-h-10",
    verticalPadding: "slim" as const,
  };

  return (
    <Row key={insurancePlan.uuid}>
      <Cell {...cellProps} className={cx("pl-5 pr-3", cellProps.className, cxGridTableStyles.dataCell)}>
        <Checkbox checked={isSelected} onChange={onSelectToggle} />
      </Cell>
      <ButtonCell {...cellProps}>{insurancePlan.groupNumber ?? EMPTY_CELL}</ButtonCell>
      <ButtonCell {...cellProps} className={cx("gap-x-2", cellProps.className)}>
        {insurancePlan.groupName ?? EMPTY_CELL}
      </ButtonCell>
      <ButtonCell {...cellProps} className={cx("gap-x-2", cellProps.className)}>
        {insurancePlan.description ?? EMPTY_CELL}
      </ButtonCell>
      <ButtonCell {...cellProps}>{insurancePlan.employerName ?? EMPTY_CELL}</ButtonCell>
      <ButtonCell {...cellProps}>
        <Link
          to={paths.insuranceDetails({ carrierId: insurancePlan.carrier.id }, { from: getFullUrl(location) })}
        >
          <Pill>
            <FloatingTooltip content={insurancePlan.carrier.name} theme="SMALL">
              <div className="truncate max-w-xs">{insurancePlan.carrier.name}</div>
            </FloatingTooltip>
          </Pill>
        </Link>
      </ButtonCell>
      {insurancePlan.patientCount > 0 ? (
        <Cell {...cellProps} className={cx("px-3 gap-x-2", cellProps.className, cxGridTableStyles.dataCell)}>
          <div className="flex items-center gap-x-2">
            <span>{insurancePlan.patientCount}</span>
            <ButtonIcon
              SvgIcon={ExternalLinkIcon}
              onClick={(e) => {
                e.preventDefault();
                // eslint-disable-next-line @typescript-eslint/naming-convention
                navigate(paths.patients({ "patientCriteria.insurancePlanUuid": insurancePlan.uuid }));
              }}
              theme="slate600"
            />
          </div>
        </Cell>
      ) : (
        <ButtonCell {...cellProps}>{insurancePlan.patientCount}</ButtonCell>
      )}

      <ButtonCell {...cellProps}>
        {insurancePlan.feeSchedule ? (
          <FeeScheduleCellPill
            feeScheduleId={insurancePlan.feeSchedule.id}
            feeScheduleName={insurancePlan.feeSchedule.name}
          />
        ) : (
          EMPTY_CELL
        )}
      </ButtonCell>
    </Row>
  );
};
