import { FC } from "react";
import { ReactComponent as Checkmark } from "@libs/assets/icons/check-circle-1.svg";

const cxStyles = {
  labelContainer: "flex items-center gap-x-2",
  checkmark: "w-5 h-5 text-green",
  label: "font-sans text-xs text-greyDark",
};

export const CheckmarkLabel: FC<{ label: string }> = ({ label }) => (
  <div className={cxStyles.labelContainer}>
    <Checkmark className={cxStyles.checkmark} />
    <span className={cxStyles.label}>{label}</span>
  </div>
);
