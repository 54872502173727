import { cx } from "@libs/utils/cx";
import { formatISODate } from "@libs/utils/date";
import { PerioChartExamRecord } from "./perioChartExamRecord";
import { usePerioChart } from "./PerioChartContext";

const cxStyles = {
  divider: "w-px h-5 ml-4 mr-4 bg-greyLight",
};

export const PerioChartPrintHeader: React.FC<{
  exam: PerioChartExamRecord;
  isPrinting: boolean;
}> = ({ exam, isPrinting }) => {
  const examInfo = exam.getExamInfo();
  const { patient } = usePerioChart();

  return (
    <div
      className={cx(
        `print:flex
         flex-row
         w-full
         items-center
         justify-center
         text-lg
         font-sansSemiBold
         mb-4`,
        isPrinting ? "flex" : "hidden"
      )}
    >
      {patient && (
        <>
          <div>Patient Name: {patient.name.fullDisplayName}</div>
          <div className={cxStyles.divider} />
          <div>DOB: {formatISODate(patient.dob)}</div>
          <div className={cxStyles.divider} />
        </>
      )}
      Perio Chart Date: {formatISODate(examInfo.date)}
    </div>
  );
};
