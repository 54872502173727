import { Link } from "react-router-dom";
import { pluralize } from "@libs/utils/pluralize";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { Row } from "@libs/components/UI/GridTableComponents";
import { Section } from "components/Insurance/Section";
import { planCxStyles } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/styles";
import { UsePlanFieldsResult } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/usePlanFields";
import {
  InsuranceFields,
  SubscriberFields,
  UseInsuranceFields,
  UseSubscriberFields,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/useInsuranceFields";
import { InsuranceValidationResult } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/DedMaxAndCoverageTab/formData";
import { Divider } from "components/UI/Divider";
import {
  BreakdownTable,
  InsuranceFieldCell,
  OrthoAgeLimit,
  PlanFieldCell,
  SubscriberFieldCell,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/BreakdownTable";
import {
  Cell,
  TableHeaders,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/TableItems";
import { CoverageTable } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/DedMaxAndCoverageTab/CoverageTable";
import { BenefitCoverageFields } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/useBenefitCoverageFields";
import { TabBanner } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/TabBanner";
import { paths } from "utils/routing/paths";

interface Props {
  coverageFields: BenefitCoverageFields[];
  coveragesQuery: ApiQueryResult<BenefitCoverageFields[]>;
  editing: boolean;
  insuranceFields: InsuranceFields;
  insuranceOrPlanIsAutoVerified: boolean;
  insurancePlanUuid: string | undefined;
  numOfPatients: number | undefined;
  onClickEdit: Func;
  onCoverageFieldChange: (updatedCoverage: BenefitCoverageFields[]) => void;
  onInsuranceFieldChange: UseInsuranceFields["handleInsuranceFieldsChange"];
  onPlanFieldChange: UsePlanFieldsResult["handlePlanFieldChange"];
  onSubmitInsuranceChanges: () => Promise<void>;
  onSubscriberFieldChange: UseSubscriberFields["handleSubscriberFieldsChange"];
  planFields: UsePlanFieldsResult["planFields"];
  subscriberFields: SubscriberFields;
  validation: InsuranceValidationResult;
}

const HEADERS = {
  maxOrDed: [
    { text: "" },
    { text: "Individual Lifetime" },
    { text: "Individual Annual" },
    { text: "Family Annual" },
  ],
  ortho: [{ text: "" }, { text: "Max" }, { text: "Max Remaining" }, { text: "Age Limit" }],
};

export const DeductiblesMaxAndCoverageTables: React.FC<Props> = ({
  coverageFields,
  coveragesQuery,
  editing,
  insuranceFields,
  insuranceOrPlanIsAutoVerified,
  insurancePlanUuid,
  numOfPatients,
  onClickEdit,
  onCoverageFieldChange,
  onInsuranceFieldChange,
  onPlanFieldChange,
  onSubmitInsuranceChanges,
  onSubscriberFieldChange,
  planFields,
  subscriberFields,
  validation,
}) => {
  const sharedProps = {
    borderBottom: true,
    edit: editing,
  };

  const planFieldProps = {
    ...sharedProps,
    onChange: onPlanFieldChange,
    planFields,
  };

  const insuranceFieldProps = {
    ...sharedProps,
    edit: true,
    insuranceFields,
    onChange: onInsuranceFieldChange,
    onSubmit: editing ? undefined : onSubmitInsuranceChanges,
    validation,
  };

  const subscriberFieldProps = {
    ...sharedProps,
    edit: true,
    onChange: (updatedSubscriber: UseSubscriberFields["subscriberFields"]) => {
      onSubscriberFieldChange(updatedSubscriber);
    },
    onSubmit: editing ? undefined : onSubmitInsuranceChanges,
    subscriberFields,
    validation,
  };

  return (
    <div className="flex flex-col mb-6">
      <TabBanner
        description={
          <div>
            Updating Deductibles, Maximums and Coverage will affect all&nbsp;
            <Link
              className="text-primaryTheme"
              // eslint-disable-next-line @typescript-eslint/naming-convention
              to={paths.patients({ "patientCriteria.insurancePlanUuid": insurancePlanUuid })}
            >
              {`${numOfPatients ?? 0} ${pluralize(numOfPatients ?? 0, "patient", "patients")}`}
            </Link>
            &nbsp;who have this insurance plan.
          </div>
        }
        editing={editing}
        onClickEdit={onClickEdit}
      />
      <div className="flex-1 flex flex-col gap-y-6 max-w-7xl m-6 overflow-y-auto">
        <div className="grid grid-cols-2">
          <Section
            className={planCxStyles.section}
            includePadding={false}
            title="Maximums"
            useCustomStyling={true}
          >
            <BreakdownTable>
              <TableHeaders headers={HEADERS.maxOrDed} />
              <Row>
                <Cell className="font-sansSemiBold">Total Benefits</Cell>
                <PlanFieldCell field="individualLifetimeMaximum" {...planFieldProps} />
                <PlanFieldCell field="individualAnnualMaximum" {...planFieldProps} />
                <PlanFieldCell field="familyAnnualMaximum" {...planFieldProps} />
              </Row>
              <Row>
                <Cell className="font-sansSemiBold">Remaining</Cell>
                <InsuranceFieldCell field="lifetimeMaximumRemaining" {...insuranceFieldProps} />
                <InsuranceFieldCell field="annualMaximumRemaining" {...insuranceFieldProps} />
                <SubscriberFieldCell field="familyAnnualMaximumRemaining" {...subscriberFieldProps} />
              </Row>
            </BreakdownTable>
          </Section>
          <Section
            className={planCxStyles.section}
            includePadding={false}
            title="Deductibles"
            useCustomStyling={true}
          >
            <BreakdownTable>
              <TableHeaders headers={HEADERS.maxOrDed} />
              <Row>
                <Cell className="font-sansSemiBold">Total Benefits</Cell>
                <PlanFieldCell field="individualLifetimeDeductible" {...planFieldProps} />
                <PlanFieldCell field="individualAnnualDeductible" {...planFieldProps} />
                <PlanFieldCell field="familyAnnualDeductible" {...planFieldProps} />
              </Row>
              <Row>
                <Cell>Remaining</Cell>
                <InsuranceFieldCell field="lifetimeDeductibleRemaining" {...insuranceFieldProps} />
                <InsuranceFieldCell field="annualDeductibleRemaining" {...insuranceFieldProps} />
                <SubscriberFieldCell field="familyAnnualDeductibleRemaining" {...subscriberFieldProps} />
              </Row>
            </BreakdownTable>
          </Section>
        </div>
        <Divider className="border-dashed" />
        <Section
          className={planCxStyles.section}
          includePadding={false}
          title="Ortho"
          useCustomStyling={true}
        >
          <BreakdownTable>
            <TableHeaders headers={HEADERS.ortho} />
            <Row>
              <Cell>Benefits</Cell>
              <PlanFieldCell field="orthoLifetimeMaximum" {...planFieldProps} />
              <InsuranceFieldCell field="orthoMaximumRemaining" {...insuranceFieldProps} />
              <Cell>
                <OrthoAgeLimit
                  editable={editing}
                  onChange={(value) => onPlanFieldChange({ orthoMaxAge: value })}
                  planFields={planFields}
                />
              </Cell>
            </Row>
          </BreakdownTable>
        </Section>
        <Divider className="border-dashed" />
        <Section
          className={planCxStyles.section}
          includePadding={false}
          title="Coverage"
          useCustomStyling={true}
        >
          <CoverageTable
            coverageFields={coverageFields}
            editing={editing}
            errorLoading={Boolean(coveragesQuery.error)}
            insuranceOrPlanIsAutoVerified={insuranceOrPlanIsAutoVerified}
            loading={coveragesQuery.isLoading}
            onChange={onCoverageFieldChange}
            onDelete={(index) => {
              onCoverageFieldChange(coverageFields.filter((_, i) => i !== index));
            }}
            planType={planFields.planType}
          />
        </Section>
      </div>
    </div>
  );
};
