/* eslint-disable @typescript-eslint/naming-convention */
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { useCallback, useMemo } from "react";
import { CalendlyForm } from "components/Settings/OnboardingSettings/CalendlyForm";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { usePathParams } from "hooks/usePathParams";
import { NotFound } from "components/NotFoundRoute";
import { paths } from "utils/routing/paths";
import { useOnboardingMeetings } from "components/Settings/OnboardingSettings/useOnboardingMeetings";
import { usePracticeLogger } from "components/Main/PracticeSegmentContext";
import { LivestormForm } from "components/Settings/OnboardingSettings/LivestormForm";

export const OnboardingScheduleFormRoute: React.FC = () => {
  const { meetingId } = usePathParams("trainingSessionsSchedule");

  const getOnboardingMeeting = useOnboardingMeetings();
  const meetingDetails = useMemo(() => getOnboardingMeeting(meetingId), [getOnboardingMeeting, meetingId]);
  const { track } = usePracticeLogger();

  const handleEventScheduled = useCallback(() => {
    track(
      { event: "Training Scheduled", domains: ["Settings"] },
      { properties: { meetingId: meetingDetails?.id } }
    );
  }, [meetingDetails?.id, track]);

  return meetingDetails ? (
    <SettingsPanel
      actions={
        <LinkIcon
          SvgIcon={CancelIcon}
          to={paths.settingsSection({ section: "training-sessions" })}
          theme="primary"
        />
      }
      title={`Schedule ${meetingDetails.title}`}
      includePadding={false}
    >
      {meetingDetails.type === "calendly" ? (
        <CalendlyForm
          calendlyLink={meetingDetails.url}
          defaultAnswers={meetingDetails.defaultAnswers}
          onEventScheduled={handleEventScheduled}
        />
      ) : (
        <LivestormForm baseUrl={meetingDetails.url} />
      )}
    </SettingsPanel>
  ) : (
    <NotFound />
  );
};
