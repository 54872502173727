import { ChangeEventHandler, FC, PropsWithChildren, ReactNode, useMemo } from "react";
import { PaymentDeviceVO, PaymentProfileVO } from "@libs/api/generated-api";
import { CharacterCounter } from "@libs/components/UI/CharacterCounter";
import { formatCurrency } from "@libs/utils/currency";
import { RadioList } from "@libs/components/UI/RadioList";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import {
  FormFieldSelectMenusDatepicker,
  FormFieldSelectMenusDatepickerProps,
} from "components/UI/FormFieldSelectMenusDatepicker";
import { PaymentCardButton } from "components/PatientProfile/Billing/PaymentCardButton";
import { PosButton } from "components/PatientProfile/Billing/PosButton";
import { CreditCard } from "components/PatientProfile/Billing/CreditCard";
import { FormFieldCurrencyInput, FormFieldCurrencyInputProps } from "components/UI/FormFieldCurrencyInput";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";
import { Wallet } from "components/PatientProfile/Billing/Wallet";
import { WalletWithFamilyMember } from "components/PatientProfile/Billing/billingUtils";
import { getFirstAndLastName } from "utils/names";
import {
  cxStyles,
  PaymentProfileUuid,
  PaymentDeviceUuid,
  PaymentAmountType,
  WalletUuid,
} from "./PaymentMethods/utils";

export const SelectPaymentDate: FC<Omit<FormFieldSelectMenusDatepickerProps, "label">> = ({
  selected,
  onChange,
  error,
  disabled,
  layout,
  required,
}) => {
  const now = useMemo(() => new Date(), []);

  return (
    <FormFieldSelectMenusDatepicker
      label="Date"
      layout={layout}
      required={required}
      selected={selected}
      maxDate={now}
      error={error}
      onChange={onChange}
      disabled={disabled}
    />
  );
};

export const PaymentProfiles: FC<{
  patientId: number;
  paymentProfiles: PaymentProfileVO[];
  paymentProfileUuid: PaymentProfileUuid;
  onPaymentProfileUuidChange: (paymentProfileUuid: PaymentProfileUuid) => void;
  disabled?: boolean;
}> = ({ patientId, paymentProfiles, paymentProfileUuid, onPaymentProfileUuidChange, disabled }) => {
  return (
    <>
      {paymentProfiles.map((profile) => (
        <PaymentCardButton
          key={profile.uuid}
          disabled={disabled}
          onClick={() => onPaymentProfileUuidChange(profile.uuid)}
        >
          <CreditCard
            disabled={disabled}
            nameOnCard={profile.billingName ?? ""}
            cardBrand={profile.card?.cardBrand ?? ""}
            cardLastFour={profile.card?.cardLastFour ?? ""}
            isSelected={profile.uuid === paymentProfileUuid}
            cardType={profile.card?.cardType}
            // We only want to show the default badge if the payment profile
            // belongs to the currently selected patient. For family cards, they
            // can also be marked as default for their respective family member,
            // but we don't want to show the badge in those cases.
            type={
              patientId !== profile.owner.id && profile.isFamily
                ? "family"
                : patientId === profile.owner.id && profile.isDefault
                  ? "default"
                  : undefined
            }
          />
        </PaymentCardButton>
      ))}
    </>
  );
};

export const Wallets: FC<{
  patientId: number;
  walletsWithFamilyMembers: WalletWithFamilyMember[];
  selectedWalletUuid: WalletUuid;
  onWalletUuidChange: (walletUuid: WalletUuid) => void;
  disabled?: boolean;
}> = ({ patientId, walletsWithFamilyMembers, selectedWalletUuid, disabled, onWalletUuidChange }) => {
  return (
    <>
      {walletsWithFamilyMembers.map((walletWithFamilyMember) => (
        <WalletButton
          key={walletWithFamilyMember.wallet.uuid}
          isSelected={walletWithFamilyMember.wallet.uuid === selectedWalletUuid}
          walletWithFamilyMember={walletWithFamilyMember}
          disabled={disabled}
          onWalletUuidChange={onWalletUuidChange}
          type={
            walletWithFamilyMember.wallet.patientId !== patientId && walletWithFamilyMember.wallet.isFamily
              ? "family"
              : undefined
          }
        />
      ))}
    </>
  );
};

export const WalletButton: FC<{
  walletWithFamilyMember: WalletWithFamilyMember;
  isSelected: boolean;
  disabled?: boolean;
  type?: "family";
  onWalletUuidChange: (walletUuid: WalletUuid) => void;
}> = ({ walletWithFamilyMember, isSelected, disabled, type, onWalletUuidChange }) => {
  const walletName = walletWithFamilyMember.familyMember?.name
    ? `${getFirstAndLastName(walletWithFamilyMember.familyMember.name)}'s Wallet`
    : "Wallet";

  return (
    <PaymentCardButton
      disabled={disabled}
      onClick={() => onWalletUuidChange(walletWithFamilyMember.wallet.uuid)}
    >
      <Wallet
        disabled={disabled}
        name={walletName}
        balance={formatCurrency(walletWithFamilyMember.wallet.balance)}
        isSelected={isSelected}
        type={type}
      />
    </PaymentCardButton>
  );
};

export const PaymentDevices: FC<{
  paymentDevices: PaymentDeviceVO[];
  paymentDeviceUuid: PaymentDeviceUuid;
  onPaymentDeviceUuidChange: (paymentDeviceUuid: PaymentDeviceUuid) => void;
  disabled?: boolean;
}> = ({ paymentDevices, paymentDeviceUuid, onPaymentDeviceUuidChange, disabled }) => {
  return (
    <>
      {paymentDevices.map((pos) => (
        <PosButton
          key={pos.uuid}
          paymentPos={pos}
          isSelected={pos.uuid === paymentDeviceUuid}
          disabled={disabled}
          onClick={() => onPaymentDeviceUuidChange(pos.uuid)}
        />
      ))}
    </>
  );
};

export const CheckNumber: FC<{
  layout?: "labelIn" | "labelOut";
  value: string | undefined;
  onChange: (value: string) => void;
  error?: string;
  disabled?: boolean;
}> = ({ value, onChange, error, disabled, layout }) => {
  return (
    <FormFieldInput
      label="Check Number"
      layout={layout}
      placeholder="XXXXXXXXXXXX"
      autoComplete="off"
      value={value}
      error={error}
      disabled={disabled}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};

export const CheckBankName: FC<{
  layout?: "labelIn" | "labelOut";
  value: string | undefined;
  onChange: (value: string) => void;
  error?: string;
  disabled?: boolean;
}> = ({ value, onChange, error, disabled, layout }) => {
  return (
    <FormFieldInput
      label="Bank Name"
      layout={layout}
      placeholder="e.g. Bank of America"
      value={value}
      error={error}
      disabled={disabled}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};

export const ExternalPosNameOnCard: FC<{
  layout?: "labelIn" | "labelOut";
  value: string | undefined;
  onChange: (value: string) => void;
  error?: string;
  disabled?: boolean;
}> = ({ value, onChange, error, disabled, layout }) => {
  return (
    <FormFieldInput
      label="Name on Card"
      layout={layout}
      placeholder="e.g. John Doe"
      autoComplete="off"
      value={value}
      error={error}
      disabled={disabled}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};

export const ExternalPosCardNumber: FC<{
  layout?: "labelIn" | "labelOut";
  value: string | undefined;
  onChange: (value: string) => void;
  error?: string;
  disabled?: boolean;
}> = ({ value, onChange, error, disabled, layout }) => {
  return (
    <FormFieldInput
      label="Card Number (last 4 digits)"
      layout={layout}
      placeholder="XXXX"
      autoComplete="off"
      value={value}
      error={error}
      disabled={disabled}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};

export const AchBankName: FC<{
  value: string | undefined;
  onChange: (value: string) => void;
  error?: string;
  disabled?: boolean;
  layout?: "labelIn" | "labelOut";
}> = ({ value, onChange, error, disabled, layout }) => {
  return (
    <FormFieldInput
      label="ACH Bank Name"
      layout={layout}
      placeholder="e.g. Bank of America"
      value={value}
      error={error}
      disabled={disabled}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};

export const AchNumber: FC<{
  layout?: "labelIn" | "labelOut";
  value: string | undefined;
  onChange: (value: string) => void;
  error?: string;
  disabled?: boolean;
}> = ({ value, onChange, error, disabled, layout }) => {
  return (
    <FormFieldInput
      label="ACH Number"
      layout={layout}
      placeholder="XXXXXXXXXXXX"
      autoComplete="off"
      value={value}
      error={error}
      disabled={disabled}
      onChange={(event) => onChange(event.target.value)}
    />
  );
};

export const EmptyPaymentMethod: FC<PropsWithChildren<{ message: string; image: string }>> = ({
  message,
  image,
  children,
}) => {
  return (
    <div className="border rounded p-3 flex flex-col items-center">
      <img alt={message} src={image} />
      <span className="text-center mt-2 text-xs">{children ?? message}</span>
    </div>
  );
};

export const PartialOrFullAmount: FC<{
  value: PaymentAmountType;
  onChange: (value: PaymentAmountType) => void;
  disabled?: boolean;
}> = ({ value, onChange, disabled }) => {
  return (
    <RadioList
      label={<span className={cxStyles.sectionHeader}>Amount Type</span>}
      onChange={(e) => onChange(e.target.value as PaymentAmountType)}
      selectedValue={value}
      required={true}
      options={[
        { value: "FULL", label: "Full" },
        { value: "PARTIAL", label: "Partial" },
      ]}
      disabled={disabled}
    />
  );
};

export const PaymentAmount: FC<{
  value: string | number | undefined;
  onChange: FormFieldCurrencyInputProps["onValueChange"];
  error?: string;
  disabled?: boolean;
}> = ({ value, onChange, error, disabled }) => {
  return (
    <FormFieldCurrencyInput
      label="Amount"
      layout="labelOut"
      required
      error={error}
      value={value}
      intlConfig={{ locale: "en-US", currency: "USD" }}
      disabled={disabled}
      onValueChange={onChange}
    />
  );
};

export const NOTE_MAX_LENGTH = 500;

export const Note: FC<{
  label?: ReactNode;
  placeholder?: string;
  disabled?: boolean;
  required?: boolean;
  value?: string;
  error?: string;
  onChange: ChangeEventHandler<HTMLTextAreaElement>;
}> = ({ label, placeholder, value, error, required, disabled, onChange }) => {
  return (
    <div className="flex flex-col max-w-xl">
      <FormFieldTextarea
        label={label ?? "Internal Note"}
        layout="labelOut"
        placeholder={placeholder ?? "Enter any important notes related to this payment..."}
        value={value}
        error={error}
        required={required}
        disabled={disabled}
        onChange={onChange}
      />
      <CharacterCounter
        className="self-end mt-2 text-xs"
        currentCount={(value ?? "").length}
        max={NOTE_MAX_LENGTH}
        unit="characters"
      />
    </div>
  );
};
