import React from "react";
import { useCameraCapture } from "components/ImageCapturing/useCameraCapture";
import { useImagingDevices } from "components/PatientProfile/Imaging/hooks/useImagingDevices";
import { ModalPage } from "components/UI/ModalPage";
import { paths } from "utils/routing/paths";
import { ImageSettingsModalBody } from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/ImageSettingsModalBody";
import { usePathParams } from "hooks/usePathParams";

export const ImagingSettingsModalPage: React.FC = () => {
  const { patientId } = usePathParams("patientTab");

  const cameraCapture = useCameraCapture();

  const { devices: allDevices, isLoading, handleDeviceSettingsUpdated } = useImagingDevices(cameraCapture);

  const closeLink = paths.patientTab({ tab: "imaging", patientId });

  return (
    <ModalPage title="Configure Imaging Devices" closeLink={closeLink}>
      <div
        className={`
          flex
          justify-between
          items-center
          p-4
          text-xs
          border-b
          border-greyLighter
        `}
      >
        Note: These settings only apply to this computer. Please visit this page from other computers used for
        imaging to configure them as well.
      </div>
      <div className="flex flex-col gap-4">
        <ImageSettingsModalBody
          onChange={handleDeviceSettingsUpdated}
          isLoadingDevices={isLoading}
          devices={allDevices}
        />
      </div>
    </ModalPage>
  );
};
