import React, { useCallback, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { SEARCH_DEBOUNCE_DELAY_MS } from "@libs/utils/constants";
import { flattenPages } from "@libs/utils/queries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ClaimsTabs, ClaimsTabsProps } from "components/Claims/Shared/Tabs";
import { cxStyles } from "components/Claims/styles";
import {
  getClaimsConfigQuery,
  getClaimsTabsTotalsQuery,
  getInfiniteClaimsQuery,
  getPracticeClaimsBillingProviders,
} from "api/claim/queries";
import { getClaimInsuranceCarriersQuery } from "api/patientInsurance/queries";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { ClaimFilters } from "components/Claims/Claims/Filters";
import { useQueryParams } from "hooks/useQueryParams";
import { ClaimsQueryUpdates } from "utils/routing/claims";
import { getClaimsFilterProps } from "components/Claims/Claims/claimsQuery";
import { ClaimState, FilterStatusData } from "components/Claims/types";
import { RECORDS_PER_PAGE } from "utils/words";
import { ClaimsTable } from "components/Claims/Claims/Table";
import { useClaimsTableCheckboxes } from "components/Claims/Claims/useClaimsTableCheckboxes";
import { paths } from "utils/routing/paths";

export const ClaimsList: React.FC<{
  isPreAuth?: boolean;
  patientId?: number;
  tabs: ClaimsTabsProps["tabs"];
  routeName: "claims" | "patientClaims";
}> = ({ isPreAuth = false, patientId, tabs, routeName }) => {
  const { practiceId } = useAccount();
  const { query, updateQuery } = useQueryParams(routeName);
  const from = routeName === "patientClaims" ? paths.patientClaims({ patientId }) : paths.claims();

  // Flag for minor UI differences between claims domain list view
  // and patient profile list view
  const isClaimsDomain = routeName === "claims";

  const [
    claimsTabsTotalsQuery,
    insuranceCarriersQuery,
    billingProvidersQuery,
    claimsConfigQuery,
    practiceProvidersQuery,
  ] = useApiQueries([
    getClaimsTabsTotalsQuery({ args: { practiceId, patientIds: patientId ? [patientId] : undefined } }),
    getClaimInsuranceCarriersQuery({ args: { practiceId } }),
    getPracticeClaimsBillingProviders({
      args: { practiceId },
    }),
    getClaimsConfigQuery({ args: { practiceId } }),
    getPracticeProvidersQuery({
      args: { practiceId },
      queryOptions: { enabled: isClaimsDomain },
    }),
  ]);

  const [searchString, setSearchString] = useState(query.search || "");

  const updateSearch = useDebouncedCallback(
    (value: string) => handleQueryParamChange({ search: value }),
    SEARCH_DEBOUNCE_DELAY_MS
  );

  const handleSearch = useCallback(
    (value: string) => {
      setSearchString(value);
      updateSearch(value);
    },
    [updateSearch]
  );

  const claimsFilterProps = useMemo(() => {
    return getClaimsFilterProps(
      query,
      billingProvidersQuery.data,
      insuranceCarriersQuery.data,
      practiceProvidersQuery.data?.filter((provider) => provider.jobCategory === "DENTIST")
    );
  }, [query, insuranceCarriersQuery.data, billingProvidersQuery.data, practiceProvidersQuery.data]);

  const claimsQueryKey = useMemo(
    () => ({
      ...query,
      practiceId,
      preAuth: isPreAuth,
      searchString: query.search,
      states: query.statuses
        ? query.statuses
            .map((status) => FilterStatusData[status].states)
            .filter(Boolean)
            .reduce((acc, curr) => (acc && curr ? [...new Set<ClaimState>([...acc, ...curr])] : acc), [])
        : undefined,
    }),
    [isPreAuth, practiceId, query]
  );

  const claimsQuery = useInfiniteApiQuery(
    getInfiniteClaimsQuery({
      args: {
        ...claimsQueryKey,
        pageNumber: 1,
        pageSize: RECORDS_PER_PAGE,
        patientIds: patientId ? [patientId] : undefined,
      },
    })
  );

  const claims = useMemo(() => {
    return flattenPages(claimsQuery.data) || [];
  }, [claimsQuery.data]);

  const {
    disableBulkActions,
    handleCheckboxSelect,
    handleSelectAllCheckboxes,
    handleDeselectAllCheckboxes,
    indeterminateCheckboxState,
    pendingClaimsSelected,
    selectedCarrierIds,
    selectedClaimUuids,
  } = useClaimsTableCheckboxes(claims, isPreAuth);

  const handleQueryParamChange = useCallback(
    (keyValues: ClaimsQueryUpdates) => {
      if ("search" in keyValues && keyValues["search"] === undefined) {
        setSearchString("");
      }

      updateQuery("replaceIn", keyValues);
      handleDeselectAllCheckboxes();
    },
    [handleDeselectAllCheckboxes, updateQuery]
  );

  const [expandedRows, setExpandedRows] = useState<Record<string, boolean>>({});

  const handleRowClick = useCallback((claimUuid: string) => {
    setExpandedRows((last) => {
      return { ...last, [claimUuid]: !last[claimUuid] };
    });
  }, []);

  return (
    <div className={cxStyles.pageWrapper}>
      <ClaimsTabs claimsTabsTotalsQuery={claimsTabsTotalsQuery} tabs={tabs} />
      <ClaimFilters
        billingProvidersQuery={billingProvidersQuery}
        claimsConfigQuery={claimsConfigQuery}
        claims={claims}
        claimsQuery={claimsQuery}
        disableBulkActions={disableBulkActions}
        filterProps={claimsFilterProps}
        insuranceCarriersQuery={insuranceCarriersQuery}
        isClaimsDomain={isClaimsDomain}
        isPreAuth={isPreAuth}
        onDeselectAll={handleDeselectAllCheckboxes}
        onFiltersUpdate={handleQueryParamChange}
        onSearch={handleSearch}
        patientId={patientId}
        practiceProvidersQuery={practiceProvidersQuery}
        queryStateApi={query}
        searchString={searchString}
        selectedCarrierIds={selectedCarrierIds}
        selectedClaimUuids={selectedClaimUuids}
      />
      <QueryResult queries={[claimsQuery, claimsTabsTotalsQuery]}>
        <ClaimsTable
          claims={claims}
          claimsQuery={claimsQuery}
          claimsQueryKey={claimsQueryKey}
          disableBulkActions={disableBulkActions}
          expandedRows={expandedRows}
          from={from}
          indeterminateCheckboxState={indeterminateCheckboxState}
          isPreAuth={isPreAuth}
          onApplyFilters={handleQueryParamChange}
          onDeselectAll={handleDeselectAllCheckboxes}
          onSelectAll={handleSelectAllCheckboxes}
          onSelectCheckbox={handleCheckboxSelect}
          onRowClick={handleRowClick}
          pendingClaimsSelected={pendingClaimsSelected}
          queryStateApi={query}
          selectedClaimUuids={selectedClaimUuids}
          totalClaims={claimsTabsTotalsQuery.data?.claimCount ?? 0}
        />
      </QueryResult>
    </div>
  );
};
