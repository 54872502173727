import { CalculateSurchargeRequest, MultiInvoicePaymentRequest } from "@libs/api/generated-api";
import { getNextPageParam, makeInfiniteQuery, makeQuery } from "@libs/utils/queries";
import { cacheForSession, oneMinuteCache, fiveMinuteCache, noCache } from "@libs/utils/queryCache";

export const getPaymentProfilesByPatientId = makeQuery({
  queryKey: ["practices", "getAllPaymentProfilesByPatient"],
  formatParams: (args: { patientId: number; practiceId: number; includeFamily?: boolean }) => [
    args.patientId,
    args.practiceId,
    { ...args },
  ],
  queryOptions: {
    ...cacheForSession,
  },
});

export const getPaymentDevice = makeQuery({
  queryKey: ["practices", "getPaymentDevice"],
  formatParams: (args: { practiceId: number; paymentDeviceUuid: string }) => [
    args.practiceId,
    args.paymentDeviceUuid,
  ],
  queryOptions: cacheForSession,
});

export const getLedgerBalanceSummary = makeQuery({
  queryKey: ["practices", "getLedgerBalanceSummary"],
  formatParams: (args: { patientId: number; practiceId: number }) => [args.patientId, args.practiceId],
  queryOptions: oneMinuteCache,
});

export const getFinixOnboardingSummary = makeQuery({
  queryKey: ["practices", "getFinixOnboardingSummary"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: oneMinuteCache,
});

export const getPracticeBillingSetting = makeQuery({
  queryKey: ["practices", "getPracticeBillingSetting"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: oneMinuteCache,
});

export const getPendingInvoiceEntries = makeQuery({
  queryKey: ["practices", "getPendingInvoiceEntries"],
  formatParams: (args: { patientId: number; practiceId: number }) => [args.patientId, args.practiceId],
  queryOptions: oneMinuteCache,
});

export const getFamilyMembersWithPendingInvoiceEntries = makeQuery({
  queryKey: ["practices", "getFamilyMembersWithPendingInvoiceEntries"],
  formatParams: (args: { practiceId: number; patientId: number }) => [args.practiceId, args.patientId],
  queryOptions: oneMinuteCache,
});

export const getInvoicesInfiniteQuery = makeInfiniteQuery({
  queryKey: ["practices", "getInvoices"],
  formatParams: ({
    patientId,
    practiceId,
    ...query
  }: {
    patientId: number;
    practiceId: number;
    includeOrders?: boolean;
    includePayments?: boolean;
    includePayerInfo?: boolean;
    includePracticeInfo?: boolean;
    sortDirection?: "ASCENDING" | "DESCENDING";
    sortField?: string;
    states?: ("FINALIZED" | "VOID" | "PARTIALLY_PAID" | "PAID")[];
    paymentStates?: (
      | "CREATED"
      | "IN_PROGRESS"
      | "AWAITING_RESULT"
      | "PENDING"
      | "SETTLED"
      | "DENIED"
      | "CHARGEBACK"
      | "VOID"
    )[];
    /** @format int32 */
    pageNumber: number;
    /** @format int32 */
    pageSize: number;
  }) => [patientId, practiceId, query],
  queryOptions: {
    ...oneMinuteCache,
    getNextPageParam,
  },
});

export const getInvoiceByUuid = makeQuery({
  queryKey: ["practices", "getInvoice"],
  formatParams: (args: {
    patientId: number;
    practiceId: number;
    invoiceUuid: string;
    query?: {
      includeOrders?: boolean;
      includePayments?: boolean;
      includePayerInfo?: boolean;
      includePracticeInfo?: boolean;
      paymentStates?: (
        | "CREATED"
        | "IN_PROGRESS"
        | "AWAITING_RESULT"
        | "PENDING"
        | "SETTLED"
        | "DENIED"
        | "CHARGEBACK"
        | "VOID"
      )[];
    };
  }) => [args.patientId, args.practiceId, args.invoiceUuid, args.query],
  queryOptions: oneMinuteCache,
});

export const getPayment = makeQuery({
  queryKey: ["practices", "getPayment"],
  formatParams: ({ practiceId, paymentUuid }: { practiceId: number; paymentUuid: string }) => [
    practiceId,
    paymentUuid,
  ],
  queryOptions: oneMinuteCache,
});

export const calculateSurchargeOnPayment = makeQuery({
  queryKey: ["public", "calculateSurchargeOnPayment"],
  formatParams: ({ practiceUuid, data }: { practiceUuid: string; data: CalculateSurchargeRequest }) => [
    practiceUuid,
    data,
  ],
  queryOptions: cacheForSession,
});

export const getMultiInvoicePayment = makeQuery({
  queryKey: ["practices", "getMultiInvoicePayment"],
  formatParams: ({
    practiceId,
    patientId,
    multiInvoicePaymentUuid,
  }: {
    practiceId: number;
    patientId: number;
    multiInvoicePaymentUuid: string;
  }) => [practiceId, patientId, multiInvoicePaymentUuid],
  queryOptions: oneMinuteCache,
});

/*
  Same as the createMultiInvoicePayment mutation, but hardcodes the commit: false flag, meaning there are no side effects on the server.
  This allows createMultiInvoicePayment in this case to be allowed to be used as a query
*/
export const getInvoicesDryRun = makeQuery({
  queryKey: ["practices", "createMultiInvoicePayment"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    data: Pick<MultiInvoicePaymentRequest, "invoiceUuids" | "payment" | "includeFamily">;
  }) => [args.practiceId, args.patientId, { ...args.data, commit: false }],
  queryOptions: noCache,
});

export const getInvoiceUrl = makeQuery({
  queryKey: ["practices", "printInvoice"],
  formatParams: ({
    patientId,
    practiceId,
    invoiceUuid,
  }: {
    patientId: number;
    practiceId: number;
    invoiceUuid: string;
  }) => [patientId, practiceId, invoiceUuid],
  queryOptions: oneMinuteCache,
});

export const getPaymentUrl = makeQuery({
  queryKey: ["practices", "printPayment"],
  formatParams: ({
    patientId,
    practiceId,
    paymentUuid,
  }: {
    patientId: number;
    practiceId: number;
    paymentUuid: string;
  }) => [practiceId, patientId, paymentUuid],
  queryOptions: oneMinuteCache,
});

export const listRefundablePayments = makeQuery({
  queryKey: ["practices", "listRefundablePayments"],
  formatParams: ({
    practiceId,
    patientId,
    query,
  }: {
    practiceId: number;
    patientId: number;
    query: {
      paymentMethods?: (
        | "STORED_PROFILE"
        | "CASH"
        | "CHECK"
        | "STORED_POS"
        | "EXTERNAL_POS"
        | "EFT"
        | "WALLET"
      )[];
      paymentReferenceTypes?: ("INVOICE" | "CLAIM_PAYMENT" | "WALLET_PAYMENT" | "BATCH_PAYMENT")[];
      paymentProfileUuids?: string[];
      minAmount?: number;
      maxPageSize?: number;
      pageNumber: number;
      pageSize: number;
    };
  }) => [practiceId, patientId, query],
  queryOptions: oneMinuteCache,
});

export const getAdjustment = makeQuery({
  queryKey: ["practices", "getAdjustment"],
  formatParams: (args: { practiceId: number; patientId: number; adjustmentUuid: string }) => [
    args.practiceId,
    args.patientId,
    args.adjustmentUuid,
  ],
  queryOptions: oneMinuteCache,
});

export const getWallets = makeQuery({
  queryKey: ["practices", "getWallets"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    withEmptyBalances?: boolean;
    includeFamily?: boolean;
    maxPageSize?: number;
    pageNumber?: number;
    pageSize?: number;
  }) =>
    // We only support 1 patient wallet at this time, so hardcode `pageNumber`
    // and `pageSize`.
    [args.practiceId, args.patientId, { pageNumber: 1, pageSize: 1, withEmptyBalances: true, ...args }],
  queryOptions: fiveMinuteCache,
});

export const getWalletActivities = makeInfiniteQuery({
  queryKey: ["practices", "getWalletActivities"],
  formatParams: ({
    practiceId,
    patientId,
    walletUuid,
    ...query
  }: {
    practiceId: number;
    patientId: number;
    walletUuid: string;
    types?: ("WITHDRAW" | "TOP_UP")[];
    activityCreatedAtGe?: number;
    activityCreatedAtLe?: number;
    pageNumber: number;
    pageSize: number;
  }) => [practiceId, patientId, walletUuid, query],
  queryOptions: { ...oneMinuteCache, getNextPageParam },
});

export const getWalletActivityById = makeQuery({
  queryKey: ["practices", "getWalletActivityById"],
  formatParams: ({
    practiceId,
    patientId,
    walletUuid,
    walletActivityId,
  }: {
    practiceId: number;
    patientId: number;
    walletUuid: string;
    walletActivityId: number;
  }) => [practiceId, patientId, walletUuid, walletActivityId],
  queryOptions: { ...oneMinuteCache, getNextPageParam },
});

export const getLedgerEntries = makeInfiniteQuery({
  queryKey: ["practices", "getLedgerEntries"],
  formatParams: ({
    patientId,
    practiceId,
    ...query
  }: {
    patientId: number;
    practiceId: number;
    sortField?: string;
    sortDirection?: "ASCENDING" | "DESCENDING";
    maxPageSize?: number;
    pageNumber: number;
    pageSize: number;
  }) => [patientId, practiceId, query],
  queryOptions: {
    ...oneMinuteCache,
    getNextPageParam,
  },
});

export const getLedgerEntryByTypeAndId = makeQuery({
  queryKey: ["practices", "getLedgerEntryByTypeAndId"],
  formatParams: ({
    patientId,
    practiceId,
    entryType,
    entryId,
  }: {
    patientId: number;
    practiceId: number;
    entryType: "APPOINTMENT" | "ADJUSTMENT";
    entryId: string;
  }) => [patientId, practiceId, entryType, entryId],
  queryOptions: {
    ...oneMinuteCache,
    getNextPageParam,
  },
});

export const getStatementDateRange = makeQuery({
  queryKey: ["practices", "getStatementDateRange"],
  formatParams: ({ patientId, practiceId }: { patientId: number; practiceId: number }) => [
    practiceId,
    patientId,
  ],
  queryOptions: oneMinuteCache,
});

export const getStatementItemCountPerDay = makeQuery({
  queryKey: ["practices", "getStatementItemCountPerDay"],
  formatParams: ({
    patientId,
    practiceId,
    startDate,
    endDate,
  }: {
    patientId: number;
    practiceId: number;
    startDate: string;
    endDate: string;
  }) => [practiceId, patientId, { startDate, endDate }],
  queryOptions: oneMinuteCache,
});

export const listPatientPaymentSummaries = makeInfiniteQuery({
  queryKey: ["v2", "listPatientPaymentSummaries"],
  formatParams: ({
    practiceId,
    patientId,
    ...query
  }: {
    practiceId: number;
    patientId: number;
    maxPageSize?: number;
    pageNumber: number;
    pageSize: number;
  }) => [practiceId, patientId, query],
  queryOptions: {
    ...oneMinuteCache,
    getNextPageParam,
  },
});

export const getFinixOnboardingFormUrl = makeQuery({
  queryKey: ["practices", "getFinixOnboardingFormUrl"],
  formatParams: ({ practiceId }: { practiceId: number }) => [practiceId],
  queryOptions: oneMinuteCache,
});

export const getFinixMerchantIdentity = makeQuery({
  queryKey: ["practices", "getFinixMerchantIdentity"],
  formatParams: ({ practiceId }: { practiceId: number }) => [practiceId],
  queryOptions: oneMinuteCache,
});

export const listMigratedTransactions = makeInfiniteQuery({
  queryKey: ["practices", "listMigratedTransactions"],
  formatParams: ({
    patientId,
    practiceId,
    ...query
  }: {
    patientId: number;
    practiceId: number;
    types?: ("ADJUSTMENT" | "PAYMENT" | "PROCEDURE" | "OTHER")[];
    sortDirection?: "ASCENDING" | "DESCENDING";
    sortField?: string;
    searchString?: string;
    employeeIds?: number[];
    maxPageSize?: number;
    pageNumber: number;
    pageSize: number;
  }) => [patientId, practiceId, query],
  queryOptions: {
    ...cacheForSession,
    getNextPageParam,
  },
});
