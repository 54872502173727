import { produce, Draft } from "immer";
import { useState, useRef, useEffect, useCallback } from "react";

/**
 * @deprecated Instead of using this hook create two components, one that handles the edit state / mutation
 * and one component that just displays the data.  Render one or the other based on the edit state.
 */
export const useEditServerState = <D>(
  serverState: D,
  isEditing: boolean,
  options?: { onStartEdit?: Func }
) => {
  const [draft, setDraft] = useState(serverState);
  const onStartEdit = options?.onStartEdit;

  const isEditingRef = useRef<boolean | null>(null);

  const handleChange = useCallback((updater: (draft: Draft<D>) => void) => {
    setDraft((previousDraft) => produce(previousDraft, (editable) => void updater(editable)));
  }, []);

  // Only copy the server state into the draft when we start editing
  useEffect(() => {
    if (isEditing && !isEditingRef.current) {
      setDraft(serverState);
      onStartEdit?.();
    }

    isEditingRef.current = isEditing;
  }, [serverState, isEditing, onStartEdit]);

  // When editing return the draft, otherwise return the server state
  return [isEditing ? draft : serverState, handleChange] as const;
};
