import { FC, useMemo } from "react";

import { useAccount } from "@libs/contexts/AccountContext";
import { useApiQueries } from "@libs/hooks/useApiQueries";

import { Panel } from "@libs/components/UI/Panel";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ElectronicPrescribing } from "components/Erx/ElectronicPrescribing";

import { getPracticeDosespotEntities } from "api/erx/queries";
import { groupBy } from "utils/groupBy";

export const ElectronicPrescribingRoute: FC = () => {
  const { practiceId } = useAccount();

  const [practiceDosespotEntitiesQuery] = useApiQueries([
    getPracticeDosespotEntities({ args: { practiceId } }),
  ]);

  const { prescribers, isActive } = useMemo(() => {
    if (practiceDosespotEntitiesQuery.data) {
      const { EMPLOYEE, PRACTICE } = groupBy(practiceDosespotEntitiesQuery.data, "type");

      return {
        prescribers: EMPLOYEE ?? [],
        isActive: (PRACTICE ?? []).length > 0,
      };
    }

    return {
      prescribers: [],
      isActive: false,
    };
  }, [practiceDosespotEntitiesQuery.data]);

  return (
    <QueryResult queries={[practiceDosespotEntitiesQuery]}>
      {practiceDosespotEntitiesQuery.data ? (
        <Panel title="Electronic Prescribing" className="h-full" contentClassName="overflow-y-auto">
          <ElectronicPrescribing prescribers={prescribers} isActive={isActive} />
        </Panel>
      ) : null}
    </QueryResult>
  );
};
