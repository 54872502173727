import { FC, useMemo, useCallback } from "react";

import { SavedFilterVO, PatientListCriteria } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { Spinner } from "@libs/components/UI/Spinner";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as VerticalMenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { EmptyContent } from "@libs/components/UI/EmptyContent";
import { FlyoverContent } from "components/UI/FlyoverComponents";

import emptyList from "assets/images/empty-list.svg";

interface Props {
  savedFiltersQuery: UseInfiniteApiListQueryResult<SavedFilterVO>;
  onApplySavedFilter: (criteria: PatientListCriteria, options: { onSuccess: Func }) => void;
  onDeleteSavedFilter: (savedFilterUuid: string, options: { onSuccess: Func }) => void;
  isDeletingSavedFilter: boolean;
}

export const SavedFlyoverContent: FC<Props> = ({
  savedFiltersQuery,
  onApplySavedFilter,
  onDeleteSavedFilter,
  isDeletingSavedFilter,
}) => {
  const { scrollRef } = useInfiniteScrollQuery({ infiniteQuery: savedFiltersQuery });
  const savedFilters = useFlattenPages(savedFiltersQuery.data);

  return (
    <ScrollableInfiniteQueryResult
      scrollRef={scrollRef}
      infiniteQuery={savedFiltersQuery}
      noResults={
        <div className="flex justify-center px-8 py-6">
          <EmptyContent text="No Saved Filters" src={emptyList} alt="No Saved Filters" size="md" />
        </div>
      }
    >
      <FlyoverContent paddingClassName="px-8 py-6">
        <div className="flex flex-col gap-y-6">
          {savedFilters?.map((savedFilter) => (
            <SavedFilter
              key={savedFilter.uuid}
              savedFilter={savedFilter}
              onApplySavedFilter={onApplySavedFilter}
              onDeleteSavedFilter={onDeleteSavedFilter}
              isDeletingSavedFilter={isDeletingSavedFilter}
            />
          ))}
        </div>
      </FlyoverContent>
    </ScrollableInfiniteQueryResult>
  );
};

const SavedFilter: FC<{
  savedFilter: SavedFilterVO;
  onApplySavedFilter: (criteria: PatientListCriteria, options: { onSuccess: Func }) => void;
  onDeleteSavedFilter: (savedFilterUuid: string, options: { onSuccess: Func }) => void;
  isDeletingSavedFilter: boolean;
}> = ({ savedFilter, onApplySavedFilter, onDeleteSavedFilter, isDeletingSavedFilter }) => {
  const menu = useBoolean(false);
  const saving = useBoolean(false);

  const menuOptions = useMemo(
    () =>
      createMenuOptions({
        SvgIcon: DeleteIcon,
        label: "Delete",
        value: "delete",
        isLoading: isDeletingSavedFilter,
      }),
    [isDeletingSavedFilter]
  );

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "delete": {
          onDeleteSavedFilter(savedFilter.uuid, { onSuccess: menu.off });
          break;
        }
        default: {
          break;
        }
      }
    },
    [onDeleteSavedFilter, savedFilter.uuid, menu]
  );

  const handleApplySavedFilter = useCallback(() => {
    if (savedFilter.filter.type === "PATIENT_LIST" && savedFilter.filter.patientListCriteria) {
      saving.on();
      onApplySavedFilter(savedFilter.filter.patientListCriteria, {
        onSuccess: saving.off,
      });
    }
  }, [onApplySavedFilter, savedFilter.filter, saving]);

  return (
    <div
      className={`
        flex
        items-center
        justify-between
        h-10
        p-1
        rounded
        border
        border-slate-300
      `}
      data-testid="saved-filter"
    >
      <button
        className="w-full h-full pl-3 pr-6 text-sm text-left truncate"
        onClick={handleApplySavedFilter}
        type="button"
      >
        {savedFilter.name}
      </button>

      {saving.isOn ? (
        <div className="flex items-center justify-center w-8 h-8">
          <Spinner variant="secondary" animation="border" size="sm" />
        </div>
      ) : (
        <ButtonMenu
          menuContent={
            <div className="w-32">
              <MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />
            </div>
          }
          onRequestOpen={menu.on}
          onRequestClose={menu.off}
          isOpen={menu.isOn}
          placement="bottom-end"
        >
          {(props) => (
            <ButtonIcon
              className="min-w-8 h-8"
              SvgIcon={VerticalMenuIcon}
              tooltip={{ content: "Saved Filter Menu", theme: "SMALL" }}
              {...props}
            />
          )}
        </ButtonMenu>
      )}
    </div>
  );
};
