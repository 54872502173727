import {
  CreateFolderRequest,
  DocumentVO,
  FolderVO,
  MoveDocumentRequest,
  UpdateDocumentRequest,
  UpdateFolderRequest,
  UploadDocumentRequest,
  CreateCustomPatientFolderRequest,
  UpdateCustomPatientFolderRequest,
  FetchCustomPatientFolderResponse,
} from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import {
  updateCachedListWithUpdatedItem,
  updateCachedListWithDeletedItem,
  replaceCachedItemWithUpdatedItem,
  updateCachedListWithCreatedItem,
  updateCachedData,
} from "@libs/utils/queryCache";
import { produce } from "immer";

export const createFolder = makeMutation({
  mutationKey: ["practices", "createFolder"],
  formatParams: (args: { practiceId: number; userId: number; data: CreateFolderRequest }) => [
    args.practiceId,
    args.userId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, userId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getFolders"), { practiceId, userId }]);
    },
  }),
});

export const updateFolder = makeMutation({
  mutationKey: ["practices", "updateFolder"],
  formatParams: (args: {
    practiceId: number;
    folderId: number;
    userId: number;
    data: UpdateFolderRequest;
  }) => [args.practiceId, args.folderId, args.userId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId, userId }) => {
      updateCachedListWithUpdatedItem<FolderVO>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getFolders"), { practiceId, userId }] },
        data.data.data,
        "id"
      );
    },
  }),
});

export const deleteFolder = makeMutation({
  mutationKey: ["practices", "deleteFolder"],
  formatParams: (args: { practiceId: number; userId: number; folderId: number }) => [
    args.practiceId,
    args.userId,
    args.folderId,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, userId, folderId }) => {
      updateCachedListWithDeletedItem<FolderVO>()(
        queryClient,
        { queryKey: [getQueryKey("practices", "getFolders"), { practiceId, userId }] },
        "id",
        folderId
      );
    },
  }),
});

export const generateUploadUrl = makeMutation({
  mutationKey: ["practices", "generateUploadUrl"],
  formatParams: (args: {
    practiceId: number;
    userId: number;
    folderId: number;
    data: UploadDocumentRequest;
  }) => [args.practiceId, args.userId, args.folderId, args.data],
});

export const confirmUpload = makeMutation({
  mutationKey: ["practices", "confirmUpload"],
  formatParams: (args: {
    practiceId: number;
    userId: number;
    folderId: number;
    data: UploadDocumentRequest;
  }) => [args.practiceId, args.userId, args.folderId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, userId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getFolders"), { practiceId, userId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getFolderDocuments"), { practiceId, userId }]);
    },
  }),
});

export const moveDocument = makeMutation({
  mutationKey: ["practices", "moveDocument"],
  formatParams: (args: {
    practiceId: number;
    userId: number;
    folderId: number;
    documentId: number;
    data: MoveDocumentRequest;
  }) => [args.practiceId, args.userId, args.folderId, args.documentId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId, userId, documentId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getFolders"), { practiceId, userId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getFolderDocuments"), { practiceId, userId }]);
      replaceCachedItemWithUpdatedItem<DocumentVO>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getDocument"), { practiceId, userId, documentId }] },
        data.data.data
      );
    },
  }),
});

export const updateDocument = makeMutation({
  mutationKey: ["practices", "updateDocument"],
  formatParams: (args: {
    practiceId: number;
    userId: number;
    folderId: number;
    documentId: number;
    data: UpdateDocumentRequest;
  }) => [args.practiceId, args.userId, args.folderId, args.documentId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId, userId, documentId, folderId, data: { folderId: updatedFolderId } }) => {
      // Just bust the cache and refetch. It's simpler and less error-prone than
      // having to write logic to figure out if the document moved folders and
      // which folder to add/udpate/delete/sort the updated document from.
      queryClient.invalidateQueries([
        getQueryKey("practices", "getFolderDocuments"),
        { practiceId, userId, folderId },
      ]);

      if (folderId !== updatedFolderId) {
        queryClient.invalidateQueries([
          getQueryKey("practices", "getFolderDocuments"),
          { practiceId, userId, folderId: updatedFolderId },
        ]);
      }

      replaceCachedItemWithUpdatedItem<DocumentVO>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getDocument"), { practiceId, userId, documentId }] },
        data.data.data
      );
      queryClient.invalidateQueries([getQueryKey("practices", "getFolders"), { practiceId, userId }]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPreSignedUrl"),
        { practiceId, userId, folderId, documentId },
      ]);
    },
  }),
});

export const deleteDocument = makeMutation({
  mutationKey: ["practices", "deleteDocument"],
  formatParams: (args: { practiceId: number; userId: number; folderId: number; documentId: number }) => [
    args.practiceId,
    args.userId,
    args.folderId,
    args.documentId,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, userId, folderId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "searchDocument"), { practiceId, userId }]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getFolderDocuments"),
        { practiceId, userId, folderId },
      ]);
      queryClient.invalidateQueries([getQueryKey("practices", "getFolders"), { practiceId, userId }]);
    },
  }),
});

export const uploadOnboardingDocument = makeMutation({
  mutationKey: ["practices", "uploadEmployeeOnboardingDocument"],
  formatParams: (args: { practiceId: number; employeeId: number; data: { document: File } }) => [
    args.practiceId,
    args.employeeId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId, employeeId }) => {
      updateCachedListWithCreatedItem<DocumentVO>({
        queryClient,
        queryFilters: {
          queryKey: [getQueryKey("practices", "getEmployeeOnboardingDocuments"), { practiceId, employeeId }],
        },
        newItem: data.data.data,
        sortOn: "name",
      });
    },
  }),
});

export const deleteOnboardingDocument = makeMutation({
  mutationKey: ["practices", "deleteEmployeeOnboardingDocument"],
  formatParams: (args: { practiceId: number; employeeId: number; documentId: number }) => [
    args.practiceId,
    args.employeeId,
    args.documentId,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, employeeId, documentId }) => {
      updateCachedListWithDeletedItem<DocumentVO>()(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getEmployeeOnboardingDocuments"), { practiceId, employeeId }],
        },
        "id",
        documentId
      );
    },
  }),
});

export const createCustomPatientFolder = makeMutation({
  mutationKey: ["practices", "createCustomPatientFolder"],
  formatParams: (args: { practiceId: number; data: CreateCustomPatientFolderRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId }) => {
      updateCachedData<FetchCustomPatientFolderResponse>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getCustomPatientFolders"), { practiceId }] },
        (cacheEntry) =>
          produce(cacheEntry, (draft) => {
            draft.customPatientFolders.push(data.data.data);
          })
      );
    },
  }),
});

export const updateCustomPatientFolder = makeMutation({
  mutationKey: ["practices", "updateCustomPatientFolder"],
  formatParams: (args: {
    practiceId: number;
    customPatientFolderId: number;
    data: UpdateCustomPatientFolderRequest;
  }) => [args.practiceId, args.customPatientFolderId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId }) => {
      updateCachedData<FetchCustomPatientFolderResponse>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getCustomPatientFolders"), { practiceId }] },
        (cacheEntry) =>
          produce(cacheEntry, (draft) => {
            draft.customPatientFolders = draft.customPatientFolders.map((folder) =>
              folder.id === data.data.data.id ? data.data.data : folder
            );
          })
      );
    },
  }),
});

export const deleteCustomPatientFolder = makeMutation({
  mutationKey: ["practices", "deleteCustomPatientFolder"],
  formatParams: (args: { practiceId: number; customPatientFolderId: number }) => [
    args.practiceId,
    args.customPatientFolderId,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, customPatientFolderId }) => {
      updateCachedData<FetchCustomPatientFolderResponse>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getCustomPatientFolders"), { practiceId }] },
        (cacheEntry) =>
          produce(cacheEntry, (draft) => {
            draft.customPatientFolders = draft.customPatientFolders.filter(
              (folder) => folder.id !== customPatientFolderId
            );
          })
      );
    },
  }),
});
