import { ChangeEvent, FC, useCallback } from "react";
import { EmployeeOnboardingStatusVO, EmploymentDetailsVO } from "@libs/api/generated-api";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as CheckedIcon } from "@libs/assets/icons/check-circle-1.svg";
import { ReactComponent as NotCheckedIcon } from "@libs/assets/icons/cancel-circle.svg";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { useItemModal } from "hooks/useItemModal";

interface ExcludeFromPayrollSelectProps {
  payrollEnabled: boolean;
  employeeOnboardedStatus: EmployeeOnboardingStatusVO["status"] | undefined;
  excludeFromPayroll: EmploymentDetailsVO["excludeFromPayroll"];
  onExcludueFromPayroll: (value: EmploymentDetailsVO["excludeFromPayroll"]) => void;
  edit: boolean;
}

export const ExcludeFromPayrollSelect: FC<ExcludeFromPayrollSelectProps> = ({
  payrollEnabled,
  employeeOnboardedStatus,
  excludeFromPayroll,
  onExcludueFromPayroll,
  edit,
}) => {
  const confirmationModal = useItemModal<{
    primaryText: string;
    secondaryText: string;
    onConfirm: Func;
  }>(null);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const excludeFromPayrollSelected = !e.target.checked;
      const employeeOnboarded = employeeOnboardedStatus === "ONBOARDED";

      if (payrollEnabled) {
        if (employeeOnboarded && excludeFromPayrollSelected) {
          confirmationModal.open({
            primaryText: "Are you sure you want to exclude this employee from Archy Payroll?",
            secondaryText: "They will be excluded when running payroll, and not appear as a listed employee.",
            onConfirm: () => {
              onExcludueFromPayroll(excludeFromPayrollSelected);
              confirmationModal.close();
            },
          });

          return;
        } else if (!employeeOnboarded && !excludeFromPayrollSelected) {
          confirmationModal.open({
            primaryText: "Are you sure you want to include this employee in Archy Payroll?",
            secondaryText:
              "They will receive an email invite to complete onboarding before they can be included when running payroll.",
            onConfirm: () => {
              onExcludueFromPayroll(excludeFromPayrollSelected);
              confirmationModal.close();
            },
          });

          return;
        }
      }

      onExcludueFromPayroll(excludeFromPayrollSelected);
    },
    [payrollEnabled, employeeOnboardedStatus, onExcludueFromPayroll, confirmationModal]
  );

  return (
    <>
      <div>
        <div className="font-sansSemiBold text-xs mb-2">Include in Archy Payroll</div>
        {edit ? (
          <Checkbox className="p-1" checked={!excludeFromPayroll} onChange={handleChange}>
            Yes
          </Checkbox>
        ) : (
          <div className="flex items-center gap-x-1 text-xs">
            {excludeFromPayroll ? (
              <>
                <Icon theme="slate600" SvgIcon={NotCheckedIcon} size="sm" />
                <div>No</div>
              </>
            ) : (
              <>
                <Icon theme="success" SvgIcon={CheckedIcon} size="sm" />
                <div>Yes</div>
              </>
            )}
          </div>
        )}
      </div>

      {confirmationModal.isOpen && (
        <ConfirmationModal
          primaryText={confirmationModal.item.primaryText}
          secondaryText={confirmationModal.item.secondaryText}
          onCancel={confirmationModal.close}
          onConfirm={confirmationModal.item.onConfirm}
        />
      )}
    </>
  );
};
