import { FC } from "react";
import { AutomationActionVO, EmailConfigVO } from "@libs/api/generated-api";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { ToggleButton } from "components/UI/ToggleButton";
import { ToggleButtonList } from "components/UI/ToggleButtonList";

import { TooltipLabel } from "components/UI/TooltipLabel";
import { isEmailConfigured } from "components/Communications/utils";

interface Props {
  emailConfig: EmailConfigVO;
  channels: AutomationActionVO["channels"];
  onChannelsChange: (newChannels: AutomationActionVO["channels"]) => void;
}

export const ChannelsFields: FC<Props> = ({ channels, onChannelsChange, emailConfig }) => {
  const canSendEmail = isEmailConfigured(emailConfig);

  return (
    <div>
      <FormFieldLabel className="text-xs font-sansSemiBold mb-1" required content="Channel" />
      <div className="flex items-center gap-x-3">
        <ToggleButton
          shape="mediumPill"
          disabled={!canSendEmail}
          checked={channels.includes("PATIENT_PREFERRED")}
          type="checkbox"
          onChange={() => onChannelsChange(["PATIENT_PREFERRED"])}
        >
          <TooltipLabel
            tooltip={{
              content:
                "This option will respect the patient's preferred contact method and send only a SMS or an Email",
            }}
            theme="slate700"
            disabled={!canSendEmail}
          >
            Preferred
          </TooltipLabel>
        </ToggleButton>
        <VerticalDivider size="lg" />
        <ToggleButtonList
          shape="mediumPill"
          selectedValues={new Set(channels.filter((channel) => channel !== "PATIENT_PREFERRED"))}
          onChange={(newSet) => onChannelsChange(newSet.size === 0 ? ["PATIENT_PREFERRED"] : [...newSet])}
          type="checkbox"
          options={[
            { value: "SMS", label: "SMS" },
            { value: "EMAIL", disabled: !canSendEmail, label: "Email" },
          ]}
        />
      </div>
    </div>
  );
};
