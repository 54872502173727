import React from "react";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { ImagingDeviceWithSettings } from "components/PatientProfile/Imaging/hooks/useImagingDevices";

type Props = {
  value?: string;
  onChange: (sensor?: string) => void;
  devices: ImagingDeviceWithSettings[];
};
export const SelectSensor: React.FC<Props> = ({ value, onChange, devices }) => {
  const options = React.useMemo(() => {
    return devices.map((device) => {
      return {
        value: device.settings.label,
        label: `${device.settings.overriddenLabel ?? device.label}${
          device.settings.calibration ? "" : " (Uncalibrated)"
        }`,
      };
    });
  }, [devices]);

  return (
    <FormFieldSelect
      options={options}
      isDark
      value={value}
      placeholder="Select a sensor"
      className="z-30"
      isClearable={false}
      isSearchable={false}
      // When opening in the lower right side of the screen, the menu will create a window scroll unless we set this to "fixed"
      // because it's always within a ButtonMenu
      menuPosition="fixed"
      closeMenuOnSelect
      onChange={(option) => {
        onChange(option?.value);
      }}
    />
  );
};
