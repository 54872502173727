import React, { useCallback, useId, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { produce } from "immer";
import { UpdatePatientRequest } from "@libs/api/generated-api";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { PatientInformationPanel } from "components/PatientProfile/PatientOverview/Info/PatientInformationPanel";
import { updatePatient } from "api/patients/mutations";
import { usePathParams } from "hooks/usePathParams";
import { handleError } from "utils/handleError";
import { getFamilyMembersV2Query, getPatientQuery } from "api/patients/queries";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { getOnboardingOptions } from "api/forms/queries";
import { PatientInformationForm } from "components/PatientProfile/PatientOverview/Info/PatientInformationForm";
import { paths } from "utils/routing/paths";
import { Prompt } from "components/UI/Prompt";

export const PatientInformationEditRoute: React.FC = () => {
  const { patientId } = usePathParams("editPatient");
  const { practiceId } = useAccount();
  const isDirtyRef = useRef(false);
  const navigate = useNavigate();
  const [patientQuery, familyMembersQuery, providersQuery, onboardingOptionsQuery] = useApiQueries([
    getPatientQuery({ args: { patientId, practiceId, includeContactPatientDetails: true } }),
    getFamilyMembersV2Query({
      args: { patientId, practiceId },
    }),
    getPracticeProvidersQuery({ args: { practiceId } }),
    getOnboardingOptions({ args: { practiceId } }),
  ]);
  const from = paths.patient({ patientId });

  const [updatePatientMutation] = useApiMutations([updatePatient]);
  const handleSave = useCallback(
    (update: UpdatePatientRequest) => {
      const data = produce(update, (draft) => {
        if (patientQuery.data) {
          // The patient image profile could have been updated while this page was being
          // viewed and we want to make sure we don't overwrite the update with
          // what we had in draft.
          draft.personalDetails.thumbnail = patientQuery.data.personalDetails.thumbnail;

          // If the patient has a contact person ignore edits made to contactModes
          // because it's possible the left it in a state that's invalid but doesn't
          // matter because they are using a contact.
          if (draft.contactDetails.contactPatientId) {
            draft.contactDetails.contactModes = patientQuery.data.contactDetails.contactModes;
            draft.contactDetails.preferredContactMode = patientQuery.data.contactDetails.preferredContactMode;
          }
        }
      });

      updatePatientMutation.mutate(
        {
          practiceId,
          patientId,
          data,
        },
        {
          onSuccess: () => {
            isDirtyRef.current = false;
            navigate(from, { replace: true });
          },
          onError: handleError,
        }
      );
    },
    [patientId, practiceId, updatePatientMutation, navigate, from, patientQuery.data]
  );

  const handleDirty = useCallback(() => {
    isDirtyRef.current = true;
  }, []);

  const patientInformationFormId = useId();

  return (
    <>
      <PatientInformationPanel
        isEditing={true}
        isSaving={updatePatientMutation.isLoading}
        patientId={patientId}
        formId={patientInformationFormId}
      >
        <QueryResult queries={[patientQuery, familyMembersQuery, providersQuery, onboardingOptionsQuery]}>
          {patientQuery.data &&
          familyMembersQuery.data &&
          providersQuery.data &&
          onboardingOptionsQuery.data ? (
            <PatientInformationForm
              // if we switch from one profile to another
              // we need to re-mount to force state to use
              // new server data
              key={patientId}
              familyMembers={familyMembersQuery.data}
              formId={patientInformationFormId}
              isEditing={true}
              onDirty={handleDirty}
              onSave={handleSave}
              patient={patientQuery.data}
              onboarding={onboardingOptionsQuery.data}
              providers={providersQuery.data}
            />
          ) : null}
        </QueryResult>
      </PatientInformationPanel>
      <Prompt
        message="Do you want to discard changes to this patient?"
        shouldBlock={({ currentLocation, nextLocation }) =>
          isDirtyRef.current && currentLocation.pathname !== nextLocation.pathname
        }
      />
    </>
  );
};
