import { FC } from "react";
import { OnboardEmployeeRequest } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { MainContent } from "@libs/components/UI/MainContent";
import { OnboardingWizard } from "components/Onboarding/OnboardingWizard";
import { getEmployee } from "api/employee/queries";
import { getProfileImageQuery } from "api/user/queries";
import { getPracticeProfileImage } from "api/practice/queries";
import { NotFoundErrorBoundary } from "components/Main/NotFoundErrorBoundary";
import { NotFound } from "components/NotFoundRoute";
import { paths } from "utils/routing/paths";
import { onboardEmployee } from "api/employee/mutations";
import { handleError } from "utils/handleError";
import { AppLayout } from "components/Main/AppLayout";
import { ProfileMenu } from "components/Main/ProfileMenu";
import { PracticeMenu } from "components/Main/PracticeMenu";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { isMigrationRole } from "components/Roles/roleUtils";

const recoverInfo = { url: paths.onboarding(), label: "Return to Onboarding" };

export const OnboardingRoute: FC = () => {
  const { practiceId, id: userId } = useAccount();
  const currentUser = useCurrentUser();
  const practice = useCurrentPractice();
  const [employeeQuery, profileImageQuery, practiceProfileImageQuery] = useApiQueries([
    getEmployee({
      args: { practiceId, employeeId: userId },
    }),
    getProfileImageQuery({ args: { userId, practiceId } }),
    getPracticeProfileImage({ args: { practiceId } }),
  ]);
  const [onboardEmployeeMutation] = useApiMutations([onboardEmployee]);
  const completeOnboarding = (draft: OnboardEmployeeRequest) => {
    onboardEmployeeMutation.mutate(
      { practiceId, employeeId: userId, data: draft },
      {
        onError: handleError,
      }
    );
  };

  // We watch to show a loading symbol while the onboarding api call is in
  // progress and until the employee query is done re-fetching
  const isCompleting =
    onboardEmployeeMutation.isLoading || (onboardEmployeeMutation.isSuccess && employeeQuery.isLoading);

  usePageTitle("Onboarding");

  return (
    <AppLayout
      nav={
        <div className="h-full flex flex-col justify-between">
          <PracticeMenu isOnboarding practiceLogo={practice.logo?.url} practiceName={practice.name} />
          <ProfileMenu
            isOnboarding
            isMigrationRole={isMigrationRole(currentUser.roleV2)}
            name={currentUser.name.shortDisplayName}
            profileImage={profileImageQuery.data}
          />
        </div>
      }
    >
      <NotFoundErrorBoundary notFoundContent={<NotFound recover={recoverInfo} />}>
        <QueryResult nonCriticalQueries={[employeeQuery, practiceProfileImageQuery]}>
          <MainContent>
            {employeeQuery.data ? (
              <OnboardingWizard
                isCompletingOnboarding={isCompleting}
                employee={employeeQuery.data}
                practice={practice}
                practiceProfileImage={practiceProfileImageQuery.data}
                onCompleteOnboarding={completeOnboarding}
              />
            ) : null}
          </MainContent>
        </QueryResult>
      </NotFoundErrorBoundary>
    </AppLayout>
  );
};
