import { Fragment, forwardRef } from "react";
import { format } from "date-fns";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatISODate } from "@libs/utils/date";
import { TableGrid, Row } from "@libs/components/UI/GridTableComponents";
import { PrintRow } from "components/DailyHuddle/PrintRow";
import { HuddleEntryGroupsByDate } from "components/DailyHuddle/types";

const headers = [
  { id: "appt", label: "Appt", width: "3fr" },
  { id: "details", label: "Details", width: "2fr" },
  { id: "insurance", label: "Insurance", width: "1fr" },
];

export const cxStyles = {
  bottomBorder: "border-b border-greyLighter",
  cell: "flex flex-col py-2",
  horizontalPadding: "px-3",
};

export const Print = forwardRef<
  HTMLDivElement | null,
  {
    entryGroupsByDate: HuddleEntryGroupsByDate;
    practice: PracticeInfoVO;
    excludedTagIds: number[];
  }
>(({ entryGroupsByDate, practice, excludedTagIds }, ref) => {
  return (
    <div className="flex flex-col" ref={ref}>
      <div className="flex items-center gap-x-2">
        <div
          className={`
            flex
            items-center
            justify-center
            h-6
            w-6
            border
            border-greyLightest
            rounded
            shadow-main
          `}
        >
          <img alt={practice.name} className="h-5 w-5" src={practice.logo?.url} />
        </div>
        <div className="text-xs font-sansSemiBold">{practice.name}</div>
      </div>
      <div className="mt-8 mb-2 text-xxs font-sansSemiBold">
        Appointment list as of {format(new Date(), "MM/dd/yyyy - h:mma")}
      </div>
      <TableGrid className="text-xxxs" columnWidths={headers.map(({ width }) => width)}>
        {headers.map((header) => (
          <div
            className={cx(
              "flex items-center h-9 py-3 font-sansSemiBold bg-white",
              cxStyles.horizontalPadding,
              cxStyles.bottomBorder
            )}
            key={header.id}
          >
            {header.label}
          </div>
        ))}
        {Object.keys(entryGroupsByDate).map((date) => (
          <Fragment key={date}>
            <Row>
              <div
                className={cx(
                  "col-span-full py-1.5 bg-whiteLight",
                  cxStyles.horizontalPadding,
                  cxStyles.bottomBorder
                )}
              >
                {formatISODate(date, "LLL d, yyyy")}
              </div>
            </Row>
            {entryGroupsByDate[date].map((entry) => (
              <PrintRow excludedTagIds={excludedTagIds} entry={entry} key={entry.appointmentId} />
            ))}
          </Fragment>
        ))}
      </TableGrid>
    </div>
  );
});
