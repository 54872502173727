import { FC, Dispatch, SetStateAction, useCallback } from "react";
import { produce } from "immer";

import { Button } from "@libs/components/UI/Button";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as PlusCircleIcon } from "@libs/assets/icons/plus-circle.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isDefined } from "@libs/utils/types";

import { FormFieldCurrencyInput } from "components/UI/FormFieldCurrencyInput";

import {
  UpdatePatientProcedureFeeDraft,
  UpdatePatientProcedureFeeDraftValidationResult,
} from "components/Charting/patientProcedureUtils";

interface Props {
  patientProcedureFeeDraft: UpdatePatientProcedureFeeDraft;
  onUpdateFeeDraft: Dispatch<SetStateAction<UpdatePatientProcedureFeeDraft>>;
  validationResult: UpdatePatientProcedureFeeDraftValidationResult;
}

export const EditEstimatesSecondary: FC<Props> = ({
  patientProcedureFeeDraft,
  onUpdateFeeDraft,
  validationResult,
}) => {
  const addSecondaryEstimates = useBoolean(() =>
    [
      patientProcedureFeeDraft.secondaryInsuranceAmount,
      patientProcedureFeeDraft.secondaryPatientAmount,
      patientProcedureFeeDraft.secondaryDeductibleAmount,
    ].some(isDefined)
  );

  const handleAddSecondaryEstimates = useCallback(() => {
    onUpdateFeeDraft((last) =>
      produce(last, (draft) => {
        draft.secondaryInsuranceAmount = "0";
        draft.secondaryPatientAmount = "0";
        draft.secondaryDeductibleAmount = "0";
      })
    );

    addSecondaryEstimates.on();
  }, [onUpdateFeeDraft, addSecondaryEstimates]);

  const handleRemoveSecondaryEstimates = useCallback(() => {
    onUpdateFeeDraft((last) =>
      produce(last, (draft) => {
        draft.secondaryInsuranceAmount = undefined;
        draft.secondaryPatientAmount = undefined;
        draft.secondaryDeductibleAmount = undefined;
      })
    );

    addSecondaryEstimates.off();
  }, [onUpdateFeeDraft, addSecondaryEstimates]);

  return (
    <div className="flex flex-col gap-y-6">
      <span className="font-sansSemiBold text-sm">Secondary</span>

      {addSecondaryEstimates.isOff ? (
        <Button
          className="flex items-center gap-x-1 text-xs"
          onClick={handleAddSecondaryEstimates}
          theme="link"
        >
          <Icon SvgIcon={PlusCircleIcon} />
          Add Secondary Estimates
        </Button>
      ) : (
        <>
          <div className="grid grid-cols-2 gap-6">
            <FormFieldCurrencyInput
              label="Insurance Estimate"
              aria-label="Secondary Insurance Estimate"
              value={patientProcedureFeeDraft.secondaryInsuranceAmount}
              onValueChange={(value) =>
                onUpdateFeeDraft((last) =>
                  produce(last, (draft) => {
                    draft.secondaryInsuranceAmount = value;
                  })
                )
              }
              error={validationResult.secondaryInsuranceAmount.$error}
              required
            />

            <FormFieldCurrencyInput
              label="Patient Estimate"
              aria-label="Secondary Patient Estimate"
              value={patientProcedureFeeDraft.secondaryPatientAmount}
              onValueChange={(value) =>
                onUpdateFeeDraft((last) =>
                  produce(last, (draft) => {
                    draft.secondaryPatientAmount = value;
                  })
                )
              }
              error={validationResult.secondaryPatientAmount.$error}
              required
            />

            <FormFieldCurrencyInput
              label="Deductible"
              aria-label="Secondary Deductible"
              value={patientProcedureFeeDraft.secondaryDeductibleAmount}
              onValueChange={(value) =>
                onUpdateFeeDraft((last) =>
                  produce(last, (draft) => {
                    draft.secondaryDeductibleAmount = value;
                  })
                )
              }
              error={validationResult.secondaryDeductibleAmount.$error}
              required
            />
          </div>

          <Button
            className="flex items-center gap-x-1 text-xs w-fit"
            onClick={handleRemoveSecondaryEstimates}
            theme="link"
          >
            <Icon SvgIcon={DeleteIcon} />
            Remove Secondary Estimates
          </Button>
        </>
      )}
    </div>
  );
};
