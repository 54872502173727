import React from "react";
import { MediaPermissionsState, MediaPermissionsStatus, useMediaPermissions } from "utils/useMediaPermission";
import { UseCameraStream } from "./useCameraStream";
import { UseCameraSources, useCameraSources } from "./useCameraSource";
import { UseCameraCaptureImage } from "./useCaptureCameraImage";

export type UseCameraCaptureState = {
  permissionState: MediaPermissionsState;
  cameraSources?: UseCameraSources & { selectedDeviceId: string; selectDeviceId: (newId: string) => void };
  cameraStream?: UseCameraStream & { cancelStream: Func; requestStream: Func; toggleStream: Func };
  captureCameraImage?: UseCameraCaptureImage;
};

/*
  useCameraCapture returns all of the interface required for getting user camera permission, selecting a user camera device,
  streaming the camera into a video element, and capturing snapshot images of the camera stream.

  The basic flow is:
  - user grants device permissions
  - user selects a device to stream
  - user request to start streaming the device
  - user captures images


  The hooks guide you through this flow by return more fields as the user gets through the process:
  - On mount (when useCameraCapture is first called) permissions are requested. Until camera permissions are granted
    you only get the permissionsState property. This will tell you the state of the device permissions. To get to the next step the user
    has to grant permissions via the browser prompts.
  - Once permissions are granted the camera sources will be automatically fetched and you will have access to the cameraSources field.
    This provides you with the ui states and method calls for managing a UI for loading and choosing a camera source.
  - Once camera sources have been returned, you will get the cameraStream field. This provides you with the ui states and method calls
    for managing a UI for loading and displaying a camera stream. To get to the next step the user must select a deviceId to stream
    by calling camerSources.selectDeviceId.
  - Once you have a stream you will get the captureCameraImage field. This provides you with the ui states and method calls
    for managing a UI for capturing images and displaying the capture.

*/

const videoPermission = { video: true };

export function useCameraCapture(): UseCameraCaptureState {
  const [deviceId, setDeviceId] = React.useState("");
  const permissionState = useMediaPermissions(videoPermission);
  const cameraSources = useCameraSources(permissionState);

  if (permissionState.status !== MediaPermissionsStatus.granted) {
    return {
      permissionState,
    };
  }

  const cameraSourcesFeature = {
    ...cameraSources,
    selectedDeviceId: deviceId,
    selectDeviceId: (newId: string) => {
      setDeviceId(newId);
    },
  };

  if (!cameraSources.sources) {
    return {
      cameraSources: cameraSourcesFeature,
      permissionState,
    };
  }

  return {
    cameraSources: cameraSourcesFeature,
    permissionState,
  };
}
