import { FC, PropsWithChildren } from "react";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { AutomationActionValidation } from "components/Communications/Automations/utils";

interface Props {
  validation: AutomationActionValidation;
}

export const TimingField: FC<PropsWithChildren<Props>> = ({ validation, children }) => {
  const hasTimingError =
    validation.sendTime.$isValid === false ||
    validation.interval.$isValid === false ||
    validation.intervalType.$isValid === false;

  return (
    <div>
      <FormFieldLabel
        className="text-xs font-sansSemiBold mb-1"
        content="Timing"
        required
        error={hasTimingError ? "error" : ""}
      />
      {children}
      {hasTimingError ? <FormFieldError className="mt-1">Timing is required</FormFieldError> : null}
    </div>
  );
};
