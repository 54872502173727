import { Route, Navigate } from "react-router-dom";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { NotFound } from "components/NotFoundRoute";
import { paths } from "utils/routing/paths";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";
import { PatientAppHistoryProvider } from "contexts/PatientLinksContext";
import { PatientInsurancePlansRoute } from "components/PatientProfile/Insurance/InsurancePlans/PatientInsurancePlansRoute";
import { PatientInsurancePlanDetailsRoute } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/PatientInsurancePlanDetailsRoute";

const ForwardToInfoStep = () => {
  const { patientId, insuranceId } = usePathParams("patientInsuranceDetails");
  const {
    query: { from },
  } = useQueryParams("patientInsuranceDetails");

  return (
    <Navigate
      replace
      to={paths.patientInsuranceStep(
        {
          step: "info",
          patientId,
          insuranceId,
        },
        from ? { from } : undefined
      )}
    />
  );
};

export const PatientInsurancePage = () => {
  const { patientId } = usePathParams("patientTab");

  return (
    <PatientAppHistoryProvider patientId={patientId} name="insurance">
      <SentryRoutes>
        <Route index element={<PatientInsurancePlansRoute />} />
        <Route path=":insuranceId" element={<ForwardToInfoStep />} />
        <Route path=":insuranceId/*" element={<PatientInsurancePlanDetailsRoute />} />
        <Route path="*" element={<NotFound />} />
      </SentryRoutes>
    </PatientAppHistoryProvider>
  );
};
