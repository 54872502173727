import React from "react";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "@libs/hooks/useBoolean";
import { formatAsISODate } from "@libs/utils/date";
import { Modal } from "@libs/components/UI/Modal";
import { ModalCloseButton, ModalContent } from "@libs/components/UI/ModalComponents";
import { CalibrationSensorCapture } from "components/ImageCapturing/Calibration/CalibrationSensorCapture";
import { CalibrationCameraCapture } from "components/ImageCapturing/Calibration/CalibrationCameraCapture";
import { ImagingSensorCalibrationDrawing } from "components/ImageCapturing/Calibration/ImagingSensorCalibrationDrawing";
import { ReactComponent as SuccessRibbonIcon } from "assets/components/success-ribbon.svg";
import { useImagingDevices } from "components/PatientProfile/Imaging/hooks/useImagingDevices";
import { useCameraCapture } from "components/ImageCapturing/useCameraCapture";

type Props = {
  deviceId: string;
  from: string;
};

export const ImagingSensorCalibration: React.FC<Props> = ({ deviceId, from }) => {
  const navigate = useNavigate();
  const cameraCapture = useCameraCapture();

  const {
    devices: allDevices,
    saveAllSettings,
    handleDeviceSettingsUpdated,
  } = useImagingDevices(cameraCapture);

  const calibrationCompleted = useBoolean(false);
  const calibrationCompletedOn = calibrationCompleted.on;
  const [imageCaptured, setImageCaptured] = React.useState<
    { url: string; w: number; h: number; cleanup?: Func } | undefined
  >();
  const deviceBeingCalibrated = React.useMemo(
    () =>
      allDevices.find((item) =>
        item.type === "sensor" || item.type === "archy-sensor"
          ? item.label === deviceId
          : item.deviceId === deviceId
      ),
    [allDevices, deviceId]
  );
  const handleDistanceSaved = React.useCallback(
    (pixelsPerMM: number) => {
      if (!deviceBeingCalibrated) {
        return;
      }

      const updatedSettings = handleDeviceSettingsUpdated({
        ...deviceBeingCalibrated,
        settings: {
          ...deviceBeingCalibrated.settings,
          calibration: {
            pixelsPerMM,
            date: formatAsISODate(new Date()),
          },
        },
      });

      saveAllSettings(updatedSettings);

      calibrationCompletedOn();
    },
    [calibrationCompletedOn, deviceBeingCalibrated, handleDeviceSettingsUpdated, saveAllSettings]
  );

  React.useEffect(() => {
    return () => {
      if (imageCaptured?.cleanup) {
        imageCaptured.cleanup();
      }
    };
  }, [imageCaptured]);

  if (!deviceBeingCalibrated) {
    return null;
  }

  const captureProps = {
    from,
    onImageCaptured: setImageCaptured,
  };

  return (
    <div className="flex flex-1 flex-col text-white relative">
      {imageCaptured ? (
        <ImagingSensorCalibrationDrawing
          image={imageCaptured}
          onClose={() => setImageCaptured(undefined)}
          onSave={handleDistanceSaved}
        />
      ) : deviceBeingCalibrated.type === "archy-sensor" || deviceBeingCalibrated.type === "sensor" ? (
        <CalibrationSensorCapture device={deviceBeingCalibrated} {...captureProps} />
      ) : (
        <CalibrationCameraCapture device={deviceBeingCalibrated} {...captureProps} />
      )}
      {calibrationCompleted.isOn && (
        <Modal size="2xs">
          <div className="pt-6 pr-8 flex justify-end">
            <ModalCloseButton
              onClick={() => {
                navigate(from, { replace: true });
              }}
            />
          </div>
          <ModalContent
            className={`
              flex
              flex-col
              text-center
              items-center
              justify-center
              pb-10
              pt-5
            `}
          >
            <SuccessRibbonIcon />
            <div className="font-sansSemiBold">Sensor calibration is complete!</div>
          </ModalContent>
        </Modal>
      )}
    </div>
  );
};
