import { FC, useId, useRef, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { AvailableTwilioPhoneNumberVO } from "@libs/api/generated-api";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useAccount } from "@libs/contexts/AccountContext";
import { useApiMutations } from "@libs/hooks/useApiMutations";

import { ModalPage } from "components/UI/ModalPage";
import { SetupTwilioProgress } from "components/Settings/Communications/MessagingService/SetupTwilioProgress";
import { PhoneNumberForm } from "components/Settings/Communications/MessagingService/PhoneNumberForm";

import { purchaseTwilioPhoneNumber } from "api/communications/mutations";
import { handleError } from "utils/handleError";
import { paths } from "utils/routing/paths";
import { usePracticeLogger } from "components/Main/PracticeSegmentContext";

interface Props {
  availablePhoneNumbers: AvailableTwilioPhoneNumberVO[] | undefined;
  onSearchPhoneNumbers: (value: string) => void;
  hasInitiated: boolean;
  hasPhoneNumber: boolean;
  isSearching: boolean;
  isPhoneOnly: boolean;
  from: string;
}

export const SetupTwilioPhone: FC<Props> = ({
  availablePhoneNumbers,
  onSearchPhoneNumbers,
  hasInitiated,
  hasPhoneNumber,
  isSearching,
  isPhoneOnly,
  from,
}) => {
  const navigate = useNavigate();
  const { track } = usePracticeLogger();
  const { practiceId } = useAccount();
  const formId = useId();
  const formContainerRef = useRef<HTMLDivElement>(null);

  const navigateToSetupPractice = useCallback(() => {
    navigate(paths.setupTwilio({ step: "practice" }, { from }), { replace: true });
  }, [navigate, from]);

  const [purchaseTwilioPhoneNumberMutation] = useApiMutations([purchaseTwilioPhoneNumber]);
  const purchaseTwilioPhoneNumberMutate = purchaseTwilioPhoneNumberMutation.mutate;

  const handleSubmit = useCallback(
    (phoneNumber: string) => {
      purchaseTwilioPhoneNumberMutate(
        { practiceId, data: { phoneNumberToPurchase: phoneNumber } },
        {
          onSuccess: () => {
            track({ event: "Twilio Phone Number Purchased", domains: ["Settings"] });
            navigate(from, { replace: true });
          },
          onError: handleError,
        }
      );
    },
    [purchaseTwilioPhoneNumberMutate, practiceId, track, navigate, from]
  );

  return (
    <ModalPage
      title={<span className="font-sansSemiBold">Number Selection</span>}
      actions={
        <div className="flex items-center justify-center gap-x-2">
          {isPhoneOnly ? (
            <ButtonInternalLink className="min-w-button" to={from} theme="secondary" replace>
              Cancel
            </ButtonInternalLink>
          ) : (
            <Button className="min-w-button" onClick={navigateToSetupPractice} theme="secondary">
              Previous
            </Button>
          )}
          <AsyncButton
            className="min-w-button"
            isLoading={purchaseTwilioPhoneNumberMutation.isLoading}
            inactive={!availablePhoneNumbers}
            disabled={hasPhoneNumber}
            form={formId}
            type="submit"
          >
            Save
          </AsyncButton>
        </div>
      }
      closeLink={from}
    >
      <div ref={formContainerRef} className="h-full p-6 overflow-y-auto">
        <div className="flex flex-col gap-y-6 max-w-xl mx-auto">
          {isPhoneOnly ? null : (
            <SetupTwilioProgress step="phone" hasInitiated={hasInitiated} hasPhoneNumber={hasPhoneNumber} />
          )}
          <PhoneNumberForm
            formId={formId}
            formContainerRef={formContainerRef}
            availablePhoneNumbers={availablePhoneNumbers}
            onSearchPhoneNumbers={onSearchPhoneNumbers}
            onSubmit={handleSubmit}
            hasPhoneNumber={hasPhoneNumber}
            isSearching={isSearching}
          />
        </div>
      </div>
    </ModalPage>
  );
};
