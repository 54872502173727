import { FC, PropsWithChildren, ReactNode } from "react";
import Skeleton from "react-loading-skeleton";
import { AppointmentAdjustmentVO, CustomAdjustmentTypeVO, InvoiceEntryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatCurrency } from "@libs/utils/currency";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle-filled.svg";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { FooterCell, HeaderCell, Row, TableGrid, TextCell } from "@libs/components/UI/GridTableComponents";
import { numberToNth } from "utils/words";
import {
  cxStylesInvoiceBanner,
  getPracticeAdjustmentById,
  getTotalsToShow,
} from "components/PatientProfile/Billing/invoiceUtils";
import { AdjustmentRequestWithDisplayValue } from "./InvoiceAdjustmentFormRow";

export const cxStyles = {
  amountCell: "text-right justify-end",
};

const cellVerticalPadding = "skinny" as const;

export const EntryTable: FC<
  PropsWithChildren & { entry: InvoiceEntryVO | undefined; calculatedAdjustments: AppointmentAdjustmentVO[] }
> = ({ entry, calculatedAdjustments, children }) => {
  return (
    <TableGrid
      className="border rounded border-greyLighter"
      columnWidths={["auto", "auto", "1fr", "auto", ...calculatedAdjustments.map(() => "auto"), "auto"]}
    >
      <HeaderRow adjustments={calculatedAdjustments} />
      {children}
      <TotalsRow
        charges={entry?.subtotalAmount}
        discounts={entry?.discountAmount}
        additionalCharges={entry?.surchargeAmount}
        total={entry?.amount}
      />
    </TableGrid>
  );
};

const HeaderRow: FC<{ adjustments: AppointmentAdjustmentVO[] }> = ({ adjustments }) => {
  return (
    <Row>
      <HeaderCell size="slim" bgColor="bg-slate-50 rounded-tl">
        Date
      </HeaderCell>
      <HeaderCell size="slim" bgColor="bg-slate-50">
        Type
      </HeaderCell>
      <HeaderCell size="slim" bgColor="bg-slate-50">
        Description
      </HeaderCell>
      <HeaderCell size="slim" bgColor="bg-slate-50" className={cx(cxStyles.amountCell)}>
        Charges
      </HeaderCell>
      {adjustments.map((_, adjustmentNumber) => (
        <HeaderCell
          key={`adjust-header-${adjustmentNumber}`}
          size="slim"
          bgColor="bg-slate-50"
          className={cx(cxStyles.amountCell)}
        >
          {adjustments.length > 1 ? numberToNth(adjustmentNumber + 1) : null} Adjust
        </HeaderCell>
      ))}
      <HeaderCell size="slim" bgColor="bg-slate-50 rounded-tr" className={cx(cxStyles.amountCell)}>
        Amount
      </HeaderCell>
    </Row>
  );
};

export const EntryRow: FC<{
  className?: string;
  date: ReactNode;
  type: ReactNode;
  description: ReactNode;
  charges: number;
  adjustments: number[];
  calculatedAmount: number | undefined;
}> = ({ date, type, description, charges, adjustments, calculatedAmount, className }) => {
  return (
    <Row highlightOnHover={false} className={className}>
      <TextCell verticalPadding={cellVerticalPadding} border={false}>
        {date}
      </TextCell>
      <TextCell verticalPadding={cellVerticalPadding} border={false}>
        {type}
      </TextCell>
      <TextCell verticalPadding={cellVerticalPadding} border={false}>
        {description}
      </TextCell>
      <TextCell verticalPadding={cellVerticalPadding} border={false} className={cxStyles.amountCell}>
        {formatCurrency(charges)}
      </TextCell>
      {adjustments.map((amount, i) => (
        <TextCell
          verticalPadding={cellVerticalPadding}
          key={`adjust-${i}`}
          border={false}
          className={cxStyles.amountCell}
        >
          {formatCurrency(amount)}
        </TextCell>
      ))}
      <TextCell verticalPadding={cellVerticalPadding} border={false} className={cxStyles.amountCell}>
        {calculatedAmount == null ? <Skeleton /> : formatCurrency(calculatedAmount)}
      </TextCell>
    </Row>
  );
};

export const getStyleForDescriptionCell = (spanAmount: number) => {
  const standardColumnsAfterDescription = 2;
  const colSpanAmount = standardColumnsAfterDescription + spanAmount;

  return { gridColumn: `span ${colSpanAmount} / span ${colSpanAmount}` };
};

export const AdjustmentRow: FC<{
  adjustmentName: string;
  adjustmentValue: string;
  totalAdjustments: number;
  isFirstAdjustment: boolean;
  isLastAdjustment: boolean;
}> = ({ adjustmentName, adjustmentValue, totalAdjustments, isFirstAdjustment, isLastAdjustment }) => {
  const descriptionStyle = getStyleForDescriptionCell(totalAdjustments + 1);

  return (
    <Row highlightOnHover={false}>
      {/* Date (dummy)  */}
      <div />

      {/* Type  */}
      <TextCell
        verticalPadding={cellVerticalPadding}
        border={false}
        className={cx("font-sansSemiBold", isFirstAdjustment && "mt-2", isLastAdjustment && "mb-2")}
      >
        Adjust
      </TextCell>

      {/* Description  */}
      <TextCell
        className={cx(isFirstAdjustment && "mt-2", isLastAdjustment && "mb-2")}
        style={descriptionStyle}
        verticalPadding={cellVerticalPadding}
        border={false}
      >
        {adjustmentName}: {adjustmentValue}
      </TextCell>
    </Row>
  );
};

export const RemovableAdjustmentRow: FC<{
  adjustment: AdjustmentRequestWithDisplayValue;
  practiceAdjustments?: CustomAdjustmentTypeVO[];
  numOfAdjustments: number;
  onRemoveAdjustment: Func;
}> = ({ adjustment, practiceAdjustments, numOfAdjustments, onRemoveAdjustment }) => {
  const descriptionStyle = getStyleForDescriptionCell(numOfAdjustments);
  const practiceAdjustment = getPracticeAdjustmentById(
    adjustment.customAdjustmentTypeId,
    practiceAdjustments ?? []
  );

  return (
    <Row highlightOnHover={false}>
      {/* Date (dummy)  */}
      <div />

      {/* Type */}
      <TextCell
        border={false}
        className={cx(
          "font-sansSemiBold my-1",
          cxStylesInvoiceBanner.bannerSection,
          cxStylesInvoiceBanner.bannerStart
        )}
      >
        Adjust
      </TextCell>

      {/* Description */}
      <TextCell
        style={descriptionStyle}
        border={false}
        className={cx("my-1", cxStylesInvoiceBanner.bannerSection)}
      >
        {practiceAdjustment?.name}: {adjustment.displayValue}
      </TextCell>

      {/* Close button */}
      <TextCell
        border={false}
        className={cx(
          "flex justify-end items-center my-1",
          cxStylesInvoiceBanner.bannerSection,
          cxStylesInvoiceBanner.bannerEnd
        )}
      >
        <ButtonIcon size="sm" SvgIcon={CancelIcon} onClick={() => onRemoveAdjustment()} />
      </TextCell>
    </Row>
  );
};

export const AddAdjustmentButtonRow: FC<{ onNewAdjustmentClick: Func; disabled: boolean }> = ({
  disabled,
  onNewAdjustmentClick,
}) => {
  return (
    <Row highlightOnHover={false}>
      {/* Date (dummy) */}
      <div />
      <TextCell className="col-span-2" border={false}>
        <Button
          disabled={disabled}
          theme="link"
          className="flex items-center gap-x-2 text-sm"
          onClick={onNewAdjustmentClick}
        >
          <AddIcon className="w-5 h-5" /> Add an Adjustment
        </Button>
      </TextCell>
    </Row>
  );
};

export type InvoiceEntryTotals = {
  charges: number | undefined;
  discounts: number | undefined;
  additionalCharges: number | undefined;
  total: number | undefined;
};

const TotalsRow: FC<InvoiceEntryTotals> = (totals) => {
  const totalsToShow = getTotalsToShow(totals);

  return (
    <Row>
      <FooterCell
        borderTop={false}
        size="medium"
        className="col-span-full mt-1 rounded-b flex gap-x-4 justify-end"
      >
        {totalsToShow.showCharges ? (
          <TotalItem label="Charges">{formatCurrency(totals.charges as number)}</TotalItem>
        ) : null}
        {totalsToShow.showMinus ? <span>-</span> : null}
        {totalsToShow.showDiscounts ? (
          <TotalItem label="Discounts">{formatCurrency(totals.discounts as number)}</TotalItem>
        ) : null}
        {totalsToShow.showPlus ? <span>+</span> : null}
        {totalsToShow.showAdditionalCharges ? (
          <TotalItem label="Addtl Charges">{formatCurrency(totals.additionalCharges as number)}</TotalItem>
        ) : null}
        {totalsToShow.showEqual ? <span>=</span> : null}
        {totalsToShow.showTotal ? (
          <TotalItem label="Total">{formatCurrency(totals.total as number)}</TotalItem>
        ) : null}
      </FooterCell>
    </Row>
  );
};

const TotalItem: FC<PropsWithChildren<{ label: ReactNode }>> = ({ label, children }) => {
  return (
    <div className="flex gap-x-2 items-center">
      <span className="font-sans text-xs">{label}</span>
      <span className="font-sansSemiBold text-base">{children}</span>
    </div>
  );
};

export const InvoiceTotalRow: FC<{ invoiceTotal?: number }> = ({ invoiceTotal }) => {
  return (
    <div
      className={`
        flex
        flex-col
        border
        border-slate-300
        rounded
        bg-slate-50
        gap-1
        p-4
      `}
    >
      <div className="font-sansSemiBold flex gap-4 items-center justify-end">
        <span className="text-sm">Invoice Total</span>
        <span>{invoiceTotal == null ? <Skeleton className="w-20" /> : formatCurrency(invoiceTotal)}</span>
      </div>
    </div>
  );
};
