import { WorkingHourVO } from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { getFullUrl } from "@libs/utils/location";
import { Row } from "@libs/components/UI/GridTableComponents";
import { paths } from "utils/routing/paths";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";

type Props = {
  data: WorkingHourVO;
  last?: boolean;
  formatWorkingSeconds: (seconds: number) => string;
};

export const PayrollHoursTableRow: React.FC<Props> = ({ last, data, formatWorkingSeconds }) => {
  const { employee, worktimeStatus, totalSecondsWorked } = data;
  const cellProps = {
    last,
    to: paths.employeeTimesheet({ id: employee.id }, { from: getFullUrl(location) }),
  };

  return (
    <Row>
      <DashboardLinkCell {...cellProps}>{employee.shortDisplayName}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatWorkingSeconds(totalSecondsWorked)}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{sentenceCaseConstant(worktimeStatus)}</DashboardLinkCell>
    </Row>
  );
};
