import { useCallback, useMemo } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { PatientInsuranceResponse } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { paths } from "utils/routing/paths";
import { PatientInsuranceDetailsStep } from "utils/routing/patientInsurance";
import { InsuranceSubscriberBadge } from "components/Insurance/InsuranceBadges";

interface Props {
  insuranceId: number | "new";
  patientId: number;
  patientInsuranceQuery: ApiQueryResult<PatientInsuranceResponse>;
  selectedTab: PatientInsuranceDetailsStep;
}

interface DetailTab {
  id: PatientInsuranceDetailsStep;
  text: string;
  to: string;
}

const getDisabledTooltip = ({
  insuranceId,
  hasInsurancePlan,
  hasPatientInsurance,
  tabId,
}: {
  insuranceId: number | "new";
  hasInsurancePlan: boolean;
  hasPatientInsurance: boolean;
  tabId: string;
}) => {
  if (insuranceId === "new" && tabId === "carrier" && !hasPatientInsurance) {
    return "Please save subscriber data";
  } else if (["coverage", "limitations"].includes(tabId) && !hasInsurancePlan) {
    return "Please save subscriber & carrier data";
  }

  return "";
};

export const InsurancePlanDetailsTabs: React.FC<Props> = ({
  insuranceId,
  patientId,
  patientInsuranceQuery,
  selectedTab,
}) => {
  const navigate = useNavigate();
  const tabs: DetailTab[] = useMemo(
    () => [
      {
        id: "info",
        text: "Subscriber",
        to: paths.patientInsuranceStep({ insuranceId, patientId, step: "info" }),
      },
      {
        id: "carrier",
        text: "Carrier & Plan",
        to: paths.patientInsuranceStep({ insuranceId, patientId, step: "carrier" }),
      },
      {
        id: "coverage",
        text: "Deductibles, Max & Coverage",
        to: paths.patientInsuranceStep({ insuranceId, patientId, step: "coverage" }),
      },
      {
        id: "limitations",
        text: "Limitations",
        to: paths.patientInsuranceStep({ insuranceId, patientId, step: "limitations" }),
      },
    ],
    [insuranceId, patientId]
  );

  const handleClickLink = useCallback(
    (e: React.MouseEvent<HTMLAnchorElement>, isDisabled: boolean, tab: DetailTab) => {
      if (isDisabled) {
        e.preventDefault();
      } else {
        navigate(tab.to);
      }
    },
    [navigate]
  );

  const { data: patientInsuranceResponse } = patientInsuranceQuery;
  const patientInsurance = patientInsuranceResponse?.patientInsurance;
  const subscriber = patientInsurance?.subscriber;
  const subscriberName = useMemo(() => {
    return subscriber?.firstName && subscriber.lastName
      ? `${subscriber.firstName} ${subscriber.lastName}`
      : "";
  }, [subscriber?.firstName, subscriber?.lastName]);
  const insurancePlan = patientInsuranceResponse?.insurancePlan;
  const groupString = useMemo(() => {
    return insurancePlan?.groupName
      ? `${insurancePlan.groupName}${insurancePlan.groupNumber ? `, ${insurancePlan.groupNumber}` : ""}`
      : "";
  }, [insurancePlan?.groupName, insurancePlan?.groupNumber]);

  return (
    <QueryResult
      loading={
        <div className="h-6 w-full">
          <LoadingContent />
        </div>
      }
      queries={[patientInsuranceQuery]}
    >
      <div className="flex items-center justify-between ml-1 gap-x-2">
        <div className="flex gap-x-6">
          {tabs.map((tab) => {
            const disabledTooltip = getDisabledTooltip({
              insuranceId,
              hasInsurancePlan: Boolean(patientInsuranceResponse?.insurancePlan),
              hasPatientInsurance: Boolean(patientInsuranceResponse),
              tabId: tab.id,
            });
            const isDisabled = Boolean(disabledTooltip);

            return (
              <FloatingTooltip content={disabledTooltip} key={tab.id} placement="bottom">
                <NavLink
                  className={() =>
                    cx(
                      disabledTooltip ? "text-slate-400 cursor-default" : "relative",
                      selectedTab === tab.id && "font-sansSemiBold",
                      !isDisabled &&
                        selectedTab === tab.id &&
                        `
                    text-primaryTheme
                    before:bg-primaryTheme
                    before:rounded
                    before:h-[2px]
                    before:absolute
                    before:left-0
                    before:right-0
                    before:-bottom-4
                  `
                    )
                  }
                  onClick={(e) => handleClickLink(e, isDisabled, tab)}
                  to={tab.to}
                >
                  {tab.text}
                </NavLink>
              </FloatingTooltip>
            );
          })}
        </div>
        {patientInsurance && subscriber && (
          <div className="flex items-center gap-x-2 text-sm">
            <InsuranceSubscriberBadge isPrimary={patientInsurance.type === "PRIMARY_SUBSCRIBER"} size="lg" />
            <span className="font-sansSemiBold">{subscriberName}</span>
            {subscriber.externalMemberId && <span>Subscriber: {subscriber.externalMemberId}</span>}
            {groupString && <span>Group: {groupString}</span>}
          </div>
        )}
      </div>
    </QueryResult>
  );
};
