import { RecallVO } from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";

const RecallTypeAltLabels: Partial<Record<RecallVO["type"], string>> = {
  FOUR_BW: "4BW",
  FMX: "FMX",
};

export const labelForRecallType = (type: RecallVO["type"]) => {
  return type in RecallTypeAltLabels ? (RecallTypeAltLabels[type] as string) : sentenceCaseConstant(type);
};
