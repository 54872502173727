import { FC, useCallback, useMemo, useState } from "react";

import Skeleton from "react-loading-skeleton";
import { Options } from "react-select";
import { PerioChartExamVO } from "@libs/api/generated-api";
import { formatISODate, getLocalDate } from "@libs/utils/date";
import { isDefined } from "@libs/utils/types";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as PrintIcon } from "@libs/assets/icons/send-to-printer.svg";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { useAccount } from "@libs/contexts/AccountContext";
import { Panel } from "@libs/components/UI/Panel";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { RemovablePill } from "@libs/components/UI/RemovablePill";
import { usePathParams } from "hooks/usePathParams";

import { useQueryParams } from "hooks/useQueryParams";
import { getPerioChartExam, getPerioChartExams } from "api/charting/queries";
import { paths } from "utils/routing/paths";
import { ToggleGroup } from "components/UI/ToggleGroup";
import { PerioValueCompareTable } from "components/Charting/Perio/PerioCompare/PerioValueCompareTable";
import { PerioCompareSitesTrendTables } from "components/Charting/Perio/PerioCompare/PerioCompareSitesTrendTables";
import {
  PerioComparePrinted,
  PrintOption,
} from "components/Charting/Perio/PerioCompare/PerioChartComparePrinted";
import { getPatientQuery } from "api/patients/queries";
import { PrintOptionsModal } from "components/Charting/PrintOptionsModal";
import { FormFieldSelect } from "components/UI/FormFieldSelect";

// We may want to make this a more general hook, but for now only this component uses it
const usePrintWizard = <T extends string>(intialSelectedOptions: T[]) => {
  const [printOptions, setPrintOptions] = useState(new Set<T>(intialSelectedOptions));
  const [printWizardState, setPrintWizardState] = useState<null | "PRINT_OPTIONS" | "PRINTING">(null);
  const handleOptionsClosed = useCallback(() => {
    setPrintWizardState(null);
  }, []);
  const handlePrintClick = useCallback(() => {
    setPrintWizardState("PRINT_OPTIONS");
  }, []);
  const handleExecutePrint = useCallback(() => {
    setPrintWizardState("PRINTING");
  }, []);

  return {
    printWizardState,
    handlePrintClick,
    printOptions,
    setPrintOptions,
    handleOptionsClosed,
    handleExecutePrint,
  };
};

const TABS: OptionInputOption<"PROBING" | "GINGMARGIN">[] = [
  {
    value: "PROBING",
    label: "Probing Depth",
  },
  {
    value: "GINGMARGIN",
    label: "Gingival Margin",
  },
];

const PRINT_OPTIONS: OptionInputOption<PrintOption>[] = [
  {
    value: "comparison",
    label: "Comparison",
  },
  {
    value: "sites",
    label: "Sites",
  },
];

const useHistoricalOptions = (
  uuidsSelected: string[],
  exams?: PerioChartExamVO[]
): Options<SelectOption<string>> => {
  return useMemo(() => {
    const uuids = new Set(uuidsSelected);

    return (
      exams
        ?.map((exam) => {
          return {
            label: `${formatISODate(exam.date)} - ${exam.provider.shortDisplayName}`,
            value: exam.uuid,
          };
        })
        .filter((exam) => !uuids.has(exam.value)) ?? []
    );
  }, [exams, uuidsSelected]);
};
const MAX_COMPARE_COUNT = 3;

export const PerioCompareRoute: FC = () => {
  const { practiceId } = useAccount();
  const { patientId } = usePathParams("perioCompare");
  const { query, updateQuery } = useQueryParams("perioCompare");
  const uuids = useMemo(() => query.uuids ?? [], [query.uuids]);
  const handleQueryParamsChanged = useCallback(
    (params: { uuids?: string[]; tab?: "PROBING" | "GINGMARGIN" }) => {
      updateQuery("replaceIn", params);
    },
    [updateQuery]
  );
  const {
    printOptions,
    setPrintOptions,
    handlePrintClick,
    handleOptionsClosed,
    printWizardState,
    handleExecutePrint,
  } = usePrintWizard(["comparison" as const, "sites" as const]);

  const [patientQuery, historicalExamsQuery, ...perioChartQueries] = useApiQueries([
    getPatientQuery({ args: { practiceId, patientId } }),
    getPerioChartExams({ args: { practiceId, patientId } }),
    ...uuids.map((uuid) =>
      getPerioChartExam({
        args: {
          practiceId,
          patientId,
          perioChartExamUuid: uuid,
        },
      })
    ),
  ]);
  const historicalOptions = useHistoricalOptions(uuids, historicalExamsQuery.data);
  const selectedExams = useMemo(() => {
    return historicalExamsQuery.data?.filter((exam) => uuids.includes(exam.uuid)) ?? [];
  }, [historicalExamsQuery.data, uuids]);

  const sortedExams = useMemo(() => {
    return perioChartQueries
      .sort((a, b) => {
        if (a.data && b.data) {
          return getLocalDate(a.data.date).getTime() - getLocalDate(b.data.date).getTime();
        }

        return 0;
      })
      .map(({ data }) => data)
      .filter(isDefined);
  }, [perioChartQueries]);
  const canSelectExams = uuids.length < MAX_COMPARE_COUNT;

  return (
    <>
      <Panel
        title="Comparison"
        className="h-full"
        includePadding={false}
        actions={
          <div className="flex gap-4">
            <ButtonIcon SvgIcon={PrintIcon} theme="primary" onClick={handlePrintClick} />
            <LinkIcon SvgIcon={CloseIcon} theme="primary" to={paths.perio({ patientId })} />
            <div />
          </div>
        }
      >
        <div className="flex justify-center h-full overflow-x-auto">
          <div className="flex flex-col gap-6 h-full w-full max-w-7xl pt-8 px-8">
            <div className="flex row justify-between">
              <ToggleGroup
                size="md"
                options={TABS}
                selectedValue={query.tab}
                onChange={(_e, { value }) => {
                  handleQueryParamsChanged({ tab: value === "PROBING" ? undefined : value });
                }}
                required
              />
              <div className="flex gap-2 items-center">
                {selectedExams.map((exam) => {
                  return (
                    <RemovablePill
                      version="v1"
                      label={formatISODate(exam.date)}
                      key={exam.uuid}
                      onRemove={() => {
                        handleQueryParamsChanged({ uuids: uuids.filter((uuid) => uuid !== exam.uuid) });
                      }}
                    />
                  );
                })}
                {canSelectExams && (
                  <FormFieldSelect
                    options={historicalOptions}
                    className="min-w-44"
                    placeholder="Select up to three exams"
                    onChange={(option) => {
                      if (option) {
                        handleQueryParamsChanged({ uuids: [...uuids, option.value] });
                      }
                    }}
                  />
                )}
              </div>
            </div>
            {uuids.length > 1 ? (
              <QueryResult
                queries={[patientQuery, ...perioChartQueries]}
                loading={<Skeleton containerClassName="flex-1" />}
              >
                <div className="flex flex-col gap-6 min-w-[1200px] pb-8">
                  <PerioValueCompareTable exams={sortedExams} sequenceType={query.tab} />
                  <div className="flex flex-col gap-4">
                    <div className="font-sansSemiBold">Sites</div>
                    <PerioCompareSitesTrendTables exams={sortedExams} sequenceType={query.tab} />
                  </div>
                </div>

                {printWizardState === "PRINTING" && patientQuery.data && (
                  <PerioComparePrinted
                    exams={sortedExams}
                    sequenceType={query.tab}
                    patient={patientQuery.data}
                    printOptions={printOptions}
                    onAfterPrint={handleOptionsClosed}
                  />
                )}
              </QueryResult>
            ) : (
              <div className="text-slate-500 text-center">Please select at least two exams to compare.</div>
            )}
          </div>
        </div>
      </Panel>
      {printWizardState === "PRINT_OPTIONS" && (
        <PrintOptionsModal
          onChange={setPrintOptions}
          onClose={handleOptionsClosed}
          onPrint={handleExecutePrint}
          title="Print Chart"
          options={PRINT_OPTIONS}
          selectedValues={printOptions}
        />
      )}
    </>
  );
};
