import React from "react";

import { ProviderRollupEntryVO, ProviderRollupResponse, WorkingHourResponse } from "@libs/api/generated-api";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { UseInfiniteApiQueryResult, ApiQueryResult } from "@libs/@types/apiQueries";
import { usePick } from "@libs/hooks/usePick";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { PayrollHoursQuery } from "utils/routing/dashboard";

import { FooterCell } from "components/Dashboard/Tables/FooterCell";

import { getTotals } from "components/Dashboard/Tables/utils";
import { DashboardTableFilters } from "components/Dashboard/Tables/DashboardTableFilters";

import { useFilterTokenProps } from "components/Dashboard/hooks/useFilterTokenProps";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import { useFlattenEntries } from "components/Dashboard/hooks/useFlattenEntries";
import { DashboardTableHeader } from "components/Dashboard/Tables/DashboardTableHeader";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";
import { PayrollHoursTableRow } from "components/Dashboard/PayrollHours/PayrollHoursTableRow";
import { EMPLOYEE_PAYROLL_TABLE_ROWS } from "components/Dashboard/PayrollHours/constants";
import { TableSelectProvider } from "components/Dashboard/Tables/TableSelectProvider";
import { ProviderFilterOption } from "components/Dashboard/hooks/useFormattedProviderRollups";
import { SelectDecimalVsClock } from "components/Employee/SelectDecimalVsClock";

export const PayrollHoursTable: React.FC<{
  isFullScreen: boolean;
  onUpdateParams: (newParams: Partial<PayrollHoursQuery>) => void;
  onClickDownload: Func;
  isDownloadingTable: boolean;
  formatWorkingSeconds: (seconds: number) => string;
  query: PayrollHoursQuery;
  selectedProvider?: ProviderRollupEntryVO;
  allProvidersQueryResult: ApiQueryResult<ProviderRollupResponse>;
  employeeWorkingHoursQuery: UseInfiniteApiQueryResult<WorkingHourResponse>;
  providerFilterOptions: ProviderFilterOption[];
}> = ({
  isFullScreen,
  employeeWorkingHoursQuery,
  onUpdateParams,
  isDownloadingTable,
  onClickDownload,
  query,
  selectedProvider,
  providerFilterOptions,
  formatWorkingSeconds,
}) => {
  const { data: paginatedData, isLoading } = employeeWorkingHoursQuery;

  const totalRows = getInfiniteQueryPagingDetails(paginatedData)?.totalElements;
  const totals = getTotals(paginatedData);

  const rows = useFlattenEntries(paginatedData);
  const isEmpty = !isLoading && rows.length === 0;
  const filterParams = usePick(query, ["filters"]);

  const filterProps = useFilterTokenProps({
    options: filterParams,
    selectedProviderDisplayName: selectedProvider?.provider.shortDisplayName,
  });
  const handleSortClick = React.useCallback(
    (newSortOrders: ColumnSortOrder[]) => {
      onUpdateParams({
        employeeTableSort: newSortOrders,
      });
    },
    [onUpdateParams]
  );

  return (
    <div className="h-full w-full flex flex-col min-h-0">
      <DashboardTableHeader
        query={query}
        title="Employee Hours"
        onUpdateParams={onUpdateParams}
        includePatientSearch={false}
        isDownloading={isDownloadingTable}
        onClickDownload={onClickDownload}
        id="payroll-hours-table"
      >
        {isFullScreen && (
          <>
            <SelectDecimalVsClock
              includeTitle={false}
              value={query.hoursInDecimal ? "decimal" : "clock"}
              onItemSelected={(value) => onUpdateParams({ hoursInDecimal: value === "decimal" })}
            />
            <TableSelectProvider
              filters={query.filters}
              onFiltersChanged={(newFilters) => {
                onUpdateParams({ filters: newFilters });
              }}
              providerFilterOptions={providerFilterOptions}
            />
          </>
        )}
      </DashboardTableHeader>

      <DashboardTableFilters {...filterParams}>
        <QueryFilterPills
          {...filterProps}
          numResults={totalRows}
          onUpdateParams={onUpdateParams}
          onClearAll={() => {
            onUpdateParams({ filters: [], patientSearch: undefined });
          }}
        />
      </DashboardTableFilters>
      <DashboardInfiniteTable
        id="employeePayrollTable"
        isEmpty={isEmpty}
        infiniteQuery={employeeWorkingHoursQuery}
        columnWidths={EMPLOYEE_PAYROLL_TABLE_ROWS.map(({ width }) => width)}
        headerRow={
          <DashboardHeaderRow
            headers={EMPLOYEE_PAYROLL_TABLE_ROWS}
            onSortClick={handleSortClick}
            sortOrders={query.employeeTableSort}
          />
        }
        footerRow={
          <>
            <FooterCell>Total</FooterCell>
            <FooterCell loading={isLoading} align="right">
              {formatWorkingSeconds(totals?.totalWorkingSeconds ?? 0)}
            </FooterCell>
            <FooterCell />
          </>
        }
      >
        {rows.map((item, i) => {
          return (
            <PayrollHoursTableRow
              key={`${item.employee.id}`}
              data={item}
              last={i === rows.length - 1}
              formatWorkingSeconds={formatWorkingSeconds}
            />
          );
        })}
      </DashboardInfiniteTable>
    </div>
  );
};
