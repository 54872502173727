import { FC, FormEvent, useState, useEffect, useMemo, useCallback, useId } from "react";
import { format, parseISO, add } from "date-fns";

import { RecallVO, UpdateRecallRequest } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { max } from "@libs/utils/validators";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { ReactComponent as RefreshIcon } from "@libs/assets/icons/refresh.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { Form } from "@libs/components/UI/Form";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";
import { updatePatientRecall } from "api/patients/mutations";

import { usePathParams } from "hooks/usePathParams";

import { handleError } from "utils/handleError";

const cxStyles = {
  label: "text-black",
  dateRow: "flex items-center h-9",
};

interface Props
  extends Pick<
    RecallVO,
    "uuid" | "intervalMonths" | "intervalDays" | "dueDate" | "scheduledDate" | "previousDate" | "note"
  > {
  onClose: Func;
}

const INTERVAL_DAYS_MAX = 31;

const schema = {
  intervalDays: [
    {
      $v: max(INTERVAL_DAYS_MAX),
      $error: `Enter up to ${INTERVAL_DAYS_MAX} days`,
    },
  ],
};

const useCalculatedDueDate = (
  previousDate: RecallVO["previousDate"],
  recallDraft: UpdateRecallRequest
): {
  calculatedDueDate: UpdateRecallRequest["dueDate"];
  showRefreshDueDateButton: boolean;
} => {
  const [calculatedDueDate, setCalculatedDueDate] = useState<UpdateRecallRequest["dueDate"]>();

  // Show refresh button when calculatedDueDate does not equal draft dueDate
  const showRefreshDueDateButton = useMemo(
    () =>
      Boolean(previousDate && (recallDraft.intervalDays ?? 0) <= INTERVAL_DAYS_MAX) &&
      calculatedDueDate !== recallDraft.dueDate,
    [previousDate, recallDraft.intervalDays, recallDraft.dueDate, calculatedDueDate]
  );

  // Set calculatedDueDate (previousDate + intervals) when intervals are changed
  useEffect(() => {
    if (!previousDate || (recallDraft.intervalDays ?? 0) > INTERVAL_DAYS_MAX) {
      return;
    }

    const newCalculatedDueDate = formatAsISODate(
      add(parseISO(previousDate), {
        months: recallDraft.intervalMonths,
        days: recallDraft.intervalDays,
      })
    );

    setCalculatedDueDate(newCalculatedDueDate);
  }, [previousDate, recallDraft.intervalDays, recallDraft.intervalMonths]);

  // Set calculatedDueDate when dueDate is manually changed
  useEffect(() => {
    if (!previousDate) {
      return;
    }

    setCalculatedDueDate(recallDraft.dueDate);
  }, [previousDate, setCalculatedDueDate, recallDraft.dueDate]);

  return { calculatedDueDate, showRefreshDueDateButton };
};

export const RecallForm: FC<Props> = ({
  uuid,
  intervalMonths,
  intervalDays,
  dueDate,
  scheduledDate,
  previousDate,
  note,
  onClose,
}) => {
  const [recallDraft, setRecallDraft] = useState<UpdateRecallRequest>({
    intervalMonths,
    intervalDays,
    dueDate,
    note,
  });
  const formValidation = useValidation(recallDraft, schema);
  const [updatePatientRecallMutation] = useApiMutations([updatePatientRecall]);
  const { patientId } = usePathParams("patient");
  const { practiceId } = useAccount();

  const { calculatedDueDate, showRefreshDueDateButton } = useCalculatedDueDate(previousDate, recallDraft);

  const handleRefreshDueDate = useCallback(() => {
    setRecallDraft((last) => ({ ...last, dueDate: calculatedDueDate }));
  }, [calculatedDueDate]);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!formValidation.validate().$isValid || !uuid) {
        return;
      }

      updatePatientRecallMutation.mutate(
        { practiceId, patientId, recallId: uuid, data: recallDraft },
        {
          onSuccess: onClose,
          onError: handleError,
        }
      );
    },
    [formValidation, updatePatientRecallMutation, practiceId, patientId, uuid, recallDraft, onClose]
  );

  const intervalDaysValidationError = formValidation.result.intervalDays.$error;
  const intervalMonthsId = useId();
  const intervalDaysId = useId();
  const intervalDueDateId = useId();
  const intervalNoteId = useId();

  return (
    <Form className="grid gap-y-4 w-full font-sans text-xs text-greyDark mt-0.5" onSubmit={handleSubmit}>
      <div className="grid gap-y-2">
        <span className={cxStyles.label}>Recall Interval</span>
        <div className="flex items-start">
          <div className="grid grid-cols-2 items-center gap-x-2 mr-5">
            <FormFieldNumberInput
              id={intervalMonthsId}
              className="w-12"
              value={recallDraft.intervalMonths}
              onValueChange={(value) => setRecallDraft((last) => ({ ...last, intervalMonths: value }))}
              min={0}
              clamp
            />
            <FormFieldLabel id={intervalMonthsId} content="Months" className={cxStyles.label} />
          </div>

          <div className="grid grid-cols-2 items-center gap-x-2 gap-y-1">
            <FormFieldNumberInput
              id={intervalDaysId}
              className="w-12"
              value={recallDraft.intervalDays}
              onValueChange={(value) => setRecallDraft((last) => ({ ...last, intervalDays: value }))}
              error={intervalDaysValidationError}
              displayErrorMessage={false}
              min={0}
              max={INTERVAL_DAYS_MAX}
              clamp
            />
            <FormFieldLabel id={intervalDaysId} content="Days" className={cxStyles.label} />
            {intervalDaysValidationError && (
              <FormFieldError className="col-span-full pl-0">{intervalDaysValidationError}</FormFieldError>
            )}
          </div>
        </div>
      </div>

      <div className="flex">
        <div className="grid gap-y-2 w-32">
          <FormFieldLabel id={intervalDueDateId} content="Due" className={cxStyles.label} />
          <div className={cxStyles.dateRow}>
            <FormFieldSelectMenusDatepicker
              id={intervalDueDateId}
              selected={recallDraft.dueDate ? getLocalDate(recallDraft.dueDate) : undefined}
              onChange={(date) =>
                date && setRecallDraft((last) => ({ ...last, dueDate: formatAsISODate(date) }))
              }
              Icon={showRefreshDueDateButton ? RefreshIcon : undefined}
              onClickIcon={showRefreshDueDateButton ? handleRefreshDueDate : undefined}
              iconTooltip={{ content: "Refresh Due Date", theme: "SMALL" }}
            />
          </div>
        </div>

        <div className="grid gap-y-2 w-16 ml-4">
          <span className={cxStyles.label}>Schedule</span>
          <div className={cxStyles.dateRow}>
            <span>{scheduledDate ? format(parseISO(scheduledDate), "MM/dd/yyyy") : "-"}</span>
          </div>
        </div>

        <div className="grid gap-y-2 w-16 ml-6">
          <span className={cxStyles.label}>Previous</span>
          <div className={cxStyles.dateRow}>
            <span>{previousDate ? format(parseISO(previousDate), "MM/dd/yyyy") : "-"}</span>
          </div>
        </div>
      </div>

      <div className="grid gap-y-2">
        <FormFieldLabel id={intervalNoteId} content="Note" className={cxStyles.label} />
        <FormFieldTextarea
          id={intervalNoteId}
          value={recallDraft.note ?? undefined}
          onChange={(e) => setRecallDraft((last) => ({ ...last, note: e.target.value || "" }))}
        />
      </div>

      <div className="flex gap-x-3">
        <Button type="button" className="w-24" onClick={onClose} theme="secondary" size="large">
          Cancel
        </Button>
        <AsyncButton
          type="submit"
          className="w-24"
          isLoading={updatePatientRecallMutation.isLoading}
          theme="primary"
          size="large"
        >
          Save
        </AsyncButton>
      </div>
    </Form>
  );
};
