import { FC, FormEvent, useMemo, useState } from "react";
import { useValidation } from "@libs/hooks/useValidation";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { updateWorktimeEntry } from "api/employee/mutations";
import { EditWorktimeModal } from "components/EmployeeProfile/Timesheet/EditWorktimeModal";
import { handleError } from "utils/handleError";
import { WorktimeComment } from "components/EmployeeProfile/Timesheet/WorktimeComment";
import { TimeInput } from "components/UI/TimeInput";
import {
  commentValidation,
  getClockOutValidation,
  isoTimeValidation,
} from "components/EmployeeProfile/Timesheet/utils";

export interface EditClockOutModalProps {
  employeeId: number;
  date: string;
  comment: string;
  clockInTime: string;
  clockOutTime: string;
  worktimeId: number;
  onRequestClose: Func;
}

export const EditClockOutModal: FC<EditClockOutModalProps> = ({
  worktimeId,
  clockOutTime,
  clockInTime,
  comment: initialComment,
  employeeId,
  onRequestClose,
  date,
}) => {
  const { practiceId } = useAccount();
  const [comment, setComment] = useState(initialComment);
  const [time, setTime] = useState(clockOutTime);
  const [updateWorktimeEntryMutation] = useApiMutations([updateWorktimeEntry]);

  const schema = useMemo(() => {
    return {
      comment: [commentValidation],
      time: [isoTimeValidation, getClockOutValidation(clockInTime)],
    };
  }, [clockInTime]);

  const { result, validate } = useValidation({ comment, time }, schema);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validate().$isValid) {
      updateWorktimeEntryMutation.mutate(
        {
          practiceId,
          employeeId,
          worktimeId,
          data: {
            submittedClockOut: time,
            notes: comment,
          },
        },
        {
          onSuccess: onRequestClose,
          onError: handleError,
        }
      );
    }
  };

  return (
    <EditWorktimeModal
      formId="edit-clock-in-form"
      onSubmit={handleSubmit}
      isLoading={updateWorktimeEntryMutation.isLoading}
      onRequestClose={onRequestClose}
      date={date}
    >
      <TimeInput className="mb-4" label="Original Clock-Out" value={clockOutTime} edit={false} />
      <TimeInput
        label="Edited Clock-Out"
        required={true}
        value={time}
        onChange={setTime}
        error={result.time.$error}
      />
      <WorktimeComment
        value={comment}
        onChange={(e) => setComment(e.target.value)}
        error={result.comment.$error}
      />
    </EditWorktimeModal>
  );
};
