import { FC } from "react";
import { cx } from "@libs/utils/cx";
import { FormFieldContainer } from "@libs/components/UI/FormFieldContainer";
import { RichTextEditor, RichTextEditorProps } from "components/UI/RichTextEditor/RichTextEditor";
import { handleError } from "utils/handleError";

const initialConfig = {
  editable: true,
  namespace: "CustomForms",
  onError: handleError,
};

export const FormsRichTextEditor: FC<Omit<RichTextEditorProps, "placeholder" | "initialConfig">> = ({
  className,
  onChange,
  initialEditorState,
  templateVariables,
}) => {
  return (
    <FormFieldContainer className={cx("px-4 py-2", className)}>
      <RichTextEditor
        initialConfig={initialConfig}
        initialEditorState={initialEditorState}
        onChange={onChange}
        templateVariables={templateVariables}
        placeholder={
          templateVariables?.length ? (
            <>
              Tip: You can type a <span className="font-bold">/</span> (forward slash) to insert provider
              names and procedures.
            </>
          ) : (
            "Enter text..."
          )
        }
      />
    </FormFieldContainer>
  );
};
