import { PaymentVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { AddFundsToWalletFlyoverForm } from "components/PatientProfile/Billing/AddFundsToWallet/AddFundsToWalletFlyoverForm";
import { getPaymentProfilesByPatientId, getPracticeBillingSetting } from "api/billing/queries";
import { listPaymentDevices } from "api/settings/payments/queries";

export const AddFundsToWalletPanel: React.FC<{
  onRequestClose: Func;
  paymentProfileUuid?: string;
  patientId: number;
  onPaymentCollected?: (payment: PaymentVO) => void;
  onClickAddCard: Func;
}> = ({ onRequestClose, paymentProfileUuid, patientId, onPaymentCollected, onClickAddCard }) => {
  const { practiceId } = useAccount();

  const [paymentProfilesQuery, paymentDevicesQuery, billingSettingsQuery] = useApiQueries([
    getPaymentProfilesByPatientId({ args: { patientId, practiceId } }),
    listPaymentDevices({ args: { practiceId, query: { states: ["ACTIVATED"] } } }),
    getPracticeBillingSetting({ args: { practiceId } }),
  ]);

  return (
    <QueryResult queries={[paymentProfilesQuery, paymentDevicesQuery, billingSettingsQuery]}>
      {paymentDevicesQuery.data && paymentProfilesQuery.data && onPaymentCollected && (
        <AddFundsToWalletFlyoverForm
          patientId={patientId}
          onPaymentCollected={onPaymentCollected}
          paymentProfileUuid={paymentProfileUuid}
          onClose={onRequestClose}
          onClickAddCard={onClickAddCard}
          paymentDevices={paymentDevicesQuery.data}
          paymentProfiles={paymentProfilesQuery.data}
          passCreditCardFees={Boolean(billingSettingsQuery.data?.passCreditCardFees)}
        />
      )}
    </QueryResult>
  );
};
