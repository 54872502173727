import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { InsuranceCarriersQueryUpdates, InsuranceCarriersQuery } from "utils/routing/settings";

interface Props {
  onUpdateFilters: (keyValues: InsuranceCarriersQueryUpdates) => void;
  queryState: InsuranceCarriersQuery;
}

export const InsuranceCarriersFilterFlyover: React.FC<Props> = ({ onUpdateFilters, queryState }) => {
  return (
    <div className="flex flex-col gap-y-6">
      <CheckboxList
        label="In Network"
        layout="vert"
        onChange={(newSet) =>
          onUpdateFilters({ inNetwork: [...newSet].map((val) => (val === "inNetwork" ? "true" : "false")) })
        }
        options={[
          { label: "Yes", value: "inNetwork" },
          { label: "No", value: "outOfNetwork" },
        ]}
        selectedValues={
          new Set(queryState.inNetwork?.map((val) => (val === "true" ? "inNetwork" : "outOfNetwork")))
        }
      />
    </div>
  );
};
