import { FC, useCallback, useEffect, useState } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ClaimsConfigRequest } from "@libs/api/generated-api";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { useServerErrorHandler } from "hooks/useServerErrorHandler";
import { getAllTaxonomyCodesQuery } from "api/employee/queries";
import { getClaimsConfigQuery } from "api/claim/queries";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { saveClaimsConfig } from "api/claim/mutations";
import { ClaimAutomationSettings } from "components/Settings/ClaimsSettings/ClaimAutomationSettings";
import { Divider } from "components/UI/Divider";
import { State, UpdateState } from "components/Settings/ClaimsSettings/types";
import { ClaimGeneralSettings } from "components/Settings/ClaimsSettings/ClaimGeneralSettings";
import { DentalXChangeStatus } from "components/Settings/ClaimsSettings/DentalXChangeStatus";
import { SettingsHeroContent } from "components/Settings/SettingsHeroLayout";
import { DentalXChangeSettings } from "components/Settings/ClaimsSettings/DentalXChangeSettings";

const isLegacyDentalXChangeUser = (username: string) => {
  return !username.startsWith("ARC_");
};

export const ClaimSettingsRoute: FC = () => {
  const editing = useBoolean(false);
  const practice = useCurrentPractice();
  const { serverErrors, clearServerErrors, handleException } = useServerErrorHandler<State>();
  const [state, setState] = useState<State>({});

  const updateState = useCallback<UpdateState>(
    (field, value) => {
      setState((last) => ({ ...last, [field]: value }));
      clearServerErrors(field);
    },
    [clearServerErrors]
  );

  const [updateClaimsConfigMutation] = useApiMutations([saveClaimsConfig]);

  const [taxonomyCodesQuery, claimsConfigQuery, practiceProvidersQuery] = useApiQueries([
    getAllTaxonomyCodesQuery({ args: {} }),
    getClaimsConfigQuery({ args: { practiceId: practice.id } }),
    getPracticeProvidersQuery({ args: { practiceId: practice.id } }),
  ]);

  const claimsConfig = claimsConfigQuery.data;

  const initializeState = useCallback(() => {
    if (claimsConfig) {
      // eslint-disable-next-line unused-imports/no-unused-vars
      const { hasDentalXChangePassword, ...newState } = claimsConfig;

      setState(newState);
    }
  }, [setState, claimsConfig]);

  useEffect(() => {
    initializeState();
  }, [editing, initializeState]);

  const handleCancelChanges = useCallback(() => {
    clearServerErrors();
    initializeState();
  }, [clearServerErrors, initializeState]);

  const editingOff = editing.off;
  const handleSaveChanges = useCallback(() => {
    clearServerErrors();

    updateClaimsConfigMutation.mutate(
      { practiceId: practice.id, data: state as ClaimsConfigRequest },
      {
        onSuccess: editingOff,
        onError: handleException,
      }
    );
  }, [clearServerErrors, updateClaimsConfigMutation, practice.id, state, handleException, editingOff]);

  return (
    <QueryResult queries={[taxonomyCodesQuery, claimsConfigQuery, practiceProvidersQuery]}>
      <SettingsPanel
        title="Claims"
        footer={
          editing.isOn ? (
            <div className="flex items-center justify-center gap-x-2">
              <Button
                className="min-w-button"
                onClick={() => {
                  handleCancelChanges();
                  editing.off();
                }}
                theme="secondary"
              >
                Cancel
              </Button>
              <AsyncButton
                className="min-w-button"
                isLoading={updateClaimsConfigMutation.isLoading}
                onClick={handleSaveChanges}
              >
                Save
              </AsyncButton>
            </div>
          ) : undefined
        }
        onToggleEdit={claimsConfig?.dentalXChangeUsername ? editing.toggle : undefined}
        isEditing={editing.isOn}
      >
        <SettingsHeroContent className="flex flex-col gap-y-6">
          {claimsConfig && <DentalXChangeStatus claimsConfig={claimsConfig} />}
          {claimsConfig?.dentalXChangeUsername && (
            <>
              {
                // Only legacy DentalXChange users will see this:
              }
              {isLegacyDentalXChangeUser(claimsConfig.dentalXChangeUsername) && (
                <DentalXChangeSettings
                  isEditing={editing.isOn}
                  state={state}
                  hasDentalXChangePassword={claimsConfig.hasDentalXChangePassword}
                  onUpdateState={updateState}
                  serverErrors={serverErrors}
                />
              )}
              <ClaimAutomationSettings
                isEditing={editing.isOn}
                state={state}
                onUpdateState={updateState}
                serverErrors={serverErrors}
                onClearServerErrors={clearServerErrors}
                onHandleException={handleException}
                taxonomyCodes={taxonomyCodesQuery.data}
                practiceProviders={practiceProvidersQuery.data}
              />
              <Divider className="border-dashed" />
              <ClaimGeneralSettings
                isEditing={editing.isOn}
                state={state}
                onUpdateState={updateState}
                serverErrors={serverErrors}
                practiceData={practice}
              />
            </>
          )}
        </SettingsHeroContent>
      </SettingsPanel>
    </QueryResult>
  );
};
