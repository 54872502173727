import { FC } from "react";
import { format, parseISO } from "date-fns";

import { RecallVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as WarningIcon } from "@libs/assets/icons/warning.svg";

export const RecallDueDate: FC<Pick<RecallVO, "dueDate" | "isDue" | "dueSoon">> = ({
  dueDate,
  isDue,
  dueSoon,
}) => {
  const isDueOrDueSoon = isDue || dueSoon;

  return (
    <div className="flex items-center font-sans text-xs text-greyDark">
      <span className="font-sansSemiBold mr-1">Due</span>
      <span className={cx(!dueDate && "ml-3 mr-12", isDueOrDueSoon && (isDue ? "text-red" : "text-orange"))}>
        {dueDate ? format(parseISO(dueDate), "MM/dd/yyyy") : "-"}
      </span>
      {isDueOrDueSoon && (
        <FloatingTooltip content={isDue ? "Overdue" : "Due Soon"} theme="SMALL">
          <WarningIcon className={cx("w-4 h-4 ml-1", isDue ? "text-red" : "text-orange")} />
        </FloatingTooltip>
      )}
    </div>
  );
};
