import React from "react";

import { createSelectStyles } from "@libs/components/UI/selectStyles";
import {
  DASHBOARD_SELECT_STYLES,
  DashboardFilterSelectProps,
} from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";
import { FormFieldSelect, FormFieldSelectProps } from "components/UI/FormFieldSelect";
import { upsertDashFilter } from "components/Dashboard/utils/filters";
import { DashboardNumericFilterVariant } from "utils/routing/dashboard/serializedFilter";

type Values = "IN" | "NOT_IN";
type Option = SelectOption<Values>;

const getOptions = (dashboardFilterType: DashboardNumericFilterVariant["type"]): Option[] => {
  const isInvoicedBalance = dashboardFilterType === "uninvoicedBalance";
  const notInLabel = isInvoicedBalance ? "Has Uninvoiced Items" : "Has Balance";
  const inLabel = isInvoicedBalance ? "No Uninvoiced Items" : "No Balance";

  return [
    { label: notInLabel, value: "NOT_IN" },
    { label: inLabel, value: "IN" },
  ];
};

type SelectProps = FormFieldSelectProps<Values, Option>;

export const ValuesInFilterSelect: React.FC<
  Omit<SelectProps, "styles" | "onChange" | "options"> &
    Omit<DashboardFilterSelectProps, "dashboardFilterType"> & {
      dashboardFilterType: DashboardNumericFilterVariant["type"];
      valuesIn: DashboardNumericFilterVariant["values"];
    }
> = ({ dashboardFilters, dashboardFilterType, onChangeFilters, valuesIn, ...rest }) => {
  const options = React.useMemo(() => getOptions(dashboardFilterType), [dashboardFilterType]);
  const designatedFilter = dashboardFilters.find(({ type: currType }) => currType === dashboardFilterType) as
    | DashboardNumericFilterVariant
    | undefined;
  const styles = React.useMemo(() => {
    return createSelectStyles<Values, Option>({
      option: (provided, optionProps) => {
        const baseProps = {
          ...provided,
          cursor: "pointer",
        };

        if (!designatedFilter) {
          return baseProps;
        }

        return {
          ...baseProps,
          fontWeight:
            (designatedFilter.op === "NOT_IN" && optionProps.data.value === "NOT_IN") ||
            (!designatedFilter.op && optionProps.data.value === "IN")
              ? "bold"
              : undefined,
        };
      },
      ...DASHBOARD_SELECT_STYLES,
    });
  }, [designatedFilter]);

  return (
    <FormFieldSelect
      {...rest}
      closeMenuOnSelect={false}
      options={options}
      styles={styles}
      onChange={(option) => {
        const updatedFilter: DashboardNumericFilterVariant = {
          type: dashboardFilterType,
          values: valuesIn,
          op: option?.value === "NOT_IN" ? "NOT_IN" : undefined,
        };

        onChangeFilters({
          filters: upsertDashFilter(dashboardFilters, updatedFilter, {
            mergeWithExisting: true,
            toggle: true,
          }),
        });
      }}
    />
  );
};
