import { useCallback } from "react";
import { InsurancePlanVO, PatientInsuranceVO } from "@libs/api/generated-api";

import { useBoolean } from "@libs/hooks/useBoolean";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { ActionModal } from "components/UI/ActionModal";
import {
  isAutoVerified,
  isVerified,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/SubscriberTab/utils";

interface Props {
  insurancePlan: InsurancePlanVO | undefined;
  isLoading: boolean;
  onClickUnverify: ({
    includeCoverage,
    includeEligibility,
    insId,
    insurancePlanUuid,
  }: {
    includeCoverage?: boolean;
    includeEligibility?: boolean;
    insId: number | undefined;
    insurancePlanUuid: string | undefined;
  }) => void;
  onClose: Func;
  patientInsurance: PatientInsuranceVO | undefined;
}

export const UnverifyActionModal: React.FC<Props> = ({
  insurancePlan,
  isLoading,
  onClickUnverify,
  onClose,
  patientInsurance,
}) => {
  const eligibility = useBoolean(isVerified(patientInsurance?.eligibilityVerifiedStatus));
  const coverage = useBoolean(isVerified(insurancePlan?.benefitVerifiedStatus));

  const handleUnverify = useCallback(() => {
    onClickUnverify({
      includeCoverage: coverage.isOn,
      includeEligibility: eligibility.isOn,
      insId: patientInsurance?.id,
      insurancePlanUuid: insurancePlan?.uuid,
    });
  }, [coverage.isOn, eligibility.isOn, insurancePlan?.uuid, onClickUnverify, patientInsurance?.id]);

  return (
    <ActionModal
      displaySecondaryButton={false}
      handleClose={onClose}
      handleSave={handleUnverify}
      isLoading={isLoading}
      primaryButtonDisabled={coverage.isOff && eligibility.isOff}
      primaryButtonText="Unverify"
      size="2xs"
      title="Unverify Insurance"
    >
      <div className="flex flex-col gap-y-3">
        <Checkbox
          checked={eligibility.isOn}
          className="flex items-center py-2"
          disabled={
            !isVerified(patientInsurance?.eligibilityVerifiedStatus) &&
            !isAutoVerified(patientInsurance?.eligibilityVerifiedStatus)
          }
          onChange={eligibility.toggle}
        >
          Eligibility
        </Checkbox>
        <Checkbox
          checked={coverage.isOn}
          className="flex items-center py-2"
          disabled={
            !isVerified(insurancePlan?.benefitVerifiedStatus) &&
            !isAutoVerified(insurancePlan?.benefitVerifiedStatus)
          }
          onChange={coverage.toggle}
        >
          Coverage
        </Checkbox>
      </div>
    </ActionModal>
  );
};
