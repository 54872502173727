import { ReactNode, MouseEvent, FC } from "react";
import { cx } from "@libs/utils/cx";
import { Layout } from "@libs/components/UI/OptionInputList";
import { LabelledOptions } from "@libs/components/UI/LabelledOptions";
import { FormFieldContainer } from "@libs/components/UI/FormFieldContainer";
import { ToggleButtonList } from "components/UI/ToggleButtonList";

const cxLabelThemes = {
  default: "text-xs font-sansSemiBold",
  regular: "text-xs",
};

interface YesNoProps {
  label: string;
  response?: boolean;
  onOptionClick?: (e: MouseEvent<HTMLElement>, question: { response: boolean; label: string }) => void;
}

const YesNo = ({ label, response, onOptionClick }: YesNoProps) => {
  return (
    <FormFieldContainer className="px-4 py-2">
      <ToggleButtonList
        type="radio"
        label={label}
        shape="smallSquare"
        selectedValue={response}
        layout="custom"
        containerLayout="custom"
        listClassName="flex items-center gap-x-2"
        className="flex items-center w-full"
        options={[
          {
            label: "Y",
            value: true,
          },
          {
            label: "N",
            value: false,
          },
        ]}
        onClick={(e, option) => onOptionClick?.(e, { label, response: option.value })}
      />
    </FormFieldContainer>
  );
};

interface YesNoListProps extends Pick<YesNoProps, "onOptionClick"> {
  questions: { response?: boolean; label: string }[];
  label?: ReactNode;
  className?: string;
  layout?: Layout;
  labelTheme?: keyof typeof cxLabelThemes;
}

export const YesNoList: FC<YesNoListProps> = ({ className, label, questions, onOptionClick }) => {
  return (
    <LabelledOptions
      listClassName="flex flex-col gap-y-4"
      labelClassName={cxLabelThemes.default}
      className={cx(className)}
      label={label}
      layout="vert"
    >
      <>
        {questions.map((o, index) => (
          <YesNo key={index} label={o.label} response={o.response} onOptionClick={onOptionClick} />
        ))}
      </>
    </LabelledOptions>
  );
};
