import { FC, FormEventHandler, useCallback, useState } from "react";
import { PaymentDeviceVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { required } from "@libs/utils/validators";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useValidation } from "@libs/hooks/useValidation";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Spinner } from "@libs/components/UI/Spinner";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useAccount } from "@libs/contexts/AccountContext";
import { Cell, cxGridTableStyles, Row } from "@libs/components/UI/GridTableComponents";
import { Form } from "@libs/components/UI/Form";
import { updatePaymentDevice } from "api/settings/payments/mutations";
import { handleError } from "utils/handleError";

interface Props {
  posDevice: PaymentDeviceVO;
  isDeleting: boolean;
  onActivate: (posDevice: PaymentDeviceVO) => void;
  onDelete: (posDevice: PaymentDeviceVO) => void;
  onDeactivate: (posDevice: PaymentDeviceVO) => void;
}

// eslint-disable-next-line complexity
export const PosDeviceRow: FC<Props> = ({ posDevice, isDeleting, onActivate, onDelete, onDeactivate }) => {
  const { practiceId } = useAccount();
  const editing = useBoolean(false);
  const canDeleteDevice = posDevice.state !== "ACTIVATED";
  const [draftPos, setDraftPos] = useState<PaymentDeviceVO>(posDevice);
  const validation = useValidation(draftPos, {
    name: { $validations: [{ $v: required, $error: "A name is required" }] },
  });

  const [{ mutate: updatePaymentDeviceMutate, isLoading: isSaving }] = useApiMutations([updatePaymentDevice]);

  const handleSave: FormEventHandler = useCallback(
    (e) => {
      e.preventDefault();

      const result = validation.validate();

      if (!result.$isValid) {
        return;
      }

      updatePaymentDeviceMutate(
        {
          practiceId,
          paymentDeviceUuid: draftPos.uuid,
          data: { name: draftPos.name },
        },
        { onSuccess: () => editing.off(), onError: handleError }
      );
    },
    [draftPos, editing, practiceId, updatePaymentDeviceMutate, validation]
  );

  const toggleEdit = useCallback(() => {
    if (editing.isOff) {
      editing.on();
    } else {
      editing.off();
      setDraftPos(posDevice);
      validation.reset();
    }
  }, [editing, posDevice, validation]);

  return (
    <Row key={posDevice.uuid}>
      <Form className="contents" onSubmit={handleSave}>
        <Cell
          className={cx(
            "flex items-center gap-x-2",
            editing.isOff ? cxGridTableStyles.cellPadding() : cxGridTableStyles.cellPaddingFormField,
            cxGridTableStyles.dataCell
          )}
        >
          <FormFieldInput
            autoFocus
            edit={editing.isOn}
            value={draftPos.name}
            error={validation.result.name.$error}
            required
            placeholder="E.g. Front Desk #1"
            onChange={(e) => setDraftPos((last) => ({ ...last, name: e.target.value }))}
            onKeyDown={(e) => e.key === "Escape" && toggleEdit()}
          />
          {isSaving ? (
            <Spinner size="md" animation="border" variant="primary" />
          ) : editing.isOff ? (
            <ButtonIcon
              SvgIcon={EditIcon}
              theme="primary"
              tooltip={{ content: "Edit Device", theme: "SMALL" }}
              onClick={toggleEdit}
            />
          ) : (
            <ButtonIcon
              SvgIcon={CancelIcon}
              theme="primary"
              tooltip={{ content: "Cancel Edit", theme: "SMALL" }}
              onClick={toggleEdit}
            />
          )}
        </Cell>
        <Cell
          className={cx("flex items-center", cxGridTableStyles.cellPadding(), cxGridTableStyles.dataCell)}
        >
          {posDevice.serialNumber}
        </Cell>
        <Cell
          className={cx("flex items-center", cxGridTableStyles.cellPadding(), cxGridTableStyles.dataCell)}
        >
          {sentenceCaseConstant(posDevice.model)}
        </Cell>
        <Cell
          className={cx("flex items-center", cxGridTableStyles.cellPadding(), cxGridTableStyles.dataCell)}
        >
          {sentenceCaseConstant(posDevice.state)}
        </Cell>
        <Cell
          className={cx(
            "flex items-center gap-x-10 text-primaryTheme justify-between",
            cxGridTableStyles.cellPadding(),
            cxGridTableStyles.dataCell
          )}
        >
          {posDevice.state === "ACTIVATED" ? (
            <Button className="w-20" theme="secondary" size="small" onClick={() => onDeactivate(posDevice)}>
              Deactivate
            </Button>
          ) : (
            <Button className="w-20" theme="secondary" size="small" onClick={() => onActivate(posDevice)}>
              Activate
            </Button>
          )}

          {isDeleting ? (
            <Spinner size="md" animation="border" variant="primary" />
          ) : (
            <ButtonIcon
              SvgIcon={DeleteIcon}
              disabled={!canDeleteDevice || editing.isOn}
              theme="primary"
              tooltip={{
                content: editing.isOn
                  ? "Cannot delete device when editing"
                  : canDeleteDevice
                    ? "Delete Device"
                    : "Deactivate the device before deleting it.",
                theme: !canDeleteDevice || editing.isOn ? "MEDIUM" : "SMALL",
              }}
              onClick={() => onDelete(posDevice)}
            />
          )}
        </Cell>
      </Form>
    </Row>
  );
};
