import { MedicalImageVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useCallback } from "react";
import { revertImageToDefaults } from "components/PatientProfile/Imaging/MountRoute/image-utils";

export const useRevertChangesInSelectedImages = ({
  handleImagesReverted,
  selectedImages,
}: {
  selectedImages?: MedicalImageVO[];
  handleImagesReverted: (changes: MedicalImageVO[]) => void;
}) => {
  const revertChangesModal = useBoolean(false);

  const handleRevertChanges = useCallback(() => {
    if (!selectedImages) {
      return;
    }

    revertChangesModal.off();
    handleImagesReverted(
      selectedImages.map((image: MedicalImageVO) => {
        return revertImageToDefaults(image);
      })
    );
  }, [handleImagesReverted, revertChangesModal, selectedImages]);

  return {
    revertChangesModal,
    handleRevertChanges,
  };
};
