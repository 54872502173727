import { PatientToothVO, ProcedureShortcutVO } from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";

export type Surface = NonNullable<ProcedureShortcutVO["surfaces"]>[number];
export type PosteriorSurface = Extract<Surface, "M" | "O" | "D" | "L" | "B" | "BV" | "LV">;
export type AnteriorSurface = Extract<Surface, "M" | "I" | "D" | "L" | "F" | "FV" | "LV">;
export type SurfaceSelection = Record<Exclude<Surface, "I" | "F" | "FV">, boolean>;
export type ClassVSurface = Extract<Surface, "BV" | "LV">;
export type ClassVAdjacentSurface = Extract<Surface, "B" | "L">;

const surfaceOrder: (keyof SurfaceSelection)[] = ["M", "O", "D", "B", "L", "BV", "LV"];

export const classVSurfaceRelations: Partial<
  Record<keyof SurfaceSelection, ClassVSurface | ClassVAdjacentSurface>
> = {
  BV: "B",
  LV: "L",
  B: "BV",
  L: "LV",
};

const toPosteriorSurfaces = (selection: PosteriorSurface | AnteriorSurface): PosteriorSurface => {
  if (selection === "I") {
    return "O";
  }

  if (selection === "F") {
    return "B";
  }

  if (selection === "FV") {
    return "BV";
  }

  return selection;
};

const fromPosteriorSurfaces = (selection: PosteriorSurface, isAnterior: boolean): Surface => {
  if (isAnterior) {
    if (selection === "O") {
      return "I";
    }

    if (selection === "B") {
      return "F";
    }

    if (selection === "BV") {
      return "FV";
    }
  }

  return selection;
};

const toUnknownSurfaces = (selection: Surface) => {
  if (isOneOf(selection, ["O", "I"])) {
    return "O/I";
  }

  if (isOneOf(selection, ["B", "F"])) {
    return "B/F";
  }

  return selection;
};

export const mapToPosteriorSurfaces = (surfaces: Surface[]) => {
  return surfaces.map((s) => toPosteriorSurfaces(s));
};

export const mapToUnknowSurfaces = (surfaces: Surface[]) => {
  return surfaces.map((s) => toUnknownSurfaces(s)).join("");
};

/**
 * Returns the number of selected surfaces according to insurance claims. Class
 * V surfaces `BV` and `LV` are special cases and are counted differently than
 * other surfaces:
 * - When BV is selected, we consider that only B is selected.
 * - When LV is selected, we consider that only L is selected.
 * - When BV and B are selected, we consider that only B is selected.
 * - When LV and L are selected, we consider that only L is selected.
 * @param value
 * @returns
 */
export const getSurfaceSelectionCount = (value?: SurfaceSelection) => {
  if (!value) {
    return 0;
  }

  const mergedClassVSurfaces: SurfaceSelection = { ...value, BV: false, LV: false };

  if (value["BV"]) {
    mergedClassVSurfaces["B"] = true;
  }

  if (value["LV"]) {
    mergedClassVSurfaces["L"] = true;
  }

  return Object.values(mergedClassVSurfaces).filter(Boolean).length;
};

export const surfacesToSelector = (surfaces: Surface[]) => {
  const surfaceSelection: SurfaceSelection = {
    M: false,
    O: false,
    D: false,
    L: false,
    B: false,
    BV: false,
    LV: false,
  };
  // When editing surface selection we only use posterior values for simplicity
  const posteriorSurfaces = surfaces.map(toPosteriorSurfaces);

  for (const posteriorSurface of posteriorSurfaces) {
    surfaceSelection[posteriorSurface] = true;
  }

  return surfaceSelection;
};

export const surfaceSelectorToArray = (
  surfaceSelection: SurfaceSelection,
  position: PatientToothVO["position"]
) => {
  return surfaceOrder
    .filter((surface) => surfaceSelection[surface])
    .map((surface) =>
      // When editing surface selection we only use posterior values for
      // simplicity. When we save the selection have to look at which the tooth
      // the surface is being selected for to know whether we need to use
      // posterior or anterior specific surface.
      fromPosteriorSurfaces(surface, position === "ANTERIOR")
    );
};
