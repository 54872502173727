import React from "react";
import { getIsLoadingMore } from "@libs/utils/queries";
import { BaseApiEntryResult, UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { TableGrid } from "@libs/components/UI/GridTableComponents";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { EmptyDashboardTable } from "components/Dashboard/Tables/EmptyDashboardTable";
import { cxTableStyles } from "components/Dashboard/Tables/cxTableStyles";
import { LoadingCells } from "components/Dashboard/Tables/LoadingCells";
import { BaseDashboardTableProps } from "components/Dashboard/Tables/DashboardTable";
import { OFFSCREEN_RENDER_ORIGIN } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/shapeUtils";

type InfiniteTableProps = Omit<BaseDashboardTableProps, "isLoading"> & {
  infiniteQuery: UseInfiniteApiQueryResult<BaseApiEntryResult>;
};

export const DashboardInfiniteTable = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & InfiniteTableProps
>(
  (
    {
      id,
      children,
      footerRow,
      isPrinting = false,
      columnWidths,
      isEmpty = false,
      className,
      infiniteQuery,
      headerRow,
    },
    ref
  ) => {
    const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery });
    const loadErrorContent = (
      <div className="col-span-full">
        <ErrorContent />
      </div>
    );
    const columnCount = columnWidths.length;
    const isLoadingMore = getIsLoadingMore(infiniteQuery);

    const loadContent = (
      <TableGrid columnWidths={columnWidths} className="col-span-full">
        {!isLoadingMore && headerRow}
        <LoadingCells columnCount={columnCount} />
      </TableGrid>
    );

    return (
      <div className={cxTableStyles.tableWrapper} style={isPrinting ? OFFSCREEN_RENDER_ORIGIN : undefined}>
        {
          // This extra div is needed, because if offscreen print style is attached to the div with the ref, it will not render in the print, as it will be offscreen there too
        }
        <div className="contents" ref={ref}>
          <PersistScrollPosition
            id={id}
            ref={rootRef}
            className={cxTableStyles.tableContainer(isPrinting, className)}
          >
            <TableGrid columnWidths={columnWidths}>
              <ScrollableInfiniteQueryResult
                infiniteQuery={infiniteQuery}
                scrollRef={scrollRef}
                loadError={loadErrorContent}
                loadMoreError={loadErrorContent}
                loading={loadContent}
                loadMore={loadContent}
                loadMoreClassName="col-span-full"
              >
                {headerRow}
                {children}
                {isEmpty && (
                  <EmptyDashboardTable className="col-span-full">No Records Found</EmptyDashboardTable>
                )}
              </ScrollableInfiniteQueryResult>
            </TableGrid>
          </PersistScrollPosition>
          {footerRow && (
            <TableGrid className={cxTableStyles.tableFooterContainer} columnWidths={columnWidths}>
              {isEmpty ? null : footerRow}
            </TableGrid>
          )}
        </div>
      </div>
    );
  }
);
