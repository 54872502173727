import React, { useMemo } from "react";
import { isDefined } from "@libs/utils/types";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { RoleGuardHide } from "components/Main/RoleGuard";
import { EmploymentDetailsForm } from "components/EmployeeProfile/EmploymentDetails/EmploymentDetailsForm";
import { EmploymentPanel } from "components/EmployeeProfile/EmploymentDetails/EmploymentPanel";
import { EmploymentDetailsProps } from "components/EmployeeProfile/types";

interface Props extends EmploymentDetailsProps {
  onBeginEditing: () => void;
}

const FORM_ID = "employment-form";

export const ViewEmploymentDetails: React.FC<Props> = ({
  employmentDetails,
  employeeRole,
  employeeId,
  assignableRoles,
  taxonomyCodes,
  onBeginEditing,
  payrollEnabled,
  employeePayrollOnboardingQuery,
  jobTitles,
}) => {
  const expandedEmployee = useMemo(
    () => ({
      roleV2Id: employeeRole.roleId,
      employmentDetails,
    }),
    [employmentDetails, employeeRole.roleId]
  );

  const amount = isDefined(employmentDetails.compensationDetails?.amount)
    ? `${employmentDetails.compensationDetails.amount}`
    : "";

  return (
    <EmploymentPanel
      actions={
        <RoleGuardHide domain="EMPLOYEE_SETTINGS" action="ACCESS_ALL">
          <ButtonIcon
            SvgIcon={EditIcon}
            tooltip={{ content: "Edit Settings", theme: "SMALL" }}
            onClick={onBeginEditing}
            theme="primary"
          />
        </RoleGuardHide>
      }
    >
      <EmploymentDetailsForm
        formId={FORM_ID}
        employeeId={employeeId}
        formState={expandedEmployee}
        taxonomyCodes={taxonomyCodes}
        assignableRoles={assignableRoles}
        employeeRoleV2={employeeRole}
        serverErrors={{}}
        amount={{ value: amount, float: employmentDetails.compensationDetails?.amount ?? 0 }}
        payrollEnabled={payrollEnabled}
        employeePayrollOnboardingQuery={employeePayrollOnboardingQuery}
        isEditing={false}
        canEditPayrollSettings={payrollEnabled && !employmentDetails.excludeFromPayroll}
        jobTitles={jobTitles}
      />
    </EmploymentPanel>
  );
};
