import { FC, FormEventHandler, useState } from "react";
import { CreateCustomAdjustmentTypeRequest } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { RadioList } from "@libs/components/UI/RadioList";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { ModalContent, ModalFooter, ModalForm } from "@libs/components/UI/ModalComponents";
import { Modal } from "@libs/components/UI/Modal";
import {
  TOOLTIP_ADJUSTMENT_CREDIT,
  TOOLTIP_ADJUSTMENT_DEBIT,
} from "components/PatientProfile/Billing/billingUtils";
import { TooltipLabel } from "components/UI/TooltipLabel";

const CATEGORY_OPTIONS: OptionInputOption<CreateCustomAdjustmentTypeRequest["entryType"]>[] = [
  {
    label: <TooltipLabel tooltip={{ content: TOOLTIP_ADJUSTMENT_DEBIT }}>Charge</TooltipLabel>,
    value: "DEBIT",
  },
  {
    label: <TooltipLabel tooltip={{ content: TOOLTIP_ADJUSTMENT_CREDIT }}>Discount</TooltipLabel>,
    value: "CREDIT",
  },
];

export const AddCustomAdjustmentModal: FC<{
  onCancel: Func;
  onSave: (newAdjustment: CreateCustomAdjustmentTypeRequest) => void;
  isCreating: boolean;
}> = ({ onCancel, onSave, isCreating }) => {
  const [draft, setDraft] = useState<Partial<CreateCustomAdjustmentTypeRequest>>({});

  const validation = useValidation(draft, {
    name: { $validations: [{ $v: required, $error: "A name is required" }] },
    entryType: { $validations: [{ $v: required, $error: "An adjustment category is required" }] },
  });

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();

    const result = validation.validate();

    if (!result.$isValid) {
      return;
    }

    // When validation passes, consider the draft fully built out. We type the
    // draft with Partial<...> to force the user to make a conscious choice
    // between charge/discount instead of setting a default.
    onSave(draft as CreateCustomAdjustmentTypeRequest);
  };

  return (
    <Modal title="Add Adjustment Type" onClose={onCancel} centerVertically={false} size="sm">
      <ModalForm onSubmit={handleSubmit}>
        <ModalContent padding="lg" className="flex flex-col gap-y-6">
          <FormFieldInput
            label={<span className="font-sansSemiBold">Name</span>}
            value={draft.name}
            onChange={(e) => setDraft((last) => ({ ...last, name: e.target.value }))}
            error={validation.result.name.$error}
          />
          {/* Wrap with <div/> so the error message is not "outside" of the block
              and thus isn't sugject to the vertical padding from the parent
              container */}
          <div>
            <RadioList
              label="Category"
              onChange={(_e, option) => setDraft((last) => ({ ...last, entryType: option.value }))}
              selectedValue={draft.entryType}
              options={CATEGORY_OPTIONS}
              error={validation.result.entryType.$error}
            />
          </div>
        </ModalContent>
        <ModalFooter actions={true}>
          <Button className="min-w-button" theme="secondary" onClick={onCancel}>
            Cancel
          </Button>
          <AsyncButton type="submit" className="min-w-button" isLoading={isCreating}>
            Save
          </AsyncButton>
        </ModalFooter>
      </ModalForm>
    </Modal>
  );
};
