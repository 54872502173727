import { HeaderData } from "components/Dashboard/Tables/types";

export const UNCOLLECTED_BY_PATIENT_AGING_COLUMNS: HeaderData[] = [
  { label: "Patient", width: "1fr", sortField: "patientLastName" },
  { label: "Phone Number", width: "1fr" },
  { label: "Next Appointment", width: "1fr", sortField: "nextAppointmentDate" },
  { label: "0-30", width: "8rem", sortField: "aging30OrLess", align: "right" },
  { label: "31-60", width: "8rem", sortField: "aging31To60", align: "right" },
  { label: "61-90", width: "8rem", sortField: "aging61To90", align: "right" },
  { label: "91-120", width: "8rem", sortField: "aging91To120", align: "right" },
  { label: "121+", width: "8rem", sortField: "aging121OrMore", align: "right" },
  { label: "Total", width: "8rem", sortField: "totalPatientBalance", align: "right" },
];

export const UNCOLLECTED_BY_CARRIER_COLUMNS: HeaderData[] = [
  { label: "Patient", width: "1fr", sortField: "patientLastName" },
  { label: "Pt DOB", width: "1fr" },
  { label: "Subscriber", width: "1fr" },
  { label: "Ins Type", width: "1fr" },
  { label: "Service Date", width: "1fr" },
  { label: "Sent Date", width: "1fr" },
  { label: "Claim Amount", width: "1fr", align: "right" },
  { label: "Ins Estimated", width: "1fr", align: "right" },
  { label: "Claim Age", width: "1fr", align: "right" },
  { label: "", width: "4rem" },
];

export const UNCOLLECTED_BY_SERVICE_COLUMNS: HeaderData[] = [
  { label: "Patient", width: "1fr", sortField: "patientLastName" },
  { label: "Date of Service", width: "8rem" },
  { label: "Type", width: "13rem" },
  { label: "Provider", width: "1fr" },
  { label: "Age", width: "6rem", sortField: "serviceDate" },
  { label: "Outstanding", width: "8rem", sortField: "outstandingBalance", align: "right" },
  { label: "Patient Balance", width: "1fr", sortField: "patientBalance", align: "right" },
  { label: "Insurance Balance", width: "1fr", sortField: "insuranceBalance", align: "right" },
  { label: "Invoice Not Created", width: "1fr", sortField: "uninvoicedBalance", align: "right" },
  { label: "Total Patient Balance", width: "1fr", sortField: "totalPatientBalance", align: "right" },
];
