import { ComponentProps, ReactNode } from "react";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { Banner } from "@libs/components/UI/Banner";

interface Props {
  actions?: ReactNode;
  description: ReactNode | string;
  editing: boolean;
  onClickEdit?: Func;
  theme?: ComponentProps<typeof Banner>["theme"];
}

export const TabBanner: React.FC<Props> = ({ actions, description, editing, onClickEdit, theme }) => {
  return editing ? null : (
    <Banner className="text-xs sticky top-0 z-20" includeIcon theme={theme ?? "info"}>
      <div className="flex items-center justify-between w-full">
        {description}
        {actions ??
          (onClickEdit ? (
            <button
              className={`
                flex
                flex-row
                items-center
                gap-x-1
                text-primaryTheme
                font-sansSemiBold
              `}
              onClick={onClickEdit}
              type="button"
            >
              <Icon SvgIcon={EditIcon} theme="primary" />
              Edit Plan Details
            </button>
          ) : null)}
      </div>
    </Banner>
  );
};
