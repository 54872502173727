import { useMemo } from "react";
import { $createParagraphNode, $createTextNode, $getRoot } from "lexical";
import { isNullish } from "@libs/utils/types";
import { isValidJSON } from "utils/json";

export const useSafeInitialState = (initialEditorState: string | undefined) => {
  return useMemo(() => {
    if (isNullish(initialEditorState)) {
      return initialEditorState;
    }

    if (isValidJSON(initialEditorState)) {
      return initialEditorState;
    }

    return () => {
      const root = $getRoot();

      const paragraphNode = $createParagraphNode();
      const textNode = $createTextNode(initialEditorState);

      paragraphNode.append(textNode);
      root.append(paragraphNode);

      return root;
    };
  }, [initialEditorState]);
};
