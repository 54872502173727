import { FC } from "react";
import { Link, useLocation } from "react-router-dom";

import { EmployeeVO } from "@libs/api/generated-api";
import { formatPhoneNumber } from "@libs/utils/phone";
import { getFullUrl } from "@libs/utils/location";
import { cx } from "@libs/utils/cx";

import { RoleGuardClick } from "components/Main/RoleGuard";

import { paths } from "utils/routing/paths";

interface Props {
  owner: EmployeeVO;
  hasInitiated: boolean;
}

export const OwnerContactDetails: FC<Props> = ({ owner, hasInitiated }) => {
  const location = useLocation();

  return (
    <div className={cx("flex flex-col gap-y-2", hasInitiated && "text-slate-400")}>
      <span className="font-sansSemiBold text-xs">Owner Contact Details</span>
      <div
        className={`
          flex
          flex-col
          gap-y-1
          p-3
          border
          border-slate-200
          rounded
          text-xs
        `}
      >
        <span className="font-sansSemiBold">
          {owner.personalDetails.firstName} {owner.personalDetails.lastName}
        </span>
        <div className={cx("flex flex-col gap-y-1", hasInitiated ? "text-slate-400" : "text-slate-700")}>
          {owner.contactDetails.phone ? <span>{formatPhoneNumber(owner.contactDetails.phone)}</span> : null}
          <span>{owner.contactDetails.workEmail}</span>
        </div>
        {hasInitiated ? null : (
          <RoleGuardClick domain="EMPLOYEE_SETTINGS" action="ACCESS_ALL">
            <Link
              className="font-sansSemiBold text-primaryTheme w-fit"
              to={paths.employee({ id: owner.id }, { from: getFullUrl(location), edit: true })}
            >
              Edit
            </Link>
          </RoleGuardClick>
        )}
      </div>
    </div>
  );
};
