import { FC, FormEvent, useState, useMemo, useCallback, useEffect } from "react";

import { FormVO, TemplateVariableGroupVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { phoneNumber } from "@libs/utils/validators";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Modal, ModalProps } from "@libs/components/UI/Modal";
import { ModalForm, ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";
import { Banner } from "@libs/components/UI/Banner";
import { ToggleButtonList } from "components/UI/ToggleButtonList";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";
import { FormFieldTemplateVariablesTextarea } from "components/UI/FormFieldTemplateVariablesTextarea";

import { getFamilyMembersV2Query, getPatientSummary } from "api/patients/queries";

import { getPatientDisplayName } from "utils/names";

export interface SendFormsModalProps {
  patientId: number;
  title?: string;
  inPersonFormTitles: FormVO["title"][];
  hasSomeSelectedCompleted: boolean;
  isSending: boolean;
  onSendForms: (
    message: string,
    { phoneOverride, onSuccess }: { phoneOverride?: string; onSuccess: Func }
  ) => void;
  onRequestClose: Func;
}

const DefaultFormsMessage = "Please fill out the required forms for your appointment here: {{FORMS_LINK}}";

const formTemplateVariables: TemplateVariableGroupVO[] = [
  {
    type: "FORMS",
    variables: [{ key: "FORMS_LINK", exampleValue: "https://t.archy.com/1dsfrAed2" }],
  },
];

export const SendFormsModal: FC<SendFormsModalProps> = ({
  patientId,
  title,
  inPersonFormTitles,
  hasSomeSelectedCompleted,
  isSending,
  onSendForms,
  onRequestClose,
}) => {
  const { practiceId } = useAccount();
  const [message, setMessage] = useState(DefaultFormsMessage);
  const [recipientValue, setRecipientValue] = useState("PRIMARY");
  const [otherPhone, setOtherPhone] = useState("");
  const sentModal = useBoolean(false);

  // Reset otherPhone after selecting another recipient if previously set
  useEffect(() => {
    if (recipientValue !== "OTHER" && otherPhone) {
      setOtherPhone("");
    }
  }, [recipientValue, otherPhone]);

  const hasMessage = message.trim().length > 0;
  const canSendMessage = recipientValue === "OTHER" ? otherPhone.length > 0 && hasMessage : hasMessage;

  const modalProps: Pick<ModalProps, "title" | "size"> = useMemo(
    () => (sentModal.isOff ? { title: title ?? "Send Forms", size: "xs" } : { size: "2xs" }),
    [sentModal.isOff, title]
  );

  const [patientQuery, familyMembersQuery] = useApiQueries([
    getPatientSummary({
      args: { patientId, practiceId },
    }),
    getFamilyMembersV2Query({
      args: { practiceId, patientId, includeUnlinked: false },
    }),
  ]);

  const recipientOptions = useMemo(() => {
    const patient = patientQuery.data;
    const linkedFamilyMembers = familyMembersQuery.data?.linkedFamilyMembers;

    if (!patient || !linkedFamilyMembers) {
      return [];
    }

    const { contact } = patient;

    // Primary contact has a value of PRIMARY, signifying that there is no
    // phoneOverride request proeprty set when selected
    const primaryContactOption = {
      label: getPatientDisplayName(contact),
      value: "PRIMARY",
    };

    // Family member phone numbers are assigned as option values to be used to
    // set phoneOverride request property when selected
    const familyMemberOptions = linkedFamilyMembers
      .filter(({ contact: fmContact, relation }) => {
        return relation !== "SELF" && fmContact.textPhone && fmContact.name.id !== contact.name.id;
      })
      // Value is prepended with ${index}_ to avoid duplicate key rendering when
      // family members share the same phone number, and is removed upon request
      .map((fm, index) => {
        const { contact: fmContact } = fm;

        return {
          label: getPatientDisplayName(fm),
          value: `${index}_${fmContact.textPhone as string}`,
        };
      });

    // Other has a value of OTHER, signifying otherPhone should be used to set
    // phoneOverride request property when selected
    const otherOption = { label: "Other", value: "OTHER" };

    return [primaryContactOption, ...familyMemberOptions, otherOption];
  }, [patientQuery.data, familyMembersQuery.data?.linkedFamilyMembers]);

  const { result: validationResult, validate } = useValidation(
    { otherPhone },
    {
      otherPhone: [
        {
          $v: phoneNumber,
          $error: "Please enter valid phone number",
          $ignore: recipientValue !== "OTHER",
        },
      ],
    }
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (validate().$isValid) {
        onSendForms(message, {
          phoneOverride:
            recipientValue === "PRIMARY"
              ? undefined
              : recipientValue === "OTHER"
                ? otherPhone
                : recipientValue.split("_")[1],
          onSuccess: sentModal.on,
        });
      }
    },
    [validate, onSendForms, message, recipientValue, otherPhone, sentModal]
  );

  return (
    <Modal onClose={onRequestClose} {...modalProps}>
      {sentModal.isOn ? (
        <SentModalContent onRequestClose={onRequestClose} />
      ) : (
        <QueryResult queries={[patientQuery, familyMembersQuery]}>
          <ModalForm onSubmit={handleSubmit}>
            <ModalContent className="flex flex-col gap-y-6 px-8 py-6">
              {inPersonFormTitles.length > 0 || hasSomeSelectedCompleted ? (
                <Banner className="rounded" theme="info">
                  <div className="flex flex-col text-xs">
                    {inPersonFormTitles.map((form, index) => (
                      <span key={`${index}_${form}`}>{form} is required to be signed in-person</span>
                    ))}
                    {hasSomeSelectedCompleted ? <span>Only incomplete forms will be sent</span> : null}
                  </div>
                </Banner>
              ) : null}

              <ToggleButtonList
                label={<span className="font-sansSemiBold pb-1">Recipient</span>}
                options={recipientOptions}
                selectedValue={recipientValue}
                onChange={(_e, option) => setRecipientValue(option.value)}
                shape="mediumPill"
                type="radio"
              />

              {recipientValue === "OTHER" ? (
                <FormFieldPhoneInput
                  label={<span className="font-sansSemiBold pb-2">Phone Number</span>}
                  value={otherPhone}
                  onValueChange={setOtherPhone}
                  error={validationResult.otherPhone.$error}
                />
              ) : null}

              <div className="flex flex-col gap-y-3">
                <div className="flex flex-col gap-y-1 text-xs">
                  <FormFieldLabel className="font-sansSemiBold" content="SMS Message" />
                  <p className="text-greyMedium">
                    Ensure {"{{FORMS_LINK}}"} is in your message where you want the link to be
                  </p>
                </div>

                <FormFieldTemplateVariablesTextarea
                  value={message}
                  templateVariables={formTemplateVariables}
                  onUpdateTemplate={(template) => setMessage(template)}
                  disableResize
                  rows={1}
                />
              </div>
            </ModalContent>

            <ModalFooter>
              <Button className="min-w-button" onClick={onRequestClose} theme="secondary">
                Cancel
              </Button>
              <AsyncButton
                className="min-w-button"
                isLoading={isSending}
                disabled={!canSendMessage}
                theme="primary"
                type="submit"
              >
                Send
              </AsyncButton>
            </ModalFooter>
          </ModalForm>
        </QueryResult>
      )}
    </Modal>
  );
};

const SentModalContent: FC<{ onRequestClose: Func }> = ({ onRequestClose }) => {
  return (
    <div className="flex flex-col min-h-0 flex-1 p-10 pb-6">
      <ModalContent>
        <div className="font-sansSemiBold text-base text-center">Your message has been sent</div>
      </ModalContent>
      <ModalFooter className="border-none">
        <Button className="min-w-button" onClick={onRequestClose}>
          Close
        </Button>
      </ModalFooter>
    </div>
  );
};
