import { useState, useCallback } from "react";
import { addMonths } from "date-fns";

export const useScheduleDatePickerYearsInView = () => {
  const [yearRange, setYearRange] = useState<number[]>([]);

  const handleMonthsDisplayChange = useCallback((openToDate: Date, monthsShown: number) => {
    let range: number[] = [];

    const currentYear = new Date().getFullYear();
    const selectedDayYear = openToDate.getFullYear();
    const lastShownMonthYear = addMonths(openToDate, monthsShown - 1).getFullYear();

    if (selectedDayYear === lastShownMonthYear) {
      range = [selectedDayYear];
    }

    range = [selectedDayYear, lastShownMonthYear];

    // For the schedule datepicker we don't care about public holidays in the past
    range = range.filter((year) => year >= currentYear);
    setYearRange(range);
  }, []);

  return {
    handleMonthsDisplayChange,
    yearRange,
  };
};
