import { WorkingHourItemVO } from "@libs/api/generated-api";
import { DayOfWeek, formatISOTimeAsAmPm } from "@libs/utils/date";
import { isString } from "@libs/utils/types";
import { TimeRangeMenuValidationOptions } from "components/UI/TimeRangeMenu";
import { groupBy } from "./groupBy";

export const daysOfWeek: DayOfWeek[] = [
  "MONDAY",
  "TUESDAY",
  "WEDNESDAY",
  "THURSDAY",
  "FRIDAY",
  "SATURDAY",
  "SUNDAY",
];

export type GroupedWorkingHourInterval = Pick<WorkingHourItemVO, "startTime" | "endTime"> & {
  id: string | number;
  isSelfBookable: boolean;
};

export type GroupedWorkingHours = {
  dayOfWeek: WorkingHourItemVO["dayOfWeek"];
  hours: GroupedWorkingHourInterval[];
  open: boolean;
};

export const groupWorkingHoursByDay = (data: WorkingHourItemVO[]): GroupedWorkingHours[] => {
  const days = groupBy(data, "dayOfWeek");

  return daysOfWeek.map((dayOfWeek) => {
    const dayHours = days[dayOfWeek];

    if (dayHours) {
      return {
        dayOfWeek,
        hours: dayHours.map((item) => {
          return {
            id: item.id ?? window.crypto.randomUUID(),
            endTime: item.endTime,
            startTime: item.startTime,
            isSelfBookable: Boolean(item.isSelfBookable),
          };
        }),
        open: true,
      };
    }

    return {
      dayOfWeek,
      hours: [
        { id: window.crypto.randomUUID(), startTime: "00:00:00", endTime: "00:00:00", isSelfBookable: false },
      ],
      open: false,
    };
  });
};

export const convertToWorkingHourItems = (data: GroupedWorkingHours[]): WorkingHourItemVO[] =>
  data
    .flatMap(({ open, hours, ...item }) => {
      if (!open) {
        return null;
      }

      return hours.map(({ id, ...hourData }) => {
        if (isString(id)) {
          return {
            ...item,
            ...hourData,
          };
        }

        return {
          ...item,
          ...hourData,
          id,
        };
      });
    })
    .filter(Boolean) as WorkingHourItemVO[];

export const minTimeErrorMessage = (minTime: string) =>
  `Start time must be after the previous end time, ${formatISOTimeAsAmPm(minTime)}`;

export const maxTimeErrorMessage = (maxTime: string) =>
  `End time must be before the next start time, ${formatISOTimeAsAmPm(maxTime)}`;

export const getMinMaxWorkingHourTime = (
  intervals: { endTime: string; startTime: string }[],
  index: number
) => {
  const minMax: TimeRangeMenuValidationOptions = { allowZeroDuration: true };

  if (intervals.length <= 1) {
    return minMax;
  }

  if (index > 0) {
    minMax.minTime = {
      value: intervals[index - 1].endTime,
      errorMessage: minTimeErrorMessage,
    };
  }

  if (index < intervals.length - 1) {
    minMax.maxTime = {
      value: intervals[index + 1].startTime,
      errorMessage: maxTimeErrorMessage,
    };
  }

  return minMax;
};

const getValue = (day: DayOfWeek, startDay: DayOfWeek = "MONDAY") => {
  let newDaysOfWeek = daysOfWeek;

  if (startDay !== newDaysOfWeek[0]) {
    const index = newDaysOfWeek.indexOf(startDay);
    const startWeek = newDaysOfWeek.slice(index, newDaysOfWeek.length);
    const finishWeek = newDaysOfWeek.slice(0, index);

    newDaysOfWeek = [...startWeek, ...finishWeek];
  }

  return newDaysOfWeek.indexOf(day);
};

export const sortGroupedWorkingHours = (groups: GroupedWorkingHours[]) => {
  return [...groups].sort((a, b) => getValue(a.dayOfWeek) - getValue(b.dayOfWeek));
};
