import { useMemo } from "react";
import { FamilyMemberResponse, PatientInsuranceSummaryVO } from "@libs/api/generated-api";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { HeaderCell, TableGrid } from "@libs/components/UI/GridTableComponents";
import { FamilyMembersTableRow } from "components/PatientProfile/PatientOverview/FamilyMembers/FamilyMembersTableRow";
import {
  getAllPrimaryInsurances,
  sortFamilyMembersByAge,
} from "components/PatientProfile/PatientOverview/utils";

interface Props {
  familyMembers: FamilyMemberResponse;
  onAddFamilyMember: () => void;
  patientFirstName: string;
  patientId: number;
}

const headers = [
  { id: "name", label: "Name", width: "250px" },
  {
    id: "insurance",
    label: "Insurance",
    tooltipContent: "The patient is covered by any insurance plans selected here",
    width: "1fr",
  },
  {
    id: "guardian",
    label: "Guardian",
    tooltipContent: "People selected here are the patient's designated Guardians",
    width: "1fr",
  },
  {
    id: "phiAccess",
    label: "PHI Access",
    tooltipContent: "People selected here will have access to the patient's Protected Health Information",
    width: "1fr",
  },
  { id: "link", label: "", width: "56px" },
];

export const FamilyMembersTable: React.FC<Props> = ({
  familyMembers,
  onAddFamilyMember,
  patientFirstName,
  patientId,
}) => {
  const primaryInsuranceByPatientId = useMemo(() => {
    const linkedPrimaryInsurances = getAllPrimaryInsurances(familyMembers);

    const insuranceByPatientId: Record<number, Record<number, PatientInsuranceSummaryVO>> = {};

    familyMembers.linkedFamilyMembers.forEach((linkedMember) => {
      insuranceByPatientId[linkedMember.memberPatientId] = {};
    });

    linkedPrimaryInsurances.forEach((insurance) => {
      insuranceByPatientId[insurance.subscriber.id][insurance.patientInsuranceSubscriberId] = insurance;
    });

    return insuranceByPatientId;
  }, [familyMembers]);

  return (
    <TableGrid columnWidths={headers.map(({ width }) => width)}>
      {headers.map((header) =>
        header.tooltipContent ? (
          <HeaderCell key={header.id} size="medium">
            {header.label}
            <Icon
              className="ml-2.5"
              SvgIcon={ErrorIcon}
              size="sm"
              tooltip={{
                content: header.tooltipContent,
              }}
            />
          </HeaderCell>
        ) : (
          <HeaderCell key={header.id} size="medium">
            {header.label}
          </HeaderCell>
        )
      )}
      {familyMembers.linkedFamilyMembers.sort(sortFamilyMembersByAge).map((familyMember, index) => (
        <FamilyMembersTableRow
          familyMember={familyMember}
          isLastRow={
            !familyMembers.unlinkedFamilyMembers.length &&
            index === familyMembers.linkedFamilyMembers.length - 1
          }
          isLinked={true}
          isSelf={familyMember.memberPatientId === patientId}
          key={familyMember.memberPatientId}
          onAddFamilyMember={onAddFamilyMember}
          patientFirstName={patientFirstName}
          patientId={patientId}
          primaryInsuranceByPatientId={primaryInsuranceByPatientId}
        />
      ))}
      {familyMembers.unlinkedFamilyMembers.length ? (
        <>
          <HeaderCell className="col-span-full" data-testid="unlinked-family-members-table" size="medium">
            <div className="pl-2">Unlinked</div>
          </HeaderCell>
          {familyMembers.unlinkedFamilyMembers.sort(sortFamilyMembersByAge).map((familyMember, index) => (
            <FamilyMembersTableRow
              familyMember={familyMember}
              isLastRow={index === familyMembers.unlinkedFamilyMembers.length - 1}
              isLinked={false}
              isSelf={false}
              key={familyMember.memberPatientId}
              onAddFamilyMember={onAddFamilyMember}
              patientFirstName={patientFirstName}
              patientId={patientId}
              primaryInsuranceByPatientId={primaryInsuranceByPatientId}
            />
          ))}
        </>
      ) : null}
    </TableGrid>
  );
};
