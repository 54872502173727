import { FC, FormEventHandler, useCallback, useState } from "react";
import { produce } from "immer";
import { LabRequest, LabVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { useBoolean } from "@libs/hooks/useBoolean";
import { parsePhoneNumber } from "@libs/utils/phone";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { Button } from "@libs/components/UI/Button";
import { FormFieldContainer } from "@libs/components/UI/FormFieldContainer";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Modal } from "@libs/components/UI/Modal";
import { FormFieldAddressAutocomplete } from "@libs/components/UI/FormFieldAddressAutocomplete";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent, FlyoverFooter, FlyoverForm } from "components/UI/FlyoverComponents";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";
import { FormFieldNumericInput } from "components/UI/FormFieldNumericInput";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { ToggleButtonList } from "components/UI/ToggleButtonList";
import { createLab, updateLab } from "api/lab/mutations";
import { handleError } from "utils/handleError";
import { useFocusOnce } from "hooks/useFocusOnce";
import { getLab } from "api/lab/queries";
import { useFileUpload } from "hooks/useFileUpload";
import { AttachFilesContent } from "components/UI/AttachModalContent/AttachFilesContent";
import { TimeInput } from "components/UI/TimeInput";
import { Note } from "components/LabCases/LabComponents";
import { AttachScanModalContent } from "components/UI/AttachModalContent/AttachScanModalContent";
import { useEnvContext } from "contexts/EnvContext";

type ShippingMethod = LabVO["shippingMethod"];

const MAX_NOTE_LENGTH = 400;
const PREVIOUSLY_UPLOADED_FILENAME = "Uploaded File";

export const LabFlyover: FC<{ labUuid?: LabVO["uuid"]; onSave: Func; onClose: Func }> = ({
  labUuid,
  onSave,
  onClose,
}) => {
  const { REACT_APP_GOOGLE_API_KEY } = useEnvContext();
  const isEditing = labUuid != null;
  const { practiceId } = useAccount();
  const [formFile, setFormFile] = useState<File>();

  const [draft, setDraft] = useState<LabRequest>({
    name: "",
    openDaysOfWeek: ["MONDAY", "TUESDAY", "WEDNESDAY", "THURSDAY", "FRIDAY"],
  });

  const labPopulated = useBoolean(false);

  const handleUpdateDraft = useCallback(
    (newDraft: Partial<LabRequest>) => {
      setDraft((prevDraft) => ({ ...prevDraft, ...newDraft }));
    },
    [setDraft]
  );

  // Focus the name field. Called after flyover is shown and its transition ends.
  const { ref, handleFocus } = useFocusOnce<HTMLInputElement>();

  const [createLabMutation, updateLabMutation] = useApiMutations([createLab, updateLab]);
  const [labQuery] = useApiQueries([
    getLab({
      args: { practiceId, labUuid: labUuid as string },
      queryOptions: { enabled: isEditing },
    }),
  ]);

  const validation = useValidation(
    { name: draft.name },
    { name: { $validations: [{ $v: required, $error: "Name is required" }] } }
  );

  const { uploadFile, isUploading } = useFileUpload();

  const handleSave: FormEventHandler = useCallback(
    async (e) => {
      e.preventDefault();

      if (!validation.validate().$isValid) {
        return;
      }

      let orderFormFile: LabRequest["orderFormFile"];

      if (formFile && formFile.name !== PREVIOUSLY_UPLOADED_FILENAME) {
        try {
          const { contentType, encryptedFileKey } = await uploadFile(formFile);

          orderFormFile = { contentType, encryptedFileKey };
        } catch (error) {
          handleError(error);

          return;
        }
      }

      try {
        await (isEditing
          ? updateLabMutation.mutateAsync({
              practiceId,
              labUuid,
              data: { ...draft, orderFormFile },
            })
          : createLabMutation.mutateAsync({
              practiceId,
              data: { ...draft, orderFormFile },
            }));
        onSave();
      } catch (error) {
        handleError(error);
      }
    },
    [
      createLabMutation,
      draft,
      formFile,
      isEditing,
      labUuid,
      onSave,
      practiceId,
      updateLabMutation,
      uploadFile,
      validation,
    ]
  );

  useSyncOnce((lab) => {
    handleUpdateDraft({ ...lab });

    if (lab.hasOrderFormFile) {
      setFormFile(new File([], PREVIOUSLY_UPLOADED_FILENAME));
    }

    labPopulated.on();
  }, labQuery.data);

  return (
    <Flyover
      title={`${isEditing ? "Edit" : "Add"} Lab`}
      onClose={onClose}
      size="md"
      onTransitionEnd={(e, { show }) => {
        show && handleFocus();
      }}
    >
      {/* eslint-disable-next-line complexity */}
      {({ close }) => (
        <FlyoverForm fieldLayout="labelOut" onSubmit={handleSave}>
          <FlyoverContent>
            <QueryResult queries={[labQuery]} loading={<LoadingOverlaySpinner />}>
              <div className="grid grid-cols-2 gap-6">
                <FormFieldInput
                  label="Name"
                  className="col-span-full"
                  autoComplete="off"
                  required
                  value={draft.name}
                  error={validation.result.name.$error}
                  onChange={(e) => handleUpdateDraft({ name: e.target.value })}
                  ref={ref}
                />
                <FormFieldInput
                  label="Contact Person"
                  value={draft.contactPerson}
                  onChange={(e) => handleUpdateDraft({ contactPerson: e.target.value })}
                />
                <FormFieldInput
                  label="Website"
                  value={draft.website}
                  onChange={(e) => handleUpdateDraft({ website: e.target.value })}
                />
                <FormFieldPhoneInput
                  label="Phone"
                  value={draft.phone}
                  onChange={(e) => handleUpdateDraft({ phone: parsePhoneNumber(e.target.value) })}
                />
                <FormFieldPhoneInput
                  label="Fax"
                  value={draft.fax}
                  onChange={(e) => handleUpdateDraft({ fax: parsePhoneNumber(e.target.value) })}
                />
                {/*
                  When editing, wait for the lab draft to be populated with an address, if any, or
                  `defaultValue` will be set to `undefined` initially and won't change after the
                  lab draft is populated.
                */}
                {(!isEditing || labPopulated.isOn) && (
                  <FormFieldAddressAutocomplete
                    label="Address"
                    apiKey={REACT_APP_GOOGLE_API_KEY}
                    onSelect={(newAddress) => {
                      // `newAddress.raw` may be an empty string (e.g. when
                      // address field is cleared).
                      if (!newAddress.raw) {
                        setDraft((prev) =>
                          produce(prev, (newDraft) => {
                            delete newDraft.address;
                          })
                        );

                        return;
                      }

                      handleUpdateDraft({
                        address: {
                          address1: newAddress.address,
                          city: newAddress.locality,
                          zip: newAddress.zip,
                          state: newAddress.state,
                          country: newAddress.country,
                        },
                      });
                    }}
                    defaultValue={draft.address?.address1}
                    className="col-span-full"
                  />
                )}
                <div className="col-span-full">
                  <DaysOfWeekField
                    daysOpen={draft.openDaysOfWeek ?? []}
                    onChange={(newDays) => handleUpdateDraft({ openDaysOfWeek: newDays })}
                  />
                </div>
                <TimeInput
                  label="Open Time"
                  value={draft.openTime}
                  onChange={(val) => handleUpdateDraft({ openTime: val })}
                />
                <TimeInput
                  label="Close Time"
                  value={draft.closeTime}
                  onChange={(val) => handleUpdateDraft({ closeTime: val })}
                />
                <ShippingMethodField
                  value={draft.shippingMethod}
                  onChange={(val) => handleUpdateDraft({ shippingMethod: val })}
                />
                <EstimatedCaseTimeField
                  value={draft.estimatedCaseTimeInDays?.toString()}
                  onChange={(val) =>
                    handleUpdateDraft({ estimatedCaseTimeInDays: val ? Number(val) : undefined })
                  }
                />
                <UploadOrderForm
                  value={formFile}
                  className="col-span-full"
                  onFile={setFormFile}
                  onClear={() => setFormFile(undefined)}
                />
                <Note
                  note={draft.notes ?? ""}
                  max={MAX_NOTE_LENGTH}
                  onChange={(val) => handleUpdateDraft({ notes: val })}
                  className="col-span-full"
                />
              </div>
            </QueryResult>
          </FlyoverContent>
          <FlyoverFooter>
            <Button className="min-w-button" theme="secondary" onClick={close}>
              Cancel
            </Button>
            <Button
              disabled={
                !(validation.result.$isValid ?? true) ||
                createLabMutation.isLoading ||
                updateLabMutation.isLoading ||
                labQuery.isInitialLoading ||
                isUploading
              }
              className="min-w-button"
              type="submit"
            >
              {isEditing ? "Save" : "Create"}
            </Button>
          </FlyoverFooter>
        </FlyoverForm>
      )}
    </Flyover>
  );
};

const DaysOfWeekField: FC<{
  daysOpen: NonNullable<LabRequest["openDaysOfWeek"]>;
  onChange: (newDays: NonNullable<LabRequest["openDaysOfWeek"]>) => void;
}> = ({ daysOpen, onChange }) => {
  return (
    <div className="flex flex-col gap-y-2">
      <FormFieldLabel content={<div className="font-sansSemiBold text-xs">Days Open</div>} />
      <ToggleButtonList
        shape="mediumPill"
        type="checkbox"
        selectedValues={new Set(daysOpen)}
        options={[
          { label: "Su", value: "SUNDAY" },
          { label: "Mo", value: "MONDAY" },
          { label: "Tu", value: "TUESDAY" },
          { label: "We", value: "WEDNESDAY" },
          { label: "Th", value: "THURSDAY" },
          { label: "Fr", value: "FRIDAY" },
          { label: "Sa", value: "SATURDAY" },
        ]}
        onChange={(updated) => onChange([...updated])}
      />
    </div>
  );
};

const ShippingMethodField: FC<{
  value: ShippingMethod | undefined;
  onChange: (newValue: ShippingMethod | undefined) => void;
}> = ({ value, onChange }) => {
  return (
    <FormFieldSelect
      label="Shipping Method"
      placeholder="Choose one"
      value={value}
      onChange={(newValue) => onChange(newValue?.value)}
      options={[
        { label: "USPS", value: "USPS" },
        { label: "FedEx", value: "FEDEX" },
        { label: "UPS", value: "UPS" },
        { label: "Courier", value: "COURIER" },
        { label: "Other", value: "OTHER" },
      ]}
    />
  );
};

const EstimatedCaseTimeField: FC<{ value?: string; onChange: (days?: string) => void }> = ({
  value,
  onChange,
}) => {
  return (
    <FormFieldNumericInput
      label="Estimated Case Time"
      value={value}
      onChange={(e) => {
        onChange(e.target.value);
      }}
    >
      <div
        className={`
          absolute
          right-2
          top-1/2
          -translate-y-1/2
          text-xs
          font-sansSemiBold
        `}
      >
        Days
      </div>
    </FormFieldNumericInput>
  );
};

const UploadOrderForm: FC<{
  value: File | undefined;
  className?: string;
  onFile: (file: File) => void;
  onClear: Func;
}> = ({ value, className, onFile, onClear }) => {
  const scanModal = useBoolean(false);

  return (
    <div className={cx("flex flex-col gap-y-2", className)}>
      <FormFieldLabel content={<div className="font-sansSemiBold text-xs">Upload Order Form</div>} />
      {value ? (
        <FormFieldContainer className="flex items-center justify-between p-3">
          <div className="text-xs">{value.name}</div>
          <ButtonIcon onClick={onClear} SvgIcon={DeleteIcon} size="lg" theme="primary" />
        </FormFieldContainer>
      ) : (
        <AttachFilesContent
          onDropFiles={(acceptedFiles) => onFile(acceptedFiles[0])}
          onRequestScanDocument={scanModal.on}
        />
      )}
      {scanModal.isOn && (
        <Modal size="xs" title="Scan Order Form">
          <AttachScanModalContent
            onScanFiles={(files) => onFile(files[0])}
            onRequestClose={scanModal.off}
            scanAs="PDF"
          />
        </Modal>
      )}
    </div>
  );
};
