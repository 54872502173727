import { FC, useMemo } from "react";
import { LoaderFunctionArgs, redirect, redirectDocument } from "react-router-dom";
import { getApiClient } from "@libs/api/clients";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { AccountTokenVO, UserAccountVO, SupportTokenResponse } from "@libs/api/generated-api";
import { captureException } from "@sentry/react";
import { isHttpResponseError } from "@libs/utils/isHttpResponseError";
import { useCurriedLoaderData } from "@libs/router/hooks";
import { getAccountTokenStorage } from "@libs/storage/accountToken";
import { parseQueryParams } from "@libs/router/url";
import { getTokensForApi, getTokensForAuthCheck } from "@libs/auth/getTokens";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { AccountToken } from "@libs/storage/types";
import { useQueryParams } from "hooks/useQueryParams";
import {
  SelectAccountPayload,
  postAuthChannelMessage,
  useAuthChannelListeners,
} from "hooks/useAuthChannelListeners";
import { getRedirectToSignedInUrl, paths, routesConfig } from "utils/routing/paths";
import { isSupportIdentityToken } from "utils/auth";
import { headers } from "utils/headers";
import { getAccountsStorage } from "storage/accounts";
import { getPracticeActivityStorage } from "storage/activity";
import { isMFARequiredError } from "utils/isMfaRequiredError";
import { SessionIdleTimer } from "components/Main/SessionIdleTimer";
import { PracticeApiClientProvider } from "contexts/PracticeApiClientProvider";
import { SelectAccountPage } from "components/Main/SelectAccountPage";
import { SupportSelectPracticePage } from "components/Main/SupportSelectPracticePage";
import { PracticeRouterContext } from "router/types";

const handleAccountSelected = (
  storage: Storage,
  account: UserAccountVO,
  email: string,
  rememberMe?: boolean
) => {
  const accountsStorage = getAccountsStorage(storage);
  const activityStorage = getPracticeActivityStorage(storage);

  activityStorage.setRecentlyActive();

  if (email) {
    if (rememberMe === true) {
      accountsStorage.addAccount({
        email,
        fullName: account.name.fullDisplayName,
        profilePic: account.avatar?.thumbnail || "",
      });
    } else if (rememberMe === false) {
      accountsStorage.removeAccount(email);
    }
  }

  postAuthChannelMessage({
    type: "selectAccount",
    userId: account.id,
  });
};

export const handleSupportAccountSelected = (
  storage: Storage,
  email: string,
  { token, account }: SupportTokenResponse
) => {
  const accountTokenStorage = getAccountTokenStorage(storage);

  accountTokenStorage.setAccountToken(email, {
    ...token,
    practiceId: account.practice.id,
    practiceUuid: account.practice.uuid,
  });

  postAuthChannelMessage({
    type: "selectAccount",
    userId: account.id,
  });
};

export const loader =
  ({ baseUrl, storage }: PracticeRouterContext) =>
  // eslint-disable-next-line max-statements, complexity
  async ({ request }: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    const { parsed: query } = parseQueryParams(routesConfig.selectAccount.query, url.searchParams);

    const accountTokenStorage = getAccountTokenStorage(storage.localStorage);

    const tokens = await getTokensForAuthCheck(storage.localStorage);
    const activityStorage = getPracticeActivityStorage(storage.localStorage);

    if (!tokens.identity) {
      return redirect(paths.signIn());
    }

    if (!activityStorage.isRecentlyActive()) {
      return redirect(paths.signOut());
    }

    if (isSupportIdentityToken(tokens.identity)) {
      return {
        accounts: [],
        email: tokens.identity.email,
        isSupportUser: true,
      };
    }

    const apiClient = getApiClient({
      baseUrl,
      headers,
      onRequestTokens: () => getTokensForApi(storage.localStorage),
    });

    let accounts: UserAccountVO[] = [];

    try {
      const accountsResponse = await apiClient.user.getUserAccounts({ userTypes: ["EMPLOYEE"] });

      accounts = accountsResponse.data.data;
    } catch (e) {
      if (isHttpResponseError(e) && isMFARequiredError(e)) {
        return redirect(
          paths.mfaSetup({
            rememberMe: query.rememberMe,
            returnUrl: query.returnUrl,
            lastUserId: query.lastUserId,
          })
        );
      }

      captureException(e);

      return redirect(paths.signOut({ signOutReason: "GET_USER_ACCOUNTS_ERROR" }));
    }

    if (accounts.length === 0) {
      return redirect(paths.signOut({ signOutReason: "NO_USER_ACCOUNTS_ERROR" }));
    }

    if (accounts.length === 1 && !accounts[0].pinRequired) {
      let newToken: AccountTokenVO | undefined;

      try {
        const accountTokenResponse = await apiClient.user.issueAccountToken({
          userId: accounts[0].id,
          rememberMe: true,
        });

        newToken = accountTokenResponse.data.data;
      } catch (e) {
        captureException(e);
      }

      if (!newToken) {
        return redirect(paths.signOut({ signOutReason: "ISSUE_TOKEN_ERROR" }));
      }

      const accountToken: AccountToken = {
        userId: newToken.userId,
        expiresAt: newToken.expiresAt,
        token: newToken.token,
        practiceId: accounts[0].practice.id,
        practiceUuid: accounts[0].practice.uuid,
      };

      accountTokenStorage.setAccountToken(tokens.identity.email, accountToken);

      handleAccountSelected(storage.localStorage, accounts[0], tokens.identity.email, query.rememberMe);

      return redirectDocument(
        getRedirectToSignedInUrl(accountToken.userId, query.lastUserId, query.returnUrl)
      );
    }

    return {
      accounts,
      email: tokens.identity.email,
      isSupportUser: false,
    };
  };

export const SelectAccountRoute: FC = () => {
  const { query } = useQueryParams("selectAccount");
  const { email, accounts, isSupportUser } = useCurriedLoaderData<typeof loader>();
  const { localStorage } = useStorageContext();

  const authChannelEvents = useMemo(
    () => ({
      onSelectAccount: (event: SelectAccountPayload) => {
        window.location.assign(getRedirectToSignedInUrl(event.userId, query.lastUserId, query.returnUrl));
      },
      onSignOut: () => {
        const params = { returnUrl: query.returnUrl, lastUserId: query.lastUserId };

        if (isSupportUser) {
          window.location.assign(paths.supportSignIn(params));
        } else {
          window.location.assign(paths.signIn(params));
        }
      },
    }),
    [query.lastUserId, query.returnUrl, isSupportUser]
  );

  useAuthChannelListeners(authChannelEvents);

  usePageTitle(isSupportUser ? "Select Practice" : "Select Account");

  return (
    <PracticeApiClientProvider>
      {isSupportUser ? (
        <SupportSelectPracticePage
          onPracticeSelected={({ token, account }) => {
            handleSupportAccountSelected(localStorage, email, { token, account });
            window.location.assign(getRedirectToSignedInUrl(account.id, query.lastUserId, query.returnUrl));
          }}
        />
      ) : (
        <SelectAccountPage
          accounts={accounts}
          onAccountSelected={(account) => {
            handleAccountSelected(localStorage, account, email, query.rememberMe);
            window.location.assign(getRedirectToSignedInUrl(account.id, query.lastUserId, query.returnUrl));
          }}
        />
      )}
      <SessionIdleTimer />
    </PracticeApiClientProvider>
  );
};
