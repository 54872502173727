import { InfiniteData } from "@tanstack/react-query";
import { uniqueBy } from "@libs/utils/array";
import { ApiResponse } from "@libs/@types/api";

export const flattenCollectionByCarrier = <
  Entry extends { insuranceCarrierId: number },
  Subtotal extends { carrier: { id: number } },
  ResponsePage extends {
    entries: Entry[];
    subtotals: Subtotal[];
  },
>(
  data?: InfiniteData<{
    data: ResponsePage;
    apiResponse: ApiResponse<ResponsePage>;
  }>
) => {
  const rowsPerCarrier: Record<number, Entry[] | undefined> = {};
  const rows = data?.pages.flatMap((item) => item.data.entries) ?? [];

  for (const row of rows) {
    row.insuranceCarrierId;

    if (!rowsPerCarrier[row.insuranceCarrierId]) {
      rowsPerCarrier[row.insuranceCarrierId] = [];
    }

    rowsPerCarrier[row.insuranceCarrierId]?.push(row);
  }

  const carriers = data?.pages.flatMap((item) => item.data.subtotals) ?? [];

  // Carriers may overflow out of a given page, must de-dupe:
  const uniqueCarriers = uniqueBy(carriers, (val) => val.carrier.id);

  return uniqueCarriers.map((item) => {
    return {
      subtotal: item,
      rows: rowsPerCarrier[item.carrier.id] ?? [],
    };
  });
};
