import React from "react";
import { useImageEditorContext } from "components/PatientProfile/Imaging/ImageEditor/ImageEditorContext";
import { DrawLayer } from "components/PatientProfile/Imaging/types/imageEditor";

export const useToggleImageEditorLayers = () => {
  const { updateLayersShown, editor, layersShown } = useImageEditorContext();

  const toggleLayer = React.useCallback(
    (layer: DrawLayer, forceShow?: boolean) => {
      const newLayersShown = new Set(layersShown);
      const hidden = forceShow === undefined ? newLayersShown.has(layer) : !forceShow;
      const canvas = editor.current?.getInstance();

      if (hidden) {
        newLayersShown.delete(layer);
      } else {
        newLayersShown.add(layer);
      }

      if (canvas && layer === "annotations") {
        const canvasObjects = canvas.getObjects();

        canvas.discardActiveObject();
        for (const obj of canvasObjects) {
          obj.set("visible", !hidden);
          obj.set("selectable", !hidden);
        }
        canvas.requestRenderAll();
      }

      updateLayersShown(newLayersShown);
    },
    [editor, layersShown, updateLayersShown]
  );

  return {
    toggleLayer,
  };
};
