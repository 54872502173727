import React, { FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import { produce } from "immer";
import { toast } from "react-toastify";
import {
  AppointmentCategoryVO,
  AppointmentRequest,
  AppointmentVO,
  DentalProcedureVO,
  LabCaseStatusVO,
  PatientProcedureVO,
  PatientToothVO,
  PreferenceVO,
  ProviderVO,
  RoomVO,
  TagVO,
} from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useValidation } from "@libs/hooks/useValidation";
import { UseBooleanResult, useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { RadioList } from "@libs/components/UI/RadioList";
import { useAccount } from "@libs/contexts/AccountContext";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { Form } from "@libs/components/UI/Form";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";
import { AppointmentProceduresAndIconsRow } from "components/ScheduleAppointments/AppointmentProceduresAndIconsRow";

import {
  useAppointmentSlotFinder,
  useAsapSlotPreferences,
} from "components/ScheduleAppointments/useAppointmentSlotFinder";
import { getAppointmentFormSchema } from "components/ScheduleAppointments/appointmentFormSchema";
import { AddNewProcedureModal } from "components/ScheduleAppointments/AddNewProcedure";
import {
  AppointmentProcedure,
  AppointmentProcedures,
  isNewProcedure,
  isSavedProcedure,
  isTreatmentPlan,
} from "components/ScheduleAppointments/useAppointmentCategoriesAndProcedures";
import { useItemModal } from "hooks/useItemModal";
import { EditPatientProcedureModal } from "components/Charting/EditPatientProcedureModal";
import { AddAppointmentProceduresModal } from "components/Charting/AddAppointmentProcedureModal";
import {
  DraftPatientProcedureRequest,
  draftToSavePatientProcedure,
} from "components/Charting/draftPatientProcedure";
import { getPreferencePutData, UNSCHEDULED_APPT_DATE } from "components/ScheduleAppointments/utils";
import { useAppointmentDurationContext } from "components/ScheduleAppointments/AppointmentDurationContext";
import { AppointmentAsapPreferences } from "components/ScheduleAppointments/AppointmentAsapPreferences";
import { AppointmentSlotFinderRow } from "components/ScheduleAppointments/AppointmentSlotFinderRow";
import { AppointmentSlotTabs, AppointmentSlotTab } from "components/ScheduleAppointments/AppointmentSlotTabs";
import { AppointmentSlotEditor } from "components/ScheduleAppointments/useAppointmentSlotEditor";
import { AppointmentFormEditSlot } from "components/ScheduleAppointments/AppointmentFormEditSlot";
import { BaseFormSection } from "components/UI/FormSection";

import { LabCaseFlyover } from "components/LabCases/LabCaseFlyover";
import { archiveLabCase } from "api/lab/mutations";
import { handleError } from "utils/handleError";
import { EditEstimatesFlyover } from "components/Charting/EditEstimatesFlyover";
import { useIsDirty } from "hooks/useIsDirty";

const editProcedureValidationOptions = { provider: false };

interface Props {
  providers: ProviderVO[];
  categories: AppointmentCategoryVO[];
  rooms: RoomVO[];
  teeth: PatientToothVO[] | undefined;
  formId: string;
  initialComment: string;
  confirmationAndReminders: UseBooleanResult;
  addToAsapList?: boolean;
  color: string;
  hasCustomColor: boolean;
  selectedCategoryId: number;
  procedures: AppointmentProcedures;
  patientId: number;
  asapPreference?: PreferenceVO;
  slotEditor: AppointmentSlotEditor;
  savedAppointment?: AppointmentVO;
  tagIds: number[];
  autoTagIds?: number[];
  tags: TagVO[];
  onUpdateTags: (newTagIds: number[]) => void;
  onProceduresAdded: (dentalprocedures: DentalProcedureVO[], patientProcedures: PatientProcedureVO[]) => void;
  onSelectCategory: (id: number) => void;
  onSubmit: (appointment: AppointmentRequest) => void;
  onValidationChange: (isValid: boolean) => void;
  onColorChange: (newColor: string | undefined) => void;
  onRemoveProcedure: (id: string | number) => void;
  onSavedProcedureSwitch: (id: number, newProcedure: PatientProcedureVO) => void;
  onNewProcedureSwitch: (
    id: string,
    newProcedure: { draft: DraftPatientProcedureRequest; dentalProcedure: DentalProcedureVO }
  ) => void;
  onUpdateProcedureDraft: (id: string, newProcedure: DraftPatientProcedureRequest) => void;
  onDirty: Func;
  initialTab?: AppointmentSlotTab;
  patientSlot: React.ReactNode;
  findFromDate?: string;
}

export const appointmentFormCxStyles = {
  contentContainer: "flex-1 flex flex-col",
  detailsContainer: "w-96",
  padding: "p-6",
  row: "flex w-full",
};

const DeleteLabCaseConfirmation: React.FC<{
  isConfirming: boolean;
  onConfirm: (method: "remove-procedure" | "archive-lab-case") => void;
  onClose: Func;
}> = ({ isConfirming, onConfirm, onClose }) => {
  const [selectedValue, setSelectedValue] = useState<"remove-procedure" | "archive-lab-case">(
    "remove-procedure"
  );

  return (
    <Modal size="2xs" onClose={onClose}>
      <ModalContent padding="confirm">
        <div className="flex flex-col gap-y-6">
          <div className="flex flex-col gap-y-2 items-center">
            <div className="text-sm font-sansSemiBold">Remove Lab Case</div>
            <div className="text-xs text-center">
              Do you also want to remove the procedure that this lab case is attached to in this appointment?
            </div>
          </div>
          <div>
            <RadioList
              layout="vert"
              verticalLayout="comfortable"
              options={[
                {
                  label: "Yes, remove lab case and procedure(s).",
                  value: "remove-procedure",
                },
                {
                  label: "No, remove lab case only and archive it.",
                  value: "archive-lab-case",
                },
              ]}
              onChange={(e, option) => setSelectedValue(option.value)}
              selectedValue={selectedValue}
            />
          </div>
        </div>
      </ModalContent>
      <ModalFooter actions>
        <Button theme="secondary" onClick={onClose} className="min-w-button">
          Cancel
        </Button>
        <AsyncButton
          isLoading={isConfirming}
          onClick={() => {
            onConfirm(selectedValue);
          }}
          className="min-w-button"
        >
          Remove
        </AsyncButton>
      </ModalFooter>
    </Modal>
  );
};

// eslint-disable-next-line complexity
export const AppointmentForm: React.FC<Props> = ({
  categories,
  providers,
  rooms,
  teeth,
  formId,
  patientId,
  color,
  hasCustomColor,
  selectedCategoryId,
  procedures,
  initialComment,
  confirmationAndReminders,
  asapPreference: serverAsapPreferences,
  savedAppointment,
  patientSlot,
  slotEditor,
  tagIds,
  tags,
  autoTagIds,
  onUpdateTags,
  onProceduresAdded,
  onSelectCategory,
  onValidationChange,
  onColorChange,
  onSubmit,
  onRemoveProcedure,
  onUpdateProcedureDraft,
  onSavedProcedureSwitch,
  onNewProcedureSwitch,
  onDirty,
  addToAsapList = false,
  initialTab = "setAppointment",
  findFromDate,
}) => {
  const [tabSelected, setTabSelected] = React.useState(initialTab);
  const duration = useAppointmentDurationContext();

  const newProcedureModal = useBoolean(false);
  const [comment, setComment] = useState(initialComment);
  const editProcedureModal = useItemModal<{ id: number; dentalProcedureId: number }>(null);
  const addProcedureModal = useItemModal<{
    id: string;
    draftPatientProcedure: DraftPatientProcedureRequest;
    dentalProcedure: DentalProcedureVO;
  }>(null);
  const editProcedureEstimatesFlyover = useItemModal<{ id: number }>(null);
  const labCaseFlyover = useItemModal<LabCaseStatusVO["uuid"]>(null);
  const labCaseDeleteConfirmation = useItemModal<PatientProcedureVO>(null);

  const {
    findSlotSelections,
    availableRoomsQuery,
    openSlotsQuery,
    hasChangedPreferences,
    fetchOpenSlots,
    updateSlotPreferences,
    setFindSlotSelections,
    slotPreferences,
    hygienists,
    dentists,
  } = useAppointmentSlotFinder(providers, findFromDate);

  const {
    updateSlotPreferences: updateAsapSlotPreferences,
    slotPreferences: asapSlotPreferences,
    addToAsapList: asapList,
    asapComment,
    handleAsapCommentUpdated,
  } = useAsapSlotPreferences({
    providers,
    initialState: { asap: addToAsapList, preference: serverAsapPreferences },
  });

  const slotEditorSelections = slotEditor.selections;

  const selections = useMemo(() => {
    const slotSelections =
      tabSelected === "setAppointment"
        ? { ...slotEditorSelections }
        : {
            date: findSlotSelections.date,
            dentistId: findSlotSelections.dentistId,
            startTime: findSlotSelections.startTime,
            roomId: findSlotSelections.roomId,
            hygienistId: findSlotSelections.hygienistId,
          };

    return {
      ...slotSelections,
      duration: duration.read,
      patientId,
    };
  }, [findSlotSelections, duration.read, tabSelected, slotEditorSelections, patientId]);

  const handleEditProcedure = useCallback(
    (procedure: AppointmentProcedure) => {
      if (isSavedProcedure(procedure)) {
        editProcedureModal.open({ id: procedure.id, dentalProcedureId: procedure.dentalProcedureId });
      } else {
        addProcedureModal.open({
          id: procedure.id,
          draftPatientProcedure: { ...procedure.draft, providerId: selections.dentistId },
          dentalProcedure: procedure.dentalProcedure,
        });
      }
    },
    [editProcedureModal, addProcedureModal, selections.dentistId]
  );

  const handleEditEstimates = useCallback(
    (procedure: AppointmentProcedure) => {
      isSavedProcedure(procedure) && editProcedureEstimatesFlyover.open({ id: procedure.id });
    },
    [editProcedureEstimatesFlyover]
  );

  const schema = useMemo(
    () =>
      getAppointmentFormSchema({
        teeth,
        ignoreSlotEdits: asapList.isOn && !selections.date,
        startTime: selections.startTime,
        date: selections.date,
      }),
    [teeth, asapList.isOn, selections.date, selections.startTime]
  );

  const formValidation = useValidation({ ...selections, procedures }, schema, { isValidating: true });

  const validateForm = formValidation.validate;

  const [archiveLabCaseMutation] = useApiMutations([archiveLabCase]);

  const { practiceId } = useAccount();
  const handleArchiveLabCase = useCallback(
    (procedure: PatientProcedureVO) => {
      procedure.labCaseStatus?.uuid &&
        archiveLabCaseMutation.mutate(
          { practiceId, labCaseUuid: procedure.labCaseStatus.uuid },
          {
            onSuccess: () => {
              onSavedProcedureSwitch(
                procedure.id,
                produce(procedure, (draft) => {
                  delete draft.labCaseStatus;
                })
              );
              labCaseDeleteConfirmation.close();
            },
            onError: handleError,
          }
        );
    },
    [archiveLabCaseMutation, labCaseDeleteConfirmation, onSavedProcedureSwitch, practiceId]
  );

  const editableValues = useMemo(
    () => {
      const allProcedures = [...procedures.category, ...procedures.additional];
      const alreadySaved = allProcedures.filter(isSavedProcedure).map((p) => p.id);
      const newProcedures = allProcedures.filter(isNewProcedure);

      return {
        patientId: selections.patientId,
        startTime: selections.startTime,
        roomId: selections.roomId,
        dentistId: selections.dentistId,
        ignoreFeeCalcError: false,
        date: selections.date ?? "",
        // In case of ASAP appointment, there may be no duration selection
        duration: selections.duration || duration.read,
        providerId: selections.hygienistId || selections.dentistId,
        appointmentCategoryId: isTreatmentPlan(selectedCategoryId) ? undefined : selectedCategoryId,
        asap: asapList.isOn,
        preference: asapList.isOn ? getPreferencePutData(asapSlotPreferences, asapComment) : undefined,
        newProcedures,
        sendConfirmationAndReminders: confirmationAndReminders.isOn,
        comments: comment,
        color: hasCustomColor ? color : undefined,
        editedProcedureIds: alreadySaved,
        tags: {
          customIds: tagIds,
        },
      };
    },
    // This is important because if any of these deps change the form is considered dirty
    // and will prompt the user to confirm they want to discard changes.
    [
      procedures.category,
      procedures.additional,
      selections,
      asapList.isOn,
      duration.read,
      selectedCategoryId,
      asapSlotPreferences,
      asapComment,
      confirmationAndReminders.isOn,
      comment,
      color,
      hasCustomColor,
      tagIds,
    ]
  );

  const handleSubmit = useCallback(
    // eslint-disable-next-line complexity
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!validateForm().$isValid) {
        return;
      }

      if (teeth) {
        const isUnscheduledAsap = !editableValues.date && editableValues.asap;

        onSubmit({
          ...editableValues,
          ...(isUnscheduledAsap
            ? {
                date: UNSCHEDULED_APPT_DATE,
                startTime: "12:00:00",
                // id values will be 0 if user is adding 'unscheduled' appt.  In this case, just choose the first value
                providerId: providers[0].id,
                dentistId: undefined,
                roomId: rooms[0].id,
              }
            : undefined),
          newProcedures: editableValues.newProcedures.map(({ draft, dentalProcedure }) => ({
            ...draftToSavePatientProcedure(draft, dentalProcedure, teeth),
            dentalProcedureId: dentalProcedure.id,
            providerId: isUnscheduledAsap
              ? providers.find(({ jobCategory }) => jobCategory === "DENTIST")?.id
              : editableValues.dentistId,
          })),
        });
      }
    },
    [validateForm, teeth, providers, onSubmit, rooms, editableValues]
  );

  useIsDirty(editableValues, { onDirty });

  useEffect(() => {
    onValidationChange(Boolean(formValidation.result.$isValid));
  }, [formValidation.result.$isValid, onValidationChange]);

  const providerProps = {
    hygienists,
    dentists,
  };
  const disabled = !patientId || !teeth;

  return (
    <>
      <Form fieldLayout="labelIn" onSubmit={handleSubmit} className="h-full flex flex-col" id={formId}>
        <AppointmentProceduresAndIconsRow
          appointmentDate={slotEditor.selections.date}
          categories={categories}
          savedCategory={savedAppointment?.category}
          color={color}
          hasCustomColor={hasCustomColor}
          comment={comment}
          disabled={disabled}
          onAddProcedure={newProcedureModal.on}
          onColorChange={onColorChange}
          onCommentChange={setComment}
          onEditProcedure={handleEditProcedure}
          onEditEstimates={handleEditEstimates}
          onRemoveProcedure={onRemoveProcedure}
          onSelectCategory={onSelectCategory}
          patientSlot={patientSlot}
          procedures={procedures}
          selectedCategoryId={selectedCategoryId}
          teeth={teeth}
          onEditLabCase={labCaseFlyover.open}
          onDeleteProcedureWithLabCase={labCaseDeleteConfirmation.open}
          tagIds={tagIds}
          tags={tags}
          autoTagIds={autoTagIds}
          onUpdateTags={onUpdateTags}
        />
        <AppointmentAsapPreferences
          {...providerProps}
          disabled={disabled}
          asapComment={asapComment}
          addToAsapList={asapList.isOn}
          onAsapListToggle={asapList.toggle}
          onAsapCommentChange={handleAsapCommentUpdated}
          onAsapPreferencesChange={updateAsapSlotPreferences}
          asapSlotPreferences={asapSlotPreferences}
        />
        <AppointmentSlotTabs
          tabSelected={tabSelected}
          onTabSelected={setTabSelected}
          tabsDisabled={
            disabled ? ["setAppointment", "findAppointment"] : duration.read ? undefined : ["findAppointment"]
          }
        />
        {tabSelected === "setAppointment" ? (
          <div className={appointmentFormCxStyles.row}>
            <div className={appointmentFormCxStyles.contentContainer}>
              <BaseFormSection className="p-6 min-h-96">
                <AppointmentFormEditSlot
                  disabled={disabled}
                  rooms={rooms}
                  slotEditor={slotEditor}
                  providers={providers}
                  timeError={formValidation.result.duration.$error}
                  savedDentist={savedAppointment?.dentist}
                  savedProvider={savedAppointment?.provider}
                  savedRoom={savedAppointment?.room}
                />
              </BaseFormSection>
            </div>
            <VerticalDivider />
            <div className={appointmentFormCxStyles.detailsContainer} />
          </div>
        ) : (
          <AppointmentSlotFinderRow
            onFetchOpenSlots={fetchOpenSlots}
            slotPreferences={slotPreferences}
            openSlotsQuery={openSlotsQuery}
            hasChangedPreferences={hasChangedPreferences}
            rooms={rooms}
            {...providerProps}
            selections={findSlotSelections}
            availableRoomsQuery={availableRoomsQuery}
            onUpdateSlotPreferences={updateSlotPreferences}
            onSelectedSlotUpdated={setFindSlotSelections}
            disabled={disabled}
          />
        )}
        <div className={cx("flex-1", appointmentFormCxStyles.row)}>
          <div className={appointmentFormCxStyles.contentContainer} />
          <VerticalDivider />
          <div className={appointmentFormCxStyles.detailsContainer} />
        </div>
      </Form>
      {newProcedureModal.isOn ? (
        <AddNewProcedureModal
          onRequestClose={newProcedureModal.off}
          patientId={patientId}
          onAddProcedures={(newDentalProcedures, newPatientProcedures) => {
            onProceduresAdded(newDentalProcedures, newPatientProcedures);
            newProcedureModal.off();
          }}
        />
      ) : editProcedureModal.isOpen ? (
        <EditPatientProcedureModal
          onRequestClose={editProcedureModal.close}
          onSaved={(data) => {
            const item = editProcedureModal.item;

            if (item.dentalProcedureId !== data.dentalProcedureId) {
              onSavedProcedureSwitch(editProcedureModal.item.id, data);
            }

            editProcedureModal.close();
          }}
          patientProcedureId={editProcedureModal.item.id}
          patientId={patientId}
          validateOptions={editProcedureValidationOptions}
          editStatus={false}
          appointment={savedAppointment}
        />
      ) : addProcedureModal.isOpen ? (
        <AddAppointmentProceduresModal
          onRequestClose={addProcedureModal.close}
          onDone={(data, dp) => {
            const item = addProcedureModal.item;

            if (dp.id === item.dentalProcedure.id) {
              onUpdateProcedureDraft(item.id, data);
            } else {
              onNewProcedureSwitch(item.id, { draft: data, dentalProcedure: dp });
            }

            addProcedureModal.close();
          }}
          dentalProcedure={addProcedureModal.item.dentalProcedure}
          draftPatientProcedure={addProcedureModal.item.draftPatientProcedure}
          providers={providers}
          patientId={patientId}
        />
      ) : editProcedureEstimatesFlyover.isOpen ? (
        <EditEstimatesFlyover
          patientProcedureId={editProcedureEstimatesFlyover.item.id}
          onSaved={() => toast.success("Procedure estimates saved")}
          onClose={editProcedureEstimatesFlyover.close}
        />
      ) : labCaseFlyover.isOpen ? (
        <LabCaseFlyover
          labCaseUuid={labCaseFlyover.item}
          patientId={patientId}
          onClose={labCaseFlyover.close}
          onSave={labCaseFlyover.close}
        />
      ) : null}
      {labCaseDeleteConfirmation.isOpen && (
        <DeleteLabCaseConfirmation
          isConfirming={archiveLabCaseMutation.isLoading}
          onClose={labCaseDeleteConfirmation.close}
          onConfirm={(removeMethod) => {
            if (removeMethod === "remove-procedure") {
              onRemoveProcedure(labCaseDeleteConfirmation.item.id);
              labCaseDeleteConfirmation.close();
            } else {
              labCaseDeleteConfirmation.item.labCaseStatus &&
                handleArchiveLabCase(labCaseDeleteConfirmation.item);
            }
          }}
        />
      )}
    </>
  );
};
