import { FC } from "react";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { ReactComponent as Edit } from "@libs/assets/icons/edit.svg";
import { ReactComponent as Delete } from "@libs/assets/icons/delete.svg";

interface Props {
  onRequestDeleteBlock: Func;
  onRequestEditBlock: Func;
}

const cxStyles = {
  optionText: "block ml-2 whitespace-nowrap",
};

export const BlockCardMenu: FC<Props> = ({ onRequestDeleteBlock, onRequestEditBlock }) => {
  return (
    <>
      <MenuOptionButton name="Edit Block" onClick={onRequestEditBlock}>
        <Edit className="w-4 h-4 fill-greyDark" />
        <span className={cxStyles.optionText}>Edit</span>
      </MenuOptionButton>
      <MenuOptionButton name="Delete Block" onClick={onRequestDeleteBlock}>
        <Delete className="w-4 h-4 fill-greyDark" />
        <span className={cxStyles.optionText}>Delete</span>
      </MenuOptionButton>
    </>
  );
};
