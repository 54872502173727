import React, { FC } from "react";
import { PaymentProfileVO } from "@libs/api/generated-api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { Radio } from "@libs/components/UI/Radio";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import EmptySavedCards from "assets/images/empty-payment-cards.svg";
import { Text } from "components/PatientProfile/Billing/Ledger/Text";

import { getPaymentProfileTypeLabel } from "components/PatientProfile/Billing/billingUtils";
import { CreditCardLogo } from "./CreditCardLogo";

export const SavedCards: React.FC<{
  paymentProfiles: PaymentProfileVO[];
  onMarkAsDefaultClick: (paymentProfile: PaymentProfileVO) => void;
  onMarkAsFamilyClick: (paymentProfile: PaymentProfileVO) => void;
  onDeleteCardClick: (paymentProfile: PaymentProfileVO) => void;
}> = ({ paymentProfiles, onMarkAsDefaultClick, onMarkAsFamilyClick, onDeleteCardClick }) =>
  paymentProfiles.length === 0 ? (
    <div className="flex flex-col gap-y-3 items-center">
      <img alt="No Saved Cards" className="w-44 h-44" src={EmptySavedCards} />
      <Text>No Saved Cards</Text>
    </div>
  ) : (
    <div className="flex flex-col gap-y-4">
      {paymentProfiles.map((paymentProfile) => (
        <CreditCardItem
          key={paymentProfile.uuid}
          paymentProfile={paymentProfile}
          onMarkAsDefault={() => onMarkAsDefaultClick(paymentProfile)}
          onMarkAsFamily={() => onMarkAsFamilyClick(paymentProfile)}
          handleDeleteCard={() => onDeleteCardClick(paymentProfile)}
        />
      ))}
    </div>
  );

const MONTH_PADDING = 2;

const CreditCardItem: FC<{
  paymentProfile: PaymentProfileVO;
  onMarkAsDefault: Func;
  onMarkAsFamily: Func;
  handleDeleteCard: Func;
}> = ({ paymentProfile, onMarkAsDefault, onMarkAsFamily, handleDeleteCard }) => (
  <div
    className={`
      border
      border-slate-300
      rounded
      flex
      items-center
      p-4
      gap-x-4
    `}
  >
    <CreditCardLogo brand={paymentProfile.card?.cardBrand} className="w-8 h-8" />
    <div className="flex-1 flex flex-col gap-y-2">
      <div>
        <Text size="sm">{paymentProfile.billingName}</Text>
      </div>
      <div className="flex flex-col">
        <div className="flex items-center gap-x-2">
          <Text>**** **** **** {paymentProfile.card?.cardLastFour}</Text>
          <VerticalDivider theme="default" size="sm" />
          <Text>
            Expires {String(paymentProfile.card?.cardExpMonth).padStart(MONTH_PADDING, "0")}/
            {paymentProfile.card?.cardExpYear}
          </Text>
        </div>
        <Text>{getPaymentProfileTypeLabel(paymentProfile.card?.cardType)}</Text>
      </div>
      <div className="flex items-center gap-x-2">
        <Checkbox checked={paymentProfile.isFamily} onChange={() => onMarkAsFamily()}>
          Family Card
        </Checkbox>
        <FloatingTooltip content="Family Cards can be used by all family members.">
          <div>
            <Icon SvgIcon={InfoIcon} theme="slate500" />
          </div>
        </FloatingTooltip>
      </div>
    </div>
    <FloatingTooltip
      content={
        paymentProfile.card?.cardIsHealthBenefit ? "FSA cards cannot be marked as default cards" : undefined
      }
    >
      <div>
        <Radio
          name="is-default"
          checked={paymentProfile.isDefault}
          disabled={paymentProfile.card?.cardIsHealthBenefit}
          onChange={onMarkAsDefault}
        >
          Default
        </Radio>
      </div>
    </FloatingTooltip>
    <ButtonIcon SvgIcon={DeleteIcon} size="lg" onClick={handleDeleteCard} />
  </div>
);
