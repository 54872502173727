import React from "react";
import { formatISODate } from "@libs/utils/date";
import { ImageLayoutItem } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";
import { OUTLINE_WIDTH } from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { ImageWithBottomAttachment } from "components/PatientProfile/Imaging/ImageWithBottomAttachment";
import { useSandboxBounds } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/hooks/useSandboxBounds";
import { ReactComponent as SensorNotFoundIcon } from "assets/icons/imaging/sensor-not-found.svg";
import { IMAGE_METADATA_HEIGHT } from "components/PatientProfile/Imaging/PatientMountsList/mountLayouts";

export interface BoxDragPreviewProps {
  image: ImageLayoutItem | React.DragEvent<HTMLDivElement>;
  scale: number;
  isShowingMetadata?: boolean;
}

export const DragPreview: React.FC<BoxDragPreviewProps> = React.memo(
  ({ image, scale, isShowingMetadata }) => {
    const dropEvent = image as React.DragEvent<HTMLDivElement> | undefined;
    const currentImage = image as ImageLayoutItem;

    const bounds = useSandboxBounds(currentImage, scale);

    if (dropEvent?.dataTransfer) {
      return null;
    }

    const date = currentImage.assignedDate ?? currentImage.createdDate ?? "";

    return (
      <ImageWithBottomAttachment
        className={`
          cursor-move
          outline
          rounded
          outline-primaryTheme
          bg-greyMedium
          overflow-hidden
        `}
        scale={scale}
        style={{ outlineWidth: OUTLINE_WIDTH }}
        image={currentImage}
        bounds={bounds}
      >
        {isShowingMetadata && (
          <div
            className="text-white flex items-center justify-end origin-right px-1"
            style={{
              transform: `scale(${scale})`,
              width: bounds.width,
              height: IMAGE_METADATA_HEIGHT * scale,
            }}
          >
            <div className="flex items-center gap-4">
              <div>{formatISODate(date, "M/d/yyyy")}</div>
              <SensorNotFoundIcon className="text-white h-5 w-5" />
            </div>
          </div>
        )}
      </ImageWithBottomAttachment>
    );
  }
);
