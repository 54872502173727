import React from "react";
import { ClaimVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatCurrency } from "@libs/utils/currency";
import { getLatestLineItemsGroup, isCompletedClaim, isInDraftEobPaymentClaim } from "components/Claims/utils";

interface Props {
  claim?: ClaimVO;
}

const cxStyles = {
  cell: `
    py-1.5
    px-3
    border-r
    first:border-r-0
    last:border-r-0
    border-b
    border-greyLighter
  `,
  doubleCell: `
    grid
    grid-cols-2
    divide-x
    divide-dotted
    divide-greyLighter
    border-r
    last:border-r-10
    border-b
    border-greyLighter
  `,
  childCell: `px-4 py-1.5 w-full h-full`,
  bgColor: ({ estimateAmount, actualAmount }: { estimateAmount: number; actualAmount?: number }) => {
    if (actualAmount == null) {
      return undefined;
    }

    if (actualAmount > estimateAmount) {
      return "bg-orangeLight";
    } else if (actualAmount < estimateAmount) {
      return "bg-redLight";
    }

    return "bg-greenLight";
  },
};

export const RowClaimDetails: React.FC<Props> = ({ claim }) => {
  const lineItemsGroup = getLatestLineItemsGroup(claim);

  if (!claim || !lineItemsGroup) {
    return null;
  }

  const completed = isCompletedClaim(claim);
  const isInDraftEobPayment = isInDraftEobPaymentClaim(claim);

  const centerAlignedCell = cx("text-center", cxStyles.cell);
  const rightAlignedCell = cx("text-right", cxStyles.cell);

  return (
    <div className="col-span-full">
      <div
        className={`
          grid
          grid-cols-[50px,repeat(17,minmax(0,1fr))]
          auto-rows-min
          text-xs
          bg-slate-100
        `}
      >
        <div className="contents text-greyDark text-left">
          <div className={cxStyles.cell} />
          <div className={cxStyles.cell}>CDT Code</div>
          <div className={cxStyles.cell}>Tth/Surf</div>
          <div className={cx("col-span-6", cxStyles.cell)}>Description</div>
          <div className={centerAlignedCell}>UCR Fees</div>
          <div className={centerAlignedCell}>Neg Fees</div>
          <div className={centerAlignedCell}>Cov</div>
          <div className={cx("col-span-2", centerAlignedCell)}>Ded Est / Pay</div>
          <div className={cx("col-span-2", centerAlignedCell)}>Pt Resp / Pay</div>
          <div className={cx("col-span-2", centerAlignedCell)}>Ins Est / Pay</div>
        </div>
        {lineItemsGroup.lineItems.map((lineItem) => (
          <div className="contents text-greyMedium text-left" key={`${lineItem.patientProcedureId}`}>
            <div className={cxStyles.cell} />
            <div className={cxStyles.cell}>{lineItem.procedureCode}</div>
            <div className={cxStyles.cell}>
              {/* procedureArea is returned as a comma joined str w/ no spaces btw the comma causing
              long lists to render as one line instead of breaking on spaces */}
              {lineItem.procedureArea ? lineItem.procedureArea.replaceAll(",", ", ") : ""}
            </div>
            <div className={cx("col-span-6", cxStyles.cell)}>{lineItem.procedureDescription}</div>
            <div className={rightAlignedCell}>{formatCurrency(lineItem.ucrRate)}</div>
            <div className={rightAlignedCell}>{formatCurrency(lineItem.negotiatedRate)}</div>
            <div className={centerAlignedCell}>{`${lineItem.insuranceCoveragePercent || 0}%`}</div>
            <ColoredAmounts
              className="col-span-2"
              estimateAmount={lineItem.deductibleEstimatedAmount}
              actualAmount={lineItemsGroup.processedAt ? lineItem.deductibleAmount : undefined}
            />
            <ColoredAmounts
              className="col-span-2"
              estimateAmount={lineItem.patientEstimatedAmount}
              actualAmount={lineItemsGroup.processedAt ? lineItem.patientAmount : undefined}
            />
            <ColoredAmounts
              className="col-span-2"
              estimateAmount={lineItem.insuranceEstimatedAmount}
              actualAmount={lineItemsGroup.processedAt ? lineItem.insuranceAmount : undefined}
            />
          </div>
        ))}
        <div className="contents text-greyMedium text-left">
          <div className={cxStyles.cell} />
          <div className={cxStyles.cell} />
          <div className={centerAlignedCell} />
          <div className={cx("col-span-6", cxStyles.cell)}>Total</div>
          <div className={rightAlignedCell}>{formatCurrency(claim.ucrAmount)}</div>
          <div className={rightAlignedCell}>{formatCurrency(claim.negotiatedAmount)}</div>
          <div className={centerAlignedCell}>-</div>
          <ColoredAmounts
            className="col-span-2"
            estimateAmount={claim.deductibleEstimatedAmount}
            actualAmount={completed && !isInDraftEobPayment ? claim.deductibleAmount : undefined}
          />
          <ColoredAmounts
            className="col-span-2"
            estimateAmount={claim.patientEstimatedAmount}
            actualAmount={completed && !isInDraftEobPayment ? claim.patientAmount : undefined}
          />
          <ColoredAmounts
            className="col-span-2"
            estimateAmount={claim.insuranceEstimatedAmount}
            actualAmount={completed && !isInDraftEobPayment ? claim.insuranceAmount : undefined}
          />
        </div>
      </div>
    </div>
  );
};

const ColoredAmounts: React.FC<{ className?: string; estimateAmount: number; actualAmount?: number }> = ({
  className,
  estimateAmount,
  actualAmount,
}) => {
  return (
    <div className={cx(className, cxStyles.doubleCell)}>
      <div className={cx("text-right", cxStyles.childCell)}>{formatCurrency(estimateAmount)}</div>
      <div
        className={cx(
          cxStyles.childCell,
          actualAmount == null ? "text-center" : "text-right",
          cxStyles.bgColor({ estimateAmount, actualAmount })
        )}
      >
        {actualAmount == null ? "-" : formatCurrency(actualAmount)}
      </div>
    </div>
  );
};
