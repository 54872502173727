import React from "react";
import { ProductionByProviderResponse } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { formatAsISODate } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { HeaderData } from "components/Dashboard/Tables/types";
import { formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";
import { handleError } from "utils/handleError";
import { PRODUCTION_BY_PROVIDER_COLUMNS } from "components/Dashboard/PracticeProduction/constants";
import { TimeSegment } from "utils/routing/dashboard";
import { useCurrentUrl } from "contexts/OriginContext";

const formatProviderDataAsCsvUrl = (data: ProductionByProviderResponse, headers: HeaderData[]) => {
  const columnTitles = headers.map(({ label }) => label).join(",");

  const rows = data.byProviders.map((row) =>
    formatCsvRow([
      row.provider.shortDisplayName,
      `${row.patientCount}`,
      formatCurrency(row.productionPerPatient),
      formatCurrency(row.totalProducedAmount),
    ])
  );
  const totals = formatCsvRow(["Total", "", "", formatCurrency(data.total.totalProducedAmount)]);

  return [columnTitles, ...rows, totals];
};

export const useDownloadProductionByProviderCSV = ({
  selectedTimeSegment,
  productionByProviderQuery,
}: {
  selectedTimeSegment: TimeSegment;
  productionByProviderQuery: ApiQueryResult<ProductionByProviderResponse>;
}) => {
  const downloading = useBoolean(false);

  const { startDate, endDate } = selectedTimeSegment;
  const sharedParams = React.useMemo(
    () => ({
      startDate: formatAsISODate(startDate),
      endDate: formatAsISODate(endDate),
    }),
    [endDate, startDate]
  );
  const currentUrl = useCurrentUrl();

  return {
    isDownloading: downloading.isOn,
    downloadCSV: React.useCallback(async () => {
      downloading.on();

      const reportDateTag = `${sharedParams.startDate}_to_${sharedParams.endDate}`;

      try {
        const response = await productionByProviderQuery.refetch();
        const providerData = response.data?.data.data;

        if (providerData) {
          const docMetadata = getArchyCsvMetadata(currentUrl, selectedTimeSegment);
          const csvRows = [
            ...docMetadata.headers,
            ...formatProviderDataAsCsvUrl(providerData, PRODUCTION_BY_PROVIDER_COLUMNS),
          ];

          saveCsv(csvRows, `ProductionByProvider_${reportDateTag}`);
        }
      } catch (e) {
        handleError(e);
      }

      downloading.off();
    }, [
      downloading,
      sharedParams.startDate,
      sharedParams.endDate,
      productionByProviderQuery,
      selectedTimeSegment,
      currentUrl,
    ]),
  };
};
