import React, { FC } from "react";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { FinixForm } from "@libs/components/UI/FinixForm";
import { PaymentProfileVO } from "@libs/api/generated-api";
import { FlyoverContent, FlyoverFooter, FlyoverForm } from "components/UI/FlyoverComponents";
import { FinixScriptLoadErrorBanner } from "components/PatientProfile/Billing/FinixScriptLoadErrorBanner";
import { useNewCardForm } from "components/PatientProfile/Billing/NewCardForm/useNewCardForm";
import { NewCardFormOptions } from "components/PatientProfile/Billing/NewCardForm/NewCardFormOptions";

interface Props {
  patientId: number;
  onSavedNewCardHandler?: (paymentProfile: PaymentProfileVO) => void;
  onRequestClose: Func;
}

export const NewCardFlyoverForm: FC<Props> = ({ patientId, onSavedNewCardHandler, onRequestClose }) => {
  const { finixForm, isDefaultCard, isFsaCard, handleChangeDefaultCard, handleChangeFsaCard, isSaving } =
    useNewCardForm({ patientId, onSavedNewCardHandler });
  const { failedLoadingFinixScript, isFormValid, submitForm, ...finixFormProps } = finixForm;

  return (
    <FlyoverForm
      className="text-sm"
      onSubmit={(e) => {
        e.preventDefault();
        // Needed to not submit the Payment Details form:
        e.stopPropagation();
        submitForm?.();
      }}
    >
      {failedLoadingFinixScript && <FinixScriptLoadErrorBanner />}
      <FlyoverContent>
        <div className="flex flex-col gap-6">
          <FinixForm {...finixFormProps} />
          <NewCardFormOptions
            isDefaultCard={isDefaultCard}
            onSetIsDefaultCard={handleChangeDefaultCard}
            isFsaCard={isFsaCard}
            onSetIsFsaCard={handleChangeFsaCard}
          />
        </div>
      </FlyoverContent>
      <FlyoverFooter>
        <Button className="min-w-button" onClick={onRequestClose} theme="secondary" disabled={isSaving}>
          Cancel
        </Button>
        <AsyncButton
          className="min-w-button"
          isLoading={isSaving}
          type="submit"
          disabled={!isFormValid || isSaving}
        >
          Save
        </AsyncButton>
      </FlyoverFooter>
    </FlyoverForm>
  );
};
