import { Link } from "react-router-dom";
import { FamilyMemberResponse, PatientSummaryVO, ProfileImageVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { useBoolean } from "@libs/hooks/useBoolean";
import { formatFullNameToInitials } from "@libs/utils/formatString";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as UserIcon } from "@libs/assets/icons/user.svg";
import { ReactComponent as FamilyMembersIcon } from "@libs/assets/icons/people.svg";
import { ReactComponent as AddAppointmentIcon } from "@libs/assets/icons/add-appointment.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { Pill } from "@libs/components/UI/Pill";
import { MenuOptionLink } from "@libs/components/UI/MenuOptionButton";
import { cx } from "@libs/utils/cx";
import { PatientCard } from "components/PatientBlurbInfo/PatientCard";
import { getPatientLink } from "components/Patients/patientLinks";
import { useNullablePathParams } from "hooks/usePathParams";
import { paths } from "utils/routing/paths";
import { getPatientDisplayName } from "utils/names";
import { RoleGuardHide } from "components/Main/RoleGuard";
import { PatientStatusPill } from "components/PatientBlurbInfo/PatientStatusPill";
import { PatientContactButtons } from "components/PatientBlurbInfo/PatientContactButtons";

interface Props {
  familyMembers: FamilyMemberResponse | undefined;
  isHipaaView: boolean;
  onAddAppointment: Func;
  onCopyEmail: Func;
  onViewMessages: Func;
  patient: PatientSummaryVO;
  patientImage: ProfileImageVO;
}

export const PatientBlurbHeader: React.FC<Props> = ({
  familyMembers,
  isHipaaView,
  onAddAppointment,
  onCopyEmail,
  onViewMessages,
  patient,
  patientImage,
}) => {
  const familyMemberMenu = useBoolean(false);
  const patientTab = useNullablePathParams("patientTab")?.tab;
  const linkedFamilyMembers =
    familyMembers?.linkedFamilyMembers.filter((member) => member.memberPatientId !== patient.id) ?? [];
  const totalFamilyBalance =
    familyMembers?.linkedFamilyMembers.reduce((next, member) => next + member.balanceAmount, 0) ?? 0;
  const showFamilyBalance = linkedFamilyMembers.length > 0;

  return (
    <div className="flex flex-col min-w-60 gap-y-3">
      <PatientCard patient={patient} patientImage={patientImage} isHipaaView={isHipaaView} />

      <div className="flex flex-wrap items-center gap-1">
        <PatientStatusPill status={patient.status} />

        <RoleGuardHide domain="BILLING" action="ACCESS_ALL">
          <Link to={paths.patientBilling({ patientId: patient.id })}>
            <Pill className="flex items-center w-fit gap-x-1" size="sm" theme="slate200">
              <Icon SvgIcon={UserIcon} size="sm" />
              <span className={cx(patient.balanceAmount < 0 && "text-red")}>
                {formatCurrency(patient.balanceAmount)}
              </span>
            </Pill>
          </Link>

          {showFamilyBalance ? (
            <Link to={paths.patientBilling({ patientId: patient.id })}>
              <Pill className="flex items-center w-fit gap-x-1" size="sm" theme="slate200">
                <Icon SvgIcon={FamilyMembersIcon} size="sm" />
                <span className={cx(totalFamilyBalance < 0 && "text-red")}>
                  {formatCurrency(totalFamilyBalance)}
                </span>
              </Pill>
            </Link>
          ) : null}
        </RoleGuardHide>
      </div>

      <div className="flex items-center gap-x-1.5 text-slate-700">
        <PatientContactButtons
          patientId={patient.id}
          patientContact={patient.contact}
          onViewMessages={onViewMessages}
          onCopyEmail={onCopyEmail}
        />

        <ButtonMenu
          placement="bottom-start"
          isOpen={familyMemberMenu.isOn}
          onRequestClose={familyMemberMenu.off}
          onRequestOpen={familyMemberMenu.on}
          menuContent={
            linkedFamilyMembers.length > 0
              ? linkedFamilyMembers.map((member) => (
                  <MenuOptionLink
                    onClick={familyMemberMenu.off}
                    key={member.memberPatientId}
                    className="min-w-24 whitespace-nowrap"
                    to={getPatientLink(member.memberPatientId, patientTab)}
                  >
                    {isHipaaView
                      ? formatFullNameToInitials({ fullName: member.name.shortDisplayName })
                      : getPatientDisplayName(member)}
                  </MenuOptionLink>
                ))
              : null
          }
        >
          {(props) => (
            <ButtonIcon
              {...props}
              SvgIcon={FamilyMembersIcon}
              tooltip={{
                content: linkedFamilyMembers.length > 0 ? "Family Members" : "No Family Members",
                theme: "SMALL",
              }}
              disabled={linkedFamilyMembers.length === 0}
            />
          )}
        </ButtonMenu>

        <RoleGuardHide domain="APPOINTMENT_MANAGEMENT" action="ACCESS_ALL">
          <ButtonIcon
            SvgIcon={AddAppointmentIcon}
            onClick={onAddAppointment}
            size="md"
            tooltip={{ content: "Add Appointment", theme: "SMALL" }}
          />
        </RoleGuardHide>
      </div>
    </div>
  );
};
