const INCHES_IN_FOOT = 12;

export type Height = {
  feet: number | undefined;
  inches: number | undefined;
};

const inchesToHeight = (inches?: number): Height => {
  if (inches === undefined) {
    return { feet: undefined, inches: undefined };
  }

  const feet = Math.floor(inches / INCHES_IN_FOOT);
  const leftoverInches = inches % INCHES_IN_FOOT;

  return {
    feet,
    inches: leftoverInches,
  };
};

export const inchesToHeightInputValues = (inches?: number): Height => {
  const { feet, inches: leftoverInches } = inchesToHeight(inches);

  return {
    feet: feet === 0 ? undefined : feet,
    inches: leftoverInches === 0 ? undefined : leftoverInches,
  };
};

export const heightToInches = (height: Height) => {
  if (height.feet === undefined && height.inches === undefined) {
    return undefined;
  }

  const inchesFromFeet = (height.feet ?? 0) * INCHES_IN_FOOT;

  return inchesFromFeet + (height.inches ?? 0);
};

export const formatInches = (savedInches?: number) => {
  const { feet, inches } = inchesToHeight(savedInches);

  if (feet === undefined && inches !== undefined) {
    return `0' ${inches}"`;
  }

  if (feet !== undefined && inches === undefined) {
    return `${feet}' 0"`;
  }

  if (feet !== undefined && inches !== undefined) {
    return `${feet}' ${inches}"`;
  }

  return "";
};
