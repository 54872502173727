import { Key, ReactNode } from "react";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Pill } from "@libs/components/UI/Pill";
import { EMPTY_CELL, LinkCell } from "@libs/components/UI/GridTableComponents";
import { OverflowItems } from "components/UI/OverflowItems";

type ReactKeys<T> = {
  [K in keyof T]: T[K] extends Key ? T[K] : never;
};

interface Props<T, IDKey extends keyof ReactKeys<T>> {
  items?: T[];
  idKey: IDKey;
  formatLabel: (item: T) => ReactNode;
  link: string;
}

export const FormRowPillsLinkCell = <T, IDKey extends keyof ReactKeys<T>>({
  link,
  items,
  formatLabel,
  idKey,
}: Props<T, IDKey>) => {
  return (
    <LinkCell to={link}>
      {items?.length ? (
        <OverflowItems
          items={items}
          renderItem={(item) => <Pill key={item[idKey] as Key}>{formatLabel(item)}</Pill>}
          renderOverflow={(overflowItems) => (
            <FloatingTooltip
              theme="SMALL"
              content={overflowItems.map((item) => formatLabel(item)).join(", ")}
            >
              <Pill>+ {overflowItems.length}</Pill>
            </FloatingTooltip>
          )}
        />
      ) : (
        EMPTY_CELL
      )}
    </LinkCell>
  );
};
