import React from "react";
import { dataURLtoFile, getHeightAndWidthFromDataUrl } from "@libs/utils/dataUrl";
import { DEGREES_90 } from "@libs/utils/math";
import { Button } from "@libs/components/UI/Button";
import { Modal } from "@libs/components/UI/Modal";
import { ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import { ImageLayoutItem } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";
import { UploadCallback } from "components/PatientProfile/Imaging/MountRoute/types";
import { isAspectCompatible } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/shapeUtils";
import { createImageWithRotation } from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { removeFilenameExtension } from "utils/files";
import { MountLayoutType } from "api/imaging/imaging-api";
import { ImageModalBody } from "components/PatientProfile/Imaging/ImportImageModal/ImageModalBody";

type Props = {
  uploadFile: UploadCallback;
  closeModal: () => void;
  mountLayout: MountLayoutType;
  isUploading?: boolean;
  getNewUploadSpot: () => ImageLayoutItem;
};
export const ImportImageModal: React.FC<Props> = ({
  closeModal,
  uploadFile,
  isUploading,
  getNewUploadSpot,
}) => {
  const handleUpload = React.useCallback(
    async (dataUrl: string, fileName: string) => {
      let imageSize = await getHeightAndWidthFromDataUrl(dataUrl);
      let imageUploaded = dataUrl;
      let persistedFilename = fileName;
      const spotToPopulate = getNewUploadSpot();

      if (
        !isAspectCompatible(imageSize, {
          width: spotToPopulate.sandbox.w,
          height: spotToPopulate.sandbox.h,
        })
      ) {
        imageUploaded = await createImageWithRotation(dataUrl, DEGREES_90, imageSize);
        persistedFilename = `${removeFilenameExtension(persistedFilename)}.png`;
        imageSize = {
          height: imageSize.width,
          width: imageSize.height,
        };
      }

      const imageFile = dataURLtoFile(imageUploaded, persistedFilename);

      return uploadFile({
        imageFile,
        ...imageSize,
        positionI: spotToPopulate.i,
        positionX: spotToPopulate.x,
        positionY: spotToPopulate.y,
        type: "EXTERNAL",
      });
    },
    [getNewUploadSpot, uploadFile]
  );

  return (
    <Modal size="xs" title="Import Image" onClose={closeModal}>
      <ModalContent padding="sm">
        <ImageModalBody uploadFile={handleUpload} isUploading={isUploading} />
      </ModalContent>
      <ModalFooter>
        <Button className="min-w-button" onClick={closeModal}>
          Done
        </Button>
      </ModalFooter>
    </Modal>
  );
};
