import { useState, useCallback, ComponentProps } from "react";
import { useDebouncedCallback } from "use-debounce";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { SEARCH_DEBOUNCE_DELAY_MS } from "@libs/utils/constants";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { ReactComponent as RemoveIcon } from "@libs/assets/icons/cancel-small.svg";
import { ReactComponent as FiltersIcon } from "@libs/assets/icons/adjust.svg";
import { Button } from "@libs/components/UI/Button";
import { Pill } from "@libs/components/UI/Pill";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";

import { formatNumberWithCommas } from "@libs/utils/formatNumber";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { FilterComponentProps, FilterInput } from "@libs/components/UI/queryFilterPillsUtils";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";

export interface QueryFiltersProps<T extends FilterInput> {
  bulkActions?: React.ReactNode;
  emptyParams: Partial<T>;
  filters: FilterComponentProps<T>[];
  onOpenFlyover?: Func;
  onUpdateParams: (keyValues: Partial<T>) => void;
  params: T;
  queries?: ComponentProps<typeof QueryResult>["queries"];
  searchParamKey?: keyof T;
  selectedCount?: number;
  totalElements: number;
  filterText?: string;
  horizontalPadding?: string;
}

export const QueryFilters = <T extends FilterInput>({
  bulkActions,
  emptyParams,
  filters,
  onOpenFlyover,
  onUpdateParams,
  filterText = "Filters",
  params,
  queries,
  searchParamKey,
  selectedCount,
  totalElements,
  horizontalPadding,
}: QueryFiltersProps<T>) => {
  const expandSearch = useBoolean(false);
  const [searchString, setSearchString] = useState(() =>
    searchParamKey ? (params[searchParamKey] as string | undefined) ?? "" : ""
  );

  const updateSearch = useDebouncedCallback((value: string) => {
    if (searchParamKey) {
      onUpdateParams({ [searchParamKey]: value } as unknown as Partial<T>);
    }
  }, SEARCH_DEBOUNCE_DELAY_MS);

  const handleSearch = useCallback(
    (value: string) => {
      setSearchString(value);
      updateSearch(value);
    },
    [updateSearch]
  );

  const handleUpdateParams = useCallback(
    (keyValues: Partial<T>) => {
      if (searchParamKey && searchParamKey in keyValues && keyValues[searchParamKey] === undefined) {
        setSearchString("");
      }

      onUpdateParams(keyValues);
    },
    [onUpdateParams, searchParamKey]
  );

  return (
    <div
      className={cx(
        `flex
         items-start
         justify-between
         py-2
         gap-x-1
         bg-slate-50
         border-b
         border-slate-200
         sticky
         top-0`,
        horizontalPadding ?? "px-4"
      )}
    >
      <div className="flex items-start gap-x-1">
        {bulkActions && (
          <div className="flex items-center h-8 gap-x-1">
            {bulkActions}
            {selectedCount ? (
              <Pill theme="slate200" size="md">
                {formatNumberWithCommas(selectedCount)} Selected
              </Pill>
            ) : null}
            <VerticalDivider className="mx-1" size="md" />
          </div>
        )}
        <QueryFilterPills
          emptyParams={emptyParams}
          filters={filters}
          numResults={totalElements}
          onClearAll={handleUpdateParams}
          onUpdateParams={handleUpdateParams}
          params={params}
          queries={queries}
          version="v2"
        />
      </div>
      <div className="flex gap-x-1">
        {searchParamKey && (
          <FormFieldInput
            className={cx(
              "transition-all duration-50 ease-in",
              expandSearch.isOn || searchString ? "w-44" : "w-24"
            )}
            Icon={searchString ? RemoveIcon : SearchIcon}
            iconOnClick={() => handleSearch("")}
            onBlur={expandSearch.off}
            onChange={(e) => handleSearch(e.target.value)}
            onFocus={expandSearch.on}
            placeholder="Search"
            value={searchString}
          />
        )}
        {onOpenFlyover && (
          <Button
            className="flex items-center gap-x-1"
            onClick={onOpenFlyover}
            size={filterText ? "small" : "smallSquare"}
            theme="tertiary"
          >
            {filterText}
            <Icon SvgIcon={FiltersIcon} />
          </Button>
        )}
      </div>
    </div>
  );
};
