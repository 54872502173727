import { useCallback, useEffect, useMemo, useRef } from "react";
import { set, format } from "date-fns";
import {
  FeeScheduleListVO,
  InsuranceCarrierVO,
  InsurancePlanVO,
  PatientInsuranceResponse,
} from "@libs/api/generated-api";
import { required } from "@libs/utils/validators";
import { pluralize } from "@libs/utils/pluralize";
import { useValidation } from "@libs/hooks/useValidation";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useAccount } from "@libs/contexts/AccountContext";
import { Banner } from "@libs/components/UI/Banner";
import { Switch } from "@libs/components/UI/Switch";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent, FlyoverFooter, FlyoverForm } from "components/UI/FlyoverComponents";
import {
  PlanFields,
  UsePlanFieldsResult,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/usePlanFields";
import { Section } from "components/Insurance/Section";
import { planCxStyles } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/styles";
import { planTypeNames } from "components/PatientProfile/Insurance/utils";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { ToggleGroup } from "components/UI/ToggleGroup";
import { Divider } from "components/UI/Divider";
import {
  createInsurancePlan,
  updateInsurancePlan,
  linkPatientInsuranceToPlan,
} from "api/patientInsurance/mutations";
import { usePathParams } from "hooks/usePathParams";
import { FormType } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/CarriersAndPlansTab/CarrierAndPlanRoute";
import { CarrierSelector } from "components/Insurance/CarrierSelector/CarrierSelector";
import {
  getCreatePlanSubmission,
  getPlanUpdateSubmission,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/formUtils";
import { useInfiniteScrollingSelectComponents } from "hooks/useInfiniteScrollingSelectComponents";

interface Props {
  autoEligibilityCarrierIds: Set<number> | undefined;
  carrierId: number;
  feeSchedulesInfiniteQuery: UseInfiniteApiQueryResult<FeeScheduleListVO[]>;
  formType: FormType;
  insuranceCarriers: InsuranceCarrierVO[];
  insuranceOrPlanIsAutoVerified: boolean;
  numOfPatients: number | undefined;
  onClose: Func;
  onPlanFieldChange: UsePlanFieldsResult["handlePlanFieldChange"];
  onSearchFeeSchedules: (search: string) => void;
  patientInsurance: PatientInsuranceResponse;
  planFields: PlanFields;
}

type CrownBridgesPaidOnOption = NonNullable<InsurancePlanVO["crownBridgesPaidOn"]>;
type FeeScheduleOption = {
  data: {
    id: number;
    name: string;
  };
  label: string;
  value: number;
};

const schema = {
  carrierId: [{ $v: required, $error: "Carrier required" }],
  feeSchedule: [{ $v: required, $error: "Fee schedule required" }],
  planType: [{ $v: required, $error: "Plan type required" }],
  renewalMonth: [{ $v: required, $error: "Renewal month required" }],
  waitingPeriodInMonths: [{ $v: required, $error: "Waiting period required" }],
};

// eslint-disable-next-line complexity
export const InsurancePlanFlyover: React.FC<Props> = ({
  autoEligibilityCarrierIds,
  carrierId,
  feeSchedulesInfiniteQuery,
  formType,
  insuranceCarriers,
  insuranceOrPlanIsAutoVerified,
  numOfPatients,
  onClose,
  onPlanFieldChange,
  onSearchFeeSchedules,
  patientInsurance,
  planFields,
}) => {
  const { practiceId } = useAccount();
  const { insuranceId, patientId } = usePathParams("patientInsuranceDetails");

  const [draftPlanFields, setDraftPlanFields] = useObjectState<PlanFields>(planFields);

  // If we are editing an insurance plan, carrierId is undefined and we need to
  // use the insurancePlan.carrier.id so sync it here for validation reasons
  useEffect(() => {
    if (!draftPlanFields.carrierId && draftPlanFields.carrier?.id) {
      setDraftPlanFields({ carrierId: draftPlanFields.carrier.id });
    }
  }, [draftPlanFields.carrier?.id, draftPlanFields.carrierId, setDraftPlanFields]);

  const hasUpdatedDuplicateUuid = useRef(false);

  useEffect(() => {
    if (formType === "duplicate" && hasUpdatedDuplicateUuid.current !== true) {
      setDraftPlanFields({ uuid: undefined });
      hasUpdatedDuplicateUuid.current = true;
    }
  }, [formType, setDraftPlanFields]);

  const options = useMemo(() => {
    return {
      booleanOptions: [
        {
          label: "No",
          value: 0 as 0 | 1,
        },
        {
          label: "Yes",
          value: 1 as 0 | 1,
        },
      ],
      coordinationOfBenefits: [
        {
          label: "Standard",
          value: "STANDARD" as InsurancePlanVO["coordinationOfBenefits"],
        },
        {
          label: "None",
          value: "NON_DUPLICTION" as InsurancePlanVO["coordinationOfBenefits"],
        },
        {
          label: "Other",
          value: "OTHER" as InsurancePlanVO["coordinationOfBenefits"],
        },
      ],
      crownBridgesPaidOn: [
        {
          label: "Seat date",
          value: "SEAT_DATE" as CrownBridgesPaidOnOption,
        },
        {
          label: "Prep date",
          value: "PREP_DATE" as CrownBridgesPaidOnOption,
        },
      ],
      eligibilityCoverageLevel: [
        {
          label: "Individual",
          value: "INDIVIDUAL" as InsurancePlanVO["eligibilityCoverageLevel"],
        },
        {
          label: "Family",
          value: "FAMILY" as InsurancePlanVO["eligibilityCoverageLevel"],
        },
      ],
      months: Array.from({ length: 12 })
        .map((_, i) => set(new Date(), { month: i }))
        .map((date) => format(date, "MMMM"))
        .map((month) => ({ label: month, value: month.toUpperCase() as InsurancePlanVO["renewalMonth"] })),
      monthCounts: Array.from({ length: 13 }).map((_, i) => ({
        label: `${i} months`,
        value: i,
      })),
      planTypes: Object.keys(planTypeNames).map((key) => ({
        label: planTypeNames[key as InsurancePlanVO["planType"]],
        value: key as InsurancePlanVO["planType"],
      })),
    };
  }, []);

  const feeSchedules = useFlattenPages(feeSchedulesInfiniteQuery.data);

  const feeScheduleOptions: FeeScheduleOption[] = useMemo(
    () =>
      (feeSchedules ?? [])
        .sort((a, b) => {
          if (a.state === "ARCHIVED" && b.state !== "ARCHIVED") {
            return 1;
          } else if (a.state !== "ARCHIVED" && b.state === "ARCHIVED") {
            return -1;
          }

          return 0;
        })
        .map((feeSchedule) => ({
          data: { id: feeSchedule.id, name: feeSchedule.name },
          label: `${feeSchedule.name}${feeSchedule.state === "ARCHIVED" ? " - archived" : ""}`,
          value: feeSchedule.id,
        })),
    [feeSchedules]
  );

  const customComponents = useInfiniteScrollingSelectComponents<FeeScheduleOption, false, number>({
    infiniteQuery: feeSchedulesInfiniteQuery,
  });

  const selectProps = { openMenuOnFocus: true, required: true };
  const toggleProps = {
    containerLayout: "custom" as const,
    labelClassName: "mb-1 font-sansSemiBold",
    size: "md" as const,
  };

  const [createInsurancePlanMutation, updateInsurancePlanMutation, linkPlanMutation] = useApiMutations([
    createInsurancePlan,
    updateInsurancePlan,
    linkPatientInsuranceToPlan,
  ]);
  const isSaving =
    createInsurancePlanMutation.isLoading ||
    updateInsurancePlanMutation.isLoading ||
    linkPlanMutation.isLoading;

  const { result, validate } = useValidation(draftPlanFields, schema);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault();

      if (!carrierId) {
        throw new Error("Carrier ID not found");
      }

      if (validate().$isValid && insuranceId !== "new") {
        const subscriberEmployer = patientInsurance.patientInsurance.subscriber.employer ?? "";

        if (formType === "edit" && draftPlanFields.uuid) {
          await updateInsurancePlanMutation.mutateAsync({
            data: getPlanUpdateSubmission(draftPlanFields, subscriberEmployer),
            insuranceId,
            insurancePlanUuid: draftPlanFields.uuid,
            patientId,
            practiceId,
          });
          onPlanFieldChange(draftPlanFields);
          onClose();
        } else {
          const createResult = await createInsurancePlanMutation.mutateAsync({
            data: getCreatePlanSubmission(
              carrierId,
              draftPlanFields,
              patientInsurance.patientInsurance.subscriber.employer ?? ""
            ),
            patientId,
            practiceId,
          });
          const createdPlanUuid = createResult.data.data.uuid;

          await linkPlanMutation.mutateAsync({
            insuranceId,
            insurancePlanUuid: createdPlanUuid,
            patientId,
            practiceId,
          });
          onPlanFieldChange({ ...draftPlanFields, uuid: createdPlanUuid });
          onClose();
        }
      }
    },
    [
      carrierId,
      createInsurancePlanMutation,
      draftPlanFields,
      formType,
      insuranceId,
      linkPlanMutation,
      onClose,
      onPlanFieldChange,
      patientId,
      patientInsurance.patientInsurance.subscriber.employer,
      practiceId,
      updateInsurancePlanMutation,
      validate,
    ]
  );

  return (
    <Flyover
      onClose={onClose}
      size="md"
      title={
        formType === "duplicate"
          ? "Duplicate Insurance Plan"
          : formType === "edit"
            ? "Edit Insurance Plan"
            : "Add Insurance Plan"
      }
    >
      <FlyoverForm fieldLayout="labelOut" onSubmit={handleSubmit}>
        <FlyoverContent className="flex flex-col gap-y-6 text-xs">
          {formType === "edit" && (
            <Banner className="rounded" theme="info">
              {`Changes will affect all ${numOfPatients ?? 0} ${pluralize(
                numOfPatients ?? 0,
                "patient",
                "patients"
              )} with this insurance plan.`}
            </Banner>
          )}
          <Section
            className={planCxStyles.section}
            includePadding={false}
            title="Carrier Details"
            useCustomStyling={true}
          >
            <div className={planCxStyles.fieldsContainer}>
              <FloatingTooltip
                content={
                  insuranceOrPlanIsAutoVerified
                    ? "Cannot edit Insurance Carrier for plans that are auto verified"
                    : ""
                }
              >
                <div>
                  <CarrierSelector
                    autoEligibilityCarrierIds={autoEligibilityCarrierIds}
                    display="label"
                    disabled={insuranceOrPlanIsAutoVerified}
                    edit
                    error={result.carrierId.$error}
                    insuranceCarriers={insuranceCarriers}
                    layout="labelOut"
                    onUpdate={(val) => setDraftPlanFields({ carrierId: val.carrierId })}
                    placeholder="Search by Carrier Name or Payer ID..."
                    required
                    value={draftPlanFields.carrierId ?? draftPlanFields.carrier?.id ?? 0}
                  />
                </div>
              </FloatingTooltip>
              <FloatingTooltip
                content={
                  insuranceOrPlanIsAutoVerified
                    ? "Cannot edit Group Number for plans that are auto verified"
                    : ""
                }
              >
                <div>
                  <FormFieldInput
                    disabled={insuranceOrPlanIsAutoVerified}
                    label="Group Number"
                    onChange={(e) => {
                      setDraftPlanFields({ groupNumber: e.target.value });
                    }}
                    name="groupNumber"
                    value={draftPlanFields.groupNumber}
                  />
                </div>
              </FloatingTooltip>
              <FormFieldInput
                Icon={InfoIcon}
                iconTooltip={{
                  content:
                    "This is a way for the practice to identify the plan and is not shared with insurance.",
                }}
                label="Nickname"
                onChange={(e) => {
                  setDraftPlanFields({ description: e.target.value });
                }}
                name="description"
                value={draftPlanFields.description}
              />
              <FormFieldInput
                label="Group Name"
                onChange={(e) => {
                  setDraftPlanFields({ groupName: e.target.value });
                }}
                name="groupName"
                value={draftPlanFields.groupName}
              />
              <FormFieldInput
                label="Employer"
                onChange={(e) => {
                  setDraftPlanFields({ employerName: e.target.value });
                }}
                name="employerName"
                value={draftPlanFields.employerName}
              />
              <FormFieldSelect
                {...selectProps}
                error={result.planType.$error}
                label="Plan Type"
                onChange={(option) => {
                  setDraftPlanFields({ planType: option?.value });
                }}
                options={options.planTypes}
                value={draftPlanFields.planType}
              />
              <FormFieldSelect
                {...selectProps}
                aria-label="fee-schedule-select"
                components={customComponents}
                error={result.feeSchedule.$error}
                label="Fee Schedule"
                onChange={(option) => {
                  setDraftPlanFields({ feeSchedule: option?.data });
                }}
                onInputChange={(val) => onSearchFeeSchedules(val)}
                options={feeScheduleOptions}
                value={draftPlanFields.feeSchedule?.id}
              />
              <FormFieldSelect
                {...selectProps}
                error={result.renewalMonth.$error}
                label="Benefits Renew in"
                onChange={(option) => {
                  setDraftPlanFields({ renewalMonth: option?.value });
                }}
                options={options.months}
                value={draftPlanFields.renewalMonth}
              />
              <FormFieldSelect
                {...selectProps}
                error={result.waitingPeriodInMonths.$error}
                label="Waiting Period"
                onChange={(option) => {
                  setDraftPlanFields({ waitingPeriodInMonths: option?.value });
                }}
                options={options.monthCounts}
                value={draftPlanFields.waitingPeriodInMonths}
              />
              <FormFieldInput
                label="Dependent Max Age"
                onChange={(e) => {
                  setDraftPlanFields({ dependentMaxAge: Number.parseInt(e.target.value) });
                }}
                name="dependentMaxAge"
                value={draftPlanFields.dependentMaxAge?.toString()}
              />
              <ToggleGroup
                {...toggleProps}
                label="Coordination of Benefits"
                onChange={(e, { value }) => setDraftPlanFields({ coordinationOfBenefits: value })}
                options={options.coordinationOfBenefits}
                selectedValue={draftPlanFields.coordinationOfBenefits}
              />
              <ToggleGroup
                {...toggleProps}
                label="Eligibility Coverage Level"
                onChange={(e, { value }) => setDraftPlanFields({ eligibilityCoverageLevel: value })}
                options={options.eligibilityCoverageLevel}
                required
                selectedValue={draftPlanFields.eligibilityCoverageLevel ?? "INDIVIDUAL"}
              />
              <ToggleGroup
                {...toggleProps}
                label="Out of Network Benefits"
                onChange={(e, { value }) => setDraftPlanFields({ outOfNetworkBenefits: Boolean(value) })}
                options={options.booleanOptions}
                selectedValue={draftPlanFields.outOfNetworkBenefits ? 1 : 0}
              />
              <ToggleGroup
                {...toggleProps}
                label="Missing Tooth Clause"
                onChange={(e, { value }) => setDraftPlanFields({ missingToothClause: Boolean(value) })}
                options={options.booleanOptions}
                selectedValue={draftPlanFields.missingToothClause ? 1 : 0}
              />
              <ToggleGroup
                {...toggleProps}
                label="Crown/Bridges Paid On"
                onChange={(e, { value }) => setDraftPlanFields({ crownBridgesPaidOn: value })}
                options={options.crownBridgesPaidOn}
                selectedValue={draftPlanFields.crownBridgesPaidOn ?? "SEAT_DATE"}
              />
            </div>
          </Section>
          <Divider className="border-dashed" />
          <Section
            className={planCxStyles.section}
            includePadding={false}
            title="Carrier Contact"
            useCustomStyling={true}
          >
            <div className={planCxStyles.fieldsContainer}>
              <div className="flex flex-col gap-y-1">
                <FormFieldLabel className="text-xs font-sansSemiBold" content="Carrier Contacted" />
                <div className="flex flex-row items-center h-8 space-x-1.5">
                  <Switch
                    checked={draftPlanFields.carrierContacted}
                    onChange={(e) => setDraftPlanFields({ carrierContacted: e.target.checked })}
                  />
                  <div className="text-xs">{draftPlanFields.carrierContacted ? "Yes" : "No"}</div>
                </div>
              </div>
              <FormFieldInput
                label="Insurance Representative"
                onChange={(e) => {
                  setDraftPlanFields({ insuranceRepresentative: e.target.value });
                }}
                name="insuranceRepresentative"
                value={draftPlanFields.insuranceRepresentative}
              />
            </div>
          </Section>
        </FlyoverContent>
        <FlyoverFooter>
          <Button className="min-w-button" disabled={isSaving} onClick={onClose} theme="secondary">
            Cancel
          </Button>
          <AsyncButton
            className="min-w-button"
            disabled={isSaving}
            isLoading={isSaving}
            theme="primary"
            type="submit"
          >
            Save
          </AsyncButton>
        </FlyoverFooter>
      </FlyoverForm>
    </Flyover>
  );
};
