/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { useMemo } from "react";
import { toBlob } from "html-to-image";
import { MedicalImageVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatISODate } from "@libs/utils/date";
import { Size } from "@libs/utils/math";
import {
  getImageTransformStyles,
  getTeethLabel,
} from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { MountFormat } from "utils/routing/patient";
import { ImageWithBottomAttachment } from "components/PatientProfile/Imaging/ImageWithBottomAttachment";
import { IMAGE_CELL_SIZE } from "components/PatientProfile/Imaging/PatientMountsList/mountLayouts";
import { OFFSCREEN_RENDER_ORIGIN } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/shapeUtils";

type Props = {
  images: {
    image: MedicalImageVO | undefined;
    index: number;
    absoluteIndex: number;
  }[];
  format: MountFormat;
  onCaptureComplete: (blob: Blob | null) => void;
};

const BASE_IMAGE_DIMENSIONS = {
  width: IMAGE_CELL_SIZE.w * 10,
  height: IMAGE_CELL_SIZE.h * 10,
};
// Optimizes image generated to the number of images that are defined in the layout:
const useCaptureDimensions = (images: Props["images"]) => {
  return React.useMemo(() => {
    const [image1, image2] = images;
    const definedImages = images.filter((item) => item.image);

    // If the images are not adjacent, then we are not in a double vertical layout
    const isDoubleVerticalLayout = definedImages.length === 2 && image2.index === image1.index + 2;
    const { sizeInverted } = getImageTransformStyles(image1.image?.transforms);

    let dimensions = {
      width: (sizeInverted ? image1.image?.h : image1.image?.w) ?? 0,
      height: (sizeInverted ? image1.image?.w : image1.image?.h) ?? 0,
    };

    if (images.length === 1) {
      return {
        dimensions: {
          ...dimensions,
          height: dimensions.height + METADATA_HEIGHT,
        },
      };
    } else if (isDoubleVerticalLayout) {
      dimensions = {
        width: BASE_IMAGE_DIMENSIONS.width,
        height: BASE_IMAGE_DIMENSIONS.height * 2,
      };
    } else if (definedImages.length === 2) {
      // Horizontal layout side by side
      dimensions = {
        width: BASE_IMAGE_DIMENSIONS.width * 2,
        height: BASE_IMAGE_DIMENSIONS.height,
      };
    } else if (definedImages.length > 2) {
      dimensions = {
        width: BASE_IMAGE_DIMENSIONS.width * 2,
        height: BASE_IMAGE_DIMENSIONS.height * 2,
      };
    }

    return {
      dimensions,
      isDoubleVerticalLayout,
    };
  }, [images]);
};
const METADATA_HEIGHT = 70;
const ImageCaptureItem: React.FC<{ image: MedicalImageVO; onAnnotationLoaded: Func; size: Size }> = ({
  image,
  onAnnotationLoaded,
  size,
}) => {
  const boundsWithMetadata = useMemo(() => {
    return {
      ...size,
      height: size.height - METADATA_HEIGHT,
    };
  }, [size]);
  const teethLabel = React.useMemo(() => {
    return getTeethLabel(image.teeth);
  }, [image.teeth]);
  const dateDisplayedISO = image.assignedDate || image.createdDate;

  const dateDisplayed = React.useMemo(() => {
    return dateDisplayedISO ? formatISODate(dateDisplayedISO, "M/d/yyy") : undefined;
  }, [dateDisplayedISO]);

  return (
    <div
      className={`
        flex
        flex-1
        border-2
        border-transparent
        relative
        justify-center
        items-center
        w-full
        h-full
        overflow-hidden
      `}
    >
      <ImageWithBottomAttachment
        key={image.id}
        image={image}
        bounds={boundsWithMetadata}
        containOptions={{ canExceedImageSize: true }}
        imageClasses="absolute w-full h-full inset-0"
        onAnnotationLoaded={onAnnotationLoaded}
      >
        <div
          className={`
            bg-greyMedium
            text-white
            px-6
            rounded-b-md
            flex
            items-center
            gap-1
            w-full
            justify-between
          `}
          style={{
            height: METADATA_HEIGHT,
            fontSize: 30,
          }}
        >
          <div className="truncate">{teethLabel ?? ""}</div>
          <div>{dateDisplayed}</div>
        </div>
      </ImageWithBottomAttachment>
    </div>
  );
};

export const ImageSideBySideCapture: React.FC<Props> = ({ images, format, onCaptureComplete }) => {
  const positionsLoaded = React.useRef<number>(0);
  const containerRef = React.useRef<HTMLDivElement>(null);
  const definedImages = React.useMemo(() => images.filter((item) => item.image), [images]);
  const { isDoubleVerticalLayout, dimensions } = useCaptureDimensions(images);
  const handleAnnotationLoaded = React.useCallback(() => {
    positionsLoaded.current++;

    if (positionsLoaded.current === definedImages.length && containerRef.current) {
      toBlob(containerRef.current).then(onCaptureComplete);
    }
  }, [definedImages.length, onCaptureComplete]);

  return (
    <div
      className="absolute flex items-center justify-center z-50"
      style={{
        ...OFFSCREEN_RENDER_ORIGIN,
        ...dimensions,
      }}
    >
      <div
        ref={containerRef}
        className={cx(
          "flex-1 h-full",
          format === "single" ? "flex flex-col" : "grid auto-cols-fr auto-rows-fr",
          format !== "single" && (isDoubleVerticalLayout ? "grid-cols-1" : "grid-cols-2")
        )}
      >
        {images.map((item) => {
          return item.image ? (
            <ImageCaptureItem
              key={item.image.id}
              image={{
                ...item.image,
                pearlAnalysis: undefined,
              }}
              onAnnotationLoaded={handleAnnotationLoaded}
              size={images.length === 1 ? dimensions : BASE_IMAGE_DIMENSIONS}
            />
          ) : (
            <div className="h-full flex-1" />
          );
        })}
      </div>
    </div>
  );
};
