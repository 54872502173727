import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";

export type Props = {
  to: string;
  last?: boolean;
  procedures: string;
};

export const ProceduresCell: React.FC<Props> = ({ to, last, procedures }) => {
  const proceduresText = procedures.split(",").join(", ");

  return (
    <DashboardLinkCell last={last} to={to}>
      <FloatingTooltip content={proceduresText} theme="MEDIUM">
        <div className="truncate">{proceduresText}</div>
      </FloatingTooltip>
    </DashboardLinkCell>
  );
};
