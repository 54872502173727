import React from "react";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";
import { DashboardFilterFormFieldSelect } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";

const SELECT_OPTIONS = [
  {
    label: "New",
    value: "NEW" as const,
  },
  {
    label: "Existing",
    value: "EXISTING" as const,
  },
];

type Props = {
  filters: DashboardFilter[];
  onUpdateParams: (newParams: { filters: DashboardFilter[] }) => void;
};
export const SelectPatientType: React.FC<Props> = ({ filters, onUpdateParams }) => {
  return (
    <DashboardFilterFormFieldSelect
      isSearchable={false}
      options={SELECT_OPTIONS}
      dashboardFilters={filters}
      dashboardFilterType="patientType"
      onChangeFilters={onUpdateParams}
      placeholder="Patient Type"
      className="min-w-[110px]"
    />
  );
};
