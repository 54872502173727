import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { fromUnixTime } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import { ClaimAttachmentVO } from "@libs/api/generated-api";
import { ISO_DATE } from "@libs/utils/date";
import { getFullUrl } from "@libs/utils/location";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as ScreenshotIcon } from "@libs/assets/icons/screenshot.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { ClaimSection } from "components/Claim/ClaimSection";
import { paths } from "utils/routing/paths";
import { attachmentImageCxStyles } from "components/Claim/Attachments/AttachmentsSummary";

interface Props {
  claimUuid: string;
  images: ClaimAttachmentVO[];
  isDraftClaim: boolean;
  onRemoveImage?: ({ attachmentUuid }: { attachmentUuid: string }) => void;
}

export const PendingEobsSection: React.FC<Props> = ({ claimUuid, images, isDraftClaim, onRemoveImage }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const practice = useCurrentPractice();

  const handleOpenEobs = useCallback(() => {
    const fullUrl = getFullUrl(location);

    navigate(paths.claimEobs({ claimUuid }, { from: fullUrl }));
  }, [claimUuid, navigate, location]);

  return (
    <ClaimSection
      required={true}
      title="Primary Claim EOBs"
      titleChildren={
        isDraftClaim ? (
          <button
            className={`
              flex
              items-center
              ml-2
              h-5
              text-primaryTheme
              font-sansSemiBold
            `}
            onClick={handleOpenEobs}
            type="button"
          >
            <ScreenshotIcon className="mt-1 h-5 w-5" />
            <span>Add Clipping</span>
          </button>
        ) : undefined
      }
    >
      {images.length ? (
        <div className={attachmentImageCxStyles.imagesContainer}>
          {images.map((image, index) => (
            <div className={attachmentImageCxStyles.imageContainer} key={index}>
              <div className="flex items-center justify-between text-xs">
                <span className="overflow-hidden whitespace-nowrap text-ellipsis">
                  {getAttachmentImagePreviewName(practice.timezoneId, image.sourceCreatedAt)}
                </span>
                {isDraftClaim && (
                  <ButtonIcon
                    SvgIcon={DeleteIcon}
                    size="sm"
                    onClick={() => onRemoveImage?.({ attachmentUuid: image.uuid })}
                    theme="primary"
                  />
                )}
              </div>
              <div className="flex justify-center w-full bg-greyLightest">
                <img src={image.data} alt={image.type} className="h-24" />
              </div>
            </div>
          ))}
        </div>
      ) : (
        <span className="text-greyDark text-xs font-sans">None</span>
      )}
    </ClaimSection>
  );
};

export const getAttachmentImagePreviewName = (practiceTimezoneId: string, createdAt?: number) => {
  if (!createdAt) {
    return "Screen Shot";
  }

  const date = fromUnixTime(createdAt);

  return `Screen Shot ${formatInTimeZone(date, practiceTimezoneId, ISO_DATE)} at ${formatInTimeZone(
    date,
    practiceTimezoneId,
    "K:mm:ss a"
  )}`;
};
