import { useCallback, useEffect, useMemo, useState } from "react";
import { ReactSortable, Sortable } from "react-sortablejs";
import { ProcedureShortcutVO } from "@libs/api/generated-api";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { getTempId, isTempId } from "@libs/utils/tempId";
import { useAccount } from "@libs/contexts/AccountContext";
import {
  getProcedureShortcutsByCategory,
  getAllProcedureShortcutCategories,
  getDentalProceduresQuery,
} from "api/charting/queries";

import {
  createProcedureShortcut,
  deleteProcedureShortcut,
  reorderProcedureShortcuts,
  updateProcedureShortcut,
} from "api/settings/charting/mutations";

import {
  ProcedureShortcutFormSubmission,
  ShortcutForm,
} from "components/Settings/ChartingSettings/ProcedureShortcutForm";

import {
  EditableShortcut,
  AddShortcut,
  SkeletonShortcut,
  cxProcedureShortcutStyles,
} from "components/Settings/ChartingSettings/ProcedureShortcut";
import { handleError } from "utils/handleError";
import { ProcedureShortcutCategoryOptions } from "components/Settings/ChartingSettings/ProcedureShortcutCategoryOptions";
import { ProceduresList } from "components/Settings/ChartingSettings/ProceduresList";
import { ProcedureShortcutsMode } from "components/Settings/ChartingSettings/types";
import { ProcedureShortcutBodyHeader } from "components/Settings/ChartingSettings/ProcedureShortcutBodyHeader";
import { mapToUnknowSurfaces } from "components/Charting/toothSurfaces";

const isSameOrder = (listA: ProcedureShortcutVO[], listB: ProcedureShortcutVO[]) => {
  if (listA.length !== listB.length) {
    return false;
  }

  for (const [index, { uuid }] of listA.entries()) {
    if (listB[index].uuid !== uuid) {
      return false;
    }
  }

  return true;
};

const DRAG_HANDLE_CLASS = "shortcut-handle";

export const ProcedureShortcutSettings: React.FC = () => {
  const { practiceId } = useAccount();
  const [mode, setMode] = useState<ProcedureShortcutsMode>({ type: "View" });
  const [category, setCategory] = useState<ProcedureShortcutVO["category"]>("EXAMS");
  const [draggingIndex, setDraggingIndex] = useState(-1);
  const [
    { data: allProcedures },
    { data: categories, error: categoriesError, isLoading: isLoadingCategories },
    { data: shortcuts, isLoading: isLoadingShortcuts, error: shortcutsError },
  ] = useApiQueries([
    getDentalProceduresQuery({ args: { practiceId } }),
    getAllProcedureShortcutCategories(),
    getProcedureShortcutsByCategory({ args: { practiceId, category } }),
  ]);

  const [createShortcutMutation, updateShortcutMutation, deleteShortcutMutation, reorderShortcutsMutation] =
    useApiMutations([
      createProcedureShortcut,
      updateProcedureShortcut,
      deleteProcedureShortcut,
      reorderProcedureShortcuts,
    ]);

  const { selectedShortcut, orderedShortcuts, lastShortcut } = useMemo(() => {
    return {
      selectedShortcut:
        mode.type === "View" || mode.type === "Edit"
          ? shortcuts?.find((shortcut) => shortcut.uuid === mode.uuid)
          : undefined,

      lastShortcut: shortcuts?.[shortcuts.length - 1],

      orderedShortcuts: shortcuts?.map((s) => ({ id: s.uuid, ...s })) || [],
    };
  }, [shortcuts, mode]);

  const handleCreate = useCallback(
    (formData: ProcedureShortcutFormSubmission) => {
      const id = getTempId();

      setMode({ type: "View", uuid: id });
      createShortcutMutation.mutate(
        {
          tempId: id,
          practiceId,
          name: formData.name,
          surfaces: formData.surfaces,
          category,
          procedures: formData.procedures,
          order: lastShortcut ? lastShortcut.order + 1 : 1,
        },
        {
          onSuccess: (response) => {
            setMode({ type: "View", uuid: response.data.data.uuid });
          },
          onError: (err) => {
            setMode({ type: "Create", ...formData });
            handleError(err);
          },
        }
      );
    },
    [createShortcutMutation, practiceId, lastShortcut, category]
  );

  const handleUpdate = useCallback(
    (formData: ProcedureShortcutFormSubmission) => {
      if (mode.type === "Edit") {
        const uuid = mode.uuid;

        setMode({ type: "View", uuid });
        updateShortcutMutation.mutate(
          {
            practiceId,
            uuid,
            category,
            name: formData.name,
            surfaces: formData.surfaces,
            procedures: formData.procedures,
          },
          {
            onError: (err) => {
              setMode({ type: "Edit", uuid });
              handleError(err);
            },
          }
        );
      }
    },
    [updateShortcutMutation, practiceId, mode, category]
  );

  const handleDelete = useCallback(() => {
    if (mode.type === "Edit") {
      const uuid = mode.uuid;

      setMode({ type: "View" });
      deleteShortcutMutation.mutate(
        { practiceId, uuid, category },
        {
          onError: (err) => {
            setMode({ type: "Edit", uuid });
            handleError(err);
          },
        }
      );
    }
  }, [practiceId, deleteShortcutMutation, category, mode]);

  const handleReorder = useCallback(
    (newOrder: ProcedureShortcutVO[]) => {
      if (!isSameOrder(newOrder, orderedShortcuts)) {
        reorderShortcutsMutation.mutate(
          { practiceId, uuids: newOrder.map(({ uuid }) => uuid), category },
          {
            onError: (err) => {
              handleError(err);
            },
          }
        );
      }
    },
    [practiceId, reorderShortcutsMutation, category, orderedShortcuts]
  );

  const handleStartSort = useCallback((evt: Sortable.SortableEvent) => {
    setDraggingIndex(evt.oldIndex ?? -1);
  }, []);
  const handleStopSort = useCallback(() => {
    setDraggingIndex(-1);
  }, []);

  useEffect(() => {
    setMode({ type: "View" });
  }, [category]);

  const allProceduresSaved = useMemo(
    () => !orderedShortcuts.some((s) => isTempId(s.uuid)),
    [orderedShortcuts]
  );

  return (
    <div className="-ml-5 pb-4">
      <div className="flex">
        <ProcedureShortcutCategoryOptions
          onSelectCategory={setCategory}
          selectedCategory={category}
          categories={categories}
          isLoading={isLoadingCategories}
          categoriesError={categoriesError}
          className="flex-none w-64"
        />
        <div className="flex-1 ml-4">
          <ProcedureShortcutBodyHeader
            selectedCategory={category}
            categories={categories}
            onRemoveClick={handleDelete}
            mode={mode}
            className="mb-3"
          />
          {isLoadingShortcuts ? (
            <SkeletonShortcut />
          ) : shortcutsError ? (
            <p className="text-red text-xs">There was a problem loading your shortcuts.</p>
          ) : mode.type === "Edit" && selectedShortcut ? (
            <ShortcutForm
              key={selectedShortcut.uuid}
              name={selectedShortcut.name}
              surfaces={selectedShortcut.surfaces}
              procedures={selectedShortcut.procedures}
              allProcedures={allProcedures}
              onFormSubmit={handleUpdate}
              onCancelClick={() => setMode({ type: "View", uuid: selectedShortcut.uuid })}
            />
          ) : mode.type === "Create" ? (
            <ShortcutForm
              key="add"
              name={mode.name}
              procedures={mode.procedures}
              surfaces={mode.surfaces}
              allProcedures={allProcedures}
              onFormSubmit={handleCreate}
              onCancelClick={() => {
                setMode({ type: "View" });
              }}
            />
          ) : shortcuts ? (
            <>
              <ReactSortable
                animation={150}
                className={cxProcedureShortcutStyles.grid}
                list={orderedShortcuts}
                handle={`.${DRAG_HANDLE_CLASS}`}
                setList={handleReorder}
                onEnd={handleStopSort}
                onStart={handleStartSort}
              >
                {orderedShortcuts.map((shortcut, index) => {
                  return (
                    <EditableShortcut
                      key={shortcut.uuid}
                      shortcut={shortcut}
                      sortHandleClassName={DRAG_HANDLE_CLASS}
                      isSelected={mode.type === "View" && mode.uuid === shortcut.uuid}
                      isSortable={allProceduresSaved}
                      isEditable={!isTempId(shortcut.uuid)}
                      isDragging={index === draggingIndex}
                      onViewClick={() => setMode({ type: "View", uuid: shortcut.uuid })}
                      onEditClick={() => setMode({ type: "Edit", uuid: shortcut.uuid })}
                    />
                  );
                })}
                <AddShortcut
                  key="add"
                  onClick={() => setMode({ type: "Create" })}
                  invisible={draggingIndex >= 0}
                />
              </ReactSortable>
              {mode.type === "View" && selectedShortcut ? (
                <>
                  <ProceduresList procedures={selectedShortcut.procedures} className="mt-4" />
                  {selectedShortcut.surfaces ? (
                    <div className="mt-4 text-sm">
                      <div className="font-sansSemiBold">Tooth Surface</div>
                      <div className="mt-4">{mapToUnknowSurfaces(selectedShortcut.surfaces)}</div>
                    </div>
                  ) : null}
                </>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </div>
  );
};
