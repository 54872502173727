import { FC, useCallback } from "react";

import { useQueryClient } from "@tanstack/react-query";
import { AutomationActionVO, AutomationActionRequest, AutomationJourneyVO } from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { updateCachedData } from "@libs/utils/queryCache";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";

import { getAutomationJourney } from "api/communications/queries";
import { updateAutomationAction, deleteAutomationAction } from "api/communications/mutations";

import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { useItemModal } from "hooks/useItemModal";

import { paths } from "utils/routing/paths";
import { handleError } from "utils/handleError";
import { AutomationPageContent } from "components/Communications/Automations/AutomationPageContent";
import { journeyNames } from "components/Communications/Automations/utils";
import { getEmailConfig } from "api/settings/communications/queries";

export const AutomationJourneyRoute: FC = () => {
  const { practiceId } = useAccount();
  const queryClient = useQueryClient();
  // TODO remove cast when BE is updated
  const { journeyType } = usePathParams("journey");
  const { query } = useQueryParams("journey");
  const from = query.from ?? paths.communications();

  usePageTitle(`${journeyNames[journeyType]} Automated Messaging`);

  const confirmDeleteModal = useItemModal<AutomationActionVO["uuid"]>(null);

  const [emailConfigQuery, journeyQuery] = useApiQueries([
    getEmailConfig({ args: { practiceId } }),
    getAutomationJourney({ args: { practiceId, journeyType } }),
  ]);

  const [updateActionMutation, deleteActionMutation] = useApiMutations([
    updateAutomationAction,
    deleteAutomationAction,
  ]);

  const handleToggleActionStatus = useCallback(
    (actionUuid: string, data: AutomationActionRequest) => {
      // optimistic updates
      updateCachedData<AutomationJourneyVO>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getAutomationJourney"), { journeyType, practiceId }] },
        (cachedEntry) => {
          return {
            ...cachedEntry,
            actions: cachedEntry.actions.map((action) =>
              action.uuid === actionUuid ? { ...action, active: data.active } : action
            ),
          };
        }
      );
      updateCachedData<AutomationActionVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getAutomationAction"),
            { practiceId, journeyType, actionUuid },
          ],
        },
        (cachedEntry) => {
          return { ...cachedEntry, active: data.active };
        }
      );

      updateActionMutation.mutate(
        {
          practiceId,
          journeyType,
          actionUuid,
          data,
        },
        {
          onError: (error) => {
            // rollback optimistic updates
            queryClient.invalidateQueries([
              getQueryKey("practices", "getAutomationJourney"),
              { practiceId, journeyType },
            ]);
            queryClient.invalidateQueries([
              getQueryKey("practices", "getAutomationAction"),
              { practiceId, journeyType, actionUuid },
            ]);
            handleError(error);
          },
        }
      );
    },
    [updateActionMutation, practiceId, journeyType, queryClient]
  );

  const handleConfirmDelete = useCallback(() => {
    if (confirmDeleteModal.isOpen) {
      deleteActionMutation.mutate(
        {
          practiceId,
          journeyType,
          actionUuid: confirmDeleteModal.item,
        },
        { onError: handleError }
      );
      confirmDeleteModal.close();
    }
  }, [confirmDeleteModal, deleteActionMutation, practiceId, journeyType]);

  return (
    <QueryResult queries={[journeyQuery, emailConfigQuery]}>
      {journeyQuery.data && emailConfigQuery.data ? (
        <AutomationPageContent
          onRequestDeleteAction={confirmDeleteModal.open}
          onToggleActionStatus={handleToggleActionStatus}
          from={from}
          emailConfig={emailConfigQuery.data}
          journey={journeyQuery.data}
        />
      ) : null}

      {confirmDeleteModal.isOpen ? (
        <ConfirmationModal
          primaryText="Delete Action"
          secondaryText="This will also remove all existing scheduled messages"
          onCancel={confirmDeleteModal.close}
          onConfirm={handleConfirmDelete}
          isConfirming={deleteActionMutation.isLoading}
          size="2xs"
        />
      ) : null}
    </QueryResult>
  );
};
