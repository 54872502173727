/* eslint-disable react/no-unescaped-entities */
import { FC, PropsWithChildren, useEffect, useRef } from "react";
import useScript from "react-script-hook/lib/use-script";
import { Panel } from "@libs/components/UI/Panel";
import { ModalContent } from "@libs/components/UI/ModalComponents";
import { ReactComponent as ArchyReferralImg } from "assets/components/referral.svg";

const HUBSPOT_FORM_PLACEHOLDER_ID = "hubspotForm";

// Type guard for iframe
const isIframe = (element: Element | null): element is HTMLIFrameElement => {
  return element?.nodeName === "IFRAME";
};

export const ArchyReferral: FC = () => {
  const [loading, error] = useScript({
    src: "https://js.hsforms.net/forms/embed/v2.js",
    checkForExisting: true,
  });

  const formRootRef = useRef<HTMLDivElement>(null);

  const handleFormReady = () => {
    // Ensure that the iframe has full height to avoid content from being cut
    // off.
    const iframe = formRootRef.current?.firstElementChild ?? null;

    if (isIframe(iframe)) {
      iframe.classList.add("h-full");
    }
  };

  useEffect(() => {
    if (loading || error) {
      return;
    }

    window.hbspt?.forms.create({
      region: "na1",
      portalId: "22174332",
      formId: "aa7d09c4-ee2d-4d32-9f99-9af33b81d7be",
      target: `#${HUBSPOT_FORM_PLACEHOLDER_ID}`,
      onFormReady: handleFormReady,
    });
  }, [loading, error]);

  return (
    <Panel title="Archy Referral" className="h-full" contentClassName="overflow-y-auto">
      <div className="grid grid-cols-2 gap-x-10 h-full">
        <div className="flex flex-col">
          <div className="p-6">
            <ArchyReferralImg />
          </div>
          <div ref={formRootRef} id={HUBSPOT_FORM_PLACEHOLDER_ID} className="h-full" />
        </div>
        <ReferralExplainer />
      </div>
    </Panel>
  );
};

const ReferralExplainer: FC = () => {
  return (
    <div className="bg-slate-100">
      <ModalContent padding="lg">
        <div className="flex flex-col gap-y-6">
          <div className="text-2xl font-sansSemiBold">
            Cash In with Every Connection: Refer a Dental Practice to Archy
          </div>
          <Paragraph>
            <div className="font-sansSemiBold">🌟 Refer. Inspire. Prosper.</div>
            <div>
              Invite a dental practice to join Archy, and after they sign up and complete 30 days, you both
              are rewarded with a $350 Visa gift card. Plus, amplify your earnings with a $1000 bonus for
              every 5 referrals!
            </div>
          </Paragraph>
          <Paragraph>
            <div className="font-sansSemiBold">Your Referral Journey:</div>
            <ul className="list-decimal list-inside">
              <li>
                <span className="font-sansSemiBold">Refer:</span> Complete our simple form with the practice's
                contact details.
              </li>
              <li>
                <span className="font-sansSemiBold">Introduce:</span> We'll reach out and show them the
                transformative power of Archy.
              </li>
              <li>
                <span className="font-sansSemiBold">Prosper:</span> Once they sign up and thrive with Archy
                for 30 days, you both earn your reward.
              </li>
            </ul>
          </Paragraph>
          <Paragraph>
            <div className="font-sansSemiBold">Empower Practices and Enrich Your Wallet:</div>
            <div>
              Leverage your professional network to introduce peers to Archy's streamlined management solution
              and enjoy the financial benefits. Start your referral journey today and make each connection
              count!
            </div>
          </Paragraph>
        </div>
      </ModalContent>
    </div>
  );
};

const Paragraph: FC<PropsWithChildren> = ({ children }) => {
  return <div className="flex flex-col gap-y-2">{children}</div>;
};
