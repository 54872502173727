import { Link } from "react-router-dom";
import { parseISO, subDays } from "date-fns";
import Skeleton from "react-loading-skeleton";
import { Dispatch, SetStateAction, useCallback, useMemo } from "react";
import { RecentProcedureHistoryItemVO, ShortcutBenefitLimitationVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { pluralize } from "@libs/utils/pluralize";
import { formatAsISODate } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { ReactComponent as RemoveIcon } from "@libs/assets/icons/cancel-small.svg";
import { ReactComponent as VerifyIcon } from "@libs/assets/icons/verified-badge.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { HeaderCell, Row, TableGrid } from "@libs/components/UI/GridTableComponents";
import {
  LimitationValidationResult,
  ProcedureHistoryFormItem,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/formData";
import { TabBanner } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/TabBanner";
import { Section } from "components/Insurance/Section";
import { planCxStyles } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/styles";
import { paths } from "utils/routing/paths";
import { Cell } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/TableItems";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { LimitationsTable } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/LimitationsTable";
import { Divider } from "components/UI/Divider";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";
import { BenefitLimitationFormItem } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/useLimitationFields";

interface Props {
  editing: boolean;
  insurancePlanUuid: string | undefined;
  historyItems: ProcedureHistoryFormItem[] | undefined;
  historyLoading: boolean;
  insuranceOrPlanIsAutoVerified: boolean;
  limitationItems: BenefitLimitationFormItem[];
  limitationsLoading: boolean;
  limitationSearchString: string;
  notes: string;
  numOfPatients: number | undefined;
  onAddLimitationItem: Func;
  onClickEdit: Func;
  onHistoryItemChange: (updated: RecentProcedureHistoryItemVO) => void;
  onLimitationItemChange: (index: number, updated: BenefitLimitationFormItem) => void;
  onLimitationItemDelete: (index: number, limitation: BenefitLimitationFormItem) => void;
  onNoteChange: (note: string) => void;
  onOpenLimitationNotesModal: (newItem: { index: number; limitation: BenefitLimitationFormItem }) => void;
  onPreferredLimitationItemChange: (index: number, updated: ShortcutBenefitLimitationVO) => void;
  onSearch: (searchString: string) => void;
  onUpdateAllLimitations: Dispatch<SetStateAction<BenefitLimitationFormItem[]>>;
  preferredLimitationItems: ShortcutBenefitLimitationVO[];
  validation: LimitationValidationResult;
}

const HISTORY_HEADERS = [
  { text: "Service", width: "1fr" },
  { text: "Date", width: "1fr" },
];
const DEFAULT_NOTE_ROWS = 8;

// eslint-disable-next-line complexity
export const LimitationsAndHistoryTables: React.FC<Props> = ({
  editing,
  insurancePlanUuid,
  historyItems,
  historyLoading,
  insuranceOrPlanIsAutoVerified,
  limitationItems,
  limitationsLoading,
  limitationSearchString,
  notes,
  numOfPatients,
  onAddLimitationItem,
  onClickEdit,
  onHistoryItemChange,
  onLimitationItemChange,
  onLimitationItemDelete,
  onNoteChange,
  onOpenLimitationNotesModal,
  onPreferredLimitationItemChange,
  onSearch,
  onUpdateAllLimitations,
  preferredLimitationItems,
  validation,
}) => {
  const expandSearch = useBoolean(false);
  const isViewingDefault = useBoolean(true);

  const handleToggleView = useCallback(
    (showPreferred: boolean) => {
      if (showPreferred) {
        onSearch("");
        isViewingDefault.on();
      } else {
        isViewingDefault.off();
      }
    },
    [isViewingDefault, onSearch]
  );

  const headers = useMemo(
    () =>
      insuranceOrPlanIsAutoVerified ? [...HISTORY_HEADERS, { text: "", width: "50px" }] : HISTORY_HEADERS,
    [insuranceOrPlanIsAutoVerified]
  );

  return (
    <div className="flex flex-col mb-6">
      <TabBanner
        description={
          <div>
            Updating the Limitations and Notes will affect all&nbsp;
            <Link
              className="text-primaryTheme"
              // eslint-disable-next-line @typescript-eslint/naming-convention
              to={paths.patients({ "patientCriteria.insurancePlanUuid": insurancePlanUuid })}
            >
              {`${numOfPatients ?? 0} ${pluralize(numOfPatients ?? 0, "patient", "patients")}`}
            </Link>
            &nbsp;who have this insurance plan.
          </div>
        }
        editing={editing}
        onClickEdit={onClickEdit}
      />
      <div className="flex flex-col gap-y-6 mt-6 mx-6">
        <Section
          className={cx("max-w-screen-2xl", planCxStyles.section)}
          includePadding={false}
          title={
            <div className="flex items-center justify-between">
              {/* Need to hardcode same height as search input to avoid layout shifting */}
              <div className="flex items-center h-[34px] gap-x-3">
                <div className="flex flex-col gap-y-1">
                  <button
                    className={cx("text-xs", isViewingDefault.isOn ? "text-primaryTheme" : "text-slate-900")}
                    onClick={() => handleToggleView(true)}
                    type="button"
                  >
                    Default Limitations
                  </button>
                  <div
                    className={cx(
                      "h-0.5 w-full rounded-full",
                      isViewingDefault.isOn ? "bg-primaryTheme" : "bg-transparent"
                    )}
                  />
                </div>
                <div className="flex flex-col gap-y-1">
                  <button
                    className={cx("text-xs", isViewingDefault.isOn ? "text-slate-900" : "text-primaryTheme")}
                    onClick={() => handleToggleView(false)}
                    type="button"
                  >
                    All Limitations
                  </button>
                  <div
                    className={cx(
                      "h-0.5 w-full rounded-full",
                      isViewingDefault.isOn ? "bg-transparent" : "bg-primaryTheme"
                    )}
                  />
                </div>
              </div>
              {isViewingDefault.isOff && (
                <FormFieldInput
                  className={cx(
                    "font-sans transition-all duration-50 ease-in",
                    expandSearch.isOn || limitationSearchString ? "w-44" : "w-24"
                  )}
                  Icon={limitationSearchString ? RemoveIcon : SearchIcon}
                  iconOnClick={() => onSearch("")}
                  onBlur={expandSearch.off}
                  onChange={(e) => onSearch(e.target.value)}
                  onFocus={expandSearch.on}
                  placeholder={expandSearch.isOn ? "Search CDT Code" : "Search"}
                  value={limitationSearchString}
                />
              )}
            </div>
          }
          useCustomStyling={true}
        >
          <LimitationsTable
            editing={editing}
            insuranceOrPlanIsAutoVerified={insuranceOrPlanIsAutoVerified}
            isViewingDefault={isViewingDefault.isOn}
            loading={limitationsLoading}
            limitationItems={limitationItems}
            onAddLimitationItem={onAddLimitationItem}
            onLimitationItemChange={onLimitationItemChange}
            onLimitationItemDelete={onLimitationItemDelete}
            onOpenLimitationNotesModal={onOpenLimitationNotesModal}
            onPreferredLimitationItemChange={onPreferredLimitationItemChange}
            onUpdateAllLimitations={onUpdateAllLimitations}
            preferredLimitationItems={preferredLimitationItems}
            validation={validation}
          />
        </Section>
        <Divider className="border-dashed max-w-xl" />
        <Section
          className={cx("max-w-xl", planCxStyles.section)}
          includePadding={false}
          title="History"
          useCustomStyling={true}
        >
          <TableGrid
            className={`
              rounded
              border
              border-greyLightest
              border-b-0
              min-w-0
              text-xs
              font-sans
              overflow-x-auto
            `}
            columnWidths={headers.map(({ width }) => width)}
          >
            {headers.map((item) => (
              <HeaderCell key={item.text} size="short">
                {item.text}
              </HeaderCell>
            ))}
            {historyLoading ? (
              <Skeleton className="-top-1 h-[400px] w-[576px]" />
            ) : (
              historyItems?.map((item, index) => (
                <Row key={index}>
                  <Cell>{item.groupName}</Cell>
                  <Cell>
                    <FormFieldSelectMenusDatepicker
                      edit={editing}
                      inputClassName="p-2 rounded w-full"
                      layout="tableValue"
                      onChange={(newDate) => {
                        onHistoryItemChange({
                          ...item,
                          autoEligibility: false,
                          date: newDate ? formatAsISODate(newDate) : undefined,
                        });
                      }}
                      maxDate={subDays(new Date(), 1)}
                      selected={item.date ? parseISO(item.date) : undefined}
                    />
                  </Cell>
                  {insuranceOrPlanIsAutoVerified && (
                    <Cell>
                      {item.autoEligibility && (
                        <Icon
                          SvgIcon={VerifyIcon}
                          theme="primary"
                          tooltip={{ content: "Auto-Verified", theme: "SMALL" }}
                        />
                      )}
                    </Cell>
                  )}
                </Row>
              ))
            )}
          </TableGrid>
        </Section>
        <Divider className="border-dashed max-w-xl" />
        <Section
          className={cx("max-w-xl", planCxStyles.section)}
          includePadding={false}
          title="Plan Notes"
          useCustomStyling={true}
        >
          {editing ? (
            <FormFieldTextarea
              className="font-sans"
              edit
              rows={DEFAULT_NOTE_ROWS}
              onChange={(e) => onNoteChange(e.target.value)}
              value={notes}
            />
          ) : (
            <div className="text-xs font-sans">{notes || "None"}</div>
          )}
        </Section>
      </div>
    </div>
  );
};
