import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Button } from "@libs/components/UI/Button";
import { PaymentProfileVO } from "@libs/api/generated-api";
import EmptySavedCards from "assets/images/empty-payment-cards.svg";

import { PaymentProfiles, EmptyPaymentMethod } from "components/PatientProfile/Billing/FormComponents";
import {
  BaseErrorCases,
  BasePaymentMethodsProps,
} from "components/PatientProfile/Billing/PaymentMethods/utils";

type Props = {
  paymentProfiles: PaymentProfileVO[];
  patientId: number;
  onClickAddCard: Func;
} & Pick<BasePaymentMethodsProps, "onPaymentProfileUuidChange" | "paymentProfileUuid" | "disabled"> & {
    errors: Pick<BaseErrorCases, "paymentProfile">;
  };
export const StoredProfileOrRefundableCard: React.FC<Props> = ({
  paymentProfiles,
  patientId,
  onPaymentProfileUuidChange,
  onClickAddCard,
  paymentProfileUuid,
  disabled,
  errors,
}) => {
  return (
    <>
      <div className="col-span-full flex flex-row items-center gap-2 justify-start">
        <div>
          <FormFieldLabel content="Card" className="text-xs font-sansSemiBold" />
        </div>
        <ButtonIcon
          SvgIcon={AddIcon}
          onClick={onClickAddCard}
          tooltip={{ content: "Add Card", theme: "SMALL" }}
          theme="primary"
          disabled={disabled}
          size="sm"
        />
      </div>
      {paymentProfiles.length > 0 ? (
        <PaymentProfiles
          patientId={patientId}
          paymentProfiles={paymentProfiles}
          paymentProfileUuid={paymentProfileUuid}
          onPaymentProfileUuidChange={onPaymentProfileUuidChange}
          disabled={disabled}
        />
      ) : (
        <div className="col-span-2">
          <EmptyPaymentMethod message="No saved cards. Please add a card first." image={EmptySavedCards}>
            No saved cards. Please{" "}
            <Button theme="link" onClick={onClickAddCard}>
              add a card
            </Button>{" "}
            first.
          </EmptyPaymentMethod>
        </div>
      )}
      {errors.paymentProfile && (
        <FormFieldError className="col-start-1">{errors.paymentProfile}</FormFieldError>
      )}
    </>
  );
};
