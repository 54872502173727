import { FC } from "react";
import { cx } from "@libs/utils/cx";
import { Icon } from "@libs/components/UI/Icon";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as RightCaret } from "@libs/assets/icons/right-caret.svg";

interface Props {
  title: string;
  description: string;
  ctaText: string;
  className?: string;
  onCtaClick: Func;
}

export const AlternateRunPayrollSection: FC<Props> = ({
  className,
  title,
  description,
  ctaText,
  onCtaClick,
}) => {
  return (
    <div className={cx("border border-greyLighter rounded p-4 w-80", className)}>
      <h3 className="text-sm font-sansSemiBold">{title}</h3>
      <p className="text-xs mt-1">{description}</p>
      <Button className="text-xs flex mt-3" onClick={onCtaClick} theme="link">
        {ctaText} <Icon size="sm" SvgIcon={RightCaret} theme="primary" />
      </Button>
    </div>
  );
};
