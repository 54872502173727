import React from "react";

import { Checkbox } from "@libs/components/UI/Checkbox";
import { DeviceRow } from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/DeviceRow";
import { ModalBodyProps } from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/types";
import { CaptureDeviceTable } from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/CaptureDeviceTable";
import { ImagingDeviceWithSettings } from "components/PatientProfile/Imaging/hooks/useImagingDevices";
import { useItemModal } from "hooks/useItemModal";
import { DeviceSettingsFlyover } from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/DeviceSettingsFlyover";
import { useTwain } from "components/ImageCapturing/useTwain";
import { useImagingHub } from "components/ImageCapturing/ImagingHubContext";
import { DynamsoftDriverRequired } from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/DynamsoftDriverRequired";
import { AISDownloadWarning } from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/AISDownloadWarning";

export const ImageSettingsModalBody: React.FC<ModalBodyProps> = ({ devices, onChange, isLoadingDevices }) => {
  const { twainInstance } = useTwain();
  const { status: hubStatus, hasForcedDynamsoft, toggleForceDynamsoft } = useImagingHub();
  const itemEdited = useItemModal<ImagingDeviceWithSettings | null>(null);

  return (
    <>
      <div className="flex flex-col gap-3">
        {hubStatus.isAISDisabled && twainInstance.neededDownloadUrl ? (
          <DynamsoftDriverRequired neededDownloadUrl={twainInstance.neededDownloadUrl} />
        ) : (
          !hubStatus.isRunning && !hubStatus.isAISDisabled && <AISDownloadWarning />
        )}

        {!isLoadingDevices && devices.length === 0 ? (
          <div className="text-greyMedium col-span-5 text-center pt-8 text-xs">No Cameras Connected</div>
        ) : (
          <CaptureDeviceTable isLoading={isLoadingDevices}>
            {devices.map((item) => {
              return (
                <DeviceRow
                  key={item.settings.id}
                  item={item}
                  onChange={onChange}
                  onClickEdit={itemEdited.open}
                />
              );
            })}
          </CaptureDeviceTable>
        )}
        {hubStatus.isAISAllowed && hasForcedDynamsoft && (
          <div className="flex justify-end">
            <Checkbox className="mr-8" checked={hasForcedDynamsoft} onChange={toggleForceDynamsoft}>
              Force Dynamsoft
            </Checkbox>
          </div>
        )}
      </div>
      {itemEdited.item && (
        <DeviceSettingsFlyover
          device={itemEdited.item}
          onClose={itemEdited.close}
          onChange={(newSettings) => {
            if (itemEdited.item) {
              onChange({ ...itemEdited.item, settings: newSettings });
            }
          }}
        />
      )}
    </>
  );
};
