/* eslint-disable complexity */
import React from "react";
import { ReportingRecallEntryVO, ReportingRecallRequest } from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { usePick } from "@libs/hooks/usePick";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { useFlattenEntries } from "components/Dashboard/hooks/useFlattenEntries";
import { useFilterTokenProps } from "components/Dashboard/hooks/useFilterTokenProps";
import { DashboardTableFilters } from "components/Dashboard/Tables/DashboardTableFilters";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import {
  RECALL_BY_PROCEDURE_HEADERS,
  RECALL_REPORT_AGING_OPTIONS,
} from "components/Dashboard/RecallReport/constants";
import { RecallReportQuery } from "utils/routing/dashboard";
import { RecallReportTableByProcedureRow } from "components/Dashboard/RecallReport/RecallReportTableByProcedureRow";
import { SelectHasNextApptFilter } from "components/Dashboard/Tables/SelectHasNextApptFilter";
import { useQueryParams } from "hooks/useQueryParams";
import { useSelectedAgingOption } from "components/Dashboard/hooks/useSelectedAgingOption";
import { getRecallStats } from "api/reporting/queries";
import { MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";
import { useDownloadRecallByProcedureReportCsv } from "components/Dashboard/RecallReport/hooks/useDownloadRecallByProcedureReportCsv";
import { DashboardTableTabs } from "components/Dashboard/Tables/DashboardTableTabs";
import { useTableTabProps } from "components/Dashboard/RecallReport/hooks/useTableTabProps";
import { DashboardFilterFormFieldSelect } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";
import { PatientStatusFilter } from "components/Dashboard/Tables/PatientStatusFilter";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";

const RECALL_TYPES: ReportingRecallEntryVO["recallType"][] = [
  "EXAM",
  "PROPHY",
  "FOUR_BW",
  "PANO",
  "FLUORIDE",
  "PERIO",
];

const RECALL_TYPE_OPTIONS = RECALL_TYPES.map((value) => ({
  label: sentenceCaseConstant(value),
  value,
}));

export const RecallReportByProcedureTableRoute: React.FC = () => {
  const { query, updateQuery } = useQueryParams("dashboardRecall");
  const { search: debouncedSearch } = useDebouncedSearch(query.patientSearch ?? "");

  const { practiceId } = useAccount();
  const { startDate, endDate } = useSelectedAgingOption({
    dateAgeIndex: query.dateAgeIndex,
    labels: RECALL_REPORT_AGING_OPTIONS,
    includeFuture: true,
  });
  const { procedureTableSort, filters } = query;

  const reportParams: ReportingRecallRequest = {
    startDate,
    endDate,
    patientSearchString: debouncedSearch,
    includeTotal: true,
    filters,
    sortOrders: procedureTableSort,
  };
  const recallReportInfiniteQuery = useInfiniteApiQuery(
    getRecallStats({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: MAX_PAGE_SIZE,
        data: reportParams,
      },
    })
  );
  const { data: paginatedData, isLoading } = recallReportInfiniteQuery;

  const handleRouteStateChange = React.useCallback(
    (updates: Partial<RecallReportQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );
  const handleSortClick = React.useCallback(
    (newSortOrders: ColumnSortOrder[]) => {
      handleRouteStateChange({
        procedureTableSort: newSortOrders,
      });
    },
    [handleRouteStateChange]
  );
  const filterParams = usePick(query, ["filters", "patientSearch"]);

  const filterProps = useFilterTokenProps({ options: filterParams });
  const totalRows = getInfiniteQueryPagingDetails(paginatedData)?.totalElements;
  const rows = useFlattenEntries(paginatedData);
  const isEmpty = !isLoading && rows.length === 0;
  const { isDownloading, downloadCSV } = useDownloadRecallByProcedureReportCsv({
    recallReportInfiniteQuery,
    startDate,
    endDate,
  });
  const tableTabProps = useTableTabProps({ query, handleRouteStateChange });

  return (
    <>
      <DashboardTableTabs
        {...tableTabProps}
        isDownloading={isDownloading}
        onClickDownload={downloadCSV}
        id="recall-report-filters"
      >
        <PatientStatusFilter query={query} onUpdateParams={handleRouteStateChange} />
        <DashboardFilterFormFieldSelect
          placeholder="Procedure"
          aria-label="Procedure"
          dashboardFilters={filters}
          dashboardFilterType="recallType"
          isSearchable={false}
          options={RECALL_TYPE_OPTIONS}
          className="min-w-[140px]"
          onChangeFilters={handleRouteStateChange}
        />
        <SelectHasNextApptFilter query={query} onUpdateParams={handleRouteStateChange} />
      </DashboardTableTabs>

      <DashboardTableFilters {...filterParams}>
        <QueryFilterPills
          {...filterProps}
          numResults={totalRows}
          onUpdateParams={handleRouteStateChange}
          onClearAll={() => {
            handleRouteStateChange({ filters: [], patientSearch: undefined });
          }}
        />
      </DashboardTableFilters>
      <DashboardInfiniteTable
        columnWidths={RECALL_BY_PROCEDURE_HEADERS.map(({ width }) => width)}
        id="recallReportByProcedureTable"
        isEmpty={isEmpty}
        infiniteQuery={recallReportInfiniteQuery}
        className="rounded-b-md"
        headerRow={
          <DashboardHeaderRow
            headers={RECALL_BY_PROCEDURE_HEADERS}
            onSortClick={handleSortClick}
            sortOrders={procedureTableSort}
          />
        }
      >
        {rows.map((row, i) => {
          const key = `${row.patient.id}-${row.uuid}-${row.recallType}`;

          return <RecallReportTableByProcedureRow key={key} row={row} last={i === rows.length - 1} />;
        })}
      </DashboardInfiniteTable>
    </>
  );
};
