import { FC } from "react";
import { DraftAutomationAction } from "components/Communications/Automations/types";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { AutomationActionValidation, IntervalTypeOptions } from "components/Communications/Automations/utils";
import { TimeInput } from "components/UI/TimeInput";

interface Props {
  action: DraftAutomationAction;
  validation: AutomationActionValidation;
  includeTime?: boolean;
  onUpdate: (updates: Partial<DraftAutomationAction>) => void;
}

export const BeforeTimeFields: FC<Props> = ({ validation, action, onUpdate, includeTime }) => {
  return (
    <div className="flex items-center gap-x-2">
      <FormFieldNumberInput
        onValueChange={(newValue) => onUpdate({ interval: newValue })}
        value={action.interval}
        min={0}
        step={1}
        clamp
        placeholder="0"
        error={validation.interval.$error}
        displayErrorMessage={false}
        className="flex-none w-14"
      />
      <FormFieldSelect
        options={IntervalTypeOptions.map((option) => ({
          ...option,
          label: `${option.label} Before`,
        }))}
        value={action.intervalType}
        isClearable={false}
        isSearchable={false}
        onItemSelected={(newValue) => onUpdate({ intervalType: newValue })}
        placeholder="Select Period"
        aria-label="Select Period"
        disabled={action.eventTime === "WHEN"}
        error={validation.intervalType.$error}
        displayErrorMessage={false}
        className="flex-none w-36"
      />
      {includeTime ? (
        <>
          <span className="text-xs">at</span>
          <TimeInput
            error={validation.sendTime.$error}
            displayErrorMessage={false}
            value={action.sendTime}
            onChange={(newTime) => onUpdate({ sendTime: newTime })}
          />
        </>
      ) : null}
    </div>
  );
};
