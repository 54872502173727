import React from "react";
import { useInvoiceAgeOptions } from "components/Dashboard/OutstandingCollections/hooks/useInvoiceAgeOptions";
import { useQueryParams } from "hooks/useQueryParams";

export const useOutstandingCollectionsSelectedTimeSegment = () => {
  const { query } = useQueryParams("dashboardPaymentOutstanding");

  const timeSegment = useInvoiceAgeOptions().find(
    (item) => item.value >= 0 && item.value === query.dateAgeIndex
  );

  return React.useMemo(() => timeSegment, [timeSegment]);
};
