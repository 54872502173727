import { FC, PropsWithChildren } from "react";
import { cx } from "@libs/utils/cx";

const cxCreditCardBadge = {
  theme: {
    primary: "bg-actionLight text-primaryTheme",
    violet: "bg-violetLight text-violet",
  },
};

export const PaymentMethodBadge: FC<PropsWithChildren<{ theme: keyof typeof cxCreditCardBadge.theme }>> = ({
  theme,
  children,
}) => {
  return (
    <div
      className={cx(
        `absolute
         right-0
         bottom-0
         font-sansSemiBold
         capitalize
         text-xs
         px-2
         py-0.5
         rounded-br
         rounded-tl`,
        cxCreditCardBadge.theme[theme]
      )}
    >
      {children}
    </div>
  );
};
