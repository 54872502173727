import React, { FC } from "react";

import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useAccount } from "@libs/contexts/AccountContext";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { useQueryParams } from "hooks/useQueryParams";
import { PatientsTreatedQuery } from "utils/routing/dashboard";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { getPatientAppointmentStatsInfiniteQuery, getTimeSeriesQuery } from "api/reporting/queries";
import { dashboardFiltersForCharting, dashboardFiltersForTables } from "components/Dashboard/utils/filters";
import { PatientsTreatedTable } from "components/Dashboard/PatientsTreated/PatientsTreatedTable";
import { useDownloadPatientAppointmentStats } from "components/Dashboard/PatientsTreated/hooks/useDownloadTreatedPatientsCSV";
import { getClaimInsuranceCarriersQuery } from "api/patientInsurance/queries";
import { DashboardLayout } from "components/Dashboard/DashboardLayout";
import { DashboardPageHeader } from "components/Dashboard/DashboardPageHeader";
import { NewVsExistingChart } from "components/Dashboard/Charting/NewVsExistingChart";
import { MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";

export const PatientsTreatedRoute: FC = () => {
  const { practiceId } = useAccount();
  const { query, updateQuery } = useQueryParams("dashboardPatientsTreated");
  const handleRouteStateChange = React.useCallback(
    (updates: Partial<PatientsTreatedQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );
  const dateWindow = useTimeSeriesPageSelections(query);
  const { selectedTimeSegment, selectedTimeSegmentParams, timeSeriesParams } = dateWindow;
  const requestParams = {
    resolution: query.resolution,
  };

  const [patientCountQuery, carriersQuery] = useApiQueries([
    getTimeSeriesQuery({
      args: {
        practiceId,
        data: {
          ...requestParams,
          ...timeSeriesParams,
          filters: dashboardFiltersForCharting(query.filters),
          metric: "patientCount",
          groupBy: "patientType",
        },
      },
    }),
    getClaimInsuranceCarriersQuery({ args: { practiceId } }),
  ]);
  const { search: debouncedSearch } = useDebouncedSearch(query.patientSearch ?? "");
  const appointmentStatsInfiniteQuery = useInfiniteApiQuery(
    getPatientAppointmentStatsInfiniteQuery({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: MAX_PAGE_SIZE,
        data: {
          ...requestParams,
          ...selectedTimeSegmentParams,
          sortOrders: query.tableSort,
          filters: dashboardFiltersForTables(query.filters),
          includeTotal: true,
          patientSearchString: debouncedSearch,
        },
      },
    })
  );

  const { isDownloading, downloadCSV } = useDownloadPatientAppointmentStats({
    appointmentStatsInfiniteQuery,
    selectedTimeSegment,
  });

  return (
    <DashboardLayout
      className="min-w-[1400px]"
      header={
        <>
          <DashboardPageHeader
            question="patients-treated"
            focusDate={query.focusDate}
            resolution={query.resolution}
            dateWindow={dateWindow}
            chartDisplay={query.chartDisplay}
            onUpdateParams={handleRouteStateChange}
          />
          {!query["table.fullScreen"] && (
            <NewVsExistingChart
              dateWindow={dateWindow}
              chartDisplay={query.chartDisplay}
              resolution={query.resolution}
              timeSeries={patientCountQuery.data}
              isLoading={patientCountQuery.isLoading}
              onUpdateParams={handleRouteStateChange}
              focusDate={query.focusDate}
            >
              {/* {query["table.fullScreen"] ? null : (
      <FilterByProvider
        formatAsCurrency
        providerFilterOptions={providerFilterOptions}
        providerFilters={providerFilters}
        isLoading={isLoadingProviderRollups}
        onProviderClicked={handleProviderFilterClicked}
      />
    )} */}
            </NewVsExistingChart>
          )}
        </>
      }
    >
      <PatientsTreatedTable
        patientSearch={query.patientSearch}
        debouncedSearch={debouncedSearch}
        query={query}
        carriersQuery={carriersQuery}
        onUpdateParams={handleRouteStateChange}
        isFullScreen={query["table.fullScreen"]}
        onClickDownload={downloadCSV}
        appointmentStatsInfiniteQuery={appointmentStatsInfiniteQuery}
        isDownloadingTable={isDownloading}
      />
    </DashboardLayout>
  );
};
