import { FC } from "react";
import { CustomAdjustmentTypeVO } from "@libs/api/generated-api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { IconsCell, Row, TextCell } from "@libs/components/UI/GridTableComponents";
import {
  TOOLTIP_ADJUSTMENT_CREDIT,
  TOOLTIP_ADJUSTMENT_DEBIT,
} from "components/PatientProfile/Billing/billingUtils";
import { ADJUSTMENT_ENTRY_TYPE_TO_LABEL } from "components/Settings/Payments/CustomAdjustmentsSettings";

export const CustomAdjustmentRow: FC<{
  adjustment: CustomAdjustmentTypeVO;
  isLast: boolean;
  onArchive: Func;
}> = ({ adjustment, isLast, onArchive }) => {
  return (
    <Row key={adjustment.id}>
      <TextCell border={!isLast}>{adjustment.name}</TextCell>
      <TextCell border={!isLast}>
        <FloatingTooltip
          content={adjustment.entryType === "CREDIT" ? TOOLTIP_ADJUSTMENT_CREDIT : TOOLTIP_ADJUSTMENT_DEBIT}
        >
          <span>{ADJUSTMENT_ENTRY_TYPE_TO_LABEL[adjustment.entryType]}</span>
        </FloatingTooltip>
      </TextCell>
      <IconsCell border={!isLast}>
        {adjustment.isArchivable && (
          <ButtonIcon
            theme="primary"
            SvgIcon={DeleteIcon}
            tooltip={{ content: "Delete", theme: "SMALL" }}
            onClick={onArchive}
          />
        )}
      </IconsCell>
    </Row>
  );
};
