import { LastStatementVO, PatientBalanceAgingEntryVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { formatPhoneNumber } from "@libs/utils/phone";
import { formatISODate, formatUnixTimestamp, LONG_LOCALIZED_DATE } from "@libs/utils/date";
import { cx } from "@libs/utils/cx";
import { Spinner } from "@libs/components/UI/Spinner";
import { useCallback, useContext } from "react";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as DownloadIcon } from "@libs/assets/icons/download.svg";
import { useBoolean } from "@libs/hooks/useBoolean";
import {
  EMPTY_CELL,
  Row,
  CheckboxCell,
  Cell,
  cxGridTableStyles,
} from "@libs/components/UI/GridTableComponents";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";
import { paths } from "utils/routing/paths";
import { PatientNameLink } from "components/Dashboard/Tables/PatientNameLink";
import { cxTableStyles } from "components/Dashboard/Tables/cxTableStyles";
import { handleError } from "utils/handleError";

export type PatientBalanceAgingEntryVOLoading = Omit<PatientBalanceAgingEntryVO, "lastStatement"> & {
  lastStatement?: LastStatementVO & { isCompleted?: false };
};

export type Props = {
  data: PatientBalanceAgingEntryVOLoading;
  last?: boolean;
  checked: boolean;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};
export const ByPatientAgingRow: React.FC<Props> = ({ data, last, checked, onCheckboxChange }) => {
  const { fetchData } = useContext(ApiClientContext);
  const { id: practiceId, timezoneId } = useCurrentPractice();
  const isDownloading = useBoolean(false);
  const {
    patient,
    patientPhone,
    totalPatientBalance,
    aging121OrMore,
    aging30OrLess,
    aging31To60,
    aging61To90,
    aging91To120,
    nextAppointmentDate,
    lastStatement,
    paymentRequestedAt,
  } = data;

  const cellProps = { last, to: paths.patientBilling({ patientId: patient.id }) };

  const downloadStatement = useCallback(
    async (uuid: string) => {
      isDownloading.on();

      try {
        const url = await fetchData<string>(
          `/practices/${practiceId}/patients/${patient.id}/statements/${uuid}/print`
        );

        window.open(url);
      } catch (err) {
        handleError(err);
      } finally {
        isDownloading.off();
      }
    },
    [practiceId, patient.id, fetchData, isDownloading]
  );

  return (
    <Row>
      <CheckboxCell
        styleOptions={{ border: !last }}
        value={patient.id}
        checked={checked}
        onChange={onCheckboxChange}
      />

      <DashboardLinkCell {...cellProps}>
        <PatientNameLink patient={patient} to={cellProps.to} />
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        {patientPhone ? formatPhoneNumber(patientPhone) : EMPTY_CELL}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        {nextAppointmentDate ? formatISODate(nextAppointmentDate) : EMPTY_CELL}
      </DashboardLinkCell>
      <Cell className={cx(cxTableStyles.cell(last, "left"), cxGridTableStyles.dataCell)}>
        {lastStatement?.isCompleted === false ? (
          <div className="flex items-center gap-x-2">
            Generating <Spinner size="xs" variant="primary" animation="border" />
          </div>
        ) : lastStatement ? (
          <button
            disabled={isDownloading.isOn}
            onClick={() => downloadStatement(lastStatement.uuid)}
            type="button"
            className="flex items-center gap-x-2"
          >
            {formatISODate(lastStatement.date)}{" "}
            {isDownloading.isOn ? (
              <Spinner size="xs" variant="primary" animation="border" />
            ) : (
              <Icon SvgIcon={DownloadIcon} size="sm" theme="primary" />
            )}
          </button>
        ) : (
          EMPTY_CELL
        )}
      </Cell>
      <Cell className={cx(cxTableStyles.cell(last, "left"), cxGridTableStyles.dataCell)}>
        {paymentRequestedAt
          ? formatUnixTimestamp(paymentRequestedAt, timezoneId, LONG_LOCALIZED_DATE)
          : EMPTY_CELL}
      </Cell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(aging30OrLess)}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(aging31To60)}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(aging61To90)}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(aging91To120)}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(aging121OrMore)}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(totalPatientBalance)}
      </DashboardLinkCell>
    </Row>
  );
};
