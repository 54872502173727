import { FC } from "react";
import { FormDateInputElementVO, FormDateResponseVO } from "@libs/api/generated-api";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { isNullish } from "@libs/utils/types";
import { FormSubmissionResponses } from "components/PatientProfile/Forms/types";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";

export const DateInput: FC<{
  element: FormDateInputElementVO;
  response?: FormDateResponseVO;
  onUpdate?: (updater: (last: FormSubmissionResponses) => void) => void;
}> = ({ element, onUpdate, response }) => {
  const edit = Boolean(onUpdate);
  const handleChange = (date: Date | null) => {
    onUpdate?.((currentResponses) => {
      currentResponses[element.uuid] = {
        type: "DATE",
        response: isNullish(date) ? "" : formatAsISODate(date),
      };
    });
  };

  return (
    <FormFieldSelectMenusDatepicker
      edit={edit}
      label={element.title}
      selected={response?.response ? getLocalDate(response.response) : null}
      onChange={handleChange}
    />
  );
};
