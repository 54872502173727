import { ClaimPaymentVO, PaymentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatDate, SECOND_IN_MS } from "@libs/utils/date";
import { formatCurrency } from "@libs/utils/currency";
import { TableGrid, HeaderCell, Cell, Row } from "@libs/components/UI/GridTableComponents";
import { ClaimSection } from "components/Claim/ClaimSection";
import { cxStyles } from "components/Claim/ProcedureTable";

interface Props {
  claimPayments: ClaimPaymentVO[];
  insuranceAmount: number;
  patientAmount: number;
}

const headers = [
  { id: "paymentCreatedAt", label: "Date", width: "120px" },
  { id: "method", label: "Type", width: "120px" },
  { id: "description", label: "Description", width: "1fr" },
  { id: "insuranceAmount", label: "Amount", width: "120px" },
];

export const getClaimPaymentMethod = (method: PaymentVO["method"]) => {
  return method === "EFT"
    ? "EFT"
    : method === "CHECK"
      ? "Check"
      : method === "STORED_PROFILE"
        ? "Virtual CC"
        : "-";
};

const getClaimPaymentDescription = (payment: PaymentVO) => {
  switch (payment.method) {
    case "EFT": {
      return `EFT #${payment.eftPayload?.eftNumber ?? ""}`;
    }
    case "CHECK": {
      return `Check #${payment.checkPayload?.checkNumber ?? ""}`;
    }
    case "CASH": {
      return payment.note;
    }
    case "EXTERNAL_POS": {
      return payment.externalPosPayload?.cardLastFour
        ? `Card Number ${payment.externalPosPayload.cardLastFour}`
        : "";
    }
    default: {
      return "";
    }
  }
};

export const ClaimPaymentsSection: React.FC<Props> = ({ claimPayments, insuranceAmount, patientAmount }) => {
  return (
    <>
      <ClaimSection title="Payments">
        {claimPayments.length ? (
          <div className="bg-white rounded border border-b-0 border-greyLighter">
            <TableGrid columnWidths={headers.map(({ width }) => width)}>
              {headers.map((header) => (
                <HeaderCell
                  className={cx(header.id === "insuranceAmount" && "justify-end")}
                  key={header.id}
                  size="slim"
                >
                  {header.label}
                </HeaderCell>
              ))}
              {claimPayments.map((claimPayment, index) => {
                const isLastPayment = index === claimPayments.length - 1;

                return (
                  <Row key={index}>
                    <Cell
                      borderBottom={isLastPayment}
                      className={cx(cxStyles.cell({}), cxStyles.cellYPadding)}
                    >
                      {formatDate(new Date(claimPayment.payment.paymentCreatedAt * SECOND_IN_MS))}
                    </Cell>
                    <Cell
                      borderBottom={isLastPayment}
                      className={cx(cxStyles.cell({}), cxStyles.cellYPadding)}
                    >
                      {getClaimPaymentMethod(claimPayment.payment.method)}
                    </Cell>
                    <Cell
                      borderBottom={isLastPayment}
                      className={cx(cxStyles.cell({}), cxStyles.cellYPadding)}
                    >
                      {getClaimPaymentDescription(claimPayment.payment)}
                    </Cell>
                    <Cell
                      borderBottom={isLastPayment}
                      className={cx("justify-end", cx(cxStyles.cell({})), cxStyles.cellYPadding)}
                    >
                      {formatCurrency(claimPayment.insuranceAmount)}
                    </Cell>
                  </Row>
                );
              })}
            </TableGrid>
          </div>
        ) : (
          <span className="text-greyDark text-xs font-sans">None</span>
        )}
      </ClaimSection>
      <div
        className={`
          flex
          items-center
          justify-between
          mt-6
          py-2
          px-4
          text-greyDark
          font-sansSemiBold
          bg-greyLighter
          rounded
        `}
      >
        <span className="flex-1 text-xs">Final Balance</span>
        <div className="flex gap-x-36">
          <div className="flex flex-col items-end">
            <div className="text-xs">Patient Responsibility</div>
            <div className="text-sm">{formatCurrency(patientAmount)}</div>
          </div>
          <div className="flex flex-col items-end">
            <div className="text-xs">Insurance Payment</div>
            <div className="text-sm">{formatCurrency(insuranceAmount)}</div>
          </div>
        </div>
      </div>
    </>
  );
};
