import React from "react";
import { InsurancePlanVO, PatientInsuranceVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { EditCurrencyInCentsCell } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/TableItems";
import { UsePlanFieldsResult } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/usePlanFields";
import {
  SubscriberFields,
  UseInsuranceFields,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/useInsuranceFields";
import { InsuranceValidationResult } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/DedMaxAndCoverageTab/formData";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";

type PlanNumericField =
  | "individualAnnualDeductible"
  | "individualLifetimeDeductible"
  | "familyAnnualDeductible"
  | "individualAnnualMaximum"
  | "individualLifetimeMaximum"
  | "familyAnnualMaximum"
  | "orthoLifetimeMaximum";
export const PlanFieldCell: React.FC<{
  edit: boolean;
  field: PlanNumericField;
  onChange: (updated: UsePlanFieldsResult["planFields"]) => void;
  planFields: UsePlanFieldsResult["planFields"];
}> = ({ edit, field, onChange, planFields }) => {
  const f = field as keyof InsurancePlanVO;
  const val = planFields[f] as number;

  return (
    <EditCurrencyInCentsCell
      align="left"
      edit={edit}
      onChange={(newValue) => {
        onChange({ ...planFields, [f]: newValue ?? null });
      }}
      value={val}
    />
  );
};

type InsuranceField =
  | "annualDeductibleRemaining"
  | "annualMaximumRemaining"
  | "lifetimeDeductibleRemaining"
  | "lifetimeMaximumRemaining"
  | "orthoMaximumRemaining";

export const InsuranceFieldCell: React.FC<{
  edit: boolean;
  field: InsuranceField;
  insuranceFields: UseInsuranceFields["insuranceFields"];
  onChange: (updated: UseInsuranceFields["insuranceFields"]) => void;
  onSubmit?: () => Promise<unknown>;
  validation: InsuranceValidationResult;
}> = ({ edit, field, insuranceFields, onChange, onSubmit, validation }) => {
  const val = insuranceFields[field as keyof PatientInsuranceVO] as number;
  const error = validation[field].$error;

  return (
    <FloatingTooltip content={error}>
      <span>
        <EditCurrencyInCentsCell
          align="left"
          edit={edit}
          error={error}
          onChange={(newValue) => {
            onChange({
              ...insuranceFields,
              [field]: newValue ?? null,
            });
          }}
          onSubmit={onSubmit}
          value={val}
        />
      </span>
    </FloatingTooltip>
  );
};

type SubscriberNumericField = "familyAnnualDeductibleRemaining" | "familyAnnualMaximumRemaining";

export const SubscriberFieldCell: React.FC<{
  edit: boolean;
  field: SubscriberNumericField;
  subscriberFields: Partial<SubscriberFields>;
  onChange: (updated: Partial<SubscriberFields>) => void;
  onSubmit?: () => Promise<unknown>;
  validation: InsuranceValidationResult;
}> = ({ edit, field, onChange, onSubmit, subscriberFields, validation }) => {
  const val = subscriberFields[field as keyof SubscriberFields] as number | undefined;
  const error = validation[field].$error;

  return (
    <FloatingTooltip content={error}>
      <span>
        <EditCurrencyInCentsCell
          edit={edit}
          error={error}
          onChange={(newValue) => {
            onChange({ ...subscriberFields, [field]: newValue ?? null });
          }}
          onSubmit={onSubmit}
          value={val}
        />
      </span>
    </FloatingTooltip>
  );
};

export const BreakdownTable: React.FC<{
  children: React.ReactNode;
  colsClassName?: "grid-cols-2" | "grid-cols-3" | "grid-cols-4" | "grid-cols-7";
}> = ({ children, colsClassName = "grid-cols-4" }) => {
  return (
    <div
      className={cx(
        `grid
         border
         border-greyLighter
         border-b-0
         rounded
         min-w-0
         max-w-[600px]
         text-xs
         font-sans`,
        colsClassName
      )}
    >
      {children}
    </div>
  );
};

type OrthoAgeLimitProps = {
  editable: boolean;
  onChange: (value: number) => void;
  planFields: UsePlanFieldsResult["planFields"];
};

export const OrthoAgeLimit: React.FC<OrthoAgeLimitProps> = ({ editable, onChange, planFields }) => {
  return (
    <FormFieldNumberInput
      clamp
      inputClassName="px-3 py-2 rounded w-full"
      edit={editable}
      layout="tableValue"
      min={0}
      onValueChange={(_, { numberValue }) => onChange(numberValue)}
      value={planFields.orthoMaxAge}
    />
  );
};
