import { FC } from "react";
import { ProcedureShortcutCategoryVO, ProcedureShortcutVO } from "@libs/api/generated-api";
import { ReactComponent as Trash } from "@libs/assets/icons/delete.svg";
import { ProcedureShortcutsMode } from "components/Settings/ChartingSettings/types";

interface Props {
  selectedCategory: ProcedureShortcutVO["category"];
  categories: ProcedureShortcutCategoryVO[] | undefined;
  mode: ProcedureShortcutsMode;
  className?: string;
  onRemoveClick: Func;
}

export const ProcedureShortcutBodyHeader: FC<Props> = ({
  selectedCategory,
  categories,
  mode,
  onRemoveClick,
  className,
}) => {
  return (
    <div className={className}>
      <div className="flex justify-between items-center max-w-sm">
        <div className="font-sansSemiBold text-sm">
          {mode.type === "View"
            ? `${categories?.find((cat) => cat.category === selectedCategory)?.displayName || ""} Options`
            : mode.type === "Create"
              ? "Add Quick Select Option"
              : "Edit Details"}
        </div>

        {mode.type === "Edit" ? (
          <button
            type="button"
            className="text-xs flex items-center text-primaryTheme hover:text-red"
            onClick={onRemoveClick}
          >
            <Trash className="mr-1 w-4 h-4" /> Remove
          </button>
        ) : null}
      </div>
    </div>
  );
};
