import { FC } from "react";
import { useLocation } from "react-router-dom";

import { TwilioOnboardingStatusVO } from "@libs/api/generated-api";
import { Pill, PillProps } from "@libs/components/UI/Pill";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { getFullUrl } from "@libs/utils/location";

import { SettingsHeroDetails } from "components/Settings/SettingsHeroLayout";
import { MailToLink } from "components/UI/MailToLink";

import { paths } from "utils/routing/paths";

type StatusConfig = { label: string; description: string; theme: PillProps["theme"] };

const getStatusMap = (phoneNumber?: string): Record<TwilioOnboardingStatusVO["status"], StatusConfig> => ({
  PENDING: {
    label: "Inactive",
    description:
      "Enable messaging services so you can send and receive messages from patients right here in Archy.",
    theme: "grey",
  },
  IN_PROGRESS: {
    label: "Provisioning",
    description:
      "Your messaging service is being provisioned; this could take up to two weeks. We will notify you once it becomes active.",
    theme: "grey",
  },
  FAILURE: {
    label: "Error",
    description:
      "We seem to have run into an issue while setting up your messaging service. Please contact support so we can fix the issue.",
    theme: "red",
  },
  COMPLETE: phoneNumber
    ? {
        label: "Active",
        description:
          "Your messaging service is active. Patients will be able to message you directly through Archy using the number below.",
        theme: "green",
      }
    : {
        label: "Incomplete",
        description:
          "Your messaging service is active. However, you will need to select a number for this practice before it can be used.",
        theme: "grey",
      },
});

export const TwilioOnboardingStatus: FC<{
  status: TwilioOnboardingStatusVO["status"];
  phoneNumber?: string;
}> = ({ status, phoneNumber }) => {
  const { theme, label, description } = getStatusMap(phoneNumber)[status];
  const location = useLocation();

  return (
    <SettingsHeroDetails>
      <Pill theme={theme} data-testid="twilio-onboarding-status">
        {label}
      </Pill>
      <p>{description}</p>
      {status === "PENDING" ? (
        <ButtonInternalLink
          to={paths.setupTwilio({ step: "practice" }, { from: getFullUrl(location) })}
          theme="link"
        >
          Activate Service
        </ButtonInternalLink>
      ) : status === "FAILURE" ? (
        <MailToLink email="support@archy.com">Contact Support</MailToLink>
      ) : phoneNumber ? (
        <span className="font-sansSemiBold">{phoneNumber}</span>
      ) : (
        <ButtonInternalLink
          to={paths.setupTwilio({ step: "phone" }, { phoneOnly: true, from: getFullUrl(location) })}
          theme="link"
        >
          Select Number
        </ButtonInternalLink>
      )}
    </SettingsHeroDetails>
  );
};
