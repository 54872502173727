import { FC, useMemo } from "react";
import { AppointmentCardConfigVO, AppointmentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { noop } from "@libs/utils/noop";
import { getDarkColor, treatmentPlanCategoryOption, unitedBlueOption } from "@libs/domains/scheduling/colors";
import { AppointmentCard } from "components/ScheduleAppointments/AppointmentCard";
import {
  indicatorLabelMap,
  getBackgroundLabel,
} from "components/Settings/Scheduling/Sections/AppointmentCardDesign/utils";
import { ArrowLabel } from "./ArrowLabel";

interface Props {
  config: AppointmentCardConfigVO;
  currentUserName: string | undefined;
}

const INDICATOR_PREVIEW_COLOR_MAP = {
  STATUS: "#E07415",
  PROVIDER: unitedBlueOption.light,
  CATEGORY: treatmentPlanCategoryOption.value,
};

const getAppointmentPreview = (fullName?: string): AppointmentVO => {
  const [first, last] = fullName?.split(" ") ?? ["Dwayne Johnson"];

  return {
    id: 1,
    patient: {
      id: 1,
      shortDisplayName: `${first} ${last}`,
      fullDisplayName: `${first} "The Rock" ${last}`,
      firstName: first,
      lastName: last,
    },
    isSelfBooked: false,
    patientProcedures: [
      {
        id: 1,
        dentalProcedureId: 1,
        displayName: "Wisdom Tooth Removal",
        fullDisplayName: "Wisdom Tooth Removal",
        description: "Wisdom Tooth Removal",
        status: "SCHEDULED",
        duration: "/XX/",
        durationInMinutes: 20,
        isInsuranceBillable: true,
      },
    ],
    date: "1999-12-31",
    startTime: "10:00:00",
    endTime: "10:55:00",
    room: {
      id: 1,
      roomName: "Room 1",
      isSelfBookable: false,
    },
    negotiatedRate: 0,
    ucrRate: 0,
    state: "IN_PROGRESS",
    duration: 55,
    provider: {
      id: 1,
      status: "ACTIVE",
      jobCategory: "DENTIST",
      name: {
        id: 1,
        shortDisplayName: "",
        fullDisplayName: "",
        firstName: "",
        lastName: "",
      },
      color: INDICATOR_PREVIEW_COLOR_MAP.PROVIDER,
    },
    dentist: {
      id: 1,
      status: "ACTIVE",
      jobCategory: "DENTIST",
      name: {
        id: 1,
        shortDisplayName: "",
        fullDisplayName: "",
        firstName: "",
        lastName: "",
      },
      color: INDICATOR_PREVIEW_COLOR_MAP.PROVIDER,
    },
    tags: {
      patient: [
        {
          id: 1,
          name: "Premedication",

          glyph: {
            name: "Pill",
            value: "",
            family: "ARCHY",
          },
          isArchived: false,
          color: "",
        },
      ],
      customAppointment: [],
      autoAppointment: [],
    },
    sendConfirmationAndReminders: false,
    patientAmount: 0,
    deductibleAmount: 0,
    asap: false,
  };
};

const providerDark = getDarkColor(INDICATOR_PREVIEW_COLOR_MAP.PROVIDER);

export const AppointmentCardPreview: FC<Props> = ({ config, currentUserName }) => {
  const bottomLeftIndicatorArrow = useMemo(() => {
    const indicatorIsProvider = config.background !== "PROVIDER";

    return config.background
      ? {
          label:
            indicatorLabelMap[
              indicatorIsProvider ? (config.showDentistColor ? "DENTIST" : "HYGIENIST") : "CATEGORY"
            ],
          color: indicatorIsProvider ? providerDark : INDICATOR_PREVIEW_COLOR_MAP.CATEGORY,
        }
      : undefined;
  }, [config.background, config.showDentistColor]);

  const backgroundArrow = useMemo(() => {
    const { background, indicators } = config;

    return {
      label: getBackgroundLabel({ ...config, isPreview: true }),
      color: background
        ? background === "PROVIDER"
          ? providerDark
          : INDICATOR_PREVIEW_COLOR_MAP.CATEGORY
        : indicators[0]
          ? indicators[0] === "PROVIDER"
            ? providerDark
            : INDICATOR_PREVIEW_COLOR_MAP.CATEGORY
          : INDICATOR_PREVIEW_COLOR_MAP.STATUS,
    };
  }, [config]);

  return (
    <div className="flex">
      <div className="flex flex-col items-end w-28">
        <ArrowLabel
          className={cx(bottomLeftIndicatorArrow ? "translate-y-1/4" : "translate-y-full")}
          direction="left"
          label={indicatorLabelMap.STATUS}
          color={INDICATOR_PREVIEW_COLOR_MAP.STATUS}
        />

        {bottomLeftIndicatorArrow && (
          <ArrowLabel
            className="translate-y-3/4"
            direction="left"
            label={bottomLeftIndicatorArrow.label}
            color={bottomLeftIndicatorArrow.color}
          />
        )}
      </div>

      <div className="w-60 h-16 pointer-events-none">
        <AppointmentCard
          hideActions
          appointment={getAppointmentPreview(currentUserName)}
          config={config}
          onAppointmentDeleted={noop}
          onRequestEditAppointment={noop}
          onRequestUpdateAppointmentState={noop}
          selectedCard={0}
        />
      </div>

      <div className="relative">
        <ArrowLabel
          className="translate-y-full"
          direction="right"
          label={backgroundArrow.label}
          color={backgroundArrow.color}
        />
      </div>
    </div>
  );
};
