import { FC, useMemo } from "react";
import { PerioChartToothConfigVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";

import { PerioChartHalfTooth } from "./PerioChartHalfTooth";
import { PerioChartToothSurfaceType, PerioChartSequenceType } from "./PerioTypes";
import { usePerioChart } from "./PerioChartContext";
import { sequenceToShortTitle, sequenceToVisibility } from "./perioChartUtils";
import { usePerioChartActiveTooth } from "./PerioChartActiveToothContext";

const LAST_TOOTH_INDEX = 15;

export const cxStyles = {
  toothRow: "flex flex-row ml-4",
  rowTitleContainer: `
    min-w-[70px]
    text-[11px]
    leading-4
    text-grayMedium
    text-right
  `,
  rowTitle: "mb-0.5",
  placeholder: "w-[54px]",
};

const PerioChartToothNumberDivider: React.FC<{ teethConfigs: PerioChartToothConfigVO[] }> = ({
  teethConfigs,
}) => {
  const { toothNum: currentToothNum } = usePerioChartActiveTooth();

  return (
    <>
      <div className="h-px w-full bg-greyLight mt-2" />
      <div className="flex flex-row">
        {teethConfigs.map((toothConfig, index) => (
          <div
            key={toothConfig.toothNum}
            className={cx(
              `text-center
               mt-0.5
               mb-0.5
               text-sm
               border
               text-greyDark
               w-[54px]
               flex
               rounded-md
               items-center
               justify-center`,
              index === 0 && "mr-2",
              index !== 0 && index !== LAST_TOOTH_INDEX && "ml-[9px] mr-2",
              index === LAST_TOOTH_INDEX && "ml-[9px]",
              toothConfig.toothNum === currentToothNum
                ? " border-primaryTheme bg-archyBlue-50"
                : "border-transparent"
            )}
          >
            <div
              className={cx(
                toothConfig.toothNum === currentToothNum &&
                  `
                flex
                items-center
                justify-center
                box-border
                text-nowrap
              `
              )}
            >
              {`${toothConfig.label}${toothConfig.implant ? "i" : ""}`}
            </div>
          </div>
        ))}
      </div>
      <div className="h-px w-full bg-greyLight mb-2" />
    </>
  );
};

export const PerioChartTeeth: FC<{
  facialSequences: PerioChartSequenceType[];
  lingualSequences: PerioChartSequenceType[];
  isTopTeeth: boolean;
}> = ({ facialSequences, lingualSequences, isTopTeeth }) => {
  const { settings, currentExam } = usePerioChart();
  const visibleFacialSequences =
    useMemo(
      () => settings && facialSequences.filter((sequence) => settings[sequenceToVisibility[sequence]]),
      [facialSequences, settings]
    ) || [];

  const visibleLingualSequences =
    useMemo(
      () => settings && lingualSequences.filter((sequence) => settings[sequenceToVisibility[sequence]]),
      [lingualSequences, settings]
    ) || [];

  const teethConfigs = useMemo(() => {
    const examInfo = currentExam?.getExamInfo();

    if (!examInfo) {
      return [];
    }

    const lower = [...examInfo.lowerToothConfig].reverse();

    return isTopTeeth ? examInfo.upperToothConfig : lower;
  }, [currentExam, isTopTeeth]);

  const currentUuid = currentExam?.getExamInfo().uuid || "";

  return (
    <div className={cx("flex", isTopTeeth ? "flex-col" : "flex-col-reverse")}>
      <div className="flex flex-row">
        <div className={cxStyles.rowTitleContainer}>
          {visibleFacialSequences.map((sequence) => (
            <div key={sequence} className={cxStyles.rowTitle}>
              {sequenceToShortTitle[sequence]}
            </div>
          ))}
        </div>
        <div className={cxStyles.toothRow}>
          {teethConfigs.map((toothConfig, index) => {
            return (
              <div className="flex" key={`${currentUuid}-${toothConfig.toothNum}`}>
                {index !== 0 && <VerticalDivider className="mx-2" />}
                {toothConfig.missing ? (
                  <div className={cxStyles.placeholder} />
                ) : (
                  <PerioChartHalfTooth
                    isTopTeeth={isTopTeeth}
                    toothConfig={toothConfig}
                    perioSequenceTypes={visibleFacialSequences}
                    surface={PerioChartToothSurfaceType.FACIAL}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
      <div className="flex flex-row">
        <div
          className={cx(
            `min-w-[70px]
             text-sm
             text-grayDark
             text-right
             font-sansSemiBold
             flex
             justify-center`,
            isTopTeeth ? "flex-col" : "flex-col-reverse"
          )}
        >
          <div>Facial</div>
          <div>{isTopTeeth ? "Palatal" : "Lingual"}</div>
        </div>
        <div className="ml-4">
          <PerioChartToothNumberDivider teethConfigs={teethConfigs} />
        </div>
      </div>
      <div className="flex flex-row">
        <div className={cxStyles.rowTitleContainer}>
          {visibleLingualSequences.map((sequence) => (
            <div key={sequence} className={cxStyles.rowTitle}>
              {sequenceToShortTitle[sequence]}
            </div>
          ))}
        </div>
        <div className={cxStyles.toothRow}>
          {teethConfigs.map((toothConfig, index) => {
            return (
              <div className="flex" key={`${currentUuid}-${toothConfig.toothNum}`}>
                {index !== 0 && <VerticalDivider className="mx-2" />}
                {toothConfig.missing ? (
                  <div className={cxStyles.placeholder} />
                ) : (
                  <PerioChartHalfTooth
                    isTopTeeth={isTopTeeth}
                    toothConfig={toothConfig}
                    perioSequenceTypes={visibleLingualSequences}
                    surface={PerioChartToothSurfaceType.LINGUAL}
                  />
                )}
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
};
