import { AdjustmentPieDatum } from "components/Dashboard/Adjustments/types";
import { labelForAdjustmentCategory } from "components/Dashboard/Adjustments/utils";
import { DonutTooltip } from "components/Dashboard/Charting/DonutTooltip";

type Props = {
  datum: AdjustmentPieDatum;
  percent: number;
};

export const AdjustmentsDonutTooltip: React.FC<Props> = ({ percent, datum }) => {
  return (
    <DonutTooltip
      percent={percent}
      value={datum.actualValue}
      label={`${labelForAdjustmentCategory(datum.adjustmentCategory, { concise: true })} - ${datum.label}`}
      isCurrency
      color={datum.color}
    />
  );
};
