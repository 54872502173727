import { FC } from "react";
import { ButtonInternalLink, ButtonLink } from "@libs/components/UI/ButtonLink";
import { Pill, PillProps } from "@libs/components/UI/Pill";
import { FinixMerchantIdentityVO } from "@libs/api/generated-api";
import { externalLinks } from "components/UI/ExternalLink";
import { paths } from "utils/routing/paths";
import { SettingsHeroDescription, SettingsHeroDetails } from "components/Settings/SettingsHeroLayout";

type StateConfig = { label: string; description: string; theme: PillProps["theme"] };

const provisioningValues = {
  label: "Provisioning",
  description: "We have received your information and working to get your billing merchant account setup.",
  theme: "blue" as const,
};

const onboardingStates: Record<
  NonNullable<FinixMerchantIdentityVO["onboardingState"]> | "FORM_COMPLETED",
  StateConfig
> = {
  PROVISIONING: provisioningValues,
  FORM_COMPLETED: provisioningValues,
  REJECTED: {
    label: "Failed",
    description:
      "There seems to have been an issue setting up your billing merchant account. Please contact Archy support to rectify the issue.",
    theme: "red",
  },
  APPROVED: {
    label: "Active",
    description:
      "Payment processing is active. Access the Dashboard to manage your payment processing services.",
    theme: "green",
  },
};

const Incomplete: StateConfig = {
  label: "Incomplete",
  description:
    "Enter all bank account and payment information so that you can accept payments directly through Archy. Once this setup is complete, Archy will mail you a credit card machine.",
  theme: "greyMedium",
};

export const FinixOnboardingStatus: FC<{ onboardingState: FinixMerchantIdentityVO["onboardingState"] }> = ({
  onboardingState,
}) => {
  const config = onboardingState ? onboardingStates[onboardingState] : Incomplete;

  return (
    <SettingsHeroDetails>
      <Pill theme={config.theme}>{config.label}</Pill>
      <SettingsHeroDescription>{config.description}</SettingsHeroDescription>

      {onboardingState === "APPROVED" ? (
        <ButtonLink href={externalLinks.finixDashboard} target="_blank" className="text-xs" theme="link">
          Dashboard
        </ButtonLink>
      ) : onboardingState ? null : (
        <ButtonInternalLink to={paths.setupFinix()} className="text-xs" theme="link">
          Activate Billing Merchant
        </ButtonInternalLink>
      )}
    </SettingsHeroDetails>
  );
};
