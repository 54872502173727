import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { ServerErrors, State, UpdateState } from "components/Settings/ClaimsSettings/types";

import { FormFieldNumericInput } from "components/UI/FormFieldNumericInput";

export const DentalXChangeSettings: React.FC<{
  isEditing: boolean;
  hasDentalXChangePassword: boolean;
  serverErrors: ServerErrors;
  state: State;
  onUpdateState: UpdateState;
}> = ({ isEditing, hasDentalXChangePassword, serverErrors, state, onUpdateState }) => {
  const passwordPlaceholder = hasDentalXChangePassword ? "*************" : "";

  return (
    <div>
      <div className="text-sm font-sansSemiBold mb-4">DentalXChange Details</div>
      <div className="grid grid-cols-2 gap-x-6 gap-y-6">
        <FormFieldInput
          label="DentalXChange Username"
          layout="labelOut"
          autoComplete="false"
          edit={isEditing}
          value={state.dentalXChangeUsername}
          placeholder="Enter username set for your DentalXChange account"
          onChange={(e) => onUpdateState("dentalXChangeUsername", e.target.value)}
          error={serverErrors.dentalXChangeUsername}
        />
        <FormFieldInput
          label="DentalXChange Password"
          layout="labelOut"
          type="password"
          autoComplete="new-password"
          edit={isEditing}
          placeholder={passwordPlaceholder || "Enter password set for your DentalXChange account"}
          value={state.dentalXChangePassword || (!isEditing && passwordPlaceholder) || undefined}
          onChange={(e) => onUpdateState("dentalXChangePassword", e.target.value)}
          error={serverErrors.dentalXChangePassword}
        />
        <FormFieldNumericInput
          label="DentalXChange Account #"
          layout="labelOut"
          edit={isEditing}
          maxLength={10}
          value={state.accountNumber?.toString()}
          onValueChange={(value) => onUpdateState("accountNumber", value ? Number(value) : null)}
          error={serverErrors.accountNumber}
        />
      </div>
    </div>
  );
};
