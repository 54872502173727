import { AlertSettingsTagVO } from "@libs/api/generated-api";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { FC } from "react";
import { TooltipLabel } from "components/UI/TooltipLabel";
import { PillTag } from "components/Notes/PillTag";

interface Props {
  selectedTags: Set<number>;
  tagOptions: AlertSettingsTagVO[];
  onUpdate: (newSet: Set<number>) => void;
}

const tooltips: Record<NonNullable<AlertSettingsTagVO["tag"]["slug"]>, string> = {
  ALLERGY:
    "Created when medical history is updated with allergies specified. Previous alerts will need to be manually switched off.",
  BIRTHDAY: "If the patients birthday is within 1 week of the appointment time, this tag will appear.",
  FAMILY_MEMBER_SAME_DAY:
    "If the patient has a family member with an appointment on the same day, this tag will appear.",
  XRAY: "If the appointment includes an x-ray procedure code, this tag will appear.",
  NEW_PATIENT: "If it is the patients first appointment, this tag will appear.",
  PRE_MEDICATION_REQUIRED:
    "Created when medical history is updated with premedication specified. Previous alerts will need to be manually switched off.",
  REPEAT_NO_SHOW: "Created when the two appointments have been marked as no show",
  MEDICAL_CONDITION:
    "Created when medical history is updated with conditions specified. Previous alerts will need to be manually switched off.",
  PREGNANT:
    "Created when medical history is updated with pregnancy specified. Previous alerts will need to be manually switched off.",
  SELF_SCHEDULED_APPOINTMENT: "If the appointment was booked online, this tag will appear.",
  CLINICAL: "",
  OVERDUE_INVOICE: "Created when the patient has any overdue balance.",
  RECALL_OVERDUE: "Created when the patient is overdue for a prophy or perio appointment.",
};

export const AlertCheckboxList: FC<Props> = ({ selectedTags, tagOptions, onUpdate }) => {
  const options = tagOptions.map((tagOption) => ({
    label: (
      <TooltipLabel tooltip={{ content: tagOption.tag.slug ? tooltips[tagOption.tag.slug] : "" }}>
        <PillTag tag={tagOption.tag} />
      </TooltipLabel>
    ),
    value: tagOption.tag.id,
  }));

  return (
    <CheckboxList
      layout="custom"
      optionListClassName="columns-2 space-y-4"
      verticalLayout="normal"
      options={options}
      onChange={onUpdate}
      selectedValues={selectedTags}
    />
  );
};
