import { FC } from "react";

import { Button } from "@libs/components/UI/Button";

import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";

interface Props {
  onClose: Func;
}

export const LoadingPhotoModalContent: FC<Props> = ({ onClose }) => {
  return (
    <>
      <ModalContent>
        <div className="h-[23.5rem]">
          <LoadingContent className="rounded-none" />
        </div>
      </ModalContent>
      <ModalFooter>
        <Button className="min-w-button" onClick={onClose} theme="secondary">
          Cancel
        </Button>
        <Button className="min-w-button" inactive>
          Save
        </Button>
      </ModalFooter>
    </>
  );
};
