import { FC, PropsWithChildren, useCallback } from "react";
import { createLinksContext } from "contexts/AppLinksContext";
import { paths, routesConfig } from "utils/routing/paths";
import {
  OnPatientAppointmentChange,
  usePatientAppointmentListener,
  PathsWithIdReferencesConfig,
  cleanAppLinks,
  getNewUrl,
} from "contexts/PatientAppointmentContext";

const defaultLinks = {
  dailyHuddle: paths.huddle(),
  schedule: paths.schedule(),
  requests: paths.scheduleRequestsList(),
  asapList: paths.scheduleAsapList(),
};

/*
 *  This is an exhaustive configuration of all the store schedule links. See MainLinksContext
 *  for more on this data structure.
 */
const pathsWithIdReferences: PathsWithIdReferencesConfig<keyof typeof defaultLinks> = {
  dailyHuddle: [
    {
      pattern: routesConfig.huddle.path,
      getNewUrl,
    },
  ],
  schedule: [{ pattern: routesConfig.schedule.path, getNewUrl }],
  requests: [{ pattern: routesConfig.scheduleRequestsList.path, getNewUrl }],
  asapList: [{ pattern: routesConfig.scheduleAsapList.path, getNewUrl }],
};

const { useLinks, LinksProvider, AppHistoryProvider } = createLinksContext("Schedule", defaultLinks);

export const useScheduleLinks = useLinks;

const SchedulePatientAppointmentListener: FC<PropsWithChildren> = ({ children }) => {
  const { linksRef, updateLinks } = useLinks();

  const handlePatientAppointmentChange: OnPatientAppointmentChange = useCallback(
    ({ patientId, appointmentId, lastPatientId }) => {
      // clean up schedule links when patient appointment changes
      const updates = cleanAppLinks({
        patientId,
        appointmentId,
        linksRef,
        config: pathsWithIdReferences,
        lastPatientId,
      });

      if (updates) {
        updateLinks(updates);
      }
    },
    [linksRef, updateLinks]
  );

  usePatientAppointmentListener(handlePatientAppointmentChange);

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const ScheduleLinksProvider = ({ children, ...props }: Parameters<typeof LinksProvider>[0]) => {
  return (
    <LinksProvider {...props}>
      <SchedulePatientAppointmentListener>{children}</SchedulePatientAppointmentListener>
    </LinksProvider>
  );
};

export const ScheduleAppHistoryProvider = AppHistoryProvider;
