import { FC, useState } from "react";
import { produce } from "immer";
import {
  CreateAppointmentAdjustmentRequest,
  CreateProcedureAdjustmentRequest,
  CustomAdjustmentTypeVO,
  InvoiceEntryVO,
} from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { centsToDollars, dollarsToCents, formatCurrency } from "@libs/utils/currency";
import { useValidation } from "@libs/hooks/useValidation";
import { greaterThan, lessOrEqualTo, required } from "@libs/utils/validators";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Button } from "@libs/components/UI/Button";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { RadioList } from "@libs/components/UI/RadioList";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { Row, TextCell } from "@libs/components/UI/GridTableComponents";

import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";
import { SymbolSwitch } from "components/UI/SymbolSwitch";
import { FormFieldSelectAdjustments } from "components/PatientProfile/Billing/FormFieldSelectAdjustments";
import { SkeletonInputField } from "components/UI/SkeletonInputField";
import {
  cxStylesInvoiceBanner,
  invoiceEntryOrLineItemToId,
  getMaximumDiscounts,
} from "components/PatientProfile/Billing/invoiceUtils";
import { updateAdjustment } from "components/PatientProfile/Billing/updateAdjustment";
import { RoleGuardClick } from "components/Main/RoleGuard";
import { PermissionAction } from "components/Roles/constants";

export type AdjustmentRequestWithDisplayValue = CreateAppointmentAdjustmentRequest & {
  displayValue?: string;
  percentOrDollar?: "dollar" | "percent";
  adjustmentType?: CustomAdjustmentTypeVO["entryType"];
  applyAdjustmentTo?: "APPOINTMENT" | "PROCEDURE";
  randomId?: string;
  defaultProcedures?: { procedureId: number }[];
};

export const InvoiceAdjustmentFormRow: FC<{
  proposedEntry: InvoiceEntryVO;
  appointmentId: CreateAppointmentAdjustmentRequest["appointmentId"];
  practiceAdjustments?: CustomAdjustmentTypeVO[];
  maxDiscountCents: ReturnType<typeof getMaximumDiscounts>;
  onApply: (adjustment: AdjustmentRequestWithDisplayValue) => void;
  onCancel: Func;
  // eslint-disable-next-line complexity
}> = ({ appointmentId, proposedEntry, practiceAdjustments, maxDiscountCents, onApply, onCancel }) => {
  const [draftAdjustment, setDraftAdjustment] = useState<AdjustmentRequestWithDisplayValue>(() => ({
    appointmentId,
    accountingType: "COLLECTION",
    customAdjustmentTypeId: 0,
    displayValue: "",
    adjustmentType: "DEBIT",
    percentOrDollar: "dollar",
    applyAdjustmentTo: "APPOINTMENT",
    randomId: crypto.randomUUID(),
    defaultProcedures: proposedEntry.lineItems.map((lineItem) => ({
      procedureId: Number(lineItem.lineItemReference.id),
    })),
  }));

  const applyButtonGuardAction: PermissionAction =
    draftAdjustment.adjustmentType === "CREDIT" ? "ADD_ADJUSTMENT_DISCOUNT" : "ADD_ADJUSTMENT_CHARGE";

  const validation = useValidation(draftAdjustment, {
    customAdjustmentTypeId: {
      $validations: [{ $v: greaterThan(0), $error: "Adjustment type is required" }],
    },
    displayValue: {
      $validations: [
        {
          $v: required,
          $error:
            draftAdjustment.percentOrDollar === "dollar" ? "Amount is required" : "Percentage is required",
        },
        {
          $v: (value) => greaterThan(0)(Number(value)),
          $error:
            draftAdjustment.percentOrDollar === "dollar"
              ? "Amount must be greater than $0"
              : "Percentage must be greater than 0%",
        },
        // Validators when using dollar amount
        {
          $ignore:
            draftAdjustment.percentOrDollar !== "dollar" || draftAdjustment.adjustmentType !== "CREDIT",
          $v: (value: unknown) => lessOrEqualTo(centsToDollars(maxDiscountCents.$entryTotal))(Number(value)),
          $error: `Amount cannot be greater than $${centsToDollars(maxDiscountCents.$entryTotal)}`,
        },
        // Validators when using percentage
        {
          $ignore: draftAdjustment.percentOrDollar !== "percent",
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          $v: (value) => lessOrEqualTo(100)(Number(value)),
          $error: "Percentage cannot be greater than 100%",
        },
      ],
    },
    procedureAdjustments: {
      $validate: true,
      $ignore:
        draftAdjustment.applyAdjustmentTo !== "PROCEDURE" || draftAdjustment.percentOrDollar !== "dollar",
      $validations: [
        {
          $v: (procAdjusts: unknown) => {
            // This validator only applies to discounts. It ensures that
            // discounts cannot be greater than the procedure values they are
            // applied to.
            if (draftAdjustment.adjustmentType === "DEBIT") {
              return true;
            }

            for (const procAdjust of procAdjusts as CreateProcedureAdjustmentRequest[]) {
              if ((procAdjust.adjustmentAmount ?? 0) > maxDiscountCents[procAdjust.procedureId]) {
                return false;
              }
            }

            return true;
          },
          $error: "Discount value cannot be greater than procedure value",
        },
        {
          $v: (procAdjusts: unknown) =>
            (procAdjusts as CreateProcedureAdjustmentRequest[]).reduce(
              (prev, curr) => prev + (curr.adjustmentAmount ?? 0),
              0
            ) === dollarsToCents(Number(draftAdjustment.displayValue)),
          $error: "Distributed amounts must match total adjustment",
        },
      ],
    },
  });

  const handleApply = () => {
    const result = validation.validate();

    if (!result.$isValid) {
      return;
    }

    // eslint-disable-next-line complexity
    const adjustment = produce(draftAdjustment, (draft) => {
      if (draft.adjustmentType === "CREDIT") {
        negateAdjustmentAmounts(draft);
      }

      // Generate a display value so we use that as the label after it's
      // created. This value isn't required by the backend, it's only for UI
      // purposes. Pass-in the percentage value as it's always the value seen by
      // the user and avoids dealing with cent<>dollar conversions.
      draft.displayValue = finalizeDisplayValue(draft);
    });

    onApply(adjustment);
  };

  const [selectedProcedures, setSelectedProcedures] = useState<Set<number>>(() => new Set());
  const procedureCheckboxOptions =
    draftAdjustment.percentOrDollar === "percent"
      ? proposedEntry.lineItems.map((lineItem, i) => ({
          label: lineItem.patientProcedureSnapshot?.cdtCode,
          // use the index as the value because that's how we key the procedures
          // off of the array.
          value: i,
        }))
      : [];

  return (
    <Row highlightOnHover={false}>
      {/* Date (dummy) */}
      <div />
      <TextCell
        className={cx(
          `col-start-2
           col-span-full
           my-1
           mr-5
           grid
           gap-x-3
           gap-y-1
           items-center`,
          draftAdjustment.applyAdjustmentTo === "PROCEDURE"
            ? "grid-cols-[auto_auto_auto_auto_1fr] grid-rows-[min-content_min-content_max-content]"
            : "grid-cols-[auto_auto_auto_1fr]",
          cxStylesInvoiceBanner.bannerStart,
          cxStylesInvoiceBanner.bannerSection,
          cxStylesInvoiceBanner.bannerEnd
        )}
      >
        {practiceAdjustments ? (
          <>
            <FormFieldSelectAdjustments
              className="w-48"
              showArchived="NO"
              adjustments={practiceAdjustments}
              value={draftAdjustment.customAdjustmentTypeId}
              required={true}
              onChange={(option) =>
                option?.value &&
                setDraftAdjustment((last) => ({
                  ...last,
                  customAdjustmentTypeId: option.value,
                  adjustmentType: option.type,
                }))
              }
            />
            {!validation.result.customAdjustmentTypeId.$isValid && (
              <FormFieldError className="row-start-2 col-start-1 self-start">
                {validation.result.customAdjustmentTypeId.$error}
              </FormFieldError>
            )}
          </>
        ) : (
          <SkeletonInputField />
        )}
        <FormFieldNumberInput
          value={draftAdjustment.displayValue}
          step={0.01}
          className="w-48"
          label={`Adjustment ${draftAdjustment.percentOrDollar === "dollar" ? "Amount" : "Percentage"}`}
          required
          onValueChange={(_, { stringValue }) => {
            setDraftAdjustment((last) =>
              produce(last, (draft) => {
                updateAdjustment(draft, { command: "UPDATE_ADJUSTMENT_VALUE", adjustmentValue: stringValue });
              })
            );
          }}
        >
          <div className="absolute right-2 -top-1.5">
            <SymbolSwitch
              size="xs"
              options={[
                { label: "Amount", value: "dollar", symbol: "$" },
                { label: "Percentage", value: "percent", symbol: "%" },
              ]}
              onChange={(_, option) => {
                setSelectedProcedures(new Set());
                setDraftAdjustment((last) =>
                  produce(last, (draft) => {
                    updateAdjustment(
                      draft,
                      option.value === "dollar"
                        ? { command: "SWITCH_TO_AMOUNT" }
                        : { command: "SWITCH_TO_PERCENTAGE" }
                    );
                  })
                );
              }}
              selectedValue={draftAdjustment.percentOrDollar}
            />
          </div>
        </FormFieldNumberInput>
        {!validation.result.displayValue.$isValid && (
          <FormFieldError className="row-start-2 col-start-2 self-start">
            {validation.result.displayValue.$error}
          </FormFieldError>
        )}

        <RadioList
          layout="vert"
          verticalLayout="slim"
          onChange={(_e, option) => {
            setSelectedProcedures(new Set());
            setDraftAdjustment((last) =>
              produce(last, (draft) => {
                updateAdjustment(
                  draft,
                  option.value === "APPOINTMENT"
                    ? { command: "APPLY_TO_APPOINTMENT" }
                    : { command: "APPLY_TO_PROCEDURE" }
                );
              })
            );
          }}
          selectedValue={draftAdjustment.applyAdjustmentTo}
          options={[
            { label: "Apply to Appt", value: "APPOINTMENT" },
            { label: "Apply to Procedure", value: "PROCEDURE" },
          ]}
        />
        {draftAdjustment.applyAdjustmentTo === "PROCEDURE" && (
          <div
            className={cx(
              `flex
               flex-col
               bg-white
               border
               rounded
               px-4
               py-2
               gap-y-2
               self-start
               row-span-3`,
              draftAdjustment.percentOrDollar === "dollar" && "w-32"
            )}
          >
            {draftAdjustment.percentOrDollar === "dollar" ? (
              proposedEntry.lineItems.map((lineItem, i) => (
                <FormFieldNumberInput
                  key={invoiceEntryOrLineItemToId(lineItem)}
                  step={0.01}
                  layout="labelLeft"
                  className="w-24"
                  inputClassName="text-right"
                  label={lineItem.patientProcedureSnapshot?.cdtCode}
                  value={centsToDollars(draftAdjustment.procedureAdjustments?.[i].adjustmentAmount ?? 0)}
                  onValueChange={(_, { stringValue }) => {
                    setDraftAdjustment((last) =>
                      produce(last, (draft) => {
                        updateAdjustment(draft, {
                          command: "UPDATE_PROCEDURE_AMOUNT",
                          dollarValue: stringValue,
                          procedureIndex: i,
                        });
                      })
                    );
                  }}
                />
              ))
            ) : (
              <CheckboxList
                verticalLayout="normal"
                layout="vert"
                selectedValues={selectedProcedures}
                options={procedureCheckboxOptions}
                onChange={(updatedSet, _, option) => {
                  setSelectedProcedures(updatedSet);
                  setDraftAdjustment((last) =>
                    produce(last, (draft) => {
                      updateAdjustment(draft, {
                        command: updatedSet.has(option.value)
                          ? "CHECK_PROCEDURE_PERCENTAGE"
                          : "UNCHECK_PROCEDURE_PERCENTAGE",
                        procedureIndex: option.value,
                      });
                    })
                  );
                }}
              />
            )}
            {validation.result.procedureAdjustments.$error && (
              <FormFieldError>{validation.result.procedureAdjustments.$error}</FormFieldError>
            )}
          </div>
        )}
        <div className="flex gap-x-3 justify-self-end">
          <RoleGuardClick domain="BILLING" action={applyButtonGuardAction}>
            <Button size="small" onClick={handleApply} disabled={!(validation.result.$isValid ?? true)}>
              Apply
            </Button>
          </RoleGuardClick>
          <ButtonIcon SvgIcon={CancelIcon} size="sm" onClick={onCancel} />
        </div>
      </TextCell>
    </Row>
  );
};

const negateAdjustmentAmounts = (adjustment: AdjustmentRequestWithDisplayValue) => {
  // Only adjustment dollar amounts can have a negative values, adjustment
  // percentages don't.
  if (adjustment.adjustmentAmount) {
    adjustment.adjustmentAmount = -adjustment.adjustmentAmount;
  }

  adjustment.procedureAdjustments?.forEach((proc) => {
    if (proc.adjustmentAmount) {
      proc.adjustmentAmount = -proc.adjustmentAmount;
    }
  });
};

const finalizeDisplayValue = (adjustment: AdjustmentRequestWithDisplayValue) => {
  if (adjustment.percentOrDollar === "dollar") {
    return formatCurrency(dollarsToCents(Number(adjustment.displayValue)));
  }

  return `${adjustment.displayValue ?? "0"}%`;
};
