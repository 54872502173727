import React, { ReactNode, useCallback } from "react";
import { useNavigate, BlockerFunction, NavigationType } from "react-router-dom";
import { isDefined } from "@libs/utils/types";
import { getFullUrl } from "@libs/utils/location";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { ModalFooterButtons } from "@libs/components/UI/ModalComponents";
import { EventSavedModal } from "components/ScheduleAppointments/EventSavedModal";
import { ModalPage } from "components/UI/ModalPage";

interface EventModalPageProps {
  backUrl: string;
  hasSavedEvent: boolean;
  eventUrl: string;
  isSaving: boolean;
  isDirty: boolean;
  savedValue: unknown;
  isFormValid: boolean;
  formId: string;
  title: ReactNode;
  savedMessage: string;
  promptMessage: string;
  shouldUnblock?: () => boolean;
  children?: ReactNode;
  isUnscheduled?: boolean;
  isRequested?: boolean;
  isDeleting?: boolean;
  onDelete?: Func;
}

export const EventModalPage: React.FC<EventModalPageProps> = ({
  backUrl,
  hasSavedEvent,
  eventUrl,
  isSaving,
  children,
  isFormValid,
  formId,
  title,
  savedMessage,
  promptMessage,
  shouldUnblock,
  isDirty,
  savedValue,
  isUnscheduled = false,
  isRequested = false,
  isDeleting = false,
  onDelete,
}) => {
  const navigate = useNavigate();

  const handlePrompt: BlockerFunction = useCallback(
    (args) => {
      const { currentLocation, nextLocation, historyAction } = args;

      if (historyAction === NavigationType.Replace && currentLocation.pathname === nextLocation.pathname) {
        return false;
      }

      if (!isDirty || isDefined(savedValue)) {
        return false;
      }

      if (shouldUnblock?.()) {
        return false;
      }

      return getFullUrl(currentLocation) !== getFullUrl(nextLocation);
    },
    [isDirty, savedValue, shouldUnblock]
  );

  return (
    <ModalPage
      promptMessage={promptMessage}
      shouldBlock={handlePrompt}
      closeLink={backUrl}
      title={title}
      actions={
        hasSavedEvent ? null : (
          <ModalFooterButtons>
            {onDelete && (
              <AsyncButton
                type="button"
                className="w-24 text-sm"
                form={formId}
                theme="link"
                isLoading={isDeleting}
                onClick={onDelete}
              >
                Delete
              </AsyncButton>
            )}
            <ButtonInternalLink className="w-24" replace theme="secondary" to={backUrl}>
              Cancel
            </ButtonInternalLink>
            <FloatingTooltip content={isFormValid ? "" : "Please complete the form."} theme="MEDIUM">
              <AsyncButton
                type="submit"
                className="w-24"
                form={formId}
                theme="primary"
                disabled={!isFormValid}
                isLoading={isSaving}
              >
                {isRequested ? "Accept" : "Save"}
              </AsyncButton>
            </FloatingTooltip>
          </ModalFooterButtons>
        )
      }
    >
      {hasSavedEvent ? (
        <EventSavedModal
          onStay={() => navigate(backUrl, { replace: true })}
          onViewSchedule={() => navigate(eventUrl, { replace: true })}
          isUnscheduled={isUnscheduled}
          message={savedMessage}
        />
      ) : (
        children
      )}
    </ModalPage>
  );
};
