import { PracticeVO } from "@libs/api/generated-api";
import { required } from "@libs/utils/validators";
import { addressSchema } from "@libs/utils/address";

export const getBusinessInformationSchema = (
  fields: Pick<PracticeVO, "isBillingAddressSame" | "taxIdLastFour">
) => {
  return {
    name: [{ $v: required, $error: "Business name is required" }],
    taxId: [{ $v: required, $error: "Tax ID is required", $ignore: Boolean(fields.taxIdLastFour) }],
    physicalAddress: addressSchema,
    billingAddress: {
      ...addressSchema,
      $ignore: fields.isBillingAddressSame,
    },
  };
};
