import React, { FormEvent, useMemo } from "react";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { Form } from "@libs/components/UI/Form";
import { getCognitoErrorMessage } from "utils/auth";
import { ForgotPasswordInputType, ForgotPasswordProps } from "components/SignIn/ForgotPassword";
import { ContentFooter } from "components/SignIn/ContentFooter";
import { FormFieldPassword } from "components/UI/FormFieldPassword";
import { cxFormStyles } from "components/SignIn/SignInForm";
import { ResetPasswordValidationResults, SignInErrors } from "components/SignIn/validationUtil";
import { OTPInput } from "components/UI/OTPInput";
import { MailToLink } from "components/UI/MailToLink";
import { PasswordGuidelines } from "components/SignIn/AuthChallenges/NewPasswordChallenge";

interface Props {
  handleForgotPassword: (
    e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => Promise<void>;
  handleInputChange: (inputName: ForgotPasswordInputType, value: string) => void;
  handleResetPassword: (e: FormEvent<HTMLFormElement>) => Promise<void>;
  forgotPasswordState: ForgotPasswordProps;
  validateResult: ResetPasswordValidationResults;
}

export const OTP_LENGTH = 6;

const ResendCode = ({ handleForgotPassword }: { handleForgotPassword: Props["handleForgotPassword"] }) => {
  return (
    <Button className="text-sm" onClick={(e) => handleForgotPassword(e)} theme="link">
      Resend Code
    </Button>
  );
};

const ErrorMessage = ({ errorMessage }: { errorMessage: string }) => {
  return (
    <div className="flex mt-2 text-red text-xs">
      <ErrorIcon className="h-4 w-4 mr-1" />
      {errorMessage}
    </div>
  );
};

export const ResetPasswordForm: React.FC<Props> = ({
  handleForgotPassword,
  handleInputChange,
  handleResetPassword,
  forgotPasswordState,
  validateResult,
}) => {
  const otpErrorMessage = useMemo(() => {
    if (validateResult.otp.$error) {
      return validateResult.otp.$error;
    } else if (forgotPasswordState.cognitoErrorCode) {
      return getCognitoErrorMessage(forgotPasswordState.cognitoErrorCode);
    }

    return "";
  }, [forgotPasswordState.cognitoErrorCode, validateResult.otp.$error]);

  return (
    <Form onSubmit={handleResetPassword} className="flex flex-col gap-6">
      <div className="font-sans text-sm">Please enter the code sent to your email address.</div>
      <div className="mb-2 font-sans text-sm">Verification Code</div>
      <OTPInput
        autoFocus={true}
        disabled={false}
        error={Boolean(validateResult.otp.$error || forgotPasswordState.cognitoErrorCode)}
        // Styles set in `inputStyles` take precedence over
        // `inputClassName` and we need to override the built-in
        // defaults which forces us to style here and in the CSS class.
        onChange={(value: string) => handleInputChange("otp", value)}
        OTPLength={OTP_LENGTH}
        otpType="number"
        placeholder="999999"
        value={forgotPasswordState.otp}
      />
      {forgotPasswordState.cognitoErrorCode === "LimitExceededException" ? (
        <div className="flex items-end">
          <ErrorMessage errorMessage={getCognitoErrorMessage("LimitExceededException")} />
        </div>
      ) : (
        <>
          {otpErrorMessage && <ErrorMessage errorMessage={otpErrorMessage} />}
          <div className="flex">
            <span className="mr-1 font-sans text-sm">Didn&apos;t receive code?</span>
            <ResendCode handleForgotPassword={handleForgotPassword} />
          </div>
        </>
      )}
      <PasswordGuidelines />
      <FormFieldPassword
        displayErrorMessage={validateResult.newPassword.$error !== SignInErrors.PASSWORD_MISMATCH}
        error={validateResult.newPassword.$error}
        layout="labelOut"
        label="New Password"
        name="newPassword"
        onChange={(event) => handleInputChange("newPassword", event.target.value)}
        placeholder="Enter Password"
        value={forgotPasswordState.newPassword}
      />
      <FormFieldPassword
        error={validateResult.confirmPassword.$error}
        layout="labelOut"
        label="Confirm Password"
        name="confirmPassword"
        onChange={(event) => handleInputChange("confirmPassword", event.target.value)}
        placeholder="Enter Password"
        value={forgotPasswordState.confirmPassword}
      />
      <div className="flex flex-col items-center">
        <AsyncButton
          className={cxFormStyles.submitButton}
          displayLoadingText={true}
          isLoading={forgotPasswordState.isLoading}
          type="submit"
        >
          {forgotPasswordState.isLoading ? "Sending" : "Submit"}
        </AsyncButton>
      </div>
      <ContentFooter>
        <span>Still facing issues accessing your account? For help, please get in touch at&nbsp;</span>
        <MailToLink email="hello@archy.com" />
      </ContentFooter>
    </Form>
  );
};
