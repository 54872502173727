import { ChangeEvent, useCallback, useMemo } from "react";
import { useLocation } from "react-router-dom";
import { EmployeeOverviewVO, EmployeeRolesVO, EmployeeVO } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { LayoutCard } from "@libs/components/UI/LayoutCard";
import { TableGrid, Title, TitleContainer } from "@libs/components/UI/GridTableComponents";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { ScrollableInfiniteQuery } from "@libs/components/UI/ScrollableInfiniteQuery";
import { QueryFilters } from "@libs/components/UI/QueryFilters";
import { BulkActionsMenu } from "components/Employee/BulkActionsMenu";
import { EmployeeRow } from "components/Employee/EmployeeRow";
import { EmptyList } from "components/Employee/EmptyList";
import { EmployeeHeaderCell } from "components/Employee/EmployeeHeaderCell";
import { EmployeesQueryUpdates, EmployeesQuery, NO_STATUS_FILTER } from "utils/routing/employees";
import { getEmloyeesFilterProps } from "components/Employee/employeesFilterQuery";
import { FlyoverV2 } from "components/UI/FlyoverV2";
import { EmployeesFilterForm } from "components/Employee/EmployeesFilterForm";

interface Props {
  employeesQuery: UseInfiniteApiListQueryResult<EmployeeVO>;
  queryState: EmployeesQuery;
  roles: EmployeeRolesVO[];
  employeeOverview: EmployeeOverviewVO;
  selectedRows: Set<number>;
  queryKey: unknown;
  onAddEmployee: Func;
  onDeleteEmployees: Func;
  onArchiveEmployees: Func;
  onQueryParamsChange: (updates: EmployeesQueryUpdates) => void;
  onToggleRow: (e: ChangeEvent<HTMLInputElement>) => void;
}

const employeeTableHeader = [
  {
    text: "",
    width: "50px",
  },
  {
    text: "Name",
    sortColumnName: "lastName",
    width: "minmax(100px, 300px)",
  },
  {
    text: "Status",
    sortColumnName: "status",
    width: "200px",
  },
  {
    text: "Job Title",
    width: "200px",
  },
  {
    text: "Access Role",
    width: "120px",
  },
  {
    text: "Phone",
    width: "160px",
  },
  {
    text: "Clock in / Clock out",
    sortColumnName: "lastWorktimeUnixTimestamp",
    width: "auto",
  },
];

export const EmployeesTableSection: React.FC<Props> = ({
  employeesQuery,
  queryState,
  roles,
  selectedRows,
  queryKey,
  onAddEmployee,
  onArchiveEmployees,
  onDeleteEmployees,
  onQueryParamsChange,
  onToggleRow,
}) => {
  const filterEmployeesFlyover = useBoolean(false);
  const location = useLocation();

  const employeesFilterProps = useMemo(() => getEmloyeesFilterProps(queryState), [queryState]);

  const handleQueryFilterChange = useCallback(
    (updates: EmployeesQueryUpdates) => {
      if ("status" in updates && updates.status === undefined) {
        onQueryParamsChange({ ...updates, status: [NO_STATUS_FILTER] });
      } else {
        onQueryParamsChange(updates);
      }
    },
    [onQueryParamsChange]
  );

  const handleClearAllFilters = useCallback(() => {
    handleQueryFilterChange(employeesFilterProps.emptyParams);
  }, [handleQueryFilterChange, employeesFilterProps.emptyParams]);

  const handleSortChange = useCallback(
    (sortColumn: string, orderBy: "ASCENDING" | "DESCENDING") => {
      onQueryParamsChange({
        orderBy,
        sortColumn,
      });
    },
    [onQueryParamsChange]
  );

  const fullUrl = getFullUrl(location);

  return (
    <>
      <LayoutCard className="h-full flex flex-col">
        <div className="flex-none relative z-10">
          <div
            className={`
              flex
              justify-between
              items-center
              h-14
              border-b
              border-slate-200
              px-6
            `}
          >
            <div className="flex items-center">
              <TitleContainer className="flex items-center">
                <Title className="mr-3">Profiles</Title>
                <ButtonIcon theme="primary" size="md" SvgIcon={AddIcon} onClick={onAddEmployee} />
              </TitleContainer>
            </div>
          </div>

          <QueryFilters
            {...employeesFilterProps}
            searchParamKey="search"
            onOpenFlyover={filterEmployeesFlyover.on}
            bulkActions={
              <BulkActionsMenu
                className="h-6"
                onArchiveEmployees={onArchiveEmployees}
                onDeleteEmployees={onDeleteEmployees}
                employeesQuery={employeesQuery}
                selectedRows={selectedRows}
              />
            }
            totalElements={getInfiniteQueryPagingDetails(employeesQuery.data)?.totalElements ?? 0}
            onUpdateParams={handleQueryFilterChange}
          />
        </div>

        <ScrollableInfiniteQuery
          queryKey={queryKey}
          id="employees-table"
          infiniteQuery={employeesQuery}
          noResults={
            <div className="py-16 col-span-full">
              <EmptyList
                isFiltered={employeesFilterProps.filters.length > 0}
                onClearFilters={handleClearAllFilters}
              />
            </div>
          }
          loading={
            <div className="min-h-screen col-span-8">
              <LoadingContent className="rounded-none" />
            </div>
          }
        >
          {(employees) => (
            <TableGrid columnWidths={employeeTableHeader.map(({ width }) => width)}>
              {employeeTableHeader.map((item, index) => (
                <EmployeeHeaderCell
                  key={index}
                  text={item.text}
                  sortColumnName={item.sortColumnName}
                  queryState={queryState}
                  onSortChange={handleSortChange}
                />
              ))}
              {employees.map((employee) => (
                <EmployeeRow
                  key={employee.id}
                  roles={roles}
                  selectedRows={selectedRows}
                  employee={employee}
                  onToggleRow={onToggleRow}
                  currentUrl={fullUrl}
                />
              ))}
            </TableGrid>
          )}
        </ScrollableInfiniteQuery>
      </LayoutCard>
      <FlyoverV2 isOpen={filterEmployeesFlyover.isOn}>
        <EmployeesFilterForm
          onRequestClose={filterEmployeesFlyover.off}
          onSave={(updates) => {
            handleQueryFilterChange(updates);
            filterEmployeesFlyover.off();
          }}
          status={queryState.status}
        />
      </FlyoverV2>
    </>
  );
};
