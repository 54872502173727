import React from "react";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { TabText } from "components/UI/TabText";

import { appointmentFormCxStyles } from "components/ScheduleAppointments/AppointmentForm";
import { Divider } from "components/UI/Divider";

export type AppointmentSlotTab = "setAppointment" | "findAppointment";
type Props = {
  tabSelected: AppointmentSlotTab;
  onTabSelected: (tab: AppointmentSlotTab) => void;
  tabsDisabled?: AppointmentSlotTab[];
};

export const AppointmentSlotTabs: React.FC<Props> = ({ tabSelected, onTabSelected, tabsDisabled }) => {
  return (
    <div className={appointmentFormCxStyles.row}>
      <div className={appointmentFormCxStyles.contentContainer}>
        <Divider />
        <div className="flex px-6">
          <button
            disabled={tabsDisabled?.includes("setAppointment")}
            onClick={() => onTabSelected("setAppointment")}
            type="button"
          >
            <TabText
              className="-ml-4"
              disabled={tabsDisabled?.includes("setAppointment")}
              isSelected={tabSelected === "setAppointment"}
            >
              Set Appointment
            </TabText>
          </button>
          <button
            disabled={tabsDisabled?.includes("findAppointment")}
            onClick={() => onTabSelected("findAppointment")}
            type="button"
          >
            <TabText
              className="-ml-4"
              disabled={tabsDisabled?.includes("findAppointment")}
              isSelected={tabSelected === "findAppointment"}
            >
              Find Appointment
            </TabText>
          </button>
        </div>
      </div>
      <VerticalDivider />
      <div className={appointmentFormCxStyles.detailsContainer} />
    </div>
  );
};
