import { FC, ReactNode } from "react";

import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as StatementIcon } from "@libs/assets/icons/statement.svg";
import { DashboardBulkActionsRow } from "components/Dashboard/Tables/DashboardBulkActionsRow";
import { MessagePatientsButton } from "components/Communications/MessagePatients/MessagePatientsButton";

interface Props {
  selectedCount: number;
  total: number;
  onRequestMessagePatients: Func;
  onRequestGenerateStatment: Func;
  filters: ReactNode;
}

export const ByPatientAgingBulkActionsRow: FC<Props> = ({
  selectedCount,
  total,
  onRequestMessagePatients,
  onRequestGenerateStatment,
  filters,
}) => {
  const actionsDisabled = total === 0 || (filters == null && selectedCount === 0);

  return (
    <DashboardBulkActionsRow
      actions={
        <div className="flex items-center gap-x-6 pr-6 h-6">
          <MessagePatientsButton
            onRequestMessagePatients={onRequestMessagePatients}
            disabled={actionsDisabled}
          />
          <ButtonIcon
            SvgIcon={StatementIcon}
            tooltip={{ content: "Generate Statement", theme: "SMALL" }}
            disabled={actionsDisabled}
            onClick={onRequestGenerateStatment}
          />
        </div>
      }
      selectedCount={selectedCount}
      total={total}
      singular="patient"
      plural="patients"
      filters={filters}
    />
  );
};
