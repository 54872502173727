import { cx } from "@libs/utils/cx";
import { cxTableStyles } from "components/Dashboard/Tables/cxTableStyles";
import { ColumnTextAlign } from "components/Dashboard/Tables/types";

export const SectionFooter: React.FC<{
  children?: React.ReactNode;
  className?: string;
  align?: ColumnTextAlign;
}> = ({ children, className, align = "left" }) => {
  return <div className={cx(cxTableStyles.sectionFooter(align), className)}>{children}</div>;
};
