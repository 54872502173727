import { MedicalImageVO } from "@libs/api/generated-api";
import { useMemo } from "react";

export const usePearlAnlaysisCounts = (images: MedicalImageVO[]) => {
  return useMemo(() => {
    return images.reduce(
      (acc, image) => {
        if (image.pearlAnalysis?.status === "DONE") {
          return { ...acc, done: acc.done + 1 };
        } else if (!image.pearlAnalysis || image.pearlAnalysis.status === "NOT_SUBMITTED") {
          return {
            ...acc,
            notSubmitted: acc.notSubmitted + 1,
          };
        }

        return { ...acc, submitted: acc.submitted + 1 };
      },
      { done: 0, submitted: 0, notSubmitted: 0 }
    );
  }, [images]);
};
