import { EmptyContent } from "@libs/components/UI/EmptyContent";
import EmptySchedule from "assets/images/empty-schedule.svg";

export const NoScheduledAppointments = () => {
  return (
    <div className="h-full p-2.5">
      <EmptyContent fullHeight text="No Scheduled Appointments" alt="No Appointment" src={EmptySchedule} />
    </div>
  );
};
