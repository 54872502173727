import {
  AutomationActionVO,
  AutomationActionRequest,
  TemplateVariableVO,
  AutomationJourneyVO,
  JourneyVO,
  TemplateVariableGroupVO,
} from "@libs/api/generated-api";
import { isoTime, maxLength, required } from "@libs/utils/validators";
import { DAY_IN_HOURS, WEEK_IN_HOURS } from "@libs/utils/date";
import { ValidationResult } from "@libs/hooks/useValidation";
import { humanizeConstant } from "@libs/utils/casing";
import { isOneOf } from "@libs/utils/isOneOf";
import { pluralize } from "@libs/utils/pluralize";
import { ApiQueryResult } from "@libs/@types/apiQueries";

import { MAX_SMS_LENGTH, validateTemplateVariables } from "components/Communications/utils";
import { DraftAutomationAction } from "components/Communications/Automations/types";

export const CHANNEL_LABELS: Record<ListItem<AutomationActionVO["channels"]>, string> = {
  SMS: "SMS",
  EMAIL: "Email",
  PATIENT_PREFERRED: "Preferred",
  MAIL: "Mail",
};

export const IntervalTypeOptions: {
  value: NonNullable<AutomationActionVO["intervalType"]>;
  label: string;
}[] = [
  { label: "Hours", value: "HOUR" },
  { label: "Days", value: "DAY" },
  { label: "Weeks", value: "WEEK" },
];

const IntervalTypeAsHoursMap: Record<NonNullable<AutomationActionVO["intervalType"]>, number> = {
  HOUR: 1,
  DAY: DAY_IN_HOURS,
  WEEK: WEEK_IN_HOURS,
};

export const formatIntervalToHours = ({
  interval,
  intervalType,
  eventTime,
}: Pick<AutomationActionVO, "interval" | "intervalType" | "eventTime">) => {
  if (interval && intervalType) {
    return interval * IntervalTypeAsHoursMap[intervalType] * (eventTime === "BEFORE" ? -1 : 1);
  }

  return 0;
};

export const sortActionsByTiming = (actions: AutomationActionVO[]) => {
  const sortedActions = [...actions];

  sortedActions.sort((actionA, actionB) => {
    const intervalInHoursA = formatIntervalToHours({
      interval: actionA.interval,
      intervalType: actionA.intervalType,
      eventTime: actionA.eventTime,
    });

    const intervalInHoursB = formatIntervalToHours({
      interval: actionB.interval,
      intervalType: actionB.intervalType,
      eventTime: actionB.eventTime,
    });

    if (intervalInHoursA && !intervalInHoursB) {
      return 1;
    }

    if (!intervalInHoursA && intervalInHoursB) {
      return -1;
    }

    return intervalInHoursA < intervalInHoursB ? -1 : intervalInHoursA > intervalInHoursB ? 1 : 0;
  });

  return sortedActions;
};

export const formatEventTiming = ({
  interval,
  intervalType,
  eventTime,
  event,
}: Pick<AutomationActionVO, "interval" | "intervalType" | "eventTime" | "event">) => {
  if (interval && intervalType) {
    const pluralizedIntervalType = pluralize(interval, intervalType, `${intervalType}S`);

    return humanizeConstant(`${interval} ${pluralizedIntervalType} ${eventTime} ${event}`);
  }

  // WHEN eventTime has no interval and intervalType
  return humanizeConstant(`${eventTime} ${event}`);
};

export const journeyNames: Record<AutomationJourneyVO["type"], string> = {
  APPOINTMENT: "Pre Appointment",
  POST_APPOINTMENT: "Post Appointment",
  RECALL: "Recall Reminders",
  FORMS: "Incomplete Forms",
};

export const getAutomationActionRequest = (action: AutomationActionVO): AutomationActionRequest => {
  const { uuid: _, dentalProcedures, ...actionRequest } = action;

  return {
    ...actionRequest,
    ...(dentalProcedures
      ? {
          dentalProcedures: {
            ids: dentalProcedures.procedures.map(({ id }) => id),
            contains: dentalProcedures.contains,
          },
        }
      : undefined),
  };
};

export const getDraftAutomationAction = (
  action: AutomationActionVO,
  isEmailConfigured: boolean
): DraftAutomationAction => {
  const { uuid: _, ...actionRequest } = action;

  return {
    ...actionRequest,
    channels: actionRequest.channels.filter((channel) => (isEmailConfigured ? true : channel === "SMS")),
    dentalProcedures: actionRequest.dentalProcedures ?? { procedures: [], contains: "ANY" },
  };
};

export const getDraftAutomationActionFromJourneyType = (
  journeyType: AutomationJourneyVO["type"],
  isEmailConfigured: boolean
): DraftAutomationAction => {
  return {
    event:
      journeyType === "FORMS"
        ? "CONFIRMED_APPOINTMENT"
        : journeyType === "RECALL"
          ? "PERIO_DUE"
          : journeyType === "APPOINTMENT"
            ? "APPOINTMENT_REQUESTED"
            : "APPOINTMENT_COMPLETED",
    eventTime: isOneOf(journeyType, ["RECALL", "FORMS"]) ? "BEFORE" : "WHEN",
    active: true,
    channels: [isEmailConfigured ? "PATIENT_PREFERRED" : "SMS"],
    ...(journeyType === "POST_APPOINTMENT"
      ? { dentalProcedures: { contains: "ANY", procedures: [] } }
      : undefined),
  };
};

export const getChannelsStatus = (channels: DraftAutomationAction["channels"]) => {
  const channelsSet = new Set(channels);

  return {
    isSendingEmails: channelsSet.has("EMAIL") || channelsSet.has("PATIENT_PREFERRED"),
    isSendingSms: channelsSet.has("SMS") || channelsSet.has("PATIENT_PREFERRED"),
  };
};

export const getAutomationActionSchema = (
  event: DraftAutomationAction["event"],
  eventTime: DraftAutomationAction["eventTime"],
  channels: DraftAutomationAction["channels"],
  hasCustomEmail: boolean,
  templateVariablesSets: {
    content: Set<TemplateVariableVO["key"]>;
    subject: Set<TemplateVariableVO["key"]>;
  }
) => {
  const { isSendingEmails, isSendingSms } = getChannelsStatus(channels);

  return {
    subject: [
      {
        $v: required,
        $error: "A subject is required for sending emails",
        $ignore: !isSendingEmails,
      },
      validateTemplateVariables(templateVariablesSets.subject),
    ],
    interval: [{ $v: required, $ignore: eventTime === "WHEN", $error: "Required" }],
    intervalType: [{ $v: required, $ignore: eventTime === "WHEN", $error: "Required" }],
    smsTemplate: [
      { $v: required, $ignore: !isSendingSms, $error: "A message is required for sending SMS messages" },
      { $v: maxLength(MAX_SMS_LENGTH), $error: `SMS messages must be ${MAX_SMS_LENGTH} characters or less` },
      validateTemplateVariables(templateVariablesSets.content),
    ],
    emailTemplate: [
      {
        $v: required,
        $ignore: !isSendingEmails || hasCustomEmail,
        $error: "A message is required for sending Emails",
      },
      validateTemplateVariables(templateVariablesSets.content),
    ],
    sendTime: {
      $ignore: !isOneOf(event, ["PERIO_DUE", "PROPHY_DUE"]),
      $validations: [
        { $v: required, $error: "Required" },
        { $v: isoTime, $error: "A valid time is required" },
      ],
    },
  };
};

export type AutomationActionValidation = ValidationResult<
  DraftAutomationAction,
  ReturnType<typeof getAutomationActionSchema>
>;

export const filterTemplateVariables = (
  journeyType: JourneyVO["type"],
  templateVariablesQuery: ApiQueryResult<TemplateVariableGroupVO[]>
) => {
  const templateVariables = templateVariablesQuery.data ?? [];

  let noFormsLink = templateVariables.map((group) => ({
    ...group,
    variables: group.variables.filter((variable) => variable.key !== "FORMS_LINK"),
  }));

  noFormsLink = noFormsLink.filter((group) => group.variables.length);

  return {
    subject: {
      data: noFormsLink.filter((group) => group.type !== "LINKS"),
      isError: templateVariablesQuery.isError,
      isLoading: templateVariablesQuery.isLoading,
      isPreviousData: templateVariablesQuery.isPreviousData,
    },
    content: {
      data: journeyType === "FORMS" ? templateVariables : noFormsLink,
      isError: templateVariablesQuery.isError,
      isLoading: templateVariablesQuery.isLoading,
      isPreviousData: templateVariablesQuery.isPreviousData,
    },
  };
};
