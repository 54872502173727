import {
  CreateCustomProcedureRequest,
  UpdateCustomProcedureRequest,
  PracticeVO,
  ProviderColorRequest,
  AdaProcedureOverrideRequest,
  RoomsRequest,
  UpdatePracticeSecuritySettingRequest,
  PracticeSecuritySettingVO,
  RoleRequest,
  DeleteRoleRequest,
  PracticeRemoteSettingsRequest,
} from "@libs/api/generated-api";
import { updateCachedData } from "@libs/utils/queryCache";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import { QueryClient } from "@tanstack/react-query";
import { trackIniatedWebSocketEvents } from "storage/initiatedWebSocketEventTimestamps";
import {
  handlePracticeAppointmentsUpdated,
  handlePatientAppointmentsUpdated,
  handleRoomsUpdated,
  handleScheduleBlocksUpdated,
  handleRoomsHoursUpdated,
  handleProvidersUpdated,
} from "api/websocket/handleScheduleUpdated";

export const updateProviderColor = makeMutation({
  mutationKey: ["practices", "updateProviderColor"],
  formatParams: (args: { practiceId: number; data: ProviderColorRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      trackIniatedWebSocketEvents("SCHEDULE_UPDATED_V2");
      handleProvidersUpdated({ queryClient, practiceId });
      queryClient.invalidateQueries([getQueryKey("practices", "getAppointmentCards"), { practiceId }]);
    },
  }),
});

export const upsertRooms = makeMutation({
  mutationKey: ["practices", "upsertRooms"],
  formatParams: (args: { practiceId: number; data: RoomsRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      trackIniatedWebSocketEvents("SCHEDULE_UPDATED_V2");
      handleRoomsUpdated({ queryClient, practiceId });
      handleRoomsHoursUpdated({ queryClient, practiceId });
      handleScheduleBlocksUpdated({ queryClient, practiceId });
      handlePatientAppointmentsUpdated({ queryClient, practiceId });
      handlePracticeAppointmentsUpdated({ queryClient, practiceId });
    },
  }),
});

export const updatePractice = makeMutation({
  mutationKey: ["practices", "updatePractice"],
  formatParams: (args: { practiceId: number; data: PracticeVO }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId }) => {
      queryClient.setQueryData([getQueryKey("practices", "getPractice"), { practiceId }], data);
      queryClient.invalidateQueries([getQueryKey("practices", "getPracticeInfoById"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("user", "getUserAccounts"), { practiceId }]);
    },
  }),
});

export const deleteProfileImage1 = makeMutation({
  mutationKey: ["practices", "deleteProfileImage1"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getProfileImage1"), { practiceId }]);
    },
  }),
});

export const uploadProfileImage1 = makeMutation({
  mutationKey: ["practices", "uploadProfileImage1"],
  formatParams: (args: { practiceId: number; image: File }) => [args.practiceId, { image: args.image }],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getProfileImage1"), { practiceId }]);
    },
  }),
});

export const createCustomProcedure = makeMutation({
  mutationKey: ["practices", "createCustomProcedure"],
  formatParams: (args: { practiceId: number; data: CreateCustomProcedureRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getDentalProceduresByPractice"),
        { practiceId },
      ]);
    },
  }),
});

const invalidateAllDentalProcedureReferences = (queryClient: QueryClient) => {
  // The amount of queries that reference dental procedures is too much to track so we'll
  // invlaidate all of them
  queryClient.invalidateQueries();
};

export const updateCustomProcedure = makeMutation({
  mutationKey: ["practices", "updateCustomProcedure"],
  formatParams: (args: {
    practiceId: number;
    dentalProcedureId: number;
    data: UpdateCustomProcedureRequest;
  }) => [args.practiceId, args.dentalProcedureId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: () => {
      invalidateAllDentalProcedureReferences(queryClient);
    },
  }),
});

export const overrideAdaProcedure = makeMutation({
  mutationKey: ["practices", "overrideAdaProcedure"],
  formatParams: (args: {
    practiceId: number;
    dentalProcedureId: number;
    data: AdaProcedureOverrideRequest;
  }) => [args.practiceId, args.dentalProcedureId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: () => {
      invalidateAllDentalProcedureReferences(queryClient);
    },
  }),
});

export const updatePracticeSecuritySettings = makeMutation({
  mutationKey: ["practices", "updatePracticeSecuritySettings"],
  formatParams: (args: { practiceId: number; data: UpdatePracticeSecuritySettingRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId }) => {
      updateCachedData<PracticeSecuritySettingVO>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getPracticeSecuritySettings"), { practiceId }] },
        (priorData) => {
          return {
            ...priorData,
            ...data.data.data,
          };
        }
      );
    },
  }),
});

export const createRoleV2 = makeMutation({
  mutationKey: ["practices", "createRoleV2"],
  formatParams: (args: { practiceId: number; data: RoleRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_response, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getPracticeRolesV2"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getRoleV2"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getAssignableRolesForUser"), { practiceId }]);
    },
  }),
});

export const updateRoleV2 = makeMutation({
  mutationKey: ["practices", "updateRoleV2"],
  formatParams: (args: { practiceId: number; roleId: number; data: RoleRequest }) => [
    args.practiceId,
    args.roleId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_response, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getPracticeRolesV2"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getAssignableRolesForUser"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getRoleV2"), { practiceId }]);
    },
  }),
});

export const deleteRoleV2 = makeMutation({
  mutationKey: ["practices", "deleteRoleV2"],
  formatParams: (args: { practiceId: number; roleId: number; data: DeleteRoleRequest }) => [
    args.practiceId,
    args.roleId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_response, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getPracticeRolesV2"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getAssignableRolesForUser"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getRoleV2"), { practiceId }]);
    },
  }),
});

export const upsertPracticeRemoteSettings = makeMutation({
  mutationKey: ["practices", "upsertPracticeRemoteSettings"],
  formatParams: (args: { practiceId: number; data: PracticeRemoteSettingsRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_response, { practiceId }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPracticeConnectedRemoteSettings"),
        { practiceId },
      ]);
    },
  }),
});
