import { isDefined } from "@libs/utils/types";
import React, { PropsWithChildren } from "react";
import { META_KEY } from "utils/keyboard";

const Bold = ({ children }: PropsWithChildren) => <span className="font-sansSemiBold">{children}</span>;
const TipList: React.FC<{ title: string; items: React.ReactNode[] }> = ({ title, items }) => {
  return (
    <div className="flex flex-col gap-2 text-xs">
      <p className="font-sansSemiBold">{title}</p>
      <ul className="list-disc list-inside flex flex-col gap-1">
        {items.map((content, index) => (
          <li key={index}>{content}</li>
        ))}
      </ul>
    </div>
  );
};
const RotateLeftTip = () => (
  <>
    <Bold>{META_KEY} + ←</Bold>: rotate left
  </>
);
const RotateRightTip = () => (
  <>
    <Bold>{META_KEY} + →</Bold>: rotate right
  </>
);

export const SandboxTipsContent: React.FC = () => {
  return (
    <div className="flex flex-col gap-6">
      <TipList
        title="General Tips"
        items={[
          "Double click image to edit",
          <React.Fragment key="zoom">
            <Bold>Shift + Click</Bold> to select multiple images
          </React.Fragment>,
          <React.Fragment key="zoom">
            <Bold>z + mouse up/down</Bold> or <Bold>scroll</Bold>: zoom in/out on mount view
          </React.Fragment>,
        ]}
      />
      <TipList
        title="Keyboard Shortcuts"
        items={[<RotateLeftTip key="left" />, <RotateRightTip key="right" />]}
      />
    </div>
  );
};

export const EditorTipsContent: React.FC<{ distanceToolEnabled: boolean }> = ({ distanceToolEnabled }) => {
  return (
    <div className="flex flex-col gap-6">
      <TipList
        title="Drawing Tips"
        items={[
          <React.Fragment key="select-all">
            <Bold>{META_KEY} + a</Bold>: select all annotations
          </React.Fragment>,
          <React.Fragment key="delete">
            Use <Bold>del</Bold> or <Bold>Backspace</Bold> to delete selected annotations
          </React.Fragment>,
        ]}
      />
      <TipList
        title="Filtering Tips"
        items={[
          <React.Fragment key="filters">
            Toggle the filter tool (<Bold>w</Bold>), then click and drag on the image to adjust brightness and
            contrast in each dimension
          </React.Fragment>,
        ]}
      />
      <TipList
        title="Keyboard Shortcuts"
        items={[
          <RotateLeftTip key="left" />,
          <RotateRightTip key="right" />,
          <React.Fragment key="magnify">
            <Bold>m</Bold>: magnifying glass
          </React.Fragment>,
          <React.Fragment key="draw">
            <Bold>f</Bold>: free draw tool
          </React.Fragment>,
          <React.Fragment key="line">
            <Bold>x</Bold>: line tool
          </React.Fragment>,
          <React.Fragment key="ellipse">
            <Bold>e</Bold>: ellipse tool
          </React.Fragment>,
          distanceToolEnabled ? (
            <React.Fragment key="distance">
              <Bold>d</Bold>: distance tool
            </React.Fragment>
          ) : null,
          <React.Fragment key="angle">
            <Bold>a</Bold>: angle tool
          </React.Fragment>,
          <React.Fragment key="select">
            <Bold>s</Bold>: select/move tool
          </React.Fragment>,
          <React.Fragment key="filters">
            <Bold>w</Bold>: filters tool
          </React.Fragment>,
        ].filter(isDefined)}
      />
    </div>
  );
};
