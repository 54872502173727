import React, { useEffect, useMemo, useRef, useState } from "react";
import { MedicalImageVO, MountVO } from "@libs/api/generated-api";
import { containImage } from "@libs/utils/image";
import { isDefined } from "@libs/utils/types";
import {
  MountLayoutConfig,
  getLayoutForLayoutName,
} from "components/PatientProfile/Imaging/PatientMountsList/mountLayouts";
import {
  ImageLayoutItem,
  ReserveImageSpotFunc,
  ReservedLayoutItem,
  ReservedStatus,
  isReservedLayoutItem,
} from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";
import {
  getImageTransformStyles,
  findOveflowSpot,
  generateOverflowSpot,
} from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { MountLayoutType } from "api/imaging/imaging-api";
import { useRenderableRef } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/hooks/useRenderableRef";
import { groupBy } from "utils/groupBy";
import { imagingUrlCache } from "components/PatientProfile/Imaging/utils/cache";

const RETAKE_OFFSET = 20;

export const generateMountDestinations = ({
  mountLayoutConfig,
  mount,
}: {
  mount?: MountVO;
  mountLayoutConfig: MountLayoutConfig;
}): (ImageLayoutItem | ReservedLayoutItem)[] => {
  if (!mount) {
    return [];
  }

  const originalImages = mount.images ?? [];

  const mountImageLayouts: ImageLayoutItem[] = originalImages.map((mountImage) => {
    const layout = mountLayoutConfig.layout[mountImage.i ?? 0] ?? mountLayoutConfig.layout[0];
    const size = containImage({ w: mountImage.w, h: mountImage.h }, { w: layout.w, h: layout.h });

    return {
      ...mountImage,
      id: mountImage.id!,
      i: mountImage.i!,
      x: mountImage.x!,
      y: mountImage.y!,
      sandbox: {
        w: size.width,
        h: size.height,
        isPlaceholder: false,
      },
      url: mountImage.url,
    };
  });
  const imagesByIndex = groupBy(mountImageLayouts, "i");
  let mountImages: ImageLayoutItem[] = [];

  for (let i = 0; i < mountLayoutConfig.layout.length; i++) {
    const layout = mountLayoutConfig.layout[i];
    const imagesAtIndex = imagesByIndex[i];

    if (isDefined(imagesAtIndex)) {
      mountImages = [...mountImages, ...imagesAtIndex];
    } else {
      mountImages.push({
        id: i,
        ...layout,
        i,
        sandbox: {
          w: layout.w,
          h: layout.h,
          isPlaceholder: true,
        },
      });
    }
  }

  const outOfBoundsImages = mountImageLayouts
    .filter((image) => image.i >= mountLayoutConfig.layout.length)
    .map((item) => ({ ...item, outOfLayoutBounds: true }));

  mountImages = [...mountImages, ...outOfBoundsImages];

  return mountImages.sort((a, b) => a.i - b.i);
};

const getSandboxImages = ({
  pendingImages = [],
  isViewingArchived,
  stackingOrder,
  orderBy,
  ...rest
}: {
  pendingImages?: ReservedLayoutItem[];
  mount?: MountVO;
  mountLayoutConfig: MountLayoutConfig;
  stackingOrder: number[];
  isViewingArchived?: boolean;
  orderBy?: "index" | "stackingOrder";
}) => {
  const images = generateMountDestinations(rest);

  pendingImages.forEach((pendingImage) => {
    // Replace existing grid images with the pending image,
    const existingIndex = images.findIndex((gridImage) => gridImage.i === pendingImage.i);

    if (existingIndex > -1) {
      if (images[existingIndex].url) {
        images.splice(existingIndex, 0, pendingImage);
      } else {
        images[existingIndex] = pendingImage;
      }
    } else {
      images.push(pendingImage);
    }
  });

  const viewableImages = isViewingArchived ? images : images.filter((item) => !item.isArchived);

  return viewableImages.sort((a, b) => {
    if (orderBy === "index") {
      return a.i - b.i;
    }

    // Items with a URL should always be on top of a placeholder
    if (a.url && !b.url) {
      return 1;
    } else if (!a.url && b.url) {
      return -1;
    }

    // Use stacking order (which was last dragged)
    const aIndex = stackingOrder.indexOf(a.id);
    const bIndex = stackingOrder.indexOf(b.id);

    return aIndex - bIndex;
  });
};

const getLogicalNextSpotToCapture = ({
  sandboxImages,
  nextCaptureSpotId,
  pendingImages,
}: {
  sandboxImages: ImageLayoutItem[];
  mountLayoutType: MountLayoutType;
  nextCaptureSpotId?: number | null;
  pendingImages?: ReservedLayoutItem[];
}) => {
  if (nextCaptureSpotId === null) {
    return findOveflowSpot(sandboxImages);
  }

  const uploadingItems =
    pendingImages?.filter((item) => item.reservation.status === ReservedStatus.Uploading) ?? [];

  const pendingIds = new Set([
    ...uploadingItems.map((item) => item.reservation.captureSpotId),
    ...uploadingItems.map((item) => item.id),
  ]);
  const indexOfPendingItems = sandboxImages.findIndex((item) => pendingIds.has(item.id));

  let nextSpot = sandboxImages
    .filter((item, i) => i > indexOfPendingItems && !pendingIds.has(item.id))
    .sort((a, b) => {
      return a.i - b.i;
    })
    .find((item) =>
      pendingIds.has(nextCaptureSpotId) || !isDefined(nextCaptureSpotId)
        ? !item.url
        : item.id === nextCaptureSpotId
    );

  if (pendingIds.size === 0 && !nextSpot) {
    nextSpot = findOveflowSpot(sandboxImages);
  }

  return nextSpot;
};

export const useMountLayout = ({
  imageMount,
  isViewingArchived,
  retakingImageId,
  onCaptureSequenceStatusChanged,
}: {
  imageMount?: MountVO;
  isViewingArchived?: boolean;
  retakingImageId?: number;
  onCaptureSequenceStatusChanged?: (status: "uploading" | "complete") => void;
}) => {
  const mountLayoutType = imageMount?.layout as MountLayoutType;
  const [isFirstCaptureSession, setIsFirstCaptureSession] = useState(imageMount?.images?.length === 0);
  const mountLayoutConfig = React.useMemo(
    () => getLayoutForLayoutName(mountLayoutType, imageMount?.images),
    [imageMount?.images, mountLayoutType]
  );

  // We need to track the mount changes using a ref, because a user may drag images around while they're capturing
  // this affects the capture callback because it depends on image positions in the mount to calculate the next spot for capture
  const mountRef = useRef(imageMount);

  // useRenderableRef allows us to listen on pendingImagesRef.current.
  const [pendingImagesRef, setPendingImages, renderedPendingImages] = useRenderableRef<ReservedLayoutItem[]>(
    () => []
  );

  // A list of imageIds in the order that they should stack in the layout
  const [stackingOrder, setStackingOrder] = React.useState<number[]>([]);

  const sandboxImages: ImageLayoutItem[] = React.useMemo(() => {
    return getSandboxImages({
      pendingImages: renderedPendingImages,
      mount: imageMount,
      mountLayoutConfig,
      stackingOrder,
      isViewingArchived,
      orderBy: "stackingOrder",
    }).filter((item) =>
      isReservedLayoutItem(item) ? item.reservation.status !== ReservedStatus.None : true
    );
  }, [imageMount, isViewingArchived, mountLayoutConfig, renderedPendingImages, stackingOrder]);

  // Next capture spot is defined when a user clicks an image or a location for an image to be captured
  // when it is undefined, the next logical position in the layout should be used
  // when it is null, it means the mount has been filled for this session and we should use the overflow spot
  const [nextCaptureSpotIdRef, handleNewCaptureSpotId, nextCaptureSpotId] = useRenderableRef<
    number | undefined | null
  >(() => undefined);

  const nextCaptureSpot = React.useMemo(() => {
    // Sandbox images will be empty when mount is full and all images are archived
    return sandboxImages.length > 0
      ? getLogicalNextSpotToCapture({
          sandboxImages,
          mountLayoutType,
          nextCaptureSpotId,
          pendingImages: pendingImagesRef.current ?? [],
        })
      : generateOverflowSpot(mountLayoutConfig.layout[0], 0);
  }, [mountLayoutConfig.layout, mountLayoutType, nextCaptureSpotId, pendingImagesRef, sandboxImages]);

  const handleOrderImageOnTop = React.useCallback((imageId: number) => {
    setStackingOrder((prev) => {
      const index = prev.indexOf(imageId);

      if (index === -1) {
        return [...prev, imageId];
      }

      return [...prev.filter((id) => id !== imageId), imageId];
    });
  }, []);

  const handleLayoutItemClicked = React.useCallback(
    (item: ImageLayoutItem) => {
      if (item.url && item.id) {
        handleOrderImageOnTop(item.id);
      }

      handleNewCaptureSpotId(item.id);
    },
    [handleNewCaptureSpotId, handleOrderImageOnTop]
  );

  useEffect(() => {
    handleNewCaptureSpotId(undefined);
  }, [handleNewCaptureSpotId, retakingImageId]);
  useEffect(() => {
    mountRef.current = imageMount;
  }, [imageMount]);

  // eslint-disable-next-line complexity
  const reserveImageSpot = React.useCallback<ReserveImageSpotFunc>(() => {
    let reservedSpot: ReservedLayoutItem | undefined;
    const latestPendingImages = pendingImagesRef.current ?? [];
    const latestLayout = getSandboxImages({
      pendingImages: latestPendingImages,
      mount: mountRef.current,
      mountLayoutConfig,
      stackingOrder,
      isViewingArchived,
      orderBy: "index",
    });

    onCaptureSequenceStatusChanged?.("uploading");

    const retakeImageSpot = latestLayout.find(
      (image) =>
        !image.sandbox.isPlaceholder && image.id === nextCaptureSpotIdRef.current && !("reservation" in image)
    );

    if (retakeImageSpot && isDefined(nextCaptureSpotIdRef.current)) {
      // We must generate a temporary id because we're uploading items in this image's spot, vs. generating new spots
      const id = -Date.now();

      // Replacing an image
      const replacementCount =
        latestPendingImages.filter(
          (image) => image.reservation.captureSpotId === nextCaptureSpotIdRef.current
        ).length + 1;

      const { sizeInverted } = getImageTransformStyles(retakeImageSpot.transforms);

      reservedSpot = {
        ...retakeImageSpot,
        id,
        reservation: {
          status: ReservedStatus.Uploading,
          captureSpotId: nextCaptureSpotIdRef.current,
        },
        url: undefined,
        x: retakeImageSpot.x + RETAKE_OFFSET * replacementCount,
        y: retakeImageSpot.y + RETAKE_OFFSET * replacementCount,
        transforms: undefined,
        w: sizeInverted ? retakeImageSpot.h : retakeImageSpot.w,
        h: sizeInverted ? retakeImageSpot.w : retakeImageSpot.h,
      };
    } else {
      const nextSpotId =
        nextCaptureSpotIdRef.current ??
        getLogicalNextSpotToCapture({
          sandboxImages: latestLayout,
          pendingImages: latestPendingImages,
          mountLayoutType,
          nextCaptureSpotId: nextCaptureSpotIdRef.current,
        })?.id;

      const pendingIds = new Set(latestPendingImages.map((image) => image.id));

      const indexOfSelectedSpot = latestLayout.findIndex((item) =>
        "reservation" in item ? item.reservation.captureSpotId === nextSpotId : item.id === nextSpotId
      );
      // Sandbox images should be safe to use since it reserves the full mount and we modify the entries directly for those.
      const indexToReserve =
        indexOfSelectedSpot > -1
          ? latestLayout.findIndex(
              (image, i) => i >= indexOfSelectedSpot && !image.url && !pendingIds.has(image.id)
            )
          : -1;

      const nextInLayout = indexToReserve > -1 ? latestLayout[indexToReserve] : findOveflowSpot(latestLayout);

      reservedSpot = {
        ...nextInLayout,
        reservation: {
          status: ReservedStatus.Uploading,
          captureSpotId: nextInLayout.id,
        },
      };
    }

    const updatedPendingImages = [...(pendingImagesRef.current ?? []), reservedSpot];

    handleOrderImageOnTop(reservedSpot.id);
    setPendingImages(updatedPendingImages);

    return reservedSpot;
  }, [
    handleOrderImageOnTop,
    isViewingArchived,
    mountLayoutConfig,
    mountLayoutType,
    nextCaptureSpotIdRef,
    onCaptureSequenceStatusChanged,
    pendingImagesRef,
    setPendingImages,
    stackingOrder,
  ]);

  const updateReservedSpot = React.useCallback(
    (id: number, updates: Partial<ReservedLayoutItem["reservation"]>) => {
      const updatedImages = (pendingImagesRef.current ?? []).map((image) => {
        if (image.id === id) {
          return {
            ...image,
            reservation: {
              ...image.reservation,
              ...updates,
            },
          };
        }

        return image;
      });

      setPendingImages(updatedImages);
    },
    [pendingImagesRef, setPendingImages]
  );

  const hasCompletedMount = useMemo(() => {
    const uniqueIndecies = new Set(imageMount?.images?.map((image) => image.i));

    return !mountLayoutConfig.layout.some((_, i) => !uniqueIndecies.has(i));
  }, [imageMount?.images, mountLayoutConfig.layout]);

  React.useEffect(() => {
    if (hasCompletedMount) {
      setIsFirstCaptureSession(false);
    }
  }, [hasCompletedMount]);

  return useMemo(() => {
    return {
      nextCaptureSpot,
      isFirstCaptureSession,
      sandboxImages,
      retakingImageId,
      zoomToImageId: isFirstCaptureSession
        ? retakingImageId ?? nextCaptureSpot?.id
        : pendingImagesRef.current?.at(-1)?.id,
      handleLayoutItemClicked,
      handleOrderImageOnTop,
      reserveImageSpot,
      handleNewCaptureSpotId,
      updateReservedSpot,
      releaseReservedSpot: (id: number, serverImage: MedicalImageVO) => {
        // Don't clear pending images until the capture session is complete, this will break the offsets
        updateReservedSpot(id, {
          status: ReservedStatus.None,
        });

        const pending = pendingImagesRef.current ?? [];
        const updatedPendingImages = pending.map((image) => {
          if (image.id === id) {
            return {
              ...image,
              id: serverImage.id!,
              reservation: {
                ...image.reservation,
                status: ReservedStatus.None,
              },
            };
          }

          return image;
        });
        const reservedItem = pending.find((item) => item.id === id);

        if (reservedItem) {
          // Populate the imaging cache with blob already saved on reserved image, so we don't re-download it from the server
          imagingUrlCache.add(reservedItem);
        }

        handleOrderImageOnTop(serverImage.id!);

        const didCompleteCaptureSequence = !updatedPendingImages.some(
          (item) => item.reservation.status === ReservedStatus.Uploading
        );

        if (didCompleteCaptureSequence) {
          const latestLayout = getSandboxImages({
            pendingImages: updatedPendingImages,
            mount: imageMount,
            mountLayoutConfig,
            stackingOrder,
            isViewingArchived,
            orderBy: "index",
          });
          const selectedSpotServerId = updatedPendingImages.find(
            (item) => item.reservation.captureSpotId === nextCaptureSpotIdRef.current
          )?.id;
          const lastSelectedIndex = latestLayout.findIndex((item) => item.id === selectedSpotServerId);
          const nextSpot = latestLayout.find((item, i) => i > lastSelectedIndex && !item.url)?.id ?? null;

          onCaptureSequenceStatusChanged?.("complete");

          if (nextCaptureSpotIdRef.current !== null) {
            handleNewCaptureSpotId(nextSpot);
          }
        }

        setPendingImages(updatedPendingImages);
      },
    };
  }, [
    nextCaptureSpot,
    isFirstCaptureSession,
    sandboxImages,
    retakingImageId,
    handleLayoutItemClicked,
    handleOrderImageOnTop,
    reserveImageSpot,
    handleNewCaptureSpotId,
    updateReservedSpot,
    pendingImagesRef,
    setPendingImages,
    imageMount,
    mountLayoutConfig,
    stackingOrder,
    isViewingArchived,
    onCaptureSequenceStatusChanged,
    nextCaptureSpotIdRef,
  ]);
};

export type UseMountLayout = ReturnType<typeof useMountLayout>;
