import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PatientVO } from "@libs/api/generated-api";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ButtonLink } from "@libs/components/UI/ButtonLink";
import { useAccount } from "@libs/contexts/AccountContext";
import { Modal } from "@libs/components/UI/Modal";
import { Banner } from "@libs/components/UI/Banner";
import { ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import { fetchPatientDosespotSingleSignOnUri } from "api/erx/mutations";
import { usePathParams } from "hooks/usePathParams";
import { handleError } from "utils/handleError";
import { getPatientQuery } from "api/patients/queries";
import { LoadingOverlay } from "components/UI/LoadingOverlay";
import { useItemModal } from "hooks/useItemModal";

import { paths } from "utils/routing/paths";
import { DoseSpotPanel } from "components/Erx/DoseSpotPanel";
import { PatientAppHistoryProvider } from "contexts/PatientLinksContext";

const FIRST_NAME = "First Name";
const LAST_NAME = "Last Name";
const DATE_OF_BIRTH = "Date Of Birth";
const GENDER = "Gender";
const ADDRESS = "Address";
const PHONE = "Phone";
const WEIGHT = "Weight";
const HEIGHT = "Height";
const WEIGHT_AND_HEIGHT_REQUIRED_AT_AGE_UNDER = 19;

// eslint-disable-next-line complexity
const getMissingPatientInfo = (patientData: PatientVO) => {
  return [
    ...(patientData.personalDetails.firstName ? [] : [FIRST_NAME]),
    ...(patientData.personalDetails.lastName ? [] : [LAST_NAME]),
    ...(patientData.personalDetails.dob ? [] : [DATE_OF_BIRTH]),
    ...(patientData.personalDetails.gender ? [] : [GENDER]),
    ...(patientData.contactDetails.addressDetails ? [] : [ADDRESS]),
    ...(patientData.contactDetails.phone ? [] : [PHONE]),
    ...(patientData.personalDetails.weightInPounds === undefined &&
    (patientData.personalDetails.age ?? 0) < WEIGHT_AND_HEIGHT_REQUIRED_AT_AGE_UNDER
      ? [WEIGHT]
      : []),
    ...(patientData.personalDetails.heightInInches === undefined &&
    (patientData.personalDetails.age ?? 0) < WEIGHT_AND_HEIGHT_REQUIRED_AT_AGE_UNDER
      ? [HEIGHT]
      : []),
  ];
};

export const PatientErxRoute: FC = () => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const { patientId } = usePathParams("patientTab");
  const [{ mutate: getSsoUri, isLoading: isLoadingIframeUrl }] = useApiMutations([
    fetchPatientDosespotSingleSignOnUri,
  ]);
  const [iframeUrl, setIframeUrl] = useState<string>();
  const missingPatientInfoModal = useItemModal<string[]>(null);

  const [{ data: patientData, isLoading: isLoadingPatient }] = useApiQueries([
    getPatientQuery({ args: { patientId, practiceId } }),
  ]);

  const missingInfo = useMemo(() => {
    if (!patientData) {
      return undefined;
    }

    return getMissingPatientInfo(patientData);
  }, [patientData]);

  if (!isLoadingPatient && missingPatientInfoModal.isClosed && missingInfo?.length) {
    missingPatientInfoModal.open(missingInfo);
  }

  const getIframeUrl = useCallback(() => {
    getSsoUri(
      { practiceId, patientId },
      {
        onSuccess: (data) => setIframeUrl(data.data.data.dosespotSingleSignOnUri),
        onError: handleError,
      }
    );
  }, [getSsoUri, patientId, practiceId]);

  const loadIframeUrl = useCallback(
    () => !isLoadingPatient && missingInfo?.length === 0 && getIframeUrl(),
    [getIframeUrl, isLoadingPatient, missingInfo]
  );

  useEffect(() => {
    loadIframeUrl();
  }, [loadIframeUrl]);

  return (
    <PatientAppHistoryProvider name="erx" patientId={patientId}>
      <DoseSpotPanel>
        {missingPatientInfoModal.isOpen && (
          <Modal title="Verify Patient Details" size="xs" centerVertically={false} portal={false}>
            <Banner theme="error" className="text-sm">
              Verify/Update all the required information for {patientData?.personalDetails.fullName}
            </Banner>
            <ModalContent padding="lg" className="text-sm space-y-3">
              <ul className="list-disc list-inside">
                <li>The following information is required to connect a patient to DoseSpot</li>
              </ul>
              <ul className="text-red list-none list-inside">
                {missingPatientInfoModal.item.map((missing) => (
                  <li key={missing}>- {missing}</li>
                ))}
              </ul>
              <ul className="list-disc list-inside">
                <li>If the problem persists, please contact Support.</li>
              </ul>
            </ModalContent>
            <ModalFooter>
              <ButtonLink
                className="min-w-button"
                onClick={() => {
                  const url = paths.patient({ patientId });

                  navigate(url);
                }}
              >
                Add Info
              </ButtonLink>
            </ModalFooter>
          </Modal>
        )}
        <LoadingOverlay
          isLoading={isLoadingPatient || isLoadingIframeUrl}
          loadingText="Loading patient to DoseSpot..."
        >
          <div className="w-full h-full">
            {iframeUrl && (
              <iframe
                title="DoseSpot"
                src={iframeUrl}
                className={`
                  origin-[0_0]
                  scale-75
                  w-[calc(100%/0.75)]
                  h-[calc(100%/0.75)]
                `}
              />
            )}
          </div>
        </LoadingOverlay>
      </DoseSpotPanel>
    </PatientAppHistoryProvider>
  );
};
