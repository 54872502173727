import React from "react";
import { PatientWalletVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { formatISODate } from "@libs/utils/date";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Row } from "@libs/components/UI/GridTableComponents";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";
import { paths } from "utils/routing/paths";

type Props = {
  row: PatientWalletVO;
  last?: boolean;
};

export const PatientWalletTableRow: React.FC<Props> = ({ row, last }) => {
  const { patient, walletBalance, lastActivityDate, patientBalance } = row;
  const patientBillingUrl = paths.patientBilling({ patientId: patient.id });
  const cellProps = { last, to: patientBillingUrl };

  return (
    <Row>
      <DashboardLinkCell {...cellProps}>
        <FloatingTooltip content="View Patient Wallet" theme="SMALL">
          <div className="hover:text-primaryTheme">{patient.shortDisplayName}</div>
        </FloatingTooltip>
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{formatCurrency(walletBalance)}</DashboardLinkCell>

      <DashboardLinkCell {...cellProps}>{formatISODate(lastActivityDate, "MM/dd/yyyy")}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{formatCurrency(patientBalance)}</DashboardLinkCell>
    </Row>
  );
};
