import { ReactNode } from "react";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";

interface Props {
  includeDivider?: boolean;
  onClose: () => void;
  title: string;
  titleDetails?: string[];
  children?: ReactNode;
}

export const ClaimModalHeader: React.FC<Props> = ({
  children,
  includeDivider = false,
  onClose,
  title,
  titleDetails,
  ...props
}) => (
  <div
    className={`
      flex
      flex-none
      justify-between
      h-[72px]
      px-8
      border-b
      border-greyLighter
    `}
    {...props}
  >
    <div className="flex-1 flex items-center">
      <div className="flex items-center font-sansSemiBold">{title}</div>
      {titleDetails?.map((detail, index) => (
        <div className="flex items-center text-sm" key={index}>
          <VerticalDivider className="mx-3" size="sm" />
          {detail}
        </div>
      ))}
      <div className="flex-1 ml-6">{children}</div>
    </div>
    <div className="flex items-center">
      {includeDivider && <VerticalDivider className="mx-6" size="sm" />}
      <ButtonIcon SvgIcon={CancelIcon} onClick={onClose} size="lg" theme="primary" />
    </div>
  </div>
);
