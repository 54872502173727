import { FC, useMemo } from "react";

import { CustomPatientFolderVO } from "@libs/api/generated-api";

import { TableGrid, HeaderCell } from "@libs/components/UI/GridTableComponents";
import { PatientFolderRow } from "components/Settings/PracticeSetup/Sections/PatientFolders/PatientFolderRow";
import { PatientFolder } from "components/Settings/PracticeSetup/Sections/PatientFolders/types";

const tableHeaders = [
  { id: "name", label: "Name", width: "minmax(max-content, 1fr)" },
  { id: "menu", width: "3.25rem" },
];

interface Props {
  customFolders: CustomPatientFolderVO[];
  defaultFolders: string[];
  onEditFolder: (patientFolder: PatientFolder) => void;
  onDeleteFolder: (customPatientFolderId: number) => void;
}

export const PatientFoldersTable: FC<Props> = ({
  customFolders,
  defaultFolders,
  onEditFolder,
  onDeleteFolder,
}) => {
  const patientFolders: PatientFolder[] = useMemo(() => {
    const customPatientFolders = customFolders.map((folder) => ({ ...folder, isDefault: false }));
    const defaultPatientFolders = defaultFolders.map((folderName, index) => ({
      id: index,
      name: folderName,
      isDefault: true,
    }));

    return [...customPatientFolders, ...defaultPatientFolders].sort((a, b) => a.name.localeCompare(b.name));
  }, [customFolders, defaultFolders]);

  return (
    <TableGrid columnWidths={tableHeaders.map((header) => header.width)}>
      {tableHeaders.map((header) => (
        <HeaderCell key={header.id} borderColor="border-slate-200" size="medium">
          {header.label}
        </HeaderCell>
      ))}

      {patientFolders.map((folder) => (
        <PatientFolderRow
          key={`${folder.id}_${folder.name}`}
          folder={folder}
          onEditFolder={onEditFolder}
          onDeleteFolder={onDeleteFolder}
        />
      ))}
    </TableGrid>
  );
};
