import React from "react";
import { ClaimVO, PatientInsuranceResponse, ProviderVO } from "@libs/api/generated-api";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { ClaimField } from "components/Claims/types";
import { getProviderOptions } from "components/Claims/Claims/FilterMenuProviders";
import { getInsuranceLabel } from "components/Insurance/utils";
import { isDraftClaim, isPreAuthClaim } from "components/Claims/utils";

interface Props {
  claim: ClaimVO;
  onSelect: (field: ClaimField, val: number) => void;
  patientInsurances: PatientInsuranceResponse[];
  practiceBillingProviders: ProviderVO[] | undefined;
  practiceProviders: ProviderVO[] | undefined;
  selectedOptions: Record<ClaimField, number | undefined>;
}

export const ClaimFields: React.FC<Props> = ({
  claim,
  onSelect,
  patientInsurances,
  practiceBillingProviders,
  practiceProviders,
  selectedOptions,
}) => {
  const canEdit = isDraftClaim(claim);
  const isPreAuth = isPreAuthClaim(claim);

  return (
    <div className="flex gap-x-3">
      {isPreAuth && (
        <>
          <FormFieldSelect
            className="flex-none w-80"
            display="label"
            edit={canEdit}
            label="Insurance Order"
            layout="labelIn"
            onItemSelected={(val) => onSelect("insuranceOrdinal", val)}
            options={[
              { label: "Primary", value: 0 },
              { label: "Secondary", value: 1 },
              { label: "Other", value: 2 },
            ]}
            placeholder="Select option"
            required={true}
            value={selectedOptions.insuranceOrdinal}
          />
          <FormFieldSelect
            className="flex-none w-80"
            display="label"
            edit={canEdit}
            label="Insurance Carrier &amp; Plan Type"
            layout="labelIn"
            onItemSelected={(val) => onSelect("patientInsuranceId", val)}
            options={getInsuranceOptions(claim.patientInsuranceId, patientInsurances)}
            placeholder="Select option"
            required={true}
            value={selectedOptions.patientInsuranceId}
          />
        </>
      )}

      <FormFieldSelect
        className="flex-none w-80"
        display="label"
        edit={canEdit && isPreAuth}
        label="Billing Provider"
        layout="labelIn"
        onItemSelected={(val) => onSelect("billingProviderId", val)}
        options={getProviderOptions(practiceBillingProviders)}
        placeholder="Select option"
        required={true}
        value={selectedOptions.billingProviderId}
      />
      <FormFieldSelect
        className="flex-none w-80"
        display="label"
        edit={canEdit}
        label="Treating Provider"
        layout="labelIn"
        onItemSelected={(val) => onSelect("treatingProviderId", val)}
        options={getProviderOptions(
          practiceProviders?.filter((provider) => provider.jobCategory === "DENTIST")
        )}
        placeholder="Select option"
        required={true}
        value={selectedOptions.treatingProviderId}
      />
    </div>
  );
};

export const getInsuranceOptions = (
  currentInsuranceId: number,
  patientInsurances: PatientInsuranceResponse[]
) => {
  return (
    patientInsurances
      .filter(
        (insurance) =>
          insurance.patientInsurance.insuranceState === "ACTIVE" ||
          insurance.patientInsurance.id === currentInsuranceId
      )
      // Sort alphabetically so that PRIMARY, SECONDARY, TERTIARY are in that order.
      .sort((a, b) =>
        a.patientInsurance.insuranceOrdinal > b.patientInsurance.insuranceOrdinal
          ? 1
          : a.patientInsurance.insuranceOrdinal < b.patientInsurance.insuranceOrdinal
            ? -1
            : 0
      )
      .map((insurance) => {
        return {
          label: getInsuranceLabel(insurance),
          value: insurance.patientInsurance.id,
        };
      })
  );
};
