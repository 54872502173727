import { HeaderData } from "components/Dashboard/Tables/types";

export const APPT_BOOKING_COLUMNS: HeaderData[] = [
  { label: "Patient", width: "1fr", sortField: "patientLastName" },
  { label: "Appt Date", width: "8rem", sortField: "appointmentDate" },
  { label: "Appt Status", width: "1fr", sortField: "appointmentState" },
  { label: "Duration (min)", width: "1fr", sortField: "appointmentDuration" },
  { label: "Provider", width: "1fr" },
  {
    label: "Procedures",
    width: "minmax(12rem,15rem)",
  },
  {
    label: "Next Appt",
    width: "12rem",
  },
  { label: "", width: "3.5rem" },
];
