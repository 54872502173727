import { FC, ReactNode } from "react";

import { ItemsCount } from "@libs/components/UI/ItemsCount";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";

export const DashboardBulkActionsRow: FC<{
  actions: ReactNode;
  selectedCount: number;
  total: number;
  singular: string;
  plural: string;
  filters?: ReactNode;
}> = ({ actions, selectedCount, total, singular, plural, filters }) => {
  return (
    <div
      className={`
        flex
        items-center
        gap-x-2
        border-t
        border-greyLighter
        bg-white
        px-5
        py-3
      `}
    >
      {actions}

      {selectedCount > 0 ? (
        <ItemsCount selectedCount={selectedCount} total={total} singular={singular} plural={plural} />
      ) : null}

      {selectedCount > 0 && filters ? <VerticalDivider size="sm" /> : null}

      {filters}
    </div>
  );
};
