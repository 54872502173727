import { format, intervalToDuration, isSameDay } from "date-fns";
import { getLocalDate } from "@libs/utils/date";
import { DATE_FUNCTIONS_BY_RESOLUTION } from "components/Dashboard/utils/timeSeriesDates";
import { TimeSegment, TimeSeriesResolutionOption } from "utils/routing/dashboard";

const getLabelForIncompletePeriod = ({
  date,
  dateWindow,
  resolution,
  dateFormat = "MMM do",
}: {
  date: Date;
  dateWindow: TimeSegment;
  resolution: Exclude<TimeSeriesResolutionOption, "DAY">;
  dateFormat?: string;
}) => {
  const { startOfPeriod, endOfPeriod, isSamePeriod } = DATE_FUNCTIONS_BY_RESOLUTION[resolution];
  const periodStart = startOfPeriod(date);
  const periodEnd = endOfPeriod(date);
  let rangeStart = `${format(periodStart, dateFormat)}`;
  let rangeEnd = `${format(periodEnd, dateFormat)}`;
  let isIncomplete = false;

  if (isSamePeriod(dateWindow.startDate, date) && !isSameDay(periodStart, dateWindow.startDate)) {
    rangeStart = `${format(dateWindow.startDate, dateFormat)}`;
    isIncomplete = true;
  }

  if (isSamePeriod(dateWindow.endDate, date) && !isSameDay(periodEnd, dateWindow.endDate)) {
    rangeEnd = `${format(dateWindow.endDate, dateFormat)}`;
    isIncomplete = true;
  }

  if (isIncomplete) {
    return `${rangeStart} - ${rangeEnd}`;
  }

  return undefined;
};

// eslint-disable-next-line complexity, max-statements
export const getTooltipLabel = ({
  dateString,
  dateWindow,
  resolution,
}: {
  dateString: string;
  dateWindow: TimeSegment;
  resolution: TimeSeriesResolutionOption;
}) => {
  const date = getLocalDate(dateString);
  const duration = intervalToDuration({
    start: dateWindow.startDate,
    end: dateWindow.endDate,
  });

  const isOverAYear = duration.years && duration.years > 0;

  switch (resolution) {
    case "DAY": {
      const formatting = isOverAYear ? "MMM do, yyyy" : "MMM do";

      // Smallest unit, can't be sliced
      return format(date, formatting);
    }
    case "WEEK": {
      const incompletePeriodLabel = getLabelForIncompletePeriod({
        resolution,
        date,
        dateWindow,
      });

      if (incompletePeriodLabel) {
        return incompletePeriodLabel;
      }

      const formatting = isOverAYear ? "M/d, yyyy" : "M/d";

      return `Week of ${format(date, formatting)}`;
    }
    case "MONTH": {
      const incompletePeriodLabel = getLabelForIncompletePeriod({
        resolution,
        date,
        dateWindow,
      });

      if (incompletePeriodLabel) {
        return incompletePeriodLabel;
      }

      return `${format(date, "MMM, yyyy")}`;
    }
    case "QUARTER": {
      const incompletePeriodLabel = getLabelForIncompletePeriod({
        resolution,
        date,
        dateWindow,
      });

      if (incompletePeriodLabel) {
        return incompletePeriodLabel;
      }

      const formatting = isOverAYear ? "QQQ, yyyy" : "QQQ";

      return format(date, formatting);
    }
    case "YEAR": {
      const incompletePeriodLabel = getLabelForIncompletePeriod({
        resolution,
        date,
        dateWindow,
        dateFormat: "MMM do, yyyy",
      });

      if (incompletePeriodLabel) {
        return incompletePeriodLabel;
      }

      return format(date, "yyyy");
    }
    // No default
  }

  return dateString;
};
