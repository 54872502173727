import React, { FC, useCallback } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useAccount } from "@libs/contexts/AccountContext";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { NewVsExistingChart } from "components/Dashboard/Charting/NewVsExistingChart";
import { DashboardLayout } from "components/Dashboard/DashboardLayout";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";

import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { AppointmentDrilldownType, PracticeProductionQuery } from "utils/routing/dashboard";
import { DashboardPageHeader } from "components/Dashboard/DashboardPageHeader";
import { getAppointmentBookingInfiniteQuery, getTimeSeriesQuery } from "api/reporting/queries";
import { dashboardFiltersForCharting, dashboardFiltersForTables } from "components/Dashboard/utils/filters";
import { AppointmentBookingTable } from "components/Dashboard/Tables/AppointmentBooking/AppointmentBookingTable";
import { useFormattedProviderRollups } from "components/Dashboard/hooks/useFormattedProviderRollups";
import { useProviderRollupQueries } from "components/Dashboard/hooks/useProviderRollupQueries";
import { FilterByProvider } from "components/Dashboard/Charting/FilterByProvider";
import { useProviderFilterHandler } from "components/Dashboard/PracticeProduction/hooks/useProviderFilterHandler";
import { useDownloadAppointmentBookingCSV } from "components/Dashboard/Tables/AppointmentBooking/hooks/useDownloadAppointmentBookingCSV";
import { getDentalProceduresQuery } from "api/charting/queries";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";
import { MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";
import { useFilteredProviderCategory } from "components/Dashboard/hooks/useFilteredProviderCategory";
import { PermissionAction } from "components/Roles/constants";
import { RoleGuardLock } from "components/Main/RoleGuard";

const pathToDentalProcedureIds: Record<AppointmentDrilldownType, string[]> = {
  hygiene: ["96", "97"],
  comprehensive: ["3", "4"],
  emergency: ["2"],
};

const apptTypeToRoleGuardAction: Record<AppointmentDrilldownType, PermissionAction> = {
  hygiene: "ACCESS_HYGIENE",
  comprehensive: "ACCESS_OPERATIONAL",
  emergency: "ACCESS_OPERATIONAL",
};

export const AppointmentDrilldownRoute: FC = () => {
  const { practiceId } = useAccount();
  const { query, updateQuery } = useQueryParams("dashboardAppointmentDrilldown");
  const { apptType } = usePathParams("dashboardAppointmentDrilldown");

  const handleRouteStateChange = useCallback(
    (updates: Partial<PracticeProductionQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );

  const dateWindow = useTimeSeriesPageSelections(query);
  const { selectedTimeSegment, timeSeriesParams, selectedTimeSegmentParams } = dateWindow;
  const requestParams = {
    resolution: query.resolution,
  };
  const handleProviderFilterClicked = useProviderFilterHandler({
    filters: query.filters,
    onUpdateFilters: handleRouteStateChange,
  });
  const targetProcedureIdsForReport = React.useMemo(() => {
    return pathToDentalProcedureIds[apptType];
  }, [apptType]);

  // These filters represent the target data we're looking at with no user filters applied on top of that
  const baseTargetingFilters: DashboardFilter[] = React.useMemo(() => {
    return [
      {
        type: "appointmentState",
        values: ["COMPLETED"],
      },
      {
        type: "dentalProcedureId",
        values: targetProcedureIdsForReport,
      },
    ];
  }, [targetProcedureIdsForReport]);

  const timeSeriesFilters: DashboardFilter[] = React.useMemo(() => {
    const providerFilters = dashboardFiltersForCharting(query.filters);

    return [
      ...providerFilters,
      {
        type: "appointmentState",
        values: ["COMPLETED"],
      },
      {
        type: "dentalProcedureId",
        values: targetProcedureIdsForReport,
      },
    ];
  }, [query.filters, targetProcedureIdsForReport]);
  const tableFilters: DashboardFilter[] = React.useMemo(() => {
    return [...query.filters, ...baseTargetingFilters];
  }, [query.filters, baseTargetingFilters]);
  const { search: debouncedSearch } = useDebouncedSearch(query.patientSearch ?? "");

  const appointmentBookingInfiniteQuery = useInfiniteApiQuery(
    getAppointmentBookingInfiniteQuery({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: MAX_PAGE_SIZE,
        data: {
          ...requestParams,
          ...selectedTimeSegmentParams,
          sortOrders: query.tableSort,
          filters: dashboardFiltersForTables(tableFilters),
          includeTotal: true,

          patientSearchString: debouncedSearch,
          includeNextAppointmentDentalProcedureIds: targetProcedureIdsForReport.map((id) =>
            Number.parseInt(id, 10)
          ),
        },
      },
    })
  );
  const { providersInTimeWindowQuery, allProvidersQuery } = useProviderRollupQueries({
    metric: "appointmentCount",
    dateWindow,
    filters: baseTargetingFilters,
  });
  const [
    patientCountQuery,
    allDentalProceduresQuery,
    providersInTimeWindowQueryResult,
    allProvidersQueryResult,
  ] = useApiQueries([
    getTimeSeriesQuery({
      args: {
        practiceId,
        data: {
          ...requestParams,
          ...timeSeriesParams,
          filters: timeSeriesFilters,
          metric: "appointmentCount",
          groupBy: "patientType",
        },
      },
    }),
    getDentalProceduresQuery({ args: { practiceId } }),

    providersInTimeWindowQuery,
    allProvidersQuery,
  ]);

  const providerFilters = useFilteredProviderCategory(query.filters);

  const {
    data: providerFilterOptions,
    isLoading: isLoadingProviderRollups,
    selectedProvider,
  } = useFormattedProviderRollups(providersInTimeWindowQueryResult, allProvidersQueryResult, {
    ...providerFilters,
  });
  const { isDownloading, downloadCSV } = useDownloadAppointmentBookingCSV({
    appointmentBookingInfiniteQuery,
    selectedTimeSegment,
  });

  return (
    <RoleGuardLock domain="REPORTING" action={apptTypeToRoleGuardAction[apptType]}>
      <DashboardLayout
        className="min-w-[1400px]"
        header={
          <>
            <DashboardPageHeader
              question="appointments"
              focusDate={query.focusDate}
              resolution={query.resolution}
              apptType={apptType}
              dateWindow={dateWindow}
              chartDisplay={query.chartDisplay}
              onUpdateParams={handleRouteStateChange}
            />
            {!query["table.fullScreen"] && (
              <NewVsExistingChart
                dateWindow={dateWindow}
                chartDisplay={query.chartDisplay}
                resolution={query.resolution}
                timeSeries={patientCountQuery.data}
                isLoading={patientCountQuery.isLoading}
                onUpdateParams={handleRouteStateChange}
                focusDate={query.focusDate}
              >
                <FilterByProvider
                  loadFailed={Boolean(
                    allProvidersQueryResult.error || providersInTimeWindowQueryResult.error
                  )}
                  providerFilterOptions={providerFilterOptions}
                  providerFilters={providerFilters}
                  isLoading={isLoadingProviderRollups}
                  onProviderClicked={handleProviderFilterClicked}
                />
              </NewVsExistingChart>
            )}
          </>
        }
      >
        <AppointmentBookingTable
          query={query}
          allDentalProceduresQuery={allDentalProceduresQuery}
          filtersAvailable={["patientType", "appointmentState", "hasNextAppointment"]}
          debouncedSearch={debouncedSearch}
          selectedProvider={selectedProvider}
          providerFilterOptions={providerFilterOptions}
          onUpdateParams={handleRouteStateChange}
          tableExpanded={query["table.fullScreen"]}
          onClickDownload={downloadCSV}
          appointmentBookingInfiniteQuery={appointmentBookingInfiniteQuery}
          isDownloadingTable={isDownloading}
        />
      </DashboardLayout>
    </RoleGuardLock>
  );
};
