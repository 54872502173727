import { PatientToothVO } from "@libs/api/generated-api";

export type ToothChartSelections =
  | { type: "ARCH"; value: Set<PatientToothVO["arch"]> }
  | { type: "QUADRANT"; value: Set<PatientToothVO["quadrant"]> }
  | { type: "TOOTH"; value: Set<number> }
  | { type: "NONE" };

export const filterUnnamedTeeth = (
  selections: ToothChartSelections,
  teeth: PatientToothVO[]
): ToothChartSelections => {
  if (selections.type === "TOOTH") {
    const toothSelectionsWithNames = new Set(
      [...selections.value].filter(
        (toothNumber) => teeth.find((tooth) => tooth.toothNum === toothNumber)?.toothName
      )
    );

    return toothSelectionsWithNames.size > 0
      ? {
          type: "TOOTH",
          value: toothSelectionsWithNames,
        }
      : { type: "NONE" };
  }

  return selections;
};
