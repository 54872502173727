import { useCallback, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { produce } from "immer";
import {
  BenefitLimitationVO,
  FrequencyLimitVO,
  GroupedBenefitLimitationsVO,
  ShortcutBenefitLimitationVO,
} from "@libs/api/generated-api";
import { SEARCH_DEBOUNCE_DELAY_MS } from "@libs/utils/constants";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";
import { cdtCodeToString } from "utils/cdtCode";

type LimitationsFields = {
  [K in keyof BenefitLimitationVO]?: BenefitLimitationVO[K];
};

export type BenefitLimitationFormItem = Omit<LimitationsFields, "frequency"> & {
  frequency?: Partial<BenefitLimitationVO["frequency"]>;
  display?: boolean;
};

export const useLimitationsFields = (groupedLimitations?: GroupedBenefitLimitationsVO) => {
  const preferredLimitations = groupedLimitations?.shortcutLimitations;
  const savedLimitations: BenefitLimitationFormItem[] | undefined =
    groupedLimitations?.completeBenefitLimitations;

  const [limitationItems, setLimitationItems] = useState(savedLimitations ?? []);
  const [preferredLimitationItems, setPreferredLimitationsItems] = useState(preferredLimitations ?? []);

  useSyncOnce(setLimitationItems, savedLimitations);
  useSyncOnce(setPreferredLimitationsItems, preferredLimitations);

  const handleCancelLimitationChange = useCallback(
    (limitations: BenefitLimitationFormItem[], prefLimitations: ShortcutBenefitLimitationVO[]) => {
      setLimitationItems(limitations);
      setPreferredLimitationsItems(prefLimitations);
    },
    []
  );

  const handleAddLimitation = useCallback(() => {
    setLimitationItems((last) => {
      return [...last, { display: true }];
    });
  }, []);

  const handleUpdateLimitationsList = useCallback((updated: ShortcutBenefitLimitationVO) => {
    setLimitationItems((last) =>
      produce(last, (draft) => {
        const index = draft.findIndex(
          // eslint-disable-next-line max-nested-callbacks
          (limitation) =>
            (updated.uuid ? limitation.uuid === updated.uuid : false) ||
            limitation.serviceName === updated.serviceName
        );

        if (index === -1) {
          draft.push(updated);
        } else {
          draft.splice(index, 1, updated);
        }
      })
    );
  }, []);

  const handePreferredLimitationItemChange = useCallback(
    (index: number, updated: ShortcutBenefitLimitationVO) => {
      handleUpdateLimitationsList(updated);
      setPreferredLimitationsItems((last) =>
        produce(last, (draft) => {
          draft.splice(index, 1, updated);
        })
      );
    },
    [handleUpdateLimitationsList]
  );

  const handleUpdatePreferredLimitationList = useCallback(
    (isDeleting: boolean, updated: BenefitLimitationFormItem) => {
      setPreferredLimitationsItems((last) =>
        produce(last, (draft) => {
          const foundLimitationIndex = findLimitationIndex(draft, updated);

          if (foundLimitationIndex > -1) {
            const updatedShortcut = getUpdatedShortcut(isDeleting, draft[foundLimitationIndex], updated);

            draft.splice(foundLimitationIndex, 1, updatedShortcut);
          }
        })
      );
    },
    []
  );

  const handleLimitationChange = useCallback(
    (index: number, updated: BenefitLimitationFormItem) => {
      handleUpdatePreferredLimitationList(false, updated);

      setLimitationItems((last) =>
        produce(last, (draft) => {
          draft.splice(index, 1, updated);
        })
      );
    },
    [handleUpdatePreferredLimitationList]
  );

  const handleLimitationDelete = useCallback(
    (index: number, limitation: BenefitLimitationFormItem) => {
      handleUpdatePreferredLimitationList(true, limitation);
      setLimitationItems((last) => {
        return last.filter((_, i) => i !== index);
      });
    },
    [handleUpdatePreferredLimitationList]
  );

  const [searchString, setSearchString] = useState("");

  const displayLimitation = (searchValue: string, limitation: BenefitLimitationFormItem) => {
    if (!limitation.uuid) {
      return true;
    }

    if (limitation.frequencyLimitationCategory) {
      for (const procedure of limitation.frequencyLimitationCategory.dentalProcedures.values()) {
        if (procedure.cdtCode.includes(searchValue)) {
          return true;
        }
      }
    }

    if (limitation.startCdtCodeRange && limitation.endCdtCodeRange) {
      if (limitation.startCdtCodeRange === limitation.endCdtCodeRange) {
        return cdtCodeToString(limitation.startCdtCodeRange)?.includes(searchValue);
      }

      const numCodesIncluded = limitation.endCdtCodeRange - limitation.startCdtCodeRange + 1;
      const cdtCodes = Array.from(
        { length: numCodesIncluded },
        (_, i) => i + (limitation.startCdtCodeRange ?? 0)
      );

      for (const code of cdtCodes) {
        if (code.toString().includes(searchValue)) {
          return true;
        }
      }
    }

    return false;
  };

  const updateSearch = useDebouncedCallback((value: string) => {
    setLimitationItems((last) => {
      const filtered = last.map((limitation) => {
        return { ...limitation, display: displayLimitation(value, limitation) };
      });

      return filtered;
    });
  }, SEARCH_DEBOUNCE_DELAY_MS);

  const handleSearch = useCallback(
    (value: string) => {
      setSearchString(value);
      updateSearch(value);
    },
    [updateSearch]
  );

  return {
    handleAddLimitation,
    handleCancelLimitationChange,
    handleLimitationChange,
    handleLimitationDelete,
    handePreferredLimitationItemChange,
    handleSearch,
    handleUpdateAllLimitations: setLimitationItems,
    limitationItems,
    preferredLimitationItems,
    searchString,
  };
};

// Determine if the updated benefit item is for a category that has only
// one dental procedure so that we can sync the shortcut benefit limtitation
const isSingularProcedureForCategory = (
  limitation: ShortcutBenefitLimitationVO,
  updated: BenefitLimitationFormItem
) => {
  // Can only be true if start and end CDT code ranges exist and are equal to each other
  if (updated.startCdtCodeRange !== updated.endCdtCodeRange) {
    return false;
  }

  // Can only be true if there is only one dental procedure
  if (limitation.frequencyLimitationCategory.dentalProcedures.length !== 1) {
    return false;
  }

  return (
    limitation.frequencyLimitationCategory.dentalProcedures[0].cdtCode ===
    cdtCodeToString(updated.startCdtCodeRange)
  );
};

const findLimitationIndex = (
  limitations: ShortcutBenefitLimitationVO[],
  updated: BenefitLimitationFormItem
) => {
  return limitations.findIndex((limitation) => {
    return (
      ((!limitation.uuid && !updated.uuid) || limitation.uuid === updated.uuid) &&
      (limitation.frequencyLimitationCategory.id === updated.frequencyLimitationCategory?.id ||
        isSingularProcedureForCategory(limitation, updated))
    );
  });
};

const getUpdatedShortcut = (
  isDeleting: boolean,
  shortcutLimitation: ShortcutBenefitLimitationVO,
  updated: BenefitLimitationFormItem
) => {
  const updatedShortcut: ShortcutBenefitLimitationVO = isDeleting
    ? {
        ageMax: undefined,
        ageMin: undefined,
        autoEligibility: false,
        frequency: undefined,
        frequencyLimitationCategory:
          updated.frequencyLimitationCategory ?? shortcutLimitation.frequencyLimitationCategory,
        serviceName: shortcutLimitation.serviceName,
        uuid: undefined,
      }
    : {
        ageMax: updated.ageMax ?? shortcutLimitation.ageMax,
        ageMin: updated.ageMin ?? shortcutLimitation.ageMin,
        autoEligibility: updated.autoEligibility ?? shortcutLimitation.autoEligibility,
        frequency: (updated.frequency ?? shortcutLimitation.frequency) as FrequencyLimitVO,
        frequencyLimitationCategory:
          updated.frequencyLimitationCategory ?? shortcutLimitation.frequencyLimitationCategory,
        serviceName: shortcutLimitation.serviceName,
        uuid: updated.uuid ?? shortcutLimitation.uuid,
      };

  return updatedShortcut;
};
