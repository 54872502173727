import {
  ComponentPropsWithRef,
  FC,
  Fragment,
  PropsWithChildren,
  ReactNode,
  forwardRef,
  useEffect,
  useMemo,
} from "react";
import Skeleton from "react-loading-skeleton";
import { FamilyPaymentAllocationVO, InvoiceNumberVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { useFullUrl } from "@libs/utils/location";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { flattenPages } from "@libs/utils/queries";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useAccount } from "@libs/contexts/AccountContext";
import { LayoutCard } from "@libs/components/UI/LayoutCard";

import { QueryResult } from "@libs/components/UI/QueryResult";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { usePathParams } from "hooks/usePathParams";
import { Col, Row } from "components/PatientProfile/Billing/Ledger/LedgerComponents";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import { listPatientPaymentSummaries } from "api/billing/queries";
import { getPaymentMethodToLabel, isFamilyPayment } from "components/PatientProfile/Billing/billingUtils";
import { useExpandedLedgerItemsStorage } from "storage/billing";

import { PatientBillingAppHistoryProvider } from "components/PatientProfile/Billing/PatientBillingLinksContex";
import { LoadingMoreIndicator } from "components/PatientProfile/Billing/Ledger/LoadingMoreIndicator";
import { getFirstAndLastName, getInitials } from "utils/names";
import { CharacterIcon } from "components/UI/CharacterIcon";
import { paths } from "utils/routing/paths";
import { PaymentMenu } from "./PaymentMenu";
import { Text } from "./Text";
import { LedgerTabsRow } from "./LedgerTabsRow";

export const PaymentsPage: FC = () => {
  const { practiceId } = useAccount();
  const { patientId } = usePathParams("patientPayments");
  const paymentSummariesQuery = useInfiniteApiQuery(
    listPatientPaymentSummaries({
      args: {
        patientId,
        practiceId,
        pageNumber: 1,
        pageSize: 20,
      },
    })
  );

  const { scrollRef, rootRef } = useInfiniteScrollQuery({
    infiniteQuery: paymentSummariesQuery,
  });
  const paymentItems = flattenPages(paymentSummariesQuery.data);

  const { clearLedgerExpandables } = useExpandedLedgerItemsStorage();

  useEffect(() => {
    if (paymentSummariesQuery.isInitialLoading) {
      clearLedgerExpandables();
    }
  }, [clearLedgerExpandables, paymentSummariesQuery.isInitialLoading]);

  return (
    <PatientBillingAppHistoryProvider name="patientPayments">
      <LayoutCard className="relative flex flex-col w-full divide-y">
        <LedgerTabsRow patientId={patientId} practiceId={practiceId} selected="Payments" />
        <Row className="pl-6 pr-2 py-3" full={false}>
          <PaymentsHeaders />
        </Row>
        <QueryResult queries={[paymentSummariesQuery]} loading={<LoadingOverlaySpinner />}>
          <PaymentsContainer ref={rootRef}>
            {paymentItems?.map((payment, i) => {
              return (
                <Fragment key={payment.uuid}>
                  <PaymentItemContainer>
                    <Row>
                      <Columns
                        date={
                          <Text>
                            <DateWithTooltip date={payment.createdAt} dateAsSeconds format="P" />
                          </Text>
                        }
                        paymentMethod={<Text>{getPaymentMethodToLabel(payment)}</Text>}
                        paidBy={<Text truncate>{getFirstAndLastName(payment.paidBy)}</Text>}
                        paymentType={
                          <Text>
                            {isFamilyPayment(payment) ? (
                              <FamilyPayment
                                payerId={payment.paidBy.id}
                                amount={payment.currencyAmount.amount}
                                familyAllocations={payment.familyPaymentAllocations}
                              />
                            ) : (
                              "Individual"
                            )}
                          </Text>
                        }
                        invoices={<InvoicesCell invoices={payment.invoiceNumbers} />}
                        paymentOnAccount={<Text>{formatCurrency(payment.amountAllocated)}</Text>}
                        menu={
                          <PaymentMenu
                            patientId={patientId}
                            paymentUuid={payment.uuid}
                            isFamilyPayment={isFamilyPayment(payment)}
                            canEditPayment={payment.permittedActions.includes("EDIT")}
                            canDeletePayment={payment.permittedActions.includes("DELETE")}
                          />
                        }
                      />
                    </Row>
                  </PaymentItemContainer>
                  {i === paymentItems.length - 1 && paymentSummariesQuery.hasNextPage && (
                    <LoadingMoreIndicator ref={scrollRef}>
                      <LoadingSkeleton />
                    </LoadingMoreIndicator>
                  )}
                </Fragment>
              );
            })}
          </PaymentsContainer>
        </QueryResult>
      </LayoutCard>
    </PatientBillingAppHistoryProvider>
  );
};

const PaymentsContainer: FC<PropsWithChildren<ComponentPropsWithRef<"div">>> = forwardRef(
  ({ children }, ref) => (
    <PersistScrollPosition
      id="ledger-payments-page"
      ref={ref}
      className="overflow-y-auto flex flex-col-reverse"
    >
      {children}
    </PersistScrollPosition>
  )
);

export const PaymentItemContainer: FC<PropsWithChildren> = ({ children }) => (
  <div className="flex pl-6 pr-2 py-2.5 border-b h-10">{children}</div>
);

const Columns: FC<{
  date: ReactNode;
  paymentMethod: ReactNode;
  paidBy: ReactNode;
  paymentType: ReactNode;
  invoices: ReactNode;
  paymentOnAccount: ReactNode;
  menu: ReactNode;
}> = ({ date, paymentMethod, paidBy, paymentType, invoices, paymentOnAccount, menu }) => {
  return (
    <div className="flex flex-1 gap-x-2">
      <Col justify="left" width="lg" align="center">
        {date}
      </Col>
      <Col justify="left" width="4xl" align="center">
        {paymentMethod}
      </Col>
      <Col justify="left" width="xl" align="center">
        {paidBy}
      </Col>
      <Col justify="left" width="full" align="center">
        {paymentType}
      </Col>
      <Col justify="left" width="3xl" align="center">
        {invoices}
      </Col>
      <Col justify="right" width="3xl" align="center">
        {paymentOnAccount}
      </Col>
      <div className="w-3" />
      <Col justify="center" width="xxs" align="center">
        {menu}
      </Col>
    </div>
  );
};

const PaymentsHeaders: FC = () => {
  return (
    <Columns
      date={<Text bold>Date</Text>}
      paymentMethod={<Text bold>Payment Method</Text>}
      paidBy={<Text bold>Paid By</Text>}
      paymentType={<Text bold>Payment Type</Text>}
      invoices={<Text bold>Invoices</Text>}
      paymentOnAccount={
        <Text bold justify="right">
          Payment on this Account
        </Text>
      }
      menu={undefined}
    />
  );
};

const LoadingSkeleton: FC = () => {
  return (
    <PaymentItemContainer>
      <Row full>
        <Columns
          date={<Skeleton containerClassName="w-full pr-2" />}
          paymentMethod={<Skeleton containerClassName="w-full pr-2" />}
          paidBy={<Skeleton containerClassName="w-full pr-2" />}
          paymentType={<Skeleton containerClassName="w-full pr-2" />}
          invoices={<Skeleton containerClassName="w-full pr-2" />}
          paymentOnAccount={<Skeleton containerClassName="w-full" />}
          menu={<Skeleton containerClassName="w-full" />}
        />
      </Row>
    </PaymentItemContainer>
  );
};

const FamilyPayment: FC<{
  payerId: number;
  amount: number;
  familyAllocations: FamilyPaymentAllocationVO[];
}> = ({ payerId, amount, familyAllocations }) => {
  // Show the payer first
  const sortedAllocations = useMemo(
    () => familyAllocations.sort((a) => (a.name.id === payerId ? -1 : 0)),
    [familyAllocations, payerId]
  );

  return (
    <div className="flex items-center gap-x-2">
      <div>Family {formatCurrency(amount)}</div>
      {sortedAllocations.map((allocation) => {
        const isPayer = payerId === allocation.name.id;

        return (
          <FloatingTooltip
            key={allocation.name.id}
            content={
              <FamilyTooltip
                name={getFirstAndLastName(allocation.name)}
                allocatedAmount={allocation.amountAllocated}
                creditAmountGiven={allocation.creditAmountGiven}
                creditAmountReceived={allocation.creditAmountReceived}
                isPayer={isPayer}
              />
            }
          >
            <ButtonInternalLink theme="link" to={paths.patientPayments({ patientId: allocation.name.id })}>
              <CharacterIcon size="sm" theme="slate" border={isPayer ? "green" : "slate50"}>
                <Text size="xxs">{getInitials(allocation.name)}</Text>
              </CharacterIcon>
            </ButtonInternalLink>
          </FloatingTooltip>
        );
      })}
    </div>
  );
};

const FamilyTooltip: FC<{
  name: string;
  allocatedAmount: number;
  creditAmountGiven: number;
  creditAmountReceived: number;
  isPayer: boolean;
}> = ({ name, allocatedAmount, creditAmountGiven, creditAmountReceived, isPayer }) => {
  return (
    <div className="flex flex-col gap-y-1">
      <div>
        <Text bold>{name}</Text>
        {isPayer && (
          <>
            <Text> - </Text>
            <Text color="green"> Payer</Text>
          </>
        )}
      </div>
      {creditAmountGiven !== 0 && <div>{formatCurrency(creditAmountGiven)} Credits Given</div>}
      {creditAmountReceived !== 0 && <div>{formatCurrency(creditAmountReceived)} Credits Received</div>}
      {allocatedAmount !== 0 && <div>{formatCurrency(allocatedAmount)} Payment on Account</div>}
    </div>
  );
};

const InvoicesCell: FC<{ invoices: InvoiceNumberVO[] }> = ({ invoices }) => {
  // If there is more than 1 invoices, show "N invoices" with a tooltip.
  // Otherwise show the invoice number linking to its invoices page.

  if (invoices.length === 1) {
    const invoice = invoices[0];

    return <InvoiceLink invoice={invoice} />;
  }

  return (
    <FloatingTooltip content={<InvoicesTooltip invoices={invoices} />}>
      <div className="flex cursor-pointer">
        <Text bold color="primary">
          {invoices.length} invoices
        </Text>
      </div>
    </FloatingTooltip>
  );
};

const InvoicesTooltip: FC<{ invoices: InvoiceNumberVO[] }> = ({ invoices }) => {
  return (
    <div className="flex flex-col gap-y-4">
      {invoices.map((invoice) => (
        <div key={invoice.uuid}>
          <InvoiceLink invoice={invoice} />
        </div>
      ))}
    </div>
  );
};

const InvoiceLink: FC<{ invoice: InvoiceNumberVO }> = ({ invoice }) => {
  const returnUrl = useFullUrl();

  return (
    <ButtonInternalLink
      theme="link"
      to={paths.viewInvoice({ patientId: invoice.patientId, invoiceUuid: invoice.uuid }, { from: returnUrl })}
    >
      <Text>#{invoice.number}</Text>
    </ButtonInternalLink>
  );
};
