import React from "react";
import { MedicalImageVO } from "@libs/api/generated-api";
import { MountFormat } from "utils/routing/patient";

export const MOUNT_FORMAT_NUMBER_SLOTS: Record<MountFormat, number> = {
  single: 1,
  double: 2,
  quad: 4,
  sandbox: 0,
};

const useInitialIndecies = <T extends MedicalImageVO>(images: T[]) => {
  return React.useMemo(
    () =>
      [null, null, null, null].map((item, i) => {
        if (i < MOUNT_FORMAT_NUMBER_SLOTS.quad) {
          return images[i]?.id ?? null;
        }

        return null;
      }),
    [images]
  );
};

export const useImageSidebarNavigation = <T extends MedicalImageVO>({
  format,
  images,
}: {
  images: T[];
  format: MountFormat;
}) => {
  const totalSelectionSize = MOUNT_FORMAT_NUMBER_SLOTS[format];
  const initialSize = useInitialIndecies(images);
  const [selectedImageIds, setSelectedImageIds] = React.useState<(number | null)[]>(initialSize);
  const mountHasVacancy = selectedImageIds.filter((item) => item !== null).length < totalSelectionSize;
  const selectedImages = React.useMemo(() => {
    return selectedImageIds
      .map((selectedImageId, i) => {
        let image = undefined;

        if (selectedImageId !== null) {
          image = images.find((item) => item.id === selectedImageId);
        }

        return {
          image,
          index: i,
          absoluteIndex: selectedImageId ?? 0,
        };
      })
      .filter((item, i) => i < totalSelectionSize);
  }, [selectedImageIds, images, totalSelectionSize]);

  return {
    selectedImages,
    selectedImageIds,
    handleNewSelectedImagesIds: setSelectedImageIds,
    handleImageMounted: React.useCallback(
      (image: MedicalImageVO, indexToMountAt?: number) => {
        const imageId = image.id as number;

        setSelectedImageIds((selected) => {
          // If no indexToMountAt, find first empty, assuming there's vacancy
          let mountIndex =
            indexToMountAt === undefined ? (mountHasVacancy ? selected.indexOf(null) : -1) : indexToMountAt;

          if (mountIndex < 0) {
            // If no empty positions, replace first
            mountIndex = 0;
          }

          const newIndecies = [...selected];

          const currentIndexOfImage = newIndecies.indexOf(imageId);

          const currentImageAtDesiredIndex = newIndecies[mountIndex];

          if (currentIndexOfImage >= 0) {
            // Swap images if they're both displayed in mount
            newIndecies[currentIndexOfImage] = currentImageAtDesiredIndex;
          }

          newIndecies[mountIndex] = imageId;

          return newIndecies;
        });
      },
      [setSelectedImageIds, mountHasVacancy]
    ),
  };
};

export type UseImageSidebarNavigation = ReturnType<typeof useImageSidebarNavigation>;
