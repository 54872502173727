import { FC } from "react";

import { EmployeeVO, ProfileImageVO } from "@libs/api/generated-api";
import { formatPhoneNumber } from "@libs/utils/phone";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { isOneOf } from "@libs/utils/isOneOf";

import { ReactComponent as EmailIcon } from "@libs/assets/icons/email.svg";
import { ReactComponent as UnlockIcon } from "@libs/assets/icons/unlock.svg";
import { ProfilePhoto } from "components/Shared/ProfilePhoto";

import { useNotificationContext } from "contexts/NotificationsContext";

interface Props {
  employee: EmployeeVO;
  employeeImage: ProfileImageVO;
  isInviteResending: boolean;
  onResendInviteClick: Func;
}

export const EmployeeProfileHeader: FC<Props> = ({
  employee,
  employeeImage,
  isInviteResending,
  onResendInviteClick,
}) => {
  const notifications = useNotificationContext();

  const employeeStatus = employee.employmentDetails.status;

  return (
    <div className="flex flex-col gap-y-6 px-6 py-3">
      <div className="flex items-center gap-x-2">
        <div className="h-14 w-14">
          <ProfilePhoto
            imageUrl={employeeImage.url}
            name={employee.personalDetails.displayName}
            userId={employee.id}
          />
        </div>

        <div className="flex flex-col gap-y-0.5 text-xs text-slate-900">
          <span className="font-sansSemiBold text-sm">{employee.personalDetails.displayName}</span>
          <span>{employee.employmentDetails.jobTitle}</span>
          {employee.contactDetails.phone ? (
            <span>{formatPhoneNumber(employee.contactDetails.phone)}</span>
          ) : null}
        </div>
      </div>

      <div className="flex items-center gap-x-2">
        <ButtonIcon
          SvgIcon={EmailIcon}
          tooltip={{ content: "Copy Email", theme: "SMALL" }}
          onClick={() => {
            if (employee.contactDetails.workEmail) {
              navigator.clipboard.writeText(employee.contactDetails.workEmail);
            }

            notifications.handleInfo("Copied to clipboard.");
          }}
          theme="slate700"
        />

        {employeeStatus && isOneOf(employeeStatus, ["ACTIVE", "PENDING"]) ? (
          <ButtonIcon
            SvgIcon={UnlockIcon}
            tooltip={{ content: "Reset Password", theme: "SMALL" }}
            onClick={onResendInviteClick}
            loading={isInviteResending}
            theme="slate700"
          />
        ) : null}
      </div>
    </div>
  );
};
