import { FormEvent, useState } from "react";
import { ProviderVO, RoomRequest, RoomVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Form } from "@libs/components/UI/Form";
import { FormSection } from "components/UI/FormSection";
import { FormSectionTitle } from "components/UI/FormSectionTitle";
import { convertToRoomRequest } from "components/Settings/PracticeSetup/Sections/Rooms/utils";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent, FlyoverFooter } from "components/UI/FlyoverComponents";
import { FormFieldSelectProviders } from "components/UI/FormFieldSelectProviders";

const EMPTY_STATE: RoomRequest = {
  roomName: "",
  order: 0,
};

const MAX_DESCRIPTION_LENGTH = 255;

const schema = {
  roomName: [
    {
      $v: required,
      $error: "Name is required",
    },
  ],
};

const FORM_NAME = "roomForm";

export const RoomsFlyover: React.FC<{
  existingRoom?: RoomVO;
  providers: ProviderVO[];
  isSaving: boolean;
  onClose: () => void;
  onSave: (room: RoomRequest) => void;
}> = ({ existingRoom, providers, isSaving, onClose, onSave }) => {
  const [state, setState] = useState<RoomRequest>(() => {
    if (!existingRoom) {
      return { ...EMPTY_STATE };
    }

    return convertToRoomRequest(existingRoom);
  });

  const validation = useValidation(state, schema);

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validation.validate().$isValid) {
      return;
    }

    onSave(state);
  };

  return (
    <Flyover title={`${existingRoom ? "Edit" : "Add"} Room`} onClose={onClose} size="md">
      <FlyoverContent paddingClassName="">
        <Form className="min-h-0 flex-1 flex flex-col py-3" onSubmit={handleSave} id={FORM_NAME}>
          <FormSection>
            <FormSectionTitle required>Name</FormSectionTitle>
            <FormFieldInput
              value={state.roomName}
              onChange={(e) => setState((prev) => ({ ...prev, roomName: e.target.value }))}
              layout="labelOut"
              error={validation.result.roomName.$error}
              maxLength={MAX_DESCRIPTION_LENGTH}
            />
          </FormSection>
          <FormSection>
            <FormSectionTitle>Description</FormSectionTitle>
            <FormFieldInput
              value={state.roomDescription}
              onChange={(e) => setState((prev) => ({ ...prev, roomDescription: e.target.value }))}
              layout="labelOut"
              maxLength={MAX_DESCRIPTION_LENGTH}
            />
          </FormSection>
          <FormSection>
            <FormSectionTitle subTitle="Select which providers can use this room. If left blank, all providers can use this room.">
              Providers
            </FormSectionTitle>
            <FormFieldSelectProviders
              onChange={(providerIds) => setState((prev) => ({ ...prev, providerIds }))}
              selectedProviderIds={state.providerIds}
              providers={providers}
              savedProviders={existingRoom?.providers}
            />
          </FormSection>
        </Form>
      </FlyoverContent>
      <FlyoverFooter actions>
        <Button className="min-w-button" theme="secondary" onClick={onClose}>
          Cancel
        </Button>
        <AsyncButton className="min-w-button" isLoading={isSaving} type="submit" form={FORM_NAME}>
          Save
        </AsyncButton>
      </FlyoverFooter>
    </Flyover>
  );
};
