import { pluralize } from "@libs/utils/pluralize";
import { AlertModal } from "@libs/components/UI/AlertModal";

type Props = {
  onConfirm: Func;
  onCancel: Func;
  imageCount: number;
};
export const RevertChangesModal: React.FC<Props> = ({ onCancel, onConfirm, imageCount }) => {
  return (
    <AlertModal
      primaryText="Revert to Original"
      secondaryText={`Are you sure? Reverting will remove all edits, filters and annotations from ${pluralize(
        imageCount,
        "this",
        "these"
      )} ${pluralize(imageCount, "image", "images")}`}
      size="xs"
      confirmText="Proceed"
      cancelText="Cancel"
      onCancel={onCancel}
      onConfirm={onConfirm}
    />
  );
};
