import { FC } from "react";

import { Button } from "@libs/components/UI/Button";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";

export const UploadedModalContent: FC<{ onRequestClose: Func }> = ({ onRequestClose }) => {
  return (
    <div className="flex flex-col min-h-0 flex-1 p-10 pb-6">
      <ModalContent>
        <div className="font-sansSemiBold text-base text-center">Your Documents are uploaded</div>
      </ModalContent>
      <ModalFooter className="border-none">
        <Button className="min-w-button" onClick={onRequestClose}>
          Close
        </Button>
      </ModalFooter>
    </div>
  );
};
