import { FC } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { noCache } from "@libs/utils/queryCache";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { isDefined } from "@libs/utils/types";
import { getAllTaxonomyCodesQuery, getEmployee, getEmployeeJobTitlesV2 } from "api/employee/queries";
import { getCompanyOnboardingStatus, getEmployeeOnboardStatus } from "api/payroll/queries";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { EmployeeAppHistoryProvider } from "components/Employee/EmployeeLinksContext";
import { PinGuard } from "components/UI/PinGuard";
import { AccessDeniedContent } from "components/UI/AccessDeniedContent";
import { getAssignableRolesForUser } from "api/user/queries";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { checkPermission } from "components/Roles/roleUtils";
import { ToggleEmploymentDetails } from "components/EmployeeProfile/EmploymentDetails/ToggleEmploymentDetails";

// eslint-disable-next-line complexity
export const EmployeeEmployment: FC = () => {
  const { query, updateQuery } = useQueryParams("employeeEmployment");
  const { practiceId, id } = useAccount();
  const currentUser = useCurrentUser();
  const { id: employeeId } = usePathParams("employee");

  // The current employee can edit roles if they have the
  // EMPLOYEE_SETTINGS.ACCESS_ALL permission and are not editing their own
  // profile. Users cannot edit their own role.
  const canEditAccess =
    checkPermission(currentUser.roleV2, "EMPLOYEE_SETTINGS", "ACCESS_ALL").isPermitted &&
    currentUser.id !== employeeId;

  const [
    employeeQuery,
    practicePayrollOnboardingQuery,
    assignableRolesQuery,
    taxonomyCodesQuery,
    jobTitlesQuery,
  ] = useApiQueries([
    getEmployee({ args: { practiceId, employeeId, includeCompensationDetails: true, includeRole: true } }),
    getCompanyOnboardingStatus({ args: { practiceId } }),
    getAssignableRolesForUser({
      args: { practiceId, userId: id },
      queryOptions: { enabled: canEditAccess },
    }),
    getAllTaxonomyCodesQuery({ args: {} }),
    getEmployeeJobTitlesV2(),
  ]);

  const employeeIsOnPayroll =
    isDefined(employeeQuery.data) && !employeeQuery.data.employmentDetails.excludeFromPayroll;

  const [employeePayrollOnboardingQuery] = useApiQueries([
    getEmployeeOnboardStatus({
      args: { practiceId, employeeId },
      queryOptions: {
        ...noCache,
        enabled: employeeIsOnPayroll,
      },
    }),
  ]);

  const assignableRoles = canEditAccess ? assignableRolesQuery.data : [];

  return (
    <EmployeeAppHistoryProvider name="employment">
      <PinGuard
        protectedQueries={[employeeQuery]}
        deniedContent={
          <div className="h-full flex place-content-center">
            <AccessDeniedContent />
          </div>
        }
      >
        <QueryResult
          queries={[
            employeeQuery,
            practicePayrollOnboardingQuery,
            assignableRolesQuery,
            taxonomyCodesQuery,
            jobTitlesQuery,
            employeePayrollOnboardingQuery,
          ]}
        >
          {employeeQuery.data &&
          employeeQuery.data.roleV2 &&
          assignableRoles &&
          taxonomyCodesQuery.data &&
          jobTitlesQuery.data &&
          (employeeIsOnPayroll ? employeePayrollOnboardingQuery.data : true) ? (
            <ToggleEmploymentDetails
              isEditing={query.edit}
              canEditAccess={canEditAccess}
              onDoneEditing={() => updateQuery("replaceIn", { edit: false })}
              onBeginEditing={() => updateQuery("replaceIn", { edit: true })}
              employeeId={employeeId}
              employeeRole={employeeQuery.data.roleV2}
              employmentDetails={employeeQuery.data.employmentDetails}
              payrollEnabled={Boolean(
                practicePayrollOnboardingQuery.data &&
                  practicePayrollOnboardingQuery.data.status !== "NOT_SETUP"
              )}
              employeePayrollOnboardingQuery={employeePayrollOnboardingQuery}
              assignableRoles={assignableRoles}
              taxonomyCodes={taxonomyCodesQuery.data}
              jobTitles={jobTitlesQuery.data}
            />
          ) : null}
        </QueryResult>
      </PinGuard>
    </EmployeeAppHistoryProvider>
  );
};
