import { FC } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { noCache } from "@libs/utils/queryCache";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getCompanyOnboardingStatus, getGustoOnboardingErrors } from "api/payroll/queries";
import { PayrollIssues } from "components/Settings/PracticeSetup/Sections/PayrollInformation/PayrollIssues";
import { PayrollSettings } from "components/Settings/PracticeSetup/Sections/PayrollInformation/PayrollSettings";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { PinGuard } from "components/UI/PinGuard";
import { AccessDeniedContent } from "components/UI/AccessDeniedContent";

export const PayrollInformationRoute: FC = () => {
  const { practiceId } = useAccount();
  const [companyOnboardingStatusQuery, gustoOnboardingErrorsQuery] = useApiQueries([
    getCompanyOnboardingStatus({
      args: { practiceId },
      queryOptions: noCache,
    }),
    getGustoOnboardingErrors({
      args: { practiceId },
      queryOptions: noCache,
    }),
  ]);

  return (
    <PinGuard
      protectedQueries={[gustoOnboardingErrorsQuery]}
      deniedContent={
        <div className="h-full flex place-content-center">
          <AccessDeniedContent />
        </div>
      }
    >
      <SettingsPanel
        title="Payroll Information"
        description="Setup payroll information for your practice to make your salary payment process easier"
      >
        <div className="flex flex-col gap-y-6">
          <QueryResult queries={[companyOnboardingStatusQuery, gustoOnboardingErrorsQuery]}>
            {companyOnboardingStatusQuery.data ? (
              <PayrollSettings onboardingStatus={companyOnboardingStatusQuery.data.status} />
            ) : null}

            {companyOnboardingStatusQuery.data && gustoOnboardingErrorsQuery.data ? (
              <PayrollIssues
                onboardingStatus={companyOnboardingStatusQuery.data.status}
                onboardingErrors={gustoOnboardingErrorsQuery.data}
              />
            ) : null}
          </QueryResult>
        </div>
      </SettingsPanel>
    </PinGuard>
  );
};
