import { format } from "date-fns";
import { FC, Fragment } from "react";
import { CustomHolidayVO } from "@libs/api/generated-api";
import { getLocalDate } from "@libs/utils/date";
import { SettingsLabel, SettingsValue } from "@libs/components/UI/SettingsComponents";

export const TimeOffTable: FC<{ customHolidays: CustomHolidayVO[] }> = ({ customHolidays }) => {
  return (
    <div className="grid grid-cols-3 gap-y-2 max-w-lg mb-5">
      <SettingsLabel>Time Off From</SettingsLabel>
      <SettingsLabel>Time Off Until</SettingsLabel>
      <SettingsLabel>Description</SettingsLabel>

      {customHolidays.map((row) => (
        <Fragment key={row.id as number}>
          <SettingsValue>
            {format(getLocalDate(row.startDate, row.startTime), "MMM dd hh:mm aaa")}
          </SettingsValue>

          <SettingsValue>{format(getLocalDate(row.endDate, row.endTime), "MMM dd hh:mm aaa")}</SettingsValue>
          <SettingsValue>{row.description}</SettingsValue>
        </Fragment>
      ))}
    </div>
  );
};
