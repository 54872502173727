import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { FamilyMemberVO, LinkedFamilyMemberVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Icon } from "@libs/components/UI/Icon";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { ReactComponent as DownCaretIcon } from "@libs/assets/icons/down-caret.svg";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus.svg";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ButtonCell } from "@libs/components/UI/GridTableComponents";
import { FamilyMembersInitialsList } from "components/PatientProfile/PatientOverview/FamilyMembers/FamilyMemberInitialsList";
import {
  InsuranceSummaryWithRelationToFamilyMember,
  SelectUpdates,
  cxStyles,
} from "components/PatientProfile/PatientOverview/FamilyMembers/FamilyMembersTableRow";

import { paths } from "utils/routing/paths";
import { sortByName } from "components/PatientProfile/PatientOverview/utils";

interface Props {
  disabled: boolean;
  familyMember: FamilyMemberVO;
  isLastRow: boolean;
  onAddFamilyMember?: () => void;
  onSelectMember: (
    familyMemberPatientId: number,
    { guardianOrPhiUpdates, insuranceUpdates }: SelectUpdates
  ) => void;
  primaryInsurances?: InsuranceSummaryWithRelationToFamilyMember[];
  updateAccessType?: "guardian" | "phiAccess";
}

interface GuardianOrPhiProps
  extends Omit<Props, "primaryInsurances" | "disabled" | "isLastRow" | "updateAccessType"> {
  linkedFamilyMembers: LinkedFamilyMemberVO[];
  onClose: () => void;
  updateAccessType: "guardian" | "phiAccess";
}

interface InsurancesProps
  extends Omit<Props, "disabled" | "isLastRow" | "onAddFamilyMember" | "updateAccessType"> {
  onClose: () => void;
}

const GuardianOrPhiMenu: React.FC<GuardianOrPhiProps> = ({
  familyMember,
  linkedFamilyMembers,
  onAddFamilyMember,
  onClose,
  onSelectMember,
  updateAccessType,
}) => {
  const handleSelectGuardianOrPhi = useCallback(
    (member: LinkedFamilyMemberVO) => {
      onSelectMember(familyMember.memberPatientId, {
        guardianOrPhiUpdates: {
          memberPatientId: member.name.id,
          [updateAccessType]: !member[updateAccessType],
        },
      });
      onClose();
    },
    [familyMember.memberPatientId, onClose, onSelectMember, updateAccessType]
  );

  return (
    <>
      {linkedFamilyMembers.length ? (
        linkedFamilyMembers.map((member, index) => (
          <button
            className="flex items-center w-full p-3 gap-x-3.5 hover:bg-slate-100"
            key={index}
            onClick={() => handleSelectGuardianOrPhi(member)}
            type="button"
          >
            <Checkbox checked={member[updateAccessType]} onChange={() => handleSelectGuardianOrPhi(member)} />
            {member.name.fullDisplayName}
          </button>
        ))
      ) : (
        <div className="flex items-center w-full p-3 gap-x-3.5">
          <Icon className="cursor-default" SvgIcon={InfoIcon} size="sm" />
          No family members
        </div>
      )}
      {onAddFamilyMember && (
        <button
          className={`
            flex
            items-center
            w-full
            p-3
            gap-x-1.5
            border-t
            border-greyLighter
            hover:bg-slate-100
          `}
          data-testid="unlink-menu-option"
          onClick={() => {
            onAddFamilyMember();
            onClose();
          }}
          type="button"
        >
          <Icon SvgIcon={PlusIcon} size="sm" />
          New Family Member
        </button>
      )}
    </>
  );
};

const InsuranceMenu: React.FC<InsurancesProps> = ({
  primaryInsurances,
  familyMember,
  onClose,
  onSelectMember,
}) => {
  const navigate = useNavigate();
  const handleSelectInsurance = useCallback(
    (insurance: InsuranceSummaryWithRelationToFamilyMember) => {
      onSelectMember(familyMember.memberPatientId, {
        insuranceUpdates: {
          patientInsuranceSubscriberId: insurance.patientInsuranceSubscriberId,
          relationToSubscriber: insurance.relationToSubscriber,
        },
      });

      onClose();
    },
    [familyMember.memberPatientId, onClose, onSelectMember]
  );

  const currentInsurancePatientSubscriberIds = new Set(
    familyMember.insurances.map((insurance) => insurance.patientInsuranceSubscriberId)
  );
  const navigateToPtInsurance = useCallback(() => {
    navigate(paths.patientTab({ patientId: familyMember.memberPatientId, tab: "insurance" }));
  }, [familyMember.memberPatientId, navigate]);

  return (
    <>
      {primaryInsurances && primaryInsurances.length ? (
        <>
          {primaryInsurances.map((insurance) => {
            const checked = currentInsurancePatientSubscriberIds.has(insurance.patientInsuranceSubscriberId);

            return (
              <button
                className="flex items-center w-full p-3 gap-x-3.5 hover:bg-slate-100"
                disabled={checked}
                key={insurance.id}
                onClick={() => handleSelectInsurance(insurance)}
                type="button"
              >
                <FloatingTooltip
                  content={
                    checked
                      ? `Insurance must be removed via ${familyMember.name.fullDisplayName}'s profile`
                      : ""
                  }
                  placement="left"
                >
                  <div>
                    <Checkbox
                      checked={checked}
                      disabled={checked}
                      onChange={() => {
                        handleSelectInsurance(insurance);
                      }}
                    />
                  </div>
                </FloatingTooltip>
                <div className="text-left">{`${insurance.subscriber.fullDisplayName}, ${
                  insurance.employer && insurance.employer !== ""
                    ? insurance.employer
                    : insurance.carrier.name
                }`}</div>
              </button>
            );
          })}
        </>
      ) : (
        <div className="flex items-center w-full p-3 gap-x-3.5">
          <Icon className="cursor-default" SvgIcon={InfoIcon} size="sm" />
          No insurances available
        </div>
      )}
      <button
        className={`
          flex
          items-center
          w-full
          p-3
          gap-x-1.5
          border-t
          border-greyLighter
          hover:bg-slate-100
        `}
        data-testid="unlink-menu-option"
        onClick={() => {
          navigateToPtInsurance();
          onClose();
        }}
        type="button"
      >
        <Icon className="cursor-default" SvgIcon={EditIcon} size="sm" />
        Manage Insurances
      </button>
    </>
  );
};

export const FamilyMembersSelectMembersCell: React.FC<Props> = ({
  disabled,
  familyMember,
  isLastRow,
  onAddFamilyMember,
  onSelectMember,
  primaryInsurances,
  updateAccessType,
}) => {
  const hover = useBoolean(false);
  const menu = useBoolean(false);
  const linkedFamilyMembers = familyMember.linkedFamilyMembers.sort(sortByName);

  const filteredInitialsData = updateAccessType
    ? linkedFamilyMembers
        .filter((member) => member[updateAccessType])
        .map((member) => {
          return {
            name: member.name.fullDisplayName.split(",")[0],
            tooltipContent: member.name.fullDisplayName,
          };
        })
    : familyMember.insurances.map((insurance) => {
        return {
          name: insurance.subscriber.shortDisplayName,
          tooltipContent: `${insurance.subscriber.shortDisplayName}, ${
            insurance.employer && insurance.employer !== "" ? insurance.employer : insurance.carrier.name
          }`,
        };
      });

  return (
    <div>
      <ButtonMenu
        isOpen={menu.isOn}
        shadow="heavy"
        menuContent={
          <div className="min-w-64 max-w-80 max-h-80 overflow-y-auto text-sm">
            {updateAccessType ? (
              <GuardianOrPhiMenu
                familyMember={familyMember}
                linkedFamilyMembers={linkedFamilyMembers}
                onAddFamilyMember={onAddFamilyMember}
                onClose={menu.off}
                onSelectMember={onSelectMember}
                updateAccessType={updateAccessType}
              />
            ) : (
              <InsuranceMenu
                primaryInsurances={primaryInsurances}
                familyMember={familyMember}
                onClose={menu.off}
                onSelectMember={onSelectMember}
              />
            )}
          </div>
        }
        onRequestClose={menu.off}
        onRequestOpen={menu.on}
        placement="bottom-start"
      >
        {(props) => (
          <ButtonCell
            border={!isLastRow}
            className={cx("w-full px-2", cxStyles.cell)}
            disabled={disabled}
            hasPadding={false}
            hideHover={true}
            onClick={menu.on}
            onMouseEnter={hover.on}
            onMouseLeave={hover.off}
            {...props}
          >
            <div
              className={cx(
                "flex items-center justify-between my-0.5 px-1 h-9 w-full",
                !disabled && "hover:bg-slate-100/50 hover:rounded"
              )}
            >
              {filteredInitialsData.length ? (
                <div className="px-1">
                  <FamilyMembersInitialsList initialsList={filteredInitialsData} />
                </div>
              ) : (
                <span className="px-1 py-3">-</span>
              )}
              {hover.isOn && <Icon SvgIcon={DownCaretIcon} size="sm" />}
            </div>
          </ButtonCell>
        )}
      </ButtonMenu>
    </div>
  );
};
