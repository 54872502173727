import { useMemo } from "react";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { RefundablePaymentVO } from "@libs/api/generated-api";
import { formatDate } from "@libs/utils/date";
import { fromUnixTime } from "date-fns";
import { formatCurrency } from "@libs/utils/currency";
import { GroupHeadingProps, components } from "react-select";
import { RequiredAsterisk } from "@libs/components/UI/RequiredAsterisk";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { getPaymentProfileTypeLabel } from "components/PatientProfile/Billing/billingUtils";
import { paymentMethodToLabel } from "components/PatientProfile/Billing/PaymentMethods/utils";

type Props = {
  onChangeRefundablePayment: (payment: RefundablePaymentVO) => void;
  selectedRefundablePayment?: RefundablePaymentVO;
  disabled?: boolean;
  refundablePayments: RefundablePaymentVO[];
};

export const RefundableTransactionOption: React.FC<{ refundable: RefundablePaymentVO }> = ({
  refundable,
}) => {
  const { paymentProfile, paymentCreatedAt } = refundable.payment;
  const method = useMemo(() => {
    if (refundable.payment.method === "STORED_PROFILE" && paymentProfile?.card?.cardType) {
      return `${getPaymentProfileTypeLabel(paymentProfile.card.cardType)} ${
        paymentProfile.card.cardLastFour ?? ""
      }`;
    }

    return paymentMethodToLabel[refundable.payment.method];
  }, [paymentProfile?.card?.cardType, refundable.payment.method, paymentProfile?.card?.cardLastFour]);

  return (
    <div className="grid grid-cols-3">
      <div className="truncate">{formatDate(fromUnixTime(paymentCreatedAt))}</div>
      <div>{method}</div>
      <div>{formatCurrency(refundable.refundableAmount)}</div>
    </div>
  );
};

type HeadingProps = GroupHeadingProps<
  SelectOption<string>,
  false,
  GroupedSelectOption<string, SelectOption<string>>
>;

const Heading = (props: HeadingProps) => (
  <components.GroupHeading {...props}>
    <div className="grid grid-cols-3 font-sansSemiBold">
      <div>Date</div>
      <div>Method</div>
      <div>Amount</div>
    </div>
  </components.GroupHeading>
);

export const SelectRefundableTransaction: React.FC<Props> = ({
  onChangeRefundablePayment,
  selectedRefundablePayment,
  refundablePayments,
  disabled = false,
}) => {
  const transactionOptions = useMemo(() => {
    return refundablePayments.map((refundable) => {
      return {
        label: <RefundableTransactionOption refundable={refundable} />,
        value: refundable.payment.uuid,
      };
    });
  }, [refundablePayments]);

  return (
    <FormFieldSelect
      components={{
        GroupHeading: Heading,
      }}
      disabled={disabled}
      display="label"
      edit
      isDark={false}
      isClearable={false}
      label={
        <div className="flex items-center">
          Transaction <RequiredAsterisk />
          <Icon
            SvgIcon={InfoIcon}
            size="sm"
            className="text-slate-500 ml-1"
            tooltip={{ content: "Select the transaction this refund is related to" }}
          />
        </div>
      }
      layout="labelOut"
      onItemSelected={(paymentUuid) => {
        const updatedSelection = refundablePayments.find(
          (refundable) => refundable.payment.uuid === paymentUuid
        );

        if (updatedSelection) {
          onChangeRefundablePayment(updatedSelection);
        }
      }}
      isSearchable={false}
      options={[{ options: transactionOptions }]}
      placeholder="Select transaction"
      className="col-span-full"
      value={selectedRefundablePayment?.payment.uuid}
    />
  );
};
