import { FC } from "react";
import { format } from "date-fns";
import { EmployeeVO } from "@libs/api/generated-api";
import { getLocalDate } from "@libs/utils/date";
import { ButtonLink } from "@libs/components/UI/ButtonLink";
import OnboardSuccess from "assets/images/onboard.svg";
import { paths } from "utils/routing/paths";
import { hasAccess } from "components/Main/EmployeeUserProvider";

interface Props {
  employee: EmployeeVO;
  practiceTimezoneId: string;
}

export const OnboardingCompletedScreen: FC<Props> = ({ employee, practiceTimezoneId }) => {
  const { accessDate, startDate } = employee.employmentDetails;

  const date = accessDate ?? startDate;

  return (
    <div className="flex flex-col items-center mt-6">
      <img alt="Onboard Success" src={OnboardSuccess} />
      <div className="text-primaryTheme text-5xl my-5">You&apos;re all set!</div>
      {hasAccess(accessDate, startDate, practiceTimezoneId) ? (
        // This is unlikely to show since the `/onboarding` endpoint should
        // redirect users to the logged-in home before this gets a chance to
        // render.
        <ButtonLink href={paths.home()}>Get started</ButtonLink>
      ) : date ? (
        <div>
          Your account will be active on: <b>{format(getLocalDate(date), "PP")}</b>.
        </div>
      ) : (
        <div>
          It looks like your account has not been configured properly. Please contact your administrator.
        </div>
      )}
    </div>
  );
};
