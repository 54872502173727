import { useCallback, useMemo } from "react";
import {
  FamilyMemberVO,
  InsuranceBySubscriberIdRequest,
  LinkedFamilyMemberVO,
  PatientInsuranceSummaryVO,
  UpdateFamilyMemberRequest,
  UpdateGuardianOrPhiAccessRequest,
} from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { Cell, Row } from "@libs/components/UI/GridTableComponents";
import { PatientTag } from "components/PatientProfile/PatientOverview/Info/PatientTag";
import {
  addDependentPatientInsurance,
  addFamilyMember,
  removeFamilyMember,
  updateFamilyMembers,
  updateGuardiansAndPhiAccess,
} from "api/patients/mutations";
import { handleError } from "utils/handleError";

import { FamilyMembersSelectMembersCell } from "components/PatientProfile/PatientOverview/FamilyMembers/FamilyMembersSelectMembersCell";
import { FamilyMemebersSelectRelationsCell } from "components/PatientProfile/PatientOverview/FamilyMembers/FamilyMembersSelectRelationsCell";
import { getFamilyMemberRelationLabel } from "components/PatientProfile/PatientOverview/utils";

interface Props {
  familyMember: FamilyMemberVO;
  isLastRow: boolean;
  isLinked: boolean;
  isSelf: boolean;
  onAddFamilyMember: () => void;
  patientFirstName: string;
  patientId: number;
  primaryInsuranceByPatientId: Record<number, Record<number, PatientInsuranceSummaryVO>>;
}

export interface InsuranceSummaryWithRelationToFamilyMember extends PatientInsuranceSummaryVO {
  relationToSubscriber: LinkedFamilyMemberVO["relation"];
}

export type SelectUpdates = {
  guardianOrPhiUpdates?: UpdateGuardianOrPhiAccessRequest;
  insuranceUpdates?: InsuranceBySubscriberIdRequest;
};

export const cxStyles = {
  cell: "flex items-center",
  cellXPadding: "px-3",
  cellYPadding: "py-2",
};

export const FamilyMembersTableRow: React.FC<Props> = ({
  familyMember,
  isSelf,
  isLastRow,
  isLinked,
  onAddFamilyMember,
  patientFirstName,
  patientId,
  primaryInsuranceByPatientId,
}) => {
  const { practiceId } = useAccount();

  const [
    addFamilyMemberMutation,
    addDependentPatientInsuranceMutation,
    removeFamilyMemberMutation,
    updateGuardiansAndPhiAccessMutation,
    updateFamilyMembersMutation,
  ] = useApiMutations([
    addFamilyMember,
    addDependentPatientInsurance,
    removeFamilyMember,
    updateGuardiansAndPhiAccess,
    updateFamilyMembers,
  ]);

  const handleUpdateRelation = useCallback(
    (relation: UpdateFamilyMemberRequest["relation"]) => {
      if (familyMember.relationId) {
        updateFamilyMembersMutation.mutate(
          {
            practiceId,
            patientId,
            updates: [
              {
                id: familyMember.relationId,
                relation,
              },
            ],
          },
          { onError: handleError }
        );
      } else if (relation) {
        addFamilyMemberMutation.mutate(
          {
            practiceId,
            patientId,
            member: {
              existingPatient: { id: familyMember.memberPatientId },
              relation,
              type: "Existing",
            },
          },
          {
            onError: handleError,
          }
        );
      }
    },
    [
      addFamilyMemberMutation,
      familyMember.memberPatientId,
      familyMember.relationId,
      patientId,
      practiceId,
      updateFamilyMembersMutation,
    ]
  );

  const handleSelectMember = useCallback(
    (familyMemberPatientId: number, { guardianOrPhiUpdates, insuranceUpdates }: SelectUpdates) => {
      if (guardianOrPhiUpdates) {
        updateGuardiansAndPhiAccessMutation.mutate(
          {
            practiceId,
            patientId: familyMemberPatientId,
            updates: guardianOrPhiUpdates,
          },
          { onError: handleError }
        );
      } else if (insuranceUpdates) {
        addDependentPatientInsuranceMutation.mutate(
          {
            practiceId,
            patientId: familyMemberPatientId,
            data: insuranceUpdates,
          },
          { onError: handleError }
        );
      }
    },
    [addDependentPatientInsuranceMutation, practiceId, updateGuardiansAndPhiAccessMutation]
  );

  const handleUnlink = useCallback(() => {
    removeFamilyMemberMutation.mutate(
      {
        practiceId,
        patientId,
        memberId: {
          familyMemberPatientId: familyMember.memberPatientId,
        },
      },
      {
        onError: handleError,
      }
    );
  }, [familyMember.memberPatientId, patientId, practiceId, removeFamilyMemberMutation]);

  const primaryInsurances = useMemo(() => {
    const selfPrimaryInsurances: InsuranceSummaryWithRelationToFamilyMember[] =
      familyMember.memberPatientId in primaryInsuranceByPatientId
        ? Object.values(primaryInsuranceByPatientId[familyMember.memberPatientId]).map((insurance) => {
            return {
              ...insurance,
              relationToSubscriber: "SELF",
            };
          })
        : [];

    return [
      ...selfPrimaryInsurances,
      ...familyMember.linkedFamilyMembers
        .filter((linkedMember) => {
          return Boolean(primaryInsuranceByPatientId[linkedMember.name.id]);
        })
        .flatMap((linkedMember) => {
          return Object.values(primaryInsuranceByPatientId[linkedMember.name.id]).map((insurance) => {
            return { ...insurance, relationToSubscriber: linkedMember.relation };
          });
        }),
    ];
  }, [familyMember.linkedFamilyMembers, familyMember.memberPatientId, primaryInsuranceByPatientId]);

  return (
    <Row>
      <Cell className={cx("pl-5 pr-3", cxStyles.cell, cxStyles.cellYPadding)} borderBottom={!isLastRow}>
        <PatientTag
          name={familyMember.name}
          age={familyMember.age}
          gender={familyMember.gender}
          contact={familyMember.contact}
          relationLabel={getFamilyMemberRelationLabel(familyMember, patientFirstName)}
        />
      </Cell>
      <FamilyMembersSelectMembersCell
        disabled={!isLinked}
        familyMember={familyMember}
        isLastRow={isLastRow}
        onSelectMember={handleSelectMember}
        primaryInsurances={primaryInsurances}
      />
      <FamilyMembersSelectMembersCell
        disabled={!isLinked}
        familyMember={familyMember}
        isLastRow={isLastRow}
        onAddFamilyMember={onAddFamilyMember}
        onSelectMember={handleSelectMember}
        updateAccessType="guardian"
      />
      <FamilyMembersSelectMembersCell
        disabled={!isLinked}
        familyMember={familyMember}
        isLastRow={isLastRow}
        onAddFamilyMember={onAddFamilyMember}
        onSelectMember={handleSelectMember}
        updateAccessType="phiAccess"
      />
      <FamilyMemebersSelectRelationsCell
        familyMember={familyMember}
        isLastRow={isLastRow}
        isLinked={isLinked}
        isSelf={isSelf}
        onUnlink={handleUnlink}
        onUpdateRelation={handleUpdateRelation}
        patientFirstName={patientFirstName}
      />
    </Row>
  );
};
