import Skeleton from "react-loading-skeleton";
import { cx } from "@libs/utils/cx";
import { ReactComponent as Checkmark } from "@libs/assets/icons/check-circle-1.svg";
import { ReactComponent as XIcon } from "@libs/assets/icons/cancel.svg";

/* Renders red X if denied=true, otherwise a green checkmark */
export const ConfirmationIcon: React.FC<{ denied?: boolean; displayDenied?: boolean }> = ({
  denied = false,
  displayDenied = true,
}) => {
  const iconClasses = "w-4 h-4 inline mr-2";

  return denied ? (
    displayDenied ? (
      <XIcon className={cx("text-red", iconClasses)} />
    ) : null
  ) : (
    <Checkmark className={cx("text-green", iconClasses)} />
  );
};

export type Props = {
  denied?: boolean;
  label?: string;
  loading?: boolean;
};
export const ConfirmationDisclosure: React.FC<Props> = ({ denied = false, label, loading = false }) => {
  if (loading) {
    return <Skeleton className="w-56" />;
  }

  if (!label) {
    return <ConfirmationIcon denied={denied} />;
  }

  return (
    <div>
      <ConfirmationIcon denied={denied} />
      <span className="text-xs">{label}</span>
    </div>
  );
};
