import { DashboardFilterFormFieldSelect } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";

interface Props {
  filters: DashboardFilter[];
  onUpdateParams: (newParams: { filters: DashboardFilter[] }) => void;
  options: { label: string; value: string }[];
}

export const SelectReferralType: React.FC<Props> = ({ filters, onUpdateParams, options }) => {
  return (
    <DashboardFilterFormFieldSelect
      className="min-w-[110px]"
      dashboardFilters={filters}
      dashboardFilterType="referredBy"
      isSearchable={false}
      onChangeFilters={onUpdateParams}
      placeholder="Referral Source"
      options={options}
    />
  );
};
