import { FC, PropsWithChildren, useMemo } from "react";
import { LONG_DAY_OF_YEAR_FORMAT, formatISODate, formatShortISOTimeAsAmPmShortHr } from "@libs/utils/date";
import { AppointmentClaimSummaryVO, AppointmentPatientProcedureVO } from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { cx } from "@libs/utils/cx";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Icon } from "@libs/components/UI/Icon";
import Skeleton from "react-loading-skeleton";
import { ReactComponent as CheckIcon } from "@libs/assets/icons/check-small.svg";
import { Pill } from "@libs/components/UI/Pill";
import { Divider } from "components/UI/Divider";
import { isDraftClaim, isVoidedClaim } from "components/Claims/utils";
import { ClaimStatePill } from "components/Claims/Claims/ClaimStatePill";
import { AppointmentSummary, CreateClaimContext } from "components/Claims/Claims/createClaimTypes";

const CardHeader: FC<{ appointment: AppointmentSummary }> = ({ appointment }) => (
  <div className="flex">
    <span className="font-sansSemiBold">{formatISODate(appointment.date, LONG_DAY_OF_YEAR_FORMAT)}</span>
    &nbsp;&bull;&nbsp;
    <span>{formatShortISOTimeAsAmPmShortHr(appointment.startTime)}</span>&nbsp;&bull;&nbsp;
    <span>{appointment.dentist.name.fullDisplayName}</span>
    <span>
      {appointment.provider.id !== appointment.dentist.id &&
        appointment.provider.jobCategory === "HYGIENIST" &&
        ` - ${appointment.provider.name.fullDisplayName}`}
    </span>
  </div>
);

const ProcedurePills: FC<{
  claims: AppointmentClaimSummaryVO[] | undefined;
  procedures: AppointmentPatientProcedureVO[];
}> = ({ claims, procedures }) => {
  const hasClaimByProcedureId: Record<number, boolean> = useMemo(() => {
    const result: Record<number, boolean> = {};

    claims
      ?.filter((claim) => !isDraftClaim(claim) && !isVoidedClaim(claim))
      .forEach((claim) => {
        claim.procedureIds.forEach((id) => {
          result[id] = true;
        });
      });

    return result;
  }, [claims]);

  // Don't show any state on procedure pills if the only claim is currently a
  // draft or there are no claims associated with the appointment
  const hideProcedureState = !claims || claims.every(isDraftClaim);

  return (
    <div className="flex flex-wrap gap-1">
      {procedures.map((procedure) => (
        <FloatingTooltip key={procedure.id} content={procedure.description}>
          <Pill
            aria-label={`procedure-${procedure.displayName}`}
            className="flex gap-x-1"
            theme={hideProcedureState || hasClaimByProcedureId[procedure.id] ? "white" : "slate200"}
          >
            {procedure.displayName}
            {hasClaimByProcedureId[procedure.id] && (
              <Icon aria-label="submitted-procedure" SvgIcon={CheckIcon} theme="success" />
            )}
          </Pill>
        </FloatingTooltip>
      ))}
    </div>
  );
};

interface Props {
  appointment: AppointmentSummary;
  onClick?: Func;
}

const ClaimSummary: FC<{ claim: AppointmentClaimSummaryVO }> = ({ claim }) => (
  <div className="flex items-center gap-x-2">
    <ClaimStatePill claim={claim} />
    <div className="flex flex-col items-start gap-y-1 py-1">
      <span>{claim.insuranceCarrier}</span>
      <div className="flex">
        <span>{sentenceCaseConstant(claim.insuranceOrdinal)}</span>
        &nbsp;&bull;&nbsp;<span>{claim.insuranceMemberId ?? "SSN (Hidden)"}</span>
        &nbsp;&bull;&nbsp;
        <span>{claim.primarySubscriberName}</span>
      </div>
    </div>
  </div>
);

const cxStyles = {
  card: `
    flex
    flex-col
    p-4
    gap-y-2
    text-xs
    border
    border-slate-300
    rounded
  `,
};

const AppointmentClaimSummaryCardWrapper: FC<
  PropsWithChildren & Props & { context: CreateClaimContext; disabled: boolean }
> = ({ appointment, children, context, disabled, onClick }) => {
  return context === CreateClaimContext.SELECT && appointment.patientInsurances?.length ? (
    <button
      className={cxStyles.card}
      data-testid={`appt-claim-summary-${appointment.id}`}
      onClick={onClick}
      type="button"
    >
      {children}
    </button>
  ) : (
    <div
      className={cx(cxStyles.card, disabled && "bg-slate-50 text-slate-500")}
      data-testid={`appt-claim-summary-${appointment.id}`}
    >
      {children}
    </div>
  );
};

export const AppointmentClaimSummaryCard: FC<Props> = ({ appointment, onClick }) => {
  const disabled = !appointment.patientInsurances?.length;

  return (
    <AppointmentClaimSummaryCardWrapper
      appointment={appointment}
      context={onClick ? CreateClaimContext.SELECT : CreateClaimContext.CREATE}
      disabled={disabled}
      onClick={onClick}
    >
      <CardHeader appointment={appointment} />
      <ProcedurePills claims={appointment.claims} procedures={appointment.patientProcedures} />
      {disabled ? (
        <div className="flex flex-col gap-y-2 w-full" data-testid="no-insurance-section">
          <Divider className="border-dashed" />
          <div>No valid insurances to file a claim</div>
        </div>
      ) : appointment.claimsLoaded ? (
        appointment.claims ? (
          <div className="flex flex-col gap-y-2 w-full" data-testid="claims-section">
            <Divider className="border-dashed" />
            {appointment.claims.map((claim) => (
              <ClaimSummary claim={claim} key={claim.claimUuid} />
            ))}
          </div>
        ) : null
      ) : (
        <div className="flex flex-col gap-y-2 w-full">
          <Divider className="border-dashed" />
          <Skeleton className="h-11 w-full" />
        </div>
      )}
    </AppointmentClaimSummaryCardWrapper>
  );
};
