import { FC, PropsWithChildren } from "react";
import { cx } from "@libs/utils/cx";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon } from "@libs/components/UI/Icon";
import { RequiredAsterisk } from "@libs/components/UI/RequiredAsterisk";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as LockIcon } from "@libs/assets/icons/lock.svg";
import { CurrentElement, FormElementRequest } from "components/Settings/Forms/types";
import { PaletteMap } from "components/Settings/Forms/utils";
import { ElementIcon } from "components/Settings/Forms/ElementIcon";

export const DRAG_FORM_ELEMENT_HANDLE = "drag-form-element-handle";

export const FormStructureElement: FC<
  PropsWithChildren<{
    element: FormElementRequest;
    isSelected?: boolean;
    isAddingElement?: boolean;
    onClick?: Func;
    onDelete?: Func;
    className?: string;
    isDragging?: boolean;
    isDraggingElement?: boolean;
  }>
> = ({
  element,
  children,
  isDragging,
  isDraggingElement,
  isAddingElement,
  isSelected,
  className,
  onClick,
  onDelete,
}) => {
  const palette = PaletteMap[element.type];

  return (
    <FormStructureElementWrapper
      isDraggingElement={isDraggingElement}
      isSelected={isSelected}
      className={className}
    >
      <div
        className={cx(
          "items-center flex",
          isDraggingElement ? "invisible" : "visible",
          !isDragging && "group"
        )}
      >
        <button
          type="button"
          className={cx("text-left py-3 pl-4 flex flex-1 items-center", DRAG_FORM_ELEMENT_HANDLE)}
          onClick={onClick}
        >
          <ElementIcon Icon={palette.Icon} />
          <h4 className="text-xs px-2">
            {children}
            {element.type === "CONSENT" ||
            ("settings" in element && new Set(element.settings).has("REQUIRED")) ? (
              <RequiredAsterisk className="text-xs" />
            ) : null}
          </h4>
        </button>
        {element.tag ? (
          <div className="pr-4">
            <Icon SvgIcon={LockIcon} />
          </div>
        ) : onDelete ? (
          <div
            className={cx(
              "flex-none pr-4 justify-center items-center opacity-0",
              !isAddingElement &&
                "group-hover:opacity-100 group-hover:transition-opacity group-hover:delay-100"
            )}
          >
            <ButtonIcon
              tooltip={{ content: "Delete", theme: "SMALL" }}
              disabled={isAddingElement}
              SvgIcon={DeleteIcon}
              onClick={onDelete}
            />
          </div>
        ) : null}
      </div>
    </FormStructureElementWrapper>
  );
};

export const DraftElementStructure: FC<{
  draftElement: CurrentElement;
}> = ({ draftElement }) => {
  const palette = PaletteMap.TEXT_INPUT;

  return draftElement.element ? (
    <FormStructureElement element={draftElement.element} isSelected={true}>
      {draftElement.element.title}
    </FormStructureElement>
  ) : (
    <FormStructureElementWrapper isSelected>
      <div className="items-center flex py-3 pl-4">
        <div className="invisible">
          <ElementIcon Icon={palette.Icon} />
        </div>
      </div>
    </FormStructureElementWrapper>
  );
};

export const FormStructureElementWrapper: FC<
  PropsWithChildren<{
    isSelected?: boolean;
    className?: string;
    isDraggingElement?: boolean;
  }>
> = ({ children, isDraggingElement, isSelected, className }) => {
  return (
    <div
      className={cx(
        "rounded border",
        className,
        isDraggingElement ? "bg-greyLightest" : " bg-white",
        isDraggingElement ? "border-greyLightest" : isSelected ? "border-primaryTheme" : "border-greyLighter"
      )}
    >
      {children}
    </div>
  );
};
