import React, { useMemo, useCallback } from "react";
import { ProductionByProviderComboVO, NameVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { keys } from "@libs/utils/object";
import { getProductionByProviderComboQuery } from "api/reporting/queries";
import { FooterCell } from "components/Dashboard/Tables/FooterCell";
import { useQueryParams } from "hooks/useQueryParams";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import {
  NOT_SCHEDULED_FILTER,
  PRODUCTION_BY_HYGIENIST_COLUMNS,
} from "components/Dashboard/PracticeProduction/constants";
import { DashboardTable } from "components/Dashboard/Tables/DashboardTable";
import { ProductionByHygienistTableSection } from "components/Dashboard/PracticeProduction/ProductionByHygienistTableSection";
import { LoadingCells } from "components/Dashboard/Tables/LoadingCells";
import { PracticeProductionQuery } from "utils/routing/dashboard";
import { useProductionTableTabProps } from "components/Dashboard/PracticeProduction/hooks/useProductionTableTabProps";
import { DashboardTableTabs } from "components/Dashboard/Tables/DashboardTableTabs";
import { useDownloadProductionByHygienistCSV } from "components/Dashboard/PracticeProduction/hooks/useDownloadProductionByHygienistCSV";
import { dashboardFiltersForTables } from "components/Dashboard/utils/filters";
import { useProviderRollupFilterOptions } from "components/Dashboard/hooks/useProviderRollupFilterOptions";
import { TableSelectProvider } from "components/Dashboard/Tables/TableSelectProvider";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";

const sortProvidersAlphabetically = (a: NameVO, b: NameVO) => {
  return a.shortDisplayName.localeCompare(b.shortDisplayName);
};
const useProductionByProviderQuery = () => {
  const { practiceId } = useAccount();

  const { query } = useQueryParams("dashboardPracticeProduction");
  const dateWindow = useTimeSeriesPageSelections(query);
  const { selectedTimeSegmentParams } = dateWindow;

  const [productionQuery] = useApiQueries([
    getProductionByProviderComboQuery({
      args: {
        practiceId,
        data: {
          ...selectedTimeSegmentParams,
          filters: [NOT_SCHEDULED_FILTER, ...dashboardFiltersForTables(query.filters)],
        },
      },
    }),
  ]);
  const isLoading = productionQuery.isLoading;
  const providerCombos = productionQuery.data?.byProviders;
  const { hygienistCombos, unassigned } = useMemo(() => {
    const combos = providerCombos ?? [];
    const map: { [id: string]: ProductionByProviderComboVO[] } = {};
    const unassignedProviders: ProductionByProviderComboVO[] = [];

    for (const providerCombo of combos) {
      if (providerCombo.providerName.id === providerCombo.dentistName.id) {
        unassignedProviders.push(providerCombo);
        continue;
      } else if (!(providerCombo.providerName.id in map)) {
        map[providerCombo.providerName.id] = [];
      }

      map[providerCombo.providerName.id].push(providerCombo);
    }

    return {
      hygienistCombos: keys(map)
        .map((key) => {
          const [firstCombo] = map[key];

          return {
            hygienistName: firstCombo.providerName,
            subtotals: map[key],
            total: map[key].reduce((acc, combo) => acc + combo.totalProducedAmount, 0),
          };
        })
        .sort((a, b) => {
          const [comboA] = a.subtotals;
          const [comboB] = b.subtotals;

          return sortProvidersAlphabetically(comboA.providerName, comboB.providerName);
        }),
      unassigned: {
        subtotals: unassignedProviders.sort((a, b) => {
          return sortProvidersAlphabetically(a.dentistName, b.dentistName);
        }),
        total: unassignedProviders.reduce((acc, combo) => acc + combo.totalProducedAmount, 0),
      },
    };
  }, [providerCombos]);

  return {
    isLoading,
    hygienistCombos,
    unassigned,
    error: productionQuery.error,
    dateWindow,
    totals: productionQuery.data?.total,
    isEmpty: !isLoading && providerCombos?.length === 0,
  };
};

export const ProductionByHygienistTableRoute: React.FC = () => {
  const { hygienistCombos, unassigned, isEmpty, isLoading, totals, error, dateWindow } =
    useProductionByProviderQuery();
  const { query, updateQuery } = useQueryParams("dashboardPracticeProduction");
  const { filters } = query;
  const tableExpanded = query["table.fullScreen"];
  const handleRouteStateChange = useCallback(
    (updates: Partial<PracticeProductionQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );
  const { selectedTimeSegment } = dateWindow;
  const { downloadCSV } = useDownloadProductionByHygienistCSV({
    hygienistCombos,
    unassigned,
    totals,
    selectedTimeSegment,
  });
  const { tabs } = useProductionTableTabProps({
    query,
  });
  const { data: providerFilterOptions } = useProviderRollupFilterOptions({
    metric: "productionAmount",
    dateWindow,
    ...query,
  });

  return (
    <>
      <DashboardTableTabs
        tabs={tabs}
        onUpdateParams={handleRouteStateChange}
        onClickDownload={isLoading ? undefined : downloadCSV}
        query={query}
        includePatientSearch={false}
        id="practice-production-by-hygienist"
      >
        {
          <div className="relative flex gap-3">
            {
              // Since they can select provider via the chart, only show provider selection when table full screen
            }
            {tableExpanded && (
              <TableSelectProvider
                filters={filters}
                onFiltersChanged={(newFilters) => {
                  handleRouteStateChange({ filters: newFilters });
                }}
                providerFilterOptions={providerFilterOptions}
              />
            )}
          </div>
        }
      </DashboardTableTabs>
      <DashboardTable
        id="productionByProviderComboTable"
        isError={Boolean(error)}
        isEmpty={isEmpty}
        isLoading={isLoading}
        columnWidths={PRODUCTION_BY_HYGIENIST_COLUMNS.map(({ width }) => width)}
        headerRow={<DashboardHeaderRow headers={PRODUCTION_BY_HYGIENIST_COLUMNS} />}
        footerRow={
          <>
            <FooterCell className="col-span-4">Totals</FooterCell>
            <FooterCell loading={isLoading} align="right">
              {totals && formatCurrency(totals.totalProducedAmount)}
            </FooterCell>
          </>
        }
      >
        {isLoading ? (
          <LoadingCells columnCount={PRODUCTION_BY_HYGIENIST_COLUMNS.length} />
        ) : isEmpty ? null : (
          <>
            {hygienistCombos.map((combos) => {
              return (
                <ProductionByHygienistTableSection
                  key={combos.hygienistName.id}
                  dentists={combos.subtotals}
                  sectionName={combos.hygienistName.fullDisplayName}
                  total={combos.total}
                />
              );
            })}
            <ProductionByHygienistTableSection
              dentists={unassigned.subtotals}
              sectionName="No Hygienist"
              total={unassigned.total}
            />
          </>
        )}
      </DashboardTable>
    </>
  );
};
