import { cx } from "@libs/utils/cx";
import { TooltipLabel } from "components/UI/TooltipLabel";
import { AddProcedureDuration } from "components/Settings/Scheduling/Sections/ProcedureDurations/AddProcedureDuration";
import { DurationRenderer } from "components/Settings/Scheduling/Sections/ProcedureDurations/DurationRenderer";

export const DurationSection: React.FC<{
  duration: string;
  onRemove: (index: number) => void;
  onAdd: (durationType: "A" | "P") => void;
  error?: string;
  className?: string;
}> = ({ duration, onRemove, onAdd, error, className }) => (
  <div className={cx("flex flex-col", className)}>
    <TooltipLabel
      tooltip={{
        content:
          "Make sure to add the Assistant and Provider time increments in the order they will take place during the procedure.",
      }}
    >
      <span className="font-sansSemiBold text-xs">Duration</span>
    </TooltipLabel>

    <div className="mt-2">
      {duration ? (
        <DurationRenderer duration={duration} showDuration onRemove={onRemove} />
      ) : (
        <div className="text-xs text-greyLight h-5">
          Click on A or P to add 5 min increments of Assistant or Provider time
        </div>
      )}
      <AddProcedureDuration onAddDuration={onAdd} duration={duration} error={error} />
    </div>
  </div>
);
