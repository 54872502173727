import { FeeScheduleVO, InsuranceCarrierVO, InsurancePlanVO } from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { isNullish } from "@libs/utils/types";
import { toFilterComponentsProps } from "@libs/components/UI/queryFilterPillsUtils";
import { PatientsQuery } from "utils/routing/patient";

export type PatientsFilterQuery = Pick<
  PatientsQuery,
  | "search"
  | "appointmentCriteria"
  | "patientCriteria.ageGroup.min"
  | "patientCriteria.ageGroup.max"
  | "patientCriteria.carrierIds"
  | "patientCriteria.primaryInsuranceExpired"
  | "patientCriteria.primaryInsuranceVerifyStatuses"
  | "patientCriteria.gender"
  | "patientCriteria.status"
  | "patientCriteria.feeScheduleId"
  | "patientCriteria.insurancePlanUuid"
>;

export const getPatientsFilterProps = (
  params: PatientsFilterQuery,
  insuranceCarriersMap: Record<number, InsuranceCarrierVO | undefined>,
  feeScheduleDetails: FeeScheduleVO | undefined,
  insurancePlan: InsurancePlanVO | undefined
) => {
  return toFilterComponentsProps(params, [
    {
      type: "default",
      prop: "search",
      format: (val) => `Search: ${val.trim()}`,
    },
    {
      type: "default",
      prop: "appointmentCriteria",
      format: (val) => sentenceCaseConstant(val),
    },
    {
      type: "default",
      prop: "patientCriteria.gender",
      format: (val) => sentenceCaseConstant(val),
    },
    {
      type: "list",
      prop: "patientCriteria.carrierIds",
      format: (val) => insuranceCarriersMap[Number(val)]?.name ?? "",
    },
    {
      type: "default",
      prop: "patientCriteria.insurancePlanUuid",
      format: () => insurancePlan?.groupName ?? "Unnamed Insurance Plan",
    },
    {
      type: "default",
      prop: "patientCriteria.feeScheduleId",
      format: (_val) => (feeScheduleDetails?.name ? `Fee Schedule: ${feeScheduleDetails.name}` : ""),
    },
    {
      type: "list",
      prop: "patientCriteria.status",
      format: (val) => sentenceCaseConstant(val),
    },
    {
      type: "list",
      prop: "patientCriteria.primaryInsuranceVerifyStatuses",
      format: (val) => sentenceCaseConstant(val),
    },
    {
      type: "combined",
      props: ["patientCriteria.ageGroup.min", "patientCriteria.ageGroup.max"],
      format: (val) => {
        const min = val["patientCriteria.ageGroup.min"];
        const max = val["patientCriteria.ageGroup.max"];

        return isNullish(min) || isNullish(max) ? "" : `Age Group: ${min} - ${max}`;
      },
    },
    {
      type: "default",
      prop: "patientCriteria.primaryInsuranceExpired",
      format: (val) => (val === true ? "Expired" : ""),
    },
  ]);
};
