import React, { useEffect, useLayoutEffect, useMemo } from "react";
import { autoUpdate, flip, offset, shift, useFloating } from "@floating-ui/react-dom-interactions";
import { createPortal } from "react-dom";
import { useBoolean } from "@libs/hooks/useBoolean";
import { usePortalElement } from "@libs/contexts/PortalContext";
import { ClickOutside } from "@libs/contexts/ClickOutsideListenerContext";
import { ReactComponent as LayersIcon } from "@libs/assets/icons/layers.svg";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { ToolBoat } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ToolBoat";
import { ToolbarButton } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ToolbarButton";

import { DrawLayer } from "components/PatientProfile/Imaging/types/imageEditor";

type Props = {
  onToggleLayer: (layer: DrawLayer) => void;
  layersShown: Set<DrawLayer>;
  includePearlLayer?: boolean;
  onMenuToggled?: (open: boolean) => void;
};
export const LayersTool: React.FC<Props> = ({
  onToggleLayer,
  layersShown,
  onMenuToggled,
  includePearlLayer,
}) => {
  const layerOptions = useMemo(() => {
    return [
      {
        label: "AI Second Opinion",
        value: "pearl" as const,
      },
      {
        label: "Annotations",
        value: "annotations" as const,
      },
      {
        label: "Filters",
        value: "filters" as const,
      },
    ].filter((option) => {
      if (option.value === "pearl" && !includePearlLayer) {
        return false;
      }

      return true;
    });
  }, [includePearlLayer]);
  const menuOpen = useBoolean(false);
  const triggerRef = React.useRef<HTMLButtonElement>(null);
  const { x, y, reference, floating, update, strategy, refs } = useFloating({
    placement: "right-start",
    // Padding allows for small amount of buffer when there is overflow offscreen, and menu is shifted
    middleware: [offset({ mainAxis: 8.5, crossAxis: -9 }), shift({ padding: 12 }), flip()],
  });
  const portalTarget = usePortalElement();

  useEffect(() => {
    if (!refs.reference.current || !refs.floating.current) {
      return;
    }

    // Only call this when the floating element is rendered
    autoUpdate(refs.reference.current, refs.floating.current, update);
  }, [refs.reference, refs.floating, update]);

  useLayoutEffect(
    () => {
      reference(triggerRef.current);
    },

    // Disabling rule as suggested in
    // https://floating-ui.com/docs/react-dom#external-reference
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [triggerRef.current]
  );

  return (
    <ToolBoat>
      <ToolbarButton
        ref={triggerRef}
        onMouseDown={() => {
          onMenuToggled?.(!menuOpen.isOn);
          menuOpen.toggle();
        }}
        toggled={menuOpen.isOn}
        SvgIcon={LayersIcon}
      />
      {menuOpen.isOn ? (
        <ClickOutside
          handler={() => {
            onMenuToggled?.(false);
            menuOpen.off();
          }}
        >
          {(tracker) =>
            portalTarget &&
            createPortal(
              <div
                role="menu"
                className="z-10 absolute bg-slate-800 text-white p-4"
                ref={floating}
                style={{
                  position: strategy,
                  top: y ?? "",
                  left: x ?? "",
                  width: "auto",
                }}
                {...tracker}
              >
                <div className="text-xs font-sansSemiBold">Show Layers</div>
                <CheckboxList
                  options={layerOptions}
                  selectedValues={layersShown}
                  includeDarkMode
                  layout="vert"
                  onChange={(updatedSet, e) => {
                    onToggleLayer(e.target.value as DrawLayer);
                  }}
                />
              </div>,
              portalTarget
            )
          }
        </ClickOutside>
      ) : null}
    </ToolBoat>
  );
};
