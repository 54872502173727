import React from "react";
import { InboundReferralResponse, InboundReferralVO } from "@libs/api/generated-api";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { SelectInsuranceCarrierFilters } from "components/Dashboard/Tables/SelectInsuranceCarrierFilters";
import { useFilterTokenProps } from "components/Dashboard/hooks/useFilterTokenProps";
import { ReferralsQuery } from "utils/routing/dashboard";
import { DashboardTableFilters } from "components/Dashboard/Tables/DashboardTableFilters";
import { DashboardTableHeader } from "components/Dashboard/Tables/DashboardTableHeader";
import { HeaderData } from "components/Dashboard/Tables/types";
import { CarriersQuery } from "components/Dashboard/types";
import { useFlattenEntries } from "components/Dashboard/hooks/useFlattenEntries";
import { SelectReferralType } from "components/Dashboard/Tables/SelectReferralType";
import { SelectHasFirstAppt } from "components/Dashboard/Tables/SelectHasFirstAppointment";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import { ReferralsTableRow } from "components/Dashboard/Referrals/ReferralsTableRow";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";

interface Props {
  carriersQuery: CarriersQuery;
  inboundReferralStatsFilteredQuery: UseInfiniteApiQueryResult<InboundReferralResponse>;
  isDownloadingTable: boolean;
  onClickDownload: Func;
  onUpdateParams: (updates: Partial<ReferralsQuery>) => void;
  patientSearch: string;
  referralOptions: {
    label: string;
    value: string;
  }[];
  query: ReferralsQuery;
}

export const REFERRALS_COLUMN_DATA: HeaderData[] = [
  { label: "Patient", width: "1fr", sortField: "patientLastName" },
  { label: "Insurance", width: "1fr", sortField: "insuranceCarrierId" },
  { label: "First Appt Date", width: "1fr", sortField: "firstAppointmentDate" },
  { label: "Referral Source", width: "1fr", sortField: "referredBy" },
  { label: "Additional Information", width: "1fr" },
  {
    label: "Net Produced",
    tooltip: { content: "The net production amount of all procedures across all completed appointments." },
    width: "1fr",
  },
];

export const ReferralsTable: React.FC<Props> = ({
  carriersQuery,
  inboundReferralStatsFilteredQuery,
  isDownloadingTable,
  onClickDownload,
  onUpdateParams,
  patientSearch,
  referralOptions,
  query,
}) => {
  const { data: paginatedData, isLoading } = inboundReferralStatsFilteredQuery;
  const totalRows = getInfiniteQueryPagingDetails(paginatedData)?.totalElements;
  const rows = useFlattenEntries<InboundReferralVO>(paginatedData);
  const isEmpty = !isLoading && rows.length === 0;
  const filterParams = {
    filters: query.filters,
    patientSearch: patientSearch === "" ? undefined : patientSearch,
  };
  const filterProps = useFilterTokenProps({ carriers: carriersQuery.data, options: filterParams });
  const handleSortClick = React.useCallback(
    (tableSort: ColumnSortOrder[]) => {
      onUpdateParams({
        tableSort,
      });
    },
    [onUpdateParams]
  );

  return (
    <div className="h-full w-full flex flex-col min-h-0">
      <DashboardTableHeader
        id="referrals-table-header"
        isDownloading={isDownloadingTable}
        onClickDownload={onClickDownload}
        onUpdateParams={onUpdateParams}
        query={query}
        title="Referrals"
      >
        <SelectInsuranceCarrierFilters
          carriersQuery={carriersQuery}
          dashboardFilterType="insuranceCarrierId"
          filters={query.filters}
          onChange={({ filters }) => onUpdateParams({ filters })}
        />
        <SelectReferralType
          filters={query.filters}
          onUpdateParams={onUpdateParams}
          options={referralOptions}
        />
        <SelectHasFirstAppt onUpdateParams={onUpdateParams} query={query} />
      </DashboardTableHeader>
      <DashboardTableFilters {...filterParams}>
        <QueryFilterPills
          {...filterProps}
          numResults={totalRows}
          onClearAll={() => onUpdateParams({ filters: [], patientSearch: undefined })}
          onUpdateParams={onUpdateParams}
        />
      </DashboardTableFilters>
      <DashboardInfiniteTable
        columnWidths={REFERRALS_COLUMN_DATA.map(({ width }) => width)}
        id="dashboard-referrals-table"
        infiniteQuery={inboundReferralStatsFilteredQuery}
        isEmpty={isEmpty}
        headerRow={
          <DashboardHeaderRow
            headers={REFERRALS_COLUMN_DATA}
            onSortClick={handleSortClick}
            sortOrders={query.tableSort}
          />
        }
      >
        {rows.map((item, index) => {
          return <ReferralsTableRow key={`${item.patient.id}-${index}`} data={item} />;
        })}
      </DashboardInfiniteTable>
    </div>
  );
};
