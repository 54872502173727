import { FC, useCallback } from "react";
import { formatCurrency } from "@libs/utils/currency";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { PracticeProductionChart } from "components/Dashboard/PracticeProduction/PracticeProductionChart";
import { DashboardLayout } from "components/Dashboard/DashboardLayout";
import { useQueryParams } from "hooks/useQueryParams";

import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { PracticeProductionQuery } from "utils/routing/dashboard";
import { dashboardFiltersForCharting } from "components/Dashboard/utils/filters";
import { getTimeSeriesQuery } from "api/reporting/queries";
import { useFormattedProviderRollups } from "components/Dashboard/hooks/useFormattedProviderRollups";
import { useProviderFilterHandler } from "components/Dashboard/PracticeProduction/hooks/useProviderFilterHandler";
import { FilterByProvider } from "components/Dashboard/Charting/FilterByProvider";
import { DashboardPageHeader } from "components/Dashboard/DashboardPageHeader";
import { useProviderRollupQueries } from "components/Dashboard/hooks/useProviderRollupQueries";
import { useFilteredProviderCategory } from "components/Dashboard/hooks/useFilteredProviderCategory";
import { TableRoutes } from "components/Dashboard/TableRoutes";
import { ProductionByProviderTable } from "components/Dashboard/PracticeProduction/ProductionByProviderTableRoute";
import { ProductionByHygienistTableRoute } from "components/Dashboard/PracticeProduction/ProductionByHygienistTableRoute";
import { ProductionByAppointmentTableRoute } from "components/Dashboard/PracticeProduction/ProductionByAppointmentTableRoute";
import { ProductionByProcedureTableRoute } from "components/Dashboard/PracticeProduction/ProductionByProcedureTableRoute";

const TABLE_ROUTES = [
  {
    path: "providers",
    element: <ProductionByProviderTable />,
  },
  {
    path: "hygienists",
    element: <ProductionByHygienistTableRoute />,
  },
  {
    path: "appointments",
    element: <ProductionByAppointmentTableRoute />,
  },
  {
    path: "procedures",
    element: <ProductionByProcedureTableRoute />,
  },
];

export const PracticeProductionRoute: FC = () => {
  const { query, updateQuery } = useQueryParams("dashboardPracticeProduction");
  const handleRouteStateChange = useCallback(
    (updates: Partial<PracticeProductionQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );

  const dateWindow = useTimeSeriesPageSelections({
    ...query,
    includeProjectedDates: true,
  });

  const handleProviderFilterClicked = useProviderFilterHandler({
    filters: query.filters,
    onUpdateFilters: handleRouteStateChange,
  });

  const providerFilters = useFilteredProviderCategory(query.filters);

  const seriesRequest = {
    resolution: query.resolution,
    ...dateWindow.timeSeriesParams,
    filters: dashboardFiltersForCharting(query.filters),
  };
  const { practiceId } = useAccount();
  const { providersInTimeWindowQuery, allProvidersQuery } = useProviderRollupQueries({
    metric: "productionAmount",
    dateWindow,
    filters: [
      {
        type: "isScheduled",
        values: ["false"],
      },
    ],
  });

  const [netProducedQuery, grossProductionQuery, providersInTimeWindowQueryResult, allProvidersQueryResult] =
    useApiQueries([
      getTimeSeriesQuery({
        args: {
          practiceId,
          data: {
            ...seriesRequest,
            metric: "productionAmount",
            groupBy: "isScheduled",
            filters: seriesRequest.filters,
          },
        },
      }),
      getTimeSeriesQuery({
        args: {
          practiceId,
          data: {
            ...seriesRequest,
            metric: "grossProductionAmount",
            groupBy: "isScheduled",
            filters: seriesRequest.filters,
          },
        },
      }),
      providersInTimeWindowQuery,
      allProvidersQuery,
    ]);
  const { data: providerFilterOptions, isLoading: isLoadingProviderRollups } = useFormattedProviderRollups(
    providersInTimeWindowQueryResult,
    allProvidersQueryResult,
    {
      ...providerFilters,
    }
  );
  const fullScreen = query["table.fullScreen"];

  return (
    <DashboardLayout
      className="min-w-[1400px]"
      header={
        <>
          <DashboardPageHeader
            question="practice-production"
            focusDate={query.focusDate}
            dateWindow={dateWindow}
            resolution={query.resolution}
            chartDisplay={query.chartDisplay}
            includeProjectedDates
            onUpdateParams={handleRouteStateChange}
            allowDateRange
          />
          {fullScreen ? null : (
            <PracticeProductionChart
              resolution={query.resolution}
              filters={query.filters}
              dateWindow={dateWindow}
              netProducedQuery={netProducedQuery}
              grossProductionQuery={grossProductionQuery}
              onUpdateParams={handleRouteStateChange}
              focusDate={query.focusDate}
              chartDisplay={query.chartDisplay}
            >
              <FilterByProvider
                formatValue={formatCurrency}
                providerFilterOptions={providerFilterOptions}
                providerFilters={providerFilters}
                isLoading={isLoadingProviderRollups}
                headerLabel="Net Produced"
                loadFailed={Boolean(providersInTimeWindowQueryResult.error || allProvidersQueryResult.error)}
                onProviderClicked={handleProviderFilterClicked}
              />
            </PracticeProductionChart>
          )}
        </>
      }
    >
      <TableRoutes routes={TABLE_ROUTES} />
    </DashboardLayout>
  );
};
