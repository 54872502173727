import React from "react";
import { addMonths, secondsToMilliseconds } from "date-fns";
import { InvoiceVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import { SummaryBanner, SummaryCell } from "components/PatientProfile/Billing/SummaryBanner";

interface Props {
  invoice: InvoiceVO;
}

export const InvoiceSummary: React.FC<Props> = ({ invoice }) => {
  const amountDue = invoice.amount - invoice.amountPaid;

  return (
    <SummaryBanner>
      <SummaryCell header="Invoice #">
        {invoice.invoiceNumber === "PENDING" ? "Pending" : invoice.invoiceNumber}
      </SummaryCell>
      <SummaryCell header="Date">
        <DateWithTooltip date={invoice.createdAt} dateAsSeconds={true} format="P" />
      </SummaryCell>
      <SummaryCell header="Due Date">
        <DateWithTooltip
          date={invoice.dueAt || addMonths(secondsToMilliseconds(invoice.createdAt), 1)}
          dateAsSeconds={true}
          format="P"
        />
      </SummaryCell>
      <SummaryCell
        className="text-right"
        header={`Amount Due ${invoice.state === "PARTIALLY_PAID" ? "(Partial)" : ""}`}
      >
        {formatCurrency(amountDue)}
      </SummaryCell>
    </SummaryBanner>
  );
};
