import React, { FC } from "react";
import { ParentSize } from "@visx/responsive";
import { TimeSeriesResponse } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { AXIS_LABEL_PROPS, CHART_COLORS, ColorScheme } from "components/Dashboard/Charting/theme";
import { ChartContainer } from "components/Dashboard/Charting/ChartContainer";
import { UnscheduledCountType } from "components/Dashboard/UnscheduledTreatment/types";
import {
  DashboardStackedBarChart,
  Props as DashboardStackedBarChartProps,
} from "components/Dashboard/Charting/DashboardStackedBarChart";
import { useLongAgingDateFormatter } from "components/Dashboard/hooks/useLongAgingDateFormatter";
import { useTimeSeriesAsAgingChartData } from "components/Dashboard/hooks/useTimeSeriesAsAgingChartData";
import { UNSCHEDULED_TREATMENT_AGING_OPTIONS } from "components/Dashboard/UnscheduledTreatment/constants";
import { useLongAgingOptions } from "components/Dashboard/hooks/useLongAgingOptions";
import { useCenteredChartMargins } from "components/Dashboard/hooks/useCenteredChartMargins";

type Props = {
  width: number;
  height: number;
  dateAgeIndex: number;
  timeSeriesQuery: ApiQueryResult<TimeSeriesResponse>;
  onBarClick: ({ dateAgeIndex }: { dateAgeIndex: number }) => void;
};

const unscheduledReportKeys: UnscheduledCountType[] = ["patientCount"];

const colorScheme: ColorScheme<UnscheduledCountType> = {
  domain: unscheduledReportKeys,
  range: [CHART_COLORS.blue],
};

export const UnscheduledTreatmentChartContent: FC<Props> = ({
  width,
  height,
  timeSeriesQuery,
  dateAgeIndex,
  onBarClick,
}) => {
  const unscheduledTreatmentOptions = useLongAgingOptions({
    labels: UNSCHEDULED_TREATMENT_AGING_OPTIONS,
    includeFuture: false,
  });

  const data = useTimeSeriesAsAgingChartData(timeSeriesQuery, unscheduledTreatmentOptions);
  const formatDate = useLongAgingDateFormatter(unscheduledTreatmentOptions);
  const handleBarClicked = React.useCallback(
    (params: { index: number }) => {
      onBarClick({ dateAgeIndex: params.index });
    },
    [onBarClick]
  );
  const { axisMargin, chartMargin } = useCenteredChartMargins(width);

  const xAxisLabel: DashboardStackedBarChartProps<UnscheduledCountType>["xAxisLabel"] = React.useMemo(() => {
    return {
      text: "Treatment Planned",
      labelProps: {
        ...AXIS_LABEL_PROPS,
        textAnchor: "end",
        x: 0,
        y: 20,
      },
    };
  }, []);

  return (
    <DashboardStackedBarChart
      data={data}
      randomizeLoadingBars
      chartMargin={chartMargin}
      axisMargin={axisMargin}
      width={width}
      height={height}
      xAxisLabel={xAxisLabel}
      isLoading={timeSeriesQuery.isLoading}
      colorScheme={colorScheme}
      onClickBar={handleBarClicked}
      keys={unscheduledReportKeys}
      selectedBarIndex={dateAgeIndex}
      formatDate={formatDate}
      labelFormat={() => "Patient count"}
    />
  );
};

export const UnscheduledTreatmentChart: FC<Omit<Props, "width" | "height">> = (props) => {
  return (
    <ChartContainer>
      <ParentSize className="flex-1 relative">
        {({ height, width }: { width: number; height: number }) => {
          return <UnscheduledTreatmentChartContent width={width} height={height} {...props} />;
        }}
      </ParentSize>
    </ChartContainer>
  );
};
