import React from "react";
import { ClaimInsuranceCarrierVO, DentalProcedureVO, ProviderVO } from "@libs/api/generated-api";
import { toFilterComponentsProps } from "@libs/components/UI/queryFilterPillsUtils";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";
import { formatDashboardFilter } from "components/Dashboard/utils/filters";

type FormatDashboardFilterArgs = {
  options: {
    filters: DashboardFilter[];
    patientSearch?: string;
    labelProviderAsPrimary?: boolean;
  };

  selectedProviderDisplayName?: string;
  procedures?: DentalProcedureVO[];
  carriers?: ClaimInsuranceCarrierVO[];
  providers?: ProviderVO[];
};

export const getFilterProps = ({
  options,
  selectedProviderDisplayName,
  procedures,
  carriers,
  providers,
}: FormatDashboardFilterArgs) => {
  return toFilterComponentsProps(options, [
    {
      type: "list",
      prop: "filters",
      format: (filter) =>
        formatDashboardFilter({
          filter,
          providerDisplayName: selectedProviderDisplayName,
          procedures,
          providers,
          carriers,
          options,
        }),
    },
    {
      type: "default",
      prop: "patientSearch",
      format: (val) => `Patient: ${val.trim()}`,
    },
  ]);
};

export const useFilterTokenProps = ({
  options,
  selectedProviderDisplayName,
  providers,
  procedures,
  carriers,
}: FormatDashboardFilterArgs) => {
  return React.useMemo(() => {
    return getFilterProps({ options, selectedProviderDisplayName, procedures, carriers, providers });
  }, [options, selectedProviderDisplayName, procedures, carriers, providers]);
};
