import { produce } from "immer";
import { FormDateInputElementRequest, FormVO } from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { toggleSet } from "@libs/utils/toggleSet";
import { ValidationResult } from "@libs/hooks/useValidation";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { RequiredCheckbox } from "components/Settings/Forms/RequiredCheckbox";
import { FormFieldSelect } from "components/UI/FormFieldSelect";

import { dateSchema } from "./schema";

const dateRangeSettings: ListItem<FormDateInputElementRequest["settings"]>[] = ["FUTURE", "PAST"];

export const DateFormContent = ({
  formState: [{ draft }, setForm],
  validation,
  isLocked,
  formType,
}: {
  validation: ValidationResult<FormDateInputElementRequest, typeof dateSchema>;
  formState: UseState<{ draft: FormDateInputElementRequest }>;
  isLocked?: boolean;
  formType: FormVO["type"];
}) => {
  const isRequired = draft.settings.includes("REQUIRED");
  const restriction = draft.settings.find((setting) => isOneOf(setting, dateRangeSettings)) ?? "";

  const handleToggleSetting = (value: ListItem<typeof draft.settings>) => {
    setForm((last) =>
      produce(last, (next) => {
        next.draft.settings = [...toggleSet(new Set(next.draft.settings), value)];
      })
    );
  };

  const handleSelectRestriction = (value: ListItem<typeof draft.settings> | "") => {
    setForm((last) =>
      produce(last, (next) => {
        next.draft.settings = next.draft.settings.filter((val) => !isOneOf(val, dateRangeSettings));

        if (value) {
          next.draft.settings.push(value);
        }
      })
    );
  };

  const handleUpdateTitle = (value: string) => {
    setForm((last) =>
      produce(last, (next) => {
        next.draft.title = value;
      })
    );
  };

  return (
    <div className="flex flex-col gap-y-4">
      <FormFieldInput
        label="Question"
        edit={!isLocked}
        required={true}
        value={draft.title}
        onChange={(e) => handleUpdateTitle(e.target.value)}
        error={validation.title.$error}
      />

      <FormFieldSelect
        label="Date is"
        edit={!isLocked}
        display="label"
        options={[
          { value: "", label: "Any date" },
          { value: "PAST", label: "In the past" },
          { value: "FUTURE", label: "In the future" },
        ]}
        value={restriction}
        onItemSelected={handleSelectRestriction}
        isClearable={false}
        isSearchable={false}
      />
      {formType === "INTAKE" ? (
        <RequiredCheckbox checked={isRequired} onChange={() => handleToggleSetting("REQUIRED")} />
      ) : null}
    </div>
  );
};
