import { FC, FormEvent, useMemo, useCallback } from "react";

import { EmailConfigVO, UpdateEmailConfigRequest } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { stripEmailDomain, stripAllButEmailDomain } from "@libs/utils/inputFormatting";
import { email } from "@libs/utils/validators";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Form } from "@libs/components/UI/Form";

import { SentFromEmailInput } from "components/Settings/Communications/SentFromEmailInput";

import { useEditServerState } from "hooks/useEditServerState";

interface Props {
  formId: string;
  emailConfig: EmailConfigVO;
  onUpdateEmails: (emails: Pick<UpdateEmailConfigRequest, "replyToEmail" | "fromEmail">) => void;
  isEditing: boolean;
}

export const EmailPreferences: FC<Props> = ({ formId, emailConfig, onUpdateEmails, isEditing }) => {
  const emailsServerState = useMemo(
    () => ({ fromEmail: emailConfig.fromEmail, replyToEmail: emailConfig.replyToEmail }),
    [emailConfig.fromEmail, emailConfig.replyToEmail]
  );

  const [emails, handleChange] = useEditServerState(emailsServerState, isEditing);

  const { validate, result } = useValidation(emails, {
    fromEmail: [
      {
        $v: () => emailConfig.domainVerified,
        $error: "Sending domain must be verified",
        $ignore: Boolean(emailConfig.domain && emailConfig.domainVerified),
      },
      {
        $v: email,
        $error: "Please enter a valid email",
      },
    ],
    replyToEmail: [
      {
        $v: email,
        $error: "Please enter a valid email",
      },
    ],
  });

  const handleUpdateEmails = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!validate().$isValid) {
        return;
      }

      onUpdateEmails({
        fromEmail: emails.fromEmail || undefined,
        replyToEmail: emails.replyToEmail || undefined,
      });
    },
    [emails, validate, onUpdateEmails]
  );

  return (
    <Form id={formId} className="flex flex-col gap-y-6 max-w-xl" onSubmit={handleUpdateEmails}>
      <h2 className="font-sansSemiBold text-sm">Email Addresses</h2>

      <SentFromEmailInput
        label="'Sent From' Email"
        description={
          isEditing
            ? "Enter the email address you would like emails to send from."
            : "Email address you would like emails to send from."
        }
        domain={emailConfig.domain}
        value={emails.fromEmail ? stripEmailDomain(emails.fromEmail) : undefined}
        onChange={(e) =>
          handleChange(
            (draft) =>
              (draft.fromEmail = `${e.target.value}@${stripAllButEmailDomain(emailConfig.domain ?? "")}`)
          )
        }
        formatReadOnlyValue={(value) =>
          value ? `${value}@${stripAllButEmailDomain(emailConfig.domain ?? "")}` : "-"
        }
        error={result.fromEmail.$error}
        edit={isEditing}
      />

      <FormFieldInput
        label="'Reply To' Email"
        description={
          isEditing
            ? "Enter the email address you would like emails to go to if patients reply."
            : "Email address you would like emails to go to if patients reply."
        }
        value={emails.replyToEmail}
        onChange={(e) => handleChange((draft) => (draft.replyToEmail = e.target.value.trim()))}
        error={result.replyToEmail.$error}
        edit={isEditing}
      />
    </Form>
  );
};
