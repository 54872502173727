import { useCallback, useMemo, useState } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { useBoolean } from "@libs/hooks/useBoolean";
import { pluralize } from "@libs/utils/pluralize";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { useAccount } from "@libs/contexts/AccountContext";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { DetailsModalPage } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/DetailsModalPage";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { getPatientInsuranceQuery } from "api/patientInsurance/queries";
import { InsurancePlanDetailsFooter } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/Footer";
import {
  getAllInsuranceCarriersQuery,
  getAutoEligibilityCarriers,
  getCarrier,
  getInfiniteInsurancePlans,
} from "api/practiceInsurance/queries";
import { getInfiniteFeeSchedulesV2Query, getPracticeFeeScheduleNamesQuery } from "api/feeSchedule/queries";
import { CarrierDetailsSection } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/CarriersAndPlansTab/CarrierDetailsSection";
import { PlanSection } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/CarriersAndPlansTab/PlanSection";
import { usePlanFields } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/usePlanFields";
import {
  verifyInsurancePlanBenefit,
  unlinkPatientInsuranceFromPlan,
  linkPatientInsuranceToPlan,
} from "api/patientInsurance/mutations";
import { handleError } from "utils/handleError";
import { EditPlanButton } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/CarriersAndPlansTab/EditPlanButton";
import { InsurancePlanFlyover } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/CarriersAndPlansTab/InsurancePlanFlyover";
import { Divider } from "components/UI/Divider";
import { TabBanner } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/TabBanner";
import { isAutoVerified } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/SubscriberTab/utils";

export type FormType = "duplicate" | "edit" | "new" | null;

// eslint-disable-next-line complexity, max-statements
export const CarrierAndPlanRoute: React.FC = () => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const { insuranceId, patientId } = usePathParams("patientInsuranceDetails");
  const { query } = useQueryParams("patientInsuranceDetails");
  const backUrl = query.from ?? paths.patientTab({ patientId, tab: "insurance" });
  const isCreating = Boolean(query.isAdding);
  const [patientInsuranceQuery] = useApiQueries([
    getPatientInsuranceQuery({
      args: {
        includeBenefitCoverage: true,
        includeInsurancePlan: true,
        insuranceId: insuranceId === "new" ? 0 : insuranceId,
        patientId,
        practiceId,
      },
    }),
  ]);

  const { data: patientInsurance, isFetched } = patientInsuranceQuery;

  const insurancePlan = patientInsurance?.insurancePlan;
  const subscriber = patientInsurance?.patientInsurance.subscriber;
  const carrierId = insurancePlan?.carrier.id ?? subscriber?.carrierId;
  const [allInsuranceCarriersQuery, autoEligibilityCarriersQuery, carrierQuery, feeSchedulesQuery] =
    useApiQueries([
      getAllInsuranceCarriersQuery({ args: { practiceId } }),
      getAutoEligibilityCarriers({ args: { practiceId } }),
      getCarrier({
        args: { carrierId: carrierId ?? 0, practiceId },
        queryOptions: { enabled: Boolean(carrierId) },
      }),
      getPracticeFeeScheduleNamesQuery({
        args: { carrierId: carrierId ?? 0, practiceId },
        queryOptions: { enabled: Boolean(carrierId) },
      }),
    ]);

  const carriers = useMemo(() => {
    const autoEligibilityCarrierIds = new Set(autoEligibilityCarriersQuery.data?.carrierIds ?? []);

    return [...(allInsuranceCarriersQuery.data ?? [])].sort((a, b) => {
      if (autoEligibilityCarrierIds.has(a.id) && !autoEligibilityCarrierIds.has(b.id)) {
        return -1;
      }

      return 0;
    });
  }, [allInsuranceCarriersQuery.data, autoEligibilityCarriersQuery.data?.carrierIds]);

  const [
    linkPatientInsuranceToPlanMutation,
    unlinkPatientInsuranceMutation,
    // TODO: Add back in when working on auto-eligibility with new designs
    _verifyInsurancePlanBenefitMutation,
  ] = useApiMutations([
    linkPatientInsuranceToPlan,
    unlinkPatientInsuranceFromPlan,
    verifyInsurancePlanBenefit,
  ]);

  const menu = useBoolean(false);
  const [formType, setFormType] = useState<FormType>(null);
  const dropPlanModal = useBoolean(false);

  const { handlePlanFieldChange, numOfPatients, onCreateNewPlan, onDropSuccess, planFields } = usePlanFields(
    patientInsurance,
    feeSchedulesQuery.data
  );

  // If planFields.uuid is undefined, this means we're creating a new plan
  const insurancePlanUuid = planFields.uuid;

  const handleLinkInsuranceToPlan = useCallback(
    (planUuid: string) => {
      if (insuranceId !== "new") {
        linkPatientInsuranceToPlanMutation.mutate(
          {
            insuranceId,
            insurancePlanUuid: planUuid,
            patientId,
            practiceId,
          },
          {
            onError: handleError,
            onSuccess: (result) => {
              if (result.data.data.insurancePlan) {
                handlePlanFieldChange(result.data.data.insurancePlan);
              }
            },
          }
        );
      }
    },
    [insuranceId, linkPatientInsuranceToPlanMutation, patientId, practiceId, handlePlanFieldChange]
  );
  const handleUnlinkInsurance = useCallback(
    (id: number, planUuid: string) => {
      unlinkPatientInsuranceMutation.mutate(
        { practiceId, patientId, insuranceId: id, data: { insurancePlanUuid: planUuid } },
        {
          onError: handleError,
          onSuccess: (response) => {
            dropPlanModal.off();
            onDropSuccess(response.data.data.patientInsurance);
          },
        }
      );
    },
    [dropPlanModal, onDropSuccess, patientId, practiceId, unlinkPatientInsuranceMutation]
  );

  const onConfirmDropPlan = useCallback(() => {
    if (insuranceId !== "new" && insurancePlanUuid) {
      handleUnlinkInsurance(insuranceId, insurancePlanUuid);
    }
  }, [handleUnlinkInsurance, insuranceId, insurancePlanUuid]);

  const [searchGroupValue, setSearchGroupValue] = useState("");
  const { search: debouncedSearchGroupValue } = useDebouncedSearch(searchGroupValue);

  const insurancePlansInfiniteQuery = useInfiniteApiQuery(
    getInfiniteInsurancePlans({
      args: {
        carrierIds: [carrierId ?? 0],
        hidePlansWithNoPatients: !searchGroupValue,
        orderBy: "DESCENDING",
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        practiceId,
        searchString: debouncedSearchGroupValue,
        sortColumn: "patientCount",
      },
      queryOptions: {
        enabled: Boolean(carrierId),
        keepPreviousData: true,
      },
    })
  );

  const [feeScheduleSearchString, setFeeScheduleSearchString] = useState("");
  const { search: debouncedFeeScheduleSearchString } = useDebouncedSearch(feeScheduleSearchString);

  const feeSchedulesInfiniteQuery = useInfiniteApiQuery(
    getInfiniteFeeSchedulesV2Query({
      args: {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        practiceId,
        searchString: debouncedFeeScheduleSearchString,
      },
      queryOptions: {
        keepPreviousData: true,
      },
    })
  );

  const handleClickCreateNewPlan = useCallback(() => {
    onCreateNewPlan();
    setFormType("new");
  }, [onCreateNewPlan]);

  const navigateToPrevTab = useCallback(
    (isAdding = false) => {
      navigate(paths.patientInsuranceStep({ patientId, insuranceId, step: "info" }, { isAdding }));
    },
    [navigate, insuranceId, patientId]
  );

  const navigateToNext = useCallback(
    (isAdding = false) => {
      navigate(paths.patientInsuranceStep({ patientId, insuranceId, step: "coverage" }, { isAdding }));
    },
    [navigate, insuranceId, patientId]
  );

  const autoEligibilityCarrierIds = useMemo(
    () => new Set(autoEligibilityCarriersQuery.data?.carrierIds),
    [autoEligibilityCarriersQuery.data?.carrierIds]
  );

  const insuranceOrPlanIsAutoVerified = useMemo(() => {
    return (
      isAutoVerified(patientInsurance?.patientInsurance.eligibilityVerifiedStatus) ||
      isAutoVerified(insurancePlan?.benefitVerifiedStatus)
    );
  }, [insurancePlan?.benefitVerifiedStatus, patientInsurance?.patientInsurance.eligibilityVerifiedStatus]);

  return insuranceId === "new" ? (
    <Navigate replace to={paths.patientInsuranceStep({ insuranceId, patientId, step: "info" })} />
  ) : (
    <DetailsModalPage
      actions={
        isCreating &&
        isFetched && (
          <InsurancePlanDetailsFooter
            // All editable data is saved within flyover form so we disable the
            // next button if there is no linked plan
            disableNext={!insurancePlanUuid}
            onBack={() => navigateToPrevTab(isCreating)}
            onNext={() => navigateToNext(isCreating)}
            primaryButtonLabel="Next"
          />
        )
      }
      backUrl={backUrl}
      insuranceId={insuranceId}
      patientId={patientId}
      patientInsuranceQuery={patientInsuranceQuery}
      queries={[carrierQuery, feeSchedulesQuery, patientInsuranceQuery]}
      selectedTab="carrier"
    >
      {Boolean(insurancePlan) && (
        <TabBanner
          actions={
            <EditPlanButton
              menu={menu}
              onDuplicatePlan={() => setFormType("duplicate")}
              onEditPlan={() => setFormType("edit")}
              onUnlinkPlan={dropPlanModal.on}
            />
          }
          description={
            <div>
              Updating the Plan Details will affect all&nbsp;
              <Link
                className="text-primaryTheme"
                // eslint-disable-next-line @typescript-eslint/naming-convention
                to={paths.patients({ "patientCriteria.insurancePlanUuid": insurancePlanUuid })}
              >
                {`${numOfPatients ?? 0} ${pluralize(numOfPatients ?? 0, "patient", "patients")}`}
              </Link>
              &nbsp;who have this insurance plan.
            </div>
          }
          editing={false}
        />
      )}
      {carrierQuery.data && feeSchedulesQuery.data && patientInsurance && (
        <div className="flex flex-col max-w-xl m-6 gap-y-6">
          <CarrierDetailsSection carrier={carrierQuery.data} />
          <Divider className="border-dashed" />
          <PlanSection
            carrierName={carrierQuery.data.name}
            hasLinkedPlan={Boolean(insurancePlanUuid)}
            insurancePlansInfiniteQuery={insurancePlansInfiniteQuery}
            isLinking={linkPatientInsuranceToPlanMutation.isLoading}
            onCreateNewPlan={handleClickCreateNewPlan}
            onLinkPlan={handleLinkInsuranceToPlan}
            onSearch={setSearchGroupValue}
            planFields={planFields}
            searchString={searchGroupValue}
          />
          {Boolean(formType) && carrierId && (
            <InsurancePlanFlyover
              autoEligibilityCarrierIds={autoEligibilityCarrierIds}
              carrierId={carrierId}
              feeSchedulesInfiniteQuery={feeSchedulesInfiniteQuery}
              formType={formType}
              insuranceCarriers={carriers}
              insuranceOrPlanIsAutoVerified={insuranceOrPlanIsAutoVerified}
              numOfPatients={numOfPatients}
              onClose={() => setFormType(null)}
              onPlanFieldChange={handlePlanFieldChange}
              onSearchFeeSchedules={setFeeScheduleSearchString}
              patientInsurance={patientInsurance}
              planFields={planFields}
            />
          )}
          {dropPlanModal.isOn && (
            <ConfirmationModal
              confirmText="Drop Plan"
              isConfirming={unlinkPatientInsuranceMutation.isLoading}
              onCancel={dropPlanModal.off}
              onConfirm={onConfirmDropPlan}
              primaryText="Are you sure you want to drop this plan?"
              secondaryText="The plan will be dropped from all dependents and the primary subscriber."
              size="2xs"
            />
          )}
        </div>
      )}
    </DetailsModalPage>
  );
};
