import { FC, useCallback } from "react";
import { Spinner } from "@libs/components/UI/Spinner";
import { Button } from "@libs/components/UI/Button";
import { clampImageDimensions } from "@libs/utils/image";
import { blobToFile } from "@libs/utils/dataUrl";
import { Modal } from "@libs/components/UI/Modal";
import { ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import { UploadDocument } from "components/UI/UploadDocument";
import { handleError } from "utils/handleError";

export const PracticeLogoModal: FC<{
  onUploadImage: (file: File) => void;
  onClose: () => void;
  isUploading: boolean;
}> = ({ onUploadImage, onClose, isUploading }) => {
  const handleUploadImage = useCallback(
    async (file: File) => {
      try {
        const { blob } = await clampImageDimensions(file, { w: 512, h: 512 });

        if (blob) {
          const imageFile = blobToFile(blob, file.name);

          onUploadImage(imageFile);
        } else {
          throw new Error("Failed to resize image");
        }
      } catch (error) {
        handleError(error);
      }
    },
    [onUploadImage]
  );

  return (
    <Modal title="Upload Practice Logo" onClose={onClose}>
      <ModalContent className="py-8 px-16 text-center">
        <div className="flex justify-center h-80">
          {isUploading ? (
            <div className="flex justify-center items-center flex-col">
              <Spinner animation="border" size="sm" />
              <span>Uploading image...</span>
            </div>
          ) : (
            <UploadDocument onFileDropped={handleUploadImage} />
          )}
        </div>
      </ModalContent>
      <ModalFooter>
        <Button theme="secondary" onClick={onClose}>
          Cancel
        </Button>
      </ModalFooter>
    </Modal>
  );
};
