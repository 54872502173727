import { FC } from "react";
import { produce } from "immer";
import { FormNumberInputElementRequest, FormVO } from "@libs/api/generated-api";
import { toggleSet } from "@libs/utils/toggleSet";
import { ValidationResult } from "@libs/hooks/useValidation";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { RequiredCheckbox } from "components/Settings/Forms/RequiredCheckbox";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";
import { getNumberSchema } from "./schema";

export const NumberFormContent: FC<{
  formType: FormVO["type"];
  formState: UseState<{ draft: FormNumberInputElementRequest }>;
  validation: ValidationResult<FormNumberInputElementRequest, ReturnType<typeof getNumberSchema>>;
}> = ({ formType, formState: [{ draft }, setForm], validation }) => {
  const handleToggleSetting = (value: ListItem<typeof draft.settings>) => {
    setForm((last) =>
      produce(last, (next) => {
        next.draft.settings = [...toggleSet(new Set(next.draft.settings), value)];
      })
    );
  };

  const handleUpdate = <T extends keyof FormNumberInputElementRequest>(
    key: T,
    value: FormNumberInputElementRequest[T]
  ) => {
    setForm((last) =>
      produce(last, (next) => {
        next.draft[key] = value;
      })
    );
  };

  return (
    <div className="flex flex-col gap-y-4">
      <FormFieldInput
        label="Question"
        value={draft.title}
        required={true}
        error={validation.title.$error}
        onChange={(e) => handleUpdate("title", e.target.value)}
      />
      <FormFieldNumberInput
        label="Minimum"
        value={draft.min}
        error={validation.min.$error}
        onValueChange={(value) => handleUpdate("min", value)}
      />
      <FormFieldNumberInput
        label="Maximum"
        value={draft.max}
        error={validation.max.$error}
        onValueChange={(value) => handleUpdate("max", value)}
      />
      {formType === "INTAKE" ? (
        <RequiredCheckbox
          checked={draft.settings.includes("REQUIRED")}
          onChange={() => handleToggleSetting("REQUIRED")}
        />
      ) : null}
    </div>
  );
};
