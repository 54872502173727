import React from "react";
import {
  AppointmentBookingVO,
  AppointmentBookingResponse,
  AppointmentBookingTotalVO,
} from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getAbsoluteUrl } from "@libs/utils/location";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { HeaderData } from "components/Dashboard/Tables/types";
import { formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";
import { handleError } from "utils/handleError";
import { useDownloadQueryPages } from "components/Dashboard/hooks/useDownloadInfiniteQuery";
import { getTotals } from "components/Dashboard/Tables/utils";
import { paths } from "utils/routing/paths";
import { APPT_BOOKING_COLUMNS } from "components/Dashboard/Tables/AppointmentBooking/constants";
import { labelForAppointmentBookingType } from "components/Dashboard/utils/labelForAppointmentBookingType";
import { TimeSegment } from "utils/routing/dashboard";
import { useCurrentUrl, useOrigin } from "contexts/OriginContext";

const formatAppointmentDataAsCsvUrl = (params: {
  rows: AppointmentBookingVO[];
  totals: AppointmentBookingTotalVO;
  headers: HeaderData[];
  origin: string;
}) => {
  const { rows, totals, headers, origin } = params;
  const columnTitles = formatCsvRow([...headers.map(({ label }) => label).filter(Boolean), "Patient URL"]);

  const rowsContent = rows.map((row) =>
    formatCsvRow([
      row.patient.shortDisplayName,
      row.appointmentDate,
      labelForAppointmentBookingType(row.appointmentState),
      `${row.appointmentDuration}`,
      row.provider.shortDisplayName,
      row.patientProcedureDisplay.split(",").join(", "),
      row.nextScheduledAppointmentDate ?? "",
      getAbsoluteUrl(
        origin,
        paths.schedule({
          patientId: row.patient.id,
          appointmentId: row.appointmentId,
          date: row.appointmentDate,
        })
      ),
    ])
  );
  const totalsContent = formatCsvRow([
    "Totals",
    `${totals.distinctCount}`,
    "",
    `${totals.totalDuration}`,
    "",
    "",
    "",
  ]);

  return [columnTitles, ...rowsContent, totalsContent];
};

export const useDownloadAppointmentBookingCSV = ({
  appointmentBookingInfiniteQuery,
  selectedTimeSegment,
}: {
  selectedTimeSegment: TimeSegment;
  appointmentBookingInfiniteQuery: UseInfiniteApiQueryResult<AppointmentBookingResponse>;
}) => {
  const downloading = useBoolean(false);

  const { startDownload: downloadAppointmentStats } = useDownloadQueryPages(appointmentBookingInfiniteQuery);
  const origin = useOrigin();
  const currentUrl = useCurrentUrl();

  return {
    isDownloading: downloading.isOn,
    downloadCSV: React.useCallback(async () => {
      downloading.on();

      try {
        const result = await downloadAppointmentStats();
        const rows = result.pages.flatMap((item) => item.data.entries);
        const totals = getTotals(result);

        if (totals) {
          const docMetadata = getArchyCsvMetadata(currentUrl, selectedTimeSegment);
          const csvRows = [
            ...docMetadata.headers,
            ...formatAppointmentDataAsCsvUrl({
              rows,
              totals,
              headers: APPT_BOOKING_COLUMNS,
              origin,
            }),
          ];

          saveCsv(csvRows, `AppointmentBooking_${docMetadata.fileNameTag}`);
        }
      } catch (e) {
        handleError(e);
      }

      downloading.off();
    }, [downloading, downloadAppointmentStats, selectedTimeSegment, origin, currentUrl]),
  };
};
