/* eslint-disable max-depth */
import React from "react";
import { useImageEditorContext } from "components/PatientProfile/Imaging/ImageEditor/ImageEditorContext";
import { ColorSwatch } from "components/PatientProfile/Imaging/ImageEditor/Tools/ColorSwatch";
import {
  isGroup,
  isText,
  drawWithOpacity,
} from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/shapeUtils";
import { EDITOR_COLORS } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/constants";

export const ColorPalette: React.FC = () => {
  const { editor: imageEditor, drawSettings, setDrawSettings, handleAnnotation } = useImageEditorContext();
  const onChangeBrushColor = React.useCallback(
    (index: number) => {
      const color = EDITOR_COLORS[index];
      const canvas = imageEditor.current?.getInstance();

      if (canvas) {
        const active = canvas.getActiveObjects();

        if (active.length > 0) {
          for (const obj of active) {
            if (isGroup(obj)) {
              for (const groupObject of obj.getObjects()) {
                if (isText(groupObject)) {
                  groupObject.set("fill", color);
                }

                groupObject.set("stroke", color);
              }
            } else {
              obj.set("stroke", drawWithOpacity(color));
            }
          }

          handleAnnotation();
        }
      }

      setDrawSettings(() => ({
        color: EDITOR_COLORS[index],
      }));
    },
    [setDrawSettings, imageEditor, handleAnnotation]
  );

  return (
    <div className="flex gap-1 px-4 py-2">
      {EDITOR_COLORS.map((c, i) => (
        <ColorSwatch
          isSelected={c === drawSettings.color}
          key={c}
          color={c}
          onClick={() => onChangeBrushColor(i)}
          className="w-5 h-5"
        />
      ))}
    </div>
  );
};
