import React from "react";
import { toMap } from "@libs/utils/array";
import { TwainSource } from "components/ImageCapturing/twain";
import { CameraDevice } from "components/ImageCapturing/useCameraSource";
import { useLoadedTwainSensors, useTwain } from "components/ImageCapturing/useTwain";
import { CaptureDeviceSettings } from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/types";
import { UseCameraCaptureState } from "components/ImageCapturing/useCameraCapture";
import { useImagingDeviceSettings } from "components/PatientProfile/Imaging/hooks/useImagingDeviceSettings";
import {
  getIsLoadingDevices,
  getIsLoadingDevicesV2,
} from "components/PatientProfile/Imaging/hooks/useIsLoadingDevices";
import { useImagingHub } from "components/ImageCapturing/ImagingHubContext";
import { DriverType } from "api/imaging/imaging-hub";
import { getSensorSettingsDeviceId } from "components/PatientProfile/Imaging/utils/devices";
import { useForceDynamsoft } from "components/ImageCapturing/useForceDynamsoft";

export type BaseDeviceItem = { settings: CaptureDeviceSettings; produces: "Photo" | "X-RAY" };
export type TwainDeviceWithSettings = BaseDeviceItem & {
  type: "sensor";
} & TwainSource;

export type ArchyServiceSensor = {
  label: string;
  driverType: DriverType;
};
export type ArchyServiceSensorWithSettings = BaseDeviceItem & {
  type: "archy-sensor";
} & ArchyServiceSensor;

export type CameraDeviceWithSettings = BaseDeviceItem & {
  type: "camera";
} & CameraDevice;
export type ImagingDeviceWithSettings =
  | TwainDeviceWithSettings
  | CameraDeviceWithSettings
  | ArchyServiceSensorWithSettings;

const defaultDeviceSettings = {
  enabled: true,
  reverseImport: false,
};

export const useImagingDevices = (cameraCapture: UseCameraCaptureState) => {
  const { twainInstance, twainSources } = useTwain();
  const forceDynamsoft = useForceDynamsoft();
  const twainSourceList = useLoadedTwainSensors();
  const { cameraSources } = cameraCapture;
  const {
    sensorSettings,
    cameraSettings,
    setArchyServiceSensorSettings,
    archyServiceSensorSettings,
    setCameraSettings,
    setSensorSettings,
    saveSettings,
  } = useImagingDeviceSettings();
  const { availableDevicesQuery, status: hubStatus } = useImagingHub();

  const devices = React.useMemo(() => {
    const cameraSettingsById = toMap(cameraSettings, "id");
    const dynamsoftSensorsByLabel = toMap(sensorSettings, "label");
    const archySensorSettingsById = toMap(archyServiceSensorSettings, "id");

    const isAISRunningAndNotDisabled = hubStatus.isRunning && !hubStatus.isAISDisabled;

    const twainDevices: Omit<TwainDeviceWithSettings, "settings">[] = isAISRunningAndNotDisabled
      ? []
      : twainSourceList.map((item) => ({
          ...item,
          type: "sensor",
          produces: "X-RAY",
        }));
    const aisDevices: Omit<ArchyServiceSensorWithSettings, "settings">[] =
      isAISRunningAndNotDisabled && availableDevicesQuery.data
        ? availableDevicesQuery.data.map((item) => ({
            label: item.name,
            driverType: item.driverType,
            type: "archy-sensor",
            produces: "X-RAY",
          }))
        : [];

    const cameras: Omit<CameraDeviceWithSettings, "settings">[] = (cameraSources?.sources || []).map(
      (camera) => ({
        ...camera,
        type: "camera",
        produces: "Photo",
      })
    );

    const allDevices = [...twainDevices, ...aisDevices, ...cameras].map((item) => {
      const settings: CaptureDeviceSettings =
        item.type === "archy-sensor"
          ? archySensorSettingsById[getSensorSettingsDeviceId(item)] ?? {
              ...defaultDeviceSettings,
              label: item.label,
              id: getSensorSettingsDeviceId(item),
            }
          : (item.type === "camera"
              ? cameraSettingsById[item.deviceId]
              : dynamsoftSensorsByLabel[item.label]) ?? {
              id: item.type === "camera" ? item.deviceId : item.label,
              label: item.label,
              ...defaultDeviceSettings,
            };

      const device: ImagingDeviceWithSettings = {
        settings,
        ...item,
        produces:
          (item.type === "camera" && !settings.produces) || settings.produces === "Photo" ? "Photo" : "X-RAY",
      };

      return device;
    });

    return allDevices;
  }, [
    hubStatus,
    archyServiceSensorSettings,
    availableDevicesQuery.data,
    cameraSettings,
    cameraSources?.sources,
    sensorSettings,
    twainSourceList,
  ]);

  const handleDeviceSettingsUpdated = React.useCallback(
    (changedItem: ImagingDeviceWithSettings) => {
      const updatedSettings = {
        cameraSettings,
        sensorSettings,
        archyServiceSensorSettings,
      };

      if (changedItem.type === "camera") {
        const settingExists = cameraSettings.some((item) => item.id === changedItem.settings.id);

        updatedSettings.cameraSettings = settingExists
          ? cameraSettings.map((config) =>
              config.id === changedItem.settings.id ? changedItem.settings : config
            )
          : [...cameraSettings, changedItem.settings];

        setCameraSettings(updatedSettings.cameraSettings);
      } else if (changedItem.type === "archy-sensor") {
        const settingExists = archyServiceSensorSettings.some(
          (setting) => setting.id === changedItem.settings.id
        );

        updatedSettings.archyServiceSensorSettings = settingExists
          ? archyServiceSensorSettings.map((config) =>
              config.id === changedItem.settings.id ? changedItem.settings : config
            )
          : [...archyServiceSensorSettings, changedItem.settings];
        setArchyServiceSensorSettings(updatedSettings.archyServiceSensorSettings);
      } else {
        const settingExists = sensorSettings.some((item) => item.id === changedItem.settings.id);

        updatedSettings.sensorSettings = settingExists
          ? sensorSettings.map((config) =>
              config.id === changedItem.settings.id ? changedItem.settings : config
            )
          : [...sensorSettings, changedItem.settings];
        // Sensor setting does exist, update it
        setSensorSettings(updatedSettings.sensorSettings);
      }

      saveSettings(updatedSettings);

      return updatedSettings;
    },
    [
      archyServiceSensorSettings,
      cameraSettings,
      saveSettings,
      sensorSettings,
      setArchyServiceSensorSettings,
      setCameraSettings,
      setSensorSettings,
    ]
  );

  return {
    devices,
    isLoading:
      hubStatus.isAISAllowed && !forceDynamsoft.hasForcedDynamsoft
        ? getIsLoadingDevicesV2(availableDevicesQuery.isLoading, cameraCapture)
        : getIsLoadingDevices(
            twainInstance.isReady || Boolean(twainInstance.neededDownloadUrl),
            cameraCapture,
            twainSources
          ),
    handleDeviceSettingsUpdated,
    saveAllSettings: React.useCallback(
      (params?: {
        cameraSettings: CaptureDeviceSettings[];
        sensorSettings: CaptureDeviceSettings[];
        archyServiceSensorSettings: CaptureDeviceSettings[];
      }) => {
        saveSettings(
          params ?? {
            sensorSettings,
            archyServiceSensorSettings,
            cameraSettings,
          }
        );
      },
      [archyServiceSensorSettings, cameraSettings, saveSettings, sensorSettings]
    ),
  };
};
