import { Dispatch, FC, SetStateAction, useCallback, useEffect } from "react";
import { DocumentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { filenameToMimeType } from "@libs/utils/mimeTypes";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { DocumentUploadError } from "@libs/utils/isDocumentUploadError";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as LoadingIcon } from "@libs/assets/icons/refresh.svg";
import { confirmUpload, generateUploadUrl } from "api/documents/mutations";

interface BaseItem {
  randomId: string;
  status: "PENDING" | "UPLOADING" | "UPLOADED" | "DELETING" | "FAILED";
}

type GenerateAndConfirmPayload = Parameters<ReturnType<typeof generateUploadUrl>["1"]>["0"];

export interface DocumentUploaderRequest {
  practiceId: number;
  userId: number;
  folderId: number;
  filename: string;
  fileData: File;
}

export const useDocumentUploader = () => {
  const [{ mutateAsync: generateUploadUrlAsync }, { mutateAsync: confirmUploadAsync }] = useApiMutations([
    generateUploadUrl,
    confirmUpload,
  ]);

  const documentUploader = useCallback(
    async ({ practiceId, userId, folderId, filename, fileData }: DocumentUploaderRequest) => {
      const fileMimeType = filenameToMimeType(filename);
      const generateAndConfirmPayload: GenerateAndConfirmPayload = {
        practiceId,
        userId,
        folderId,
        data: { documentName: filename, contentType: fileMimeType },
      };

      // Generate pre-signed S3 URL
      const {
        data: { data: uploadUrl },
      } = await generateUploadUrlAsync(generateAndConfirmPayload);

      // Upload to pre-signed S3 URL
      const response = await fetch(uploadUrl, {
        method: "PUT",
        body: fileData,
      });

      if (!response.ok) {
        const errorString = `Failed to upload document, error ${response.status} (${response.statusText})`;

        throw new DocumentUploadError({
          errorString,
          responseText: await response.text(),
          folderId,
          filename,
        });
      }

      // Confirm update
      const confirmation = await confirmUploadAsync(generateAndConfirmPayload);

      // Return DocumentVO
      return confirmation.data.data;
    },
    [confirmUploadAsync, generateUploadUrlAsync]
  );

  return { documentUploader };
};

export interface ItemWithFile extends BaseItem {
  file: File;
}

export interface ItemWithDocument extends BaseItem {
  document: DocumentVO;
}

export type FileAndDoc = ItemWithFile | ItemWithDocument;

type UploadFileHandler = (file: ItemWithFile) => Promise<DocumentVO>;
type DeleteDocumentHandler = (document: ItemWithDocument) => Promise<unknown>;
type FilesAndDocuments = FileAndDoc[];

const isItemWithFile = (item: FileAndDoc): item is ItemWithFile => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (item as ItemWithFile).file !== undefined;
};

const isItemWithDoc = (item: FileAndDoc): item is ItemWithDocument => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  return (item as ItemWithDocument).document !== undefined;
};

// This component manages an array of files (type `FileAndDoc`) to be uploaded
// given an initial set of documents `filesAndDocs` (array could be empty, or
// contain previously uploaded documents in the `"UPLOADED"` state).
//
// `DocumentUploadManager` will take care of uploading and deleting documents
// while updating UI states, and keeping the `filesAndDocs` array in sync by
// using the provided `filesAndDocsSetter` which is the state setter returned by
// React's `useState()` when creating the array of files.
//
// The array of files can also be augmented by appending new files to in the
// "PENDING" state which will signal `DocumentUploadManager` to start uploading
// them.
//
// It's also important to provide a unique (random) ID for each file so they can
// be tracked and kept in sync during the lifecycle of `DocumentUploadManager`.
//
// The callbacks `onUpload` and `onDelete` must throw when requests fail in
// order for `DocumentUploadManager` to reflect UI state accordingly.
export const DocumentUploadManager: FC<{
  filesAndDocs: FileAndDoc[];
  filesAndDocsSetter: Dispatch<SetStateAction<FilesAndDocuments>>;
  onDelete: DeleteDocumentHandler;
  onUpload: UploadFileHandler;
}> = ({ filesAndDocs, filesAndDocsSetter, onUpload, onDelete }) => {
  const replaceUserFile = useCallback(
    (replacement: FileAndDoc) => {
      filesAndDocsSetter((prevFileAndDocs) => {
        const indexToReplace = prevFileAndDocs.findIndex(
          (fileAndDoc) => fileAndDoc.randomId === replacement.randomId
        );
        const newArray = [...prevFileAndDocs];

        newArray[indexToReplace] = replacement;

        return newArray;
      });
    },
    [filesAndDocsSetter]
  );

  const deleteUserFile = useCallback(
    (toDelete: FileAndDoc) => {
      filesAndDocsSetter((prevFileAndDocs) => {
        const newArray = prevFileAndDocs.filter((fileAndDoc) => fileAndDoc.randomId !== toDelete.randomId);

        return newArray;
      });
    },
    [filesAndDocsSetter]
  );

  const handleUpload = useCallback(
    (fileAndDocToUpload: ItemWithFile) => {
      replaceUserFile({ ...fileAndDocToUpload, status: "UPLOADING" });
      onUpload(fileAndDocToUpload)
        .then((data) => replaceUserFile({ ...fileAndDocToUpload, document: data, status: "UPLOADED" }))
        .catch(() => replaceUserFile({ ...fileAndDocToUpload, status: "FAILED" }));
    },
    [onUpload, replaceUserFile]
  );

  const handleDelete = useCallback(
    (fileAndDocToDelete: ItemWithDocument) => {
      replaceUserFile({ ...fileAndDocToDelete, status: "DELETING" });
      onDelete(fileAndDocToDelete)
        .then(() => deleteUserFile(fileAndDocToDelete))
        .catch(() => replaceUserFile({ ...fileAndDocToDelete, status: "FAILED" }));
    },
    [deleteUserFile, onDelete, replaceUserFile]
  );

  // Upload files that are in state PENDING
  useEffect(() => {
    filesAndDocs
      .filter(isItemWithFile)
      .filter((itemWithFile) => itemWithFile.status === "PENDING")
      .forEach(handleUpload);
  }, [filesAndDocs, handleUpload]);

  return (
    <div className="text-xs grid grid-cols-[auto_1fr_auto]">
      {filesAndDocs.map((fileAndDoc) => {
        return isItemWithDoc(fileAndDoc) ? (
          <FileRow<ItemWithDocument>
            key={fileAndDoc.randomId}
            fileAndDoc={fileAndDoc}
            onActionClick={fileAndDoc.status === "DELETING" ? undefined : handleDelete}
            actionTooltip={fileAndDoc.status === "FAILED" ? "Retry Delete" : "Delete"}
          />
        ) : (
          <FileRow<ItemWithFile>
            key={fileAndDoc.randomId}
            fileAndDoc={fileAndDoc}
            onActionClick={handleUpload}
            actionTooltip={fileAndDoc.status === "FAILED" ? "Retry Upload" : "Upload"}
          />
        );
      })}
    </div>
  );
};

const cxStyles = {
  cell: "flex items-center",
};

const STATUS_TO_LABEL: Record<BaseItem["status"], string> = {
  DELETING: "Deleting...",
  FAILED: "Failed",
  UPLOADED: "Uploaded",
  UPLOADING: "Uploading...",
  PENDING: "Uploading...",
};

const STATUS_TO_ICON: Record<BaseItem["status"], IconComponent> = {
  DELETING: LoadingIcon,
  UPLOADING: LoadingIcon,
  FAILED: DeleteIcon,
  UPLOADED: DeleteIcon,
  PENDING: LoadingIcon,
};

interface FileRowProps<T> {
  fileAndDoc: T;
  onActionClick?: (fileAndDoc: T) => unknown;
  actionTooltip?: string;
}

const FileRow = <T extends FileAndDoc>({ fileAndDoc, onActionClick, actionTooltip }: FileRowProps<T>) => {
  return (
    <div className="*:hover:bg-accentLight contents">
      <div className={cx("rounded-l pl-4 pr-2", cxStyles.cell)}>
        {isItemWithFile(fileAndDoc) ? fileAndDoc.file.name : fileAndDoc.document.name}
      </div>
      <div className={cx("justify-end px-2", cxStyles.cell)}>{STATUS_TO_LABEL[fileAndDoc.status]}</div>
      <div className={cx("py-1 rounded-r px-2", cxStyles.cell)}>
        <ButtonIcon
          tooltip={{ content: actionTooltip, theme: "SMALL" }}
          SvgIcon={STATUS_TO_ICON[fileAndDoc.status]}
          onClick={() => onActionClick?.(fileAndDoc)}
          className={cx(
            (fileAndDoc.status === "UPLOADING" || fileAndDoc.status === "DELETING") && "animate-spin"
          )}
        />
      </div>
    </div>
  );
};
