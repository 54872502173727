import { PracticeInfoVO } from "@libs/api/generated-api";

import { mirroredOptions } from "@libs/utils/forms";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { RadioList } from "@libs/components/UI/RadioList";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { FormFieldSwitch } from "@libs/components/UI/FormFieldSwitch";
import {
  getAssignmentOfBenefitsDisclosure,
  getReleaseOfPatientInfoDisclosure,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/utils";
import { ServerErrors, State, UpdateState } from "components/Settings/ClaimsSettings/types";

const ASSIGNMENT_OPTIONS = mirroredOptions(["Practice", "Patient"]);

export const ClaimGeneralSettings: React.FC<{
  isEditing: boolean;
  serverErrors: ServerErrors;
  state: State;
  practiceData?: PracticeInfoVO;
  onUpdateState: UpdateState;
}> = ({ isEditing, state, practiceData, onUpdateState }) => {
  return (
    <div>
      <div className="text-sm font-sansSemiBold mb-4">Claim General</div>
      <div className="grid grid-cols-2 gap-x-6 gap-y-6">
        <div className="flex flex-col text-greyDark">
          <FormFieldSwitch
            label={
              <div className="flex font-sansSemiBold text-xs gap-x-2 items-center">
                <span>Release of Patient Information</span>
                <FloatingTooltip
                  content={`Determine if ${
                    practiceData?.name ?? "the practice"
                  } will file claims on behalf of the patient or require the patient to file claims themselves. Selecting Yes will allow draft claims to be created and sent by the practice. Selecting No will not allow claims to be drafted and sent by the practice.`}
                >
                  <div>
                    <Icon SvgIcon={InfoIcon} theme="slate700" />
                  </div>
                </FloatingTooltip>
              </div>
            }
            edit={isEditing}
            checked={Boolean(state.releaseOfPatientInfo)}
            onChange={(e) => onUpdateState("releaseOfPatientInfo", e.target.checked)}
          />
          {isEditing && state.releaseOfPatientInfo && (
            <div className="text-xs">
              {getReleaseOfPatientInfoDisclosure(state.releaseOfPatientInfo, practiceData?.name)}
            </div>
          )}
        </div>

        <div className="flex flex-col text-greyDark">
          <FormFieldLabel
            content={
              <div className="flex font-sansSemiBold text-xs gap-x-2 items-center">
                <span>Assignment of Benefits</span>
                <FloatingTooltip content="Determine if insurance payments will be sent to the practice or the patient. If assignment of benefits is set to the practice, the patient's ledger will show any open insurance balance due by the insurance carrier. If the assignment of benefits is set to the patient, the entire balance will be set to the patient on their ledger.">
                  <div>
                    <Icon SvgIcon={InfoIcon} theme="slate700" />
                  </div>
                </FloatingTooltip>
              </div>
            }
          />
          <div className="flex flex-col gap-x-3 gap-y-2">
            {isEditing ? (
              <div className="ml-1.5">
                <RadioList
                  className="gap-x-3 items-center"
                  onChange={(e) => onUpdateState("assignmentOfBenefits", e.target.value === "Practice")}
                  options={ASSIGNMENT_OPTIONS}
                  selectedValue={state.assignmentOfBenefits ? "Practice" : "Patient"}
                />
              </div>
            ) : (
              <span className="text-xs mt-2">{state.assignmentOfBenefits ? "Practice" : "Patient"}</span>
            )}
            {isEditing && (
              <div className="text-xs">
                {getAssignmentOfBenefitsDisclosure(Boolean(state.assignmentOfBenefits), practiceData?.name)}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};
