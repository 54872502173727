import { FC, FormEventHandler, useState, useCallback } from "react";

import { MessageCampaignVO } from "@libs/api/generated-api";
import { required, email } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import { isOneOf } from "@libs/utils/isOneOf";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ReactComponent as SendIcon } from "@libs/assets/icons/send.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { Modal } from "@libs/components/UI/Modal";
import { ModalForm, ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";

import { EmailEditorPreview } from "components/Communications/Campaigns/EmailEditor/EmailEditorPreview";

interface Props {
  campaign: MessageCampaignVO;
  emailTemplatePreviewUrl?: string;
  onRequestEmailTemplatePreviewUrl: (previewUrl: string) => void;
  onRequestEditEmail: Func;
  onSendPreviewEmail: (recipientEmail: string, options: { onSuccess: Func }) => void;
  hasEmailTemplate: boolean;
  isSendingPreviewEmail: boolean;
  isEditable: boolean;
}

export const CampaignEmail: FC<Props> = ({
  campaign,
  emailTemplatePreviewUrl,
  onRequestEmailTemplatePreviewUrl,
  onRequestEditEmail,
  onSendPreviewEmail,
  hasEmailTemplate,
  isSendingPreviewEmail,
  isEditable,
}) => {
  const sendTestModal = useBoolean(false);
  const canSendTest = isOneOf(campaign.status, ["DRAFT", "SCHEDULED"]);

  const handleSendTest = useCallback(
    (recipientEmail: string) => {
      onSendPreviewEmail(recipientEmail, { onSuccess: sendTestModal.off });
    },
    [onSendPreviewEmail, sendTestModal.off]
  );

  return (
    <div
      className={`
        flex
        flex-col
        items-center
        justify-center
        gap-y-6
        p-6
        min-w-[22rem]
        min-h-full
      `}
    >
      <div className="flex items-center gap-x-6 max-w-5xl">
        {hasEmailTemplate ? (
          <Button
            className="flex items-center justify-center gap-x-1 min-w-24 text-xs"
            onClick={sendTestModal.on}
            disabled={!canSendTest}
            theme="link"
          >
            <SendIcon className="w-5 h-5" />
            Send Test
          </Button>
        ) : null}

        <Button
          className="flex items-center justify-center gap-x-1 min-w-24 text-xs"
          onClick={onRequestEditEmail}
          disabled={!isEditable}
          theme="link"
        >
          <EditIcon className="w-5 h-5" />
          Edit Email
        </Button>
      </div>

      {hasEmailTemplate ? (
        <>
          <div className="w-full rounded bg-slate-50 drop-shadow-2xl">
            {emailTemplatePreviewUrl && campaign.name ? (
              <img
                className="object-contain rounded"
                src={emailTemplatePreviewUrl}
                alt={`${campaign.name} Preview`}
              />
            ) : (
              <div className="w-full h-[700px]">
                <LoadingContent className="h-full" />
              </div>
            )}
          </div>

          {campaign.emailTemplate?.json && !emailTemplatePreviewUrl ? (
            <EmailEditorPreview
              emailTemplateJson={campaign.emailTemplate.json}
              emailTemplatePreviewUrl={emailTemplatePreviewUrl}
              onRequestEmailTemplatePreviewUrl={onRequestEmailTemplatePreviewUrl}
            />
          ) : null}
        </>
      ) : null}

      {sendTestModal.isOn ? (
        <SendTestModal
          isSending={isSendingPreviewEmail}
          onSendTest={handleSendTest}
          onClose={sendTestModal.off}
        />
      ) : null}
    </div>
  );
};

const SendTestModal: FC<{
  isSending: boolean;
  onSendTest: (recipientEmail: string) => void;
  onClose: Func;
}> = ({ isSending, onSendTest, onClose }) => {
  const [recipientEmail, setRecipientEmail] = useState("");

  const { validate, result } = useValidation(
    { recipientEmail },
    {
      recipientEmail: [
        {
          $v: required,
          $error: "Recipient email is required",
        },
        {
          $v: email,
          $error: "Recipient must be a valid email address",
        },
      ],
    }
  );

  const handleSubmit: FormEventHandler = useCallback(
    (e) => {
      e.preventDefault();

      if (!validate().$isValid) {
        return;
      }

      onSendTest(recipientEmail);
    },
    [validate, onSendTest, recipientEmail]
  );

  return (
    <Modal title="Send Test" onClose={onClose} centerVertically={false} size="3xs">
      <ModalForm onSubmit={handleSubmit}>
        <ModalContent padding="lg">
          <FormFieldInput
            label="Recipient"
            value={recipientEmail}
            onChange={(e) => setRecipientEmail(e.target.value)}
            error={result.recipientEmail.$error}
            required
          />
        </ModalContent>
        <ModalFooter>
          <Button className="min-w-button" onClick={onClose} theme="secondary">
            Cancel
          </Button>
          <AsyncButton
            aria-label="Send Test Email"
            className="min-w-button"
            isLoading={isSending}
            type="submit"
          >
            Send
          </AsyncButton>
        </ModalFooter>
      </ModalForm>
    </Modal>
  );
};
