import { FC, useState, useCallback } from "react";
import { DocumentVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { addFilenameExtension, removeFilenameExtension } from "utils/files";

import { StagedDocumentFile } from "components/UserDocuments/UploadDocumentFilesModal";

import { FormFieldSelect } from "components/UI/FormFieldSelect";

interface Props {
  file: StagedDocumentFile;
  folderOptions: SelectOption<DocumentVO["id"]>[];
  onUpdateFile: (stagedId: StagedDocumentFile["stagedId"], updatedFile: Partial<StagedDocumentFile>) => void;
  onDeleteFile: (stagedId: StagedDocumentFile["stagedId"]) => void;
}

export const UploadDocumentFileRow: FC<Props> = ({ file, folderOptions, onUpdateFile, onDeleteFile }) => {
  const [draftFilename, setDraftFilename] = useState(removeFilenameExtension(file.filename));
  const editFilename = useBoolean(false);

  const handleUpdateFilename = useCallback(() => {
    onUpdateFile(file.stagedId, { filename: addFilenameExtension(file.filename, draftFilename) });
    editFilename.off();
  }, [onUpdateFile, file.stagedId, file.filename, draftFilename, editFilename]);

  return (
    <div className="flex items-center justify-between gap-x-4 p-2">
      <div className="flex items-center gap-x-1 min-h-9 overflow-hidden">
        {editFilename.isOff ? (
          <>
            <FloatingTooltip content={file.filename} theme="SMALL" placement="top-start">
              <span className="text-xs truncate">{file.filename}</span>
            </FloatingTooltip>

            <ButtonIcon
              SvgIcon={EditIcon}
              tooltip={{ content: "Edit Filename", theme: "SMALL" }}
              onClick={editFilename.on}
              size="sm"
            />
          </>
        ) : (
          <>
            <FormFieldInput
              value={draftFilename}
              onChange={(e) => setDraftFilename(e.target.value)}
              onBlur={handleUpdateFilename}
              autoFocus
            />
            <Button onClick={handleUpdateFilename} theme="primary" size="small">
              Save
            </Button>
          </>
        )}
      </div>

      <div className="flex items-center gap-x-3">
        <FormFieldSelect
          className="w-56"
          options={folderOptions}
          placeholder="Select Folder"
          value={file.folderId}
          onItemChanged={(value) => onUpdateFile(file.stagedId, { folderId: value })}
          isSearchable={false}
          required
        />

        <ButtonIcon
          SvgIcon={DeleteIcon}
          tooltip={{ content: "Remove File", theme: "SMALL" }}
          onClick={() => onDeleteFile(file.stagedId)}
          theme="primary"
        />
      </div>
    </div>
  );
};
