import { sentenceCaseConstant } from "@libs/utils/casing";
import { HOUR_IN_MINUTES, MINUTE_IN_SECONDS } from "@libs/utils/date";
import { DecimalPlaces, round } from "@libs/utils/math";
import { FilteredProviderType } from "utils/routing/dashboard/serializedFilter";
import { padTime } from "components/UI/timeInput.utils";

export const formatWorkingSeconds = (workingSeconds: number, hoursInDecimal?: boolean) => {
  const hours = workingSeconds / (HOUR_IN_MINUTES * MINUTE_IN_SECONDS);

  if (hoursInDecimal) {
    return `${round(hours, DecimalPlaces.hundredth)}`;
  }

  const minutes = (workingSeconds % (HOUR_IN_MINUTES * MINUTE_IN_SECONDS)) / MINUTE_IN_SECONDS;

  return `${padTime(`${Math.floor(hours)}`)}:${padTime(`${Math.round(minutes)}`)}`;
};

export const labelForJobCategory = (jobCategory: FilteredProviderType) => {
  return sentenceCaseConstant(jobCategory);
};
