import React from "react";
import { useDebouncedCallback } from "use-debounce";
import { useQueryClient } from "@tanstack/react-query";
import { MountVO, UpdateMountRequest } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { ImageSelectMoveProps } from "components/PatientProfile/Imaging/MountRoute/types";
import { CANVAS_BOUNDS } from "components/PatientProfile/Imaging/PatientMountsList/mountLayouts";
import { handleError } from "utils/handleError";
import { updateMountCacheEntry } from "api/imaging/cache";
import { imageHookConfig } from "components/PatientProfile/Imaging/MountRoute/hooks/config";
import { updateMount } from "api/imaging/mutations";

export const useMountUpdater = ({
  patientId,
  imageMount,
  editsPromise,
}: {
  patientId: number;
  imageMount?: MountVO;
  editsPromise?: React.MutableRefObject<Promise<unknown> | undefined>;
}) => {
  const { practiceId } = useAccount();
  const saving = useBoolean(false);
  const mountUpdaterPromise = React.useRef<undefined | Promise<void>>(undefined);
  const [{ mutate: mutateMount }] = useApiMutations([updateMount]);

  const mountId = imageMount?.id;
  const commitMountChanges = React.useCallback(
    async (imageUpdateParams: {
      practiceId: number;
      patientId: number;
      mountId: number;
      data: UpdateMountRequest;
      onDone: Func;
    }) => {
      const images = imageUpdateParams.data.images;

      if (!images) {
        return undefined;
      }

      await editsPromise?.current;
      mountUpdaterPromise.current = new Promise<void>((resolve) => {
        mutateMount(
          { ...imageUpdateParams, invalidateCache: false },
          {
            onError: handleError,
            onSettled: () => {
              resolve();
              imageUpdateParams.onDone();
              mountUpdaterPromise.current = undefined;
            },
          }
        );
      });

      return mountUpdaterPromise.current;
    },
    [editsPromise, mutateMount]
  );

  const debouncedUpdateMount = useDebouncedCallback(
    commitMountChanges,
    imageHookConfig.imageEditCommitDelayMS
  );
  const queryClient = useQueryClient();

  const handleMountChanged = React.useCallback(
    ({ updates }: { updates: UpdateMountRequest }) => {
      if (!mountId) {
        return Promise.resolve();
      }

      saving.on();

      const params = { data: updates, practiceId, mountId, patientId };

      updateMountCacheEntry(queryClient, params, (data) => {
        return {
          ...data,
          images: updates.images ?? data.images,
        };
      });

      return debouncedUpdateMount({ ...params, onDone: saving.off });
    },
    [debouncedUpdateMount, mountId, patientId, practiceId, queryClient, saving]
  );
  const handleImageOriginChanged: ImageSelectMoveProps["onImageOriginChanged"] = React.useCallback(
    // eslint-disable-next-line complexity
    (image, newOrigin) => {
      let { x, y } = newOrigin;

      if (x < CANVAS_BOUNDS.left) {
        x = CANVAS_BOUNDS.left;
      } else if (CANVAS_BOUNDS.w < x + image.sandbox.w - CANVAS_BOUNDS.left) {
        x = CANVAS_BOUNDS.w - image.sandbox.w;
      }

      if (y < CANVAS_BOUNDS.top) {
        y = CANVAS_BOUNDS.top;
      } else if (CANVAS_BOUNDS.h < y + image.sandbox.h - CANVAS_BOUNDS.top) {
        y = CANVAS_BOUNDS.h - image.sandbox.h;
      }

      const images = imageMount?.images;

      if (!images || !mountId) {
        return;
      }

      handleMountChanged({
        updates: {
          images: images.map((item) => {
            if (item.id === image.id) {
              return {
                ...item,
                x,
                y,
              };
            }

            return item;
          }),
        },
      });
    },
    [handleMountChanged, imageMount?.images, mountId]
  );

  return {
    mountUpdaterPromise,
    handleImageOriginChanged,
    handleMountChanged,
    isSavingMount: saving.isOn,
    debouncedUpdateMount,
  };
};

export type UseMountUpdater = ReturnType<typeof useMountUpdater>;
