import { Link } from "react-router-dom";
import { AppointmentVO } from "@libs/api/generated-api";
import { paths } from "utils/routing/paths";
import { AppointmentSummary } from "components/PatientBlurbInfo/AppointmentSummary";

interface Props {
  appointment: AppointmentVO;
  onEditAppointment: Func;
}

export const AppointmentCard: React.FC<Props> = ({ appointment, onEditAppointment }) => {
  return (
    <Link
      to={paths.schedule({
        patientId: appointment.patient.id,
        appointmentId: appointment.id,
        date: appointment.date,
      })}
      className="-mx-3"
      onClick={onEditAppointment}
    >
      <AppointmentSummary appointment={appointment} theme="white" />
    </Link>
  );
};
