import { useCallback } from "react";
import { formatISODate } from "@libs/utils/date";
import { getFullUrl } from "@libs/utils/location";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useObjectState } from "@libs/hooks/useObjectState";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { Button } from "@libs/components/UI/Button";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { RadioList } from "@libs/components/UI/RadioList";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Form } from "@libs/components/UI/Form";
import { FormFieldSwitch } from "@libs/components/UI/FormFieldSwitch";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent, FlyoverFooter } from "components/UI/FlyoverComponents";
import {
  CaptureDeviceSettings,
  MouthLocation,
} from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/types";
import {
  BaseDeviceItem,
  ImagingDeviceWithSettings,
} from "components/PatientProfile/Imaging/hooks/useImagingDevices";
import { paths } from "utils/routing/paths";

const FORMAT_OPTIONS: OptionInputOption<BaseDeviceItem["produces"]>[] = [
  { label: "X-Ray", value: "X-RAY" },
  { label: "Photo", value: "Photo" },
];
const CAPTURE_ORDER_OPTIONS: OptionInputOption<boolean>[] = [
  { label: "Default", value: false },
  { label: "Reverse", value: true },
];
const ORIENT_ON_CAPTURE_OPTIONS: OptionInputOption<"x" | "y" | "default">[] = [
  { label: "Default", value: "default" },
  { label: "Mirror horizontally", value: "x" },
  { label: "Mirror vertically", value: "y" },
];
const ROTATE_ON_CAPTURE: OptionInputOption<MouthLocation>[] = [
  { label: "Upper PA", value: "UPPER_PA" },
  { label: "Lower PA", value: "LOWER_PA" },
  { label: "Left BW", value: "LEFT_BW" },
  { label: "Right BW", value: "RIGHT_BW" },
];

const LabelWithTooltip: React.FC<{ label: string; tooltip: string }> = ({ label, tooltip }) => {
  return (
    <div className="flex items-center gap-1">
      {label}
      <Icon
        tooltip={{
          theme: "MEDIUM",
          content: tooltip,
        }}
        theme="slate500"
        size="sm"
        SvgIcon={InfoIcon}
      />
    </div>
  );
};

export const DeviceSettingsFlyover: React.FC<{
  device: ImagingDeviceWithSettings;
  onClose: () => void;
  onChange: (item: CaptureDeviceSettings) => void;
  // eslint-disable-next-line complexity
}> = ({ device, onClose, onChange }) => {
  const editStateDirty = useBoolean(false);

  const [newSettings, updateSettings] = useObjectState(device.settings);
  const handleSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      if (e) {
        e.preventDefault();
      }

      onChange({
        ...newSettings,
        overriddenLabel:
          newSettings.overriddenLabel?.trim().length === 0 ? undefined : newSettings.overriddenLabel,
      });
      onClose();
    },
    [newSettings, onChange, onClose]
  );
  const fullUrl = getFullUrl(location);
  const setDirty = editStateDirty.on;
  const handleChanges = useCallback(
    (settings: Partial<CaptureDeviceSettings>) => {
      setDirty();
      updateSettings(settings);
    },
    [setDirty, updateSettings]
  );
  const hasOverride =
    newSettings.overriddenLabel !== undefined && newSettings.overriddenLabel.trim() !== device.label;

  return (
    <Flyover size="md" onClose={onClose} title="Edit Device Settings">
      <FlyoverContent>
        <Form onSubmit={handleSubmit} className="flex flex-col gap-y-6">
          <div className="flex flex-col gap-1">
            <FormFieldInput
              label="Name"
              value={newSettings.overriddenLabel ?? newSettings.label}
              onChange={(e) => handleChanges({ overriddenLabel: e.target.value })}
            />
            {hasOverride ? <div className="text-greyLight text-xs">{device.label}</div> : null}
          </div>

          <RadioList
            label={
              <LabelWithTooltip
                label="Format"
                tooltip="Archy assumes all TWAIN devices produce x-rays, and USB cameras produce photos. This setting allows to override that."
              />
            }
            selectedValue={newSettings.produces ?? device.produces}
            onChange={(_, { value }) => {
              handleChanges({ produces: value });
            }}
            options={FORMAT_OPTIONS}
          />
          {(device.type === "sensor" || device.type === "archy-sensor") && (
            <>
              <RadioList
                label={
                  <LabelWithTooltip
                    label="Capture Order"
                    tooltip="Some sensors allow for a bulk import of images after scanning. This setting reverses the default order when many images are imported at once."
                  />
                }
                selectedValue={Boolean(newSettings.reverseImport)}
                onChange={(_, { value }) => {
                  handleChanges({ reverseImport: value === true ? true : undefined });
                }}
                options={CAPTURE_ORDER_OPTIONS}
              />
              <RadioList
                label={
                  <LabelWithTooltip
                    label="Mirror on Capture"
                    tooltip="This setting allows mirroring an image horizontally or vertically from its original disposition."
                  />
                }
                selectedValue={newSettings.flipOnCapture?.[0] ?? "default"}
                onChange={(_, { value }) => {
                  handleChanges({ flipOnCapture: value === "default" ? undefined : [value] });
                }}
                options={ORIENT_ON_CAPTURE_OPTIONS}
              />
              <CheckboxList
                label={
                  <LabelWithTooltip
                    label="Rotate on Capture"
                    tooltip="Some sensors import images rotated 180 degrees. This setting allows to override that for specific areas of the mouth."
                  />
                }
                selectedValues={new Set(newSettings.rotateOnCapture)}
                onChange={(newSet) => {
                  handleChanges({ rotateOnCapture: [...newSet] });
                }}
                options={ROTATE_ON_CAPTURE}
              />

              <FormFieldSwitch
                label={
                  <LabelWithTooltip
                    label="Continuous Capture"
                    tooltip="For certain sensors, checking this box will allow you to continuously capture until you are done, rather than clicking the capture button for each image. "
                  />
                }
                aria-label="Continuous Capture"
                checked={newSettings.autoReopenAfterCapture}
                onChange={(e) => handleChanges({ autoReopenAfterCapture: e.target.checked })}
                size="lg"
              />
            </>
          )}

          <div className="flex flex-col text-xs gap-4">
            <FormFieldLabel className="font-sansSemiBold" content="Calibration" />
            <div className="flex gap-4 items-center">
              {newSettings.calibration?.date ? `Done ${formatISODate(newSettings.calibration.date)}` : null}
              <ButtonInternalLink
                theme="secondary"
                className="text-primaryTheme"
                to={paths.sensors(
                  { deviceId: device.type === "camera" ? device.deviceId : device.label },
                  { from: fullUrl }
                )}
              >
                {newSettings.calibration ? "Recalibrate" : "Calibrate"}
              </ButtonInternalLink>
            </div>
          </div>
        </Form>
      </FlyoverContent>
      <FlyoverFooter actions>
        <Button className="min-w-button" theme="secondary" onClick={onClose}>
          Cancel
        </Button>
        <Button
          className="min-w-button"
          theme="primary"
          disabled={editStateDirty.isOff}
          onClick={() => handleSubmit()}
        >
          Save
        </Button>
      </FlyoverFooter>
    </Flyover>
  );
};
