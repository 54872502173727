import { stopStream } from "utils/stopStream";

export const requestCameraSources = async () => {
  const devices = await navigator.mediaDevices.enumerateDevices();
  const cameraSources = [];

  for (const [index, deviceInfo] of devices.entries()) {
    if (deviceInfo.kind === "videoinput") {
      cameraSources.push({
        deviceId: deviceInfo.deviceId,
        label: deviceInfo.label || `camera ${index + 1}: ${deviceInfo.deviceId}`,
      });
    }
  }

  return cameraSources;
};

const createImageCapture = (track: MediaStreamTrack) => {
  // ImageCapture is not supported in Safari, iOS Safari or Firefox
  // eslint-disable-next-line compat/compat
  return new ImageCapture(track);
};

export const captureCameraImage = async (stream: MediaStream) => {
  const mediaStreamTrack = stream.getVideoTracks()[0];

  const imageCapture = createImageCapture(mediaStreamTrack);

  const { imageHeight, imageWidth } = await imageCapture.getPhotoCapabilities();

  const blob = await imageCapture.takePhoto({ imageWidth: imageWidth.max, imageHeight: imageHeight.max });

  return blob;
};

export const subscribeToDeviceChanges = (onDeviceChange: (e: Event) => void) => {
  navigator.mediaDevices.addEventListener("devicechange", onDeviceChange);

  return () => {
    navigator.mediaDevices.removeEventListener("devicechange", onDeviceChange);
  };
};

const FOUR_K = {
  width: 4096,
  height: 2160,
};

export const requestCameraStream = async (deviceId: string) => {
  const constraints = {
    video: deviceId
      ? { deviceId: { exact: deviceId }, width: { ideal: FOUR_K.width }, height: { ideal: FOUR_K.width } }
      : true,
  };

  const stream = await navigator.mediaDevices.getUserMedia(constraints);
  const mediaStreamTrack = stream.getVideoTracks()[0];

  const imageCapture = createImageCapture(mediaStreamTrack);

  const photoCapabilities = await imageCapture.getPhotoCapabilities();

  const photoAspect = photoCapabilities.imageWidth.max / photoCapabilities.imageHeight.max;

  // Makes video represent same aspect that the photo will be represented in
  mediaStreamTrack.applyConstraints({
    aspectRatio: photoAspect,
  });

  return {
    stream,
    photoCapabilities,
    closeStream: () => {
      stopStream(stream);
    },
  };
};
