import {
  PatientChartVisualCondition,
  PatientChartVisualProcedure,
  PatientToothVO,
} from "@libs/api/generated-api";
import { half, isInRange } from "@libs/utils/math";
import { numericRegex } from "@libs/utils/regex";

export type TeethMarkings = PatientChartVisualCondition | PatientChartVisualProcedure;

/* eslint-disable @typescript-eslint/naming-convention */
export const nameMapping: Record<number | string, number | string | undefined> = {
  4: "A",
  5: "B",
  6: "C",
  7: "D",
  8: "E",
  9: "F",
  10: "G",
  11: "H",
  12: "I",
  13: "J",
  20: "K",
  21: "L",
  22: "M",
  23: "N",
  24: "O",
  25: "P",
  26: "Q",
  27: "R",
  28: "S",
  29: "T",
  A: 4,
  B: 5,
  C: 6,
  D: 7,
  E: 8,
  F: 9,
  G: 10,
  H: 11,
  I: 12,
  J: 13,
  K: 20,
  L: 21,
  M: 22,
  N: 23,
  O: 24,
  P: 25,
  Q: 26,
  R: 27,
  S: 28,
  T: 29,
};

export const getToothName = (toothNum: number, state: PatientToothVO["state"]) => {
  if (state.startsWith("PRIMARY") && !nameMapping[toothNum]) {
    return "";
  }

  if (state.startsWith("PERMANENT") || state === "MISSING") {
    return `${toothNum}`;
  }

  return nameMapping[toothNum] as string;
};

export const lookupToothName = (teeth: { toothNum: number; toothName: string }[], toothNum: number) => {
  const match = teeth.find((tooth) => tooth.toothNum === toothNum);

  if (!match) {
    return "";
  }

  return match.toothName;
};

export const TEETH_COUNT = 32;
export const TEETH_ROW_COUNT = half(TEETH_COUNT);

const FIRST_SUPERNUMERARY_TOOTH = 51;
const LAST_SUPERNUMERARY_TOOTH = 82;
const SUPERNUMERARY_TOOTHNAME_LENGHT = 2;
const SUPERNUMERARY_TEETH_NUMBER_OFFSET = 50;

export const isValidSupernumeraryToothName = (toothName: string) => {
  if (toothName.length !== SUPERNUMERARY_TOOTHNAME_LENGHT) {
    return false;
  }

  if (numericRegex.test(toothName)) {
    const toothNum = Number(toothName);

    return isInRange(FIRST_SUPERNUMERARY_TOOTH, toothNum, LAST_SUPERNUMERARY_TOOTH);
  }

  const firstChar = toothName[0];

  if (numericRegex.test(firstChar)) {
    return false;
  }

  return firstChar in nameMapping && toothName[1] === "S";
};

export const isValidToothName = (toothName: string) => {
  if (numericRegex.test(toothName)) {
    const toothNum = Number(toothName);

    return isInRange(1, toothNum, TEETH_COUNT);
  }

  return toothName in nameMapping;
};

export const getSupernumeraryAdjacentToothName = (supernumeraryToothName: string) => {
  if (numericRegex.test(supernumeraryToothName)) {
    return String(Number(supernumeraryToothName) - SUPERNUMERARY_TEETH_NUMBER_OFFSET);
  }

  return supernumeraryToothName[0];
};

export const cxLayerColors = {
  white: {
    tooth: "fill-white text-white stroke-white",
    menu: "bg-white",
  },
  condition: {
    tooth: "fill-black text-black stroke-black",
    menu: "bg-black",
  },
  EXISTING_OTHER: {
    tooth: "fill-greenDark text-greenDark stroke-greenDark",
    menu: "bg-greenDark",
  },
  PLANNED: {
    tooth: "fill-redDark text-redDark stroke-redDark",
    menu: "bg-redDark",
  },
  SCHEDULED: {
    tooth: "fill-redDark text-redDark stroke-redDark",
    menu: "bg-redDark",
  },
  DONE: {
    tooth: "fill-blueDark text-blueDark stroke-blueDark",
    menu: "bg-blueDark",
  },
  EXISTING_CURRENT: {
    tooth: "fill-blueDark text-blueDark stroke-blueDark",
    menu: "bg-blueDark",
  },
  REFERRED: {
    tooth: "fill-magentaDark text-magentaDark stroke-magentaDark",
    menu: "bg-magentaDark",
  },
  // doesnt show on odontogram
  // REJECTED: "fill-red text-red stroke-red",
};

export const cxLightLayerColors = {
  condition: {
    tooth: "fill-[#606060] text-[#606060] stroke-[#606060]",
  },
  EXISTING_OTHER: {
    tooth: "fill-[#4FCE85] text-[#4FCE85] stroke-[#4FCE85]",
  },
  PLANNED: {
    tooth: "fill-[#F77878] text-[#F77878] stroke-[#F77878]",
  },
  SCHEDULED: {
    tooth: "fill-[#F77878] text-[#F77878] stroke-[#F77878]",
  },
  DONE: {
    tooth: "fill-[#5AA0FF] text-[#5AA0FF] stroke-[#5AA0FF]",
  },
  EXISTING_CURRENT: {
    tooth: "fill-[#5AA0FF] text-[#5AA0FF] stroke-[#5AA0FF]",
  },
  REFERRED: {
    tooth: "fill-[#DB4B97] text-[#DB4B97] stroke-[#DB4B97]",
  },
  // doesnt show on odontogram
  // REJECTED: "fill-[#E18CBA] text-[#E18CBA] stroke-[#E18CBA]",
};

export const cxCrownSurfaceLayerColors = {
  condition: {
    tooth: "fill-[#808080] text-[#808080] stroke-[#808080]",
  },
  EXISTING_OTHER: {
    tooth: "fill-[#93D6B0] text-[#93D6B0] stroke-[#93D6B0]",
  },
  PLANNED: {
    tooth: "fill-[#F5ABAB] text-[#F5ABAB] stroke-[#F5ABAB]",
  },
  SCHEDULED: {
    tooth: "fill-[#F5ABAB] text-[#F5ABAB] stroke-[#F5ABAB]",
  },
  DONE: {
    tooth: "fill-[#97BFF6] text-[#97BFF6] stroke-[#97BFF6]",
  },
  EXISTING_CURRENT: {
    tooth: "fill-[#97BFF6] text-[#97BFF6] stroke-[#97BFF6]",
  },
  REFERRED: {
    tooth: "fill-[#E18CBA] text-[#E18CBA] stroke-[#E18CBA]",
  },
  // doesnt show on odontogram
  // REJECTED: "fill-[#E18CBA] text-[#E18CBA] stroke-[#E18CBA]",
};
