import {
  ClaimInsuranceCarrierVO,
  CustomAdjustmentTypeVO,
  DentalProcedureVO,
  Filter,
  ProviderVO,
} from "@libs/api/generated-api";
import { toMap } from "@libs/utils/array";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { labelForCarrier } from "components/Dashboard/Adjustments/utils";
import { labelForRecallType } from "utils/labelForRecallType";
import { DashboardFilter, isIdFilter } from "utils/routing/dashboard/serializedFilter";

const parseId = (id: string | null) => (id === null ? null : Number.parseInt(id, 10));

export const upsertDashFilter = (
  filters: DashboardFilter[],
  updatedFilter: DashboardFilter,
  params?: {
    mergeWithExisting?: boolean;
    toggle?: boolean;
  }
): DashboardFilter[] => {
  const { mergeWithExisting, toggle } = params ?? { mergeWithExisting: false, toggle: false };

  if (updatedFilter.values.length === 0) {
    return filters.filter((item) => item.type !== updatedFilter.type);
  } else if (filters.some((item) => item.type === updatedFilter.type)) {
    return (
      filters
        // eslint-disable-next-line complexity
        .map((item) => {
          if (item.type === updatedFilter.type) {
            const [toggleValue] = updatedFilter.values;
            const hasOperator = item.op || updatedFilter.op;
            const hadNull = isIdFilter(item) && item.values.includes(null);
            const hasNull = isIdFilter(updatedFilter) && updatedFilter.values.includes(null);
            const shouldRemove =
              toggle &&
              (((item.values as (string | null)[]).includes(toggleValue) && !hasOperator) ||
                (hasOperator && item.op === updatedFilter.op));
            const newValue = shouldRemove
              ? item.values.filter((val) => val !== toggleValue)
              : (updatedFilter.values as string[]);

            if (mergeWithExisting) {
              let values = shouldRemove ? newValue : [...new Set([...item.values, ...updatedFilter.values])];

              // Null cannot be selected with other values
              if (hadNull && !hasNull) {
                values = values.filter((val) => val !== null);
              } else if (!hadNull && hasNull) {
                values = values.filter((val) => val === null);
              }

              return {
                ...updatedFilter,
                values,
              } as DashboardFilter;
            }

            return { ...updatedFilter, values: newValue } as DashboardFilter;
          }

          return item;
        })
        .filter((item) => item.values.length > 0)
    );
  }

  return [...filters, updatedFilter];
};

/**
 * Transform filters into filters compatible with time-series/charting api
 * @param filters - Array of all dashboard filters
 * @return - filters that are compatible for charting
 */
export const dashboardFiltersForCharting = (filters: DashboardFilter[]): DashboardFilter[] => {
  const chartOnlyFilters = new Set<DashboardFilter["type"]>(["providerId", "providerJobCategory"]);

  return filters.filter(({ type }) => chartOnlyFilters.has(type));
};
/**
 * Transform filters into filters compatible with tables
 * @param filters - Array of all dashboard filters
 * @return - filters that are compatible for tables (all filters for now)
 */
export const dashboardFiltersForTables = (filters: DashboardFilter[]): Filter[] => filters;

export const getFilterSelectedValues = ({
  type,
  filters,
}: {
  type: DashboardFilter["type"];
  filters: DashboardFilter[];
}) => {
  return new Set<string | null>(filters.find(({ type: currType }) => type.includes(currType))?.values);
};

const commaDelimitedEnums = (values: string[]) =>
  values.map((value) => sentenceCaseConstant(value)).join(", ");

// eslint-disable-next-line complexity, complexity, max-statements
export const formatDashboardFilter = ({
  filter,
  providerDisplayName,
  procedures,
  carriers,
  providers,
  adjustmentTypes,
  options,
}: {
  filter: DashboardFilter;
  providerDisplayName?: string;
  procedures?: DentalProcedureVO[];
  carriers?: ClaimInsuranceCarrierVO[];
  providers?: ProviderVO[];
  options: {
    labelProviderAsPrimary?: boolean;
  };
  adjustmentTypes?: CustomAdjustmentTypeVO[];
}) => {
  const handleProviders = (filterType: "providerId" | "associatedProviderId") => {
    const providerTitle =
      filterType === "providerId"
        ? options.labelProviderAsPrimary
          ? "Primary Provider"
          : "Provider"
        : "Associated Provider";

    if (!providers) {
      return `${providerTitle}: ${providerDisplayName ?? ""}`;
    }

    const providerLookup = toMap(providers, "id");

    return `${providerTitle}: ${filter.values
      .map((id) => {
        return id === "-1" || id === null
          ? "No Provider"
          : providerLookup[id]?.name.shortDisplayName ?? "Unknown Provider";
      })
      .join(", ")}`;
  };

  const handleCarriers = () => {
    if (!carriers) {
      return "";
    }

    const carrierLookup = toMap(carriers, "insuranceCarrierId");

    return `Carrier: ${filter.values
      .map((strId) => {
        const carrierId = parseId(strId);

        return carrierId ? labelForCarrier(carrierLookup, carrierId) : "No Carrier";
      })
      .join(", ")}`;
  };

  switch (filter.type) {
    case "dentalProcedureId": {
      const procedureIds = new Set(filter.values.map((val) => parseId(val)));

      if (!procedures) {
        return "";
      }

      return `Procedure: ${procedures
        .filter((procedure) => procedureIds.has(procedure.id))
        .map((item) => item.simpleName)
        .join(", ")}`;
    }
    case "isScheduled": {
      return `Appt Status: ${filter.values
        .map((val) => (val === "true" ? "Incomplete" : "Complete"))
        .join(", ")}`;
    }
    case "providerId":
    case "associatedProviderId": {
      return handleProviders(filter.type);
    }
    case "providerJobCategory": {
      return `Provider: ${filter.values[0] === "DENTIST" ? "Doctors" : "Hygienists"}`;
    }
    case "paymentMethod": {
      return `Payment Method: ${commaDelimitedEnums(filter.values)}`;
    }
    case "patientType": {
      return `Patient Type: ${commaDelimitedEnums(filter.values)}`;
    }
    case "recallType": {
      return `Procedure: ${filter.values.map((type) => labelForRecallType(type)).join(", ")}`;
    }
    case "treatmentPlanState": {
      return `Plan Status: ${commaDelimitedEnums(filter.values)}`;
    }
    case "isHygiene": {
      return `Hygiene: ${commaDelimitedEnums(
        filter.values.map((val) => (val === "true" ? "Include" : "Exclude"))
      )}`;
    }
    case "isMasterTreatmentPlan": {
      return `Treatment Plan: ${filter.values
        .map((val) => (val === "true" ? "Master" : "Not Master"))
        .join(", ")}`;
    }
    case "hasNextAppointment": {
      return `Next Appt: ${filter.values
        .map((val) => (val === "true" ? "Has Next Appt" : "No Next Appt"))
        .join(", ")}`;
    }
    case "hasFirstAppointment": {
      return `Appointment: ${filter.values
        .map((val) => (val === "true" ? "Has First Appt" : "No First Appt"))
        .join(", ")}`;
    }
    case "appointmentState": {
      return `Appt Status: ${commaDelimitedEnums(filter.values)}`;
    }
    case "primaryInsuranceCarrierId":
    case "insuranceCarrierId": {
      return handleCarriers();
    }

    case "claimState": {
      return `Claim Status: ${commaDelimitedEnums(filter.values)}`;
    }
    case "customAdjustmentTypeId": {
      const adjustmentTypeIds = new Set(filter.values.map((val) => parseId(val)));

      if (!adjustmentTypes) {
        return "";
      }

      return `Adjustment Types: ${adjustmentTypes
        .filter((adjustment) => adjustmentTypeIds.has(adjustment.id))
        .map((item) => item.name)
        .join(", ")}`;
    }
    case "insuranceAdjustmentState": {
      return `Adjustment Status: ${commaDelimitedEnums(filter.values)}`;
    }
    case "patientStatus": {
      return `Patient Status: ${commaDelimitedEnums(filter.values)}`;
    }
    case "eventType": {
      return `Entry Type: ${commaDelimitedEnums(filter.values)}`;
    }
    case "referredBy": {
      return `Referred By: ${commaDelimitedEnums(filter.values)}`;
    }
    case "insuranceBalance": {
      return `Insurance Balance: ${filter.op === "NOT_IN" ? "Has Balance" : "No Balance"}`;
    }
    case "uninvoicedBalance": {
      return `Uninvoiced Items: ${filter.op === "NOT_IN" ? "Has Uninvoiced Items" : "No Uninvoiced Items"}`;
    }
    default: {
      return "";
    }
  }
};

export const NO_NEXT_APPT_FILTER: DashboardFilter = { type: "hasNextAppointment", values: ["false"] };
export const ACTIVE_PATIENT_STATUS_FILTER: DashboardFilter = { type: "patientStatus", values: ["ACTIVE"] };

// Default filters for the 'Show by Patient' in Recall
export const ACTIVE_NO_NEXT_APPT_FILTER: DashboardFilter[] = [
  NO_NEXT_APPT_FILTER,
  ACTIVE_PATIENT_STATUS_FILTER,
];
// Unscheduled Procedures report default filter
export const ACTIVE_NO_NEXT_APPT_EXCLUDE_HYGIENE_FILTER: DashboardFilter[] = [
  ...ACTIVE_NO_NEXT_APPT_FILTER,
  {
    type: "isHygiene",
    values: ["false"],
  },
];

// Default filters for the 'Show by Procedure' tab
export const DEFAULT_PROCEDURE_TAB_FILTER: DashboardFilter[] = [
  NO_NEXT_APPT_FILTER,
  ACTIVE_PATIENT_STATUS_FILTER,
  { type: "recallType", values: ["PROPHY"] },
];
