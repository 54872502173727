import { useCallback } from "react";
import { isError } from "@libs/utils/types";
import { CognitoErrorCode } from "@libs/utils/cognito";
import { logSignInError } from "components/SignIn/logSignInError";
import { getCognitoErrorMessage } from "utils/auth";

export const useHandleAuthError = (updateSignInState: (params: { errorMessage: string }) => void) => {
  const handleAuthError = useCallback(
    (error: unknown) => {
      logSignInError(error);

      if (isError(error)) {
        const cognitoError = error as { code?: CognitoErrorCode };

        updateSignInState({
          errorMessage: cognitoError.code ? getCognitoErrorMessage(cognitoError.code) : error.message,
        });
      }
    },
    [updateSignInState]
  );

  return handleAuthError;
};
