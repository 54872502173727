import { FC, useCallback, useMemo, useState } from "react";
import { add, endOfDay, millisecondsToSeconds } from "date-fns";
import designConfig from "@libs/design.config";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isDefined } from "@libs/utils/types";
import { nowInTimezone } from "@libs/utils/date";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Spinner } from "@libs/components/UI/Spinner";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { AlertModal } from "@libs/components/UI/AlertModal";
import { createSelectStyles } from "@libs/components/UI/selectStyles";
import { Switch } from "@libs/components/UI/Switch";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { BaseFormSection } from "components/UI/FormSection";
import { getInfiniteEmployees } from "api/employee/queries";
import { FormFieldMultiSelect } from "components/UI/FormFieldMultiSelect";
import { useInfiniteScrollingSelectComponents } from "hooks/useInfiniteScrollingSelectComponents";
import { CheckboxRow } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/CheckboxRow";
import { resetEmployeeMfa } from "api/employee/mutations";
import { getPracticeSecuritySettings } from "api/practice/queries";
import { updatePracticeSecuritySettings } from "api/practice/mutations";
import { handleError } from "utils/handleError";

const getEnforcementDate = (practiceTimezoneId: string) => {
  // For testing -- starts enforcement 30 seconds from now, soft enforcement immediately
  // return millisecondsToSeconds(add(new Date(), { days: 1, seconds: 30 }).getTime());

  // Starts serverside enforcement in 10 seconds
  // return millisecondsToSeconds(add(new Date(), { seconds: 10 }).getTime());

  return millisecondsToSeconds(add(endOfDay(nowInTimezone(practiceTimezoneId)), { days: 3 }).getTime());
};

export const MFASettingsRoute: FC = () => {
  const practice = useCurrentPractice();
  const updatingMfa = useBoolean(false);
  const mfaEnforcedModalOpen = useBoolean(false);
  const mfaResetModalOpen = useBoolean(false);
  const mfaRestModalOpenOff = mfaResetModalOpen.off;
  const [securitySettingsQuery] = useApiQueries([
    getPracticeSecuritySettings({ args: { practiceId: practice.id } }),
  ]);
  const setUpdatingMfa = updatingMfa.set;
  const employeesQuery = useInfiniteApiQuery(
    getInfiniteEmployees({
      args: {
        orderBy: "ASCENDING",
        pageSize: PAGE_SIZE,
        pageNumber: 1,
        practiceId: practice.id,
        sortColumn: "lastName",
        statuses: ["ACTIVE", "PENDING", "FURLOUGHED"],
      },
    })
  );
  const [selectedEmployeeIds, setSelectedEmployeeIds] = useState(new Set<number>());
  const [resetEmployeeMfaMutation, updatePracticeSecuritySettingsMutation] = useApiMutations([
    resetEmployeeMfa,
    updatePracticeSecuritySettings,
  ]);
  const resetEmployeeMfaMutateAsync = resetEmployeeMfaMutation.mutateAsync;
  const updateMfaEnforcedMutateAsync = updatePracticeSecuritySettingsMutation.mutateAsync;
  const handleMFAReset = useCallback(async () => {
    mfaRestModalOpenOff();

    setUpdatingMfa(true);

    try {
      await Promise.all(
        [...selectedEmployeeIds].map((employeeId) => {
          return resetEmployeeMfaMutateAsync({
            practiceId: practice.id,
            employeeId,
          });
        })
      );
      setSelectedEmployeeIds(new Set());
    } catch (e) {
      handleError(e);
    }

    setUpdatingMfa(false);
  }, [setUpdatingMfa, mfaRestModalOpenOff, selectedEmployeeIds, resetEmployeeMfaMutateAsync, practice.id]);
  const customStyles = useMemo(
    () =>
      createSelectStyles<number, SelectOption<number>, true>({
        valueContainer: () => ({
          padding: 0,
        }),
        option: (_, { isFocused }) => ({
          backgroundColor: isFocused ? designConfig.colors.greyLightest : "white",
        }),
      }),
    []
  );

  const employees = useFlattenPages(employeesQuery.data);

  const employeeOptions = useMemo(() => {
    return (
      employees?.map((employee) => {
        return {
          value: employee.id,
          label: `${employee.personalDetails.firstName} ${employee.personalDetails.lastName}`,
        };
      }) ?? []
    );
  }, [employees]);

  const customComponents = useInfiniteScrollingSelectComponents<SelectOption<number>, true, number>({
    infiniteQuery: employeesQuery,
  });
  const handleMfaEnforced = useCallback(
    async (enforced: boolean) => {
      const mfaEnforcedAt = enforced ? getEnforcementDate(practice.timezoneId) : undefined;

      try {
        await updateMfaEnforcedMutateAsync({
          practiceId: practice.id,
          data: {
            mfaEnforcedAt,
          },
        });
      } catch (err) {
        handleError(err);
      }
    },
    [practice.id, practice.timezoneId, updateMfaEnforcedMutateAsync]
  );
  const mfaEnabled = isDefined(securitySettingsQuery.data?.mfaEnforcedAt);

  return (
    <>
      <SettingsPanel title="Multi Factor Authentication">
        <div className="flex flex-col gap-8">
          <BaseFormSection title="Enable Multi Factor Authentication">
            <div className="flex flex-col gap-4 text-xs">
              <p>
                Multi factor authentication is an extra layer of security. When enabled, employees will be
                asked to enter an extra code via an authenticator app during log in.
              </p>
              <p>
                <strong>Warning: </strong>All employees will have 48 hours to set up MFA upon re-login once
                it’s enabled for the practice.
              </p>
              <Switch
                widthFit
                checked={mfaEnabled}
                onChange={(e) => {
                  if (e.target.checked) {
                    mfaEnforcedModalOpen.on();

                    return;
                  }

                  handleMfaEnforced(false);
                }}
              >
                {updatePracticeSecuritySettingsMutation.isLoading || securitySettingsQuery.isLoading ? (
                  <Spinner animation="border" variant="primary" size="xs" />
                ) : (
                  `Multi Factor Authentication ${mfaEnabled ? "enabled" : "disabled"}`
                )}
              </Switch>
            </div>
          </BaseFormSection>
          <BaseFormSection title="Reset Multi Factor Authentication">
            <div className="flex flex-col gap-4 text-xs">
              <p>
                If your employee cannot log in due to multi factor authentication, you can reset it for them.
              </p>
              <div className="flex flex-col gap-6">
                <FormFieldMultiSelect
                  label="Select Employees"
                  captureMenuScroll={false}
                  options={employeeOptions}
                  onChange={(option) => {
                    setSelectedEmployeeIds(new Set(option.map((o) => o.value)));
                  }}
                  hideSelectedOptions={false}
                  formatOptionLabel={({ label: optionLabel, value, isDisabled }) => {
                    return (
                      <CheckboxRow
                        label={optionLabel}
                        checked={selectedEmployeeIds.has(value)}
                        disabled={isDisabled}
                      />
                    );
                  }}
                  styles={customStyles}
                  components={customComponents}
                  closeMenuOnSelect={false}
                  layout="labelOut"
                  className="max-w-lg"
                  value={[...selectedEmployeeIds]}
                />
                <div>
                  <AsyncButton
                    isLoading={updatingMfa.isOn}
                    onClick={mfaResetModalOpen.on}
                    className="min-w-button"
                    disabled={selectedEmployeeIds.size === 0}
                  >
                    Reset MFA
                  </AsyncButton>
                </div>
              </div>
            </div>
          </BaseFormSection>
        </div>
      </SettingsPanel>
      {mfaResetModalOpen.isOn && (
        <AlertModal
          onConfirm={handleMFAReset}
          primaryText="Reset MFA"
          confirmText="Yes"
          onCancel={mfaResetModalOpen.off}
        >
          <div className="flex flex-col gap-4 font-sans text-xs mt-1 text-center">
            <p>Are you sure you want to reset MFA for these employees?</p>
            <p>They will be asked to set up MFA upon re-login.</p>
          </div>
        </AlertModal>
      )}
      {mfaEnforcedModalOpen.isOn && (
        <AlertModal
          onConfirm={() => {
            mfaEnforcedModalOpen.off();
            handleMfaEnforced(true);
          }}
          primaryText="Multi Factor Authentication"
          secondaryText="Are you sure you want to turn MFA on for all employees? They will have 48 hours to set up MFA upon re-login."
          confirmText="Yes"
          onCancel={mfaEnforcedModalOpen.off}
        />
      )}
    </>
  );
};
