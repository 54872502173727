import { FC, useCallback } from "react";

import { RankedPatientMessageVO, PatientSummaryVO, MessageVO } from "@libs/api/generated-api";
import { UseInfiniteApiQueryResult, ApiQueryResult } from "@libs/@types/apiQueries";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";
import { LayoutCard } from "@libs/components/UI/LayoutCard";

import { QueryResult } from "@libs/components/UI/QueryResult";
import { MessagingContactsList } from "components/Messaging/MessagingContactsList";
import { MessagingHeader } from "components/Messaging/MessagingHeader";
import { MessagingConversation } from "components/Messaging/MessagingConversation";
import { MessageComposer } from "components/Messaging/MessageComposer";
import { ContactSearch } from "components/Messaging/ContactSearch";
import { ContactBanner } from "components/Messaging/ContactBanner";

import NoPatientSelectedConversation from "assets/images/empty-conversation.svg";
import EmptyMessages from "assets/images/empty-messages.svg";

import { isTextingDisabled } from "components/Patient/contactModes";

export const MAX_MESSAGE_LENGTH = 600;

interface Props {
  message: string;
  selectedPatientId: number | undefined;
  selectedPatientContactQuery: ApiQueryResult<RankedPatientMessageVO[]>;
  practiceContactMessagesQuery: UseInfiniteApiQueryResult<RankedPatientMessageVO[]>;
  patientsWithSameContactQuery: ApiQueryResult<PatientSummaryVO[]>;
  patientQuery: ApiQueryResult<PatientSummaryVO>;
  patientMessagesQuery: UseInfiniteApiQueryResult<MessageVO[]>;
  contactCounter: number;
  contactSearchString: string;
  isSearching: boolean;
  onSearchContact: (value: string) => void;
  onSearchClear: Func;
  onSelectContact: (patientId: number) => void;
  onChangeReadStatus: (patientId: number, isRead: boolean) => void;
  onMessageChange: (patientId: number, message: string) => void;
  onSendMessage: (patientId: number, message: string) => void;
  onRetryFailedMessage: (messageId: number) => void;
  onRequestCancelScheduledMessage: (message: MessageVO) => void;
}

export const Messaging: FC<Props> = ({
  message,
  selectedPatientId,
  selectedPatientContactQuery,
  patientQuery,
  practiceContactMessagesQuery,
  patientsWithSameContactQuery,
  patientMessagesQuery,
  contactSearchString,
  contactCounter,
  onSearchContact,
  onSearchClear,
  onSelectContact,
  onChangeReadStatus,
  onSendMessage,
  onMessageChange,
  onRetryFailedMessage,
  onRequestCancelScheduledMessage,
  isSearching,
}) => {
  const selectedPatient = selectedPatientContactQuery.data?.[0];
  const suggestStartFirstConversation = !practiceContactMessagesQuery.isLoading && contactCounter === 0;
  const suggestSelectPatient = !selectedPatientId;

  const handleLoadReadStatus = useCallback(
    (patientId: number, isRead?: boolean) => {
      if (isRead === false) {
        onChangeReadStatus(patientId, false);
      }
    },
    [onChangeReadStatus]
  );

  // When selectedPatient is loaded for the first time, we want to automatically
  // mark their message as "Read" if it is "Unread" on load, or when the user
  // selects a new contact that has an "Unread" status
  useSyncOnce(({ patientId, isRead }) => handleLoadReadStatus(patientId, isRead), selectedPatient);

  const handleSelectContact = useCallback(
    (patientId: number, isRead: boolean) => {
      onSelectContact(patientId);
      handleLoadReadStatus(patientId, isRead);
    },
    [onSelectContact, handleLoadReadStatus]
  );

  return (
    <LayoutCard className="flex h-full overflow-hidden">
      <div
        className={`
          flex
          flex-col
          w-80
          border-r
          border-greyLighter
          overflow-hidden
        `}
      >
        <ContactSearch
          contactSearchString={contactSearchString}
          onSearchContact={onSearchContact}
          onSearchClear={onSearchClear}
        />

        {suggestStartFirstConversation ? (
          <div
            className={`
              flex
              flex-col
              flex-1
              items-center
              justify-center
              px-6
              gap-y-5
            `}
          >
            <img src={EmptyMessages} alt="No Previous Conversations" />
            <span className="text-sm text-center">
              No previous conversations.
              <br />
              Start a new conversation by searching for a patient above!
            </span>
          </div>
        ) : (
          <MessagingContactsList
            practiceContactMessagesQuery={practiceContactMessagesQuery}
            selectedPatientId={selectedPatient?.patientId}
            onSelectContact={handleSelectContact}
            onChangeReadStatus={onChangeReadStatus}
            isSearch={isSearching}
          />
        )}
      </div>

      {suggestSelectPatient ? (
        <div className="flex flex-col flex-1 items-center justify-center gap-y-5">
          <img src={NoPatientSelectedConversation} alt="Select Patient" />
          <span className="text-sm">Select a patient to start a conversation</span>
        </div>
      ) : (
        <div className="flex flex-col flex-1" data-testid="messaging-contact-container">
          <QueryResult queries={[patientsWithSameContactQuery, patientQuery, selectedPatientContactQuery]}>
            {patientsWithSameContactQuery.data && patientQuery.data && selectedPatient ? (
              <>
                <div className="p-6 border-b border-greyLighter" data-testid="messaging-header">
                  <div className="flex items-center justify-between gap-x-4 h-8">
                    <MessagingHeader
                      patientsWithSameContact={patientsWithSameContactQuery.data}
                      selectedPatient={selectedPatient}
                      patient={patientQuery.data}
                    />
                  </div>
                </div>

                <MessagingConversation
                  patientMessagesQuery={patientMessagesQuery}
                  selectedPatient={selectedPatient}
                  onRetryFailedMessage={onRetryFailedMessage}
                  onRequestCancelScheduledMessage={onRequestCancelScheduledMessage}
                  onChangeReadStatus={onChangeReadStatus}
                />

                <ContactBanner
                  key={selectedPatient.id}
                  className="mx-6 mb-2"
                  patientName={selectedPatient.patientName}
                  contact={patientQuery.data.contact}
                />

                <MessageComposer
                  message={message}
                  onMessageChange={(msg) => onMessageChange(selectedPatient.patientId, msg)}
                  onSend={() => onSendMessage(selectedPatient.patientId, message)}
                  maxCharacters={MAX_MESSAGE_LENGTH}
                  disabled={isTextingDisabled(patientQuery.data.contact)}
                />
              </>
            ) : null}
          </QueryResult>
        </div>
      )}
    </LayoutCard>
  );
};
