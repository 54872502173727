import Skeleton from "react-loading-skeleton";
import React from "react";
import { Cell, HeaderCell, TableGrid } from "@libs/components/UI/GridTableComponents";

const COLUMNS = [
  {
    title: "Device Name",
    width: "minmax(6rem,1fr)",
    id: "device",
  },
  {
    title: "Format",
    width: "6.5rem",
    id: "format",
  },
  {
    title: "Capture Order",
    width: "8rem",
    id: "importOrder",
  },
  {
    title: "Mirror on Capture",
    width: "8rem",
    id: "orientOnCapture",
  },
  {
    title: "Rotate on Capture",
    width: "minmax(10rem,1fr)",
    id: "rotateOnCapture",
  },
  {
    title: "Calibration",
    id: "calibration",
    width: "8.5rem",
  },
  {
    title: "Enabled",
    width: "7.5rem",
    id: "deviceEnabled",
  },
  {
    title: "",
    width: "4rem",
    id: "editButton",
  },
];

export const CaptureDeviceTable: React.FC<{
  children: React.ReactNode;
  isLoading: boolean;
}> = ({ children, isLoading }) => {
  return (
    <TableGrid columnWidths={COLUMNS.map(({ width }) => width)}>
      {COLUMNS.map(({ title, id }) => (
        <HeaderCell size="short" key={id}>
          {title}
        </HeaderCell>
      ))}
      {isLoading
        ? [...COLUMNS, ...COLUMNS, ...COLUMNS].map((_, i) => (
            <Cell className="p-2" key={i}>
              <Skeleton />
            </Cell>
          ))
        : children}
    </TableGrid>
  );
};
