import { FC, PropsWithChildren, useState } from "react";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { RadioList } from "@libs/components/UI/RadioList";
import { Modal, ModalProps } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter, ModalForm } from "@libs/components/UI/ModalComponents";

export type ChangeMode = "CHANGE_ONE" | "CHANGE_FUTURE" | "CHANGE_ALL";
export type ChangeModeOption = { label: string; value: ChangeMode };
export const changeOptions: { label: string; value: ChangeMode }[] = [
  { label: "This time block", value: "CHANGE_ONE" },
  { label: "This and following time blocks", value: "CHANGE_FUTURE" },
  { label: "All time blocks", value: "CHANGE_ALL" },
];

export interface RecurringBlockModalProps {
  title: NonNullable<ModalProps["title"]>;
  options?: ChangeModeOption[];
  isSaving?: boolean;
  onClose: NonNullable<ModalProps["onClose"]>;
  onCancel: Func;
  onConfirm: (value: ChangeMode) => void;
}

export const RecurringBlockModal: FC<PropsWithChildren<RecurringBlockModalProps>> = ({
  title,
  children,
  options = changeOptions,
  isSaving,
  onClose,
  onCancel,
  onConfirm,
}) => {
  const [state, setState] = useState<ChangeMode>(() => options[0].value);

  return (
    <Modal size="2xs" title={title} onClose={onClose}>
      <ModalForm
        onSubmit={(e) => {
          e.preventDefault();
          onConfirm(state);
        }}
      >
        <ModalContent padding="lg">
          {children}
          <RadioList
            selectedValue={state}
            options={options}
            onChange={(_, option) => setState(option.value)}
            layout="vert"
          />
        </ModalContent>
        <ModalFooter>
          <Button onClick={onCancel} theme="secondary" className="min-w-button">
            Cancel
          </Button>
          <AsyncButton isLoading={isSaving ?? false} type="submit" className="min-w-button">
            Confirm
          </AsyncButton>
        </ModalFooter>
      </ModalForm>
    </Modal>
  );
};
