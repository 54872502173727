import { DarkRoomButton } from "components/PatientProfile/Imaging/DarkRoomButton";
import { useImageEditorContext } from "components/PatientProfile/Imaging/ImageEditor/ImageEditorContext";

const BUTTON_OPTIONS = [
  {
    label: "Invert",
    value: "invert" as const,
  },
  {
    label: "Equalize",
    value: "equalize" as const,
  },
  {
    label: "Auto Enhance",
    value: "auto-enhance" as const,
  },
  {
    label: "ColorMap",
    value: "colorMap" as const,
  },
];

export const LensesMenu: React.FC = () => {
  const { setLensApplied, lensApplied } = useImageEditorContext();

  return (
    <div className="flex flex-col gap-2 items-center w-42 px-4 py-2">
      {BUTTON_OPTIONS.map((button) => {
        const isSelected = lensApplied === button.value;

        return (
          <DarkRoomButton
            key={button.value}
            theme={isSelected ? "primary" : "secondary"}
            className="w-full"
            onClick={() => {
              setLensApplied(isSelected ? "none" : button.value);
            }}
          >
            {button.label}
          </DarkRoomButton>
        );
      })}
    </div>
  );
};
