import { useState, useCallback } from "react";
import { PatientToothVO, UpsertPatientToothRequest } from "@libs/api/generated-api";
import { RadioList } from "@libs/components/UI/RadioList";
import { Button } from "@libs/components/UI/Button";
import { ToothChartSelections } from "components/Charting/toothChartData";

interface Props {
  selectedTeeth: ToothChartSelections;
  teeth: PatientToothVO[] | undefined;
  onApply: (updates: UpsertPatientToothRequest[]) => void;
}

type ApplyTo = "selected" | "all";

const getApplyToOptions = (
  hasSelections: boolean
): { value: ApplyTo; label: string; disabled?: boolean }[] => [
  {
    value: "selected",
    label: "Selected",
    disabled: !hasSelections,
  },
  {
    value: "all",
    label: "All Teeth",
  },
];

const StateOptions: { value: PatientToothVO["state"]; label: string }[] = [
  {
    value: "PRIMARY_UNERUPTED",
    label: "Primary Unerupted",
  },
  {
    value: "PRIMARY",
    label: "Primary",
  },
  {
    value: "PERMANENT_UNERUPTED",
    label: "Permanent Unerupted",
  },
  {
    value: "PERMANENT",
    label: "Permanent",
  },
  {
    value: "MISSING",
    label: "Missing",
  },
];

export const ToothStateMenu: React.FC<Props> = ({ selectedTeeth, teeth, onApply }) => {
  const hasSelections = selectedTeeth.type !== "TOOTH" || selectedTeeth.value.size > 0;
  const [applyTo, setApplyTo] = useState<ApplyTo>(() => (hasSelections ? "selected" : "all"));
  const [state, setState] = useState<PatientToothVO["state"] | "">("");

  const handleApply = useCallback(() => {
    if (teeth && state) {
      const teethSet =
        selectedTeeth.type === "NONE"
          ? new Set<number>()
          : selectedTeeth.type === "TOOTH"
            ? selectedTeeth.value
            : selectedTeeth.type === "ARCH"
              ? new Set(
                  teeth.filter((tooth) => selectedTeeth.value.has(tooth.arch)).map(({ toothNum }) => toothNum)
                )
              : new Set(
                  teeth
                    .filter((tooth) => selectedTeeth.value.has(tooth.quadrant))
                    .map(({ toothNum }) => toothNum)
                );

      const updating = applyTo === "all" ? teeth : teeth.filter((t) => teethSet.has(t.toothNum));

      onApply(
        updating.map((t) => ({
          // if tooth's state changes the conditions no longer apply
          conditions: t.state === state ? t.conditions : [],
          toothNum: t.toothNum,
          state,
        }))
      );
    }
  }, [onApply, state, teeth, applyTo, selectedTeeth]);

  return (
    <div className="p-4">
      <RadioList
        label="Apply To"
        layout="vert"
        selectedValue={applyTo}
        onChange={(_e, value) => setApplyTo(value.value)}
        optionClassName="whitespace-nowrap"
        options={getApplyToOptions(hasSelections)}
      />
      <div className="border-t border-t-greyLighter w-full mt-2 mb-3" />
      <RadioList
        label="Mark as"
        layout="vert"
        required={true}
        selectedValue={state}
        onChange={(_e, value) => setState(value.value)}
        optionClassName="whitespace-nowrap"
        options={StateOptions}
      />
      <Button className="w-full mt-3" type="button" disabled={!state} onClick={handleApply}>
        Apply
      </Button>
    </div>
  );
};
