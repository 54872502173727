import { cx } from "@libs/utils/cx";
import { OptionInput, ValueTypes } from "@libs/components/UI/OptionInput";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { OptionInputList, RadioInputListProps } from "@libs/components/UI/OptionInputList";

interface IconSwitchOption<V extends ValueTypes> {
  Icon: IconComponent;
  value: V;
  label: string;
}
interface IconSwitchProps<V extends ValueTypes>
  extends Omit<
    RadioInputListProps<V, IconSwitchOption<V>>,
    "type" | "renderOptionItem" | "optionClassName" | "children"
  > {
  options: [IconSwitchOption<V>, IconSwitchOption<V>];
}

export const IconSwitch = <V extends ValueTypes>({ className, options, ...props }: IconSwitchProps<V>) => {
  return (
    <OptionInputList
      type="radio"
      className={cx(
        `rounded-3xl
         cursor-pointer
         h-8
         w-[68px]
         relative
         block
         bg-white`,
        className
      )}
      options={options}
      optionClassName={({ value }) =>
        cx(
          `absolute
           z-[1]
           top-1/2
           -translate-y-1/2
           cursor-pointer
           flex
           items-center
           justify-center
           w-8
           h-8`,
          value === options[0].value ? "left-0.5" : "right-0.5"
        )
      }
      renderOptionItem={({ children: _children, ...optionProps }, index, option) => (
        <OptionInput {...optionProps}>
          <FloatingTooltip content={option.label} theme="SMALL">
            <option.Icon
              className={cx(
                "w-5 h-5 transition-colors ease-in-out",
                optionProps.checked ? "text-white" : "text-primaryTheme"
              )}
            />
          </FloatingTooltip>
        </OptionInput>
      )}
      {...props}
    >
      <span
        className={cx(
          `rounded-full
           absolute
           block
           h-9
           w-9
           bg-primaryTheme
           ease-in-out
           transition-transform
           top-1/2
           left-0
           -translate-y-1/2`,
          props.selectedValue === options[0].value ? "translate-x-0" : "translate-x-8"
        )}
      />
    </OptionInputList>
  );
};
