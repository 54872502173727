import { FC, useMemo, useCallback } from "react";
import { PatientInsuranceResponse } from "@libs/api/generated-api";
import { isLeftISODateAfterRightISODate, isLeftISODateAfterOrEqualRightISODate } from "@libs/utils/date";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { getClaimsByAppointment } from "api/claim/queries";
import { getInfiniteAppointmentsQuery } from "api/patients/queries";
import { AppointmentClaimSummaryCard } from "components/Claims/Claims/AppointmentClaimSummaryCard";
import { FlyoverContent } from "components/UI/FlyoverComponents";
import { AppointmentSummary } from "components/Claims/Claims/createClaimTypes";
import { LoadingState } from "components/Main/LoadingState";

interface Props {
  onNavigateToCreateClaim: (selectedAppt: AppointmentSummary) => void;
  patientId: number;
  patientInsurancesQuery: ApiQueryResult<PatientInsuranceResponse[]>;
}

export const CreateClaimSelectApptContent: FC<Props> = ({
  onNavigateToCreateClaim,
  patientId,
  patientInsurancesQuery,
}) => {
  const { practiceId } = useAccount();
  const patientAppointmentsQuery = useInfiniteApiQuery(
    getInfiniteAppointmentsQuery({
      args: {
        dateMode: "PAST",
        includeStates: ["COMPLETED"],
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        patientId,
        practiceId,
      },
    })
  );

  const patientAppointments = useFlattenPages(patientAppointmentsQuery.data);
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: patientAppointmentsQuery });

  const [claimsByAppointmentQuery] = useApiQueries([
    getClaimsByAppointment({
      args: {
        ids: patientAppointments?.map((appt) => appt.id) ?? [],
        practiceId,
      },
      queryOptions: {
        enabled: Boolean(patientAppointments?.length),
      },
    }),
  ]);

  const appointmentsById: Record<string, AppointmentSummary> = useMemo(() => {
    const apptsById = (patientAppointments ?? []).reduce(
      (acc, appt) => {
        acc[appt.id] = {
          claimsLoaded: false,
          date: appt.date,
          dentist: appt.dentist,
          id: appt.id,
          patientProcedures: appt.patientProcedures,
          provider: appt.provider,
          startTime: appt.startTime,
        };

        return acc;
      },
      {} as Record<string, AppointmentSummary>
    );

    if (Object.keys(apptsById).length && claimsByAppointmentQuery.data) {
      claimsByAppointmentQuery.data.forEach((claim) => {
        const appt = { ...apptsById[claim.appointmentId] };

        appt.claims ??= [];
        appt.claimUuids ??= new Set();

        if (!appt.claimUuids.has(claim.claimUuid)) {
          appt.claims.push(claim);
          appt.claimUuids.add(claim.claimUuid);
        }

        apptsById[claim.appointmentId] = appt;
      });

      Object.keys(apptsById).forEach((id) => {
        const appt = { ...apptsById[id] };

        appt.claimsLoaded = true;
        apptsById[id] = appt;
      });
    }

    if (Object.keys(apptsById).length && patientInsurancesQuery.data) {
      Object.keys(apptsById).forEach((id) => {
        const appt = { ...apptsById[id] };

        appt.patientInsurances = patientInsurancesQuery.data
          // eslint-disable-next-line complexity
          ?.filter((insurance) => {
            if (
              insurance.patientInsurance.startDate &&
              isLeftISODateAfterOrEqualRightISODate(appt.date, insurance.patientInsurance.startDate) &&
              insurance.patientInsurance.expiryDate &&
              isLeftISODateAfterOrEqualRightISODate(insurance.patientInsurance.expiryDate, appt.date)
            ) {
              return true;
            } else if (insurance.patientInsurance.startDate && insurance.patientInsurance.expiryDate) {
              return false;
            } else if (!insurance.patientInsurance.startDate && !insurance.patientInsurance.expiryDate) {
              return true;
            } else if (!insurance.patientInsurance.startDate) {
              return (
                insurance.patientInsurance.expiryDate &&
                isLeftISODateAfterOrEqualRightISODate(insurance.patientInsurance.expiryDate, appt.date)
              );
            } else if (!insurance.patientInsurance.expiryDate) {
              return isLeftISODateAfterRightISODate(appt.date, insurance.patientInsurance.startDate);
            }

            return true;
          })
          .map((insurance) => insurance.patientInsurance);
        apptsById[id] = appt;
      });
    }

    return apptsById;
  }, [claimsByAppointmentQuery.data, patientAppointments, patientInsurancesQuery.data]);

  const sortedAppointments = useMemo(
    () =>
      Object.values(appointmentsById).sort((a, b) =>
        `${b.date}T${b.startTime}`.localeCompare(`${a.date}T${a.startTime}`)
      ),
    [appointmentsById]
  );

  const onClickApptCard = useCallback(
    (appt: AppointmentSummary) => onNavigateToCreateClaim(appt),
    [onNavigateToCreateClaim]
  );

  return (
    <FlyoverContent>
      <QueryResult queries={[patientAppointmentsQuery, patientInsurancesQuery]}>
        {sortedAppointments.length ? (
          <div className="flex-1 flex flex-col h-full gap-y-4" ref={rootRef}>
            <div>Select an appointment to create a claim</div>
            <div className="flex-1 flex flex-col gap-y-2 overflow-y-auto">
              <ScrollableInfiniteQueryResult
                infiniteQuery={patientAppointmentsQuery}
                loading={<LoadingState />}
                loadMore={<LoadingState />}
                loadMoreClassName="w-full"
                scrollRef={scrollRef}
              >
                {sortedAppointments.map((appointment) => (
                  <AppointmentClaimSummaryCard
                    appointment={appointment}
                    key={appointment.id}
                    onClick={() => onClickApptCard(appointment)}
                  />
                ))}
              </ScrollableInfiniteQueryResult>
            </div>
          </div>
        ) : (
          <div>There are no available appointments</div>
        )}
      </QueryResult>
    </FlyoverContent>
  );
};
