import { lazy, Suspense } from "react";
import { LoadingContent } from "@libs/components/UI/LoadingContent";

const FormsKioskRoute = lazy(
  () => import(/* webpackChunkName: "forms-kiosk-settings" */ "./FormsKioskRoute")
);

export const LazyFormsKioskRoute: React.FC = (props) => {
  return (
    <Suspense fallback={<LoadingContent />}>
      <FormsKioskRoute {...props} />
    </Suspense>
  );
};
