import { useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { SECOND_IN_MS } from "@libs/utils/date";
import { getArch } from "@libs/utils/charting";
import { isDefined } from "@libs/utils/types";
import { PerioChartExamSettingsVO } from "@libs/api/generated-api";
import { half } from "@libs/utils/math";
import { FocusDirection, useFocusManager } from "contexts/FocusManagerContext";
import { usePerioChart } from "components/Charting/Perio/PerioChartContext";
import {
  getControlInfo,
  PerioControlInfo,
  SEQUENCE_TYPES_ORDERED,
  sequenceToVisibility,
} from "components/Charting/Perio/perioChartUtils";
import { PerioChartToothSurfaceType } from "components/Charting/Perio/PerioTypes";

const THREE_SECONDS = 3;

const isArrowPress = (key: string): key is "ArrowLeft" | "ArrowRight" | "ArrowUp" | "ArrowDown" =>
  new Set(["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"]).has(key);

const getLeftRightFocus = ({
  arch,
  key,
  settings,
  surface,
}: {
  key: "ArrowRight" | "ArrowLeft";
  settings: PerioChartExamSettingsVO;
  arch: "ARCH_UPPER" | "ARCH_LOWER";
  surface: PerioChartToothSurfaceType;
}) => {
  const arrowLeftFocus =
    (arch === "ARCH_UPPER" && settings.autoAdvance === "LEFT") ||
    (arch === "ARCH_LOWER" && settings.autoAdvanceMandibular === "LEFT")
      ? surface === PerioChartToothSurfaceType.FACIAL
        ? FocusDirection.NEXT
        : FocusDirection.PREV
      : surface === PerioChartToothSurfaceType.FACIAL
        ? FocusDirection.PREV
        : FocusDirection.NEXT;

  return key === "ArrowLeft"
    ? arrowLeftFocus
    : // Always go opposite of left, for right focus:
      arrowLeftFocus === FocusDirection.NEXT
      ? FocusDirection.PREV
      : FocusDirection.NEXT;
};

const FIND_VERTICAL_FOCUS_OFFSET_INCREMENT = 5;
const MAX_OFFSET_ATTEMPTS = 100;
const VERTICAL_SCROLL_BUFFER = 50;
const hasReachedVerticalBoundry = ({
  direction,
  controlInfo,
  settings,
}: {
  direction: "ArrowUp" | "ArrowDown";
  settings: PerioChartExamSettingsVO;
  controlInfo: NonNullable<PerioControlInfo>;
}) => {
  if (!isDefined(controlInfo.toothNum)) {
    return true;
  }

  const arch = getArch(controlInfo.toothNum - 1);
  const isNavigatingTowardBoundary =
    (direction === "ArrowUp" && arch === "ARCH_UPPER") ||
    (direction === "ArrowDown" && arch === "ARCH_LOWER");

  // We filter out AUTOCAL because it can't be focused
  const boundarySequence = SEQUENCE_TYPES_ORDERED.filter((item) => item !== "AUTOCAL").find(
    (sequenceType) => settings[sequenceToVisibility[sequenceType]]
  );

  return (
    isNavigatingTowardBoundary &&
    controlInfo.sequence === boundarySequence &&
    controlInfo.surface === PerioChartToothSurfaceType.FACIAL
  );
};

// Checks if the element Y has crossed the threshold into an area that may need scrolling to see the next element
const isVerticalScrollNeeded = (
  key: "ArrowUp" | "ArrowDown",
  elemY: number,
  scrollRef?: React.RefObject<HTMLDivElement> | undefined
) => {
  if (!scrollRef?.current) {
    return false;
  }

  return (
    (key === "ArrowDown" && elemY > scrollRef.current.clientHeight - VERTICAL_SCROLL_BUFFER) ||
    (key === "ArrowUp" && elemY < VERTICAL_SCROLL_BUFFER + scrollRef.current.offsetTop)
  );
};

// Because of the complexity with identifying the next vertical focus element, we take a ray tracing approach to find the next element.
// we test a point every FIND_VERTICAL_FOCUS_OFFSET_INCREMENT pixels in the direction of the arrow key, MAX_OFFSET_ATTEMPTS times.  If we find
// an element that isn't disabled in the perio chart, we return it
const getNextVerticalFocusElem = ({
  key,
  activeElem,
  controlInfo,
  settings,
  scrollRef,
}: {
  key: "ArrowUp" | "ArrowDown";
  activeElem: Element;
  settings: PerioChartExamSettingsVO;
  controlInfo: NonNullable<PerioControlInfo>;
  scrollRef?: React.RefObject<HTMLDivElement>;
}) => {
  if (hasReachedVerticalBoundry({ direction: key, controlInfo, settings })) {
    return undefined;
  }

  const bounds = activeElem.getBoundingClientRect();
  const currentElemCenterPoint = {
    x: bounds.left + half(bounds.width),
    y: bounds.top + half(bounds.height),
  };

  const findNextElem = (): HTMLElement | undefined => {
    for (
      let offset = FIND_VERTICAL_FOCUS_OFFSET_INCREMENT;
      offset < FIND_VERTICAL_FOCUS_OFFSET_INCREMENT * MAX_OFFSET_ATTEMPTS;
      offset += FIND_VERTICAL_FOCUS_OFFSET_INCREMENT
    ) {
      const newY = key === "ArrowUp" ? currentElemCenterPoint.y - offset : currentElemCenterPoint.y + offset;
      const nextElem = document.elementFromPoint(currentElemCenterPoint.x, newY);

      if (
        activeElem !== nextElem &&
        getControlInfo(nextElem as HTMLElement) &&
        nextElem &&
        "focus" in nextElem &&
        !nextElem.getAttribute("disabled")
      ) {
        return nextElem as HTMLElement;
      }
    }

    return undefined;
  };
  const nextElem = findNextElem();

  if (isVerticalScrollNeeded(key, currentElemCenterPoint.y, scrollRef)) {
    const scrollAmount = 200;

    scrollRef?.current?.scrollBy({
      top: key === "ArrowUp" ? -scrollAmount : scrollAmount,
      behavior: "smooth",
    });
  }

  return nextElem;
};

const handleArrowPress = ({
  key,
  handleFocus,
  settings,
  scrollRef,
}: {
  key: "ArrowLeft" | "ArrowRight" | "ArrowUp" | "ArrowDown";
  handleFocus: (focusDirection: FocusDirection, startingTabIndex?: number) => HTMLInputElement | undefined;
  settings: PerioChartExamSettingsVO | undefined;
  scrollRef?: React.RefObject<HTMLDivElement>;
}) => {
  const activeElem = document.activeElement;
  const controlInfo = getControlInfo(activeElem as HTMLElement);

  if (
    !isDefined(activeElem) ||
    !isDefined(controlInfo?.toothNum) ||
    !settings ||
    !isDefined(controlInfo.surface)
  ) {
    return;
  }

  const arch = getArch(controlInfo.toothNum - 1);

  if (key === "ArrowLeft" || key === "ArrowRight") {
    const nextFocus = getLeftRightFocus({ arch, settings, key, surface: controlInfo.surface });

    handleFocus(nextFocus);
  } else {
    const nextVerticalElem = getNextVerticalFocusElem({ key, activeElem, controlInfo, settings, scrollRef });

    if (nextVerticalElem) {
      nextVerticalElem.focus();
    }
  }
};

export const usePerioInputListeners = ({
  chartRef,
  scrollRef,
}: {
  chartRef: React.RefObject<HTMLDivElement>;
  scrollRef?: React.RefObject<HTMLDivElement>;
}) => {
  const { saveExam, settings } = usePerioChart();

  const focusManager = useFocusManager();
  const focus = focusManager.focus;
  const debounceSave = useDebouncedCallback(saveExam, THREE_SECONDS * SECOND_IN_MS);

  useEffect(() => {
    const chartElement = chartRef.current;

    if (!chartElement) {
      return undefined;
    }

    const handleKeyDown = (e: KeyboardEvent) => {
      if (isArrowPress(e.key)) {
        e.preventDefault();
        handleArrowPress({ key: e.key, handleFocus: focus, settings, scrollRef });
      }
    };

    // Listen to all key up and click events to debounce the save.
    chartElement.addEventListener("click", debounceSave, true);
    chartElement.addEventListener("keyup", debounceSave, true);
    chartElement.addEventListener("keydown", handleKeyDown, true);

    return () => {
      chartElement.removeEventListener("click", debounceSave, true);
      chartElement.removeEventListener("keyup", debounceSave, true);
      chartElement.removeEventListener("keydown", handleKeyDown, true);
    };
  }, [chartRef, debounceSave, focus, scrollRef, settings]);
};
