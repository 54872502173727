import { useAccount } from "@libs/contexts/AccountContext";
import { getProviderRollupQuery } from "api/reporting/queries";
import { FilteredProvider } from "components/Dashboard/types";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";

import { TimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";

export type ProviderFilterOption = {
  title: string;
  provider: FilteredProvider;
  value: number;
  items?: ProviderFilterOption[];
};

export const useProviderRollupQueries = ({
  metric,
  filters,
  dateWindow,
}: {
  metric: "productionAmount" | "patientCount" | "appointmentCount" | "netProduction" | "employeeWorkingHour";
  filters?: DashboardFilter[];
  dateWindow: TimeSeriesPageSelections;
}) => {
  const { practiceId } = useAccount();
  const { selectedTimeSegmentParams, timeSeriesParams } = dateWindow;

  return {
    providersInTimeWindowQuery: getProviderRollupQuery({
      args: {
        practiceId,
        data: {
          metric,
          ...selectedTimeSegmentParams,
          filters,
        },
      },
    }),
    allProvidersQuery: getProviderRollupQuery({
      args: {
        practiceId,
        data: {
          metric,
          ...timeSeriesParams,
          filters,
        },
      },
    }),
  };
};
