import { cx } from "@libs/utils/cx";
import EmptyClaims from "assets/images/empty-claims-records.svg";

export const EmptyClaimsList: React.FC<{ wrapperClassName?: string; text: string }> = ({
  wrapperClassName,
  text,
}) => {
  return (
    <div className={cx("col-span-full", wrapperClassName)}>
      <div className={cx("flex flex-col items-center justify-center", wrapperClassName)}>
        <img alt="No Recorded Claims" src={EmptyClaims} />
        <div className="mt-5 text-sm text-greyDark">{text}</div>
      </div>
    </div>
  );
};
