import { useMemo } from "react";
import { components } from "react-select";
import { SelectComponents } from "react-select/dist/declarations/src/components";
import { PatientProcedureVO, TreatmentPlanVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { isDefined } from "@libs/utils/types";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as KioskIcon } from "@libs/assets/icons/tablet.svg";
import { ReactComponent as SendIcon } from "@libs/assets/icons/send.svg";
import { ReactComponent as Delete } from "@libs/assets/icons/delete.svg";
import { ReactComponent as Calendar } from "@libs/assets/icons/calendar.svg";
import { ReactComponent as Send } from "@libs/assets/icons/send-file.svg";
import { ReactComponent as Print } from "@libs/assets/icons/send-to-printer.svg";
import { ReactComponent as PlusCircle } from "@libs/assets/icons/plus-circle.svg";
import { ReactComponent as Prioritize } from "@libs/assets/icons/priority.svg";

import { Select } from "@libs/components/UI/Select";
import { createSelectStyles } from "@libs/components/UI/selectStyles";
import { tablePriorityOptions } from "components/Charting/patientProcedureFieldOptions";
import {
  canDeleteEvery,
  canPreAuthEvery,
  canPrioritizeEvery,
  canScheduleEvery,
  requiresProsthetic,
} from "components/Charting/patientProcedurePermissions";

type ProceduresBulkActionCallback = (procedures: PatientProcedureVO[]) => void;
export interface ProceduresBulkActionCallbacks {
  onScheduleProceduresClick?: ProceduresBulkActionCallback;
  onDeleteProceduresClick: ProceduresBulkActionCallback;
  onCreateTreatmentPlanClick?: ProceduresBulkActionCallback;
  onAddProceduresToTreatmentPlanClick: (
    procedures: PatientProcedureVO[],
    targetTreatmentPlanUuid: string
  ) => void;
  onSendProceduresToPreAuthClick?: ProceduresBulkActionCallback;
  handleCreateTreatmentPlanFormTask: (params: {
    procedures: PatientProcedureVO[];
    forceInsuranceEstimate?: boolean;
    method: "print" | "send" | "kiosk";
  }) => Promise<void>;
  onPrioritizeProceduresClick?: (procedures: PatientProcedureVO[], priority: number) => void;
}

interface Props {
  selectedRows: Set<number>;
  procedures: PatientProcedureVO[] | undefined;
  treatmentPlans?: TreatmentPlanVO[];
  className?: string;
  totalCount: number;
  isPrinting: boolean;
  isSendingToKiosk: boolean;
  currentTreatmentPlan?: TreatmentPlanVO;
}

type AddProceduresSelectComponent = Partial<
  SelectComponents<SelectOption<string>, false, GroupedSelectOption<string, SelectOption<string>>>
>;

const noProceduresSelectedMessage = "Select procedures to perform actions";

const getAddProceduresMenuComponents = (
  hasSelectedProcedures: boolean,
  tooltip: string
): AddProceduresSelectComponent => ({
  IndicatorsContainer: () => null,
  Placeholder: (props) => {
    return (
      <components.Placeholder {...props} className={cx("text-greyDark", props.className)}>
        <Icon
          SvgIcon={PlusCircle}
          size="md"
          disabled={!hasSelectedProcedures}
          theme="primary"
          tooltip={{
            theme: "SMALL",
            content: tooltip,
          }}
        />
      </components.Placeholder>
    );
  },
});

type PrioritizeSelectComponent = Partial<
  SelectComponents<SelectOption<number>, false, GroupedSelectOption<number, SelectOption<number>>>
>;

const getPrioritizeMenuComponents = (canPrioritize: boolean, tooltip: string): PrioritizeSelectComponent => ({
  IndicatorsContainer: () => null,
  Placeholder: (props) => {
    return (
      <components.Placeholder {...props} className={cx("text-greyDark", props.className)}>
        <Icon
          tooltip={{
            theme: "SMALL",
            content: tooltip,
          }}
          size="md"
          disabled={!canPrioritize}
          theme="primary"
          SvgIcon={Prioritize}
        />
      </components.Placeholder>
    );
  },
});

const addProceduresPlanMenuStyles = createSelectStyles<string, SelectOption<string>>({
  menu: () => ({
    width: "max-content",
  }),
});

const priorityMenuStyles = createSelectStyles<number, SelectOption<number>>({
  menu: () => ({
    width: "max-content",
  }),
  menuList: () => ({
    display: "flex",
  }),
});

const isDeleteDisabled = (
  hasSelectedProcedures: boolean,
  canBulkDelete: boolean,
  txPlan?: TreatmentPlanVO
) => {
  if (hasSelectedProcedures) {
    // any procedures can be deleted from an alt/archived tx plan
    if (txPlan) {
      return false;
    }

    // only certain procedures can be deleted from the master tx plan
    if (canBulkDelete) {
      return false;
    }

    return true;
  }

  // if no procedures are selected and the user is currently
  // on an alt/archived tx plan it's assumed the delete action
  // is to delete the tx plan. The active plan cannont be deleted
  if (txPlan && txPlan.state !== "ACTIVE") {
    return false;
  }

  return true;
};

const getSelectedProceduresTooltips = ({
  canBulkSchedule,
  canPrioritize,
  canBulkPreAuth,
  canBulkDelete,
  currentTreatmentPlan,
  requiresProstheticInfo,
}: {
  canBulkSchedule: boolean;
  canPrioritize: boolean;
  canBulkPreAuth: boolean;
  canBulkDelete: boolean;
  currentTreatmentPlan: TreatmentPlanVO | undefined;
  requiresProstheticInfo: boolean;
}) => ({
  add: "Add Procedures To Treatment Plan",
  schedule: canBulkSchedule ? "Schedule Procedures" : 'Only "Planned" procedures can be scheduled.',
  prioritize: canPrioritize
    ? "Prioritize Procedures"
    : 'Only "Planned", "Scheduled", or "Referred" procedures can be prioritized.',
  remove:
    canBulkDelete || currentTreatmentPlan
      ? "Remove Selected Procedures"
      : '"Completed" and "Scheduled" procedures cannot be removed.',
  edit: "Edit Clinical Notes",
  preAuth: requiresProstheticInfo
    ? 'Some of the selected procedures require the "Crown, Bridge, Denture Details" to be filled out before sending to pre-auth.'
    : canBulkPreAuth
      ? "Send Procedures To Pre-Auth"
      : 'Only "Planned" and "Scheduled" procedures can be sent to pre-auth.',
  print: "Print Selected Procedures",
  kiosk: "Send Selected Procedures to Kiosk",
  sendPatient: "Send Selected Procedures to Patient",
});

// eslint-disable-next-line complexity
export const ProceduresBulkActionsMenu: React.FC<Props & ProceduresBulkActionCallbacks> = ({
  selectedRows,
  procedures,
  treatmentPlans,
  className,
  currentTreatmentPlan,
  isPrinting,
  isSendingToKiosk,
  onAddProceduresToTreatmentPlanClick,
  onCreateTreatmentPlanClick,
  onDeleteProceduresClick,
  onScheduleProceduresClick,
  onSendProceduresToPreAuthClick,
  handleCreateTreatmentPlanFormTask,
  onPrioritizeProceduresClick,
}) => {
  const selectedProcedures = useMemo(() => {
    if (!procedures) {
      return [];
    }

    const selected = [...selectedRows].map((selectedId) => procedures.find((pr) => pr.id === selectedId));

    return selected.filter(isDefined);
  }, [selectedRows, procedures]);
  const {
    canBulkSchedule,
    canPrioritize,
    canBulkPreAuth,
    canBulkDelete,
    hasSelectedProcedures,
    requiresProstheticInfo,
  } = useMemo(() => {
    return {
      hasSelectedProcedures: selectedProcedures.length > 0,
      canBulkSchedule: canScheduleEvery(selectedProcedures),
      canPrioritize: canPrioritizeEvery(selectedProcedures),
      canBulkPreAuth: canPreAuthEvery(selectedProcedures),
      requiresProstheticInfo: selectedProcedures.some(requiresProsthetic),
      canBulkDelete: canDeleteEvery(selectedProcedures),
    };
  }, [selectedProcedures]);

  const tooltips = useMemo(() => {
    return hasSelectedProcedures
      ? getSelectedProceduresTooltips({
          canBulkDelete,
          canBulkPreAuth,
          canBulkSchedule,
          canPrioritize,
          requiresProstheticInfo,
          currentTreatmentPlan,
        })
      : {
          add: noProceduresSelectedMessage,
          prioritize: noProceduresSelectedMessage,
          addTo: noProceduresSelectedMessage,
          schedule: noProceduresSelectedMessage,
          remove: currentTreatmentPlan
            ? currentTreatmentPlan.state === "ACTIVE"
              ? "The active treatment plan cannot be removed"
              : "Remove Treatment Plan"
            : noProceduresSelectedMessage,
          edit: noProceduresSelectedMessage,
          preAuth: noProceduresSelectedMessage,
          kiosk: currentTreatmentPlan ? "Send Treatment Plan to Kiosk" : noProceduresSelectedMessage,
          sendPatient: currentTreatmentPlan ? "Send Treatment Plan to Patient" : noProceduresSelectedMessage,
          print: currentTreatmentPlan ? "Print Treatment Plan" : noProceduresSelectedMessage,
        };
  }, [
    canBulkDelete,
    canBulkSchedule,
    canBulkPreAuth,
    hasSelectedProcedures,
    requiresProstheticInfo,
    canPrioritize,
    currentTreatmentPlan,
  ]);

  const { addProceduresComponents, prioritizeComponents } = useMemo(() => {
    return {
      addProceduresComponents: getAddProceduresMenuComponents(hasSelectedProcedures, tooltips.add),
      prioritizeComponents: getPrioritizeMenuComponents(
        hasSelectedProcedures && canPrioritize,
        tooltips.prioritize
      ),
    };
  }, [hasSelectedProcedures, canPrioritize, tooltips.add, tooltips.prioritize]);

  const addProceduresOptions = useMemo(() => {
    const options = [];

    if (onCreateTreatmentPlanClick) {
      options.push({
        label: "",
        options: [
          {
            value: "new",
            label: "Create New TP",
          },
        ],
      });
    }

    if (treatmentPlans?.length) {
      return [
        ...options,
        {
          label: "Add to Existing TP",
          options: treatmentPlans.map((tp) => ({ value: tp.uuid, label: tp.name })),
        },
      ];
    }

    return options;
  }, [treatmentPlans, onCreateTreatmentPlanClick]);

  return (
    <div className={cx("flex items-center", className)}>
      <div className="flex items-center gap-x-6">
        <div>
          <Select
            isSearchable={false}
            isClearable={false}
            menuIsOpen={hasSelectedProcedures ? undefined : false}
            styles={addProceduresPlanMenuStyles}
            options={addProceduresOptions}
            onItemSelected={(newValue) =>
              newValue === "new" && onCreateTreatmentPlanClick
                ? onCreateTreatmentPlanClick(selectedProcedures)
                : onAddProceduresToTreatmentPlanClick(selectedProcedures, newValue)
            }
            components={addProceduresComponents}
          />
        </div>
        {onScheduleProceduresClick ? (
          <ButtonIcon
            SvgIcon={Calendar}
            tooltip={{
              content: tooltips.schedule,
              theme: "SMALL",
            }}
            onClick={() => onScheduleProceduresClick(selectedProcedures)}
            disabled={!hasSelectedProcedures || !canBulkSchedule}
            theme="primary"
          />
        ) : null}
        {onPrioritizeProceduresClick ? (
          <div>
            <Select
              isSearchable={false}
              isClearable={false}
              menuIsOpen={hasSelectedProcedures && canPrioritize ? undefined : false}
              styles={priorityMenuStyles}
              options={tablePriorityOptions}
              onItemSelected={(newValue) => onPrioritizeProceduresClick(selectedProcedures, newValue)}
              components={prioritizeComponents}
            />
          </div>
        ) : null}
        {onSendProceduresToPreAuthClick ? (
          <ButtonIcon
            SvgIcon={Send}
            tooltip={{ content: tooltips.preAuth, theme: "SMALL" }}
            onClick={() => onSendProceduresToPreAuthClick(selectedProcedures)}
            disabled={!hasSelectedProcedures || !canBulkPreAuth}
            theme="primary"
          />
        ) : null}

        <ButtonIcon
          SvgIcon={Print}
          loading={isPrinting}
          size="md"
          tooltip={{ content: tooltips.print, theme: "SMALL" }}
          onClick={() =>
            handleCreateTreatmentPlanFormTask({ procedures: selectedProcedures, method: "print" })
          }
          disabled={!hasSelectedProcedures && !currentTreatmentPlan}
          theme="primary"
        />
        <ButtonIcon
          SvgIcon={SendIcon}
          size="md"
          tooltip={{ content: tooltips.sendPatient, theme: "SMALL" }}
          onClick={() =>
            handleCreateTreatmentPlanFormTask({ procedures: selectedProcedures, method: "send" })
          }
          disabled={!hasSelectedProcedures && !currentTreatmentPlan}
          theme="primary"
        />
        <ButtonIcon
          SvgIcon={KioskIcon}
          loading={isSendingToKiosk}
          size="md"
          tooltip={{ content: tooltips.kiosk, theme: "SMALL" }}
          onClick={() =>
            handleCreateTreatmentPlanFormTask({ procedures: selectedProcedures, method: "kiosk" })
          }
          disabled={!hasSelectedProcedures && !currentTreatmentPlan}
          theme="primary"
        />
        <ButtonIcon
          SvgIcon={Delete}
          tooltip={{ content: tooltips.remove, theme: "SMALL" }}
          onClick={() => onDeleteProceduresClick(selectedProcedures)}
          disabled={isDeleteDisabled(hasSelectedProcedures, canBulkDelete, currentTreatmentPlan)}
          theme="primary"
        />
      </div>
    </div>
  );
};
