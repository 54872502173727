import { ChallengeName, CognitoUser } from "amazon-cognito-identity-js";
import { CognitoErrorCode } from "@libs/utils/cognito";

export type ChallengeProps = {
  onChallengeComplete: (params: { signedInUser: CognitoUser } | { error: CognitoErrorCode }) => void;
  authUser: CognitoUser;
};

export type SupportedChallenge = Extract<ChallengeName, "SOFTWARE_TOKEN_MFA" | "NEW_PASSWORD_REQUIRED">;
export const isSupportedChallenge = (challenge: unknown): challenge is SupportedChallenge => {
  return challenge === "SOFTWARE_TOKEN_MFA" || challenge === "NEW_PASSWORD_REQUIRED";
};
