import { InfiniteData } from "@tanstack/react-query";
import React from "react";
import { ProductionByAppointmentResponse } from "@libs/api/generated-api";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { getIsLoadingMore } from "@libs/utils/queries";
import { ApiResponse } from "@libs/@types/api";
import { useAccount } from "@libs/contexts/AccountContext";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { getProductionByAppointmentInfiniteQuery } from "api/reporting/queries";
import { getTotals } from "components/Dashboard/Tables/utils";
import { useQueryParams } from "hooks/useQueryParams";
import { dashboardFiltersForTables } from "components/Dashboard/utils/filters";
import { TimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";

export const useProductionByAppointmentQuery = ({
  pageSize,
  dateWindow,
  enabled = true,
}: {
  pageSize: number;
  enabled?: boolean;
  dateWindow: TimeSeriesPageSelections;
}) => {
  const { practiceId } = useAccount();
  const { query } = useQueryParams("dashboardPracticeProduction");
  const { selectedTimeSegmentParams } = dateWindow;

  const { search, isWaiting } = useDebouncedSearch(query.patientSearch ?? "");

  const sortOrders = query.apptTableSort;
  const appointmentInfiniteQuery = useInfiniteApiQuery(
    getProductionByAppointmentInfiniteQuery({
      args: {
        practiceId,
        pageSize,
        pageNumber: 1,
        data: {
          includeTotal: true,
          ...selectedTimeSegmentParams,
          patientSearchString: search,
          filters: dashboardFiltersForTables(query.filters),
          sortOrders,
        },
      },
      queryOptions: {
        enabled,
      },
    })
  );
  const { data, isLoading } = appointmentInfiniteQuery;
  const isLoadingMore = getIsLoadingMore(appointmentInfiniteQuery);

  return {
    appointmentInfiniteQuery,
    isLoadingMore,
    isLoading: isWaiting || isLoading,
    totals: getTotals<ProductionByAppointmentResponse>(data),
  };
};

export const useFlattenAppointmentPages = (
  data:
    | InfiniteData<{
        data: ProductionByAppointmentResponse;
        apiResponse: ApiResponse<ProductionByAppointmentResponse>;
      }>
    | undefined
) => {
  return React.useMemo(() => data?.pages.flatMap((item) => item.data.byPatients), [data]);
};
