import React from "react";
import { ReportingRecallByPatientEntryVO, ReportingRecallByPatientResponse } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getAbsoluteUrl } from "@libs/utils/location";
import { formatCurrency } from "@libs/utils/currency";
import { phoneNumberFormatter } from "@libs/utils/phone";
import { getLocalDate } from "@libs/utils/date";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";
import { handleError } from "utils/handleError";
import { useDownloadQueryPages } from "components/Dashboard/hooks/useDownloadInfiniteQuery";
import { getTotals } from "components/Dashboard/Tables/utils";
import { paths } from "utils/routing/paths";
import { RECALL_BY_PATIENT_HEADERS } from "components/Dashboard/RecallReport/constants";
import { getDaysOverdue } from "components/Dashboard/RecallReport/utils";
import { useCurrentUrl, useOrigin } from "contexts/OriginContext";

const formatAppointmentDataAsCsvUrl = (params: {
  rows: ReportingRecallByPatientEntryVO[];
  origin: string;
}) => {
  const { rows, origin } = params;

  const columnTitles = formatCsvRow([
    ...RECALL_BY_PATIENT_HEADERS.map(({ label }) => label).filter(Boolean),
    "Patient URL",
    "Next Appt URL",
    "Previous Appt URL",
  ]);
  const rowsContent = rows.map((row) => {
    const {
      patient,
      remainingInsuranceBenefitAmount,
      scheduledAppointmentId,
      previousAppointmentDate,
      previousAppointmentId,
      dueAge,
      patientPhone,
      dueDate,
      carrier,
      scheduledDate,
      previousDate,
    } = row;

    return formatCsvRow([
      patient.fullDisplayName,
      patientPhone ? phoneNumberFormatter(patientPhone) : "",
      carrier?.name ?? "",
      remainingInsuranceBenefitAmount ? formatCurrency(remainingInsuranceBenefitAmount) : "",
      scheduledDate ?? "",
      getDaysOverdue(dueAge),
      dueDate,
      previousDate ?? "",
      previousAppointmentDate ?? "",
      getAbsoluteUrl(origin, paths.patient({ patientId: patient.id })),

      scheduledDate
        ? getAbsoluteUrl(
            origin,
            paths.schedule({
              patientId: patient.id,
              appointmentId: scheduledAppointmentId,
              date: scheduledDate,
            })
          )
        : "",
      previousAppointmentDate
        ? getAbsoluteUrl(
            origin,
            paths.schedule({
              patientId: patient.id,
              appointmentId: previousAppointmentId,
              date: previousAppointmentDate,
            })
          )
        : "",
    ]);
  });

  return [columnTitles, ...rowsContent];
};

export const useDownloadRecallByPatientReportCsv = (params: {
  startDate: string;
  endDate?: string;
  recallReportInfiniteQuery: UseInfiniteApiQueryResult<ReportingRecallByPatientResponse>;
}) => {
  const downloading = useBoolean(false);

  const { startDownload } = useDownloadQueryPages(params.recallReportInfiniteQuery);
  const origin = useOrigin();
  const currentUrl = useCurrentUrl();

  return {
    isDownloading: downloading.isOn,
    downloadCSV: React.useCallback(async () => {
      downloading.on();

      try {
        const result = await startDownload();
        const rows = result.pages.flatMap((item) => item.data.entries);
        const totals = getTotals(result);

        if (totals) {
          const docMetadata = getArchyCsvMetadata(currentUrl, {
            startDate: getLocalDate(params.startDate),
            endDate: params.endDate ? getLocalDate(params.endDate) : undefined,
          });
          const csvRows = [
            ...docMetadata.headers,
            ...formatAppointmentDataAsCsvUrl({
              rows,
              origin,
            }),
          ];

          saveCsv(csvRows, `RecallReport_ByPatient_${docMetadata.fileNameTag}`);
        }
      } catch (e) {
        handleError(e);
      } finally {
        downloading.off();
      }
    }, [downloading, startDownload, params.startDate, params.endDate, origin, currentUrl]),
  };
};
