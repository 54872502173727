import {
  CreateProcedureShortcutRequest,
  DentalProcedureVO,
  ProcedureShortcutVO,
  UpdateProcedureShortcutRequest,
} from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { updateCachedData } from "@libs/utils/queryCache";
import { getQueryKey } from "@libs/utils/queries";
import { makeMutation } from "@libs/utils/mutations";

const getShortcutsKey = (args: { practiceId: number; category: ProcedureShortcutVO["category"] }) => [
  getQueryKey("practices", "getProcedureShortcutsByCategory"),
  args,
];

export const createProcedureShortcut = makeMutation({
  mutationKey: ["practices", "createProcedureShortcut"],
  formatParams: (args: {
    tempId: string;
    practiceId: number;
    name: string;
    surfaces?: CreateProcedureShortcutRequest["surfaces"];
    category: ProcedureShortcutVO["category"];
    procedures: DentalProcedureVO[];
    order: number;
  }) => [
    args.practiceId,
    {
      name: args.name,
      surfaces: args.surfaces,
      category: args.category,
      procedureIds: args.procedures.map((proc) => proc.id),
    },
  ],
  mutationOptions: (queryClient) => {
    return {
      onMutate: ({ tempId, category, practiceId, name, surfaces, procedures, order }) => {
        updateCachedData<ProcedureShortcutVO[]>(
          queryClient,
          { queryKey: getShortcutsKey({ practiceId, category }), exact: true },
          (data) => [...data, { uuid: tempId, name, category, procedures, order, surfaces }]
        );
      },
      onSuccess: (response, { tempId, practiceId, category }) => {
        updateCachedData<ProcedureShortcutVO[]>(
          queryClient,
          { queryKey: getShortcutsKey({ practiceId, category }), exact: true },
          (data) =>
            data.map((note) => {
              if (note.uuid === tempId) {
                return response.data.data;
              }

              return note;
            })
        );
      },
      onError: (_error, { tempId, practiceId, category }) => {
        updateCachedData<ProcedureShortcutVO[]>(
          queryClient,
          { queryKey: getShortcutsKey({ practiceId, category }), exact: true },
          (data) => data.filter((note) => note.uuid !== tempId)
        );
      },
    };
  },
});

export const deleteProcedureShortcut = makeMutation({
  mutationKey: ["practices", "deleteProcedureShortcut"],
  formatParams: (args: { practiceId: number; category: ProcedureShortcutVO["category"]; uuid: string }) => [
    args.practiceId,
    args.uuid,
  ],
  mutationOptions: (queryClient) => ({
    onMutate: ({ uuid, practiceId, category }) => {
      updateCachedData<ProcedureShortcutVO[]>(
        queryClient,
        { queryKey: getShortcutsKey({ practiceId, category }), exact: true },
        (data) => data.filter((note) => note.uuid !== uuid)
      );
    },
    onError: (_error, { practiceId, category }) =>
      queryClient.invalidateQueries(getShortcutsKey({ practiceId, category })),
  }),
});

export const updateProcedureShortcut = makeMutation({
  mutationKey: ["practices", "updateProcedureShortcut"],
  formatParams: (args: {
    practiceId: number;
    uuid: string;
    category: ProcedureShortcutVO["category"];
    name: string;
    surfaces?: UpdateProcedureShortcutRequest["surfaces"];
    procedures: DentalProcedureVO[];
  }) => [
    args.practiceId,
    args.uuid,
    { name: args.name, surfaces: args.surfaces, procedureIds: args.procedures.map((proc) => proc.id) },
  ],
  mutationOptions: (queryClient) => ({
    onMutate: ({ uuid, name, procedures, surfaces, practiceId, category }) => {
      updateCachedData<ProcedureShortcutVO[]>(
        queryClient,
        { queryKey: getShortcutsKey({ practiceId, category }), exact: true },
        (data) =>
          data.map((note) => {
            if (note.uuid === uuid) {
              return {
                ...note,
                name,
                surfaces,
                procedures,
              };
            }

            return note;
          })
      );
    },
    onError: (_error, { practiceId, category }) =>
      queryClient.invalidateQueries(getShortcutsKey({ practiceId, category })),
  }),
});

export const reorderProcedureShortcuts = makeMutation({
  mutationKey: ["practices", "reorderProcedureShortcuts"],
  formatParams: (args: {
    practiceId: number;
    category: ProcedureShortcutVO["category"];
    uuids: string[];
  }) => [args.practiceId, { category: args.category, shortcutUuids: args.uuids }],
  mutationOptions: (queryClient) => ({
    onMutate: ({ category, practiceId, uuids }) => {
      updateCachedData<ProcedureShortcutVO[]>(
        queryClient,
        { queryKey: getShortcutsKey({ practiceId, category }), exact: true },
        (data) => uuids.map((uuid) => data.find((shorcut) => shorcut.uuid === uuid)).filter(isDefined)
      );
    },
    onError: (_error, { practiceId, category }) =>
      queryClient.invalidateQueries(getShortcutsKey({ practiceId, category })),
  }),
});
