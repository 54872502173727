import { getDaysInYear, hoursToMilliseconds } from "date-fns";
import { useMemo } from "react";
import { wrapStorage } from "@libs/storage/wrapStorage";
import { getLocalStorage } from "@libs/storage/getStorage";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { StorageNamespaces } from "storage/namespaces";
import { NO_DEVICE_INDEX } from "components/ImageCapturing/TwainContext";
import { ScannerSettings } from "./useScannerSettings";

const HOURS_24 = 24;
const SETTINGS_OPTIONS = {
  version: "v2",
  expires: hoursToMilliseconds(HOURS_24) * getDaysInYear(Date.now()),
};
const KEY = "settings";

const migrateSettingsV1V2 = () => {
  try {
    const storage = getLocalStorage();
    const oldSettings = {
      preferredDeviceIndex: JSON.parse(
        storage.getItem(`${StorageNamespaces.scannerSettings}_preferredDeviceIndex`) || "null"
      ) as {
        value: ScannerSettings["preferredDeviceIndex"];
        version: "v1";
        expires: number;
      },
      colorMode: JSON.parse(storage.getItem(`${StorageNamespaces.scannerSettings}_colorMode`) || "null") as {
        value: ScannerSettings["colorMode"];
        version: "v1";
        expires: number;
      },
      useFeeder: JSON.parse(storage.getItem(`${StorageNamespaces.scannerSettings}_useFeeder`) || "null") as {
        value: ScannerSettings["useFeeder"];
        version: "v1";
        expires: number;
      },
      autoDiscardBlankPages: JSON.parse(
        storage.getItem(`${StorageNamespaces.scannerSettings}_autoDiscardBlankPages`) || "null"
      ) as {
        value: ScannerSettings["autoDiscardBlankPages"];
        version: "v1";
        expires: number;
      },
      useDuplex: JSON.parse(storage.getItem(`${StorageNamespaces.scannerSettings}_useDuplex`) || "null") as {
        value: ScannerSettings["useDuplex"];
        version: "v1";
        expires: number;
      },
    };

    const newSettings: ScannerSettings = {
      preferredDeviceIndex: oldSettings.preferredDeviceIndex.value,
      colorMode: oldSettings.colorMode.value,
      useFeeder: oldSettings.useFeeder.value,
      autoDiscardBlankPages: oldSettings.autoDiscardBlankPages.value,
      useDuplex: oldSettings.useDuplex.value,
    };

    const scannerSettingsStorage = wrapStorage<ScannerSettings, StorageNamespaces>(
      StorageNamespaces.scannerSettings,
      storage
    );

    scannerSettingsStorage.set(KEY, newSettings, SETTINGS_OPTIONS);
    scannerSettingsStorage.clear(`preferredDeviceIndex`);
    scannerSettingsStorage.clear(`colorMode`);
    scannerSettingsStorage.clear(`useFeeder`);
    scannerSettingsStorage.clear(`autoDiscardBlankPages`);
    scannerSettingsStorage.clear(`useDuplex`);
  } catch {
    // If it fails it means that the settings were already migrated or that the
    // user has no settings.
  }
};

export const useScannerSettingsStorage = () => {
  migrateSettingsV1V2();

  const { localStorage } = useStorageContext();

  return useMemo(() => {
    const scannerSettingsStorage = wrapStorage<ScannerSettings, StorageNamespaces>(
      StorageNamespaces.scannerSettings,
      localStorage
    );

    return {
      saveSettingsToLocalStorage: (settings: ScannerSettings) => {
        scannerSettingsStorage.set(KEY, settings, SETTINGS_OPTIONS);
      },
      loadSettingsFromLocalStorage: () => {
        const settings: ScannerSettings = scannerSettingsStorage.ensure(
          KEY,
          {
            preferredDeviceIndex: NO_DEVICE_INDEX,
            colorMode: "GRAY",
            useFeeder: true,
            autoDiscardBlankPages: true,
            useDuplex: true,
          },
          SETTINGS_OPTIONS
        );

        return settings;
      },
    };
  }, [localStorage]);
};
