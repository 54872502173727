import React from "react";
import { useNavigate } from "react-router-dom";
import { AdjustmentCategory, DashboardAdjustmentsQuery } from "utils/routing/dashboard";
import { upsertDashFilter } from "components/Dashboard/utils/filters";
import { PieDatumColored } from "components/Dashboard/Charting/types";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";
import { paths } from "utils/routing/paths";

export const useDonutDatumOnClick = ({
  tableTabSelected,
  onUpdateFilters,
  params,
}: {
  tableTabSelected: AdjustmentCategory;
  params: DashboardAdjustmentsQuery;
  onUpdateFilters: ({ filters }: { filters: DashboardFilter[] }) => void;
}) => {
  const navigate = useNavigate();
  const { filters } = params;

  return React.useCallback(
    (
      datum: PieDatumColored<{
        adjustmentCategory: AdjustmentCategory;
      }>
    ) => {
      if (tableTabSelected !== datum.adjustmentCategory) {
        // Not viewing the right table tab, switch tabs, and apply filter
        const filterType =
          tableTabSelected === "insurance"
            ? ("customAdjustmentTypeId" as const)
            : ("insuranceCarrierId" as const);

        navigate(
          paths.dashboardAdjustments(
            { tableTab: datum.adjustmentCategory },
            {
              ...params,
              filters: [{ type: filterType, values: [datum.key] }] as DashboardFilter[],
            }
          )
        );

        return;
      }

      const filterType =
        tableTabSelected === "insurance"
          ? ("insuranceCarrierId" as const)
          : ("customAdjustmentTypeId" as const);
      const currentFilter: DashboardFilter = filters.find((item) => item.type === filterType) ?? {
        type: filterType,
        values: [],
      };
      const currentValues = currentFilter.values as string[];
      const updatedFilter = {
        ...currentFilter,
        values: currentValues.includes(datum.key)
          ? currentFilter.values.filter((id) => id !== datum.key)
          : [...currentFilter.values, datum.key],
      };

      onUpdateFilters({
        filters: upsertDashFilter(filters, updatedFilter as DashboardFilter),
      });
    },
    [tableTabSelected, filters, onUpdateFilters, navigate, params]
  );
};
