import React, { useId } from "react";
import { OpenSlotVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { ToggleButton } from "components/UI/ToggleButton";

export interface Props {
  openSlots: OpenSlotVO[];
  onSelectProvider: (provider: { hygienistId: number } | { dentistId: number }) => void;
  selectedProviderId: number;
  isFirstRow: boolean;
}

export const SlotProviderSelect: React.FC<Props> = ({
  openSlots,
  onSelectProvider,
  selectedProviderId,
  isFirstRow,
}) => {
  const groupId = useId();

  return (
    <div
      className={cx("grid grid-cols-5 gap-x-1 gap-y-2 w-full pl-5 py-3", isFirstRow ? "pt-9 pb-3" : "py-3")}
    >
      {openSlots.map(({ provider }) => {
        return (
          <div key={provider.id} className="flex">
            <ToggleButton
              name={groupId}
              type="radio"
              className={cx(
                "text-left cursor-pointer text-xs truncate max-w-full flex",
                provider.id === selectedProviderId && "font-sansSemiBold"
              )}
              onClick={() =>
                onSelectProvider(
                  provider.jobCategory === "DENTIST"
                    ? { dentistId: provider.id }
                    : { hygienistId: provider.id }
                )
              }
              baseTextColor={provider.id === selectedProviderId ? "text-primaryTheme" : "text-greyDark"}
            >
              {provider.name.shortDisplayName}
            </ToggleButton>
          </div>
        );
      })}
    </div>
  );
};
