import { useCallback } from "react";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { Row, Cell, cxGridTableStyles } from "@libs/components/UI/GridTableComponents";

interface Props {
  referralName: string;
  onClickDelete: Func;
  onClickEdit: Func;
}

const cxStyles = {
  cell: cx("flex items-center h-10", cxGridTableStyles.cellPadding()),
  menuOption: "flex flex-row gap-x-3",
};

const menuOptions = createMenuOptions(
  {
    SvgIcon: EditIcon,
    label: "Edit",
    value: "edit",
  },
  {
    SvgIcon: DeleteIcon,
    iconTheme: "error",
    label: "Delete",
    value: "delete",
  }
);

export const ReferralsRow: React.FC<Props> = ({ referralName, onClickDelete, onClickEdit }) => {
  const menu = useBoolean(false);

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "edit": {
          onClickEdit();
          menu.off();
          break;
        }
        case "delete": {
          onClickDelete();
          menu.off();
          break;
        }
        default: {
          break;
        }
      }
    },
    [menu, onClickDelete, onClickEdit]
  );

  return (
    <Row aria-label={referralName} key={referralName}>
      <Cell className={cxStyles.cell}>{referralName}</Cell>
      <Cell className={cx("flex justify-center pr-4", cxStyles.cell)}>
        <ButtonMenu
          isOpen={menu.isOn}
          menuContent={
            <div className="w-40">
              <MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />
            </div>
          }
          onRequestClose={menu.off}
          onRequestOpen={menu.on}
          placement="bottom-end"
        >
          {(props) => (
            <ButtonIcon
              {...props}
              aria-label="custom-icon-actions-menu"
              SvgIcon={MenuIcon}
              size="sm"
              theme="primary"
            />
          )}
        </ButtonMenu>
      </Cell>
    </Row>
  );
};
