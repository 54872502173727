import { FC, PropsWithChildren, useMemo, useState } from "react";
import { cx } from "@libs/utils/cx";
import { ClickOutside } from "@libs/contexts/ClickOutsideListenerContext";
import { Form } from "@libs/components/UI/Form";
import { PaletteMap } from "components/Settings/Forms/utils";
import { FormElementRequest } from "components/Settings/Forms/types";
import { ElementSummary } from "components/Settings/Forms/ElementSummary";
import { useIsDirty } from "hooks/useIsDirty";
import { EditElementFormButtons } from "components/Settings/Forms/EditElementFormButtons";

interface Props {
  // keep isEditing here until so we can use it
  // if needed in the future
  isEditing: boolean;
  element: FormElementRequest;
  contentClassName?: string;
  onDraftChange: undefined | ((element: FormElementRequest) => void);
  onSubmit: Func;
  onCancel: Func;
  onValidate: () => boolean | undefined;
  onInvalid: Func;
}

export const FormWrapper: FC<PropsWithChildren<Props>> = ({
  children,
  element,
  contentClassName,
  onValidate,
  onInvalid,
  onDraftChange,
  onCancel,
  onSubmit,
}) => {
  const { isDirty, markClean } = useIsDirty(element, { onDirty: onDraftChange });
  const [invalidSubmission, setInvalidSubmission] = useState(0);
  const handleSubmit = () => {
    if (isDirty) {
      if (onValidate()) {
        onSubmit();
        markClean();
      } else {
        onInvalid();
        setInvalidSubmission((last) => last + 1);
      }
    }
  };

  const { title, description } = useMemo(() => {
    return PaletteMap[element.type];
  }, [element]);

  return (
    <ClickOutside handler={handleSubmit}>
      {(props) => (
        <Form
          key={invalidSubmission}
          fieldLayout="labelIn"
          className={cx("min-h-0 flex-1 flex flex-col", invalidSubmission > 0 && "animate-shake")}
          onSubmit={(e) => {
            e.preventDefault();
            handleSubmit();
          }}
          {...props}
        >
          <div className={cx("min-h-0 flex-1 overflow-y-auto p-6", contentClassName)}>
            <ElementSummary
              title={<span className="font-sansSemiBold">{title}</span>}
              description={description}
              className="pb-6"
            />
            {children}
          </div>
          <EditElementFormButtons isLocked={Boolean(element.tag)} onCancel={onCancel} disabled={!isDirty} />
        </Form>
      )}
    </ClickOutside>
  );
};
