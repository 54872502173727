import { Route } from "react-router-dom";
import { FC } from "react";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { PayrollRoute } from "components/Payroll/PayrollRoute";
import { PayrollEditRoute } from "components/Payroll/PayrollEditRoute";
import { NotFound } from "components/NotFoundRoute";
import { PayrollDetailRoute } from "components/Payroll/PayrollDetailRoute";
import { EmployeesAppHistoryProvider } from "components/Employee/EmployeesLinksContext";

const PayrollRoutes = [
  {
    index: true,
    element: <PayrollRoute />,
  },
  {
    path: ":payrollId/edit",
    element: <PayrollEditRoute />,
  },
  {
    path: ":payrollId/*",
    element: <PayrollDetailRoute />,
  },
];

export const PayrollApp: FC = () => {
  return (
    <EmployeesAppHistoryProvider name="payroll">
      <SentryRoutes>
        {PayrollRoutes.map((routeParams, i) => {
          return <Route {...routeParams} key={i} />;
        })}
        <Route path="*" element={<NotFound />} />
      </SentryRoutes>
    </EmployeesAppHistoryProvider>
  );
};
