import { HeaderData } from "components/Dashboard/Tables/types";

export const PATIENTS_TREATED_COLUMN_DATA: HeaderData[] = [
  { label: "Patient", width: "1fr", sortField: "patientLastName" },
  { label: "Patient Age", width: "8.5rem", sortField: "patientDob" },
  { label: "Insurance Carrier", width: "1fr", sortField: "primaryInsuranceCarrierName" },
  {
    label: "Patient Type",
    width: "1fr",
    sortField: "patientType",
    tooltip: {
      content: (
        <>
          Patients who are either new to the practice or existing patients.
          <p className="py-1">
            <strong>New patients:</strong> A patient who completed their first appointment at the practice
            during the time period selected.
          </p>
          <p className="py-1">
            <strong>Existing patients:</strong> A patient who has previously completed an appointment at the
            practice before the time period selected.
          </p>
        </>
      ),
    },
  },
  {
    label: "Completed Appts",
    width: "1fr",
    tooltip: {
      content: "The total number of appointments completed by the patient during the time period selected.",
    },
  },
];
