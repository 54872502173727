import { FC, useMemo, useState } from "react";
import { CustomAdjustmentTypeVO, InvoiceVO, PaymentVO } from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ModalPage } from "components/UI/ModalPage";
import { paths } from "utils/routing/paths";
import { getMultiInvoicePayment, getPaymentUrl } from "api/billing/queries";
import { PracticeAndPatientAddresses } from "components/PatientProfile/Billing/PracticeAndPatientAddresses";
import { getPatientSummary } from "api/patients/queries";
import { getAdjustmentTypesForPractice } from "api/settings/payments/queries";
import { usePathParams } from "hooks/usePathParams";
import { invoiceEntryOrLineItemToId } from "components/PatientProfile/Billing/invoiceUtils";
import { InvoiceEntry } from "components/PatientProfile/Billing/InvoiceEntry";
import { InvoiceTotalRow } from "components/PatientProfile/Billing/InvoiceEntryComponents";
import { InvoiceNote } from "components/PatientProfile/Billing/InvoiceNote";
import { PrintButton } from "components/UI/PrintButton";
import { InvoicePaymentReceiptSummary } from "components/PatientProfile/Billing/InvoicePaymentReceiptSummary";
import { PaymentHistory } from "components/PatientProfile/Billing/PaymentHistory";
import { useQueryParams } from "hooks/useQueryParams";
import { InvoicePaymentSummary } from "components/PatientProfile/Billing/InvoicePaymentSummary";
import { Text } from "components/PatientProfile/Billing/Ledger/Text";

export const ViewPaymentPage: FC = () => {
  const { patientId, paymentUuid } = usePathParams("viewPayment");
  const {
    query: { from },
  } = useQueryParams("viewPayment");
  const closeLink = from ?? paths.patientBilling({ patientId });
  const practice = useCurrentPractice();
  const [requestPrint, setRequestPrint] = useState(false);

  const [patientQuery, practiceAdjustmentsQuery, multiInvoicePaymentQuery] = useApiQueries([
    getPatientSummary({ args: { patientId, practiceId: practice.id } }),
    getAdjustmentTypesForPractice({ args: { practiceId: practice.id } }),
    getMultiInvoicePayment({
      args: { patientId, practiceId: practice.id, multiInvoicePaymentUuid: paymentUuid },
    }),
  ]);

  const [paymentUrlQuery] = useApiQueries([
    getPaymentUrl({
      args: { patientId, practiceId: practice.id, paymentUuid },
      queryOptions: {
        enabled: requestPrint,
        onSuccess: (data) => window.open(data.data.data, "_target"),
      },
    }),
  ]);

  return (
    <ModalPage
      title={<span className="font-sansSemiBold">Receipt</span>}
      closeLink={closeLink}
      className="flex-1"
      actions={
        <div className="flex gap-x-3 justify-center">
          <ButtonInternalLink className="min-w-button" to={closeLink}>
            Done
          </ButtonInternalLink>
        </div>
      }
    >
      <div className="relative h-full w-full">
        <QueryResult
          queries={[patientQuery, practiceAdjustmentsQuery, multiInvoicePaymentQuery]}
          nonCriticalQueries={[paymentUrlQuery]}
          loading={<LoadingOverlaySpinner />}
        >
          {multiInvoicePaymentQuery.data && practiceAdjustmentsQuery.data && (
            <div className="p-5 flex flex-col gap-y-5">
              <div className="flex justify-end">
                <PrintButton
                  className="text-primaryTheme text-sm font-sansSemiBold"
                  printUrl={paymentUrlQuery.data}
                  theme="link"
                  isLoadingPrintUrl={paymentUrlQuery.isInitialLoading}
                  onClick={() => setRequestPrint(true)}
                />
              </div>
              {patientQuery.data && (
                <PracticeAndPatientAddresses practiceData={practice} patientData={patientQuery.data} />
              )}
              <InvoicePaymentReceiptSummary payment={multiInvoicePaymentQuery.data.payment} />
              <MultiInvoices
                payment={multiInvoicePaymentQuery.data.payment}
                invoices={multiInvoicePaymentQuery.data.invoices}
                practiceAdjustments={practiceAdjustmentsQuery.data}
              />
            </div>
          )}
        </QueryResult>
      </div>
    </ModalPage>
  );
};

const MultiInvoices: FC<{
  payment: PaymentVO;
  invoices: InvoiceVO[];
  practiceAdjustments: CustomAdjustmentTypeVO[];
}> = ({ payment, invoices, practiceAdjustments }) => {
  return (
    <>
      {invoices.map((invoice) => (
        <Invoice
          key={invoice.uuid}
          payment={payment}
          invoice={invoice}
          practiceAdjustments={practiceAdjustments}
        />
      ))}
    </>
  );
};

const Invoice: FC<{
  payment: PaymentVO;
  invoice: InvoiceVO;
  practiceAdjustments: CustomAdjustmentTypeVO[];
}> = ({ payment, invoice, practiceAdjustments }) => {
  const paymentsToShow = useMemo(
    () =>
      invoice.payments.filter(
        (invoicePayment) =>
          invoicePayment.paymentCreatedAt <= payment.paymentCreatedAt &&
          isOneOf(invoicePayment.state, ["SETTLED"])
      ),
    [invoice.payments, payment.paymentCreatedAt]
  );

  return (
    <div className="flex flex-col gap-y-4">
      <div className="flex flex-col gap-y-2">
        <Text bold size="md">
          Invoice #{invoice.invoiceNumber}
          {invoice.payerName ? ` – ${invoice.payerName}` : null}
        </Text>
        {invoice.entries.map((entry) => (
          <div key={invoiceEntryOrLineItemToId(entry)} className="flex flex-col gap-y-2">
            <InvoiceEntry entry={entry} practiceAdjustments={practiceAdjustments} />
          </div>
        ))}
        <div className="flex flex-col gap-y-2">
          <InvoiceTotalRow invoiceTotal={invoice.amount} />
          <InvoicePaymentSummary invoice={invoice} />
        </div>
      </div>
      <div className="flex gap-x-12">
        {paymentsToShow.length > 0 && (
          <div className="flex-1">
            <PaymentHistory invoiceAmount={invoice.amount} payments={paymentsToShow} />
          </div>
        )}
        <div className="flex-1">
          <InvoiceNote invoice={invoice} />
        </div>
      </div>
    </div>
  );
};
