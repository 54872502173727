import React, { useCallback } from "react";
import { IPFilterVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/minus.svg";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus-circle.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { Cell, HeaderCell, Row, TableGrid } from "@libs/components/UI/GridTableComponents";
import { getIpFilters } from "api/settings/practice/queries";
import { deleteIpFilter } from "api/settings/practice/mutations";
import { handleError } from "utils/handleError";
import { getInfiniteEmployees } from "api/employee/queries";
import { useItemModal } from "hooks/useItemModal";
import { IpFilterModal } from "components/Settings/Security/IPAuthorization/IpFilterModal";
import { BannerMessaging } from "components/Settings/Security/IPAuthorization/BannerMessaging";

interface Props {
  isEditing: boolean;
}

const headers: Record<string, { id: string; label: string; width: string }[]> = {
  default: [
    { id: "allowedIp", label: "Allowed IP", width: "300px" },
    { id: "description", label: "Description", width: "350px" },
    { id: "numOfEmployees", label: "Employees Assigned", width: "200px" },
  ],
  editMode: [
    { id: "allowedIp", label: "Allowed IP", width: "300px" },
    { id: "description", label: "Description", width: "350px" },
    { id: "numOfEmployees", label: "Employees Assigned", width: "200px" },
    { id: "actionsColumn", label: "", width: "100px" },
  ],
};

const cxStyles = {
  cell: "py-4 px-3 first:pl-5 last:py-2 last:pr-5",
};

// IpFilter in a draft state is passed into the modal to add or edit
// and new IpFilters will not have a uuid
export interface DraftIpFilter extends Omit<IPFilterVO, "uuid"> {
  uuid?: string;
}

export type DraftIpFilterKey = keyof DraftIpFilter;

export const EMPTY_IP_FILTER: DraftIpFilter = {
  address: "",
  appliesToAll: false,
  description: "",
  userIds: [],
};

export const IPAuthSettings: React.FC<Props> = ({ isEditing }) => {
  const { practiceId } = useAccount();

  const [ipFiltersQuery] = useApiQueries([getIpFilters({ args: { practiceId } })]);

  const employeesQuery = useInfiniteApiQuery(
    getInfiniteEmployees({
      args: {
        orderBy: "ASCENDING",
        pageSize: PAGE_SIZE,
        pageNumber: 1,
        practiceId,
        sortColumn: "lastName",
        statuses: ["ACTIVE", "INACTIVE", "PENDING", "FURLOUGHED"],
      },
    })
  );

  const totalEmployees = getInfiniteQueryPagingDetails(employeesQuery.data)?.totalElements;

  const ipFilterModal = useItemModal<{ ipFilter: DraftIpFilter }>(null);

  const handleEditIpFilter = useCallback(
    (ipFilter: DraftIpFilter) => {
      ipFilterModal.open({ ipFilter });
    },
    [ipFilterModal]
  );

  const [deleteIpFilterMutation] = useApiMutations([deleteIpFilter]);

  const handleDeleteIpFilter = useCallback(
    async (uuid: string) => {
      try {
        await deleteIpFilterMutation.mutateAsync({
          ipFilterUuid: uuid,
          practiceId,
        });
      } catch (err) {
        handleError(err);
      }
    },
    [deleteIpFilterMutation, practiceId]
  );

  const handleAddIpFilterClick = useCallback(() => {
    let filter = EMPTY_IP_FILTER;

    if (!ipFiltersQuery.data?.length) {
      filter = { ...EMPTY_IP_FILTER, appliesToAll: true };
    }

    ipFilterModal.open({ ipFilter: filter });
  }, [ipFilterModal, ipFiltersQuery]);

  return (
    <div className="flex flex-col">
      {ipFiltersQuery.data ? (
        <div className="text-sm">
          <BannerMessaging ipFilters={ipFiltersQuery.data} />
        </div>
      ) : null}
      {ipFiltersQuery.data?.length ? (
        <TableGrid columnWidths={headers[isEditing ? "editMode" : "default"].map(({ width }) => width)}>
          {headers[isEditing ? "editMode" : "default"].map((item) => (
            <HeaderCell key={item.id}>{item.label}</HeaderCell>
          ))}
          {ipFiltersQuery.data.map((ipFilter) => (
            <Row key={ipFilter.uuid}>
              <Cell className={cxStyles.cell}>{ipFilter.address}</Cell>
              <Cell className={cxStyles.cell}>{ipFilter.description}</Cell>
              <Cell className={cxStyles.cell}>
                {ipFilter.appliesToAll ? totalEmployees : ipFilter.userIds.length}
              </Cell>
              <Cell className={cx(isEditing ? cx("flex justify-end", cxStyles.cell) : "hidden")}>
                <ButtonIcon
                  className="mr-3"
                  SvgIcon={EditIcon}
                  onClick={() => handleEditIpFilter(ipFilter)}
                  theme="primary"
                  tooltip={{ content: "Edit", theme: "SMALL" }}
                />
                <ButtonIcon
                  SvgIcon={DeleteIcon}
                  onClick={() => {
                    if (ipFilter.uuid) {
                      handleDeleteIpFilter(ipFilter.uuid);
                    }
                  }}
                  theme="primary"
                  tooltip={{ content: "Delete", theme: "SMALL" }}
                />
              </Cell>
            </Row>
          ))}
        </TableGrid>
      ) : null}
      {isEditing && (
        <div>
          <button
            className={`
              flex
              flex-row
              items-center
              mt-4
              mb-6
              gap-x-2.5
              text-primaryTheme
            `}
            onClick={handleAddIpFilterClick}
            type="button"
          >
            <PlusIcon className="h-4 w-4" />
            <span className="text-sm font-sansSemiBold">Add Authorized IP Address</span>
          </button>
        </div>
      )}
      {ipFilterModal.isOpen && (
        <IpFilterModal
          ipFilter={ipFilterModal.item.ipFilter}
          onClose={ipFilterModal.close}
          ipFilters={ipFiltersQuery.data}
        />
      )}
    </div>
  );
};
