import { useCallback, useMemo, useState } from "react";
import { SelfBookingConfigVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isDefined } from "@libs/utils/types";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { Button } from "@libs/components/UI/Button";
import { TableGrid, HeaderCell, Row } from "@libs/components/UI/GridTableComponents";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { InsuranceListFlyover } from "components/Settings/Scheduling/Sections/OnlineBooking/InsuranceCarrierFlyover";
import { ShortListRow } from "components/Settings/Scheduling/Sections/OnlineBooking/ShortListRow";
import { SelfBookingConfigRequestSaveData } from "components/Settings/Scheduling/Sections/OnlineBooking/OnlineBooking";

interface Props {
  isSaving: boolean;
  onUpdateConfig: (updatedState: SelfBookingConfigRequestSaveData, onSuccess?: Func) => void;
  selfBookingConfig: SelfBookingConfigVO;
}

const headers = [
  { id: "carrierName", label: "Carrier Name", width: "1fr" },
  { id: "inNetwork", label: "Display as In-Network", width: "1fr" },
  { id: "actions", label: "", width: "min-content" },
];

export const ShortListTable: React.FC<Props> = ({ isSaving, onUpdateConfig, selfBookingConfig }) => {
  const [selectedIndex, setSelectedIndex] = useState<number | null>(null);
  const flyover = useBoolean(false);
  const deleteModal = useBoolean(false);

  const alphabetizedInsuranceCarriers = useMemo(() => {
    return (selfBookingConfig.patientFacingCarrierNames ?? []).sort((a, b) => a.name.localeCompare(b.name));
  }, [selfBookingConfig.patientFacingCarrierNames]);

  const handleEdit = useCallback(
    (index: number) => {
      setSelectedIndex(index);
      flyover.on();
    },
    [flyover]
  );

  const handleClickDelete = useCallback(
    (index: number) => {
      deleteModal.on();
      setSelectedIndex(index);
    },
    [deleteModal]
  );

  const handleDelete = useCallback(() => {
    const updatedState = {
      patientFacingCarrierNames: selfBookingConfig.patientFacingCarrierNames?.filter(
        (_, index) => index !== selectedIndex
      ),
    };

    onUpdateConfig(updatedState, () => {
      deleteModal.off();
      setSelectedIndex(null);
    });
  }, [deleteModal, onUpdateConfig, selectedIndex, selfBookingConfig.patientFacingCarrierNames]);

  const handleAddInsurance = useCallback(() => {
    setSelectedIndex(selfBookingConfig.patientFacingCarrierNames?.length || 0);
    flyover.on();
  }, [flyover, selfBookingConfig.patientFacingCarrierNames?.length]);

  const handleCloseFlyover = useCallback(() => {
    flyover.off();
    setSelectedIndex(null);
  }, [flyover]);

  return (
    <div className="flex flex-col max-w-xl gap-y-6 w-full text-sm">
      <TableGrid
        className="rounded border border-greyLighter"
        columnWidths={headers.map(({ width }) => width)}
      >
        <Row>
          {headers.map((item) => (
            <HeaderCell key={item.id} rounded={true} size="short" sticky={false}>
              {item.label}
            </HeaderCell>
          ))}
        </Row>
        {[...alphabetizedInsuranceCarriers].map((carrierName, index) => (
          <ShortListRow
            carrierName={carrierName}
            key={carrierName.name}
            onClickDelete={() => handleClickDelete(index)}
            onClickEdit={() => handleEdit(index)}
          />
        ))}
        <Row>
          <div className="col-span-full flex items-center h-10 px-3 text-xs border-b-0">
            <Button
              className={`
                flex
                items-center
                p-2
                gap-x-2
                rounded
                hover:bg-whiteLight
                disabled:text-greyLight
                disabled:bg-white
              `}
              onClick={handleAddInsurance}
              theme="link"
            >
              <AddIcon className="w-4 h-4" />
              <span className="font-sans">Insurance Carrier</span>
            </Button>
          </div>
        </Row>
      </TableGrid>
      {flyover.isOn && isDefined(selectedIndex) && (
        <InsuranceListFlyover
          isEditing={selectedIndex < alphabetizedInsuranceCarriers.length}
          isSaving={isSaving}
          onClose={handleCloseFlyover}
          onUpdateConfig={onUpdateConfig}
          patientFacingCarrierNames={selfBookingConfig.patientFacingCarrierNames ?? []}
          selectedIndex={selectedIndex}
        />
      )}
      {deleteModal.isOn && (
        <ConfirmationModal
          isConfirming={isSaving}
          onCancel={deleteModal.off}
          onConfirm={handleDelete}
          primaryText="Delete Insurance Carrier"
          secondaryText="Are you sure you want to delete this insurance carrier?"
          size="2xs"
        />
      )}
    </div>
  );
};
