import React from "react";

import { MedicalImageVO, MountVO } from "@libs/api/generated-api";

import { useMenu } from "@libs/components/UI/Menu";
import { UseMountUpdater } from "components/PatientProfile/Imaging/MountRoute/hooks/useMountUpdater";

export const useImageArchiver = ({
  mount,
  handleMountChanged,
}: {
  mount?: MountVO;
  handleMountChanged: UseMountUpdater["handleMountChanged"];
}) => {
  const archiveModal = useMenu(false);
  const imageToArchive = React.useRef<MedicalImageVO | null>(null);

  const onConfirmArchive = React.useCallback(() => {
    const images = mount?.images;
    const image = imageToArchive.current;

    imageToArchive.current = null;

    if (!images || !image) {
      return;
    }

    const willArchive = !image.isArchived;

    handleMountChanged({
      updates: {
        images: images.map((item) => {
          if (item.id === image.id) {
            return {
              ...item,
              isArchived: willArchive,
            };
          }

          return item;
        }),
      },
    });
  }, [mount?.images, handleMountChanged]);
  const handleToggleImageArchived = React.useCallback(
    (image: MedicalImageVO) => {
      const willArchive = !image.isArchived;

      imageToArchive.current = image;

      if (willArchive) {
        archiveModal.open();
      } else {
        onConfirmArchive();
      }
    },
    [archiveModal, onConfirmArchive]
  );

  return { handleToggleImageArchived, onConfirmArchive, archiveModal };
};
