import { FC, useMemo, useState } from "react";
import { UpdatePatientRequest, ProviderVO, PatientVO, OnboardingOptionsVO } from "@libs/api/generated-api";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { PatientInfoCard } from "components/PatientProfile/PatientOverview/Info/PatientInfoCard";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";
import { ProviderInputSelect } from "components/UI/ProviderInputSelect";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";
import { OnChangePatientHandler } from "components/PatientProfile/PatientOverview/types";
import { PREFERRED_LANGUAGE_OPTIONS } from "components/Communications/Filters/utils";
import { useNow } from "hooks/useNow";

export const PatientAdditionalInformation: FC<{
  isEditing: boolean;
  onChange: OnChangePatientHandler;
  onboarding?: OnboardingOptionsVO;
  patient: UpdatePatientRequest | PatientVO;
  providers?: ProviderVO[];
  // eslint-disable-next-line complexity
}> = ({ isEditing, onChange, patient, providers, onboarding }) => {
  const today = useNow();
  const [preferredLanguage, setPreferredLanguage] = useState(
    patient.additionalInformation?.preferredLanguage
  );

  const onboardingOptions = useMemo(() => {
    return onboarding?.referralOptions.map((option) => ({ label: option, value: option })) ?? [];
  }, [onboarding]);

  return (
    <PatientInfoCard title="Additional Information">
      <FormFieldSelect
        display="label"
        label="Preferred Language"
        value={preferredLanguage}
        edit={isEditing}
        menuPlacement="top"
        onChange={(option) => {
          setPreferredLanguage(option?.value);
          onChange((draft) => (draft.additionalInformation.preferredLanguage = option?.value));
        }}
        options={PREFERRED_LANGUAGE_OPTIONS}
      />
      <FormFieldInput
        label="Emergency Contact Name"
        value={patient.additionalInformation?.emergencyContact?.name}
        onChange={(event) =>
          onChange(
            (draft) =>
              (draft.additionalInformation.emergencyContact = {
                ...draft.additionalInformation.emergencyContact,
                name: event.target.value,
              })
          )
        }
        edit={isEditing}
      />
      <FormFieldPhoneInput
        label="Emergency Contact Number"
        value={patient.additionalInformation?.emergencyContact?.phone}
        edit={isEditing}
        onValueChange={(phone) =>
          onChange(
            (draft) =>
              (draft.additionalInformation.emergencyContact = {
                ...draft.additionalInformation.emergencyContact,
                phone,
              })
          )
        }
      />
      <ProviderInputSelect
        label="Primary Provider"
        menuPlacement="top"
        value={patient.additionalInformation?.primaryProvider}
        edit={isEditing}
        onChange={(option) => {
          onChange((draft) => (draft.additionalInformation.primaryProvider = option?.name));
        }}
        providers={(providers || []).filter((provider) => provider.jobCategory === "DENTIST")}
      />
      <ProviderInputSelect
        label="Primary Hygienist"
        menuPlacement="top"
        value={patient.additionalInformation?.primaryHygienist}
        edit={isEditing}
        onChange={(option) => {
          onChange((draft) => (draft.additionalInformation.primaryHygienist = option?.name));
        }}
        providers={(providers || []).filter((provider) => provider.jobCategory === "HYGIENIST")}
      />
      <FormFieldInput
        label="Employer"
        value={patient.personalDetails.employer}
        edit={isEditing}
        onChange={(event) => onChange((draft) => (draft.personalDetails.employer = event.target.value))}
      />
      <FormFieldNumberInput
        label="Completed Visits"
        value={patient.personalDetails.completedVisits}
        edit={isEditing}
        min={0}
        clamp
        onValueChange={(value) => onChange((draft) => (draft.personalDetails.completedVisits = value))}
      />
      <FormFieldSelectMenusDatepicker
        label="Registered Date"
        edit={isEditing}
        maxDate={today}
        selected={
          patient.personalDetails.registeredDate
            ? getLocalDate(patient.personalDetails.registeredDate)
            : undefined
        }
        onChange={(date) => {
          onChange((draft) => (draft.personalDetails.registeredDate = date ? formatAsISODate(date) : ""));
        }}
      />
      <FormFieldSelectMenusDatepicker
        label="First Visit"
        edit={isEditing}
        maxDate={today}
        selected={
          patient.personalDetails.firstVisit ? getLocalDate(patient.personalDetails.firstVisit) : undefined
        }
        onChange={(date) => {
          onChange((draft) => (draft.personalDetails.firstVisit = date ? formatAsISODate(date) : ""));
        }}
      />
      <FloatingTooltip content={isEditing && !onboardingOptions.length ? "No referrals configured." : ""}>
        <div>
          <FormFieldSelect
            disabled={isEditing && !onboardingOptions.length}
            display="label"
            edit={isEditing}
            label="Referred By"
            menuPlacement="top"
            onItemSelected={(value) => {
              onChange((draft) => (draft.additionalInformation.referredBy = value));
            }}
            options={
              isEditing && onboardingOptions.length > 0
                ? onboardingOptions
                : patient.additionalInformation?.referredBy
                  ? [
                      {
                        label: patient.additionalInformation.referredBy,
                        value: patient.additionalInformation.referredBy,
                      },
                    ]
                  : []
            }
            value={patient.additionalInformation?.referredBy ?? ""}
          />
        </div>
      </FloatingTooltip>
      <FormFieldInput
        edit={isEditing}
        label="Referral Information"
        onChange={(event) =>
          onChange((draft) => (draft.additionalInformation.referralInformation = event.target.value))
        }
        value={patient.additionalInformation?.referralInformation}
      />
      {!isEditing && patient.personalDetails.previousPmsId && (
        <FormFieldInput label="Previous PMS ID" value={patient.personalDetails.previousPmsId} edit={false} />
      )}
    </PatientInfoCard>
  );
};
