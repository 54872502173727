import React, { FC } from "react";
import { ParentSize } from "@visx/responsive";
import { TimeSeriesResponse } from "@libs/api/generated-api";
import { CHART_COLORS, ColorScheme } from "components/Dashboard/Charting/theme";
import { useTimeseriesTemplateForResolution } from "components/Dashboard/hooks/useTimeseriesTemplateForResolution";
import { PaymentCollectedDataPointKey } from "components/Dashboard/PaymentCollected/types";
import { ChartContainer } from "components/Dashboard/Charting/ChartContainer";
import {
  DashboardChartDisplay,
  DashboardQueryWithDateNavigation,
  TimeSeriesResolutionOption,
} from "utils/routing/dashboard";
import { labelForPaymentCollectedKey } from "components/Dashboard/PaymentCollected/utils";
import { useBarStackDecorators } from "components/Dashboard/hooks/useBarStackDecorators";
import { useTimeseriesAsStacks } from "components/Dashboard/Charting/hooks/useTimeseriesAsStacks";
import { TimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { DashboardTimeseriesChart } from "components/Dashboard/Charting/DashboardTimeseriesChart";
import { useFocusDateClick } from "components/Dashboard/hooks/useFocusDateClick";

const useTimeseriesPaymentData = (
  resolution: TimeSeriesResolutionOption,
  isLoading: boolean,
  keys: PaymentCollectedDataPointKey[],
  dateWindow: TimeSeriesPageSelections,
  payerData?: TimeSeriesResponse
) => {
  //We store last data, to be able to represent the last state while loading new one
  const timeline = useTimeseriesTemplateForResolution({ resolution, dateWindow });

  const data = useTimeseriesAsStacks(timeline, keys, isLoading ? undefined : payerData);

  return {
    data,
  };
};

type Props = {
  width: number;
  height: number;
  dateWindow: TimeSeriesPageSelections;
  resolution: TimeSeriesResolutionOption;
  payerData?: TimeSeriesResponse;
  isLoading: boolean;
  focusDate?: Date;
  chartDisplay: DashboardChartDisplay;
  onUpdateParams: (update: Partial<DashboardQueryWithDateNavigation>) => void;
};

const paymentCollectedKeys: PaymentCollectedDataPointKey[] = ["PATIENT", "INSURANCE_CARRIER"];
const colorScheme: ColorScheme<PaymentCollectedDataPointKey> = {
  domain: paymentCollectedKeys,
  range: [CHART_COLORS.blue, CHART_COLORS.blueBright],
};

export const PaymentCollectedChartContent: FC<Props> = React.memo(
  ({
    width,
    height,
    resolution,
    payerData,
    isLoading,
    focusDate,
    dateWindow,
    onUpdateParams,
    chartDisplay,
  }) => {
    const { data } = useTimeseriesPaymentData(
      resolution,
      isLoading,
      paymentCollectedKeys,
      dateWindow,
      payerData
    );

    const barDecorators = useBarStackDecorators(data, focusDate, resolution);
    const handleDateClicked = useFocusDateClick({ handleParamsChange: onUpdateParams, focusDate });

    return (
      <DashboardTimeseriesChart
        data={data}
        resolution={resolution}
        randomizeLoadingBars
        chartDisplay={chartDisplay}
        width={width}
        timeSegment={dateWindow}
        height={height}
        isLoading={isLoading}
        colorScheme={colorScheme}
        keys={paymentCollectedKeys}
        onClickDate={handleDateClicked}
        isCurrency
        labelFormat={labelForPaymentCollectedKey}
        {...barDecorators}
      />
    );
  }
);
PaymentCollectedChartContent.displayName = "PaymentCollectedChartContent";

export const PaymentCollectedChart: FC<Omit<Props, "width" | "height">> = ({ dateWindow, ...rest }) => {
  return (
    <ChartContainer disabledMessage={dateWindow.invalidTimeSeriesParamsMessage}>
      <ParentSize className="flex-1 relative">
        {({ height, width }: { width: number; height: number }) => {
          return (
            <PaymentCollectedChartContent width={width} height={height} dateWindow={dateWindow} {...rest} />
          );
        }}
      </ParentSize>
    </ChartContainer>
  );
};
