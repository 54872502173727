import { FC, FormEvent, useCallback, useId, useState } from "react";
import { produce, Draft } from "immer";
import { FormSubmissionVO, FormVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { Button } from "@libs/components/UI/Button";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Form } from "@libs/components/UI/Form";
import { paths } from "utils/routing/paths";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";
import { getClinicalNoteFormPreview } from "api/notes/queries";
import { getFormPreview } from "api/forms/queries";
import { assignDefaultEditableTextResponses } from "components/Notes/utils";
import { ModalPage } from "components/UI/ModalPage";
import { FormStructure } from "components/PatientProfile/Forms/FormStructure";

const Preview = ({
  form,
  formElementId,
  practiceId,
  isViewingNote,
  onViewNote,
}: {
  form: FormVO;
  formElementId: string;
  practiceId: number;
  isViewingNote: boolean;
  onViewNote: Func;
}) => {
  const [responses, setResponses] = useState<FormSubmissionVO["responses"]>(() =>
    assignDefaultEditableTextResponses(form, undefined)
  );
  const handleUpdate = useCallback((updater: (draft: Draft<FormSubmissionVO["responses"]>) => void) => {
    setResponses((previousDraft) => produce(previousDraft, (editable) => void updater(editable)));
  }, []);

  // nesting a query as it's never needed until subsequent actions
  const [notePreview] = useApiQueries([
    getClinicalNoteFormPreview({
      args: { practiceId, formUuid: form.uuid, data: { responses } },
      queryOptions: {
        enabled: isViewingNote,
      },
    }),
  ]);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onViewNote();
  };

  return (
    <QueryResult queries={[notePreview]}>
      <div className="max-w-3xl mx-auto px-6 py-12">
        {isViewingNote ? (
          notePreview.data ? (
            <p className="whitespace-pre-wrap text-xs">{notePreview.data.text || null}</p>
          ) : null
        ) : (
          <Form id={formElementId} onSubmit={handleSubmit}>
            <FormStructure onUpdate={handleUpdate} form={form} responses={responses} />
          </Form>
        )}
      </div>
    </QueryResult>
  );
};

export const ClinicalNoteFormPreviewRoute: FC = () => {
  const practice = useCurrentPractice();
  const { query } = useQueryParams("clinicalNoteFormPreview");
  const { formId } = usePathParams("clinicalNoteFormPreview");
  const from = query.from ?? paths.editClinicalNotesForms({ formId });
  const isViewingNote = useBoolean(false);
  const [formQuery] = useApiQueries([
    getFormPreview({
      args: { practiceUuid: practice.uuid, formUuid: formId },
    }),
  ]);

  const formElementId = useId();

  return (
    <QueryResult queries={[formQuery]}>
      {formQuery.data ? (
        <ModalPage
          title={<span className="font-sansSemiBold">{formQuery.data.title} Preview</span>}
          closeLink={from}
          className="flex-1"
          actions={
            <div className="flex gap-x-3 justify-center">
              <ButtonInternalLink className="min-w-button" theme="secondary" to={from}>
                Back to Editor
              </ButtonInternalLink>
              {isViewingNote.isOn ? (
                <Button key="form" className="min-w-button" theme="primary" onClick={isViewingNote.off}>
                  View Form
                </Button>
              ) : (
                <Button
                  key="note"
                  form={formElementId}
                  type="submit"
                  className="min-w-button"
                  theme="primary"
                >
                  View Note
                </Button>
              )}
            </div>
          }
        >
          <Preview
            formElementId={formElementId}
            form={formQuery.data}
            isViewingNote={isViewingNote.isOn}
            practiceId={practice.id}
            onViewNote={isViewingNote.on}
          />
        </ModalPage>
      ) : null}
    </QueryResult>
  );
};
