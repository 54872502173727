/* eslint-disable complexity */

import { CollectionAdjustmentResponse } from "@libs/api/generated-api";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { useAccount } from "@libs/contexts/AccountContext";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { getCollectionAdjustmentStatsInfiniteQuery } from "api/reporting/queries";
import { getTotals, MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";
import { DashboardAdjustmentsQuery } from "utils/routing/dashboard";
import { useFlattenEntries } from "components/Dashboard/hooks/useFlattenEntries";

export const useCollectionAdjustmentsQuery = ({
  params,
  enabled = true,
}: {
  params: DashboardAdjustmentsQuery;
  enabled?: boolean;
}) => {
  const { practiceId } = useAccount();
  const { search, isWaiting } = useDebouncedSearch(params.patientSearch ?? "");
  const { selectedTimeSegmentParams } = useTimeSeriesPageSelections(params);
  const collectionAdjustmentInfiniteQuery = useInfiniteApiQuery(
    getCollectionAdjustmentStatsInfiniteQuery({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: MAX_PAGE_SIZE,
        data: {
          filters: params.filters,
          includeTotal: true,
          ...selectedTimeSegmentParams,
          patientSearchString: search,
          sortOrders: params.collectionTableSort,
        },
      },
      queryOptions: {
        enabled,
      },
    })
  );
  const isLoading =
    isWaiting ||
    collectionAdjustmentInfiniteQuery.isLoading ||
    collectionAdjustmentInfiniteQuery.isInitialLoading;

  const data = useFlattenEntries(collectionAdjustmentInfiniteQuery.data);

  return {
    collectionAdjustmentInfiniteQuery,
    isLoading,
    data,
    totals: collectionAdjustmentInfiniteQuery.data
      ? getTotals<CollectionAdjustmentResponse>(collectionAdjustmentInfiniteQuery.data)
      : undefined,
    totalRows: getInfiniteQueryPagingDetails(collectionAdjustmentInfiniteQuery.data)?.totalElements,
    isEmpty: !isLoading && !collectionAdjustmentInfiniteQuery.error && data.length === 0,
  };
};
