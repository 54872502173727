import { ClaimEobFileVO } from "@libs/api/generated-api";
import { Cell, TableGrid } from "@libs/components/UI/GridTableComponents";
import { EobRow } from "components/Claim/EobRow";
import { ClaimSection } from "components/Claim/ClaimSection";

interface Props {
  canDownload: boolean;
  emptyListText?: string;
  files: ClaimEobFileVO[];
  title: string;
}

const headers = [
  { id: "fileName", label: "Name", width: "500px" },
  { id: "dateUploaded", label: "Date Uploaded", width: "100px" },
  { id: "icons", width: "1fr" },
];

export const EobsSection: React.FC<Props> = ({ canDownload, emptyListText, files, title }) => {
  return (
    <ClaimSection title={title}>
      {files.length ? (
        <TableGrid columnWidths={headers.map(({ width }) => width)}>
          {headers.map((item) => (
            <Cell className="py-1 text-greyDark font-sansSemiBold" key={item.id}>
              {item.label}
            </Cell>
          ))}
          {files.map((file) => (
            <EobRow canDownload={canDownload} file={file} key={file.uuid} />
          ))}
        </TableGrid>
      ) : (
        <div className="text-xs">{emptyListText ?? "None"}</div>
      )}
    </ClaimSection>
  );
};
