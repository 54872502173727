import { FC, useState, useCallback } from "react";
import { Draggable } from "@mobiscroll/react";
import { AppointmentVO } from "@libs/api/generated-api";
import { formatFullNameToInitials } from "@libs/utils/formatString";
import { getDurationLabel } from "components/ScheduleAppointments/utils";
import { createHoldEvent } from "components/ScheduleAppointments/createEvent";
import { HolderCard } from "./AppointmentHolder";

interface Props {
  appointment: AppointmentVO;
  onClick: Func;
  onDoubleClick: Func;
  hipaaView: boolean;
  isSelected: boolean;
  onRequestDelete: Func;
}

export const DraggableHolderCard: FC<Props> = ({
  isSelected,
  appointment,
  hipaaView,
  onDoubleClick,
  onClick,
  onRequestDelete,
}) => {
  const [draggable, setDraggable] = useState<HTMLElement | null>(null);
  const { shortDisplayName } = appointment.patient;

  const setDrag = useCallback((event: HTMLElement | null) => {
    setDraggable(event);
  }, []);

  const name = hipaaView ? formatFullNameToInitials({ fullName: shortDisplayName }) : shortDisplayName;

  const duration = getDurationLabel(appointment.duration || 0);

  const event = createHoldEvent(appointment);

  return (
    <>
      <div className="mbsc-appointment-holder-drag" draggable ref={setDrag}>
        <HolderCard
          isSelected={isSelected}
          name={name}
          duration={duration}
          onDoubleClick={onDoubleClick}
          onClick={onClick}
          onRequestDelete={onRequestDelete}
        />
      </div>
      <Draggable dragData={event} element={draggable === null ? undefined : draggable} />
    </>
  );
};
