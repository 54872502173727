/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { useCallback, useRef } from "react";
import { useReactToPrint } from "react-to-print";
import { noop } from "@libs/utils/noop";
import { MountVO, PatientSummaryVO } from "@libs/api/generated-api";
import { format } from "date-fns";
import { toBlob } from "html-to-image";
import { downloadBlob } from "@libs/utils/dataUrl";
import { ImageLayoutItem } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";
import { ImageWithBottomAttachment } from "components/PatientProfile/Imaging/ImageWithBottomAttachment";
import { useSandboxBounds } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/hooks/useSandboxBounds";
import {
  fitMountLayout,
  getExportedDocumentTag,
} from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { ImageViewMetadata } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ImageViewMetadata";
import { IMAGE_METADATA_HEIGHT } from "components/PatientProfile/Imaging/PatientMountsList/mountLayouts";
import { OFFSCREEN_RENDER_ORIGIN } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/shapeUtils";
import { useImageListContext } from "components/PatientProfile/Imaging/shared/ImageListContext";

const DEFAULT_METADATA_FONT_SIZE = 16;
const ExportableImageItem: React.FC<{
  image: ImageLayoutItem;
  className?: string;
  isShowingMetadata?: boolean;
  onAnnotationLoaded: Func;
  scale: number;
  onImageLoaded: Func;
}> = ({ image, isShowingMetadata, scale, onAnnotationLoaded, onImageLoaded }) => {
  const bounds = useSandboxBounds(image);
  const dimensions = {
    left: image.x,
    top: image.y,
  };

  return (
    <div className="absolute rounded" style={dimensions}>
      <ImageWithBottomAttachment
        bounds={bounds}
        image={image}
        onEditImage={noop}
        onImageLoaded={onImageLoaded}
        onAnnotationLoaded={onAnnotationLoaded}
        onClickImage={noop}
        actionsEnabled={false}
        className="object-contain rounded"
      >
        {isShowingMetadata && (
          <ImageViewMetadata
            image={image}
            onImageUpdate={noop}
            style={{ height: IMAGE_METADATA_HEIGHT * scale, fontSize: DEFAULT_METADATA_FONT_SIZE * scale }}
          />
        )}
      </ImageWithBottomAttachment>
    </div>
  );
};

const PRINT_MARGIN = 20;

// When the vertical margin is too small, the print will try to move the image to the next page.  This buffers that threshold
const VERTICAL_PAGE_BREAK_BUFFER = 5;

// This is 11 x 8.5 inches in pixels, accounting for the 20px margin (subtracts 20 from 1056 and 816)
const BASE_DIMENSIONS = {
  width: 1056,
  height: 816,
};

const PRINTABLE_SIZE = {
  width: BASE_DIMENSIONS.width - PRINT_MARGIN * 2,
  height: BASE_DIMENSIONS.height - PRINT_MARGIN * 2 - VERTICAL_PAGE_BREAK_BUFFER,
};

const HEADER_HEIGHT_PX = 40;

export const CaptureMountLayout: React.FC<{
  gridImages: ImageLayoutItem[];
  selectedImageIds: Set<number>;
  isShowingMetadata?: boolean;
  onExportComplete: Func;
  patient: PatientSummaryVO;
  imageMount: MountVO;
  isViewingArchived: boolean;
  exportType: "image" | "print";
}> = ({
  gridImages,
  selectedImageIds,
  isShowingMetadata = false,
  onExportComplete,
  imageMount,
  isViewingArchived,
  exportType,
  patient,
}) => {
  const printRef = useRef<HTMLDivElement | null>(null);
  const { layersShown } = useImageListContext();
  const hasAnnotations = layersShown.has("annotations");

  const annotationsLoaded = React.useRef<number>(0);
  const imagesLoaded = React.useRef<number>(0);
  const { fittedImages, scale } = React.useMemo(() => {
    return fitMountLayout({
      gridImages,
      dimensions: {
        size: PRINTABLE_SIZE,
        headerSize: HEADER_HEIGHT_PX,
      },
      isViewingArchived,
      selectedImageIds,
    });
  }, [gridImages, isViewingArchived, selectedImageIds]);

  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle: getExportedDocumentTag(imageMount, patient),
    pageStyle: `@page { size: letter landscape; margin: ${PRINT_MARGIN}px;  }`,
    onAfterPrint: onExportComplete,
  });

  const handleExport = useCallback(() => {
    if (
      !printRef.current ||
      imagesLoaded.current < fittedImages.length ||
      (hasAnnotations && annotationsLoaded.current < fittedImages.length)
    ) {
      return;
    }

    if (exportType === "image") {
      toBlob(printRef.current).then((blob) => {
        if (blob) {
          downloadBlob(blob, `${getExportedDocumentTag(imageMount, patient)}.png`);
          onExportComplete();
        }
      });
    } else {
      handlePrint();
    }
  }, [exportType, fittedImages.length, handlePrint, hasAnnotations, imageMount, onExportComplete, patient]);

  const handleImageLoaded = React.useCallback(() => {
    // This callback assures all images have had their annotation loaded before printing
    imagesLoaded.current++;
    handleExport();
  }, [handleExport]);
  const handleAnnotationLoaded = React.useCallback(() => {
    // This callback assures all images have had their annotation loaded before printing
    annotationsLoaded.current++;
    handleExport();
  }, [handleExport]);

  return (
    <div
      style={{
        ...OFFSCREEN_RENDER_ORIGIN,
        ...(exportType === "print" ? PRINTABLE_SIZE : BASE_DIMENSIONS),
      }}
    >
      <div
        ref={(c) => {
          if (c) {
            printRef.current = c;
            handleExport();
          }
        }}
        style={exportType === "image" ? { padding: PRINT_MARGIN } : undefined}
        className={exportType === "image" ? "bg-white" : undefined}
      >
        <div style={{ height: HEADER_HEIGHT_PX }} className="flex flex-row items-center justify-between">
          <div className="text-lg font-sansSemiBold">{getExportedDocumentTag(imageMount, patient)}</div>
          <div className="text-slate-700">
            {exportType === "image" ? "Exported" : "Printed"} on {format(new Date(), "MM/dd/yyyy")}
          </div>
        </div>
        <div
          className="relative"
          style={
            exportType === "image"
              ? {
                  width: PRINTABLE_SIZE.width,
                  height: PRINTABLE_SIZE.height - HEADER_HEIGHT_PX,
                }
              : undefined
          }
        >
          {fittedImages.map((item, i) => (
            <ExportableImageItem
              image={item}
              key={i}
              scale={scale}
              isShowingMetadata={isShowingMetadata}
              onImageLoaded={handleImageLoaded}
              onAnnotationLoaded={handleAnnotationLoaded}
            />
          ))}
        </div>
      </div>
    </div>
  );
};
