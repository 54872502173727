import React, { FC } from "react";
import { Filter, UnscheduledProcedureRequest } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useAccount } from "@libs/contexts/AccountContext";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { UnscheduledTreatmentByPatientTable } from "components/Dashboard/UnscheduledTreatment/UnscheduledTreatmentByPatientTable";
import { DashboardLayout } from "components/Dashboard/DashboardLayout";
import { SelectReportingQuestion } from "components/Dashboard/SelectReportingQuestion";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { useQueryParams } from "hooks/useQueryParams";
import { getTimeSeriesQuery, getUnscheduledProcedures } from "api/reporting/queries";
import { UnscheduledTreatmentQuery } from "utils/routing/dashboard";
import { UnscheduledTreatmentChart } from "components/Dashboard/UnscheduledTreatment/UnscheduledTreatmentChart";
import { MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";
import { useSelectedAgingOption } from "components/Dashboard/hooks/useSelectedAgingOption";
import { ACTIVE_NO_NEXT_APPT_EXCLUDE_HYGIENE_FILTER } from "components/Dashboard/utils/filters";
import { UNSCHEDULED_TREATMENT_AGING_OPTIONS } from "components/Dashboard/UnscheduledTreatment/constants";
import { useLongAgingOptions } from "components/Dashboard/hooks/useLongAgingOptions";
import { getDentalProceduresQuery } from "api/charting/queries";
import { cxStyles } from "components/Dashboard/DashboardPageHeader";

const CHART_FILTERS: Filter[] = ACTIVE_NO_NEXT_APPT_EXCLUDE_HYGIENE_FILTER;

export const UnscheduledTreatmentRoute: FC = () => {
  const unscheduledTreatmentAgingOptions = useLongAgingOptions({
    labels: UNSCHEDULED_TREATMENT_AGING_OPTIONS,
    includeFuture: false,
  });

  const { query, updateQuery } = useQueryParams("dashboardUnscheduledTreatment");

  const { practiceId } = useAccount();

  const [timeSeriesQuery, allDentalProceduresQuery] = useApiQueries([
    getTimeSeriesQuery({
      args: {
        practiceId,
        data: {
          resolution: "LONG_AGING" as const,
          filters: CHART_FILTERS,
          metric: "unscheduledProcedurePatientCount",
        },
      },
    }),
    getDentalProceduresQuery({ args: { practiceId } }),
  ]);
  const { search: debouncedSearch } = useDebouncedSearch(query.patientSearch ?? "");

  const { startDate, endDate } = useSelectedAgingOption({
    dateAgeIndex: query.dateAgeIndex,
    labels: UNSCHEDULED_TREATMENT_AGING_OPTIONS,
    includeFuture: false,
  });
  const { tableSort, filters, dateAgeIndex } = query;

  const reportParams: UnscheduledProcedureRequest = {
    startDate,
    endDate,
    patientSearchString: debouncedSearch,
    filters,
    sortOrders: tableSort,
    includeTotal: true,
  };
  const unscheduledTreatmentQuery = useInfiniteApiQuery(
    getUnscheduledProcedures({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: MAX_PAGE_SIZE,
        data: reportParams,
      },
    })
  );

  const handleRouteStateChange = React.useCallback(
    (updates: Partial<UnscheduledTreatmentQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );

  return (
    <DashboardLayout
      className="min-w-[1150px]"
      header={
        <>
          <div className={cxStyles.pageHeader}>
            <SelectReportingQuestion question="unscheduled-treatment" />
            <div className="flex items-center space-x-4">
              <div className="text-sm font-sansSemiBold">Treatment Planned</div>
              <FormFieldSelect
                aria-label="Select Time Span"
                isClearable={false}
                className="min-w-48"
                isSearchable={false}
                options={unscheduledTreatmentAgingOptions}
                value={dateAgeIndex}
                onChange={(option) => {
                  if (option) {
                    handleRouteStateChange({ dateAgeIndex: option.value });
                  }
                }}
              />
            </div>
          </div>

          {query["table.fullScreen"] ? null : (
            <UnscheduledTreatmentChart
              dateAgeIndex={dateAgeIndex}
              timeSeriesQuery={timeSeriesQuery}
              onBarClick={handleRouteStateChange}
            />
          )}
        </>
      }
    >
      <UnscheduledTreatmentByPatientTable
        unscheduledTreatmentQuery={unscheduledTreatmentQuery}
        startDate={startDate}
        endDate={endDate}
        query={query}
        allDentalProceduresQuery={allDentalProceduresQuery}
        onUpdateParams={handleRouteStateChange}
      />
    </DashboardLayout>
  );
};
