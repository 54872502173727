import { EmploymentDetailsVO } from "@libs/api/generated-api";
import { FC } from "react";
import { FormFieldSelect, FormFieldSelectProps } from "components/UI/FormFieldSelect";

interface Props
  extends Omit<
    FormFieldSelectProps<
      NonNullable<EmploymentDetailsVO["flsaStatus"]>,
      SelectOption<NonNullable<EmploymentDetailsVO["flsaStatus"]>>
    >,
    "options"
  > {}

export const FormFieldFlsaSelect: FC<Props> = ({ label = "Overtime", ...props }) => {
  return (
    <FormFieldSelect
      {...props}
      label={label}
      options={[
        { label: "Exempt", value: "EXEMPT" },
        { label: "Non-Exempt", value: "NON_EXEMPT" },
      ]}
    />
  );
};
