import { FC, useMemo, useState } from "react";
import { useLocation } from "react-router-dom";
import { PayrollSummaryVO } from "@libs/api/generated-api";
import { noop } from "@libs/utils/noop";
import { formatISODate } from "@libs/utils/date";
import { getFullUrl } from "@libs/utils/location";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { Icon } from "@libs/components/UI/Icon";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { AlternateRunPayrollSection } from "components/Payroll/AlternateRunPayrollSection";
import { paths } from "utils/routing/paths";

interface Props {
  unprocessedPayrollsQuery: ApiQueryResult<PayrollSummaryVO[]>;
  disabledRunPayroll: boolean;
}

const SelectPayroll: FC<{ payrolls: PayrollSummaryVO[]; disabledRunPayroll: boolean }> = ({
  payrolls,
  disabledRunPayroll,
}) => {
  const location = useLocation();
  const [selectedId, setSelectedId] = useState<string>(payrolls[0] ? payrolls[0].uuid : "");

  const unprocessedOptions = useMemo(() => {
    return payrolls.map((payroll) => ({
      value: payroll.uuid,
      label: `${formatISODate(payroll.periodStartDate)} - ${formatISODate(payroll.periodEndDate)}`,
    }));
  }, [payrolls]);

  return (
    <>
      <div className="flex items-center mb-2">
        <h1 className="font-sansSemiBold">Regular Payroll</h1>
        {unprocessedOptions.length ? (
          <FormFieldSelect
            options={unprocessedOptions}
            value={selectedId}
            isSearchable={false}
            isClearable={false}
            className="w-60 ml-4"
            onItemSelected={setSelectedId}
          />
        ) : null}
      </div>
      <div className="flex text-xs mb-5 items-center">
        {unprocessedOptions.length ? (
          <>
            <p className="mr-1">Run payroll for the selected period.</p>
            <Icon
              SvgIcon={ErrorIcon}
              theme="primary"
              tooltip={{
                content:
                  "If you have hourly workers, all hours must be entered and approved prior to running payroll.",
              }}
            />
          </>
        ) : (
          <p>No pay periods available.</p>
        )}
      </div>
      {unprocessedOptions.length && !disabledRunPayroll ? (
        <ButtonInternalLink to={paths.editPayroll({ payrollId: selectedId }, { from: getFullUrl(location) })}>
          Run Regular Payroll
        </ButtonInternalLink>
      ) : (
        <Button theme="primary" disabled={true}>
          Run Regular Payroll
        </Button>
      )}
    </>
  );
};

export const RunPayrollSection: FC<Props> = ({ disabledRunPayroll, unprocessedPayrollsQuery }) => {
  return (
    <>
      <div>
        <QueryResult queries={[unprocessedPayrollsQuery]}>
          {unprocessedPayrollsQuery.data ? (
            <SelectPayroll disabledRunPayroll={disabledRunPayroll} payrolls={unprocessedPayrollsQuery.data} />
          ) : null}
        </QueryResult>
      </div>
      {/* Make visible once off cycle is done */}
      <div className="flex invisible">
        <AlternateRunPayrollSection
          title="Off-Cycle Payroll"
          description="Run a payroll outside of regular pay schedule"
          ctaText="Run Off-Cycle Payroll"
          onCtaClick={noop}
          className="my-2"
        />
        <AlternateRunPayrollSection
          className="ml-4 my-2"
          title="Dismissal"
          description="Run it when an employee is dismissed"
          ctaText="Go to Employees list"
          onCtaClick={noop}
        />
      </div>
    </>
  );
};
