import React, { useCallback, useMemo, useRef, useState } from "react";
import { DndProvider, XYCoord } from "react-dnd";
import { useNavigate } from "react-router-dom";
import { MedicalImageVO, MountVO, PatientSummaryVO, TransferImagesRequest } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@libs/utils/queries";
import { HTML5Backend } from "react-dnd-html5-backend";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { Banner } from "@libs/components/UI/Banner";
import { useSelectedImageIds } from "components/PatientProfile/Imaging/MountRoute/hooks/useSelectedImageIds";
import { useCaptureTools } from "components/PatientProfile/Imaging/MountRoute/hooks/useCaptureTools";
import {
  ImageTransferData,
  TransferImagesFlyover,
} from "components/PatientProfile/Imaging/MountRoute/TransferImagesFlyover";
import { SelectedImageOptionsToolbar } from "components/PatientProfile/Imaging/MountRoute/SelectedImageOptionsToolbar";
import { useImageArchiver } from "components/PatientProfile/Imaging/MountRoute/hooks/useImageArchiver";
import { HeaderRight } from "components/PatientProfile/Imaging/MountRoute/MountHeader/HeaderRight";
import { HeaderLeft } from "components/PatientProfile/Imaging/MountRoute/MountHeader/HeaderLeft";
import { useCameraCapture } from "components/ImageCapturing/useCameraCapture";
import { useCloseTwainOnExit } from "components/ImageCapturing/useTwain";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";
import { MountDetailsQuery } from "utils/routing/patient";
import { paths } from "utils/routing/paths";
import { useAppTheme } from "hooks/useAppTheme";
import { DarkRoomHeader } from "components/PatientProfile/Imaging/DarkRoomHeader";
import { useItemModal } from "hooks/useItemModal";
import { MountLayoutType } from "api/imaging/imaging-api";
import { handleError } from "utils/handleError";
import { transferImages, updateMount } from "api/imaging/mutations";
import { TransferConfirmationModal } from "components/PatientProfile/Imaging/PatientMountsList/TransferConfirmationModal";
import { useBlockRouteUntil } from "hooks/useBlockRouteUntil";
import { ImageLayoutItem } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";
import { CaptureMountLayout } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/CaptureMountLayout";
import { HeaderUploadOptions } from "components/PatientProfile/Imaging/MountRoute/MountHeader/HeaderUploadOptions";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { mountLayoutList } from "components/PatientProfile/Imaging/PatientMountsList/mountLayouts";
import { useMountContext } from "components/PatientProfile/Imaging/MountRoute/MountContext";
import {
  SandboxLayoutContextProvider,
  useSandboxLayoutContext,
} from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/SandboxLayoutContext";
import { ImageSandboxLayout } from "./ImageSandboxLayout";

type Props = {
  imageMount: MountVO;
  patient: PatientSummaryVO;
  mountsInfiniteQuery: UseInfiniteApiQueryResult<MountVO[]>;
  onEditImage: (params: Pick<MedicalImageVO, "id">) => void;
  onClickTeeth: (image: MedicalImageVO) => void;
  isOnboardedWithPearl: boolean;
};
// eslint-disable-next-line max-statements, complexity
export const ImageSandboxContent: React.FC<Props> = ({
  imageMount,
  patient,
  onEditImage,
  onClickTeeth,
  mountsInfiniteQuery,
  isOnboardedWithPearl,
}) => {
  useCloseTwainOnExit();

  const {
    sandboxImages,
    isFirstCaptureSession,
    nextCaptureSpot,
    retakingImageId,
    handleLayoutItemClicked,
    handleOrderImageOnTop,
  } = useSandboxLayoutContext();

  const [exportType, setExportType] = useState<"image" | "print" | null>(null);
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const params = usePathParams("mountDetails");
  const { patientId } = params;

  const { query, updateQuery } = useQueryParams("mountDetails");
  const { format: mountFormat, hideMeta, deviceId, showArchived } = query;
  const cameraCapture = useCameraCapture();

  // Only track the first time whether or not this was a new mount.
  const isNewMount = useRef(imageMount.images?.length === 0);

  const queryClient = useQueryClient();

  useCloseTwainOnExit();

  const { handleImageSelected, selectedImageIds, clearSelectedImageIds } = useSelectedImageIds();

  const mountLayout = imageMount.layout as MountLayoutType;

  const {
    selectedCaptureDevice,
    handleSelectedCaptureDeviceChanged,
    startSensorUploader,
    handleSingleImageUpload,
    devices,
    isUploading,
    captureState,
  } = useCaptureTools({
    sandboxImages,
    layout: mountLayout,
    patient,
    deviceId,
  });
  const handleItemClicked = useCallback(
    (item: ImageLayoutItem) => {
      if (isUploading) {
        return;
      }

      if (item.url) {
        handleImageSelected(item);
      }

      handleLayoutItemClicked(item);
    },
    [isUploading, handleLayoutItemClicked, handleImageSelected]
  );

  const { imageEditQueue, handleUpdateMountMetadata } = useMountContext();
  const { handleImageUpdate, handleImageOriginChanged, handleMountChanged, flushMountChanges } =
    imageEditQueue;

  const { archiveModal, onConfirmArchive } = useImageArchiver({
    mount: imageMount,
    handleMountChanged,
  });
  const handleQueryParamsChanged = React.useCallback(
    (queryParams: Partial<MountDetailsQuery>) => {
      updateQuery("replaceIn", queryParams);
    },
    [updateQuery]
  );

  useAppTheme("dark");

  const isShowingMetadata = !hideMeta;
  const handleEditImage = useCallback(
    (image: Pick<MedicalImageVO, "id">) => {
      flushMountChanges();
      onEditImage(image);
    },
    [flushMountChanges, onEditImage]
  );

  const selectedMount = useItemModal<{ imageMount: MountVO }>(null);
  const confirmationModal = useBoolean(false);

  const [transferImagesMutation, { mutate: mutateMount }] = useApiMutations([transferImages, updateMount]);
  const [confirmationNavigateData, setConfirmationNavigateData] = useState<{
    mountId: number;
    patientId: number;
  }>();

  const handleTransferMount = useCallback(
    ({ targetMountId, targetPatientId }: ImageTransferData) => {
      if (targetPatientId) {
        const data: TransferImagesRequest = {
          imageIds: [...selectedImageIds],
          sourceMountId: imageMount.id,
          sourcePatientId: patientId,
          targetPatientId,
        };

        if (targetMountId) {
          data.targetMountId = targetMountId;
        }

        transferImagesMutation.mutate(
          {
            data,
            practiceId,
          },
          {
            onSuccess: (response) => {
              selectedMount.close();
              confirmationModal.on();
              setConfirmationNavigateData({
                mountId: response.data.data.id,
                patientId: targetPatientId,
              });
            },
            onError: handleError,
          }
        );
      }
    },
    [
      confirmationModal,
      imageMount.id,
      patientId,
      practiceId,
      selectedImageIds,
      selectedMount,
      transferImagesMutation,
    ]
  );

  const uploadFinished = useCallback(
    () => (isUploading ? Promise.reject() : Promise.resolve()),
    [isUploading]
  );

  const handleOnImageOriginChanged = useCallback(
    (image: ImageLayoutItem, origin: XYCoord) => {
      handleImageOriginChanged(image, origin);
      handleOrderImageOnTop(image.id);
    },
    [handleImageOriginChanged, handleOrderImageOnTop]
  );
  const isPopulated = (imageMount.images ?? []).some((item) => item.url);
  const dropdownItems = mountLayoutList.map(({ type: mountType, label }) => ({ value: mountType, label }));

  const changeMount = React.useCallback(
    (layout: string) => {
      mutateMount(
        {
          practiceId,
          patientId,
          mountId: imageMount.id,
          data: {
            images: imageMount.images,
            layout,
          },
        },
        { onError: handleError }
      );
    },
    [mutateMount, practiceId, patientId, imageMount.id, imageMount.images]
  );

  useBlockRouteUntil(uploadFinished);

  const displayedSandboxItems = useMemo(
    () => (isFirstCaptureSession ? sandboxImages : sandboxImages.filter((item) => item.url)),
    [sandboxImages, isFirstCaptureSession]
  );

  return (
    <>
      <div className="flex flex-col min-h-0 h-full dark">
        <DarkRoomHeader className="min-w-[72rem] print:hidden">
          <HeaderLeft
            mountFormat={mountFormat}
            imageMount={imageMount}
            mountsInfiniteQuery={mountsInfiniteQuery}
            patient={patient}
          >
            <HeaderUploadOptions
              selectedCaptureDevice={selectedCaptureDevice}
              imageMount={imageMount}
              sandboxImages={sandboxImages}
              isUploading={isUploading}
              handleUploadWithSensor={startSensorUploader}
              cameraCapture={cameraCapture}
              isFirstCaptureSession={Boolean(imageMount) && isFirstCaptureSession}
              onUpload={handleSingleImageUpload}
              nextCaptureSpot={nextCaptureSpot}
              captureState={captureState}
              patient={patient}
              onCaptureDeviceChanged={handleSelectedCaptureDeviceChanged}
              isRetaking={Boolean(retakingImageId)}
            />
          </HeaderLeft>

          <HeaderRight
            mountFormat={mountFormat}
            imageMount={imageMount}
            showArchived={showArchived}
            onToggleShowArchived={() => handleQueryParamsChanged({ showArchived: !showArchived })}
            onClickDone={() => {
              if (isNewMount.current) {
                // Clear out the mount list so it will show the new mount on exit.
                queryClient.invalidateQueries([getQueryKey("practices", "getMountList"), { patientId }]);
              }

              navigate(paths.patientTab({ tab: "imaging", patientId }));
            }}
            devices={devices}
            onChangeImage={handleUpdateMountMetadata}
            onUpdateParams={handleQueryParamsChanged}
            isShowingMetadata={isShowingMetadata}
          >
            <SelectedImageOptionsToolbar
              handleMountChanged={handleMountChanged}
              mount={imageMount}
              onUpdateParams={handleQueryParamsChanged}
              params={query}
              patient={patient}
              handleExportMount={() => setExportType("image")}
              handlePrintMount={() => setExportType("print")}
              selectedImageIds={selectedImageIds}
            />
            {selectedCaptureDevice.mode === "X_RAY" && isFirstCaptureSession && !isPopulated && (
              <div className="mountDropdown">
                <FormFieldSelect
                  value={imageMount.layout}
                  display="value"
                  className="min-w-28"
                  isClearable={false}
                  isSearchable={false}
                  options={dropdownItems}
                  isDark
                  onItemSelected={changeMount}
                />
              </div>
            )}
          </HeaderRight>
        </DarkRoomHeader>
        <div className="flex flex-1 min-h-0 flex-row">
          <ImageSandboxLayout
            mount={imageMount}
            handleMountChanged={handleMountChanged}
            onImageOriginChanged={handleOnImageOriginChanged}
            onClickItem={handleItemClicked}
            selectedImageIds={selectedImageIds}
            sandboxImages={displayedSandboxItems}
            onClickBackground={clearSelectedImageIds}
            isShowingMetadata={isShowingMetadata}
            onImageUpdate={handleImageUpdate}
            devices={devices}
            patientId={patientId}
            captureState={captureState}
            onEditImage={handleEditImage}
            onClickTeeth={onClickTeeth}
            isOnboardedWithPearl={isOnboardedWithPearl}
          />
          {isUploading && (
            <Banner
              theme="warning"
              className={`
                text-xs
                text-white
                absolute
                bottom-4
                left-1/2
                transform
                -translate-x-1/2
              `}
            >
              Image upload in progress, please do not navigate away from this page.
            </Banner>
          )}
        </div>
      </div>
      {exportType && (
        <CaptureMountLayout
          imageMount={imageMount}
          gridImages={sandboxImages}
          isShowingMetadata={isShowingMetadata}
          selectedImageIds={selectedImageIds}
          patient={patient}
          onExportComplete={() => {
            setExportType(null);
          }}
          isViewingArchived={showArchived}
          exportType={exportType}
        />
      )}

      {archiveModal.isOpen && (
        <ConfirmationModal
          primaryText="Are you sure you want to archive this image?"
          onCancel={archiveModal.close}
          onConfirm={() => {
            archiveModal.close();
            onConfirmArchive();
          }}
        />
      )}

      {selectedMount.isOpen && (
        <TransferImagesFlyover
          isLoading={transferImagesMutation.isLoading}
          onClose={selectedMount.close}
          onTransfer={handleTransferMount}
          sourceMount={selectedMount.item.imageMount}
          sourcePatientId={patientId}
        />
      )}
      {confirmationModal.isOn && confirmationNavigateData && (
        <TransferConfirmationModal
          confirmationNavigateData={confirmationNavigateData}
          onClose={confirmationModal.off}
          transferType="IMAGES"
        />
      )}
    </>
  );
};

export const ImageSandboxRoute: React.FC<
  Props & {
    showArchived: boolean;
    selectedImageIds?: number[];
  }
> = (props) => {
  const { updateQuery } = useQueryParams("mountDetails");
  const uploading = useBoolean(false);
  const uploadingSet = uploading.set;
  const handleClearSelectedImageIds = useCallback(() => {
    updateQuery("replaceIn", { selectedImageIds: undefined });
  }, [updateQuery]);

  React.useEffect(() => {
    // Clear selectedImageIds when user navigates away
    return () => handleClearSelectedImageIds();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleCaptureSequenceStatusChanged = useCallback(
    (status: "uploading" | "complete") => {
      uploadingSet(status === "uploading");

      if (status === "complete") {
        handleClearSelectedImageIds();
      }
    },
    [handleClearSelectedImageIds, uploadingSet]
  );

  return (
    <SandboxLayoutContextProvider
      imageMount={props.imageMount}
      showArchived={props.showArchived}
      selectedImageIds={props.selectedImageIds}
      onCaptureSequenceStatusChanged={handleCaptureSequenceStatusChanged}
    >
      <DndProvider backend={HTML5Backend}>
        <ImageSandboxContent {...props} />
      </DndProvider>
    </SandboxLayoutContextProvider>
  );
};
