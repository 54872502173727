import React, { useMemo } from "react";
import designConfig from "@libs/design.config";
import { FeeScheduleVO, NameVO, ProviderVO } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { createSelectStyles } from "@libs/components/UI/selectStyles";
import { FormFieldMultiSelect } from "components/UI/FormFieldMultiSelect";
import { CheckboxRow } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/CheckboxRow";

export const SELECT_ALL_INDEX = -1;

type Props = {
  error?: string;
  lookupProvidersWithoutUCR?: boolean;
  onUpdate: (fields: Partial<FeeScheduleVO>) => void;
  originallySelectedProviders: NameVO[] | undefined;
  providersQuery: ApiQueryResult<ProviderVO[]>;
  providersWithoutUcrQuery: ApiQueryResult<ProviderVO[]>;
  selectedProviderIds: number[];
};

export const SelectProviders: React.FC<Props> = ({
  error,
  lookupProvidersWithoutUCR,
  onUpdate,
  originallySelectedProviders,
  providersQuery,
  providersWithoutUcrQuery,
  selectedProviderIds,
}) => {
  const canSelectAll = !lookupProvidersWithoutUCR;

  const withoutUcrSet = useMemo(() => {
    return new Set(providersWithoutUcrQuery.data?.map((item) => item.id) ?? []);
  }, [providersWithoutUcrQuery.data]);

  const { data: providersAvailable } = providersQuery;

  const providersAvailableIncludingOriginalProviders = useMemo(() => {
    const providersAvailableIds = new Set(providersAvailable?.map((item) => item.id) ?? []);
    const excludedOriginalProviders: NameVO[] = [];

    originallySelectedProviders?.forEach((item) => {
      if (!providersAvailableIds.has(item.id)) {
        excludedOriginalProviders.push(item);
      }
    });

    const list = [
      ...(providersAvailable ?? []).map((item) => {
        return {
          label: item.name.fullDisplayName,
          value: item.id,
        };
      }),
      ...excludedOriginalProviders.map((item) => {
        return {
          label: item.fullDisplayName,
          value: item.id,
        };
      }),
    ];

    return list.sort((a, b) => (a.label <= b.label ? -1 : 1));
  }, [originallySelectedProviders, providersAvailable]);

  const originallySelectedProviderIds = useMemo(() => {
    return new Set(originallySelectedProviders?.map((item) => item.id));
  }, [originallySelectedProviders]);

  const selectedValues = useMemo(() => {
    if (
      canSelectAll &&
      (selectedProviderIds.length === 0 ||
        originallySelectedProviderIds.size === providersAvailableIncludingOriginalProviders.length)
    ) {
      // When no providers are defined, AND when all providers are defined, it
      // means it applies to all providers (SELECT_ALL_INDEX by default). We
      // submit this to the server as all provider ids selected, but the server
      // does magic to essentially store this setting as 'undefined' in the
      // backend meaning it applies to all

      // migrated practices will have no providers set here.
      return [SELECT_ALL_INDEX];
    }

    return selectedProviderIds;
  }, [
    canSelectAll,
    originallySelectedProviderIds.size,
    providersAvailableIncludingOriginalProviders.length,
    selectedProviderIds,
  ]);

  const currentProviders = useMemo(() => new Set(selectedValues), [selectedValues]);

  const options = useMemo(() => {
    if (lookupProvidersWithoutUCR) {
      return [
        { isDisabled: true, label: "All", value: SELECT_ALL_INDEX },
        ...providersAvailableIncludingOriginalProviders.map((provider) => {
          return {
            ...provider,
            isDisabled:
              withoutUcrSet.has(provider.value) || originallySelectedProviderIds.has(provider.value),
          };
        }),
      ];
    }

    return [
      {
        label: "All",
        value: SELECT_ALL_INDEX,
      },
      ...providersAvailableIncludingOriginalProviders,
    ];
  }, [
    lookupProvidersWithoutUCR,
    originallySelectedProviderIds,
    providersAvailableIncludingOriginalProviders,
    withoutUcrSet,
  ]);

  const customStyles = useMemo(
    () =>
      createSelectStyles<number, SelectOption<number>, true>({
        valueContainer: () => ({
          padding: 0,
        }),
        option: (_, { isFocused }) => ({
          backgroundColor: isFocused ? designConfig.colors.greyLightest : "white",
        }),
      }),
    []
  );

  return (
    <QueryResult queries={[providersQuery, providersWithoutUcrQuery]}>
      <FormFieldMultiSelect
        closeMenuOnSelect={false}
        error={error}
        formatOptionLabel={({ label: optionLabel, value, isDisabled }) => {
          return (
            <FloatingTooltip
              content={
                isDisabled
                  ? value === SELECT_ALL_INDEX
                    ? "Cannot select all providers for a UCR fee schedule."
                    : "Provider is already assigned a UCR fee schedule."
                  : ""
              }
              placement="left"
            >
              <div>
                <CheckboxRow
                  label={optionLabel}
                  checked={currentProviders.has(value)}
                  disabled={isDisabled}
                />
              </div>
            </FloatingTooltip>
          );
        }}
        hideSelectedOptions={false}
        isSearchable={false}
        label="Providers"
        layout="labelOut"
        onChange={(selectedOptions) => {
          const selectedAll =
            canSelectAll &&
            (selectedOptions.some(
              (item) => item.value === SELECT_ALL_INDEX && !currentProviders.has(SELECT_ALL_INDEX)
            ) ||
              selectedOptions.length === 0);

          if (selectedAll) {
            onUpdate({ providerIds: providersAvailableIncludingOriginalProviders.map((item) => item.value) });
          } else {
            onUpdate({
              providerIds: selectedOptions
                .filter((item) => item.value !== SELECT_ALL_INDEX)
                .map((item) => item.value),
            });
          }
        }}
        options={options}
        placeholder="Select providers..."
        required
        styles={customStyles}
        value={selectedValues}
      />
    </QueryResult>
  );
};
