import { MedicalImageVO } from "@libs/api/generated-api";
import React, { useEffect } from "react";
import { imagingUrlCache } from "components/PatientProfile/Imaging/utils/cache";

export const useCachedImage = ({ image, enabled = true }: { image: MedicalImageVO; enabled?: boolean }) => {
  const [imageUrl, setImageUrl] = React.useState<undefined | string>();

  useEffect(() => {
    if (enabled) {
      imagingUrlCache.add(image).then((url) => {
        setImageUrl(url);
      });
    }
  }, [image, enabled]);

  return enabled ? imageUrl : image.url;
};
