import { Link } from "react-router-dom";
import { formatISODate } from "@libs/utils/date";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { paths } from "utils/routing/paths";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";
import { isUnscheduledAsapAppt } from "components/ScheduleAppointments/utils";

export const AppointmentDateLinkCell: React.FC<{
  last?: boolean;
  date?: string;
  appointmentId?: number;
  patientId: number;

  // Link for the *row* on hover
  to: string;
}> = ({ patientId, appointmentId, date, last, to }) => {
  return (
    <DashboardLinkCell to={to} last={last}>
      {appointmentId && date && !isUnscheduledAsapAppt(date) ? (
        <FloatingTooltip content="See on Calendar" theme="SMALL">
          <Link
            className="hover:text-primaryTheme"
            onClick={(e) => {
              e.stopPropagation();
            }}
            to={paths.schedule({
              patientId,
              appointmentId,
              date,
            })}
          >
            {formatISODate(date)}
          </Link>
        </FloatingTooltip>
      ) : (
        EMPTY_CELL
      )}
    </DashboardLinkCell>
  );
};
