import { InsuranceCarrierVO, InsurancePlanSummaryVO } from "@libs/api/generated-api";
import { toFilterComponentsProps } from "@libs/components/UI/queryFilterPillsUtils";
import { InsurancePlansQuery } from "utils/routing/settings";

const getInsuranceCarrierName = (insuranceCarriers: InsuranceCarrierVO[] | undefined, val: number) => {
  return insuranceCarriers?.find((carrier) => carrier.id === val)?.name ?? "";
};

export const getInsurancePlanFilterProps = (
  insuranceCarriers: InsuranceCarrierVO[] | undefined,
  insurancePlans: InsurancePlanSummaryVO[] | undefined,
  queryState: InsurancePlansQuery
) =>
  toFilterComponentsProps(queryState, [
    {
      type: "list",
      prop: "carrierIds",
      format: (val) => getInsuranceCarrierName(insuranceCarriers, val),
    },
    {
      type: "default",
      prop: "searchString",
      format: (val) => `Search: ${val.trim()}`,
    },
    {
      type: "default",
      prop: "feeScheduleId",
      format: () => insurancePlans?.[0]?.feeSchedule?.name ?? "",
    },
    {
      type: "default",
      prop: "hidePlansWithNoPatients",
      format: (val) => (val ? "Hide plans with no patients" : ""),
    },
  ]);
