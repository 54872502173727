import { MouseEvent, KeyboardEvent, forwardRef } from "react";

import { stripAllButNumbers } from "@libs/utils/inputFormatting";
import { isOneOf } from "@libs/utils/isOneOf";
import {
  FormFieldFormattedInput,
  FormFieldFormattedInputProps,
} from "@libs/components/UI/FormFieldFormattedInput";

export const MONTH_DAY_LENGTH = 4;

const MONTH_FIRST_INDEX = 0;
const MONTH_SECOND_INDEX = 1;
const MONTH_SECOND_NUM_MAX = 2;

const DAY_FIRST_INDEX = 2;
const DAY_FIRST_NUM_MAX = 3;
const DAY_SECOND_INDEX = 3;

const AFTER_MONTH = 2;

const MONTHS_WITH_31_DAYS = new Set(["01", "03", "05", "07", "08", "10", "12"]);

const FEBRUARY = "02";
const FEBRUARY_DAY_FIRST_NUM_MAX = 2;

// eslint-disable-next-line complexity
const cleanValue = (value: string) => {
  const numbers = [...stripAllButNumbers(value)].map(Number);
  const month = numbers.slice(0, AFTER_MONTH).join("");
  const isMonthWith31Days = MONTHS_WITH_31_DAYS.has(month);

  numbers.splice(MONTH_DAY_LENGTH);

  for (const [index, num] of numbers.entries()) {
    switch (index) {
      case MONTH_FIRST_INDEX: {
        if (num > 1) {
          // Remove num if greater than 1 (must not be more than 12 months)
          numbers.splice(MONTH_FIRST_INDEX, 1);
        }

        break;
      }
      case MONTH_SECOND_INDEX: {
        if (
          (numbers[MONTH_FIRST_INDEX] === 0 && num === 0) ||
          (numbers[MONTH_FIRST_INDEX] === 1 && num > MONTH_SECOND_NUM_MAX)
        ) {
          // Remove num if first month num is 0 and second month num is 0, or if
          // first month num is 1 and second month num is greater than 2 (must
          // not be more than 12 months)
          numbers.splice(MONTH_SECOND_INDEX, 1);
        }

        break;
      }
      case DAY_FIRST_INDEX: {
        if (num > DAY_FIRST_NUM_MAX || (month === FEBRUARY && num > FEBRUARY_DAY_FIRST_NUM_MAX)) {
          // Remove num if greater than 3 (must not be more than 30 or 31 days),
          // or greater than 2 if February (must not be more than 29 days)
          numbers.splice(DAY_FIRST_INDEX, 1);
        }

        break;
      }
      case DAY_SECOND_INDEX: {
        if (
          (numbers[DAY_FIRST_INDEX] === 0 && num === 0) ||
          (numbers[DAY_FIRST_INDEX] === DAY_FIRST_NUM_MAX && num > (isMonthWith31Days ? 1 : 0))
        ) {
          // Remove num if first day num is 0 and second day num is 0, or if
          // first day num is 3 and second day num is greater than 0 or 1 (must
          // not be more than 30 or 31 days depending on the month)
          numbers.splice(DAY_SECOND_INDEX, 1);
        }

        break;
      }
      default: {
        break;
      }
    }
  }

  return numbers.join("");
};

const formatValue = (value?: string) => {
  if (!value) {
    return "";
  }

  const characters = [...value];
  let formattedValue = "";

  for (const [index, char] of characters.entries()) {
    formattedValue += index === AFTER_MONTH ? ` / ${char}` : char;
  }

  return formattedValue;
};

const formattedCharacters = ["/", " "];

export const formatMonthDay = (value: string) => {
  const numbers = [...stripAllButNumbers(value)].map(Number);
  let monthDay = "--";

  for (const [index, num] of numbers.entries()) {
    monthDay += index === AFTER_MONTH ? `-${num}` : num;
  }

  return monthDay;
};

export const fillMonthDay = (value: string) => {
  const numbers = [...stripAllButNumbers(value)].map(Number);

  switch (numbers.length) {
    case MONTH_FIRST_INDEX + 1: {
      if (numbers[MONTH_FIRST_INDEX] === 0) {
        // When first month num is 0, fill with 01 / 01
        numbers.splice(MONTH_FIRST_INDEX + 1, 0, 1, 0, 1);
      } else {
        // When first month num is 1, insert 0 and fill with 01 / 01
        numbers.splice(MONTH_FIRST_INDEX, 1, 0, 1, 0, 1);
      }

      break;
    }
    case MONTH_SECOND_INDEX + 1: {
      // When second month num is present, fill with MM / 01
      numbers.splice(MONTH_SECOND_INDEX + 1, 0, 0, 1);
      break;
    }
    case DAY_FIRST_INDEX + 1: {
      if (numbers[DAY_FIRST_INDEX] === 0) {
        // When first day num is 0, fill with MM / 01
        numbers.splice(DAY_FIRST_INDEX + 1, 0, 1);
      } else {
        // When first day num is greater than 0, insert 0 and fill with MM / 0D
        numbers.splice(DAY_FIRST_INDEX, 1, 0, numbers[DAY_FIRST_INDEX]);
      }

      break;
    }
    default: {
      break;
    }
  }

  return formatMonthDay(numbers.join(""));
};

export const formatLocalizedMonthDay = (value: string) => {
  const strippedValue = stripAllButNumbers(value);

  return `${strippedValue.slice(0, AFTER_MONTH)}/${strippedValue.slice(AFTER_MONTH)}`;
};

export type FormFieldMonthDayInputProps = Omit<
  FormFieldFormattedInputProps,
  "inputMode" | "cleanValue" | "formatValue" | "formattedCharacters" | "onClick" | "onKeyDown"
>;

export const FormFieldMonthDayInput = forwardRef<HTMLInputElement, FormFieldMonthDayInputProps>(
  (props, ref) => {
    const handleClick = (e: MouseEvent<HTMLInputElement>) => {
      // Always set cursor position to end of input value
      e.currentTarget.setSelectionRange(e.currentTarget.value.length, e.currentTarget.value.length);
    };

    const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
      // Prevent arrow keys from moving cursor position
      if (isOneOf(e.key, ["ArrowLeft", "ArrowRight", "ArrowUp", "ArrowDown"])) {
        e.preventDefault();
      }
    };

    return (
      <FormFieldFormattedInput
        {...props}
        ref={ref}
        placeholder="MM / DD"
        cleanValue={cleanValue}
        formatValue={formatValue}
        formattedCharacters={formattedCharacters}
        onClick={handleClick}
        onKeyDown={handleKeyDown}
        inputMode="numeric"
      />
    );
  }
);
