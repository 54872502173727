import { WalletVO } from "@libs/api/generated-api";
import { PLACEHOLDER_WALLET_ID } from "components/PatientProfile/Billing/billingUtils";
import { createLinksContext } from "contexts/AppLinksContext";
import { paths } from "utils/routing/paths";

export const getDefaultPatientBillingLinks = (patientId: number, walletUuid: WalletVO["uuid"]) => ({
  patientBilling: paths.patientBilling({ patientId }),
  patientInvoices: paths.patientInvoices({ patientId }),
  patientPayments: paths.patientPayments({ patientId }),
  patientWalletActivity: paths.patientWalletActivity({ patientId, walletUuid }),
});

const { useLinks, LinksProvider, AppHistoryProvider } = createLinksContext(
  "PatientBilling",
  getDefaultPatientBillingLinks(0, PLACEHOLDER_WALLET_ID)
);

export const usePatientBillingLinks = useLinks;
export const PatientBillingLinksProvider = LinksProvider;
export const PatientBillingAppHistoryProvider = AppHistoryProvider;
