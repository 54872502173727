import { useLocation } from "react-router-dom";
import { getFullUrl } from "@libs/utils/location";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { useAccount } from "@libs/contexts/AccountContext";
import { TableGrid, HeaderCell } from "@libs/components/UI/GridTableComponents";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getClaimsTabsTotalsQuery, getDraftEobPaymentSummaries } from "api/claim/queries";
import { ClaimsTabs } from "components/Claims/Shared/Tabs";
import { EobTableRow } from "components/Claims/Eobs/TableRow";
import { EmptyClaimsList } from "components/Claims/Shared/EmptyList";
import { useClaimsTabs } from "components/Claims/Claims/useClaimTabs";
import { ClaimsAppHistoryProvider } from "components/Claims/ClaimsLinksContext";

const headers = [
  { id: "date", label: "Date", width: "150px" },
  { id: "insuranceCarrier", label: "Ins Carrier", width: "2fr" },
  { id: "paymentStatus", label: "", width: "1fr" },
  { id: "noOfClaims", label: "No. of Claims", width: "150px" },
  { className: "justify-end", id: "patientAmount", label: "Patient Responsibility", width: "200px" },
  { className: "justify-end", id: "insuranceAmount", label: "Insurance Amount", width: "200px" },
  { id: "actionsColumn", label: "", width: "100px" },
  { id: "remainingSpace", label: "", width: "1fr" },
];

export const EobsRoute: React.FC = () => {
  usePageTitle("EOBs");

  const location = useLocation();
  const { practiceId } = useAccount();

  const [claimsTabsTotalsQuery, eobsQuery] = useApiQueries([
    getClaimsTabsTotalsQuery({ args: { practiceId } }),
    getDraftEobPaymentSummaries({ args: { practiceId } }),
  ]);
  const fullUrl = getFullUrl(location);

  const { data: draftEobPayments } = eobsQuery;
  const isEmpty = !draftEobPayments?.length;
  const tabs = useClaimsTabs();

  return (
    <ClaimsAppHistoryProvider name="eob">
      <ClaimsTabs tabs={tabs} claimsTabsTotalsQuery={claimsTabsTotalsQuery} />
      <QueryResult queries={[eobsQuery]}>
        <div className="flex-1 min-h-0 overflow-y-auto">
          <TableGrid columnWidths={headers.map(({ width }) => width)}>
            {headers.map((item) => (
              <HeaderCell className={item.className} key={item.id}>
                {item.label}
              </HeaderCell>
            ))}
            {isEmpty ? (
              <EmptyClaimsList text="No EOBs" />
            ) : (
              draftEobPayments.map((draftEobPayment) => (
                <EobTableRow
                  currentUrl={fullUrl}
                  draftEobPayment={draftEobPayment}
                  key={draftEobPayment.uuid}
                />
              ))
            )}
          </TableGrid>
        </div>
      </QueryResult>
    </ClaimsAppHistoryProvider>
  );
};
