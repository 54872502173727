/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from "react";
import { format } from "date-fns";
import { getLocalDate } from "@libs/utils/date";
import { TimeSeriesResolutionOption } from "utils/routing/dashboard";
import { MAX_DAYS_X_AXIS, MAX_MONTHS_X_AXIS } from "components/Dashboard/Charting/theme";
import { formatDateForResolution } from "components/Dashboard/utils/timeSeriesDates";

export const getNumberOfTicks = (resolution: TimeSeriesResolutionOption, dataPointCount: number) => {
  return (resolution === "MONTH" && dataPointCount <= MAX_MONTHS_X_AXIS) ||
    (resolution === "DAY" && dataPointCount <= MAX_DAYS_X_AXIS)
    ? dataPointCount
    : undefined;
};
export const useDateFormatter = ({
  resolution,
  dataPointCount,
  ...dateWindow
}: {
  startDate: Date;
  endDate: Date;
  dataPointCount: number;
  resolution: TimeSeriesResolutionOption;
}) => {
  return React.useCallback(
    (dateString: string) => {
      const date = getLocalDate(dateString);
      const defaultFormat = format(date, "M/d/yyyy");

      switch (resolution) {
        case "DAY": {
          if (dataPointCount <= MAX_DAYS_X_AXIS) {
            return formatDateForResolution(resolution, dateString, { dateWindow, size: "sm" });
          }

          break;
        }
        case "MONTH": {
          return dataPointCount <= MAX_MONTHS_X_AXIS
            ? formatDateForResolution(resolution, dateString, { dateWindow, size: "sm" })
            : format(date, "MMM yyyy");
        }
        case "QUARTER":
        case "YEAR": {
          {
            return formatDateForResolution(resolution, dateString, { dateWindow, size: "sm" });
          }
        }

        // No default
      }

      return defaultFormat;
    },
    [dateWindow, dataPointCount, resolution]
  );
};
