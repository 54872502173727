import { useCallback } from "react";
import { LabCaseReturnReasonVO, LabCaseVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { getLabCaseReturnReasons } from "api/lab/queries";
import { updateLabCaseStatus } from "api/lab/mutations";

export const useLabCaseStatus = () => {
  const { practiceId } = useAccount();
  const [labCaseReturnReasonsQuery] = useApiQueries([getLabCaseReturnReasons({ args: { practiceId } })]);
  const [labCaseMutation] = useApiMutations([updateLabCaseStatus]);
  const handleLabCaseStatusChange = useCallback(
    (
      labCaseUuid: LabCaseVO["uuid"],
      status: LabCaseVO["status"],
      reason?: LabCaseReturnReasonVO["uuid"]
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    ) => {
      return labCaseMutation.mutateAsync({
        practiceId,
        labCaseUuid,
        data: {
          status,
          returnReasonUuid: reason,
        },
      });
    },
    [labCaseMutation, practiceId]
  );

  return {
    labCaseReturnReasons: labCaseReturnReasonsQuery.data ?? [],
    onLabCaseStatusChange: handleLabCaseStatusChange,
  };
};
