import { cx } from "@libs/utils/cx";
import { OptionInput, ValueTypes } from "@libs/components/UI/OptionInput";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { OptionInputList, RadioInputListProps } from "@libs/components/UI/OptionInputList";

interface SymbolSwitchOption<V extends ValueTypes> {
  symbol: string;
  value: V;
  label: string;
}
interface SymbolSwitchProps<V extends ValueTypes>
  extends Omit<
    RadioInputListProps<V, SymbolSwitchOption<V>>,
    "type" | "renderOptionItem" | "optionClassName" | "children"
  > {
  options: [SymbolSwitchOption<V>, SymbolSwitchOption<V>];
  size?: "xs";
}

const cxStyles = {
  container: { xs: "h-5 w-10" },
  dotSticker: { xs: "h-4 w-4" },
  symbolOffset: {
    xs: { left: "left-0.5", right: "right-0.5" },
  },
  option: { xs: "w-4 h-4" },
  font: { xs: "text-xs" },
  dotMovementRangeStart: { xs: "translate-x-0.5" },
  dotMovementRangeStop: { xs: "translate-x-[1.35rem]" },
};

export const SymbolSwitch = <V extends ValueTypes>({
  className,
  options,
  size = "xs",
  ...props
}: SymbolSwitchProps<V>) => {
  return (
    <OptionInputList
      type="radio"
      className={cx(
        `rounded-3xl
         cursor-pointer
         relative
         block
         bg-white
         ring-1
         ring-greyLighter`,
        cxStyles.container[size],
        className
      )}
      options={options}
      optionClassName={({ value }) =>
        cx(
          `absolute
           z-[1]
           top-1/2
           -translate-y-1/2
           cursor-pointer
           flex
           items-center
           justify-center`,
          cxStyles.option[size],
          value === options[0].value ? cxStyles.symbolOffset[size].left : cxStyles.symbolOffset[size].right
        )
      }
      renderOptionItem={({ children: _children, ...optionProps }, index, option) => (
        <OptionInput {...optionProps}>
          <FloatingTooltip content={option.label} theme="SMALL">
            <div
              className={cx(
                "transition-colors ease-in-out",
                optionProps.checked ? "text-white" : "text-primaryTheme"
              )}
            >
              <span className={cx("flex items-center", cxStyles.font[size])}>{option.symbol}</span>
            </div>
          </FloatingTooltip>
        </OptionInput>
      )}
      {...props}
    >
      <span
        className={cx(
          `rounded-full
           absolute
           block
           bg-primaryTheme
           ease-in-out
           transition-transform
           top-1/2
           left-0
           -translate-y-1/2`,
          cxStyles.dotSticker[size],
          props.selectedValue === options[0].value
            ? cxStyles.dotMovementRangeStart[size]
            : cxStyles.dotMovementRangeStop[size]
        )}
      />
    </OptionInputList>
  );
};
