import { cx } from "@libs/utils/cx";
import { isOneOf } from "@libs/utils/isOneOf";
import { CaptureStatus } from "api/imaging/imaging-hub";
import { UseSensorCapture } from "components/PatientProfile/Imaging/MountRoute/hooks/useSensorCapture";

const cxCaputureStatusColors: Record<
  CaptureStatus["state"],
  {
    color: string;
    label: string;
  }
> = {
  Ready: {
    color: "bg-green",
    label: "READY",
  },
  Capturing: {
    color: "bg-yellowDark",
    label: "CAPTURING",
  },
  Captured: {
    color: "bg-yellowDark",
    label: "CAPTURING",
  },
  Finished: {
    color: "bg-yellowDark",
    label: "Finished",
  },
  Error: {
    color: "bg-red",
    label: "Error",
  },
};

export const shouldShowStatus = (captureState: UseSensorCapture["captureState"]) => {
  return (
    captureState.lastCaptureStatus &&
    isOneOf(captureState.lastCaptureStatus.state, ["Ready", "Capturing", "Captured", "Error"])
  );
};

export const SensorCaptureStatus: React.FC<{
  captureState: UseSensorCapture["captureState"];
  onToggleRetake?: (retaking: boolean) => void;
  isRetaking: boolean;
}> = ({ captureState, isRetaking }) => {
  const { lastCaptureStatus } = captureState;
  const sensorState = lastCaptureStatus?.state;

  if (!sensorState) {
    return null;
  }

  // const canRetake = sensorState === "Ready" && onToggleRetake;

  const captureStateMetadata = cxCaputureStatusColors[sensorState];

  return (
    <div className="flex gap-2">
      <div
        className={cx(
          `flex
           items-center
           justify-center
           text-center
           h-full
           text-white
           text-xs
           justify-centerF
           font-sansSemiBold
           gap-2
           p-3
           min-w-button`,
          captureStateMetadata.color
        )}
      >
        {isOneOf(sensorState, ["Capturing", "Captured"])
          ? cxCaputureStatusColors.Capturing.label
          : isRetaking && sensorState === "Ready"
            ? "RETAKING"
            : captureStateMetadata.label}
      </div>
    </div>
  );
};
