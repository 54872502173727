export const claimConstants = {
  deletePreauthConfirmation: "Are you sure you want to delete this pre-auth claim?",
  deletePreauthSecondaryConfirmation:
    "Deleting this claim will remove the selected claim associated with this appointment. In order to submit a new claim, you will need to create a new claim from the Claim section of a Patient's Profile.",
  deleteConfirmation: "Are you sure you want to delete this claim?",
  deleteSecondaryConfirmation:
    "Deleting this claim will remove the selected claim associated with this appointment. In order to submit a new claim, you will need to create a new claim from the Claim section of a Patient's Profile.",
  voidConfirmation: "Are you sure you want to void this claim?",
  voidSecondaryConfirmation:
    "If this claim was received by the insurance carrier, you will also need to contact the carrier to ensure the claim is fully voided. Claims marked as Voided in Archy cannot be undone.",
  missingRequirementsConfirmation: "Claim is missing support requirements.",
  missingRequirementsSecondaryConfirmation:
    "Are you sure you want to submit the claim? This claim requires attachments, you will need to add required attachments in DentalXChange before the claim is submitted to the carrier.",
  manualSubmitConfirmation: "Are you sure you want to manually submit this claim?",
  manualSubmitSecondaryConfirmation:
    'This will update the status of your claim to "Sent" and be ready for EOB payment processing.',
  skipPaymentConfirmation: "Are you sure you don't want to submit and process payment for this claim?",
  skipPaymentSecondaryConfirmation:
    'This will update the status of your claim to "Completed" and you can access skipped claims from the Claims tab to resubmit them at your convenience.',
  resubmitConfirmation: "Are you sure you want to resubmit this claim?",
  resubmitSecondaryConfirmation:
    "A replacement draft claim will be created which can then be submitted to the payer.",
};
