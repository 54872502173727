import { useMemo } from "react";
import { MessageVO } from "@libs/api/generated-api";
import { UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { TableGrid, HeaderCell } from "@libs/components/UI/GridTableComponents";

import { MessageRow } from "components/PatientProfile/Communication/MessageRow";

import EmptyCommunications from "assets/images/empty-communications.svg";

import { MessagesQuery } from "api/messaging/queries";

export type InfiniteMessagesQueryResult = UseInfiniteApiListQueryResult<MessageVO>;

interface Props {
  patientId: number;
  messageQueryType: MessagesQuery["type"];
  messagesQuery: InfiniteMessagesQueryResult;
  onRequestCancelScheduledMessage: (message: MessageVO) => void;
}

export const MessagesTable: React.FC<Props> = ({
  patientId,
  messageQueryType,
  messagesQuery,
  onRequestCancelScheduledMessage,
}) => {
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: messagesQuery });

  const headers = useMemo(() => {
    return [
      { id: "date", text: "Date", width: "10rem" },
      { id: "type", text: "Type", width: "max-content" },
      { id: "status", text: "Status", width: "6rem" },
      {
        id: "sender",
        text: "To/From",
        width: "minmax(6rem, max-content)",
      },
      { id: "message", text: messageQueryType === "CALL" ? "" : "Message", width: "1fr" },
      { id: "attachments", text: messageQueryType === "CALL" ? "" : "Attachments", width: "max-content" },
      { id: "action", text: "", width: "max-content" },
    ];
  }, [messageQueryType]);

  return (
    <PersistScrollPosition ref={rootRef} id="messages-table" className="h-full overflow-y-auto">
      <TableGrid columnWidths={headers.map(({ width }) => width)}>
        {headers.map(({ id, text }) => (
          <HeaderCell key={id} size="medium" sticky>
            {text}
          </HeaderCell>
        ))}

        <ScrollableInfiniteQueryResult
          scrollRef={scrollRef}
          infiniteQuery={messagesQuery}
          loading={
            <div className="col-span-full p-4">
              <LoadingContent />
            </div>
          }
          noResults={
            <div className="col-span-full flex flex-col items-center gap-y-5 p-20">
              <img src={EmptyCommunications} alt="No Communications" />
              <p className="text-sm">No Past Communications</p>
            </div>
          }
        >
          {messagesQuery.data?.pages.map(({ data }) =>
            data.map((message) => (
              <MessageRow
                key={message.id}
                patientId={patientId}
                message={message}
                onRequestCancelScheduledMessage={onRequestCancelScheduledMessage}
              />
            ))
          )}
        </ScrollableInfiniteQueryResult>
      </TableGrid>
    </PersistScrollPosition>
  );
};
