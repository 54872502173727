import { FC, useState, useMemo } from "react";
import { produce } from "immer";

import { PatientListRequest } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { useAccount } from "@libs/contexts/AccountContext";
import { Panel } from "@libs/components/UI/Panel";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { SortDirection } from "@libs/components/UI/GridTableComponents";

import { CampaignGroupTable } from "components/Communications/Campaigns/Campaign/CampaignGroupTable";

import { getMessageCampaign } from "api/communications/queries";
import { getInfinitePatientsV2 } from "api/patients/queries";

import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";

import { paths } from "utils/routing/paths";

export const CampaignGroupRoute: FC = () => {
  const { practiceId } = useAccount();
  const { campaignUuid } = usePathParams("campaignGroup");
  const { query } = useQueryParams("campaignGroup");
  const from = query.from ?? paths.campaign({ campaignUuid });

  usePageTitle("Campaign");

  const [orderBy, setOrderBy] = useState<SortDirection>("ASCENDING");

  const [messageCampaignQuery] = useApiQueries([
    getMessageCampaign({ args: { practiceId, messageCampaignUuid: campaignUuid } }),
  ]);

  const patientsQueryArgs = useMemo(() => {
    if (
      messageCampaignQuery.data &&
      messageCampaignQuery.data.filter?.type === "PATIENT_LIST" &&
      messageCampaignQuery.data.filter.patientListCriteria
    ) {
      const { maxPageSize, pageNumber, pageSize, ...data } =
        messageCampaignQuery.data.filter.patientListCriteria;

      return {
        maxPageSize,
        pageNumber,
        pageSize,
        data: produce(data, (draft) => {
          draft.orderBy = orderBy;
        }),
      };
    }

    return {
      pageNumber: 1,
      pageSize: PAGE_SIZE,
      data: {} as PatientListRequest,
    };
  }, [messageCampaignQuery.data, orderBy]);

  const patientsQuery = useInfiniteApiQuery(
    getInfinitePatientsV2({
      args: {
        practiceId,
        ...patientsQueryArgs,
      },
      queryOptions: {
        enabled: Boolean(patientsQueryArgs),
      },
    })
  );

  const handleSortChange = () => setOrderBy((last) => (last === "ASCENDING" ? "DESCENDING" : "ASCENDING"));

  return (
    <Panel
      title="Group Members"
      className="h-full"
      includePadding={false}
      actions={
        <LinkIcon
          SvgIcon={CloseIcon}
          tooltip={{ content: "Close", theme: "SMALL" }}
          to={from}
          theme="primary"
          replace
        />
      }
    >
      <QueryResult queries={[messageCampaignQuery]}>
        {patientsQueryArgs.data.orderBy ? (
          <CampaignGroupTable
            patientsQuery={patientsQuery}
            sortDirection={patientsQueryArgs.data.orderBy as SortDirection}
            onSortChange={handleSortChange}
          />
        ) : null}
      </QueryResult>
    </Panel>
  );
};
