import { FC, RefObject, useCallback } from "react";
import EmailEditor, { EditorRef, EmailEditorProps, Editor } from "react-email-editor";

import { parseEmailTemplateJson } from "components/Communications/Campaigns/EmailEditor/utils";

import { useEditorUpdates } from "components/Communications/Campaigns/EmailEditor/hooks";

interface Props {
  editorRef: RefObject<EditorRef>;
  emailTemplateJson: string;
  options: EmailEditorProps["options"];
  onUpdate?: (data: unknown) => void;
}

export const EmailEditorEmbed: FC<Props> = ({ editorRef, emailTemplateJson, options, onUpdate }) => {
  const { handleEditorReady } = useEditorUpdates(editorRef, onUpdate);

  const handleLoad: EmailEditorProps["onLoad"] = useCallback(
    (editor: Editor) => {
      const jsonTemplate = parseEmailTemplateJson(emailTemplateJson);

      if (jsonTemplate) {
        editor.loadDesign(jsonTemplate);
      }
    },
    [emailTemplateJson]
  );

  const handleReady: EmailEditorProps["onReady"] = useCallback(
    () => handleEditorReady(),
    [handleEditorReady]
  );

  return (
    <EmailEditor
      ref={editorRef}
      options={options}
      onLoad={handleLoad}
      onReady={handleReady}
      minHeight="100%"
    />
  );
};
