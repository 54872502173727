import { FC } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import {
  getInfiniteAppointmentsQuery,
  getPatientAppointmentsQuery,
  getPatientRecallsQuery,
  getPatientQuery,
  getFamilyMembersV2Query,
} from "api/patients/queries";
import { AppointmentsPanel } from "components/PatientProfile/PatientOverview/Appointments/AppointmentsPanel";
import { Family } from "components/PatientProfile/PatientOverview/FamilyMembers/Family";
import { PatientAppHistoryProvider } from "contexts/PatientLinksContext";
import { useNullablePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { RecallFlyover } from "components/PatientProfile/PatientOverview/Recall/RecallFlyover";
import { routesConfig } from "utils/routing/paths";
import { PatientInformationEditRoute } from "components/PatientProfile/PatientOverview/Info/PatientInformationEditRoute";
import { PatientInformationReadOnlyRoute } from "components/PatientProfile/PatientOverview/Info/PatientInformationReadOnlyRoute";
import { usePatientAppointmentContext } from "contexts/PatientAppointmentContext";

export const PatientOverview: FC = () => {
  const patientPathParams = useNullablePathParams("patient");
  const editPatientPathParams = useNullablePathParams("editPatient");
  const patientQueryParams = useQueryParams("patient");
  const editPatientQueryParams = useQueryParams("editPatient");
  const location = useLocation();

  const patientId = (patientPathParams?.patientId ?? editPatientPathParams?.patientId) as number;
  const recall = patientQueryParams.query.recall || editPatientQueryParams.query.recall;
  const updateQuery = patientPathParams ? patientQueryParams.updateQuery : editPatientQueryParams.updateQuery;

  const { practiceId } = useAccount();
  const [patientQuery, familyMembersQuery, noShowQuery, canceledQuery, recallsQuery] = useApiQueries([
    getPatientQuery({ args: { patientId, practiceId, includeContactPatientDetails: true } }),
    getFamilyMembersV2Query({
      args: { patientId, practiceId },
    }),

    getPatientAppointmentsQuery({
      args: { includeStates: ["NO_SHOW"], patientId, practiceId, pageSize: 1, pageNumber: 1 },
    }),
    getPatientAppointmentsQuery({
      args: { includeStates: ["CANCELED"], patientId, practiceId, pageSize: 1, pageNumber: 1 },
    }),
    getPatientRecallsQuery({ args: { practiceId, patientId } }),
  ]);

  const appointmentsQuery = useInfiniteApiQuery(
    getInfiniteAppointmentsQuery({
      args: { patientId, practiceId, pageSize: 25, pageNumber: 1 },
    })
  );

  const { deleteAppointment } = usePatientAppointmentContext();

  const handleOpenRecall = () => {
    updateQuery("replaceIn", { recall: true });
  };

  const handleCloseRecall = () => {
    updateQuery("replaceIn", { recall: false });
  };

  return (
    <PatientAppHistoryProvider patientId={patientId} name="overview">
      <div className="h-full flex">
        <div className="flex-1 h-full border-r border-slate-400">
          {matchPath(routesConfig.editPatient.path, location.pathname) ? (
            <PatientInformationEditRoute />
          ) : (
            <PatientInformationReadOnlyRoute />
          )}
        </div>
        <div className="flex-1 flex flex-col h-full">
          <div className="flex-none max-h-[50%] border-b border-slate-400" data-testid="family-members-table">
            <Family
              familyMembersQuery={familyMembersQuery}
              patientId={patientId}
              patientQuery={patientQuery}
            />
          </div>
          <div className="flex-1 min-h-0">
            <AppointmentsPanel
              appointmentsQuery={appointmentsQuery}
              canceledQuery={canceledQuery}
              noShowQuery={noShowQuery}
              onRequestOpenRecall={handleOpenRecall}
              onAppointmentDeleted={deleteAppointment}
              patientId={patientId}
              recallsQuery={recallsQuery}
            />
          </div>
        </div>
      </div>
      {recall && <RecallFlyover patientId={patientId} onClose={handleCloseRecall} />}
    </PatientAppHistoryProvider>
  );
};
