import { FC, useMemo } from "react";
import { SingleValue } from "react-select";
import { CustomAdjustmentTypeVO } from "@libs/api/generated-api";
import { FormFieldSelect } from "components/UI/FormFieldSelect";

type AdjustmentOption = {
  label: string;
  value: CustomAdjustmentTypeVO["id"];
  type: CustomAdjustmentTypeVO["entryType"];
};
type AdjustmentGroup = { label: "Discounts" | "Additional Charges"; options: AdjustmentOption[] };
type AdjustmentOptions = AdjustmentGroup[];

export const FormFieldSelectAdjustments: FC<{
  adjustments: CustomAdjustmentTypeVO[];
  value?: CustomAdjustmentTypeVO["id"];
  showArchived: "NO" | "ONLY_SELECTED" | "YES";
  required: boolean;
  onChange: (option: SingleValue<AdjustmentOption>) => void;
  error?: string;
  className?: string;
}> = ({ adjustments, showArchived, value, required, onChange, error, className }) => {
  const adjustmentOptions: AdjustmentOptions = useMemo(() => {
    const discounts: AdjustmentGroup = {
      label: "Discounts",
      options: [],
    };
    const charges: AdjustmentGroup = {
      label: "Additional Charges",
      options: [],
    };

    adjustments.forEach((adjustment) => {
      const option = { label: adjustment.name, value: adjustment.id, type: adjustment.entryType };

      if (adjustment.isArchived && showArchived === "NO") {
        return;
      }

      if (adjustment.isArchived && showArchived === "ONLY_SELECTED" && adjustment.id !== value) {
        return;
      }

      if (adjustment.entryType === "CREDIT") {
        discounts.options.push(option);
      } else {
        charges.options.push(option);
      }
    });

    return [charges, discounts];
  }, [adjustments, value, showArchived]);

  return (
    <FormFieldSelect
      label="Adjustment Type"
      required={required}
      layout="labelIn"
      options={adjustmentOptions}
      value={value}
      error={error}
      onChange={onChange}
      className={className}
    />
  );
};
