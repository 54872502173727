import { FC } from "react";
import { LabCaseReturnReasonVO, LabCaseTypeVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { ButtonCell, HeaderCell, IconsCell, Row, TableGrid } from "@libs/components/UI/GridTableComponents";

export const LabCaseReturnReasonsTable: React.FC<{
  returnReasons: LabCaseReturnReasonVO[];
  onEdit: (labCaseTypeUuid: LabCaseTypeVO["uuid"]) => void;
  onDelete: (labCaseTypeUuid: LabCaseTypeVO["uuid"]) => void;
}> = ({ returnReasons, onEdit, onDelete }) => {
  return (
    <TableGrid columnWidths={["1fr", "auto"]}>
      <Headers />
      {returnReasons.map((returnReason) => (
        <Row highlightOnHover key={returnReason.uuid}>
          <ButtonCell
            onDoubleClick={() => onEdit(returnReason.uuid)}
            verticalPadding="skinny"
            className="flex items-center h-10"
          >
            {returnReason.reason}
          </ButtonCell>
          <IconsCell verticalPadding="skinny" className="flex items-center h-10">
            <Menu onEdit={() => onEdit(returnReason.uuid)} onDelete={() => onDelete(returnReason.uuid)} />
          </IconsCell>
        </Row>
      ))}
    </TableGrid>
  );
};

const Headers: FC = () => (
  <>
    <HeaderCell size="short">Name</HeaderCell>
    {/* menu column */}
    <HeaderCell size="short" />
  </>
);

const Menu: React.FC<{ onEdit: Func; onDelete: Func }> = ({ onEdit, onDelete }) => {
  const menu = useBoolean(false);

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      menuContent={
        <MenuOptions
          options={createMenuOptions(
            { label: "Edit", value: "edit", SvgIcon: EditIcon },
            { label: "Delete", value: "delete", SvgIcon: DeleteIcon }
          )}
          onOptionClick={(option) => {
            switch (option.value) {
              case "delete": {
                onDelete();
                break;
              }
              case "edit": {
                onEdit();
                break;
              }
              // no default
            }
          }}
        />
      }
      onRequestClose={menu.off}
    >
      {(props) => {
        return <ButtonIcon {...props} size="md" SvgIcon={MenuIcon} />;
      }}
    </ButtonMenu>
  );
};
