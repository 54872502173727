import {
  CreateTagRequest,
  UpdateTagRequest,
  UpdateAlertSettingsRequest,
  AlertSettingsVO,
  TagVO,
} from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import { updateCachedData } from "@libs/utils/queryCache";
import { produce } from "immer";
import { trackIniatedWebSocketEvents } from "storage/initiatedWebSocketEventTimestamps";

export const createTag = makeMutation({
  mutationKey: ["practices", "createTag"],
  formatParams: (args: { practiceId: number; data: CreateTagRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getTags"), { practiceId }]);
    },
  }),
});

export const toggleTagArchive = makeMutation({
  mutationKey: ["practices", "updateTag"],
  formatParams: (args: { practiceId: number; tagId: number; data: UpdateTagRequest }) => [
    args.practiceId,
    args.tagId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onMutate: ({ practiceId, tagId, data }) => {
      updateCachedData<TagVO[]>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getTags"), { practiceId, includeArchived: true }],
        },
        (tags) =>
          tags.map((currentTag) =>
            currentTag.id === tagId
              ? {
                  ...currentTag,
                  isArchived: data.isArchived,
                }
              : currentTag
          )
      );

      if (data.isArchived) {
        updateCachedData<TagVO[]>(
          queryClient,
          {
            queryKey: [getQueryKey("practices", "getTags"), { practiceId, includeArchived: undefined }],
          },
          (tags) => tags.filter(({ id }) => id !== tagId)
        );
      }
    },
    onError: (_, { practiceId }) => {
      // rollback optimistic updates
      queryClient.invalidateQueries([getQueryKey("practices", "getTags"), { practiceId }]);
    },
    onSuccess: (_, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getTags"), { practiceId }]);
    },
  }),
});

export const updateTag = makeMutation({
  mutationKey: ["practices", "updateTag"],
  formatParams: (args: { practiceId: number; tagId: number; data: UpdateTagRequest }) => [
    args.practiceId,
    args.tagId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getTags"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getAlertSettings"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientNoteAlerts"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientNote"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("v2", "getPatientNotesV2"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientNoteAuditHistory"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getAppointmentClinicalNote"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getAppointment"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getNextPatientAppointment"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getAppointmentCards"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getDailyHuddle"), { practiceId }]);
    },
  }),
});

export const updateAlertSettings = makeMutation({
  mutationKey: ["practices", "updateAlertSettings"],
  formatParams: (args: { practiceId: number; data: UpdateAlertSettingsRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onMutate: ({ practiceId, data }) => {
      updateCachedData<AlertSettingsVO>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getAlertSettings"), { practiceId }],
        },
        (oldData) => {
          return produce(oldData, (draft) => {
            draft.appearance = data.appearance;
            for (const alertTag of draft.appointmentAutoTags) {
              alertTag.isEnabled = data.enabledAppointmentAutoTagIds.includes(alertTag.tag.id);
            }
            for (const alertTag of draft.patientAutoTags) {
              alertTag.isEnabled = data.enabledPatientAutoTagIds.includes(alertTag.tag.id);
            }
          });
        }
      );
    },
    onError: (_, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getAlertSettings"), { practiceId }]);
    },
    onSuccess: (response, { practiceId }) => {
      trackIniatedWebSocketEvents("APPOINTMENT_ALERT_SETTINGS_UPDATED");
      // This mutation does some async BE work. The
      // isPatientAutoAlertNoteJobInProgress value returned allows
      // the UI to indicate that the job is still in progress.
      updateCachedData<AlertSettingsVO>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getAlertSettings"), { practiceId }],
        },
        () => {
          return response.data.data;
        }
      );
    },
  }),
});
