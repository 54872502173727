import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { useFullUrl } from "@libs/utils/location";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { useAccount } from "@libs/contexts/AccountContext";
import { Panel } from "@libs/components/UI/Panel";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { paths } from "utils/routing/paths";
import { getPracticeRolesV2 } from "api/practice/queries";
import { DeleteRoleModal } from "components/Roles/DeleteRoleModal";
import { useItemModal } from "hooks/useItemModal";
import { deleteRoleV2 } from "api/practice/mutations";
import { handleError } from "utils/handleError";
import { RoleHandler, LoadingSkeleton, RolesTable } from "./RolesTableComponents";

export const RolesRoute: FC = () => {
  const { practiceId } = useAccount();
  const [practiceRolesQuery] = useApiQueries([getPracticeRolesV2({ args: { practiceId } })]);
  const fullUrl = useFullUrl();
  const navigate = useNavigate();
  const [deleteRole] = useApiMutations([deleteRoleV2]);

  const deleteModal = useItemModal<number>(null);

  const handleEdit: RoleHandler = useCallback(
    (role) => navigate(paths.roleEdit({ roleId: role.roleId }, { from: fullUrl })),
    [fullUrl, navigate]
  );

  const handleCopy: RoleHandler = useCallback(
    (role) => navigate(paths.roleCopy({ roleId: role.roleId }, { from: fullUrl })),
    [fullUrl, navigate]
  );

  const handleDelete = useCallback(
    (roleId: number, replacementRoleId?: number) => {
      deleteRole.mutate(
        { practiceId, roleId, data: { replacementRoleId } },
        { onSuccess: deleteModal.close, onError: handleError }
      );
    },
    [deleteModal, deleteRole, practiceId]
  );

  return (
    <Panel
      title={
        <div className="flex gap-x-2 items-center">
          Roles
          <ButtonInternalLink theme="link" to={paths.roleCreate({ from: fullUrl })}>
            <Icon SvgIcon={AddIcon} />
          </ButtonInternalLink>
        </div>
      }
      includePadding={false}
    >
      <QueryResult queries={[practiceRolesQuery]} loading={<LoadingSkeleton />}>
        {practiceRolesQuery.data && (
          <RolesTable
            roles={practiceRolesQuery.data}
            onRowClick={handleEdit}
            onEditClick={handleEdit}
            onCopyClick={handleCopy}
            // If no employees are tied to this role, delete immediately, otherwise open the delete
            // modal to list employees affected and ask the user for a replacement role.
            onDeleteClick={(role, employees) =>
              employees.length > 0 ? deleteModal.open(role.roleId) : handleDelete(role.roleId)
            }
          />
        )}
      </QueryResult>
      {deleteModal.isOpen && (
        <DeleteRoleModal
          roleId={deleteModal.item}
          onClose={deleteModal.close}
          onConfirm={handleDelete}
          isDeleting={deleteRole.isLoading}
        />
      )}
    </Panel>
  );
};
