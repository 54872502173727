import {
  LabCaseRequest,
  LabCaseReturnReasonRequest,
  LabCaseStatusRequest,
  LabCaseTypeRequest,
  LabRequest,
} from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import { invalidateProceduresAndTreatmentPlan } from "api/charting/mutations";
import {
  handlePatientAppointmentsUpdated,
  handlePracticeAppointmentsUpdated,
} from "api/websocket/handleScheduleUpdated";

export const createLab = makeMutation({
  mutationKey: ["practices", "createLab"],
  formatParams: (args: { practiceId: number; data: LabRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getLabs"), { practiceId }]);
    },
  }),
});

export const updateLab = makeMutation({
  mutationKey: ["practices", "updateLab"],
  formatParams: (args: { practiceId: number; labUuid: string; data: LabRequest }) => [
    args.practiceId,
    args.labUuid,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, labUuid, data: { orderFormFile } }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getLab"), { practiceId, labUuid }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLabs"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCases"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCase"), { practiceId }]);

      // If the order form file was updated, invalidate the printLabOrderForm query
      if (orderFormFile) {
        queryClient.invalidateQueries([
          getQueryKey("practices", "printLabOrderForm"),
          { practiceId, labUuid },
        ]);
      }
    },
  }),
});

export const archiveLab = makeMutation({
  mutationKey: ["practices", "archiveLab"],
  formatParams: (args: { practiceId: number; labUuid: string }) => [args.practiceId, args.labUuid],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, labUuid }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getLab"), { practiceId, labUuid }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLabs"), { practiceId }]);
    },
  }),
});

export const unarchiveLab = makeMutation({
  mutationKey: ["practices", "unarchiveLab"],
  formatParams: (args: { practiceId: number; labUuid: string }) => [args.practiceId, args.labUuid],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, labUuid }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getLab"), { practiceId, labUuid }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLabs"), { practiceId }]);
    },
  }),
});

export const createLabCaseType = makeMutation({
  mutationKey: ["practices", "createLabCaseType"],
  formatParams: (args: { practiceId: number; data: LabCaseTypeRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCaseTypes"), { practiceId }]);
    },
  }),
});

export const updateLabCaseType = makeMutation({
  mutationKey: ["practices", "updateLabCaseType"],
  formatParams: (args: { practiceId: number; labCaseTypeUuid: string; data: LabCaseTypeRequest }) => [
    args.practiceId,
    args.labCaseTypeUuid,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, labCaseTypeUuid }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCaseTypes"), { practiceId }]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getLabCaseType"),
        { practiceId, labCaseTypeUuid },
      ]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCases"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCase"), { practiceId }]);
    },
  }),
});

export const deleteLabCaseType = makeMutation({
  mutationKey: ["practices", "deleteLabCaseType"],
  formatParams: (args: { practiceId: number; labCaseTypeUuid: string }) => [
    args.practiceId,
    args.labCaseTypeUuid,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, labCaseTypeUuid }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCaseTypes"), { practiceId }]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getLabCaseType"),
        { practiceId, labCaseTypeUuid },
      ]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCases"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCase"), { practiceId }]);
    },
  }),
});

export const createLabCaseReturnReason = makeMutation({
  mutationKey: ["practices", "createLabCaseReturnReason"],
  formatParams: (args: { practiceId: number; data: LabCaseReturnReasonRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCaseReturnReasons"), { practiceId }]);
    },
  }),
});

export const deleteLabCaseReturnReason = makeMutation({
  mutationKey: ["practices", "deleteLabCaseReturnReason"],
  formatParams: (args: { practiceId: number; labCaseReturnReasonUuid: string }) => [
    args.practiceId,
    args.labCaseReturnReasonUuid,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, labCaseReturnReasonUuid }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCaseReturnReasons"), { practiceId }]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getLabCaseReturnReason"),
        { practiceId, labCaseReturnReasonUuid },
      ]);
    },
  }),
});

export const updateLabCaseReturnReason = makeMutation({
  mutationKey: ["practices", "updateLabCaseReturnReason"],
  formatParams: (args: {
    practiceId: number;
    labCaseReturnReasonUuid: string;
    data: LabCaseReturnReasonRequest;
  }) => [args.practiceId, args.labCaseReturnReasonUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, labCaseReturnReasonUuid }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCaseReturnReasons"), { practiceId }]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getLabCaseReturnReason"),
        { practiceId, labCaseReturnReasonUuid },
      ]);
    },
  }),
});

export const createLabCase = makeMutation({
  mutationKey: ["practices", "createLabCase"],
  formatParams: (args: { practiceId: number; data: LabCaseRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, data: { patientId } }) => {
      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId, patientId });
      handlePracticeAppointmentsUpdated({ queryClient, practiceId });
      handlePatientAppointmentsUpdated({ queryClient, practiceId, patientId });
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCases"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCaseTypes"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCaseType"), { practiceId }]);
    },
  }),
});

export const updateLabCase = makeMutation({
  mutationKey: ["practices", "updateLabCase"],
  formatParams: (args: { practiceId: number; labCaseUuid: string; data: LabCaseRequest }) => [
    args.practiceId,
    args.labCaseUuid,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, labCaseUuid, data: { patientId } }) => {
      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId, patientId });
      handlePracticeAppointmentsUpdated({ queryClient, practiceId });
      handlePatientAppointmentsUpdated({ queryClient, practiceId, patientId });
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCases"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCase"), { practiceId, labCaseUuid }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCaseTypes"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCaseType"), { practiceId }]);
    },
  }),
});

export const archiveLabCase = makeMutation({
  mutationKey: ["practices", "archiveLabCase"],
  formatParams: (args: { practiceId: number; labCaseUuid: string }) => [args.practiceId, args.labCaseUuid],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, labCaseUuid }) => {
      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId });
      handlePracticeAppointmentsUpdated({ queryClient, practiceId });
      handlePatientAppointmentsUpdated({ queryClient, practiceId });
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCases"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCase"), { practiceId, labCaseUuid }]);
    },
  }),
});

export const unarchiveLabCase = makeMutation({
  mutationKey: ["practices", "unarchiveLabCase"],
  formatParams: (args: { practiceId: number; labCaseUuid: string }) => [args.practiceId, args.labCaseUuid],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, labCaseUuid }) => {
      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId });
      handlePracticeAppointmentsUpdated({ queryClient, practiceId });
      handlePatientAppointmentsUpdated({ queryClient, practiceId });
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCases"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCase"), { practiceId, labCaseUuid }]);
    },
  }),
});

export const updateLabCaseStatus = makeMutation({
  mutationKey: ["practices", "updateLabCaseStatus"],
  formatParams: (args: { practiceId: number; labCaseUuid: string; data: LabCaseStatusRequest }) => [
    args.practiceId,
    args.labCaseUuid,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, labCaseUuid }) => {
      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId });
      handlePracticeAppointmentsUpdated({ queryClient, practiceId });
      handlePatientAppointmentsUpdated({ queryClient, practiceId });
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCases"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLabCase"), { practiceId, labCaseUuid }]);
    },
  }),
});
