import React, { useState } from "react";
import { DentalProcedureFeeVO, DentalProcedureVO, FeeScheduleListVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { groupBy } from "utils/groupBy";
import { SubcategoryProcedures } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/FeeCategoryTable/SubcategoryProcedures";
import { getFeeScheduleDetails } from "api/feeSchedule/queries";
import { getDentalProceduresQuery } from "api/charting/queries";
import { Headers } from "./TableItems";

type Props = {
  feeScheduleName?: string;
  feeScheduleDetailProcedureFees: Partial<DentalProcedureFeeVO>[];
  feeSchedulesQuery: UseInfiniteApiListQueryResult<FeeScheduleListVO>;
  procedures: DentalProcedureVO[];
  category: string;
  isPracticeUcr: boolean;
  onFeeUpdated: (fee: DentalProcedureFeeVO) => void;
};

type DefinedCategoryItem = Omit<DentalProcedureVO, "subCategory"> & {
  subCategory: string;
};
export const FeeCategoryTable: React.FC<Props> = React.memo(
  ({
    feeScheduleName,
    feeScheduleDetailProcedureFees,
    feeSchedulesQuery,
    isPracticeUcr,
    category,
    procedures,
    onFeeUpdated,
  }) => {
    const { practiceId } = useAccount();

    // Must fetch specific procedure to have ucrFee attached
    const fetchUcrFees = true;
    const [compareFeeScheduleId, setCompareFeeScheduleId] = useState<number | undefined>();
    const [comparedFeeScheduleQuery, proceduresForCategory] = useApiQueries([
      getFeeScheduleDetails({
        args: { practiceId, feeScheduleId: compareFeeScheduleId ?? 0 },
        queryOptions: { enabled: Boolean(compareFeeScheduleId) },
      }),
      getDentalProceduresQuery({ args: { practiceId, category, fetchUcrFees } }),
    ]);

    const subCategoryProcedures = React.useMemo(() => {
      const filtered = new Set(procedures.map((item) => item.id));
      const filteredProcedures = proceduresForCategory.data?.filter((item) => filtered.has(item.id)) ?? [];
      const inCategory: DefinedCategoryItem[] = filteredProcedures
        .filter((item) => item.categoryType === category)
        .map((item) => ({ ...item, subCategory: item.subCategory || "Unspecified" }));
      const groupedItems = groupBy(inCategory, "subCategory");

      return Object.keys(groupedItems).map((key) => {
        return {
          subCategory: key,
          procedures: groupedItems[key] as DentalProcedureVO[],
        };
      });
    }, [procedures, proceduresForCategory.data, category]);

    return (
      <div
        className="grid border-x border-greyLighter border-t"
        style={{
          gridTemplateColumns: isPracticeUcr
            ? `6rem 4fr 1fr minmax(0, 10rem) minmax(0, 10rem)`
            : `6rem 4fr 1fr 6rem minmax(0, 10rem) minmax(0, 10rem)`,
        }}
      >
        <Headers
          comparedFeeScheduleQuery={comparedFeeScheduleQuery}
          feeScheduleName={feeScheduleName}
          feeSchedulesQuery={feeSchedulesQuery}
          isPracticeUcr={isPracticeUcr}
          onSelectCompareFeeSchedule={setCompareFeeScheduleId}
          selectedCompareFeeScheduleId={compareFeeScheduleId}
        />
        {subCategoryProcedures.map(({ subCategory, procedures: subcategoryProcedures }) => (
          <SubcategoryProcedures
            comparedFeeScheduleQuery={comparedFeeScheduleQuery}
            feeScheduleDetailProcedureFees={feeScheduleDetailProcedureFees}
            isPracticeUcr={isPracticeUcr}
            key={subCategory}
            onFeeUpdated={onFeeUpdated}
            procedures={subcategoryProcedures}
            subCategory={subCategory}
          />
        ))}
      </div>
    );
  }
);
