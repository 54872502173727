import { FC } from "react";
import { LabVO } from "@libs/api/generated-api";
import { formatAddress } from "@libs/utils/address";
import {
  abbreviatedDayOfWeek,
  formatISOTimeAsAmPm,
  formatISOTimeRangeAsAmPm,
  getDaysOfWeekRanges,
} from "@libs/utils/date";
import { formatPhoneNumber } from "@libs/utils/phone";
import { ReactComponent as ExternalLinkIcon } from "@libs/assets/icons/external-link.svg";

export const LabInfoCard: FC<{ lab: LabVO }> = ({ lab }) => {
  return (
    <div className="flex flex-col gap-y-4">
      <div className="text-sm font-sansSemiBold">{lab.name}</div>
      {lab.contactPerson || lab.openDaysOfWeek || lab.openTime || lab.closeTime || lab.shippingMethod ? (
        <LabInfoDetails
          contactPerson={lab.contactPerson}
          openDaysOfWeek={lab.openDaysOfWeek}
          openTime={lab.openTime}
          closeTime={lab.closeTime}
          shippingMethod={lab.shippingMethod}
        />
      ) : null}
      {lab.address || lab.phone || lab.fax ? (
        <LabInfoContacts address={lab.address} phone={lab.phone} fax={lab.fax} />
      ) : null}
      {lab.website ? <LabWebSiteLink website={lab.website} /> : null}
    </div>
  );
};

const LabInfoDetails: FC<
  Pick<LabVO, "contactPerson" | "openDaysOfWeek" | "openTime" | "closeTime" | "shippingMethod">
> = ({ contactPerson, openDaysOfWeek, openTime, closeTime, shippingMethod }) => {
  return (
    <div className="flex flex-col gap-y-2">
      {contactPerson && <div className="text-sm">Contact: {contactPerson}</div>}
      {openDaysOfWeek && (
        <div className="text-sm">
          Open:{" "}
          {getDaysOfWeekRanges(openDaysOfWeek, (start, end) => {
            if (end == null) {
              return abbreviatedDayOfWeek(start);
            }

            return `${abbreviatedDayOfWeek(start)}-${abbreviatedDayOfWeek(end)}`;
          }).join(", ")}
        </div>
      )}
      {openTime || closeTime ? (
        <div className="text-sm">
          Hours:{" "}
          {openTime && closeTime
            ? formatISOTimeRangeAsAmPm(openTime, closeTime)
            : openTime
              ? formatISOTimeAsAmPm(openTime)
              : closeTime
                ? formatISOTimeAsAmPm(closeTime)
                : null}
        </div>
      ) : null}
      {shippingMethod && <div className="text-sm">Shipping: {shippingMethod}</div>}
    </div>
  );
};

const LabInfoContacts: FC<Pick<LabVO, "address" | "phone" | "fax">> = ({ address, phone, fax }) => {
  const formattedAddress = formatAddress(address);

  return (
    <div className="flex flex-col gap-y-2">
      {formattedAddress.lineOne ? (
        <div className="flex flex-col">
          <div className="text-sm">{formattedAddress.lineOne}</div>
          {formattedAddress.lineTwo && <div className="text-sm">{formattedAddress.lineTwo}</div>}
        </div>
      ) : null}
      {phone && <div className="text-sm">Phone: {formatPhoneNumber(phone)}</div>}
      {fax && <div className="text-sm">Fax: {formatPhoneNumber(fax)}</div>}
    </div>
  );
};

const LabWebSiteLink: FC<{ website: string }> = ({ website }) => {
  let domainName = website;

  if (website) {
    try {
      domainName = new URL(website).hostname;
    } catch {
      // URL is likely invalid, do nothing
    }
  }

  return (
    <a
      href={website}
      target="_blank"
      title={website}
      className={`
        text-sm
        text-archyBlue-500
        font-sansSemiBold
        flex
        items-center
        gap-x-2
      `}
    >
      <span className="truncate">{domainName}</span>{" "}
      <div className="h-5 w-5">
        <ExternalLinkIcon className="h-5 w-5" />
      </div>
    </a>
  );
};
