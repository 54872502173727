import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { DentalProcedureFeeVO, FeeScheduleVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isDefined } from "@libs/utils/types";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";
import { useIsDirty } from "hooks/useIsDirty";

export type EditableFeeSchedule = Partial<FeeScheduleVO> & {
  dentalProcedureFees: Partial<DentalProcedureFeeVO>[];
  carrierIds: number[];
  providerIds: number[];
};

const INITIAL_STATE = {
  carrierIds: [],
  dentalProcedureFees: [],
  isProviderUcr: true,
  name: "",
  providerIds: [],
};

export const useEditableFeeSchedule = (
  feeSchedule: FeeScheduleVO | undefined,
  isCreating: boolean,
  isCopying: boolean
) => {
  const [editableFeeSchedule, setEditableFeeSchedule] = useState<EditableFeeSchedule | undefined>(undefined);

  const handleUpdateEditableFeeSchedule = useCallback((updates: Partial<EditableFeeSchedule>) => {
    setEditableFeeSchedule((last) => (last ? { ...last, ...updates } : { ...INITIAL_STATE, ...updates }));
  }, []);

  const hasLoaded = useBoolean(false);
  const editing = useBoolean(false);

  useIsDirty(editableFeeSchedule, {
    onDirty: () => {
      if (hasLoaded.isOn && editableFeeSchedule) {
        editing.on();
      }
    },
  });

  const hasEditsToFees = useRef(false);

  useSyncOnce((loadedSchedule) => {
    handleUpdateEditableFeeSchedule(
      isCopying
        ? {
            ...loadedSchedule,
            carrierIds: [],
            dentalProcedureFees: loadedSchedule.dentalProcedureFees
              ? loadedSchedule.dentalProcedureFees.filter((item) => isDefined(item.fee))
              : [],
            id: undefined,
            isPracticeUcr: false,
            inNetwork: undefined,
            name: `${loadedSchedule.name} (Copy)`,
            providerIds: loadedSchedule.isProviderUcr ? [] : loadedSchedule.providerIds ?? [],
            providers: undefined,
          }
        : loadedSchedule
    );
  }, feeSchedule);

  const turnHasLoadedOn = hasLoaded.on;
  const hasSetEditableFeeSchedule = useMemo(() => editableFeeSchedule !== undefined, [editableFeeSchedule]);

  useEffect(() => {
    if (isCreating && !isCopying) {
      handleUpdateEditableFeeSchedule(INITIAL_STATE);
    } else if (hasSetEditableFeeSchedule) {
      turnHasLoadedOn();
    }
  }, [handleUpdateEditableFeeSchedule, hasSetEditableFeeSchedule, isCopying, isCreating, turnHasLoadedOn]);

  const handleFeeScheduleChange = useCallback(
    (fields: Partial<FeeScheduleVO>) => handleUpdateEditableFeeSchedule(fields),
    [handleUpdateEditableFeeSchedule]
  );

  const onFeeUpdated = useCallback(
    (newFee: DentalProcedureFeeVO) => {
      // Tracks whether they have edited fees, so we know to submit them.
      hasEditsToFees.current = true;

      const currentProcedure = editableFeeSchedule?.dentalProcedureFees.find(
        (item) => item.procedureId === newFee.procedureId
      );

      if (currentProcedure) {
        handleFeeScheduleChange({
          dentalProcedureFees: editableFeeSchedule?.dentalProcedureFees.map((item) => {
            if (item.procedureId === newFee.procedureId) {
              return newFee;
            }

            return item;
          }),
        });
      } else {
        handleFeeScheduleChange({
          dentalProcedureFees: [...(editableFeeSchedule?.dentalProcedureFees ?? []), newFee],
        });
      }
    },
    [handleFeeScheduleChange, editableFeeSchedule?.dentalProcedureFees]
  );

  const afterSave = useCallback(() => {
    hasEditsToFees.current = false;
    // Remove deleted procedures
    handleFeeScheduleChange({
      dentalProcedureFees: editableFeeSchedule?.dentalProcedureFees.filter((item) => item.fee !== undefined),
    });
  }, [handleFeeScheduleChange, editableFeeSchedule?.dentalProcedureFees]);

  return {
    editableFeeSchedule,
    handleFeeScheduleChange,
    hasEdits: editing.isOn,
    hasEditsToFees,
    onFeeScheduleSaved: afterSave,
    onFeeUpdated,
    turnOffEditing: editing.off,
  };
};
