import { useQuery } from "@tanstack/react-query";
import { SUCCESS } from "@libs/utils/statusCodes";
import { ImagingHttpApi } from "api/imaging/imaging-hub";

export const useOfflineImagesQuery = (query: { IncludeUploaded?: boolean }) => {
  return useQuery(
    ["archy-imaging-service-api", "search", query],
    () => {
      return ImagingHttpApi.images.search(query).then((res) => {
        if (res.status !== SUCCESS) {
          throw new Error(`Could not fetch offline images, status: ${res.status}`);
        }

        return res.data.data;
      });
    },
    {
      cacheTime: 0,
    }
  );
};
