import React, { useCallback, useMemo } from "react";
import {
  Axis, // any of these can be non-animated equivalents
  AnimatedGrid,
  TooltipDatum,
  XYChart,
  Tooltip,
  EventHandlerParams,
  buildChartTheme,
} from "@visx/xychart";
import { LabelFormatter } from "@visx/legend/lib/types";
import { ScaleOrdinal } from "d3-scale";
import { RenderTooltipParams } from "@visx/xychart/lib/components/Tooltip";
import { DefaultThresholdInput, ScaleConfig, StringLike } from "@visx/scale";
import { AxisScaleOutput } from "@visx/axis";
import { formatCurrency } from "@libs/utils/currency";
import { LineSeriesDatum } from "components/Dashboard/Charting/types";
import { ChartLegend } from "components/Dashboard/Charting/ChartLegend";
import { useYAxisFormatter } from "components/Dashboard/Charting/useYAxisFormatter";
import { DashboardChartTooltip } from "components/Dashboard/Charting/DashboardChartTooltip";
import {
  AXIS_LABEL_PROPS,
  GRID_ROWS_DEFAULTS,
  useBottomTickLabelProps,
  useLeftAxisTickProps,
} from "components/Dashboard/Charting/theme";
import { LineChartAnnotations } from "components/Dashboard/Charting/LineChartAnnotations";

type Props<T extends string> = {
  width: number;
  height: number;
  children: React.ReactNode;
  labelFormat: LabelFormatter<T>;
  colorScale: ScaleOrdinal<T, string, never>;
  numTicksXAxis?: number;
  isCurrency?: boolean;
  onClickDate: (params: { date: string; index: number }) => void;
  formatDate: (date: string) => string;
  innerRef: React.Ref<SVGSVGElement> | undefined;
  keys: T[];
  selectedDateIndex?: number;
  dottedDateIndex?: number;
  formatTooltipDate?: (date: string) => string;
  formatValue?: (val: number) => string;
};

const MINIMUM_WIDTH = 100;

type ScaleConfigDefault = ScaleConfig<AxisScaleOutput, StringLike, DefaultThresholdInput>;
type Datum = { date: string; value: number };

export const LineChartLayout = <T extends string>({
  width,
  height,
  children,
  labelFormat,
  colorScale,
  formatDate,
  keys,
  onClickDate,
  isCurrency = false,
  selectedDateIndex,
  dottedDateIndex,
  numTicksXAxis,
  formatTooltipDate,
  formatValue,
}: Props<T>) => {
  const yAxisFormatter = useYAxisFormatter({ formatValue, isCurrency });
  const bottomAxisLabelProps = useBottomTickLabelProps<string>({ clickable: true });
  const leftAxisLabelProps = useLeftAxisTickProps<number>();
  const handleLineChartClick = useCallback(
    (e: EventHandlerParams<Datum>) => {
      onClickDate({ date: e.datum.date, index: e.index });
    },
    [onClickDate]
  );
  const theme = useMemo(() => {
    return buildChartTheme({
      colors: colorScale.range(),
      backgroundColor: "white",
      tickLength: 3,
      htmlLabel: {},
      gridColor: GRID_ROWS_DEFAULTS.stroke,
      gridColorDark: GRID_ROWS_DEFAULTS.stroke,
    });
  }, [colorScale]);

  return width < MINIMUM_WIDTH ? null : (
    <>
      <XYChart<ScaleConfigDefault, ScaleConfigDefault, Datum>
        height={height}
        theme={theme}
        width={width}
        xScale={{ type: "band" }}
        yScale={{ type: "linear" }}
        onPointerUp={handleLineChartClick}
      >
        <Axis
          orientation="bottom"
          tickFormat={formatDate}
          tickLabelProps={bottomAxisLabelProps}
          hideTicks
          hideAxisLine
          labelProps={AXIS_LABEL_PROPS}
          numTicks={numTicksXAxis}
        />
        <Axis
          orientation="left"
          tickFormat={yAxisFormatter}
          tickLabelProps={leftAxisLabelProps}
          labelProps={AXIS_LABEL_PROPS}
          numTicks={GRID_ROWS_DEFAULTS.numTicks}
        />
        <AnimatedGrid columns={false} {...GRID_ROWS_DEFAULTS} />
        {formatTooltipDate && (
          <Tooltip
            snapTooltipToDatumX
            snapTooltipToDatumY
            showSeriesGlyphs
            showVerticalCrosshair
            renderTooltip={(params: RenderTooltipParams<LineSeriesDatum<T>>) => {
              const untypedDatumByKey = params.tooltipData?.datumByKey;

              if (untypedDatumByKey) {
                const datumByKey = untypedDatumByKey as unknown as Record<
                  T,
                  TooltipDatum<LineSeriesDatum<T>>
                >;
                const date = params.tooltipData?.nearestDatum?.datum.date;

                return (
                  <DashboardChartTooltip
                    formatValue={formatValue ?? (isCurrency ? formatCurrency : undefined)}
                    dateLabel={date ? formatTooltipDate(date) : undefined}
                    items={keys.map((revenueType, i) => {
                      return {
                        value: datumByKey[revenueType].datum.value,
                        label: `${labelFormat(revenueType, i) ?? ""}`,
                        color: colorScale(revenueType),
                      };
                    })}
                  />
                );
              }

              return null;
            }}
          />
        )}

        {children}
        <LineChartAnnotations
          selectedDateIndex={selectedDateIndex}
          dottedDateIndex={dottedDateIndex}
          onClickDate={onClickDate}
        />
      </XYChart>

      <ChartLegend scale={colorScale} labelFormat={labelFormat} />
    </>
  );
};
