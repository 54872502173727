import { FC, ReactNode } from "react";

import { cx } from "@libs/utils/cx";
import { ReactComponent as RightArrowIcon } from "@libs/assets/icons/right-arrow.svg";

const cxStyles = {
  textColor: (disabled: boolean) => (disabled ? "text-greyLight" : "text-black"),
  arrowColor: (disabled: boolean) => (disabled ? "text-greyLighter" : "text-primaryTheme"),
};

export const PayrollCard: FC<{
  primaryText: ReactNode;
  secondaryText?: ReactNode;
  warningText?: string;
  onClick?: Func;
  disabled?: boolean;
}> = ({ primaryText, secondaryText, warningText, onClick, disabled = false }) => {
  return (
    <div className="flex flex-col gap-y-3 max-w-sm">
      <button
        type="button"
        className={`
          flex
          items-center
          justify-between
          rounded
          border
          border-greyLighter
          bg-white
          gap-x-5
          p-5
        `}
        onClick={onClick}
        disabled={disabled || !onClick}
      >
        <div className={cx("flex flex-col items-start gap-y-1 text-left", cxStyles.textColor(disabled))}>
          <span className="font-sansSemiBold text-sm">{primaryText}</span>
          {secondaryText && <span className="font-sans text-xs">{secondaryText}</span>}
        </div>

        {onClick && <RightArrowIcon className={cx("w-6 h-6 flex-none", cxStyles.arrowColor(disabled))} />}
      </button>

      {warningText && !disabled && (
        <span className="font-sans text-xs text-greyDark">
          <span className="text-red">*&nbsp;</span>
          {warningText}
        </span>
      )}
    </div>
  );
};
