import { FC, Dispatch, SetStateAction, useState } from "react";
import { produce } from "immer";

import { PatientListCriteria } from "@libs/api/generated-api";
import { getLocalDate, formatAsISODate } from "@libs/utils/date";
import { Button } from "@libs/components/UI/Button";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";

import { FilterSection } from "components/Communications/Filters/FilterSection";

import { SelectDateRange } from "components/Dashboard/SelectDateRange";

type FilterSelection = "WITHIN" | "BETWEEN" | "NONE" | undefined;

interface Props
  extends Pick<PatientListCriteria, "appointmentWithinDays" | "appointmentDateRange" | "hasNoAppointment"> {
  onUpdatePatientListCriteria: Dispatch<SetStateAction<PatientListCriteria>>;
}

export const AppointmentFilterSection: FC<Props> = ({
  appointmentWithinDays,
  appointmentDateRange,
  hasNoAppointment,
  onUpdatePatientListCriteria,
}) => {
  const [filterSelection, setFilterSelection] = useState<FilterSelection>(() =>
    appointmentWithinDays == null
      ? appointmentDateRange == null
        ? hasNoAppointment == null
          ? undefined
          : "NONE"
        : "BETWEEN"
      : "WITHIN"
  );

  const handleClearAppointmentCriteria = () => {
    onUpdatePatientListCriteria((last) =>
      produce(last, (draft) => {
        delete draft.appointmentWithinDays;
        delete draft.appointmentDateRange;
        delete draft.hasNoAppointment;
      })
    );
  };

  return (
    <FilterSection
      title="Appointment"
      dataTestId="appointment-filter-section"
      isOpen={
        appointmentWithinDays != null ||
        appointmentDateRange?.min != null ||
        appointmentDateRange?.max != null ||
        hasNoAppointment != null
      }
    >
      <div className="flex flex-col gap-y-2">
        <div className="flex items-center gap-x-2">
          <FormFieldSelect
            aria-label="Appointment Filter Selection"
            className="w-36"
            placeholder="Select..."
            options={[
              { label: "Within Next...", value: "WITHIN" },
              { label: "Between", value: "BETWEEN" },
              { label: "None", value: "NONE" },
            ]}
            value={filterSelection}
            onItemSelected={(value) => {
              if (value === "NONE") {
                setFilterSelection(value);
                onUpdatePatientListCriteria((last) =>
                  produce(last, (draft) => {
                    draft.hasNoAppointment = true;
                    delete draft.appointmentWithinDays;
                    delete draft.appointmentDateRange;
                  })
                );
              } else {
                setFilterSelection(value);
                handleClearAppointmentCriteria();
              }
            }}
            required
          />

          {filterSelection === "WITHIN" ? (
            <FormFieldNumberInput
              aria-label="Appointment Within Days"
              className="w-24"
              value={appointmentWithinDays}
              placeholder="00"
              onChange={(e) =>
                onUpdatePatientListCriteria((last) =>
                  produce(last, (draft) => {
                    const value = e.target.value ? Number(e.target.value) : undefined;

                    draft.appointmentWithinDays = value;
                    delete draft.appointmentDateRange;
                    delete draft.hasNoAppointment;
                  })
                )
              }
            >
              <span
                className={`
                  font-sansSemiBold
                  text-xs
                  text-slate700
                  absolute
                  right-3
                  top-1/2
                  -translate-y-1/2
                `}
              >
                Days
              </span>
            </FormFieldNumberInput>
          ) : filterSelection === "BETWEEN" ? (
            <SelectDateRange
              id="appointment-date-range"
              className="w-48"
              startDate={appointmentDateRange?.min ? getLocalDate(appointmentDateRange.min) : undefined}
              endDate={appointmentDateRange?.max ? getLocalDate(appointmentDateRange.max) : undefined}
              onRangeSelected={(range) => {
                onUpdatePatientListCriteria((last) =>
                  produce(last, (draft) => {
                    draft.appointmentDateRange = {
                      min: formatAsISODate(range.startDate),
                      max: formatAsISODate(range.endDate),
                    };
                    delete draft.appointmentWithinDays;
                    delete draft.hasNoAppointment;
                  })
                );
              }}
            />
          ) : null}
        </div>

        {filterSelection ? (
          <Button
            className="w-fit text-xs"
            onClick={() => {
              setFilterSelection(undefined);
              handleClearAppointmentCriteria();
            }}
            size="custom"
            theme="link"
          >
            Clear
          </Button>
        ) : null}
      </div>
    </FilterSection>
  );
};
