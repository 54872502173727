import React from "react";
import { upsertDashFilter } from "components/Dashboard/utils/filters";
import { FilteredProvider } from "components/Dashboard/types";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";

export const useProviderFilterHandler = ({
  filters,
  onUpdateFilters,
}: {
  filters: DashboardFilter[];
  onUpdateFilters: (params: { filters: DashboardFilter[] }) => void;
}) => {
  return React.useCallback(
    (provider?: FilteredProvider) => {
      let updatedFilters: DashboardFilter[] = filters.filter(
        (item) => item.type !== "providerId" && item.type !== "providerJobCategory"
      );

      if (provider?.name) {
        updatedFilters = upsertDashFilter(updatedFilters, {
          type: "providerId",
          values: [`${provider.name.id}`],
        });
      } else if (provider?.jobCategory) {
        updatedFilters = upsertDashFilter(updatedFilters, {
          type: "providerJobCategory",
          values: [provider.jobCategory],
        });
      }

      onUpdateFilters({ filters: updatedFilters });
    },
    [filters, onUpdateFilters]
  );
};
