import React from "react";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as WarningIcon } from "@libs/assets/icons/warning.svg";
import { ButtonLink } from "@libs/components/UI/ButtonLink";
import { ReactComponent as DownloadIcon } from "@libs/assets/icons/download.svg";
import { IMAGING_SERVICE_DOWNLOAD_URL } from "api/imaging/imaging-hub";

export const AISDownloadWarning: React.FC = () => {
  return (
    <div className="py-2 px-3 items-center text-xs bg-blueLight flex gap-3">
      <Icon SvgIcon={WarningIcon} theme="slate700" />
      <div className="flex items-center justify-between w-full">
        <span>For a better imaging experience install Archy Imaging Service</span>
        <ButtonLink theme="link" href={IMAGING_SERVICE_DOWNLOAD_URL} target="_blank" size="small">
          <div className="flex items-center gap-3">
            Install
            <Icon SvgIcon={DownloadIcon} theme="primary" />
          </div>
        </ButtonLink>
      </div>
    </div>
  );
};
