import { createLinksContext } from "contexts/AppLinksContext";
import { paths } from "utils/routing/paths";

const { useLinks, LinksProvider, AppHistoryProvider } = createLinksContext("Employees", {
  employees: paths.employees(),
  payroll: paths.payroll(),
  roles: paths.roles(),
  electronicPrescribing: paths.electronicPrescribing(),
});

export const useEmployeesLinks = useLinks;
export const EmployeesLinksProvider = LinksProvider;
export const EmployeesAppHistoryProvider = AppHistoryProvider;
