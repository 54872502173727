import { useState } from "react";
import { ReactZoomPanPinchProps } from "react-zoom-pan-pinch";
import { useKeyEventCallback } from "@libs/hooks/useKeyEventCallback";

type DoubleClickMode = NonNullable<ReactZoomPanPinchProps["doubleClick"]>["mode"];

export const useToggleZoomMode = ({ key = "Shift" }) => {
  const [doubleClickMode, setDoubleClickMode] = useState<DoubleClickMode>("zoomIn");

  useKeyEventCallback("keydown", key, () => setDoubleClickMode("zoomOut"));
  useKeyEventCallback("keyup", key, () => setDoubleClickMode("zoomIn"));

  return doubleClickMode;
};
