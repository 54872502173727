import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { isToday, parseISO } from "date-fns";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { PatientInsuranceVO, InsuranceDetailsVO, PatientSummaryVO } from "@libs/api/generated-api";
import { getLocalDate } from "@libs/utils/date";
import { useValidation } from "@libs/hooks/useValidation";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getQueryKey } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as PrimaryIcon } from "@libs/assets/icons/one.svg";
import { ReactComponent as ArchiveIcon } from "@libs/assets/icons/archive.svg";
import { ReactComponent as UnarchiveIcon } from "@libs/assets/icons/unarchive.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Form } from "@libs/components/UI/Form";
import { DetailsModalPage } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/DetailsModalPage";
import { InsurancePlanDetailsFooter } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/Footer";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { getAllInsuranceCarriersQuery, getAutoEligibilityCarriers } from "api/practiceInsurance/queries";
import {
  UseInsuranceState,
  useLinkedPrimarySubscriber,
  useLookupPrimarySubscriber,
  usePatientDraft,
  usePatientInsuranceState,
} from "components/Patient/hooks";
import {
  PrimarySubscriber,
  PatientDraft,
  DependentPrimarySubscriber,
  SelectedPrimarySubscriber,
  SubscriberIdType,
} from "components/Patient/types";
import { getPatientInsuranceQuery } from "api/patientInsurance/queries";
import { getPatientSummary } from "api/patients/queries";
import {
  UpdateInsuranceFormState,
  getAddInsurancePostData,
  getUpdatePatientInsurancePatchData,
} from "components/Patient/formPostData";
import { getPatientInsuranceSchema } from "components/Patient/formSchemas";
import {
  updatePatientInsurance,
  addPatientInsurance,
  updatePatientInsuranceOrdinal,
  updatePatientInsuranceState,
  verifyPatientInsuranceEligibility,
  verifyInsurancePlanBenefit,
  autoVerifyEligibility,
  updateInsurancePlanFeeSchedule,
} from "api/patientInsurance/mutations";
import { handleError } from "utils/handleError";
import { InsuranceDetailsSection } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/SubscriberTab/InsuranceDetailsSection";
import { Divider } from "components/UI/Divider";
import { AdditionalInformationSection } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/SubscriberTab/AdditionalInformationSection";
import { SubscriberDetailsSection } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/SubscriberTab/SubscriberDetailsSection";
import { TabBanner } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/TabBanner";
import { ConfirmArchiveModal } from "components/PatientProfile/Insurance/ConfirmArchiveModal";
import { VerificationSection } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/SubscriberTab/VerificationSection";
import { NO_INSURANCE_CARRIER_ID } from "components/Dashboard/Adjustments/constants";
import { VerifyActionModal } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/SubscriberTab/VerifyActionModal";
import { UnverifyActionModal } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/SubscriberTab/UnverifyActionModal";
import { useWebSocketContext } from "contexts/WebSocketContext";
import { FeeScheduleActionModal } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/SubscriberTab/FeeScheduleActionModal";
import { isAutoVerified } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/SubscriberTab/utils";
import { WebSocketMessageEvent } from "api/websocket/webSocketMessage";

const FORM_ID = "patient-insurance-subscriber-form";

const cxStyles = {
  bannerActionsBtns: `
    flex
    flex-row
    items-center
    gap-x-1
    text-primaryTheme
    font-sansSemiBold
  `,
};

const MODAL_TIMEOUT = 20_000;

// eslint-disable-next-line complexity, max-statements
export const SubscriberRoute: React.FC = () => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const { insuranceId, patientId } = usePathParams("patientInsuranceDetails");
  const { query } = useQueryParams("patientInsuranceDetails");
  const backUrl = query.from ?? paths.patientTab({ patientId, tab: "insurance" });
  const isCreating = insuranceId === "new";
  const editing = useBoolean(false);

  const [allInsuranceCarriersQuery, autoEligibilityCarriersQuery, patientInsuranceQuery, patientQuery] =
    useApiQueries([
      getAllInsuranceCarriersQuery({ args: { practiceId } }),
      getAutoEligibilityCarriers({
        args: { practiceId },
      }),
      getPatientInsuranceQuery({
        args: {
          includeBenefitCoverage: true,
          includeInsurancePlan: true,
          insuranceId: isCreating ? 0 : insuranceId,
          patientId,
          practiceId,
        },
        queryOptions: { enabled: !isCreating },
      }),
      getPatientSummary({ args: { practiceId, patientId } }),
    ]);

  const carriers = useMemo(() => {
    const autoEligibilityCarrierIds = new Set(autoEligibilityCarriersQuery.data?.carrierIds ?? []);

    return [...(allInsuranceCarriersQuery.data ?? [])].sort((a, b) => {
      if (autoEligibilityCarrierIds.has(a.id) && !autoEligibilityCarrierIds.has(b.id)) {
        return -1;
      }

      return 0;
    });
  }, [autoEligibilityCarriersQuery.data?.carrierIds, allInsuranceCarriersQuery.data]);

  const patientInsurance = patientInsuranceQuery.data?.patientInsurance;
  const insurancePlan = patientInsuranceQuery.data?.insurancePlan;
  const isArchived = patientInsuranceQuery.data?.patientInsurance.insuranceState === "ARCHIVED";
  const inEditMode = editing.isOn || isCreating || (!patientInsuranceQuery.isLoading && !patientInsurance);

  const patientDrafts = usePatientDraft({
    initialPrimarySubscriber: { patientId, subscriberIdType: isCreating ? "subscriberId" : "ssn" },
  });

  const {
    handleUpdatePatientDraft,
    handleUpdatePrimarySubscriber,
    handleUpdateSelectedPrimarySubscriber,
    patientDraft,
    primarySubscriber,
    selectedPrimarySubscriber,
  } = patientDrafts;

  const [dependentPrimarySubscriber, handleUpdateDependentPrimarySubscriber] = useLinkedPrimarySubscriber({
    subscriberIdType: "subscriberId",
  });

  useLoadedPatientProperties({
    currentInsurance: patientInsurance,
    currentPatient: patientQuery.data,
    handleUpdateDependentPrimarySubscriber,
    handleUpdatePatientDraft,
    handleUpdatePrimarySubscriber,
    handleUpdateSelectedPrimarySubscriber,
  });

  //  Looks up primary subscriber on behalf of dependent fields:
  const dependentSubscriberMatch = useLookupPrimarySubscriber(
    dependentPrimarySubscriber,
    patientInsurance?.subscriber
  );

  // Must memoize dob, or it will trigger a downstream re-render over and over
  const primarySubscriberDob = useMemo(
    () => (patientQuery.data?.dob ? parseISO(patientQuery.data.dob) : null),
    [patientQuery.data?.dob]
  );

  // Looks up primary subscriber on behalf of primary subscriber fields
  const primarySubscriberMatch = useLookupPrimarySubscriber(
    {
      dob: primarySubscriberDob,
      subscriberIdType: primarySubscriber.subscriberIdType,
      carrierId: primarySubscriber.carrierId,
      subscriberId: primarySubscriber.subscriberId,
      ssn: primarySubscriber.ssn,
    },
    patientInsurance?.subscriber
  );

  const { isPrimary, subscriber, subscriberMatch } = useMemo(() => {
    const isPrimarySubscriber = patientDraft.insuranceSubscriberType === "PRIMARY_SUBSCRIBER";

    return {
      isPrimary: isPrimarySubscriber,
      subscriber: isPrimarySubscriber ? primarySubscriber : dependentPrimarySubscriber,
      subscriberMatch: isPrimarySubscriber ? primarySubscriberMatch : dependentSubscriberMatch,
    };
  }, [
    patientDraft.insuranceSubscriberType,
    primarySubscriber,
    dependentPrimarySubscriber,
    primarySubscriberMatch,
    dependentSubscriberMatch,
  ]);

  const { insuranceState, handleInsuranceChange } = usePatientInsuranceState(patientInsurance);

  const formData = getSubmittedFormFields(
    patientDrafts,
    dependentPrimarySubscriber,
    insuranceState,
    patientQuery.data?.ssnLastFour
  );

  const ssnLastFour = patientInsurance?.subscriber.ssnLastFour ?? patientQuery.data?.ssnLastFour;

  const usingPatientSsn = Boolean(!primarySubscriber.ssn && ssnLastFour);

  const schema = useMemo(() => {
    return getPatientInsuranceSchema({
      dependentPrimarySubscriberIdType: dependentPrimarySubscriber.subscriberIdType,
      isCreatingInsurance: isCreating,
      insuranceSubscriberType: patientDraft.insuranceSubscriberType,
      primarySubscriberSubscriberIdType: primarySubscriber.subscriberIdType,
      usingPatientSsn,
    });
  }, [
    dependentPrimarySubscriber.subscriberIdType,
    isCreating,
    patientDraft,
    primarySubscriber.subscriberIdType,
    usingPatientSsn,
  ]);

  const { result: validation, validate, reset } = useValidation(formData, schema);

  useEffect(() => {
    if (subscriberMatch && patientDraft.insuranceSubscriberType === "DEPENDENT") {
      const updatesWithSubscriber = {
        firstName: subscriberMatch.personalDetails.firstName,
        lastName: subscriberMatch.personalDetails.lastName,
        dob: parseISO(subscriberMatch.personalDetails.dob),
      };

      handleUpdateDependentPrimarySubscriber(updatesWithSubscriber);
    }
  }, [handleUpdateDependentPrimarySubscriber, patientDraft.insuranceSubscriberType, subscriberMatch]);

  const handleUpdateInsuranceType = useCallback(
    (value: InsuranceDetailsVO["type"]) => {
      reset();

      if (value === "DEPENDENT") {
        const updates: Partial<DependentPrimarySubscriber> = {
          carrierId: primarySubscriber.carrierId,
          ssn: primarySubscriber.ssn,
          subscriberId: primarySubscriber.subscriberId,
        };
        const updatesWithSubscriber = subscriberMatch
          ? {
              ...updates,
              firstName: subscriberMatch.personalDetails.firstName,
              lastName: subscriberMatch.personalDetails.lastName,
              dob: parseISO(subscriberMatch.personalDetails.dob),
            }
          : updates;

        handleUpdateDependentPrimarySubscriber(updatesWithSubscriber);
      } else if (value === "PRIMARY_SUBSCRIBER") {
        handleUpdatePrimarySubscriber({
          carrierId: dependentPrimarySubscriber.carrierId,
          ssn: dependentPrimarySubscriber.ssn,
          subscriberId: dependentPrimarySubscriber.subscriberId,
        });
      }

      handleUpdatePatientDraft({ insuranceSubscriberType: value });
    },
    [
      dependentPrimarySubscriber.carrierId,
      dependentPrimarySubscriber.ssn,
      dependentPrimarySubscriber.subscriberId,
      handleUpdateDependentPrimarySubscriber,
      handleUpdatePatientDraft,
      handleUpdatePrimarySubscriber,
      primarySubscriber.carrierId,
      primarySubscriber.ssn,
      primarySubscriber.subscriberId,
      reset,
      subscriberMatch,
    ]
  );

  const handleUpdateRelationship = useCallback(
    (value: PatientInsuranceVO["relationshipWithSubscriber"]) => {
      handleUpdateDependentPrimarySubscriber({ relationship: value });
    },
    [handleUpdateDependentPrimarySubscriber]
  );

  const handleChangeAssignmentOfBenefits = useCallback(
    (val: boolean) => handleInsuranceChange({ assignmentOfBenefitsToPractice: val }),
    [handleInsuranceChange]
  );

  const handleChangeInfoRelease = useCallback(
    (val: boolean) => {
      handleInsuranceChange({
        releaseOfPatientInfo: val,
        // If release of patient information is false, default assignment of benefits to patient
        assignmentOfBenefitsToPractice: val ? insuranceState.assignmentOfBenefitsToPractice : false,
      });
    },
    [handleInsuranceChange, insuranceState.assignmentOfBenefitsToPractice]
  );

  const handleSubscriberNotesChange = useCallback(
    (val: string) => {
      handleInsuranceChange({ notes: val });
    },
    [handleInsuranceChange]
  );

  const [
    { mutateAsync: addInsuranceMutate, isLoading: isRequestingInsuranceCreation },
    { mutateAsync: autoVerifyMutate, isLoading: verifyingAutoEligibility },
    updateInsurancePlanFeeScheduleMutation,
    { mutate: updateInsuranceMutate, isLoading: isUpdatingInsurance },
    updatePatientInsuranceOrdinalMutation,
    updatePatientInsuranceStateMutation,
    { mutateAsync: verifyPlanBenefit, isLoading: verifyingPlanBenefit },
    { mutateAsync: verifyEligibility, isLoading: verifyingEligibility },
  ] = useApiMutations([
    addPatientInsurance,
    autoVerifyEligibility,
    updateInsurancePlanFeeSchedule,
    updatePatientInsurance,
    updatePatientInsuranceOrdinal,
    updatePatientInsuranceState,
    verifyInsurancePlanBenefit,
    verifyPatientInsuranceEligibility,
  ]);

  const navigateToNext = useCallback(
    (insId: number, isAdding = false) => {
      navigate(paths.patientInsuranceStep({ patientId, insuranceId: insId, step: "carrier" }, { isAdding }));
    },
    [navigate, patientId]
  );

  const navigateToCreatedPtInsurance = useCallback(
    (insId: number, isAdding = true) => {
      navigate(paths.patientInsuranceStep({ patientId, insuranceId: insId, step: "info" }, { isAdding }));
    },
    [navigate, patientId]
  );

  const handleSetAsPrimary = useCallback(() => {
    updatePatientInsuranceOrdinalMutation.mutate(
      {
        data: {
          ordinal: "PRIMARY",
        },
        insuranceId: insuranceId as number,
        patientId,
        practiceId,
      },
      { onError: handleError }
    );
  }, [insuranceId, patientId, practiceId, updatePatientInsuranceOrdinalMutation]);

  const autoEligibilityCarrierIds = useMemo(
    () => new Set(autoEligibilityCarriersQuery.data?.carrierIds),
    [autoEligibilityCarriersQuery.data?.carrierIds]
  );

  const isAutoEligibleCarrier = useMemo(
    () => autoEligibilityCarrierIds.has(subscriber.carrierId),
    [autoEligibilityCarrierIds, subscriber.carrierId]
  );

  const verifyActionModal = useBoolean(false);
  const unverifyActionModal = useBoolean(false);
  const feeScheduleActionModal = useBoolean(false);
  const autoVerifying = useBoolean(false);

  const handleVerify = useCallback(
    async ({
      includeCoverage = false,
      insId,
      insurancePlanUuid,
      isAdding = false,
      requestAutoVerifying,
    }: {
      includeCoverage?: boolean;
      insId: number;
      insurancePlanUuid?: string;
      isAdding?: boolean;
      onError?: Func;
      requestAutoVerifying: boolean;
    }) => {
      try {
        if (requestAutoVerifying) {
          autoVerifying.on();
          await autoVerifyMutate({
            data: { eligibilityOnly: !includeCoverage },
            insuranceId: insId,
            patientId,
            practiceId,
          });
        } else {
          await verifyEligibility({
            data: { status: "VERIFIED" },
            insuranceId: insId,
            patientId,
            practiceId,
          });

          if (includeCoverage && insurancePlanUuid) {
            await verifyPlanBenefit({
              data: { status: "VERIFIED" },
              insuranceId: insId,
              insurancePlanUuid,
              patientId,
              practiceId,
            });
          }
        }
      } catch (error) {
        handleError(error);
        verifyActionModal.off();
        autoVerifying.off();
        navigateToCreatedPtInsurance(insId);
      } finally {
        if (!requestAutoVerifying) {
          verifyActionModal.off();

          if (isAdding) {
            navigateToCreatedPtInsurance(insId);
          }
        }
      }
    },
    [
      autoVerifyMutate,
      autoVerifying,
      navigateToCreatedPtInsurance,
      patientId,
      practiceId,
      verifyActionModal,
      verifyEligibility,
      verifyPlanBenefit,
    ]
  );

  const { messages } = useWebSocketContext();
  const queryClient = useQueryClient();

  const hasReceivedMessage = useBoolean(false);
  const turnHasReceivedMessageOff = useCallback(() => hasReceivedMessage.off(), [hasReceivedMessage]);
  const turnHasReceivedMessageOn = useCallback(() => hasReceivedMessage.on(), [hasReceivedMessage]);

  useEffect(() => {
    const onMessageHandler = (event: WebSocketMessageEvent) => {
      const message = event.detail;

      if (message.type === "AUTO_ELIGIBILITY_VERIFIED") {
        turnHasReceivedMessageOn();

        queryClient.invalidateQueries([
          getQueryKey("practices", "getPatientInsurances"),
          { practiceId, patientId },
        ]);
        queryClient.invalidateQueries([
          getQueryKey("practices", "getPatientInsurance"),
          { practiceId, patientId, insuranceId },
        ]);
      }
    };

    if (messages) {
      messages.addEventListener("message", onMessageHandler);

      return () => {
        messages.removeEventListener("message", onMessageHandler);
      };
    }

    return undefined;
  }, [insuranceId, patientId, practiceId, queryClient, turnHasReceivedMessageOn, messages]);

  useEffect(() => {
    if (autoVerifying.isOn && hasReceivedMessage.isOn) {
      turnHasReceivedMessageOff();
      verifyActionModal.off();
      autoVerifying.off();

      if (!patientInsuranceQuery.data?.insurancePlan?.feeSchedule?.id) {
        feeScheduleActionModal.on();
      }
    }
  }, [
    patientInsuranceQuery.data?.insurancePlan?.feeSchedule?.id,
    autoVerifying,
    feeScheduleActionModal,
    hasReceivedMessage.isOn,
    verifyActionModal,
    turnHasReceivedMessageOff,
  ]);

  const [createdInsuranceId, setCreatedInsuranceId] = useState<number>();

  useEffect(() => {
    const id =
      autoVerifying.isOn && hasReceivedMessage.isOff
        ? window.setTimeout(() => {
            autoVerifying.off();
            verifyActionModal.off();
            feeScheduleActionModal.off();
            toast.info("Still verifying patient and plan data. Please check again in a few minutes.");

            if (createdInsuranceId) {
              navigateToCreatedPtInsurance(createdInsuranceId);
            }
          }, MODAL_TIMEOUT)
        : 0;

    return () => {
      if (id) {
        window.clearTimeout(id);
      }
    };
  }, [
    createdInsuranceId,
    autoVerifying,
    feeScheduleActionModal,
    hasReceivedMessage.isOff,
    navigate,
    patientId,
    verifyActionModal,
    navigateToCreatedPtInsurance,
  ]);

  const handleSubmit = useCallback(
    async (
      e?: FormEvent<HTMLFormElement>,
      verify = false,
      requestAutoVerifying = false,
      includeCoverage = false
    ) => {
      e?.preventDefault();

      if (insuranceId !== "new" && editing.isOff) {
        navigateToNext(insuranceId, true);
      }

      if (validate().$isValid) {
        if (isCreating) {
          const data = getAddInsurancePostData(formData);

          try {
            const response = await addInsuranceMutate({ data, patientId, practiceId });

            setCreatedInsuranceId(response.data.data.id);

            if (verify) {
              await handleVerify({
                includeCoverage,
                insId: response.data.data.id,
                isAdding: true,
                requestAutoVerifying,
              });
            } else {
              navigateToCreatedPtInsurance(response.data.data.id);
            }
          } catch (error) {
            handleError(error);
          } finally {
            editing.off();
          }
        } else if (insuranceId) {
          const patchData = getUpdatePatientInsurancePatchData(
            insuranceState,
            primarySubscriber,
            dependentPrimarySubscriber.relationship
          );

          updateInsuranceMutate(
            {
              data: patchData,
              insuranceId,
              invalidateSsn: patientDraft.insuranceSubscriberType === "PRIMARY_SUBSCRIBER",
              patientId,
              practiceId,
            },
            {
              onSuccess: editing.off,
              onError: handleError,
            }
          );
        }
      }
    },
    [
      addInsuranceMutate,
      dependentPrimarySubscriber.relationship,
      editing,
      formData,
      handleVerify,
      insuranceId,
      insuranceState,
      isCreating,
      navigateToCreatedPtInsurance,
      navigateToNext,
      patientDraft.insuranceSubscriberType,
      patientId,
      practiceId,
      primarySubscriber,
      updateInsuranceMutate,
      validate,
    ]
  );

  const handleClickVerify = useCallback(
    async ({
      includeCoverage,
      insId,
      requestAutoVerifying,
    }: {
      includeCoverage?: boolean;
      insId?: number;
      requestAutoVerifying: boolean;
    }) => {
      if (insId) {
        try {
          await handleVerify({
            includeCoverage,
            insId,
            insurancePlanUuid: insurancePlan?.uuid,
            requestAutoVerifying,
          });
        } catch (error) {
          handleError(error);
        }
      } else {
        try {
          await handleSubmit(undefined, true, requestAutoVerifying, includeCoverage);
        } catch {
          // Do nothing - handled in handleSubmit
        }
      }
    },
    [handleSubmit, handleVerify, insurancePlan?.uuid]
  );

  const handleClickUnverify = useCallback(
    async ({
      includeCoverage = false,
      includeEligibility = false,
      insId,
      insurancePlanUuid,
    }: {
      includeCoverage?: boolean;
      includeEligibility?: boolean;
      insId?: number;
      insurancePlanUuid?: string;
    }) => {
      try {
        if (insId) {
          if (includeEligibility) {
            await verifyEligibility({
              data: { status: "NOT_VERIFIED" },
              insuranceId: insId,
              patientId,
              practiceId,
            });
          }

          if (includeCoverage && insurancePlanUuid) {
            await verifyPlanBenefit({
              data: { status: "NOT_VERIFIED" },
              insuranceId: insId,
              insurancePlanUuid,
              patientId,
              practiceId,
            });
          }
        }
      } catch (error) {
        handleError(error);
      } finally {
        unverifyActionModal.off();
      }
    },
    [patientId, practiceId, unverifyActionModal, verifyEligibility, verifyPlanBenefit]
  );

  const handleSaveFeeSchedule = useCallback(
    async (feeScheduleId: number) => {
      if (patientInsuranceQuery.data && patientInsuranceQuery.data.insurancePlan) {
        await updateInsurancePlanFeeScheduleMutation.mutateAsync({
          data: { feeScheduleId },
          insuranceId: patientInsuranceQuery.data.patientInsurance.id,
          insurancePlanUuid: patientInsuranceQuery.data.insurancePlan.uuid,
          patientId,
          practiceId,
        });
        navigateToCreatedPtInsurance(patientInsuranceQuery.data.patientInsurance.id, isCreating);
      }

      feeScheduleActionModal.off();
      editing.off();
    },
    [
      patientInsuranceQuery.data,
      editing,
      feeScheduleActionModal,
      isCreating,
      navigateToCreatedPtInsurance,
      patientId,
      practiceId,
      updateInsurancePlanFeeScheduleMutation,
    ]
  );

  const archiveModal = useBoolean(false);
  const handleToggleArchive = useCallback(async () => {
    if (patientInsurance?.insuranceState === "ARCHIVED") {
      try {
        await updatePatientInsuranceStateMutation.mutateAsync({
          practiceId,
          patientId,
          insuranceId: insuranceId as number,
          data: { state: "ACTIVE" },
        });
        navigate(paths.patientTab({ tab: "insurance", patientId }));
      } catch (e) {
        handleError(e);
      }
    } else {
      archiveModal.on();
    }
  }, [
    archiveModal,
    navigate,
    insuranceId,
    patientId,
    patientInsurance?.insuranceState,
    practiceId,
    updatePatientInsuranceStateMutation,
  ]);

  const canVerify = useMemo(() => {
    const detailsDataExists = Boolean(
      (primarySubscriber.carrierId !== NO_INSURANCE_CARRIER_ID && primarySubscriber.subscriberId) ||
        primarySubscriber.subscriberIdType === "ssn"
    );

    return patientDraft.insuranceSubscriberType === "PRIMARY_SUBSCRIBER"
      ? detailsDataExists
      : Boolean(
          detailsDataExists &&
            dependentPrimarySubscriber.firstName &&
            dependentPrimarySubscriber.lastName &&
            dependentPrimarySubscriber.dob &&
            dependentPrimarySubscriber.relationship
        );
  }, [
    dependentPrimarySubscriber.dob,
    dependentPrimarySubscriber.firstName,
    dependentPrimarySubscriber.lastName,
    dependentPrimarySubscriber.relationship,
    patientDraft.insuranceSubscriberType,
    primarySubscriber.carrierId,
    primarySubscriber.subscriberId,
    primarySubscriber.subscriberIdType,
  ]);

  return (
    <DetailsModalPage
      actions={
        (inEditMode || query.isAdding) && (
          <InsurancePlanDetailsFooter
            formId={FORM_ID}
            isLoading={isRequestingInsuranceCreation || isUpdatingInsurance}
            onBack={() => (editing.isOn ? editing.off() : navigate(backUrl))}
            primaryButtonLabel={inEditMode ? "Save" : "Next"}
            secondaryButtonLabel="Cancel"
          />
        )
      }
      backUrl={backUrl}
      insuranceId={insuranceId}
      patientId={patientId}
      patientInsuranceQuery={patientInsuranceQuery}
      queries={[allInsuranceCarriersQuery, autoEligibilityCarriersQuery, patientInsuranceQuery, patientQuery]}
      selectedTab="info"
    >
      {!isCreating && (
        <TabBanner
          actions={
            <div className="flex items-center gap-x-3">
              {patientInsurance?.insuranceOrdinal !== "PRIMARY" && !isArchived && (
                <button className={cxStyles.bannerActionsBtns} onClick={handleSetAsPrimary} type="button">
                  <Icon SvgIcon={PrimaryIcon} theme="primary" />
                  Set as Primary
                </button>
              )}
              <button className={cxStyles.bannerActionsBtns} onClick={handleToggleArchive} type="button">
                <Icon SvgIcon={isArchived ? UnarchiveIcon : ArchiveIcon} theme="primary" />
                {isArchived ? "Unarchive Insurance" : "Archive Insurance"}
              </button>
              {Boolean(patientInsuranceQuery.data) && (
                <button className={cxStyles.bannerActionsBtns} onClick={editing.on} type="button">
                  <Icon SvgIcon={EditIcon} theme="primary" />
                  Edit Subscriber
                </button>
              )}
            </div>
          }
          description="Below mentioned details are for this specific patient only. Making changes will not affect other patients."
          editing={editing.isOn}
          theme={isArchived ? "error" : "info"}
        />
      )}
      <QueryResult queries={[allInsuranceCarriersQuery, patientInsuranceQuery, patientQuery]}>
        {allInsuranceCarriersQuery.data && patientQuery.data && (
          <Form
            className="flex flex-col max-w-xl m-6 gap-y-6"
            fieldLayout="labelIn"
            id={FORM_ID}
            onSubmit={handleSubmit}
          >
            <InsuranceDetailsSection
              autoEligibilityCarrierIds={autoEligibilityCarrierIds}
              carrierId={subscriber.carrierId}
              editing={inEditMode}
              hasLinkedPlan={Boolean(insurancePlan)}
              insuranceCarriers={carriers}
              insuranceState={insuranceState}
              insuranceSubscriberType={patientDraft.insuranceSubscriberType}
              isCreating={isCreating}
              isPrimary={isPrimary}
              onInsuranceChange={handleInsuranceChange}
              onUpdateDependentPrimarySubscriber={handleUpdateDependentPrimarySubscriber}
              onUpdateInsuranceType={handleUpdateInsuranceType}
              onUpdateCarrier={
                isPrimary ? handleUpdatePrimarySubscriber : handleUpdateDependentPrimarySubscriber
              }
              onUpdatePrimarySubscriber={handleUpdatePrimarySubscriber}
              ssnLastFour={ssnLastFour}
              subscriber={subscriber}
              subscriberMatch={subscriberMatch}
              validation={validation}
            />
            {!isPrimary && (
              <>
                <Divider className="border-dashed" />
                <SubscriberDetailsSection
                  dependentPrimarySubscriber={dependentPrimarySubscriber}
                  isCreating={isCreating}
                  isEditing={inEditMode}
                  selectedPrimarySubscriber={selectedPrimarySubscriber}
                  onUpdatePrimarySubscriber={handleUpdatePrimarySubscriber}
                  onUpdateDependentPrimarySubscriber={handleUpdateDependentPrimarySubscriber}
                  onUpdateRelationship={handleUpdateRelationship}
                  onUpdateSelectedPrimarySubscriber={handleUpdateSelectedPrimarySubscriber}
                  subscriberMatch={subscriberMatch}
                  validation={validation}
                />
              </>
            )}
            <Divider className="border-dashed" />
            <AdditionalInformationSection
              editing={editing.isOn || isCreating}
              insuranceState={insuranceState}
              onChangeAssignmentOfBenefits={handleChangeAssignmentOfBenefits}
              onChangeInfoRelease={handleChangeInfoRelease}
              onChangeSubscriberNotes={handleSubscriberNotesChange}
            />
            <Divider className="border-dashed" />
            {isCreating ? null : (
              <VerificationSection
                canVerify={canVerify}
                insurancePlan={insurancePlan}
                onOpenVerifyModal={verifyActionModal.on}
                onOpenUnverifyModal={unverifyActionModal.on}
                patientInsurance={patientInsurance}
                verificationError={patientInsurance?.autoEligibilityErrorMessage}
              />
            )}
          </Form>
        )}
      </QueryResult>
      {archiveModal.isOn && (
        <ConfirmArchiveModal
          expirationDate={patientInsurance?.expiryDate}
          insuranceId={insuranceId as number}
          onAction={archiveModal.off}
          patientId={patientId}
          practiceId={practiceId}
        />
      )}
      {verifyActionModal.isOn && (
        <VerifyActionModal
          disableAutoVerify={Boolean(
            isAutoVerified(patientInsurance?.eligibilityVerifiedStatus) &&
              patientInsurance.eligibilityLastVerifiedAt &&
              isToday(getLocalDate(patientInsurance.eligibilityLastVerifiedAt))
          )}
          insurancePlan={insurancePlan}
          isAutoEligibleCarrier={isAutoEligibleCarrier}
          isAutoVerifying={autoVerifying.isOn}
          isLoading={verifyingEligibility || verifyingPlanBenefit || verifyingAutoEligibility}
          onClickVerify={handleClickVerify}
          onClose={verifyActionModal.off}
          patientInsurance={patientInsurance}
        />
      )}
      {unverifyActionModal.isOn && (
        <UnverifyActionModal
          insurancePlan={insurancePlan}
          isLoading={verifyingEligibility || verifyingPlanBenefit || verifyingAutoEligibility}
          onClickUnverify={handleClickUnverify}
          onClose={unverifyActionModal.off}
          patientInsurance={patientInsurance}
        />
      )}
      {feeScheduleActionModal.isOn && patientInsuranceQuery.data?.insurancePlan && (
        <FeeScheduleActionModal
          autoVerifiedInsurancePlan={patientInsuranceQuery.data.insurancePlan}
          onClose={feeScheduleActionModal.off}
          onSave={handleSaveFeeSchedule}
        />
      )}
    </DetailsModalPage>
  );
};

const getSubmittedFormFields = (
  patientDraftData: ReturnType<typeof usePatientDraft>,
  dependentPrimarySubscriber: DependentPrimarySubscriber,
  insuranceState: UseInsuranceState["insuranceState"],
  ssnLastFour?: string
) => {
  const { patientDraft, primarySubscriber, selectedPrimarySubscriber, patientMatch } = patientDraftData;
  let formData: UpdateInsuranceFormState = {
    patientDraft,
    primarySubscriber,
    selectedPrimarySubscriber,
    patientMatch,
    dependentPrimarySubscriber,
    insuranceState,
  };

  if (
    formData.patientDraft.insuranceSubscriberType === "PRIMARY_SUBSCRIBER" &&
    formData.primarySubscriber.subscriberIdType === "ssn" &&
    !formData.primarySubscriber.ssn
  ) {
    // Patient has selected create primary subscriber, has an SSN, and hasn't entered one manually
    formData = {
      ...formData,
      primarySubscriber: {
        ...formData.primarySubscriber,
        ssn: undefined,
        ssnLastFour,
      },
    };
  }

  return formData;
};

const useLoadedPatientProperties = (params: {
  currentInsurance?: PatientInsuranceVO;
  currentPatient?: PatientSummaryVO;
  handleUpdateDependentPrimarySubscriber: (partial: Partial<DependentPrimarySubscriber>) => void;
  handleUpdatePrimarySubscriber: (partial: Partial<PrimarySubscriber>) => void;
  handleUpdatePatientDraft: (partial: Partial<PatientDraft>) => void;
  handleUpdateSelectedPrimarySubscriber: (partial: Partial<SelectedPrimarySubscriber>) => void;
}) => {
  const {
    currentInsurance,
    currentPatient,
    handleUpdateDependentPrimarySubscriber,
    handleUpdatePrimarySubscriber,
    handleUpdatePatientDraft,
    handleUpdateSelectedPrimarySubscriber,
  } = params;

  const syncPatientFields = useCallback(
    (loadedPatient: PatientSummaryVO) => {
      handleUpdatePatientDraft({
        dob: getLocalDate(loadedPatient.dob),
        email: loadedPatient.contact.relation === "SELF" ? loadedPatient.contact.email : "",
        firstName: loadedPatient.name.firstName,
      });
    },
    [handleUpdatePatientDraft]
  );

  useSyncOnce(syncPatientFields, currentPatient);

  const currentInsuranceLoaded = useRef(false);

  useEffect(() => {
    if (currentInsuranceLoaded.current || !currentInsurance) {
      return;
    }

    const { subscriber, relationshipWithSubscriber, type } = currentInsurance;
    const ssnLastFour = subscriber.ssnLastFour ?? currentPatient?.ssnLastFour;
    const subscriberParams = {
      carrierId: subscriber.carrierId,
      dob: subscriber.dob ? getLocalDate(subscriber.dob) : undefined,
      patientId: subscriber.patientId,
      relationship: relationshipWithSubscriber,
      ssn: undefined,
      ssnLastFour,
      subscriberId: subscriber.externalMemberId ?? "",
      subscriberIdType: (subscriber.externalMemberId === undefined
        ? "ssn"
        : "subscriberId") as SubscriberIdType,
    };

    if (type === "PRIMARY_SUBSCRIBER") {
      handleUpdatePrimarySubscriber(subscriberParams);
    } else {
      handleUpdateSelectedPrimarySubscriber({
        relationship: subscriberParams.relationship,
      });
      handleUpdateDependentPrimarySubscriber({
        ...subscriberParams,
        firstName: subscriber.firstName,
        lastName: subscriber.lastName,
      });
    }

    handleUpdatePatientDraft({
      relationship: relationshipWithSubscriber,
      insuranceSubscriberType: type === "PRIMARY_SUBSCRIBER" ? "PRIMARY_SUBSCRIBER" : "DEPENDENT",
    });

    currentInsuranceLoaded.current = true;
  }, [
    handleUpdateDependentPrimarySubscriber,
    handleUpdateSelectedPrimarySubscriber,
    currentPatient,
    currentInsuranceLoaded,
    currentInsurance,
    handleUpdatePrimarySubscriber,
    handleUpdatePatientDraft,
  ]);
};
