import { FC, useId, useCallback } from "react";

import { PracticeRemoteSettingsRequest } from "@libs/api/generated-api";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";

import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { SettingsPanel } from "components/Settings/SettingsPanel";

import { ServerAccess } from "components/Settings/OnboardingSettings/ServerAccess/ServerAccess";

import { getPracticeConnectedRemoteSettings } from "api/practice/queries";
import { upsertPracticeRemoteSettings } from "api/practice/mutations";
import { handleError } from "utils/handleError";

export const ServerAccessRoute: FC = () => {
  const { practiceId } = useAccount();
  const editing = useBoolean(false);
  const formId = useId();

  const [practiceConnectedRemoteSettingsQuery] = useApiQueries([
    getPracticeConnectedRemoteSettings({ args: { practiceId } }),
  ]);

  const [upsertPracticeRemoteSettingsMutation] = useApiMutations([upsertPracticeRemoteSettings]);
  const upsertPracticeRemoteSettingsMutate = upsertPracticeRemoteSettingsMutation.mutate;

  const handleUpdateRemoteSettings = useCallback(
    (data: PracticeRemoteSettingsRequest, options?: { onSuccess?: Func }) => {
      upsertPracticeRemoteSettingsMutate(
        { practiceId, data },
        {
          onSuccess: () => {
            options?.onSuccess?.();
            editing.off();
          },
          onError: handleError,
        }
      );
    },
    [upsertPracticeRemoteSettingsMutate, practiceId, editing]
  );

  return (
    <QueryResult queries={[practiceConnectedRemoteSettingsQuery]}>
      {practiceConnectedRemoteSettingsQuery.data ? (
        <SettingsPanel
          title="Server Access"
          footer={
            editing.isOn ? (
              <div className="flex items-center justify-center gap-x-2">
                <Button className="min-w-button" onClick={editing.off} theme="secondary">
                  Cancel
                </Button>
                <AsyncButton
                  className="min-w-button"
                  isLoading={upsertPracticeRemoteSettingsMutation.isLoading}
                  form={formId}
                  type="submit"
                >
                  Save
                </AsyncButton>
              </div>
            ) : undefined
          }
          onToggleEdit={editing.toggle}
          isEditing={editing.isOn}
        >
          <ServerAccess
            formId={formId}
            connectedRemoteSettings={practiceConnectedRemoteSettingsQuery.data}
            onUpdateRemoteSettings={handleUpdateRemoteSettings}
            isEditing={editing.isOn}
          />
        </SettingsPanel>
      ) : null}
    </QueryResult>
  );
};
