import { FC, RefObject, FormEvent, useId, useState, useMemo, useCallback } from "react";
import { flushSync } from "react-dom";

import { AvailableTwilioPhoneNumberVO } from "@libs/api/generated-api";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { FormFieldDescription } from "@libs/components/UI/FormFieldDescription";
import { cxStyle } from "@libs/components/UI/FormField";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { RadioList } from "@libs/components/UI/RadioList";
import { ReactComponent as WarningIcon } from "@libs/assets/icons/warning.svg";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";

import { Form } from "@libs/components/UI/Form";
import { Banner } from "@libs/components/UI/Banner";
import { FormFieldNumericInput } from "components/UI/FormFieldNumericInput";

import { scrollToFirstError } from "utils/scrollToFirstError";

const MAX_LENGTH = 3;

interface Props {
  formId: string;
  formContainerRef: RefObject<HTMLDivElement>;
  availablePhoneNumbers: AvailableTwilioPhoneNumberVO[] | undefined;
  onSearchPhoneNumbers: (value: string) => void;
  onSubmit: (phoneNumber: string) => void;
  hasPhoneNumber: boolean;
  isSearching: boolean;
}

export const PhoneNumberForm: FC<Props> = ({
  formId,
  formContainerRef,
  availablePhoneNumbers,
  onSearchPhoneNumbers,
  onSubmit,
  hasPhoneNumber,
  isSearching,
}) => {
  const preferredAreaCodeFieldId = useId();
  const [phoneAreaCode, setPhoneAreaCode] = useState("");
  const [phonePrefix, setPhonePrefix] = useState("");
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState("");

  const phoneNumberOptions = useMemo(
    () =>
      availablePhoneNumbers?.map(({ phoneNumber, phoneNumberFriendlyName, locality, region }) => ({
        label: `${phoneNumberFriendlyName} - ${locality ? `${locality}, ${region}` : region}`,
        value: phoneNumber,
      })) ?? [],
    [availablePhoneNumbers]
  );

  const handleSearch = useCallback(() => {
    onSearchPhoneNumbers(phoneAreaCode + phonePrefix);
  }, [onSearchPhoneNumbers, phoneAreaCode, phonePrefix]);

  const { validate, result: validationResult } = useValidation(
    { selectedPhoneNumber },
    {
      selectedPhoneNumber: [
        {
          $v: required,
          $error: "Phone number is required",
        },
      ],
    }
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const result = flushSync(() => validate());

      if (result.$isValid) {
        onSubmit(selectedPhoneNumber);
      } else {
        scrollToFirstError(formContainerRef.current);
      }
    },
    [validate, onSubmit, selectedPhoneNumber, formContainerRef]
  );

  return (
    <Form id={formId} className="flex flex-col gap-y-6" onSubmit={handleSubmit}>
      <h3 className="font-sansSemiBold text-sm text-black">Phone Number</h3>

      <div className="flex flex-col">
        <FormFieldLabel
          id={preferredAreaCodeFieldId}
          className={cxStyle.label({ layout: "labelOut", edit: true })}
          content="Preferred Area Code"
          disabled={hasPhoneNumber}
          required
        />

        <FormFieldDescription disabled={hasPhoneNumber}>
          Input your preferred area code here. Only the first three digits are required to search.
        </FormFieldDescription>

        <div className="flex items-center gap-x-2">
          <FormFieldNumericInput
            aria-label="Phone Area Code"
            id={preferredAreaCodeFieldId}
            className="w-14"
            placeholder="555"
            value={phoneAreaCode}
            onChange={(e) => setPhoneAreaCode(e.target.value)}
            disabled={hasPhoneNumber}
            maxLength={MAX_LENGTH}
          />

          <span className="text-xs text-black">-</span>

          <FormFieldNumericInput
            aria-label="Phone Prefix"
            className="w-14"
            placeholder="000"
            value={phonePrefix}
            onChange={(e) => setPhonePrefix(e.target.value)}
            disabled={hasPhoneNumber}
            maxLength={MAX_LENGTH}
          />

          <AsyncButton
            className="min-w-button"
            onClick={handleSearch}
            isLoading={isSearching}
            inactive={phoneAreaCode.length < MAX_LENGTH}
            disabled={hasPhoneNumber}
            theme="secondary"
            size="medium"
            type="button"
          >
            Search
          </AsyncButton>
        </div>
      </div>

      <div>
        {availablePhoneNumbers ? (
          <>
            <RadioList
              label="Available Numbers"
              options={phoneNumberOptions}
              selectedValue={selectedPhoneNumber}
              onChange={(_e, { value }) => setSelectedPhoneNumber(value)}
              disabled={hasPhoneNumber}
              error={validationResult.selectedPhoneNumber.$error}
              verticalLayout="normal"
              layout="vert"
            />

            {availablePhoneNumbers.length === 0 ? (
              <Banner
                aria-label="No Available Numbers"
                className="rounded text-xs"
                Icon={WarningIcon}
                theme="info"
              >
                We couldn&apos;t find any matching available numbers
              </Banner>
            ) : null}
          </>
        ) : null}
      </div>
    </Form>
  );
};
