import { AppointmentCategoryRequest, AppointmentCategoryVO } from "@libs/api/generated-api";

export const convertToAppointmentCategoryRequest = (
  category: AppointmentCategoryVO,
  overrides?: Partial<AppointmentCategoryRequest>
): AppointmentCategoryRequest => {
  const { providers: existingProviders, ...fieldsToCopy } = category;

  return { ...fieldsToCopy, providerIds: existingProviders.map((provider) => provider.id), ...overrides };
};
