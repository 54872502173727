import { FC } from "react";
import { LexicalComposer } from "@lexical/react/LexicalComposer";
import { PlainTextPlugin } from "@lexical/react/LexicalPlainTextPlugin";
import { ContentEditable } from "@lexical/react/LexicalContentEditable";
import { HistoryPlugin } from "@lexical/react/LexicalHistoryPlugin";
import { OnChangePlugin } from "@lexical/react/LexicalOnChangePlugin";
import LexicalErrorBoundary from "@lexical/react/LexicalErrorBoundary";
import { TemplateVariableGroupVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { TemplateVariablePlugin } from "components/UI/RichTextEditor/TemplateVariablePlugin";
import { TemplateVariableNode } from "components/UI/RichTextEditor/TemplateVariableNode";
import { useSafeInitialState } from "components/UI/RichTextEditor/useSafeInitialState";
import { useDebouncedOnEditorStateChange } from "components/UI/RichTextEditor/useDebouncedOnChange";
import { EditorProps } from "components/UI/RichTextEditor/types";
import { Placeholder } from "components/UI/RichTextEditor/Placeholder";
import { PreventNewLinesPlugin } from "components/UI/RichTextEditor/PreventNewLinesPlugin";

interface Props extends EditorProps {
  templateVariables: TemplateVariableGroupVO[];
  multiline?: boolean;
  fullHeight?: boolean;
}

const theme = {
  paragraph: "leading-5",
};

export const TemplateVariablesEditor: FC<Props> = ({
  initialConfig,
  onChange,
  initialEditorState,
  templateVariables,
  placeholder,
  className,
  multiline = true,
  fullHeight = true,
  ...contentEditableProps
}) => {
  const safeInitialState = useSafeInitialState(initialEditorState);

  const handleChange = useDebouncedOnEditorStateChange(onChange);

  return (
    <LexicalComposer
      initialConfig={{
        ...initialConfig,
        nodes: [TemplateVariableNode],
        editorState: safeInitialState,
        theme,
      }}
    >
      <div className={cx("relative", className)}>
        <PlainTextPlugin
          contentEditable={
            <ContentEditable
              {...contentEditableProps}
              className={cx(
                "text-xs focus-visible:outline-none leading-5",
                multiline ? "overflow-y-auto" : "overflow-x-auto whitespace-pre",
                fullHeight && "h-full"
              )}
            />
          }
          placeholder={<Placeholder content={placeholder} />}
          ErrorBoundary={LexicalErrorBoundary}
        />
      </div>
      <OnChangePlugin onChange={handleChange} />
      <HistoryPlugin />
      <TemplateVariablePlugin templateVariables={templateVariables} />
      {/*  eslint-disable-next-line react/jsx-no-useless-fragment */}
      {multiline ? <></> : <PreventNewLinesPlugin />}
    </LexicalComposer>
  );
};
