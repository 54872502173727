import { toFilterComponentsProps } from "@libs/components/UI/queryFilterPillsUtils";
import { InsuranceCarriersQuery } from "utils/routing/settings";

export const getInsuranceCarriersFilterProps = (queryState: InsuranceCarriersQuery) =>
  toFilterComponentsProps(queryState, [
    {
      type: "list",
      prop: "inNetwork",
      format: (val) => (val === "true" ? "In Network" : "Out of Network"),
    },
    {
      type: "default",
      prop: "searchString",
      format: (val) => `Search: ${val.trim()}`,
    },
  ]);
