import React from "react";
import { MedicalImageVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { ImageDrop } from "components/PatientProfile/Imaging/ImageSidebarNavigation/ImageDrop";
import { ImageDetailProps } from "components/PatientProfile/Imaging/MountRoute/types";
import { MountFormat } from "utils/routing/patient";

type Props = {
  images: {
    image: MedicalImageVO | undefined;
    index: number;
    absoluteIndex: number;
  }[];
  format: MountFormat;
  isShowingMetadata: boolean;
  canDragFrom: boolean;
  onEditImage: ImageDetailProps["onEditImage"];
  onImageMounted: (image: MedicalImageVO, mountIndex: number) => void;
  imageEditing?: MedicalImageVO;
  dragDropType: string;
  className?: string;
} & Omit<ImageDetailProps, "onEditImage">;

export const ImageSideBySideViewer: React.FC<Props> = ({
  images,
  format,
  onEditImage,
  isShowingMetadata,
  onImageUpdate,
  onImageMounted,
  canDragFrom,
  dragDropType,
  devices,
  className,
  onClickTeeth,
}) => {
  return (
    <div
      className={cx(
        "grid flex-1 h-full auto-cols-fr auto-rows-fr",
        format === "single" ? "grid-cols-1" : "grid-cols-2",
        className
      )}
    >
      {images.map((item) => {
        return (
          <ImageDrop
            data={item}
            key={item.index}
            onEditImage={onEditImage}
            dragDropType={dragDropType}
            isShowingMetadata={isShowingMetadata}
            onImageUpdate={onImageUpdate}
            canDragFrom={canDragFrom}
            onImageMounted={onImageMounted}
            devices={devices}
            format={format}
            index={item.index}
            onClickTeeth={onClickTeeth}
          />
        );
      })}
    </div>
  );
};
