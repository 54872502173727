import { useCallback } from "react";
import { PatientInsuranceVO, SubscriberVO } from "@libs/api/generated-api";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";

const convertSubscriber = (subscriber?: SubscriberVO) => {
  const { annualDeductibleRemaining, annualMaximumRemaining, ...remaining } = subscriber ?? {};

  return {
    ...remaining,
    familyAnnualDeductibleRemaining: annualDeductibleRemaining,
    familyAnnualMaximumRemaining: annualMaximumRemaining,
  };
};

export type SubscriberFields = Partial<ReturnType<typeof convertSubscriber>>;

export const useSubscriberFields = (subscriber?: SubscriberVO) => {
  const [subscriberFields, setSubscriberFields] = useObjectState<SubscriberFields>(() =>
    convertSubscriber(subscriber)
  );

  useSyncOnce((sub) => setSubscriberFields(convertSubscriber(sub)), subscriber);

  const handleCancelSubscriberFieldsChange = useCallback(
    (sub: SubscriberVO) => {
      setSubscriberFields(convertSubscriber(sub));
    },
    [setSubscriberFields]
  );

  return {
    handleCancelSubscriberFieldsChange,
    handleSubscriberFieldsChange: setSubscriberFields,
    subscriberFields,
  };
};

export type UseSubscriberFields = {
  subscriberFields: SubscriberFields;
  handleSubscriberFieldsChange: (partial: Partial<SubscriberFields>) => void;
};

export type InsuranceFields = {
  [K in keyof PatientInsuranceVO]?: PatientInsuranceVO[K];
};

export const useInsuranceFields = (insurance?: PatientInsuranceVO) => {
  const [insuranceFields, setInsuranceFields] = useObjectState<InsuranceFields>(insurance ?? {});

  useSyncOnce(setInsuranceFields, insurance);

  return { insuranceFields, handleInsuranceFieldsChange: setInsuranceFields };
};

export type UseInsuranceFields = {
  insuranceFields: InsuranceFields;
  handleInsuranceFieldsChange: (partial: Partial<InsuranceFields>) => void;
};
