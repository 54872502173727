import { FC, useState, useCallback } from "react";

import { useBoolean } from "@libs/hooks/useBoolean";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { StagedEobFile } from "components/Eob/AttachEobFilesModal";

import { addFilenameExtension, removeFilenameExtension } from "utils/files";

interface Props {
  file: StagedEobFile;
  onUpdateFile: (stagedId: StagedEobFile["stagedId"], updatedFile: Partial<StagedEobFile>) => void;
  onDeleteFile: (stagedId: StagedEobFile["stagedId"]) => void;
}

export const AttachEobFileRow: FC<Props> = ({ file, onUpdateFile, onDeleteFile }) => {
  const filename = file.fileData.name;
  const [draftFilename, setDraftFilename] = useState(removeFilenameExtension(filename));
  const editFilename = useBoolean(false);

  const handleUpdateFilename = useCallback(() => {
    onUpdateFile(file.stagedId, {
      fileData: new File([file.fileData], addFilenameExtension(file.fileData.name, draftFilename), {
        type: file.fileData.type,
      }),
    });
    editFilename.off();
  }, [onUpdateFile, file, draftFilename, editFilename]);

  return (
    <div className="flex items-center justify-between gap-x-4 p-2">
      <div className="flex items-center gap-x-1 min-h-9 overflow-hidden">
        {editFilename.isOff ? (
          <>
            <FloatingTooltip content={filename} theme="SMALL" placement="top-start">
              <span className="text-xs truncate">{filename}</span>
            </FloatingTooltip>

            <ButtonIcon
              SvgIcon={EditIcon}
              tooltip={{ content: "Edit Filename", theme: "SMALL" }}
              onClick={editFilename.on}
              size="sm"
            />
          </>
        ) : (
          <>
            <FormFieldInput
              value={draftFilename}
              onChange={(e) => setDraftFilename(e.target.value)}
              onBlur={handleUpdateFilename}
              autoFocus
            />
            <Button onClick={handleUpdateFilename} theme="primary" size="small">
              Save
            </Button>
          </>
        )}
      </div>

      <ButtonIcon
        SvgIcon={DeleteIcon}
        tooltip={{ content: "Remove File", theme: "SMALL" }}
        onClick={() => onDeleteFile(file.stagedId)}
        theme="primary"
      />
    </div>
  );
};
