import { FC } from "react";
import { Link } from "react-router-dom";
import { CompanyOnboardingStatusVO } from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { ReactComponent as WarningIcon } from "@libs/assets/icons/warning.svg";
import { ReactComponent as HighPriorityIcon } from "@libs/assets/icons/high-priority.svg";
import GustoLogo from "assets/images/logo-gusto.svg";
import { paths } from "utils/routing/paths";
import { GustoOnboardingOverlay } from "components/Settings/PracticeSetup/Sections/PayrollInformation/GustoOnboardingOverlay";
import { PayrollCard } from "./PayrollCard";
import { GustoTosModal } from "./GustoTosModal";
import { GustoMigrationModal } from "./GustoMigrationModal";
import { useOnboardingFlow, useMigrationFlow, useSetupFlow } from "./hooks";

export const PayrollSettings: FC<{
  onboardingStatus: CompanyOnboardingStatusVO["status"];
}> = ({ onboardingStatus }) => {
  const { gustoOnboardingOverlay, linkToGustoPracticeFlow } = useOnboardingFlow(onboardingStatus);
  const { gustoMigrationModal } = useMigrationFlow(onboardingStatus);
  const { gustoTosModal, handleTosAccept, linkToGustoOauth, isOnboardingNewPractice } =
    useSetupFlow(gustoOnboardingOverlay);

  return (
    <div className="flex flex-col gap-y-5">
      <div className="font-sans">
        <p className="text-sm text-black">
          {onboardingStatus === "ONBOARDED" ? (
            <>
              Your practice is currently set up with{" "}
              <img alt="Gusto Logo" className="h-7 inline" src={GustoLogo} /> for payroll management.
            </>
          ) : (
            <>
              Archy partners with <img alt="Gusto Logo" className="h-7 inline" src={GustoLogo} /> for payroll
              management.
            </>
          )}
        </p>

        {onboardingStatus === "NOT_SETUP" && (
          <p className="text-xs text-greyDark">
            Make sure all your employees are fully onboarded with Archy before you integrate with Gusto.{" "}
            <Link className="font-sansSemiBold text-primaryTheme" to={paths.employees()}>
              View Employees Section
            </Link>
          </p>
        )}
      </div>

      {onboardingStatus === "NOT_SETUP" ? (
        <div className="flex gap-x-5">
          <PayrollCard
            primaryText="New to Gusto?"
            secondaryText="Let's get you started with a fresh account."
            onClick={() => gustoTosModal.open({ setupPractice: true })}
          />

          <PayrollCard
            primaryText="Already a Gusto customer?"
            secondaryText="Sign into your account to integrate with Archy."
            warningText="Make sure you have your Archy employee list up to date."
            onClick={() => gustoTosModal.open({ setupPractice: false })}
          />
        </div>
      ) : isOneOf(onboardingStatus, ["NEEDS_MIGRATION", "NEEDS_ONBOARDING", "NOT_APPROVED", "ONBOARDED"]) ? (
        <PayrollSettingsCard onboardingStatus={onboardingStatus} onClick={linkToGustoPracticeFlow} />
      ) : null}

      {onboardingStatus === "NEEDS_AUTHENTICATION" ? (
        <PayrollCard
          primaryText="Payroll Settings"
          secondaryText="Sign back into Gusto to access your account."
          onClick={linkToGustoOauth}
        />
      ) : null}

      {gustoTosModal.isOpen && (
        <GustoTosModal onTosAccept={handleTosAccept} onRequestClose={gustoTosModal.close} />
      )}

      {gustoMigrationModal.isOn && <GustoMigrationModal onRequestClose={gustoMigrationModal.off} />}

      {(isOnboardingNewPractice || gustoOnboardingOverlay.isOn) && <GustoOnboardingOverlay />}
    </div>
  );
};

const PayrollSettingsCard: FC<{
  onboardingStatus: CompanyOnboardingStatusVO["status"];
  onClick: Func;
}> = ({ onboardingStatus, onClick }) => (
  <>
    {onboardingStatus === "NOT_APPROVED" ? (
      <div className="flex items-center gap-x-1.5 mb-1 text-xs">
        <WarningIcon className="w-5 h-5 fill-red flex-none" />
        Your new Gusto account is currently under review, and may take 2 business days to be approved.
      </div>
    ) : null}

    <PayrollCard
      primaryText="Payroll Settings"
      secondaryText={
        onboardingStatus === "NEEDS_ONBOARDING" ? (
          <div className="flex items-center gap-x-1.5">
            <HighPriorityIcon className="w-5 h-5 fill-orange flex-none" />
            Complete onboarding.
          </div>
        ) : (
          "Edit Gusto's Payroll Settings."
        )
      }
      onClick={onClick}
      disabled={isOneOf(onboardingStatus, ["NEEDS_MIGRATION", "NOT_APPROVED"])}
    />
  </>
);
