import {
  BoolVal,
  Enum,
  NumVal,
  StrVal,
  routesConfigHelper,
  ParsedParams,
  NumCSV,
  StrCSV,
  withDefault,
  NumberOrNew,
  NullableBoolVal,
} from "@libs/router/url";

export type SettingsSections =
  | "training-sessions"
  | "sensor-testing"
  | "procedure-shortcuts"
  | "clinical-note-forms"
  | "business-information"
  | "contact-information"
  | "working-hours"
  | "holiday-calendar"
  | "practice-rooms"
  | "phones"
  | "patient-folders"
  | "payroll-information"
  | "procedure-codes"
  | "appointment-tags"
  | "appointment-card-design"
  | "appointment-categories"
  | "provider-scheduling"
  | "online-booking"
  | "fee-schedules"
  | "insurances"
  | "insurance-plans"
  | "default-limitations"
  | "claim-settings"
  | "labs"
  | "lab-case-types"
  | "lab-return-reasons"
  | "billing-merchant"
  | "pos-devices"
  | "payment-patient-portal"
  | "custom-adjustments"
  | "ip-auth"
  | "mfa"
  | "server-access"
  | "referral-options"
  | "setup-kiosk"
  | "forms"
  | "email-authentication"
  | "messaging-services"
  | "email-preferences"
  | "notes"
  | "imaging";

export const SettingsPathParams = {
  section: Enum<SettingsSections>().Val([
    "business-information",
    "contact-information",
    "working-hours",
    "holiday-calendar",
    "practice-rooms",
    "imaging",
    "phones",
    "patient-folders",
    "payroll-information",
    "procedure-codes",
    "appointment-tags",
    "appointment-card-design",
    "appointment-categories",
    "provider-scheduling",
    "online-booking",
    "fee-schedules",
    "insurances",
    "insurance-plans",
    "default-limitations",
    "claim-settings",
    "clinical-note-forms",
    "procedure-shortcuts",
    "billing-merchant",
    "pos-devices",
    "forms",
    "referral-options",
    "setup-kiosk",
    "payment-patient-portal",
    "custom-adjustments",
    "ip-auth",
    "mfa",
    "server-access",
    "email-authentication",
    "messaging-services",
    "training-sessions",
    "sensor-testing",
    "email-preferences",
    "labs",
    "lab-case-types",
    "lab-return-reasons",
    "notes",
  ]),
};

// FEE SCHEDULES LIST PAGE
const FeeSchedulesQueryParams = {
  carrierIds: NumCSV,
  inNetwork: StrCSV,
  providerIds: NumCSV,
  searchString: StrVal,
  states: StrCSV,
  types: StrCSV,
};

export type FeeSchedulesQuery = ParsedParams<(typeof SettingsRoutesConfig.feeSchedules)["query"]>;
export type FeeSchedulesQueryUpdates = Partial<FeeSchedulesQuery>;

// FEE SCHEDULE DETAILS PAGE
export const FeeScheduleDetailsPathParams = {
  feeScheduleId: NumberOrNew,
};

export const FeeScheduleDetailsQueryParams = { copyId: NumVal, from: StrVal };
export type FeeSchedulesDetailsQuery = Partial<
  ParsedParams<(typeof SettingsRoutesConfig.feeScheduleDetails)["query"]>
>;

// INSURANCE CARRIERS LIST PAGE
export type CarriersSortKeys = "name" | "patientCount" | "payerId" | "planCount";

const SortEnum = Enum<"ASCENDING" | "DESCENDING">().Val(["ASCENDING", "DESCENDING"]);

const InsuranceCarriersQueryParams = {
  inNetwork: StrCSV,
  orderBy: withDefault(SortEnum, "ASCENDING"),
  searchString: StrVal,
  sortColumn: withDefault(
    Enum<CarriersSortKeys>().Val(["name", "patientCount", "payerId", "planCount"]),
    "name"
  ),
};

export type InsuranceCarriersQuery = ParsedParams<(typeof SettingsRoutesConfig.insuranceCarriers)["query"]>;
export type InsuranceCarriersQueryUpdates = Partial<InsuranceCarriersQuery>;

// INSURANCE CARRIER DETAILS PAGE
const InsuranceCarrierDetailsPathParams = {
  carrierId: NumberOrNew,
};

export type InsuranceCarrierDetailsQuery = Partial<
  ParsedParams<(typeof SettingsRoutesConfig.insuranceDetails)["query"]>
>;
export type InsuranceCarrierDetailsQueryUpdates = Partial<InsuranceCarrierDetailsQuery>;

// INSURANCE PLANS LIST PAGE
export type PlansSortKeys = "groupName" | "groupNumber" | "employerName" | "patientCount";

const InsurancePlansQueryParams = {
  carrierIds: NumCSV,
  feeScheduleId: NumVal,
  from: StrVal,
  hidePlansWithNoPatients: BoolVal,
  searchString: StrVal,
  sortColumn: withDefault(
    Enum<PlansSortKeys>().Val(["groupName", "groupNumber", "employerName", "patientCount"]),
    "groupNumber"
  ),
  sortDirection: withDefault(SortEnum, "ASCENDING"),
};

export type InsurancePlansQuery = ParsedParams<(typeof SettingsRoutesConfig.insurancePlans)["query"]>;
export type InsurancePlansQueryUpdates = Partial<InsurancePlansQuery>;

// INSURANCE PLAN DETAILS PAGE
const InsurancePlanDetailsPathParams = {
  planUuid: {
    get: (val: string) => val,
    set: (val: string) => `${val}`,
    type: "string" as const,
  },
};

export const InsurancePlanDetailsQueryParams = { copyUuid: StrVal, from: StrVal };
export type InsurancePlanDetailsQuery = Partial<
  ParsedParams<(typeof SettingsRoutesConfig.insurancePlanDetails)["query"]>
>;

const formDetailParams = {
  params: {
    formId: StrVal,
  },
  query: {
    from: StrVal,
  },
};

export const NotesTagsQueryParams = {
  typeFilters: Enum<"ARCHY" | "CUSTOM">().CSV(["ARCHY", "CUSTOM"]),
  includeArchived: NullableBoolVal,
  addTag: BoolVal,
  ediTag: NumVal,
  filterTags: BoolVal,
};

export const SettingsRoutesConfig = routesConfigHelper({
  authenticateGusto: {
    path: "/settings/authenticate-gusto",
    query: {
      code: StrVal,
    },
  },
  gusto: {
    path: "/settings/gusto",
  },
  settingsSection: {
    path: "/settings/:section",
    params: SettingsPathParams,
  },
  settingsActivateDentalXChange: {
    path: "/settings/claim-settings/activate-dentalxchange",
  },
  feeSchedules: {
    path: "/settings/fee-schedules",
    query: FeeSchedulesQueryParams,
  },
  feeScheduleDetails: {
    path: "/settings/fee-schedules/:feeScheduleId",
    query: FeeScheduleDetailsQueryParams,
    params: FeeScheduleDetailsPathParams,
  },
  insuranceCarriers: {
    path: "/settings/insurances",
    query: InsuranceCarriersQueryParams,
  },
  insuranceDetails: {
    path: "/settings/insurances/:carrierId",
    params: InsuranceCarrierDetailsPathParams,
    query: {
      from: StrVal,
    },
  },
  insurancePlans: {
    path: "/settings/insurance-plans",
    query: InsurancePlansQueryParams,
  },
  insurancePlanDetails: {
    path: "/settings/insurance-plans/:planUuid",
    params: InsurancePlanDetailsPathParams,
    query: InsurancePlanDetailsQueryParams,
  },
  insurancePreferences: {
    path: "/settings/default-limitations",
  },
  clinicalNoteForms: {
    path: "/settings/clinical-note-forms",
  },
  clinicalNoteFormPreview: {
    path: "/settings/clinical-note-forms/:formId/preview",
    ...formDetailParams,
  },
  editClinicalNotesForms: {
    path: "/settings/clinical-note-forms/:formId/edit",
    ...formDetailParams,
  },

  // The below two routes are effectively the same, but the training-sessions and sensor-testing part
  // dictate which nav item gets selected in settings
  trainingSessionsSchedule: {
    path: "/settings/training-sessions/:meetingId",
    params: {
      meetingId: StrVal,
    },
  },
  sensorTestingSchedule: {
    path: "/settings/sensor-testing/schedule",
  },
  forms: {
    path: "/settings/forms",
  },
  editForm: {
    path: "/settings/forms/:formId/edit",
    ...formDetailParams,
  },
  noteTags: {
    path: "/settings/notes/tags",
    query: NotesTagsQueryParams,
  },
  noteAlerts: {
    path: "/settings/notes/alerts",
  },
  setupFinix: {
    path: "/settings/billing-merchant/setup-finix",
  },
  setupTwilio: {
    path: "/settings/messaging-services/setup-twilio/:step",
    params: {
      step: Enum<"practice" | "phone">().Val(["practice", "phone"]),
    },
    query: {
      phoneOnly: withDefault(BoolVal, false),
      from: StrVal,
    },
  },
});
