import { useMemo } from "react";
import { EmployeeVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { isDefined } from "@libs/utils/types";
import { isOneOf } from "@libs/utils/isOneOf";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as Delete } from "@libs/assets/icons/delete.svg";
import { ReactComponent as Archive } from "@libs/assets/icons/archive-folder.svg";
import { UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";

interface Props {
  selectedRows: Set<number>;
  employeesQuery: UseInfiniteApiListQueryResult<EmployeeVO>;
  className?: string;
  onArchiveEmployees: Func;
  onDeleteEmployees: Func;
}

const cxStyles = {
  actionContainer: "mr-6 last:mr-0",
};

const noEmployeesSelectedMessage = "Select employees";

export const BulkActionsMenu: React.FC<Props> = ({
  selectedRows,
  employeesQuery,
  className,
  onArchiveEmployees,
  onDeleteEmployees,
}) => {
  const employees = useFlattenPages(employeesQuery.data);

  const selectedEmployees = useMemo(() => {
    if (!employees) {
      return [];
    }

    const selected = [...selectedRows].map((selectedId) => employees.find((emp) => emp.id === selectedId));

    return selected.filter(isDefined);
  }, [selectedRows, employees]);

  const { canBulkDelete, canBulkArchive, hasSelectedEmployees } = useMemo(() => {
    return {
      hasSelectedEmployees: selectedEmployees.length > 0,
      canBulkArchive: selectedEmployees.every((emp) =>
        isOneOf(String(emp.employmentDetails.status), ["ACTIVE", "INACTIVE", "FURLOUGHED"])
      ),
      canBulkDelete: selectedEmployees.every((emp) => emp.employmentDetails.status === "PENDING"),
    };
  }, [selectedEmployees]);

  const tooltips = useMemo(() => {
    return hasSelectedEmployees
      ? {
          archive: canBulkArchive
            ? "Terminate Employees"
            : "Only Active, Inactive, and Furloughed employees can be Terminated",
          remove: canBulkDelete
            ? "Remove Employees"
            : "Only Pending employees can be removed. Others can be Terminated",
        }
      : {
          archive: noEmployeesSelectedMessage,
          remove: noEmployeesSelectedMessage,
        };
  }, [canBulkArchive, canBulkDelete, hasSelectedEmployees]);

  return (
    <div className={cx("flex items-center", className)}>
      <div className="flex items-center">
        <ButtonIcon
          SvgIcon={Archive}
          disabled={!hasSelectedEmployees || !canBulkArchive}
          tooltip={{ content: tooltips.archive, theme: canBulkArchive ? "SMALL" : "MEDIUM" }}
          size="md"
          theme="primary"
          onClick={() => onArchiveEmployees()}
          className={cxStyles.actionContainer}
        />
        <ButtonIcon
          SvgIcon={Delete}
          disabled={!hasSelectedEmployees || !canBulkDelete}
          tooltip={{ content: tooltips.remove, theme: canBulkDelete ? "SMALL" : "MEDIUM" }}
          size="md"
          theme="primary"
          onClick={() => onDeleteEmployees()}
          className={cxStyles.actionContainer}
        />
      </div>
    </div>
  );
};
