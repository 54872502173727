import { useCallback, useEffect, useRef } from "react";
import { addBreadcrumb } from "@sentry/react";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useDebouncedCallback } from "use-debounce";
import { useImagingHub } from "components/ImageCapturing/ImagingHubContext";
import {
  CaptureInfo,
  CaptureStatus,
  DeviceInfo,
  ErrorHubResponse,
  SuccessHubResponse,
  isHubErrorResponse,
} from "api/imaging/imaging-hub";
import { handleError } from "utils/handleError";

import { ImageCaptureResult } from "api/imaging/imaging-api";
import { CaptureDeviceSettings } from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/types";

const PROCESS_IMAGES_DEBOUNCE_MS = 1600;

export const useSensorCapture = () => {
  const [captureState, updateCaptureState] = useObjectState<{
    lastCaptureStatus?: CaptureStatus;
    hasStartedCapture: boolean;
  }>({
    lastCaptureStatus: undefined,
    hasStartedCapture: false,
  });

  const { hub, availableDevicesQuery } = useImagingHub();

  const availableDevices = availableDevicesQuery.data;
  const captureResults = useRef<ImageCaptureResult[]>([]);

  const onImageResult = useRef<((imageCaptureResult: ImageCaptureResult) => void) | null>(null);
  const onCaptureStatusRef = useRef<((status: CaptureStatus) => void) | null>(null);

  // This should only be used for reverse capture because the images come in all at once.
  const debouncedCaptureCallback = useDebouncedCallback((func: Func) => {
    func();
    // Time is set to 1.6 (same as Dynamsoft) seconds for Dexis sensors using FMS.
  }, PROCESS_IMAGES_DEBOUNCE_MS);

  const stopCapture = useCallback(() => {
    updateCaptureState({
      hasStartedCapture: false,
    });

    return hub.stopCapturing();
  }, [hub, updateCaptureState]);

  useEffect(() => {
    return () => {
      if (captureState.hasStartedCapture) {
        // Ensure we stop capturing on route changes. Such as switching mounts or clicking done.
        // Each capture session with AIS is tied to a specific mount, and date so this is necessary.
        stopCapture();
      }
    };
  }, [captureState.hasStartedCapture, stopCapture]);

  useEffect(() => {
    const unsubscribers = [
      hub.onCaptureStatus((status) => {
        updateCaptureState({
          lastCaptureStatus: status,
        });
        onCaptureStatusRef.current?.(status);
      }),
      hub.onImageResult((image) => {
        onImageResult.current?.(image);
      }),
    ];

    return () => {
      for (const unsubscribe of unsubscribers) {
        unsubscribe();
      }
    };
  }, [hub, updateCaptureState]);

  const startSensorCapture = useCallback(
    ({
      deviceId,
      captureInfo,
      onCapture,
      sensorSettings,
      onEndCapture,
    }: {
      deviceId: string;
      captureInfo?: CaptureInfo;
      sensorSettings?: CaptureDeviceSettings;
      onCapture: (params: {
        result: ImageCaptureResult;
        startCapture: Func;
        device: DeviceInfo;
        stopCapture: () => Promise<ErrorHubResponse | SuccessHubResponse>;
      }) => void;
      onEndCapture?: () => void;
    }) => {
      const device = availableDevices?.find((item) => item.name === deviceId);

      addBreadcrumb({
        level: "info",
        category: "imaging",
        message: "startSensorCapture called",
        data: {
          deviceName: device?.name,
          agentType: "AIS",
        },
      });

      if (device) {
        const handleCaptureError = (e: unknown) => {
          handleError(e);
          stopCapture();
          onEndCapture?.();
        };

        const startCapture = async () => {
          captureResults.current = [];
          updateCaptureState({
            hasStartedCapture: true,
          });

          try {
            const result = await hub.startCapturing({
              driverType: device.driverType,
              deviceName: device.name,
              captureInfo,
            });

            if (isHubErrorResponse(result)) {
              handleCaptureError(result);
            }
          } catch (e) {
            handleCaptureError(e);
          }
        };

        startCapture();

        onCaptureStatusRef.current = (status) => {
          if (status.state === "Finished") {
            onEndCapture?.();
            stopCapture();
          } else if (status.state === "Error") {
            const error: ErrorHubResponse = {
              errors: [
                {
                  message: status.message ?? "An Unknown Error Occurred",
                },
              ],
            };

            handleError(error);
            onEndCapture?.();
            stopCapture();
          }
        };
        onImageResult.current = (result) => {
          if (sensorSettings?.reverseImport) {
            captureResults.current.push(result);
            debouncedCaptureCallback(() => {
              // Debounced is only used when we reverse import, in which case we must wait
              // until all the images have come through.
              captureResults.current.reverse().forEach((image) => {
                onCapture({ result: image, startCapture, stopCapture, device });
              });
              captureResults.current = [];
            });
          } else {
            onCapture({ result, startCapture, stopCapture, device });
          }
        };
      }
    },
    [availableDevices, stopCapture, updateCaptureState, hub, debouncedCaptureCallback]
  );

  return {
    startSensorCapture,
    captureState,
  };
};
export type UseSensorCapture = ReturnType<typeof useSensorCapture>;
