import React from "react";
import {
  InsuranceAdjustmentEntryVO,
  InsuranceAdjustmentResponse,
  InsuranceAdjustmentSubtotalVO,
  InsuranceAdjustmentTotalVO,
} from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getAbsoluteUrl } from "@libs/utils/location";
import { formatPhoneNumber } from "@libs/utils/phone";
import { formatCurrency } from "@libs/utils/currency";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { EMPTY_ROW, formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";
import { handleError } from "utils/handleError";
import { useDownloadQueryPages } from "components/Dashboard/hooks/useDownloadInfiniteQuery";
import { paths } from "utils/routing/paths";
import { flattenCollectionByCarrier } from "components/Dashboard/utils/flattenCollectionByCarrier";
import { getTotals } from "components/Dashboard/Tables/utils";
import { INSURANCE_ADJUSTMENT_HEADERS } from "components/Dashboard/Adjustments/constants";
import { TimeSegment } from "utils/routing/dashboard";
import { useCurrentUrl, useOrigin } from "contexts/OriginContext";

const formatInsuranceAdjustmentsToCsv = (
  carriers: {
    subtotal: InsuranceAdjustmentSubtotalVO;
    rows: InsuranceAdjustmentEntryVO[];
  }[],
  totals: InsuranceAdjustmentTotalVO,
  origin: string
) => {
  const csvHeaders = formatCsvRow([
    "Carrier Name",
    "Carrier Phone",
    ...INSURANCE_ADJUSTMENT_HEADERS.map(({ label }) => label).filter(Boolean),
    "Claim URL",
  ]);

  const rowsContent = carriers.flatMap(({ subtotal: { carrier }, rows }) => {
    const claimItems = rows.map(
      ({
        patient,
        serviceDate,
        primarySubscriberPatient,
        insuranceOrdinal,
        insuranceAdjustmentState,
        insuranceAdjustmentAmount,
        ucrFee,
        netProduction,
        claimUuid,
      }) => {
        return formatCsvRow([
          carrier.name,
          `${carrier.phone ? ` ${formatPhoneNumber(carrier.phone) ?? ""}` : ""}`,
          patient.fullDisplayName,
          serviceDate,
          primarySubscriberPatient?.fullDisplayName ?? "",
          insuranceOrdinal ?? "",
          sentenceCaseConstant(insuranceAdjustmentState),
          formatCurrency(ucrFee),
          formatCurrency(insuranceAdjustmentAmount),
          formatCurrency(netProduction),
          claimUuid ? getAbsoluteUrl(origin, paths.claim({ claimUuid })) : "",
        ]);
      }
    );

    return claimItems;
  });

  const totalsContent = formatCsvRow([
    "",
    "",
    "",
    "",
    "",
    "",
    "Grand Total:",
    formatCurrency(totals.totalUcrFeeAmount),
    formatCurrency(totals.totalAdjustmentAmount),
    formatCurrency(totals.totalNetProductionAmount),
    "",
  ]);

  return [csvHeaders, ...rowsContent, EMPTY_ROW, totalsContent];
};

export const useDownloadInsuranceAdjustmentsCsv = (params: {
  selectedTimeSegment: TimeSegment;
  insuranceAdjustmentInfiniteQuery: UseInfiniteApiQueryResult<InsuranceAdjustmentResponse>;
}) => {
  const downloading = useBoolean(false);
  const origin = useOrigin();
  const currentUrl = useCurrentUrl();
  const { startDownload } = useDownloadQueryPages(params.insuranceAdjustmentInfiniteQuery);

  return {
    isDownloading: downloading.isOn,
    downloadCSV: React.useCallback(async () => {
      downloading.on();

      try {
        const result = await startDownload();
        const carriers = flattenCollectionByCarrier<
          InsuranceAdjustmentEntryVO,
          InsuranceAdjustmentSubtotalVO,
          InsuranceAdjustmentResponse
        >(result);
        const totals = getTotals(result);

        if (totals) {
          const docMetadata = getArchyCsvMetadata(currentUrl, params.selectedTimeSegment);
          const csvRows = [
            ...docMetadata.headers,
            ...formatInsuranceAdjustmentsToCsv(carriers, totals, origin),
          ];

          saveCsv(csvRows, `InsuranceAdjustment_${docMetadata.fileNameTag}`);
        }
      } catch (e) {
        handleError(e);
      }

      downloading.off();
    }, [downloading, startDownload, params.selectedTimeSegment, origin, currentUrl]),
  };
};
