import { QueryClient } from "@tanstack/react-query";
import {
  BatchUpsertFrequencyLimitationCategoriesRequest,
  ErrorResponse,
  HideUnhideFrequencyLimitationCategoryRequest,
  HttpResponse,
  InsuranceCarrierRequest,
  InsurancePlanMergeRequest,
  SuccessResponseInsuranceCarrierDetailsVO,
} from "@libs/api/generated-api";
import { updateCachedData } from "@libs/utils/queryCache";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";

const handleUpdateCarrierSuccess = (
  queryClient: QueryClient,
  response: HttpResponse<SuccessResponseInsuranceCarrierDetailsVO, ErrorResponse>,
  { practiceId, carrierId }: { carrierId: number; practiceId: number }
) => {
  updateCachedData(
    queryClient,
    {
      queryKey: [getQueryKey("practices", "getCarrier"), { practiceId, carrierId }],
    },
    () => {
      return response.data.data;
    }
  );

  const keys = [
    // Insurance Carrier
    [getQueryKey("practices", "getAllPracticeInsuranceCarriers"), { practiceId }],
    [getQueryKey("practices", "getPracticeInsuranceCarriers"), { practiceId }],

    // Practice Insurance
    [getQueryKey("practices", "getInsurancePlan"), { practiceId }],

    // Patient Insurance
    [getQueryKey("practices", "getPatientInsurance"), { practiceId }],
    [getQueryKey("practices", "getPatientInsurances"), { practiceId }],

    // Family Members
    [getQueryKey("v2", "getFamilyMembersV2"), { practiceId }],
  ];

  for (const key of keys) {
    queryClient.invalidateQueries(key);
  }
};

export const updateArchyCarrier = makeMutation({
  mutationKey: ["insurances", "updateArchyCarrier"],
  formatParams: (args: { carrierId: number; practiceId: number; data: InsuranceCarrierRequest }) => [
    args.carrierId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { carrierId, practiceId }) => {
      handleUpdateCarrierSuccess(queryClient, response, { carrierId, practiceId });
    },
  }),
});

export const updateCustomCarrier = makeMutation({
  mutationKey: ["practices", "updateCustomCarrier"],
  formatParams: (args: { practiceId: number; carrierId: number; data: InsuranceCarrierRequest }) => [
    args.practiceId,
    args.carrierId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, carrierId }) => {
      handleUpdateCarrierSuccess(queryClient, response, { carrierId, practiceId });
    },
  }),
});

export const createCustomCarrier = makeMutation({
  mutationKey: ["practices", "createCustomCarrier"],
  formatParams: (args: { practiceId: number; data: InsuranceCarrierRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      const keys = [
        [getQueryKey("practices", "getAllPracticeInsuranceCarriers"), { practiceId }],
        [getQueryKey("practices", "getPracticeInsuranceCarriers"), { practiceId }],
      ];

      for (const key of keys) {
        queryClient.invalidateQueries(key);
      }
    },
  }),
});

export const batchUpsertFrequencyLimitationCategories = makeMutation({
  mutationKey: ["practices", "batchUpsertFrequencyLimitationCategories"],
  formatParams: (args: { practiceId: number; data: BatchUpsertFrequencyLimitationCategoriesRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("v2", "getFrequencyLimitationCategories"), { practiceId }]);
    },
  }),
});

export const hideUnhideDefaultFrequencyLimitationCategory = makeMutation({
  mutationKey: ["practices", "hideUnhideDefaultFrequencyLimitationCategory"],
  formatParams: (args: {
    practiceId: number;
    frequencyLimitationCategoryId: number;
    data: HideUnhideFrequencyLimitationCategoryRequest;
  }) => [args.practiceId, args.frequencyLimitationCategoryId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("v2", "getFrequencyLimitationCategories"), { practiceId }]);
    },
  }),
});

export const deleteFrequencyLimitationCategory = makeMutation({
  mutationKey: ["practices", "deleteFrequencyLimitationCategory"],
  formatParams: (args: { practiceId: number; frequencyLimitationCategoryId: number }) => [
    args.practiceId,
    args.frequencyLimitationCategoryId,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("v2", "getFrequencyLimitationCategories"), { practiceId }]);
    },
  }),
});

export const mergeInsurancePlans = makeMutation({
  mutationKey: ["practices", "mergeInsurancePlans"],
  formatParams: (args: { practiceId: number; data: InsurancePlanMergeRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getInsurancePlans"), { practiceId }]);
    },
  }),
});
