import { absLessOrEqualTo, greaterThan, lessThan, maxLength } from "@libs/utils/validators";
import { formatCurrency } from "@libs/utils/currency";
import { NOTE_MAX_LENGTH } from "components/PatientProfile/Billing/FormComponents";

export const paymentNoteValidator = [
  {
    $v: maxLength(NOTE_MAX_LENGTH),
    $error: `Note cannot exceed ${NOTE_MAX_LENGTH} characters`,
  },
];

export const paymentAmountValidator = (maxAmountDue: number) => [
  {
    $v: maxAmountDue >= 0 ? greaterThan(0) : lessThan(0),
    $error: `Amount must be greater than ${formatCurrency(0)}`,
    $ignore: maxAmountDue === 0, // for when invoices cancel each other out
  },
  {
    $v: absLessOrEqualTo(maxAmountDue),
    $error: `Amount cannot be greater than ${formatCurrency(Math.abs(maxAmountDue))}`,
  },
];
