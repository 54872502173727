import { ComponentProps, FC, useCallback } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { DocumentVO, FolderVO } from "@libs/api/generated-api";
import { UserDocuments } from "components/UserDocuments/UserDocuments";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { PatientAppHistoryProvider } from "contexts/PatientLinksContext";

export const PatientDocuments: FC = () => {
  const { patientId } = usePathParams("patientTab");
  const navigate = useNavigate();

  const handleDocumentSelectionChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    (selection: Parameters<ComponentProps<typeof UserDocuments>["onSelectionChange"]>[0]) => {
      updatePatientDocumentsSearchQuery({ navigate, patientId, ...selection });
    },
    [navigate, patientId]
  );

  const { query } = useQueryParams("patientDocuments");

  return (
    <PatientAppHistoryProvider patientId={patientId} name="documents">
      <UserDocuments
        userId={patientId}
        selectedDocId={query.docId}
        selectedFolderId={query.folderId}
        onSelectionChange={handleDocumentSelectionChange}
      />
    </PatientAppHistoryProvider>
  );
};

const updatePatientDocumentsSearchQuery = ({
  navigate,
  patientId,
  folderId,
  docId,
}: {
  navigate: NavigateFunction;
  patientId: number;
  folderId?: FolderVO["id"];
  docId?: DocumentVO["id"];
}) => {
  const urlQuery = {
    ...(docId && { docId }),
    ...(folderId && { folderId }),
  };

  navigate(paths.patientDocuments({ patientId }, urlQuery), { replace: true });
};
