import { Dispatch, SetStateAction } from "react";
import { RoomVO } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { SlotSelections } from "components/ScheduleAppointments/types";

import { RoomOptions } from "components/ScheduleAppointments/RoomOptions";

interface Props {
  selections: SlotSelections;
  onSelectedSlotUpdated: Dispatch<SetStateAction<SlotSelections>>;
  availableRoomsQuery: ApiQueryResult<RoomVO[]>;
  rooms: RoomVO[];
  disabled: boolean;
}

export const AppointmentSelectRoom: React.FC<Props> = ({
  selections,
  onSelectedSlotUpdated,
  availableRoomsQuery,
  rooms,
  disabled,
}) => {
  return (
    <>
      <div className="mb-2 font-sansSemiBold text-xs">Select Room</div>
      <p className="mb-2 text-xs">Choose an appt slot before selecting a room.</p>
      <div className="mb-2">
        <RoomOptions
          disabled={disabled}
          rooms={availableRoomsQuery.data || rooms}
          selections={selections}
          onSelectedSlotUpdated={onSelectedSlotUpdated}
        />
      </div>
      {availableRoomsQuery.isError ? (
        <ErrorContent layout="horiz">
          There was a problem loading room availability. You can still schedule the appointment but it&apos;s
          possible the room you choose is already booked.
        </ErrorContent>
      ) : null}
    </>
  );
};
