import { Link, useLocation } from "react-router-dom";
import { PatientInsuranceResponse } from "@libs/api/generated-api";
import { Pill } from "@libs/components/UI/Pill";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus-small.svg";
import { getFullUrl } from "@libs/utils/location";
import { PatientInsuranceBadges } from "components/Insurance/PatientInsuranceBadges";
import { getInsuranceLabel } from "components/Insurance/utils";
import { paths } from "utils/routing/paths";

interface Props {
  patientId: number;
  insurance: PatientInsuranceResponse | undefined;
}

export const PatientAppointmentInsurance: React.FC<Props> = ({ patientId, insurance }) => {
  const location = useLocation();

  return (
    <div className="flex flex-col gap-y-1 text-xs">
      <span className="font-sansSemiBold leading-6">Insurance</span>

      {insurance ? (
        <>
          <span className="truncate">{getInsuranceLabel(insurance)}</span>
          <div className="flex squeeze-children">
            <PatientInsuranceBadges borderColor="slate100" badgeSize="lg" primaryInsurance={insurance} />
          </div>
        </>
      ) : (
        <Link
          className="w-fit"
          to={paths.patientInsuranceStep(
            { insuranceId: "new", step: "info", patientId },
            { from: getFullUrl(location) }
          )}
        >
          <Pill className="flex items-center gap-x-1 text-xs" theme="grey">
            Add
            <Icon SvgIcon={PlusIcon} theme="slate700" />
          </Pill>
        </Link>
      )}
    </div>
  );
};
