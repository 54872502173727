import { FC, useCallback, useEffect, useState } from "react";

import { FolderVO, UpdateDocumentRequest } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";

import { useAccount } from "@libs/contexts/AccountContext";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { UploadDocumentFilesModal } from "components/UserDocuments/UploadDocumentFilesModal";
import { ScanDocumentFileModal } from "components/UserDocuments/ScanDocumentFileModal";
import { PinGuard } from "components/UI/PinGuard";

import { getDocument, getDocumentUrl, getFolders } from "api/documents/queries";
import { deleteDocument, deleteFolder, moveDocument, updateDocument } from "api/documents/mutations";

import { useItemModal } from "hooks/useItemModal";

import { handleError } from "utils/handleError";

import { HeaderTitle } from "./HeaderTitle";
import { HeaderTools } from "./HeaderTools";
import { ExpandedFolders, TreeView } from "./TreeView";
import { PreviewDocument } from "./PreviewDocument";
import { FolderFlyover } from "./FolderFlyover";
import { DocumentModal } from "./DocumentModal";

interface Props {
  userId: number;
  selectedDocId: number | undefined;
  selectedFolderId: number | undefined;
  onSelectionChange: ({ folderId, docId }: { folderId?: number; docId?: number }) => void;
}

// eslint-disable-next-line complexity
export const UserDocuments: FC<Props> = ({ userId, selectedFolderId, selectedDocId, onSelectionChange }) => {
  const { practiceId } = useAccount();
  const [expandedFolders, setExpandedFolders] = useState<ExpandedFolders>({});
  const folderFlyover = useItemModal<FolderVO | undefined>(null);
  const showUploadModal = useBoolean(false);
  const showScanModal = useBoolean(false);
  const showEditDocumentModal = useBoolean(false);
  const [folderToDelete, setFolderToDelete] = useState<FolderVO>();

  const [
    selectedDocumentQuery,
    { data: downloadUrl, isLoading: isDownloadUrlLoading },
    { data: printUrl, isLoading: isPrintUrlLoading },
    { data: folders, isLoading: isFoldersLoading },
  ] = useApiQueries([
    getDocument({
      args: {
        practiceId,
        userId,
        documentId: selectedDocId ?? 0,
        includeMetadata: true,
      },
      queryOptions: { enabled: Boolean(selectedDocId) },
    }),
    getDocumentUrl({
      args: {
        practiceId,
        userId,
        folderId: selectedFolderId ?? 0,
        documentId: selectedDocId ?? 0,
        download: true,
      },
      queryOptions: { enabled: Boolean(selectedDocId) && Boolean(selectedFolderId) },
    }),
    getDocumentUrl({
      args: {
        practiceId,
        userId,
        folderId: selectedFolderId ?? 0,
        documentId: selectedDocId ?? 0,
        download: false,
      },
      queryOptions: { enabled: Boolean(selectedDocId) && Boolean(selectedFolderId) },
    }),
    getFolders({
      args: { practiceId, userId },
      queryOptions: {
        onError: handleError,
        onSuccess: (data) => {
          setExpandedFolders((last) => {
            const newExpanded: ExpandedFolders = {};

            data.data.data.forEach((folder) => {
              newExpanded[folder.id] = false;
            });

            return { ...newExpanded, ...last };
          });
        },
      },
    }),
  ]);

  const showFileDeleteConfirmationModal = useBoolean(false);
  const [
    { mutate: deleteDocumentMutate, isLoading: isDocumentDeleting },
    { mutate: moveDocumentMutate, isLoading: isDocumentMoving },
    { mutate: updateDocumentMutate, isLoading: isDocumentUpdating },
    { mutate: deleteFolderMutate, isLoading: isFolderDeleting },
  ] = useApiMutations([deleteDocument, moveDocument, updateDocument, deleteFolder]);
  const [isDeletingDocument, setIsDeletingDocument] = useState(false);

  const handleFolderDelete = useCallback(
    (folder: FolderVO) => {
      deleteFolderMutate(
        { practiceId, userId, folderId: folder.id },
        {
          onError: (error) => handleError(error),
          onSuccess: () => {
            setFolderToDelete(undefined);

            if (selectedFolderId === folder.id) {
              onSelectionChange({});
            }
          },
        }
      );
    },
    [deleteFolderMutate, onSelectionChange, practiceId, selectedFolderId, userId]
  );

  const handleSaveDocument = useCallback(
    (draftDocument: UpdateDocumentRequest) => {
      if (!selectedDocumentQuery.data) {
        return;
      }

      updateDocumentMutate(
        {
          practiceId,
          userId,
          folderId: selectedDocumentQuery.data.folderId,
          documentId: selectedDocumentQuery.data.id,
          data: draftDocument,
        },
        {
          onError: handleError,
          onSuccess: () => {
            showEditDocumentModal.off();
            onSelectionChange({
              folderId: draftDocument.folderId,
              docId: selectedDocumentQuery.data?.id,
            });
          },
        }
      );
    },
    [
      onSelectionChange,
      practiceId,
      selectedDocumentQuery,
      showEditDocumentModal,
      updateDocumentMutate,
      userId,
    ]
  );

  const handleDeleteDocument = useCallback(() => {
    if (!selectedDocumentQuery.data) {
      return;
    }

    setIsDeletingDocument(true);
    deleteDocumentMutate(
      {
        practiceId,
        userId,
        folderId: selectedDocumentQuery.data.folderId,
        documentId: selectedDocumentQuery.data.id,
      },
      {
        onSuccess: () => {
          showFileDeleteConfirmationModal.off();
          setIsDeletingDocument(false);
          onSelectionChange({
            folderId: selectedDocumentQuery.data?.folderId,
          });
        },
        onError: (err) => {
          setIsDeletingDocument(false);
          handleError(err);
        },
      }
    );
  }, [
    deleteDocumentMutate,
    onSelectionChange,
    practiceId,
    selectedDocumentQuery,
    showFileDeleteConfirmationModal,
    userId,
  ]);

  useEffect(() => {
    if (selectedFolderId) {
      setExpandedFolders((last) => ({ ...last, [selectedFolderId]: true }));
    }
  }, [selectedFolderId]);

  const expandAll = () => {
    setExpandedFolders((last) => {
      const newExpandedFolders: ExpandedFolders = {};

      Object.keys(last).forEach((folderId) => (newExpandedFolders[Number(folderId)] = true));

      return newExpandedFolders;
    });
  };

  const collapseAll = () => {
    setExpandedFolders((last) => {
      const newExpandedFolders: ExpandedFolders = {};

      Object.keys(last).forEach((folderId) => (newExpandedFolders[Number(folderId)] = false));

      return newExpandedFolders;
    });
  };

  const handleOpenCreateFolderFlyover = () => {
    folderFlyover.open(undefined);
  };

  const hasFolders = (folders ?? []).length > 0;

  return (
    <PinGuard protectedQueries={[selectedDocumentQuery]}>
      <div
        className={`
          grid
          grid-cols-[20rem_1fr]
          grid-rows-[auto_minmax(0,_1fr)]
          h-full
          bg-white
        `}
      >
        <HeaderTitle onExpandAll={expandAll} onCollapseAll={collapseAll} disableExpand={!hasFolders} />
        <HeaderTools
          practiceId={practiceId}
          userId={userId}
          onAddFolderClick={handleOpenCreateFolderFlyover}
          onUploadFileClick={showUploadModal.on}
          onScanClick={showScanModal.on}
          hasFolders={hasFolders}
          onSelectionChange={onSelectionChange}
        />
        <TreeView
          practiceId={practiceId}
          userId={userId}
          folders={folders}
          isFoldersLoading={isFoldersLoading}
          expandedFolders={expandedFolders}
          isLoading={isDocumentMoving || isFolderDeleting || isDocumentDeleting || isDocumentUpdating}
          selectedDocId={selectedDocId}
          onFolderEdit={(folder) => folderFlyover.open(folder)}
          onFolderDelete={setFolderToDelete}
          onFolderClick={(folderClicked) => {
            // Keep file selected while other folders are open or collapsed.
            // Although unselect the file if the folder being collapsed is the
            // folder of the selected file.
            if (!selectedDocId || selectedFolderId === folderClicked.id) {
              if (expandedFolders[folderClicked.id]) {
                onSelectionChange({});
              } else {
                onSelectionChange({ folderId: folderClicked.id });
              }
            }

            setExpandedFolders((last) => ({ ...last, [folderClicked.id]: !last[folderClicked.id] }));
          }}
          onDocumentClick={(clickedDoc) => {
            onSelectionChange({
              folderId: clickedDoc.folderId,
              // Select or Deselect document
              ...(clickedDoc.id !== selectedDocId && { docId: clickedDoc.id }),
            });
          }}
          onDocumentMove={(sourceDocument, destinationFolder) => {
            moveDocumentMutate(
              {
                practiceId,
                userId,
                folderId: sourceDocument.folderId,
                documentId: sourceDocument.id,
                data: { updatedFolderId: destinationFolder.id },
              },
              {
                onError: handleError,
                onSuccess: () => {
                  onSelectionChange({
                    folderId: destinationFolder.id,
                    docId: sourceDocument.id,
                  });
                },
              }
            );
          }}
        />

        <PreviewDocument
          document={selectedDocumentQuery.data}
          printUrl={printUrl}
          downloadUrl={downloadUrl}
          isLoadingUrl={isDownloadUrlLoading || isPrintUrlLoading}
          hasFolders={hasFolders}
          onEdit={showEditDocumentModal.on}
          onDelete={showFileDeleteConfirmationModal.on}
          onAddFolderClick={handleOpenCreateFolderFlyover}
        />

        {folderFlyover.isOpen && (
          <FolderFlyover userId={userId} folder={folderFlyover.item} onClose={folderFlyover.close} />
        )}

        {showUploadModal.isOn && folders && (
          <UploadDocumentFilesModal
            userId={userId}
            folders={folders}
            defaultFolderId={selectedFolderId}
            onRequestClose={showUploadModal.off}
          />
        )}

        {showFileDeleteConfirmationModal.isOn && (
          <ConfirmationModal
            isConfirming={isDeletingDocument}
            onCancel={showFileDeleteConfirmationModal.off}
            onConfirm={handleDeleteDocument}
            primaryText="Are you sure you want to delete this document?"
            secondaryText="This file will be permanently deleted and will no longer be accessible to the practice or on the patient portal."
          />
        )}

        {showEditDocumentModal.isOn && selectedDocumentQuery.data && (
          <DocumentModal
            document={selectedDocumentQuery.data}
            folders={folders}
            isSaving={isDocumentUpdating}
            onSave={handleSaveDocument}
            onCloseRequest={showEditDocumentModal.off}
          />
        )}

        {folderToDelete && (
          <ConfirmationModal
            isConfirming={isFolderDeleting}
            onCancel={() => setFolderToDelete(undefined)}
            onConfirm={() => handleFolderDelete(folderToDelete)}
            primaryText={`Are you sure you want to delete folder ${folderToDelete.name}?`}
            secondaryText="All contents of this folder will be permanently deleted and will no longer be accessible to the practice or on the patient portal."
          />
        )}

        {showScanModal.isOn && folders && (
          <ScanDocumentFileModal
            userId={userId}
            folders={folders}
            defaultFolderId={selectedFolderId}
            onUploadSuccess={(document) => {
              onSelectionChange({
                folderId: document.folderId,
                docId: document.id,
              });
            }}
            onRequestClose={showScanModal.off}
          />
        )}
      </div>
    </PinGuard>
  );
};
