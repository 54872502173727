import { InputHTMLAttributes } from "react";
import { cx } from "@libs/utils/cx";

export type PerioChartCheckboxProps = InputHTMLAttributes<HTMLInputElement>;

export const PerioChartCheckbox: React.FC<PerioChartCheckboxProps> = ({ value, ...props }) => {
  const boundValue = Boolean(value);

  return (
    <input
      {...props}
      checked={boundValue}
      className={cx(
        `w-4
         h-4
         rounded-full
         border
         appearance-none
         border-slate-300
         hover:border-primaryTheme
         disabled:border-greyLightest
         disabled:bg-greyLightest
         text-center
         text-xs
         text-greyDark`,
        props.className
      )}
      type="checkbox"
    />
  );
};
