import React from "react";
import { cx } from "@libs/utils/cx";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";

type Props = {
  SvgIcon: IconComponent;
  onClick?: Func;
  toggled?: boolean;
  onMouseDown?: Func;
  disabled?: boolean;
  iconStyle?: React.CSSProperties;
  tooltip?: string;
  size?: "sm" | "md";
};
export const ToolbarButton = React.forwardRef<HTMLButtonElement, Props>(
  ({ SvgIcon, onClick, onMouseDown, disabled, toggled = false, size = "sm", iconStyle, tooltip }, ref) => {
    return (
      <ButtonIcon
        ref={ref}
        tooltip={{ content: tooltip, theme: "SMALL" }}
        className={cx(
          `bg-slate-700
           w-full
           py-2
           enabled:hover:bg-slate-800/90
           first:rounded-t-[4px]
           last:rounded-b-[4px]`,
          toggled ? "bg-slate-900" : "bg-slate-700"
        )}
        disabled={disabled}
        onClick={onClick}
        iconStyle={iconStyle}
        onMouseDown={onMouseDown}
        theme="darkBackground"
        size={size}
        SvgIcon={SvgIcon}
      />
    );
  }
);
