import {
  SerializedEditorState,
  SerializedLineBreakNode,
  SerializedParagraphNode,
  SerializedTextNode,
} from "lexical";
import { TemplateVariableGroupVO, TemplateVariableVO } from "@libs/api/generated-api";
import { humanizeConstant, titleCaseConstant } from "@libs/utils/casing";

export type TextNode = Omit<SerializedTextNode, "type"> & { type: "text" };

export type LineBreakNode = Omit<SerializedLineBreakNode, "type"> & { type: "linebreak" };

type TemplateVariable = Omit<SerializedTextNode, "type"> & {
  type: "templateVariable";
  templateVariableKey: string;
};

export type ParagraphNode = Omit<SerializedParagraphNode, "type" | "children"> & {
  type: "paragraph";
  children: Nodes[];
};
type Nodes = TextNode | LineBreakNode | ParagraphNode | TemplateVariable;

const stringifyChildren = (children: Nodes[]) => {
  let text = "";

  for (const node of children) {
    switch (node.type) {
      case "text": {
        // escape variables
        text += node.text.replaceAll(variableRegex, "{$1}");
        break;
      }
      case "templateVariable": {
        text += `{{${node.templateVariableKey}}}`;
        break;
      }
      case "linebreak": {
        text += "\n";
        break;
      }
      case "paragraph": {
        text += stringifyChildren(node.children);
        break;
      }
      default: {
        continue;
      }
    }
  }

  return text;
};

export const templateVariablesToString = (content: string) => {
  try {
    const state = JSON.parse(content) as SerializedEditorState<Nodes>;

    return stringifyChildren(state.root.children);
  } catch {
    return content;
  }
};

export const getTextNode = (text: string) => ({
  text,
  type: "text" as const,
  version: 1,
  style: "",
  mode: "normal" as const,
  format: 0,
  detail: 0,
});

export const getVariableNode = (templateVariableKey: string, isAvailable: boolean) => ({
  detail: 1,
  format: 0,
  mode: "segmented" as const,
  style: "",
  isAvailable,
  text: titleCaseConstant(templateVariableKey),
  type: "templateVariable" as const,
  version: 1,
  templateVariableKey,
});

const getRootNode = (children: Nodes[]) => ({
  children,
  direction: "ltr" as const,
  format: "" as const,
  indent: 0,
  type: "root" as const,
  version: 1,
});

const getParagraphNode = (children: Nodes[]) => ({
  children,
  direction: "ltr" as const,
  format: "" as const,
  indent: 0,
  type: "paragraph" as const,
  version: 1,
});

const variableSeparators = {
  open: "{{",
  close: "}}",
};

const escapedVariableSeparators = {
  open: "{{{",
  close: "}}}",
};

const variableCharacters = /[A-Z_]/;

const variableRegex = /({{[A-Z_]+}})/g;

export const maybeVariableRegex = /{{2,}[A-Z_]+}{2,}/g;
export const escapedVariableRegex = /{{3,}[A-Z_]+}{3,}/g;

const isStartOfSequence = (index: number, content: string, sequence: string) => {
  // eslint-disable-next-line unicorn/no-for-loop
  for (let i = 0; i < sequence.length; i++) {
    if (content[index + i] !== sequence[i]) {
      return false;
    }
  }

  return true;
};

const findVariableStart = (currentIndex: number, content: string) => {
  let index = currentIndex;

  while (content[index] === "{") {
    index++;
  }

  return index;
};

const isStartOfEscapedVariable = (index: number, content: string) => {
  return isStartOfSequence(index, content, escapedVariableSeparators.open);
};

const isEndOfEscapedVariable = (index: number, content: string) => {
  return isStartOfSequence(index, content, escapedVariableSeparators.close);
};

const isStartOfVariable = (index: number, content: string) => {
  return isStartOfSequence(index, content, variableSeparators.open);
};

const isEndOfVariable = (index: number, content: string) => {
  return isStartOfSequence(index, content, variableSeparators.close);
};

const isNewline = (index: number, content: string) => {
  return content[index] === "\n";
};

/* eslint-disable-next-line complexity, max-statements */
export const templateStringToTemplateVariableNodes = (
  content: string,
  templateVariables: TemplateVariableGroupVO[]
) => {
  const len = content.length;
  let i = 0;

  // the collection of nodes that will be returned
  const children: Nodes[] = [];

  // Assume we are starting with a text node
  let textNodeStartIndex = 0;

  const templateVariablesSet = new Set(
    templateVariables.flatMap((group) => group.variables.map((variable) => variable.key))
  );

  const captureTextNodeUntilHere = (currentIndex: number, nextStart: number) => {
    if (currentIndex > textNodeStartIndex) {
      children.push(getTextNode(content.slice(textNodeStartIndex, currentIndex)));
    }

    textNodeStartIndex = nextStart;
  };

  while (i < len) {
    if (isStartOfEscapedVariable(i, content)) {
      // If we have something like {{{{{{{EXAMPLE}}, move up to {{{EXAMPLE}}
      const variableStart = findVariableStart(i + escapedVariableSeparators.open.length, content);

      const openStart = variableStart - escapedVariableSeparators.open.length;
      let j = variableStart;

      while (j < len) {
        // If we find a escaped variable close sequence, capture the text node up to the
        // start of the escaped variable and then unescape the variable and capture it as
        // a text node
        if (isEndOfEscapedVariable(j, content)) {
          const variableValue = content.slice(variableStart, j);
          const afterClose = j + escapedVariableSeparators.close.length;

          // eslint-disable-next-line max-depth
          if (variableValue.length) {
            captureTextNodeUntilHere(openStart, afterClose);

            const unescapedVariable = content.slice(openStart + 1, afterClose - 1);

            children.push(getTextNode(unescapedVariable));
          }

          // if the variable is empty, treat everything as text
          // and skip to the end of the closing sequence
          i = afterClose;
          break;
        }

        // If we find a variable close sequence, capture the text node up to the
        // start of the variable open sequence and capture the variable node
        if (isEndOfVariable(j, content)) {
          const variableValue = content.slice(variableStart, j);
          const afterClose = j + variableSeparators.close.length;

          // eslint-disable-next-line max-depth
          if (variableValue.length) {
            captureTextNodeUntilHere(openStart + 1, afterClose);
            children.push(
              getVariableNode(
                variableValue,
                templateVariablesSet.has(variableValue as TemplateVariableVO["key"])
              )
            );
          }

          // if the variable is empty, treat everything as text
          // and skip to the end of the closing sequence
          i = afterClose;
          break;
        }

        if (!variableCharacters.test(content[j])) {
          // we are no longer dealing with an escaped variable
          i = j + 1;

          break;
        }

        j++;
      }

      if (j >= len) {
        i = len;
      }
    } else if (isStartOfVariable(i, content)) {
      const variableStart = i + variableSeparators.open.length;
      let j = variableStart;

      while (j < len) {
        // If we find a variable close sequence, capture the text node up to the
        // start of the variable open sequence and then capture the variable node
        if (isEndOfVariable(j, content)) {
          const variableValue = content.slice(variableStart, j);
          const afterClose = j + variableSeparators.close.length;

          // eslint-disable-next-line max-depth
          if (variableValue.length) {
            captureTextNodeUntilHere(i, afterClose);

            children.push(
              getVariableNode(
                variableValue,
                templateVariablesSet.has(variableValue as TemplateVariableVO["key"])
              )
            );
          }

          // if the variable is empty, treat everything as text
          // and skip to the end of the closing sequence
          i = afterClose;

          break;
        }

        if (!variableCharacters.test(content[j])) {
          // we are no longer dealing with a variable
          i = j + 1;

          break;
        }

        j++;
      }

      if (j >= len) {
        i = len;
      }
    } else if (isNewline(i, content)) {
      captureTextNodeUntilHere(i, i + 1);

      children.push({
        type: "linebreak" as const,
        version: 1,
      });
      i++;
    } else {
      // found another text node character, move to next character
      i++;
    }
  }

  if (i === len && textNodeStartIndex < len) {
    captureTextNodeUntilHere(len, len + 1);
  }

  const state: SerializedEditorState<Nodes> = {
    root: getRootNode([getParagraphNode(children)]),
  };

  return state;
};

/* eslint-disable-next-line complexity, max-statements */
export const resolveTemplateString = (content: string, templateVariables: TemplateVariableGroupVO[]) => {
  const len = content.length;
  let i = 0;

  // the collection of nodes that will be returned
  const children: { key: string; type: "variable" | "text"; value: string }[] = [];

  // Assume we are starting with a text node
  let textNodeStartIndex = 0;

  const templateVariablesSet = new Set(
    templateVariables.flatMap((group) => group.variables.map((variable) => variable.key))
  );

  const captureTextNodeUntilHere = (currentIndex: number, nextStart: number) => {
    if (currentIndex > textNodeStartIndex) {
      children.push({
        key: `${currentIndex}`,
        type: "text",
        value: content.slice(textNodeStartIndex, currentIndex),
      });
    }

    textNodeStartIndex = nextStart;
  };

  while (i < len) {
    if (isStartOfEscapedVariable(i, content)) {
      // If we have something like {{{{{{{EXAMPLE}}, move up to {{{EXAMPLE}}
      const variableStart = findVariableStart(i + escapedVariableSeparators.open.length, content);

      const openStart = variableStart - escapedVariableSeparators.open.length;
      let j = variableStart;

      while (j < len) {
        // If we find a escaped variable close sequence, capture the text node up to the
        // start of the escaped variable and then unescape the variable and capture it as
        // a text node
        if (isEndOfEscapedVariable(j, content)) {
          const variableValue = content.slice(variableStart, j);
          const afterClose = j + escapedVariableSeparators.close.length;

          // eslint-disable-next-line max-depth
          if (variableValue.length) {
            captureTextNodeUntilHere(openStart, afterClose);

            const unescapedVariable = content.slice(openStart + 1, afterClose - 1);

            children.push({ key: `${openStart + 1}`, type: "text", value: unescapedVariable });
          }

          // if the variable is empty, treat everything as text
          // and skip to the end of the closing sequence
          i = afterClose;
          break;
        }

        // If we find a variable close sequence, capture the text node up to the
        // start of the variable open sequence and capture the variable node
        if (isEndOfVariable(j, content)) {
          const variableValue = content.slice(variableStart, j);
          const afterClose = j + variableSeparators.close.length;

          // eslint-disable-next-line max-depth
          if (variableValue.length && templateVariablesSet.has(variableValue as TemplateVariableVO["key"])) {
            captureTextNodeUntilHere(openStart + 1, afterClose);
            children.push({
              type: "variable",
              value: humanizeConstant(variableValue),
              key: `${openStart + 1}`,
            });
          }

          // if the variable is empty, treat everything as text
          // and skip to the end of the closing sequence
          i = afterClose;
          break;
        }

        if (!variableCharacters.test(content[j])) {
          // we are no longer dealing with an escaped variable
          i = j + 1;

          break;
        }

        j++;
      }

      if (j >= len) {
        i = len;
      }
    } else if (isStartOfVariable(i, content)) {
      const variableStart = i + variableSeparators.open.length;
      let j = variableStart;

      while (j < len) {
        // If we find a variable close sequence, capture the text node up to the
        // start of the variable open sequence and then capture the variable node
        if (isEndOfVariable(j, content)) {
          const variableValue = content.slice(variableStart, j);
          const afterClose = j + variableSeparators.close.length;

          // eslint-disable-next-line max-depth
          if (variableValue.length && templateVariablesSet.has(variableValue as TemplateVariableVO["key"])) {
            captureTextNodeUntilHere(i, afterClose);
            children.push({
              type: "variable",
              value: humanizeConstant(variableValue),
              key: `${i}`,
            });
          }

          // if the variable is empty, treat everything as text
          // and skip to the end of the closing sequence
          i = afterClose;

          break;
        }

        if (!variableCharacters.test(content[j])) {
          // we are no longer dealing with a variable
          i = j + 1;

          break;
        }

        j++;
      }

      if (j >= len) {
        i = len;
      }
    } else {
      // found another text node character, move to next character
      i++;
    }
  }

  if (i === len && textNodeStartIndex < len) {
    captureTextNodeUntilHere(len, len + 1);
  }

  return children;
};
