import { FC } from "react";
import { FormDateInputElementRequest } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { FormWrapper } from "components/Settings/Forms/FormWrapper";
import { EditElementFormProps } from "components/Settings/Forms/types";
import { DateFormContent } from "components/Settings/Forms/Date/FormContent";
import { dateSchema, useDateFormState } from "./schema";

export const DateForm: FC<EditElementFormProps<FormDateInputElementRequest>> = ({
  element,
  isEditing,
  formType,
  onInvalid,
  onDraftChange,
  onSubmit,
  onSecondaryClick,
}) => {
  const state = useDateFormState(element);

  const formState = state[0];
  const { draft } = formState;

  const validation = useValidation(draft, dateSchema);

  const handleSubmit = () => {
    onSubmit(draft);
    validation.reset();
  };

  return (
    <FormWrapper
      element={draft}
      isEditing={isEditing}
      onValidate={() => validation.validate().$isValid}
      onDraftChange={onDraftChange}
      onInvalid={onInvalid}
      onSubmit={handleSubmit}
      onCancel={onSecondaryClick}
    >
      <DateFormContent formType={formType} formState={state} validation={validation.result} />
    </FormWrapper>
  );
};
