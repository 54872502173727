import { FC } from "react";

import { ProviderVO } from "@libs/api/generated-api";

import { ProviderPill } from "components/PatientBlurbInfo/ProviderPill";

interface Props {
  primaryProvider: ProviderVO;
}

export const PatientPrimaryProvider: FC<Props> = ({ primaryProvider }) => {
  return (
    <div className="flex flex-col gap-y-1 text-xs">
      <span className="font-sansSemiBold leading-6">Primary Provider</span>
      <ProviderPill name={primaryProvider.name.fullDisplayName} color={primaryProvider.color} />
    </div>
  );
};
