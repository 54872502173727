/* eslint-disable @typescript-eslint/naming-convention */

import { cx } from "@libs/utils/cx";
import { toPercent } from "@libs/utils/math";
import { EMPTY_CELL, TextCell } from "@libs/components/UI/GridTableComponents";

export const PerioTrendChangeCell: React.FC<{
  border: boolean;
  className: string;
  isEmpty?: boolean;
  count: number;
  percent: number;
  delta: number;
  index: number;
  finding?: "negative" | "positive";
}> = ({ border, finding, className, isEmpty, delta, count, percent }) => {
  const percentValue = `${toPercent(Math.abs(delta))}%`;

  return (
    <TextCell border={border} className={cx("gap-2 justify-between", className, !isEmpty && "flex")}>
      {isEmpty ? (
        EMPTY_CELL
      ) : (
        <>
          <span className="w-4">{count}</span>
          <span className="w-12">{toPercent(percent)}%</span>
          <span
            className={cx(
              "min-w-16",
              finding === "negative" && "text-red",
              finding === "positive" && "text-green"
            )}
          >
            {delta > 0 ? `▲ ${percentValue}` : delta < 0 ? `▼ ${percentValue}` : ""}
          </span>
        </>
      )}
    </TextCell>
  );
};
