import React, { useCallback, useEffect, useMemo } from "react";
import { isDefined } from "@libs/utils/types";
import { WrapStorage, wrapStorage } from "@libs/storage/wrapStorage";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { CaptureDeviceSettings } from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/types";
import { StorageNamespaces } from "storage/namespaces";
import { useImagingHub } from "components/ImageCapturing/ImagingHubContext";
import { getSensorSettingsDeviceId } from "components/PatientProfile/Imaging/utils/devices";
import { SHOULD_REOPEN_TWAIN_SENSORS } from "components/PatientProfile/Imaging/utils/ais";

const SENSOR_SETTINGS_STORE_KEY = "sensorSettings";
const CAMERA_SETTINGS_STORE_KEY = "cameraSettings";
const ARCHY_SENSOR_SETTINGS_KEY = "archySensorSettings";
const ARCHY_HAS_MIGRATED_AIS = "hasMigratedDynamsoftSettings";
const HAS_MIGRATED_AUTO_CAPTURE_SENSORS = "hasMigratedAutocaptureSensors";

const STORE_VERSION = "v2";

const getHasMigratedDynamsoftSettings = (
  imagingStorage: WrapStorage<CaptureDeviceSettings[], StorageNamespaces>
) => {
  return Boolean(imagingStorage.get(ARCHY_HAS_MIGRATED_AIS, STORE_VERSION));
};

// To re-run a migration on a machine, enter the following into their console: localStorage.setItem("imaging_hasMigratedDynamsoftSettings", null) or localStorage.setItem("imaging_hasMigratedAutocaptureSensors", null)
const useSettingsMigrations = (
  dynamsoftSettings: CaptureDeviceSettings[],
  aisDeviceSettings: CaptureDeviceSettings[],
  imagingStorage: WrapStorage<CaptureDeviceSettings[], StorageNamespaces>,
  handleAISDevicesUpdated: React.Dispatch<React.SetStateAction<CaptureDeviceSettings[]>>
) => {
  const { availableDevicesQuery, status: hubStatus } = useImagingHub();

  const aisDevices = availableDevicesQuery.data;

  useEffect(() => {
    if (!hubStatus.isRunning || !aisDevices) {
      return;
    }

    let updatedSettings: CaptureDeviceSettings[] = aisDeviceSettings;
    const hasMigratedDynamsoftSettings = getHasMigratedDynamsoftSettings(imagingStorage);

    if (!hasMigratedDynamsoftSettings) {
      updatedSettings = dynamsoftSettings
        .filter((dynamsoftSetting) => dynamsoftSetting.enabled)
        .map((dynamsoftSetting) => {
          const aisSettingName = dynamsoftSetting.label.replace(" (TWAIN)", "");
          const ais64bitSettingName = aisSettingName.replace(" (TWAIN(64Bit))", "");
          const aisDevice = aisDevices.find(
            (device) => device.name === aisSettingName || device.name === ais64bitSettingName
          );

          if (!aisDevice) {
            return undefined;
          }

          return {
            ...dynamsoftSetting,
            id: getSensorSettingsDeviceId({
              label: aisDevice.name,
              driverType: aisDevice.driverType,
            }),
            label: aisSettingName,
          };
        })
        .filter(isDefined);
      imagingStorage.set(ARCHY_HAS_MIGRATED_AIS, [], {
        expires: 99_999_999_999,
        version: STORE_VERSION,
      });
    }

    const hasMigratedAutocaptureSensors = Boolean(
      imagingStorage.get(HAS_MIGRATED_AUTO_CAPTURE_SENSORS, STORE_VERSION)
    );

    if (!hasMigratedAutocaptureSensors) {
      updatedSettings = updatedSettings.map((setting) => {
        if (SHOULD_REOPEN_TWAIN_SENSORS.has(setting.label)) {
          return {
            ...setting,
            autoReopenAfterCapture: true,
          };
        }

        return setting;
      });

      const autoCaptureDevices = aisDevices.filter((item) => SHOULD_REOPEN_TWAIN_SENSORS.has(item.name));

      for (const device of autoCaptureDevices) {
        // Migrate devices not already in settings
        if (updatedSettings.some((item) => item.label !== device.name)) {
          updatedSettings.push({
            id: getSensorSettingsDeviceId({
              label: device.name,
              driverType: device.driverType,
            }),
            label: device.name,
            enabled: true,
            autoReopenAfterCapture: true,
          });
        }
      }

      imagingStorage.set(HAS_MIGRATED_AUTO_CAPTURE_SENSORS, [], {
        expires: 99_999_999_999,
        version: STORE_VERSION,
      });
    }

    if (!hasMigratedDynamsoftSettings || !hasMigratedAutocaptureSensors) {
      imagingStorage.set(ARCHY_SENSOR_SETTINGS_KEY, updatedSettings, {
        expires: 99_999_999_999,
        version: STORE_VERSION,
      });

      handleAISDevicesUpdated(updatedSettings);
    }
  }, [aisDeviceSettings, aisDevices, dynamsoftSettings, handleAISDevicesUpdated, hubStatus, imagingStorage]);
};

export const useImagingDeviceSettings = () => {
  const { status: hubStatus } = useImagingHub();

  const { localStorage } = useStorageContext();
  const imagingStorage = useMemo(
    () =>
      wrapStorage<CaptureDeviceSettings[], StorageNamespaces>(StorageNamespaces.imagingDevices, localStorage),
    [localStorage]
  );

  const [sensorSettings, setSensorSettings] = React.useState<CaptureDeviceSettings[]>(
    imagingStorage.get(SENSOR_SETTINGS_STORE_KEY, STORE_VERSION) ?? []
  );
  const [archyServiceSensorSettings, setArchyServiceSensorSettings] = React.useState<CaptureDeviceSettings[]>(
    imagingStorage.get(ARCHY_SENSOR_SETTINGS_KEY, STORE_VERSION) ?? []
  );
  const [cameraSettings, setCameraSettings] = React.useState<CaptureDeviceSettings[]>(
    imagingStorage.get(CAMERA_SETTINGS_STORE_KEY, STORE_VERSION) ?? []
  );

  useSettingsMigrations(
    sensorSettings,
    archyServiceSensorSettings,
    imagingStorage,
    setArchyServiceSensorSettings
  );

  return {
    sensorSettings,
    archyServiceSensorSettings,
    setArchyServiceSensorSettings,
    setSensorSettings,
    cameraSettings,
    setCameraSettings,
    getSensorSettings: useCallback(() => {
      return hubStatus.isRunning && !hubStatus.isAISDisabled ? archyServiceSensorSettings : sensorSettings;
    }, [hubStatus, sensorSettings, archyServiceSensorSettings]),
    saveSettings: useCallback(
      (params: {
        cameraSettings: CaptureDeviceSettings[];
        sensorSettings: CaptureDeviceSettings[];
        archyServiceSensorSettings: CaptureDeviceSettings[];
      }) => {
        imagingStorage.set(CAMERA_SETTINGS_STORE_KEY, params.cameraSettings, {
          expires: 99_999_999_999,
          version: STORE_VERSION,
        });
        imagingStorage.set(SENSOR_SETTINGS_STORE_KEY, params.sensorSettings, {
          expires: 99_999_999_999,
          version: STORE_VERSION,
        });
        imagingStorage.set(ARCHY_SENSOR_SETTINGS_KEY, params.archyServiceSensorSettings, {
          expires: 99_999_999_999,
          version: STORE_VERSION,
        });
      },
      [imagingStorage]
    ),
  };
};
