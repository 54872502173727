import { ReportingRecallByPatientEntryVO } from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { DashboardFilterFormFieldSelect } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";

type FiltersParam = {
  filters: DashboardFilter[];
};
type Props = {
  query: FiltersParam;
  onUpdateParams: (params: FiltersParam) => void;
};

const PATIENT_STATUS_OPTIONS = (
  [
    "PROSPECT",
    "ACTIVE",
    "INACTIVE",
    "ARCHIVED",
    "NONPATIENT",
    "DECEASED",
  ] as ReportingRecallByPatientEntryVO["patientStatus"][]
).map((value) => ({ value, label: sentenceCaseConstant(value) }));

export const PatientStatusFilter: React.FC<Props> = ({ query, onUpdateParams }) => {
  return (
    <DashboardFilterFormFieldSelect
      placeholder="Patient Status"
      aria-label="Patient Status"
      dashboardFilters={query.filters}
      dashboardFilterType="patientStatus"
      isSearchable={false}
      options={PATIENT_STATUS_OPTIONS}
      className="min-w-[140px]"
      onChangeFilters={onUpdateParams}
    />
  );
};
