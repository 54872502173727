import React from "react";
import { ProviderVO } from "@libs/api/generated-api";

export const useProviderFilterOptions = (
  providers?: ProviderVO[],
  options?: { includeNoProviderOption: boolean }
) =>
  React.useMemo(() => {
    if (!providers) {
      return [];
    }

    const providerOptions = providers.map((provider) => ({
      value: `${provider.id}`,
      label: provider.name.shortDisplayName,
    }));

    if (options?.includeNoProviderOption) {
      return [...providerOptions, { value: "-1", label: "No Provider" }];
    }

    return providerOptions;
  }, [options?.includeNoProviderOption, providers]);
