import { useState } from "react";
import { FormDateInputElementRequest } from "@libs/api/generated-api";
import { titleSchema } from "components/Settings/Forms/utils";

export const useDateFormState = (
  element: FormDateInputElementRequest | (() => FormDateInputElementRequest)
) => {
  return useState(() => ({ draft: typeof element === "function" ? element() : element }));
};

export const dateSchema = {
  title: titleSchema,
};
