import { FC, ReactNode } from "react";
import { FormatOptionLabelContext } from "react-select/dist/declarations/src/Select";
import { ReactComponent as AlertIcon } from "@libs/assets/icons/error.svg";

export interface SlotAttributeOptionProps {
  value: number;
  label: ReactNode;
  isAvailable?: boolean;
}

export const SlotAttributeOption: FC<
  SlotAttributeOptionProps & { context: FormatOptionLabelContext; selectedValue: number }
> = (props) => {
  return (
    <div className="flex w-full justify-between">
      <span className="pr-2">{props.label}</span>
      {props.isAvailable === false ? <AlertIcon className="inline-block flex-none fill-red w-4 h-4" /> : null}
    </div>
  );
};
