import { FC, useMemo } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { PayrollLineItemVO, SubLineItemsVO } from "@libs/api/generated-api";
import { formatISODate, formatShortDayOfMonth, getLocalDate } from "@libs/utils/date";
import { isOneOf } from "@libs/utils/isOneOf";
import { getFullUrl } from "@libs/utils/location";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { hasFailedPin } from "@libs/utils/hasFailedPin";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { Button } from "@libs/components/UI/Button";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { AlertModal } from "@libs/components/UI/AlertModal";
import { getPayroll } from "api/payroll/queries";
import { PayrollLineItemsTable } from "components/Payroll/PayrollLineItemsTable";
import { ModalPage } from "components/UI/ModalPage";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { calculatePayroll, updateLineItem } from "api/payroll/mutations";
import { handleError } from "utils/handleError";
import { LoadingOverlay } from "components/UI/LoadingOverlay";
import {
  formatDeadlineDate,
  hasPendingLineItems,
  hasUnresolvedPostTaxDeductions,
  hasNotOnboardedLineItems,
  practicePaysDoubleOvertime,
} from "components/Payroll/utils";
import {
  PayrollEmployeeOnboardWarningBanner,
  PayrollDeductionErrorsBanner,
  PayrollProcessingErrorsBanner,
} from "components/Payroll/PayrollErrorsBanners";
import { useItemModal } from "hooks/useItemModal";
import { EmployeePinModal } from "components/Employee/EmployeePinModal";
import { PinGuard } from "components/UI/PinGuard";

// eslint-disable-next-line complexity
export const PayrollEditRoute: FC = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const practice = useCurrentPractice();
  const { payrollId } = usePathParams("editPayroll");
  const { query } = useQueryParams("editPayroll");
  const from = query.from ?? paths.payroll();
  const isChangingToCalculating = useBoolean(false);
  const finishLaterModal = useBoolean(false);
  const pinModal = useItemModal<Func>(null);

  usePageTitle("Edit Payroll");

  const [payrollQuery] = useApiQueries([getPayroll({ args: { practiceId: practice.id, payrollId } })]);
  const [saveLineItemMutation, calculatePayrollMutation] = useApiMutations([
    updateLineItem,
    calculatePayroll,
  ]);

  const hasUnskippedEmployeesNotOnboarded = useMemo(() => {
    return hasNotOnboardedLineItems(payrollQuery.data?.lineItems, { allowSkipped: true });
  }, [payrollQuery.data]);

  const hasUnresolvedDeductions = useMemo(() => {
    return hasUnresolvedPostTaxDeductions(payrollQuery.data?.lineItems);
  }, [payrollQuery.data]);

  const hasPendingStatus = useMemo(() => {
    return hasPendingLineItems(payrollQuery.data?.lineItems);
  }, [payrollQuery.data]);

  const handleToggleSkip = (lineItem: PayrollLineItemVO) => {
    if (payrollQuery.data) {
      const employeeId = lineItem.employee.id;

      saveLineItemMutation.mutate(
        {
          practiceId: practice.id,
          payrollId,
          employeeId,
          lineItem: { subLineItems: lineItem.subLineItems, skip: !lineItem.isSkipped },
        },
        {
          onError: (err) => {
            if (hasFailedPin(err)) {
              pinModal.open(() => handleToggleSkip(lineItem));
            } else {
              handleError(err);
            }
          },
        }
      );
    }
  };

  const handleUpdateSubLineItems = (lineItem: PayrollLineItemVO, updates: Partial<SubLineItemsVO>) => {
    if (payrollQuery.data) {
      const employeeId = lineItem.employee.id;

      saveLineItemMutation.mutate(
        {
          practiceId: practice.id,
          payrollId,
          employeeId,
          lineItem: {
            subLineItems: { ...lineItem.subLineItems, ...updates },
            skip: lineItem.isSkipped,
          },
        },
        {
          onError: (err) => {
            if (hasFailedPin(err)) {
              pinModal.open(() => handleUpdateSubLineItems(lineItem, updates));
            } else {
              handleError(err);
            }
          },
        }
      );
    }
  };

  const handleContinue = () => {
    // once we execute the calculate mutation if it completes it will refetch
    // the payroll query which will then have a "CALCULATING" status and
    // redirect us to the detail page
    isChangingToCalculating.on();
    calculatePayrollMutation.mutate(
      {
        practiceId: practice.id,
        payrollId,
      },
      {
        onError: (err) => {
          isChangingToCalculating.off();

          if (hasFailedPin(err)) {
            pinModal.open(() => handleContinue());
          } else {
            handleError(err);
          }
        },
      }
    );
  };

  return (
    <>
      <PinGuard protectedQueries={[payrollQuery]}>
        <QueryResult queries={[payrollQuery]}>
          {payrollQuery.data ? (
            isOneOf(payrollQuery.data.status, ["CALCULATING", "SUBMITTED"]) ? (
              <Navigate
                replace
                to={paths.payrollDetail({ payrollId }, { root: from, from: getFullUrl(location) })}
              />
            ) : (
              <LoadingOverlay
                overlayClassName="z-20 border border-greyLighter rounded"
                opaque={isChangingToCalculating.isOn}
                isLoading={saveLineItemMutation.isLoading || isChangingToCalculating.isOn}
                loadingText={saveLineItemMutation.isLoading ? "Saving Changes..." : "Calculating Payroll..."}
              >
                <ModalPage
                  className="relative"
                  title={
                    <>
                      <div className="w-2/5">
                        <div className="font-sansSemiBold flex items-center">
                          <h1>Run Payroll</h1>
                          <div className="h-4 w-px bg-greyLight mx-4" />
                          <div className="text-sm">
                            Pay Period {formatISODate(payrollQuery.data.periodStartDate)} -{" "}
                            {formatISODate(payrollQuery.data.periodEndDate)}
                          </div>
                        </div>
                        {/* TODO Figure out what data drives this text and how it changes based on different states */}
                        <p className="text-xs mt-2.5">
                          To pay employees on{" "}
                          {payrollQuery.data.employeePayDate
                            ? formatShortDayOfMonth(getLocalDate(payrollQuery.data.employeePayDate))
                            : ""}
                          , you’ll need to run payroll by{" "}
                          {formatDeadlineDate(practice.timezoneId, payrollQuery.data.payrollDeadline)}.
                        </p>
                        <p className="text-xs">
                          Payroll can be canceled until{" "}
                          {formatDeadlineDate(practice.timezoneId, payrollQuery.data.payrollDeadline)}.
                        </p>
                      </div>
                      <PayrollProcessingErrorsBanner errors={payrollQuery.data.errors} />
                      <PayrollDeductionErrorsBanner lineItems={payrollQuery.data.lineItems} />
                      <PayrollEmployeeOnboardWarningBanner lineItems={payrollQuery.data.lineItems} />
                    </>
                  }
                  actions={
                    <div className="flex items-center justify-center relative gap-x-3">
                      <Button onClick={finishLaterModal.on} className="min-w-button" theme="secondary">
                        Finish Later
                      </Button>
                      <FloatingTooltip
                        placement="top"
                        content={
                          hasPendingStatus
                            ? "Timesheet status must be approved for all employees before you can continue."
                            : hasUnresolvedDeductions
                              ? "All deductions must be successfully processed before you can run payroll."
                              : hasUnskippedEmployeesNotOnboarded
                                ? 'Employees marked as "Not Onboarded" must be either onboarded or skipped.'
                                : ""
                        }
                      >
                        <Button
                          className="min-w-button"
                          disabled={
                            hasPendingStatus || hasUnresolvedDeductions || hasUnskippedEmployeesNotOnboarded
                          }
                          onClick={handleContinue}
                        >
                          Continue
                        </Button>
                      </FloatingTooltip>
                    </div>
                  }
                  closeLink={from}
                >
                  <PayrollLineItemsTable
                    onToggleSkip={handleToggleSkip}
                    onUpdateSubLineItems={handleUpdateSubLineItems}
                    payroll={payrollQuery.data}
                    showDoubleOvertime={practicePaysDoubleOvertime(practice)}
                  />
                </ModalPage>
              </LoadingOverlay>
            )
          ) : null}
        </QueryResult>
      </PinGuard>
      {finishLaterModal.isOn ? (
        <AlertModal
          primaryText="Finish this payroll later"
          secondaryText="All the changes and edits you’ve made here will be saved for when you resume this payroll."
          onConfirm={() => navigate(from, { replace: true })}
        />
      ) : null}
      {pinModal.isOpen && <EmployeePinModal onPinSuccess={pinModal.item} onClose={pinModal.close} />}
    </>
  );
};
