import { FC, Fragment, MouseEvent, MouseEventHandler, ReactNode, useCallback, useMemo } from "react";
import { LabCaseReturnReasonVO, LabCaseVO, PatientVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isDefined } from "@libs/utils/types";
import { formatISODate, isLeftISODateAfterRightISODate } from "@libs/utils/date";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { flattenPages } from "@libs/utils/queries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as ArchiveIcon } from "@libs/assets/icons/archive.svg";
import { ReactComponent as UnarchiveIcon } from "@libs/assets/icons/unarchive.svg";
import { ReactComponent as NoteIcon } from "@libs/assets/icons/note-filled.svg";
import { ReactComponent as HistoryIcon } from "@libs/assets/icons/history.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { useAccount } from "@libs/contexts/AccountContext";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { Panel } from "@libs/components/UI/Panel";
import { QueryFilters } from "@libs/components/UI/QueryFilters";
import {
  ButtonCell,
  ColumnSortIndicator,
  EMPTY_CELL,
  HeaderButtonCell,
  HeaderCell,
  Row,
  TableGrid,
  TextCell,
} from "@libs/components/UI/GridTableComponents";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { getLabCases } from "api/lab/queries";
import { LabCaseFlyover } from "components/LabCases/LabCaseFlyover";
import {
  AppointmentOverflowTooltipContent,
  LabCaseQueryFiltersFlyover,
  LabCaseStatus,
  LabCaseStatusMenuButton,
  LabFormIcon,
  ProcedurePillsWithOverflow,
  labCaseStatusOptions,
} from "components/LabCases/LabComponents";
import { useItemModal } from "hooks/useItemModal";
import { archiveLabCase, unarchiveLabCase } from "api/lab/mutations";
import { LabCaseHistoryFlyover } from "components/LabCases/LabCaseHistoryFlyover";
import { useQueryParams } from "hooks/useQueryParams";
import { handleError } from "utils/handleError";
import { LabCasesQuery, LabCasesSortDirection, LabCasesSortField } from "utils/routing/labCases";
import { getLabCasesFilterProps } from "components/LabCases/labCasesFilterQuery";
import { useLabCaseStatus } from "components/LabCases/hooks/useLabCaseStatus";
import { getInitials } from "utils/names";
import { LabInfoCard } from "components/LabCases/LabInfoCard";
import { OverflowItems } from "components/UI/OverflowItems";
import { PanelTitle } from "components/Settings/LabCases/PanelTitle";

// TODO: Consider extracting `labCasesQuery` to the parent components, along
// with the query filters logic.
export const LabCasesPage: FC<{
  patientId?: PatientVO["id"];
  onPatientIdSelected?: (patientId: PatientVO["id"]) => void;
  // eslint-disable-next-line complexity
}> = ({ patientId, onPatientIdSelected }) => {
  const { practiceId } = useAccount();
  const flyover = useItemModal<LabCaseVO["uuid"] | undefined>(null);
  const historyFlyover = useItemModal<LabCaseVO["uuid"]>(null);
  const archiveConfirmation = useItemModal<LabCaseVO["uuid"]>(null);
  const unarchiveConfirmation = useItemModal<LabCaseVO["uuid"]>(null);
  const { query, updateQuery } = useQueryParams("labCases");
  const [archiveMutation, unarchiveMutation] = useApiMutations([archiveLabCase, unarchiveLabCase]);
  const { labCaseReturnReasons, onLabCaseStatusChange } = useLabCaseStatus();

  const labCasesQuery = useInfiniteApiQuery(
    getLabCases({
      args: {
        practiceId,
        patientId,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        includeArchived: query.showArchived,
        statuses: query.labCaseStatuses,
        patientSearchString: query.patientSearchString,
        sortField: query.sortField,
        sortDirection: query.sortDirection,
      },
      queryOptions: { keepPreviousData: true },
    })
  );

  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: labCasesQuery });

  const labCases = flattenPages(labCasesQuery.data);
  const filtersFlyover = useBoolean(false);

  const handleApplyFilters = (updatedQueryFilters: LabCasesQuery) => {
    filtersFlyover.off();

    updateQuery("replaceIn", updatedQueryFilters);
  };

  const handleArchive = (labCaseUuid: LabCaseVO["uuid"]) => {
    archiveMutation.mutate(
      { practiceId, labCaseUuid },
      {
        onSuccess: archiveConfirmation.close,
        onError: handleError,
      }
    );
  };

  const handleUnarchive = (labCaseUuid: LabCaseVO["uuid"]) => {
    unarchiveMutation.mutate(
      { practiceId, labCaseUuid },
      {
        onSuccess: unarchiveConfirmation.close,
        onError: handleError,
      }
    );
  };

  const handleRowClick = useCallback(
    (e: MouseEvent, labCase: LabCaseVO) => {
      onPatientIdSelected?.(labCase.patient.id);
    },
    [onPatientIdSelected]
  );

  const handleRowDoubleClick = useCallback(
    (e: MouseEvent, labCase: LabCaseVO) => {
      flyover.open(labCase.uuid);
    },
    [flyover]
  );

  const showPatientName = patientId == null;

  return (
    <>
      <Panel
        includePadding={false}
        className="h-full"
        contentClassName="overflow-y-auto"
        title={<PanelTitle onAdd={() => flyover.open(undefined)}>Lab Cases</PanelTitle>}
        actions={
          <Menu
            isShowingArchived={query.showArchived}
            onShowArchived={() => {
              updateQuery("replaceIn", {
                showArchived: !query.showArchived,
              });
            }}
          />
        }
      >
        <QueryFilters
          {...getLabCasesFilterProps(query)}
          onUpdateParams={(updatedQueryParams) => handleApplyFilters({ ...query, ...updatedQueryParams })}
          params={query}
          queries={[labCasesQuery]}
          totalElements={labCases?.length ?? 0}
          searchParamKey={patientId == null ? "patientSearchString" : undefined}
          onOpenFlyover={filtersFlyover.on}
        />
        <QueryResult queries={[labCasesQuery]}>
          {labCases && (
            <PersistScrollPosition id={`lab-cases-${patientId ?? "all"}`} ref={rootRef}>
              <TableGrid
                columnWidths={[
                  "112px", // Date
                  ...(showPatientName ? ["minmax(200px,1fr)"] : []), // Patient
                  "minmax(max-content,176px)", // Lab Case Type
                  "minmax(188px,1fr)", // Procedure
                  "minmax(152px,1fr)", // Lab
                  "144px", // Est Receive Date
                  "112px", // Appt Date
                  "max-content", // Provider
                  "max-content", // Icons
                  "132px", // Lab Case status
                  "max-content", // menu
                ]}
              >
                <TableHeaders
                  showPatientName={showPatientName}
                  sortField={query.sortField}
                  sortDirection={query.sortDirection}
                  onSortFieldClick={(sortField) => {
                    updateQuery("replaceIn", {
                      sortField,
                      sortDirection:
                        query.sortField === sortField
                          ? query.sortDirection === "ASCENDING"
                            ? "DESCENDING"
                            : "ASCENDING"
                          : "ASCENDING",
                    });
                  }}
                />
                <ScrollableInfiniteQueryResult
                  infiniteQuery={labCasesQuery}
                  scrollRef={scrollRef}
                  loadMoreClassName="col-span-full"
                >
                  {labCases.map((labCase) => (
                    <Fragment key={labCase.uuid}>
                      <LabCaseRow
                        showPatientName={showPatientName}
                        labCase={labCase}
                        labCaseReturnReasons={labCaseReturnReasons}
                        onEdit={flyover.open}
                        onViewHistory={historyFlyover.open}
                        onArchive={archiveConfirmation.open}
                        onUnarchive={unarchiveConfirmation.open}
                        onLabCaseStatusChange={(...args) => onLabCaseStatusChange(...args).catch(handleError)}
                        onRowClick={(e) => handleRowClick(e, labCase)}
                        onRowDoubleClick={(e) => handleRowDoubleClick(e, labCase)}
                      />
                    </Fragment>
                  ))}
                </ScrollableInfiniteQueryResult>
              </TableGrid>
            </PersistScrollPosition>
          )}
        </QueryResult>
      </Panel>

      {flyover.isOpen && (
        <LabCaseFlyover
          labCaseUuid={flyover.item}
          patientId={patientId}
          onClose={flyover.close}
          onSave={flyover.close}
        />
      )}
      {archiveConfirmation.isOpen && (
        <ConfirmationModal
          primaryText="Archive Lab Case?"
          secondaryText="Are you sure you want to archive this lab case?"
          isConfirming={archiveMutation.isLoading}
          onConfirm={() => handleArchive(archiveConfirmation.item)}
          onCancel={archiveConfirmation.close}
        />
      )}
      {unarchiveConfirmation.isOpen && (
        <ConfirmationModal
          primaryText="Unarchive Lab Case?"
          secondaryText="Are you sure you want to unarchive this lab case?"
          isConfirming={unarchiveMutation.isLoading}
          onConfirm={() => handleUnarchive(unarchiveConfirmation.item)}
          onCancel={unarchiveConfirmation.close}
        />
      )}
      {historyFlyover.isOpen && (
        <LabCaseHistoryFlyover labCaseUuid={historyFlyover.item} onClose={historyFlyover.close} />
      )}
      {filtersFlyover.isOn && (
        <LabCaseQueryFiltersFlyover
          statuses={labCaseStatusOptions.map((status) => status.value)}
          selectedStatuses={query.labCaseStatuses ?? []}
          onSubmit={(updatedQueryParams) => handleApplyFilters({ ...query, ...updatedQueryParams })}
          onClose={filtersFlyover.off}
        />
      )}
    </>
  );
};

const TableHeaders: FC<{
  showPatientName: boolean;
  sortField: LabCasesSortField;
  sortDirection: LabCasesSortDirection;
  onSortFieldClick: (sort: LabCasesSortField) => void;
}> = ({ showPatientName, sortField, sortDirection, onSortFieldClick }) => {
  return (
    <Columns
      showPatientName={showPatientName}
      date={
        <HeaderButtonCell
          size="medium"
          className="flex items-center gap-x-2 text-xs font-sansSemiBold"
          onClick={() => onSortFieldClick("DATE")}
        >
          Date <ColumnSortIndicator direction={sortField === "DATE" ? sortDirection : undefined} />
        </HeaderButtonCell>
      }
      patient={
        <HeaderButtonCell
          size="medium"
          className="flex items-center gap-x-2 text-xs font-sansSemiBold"
          onClick={() => onSortFieldClick("PATIENT")}
        >
          Patient <ColumnSortIndicator direction={sortField === "PATIENT" ? sortDirection : undefined} />
        </HeaderButtonCell>
      }
      type={
        <HeaderCell size="medium" className="flex items-center gap-x-2 text-xs font-sansSemiBold">
          Type
        </HeaderCell>
      }
      procedure={<HeaderCell size="medium">Procedure</HeaderCell>}
      lab={
        <HeaderButtonCell
          size="medium"
          className="flex items-center gap-x-2 text-xs font-sansSemiBold"
          onClick={() => onSortFieldClick("LAB")}
        >
          Lab <ColumnSortIndicator direction={sortField === "LAB" ? sortDirection : undefined} />
        </HeaderButtonCell>
      }
      estReceiveDate={
        <HeaderButtonCell
          size="medium"
          className="flex items-center gap-x-2 text-xs font-sansSemiBold"
          onClick={() => onSortFieldClick("ESTIMATED_RECEIVE_DATE")}
        >
          Est Receive Date{" "}
          <ColumnSortIndicator
            direction={sortField === "ESTIMATED_RECEIVE_DATE" ? sortDirection : undefined}
          />
        </HeaderButtonCell>
      }
      appointmentDate={
        <HeaderButtonCell
          size="medium"
          className="flex items-center gap-x-2 text-xs font-sansSemiBold"
          onClick={() => onSortFieldClick("NEXT_PATIENT_PROCEDURE_DATE")}
        >
          Appt Date{" "}
          <ColumnSortIndicator
            direction={sortField === "NEXT_PATIENT_PROCEDURE_DATE" ? sortDirection : undefined}
          />
        </HeaderButtonCell>
      }
      provider={
        <HeaderCell size="medium" className="flex items-center gap-x-2 text-xs font-sansSemiBold">
          Prov
        </HeaderCell>
      }
      icons={<HeaderCell size="medium" />}
      status={
        <HeaderButtonCell
          size="medium"
          className="flex items-center gap-x-2 text-xs font-sansSemiBold"
          onClick={() => onSortFieldClick("STATUS")}
        >
          Status <ColumnSortIndicator direction={sortField === "STATUS" ? sortDirection : undefined} />
        </HeaderButtonCell>
      }
      menu={<HeaderCell size="medium" />}
    />
  );
};

const Columns: FC<{
  showPatientName: boolean;
  date: ReactNode;
  patient: ReactNode;
  type: ReactNode;
  procedure: ReactNode;
  lab: ReactNode;
  estReceiveDate: ReactNode;
  appointmentDate: ReactNode;
  provider: ReactNode;
  icons: ReactNode;
  status: ReactNode;
  menu: ReactNode;
}> = ({
  showPatientName,
  date,
  patient,
  type,
  procedure,
  lab,
  estReceiveDate,
  appointmentDate,
  provider,
  icons,
  status,
  menu,
}) => {
  return (
    <Row>
      {date}
      {showPatientName && patient}
      {type}
      {procedure}
      {lab}
      {estReceiveDate}
      {appointmentDate}
      {provider}
      {icons}
      {status}
      {menu}
    </Row>
  );
};

const LabCaseRow: FC<{
  showPatientName: boolean;
  labCase: LabCaseVO;
  labCaseReturnReasons: LabCaseReturnReasonVO[];
  onEdit: (labCaseUuid: LabCaseVO["uuid"]) => void;
  onViewHistory: (labCaseUuid: LabCaseVO["uuid"]) => void;
  onArchive: (labCaseUuid: LabCaseVO["uuid"]) => void;
  onUnarchive: (labCaseUuid: LabCaseVO["uuid"]) => void;
  onLabCaseStatusChange: (
    labCaseUuid: LabCaseVO["uuid"],
    status: LabCaseVO["status"],
    reason?: LabCaseReturnReasonVO["uuid"]
  ) => void;
  onRowClick?: MouseEventHandler;
  onRowDoubleClick?: MouseEventHandler;
}> = ({
  showPatientName,
  labCase,
  labCaseReturnReasons,
  onEdit,
  onViewHistory,
  onArchive,
  onUnarchive,
  onLabCaseStatusChange,
  onRowClick,
  onRowDoubleClick,
}) => {
  const providers = useMemo(() => {
    // Get a list of providers
    const providersList = labCase.patientProcedures.map((proc) => proc.provider).filter(isDefined);

    // Deduplicate the list of providers
    const uniqueProviderIds = new Set(providersList.map((provider) => provider.id));

    // Return the deduplicated list of providers
    return [...uniqueProviderIds]
      .map((id) => providersList.find((provider) => provider.id === id))
      .filter(isDefined);
  }, [labCase.patientProcedures]);

  return (
    <Columns
      showPatientName={showPatientName}
      date={
        <ButtonCell onClick={onRowClick} onDoubleClick={onRowDoubleClick} verticalPadding="slim">
          {formatISODate(labCase.date)}
        </ButtonCell>
      }
      patient={
        <ButtonCell onClick={onRowClick} onDoubleClick={onRowDoubleClick} verticalPadding="slim">
          {labCase.patient.fullDisplayName}
        </ButtonCell>
      }
      type={
        <ButtonCell onClick={onRowClick} onDoubleClick={onRowDoubleClick} verticalPadding="slim">
          {labCase.types.join(", ") || EMPTY_CELL}
        </ButtonCell>
      }
      procedure={
        <ButtonCell onClick={onRowClick} onDoubleClick={onRowDoubleClick} verticalPadding="slim">
          <ProcedurePillsWithOverflow procedures={labCase.patientProcedures} />
        </ButtonCell>
      }
      lab={
        <ButtonCell onClick={onRowClick} onDoubleClick={onRowDoubleClick} verticalPadding="slim">
          {labCase.lab ? (
            <FloatingTooltip content={<LabInfoCard lab={labCase.lab} />}>
              <span>{labCase.lab.name}</span>
            </FloatingTooltip>
          ) : (
            EMPTY_CELL
          )}
        </ButtonCell>
      }
      estReceiveDate={
        <ButtonCell onClick={onRowClick} onDoubleClick={onRowDoubleClick} verticalPadding="slim">
          <EstReceiveDate labCase={labCase} />
        </ButtonCell>
      }
      appointmentDate={
        <ButtonCell onClick={onRowClick} onDoubleClick={onRowDoubleClick} verticalPadding="slim">
          <OverflowItems
            className="items-center"
            items={labCase.appointments}
            renderItem={(appt) => <div key={appt.id}>{formatISODate(appt.date)}</div>}
            renderOverflow={(overflowItems) => (
              <FloatingTooltip
                theme="MEDIUM"
                content={<AppointmentOverflowTooltipContent appointments={overflowItems} />}
              >
                <span className="text-archyBlue-500 cursor-default">+{overflowItems.length}</span>
              </FloatingTooltip>
            )}
          />
        </ButtonCell>
      }
      provider={
        <ButtonCell onClick={onRowClick} onDoubleClick={onRowDoubleClick} verticalPadding="slim">
          {providers.flatMap((provider, i) => [
            <FloatingTooltip key={provider.id} content={provider.fullDisplayName}>
              <span>{getInitials(provider)}</span>
            </FloatingTooltip>,
            providers.length - 1 === i ? null : <span key={`${provider.id}-divider`}>, </span>,
          ])}
        </ButtonCell>
      }
      icons={
        <TextCell verticalPadding="slim">
          <div className="flex gap-x-2">
            <div className="w-5">
              {labCase.lab?.hasOrderFormFile && <LabFormIcon labUuid={labCase.lab.uuid} />}
            </div>
            <div className="w-5">
              {labCase.notes && (
                <FloatingTooltip content={labCase.notes}>
                  <NoteIcon className="w-5 h-5" />
                </FloatingTooltip>
              )}
            </div>
          </div>
        </TextCell>
      }
      status={
        <TextCell verticalPadding="slim">
          <LabCaseStatusMenuButton
            buttonClassName="w-28"
            labCaseReturnReasons={labCaseReturnReasons}
            onStatusChange={(status, reason) => onLabCaseStatusChange(labCase.uuid, status, reason)}
          >
            <LabCaseStatus labCaseStatus={labCase.status} />
          </LabCaseStatusMenuButton>
        </TextCell>
      }
      menu={
        <TextCell verticalPadding="slim">
          {labCase.isArchived ? (
            <ButtonIcon
              size="md"
              SvgIcon={UnarchiveIcon}
              onClick={() => onUnarchive(labCase.uuid)}
              tooltip={{ content: "Unarchive", theme: "SMALL" }}
            />
          ) : (
            <LabCaseMenu
              archiveLabel="Archive"
              onEdit={() => onEdit(labCase.uuid)}
              onViewHistory={() => onViewHistory(labCase.uuid)}
              onArchive={() => (labCase.isArchived ? onUnarchive(labCase.uuid) : onArchive(labCase.uuid))}
            />
          )}
        </TextCell>
      }
    />
  );
};

const LabCaseMenu: FC<{ archiveLabel: string; onEdit: Func; onViewHistory: Func; onArchive: Func }> = ({
  archiveLabel,
  onEdit,
  onViewHistory,
  onArchive,
}) => {
  const menu = useBoolean(false);

  const menuOptions = useMemo(
    () =>
      createMenuOptions(
        {
          label: "Edit",
          value: "edit",
          SvgIcon: EditIcon,
        },
        {
          label: "View History",
          value: "view-history",
          SvgIcon: HistoryIcon,
        },
        {
          label: archiveLabel,
          value: "archive",
          SvgIcon: ArchiveIcon,
        }
      ),
    [archiveLabel]
  );

  const handleOptionClick = useCallback(
    (option: { value: string }) => {
      switch (option.value) {
        case "edit": {
          onEdit();
          break;
        }
        case "view-history": {
          onViewHistory();
          break;
        }
        case "archive": {
          onArchive();

          break;
        }
        // no default
      }

      menu.off();
    },
    [menu, onArchive, onEdit, onViewHistory]
  );

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      menuContent={<MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />}
      onRequestClose={menu.off}
    >
      {(props) => {
        return <ButtonIcon {...props} size="md" SvgIcon={MenuIcon} />;
      }}
    </ButtonMenu>
  );
};

const EstReceiveDate: FC<{ labCase: LabCaseVO }> = ({ labCase }) => {
  return (
    <div className="text-xs flex items-center gap-x-3">
      {labCase.estimatedReceiveDate ? formatISODate(labCase.estimatedReceiveDate) : EMPTY_CELL}
      {labCase.appointments.some((app) =>
        labCase.estimatedReceiveDate
          ? isLeftISODateAfterRightISODate(labCase.estimatedReceiveDate, app.date)
          : false
      ) ? (
        <LabCaseLateWarningIcon />
      ) : null}
    </div>
  );
};

export const LabCaseLateWarningIcon: FC = () => {
  return (
    <FloatingTooltip content="The lab case is estimated to arrive after the procedure's scheduled appointment date.">
      <ErrorIcon className="w-5 h-5 text-redDark" />
    </FloatingTooltip>
  );
};

const Menu: FC<{ isShowingArchived: boolean; onShowArchived: Func }> = ({
  isShowingArchived,
  onShowArchived,
}) => {
  const menu = useBoolean(false);

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      menuContent={
        <MenuOptions
          options={createMenuOptions({
            label: isShowingArchived ? "Hide Archived" : "Show Archived",
            value: "show-archive",
            SvgIcon: ArchiveIcon,
          })}
          onOptionClick={(option) => {
            switch (option.value) {
              case "show-archive": {
                onShowArchived();
                break;
              }
              // no default
            }

            menu.off();
          }}
        />
      }
      onRequestClose={menu.off}
    >
      {(props) => {
        return <ButtonIcon {...props} size="lg" SvgIcon={MenuIcon} theme="primary" />;
      }}
    </ButtonMenu>
  );
};
