import { useCallback, useMemo } from "react";
import { ParentSize } from "@visx/responsive";
import { scaleLinear } from "@visx/scale";
import { format, isSameYear } from "date-fns";
import { InboundReferralResponse } from "@libs/api/generated-api";
import { pluralize } from "@libs/utils/pluralize";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { ChartContainer } from "components/Dashboard/Charting/ChartContainer";
import { DashboardDonutChart } from "components/Dashboard/Charting/DashboardDonutChart";
import { CHART_COLORS } from "components/Dashboard/Charting/theme";
import { ReferralsDonutTooltip } from "components/Dashboard/Referrals/ReferralsDonutTooltip";
import { ReferralPieDatum } from "components/Dashboard/Referrals/ReferralsRoute";
import { ReferralsQuery, TimeSegment } from "utils/routing/dashboard";
import { upsertDashFilter } from "components/Dashboard/utils/filters";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";

type Props = {
  inboundReferralStatsQuery: UseInfiniteApiQueryResult<InboundReferralResponse>;
  onUpdateParams: (updates: Partial<ReferralsQuery>) => void;
  query: ReferralsQuery;
  selectedTimeSegment: TimeSegment;
};

export const ReferralsChart: React.FC<Props> = ({
  inboundReferralStatsQuery,
  onUpdateParams,
  selectedTimeSegment,
  query,
}) => {
  const { startDate, endDate } = selectedTimeSegment;
  const referredByValues = useMemo(
    () => new Set(query.filters.find((item) => item.type === "referredBy")?.values),
    [query.filters]
  );

  const inboundReferrals = inboundReferralStatsQuery.data?.pages.flatMap((item) => item.data.entries);
  const data = useMemo(() => {
    const dataByOnboardingOptions: Record<string, ReferralPieDatum> = (inboundReferrals ?? []).reduce(
      (prev: Record<string, ReferralPieDatum>, currReferral) => {
        if (currReferral.referredBy in prev) {
          prev[currReferral.referredBy].actualValue += 1;
          prev[currReferral.referredBy].value += 1;
          prev[currReferral.referredBy].netProduction += currReferral.netProduction;
        } else {
          prev[currReferral.referredBy] = {
            actualValue: 1,
            className: "cursor-pointer",
            color: "",
            key: currReferral.referredBy,
            label: currReferral.referredBy,
            netProduction: currReferral.netProduction,
            selected: referredByValues.has(currReferral.referredBy),
            value: 1,
          };
        }

        return prev;
      },
      {}
    );
    const pieWedges = Object.values(dataByOnboardingOptions);

    const colorScale = scaleLinear({
      domain: [0, pieWedges.length],
      range: [CHART_COLORS.blue, "#DEFAFF"],
    });

    return pieWedges.map((item, index) => {
      return {
        ...item,
        color: colorScale(index),
      };
    });
  }, [inboundReferrals, referredByValues]);

  const totalPatients = data.reduce((acc, item) => acc + item.actualValue, 0);

  const handleDatumClicked = useCallback(
    (datum: ReferralPieDatum) => {
      const currentFilter: DashboardFilter = query.filters.find((item) => item.type === "referredBy") ?? {
        type: "referredBy",
        values: [],
      };
      const currentValues = currentFilter.values;
      const updatedFilter = {
        ...currentFilter,
        values: currentValues.includes(datum.key)
          ? currentFilter.values.filter((id) => id !== datum.key)
          : [...currentFilter.values, datum.key],
      };

      onUpdateParams({
        filters: upsertDashFilter(query.filters, updatedFilter as DashboardFilter),
      });
    },
    [onUpdateParams, query.filters]
  );
  const donutTitle = useMemo(() => {
    return isSameYear(startDate, endDate)
      ? `${format(startDate, "MMM do")} - ${format(endDate, "MMM do yyyy")}`
      : `${format(startDate, "MMM d yyyy")} - ${format(endDate, "MMM d yyyy")}`;
  }, [endDate, startDate]);

  return (
    <ChartContainer>
      <ParentSize className="relative pb-6">
        {({ height, width }: { width: number; height: number }) => {
          return (
            <DashboardDonutChart
              animate
              data={data}
              emptyResultsMessage="No referrals are in selected time range."
              error={Boolean(inboundReferralStatsQuery.error)}
              height={height}
              isLoading={inboundReferralStatsQuery.isLoading}
              onClickDatum={handleDatumClicked}
              title={donutTitle}
              subtitle={`${totalPatients} ${pluralize(totalPatients, "patient", "patients")}`}
              Tooltip={ReferralsDonutTooltip}
              width={width}
            />
          );
        }}
      </ParentSize>
    </ChartContainer>
  );
};
