import { FC, useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { PatientProcedureVO, TreatmentPlanVO } from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { BAD_REQUEST, NOT_FOUND } from "@libs/utils/statusCodes";
import { useApiQueries } from "@libs/hooks/useApiQueries";

import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { ItemsCount } from "@libs/components/UI/ItemsCount";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { QueryFilterPillsContainer } from "@libs/components/UI/QueryFilterPills";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { getTreatmentPlanQuery } from "api/charting/queries";
import { getPatientInsurancesQuery } from "api/patientInsurance/queries";
import { deleteTreatmentPlan, updateTreatmentPlan, updateTreatmentPlanState } from "api/charting/mutations";
import { ConfirmDeleteModal } from "components/Charting/ConfirmDeleteModal";
import { handleError } from "utils/handleError";
import { EditPatientProcedureModal } from "components/Charting/EditPatientProcedureModal";
import { ProceduresBulkActionsMenu } from "components/Charting/ProceduresBulkActionsMenu";
import { TreatmentPlanMenu } from "components/Charting/TreatmentPlanMenu";
import { ProceduresTableAlternate } from "components/Charting/ProceduresTableAlternate";
import { PatientProceduresTableLayout } from "components/Charting/ProceduresTableComponents";
import { PreAuthLoadingModal } from "components/Charting/PreAuthLoadingModal";
import { paths } from "utils/routing/paths";
import { usePatientProceduresTable } from "components/Charting/usePatientProceduresTable";
import { useItemModal } from "hooks/useItemModal";
import { EmptyProceduresList } from "components/Charting/EmptyProceduresList";
import { ConfirmInsuranceEstimateModal } from "components/Insurance/ConfirmInsuranceEstimateModal";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";

import { PatientProceduresQueryFilterPills } from "components/Charting/PatientProceduresQueryFilterPills";
import { KioskModal } from "components/PatientProfile/Forms/KioskModal";
import { SendTreatmentPlanModal } from "components/Charting/SendTreatmentPlanModal";
import { EditEstimatesFlyover } from "components/Charting/EditEstimatesFlyover";

// eslint-disable-next-line complexity
export const TreatmentPlanTableRoute: FC<{ treatmentPlanUuid: string }> = ({ treatmentPlanUuid }) => {
  const { practiceId } = useAccount();
  const { patientId } = usePathParams("charting");
  const { query, updateQuery } = useQueryParams("charting");

  const navigate = useNavigate();
  const location = useLocation();
  const confirmDeleteTxPlanModal = useItemModal<TreatmentPlanVO>(null);
  const confirmDeleteModal = useItemModal<{
    treatmentPlan: TreatmentPlanVO;
    patientProcedures: PatientProcedureVO[];
  }>(null);

  const [treatmentPlanQuery, { data: patientInsurances }] = useApiQueries([
    getTreatmentPlanQuery({ args: { practiceId, treatmentPlanUuid } }),
    getPatientInsurancesQuery({ args: { practiceId, patientId, insuranceState: ["ACTIVE"] } }),
  ]);

  const [updateTreatmentPlanStateMutation, updateTreatmentPlanMutation, deleteTreatmentPlanMutation] =
    useApiMutations([updateTreatmentPlanState, updateTreatmentPlan, deleteTreatmentPlan]);

  const currentTreatmentPlan = useMemo(() => {
    return treatmentPlanQuery.data
      ? {
          ...treatmentPlanQuery.data,
          patientProcedures: query.patientProcedureStatuses.length
            ? treatmentPlanQuery.data.patientProcedures.filter((pp) =>
                isOneOf(pp.status, query.patientProcedureStatuses)
              )
            : treatmentPlanQuery.data.patientProcedures,
        }
      : undefined;
  }, [treatmentPlanQuery.data, query.patientProcedureStatuses]);

  const {
    handleCheckboxChange,
    resetSelectedRows,
    handleSendProceduresToPreAuthClick,
    handleAddProceduresToTreatmentPlanClick,
    handleScheduleProceduresClick,
    handlePatientProcedureSaved,
    deleteMasterProcedures,
    handlePrioritizeProcedures,
    handleEditClick,
    handleEditEstimatesClick,
    handleCreateTreatmentPlanFormTask,
    handlePriorityChanged,
    handleSendPreAuthClick,
    selectedRows,
    treatmentPlans,
    isCreatingPreAuth,
    kioskModal,
    sendFormsModal,
    formTaskLoading,
    editPatientProceduresModal,
    editProcedureEstimatesFlyover,
    confirmProceduresEstimateModal,
    confirmProceduresEstimateModalTitle,
    selectAllRows,
  } = usePatientProceduresTable({
    patientId,
    procedureIds: currentTreatmentPlan?.patientProcedures.map((proc) => proc.id),
    treatmentPlanUuid,
    patientInsurances,
  });

  const handleDeleteProceduresClick = useCallback(
    (selectedProcedures: PatientProcedureVO[]) => {
      if (currentTreatmentPlan) {
        if (selectedProcedures.length) {
          confirmDeleteModal.open({
            treatmentPlan: currentTreatmentPlan,
            patientProcedures: selectedProcedures,
          });
        } else {
          confirmDeleteTxPlanModal.open(currentTreatmentPlan);
        }
      }
    },
    [confirmDeleteModal, currentTreatmentPlan, confirmDeleteTxPlanModal]
  );

  const deleteTreatmentPlanProcedures = useCallback(
    (treatmentPlan: TreatmentPlanVO, selectedProcedures: PatientProcedureVO[]) => {
      updateTreatmentPlanMutation.mutate(
        {
          practiceId,
          treatmentPlanUuid: treatmentPlan.uuid,
          patientId,
          data: {
            name: treatmentPlan.name,
            patientProcedures: treatmentPlan.patientProcedures.filter(
              (proc) => !selectedProcedures.some((selected) => selected.id === proc.id)
            ),
          },
        },
        {
          onError: handleError,
          onSuccess: () => {
            resetSelectedRows();
          },
        }
      );
    },
    [updateTreatmentPlanMutation, practiceId, patientId, resetSelectedRows]
  );

  const handleConfirmDeleteProcedures = useCallback(
    (deleteFromAll: boolean) => {
      if (confirmDeleteModal.isOpen) {
        if (deleteFromAll) {
          deleteMasterProcedures(confirmDeleteModal.item.patientProcedures);
        } else {
          deleteTreatmentPlanProcedures(
            confirmDeleteModal.item.treatmentPlan,
            confirmDeleteModal.item.patientProcedures
          );
        }

        confirmDeleteModal.close();
      }
    },
    [deleteMasterProcedures, deleteTreatmentPlanProcedures, confirmDeleteModal]
  );

  const handleTreatmentPlanStateChange = useCallback(
    (newState: TreatmentPlanVO["state"]) => {
      updateTreatmentPlanStateMutation.mutate(
        {
          patientId,
          practiceId,
          treatmentPlanUuid,
          data: {
            state: newState,
          },
        },
        {
          onError: handleError,
        }
      );
    },
    [updateTreatmentPlanStateMutation, patientId, practiceId, treatmentPlanUuid]
  );

  const handleDeleteTreatmentPlanClick = useCallback(() => {
    deleteTreatmentPlanMutation.mutate(
      {
        patientId,
        practiceId,
        treatmentPlanUuid,
      },
      {
        onError: handleError,
        onSuccess: () => {
          navigate(paths.charting({ patientId }), { replace: true });
        },
      }
    );
  }, [deleteTreatmentPlanMutation, patientId, practiceId, navigate, treatmentPlanUuid]);

  const handleSaveTreatmentPlanName = useCallback(
    (treatmentPlan: TreatmentPlanVO, draftPlanName: string) => {
      updateTreatmentPlanMutation.mutate(
        {
          practiceId,
          treatmentPlanUuid: treatmentPlan.uuid,
          patientId,
          data: {
            name: draftPlanName,
            patientProcedures: treatmentPlan.patientProcedures,
          },
        },
        {
          onError: handleError,
        }
      );
    },
    [patientId, practiceId, updateTreatmentPlanMutation]
  );

  useEffect(() => {
    resetSelectedRows();
  }, [treatmentPlanUuid, query.patientProcedureStatuses, resetSelectedRows]);

  return (
    <>
      <QueryResult
        queries={[treatmentPlanQuery]}
        loadError={
          [NOT_FOUND, BAD_REQUEST].includes(treatmentPlanQuery.error?.status ?? 0) ? (
            <EmptyProceduresList>Treatment Plan Not Found</EmptyProceduresList>
          ) : (
            <ErrorContent />
          )
        }
      >
        {currentTreatmentPlan ? (
          <PatientProceduresTableLayout
            menuRow={
              <>
                <TreatmentPlanMenu
                  treatmentPlan={currentTreatmentPlan}
                  onTreatmentPlanStateChange={handleTreatmentPlanStateChange}
                  onSaveTreatmentPlanName={handleSaveTreatmentPlanName}
                />
                <div className="h-6 flex items-center">
                  <VerticalDivider size="sm" />
                </div>
                <ProceduresBulkActionsMenu
                  className="pt-0.5"
                  procedures={currentTreatmentPlan.patientProcedures}
                  totalCount={currentTreatmentPlan.patientProcedures.length}
                  treatmentPlans={treatmentPlans}
                  currentTreatmentPlan={currentTreatmentPlan}
                  selectedRows={selectedRows}
                  isPrinting={formTaskLoading === "print"}
                  isSendingToKiosk={formTaskLoading === "kiosk"}
                  onAddProceduresToTreatmentPlanClick={handleAddProceduresToTreatmentPlanClick}
                  handleCreateTreatmentPlanFormTask={handleCreateTreatmentPlanFormTask}
                  onScheduleProceduresClick={handleScheduleProceduresClick}
                  onDeleteProceduresClick={handleDeleteProceduresClick}
                  onSendProceduresToPreAuthClick={handleSendProceduresToPreAuthClick}
                  onPrioritizeProceduresClick={handlePrioritizeProcedures}
                />

                <QueryFilterPillsContainer>
                  <ItemsCount
                    className="whitespace-nowrap"
                    selectedCount={selectedRows.size}
                    total={currentTreatmentPlan.patientProcedures.length}
                    singular="procedure"
                    plural="procedures"
                  />

                  <PatientProceduresQueryFilterPills
                    query={query}
                    onUpdate={(params) => updateQuery("replaceIn", params)}
                  />
                </QueryFilterPillsContainer>
              </>
            }
          >
            <ProceduresTableAlternate
              patientId={patientId}
              queryKey={location.pathname}
              selectedRows={selectedRows}
              isFiltered={query.patientProcedureStatuses.length > 0}
              treatmentPlan={currentTreatmentPlan}
              onDeselectAllRows={resetSelectedRows}
              onSelectAllRows={selectAllRows}
              onEditClick={handleEditClick}
              onEditEstimatesClick={handleEditEstimatesClick}
              onPriorityChanged={handlePriorityChanged}
              onToggleRow={handleCheckboxChange}
              onSendPreAuthClick={(patientProcedure) => handleSendPreAuthClick(patientProcedure)}
              editProcedureId={
                editPatientProceduresModal.item?.patientProcedure.id ?? editProcedureEstimatesFlyover.item?.id
              }
            />
          </PatientProceduresTableLayout>
        ) : null}
      </QueryResult>

      {confirmDeleteTxPlanModal.isOpen ? (
        <ConfirmationModal
          size="2xs"
          isConfirming={deleteTreatmentPlanMutation.isLoading}
          onCancel={confirmDeleteTxPlanModal.close}
          onConfirm={handleDeleteTreatmentPlanClick}
          primaryText={`Remove Treatment Plan "${confirmDeleteTxPlanModal.item.name}"`}
          secondaryText="The procedures in this plan will still be available in the Master Tx Plan. Are you sure you want to continue?"
        />
      ) : null}

      {confirmDeleteModal.isOpen ? (
        <ConfirmDeleteModal
          onCancel={confirmDeleteModal.close}
          onConfirm={handleConfirmDeleteProcedures}
          treatmentPlan={confirmDeleteModal.item.treatmentPlan}
          procedures={confirmDeleteModal.item.patientProcedures}
        />
      ) : null}
      {isCreatingPreAuth ? <PreAuthLoadingModal /> : null}

      {editPatientProceduresModal.isOpen ? (
        <EditPatientProcedureModal
          onRequestClose={editPatientProceduresModal.close}
          onSaved={() => {
            handlePatientProcedureSaved();
            editPatientProceduresModal.close();
          }}
          validateOptions={editPatientProceduresModal.item.validateOptions}
          patientProcedureId={editPatientProceduresModal.item.patientProcedure.id}
          patientProcedure={editPatientProceduresModal.item.patientProcedure}
          targetTreatmentPlanUuid={editPatientProceduresModal.item.targetTreatmentPlanUuid}
          patientId={patientId}
        />
      ) : null}
      {editProcedureEstimatesFlyover.isOpen ? (
        <EditEstimatesFlyover
          patientProcedureId={editProcedureEstimatesFlyover.item.id}
          onSaved={handlePatientProcedureSaved}
          onClose={editProcedureEstimatesFlyover.close}
        />
      ) : null}
      {confirmProceduresEstimateModal.isOpen ? (
        <ConfirmInsuranceEstimateModal
          type={
            confirmProceduresEstimateModal.item.method === "print" ? "print-procedures" : "send-procedures"
          }
          title={confirmProceduresEstimateModalTitle}
          onConfirm={(forceInsuranceEstimate) =>
            handleCreateTreatmentPlanFormTask({
              ...confirmProceduresEstimateModal.item,
              forceInsuranceEstimate,
            })
          }
          onRequestClose={confirmProceduresEstimateModal.close}
        />
      ) : null}
      {kioskModal.item ? <KioskModal onRequestClose={kioskModal.close} kioskCode={kioskModal.item} /> : null}
      {sendFormsModal.item ? (
        <SendTreatmentPlanModal
          patientId={patientId}
          formTaskRequest={sendFormsModal.item}
          onRequestClose={sendFormsModal.close}
        />
      ) : null}
    </>
  );
};
