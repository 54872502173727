import { AlertSettingsVO } from "@libs/api/generated-api";
import { useAccount } from "@libs/contexts/AccountContext";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isDefined } from "@libs/utils/types";
import { useEffect } from "react";
import { getLastAlertedPatient, trackLastAlertedPatient, usePatientAlertSnoozeStorage } from "storage/alerts";

export const useShowAlerts = ({
  patientId,
  alertsCount,
  appearance,
  areNotesShowing,
  hipaaView,
}: {
  patientId: number;
  alertsCount: number | undefined;
  appearance: AlertSettingsVO["appearance"] | undefined;
  areNotesShowing: boolean;
  hipaaView: boolean;
}) => {
  const showAlerts = useBoolean(false);
  const { id: userId } = useAccount();

  const { arePatientAlertsSnoozed } = usePatientAlertSnoozeStorage();

  const hide = showAlerts.off;
  const show = showAlerts.on;

  useEffect(() => {
    if (!isDefined(appearance) || !isDefined(alertsCount)) {
      return;
    }

    if (appearance === "SHOW_ALERT") {
      if (
        alertsCount > 0 &&
        !hipaaView &&
        !areNotesShowing &&
        patientId !== getLastAlertedPatient() &&
        !arePatientAlertsSnoozed({ patientId, userId })
      ) {
        show();
      }

      trackLastAlertedPatient(patientId);
    }
  }, [patientId, userId, hipaaView, alertsCount, show, appearance, arePatientAlertsSnoozed, areNotesShowing]);

  useEffect(() => {
    if (!alertsCount && showAlerts.isOn) {
      hide();
    }
  }, [alertsCount, showAlerts.isOn, hide]);

  return {
    hideAlerts: hide,
    showAlerts: show,
    isShowingAlerts: showAlerts.isOn,
  };
};
