import { useMemo } from "react";
import { ProviderVO, RoomVO } from "@libs/api/generated-api";
import { isNullish } from "@libs/utils/types";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { ReactComponent as TimeIcon } from "@libs/assets/icons/clock.svg";
import { mapSelectionsToOptions } from "@libs/utils/mapSelectOptions";
import { keys } from "@libs/utils/object";
import {
  SlotAttributeOption,
  SlotAttributeOptionProps,
} from "components/ScheduleAppointments/SlotAttributeOption";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { AppointmentSlotEditor } from "components/ScheduleAppointments/useAppointmentSlotEditor";
import { FormFieldTimeRangeMenu } from "components/UI/FormFieldTimeRangeMenu";
import { useAppointmentDurationContext } from "components/ScheduleAppointments/AppointmentDurationContext";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { PreSelections } from "components/ScheduleAppointments/types";

interface Props {
  savedProvider: ProviderVO | undefined;
  savedDentist: ProviderVO | undefined;
  savedRoom: RoomVO | undefined;
  providers: ProviderVO[] | undefined;
  rooms: RoomVO[] | undefined;
  disabled: boolean;
  slotEditor: AppointmentSlotEditor;
  timeError: string | undefined;
}

const cxStyles = {
  inputs: `
    flex
    flex-wrap
    bg-offWhite
    border
    border-greyLighter
    p-3
    rounded
    gap-2
  `,
  baseField: "w-40",
  title: "font-sansSemiBold text-sm mb-3",
};

export const isProviderConsideredAvailable = (
  provider: ProviderVO,
  preSelections: { dentistId: number; hygienistId: number },
  selectedHygienistId: number
) => {
  // GF-4368 if a hygienist has been chosen
  // we no longer care about the availability of
  // the dentist
  return (
    Boolean(provider.isAvailable) ||
    (provider.jobCategory === "DENTIST"
      ? preSelections.dentistId === provider.id || Boolean(selectedHygienistId)
      : preSelections.hygienistId === provider.id)
  );
};

const getProviderOption = (
  provider: ProviderVO,
  preSelections: PreSelections,
  hygienistId: number
): SlotAttributeOptionProps => ({
  value: provider.id,
  label: provider.name.fullDisplayName,
  isAvailable: isProviderConsideredAvailable(
    provider,
    { dentistId: preSelections.dentistId, hygienistId: preSelections.hygienistId },
    hygienistId
  ),
});

const getAvailabilityMap = (options: { value: number; isAvailable?: boolean }[]) => {
  const availability: Record<number, boolean> = {};

  for (const option of options) {
    availability[option.value] = Boolean(option.isAvailable);
  }

  return availability;
};

// eslint-disable-next-line complexity
export const AppointmentFormEditSlot: React.FC<Props> = ({
  disabled,
  rooms,
  slotEditor,
  providers,
  timeError,
  savedDentist,
  savedProvider,
  savedRoom,
}) => {
  const { availableRoomsQuery, availableProvidersQuery, selections, preSelections } = slotEditor;

  const { roomId, hygienistId, dentistId, startTime, date } = selections;
  const duration = useAppointmentDurationContext();
  const { dentistOptions, hygienistOptions } = useMemo(() => {
    const dentists: ProviderVO[] = [];
    const hygienists: ProviderVO[] = [];
    const providerOptions = availableProvidersQuery.data || providers;

    if (providerOptions) {
      for (const provider of providerOptions) {
        if (provider.jobCategory === "DENTIST") {
          dentists.push(provider);
        } else if (provider.jobCategory === "HYGIENIST") {
          hygienists.push(provider);
        }
      }
    }

    return {
      dentistOptions: mapSelectionsToOptions(dentists, savedDentist, (provider) =>
        getProviderOption(provider, preSelections, hygienistId)
      ),
      hygienistOptions: mapSelectionsToOptions(
        hygienists,
        savedProvider && savedProvider.id !== savedDentist?.id ? savedProvider : undefined,
        (provider) => getProviderOption(provider, preSelections, hygienistId)
      ),
    };
  }, [availableProvidersQuery.data, providers, savedDentist, savedProvider, hygienistId, preSelections]);

  const providerAvailability = useMemo(
    () => getAvailabilityMap([...dentistOptions, ...hygienistOptions]),
    [dentistOptions, hygienistOptions]
  );

  const { roomOptions, roomAvailability } = useMemo(() => {
    const roomsData = (availableRoomsQuery.data || rooms) ?? [];
    const options: SlotAttributeOptionProps[] = mapSelectionsToOptions(roomsData, savedRoom, (room) => ({
      value: room.id,
      label: room.roomName,
      isAvailable: isNullish(room.isAvailable)
        ? room.isAvailable
        : room.isAvailable || roomId === room.id || room.id === savedRoom?.id,
    }));

    return {
      roomOptions: options,
      roomAvailability: getAvailabilityMap(options),
    };
  }, [availableRoomsQuery.data, roomId, rooms, savedRoom]);

  const canFetchAvailability = date && startTime && duration.read;
  const changedSelections = new Set(
    keys(preSelections).filter((key) => preSelections[key] !== selections[key])
  );

  return (
    <div className="flex">
      <div className={cxStyles.inputs}>
        <FormFieldSelectMenusDatepicker
          label="Date"
          isClearable
          placeholderText="Appt Day"
          className="w-40"
          selected={date ? getLocalDate(date) : undefined}
          disabled={disabled}
          onChange={(newDate) => {
            slotEditor.updateSelections({ date: newDate ? formatAsISODate(newDate) : undefined });
          }}
        />
        <FormFieldTimeRangeMenu
          label="Time"
          disabled={disabled || !date}
          error={timeError}
          rangeType="durationInMinutes"
          startTime={startTime}
          durationInMinutes={duration.read}
          className="min-w-36"
          placeholder="Appt Time"
          Icon={TimeIcon}
          onChange={(updates) => {
            slotEditor.updateSelections({ startTime: updates.startTime });
            duration.update(updates.durationInMinutes);
          }}
        />
        <FormFieldSelect
          disabled={disabled || !canFetchAvailability}
          className={cxStyles.baseField}
          required={false}
          label="Hygienist"
          options={hygienistOptions}
          onItemChanged={(value) => slotEditor.updateSelections({ hygienistId: value || 0 })}
          isSearchable={false}
          formatOptionLabel={(props, meta) => (
            <SlotAttributeOption {...props} context={meta.context} selectedValue={hygienistId} />
          )}
          error={
            hygienistId && changedSelections.has("hygienistId") && !providerAvailability[hygienistId]
              ? "Hygienist not available"
              : undefined
          }
          value={hygienistId}
        />

        <FormFieldSelect
          disabled={disabled || !canFetchAvailability}
          className={cxStyles.baseField}
          required={true}
          error={
            availableProvidersQuery.isError
              ? "There was a problem loading provider availability. You can still schedule the appointment but it's possible the provider you choose is already booked."
              : dentistId && changedSelections.has("dentistId") && !providerAvailability[dentistId]
                ? "Provider not available"
                : undefined
          }
          label="Provider"
          options={dentistOptions}
          onItemSelected={(value) => {
            slotEditor.updateSelections({ dentistId: value });
          }}
          isSearchable={false}
          formatOptionLabel={(props, meta) => (
            <SlotAttributeOption
              {...props}
              isAvailable={Boolean(hygienistId) || props.isAvailable}
              context={meta.context}
              selectedValue={dentistId}
            />
          )}
          value={dentistId}
        />
        <FormFieldSelect
          disabled={disabled || !canFetchAvailability}
          className={cxStyles.baseField}
          required={true}
          error={
            availableRoomsQuery.isError
              ? "There was a problem loading room availability. You can still schedule the appointment but it's possible the room you choose is already booked."
              : roomId && changedSelections.has("roomId") && !roomAvailability[roomId]
                ? "Room not available"
                : undefined
          }
          label="Room"
          options={roomOptions}
          isSearchable={false}
          formatOptionLabel={(props, meta) => (
            <SlotAttributeOption {...props} context={meta.context} selectedValue={roomId} />
          )}
          onItemSelected={(value) => slotEditor.updateSelections({ roomId: value })}
          value={roomId}
        />
      </div>
    </div>
  );
};
