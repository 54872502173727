/* eslint-disable @typescript-eslint/naming-convention */
import { FC, useMemo } from "react";

import { PerioChartExamEntryVO, PerioChartExamVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { formatISODate } from "@libs/utils/date";
import { HeaderCell, Row, TableGrid, TextCell } from "@libs/components/UI/GridTableComponents";
import { getExamTeethSummary } from "components/Charting/Perio/perioChartUtils";
import { PerioDepthTrendTable } from "components/Charting/Perio/PerioCompare/PerioDepthTrendTable";
import { PerioTrendChangeCell } from "components/Charting/Perio/PerioCompare/PerioTrendChangeCell";

type Props = {
  exams: PerioChartExamVO[];
  sequenceType: "GINGMARGIN" | "PROBING";
  // settings: PerioChartExamSettingsVO;
};

const PERIO_FIELDS = [
  "mbValue" as const,
  "dbValue" as const,
  "mlValue" as const,
  "dlValue" as const,
  "lValue" as const,
  "bValue" as const,
];

type TableRow = {
  uuid: string;
  date: string;
  values: {
    count: number;
    percent: number;
  }[];
};

const getDeltas = (currentExamEntries: PerioChartExamEntryVO[], prevEntries?: PerioChartExamEntryVO[]) => {
  if (!prevEntries) {
    return undefined;
  }

  return currentExamEntries.map((currentEntry) => {
    const prevEntry = prevEntries.find((entry) => entry.toothNum === currentEntry.toothNum);
    const siteDeltas = PERIO_FIELDS.map((currentField) => {
      const currentValue = currentEntry[currentField];
      const prevValue = prevEntry?.[currentField];

      if (isDefined(currentValue) && isDefined(prevValue)) {
        return Math.abs(currentValue) - Math.abs(prevValue);
      }

      return 0;
    });

    return {
      entry: currentEntry,
      siteDeltas,
    };
  });
};

// MARGIN_DELTA_COLUMNS represents growth or recession of a given site over time
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const MARGIN_DELTA_COLUMNS = [2, 1, 0, -1, -2];

const PerioStatesOnDateRow: React.FC<{
  row: TableRow;
  isLast?: boolean;
  previousRow?: TableRow;
}> = ({ row, isLast = false, previousRow }) => {
  const sharedProps = {
    border: !isLast,
    className: "border-r last:border-r-0 text-xs",
  };

  return (
    <Row>
      <TextCell {...sharedProps}>{formatISODate(row.date)}</TextCell>
      {row.values.map(({ count, percent }, i) => {
        const prevCount = previousRow?.values[i].count;
        const delta = isDefined(prevCount) && prevCount !== 0 ? (count - prevCount) / Math.abs(prevCount) : 0;

        return (
          <PerioTrendChangeCell
            index={i}
            delta={delta}
            percent={percent}
            key={row.uuid}
            finding={delta < 0 ? "positive" : delta > 0 ? "negative" : undefined}
            {...sharedProps}
            count={count}
          />
        );
      })}
    </Row>
  );
};

type TableSequenceType = Extract<
  PerioChartExamEntryVO["perioSequenceType"],
  "PLAQUE" | "CALCULUS" | "BLEEDING" | "SUPPURATION"
>;

const STATES_IN_TABLE: TableSequenceType[] = ["PLAQUE", "CALCULUS", "BLEEDING", "SUPPURATION"];
const STATE_COLUMNS = [
  {
    label: "Date",
    width: "11rem",
  },
  {
    label: "Plaque",
    width: "1fr",
  },
  {
    label: "Calculus",
    width: "1fr",
  },
  {
    label: "Bleeding",
    width: "1fr",
  },
  {
    label: "Suppuration",
    width: "1fr",
  },
];
const populatedSitesForType = (entries: PerioChartExamEntryVO[]) => {
  const statesInTable = new Set(STATES_IN_TABLE);
  const perioStateCounts: Record<TableSequenceType, number> = {
    PLAQUE: 0,
    CALCULUS: 0,
    BLEEDING: 0,
    SUPPURATION: 0,
  };

  for (const entry of entries) {
    const sequenceType = entry.perioSequenceType as TableSequenceType;

    if (statesInTable.has(sequenceType)) {
      perioStateCounts[sequenceType] += PERIO_FIELDS.reduce((acc, field) => acc + (entry[field] ?? 0), 0);
    }
  }

  return perioStateCounts;
};

const PerioStatesByDate: React.FC<{ data: TableRow[] }> = ({ data }) => {
  return (
    <TableGrid
      className="border border-slate-300 rounded-md"
      columnWidths={STATE_COLUMNS.map(({ width }) => width)}
    >
      <Row>
        {STATE_COLUMNS.map(({ label }) => (
          <HeaderCell bgColor="transparent" size="short" key={label} className="border-r last:border-r-0">
            {label}
          </HeaderCell>
        ))}
      </Row>
      {data.map((row, i) => {
        return (
          <PerioStatesOnDateRow
            key={row.uuid}
            row={row}
            isLast={i === data.length - 1}
            previousRow={data[i - 1]}
          />
        );
      })}
    </TableGrid>
  );
};

export const PerioCompareSitesTrendTables: FC<Props> = ({ exams, sequenceType }) => {
  const examRows = useMemo(() => {
    return exams.map((exam, i) => {
      const { totalSiteCount } = getExamTeethSummary(exam);

      const depthEntries = exam.entries.filter((entry) => entry.perioSequenceType === sequenceType);
      const prevDepthEntries = exams[i - 1]?.entries.filter(
        (entry) => entry.perioSequenceType === sequenceType
      );
      const deltas = getDeltas(depthEntries, prevDepthEntries);

      const siteDepthDeltas = MARGIN_DELTA_COLUMNS.map((delta, j) => {
        if (!deltas) {
          return 0;
        }

        // eslint-disable-next-line max-nested-callbacks
        return deltas.reduce((acc, entryDelta) => {
          return (
            entryDelta.siteDeltas.reduce(
              // eslint-disable-next-line max-nested-callbacks
              (siteAccumulator, siteDelta) => {
                if (j === 0) {
                  return siteDelta >= delta ? siteAccumulator + 1 : siteAccumulator;
                } else if (j === MARGIN_DELTA_COLUMNS.length - 1) {
                  return siteDelta <= delta ? siteAccumulator + 1 : siteAccumulator;
                }

                return siteDelta === delta ? siteAccumulator + 1 : siteAccumulator;
              },
              0
            ) + acc
          );
        }, 0);
      }).map((count) => ({ count, percent: count / totalSiteCount }));
      const sequenceTypeCountForType = populatedSitesForType(exam.entries);

      const sharedProps = {
        uuid: exam.uuid,
        date: exam.date,
      };

      return {
        states: {
          ...sharedProps,
          values: STATES_IN_TABLE.map((value) => ({
            count: sequenceTypeCountForType[value],
            percent: sequenceTypeCountForType[value] / totalSiteCount,
          })),
        },
        depthChange: {
          ...sharedProps,
          values: siteDepthDeltas,
        },
      };
    });
  }, [exams, sequenceType]);

  return (
    <div className="flex flex-col gap-6">
      <PerioDepthTrendTable data={examRows.map(({ depthChange }) => depthChange)} />
      <PerioStatesByDate data={examRows.map(({ states }) => states)} />
    </div>
  );
};
