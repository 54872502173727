import { FormEventHandler, useCallback, useMemo } from "react";
import { toast } from "react-toastify";
import { Navigate } from "react-router-dom";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useValidation } from "@libs/hooks/useValidation";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { isHttpResponseError } from "@libs/utils/isHttpResponseError";
import { useAccount } from "@libs/contexts/AccountContext";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { Form } from "@libs/components/UI/Form";
import { DetailsModalPage } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/DetailsModalPage";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import {
  getGroupedBenefitLimitationsQuery,
  getPatientInsuranceQuery,
  getRecentProcedureHistoryItemsQuery,
} from "api/patientInsurance/queries";
import { InsurancePlanDetailsFooter } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/Footer";
import { PlanNotFound } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/PlanNotFound";
import { LimitationsAndHistoryTables } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/LimitationsAndHistoryTables";
import { usePlanFields } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/usePlanFields";
import { getPracticeFeeScheduleNamesQuery } from "api/feeSchedule/queries";
import { useHistoryFields } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/useHistoryFields";
import {
  BenefitLimitationFormItem,
  useLimitationsFields,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/useLimitationFields";
import {
  getLimitationsPutData,
  getLimitationsSchema,
  getProcedureHistoryPutData,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/formData";
import { LimitationNotesModal } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/LimitationNotesModal";
import { useItemModal } from "hooks/useItemModal";
import {
  isProcedureHistoryConflict,
  updateInsurancePlan,
  upsertBenefitLimitations,
  upsertProcedureHistory,
} from "api/patientInsurance/mutations";
import { handleError } from "utils/handleError";
import { getPlanUpdateSubmission } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/formUtils";
import { isAutoVerified } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/SubscriberTab/utils";

export const LIMITATIONS_FORM_ID = "patient-insurance-limitations-form";

// eslint-disable-next-line complexity, max-statements
export const LimitationsRoute: React.FC = () => {
  const { practiceId } = useAccount();
  const { insuranceId, patientId } = usePathParams("patientInsuranceDetails");
  const { query } = useQueryParams("patientInsuranceDetails");
  const backUrl = query.from ?? paths.patientTab({ patientId, tab: "insurance" });
  const isCreating = insuranceId === "new";
  const numericInsuranceId = insuranceId === "new" ? 0 : insuranceId;

  const [patientInsuranceQuery, procedureHistoryQuery] = useApiQueries([
    getPatientInsuranceQuery({
      args: {
        includeBenefitCoverage: true,
        includeInsurancePlan: true,
        insuranceId: isCreating ? 0 : insuranceId,
        patientId,
        practiceId,
      },
      queryOptions: { enabled: !isCreating },
    }),
    getRecentProcedureHistoryItemsQuery({
      args: { practiceId, insuranceId: numericInsuranceId, patientId },
    }),
  ]);
  const {
    data: patientInsuranceResponse,
    error: insuranceError,
    isFetched: insuranceFetched,
  } = patientInsuranceQuery;

  const insurancePlan = patientInsuranceResponse?.insurancePlan;
  const planUuid = insurancePlan?.uuid;
  const patientInsurance = patientInsuranceResponse?.patientInsurance;
  const subscriber = patientInsurance?.subscriber;
  const carrierId = insurancePlan?.carrier.id ?? subscriber?.carrierId;

  const [feeSchedulesQuery, groupedBenefitLimitationsQuery] = useApiQueries([
    getPracticeFeeScheduleNamesQuery({
      args: { carrierId: carrierId ?? 0, practiceId },
      queryOptions: { enabled: Boolean(carrierId) },
    }),
    getGroupedBenefitLimitationsQuery({
      args: { practiceId, insurancePlanUuid: planUuid ?? "" },
      queryOptions: { enabled: Boolean(planUuid) },
    }),
  ]);

  const editing = useBoolean(false);

  const { handlePlanFieldChange, numOfPatients, planFields } = usePlanFields(
    patientInsuranceResponse,
    feeSchedulesQuery.data
  );

  const { handleCancelHistoryChange, handleHistoryChange, historyFields } = useHistoryFields(
    procedureHistoryQuery.data
  );
  const {
    handleAddLimitation,
    handleCancelLimitationChange,
    handleLimitationChange,
    handleLimitationDelete,
    handePreferredLimitationItemChange,
    handleSearch,
    handleUpdateAllLimitations,
    limitationItems,
    preferredLimitationItems,
    searchString: limitationSearchString,
  } = useLimitationsFields(groupedBenefitLimitationsQuery.data);

  const handleNoteChange = useCallback(
    (value: string) => {
      handlePlanFieldChange({ planNotes: value });
    },
    [handlePlanFieldChange]
  );

  const turnEditingOff = editing.off;

  const cancelBatchedChanges = useCallback(() => {
    if (procedureHistoryQuery.data) {
      handleCancelHistoryChange([...procedureHistoryQuery.data]);
    }

    if (groupedBenefitLimitationsQuery.data) {
      handleCancelLimitationChange(
        groupedBenefitLimitationsQuery.data.completeBenefitLimitations,
        groupedBenefitLimitationsQuery.data.shortcutLimitations ?? []
      );
    }

    if (insurancePlan) {
      handlePlanFieldChange(insurancePlan);
    }

    turnEditingOff();
  }, [
    procedureHistoryQuery.data,
    groupedBenefitLimitationsQuery.data,
    insurancePlan,
    turnEditingOff,
    handleCancelHistoryChange,
    handleCancelLimitationChange,
    handlePlanFieldChange,
  ]);

  const schema = getLimitationsSchema();
  const { result: validation, validate } = useValidation(limitationItems, schema);

  const [
    { mutateAsync: mutatePlan, isLoading: isSavingPlan },
    { mutateAsync: mutateLimitations, isLoading: isSavingLimitations },
    { mutateAsync: mutateProcedureHistory, isLoading: isSavingHistory },
  ] = useApiMutations([updateInsurancePlan, upsertBenefitLimitations, upsertProcedureHistory]);

  const isSaving = useMemo(
    () => isSavingHistory || isSavingLimitations || isSavingPlan,
    [isSavingHistory, isSavingLimitations, isSavingPlan]
  );

  const limitationNotesModal = useItemModal<{ index: number; limitation: BenefitLimitationFormItem }>(null);

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault();

      const sanitizedLimitations = limitationItems.filter((item) => {
        delete item["display"];

        return item.frequency
          ? item.frequency.durationQuantity && item.frequency.frequencyQuantity
          : Object.keys(item).length > 0;
      });

      if (planUuid) {
        handleUpdateAllLimitations(sanitizedLimitations);

        if (!validate().$isValid || insuranceId === "new") {
          return;
        }

        const params = {
          patientId,
          practiceId,
          insuranceId,
          insurancePlanUuid: planUuid,
        };

        try {
          const requests: Promise<unknown>[] = [
            mutateLimitations({
              ...params,
              insurancePlanUuid: planUuid,
              data: getLimitationsPutData(sanitizedLimitations),
            }),
            mutateProcedureHistory({
              patientId,
              practiceId,
              insuranceId,
              data: getProcedureHistoryPutData(historyFields),
            }),
            mutatePlan({
              patientId,
              practiceId,
              insuranceId,
              insurancePlanUuid: planUuid,
              data: getPlanUpdateSubmission(planFields, planFields.employerName ?? ""),
            }),
          ];

          await Promise.all(requests);
          editing.off();
        } catch (err) {
          if (isHttpResponseError(err) && isProcedureHistoryConflict(err.error)) {
            toast.error("Someone else just updated this insurance. Please try again");

            return;
          }

          handleError(err);
        }
      }
    },
    [
      limitationItems,
      planUuid,
      handleUpdateAllLimitations,
      validate,
      insuranceId,
      patientId,
      practiceId,
      mutateLimitations,
      mutateProcedureHistory,
      historyFields,
      mutatePlan,
      planFields,
      editing,
    ]
  );

  const insuranceOrPlanIsAutoVerified = useMemo(() => {
    return (
      isAutoVerified(patientInsurance?.eligibilityVerifiedStatus) ||
      isAutoVerified(insurancePlan?.benefitVerifiedStatus)
    );
  }, [insurancePlan?.benefitVerifiedStatus, patientInsurance?.eligibilityVerifiedStatus]);

  return insuranceId === "new" ? (
    <Navigate replace to={paths.patientInsuranceStep({ insuranceId, patientId, step: "info" })} />
  ) : (
    <DetailsModalPage
      actions={
        editing.isOn && (
          <InsurancePlanDetailsFooter
            formId={LIMITATIONS_FORM_ID}
            isLoading={isSaving}
            onBack={cancelBatchedChanges}
            secondaryButtonLabel="Cancel"
          />
        )
      }
      backUrl={backUrl}
      insuranceId={insuranceId}
      patientId={patientId}
      patientInsuranceQuery={patientInsuranceQuery}
      queries={[patientInsuranceQuery, procedureHistoryQuery]}
      selectedTab="limitations"
    >
      {insuranceFetched && insuranceError ? (
        <ErrorContent />
      ) : insuranceFetched && !insurancePlan ? (
        <PlanNotFound patientId={patientId} insuranceId={insuranceId} />
      ) : (
        <Form fieldLayout="labelIn" id={LIMITATIONS_FORM_ID} onSubmit={handleSubmit}>
          <LimitationsAndHistoryTables
            editing={editing.isOn}
            historyItems={historyFields}
            historyLoading={procedureHistoryQuery.isLoading}
            insuranceOrPlanIsAutoVerified={insuranceOrPlanIsAutoVerified}
            insurancePlanUuid={planUuid}
            limitationItems={limitationItems}
            limitationsLoading={groupedBenefitLimitationsQuery.isLoading}
            limitationSearchString={limitationSearchString}
            notes={planFields.planNotes ?? ""}
            numOfPatients={numOfPatients}
            onAddLimitationItem={handleAddLimitation}
            onClickEdit={editing.on}
            onHistoryItemChange={handleHistoryChange}
            onLimitationItemChange={handleLimitationChange}
            onLimitationItemDelete={handleLimitationDelete}
            onOpenLimitationNotesModal={limitationNotesModal.open}
            onNoteChange={handleNoteChange}
            onPreferredLimitationItemChange={handePreferredLimitationItemChange}
            onSearch={handleSearch}
            onUpdateAllLimitations={handleUpdateAllLimitations}
            preferredLimitationItems={preferredLimitationItems}
            validation={validation}
          />
          {limitationNotesModal.item && (
            <LimitationNotesModal
              isEditing={editing.isOn}
              notes={limitationNotesModal.item.limitation.notes ?? ""}
              onCancel={limitationNotesModal.close}
              onConfirm={(updatedNote: string) => {
                if (editing.isOn) {
                  handleLimitationChange(limitationNotesModal.item.index, {
                    ...limitationNotesModal.item.limitation,
                    notes: updatedNote,
                  });
                }

                limitationNotesModal.close();
              }}
            />
          )}
        </Form>
      )}
    </DetailsModalPage>
  );
};
