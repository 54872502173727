/* eslint-disable @typescript-eslint/no-empty-function */
import { createContext, useContext, useRef, FC, useMemo, useCallback, PropsWithChildren } from "react";
import { noop } from "@libs/utils/noop";

const MAX_ATTEMPTS = 10;

export enum FocusDirection {
  FIRST,
  LAST,
  NEXT,
  PREV,
}

type FocusManagerValue = {
  registerTabIndex: (tabIndex: number) => void;
  unregisterTabIndex: (tabIndex: number) => void;
  focus: (focusDirection: FocusDirection, startingTabIndex?: number) => HTMLInputElement | undefined;
  getFocus: (focusDirection: FocusDirection, startingTabIndex?: number) => HTMLInputElement | undefined;
};

// Note: This intentionally does not expose the currentTabIndex as that would resulting in every control
// listening on it to re-render which would majorly impact page performance.
const Context = createContext<FocusManagerValue>({
  /**
   * Register control tab index, so the focus manager knows what is available.
   */
  registerTabIndex: noop,
  /**
   * Unregister a control tab index, so the focus manager knows what is available.
   */
  unregisterTabIndex: noop,
  /**
   * Focuses the next input control based on the current tab index.
   */
  /**
   * Focuses based on the focus direction and optional current tab index.
   */
  focus: () => undefined,
  /**
   * Get's the next potential focus based on input. Does not actually focus it .
   */
  getFocus: () => undefined,
});

Context.displayName = "FocusManagerContext";

export const useFocusManager = () => useContext(Context);

const getQueryString = (tabIndex: number) => `input[tabindex='${tabIndex}']:not([disabled])`;

export const FocusManagerProvider: FC<PropsWithChildren> = ({ children }) => {
  const tabIndexRegistryRef = useRef<number[]>([]);
  const needsSortRef = useRef(true);

  // eslint-disable-next-line complexity
  const getFocus = useCallback((focusDirection: FocusDirection, startingTabIndex?: number) => {
    const tabIndexRegistry = tabIndexRegistryRef.current;

    if (tabIndexRegistry.length === 0) {
      return undefined;
    }

    if (needsSortRef.current) {
      tabIndexRegistry.sort((a, b) => a - b);
      needsSortRef.current = false;
    }

    if (focusDirection === FocusDirection.FIRST) {
      const tabIndex = tabIndexRegistry[0];

      return document.querySelector<HTMLInputElement>(getQueryString(tabIndex)) || undefined;
    } else if (focusDirection === FocusDirection.LAST) {
      const tabIndex = tabIndexRegistry.at(-1);

      if (tabIndex === undefined) {
        return undefined;
      }

      return document.querySelector<HTMLInputElement>(getQueryString(tabIndex)) || undefined;
    }

    startingTabIndex ??= Number(document.activeElement?.getAttribute("tabindex"));

    let indexToUse = 0;
    const directionAmount = focusDirection === FocusDirection.NEXT ? 1 : -1;

    indexToUse = tabIndexRegistry.indexOf(startingTabIndex);

    if (indexToUse === -1) {
      indexToUse = tabIndexRegistry[0];
    }

    indexToUse += directionAmount;

    // Since controls can be disabled, this will attempt finding the next up to the maximum attempts.
    for (let i = 0; i < MAX_ATTEMPTS; ++i) {
      if (indexToUse < 0) {
        indexToUse = tabIndexRegistry.length - 1;
      } else {
        indexToUse %= tabIndexRegistry.length;
      }

      const tabIndex = tabIndexRegistry[indexToUse];

      const control = document.querySelector<HTMLInputElement>(getQueryString(tabIndex));

      if (control) {
        return control;
      }

      indexToUse += directionAmount;
    }

    return undefined;
  }, []);

  const contextValue = useMemo(
    () => ({
      registerTabIndex: (tabIndex: number) => {
        tabIndexRegistryRef.current.push(tabIndex);
        needsSortRef.current = true;
      },
      unregisterTabIndex: (tabIndex: number) => {
        const tabIndexRegistry = tabIndexRegistryRef.current;
        const foundEntryIndex = tabIndexRegistry.indexOf(tabIndex);

        if (foundEntryIndex !== -1) {
          tabIndexRegistry.splice(foundEntryIndex, 1);
        }
      },
      focus: (focusDirection: FocusDirection, startingTabIndex?: number) => {
        const nextInput = getFocus(focusDirection, startingTabIndex);

        if (nextInput) {
          nextInput.focus();
          nextInput.select();
        }

        return nextInput;
      },
      getFocus,
    }),
    [getFocus]
  );

  return <Context.Provider value={contextValue}>{children}</Context.Provider>;
};
