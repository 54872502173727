import { useCallback } from "react";
import { UseBooleanResult } from "@libs/hooks/useBoolean";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as CopyIcon } from "@libs/assets/icons/copy.svg";
import { ReactComponent as UnlinkIcon } from "@libs/assets/icons/unlink.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";

interface Props {
  menu: UseBooleanResult;
  onDuplicatePlan: Func;
  onEditPlan: Func;
  onUnlinkPlan: Func;
}

const MENU_OPTIONS = createMenuOptions(
  {
    SvgIcon: EditIcon,
    label: "Edit Plan",
    value: "editPlan",
  },
  {
    SvgIcon: CopyIcon,
    label: "Duplicate Plan",
    value: "duplicatePlan",
  },
  {
    SvgIcon: UnlinkIcon,
    label: "Unlink Plan",
    value: "unlinkPlan",
  }
);

export const EditPlanButton: React.FC<Props> = ({ menu, onDuplicatePlan, onEditPlan, onUnlinkPlan }) => {
  const handleOptionClick = useCallback(
    (option: ListItem<typeof MENU_OPTIONS>) => {
      switch (option.value) {
        case "editPlan": {
          onEditPlan();
          break;
        }
        case "duplicatePlan": {
          onDuplicatePlan();
          break;
        }
        case "unlinkPlan": {
          onUnlinkPlan();
          break;
        }
        // no default
      }

      menu.off();
    },
    [menu, onDuplicatePlan, onEditPlan, onUnlinkPlan]
  );

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestClose={menu.off}
      onRequestOpen={menu.on}
      menuContent={<MenuOptions options={MENU_OPTIONS} onOptionClick={handleOptionClick} />}
      placement="bottom-end"
    >
      {(props) => (
        <button
          {...props}
          className={`
            flex
            items-center
            gap-x-1
            text-primaryTheme
            font-sansSemiBold
          `}
          type="button"
        >
          <Icon SvgIcon={EditIcon} size="sm" theme="primary" />
          Edit Plan
        </button>
      )}
    </ButtonMenu>
  );
};
