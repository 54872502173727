import { FC } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { AppointmentCardDesign } from "components/Settings/Scheduling/Sections/AppointmentCardDesign/AppointmentCardDesign";

export const AppointmentCardDesignRoute: FC = () => {
  const isEditing = useBoolean(false);

  return (
    <SettingsPanel
      includePadding={false}
      isEditing={isEditing.isOn}
      onToggleEdit={isEditing.toggle}
      title="Appointment Card Design"
    >
      <AppointmentCardDesign editSection={isEditing.isOn} onClose={isEditing.off} />
    </SettingsPanel>
  );
};
