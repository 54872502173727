import React from "react";
import { clampImageDimensions } from "@libs/utils/image";
import { CameraVideoPreview } from "components/ImageCapturing/CameraVideoPreview";
import { useCameraStream } from "components/ImageCapturing/useCameraStream";
import { useCaptureCameraImage } from "components/ImageCapturing/useCaptureCameraImage";
import { handleError } from "utils/handleError";
import { useMediaPermissions } from "utils/useMediaPermission";
import { CalibrationCaptureHeader } from "components/ImageCapturing/Calibration/CalibrationCaptureHeader";
import { CameraDeviceWithSettings } from "components/PatientProfile/Imaging/hooks/useImagingDevices";

const videoPermission = { video: true };

type Props = {
  device: CameraDeviceWithSettings;
  onImageCaptured: (image: { url: string; w: number; h: number; cleanup?: Func }) => void;
  from: string;
};

export const CalibrationCameraCapture: React.FC<Props> = ({ onImageCaptured, device, from }) => {
  useMediaPermissions(videoPermission);

  const { stream, cancelStream, requestStream, isRequestingStream } = useCameraStream(device.deviceId);
  const captureCameraImage = useCaptureCameraImage(stream);

  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const [isTakingPhoto, setIsTakingPhoto] = React.useState(false);

  React.useEffect(() => {
    requestStream();
  }, [requestStream]);

  const capture = React.useCallback(async () => {
    setIsTakingPhoto(true);

    const blob = await captureCameraImage.capture();

    setIsTakingPhoto(false);

    if (blob) {
      const dimensions1440p = { w: 2560, h: 1440 };
      const resized = await clampImageDimensions(blob, dimensions1440p);

      if (resized.blob) {
        try {
          onImageCaptured({
            url: resized.url,
            w: resized.dimensions.width,
            h: resized.dimensions.height,
            cleanup: resized.cleanup,
          });
        } catch (e) {
          handleError(e);
        }
      } else {
        handleError(new Error("Error resizing dimensions"));
      }

      if (videoRef.current) {
        videoRef.current.srcObject = null;
      }

      cancelStream();
    }
  }, [cancelStream, captureCameraImage, onImageCaptured]);

  return (
    <>
      <CalibrationCaptureHeader
        device={device}
        from={from}
        onClickCapture={capture}
        captureDisabled={isRequestingStream || isTakingPhoto}
        isCapturing={isTakingPhoto}
      />
      <div className="flex justify-center">
        <CameraVideoPreview className="max-w-4xl" key={stream?.id} stream={stream} />
      </div>
    </>
  );
};
