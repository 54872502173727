import React, { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MedicalImageVO, MountVO, PatientSummaryVO } from "@libs/api/generated-api";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@libs/utils/queries";
import { downloadBlob } from "@libs/utils/dataUrl";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import { getExportedDocumentTag } from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { HeaderRight } from "components/PatientProfile/Imaging/MountRoute/MountHeader/HeaderRight";
import { HeaderLeft } from "components/PatientProfile/Imaging/MountRoute/MountHeader/HeaderLeft";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";
import { MountDetailsQuery } from "utils/routing/patient";
import { paths } from "utils/routing/paths";
import { useAppTheme } from "hooks/useAppTheme";
import { ImageSidebarNavigation } from "components/PatientProfile/Imaging/ImageSidebarNavigation";
import { DarkRoomHeader } from "components/PatientProfile/Imaging/DarkRoomHeader";
import { useImageSidebarNavigation } from "components/PatientProfile/Imaging/hooks/useImageSidebarNavigation";
import { useMountContext } from "components/PatientProfile/Imaging/MountRoute/MountContext";

export const MountImageSidebarRoute: React.FC<{
  imageMount: MountVO;
  patient: PatientSummaryVO;
  mountsInfiniteQuery: UseInfiniteApiQueryResult<MountVO[]>;
  onEditImage: (params: Pick<MedicalImageVO, "id">) => void;
  onClickTeeth: (image: MedicalImageVO) => void;
  isOnboardedWithPearl: boolean;
  // eslint-disable-next-line max-statements, complexity
}> = ({ imageMount, patient, onEditImage, mountsInfiniteQuery, onClickTeeth, isOnboardedWithPearl }) => {
  const [exportType, setExportType] = useState<"image" | "print" | null>(null);
  const navigate = useNavigate();
  const params = usePathParams("mountDetails");
  const { patientId } = params;
  const { query, updateQuery } = useQueryParams("mountDetails");

  const { devices, imageEditQueue, handleUpdateMountMetadata } = useMountContext();
  const images = useMemo(() => {
    return imageMount.images?.filter((image) => query.showArchived || !image.isArchived) ?? [];
  }, [imageMount.images, query.showArchived]);

  const { format: mountFormat, hideMeta, showArchived } = query;

  const sideBarProps = useImageSidebarNavigation({
    images,
    format: mountFormat,
  });
  // Only track the first time whether or not this was a new mount.
  const isNewMount = useRef(imageMount.images?.length === 0);

  const queryClient = useQueryClient();

  const handleQueryParamsChanged = React.useCallback(
    (queryParams: Partial<MountDetailsQuery>) => {
      updateQuery("replaceIn", queryParams);
    },
    [updateQuery]
  );

  useAppTheme("dark");

  const isShowingMetadata = !hideMeta;
  const { handleImageUpdate, revertImage } = imageEditQueue;

  const sharedHeaderProps = {
    imageMount,
    patientId,
    mountFormat,
  };

  return (
    <DndProvider backend={HTML5Backend}>
      <div className="flex flex-col min-h-0 h-full dark">
        <DarkRoomHeader className="min-w-[72rem] print:hidden">
          <HeaderLeft {...sharedHeaderProps} mountsInfiniteQuery={mountsInfiniteQuery} patient={patient} />

          <HeaderRight
            {...sharedHeaderProps}
            showArchived={showArchived}
            onToggleShowArchived={() => handleQueryParamsChanged({ showArchived: !showArchived })}
            onClickDone={() => {
              if (isNewMount.current) {
                // Clear out the mount list so it will show the new mount on exit.
                queryClient.invalidateQueries([getQueryKey("practices", "getMountList"), { patientId }]);
              }

              navigate(paths.patientTab({ tab: "imaging", patientId }));
            }}
            devices={devices}
            onChangeImage={handleUpdateMountMetadata}
            onUpdateParams={handleQueryParamsChanged}
            isShowingMetadata={isShowingMetadata}
          />
        </DarkRoomHeader>
        <div className="flex flex-1 min-h-0 flex-row">
          <ImageSidebarNavigation
            {...sideBarProps}
            isShowingMetadata={isShowingMetadata}
            patientId={patientId}
            devices={devices}
            onClickTeeth={onClickTeeth}
            isOnboardedWithPearl={isOnboardedWithPearl}
            onEditImage={onEditImage}
            onCaptureComplete={
              exportType === "image"
                ? (blob) => {
                    setExportType(null);
                    downloadBlob(blob, getExportedDocumentTag(imageMount, patient));
                  }
                : undefined
            }
            onRevertImage={revertImage}
            images={images}
            onImageUpdate={handleImageUpdate}
            mountId={imageMount.id}
            format={mountFormat}
          />
        </div>
      </div>
    </DndProvider>
  );
};
