import { createContext, useContext, useState, useEffect, PropsWithChildren } from "react";
import { PerioChartExamEntryVO } from "@libs/api/generated-api";
import { getControlInfo } from "./perioChartUtils";
import { PerioChartExamEntryField, PerioChartToothSurfaceType } from "./PerioTypes";

type SequenceType = PerioChartExamEntryVO["perioSequenceType"];
export interface PerioChartFocusValue {
  toothNum?: number;
  sequence?: SequenceType;
  surface?: PerioChartToothSurfaceType;
  tabIndex?: number;
  fieldName?: PerioChartExamEntryField;
}

const Context = createContext<PerioChartFocusValue>({});

Context.displayName = "PerioChartActiveToothContext";

export const usePerioChartActiveTooth = () => useContext(Context);

export const PerioChartActiveToothProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [controlInfo, setControlInfo] = useState<PerioChartFocusValue>({});

  useEffect(() => {
    const focusHandler = (e: FocusEvent) => {
      const element = e.target as HTMLElement;

      const newControlInfo = getControlInfo(element);

      if (newControlInfo) {
        setControlInfo(newControlInfo);
      }
    };

    document.addEventListener("focus", focusHandler, true);

    return () => {
      document.removeEventListener("focus", focusHandler, true);
    };
  }, []);

  return <Context.Provider value={controlInfo}>{children}</Context.Provider>;
};
