import { FC } from "react";

import { LabCaseTypeVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { PAGE_SIZE } from "@libs/utils/constants";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { Panel } from "@libs/components/UI/Panel";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { LabCaseTypeFlyover } from "components/LabCases/LabCaseTypeFlyover";
import { getLabCaseTypes } from "api/lab/queries";
import { LabCaseTypesTable } from "components/Settings/LabCases/LabCaseTypesTable";
import { useItemModal } from "hooks/useItemModal";
import { deleteLabCaseType } from "api/lab/mutations";
import { handleError } from "utils/handleError";
import { PanelTitle } from "components/Settings/LabCases/PanelTitle";

export const LabCaseTypesSettingsRoute: FC = () => {
  const flyover = useItemModal<LabCaseTypeVO["uuid"] | undefined>(null);
  const { practiceId } = useAccount();
  const [labCaseTypesQuery] = useApiQueries([
    getLabCaseTypes({ args: { practiceId, pageNumber: 1, pageSize: PAGE_SIZE } }),
  ]);
  const [labCaseTypeMutation] = useApiMutations([deleteLabCaseType]);
  const confirmDelete = useItemModal<LabCaseTypeVO["uuid"]>(null);

  const handleDelete = (labCaseTypeUuid: LabCaseTypeVO["uuid"]) => {
    labCaseTypeMutation.mutate(
      { practiceId, labCaseTypeUuid },
      { onSuccess: confirmDelete.close, onError: handleError }
    );
  };

  return (
    <Panel
      className="h-full"
      contentClassName="overflow-y-auto"
      includePadding={false}
      title={<PanelTitle onAdd={() => flyover.open(undefined)}>Lab Case Types</PanelTitle>}
    >
      <div className="text-sm max-w-3xl py-4 px-6">
        Assign procedures to a Lab Case Type that typically require a Lab Case.
      </div>
      <QueryResult queries={[labCaseTypesQuery]}>
        <LabCaseTypesTable
          labCaseTypes={labCaseTypesQuery.data ?? []}
          onEdit={flyover.open}
          onDelete={confirmDelete.open}
        />
      </QueryResult>
      {flyover.isOpen && (
        <LabCaseTypeFlyover labCaseTypeUuid={flyover.item} onClose={flyover.close} onSave={flyover.close} />
      )}
      {confirmDelete.isOpen && (
        <ConfirmationModal
          primaryText="Are you sure you want to delete this Lab Case Type?"
          secondaryText="It won’t be available for any new lab orders but will remain attached to any existing orders."
          isConfirming={labCaseTypeMutation.isLoading}
          onCancel={confirmDelete.close}
          onConfirm={() => {
            handleDelete(confirmDelete.item);
          }}
        />
      )}
    </Panel>
  );
};
