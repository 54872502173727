import { FC } from "react";
import { ReactSortable } from "react-sortablejs";
import { isNullish } from "@libs/utils/types";
import {
  CurrentElement,
  DragItem,
  SortableFormSection,
  SortableFormSectionElement,
} from "components/Settings/Forms/types";
import {
  DRAG_FORM_ELEMENT_HANDLE,
  FormStructureElement,
  FormStructureElementWrapper,
} from "components/Settings/Forms/FormStructureElement";

import { NESTED_SORT_CONTAINER_CLASSNAME } from "components/Settings/Forms/utils";
import { CollapsibleSection } from "components/UI/CollapsibleSection";
import { FormSectionHeader } from "components/Settings/Forms/FormSectionHeader";
import { ElementButtonInsert } from "components/Settings/Forms/ElementButtonInsert";

interface Props {
  element: SortableFormSection;
  currentElement: CurrentElement | undefined;
  placeholderElement: CurrentElement | undefined;
  isDragging: boolean;
  dragItem: DragItem | undefined;
  index: number;
  isSelected: boolean;
  isOpen: boolean;
  onToggleOpen: (secitonId: string) => void;
  onEditElement: (element?: CurrentElement) => void;
  onDelete: (id: string) => void;
  onSorted: (id: string, elements: SortableFormSectionElement[]) => void;
  onUpdateDragItem: (dragItem: DragItem | undefined) => void;
}

export const FormSection: FC<Props> = ({
  element,
  currentElement,
  placeholderElement,
  isDragging,
  dragItem,
  isSelected,
  isOpen,
  index,
  onToggleOpen,
  onEditElement,
  onDelete,
  onSorted,
  onUpdateDragItem,
}) => {
  return (
    <FormStructureElementWrapper isDraggingElement={isDragging} isSelected={isSelected} className="px-4">
      <FormSectionHeader
        onDelete={() => onDelete(element.uuid)}
        onClick={() => onEditElement({ flow: "EDIT", element })}
        isAddingElement={currentElement?.flow === "ADD"}
        isDraggingElement={isDragging}
        isDragging={Boolean(dragItem)}
        onToggleOpen={() => {
          onToggleOpen(element.uuid);
          onEditElement({ flow: "EDIT", element });
        }}
        isOpen={isOpen}
        title={element.title}
        conditions={element.conditions}
      />
      <CollapsibleSection isOpen={isOpen}>
        <div className="py-1">
          <ElementButtonInsert
            isDragging={Boolean(dragItem)}
            placeholderElement={placeholderElement}
            currentElement={currentElement}
            index={index}
            sectionIndex={0}
            onEditElement={onEditElement}
          />

          <ReactSortable
            group="nested"
            disabled={currentElement?.flow === "ADD"}
            animation={150}
            invertSwap
            className={NESTED_SORT_CONTAINER_CLASSNAME}
            list={element.content}
            handle={`.${DRAG_FORM_ELEMENT_HANDLE}`}
            setList={(elements) => onSorted(element.uuid, elements)}
            onEnd={() => {
              onUpdateDragItem(undefined);
            }}
            onStart={(e) =>
              onUpdateDragItem(
                isNullish(e.oldIndex)
                  ? undefined
                  : {
                      item: element.content[e.oldIndex],
                    }
              )
            }
          >
            {element.content.map((sectionElement, sectionIndex) => (
              <div key={sectionElement.uuid}>
                <FormStructureElement
                  isAddingElement={currentElement?.flow === "ADD"}
                  isDraggingElement={Boolean(
                    dragItem &&
                      (dragItem.item.uuid === sectionElement.uuid || dragItem.item.uuid === element.uuid)
                  )}
                  isDragging={Boolean(dragItem)}
                  onDelete={() => onDelete(sectionElement.uuid)}
                  isSelected={currentElement?.element?.uuid === sectionElement.uuid}
                  element={sectionElement}
                  onClick={() => onEditElement({ flow: "EDIT", element: sectionElement })}
                >
                  {sectionElement.title}
                </FormStructureElement>
                <ElementButtonInsert
                  isDragging={Boolean(dragItem)}
                  currentElement={currentElement}
                  placeholderElement={placeholderElement}
                  index={index}
                  sectionIndex={sectionIndex + 1}
                  onEditElement={onEditElement}
                />
              </div>
            ))}
          </ReactSortable>
        </div>
      </CollapsibleSection>
    </FormStructureElementWrapper>
  );
};
