import { centsToDollars } from "@libs/utils/currency";

export const isDifferentValue = (
  dollarStringValue: string | undefined,
  centNumericValue: number | undefined
) => {
  const dollarValue = Number(dollarStringValue ?? 0);
  const centToDollarValue = centsToDollars(centNumericValue ?? 0);

  return dollarValue !== centToDollarValue;
};
