import { useCallback, useState } from "react";
import {
  AppointmentRequest,
  AppointmentVO,
  ErrorResponse,
  HttpResponse,
  SuccessResponseAppointmentVO,
} from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ApiErrorResponse } from "@libs/@types/api";
import { paths } from "utils/routing/paths";
import { useItemModal } from "hooks/useItemModal";
import { handleError } from "utils/handleError";

export const useAppointmentRoute = () => {
  const [savedAppointment, setSavedAppointment] = useState<AppointmentVO | null>(null);
  const formValid = useBoolean(false);
  const formDirty = useBoolean(false);
  const benefitLimitationModal = useItemModal<{ errorMessage: string; data: AppointmentRequest }>(null);
  const openBenefitLimitationModal = benefitLimitationModal.open;
  const handleSaveError = useCallback(
    (data: AppointmentRequest) => (error: ApiErrorResponse) => {
      const firstError = error.error.errors?.[0];

      if (firstError?.errorCode === "BENEFIT_LIMITATION" && firstError.message) {
        openBenefitLimitationModal({
          errorMessage: firstError.message,
          data: {
            ...data,
            ignoreFeeCalcError: true,
          },
        });
      } else {
        handleError(error);
      }
    },
    [openBenefitLimitationModal]
  );
  const handleSaveSuccess = useCallback(
    (response: HttpResponse<SuccessResponseAppointmentVO, ErrorResponse>) => {
      setSavedAppointment(response.data.data);
    },
    []
  );

  return {
    formValid,
    formDirty,
    savedAppointment,
    benefitLimitationModal,
    appointmentUrl: savedAppointment
      ? paths.schedule({
          patientId: savedAppointment.patient.id,
          appointmentId: savedAppointment.id,
          date: savedAppointment.date,
        })
      : "",
    handleSaveError,
    handleSaveSuccess,
  };
};
