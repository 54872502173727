import { FC, useCallback } from "react";
import { DentalProcedureVO, ProviderVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Modal } from "@libs/components/UI/Modal";
import { getDentalProceduresQuery, getPatientChartV2 } from "api/charting/queries";
import { AddPatientProceduresFormWrapper } from "components/Charting/AddPatientProceduresFormWrapper";
import { DraftPatientProcedureRequest } from "components/Charting/draftPatientProcedure";

interface Props {
  patientId: number;
  providers: ProviderVO[];
  dentalProcedure: DentalProcedureVO;
  draftPatientProcedure: DraftPatientProcedureRequest;
  onDone: (
    draftPatientProcedure: DraftPatientProcedureRequest,
    draftDentalProcedure: DentalProcedureVO
  ) => void;
  onRequestClose: Func;
}

const validateOptions = {
  provider: false,
};

export const AddAppointmentProceduresModal: FC<Props> = ({
  onRequestClose,
  patientId,
  providers,
  dentalProcedure,
  draftPatientProcedure,
  onDone,
}) => {
  const { practiceId } = useAccount();
  const [allDentalProceduresQuery, chartV2Query] = useApiQueries([
    getDentalProceduresQuery({ args: { practiceId } }),
    getPatientChartV2({
      args: { patientId, practiceId },
    }),
  ]);

  const onSubmit = useCallback(
    (draftProcedures: DraftPatientProcedureRequest[], draftDentalProcedures: DentalProcedureVO[]) => {
      onDone(draftProcedures[0], draftDentalProcedures[0]);
    },
    [onDone]
  );

  return (
    <Modal title="Edit Procedure" onClose={onRequestClose}>
      <QueryResult queries={[chartV2Query, allDentalProceduresQuery]}>
        {chartV2Query.data && allDentalProceduresQuery.data ? (
          <AddPatientProceduresFormWrapper
            onSubmit={onSubmit}
            onRequestClose={onRequestClose}
            isSaving={false}
            providers={providers}
            teeth={chartV2Query.data}
            editStatus={false}
            allDentalProcedures={allDentalProceduresQuery.data}
            dentalProcedures={[dentalProcedure]}
            validateOptions={validateOptions}
            draftPatientProcedures={[draftPatientProcedure]}
          />
        ) : null}
      </QueryResult>
    </Modal>
  );
};
