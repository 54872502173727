import { PatientProcedureVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { PreAuthInfo } from "components/Charting/PreAuthLabel";

interface Props {
  patientProcedures: PatientProcedureVO[];
  selectedProcedures: Record<number, PatientProcedureVO | undefined>;
  onToggleRow: (p: PatientProcedureVO) => void;
}

const EMPTY_CELL = "-";

const cxStyles = {
  grid: "grid grid-cols-[40px_60px_120px_auto_140px]",
  cell: "group-hover:bg-slate-100 text-left block",
  cellPadding: "p-2",
};

export const AddPatientProceduresHeaderRow = () => (
  <div className={cx("flex-none text-xs font-sansSemiBold", cxStyles.grid)}>
    <div className={cxStyles.cellPadding} />
    <div className={cxStyles.cellPadding}>Code</div>
    <div className={cxStyles.cellPadding}>Tooth - Surface</div>
    <div className={cxStyles.cellPadding}>Procedure Description</div>
    <div className={cxStyles.cellPadding}>Insurance Status</div>
  </div>
);

export const AddPatientProceduresTable: React.FC<Props> = ({
  patientProcedures,
  selectedProcedures,
  onToggleRow,
}) => {
  return (
    <div className={cxStyles.grid}>
      {patientProcedures.map((patientProcedure) => (
        <div
          key={patientProcedure.id}
          className={cx(
            "contents text-xs group",
            Boolean(selectedProcedures[patientProcedure.id]) && "*:bg-actionLight"
          )}
        >
          <div className={cx("flex justify-end cursor-pointer", cxStyles.cell, cxStyles.cellPadding)}>
            <Checkbox
              onChange={() => onToggleRow(patientProcedure)}
              value={patientProcedure.id}
              checked={Boolean(selectedProcedures[patientProcedure.id])}
            />
          </div>
          <button
            onClick={() => onToggleRow(patientProcedure)}
            type="button"
            className={cx(cxStyles.cell, cxStyles.cellPadding)}
          >
            {patientProcedure.cdtCode}
          </button>
          <button
            onClick={() => onToggleRow(patientProcedure)}
            type="button"
            className={cx(cxStyles.cell, cxStyles.cellPadding)}
          >
            {patientProcedure.procedureArea || EMPTY_CELL}
          </button>
          <button
            onClick={() => onToggleRow(patientProcedure)}
            type="button"
            className={cx("truncate", cxStyles.cell, cxStyles.cellPadding)}
          >
            {patientProcedure.description || EMPTY_CELL}
          </button>
          <button
            onClick={() => onToggleRow(patientProcedure)}
            type="button"
            className={cx(cxStyles.cell, cxStyles.cellPadding)}
          >
            <PreAuthInfo status={patientProcedure.preAuthStatus} />
          </button>
        </div>
      ))}
    </div>
  );
};
