import { FC } from "react";

import { DosespotEntityVO } from "@libs/api/generated-api";
import { Button } from "@libs/components/UI/Button";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as PlusCircleIcon } from "@libs/assets/icons/plus-circle.svg";

import { TableGrid, HeaderCell, Row, TextCell } from "@libs/components/UI/GridTableComponents";
import { PrescriberRow } from "components/Erx/PrescriberRow";

const tableHeaders = [
  { id: "employee", label: "Employee", width: "minmax(max-content,1fr)" },
  { id: "role", label: "Role", width: "1fr" },
  { id: "cost", label: "Cost", width: "1fr" },
  { id: "status", label: "Status", width: "1fr" },
];

interface Props {
  prescribers: DosespotEntityVO[];
  onAddPrescriber: Func;
}

export const PrescribersTable: FC<Props> = ({ prescribers, onAddPrescriber }) => {
  return (
    <div className="flex flex-col gap-y-6">
      <h3 className="font-sansSemiBold text-sm text-black">Prescribers</h3>

      <TableGrid
        className="border border-slate-200 rounded max-h-full overflow-y-auto"
        columnWidths={tableHeaders.map(({ width }) => width)}
      >
        {tableHeaders.map(({ id, label }) => (
          <HeaderCell key={id} borderColor="border-slate-200" size="short" sticky={false} rounded>
            {label}
          </HeaderCell>
        ))}

        {prescribers.map((prescriber, index) => (
          <PrescriberRow key={`${prescriber.name}_${index}`} prescriber={prescriber} isFirst={index === 0} />
        ))}

        <Row highlightOnHover={false}>
          <TextCell className="col-span-full" border={false}>
            <Button className="flex items-center gap-x-2 text-xs" onClick={onAddPrescriber} theme="link">
              <Icon SvgIcon={PlusCircleIcon} />
              Provider
            </Button>
          </TextCell>
        </Row>
      </TableGrid>
    </div>
  );
};
