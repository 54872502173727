import { FC, useMemo } from "react";
import { cx } from "@libs/utils/cx";
import { OptionInput } from "@libs/components/UI/OptionInput";
import { classVSurfaceRelations, SurfaceSelection } from "components/Charting/toothSurfaces";

const cxButtonStates = {
  selected: "bg-actionLight border-primaryTheme",
  unselected: "bg-white border-slate-200",
};

const cxStyles = {
  container: ({
    checked,
    disabled,
    isWedge,
    isInnerWedge,
  }: {
    checked?: boolean;
    disabled?: boolean;
    isWedge: boolean;
    isInnerWedge: boolean;
  }) =>
    cx(
      "border-[0.5px]",
      isWedge
        ? isInnerWedge
          ? "w-1/2 h-1/2 absolute z-[1] -rotate-45"
          : "w-3/4 h-3/4 absolute z-0 -rotate-45"
        : `
      w-1/2
      h-1/2
      absolute
      z-10
      rounded-full
      top-1/2
      left-1/2
      -translate-x-1/2
      -translate-y-1/2
    `,
      !disabled && "cursor-pointer hover:border-slate-900 focus-within:border-slate-900",
      checked ? cxButtonStates.selected : cxButtonStates.unselected
    ),
  content: ({
    disabled,
    checked,
    surfaceKey,
  }: {
    disabled?: boolean;
    checked?: boolean;
    surfaceKey: keyof SurfaceSelection;
  }) =>
    cx(
      "text-xs absolute -translate-x-1/2 -translate-y-1/2",
      surfaceKey !== "O" && "rotate-45", // rotate only when it's a wedge
      disabled && !checked ? "text-slate-200" : "text-slate-900",
      surfaceKey === "LV"
        ? "top-[58%] left-[42%]" // must add up to 100% to be horizontally centered
        : surfaceKey === "BV"
          ? "top-[42%] left-[58%]" // top/left values of LV (above) should be swapped
          : "top-1/2 left-1/2"
    ),
};

const cxSurfaceStyles: Record<keyof SurfaceSelection, string> = {
  M: "rounded-tl-[100%] top-0 left-0 origin-bottom-right",
  L: "rounded-bl-[100%] bottom-0 left-0 origin-top-right",
  LV: "rounded-bl-[100%] -bottom-1/4 -left-1/4 origin-top-right",
  D: "rounded-br-[100%] bottom-0 right-0 origin-top-left",
  B: "rounded-tr-[100%] top-0 right-0 origin-bottom-left",
  BV: "rounded-tr-[100%] -top-1/4 -right-1/4 origin-bottom-left",
  O: "",
};

const surfaceLabel: Record<keyof SurfaceSelection, string> = {
  M: "M",
  D: "D",
  L: "L",
  O: "O/I",
  B: "B/F",
  BV: "V",
  LV: "V",
};

interface Props {
  selection: SurfaceSelection;
  disabled?: boolean;
  selectionType: "single" | "multi";
  className?: string;
  onUpdate: (newSelections: SurfaceSelection) => void;
}

export const SurfaceSelector: FC<Props> = ({ selection, disabled, selectionType, onUpdate, className }) => {
  const options = useMemo(() => {
    const surfaceKeys = Object.keys(selection) as (keyof SurfaceSelection)[];

    return surfaceKeys.map((surfaceKey) => {
      const checked = selection[surfaceKey];
      const isWedge = surfaceKey !== "O";
      const isInnerWedge = isWedge && surfaceKey.length === 1;

      return {
        value: surfaceKey,
        checked,
        contentClassName: cxStyles.content({ checked, disabled, surfaceKey }),
        className: cx(
          isWedge ? cxSurfaceStyles[surfaceKey] : "",
          cxStyles.container({ checked, disabled, isWedge, isInnerWedge })
        ),
        children: surfaceLabel[surfaceKey],
        onChange: () => {
          if (selectionType === "multi") {
            onUpdate({ ...selection, [surfaceKey]: !selection[surfaceKey] });
          } else {
            const copy = { ...selection };
            const classVRelation = classVSurfaceRelations[surfaceKey];

            for (const sk of surfaceKeys) {
              if (sk === surfaceKey) {
                copy[sk] = !copy[sk];
              } else if (sk === classVRelation) {
                // keep it as is
                continue;
              } else {
                copy[sk] = false;
              }
            }

            onUpdate(copy);
          }
        },
      };
    });
  }, [selection, selectionType, disabled, onUpdate]);

  return (
    <div className={cx("aspect-square relative min-w-20", className)}>
      {options.map((option) => (
        <OptionInput key={option.value} type="checkbox" {...option} />
      ))}
    </div>
  );
};
