import { HeaderData } from "components/Dashboard/Tables/types";

export const BY_PAYMENT_TYPE_TABLE_HEADERS: HeaderData[] = [
  { label: "Payment Method", width: "minmax(9rem,12rem)" },
  { label: "Patient", width: "1fr" },
  { label: "Date of Collection", width: "1fr" },
  { label: "Payer Type", width: "1fr" },
  { label: "Payment Details", width: "1fr" },
  { label: "Total Collected", width: "1fr", align: "right" },
];

export const BY_PATIENT_TABLE_HEADERS: HeaderData[] = [
  { label: "Patient", width: "1fr", sortField: "patientLastName" },
  { label: "Date of Collection", width: "1fr" },
  { label: "Payer Type", width: "1fr" },
  { label: "Payment Method", width: "1fr" },
  { label: "Payment Details", width: "1fr" },
  { label: "Payment Channel", width: "1fr" },
  { label: "Total Collected", width: "1fr", align: "right" },
];

export const BY_PROVIDER_TABLE_HEADERS: HeaderData[] = [
  { label: "Primary Provider", width: "1fr", sortField: "providerLastName" },
  { label: "Associated Provider", width: "1fr", sortField: "associatedProviderLastName" },
  { label: "Patient", width: "1fr", sortField: "patientLastName" },
  { label: "Date of Collection", width: "1fr" },
  { label: "Payer Type", width: "1fr" },
  { label: "Payment Method", width: "1fr" },
  { label: "Payment Details", width: "1fr" },
  { label: "Total Collected", width: "1fr", align: "right" },
];

export const COLLECTED_BY_ASSOCIATED_PROVIDER_HEADERS: HeaderData[] = [
  { label: "Associated Provider", width: "1fr" },
  { label: "Primary Provider", width: "1fr" },
  {
    label: "Patient Count",
    width: "1fr",
    align: "right",
  },
  {
    label: "Insurance Paid",
    width: "1fr",
    align: "right",
  },
  {
    label: "Patient Paid",
    width: "1fr",
    align: "right",
  },
  {
    label: "Total Collected",
    width: "1fr",
    align: "right",
  },
];
