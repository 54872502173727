import { FC, FormEvent, useCallback, useMemo, useState } from "react";
import { produce } from "immer";
import { DentalProcedureVO, PatientToothVO, ProviderVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { ModalForm, ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import {
  DraftPatientProcedureRequest,
  stripSelectionsWithDentalProcedure,
} from "components/Charting/draftPatientProcedure";
import { getPatientProceduresSchema } from "components/Charting/patientProcedureSchema";
import { AddPatientProcedureForm } from "components/Charting/AddPatientProcedureForm";

interface Props {
  onRequestClose: Func;
  onSubmit: (
    draftProcedures: DraftPatientProcedureRequest[],
    draftDentalProcedures: DentalProcedureVO[]
  ) => void;
  dentalProcedures: DentalProcedureVO[];
  teeth: PatientToothVO[];
  allDentalProcedures: DentalProcedureVO[];
  draftPatientProcedures: DraftPatientProcedureRequest[];
  providers: ProviderVO[];
  validateOptions?: {
    provider?: boolean;
  };
  editStatus?: boolean;
  isSaving: boolean;
}

interface SwitchInfo {
  index: number;
  dentalProcedure?: DentalProcedureVO;
  draft?: DraftPatientProcedureRequest;
}

export const AddPatientProceduresFormWrapper: FC<Props> = ({
  onSubmit,
  onRequestClose,
  dentalProcedures,
  allDentalProcedures,
  draftPatientProcedures: initialDraftPatientProcedures,
  validateOptions,
  providers,
  isSaving,
  teeth,
  editStatus,
}) => {
  const [draftPatientProcedures, setDraftPatientProcedures] = useState<DraftPatientProcedureRequest[]>(() => {
    return initialDraftPatientProcedures.map((dpp, index) => {
      const dentalProcedure = dentalProcedures[index];

      return stripSelectionsWithDentalProcedure(dpp, teeth, dentalProcedure);
    });
  });

  const [switchInfo, setSwitchInfo] = useState<Record<number, SwitchInfo | undefined>>({});

  const handleSwitchDraftDentalProcedure = useCallback(
    (info: SwitchInfo) => {
      if (!info.dentalProcedure || !info.draft) {
        setSwitchInfo((last) =>
          produce(last, (editable) => {
            delete editable[info.index];
          })
        );
      } else {
        const stripped = stripSelectionsWithDentalProcedure(info.draft, teeth, info.dentalProcedure);

        stripped.dentalProcedureId = info.dentalProcedure.id;

        setSwitchInfo((last) =>
          produce(last, (editable) => {
            editable[info.index] = {
              ...info,
              draft: stripped,
            };
          })
        );
      }
    },
    [teeth]
  );

  const { drafts, draftProcedures } = useMemo(() => {
    return {
      drafts: draftPatientProcedures.map((dpp, index) => {
        const switchIndex = switchInfo[index];

        if (switchIndex && switchIndex.draft) {
          return switchIndex.draft;
        }

        return dpp;
      }),
      draftProcedures: dentalProcedures.map((dp, index) => {
        const switchIndex = switchInfo[index];

        if (switchIndex && switchIndex.dentalProcedure) {
          return switchIndex.dentalProcedure;
        }

        return dp;
      }),
    };
  }, [draftPatientProcedures, switchInfo, dentalProcedures]);

  const isCollapsible = draftPatientProcedures.length > 1;

  const handleUpdateDraft = useCallback(
    (updates: Partial<DraftPatientProcedureRequest>, index: number) => {
      const switchIndex = switchInfo[index];

      if (switchIndex) {
        setSwitchInfo((last) =>
          produce(last, (editable) => {
            const item = editable[index];

            if (item?.draft) {
              item.draft = {
                ...item.draft,
                ...updates,
              };
            }
          })
        );
      } else {
        setDraftPatientProcedures((last) =>
          produce(last, (editable) => {
            editable[index] = {
              ...editable[index],
              ...updates,
            };
          })
        );
      }
    },
    [switchInfo]
  );

  const schema = useMemo(() => {
    return getPatientProceduresSchema(draftProcedures, drafts, teeth, validateOptions);
  }, [drafts, draftProcedures, teeth, validateOptions]);

  // the only reason the modal is open is to show validation errors
  // so begin validating on load
  const { validate, result } = useValidation(drafts, schema, { isValidating: true });

  const handleFormSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!validate().$isValid) {
        return;
      }

      onSubmit(drafts, draftProcedures);
    },
    [drafts, draftProcedures, validate, onSubmit]
  );

  return (
    <ModalForm fieldLayout="labelIn" onSubmit={handleFormSubmit}>
      <ModalContent>
        {drafts.map((dpp, index) => {
          const key = `${draftProcedures[index].id}-${index}`;

          return (
            <AddPatientProcedureForm
              key={key}
              dentalProcedure={draftProcedures[index]}
              allDentalProcedures={allDentalProcedures}
              teeth={teeth}
              editStatus={editStatus}
              draftPatientProcedure={dpp}
              validation={result.$items[index]}
              providers={providers}
              onUpdateDraft={(updates) => handleUpdateDraft(updates, index)}
              validateOptions={validateOptions}
              isCollapsible={isCollapsible}
              isSwitching={Boolean(switchInfo[index])}
              onUpdateSwitchDentalProcedure={(update) =>
                handleSwitchDraftDentalProcedure(update ? { ...update, index } : { index })
              }
            />
          );
        })}
      </ModalContent>
      <ModalFooter>
        <Button className="min-w-button" theme="secondary" onClick={onRequestClose}>
          Cancel
        </Button>
        <AsyncButton className="min-w-button" isLoading={isSaving} type="submit">
          Save
        </AsyncButton>
      </ModalFooter>
    </ModalForm>
  );
};
