import { DailyHuddleAppointmentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatCurrency } from "@libs/utils/currency";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { formatISODate, formatISOTimeAsAmPmShortHr } from "@libs/utils/date";
import { Glyph } from "@libs/components/UI/Glyph";
import { filterMap } from "@libs/utils/array";
import { Row } from "@libs/components/UI/GridTableComponents";
import { cxStyles } from "components/DailyHuddle/Print";
import { genderInitial } from "components/PatientProfile/PatientOverview/utils";
import { sortAppointmentTags } from "components/ScheduleAppointments/tags";

interface Props {
  entry: DailyHuddleAppointmentVO;
  excludedTagIds: number[];
}

// eslint-disable-next-line complexity
export const PrintRow: React.FC<Props> = ({ entry, excludedTagIds }) => {
  const excludedTagIdsSet = new Set(excludedTagIds);

  return (
    <Row>
      <div className={cx(cxStyles.cell, cxStyles.horizontalPadding, cxStyles.bottomBorder)}>
        <div>
          <span className="font-sansSemiBold">{formatISOTimeAsAmPmShortHr(entry.appointmentStartTime)},</span>{" "}
          {entry.appointmentDuration} mins, {entry.provider.shortDisplayName}, {entry.room.roomName}
        </div>
        <div>
          <span className="font-sansSemiBold">{entry.patient.fullDisplayName}</span>
          {`${entry.gender ? `, ${genderInitial[entry.gender]}` : ""}${
            entry.dob ? `, ${formatISODate(entry.dob)}` : ""
          }`}
        </div>
        <div className="font-sansSemiBold">{entry.appointmentState}</div>

        <div className="mt-0.5">
          {entry.clinicalNoteStatus ? (
            <span
              className={cx(
                "px-0.5 align-middle",
                entry.clinicalNoteStatus === "COMPLETED"
                  ? "text-green"
                  : entry.clinicalNoteStatus === "DRAFT"
                    ? "text-orange"
                    : "text-red"
              )}
            >
              <Glyph size="sm" family="ARCHY" inline>
                
              </Glyph>
            </span>
          ) : null}
          {filterMap(sortAppointmentTags(entry.tags), (tag) =>
            excludedTagIdsSet.has(tag.id) ? null : (
              <span key={tag.id} className="px-0.5 align-middle">
                <Glyph size="sm" family={tag.glyph.family} inline>
                  {tag.glyph.value}
                </Glyph>
              </span>
            )
          )}
        </div>
      </div>
      <div className={cx(cxStyles.cell, cxStyles.horizontalPadding, cxStyles.bottomBorder)}>
        <div>Balance: {formatCurrency(entry.balanceAmount)}</div>
      </div>
      <div className={cx(cxStyles.cell, cxStyles.horizontalPadding, cxStyles.bottomBorder)}>
        {entry.dailyHuddleInsuranceInfo && (
          <>
            <div>
              {entry.dailyHuddleInsuranceInfo.insuranceType === "PRIMARY_SUBSCRIBER"
                ? "Primary"
                : entry.dailyHuddleInsuranceInfo.insuranceType
                  ? sentenceCaseConstant(entry.dailyHuddleInsuranceInfo.insuranceType)
                  : ""}
            </div>
            <div>
              {entry.dailyHuddleInsuranceInfo.eligibilityVerifiedStatus
                ? "Eligibility Verified"
                : "Eligibility Unverified"}
            </div>
            <div>
              {entry.dailyHuddleInsuranceInfo.benefitVerifiedStatus
                ? "Benefits Verified"
                : "Benefits Unverified"}
            </div>
          </>
        )}
      </div>
    </Row>
  );
};
