import React from "react";
import { ImageLayoutItem } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";
import { getImageTransformStyles } from "components/PatientProfile/Imaging/MountRoute/image-utils";

export const useSandboxBounds = (image: ImageLayoutItem, scale = 1) => {
  return React.useMemo(() => {
    const { sizeInverted } = getImageTransformStyles(image.transforms);
    const width = sizeInverted ? image.sandbox.h : image.sandbox.w;
    const height = sizeInverted ? image.sandbox.w : image.sandbox.h;

    return {
      width: width * scale,
      height: height * scale,
    };
  }, [image.sandbox.h, image.sandbox.w, image.transforms, scale]);
};
