import { FC } from "react";
import { ModalPage } from "components/UI/ModalPage";
import { LoadingOverlay } from "components/UI/LoadingOverlay";
import GustoLogo from "assets/images/logo-gusto.svg";

export const GustoEmbeddedFlow: FC<{
  isLoading: boolean;
  iframeUrl: string | undefined;
  closeLink: string;
}> = ({ isLoading, iframeUrl, closeLink }) => {
  return (
    <ModalPage
      title={
        <div className="flex items-baseline gap-x-6 text-greyDark">
          <h1 className="font-sansSemiBold">Payroll Settings</h1>
          <span className="font-sans text-xs">
            Powered by <img alt="Gusto Logo" className="h-5 inline" src={GustoLogo} />
          </span>
        </div>
      }
      closeLink={closeLink}
    >
      <LoadingOverlay isLoading={isLoading} loadingText="Loading Gusto Settings...">
        <div className="w-full h-full overflow-hidden">
          <iframe
            title="Gusto"
            src={iframeUrl}
            className={`
              origin-[0_0]
              scale-75
              w-[calc(100%/0.75)]
              h-[calc(100%/0.75)]
            `}
          />
        </div>
      </LoadingOverlay>
    </ModalPage>
  );
};
