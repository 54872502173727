import { useMemo } from "react";
import { AddressVO, InsuranceCarrierDetailsVO, InsuranceCarrierVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldAddressAutocomplete } from "@libs/components/UI/FormFieldAddressAutocomplete";
import { Switch } from "@libs/components/UI/Switch";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { Divider } from "components/UI/Divider";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";
import { InsuranceCarrierValidationResult } from "components/Settings/InsuranceCarriers/schema";
import { EMPTY_ADDRESS } from "components/Settings/InsuranceCarriers/InsuranceDetails";
import { useIsDirty } from "hooks/useIsDirty";
import { usePathParams } from "hooks/usePathParams";
import { useEnvContext } from "contexts/EnvContext";

interface Props {
  allCarriers: InsuranceCarrierVO[] | undefined;
  initialDetails: InsuranceCarrierDetailsVO | undefined;
  insuranceDetails: Partial<InsuranceCarrierDetailsVO>;
  // Users can only edit custom insurance carriers. For any Archy insurance
  // carriers, the only editable fields are the ones that are not yet filled in
  // b/c changes would apply to all practices. However, if our CS team needs to
  // edit an Archy carrier, we will allow them to do so without having to reach
  // out to eng to edit it in the DB. isArchyEmployee is flagged to emails that
  // end with `@archy.com`
  isArchyEmployee: boolean;
  onDirty: Func;
  onUpdate: (partial: Partial<Partial<InsuranceCarrierDetailsVO>>) => void;
  validation: InsuranceCarrierValidationResult | undefined;
}

const cxStyles = {
  sectionHeader: (disabled: boolean) =>
    cx("text-sm font-sansSemiBold", disabled ? "text-slate-500" : "text-slate-900"),
};

const disableField = (
  isArchyEmployee: boolean,
  isCreating: boolean,
  isGlobalCarrier: boolean,
  value: AddressVO | string | boolean | undefined
) => !isArchyEmployee && !isCreating && isGlobalCarrier && Boolean(value);

// eslint-disable-next-line complexity
export const InsuranceDetailsForm: React.FC<Props> = ({
  allCarriers,
  initialDetails,
  insuranceDetails,
  isArchyEmployee,
  onDirty,
  onUpdate,
  validation,
}) => {
  const { REACT_APP_GOOGLE_API_KEY } = useEnvContext();

  const { carrierId } = usePathParams("insuranceDetails");
  const isCreating = carrierId === "new";

  useIsDirty(insuranceDetails, { onDirty });

  const payerIdOptions = useMemo(() => {
    if (!allCarriers) {
      return [];
    }

    const carrierIds = new Set(allCarriers.map((c) => c.payerId).sort());

    return [...carrierIds].map((id) => ({
      value: id,
      label: id,
    }));
  }, [allCarriers]);

  const isGlobalCarrier = insuranceDetails.type === "GLOBAL";

  return (
    <div className="grid grid-cols-2 max-w-xl p-1 gap-6">
      <div className={cxStyles.sectionHeader(isGlobalCarrier && !isArchyEmployee)}>Carrier</div>
      <FormFieldInput
        className="col-span-full"
        disabled={isGlobalCarrier && !isArchyEmployee}
        error={validation?.name.$error}
        label="Name"
        onChange={(e) => onUpdate({ name: e.target.value })}
        required
        value={insuranceDetails.name}
      />
      <FormFieldSelect
        disabled={isGlobalCarrier && !isArchyEmployee}
        error={validation?.payerId.$error}
        label="Payer ID"
        onChange={(value) => onUpdate({ payerId: value?.value })}
        options={payerIdOptions}
        required
        value={insuranceDetails.payerId}
      />
      <div className="flex flex-col gap-y-1 text-xs">
        <div className="flex items-center gap-x-1 font-sansSemiBold">
          <span
            className={cx(
              disableField(
                isArchyEmployee,
                isCreating,
                isGlobalCarrier,
                initialDetails?.acceptsElectronicAttachments
              )
                ? "text-slate-500"
                : "text-slate-900"
            )}
          >
            Accepts Electronic Attachments
          </span>
          <Icon
            disabled={isGlobalCarrier && !isArchyEmployee}
            SvgIcon={InfoIcon}
            size="sm"
            tooltip={{
              content:
                "Select if this carrier allows digital attachments (such as xrays or images) to be submitted with a claim.",
            }}
          />
        </div>
        <div className="p-1 mt-1">
          <Switch
            checked={Boolean(insuranceDetails.acceptsElectronicAttachments)}
            disabled={isGlobalCarrier && !isArchyEmployee}
            name="Accepts Electronic Attachments"
            onChange={(e) => onUpdate({ acceptsElectronicAttachments: e.target.checked })}
          >
            <div>{insuranceDetails.acceptsElectronicAttachments ? "Yes" : "No"}</div>
          </Switch>
        </div>
      </div>
      <Divider className="col-span-full border-dashed" />
      <div
        className={cxStyles.sectionHeader(
          isGlobalCarrier &&
            Boolean(insuranceDetails.website && insuranceDetails.phone && insuranceDetails.fax)
        )}
      >
        Contact
      </div>
      <FormFieldInput
        className="col-span-full"
        disabled={disableField(isArchyEmployee, isCreating, isGlobalCarrier, initialDetails?.website)}
        label="Website"
        onChange={(e) => onUpdate({ website: e.target.value })}
        value={insuranceDetails.website}
      />
      <FormFieldPhoneInput
        disabled={disableField(isArchyEmployee, isCreating, isGlobalCarrier, initialDetails?.phone)}
        error={validation?.phone.$error}
        label="Phone"
        onValueChange={(value) => onUpdate({ phone: value })}
        value={insuranceDetails.phone}
      />
      <FormFieldPhoneInput
        disabled={disableField(isArchyEmployee, isCreating, isGlobalCarrier, initialDetails?.fax)}
        error={validation?.fax.$error}
        label="Fax"
        onValueChange={(value) => onUpdate({ fax: value })}
        value={insuranceDetails.fax}
      />
      <Divider className="col-span-full border-dashed" />
      <div
        className={cxStyles.sectionHeader(
          disableField(isArchyEmployee, isCreating, isGlobalCarrier, initialDetails?.address)
        )}
      >
        Address
      </div>
      <FormFieldAddressAutocomplete
        className="col-span-full"
        apiKey={REACT_APP_GOOGLE_API_KEY}
        disabled={disableField(isArchyEmployee, isCreating, isGlobalCarrier, initialDetails?.address)}
        defaultValue={insuranceDetails.address?.address1}
        error={validation?.address.address1.$error}
        label="Address"
        onSelect={(addr) =>
          onUpdate({
            address: addr.raw
              ? {
                  address1: addr.address,
                  state: addr.state,
                  city: addr.locality,
                  zip: addr.zip,
                }
              : EMPTY_ADDRESS,
          })
        }
        required
      />
      <FormFieldInput
        className="col-span-full"
        disabled={disableField(isArchyEmployee, isCreating, isGlobalCarrier, initialDetails?.address)}
        label="Address 2"
        onChange={(e) =>
          onUpdate({
            address: {
              ...(insuranceDetails.address ?? EMPTY_ADDRESS),
              address2: e.target.value,
            },
          })
        }
        value={insuranceDetails.address?.address2}
      />
      <div className="col-span-full grid grid-cols-3 gap-6">
        <FormFieldInput
          disabled={disableField(isArchyEmployee, isCreating, isGlobalCarrier, initialDetails?.address)}
          error={validation?.address.city.$error}
          label="City"
          onChange={(e) =>
            onUpdate({
              address: {
                ...(insuranceDetails.address ?? EMPTY_ADDRESS),
                city: e.target.value,
              },
            })
          }
          required
          value={insuranceDetails.address?.city}
        />
        <FormFieldInput
          disabled={disableField(isArchyEmployee, isCreating, isGlobalCarrier, initialDetails?.address)}
          error={validation?.address.state.$error}
          label="State"
          onChange={(e) =>
            onUpdate({
              address: {
                ...(insuranceDetails.address ?? EMPTY_ADDRESS),
                state: e.target.value,
              },
            })
          }
          required
          value={insuranceDetails.address?.state}
        />
        <FormFieldInput
          disabled={disableField(isArchyEmployee, isCreating, isGlobalCarrier, initialDetails?.address)}
          error={validation?.address.zip.$error}
          label="Zip"
          onChange={(e) =>
            onUpdate({
              address: {
                ...(insuranceDetails.address ?? EMPTY_ADDRESS),
                zip: e.target.value,
              },
            })
          }
          required
          value={insuranceDetails.address?.zip}
        />
      </div>
    </div>
  );
};
