/* eslint-disable complexity */
import React, {
  ChangeEventHandler,
  InputHTMLAttributes,
  KeyboardEventHandler,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import { PerioChartExamEntryVO, PerioChartToothConfigVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { FocusDirection, useFocusManager } from "contexts/FocusManagerContext";
import { ChangeTrackedRecord } from "utils/changeTrackedRecord";
import {
  PerioChartControlCount,
  PerioChartExamEntryField,
  PerioChartSequenceType,
  PerioChartToothSurfaceType,
} from "./PerioTypes";
import { usePerioChart } from "./PerioChartContext";
import { getControlInfo, sequenceToThresholdSettings } from "./perioChartUtils";
import { PerioChartNumericInput, ThresholdCondition } from "./PerioChartNumericInput";
import { PerioChartCheckbox } from "./PerioChartCheckbox";
import { usePerioChartControlContext } from "./PerioChartControlContext";

const PLUS_TEN = 10;

const cxSequenceToControl: Record<
  PerioChartSequenceType,
  (params: { toothConfig: PerioChartToothConfigVO; count?: number }) => {
    component: React.ElementType<InputHTMLAttributes<HTMLInputElement>>;
    props?: Record<string, unknown>;
  }
> = {
  PROBING: () => {
    return { component: PerioChartNumericInput };
  },
  GINGMARGIN: () => {
    return { component: PerioChartNumericInput, props: { showPlusOnPositive: true } };
  },
  AUTOCAL: () => {
    return { component: PerioChartNumericInput };
  },
  MUCOGINGJUNC: () => {
    return { component: PerioChartNumericInput };
  },
  MOBILITY: () => {
    return { component: PerioChartNumericInput, props: { className: "col-start-2" } };
  },
  FURCATION: (params) => {
    return {
      component: PerioChartNumericInput,
      props: {
        // Furcation doesn't apply to implants
        className: cx(params.count === 1 && "col-start-2", params.toothConfig.implant && "invisible"),
      },
    };
  },
  PLAQUE: () => {
    return { component: PerioChartCheckbox, props: { className: "checked:bg-perioChart-plaque" } };
  },
  CALCULUS: () => {
    return { component: PerioChartCheckbox, props: { className: "checked:bg-perioChart-calculus" } };
  },
  BLEEDING: () => {
    return { component: PerioChartCheckbox, props: { className: "checked:bg-perioChart-bleeding" } };
  },
  SUPPURATION: () => {
    return { component: PerioChartCheckbox, props: { className: "checked:bg-perioChart-suppuration" } };
  },
};

export const PerioChartToothControlWrapper: React.FC<{
  controlCount?: PerioChartControlCount;
  toothConfig: PerioChartToothConfigVO;
  sequenceType: Exclude<PerioChartSequenceType, "AUTOCAL">;
  surface: PerioChartToothSurfaceType;
  field: PerioChartExamEntryField;
  tabIndex: number;
}> = ({ controlCount, toothConfig, sequenceType, surface, field, tabIndex }) => {
  const { toothNum } = toothConfig;
  const { settings, currentExam } = usePerioChart();
  const [examEntry, setExamEntry] = useState<ChangeTrackedRecord<PerioChartExamEntryVO> | undefined>();
  const [value, setValue] = useState<number | undefined>();

  const focusManager = useFocusManager();
  const { controlFeatures, setPlusTen, setGingMargPlus } = usePerioChartControlContext();

  useEffect(() => {
    // Register the controls tab indexes.
    focusManager.registerTabIndex(tabIndex);

    return () => {
      focusManager.unregisterTabIndex(tabIndex);
    };
  }, [focusManager, tabIndex]);

  useLayoutEffect(() => {
    // Keep the exam entry in sync with the exam record changes.
    const newExamEntry = currentExam?.getEntry(toothNum, sequenceType);

    setExamEntry(newExamEntry);

    if (!newExamEntry && currentExam) {
      // Listen for changes since hotkeys could create the sequence entry for this wrapper.
      const unsubscribe = currentExam.subscribeToRecordsCreated((record) => {
        if (record.toothNum === toothNum && record.perioSequenceType === sequenceType) {
          setExamEntry(record);
          unsubscribe();
        }
      });

      return unsubscribe;
    }

    return undefined;
  }, [currentExam, field, sequenceType, toothNum]);

  useLayoutEffect(() => {
    // Keep the exam entry value in sync with the record.
    setValue(examEntry?.[field]);

    return examEntry?.subscribe((changedField, newValue) => {
      if (field === changedField) {
        setValue(newValue as number);
      }
    });
  }, [examEntry, field, sequenceType]);

  const changeHandler: ChangeEventHandler<HTMLInputElement> = (e) => {
    let examEntryToUse = examEntry;

    if (!examEntryToUse) {
      // Records are lazily created because there are hundreds of them.
      examEntryToUse = currentExam?.getOrCreateEntry(toothNum, sequenceType);
      setExamEntry(examEntryToUse);
    }

    if (examEntryToUse) {
      let focusNext = false;
      let newValue: number | undefined;

      if (e.currentTarget.type === "checkbox") {
        newValue = e.currentTarget.checked ? 1 : undefined;
      } else {
        newValue = Number(e.currentTarget.value);

        if (Number.isNaN(newValue) || e.currentTarget.value === "") {
          newValue = undefined;
        } else {
          if (sequenceType === "GINGMARGIN") {
            // Gingival Margin is generally a negative value, except when Ging Marg + is active.
            newValue = controlFeatures.gingMargPlusActive ? newValue : -newValue;
          } else {
            newValue = controlFeatures.plusTenActive ? newValue + PLUS_TEN : newValue;
          }

          focusNext = true;
        }
      }

      examEntryToUse.setValue(field, newValue);
      setValue(newValue);

      if (focusNext) {
        const autoAdvanceStop = settings?.autoAdvanceStop ?? true;

        if (autoAdvanceStop) {
          const newFocus = focusManager.getFocus(FocusDirection.NEXT);
          const newControlInfo = getControlInfo(newFocus);

          if (newFocus && newControlInfo && newControlInfo.sequence === sequenceType) {
            newFocus.focus();
            newFocus.select();
          }
        } else {
          focusManager.focus(FocusDirection.NEXT);
        }
      }
    }
  };

  // eslint-disable-next-line max-statements
  const handleHotKey: KeyboardEventHandler = (e) => {
    // + will toggle plus ten
    if (e.key === "+") {
      setPlusTen(!controlFeatures.plusTenActive);
      e.preventDefault();

      return;
    }

    if (e.key === "Backspace") {
      focusManager.focus(FocusDirection.PREV);

      const activeElement = document.activeElement as HTMLElement | null;
      const newControlInfo = activeElement && getControlInfo(activeElement);

      if (newControlInfo && newControlInfo.toothNum && newControlInfo.sequence && newControlInfo.fieldName) {
        const entry = currentExam?.getEntry(newControlInfo.toothNum, newControlInfo.sequence);

        if (entry) {
          entry.setValue(newControlInfo.fieldName, undefined);
        }
      }

      e.preventDefault();

      return;
    }

    const key = e.key.toLowerCase();

    if (key === "g") {
      setGingMargPlus(!controlFeatures.gingMargPlusActive);
      e.preventDefault();

      return;
    }

    // p, b, c, s will toggle their associated checkboxes.
    let hotKeySequenceType: PerioChartExamEntryVO["perioSequenceType"] | undefined;

    switch (key) {
      case "p": {
        hotKeySequenceType = "PLAQUE";
        break;
      }
      case "b": {
        hotKeySequenceType = "BLEEDING";
        break;
      }
      case "c": {
        hotKeySequenceType = "CALCULUS";
        break;
      }
      case "s": {
        hotKeySequenceType = "SUPPURATION";
        break;
      }
      default: {
        break;
      }
    }

    if (hotKeySequenceType) {
      const entry = currentExam?.getOrCreateEntry(toothNum, hotKeySequenceType);

      if (entry) {
        entry[field] = entry[field] ? undefined : 1;
        e.preventDefault();
      }
    }
  };

  const thresholdProps: { valueThreshold?: number; thresholdCondition?: ThresholdCondition } = {};
  const thresholdProperty = sequenceToThresholdSettings[sequenceType];

  if (thresholdProperty && settings) {
    thresholdProps.valueThreshold = settings[thresholdProperty.setting];
    thresholdProps.thresholdCondition = thresholdProperty.condition;
  }

  const controlConfig = cxSequenceToControl[sequenceType]({
    toothConfig,
    count: controlCount,
  });
  const ControlType = controlConfig.component;
  const controlProps = controlConfig.props || {};

  return (
    <ControlType
      {...controlProps}
      tabIndex={tabIndex}
      {...thresholdProps}
      onChange={changeHandler}
      onKeyDown={handleHotKey}
      value={value}
      data-tooth-number={toothNum}
      data-sequence-type={sequenceType}
      data-surface={surface}
      data-field-name={field}
    />
  );
};
