import { createLinksContext } from "contexts/AppLinksContext";
import { paths } from "utils/routing/paths";

export const getDefaultPatientClaimsLinks = (patientId: number) => ({
  claims: paths.patientClaims({ patientId }),
  preAuths: paths.patientPreAuths({ patientId }),
});

const { useLinks, LinksProvider, AppHistoryProvider } = createLinksContext(
  "PatientClaims",
  getDefaultPatientClaimsLinks(0)
);

export const usePatientClaimsLinks = useLinks;
export const PatientClaimsLinksProvider = LinksProvider;
export const PatientClaimsAppHistoryProvider = AppHistoryProvider;
