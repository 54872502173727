import React from "react";
import { ReactComponent as CreditCard } from "@libs/assets/icons/credit-card-single.svg";
import { ReactComponent as Visa } from "@libs/assets/icons/visa.svg";
import { ReactComponent as Mastercard } from "@libs/assets/icons/master-card.svg";
import { ReactComponent as Amex } from "@libs/assets/icons/american-express.svg";
import { ReactComponent as Discover } from "@libs/assets/icons/discover.svg";

interface Props extends React.SVGProps<SVGSVGElement> {
  brand?: string;
}

export const CreditCardLogo: React.FC<Props> = ({ brand, ...rest }) => {
  const ccBrand = brand?.toLocaleUpperCase();

  return ccBrand === "VISA" ? (
    <Visa {...rest} />
  ) : ccBrand === "MASTERCARD" ? (
    <Mastercard {...rest} />
  ) : ccBrand === "AMERICAN_EXPRESS" || ccBrand === "AMEX" ? (
    <Amex {...rest} />
  ) : ccBrand === "DISCOVER" ? (
    <Discover {...rest} />
  ) : (
    <CreditCard {...rest} />
  );
};
