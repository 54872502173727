import React from "react";
import { AppointmentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatISODate, formatISOTimeRangeAsAmPm } from "@libs/utils/date";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as NoteIcon } from "@libs/assets/icons/note.svg";
import { ButtonCell, Cell, EMPTY_CELL, Row } from "@libs/components/UI/GridTableComponents";
import { AppointmentMenu, AppointmentMenuProps } from "components/ScheduleAppointments/AppointmentMenu";
import { AppointmentProviders } from "components/ScheduleAppointments/AppointmentDetailComponents";

const cxStyles = {
  cell: "flex items-center whitespace-nowrap min-h-10",
};

type ScheduleRequestRowProps = {
  appointment: AppointmentVO;
  isSelected: boolean;
  onAppointmentDeleted: AppointmentMenuProps["onAppointmentDeleted"];
  onEdit: (appointment: AppointmentVO) => void;
  onClick: (appointment: AppointmentVO) => void;
};

// eslint-disable-next-line complexity
export const ScheduleRequestRow: React.FC<ScheduleRequestRowProps> = ({
  appointment,
  isSelected,
  onAppointmentDeleted,
  onEdit,
  onClick,
}) => {
  const { duration, patient, date, startTime, endTime, categoryName, comments, room, dentist, provider } =
    appointment;

  const buttonCellProps = {
    onClick: () => onClick(appointment),
    onDoubleClick: () => onEdit(appointment),
    className: cxStyles.cell,
    verticalPadding: "slim" as const,
  };

  return (
    <Row highlightOnHover isSelected={isSelected}>
      <ButtonCell {...buttonCellProps}>
        <div className="whitespace-nowrap">
          {`${formatISODate(date)}, ${formatISOTimeRangeAsAmPm(startTime, endTime)}`}
        </div>
      </ButtonCell>
      <ButtonCell {...buttonCellProps}>{duration ? `${duration} Min` : EMPTY_CELL}</ButtonCell>
      <ButtonCell {...buttonCellProps}>{patient.shortDisplayName}</ButtonCell>
      <ButtonCell {...buttonCellProps}>
        <AppointmentProviders dentist={dentist} provider={provider} />
      </ButtonCell>
      <ButtonCell {...buttonCellProps}>{room.roomName}</ButtonCell>
      <ButtonCell {...buttonCellProps}>{categoryName?.name}</ButtonCell>
      <ButtonCell {...buttonCellProps} className={cxStyles.cell}>
        {comments && (
          <Icon
            SvgIcon={NoteIcon}
            tooltip={{ content: <p className="whitespace-pre-line">{comments}</p>, theme: "MEDIUM" }}
          />
        )}
      </ButtonCell>
      <Cell className={cx("pr-5 group-hover:bg-slate-100", cxStyles.cell)}>
        <AppointmentMenu
          appointment={appointment}
          onAppointmentDeleted={onAppointmentDeleted}
          placement="left-start"
          onTriggerClick={() => onClick(appointment)}
        />
      </Cell>
    </Row>
  );
};
