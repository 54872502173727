import { FC, FormEvent, ReactNode } from "react";
import { format } from "date-fns";
import { getLocalDate } from "@libs/utils/date";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { Form } from "@libs/components/UI/Form";
import { Modal, ModalProps } from "@libs/components/UI/Modal";
import { ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";

export const EditWorktimeModal: FC<{
  date: string;
  isLoading: boolean;
  formId: string;
  size?: ModalProps["size"];
  confirmText?: string;
  onRequestClose: Func;
  onSubmit: (e: FormEvent<HTMLFormElement>) => void;
  children?: ReactNode;
}> = ({
  children,
  size = "xs",
  confirmText = "Confirm",
  onRequestClose,
  date,
  formId,
  isLoading,
  onSubmit,
}) => {
  return (
    <Modal size={size} title={format(getLocalDate(date), "E, MMM d")} onClose={onRequestClose}>
      <ModalContent padding="sm">
        <Form id={formId} onSubmit={onSubmit}>
          {children}
        </Form>
      </ModalContent>
      <ModalFooter>
        <Button theme="secondary" className="min-w-button" onClick={onRequestClose}>
          Cancel
        </Button>
        <AsyncButton form={formId} type="submit" isLoading={isLoading} className="min-w-button">
          {confirmText}
        </AsyncButton>
      </ModalFooter>
    </Modal>
  );
};
