import { FC, useMemo } from "react";
import { RecurringScheduleVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { getRecurrenceOptions } from "components/ScheduleAppointments/getRecurrenceOptions";
import { getRecurrenceLabel } from "components/ScheduleAppointments/getRecurrenceLabel";
import { getRepeatValueFromRecurrence } from "components/ScheduleAppointments/getRepeatValueFromRecurrence";
import { BlockCustomRepeatModal } from "components/ScheduleAppointments/BlockCustomRepeatModal";

export const BlockRepeatSelectMenu: FC<{
  recurrence: RecurringScheduleVO | undefined;
  date: string | undefined;
  onChange: (recurrence: RecurringScheduleVO | undefined) => void;
}> = ({ recurrence, date, onChange }) => {
  const customRepeatModal = useBoolean(false);
  const repeatValue = useMemo(() => getRepeatValueFromRecurrence(date, recurrence), [date, recurrence]);

  const repeatOptions = useMemo(() => {
    return getRecurrenceOptions(date);
  }, [date]);

  return (
    <>
      <FormFieldSelect
        required
        formatOptionLabel={(option, ctx) => {
          if (
            repeatValue === "CUSTOM" &&
            recurrence &&
            option.value === "CUSTOM" &&
            ctx.context === "value"
          ) {
            const label = getRecurrenceLabel(recurrence);

            return (
              <FloatingTooltip placement="top-start" content={label}>
                <div className="truncate">{label}</div>
              </FloatingTooltip>
            );
          }

          return option.label;
        }}
        disabled={!date}
        label="Repeats"
        isClearable={false}
        isSearchable={false}
        value={repeatValue}
        options={repeatOptions}
        onChange={(option) => {
          if (option) {
            if (option.value === "CUSTOM") {
              customRepeatModal.on();
            } else {
              onChange(option.recurrence);
            }
          }
        }}
      />
      {customRepeatModal.isOn && date ? (
        <BlockCustomRepeatModal
          date={date}
          recurrence={recurrence}
          onRequestClose={customRepeatModal.off}
          onSave={(newRecurrence) => {
            onChange(newRecurrence);
            customRepeatModal.off();
          }}
        />
      ) : null}
    </>
  );
};
