import React, { PropsWithChildren } from "react";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { Panel } from "@libs/components/UI/Panel";
import { paths } from "utils/routing/paths";

type Props = {
  isEditing: boolean;
  patientId: number;
  isSaving?: boolean;
  formId?: string;
};

export const PatientInformationPanel: React.FC<PropsWithChildren<Props>> = ({
  isEditing,
  patientId,
  isSaving,
  formId,
  children,
}) => {
  return (
    <Panel
      actions={
        isEditing ? (
          <div
            className={`
              flex
              text-primaryTheme
              text-sm
              font-sansSemiBold
              items-center
              gap-x-6
            `}
          >
            <ButtonInternalLink to={paths.patient({ patientId })} theme="link">
              Cancel
            </ButtonInternalLink>
            <AsyncButton
              data-testid="update-patient"
              form={formId}
              isLoading={isSaving ?? false}
              theme="link"
              type="submit"
            >
              Save
            </AsyncButton>
          </div>
        ) : (
          <LinkIcon
            SvgIcon={EditIcon}
            to={paths.editPatient({ patientId })}
            theme="primary"
            tooltip={{ content: "Edit Patient Info", theme: "SMALL" }}
          />
        )
      }
      className="h-full"
      contentClassName="overflow-y-auto"
      includePadding={false}
      title="Patient Information"
    >
      {children}
    </Panel>
  );
};
