import React from "react";
import { useQueryParams } from "hooks/useQueryParams";

import { PaymentCollectedQuery } from "utils/routing/dashboard";
import { paths } from "utils/routing/paths";

export const useTableTabProps = () => {
  const { updateQuery, query } = useQueryParams("dashboardPaymentCollected");
  const handleRouteStateChange = React.useCallback(
    (updates: Partial<PaymentCollectedQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );

  return React.useMemo(() => {
    const queryParams = { startDate: query.startDate, endDate: query.endDate };
    const tabs = [
      {
        text: "Show by Payment Method",
        to: paths.dashboardPaymentCollected({ tableTab: "payment-methods" }, queryParams),
      },
      {
        text: "Show by Patient",
        to: paths.dashboardPaymentCollected({ tableTab: "patients" }, queryParams),
      },
      {
        text: "Show by Provider",
        to: paths.dashboardPaymentCollected({ tableTab: "providers" }, queryParams),
      },
      {
        text: "Show by Associated Provider",
        to: paths.dashboardPaymentCollected({ tableTab: "associated-provider" }, queryParams),
      },
    ];

    return {
      tabs,
      query,
      onUpdateParams: handleRouteStateChange,
    };
  }, [handleRouteStateChange, query]);
};
