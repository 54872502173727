import { FC, ChangeEvent, useState, useMemo, useCallback } from "react";
import { produce } from "immer";

import { EmployeeConsolidationVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useAccount } from "@libs/contexts/AccountContext";
import { ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";

import { Modal } from "@libs/components/UI/Modal";
import { migrateCompany } from "api/payroll/mutations";

import { handleError } from "utils/handleError";

import { EmployeeConsolidationTable } from "./EmployeeConsolidationTable";
import { GustoLoadingOverlay } from "./GustoLoadingOverlay";

export const MERGE_SELECTION_SIZE = 2;

export interface EmployeeConsolidationModalProps {
  employeeConsolidation: EmployeeConsolidationVO;
  isWaitingForMigrationEvent: boolean;
  onBeginConsolidation: Func;
  onRequestClose: Func;
}

interface EmployeeListState {
  employeeType: keyof EmployeeConsolidationVO;
  draftEmployeeConsolidation: EmployeeConsolidationVO;
  selectedEmployeeIndexes: Set<number>;
  mergedEmployeeIndexes: Set<number>;
}

export const EmployeeConsolidationModal: FC<EmployeeConsolidationModalProps> = ({
  employeeConsolidation,
  isWaitingForMigrationEvent,
  onBeginConsolidation,
  onRequestClose,
}) => {
  const [
    { employeeType, draftEmployeeConsolidation, selectedEmployeeIndexes, mergedEmployeeIndexes },
    setEmployeeList,
  ] = useState<EmployeeListState>({
    employeeType: "active",
    draftEmployeeConsolidation: employeeConsolidation,
    selectedEmployeeIndexes: new Set(),
    mergedEmployeeIndexes: new Set(),
  });

  const confirmCancelModal = useBoolean(false);
  const confirmMergeModal = useBoolean(false);
  const confirmConsolidationModal = useBoolean(false);

  const { practiceId } = useAccount();
  const [{ mutate: initiateCompanyMigration, isLoading: isInitiatingCompanyMigration }] = useApiMutations([
    migrateCompany,
  ]);

  const selectedEmployees = useMemo(() => {
    const [indexA, indexB] = selectedEmployeeIndexes;
    const employeeA = draftEmployeeConsolidation[employeeType][indexA];
    const employeeB = draftEmployeeConsolidation[employeeType][indexB];

    return [employeeA, employeeB];
  }, [selectedEmployeeIndexes, draftEmployeeConsolidation, employeeType]);

  const disableMerge = useMemo(() => {
    const [employeeA, employeeB] = selectedEmployees;

    // Enable when there are exactly two employees selected to merge, and disable if
    // selected employees are onboarded on same platform (invalid merge candidates)
    return selectedEmployeeIndexes.size === MERGE_SELECTION_SIZE
      ? Boolean((employeeA.archyId && employeeB.archyId) || (employeeA.gustoId && employeeB.gustoId))
      : true;
  }, [selectedEmployeeIndexes, selectedEmployees]);

  const handleEmployeeSelect = (e: ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    const employeeIndex = Number(value);

    if (checked) {
      setEmployeeList((last) =>
        produce(last, (draft) => {
          draft.selectedEmployeeIndexes = new Set([...last.selectedEmployeeIndexes, employeeIndex]);
        })
      );
    } else {
      setEmployeeList((last) =>
        produce(last, (draft) => {
          draft.selectedEmployeeIndexes = new Set(
            [...last.selectedEmployeeIndexes].filter((selectedIndex) => selectedIndex !== employeeIndex)
          );
        })
      );
    }
  };

  const handleEmployeeMerge = useCallback(() => {
    // Sort indexes to insert merged employee at index nearest to top
    const [mergeEmployeeIndex, duplicateEmployeeIndex] = [...selectedEmployeeIndexes].sort((a, b) => a - b);
    const [employeeA, employeeB] = selectedEmployees;
    const gustoId = employeeA.gustoId ?? employeeB.gustoId;

    confirmMergeModal.off();

    setEmployeeList((last) => ({
      ...last,
      // Replaces first employee with merged employee and removes duplicate employee
      draftEmployeeConsolidation: produce(last.draftEmployeeConsolidation, (draft) => {
        // Merged employee should retain properties from employee with archyId
        draft[last.employeeType][mergeEmployeeIndex] = {
          ...(employeeA.archyId ? employeeA : employeeB),
          gustoId,
        };
        // Remove duplicate employee
        draft[last.employeeType].splice(duplicateEmployeeIndex, 1);
      }),
      // Clears selected employees
      selectedEmployeeIndexes: new Set(),
      // Highlights rows of merged employees
      mergedEmployeeIndexes: new Set(
        // Adjust merged employee indexes for removed duplicate employee
        [...last.mergedEmployeeIndexes, mergeEmployeeIndex].map((mergedIndex) =>
          mergedIndex > duplicateEmployeeIndex ? mergedIndex - 1 : mergedIndex
        )
      ),
    }));
  }, [selectedEmployeeIndexes, selectedEmployees, confirmMergeModal]);

  const handleEmployeeConsolidation = useCallback(() => {
    confirmConsolidationModal.off();

    initiateCompanyMigration(
      { practiceId, data: draftEmployeeConsolidation },
      {
        onSuccess: onBeginConsolidation,
        onError: handleError,
      }
    );
  }, [
    confirmConsolidationModal,
    initiateCompanyMigration,
    practiceId,
    draftEmployeeConsolidation,
    onBeginConsolidation,
  ]);

  return (
    <>
      <Modal onClose={confirmCancelModal.on} title="Consolidate Employee List">
        <ModalContent padding="lg" className="min-h-[36rem] font-sans text-sm text-greyDark">
          <EmployeeConsolidationTable
            employeeType={employeeType}
            employees={draftEmployeeConsolidation[employeeType]}
            selectedEmployeeIndexes={selectedEmployeeIndexes}
            mergedEmployeeIndexes={mergedEmployeeIndexes}
            disableMerge={disableMerge}
            onMergeClick={confirmMergeModal.on}
            onEmployeeSelect={handleEmployeeSelect}
          />

          {isWaitingForMigrationEvent && (
            <div className="absolute inset-0 z-50">
              <GustoLoadingOverlay className="rounded" loadingText="Consolidating Employee List..." />
            </div>
          )}
        </ModalContent>

        <ModalFooter>
          <Button className="min-w-button" onClick={confirmCancelModal.on} theme="secondary">
            Cancel
          </Button>
          {employeeType === "active" ? (
            <Button
              className="min-w-button"
              onClick={() => {
                setEmployeeList((last) =>
                  produce(last, (draft) => {
                    draft.employeeType = "archived";
                    draft.mergedEmployeeIndexes = new Set();
                  })
                );
              }}
              disabled={selectedEmployeeIndexes.size > 0}
            >
              Next
            </Button>
          ) : (
            <AsyncButton
              className="min-w-button"
              isLoading={isInitiatingCompanyMigration}
              onClick={confirmConsolidationModal.on}
              disabled={selectedEmployeeIndexes.size > 0}
            >
              Consolidate
            </AsyncButton>
          )}
        </ModalFooter>
      </Modal>

      {confirmCancelModal.isOn && (
        <ConfirmationModal
          primaryText="Are you sure you want to cancel this process?"
          secondaryText="You will lose the changes you've made to the employee list and will have to reinitiate it from scratch."
          onCancel={confirmCancelModal.off}
          onConfirm={onRequestClose}
        />
      )}

      {confirmMergeModal.isOn && (
        <ConfirmationModal
          primaryText="Are you sure you want to merge these employees?"
          secondaryText="The resulting merged employee will retain the Personal Information listed on Archy and the Payroll & Taxes Information listed on Gusto."
          onCancel={confirmMergeModal.off}
          onConfirm={handleEmployeeMerge}
        />
      )}

      {confirmConsolidationModal.isOn && (
        <ConfirmationModal
          primaryText="Are you sure you want to proceed with this consolidation?"
          secondaryText="This process cannot be reversed and may take a few moments."
          onCancel={confirmConsolidationModal.off}
          onConfirm={handleEmployeeConsolidation}
        />
      )}
    </>
  );
};
