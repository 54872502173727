// TODO fix accessiblity issues
/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */

import { addMonths, addDays, isSameDay, startOfToday, subDays } from "date-fns";
import { useMemo, useEffect, useCallback } from "react";
import DatePicker, { ReactDatePickerProps } from "react-datepicker";
import { CustomHolidayVO, PublicHolidayVO, WorkingHourItemVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import {
  SIX_MONTHS,
  FOUR_MONTHS,
  THREE_MONTHS,
  formatLongDayOfMonth,
  formattedDateToISODate,
  getLocalDate,
  nowInTimezone,
} from "@libs/utils/date";
import { usePortalId } from "@libs/contexts/PortalContext";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as RightCaret } from "@libs/assets/icons/right-caret.svg";
import { ReactComponent as LeftCaret } from "@libs/assets/icons/left-caret.svg";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { ReactDatePickerNav, ReactDatePickerNavInput } from "components/UI/ReactDatePickerNav";
import {
  customHolidaysToDateIntervalsMap,
  hasWorkHours,
  publicHolidaysToDateIntervalsMap,
  workHoursToDayIndexMap,
} from "./calculateWorkHours";
import { useMonthNavigation } from "./useMonthNavigation";

type DateCallback = (date: Date) => void;

interface Props extends Omit<ReactDatePickerProps, "selected" | "onChange"> {
  onChange: DateCallback;
  workHours: WorkingHourItemVO[] | undefined;
  customHolidays: CustomHolidayVO[] | undefined;
  publicHolidays: PublicHolidayVO[] | undefined;
  selected: Date;
  onUpdateMonthsDisplay: (selectedDate: Date, monthsShown: number) => void;
}

export const ScheduleDatePicker: React.FC<Props> = ({
  customHolidays,
  publicHolidays,
  workHours,
  onChange,
  onUpdateMonthsDisplay,
  ...props
}) => {
  const practice = useCurrentPractice();
  const modal = useBoolean(false);
  const { selected } = props;

  const prevDay = () => {
    onChange(subDays(selected, 1));
  };

  const nextDay = () => {
    onChange(addDays(selected, 1));
  };

  const { increaseMonths, decreaseMonths, handleOpenDatepicker, openToDate, openKey } = useMonthNavigation(
    selected,
    SIX_MONTHS
  );

  useEffect(() => {
    if (modal.isOn) {
      onUpdateMonthsDisplay(openToDate, SIX_MONTHS);
    }
  }, [onUpdateMonthsDisplay, openToDate, modal.isOn]);

  const { customHolidayIntervalMap, workDayIndexMap, publicHolidayIntervalMap } = useMemo(() => {
    return {
      customHolidayIntervalMap: customHolidays ? customHolidaysToDateIntervalsMap(customHolidays) : {},
      workDayIndexMap: workHours ? workHoursToDayIndexMap(workHours) : {},
      publicHolidayIntervalMap: publicHolidays?.length
        ? publicHolidaysToDateIntervalsMap(publicHolidays)
        : {},
    };
  }, [workHours, publicHolidays, customHolidays]);

  const handleFormatValue = useCallback((value?: string) => {
    return value ? formatLongDayOfMonth(getLocalDate(formattedDateToISODate(value))) : "";
  }, []);

  const handleInputClick = () => {
    handleOpenDatepicker(selected);
    modal.on();
  };

  const handleJumpToDateClick = useCallback(
    (monthsToJump?: number) => {
      modal.off();

      if (monthsToJump) {
        onChange(addMonths(selected, monthsToJump));

        return;
      }

      onChange(nowInTimezone(practice.timezoneId));
    },
    [modal, onChange, selected, practice.timezoneId]
  );
  const portalId = usePortalId();

  return (
    <ReactDatePickerNav onNextClick={nextDay} onPrevClick={prevDay} className="z-10">
      <DatePicker
        {...props}
        onChange={(d) => {
          if (d) {
            modal.off();
            onChange(d);
          }
        }}
        portalId={portalId}
        key={openKey}
        monthsShown={SIX_MONTHS}
        open={modal.isOn}
        onInputClick={handleInputClick}
        onClickOutside={modal.off}
        openToDate={openToDate}
        customInput={<ReactDatePickerNavInput className="w-56" onFormatValue={handleFormatValue} />}
        dayClassName={(data) => {
          const today = startOfToday();

          return cx(
            isSameDay(today, data) && "today",
            !hasWorkHours(data, workDayIndexMap, publicHolidayIntervalMap, customHolidayIntervalMap) &&
              "no-work-hours",
            today > data && "past"
          );
        }}
        calendarStartDay={0}
        wrapperClassName="react-datepicker-archy-datepicker-wrapper"
        popperClassName={`
          react-datepicker-archy-popper
          react-datepicker-archy-calendar
          react-datepicker-archy-schedule-calendar-popper
        `}
        popperPlacement="bottom"
      >
        <div
          className={`
            absolute
            top-0
            w-full
            left-0
            flex
            justify-between
            items-center
            pt-2.5
            px-16
            h-16
          `}
        >
          <ButtonIcon onClick={decreaseMonths} theme="primary" SvgIcon={LeftCaret} />

          <div className="flex items-center gap-x-2">
            <Button
              type="button"
              theme="tertiary"
              className="text-primaryTheme"
              onClick={() => handleJumpToDateClick()}
            >
              Today
            </Button>
            <Button
              type="button"
              theme="tertiary"
              className="text-primaryTheme"
              onClick={() => handleJumpToDateClick(THREE_MONTHS)}
            >
              + 3 M
            </Button>
            <Button
              type="button"
              theme="tertiary"
              className="text-primaryTheme"
              onClick={() => handleJumpToDateClick(FOUR_MONTHS)}
            >
              + 4 M
            </Button>
            <Button
              type="button"
              theme="tertiary"
              className="text-primaryTheme"
              onClick={() => handleJumpToDateClick(SIX_MONTHS)}
            >
              + 6 M
            </Button>
          </div>

          <ButtonIcon onClick={increaseMonths} theme="primary" SvgIcon={RightCaret} />
        </div>
      </DatePicker>
    </ReactDatePickerNav>
  );
};
