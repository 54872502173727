import { ButtonInternalLink, ButtonLink, ButtonLinkProps } from "@libs/components/UI/ButtonLink";
import sensorTestingImage from "assets/images/onboarding-sensor-testing.svg";
import { paths } from "utils/routing/paths";
import { MailToLink } from "components/UI/MailToLink";
import { SetupStep } from "components/Settings/OnboardingSettings/SetupStep";
import { DetailDisclosure } from "components/Settings/OnboardingSettings/DetailDisclosure";
import {
  SettingsHeroContainer,
  SettingsHeroContent,
  SettingsHeroDetails,
  SettingsHeroTitle,
} from "components/Settings/SettingsHeroLayout";
import { externalLinks } from "components/UI/ExternalLink";
import { IMAGING_SERVICE_DOWNLOAD_URL } from "api/imaging/imaging-hub";
import { SettingsPanel } from "components/Settings/SettingsPanel";

export const OnboardingSensorTestingRoute: React.FC = () => {
  const sharedProps: ButtonLinkProps = {
    size: "medium",
  };

  return (
    <SettingsPanel title="Sensor Testing">
      <SettingsHeroContent className="flex flex-col gap-y-6 max-w-xl mx-auto">
        <SettingsHeroContainer image={<img src={sensorTestingImage} alt="iPad with X-Ray and USB drive" />}>
          <SettingsHeroDetails>
            <SettingsHeroTitle>Sensor Testing</SettingsHeroTitle>
            <p>
              Now that you have begun onboarding with Archy it&apos;s time to get your sensors set up. Get
              prepared for sensor testing and book in your session below!
            </p>
            <MailToLink className="text-xs" email="support@archy.com">
              Contact Support
            </MailToLink>
          </SettingsHeroDetails>
        </SettingsHeroContainer>
        <SetupStep
          title="1. Install AnyDesk"
          description="Prior to your sensor setup meeting, have your IT company install and setup AnyDesk on the device we will be using for the session."
        >
          <ButtonLink href={externalLinks.anydeskDownloadUrl} size="small" {...sharedProps}>
            Install AnyDesk
          </ButtonLink>
        </SetupStep>
        <SetupStep
          title="2. Install and Verify Drivers"
          description="Install Twain drivers for your sensors and scanners if not already installed. WIA drivers are the most ideal for scanners."
        />

        <SetupStep
          title="3. Install Archy Imaging Service (AIS)"
          description="The Archy Imaging Service allows Archy to connect directly to your sensors. This service also allows us to store captured images and upload them to Archy even if you have network issues."
        >
          <ButtonLink href={IMAGING_SERVICE_DOWNLOAD_URL} target="_blank" {...sharedProps}>
            Install AIS
          </ButtonLink>
        </SetupStep>
        <SetupStep
          title="4. Schedule Sensor Set Up"
          description="In the meeting, we will walk you through imaging testing on one computer, and then your IT person can complete the testing on the rest of your computers."
        >
          <DetailDisclosure icon="clock">1 Hour</DetailDisclosure>
          <DetailDisclosure icon="people">IT Staff and a Clinical Team Member</DetailDisclosure>
          <ButtonInternalLink to={paths.sensorTestingSchedule()} {...sharedProps}>
            Schedule
          </ButtonInternalLink>
        </SetupStep>
      </SettingsHeroContent>
    </SettingsPanel>
  );
};
