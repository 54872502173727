import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { formatISODate } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { pluralize } from "@libs/utils/pluralize";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as RightArrowIcon } from "@libs/assets/icons/right-arrow.svg";
import { ReactComponent as CompareIcon } from "@libs/assets/icons/compare.svg";
import {
  TableGrid,
  IconsCell,
  TextCell,
  HeaderCheckboxCell,
  CheckboxCell,
  HeaderCell,
  ColumnSortIndicator,
  SortDirection,
  HeaderButtonCell,
  Row,
} from "@libs/components/UI/GridTableComponents";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { FlyoverContent } from "components/UI/FlyoverComponents";
import { Flyover } from "components/UI/Flyover";
import { usePerioChart } from "components/Charting/Perio/PerioChartContext";
import { useSelectRows } from "hooks/useSelectRows";
import { paths } from "utils/routing/paths";

type Props = {
  onClose: Func;
  patientId: number;
};

const COLUMNS = [
  { title: "", width: "3.5rem" },
  { title: "Date", width: "6rem" },
  { title: "Author", width: "1fr" },
  { title: "", width: "4rem" },
];

const PERIOCHART_MAX_COMPARISION = 3;

export const PerioChartHistoryFlyover: React.FC<Props> = ({ onClose, patientId }) => {
  const { historicalExamInfos, isLoading, currentExam, loadExam, deleteExams } = usePerioChart();
  const totalRows = historicalExamInfos?.length ?? 0;
  const [sortDirection, setSortDirection] = useState<SortDirection>("DESCENDING");
  const currentUuid = currentExam?.getExamInfo().uuid;
  const confirmDeleteModal = useBoolean(false);
  const { selectedCount, selectedRows, selectAllRows, resetSelectedRows, handleCheckboxChange } =
    useSelectRows(historicalExamInfos?.map(({ uuid }) => uuid) ?? []);
  const navigate = useNavigate();

  const handleComparePerioCharts = useCallback(() => {
    navigate(paths.perioCompare({ patientId }, { uuids: [...selectedRows] }));
  }, [navigate, patientId, selectedRows]);
  const sortedRows = useMemo(() => {
    return sortDirection === "ASCENDING" && historicalExamInfos
      ? [...historicalExamInfos].reverse()
      : historicalExamInfos;
  }, [historicalExamInfos, sortDirection]);

  const isOverComparisionLimit = selectedCount > PERIOCHART_MAX_COMPARISION;

  return (
    <>
      <Flyover title="Perio Charts" onClose={onClose}>
        <FlyoverContent className="items-start" paddingClassName="px-0 py-0">
          <TableGrid
            gridTemplateRows={isLoading ? "auto 1fr" : undefined}
            columnWidths={COLUMNS.map(({ width }) => width)}
          >
            <Row>
              <TextCell verticalPadding="slim" className="bg-slate-50 h-10 flex items-center">
                <ButtonIcon
                  disabled={selectedCount === 0}
                  SvgIcon={DeleteIcon}
                  tooltip={{ content: "Delete selected exams", theme: "SMALL" }}
                  theme="primary"
                  onClick={confirmDeleteModal.on}
                />
              </TextCell>

              <TextCell verticalPadding="slim" className="col-span-3 flex items-center bg-slate-50">
                <ButtonIcon
                  SvgIcon={CompareIcon}
                  theme="primary"
                  onClick={handleComparePerioCharts}
                  tooltip={
                    isOverComparisionLimit
                      ? { content: "You may only compare up to 3 exams at a time.", theme: "SMALL" }
                      : selectedCount <= 1
                        ? { content: "Select one more to compare", theme: "SMALL" }
                        : { content: "Compare exams", theme: "SMALL" }
                  }
                  disabled={selectedCount <= 1 || isOverComparisionLimit}
                />
              </TextCell>
            </Row>
            <Row>
              <HeaderCheckboxCell
                selectedCount={selectedCount}
                totalRows={totalRows}
                onSelectAllRows={selectAllRows}
                onDeselectAllRows={resetSelectedRows}
              />
              <HeaderButtonCell
                className="flex gap-2"
                onClick={() => {
                  setSortDirection(sortDirection === "ASCENDING" ? "DESCENDING" : "ASCENDING");
                }}
              >
                Date <ColumnSortIndicator direction={sortDirection} />
              </HeaderButtonCell>
            </Row>
            <HeaderCell className="col-span-2">Author</HeaderCell>
            {sortedRows?.map((historicalExam) => {
              const { uuid, date, provider } = historicalExam;

              return (
                <Row key={uuid}>
                  <CheckboxCell
                    value={uuid}
                    checked={selectedRows.has(uuid)}
                    onChange={handleCheckboxChange}
                  />
                  <TextCell className="flex items-center">{formatISODate(date)}</TextCell>
                  <TextCell className="flex items-center">{provider.shortDisplayName}</TextCell>

                  <IconsCell>
                    <ButtonIcon
                      onClick={() => {
                        if (currentUuid !== uuid) {
                          loadExam(uuid);
                        }

                        onClose();
                      }}
                      disabled={selectedCount > 0}
                      SvgIcon={RightArrowIcon}
                      theme="primary"
                    />
                  </IconsCell>
                </Row>
              );
            })}
          </TableGrid>
        </FlyoverContent>
      </Flyover>
      {confirmDeleteModal.isOn && (
        <ConfirmationModal
          onCancel={confirmDeleteModal.off}
          onConfirm={() => {
            confirmDeleteModal.off();
            deleteExams([...selectedRows]);
            onClose();
          }}
          primaryText={pluralize(
            selectedCount,
            "Are you sure you want to delete this Perio Chart Exam?",
            "Are you sure you want to delete these Perio Chart Exams?"
          )}
        />
      )}
    </>
  );
};
