import { DashboardFilterFormFieldSelect } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";

type FiltersParam = {
  filters: DashboardFilter[];
};
type Props = {
  query: FiltersParam;
  onUpdateParams: (params: FiltersParam) => void;
};

const HAS_FIRST_APPT_OPTIONS = [
  {
    label: "No First Appt",
    value: "false" as const,
  },
  {
    label: "Has First Appt",
    value: "true" as const,
  },
];

export const SelectHasFirstAppt: React.FC<Props> = ({ query, onUpdateParams }) => {
  return (
    <DashboardFilterFormFieldSelect
      aria-label="First Appt"
      className="min-w-[140px]"
      dashboardFilters={query.filters}
      dashboardFilterType="hasFirstAppointment"
      isSearchable={false}
      onChangeFilters={onUpdateParams}
      options={HAS_FIRST_APPT_OPTIONS}
      placeholder="Appointment"
    />
  );
};
