import { useCallback } from "react";
import { cx } from "@libs/utils/cx";
import { isOneOf } from "@libs/utils/isOneOf";
import { ColumnSortIndicator, HeaderButtonCell, HeaderCell } from "@libs/components/UI/GridTableComponents";
import { OrderBy, SortColumn } from "api/patients/queries";
import { HeaderItem } from "components/Patients/Table";
import { PatientsQuery } from "utils/routing/patient";

interface Props {
  headerItem: HeaderItem;
  onSortChange: (orderBy: OrderBy, sortColumn: SortColumn) => void;
  queryStateApi: PatientsQuery;
}

export const TableHeaderCell: React.FC<Props> = ({ headerItem, onSortChange, queryStateApi }) => {
  const handleSort = useCallback(() => {
    if (isOneOf(headerItem.id, ["checkbox", "menu"])) {
      return;
    }

    const orderBy =
      headerItem.id === queryStateApi.sortColumn && queryStateApi.orderBy === "DESCENDING"
        ? "ASCENDING"
        : "DESCENDING";

    onSortChange(orderBy, headerItem.id);
  }, [headerItem.id, onSortChange, queryStateApi.orderBy, queryStateApi.sortColumn]);

  return headerItem.sortable ? (
    <HeaderButtonCell
      className={cx(headerItem.id === "nextAppointment" && "justify-end pr-4")}
      onClick={handleSort}
      size="short"
    >
      {headerItem.label}
      <ColumnSortIndicator
        className="ml-2"
        direction={queryStateApi.sortColumn === headerItem.id ? queryStateApi.orderBy : undefined}
      />
    </HeaderButtonCell>
  ) : (
    <HeaderCell size="short">{headerItem.label}</HeaderCell>
  );
};
