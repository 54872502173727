import { FC } from "react";
import { cx } from "@libs/utils/cx";

const cxStyles = {
  sticker: {
    adjustment: "bg-plumWeb",
    appointment: "bg-navy-50",
    invoice: "bg-blueCadet",
    claim: "bg-mossGreen",
    claimBalanceTransfer: "bg-khaki",
    appointmentEdit: "bg-blueShadow",
  },
};

export const DescriptionSticker: FC<{ color: keyof typeof cxStyles.sticker }> = ({ color }) => {
  return <div className={cx("w-2 h-4 rounded-sm flex-none", cxStyles.sticker[color])} />;
};
