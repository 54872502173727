import { UpdateEmailConfigRequest, EmailConfigVO } from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import { updateCachedData } from "@libs/utils/queryCache";

export const updateEmailConfig = makeMutation({
  mutationKey: ["practices", "updateEmailConfig"],
  formatParams: (args: { practiceId: number; data: UpdateEmailConfigRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId }) => {
      updateCachedData<EmailConfigVO>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getEmailConfig"), { practiceId }] },
        () => data.data.data
      );
    },
  }),
});

export const verifyDomain = makeMutation({
  mutationKey: ["practices", "verifyDomain"],
  formatParams: (args: { practiceId: number; checkingVerification: boolean }) => [
    args.practiceId,
    { checkingVerification: args.checkingVerification },
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId }) => {
      updateCachedData<EmailConfigVO>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getEmailConfig"), { practiceId }] },
        () => data.data.data
      );
    },
  }),
});
