import { useMemo } from "react";
import { BatchUpsertBenefitCoverageRequest, UpdatePatientInsuranceRequest } from "@libs/api/generated-api";
import { ValidationResult } from "@libs/hooks/useValidation";
import { lessOrEqualTo, max, min, required } from "@libs/utils/validators";
import { formatCurrency } from "@libs/utils/currency";
import { PlanFields } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/usePlanFields";
import { BenefitCoverageFields } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/useBenefitCoverageFields";

export const coveragesSchema = {
  benefits: {
    $items: {
      startCdtCodeRange: [
        {
          $error: "Start code required",
          $v: required,
        },
      ],
      endCdtCodeRange: [
        {
          $error: "End code required",
          $v: required,
        },
      ],
      benefitCategory: [
        {
          $error: "Category required",
          $v: required,
        },
      ],
      percentCoverage: [
        {
          $error: "Coverage must be greater than or equal to 0",
          $v: min(0),
        },
        {
          $error: "Coverage must be less than or equal to 100",
          // eslint-disable-next-line @typescript-eslint/no-magic-numbers
          $v: max(100),
        },
        {
          $error: "Coverage percentage required",
          $v: required,
        },
      ],
      waitingPeriodInMonths: [
        {
          $error: "Waiting Period must be greater than or equal to 0",
          $v: min(0),
        },
        {
          $error: "Waiting Period required",
          $v: required,
        },
      ],
    },
  },
};

export const getCoverageUpdatePostData = (
  coverages: BenefitCoverageFields[]
): BatchUpsertBenefitCoverageRequest => {
  return {
    benefits: coverages
      .filter((item) => Object.keys(item).length > 0)
      .map((item) => {
        return {
          applyDeductible: item.applyDeductible,
          autoEligibility: item.autoEligibility,
          benefitCategory: item.benefitCategory as string,
          copayAmount: item.copayAmount,
          endCdtCodeRange: item.endCdtCodeRange as number,
          startCdtCodeRange: item.startCdtCodeRange as number,
          percentCoverage: item.percentCoverage,
          preauthRequired: item.preauthRequired,
          uuid: item.uuid,
          waitingPeriodInMonths: item.waitingPeriodInMonths,
        };
      }),
  };
};

export type CoveragesValidationResult = ValidationResult<
  BatchUpsertBenefitCoverageRequest,
  typeof coveragesSchema
>;

export const useInsuranceAndSubscriberSchema = (planFields: PlanFields) => {
  const individualLifetimeDeductible = planFields.individualLifetimeDeductible || 0;
  const individualAnnualMaximum = planFields.individualAnnualMaximum || 0;
  const individualLifetimeMaximum = planFields.individualLifetimeMaximum || 0;
  const individualAnnualDeductible = planFields.individualAnnualDeductible || 0;
  const familyAnnualDeductible = planFields.familyAnnualDeductible || 0;
  const familyAnnualMaximum = planFields.familyAnnualMaximum || 0;
  const orthoLifetimeMaximum = planFields.orthoLifetimeMaximum || 0;

  return useMemo(
    () => ({
      lifetimeDeductibleRemaining: [
        {
          $error:
            "Individual lifetime deductible remaining amount cannot be set until individual lifetime deductible is set",
          $v: (value: unknown) => value == null || required(planFields.individualLifetimeDeductible),
        },
        {
          $error: `Individual lifetime deductible remaining amount cannot be greater than ${formatCurrency(
            individualLifetimeDeductible
          )}`,
          $v: lessOrEqualTo(individualLifetimeDeductible),
        },
      ],
      lifetimeMaximumRemaining: [
        {
          $error:
            "Individual lifetime maximum remaining amount cannot be set until individual lifetime maximum is set",
          $v: (value: unknown) => value == null || required(planFields.individualLifetimeMaximum),
        },
        {
          $error: `Individual lifetime maximum remaining amount cannot be greater than ${formatCurrency(
            individualLifetimeMaximum
          )}`,
          $v: lessOrEqualTo(individualLifetimeMaximum),
        },
      ],
      annualDeductibleRemaining: [
        {
          $error:
            "Individual annual deductible remaining cannot be set until individual annual deductible is set",
          $v: (value: unknown) => value == null || required(planFields.individualAnnualDeductible),
        },
        {
          $error: `Individual annual deductible remaining amount cannot be greater than ${formatCurrency(
            individualAnnualDeductible
          )}`,
          $v: lessOrEqualTo(individualAnnualDeductible),
        },
      ],
      annualMaximumRemaining: [
        {
          $error: "Individual annual maximum remaining cannot be set until individual annual maximum is set",
          $v: (value: unknown) => value == null || required(planFields.individualAnnualMaximum),
        },
        {
          $error: `Individual annual maximum remaining amount cannot be greater than ${formatCurrency(
            individualAnnualMaximum
          )}`,
          $v: lessOrEqualTo(individualAnnualMaximum),
        },
      ],
      familyAnnualDeductibleRemaining: [
        {
          $error:
            "Family annual deductible remaining amount cannot be set until family annual deductible is set",
          $v: (value: unknown) => value == null || required(planFields.familyAnnualDeductible),
        },
        {
          $error: `Family annual deductible remaining amount cannot be greater than ${formatCurrency(
            familyAnnualDeductible
          )}`,
          $v: lessOrEqualTo(familyAnnualDeductible),
        },
      ],
      familyAnnualMaximumRemaining: [
        {
          $error: "Family annual maximum remaining amount cannot be set until family annual maximum is set",
          $v: (value: unknown) => value == null || required(planFields.familyAnnualMaximum),
        },
        {
          $error: `Family annual maximum remaining amount cannot be greater than ${formatCurrency(
            familyAnnualMaximum
          )}`,
          $v: lessOrEqualTo(familyAnnualMaximum),
        },
      ],
      orthoMaximumRemaining: [
        {
          $error: "Ortho maximum remaining amount cannot be set until ortho lifetime maximum is set",
          $v: (value: unknown) => value == null || required(planFields.orthoLifetimeMaximum),
        },
        {
          $error: `Ortho maximum remaining amount cannot be greater than ${formatCurrency(
            orthoLifetimeMaximum
          )}`,
          $v: lessOrEqualTo(orthoLifetimeMaximum),
        },
      ],
    }),
    [
      planFields,
      familyAnnualDeductible,
      familyAnnualMaximum,
      individualAnnualDeductible,
      individualAnnualMaximum,
      individualLifetimeDeductible,
      individualLifetimeMaximum,
      orthoLifetimeMaximum,
    ]
  );
};

export type InsuranceValidationResult = ValidationResult<
  UpdatePatientInsuranceRequest,
  ReturnType<typeof useInsuranceAndSubscriberSchema>
>;
