import React from "react";
import type { XYCoord } from "react-dnd";
import { useDragLayer } from "react-dnd";
import { DragPreview } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/DragPreview";
import { SANDBOX_NAVIGATION } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/constants";
import { ImageWithZoom } from "./types";

function getItemStyles(initialOffset: XYCoord | null, currentOffset: XYCoord | null) {
  if (!initialOffset || !currentOffset) {
    return {
      display: "none",
    };
  }

  const { x, y } = currentOffset;

  const transform = `translate(${x}px, ${y}px)`;

  return {
    transform,
    WebkitTransform: transform,
  };
}

export const DragLayer: React.FC<{ isShowingMetadata?: boolean }> = ({ isShowingMetadata = false }) => {
  const { isDragging, item, initialOffset, currentOffset, itemType } = useDragLayer<
    {
      isDragging: boolean;
      currentOffset: { x: number; y: number } | null;
      initialOffset: { x: number; y: number } | null;
      item?: ImageWithZoom;
      itemType: string | symbol | null;
    },
    ImageWithZoom
  >((monitor) => ({
    item: monitor.getItem(),
    itemType: monitor.getItemType(),
    initialOffset: monitor.getInitialSourceClientOffset(),
    currentOffset: monitor.getSourceClientOffset(),
    isDragging: monitor.isDragging(),
  }));

  if (!isDragging || !item || itemType !== SANDBOX_NAVIGATION) {
    return null;
  }

  return (
    <div className="z-50 left-0 top-0 w-full h-full pointer-events-none fixed">
      <div style={getItemStyles(initialOffset, currentOffset)}>
        <DragPreview isShowingMetadata={isShowingMetadata} image={item.image} scale={item.zoomScale} />
      </div>
    </div>
  );
};
