import { FC, useCallback, useEffect, useMemo, useState } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isOneOf } from "@libs/utils/isOneOf";
import { INTERNAL_SERVER_ERROR, BAD_GATEWAY, SERVICE_UNAVAILABLE } from "@libs/utils/statusCodes";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { Phones } from "components/Settings/PracticeSetup/Sections/Phones/Phones";
import MangoLogo from "assets/images/logo-mango.svg";
import { upsertMangoAuthorization } from "api/settings/phones/mutations";
import { getPhoneExtensions } from "api/settings/phones/queries";
import { ExtensionsModal } from "components/Settings/PracticeSetup/Sections/Phones/ExtensionsModalContent";
import { useMangoContext } from "components/Mango/MangoContext";

export const PracticePhonesRoute: FC = () => {
  const { practiceId } = useAccount();
  const fetched = useBoolean(false);
  const authKeyFailed = useBoolean(false);

  const [phoneExtensionsQuery] = useApiQueries([
    getPhoneExtensions({ args: { practiceId }, queryOptions: { enabled: fetched.isOff } }),
  ]);

  // If getPhoneExtensions returns an error, we do not want to rerun the query
  // so set fetched to off and instead we render the instructions so the user
  // can try to authenticate with Mango
  useEffect(() => {
    if (phoneExtensionsQuery.isFetched) {
      fetched.on();
    }
  }, [authKeyFailed, fetched, phoneExtensionsQuery.isFetched]);

  // Only a 5xx should be considered a loading error. 4xx error status
  // represents we sent something incorrectly to Mango or that the auth key is
  // no longer valid so will render the instructions
  const isLoadingError = useMemo(() => {
    return Boolean(
      phoneExtensionsQuery.isError &&
        phoneExtensionsQuery.error &&
        isOneOf(phoneExtensionsQuery.error.status, [
          INTERNAL_SERVER_ERROR,
          BAD_GATEWAY,
          SERVICE_UNAVAILABLE,
          SERVICE_UNAVAILABLE,
        ])
    );
  }, [phoneExtensionsQuery.error, phoneExtensionsQuery.isError]);

  const [authKey, setAuthKey] = useState("");

  const [upsertMangoAuthorizationMutation] = useApiMutations([upsertMangoAuthorization]);

  const handleClickConnect = useCallback(() => {
    upsertMangoAuthorizationMutation.mutate(
      { practiceId, data: { key: authKey } },
      { onError: authKeyFailed.on, onSuccess: fetched.off }
    );
  }, [authKey, authKeyFailed.on, fetched.off, practiceId, upsertMangoAuthorizationMutation]);

  const handleClickGoBack = useCallback(() => {
    authKeyFailed.off();
  }, [authKeyFailed]);

  const editing = useBoolean(false);
  const { allowInbound, ext, setMangoConfig } = useMangoContext();

  return (
    <SettingsPanel
      actions={
        phoneExtensionsQuery.data && <ButtonIcon SvgIcon={EditIcon} onClick={editing.on} theme="primary" />
      }
      includePadding={false}
      isEditing={editing.isOn}
      title={
        <div className="flex items-center">
          <span>Phones</span>
          <span className="ml-6 mr-2 font-sans text-xs">Powered by</span>
          <img alt="Mango Logo" className="mt-1 h-4" src={MangoLogo} />
        </div>
      }
    >
      <Phones
        allowInbound={allowInbound}
        authKey={authKey}
        authKeyFailed={authKeyFailed.isOn}
        extension={ext}
        hasError={Boolean(phoneExtensionsQuery.error || !phoneExtensionsQuery.data)}
        isLoading={fetched.isOff}
        isLoadingError={isLoadingError}
        onConnect={handleClickConnect}
        onGoBack={handleClickGoBack}
        onUpdateAuthKey={setAuthKey}
      />
      {editing.isOn && (
        <ExtensionsModal
          allowInbound={allowInbound}
          extensions={phoneExtensionsQuery.data ?? []}
          onClose={editing.off}
          isSettingsPage={true}
          selectedExt={ext}
          onSave={setMangoConfig}
        />
      )}
    </SettingsPanel>
  );
};
