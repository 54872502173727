import { FC, useState } from "react";

import { RecallVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { RecallInfo } from "components/PatientProfile/PatientOverview/Recall/RecallInfo";
import { RecallForm } from "components/PatientProfile/PatientOverview/Recall/RecallForm";

import { labelForRecallType } from "utils/labelForRecallType";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent } from "components/UI/FlyoverComponents";
import { getPatientRecallsQuery } from "api/patients/queries";

interface Props {
  onClose: Func;
  patientId: number;
}

export const RecallFlyover: FC<Props> = ({ patientId, onClose }) => {
  const [editType, setEditType] = useState<RecallVO["type"] | null>(null);
  const { practiceId } = useAccount();

  const [recallsQuery] = useApiQueries([getPatientRecallsQuery({ args: { practiceId, patientId } })]);

  const handleOpenEdit = (recallType: RecallVO["type"]) => {
    setEditType(recallType);
  };

  const handleCloseEdit = () => {
    setEditType(null);
  };

  return (
    <Flyover clickAwayToClose title="Recall" onClose={onClose} size="sm">
      <FlyoverContent paddingClassName="">
        <QueryResult queries={[recallsQuery]}>
          {recallsQuery.data?.map((recall) => (
            <div
              key={recall.type}
              className={cx(
                "flex px-8 py-4 border-b border-b-greyLighter group",
                recall.type === editType ? "bg-screenBackground" : "hover:bg-slate-100"
              )}
            >
              <p className="font-sansSemiBold text-sm text-greyDark min-w-20 mr-4">
                {labelForRecallType(recall.type)}
              </p>

              {recall.type === editType ? (
                <RecallForm
                  uuid={recall.uuid}
                  intervalMonths={recall.intervalMonths}
                  intervalDays={recall.intervalDays}
                  dueDate={recall.dueDate}
                  scheduledDate={recall.scheduledDate}
                  previousDate={recall.previousDate}
                  note={recall.note}
                  onClose={handleCloseEdit}
                />
              ) : (
                <RecallInfo
                  dueDate={recall.dueDate}
                  isDue={recall.isDue}
                  dueSoon={recall.dueSoon}
                  scheduledDate={recall.scheduledDate}
                  scheduledAppointmentId={recall.scheduledAppointmentId}
                  previousDate={recall.previousDate}
                  note={recall.note}
                  onEdit={() => handleOpenEdit(recall.type)}
                  disableEdit={Boolean(!recall.uuid)}
                />
              )}
            </div>
          ))}
        </QueryResult>
      </FlyoverContent>
    </Flyover>
  );
};
