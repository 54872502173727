import { FC } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getSchedulingConfigQuery } from "api/scheduling/queries";
import { CardConfig } from "components/Settings/Scheduling/Sections/AppointmentCardDesign/CardConfig";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { CardConfigForm } from "./CardConfigForm";

interface Props {
  editSection: boolean;
  onClose: Func;
}

export const AppointmentCardDesign: FC<Props> = ({ editSection, onClose }) => {
  const { practiceId } = useAccount();
  const [schedulingConfigQuery] = useApiQueries([getSchedulingConfigQuery({ args: { practiceId } })]);

  const currentUser = useCurrentUser();

  const config = schedulingConfigQuery.data?.appointmentCardConfig;

  const currentUserName = currentUser.name.fullDisplayName;

  return (
    <QueryResult queries={[schedulingConfigQuery]}>
      {config &&
        (editSection ? (
          <CardConfigForm config={config} currentUserName={currentUserName} onClose={onClose} />
        ) : (
          <CardConfig config={config} currentUserName={currentUserName} />
        ))}
    </QueryResult>
  );
};
