import { FC, PropsWithChildren, ReactNode } from "react";

interface Props {
  primaryActions: ReactNode;
  secondaryActions?: ReactNode;
}

export const MessagePanelLayout: FC<PropsWithChildren<Props>> = ({
  primaryActions,
  secondaryActions,
  children,
}) => {
  return (
    <>
      <div className="flex items-center justify-between flex-none">
        {primaryActions}
        <div className="flex items-center gap-x-2">{secondaryActions}</div>
      </div>
      <div className="flex flex-col items-center justify-center flex-1 min-h-0">{children}</div>
    </>
  );
};
