import React, { useCallback, useContext } from "react";
import { ClaimEobFileVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as ExternalLinkIcon } from "@libs/assets/icons/external-link.svg";
import { ReactComponent as DownloadIcon } from "@libs/assets/icons/download.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { Cell, Row } from "@libs/components/UI/GridTableComponents";
import { downloadFile } from "utils/files";
import { handleError } from "utils/handleError";

interface Props {
  canDownload: boolean;
  file: ClaimEobFileVO;
}

export const EobRow: React.FC<Props> = ({ canDownload, file }) => {
  const { fetchBlob } = useContext(ApiClientContext);
  const { practiceId } = useAccount();
  const { httpClient } = useContext(ApiClientContext);

  const downloadEob = useCallback(async () => {
    try {
      const blob = await fetchBlob(`/practices/${practiceId}/claims/eob-url/${file.uuid}/print`);

      downloadFile(blob, file.name);
    } catch (e) {
      handleError(e);
    }
  }, [fetchBlob, file.name, file.uuid, practiceId]);

  const openEob = useCallback(async () => {
    try {
      const result = await httpClient.practices.getEobUrl(practiceId, file.uuid);
      const url = result.data.data;

      if (url) {
        window.open(url, "_blank");
      }
    } catch (error) {
      handleError(error);
    }
  }, [file.uuid, httpClient.practices, practiceId]);

  return (
    <Row className="text-xs text-greyDark">
      <Cell className="py-1">{file.name}</Cell>
      <Cell className="py-1">{formatISODate(file.createdAt)}</Cell>
      <Cell className="flex items-center justify-end">
        {canDownload && (
          <button onClick={downloadEob} type="button">
            <FloatingTooltip content="Download" theme="SMALL">
              <DownloadIcon className="fill-primaryTheme w-4 h-4 mr-4" />
            </FloatingTooltip>
          </button>
        )}

        <button onClick={openEob} type="button">
          <FloatingTooltip content="View" theme="SMALL">
            <ExternalLinkIcon className="w-4 h-4 fill-primaryTheme mr-2" />
          </FloatingTooltip>
        </button>
      </Cell>
    </Row>
  );
};
