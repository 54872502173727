import { FC } from "react";
import { DateWorktimeDetails, DateWorktimeVO } from "@libs/api/generated-api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as TrashIcon } from "@libs/assets/icons/delete.svg";
import { TextCell, EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { EditApprovedTimeModalProps } from "components/EmployeeProfile/Timesheet/EditApprovedTimeModal";

interface Props {
  hoursForDay: DateWorktimeVO;
  workTime: DateWorktimeDetails;
  isApprovable: boolean;
  hasSelections: boolean;
  onEditApproval: (params: Omit<EditApprovedTimeModalProps, "onRequestClose" | "employeeId">) => void;
  onApproveWorktime: (worktimeId: number) => void;
  onDeleteWorktime: (worktimeId: number) => void;
}

export const TimeSheetRowActionsCell: FC<Props> = ({
  hoursForDay,
  workTime,
  isApprovable,
  hasSelections,
  onEditApproval,
  onApproveWorktime,
  onDeleteWorktime,
}) => {
  const deleteProps = {
    onClick: () => {
      onDeleteWorktime(workTime.id);
    },
    disabled: hasSelections,
    theme: "primary" as const,
    SvgIcon: TrashIcon,
    tooltip: { content: "Delete Clock in/out", theme: "SMALL" } as const,
  };

  return (
    <TextCell border={!workTime.clockOut.time}>
      {isApprovable ? (
        <div className="flex gap-x-3">
          <Button disabled={hasSelections} theme="link" onClick={() => onApproveWorktime(workTime.id)}>
            Approve
          </Button>

          <ButtonIcon
            onClick={() => {
              onEditApproval({ date: hoursForDay.date, worktime: workTime });
            }}
            disabled={hasSelections}
            theme="primary"
            SvgIcon={EditIcon}
            tooltip={{ content: "Edit Clock in/out", theme: "SMALL" }}
          />
          <ButtonIcon {...deleteProps} />
        </div>
      ) : workTime.status === "APPROVED" ? (
        <ButtonIcon {...deleteProps} />
      ) : (
        <div className="flex">
          <div className="flex-1">{EMPTY_CELL}</div>
        </div>
      )}
    </TextCell>
  );
};
