import React from "react";
import { toast } from "react-toastify";
import { readFileToURL } from "@libs/utils/dataUrl";
import { pluralize } from "@libs/utils/pluralize";
import { Spinner } from "@libs/components/UI/Spinner";
import { UploadDocument } from "components/UI/UploadDocument";
import { mimeTypeMapping } from "utils/files";

type Props = {
  uploadFile: (dataUrl: string, name: string) => Promise<unknown>;
  isUploading?: boolean;
};

export const ImageModalBody: React.FC<Props> = ({ uploadFile, isUploading }) => {
  const [uploadFileData, setUploadedFileData] = React.useState<{ name: string }[]>([]);
  const handleUpload = React.useCallback(
    async (file: File) => {
      try {
        const dataUrl = await readFileToURL(file);

        await uploadFile(dataUrl, file.name);
        setUploadedFileData((prev) => [{ name: file.name }, ...prev]);
      } catch {
        toast.error("An error occurred uploading this file");
      }
    },
    [uploadFile, setUploadedFileData]
  );

  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <UploadDocument
        onFileDropped={handleUpload}
        accept={`${mimeTypeMapping.png}, ${mimeTypeMapping.jpeg}`}
      />
      <div className="mt-5">
        {isUploading && <Spinner animation="border" size="sm" />}
        {uploadFileData.length > 0 && (
          <div className="text-xs">
            {uploadFileData.length} {pluralize(uploadFileData.length, "file", "files")} uploaded
          </div>
        )}
      </div>
    </div>
  );
};
