import Skeleton from "react-loading-skeleton";
import { titleCaseConstant } from "@libs/utils/casing";
import { formatCurrency } from "@libs/utils/currency";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Pill } from "@libs/components/UI/Pill";
import { useAccount } from "@libs/contexts/AccountContext";
import { getPatientInsuranceQuery } from "api/patientInsurance/queries";
import { getInsuranceLabel } from "components/Insurance/utils";

interface Props {
  insuranceId: number;
  patientId: number;
}

export const InsuranceTooltipContent: React.FC<Props> = ({ insuranceId, patientId }) => {
  const { practiceId } = useAccount();
  const [patientInsuranceQuery] = useApiQueries([
    getPatientInsuranceQuery({
      args: { practiceId, insuranceId, patientId },
    }),
  ]);

  const patientInsurance = patientInsuranceQuery.data;

  return patientInsurance ? (
    <div className="flex flex-col w-56 gap-y-2 text-sm">
      <span className="font-sansSemiBold">{getInsuranceLabel(patientInsurance)}</span>
      <span>
        Annual Max Rem {formatCurrency(patientInsurance.patientInsurance.annualMaximumRemaining ?? 0)}
      </span>
      <span>
        Annual Ded Rem {formatCurrency(patientInsurance.patientInsurance.annualDeductibleRemaining ?? 0)}
      </span>
      <Pill className="mt-2 max-w-fit" theme="blue">
        {titleCaseConstant(patientInsurance.patientInsurance.insuranceNetworkType)}
      </Pill>
    </div>
  ) : (
    <Skeleton className="w-56 h-20" />
  );
};
