import React from "react";
import {
  PatientAppointmentStatsRequest,
  PatientAppointmentStatsResponse,
  PatientAppointmentStatsTotalVO,
  PatientAppointmentStatsVO,
} from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getAgeByDob } from "@libs/utils/formatString";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { getAbsoluteUrl } from "@libs/utils/location";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { PATIENTS_TREATED_COLUMN_DATA } from "components/Dashboard/PatientsTreated/PatientsTreatedColumnData";
import { HeaderData } from "components/Dashboard/Tables/types";
import { formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";
import { handleError } from "utils/handleError";
import { useDownloadQueryPages } from "components/Dashboard/hooks/useDownloadInfiniteQuery";
import { getTotals } from "components/Dashboard/Tables/utils";

import { paths } from "utils/routing/paths";
import { TimeSegment } from "utils/routing/dashboard";
import { useCurrentUrl, useOrigin } from "contexts/OriginContext";
import { useNow } from "hooks/useNow";

const formatAppointmentDataAsCsvUrl = (
  now: Date,
  rows: PatientAppointmentStatsVO[],
  totals: PatientAppointmentStatsTotalVO,
  headers: HeaderData[],
  origin: string
) => {
  const columnTitles = formatCsvRow([...headers.map(({ label }) => label).filter(Boolean), "Patient URL"]);

  const rowsContent = rows.map((row) =>
    formatCsvRow([
      row.patient.shortDisplayName,
      `${getAgeByDob(now, row.patientDob)}`,
      row.primaryInsuranceCarrier?.name ?? "",
      sentenceCaseConstant(row.patientType),
      `${row.completedAppointments}`,
      getAbsoluteUrl(origin, paths.patient({ patientId: row.patient.id })),
    ])
  );
  const totalsContent = formatCsvRow(["", "", "", "Totals", `${totals.totalCompletedAppointments}`, ""]);

  return [columnTitles, ...rowsContent, totalsContent];
};

export const useDownloadPatientAppointmentStats = ({
  appointmentStatsInfiniteQuery,
  selectedTimeSegment,
}: PatientAppointmentStatsRequest & {
  selectedTimeSegment: TimeSegment;
  appointmentStatsInfiniteQuery: UseInfiniteApiQueryResult<PatientAppointmentStatsResponse>;
}) => {
  const now = useNow();
  const downloading = useBoolean(false);

  const { startDownload: downloadAppointmentStats } = useDownloadQueryPages(appointmentStatsInfiniteQuery);
  const origin = useOrigin();
  const currentUrl = useCurrentUrl();

  return {
    isDownloading: downloading.isOn,
    downloadCSV: React.useCallback(async () => {
      downloading.on();

      try {
        const result = await downloadAppointmentStats();
        const rows = result.pages.flatMap((item) => item.data.entries);
        const totals = getTotals(result);

        if (totals) {
          const docMetadata = getArchyCsvMetadata(currentUrl, selectedTimeSegment);

          const csvRows = [
            ...docMetadata.headers,
            ...formatAppointmentDataAsCsvUrl(now, rows, totals, PATIENTS_TREATED_COLUMN_DATA, origin),
          ];

          saveCsv(csvRows, `PatientsTreated_${docMetadata.fileNameTag}`);
        }
      } catch (e) {
        handleError(e);
      }

      downloading.off();
    }, [downloading, downloadAppointmentStats, selectedTimeSegment, origin, currentUrl, now]),
  };
};
