import { FC, useMemo } from "react";
import { RecurringScheduleVO } from "@libs/api/generated-api";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import {
  dailyOption,
  getAnnuallyOption,
  getMonthlyOption,
  getWeeklyOption,
  RepeatValue,
} from "components/ScheduleAppointments/getRecurrenceOptions";

interface Props {
  recurrenceType: RecurringScheduleVO["recurrenceType"];
  date: string;
  onChange: (newRecurrence: RecurringScheduleVO) => void;
}

const unitsMap: Record<RecurringScheduleVO["recurrenceType"], RepeatValue> = {
  DAYS_OF_WEEKS: "WEEKLY_ON",
  DAYS_OF_LAST_WEEKS_OF_MONTH: "MONTHLY_ON",
  DAYS_OF_MONTHS: "MONTHLY_ON",
  DAYS_OF_WEEKS_OF_MONTH: "MONTHLY_ON",
  DAYS_OF_YEAR: "ANNUALLY_ON",
  DAYS: "DAILY",
};

export const BlockCustomRepeatUnitSelectMenu: FC<Props> = ({ recurrenceType, date, onChange }) => {
  const unitOptions = useMemo(() => {
    return [
      {
        ...dailyOption,
        label: "Day",
      },
      {
        ...getWeeklyOption(date),
        label: "Week",
      },
      {
        ...getMonthlyOption(date),
        label: "Month",
      },
      {
        ...getAnnuallyOption(date),
        label: "Year",
      },
    ];
  }, [date]);

  return (
    <FormFieldSelect
      isClearable={false}
      isSearchable={false}
      display="label"
      className="self-start"
      value={unitsMap[recurrenceType]}
      options={unitOptions}
      onChange={(option) => {
        const nextRecurrence = option?.recurrence;

        if (nextRecurrence) {
          onChange(nextRecurrence);
        }
      }}
    />
  );
};
