import { FC, Suspense, lazy } from "react";
import { Navigate, Route } from "react-router-dom";
import { isOneOf } from "@libs/utils/isOneOf";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { NotFound } from "components/NotFoundRoute";
import { HomeRoute } from "components/HomeRoute";
import { DashboardRoute } from "components/Dashboard/DashboardRoute";
import { MessagingRoute } from "components/Messaging/MessagingRoute";
import { CommunicationsApp } from "components/Communications/CommunicationsApp";
import { PatientsApp } from "components/Patient/PatientsApp";
import { EmployeesApp } from "components/Employee/EmployeesApp";
import { ClaimsApp } from "components/Claims/ClaimsApp";
import { SettingsRoute } from "components/Settings/SettingsRoute";
import { signedInRedirect } from "utils/routing/paths";
import { SchedulingApp } from "components/ScheduleAppointments/SchedulingApp";
import { ErxNotificationsRoute } from "components/Erx/ErxNotificationsRoute";
import { SSOZendeskRoute } from "components/SSO/SSOZendeskRoute";
import { ImagingSensorsRoute } from "components/ImageCapturing/ImagingSensorsRoute";
import { AuthLayout } from "components/Main/AuthLayout";
import { LabCasesRoute } from "components/LabCases/LabCasesRoute";
import { useEnvContext } from "contexts/EnvContext";
import { ImagingHubProvider } from "components/ImageCapturing/ImagingHubContext";
import { RoleGuardLock } from "components/Main/RoleGuard";

const AuthRoutes: {
  index?: boolean;
  path?: string;
  element: JSX.Element;
}[] = [
  {
    index: true,
    element: <HomeRoute />,
  },
  {
    path: "dashboard/*",
    element: (
      <RoleGuardLock domain="REPORTING" action="ACCESS_ALL">
        <DashboardRoute />
      </RoleGuardLock>
    ),
  },
  {
    path: "patients/*",
    element: (
      <RoleGuardLock domain="PATIENT_PROFILE" action="ACCESS_ALL">
        <PatientsApp />
      </RoleGuardLock>
    ),
  },
  {
    path: "employees/*",
    element: <EmployeesApp />,
  },
  {
    path: "claims/*",
    element: (
      <RoleGuardLock domain="CLAIMS" action="ACCESS_ALL">
        <ClaimsApp />
      </RoleGuardLock>
    ),
  },
  {
    path: "settings/*",
    element: (
      <RoleGuardLock domain="PRACTICE_SETTINGS" action="ACCESS_ALL">
        <SettingsRoute />
      </RoleGuardLock>
    ),
  },
  {
    path: "schedule/*",
    element: (
      <RoleGuardLock domain="APPOINTMENT_MANAGEMENT" action="ACCESS_ALL">
        <SchedulingApp />
      </RoleGuardLock>
    ),
  },
  {
    path: "messaging",
    element: (
      <RoleGuardLock domain="COMMUNICATION" action="ACCESS_ALL">
        <MessagingRoute />
      </RoleGuardLock>
    ),
  },
  {
    path: "communications/*",
    element: (
      <RoleGuardLock domain="COMMUNICATION" action="BULK_MESSAGE">
        <CommunicationsApp />
      </RoleGuardLock>
    ),
  },
  {
    path: "erx",
    element: <ErxNotificationsRoute />,
  },
  {
    path: "sensors/:deviceId",
    element: <ImagingSensorsRoute />,
  },
  {
    path: "sso/zendesk",
    element: <SSOZendeskRoute />,
  },
  {
    path: "lab-cases",
    element: (
      <RoleGuardLock domain="LAB_CASE" action="ACCESS_ALL">
        <LabCasesRoute />
      </RoleGuardLock>
    ),
  },
];

const VerifyOdontogramRoute = lazy(() => {
  const promise = import(
    /* webpackChunkName: "verify-odontogram" */ "components/Charting/internal/VerifyOdontogramRoute"
  );

  return promise.then((component) => ({
    default: component.VerifyOdontogramRoute,
  }));
});

export const SignedInAppRoutes: FC = () => {
  const env = useEnvContext();

  return (
    <ImagingHubProvider>
      <AuthLayout>
        <SentryRoutes>
          {AuthRoutes.map((routeParams, i) => {
            return <Route {...routeParams} key={i} />;
          })}
          {isOneOf(env.REACT_APP_ENVIRONMENT, ["development", "preprod"]) ? (
            <Route
              path="verify-odontogram"
              element={
                <Suspense>
                  <VerifyOdontogramRoute />
                </Suspense>
              }
            />
          ) : null}
          <Route path="onboarding/*" element={<Navigate replace to={signedInRedirect} />} />
          <Route path="*" element={<NotFound />} />
        </SentryRoutes>
      </AuthLayout>
    </ImagingHubProvider>
  );
};
