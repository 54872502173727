import { WorktimeOverviewVO } from "@libs/api/generated-api";
import { SelectDecimalVsClock } from "components/Employee/SelectDecimalVsClock";
import { HoursSummary } from "components/EmployeeProfile/Timesheet/HoursSummary";

type Props = {
  worktimeOverview: WorktimeOverviewVO;
  hoursInDecimal: boolean;
  onUpdateHoursFormat: (value: boolean) => void;
};
export const ChartPanel: React.FC<Props> = ({ worktimeOverview, hoursInDecimal, onUpdateHoursFormat }) => {
  return (
    <div className="flex bg-offWhite items-center px-6 py-4 justify-between">
      <SelectDecimalVsClock
        value={hoursInDecimal ? "decimal" : "clock"}
        onItemSelected={(value) => onUpdateHoursFormat(value === "decimal")}
      />

      <HoursSummary
        hoursInDecimal={hoursInDecimal}
        totalOvertimeHours={worktimeOverview.totalOvertimeHours}
        totalWorkingHours={worktimeOverview.totalWorkingHours}
      />
    </div>
  );
};
