import { useState } from "react";
import { Button } from "@libs/components/UI/Button";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { ModalContent, ModalFooter, ModalForm, ModalHeader } from "@libs/components/UI/ModalComponents";
import { Modal } from "@libs/components/UI/Modal";

interface Props {
  treatmentPlanName: string;
  onRequestClose: Func;
  onSaveTreatmentPlanName: (newName: string) => void;
}

export const EditTreatmentPlanNameModal: React.FC<Props> = ({
  treatmentPlanName,
  onRequestClose,
  onSaveTreatmentPlanName,
}) => {
  const [draftName, setDraftName] = useState(treatmentPlanName);

  return (
    <Modal dataTestId="edit-tx-plan-name-modal" size="2xs">
      <ModalHeader onClose={onRequestClose}>Edit Plan Name</ModalHeader>
      <ModalForm
        onSubmit={(e) => {
          e.preventDefault();
          onSaveTreatmentPlanName(draftName);
        }}
      >
        <ModalContent padding="sm">
          <FormFieldInput label="Name" value={draftName} onChange={(e) => setDraftName(e.target.value)} />
        </ModalContent>
        <ModalFooter>
          <Button onClick={onRequestClose} theme="secondary" className="min-w-button">
            Cancel
          </Button>
          <Button type="submit" className="min-w-button">
            Save
          </Button>
        </ModalFooter>
      </ModalForm>
    </Modal>
  );
};
