/* eslint-disable @typescript-eslint/no-magic-numbers */
import { FC, useCallback } from "react";
import { Link } from "react-router-dom";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { PAGE_SIZE } from "@libs/utils/constants";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { usePick } from "@libs/hooks/usePick";
import { useAccount } from "@libs/contexts/AccountContext";
import { LayoutCard } from "@libs/components/UI/LayoutCard";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Banner } from "@libs/components/UI/Banner";
import { getCompanyOnboardingStatus, getGustoOnboardingErrors, getPayrolls } from "api/payroll/queries";
import { PayrollsTable } from "components/Payroll/PayrollsTable";

import { useQueryParams } from "hooks/useQueryParams";
import { PayrollQuery } from "utils/routing/employees";
import { RunPayrollSection } from "components/Payroll/RunPayrollSection";
import { PayrollsTitleBar } from "components/Payroll/PayrollsTitleBar";
import { paths } from "utils/routing/paths";
import { PinGuard } from "components/UI/PinGuard";
import { AccessDeniedContent } from "components/UI/AccessDeniedContent";
import { TablePagerPageDetails } from "components/UI/TablePagerPageDetails";

export const PayrollRoute: FC = () => {
  const { practiceId } = useAccount();
  const { query, updateQuery } = useQueryParams("payroll");
  const contentParams = usePick(query, ["payrollType", "payrollYear", "pageSize"]);
  const filterParams = usePick(query, ["payrollType", "payrollYear"]);
  const scrollParams = usePick(query, ["payrollType", "payrollYear", "pageSize", "page"]);

  usePageTitle("Payroll");

  const [processedPayrollsQuery, unprocessedPayrollsQuery, onboardingErrorsQuery, onboardingStatusQuery] =
    useApiQueries([
      getPayrolls({
        args: {
          practiceId,
          pageNumber: query.page,
          pageSize: query.pageSize,
          type: query.payrollType,
          year: query.payrollYear,
          processed: true,
        },
      }),
      getPayrolls({
        args: {
          practiceId,
          pageNumber: query.page,
          pageSize: PAGE_SIZE,
          unprocessed: true,
        },
      }),
      getGustoOnboardingErrors({
        args: { practiceId },
      }),
      getCompanyOnboardingStatus({ args: { practiceId } }),
    ]);

  const handleUpdatePayrollsList = useCallback(
    (updates: Partial<PayrollQuery>) => {
      // if params that alter the paging contenxt change reset to first page
      const filterKeys = Object.keys(contentParams) as (keyof Pick<
        PayrollQuery,
        "payrollType" | "pageSize" | "payrollYear"
      >)[];
      let expandedUpdates = updates;

      for (const key of filterKeys) {
        if (key in updates && updates[key] !== contentParams[key]) {
          expandedUpdates = { ...updates, page: undefined };
          break;
        }
      }

      updateQuery("replaceIn", expandedUpdates);
    },
    [updateQuery, contentParams]
  );

  const handlePageChange = (newPage: number) => {
    handleUpdatePayrollsList({ page: newPage });
  };
  const handlePageSizeChange = (newSize: number) => {
    handleUpdatePayrollsList({ pageSize: newSize, page: 1 });
  };

  const pageSize = Math.abs(query.pageSize);
  const page = Math.abs(query.page);

  return (
    <PinGuard
      protectedQueries={[processedPayrollsQuery, unprocessedPayrollsQuery, onboardingErrorsQuery]}
      deniedContent={
        <div className="w-full h-full flex place-content-center">
          <AccessDeniedContent />
        </div>
      }
    >
      <QueryResult queries={[onboardingErrorsQuery, onboardingStatusQuery]}>
        <div className="h-full flex flex-col">
          {onboardingErrorsQuery.data?.gustoErrorsFromOnboardingSteps.length ? (
            <Banner className="text-sm" theme="error">
              Please review these issue(s) in your Gusto settings in order to unblock your payroll.{" "}
              <Link
                className="text-primaryTheme"
                to={paths.settingsSection({ section: "payroll-information" })}
              >
                Review issues
              </Link>
            </Banner>
          ) : onboardingErrorsQuery.data?.archyErrors.length ? (
            <Banner className="text-sm mb-3 rounded" theme="error">
              Please review these issue(s) in your Archy settings to make sure your payroll is accurate.{" "}
              <Link
                className="text-primaryTheme"
                to={paths.settingsSection({ section: "payroll-information" })}
              >
                Review issues
              </Link>
            </Banner>
          ) : null}
          <LayoutCard className="flex-none p-4 flex justify-between">
            <RunPayrollSection
              disabledRunPayroll={
                Boolean(onboardingErrorsQuery.data?.gustoErrorsFromOnboardingSteps.length) ||
                onboardingStatusQuery.data?.status === "NOT_APPROVED"
              }
              unprocessedPayrollsQuery={unprocessedPayrollsQuery}
            />
          </LayoutCard>
          <LayoutCard className="flex-1 min-h-0 flex flex-col border-t border-t-greyLighter">
            <PayrollsTitleBar
              filters={filterParams}
              totalElements={processedPayrollsQuery.apiResponse?.data.pageDetails?.totalElements}
              onUpdatePayrolls={handleUpdatePayrollsList}
            />
            <PersistScrollPosition
              className="flex-1 min-h-0 overflow-y-scroll"
              id="payrollsList"
              resetScrollKey={scrollParams}
            >
              <PayrollsTable payrollsQuery={processedPayrollsQuery} />
            </PersistScrollPosition>

            <TablePagerPageDetails
              page={page}
              pageSize={pageSize}
              pageDetails={processedPayrollsQuery.apiResponse?.data.pageDetails}
              onPageChange={handlePageChange}
              onPageSizeChange={handlePageSizeChange}
            />
          </LayoutCard>
        </div>
      </QueryResult>
    </PinGuard>
  );
};
