import { PatientSummaryVO } from "@libs/api/generated-api";
import { sentenceCase } from "@libs/utils/casing";
import { formatISODate } from "@libs/utils/date";
import { getAgeByDob } from "@libs/utils/formatString";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";
import { useNow } from "hooks/useNow";

interface Props {
  patient: PatientSummaryVO;
}

// eslint-disable-next-line complexity
export const PeekInformationSection: React.FC<Props> = ({ patient }) => {
  const now = useNow();
  const phone = patient.contact.callPhone;
  const [phoneLabel, phoneNumber] = phone ? ["Phone", phone] : ["", ""];

  const email = patient.contact.email;

  return (
    <>
      <span className="text-sm font-sansSemiBold">Information</span>
      <FormFieldInput edit={false} label="Name" value={patient.name.fullDisplayName} />
      <FormFieldInput
        edit={false}
        label="Date of Birth, Age"
        value={`${formatISODate(patient.dob)}${patient.dob ? `, ${getAgeByDob(now, patient.dob)}` : ""}`}
      />
      {patient.gender && <FormFieldInput edit={false} label="Gender" value={sentenceCase(patient.gender)} />}
      <FormFieldInput
        edit={false}
        label="Preferred Contact Method"
        value={
          patient.contact.relation === "SELF"
            ? sentenceCase(patient.contact.preferredContactMode)
            : "Contact Person"
        }
      />
      {phoneLabel && <FormFieldPhoneInput edit={false} label={phoneLabel} value={phoneNumber} />}
      {email && <FormFieldInput edit={false} label="Email" value={email} />}
    </>
  );
};
