import { DarkRoomSkeleton } from "components/PatientProfile/Imaging/MountRoute/DarkRoomSkeleton";

export const LoadingDarkRoom: React.FC = () => {
  return (
    <div className="bg-black min-h-full flex flex-col select-none">
      <div className="w-full h-14 shadow-main flex justify-start items-center">
        <DarkRoomSkeleton className="ml-6 w-40" count={1} />
      </div>
      <div className="flex-1 justify-center items-center flex" />
    </div>
  );
};
