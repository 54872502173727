import { FC } from "react";
import { IPFilterVO } from "@libs/api/generated-api";
import { Banner } from "@libs/components/UI/Banner";
import {
  AddIpAddresses,
  AfterAddingIp,
  EmployeeAccess,
  GlobalAccess,
  WhiteListGlobalIp,
} from "components/Settings/Security/IPAuthorization/Messages";
import { isGlobalIp } from "components/Settings/Security/IPAuthorization/utils";

interface Props {
  ipFilters: IPFilterVO[];
}

export const BannerMessaging: FC<Props> = ({ ipFilters }) => {
  const globalFilter = ipFilters.find((ipFilter) => {
    return isGlobalIp(ipFilter);
  });
  const hasMultipleFilters = ipFilters.length > 1;
  const allEmployeesCanAccessAnywhere = Boolean(globalFilter?.appliesToAll);
  const limitedAccessGlobalOnlyRule = Boolean(globalFilter?.appliesToAll === false && !hasMultipleFilters);

  return allEmployeesCanAccessAnywhere ? (
    <Banner className="rounded" theme="info">
      <GlobalAccess />
    </Banner>
  ) : limitedAccessGlobalOnlyRule ? (
    <Banner className="rounded" theme="warning">
      <WhiteListGlobalIp />
    </Banner>
  ) : ipFilters.length ? (
    <Banner className="rounded" theme="warning">
      <EmployeeAccess />
    </Banner>
  ) : (
    <div className="flex flex-col gap-y-3">
      <Banner className="rounded" theme="info">
        <AddIpAddresses />
      </Banner>
      <Banner className="rounded" theme="warning">
        <AfterAddingIp />
      </Banner>
    </div>
  );
};
