import React from "react";
import { formatCurrency } from "@libs/utils/currency";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { usePick } from "@libs/hooks/usePick";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { ValuesInFilterSelect } from "components/Dashboard/Tables/ValuesInFilterSelect";
import { FooterCell } from "components/Dashboard/Tables/FooterCell";
import { useQueryParams } from "hooks/useQueryParams";
import { useFilterTokenProps } from "components/Dashboard/hooks/useFilterTokenProps";
import { DashboardTableFilters } from "components/Dashboard/Tables/DashboardTableFilters";

import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import { MAX_PAGE_SIZE, MAX_PAGE_SIZE_DOWNLOAD, getTotals } from "components/Dashboard/Tables/utils";
import { UNCOLLECTED_BY_SERVICE_COLUMNS } from "components/Dashboard/OutstandingCollections/constants";
import { DashboardTableTabs } from "components/Dashboard/Tables/DashboardTableTabs";
import { useTableTabProps } from "components/Dashboard/OutstandingCollections/hooks/useTableTabProps";
import { useOutstandingCollectionsSelectedTimeSegment } from "components/Dashboard/OutstandingCollections/hooks/useOutstandingCollectionsSelectedTimeSegment";
import { getUncollectedByServiceInfiniteQuery } from "api/reporting/queries";
import { useDownloadOutstandingCollectionsByServiceCSV } from "components/Dashboard/OutstandingCollections/hooks/useDownloadOutstandingCollectionsByServiceCSV";
import { useFlattenEntries } from "components/Dashboard/hooks/useFlattenEntries";
import { ByServiceRow } from "components/Dashboard/OutstandingCollections/ByServiceRow";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { OutstandingCollectionsQuery } from "utils/routing/dashboard";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";
import { DashboardSelectProviderFilter } from "components/Dashboard/Tables/DashboardSelectProviderFilter";

// eslint-disable-next-line complexity
export const ByServiceTableRoute: React.FC = () => {
  const { updateQuery, query } = useQueryParams("dashboardPaymentOutstanding");

  const { practiceId } = useAccount();
  const sortOrders = query.serviceTableSort;

  const selectedTimeSegment = useOutstandingCollectionsSelectedTimeSegment();
  const [providersQuery] = useApiQueries([getPracticeProvidersQuery({ args: { practiceId } })]);

  const { search, isWaiting } = useDebouncedSearch(query.patientSearch ?? "");
  const outstandingCollectionsByProviderQuery = useInfiniteApiQuery(
    getUncollectedByServiceInfiniteQuery({
      args: {
        practiceId,
        pageSize: MAX_PAGE_SIZE,
        pageNumber: 1,
        data: {
          includeTotal: true,
          ...selectedTimeSegment,
          patientSearchString: search,
          sortOrders,
          filters: query.filters,
        },
      },
    })
  );
  const totals = getTotals(outstandingCollectionsByProviderQuery.data);
  const isLoading =
    isWaiting ||
    outstandingCollectionsByProviderQuery.isLoading ||
    outstandingCollectionsByProviderQuery.isInitialLoading;

  const pagingDetails = getInfiniteQueryPagingDetails(outstandingCollectionsByProviderQuery.data);
  const handleRouteStateChange = React.useCallback(
    (updates: Partial<OutstandingCollectionsQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );

  const filterParams = usePick(query, ["filters", "patientSearch"]);
  const filterProps = useFilterTokenProps({ options: filterParams, providers: providersQuery.data });

  const handleSortClick = React.useCallback(
    (serviceTableSort: ColumnSortOrder[]) => {
      handleRouteStateChange({ serviceTableSort });
    },
    [handleRouteStateChange]
  );
  const tableTabProps = useTableTabProps();
  const { isDownloading, downloadCSV } = useDownloadOutstandingCollectionsByServiceCSV({
    outstandingCollectionsByProviderQuery,
    selectedTimeSegment,
  });
  const rows = useFlattenEntries(outstandingCollectionsByProviderQuery.data);
  const isEmpty = !isLoading && rows.length === 0;

  return (
    <>
      <DashboardTableTabs
        {...tableTabProps}
        onClickDownload={downloadCSV}
        downloadCsvDisabled={(pagingDetails?.totalPages ?? 0) > MAX_PAGE_SIZE_DOWNLOAD}
        isDownloading={isDownloading}
        query={query}
        id="outstanding-collections-by-service-tabs"
      >
        <DashboardSelectProviderFilter
          providers={providersQuery.data}
          includeNoProviderOption
          providerFilterType="providerId"
          onChangeFilters={handleRouteStateChange}
          filters={query.filters}
        />

        <ValuesInFilterSelect
          placeholder="Insurance Balance"
          dashboardFilterType="insuranceBalance"
          valuesIn={["0"]}
          aria-label="Insurance Balance"
          isSearchable={false}
          dashboardFilters={query.filters}
          onChangeFilters={handleRouteStateChange}
        />
      </DashboardTableTabs>
      <DashboardTableFilters {...filterParams}>
        <QueryFilterPills
          {...filterProps}
          numResults={pagingDetails?.totalElements}
          onUpdateParams={handleRouteStateChange}
          onClearAll={() => {
            handleRouteStateChange({ filters: [], patientSearch: undefined });
          }}
        />
      </DashboardTableFilters>
      <DashboardInfiniteTable
        infiniteQuery={outstandingCollectionsByProviderQuery}
        columnWidths={UNCOLLECTED_BY_SERVICE_COLUMNS.map(({ width }) => width)}
        id="uncollectedByProviderTable"
        isEmpty={isEmpty}
        headerRow={
          <DashboardHeaderRow
            headers={UNCOLLECTED_BY_SERVICE_COLUMNS}
            onSortClick={handleSortClick}
            sortOrders={sortOrders}
          />
        }
        footerRow={
          <>
            <FooterCell>Totals</FooterCell>

            <FooterCell className="col-span-4" />
            <FooterCell loading={isLoading} align="right">
              {totals && formatCurrency(totals.totalOutstandingBalance)}
            </FooterCell>
            <FooterCell loading={isLoading} align="right">
              {totals && formatCurrency(totals.totalPatientBalance)}
            </FooterCell>
            <FooterCell loading={isLoading} align="right">
              {totals && formatCurrency(totals.totalInsuranceBalance)}
            </FooterCell>
            <FooterCell loading={isLoading} align="right">
              {totals && formatCurrency(totals.totalUninvoicedBalance)}
            </FooterCell>
            <FooterCell loading={isLoading} align="right">
              {totals && formatCurrency(totals.totalPatientBalance)}
            </FooterCell>
          </>
        }
      >
        {rows.map((row, i) => {
          const key = `uncollected_by_service_${row.patient.id}-${row.appointmentId ?? ""}-${
            row.serviceDate
          }-${row.entryType}-${row.adjustmentUuid ?? ""}-${i}`;

          return <ByServiceRow key={key} data={row} last={i === rows.length - 1} />;
        })}
      </DashboardInfiniteTable>
    </>
  );
};
