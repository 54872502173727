/* eslint-disable react-hooks/rules-of-hooks */
// eslint-disable-next-line unicorn/prefer-node-protocol

import { useCallback } from "react";
import { DEGREES_90 } from "@libs/utils/math";
import { readFileToURL } from "@libs/utils/dataUrl";
import { useSensorCapture } from "components/PatientProfile/Imaging/MountRoute/hooks/useSensorCapture";
import { fetchImageWithAdjustments } from "components/PatientProfile/Imaging/utils/ais";

type CaptureResponse = Promise<
  | {
      url: string;
      w: number;
      h: number;
    }
  | undefined
>;

export const useAISSampleImage = () => {
  const { startSensorCapture, captureState } = useSensorCapture();
  const captureSampleImage = useCallback(
    ({ deviceId }: { deviceId: string }): CaptureResponse => {
      return new Promise((resolve) => {
        return startSensorCapture({
          deviceId,
          onCapture: async ({ result, stopCapture }) => {
            const degrees = result.width < result.height ? DEGREES_90 : undefined;
            const sampleImage = await fetchImageWithAdjustments({
              image: result,
              degrees,
            });
            const base64Image = await readFileToURL(sampleImage.image);

            stopCapture();

            return resolve({ url: base64Image, w: sampleImage.width, h: sampleImage.height });
          },
          onEndCapture: () => resolve(undefined),
        });
      });
    },
    [startSensorCapture]
  );

  return {
    captureState,
    captureSampleImage,
  };
};
