import React from "react";
import { SECOND_IN_MS } from "@libs/utils/date";
import { stopStream } from "./stopStream";

export enum MediaPermissionsStatus {
  granted = "granted",
  error = "error",
  undetermined = "undetermined",
  requesting = "requesting",
}

export enum MediaPermissionsErrors {
  NotFound = "NotFound",
  NotSupported = "NotSupported",
  NotReadable = "NotReadable",
  NotAllowed = "NotAllowed",
  Unhandled = "Unhandled",
}

export type MediaPermissionsState =
  | { status: MediaPermissionsStatus.requesting }
  | { status: MediaPermissionsStatus.granted }
  | { status: MediaPermissionsStatus.undetermined }
  | {
      status: MediaPermissionsStatus.error;
      error: MediaPermissionsErrors;
      info?: string;
    };

export const useMediaPermissions = (constraints: MediaStreamConstraints | null) => {
  const [state, setState] = React.useState<MediaPermissionsState>({
    status: MediaPermissionsStatus.undetermined,
  });

  React.useEffect(() => {
    let timeoutId = 0;
    let mediaStream: null | MediaStream = null;

    if (!constraints) {
      setState({
        status: MediaPermissionsStatus.undetermined,
      });
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (window.navigator.mediaDevices) {
      timeoutId = window.setTimeout(() => {
        setState({
          status: MediaPermissionsStatus.requesting,
        });
      }, SECOND_IN_MS);

      window.navigator.mediaDevices
        .getUserMedia(constraints)
        .then((stream) => {
          mediaStream = stream;
          setState({
            status: MediaPermissionsStatus.granted,
          });
          clearTimeout(timeoutId);
          stopStream(stream);
        })
        .catch((err: Error) => {
          clearTimeout(timeoutId);

          switch (err.name) {
            case "NotFoundError":
            case "DevicesNotFoundError": {
              setState({
                status: MediaPermissionsStatus.error,
                error: MediaPermissionsErrors.NotFound,
              });

              break;
            }
            case "NotReadableError":
            case "TrackStartError": {
              setState({
                status: MediaPermissionsStatus.error,
                error: MediaPermissionsErrors.NotReadable,
              });

              break;
            }
            case "NotAllowedError":
            case "PermissionDeniedError": {
              setState({
                status: MediaPermissionsStatus.error,
                error: MediaPermissionsErrors.NotAllowed,
              });

              break;
            }
            default: {
              setState({
                status: MediaPermissionsStatus.error,
                error: MediaPermissionsErrors.Unhandled,
                info: err.name,
              });
            }
          }
        });
    } else {
      setState({
        status: MediaPermissionsStatus.error,
        error: MediaPermissionsErrors.NotSupported,
      });
    }

    return () => {
      if (timeoutId) {
        window.clearTimeout(timeoutId);
      }

      if (mediaStream) {
        stopStream(mediaStream);
      }
    };
  }, [constraints]);

  return state;
};
