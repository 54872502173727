import { FC, HTMLProps } from "react";

import { ButtonIcon } from "@libs/components/UI/ButtonIcon";

import { ReactComponent as AddFolderIcon } from "@libs/assets/icons/add-folder.svg";
import { ReactComponent as UploadFileIcon } from "@libs/assets/icons/upload-file.svg";
import { ReactComponent as ScanIcon } from "@libs/assets/icons/scan.svg";

import { SearchDocumentAutoComplete } from "./SearchDocumentAutoComplete";

interface Props extends HTMLProps<HTMLDivElement> {
  userId: number;
  practiceId: number;
  hasFolders: boolean;
  onAddFolderClick: Func;
  onUploadFileClick: Func;
  onScanClick: Func;
  onSelectionChange: ({ folderId, docId }: { folderId?: number; docId?: number }) => void;
}

export const HeaderTools: FC<Props> = ({
  userId,
  practiceId,
  hasFolders,
  onAddFolderClick,
  onUploadFileClick,
  onScanClick,
  onSelectionChange,
}) => {
  return (
    <div
      className={`
        flex
        items-center
        justify-between
        h-14
        px-6
        border-slate-200
        border-b
      `}
    >
      <div className="flex text-primaryTheme gap-x-3">
        <ButtonIcon
          tooltip={{ content: "New Folder", theme: "SMALL" }}
          SvgIcon={AddFolderIcon}
          theme="primary"
          onClick={onAddFolderClick}
        />
        <ButtonIcon
          tooltip={{
            content: hasFolders ? "Upload File" : "Create a folder before uploading a file",
            theme: hasFolders ? "SMALL" : "MEDIUM",
          }}
          SvgIcon={UploadFileIcon}
          theme="primary"
          onClick={onUploadFileClick}
          disabled={!hasFolders}
        />
        <ButtonIcon
          tooltip={{
            content: hasFolders ? "Scan Document" : "Create a folder before scanning a document",
            theme: hasFolders ? "SMALL" : "MEDIUM",
          }}
          SvgIcon={ScanIcon}
          theme="primary"
          onClick={onScanClick}
          disabled={!hasFolders}
        />
      </div>
      <div>
        <SearchDocumentAutoComplete
          practiceId={practiceId}
          userId={userId}
          onSelect={(selectedDoc) =>
            onSelectionChange({
              folderId: selectedDoc?.folderId,
              docId: selectedDoc?.id,
            })
          }
        />
      </div>
    </div>
  );
};
