import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { useDebounce } from "use-debounce";
import { PreferenceVO, ProviderVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { formatAsISODate, getLocalDate, getTimeValues } from "@libs/utils/date";
import { toMap } from "@libs/utils/array";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { SEARCH_DEBOUNCE_DELAY_MS } from "@libs/utils/constants";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useAccount } from "@libs/contexts/AccountContext";
import { getPracticeAvailableRoomsQuery, getOpenSlotsQuery, OpenSlotsRequest } from "api/scheduling/queries";
import { SlotPreferences, SlotSelections } from "components/ScheduleAppointments/types";
import {
  endOfDay,
  isBeginningOfDay,
  timePreferenceToTimeString,
} from "components/ScheduleAppointments/timeSlotPreference";
import { useAppointmentDurationContext } from "components/ScheduleAppointments/AppointmentDurationContext";
import { useNow } from "hooks/useNow";

const isAvailableSlotQueryEnabled = (duration: number, slotSelections: SlotSelections) => {
  return Boolean(duration && slotSelections.startTime && slotSelections.date);
};

const getAvailableFilters = (duration: number, slotSelections: SlotSelections) => {
  return {
    appointmentDurationInMinutes: duration,
    startDate: slotSelections.date || "",
    startTime: slotSelections.startTime || "",
  };
};

const getEmptySlotSelections = () => ({
  hygienistId: 0,
  dentistId: 0,
  id: "",
  startTime: "",
  endTime: "",
  date: undefined,
  roomId: 0,
});

const useHasChangedSlotPreferences = (preferences: OpenSlotsRequest) => {
  const [hasChangedPreferences, setHasChangedPreferences] = useState(false);
  const lastRef = useRef(preferences);

  useEffect(() => {
    if (preferences !== lastRef.current) {
      lastRef.current = preferences;
      setHasChangedPreferences(true);
    }
  }, [preferences]);

  return {
    hasChangedPreferences,
    setHasChangedPreferences,
  };
};
const getDefaultSlotPreferences = (fromDate: Date) => ({
  fromDate,
  dentists: null,
  hygienists: null,
  days: null,
  from: {
    hours: 0,
    minutes: 0,
    seconds: 0,
  },
  to: {
    hours: 0,
    minutes: 0,
    seconds: 0,
  },
});

const getSlotPreferences = (fromDate: Date, fromPreferenceVO?: PreferenceVO): SlotPreferences => {
  if (fromPreferenceVO) {
    const timeRange = fromPreferenceVO.timeRanges?.[0];
    const providerArray = fromPreferenceVO.providers ?? [];
    const dentists = toMap(
      providerArray.filter((item) => item.jobCategory === "DENTIST"),
      "id"
    );
    const hygienists = toMap(
      providerArray.filter((item) => item.jobCategory === "HYGIENIST"),
      "id"
    );
    const {
      hours: startHours,
      minutes: startMinutes,
      seconds: startSeconds,
    } = getTimeValues(timeRange?.startTime ?? "00:00:00");
    const {
      hours: endHours,
      minutes: endMinutes,
      seconds: endSeconds,
    } = getTimeValues(timeRange?.endTime ?? "00:00:00");

    return {
      ...getDefaultSlotPreferences(fromDate),
      from: {
        hours: startHours,
        minutes: startMinutes,
        seconds: startSeconds,
      },
      to: {
        hours: endHours,
        minutes: endMinutes,
        seconds: endSeconds,
      },
      dentists,
      hygienists,
      days: fromPreferenceVO.daysPreferred
        ? toMap(
            fromPreferenceVO.daysPreferred.map((value) => ({
              value,
              label: value[0],
            })),
            "value"
          )
        : null,
    };
  }

  return getDefaultSlotPreferences(fromDate);
};

const useSlotPreferenceState = (
  providers: ProviderVO[],
  options: { findFromDate?: string; initialPreferences?: PreferenceVO }
) => {
  const now = useNow();
  const [slotPreferences, updateSlotPreferences] = useObjectState<SlotPreferences>(() =>
    getSlotPreferences(
      options.findFromDate ? getLocalDate(options.findFromDate) : now,
      options.initialPreferences
    )
  );

  const { dentists, hygienists } = useMemo(() => {
    return {
      dentists: providers.filter((provider) => provider.jobCategory === "DENTIST"),
      hygienists: providers.filter((provider) => provider.jobCategory === "HYGIENIST"),
    };
  }, [providers]);

  return {
    updateSlotPreferences,
    slotPreferences,
    dentists,
    hygienists,
  };
};

// this hooks manages all the data fetching and state related to finding an open slot
export const useAppointmentSlotFinder = (providers: ProviderVO[], findFromDate: string | undefined) => {
  const slotPreferenceState = useSlotPreferenceState(providers, { findFromDate });
  const [findSlotSelections, setFindSlotSelections] = useState<SlotSelections>(() =>
    getEmptySlotSelections()
  );
  const duration = useAppointmentDurationContext();
  const { slotPreferences } = slotPreferenceState;
  const apiSlotPreferences = useMemo(() => {
    const { fromDate, dentists, hygienists, days, from, to } = slotPreferences;
    const apiPrefs: OpenSlotsRequest = {
      appointmentDurationInMinutes: duration.read,
      startDate: formatAsISODate(fromDate),
    };

    let providerIds: number[] = [];

    if (dentists) {
      providerIds = Object.keys(dentists).map(Number);
    }

    if (hygienists) {
      providerIds = [...providerIds, ...Object.keys(hygienists).map(Number)];
    }

    if (providerIds.length) {
      apiPrefs.providerIds = providerIds;
    }

    if (days) {
      apiPrefs.daysPreferred = Object.values(days)
        .filter(isDefined)
        .map((day) => day.value);
    }

    if (!isBeginningOfDay(from) || !isBeginningOfDay(to)) {
      apiPrefs.timeRanges = [
        `${timePreferenceToTimeString(from)}-${timePreferenceToTimeString(
          isBeginningOfDay(to) ? endOfDay : to
        )}`,
      ];
    }

    return apiPrefs;
  }, [slotPreferences, duration.read]);

  const lastApiSlotPreferences = useRef(apiSlotPreferences);

  // This value is editable via input so we should debounce to avoid superfluous api calls
  const [throttledDurationInMinutes] = useDebounce(duration.read, SEARCH_DEBOUNCE_DELAY_MS);

  const { practiceId } = useAccount();
  const [openSlotsQuery, availableRoomsQuery] = useApiQueries([
    getOpenSlotsQuery({
      args: { practiceId, filters: apiSlotPreferences },
      queryOptions: { enabled: false, keepPreviousData: true },
    }),
    getPracticeAvailableRoomsQuery({
      args: {
        practiceId,
        filters: getAvailableFilters(throttledDurationInMinutes, findSlotSelections),
      },
      queryOptions: { enabled: isAvailableSlotQueryEnabled(throttledDurationInMinutes, findSlotSelections) },
    }),
  ]);

  const { hasChangedPreferences, setHasChangedPreferences } =
    useHasChangedSlotPreferences(apiSlotPreferences);

  const fetchSlots = openSlotsQuery.refetch;
  const fetchOpenSlots = useCallback(() => {
    setHasChangedPreferences(false);
    fetchSlots();
  }, [fetchSlots, setHasChangedPreferences]);

  // every time a user changes there slot references we need to
  // clear out slot attribute they had already selected.
  // skip initial mount
  useEffect(() => {
    if (lastApiSlotPreferences.current !== apiSlotPreferences) {
      lastApiSlotPreferences.current = apiSlotPreferences;
      setFindSlotSelections(getEmptySlotSelections());
    }
  }, [apiSlotPreferences, setFindSlotSelections]);

  return {
    openSlotsQuery,
    availableRoomsQuery,
    fetchOpenSlots,
    hasChangedPreferences,
    findSlotSelections,
    setFindSlotSelections,
    ...slotPreferenceState,
  };
};

export const useAsapSlotPreferences = (options: {
  providers: ProviderVO[];
  initialState: {
    preference?: PreferenceVO;
    asap: boolean;
  };
}) => {
  const addToAsapList = useBoolean(options.initialState.asap);
  const [asapComment, handleAsapCommentUpdated] = useState(options.initialState.preference?.comment ?? "");

  const slotPreferenceState = useSlotPreferenceState(options.providers, {
    initialPreferences: options.initialState.preference,
  });

  return {
    ...slotPreferenceState,
    addToAsapList,
    asapComment,
    handleAsapCommentUpdated,
  };
};
