import {
  BatchUpdateRecentProcedureHistoryRequest,
  BatchUpsertBenefitLimitationRequest,
  FrequencyLimitVO,
  RecentProcedureHistoryItemVO,
} from "@libs/api/generated-api";
import { ValidationResult } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { BenefitLimitationFormItem } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/useLimitationFields";

export const getLimitationsSchema = () => {
  return {
    $items: {
      serviceName: [
        {
          $error: "Name required",
          $v: required,
        },
      ],
    },
  };
};

export type ProcedureHistoryFormItem = Omit<RecentProcedureHistoryItemVO, "date"> & {
  date?: string;
  dirty?: boolean;
};

export const getLimitationsPutData = (
  stagedLimitations: BenefitLimitationFormItem[]
): BatchUpsertBenefitLimitationRequest => {
  const limitationsToUpdate = stagedLimitations
    .filter((item) => Object.keys(item).length > 0)
    .map(
      ({
        ageMax,
        ageMin,
        serviceName,
        uuid,
        startCdtCodeRange,
        endCdtCodeRange,
        waitingPeriodInMonths,
        downgradeProcedure,
        sameDay,
        notes,
        frequency,
        frequencyLimitationCategory,
        autoEligibility,
      }) => {
        const limitationFrequency: FrequencyLimitVO | undefined = frequency
          ? {
              ...frequency,
              frequencyQuantity: frequency.frequencyQuantity ?? 1,
              limitationType: frequency.limitationType ?? "CALENDAR_YEAR",
              durationType: frequency.durationType ?? "MONTH",
              durationQuantity: frequency.durationQuantity ?? 1,
            }
          : undefined;
        const benefitRequest = {
          uuid,
          // Validation has run for these fields, so we cast them:
          startCdtCodeRange: startCdtCodeRange as number,
          serviceName: serviceName as string,
          // -----
          endCdtCodeRange,
          waitingPeriodInMonths,
          downgradeProcedureId: downgradeProcedure?.id,
          ageMax,
          ageMin,
          sameDay,
          notes,
          frequency: limitationFrequency,
          frequencyLimitationCategoryId: frequencyLimitationCategory?.id,
          autoEligibility,
        };

        return benefitRequest;
      }
    );

  return {
    benefits: limitationsToUpdate,
  };
};

export const getProcedureHistoryPutData = (
  stagedHistory: ProcedureHistoryFormItem[]
): BatchUpdateRecentProcedureHistoryRequest => {
  const requests: RecentProcedureHistoryItemVO[] = stagedHistory.map((item) => {
    return {
      ...item,
      date: item.date,
    };
  });

  return {
    requests,
  };
};

export type LimitationValidationResult = ValidationResult<
  BenefitLimitationFormItem[],
  ReturnType<typeof getLimitationsSchema>
>;
