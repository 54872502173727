import { FC, useCallback } from "react";
import { WalletActivityVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { useBoolean } from "@libs/hooks/useBoolean";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { isOneOf } from "@libs/utils/isOneOf";
import { getFullUrl } from "@libs/utils/location";
import { pluralize } from "@libs/utils/pluralize";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { ReactComponent as PreviewFileIcon } from "@libs/assets/icons/preview-file.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as RefundIcon } from "@libs/assets/icons/refund.svg";
import { IconsCell, Row, TextCell } from "@libs/components/UI/GridTableComponents";
import { Badge, BadgeColor } from "@libs/components/UI/Badge";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import { ViewPrePaymentReceiptModal } from "components/PatientProfile/Billing/ViewPrePaymentReceiptModal";
import { EditInvoicePaymentModal } from "components/PatientProfile/Billing/EditInvoicePaymentModal";
import { EditWalletActivityModal } from "components/PatientProfile/Billing/EditWalletActivityModal";
import {
  getPaymentMethodToLabel,
  isPaymentEditableChecker,
} from "components/PatientProfile/Billing/billingUtils";
import { RefundPrePaymentModal } from "components/PatientProfile/Billing/RefundPrePaymentModal";
import { PaymentNoteIcon } from "components/PatientProfile/Billing/Ledger/LedgerComponents";
import { paths } from "utils/routing/paths";
import { RoleConditionHandler } from "components/Roles/roleUtils";
import { RoleGuardClick } from "components/Main/RoleGuard";

const walletActivityStatuses: Record<WalletActivityVO["walletActivityType"], string> = {
  TOP_UP: "Deposit",
  WITHDRAW: "Withdrawal",
  CREDIT: "Credit",
  REFUND: "Refund",
};

const statusToBadgeLabel: Partial<Record<WalletActivityVO["walletActivityState"], BadgeColor>> = {
  FINALIZED: "green",
  FAILED: "red",
  PENDING: "orange",
};

interface Props {
  walletActivity: WalletActivityVO;
}

const useWalletActivityRow = ({ walletActivity }: Props) => {
  const receiptModal = useBoolean(false);
  const editModal = useBoolean(false);
  const refundModal = useBoolean(false);

  const canRefund =
    walletActivity.walletActivityType === "TOP_UP" &&
    (walletActivity.payment?.refundableCurrencyAmount?.amount ?? 0) > 0;

  const isRefund = walletActivity.walletActivityType === "REFUND";

  return {
    walletActivity,
    receiptModal,
    editModal,
    refundModal,
    rowColor:
      walletActivity.walletActivityType === "TOP_UP"
        ? "text-greenDark"
        : walletActivity.walletActivityType === "REFUND"
          ? "text-blue"
          : "",
    canEdit: walletActivity.payment && walletActivity.payment.permittedActions.includes("EDIT"),
    canRefund,
    isRefund,
    canViewReceipt: walletActivity.walletActivityState === "FINALIZED",
    note: walletActivity.payment?.note,
  };
};

// eslint-disable-next-line complexity
export const WalletActivityRow: FC<Props> = (props) => {
  const {
    walletActivity,
    receiptModal,
    editModal,
    refundModal,
    rowColor,
    canEdit,
    canViewReceipt,
    canRefund,
    isRefund,
    note,
  } = useWalletActivityRow(props);

  const formattedInvoiceNumbers = `${pluralize(
    walletActivity.payment?.invoiceNumbers.length ?? 0,
    "invoice",
    "invoices"
  )} ${walletActivity.payment?.invoiceNumbers.map((num) => `#${num}`).join(", ") ?? ""}`;

  const paymentEditableChecker: RoleConditionHandler = useCallback(
    (condition) => isPaymentEditableChecker(walletActivity.payment, condition),
    [walletActivity.payment]
  );

  const conditionReason = "This payment is no longer editable.";

  return (
    <Row className={rowColor}>
      {/* Actions */}
      <IconsCell>
        {canEdit &&
          (isOneOf(walletActivity.walletActivityType, ["TOP_UP", "REFUND"]) ? (
            <RoleGuardClick
              domain="BILLING"
              action="EDIT_PAYMENT"
              onRoleCondition={paymentEditableChecker}
              conditionReason={conditionReason}
            >
              <ButtonIcon
                theme="primary"
                tooltip={{ content: isRefund ? "Edit Refund" : "Edit Payment", theme: "SMALL" }}
                SvgIcon={EditIcon}
                onClick={editModal.on}
              />
            </RoleGuardClick>
          ) : (
            <RoleGuardClick
              domain="BILLING"
              action="EDIT_PAYMENT"
              onRoleCondition={paymentEditableChecker}
              conditionReason={conditionReason}
            >
              <LinkIcon
                theme="primary"
                tooltip={{ content: isRefund ? "Edit Refund" : "Edit Payment", theme: "SMALL" }}
                SvgIcon={EditIcon}
                to={paths.editPayment(
                  {
                    patientId: walletActivity.patientId,
                    paymentUuid: walletActivity.payment?.batchPaymentUuid ?? walletActivity.payment?.uuid,
                  },
                  { from: getFullUrl(location) }
                )}
              />
            </RoleGuardClick>
          ))}
        {canRefund && (
          <RoleGuardClick domain="BILLING" action="CREATE_REFUND">
            <ButtonIcon
              SvgIcon={RefundIcon}
              theme="primary"
              tooltip={{ content: "Refund", theme: "SMALL" }}
              onClick={refundModal.on}
            />
          </RoleGuardClick>
        )}
        {canViewReceipt &&
          (isOneOf(walletActivity.walletActivityType, ["TOP_UP", "REFUND"]) ? (
            <ButtonIcon
              theme="primary"
              tooltip={{ content: "View Receipt", theme: "SMALL" }}
              SvgIcon={PreviewFileIcon}
              onClick={receiptModal.on}
            />
          ) : (
            <LinkIcon
              theme="primary"
              tooltip={{ content: "View Receipt", theme: "SMALL" }}
              SvgIcon={PreviewFileIcon}
              to={paths.viewPayment(
                {
                  patientId: walletActivity.patientId,
                  paymentUuid: walletActivity.payment?.uuid,
                },
                { from: getFullUrl(location) }
              )}
            />
          ))}
      </IconsCell>

      {/* Date */}
      <TextCell>
        <DateWithTooltip date={walletActivity.activityCreatedAt} dateAsSeconds={true} format="P" />
      </TextCell>

      {/* Payment Type */}
      <TextCell>{walletActivityStatuses[walletActivity.walletActivityType]}</TextCell>

      {/* Payment Method */}
      <TextCell>{walletActivity.payment && getPaymentMethodToLabel(walletActivity.payment)}</TextCell>

      {/* Description */}
      <TextCell>
        <div className="flex justify-between">
          <div>
            {isOneOf(walletActivity.walletActivityType, ["TOP_UP", "REFUND"])
              ? walletActivity.note
              : walletActivity.walletActivityType === "CREDIT"
                ? `Refund of ${formattedInvoiceNumbers} credited to wallet`
                : `For ${formattedInvoiceNumbers}`}
          </div>
          <div>
            {walletActivity.walletActivityState !== "FINALIZED" && (
              <Badge
                label={sentenceCaseConstant(walletActivity.walletActivityState)}
                type="pill"
                color={statusToBadgeLabel[walletActivity.walletActivityState]}
              />
            )}
          </div>
        </div>
      </TextCell>

      {/* Internal notes */}
      <IconsCell>{note && <PaymentNoteIcon note={note} />}</IconsCell>

      {/* Amount */}
      <TextCell className="text-right">{formatCurrency(walletActivity.currencyAmount.amount)}</TextCell>
      {editModal.isOn && isOneOf(walletActivity.walletActivityType, ["WITHDRAW", "CREDIT"])
        ? walletActivity.payment && (
            <RoleGuardClick
              domain="BILLING"
              action="EDIT_PAYMENT"
              onRoleCondition={paymentEditableChecker}
              conditionReason={conditionReason}
            >
              <EditInvoicePaymentModal
                patientId={walletActivity.patientId}
                paymentUuid={walletActivity.payment.batchPaymentUuid ?? walletActivity.payment.uuid}
                invoiceUuid={walletActivity.payment.paymentReference.uuid}
                onSave={editModal.off}
                onClose={editModal.off}
              />
            </RoleGuardClick>
          )
        : editModal.isOn && isOneOf(walletActivity.walletActivityType, ["TOP_UP", "REFUND"])
          ? walletActivity.payment && (
              <RoleGuardClick
                domain="BILLING"
                action="EDIT_PAYMENT"
                onRoleCondition={paymentEditableChecker}
                conditionReason={conditionReason}
              >
                <EditWalletActivityModal
                  patientId={walletActivity.patientId}
                  walletUuid={walletActivity.walletUuid}
                  walletActivityId={walletActivity.id}
                  onSave={editModal.off}
                  onClose={editModal.off}
                />
              </RoleGuardClick>
            )
          : null}

      {receiptModal.isOn && walletActivity.payment && (
        <ViewPrePaymentReceiptModal
          walletActivityNote={walletActivity.note}
          patientId={walletActivity.patientId}
          onClose={receiptModal.off}
          paymentUuid={walletActivity.payment.uuid}
        />
      )}

      {refundModal.isOn && (
        <RefundPrePaymentModal
          onClose={refundModal.off}
          walletActivityId={walletActivity.id}
          patientId={walletActivity.patientId}
          walletUuid={walletActivity.walletUuid}
          onRefund={refundModal.off}
        />
      )}
    </Row>
  );
};
