import { FC, useState } from "react";
import { cx } from "@libs/utils/cx";
import { pluralize } from "@libs/utils/pluralize";
import { Button } from "@libs/components/UI/Button";
import { FormFieldChildrenContainer } from "@libs/components/UI/FormField";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";
import { ToggleGroup } from "components/UI/ToggleGroup";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";

export const TimeLimitModal: FC<{
  value: number | undefined;
  onSave: (limit: undefined | number) => void;
  onClose: Func;
}> = ({ value, onSave, onClose }) => {
  const [limitType, setLimitType] = useState<"ANY_TIME" | "WITHIN">(value ? "WITHIN" : "ANY_TIME");
  const [daysLimit, setDaysLimit] = useState<number>(value ?? 0);

  return (
    <Modal title="Time Limit" onClose={onClose} size="3xs">
      <ModalContent padding="mdEven" className="flex flex-col gap-y-3">
        <div className="text-xs">
          You may specify a length of time after a payment is created in which the user can make an edit.
        </div>
        <div className="flex items-center gap-x-3">
          <ToggleGroup
            selectedValue={limitType}
            options={[
              {
                value: "ANY_TIME",
                label: "Any Time",
              },
              {
                value: "WITHIN",
                label: "Within",
              },
            ]}
            size="md"
            onChange={(e, option) => setLimitType(option.value)}
          />
          <FormFieldNumberInput
            className="w-24 h-8"
            disabled={limitType === "ANY_TIME"}
            value={String(daysLimit)}
            onChange={(e) => setDaysLimit(Number(e.target.value || "0"))}
          >
            <FormFieldChildrenContainer align="center">
              <div className={cx("text-xs font-sansSemiBold", limitType === "ANY_TIME" && "text-slate-400")}>
                {pluralize(daysLimit, "Day", "Days")}
              </div>
            </FormFieldChildrenContainer>
          </FormFieldNumberInput>
        </div>
      </ModalContent>
      <ModalFooter actions>
        <Button
          className="min-w-button"
          onClick={() => onSave(limitType === "ANY_TIME" ? undefined : daysLimit)}
        >
          Save
        </Button>
      </ModalFooter>
    </Modal>
  );
};
