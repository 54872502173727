import { FC } from "react";
import Skeleton from "react-loading-skeleton";

import { RankedPatientMessageVO } from "@libs/api/generated-api";
import { formatUnixTimestampRelative } from "@libs/utils/date";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";

import { MessagingContact } from "components/Messaging/MessagingContact";

import { useNow } from "hooks/useNow";

const RelativeLocaleFormat = {
  today: "p",
  tomorrow: "'Tomorrow,' MMM d",
  nextWeek: "MMM d",
  yesterday: "'Yesterday'",
  lastWeek: "MMM d",
  other: "PP",
};

const getSegmentLabel = (contacts: RankedPatientMessageVO[], isRead: boolean, index: number) => {
  if (index === 0) {
    // First label will always be either Recent or Unread
    return contacts[0].isRead ? "Recent" : "Unread";
  }

  if (!contacts[index - 1].isRead && isRead) {
    // Second label will be Recent if previous is unread and current is read
    return "Recent";
  }

  return null;
};

interface Props {
  practiceContactMessagesQuery: UseInfiniteApiQueryResult<RankedPatientMessageVO[]>;
  selectedPatientId: number | undefined;
  onSelectContact: (patientId: number, isRead: boolean) => void;
  onChangeReadStatus: (patientId: number, isRead: boolean) => void;
  isSearch: boolean;
}

export const MessagingContactsList: FC<Props> = ({
  practiceContactMessagesQuery,
  selectedPatientId,
  onSelectContact,
  onChangeReadStatus,
  isSearch,
}) => {
  const { rootRef, scrollRef } = useInfiniteScrollQuery({
    infiniteQuery: practiceContactMessagesQuery,
  });

  const contacts = useFlattenPages(practiceContactMessagesQuery.data) ?? [];

  const now = useNow();

  return (
    <PersistScrollPosition ref={rootRef} id="messaging-contacts" className="h-full px-3 overflow-y-auto">
      <ScrollableInfiniteQueryResult
        scrollRef={scrollRef}
        infiniteQuery={practiceContactMessagesQuery}
        loading={<Skeleton className="h-14 my-1" count={5} />}
        loadMore={<Skeleton className="h-14 my-1" count={2} />}
      >
        <div data-testid="messaging-contacts-list">
          {contacts.map((contact, index) => {
            const { id, patientId, patientName, patientThumbnail, content, timestamp, isRead } = contact;
            const key = `${id ?? 0}_${patientId}_${index}`;
            // BE exposes isRead as null in cases where no patient messages are
            // found (e.g. a conversation only containing messages sent from the
            // practice, but no messages from the patient). In this scenario, we
            // use nullish coalescing to default to true as to not display an
            // "unread" indicator.
            const isMarkedRead = isRead ?? true;
            const segmentLabel = getSegmentLabel(contacts, isMarkedRead, index);

            return (
              <div key={key} className="flex flex-col pb-2">
                {segmentLabel && !isSearch ? (
                  <span className="font-sansSemiBold text-xxs px-3 py-2">{segmentLabel}</span>
                ) : null}

                <MessagingContact
                  contactName={patientName}
                  avatar={patientThumbnail}
                  message={content ?? ""}
                  timestamp={formatUnixTimestampRelative(timestamp ?? 0, now, RelativeLocaleFormat)}
                  isSelected={patientId === selectedPatientId}
                  isRead={isMarkedRead}
                  isSearch={isSearch}
                  onSelectContact={() => onSelectContact(patientId, isMarkedRead)}
                  onChangeReadStatus={() => onChangeReadStatus(patientId, isMarkedRead)}
                />
              </div>
            );
          })}
        </div>
      </ScrollableInfiniteQueryResult>
    </PersistScrollPosition>
  );
};
