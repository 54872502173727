import { useState, useMemo, useCallback } from "react";

import { blobToFile } from "@libs/utils/dataUrl";
import { MINUTE_IN_SECONDS, SECOND_IN_MS } from "@libs/utils/date";
import { ScannerSettings } from "components/UserDocuments/useScannerSettings";

import { handleError } from "utils/handleError";
import { useImagingHub } from "components/ImageCapturing/ImagingHubContext";
import { IMAGING_SERVICE_DOWNLOAD_URL, ImagingHttpApi, isHubErrorResponse } from "api/imaging/imaging-hub";

const colorModeToPixelMode = {
  BW: "BlackWhite" as const,
  RGB: "RGB" as const,
  GRAY: "Gray" as const,
};

const SCANNER_TIMEOUT_MS = MINUTE_IN_SECONDS * SECOND_IN_MS;

// DPI for scanner
const DEFAULT_RESOLUTION = 150;

export const useArchyImagingServiceScanner = ({
  scanAs,
  filename,
  onScanFiles,
  onRequestClose,
  scannerSettings,
}: {
  filename?: string;
  scanAs: "PDF" | "JPG";
  onScanFiles: (files: File[]) => void | Promise<void>;
  onRequestClose: Func;
  scannerSettings: ScannerSettings;
}) => {
  const { availableDevicesQuery, status, hub } = useImagingHub();

  const [isScanning, setIsScanning] = useState(false);

  const deviceOptions = useMemo(() => {
    return (
      availableDevicesQuery.data
        ?.filter((device) => device.driverType === "Twain" || device.driverType === "Wia")
        .map((device, index) => {
          return { label: `${device.name} (${device.driverType.toUpperCase()})`, value: index };
        }) ?? []
    );
  }, [availableDevicesQuery.data]);
  const selectedDevice = useMemo(
    () => availableDevicesQuery.data?.find((_, index) => index === scannerSettings.preferredDeviceIndex),
    [availableDevicesQuery.data, scannerSettings.preferredDeviceIndex]
  );

  const canScanDocument = status.isRunning && selectedDevice;

  const handleScanDocument = useCallback((): {
    promise: Promise<void>;
    abortController?: AbortController;
  } => {
    if (!selectedDevice) {
      return { promise: Promise.resolve() };
    }

    setIsScanning(true);

    const abortController = new AbortController();
    // AbortSignal.timeout() is not supported in Firefox 96, Edge 97, Chrome 97
    // Basically browsers started supporting this early to late 2022
    // eslint-disable-next-line compat/compat
    const timeoutSignal = AbortSignal.timeout(SCANNER_TIMEOUT_MS);

    const runScan = async () => {
      try {
        const result = await hub.acquireImage({
          driverType: selectedDevice.driverType,
          deviceName: selectedDevice.name,
          config: {
            pixelType: colorModeToPixelMode[scannerSettings.colorMode],
            useAutoDocumentFeeder: scannerSettings.useFeeder,
            useDuplex: scannerSettings.useDuplex,
            autoDiscardBlankPages: scannerSettings.autoDiscardBlankPages,
            showUI: false,
            resolution: DEFAULT_RESOLUTION,
          },
          // eslint-disable-next-line compat/compat
          abortSignal:
            "any" in AbortSignal
              ? AbortSignal.any([abortController.signal, timeoutSignal])
              : abortController.signal,
        });

        if (isHubErrorResponse(result)) {
          throw result;
        } else if ("data" in result) {
          const scannedCount = result.data.imageCount;

          const imageResponses =
            scanAs === "JPG"
              ? await Promise.all(
                  Array.from({ length: scannedCount }).map((_, imageIndex) =>
                    ImagingHttpApi.images.getImageFromBuffer({
                      bufferOutputType: "JPEG",
                      imageIndex,
                    })
                  )
                )
              : [await ImagingHttpApi.images.getImageFromBuffer({ bufferOutputType: "PDF" })];

          const files = await Promise.all(
            imageResponses.map(async (response) => {
              const imageBlob = await response.blob();

              return scanAs === "PDF"
                ? blobToFile(imageBlob, filename ?? "Untitled.pdf")
                : blobToFile(imageBlob, filename ?? "Untitled.jpg");
            })
          );

          await onScanFiles(files);

          hub.clearImageBuffer();
        }

        onRequestClose();
      } catch (err) {
        hub.closeConnection();
        handleError(err);
      } finally {
        setIsScanning(false);
      }
    };

    return {
      abortController,
      promise: runScan(),
    };
  }, [
    selectedDevice,
    hub,
    scannerSettings.colorMode,
    scannerSettings.useFeeder,
    scannerSettings.useDuplex,
    scannerSettings.autoDiscardBlankPages,
    onRequestClose,
    scanAs,
    onScanFiles,
    filename,
  ]);

  return {
    handleScanDocument,
    deviceOptions,
    isScanning,
    canScanDocument,
    isLoadingSources: false,
    driverDownloadUrl: status.isRunning ? null : IMAGING_SERVICE_DOWNLOAD_URL,
  };
};
