import React, { useCallback, useMemo, useState } from "react";
import { ClaimLineItemData, ClaimLineItemVO, DentalProcedureVO } from "@libs/api/generated-api";
import { isNumber } from "@libs/utils/types";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as PlusCircleIcon } from "@libs/assets/icons/plus-circle.svg";
import { ReactComponent as NoteIcon } from "@libs/assets/icons/note.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { ActionModal } from "components/UI/ActionModal";
import { RemarksModalContent } from "components/Claim/RemarksModalContent";
import { updateEobClaimLineItem } from "api/claim/mutations";
import { handleError } from "utils/handleError";
import { useItemModal } from "hooks/useItemModal";

interface Props {
  claimLineItem: ClaimLineItemVO;
  claimUuid: string;
  disableActions: boolean;
  lineItemIndex?: number;
  onAdjustmentRemarkChange?:
    | ((
        lineItemIndex: number,
        updates: {
          downgradedCdtCode?: string;
          remarks?: string;
        }
      ) => void)
    | undefined;
  version: number;
}

export const ProcedureTableRowRemarksCell: React.FC<Props> = ({
  claimLineItem,
  claimUuid,
  disableActions,
  lineItemIndex,
  onAdjustmentRemarkChange,
  version,
}) => {
  const { practiceId } = useAccount();

  const remarksModal = useItemModal<number>(null);
  const [draftRemarks, setDraftRemarks] = useState(claimLineItem.remarks);
  const [procedureDowngradeChecked, setProcedureDowngradeChecked] = useState(
    Boolean(claimLineItem.downgradedCdtCode)
  );
  const [draftCdtCode, setDraftCdtCode] = useState(claimLineItem.downgradedCdtCode);

  const handleUpdateRemarks = useCallback((event: React.ChangeEvent<HTMLTextAreaElement>) => {
    setDraftRemarks(event.target.value);
  }, []);

  const toggleProcedureDowngrade = useCallback(() => {
    if (procedureDowngradeChecked) {
      setDraftCdtCode(undefined);
    }

    setProcedureDowngradeChecked((last) => !last);
  }, [procedureDowngradeChecked]);

  const handleUpdateCdtCode = useCallback((newProcedure: DentalProcedureVO | undefined) => {
    setDraftCdtCode(newProcedure?.cdtCode);
  }, []);

  const removeDowngradedProcedureCode = useMemo(() => {
    return !procedureDowngradeChecked || !draftCdtCode;
  }, [draftCdtCode, procedureDowngradeChecked]);

  const [updateClaimLineItemsMutation] = useApiMutations([updateEobClaimLineItem]);

  const handleSaveRemarks = useCallback(async () => {
    if (onAdjustmentRemarkChange && isNumber(lineItemIndex)) {
      onAdjustmentRemarkChange(lineItemIndex, { downgradedCdtCode: draftCdtCode, remarks: draftRemarks });
    } else {
      const lineItemRemarkUpdate: ClaimLineItemData = {
        patientProcedureId: claimLineItem.patientProcedureId,
        remarks: draftRemarks,
        version,
      };

      if (removeDowngradedProcedureCode) {
        lineItemRemarkUpdate.downgradedCdtCode = "";
      } else if (draftCdtCode) {
        lineItemRemarkUpdate.downgradedCdtCode = draftCdtCode;
      }

      if (draftRemarks || draftCdtCode || removeDowngradedProcedureCode) {
        try {
          await updateClaimLineItemsMutation.mutateAsync({
            practiceId,
            claimUuid,
            data: {
              lineItems: [lineItemRemarkUpdate],
              remarksOrDowngradeCodeOnly: true,
            },
          });
        } catch (err) {
          handleError(err);
        }
      }
    }

    remarksModal.close();
  }, [
    claimLineItem.patientProcedureId,
    claimUuid,
    draftCdtCode,
    draftRemarks,
    lineItemIndex,
    onAdjustmentRemarkChange,
    practiceId,
    remarksModal,
    removeDowngradedProcedureCode,
    updateClaimLineItemsMutation,
    version,
  ]);

  const handleCancel = useCallback(() => {
    setDraftRemarks(claimLineItem.remarks);
    setDraftCdtCode(claimLineItem.downgradedCdtCode);
    setProcedureDowngradeChecked(Boolean(claimLineItem.downgradedCdtCode));
    remarksModal.close();
  }, [claimLineItem.downgradedCdtCode, claimLineItem.remarks, remarksModal]);

  return (
    <>
      <ButtonIcon
        disabled={disableActions}
        SvgIcon={draftRemarks || draftCdtCode ? NoteIcon : PlusCircleIcon}
        onClick={() => remarksModal.open(claimLineItem.patientProcedureId)}
        theme="primary"
        tooltip={{ content: draftRemarks || draftCdtCode ? "Edit" : "Add", theme: "SMALL" }}
        size="sm"
      />

      {remarksModal.isOpen && (
        <ActionModal
          handleClose={handleCancel}
          handleSave={handleSaveRemarks}
          primaryButtonText="Save"
          size="sm"
          title="Remarks"
        >
          <RemarksModalContent
            claimLineItem={claimLineItem}
            draftCdtCode={draftCdtCode}
            draftRemarks={draftRemarks}
            handleUpdateCdtCode={handleUpdateCdtCode}
            handleUpdateRemarks={handleUpdateRemarks}
            procedureDowngradeChecked={procedureDowngradeChecked}
            toggleProcedureDowngrade={toggleProcedureDowngrade}
          />
        </ActionModal>
      )}
    </>
  );
};
