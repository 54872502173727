import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { format, parseISO } from "date-fns";

import { RecallVO } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { ReactComponent as Messages } from "@libs/assets/icons/messages.svg";
import { ReactComponent as CalendarPlus } from "@libs/assets/icons/add-appointment.svg";

import { usePathParams } from "hooks/usePathParams";
import { paths } from "utils/routing/paths";

type Props = Pick<RecallVO, "scheduledDate" | "scheduledAppointmentId" | "dueDate">;

export const RecallScheduleDate: FC<Props> = ({ scheduledDate, scheduledAppointmentId, dueDate }) => {
  const location = useLocation();
  const { patientId } = usePathParams("patient");

  return (
    <div className="flex items-center font-sans text-xs text-greyDark">
      <span className="font-sansSemiBold mr-1">Sched</span>
      {scheduledDate && scheduledAppointmentId ? (
        <Link
          className="text-primaryTheme"
          to={paths.schedule({ date: scheduledDate, patientId, appointmentId: scheduledAppointmentId })}
        >
          {format(parseISO(scheduledDate), "MM/dd/yyyy")}
        </Link>
      ) : (
        <>
          <LinkIcon
            SvgIcon={Messages}
            className="ml-1"
            to={paths.messaging({ patientId })}
            tooltip={{ content: "Send Text", theme: "SMALL" }}
            theme="primary"
          />
          <LinkIcon
            SvgIcon={CalendarPlus}
            className="ml-2"
            to={paths.addPatientAppointment({ patientId, findFromDate: dueDate, from: getFullUrl(location) })}
            tooltip={{ content: "Add Appointment", theme: "SMALL" }}
            theme="primary"
          />
        </>
      )}
    </div>
  );
};
