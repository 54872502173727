import { FC, FormEvent, useCallback, useEffect, useMemo, useState } from "react";
import {
  AppointmentClaimSummaryVO,
  ClaimVO,
  ClaimsConfigVO,
  PatientInsuranceVO,
  ProviderVO,
} from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { useNavigate } from "react-router-dom";
import { getFullUrl } from "@libs/utils/location";
import { isDefined } from "@libs/utils/types";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { RadioList } from "@libs/components/UI/RadioList";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Banner } from "@libs/components/UI/Banner";
import { AppointmentClaimSummaryCard } from "components/Claims/Claims/AppointmentClaimSummaryCard";
import { FlyoverContent, FlyoverFooter, FlyoverForm } from "components/UI/FlyoverComponents";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import {
  InsuranceOrdinalOption,
  LoadingOptions,
  PatientInsuranceOption,
} from "components/Claims/Claims/CreateClaimComponents";
import { isDraftClaim, isSubmittedClaim, isSubmittingClaim, isCompletedClaim } from "components/Claims/utils";
import { AppointmentSummary, CreateClaimButtonText } from "components/Claims/Claims/createClaimTypes";
import { useCreateClaim } from "components/Claims/Claims/useCreateClaim";
import { handleError } from "utils/handleError";
import { paths } from "utils/routing/paths";
import { createClaim } from "api/claim/mutations";

interface Props {
  appointment: AppointmentSummary;
  billingProvidersQuery: ApiQueryResult<ProviderVO[]>;
  claimsConfigQuery: ApiQueryResult<ClaimsConfigVO>;
  onCancel: Func;
}

export type CreateClaimState = Partial<
  Pick<ClaimVO, "billingProviderId" | "insuranceCarrier" | "insuranceCarrierId" | "insuranceOrdinal"> & {
    patientInsuranceId?: number;
    patientInsuranceOrdinal?: PatientInsuranceVO["insuranceOrdinal"];
    primaryClaimUuid?: string;
  }
>;

export const CreateClaimContent: FC<Props> = ({
  appointment,
  billingProvidersQuery,
  claimsConfigQuery,
  onCancel,
}) => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const [state, setState] = useState<CreateClaimState>({ insuranceOrdinal: "PRIMARY" });

  const handleUpdateState = useCallback(
    (updates: CreateClaimState) => setState((last) => ({ ...last, ...updates })),
    []
  );

  // Set billing Provider ID based on claims config/primary claim.
  useEffect(() => {
    if (state.insuranceOrdinal !== "PRIMARY") {
      const primaryClaim = appointment.claims?.find((claim) => claim.claimUuid === state.primaryClaimUuid);

      if (primaryClaim) {
        handleUpdateState({ billingProviderId: primaryClaim.billingProviderName.id });
      }

      return;
    }

    const claimsConfig = claimsConfigQuery.data;

    if (claimsConfig) {
      const billingProviderType = claimsConfig.billingProviderType;

      switch (billingProviderType) {
        case "BUSINESS": {
          handleUpdateState({ billingProviderId: 0 });

          break;
        }
        case "INDIVIDUAL": {
          handleUpdateState({ billingProviderId: claimsConfig.billingProviderId });

          break;
        }
        case "TREATING": {
          handleUpdateState({ billingProviderId: appointment.dentist.id });

          break;
        }
        // No default
      }
    }
  }, [
    appointment.claims,
    appointment.dentist.id,
    claimsConfigQuery.data,
    handleUpdateState,
    state.insuranceOrdinal,
    state.primaryClaimUuid,
  ]);

  const { completedClaims, draftClaims, primaryClaims, submittedClaims } = useMemo(() => {
    const completed: AppointmentClaimSummaryVO[] = [];
    const drafts: AppointmentClaimSummaryVO[] = [];
    const primaries: AppointmentClaimSummaryVO[] = [];
    const submitted: AppointmentClaimSummaryVO[] = [];

    appointment.claims?.forEach((claim) => {
      if (isDraftClaim(claim)) {
        drafts.push(claim);
      } else if (isSubmittedClaim(claim) || isSubmittingClaim(claim)) {
        submitted.push(claim);
      } else if (isCompletedClaim(claim)) {
        completed.push(claim);
      }

      if (claim.insuranceOrdinal === "PRIMARY") {
        primaries.push(claim);
      }
    });

    return {
      completedClaims: completed,
      draftClaims: drafts,
      primaryClaims: primaries,
      submittedClaims: submitted,
    };
  }, [appointment.claims]);

  const insuranceOrdinalOptions = useMemo(() => {
    const disabledNonPrimary = !primaryClaims.length;

    return [
      {
        label: <InsuranceOrdinalOption insuranceOrdinal="PRIMARY" />,
        value: "PRIMARY" as const,
      },
      {
        disabled: disabledNonPrimary,
        label: <InsuranceOrdinalOption disabled={disabledNonPrimary} insuranceOrdinal="SECONDARY" />,
        value: "SECONDARY" as const,
      },
      {
        disabled: disabledNonPrimary,
        label: <InsuranceOrdinalOption disabled={disabledNonPrimary} insuranceOrdinal="OTHER" />,
        value: "OTHER" as const,
      },
    ];
  }, [primaryClaims.length]);

  const patientInsuranceOptions = useMemo(() => {
    return appointment.patientInsurances?.map((insurance) => ({
      insurance,
      label: (
        <>
          {insurance.subscriber.carrier}
          &nbsp;&bull;&nbsp;
          {sentenceCaseConstant(insurance.insuranceOrdinal)}
        </>
      ),
      value: insurance.id,
    }));
  }, [appointment.patientInsurances]);

  const updatePatientInsurance = useCallback(
    (val: number) => {
      const matchedPatientInsurance = appointment.patientInsurances?.find((ins) => ins.id === val);
      const insuranceCarrierId = matchedPatientInsurance?.subscriber.carrierId;
      const insuranceCarrier = matchedPatientInsurance?.subscriber.carrier;
      const patientInsuranceOrdinal = matchedPatientInsurance?.insuranceOrdinal;

      handleUpdateState({
        insuranceCarrier,
        insuranceCarrierId,
        patientInsuranceId: val,
        patientInsuranceOrdinal,
      });
    },
    [appointment.patientInsurances, handleUpdateState]
  );

  const billingProvidersOptions = useMemo(
    () =>
      billingProvidersQuery.data?.map((provider) => ({
        label: provider.name.fullDisplayName,
        value: provider.id,
      })),
    [billingProvidersQuery.data]
  );

  const { submitButtonText, warningMessages, viewClaimUuid } = useCreateClaim({
    appointment,
    completedClaims,
    draftClaims,
    state,
    submittedClaims,
  });

  const submitButtonDisabledText = useMemo(() => {
    if (
      !state.insuranceCarrierId ||
      (!isDefined(state.billingProviderId) && submitButtonText === CreateClaimButtonText.CREATE) ||
      (state.insuranceOrdinal !== "PRIMARY" &&
        !state.primaryClaimUuid &&
        submitButtonText === CreateClaimButtonText.CREATE)
    ) {
      return "Required fields missing";
    }

    return "";
  }, [
    state.billingProviderId,
    state.insuranceCarrierId,
    state.insuranceOrdinal,
    state.primaryClaimUuid,
    submitButtonText,
  ]);

  const [createClaimMutation] = useApiMutations([createClaim]);

  const create = useCallback(() => {
    if (isDefined(state.billingProviderId) && state.insuranceOrdinal && state.patientInsuranceId) {
      createClaimMutation.mutate(
        {
          practiceId,
          data: {
            appointmentId: appointment.id,
            billingProviderId: state.billingProviderId,
            insuranceOrdinal: state.insuranceOrdinal,
            patientInsuranceId: state.patientInsuranceId,
            primaryClaimUuid: state.primaryClaimUuid,
          },
        },
        {
          onError: handleError,
          onSuccess: (response) => {
            const fullUrl = getFullUrl(location);

            navigate(paths.claim({ claimUuid: response.data.data.uuid }, { from: fullUrl }));
          },
        }
      );
    }
  }, [
    appointment.id,
    createClaimMutation,
    navigate,
    practiceId,
    state.billingProviderId,
    state.insuranceOrdinal,
    state.patientInsuranceId,
    state.primaryClaimUuid,
  ]);

  const view = useCallback(() => {
    if (viewClaimUuid) {
      const fullUrl = getFullUrl(location);

      navigate(paths.claim({ claimUuid: viewClaimUuid }, { from: fullUrl }));
    }
  }, [navigate, viewClaimUuid]);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (submitButtonText === CreateClaimButtonText.CREATE) {
        create();
      } else {
        view();
      }
    },
    [submitButtonText, create, view]
  );

  return (
    <FlyoverForm onSubmit={handleSubmit}>
      <FlyoverContent className="flex-1 flex flex-col h-full gap-y-6">
        <div className="flex flex-col gap-y-2">
          <span className="text-xs font-sansSemiBold">Appointment</span>
          <AppointmentClaimSummaryCard appointment={appointment} key={appointment.id} />
        </div>
        <RadioList
          label="Insurance Order"
          onChange={(_e, value) => {
            if (value.value === "PRIMARY") {
              handleUpdateState({ insuranceOrdinal: value.value, primaryClaimUuid: undefined });
            } else if (primaryClaims.length === 1) {
              handleUpdateState({
                insuranceOrdinal: value.value,
                primaryClaimUuid: primaryClaims[0].claimUuid,
              });
            } else {
              handleUpdateState({ insuranceOrdinal: value.value, primaryClaimUuid: undefined });
            }
          }}
          options={insuranceOrdinalOptions}
          selectedValue={state.insuranceOrdinal}
        />
        {state.insuranceOrdinal !== "PRIMARY" && (
          <FormFieldSelect
            disabled={submitButtonText === CreateClaimButtonText.VIEW}
            display="label"
            edit={true}
            label="Primary Claim"
            layout="labelOut"
            onItemSelected={(val) => handleUpdateState({ primaryClaimUuid: val })}
            options={primaryClaims.map((claim) => ({
              label: (
                <div className="grid grid-cols-4">
                  <div className="col-span-2">{claim.insuranceCarrier}</div>
                  <div>{claim.insuranceMemberId ?? "SSN (Hidden)"}</div>
                  <div>{claim.billingProviderName.fullDisplayName}</div>
                </div>
              ),
              value: claim.claimUuid,
            }))}
            required={submitButtonText === CreateClaimButtonText.CREATE}
            value={state.primaryClaimUuid}
          />
        )}
        <FormFieldSelect
          formatOptionLabel={(option, meta) => {
            if (meta.context === "value") {
              return option.label;
            }

            return <PatientInsuranceOption patientInsurance={option.insurance} />;
          }}
          display="label"
          edit
          label="Patient Insurance"
          layout="labelOut"
          onItemSelected={(val) => updatePatientInsurance(val)}
          options={patientInsuranceOptions ?? []}
          required
          value={state.patientInsuranceId}
        />
        <QueryResult
          loading={<LoadingOptions content="Billing Provider" />}
          queries={[billingProvidersQuery]}
        >
          <FormFieldSelect
            disabled={submitButtonText === CreateClaimButtonText.VIEW}
            display="label"
            edit
            label="Billing Provider"
            layout="labelOut"
            onItemSelected={(val) => handleUpdateState({ billingProviderId: val })}
            options={billingProvidersOptions ?? []}
            required={submitButtonText === CreateClaimButtonText.CREATE}
            value={state.billingProviderId}
          />
        </QueryResult>
        {warningMessages.length ? (
          <Banner className="text-xs" theme="announcement">
            {warningMessages.length > 1 ? (
              <ul className="list-outside list-disc ml-4">
                {warningMessages.map((text, i) => (
                  <li key={i}>{text}</li>
                ))}
              </ul>
            ) : (
              warningMessages[0]
            )}
          </Banner>
        ) : null}
      </FlyoverContent>
      <FlyoverFooter>
        <Button className="min-w-button" onClick={onCancel} theme="secondary">
          Cancel
        </Button>
        <FloatingTooltip content={submitButtonDisabledText}>
          <AsyncButton
            className="min-w-button"
            disabled={Boolean(submitButtonDisabledText)}
            isLoading={createClaimMutation.isLoading}
            theme="primary"
            type="submit"
          >
            {submitButtonText}
          </AsyncButton>
        </FloatingTooltip>
      </FlyoverFooter>
    </FlyoverForm>
  );
};
