import React from "react";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as CameraProfile } from "@libs/assets/icons/camera.svg";
import { ReactComponent as TrashCan } from "@libs/assets/icons/delete.svg";
import { ReactComponent as UploadIcon } from "@libs/assets/icons/upload.svg";

const cxStyles = {
  row: `
    flex
    cursor-pointer
    items-center
    py-2
    px-3
    gap-x-2
    hover:bg-slate-100
    w-full
  `,
  icon: "absolute rounded bg-white",
  optionIcon: "w-4 h-4",
};

export const ImageUploadPopover: React.FC<{
  onDeleteImage: () => void;
  onUploadImage: () => void;
  iconClassName?: string;
}> = ({ onDeleteImage, onUploadImage, iconClassName }) => {
  const menu = useBoolean(false);

  const handleDeleteImage = () => {
    menu.off();
    onDeleteImage();
  };

  const handleUploadImage = () => {
    menu.off();
    onUploadImage();
  };

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      onRequestClose={menu.off}
      className="h-full"
      placement="bottom-start"
      menuContent={
        <div className="w-32 py-1.5 text-xs text-greyDark font-sans">
          <button type="button" className={cxStyles.row} onClick={handleUploadImage}>
            <UploadIcon className={cxStyles.optionIcon} />
            <div>Upload Image</div>
          </button>
          <button type="button" className={cxStyles.row} onClick={handleDeleteImage}>
            <TrashCan className={cxStyles.optionIcon} />
            <div>Delete Image</div>
          </button>
        </div>
      }
    >
      {(props) => (
        <ButtonIcon
          {...props}
          className={cx(cxStyles.icon, iconClassName)}
          SvgIcon={CameraProfile}
          theme="primary"
          size="sm"
        />
      )}
    </ButtonMenu>
  );
};
