import { FeeScheduleListVO, ProviderVO } from "@libs/api/generated-api";
import { UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { TableGrid, HeaderCell } from "@libs/components/UI/GridTableComponents";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { FeeSchedulesTableRow } from "components/Settings/FeeSchedules/Row";

interface Props {
  feeSchedulesQuery: UseInfiniteApiListQueryResult<FeeScheduleListVO>;
  feeSchedulesQueryKey: unknown;
  onArchive: (feeSchedule: FeeScheduleListVO) => void;
  onCopy: (feeScheduleToCopyId: number) => void;
  onEdit: (feeScheduleId: number | "new") => void;
  onUnarchive: (feeScheduleId: number) => void;
  providersByIdMap: Record<number, ProviderVO | undefined>;
}

const headers = [
  {
    id: "name",
    label: "Name",
    width: "2fr",
  },
  {
    id: "providers",
    label: "Providers",
    width: "2fr",
  },
  {
    id: "type",
    label: "Type",
    width: "1fr",
  },
  {
    id: "insurancePlans",
    label: "Ins Plans",
    width: "1fr",
  },
  {
    id: "patients",
    label: "Patients",
    width: "1fr",
  },
  {
    id: "startDate",
    label: "Effective On",
    width: "1fr",
  },
  {
    id: "endDate",
    label: "Expires On",
    width: "1fr",
  },
  {
    id: "inNetwork",
    label: "In Network",
    width: "1fr",
  },
  {
    id: "status",
    label: "Status",
    width: "1fr",
  },
  {
    id: "actions",
    label: "",
    width: "min-content",
  },
];

export const FeeSchedulesTable: React.FC<Props> = ({
  feeSchedulesQuery,
  feeSchedulesQueryKey,
  onArchive,
  onCopy,
  onEdit,
  onUnarchive,
  providersByIdMap,
}) => {
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: feeSchedulesQuery });
  const feeSchedules = useFlattenPages(feeSchedulesQuery.data);

  return (
    <PersistScrollPosition
      className="min-h-0 col-span-full overflow-y-scroll"
      id="feeSchedulesTable"
      ref={rootRef}
      resetScrollKey={feeSchedulesQueryKey}
    >
      <ScrollableInfiniteQueryResult
        infiniteQuery={feeSchedulesQuery}
        loading={<LoadingContent containerClassName="min-h-screen col-span-full" />}
        loadMoreClassName="col-span-full"
        scrollRef={scrollRef}
      >
        <TableGrid className="min-w-[1024px]" columnWidths={headers.map(({ width }) => width)}>
          {headers.map((header) => (
            <HeaderCell key={header.id} size="short">
              {header.label}
            </HeaderCell>
          ))}
          {feeSchedules &&
            feeSchedules.map((feeSchedule) => {
              return (
                <FeeSchedulesTableRow
                  feeSchedule={feeSchedule}
                  key={feeSchedule.id}
                  onArchive={onArchive}
                  onCopy={onCopy}
                  onEdit={onEdit}
                  onUnarchive={onUnarchive}
                  providersByIdMap={providersByIdMap}
                />
              );
            })}
        </TableGrid>
      </ScrollableInfiniteQueryResult>
    </PersistScrollPosition>
  );
};
