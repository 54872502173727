import { FC, useCallback } from "react";

import { useNavigate } from "react-router-dom";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";

import { EmailTemplates } from "components/Communications/Campaigns/EmailTemplates/EmailTemplates";

import { EmailEditorJSONTemplate } from "components/Communications/Campaigns/EmailEditor/types";
import { getUpdateEmailCampaignRequest } from "components/Communications/Campaigns/Campaign/utils";

import { getMessageCampaign } from "api/communications/queries";
import { updateMessageCampaign } from "api/communications/mutations";

import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";

import { paths } from "utils/routing/paths";
import { handleError } from "utils/handleError";

export const EmailTemplatesRoute: FC = () => {
  const { practiceId } = useAccount();
  const { campaignUuid } = usePathParams("campaignTemplates");
  const { query } = useQueryParams("campaignTemplates");
  const navigate = useNavigate();
  const from = query.from ?? paths.communications();

  usePageTitle("Campaign");

  const [messageCampaignQuery] = useApiQueries([
    getMessageCampaign({ args: { practiceId, messageCampaignUuid: campaignUuid } }),
  ]);

  const [updateMessageCampaignMutation] = useApiMutations([updateMessageCampaign]);

  const handleApplyTemplate = useCallback(
    (jsonTemplate: EmailEditorJSONTemplate) => {
      if (!messageCampaignQuery.data) {
        return;
      }

      const campaign = messageCampaignQuery.data;
      const data = getUpdateEmailCampaignRequest(campaign, {
        emailTemplate: {
          json: JSON.stringify(jsonTemplate),
          html: "",
        },
      });

      updateMessageCampaignMutation.mutate(
        {
          practiceId,
          messageCampaignUuid: campaign.uuid,
          data,
        },
        {
          onSuccess: () =>
            navigate(paths.campaignEmail({ campaignUuid: campaign.uuid }, { from }), { replace: true }),
          onError: handleError,
        }
      );
    },
    [messageCampaignQuery.data, updateMessageCampaignMutation, practiceId, navigate, from]
  );

  return (
    <QueryResult queries={[messageCampaignQuery]}>
      <EmailTemplates onApplyTemplate={handleApplyTemplate} from={from} />
    </QueryResult>
  );
};
