import { FC } from "react";
import { ClampText } from "@libs/components/UI/ClampText";
import { useBoolean } from "@libs/hooks/useBoolean";

const TOGGLE_CLAMP_ATTR_KEY = "data-toggle-text-clamp";

export const isToggleClampElement = (el: HTMLElement) => {
  return el.hasAttribute(TOGGLE_CLAMP_ATTR_KEY);
};

const toggleClampAttr = { [TOGGLE_CLAMP_ATTR_KEY]: true };

export const NoteText: FC<{
  isClamped: boolean;
  text: string | undefined;
  // eslint-disable-next-line complexity
}> = ({ text, isClamped }) => {
  const hasTextOverflow = useBoolean(false);

  return (
    <div>
      {text ? (
        <ClampText
          preserveWhitespace
          text={text}
          isClamped={isClamped}
          onOverflowClamp={hasTextOverflow.on}
        />
      ) : (
        <p className="text-greyLight">Notes have not been added.</p>
      )}
      {hasTextOverflow.isOn ? (
        <div className="text-xs flex gap-x-1 w-full items-center font-sansSemiBold" {...toggleClampAttr}>
          {isClamped ? "More" : "Less"}
        </div>
      ) : null}
    </div>
  );
};
