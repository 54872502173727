import { FC } from "react";
import { cx } from "@libs/utils/cx";
import { getPaymentProfileTypeLabel } from "components/PatientProfile/Billing/billingUtils";
import { CreditCardLogo } from "./CreditCardLogo";
import { PaymentMethodBadge } from "./PaymentMethodBadge";

export const CreditCard: FC<{
  cardBrand: string;
  nameOnCard: string;
  cardLastFour: string;
  isSelected: boolean;
  type?: "default" | "family";
  cardType?: string;
  disabled?: boolean;
}> = ({ cardBrand, nameOnCard, cardLastFour, isSelected, type, disabled, cardType }) => {
  return (
    <div
      className={cx(
        `grid
         grid-cols-[min-content_1fr]
         items-center
         gap-x-3
         outline
         px-4
         py-3.5
         rounded
         relative`,
        isSelected ? "outline-primaryTheme outline-2" : "outline-greyLighter outline-1",
        disabled && "text-greyLight"
      )}
    >
      <CreditCardLogo brand={cardBrand} className="w-8 h-8" />
      <div className="text-xs text-left flex flex-col gap-y-1 overflow-hidden">
        <div className="font-sansSemiBold truncate">{nameOnCard}</div>
        <div>XXXX XXXX XXXX {cardLastFour}</div>
        <div className="text-greyMedium">{getPaymentProfileTypeLabel(cardType)}</div>
      </div>
      {type === "default" ? (
        <PaymentMethodBadge theme="primary">Default</PaymentMethodBadge>
      ) : type === "family" ? (
        <PaymentMethodBadge theme="violet">Family</PaymentMethodBadge>
      ) : null}
    </div>
  );
};
