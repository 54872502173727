import { getNextPageParam, makeInfiniteQuery, makeQuery } from "@libs/utils/queries";
import { cacheForSession, oneMinuteCache, fiveMinuteCache } from "@libs/utils/queryCache";

// This API doesn't actually suuport infinite queries but this is done
// in anticipation of the API being updated to support it.
export const getInfiniteFormSummaries = makeInfiniteQuery({
  queryKey: ["practices", "getFormSummaries"],
  formatParams: ({
    practiceId,
    pageNumber: _pageNumber,
    ...query
  }: {
    practiceId: number;
    pageNumber: number;
    publishedOnly?: boolean;
    types?: ("INTAKE" | "CLINICAL_NOTE")[];
    appointmentId?: number;
  }) => [practiceId, query],
  queryOptions: {
    ...cacheForSession,
    getNextPageParam,
  },
});

export const getFormSummaries = makeQuery({
  queryKey: ["practices", "getFormSummaries"],
  formatParams: ({
    practiceId,
    ...query
  }: {
    practiceId: number;
    publishedOnly?: boolean;
    types?: ("INTAKE" | "CLINICAL_NOTE")[];
    appointmentId?: number;
  }) => [practiceId, query],
  queryOptions: cacheForSession,
});

export const getDraftForm = makeQuery({
  queryKey: ["practices", "getDraftForm"],
  formatParams: (args: { practiceId: number; uuidOrSlug: string }) => [args.practiceId, args.uuidOrSlug],
  queryOptions: cacheForSession,
});

export const getPublishedForm = makeQuery({
  queryKey: ["practices", "getPublishedForm"],
  // the patient id is for caching purposes only, so that we have a way to decache
  // the form when the patient changes
  formatParams: (args: { practiceId: number; patientId: number; uuidOrSlug: string }) => [
    args.practiceId,
    args.uuidOrSlug,
  ],
  queryOptions: cacheForSession,
});

export const getLatestFormSubmission = makeQuery({
  queryKey: ["practices", "getLatestFormSubmission"],
  formatParams: (args: { practiceId: number; patientId: number; uuidOrSlug: string }) => [
    args.practiceId,
    args.patientId,
    args.uuidOrSlug,
  ],
  queryOptions: oneMinuteCache,
});

export const getFormPreview = makeQuery({
  queryKey: ["public", "getFormPreview"],
  formatParams: ({ practiceUuid, formUuid }: { practiceUuid: string; formUuid: string }) => [
    practiceUuid,
    formUuid,
  ],
  queryOptions: cacheForSession,
});

export const getOnboardingOptions = makeQuery({
  queryKey: ["practices", "getOnboardingOptions"],
  formatParams: ({ practiceId }: { practiceId: number }) => [practiceId],
  queryOptions: fiveMinuteCache,
});
