import React from "react";
import { cx } from "@libs/utils/cx";
import { RequiredAsterisk } from "@libs/components/UI/RequiredAsterisk";

interface Props extends React.HTMLProps<HTMLElement> {
  required?: boolean;
  subTitle?: string;
  title: string;
  titleClass?: string;
  titleChildren?: JSX.Element | undefined;
}

export const ClaimSection: React.FC<Props> = ({
  children,
  required,
  subTitle,
  title,
  titleClass,
  titleChildren,
}) => {
  return (
    <div className="flex flex-col mt-6">
      <div className={cx("flex flex-row mb-3 text-sm items-center", titleClass)}>
        <div className="flex">
          <div className="font-sansSemiBold">{title}</div>
          {required && <RequiredAsterisk />}
          {subTitle && <div className="ml-2">{subTitle}</div>}
        </div>
        {titleChildren}
      </div>
      {children}
    </div>
  );
};
