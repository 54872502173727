import { FC, useEffect, useRef, useState } from "react";
import { EmailConfigVO, PracticeInfoVO } from "@libs/api/generated-api";
import { SMSMessageActions } from "components/Communications/SMSMessageActions";
import { MessagePanelLayout } from "components/Communications/MessagePanelLayout";
import {
  MessageViewToggleGroup,
  MessageViewToggleGroupProps,
} from "components/Communications/MessageViewToggleGroup";
import { EmailMessageActions } from "components/Communications/EmailMessageActions";
import { EmailMessage } from "components/Communications/EmailMessage";
import { SMSMessage } from "components/Communications/SMSMessage";
import { DraftAutomationAction } from "components/Communications/Automations/types";
import { FilteredTemplateVariablesQuery } from "components/Communications/types";
import { isEmailConfigured } from "components/Communications/utils";
import { AutomationActionValidation } from "components/Communications/Automations/utils";

/**
 * A hook to generate a unique key every time new template variables are loaded.
 * @param isPreviousTemplateVariables A boolean to indicate if the user is currently using the previous template variables.
 * @returns A unique key for every time new template variables are loaded.
 */
const useNewVariablesLoadedKey = (isPreviousTemplateVariables: boolean) => {
  const [key, setKey] = useState(0);
  const ref = useRef(isPreviousTemplateVariables);

  useEffect(() => {
    if (!isPreviousTemplateVariables && ref.current) {
      setKey((last) => last + 1);
    }

    ref.current = isPreviousTemplateVariables;
  }, [isPreviousTemplateVariables]);

  return key;
};

interface Props {
  action: DraftAutomationAction;
  contentTemplateVariablesQuery: FilteredTemplateVariablesQuery;
  subjectTemplateVariablesQuery: FilteredTemplateVariablesQuery;
  onUpdate: (updates: Partial<DraftAutomationAction>) => void;
  onSendTestModal: Func;
  validation: AutomationActionValidation;
  emailConfig: EmailConfigVO;
  practice: PracticeInfoVO;
}

export const ActionMessagePanel: FC<
  Props & Pick<MessageViewToggleGroupProps, "messageView" | "onMessageViewChange">
> = ({
  action,
  contentTemplateVariablesQuery,
  subjectTemplateVariablesQuery,
  onUpdate,
  onSendTestModal,
  validation,
  emailConfig,
  practice,
  ...props
}) => {
  const { messageView } = props;
  const messageViewToggleGroupProps: MessageViewToggleGroupProps = {
    channels: action.channels,
    canSendEmail: isEmailConfigured(emailConfig),
    ...props,
  };

  const newVariablesLoadedKey = useNewVariablesLoadedKey(contentTemplateVariablesQuery.isPreviousData);
  const [copyCount, setCopyCount] = useState(0);

  const handleCopySms = () => {
    setCopyCount((last) => last + 1);
    onUpdate({ emailTemplate: action.smsTemplate });
  };

  return messageView === "SMS" ? (
    <MessagePanelLayout
      primaryActions={<MessageViewToggleGroup {...messageViewToggleGroupProps} />}
      secondaryActions={<SMSMessageActions smsTemplate={action.smsTemplate} onSendTest={onSendTestModal} />}
    >
      <SMSMessage
        // The SMS message needs to force re-render
        // in order to make sure template variables
        // state is re-evaluated within the TemplateVariablesEditor
        // Case 1: TemplateVariables are already loaded for
        // all action event types. When switching action event
        // types the component is forced to re-render and the variables will
        // also be switched immediately as they are in cache.
        // Case 2: Template variables are loaded for the current event type
        // but not for the one being switched to. In this case
        // newVariablesKey will get bumped once the variables have been loaded
        // and the component will be forced to re-render
        key={`${action.event}-${newVariablesLoadedKey}`}
        smsTemplate={action.smsTemplate}
        contentTemplateVariablesQuery={contentTemplateVariablesQuery}
        onTemplateChange={(smsTemplate) => onUpdate({ smsTemplate })}
        validationResult={{
          smsTemplate: validation.smsTemplate,
        }}
      />
    </MessagePanelLayout>
  ) : (
    <MessagePanelLayout
      primaryActions={<MessageViewToggleGroup {...messageViewToggleGroupProps} />}
      secondaryActions={
        <EmailMessageActions
          emailTemplate={action.emailTemplate}
          smsTemplate={action.smsTemplate}
          onCopySms={handleCopySms}
          onSendTest={onSendTestModal}
        />
      }
    >
      <EmailMessage
        // The Email message needs to force re-render
        // for the same cases as SMS editor above with an additional case.
        // When the user wants to copy the SMS message into the email message
        // we need to force re-render so the email editor takes the new state.
        key={`${copyCount}-${action.event}-${newVariablesLoadedKey}`}
        emailTemplate={action.emailTemplate}
        subject={action.subject}
        onTemplateChange={(emailTemplate) => onUpdate({ emailTemplate })}
        onSubjectChange={(subject) => onUpdate({ subject })}
        contentTemplateVariablesQuery={contentTemplateVariablesQuery}
        subjectTemplateVariablesQuery={subjectTemplateVariablesQuery}
        validationResult={{
          emailTemplate: validation.emailTemplate,
          subject: validation.subject,
        }}
        practice={practice}
      />
    </MessagePanelLayout>
  );
};
