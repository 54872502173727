import { FC, MouseEvent } from "react";
import { PatientNoteVO, TagVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";

import { NoteListItemTitle } from "components/Notes/NoteListItemTitle";
import { NoteListItemAuditEntry } from "components/Notes/NoteListItemAuditEntry";
import { PillTag } from "components/Notes/PillTag";
import { AlertToggle } from "components/Notes/AlertToggle";
import { NoteText, isToggleClampElement } from "components/Notes/NoteText";

export const AlertPanelItem: FC<{
  onToggleAlert: (note: PatientNoteVO, isAlert: boolean) => void;
  onEditClick: Func;
  note: PatientNoteVO;
  clinicalTag: TagVO | undefined;
}> = ({ note, clinicalTag, onEditClick, onToggleAlert }) => {
  const clamped = useBoolean(true);
  const tags = note.type === "CHART" && clinicalTag ? [clinicalTag, ...note.tags] : note.tags;

  const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
    if (isToggleClampElement(e.target as HTMLElement)) {
      clamped.toggle();
    } else {
      onEditClick();
    }
  };

  return (
    <div className="relative p-3 pb-4">
      <button
        type="button"
        onClick={handleClick}
        className="text-xs flex flex-col items-stretch gap-y-3 text-left w-full"
      >
        <div className="pr-8">
          <NoteListItemTitle note={note} />
        </div>
        <div className="flex gap-1 flex-wrap">
          {tags.map((tag) => (
            <PillTag key={tag.id} tag={tag} />
          ))}
        </div>
        <NoteText isClamped={clamped.isOn} text={note.text} />
        {note.lastEdit ? <NoteListItemAuditEntry auditEntry={note.lastEdit} /> : null}
      </button>
      <div className="absolute right-2 top-2">
        <AlertToggle isAlert={note.isAlert} onToggleAlert={(isAlert) => onToggleAlert(note, isAlert)} />
      </div>
    </div>
  );
};
