import { FocusEvent } from "react";
import { produce } from "immer";

import { PracticeConnectedRemoteSettingsVO, PracticeRemoteSettingsRequest } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";

import { numericAnydeskId } from "@libs/utils/validators";

export const CONNECTED_VALUE = "••••••••";

export const getConnectedValue = (isConnected: boolean) => (isConnected ? CONNECTED_VALUE : undefined);

export const getConnectedInputKeys = (e: FocusEvent<HTMLInputElement>) => {
  const { dataset, value } = e.target;
  const hasKey = dataset.hasKey as keyof PracticeConnectedRemoteSettingsVO;
  const key = dataset.key as keyof PracticeRemoteSettingsRequest;

  return { hasKey, key, value };
};

const ANYDESK_ID_ERROR = "Please enter a valid AnyDesk ID";

export const getRemoteSettingsSchema = (remoteSettings: PracticeRemoteSettingsRequest) => {
  return {
    anydeskAddress: [
      {
        $v: numericAnydeskId,
        $error: ANYDESK_ID_ERROR,
        $ignore: !remoteSettings.anydeskAddress,
      },
    ],
    anydeskAddress2: [
      {
        $v: numericAnydeskId,
        $error: ANYDESK_ID_ERROR,
        $ignore: !remoteSettings.anydeskAddress2,
      },
    ],
    anydeskAddress3: [
      {
        $v: numericAnydeskId,
        $error: ANYDESK_ID_ERROR,
        $ignore: !remoteSettings.anydeskAddress3,
      },
    ],
  };
};

export const formatRemoteSettingsRequest = (remoteSettings: PracticeRemoteSettingsRequest) => {
  return produce(remoteSettings, (draft) => {
    for (const key in draft) {
      const requestKey = key as keyof PracticeRemoteSettingsRequest;
      const value = draft[requestKey];

      if (value === CONNECTED_VALUE || (isDefined(value) && value.trim().length === 0)) {
        draft[requestKey] = undefined;
      }
    }
  });
};

export const trackChangesForSegment = (
  connectedRemoteSettings: PracticeConnectedRemoteSettingsVO,
  remoteSettingsRequest: PracticeRemoteSettingsRequest
) => {
  let hasUpdatedAnydeskIds = false;
  let hasUpdatedCredentials = false;

  for (const key in remoteSettingsRequest) {
    const requestKey = key as keyof PracticeRemoteSettingsRequest;
    const connectedKey = key as keyof PracticeConnectedRemoteSettingsVO;

    if (key.includes("anydesk")) {
      // BE returns null when there is no value, but our types expect string |
      // undefined, so we need to default to undefined if the value is null
      const requestValue = remoteSettingsRequest[requestKey] ?? undefined;
      const connectedValue = connectedRemoteSettings[connectedKey] ?? undefined;

      if (requestValue !== connectedValue) {
        hasUpdatedAnydeskIds = true;
      }
    } else if (isDefined(remoteSettingsRequest[requestKey])) {
      // We cannot compare connected credentials since we do not return the
      // actual values. If the value is defined, it means it has been updated
      hasUpdatedCredentials = true;
    }
  }

  return {
    hasUpdatedAnydeskIds,
    hasUpdatedCredentials,
  };
};
