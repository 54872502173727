/**
 * Finds the nearest scrollable container.
 * @param element The starting element.
 * @returns The HTMLElement that is scrollable or null.
 */
export const findScrollable = (element?: HTMLElement | null): HTMLElement | null => {
  if (!element) {
    return null;
  }

  return element.scrollHeight > element.clientHeight ? element : findScrollable(element.parentElement);
};
