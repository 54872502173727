import { FC } from "react";
import { DentalProcedureVO, PatientToothVO, ProviderVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";

import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { Button } from "@libs/components/UI/Button";

import { ReactComponent as DropDownIcon } from "@libs/assets/icons/down-caret.svg";

import { DraftPatientProcedureRequest } from "components/Charting/draftPatientProcedure";
import {
  isIncompleteSelection,
  PatientProcedureValidationResult,
} from "components/Charting/patientProcedureSchema";
import { AddProcedureForm } from "components/Charting/AddProcedureForm";
import {
  AddProcedureFormHeaderContentWrapper,
  AddProcedureFormHeaderContent,
} from "components/Charting/AddProcedureFormHeader";

import { ProcedureSelector } from "components/ProcedureSelector/ProcedureSelector";

interface Props {
  onUpdateDraft: (updates: Partial<DraftPatientProcedureRequest>) => void;

  onUpdateSwitchDentalProcedure: (switchInfo?: {
    draft: DraftPatientProcedureRequest;
    dentalProcedure: DentalProcedureVO;
  }) => void;
  isSwitching: boolean;
  allDentalProcedures: DentalProcedureVO[];
  dentalProcedure: DentalProcedureVO;
  teeth: PatientToothVO[];
  validation: PatientProcedureValidationResult;
  isCollapsible: boolean;
  draftPatientProcedure: DraftPatientProcedureRequest;
  providers: ProviderVO[];
  validateOptions?: {
    provider?: boolean;
  };
  editStatus?: boolean;
}

export const AddPatientProcedureForm: FC<Props> = ({
  onUpdateDraft,
  onUpdateSwitchDentalProcedure,
  isSwitching,
  allDentalProcedures,
  dentalProcedure,
  validation,
  validateOptions,
  draftPatientProcedure,
  providers,
  isCollapsible,
  teeth,
  editStatus,
}) => {
  const formSection = useBoolean(
    // Open by default if
    // 1. Only one procedure being edited at a time
    // 2. The procedure is in an invalid state
    // 3. The procedure is in a valid state but has not selected of the area selection options.
    //    This is to handle the case where two DPs are optional but the user has made selections on the
    //    the ordontogram that do not match any of the area selection options. In this case we show them
    //    the add patient procedure modal and stripping out their invalid selections. This leaves them
    //    in a valid state but they are given an opportunity to make an area selection that complies with
    //    the dental procedure.
    () =>
      !isCollapsible ||
      validation.$isValid === false ||
      isIncompleteSelection(draftPatientProcedure, dentalProcedure)
  );

  return (
    <AddProcedureForm
      dentalProcedure={dentalProcedure}
      teeth={teeth}
      draft={draftPatientProcedure}
      validation={validation}
      isExpanded={formSection.isOn}
      providers={providers}
      onUpdateDraft={onUpdateDraft}
      validateOptions={validateOptions}
      editStatus={editStatus}
      headerContent={
        <AddProcedureFormHeaderContentWrapper>
          <>
            {isSwitching ? (
              <>
                <div className="flex items-center w-full h-9">
                  <ProcedureSelector
                    mode="single"
                    procedures={allDentalProcedures}
                    value={dentalProcedure}
                    required={true}
                    aria-label="CDT Code"
                    onChange={(val) => {
                      if (val) {
                        onUpdateSwitchDentalProcedure({ draft: draftPatientProcedure, dentalProcedure: val });
                      }
                    }}
                    className="w-full"
                  />
                </div>
                <Button className="text-xs" theme="link" onClick={() => onUpdateSwitchDentalProcedure()}>
                  Cancel
                </Button>
              </>
            ) : (
              <>
                <AddProcedureFormHeaderContent dentalProcedure={dentalProcedure} />
                <Button
                  className="text-xs"
                  theme="link"
                  onClick={() => {
                    onUpdateSwitchDentalProcedure({ draft: draftPatientProcedure, dentalProcedure });
                  }}
                >
                  Change Procedure
                </Button>
              </>
            )}
            {isCollapsible ? (
              <button
                className="flex flex-col items-end flex-none w-24"
                type="button"
                onClick={formSection.toggle}
              >
                <DropDownIcon
                  className={cx(
                    "text-primaryTheme w-5 h-5 transition-transform",
                    formSection.isOn && "rotate-180"
                  )}
                />
                {validation.$isValid ? null : <FormFieldError>Details required</FormFieldError>}
              </button>
            ) : null}
          </>
        </AddProcedureFormHeaderContentWrapper>
      }
    />
  );
};
