import { FC } from "react";
import { NavLink } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { ReactComponent as DropDownIcon } from "@libs/assets/icons/down-caret.svg";
import { BoldSpaced } from "@libs/components/UI/BoldSpaced";
import { SettingsNestedNavigationItem } from "components/Settings/routes";
import { CollapsibleSection } from "components/UI/CollapsibleSection";

export const SettingsNestedNavigation: FC<{
  item: SettingsNestedNavigationItem;
  isOpen: boolean;
  isChildSelected: boolean;
  onToggle: Func;
}> = ({ item, isOpen, isChildSelected, onToggle }) => {
  return (
    <>
      <button
        className={cx(
          `flex
           p-3
           items-center
           justify-between
           w-full
           hover:bg-slate-200
           rounded`,
          !isOpen && isChildSelected && "text-primaryTheme"
        )}
        type="button"
        onClick={onToggle}
      >
        <p className="font-sansSemiBold text-sm">{item.label}</p>

        <DropDownIcon
          className={cx(
            `cursor-pointer
             transition-transform
             w-4
             h-4
             fill-primaryTheme
             ml-2`,
            isOpen && "rotate-180"
          )}
        />
      </button>
      <CollapsibleSection isOpen={isOpen}>
        {item.children.map((content) => {
          return (
            <NavLink
              className={({ isActive }) =>
                cx(
                  "p-3 block hover:bg-slate-200 rounded text-sm",
                  isActive && "font-sansSemiBold text-primaryTheme"
                )
              }
              key={content.to}
              to={content.to}
            >
              <BoldSpaced>{content.label}</BoldSpaced>
            </NavLink>
          );
        })}
      </CollapsibleSection>
    </>
  );
};
