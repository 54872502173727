import { useLocation } from "react-router-dom";
import { getFullUrl } from "@libs/utils/location";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { paths } from "utils/routing/paths";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";

export const EditAppointmentCell: React.FC<{
  last?: boolean;
  appointmentId: number;
  patientId: number;
  to: string;
}> = ({ last, appointmentId, patientId, to }) => {
  const location = useLocation();

  return (
    <DashboardLinkCell last={last} to={to}>
      <FloatingTooltip content="Edit Appointment" theme="SMALL">
        <ButtonInternalLink
          theme="link"
          to={paths.editAppointment({ appointmentId, patientId }, { from: getFullUrl(location) })}
        >
          <EditIcon className="w-5 h-5" />
        </ButtonInternalLink>
      </FloatingTooltip>
    </DashboardLinkCell>
  );
};
