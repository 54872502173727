import { PaymentProfileVO, PaymentVO } from "@libs/api/generated-api";
import { useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FlyoverReel } from "components/UI/FlyoverReel";
import { NewCardFlyoverForm } from "components/PatientProfile/Billing/NewCardForm/NewCardFlyoverForm";
import { ManagePaymentMethodsFlyoverPanel } from "components/PatientProfile/Billing/ManagePaymentMethodsFlyoverPanel";
import { AddFundsToWalletPanel } from "components/PatientProfile/Billing/AddFundsToWallet/AddFundsToWalletPanel";
import { FlyoverReelHeader } from "components/UI/FlyoverReelHeader";
import { paths } from "utils/routing/paths";

export type FlyoverPanel =
  | { id: "AddToWallet"; isDirty?: boolean }
  | { id: "AddCard"; isDirty?: boolean; onSavedNewCardHandler?: (paymentProfile: PaymentProfileVO) => void }
  | { id: "ManagePaymentMethods"; isDirty?: boolean };

export const PatientPaymentMethodsFlyoverReel: React.FC<{
  onClose: Func;
  patientId: number;
  initialPanel: FlyoverPanel;
}> = ({ onClose, initialPanel, patientId }) => {
  const [paymentProfileUuid, setPaymentProfileUuid] = useState<string | undefined>(undefined);
  const navigate = useNavigate();
  const onPaymentCollected = useCallback(
    (payment: PaymentVO) => {
      navigate(
        paths.patientWalletActivity({
          patientId,
          walletUuid: payment.paymentReference.uuid,
        })
      );
    },
    [navigate, patientId]
  );

  return (
    <FlyoverReel
      initialPanel={initialPanel}
      onClose={onClose}
      size="md"
      dataTestId="manage-patient-payment-flyover"
    >
      {({ panel, pushPanel, handleBack, index }) => {
        const headerProps = {
          onBack: index > 0 ? handleBack : undefined,
        };

        const goBackOrClose = index > 0 ? handleBack : onClose;

        return panel.id === "AddToWallet" ? (
          <>
            <FlyoverReelHeader {...headerProps} onRequestClose={onClose}>
              Add Wallet Funds
            </FlyoverReelHeader>
            <AddFundsToWalletPanel
              patientId={patientId}
              onRequestClose={goBackOrClose}
              paymentProfileUuid={paymentProfileUuid}
              onClickAddCard={() => pushPanel({ id: "AddCard" })}
              onPaymentCollected={(payment) => {
                onPaymentCollected(payment);
                onClose();
              }}
            />
          </>
        ) : panel.id === "AddCard" ? (
          <>
            <FlyoverReelHeader {...headerProps} onRequestClose={onClose}>
              Add Card
            </FlyoverReelHeader>
            <NewCardFlyoverForm
              onRequestClose={goBackOrClose}
              patientId={patientId}
              onSavedNewCardHandler={(paymentProfile) => {
                setPaymentProfileUuid(paymentProfile.uuid);
                goBackOrClose();
                panel.onSavedNewCardHandler?.(paymentProfile);
              }}
            />
          </>
        ) : (
          <>
            <FlyoverReelHeader {...headerProps} onRequestClose={onClose}>
              Manage Payment Methods
            </FlyoverReelHeader>
            <ManagePaymentMethodsFlyoverPanel
              patientId={patientId}
              onRequestClose={goBackOrClose}
              onClickAddCard={() => pushPanel({ id: "AddCard" })}
              onClickAddFundsToWallet={() => pushPanel({ id: "AddToWallet" })}
            />
          </>
        );
      }}
    </FlyoverReel>
  );
};
