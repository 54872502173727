import React, { FC } from "react";
import { TimeSeriesResolutionOption } from "components/Dashboard/types";
import { DashboardTimeseriesChart } from "components/Dashboard/Charting/DashboardTimeseriesChart";
import { useBarStackDecorators } from "components/Dashboard/hooks/useBarStackDecorators";
import { CHART_COLORS, ColorScheme } from "components/Dashboard/Charting/theme";
import { labelForAdjustmentCategory } from "components/Dashboard/Adjustments/utils";
import { BarStackData } from "components/Dashboard/Charting/types";
import {
  AdjustmentCategory,
  DashboardAdjustmentsQuery,
  DashboardChartDisplay,
  TimeSegment,
} from "utils/routing/dashboard";
import { useFocusDateClick } from "components/Dashboard/hooks/useFocusDateClick";

const adjustmentTypeKeys: AdjustmentCategory[] = ["insurance", "collection"];
const colorScheme: ColorScheme<AdjustmentCategory> = {
  domain: adjustmentTypeKeys,
  range: [CHART_COLORS.blue, CHART_COLORS.blueBright],
};

export const AdjustmentsOverTimeChart: FC<{
  width: number;
  height: number;
  resolution: TimeSeriesResolutionOption;
  data: BarStackData<AdjustmentCategory>[];
  isLoading: boolean;
  isFirstLoad: boolean;
  timeSegment: TimeSegment;
  focusDate?: Date;
  chartDisplay: DashboardChartDisplay;
  onUpdateParams: (updates: Partial<DashboardAdjustmentsQuery>) => void;
}> = React.memo(
  ({
    width,
    height,
    resolution,
    data,
    focusDate,
    onUpdateParams,
    isLoading,
    isFirstLoad,
    timeSegment,
    chartDisplay,
  }) => {
    const barDecorators = useBarStackDecorators(data, focusDate, resolution);
    const handleDateClicked = useFocusDateClick({ handleParamsChange: onUpdateParams, focusDate });

    return (
      <DashboardTimeseriesChart
        timeSegment={timeSegment}
        chartDisplay={chartDisplay}
        data={data}
        resolution={resolution}
        width={width}
        height={height}
        isLoading={isLoading}
        colorScheme={colorScheme}
        keys={adjustmentTypeKeys}
        randomizeLoadingBars={isFirstLoad}
        onClickDate={handleDateClicked}
        isCurrency
        labelFormat={labelForAdjustmentCategory}
        {...barDecorators}
      />
    );
  }
);
