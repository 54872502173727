import { FC } from "react";

import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Flyover } from "components/UI/Flyover";

import { getDentalProceduresQuery } from "api/charting/queries";

import { getPracticeProvidersQuery } from "api/practice/queries";
import { getAppointmentCategoriesQuery } from "api/scheduling/queries";
import { ClinicalNotesSettingsForm } from "components/Settings/ChartingSettings/ClinicalNotesSettingsForm";
import { EditFormSettingsProps } from "components/Settings/Forms/types";

export const ClinicalNotesSettingsFlyover: FC<EditFormSettingsProps> = ({
  form,
  onUpdateSettings,
  onRequestClose,
  isSaving,
}) => {
  const { practiceId } = useAccount();
  const [proceduresQuery, providersQuery, categoriesQuery] = useApiQueries([
    getDentalProceduresQuery({ args: { practiceId } }),
    getPracticeProvidersQuery({
      args: { practiceId },
    }),
    getAppointmentCategoriesQuery({
      args: { practiceId },
    }),
  ]);

  return (
    <Flyover title="Settings" onClose={onRequestClose} size="md">
      <QueryResult queries={[proceduresQuery, providersQuery, categoriesQuery]}>
        {proceduresQuery.data && providersQuery.data && categoriesQuery.data ? (
          <ClinicalNotesSettingsForm
            form={form}
            procedures={proceduresQuery.data}
            providers={providersQuery.data}
            categories={categoriesQuery.data}
            onUpdateSettings={onUpdateSettings}
            onRequestClose={onRequestClose}
            isSaving={isSaving}
          />
        ) : null}
      </QueryResult>
    </Flyover>
  );
};
