import { FC, FormEventHandler, useState } from "react";
import { LabCaseReturnReasonVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";
import { Button } from "@libs/components/UI/Button";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent, FlyoverFooter, FlyoverForm } from "components/UI/FlyoverComponents";
import { createLabCaseReturnReason, updateLabCaseReturnReason } from "api/lab/mutations";
import { handleError } from "utils/handleError";
import { useFocusOnce } from "hooks/useFocusOnce";
import { getLabCaseReturnReason } from "api/lab/queries";

export const LabCaseReturnReasonFlyover: FC<{
  labCaseReturnReasonUuid?: LabCaseReturnReasonVO["uuid"];
  onClose: Func;
  onSave: Func;
}> = ({ labCaseReturnReasonUuid, onSave, onClose }) => {
  const isEditing = labCaseReturnReasonUuid != null;
  const { practiceId } = useAccount();
  const [name, setName] = useState<string>();

  // Focus the name field. Called after flyover is shown and its transition ends.
  const { ref: focusRef, handleFocus } = useFocusOnce<HTMLInputElement>();

  const [originalLabCaseReturnReasonQuery] = useApiQueries([
    getLabCaseReturnReason({
      args: { practiceId, labCaseReturnReasonUuid: labCaseReturnReasonUuid as string },
      queryOptions: { enabled: isEditing },
    }),
  ]);

  const isInitializing = originalLabCaseReturnReasonQuery.isInitialLoading;

  const [createLabCaseReturnReasonMutation, updateLabCaseReturnReasonMutation] = useApiMutations([
    createLabCaseReturnReason,
    updateLabCaseReturnReason,
  ]);

  const validation = useValidation(
    { name },
    { name: { $validations: [{ $v: required, $error: "Name is required" }] } }
  );

  const handleSave: FormEventHandler = (e) => {
    e.preventDefault();

    if (!validation.validate().$isValid) {
      return;
    }

    const payload = {
      practiceId,
      data: { reason: name ?? "" },
    };

    if (isEditing) {
      updateLabCaseReturnReasonMutation.mutate(
        { ...payload, labCaseReturnReasonUuid },
        { onSuccess: onSave, onError: handleError }
      );
    } else {
      createLabCaseReturnReasonMutation.mutate(payload, {
        onSuccess: onSave,
        onError: handleError,
      });
    }
  };

  useSyncOnce((labCaseReturnReason) => {
    setName(labCaseReturnReason.reason);
  }, originalLabCaseReturnReasonQuery.data);

  return (
    <Flyover
      title={`${isEditing ? "Edit" : "Add"} Return Reason`}
      onClose={onClose}
      size="md"
      onTransitionEnd={(e, { show }) => {
        show && handleFocus();
      }}
    >
      {({ close }) => (
        <FlyoverForm fieldLayout="labelOut" onSubmit={handleSave}>
          <FlyoverContent>
            <QueryResult queries={[originalLabCaseReturnReasonQuery]} loading={<LoadingOverlaySpinner />}>
              <div className="grid grid-cols-2 gap-6">
                <FormFieldInput
                  label="Name"
                  className="col-span-full"
                  autoComplete="off"
                  required
                  value={name}
                  error={validation.result.name.$error}
                  onChange={(e) => setName(e.target.value)}
                  ref={focusRef}
                />
              </div>
            </QueryResult>
          </FlyoverContent>
          <FlyoverFooter>
            <Button className="min-w-button" theme="secondary" onClick={close}>
              Cancel
            </Button>
            <Button
              disabled={
                !(validation.result.$isValid ?? true) ||
                createLabCaseReturnReasonMutation.isLoading ||
                updateLabCaseReturnReasonMutation.isLoading ||
                isInitializing
              }
              className="min-w-button"
              type="submit"
            >
              {isEditing ? "Save" : "Create"}
            </Button>
          </FlyoverFooter>
        </FlyoverForm>
      )}
    </Flyover>
  );
};
