import { cx } from "@libs/utils/cx";
import { formatISODate } from "@libs/utils/date";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { Cell } from "@libs/components/UI/GridTableComponents";
import { Switch } from "@libs/components/UI/Switch";
import {
  ModalBodyProps,
  MouthLocation,
} from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/types";

import { ImagingDeviceWithSettings } from "components/PatientProfile/Imaging/hooks/useImagingDevices";

type ListItemProps = {
  item: ImagingDeviceWithSettings;
  onChange: ModalBodyProps["onChange"];
  onClickEdit: (item: ImagingDeviceWithSettings) => void;
};

const cxStyles = {
  cell: "flex items-center h-12 px-4",
};

const rotateSettingToLabel: Record<MouthLocation, string> = {
  UPPER_PA: "Upper PA",
  LOWER_PA: "Lower PA",
  LEFT_BW: "Left BW",
  RIGHT_BW: "Right BW",
};

const DEFAULT_TEXT = "Default";

// eslint-disable-next-line complexity
export const DeviceRow: React.FC<ListItemProps> = ({ item, onChange, onClickEdit }) => {
  const { settings, label, type } = item;

  const imageTypeProduced =
    (type === "camera" && !settings.produces) || settings.produces === "Photo" ? "Photo" : "X-Ray";
  const roateOnCaptureLabel = settings.rotateOnCapture
    ? settings.rotateOnCapture.map((rotateSetting) => rotateSettingToLabel[rotateSetting]).join(", ")
    : DEFAULT_TEXT;

  return (
    <>
      <Cell className={cxStyles.cell}>{settings.overriddenLabel ?? label}</Cell>
      <Cell className={cxStyles.cell}>{imageTypeProduced}</Cell>

      <Cell className={cxStyles.cell}>{settings.reverseImport ? "Reversed" : DEFAULT_TEXT}</Cell>
      <Cell className={cxStyles.cell}>
        {settings.flipOnCapture?.includes("x")
          ? "Mirror Horizontally"
          : settings.flipOnCapture?.includes("y")
            ? "Mirror Vertically"
            : DEFAULT_TEXT}
      </Cell>
      <Cell className={cx("truncate", cxStyles.cell)}>
        <FloatingTooltip content={roateOnCaptureLabel} theme="MEDIUM">
          <div className="truncate">{roateOnCaptureLabel}</div>
        </FloatingTooltip>
      </Cell>
      <Cell className={cxStyles.cell}>
        {settings.calibration ? `Done ${formatISODate(settings.calibration.date)}` : "Not Done"}
      </Cell>
      <Cell className={cxStyles.cell}>
        <Switch
          checked={settings.enabled}
          onChange={() => onChange({ ...item, settings: { ...settings, enabled: !settings.enabled } })}
          labelPosition="right"
        >
          {settings.enabled ? "Yes" : "No"}
        </Switch>
      </Cell>
      <Cell className={cxStyles.cell}>
        <ButtonIcon
          SvgIcon={EditIcon}
          onClick={() => {
            onClickEdit(item);
          }}
        />
      </Cell>
    </>
  );
};
