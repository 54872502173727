import { FC } from "react";
import { CompensationDetailsVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { CompensationPeriodOptions } from "components/Employee/data";
import { FormFieldCurrencyInput, FormFieldCurrencyInputHandler } from "components/UI/FormFieldCurrencyInput";

type Props = {
  amount: string | undefined;
  period: CompensationDetailsVO["period"];
  onAmountChange: FormFieldCurrencyInputHandler;
  onPeriodChange: (value: CompensationDetailsVO["period"]) => void;
  amountError: string | undefined;
  periodError: string | undefined;
  isExcludedFromPayroll: boolean;
  isEditing: boolean;
};

export const CompensationField: FC<Props> = ({
  amount,
  period,
  onPeriodChange,
  onAmountChange,
  amountError,
  periodError,
  isExcludedFromPayroll,
  isEditing,
}) => {
  const sharedProps = { edit: isEditing };

  return (
    <div className={cx("flex items-start", isEditing ? "gap-3" : "gap-0.5")}>
      <FormFieldCurrencyInput
        label="Compensation"
        className={cx(isEditing ? "flex-1" : "flex-none")}
        value={amount}
        min={0}
        onValueChange={onAmountChange}
        error={amountError}
        required={!isExcludedFromPayroll}
        disabled={isExcludedFromPayroll}
        {...sharedProps}
      />
      {!isEditing && !amount ? null : (
        <FormFieldSelect
          label={<span className="invisible">Period</span>}
          placeholder="Period"
          display="label"
          className="flex-1"
          options={CompensationPeriodOptions}
          value={period}
          onChange={(e) => onPeriodChange(e?.value as CompensationDetailsVO["period"])}
          error={periodError}
          isClearable={false}
          disabled={isExcludedFromPayroll}
          {...sharedProps}
        />
      )}
    </div>
  );
};
