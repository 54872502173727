import { MFAChallenge } from "components/SignIn/AuthChallenges/MFAChallenge";
import { NewPasswordChallenge } from "components/SignIn/AuthChallenges/NewPasswordChallenge";
import { ChallengeProps, SupportedChallenge } from "components/SignIn/AuthChallenges/utils";

const ChallengeComponents: Record<SupportedChallenge, React.FunctionComponent<ChallengeProps>> = {
  SOFTWARE_TOKEN_MFA: MFAChallenge,
  NEW_PASSWORD_REQUIRED: NewPasswordChallenge,
};

export const AuthChallenge: React.FC<{ challengeName: SupportedChallenge } & ChallengeProps> = ({
  challengeName,
  ...rest
}) => {
  const ChallengeComponent = ChallengeComponents[challengeName];

  return <ChallengeComponent {...rest} />;
};
