import { ButtonHTMLAttributes, FC } from "react";
import { cx } from "@libs/utils/cx";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ButtonProps } from "@libs/components/UI/Button";
import { ButtonLink } from "@libs/components/UI/ButtonLink";
import { ReactComponent as PrintIcon } from "@libs/assets/icons/send-to-printer.svg";

type Props = ButtonHTMLAttributes<HTMLButtonElement> & { theme?: ButtonProps["theme"] } & {
  printUrl: string | undefined;
  isLoadingPrintUrl: boolean;
  onClick: () => void;
};

const cxStyles = {
  buttonIconLayout: "flex items-center gap-x-2",
};

export const PrintButton: FC<Props> = ({ printUrl, isLoadingPrintUrl, onClick, theme, className }) => {
  return printUrl ? (
    <ButtonLink
      theme={theme}
      href={printUrl}
      target="_blank"
      className={cx(cxStyles.buttonIconLayout, className)}
    >
      <PrintIcon className="h-4 w-4" />
      Print
    </ButtonLink>
  ) : (
    <AsyncButton
      className={cx(cxStyles.buttonIconLayout, className)}
      isLoading={isLoadingPrintUrl}
      theme={theme}
      onClick={onClick}
    >
      <PrintIcon className="h-4 w-4" />
      Print
    </AsyncButton>
  );
};
