import React, { Dispatch, FC, ReactNode, SetStateAction, useMemo } from "react";
import { format, parseISO } from "date-fns";
import Skeleton from "react-loading-skeleton";
import { AppointmentSeriesVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { AppointmentIntervalSlots } from "components/ScheduleAppointments/AppointmentIntervalSlots";
import { SlotSelections } from "components/ScheduleAppointments/types";
import { chunkSlotsByInterval } from "components/ScheduleAppointments/utils";
import { AppointmentListPrompt } from "components/ScheduleAppointments/AppointmentListPrompt";

interface Props {
  appointmentDurationInMinutes: number;
  openSlotsQuery: ApiQueryResult<AppointmentSeriesVO[]>;
  selections: SlotSelections;
  onSelectedSlotUpdated: Dispatch<SetStateAction<SlotSelections>>;
}

const TableHeader: FC<{ className?: string; children?: ReactNode }> = ({ children, className }) => (
  <div
    className={cx(
      `font-sansSemiBold
       text-xs
       py-4
       pl-4
       border-b
       border-b-greyLighter
       sticky
       top-0
       bg-white
       z-10`,
      className
    )}
  >
    {children}
  </div>
);

export const AppointmentList: React.FC<Props> = ({
  appointmentDurationInMinutes,
  openSlotsQuery,
  selections,
  onSelectedSlotUpdated,
}) => {
  const daysWithSlotsChunkedByInterval = useMemo(
    () => (openSlotsQuery.data ? chunkSlotsByInterval(openSlotsQuery.data) : null),
    [openSlotsQuery.data]
  );

  return (
    <div className="mb-4 w-full bg-white border border-greyLighter rounded">
      <QueryResult
        queries={[
          {
            isLoading: openSlotsQuery.status === "loading",
            isError: openSlotsQuery.isError,
          },
        ]}
        loading={<Skeleton containerClassName="h-96 w-full block" inline={true} className="h-full block" />}
      >
        {daysWithSlotsChunkedByInterval?.length ? (
          <div className="grid grid-cols-[210px_1fr] auto-rows-min relative">
            <TableHeader className="rounded-tl">Time Slots</TableHeader>
            <TableHeader className="rounded-tr">Available Providers & Hygienists</TableHeader>
            {daysWithSlotsChunkedByInterval.map((dayWithSlotsChunkedByInterval, outerIndex) => {
              return dayWithSlotsChunkedByInterval.slotsChunkedByInterval.length ? (
                <React.Fragment key={dayWithSlotsChunkedByInterval.date}>
                  {dayWithSlotsChunkedByInterval.slotsChunkedByInterval.map((openSlots, index) => (
                    <AppointmentIntervalSlots
                      key={index}
                      isLast={
                        outerIndex === daysWithSlotsChunkedByInterval.length - 1 &&
                        index === dayWithSlotsChunkedByInterval.slotsChunkedByInterval.length - 1
                      }
                      openSlots={openSlots}
                      selection={selections}
                      onSelectedSlotUpdated={onSelectedSlotUpdated}
                      appointmentDurationInMinutes={appointmentDurationInMinutes}
                    >
                      {index === 0 ? (
                        <div className="font-sansSemiBold text-xs text-greyDark mb-2">
                          {format(parseISO(dayWithSlotsChunkedByInterval.date), "EEEE, MMMM do")}
                        </div>
                      ) : null}
                    </AppointmentIntervalSlots>
                  ))}
                </React.Fragment>
              ) : null;
            })}
          </div>
        ) : (
          <AppointmentListPrompt>No Slots Available</AppointmentListPrompt>
        )}
      </QueryResult>
    </div>
  );
};
