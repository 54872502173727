import { ChangeEventHandler, FC, KeyboardEventHandler, useCallback, useMemo, useState } from "react";
import { PatientSummaryVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { getAgeByDob } from "@libs/utils/formatString";
import { isOneOf } from "@libs/utils/isOneOf";
import { formatPhoneNumber } from "@libs/utils/phone";
import { isDefined } from "@libs/utils/types";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { ReactComponent as RefreshIcon } from "@libs/assets/icons/refresh.svg";
import { ReactComponent as RemoveIcon } from "@libs/assets/icons/cancel-small.svg";
import { Menu, useMenu } from "@libs/components/UI/Menu";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { useNow } from "hooks/useNow";
import { Avatar } from "components/UI/Avatar";

interface SearchProps {
  patient: PatientSummaryVO;
}

const PatientSearchResult: FC<SearchProps> = ({ patient }) => {
  const now = useNow();
  const { contact, dob, gender, name, ssnLastFour } = patient;

  const dobAgeGender = useMemo(() => {
    const strings = [formatISODate(dob), getAgeByDob(now, dob)];

    if (gender) {
      strings.push(gender.charAt(0));
    }

    return strings.join(", ");
  }, [dob, gender, now]);

  return (
    <div className="flex items-center gap-x-3">
      <Avatar name={name.fullDisplayName} />
      <div className="flex flex-col items-start">
        <span className="text-sm text-slate-900 dark:text-slate-100">{name.fullDisplayName}</span>
        <div
          className={`
            flex
            gap-x-2
            text-xs
            text-slate-700
            divide-x
            divide-slate-300
            dark:text-slate-300
            dark:divide-slate-500
          `}
        >
          <span>{dobAgeGender}</span>
          {contact.callPhone ? <span className="pl-2">{formatPhoneNumber(contact.callPhone)}</span> : null}
          {ssnLastFour ? <span className="pl-2">SSN {ssnLastFour}</span> : null}
        </div>
      </div>
    </div>
  );
};

interface Props {
  isSearching: boolean;
  onClearSelected: Func;
  onSearch: (val: string) => void;
  onSelect: ((patientId: number | undefined) => void) | ((patientId: number) => void);
  patientSearchResults: PatientSummaryVO[];
  searchString: string;
}

export const TransferPatientSearch: FC<Props> = ({
  isSearching,
  onClearSelected,
  onSearch,
  onSelect,
  patientSearchResults,
  searchString,
}) => {
  const menu = useMenu<HTMLInputElement>();
  const [highlightIndex, setHighlightIndex] = useState<number>();

  const clearSearch = useCallback(() => {
    onSearch("");
    onClearSelected();
    setHighlightIndex(undefined);
  }, [onClearSelected, onSearch]);

  const handleOnChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      const val = e.target.value;

      onSearch(val);

      if (val) {
        menu.open();
      }
    },
    [menu, onSearch]
  );

  const handleSelect = useCallback(
    (patient: PatientSummaryVO) => {
      onSelect(patient.id);
      onSearch(patient.name.fullDisplayName);
      menu.close();
    },
    [menu, onSearch, onSelect]
  );

  const handleSearchKey = useCallback(
    (key: "ArrowUp" | "ArrowDown" | "Enter") => {
      switch (key) {
        case "ArrowDown": {
          setHighlightIndex((i) => Math.min((i ?? -1) + 1, patientSearchResults.length - 1));

          break;
        }
        case "ArrowUp": {
          setHighlightIndex((i) => Math.max((i ?? 1) - 1, 0));

          break;
        }
        case "Enter": {
          if (!isDefined(highlightIndex)) {
            return;
          }

          handleSelect(patientSearchResults[highlightIndex]);

          break;
        }
        // No default
      }
    },
    [handleSelect, highlightIndex, patientSearchResults]
  );

  const handleOnKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (isOneOf(e.key, ["ArrowUp", "ArrowDown", "Enter"])) {
        e.preventDefault();
        handleSearchKey(e.key);
      }
    },
    [handleSearchKey]
  );

  const menuOptions = useMemo(
    () =>
      createMenuOptions(
        ...patientSearchResults.map((patient, i) => ({
          // eslint-disable-next-line @typescript-eslint/naming-convention
          "aria-label": `patient-search-result-${i}`,
          highlighted: i === highlightIndex,
          label: <PatientSearchResult patient={patient} />,
          onMouseEnter: () => setHighlightIndex(i),
          value: i,
        }))
      ),
    [highlightIndex, patientSearchResults]
  );

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      handleSelect(patientSearchResults[option.value]);
    },
    [handleSelect, patientSearchResults]
  );

  return (
    <div className="flex flex-col">
      <FormFieldInput
        aria-label="transfer-patient-search"
        autoComplete="off"
        Icon={isSearching ? RefreshIcon : searchString ? RemoveIcon : SearchIcon}
        iconClassName={isSearching ? "animate-spin" : undefined}
        iconOnClick={isSearching ? undefined : searchString ? clearSearch : undefined}
        onChange={handleOnChange}
        onFocus={menu.open}
        onKeyDown={handleOnKeyDown}
        placeholder="Search Patients"
        ref={menu.triggerRef}
        value={searchString}
      />
      {menu.isOpen &&
        (patientSearchResults.length > 0 ? (
          <Menu
            className={`
              flex
              flex-col
              max-h-72
              overflow-y-auto
              border-greyLighter
              bg-white
              dark:bg-slate-800
              dark:border-slate-500
              border
              shadow-main
              rounded
            `}
            matchTriggerWidth={true}
            onRequestClose={menu.close}
            placement="bottom"
            triggerRef={menu.triggerRef}
          >
            <MenuOptions onOptionClick={handleOptionClick} options={menuOptions} />
          </Menu>
        ) : null)}
    </div>
  );
};
