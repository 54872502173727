import { TemplateVariableVO, TwilioOnboardingStatusVO, EmailConfigVO } from "@libs/api/generated-api";
import { isString } from "@libs/utils/types";

import { maybeVariableRegex, escapedVariableRegex } from "components/UI/RichTextEditor/templateVariables";

export const MAX_SMS_LENGTH = 400;

export const collectVariablesFromTemplate = (template: string) => {
  // Find instance that could be variables or escaped variables,
  // filter out escaped variables, and remove excess curly braces
  return (
    template
      .match(maybeVariableRegex)
      // eslint-disable-next-line unicorn/prefer-regexp-test
      ?.filter((maybeVariable) => !maybeVariable.match(escapedVariableRegex))
      .map((variable) => variable.replaceAll("{", "").replaceAll("}", "") as TemplateVariableVO["key"]) ?? []
  );
};

export const validateTemplateVariables = (set: Set<TemplateVariableVO["key"]>) => ({
  $v: (value: unknown) => {
    if (!isString(value)) {
      return true;
    }

    const variables = collectVariablesFromTemplate(value);

    if (!variables.length) {
      return true;
    }

    return variables.every((variable) => set.has(variable));
  },
  $error: "Variables in red are not available for the selected.",
});

export const isMassSmsConfigured = (twilioOnboardingStatus: TwilioOnboardingStatusVO) =>
  Boolean(twilioOnboardingStatus.status === "COMPLETE" && twilioOnboardingStatus.practiceCommsNumber);

export const isEmailConfigured = (emailConfig: EmailConfigVO) =>
  Boolean(emailConfig.domainVerified && emailConfig.replyToEmail);
