import { PatientToothVO } from "@libs/api/generated-api";
import { isNumber } from "@libs/utils/types";
import { required, min } from "@libs/utils/validators";
import { getValidation } from "@libs/hooks/useValidation";
import { getPatientProcedureSchema } from "components/Charting/patientProcedureSchema";
import {
  AppointmentProcedures,
  isNewProcedure,
} from "components/ScheduleAppointments/useAppointmentCategoriesAndProcedures";
import { endDateIsSameAsStart } from "components/ScheduleAppointments/utils";

export const getAppointmentFormSchema = (params: {
  teeth: PatientToothVO[] | undefined;
  ignoreSlotEdits: boolean;
  startTime: string;
  date: string | undefined;
}) => {
  const asapIgnore = { $ignore: params.ignoreSlotEdits };

  return {
    procedures: [
      {
        $v: (val: unknown) => {
          const { category, additional } = val as AppointmentProcedures;

          return [...category, ...additional].filter(isNewProcedure).every(
            (p) =>
              getValidation(
                p.draft,
                getPatientProcedureSchema(p.dentalProcedure, p.draft, params.teeth ?? [], {
                  provider: false,
                })
              ).$isValid
          );
        },
      },
    ],
    patientId: [{ $v: required }, { $v: min(1) }],
    duration: {
      $validations: [
        { $v: required },
        { $v: min(1) },
        {
          $v: (val: unknown) => isNumber(val) && endDateIsSameAsStart(params.startTime, val),
          $error: "End time must be before 12:00AM",
          $ignore: !params.date || !params.startTime,
        },
      ],
      ...asapIgnore,
    },

    dentistId: [
      { $v: required, ...asapIgnore },
      {
        $v: min(1),
        ...asapIgnore,
      },
    ],
    roomId: [
      { $v: required, ...asapIgnore },
      { $v: min(1), ...asapIgnore },
    ],
    date: [
      {
        $v: required,
        ...asapIgnore,
      },
    ],
    startTime: [
      {
        $v: required,
        ...asapIgnore,
      },
    ],
  };
};
