import { CSSProperties } from "react";
import { MedicalImageVO } from "@libs/api/generated-api";
import { containImage } from "@libs/utils/image";
import { half } from "@libs/utils/math";
import { getImageTransformStyles } from "components/PatientProfile/Imaging/MountRoute/image-utils";

export const getImageStylesWithRotationTransforms = (
  size: { width: number; height: number },
  image: Pick<MedicalImageVO, "w" | "h" | "transforms">,
  options: { canExceedImageSize: boolean }
) => {
  const { transform, sizeInverted, rotationDegrees } = getImageTransformStyles(image.transforms);
  const container = { w: size.width, h: size.height };

  const baseWidth = options.canExceedImageSize ? size.width : image.w ?? 0;
  const baseHeight = options.canExceedImageSize ? size.height : image.h ?? 0;

  const containedImage = containImage(
    sizeInverted ? { w: baseHeight, h: baseWidth } : { w: baseWidth, h: baseHeight },
    container
  );
  const baseStyles = { width: Math.ceil(containedImage.width), height: Math.ceil(containedImage.height) };

  let styles: CSSProperties = {
    transform,
    ...baseStyles,
    top: 0,
    left: 0,
  };

  if (sizeInverted) {
    const aspectOffset = Math.floor(half(baseStyles.width - baseStyles.height));

    styles = {
      ...styles,
      width: baseStyles.height,
      height: baseStyles.width,
      top: -aspectOffset,
      left: aspectOffset,
    };
  }

  return {
    styles,
    containedImage,
    baseStyles: { width: containedImage.width, height: containedImage.height },
    sizeInverted,
    rotationDegrees,
  };
};
