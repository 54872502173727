import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { format } from "date-fns";
import { PatientProcedureVO } from "@libs/api/generated-api";
import { nowInTimezone } from "@libs/utils/date";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { flattenPages, getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { ItemsCount } from "@libs/components/UI/ItemsCount";
import { QueryFilterPillsContainer } from "@libs/components/UI/QueryFilterPills";
import { ProceduresTableMaster } from "components/Charting/ProceduresTableMaster";
import { ConfirmDeleteModal } from "components/Charting/ConfirmDeleteModal";
import { EditPatientProcedureModal } from "components/Charting/EditPatientProcedureModal";
import { ProceduresBulkActionsMenu } from "components/Charting/ProceduresBulkActionsMenu";
import {
  PatientProceduresTableLayout,
  ProceduresHeaderCell,
} from "components/Charting/ProceduresTableComponents";
import { PreAuthLoadingModal } from "components/Charting/PreAuthLoadingModal";
import { usePatientProceduresTable } from "components/Charting/usePatientProceduresTable";
import { usePathParams } from "hooks/usePathParams";
import {
  CheckColumn,
  DateColumn,
  ProcedureAreaColumn,
  CdtCodeColumn,
  DescriptionColumn,
  ProviderColumn,
  NegFeesColumn,
  InsAmtColumn,
  PtAmtColumn,
  EditColumn,
  PriorityColumn,
  PreAuthColumn,
  CDTCodeCell,
  ApptDateCell,
  DescriptionCell,
  InsuranceAmountCell,
  NegotiatedAmountCell,
  PatientAmountCell,
  PreAuthStatusCell,
  PriorityCell,
  ProviderCell,
  ToothSurfaceCell,
  TreatmentTypeColumn,
  TreatmentTypeCell,
  CreatedDateColumn,
  CreatedDateCell,
  ActionsCell,
  LabCaseStatusCell,
  LabCaseStatusColumn,
  DecuctionsColumn,
  DeductibleStatusCell,
} from "components/Charting/ProcedureTableCells";
import { useNow } from "hooks/useNow";
import {
  DefaultSortMap,
  PatientProcedureSortColumns,
  masterTpSortingPerPrimaryKey,
} from "utils/routing/charting";
import { createTreatmentPlan } from "api/charting/mutations";
import { handleError } from "utils/handleError";
import { paths } from "utils/routing/paths";
import { useItemModal } from "hooks/useItemModal";
import { getInfinitePatientProceduresQuery } from "api/charting/queries";
import { getPatientInsurancesQuery } from "api/patientInsurance/queries";
import { useQueryParams } from "hooks/useQueryParams";
import { ConfirmInsuranceEstimateModal } from "components/Insurance/ConfirmInsuranceEstimateModal";
import { PatientProceduresQueryFilterPills } from "components/Charting/PatientProceduresQueryFilterPills";
import { KioskModal } from "components/PatientProfile/Forms/KioskModal";
import { SendTreatmentPlanModal } from "components/Charting/SendTreatmentPlanModal";
import { useLabCaseStatus } from "components/LabCases/hooks/useLabCaseStatus";
import { LabCaseFlyover } from "components/LabCases/LabCaseFlyover";
import { EditEstimatesFlyover } from "components/Charting/EditEstimatesFlyover";

const columns: { width: string; text: string; id: string; sortKey?: PatientProcedureSortColumns }[] = [
  CheckColumn,
  {
    ...PriorityColumn,
    sortKey: "priority" as const,
  },
  TreatmentTypeColumn,
  {
    ...CreatedDateColumn,
    sortKey: "createdAt" as const,
  },

  {
    ...DateColumn,
    sortKey: "date" as const,
  },
  {
    ...ProcedureAreaColumn,
    sortKey: "procedureArea" as const,
  },
  CdtCodeColumn,
  DescriptionColumn,
  ProviderColumn,
  NegFeesColumn,
  InsAmtColumn,
  PtAmtColumn,
  DecuctionsColumn,
  PreAuthColumn,
  LabCaseStatusColumn,
  EditColumn,
];

// eslint-disable-next-line complexity
export const PatientProceduresTableRoute: FC = () => {
  const practice = useCurrentPractice();
  const navigate = useNavigate();
  const location = useLocation();
  const { patientId } = usePathParams("charting");
  const { query, updateQuery } = useQueryParams("charting");
  const confirmDeleteModal = useItemModal<PatientProcedureVO[]>(null);

  const now = useNow();
  const apiSort = query.sort.map(([column, direction]) => `${column}-${direction}`);
  const queryKey = `${location.pathname}${apiSort.join(",")}`;

  const patientProceduresQuery = useInfiniteApiQuery(
    getInfinitePatientProceduresQuery({
      args: {
        practiceId: practice.id,
        patientId,
        pageSize: PAGE_SIZE,
        pageNumber: 1,
        includeStatuses: query.patientProcedureStatuses,
        sort: apiSort,
      },
    })
  );

  const [{ data: patientInsurances }] = useApiQueries([
    getPatientInsurancesQuery({ args: { practiceId: practice.id, patientId, insuranceState: ["ACTIVE"] } }),
  ]);

  const { labCaseReturnReasons, onLabCaseStatusChange } = useLabCaseStatus();
  const labCaseFlyover = useItemModal<PatientProcedureVO>(null);
  const [createTreatmentPlanMutation] = useApiMutations([createTreatmentPlan]);

  const { procedureIds, procedures } = useMemo(() => {
    const items = flattenPages(patientProceduresQuery.data);

    return {
      procedures: items,
      procedureIds: items?.map((proc) => proc.id),
    };
  }, [patientProceduresQuery.data]);

  const totalCount = getInfiniteQueryPagingDetails(patientProceduresQuery.data)?.totalElements ?? 0;

  const sortUpdates: Partial<DefaultSortMap<PatientProcedureSortColumns>> = masterTpSortingPerPrimaryKey;

  const {
    handleCheckboxChange,
    resetSelectedRows,
    handleSendProceduresToPreAuthClick,
    handleAddProceduresToTreatmentPlanClick,
    handleScheduleProceduresClick,
    handlePatientProcedureSaved,
    deleteMasterProcedures,
    handlePrioritizeProcedures,
    handleEditClick,
    handleEditEstimatesClick,
    handleCreateTreatmentPlanFormTask,
    handlePriorityChanged,
    handleSendPreAuthClick,
    isCreatingPreAuth,
    selectedRows,
    treatmentPlans,
    editPatientProceduresModal,
    editProcedureEstimatesFlyover,
    confirmProceduresEstimateModal,
    confirmProceduresEstimateModalTitle,
    kioskModal,
    sendFormsModal,
    formTaskLoading,
    setRows,
  } = usePatientProceduresTable({
    patientId,
    procedureIds,
    patientInsurances,
  });

  const viewedRows = useRef<Set<number>>(new Set());

  const handleRowViewed = useCallback((id: number) => {
    viewedRows.current.add(id);
  }, []);

  const handleSelectAllViewedRows = useCallback(() => {
    setRows(new Set(viewedRows.current));
  }, [setRows]);

  const handleDeleteMasterTPProceduresClick = useCallback(
    (selectedProcedures: PatientProcedureVO[]) => {
      confirmDeleteModal.open(selectedProcedures);
    },
    [confirmDeleteModal]
  );

  const handleConfirmDeleteProcedures = useCallback(() => {
    if (confirmDeleteModal.isOpen) {
      deleteMasterProcedures(confirmDeleteModal.item);
      confirmDeleteModal.close();
    }
  }, [deleteMasterProcedures, confirmDeleteModal]);

  const handleCreateTreatmentPlanClick = useCallback(
    (selectedProcedures: PatientProcedureVO[]) => {
      const rightNow = nowInTimezone(practice.timezoneId);

      createTreatmentPlanMutation.mutate(
        {
          practiceId: practice.id,
          data: {
            name: `Plan ${format(rightNow, "M.d.yyyy hh:mm:ss")}`,
            patientId,
            patientProcedures: selectedProcedures,
          },
        },
        {
          onError: handleError,
          onSuccess: (response) =>
            navigate(
              paths.charting(
                {
                  patientId,
                },
                {
                  treatmentPlanUuid: response.data.data.uuid,
                }
              )
            ),
        }
      );
    },
    [patientId, practice.id, createTreatmentPlanMutation, navigate, practice.timezoneId]
  );

  const handleSort = (sortColumn: PatientProcedureSortColumns) => {
    const [currentColumn, currentDirection] = query.sort[0];

    if (currentColumn === sortColumn) {
      const copy = [...query.sort];

      copy[0] = [currentColumn, currentDirection === "ASCENDING" ? "DESCENDING" : "ASCENDING"];
      updateQuery("replaceIn", {
        sort: copy,
      });
    } else {
      updateQuery("replaceIn", {
        sort: sortUpdates[sortColumn],
      });
    }
  };

  useEffect(() => {
    resetSelectedRows();
    viewedRows.current = new Set();
  }, [query.patientProcedureStatuses, query.sort, resetSelectedRows]);

  const totalCountRef = useRef(totalCount);

  // this allows us to not display a different count until we have new results
  useEffect(() => {
    if (patientProceduresQuery.data) {
      totalCountRef.current = totalCount;
    }
  }, [totalCount, patientProceduresQuery.data]);

  const emptyMessage =
    totalCount === 0
      ? query.patientProcedureStatuses.length === 0
        ? "No procedures have been created."
        : "No procedures match the current filter."
      : "";

  return (
    <>
      <PatientProceduresTableLayout
        menuRow={
          <div className="flex gap-x-6 items-start">
            <ProceduresBulkActionsMenu
              className="py-0.5"
              procedures={procedures}
              totalCount={totalCount}
              treatmentPlans={treatmentPlans}
              selectedRows={selectedRows}
              isPrinting={formTaskLoading === "print"}
              isSendingToKiosk={formTaskLoading === "kiosk"}
              onCreateTreatmentPlanClick={handleCreateTreatmentPlanClick}
              onAddProceduresToTreatmentPlanClick={handleAddProceduresToTreatmentPlanClick}
              handleCreateTreatmentPlanFormTask={handleCreateTreatmentPlanFormTask}
              onScheduleProceduresClick={handleScheduleProceduresClick}
              onDeleteProceduresClick={handleDeleteMasterTPProceduresClick}
              onSendProceduresToPreAuthClick={handleSendProceduresToPreAuthClick}
              onPrioritizeProceduresClick={handlePrioritizeProcedures}
            />
            <QueryFilterPillsContainer>
              <ItemsCount
                className="whitespace-nowrap"
                selectedCount={selectedRows.size}
                total={patientProceduresQuery.isLoading ? totalCountRef.current : totalCount}
                singular="procedure"
                plural="procedures"
              />
              <PatientProceduresQueryFilterPills
                query={query}
                onUpdate={(params) => updateQuery("replaceIn", params)}
              />
            </QueryFilterPillsContainer>
          </div>
        }
      >
        <ProceduresTableMaster
          totalCount={totalCount}
          queryKey={queryKey}
          emptyMessage={emptyMessage}
          proceduresQuery={patientProceduresQuery}
          columns={columns}
          onToggleRow={handleCheckboxChange}
          onRowViewed={handleRowViewed}
          selectedRows={selectedRows}
          editProcedureId={
            editPatientProceduresModal.item?.patientProcedure.id ?? editProcedureEstimatesFlyover.item?.id
          }
          renderHeaderCell={(col) => (
            <ProceduresHeaderCell
              column={col}
              totalRows={totalCount}
              selectedRows={selectedRows}
              onSelectAllRows={handleSelectAllViewedRows}
              onDeselectAllRows={resetSelectedRows}
              onSort={handleSort}
              sort={query.sort[0]}
            />
          )}
          renderRow={(procedure) => (
            <>
              <PriorityCell
                procedure={procedure}
                onPriorityChanged={handlePriorityChanged}
                onClick={handleEditClick}
              />
              <TreatmentTypeCell onClick={handleEditClick} procedure={procedure} />
              <CreatedDateCell procedure={procedure} onClick={handleEditClick} />
              <ApptDateCell onClick={handleEditClick} procedure={procedure} />
              <ToothSurfaceCell onClick={handleEditClick} procedure={procedure} />
              <CDTCodeCell now={now} onClick={handleEditClick} procedure={procedure} />
              <DescriptionCell onClick={handleEditClick} procedure={procedure} />
              <ProviderCell onClick={handleEditClick} procedure={procedure} />
              <NegotiatedAmountCell onClick={handleEditClick} procedure={procedure} />
              <InsuranceAmountCell onClick={handleEditClick} procedure={procedure} />
              <PatientAmountCell onClick={handleEditClick} procedure={procedure} />
              <DeductibleStatusCell procedure={procedure} onClick={handleEditClick} />
              <PreAuthStatusCell procedure={procedure} onClick={handleEditClick} />
              <LabCaseStatusCell
                labCaseReturnReasons={labCaseReturnReasons}
                procedure={procedure}
                onClick={labCaseFlyover.open}
                onStatusChange={(...args) => {
                  procedure.labCaseStatus?.uuid &&
                    onLabCaseStatusChange(procedure.labCaseStatus.uuid, ...args).catch(handleError);
                }}
              />
              <ActionsCell
                onSendPreAuthClick={handleSendPreAuthClick}
                onEditProcedureClick={handleEditClick}
                onEditEstimatesClick={handleEditEstimatesClick}
                onEditLabCaseClick={procedure.labCaseStatus ? labCaseFlyover.open : undefined}
                procedure={procedure}
              />
            </>
          )}
        />
      </PatientProceduresTableLayout>

      {confirmDeleteModal.isOpen ? (
        <ConfirmDeleteModal
          onCancel={confirmDeleteModal.close}
          onConfirm={handleConfirmDeleteProcedures}
          procedures={confirmDeleteModal.item}
        />
      ) : null}
      {isCreatingPreAuth ? <PreAuthLoadingModal /> : null}
      {editPatientProceduresModal.isOpen ? (
        <EditPatientProcedureModal
          onRequestClose={editPatientProceduresModal.close}
          onSaved={() => {
            handlePatientProcedureSaved();
            editPatientProceduresModal.close();
          }}
          validateOptions={editPatientProceduresModal.item.validateOptions}
          patientProcedureId={editPatientProceduresModal.item.patientProcedure.id}
          patientProcedure={editPatientProceduresModal.item.patientProcedure}
          patientId={patientId}
        />
      ) : null}

      {editProcedureEstimatesFlyover.isOpen ? (
        <EditEstimatesFlyover
          patientProcedureId={editProcedureEstimatesFlyover.item.id}
          onSaved={handlePatientProcedureSaved}
          onClose={editProcedureEstimatesFlyover.close}
        />
      ) : null}

      {confirmProceduresEstimateModal.isOpen ? (
        <ConfirmInsuranceEstimateModal
          type={
            confirmProceduresEstimateModal.item.method === "print" ? "print-procedures" : "send-procedures"
          }
          title={confirmProceduresEstimateModalTitle}
          onConfirm={(forceInsuranceEstimate) =>
            handleCreateTreatmentPlanFormTask({
              ...confirmProceduresEstimateModal.item,
              forceInsuranceEstimate,
            })
          }
          onRequestClose={confirmProceduresEstimateModal.close}
        />
      ) : null}
      {kioskModal.item ? <KioskModal onRequestClose={kioskModal.close} kioskCode={kioskModal.item} /> : null}
      {sendFormsModal.item ? (
        <SendTreatmentPlanModal
          patientId={patientId}
          formTaskRequest={sendFormsModal.item}
          onRequestClose={sendFormsModal.close}
        />
      ) : null}
      {labCaseFlyover.isOpen ? (
        <LabCaseFlyover
          labCaseUuid={labCaseFlyover.item.labCaseStatus?.uuid}
          procedureId={labCaseFlyover.item.id}
          patientId={patientId}
          onClose={labCaseFlyover.close}
          onSave={labCaseFlyover.close}
        />
      ) : null}
    </>
  );
};
