import React, { useCallback, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { ClaimLineItemData, ClaimPaymentSummaryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatCurrency } from "@libs/utils/currency";
import { formatISODate } from "@libs/utils/date";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { noop } from "@libs/utils/noop";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as DownCaretIcon } from "@libs/assets/icons/down-caret.svg";
import { ReactComponent as UpCaretIcon } from "@libs/assets/icons/up-caret.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { ButtonCell, Row } from "@libs/components/UI/GridTableComponents";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { ClaimLineItemActions } from "components/Eob/EobRoute";
import { updateEobVerified } from "api/claim/mutations";
import { getClaimQuery } from "api/claim/queries";
import { handleError } from "utils/handleError";
import { useItemModal } from "hooks/useItemModal";
import { paths } from "utils/routing/paths";
import { ProcedureTable } from "components/Claim/ProcedureTable";
import { getProcedureTableHeaders } from "components/Claim/procedureTableUtils";

interface Props {
  claimPaymentSummary: ClaimPaymentSummaryVO;
  disableActions: boolean;
  isExpanded: boolean;
  onDeleteClaim: (claimUuid: string) => void;
  onToggleRow: (claimUuid: string) => void;
  onUpdateGroupedClaimLineItems: (
    claimUuid: string,
    action: ClaimLineItemActions,
    options?: {
      hasErrors: boolean;
      lineItemIndex: number;
      updates: ClaimLineItemData;
    }
  ) => void;
}

export const cxStyles = {
  cell: (isExpanded: boolean) =>
    cx(
      `flex
       items-center
       py-2
       group-hover:bg-slate-100
       group-hover:cursor-pointer
       last:pr-2`,
      isExpanded && "border-b-0 bg-slate-100 font-sansSemiBold"
    ),
  currencyValueCell: "justify-end",
};

// eslint-disable-next-line complexity
export const EobPaymentTableRow: React.FC<Props> = ({
  claimPaymentSummary,
  disableActions,
  isExpanded,
  onDeleteClaim,
  onToggleRow,
  onUpdateGroupedClaimLineItems,
}) => {
  const { practiceId } = useAccount();
  const [queryClaimUuid, setQueryClaimUuid] = useState<string>();

  useEffect(() => {
    if (isExpanded) {
      setQueryClaimUuid(claimPaymentSummary.uuid);
    }
  }, [claimPaymentSummary.uuid, isExpanded]);

  const handleToggleClaimRow = useCallback(() => {
    setQueryClaimUuid(claimPaymentSummary.uuid);
    onToggleRow(claimPaymentSummary.uuid);
  }, [claimPaymentSummary.uuid, onToggleRow]);

  const claimLink = paths.claim({ claimUuid: claimPaymentSummary.uuid });
  const isAdditionalPayment = claimPaymentSummary.latestVersionType === "ADDITIONAL";
  const [claimIsVerified, setClaimIsVerified] = useState(claimPaymentSummary.eobVerified);

  const removeClaimModal = useItemModal<string>(null);

  const handleConfirmDeleteClaim = useCallback(() => {
    onDeleteClaim(claimPaymentSummary.uuid);
    removeClaimModal.close();
    setQueryClaimUuid(undefined);
  }, [claimPaymentSummary.uuid, onDeleteClaim, removeClaimModal]);

  const cellClassName = cxStyles.cell(isExpanded);
  const baseButtonCellProps = {
    border: !isExpanded,
    className: cellClassName,
    onClick: handleToggleClaimRow,
  };

  const [{ data: claim }] = useApiQueries([
    getClaimQuery({
      args: { practiceId, claimUuid: queryClaimUuid || "" },
      queryOptions: { enabled: Boolean(queryClaimUuid) },
    }),
  ]);

  const [updateEobVerifiedMutation] = useApiMutations([updateEobVerified]);

  const handleToggleClaimVerification = useCallback(async () => {
    try {
      await updateEobVerifiedMutation.mutateAsync({
        practiceId,
        claimUuid: claimPaymentSummary.uuid,
        query: {
          verified: !claimIsVerified,
        },
      });
      setClaimIsVerified((last) => !last);
    } catch (err) {
      handleError(err);
    }
  }, [updateEobVerifiedMutation, practiceId, claimPaymentSummary.uuid, claimIsVerified, setClaimIsVerified]);

  const lastLineItemsGroupIndex = (claim?.lineItemGroups.length ?? 1) - 1;

  return (
    <Row>
      <ButtonCell {...baseButtonCellProps} data-testid={`eob-claim-${claimPaymentSummary.claimNumber}-row`}>
        {isExpanded ? (
          <FloatingTooltip content="Collapse" theme="SMALL">
            <UpCaretIcon className="fill-primaryTheme w-4 h-4" />
          </FloatingTooltip>
        ) : (
          <FloatingTooltip content="Expand" theme="SMALL">
            <DownCaretIcon className="fill-primaryTheme w-4 h-4" />
          </FloatingTooltip>
        )}
      </ButtonCell>
      <ButtonCell {...baseButtonCellProps}>{formatISODate(claimPaymentSummary.serviceDate)}</ButtonCell>
      <ButtonCell {...baseButtonCellProps}>{claimPaymentSummary.patientName.firstName}</ButtonCell>
      <ButtonCell {...baseButtonCellProps}>{claimPaymentSummary.patientName.lastName}</ButtonCell>
      <ButtonCell {...baseButtonCellProps}>
        <FloatingTooltip content="Open Claim" theme="SMALL">
          <Link className="hover:text-primaryTheme" to={claimLink}>
            {claimPaymentSummary.claimNumber}
          </Link>
        </FloatingTooltip>
      </ButtonCell>
      <ButtonCell {...baseButtonCellProps}>
        {sentenceCaseConstant(claimPaymentSummary.insuranceOrdinal)}
        {isAdditionalPayment && <span className="font-sansSemiBold text-orange">&nbsp;Additional</span>}
      </ButtonCell>
      <ButtonCell {...baseButtonCellProps}>
        {claimPaymentSummary.insuranceMemberId ?? "SSN (Hidden)"}
      </ButtonCell>
      <ButtonCell {...baseButtonCellProps} className={cx(cellClassName, cxStyles.currencyValueCell)}>
        {formatCurrency(claimPaymentSummary.unpaidPatientAmount)}
      </ButtonCell>
      <ButtonCell {...baseButtonCellProps} className={cx(cellClassName, cxStyles.currencyValueCell)}>
        {formatCurrency(claimPaymentSummary.unpaidInsuranceAmount)}
      </ButtonCell>
      <ButtonCell
        {...baseButtonCellProps}
        className={cx("justify-center", cellClassName, disableActions && "cursor-default")}
        onClick={disableActions ? noop : handleToggleClaimVerification}
      >
        <FloatingTooltip
          theme="SMALL"
          content={disableActions ? "" : claimIsVerified ? "Mark unverified" : "Mark verified"}
        >
          <div
            className={cx(
              "h-3 w-3 border rounded-full",
              disableActions ? "border-slate-400" : "border-primaryTheme",
              claimIsVerified && (disableActions ? "bg-slate-400" : "bg-primaryTheme")
            )}
          />
        </FloatingTooltip>
      </ButtonCell>
      <ButtonCell
        {...baseButtonCellProps}
        onClick={(event) => {
          event.stopPropagation();
          removeClaimModal.open(claimPaymentSummary.uuid);
        }}
      >
        <Icon
          disabled={disableActions}
          SvgIcon={DeleteIcon}
          size="sm"
          theme="primary"
          tooltip={{ content: "Remove", theme: "SMALL" }}
        />
      </ButtonCell>
      {isExpanded ? (
        <div
          className={`
            col-span-full
            px-5
            pb-5
            bg-slate-100
            border-b
            border-b-greyLighter
          `}
        >
          {claim ? (
            <ProcedureTable
              claim={claim}
              disableActions={disableActions}
              headers={getProcedureTableHeaders({ claim, isAdditionalPayment })}
              isAdditionalPayment={isAdditionalPayment}
              isDraftEobView={true}
              lineItems={claim.lineItemGroups[lastLineItemsGroupIndex].lineItems}
              onUpdateGroupedClaimLineItems={onUpdateGroupedClaimLineItems}
              previousLineItems={
                isAdditionalPayment ? claim.lineItemGroups[lastLineItemsGroupIndex - 1].lineItems : undefined
              }
              showTotals={true}
              version={claim.lineItemGroups[lastLineItemsGroupIndex].version}
            />
          ) : (
            <Skeleton />
          )}
        </div>
      ) : null}
      {removeClaimModal.isOpen && (
        <ConfirmationModal
          onCancel={removeClaimModal.close}
          onConfirm={handleConfirmDeleteClaim}
          primaryText="Are you sure you want to remove this claim?"
          secondaryText="Please check carefully whether this claim is not a part of the EOB you want to process."
        />
      )}
    </Row>
  );
};
