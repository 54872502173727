import { ClaimVO } from "@libs/api/generated-api";
import { FooterDraft } from "components/Claim/FooterDraft";
import { FooterPreAuth } from "components/Claim/FooterPreAuth";
import { FooterSent } from "components/Claim/FooterSent";

interface Props {
  claim: ClaimVO;
  isDraft: boolean;
  isInDraftEobPayment: boolean;
  isPreAuth: boolean;
  isPrimaryClaim: boolean;
  isSubmitted: boolean;
  isSubmitting: boolean;
  disableActions: boolean;
  onCancelClick: Func;
  onVoidClick: Func;
  onCreateDraftEobPayment: Func;
  onManuallySubmitClick: Func;
  onResubmitClick: Func;
  onSkipPaymentClick: Func;
  onDeleteClick: Func;
  onDeleteAndCreateClick: Func;
  onSubmitClick: (overrideNeedsAttachment?: boolean) => void;
}

export const DetailsPageFooter: React.FC<Props> = ({
  claim,
  isDraft,
  isInDraftEobPayment,
  isPreAuth,
  isPrimaryClaim,
  isSubmitted,
  isSubmitting,
  disableActions,
  onCancelClick,
  onVoidClick,
  onCreateDraftEobPayment,
  onManuallySubmitClick,
  onResubmitClick,
  onSkipPaymentClick,
  onDeleteClick,
  onDeleteAndCreateClick,
  onSubmitClick,
}) => {
  return isPreAuth ? (
    <FooterPreAuth
      claim={claim}
      isDraft={isDraft}
      isPrimaryClaim={isPrimaryClaim}
      isSubmitting={isSubmitting}
      onCancelClick={onCancelClick}
      onSubmitClick={onSubmitClick}
      disableActions={disableActions}
    />
  ) : !isInDraftEobPayment && (isDraft || isSubmitting) ? (
    <FooterDraft
      claim={claim}
      isPrimaryClaim={isPrimaryClaim}
      isSubmitting={isSubmitting}
      onCancelClick={onCancelClick}
      onManuallySubmitClick={onManuallySubmitClick}
      onSkipPaymentClick={onSkipPaymentClick}
      onDeleteClick={onDeleteClick}
      onDeleteAndCreateClick={onDeleteAndCreateClick}
      onSubmitClick={onSubmitClick}
      disableActions={disableActions}
    />
  ) : (
    <FooterSent
      claim={claim}
      isDraft={isDraft}
      isPreAuth={isPreAuth}
      isSubmitted={isSubmitted}
      isSubmitting={isSubmitting}
      onCreateDraftEobPayment={onCreateDraftEobPayment}
      onResubmitClick={onResubmitClick}
      onVoidClick={onVoidClick}
      onCancelClick={onCancelClick}
      disableActions={disableActions}
    />
  );
};
