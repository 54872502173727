import { FC, useRef, useMemo, useCallback } from "react";
import { EditorRef } from "react-email-editor";

import { UnlayerTokenVO, TemplateVariableGroupVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as VerticalMenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { Panel } from "@libs/components/UI/Panel";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";

import { EmailEditorEmbed } from "components/Communications/Campaigns/EmailEditor/EmailEditorEmbed";
import {
  exportEmailEditorTemplate,
  exportEmailEditorImage,
  getEmailEditorOptions,
} from "components/Communications/Campaigns/EmailEditor/utils";

import { useEnvContext } from "contexts/EnvContext";

import { Prompt } from "components/UI/Prompt";

interface Props {
  emailTemplateJson: string | undefined;
  unlayerToken: UnlayerTokenVO;
  templateVariables: TemplateVariableGroupVO[];
  isSaving: boolean;
  isDirty: boolean;
  onSaveEmailTemplate: (updates: { html: string; json: string }, previewUrl: string | undefined) => void;
  onChangeTemplate: Func;
  onDirty: Func;
  from: string;
}

export const EmailEditor: FC<Props> = ({
  emailTemplateJson,
  unlayerToken,
  templateVariables,
  isSaving,
  isDirty,
  onSaveEmailTemplate,
  onChangeTemplate,
  onDirty,
  from,
}) => {
  const { REACT_APP_UNLAYER_PROJECT_ID } = useEnvContext();
  const editorRef = useRef<EditorRef>(null);

  const menu = useBoolean(false);
  const confirmChangeTemplateModal = useBoolean(false);

  const emailExport = useBoolean(false);
  const emailExportOn = emailExport.on;
  const emailExportOff = emailExport.off;

  const editorOptions = useMemo(
    () =>
      getEmailEditorOptions({
        unlayerProjectId: REACT_APP_UNLAYER_PROJECT_ID,
        unlayerToken,
        templateVariables,
      }),
    [REACT_APP_UNLAYER_PROJECT_ID, unlayerToken, templateVariables]
  );

  const menuOptions = useMemo(
    () =>
      createMenuOptions({
        label: "Change Template",
        value: "change-template",
      }),
    []
  );

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "change-template": {
          confirmChangeTemplateModal.on();
          break;
        }
        default: {
          break;
        }
      }

      menu.off();
    },
    [menu, confirmChangeTemplateModal]
  );

  const handleSave = useCallback(async () => {
    if (!editorRef.current?.editor) {
      return;
    }

    const { editor } = editorRef.current;

    emailExportOn();

    const previewUrl = (await exportEmailEditorImage(editor)) ?? undefined;
    const emailTemplate = await exportEmailEditorTemplate(editor);

    emailExportOff();
    onSaveEmailTemplate(emailTemplate, previewUrl);
  }, [editorRef, emailExportOn, emailExportOff, onSaveEmailTemplate]);

  return (
    <>
      <Panel
        title="Email Builder"
        className="h-full"
        includePadding={false}
        actions={
          <ButtonMenu
            menuContent={
              <div className="w-36">
                <MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />
              </div>
            }
            onRequestOpen={menu.on}
            onRequestClose={menu.off}
            isOpen={menu.isOn}
            placement="bottom-end"
          >
            {(props) => <ButtonIcon SvgIcon={VerticalMenuIcon} theme="primary" {...props} />}
          </ButtonMenu>
        }
        footer={
          <div className="flex justify-center gap-x-2">
            <ButtonInternalLink className="min-w-button" to={from} theme="secondary" replace>
              Close
            </ButtonInternalLink>
            <AsyncButton
              className="min-w-button"
              onClick={handleSave}
              isLoading={emailExport.isOn || isSaving}
            >
              Save
            </AsyncButton>
          </div>
        }
      >
        {emailTemplateJson ? (
          <EmailEditorEmbed
            editorRef={editorRef}
            emailTemplateJson={emailTemplateJson}
            options={editorOptions}
            onUpdate={onDirty}
          />
        ) : null}
      </Panel>

      {confirmChangeTemplateModal.isOn ? (
        <ConfirmationModal
          primaryText="Change Template"
          secondaryText="Changing the template will result in losing all existing work. Are you sure you want to proceed?"
          confirmText="Continue"
          onConfirm={onChangeTemplate}
          onCancel={confirmChangeTemplateModal.off}
          size="3xs"
        />
      ) : null}
      {isDirty ? (
        <Prompt message="There are unsaved changes in the email builder? Are you sure you want to proceed?" />
      ) : null}
    </>
  );
};
