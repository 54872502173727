import { FC, useCallback, useMemo } from "react";
import { PermissionActionVO, RoleRequest } from "@libs/api/generated-api";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import {
  DOMAIN_DATA,
  PERMISSIONS_DATA,
  DOMAINS_LOCKED,
  getLockedDomainTooltip,
} from "components/Roles/constants";
import { groupPermissions } from "components/Roles/roleUtils";
import { PermissionList, DomainPermissionItem, SubPermissionItem } from "./RoleFormComponents";

export const RoleForm: FC<{
  allPermissions: PermissionActionVO[];
  draftRole: RoleRequest;
  viewOnly: boolean;
  errors: Record<"name", string | undefined>;
  onDraftRoleChange: (draftRole: RoleRequest) => void;
}> = ({ allPermissions, draftRole, viewOnly, errors, onDraftRoleChange }) => {
  const handlePermissionToggle = useCallback(
    (permissionUuid: string, isOn: boolean) => {
      onDraftRoleChange({
        ...draftRole,
        permissionActions: draftRole.permissionActions.map((permissionAction) => {
          if (permissionAction.permissionActionUuid === permissionUuid) {
            return {
              ...permissionAction,
              effect: isOn ? "ALLOW" : "DENY",
            };
          }

          return permissionAction;
        }),
      });
    },
    [draftRole, onDraftRoleChange]
  );

  const handleTimeLimitChange = useCallback(
    (permissionUuid: string, daysLimit: number | undefined) => {
      onDraftRoleChange({
        ...draftRole,
        permissionActions: draftRole.permissionActions.map((permissionAction) => {
          if (permissionAction.permissionActionUuid === permissionUuid) {
            if (daysLimit == null) {
              // If the user clears the time limit, we remove the time limit condition
              const conditions = { ...permissionAction.conditions };

              delete conditions.numberOfDaysLimit;

              return {
                ...permissionAction,
                conditions,
              };
            }

            return {
              ...permissionAction,
              conditions: {
                ...permissionAction.conditions,
                numberOfDaysLimit: daysLimit,
              },
            };
          }

          return permissionAction;
        }),
      });
    },
    [draftRole, onDraftRoleChange]
  );

  const groupedPermissions = useMemo(() => groupPermissions(allPermissions), [allPermissions]);

  return (
    <div className="flex flex-col gap-y-6">
      <FormFieldInput
        label="Name"
        required
        value={draftRole.name}
        onChange={(e) => onDraftRoleChange({ ...draftRole, name: e.target.value })}
        autoFocus
        disabled={viewOnly}
        error={errors.name}
      />
      <div className="flex flex-col gap-y-2">
        <div className="text-xs font-sansSemiBold">Permissions</div>
        <PermissionList>
          {groupedPermissions.map((group) => {
            const [domainPermission, ...subPermissions] = group;
            const isOn = draftRole.permissionActions.some(
              (permission) =>
                permission.permissionActionUuid === domainPermission.uuid && permission.effect === "ALLOW"
            );

            return (
              <DomainPermissionItem
                key={domainPermission.uuid}
                label={DOMAIN_DATA[domainPermission.domain].label}
                description={DOMAIN_DATA[domainPermission.domain].description}
                disabled={viewOnly || DOMAINS_LOCKED.has(domainPermission.domain)}
                tooltip={
                  DOMAINS_LOCKED.has(domainPermission.domain) ? getLockedDomainTooltip(isOn) : undefined
                }
                isOn={isOn}
                onToggle={(value) => {
                  handlePermissionToggle(domainPermission.uuid, value);
                }}
              >
                {subPermissions.map((subPermission) => (
                  <SubPermissionItem
                    key={subPermission.uuid}
                    label={PERMISSIONS_DATA[subPermission.action].label}
                    tooltip={PERMISSIONS_DATA[subPermission.action].tooltip}
                    withTimeLimit={PERMISSIONS_DATA[subPermission.action].withTimeLimit}
                    disabled={viewOnly}
                    timeLimitValue={
                      draftRole.permissionActions.find(
                        (permission) => permission.permissionActionUuid === subPermission.uuid
                      )?.conditions?.numberOfDaysLimit
                    }
                    isOn={draftRole.permissionActions.some(
                      (permissionAction) =>
                        permissionAction.permissionActionUuid === subPermission.uuid &&
                        permissionAction.effect === "ALLOW"
                    )}
                    onToggle={(value) => {
                      handlePermissionToggle(subPermission.uuid, value);
                    }}
                    onTimeLimitChange={(daysLimit) => {
                      handleTimeLimitChange(subPermission.uuid, daysLimit);
                    }}
                  />
                ))}
              </DomainPermissionItem>
            );
          })}
        </PermissionList>
      </div>
    </div>
  );
};
