import { Link } from "react-router-dom";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { useAccount } from "@libs/contexts/AccountContext";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { ReactComponent as OpenProfileIcon } from "@libs/assets/icons/open-profile.svg";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import {
  getFamilyMembersV2Query,
  getInfiniteAppointmentsQuery,
  getPatientSummary,
} from "api/patients/queries";
import { paths } from "utils/routing/paths";
import { PeekInformationSection } from "components/PatientBlurbInfo/PeekInformationSection";
import { Divider } from "components/UI/Divider";
import { AppointmentCard } from "components/PatientBlurbInfo/AppointmentCard";
import { PersonTag } from "components/Shared/PersonTag";
import { useNullablePathParams } from "hooks/usePathParams";
import { getPatientLink } from "components/Patients/patientLinks";
import { getPatientDisplayName } from "utils/names";
import { FlyoverHeader } from "components/UI/FlyoverComponents";

interface Props {
  patientId: number;
  onRequestClose: Func;
}

export const PatientInfoPeek: React.FC<Props> = ({ patientId, onRequestClose }) => {
  const { practiceId } = useAccount();

  const [familyMembersQuery, patientQuery] = useApiQueries([
    getFamilyMembersV2Query({
      args: { patientId, practiceId },
    }),
    getPatientSummary({ args: { patientId, practiceId } }),
  ]);
  const { data: patient } = patientQuery;
  const { data: familyMembers } = familyMembersQuery;

  const appointmentsQuery = useInfiniteApiQuery(
    getInfiniteAppointmentsQuery({
      args: { patientId, practiceId, pageSize: 25, pageNumber: 1 },
    })
  );

  const appointments = useFlattenPages(appointmentsQuery.data);
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: appointmentsQuery });
  const patientTab = useNullablePathParams("patientTab")?.tab;

  return (
    <>
      <FlyoverHeader size="sm">
        <div className="flex items-center justify-between">
          <div className="flex-1">Pt Profile</div>
          <LinkIcon
            SvgIcon={OpenProfileIcon}
            className="text-primaryTheme font-sansSemiBold"
            to={paths.patient({ patientId })}
            onClick={onRequestClose}
            tooltip={{ content: "View More", theme: "SMALL" }}
          />
        </div>
      </FlyoverHeader>

      <div ref={rootRef} className="min-h-0 flex-1 overflow-y-auto p-6 gap-y-4 flex flex-col">
        <QueryResult queries={[patientQuery]}>
          {patient && <PeekInformationSection patient={patient} />}
        </QueryResult>
        <QueryResult queries={[familyMembersQuery]}>
          {familyMembers && familyMembers.linkedFamilyMembers.length > 1 && (
            <>
              <div className="py-1">
                <Divider />
              </div>
              <span className="text-sm font-sansSemiBold">Family</span>
              {familyMembers.linkedFamilyMembers
                .filter((familyMember) => familyMember.memberPatientId !== patientId)
                .map((familyMember) => (
                  <Link
                    className="block"
                    onClick={onRequestClose}
                    to={getPatientLink(familyMember.memberPatientId, patientTab)}
                    key={familyMember.memberPatientId}
                  >
                    <PersonTag displayName={getPatientDisplayName(familyMember)} />
                  </Link>
                ))}
            </>
          )}
        </QueryResult>

        <ScrollableInfiniteQueryResult
          infiniteQuery={appointmentsQuery}
          loadMoreClassName="w-full"
          scrollRef={scrollRef}
        >
          {Boolean(appointments?.length) && (
            <div className="flex flex-col gap-y-4">
              <div className="py-1">
                <Divider />
              </div>
              <span className="text-sm font-sansSemiBold">Appointment History</span>
              <div className="flex flex-col gap-y-4">
                {appointments?.map((appointment) => (
                  <AppointmentCard
                    appointment={appointment}
                    key={appointment.id}
                    onEditAppointment={onRequestClose}
                  />
                ))}
              </div>
            </div>
          )}
        </ScrollableInfiniteQueryResult>
      </div>
    </>
  );
};
