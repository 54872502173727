import { ChangeEvent, useCallback, useId, useMemo } from "react";
import { DentalProcedureVO, PatientToothVO } from "@libs/api/generated-api";
import { DraftPatientProcedureRequest } from "components/Charting/draftPatientProcedure";
import { ToothOptionsList } from "components/Charting/ToothOptionsList";
import { ToggleButton } from "components/UI/ToggleButton";
import {
  isAreaSelectionRequired,
  isQuadrantDisabledByDentalProcedure,
} from "components/Charting/patientProcedureSchema";
import { useIsToothDisabledByDentalProcedure } from "components/Charting/useIsToothDisabledByAreaSelection";

interface Props {
  dentalProcedure: DentalProcedureVO;
  teeth: PatientToothVO[];
  quadrant: DraftPatientProcedureRequest["quadrant"];
  onUpdateDraft: (updates: Partial<DraftPatientProcedureRequest>) => void;
}

export const QuadrantOptions: React.FC<Props> = ({ dentalProcedure, teeth, quadrant, onUpdateDraft }) => {
  const toothSelections = useMemo(() => {
    if (!quadrant) {
      return new Set<string>();
    }

    const toothNames = teeth
      .filter((tooth) => tooth.quadrant === quadrant)
      .map(({ toothName }) => toothName)
      .filter(Boolean);

    return new Set(toothNames);
  }, [quadrant, teeth]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newQuadrant = e.target.value as PatientToothVO["quadrant"];
      const unselect = !isAreaSelectionRequired(dentalProcedure.areaSelection) && newQuadrant === quadrant;

      onUpdateDraft({ quadrant: unselect ? undefined : newQuadrant });
    },
    [onUpdateDraft, dentalProcedure, quadrant]
  );
  const isToothDisabled = useIsToothDisabledByDentalProcedure(dentalProcedure);

  const quadrantRadioName = useId();

  return (
    <div className="flex items-center">
      <div className="flex-none pr-3">
        <ToggleButton
          type="radio"
          className="mb-3"
          shape="square"
          checked={quadrant === "QUAD_UPPER_RIGHT"}
          name={quadrantRadioName}
          disabled={isQuadrantDisabledByDentalProcedure("QUAD_UPPER_RIGHT", dentalProcedure)}
          value="QUAD_UPPER_RIGHT"
          onChange={handleChange}
        >
          UR
        </ToggleButton>
        <ToggleButton
          type="radio"
          shape="square"
          checked={quadrant === "QUAD_LOWER_RIGHT"}
          name={quadrantRadioName}
          value="QUAD_LOWER_RIGHT"
          disabled={isQuadrantDisabledByDentalProcedure("QUAD_LOWER_RIGHT", dentalProcedure)}
          onChange={handleChange}
        >
          LR
        </ToggleButton>
      </div>
      <ToothOptionsList
        disabled={true}
        isToothDisabled={isToothDisabled}
        type="checkbox"
        teeth={teeth}
        canDeselect={!isAreaSelectionRequired(dentalProcedure.areaSelection)}
        toothSelections={toothSelections}
      />
      <div className="flex-none pl-3">
        <ToggleButton
          type="radio"
          className="mb-3"
          shape="square"
          checked={quadrant === "QUAD_UPPER_LEFT"}
          name={quadrantRadioName}
          value="QUAD_UPPER_LEFT"
          disabled={isQuadrantDisabledByDentalProcedure("QUAD_UPPER_LEFT", dentalProcedure)}
          onChange={handleChange}
        >
          UL
        </ToggleButton>
        <ToggleButton
          type="radio"
          shape="square"
          checked={quadrant === "QUAD_LOWER_LEFT"}
          name={quadrantRadioName}
          value="QUAD_LOWER_LEFT"
          disabled={isQuadrantDisabledByDentalProcedure("QUAD_LOWER_RIGHT", dentalProcedure)}
          onChange={handleChange}
        >
          LL
        </ToggleButton>
      </div>
    </div>
  );
};
