import { Route } from "react-router-dom";
import { FC } from "react";
import { LayoutCard } from "@libs/components/UI/LayoutCard";
import { MainContent } from "@libs/components/UI/MainContent";
import { MainAppHistoryProvider } from "components/Main/MainLinksContext";
import { NotFound } from "components/NotFoundRoute";
import { ClaimAttachmentsRoute } from "components/Claim/Attachments/ClaimAttachmentsRoute";
import { ClaimEobsRoute } from "components/Claim/Attachments/ClaimEobsRoute";
import { ClaimRoute } from "components/Claim/ClaimRoute";
import { EobRoute } from "components/Eob/EobRoute";
import { PreAuthsRoute } from "components/Claims/PreAuths/PreAuthsRoute";
import { ClaimsRoute } from "components/Claims/Claims/ClaimsRoute";
import { EobsRoute } from "components/Claims/Eobs/EobsRoute";

import { ClaimsBulkNotifier } from "components/Claims/Claims/ClaimsBulkNotifier";
import { ClaimHistoryRoute } from "components/Claim/HistoryRoute";
import { ClaimAdjustmentRoute } from "components/Claim/AdjustmentRoute";
import { ClaimsLinksProvider } from "components/Claims/ClaimsLinksContext";
import { SentryRoutes } from "components/UI/SentryRoutes";

const ClaimsTabsRoutes = [
  {
    element: <PreAuthsRoute />,
    path: "pre-auths",
  },
  {
    element: <ClaimsRoute />,
    index: true,
  },
  {
    element: <EobsRoute />,
    path: "eobs",
  },
];

const ClaimRoutes = [
  {
    element: <ClaimRoute />,
    path: ":claimUuid",
  },
  {
    element: <ClaimAdjustmentRoute />,
    path: ":claimUuid/add-adjustment",
  },
  {
    element: <ClaimAttachmentsRoute />,
    path: ":claimUuid/attachments",
  },
  {
    element: <ClaimEobsRoute />,
    path: ":claimUuid/eobs",
  },
  {
    element: <ClaimHistoryRoute />,
    path: ":claimUuid/history",
  },
];

const EobRoutes = [
  {
    element: <EobRoute />,
    path: "eobs/:draftEobPaymentUuid",
  },
];

export const ClaimsRoutes: { element: JSX.Element; index?: boolean; path?: string }[] = [
  ...ClaimsTabsRoutes,
  ...ClaimRoutes,
  ...EobRoutes,
];

export const ClaimsApp: FC = () => {
  return (
    <MainAppHistoryProvider name="claims">
      <MainContent>
        <LayoutCard className="flex flex-col justify-between h-full bg-white text-greyDark">
          <ClaimsBulkNotifier />
          <ClaimsLinksProvider>
            <SentryRoutes>
              {ClaimsRoutes.map(({ path, element, index }, i) => (
                <Route key={i} index={index} path={path} element={element} />
              ))}
              <Route path="*" element={<NotFound />} />
            </SentryRoutes>
          </ClaimsLinksProvider>
        </LayoutCard>
      </MainContent>
    </MainAppHistoryProvider>
  );
};
