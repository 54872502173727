import React from "react";
import { useNavigate } from "react-router-dom";
import { paths } from "utils/routing/paths";
import { SelectPatientMount } from "components/PatientProfile/Imaging/MountRoute/SelectPatientMount";
import { HeaderLeftProps } from "./types";

// eslint-disable-next-line complexity
export const HeaderLeft: React.FC<HeaderLeftProps> = ({
  imageMount,
  patient,
  mountFormat,
  mountsInfiniteQuery,
  children,
}) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="flex items-center gap-5 text-white font-sansSemiBold">
        <div className="truncate max-w-[280px] font-sansSemiBold">{patient.name.shortDisplayName}</div>
      </div>
      <SelectPatientMount
        isDark
        mountsInfiniteQuery={mountsInfiniteQuery}
        selectedMount={imageMount}
        className="w-72"
        onChange={(targetMountId) => {
          navigate(
            paths.mountDetails({ mountId: targetMountId, patientId: patient.id }, { format: mountFormat })
          );
        }}
      />
      {children}
    </>
  );
};
