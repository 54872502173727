import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { AutomationActionRequest, AutomationActionVO } from "@libs/api/generated-api";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { getQueryKey } from "@libs/utils/queries";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { updateAutomationAction } from "api/communications/mutations";
import { ActionModalPage, ActionModalPageProps } from "components/Communications/Automations/ActionModalPage";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { handleError } from "utils/handleError";
import { getAutomationAction, getPatientEngagementTemplateVariables } from "api/communications/queries";
import { getDentalProceduresQuery } from "api/charting/queries";
import { isEmailConfigured } from "components/Communications/utils";
import { getDraftAutomationAction, journeyNames } from "components/Communications/Automations/utils";
import { getEmailConfig } from "api/settings/communications/queries";

const EditAutomationContent: FC<
  { action: AutomationActionVO } & Omit<
    ActionModalPageProps,
    "draftAction" | "templateVariablesQuery" | "onUpdate"
  >
> = ({ action, ...props }) => {
  const { practiceId } = useAccount();
  const [draftAction, updateDraftAction] = useObjectState(() => {
    return getDraftAutomationAction(action, isEmailConfigured(props.emailConfig));
  });

  const [templateVariablesQuery] = useApiQueries([
    getPatientEngagementTemplateVariables({
      args: { practiceId, eventType: draftAction.event, category: "ACTION" },
      // We want to keep previous date otherwise when switching between events
      // the message content flickers between loading and loaded state
      queryOptions: { enabled: Boolean(draftAction.event), keepPreviousData: true },
    }),
  ]);

  return (
    <ActionModalPage
      draftAction={draftAction}
      onUpdate={updateDraftAction}
      templateVariablesQuery={templateVariablesQuery}
      {...props}
    />
  );
};

export const EditAutomationActionRoute: FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const practice = useCurrentPractice();
  const { practiceId } = useAccount();
  const { journeyType, actionUuid } = usePathParams("editAutomationAction");
  const { query } = useQueryParams("editAutomationAction");
  const from = query.from ?? paths.journey({ journeyType });
  const requiresDentalProcedures = journeyType === "POST_APPOINTMENT";

  const [emailConfigQuery, actionQuery, dentalProceduresQuery] = useApiQueries([
    getEmailConfig({ args: { practiceId } }),
    getAutomationAction({ args: { journeyType, actionUuid, practiceId } }),
    getDentalProceduresQuery({
      args: { practiceId },
      queryOptions: { enabled: requiresDentalProcedures },
    }),
  ]);

  const [updateAutomationActionMutation] = useApiMutations([updateAutomationAction]);

  const handleSave = (data: AutomationActionRequest) => {
    updateAutomationActionMutation.mutate(
      { practiceId, actionUuid, journeyType, data },
      {
        onError: handleError,
        onSuccess: () => {
          queryClient.invalidateQueries([
            getQueryKey("practices", "getAutomationJourney"),
            { practiceId, journeyType },
          ]);
          queryClient.invalidateQueries([
            getQueryKey("practices", "getAutomationAction"),
            { practiceId, journeyType, actionUuid },
          ]);
          navigate(from, { replace: true });
        },
      }
    );
  };

  return (
    <QueryResult queries={[actionQuery, dentalProceduresQuery, emailConfigQuery]}>
      {actionQuery.data &&
      emailConfigQuery.data &&
      (!requiresDentalProcedures || dentalProceduresQuery.data) ? (
        <EditAutomationContent
          title={`Edit ${journeyNames[journeyType]} Message`}
          onSave={handleSave}
          from={from}
          emailConfig={emailConfigQuery.data}
          isSaving={updateAutomationActionMutation.isLoading}
          action={actionQuery.data}
          journeyType={journeyType}
          dentalProcedures={dentalProceduresQuery.data}
          practice={practice}
        />
      ) : null}
    </QueryResult>
  );
};
