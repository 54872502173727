/* eslint-disable @typescript-eslint/naming-convention */
import { AnnotationPropValue, PearlCategory } from "components/PatientProfile/Imaging/PearlAI/utils";

const annotationCategoryToLabel: Record<PearlCategory, string> = {
  crown: "Crown",
  "periapical.radiolucency": "Periapical Radiolucency",
  filling: "Filling",
  anatomy: "Anatomy",
  caries: "Caries",
  "margin.discrepancy": "Notable Margin",
  implant: "Implant",
  "root.canal": "Root Canal",
  bridge: "Bridge",
  calculus: "Calculus",
};

const annotationPropValuetoLabel: Record<AnnotationPropValue, string> = {
  background: "Background",
  "conditions.anatomy.bone": "Bone",
  "conditions.anatomy.cementum": "Cementum",
  "conditions.anatomy.dentin": "Dentin",
  "conditions.anatomy.enamel": "Enamel",
  "conditions.anatomy.pulp": "Pulp",
  "conditions.restoration_e": "Restoration",
  "conditions.anatomy.occlusal": "Occlusal",
};

export const getLabelForAnnotationCategory = (category?: PearlCategory) => {
  if (!category) {
    return "Unknown";
  }

  return annotationCategoryToLabel[category];
};

export const getLabelForAnnotationPropValue = (propValue?: AnnotationPropValue) => {
  if (!propValue) {
    return "Unknown";
  }

  return annotationPropValuetoLabel[propValue];
};
