import React, { useMemo } from "react";
import { lazyDefault } from "@libs/utils/lazyDefault";
import { DailyHuddleAppointmentVO, ProviderVO, RoomVO, TagVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { QueryFilters } from "@libs/components/UI/QueryFilters";
import { getDailyHuddleFilterProps } from "components/DailyHuddle/dailyHuddleFilterPills";
import { MessagePatientsButton } from "components/Communications/MessagePatients/MessagePatientsButton";
import { FlyoverV2 } from "components/UI/FlyoverV2";
import { MessagePatientsFlyover } from "components/Communications/MessagePatients/MessagePatientsFlyover";
import { DailyHuddleQuery } from "utils/routing/scheduling";
import { DailyHuddleQueryKeyData } from "components/DailyHuddle/types";

interface Props {
  providers: ProviderVO[] | undefined;
  rooms: RoomVO[] | undefined;
  tags: TagVO[] | undefined;
  selectedCount: number;
  selectedRows: Set<number>;
  deselectedRowsFromSelectAll: Set<number>;
  hasAllSelected: boolean;
  totalRows: number;
  query: DailyHuddleQuery;
  dailyHuddleInfiniteQuery: UseInfiniteApiQueryResult<DailyHuddleAppointmentVO[]>;
  dailyHuddleQueryData: DailyHuddleQueryKeyData;
  onUpdateQuery: (updates: Partial<DailyHuddleQuery>) => void;
}

const DailyHuddleFiltersForm = lazyDefault(
  () => import("components/DailyHuddle/DailyHuddleFiltersForm"),
  "DailyHuddleFiltersForm"
);

export const DailyHuddleQueryFilters: React.FC<Props> = ({
  providers,
  rooms,
  tags,
  totalRows,
  selectedCount,
  selectedRows,
  deselectedRowsFromSelectAll,
  hasAllSelected,
  query,
  dailyHuddleQueryData,
  dailyHuddleInfiniteQuery,
  onUpdateQuery,
}) => {
  const messagePatientsFlyover = useBoolean(false);
  const filtersFlyover = useBoolean(false);
  const filterProps = useMemo(
    () => getDailyHuddleFilterProps(providers, rooms, tags, query),
    [providers, rooms, tags, query]
  );

  return (
    <>
      <QueryFilters
        {...filterProps}
        searchParamKey="patientName"
        bulkActions={
          <div className="pl-1">
            <MessagePatientsButton
              onRequestMessagePatients={messagePatientsFlyover.on}
              disabled={!totalRows || !selectedCount}
            />
          </div>
        }
        queries={[dailyHuddleInfiniteQuery]}
        onUpdateParams={onUpdateQuery}
        totalElements={totalRows}
        onOpenFlyover={filtersFlyover.on}
      />
      {messagePatientsFlyover.isOn ? (
        <MessagePatientsFlyover
          type="DAILY_HUDDLE"
          criteria={dailyHuddleQueryData}
          selectionCategory="DAILY_HUDDLE"
          selectedCount={selectedCount}
          filteredCount={totalRows}
          selectedIds={selectedRows}
          deselectedIds={deselectedRowsFromSelectAll}
          hasAllSelected={hasAllSelected}
          onClose={messagePatientsFlyover.off}
        />
      ) : null}
      {providers && rooms && tags ? (
        <FlyoverV2 isOpen={filtersFlyover.isOn} onClickAway={filtersFlyover.off}>
          <DailyHuddleFiltersForm
            onRequestClose={filtersFlyover.off}
            onSave={(updates) => {
              onUpdateQuery(updates);
              filtersFlyover.off();
            }}
            tagIds={query.tagIds}
            providerIds={query.providerIds}
            roomIds={query.roomIds}
            statuses={query.statuses}
            clinicalNoteStatuses={query.clinicalNoteStatuses}
            tags={tags}
            providers={providers}
            rooms={rooms}
          />
        </FlyoverV2>
      ) : null}
    </>
  );
};
