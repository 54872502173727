/* eslint-disable @typescript-eslint/no-magic-numbers */
import { FC, PropsWithChildren } from "react";
import { InvoiceEntryVO, LedgerV2EntryPaymentVO, PaymentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { pluralize } from "@libs/utils/pluralize";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as NoteIcon } from "@libs/assets/icons/note.svg";
import { Text } from "components/PatientProfile/Billing/Ledger/Text";
import {
  getPaymentMethodToLabel,
  isPaymentStateFailed,
} from "components/PatientProfile/Billing/billingUtils";

export type PracticeAndPatientIds = { practiceId: number; patientId: number };

export const cxStyles = {
  row: {
    padding: {
      billingHeader: "px-2 py-4",
      tableRow: "py-1 pl-4",
      filterRow: "py-2 px-5",
    },
  },
  column: {
    width: {
      xxs: "w-6",
      xs: "w-8",
      sm: "w-10",
      md: "w-16",
      lg: "w-24",
      xl: "w-32",
      ["2xl"]: "w-36",
      ["3xl"]: "w-40",
      ["4xl"]: "w-44",
      ["5xl"]: "w-60",
      full: "flex-1",
    },
    justify: {
      left: "justify-start",
      right: "justify-end",
      center: "justify-center",
    },
    align: {
      top: "items-start",
      bottom: "items-end",
      center: "items-center",
    },
  },
};

export const AccordionContainer: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className={`
        flex
        flex-wrap
        ring-1
        ring-grey-300
        ring-inset
        rounded
        basis-full
        divide-y
        divide-slate-300
        bg-white
      `}
    >
      {children}
    </div>
  );
};

export const AccordionItem: FC<PropsWithChildren> = ({ children }) => {
  return <div className="flex flex-col basis-full p-2 gap-y-2">{children}</div>;
};

export const Row: FC<
  PropsWithChildren<{ full?: boolean; padding?: keyof typeof cxStyles.row.padding; className?: string }>
> = ({ full = true, padding, className, children }) => {
  return (
    <div className={cx("flex", full && "basis-full", padding && cxStyles.row.padding[padding], className)}>
      {children}
    </div>
  );
};

export const Col: FC<
  PropsWithChildren<{
    background?: "orangeLight";
    justify: keyof typeof cxStyles.column.justify;
    align?: keyof typeof cxStyles.column.align;
    width: keyof typeof cxStyles.column.width;
    nowrap?: boolean;
  }>
> = ({ background, justify, align, width, nowrap, children }) => {
  return (
    <div
      className={cx(
        "flex",
        cxStyles.column.width[width],
        cxStyles.column.justify[justify],
        align && cxStyles.column.align[align],
        background && "bg-orangeLight",
        nowrap && "whitespace-nowrap"
      )}
    >
      {children}
    </div>
  );
};

export const PaymentDescription: FC<{ payment: PaymentVO | LedgerV2EntryPaymentVO }> = ({ payment }) => {
  const paymentState = "state" in payment ? payment.state : undefined;
  const paymentFailed = paymentState ? isPaymentStateFailed(paymentState) : false;

  return (
    <div className="flex gap-x-2 items-center">
      <Text>
        By {getPaymentMethodToLabel(payment)}
        {paymentFailed && " - Failed"}
      </Text>
      {payment.note && <PaymentNoteIcon note={payment.note} />}
    </div>
  );
};

export const PaymentNoteIcon: FC<{ note: string }> = ({ note }) => {
  return (
    <FloatingTooltip content={note}>
      <NoteIcon className="w-5 h-5 text-slate-900" />
    </FloatingTooltip>
  );
};

export const InvoiceEntryDescription: FC<{ entry: InvoiceEntryVO }> = ({ entry }) => {
  const numOfProcedures = entry.lineItems.length;
  const proceduresText = `${numOfProcedures} ${pluralize(numOfProcedures, "procedure", "procedures")}`;

  const numOfAdjustments = entry.appointmentAdjustments?.length ?? 0;
  const adjustmentsText = `${numOfAdjustments} ${pluralize(numOfAdjustments, "adjustment", "adjustments")}`;

  return (
    <>
      {sentenceCaseConstant(entry.entryReference.type)}:{" "}
      {entry.entryReference.type === "APPOINTMENT"
        ? `${entry.appointmentSnapshot?.providerName ?? ""} (${proceduresText}, ${adjustmentsText})`
        : entry.adjustmentName}
    </>
  );
};

export const NoPayments: FC = () => {
  return <i>No Payments</i>;
};
