import { FC, HTMLProps, PropsWithChildren, useEffect, useRef, useState } from "react";
import { TransformComponent, TransformWrapper, useTransformEffect } from "react-zoom-pan-pinch";

import { DocumentVO } from "@libs/api/generated-api";
import { Spinner } from "@libs/components/UI/Spinner";
import { Icon } from "@libs/components/UI/Icon";
import { cx } from "@libs/utils/cx";

import { ReactComponent as DownloadIcon } from "@libs/assets/icons/download.svg";
import { ReactComponent as ExternalLinkIcon } from "@libs/assets/icons/external-link.svg";

import { ButtonLink } from "@libs/components/UI/ButtonLink";
import { Button } from "@libs/components/UI/Button";
import { EmptyContent } from "@libs/components/UI/EmptyContent";

import EmptyDocuments from "assets/images/empty-documents-list.svg";

import { PreviewToolbar } from "./PreviewToolbar";
import { useToggleZoomMode } from "./useToggleZoomMode";

const MIN_SCALE = 0.1; // 10%
const MAX_SCALE = 3; // 300%

interface Props extends HTMLProps<HTMLDivElement> {
  document?: DocumentVO;
  printUrl?: string;
  downloadUrl?: string;
  isLoadingUrl: boolean;
  hasFolders: boolean;
  onEdit: (document: DocumentVO) => void;
  onDelete: (document: DocumentVO) => void;
  onAddFolderClick: () => void;
}

export const PreviewDocument: FC<Props> = ({
  document,
  printUrl,
  downloadUrl,
  isLoadingUrl,
  hasFolders,
  onEdit,
  onDelete,
  onAddFolderClick,
}) => {
  const altOrTitle = "Document Preview";
  const [hasFailedToLoadPreview, setHasFailedToLoadPreview] = useState(false);

  useEffect(() => {
    // Reset failed state whenever we pick a different document
    setHasFailedToLoadPreview(false);
  }, [printUrl]);

  const documentNameLowercase = document ? document.name.toLocaleLowerCase() : undefined;

  return document && printUrl ? (
    hasFailedToLoadPreview ? (
      <EmptyContent
        className="justify-center"
        src={EmptyDocuments}
        alt="Failed to Load Preview"
        text="Unable to preview document. Please download it instead."
        size="md"
      >
        <ButtonLink className="flex items-center gap-x-2" href={printUrl} download={document.name}>
          <Icon SvgIcon={DownloadIcon} size="sm" />
          Download
        </ButtonLink>
      </EmptyContent>
    ) : documentNameLowercase?.endsWith(".pdf") ? (
      <PdfViewer
        document={document}
        printUrl={printUrl}
        downloadUrl={downloadUrl}
        onDelete={onDelete}
        onEdit={onEdit}
        onLoadError={() => setHasFailedToLoadPreview(true)}
      />
    ) : documentNameLowercase?.endsWith(".html") ? (
      <EmptyContent
        className="justify-center"
        src={EmptyDocuments}
        alt="Open html Document"
        text="Unable to preview html document. Please open it instead."
        size="md"
      >
        <ButtonLink
          className="flex items-center gap-x-2"
          href={printUrl}
          download={document.name}
          target="_blank"
        >
          Open
          <Icon SvgIcon={ExternalLinkIcon} size="sm" />
        </ButtonLink>
      </EmptyContent>
    ) : (
      <ImageViewer
        document={document}
        onDelete={onDelete}
        onEdit={onEdit}
        onLoadError={() => setHasFailedToLoadPreview(true)}
        printUrl={printUrl}
        downloadUrl={downloadUrl}
        alt={altOrTitle}
      />
    )
  ) : isLoadingUrl ? (
    <div className="flex flex-col items-center justify-center bg-slate-700">
      <Spinner animation="border" size="panel" variant="primary" />
    </div>
  ) : (
    <EmptyContent
      className="justify-center"
      src={EmptyDocuments}
      alt="No Documents"
      text={hasFolders ? "Please select a file to preview" : "Create your first folder to add documents"}
      size="md"
    >
      {!hasFolders && <Button onClick={onAddFolderClick}>Create Folder</Button>}
    </EmptyContent>
  );
};

const ImageViewer: FC<
  Omit<HTMLProps<HTMLImageElement>, "crossOrigin"> & {
    document: DocumentVO;
    printUrl?: string;
    downloadUrl?: string;
    onEdit: (document: DocumentVO) => void;
    onDelete: (document: DocumentVO) => void;
    onLoadError: (document: DocumentVO) => void;
  }
> = ({ document, printUrl, downloadUrl, onEdit, onDelete, onLoadError, alt, className }) => {
  const [isPanning, setIsPanning] = useState(false);
  const imgRef = useRef<HTMLImageElement & { initializedUrl: string }>(null);
  const doubleClickMode = useToggleZoomMode({ key: "Shift" });
  const [zoomScale, setZoomScale] = useState(1);

  return (
    <div className="flex flex-col">
      <TransformWrapper
        minScale={MIN_SCALE}
        maxScale={MAX_SCALE}
        wheel={{ disabled: true }}
        doubleClick={{ mode: doubleClickMode }}
        onPanningStart={() => setIsPanning(true)}
        onPanningStop={() => setIsPanning(false)}
      >
        {({ zoomIn, zoomOut, zoomToElement }) => {
          // We want to use the function `zoomToElement()` to fit the image to
          // the user's screen but only once after the image is loaded. This
          // prevents us from having to calculate and pass `initialScale` to
          // `TransformWrapper` (above).

          return (
            <ZoomScale onZoomScale={setZoomScale}>
              <PreviewToolbar
                document={document}
                onZoomIn={() => zoomIn()}
                onZoomOut={() => zoomOut()}
                onZoomFit={() => imgRef.current && zoomToElement(imgRef.current)}
                onDelete={onDelete}
                printUrl={printUrl}
                downloadUrl={downloadUrl}
                onEdit={onEdit}
                zoomScale={zoomScale}
              />
              <PreviewCanvas>
                <div className={cx(isPanning ? "cursor-grabbing" : "cursor-grab", className)}>
                  <img
                    src={printUrl}
                    alt={alt}
                    ref={imgRef}
                    onError={() => {
                      onLoadError(document);
                    }}
                    onLoad={() => {
                      if (imgRef.current) {
                        if (printUrl && imgRef.current.initializedUrl !== printUrl) {
                          imgRef.current.initializedUrl = printUrl;
                        }

                        zoomToElement(imgRef.current, undefined, 0);
                      }
                    }}
                  />
                </div>
              </PreviewCanvas>
            </ZoomScale>
          );
        }}
      </TransformWrapper>
    </div>
  );
};

const PdfViewer: FC<{
  printUrl?: string;
  downloadUrl?: string;
  document: DocumentVO;
  onEdit: (document: DocumentVO) => void;
  onDelete: (document: DocumentVO) => void;
  onLoadError: (document: DocumentVO) => void;
}> = ({ document, printUrl, onEdit, onDelete }) => {
  return (
    <div className="flex flex-col">
      <PreviewToolbar
        document={document}
        onDelete={onDelete}
        onEdit={onEdit}
        showDownload={false}
        showPrint={false}
      />
      <iframe title={document.name} className="h-full" src={printUrl} />
    </div>
  );
};

const PreviewCanvas: FC<PropsWithChildren> = ({ children }) => (
  <TransformComponent wrapperClass="h-full w-full bg-slate-700 overflow-hidden">
    {children}
  </TransformComponent>
);

const ZoomScale: FC<PropsWithChildren & { onZoomScale: (scale: number) => void }> = ({
  onZoomScale,
  children,
}) => {
  // This hook must be used inside `TransformWrapper`
  useTransformEffect((context) => onZoomScale(context.state.scale));

  // eslint-disable-next-line react/jsx-no-useless-fragment
  return <>{children}</>;
};
