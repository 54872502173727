import designConfig from "@libs/design.config";
import { half } from "@libs/utils/math";

const SELECTED_WIDTH_BUFFER = 0.2;
const SELECTED_HEIGHT_BUFFER = 0.2;
const BOTTOM_OVERFLOW = 6;

type Props = {
  x: number;
  barWidth: number;
  yMax: number;
  marginBottom: number;
};
export const SelectedBar: React.FC<Props> = ({ x, barWidth, yMax }) => {
  const outlineOutset = {
    width: SELECTED_WIDTH_BUFFER * barWidth,
    height: SELECTED_HEIGHT_BUFFER * yMax,
  };

  return (
    <rect
      x={x - half(outlineOutset.width)}
      y={-half(outlineOutset.height)}
      width={barWidth + outlineOutset.width}
      height={yMax + half(outlineOutset.height) + BOTTOM_OVERFLOW}
      fill="none"
      stroke={designConfig.colors.primaryTheme}
      strokeWidth={1}
      rx={5}
    />
  );
};
