import React from "react";
import {
  ProductionByProcedureResponse,
  ProductionByProcedureVO,
  ProductionTotalVO,
} from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getAbsoluteUrl } from "@libs/utils/location";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { HeaderData } from "components/Dashboard/Tables/types";
import { useDownloadQueryPages } from "components/Dashboard/hooks/useDownloadInfiniteQuery";
import { getTotals } from "components/Dashboard/Tables/utils";
import { formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";
import { handleError } from "utils/handleError";
import { paths } from "utils/routing/paths";
import { PRODUCTION_BY_PROCEDURE_COLUMNS } from "components/Dashboard/PracticeProduction/constants";
import { TimeSegment } from "utils/routing/dashboard";
import { useCurrentUrl, useOrigin } from "contexts/OriginContext";

const formatProcedureDataAsCsv = (
  rows: ProductionByProcedureVO[],
  totals: ProductionTotalVO,
  headers: HeaderData[],
  origin: string
) => {
  const columnTitles = formatCsvRow([...headers.map(({ label }) => label).filter(Boolean), "Appt URL"]);

  const rowsContent = rows.map((row) =>
    formatCsvRow([
      row.patient.shortDisplayName,
      row.productionDate,
      row.provider.fullDisplayName,
      row.dentalProcedureCdtCode,
      row.dentalProcedureSimpleName,
      row.isScheduled ? formatCurrency(row.totalGrossScheduledAmount) : "✅",
      row.isScheduled ? "" : formatCurrency(row.totalGrossProducedAmount),
      formatCurrency(row.totalProducedAmount),
      getAbsoluteUrl(
        origin,
        paths.schedule({
          patientId: row.patient.id,
          appointmentId: row.appointmentId,
          date: row.productionDate,
        })
      ),
    ])
  );
  const totalsContent = formatCsvRow([
    "",
    "",
    "",
    "",
    "Totals",
    formatCurrency(totals.totalGrossScheduledAmount),
    formatCurrency(totals.totalGrossProducedAmount),
    formatCurrency(totals.totalProducedAmount),
  ]);

  return [columnTitles, ...rowsContent, totalsContent];
};

export const useDownloadProductionByProcedureCSV = ({
  selectedTimeSegment,
  productionByProcedureQuery,
}: {
  productionByProcedureQuery: UseInfiniteApiQueryResult<ProductionByProcedureResponse>;
  selectedTimeSegment: TimeSegment;
}) => {
  const downloading = useBoolean(false);

  const { startDownload: downloadProceduresData } = useDownloadQueryPages(productionByProcedureQuery);
  const origin = useOrigin();
  const currentUrl = useCurrentUrl();

  return {
    isDownloading: downloading.isOn,
    downloadCSV: React.useCallback(async () => {
      downloading.on();

      try {
        const result = await downloadProceduresData();
        const rows = result.pages.flatMap((item) => item.data.entries);
        const totals = getTotals(result);

        if (totals) {
          const docMetadata = getArchyCsvMetadata(currentUrl, selectedTimeSegment);
          const csvRows = [
            ...docMetadata.headers,
            ...formatProcedureDataAsCsv(rows, totals, PRODUCTION_BY_PROCEDURE_COLUMNS, origin),
          ];

          saveCsv(csvRows, `ProductionByProcedure_${docMetadata.fileNameTag}`);
        }
      } catch (e) {
        handleError(e);
      }

      downloading.off();
    }, [downloading, downloadProceduresData, selectedTimeSegment, origin, currentUrl]),
  };
};
