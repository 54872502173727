import React, { useCallback, useState } from "react";
import { Navigate, useNavigate } from "react-router-dom";
import { getUnixTime } from "date-fns";
import { ClaimAttachmentVO } from "@libs/api/generated-api";
import { dataURLtoFile } from "@libs/utils/dataUrl";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { Button } from "@libs/components/UI/Button";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { ClaimModalHeader } from "components/Claim/ModalHeader";
import { getClaimQuery, getPrimaryEobsQuery } from "api/claim/queries";
import { deleteAttachments } from "api/claim/mutations";
import { paths } from "utils/routing/paths";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";
import { useSubmitClaim } from "components/Claims/Shared/useSubmitClaim";
import { handleError } from "utils/handleError";
import { ClaimModalFooter } from "components/Claim/ModalFooter";
import { EobsSection } from "components/Claim/EobsSection";
import { ExternalUpload } from "components/Claim/Attachments/ExternalUpload";
import { useUploadAttachments } from "components/Claim/Attachments/useUploadAttachments";

export type EobImage = Required<Pick<ClaimAttachmentVO, "data" | "isUploaded" | "sourceCreatedAt">> & {
  sourceId?: number;
  type: "EOB";
  uuid?: string;
};

// eslint-disable-next-line complexity
export const ClaimEobsRoute: React.FC = () => {
  const navigate = useNavigate();
  const { query: queryParams } = useQueryParams("claimEobs");
  const { claimUuid } = usePathParams("claimEobs");
  const { practiceId } = useAccount();

  const [{ data: claim }] = useApiQueries([getClaimQuery({ args: { practiceId, claimUuid } })]);
  const from = queryParams.from;

  const [primaryEobQuery] = useApiQueries([
    getPrimaryEobsQuery({
      args: { practiceId, appointmentId: claim?.appointmentId || 0 },
      queryOptions: { enabled: Boolean(claim?.appointmentId) },
    }),
  ]);

  usePageTitle(`Claims ${claim?.claimNumber ? `#${claim.claimNumber}` : ""} - Upload EOBs`);

  const handleClose = useCallback(() => {
    navigate(from ?? paths.claim({ claimUuid }), { replace: true });
  }, [navigate, from, claimUuid]);

  const [eobImages, setEobImages] = useState<EobImage[]>([]);

  useSyncOnce((loadedClaim) => {
    setEobImages(loadedClaim.attachments.filter((attachment) => attachment.type === "EOB") as EobImage[]);
  }, claim);

  const addImage = useCallback((data: string) => {
    const eobImage: EobImage = {
      data,
      isUploaded: true,
      sourceCreatedAt: getUnixTime(Date.now()),
      type: "EOB",
    };

    setEobImages((last) => {
      const imageExists = last.some((image) => image.data === eobImage.data);

      if (!imageExists) {
        return [...last, eobImage];
      }

      return last;
    });
  }, []);

  const removeImage = useCallback(
    (eobImage: Omit<ClaimAttachmentVO, "uuid">) => {
      const existingImageIndex = eobImages.findIndex((image) => image.data === eobImage.data);

      if (existingImageIndex !== -1) {
        const eobImagesCopy = [...eobImages];

        eobImagesCopy.splice(existingImageIndex, 1);
        setEobImages([...eobImagesCopy]);
      }
    },
    [eobImages]
  );

  const uploadAttachments = useUploadAttachments();
  const [deleteAttachmentsMutation] = useApiMutations([deleteAttachments]);
  const [isUploading, setIsUploading] = useState(false);

  const { validate, handleValidateError } = useSubmitClaim();

  const handleDeleteEobs = useCallback(async () => {
    if (!claim) {
      return;
    }

    // Delete any eobs that are no longer in the attachment list.
    const existingEobImages = claim.attachments
      .filter((attachment) => attachment.type === "EOB")
      .map((attachment) => attachment.uuid);

    const eobs = new Set(eobImages.map((image) => image.uuid).filter(Boolean));

    const eobUuidsToRemove = existingEobImages.filter((uuid) => !eobs.has(uuid));

    if (eobUuidsToRemove.length > 0) {
      await deleteAttachmentsMutation.mutateAsync({
        practiceId,
        claimUuid,
        data: { attachmentUuids: eobUuidsToRemove },
      });
    }
  }, [claim, claimUuid, deleteAttachmentsMutation, eobImages, practiceId]);

  const handleUploadEobs = useCallback(async () => {
    if (eobImages.length > 0) {
      const files = eobImages
        .filter((image) => !image.uuid)
        .map((image) => ({
          type: image.type,
          file: dataURLtoFile(image.data, "EobClipping.jpeg"),
          createdAt: image.sourceCreatedAt,
        }));

      if (files.length > 0) {
        await uploadAttachments(
          claimUuid,
          {
            // eslint-disable-next-line unused-imports/no-unused-vars
            attachments: files.map(({ file, ...metadata }) => metadata),
          },
          files.map((data) => data.file)
        );
      }
    }
  }, [eobImages, claimUuid, uploadAttachments]);

  const upload = useCallback(async () => {
    setIsUploading(true);

    try {
      await handleDeleteEobs();
      await handleUploadEobs();
    } catch (e) {
      handleError(e);
      handleClose();
      setIsUploading(false);

      return;
    }

    if (eobImages.length > 0) {
      try {
        await validate(claimUuid);
      } catch (err) {
        handleValidateError(err);
      }
    }

    handleClose();
    setIsUploading(false);
  }, [handleClose, handleDeleteEobs, handleUploadEobs, validate, handleValidateError, claimUuid, eobImages]);

  return claim?.insuranceOrdinal === "PRIMARY" ? (
    <Navigate replace to={paths.claim({ claimUuid: claim.uuid }, from ? { from } : undefined)} />
  ) : claim ? (
    <>
      <ClaimModalHeader data-testid="eobs-claim-header" onClose={handleClose} title="Upload EOB Clipping" />
      <div className="flex-1 pt-6 px-6">
        <ExternalUpload
          attachmentType="image"
          canSelectMultiple={true}
          images={eobImages}
          onAddImage={addImage}
          removeImage={removeImage}
          userId={claim.patientName.id}
        >
          <div className="text-sm font-sansSemiBold">Open an EOB to create a clipping</div>
          <QueryResult
            loading={
              <div className="h-1/2">
                <LoadingContent />
              </div>
            }
            queries={[primaryEobQuery]}
          >
            {primaryEobQuery.data && (
              <EobsSection
                canDownload={false}
                emptyListText="There are no EOBs attached to the primary claim. Please follow the instructions and paste an image of the primary EOB or go back and change this to a primary claim."
                files={primaryEobQuery.data}
                title="Primary Claim EOBs"
              />
            )}
          </QueryResult>
        </ExternalUpload>
      </div>

      <ClaimModalFooter>
        <Button className="min-w-button" disabled={isUploading} onClick={handleClose} theme="secondary">
          Cancel
        </Button>
        <FloatingTooltip content={isUploading ? "Uploading..." : ""} theme="SMALL">
          <div>
            <Button className="min-w-button" disabled={isUploading} onClick={upload} theme="primary">
              {eobImages.length === 0 ? "Update" : "Upload"}
            </Button>
          </div>
        </FloatingTooltip>
      </ClaimModalFooter>
    </>
  ) : null;
};
