import React from "react";
import { RoomVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Icon } from "@libs/components/UI/Icon";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { Pill } from "@libs/components/UI/Pill";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as DragIcon } from "@libs/assets/icons/drag-reorder-2.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { Cell, TextCell, cxGridTableStyles } from "@libs/components/UI/GridTableComponents";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";

const cxStyles = {
  cell: cx("flex items-center", cxGridTableStyles.cellPadding()),
  menuText: "ml-3 text-sm",
  pillCell: "flex-wrap gap-y-1.5 gap-x-2",
};

export const RoomRow: React.FC<{
  room: RoomVO;
  onEdit: (room: RoomVO) => void;
  onDelete: (room: RoomVO) => void;
}> = ({ room, onEdit, onDelete }) => {
  const menu = useBoolean(false);
  const deleteModal = useBoolean(false);

  return (
    <>
      <Cell className={cxStyles.cell}>
        <Icon
          SvgIcon={DragIcon}
          className="drag-handle cursor-move"
          tooltip={{ content: "Reorder", theme: "SMALL" }}
        />
      </Cell>
      <TextCell className="flex items-center">{room.roomName}</TextCell>
      <TextCell className="flex items-center">{room.roomDescription}</TextCell>
      <Cell className={cx(cxStyles.pillCell, cxStyles.cell)}>
        {room.providers?.map((provider) => (
          <Pill key={`${room.id || ""}-${provider.id}`}>{provider.name.shortDisplayName}</Pill>
        ))}
        {room.providers?.length === 0 ? <Pill>Any Provider</Pill> : null}
      </Cell>
      <Cell className={cxStyles.cell}>
        <ButtonMenu
          isOpen={menu.isOn}
          onRequestOpen={menu.on}
          onRequestClose={menu.off}
          placement="left"
          menuContent={
            <div className="w-64">
              <MenuOptionButton
                name="edit"
                onClick={() => {
                  menu.off();
                  onEdit(room);
                }}
              >
                <Icon SvgIcon={EditIcon} size="md" />
                <div className={cxStyles.menuText}>Edit</div>
              </MenuOptionButton>
              <MenuOptionButton
                name="delete"
                className="border-t"
                onClick={() => {
                  menu.off();
                  deleteModal.on();
                }}
              >
                <Icon SvgIcon={DeleteIcon} size="md" theme="error" />
                <div className={cxStyles.menuText}>Delete</div>
              </MenuOptionButton>
            </div>
          }
        >
          {(props) => <ButtonIcon SvgIcon={MenuIcon} theme="primary" size="md" className="mr-2" {...props} />}
        </ButtonMenu>
      </Cell>
      {deleteModal.isOn && (
        <ConfirmationModal
          size="2xs"
          isConfirming={false}
          confirmText="Delete"
          onCancel={deleteModal.off}
          onConfirm={() => {
            deleteModal.off();
            onDelete(room);
          }}
          primaryText="Delete Room"
          secondaryText="Are you sure you want to delete this room?"
        />
      )}
    </>
  );
};
