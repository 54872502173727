import { AppointmentClaimSummaryVO, ClaimSummaryVO, ClaimVO } from "@libs/api/generated-api";
import { BadgeColor } from "@libs/components/UI/Badge";
import { ClaimInsurancePaymentStatus, ClaimState } from "components/Claims/types";

export const isPreAuthClaim = (claim: ClaimVO) => {
  return claim.isPreAuth;
};

export const isDraftClaim = (claim: ClaimVO | ClaimSummaryVO | AppointmentClaimSummaryVO) => {
  return claim.state === "PENDING" || claim.state === "FAILED";
};

export const isSubmittingClaim = (claim: ClaimVO | ClaimSummaryVO | AppointmentClaimSummaryVO) => {
  return claim.state === "SUBMITTING";
};

export const isSubmittedClaim = (claim: ClaimVO | ClaimSummaryVO | AppointmentClaimSummaryVO) => {
  return claim.state === "SUBMITTED";
};

export const isInDraftEobPaymentClaim = (claim: ClaimVO | ClaimSummaryVO | AppointmentClaimSummaryVO) => {
  return Boolean(claim.draftEobPaymentUuid);
};

export const isCompletedClaim = (claim: ClaimVO | ClaimSummaryVO | AppointmentClaimSummaryVO) => {
  return claim.state === "COMPLETED";
};

export const isVoidedClaim = (claim: ClaimVO | ClaimSummaryVO | AppointmentClaimSummaryVO) => {
  return claim.state === "VOIDED";
};

export const canProcessAdditionalPaymentForClaim = (claim: ClaimVO | ClaimSummaryVO) => {
  const allowedPaymentStatuses = new Set(["PAID_AS_EXPECTED", "OVERPAID", "UNDERPAID", "DENIED"]);

  return (
    isCompletedClaim(claim) &&
    allowedPaymentStatuses.has(claim.insurancePaymentStatus) &&
    !claim.replacementClaimUuid &&
    !claim.draftEobPaymentUuid
  );
};

export const getLatestLineItemsGroup = (claim: ClaimVO | undefined) => {
  const latestLineItemGroupIndex = (claim?.lineItemGroups.length || 1) - 1;

  return claim?.lineItemGroups[latestLineItemGroupIndex];
};

export const stateToPillText: Record<ClaimState | "EOB_IN_PROGRESS", string> = {
  COMPLETED: "Completed",
  EOB_IN_PROGRESS: "Processing EOB",
  FAILED: "Draft",
  PENDING: "Draft",
  SUBMITTED: "Sent",
  SUBMITTING: "Submitting...",
  VOIDED: "Voided",
};

export const paymentStatusToPillText: Record<ClaimInsurancePaymentStatus, string> = {
  UNPAID: "Unpaid",
  PAID_AS_EXPECTED: "Paid",
  OVERPAID: "Overpaid",
  UNDERPAID: "Underpaid",
  DENIED: "Denied",
  NOT_SUBMITTED: "Skipped",
};
export const themesByClaimInsuranceStatus: Record<ClaimInsurancePaymentStatus, BadgeColor | undefined> = {
  DENIED: "magenta",
  NOT_SUBMITTED: "white",
  OVERPAID: "orange",
  PAID_AS_EXPECTED: "green",
  UNDERPAID: "red",
  UNPAID: undefined,
};

export const colorsByClaimState: Record<ClaimState | "EOB_IN_PROGRESS", BadgeColor | undefined> = {
  COMPLETED: undefined,
  EOB_IN_PROGRESS: "secondaryTheme",
  FAILED: "white",
  PENDING: "white",
  SUBMITTING: "white",
  SUBMITTED: "blue",
  VOIDED: "white",
};

export const preAuthStateToPillText: Record<ClaimState, string> = {
  ...stateToPillText,
  COMPLETED: "Received",
};

export const preAuthColorsByClaimState: Record<ClaimState, BadgeColor | undefined> = {
  ...colorsByClaimState,
  COMPLETED: "secondaryTheme",
};
