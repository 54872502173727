/* eslint-disable @typescript-eslint/no-magic-numbers */
import {
  InvoiceVO,
  LedgerV2AdjustmentEntryVO,
  LedgerV2AppointmentEditSubEntryVO,
  LedgerV2AppointmentEntryVO,
  LedgerV2AppointmentSubEntryVO,
  LedgerV2ClaimBalanceTransferSubEntryVO,
  LedgerV2ClaimSubEntryVO,
  LedgerV2InvoiceSubEntryVO,
} from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";

export const isLedgerAppointmentEntry = (
  ledgerOrder: LedgerV2AdjustmentEntryVO | LedgerV2AppointmentEntryVO
): ledgerOrder is LedgerV2AppointmentEntryVO => ledgerOrder.type === "APPOINTMENT";

export const isLedgerAdjustmentEntry = (
  ledgerOrder: LedgerV2AdjustmentEntryVO | LedgerV2AppointmentEntryVO
): ledgerOrder is LedgerV2AdjustmentEntryVO => ledgerOrder.type === "ADJUSTMENT";

export const isInvoiceSubEntry = (
  subEntry: LedgerV2AppointmentEntryVO["subEntries"][0]
): subEntry is LedgerV2InvoiceSubEntryVO => subEntry.type === "INVOICE";

export const isAppointmentSubEntry = (
  subEntry: LedgerV2AppointmentEntryVO["subEntries"][0]
): subEntry is LedgerV2AppointmentSubEntryVO => {
  return subEntry.type === "APPOINTMENT";
};

export const isAppointmentEditSubEntry = (
  subEntry: LedgerV2AppointmentEntryVO["subEntries"][0]
): subEntry is LedgerV2AppointmentEditSubEntryVO => {
  return subEntry.type === "APPOINTMENT_EDIT";
};

export const isClaimSubEntry = (
  subEntry: LedgerV2AppointmentEntryVO["subEntries"][0]
): subEntry is LedgerV2ClaimSubEntryVO => subEntry.type === "CLAIM";

export const isClaimBalanceTransferSubEntry = (
  subEntry: LedgerV2AppointmentEntryVO["subEntries"][0]
): subEntry is LedgerV2ClaimBalanceTransferSubEntryVO => subEntry.type === "CLAIM_BALANCE_TRANSFER";

export const isClaimCompleted = (claimSubEntry: LedgerV2ClaimSubEntryVO) => {
  return claimSubEntry.claimState === "COMPLETED";
};

/**
 * Returns the ID of a ledger entry.
 * @param ledgerEntry The ledger entry to get the ID for.
 * @returns The ID of the ledger entry.
 */
export const ledgerEntryToId = (ledgerEntry: LedgerV2AppointmentEntryVO | LedgerV2AdjustmentEntryVO) => {
  const type = ledgerEntry.type;
  const id = isLedgerAppointmentEntry(ledgerEntry) ? ledgerEntry.appointmentId : ledgerEntry.adjustmentUuid;

  return `${type}-${id}`;
};

export const ledgerSubEntryToId = (
  ledgerSubEntry:
    | LedgerV2InvoiceSubEntryVO
    | LedgerV2ClaimSubEntryVO
    | LedgerV2AppointmentSubEntryVO
    | LedgerV2AppointmentEditSubEntryVO
    | LedgerV2ClaimBalanceTransferSubEntryVO
) => {
  const type = ledgerSubEntry.type;
  const id = isInvoiceSubEntry(ledgerSubEntry)
    ? ledgerSubEntry.invoiceUuid
    : isClaimSubEntry(ledgerSubEntry)
      ? ledgerSubEntry.claimUuid
      : isAppointmentSubEntry(ledgerSubEntry)
        ? ledgerSubEntry.appointmentId
        : isAppointmentEditSubEntry(ledgerSubEntry)
          ? // Appointment Edit sub-entries require appending the timestamp to
            // increase likelyhood of uniqueness since an appointment can be editted
            // multiple times and the appointment ID alone wouldn't be enough of a
            // differentiator.
            `${ledgerSubEntry.appointmentId}-${ledgerSubEntry.timestamp}`
          : // Claim Balance Transfer sub-entries require appending the timestamp to
            // increase likelyhood of uniqueness since a claim can generate multiple
            // balance transfers and the claim ID alone wouldn't be enough of a
            // differentiator.
            `${ledgerSubEntry.claimUuid}-${ledgerSubEntry.timestamp}`;

  return `${type}-${id}`;
};

/**
 * Analyzes a ledger adjustment entry and returns an object with boolean values
 * indicating whether the entry is settled, whether it's a credit adjustment,
 * and whether the invoice amount is positive.
 *
 * @param adjustmentEntry - The ledger adjustment entry to analyze.
 * @returns An object with boolean values indicating the status of the
 * adjustment entry.
 */
export const getAdjustmentEntryStatus = (adjustmentEntry: LedgerV2AdjustmentEntryVO) => {
  const isSettled = adjustmentEntry.patientRunningBalance === 0;
  const isCreditAdjustment = adjustmentEntry.customAdjustmentType.entryType === "CREDIT";
  const invoiceSubEntry = adjustmentEntry.subEntries.find(isInvoiceSubEntry);
  const isPositiveInvoice = invoiceSubEntry ? invoiceSubEntry.invoiceAmount >= 0 : false;

  return { isSettled, isCreditAdjustment, isPositiveInvoice };
};

/**
 * Checks whether a credit adjustment has been applied to a ledger entry.
 *
 * @param adjustmentEntry - The ledger adjustment entry to check.
 * @returns A boolean value indicating whether a credit adjustment has been
 * applied to the ledger entry.
 */
export const isCreditApplied = (adjustmentEntry: LedgerV2AdjustmentEntryVO) => {
  const { isCreditAdjustment, isPositiveInvoice } = getAdjustmentEntryStatus(adjustmentEntry);

  return isCreditAdjustment && isPositiveInvoice;
};

const getInvoiceState = (invoice: InvoiceVO | LedgerV2InvoiceSubEntryVO) => {
  if ("state" in invoice) {
    return invoice.state;
  }

  return invoice.invoiceState;
};

const getInvoiceAmount = (invoice: InvoiceVO | LedgerV2InvoiceSubEntryVO) => {
  if ("amount" in invoice) {
    return invoice.amount;
  }

  return invoice.invoiceAmount;
};

/**
 * Checks whether an invoice can be collected.
 * @param invoice The invoice to check.
 * @returns A boolean value indicating whether the invoice can be collected.
 */
export const canCollectInvoice = (invoice: InvoiceVO | LedgerV2InvoiceSubEntryVO) => {
  const invoiceState = getInvoiceState(invoice);
  const invoiceAmount = getInvoiceAmount(invoice);

  return isOneOf(invoiceState, ["FINALIZED", "PARTIALLY_PAID"]) && invoiceAmount > 0;
};

/**
 * Checks whether an invoice can be refunded.
 * @param invoice The invoice to check.
 * @returns A boolean value indicating whether the invoice can be refunded.
 */
export const canRefundInvoice = (invoice: InvoiceVO | LedgerV2InvoiceSubEntryVO) => {
  const invoiceState = getInvoiceState(invoice);
  const invoiceAmount = getInvoiceAmount(invoice);

  return isOneOf(invoiceState, ["FINALIZED", "PARTIALLY_PAID"]) && invoiceAmount < 0;
};

/**
 * Checks whether an invoice can be voided.
 * @param invoice The invoice to check.
 * @returns A boolean value indicating whether the invoice can be voided.
 */
export const canVoidInvoice = (invoice: InvoiceVO | LedgerV2InvoiceSubEntryVO) => {
  const invoiceState = getInvoiceState(invoice);

  return invoiceState === "FINALIZED";
};
