import { FC, FormEvent, useCallback, useState } from "react";
import { produce } from "immer";
import { PaidTimeOffVO } from "@libs/api/generated-api";
import { Button } from "@libs/components/UI/Button";
import { Modal } from "@libs/components/UI/Modal";
import { ModalFooter, ModalContent, ModalForm } from "@libs/components/UI/ModalComponents";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";

interface Props {
  pto: PaidTimeOffVO[];
  onSave: (updated: PaidTimeOffVO[]) => void;
  onClose: Func;
}

const labelsMap: Record<PaidTimeOffVO["type"], string> = {
  SICK_LEAVE: "Sick Hours",
  VACATION: "Paid Time Off",
  HOLIDAY: "Paid Holiday Hours",
};

type EditablePto = {
  name: string;
  type: PaidTimeOffVO["type"];
  hours: number | null;
};

const PtoItem: FC<{
  name: string;
  hours: number | null;
  onChange: (update: Partial<Pick<EditablePto, "hours">>) => void;
}> = ({ name, hours, onChange }) => {
  return (
    <div>
      <div className="font-sansSemiBold text-xs mb-2">{name}</div>
      <FormFieldNumberInput
        label="hrs"
        min={0}
        step={0.01}
        clamp
        className="flex flex-row-reverse items-center gap-2 w-24"
        value={hours}
        onValueChange={(value) => onChange({ hours: value })}
      />
    </div>
  );
};

const types: PaidTimeOffVO["type"][] = ["VACATION", "SICK_LEAVE", "HOLIDAY"];

export const PtoModal: FC<Props> = ({ pto, onClose, onSave }) => {
  const [draftPto, setDraftPto] = useState<EditablePto[]>(
    types.map((type) => {
      const existingHours = pto.find((ptoItem) => ptoItem.type === type)?.hours;

      return {
        name: labelsMap[type],
        type,
        hours: existingHours ?? null,
      };
    })
  );

  const handleChange = useCallback((index: number, val: Partial<Pick<EditablePto, "hours">>) => {
    setDraftPto((last) =>
      produce(last, (lastDraft) => {
        lastDraft[index] = {
          ...lastDraft[index],
          ...val,
        };
      })
    );
  }, []);

  const handleSave = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const ptoToSave = draftPto
        .map(({ type, hours }) => ({ type, hours: hours ?? 0 }))
        .filter((item) => item.hours > 0);

      onSave(ptoToSave);
    },
    [onSave, draftPto]
  );

  return (
    <Modal centerVertically={false} size="xs" title="Paid Time Off" onClose={onClose}>
      <ModalForm onSubmit={handleSave}>
        <ModalContent padding="lg">
          <p className="text-xs mb-5">Enter the PTO hours used by your employee on this pay period.</p>
          <div className="grid grid-cols-3 gap-12">
            {draftPto.map((ptoItem, index) => (
              <PtoItem
                key={ptoItem.type}
                name={ptoItem.name}
                hours={ptoItem.hours}
                onChange={(val) => handleChange(index, val)}
              />
            ))}
          </div>
        </ModalContent>
        <ModalFooter>
          <Button onClick={onClose} className="min-w-button" theme="secondary">
            Cancel
          </Button>
          <Button type="submit" className="min-w-button">
            Save
          </Button>
        </ModalFooter>
      </ModalForm>
    </Modal>
  );
};
