import React from "react";
import { cx } from "@libs/utils/cx";

type PlaceholderProps = {
  style?: React.CSSProperties;
  className: string;
  isNextUpload: boolean;
  name?: string;
  index: number;
  id?: string;
  onClick?: () => void;
};

export const ImageCapturePlaceholder: React.FC<PlaceholderProps> = ({
  style,
  className,
  index,
  name,
  id,
  isNextUpload,
  onClick,
}) => {
  return (
    <button
      type="button"
      onClick={onClick}
      id={id}
      style={style}
      className={cx(isNextUpload ? "border-primaryTheme border-2" : "border border-greyLight", className)}
    >
      <div className="flex h-full justify-center items-center">
        <div
          className={`
            border
            border-dashed
            rounded-full
            border-greyMedium
            w-20
            h-20
            flex
            justify-center
            items-center
          `}
        >
          <p className="font-sansSemiBold text-4xl text-greyMedium">{index + 1}</p>
        </div>
        {name && (
          <div className="absolute bottom-2 left-2">
            <p className="font-sans text-base text-white">{name}</p>
          </div>
        )}
      </div>
    </button>
  );
};
