import React from "react";
import { useDrag } from "react-dnd";
import { Options } from "react-select";
import useMeasure from "react-use-measure";
import { MedicalImageVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { isDefined } from "@libs/utils/types";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { ScrollableInfiniteQuery } from "@libs/components/UI/ScrollableInfiniteQuery";
import { ImageContent } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ImageContent";

import { SIDEBAR_NAVIGATION } from "components/PatientProfile/Imaging/ImageSidebarNavigation/constants";
import { DarkRoomSelect } from "components/PatientProfile/Imaging/DarkRoomSelect";
import { DarkRoomSkeleton } from "components/PatientProfile/Imaging/MountRoute/DarkRoomSkeleton";
import { useContainedTransforms } from "components/PatientProfile/Imaging/hooks/useContainedTransforms";
import { ImageViewMetadata } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ImageViewMetadata";
import { ImageDetailProps } from "components/PatientProfile/Imaging/MountRoute/types";
import { CompareImagingSortType } from "utils/routing/patient";

const cxSidebarClasses = {
  container: `
    flex
    flex-col
    min-h-0
    basis-56
    mx-4
    pr-1
    text-white
    overflow-y-auto
    print:hidden
  `,
  imageContainer: "aspect-square h-36",
  imageItem: "absolute w-full h-full inset-0",
};

type Props = {
  images: MedicalImageVO[];
  selectedImageIds: (number | null)[];
  onImageMounted: (image: MedicalImageVO, mountIndex?: number) => void;
  onSortImages?: (sortCriteria: CompareImagingSortType) => void;
  sortBy?: CompareImagingSortType;
  medicalImagesInfiniteQuery?: UseInfiniteApiQueryResult<MedicalImageVO[]>;
} & Omit<ImageDetailProps, "onEditImage">;

type ImageNavProps<T> = {
  isSelected: boolean;
  image: T;
  onImageMounted: Props["onImageMounted"];
} & Omit<ImageDetailProps, "onEditImage">;

export const ImageNavItem = <T extends MedicalImageVO>({
  image,
  isSelected,
  onImageMounted,
  onImageUpdate,
  devices,
  isShowingMetadata,
  onClickTeeth,
}: ImageNavProps<T>) => {
  const [_, drag] = useDrag(
    () => ({
      type: SIDEBAR_NAVIGATION,
      item: image,
      canDrag: !isSelected,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [onImageMounted, image, isSelected]
  );

  const [containerRef, bounds] = useMeasure({ debounce: 100 });
  const stylesWithRotation = useContainedTransforms(bounds, image, { canExceedImageSize: true });

  return (
    <div
      className={cx(
        `border-2
         flex
         flex-col
         justify-center
         items-center
         relative
         mb-2
         select-none`,
        isSelected ? "border-primaryTheme" : "border-transparent active:cursor-grabbing"
      )}
    >
      <button
        type="button"
        ref={(c) => {
          drag(c);
          containerRef(c);
        }}
        onDoubleClick={() => {
          if (!isSelected) {
            onImageMounted(image);
          }
        }}
        className="w-full flex flex-1 items-center justify-center relative"
      >
        <ImageContent
          image={image}
          className={cxSidebarClasses.imageContainer}
          imageClasses={cxSidebarClasses.imageItem}
          style={stylesWithRotation.styles}
        />
      </button>
      {isShowingMetadata && (
        <ImageViewMetadata
          image={image}
          onImageUpdate={onImageUpdate}
          devices={devices}
          onClickTeeth={onClickTeeth}
        />
      )}
    </div>
  );
};

const LoadingMore: React.FC = () => {
  return (
    <>
      {Array.from({ length: 5 }).map((_, index) => (
        <DarkRoomSkeleton key={index} className={cxSidebarClasses.imageContainer} />
      ))}
    </>
  );
};
const LoadingError: React.FC = () => {
  return <div className="text-xs">Error loading all images. Please try again later.</div>;
};

const SORT_OPTIONS: Options<SelectOption<CompareImagingSortType>> = [
  {
    label: "Date Taken",
    value: "assignedDate" as const,
  },
  {
    label: "Tooth Label",
    value: "teeth" as const,
  },
];

export const Sidebar = ({
  images,
  selectedImageIds,
  onImageMounted,
  onImageUpdate,
  onSortImages,
  sortBy,
  medicalImagesInfiniteQuery,
  isShowingMetadata,
  onClickTeeth,
  devices,
}: Props) => {
  const containerRef = React.useRef<HTMLDivElement | null>(null);

  React.useEffect(() => {
    const handleWheel = (e: WheelEvent) => {
      containerRef.current?.scrollBy({
        top: e.deltaY,
      });
    };

    window.addEventListener("wheel", handleWheel);

    return () => window.removeEventListener("wheel", handleWheel);
  }, []);

  const children = (
    <>
      {onSortImages && sortBy && (
        <div className="flex items-center gap-2 my-2 text-xs">
          Order by{" "}
          <DarkRoomSelect<CompareImagingSortType, SelectOption<CompareImagingSortType>>
            isSearchable={false}
            isClearable={false}
            options={SORT_OPTIONS}
            value={sortBy}
            onChange={(option) => {
              if (option) {
                onSortImages(option.value);
              }
            }}
          />
        </div>
      )}

      {images.map((image) => (
        <ImageNavItem
          key={image.id}
          isSelected={selectedImageIds.includes(isDefined(image.id) ? image.id : null)}
          image={image}
          isShowingMetadata={isShowingMetadata}
          onImageMounted={onImageMounted}
          onImageUpdate={onImageUpdate}
          devices={devices}
          onClickTeeth={onClickTeeth}
        />
      ))}
    </>
  );

  return medicalImagesInfiniteQuery ? (
    <ScrollableInfiniteQuery
      id="images-sidebar"
      infiniteQuery={medicalImagesInfiniteQuery}
      className={cxSidebarClasses.container}
      ref={containerRef}
      loadMore={<LoadingMore />}
      loadMoreError={<LoadingError />}
      loadError={<LoadingError />}
    >
      {() => children}
    </ScrollableInfiniteQuery>
  ) : (
    <div className={cxSidebarClasses.container} ref={containerRef}>
      {children}
    </div>
  );
};
