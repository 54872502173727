import React, { useEffect, useState } from "react";
import { TransformComponent, ReactZoomPanPinchRef, useTransformEffect } from "react-zoom-pan-pinch";

import { cx } from "@libs/utils/cx";
import { round, DecimalPlaces } from "@libs/utils/math";
import { isDefined } from "@libs/utils/types";
import { ImageList } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ImageList";
import { ImageDetailProps, ImageSelectMoveProps } from "components/PatientProfile/Imaging/MountRoute/types";
import {
  ImageWrapperComponentType,
  getImageElementId,
} from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ImageItem";
import { UseSensorCapture } from "components/PatientProfile/Imaging/MountRoute/hooks/useSensorCapture";
import { ImageLayoutItem } from "./types";

type PanZoomContentProps = {
  images: ImageLayoutItem[];
  isLoaded: boolean;
  zoomIn: ReactZoomPanPinchRef["zoomIn"];
  zoomOut: ReactZoomPanPinchRef["zoomOut"];
  zoomToElement?: ReactZoomPanPinchRef["zoomToElement"];
  zoomToImageId?: number;
  captureState?: UseSensorCapture["captureState"];
  ImageWrapper?: ImageWrapperComponentType;
  nextCaptureSpot?: ImageLayoutItem;
} & ImageDetailProps &
  ImageSelectMoveProps;

const useZPressMouseMove = (onVerticalMove: (delta: number) => void) => {
  const zKeyDown = React.useRef(false);
  const mouseStartY = React.useRef<number | null>(null);

  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      if (e.key === "z") {
        zKeyDown.current = true;
      }
    };

    document.addEventListener("keydown", onKeyDown);

    const onKeyUp = (e: KeyboardEvent) => {
      if (e.key === "z") {
        zKeyDown.current = false;
        mouseStartY.current = null;
      }
    };

    document.addEventListener("keyup", onKeyUp);

    const onMouseMove = (e: MouseEvent) => {
      if (!zKeyDown.current) {
        return;
      }

      if (mouseStartY.current === null) {
        mouseStartY.current = e.clientY;
      } else {
        const THROTTLE_AMT = 1000;
        const y = round((e.clientY - mouseStartY.current) / THROTTLE_AMT, DecimalPlaces.hundredth);

        if (y === 0) {
          return;
        }

        onVerticalMove(y);
      }
    };

    document.addEventListener("mousemove", onMouseMove);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
      document.removeEventListener("keyup", onKeyUp);
      document.removeEventListener("mousemove", onMouseMove);
    };
  }, [onVerticalMove]);
};

const ZOOM_COEFFICIENT = 1300;

export const PanZoomContent: React.FC<PanZoomContentProps> = ({
  isLoaded,
  zoomIn,
  zoomOut,
  isShowingMetadata = false,
  zoomToElement,
  zoomToImageId,
  captureState,
  nextCaptureSpot,
  ...rest
}) => {
  const onZoomMove = React.useCallback(
    (delta: number) => {
      if (delta < 0) {
        zoomIn(-delta);
      } else {
        zoomOut(delta);
      }
    },
    [zoomIn, zoomOut]
  );

  useZPressMouseMove(onZoomMove);

  const [zoomScale, setZoomScale] = useState<number>();

  useTransformEffect((context) => {
    setZoomScale(context.state.scale);
  });

  useEffect(() => {
    if (isDefined(zoomToImageId) && captureState?.hasStartedCapture) {
      const zoomValue = window.innerWidth / ZOOM_COEFFICIENT;

      zoomToElement?.(getImageElementId(zoomToImageId), zoomValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [zoomToImageId, captureState?.hasStartedCapture]);

  return (
    <TransformComponent wrapperClass={cx("cursor-grab w-full", !isLoaded && "invisible")}>
      <ImageList
        isShowingMetadata={isShowingMetadata}
        scale={zoomScale || 1}
        nextCaptureSpot={nextCaptureSpot}
        {...rest}
      />
    </TransformComponent>
  );
};
