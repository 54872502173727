import React from "react";
import { useNavigate } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { ClaimModalHeader } from "components/Claim/ModalHeader";
import { ClaimAttachmentsFooter } from "components/Claim/Attachments/ClaimAttachmentsFooter";
import { NarrativesAttachments } from "components/Claim/Attachments/NarrativesAttachments";
import { OtherAttachments } from "components/Claim/Attachments/OtherAttachments";
import { AttachmentsProvider, useAttachments } from "components/Claim/Attachments/ClaimAttachmentsContext";
import { PerioChartAttachments } from "components/Claim/Attachments/PerioChartAttachments";
import {
  AttachmentsViewProvider,
  useAttachmentsView,
} from "components/Claim/Attachments/AttachmentsViewContext";
import { ImagesAttachments } from "components/Claim/Attachments/ImagesAttachments";
import { ReviewAttachments } from "components/Claim/Attachments/ReviewAttachments";
import { paths } from "utils/routing/paths";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";
import { ClaimAttachmentsHeader } from "components/Claim/Attachments/ClaimAttachmentsHeader";
import { LoadingView } from "components/Claim/Attachments/LoadingView";
import { Divider } from "components/UI/Divider";

export const ClaimAttachmentsRoute: React.FC = () => (
  <AttachmentsProvider>
    <AttachmentsViewProvider>
      <ClaimUploadAttachmentsContainer />
    </AttachmentsViewProvider>
  </AttachmentsProvider>
);

const tabIndexMap = [ImagesAttachments, NarrativesAttachments, PerioChartAttachments, OtherAttachments];

const cxStyles = {
  contentPadding: "px-6",
};

const ClaimUploadAttachmentsContainer: React.FC = () => {
  const { claim } = useAttachments();
  const { activeTabIndex, isInReview } = useAttachmentsView();

  usePageTitle(`Claims ${claim?.claimNumber ? `#${claim.claimNumber}` : ""} - Attachments`);

  const navigate = useNavigate();
  const { query } = useQueryParams("claimAttachments");
  const { claimUuid } = usePathParams("claimAttachments");

  const from = query.from ?? paths.claim({ claimUuid });

  const handleClose = () => {
    navigate(from, { replace: true });
  };

  const TabComponent = isInReview && claim ? ReviewAttachments : tabIndexMap[activeTabIndex];

  return (
    <>
      <ClaimModalHeader
        data-testid="attachments-claim-header"
        onClose={handleClose}
        title="Upload Attachments"
      />
      <div className="flex flex-col flex-1 min-h-0 overflow-y-hidden">
        <div className={cxStyles.contentPadding}>
          <ClaimAttachmentsHeader />
        </div>
        <Divider />
        <div
          className={cx("flex-1 min-h-0 overflow-y-hidden", cxStyles.contentPadding, claim ? "mt-6" : "mt-1")}
        >
          {claim ? <TabComponent claim={claim} /> : <LoadingView />}
        </div>
      </div>
      <ClaimAttachmentsFooter onClose={handleClose} />
    </>
  );
};
