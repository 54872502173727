import React, { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { AddressVO, CreateCustomCarrierRequest, InsuranceCarrierDetailsVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useValidation } from "@libs/hooks/useValidation";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Button } from "@libs/components/UI/Button";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ModalContent, ModalFooter, ModalForm } from "@libs/components/UI/ModalComponents";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { getAllInsuranceCarriersQuery, getCarrier } from "api/practiceInsurance/queries";
import { paths } from "utils/routing/paths";
import { usePathParams } from "hooks/usePathParams";
import { ModalPage } from "components/UI/ModalPage";
import { useQueryParams } from "hooks/useQueryParams";
import { InsuranceDetailsForm } from "components/Settings/InsuranceCarriers/InsuranceDetails/InsuranceDetailsForm";
import { getCarrierRequestData, insuranceCarrierSchema } from "components/Settings/InsuranceCarriers/schema";
import {
  createCustomCarrier,
  updateCustomCarrier,
  updateArchyCarrier,
} from "api/practiceInsurance/mutations";
import { handleError } from "utils/handleError";
import { useCurrentUser } from "contexts/CurrentUserContext";

export const EMPTY_ADDRESS: AddressVO = {
  address1: "",
  city: "",
  state: "",
  zip: "",
};

const EMPTY_CARRIER: CreateCustomCarrierRequest = {
  name: "",
  payerId: "",
  address: EMPTY_ADDRESS,
};

export const InsuranceDetailsRoute: React.FC = () => {
  const navigate = useNavigate();
  const { carrierId } = usePathParams("insuranceDetails");
  const isNew = carrierId === "new";
  const { query } = useQueryParams("insuranceDetails");
  const from = query.from ?? paths.settingsSection({ section: "insurances" });
  const { practiceId } = useAccount();
  const currentUser = useCurrentUser();
  const [insuranceDetailsQuery, allInsuranceCarriersQuery] = useApiQueries([
    getCarrier({
      args: { practiceId, carrierId: isNew ? 0 : carrierId },
      queryOptions: { enabled: !isNew },
    }),
    getAllInsuranceCarriersQuery({ args: { practiceId } }),
  ]);

  const isArchyEmployee = currentUser.type === "SUPPORT_USER" || currentUser.email.includes("@archy.com");

  const [draft, updateDraft, setDraft] = useObjectState<Partial<InsuranceCarrierDetailsVO>>(EMPTY_CARRIER);

  useEffect(() => {
    if (!draft.id && insuranceDetailsQuery.data) {
      setDraft(insuranceDetailsQuery.data);
    }
  }, [draft.id, insuranceDetailsQuery.data, setDraft]);

  const dirty = useBoolean(false);

  const changesMadeModal = useBoolean(false);

  const onRequestClose = useCallback(() => {
    if (dirty.isOn) {
      changesMadeModal.on();
    } else {
      navigate(from);
    }
  }, [changesMadeModal, dirty.isOn, from, navigate]);

  const { validate, result } = useValidation(draft, insuranceCarrierSchema);

  const [createCustomCarrierMutation, updateArchyCarrierMutation, updateCustomCarrierMutation] =
    useApiMutations([createCustomCarrier, updateArchyCarrier, updateCustomCarrier]);

  const handleSubmit: React.FormEventHandler<HTMLFormElement> = React.useCallback(
    (e) => {
      e.preventDefault();

      if (validate().$isValid) {
        if (isNew) {
          createCustomCarrierMutation.mutate(
            {
              practiceId,
              data: getCarrierRequestData(draft),
            },
            {
              onSuccess: () => navigate(from),
              onError: handleError,
            }
          );
        } else {
          const args = { practiceId, carrierId, data: getCarrierRequestData(draft) };
          const queryOptions = {
            onSuccess: () => navigate(from),
            onError: handleError,
          };

          if (draft.type === "GLOBAL" && !isArchyEmployee) {
            updateArchyCarrierMutation.mutate(args, queryOptions);
          } else {
            updateCustomCarrierMutation.mutate(args, queryOptions);
          }
        }
      }
    },
    [
      carrierId,
      createCustomCarrierMutation,
      draft,
      from,
      navigate,
      isArchyEmployee,
      isNew,
      practiceId,
      updateArchyCarrierMutation,
      updateCustomCarrierMutation,
      validate,
    ]
  );

  return (
    <ModalPage
      className="w-full"
      closeLink={from}
      title={
        <div className="pl-2 font-sansSemiBold">
          {isNew
            ? "New Insurance Carrier"
            : draft.type === "CUSTOM"
              ? "Custom Insurance Carrier"
              : "Insurance Carrier"}
        </div>
      }
    >
      <ModalForm
        className="h-full"
        data-testid="insurance-carrier-form"
        fieldLayout="labelOut"
        onSubmit={handleSubmit}
      >
        <ModalContent padding="sm">
          <QueryResult queries={[insuranceDetailsQuery]}>
            {isNew || draft.id ? (
              <InsuranceDetailsForm
                allCarriers={allInsuranceCarriersQuery.data}
                initialDetails={insuranceDetailsQuery.data}
                insuranceDetails={draft}
                isArchyEmployee={isArchyEmployee}
                onDirty={dirty.on}
                onUpdate={updateDraft}
                validation={result}
              />
            ) : null}
          </QueryResult>
        </ModalContent>
        <ModalFooter>
          <Button onClick={onRequestClose} theme="secondary" className="min-w-button">
            Cancel
          </Button>
          <Button className="min-w-button" disabled={dirty.isOff} type="submit">
            Save
          </Button>
        </ModalFooter>
      </ModalForm>
      {changesMadeModal.isOn && (
        <ConfirmationModal
          cancelText="No"
          confirmText="Yes"
          onCancel={changesMadeModal.off}
          onConfirm={() => navigate(from)}
          primaryText="Changes Made"
          secondaryText="Changes will be lost, do you still want to leave?"
          size="2xs"
        />
      )}
    </ModalPage>
  );
};
