import { FC, PropsWithChildren } from "react";

export const EmptyProceduresList: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className={`
        h-full
        flex
        items-center
        justify-center
        text-sm
        text-greyMedium
      `}
    >
      {children}
    </div>
  );
};
