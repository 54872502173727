import { dollarsToCents } from "@libs/utils/currency";
import { AdjustmentRequestWithDisplayValue } from "components/PatientProfile/Billing/InvoiceAdjustmentFormRow";

export type AdjustmentOperation =
  | { command: "UPDATE_ADJUSTMENT_VALUE"; adjustmentValue: string }
  | { command: "SWITCH_TO_PERCENTAGE" }
  | { command: "SWITCH_TO_AMOUNT" }
  | { command: "APPLY_TO_APPOINTMENT" }
  | { command: "APPLY_TO_PROCEDURE" }
  | { command: "UPDATE_PROCEDURE_AMOUNT"; dollarValue: string; procedureIndex: number }
  | { command: "CHECK_PROCEDURE_PERCENTAGE"; procedureIndex: number }
  | { command: "UNCHECK_PROCEDURE_PERCENTAGE"; procedureIndex: number };

/**
 * Mutates an adjustment given an adjustment operation.
 * @param adjustment Adjustment to mutate
 * @param operation Operation to apply on adjustment
 */
export const updateAdjustment = (
  adjustment: AdjustmentRequestWithDisplayValue,
  operation: AdjustmentOperation
) => {
  switch (operation.command) {
    case "UPDATE_ADJUSTMENT_VALUE": {
      updateAdjustmentValue(adjustment, operation.adjustmentValue);

      break;
    }
    case "SWITCH_TO_PERCENTAGE": {
      switchToPercentage(adjustment);
      break;
    }
    case "SWITCH_TO_AMOUNT": {
      switchToAmount(adjustment);
      break;
    }
    case "APPLY_TO_APPOINTMENT": {
      applyValueToAppointment(adjustment);

      break;
    }
    case "APPLY_TO_PROCEDURE": {
      applyValueToProcedures(adjustment);

      break;
    }
    case "UPDATE_PROCEDURE_AMOUNT": {
      updateProcedureAmount(adjustment, operation.procedureIndex, operation.dollarValue);

      break;
    }
    case "CHECK_PROCEDURE_PERCENTAGE": {
      checkProcedurePercentage(adjustment, operation.procedureIndex);

      break;
    }
    case "UNCHECK_PROCEDURE_PERCENTAGE": {
      uncheckProcedurePercentage(adjustment, operation.procedureIndex);

      break;
    }
    default: {
      break;
    }
  }
};

const updateAdjustmentValue = (adjustment: AdjustmentRequestWithDisplayValue, value: string) => {
  adjustment.displayValue = value;

  if (adjustment.applyAdjustmentTo === "APPOINTMENT") {
    applyValueToAppointment(adjustment);
  } else if (adjustment.applyAdjustmentTo === "PROCEDURE") {
    applyValueToProcedures(adjustment);
  }
};

const switchToPercentage = (adjustment: AdjustmentRequestWithDisplayValue) => {
  adjustment.percentOrDollar = "percent";

  if (adjustment.applyAdjustmentTo === "PROCEDURE") {
    adjustment.procedureAdjustments = [...(adjustment.defaultProcedures ?? [])];
  }

  updateAdjustmentValue(adjustment, adjustment.displayValue ?? "");
};

const switchToAmount = (adjustment: AdjustmentRequestWithDisplayValue) => {
  adjustment.percentOrDollar = "dollar";

  if (adjustment.applyAdjustmentTo === "PROCEDURE") {
    adjustment.procedureAdjustments = [...(adjustment.defaultProcedures ?? [])];
  }

  updateAdjustmentValue(adjustment, adjustment.displayValue ?? "");
};

const applyValueToAppointment = (adjustment: AdjustmentRequestWithDisplayValue) => {
  adjustment.applyAdjustmentTo = "APPOINTMENT";
  delete adjustment.procedureAdjustments;

  if (adjustment.percentOrDollar === "dollar") {
    delete adjustment.adjustmentPercentage;

    if (Number(adjustment.displayValue) > 0) {
      adjustment.adjustmentAmount = dollarsToCents(Number(adjustment.displayValue));
    } else {
      delete adjustment.adjustmentAmount;
    }
  } else {
    delete adjustment.adjustmentAmount;

    if (Number(adjustment.displayValue) > 0) {
      adjustment.adjustmentPercentage = adjustment.displayValue;
    } else {
      delete adjustment.adjustmentPercentage;
    }
  }
};

const applyValueToProcedures = (adjustment: AdjustmentRequestWithDisplayValue) => {
  adjustment.applyAdjustmentTo = "PROCEDURE";
  delete adjustment.adjustmentAmount;
  delete adjustment.adjustmentPercentage;

  if (adjustment.procedureAdjustments == null) {
    adjustment.procedureAdjustments = [...(adjustment.defaultProcedures ?? [])];
  }

  if (adjustment.percentOrDollar === "percent") {
    adjustment.procedureAdjustments.forEach((proc) => {
      delete proc.adjustmentAmount;

      if (adjustment.displayValue && proc.adjustmentPercentage != null) {
        proc.adjustmentPercentage = adjustment.displayValue;
      } else {
        delete proc.adjustmentPercentage;
      }
    });
  }
};

const updateProcedureAmount = (
  adjustment: AdjustmentRequestWithDisplayValue,
  procedureIndex: number,
  dollarValue: string
) => {
  if (adjustment.procedureAdjustments == null) {
    return;
  }

  const dollarAmount = Number(dollarValue);

  if (dollarAmount === 0) {
    delete adjustment.procedureAdjustments[procedureIndex].adjustmentAmount;
  } else {
    adjustment.procedureAdjustments[procedureIndex].adjustmentAmount = dollarsToCents(dollarAmount);
  }
};

const checkProcedurePercentage = (adjustment: AdjustmentRequestWithDisplayValue, procedureIndex: number) => {
  if (adjustment.procedureAdjustments == null) {
    return;
  }

  if (Number(adjustment.displayValue) > 0) {
    adjustment.procedureAdjustments[procedureIndex].adjustmentPercentage = adjustment.displayValue;
  }
};

const uncheckProcedurePercentage = (
  adjustment: AdjustmentRequestWithDisplayValue,
  procedureIndex: number
) => {
  if (adjustment.procedureAdjustments == null) {
    return;
  }

  delete adjustment.procedureAdjustments[procedureIndex].adjustmentPercentage;
};
