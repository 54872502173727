import { FC, ReactNode } from "react";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { FormFieldButton, FormFieldButtonProps } from "@libs/components/UI/FormFieldButton";
import { UseBooleanResult, useBoolean } from "@libs/hooks/useBoolean";
import { ReactComponent as DropDownIcon } from "@libs/assets/icons/down-caret.svg";
import { cx } from "@libs/utils/cx";

type MenuContentRenderProps = (menu: UseBooleanResult) => ReactNode;

interface Props extends Omit<FormFieldButtonProps, "Icon" | "className" | "type" | "onMouseDown"> {
  className?: string;
  buttonClassName?: string;
  matchTriggerWidth?: boolean;
  onRequestClose?: Func;
  onRequestOpen?: Func;
  menuContent: ReactNode | MenuContentRenderProps;
}

export const FormFieldMenu: FC<Props> = ({
  menuContent,
  className,
  buttonClassName,
  matchTriggerWidth,
  onRequestClose,
  onRequestOpen,
  ...formFieldButtonProps
}) => {
  const menu = useBoolean(false);

  const handleRequestOpen = () => {
    onRequestOpen?.();
    menu.on();
  };

  const handleRequestClose = () => {
    onRequestClose?.();
    menu.off();
  };

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={handleRequestOpen}
      onRequestClose={handleRequestClose}
      placement="bottom-start"
      menuContent={typeof menuContent === "function" ? menuContent(menu) : menuContent}
      className={className}
      matchTriggerWidth={matchTriggerWidth}
    >
      {(props) => (
        <FormFieldButton
          {...props}
          {...formFieldButtonProps}
          Icon={DropDownIcon}
          className={buttonClassName}
          iconClassName={cx(
            "transition-transform",
            menu.isOn && "rotate-180",
            formFieldButtonProps.iconClassName
          )}
        />
      )}
    </ButtonMenu>
  );
};
