import { FC, useMemo } from "react";
import { cx } from "@libs/utils/cx";
import { ReactComponent as DrawArrowSingle } from "@libs/assets/icons/right-arrow.svg";

const cxStyles = {
  label: "w-14 font-sans text-xs text-greyMedium",
};

interface Props {
  className?: string;
  direction: "left" | "right";
  label: string;
  color?: string;
}

export const ArrowLabel: FC<Props> = ({ className, direction, label, color }) => {
  const isLeft = useMemo(() => direction === "left", [direction]);

  return (
    <div className={cx("flex items-center gap-x-2", isLeft ? "-mr-2" : "-ml-2", className)}>
      {isLeft && <span className={cxStyles.label}>{label}</span>}
      <DrawArrowSingle
        className={cx("h-5 mt-px", isLeft && "rotate-180")}
        style={color ? { color } : undefined}
      />
      {!isLeft && <span className={cxStyles.label}>{label}</span>}
    </div>
  );
};
