import { useAccount } from "@libs/contexts/AccountContext";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import React from "react";
import { useNavigate } from "react-router-dom";
import { MountVO, PatientSummaryVO } from "@libs/api/generated-api";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { useQueryParams } from "hooks/useQueryParams";
import { SensorCaptureStatus, shouldShowStatus } from "components/PatientProfile/Imaging/SensorCaptureStatus";
import { paths } from "utils/routing/paths";
import { UseCameraCaptureState } from "components/ImageCapturing/useCameraCapture";
import { UploadCallback } from "components/PatientProfile/Imaging/MountRoute/types";
import { CaptureDevice } from "components/PatientProfile/Imaging/MountRoute/MountHeader/types";
import { UseArchyAgentUploader } from "components/PatientProfile/Imaging/MountRoute/hooks/useArchyAgentUploader";
import { ImageLayoutItem } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";
import { generateMount } from "api/imaging/mutations";
import { handleError } from "utils/handleError";
import { CameraDevice } from "components/ImageCapturing/useCameraSource";
import { MOUNT_IMAGE_MAX_LENGTH } from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { FirstCapturesButton } from "components/PatientProfile/Imaging/MountRoute/MountHeader/FirstCapturesButton";
import { NewImagePopoverButton } from "components/PatientProfile/Imaging/PatientMountsList/NewImagePopoverButton";
import { CameraUploadModal } from "components/PatientProfile/Imaging/MountRoute/CameraUploadModal";
import { ImportImageModal } from "components/PatientProfile/Imaging/ImportImageModal";
import { MountLayoutType } from "api/imaging/imaging-api";
import { useImportImageSpot } from "components/PatientProfile/Imaging/hooks/useImportImageSpot";

export type HeaderUploadOptions = {
  patient: PatientSummaryVO;
  imageMount: MountVO;
  isFirstCaptureSession: boolean;
  cameraCapture: UseCameraCaptureState;
  onUpload: UploadCallback;
  isUploading: boolean;
  selectedCaptureDevice: CaptureDevice;
  captureState: UseArchyAgentUploader["captureState"];
  nextCaptureSpot?: ImageLayoutItem;
  sandboxImages: ImageLayoutItem[];
  handleUploadWithSensor: (params: { deviceId: string }) => void;
  onCaptureDeviceChanged: (device: CaptureDevice) => void;
  onToggleRetake?: (isRetaking: boolean) => void;
  isRetaking: boolean;
};

// eslint-disable-next-line complexity
export const HeaderUploadOptions = ({
  patient,
  imageMount,
  isFirstCaptureSession,
  cameraCapture,
  onUpload,
  isUploading,
  selectedCaptureDevice,
  captureState,
  nextCaptureSpot,
  sandboxImages: sandboxImages,
  handleUploadWithSensor,
  onCaptureDeviceChanged,
  onToggleRetake = () => {},
  isRetaking,
}: HeaderUploadOptions) => {
  const [modalOpen, setModalOpen] = React.useState<"upload" | "capture" | "maximum-reached" | undefined>(
    undefined
  );

  const { practiceId } = useAccount();
  const navigate = useNavigate();
  const { getNewUploadSpot, resetSpotGenerator } = useImportImageSpot({
    sandboxImages,
    startingCaptureSpot: nextCaptureSpot,
  });

  const closeModal = React.useCallback(() => {
    setModalOpen(undefined);
    resetSpotGenerator();
  }, [resetSpotGenerator]);
  const searchParams = useQueryParams("mountDetails");
  const [createMountMutation] = useApiMutations([generateMount]);
  const createMountMutate = createMountMutation.mutate;
  const handleOverflowCreateMount = React.useCallback(() => {
    const params: { [s: string]: string } = {};

    createMountMutate(
      {
        patientId: patient.id,
        practiceId,
        data: {
          layout: imageMount.layout,
          name: imageMount.name,
        },
      },

      {
        onSuccess: (response) => {
          navigate(paths.mountDetails({ mountId: response.data.data.id, patientId: patient.id }, params));
        },
        onError: handleError,
      }
    );
  }, [createMountMutate, practiceId, imageMount.layout, imageMount.name, navigate, patient.id]);
  const didTriggerFromMountList = Boolean(searchParams.query.deviceId || searchParams.query.import);

  const handleCaptureDeviceChanged = React.useCallback(
    (device: CameraDevice) => {
      onCaptureDeviceChanged({ mode: "PHOTO", ...device });
      setModalOpen("capture");
    },
    [onCaptureDeviceChanged]
  );
  const handleUpload: UploadCallback = React.useCallback(
    async (imageParams) => {
      if (selectedCaptureDevice.mode === "UNKNOWN") {
        throw new Error("Unknown capture device");
      }

      const result = onUpload(
        {
          ...imageParams,
          type: modalOpen === "upload" ? "EXTERNAL" : selectedCaptureDevice.mode,
        },
        selectedCaptureDevice.deviceId
      );

      if ((imageMount.images?.length ?? 0) + 1 >= MOUNT_IMAGE_MAX_LENGTH) {
        setModalOpen("maximum-reached");
      }

      return result;
    },
    [
      onUpload,
      modalOpen,
      selectedCaptureDevice.mode,
      selectedCaptureDevice.deviceId,
      imageMount.images?.length,
    ]
  );
  const shouldShowOnboardingButtons = isFirstCaptureSession && didTriggerFromMountList;

  const shouldShowCaptureButton = shouldShowStatus(captureState);

  return (
    <>
      {!shouldShowCaptureButton &&
        (shouldShowOnboardingButtons ? (
          <FirstCapturesButton
            onModalToggle={setModalOpen}
            isCapturing={captureState.hasStartedCapture}
            mode={selectedCaptureDevice.mode}
            handleUploadWithSensor={handleUploadWithSensor}
          />
        ) : (
          <NewImagePopoverButton
            onSensorClick={handleUploadWithSensor}
            cameraCapture={cameraCapture}
            onCameraClick={handleCaptureDeviceChanged}
            onImportClick={() => {
              setModalOpen("upload");
            }}
            isDarkMode
            patientId={patient.id}
            disabled={captureState.hasStartedCapture}
            limitReached={(imageMount.images?.length ?? 0) >= MOUNT_IMAGE_MAX_LENGTH}
            onCreateNewMount={handleOverflowCreateMount}
          />
        ))}
      {shouldShowCaptureButton && (
        <SensorCaptureStatus
          captureState={captureState}
          onToggleRetake={onToggleRetake}
          isRetaking={isRetaking}
        />
      )}
      {selectedCaptureDevice.deviceId && modalOpen === "capture" && (
        <CameraUploadModal
          cameraCapture={cameraCapture}
          deviceId={selectedCaptureDevice.deviceId}
          closeModal={closeModal}
          handleUploadPhoto={handleUpload}
          getNewUploadSpot={getNewUploadSpot}
          label={selectedCaptureDevice.label}
        />
      )}
      {modalOpen === "upload" && (
        <ImportImageModal
          getNewUploadSpot={getNewUploadSpot}
          closeModal={closeModal}
          uploadFile={handleUpload}
          isUploading={isUploading}
          mountLayout={imageMount.layout as MountLayoutType}
        />
      )}
      {modalOpen === "maximum-reached" && (
        <ConfirmationModal
          size="sm"
          onCancel={closeModal}
          onConfirm={() => {
            handleOverflowCreateMount();
            closeModal();
          }}
          primaryText="Upload completed. Please create a new mount to add more images."
          secondaryText={`Mounts can only contain up to ${MOUNT_IMAGE_MAX_LENGTH} images. Create a new mount?`}
        />
      )}
    </>
  );
};
