import { Dispatch, SetStateAction, useCallback, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { produce } from "immer";
import { FrequencyLimitVO, ShortcutBenefitLimitationVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as AddNoteIcon } from "@libs/assets/icons/note.svg";
import { ReactComponent as ViewNoteIcon } from "@libs/assets/icons/google-docs.svg";
import { ReactComponent as VerifyIcon } from "@libs/assets/icons/verified-badge.svg";
import {
  TableGrid,
  HeaderCell,
  Row,
  ButtonCell,
  ColumnSortIndicator,
  HeaderButtonCell,
} from "@libs/components/UI/GridTableComponents";
import { LimitationValidationResult } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/formData";
import {
  AgeInputCell,
  CdtCodeSearchCell,
  Cell,
  FullWidthRow,
  InputCell,
  ProcedureSelectCell,
  WaitingPeriodCell,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/TableItems";
import { planCxStyles } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/styles";
import { SelectFrequencyLimit } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/SelectFrequencyLimit";
import { BenefitLimitationFormItem } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/useLimitationFields";
import { cdtCodeToString } from "utils/cdtCode";

interface Props {
  editing: boolean;
  insuranceOrPlanIsAutoVerified: boolean;
  isViewingDefault: boolean;
  loading: boolean;
  limitationItems: BenefitLimitationFormItem[];
  onAddLimitationItem: Func;
  onLimitationItemChange: (index: number, updated: BenefitLimitationFormItem) => void;
  onLimitationItemDelete: (index: number, limitation: BenefitLimitationFormItem) => void;
  onOpenLimitationNotesModal: (newItem: { index: number; limitation: BenefitLimitationFormItem }) => void;
  onPreferredLimitationItemChange: (index: number, updated: ShortcutBenefitLimitationVO) => void;
  onUpdateAllLimitations: Dispatch<SetStateAction<BenefitLimitationFormItem[]>>;
  preferredLimitationItems: ShortcutBenefitLimitationVO[];
  validation: LimitationValidationResult;
}

type LimitationHeader = {
  id: string;
  text: string;
  sortable?: boolean;
  width: string;
};

const PreferredLimitationRow: React.FC<{
  editing: boolean;
  insuranceOrPlanIsAutoVerified: boolean;
  index: number;
  limitation: ShortcutBenefitLimitationVO;
  onChange: (index: number, limitation: ShortcutBenefitLimitationVO) => void;
}> = ({ editing, index, insuranceOrPlanIsAutoVerified, limitation, onChange }) => {
  return (
    <Row>
      <InputCell
        className={planCxStyles.tableCell}
        edit={false}
        onChange={(value) => {
          onChange(index, { ...limitation, autoEligibility: false, serviceName: value });
        }}
        value={limitation.serviceName}
      />
      <Cell className={planCxStyles.tableCell}>
        <SelectFrequencyLimit
          editable={editing}
          frequency={limitation.frequency ?? {}}
          frequencyLimitationCategory={limitation.frequencyLimitationCategory}
          onChange={(value) => {
            onChange(index, { ...limitation, autoEligibility: false, frequency: value as FrequencyLimitVO });
          }}
        />
      </Cell>
      <AgeInputCell
        ageMin={limitation.ageMin}
        ageMax={limitation.ageMax}
        className={cx("flex gap-x-1", planCxStyles.tableCell)}
        edit={editing}
        inputClassName="flex-1"
        min={0}
        onChangeMin={(ageMin) => {
          onChange(index, { ...limitation, autoEligibility: false, ageMin });
        }}
        onChangeMax={(ageMax) => {
          onChange(index, { ...limitation, autoEligibility: false, ageMax });
        }}
      />
      {insuranceOrPlanIsAutoVerified && (
        <Cell className={planCxStyles.tableCell}>
          {limitation.autoEligibility && (
            <Icon
              SvgIcon={VerifyIcon}
              theme="primary"
              tooltip={{ content: "Auto-Verified", theme: "SMALL" }}
            />
          )}
        </Cell>
      )}
    </Row>
  );
};

const LimitationRow: React.FC<{
  editing: boolean;
  index: number;
  insuranceOrPlanIsAutoVerified: boolean;
  limitation: BenefitLimitationFormItem;
  onChange: (index: number, limitation: BenefitLimitationFormItem) => void;
  onDelete: Func;
  onOpenNoteModal: Func;
  validation?: LimitationValidationResult["$items"][number];
}> = ({
  editing,
  index,
  insuranceOrPlanIsAutoVerified,
  limitation,
  onChange,
  onDelete,
  onOpenNoteModal,
  validation,
}) => {
  const handleWaitingPeriodChange = useCallback(
    (numOfMonths: number | undefined) => {
      onChange(index, {
        ...limitation,
        autoEligibility: false,
        waitingPeriodInMonths: numOfMonths,
      });
    },
    [index, limitation, onChange]
  );

  return (
    <Row>
      <InputCell
        className={planCxStyles.tableCell}
        error={validation?.serviceName.$error}
        edit={editing}
        onChange={(value) => {
          onChange(index, { ...limitation, autoEligibility: false, serviceName: value });
        }}
        value={limitation.serviceName}
      />
      <ProcedureSelectCell
        className={planCxStyles.tableCell}
        index={index}
        isEditing={editing}
        limitation={limitation}
        onChange={onChange}
      />
      <Cell className={planCxStyles.tableCell}>
        <SelectFrequencyLimit
          editable={editing}
          frequency={limitation.frequency ?? {}}
          frequencyLimitationCategory={limitation.frequencyLimitationCategory}
          onChange={(value) => {
            onChange(index, { ...limitation, autoEligibility: false, frequency: value as FrequencyLimitVO });
          }}
        />
      </Cell>
      <AgeInputCell
        ageMin={limitation.ageMin}
        ageMax={limitation.ageMax}
        className={cx("flex gap-x-1", planCxStyles.tableCell)}
        edit={editing}
        inputClassName="flex-1"
        min={0}
        onChangeMin={(ageMin) => {
          onChange(index, { ...limitation, autoEligibility: false, ageMin });
        }}
        onChangeMax={(ageMax) => {
          onChange(index, { ...limitation, autoEligibility: false, ageMax });
        }}
      />

      <WaitingPeriodCell
        className={planCxStyles.tableCell}
        isEditing={editing}
        onChange={(val) => handleWaitingPeriodChange(val)}
        waitingPeriodInMonths={limitation.waitingPeriodInMonths}
      />
      <CdtCodeSearchCell
        align="right"
        className={cx("w-full", planCxStyles.tableCell)}
        edit={editing}
        isClearable
        onChange={(_value, procedure) =>
          onChange(index, { ...limitation, autoEligibility: false, downgradeProcedure: procedure })
        }
        value={limitation.downgradeProcedure?.cdtCode}
      />
      <ButtonCell className={planCxStyles.tableCell} hasPadding={false}>
        <Icon SvgIcon={limitation.notes ? ViewNoteIcon : AddNoteIcon} onClick={onOpenNoteModal} />
      </ButtonCell>
      {insuranceOrPlanIsAutoVerified && (
        <Cell className={planCxStyles.tableCell}>
          {limitation.autoEligibility && (
            <Icon
              SvgIcon={VerifyIcon}
              theme="primary"
              tooltip={{ content: "Auto-Verified", theme: "SMALL" }}
            />
          )}
        </Cell>
      )}
      {editing ? (
        <ButtonCell className={planCxStyles.tableCell} hasPadding={false}>
          <Icon SvgIcon={DeleteIcon} onClick={onDelete} />
        </ButtonCell>
      ) : (
        <Cell className={planCxStyles.tableCell} />
      )}
    </Row>
  );
};

export const LimitationsTable: React.FC<Props> = ({
  editing,
  insuranceOrPlanIsAutoVerified,
  isViewingDefault,
  limitationItems,
  loading,
  onAddLimitationItem,
  onLimitationItemChange,
  onLimitationItemDelete,
  onOpenLimitationNotesModal,
  onPreferredLimitationItemChange,
  onUpdateAllLimitations,
  preferredLimitationItems,
  validation,
}) => {
  const headers: LimitationHeader[] = useMemo(() => {
    const serviceHeader = {
      id: "service",
      text: "Service",
      sortable: !isViewingDefault,
      width: isViewingDefault ? "1fr" : "2fr",
    };

    const frequencyLimitHeader = {
      id: "frequencyLimit",
      text: "Frequency Limit",
      width: isViewingDefault ? "2fr" : editing ? "28rem" : "4fr",
    };
    const ageHeader = { id: "age", text: "Min - Max Age", width: "110px" };
    const autoVerifyHeader = { id: "autoverify", text: "", width: "50px" };

    const allHeaders = [
      serviceHeader,
      { id: "procedure", text: "Procedures", sortable: true, width: editing ? "14rem" : "2fr" },
      frequencyLimitHeader,
      ageHeader,
      { id: "waitingPeriod", text: "Waiting Period", width: "170px" },
      { id: "downgrade", text: "Downgrade", width: editing ? "9rem" : "144px" },
      { id: "notes", text: "", width: "40px" },
      { id: "delete", text: "", width: "40px" },
    ];

    const defaultHeaders = insuranceOrPlanIsAutoVerified
      ? [serviceHeader, frequencyLimitHeader, ageHeader, autoVerifyHeader]
      : [serviceHeader, frequencyLimitHeader, ageHeader];

    return isViewingDefault
      ? defaultHeaders
      : insuranceOrPlanIsAutoVerified
        ? [...allHeaders, autoVerifyHeader]
        : allHeaders;
  }, [editing, insuranceOrPlanIsAutoVerified, isViewingDefault]);

  const [sort, setSort] = useState<{
    column: string;
    direction: "ASCENDING" | "DESCENDING";
  }>({ column: "service", direction: "ASCENDING" });

  const handleSort = useCallback(
    (id: string) => {
      const column = id;
      const direction =
        id === sort.column ? (sort.direction === "ASCENDING" ? "DESCENDING" : "ASCENDING") : "ASCENDING";

      setSort({ column, direction });

      onUpdateAllLimitations((last) =>
        produce(last, (draft) => {
          // eslint-disable-next-line max-nested-callbacks
          draft.sort((a, b) => {
            const valA =
              (column === "service" ? a.serviceName?.toLowerCase() : cdtCodeToString(a.startCdtCodeRange)) ||
              "";
            const valB =
              (column === "service" ? b.serviceName?.toLowerCase() : cdtCodeToString(b.startCdtCodeRange)) ||
              "";

            if (direction === "ASCENDING") {
              return valA < valB ? -1 : 1;
            }

            return valA > valB ? -1 : 1;
          });
        })
      );
    },
    [onUpdateAllLimitations, sort.column, sort.direction]
  );

  return (
    <TableGrid
      className={cx(
        `rounded
         border
         border-greyLightest
         border-b-0
         min-w-0
         text-xs
         overflow-x-auto`,
        isViewingDefault && "max-w-3xl"
      )}
      columnWidths={headers.map(({ width }) => width)}
    >
      {headers.map((item) =>
        item.sortable ? (
          <HeaderButtonCell key={item.id} onClick={() => handleSort(item.id)} size="short">
            {item.text}
            <ColumnSortIndicator
              className="ml-2"
              direction={sort.column === item.id ? sort.direction : undefined}
            />
          </HeaderButtonCell>
        ) : (
          <HeaderCell key={item.id} size="short">
            {item.text}
          </HeaderCell>
        )
      )}
      {loading ? (
        <Skeleton className="-top-1 h-[400px] w-[1280px]" />
      ) : isViewingDefault ? (
        preferredLimitationItems.map((limitation, index) => (
          <PreferredLimitationRow
            editing={editing}
            index={index}
            insuranceOrPlanIsAutoVerified={insuranceOrPlanIsAutoVerified}
            key={index}
            limitation={limitation}
            onChange={onPreferredLimitationItemChange}
          />
        ))
      ) : (
        limitationItems.map((limitation, index) => {
          const displayLimitation = limitation.display || !("display" in limitation);

          return (
            displayLimitation && (
              <LimitationRow
                editing={editing}
                index={index}
                insuranceOrPlanIsAutoVerified={insuranceOrPlanIsAutoVerified}
                key={index}
                limitation={limitation}
                onChange={onLimitationItemChange}
                onDelete={() => onLimitationItemDelete(index, limitation)}
                onOpenNoteModal={() => onOpenLimitationNotesModal({ index, limitation })}
                validation={validation.$items[index]}
              />
            )
          );
        })
      )}

      {editing && !isViewingDefault && (
        <FullWidthRow>
          <button
            className={`
              flex
              items-center
              px-2
              py-1
              gap-x-2
              font-sans
              text-primaryTheme
              focus:border-primaryTheme
            `}
            onClick={onAddLimitationItem}
            type="button"
          >
            <Icon SvgIcon={AddIcon} size="sm" theme="primary" />
            Limitation
          </button>
        </FullWidthRow>
      )}
    </TableGrid>
  );
};
