/* eslint-disable @typescript-eslint/no-magic-numbers */
import { FC, useMemo } from "react";

import { RankedPatientMessageVO, PatientSummaryVO } from "@libs/api/generated-api";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";

import { Avatar } from "components/UI/Avatar";
import { getPatientDisplayNameFromPatient } from "utils/names";
import { useNow } from "hooks/useNow";

const MAX_PATIENTS_TO_SHOW = 4;

const cxStyles = {
  patientContainer: `
    flex
    items-center
    justify-center
    w-8
    h-8
    text-xxs
    font-sansSemiBold
    bg-greyLightest
    border
    border-white
    rounded-full
    absolute
    right-0
  `,
};

interface Props {
  patientsWithSameContact: PatientSummaryVO[];
  patient: PatientSummaryVO;
  selectedPatient: RankedPatientMessageVO;
}

export const MessagingHeader: FC<Props> = ({ selectedPatient, patientsWithSameContact, patient }) => {
  const now = useNow();
  const selectedPatientHasContact = Boolean(patient.contact.relation !== "SELF");

  const contactName = patient.contact.name.fullDisplayName;

  const { contactPatients, overflowPatients } = useMemo(() => {
    if (patientsWithSameContact.length > 0) {
      const patients = [...patientsWithSameContact].reverse();

      if (selectedPatientHasContact) {
        patients.push(
          patients.splice(
            patients.findIndex((patientSummary) => patientSummary.id === selectedPatient.patientId),
            1
          )[0]
        );
      }

      const hasOverflowPatients = patients.length > MAX_PATIENTS_TO_SHOW;
      const visiblePatientsIndex = patients.length - MAX_PATIENTS_TO_SHOW;

      return hasOverflowPatients
        ? {
            contactPatients: patients.slice(visiblePatientsIndex),
            overflowPatients: patients.slice(0, visiblePatientsIndex),
          }
        : {
            contactPatients: patients,
          };
    }

    return { contactPatients: [] };
  }, [patientsWithSameContact, selectedPatientHasContact, selectedPatient]);

  const patientsWithSameContactStyle = {
    width: `${(overflowPatients ? contactPatients.length + 1 : contactPatients.length) * 1.938}rem`,
  };

  const overflowPatientNames = overflowPatients
    ?.map((p) => getPatientDisplayNameFromPatient(now, p))
    .join("\n");

  return (
    <>
      <p className="font-sansSemiBold text-lg">{contactName}</p>

      {contactPatients.length > 0 ? (
        <div className="flex items-center justify-end gap-x-2 relative">
          <span className="text-xs">Contact Person for</span>

          <div className="h-8" style={patientsWithSameContactStyle}>
            {contactPatients.map((contactPatient, index) => {
              const patientName = contactPatient.name.fullDisplayName;
              const stackStyle = {
                marginRight: `${(overflowPatients ? index + 1 : index) * 1.875}rem`,
                zIndex: contactPatients.length - index,
              };

              return (
                <div className={cxStyles.patientContainer} key={contactPatient.id} style={stackStyle}>
                  <Avatar name={patientName} />
                </div>
              );
            })}

            {overflowPatients ? (
              <FloatingTooltip className="whitespace-pre-wrap" content={overflowPatientNames} theme="SMALL">
                <div className={cxStyles.patientContainer} style={{ zIndex: contactPatients.length + 1 }}>
                  +{overflowPatients.length}
                </div>
              </FloatingTooltip>
            ) : null}
          </div>
        </div>
      ) : null}
    </>
  );
};
