import { useState, useCallback, useMemo, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { toast } from "react-toastify";
import { ClaimLineItemData } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { formatCurrency } from "@libs/utils/currency";
import { isOneOf } from "@libs/utils/isOneOf";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { usePageTitle, formatQueryTitle } from "@libs/hooks/usePageTitle";
import { Button } from "@libs/components/UI/Button";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ConfirmationModal, ConfirmationModalProps } from "@libs/components/UI/ConfirmationModal";
import { ModalContent } from "@libs/components/UI/ModalComponents";
import { Modal } from "@libs/components/UI/Modal";
import { Badge } from "@libs/components/UI/Badge";
import { Banner } from "@libs/components/UI/Banner";
import { deleteEobFile, updateDraftEobPayment, updateEobClaimLineItem } from "api/claim/mutations";
import { getDraftEobPayment } from "api/claim/queries";
import { ClaimModalHeader } from "components/Claim/ModalHeader";
import { useItemModal } from "hooks/useItemModal";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { handleError } from "utils/handleError";
import { paths } from "utils/routing/paths";
import { EobPaymentSummary } from "components/Eob/EobPaymentSummary";
import { ActionModal } from "components/UI/ActionModal";
import { AddClaimsModalContent } from "components/Eob/AddClaimsModalContent";
import { EobPaymentTable } from "components/Eob/EobPaymentTable";
import { ClaimModalFooter } from "components/Claim/ModalFooter";
import { SelectedPaymentType, useSubmitDraftEobPayment } from "components/Eob/useSubmitDraftEobPayment";
import { PaymentSuccessModalContent } from "components/Eob/PaymentSuccessModalContent";
import { PaymentModal } from "components/Eob/PaymentModal";
import { ExternalLink } from "components/UI/ExternalLink";

type LineItemByProcedureId = Record<string, ClaimLineItemData | undefined>;
type GroupedLineItemsByClaimId = Record<string, LineItemByProcedureId | undefined>;
export enum ClaimLineItemActions {
  "UPDATE" = "UPDATE",
  "REMOVE" = "REMOVE",
}
type ErrorsByClaimId = Record<string, Set<number> | undefined> | undefined;

const REFRESH_INTERVAL_MS = 1000;

// eslint-disable-next-line max-statements, complexity
export const EobRoute = () => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const { draftEobPaymentUuid } = usePathParams("eob");
  const { query } = useQueryParams("eob");
  const from = query.from ?? paths.eobs();

  const confirmationModal = useItemModal<ConfirmationModalProps>(null);
  const addClaimsModal = useItemModal<string>(null);
  const uploadEobFileModal = useItemModal<string>(null);
  const successModal = useItemModal<string>(null);
  const paymentModal = useItemModal<string>(null);
  const uploadEobFileMenu = useBoolean(false);

  const [draftEobPaymentQuery] = useApiQueries([
    getDraftEobPayment({ args: { practiceId, draftEobPaymentUuid } }),
  ]);

  const { data: draftEobPayment } = draftEobPaymentQuery;
  const makingFinixPayment = useBoolean(false);

  useEffect(() => {
    if (draftEobPayment && draftEobPayment.state === "PAYMENT_PENDING") {
      makingFinixPayment.on();
    } else {
      makingFinixPayment.off();
    }
  }, [draftEobPayment, draftEobPayment?.state, makingFinixPayment]);

  const [deleteEobFileMutation, updateClaimLineItemsMutation, updateDraftEobPaymentMutation] =
    useApiMutations([deleteEobFile, updateEobClaimLineItem, updateDraftEobPayment]);

  usePageTitle(
    formatQueryTitle(draftEobPaymentQuery, (eobData) => `Process EOB Payment - ${eobData.insuranceCarrier}`)
  );

  const [groupedClaimLineItems, setGroupedClaimLineItems] = useState<GroupedLineItemsByClaimId>({});
  const [errors, setErrors] = useState<ErrorsByClaimId>();
  const [selectedClaimUuids, setSelectedClaimUuids] = useState<Set<string>>(new Set());

  const handleUpdateErrors = useCallback(
    (claimUuid: string, patientProcedureId: number, hasError: boolean) => {
      const errorsCopy = errors ?? {};

      if (!errorsCopy[claimUuid]) {
        errorsCopy[claimUuid] = new Set();
      }

      const patientProcedureHasError = errorsCopy[claimUuid].has(patientProcedureId);

      if (hasError) {
        if (!patientProcedureHasError) {
          errorsCopy[claimUuid].add(patientProcedureId);
        }
      } else if (patientProcedureHasError) {
        errorsCopy[claimUuid].delete(patientProcedureId);
      }

      setErrors(errorsCopy);
    },
    [errors]
  );

  const handleUpdateGroupedClaimLineItems = useCallback(
    (
      claimUuid: string,
      action: ClaimLineItemActions,
      options?: { hasErrors: boolean; lineItemIndex: number; updates: ClaimLineItemData }
    ) => {
      if (action === ClaimLineItemActions.REMOVE) {
        const groupedClaimLineItemsCopy = groupedClaimLineItems;

        delete groupedClaimLineItemsCopy[claimUuid];
        setGroupedClaimLineItems(groupedClaimLineItemsCopy);

        return;
      }

      if (options) {
        handleUpdateErrors(claimUuid, options.updates.patientProcedureId, options.hasErrors);

        if (!options.hasErrors) {
          const updatedLineItem = {
            ...groupedClaimLineItems[claimUuid]?.[options.lineItemIndex],
            ...options.updates,
          };

          const updatedClaimLineItems = {
            ...groupedClaimLineItems[claimUuid],
            [options.updates.patientProcedureId]: updatedLineItem,
          };

          setGroupedClaimLineItems((last) => {
            return { ...last, [claimUuid]: updatedClaimLineItems };
          });
        }
      }
    },
    [groupedClaimLineItems, handleUpdateErrors]
  );

  const handleUpdateClaimLineItems = useCallback(
    async (claimUuid: string, isUpdatingDraft: boolean) => {
      const updatedLineItemsMap = groupedClaimLineItems[claimUuid];
      const updatedLineItems = Object.values(updatedLineItemsMap || {}).filter(
        Boolean
      ) as ClaimLineItemData[];

      // Filter out any line items that have errors if we are in edit mode so that
      // the user has time to fix them without getting error notifications. On
      // actual save (exit modal or click Next), we send all line items
      const filteredLineItems = isUpdatingDraft
        ? updatedLineItems.filter((lineItem) => !errors?.[claimUuid]?.has(lineItem.patientProcedureId))
        : updatedLineItems;

      if (updatedLineItemsMap && filteredLineItems.length) {
        try {
          // Clear it out so we can track new changes while it is saving.
          handleUpdateGroupedClaimLineItems(claimUuid, ClaimLineItemActions.REMOVE);
          await updateClaimLineItemsMutation.mutateAsync({
            practiceId,
            claimUuid,
            data: {
              lineItems: filteredLineItems,
            },
          });
        } catch (err) {
          handleError(err);

          setGroupedClaimLineItems((last) => {
            // Restore old line items that failed.
            const lineItems = updatedLineItemsMap;

            // Apply any recent changes on top of the failed to save values.
            const changedLineItems = last[claimUuid];

            if (changedLineItems != null) {
              for (const [key, value] of Object.entries(changedLineItems)) {
                const existingEntry = lineItems[key];

                lineItems[key] = existingEntry ? { ...existingEntry, ...value } : value;
              }
            }

            return { ...last, [claimUuid]: lineItems };
          });
        }
      }
    },
    [
      groupedClaimLineItems,
      errors,
      handleUpdateGroupedClaimLineItems,
      practiceId,
      updateClaimLineItemsMutation,
    ]
  );

  const handleUpdateAllClaimLineItems = useCallback(() => {
    if (draftEobPayment && Object.keys(groupedClaimLineItems).length) {
      draftEobPayment.claimPaymentSummaries.forEach((claim) => handleUpdateClaimLineItems(claim.uuid, true));
    }
  }, [draftEobPayment, groupedClaimLineItems, handleUpdateClaimLineItems]);

  const debounceSave = useDebouncedCallback(() => handleUpdateAllClaimLineItems(), REFRESH_INTERVAL_MS);

  useEffect(() => {
    // Listen to all key up and click events to debounce the save.
    document.addEventListener("keyup", debounceSave, true);
    document.addEventListener("click", debounceSave, true);

    return () => {
      document.removeEventListener("click", debounceSave, true);
      document.removeEventListener("keyup", debounceSave, true);
    };
  }, [debounceSave]);

  const handleSaveEob = useCallback(() => {
    handleUpdateAllClaimLineItems();
    confirmationModal.close();
    navigate(from, { replace: true });
  }, [handleUpdateAllClaimLineItems, confirmationModal, navigate, from]);

  const handleGoBack = useCallback(() => {
    if (makingFinixPayment.isOff) {
      confirmationModal.open({
        onCancel: confirmationModal.close,
        onConfirm: handleSaveEob,
        primaryText: "Are you sure you want to leave this as a draft?",
        secondaryText:
          "Your data is saved and you can continue filling in the details from EOBs in Progress tab later.",
      });
    } else {
      navigate(from);
    }
  }, [confirmationModal, from, handleSaveEob, navigate, makingFinixPayment.isOff]);

  const handleClearSelectedClaimUuids = useCallback(() => {
    setSelectedClaimUuids(new Set());
  }, []);

  const handleConfirmCancelAddClaims = useCallback(() => {
    confirmationModal.close();
    addClaimsModal.close();
    handleClearSelectedClaimUuids();
  }, [addClaimsModal, confirmationModal, handleClearSelectedClaimUuids]);

  const handleCancelAddClaims = useCallback(() => {
    if (selectedClaimUuids.size) {
      confirmationModal.open({
        onCancel: confirmationModal.close,
        onConfirm: handleConfirmCancelAddClaims,
        primaryText: "The selected claims won't be added to the claim payment process",
        secondaryText:
          "Please check carefully whether the selected claims are not a part of the EOB you're processing.",
      });
    } else {
      addClaimsModal.close();
    }
  }, [addClaimsModal, confirmationModal, handleConfirmCancelAddClaims, selectedClaimUuids.size]);

  const handleUpdateDraftEobPayment = useCallback(
    async (claimUuids: string[]) => {
      if (draftEobPaymentUuid) {
        try {
          await updateDraftEobPaymentMutation.mutateAsync({
            data: { claimUuids },
            draftEobPaymentUuid,
            practiceId,
          });
          handleClearSelectedClaimUuids();
        } catch (err) {
          handleError(err);
        }
      }
    },
    [draftEobPaymentUuid, handleClearSelectedClaimUuids, practiceId, updateDraftEobPaymentMutation]
  );

  const handleAddClaims = useCallback(() => {
    const eobClaimUuids = (draftEobPayment?.claimPaymentSummaries ?? []).map((claim) => claim.uuid);
    const claimUuids = [...eobClaimUuids, ...selectedClaimUuids];

    handleUpdateDraftEobPayment(claimUuids);
    addClaimsModal.close();
  }, [
    addClaimsModal,
    draftEobPayment?.claimPaymentSummaries,
    handleUpdateDraftEobPayment,
    selectedClaimUuids,
  ]);

  const handleDeleteEobFile = useCallback(
    (eobFileUuid: string) => {
      if (draftEobPayment) {
        deleteEobFileMutation.mutate(
          {
            draftEobPaymentUuid,
            eobUuid: eobFileUuid,
            practiceId,
          },
          {
            onError: handleError,
          }
        );
        draftEobPayment.eobFiles = draftEobPayment.eobFiles.filter((file) => file.uuid !== eobFileUuid);

        if (!draftEobPayment.eobFiles.length) {
          uploadEobFileMenu.off();
        }
      }
    },
    [deleteEobFileMutation, draftEobPayment, draftEobPaymentUuid, practiceId, uploadEobFileMenu]
  );

  const handleToggleClaim = useCallback(
    (claimUuid: string) => {
      const selectedClaimUuidsCopy = selectedClaimUuids;

      if (selectedClaimUuids.has(claimUuid)) {
        selectedClaimUuidsCopy.delete(claimUuid);
      } else {
        selectedClaimUuidsCopy.add(claimUuid);
      }

      setSelectedClaimUuids(new Set(selectedClaimUuidsCopy));
    },
    [selectedClaimUuids]
  );

  const handleDeleteClaimFromDraftEobPayment = useCallback(
    (claimUuidToDelete: string) => {
      const eobClaimUuids = (draftEobPayment?.claimPaymentSummaries ?? [])
        .map((claim) => claim.uuid)
        .filter((claimUuid) => claimUuid !== claimUuidToDelete);

      handleUpdateDraftEobPayment(eobClaimUuids);
    },
    [draftEobPayment?.claimPaymentSummaries, handleUpdateDraftEobPayment]
  );

  const numErrors = Object.values(errors ?? {}).reduce((acc, curr) => acc + (curr ?? new Set()).size, 0);

  const disabledNextTooltip = useMemo(() => {
    return makingFinixPayment.isOn
      ? "Payment processing"
      : draftEobPayment?.claimPaymentSummaries.length
        ? numErrors
          ? "Fix all errors before continuing"
          : draftEobPayment.eobFiles.length
            ? ""
            : "Must have at least one EOB attached to processs payment"
        : "Must have at least one claim to process EOB payment";
  }, [
    draftEobPayment?.claimPaymentSummaries.length,
    draftEobPayment?.eobFiles.length,
    makingFinixPayment.isOn,
    numErrors,
  ]);

  const canSkipPayment = (draftEobPayment?.unpaidInsuranceAmount ?? 0) === 0;
  const isNegativePayment = (draftEobPayment?.unpaidInsuranceAmount ?? 0) < 0;

  const handleAfterPayment = useCallback(
    (selectedPaymentType: SelectedPaymentType) => {
      if (isOneOf(selectedPaymentType, ["EFT", "CHECK"])) {
        successModal.open(draftEobPaymentUuid);
      } else {
        paymentModal.close();
        navigate(from, { replace: true });
        toast.info("Payment processing - we will notify you when payment is complete");
      }
    },
    [draftEobPaymentUuid, from, navigate, paymentModal, successModal]
  );

  const {
    cardBrand,
    disableFinalizeClaim,
    failedLoadingFinixScript,
    formValues,
    handleDateFieldUpdate,
    handleFieldUpdate,
    handleFinalizePayment,
    handleSelectPaymentType,
    isMakingPayment,
    onPaymentComplete,
    selectedPaymentType,
    ...finixProps
  } = useSubmitDraftEobPayment(canSkipPayment, draftEobPaymentUuid, handleAfterPayment);

  const handleSkipPayment = useCallback(async () => {
    await handleFinalizePayment();
    confirmationModal.close();
    onPaymentComplete(false);
  }, [confirmationModal, handleFinalizePayment, onPaymentComplete]);

  const handleConfirmNegativePayment = useCallback(() => {
    if (draftEobPayment) {
      paymentModal.open(draftEobPayment.uuid);
      confirmationModal.close();
      onPaymentComplete(false);
    }
  }, [confirmationModal, draftEobPayment, onPaymentComplete, paymentModal]);

  const handleNext = useCallback(() => {
    handleUpdateAllClaimLineItems();

    if (draftEobPayment) {
      if (canSkipPayment) {
        confirmationModal.open({
          disabled: isMakingPayment,
          isConfirming: isMakingPayment,
          onCancel: confirmationModal.close,
          onConfirm: handleSkipPayment,
          primaryText: "Are you sure you want to process a $0.00 EOB payment?",
          secondaryText:
            "This will result in any claims with a $0.00 insurance payment as denied and the patient owing the patient amount entered.",
        });
      } else if (isNegativePayment) {
        confirmationModal.open({
          disabled: isMakingPayment,
          isConfirming: isMakingPayment,
          onCancel: confirmationModal.close,
          onConfirm: handleConfirmNegativePayment,
          primaryText: `Are you sure you want to process a ${formatCurrency(
            draftEobPayment.unpaidInsuranceAmount
          )} EOB payment?`,
          secondaryText:
            "A negative amount generally means that you will be issuing a refund check to the insurance company.",
        });
      } else {
        paymentModal.open(draftEobPaymentUuid);
      }
    }
  }, [
    handleUpdateAllClaimLineItems,
    draftEobPayment,
    canSkipPayment,
    isNegativePayment,
    confirmationModal,
    isMakingPayment,
    handleSkipPayment,
    handleConfirmNegativePayment,
    paymentModal,
    draftEobPaymentUuid,
  ]);

  return (
    <QueryResult queries={[draftEobPaymentQuery]}>
      {draftEobPayment && (
        <>
          <ClaimModalHeader
            onClose={handleGoBack}
            data-testid="eob-detail-page-claim-header"
            title="Process EOB"
            titleDetails={["Enter amounts exactly as stated in the EOB received."]}
          >
            {draftEobPayment.state === "PAYMENT_PENDING" && (
              <Badge color="white" label="Payment Processing" />
            )}
          </ClaimModalHeader>
          {draftEobPayment.paymentErrorMessage && draftEobPayment.state !== "PAYMENT_PENDING" && (
            <Banner contentAlignment="center" includeIcon theme="error">
              <div className="flex text-xs">
                <span className="font-sansSemiBold">Payment Failed:&nbsp;</span>
                {draftEobPayment.paymentErrorMessage} Some virtual cards require the entire payment to be
                processed at once, using&nbsp;
                <ExternalLink name="bulkEobProcessing">Bulk EOB</ExternalLink>
                &nbsp;processing.
              </div>
            </Banner>
          )}
          <EobPaymentSummary
            addClaimsModal={addClaimsModal}
            disableActions={makingFinixPayment.isOn}
            draftEobPayment={draftEobPayment}
            onAddClaims={handleAddClaims}
            onCancelAddClaims={handleCancelAddClaims}
            onDeleteEobFile={handleDeleteEobFile}
            onToggleClaim={handleToggleClaim}
            selectedClaimUuids={selectedClaimUuids}
            uploadEobFileMenu={uploadEobFileMenu}
            uploadEobFileModal={uploadEobFileModal}
          />
          <EobPaymentTable
            claims={draftEobPayment.claimPaymentSummaries}
            disableActions={makingFinixPayment.isOn}
            onDeleteClaim={handleDeleteClaimFromDraftEobPayment}
            onUpdateGroupedClaimLineItems={handleUpdateGroupedClaimLineItems}
          />
          <div className="justify-end mt-3">
            <ClaimModalFooter>
              <FloatingTooltip content={numErrors ? "Fix all errors before saving draft" : ""}>
                <div>
                  <Button
                    className="w-[120px]"
                    disabled={Boolean(numErrors)}
                    onClick={handleGoBack}
                    theme="secondary"
                  >
                    Finish Later
                  </Button>
                </div>
              </FloatingTooltip>
              <FloatingTooltip content={disabledNextTooltip}>
                <div>
                  <Button
                    className="w-[120px]"
                    disabled={Boolean(disabledNextTooltip)}
                    onClick={handleNext}
                    theme="primary"
                  >
                    Next
                  </Button>
                </div>
              </FloatingTooltip>
            </ClaimModalFooter>
          </div>
        </>
      )}
      {confirmationModal.isOpen && (
        <ConfirmationModal
          onCancel={confirmationModal.item.onCancel}
          onConfirm={confirmationModal.item.onConfirm}
          primaryText={confirmationModal.item.primaryText}
          secondaryText={confirmationModal.item.secondaryText}
        >
          {confirmationModal.item.children}
        </ConfirmationModal>
      )}
      {addClaimsModal.isOpen && draftEobPayment && (
        <ActionModal
          handleCancel={handleCancelAddClaims}
          handleClose={addClaimsModal.close}
          handleSave={handleAddClaims}
          primaryButtonDisabled={!selectedClaimUuids.size}
          primaryButtonDisabledTooltip={selectedClaimUuids.size ? "" : "Select claim"}
          secondaryTitle={draftEobPayment.insuranceCarrier}
          title="Add Claims"
        >
          <AddClaimsModalContent
            insuranceCarrierId={draftEobPayment.insuranceCarrierId}
            onToggleClaim={handleToggleClaim}
            selectedClaimUuids={selectedClaimUuids}
          />
        </ActionModal>
      )}
      {paymentModal.isOpen && draftEobPayment && (
        <PaymentModal
          {...finixProps}
          allowCCPayment={!isNegativePayment}
          cardBrand={cardBrand}
          disableFinalizeClaim={disableFinalizeClaim}
          draftEobPayment={draftEobPayment}
          formValues={formValues}
          hasFinixLoadingError={Boolean(failedLoadingFinixScript)}
          isMakingPayment={isMakingPayment}
          onClose={paymentModal.close}
          onDateFieldUpdate={handleDateFieldUpdate}
          onFieldUpdate={handleFieldUpdate}
          onFinalizePayment={handleFinalizePayment}
          onSelectPaymentType={handleSelectPaymentType}
          selectedPaymentType={selectedPaymentType}
        />
      )}
      {successModal.isOpen && (
        <Modal size="xs">
          <ModalContent padding="sm">
            <PaymentSuccessModalContent numOfClaims={draftEobPayment?.claimPaymentSummaries.length || 0} />
          </ModalContent>
        </Modal>
      )}
    </QueryResult>
  );
};
