import { FC } from "react";
import { useLocation } from "react-router-dom";

import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as OpenProfileIcon } from "@libs/assets/icons/open-profile.svg";
import { getFullUrl } from "@libs/utils/location";

import { Banner } from "@libs/components/UI/Banner";

import { paths } from "utils/routing/paths";

interface Props {
  patientId: number;
}

export const ImportedLedgerBanner: FC<Props> = ({ patientId }) => {
  const location = useLocation();

  return (
    <Banner className="rounded text-xs" theme="info" data-testid="imported-ledger-banner">
      <div className="flex items-center gap-x-3 w-full">
        <span className="flex-1">
          This patient has an imported ledger from your previous practice management system
        </span>
        <ButtonInternalLink
          className="flex items-center gap-x-3"
          to={paths.importedLedger({ patientId }, { from: getFullUrl(location) })}
          theme="link"
        >
          <span>View</span>
          <Icon SvgIcon={OpenProfileIcon} size="sm" />
        </ButtonInternalLink>
      </div>
    </Banner>
  );
};
