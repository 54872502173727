import React from "react";
import { ImagingSensorCalibration } from "components/ImageCapturing/Calibration/ImagingSensorCalibration";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { FullScreenPortal } from "components/PatientProfile/Imaging/shared/FullScreenPortal";

export const ImagingSensorsRoute: React.FC = () => {
  const { deviceId } = usePathParams("sensors");
  const { query } = useQueryParams("sensors");

  return (
    <FullScreenPortal isDark>
      <ImagingSensorCalibration deviceId={deviceId} from={query.from ?? paths.home()} />
    </FullScreenPortal>
  );
};
