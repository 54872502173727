import { FormEvent, useMemo, useState } from "react";
import {
  AppointmentCategoryRequest,
  AppointmentCategoryVO,
  DentalProcedureVO,
  ProviderVO,
  AppointmentCategorySelfBookingRequest,
} from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Icon } from "@libs/components/UI/Icon";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useAccount } from "@libs/contexts/AccountContext";
import { FormFieldColorPicker } from "@libs/components/UI/FormFieldColorPicker";
import { TREATMENT_PLAN_CATEGORY_COLOR } from "@libs/domains/scheduling/colors";
import { Form } from "@libs/components/UI/Form";
import { Banner } from "@libs/components/UI/Banner";
import { Switch } from "@libs/components/UI/Switch";
import { FormSectionTitle } from "components/UI/FormSectionTitle";
import { FormSection } from "components/UI/FormSection";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { ProcedureSelector } from "components/ProcedureSelector/ProcedureSelector";
import { calculateDuration } from "utils/calculateProceduresDuration";
import { FormFieldSelectProviders } from "components/UI/FormFieldSelectProviders";
import { Divider } from "components/UI/Divider";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent, FlyoverFooter } from "components/UI/FlyoverComponents";
import { ToggleButtonList } from "components/UI/ToggleButtonList";
import { getSchedulingConfigQuery } from "api/scheduling/queries";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";
import { convertToAppointmentCategoryRequest } from "components/Settings/Scheduling/Sections/AppointmentCategories/utils";

const TWO_HUNDRED_AND_FOURTY_MINUTES = 240;
const FIVE_MINUTES = 5;

const EMPTY_STATE: AppointmentCategoryRequest = {
  name: "",
  procedures: [],
  duration: 0,
  color: TREATMENT_PLAN_CATEGORY_COLOR,
};

const cxStyles = {
  pillContainer: "flex flex-row items-center gap-x-2",
};

const MAX_NAME_LENGTH = 100;

const patientTypeOptions: OptionInputOption<AppointmentCategorySelfBookingRequest["patientType"]>[] = [
  { value: "ALL" as const, label: "All Patients" },
  { value: "EXISTING" as const, label: "Existing Patients" },
  { value: "NEW" as const, label: "New Patients" },
];

const bookingTypeOptions: OptionInputOption<AppointmentCategorySelfBookingRequest["bookingType"]>[] = [
  {
    value: "REQUEST" as const,
    label: (
      <div className={cxStyles.pillContainer}>
        Request
        <Icon
          SvgIcon={InfoIcon}
          size="sm"
          tooltip={{
            content: "Request type bookings will require your practice to manually approve every appointment",
          }}
        />
      </div>
    ),
  },
  {
    value: "SCHEDULE" as const,
    label: (
      <div className={cxStyles.pillContainer}>
        Scheduled
        <Icon
          SvgIcon={InfoIcon}
          size="sm"
          tooltip={{
            content:
              "Schedule type bookings will be automatically accepted and placed on the practice schedule",
          }}
        />
      </div>
    ),
  },
];

const defaultSelfbookingConfig: AppointmentCategorySelfBookingRequest = {
  bookingType: "REQUEST",
  patientType: "ALL",
  leadTimeToBookInDays: 1,
};

const FORM_NAME = "categoryForm";

export const AppointmentCategoryFlyover: React.FC<{
  existingCategory?: AppointmentCategoryVO;
  dentalProcedures: DentalProcedureVO[];
  providers: ProviderVO[];
  isSaving: boolean;
  onClose: () => void;
  onSave: (category: AppointmentCategoryRequest) => void;
}> = ({ existingCategory, dentalProcedures, providers, isSaving, onClose, onSave }) => {
  const { practiceId } = useAccount();
  const [{ data: schedulingConfig }] = useApiQueries([getSchedulingConfigQuery({ args: { practiceId } })]);
  const showNotification = Boolean(schedulingConfig?.selfBookingConfig.enabled) === false;
  const [state, setState] = useState<AppointmentCategoryRequest>(() => {
    if (!existingCategory) {
      return { ...EMPTY_STATE };
    }

    return convertToAppointmentCategoryRequest(existingCategory);
  });

  const schema = useMemo(
    () => ({
      name: [
        {
          $v: required,
          $error: "Category Name is required",
        },
      ],
      externalName: [
        {
          $v: required,
          $error: "External Name is required when Online Booking is enabled",
          $ignore: state.isSelfBookable !== true,
        },
      ],
    }),
    [state.isSelfBookable]
  );

  const validation = useValidation(state, schema);

  const handleSave = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validation.validate().$isValid) {
      return;
    }

    onSave(state);
  };

  const durationOptions = useMemo(() => {
    const durations = [];

    for (let dur = FIVE_MINUTES; dur <= TWO_HUNDRED_AND_FOURTY_MINUTES; dur += FIVE_MINUTES) {
      durations.push({ label: `${dur} Minutes`, value: dur });
    }

    return durations;
  }, []);

  return (
    <Flyover title={`${existingCategory ? "Edit" : "Add"} Category`} onClose={onClose} size="md">
      <FlyoverContent paddingClassName="">
        <Form className="min-h-0 flex-1 flex flex-col py-3" onSubmit={handleSave} id={FORM_NAME}>
          <FormSection>
            <FormSectionTitle required>Category Name</FormSectionTitle>
            <FormFieldInput
              value={state.name}
              onChange={(e) => setState((prev) => ({ ...prev, name: e.target.value }))}
              layout="labelOut"
              error={validation.result.name.$error}
              maxLength={MAX_NAME_LENGTH}
            />
          </FormSection>
          <FormSection>
            <FormSectionTitle>External Name</FormSectionTitle>
            <FormFieldInput
              value={state.externalName}
              onChange={(e) => setState((prev) => ({ ...prev, externalName: e.target.value }))}
              layout="labelOut"
              maxLength={MAX_NAME_LENGTH}
              error={validation.result.externalName.$error}
            />
          </FormSection>
          <FormSection>
            <FormSectionTitle>Color</FormSectionTitle>
            <FormFieldColorPicker
              value={state.color}
              required={true}
              onChange={(val) => setState((prev) => ({ ...prev, color: val }))}
            />
          </FormSection>
          <FormSection>
            <FormSectionTitle>Procedures</FormSectionTitle>
            <ProcedureSelector
              placeholder="Search / select standard procedures for this category"
              values={state.procedures}
              procedures={dentalProcedures}
              onChange={(values) => {
                setState((prev) => ({ ...prev, procedures: values, duration: calculateDuration(values) }));
              }}
            />
          </FormSection>
          <FormSection>
            <FormSectionTitle>Duration</FormSectionTitle>
            <FormFieldSelect
              value={state.duration}
              options={durationOptions}
              onItemSelected={(val) => setState((prev) => ({ ...prev, duration: val }))}
            />
          </FormSection>
          <FormSection>
            <FormSectionTitle subTitle="Select which providers can perform this category. If left blank, all providers are available.">
              Providers
            </FormSectionTitle>
            <FormFieldSelectProviders
              onChange={(providerIds) => setState((prev) => ({ ...prev, providerIds }))}
              selectedProviderIds={state.providerIds}
              providers={providers}
              savedProviders={existingCategory?.providers}
            />
          </FormSection>

          <>
            <div className="px-8 py-3">
              <Divider />
            </div>
            <FormSection>
              <FormSectionTitle subTitle="Switch this on to allow your patients to book this appointment category online.">
                Online Booking
              </FormSectionTitle>

              <div className="flex flex-row items-center p-1 mt-3 gap-3">
                <Switch
                  checked={state.isSelfBookable}
                  onChange={(e) =>
                    setState((prev) => ({
                      ...prev,
                      isSelfBookable: e.target.checked,
                      selfBookingConfig: e.target.checked ? defaultSelfbookingConfig : undefined,
                    }))
                  }
                />
                <div className="text-sm">{state.isSelfBookable ? "On" : "Off"}</div>
              </div>
              {state.isSelfBookable && showNotification && (
                <Banner theme="info" className="text-xs/4 mt-6">
                  You will need to turn on online bookings in practice settings before patients can book
                  online.
                </Banner>
              )}
            </FormSection>
            {state.isSelfBookable && (
              <>
                <FormSection>
                  <FormSectionTitle>Available To</FormSectionTitle>
                  <ToggleButtonList
                    type="radio"
                    shape="mediumPill"
                    selectedValue={state.selfBookingConfig?.patientType}
                    options={patientTypeOptions}
                    onChange={(e, option) =>
                      setState((prev) => ({
                        ...prev,
                        selfBookingConfig: {
                          ...(prev.selfBookingConfig || defaultSelfbookingConfig),
                          patientType: option.value,
                        },
                      }))
                    }
                  />
                </FormSection>
                <FormSection>
                  <FormSectionTitle>Booking Type</FormSectionTitle>
                  <ToggleButtonList
                    type="radio"
                    shape="mediumPill"
                    selectedValue={state.selfBookingConfig?.bookingType}
                    options={bookingTypeOptions}
                    onChange={(e, option) =>
                      setState((prev) => ({
                        ...prev,
                        selfBookingConfig: {
                          ...(prev.selfBookingConfig || defaultSelfbookingConfig),
                          bookingType: option.value,
                        },
                      }))
                    }
                  />
                </FormSection>
                <FormSection>
                  <FormSectionTitle subTitle="Specify the minimum amount of time required before this category can be booked.">
                    Lead Time
                  </FormSectionTitle>
                  <div className="flex flex-row gap-2 items-center">
                    <FormFieldNumberInput
                      id="leadtime"
                      className="w-16"
                      value={state.selfBookingConfig?.leadTimeToBookInDays}
                      onChange={(e) =>
                        setState((prev) => ({
                          ...prev,
                          selfBookingConfig: {
                            ...(prev.selfBookingConfig || defaultSelfbookingConfig),
                            leadTimeToBookInDays: Number(e.target.value || 1),
                          },
                        }))
                      }
                    />
                    <span className="text-sm">Days</span>
                  </div>
                </FormSection>
              </>
            )}
          </>
        </Form>
      </FlyoverContent>
      <FlyoverFooter actions>
        <Button className="min-w-button" theme="secondary" onClick={onClose}>
          Cancel
        </Button>
        <AsyncButton className="min-w-button" isLoading={isSaving} type="submit" form={FORM_NAME}>
          Save
        </AsyncButton>
      </FlyoverFooter>
    </Flyover>
  );
};
