import React from "react";
import { ReactComponent as QuadView } from "assets/icons/imaging/imaging-quadrant-view.svg";
import { ReactComponent as SingleView } from "assets/icons/imaging/imaging-single-view.svg";
import { ReactComponent as DoubleView } from "assets/icons/imaging/imaging-double-view.svg";
import { ReactComponent as GridMount } from "assets/icons/imaging/imaging-grid-view.svg";
import { ReactComponent as MountView } from "assets/icons/imaging/imaging-mount-view.svg";
import { MountIconButton } from "components/PatientProfile/Imaging/MountRoute/MountIconButton";
import { MountDetailsQuery, MountFormat } from "utils/routing/patient";

type RightHeaderProps = {
  mountType: string;
  mountFormat: MountFormat;
  archivedCount: number;
  onUpdateParams: (newParams: Partial<MountDetailsQuery>) => void;
  isShowingMetadata?: boolean;
};

export const HeaderRightViewSelections: React.FC<RightHeaderProps> = ({
  mountType,
  mountFormat,
  onUpdateParams,
}) => {
  return (
    <div className="flex gap-2">
      <MountIconButton
        title="View All"
        onClick={() => onUpdateParams({ format: undefined })}
        SvgIcon={mountType === "Grid" ? GridMount : MountView}
        size="xl"
        selected={mountFormat === "sandbox"}
      />
      <MountIconButton
        title="View Single"
        onClick={() => onUpdateParams({ format: "single" })}
        SvgIcon={SingleView}
        selected={mountFormat === "single"}
      />
      <MountIconButton
        title="View Double"
        onClick={() => onUpdateParams({ format: "double" })}
        SvgIcon={DoubleView}
        selected={mountFormat === "double"}
      />
      <MountIconButton
        title="View Double"
        onClick={() => onUpdateParams({ format: "quad" })}
        SvgIcon={QuadView}
        selected={mountFormat === "quad"}
      />
    </div>
  );
};
