import { FC } from "react";
import { Button } from "@libs/components/UI/Button";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent } from "@libs/components/UI/ModalComponents";

interface Props {
  onRequestClose: Func;
  kioskCode: string;
}

export const KioskModal: FC<Props> = ({ kioskCode, onRequestClose }) => {
  return (
    <Modal onClose={onRequestClose} size="2xs">
      <ModalContent className="flex flex-col justify-center items-center gap-6 px-8 py-6">
        <div className="font-sansSemiBold">Sent to Kiosk</div>
        <div className="text-sm">
          Use PIN <strong>{kioskCode}</strong> to access
        </div>
        <Button className="min-w-button" onClick={onRequestClose} theme="primary">
          Close
        </Button>
      </ModalContent>
    </Modal>
  );
};
