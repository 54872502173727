import { FC, FormEvent, useMemo, useCallback } from "react";

import { MessageVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { RadioList } from "@libs/components/UI/RadioList";
import { useAccount } from "@libs/contexts/AccountContext";
import { Modal } from "@libs/components/UI/Modal";
import { ModalForm, ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";
import { CancelScheduledOptionLabel } from "components/PatientProfile/Communication/CancelScheduledOptionLabel";

import { cancelScheduledMessage, cancelMessageCampaign } from "api/communications/mutations";

import { handleError } from "utils/handleError";

interface Props {
  message: MessageVO;
  onRequestClose: Func;
  onCancelSuccess: Func;
}

export const CancelScheduledMessageModal: FC<Props> = ({ message, onRequestClose, onCancelSuccess }) => {
  const { practiceId } = useAccount();
  const cancelAll = useBoolean(false);

  const title = message.sender.messageCampaignUuid
    ? "Cancel Pending Bulk Messages"
    : "Cancel Scheduled Message";

  const options = useMemo(
    () => [
      {
        label: message.sender.messageCampaignUuid ? (
          "For this patient only"
        ) : (
          <CancelScheduledOptionLabel
            primaryText="This Message Only"
            secondaryText="This will remove only this message, the patient may receive further automated messages."
          />
        ),
        value: false,
      },
      {
        label: message.sender.messageCampaignUuid ? (
          "For all patients"
        ) : (
          <CancelScheduledOptionLabel
            primaryText="All Scheduled Messages"
            secondaryText="This will cancel any further messages that are currently scheduled to send."
          />
        ),
        value: true,
      },
    ],
    [message.sender.messageCampaignUuid]
  );

  const [cancelScheduledMessageMutation, cancelMessageCampaignMutation] = useApiMutations([
    cancelScheduledMessage,
    cancelMessageCampaign,
  ]);

  const cancelScheduledMessageMutate = cancelScheduledMessageMutation.mutateAsync;
  const cancelMessageCampaignMutate = cancelMessageCampaignMutation.mutateAsync;
  const isLoading = cancelScheduledMessageMutation.isLoading || cancelMessageCampaignMutation.isLoading;

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      try {
        await (message.sender.messageCampaignUuid && cancelAll.isOn
          ? cancelMessageCampaignMutate({
              practiceId,
              messageCampaignUuid: message.sender.messageCampaignUuid,
            })
          : cancelScheduledMessageMutate({
              practiceId,
              messageId: message.id,
              query: { cancelAllInJourney: cancelAll.isOn },
            }));
        onCancelSuccess();
        onRequestClose();
      } catch (error) {
        handleError(error);
      }
    },
    [
      cancelMessageCampaignMutate,
      cancelScheduledMessageMutate,
      practiceId,
      message,
      cancelAll.isOn,
      onCancelSuccess,
      onRequestClose,
    ]
  );

  return (
    <Modal title={title} onClose={onRequestClose} size="2xs">
      <ModalForm onSubmit={handleSubmit}>
        <ModalContent padding="lg">
          <RadioList
            optionClassName="gap-x-1.5"
            options={options}
            selectedValue={cancelAll.isOn}
            onChange={() => cancelAll.toggle()}
            layout="vert"
          />
        </ModalContent>
        <ModalFooter>
          <Button className="min-w-button" onClick={onRequestClose} theme="secondary">
            Cancel
          </Button>
          <AsyncButton className="min-w-button" isLoading={isLoading} theme="primary" type="submit">
            Confirm
          </AsyncButton>
        </ModalFooter>
      </ModalForm>
    </Modal>
  );
};
