import React from "react";
import {
  CollectionAdjustmentResponse,
  CollectionAdjustmentVO,
  CollectionAdjustmentTotalVO,
} from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getAbsoluteUrl } from "@libs/utils/location";
import { formatCurrency } from "@libs/utils/currency";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";
import { handleError } from "utils/handleError";
import { useDownloadQueryPages } from "components/Dashboard/hooks/useDownloadInfiniteQuery";
import { getTotals } from "components/Dashboard/Tables/utils";
import { paths } from "utils/routing/paths";
import { COLLECTION_ADJUSTMENT_HEADERS } from "components/Dashboard/Adjustments/constants";
import { TimeSegment } from "utils/routing/dashboard";
import { useCurrentUrl, useOrigin } from "contexts/OriginContext";

const formatAppointmentDataAsCsvUrl = (params: {
  rows: CollectionAdjustmentVO[];
  totals: CollectionAdjustmentTotalVO;
  origin: string;
}) => {
  const { rows, totals, origin } = params;
  const columnTitles = formatCsvRow([
    ...COLLECTION_ADJUSTMENT_HEADERS.map(({ label }) => label).filter(Boolean),
    "Patient URL",
  ]);

  const rowsContent = rows.map((row) =>
    formatCsvRow([
      row.patient.fullDisplayName,
      row.adjustmentDate,
      row.adjustmentName,
      row.adjustmentNote ?? "",
      formatCurrency(row.collectionAdjustmentAmount),
      getAbsoluteUrl(origin, paths.patient({ patientId: row.patient.id })),
    ])
  );
  const totalsContent = formatCsvRow([
    "Totals",
    "",
    "",
    "",
    `${formatCurrency(totals.totalCollectionAdjustmentAmount)}`,
    "",
  ]);

  return [columnTitles, ...rowsContent, totalsContent];
};

export const useDownloadCollectionAdjustmentsCsv = ({
  selectedTimeSegment,
  collectionAdjustmentInfiniteQuery,
}: {
  selectedTimeSegment: TimeSegment;
  collectionAdjustmentInfiniteQuery: UseInfiniteApiQueryResult<CollectionAdjustmentResponse>;
}) => {
  const downloading = useBoolean(false);
  const origin = useOrigin();
  const currentUrl = useCurrentUrl();
  const { startDownload: downloadAppointmentStats } = useDownloadQueryPages(
    collectionAdjustmentInfiniteQuery
  );

  return {
    isDownloading: downloading.isOn,
    downloadCSV: React.useCallback(async () => {
      downloading.on();

      const docMetadata = getArchyCsvMetadata(currentUrl, selectedTimeSegment);

      try {
        const result = await downloadAppointmentStats();
        const rows = result.pages.flatMap((item) => item.data.entries);
        const totals = getTotals(result);

        if (totals) {
          const csvRows = [
            ...docMetadata.headers,
            ...formatAppointmentDataAsCsvUrl({
              rows,
              totals,
              origin,
            }),
          ];

          saveCsv(csvRows, `CollectionAdjustments_${docMetadata.fileNameTag}`);
        }
      } catch (e) {
        handleError(e);
      }

      downloading.off();
    }, [downloading, selectedTimeSegment, downloadAppointmentStats, origin, currentUrl]),
  };
};
