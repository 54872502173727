import { MigratedTransactionVO, NameVO } from "@libs/api/generated-api";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { sentenceCaseConstant } from "@libs/utils/casing";

import { toFilterComponentsProps } from "@libs/components/UI/queryFilterPillsUtils";
import { ImportedLedgerQuery } from "utils/routing/patient";

export const TYPE_OPTIONS: OptionInputOption<MigratedTransactionVO["type"]>[] = [
  { label: "Adjustment", value: "ADJUSTMENT" },
  { label: "Payment", value: "PAYMENT" },
  { label: "Procedure", value: "PROCEDURE" },
  { label: "Other", value: "OTHER" },
];

export type ImportedLedgerFilterQueryParams = Pick<
  ImportedLedgerQuery,
  "types" | "employeeIds" | "searchString"
>;

export const getQueryFiltersProps = (
  params: ImportedLedgerFilterQueryParams,
  employeeNamesMap: Map<number, NameVO>
) => {
  return toFilterComponentsProps(params, [
    {
      type: "list",
      prop: "types",
      format: (val) => sentenceCaseConstant(val),
    },
    {
      type: "list",
      prop: "employeeIds",
      format: (val) => {
        const employeeName = employeeNamesMap.get(val);

        return employeeName ? employeeName.fullDisplayName : `Employee ID: ${String(val)}`;
      },
    },
    {
      type: "default",
      prop: "searchString",
      format: (val) => `Search: ${val.trim()}`,
    },
  ]);
};
