import { MouseEventHandler } from "react";
import { useDebouncedCallback } from "use-debounce";
import { secondsToMilliseconds } from "date-fns";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { Button } from "@libs/components/UI/Button";
import { usePerioChart } from "components/Charting/Perio/PerioChartContext";
import { TEETH_COUNT } from "components/Charting/teeth";

const cxStyle = {
  color: {
    BLEEDING: "bg-perioChart-bleeding",
    SUPPURATION: "bg-perioChart-suppuration",
    PLAQUE: "bg-perioChart-plaque",
    CALCULUS: "bg-perioChart-calculus",
  },
};

export const PerioChartApplyAll: React.FC<{
  sequenceType: "BLEEDING" | "SUPPURATION" | "PLAQUE" | "CALCULUS";
}> = ({ sequenceType }) => {
  const { currentExam, saveExam } = usePerioChart();
  const apply = useBoolean(true);
  const debounceSave = useDebouncedCallback(saveExam, secondsToMilliseconds(1));

  const handleClick: MouseEventHandler = (e) => {
    for (let i = 1; i <= TEETH_COUNT; i++) {
      const entry = currentExam?.getOrCreateEntry(i, sequenceType);

      if (entry) {
        const value = apply.isOn ? 1 : undefined;

        // Facial
        entry["dbValue"] = value;
        entry["bValue"] = value;
        entry["mbValue"] = value;

        // Lingual
        entry["dlValue"] = value;
        entry["lValue"] = value;
        entry["mlValue"] = value;
        e.preventDefault();
      }
    }
    apply.toggle();
    debounceSave();
  };

  return (
    <Button theme="secondary" size="small" className="whitespace-nowrap" onClick={handleClick}>
      <div className="flex items-center gap-x-2">
        <div className={cx("h-2.5 w-2.5 rounded-sm", cxStyle.color[sequenceType])} />{" "}
        {sentenceCaseConstant(sequenceType)} All
      </div>
    </Button>
  );
};
