import { useMemo, useCallback, useState } from "react";
import { produce } from "immer";
import {
  AppointmentCategoryVO,
  AppointmentPatientProcedureVO,
  DentalProcedureVO,
  LabCaseStatusVO,
  PatientProcedureVO,
} from "@libs/api/generated-api";

import { TREATMENT_PLAN_CATEGORY_COLOR } from "@libs/domains/scheduling/colors";
import { isDefined } from "@libs/utils/types";
import {
  dentalProceduresToDraftPatientProcedures,
  DraftPatientProcedureRequest,
} from "components/Charting/draftPatientProcedure";

import { useNow } from "hooks/useNow";

export const isSavedProcedure = (
  val: AppointmentProcedure
): val is PatientProcedureVO | AppointmentPatientProcedureVO => {
  return typeof val.id === "number";
};

export const isNewProcedure = (val: AppointmentProcedure): val is LocalDraftPatientProcedureRequest => {
  return typeof val.id === "string";
};

export const ValidAddPatientProcedureStatuses: PatientProcedureVO["status"][] = ["PLANNED"];

export const TREATMENT_PLAN_CATEGORY_ID = -1;

export const isTreatmentPlan = (categoryId: number) => categoryId === TREATMENT_PLAN_CATEGORY_ID;

const getCategoryColor = (category: AppointmentCategoryVO | undefined) =>
  category ? category.color : TREATMENT_PLAN_CATEGORY_COLOR;

const getSelectedCategory = (categories: AppointmentCategoryVO[], selectedCategoryId: number) =>
  selectedCategoryId > 0 ? categories.find((cat) => cat.id === selectedCategoryId) : undefined;

const createDentalProcedureDrafts = (
  dentalProcedures: DentalProcedureVO[],
  now: Date
): LocalDraftPatientProcedureRequest[] => {
  return dentalProceduresToDraftPatientProcedures(
    [],
    dentalProcedures,
    "PLANNED",
    undefined,
    {
      type: "NONE",
    },
    now
  ).map((dp, index) => ({
    draft: dp,
    id: crypto.randomUUID(),
    dentalProcedureId: dentalProcedures[index].id,
    duration: dentalProcedures[index].duration,
    dentalProcedure: dentalProcedures[index],
  }));
};

const recycleProcedures = (drafts: LocalDraftPatientProcedureRequest[], existing: AppointmentProcedure[]) => {
  const existingCopy = [...existing];

  const recycledItems: AppointmentProcedure[] = [];

  for (const draft of drafts) {
    let recycled = false;

    for (const [index, item] of existingCopy.entries()) {
      if (item.dentalProcedureId === draft.dentalProcedureId) {
        recycled = true;
        recycledItems.push(item);
        existingCopy.splice(index, 1);
        break;
      }
    }

    if (!recycled) {
      recycledItems.push(draft);
    }
  }

  return recycledItems;
};

export type LocalDraftPatientProcedureRequest = {
  id: string;
  draft: DraftPatientProcedureRequest;
  dentalProcedureId: number;
  duration: string;
  dentalProcedure: DentalProcedureVO;
  labCaseStatus?: LabCaseStatusVO;
};

export type AppointmentProcedure =
  | LocalDraftPatientProcedureRequest
  | PatientProcedureVO
  | AppointmentPatientProcedureVO;
export type CategoryProceduresMap = Record<number, AppointmentProcedure[] | undefined>;

interface State {
  selectedCategoryId: number;
  color: string | undefined;
  categoryProceduresMap: CategoryProceduresMap;
  additionalProcedures: AppointmentProcedure[];
}

export type AppointmentProcedures = {
  category: AppointmentProcedure[];
  additional: AppointmentProcedure[];
};

export const useAppointmentCategoriesAndProcedures = ({
  appointmentCategoryProcedures,
  categories,
  initialCategoryId,
  initialColor,
  patientProcedures,
}: {
  appointmentCategoryProcedures?: AppointmentPatientProcedureVO[];
  categories: AppointmentCategoryVO[];
  initialCategoryId: number;
  initialColor?: string;
  patientProcedures?: (PatientProcedureVO | AppointmentPatientProcedureVO)[];
}) => {
  const now = useNow();
  const [{ additionalProcedures, categoryProceduresMap, selectedCategoryId, color }, setState] =
    useState<State>(() => {
      const selectedCategory = getSelectedCategory(categories, initialCategoryId);

      return {
        color: initialColor,
        selectedCategoryId: initialCategoryId,
        categoryProceduresMap: {
          [initialCategoryId]:
            appointmentCategoryProcedures ||
            (selectedCategory ? createDentalProcedureDrafts(selectedCategory.procedures, now) : undefined),
        },
        additionalProcedures: patientProcedures ?? [],
      };
    });

  const selectedCategory = useMemo(() => {
    return getSelectedCategory(categories, selectedCategoryId);
  }, [categories, selectedCategoryId]);

  const procedures = useMemo(() => {
    return {
      category: categoryProceduresMap[selectedCategoryId] ?? [],
      additional: additionalProcedures,
    };
  }, [categoryProceduresMap, additionalProcedures, selectedCategoryId]);

  const categoryColor = useMemo(() => {
    return getCategoryColor(selectedCategory);
  }, [selectedCategory]);

  const removeProcedure = useCallback(
    (id: string | number) => {
      setState((last) =>
        produce(last, (draft) => {
          // eslint-disable-next-line max-nested-callbacks
          draft.additionalProcedures = draft.additionalProcedures.filter((p) => p.id !== id);

          const categoryProcedures = draft.categoryProceduresMap[draft.selectedCategoryId];

          if (categoryProcedures) {
            draft.categoryProceduresMap[draft.selectedCategoryId] = categoryProcedures.filter(
              // eslint-disable-next-line max-nested-callbacks
              (p) => p.id !== id
            );
          }
        })
      );
    },
    [setState]
  );

  const selectCategory = useCallback(
    (id: number) => {
      // Select all of the category dental procedures when switching between categories.
      // Patient and added dental procedures are sticky and should remain when switching
      // categories

      setState((last) =>
        produce(last, (draft) => {
          const selected = getSelectedCategory(categories, id);

          draft.selectedCategoryId = id;

          if (selected) {
            const proceduresToRecyle = last.categoryProceduresMap[id] ?? appointmentCategoryProcedures ?? [];

            draft.categoryProceduresMap[id] = recycleProcedures(
              createDentalProcedureDrafts(selected.procedures, now),
              proceduresToRecyle
            );
          }
        })
      );
    },
    [setState, categories, appointmentCategoryProcedures, now]
  );

  const addProcedures = useCallback(
    (newDentalProcedures: DentalProcedureVO[], newPatientProcedures: PatientProcedureVO[]) => {
      setState((last) => {
        const newUniques = newPatientProcedures.filter(
          // eslint-disable-next-line max-nested-callbacks
          (npp) => !last.additionalProcedures.some((ap) => ap.id === npp.id)
        );

        return {
          ...last,
          additionalProcedures: [
            ...last.additionalProcedures,
            ...createDentalProcedureDrafts(newDentalProcedures, now),
            ...newUniques,
          ],
        };
      });
    },
    [setState, now]
  );

  const updateDraft = useCallback((id: string, data: DraftPatientProcedureRequest) => {
    setState((last) =>
      produce(last, (draft) => {
        const match = // eslint-disable-next-line max-nested-callbacks
          (draft.additionalProcedures.find((ap) => ap.id === id) ||
            draft.categoryProceduresMap[last.selectedCategoryId]?.find(
              // eslint-disable-next-line max-nested-callbacks
              (ap) => ap.id === id
            )) as LocalDraftPatientProcedureRequest;

        match.draft = data;
      })
    );
  }, []);

  const switchProcedure = useCallback(
    (id: number | string, data: PatientProcedureVO | LocalDraftPatientProcedureRequest) => {
      setState((last) =>
        produce(last, (draft) => {
          // If a procedure is switched we need to pull it out of anywhere we were storing it
          // (it's possible the existing saved procedure was recycled across several categories)
          // and add it to additiona procedures

          // It's possible the existing saved procedure was recycled across several categories
          const categoryIds = Object.keys(last.categoryProceduresMap) as unknown as number[];

          for (const categoryId of categoryIds) {
            const categoryProcedures = last.categoryProceduresMap[categoryId];

            draft.categoryProceduresMap[categoryId] = categoryProcedures?.filter(
              // eslint-disable-next-line max-nested-callbacks
              (cp) => cp.id !== id
            );
          }
          // eslint-disable-next-line max-nested-callbacks
          draft.additionalProcedures = [...last.additionalProcedures.filter((ap) => ap.id !== id), data];
        })
      );
    },
    []
  );

  const switchSavedProcedure = useCallback(
    (id: number, data: PatientProcedureVO) => {
      switchProcedure(id, data);
    },
    [switchProcedure]
  );

  const switchNewProcedure = useCallback(
    (id: string, data: { draft: DraftPatientProcedureRequest; dentalProcedure: DentalProcedureVO }) => {
      switchProcedure(id, {
        ...data,
        duration: data.dentalProcedure.duration,
        dentalProcedureId: data.dentalProcedure.id,
        id: crypto.randomUUID(),
      });
    },
    [switchProcedure]
  );

  const setColor = useCallback(
    (newColor: string | undefined) => {
      setState((last) => ({ ...last, color: newColor }));
    },
    [setState]
  );

  return {
    color: color ?? categoryColor,
    hasCustomColor: isDefined(color),
    selectedCategoryId,
    procedures,
    selectedCategory,
    switchSavedProcedure,
    switchNewProcedure,
    updateDraft,
    addProcedures,
    removeProcedure,
    selectCategory,
    setColor,
  };
};
