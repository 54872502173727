// logic here https://grindfoundry.atlassian.net/browse/GF-626
const durationRegex = /(\/*)(X*)(\/*)/;

export const UNIT_DURATION = 5;

export const calculateDuration = (selectedProceduresList: { duration: string }[]) => {
  let maxInitial = 0;
  let maxEnd = 0;
  let mainSum = 0;

  for (const selectedProcedure of selectedProceduresList) {
    // any string will match
    const match = selectedProcedure.duration.match(durationRegex) as RegExpMatchArray;
    const [_, initial, main, end] = match;

    maxInitial = Math.max(maxInitial, initial.length);
    maxEnd = Math.max(maxEnd, end.length);
    mainSum += main.length;
  }

  return (maxInitial + mainSum + maxEnd) * UNIT_DURATION;
};
