import { FC, PropsWithChildren } from "react";
import { EmailDnsRecord } from "@libs/api/generated-api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as CopyIcon } from "@libs/assets/icons/copy.svg";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { Icon } from "@libs/components/UI/Icon";
import { useNotificationContext } from "contexts/NotificationsContext";

const recordInfo: Partial<Record<EmailDnsRecord["type"], string>> = {
  TXT: "Make sure to include the quotation marks when entering your TXT record",
  MX: "If your provider has a Priority field, remove the 10 from Value and enter it in the Priority field",
};

interface Props {
  emailDnsRecord: EmailDnsRecord;
}

const Field: FC<PropsWithChildren<{ label: string }>> = ({ label, children }) => {
  return (
    <div className="flex items-center">
      <div className="w-20 font-sansSemiBold">{label}</div>
      {children}
    </div>
  );
};

export const EmailDnsRecordRow: FC<Props> = ({ emailDnsRecord }) => {
  const notification = useNotificationContext();

  const handleCopyKey = (value: string) => {
    navigator.clipboard.writeText(value);
    notification.handleInfo("Copied to clipboard.");
  };

  return (
    <div
      className={`
        border
        rounded
        border-slate-200
        p-3
        text-xs
        flex
        flex-col
        gap-y-1
      `}
    >
      <Field label="Type">
        <div data-testid="email-dns-record-type">{emailDnsRecord.type}</div>
      </Field>
      <Field label="Name">
        <div data-testid="email-dns-record-name" className="flex items-center gap-x-2">
          {" "}
          {emailDnsRecord.name}
          <ButtonIcon
            SvgIcon={CopyIcon}
            tooltip={{ content: "Copy", theme: "SMALL" }}
            onClick={() => handleCopyKey(emailDnsRecord.name)}
            size="sm"
          />
        </div>
      </Field>
      <Field label="Value">
        <div data-testid="email-dns-record-value" className="flex items-center gap-x-2">
          {emailDnsRecord.value}
          <ButtonIcon
            SvgIcon={CopyIcon}
            tooltip={{ content: "Copy", theme: "SMALL" }}
            onClick={() => handleCopyKey(emailDnsRecord.value)}
            size="sm"
          />
        </div>
      </Field>

      {recordInfo[emailDnsRecord.type] ? (
        <div className="flex items-center gap-x-2">
          <Icon size="sm" SvgIcon={InfoIcon} />
          {recordInfo[emailDnsRecord.type]}
        </div>
      ) : null}
    </div>
  );
};
