import { Link } from "react-router-dom";
import { isDefined } from "@libs/utils/types";
import { Pill } from "@libs/components/UI/Pill";

type OnlineBookingSettingsLinkProps = {
  count?: number;
  description: string;
  Icon: IconComponent;
  linkName: string;
  linkTo: string;
  title: string;
};

export const OnlineBookingSettingsLink: React.FC<OnlineBookingSettingsLinkProps> = ({
  count,
  description,
  Icon,
  linkName,
  linkTo,
  title,
}) => {
  return (
    <div className="flex flex-row items-center gap-x-6">
      <div className="flex items-center justify-center h-24 w-24">
        <Icon />
      </div>
      <div className="flex flex-col gap-y-2">
        {isDefined(count) ? (
          <Pill theme={count ? "green" : "orange"} className="self-start">
            {count === 0 ? "No" : count} {title}
          </Pill>
        ) : (
          <div className="font-sansSemiBold text-sm text-greyMedium">{linkName}</div>
        )}
        <div className="text-xs/4 text-greyMedium">{description}</div>
        <Link to={linkTo} className="font-sansSemiBold text-xs/4 text-primaryTheme">
          {linkName}
        </Link>
      </div>
    </div>
  );
};
