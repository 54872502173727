import { PropsWithChildren, useCallback, useContext, useState } from "react";
import { FileRejection } from "react-dropzone";
import { ClaimAttachmentVO, DocumentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { readFileToURL } from "@libs/utils/dataUrl";
import { SupportedUploadMimeTypes, mimeTypesToExtensions } from "@libs/utils/mimeTypes";
import { listToText } from "@libs/utils/formatString";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { captureException } from "@sentry/react";
import { Spinner } from "@libs/components/UI/Spinner";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { useAccount } from "@libs/contexts/AccountContext";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { Modal } from "@libs/components/UI/Modal";
import { AttachScanModalContent } from "components/UI/AttachModalContent/AttachScanModalContent";
import { AttachDocumentModalContent } from "components/UI/AttachModalContent/AttachDocumentModalContent";
import { Instructions } from "components/Claim/Attachments/Instructions";
import { AttachFilesContent } from "components/UI/AttachModalContent/AttachFilesContent";
import { ImagePreview } from "components/Claim/Attachments/ImagePreview";
import { getFolders } from "api/documents/queries";

import { MedicalImage, OtherImage, PerioChart } from "components/Claim/Attachments/ClaimAttachmentsContext";
import { handleError } from "utils/handleError";
import { EobImage } from "components/Claim/Attachments/ClaimEobsRoute";

interface Props {
  attachmentType: "image" | "perio" | "other";
  canSelectMultiple: boolean;
  editImage?: (imageData: string, type: ClaimAttachmentVO["type"]) => void;
  images: EobImage[] | MedicalImage[] | PerioChart[] | OtherImage[] | undefined;
  onAddImage: (url: string) => void;
  onRemovePerioChartImage?: Func;
  removeImage?: ((image: Omit<ClaimAttachmentVO, "uuid">) => void) | undefined;
  userId: number;
}

const cxStyles = {
  section: "w-1/2",
};

const ALLOWED_MIME_TYPES: SupportedUploadMimeTypes[] = ["image/jpeg", "image/png"];
const MAX_IMG_DIMENSION = 1800;

export const MAX_CLAIM_ATTACHMENT_UPLOAD_SIZE_IN_BYTES = 10_000_000;

const resizeImage = (documentUrl: string) => {
  return new Promise<string>((resolve, reject) => {
    const img = new Image();

    img.addEventListener("load", () => {
      const canvas = document.createElement("canvas");
      const originalHeight = img.height;
      const originalWidth = img.width;

      const aspectRatio = originalWidth / originalHeight;

      let newWidth = MAX_IMG_DIMENSION;
      let newHeight = newWidth / aspectRatio;

      if (newHeight > MAX_IMG_DIMENSION) {
        newHeight = MAX_IMG_DIMENSION;
        newWidth = newHeight * aspectRatio;
      }

      canvas.width = newWidth;
      canvas.height = newHeight;

      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.drawImage(img, 0, 0, originalWidth, originalHeight, 0, 0, newWidth, newHeight);
      }

      resolve(canvas.toDataURL("image/jpeg"));
    });

    img.addEventListener("error", () => {
      reject(new Error("Error loading image."));
    });

    img.src = documentUrl;
    img.crossOrigin = "anonymous";
  });
};

export const ExternalUpload: React.FC<PropsWithChildren & Props> = ({
  attachmentType,
  canSelectMultiple,
  children,
  editImage,
  images,
  onAddImage,
  onRemovePerioChartImage,
  removeImage,
  userId,
}) => {
  const { fetchData } = useContext(ApiClientContext);
  const { practiceId } = useAccount();
  const [modalContent, setModalContent] = useState<"scan" | "select" | null>(null);
  const [foldersQuery] = useApiQueries([getFolders({ args: { practiceId, userId } })]);
  const [loadImagesState, setLoadImagesState] = useState<"error" | "loading" | "idle">("idle");

  const handleAddFiles = useCallback(
    (files: File[]) => {
      setLoadImagesState("loading");

      const promises = files.map((file) => readFileToURL(file).then((src) => resizeImage(src)));

      Promise.all(promises)
        .then((urls) => {
          for (const url of urls) {
            onAddImage(url);
          }
          setLoadImagesState("idle");
        })
        .catch((e) => {
          setLoadImagesState("error");
          captureException(e);
        });
    },
    [onAddImage]
  );

  const handleDropFiles = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      handleAddFiles(acceptedFiles);
      rejectedFiles.forEach(handleError);
    },
    [handleAddFiles]
  );

  const handleAddDocuments = useCallback(
    (files: DocumentVO[]) => {
      setLoadImagesState("loading");

      const promises = files.map((file) =>
        fetchData<string>(
          `/practices/${practiceId}/users/${userId}/folders/${file.folderId}/documents/${file.id}/url?includeMetadata=true`
        ).then((url) => resizeImage(url))
      );

      Promise.all(promises)
        .then((urls) => {
          for (const url of urls) {
            onAddImage(url);
          }
          setLoadImagesState("idle");
        })
        .catch((e) => {
          setLoadImagesState("error");
          captureException(e);
        });
    },
    [fetchData, practiceId, userId, onAddImage]
  );

  return (
    <div className="flex h-full w-full">
      <div className={cxStyles.section}>
        <div className="flex flex-col h-full gap-y-6 pb-6 pr-6">
          {children}
          <AttachFilesContent
            allowedMimeTypes={ALLOWED_MIME_TYPES}
            allowPaste={true}
            canSelectMultiple={canSelectMultiple}
            maxFilesSelected={Boolean(!canSelectMultiple && images?.length)}
            maxFileSizeByBytes={MAX_CLAIM_ATTACHMENT_UPLOAD_SIZE_IN_BYTES}
            onDropFiles={handleDropFiles}
            onRequestScanDocument={() => setModalContent("scan")}
            onRequestSelectDocument={() => setModalContent("select")}
          />
          {(images?.length ?? 0) > 0 ? (
            <div className="flex-1 overflow-y-auto">
              <div className="flex flex-wrap gap-3">
                {images?.map((image) => (
                  <ImagePreview
                    image={image}
                    key={image.data}
                    editImage={editImage}
                    onRemovePerioChartImage={onRemovePerioChartImage}
                    removeImage={removeImage}
                  />
                ))}
              </div>
            </div>
          ) : null}
          <div className="flex items-center justify-center">
            {loadImagesState === "loading" ? (
              <Spinner animation="border" variant="primary" size="md" />
            ) : loadImagesState === "error" ? (
              <ErrorContent />
            ) : null}
          </div>
        </div>
      </div>
      <div className="h-full">
        <VerticalDivider />
      </div>
      <div className={cx("px-6", cxStyles.section)}>
        <Instructions attachmentType={attachmentType} />
      </div>
      {modalContent && foldersQuery.data && (
        <Modal
          onClose={() => setModalContent(null)}
          size="xs"
          title={
            modalContent === "scan" ? (
              "Scan Document"
            ) : (
              <div className="flex items-baseline">
                <span>Select Document&nbsp;</span>
                <span className="text-xs text-greyLight font-sans">{`(Select ${listToText(
                  mimeTypesToExtensions(ALLOWED_MIME_TYPES),
                  "or"
                )})`}</span>
              </div>
            )
          }
        >
          {modalContent === "scan" ? (
            <AttachScanModalContent
              onScanFiles={handleAddFiles}
              onRequestClose={() => setModalContent(null)}
              scanAs="JPG"
            />
          ) : (
            <AttachDocumentModalContent
              allowedMimeTypes={ALLOWED_MIME_TYPES}
              canSelectMultiple={canSelectMultiple}
              folders={foldersQuery.data}
              onRequestClose={() => setModalContent(null)}
              onSelectDocuments={handleAddDocuments}
              userId={userId}
            />
          )}
        </Modal>
      )}
    </div>
  );
};
