import { scaleLinear } from "@visx/scale";
import React from "react";
import { CustomAdjustmentTypeVO } from "@libs/api/generated-api";
import { toMap } from "@libs/utils/array";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { AdjustmentPieDatum } from "components/Dashboard/Adjustments/types";
import { CarriersQuery, TimeSeriesQuery } from "components/Dashboard/types";
import { CHART_COLORS } from "components/Dashboard/Charting/theme";
import { labelForAdjustmentTypeId, labelForCarrier } from "components/Dashboard/Adjustments/utils";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";
import { handleError } from "utils/handleError";

const throwDimensionError = () => {
  const error = new Error("No dimension provided for timeSeries item");

  handleError(error);
  throw error;
};

export const useAdjustmentQueriesAsPieData = (
  carrierAdjustmentsForSelectedTimePeriodQuery: TimeSeriesQuery,
  collectionAdjustmentsForSelectedTimePeriodQuery: TimeSeriesQuery,
  allCarriersQuery: CarriersQuery,
  adjustmentTypesQuery: ApiQueryResult<CustomAdjustmentTypeVO[]>,
  filters: DashboardFilter[]
) => {
  const insuranceAdjustmentData = carrierAdjustmentsForSelectedTimePeriodQuery.data;
  const collectionAdjustmentData = collectionAdjustmentsForSelectedTimePeriodQuery.data;
  const allCarriers = allCarriersQuery.data;
  const adjustmentTypes = adjustmentTypesQuery.data;

  const pieWedges: AdjustmentPieDatum[] = React.useMemo(() => {
    if (!insuranceAdjustmentData || !collectionAdjustmentData || !allCarriers) {
      return [];
    }

    const carrierLookup = toMap(allCarriers, "insuranceCarrierId");
    const colorScale = scaleLinear({
      domain: [0, insuranceAdjustmentData.timeSeries.length + collectionAdjustmentData.timeSeries.length],
      range: [CHART_COLORS.blue, "#DEFAFF"],
    });
    const carrierFilterValues = new Set(filters.find((item) => item.type === "insuranceCarrierId")?.values);
    const adjustmentFilterValues = new Set(
      filters.find((item) => item.type === "customAdjustmentTypeId")?.values
    );
    const insuranceAdjustments: AdjustmentPieDatum[] = insuranceAdjustmentData.timeSeries.map(
      (item, index) => {
        if (!item.dimension) {
          return throwDimensionError();
        }

        const val = item.dataPoints[0].value;
        const carrierId = Number.parseInt(item.dimension, 10);

        return {
          key: item.dimension,
          label: labelForCarrier(carrierLookup, carrierId),
          value: Math.abs(val),
          actualValue: val,
          color: colorScale(index),
          className: "cursor-pointer",
          adjustmentCategory: "insurance",
          selected: carrierFilterValues.has(item.dimension),
        };
      }
    );
    const collectionAdjustments: AdjustmentPieDatum[] = collectionAdjustmentData.timeSeries.map(
      (item, index) => {
        if (!item.dimension) {
          return throwDimensionError();
        }

        const adjustmentTypeId = item.dimension;
        const val = item.dataPoints[0].value;

        return {
          key: adjustmentTypeId,
          label: labelForAdjustmentTypeId(adjustmentTypeId, adjustmentTypes),
          value: Math.abs(val),
          actualValue: val,
          adjustmentCategory: "collection",
          className: "cursor-pointer",
          color: colorScale(insuranceAdjustments.length + index),
          selected: adjustmentFilterValues.has(adjustmentTypeId),
        };
      }
    );

    return [...insuranceAdjustments, ...collectionAdjustments];
  }, [adjustmentTypes, allCarriers, collectionAdjustmentData, filters, insuranceAdjustmentData]);

  return pieWedges;
};
