import React, { FC, ReactNode } from "react";
import { LedgerV2ProcedureItemVO } from "@libs/api/generated-api";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { getInitials } from "utils/names";
import { Text } from "./Text";

const ASSIGNED_PRACTICE_ID = -1;

export const ProviderInitials: FC<{ provider: LedgerV2ProcedureItemVO["provider"] }> = ({ provider }) => {
  return provider ? (
    <Initials
      initials={provider.id === ASSIGNED_PRACTICE_ID ? "—" : getInitials(provider)}
      fullName={provider.fullDisplayName}
    />
  ) : null;
};

const Initials: FC<{ initials: ReactNode; fullName: ReactNode }> = ({ initials, fullName }) => {
  return (
    <FloatingTooltip content={fullName} theme="SMALL">
      <div className="flex">
        <Text>{initials}</Text>
      </div>
    </FloatingTooltip>
  );
};
