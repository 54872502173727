import { FC } from "react";

import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Flyover } from "components/UI/Flyover";
import { EditFormSettings } from "components/Settings/Forms/EditFormSettings";

import { getDentalProceduresQuery } from "api/charting/queries";

import { EditFormSettingsProps } from "components/Settings/Forms/types";

export const EditFormSettingsFlyover: FC<EditFormSettingsProps> = ({
  form,
  onUpdateSettings,
  onRequestClose,
  isSaving,
}) => {
  const { practiceId } = useAccount();
  const [proceduresQuery] = useApiQueries([getDentalProceduresQuery({ args: { practiceId } })]);

  return (
    <Flyover title="Settings" onClose={onRequestClose} size="md">
      <QueryResult queries={[proceduresQuery]}>
        {proceduresQuery.data ? (
          <EditFormSettings
            form={form}
            procedures={proceduresQuery.data}
            onUpdateSettings={onUpdateSettings}
            onRequestClose={onRequestClose}
            isSaving={isSaving}
          />
        ) : null}
      </QueryResult>
    </Flyover>
  );
};
