import React, { FormEvent } from "react";
import { EmployeeVO } from "@libs/api/generated-api";
import { formatAddress } from "@libs/utils/address";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldAddressAutocomplete } from "@libs/components/UI/FormFieldAddressAutocomplete";
import { Form } from "@libs/components/UI/Form";
import { Section } from "components/EmployeeProfile/Section";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { FormFieldSelectGender } from "components/UI/FormFieldSelectGender";
import { FormFieldSelectEmergencyRelationship } from "components/UI/FormFieldSelectEmergencyRelationship";
import { FormFieldSelectPronoun } from "components/UI/FormFieldSelectPronoun";
import { Divider } from "components/UI/Divider";
import { FormFieldSsnInput } from "components/UI/FormFieldSsnInput";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";
import { createAddressValue } from "api/user/data";
import { EmployeePersonalDetailsValidationResult } from "components/Employee/schema";
import { useEnvContext } from "contexts/EnvContext";

const sanitizeName = (name: string) => name.replaceAll(/\d+/g, "");

interface EditPersonalDetailsProps {
  employee: Pick<EmployeeVO, "personalDetails" | "contactDetails">;
  serverErrors: Partial<Record<string | number, string>>;
  validation?: EmployeePersonalDetailsValidationResult;
  onSubmit?: (e: FormEvent<HTMLFormElement>) => void;
  onChange?: (updater: (draft: Pick<EmployeeVO, "personalDetails" | "contactDetails">) => void) => void;
  isEditing: boolean;
  isOwner?: boolean;
  isIncludedInPayroll?: boolean;
  isActive?: boolean;
}

const FORM_ID = "employee-personal-info-form";

// eslint-disable-next-line complexity
export const PersonalDetailsForm: React.FC<EditPersonalDetailsProps> = ({
  employee,
  serverErrors,
  validation,
  onSubmit,
  onChange,
  isEditing,
  isOwner,
  isIncludedInPayroll,
  isActive,
}) => {
  const { REACT_APP_GOOGLE_API_KEY } = useEnvContext();
  const humanizedAddress = formatAddress(employee.contactDetails.addressDetails);

  const areContactFieldsRequiredForPayroll = Boolean(isActive && isIncludedInPayroll);

  return (
    <Form
      fieldLayout="labelOut"
      id={FORM_ID}
      className="gap-y-6 flex flex-col max-w-xl mx-auto"
      onSubmit={onSubmit}
    >
      <Section title="Personal">
        <FormFieldInput
          required
          error={serverErrors["personalDetails.firstName"] || validation?.personalDetails.firstName.$error}
          label="First Name"
          type="text"
          value={employee.personalDetails.firstName}
          edit={isEditing}
          onChange={(e) =>
            onChange?.((draft) => (draft.personalDetails.firstName = sanitizeName(e.target.value)))
          }
        />
        <FormFieldInput
          error={serverErrors["personalDetails.middleName"]}
          label="Middle Name"
          type="text"
          value={employee.personalDetails.middleName}
          edit={isEditing}
          onChange={(e) =>
            onChange?.((draft) => (draft.personalDetails.middleName = sanitizeName(e.target.value)))
          }
        />
        <FormFieldInput
          required
          error={serverErrors["personalDetails.lastName"] || validation?.personalDetails.lastName.$error}
          label="Last Name"
          type="text"
          value={employee.personalDetails.lastName}
          edit={isEditing}
          onChange={(e) =>
            onChange?.((draft) => (draft.personalDetails.lastName = sanitizeName(e.target.value)))
          }
        />

        <FormFieldInput
          error={serverErrors["personalDetails.preferredName"]}
          label="Preferred Name"
          type="text"
          value={employee.personalDetails.preferredName}
          edit={isEditing}
          onChange={(e) =>
            onChange?.((draft) => (draft.personalDetails.preferredName = sanitizeName(e.target.value)))
          }
        />
        <FormFieldSelectGender
          label="Gender"
          required={false}
          error={serverErrors["personalDetails.gender"]}
          value={employee.personalDetails.gender}
          edit={isEditing}
          onChange={(e) => onChange?.((draft) => (draft.personalDetails.gender = e?.value))}
        />
        <FormFieldSelectPronoun
          error={serverErrors["personalDetails.preferredPronouns"]}
          value={employee.personalDetails.preferredPronouns}
          edit={isEditing}
          label="Preferred Pronouns"
          onChange={(e) => onChange?.((draft) => (draft.personalDetails.preferredPronouns = e?.value))}
        />
        <FormFieldSelectMenusDatepicker
          label="Date of Birth"
          error={serverErrors["personalDetails.dob"] || validation?.personalDetails.dob.$error}
          selected={employee.personalDetails.dob ? getLocalDate(employee.personalDetails.dob) : undefined}
          edit={isEditing}
          required={Boolean(isOwner)}
          onChange={(date) =>
            onChange?.((draft) => (draft.personalDetails.dob = date ? formatAsISODate(date) : ""))
          }
        />
        <FormFieldSsnInput
          label="Social Security Number"
          edit={isEditing}
          ssnLastFour={employee.personalDetails.ssnLastFour}
          value={employee.personalDetails.ssn}
          error={serverErrors["personalDetails.ssn"]}
          onValueChange={(value) => onChange?.((draft) => (draft.personalDetails.ssn = value || undefined))}
        />
      </Section>
      <Divider className="border-dashed" />
      <Section title="Contact">
        <div className="col-span-2">
          {isEditing ? (
            <div className="grid grid-cols-8 gap-6">
              <FormFieldAddressAutocomplete
                apiKey={REACT_APP_GOOGLE_API_KEY}
                required={areContactFieldsRequiredForPayroll}
                error={validation?.contactDetails.addressDetails.address1.$error}
                defaultValue={employee.contactDetails.addressDetails?.address1}
                className="col-span-4"
                label="Address"
                onSelect={(e) =>
                  onChange?.(
                    (draft) =>
                      (draft.contactDetails.addressDetails = e.raw
                        ? { address1: e.address, state: e.state, city: e.locality, zip: e.zip }
                        : undefined)
                  )
                }
              />
              <FormFieldInput
                error={serverErrors["contactDetails.addressDetails.address2"]}
                label="Apt, Suite, Bldg, Street"
                className="col-span-4"
                type="text"
                value={employee.contactDetails.addressDetails?.address2}
                onChange={(e) => {
                  if (!employee.contactDetails.addressDetails?.address1 && !e.target.value) {
                    onChange?.((draft) => (draft.contactDetails.addressDetails = undefined));
                  } else {
                    onChange?.((draft) => {
                      draft.contactDetails.addressDetails = createAddressValue({
                        ...draft.contactDetails.addressDetails,
                        address2: e.target.value,
                      });
                    });
                  }
                }}
              />
              <FormFieldInput
                className="col-span-4"
                error={
                  serverErrors["contactDetails.addressDetails.city"] ||
                  validation?.contactDetails.addressDetails.city.$error
                }
                label="City"
                required={areContactFieldsRequiredForPayroll}
                type="text"
                value={employee.contactDetails.addressDetails?.city}
                onChange={(e) => {
                  onChange?.((draft) => {
                    draft.contactDetails.addressDetails = createAddressValue({
                      ...draft.contactDetails.addressDetails,
                      city: e.target.value,
                    });
                  });
                }}
              />
              <FormFieldInput
                error={serverErrors["state"] || validation?.contactDetails.addressDetails.state.$error}
                label="State"
                required={areContactFieldsRequiredForPayroll}
                type="text"
                className="col-span-2"
                value={employee.contactDetails.addressDetails?.state}
                onChange={(e) => {
                  onChange?.((draft) => {
                    draft.contactDetails.addressDetails = createAddressValue({
                      ...draft.contactDetails.addressDetails,
                      state: e.target.value,
                    });
                  });
                }}
              />

              <FormFieldInput
                error={
                  serverErrors["contactDetails.addressDetails.zip"] ||
                  validation?.contactDetails.addressDetails.zip.$error
                }
                label="Zip Code"
                required={areContactFieldsRequiredForPayroll}
                className="col-span-2"
                type="text"
                value={employee.contactDetails.addressDetails?.zip}
                onChange={(e) => {
                  onChange?.((draft) => {
                    draft.contactDetails.addressDetails = createAddressValue({
                      ...draft.contactDetails.addressDetails,
                      zip: e.target.value,
                    });
                  });
                }}
              />
            </div>
          ) : (
            <div className="text-xs">
              <div className="pb-1 font-sansSemiBold">Address</div>
              {employee.contactDetails.addressDetails ? (
                <div className="">
                  {humanizedAddress.lineOne}
                  <br />
                  {humanizedAddress.lineTwo}
                </div>
              ) : (
                "-"
              )}
            </div>
          )}
        </div>
        <FormFieldInput
          error={serverErrors["contactDetails.workEmail"] || validation?.contactDetails.workEmail.$error}
          required
          label="Work Email"
          type="email"
          value={employee.contactDetails.workEmail}
          edit={isEditing}
          onChange={(e) => onChange?.((draft) => (draft.contactDetails.workEmail = e.target.value))}
        />
        <FormFieldInput
          error={
            serverErrors["contactDetails.personalEmail"] || validation?.contactDetails.personalEmail.$error
          }
          required={areContactFieldsRequiredForPayroll}
          label="Personal Email"
          type="email"
          value={employee.contactDetails.personalEmail}
          edit={isEditing}
          onChange={(e) => onChange?.((draft) => (draft.contactDetails.personalEmail = e.target.value))}
        />

        <FormFieldPhoneInput
          label="Phone"
          required={Boolean(isOwner)}
          error={serverErrors["contactDetails.phone"] || validation?.contactDetails.phone.$error}
          edit={isEditing}
          value={employee.contactDetails.phone}
          onValueChange={(phone) => onChange?.((draft) => (draft.contactDetails.phone = phone))}
        />
      </Section>
      <Divider className="border-dashed" />
      <Section title="Emergency Contact">
        <FormFieldInput
          error={serverErrors["contactDetails.emergencyContact.name"]}
          label="Name"
          type="text"
          value={employee.contactDetails.emergencyContact?.name}
          edit={isEditing}
          onChange={(e) =>
            onChange?.(
              (draft) =>
                (draft.contactDetails.emergencyContact = {
                  ...draft.contactDetails.emergencyContact,
                  name: e.target.value,
                })
            )
          }
        />
        <FormFieldPhoneInput
          label="Phone"
          error={serverErrors["contactDetails.emergencyContact.phone"]}
          edit={isEditing}
          value={employee.contactDetails.emergencyContact?.phone}
          onValueChange={(phone) =>
            onChange?.(
              (draft) =>
                (draft.contactDetails.emergencyContact = {
                  ...draft.contactDetails.emergencyContact,
                  phone,
                })
            )
          }
        />
        <FormFieldSelectEmergencyRelationship
          error={serverErrors["contactDetails.emergencyContact.relationship"]}
          label="Relationship"
          value={employee.contactDetails.emergencyContact?.relationship}
          edit={isEditing}
          menuPlacement="top"
          onChange={(option) =>
            onChange?.(
              (draft) =>
                (draft.contactDetails.emergencyContact = {
                  ...draft.contactDetails.emergencyContact,
                  relationship: option?.value,
                })
            )
          }
        />
      </Section>
    </Form>
  );
};
