import { FC, useMemo, useCallback } from "react";
import { format, parseISO } from "date-fns";
import { produce } from "immer";
import { PublicHolidayVO, TimeRangeVO, WorkingHourItemVO } from "@libs/api/generated-api";
import { addHoursToISOTime } from "@libs/utils/date";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as RemoveIcon } from "@libs/assets/icons/minus.svg";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { SettingsLabel, SettingsValue } from "@libs/components/UI/SettingsComponents";
import { Switch } from "@libs/components/UI/Switch";
import { TIME_STRING_HOURS_END_INDEX } from "components/WorkingHours/EditWorkingHours";
import { getMinMaxWorkingHourTime, groupWorkingHoursByDay } from "utils/workingHoursUtil";
import { FormFieldTimeRangeMenu } from "components/UI/FormFieldTimeRangeMenu";
import { YearTabs } from "components/Settings/YearTabs";

const cxStyles = {
  row: `
    grid
    grid-cols-[80px_50px_80px_200px_repeat(2,_65px)]
    gap-11
    items-start
    w-full
    mt-2.5
    mb-2.5
  `,
};

interface Props {
  publicHolidays: EditablePublicHoliday[];
  workingHourItems: WorkingHourItemVO[];
  onChange: (publicHolidays: EditablePublicHoliday[]) => void;
  onYearSelect: (year: string) => void;
  selectedYear: string;
}

export type EditablePublicHoliday = Omit<PublicHolidayVO, "timeRanges"> & {
  timeRanges?: (TimeRangeVO & { id: string | number })[];
};

export const EditUsHolidays: FC<Props> = ({
  publicHolidays,
  workingHourItems,
  onChange,
  onYearSelect,
  selectedYear,
}) => {
  const workingHours = useMemo(() => {
    return groupWorkingHoursByDay(workingHourItems);
  }, [workingHourItems]);

  const handleTimeChange = useCallback(
    (holidayIndex: number, hoursIndex: number, startTime: string, endTime: string) => {
      const holidays = produce(publicHolidays, (draft) => {
        const timeRange = (draft[holidayIndex].timeRanges ?? [])[hoursIndex];

        timeRange.startTime = startTime;
        timeRange.endTime = endTime;
      });

      onChange(holidays);
    },
    [publicHolidays, onChange]
  );

  const isAddTimeDisabled = useCallback(
    (holidayIndex: number, hoursIndex: number) => {
      const publicHolidayHours = publicHolidays[holidayIndex].timeRanges ?? [];
      const lastHoursIndex = publicHolidayHours.length - 1;
      const isLastTimeSlot = lastHoursIndex === hoursIndex;
      const { endTime: lastEndTime } = publicHolidayHours[lastHoursIndex];

      // Disable add click if the last time slot ends at 11:00 PM (23:00:00)
      return lastEndTime.slice(0, TIME_STRING_HOURS_END_INDEX) === "23" && isLastTimeSlot;
    },
    [publicHolidays]
  );

  const addTimeInterval = useCallback(
    (holidayIndex: number) => {
      const holidayTimeRanges = publicHolidays[holidayIndex].timeRanges ?? [];
      const { endTime: prevEndTime } = holidayTimeRanges.at(-1) as ListItem<typeof holidayTimeRanges>;
      const startTime = addHoursToISOTime(prevEndTime, 1);
      const endTime = addHoursToISOTime(startTime, 1);

      const holidays = produce(publicHolidays, (draft) => {
        draft[holidayIndex].timeRanges?.push({ startTime, endTime, id: crypto.randomUUID() });
      });

      onChange(holidays);
    },
    [publicHolidays, onChange]
  );

  const removeTimeInterval = useCallback(
    (holidayIndex: number, hoursIndex: number) => {
      const holidays = produce(publicHolidays, (draft) => {
        draft[holidayIndex]?.timeRanges?.splice(hoursIndex, 1);
      });

      onChange(holidays);
    },
    [publicHolidays, onChange]
  );

  const handleSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, holidayIndex: number) => {
      const { checked } = event.target;
      const holidayDate = publicHolidays[holidayIndex].date;
      const holidayDayOfWeek = format(parseISO(holidayDate), "EEEE").toUpperCase();
      const practiceHoursOfDay = workingHours.find(
        (groupedWorkingHours) => groupedWorkingHours.dayOfWeek === holidayDayOfWeek
      );
      const practiceTimeRanges = practiceHoursOfDay?.hours.map(({ startTime, endTime, id }) => ({
        id,
        startTime,
        endTime,
      }));

      const holidays = produce(publicHolidays, (draft) => {
        const holiday = draft[holidayIndex];

        holiday.isClosed = !checked;
        holiday.timeRanges = checked ? practiceTimeRanges : holiday.timeRanges;
      });

      onChange(holidays);
    },
    [publicHolidays, workingHours, onChange]
  );

  return (
    <YearTabs selectedTab={selectedYear} onSelect={onYearSelect}>
      <div className={cxStyles.row}>
        <SettingsValue />
        <SettingsLabel>Date</SettingsLabel>
        <SettingsLabel>Day</SettingsLabel>
        <SettingsLabel>Description</SettingsLabel>
      </div>
      {publicHolidays.map(
        (holiday, holidayIndex) =>
          holiday.id && (
            <div key={holiday.id} className={cxStyles.row}>
              <SettingsValue className="flex items-center gap-x-2.5 mb-1">
                <Switch
                  value={holiday.id}
                  onChange={(event) => handleSwitchChange(event, holidayIndex)}
                  checked={!holiday.isClosed}
                />
                <p className="text-xs">{holiday.isClosed ? "Closed" : "Open"}</p>
              </SettingsValue>
              <SettingsValue>{format(parseISO(holiday.date), "MMM dd")}</SettingsValue>
              <SettingsValue>{format(parseISO(holiday.date), "iiii")}</SettingsValue>
              <SettingsValue>{holiday.description}</SettingsValue>
              <div>
                {!holiday.isClosed &&
                  holiday.timeRanges?.map((range, hoursIndex) => (
                    <div key={hoursIndex} className="min-w-72">
                      <div className="flex items-center mb-1">
                        <FormFieldTimeRangeMenu
                          startTime={range.startTime}
                          endTime={range.endTime}
                          onChange={(value) => {
                            handleTimeChange(holidayIndex, hoursIndex, value.startTime, value.endTime);
                          }}
                          validation={
                            holiday.timeRanges
                              ? getMinMaxWorkingHourTime(holiday.timeRanges, hoursIndex)
                              : undefined
                          }
                        />
                        <div className="flex justify-between w-14 ml-5">
                          {holiday.timeRanges && hoursIndex === holiday.timeRanges.length - 1 ? (
                            <ButtonIcon
                              SvgIcon={AddIcon}
                              onClick={() => addTimeInterval(holidayIndex)}
                              theme="primary"
                              tooltip={{ content: "Add Time Slot", theme: "SMALL" }}
                              disabled={isAddTimeDisabled(holidayIndex, hoursIndex)}
                            />
                          ) : null}
                          {hoursIndex !== 0 && (
                            <ButtonIcon
                              SvgIcon={RemoveIcon}
                              onClick={() => removeTimeInterval(holidayIndex, hoursIndex)}
                              theme="primary"
                              tooltip={{ content: "Remove Time Slot", theme: "SMALL" }}
                            />
                          )}
                        </div>
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          )
      )}
    </YearTabs>
  );
};
