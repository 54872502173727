import { FC } from "react";

import { Icon, IconProps } from "@libs/components/UI/Icon";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";

import { getExpiredCDTCodeDate, getExpiredCDTCodeMessage } from "components/ScheduleAppointments/utils";

const themes: Record<string, Pick<IconProps, "SvgIcon" | "theme">> = {
  info: { SvgIcon: InfoIcon, theme: "slate500" },
  error: { SvgIcon: ErrorIcon, theme: "error" },
};

interface Props {
  endDate: string | undefined;
  now: Date;
  theme?: keyof typeof themes;
}

export const ExpiredCDTCodeIcon: FC<Props> = ({ endDate, now, theme = "error" }) => {
  const expiredDate = getExpiredCDTCodeDate(endDate, now);

  return expiredDate ? (
    <Icon {...themes[theme]} tooltip={{ content: getExpiredCDTCodeMessage(expiredDate) }} size="sm" />
  ) : null;
};
