import { getNextPageParam, makeInfiniteQuery, makeQuery } from "@libs/utils/queries";
import { cacheForSession, noCache, oneMinuteCache } from "@libs/utils/queryCache";
import { ClaimsQuery } from "utils/routing/claims";

export interface ApiClaimQueryParams extends Omit<ClaimsQuery, "search" | "tab"> {
  pageNumber: number;
  pageSize: number;
  searchString?: string;
}

export const getInfiniteClaimsQuery = makeInfiniteQuery({
  queryKey: ["practices", "getClaimSummaries"],
  formatParams: ({
    practiceId,
    ...query
  }: { practiceId: number; patientIds?: number[] } & ApiClaimQueryParams) => [practiceId, query],
  queryOptions: { ...oneMinuteCache, getNextPageParam },
});

export const getClaimsTabsTotalsQuery = makeQuery({
  queryKey: ["practices", "getClaimsOverview"],
  formatParams: ({ practiceId, ...query }: { practiceId: number; patientIds?: number[] }) => [
    practiceId,
    query,
  ],
  queryOptions: oneMinuteCache,
});

export const getClaimQuery = makeQuery({
  queryKey: ["practices", "getClaim"],
  formatParams: ({ practiceId, claimUuid }: { practiceId: number; claimUuid: string }) => [
    practiceId,
    claimUuid,
  ],
  queryOptions: oneMinuteCache,
});

export const getPrimaryEobsQuery = makeQuery({
  queryKey: ["practices", "getPrimaryEobs"],
  formatParams: ({ practiceId, appointmentId }: { practiceId: number; appointmentId: number }) => [
    practiceId,
    appointmentId,
  ],
  queryOptions: oneMinuteCache,
});

export const getPracticeClaimsBillingProviders = makeQuery({
  queryKey: ["practices", "getClaimsBillingProviders"],
  formatParams: ({ practiceId }: { practiceId: number }) => [practiceId],
  queryOptions: cacheForSession,
});

export type EligibleClaimFilter = "SUBMITTED" | "COMPLETED" | "PENDING_NON_PRIMARY";
export interface ApiPaymentEligibleClaimsQuery {
  filters: EligibleClaimFilter[];
  insuranceCarrierIds: number[];
  pageNumber: number;
  pageSize: number;
  searchString?: string;
}

export const getInfinitePaymentEligibleClaimSummaries = makeInfiniteQuery({
  queryKey: ["practices", "getPaymentEligibleClaimSummaries"],
  formatParams: ({ practiceId, ...query }: { practiceId: number } & ApiPaymentEligibleClaimsQuery) => [
    practiceId,
    query,
  ],
  queryOptions: { ...oneMinuteCache, getNextPageParam },
});

export const getDraftEobPaymentSummaries = makeQuery({
  queryKey: ["practices", "getDraftEobPaymentSummaries"],
  formatParams: ({ practiceId }: { practiceId: number }) => [practiceId],
  queryOptions: oneMinuteCache,
});

export const getDraftEobPayment = makeQuery({
  queryKey: ["practices", "getDraftEobPayment"],
  formatParams: ({
    practiceId,
    draftEobPaymentUuid,
  }: {
    practiceId: number;
    draftEobPaymentUuid: string;
  }) => [practiceId, draftEobPaymentUuid],
  queryOptions: oneMinuteCache,
});

export const getClaimsByAppointment = makeQuery({
  queryKey: ["practices", "getClaimsByAppointment"],
  formatParams: ({ practiceId, ...query }: { practiceId: number; ids: number[] }) => [practiceId, query],
  queryOptions: oneMinuteCache,
});

export const getClaimsConfigQuery = makeQuery({
  queryKey: ["practices", "getClaimsConfig"],
  formatParams: ({ practiceId }: { practiceId: number }) => [practiceId],
  queryOptions: cacheForSession,
});

export const getReceiverLoginUrlQuery = makeQuery({
  queryKey: ["practices", "getReceiverLoginUrl"],
  formatParams: ({ practiceId }: { practiceId: number }) => [practiceId],
  queryOptions: noCache,
});
