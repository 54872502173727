import { FC, useCallback, useState } from "react";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { getTempId } from "@libs/utils/tempId";
import { DropFileZone } from "components/UI/DropFileZone";
import {
  DocumentUploadManager,
  FileAndDoc,
  ItemWithDocument,
  ItemWithFile,
} from "components/UI/DocumentUploadManager";
import { handleError } from "utils/handleError";
import { getEmployeeOnboardingDocuments } from "api/employee/queries";
import { deleteOnboardingDocument, uploadOnboardingDocument } from "api/documents/mutations";

interface Props {
  practiceId: number;
  employeeId: number;
}

export const UploadDocumentsScreen: FC<Props> = ({ practiceId, employeeId }) => {
  const [{ mutateAsync: uploadDocumentAsync }, { mutateAsync: deleteDocumentAsync }] = useApiMutations([
    uploadOnboardingDocument,
    deleteOnboardingDocument,
  ]);

  const [userFiles, setUserFiles] = useState<FileAndDoc[]>([]);
  const [docInitialized, setDocInitialized] = useState(false);

  const appendUserFile = (fileAndDocToAppend: FileAndDoc) => {
    setUserFiles((prevFileAndDocs) => [...prevFileAndDocs, fileAndDocToAppend]);
  };

  // Only fetched once to initialize the data
  useApiQueries([
    getEmployeeOnboardingDocuments({
      args: { practiceId, employeeId },
      queryOptions: {
        onSuccess: (data) => {
          setUserFiles(
            data.data.data.map((doc) => ({
              document: doc,
              status: "UPLOADED",
              randomId: getTempId(),
            }))
          );
          setDocInitialized(true);
        },
        enabled: !docInitialized,
      },
    }),
  ]);

  const uploadUserFile = useCallback(
    async (fileToUpload: ItemWithFile) => {
      try {
        const response = await uploadDocumentAsync({
          practiceId,
          employeeId,
          data: { document: fileToUpload.file },
        });

        return response.data.data;
      } catch (err) {
        handleError(err);
        throw err;
      }
    },
    [employeeId, practiceId, uploadDocumentAsync]
  );

  const deleteDocument = useCallback(
    async (docToDelete: ItemWithDocument) => {
      try {
        await deleteDocumentAsync({ practiceId, employeeId, documentId: docToDelete.document.id });
      } catch (err) {
        handleError(err);
        throw err;
      }
    },
    [deleteDocumentAsync, employeeId, practiceId]
  );

  return (
    <div className="flex flex-col gap-y-8">
      <div className="text-center">
        <div className="text-primaryTheme text-3xl">General Information</div>
        <div className="text-xs">
          This will help your practice owner and colleagues identify you on the platform.
        </div>
      </div>
      <DropFileZone
        onFilesDropped={(accepted) => {
          accepted.forEach((file) => {
            const fileAndDoc: ItemWithFile = {
              file,
              status: "PENDING",
              randomId: getTempId(),
            };

            appendUserFile(fileAndDoc);
          });
        }}
      />
      <DocumentUploadManager
        filesAndDocs={userFiles}
        filesAndDocsSetter={setUserFiles}
        onDelete={deleteDocument}
        onUpload={uploadUserFile}
      />
    </div>
  );
};
