import { FC } from "react";
import { useLocation, Link } from "react-router-dom";
import { ScheduleBlockVO } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import {
  ChangeMode,
  RecurringBlockModal,
  RecurringBlockModalProps,
} from "components/ScheduleAppointments/RecurringBlockModal";
import { AppointmentGrouping } from "utils/routing/scheduling";
import { paths } from "utils/routing/paths";
import {
  deleteScheduleBlock,
  deleteRecurringScheduleBlockFuture,
  deleteRecurringScheduleBlockInstance,
} from "api/scheduling/mutations";
import { handleError } from "utils/handleError";

interface Props {
  block: ScheduleBlockVO;
  groupedBy: AppointmentGrouping;
  onConfirm: Func;
  onRequestClose: RecurringBlockModalProps["onClose"];
}

export const DeleteRecurringBlockModal: FC<Props> = ({ block, groupedBy, onRequestClose, onConfirm }) => {
  const { practiceId } = useAccount();
  const location = useLocation();
  const hasMultipleBlocks = block.providers.length + block.rooms.length > 1;
  const [
    deleteScheduleBlockMutation,
    deleteRecurringScheduleBlockFutureMutation,
    deleteRecurringScheduleBlockInstanceMutation,
  ] = useApiMutations([
    deleteScheduleBlock,
    deleteRecurringScheduleBlockFuture,
    deleteRecurringScheduleBlockInstance,
  ]);

  const handleConfirmDelete = (changeMode: ChangeMode) => {
    const mutateOptions = { onSuccess: onConfirm, onError: handleError };

    if (changeMode === "CHANGE_ONE") {
      deleteRecurringScheduleBlockInstanceMutation.mutate(
        {
          practiceId,
          blockId: block.id,
          instanceDate: block.date,
        },
        mutateOptions
      );
    } else if (changeMode === "CHANGE_FUTURE") {
      deleteRecurringScheduleBlockFutureMutation.mutate(
        {
          practiceId,
          blockId: block.id,
          instanceDate: block.date,
        },
        mutateOptions
      );
    } else {
      deleteScheduleBlockMutation.mutate(
        {
          practiceId,
          blockId: block.id,
        },
        mutateOptions
      );
    }
  };

  return (
    <RecurringBlockModal
      title="Delete Recurring Block"
      isSaving={
        deleteRecurringScheduleBlockInstanceMutation.isLoading ||
        deleteRecurringScheduleBlockFutureMutation.isLoading ||
        deleteScheduleBlockMutation.isLoading
      }
      onClose={onRequestClose}
      onCancel={onRequestClose}
      onConfirm={handleConfirmDelete}
    >
      {hasMultipleBlocks ? (
        <div className="mb-6 text-xs">
          To remove the time block for this {groupedBy} only,
          <Link
            className="text-primaryTheme px-1"
            to={paths.editBlock(
              { blockId: block.id, instanceDate: block.date },
              { from: getFullUrl(location) }
            )}
          >
            click here
          </Link>
          to edit.
        </div>
      ) : null}
    </RecurringBlockModal>
  );
};
