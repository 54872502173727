import { FC } from "react";
import { format, parseISO } from "date-fns";
import { PublicHolidayVO, TimeRangeVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { getLocalDate } from "@libs/utils/date";
import { SettingsLabel, SettingsValue } from "@libs/components/UI/SettingsComponents";
import { YearTabs } from "components/Settings/YearTabs";

const cxStyles = {
  holidayField: `
    grid
    gap-11
    w-full
    mb-2.5
    grid-cols-[50px_50px_80px_200px_65px_65px]
  `,
};

const Row: FC<{ holiday: PublicHolidayVO; timeRange?: TimeRangeVO; index: number }> = ({
  holiday,
  timeRange,
  index,
}) => {
  return (
    <div className={cxStyles.holidayField}>
      {index === 0 ? (
        <>
          <SettingsValue>{holiday.isClosed ? "Closed" : "Open"}</SettingsValue>
          <SettingsValue>{format(parseISO(holiday.date), "MMM dd")}</SettingsValue>
          <SettingsValue>{format(parseISO(holiday.date), "EEEE")}</SettingsValue>
          <SettingsValue>{holiday.description}</SettingsValue>
        </>
      ) : (
        <>
          {[..."1234"].map((value) => (
            <SettingsValue key={value} />
          ))}
        </>
      )}
      <SettingsValue>
        {timeRange?.startTime && holiday.date
          ? format(getLocalDate(holiday.date, timeRange.startTime), "hh:mm aaa")
          : "-"}
      </SettingsValue>
      <SettingsValue>
        {timeRange?.startTime && holiday.date
          ? format(getLocalDate(holiday.date, timeRange.endTime), "hh:mm aaa")
          : "-"}
      </SettingsValue>
    </div>
  );
};

export const UsHolidays: FC<{
  selectedYear: string;
  publicHolidays: PublicHolidayVO[];
  onYearUpdate: (newYear: string) => void;
}> = ({ selectedYear, publicHolidays, onYearUpdate }) => {
  return (
    <YearTabs selectedTab={selectedYear} onSelect={onYearUpdate}>
      <div className={cx("col-10 mt-2.5", cxStyles.holidayField)}>
        <SettingsValue />
        <SettingsLabel>Date</SettingsLabel>
        <SettingsLabel>Day</SettingsLabel>
        <SettingsLabel>Description</SettingsLabel>
        <SettingsLabel>Open From</SettingsLabel>
        <SettingsLabel>Open Until</SettingsLabel>
      </div>
      {publicHolidays.map((holiday, index) =>
        holiday.timeRanges ? (
          holiday.timeRanges.map((timeRange, timeRangeIndex) => (
            <Row
              key={`${holiday.id ?? ""}${timeRangeIndex}`}
              holiday={holiday}
              timeRange={timeRange}
              index={timeRangeIndex}
            />
          ))
        ) : (
          <Row key={`${holiday.id ?? ""}${index}`} holiday={holiday} index={0} />
        )
      )}
    </YearTabs>
  );
};
