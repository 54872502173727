import React from "react";
import { EmployeeVO } from "@libs/api/generated-api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { PersonalDetailsPanel } from "components/EmployeeProfile/PersonalDetails/PersonalDetailsPanel";
import { PersonalDetailsForm } from "components/EmployeeProfile/PersonalDetails/PersonalDetailsForm";

interface EditPersonalDetailsProps {
  employee: EmployeeVO;
  onBeginEditing: Func;
}

export const ViewPersonalDetails: React.FC<EditPersonalDetailsProps> = ({ employee, onBeginEditing }) => {
  return (
    <PersonalDetailsPanel
      actions={
        <ButtonIcon
          SvgIcon={EditIcon}
          tooltip={{ content: "Edit Settings", theme: "SMALL" }}
          onClick={onBeginEditing}
          theme="primary"
        />
      }
    >
      <PersonalDetailsForm serverErrors={{}} isEditing={false} employee={employee} />
    </PersonalDetailsPanel>
  );
};
