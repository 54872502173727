import { useBoolean } from "@libs/hooks/useBoolean";
import React from "react";
import { ReactComponent as SwatchIcon } from "@libs/assets/icons/square-filled.svg";
import { ReactComponent as FreehandIcon } from "@libs/assets/icons/freehand.svg";
import { ReactComponent as CircleIcon } from "@libs/assets/icons/circle.svg";
import { ReactComponent as LineIcon } from "@libs/assets/icons/line.svg";
import { ReactComponent as MoveIcon } from "@libs/assets/icons/move.svg";
import { ReactComponent as AngleIcon } from "@libs/assets/icons/angle.svg";
import { ReactComponent as DistanceIcon } from "@libs/assets/icons/distance.svg";
import { ReactComponent as BrushIcon } from "@libs/assets/icons/brush.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { isOneOf } from "@libs/utils/isOneOf";
import { ToolBoat } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ToolBoat";
import { ToolbarButton } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ToolbarButton";
import { RangeAdjuster } from "components/PatientProfile/Imaging/ImageEditor/Tools/RangeAdjuster";
import { useImageEditorContext } from "components/PatientProfile/Imaging/ImageEditor/ImageEditorContext";
import { ColorPalette } from "components/PatientProfile/Imaging/ImageEditor/Tools/ColorPalette";
import { DrawSettings } from "components/PatientProfile/Imaging/types/imageEditor";
import { useToggleImageEditorLayers } from "components/PatientProfile/Imaging/ImageEditor/Tools/useToggleImageEditorLayers";

export const DrawingToolBoats: React.FC<{
  onMenuToggled?: (open: boolean) => void;
  disabledMessage?: string;
  // eslint-disable-next-line complexity
}> = ({ onMenuToggled, disabledMessage }) => {
  const brushMenuOpen = useBoolean(false);
  const swatchMenuOpen = useBoolean(false);
  const {
    editor: imageEditor,
    drawSettings,
    setDrawSettings,
    imageSelected,
    imageSensorSetting,
    handleImageUpdate,
    handleAnnotation,
  } = useImageEditorContext();
  const { drawMode, mouseMode, brushSize, color: swatchColor } = drawSettings;
  const { toggleLayer } = useToggleImageEditorLayers();

  React.useEffect(() => {
    if (imageEditor.current) {
      const changeHandler = imageEditor.current.canvasChangeHandler;

      changeHandler.current = handleAnnotation;
    }
  }, [imageEditor, handleImageUpdate, handleAnnotation]);

  const handleChangeBrushsize: React.ChangeEventHandler<HTMLInputElement> = React.useCallback(
    (e) => {
      const newBrushSize = e.target.valueAsNumber;

      setDrawSettings(() => ({
        brushSize: newBrushSize,
      }));

      const canvas = imageEditor.current?.getInstance();

      if (canvas) {
        const active = canvas.getActiveObjects();

        for (const obj of active) {
          obj.set("strokeWidth", newBrushSize);
        }

        if (active.length > 0) {
          canvas.requestRenderAll();
          handleAnnotation();
        }
      }
    },
    [setDrawSettings, imageEditor, handleAnnotation]
  );
  const handleNewDrawSettings = React.useCallback(
    (settings: Partial<Pick<DrawSettings, "drawMode" | "mouseMode" | "pixelsPerMM">>) => {
      const canvas = imageEditor.current?.getInstance();

      canvas?.discardActiveObject();

      const newValues = { ...settings };

      setDrawSettings((prior) => {
        if (
          (prior.mouseMode === "select" && settings.mouseMode === "select") ||
          (settings.drawMode === prior.drawMode && settings.mouseMode === prior.mouseMode)
        ) {
          newValues.drawMode = undefined;
          newValues.mouseMode = undefined;
        }

        if (newValues.drawMode || newValues.mouseMode) {
          toggleLayer("annotations", true);
        }

        return newValues;
      });
    },
    [imageEditor, setDrawSettings, toggleLayer]
  );
  const sharedProps = { disabled: !imageSelected };
  const isDrawMode = Boolean(mouseMode === "draw" && imageSelected);

  const distanceDisabled = !imageSensorSetting?.calibration?.pixelsPerMM;

  return (
    <>
      <ToolBoat>
        <ToolbarButton
          {...sharedProps}
          tooltip={disabledMessage ?? "Free Draw (F)"}
          toggled={drawMode === "free_draw" && isDrawMode}
          onClick={() => handleNewDrawSettings({ drawMode: "free_draw", mouseMode: "draw" })}
          SvgIcon={FreehandIcon}
        />
        <ToolbarButton
          {...sharedProps}
          tooltip={disabledMessage ?? "Draw Line (X)"}
          toggled={drawMode === "line" && isDrawMode}
          onClick={() => handleNewDrawSettings({ drawMode: "line", mouseMode: "draw" })}
          SvgIcon={LineIcon}
        />
        <ToolbarButton
          {...sharedProps}
          tooltip={disabledMessage ?? "Draw Ellipse (E)"}
          toggled={drawMode === "ellipse" && isDrawMode}
          onClick={() => handleNewDrawSettings({ drawMode: "ellipse", mouseMode: "draw" })}
          SvgIcon={CircleIcon}
        />
        <ToolbarButton
          {...sharedProps}
          toggled={drawMode === "distance" && isDrawMode}
          disabled={distanceDisabled}
          tooltip={
            disabledMessage ??
            (imageSensorSetting?.calibration?.pixelsPerMM
              ? "Draw Distance (D)"
              : imageSelected?.sensor
                ? "Draw Distance - Sensor requires calibration"
                : "Draw Distance - Please assign a sensor to this image to use distance measurement")
          }
          onClick={() =>
            handleNewDrawSettings({
              drawMode: "distance",
              mouseMode: "draw",
              pixelsPerMM: imageSensorSetting?.calibration?.pixelsPerMM,
            })
          }
          SvgIcon={DistanceIcon}
        />
        <ToolbarButton
          {...sharedProps}
          tooltip={disabledMessage ?? "Draw Angle (A)"}
          toggled={drawMode === "angle" && isDrawMode}
          onClick={() => handleNewDrawSettings({ drawMode: "angle", mouseMode: "draw" })}
          SvgIcon={AngleIcon}
        />
        <ToolbarButton
          {...sharedProps}
          tooltip={disabledMessage ?? "Select / Move (S)"}
          toggled={Boolean(mouseMode === "select" && imageSelected)}
          onClick={() => handleNewDrawSettings({ mouseMode: "select" })}
          SvgIcon={MoveIcon}
        />
      </ToolBoat>
      <ToolBoat>
        <ButtonMenu
          menuContent={
            <div className="px-4 py-2">
              <RangeAdjuster
                defaultValue={brushSize}
                value={brushSize}
                min={1}
                max={20}
                step={1}
                onChange={handleChangeBrushsize}
              />
            </div>
          }
          placement="right-end"
          isOpen={brushMenuOpen.isOn}
          onRequestClose={() => {
            brushMenuOpen.off();
            onMenuToggled?.(false);
          }}
          onRequestOpen={() => {
            brushMenuOpen.on();
            onMenuToggled?.(true);
          }}
        >
          {(props) => (
            <ToolbarButton
              {...props}
              tooltip={disabledMessage ?? "Brush Size"}
              disabled={
                sharedProps.disabled || !drawMode || !isOneOf(drawMode, ["free_draw", "line", "ellipse"])
              }
              SvgIcon={BrushIcon}
            />
          )}
        </ButtonMenu>

        <ButtonMenu
          menuContent={<ColorPalette />}
          placement="right-end"
          isOpen={swatchMenuOpen.isOn}
          onRequestClose={swatchMenuOpen.off}
          onRequestOpen={swatchMenuOpen.on}
        >
          {(props) => (
            <ToolbarButton
              {...props}
              {...sharedProps}
              tooltip={disabledMessage ?? "Color Swatch"}
              iconStyle={{ color: swatchColor }}
              SvgIcon={SwatchIcon}
              size="md"
            />
          )}
        </ButtonMenu>
      </ToolBoat>
    </>
  );
};
