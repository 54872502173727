import { FC } from "react";

import { Banner } from "@libs/components/UI/Banner";

export const SendCountExceededBanner: FC = () => {
  return (
    <Banner className="w-full rounded text-xs" theme="info">
      Selected patients exceeds the maximum number of messages that can be sent at once.
    </Banner>
  );
};
