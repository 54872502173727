import React, { FC } from "react";
import { ClaimVO, LedgerV2ClaimSubEntryVO } from "@libs/api/generated-api";
import { currencyValueOrDash, formatCurrency, formatCurrencyAsDelta } from "@libs/utils/currency";
import { getLocalDate } from "@libs/utils/date";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import {
  AccordionItem,
  PracticeAndPatientIds,
} from "components/PatientProfile/Billing/Ledger/LedgerComponents";
import { DescriptionSticker } from "components/PatientProfile/Billing/Ledger/DescriptionSticker";
import { getClaimPaymentMethod } from "components/Claim/PaymentsSection";
import { ClaimStateAndPaymentStatusPill } from "./ClaimStateAndPaymentStatusPill";
import { ClaimMenu } from "./ClaimMenu";
import { Text } from "./Text";
import { isClaimCompleted, ledgerSubEntryToId } from "./ledgerUtils";
import { usePersistExpandRow } from "./usePersistExpandRow";
import { DetailsTable } from "./DetailsTable";
import {
  ExpandableRow,
  DetailsRow,
  EobPaymentTableHeader,
  EobPaymentTableRow,
  TableRow,
  ProcedureDoubleColumnTableRow,
  DoubleColumnTableRow,
  DescriptionWithPatientInsuranceRow,
} from "./AppointmentsAndAdjustmentsPage";
import { ProviderInitials } from "./ProviderInitials";

export const ClaimExpandable: FC<PracticeAndPatientIds & { claimSubEntry: LedgerV2ClaimSubEntryVO }> = ({
  claimSubEntry,
}) => {
  const expandRow = usePersistExpandRow(ledgerSubEntryToId(claimSubEntry));

  return (
    <AccordionItem>
      <ExpandableRow
        itemId={ledgerSubEntryToId(claimSubEntry)}
        indentation={2}
        date={
          <Text bold>
            <DateWithTooltip date={getLocalDate(claimSubEntry.timestamp)} format="P" />
          </Text>
        }
        details={
          <DescriptionWithPatientInsuranceRow
            descriptionColumn={
              <ClaimDescription
                claimNumber={claimSubEntry.claimNumber}
                insuranceOrdinal={claimSubEntry.insuranceOrdinal}
                insuranceAmount={claimSubEntry.insuranceAmount}
              />
            }
            patientColumn={undefined}
            insuranceColumn={<ClaimStateAndPaymentStatusPill claimSubEntry={claimSubEntry} />}
          />
        }
        amount={
          <Text
            color={isClaimCompleted(claimSubEntry) ? "green" : undefined}
            bold={isClaimCompleted(claimSubEntry)}
          >
            {currencyValueOrDash(claimSubEntry.collectionAmount)}
          </Text>
        }
        outstanding={undefined}
        menu={<ClaimMenu claimUuid={claimSubEntry.claimUuid} />}
        expandedContent={<ClaimExpanded claimSubEntry={claimSubEntry} />}
        isExpanded={expandRow.isOn}
        onToggleExpand={expandRow.toggle}
      />
    </AccordionItem>
  );
};

const ClaimDescription: FC<{
  claimNumber: ClaimVO["claimNumber"];
  insuranceOrdinal: ClaimVO["insuranceOrdinal"];
  insuranceAmount: ClaimVO["insuranceAmount"];
}> = ({ claimNumber, insuranceOrdinal, insuranceAmount }) => {
  return (
    <div className="flex items-center gap-x-2">
      <DescriptionSticker color="claim" />
      <Text>
        <Text bold>
          Claim ({sentenceCaseConstant(insuranceOrdinal)}) #{claimNumber}:
        </Text>{" "}
        {formatCurrency(insuranceAmount)}
      </Text>
    </div>
  );
};

const ClaimProceduresTableHeader: FC<{ claimSubEntry: LedgerV2ClaimSubEntryVO }> = ({ claimSubEntry }) => {
  return (
    <DoubleColumnTableRow
      description={<Text bold>Procedures</Text>}
      patientCol1={undefined}
      patientCol2={undefined}
      insuranceCol1={isClaimCompleted(claimSubEntry) ? <Text bold>Ins Est</Text> : undefined}
      insuranceCol2={isClaimCompleted(claimSubEntry) ? <Text bold>Ins Pay</Text> : <Text bold>Ins Est</Text>}
    />
  );
};

const ClaimExpanded: FC<{ claimSubEntry: LedgerV2ClaimSubEntryVO }> = ({ claimSubEntry }) => {
  return (
    <>
      <DetailsRow indentation={2}>
        <DetailsTable>
          <InsuranceCarrier claimSubEntry={claimSubEntry} />
        </DetailsTable>
      </DetailsRow>
      <DetailsRow indentation={2}>
        <DetailsTable>
          <ClaimPayments claimSubEntry={claimSubEntry} />
        </DetailsTable>
      </DetailsRow>
      <DetailsRow indentation={2}>
        <DetailsTable>
          <ClaimProcedures claimSubEntry={claimSubEntry} />
        </DetailsTable>
      </DetailsRow>
    </>
  );
};

const ClaimPayments: FC<{ claimSubEntry: LedgerV2ClaimSubEntryVO }> = ({ claimSubEntry }) => {
  return claimSubEntry.payments.length ? (
    <>
      <EobPaymentTableHeader />
      {claimSubEntry.payments.map((claimPayment) => (
        <EobPaymentTableRow
          key={claimPayment.payment.uuid}
          paymentDate={
            <Text>
              <DateWithTooltip date={claimPayment.payment.paymentCreatedAt} dateAsSeconds format="P" />
            </Text>
          }
          paymentDescription={<Text>By {getClaimPaymentMethod(claimPayment.payment.method)}</Text>}
          paymentAmount={
            <Text color="green" bold>
              {formatCurrency(-claimPayment.insuranceAmount)}
            </Text>
          }
        />
      ))}
    </>
  ) : (
    <TableRow
      descriptionColumn={
        <Text>
          <i>No Payments</i>
        </Text>
      }
      patientColumn={undefined}
      insuranceColumn={undefined}
    />
  );
};

const ClaimProcedures: FC<{ claimSubEntry: LedgerV2ClaimSubEntryVO }> = ({ claimSubEntry }) => {
  const hasTotalsRow = claimSubEntry.procedures.length > 1;
  const amountChange = claimSubEntry.insuranceAmount - claimSubEntry.insuranceEstimatedAmount;

  return (
    <>
      <ClaimProceduresTableHeader claimSubEntry={claimSubEntry} />
      {claimSubEntry.procedures.map((procedure) => (
        <ProcedureDoubleColumnTableRow
          key={procedure.patientProcedureId}
          providerInitials={<ProviderInitials provider={procedure.provider} />}
          cdtCode={<Text>{procedure.cdtCode}</Text>}
          toothName={<Text>{procedure.procedureArea}</Text>}
          procedureName={<Text>{procedure.procedureName}</Text>}
          patientCol1={undefined}
          patientCol2={undefined}
          insuranceCol1={
            isClaimCompleted(claimSubEntry) ? (
              <Text>{formatCurrency(procedure.insuranceEstimatedAmount)}</Text>
            ) : undefined
          }
          insuranceCol2={
            isClaimCompleted(claimSubEntry) ? (
              <Text background={hasTotalsRow ? undefined : "orange"}>
                {formatCurrency(procedure.insuranceAmount)}
              </Text>
            ) : (
              <Text>{formatCurrency(procedure.insuranceEstimatedAmount)}</Text>
            )
          }
        />
      ))}
      {hasTotalsRow && (
        <ProcedureDoubleColumnTableRow
          providerInitials={undefined}
          cdtCode={undefined}
          toothName={undefined}
          procedureName={undefined}
          patientCol1={undefined}
          patientCol2={isClaimCompleted(claimSubEntry) && <Text bold>Total</Text>}
          insuranceCol1={
            isClaimCompleted(claimSubEntry) ? (
              <Text bold>{formatCurrency(claimSubEntry.insuranceEstimatedAmount)}</Text>
            ) : (
              <Text bold>Total</Text>
            )
          }
          insuranceCol2={
            isClaimCompleted(claimSubEntry) ? (
              <Text
                bold
                background={
                  claimSubEntry.paymentStatus === "UNDERPAID"
                    ? "red"
                    : claimSubEntry.paymentStatus === "OVERPAID"
                      ? "orange"
                      : claimSubEntry.paymentStatus === "PAID_AS_EXPECTED"
                        ? "green"
                        : undefined
                }
              >
                {formatCurrency(claimSubEntry.insuranceAmount)}
              </Text>
            ) : (
              <Text bold>{formatCurrency(claimSubEntry.insuranceEstimatedAmount)}</Text>
            )
          }
        />
      )}
      {isClaimCompleted(claimSubEntry) && (
        <ProcedureDoubleColumnTableRow
          providerInitials={undefined}
          cdtCode={undefined}
          toothName={undefined}
          procedureName={undefined}
          patientCol1={undefined}
          patientCol2={undefined}
          insuranceCol1={undefined}
          insuranceCol2={
            amountChange !== 0 && (
              <Text bold color="orange">
                {formatCurrencyAsDelta(amountChange)}
              </Text>
            )
          }
        />
      )}
    </>
  );
};

const InsuranceCarrier: FC<{ claimSubEntry: LedgerV2ClaimSubEntryVO }> = ({ claimSubEntry }) => {
  return (
    <TableRow
      descriptionColumn={<Text>{claimSubEntry.insuranceCarrier}</Text>}
      patientColumn={undefined}
      insuranceColumn={undefined}
    />
  );
};
