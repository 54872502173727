import React from "react";
import { useDrag } from "react-dnd";
import { getEmptyImage } from "react-dnd-html5-backend";
import { useDebouncedCallback } from "use-debounce";
import { useTransformEffect } from "react-zoom-pan-pinch";
import { cx } from "@libs/utils/cx";

import { noop } from "@libs/utils/noop";
import { calculateOutlineSize } from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { ImageViewMetadata } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ImageViewMetadata";
import { ImageDetailProps } from "components/PatientProfile/Imaging/MountRoute/types";
import { ImageCapturePlaceholder } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ImageCapturePlaceholder";
import { SANDBOX_NAVIGATION } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/constants";
import { ImageWithBottomAttachment } from "components/PatientProfile/Imaging/ImageWithBottomAttachment";
import { useSandboxBounds } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/hooks/useSandboxBounds";
import { ImageUploading } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ImageUploading";
import { ImageLayoutItem, ImageWithZoom, ReservedStatus, isReservedLayoutItem } from "./types";

export type ImageWrapperComponentTypeProps<T = unknown> = {
  isSelected: boolean;
  isDragging: boolean;
  image: ImageLayoutItem;
  children: React.ReactElement;
  scale: number;
} & T;

export type ImageWrapperComponentType<T = unknown> = React.ComponentType<ImageWrapperComponentTypeProps<T>>;

type Props = {
  isNextUpload: boolean;
  scale: number;
  image: ImageLayoutItem;
  onClickItem: (image: ImageLayoutItem) => void;
  ImageWrapper?: ImageWrapperComponentType;
  isSelected: boolean;
} & ImageDetailProps;

const getStyles = (wasDragging: boolean, image: ImageLayoutItem) => {
  const TOP_Z = 100;

  return {
    left: image.x,
    top: image.y,
    ...(image.url ? {} : { backgroundColor: "#262422", width: image.sandbox.w, height: image.sandbox.h }),
    opacity: 1,
    zIndex: wasDragging ? TOP_Z : undefined,
  };
};
const DRAG_Z_ALIGN_DELAY = 1500;
const useDragDrop = (params: { image: ImageLayoutItem }) => {
  const { image } = params;
  const [wasDragging, setWasDragging] = React.useState(false);
  const [zoomScale, setZoomScale] = React.useState(1);

  useTransformEffect((context) => setZoomScale(context.state.scale));

  const imageWithZoom: ImageWithZoom = React.useMemo(
    () => ({
      image,
      zoomScale,
    }),
    [image, zoomScale]
  );
  const [{ isDragging }, dragRef, preview] = useDrag<ImageWithZoom, unknown, { isDragging: boolean }>(
    () => ({
      type: SANDBOX_NAVIGATION,
      item: imageWithZoom,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [imageWithZoom]
  );

  React.useEffect(() => {
    preview(getEmptyImage(), { captureDraggingState: true });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [imageWithZoom]);

  const reset = useDebouncedCallback(
    () => {
      setWasDragging(false);
    },
    DRAG_Z_ALIGN_DELAY,
    { leading: false, trailing: true }
  );

  React.useEffect(() => {
    if (isDragging) {
      setWasDragging(true);
    } else {
      reset();
    }
  }, [isDragging, reset]);

  return {
    wasDragging,
    dragRef,
    isDragging,
  };
};

export const getImageElementId = (imageId?: number) => `image-${imageId}`;

export const ImageItem: React.FC<Props> = ({
  image,
  isNextUpload,
  onImageUpdate,
  onEditImage,
  ImageWrapper,
  isShowingMetadata = false,
  scale,
  isSelected,
  devices,
  onClickItem,
  onClickTeeth,
}) => {
  const { dragRef, wasDragging, isDragging } = useDragDrop({
    image,
  });
  const styles = getStyles(wasDragging, image);

  const bounds = useSandboxBounds(image);

  if (isReservedLayoutItem(image) && image.reservation.status !== ReservedStatus.None) {
    return (
      <div className="absolute rounded select-none" style={styles}>
        <ImageUploading image={image} bounds={bounds}>
          <ImageViewMetadata image={image} devices={devices} onClickTeeth={noop} disabled={true} />
        </ImageUploading>
      </div>
    );
  }

  const imageDomId = getImageElementId(image.id);

  if (!image.url || !image.id) {
    return (
      <ImageCapturePlaceholder
        style={styles}
        className="absolute rounded"
        index={image.i}
        name={image.name}
        onClick={() => onClickItem(image)}
        isNextUpload={isNextUpload}
        id={imageDomId}
      />
    );
  }

  const MoveableImage = (
    <ImageWithBottomAttachment
      image={image}
      bounds={bounds}
      id={imageDomId}
      onEditImage={onEditImage}
      onClickImage={onClickItem}
      actionsEnabled={!ImageWrapper}
      style={{
        outlineWidth: calculateOutlineSize(scale),
      }}
      className={cx("object-contain outline-primaryTheme hover:outline rounded", isSelected && "outline")}
    >
      {isShowingMetadata && (
        <ImageViewMetadata
          image={image}
          onImageUpdate={onImageUpdate}
          devices={devices}
          onClickTeeth={onClickTeeth}
        />
      )}
    </ImageWithBottomAttachment>
  );

  return (
    <div
      className={cx("absolute rounded select-none", isDragging && "opacity-0")}
      style={styles}
      ref={dragRef}
    >
      {ImageWrapper ? (
        <ImageWrapper isSelected={false} isDragging={isDragging} image={image} scale={scale}>
          {MoveableImage}
        </ImageWrapper>
      ) : (
        MoveableImage
      )}
    </div>
  );
};
