import { useCallback, useEffect, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { ClaimInsuranceCarrierVO } from "@libs/api/generated-api";
import { SEARCH_DEBOUNCE_DELAY_MS } from "@libs/utils/constants";
import { Button } from "@libs/components/UI/Button";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Form } from "@libs/components/UI/Form";
import { filtersCxStyles } from "components/Claims/Claims/Filters";
import { ClaimsQuery, ClaimsQueryUpdates } from "utils/routing/claims";

interface Props {
  insuranceCarriers: ClaimInsuranceCarrierVO[] | undefined;
  onFiltersUpdate: (keyValues: ClaimsQueryUpdates) => void;
  onRequestClose: Func;
  queryStateApi: ClaimsQuery;
}

export const FilterMenuInsuranceCarriers: React.FC<Props> = ({
  insuranceCarriers,
  onFiltersUpdate,
  onRequestClose,
  queryStateApi,
}) => {
  const [selectedCarriers, setSelectedCarriers] = useState(new Set(queryStateApi.insuranceCarrierIds));

  const insuranceCarrierOptions = getInsuranceCarriersOptions(insuranceCarriers);
  const insuranceSearchValue = useRef("");
  const [insuranceOptions, setInsuranceOptions] = useState<
    | {
        label: string;
        value: number;
      }[]
    | null
  >(null);

  const filterInsurances = useCallback(() => {
    setInsuranceOptions(
      insuranceCarrierOptions.filter((option) =>
        option.label.toLowerCase().includes(insuranceSearchValue.current.toLowerCase())
      )
    );
  }, [insuranceCarrierOptions]);

  useEffect(() => {
    if (!insuranceOptions) {
      filterInsurances();
    }
  }, [filterInsurances, insuranceOptions]);

  const debouncedSearch = useDebouncedCallback(filterInsurances, SEARCH_DEBOUNCE_DELAY_MS, {
    leading: true,
  });

  const handleInsuranceSearch = useCallback(
    (searchString: string) => {
      insuranceSearchValue.current = searchString;
      debouncedSearch();
    },
    [debouncedSearch]
  );

  const handleApplyFilter = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onFiltersUpdate({ insuranceCarrierIds: [...selectedCarriers] });
      onRequestClose();
    },
    [selectedCarriers, onFiltersUpdate, onRequestClose]
  );

  return (
    <Form className={filtersCxStyles.menuWrapper} onSubmit={handleApplyFilter}>
      <FormFieldInput
        className="px-4 mb-3"
        Icon={SearchIcon}
        onChange={(event) => handleInsuranceSearch(event.target.value)}
      />
      <CheckboxList
        className={filtersCxStyles.menuContent}
        layout="vert"
        onChange={(newSet) => setSelectedCarriers(newSet)}
        options={insuranceOptions || []}
        selectedValues={selectedCarriers}
      />
      <div className="mt-2 px-4">
        <Button className="w-full" type="submit">
          Apply Filters
        </Button>
      </div>
    </Form>
  );
};

export const getInsuranceCarriersOptions = (insuranceCarriers: ClaimInsuranceCarrierVO[] | undefined) => {
  if (!insuranceCarriers) {
    return [];
  }

  return insuranceCarriers.map((carrier) => {
    return { label: carrier.name, value: carrier.insuranceCarrierId };
  });
};
