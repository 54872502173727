import { ComponentPropsWithoutRef, FC, PropsWithChildren, ReactNode } from "react";
import { cx } from "@libs/utils/cx";

type Theme = "SMALL" | "LARGE";

const cxStyles = {
  balanceBox: ({ hasTwoIcons }: { hasTwoIcons: boolean }) =>
    cx(
      "relative grid gap-x-4 text-xs h-fit",
      hasTwoIcons ? "grid-cols-[auto_1fr_auto]" : "grid-cols-[auto_1fr]"
    ),
  balanceIcon: "place-self-center w-6 row-span-2",
  balanceAmount: (theme: Theme) =>
    cx("flex gap-2 items-center font-sansSemiBold", theme === "LARGE" ? "text-xl" : "text-sm"),
};

type Props = ComponentPropsWithoutRef<"div"> & {
  leftIcon: ReactNode;
  rightIcon?: ReactNode;
  amount: ReactNode;
  label: ReactNode;
  theme: Theme;
  testid?: boolean;
} & PropsWithChildren;

export const BalanceBox: FC<Props> = ({ leftIcon, amount, label, theme, rightIcon, className, children }) => {
  return (
    <div className={cx(cxStyles.balanceBox({ hasTwoIcons: Boolean(rightIcon) }), className)}>
      <div className={cxStyles.balanceIcon}>{leftIcon}</div>
      <div>{label}</div>
      {rightIcon && <div className={cxStyles.balanceIcon}>{rightIcon}</div>}
      <div className={cxStyles.balanceAmount(theme)}>
        {amount}
        {children}
      </div>
    </div>
  );
};
