import React from "react";

import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as InboxIcon } from "@libs/assets/icons/inbox.svg";

export const ScheduleHoldsButton: React.FC<{
  count?: number;
  onToggleShowHolds: Func;
}> = ({ count = 0, onToggleShowHolds }) => {
  return (
    <FloatingTooltip content="Appointment Holds" theme="SMALL">
      <button type="button" className="flex items-center gap-1" onClick={onToggleShowHolds}>
        <Icon SvgIcon={InboxIcon} />
        <div className="text-sm font-sansSemiBold">{count > 0 ? count : ""}</div>
      </button>
    </FloatingTooltip>
  );
};
