import { useEffect, createContext, PropsWithChildren, useMemo, useRef, useContext, useCallback } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { captureException as sentryCaptureException } from "@sentry/react";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { PathConfig, isParamParseError } from "@libs/router/url";
import { useAccount } from "@libs/contexts/AccountContext";
import { noop } from "@libs/utils/noop";
import { keys } from "@libs/utils/object";
import { useEnvContext } from "contexts/EnvContext";
import { routesConfig } from "utils/routing/paths";
import { CurrentUser } from "contexts/CurrentUserContext";
import { getSegmentAnalytics } from "utils/segment";

/*
  PracticeSegmentContext is the logger for all logged-in practice users. To
  log events, you can use the `usePracticeLogger` hook, which returns a function `track` that
  triggers a segment track with a pre-generated properties related to the current practice/user.
*/

type AppDomain = "Settings" | "Employees";
type TrackFn = (
  eventParams: {
    event: string;
    domains?: AppDomain[];
  },
  options?: { properties?: object; callback?: () => void }
) => void;
type SegmentOptions = {
  user: CurrentUser;
  practice?: PracticeInfoVO;
};

const getPathnameMatch = (pathname: string) => {
  for (const key of keys(routesConfig)) {
    const route = routesConfig[key];
    const match = matchPath(route.path, pathname);

    if (match && "params" in route) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      const params: PathConfig<any> = route.params;

      return { path: match.pattern.path, params: match.params, routeConfigParams: params };
    }
  }

  return undefined;
};
const logPageView = (maskedPathName: string, properties?: Record<string, unknown>) => {
  const analytics = getSegmentAnalytics();

  if (!analytics) {
    return;
  }

  analytics.page(maskedPathName, {
    // Never log the page title as it may contain PII, and is hard to post-process because it's an ambiguous value
    title: "",
    ...properties,
  });
};

type BaseEventProperties = {
  userId: number;
  practiceId: number;
  practice: {
    practiceUuid: string;
    phoneNumber: string | undefined;
    email: string;
    doingBusinessAs: string;
    practiceTimezoneId: string;
    ownerName: string | undefined;
    id: number;
  };
  user: {
    firstName: string;
    lastName: string;
    role: string;
    workEmail: string;
  };
  employee?: {
    status: "PENDING" | "ACTIVE" | "INACTIVE" | "ARCHIVED" | "FURLOUGHED" | undefined;
    jobTitle?: string;
  };
};

const logPathChange = ({
  pathname,
  eventProperties: baseEventProperties,
}: {
  pathname: string;
  eventProperties?: BaseEventProperties;
}) => {
  const match = getPathnameMatch(pathname);

  if (!match) {
    logPageView(pathname);

    return;
  }

  let loggedPath = match.path; // Start with logging /patient/:patientId/:tab
  const eventProperties: Record<string, unknown> = { ...match.params, archy: baseEventProperties };

  for (const [paramName, paramValue] of Object.entries(match.params)) {
    const paramConfig = match.routeConfigParams[paramName];
    const paramStringValue = paramValue as string;
    const wildcard = `:${paramName}`;

    if (
      paramConfig.type === "enum" ||
      (paramConfig.type === "new_or_number" && paramConfig.get(paramStringValue) === "new")
    ) {
      // Enums we want to be expressed in the url to represent the unique state of that url (a tab for example)
      // thus we replace the wildcard with the enum value to be represented in the segment event
      loggedPath = loggedPath.replace(wildcard, paramStringValue);
      // Because of representation in the url (for example :tab being imaging), we can remove it from eventProperties
      delete eventProperties[paramName];
    } else {
      try {
        // Log dates as strings
        const paramValueParsed =
          paramConfig.type === "date"
            ? paramStringValue
            : (paramConfig.get(paramStringValue) as number | string);

        eventProperties[paramName] = paramValueParsed;
      } catch (err) {
        if (!isParamParseError(err)) {
          sentryCaptureException(err);
        }
      }
    }
  }
  // Now logged path will be /patient/:patientId/imaging

  logPageView(loggedPath, eventProperties);
};

const useInitializeSegment = (params: SegmentOptions) => {
  const account = useAccount();

  const env = useEnvContext();

  const { REACT_APP_RELEASE_VERSION } = useEnvContext();
  const { user, practice } = params;
  const location = useLocation();
  const { pathname } = location;
  const eventProperties = useRef<BaseEventProperties | undefined>();
  const initialEventQueue = useRef<{ eventName: string; properties?: object; callback?: () => void }[]>([]);

  const trackEvent = useCallback(
    (
      eventName: string,
      archyPracticeProps: BaseEventProperties,
      properties?: object,
      callback?: () => void
    ) => {
      const segment = getSegmentAnalytics();
      const submittedProperties = { ...properties, archy: archyPracticeProps };

      segment?.track(eventName, { ...properties, archy: archyPracticeProps }, callback);

      if (env.REACT_APP_ENVIRONMENT === "development") {
        console.log(`Event "${eventName}" submitted with properties:`, submittedProperties);
      }
    },
    [env]
  );

  // Identify user/practice to segment:
  useEffect(() => {
    const analytics = getSegmentAnalytics();

    if (analytics && practice) {
      const {
        uuid: practiceUuid,
        phoneNumber,
        email,
        name,
        ownerName,
        timezoneId,
        id: practiceId,
      } = practice;
      const userId = `${account.id}`;

      const practiceProperties = {
        practiceUuid,
        phoneNumber,
        email,
        doingBusinessAs: name,
        practiceTimezoneId: timezoneId,
        ownerName,
        id: practiceId,
      };
      const userProperties = {
        firstName: user.name.firstName,
        lastName: user.name.lastName,
        role: user.roleV2.name,
        workEmail: user.email,
      };
      const employeeProperties =
        user.type === "EMPLOYEE"
          ? {
              status: user.employmentDetails.status,
              jobTitle: user.employmentDetails.jobTitle,
            }
          : undefined;

      eventProperties.current = {
        userId: account.id,
        practiceId: practiceProperties.id,
        practice: practiceProperties,
        user: userProperties,
        employee: employeeProperties,
      };

      const eventParams = eventProperties.current;

      analytics.group(`Practice ${account.practiceId}`, eventParams.practice);

      analytics.identify(userId, {
        appReleaseVersion: REACT_APP_RELEASE_VERSION,
        practiceId: eventParams.practice.id,
        id: account.id,
        user: eventParams.user,
        employee: eventParams.employee,
        practice: eventParams.practice,
      });

      // Empty out initial queue of events while segment and event properties were loading
      if (initialEventQueue.current.length > 0) {
        for (const event of initialEventQueue.current) {
          trackEvent(event.eventName, eventParams, event.properties, event.callback);
        }
      }

      initialEventQueue.current = [];
    }
  }, [REACT_APP_RELEASE_VERSION, practice, account, user, trackEvent]);

  // Log page views to Segment
  useEffect(() => {
    if (!pathname) {
      return;
    }

    queueMicrotask(() => logPathChange({ pathname, eventProperties: eventProperties.current }));
  }, [REACT_APP_RELEASE_VERSION, eventProperties, pathname]);

  return useMemo(() => {
    return {
      track: (
        { event, domains }: { event: string; domains?: AppDomain[] },
        options?: {
          properties?: object;
          callback?: () => void;
        }
      ) => {
        const segment = getSegmentAnalytics();
        let eventName = event;

        if (domains) {
          eventName = `${domains.map((domain) => `[${domain}]`).join(" ")} ${event}`;
        }

        if (segment && eventProperties.current) {
          trackEvent(eventName, eventProperties.current, options?.properties, options?.callback);
        } else {
          initialEventQueue.current.push({
            eventName,
            properties: options?.properties,
            callback: options?.callback,
          });
        }
      },
    };
  }, [trackEvent]);
};

export interface PracticeSegmentValue {
  track: TrackFn;
}

const Context = createContext<PracticeSegmentValue>({
  track: noop,
});

Context.displayName = "PracticeSegment";
export const PracticeSegment = Context;
export const SegmentProvider: React.FC<PropsWithChildren & SegmentOptions> = ({
  user,
  practice,
  children,
}) => {
  const logger = useInitializeSegment({ user, practice });

  return <Context.Provider value={logger}>{children}</Context.Provider>;
};

export const usePracticeLogger = () => useContext(PracticeSegment);
