import { RecurringScheduleVO } from "@libs/api/generated-api";
import { formatEnumDayOfWeek, getLocalDate, isLastOccurenceOfWeekdayInMonth } from "@libs/utils/date";
import { isOneOf } from "@libs/utils/isOneOf";
import { getRecurrenceOptions } from "components/ScheduleAppointments/getRecurrenceOptions";
import { getRepeatValueFromRecurrence } from "components/ScheduleAppointments/getRepeatValueFromRecurrence";

// Given the current repeat value, current recurrence, and a new start date,
// this function will return a new recurrence that reflects the new start date.
// eslint-disable-next-line complexity
export const getUpdatedRecurrence = ({
  newDate,
  instanceDate,
  currentRecurrence,
}: {
  currentRecurrence: RecurringScheduleVO | undefined;
  newDate: string | undefined;
  instanceDate: string | undefined;
}) => {
  let updates: Partial<RecurringScheduleVO> | undefined;

  if (!instanceDate || !currentRecurrence || !newDate) {
    return undefined;
  }

  const repeatValue = getRepeatValueFromRecurrence(instanceDate, currentRecurrence);

  const options = getRecurrenceOptions(newDate);

  if (repeatValue === "CUSTOM") {
    if (currentRecurrence.recurrenceType === "DAYS_OF_MONTHS") {
      updates = options.find((op) => op.value === "MONTHLY_ON")?.recurrence;
    } else if (currentRecurrence.recurrenceType === "DAYS_OF_YEAR") {
      updates = options.find((op) => op.value === "ANNUALLY_ON")?.recurrence;
    } else if (
      isOneOf(currentRecurrence.recurrenceType, ["DAYS_OF_WEEKS_OF_MONTH", "DAYS_OF_LAST_WEEKS_OF_MONTH"])
    ) {
      updates = isLastOccurenceOfWeekdayInMonth(getLocalDate(newDate))
        ? options.find((op) => op.value === "MONTHLY_ON_LAST")?.recurrence
        : options.find((op) => op.value === "MONTHLY_ON_ORDINAL")?.recurrence;
    } else if (currentRecurrence.recurrenceType === "DAYS_OF_WEEKS") {
      const currentDayOfWeek = formatEnumDayOfWeek(getLocalDate(instanceDate));
      const newDayOfWeek = formatEnumDayOfWeek(getLocalDate(newDate));
      const daySet = new Set(currentRecurrence.daysOfWeek);

      if (daySet.has(currentDayOfWeek)) {
        daySet.delete(currentDayOfWeek);
        daySet.add(newDayOfWeek);

        updates = {
          daysOfWeek: [...daySet],
        };
      }
    }

    const updatedRecurrence = updates
      ? {
          ...currentRecurrence,
          ...updates,
          // maintain current every value
          // this shouldn't change when updating the
          // date of a recurrence
          every: currentRecurrence.every,
        }
      : currentRecurrence;

    return {
      ...updatedRecurrence,
      endDate:
        // if the current recurrence has an end date and the new date is after the end date
        // then we should clear the end date
        !currentRecurrence.endDate || getLocalDate(newDate) > getLocalDate(currentRecurrence.endDate)
          ? undefined
          : currentRecurrence.endDate,
    };
  }

  if (repeatValue === "MONTHLY_ON_ORDINAL" && !options.some((op) => op.value === "MONTHLY_ON_ORDINAL")) {
    return options.find((op) => op.value === "MONTHLY_ON_LAST")?.recurrence;
  }

  if (repeatValue === "MONTHLY_ON_LAST" && !options.some((op) => op.value === "MONTHLY_ON_LAST")) {
    return options.find((op) => op.value === "MONTHLY_ON_ORDINAL")?.recurrence;
  }

  return options.find((op) => op.value === repeatValue)?.recurrence;
};
