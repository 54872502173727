/**
 * hex to rgb
 * @param {string} color - hex color
 * @param {string} alpha - color alpha value
 * @returns {string} rgb expression
 */
export const getRgb = (color: string, alpha: number | string) => {
  const SHORT_LENGTH = 4;
  const R_INDEX = 3;
  const G_INDEX = 5;
  const B_INDEX = 7;

  if (color.length === SHORT_LENGTH) {
    color = `${color}${color.slice(1, SHORT_LENGTH)}`;
  }

  const r = Number.parseInt(color.slice(1, R_INDEX), 16);
  const g = Number.parseInt(color.slice(R_INDEX, G_INDEX), 16);
  const b = Number.parseInt(color.slice(G_INDEX, B_INDEX), 16);
  const a = alpha || 1;

  return `rgba(${r}, ${g}, ${b}, ${a})`;
};
