import { FC } from "react";
import { createPortal } from "react-dom";
import { useQuerySelector } from "@libs/hooks/useQuerySelector";
import { useDisableScrollBody } from "@libs/hooks/useDisableScrollBody";
import { GustoLoadingOverlay } from "components/Settings/PracticeSetup/Sections/PayrollInformation/GustoLoadingOverlay";
import { PRACTICE_SETTINGS_ID } from "components/Settings/SettingsApp";

export const GustoOnboardingOverlay: FC = () => {
  const element = useQuerySelector(`#${PRACTICE_SETTINGS_ID}`);

  useDisableScrollBody({ enabled: true });

  return (
    element &&
    createPortal(
      <div className="absolute inset-0 z-50">
        <GustoLoadingOverlay />
      </div>,
      element
    )
  );
};
