import { FC } from "react";
import { FormTextInputElementRequest } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { FormWrapper } from "components/Settings/Forms/FormWrapper";
import { EditElementFormProps } from "components/Settings/Forms/types";
import { TextInputFormContent } from "components/Settings/Forms/Text/FormContent";
import { textInputSchema, useTextInputState } from "./schema";

export const TextInputForm: FC<EditElementFormProps<FormTextInputElementRequest>> = ({
  element,
  isEditing,
  formType,
  onInvalid,
  onDraftChange,
  onSecondaryClick,
  onSubmit,
}) => {
  const state = useTextInputState(element);

  const [{ draft }] = state;

  const validation = useValidation(draft, textInputSchema);

  const handleSubmit = () => {
    onSubmit(draft);
    validation.reset();
  };

  return (
    <FormWrapper
      element={draft}
      isEditing={isEditing}
      onDraftChange={onDraftChange}
      onSubmit={handleSubmit}
      onCancel={onSecondaryClick}
      onInvalid={onInvalid}
      onValidate={() => validation.validate().$isValid}
    >
      <TextInputFormContent formType={formType} formState={state} validation={validation.result} />
    </FormWrapper>
  );
};
