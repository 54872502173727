import { useCallback, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  DentalProcedureVO,
  PatientProcedureVO,
  PatientInsuranceResponse,
  CreateTreatmentPlanFormTaskRequest,
  FormTaskVO,
} from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getFullUrl } from "@libs/utils/location";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { getPatientTreatmentPlansQuery } from "api/charting/queries";
import {
  addProceduresToTreatmentPlan,
  createTreatmentPlanFormTask,
  deletePatientProcedures,
  updatePatientProcedures,
} from "api/charting/mutations";
import { useSelectRows } from "hooks/useSelectRows";
import { handleError } from "utils/handleError";
import { createPreAuthClaim } from "api/claim/mutations";
import { paths } from "utils/routing/paths";
import { useItemModal } from "hooks/useItemModal";
import { useConfirmProceduresEstimate } from "components/Insurance/ConfirmInsuranceEstimateModal";
import { generateFormTaskKioskCode } from "api/formTasks/mutations";
import { getPatientSummary } from "api/patients/queries";
import { usePrintForm } from "components/PatientProfile/Forms/hooks/usePrintForm";

// eslint-disable-next-line max-statements
export const usePatientProceduresTable = ({
  patientId,
  procedureIds,
  treatmentPlanUuid,
  patientInsurances,
}: {
  patientId: number;
  procedureIds: number[] | undefined;
  treatmentPlanUuid?: string;
  patientInsurances?: PatientInsuranceResponse[];
}) => {
  const { practiceId } = useAccount();
  const navigate = useNavigate();
  const location = useLocation();
  const [formTaskLoading, setFormTaskLoading] = useState<"kiosk" | "print" | null>(null);
  const preAuthLoadingModal = useBoolean(false);
  const editPatientProceduresModal = useItemModal<{
    patientProcedure: PatientProcedureVO;
    dentalProcedure: DentalProcedureVO;
    targetTreatmentPlanUuid?: string;
    validateOptions?: {
      prosthetic: boolean;
      eager: boolean;
    };
  }>(null);
  const editProcedureEstimatesFlyover = useItemModal<{ id: number }>(null);

  const {
    showConfirmProceduresEstimateModal,
    confirmProceduresEstimateModal,
    modalTitle: confirmProceduresEstimateModalTitle,
  } = useConfirmProceduresEstimate(patientInsurances);
  const kioskModal = useItemModal<string | null>(null);

  const [treatmentPlansQuery, { data: patient }] = useApiQueries([
    getPatientTreatmentPlansQuery({
      args: {
        practiceId,
        patientId,
        includeStates: ["ACTIVE", "INACTIVE"],
      },
    }),
    getPatientSummary({
      args: { patientId, practiceId },
    }),
  ]);

  const [
    deletePatientProceduresMutation,
    addProceduresToTreatmentPlanMutation,
    updatePatientProceduresMutation,
    createPreAuthClaimMutation,
    generateKioskCodeMutation,
    createTreatmentPlanFormTaskMutation,
  ] = useApiMutations([
    deletePatientProcedures,
    addProceduresToTreatmentPlan,
    updatePatientProcedures,
    createPreAuthClaim,
    generateFormTaskKioskCode,
    createTreatmentPlanFormTask,
  ]);

  const { selectAllRows, selectedRows, resetSelectedRows, handleCheckboxChange, setRows } =
    useSelectRows(procedureIds);

  const treatmentPlans = treatmentPlansQuery.data;

  const handlePatientProcedureSaved = useCallback(() => {
    toast.success("Procedure saved");
  }, []);

  const updateProcedures = updatePatientProceduresMutation.mutate;
  const handlePrioritizeProcedures = useCallback(
    (procedures: PatientProcedureVO[], priority: number) => {
      updateProcedures(
        {
          practiceId,
          patientId,
          data: {
            targetTreatmentPlanUuid: treatmentPlanUuid,
            patientProcedureIds: procedures.map(({ id }) => id),
            priority: priority === 0 ? null : priority,
          },
        },
        {
          onSuccess: resetSelectedRows,
        }
      );
    },
    [updateProcedures, patientId, practiceId, resetSelectedRows, treatmentPlanUuid]
  );

  const handlePriorityChanged = useCallback(
    (patientProcedure: PatientProcedureVO, newPriority: number) => {
      handlePrioritizeProcedures([patientProcedure], newPriority);
    },
    [handlePrioritizeProcedures]
  );

  const sendPreAuth = useCallback(
    (patientProcedureIds: number[]) => {
      const data = {
        patientId,
        patientProcedureIds,
      };

      const fullUrl = getFullUrl(location);

      createPreAuthClaimMutation.mutate(
        { practiceId, data },
        {
          onSuccess: (response) =>
            navigate(paths.claim({ claimUuid: response.data.data.uuid }, { from: fullUrl })),
          onError: handleError,
        }
      );
    },
    [createPreAuthClaimMutation, navigate, patientId, practiceId, location]
  );

  const handleSendPreAuthClick = useCallback(
    (patientProcedure: PatientProcedureVO) => {
      const dentalProcedure = patientProcedure.dentalProcedure;

      if (dentalProcedure) {
        if (!patientProcedure.implant && dentalProcedure.prosthetic) {
          editPatientProceduresModal.open({
            patientProcedure,
            dentalProcedure,
            targetTreatmentPlanUuid: treatmentPlanUuid,
            validateOptions: {
              eager: true,
              prosthetic: true,
            },
          });
        } else {
          sendPreAuth([patientProcedure.id]);
        }
      }
    },
    [sendPreAuth, editPatientProceduresModal, treatmentPlanUuid]
  );

  const handleEditClick = useCallback(
    (patientProcedure: PatientProcedureVO) => {
      const dentalProcedure = patientProcedure.dentalProcedure;

      if (dentalProcedure) {
        editPatientProceduresModal.open({
          patientProcedure,
          dentalProcedure,
          targetTreatmentPlanUuid: treatmentPlanUuid,
        });
      }
    },
    [editPatientProceduresModal, treatmentPlanUuid]
  );

  const handleEditEstimatesClick = useCallback(
    (patientProcedure: PatientProcedureVO) => {
      const { id, dentalProcedure } = patientProcedure;

      if (dentalProcedure) {
        editProcedureEstimatesFlyover.open({ id });
      }
    },
    [editProcedureEstimatesFlyover]
  );

  const handleScheduleProceduresClick = useCallback(
    (selectedProcedures: PatientProcedureVO[]) => {
      navigate(
        paths.addPatientAppointment({
          patientId,
          from: getFullUrl(location),
          patientProcedureIds: selectedProcedures.map((pp) => pp.id),
        })
      );
    },
    [navigate, patientId, location]
  );

  const deleteMasterProcedures = useCallback(
    (selectedProcedures: PatientProcedureVO[]) => {
      deletePatientProceduresMutation.mutate(
        {
          practiceId,
          patientId,
          data: {
            patientProcedureIds: selectedProcedures.map((proc) => proc.id),
          },
        },
        {
          onError: handleError,
          onSuccess: resetSelectedRows,
        }
      );
    },
    [practiceId, patientId, deletePatientProceduresMutation, resetSelectedRows]
  );

  const handleAddProceduresToTreatmentPlanClick = useCallback(
    (selectedProcedures: PatientProcedureVO[], targetTreatmentPlanUuid: string) => {
      addProceduresToTreatmentPlanMutation.mutate(
        {
          practiceId,
          treatmentPlanUuid: targetTreatmentPlanUuid,
          data: {
            patientProcedureIds: selectedProcedures.map((p) => p.id),
            sourceTreatmentPlanUuid: treatmentPlanUuid,
          },
        },
        {
          onError: handleError,
          onSuccess: () => {
            navigate(
              paths.charting(
                {
                  patientId,
                },
                {
                  treatmentPlanUuid: targetTreatmentPlanUuid,
                }
              )
            );
          },
        }
      );
    },
    [practiceId, addProceduresToTreatmentPlanMutation, navigate, patientId, treatmentPlanUuid]
  );

  const handleSendProceduresToPreAuthClick = useCallback(
    (selectedProcedures: PatientProcedureVO[]) => {
      sendPreAuth(selectedProcedures.map((procedure) => procedure.id));
    },
    [sendPreAuth]
  );
  const { getFormPrintLink } = usePrintForm();
  const createTreatmentPlanFormTaskMutateAsync = createTreatmentPlanFormTaskMutation.mutateAsync;
  const generateFormTaskKioskCodeAsync = generateKioskCodeMutation.mutateAsync;

  const handleTreatmentPlanFormTaskForKiosk = useCallback(
    async (newFormTask: FormTaskVO) => {
      const {
        data: { data: kioskCode },
      } = await generateFormTaskKioskCodeAsync({
        practiceId,
        formTaskUuids: [newFormTask.uuid],
      });

      kioskModal.open(kioskCode);
    },
    [generateFormTaskKioskCodeAsync, kioskModal, practiceId]
  );

  const sendFormsModal = useItemModal<CreateTreatmentPlanFormTaskRequest | null>(null);

  const handleCreateTreatmentPlanFormTask = useCallback(
    async ({
      procedures,
      forceInsuranceEstimate,
      method,
    }: {
      procedures: PatientProcedureVO[];
      forceInsuranceEstimate?: boolean;
      method: "print" | "send" | "kiosk";
    }) => {
      if (showConfirmProceduresEstimateModal({ procedures, method })) {
        return;
      }

      const treatmentPlanFormTaskRequest: CreateTreatmentPlanFormTaskRequest = {
        procedureIds: procedures.length > 0 ? procedures.map(({ id }) => id) : undefined,
        treatmentPlanUuid,
        printOnly: method === "print",
        printFeeCalcRequest: forceInsuranceEstimate
          ? {
              forceInsuranceEstimate: true,
            }
          : undefined,
      };

      if (method === "send") {
        sendFormsModal.open(treatmentPlanFormTaskRequest);

        return;
      }

      setFormTaskLoading(method);

      try {
        const result = await createTreatmentPlanFormTaskMutateAsync({
          practiceId,
          data: treatmentPlanFormTaskRequest,
        });
        const formTask = result.data.data;

        if (method === "kiosk") {
          await handleTreatmentPlanFormTaskForKiosk(formTask);
        } else if (patient) {
          const url = getFormPrintLink({ formTaskUuids: [formTask.uuid], patientDob: patient.dob });

          window.open(url, "_blank");
        }
      } catch (e) {
        handleError(e);
      } finally {
        setFormTaskLoading(null);
      }
    },
    [
      showConfirmProceduresEstimateModal,
      createTreatmentPlanFormTaskMutateAsync,
      practiceId,
      treatmentPlanUuid,
      handleTreatmentPlanFormTaskForKiosk,
      patient,
      getFormPrintLink,
      sendFormsModal,
    ]
  );

  return {
    handleEditClick,
    handleEditEstimatesClick,
    handleCheckboxChange,
    formTaskLoading,
    handleCreateTreatmentPlanFormTask,
    handleSendProceduresToPreAuthClick,
    handleAddProceduresToTreatmentPlanClick,
    handleScheduleProceduresClick,
    handleSendPreAuthClick,
    handlePriorityChanged,
    handlePrioritizeProcedures,
    handlePatientProcedureSaved,
    resetSelectedRows,
    selectAllRows,
    setRows,
    deleteMasterProcedures,
    selectedRows,
    treatmentPlans,
    kioskModal,
    sendFormsModal,
    editPatientProceduresModal,
    editProcedureEstimatesFlyover,
    confirmProceduresEstimateModal,
    confirmProceduresEstimateModalTitle,
    preAuthLoadingModal,
    isCreatingPreAuth: createPreAuthClaimMutation.isLoading,
  };
};
