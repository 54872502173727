import { useMemo } from "react";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { RadioList } from "@libs/components/UI/RadioList";
import { ActionModal } from "components/UI/ActionModal";
import {
  getTeethLabel,
  orderTeethForOdontogram,
} from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { MountDetailsQuery, TeethSearchMode } from "utils/routing/patient";

type Props = {
  teeth: string[];
  onClose: Func;
  teethSearchMode: TeethSearchMode;
  teethSearchTeeth: string[];
  onUpdateParams: (params: Partial<MountDetailsQuery>) => void;
  onConfirm: Func;
};

const OptionLabel: React.FC<{ label: string; tooltip: string; children?: React.ReactNode }> = ({
  label,
  tooltip,
  children,
}) => {
  return (
    <>
      <div className="flex items-center gap-2">
        <div>{label}</div>
        <FloatingTooltip theme="MEDIUM" content={tooltip}>
          <InfoIcon className="h-4 w-4 text-greyLight" />
        </FloatingTooltip>
      </div>
      {children}
    </>
  );
};

export const TeethOptionsFilterModal: React.FC<Props> = ({
  onClose,
  onConfirm,
  teeth,
  teethSearchMode,
  teethSearchTeeth,
  onUpdateParams,
}) => {
  const filterOptions = useMemo(() => {
    return [
      {
        label: (
          <OptionLabel
            label="Contain all teeth"
            tooltip="Images that contain all teeth present in the reference image."
          />
        ),
        value: "CONTAINS_ALL" as const,
      },
      {
        label: (
          <OptionLabel
            label="Contain any teeth"
            tooltip="Images that contain selected teeth present in the reference image."
          />
        ),
        value: "CONTAINS_ANY" as const,
      },
    ];
  }, []);

  const teethOptions = useMemo(() => {
    return teeth.map((tooth) => ({ value: tooth, label: tooth }));
  }, [teeth]);

  const toothSelections = useMemo(
    () => new Set(orderTeethForOdontogram(teethSearchTeeth)),
    [teethSearchTeeth]
  );

  const teethLabel = useMemo(() => getTeethLabel(teeth), [teeth]);

  return (
    <ActionModal
      handleSave={onConfirm}
      title="Find Comparison Images"
      handleClose={onClose}
      primaryButtonText="Find & Compare"
    >
      <div className="px-4 pt-6 flex flex-col gap-4 min-h-52">
        <div className="flex flex-col gap-2 text-sm">
          <div className="font-sansSemiBold">Reference image</div>
          <div>{teethLabel}</div>
        </div>
        <RadioList
          label="Find Images that"
          labelTheme="smallBold"
          layout="vert"
          options={filterOptions}
          selectedValue={teethSearchMode}
          includeDarkMode={false}
          onChange={(_e, option) => {
            option.value;
            onUpdateParams({ teethSearchMode: option.value });
          }}
        />
        {teethSearchMode === "CONTAINS_ANY" && (
          <CheckboxList
            options={teethOptions}
            label="Select which teeth to match"
            layout="horiz"
            labelTheme="regular"
            includeDarkMode={false}
            parseNumbers={false}
            selectedValues={toothSelections}
            onChange={(newTeeth) => {
              // Checkbox list converts string numbers to numbers, for convenience, this converts them back to strings, we want the string number
              onUpdateParams({ teethSearchTeeth: [...newTeeth] });
            }}
          />
        )}
      </div>
    </ActionModal>
  );
};
