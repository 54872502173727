import React from "react";
import { getFilterSelectedValues } from "components/Dashboard/utils/filters";
import { DashboardFilter, FilteredProviderType } from "utils/routing/dashboard/serializedFilter";

export const useFilteredProviderCategory = (filters: DashboardFilter[]) => {
  return React.useMemo(() => {
    const providerIds = [...getFilterSelectedValues({ type: "providerId", filters })];
    const providerJob = [...getFilterSelectedValues({ type: "providerJobCategory", filters })];
    const [providerIdFilter] = providerIds;

    return {
      providerId: providerIdFilter ? Number.parseInt(providerIdFilter, 10) : undefined,
      providerJob: providerJob[0] as FilteredProviderType | undefined,
    };
  }, [filters]);
};
