import { PaymentDeviceVO } from "@libs/api/generated-api";
import { makeQuery } from "@libs/utils/queries";
import { cacheForSession } from "@libs/utils/queryCache";

export const listPaymentDevices = makeQuery({
  queryKey: ["practices", "listPaymentDevices"],
  formatParams: (args: {
    practiceId: number;
    query?: {
      states?: PaymentDeviceVO["state"][];
    };
  }) => [args.practiceId, args.query],
  queryOptions: cacheForSession,
});

export const getAdjustmentTypesForPractice = makeQuery({
  queryKey: ["practices", "getAdjustmentTypesForPractice"],
  formatParams: ({ practiceId, query }: { practiceId: number; query?: { hideArchived?: boolean } }) => [
    practiceId,
    query,
  ],
  queryOptions: cacheForSession,
});
