/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-non-null-assertion */
import { useMemo, createContext, useCallback, useContext, useState, PropsWithChildren } from "react";
import {
  ClaimAttachmentEntry,
  ClaimAttachmentVO,
  ClaimVO,
  UpdateAttachmentEntry,
  UploadAttachmentEntry,
} from "@libs/api/generated-api";
import { dataURLtoFile } from "@libs/utils/dataUrl";
import { isOneOf } from "@libs/utils/isOneOf";
import { asyncNoop, noop } from "@libs/utils/noop";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";
import { useAccount } from "@libs/contexts/AccountContext";
import { deleteAttachments, setAttachments, updateAttachments } from "api/claim/mutations";
import { getClaimQuery } from "api/claim/queries";
import { handleError } from "utils/handleError";
import { MAX_CLAIM_ATTACHMENT_UPLOAD_SIZE_IN_BYTES } from "components/Claim/Attachments/ExternalUpload";
import { usePathParams } from "hooks/usePathParams";
import { useUploadAttachments } from "components/Claim/Attachments/useUploadAttachments";

export const IMAGE_TYPES = new Set(["XRAY", "PHOTO"]);
export const OTHER_TYPES = new Set(["DIAGNOSTIC", "MODEL", "OTHER", "RADIOLOGY", "REFERRAL"]);

export type Narrative = Required<Pick<ClaimAttachmentVO, "data">> & { type: "NOTE"; uuid?: string };
// isUploaded Means that this image should be uploaded vs being a reference to
// an existing image
export type MedicalImage = Required<Pick<ClaimAttachmentVO, "data" | "isUploaded" | "sourceCreatedAt">> & {
  sourceId?: number;
  type: "PHOTO" | "XRAY";
  uuid?: string;
};
export type OtherImage = Required<Pick<ClaimAttachmentVO, "data" | "isUploaded" | "sourceCreatedAt">> & {
  sourceId?: number;
  type: "DIAGNOSTIC" | "MODEL" | "OTHER" | "RADIOLOGY" | "REFERRAL";
  uuid?: string;
};
export type PerioChart = Required<Pick<ClaimAttachmentVO, "data" | "isUploaded" | "sourceCreatedAt">> & {
  sourceUuid?: string;
  type: "CHART";
  uuid?: string;
};

export interface AttachmentsState {
  medicalImages?: MedicalImage[];
  narrative?: Narrative;
  other?: OtherImage[];
  perioCharts?: PerioChart[];
}

export interface ClaimAttachmentsContextValue extends AttachmentsState {
  addMedicalImage: (medicalImage: MedicalImage) => void;
  addOtherImage: (otherImage: OtherImage) => void;
  claim?: ClaimVO;
  editMedicalImage: (imageData: string, type: ClaimAttachmentVO["type"]) => void;
  editOtherImage: (imageData: string, type: ClaimAttachmentVO["type"]) => void;
  removeImage: (image: Omit<ClaimAttachmentVO, "uuid">) => void;
  setNarrative: (narrative?: string) => void;
  setPerioCharts: (charts?: PerioChart[]) => void;
  toggleMedicalImageType: () => void;
  uploadAttachments: () => Promise<void>;
  useArchyImages: boolean;
}

interface CustomImageEntry extends UploadAttachmentEntry {
  file: File;
}

const Context = createContext<ClaimAttachmentsContextValue>({
  addMedicalImage: noop,
  addOtherImage: noop,
  editMedicalImage: noop,
  editOtherImage: noop,
  removeImage: noop,
  setNarrative: noop,
  setPerioCharts: noop,
  toggleMedicalImageType: noop,
  uploadAttachments: asyncNoop,
  useArchyImages: true,
});

Context.displayName = "ClaimAttachmentsContext";

export const useAttachments = () => useContext(Context);

export const AttachmentsProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [attachmentsState, setAttachmentsState] = useState<AttachmentsState>({});
  const { practiceId } = useAccount();
  const { claimUuid } = usePathParams("claimAttachments");
  const [{ data: claim }] = useApiQueries([
    getClaimQuery({
      args: { practiceId, claimUuid },
    }),
  ]);
  const uploadAttachments = useUploadAttachments();

  // Handle using Archy or external images
  const useArchyImages = useBoolean(true);
  const [selectedArchyImages, setSelectedArchyImages] = useState<MedicalImage[] | undefined>(undefined);
  const [selectedExternalImages, setSelectedExternalImages] = useState<MedicalImage[] | undefined>(undefined);

  useSyncOnce((loadedClaim) => {
    const medicalImages = loadedClaim.attachments.filter((attachment) =>
      IMAGE_TYPES.has(attachment.type)
    ) as MedicalImage[];

    setAttachmentsState({
      medicalImages,
      narrative: loadedClaim.attachments.find(
        (attachment) => attachment.type === "NOTE" && attachment.sourceId == null
      ) as Narrative,
      other: loadedClaim.attachments.filter((attachment) => OTHER_TYPES.has(attachment.type)) as OtherImage[],
      perioCharts: loadedClaim.attachments.filter(
        (attachment) => attachment.type === "CHART"
      ) as PerioChart[],
    });

    if (!medicalImages.length || medicalImages.some((image) => image.sourceId)) {
      setSelectedArchyImages(medicalImages);
      useArchyImages.on();
    } else {
      setSelectedExternalImages(medicalImages);
      useArchyImages.off();
    }
  }, claim);

  // In order to maintain previous selections if the user toggles between Archy
  // images or external images, track draft selected images of both types and
  // reset when toggling the type
  const toggleMedicalImageType = useCallback(() => {
    const useArchy = useArchyImages.isOn;

    if (useArchy) {
      setAttachmentsState((last) => {
        return {
          ...last,
          medicalImages: selectedExternalImages ?? [],
        };
      });
    } else {
      setAttachmentsState((last) => {
        return {
          ...last,
          medicalImages: selectedArchyImages ?? [],
        };
      });
    }

    useArchyImages.toggle();
  }, [selectedArchyImages, selectedExternalImages, useArchyImages]);

  const addMedicalImage = useCallback(
    (medicalImage: MedicalImage) => {
      if (useArchyImages.isOn) {
        setSelectedArchyImages((last) => {
          const imageExists = last?.some((image) => image.data === medicalImage.data);

          if (!imageExists) {
            return [...(last ?? []), medicalImage];
          }

          return last;
        });
        setAttachmentsState((last) => {
          const imageExists = last.medicalImages?.some((image) => image.data === medicalImage.data);

          if (!imageExists) {
            return { ...attachmentsState, medicalImages: [...(last.medicalImages ?? []), medicalImage] };
          }

          return last;
        });
      } else {
        setSelectedExternalImages((last) => {
          const imageExists = last?.some((image) => image.data === medicalImage.data);

          if (!imageExists) {
            return [...(last ?? []), medicalImage];
          }

          return last;
        });
        setAttachmentsState((last) => {
          const imageExists = last.medicalImages?.some((image) => image.data === medicalImage.data);

          if (!imageExists) {
            return { ...attachmentsState, medicalImages: [...(last.medicalImages ?? []), medicalImage] };
          }

          return last;
        });
      }
    },
    [attachmentsState, useArchyImages.isOn]
  );

  // Only used for external images to modify type
  const editMedicalImage = useCallback(
    (imageData: string, type: ClaimAttachmentVO["type"]) => {
      const medicalImages = selectedExternalImages;

      if (medicalImages) {
        const existingImageIndex = medicalImages.findIndex((entry) => entry.data === imageData);

        if (existingImageIndex !== -1 && isOneOf(type, ["PHOTO", "XRAY"])) {
          medicalImages[existingImageIndex].type = type;
          setSelectedExternalImages([...medicalImages]);
          setAttachmentsState({ ...attachmentsState, medicalImages });
        }
      }
    },
    [attachmentsState, selectedExternalImages]
  );

  const removeImage = useCallback(
    (image: Omit<ClaimAttachmentVO, "uuid">) => {
      if (IMAGE_TYPES.has(image.type)) {
        if (useArchyImages.isOn) {
          const medicalImages = selectedArchyImages;

          if (medicalImages) {
            const existingImageIndex = medicalImages.findIndex((entry) => entry.sourceId === image.sourceId);

            if (existingImageIndex !== -1) {
              medicalImages.splice(existingImageIndex, 1);
              setSelectedArchyImages([...medicalImages]);
              setAttachmentsState({ ...attachmentsState, medicalImages });
            }
          }
        } else {
          const medicalImages = selectedExternalImages;

          if (medicalImages) {
            const existingImageIndex = medicalImages.findIndex((entry) => entry.data === image.data);

            if (existingImageIndex !== -1) {
              medicalImages.splice(existingImageIndex, 1);
              setSelectedExternalImages([...medicalImages]);
              setAttachmentsState({ ...attachmentsState, medicalImages });
            }
          }
        }
      } else if (OTHER_TYPES.has(image.type)) {
        const otherImages = attachmentsState.other;

        if (otherImages) {
          const existingImageIndex = otherImages.findIndex((entry) => entry.data === image.data);

          if (existingImageIndex !== -1) {
            otherImages.splice(existingImageIndex, 1);
            setAttachmentsState({ ...attachmentsState, other: otherImages });
          }
        }
      }
    },
    [attachmentsState, selectedArchyImages, selectedExternalImages, useArchyImages.isOn]
  );

  const addOtherImage = useCallback(
    (otherImage: OtherImage) => {
      setAttachmentsState((last) => {
        const imageExists = last.other?.some((image) => image.data === otherImage.data);

        if (!imageExists) {
          return { ...attachmentsState, other: [...(last.other ?? []), otherImage] };
        }

        return last;
      });
    },
    [attachmentsState]
  );

  // Only used for other images to modify type
  const editOtherImage = useCallback(
    (imageData: string, type: ClaimAttachmentVO["type"]) => {
      const otherImages = attachmentsState.other;

      if (otherImages) {
        const existingImageIndex = otherImages.findIndex((entry) => entry.data === imageData);

        if (
          existingImageIndex !== -1 &&
          isOneOf(type, ["DIAGNOSTIC", "MODEL", "OTHER", "RADIOLOGY", "REFERRAL"])
        ) {
          otherImages[existingImageIndex].type = type;
          setAttachmentsState({ ...attachmentsState, other: otherImages });
        }
      }
    },
    [attachmentsState]
  );

  const [deleteAttachmentsMutation, setAttachmentsMutation, updateAttachmentsMutation] = useApiMutations([
    deleteAttachments,
    setAttachments,
    updateAttachments,
  ]);

  // Used to update `createdAt` on external perio charts or `type` on images
  const handleUpdateAttachments = useCallback(async () => {
    if (!claim) {
      return;
    }

    let imagesToUpdate: UpdateAttachmentEntry[] = [];
    const otherImagesToUpdate: UpdateAttachmentEntry[] = (attachmentsState.other ?? [])
      .filter((image) => image.uuid)
      .map((image) => {
        return { uuid: image.uuid as string, type: image.type };
      });
    let medicalImagesToUpdate: UpdateAttachmentEntry[] = [];

    if (useArchyImages.isOff) {
      medicalImagesToUpdate = (attachmentsState.medicalImages ?? [])
        .filter((image) => image.uuid)
        .map((image) => {
          return { uuid: image.uuid as string, type: image.type };
        });
    }

    imagesToUpdate = [...otherImagesToUpdate, ...medicalImagesToUpdate];

    const existingExternalPerioCharts = claim.attachments.filter(
      (attachment) => attachment.type === "CHART" && !attachment.sourceUuid
    );

    const chartsToUpdate = (attachmentsState.perioCharts ?? [])
      .filter(
        (chart) =>
          chart.uuid &&
          !chart.sourceUuid &&
          existingExternalPerioCharts.some((attachment) => attachment.data === chart.data)
      )
      .map((chart) => {
        return { uuid: chart.uuid as string, createdAt: chart.sourceCreatedAt };
      });

    const attachmentsToUpdate = [...imagesToUpdate, ...chartsToUpdate];

    if (attachmentsToUpdate.length) {
      await updateAttachmentsMutation.mutateAsync({
        practiceId,
        claimUuid,
        data: { attachments: attachmentsToUpdate },
      });
    }
  }, [
    attachmentsState.medicalImages,
    attachmentsState.other,
    attachmentsState.perioCharts,
    claim,
    claimUuid,
    practiceId,
    updateAttachmentsMutation,
    useArchyImages.isOff,
  ]);

  const handleDeleteAttachments = useCallback(async () => {
    if (!claim) {
      return;
    }

    // Delete any custom images that are no longer in the attachment list.
    const existingPerioCharts = claim.attachments
      .filter((attachment) => attachment.type === "CHART")
      .map((attachment) => attachment.uuid);

    const existingCustomImages = claim.attachments
      .filter(
        (attachment) =>
          (IMAGE_TYPES.has(attachment.type) || OTHER_TYPES.has(attachment.type)) && attachment.isUploaded
      )
      .map((attachment) => attachment.uuid);

    const perioCharts = new Set(
      (attachmentsState.perioCharts || []).map((chart) => chart.uuid).filter(Boolean)
    );
    const images = new Set(
      [...(attachmentsState.medicalImages ?? []), ...(attachmentsState.other ?? [])]
        .map((image) => image.uuid)
        .filter(Boolean)
    );

    const chartsAndImages = [
      ...existingPerioCharts.filter((uuid) => !perioCharts.has(uuid)),
      ...existingCustomImages.filter((uuid) => !images.has(uuid)),
    ];

    if (chartsAndImages.length > 0) {
      await deleteAttachmentsMutation.mutateAsync({
        practiceId,
        claimUuid,
        data: { attachmentUuids: chartsAndImages },
      });
    }
  }, [
    attachmentsState.medicalImages,
    attachmentsState.other,
    attachmentsState.perioCharts,
    claim,
    claimUuid,
    deleteAttachmentsMutation,
    practiceId,
  ]);

  const handleSetAttachments = useCallback(async () => {
    if (!claim || !attachmentsState.medicalImages) {
      return;
    }

    // Set all the attachments that aren't custom uploaded ones.
    const claimAttachmentEntries: ClaimAttachmentEntry[] = attachmentsState.medicalImages.filter(
      (image) => !image.isUploaded
    );

    if (attachmentsState.narrative) {
      claimAttachmentEntries.push({ type: "NOTE", note: attachmentsState.narrative.data });
    }

    if (claim.attachments.length !== 0 || claimAttachmentEntries.length !== 0) {
      await setAttachmentsMutation.mutateAsync({
        practiceId,
        claimUuid,
        data: { attachments: claimAttachmentEntries },
      });
    }
  }, [
    attachmentsState.medicalImages,
    attachmentsState.narrative,
    claim,
    claimUuid,
    practiceId,
    setAttachmentsMutation,
  ]);

  const handleUploadAttachments = useCallback(async () => {
    const perioCharts = attachmentsState.perioCharts || [];
    const medicalImages = attachmentsState.medicalImages || [];
    const otherImages = attachmentsState.other || [];

    const customImages = [
      ...medicalImages
        .filter((image) => image.isUploaded && !image.uuid)
        .map((image) => ({
          type: image.type,
          file: dataURLtoFile(image.data, "MedicalImage.jpeg"),
          createdAt: image.sourceCreatedAt,
          sourceId: image.sourceId,
        })),
      ...otherImages
        .filter((image) => image.isUploaded && !image.uuid)
        .map((image) => ({
          type: image.type,
          file: dataURLtoFile(image.data, "OtherImage.jpeg"),
          createdAt: image.sourceCreatedAt,
        })),
      ...perioCharts
        .filter((chart) => !chart.uuid)
        .map((chart) => ({
          type: chart.type,
          file: dataURLtoFile(chart.data, "PerioChart.png"),
          createdAt: chart.sourceCreatedAt,
          sourceUuid: chart.sourceUuid,
        })),
    ];

    const batches: CustomImageEntry[][] = [];
    let currentTotalImageSize = 0;
    let currentBatchIndex = 0;

    customImages.forEach((image) => {
      if (batches[currentBatchIndex]) {
        const newTotalImageSize = image.file.size + currentTotalImageSize;

        if (newTotalImageSize > MAX_CLAIM_ATTACHMENT_UPLOAD_SIZE_IN_BYTES) {
          currentBatchIndex++;
          batches.push([image]);
          currentTotalImageSize = image.file.size;
        } else {
          batches[currentBatchIndex].push(image);
          currentTotalImageSize = newTotalImageSize;
        }
      } else {
        batches.push([image]);
        currentTotalImageSize = image.file.size;
      }
    });

    // Upload any custom images.
    if (batches.length > 0) {
      for (const batch of batches) {
        await uploadAttachments(
          claimUuid,
          {
            // eslint-disable-next-line unused-imports/no-unused-vars
            attachments: batch.map(({ file, ...metadata }) => metadata),
          },
          batch.map((data) => data.file)
        );
      }
    }
  }, [
    attachmentsState.medicalImages,
    attachmentsState.other,
    attachmentsState.perioCharts,
    claimUuid,
    uploadAttachments,
  ]);

  // eslint-disable-next-line complexity
  const upload = useCallback(async () => {
    if (!claim) {
      return;
    }

    try {
      // Update any attachments where createdAt is being updated.
      await handleUpdateAttachments();

      // Delete any custom images that are no longer in the attachment list.
      await handleDeleteAttachments();

      // Set all the attachments that aren't custom uploaded ones.
      await handleSetAttachments();

      // Upload any custom attachments.
      await handleUploadAttachments();
    } catch (error) {
      handleError(error);
      throw error;
    }
  }, [
    claim,
    handleDeleteAttachments,
    handleSetAttachments,
    handleUpdateAttachments,
    handleUploadAttachments,
  ]);

  const value = useMemo<ClaimAttachmentsContextValue>(() => {
    return {
      claim,
      ...attachmentsState,
      addMedicalImage,
      addOtherImage,
      editMedicalImage,
      editOtherImage,
      removeImage,
      selectedArchyImages,
      setNarrative: (narrative?: string) =>
        setAttachmentsState({
          ...attachmentsState,
          narrative: narrative ? { type: "NOTE", data: narrative } : undefined,
        }),
      setPerioCharts: (charts?: PerioChart[]) =>
        setAttachmentsState({
          ...attachmentsState,
          perioCharts: charts,
        }),
      toggleMedicalImageType,
      uploadAttachments: upload,
      useArchyImages: useArchyImages.isOn,
    };
  }, [
    addMedicalImage,
    addOtherImage,
    attachmentsState,
    claim,
    editMedicalImage,
    editOtherImage,
    removeImage,
    selectedArchyImages,
    toggleMedicalImageType,
    upload,
    useArchyImages.isOn,
  ]);

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
