import { FC } from "react";

import { cx } from "@libs/utils/cx";
import { BaseFormSection, BaseFormSectionProps } from "components/UI/FormSection";

export const FormSection: FC<
  Pick<BaseFormSectionProps, "title" | "subTitle" | "disabled" | "required" | "children"> & {
    error?: boolean;
  }
> = ({ error = false, children, ...rest }) => (
  <BaseFormSection
    {...rest}
    className={cx("flex flex-col gap-y-2 font-sansSemiBold text-xs", error && "text-red")}
    useCustomStyling
  >
    <div className="font-sans">{children}</div>
  </BaseFormSection>
);
