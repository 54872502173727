import { Route } from "react-router-dom";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { NotFound } from "components/NotFoundRoute";
import { MedicalHistoryRoute } from "components/PatientProfile/MedicalHistory/MedicalHistoryRoute";
import { PatientAppHistoryProvider } from "contexts/PatientLinksContext";
import { usePathParams } from "hooks/usePathParams";

export const MedicalHistoryApp = () => {
  const { patientId } = usePathParams("medicalHistory");

  return (
    <PatientAppHistoryProvider patientId={patientId} name="medicalHistory">
      <SentryRoutes>
        <Route index element={<MedicalHistoryRoute />} />
        <Route path="*" element={<NotFound />} />
      </SentryRoutes>
    </PatientAppHistoryProvider>
  );
};
