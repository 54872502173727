import { FC, MouseEvent, ReactNode } from "react";
import { BlockerFunction, LinkProps } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { LayoutCard } from "@libs/components/UI/LayoutCard";

import { Prompt } from "components/UI/Prompt";

export const ModalPage: FC<{
  title: ReactNode;
  actions?: ReactNode;
  closeLink: LinkProps["to"];
  promptMessage?: string;
  shouldBlock?: BlockerFunction;
  className?: string;
  onCloseClick?: (e: MouseEvent<HTMLAnchorElement>) => void;
  children?: ReactNode;
}> = ({ actions, children, title, shouldBlock, onCloseClick, closeLink, promptMessage, className }) => {
  return (
    <>
      <LayoutCard className={cx("h-full flex flex-col", className)}>
        <div
          className={`
            flex-none
            py-4
            pl-4
            pr-14
            border-b
            border-b-slate-200
            relative
          `}
        >
          {title}
          <LinkIcon
            replace
            theme="primary"
            className="right-4 absolute top-4"
            onClick={onCloseClick}
            SvgIcon={CloseIcon}
            tooltip={{ content: "Close", theme: "SMALL" }}
            to={closeLink}
            size="lg"
          />
        </div>
        <div className="flex-1 min-h-0 overflow-y-auto">{children}</div>
        {actions ? <div className="flex-none p-4 border-t border-t-slate-200">{actions}</div> : null}
      </LayoutCard>

      {promptMessage ? <Prompt shouldBlock={shouldBlock} message={promptMessage} /> : null}
    </>
  );
};
