import { ChangeEvent, Fragment } from "react";
import { PatientProcedureVO } from "@libs/api/generated-api";
import { UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { useNow } from "hooks/useNow";
import {
  Column,
  ProceduresTableGrid,
  ProceduresTableRow,
} from "components/Charting/ProceduresTableComponents";

type Props<C extends Column> = {
  proceduresQuery: UseInfiniteApiListQueryResult<PatientProcedureVO>;
  selectedRows: Set<number>;
  columns: C[];
  queryKey: unknown;
  totalCount: number;
  renderHeaderCell: (column: C) => JSX.Element;
  renderRow: (procedure: PatientProcedureVO) => JSX.Element;
  onToggleRow: (e: ChangeEvent<HTMLInputElement>) => void;
  emptyMessage: string;
  onRowViewed: (id: number) => void;
  editProcedureId?: number;
};

const id = "procedures-table-scroll-container";

export const ProceduresTableMaster = <C extends Column>({
  columns,
  queryKey,
  renderHeaderCell,
  renderRow,
  proceduresQuery,
  selectedRows,
  emptyMessage,
  totalCount,
  onToggleRow,
  onRowViewed,
  editProcedureId,
}: Props<C>) => {
  const now = useNow();

  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: proceduresQuery });

  const procedures = useFlattenPages(proceduresQuery.data);

  return (
    <PersistScrollPosition
      resetScrollKey={queryKey}
      id={id}
      className="h-full min-h-inherit overflow-y-auto"
      ref={rootRef}
    >
      <ProceduresTableGrid
        emptyMessage={emptyMessage}
        columns={columns}
        isLoading={proceduresQuery.isLoading}
        isEmpty={totalCount === 0 && !proceduresQuery.isLoading}
      >
        {columns.map((col) => (
          <Fragment key={col.id}>{renderHeaderCell(col)}</Fragment>
        ))}
        <ScrollableInfiniteQueryResult
          loading={<LoadingContent containerClassName="col-span-full" />}
          infiniteQuery={proceduresQuery}
          scrollRef={scrollRef}
          loadMoreClassName="col-span-full"
        >
          {procedures?.map((procedure) => (
            <ProceduresTableRow
              key={procedure.id}
              now={now}
              scrollContainerId={id}
              selectedRows={selectedRows}
              procedure={procedure}
              onToggleRow={onToggleRow}
              onViewed={onRowViewed}
              isEditing={procedure.id === editProcedureId}
            >
              {renderRow(procedure)}
            </ProceduresTableRow>
          ))}
        </ScrollableInfiniteQueryResult>
      </ProceduresTableGrid>
    </PersistScrollPosition>
  );
};
