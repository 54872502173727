import { FC, FormEventHandler, useMemo, useState } from "react";
import { NameVO, RoleVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter, ModalForm } from "@libs/components/UI/ModalComponents";
import { getPracticeRolesV2 } from "api/practice/queries";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { Avatar } from "components/UI/Avatar";

export const DeleteRoleModal: FC<{
  roleId: number;
  isDeleting: boolean;
  onConfirm: (roleId: number, replacementRoleId: number) => void;
  onClose: Func;
}> = ({ roleId, isDeleting, onConfirm, onClose }) => {
  const { practiceId } = useAccount();
  const [employeeRoles] = useApiQueries([getPracticeRolesV2({ args: { practiceId } })]);

  const employeeRoleToDelete = useMemo(
    () => employeeRoles.data?.find((er) => er.role.roleId === roleId),
    [employeeRoles.data, roleId]
  );

  const replacementRoles = useMemo(
    () => employeeRoles.data?.filter((er) => er.role.roleId !== roleId).map((er) => er.role),
    [employeeRoles.data, roleId]
  );

  const [replacementRoleId, setReplacementRoleId] = useState<number>();

  const validation = useValidation(
    {
      replacementRoleId,
    },
    {
      replacementRoleId: [{ $v: required, $error: "Please select a replacement role." }],
    }
  );

  const handleDelete: FormEventHandler = (e) => {
    e.preventDefault();

    if (!validation.validate().$isValid) {
      return;
    }

    if (replacementRoleId) {
      onConfirm(roleId, replacementRoleId);
    }
  };

  return (
    <Modal size="xs" onClose={onClose} title="Reassign Roles">
      <ModalForm onSubmit={handleDelete}>
        <ModalContent padding="lg">
          <QueryResult queries={[employeeRoles]}>
            {employeeRoleToDelete && replacementRoles && (
              <DeleteRoleModalContent
                replacementRoleId={replacementRoleId}
                employeesAffected={employeeRoleToDelete.employees}
                replacementRoles={replacementRoles}
                errors={{
                  role: validation.result.replacementRoleId.$error,
                }}
                onReplacementRoleChange={setReplacementRoleId}
              />
            )}
          </QueryResult>
        </ModalContent>
        <ModalFooter actions>
          <Button theme="secondary" onClick={onClose} className="min-w-button">
            Cancel
          </Button>
          <AsyncButton type="submit" isLoading={isDeleting} className="min-w-button">
            Apply
          </AsyncButton>
        </ModalFooter>
      </ModalForm>
    </Modal>
  );
};

const DeleteRoleModalContent: FC<{
  replacementRoleId?: number;
  employeesAffected: NameVO[];
  replacementRoles: RoleVO[];
  errors: Record<"role", string | undefined>;
  onReplacementRoleChange: (replacementRoleId: number) => void;
}> = ({ replacementRoleId, employeesAffected, replacementRoles, errors, onReplacementRoleChange }) => {
  return (
    <div className="flex flex-col gap-y-3">
      <div className="text-xs">
        Please select a new role for the following employees who are assigned this role.
      </div>
      <div className="flex flex-col gap-y-4">
        {employeesAffected.map((employee) => (
          <EmployeeNameWithAvatar key={employee.id} employee={employee} />
        ))}
      </div>
      <FormFieldSelect
        placeholder="Select replacement role..."
        required
        options={replacementRoles.map((role) => ({ label: role.name, value: role.roleId }))}
        value={replacementRoleId}
        error={errors.role}
        onItemChanged={(value) => value && onReplacementRoleChange(value)}
      />
    </div>
  );
};

const EmployeeNameWithAvatar: FC<{ employee: NameVO }> = ({ employee }) => {
  return (
    <div className="flex items-center gap-x-2">
      <Avatar size="sm" name={employee.fullDisplayName} />
      <div className="text-xs">{employee.fullDisplayName}</div>
    </div>
  );
};
