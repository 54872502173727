import { FC } from "react";
import { EmailConfigVO, UpdateEmailConfigRequest } from "@libs/api/generated-api";
import { ReactComponent as Notify } from "@libs/assets/components/notify.svg";
import { Divider } from "components/UI/Divider";

import { EmailDnsRecordsTable } from "components/Settings/Communications/EmailDnsRecordsTable";
import {
  SettingsHeroContainer,
  SettingsHeroContent,
  SettingsHeroDescription,
  SettingsHeroDetails,
  SettingsHeroTitle,
} from "components/Settings/SettingsHeroLayout";
import { ExternalLink } from "components/UI/ExternalLink";
import { SendingDomain } from "components/Settings/Communications/SendingDomain";
import { VerifyDomain } from "components/Settings/Communications/VerifyDomain";
import { ChangeDomain } from "components/Settings/Communications/ChangeDomain";

interface Props {
  emailConfig: EmailConfigVO;
  onGenerateDomain: (domain: UpdateEmailConfigRequest["domain"]) => void;
  onVerifyDomain: (checkingVerification: boolean) => void;
  onResetDomain: Func;
  isResettingDomain: boolean;
  isGeneratingDomain: boolean;
  isVerifyingDomain: boolean;
}

export const EmailAuthentication: FC<Props> = ({
  emailConfig,
  onGenerateDomain,
  onVerifyDomain,
  onResetDomain,
  isResettingDomain,
  isGeneratingDomain,
  isVerifyingDomain,
}) => {
  return (
    <div className="flex flex-col gap-y-6 justify-stretch">
      <SettingsHeroContent className="flex flex-col gap-y-6">
        <SettingsHeroContainer image={<Notify />}>
          <SettingsHeroDetails>
            <SettingsHeroTitle>Email Domain Verification</SettingsHeroTitle>
            <SettingsHeroDescription>
              Before you can send emails from Archy, you&apos;ll need to add Archy&apos;s DNS records to your
              domain provider.
            </SettingsHeroDescription>
            <ExternalLink name="emailAuthentication">View Help Guide</ExternalLink>
          </SettingsHeroDetails>
        </SettingsHeroContainer>
        {emailConfig.domainDnsConfig && emailConfig.domain ? (
          <>
            <VerifyDomain
              emailConfig={emailConfig}
              isVerifyingDomain={isVerifyingDomain}
              onVerifyDomain={onVerifyDomain}
            />
            <Divider className="border-dashed" />
            <EmailDnsRecordsTable domainDnsConfig={emailConfig.domainDnsConfig} domain={emailConfig.domain} />
            <Divider className="border-dashed" />
            <ChangeDomain
              isResettingDomain={isResettingDomain}
              onResetDomain={onResetDomain}
              domain={emailConfig.domain}
            />
          </>
        ) : (
          <SendingDomain isGeneratingDomain={isGeneratingDomain} onGenerateDomain={onGenerateDomain} />
        )}
      </SettingsHeroContent>
    </div>
  );
};
