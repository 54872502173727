import { ComponentProps, PropsWithChildren } from "react";
import { LabCaseReturnReasonVO, LabCaseStatusVO } from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import {
  LabCaseMultiStatusIcon,
  LabCaseMultiStatusMenuButton,
  LabCaseStatusIcon,
  LabCaseStatusMenuButton,
} from "components/LabCases/LabComponents";

export const LabCaseMultiStatusIconMenu: React.FC<
  PropsWithChildren<{
    labCaseStatuses: LabCaseStatusVO[];
    returnReasons: LabCaseReturnReasonVO[];
    onStatusChange: ComponentProps<typeof LabCaseMultiStatusMenuButton>["onStatusChange"];
  }>
> = ({ labCaseStatuses, returnReasons, onStatusChange }) => {
  if (labCaseStatuses.length === 0) {
    return null;
  }

  return labCaseStatuses.length > 1 ? (
    <LabCaseMultiStatusMenuButton
      returnReasons={returnReasons}
      labCaseStatuses={labCaseStatuses}
      onStatusChange={onStatusChange}
    >
      <LabCaseMultiStatusIcon shape="square" labCases={labCaseStatuses} size="md" />
    </LabCaseMultiStatusMenuButton>
  ) : (
    <LabCaseStatusMenuButton
      labCaseReturnReasons={returnReasons}
      onStatusChange={(status, reason) => onStatusChange(labCaseStatuses[0].uuid, status, reason)}
    >
      <FloatingTooltip content={sentenceCaseConstant(labCaseStatuses[0].status)} theme="SMALL">
        <div>
          <LabCaseStatusIcon shape="square" size="md" status={labCaseStatuses[0].status} />
        </div>
      </FloatingTooltip>
    </LabCaseStatusMenuButton>
  );
};
