import { FC, useCallback, useMemo, useState } from "react";
import { PatientVO, StatementDateRangeVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { AdjustmentModal } from "components/PatientProfile/Billing/AdjustmentModal";
import { GenerateStatementModal } from "components/PatientProfile/Billing/Ledger/GenerateStatementModal";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { checkPermission } from "components/Roles/roleUtils";

const createLedgerMenuOptions = (canCreateAdjustment: boolean, statementStartDate?: string) => {
  return createMenuOptions(
    {
      label: "Generate Statement",
      value: "generateStatement",
      disabled: !statementStartDate,
      tooltip: {
        content: statementStartDate ? undefined : "There are no entries to generate a statement",
      },
    },
    ...(canCreateAdjustment
      ? [
          {
            label: "Create Adjustment",
            value: "createAdjustment",
          },
        ]
      : [])
  );
};

type LedgerMenuOptions = ReturnType<typeof createLedgerMenuOptions>;
type LedgerMenuOptionValue = ListItem<LedgerMenuOptions>["value"];

export const LedgerMenu: FC<{ patientId: PatientVO["id"]; statementDateRange?: StatementDateRangeVO }> = ({
  patientId,
  statementDateRange,
}) => {
  const [modal, setModal] = useState<LedgerMenuOptionValue | null>(null);
  const closeModal = useCallback(() => setModal(null), []);
  const { roleV2 } = useCurrentUser();
  const menuOptions = useMemo(() => {
    const canCreateAdjustment =
      checkPermission(roleV2, "BILLING", "ADD_ADJUSTMENT_CHARGE").isPermitted ||
      checkPermission(roleV2, "BILLING", "ADD_ADJUSTMENT_DISCOUNT").isPermitted;

    return createLedgerMenuOptions(canCreateAdjustment, statementDateRange?.startDate);
  }, [roleV2, statementDateRange?.startDate]);

  return (
    <>
      <LedgerDropdownMenu onOptionClick={(option) => setModal(option.value)} menuOptions={menuOptions} />
      {modal === "createAdjustment" ? (
        <AdjustmentModal
          onClose={closeModal}
          onAdjustmentSave={closeModal}
          patientId={patientId}
          attachedToInvoice={false}
        />
      ) : modal === "generateStatement" ? (
        <GenerateStatementModal onRequestClose={closeModal} patientId={patientId} />
      ) : null}
    </>
  );
};

const LedgerDropdownMenu: FC<{
  onOptionClick: (option: ListItem<ReturnType<typeof createLedgerMenuOptions>>) => void;
  menuOptions: LedgerMenuOptions;
}> = ({ menuOptions, onOptionClick }) => {
  const menu = useBoolean(false);

  return (
    <ButtonMenu
      placement="bottom-start"
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      menuContent={
        <MenuOptions
          options={menuOptions}
          onOptionClick={(option) => {
            onOptionClick(option);

            menu.off();
          }}
        />
      }
      onRequestClose={menu.off}
    >
      {(props) => {
        return <ButtonIcon {...props} theme="primary" SvgIcon={MenuIcon} size="lg" />;
      }}
    </ButtonMenu>
  );
};
