import { useMemo } from "react";
import {
  PatientVO,
  PaymentCreationRequest,
  PaymentDeviceVO,
  PaymentProfileVO,
  RefundablePaymentVO,
} from "@libs/api/generated-api";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import PosImage from "assets/images/POS.svg";
import { WalletWithFamilyMember } from "components/PatientProfile/Billing/billingUtils";
import {
  CheckNumber,
  CheckBankName,
  PaymentDevices,
  ExternalPosNameOnCard,
  ExternalPosCardNumber,
  AchNumber,
  AchBankName,
  EmptyPaymentMethod,
  Wallets,
} from "components/PatientProfile/Billing/FormComponents";
import { StoredProfileOrRefundableCard } from "components/PatientProfile/Billing/PaymentMethods/StoredProfileOrRefundableCard";
import { SelectRefundableTransaction } from "components/PatientProfile/Billing/PaymentMethods/SelectRefundableTransaction";
import {
  PaymentDraft,
  PaymentDraftUpdateFn,
} from "components/PatientProfile/Billing/Payment/useCollectPaymentForm";

export const cxStyles = {
  sectionHeader: "font-sansSemiBold text-xs",
};

export type CheckPayload = PaymentCreationRequest["checkPayload"];
export type ExternalPosPayload = PaymentCreationRequest["externalPosPayload"];
export type EftPayload = PaymentCreationRequest["eftPayload"];
export type PaymentProfileUuid = PaymentCreationRequest["paymentProfileUuid"];
export type PaymentDeviceUuid = PaymentCreationRequest["paymentDeviceUuid"];
export type WalletUuid = PaymentCreationRequest["walletUuid"];

type ConfigurePaymentMethodProps = {
  paymentDraft: PaymentDraft;
  handleUpdatePaymentDraft: PaymentDraftUpdateFn;
  walletsWithFamilyMembers?: WalletWithFamilyMember[];
  onClickAddCard: Func;
  paymentProfiles: PaymentProfileVO[];
  paymentDevices: PaymentDeviceVO[];
  patientId: PatientVO["id"];
  disabled?: boolean;
  refundablePayments?: RefundablePaymentVO[];
  errors: {
    checkNumber?: string;
    checkBankName?: string;
    eftNumber?: string;
    eftBankName?: string;
    paymentProfile?: string;
    paymentDevice?: string;
    externalPosLastFour?: string;
    externalPosNameOnCard?: string;
  };
};

const ConfigurePaymentMethodContents: React.FC<
  ConfigurePaymentMethodProps
  // eslint-disable-next-line complexity
> = ({
  paymentDraft,
  handleUpdatePaymentDraft,
  errors,
  disabled,
  patientId,
  walletsWithFamilyMembers,
  paymentProfiles,
  onClickAddCard,
  refundablePayments,
  paymentDevices,
}) => {
  const sharedProps = {
    disabled,
  };
  const patientPaymentProfiles = useMemo(
    () => (paymentProfiles.length === 0 ? [] : paymentProfiles),
    [paymentProfiles]
  );
  const {
    paymentMethod,
    selectedWalletUuid,
    selectedRefundablePayment,
    selectedPaymentPosUuid,
    externalPosPayload,
    checkPayload,
    selectedPaymentProfileUuid,
    eftPayload,
  } = paymentDraft;

  return paymentMethod === "WALLET" && walletsWithFamilyMembers ? (
    <Wallets
      patientId={patientId}
      walletsWithFamilyMembers={walletsWithFamilyMembers}
      selectedWalletUuid={selectedWalletUuid}
      onWalletUuidChange={(updatedWalletUuid) => {
        handleUpdatePaymentDraft({
          selectedWalletUuid: updatedWalletUuid,
        });
      }}
      disabled={disabled}
    />
  ) : paymentMethod === "CHECK" ? (
    <>
      <CheckNumber
        {...sharedProps}
        value={checkPayload?.checkNumber}
        onChange={(value) =>
          handleUpdatePaymentDraft({
            checkPayload: {
              bankName: checkPayload?.bankName ?? "",
              checkNumber: value,
            },
          })
        }
        error={errors.checkNumber}
      />
      <CheckBankName
        {...sharedProps}
        value={checkPayload?.bankName}
        onChange={(value) => {
          handleUpdatePaymentDraft({
            checkPayload: {
              bankName: value,
              checkNumber: checkPayload?.checkNumber ?? "",
            },
          });
        }}
        error={errors.checkBankName}
      />
    </>
  ) : paymentMethod === "STORED_PROFILE" ? (
    <StoredProfileOrRefundableCard
      patientId={patientId}
      disabled={disabled}
      paymentProfiles={patientPaymentProfiles}
      errors={errors}
      paymentProfileUuid={selectedPaymentProfileUuid}
      onClickAddCard={onClickAddCard}
      onPaymentProfileUuidChange={(paymentProfileUuid) => {
        handleUpdatePaymentDraft(
          {
            selectedPaymentProfileUuid: paymentProfileUuid,
          },
          { shouldUpdateMultipaymentInvoice: true }
        );
      }}
    />
  ) : paymentMethod === "REFUNDABLE_CARD" && refundablePayments ? (
    <SelectRefundableTransaction
      selectedRefundablePayment={selectedRefundablePayment}
      onChangeRefundablePayment={(refundablePayment) => {
        handleUpdatePaymentDraft(
          {
            selectedRefundablePayment: refundablePayment,
          },
          { shouldUpdateMultipaymentInvoice: true }
        );
      }}
      disabled={disabled}
      refundablePayments={refundablePayments}
    />
  ) : paymentMethod === "STORED_POS" ? (
    <>
      {paymentDevices.length ? (
        <PaymentDevices
          paymentDevices={paymentDevices}
          paymentDeviceUuid={selectedPaymentPosUuid}
          onPaymentDeviceUuidChange={(uuid) => {
            handleUpdatePaymentDraft({
              selectedPaymentPosUuid: uuid,
            });
          }}
          disabled={disabled}
        />
      ) : (
        <div className="col-span-2">
          <EmptyPaymentMethod
            message={"No POS found. Please register a POS in the practice's settings first."}
            image={PosImage}
          />
        </div>
      )}
      {errors.paymentDevice && (
        <FormFieldError className="col-start-1">{errors.paymentDevice}</FormFieldError>
      )}
    </>
  ) : paymentMethod === "EXTERNAL_POS" ? (
    <>
      <ExternalPosNameOnCard
        {...sharedProps}
        value={externalPosPayload?.nameOnCard}
        onChange={(value) =>
          handleUpdatePaymentDraft({
            externalPosPayload: {
              nameOnCard: value,
              cardLastFour: externalPosPayload?.cardLastFour ?? "",
            },
          })
        }
        error={errors.externalPosNameOnCard}
      />
      <ExternalPosCardNumber
        {...sharedProps}
        value={externalPosPayload?.cardLastFour}
        onChange={(value) =>
          handleUpdatePaymentDraft({
            externalPosPayload: {
              nameOnCard: externalPosPayload?.nameOnCard ?? "",
              cardLastFour: value,
            },
          })
        }
        error={errors.externalPosLastFour}
      />
    </>
  ) : paymentMethod === "EFT" ? (
    <>
      <AchNumber
        {...sharedProps}
        value={eftPayload?.eftNumber}
        onChange={(value) =>
          handleUpdatePaymentDraft({
            eftPayload: {
              bankName: eftPayload?.bankName ?? "",
              eftNumber: value,
            },
          })
        }
        error={errors.eftNumber}
      />
      <AchBankName
        {...sharedProps}
        value={eftPayload?.bankName}
        onChange={(value) => {
          handleUpdatePaymentDraft({
            eftPayload: {
              bankName: value,
              eftNumber: eftPayload?.eftNumber ?? "",
            },
          });
        }}
        error={errors.eftBankName}
      />
    </>
  ) : null;
};

export const ConfigurePaymentMethod: React.FC<
  ConfigurePaymentMethodProps
  // eslint-disable-next-line complexity
> = (props) => {
  return (
    <div className="grid grid-cols-2 gap-6">
      <ConfigurePaymentMethodContents {...props} />
    </div>
  );
};
