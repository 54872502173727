import { ParentSize } from "@visx/responsive";
import { TimeSeriesResponse } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { ChartContainer } from "components/Dashboard/Charting/ChartContainer";
import { DashboardTimeseriesChart } from "components/Dashboard/Charting/DashboardTimeseriesChart";
import { ColorScheme } from "components/Dashboard/Charting/theme";
import { BarStackData } from "components/Dashboard/Charting/types";
import { useProvidersAsTimeSeriesData } from "components/Dashboard/PayrollHours/hooks/useProvidersAsTimeSeriesData";
import { labelForJobCategory } from "components/Dashboard/PayrollHours/utils";
import { useBarStackDecorators } from "components/Dashboard/hooks/useBarStackDecorators";
import { useFocusDateClick } from "components/Dashboard/hooks/useFocusDateClick";
import { TimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { SelectDecimalVsClock } from "components/Employee/SelectDecimalVsClock";
import {
  DashboardChartDisplay,
  PayrollHoursQuery,
  TimeSeriesResolutionOption,
} from "utils/routing/dashboard";
import { FilteredProviderType } from "utils/routing/dashboard/serializedFilter";

type ContentProps = {
  width: number;
  height: number;
  resolution: TimeSeriesResolutionOption;
  dateWindow: TimeSeriesPageSelections;
  keys: FilteredProviderType[];
  focusDate?: Date;
  chartDisplay: DashboardChartDisplay;
  data: BarStackData<FilteredProviderType>[];
  isLoading: boolean;
  isFirstLoad: boolean;
  onUpdateParams: (update: Partial<PayrollHoursQuery>) => void;
  colorScheme: ColorScheme<FilteredProviderType>;
  formatWorkingSeconds: (seconds: number) => string;
};

export const PayrollHoursChartContent: React.FC<ContentProps> = ({
  dateWindow,
  width,
  height,
  resolution,
  chartDisplay,
  focusDate,
  data,
  keys,
  onUpdateParams,
  formatWorkingSeconds,
  isLoading,
  isFirstLoad,
  colorScheme,
}) => {
  const barDecorators = useBarStackDecorators(data, focusDate, resolution);
  const handleDateClicked = useFocusDateClick({ handleParamsChange: onUpdateParams, focusDate });

  return (
    <DashboardTimeseriesChart
      data={data}
      resolution={resolution}
      randomizeLoadingBars={isFirstLoad}
      timeSegment={dateWindow}
      width={width}
      height={height}
      chartDisplay={chartDisplay}
      isLoading={isLoading}
      colorScheme={colorScheme}
      keys={keys}
      formatValue={formatWorkingSeconds}
      onClickDate={handleDateClicked}
      labelFormat={labelForJobCategory}
      {...barDecorators}
    />
  );
};
type Props = {
  workingHourTimeseriesQuery: ApiQueryResult<TimeSeriesResponse>;
  children: React.ReactNode;
  hoursInDecimal: boolean;
} & Omit<ContentProps, "width" | "height" | "data" | "colorScheme" | "isLoading" | "isFirstLoad" | "keys">;
export const PayrollHoursChart: React.FC<Props> = ({
  dateWindow,
  workingHourTimeseriesQuery,
  resolution,
  children,
  hoursInDecimal,
  onUpdateParams,
  ...rest
}) => {
  const timeSeriesProps = useProvidersAsTimeSeriesData({
    workingHourTimeseriesQuery,
    dateWindow,
    resolution,
  });

  return (
    <ChartContainer disabledMessage={dateWindow.invalidTimeSeriesParamsMessage}>
      <ParentSize className="flex-1 relative">
        {({ height, width }: { width: number; height: number }) => {
          return (
            <>
              <SelectDecimalVsClock
                className="absolute top-0 left-0 z-10"
                value={hoursInDecimal ? "decimal" : "clock"}
                onItemSelected={(value) => onUpdateParams({ hoursInDecimal: value === "decimal" })}
              />

              <PayrollHoursChartContent
                resolution={resolution}
                width={width}
                height={height}
                dateWindow={dateWindow}
                onUpdateParams={onUpdateParams}
                {...rest}
                {...timeSeriesProps}
              />
            </>
          );
        }}
      </ParentSize>
      {children}
    </ChartContainer>
  );
};
