import { useCallback, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { CompanyOnboardingStatusVO } from "@libs/api/generated-api";
import { useBoolean, UseBooleanResult } from "@libs/hooks/useBoolean";
import { getAbsoluteUrl } from "@libs/utils/location";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { onboardNewPractice } from "api/payroll/mutations";

import { useItemModal } from "hooks/useItemModal";

import { handleError } from "utils/handleError";
import { paths, routesConfig } from "utils/routing/paths";
import { useEnvContext } from "contexts/EnvContext";
import { useOrigin } from "contexts/OriginContext";

export const useOnboardingFlow = (onboardingStatus: CompanyOnboardingStatusVO["status"]) => {
  const gustoOnboardingOverlay = useBoolean(false);
  const navigate = useNavigate();

  const linkToGustoPracticeFlow = useCallback(() => navigate(paths.gusto()), [navigate]);

  const openGustoOnboardingFlow = useCallback(() => {
    if (gustoOnboardingOverlay.isOn && onboardingStatus === "NEEDS_ONBOARDING") {
      linkToGustoPracticeFlow();
    }
  }, [gustoOnboardingOverlay, onboardingStatus, linkToGustoPracticeFlow]);

  // Only openGustoOnboardingFlow after initial gustoOnboardingOverlay and status is NEEDS_ONBOARDING
  useEffect(() => openGustoOnboardingFlow(), [openGustoOnboardingFlow]);

  return {
    gustoOnboardingOverlay,
    linkToGustoPracticeFlow,
  };
};

export const useMigrationFlow = (onboardingStatus: CompanyOnboardingStatusVO["status"]) => {
  const gustoMigrationModal = useBoolean(false);

  const openGustoMigrationModal = useCallback(() => {
    if (onboardingStatus === "NEEDS_MIGRATION" && gustoMigrationModal.isOff) {
      gustoMigrationModal.on();
    }
  }, [onboardingStatus, gustoMigrationModal]);

  // Always openGustoMigrationModal until status changes from NEEDS_MIGRATION to ONBOARDED
  useEffect(() => openGustoMigrationModal(), [openGustoMigrationModal]);

  return { gustoMigrationModal };
};

export const useSetupFlow = (gustoOnboardingOverlay: UseBooleanResult) => {
  const { REACT_APP_GUSTO_OAUTH2_URL, REACT_APP_GUSTO_CLIENT_ID } = useEnvContext();
  const gustoTosModal = useItemModal<{ setupPractice: boolean }>(null);
  const { practiceId } = useAccount();
  const origin = useOrigin();
  const [{ mutate: onboardNewPracticeMutate, isLoading: isOnboardingNewPractice }] = useApiMutations([
    onboardNewPractice,
  ]);

  const linkToGustoOauth = useCallback(() => {
    // can be empty for testing
    if (!REACT_APP_GUSTO_OAUTH2_URL || !REACT_APP_GUSTO_CLIENT_ID) {
      return;
    }

    const redirectUri = getAbsoluteUrl(origin, routesConfig.authenticateGusto.path);

    window.location.assign(
      `${REACT_APP_GUSTO_OAUTH2_URL}?client_id=${REACT_APP_GUSTO_CLIENT_ID}&redirect_uri=${redirectUri}&response_type=code`
    );
  }, [REACT_APP_GUSTO_CLIENT_ID, REACT_APP_GUSTO_OAUTH2_URL, origin]);

  const handleTosAccept = useCallback(() => {
    gustoTosModal.close();

    if (gustoTosModal.item?.setupPractice) {
      onboardNewPracticeMutate(
        { practiceId },
        {
          onSuccess: gustoOnboardingOverlay.on,
          onError: handleError,
        }
      );
    } else {
      linkToGustoOauth();
    }
  }, [linkToGustoOauth, gustoTosModal, onboardNewPracticeMutate, practiceId, gustoOnboardingOverlay]);

  return {
    gustoTosModal,
    handleTosAccept,
    linkToGustoOauth,
    isOnboardingNewPractice,
  };
};
