import {
  SendMessageCampaignRequest,
  MessageCampaignRequest,
  UpdateMessageCampaignRequest,
  MessageCampaignVO,
  PreviewMessageCampaignRequest,
  CreateSavedFilterRequest,
  SavedFilterVO,
  AutomationActionRequest,
  AutomationJourneyVO,
  PreviewSmsAutomationRequest,
  PreviewEmailAutomationRequest,
  TwilioOnboardingStatusVO,
  TwilioPhoneNumberPurchaseRequest,
} from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import { updateCachedData, updatePaginatedCachedData } from "@libs/utils/queryCache";
import { formatPhoneNumber } from "@libs/utils/phone";

export const createAutomationAction = makeMutation({
  mutationKey: ["practices", "createAutomationAction"],
  formatParams: (args: {
    practiceId: number;
    journeyType: AutomationJourneyVO["type"];
    data: AutomationActionRequest;
  }) => [args.practiceId, args.journeyType, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, journeyType }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getAutomationJourney"),
        { practiceId, journeyType },
      ]);
    },
  }),
});

export const updateAutomationAction = makeMutation({
  mutationKey: ["practices", "updateAutomationAction"],
  formatParams: (args: {
    practiceId: number;
    journeyType: AutomationJourneyVO["type"];
    actionUuid: string;
    data: AutomationActionRequest;
  }) => [args.practiceId, args.journeyType, args.actionUuid, args.data],
});

export const deleteAutomationAction = makeMutation({
  mutationKey: ["practices", "deleteAutomationAction"],
  formatParams: (args: {
    practiceId: number;
    journeyType: AutomationJourneyVO["type"];
    actionUuid: string;
  }) => [args.practiceId, args.journeyType, args.actionUuid],
  mutationOptions: (queryClient) => ({
    onMutate: ({ practiceId, journeyType, actionUuid }) => {
      updateCachedData<AutomationJourneyVO>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getAutomationJourney"), { journeyType, practiceId }] },
        (cachedEntry) => {
          return {
            ...cachedEntry,
            actions: cachedEntry.actions.filter((action) => action.uuid !== actionUuid),
          };
        }
      );
    },
    onError: (_error, { practiceId, journeyType }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getAutomationJourney"),
        { practiceId, journeyType },
      ]);
    },
  }),
});

export const cancelScheduledMessage = makeMutation({
  mutationKey: ["practices", "cancelScheduledMessage"],
  formatParams: (args: {
    practiceId: number;
    messageId: number;
    query?: { cancelAllInJourney?: boolean };
  }) => [args.practiceId, args.messageId, args.query],
});

export const sendMessageCampaign = makeMutation({
  mutationKey: ["practices", "sendMessageCampaign"],
  formatParams: (args: { practiceId: number; data: SendMessageCampaignRequest }) => [
    args.practiceId,
    args.data,
  ],
});

export const createMessageCampaign = makeMutation({
  mutationKey: ["practices", "createMessageCampaign"],
  formatParams: (args: { practiceId: number; data: MessageCampaignRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "listMessageCampaigns"), { practiceId }]);
    },
  }),
});

export const updateMessageCampaign = makeMutation({
  mutationKey: ["practices", "updateMessageCampaign"],
  formatParams: (args: {
    practiceId: number;
    messageCampaignUuid: string;
    data: UpdateMessageCampaignRequest;
  }) => [args.practiceId, args.messageCampaignUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId, messageCampaignUuid }) => {
      updateCachedData<MessageCampaignVO>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getMessageCampaign"), { practiceId, messageCampaignUuid }],
        },
        () => data.data.data
      );
      queryClient.invalidateQueries([getQueryKey("practices", "listMessageCampaigns"), { practiceId }]);
    },
  }),
});

export const triggerMessageCampaign = makeMutation({
  mutationKey: ["practices", "triggerMessageCampaign"],
  formatParams: (args: { practiceId: number; messageCampaignUuid: string }) => [
    args.practiceId,
    args.messageCampaignUuid,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId, messageCampaignUuid }) => {
      updateCachedData<MessageCampaignVO>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getMessageCampaign"), { practiceId, messageCampaignUuid }],
        },
        () => data.data.data
      );
      queryClient.invalidateQueries([getQueryKey("practices", "listMessageCampaigns"), { practiceId }]);
    },
  }),
});

export const deleteDraftMessageCampaign = makeMutation({
  mutationKey: ["practices", "deleteDraftMessageCampaign"],
  formatParams: (args: { practiceId: number; messageCampaignUuid: string }) => [
    args.practiceId,
    args.messageCampaignUuid,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, messageCampaignUuid }) => {
      updateCachedData(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "listMessageCampaigns"), { practiceId }],
        },
        (cacheEntry: MessageCampaignVO[]) => {
          return cacheEntry.filter((campaign) => campaign.uuid !== messageCampaignUuid);
        }
      );
    },
  }),
});

export const previewMessageCampaign = makeMutation({
  mutationKey: ["practices", "previewMessageCampaign"],
  formatParams: (args: {
    practiceId: number;
    messageCampaignUuid: string;
    data: PreviewMessageCampaignRequest;
  }) => [args.practiceId, args.messageCampaignUuid, args.data],
});

export const previewSmsAutomation = makeMutation({
  mutationKey: ["practices", "previewSmsAutomation"],
  formatParams: (args: {
    practiceId: number;
    journeyType: AutomationJourneyVO["type"];
    data: PreviewSmsAutomationRequest;
  }) => [args.practiceId, args.journeyType, args.data],
});

export const previewEmailAutomation = makeMutation({
  mutationKey: ["practices", "previewEmailAutomation"],
  formatParams: (args: {
    practiceId: number;
    journeyType: AutomationJourneyVO["type"];
    data: PreviewEmailAutomationRequest;
  }) => [args.practiceId, args.journeyType, args.data],
});

export const cancelMessageCampaign = makeMutation({
  mutationKey: ["practices", "cancelMessageCampaign"],
  formatParams: (args: { practiceId: number; messageCampaignUuid: string }) => [
    args.practiceId,
    args.messageCampaignUuid,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId, messageCampaignUuid }) => {
      updateCachedData<MessageCampaignVO>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getMessageCampaign"), { practiceId, messageCampaignUuid }],
        },
        () => data.data.data
      );
      queryClient.invalidateQueries([getQueryKey("practices", "listMessageCampaigns"), { practiceId }]);
    },
  }),
});

export const createSavedFilter = makeMutation({
  mutationKey: ["practices", "createSavedFilter"],
  formatParams: (args: { practiceId: number; data: CreateSavedFilterRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "listSavedFilters"), { practiceId }]);
    },
  }),
});

export const deleteSavedFilter = makeMutation({
  mutationKey: ["practices", "deleteSavedFilter"],
  formatParams: (args: { practiceId: number; savedFilterUuid: string }) => [
    args.practiceId,
    args.savedFilterUuid,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, savedFilterUuid }) => {
      updatePaginatedCachedData(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "listSavedFilters"), { practiceId }],
        },
        (cacheEntry: SavedFilterVO[]) => {
          return cacheEntry.filter((savedFilter) => savedFilter.uuid !== savedFilterUuid);
        }
      );
    },
  }),
});

export const initTwilioSubaccountAndProfile = makeMutation({
  mutationKey: ["practices", "initTwilioSubaccountAndProfile"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      updateCachedData<TwilioOnboardingStatusVO>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getTwilioRegistrationStatus"), { practiceId }] },
        (cachedEntry) => ({ ...cachedEntry, status: "IN_PROGRESS" })
      );
    },
  }),
});

export const purchaseTwilioPhoneNumber = makeMutation({
  mutationKey: ["practices", "purchaseTwilioPhoneNumber"],
  formatParams: (args: { practiceId: number; data: TwilioPhoneNumberPurchaseRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, data }) => {
      updateCachedData<TwilioOnboardingStatusVO>(
        queryClient,
        { queryKey: [getQueryKey("practices", "getTwilioRegistrationStatus"), { practiceId }] },
        () => {
          const countryCodeIndex = 2;
          const practiceCommsNumber = String(
            formatPhoneNumber(data.phoneNumberToPurchase.slice(countryCodeIndex))
          );

          return { status: "IN_PROGRESS", practiceCommsNumber };
        }
      );
    },
  }),
});
