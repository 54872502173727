import { useEffect, useRef } from "react";

/**
 * Allows you to get the last focused element, so you can potentially return focus to it.
 * This does not trigger re-renders on focus change.
 * @returns A ref to the last focus element.
 */
export const useLastFocus = () => {
  const focusRef = useRef<HTMLElement>();

  useEffect(() => {
    const focusHandler = (e: FocusEvent) => {
      focusRef.current = e.target as HTMLElement;
    };

    document.addEventListener("focusout", focusHandler, true);

    return () => {
      document.removeEventListener("focusout", focusHandler, true);
    };
  }, []);

  return focusRef;
};
