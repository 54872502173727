import React from "react";
import { useReactToPrint } from "react-to-print";
import { PatientVO, PerioChartExamVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { PerioValueCompareTable } from "components/Charting/Perio/PerioCompare/PerioValueCompareTable";
import { PerioCompareSitesTrendTables } from "components/Charting/Perio/PerioCompare/PerioCompareSitesTrendTables";
import { OFFSCREEN_RENDER_ORIGIN } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/shapeUtils";

export type PrintOption = "sites" | "comparison";
type Props = {
  exams: PerioChartExamVO[];
  sequenceType: "GINGMARGIN" | "PROBING";
  printOptions: Set<PrintOption>;
  onAfterPrint: Func;
  patient: PatientVO;
};

const Header: React.FC<Pick<Props, "patient"> & { title: string }> = ({ patient, title }) => {
  return (
    <div className="flex flex-col gap-4 mb-4">
      <div className="text-sm">
        <span className="font-sansSemiBold">Patient: {patient.personalDetails.fullName}</span>{" "}
        {formatISODate(patient.personalDetails.dob)}
      </div>
      <div className="font-sansSemiBold">{title}</div>
    </div>
  );
};

export const PerioComparePrinted: React.FC<Props> = ({
  exams,
  sequenceType,
  onAfterPrint,
  printOptions,
  patient,
}) => {
  const documentTitle =
    sequenceType === "PROBING" ? "Probing Depth Comparison" : "Gingival Margin Comparison";
  const printRef = React.useRef<HTMLDivElement | null>(null);
  const handlePrint = useReactToPrint({
    content: () => printRef.current,
    documentTitle,
    pageStyle: "@page { size: letter landscape; margin: 25px; }",
    onAfterPrint,
  });

  return (
    <div style={OFFSCREEN_RENDER_ORIGIN}>
      <div
        className="flex flex-col"
        ref={(c) => {
          if (c) {
            printRef.current = c;
            handlePrint();
          }
        }}
      >
        {printOptions.has("comparison") && (
          <>
            <Header patient={patient} title={documentTitle} />
            <PerioValueCompareTable exams={exams} sequenceType={sequenceType} />
            <div className="break-after-page" />
          </>
        )}
        {printOptions.has("sites") && (
          <>
            <Header patient={patient} title="Site Summary" />
            <PerioCompareSitesTrendTables exams={exams} sequenceType={sequenceType} />
          </>
        )}
      </div>
    </div>
  );
};
