import { FC } from "react";

export const DragToCreateAppointmentCard: FC = () => {
  return (
    <div
      className={`
        h-full
        w-full
        bg-actionLight
        border
        border-greyLighter
        rounded
      `}
    />
  );
};
