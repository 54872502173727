import React, { FC } from "react";
import { scaleOrdinal } from "@visx/scale";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import { ParentSize } from "@visx/responsive";

import { TimeSeriesResponse } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { formatCurrency } from "@libs/utils/currency";
import { CHART_COLORS, colorForKey, ColorScheme } from "components/Dashboard/Charting/theme";
import { RevenueType } from "components/Dashboard/PracticeProduction/types";
import { useTimeseriesTemplateForResolution } from "components/Dashboard/hooks/useTimeseriesTemplateForResolution";
import { DEFAULT_Y_AXIS_MAX_CENTS, getMaximumYAxis } from "components/Dashboard/Charting/utils";
import { ChartContainer } from "components/Dashboard/Charting/ChartContainer";
import { TimeSeriesQuery, TimeSeriesResolutionOption } from "components/Dashboard/types";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";

import { DashboardChartTooltip } from "components/Dashboard/Charting/DashboardChartTooltip";
import { labelForRevenueType } from "components/Dashboard/PracticeProduction/utils";
import { getStackTotals } from "components/Dashboard/utils/barStacks";
import { BarStackData, BarStackGroup, LineSeriesData } from "components/Dashboard/Charting/types";
import { useTimeseriesAsStacks } from "components/Dashboard/Charting/hooks/useTimeseriesAsStacks";
import { VisxGroupedBarStacks } from "components/Dashboard/Charting/VisxGroupedBarStacks";
import { useBarStackDecorators } from "components/Dashboard/hooks/useBarStackDecorators";
import { TimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { useStackMarginsAndScales } from "components/Dashboard/Charting/hooks/useStackMarginsAndScales";
import { DashboardChartDisplay, DashboardQueryWithDateNavigation } from "utils/routing/dashboard";
import { BarChartLayout } from "components/Dashboard/Charting/BarChartLayout";
import { DashboardLineChart } from "components/Dashboard/Charting/DashboardLineChart";
import { LineChartLayout } from "components/Dashboard/Charting/LineChartLayout";
import { getNumberOfTicks, useDateFormatter } from "components/Dashboard/hooks/useDateFormatter";
import { useFocusDateClick } from "components/Dashboard/hooks/useFocusDateClick";
import { getTooltipLabel } from "components/Dashboard/Charting/getTooltipLabel";

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "white",
};
const practiceProductionKeys: RevenueType[] = [
  "grossProduction",
  "netProduced",
  "grossScheduled",
  "netScheduled",
];
const colorScheme: ColorScheme<RevenueType> = {
  domain: practiceProductionKeys,
  range: [CHART_COLORS.blueBright, CHART_COLORS.blue, CHART_COLORS.greenBright, CHART_COLORS.greenDark],
};
const colorScale = scaleOrdinal<RevenueType, string>(colorScheme);

export const getGroupedStackTotals = <T extends string>(data: BarStackGroup<T>[]) => {
  return data.map((val) => getStackTotals(val.stacks));
};

const DIMENSION_KEYS = ["true", "false"];

// eslint-disable-next-line complexity
const useTimeseriesProductionData = ({
  dateWindow,
  resolution,
  grossProductionQuery,
  netProducedQuery,
}: {
  dateWindow: TimeSeriesPageSelections;
  resolution: TimeSeriesResolutionOption;
  grossProductionQuery: TimeSeriesQuery;
  netProducedQuery: TimeSeriesQuery;
}) => {
  //We store last data, to be able to represent the last state while loading new one

  const timeline = useTimeseriesTemplateForResolution({ resolution, dateWindow });

  const isLoading =
    grossProductionQuery.isInitialLoading ||
    grossProductionQuery.isLoading ||
    netProducedQuery.isInitialLoading ||
    netProducedQuery.isLoading;

  // True means isScheduled=true as the dimension
  const grossProductionStacks = useTimeseriesAsStacks(timeline, DIMENSION_KEYS, grossProductionQuery.data);
  const netProducedStacks = useTimeseriesAsStacks(timeline, DIMENSION_KEYS, netProducedQuery.data);

  const data: BarStackGroup<RevenueType>[] = React.useMemo(() => {
    return grossProductionStacks.map(({ date, false: grossProduction, true: grossScheduled }, i) => {
      const netProducedIsScheduled = netProducedStacks[i];

      return {
        date,
        stacks: [
          { grossProduction, grossScheduled },
          {
            netProduced: netProducedIsScheduled.false,
            netScheduled: netProducedIsScheduled.true,
          },
        ],
      };
    });
  }, [grossProductionStacks, netProducedStacks]);
  const lineSeries: LineSeriesData<RevenueType> = React.useMemo(() => {
    return {
      netProduced: netProducedStacks.map(({ date, false: netProduced }) => ({
        date,
        value: netProduced,
        key: "netProduced" as const,
      })),
      netScheduled: netProducedStacks.map(({ date, true: netScheduled }) => ({
        date,
        value: netScheduled,
        key: "netScheduled" as const,
      })),
      grossScheduled: grossProductionStacks.map(({ date, true: grossScheduled }) => ({
        date,
        value: grossScheduled,
        key: "grossScheduled" as const,
      })),
      grossProduction: grossProductionStacks.map(({ date, false: grossProduction }) => ({
        date,
        value: grossProduction,
        key: "grossProduction" as const,
      })),
    };
  }, [grossProductionStacks, netProducedStacks]);

  const [lastData, setLastData] = React.useState<BarStackGroup<RevenueType>[]>();
  const [lastSeries, setLastSeries] = React.useState<LineSeriesData<RevenueType>>();

  const maximumValue = isLoading
    ? lastData
      ? getMaximumYAxis({
          yValues: getGroupedStackTotals(lastData).flat(),
          resolution,
          isCurrency: true,
        })
      : DEFAULT_Y_AXIS_MAX_CENTS
    : getMaximumYAxis({
        yValues: getGroupedStackTotals(data).flat(),
        resolution,
        isCurrency: true,
      });

  React.useEffect(() => {
    if (!isLoading) {
      setLastData(data);
      setLastSeries(lineSeries);
    }
  }, [data, isLoading, lineSeries]);
  React.useEffect(() => {
    setLastData(undefined);
    setLastSeries(undefined);
  }, [resolution]);

  return {
    data: isLoading ? lastData ?? data : data,
    maximumValue,
    isFirstLoad: isLoading && !lastData,
    resolution,
    isLoading,
    lineSeries: isLoading ? lastSeries ?? lineSeries : lineSeries,
    loadingData: React.useMemo(() => {
      // To show the loading state, we just mock up one bar, as the sum of production
      const result: BarStackData<"productionSum">[] = (isLoading ? lastData ?? data : data).flatMap(
        (item) => ({
          date: item.date,
          productionSum: (item.stacks[0].grossProduction ?? 0) + (item.stacks[0].grossScheduled ?? 0),
        })
      );

      return result as unknown as BarStackData<RevenueType>[];
    }, [data, isLoading, lastData]),
  };
};

type PracticeProductionProps = {
  resolution: TimeSeriesResolutionOption;
  filters: DashboardFilter[];
  productionData?: TimeSeriesResponse;
  focusDate?: Date;
  dateWindow: TimeSeriesPageSelections;
  onUpdateParams: (update: Partial<DashboardQueryWithDateNavigation>) => void;
  children: React.ReactNode;
  netProducedQuery: TimeSeriesQuery;
  grossProductionQuery: TimeSeriesQuery;
  chartDisplay: DashboardChartDisplay;
};

export const PracticeProductionChartContent: FC<
  Omit<PracticeProductionProps, "children"> & {
    width: number;
    height: number;
  }
> = ({
  width,
  height,
  resolution,
  focusDate,
  netProducedQuery,
  grossProductionQuery,
  dateWindow,
  onUpdateParams,
  chartDisplay,
}) => {
  const { data, maximumValue, isFirstLoad, loadingData, isLoading, lineSeries } = useTimeseriesProductionData(
    {
      dateWindow,
      resolution,
      netProducedQuery,
      grossProductionQuery,
    }
  );

  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
    useTooltip<BarStackGroup<RevenueType>>();
  const formatDate = useDateFormatter({ ...dateWindow, resolution, dataPointCount: data.length });
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });
  const marginsAndScales = useStackMarginsAndScales({
    width,
    height,
    data,
    yDomain: { min: 0, max: maximumValue },
    resolution,
  });
  const { margins, xScale, yScale, yMax } = marginsAndScales;

  const handleDateClicked = useFocusDateClick({ handleParamsChange: onUpdateParams, focusDate });

  const barDecorators = useBarStackDecorators(data, focusDate, resolution);
  const { selectedBarIndex, dottedBarIndex } = barDecorators;
  const dataPointCount = data.length;
  const numTicksXAxis = getNumberOfTicks(resolution, dataPointCount);
  const formatTooltipLabel = React.useCallback(
    (date: string) => {
      return getTooltipLabel({
        dateString: date,
        dateWindow: {
          startDate: dateWindow.startDate,
          endDate: dateWindow.endDate,
        },
        resolution,
      });
    },
    [dateWindow.endDate, dateWindow.startDate, resolution]
  );

  return chartDisplay === "line" ? (
    <LineChartLayout
      width={width}
      height={height}
      innerRef={containerRef}
      colorScale={colorScale}
      formatTooltipDate={formatTooltipLabel}
      formatDate={formatDate}
      keys={practiceProductionKeys}
      onClickDate={handleDateClicked}
      labelFormat={labelForRevenueType}
      isCurrency
      selectedDateIndex={selectedBarIndex}
      dottedDateIndex={dottedBarIndex}
      numTicksXAxis={numTicksXAxis}
    >
      <DashboardLineChart data={lineSeries} keys={practiceProductionKeys} colorScheme={colorScheme} />
    </LineChartLayout>
  ) : (
    <>
      <BarChartLayout
        width={width}
        height={height}
        isLoading={isLoading}
        isFirstLoad={isFirstLoad}
        tooltipOpen={tooltipOpen}
        innerRef={containerRef}
        loadingData={loadingData}
        colorScale={colorScale}
        formatDate={formatDate}
        labelFormat={labelForRevenueType}
        isCurrency
        numTicksXAxis={numTicksXAxis}
        {...marginsAndScales}
      >
        <VisxGroupedBarStacks
          keys={practiceProductionKeys}
          onHideTooltip={hideTooltip}
          onShowTooltip={showTooltip}
          onClickGroup={handleDateClicked}
          xScale={xScale}
          yScale={yScale}
          data={data}
          yMax={yMax}
          tooltipData={tooltipData}
          barColorScale={colorScale}
          marginBottom={margins.chartMargin.bottom}
          selectedGroupIndex={selectedBarIndex}
          dottedGroupIndex={dottedBarIndex}
        />
      </BarChartLayout>

      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
          className="space-y-1 bg-white text-greyDark py-4"
        >
          <DashboardChartTooltip
            formatValue={formatCurrency}
            dateLabel={formatTooltipLabel(tooltipData.date)}
            items={practiceProductionKeys.map((revenueType) => {
              return {
                value: tooltipData.stacks.find((item) => isDefined(item[revenueType]))?.[revenueType] ?? 0,
                label: labelForRevenueType(revenueType),
                color: colorForKey(colorScheme, revenueType),
              };
            })}
          />
        </TooltipInPortal>
      )}
    </>
  );
};

export const PracticeProductionChart: FC<PracticeProductionProps> = ({
  children,
  dateWindow,

  ...rest
}) => {
  return (
    <ChartContainer disabledMessage={dateWindow.invalidTimeSeriesParamsMessage}>
      <ParentSize className="flex-1 relative">
        {({ height, width }: { width: number; height: number }) => {
          return (
            <PracticeProductionChartContent width={width} height={height} dateWindow={dateWindow} {...rest} />
          );
        }}
      </ParentSize>
      {children}
    </ChartContainer>
  );
};
