import { FC, useEffect, useState, Fragment } from "react";
import {
  $createParagraphNode,
  $getSelection,
  $isRangeSelection,
  FORMAT_TEXT_COMMAND,
  LexicalEditor,
  createCommand,
  COMMAND_PRIORITY_EDITOR,
  FORMAT_ELEMENT_COMMAND,
} from "lexical";
import { mergeRegister } from "@lexical/utils";
import { $createHeadingNode, HeadingTagType, HeadingNode } from "@lexical/rich-text";
import {
  INSERT_ORDERED_LIST_COMMAND,
  INSERT_UNORDERED_LIST_COMMAND,
  REMOVE_LIST_COMMAND,
  ListNode,
  ListItemNode,
} from "@lexical/list";

import { $setBlocksType } from "@lexical/selection";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { ListPlugin } from "@lexical/react/LexicalListPlugin";
import { cx } from "@libs/utils/cx";
import { isOneOf } from "@libs/utils/isOneOf";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as BoldIcon } from "@libs/assets/icons/bold.svg";
import { ReactComponent as ItalicIcon } from "@libs/assets/icons/italic.svg";
import { ReactComponent as UnderlineIcon } from "@libs/assets/icons/underline.svg";
import { ReactComponent as OLIcon } from "@libs/assets/icons/ordered-list.svg";
import { ReactComponent as ULIcon } from "@libs/assets/icons/unordered-list.svg";
import { ReactComponent as H1Icon } from "@libs/assets/icons/h1.svg";
import { ReactComponent as H2Icon } from "@libs/assets/icons/h2.svg";
import { ReactComponent as AlignLeftIcon } from "@libs/assets/icons/align-left.svg";
import { ReactComponent as AlignCenterIcon } from "@libs/assets/icons/align-center.svg";
import { ReactComponent as AlignRightIcon } from "@libs/assets/icons/align-right.svg";

const INSERT_HEADING_COMMAND = createCommand();
const REMOVE_HEADING_COMMAND = createCommand();

type BlockTypes = "h1" | "h2" | "ol" | "ul";

const actionSets: {
  label: string;
  Icon: IconComponent;
  command: Parameters<LexicalEditor["dispatchCommand"]>;
  blockType?: BlockTypes;
}[][] = [
  [
    {
      label: "Title Heading",
      Icon: H1Icon,
      command: [INSERT_HEADING_COMMAND, "h1"],
      blockType: "h1",
    },
    {
      label: "Subtitle Heading",
      Icon: H2Icon,
      command: [INSERT_HEADING_COMMAND, "h2"],
      blockType: "h2",
    },
  ],
  [
    {
      label: "Bold",
      Icon: BoldIcon,
      command: [FORMAT_TEXT_COMMAND, "bold"],
    },
    {
      label: "Italic",
      Icon: ItalicIcon,
      command: [FORMAT_TEXT_COMMAND, "italic"],
    },
    {
      label: "Underline",
      Icon: UnderlineIcon,
      command: [FORMAT_TEXT_COMMAND, "underline"],
    },
  ],
  [
    {
      label: "Ordered List",
      Icon: OLIcon,
      command: [INSERT_ORDERED_LIST_COMMAND, undefined],
      blockType: "ol",
    },
    {
      label: "Unordered List",
      Icon: ULIcon,
      command: [INSERT_UNORDERED_LIST_COMMAND, undefined],
      blockType: "ul",
    },
  ],
  [
    {
      label: "Left Align",
      Icon: AlignLeftIcon,
      command: [FORMAT_ELEMENT_COMMAND, "left"],
    },
    {
      label: "Center Align",
      Icon: AlignCenterIcon,
      command: [FORMAT_ELEMENT_COMMAND, "center"],
    },
    {
      label: "Right Align",
      Icon: AlignRightIcon,
      command: [FORMAT_ELEMENT_COMMAND, "right"],
    },
  ],
];

export const ToolbarTheme = {
  heading: {
    h1: "text-lg font-sansSemiBold mb-1",
    h2: "text-sm font-sansSemiBold mb-1",
  },
  list: {
    ol: "ml-8 my-2 list-decimal list-outside",
    listitem: "mb-1",
    ul: "ml-8 my-2 list-disc list-outside",
  },
  text: {
    underline: "underline",
    bold: "font-sansSemiBold",
    italic: "italic",
  },
  paragraph: "leading-5",
};

export const ToolbarNodes = [HeadingNode, ListNode, ListItemNode];

export const ToolbarPlugin: FC<{ className?: string }> = ({ className }) => {
  const [editor] = useLexicalComposerContext();

  const [blockType, setBlockType] = useState<BlockTypes>();

  useEffect(() => {
    return mergeRegister(
      editor.registerCommand(
        INSERT_HEADING_COMMAND,
        (headingSize: HeadingTagType) => {
          const selection = $getSelection();

          if (!$isRangeSelection(selection)) {
            return false;
          }

          $setBlocksType(selection, () => $createHeadingNode(headingSize));

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      ),
      editor.registerCommand(
        REMOVE_HEADING_COMMAND,
        () => {
          const selection = $getSelection();

          if (!$isRangeSelection(selection)) {
            return false;
          }

          $setBlocksType(selection, () => $createParagraphNode());

          return true;
        },
        COMMAND_PRIORITY_EDITOR
      )
    );
  }, [editor]);

  return (
    <>
      <div className={cx("flex gap-x-2 items-center border-b border-b-greyLighter pb-2", className)}>
        {actionSets.map((actions, index) => (
          <Fragment key={index}>
            {actions.map((action) => (
              <button
                key={action.label}
                type="button"
                onClick={() => {
                  if (action.blockType && action.blockType === blockType) {
                    if (isOneOf(action.blockType, ["ol", "ul"])) {
                      editor.dispatchCommand(REMOVE_LIST_COMMAND, undefined);
                    } else {
                      editor.dispatchCommand(REMOVE_HEADING_COMMAND, undefined);
                    }

                    setBlockType(undefined);
                  } else {
                    editor.dispatchCommand(...action.command);
                    setBlockType(action.blockType);
                  }
                }}
              >
                <Icon size="lg" SvgIcon={action.Icon} tooltip={{ theme: "SMALL", content: action.label }} />
              </button>
            ))}
            {index < actionSets.length - 1 ? <div className="h-6 w-px bg-greyLighter" /> : null}
          </Fragment>
        ))}
      </div>
      <ListPlugin />
    </>
  );
};
