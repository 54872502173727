import { FC, PropsWithChildren } from "react";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { ModalFooterButtons } from "@libs/components/UI/ModalComponents";
import { ModalPage } from "components/UI/ModalPage";

export const RoleModalPage: FC<
  PropsWithChildren<{
    closeUrl: string;
    isSaving: boolean;
    formId: string;
    titleText: string;
    saveText: string;
  }>
> = ({ closeUrl, isSaving, formId, children, saveText, titleText }) => {
  return (
    <ModalPage
      title={<div className="pl-2 font-sansSemiBold">{titleText}</div>}
      closeLink={closeUrl}
      actions={
        <ModalFooterButtons>
          <ButtonInternalLink className="min-w-button" theme="secondary" to={closeUrl}>
            Cancel
          </ButtonInternalLink>
          <AsyncButton
            className="min-w-button"
            isLoading={isSaving}
            theme="primary"
            type="submit"
            form={formId}
          >
            {saveText}
          </AsyncButton>
        </ModalFooterButtons>
      }
    >
      {children}
    </ModalPage>
  );
};
