import { FC } from "react";
import { FormTextBlockElementRequest, TemplateVariableGroupVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { useObjectState } from "@libs/hooks/useObjectState";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { EditElementFormProps } from "components/Settings/Forms/types";
import { FormWrapper } from "components/Settings/Forms/FormWrapper";
import { FormsRichTextEditor } from "components/Settings/Forms/FormsRichTextEditor";
import { titleSchema } from "components/Settings/Forms/utils";

const textBlockSchema = {
  title: titleSchema,
};

interface Props extends EditElementFormProps<FormTextBlockElementRequest> {
  templateVariables?: TemplateVariableGroupVO[];
}

export const TextBlockForm: FC<Props> = ({
  element,
  templateVariables,
  isEditing,
  onInvalid,
  onDraftChange,
  onSecondaryClick,
  onSubmit,
}) => {
  const [draft, updateDraft] = useObjectState(element);
  const validation = useValidation(draft, textBlockSchema);

  const handleSubmit = () => {
    onSubmit(draft);
    validation.reset();
  };

  return (
    <FormWrapper
      element={draft}
      isEditing={isEditing}
      onSubmit={handleSubmit}
      contentClassName="flex flex-col"
      onCancel={onSecondaryClick}
      onDraftChange={onDraftChange}
      onInvalid={onInvalid}
      onValidate={() => validation.validate().$isValid}
    >
      <div className="flex-1 min-h-0 flex flex-col gap-y-4">
        <FormFieldInput
          value={draft.title}
          required={true}
          label={
            <div>
              Title <span className="italic text-greyLight">Not visible to patients</span>
            </div>
          }
          error={validation.result.title.$error}
          onChange={(e) => updateDraft({ title: e.target.value })}
          className="flex-0"
        />
        <FormsRichTextEditor
          className="flex-1 min-h-0"
          initialEditorState={draft.content}
          templateVariables={templateVariables}
          onChange={({ content }) => {
            updateDraft({ content });
          }}
        />
      </div>
    </FormWrapper>
  );
};
