import { ChangeEvent, FC, ReactNode, useEffect, useRef } from "react";
import { PatientProcedureVO, SortOrder } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatCurrency } from "@libs/utils/currency";
import { noop } from "@libs/utils/noop";
import {
  ActionsRow,
  ColumnSortIndicator,
  HeaderButtonCell,
  HeaderCell,
  HeaderCheckboxCell,
  Row,
  TableGrid,
} from "@libs/components/UI/GridTableComponents";
import { ProcedureCheckboxCell, SubTotalCell, TotalCell } from "components/Charting/ProcedureTableCells";
import { RowTotal } from "components/Charting/useRowTotals";
import { EmptyProceduresList } from "components/Charting/EmptyProceduresList";

export const PatientProceduresTableLayout: FC<{
  menuRow: ReactNode;
  children?: ReactNode;
}> = ({ menuRow, children }) => {
  return (
    <div data-testid="patient-procedures-layout-table" className="flex flex-col min-h-0 flex-1">
      <ActionsRow contentClassName="flex items-start gap-x-4">{menuRow}</ActionsRow>
      <div className="flex-1 min-h-0">{children}</div>
    </div>
  );
};

export const ProceduresTableRow: FC<{
  selectedRows: Set<number>;
  procedure: PatientProcedureVO;
  scrollContainerId?: string;
  now: Date;
  children?: ReactNode;
  onToggleRow: (e: ChangeEvent<HTMLInputElement>) => void;
  onViewed?: (id: number) => void;
  isEditing?: boolean;
}> = ({ selectedRows, procedure, scrollContainerId, onToggleRow, onViewed, isEditing = false, children }) => {
  const elRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    const el = elRef.current?.firstElementChild;
    const root = scrollContainerId ? document.querySelector(`#${scrollContainerId}`) : null;

    if (el && root && onViewed) {
      const observer = new IntersectionObserver(
        (entries) => {
          if (entries[0].isIntersecting) {
            onViewed(procedure.id);
          }
        },
        {
          root,
          rootMargin: "0px",
          // once the checkbox can be seen
          threshold: 0.33,
        }
      );

      observer.observe(el);

      return () => {
        observer.unobserve(el);
      };
    }

    return noop;
  }, [scrollContainerId, procedure.id, onViewed]);

  return (
    <Row
      data-testid="procedure-table-row"
      ref={elRef}
      isSelected={selectedRows.has(procedure.id) || isEditing}
      key={procedure.id}
    >
      <ProcedureCheckboxCell
        checked={selectedRows.has(procedure.id)}
        value={procedure.id}
        onChange={onToggleRow}
      />
      {children}
    </Row>
  );
};

export type Column = { width: string; id: string };

type ProceduresHeaderCellProps<SC extends string> = {
  totalRows: number;
  selectedRows: Set<number>;
  onSelectAllRows: Func;
  onDeselectAllRows: Func;
  onSort?: (sortColumn: SC) => void;
  sort?: [SC, SortOrder["direction"]];
  column: Column & { sortKey?: SC; text: string };
};

export const ProceduresHeaderCell = <SC extends string>({
  totalRows,
  selectedRows,
  sort,
  column,
  onSort,
  onSelectAllRows,
  onDeselectAllRows,
}: ProceduresHeaderCellProps<SC>) => {
  return column.id === "checkbox" ? (
    <HeaderCheckboxCell
      selectedCount={selectedRows.size}
      totalRows={totalRows}
      onSelectAllRows={onSelectAllRows}
      onDeselectAllRows={onDeselectAllRows}
    />
  ) : column.sortKey ? (
    <HeaderButtonCell onClick={() => column.sortKey && onSort?.(column.sortKey)}>
      {column.text}

      <ColumnSortIndicator direction={column.sortKey === sort?.[0] ? sort[1] : undefined} className="ml-3" />
    </HeaderButtonCell>
  ) : (
    <HeaderCell>{column.text}</HeaderCell>
  );
};

export const ProceduresTableGrid: FC<{
  isEmpty: boolean;
  emptyMessage: string;
  isLoading?: boolean;
  columns: { width: string }[];
  children?: ReactNode;
}> = ({ emptyMessage, isLoading, isEmpty, columns, children }) => {
  return isEmpty ? (
    <EmptyProceduresList>{emptyMessage}</EmptyProceduresList>
  ) : (
    <TableGrid
      className={cx(isLoading && "h-full")}
      gridTemplateRows={isLoading ? "auto 1fr" : undefined}
      columnWidths={columns.map(({ width }) => width)}
    >
      {children}
    </TableGrid>
  );
};

export const SubTotalRow: FC<{
  isLast: boolean;
  row?: Omit<RowTotal, "lastId">;
  label: ReactNode;
}> = ({ isLast, row, label }) =>
  row ? (
    <div className={cx("contents", !isLast && "*:border-greyLight *:border-b")}>
      <SubTotalCell className="col-span-7" />
      <SubTotalCell>{label}</SubTotalCell>
      <SubTotalCell />
      <SubTotalCell>{formatCurrency(row.negotiatedRate)}</SubTotalCell>
      <SubTotalCell>{formatCurrency(row.insuranceAmount)}</SubTotalCell>
      <SubTotalCell>{formatCurrency(row.patientAmount)}</SubTotalCell>
      <SubTotalCell className="col-span-4" />
    </div>
  ) : null;

export const TotalRow: FC<{
  row?: Omit<RowTotal, "lastId">;
  label: ReactNode;
}> = ({ row, label }) =>
  row ? (
    <>
      <TotalCell className="col-span-7" />
      <TotalCell>{label}</TotalCell>
      <TotalCell />
      <TotalCell>{formatCurrency(row.negotiatedRate)}</TotalCell>
      <TotalCell>{formatCurrency(row.insuranceAmount)}</TotalCell>
      <TotalCell>{formatCurrency(row.patientAmount)}</TotalCell>
      <TotalCell className="col-span-4" />
    </>
  ) : null;
