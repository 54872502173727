import { FC } from "react";
import { PayrollSummaryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { formatCurrency } from "@libs/utils/currency";
import { formatISODate } from "@libs/utils/date";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { EMPTY_CELL, HeaderCell, LinkCell, Row, TableGrid } from "@libs/components/UI/GridTableComponents";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { paths } from "utils/routing/paths";

interface Props {
  payrollsQuery: ApiQueryResult<PayrollSummaryVO[]>;
}

const headers = [
  { id: "period", label: "Period", width: "1fr" },
  { id: "processedDate", label: "Processed Date", width: "1fr" },
  { id: "cancelable", label: "Cancelable", width: "1fr" },
  { id: "checkDate", label: "Check Date", width: "1fr" },
  { id: "type", label: "Type", width: "1fr" },
  { id: "total", label: "Total Payroll", width: "min-content" },
];

export const PayrollsTable: FC<Props> = ({ payrollsQuery }) => {
  return (
    <TableGrid
      className={cx(
        ((payrollsQuery.data && !payrollsQuery.data.length) ||
          payrollsQuery.isLoading ||
          payrollsQuery.isError) &&
          "h-full grid-rows-[min-content_1fr]"
      )}
      columnWidths={headers.map(({ width }) => width)}
    >
      {headers.map((item) => (
        <HeaderCell key={item.id}>{item.label}</HeaderCell>
      ))}

      <QueryResult
        queries={[payrollsQuery]}
        loading={<LoadingContent containerClassName="col-span-6" className="rounded-none" />}
        loadError={
          <div className="col-span-6">
            <ErrorContent />
          </div>
        }
      >
        {payrollsQuery.data ? (
          payrollsQuery.data.length ? (
            <>
              {payrollsQuery.data.map((payroll) => (
                <Row key={payroll.uuid}>
                  <LinkCell to={paths.payrollDetail({ payrollId: payroll.uuid })}>
                    {formatISODate(payroll.periodStartDate)} - {formatISODate(payroll.periodEndDate)}
                  </LinkCell>
                  <LinkCell to={paths.payrollDetail({ payrollId: payroll.uuid })}>
                    {payroll.submittedDate ? formatISODate(payroll.submittedDate) : EMPTY_CELL}
                  </LinkCell>
                  <LinkCell to={paths.payrollDetail({ payrollId: payroll.uuid })}>
                    {payroll.isCancelable ? <span className="text-primaryTheme">Yes</span> : "No"}
                  </LinkCell>
                  <LinkCell to={paths.payrollDetail({ payrollId: payroll.uuid })}>
                    {payroll.employeePayDate ? formatISODate(payroll.employeePayDate) : EMPTY_CELL}
                  </LinkCell>
                  <LinkCell to={paths.payrollDetail({ payrollId: payroll.uuid })}>
                    {sentenceCaseConstant(payroll.type)}
                  </LinkCell>
                  <LinkCell to={paths.payrollDetail({ payrollId: payroll.uuid })}>
                    {formatCurrency(payroll.totalAmount ?? 0)}
                  </LinkCell>
                </Row>
              ))}
            </>
          ) : (
            <div className="flex justify-center items-center col-span-6 text-sm">
              No payrolls have been processed.
            </div>
          )
        ) : null}
      </QueryResult>
    </TableGrid>
  );
};
