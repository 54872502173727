import { FC, useContext } from "react";
import { PayrollVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { hasFailedPin } from "@libs/utils/hasFailedPin";
import { Icon } from "@libs/components/UI/Icon";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ReactComponent as DownloadIcon } from "@libs/assets/icons/download.svg";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { handleError } from "utils/handleError";
import { downloadFile } from "utils/files";
import { EmployeePinModal } from "components/Employee/EmployeePinModal";

const getFilename = (payroll: PayrollVO) => {
  return `Receipt_Pay_Period_${formatISODate(payroll.periodStartDate)}-${formatISODate(
    payroll.periodEndDate
  )}.pdf`;
};

interface Props {
  practiceId: number;
  payroll: PayrollVO;
}

export const DownloadPayrollReceiptButton: FC<Props> = ({ practiceId, payroll }) => {
  const { fetchBlob } = useContext(ApiClientContext);
  const isLoading = useBoolean(false);
  const pinModal = useBoolean(false);
  const handleDownload = async () => {
    try {
      isLoading.on();

      const blob = await fetchBlob(`/practices/${practiceId}/payrolls/${payroll.uuid}/receipt`);

      downloadFile(blob, getFilename(payroll));
    } catch (err) {
      if (hasFailedPin(err)) {
        pinModal.on();
      } else {
        handleError(err);
      }
    } finally {
      isLoading.off();
    }
  };

  return (
    <>
      <AsyncButton
        theme="link"
        className="ml-6 flex items-center text-xs"
        onClick={handleDownload}
        isLoading={isLoading.isOn}
      >
        Download Receipt
        <Icon className="ml-1" theme="primary" SvgIcon={DownloadIcon} />
      </AsyncButton>
      {pinModal.isOn && (
        <EmployeePinModal
          onPinSuccess={() => {
            handleDownload();
            pinModal.off();
          }}
          onClose={pinModal.off}
        />
      )}
    </>
  );
};
