import {
  CreateCustomCarrierRequest,
  InsuranceCarrierDetailsVO,
  InsuranceCarrierRequest,
} from "@libs/api/generated-api";
import { ValidationResult } from "@libs/hooks/useValidation";
import { phoneNumber, required } from "@libs/utils/validators";
import { EMPTY_ADDRESS } from "components/Settings/InsuranceCarriers/InsuranceDetails";

export const insuranceCarrierSchema = {
  address: {
    address1: [{ $v: required, $error: "Address required" }],
    city: [{ $v: required, $error: "City required" }],
    state: [{ $v: required, $error: "State required" }],
    zip: [{ $v: required, $error: "Zip required" }],
  },
  fax: [{ $v: phoneNumber, $error: "Invalid fax" }],
  name: [{ $v: required, $error: "Name required" }],
  payerId: [{ $v: required, $error: "Payer ID required" }],
  phone: [{ $v: phoneNumber, $error: "Invalid phone" }],
};

export type InsuranceCarrierValidationResult = ValidationResult<
  CreateCustomCarrierRequest | InsuranceCarrierRequest,
  typeof insuranceCarrierSchema
>;

export const getCarrierRequestData = (draft: Partial<InsuranceCarrierDetailsVO>) => ({
  acceptsAutomatedEligibilities: draft.acceptsAutomatedEligibilities,
  acceptsElectronicAttachments: draft.acceptsElectronicAttachments,
  acceptsToothRange: draft.acceptsToothRange,
  address: draft.address ?? EMPTY_ADDRESS,
  comments: draft.comments,
  fax: draft.fax,
  name: draft.name ?? "",
  payerId: draft.payerId ?? "",
  phone: draft.phone,
  website: draft.website,
});
