import { FC, HTMLProps } from "react";
import { useDrag } from "react-dnd";

import { DocumentVO } from "@libs/api/generated-api";
import { Icon } from "@libs/components/UI/Icon";
import { cx } from "@libs/utils/cx";

import { ReactComponent as DragIcon } from "@libs/assets/icons/drag-reorder-2.svg";
import { DateWithTooltip } from "components/UI/DateWithTooltip";

import { TreeViewDraggables } from "./TreeViewDraggables";

interface Props extends HTMLProps<HTMLButtonElement> {
  document: DocumentVO;
  isSelected: boolean;
  onDocumentClick: (doc: DocumentVO) => void;
}

export const TreeViewFile: FC<Props> = ({ document, isSelected, onDocumentClick }) => {
  const [{ isDragging }, drag] = useDrag(() => ({
    type: TreeViewDraggables.FILE,
    item: document,
    collect: (monitor) => ({
      isDragging: Boolean(monitor.isDragging()),
    }),
  }));

  return (
    <button
      ref={drag}
      type="button"
      className={cx(
        `flex
         items-center
         gap-x-2
         p-3
         w-full
         text-left
         last:rounded-b`,
        isSelected ? "bg-actionLight" : "hover:bg-slate-100",
        isDragging && "opacity-50 cursor-move"
      )}
      onClick={() => onDocumentClick(document)}
    >
      <Icon SvgIcon={DragIcon} className="cursor-ns-resize" theme="slate700" />
      <div className="flex-1 text-xs overflow-hidden">
        <DateWithTooltip date={document.metadata?.createdAt ?? 0} dateAsSeconds={true} format="Pp" />
        <div className={cx("text-sm", isSelected && "text-primaryTheme")}>{document.name}</div>
      </div>
    </button>
  );
};
