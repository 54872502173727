import { CognitoUser } from "amazon-cognito-identity-js";
import { FormEvent, useCallback } from "react";
import { Auth } from "aws-amplify";
import { isError } from "@tanstack/react-query";
import { useBoolean } from "@libs/hooks/useBoolean";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { CognitoErrorCode } from "@libs/utils/cognito";
import { Form } from "@libs/components/UI/Form";
import { PageWrapper, PageWrapperCard } from "components/SignIn/PageWrapper";
import { OTPInput } from "components/UI/OTPInput";
import { useMfaChallenge } from "components/SignIn/hooks/useMfaChallenge";
import { useHandleAuthError } from "components/SignIn/hooks/useHandleAuthError";
import { ChallengeProps } from "components/SignIn/AuthChallenges/utils";

const OTP_LENGTH = 6;

export const MFAChallenge: React.FC<ChallengeProps> = ({ authUser, onChallengeComplete }) => {
  const signingIn = useBoolean(false);
  const { rememberDevice, mfaEntryState, updateMfaEntryState, handleOtpChange, validation } =
    useMfaChallenge();

  const handleAuthError = useHandleAuthError(updateMfaEntryState);
  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (validation.validate().$isValid) {
        try {
          signingIn.on();

          const signedInUser = (await Auth.confirmSignIn(
            authUser,
            mfaEntryState.otp,
            "SOFTWARE_TOKEN_MFA"
          )) as CognitoUser;

          // This must come second, user must be signed in to rememberDevice
          await (rememberDevice.isOn ? Auth.rememberDevice() : Auth.forgetDevice());

          onChallengeComplete({ signedInUser });
        } catch (error) {
          updateMfaEntryState({
            otp: "",
          });

          if (isError(error)) {
            const cognitoError = error as { code?: CognitoErrorCode };

            if (cognitoError.code === "NotAuthorizedException") {
              // In this case, they must re-enter username and password
              onChallengeComplete({
                error: cognitoError.code,
              });
            } else {
              handleAuthError(error);
            }
          } else {
            handleAuthError(error);
          }

          signingIn.off();
        }
      }
    },
    [
      validation,
      rememberDevice,
      signingIn,
      authUser,
      mfaEntryState.otp,
      onChallengeComplete,
      updateMfaEntryState,
      handleAuthError,
    ]
  );
  const errorMessage = mfaEntryState.errorMessage ?? validation.result.otp.$error;

  return (
    <PageWrapper>
      <PageWrapperCard title="Keep your account secure">
        <Form className="flex flex-col gap-8 text-xs items-center" onSubmit={handleSubmit}>
          <div>
            To ensure we know it&apos;s really you when you log in, you&apos;ll need to enter a code from your
            authenticator app.
          </div>

          <div className="flex flex-col gap-3 self-center items-center">
            <div className="text-sm font-sansSemiBold">Enter the verification code</div>
            <OTPInput
              disabled={false}
              onChange={handleOtpChange}
              OTPLength={OTP_LENGTH}
              otpType="number"
              error={Boolean(validation.result.otp.$error || mfaEntryState.errorMessage)}
              value={mfaEntryState.otp}
            />
            {errorMessage && <div className="text-xxs text-red">{errorMessage}</div>}

            <div className="flex">
              <Checkbox
                checked={rememberDevice.isOn}
                onChange={(e) => {
                  rememberDevice.set(e.target.checked);
                }}
              >
                Remember this browser on this device
              </Checkbox>
            </div>
          </div>
          <div className="flex flex-col gap-4">
            <AsyncButton
              isLoading={signingIn.isOn}
              theme="primary"
              type="submit"
              disabled={mfaEntryState.otp.length !== OTP_LENGTH}
              className="w-fit min-w-button"
            >
              Next
            </AsyncButton>
          </div>
          <div className="text-xs">
            <strong>Need Help?</strong> Contact your practice administrator.
          </div>
        </Form>
      </PageWrapperCard>
    </PageWrapper>
  );
};
