import React from "react";
import { getLabelForFontSize } from "components/Settings/Scheduling/Sections/AppointmentCardDesign/utils";

export const ShowHideOptions: { label: string; value: "SHOW" | "HIDE" }[] = [
  {
    label: "Show",
    value: "SHOW",
  },
  {
    label: "Hide",
    value: "HIDE",
  },
];
export const TypeSizeOptions = ["STANDARD" as const, "SMALL" as const].map((value) => {
  return {
    value,
    label: getLabelForFontSize(value),
  };
});

export const IndicatorOptions: { label: string; value: "PROVIDER" | "CATEGORY" }[] = [
  {
    label: "Category",
    value: "CATEGORY",
  },
  {
    label: "Provider",
    value: "PROVIDER",
  },
];

export const ProviderOptions: { label: React.ReactNode; value: "HYGIENIST" | "DENTIST" }[] = [
  {
    label: "Dentist",
    value: "DENTIST",
  },
  {
    label: "Hygienist",
    value: "HYGIENIST",
  },
];
