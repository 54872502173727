import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as DownloadIcon } from "@libs/assets/icons/download.svg";
import { RoleGuardClick } from "components/Main/RoleGuard";

type Props = {
  onClick: Func;
  isDownloading?: boolean;
  disabled?: boolean;
};
export const ExportCsvButton: React.FC<Props> = ({ isDownloading, onClick, disabled = false }) => {
  return (
    <RoleGuardClick domain="REPORTING" action="PRINT_DOWNLOAD">
      <div className="flex items-center w-5 justify-center">
        <ButtonIcon
          theme="primary"
          loading={isDownloading}
          tooltip={{
            content: disabled ? "Too many rows; Please filter your results to export." : "Export to CSV",
            theme: disabled ? "MEDIUM" : "SMALL",
          }}
          SvgIcon={DownloadIcon}
          onClick={onClick}
          disabled={disabled}
        />
      </div>
    </RoleGuardClick>
  );
};
