import React, { ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { PanelProps, Panel } from "@libs/components/UI/Panel";

interface SettingsPanelProps
  extends Pick<
    PanelProps,
    "includePadding" | "contentClassName" | "actions" | "title" | "titleBarClassName" | "footer"
  > {
  children?: ReactNode;
  description?: string | ReactNode;
  isEditing?: boolean;
  onToggleEdit?: () => void;
  showScrollBar?: boolean;
}

export const SettingsPanel: React.FC<SettingsPanelProps> = ({
  children,
  isEditing,
  onToggleEdit,
  showScrollBar,
  description,
  includePadding = true,
  ...panelProps
}) => {
  return (
    <Panel
      actions={
        onToggleEdit ? (
          <ButtonIcon
            SvgIcon={isEditing ? CancelIcon : EditIcon}
            onClick={onToggleEdit}
            theme="primary"
            tooltip={{ content: isEditing ? "Cancel" : "Edit", theme: "SMALL" }}
          />
        ) : null
      }
      className="h-full flex-1"
      contentClassName={cx(includePadding && "p-6", showScrollBar ? "overflow-y-scroll" : "overflow-y-auto")}
      includePadding={false}
      {...panelProps}
    >
      {description ? (
        <p className={cx("text-sm font-sans mb-6", !includePadding && "pl-6 pt-6")}>{description}</p>
      ) : null}
      {children}
    </Panel>
  );
};
