import React, { useCallback } from "react";
import { InsurancePlanVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { noop } from "@libs/utils/noop";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { ReactComponent as VerifyIcon } from "@libs/assets/icons/verified-badge.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { HeaderCell, Row, TableGrid } from "@libs/components/UI/GridTableComponents";
import { CdtCodeSearch } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/CdtCodeSearch";
import { planCxStyles } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/styles";
import { SelectDentalCategory } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/DedMaxAndCoverageTab/SelectDentalCategory";
import {
  Cell,
  CheckboxCell,
  EditCurrencyInCentsCell,
  FullWidthRow,
  NumberInputCell,
  CellProps,
  WaitingPeriodCell,
  InputCell,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/TableItems";
import { BenefitCoverageFields } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/useBenefitCoverageFields";
import { cdtCodeToNumber, cdtCodeToString } from "utils/cdtCode";
import { CoveragesValidationResult } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/DedMaxAndCoverageTab/formData";

const DEFAULT_BENEFIT_COVERAGE: Partial<BenefitCoverageFields> = {
  copayAmount: 0,
  percentCoverage: 0,
  waitingPeriodInMonths: 0,
};

const DEFAULT_HMO_BENEFIT_COVERAGE: Partial<BenefitCoverageFields> = {
  copayAmount: 0,
  percentCoverage: 100,
  waitingPeriodInMonths: 0,
};

const CoverageRow: React.FC<{
  coverages?: BenefitCoverageFields;
  editing: boolean;
  insuranceOrPlanIsAutoVerified: boolean;
  index: number;
  loading: boolean;
  onChange: (coverages: BenefitCoverageFields) => void;
  onDelete: (index: number) => void;
  validation?: CoveragesValidationResult["benefits"]["$items"][number];
  // eslint-disable-next-line complexity
}> = ({
  coverages,
  editing,
  index,
  insuranceOrPlanIsAutoVerified,
  loading,
  onChange,
  onDelete,
  validation,
}) => {
  const cellProps: Partial<CellProps> = {
    className: planCxStyles.tableCell,
    loading,
  };

  const sharedInputProps = {
    edit: editing,
  };

  const handleWaitingPeriodChange = useCallback(
    (numOfMonths: number | undefined) => {
      onChange({
        ...coverages,
        autoEligibility: false,
        waitingPeriodInMonths: numOfMonths,
      });
    },
    [coverages, onChange]
  );

  return (
    <Row>
      <Cell {...cellProps}>
        {editing && !coverages?.isDefault ? (
          <SelectDentalCategory
            isClearable={false}
            autoFocus={!coverages?.benefitCategory}
            value={coverages?.benefitCategory}
            className="w-full"
            error={validation?.benefitCategory.$error}
            onChange={(val) => {
              onChange({
                ...coverages,
                autoEligibility: false,
                benefitCategory: val,
              });
            }}
          />
        ) : (
          coverages?.benefitCategory
        )}
      </Cell>
      <Cell {...cellProps} className={cx("space-x-2", cellProps.className)}>
        <CdtCodeSearch
          className={cx(editing ? "flex-1" : "")}
          isClearable={false}
          edit={sharedInputProps.edit && !coverages?.isDefault}
          value={cdtCodeToString(coverages?.startCdtCodeRange)}
          error={validation?.startCdtCodeRange.$error}
          endRange={coverages?.endCdtCodeRange}
          onChange={(value) => {
            const startCode = cdtCodeToNumber(value);
            let endCode = coverages?.endCdtCodeRange;

            if (!endCode || (startCode ?? 0) > endCode) {
              endCode = startCode;
            }

            onChange({
              ...coverages,
              autoEligibility: false,
              startCdtCodeRange: startCode,
              endCdtCodeRange: endCode,
            });
          }}
        />
        <div>to</div>
        <CdtCodeSearch
          className={cx(editing ? "flex-1" : "")}
          edit={sharedInputProps.edit && !coverages?.isDefault}
          isClearable={false}
          startRange={coverages?.startCdtCodeRange}
          value={cdtCodeToString(coverages?.endCdtCodeRange)}
          error={validation?.endCdtCodeRange.$error}
          onChange={(value) => {
            onChange({
              ...coverages,
              autoEligibility: false,
              endCdtCodeRange: cdtCodeToNumber(value),
            });
          }}
        />
      </Cell>
      {editing ? (
        <NumberInputCell
          {...sharedInputProps}
          {...cellProps}
          error={validation?.percentCoverage.$error}
          value={coverages?.percentCoverage}
          min={0}
          max={100}
          onValueChange={(val) => {
            onChange({ ...coverages, autoEligibility: false, percentCoverage: val });
          }}
        />
      ) : (
        <InputCell
          {...cellProps}
          edit={false}
          onChange={noop}
          value={coverages?.percentCoverage == null ? "" : `${coverages.percentCoverage}%`}
        />
      )}
      <WaitingPeriodCell
        {...cellProps}
        isEditing={sharedInputProps.edit}
        onChange={handleWaitingPeriodChange}
        error={validation?.waitingPeriodInMonths.$error}
        waitingPeriodInMonths={coverages?.waitingPeriodInMonths}
      />
      <EditCurrencyInCentsCell
        {...cellProps}
        {...sharedInputProps}
        value={coverages?.copayAmount}
        onChange={(newValue) => {
          onChange({ ...coverages, autoEligibility: false, copayAmount: newValue });
        }}
      />
      <CheckboxCell
        {...sharedInputProps}
        {...cellProps}
        onChange={(checked: boolean) => {
          onChange({ ...coverages, autoEligibility: false, applyDeductible: checked });
        }}
        checked={Boolean(coverages?.applyDeductible)}
      />
      <CheckboxCell
        {...sharedInputProps}
        {...cellProps}
        className={cellProps.className}
        checked={Boolean(coverages?.preauthRequired)}
        onChange={(checked: boolean) => {
          onChange({ ...coverages, autoEligibility: false, preauthRequired: checked });
        }}
      />
      {insuranceOrPlanIsAutoVerified && (
        <Cell {...cellProps}>
          {coverages?.autoEligibility && (
            <Icon
              SvgIcon={VerifyIcon}
              theme="primary"
              tooltip={{ content: "Auto-Verified", theme: "SMALL" }}
            />
          )}
        </Cell>
      )}
      {editing ? (
        <Cell {...cellProps}>
          {!coverages?.isDefault && (
            <div className="flex items-center justify-center w-full">
              <ButtonIcon SvgIcon={DeleteIcon} onClick={() => onDelete(index)} size="sm" theme="primary" />
            </div>
          )}
        </Cell>
      ) : (
        <Cell {...cellProps} />
      )}
    </Row>
  );
};

interface Props {
  coverageFields: BenefitCoverageFields[];
  editing: boolean;
  errorLoading: boolean;
  insuranceOrPlanIsAutoVerified: boolean;
  loading: boolean;
  onChange: (coverages: BenefitCoverageFields[]) => void;
  onDelete: (index: number) => void;
  planType: InsurancePlanVO["planType"] | undefined;
  validation?: CoveragesValidationResult;
}

const headers = [
  { id: "category", text: "Category", width: "5fr" },
  { id: "procedures", text: "Procedures", width: "6fr" },
  { id: "coverage", text: "Coverage", width: "2fr" },
  { id: "waitingPeriod", text: "Waiting Period", width: "4fr" },
  { id: "copay", text: "Copay", width: "2fr" },
  { id: "deductible", text: "Deductible", width: "2fr" },
  { id: "preAuth", text: "Pre-Auth Req", width: "3fr" },
];

export const CoverageTable: React.FC<Props> = ({
  coverageFields,
  editing,
  errorLoading,
  insuranceOrPlanIsAutoVerified,
  loading,
  onChange,
  onDelete,
  planType,
  validation,
}) => {
  const handleStageNewCoverage = React.useCallback(() => {
    onChange([
      ...coverageFields,
      planType === "HMO_DHMO" ? DEFAULT_HMO_BENEFIT_COVERAGE : DEFAULT_BENEFIT_COVERAGE,
    ]);
  }, [onChange, coverageFields, planType]);

  if (errorLoading) {
    return <ErrorContent />;
  }

  const tableHeaders = insuranceOrPlanIsAutoVerified
    ? [...headers, { id: "autoverified", text: "", width: "50px" }, { id: "", text: "", width: "50px" }]
    : [...headers, { id: "", text: "", width: "50px" }];

  return (
    <TableGrid
      className={`
        rounded
        border
        border-greyLightest
        border-b-0
        min-w-0
        text-xs
        overflow-x-auto
        max-w-[1216px]
      `}
      columnWidths={tableHeaders.map(({ width }) => width)}
    >
      {tableHeaders.map((item) => (
        <HeaderCell key={item.id} size="short">
          {item.text}
        </HeaderCell>
      ))}
      {coverageFields.map((coverages, i) => {
        return (
          <CoverageRow
            coverages={coverages}
            editing={editing}
            index={i}
            insuranceOrPlanIsAutoVerified={insuranceOrPlanIsAutoVerified}
            key={`${coverages.uuid ?? ""}-${i}`}
            loading={loading}
            onChange={(updatedItem) => {
              const updatedCoverages = coverageFields.map((item, j) => {
                if (i === j) {
                  return updatedItem;
                }

                return item;
              });

              onChange(updatedCoverages);
            }}
            onDelete={onDelete}
            validation={validation?.benefits.$items[i]}
          />
        );
      })}
      {editing && (
        <FullWidthRow>
          <button
            className={`
              flex
              items-center
              px-2
              py-1
              gap-x-2
              font-sans
              text-primaryTheme
              focus:border-primaryTheme
            `}
            onClick={handleStageNewCoverage}
            type="button"
          >
            <Icon SvgIcon={AddIcon} size="sm" theme="primary" />
            Coverage
          </button>
        </FullWidthRow>
      )}
    </TableGrid>
  );
};
