import { AxisBottom, AxisLeft, AxisScale, TickFormatter } from "@visx/axis";
import { GridRows } from "@visx/grid";
import React from "react";
import { ScaleSVG } from "@visx/responsive";
import { Group } from "@visx/group";
import { LabelFormatter } from "@visx/legend/lib/types";
import { ScaleOrdinal, ScaleLinear, ScaleBand } from "d3-scale";
import { TextProps } from "@visx/text";
import {
  AXIS_COLORS,
  GRID_ROWS_DEFAULTS,
  useBottomTickLabelProps,
  useLeftAxisTickProps,
} from "components/Dashboard/Charting/theme";
import { LoadingChart } from "components/Dashboard/Charting/LoadingChart";
import { BarStackData, ChartMargin } from "components/Dashboard/Charting/types";
import { ChartLegend } from "components/Dashboard/Charting/ChartLegend";
import { useYAxisFormatter } from "components/Dashboard/Charting/useYAxisFormatter";

type Props<T extends string> = {
  loadingData: BarStackData<T>[];
  width: number;
  height: number;
  isLoading: boolean;
  isFirstLoad: boolean;
  children: React.ReactNode;
  formatDate: TickFormatter<string>;
  labelFormat: LabelFormatter<T>;
  colorScale: ScaleOrdinal<T, string, never>;
  tooltipOpen: boolean;
  xScale: ScaleBand<string>;
  yScale: ScaleLinear<number, number, never>;
  yMax: number;
  yZeroPosition: number;
  gridXMax: number;
  isCurrency?: boolean;
  formatValue?: (val: number) => string;
  xAxisLabel?: {
    text: string;
    labelProps: Partial<TextProps>;
  };
  margins: {
    chartMargin: ChartMargin;
    axisMargin: ChartMargin;
  };
  numTicksXAxis?: number;
  innerRef: React.Ref<SVGSVGElement> | undefined;
};

const MINIMUM_WIDTH = 100;

export const BarChartLayout = <T extends string>({
  loadingData,
  width,
  height,
  isLoading,
  numTicksXAxis,
  children,
  isFirstLoad,
  formatDate,
  labelFormat,
  colorScale,
  innerRef,
  margins,
  xScale,
  yScale,
  yMax,
  gridXMax,
  yZeroPosition,
  xAxisLabel,
  formatValue,
  isCurrency = false,
}: Props<T>) => {
  const bottomAxisLabelProps = useBottomTickLabelProps<string>({ clickable: true });
  const leftAxisLabelProps = useLeftAxisTickProps<number>();
  const yAxisFormatter = useYAxisFormatter({ formatValue, isCurrency });

  return width < MINIMUM_WIDTH ? null : (
    <>
      {isLoading && (
        <LoadingChart
          data={loadingData}
          randomize={isFirstLoad}
          xScale={xScale}
          yScale={yScale}
          barWidth={xScale.bandwidth()}
          margins={margins.chartMargin}
          yMax={yMax}
        />
      )}
      <ScaleSVG width={width} height={height} innerRef={innerRef}>
        <AxisLeft<AxisScale<number>>
          scale={yScale}
          top={margins.axisMargin.top}
          left={margins.axisMargin.left}
          numTicks={GRID_ROWS_DEFAULTS.numTicks}
          tickFormat={yAxisFormatter}
          hideTicks
          hideAxisLine
          tickLabelProps={leftAxisLabelProps}
        />
        <GridRows
          {...GRID_ROWS_DEFAULTS}
          top={margins.axisMargin.top}
          left={margins.axisMargin.left}
          scale={yScale}
          width={gridXMax + margins.chartMargin.right}
          height={yMax}
        />
        <Group top={margins.chartMargin.top} left={margins.chartMargin.left}>
          {!isLoading && children}
        </Group>

        <AxisBottom
          top={yMax + margins.chartMargin.top}
          left={margins.chartMargin.left}
          scale={xScale}
          tickFormat={formatDate}
          label={xAxisLabel?.text}
          labelProps={xAxisLabel?.labelProps}
          numTicks={numTicksXAxis}
          hideTicks
          hideAxisLine
          rangePadding={margins.chartMargin.left - margins.axisMargin.left}
          tickLabelProps={bottomAxisLabelProps}
        />
        <line
          x1={margins.axisMargin.left}
          y1={yZeroPosition}
          x2={width - margins.axisMargin.right + margins.chartMargin.right}
          y2={yZeroPosition}
          stroke={AXIS_COLORS.baseLine}
        />
      </ScaleSVG>

      <ChartLegend scale={colorScale} labelFormat={labelFormat} />
    </>
  );
};
