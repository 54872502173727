import { FC } from "react";
import { Navigate, Route } from "react-router-dom";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { OnboardingRoute } from "components/Onboarding/OnboardingRoute";
import { paths } from "utils/routing/paths";

export const OnboardingApp: FC = () => {
  return (
    <SentryRoutes>
      <Route path="onboarding" element={<OnboardingRoute />} />
      <Route path="*" element={<Navigate replace to={paths.onboarding()} />} />
    </SentryRoutes>
  );
};
