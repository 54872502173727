import React, { useCallback } from "react";
import { FeeScheduleVO, InsuranceCarrierVO, NameVO, ProviderVO } from "@libs/api/generated-api";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { RequiredAsterisk } from "@libs/components/UI/RequiredAsterisk";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Switch } from "@libs/components/UI/Switch";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { SelectProviders } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/SelectProviders";
import { FeeScheduleValidationResult } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/formData";
import { SelectCarriers } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/SelectCarriers";
import { EditableFeeSchedule } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/useEditableFeeSchedule";
import { ToggleButtonList } from "components/UI/ToggleButtonList";

export const FeeScheduleFields: React.FC<{
  carriersQuery: ApiQueryResult<InsuranceCarrierVO[]>;
  editableFeeSchedule: EditableFeeSchedule;
  isCreating: boolean;
  isCopying: boolean;
  onUpdate: (fields: Partial<FeeScheduleVO>) => void;
  originallySelectedProviders: NameVO[] | undefined;
  providersQuery: ApiQueryResult<ProviderVO[]>;
  providersWithoutUcrQuery: ApiQueryResult<ProviderVO[]>;
  validation: FeeScheduleValidationResult;
}> = React.memo(
  // eslint-disable-next-line complexity
  ({
    carriersQuery,
    editableFeeSchedule,
    isCreating,
    isCopying,
    onUpdate,
    originallySelectedProviders,
    providersQuery,
    providersWithoutUcrQuery,
    validation,
  }) => {
    const handleSelectCarriers = useCallback(
      (carrierIds: number[]) => {
        onUpdate({ carrierIds });
      },
      [onUpdate]
    );

    const isEditing = !isCreating && !isCopying;

    return (
      <div className="flex flex-col gap-y-6">
        <FormFieldInput
          error={validation.name.$error}
          label="Name"
          onChange={(e) => onUpdate({ name: e.target.value })}
          placeholder="Fee Schedule Name"
          required
          value={editableFeeSchedule.name}
        />
        <ToggleButtonList
          label="Type"
          onChange={(_e, option) =>
            onUpdate({ isProviderUcr: option.value === "PROVIDER_UCR", providerIds: [] })
          }
          options={[
            {
              disabled: isEditing && !editableFeeSchedule.isProviderUcr,
              label: "Provider UCR",
              value: "PROVIDER_UCR",
            },
            {
              disabled: isEditing && editableFeeSchedule.isProviderUcr,
              label: "Fee Schedule",
              value: "CARRIER",
            },
          ]}
          required
          shape="mediumPill"
          selectedValue={editableFeeSchedule.isProviderUcr ? "PROVIDER_UCR" : "CARRIER"}
          type="radio"
        />
        <SelectProviders
          error={validation.providerIds.$error}
          lookupProvidersWithoutUCR={editableFeeSchedule.isProviderUcr}
          onUpdate={onUpdate}
          originallySelectedProviders={originallySelectedProviders}
          providersQuery={providersQuery}
          providersWithoutUcrQuery={providersWithoutUcrQuery}
          selectedProviderIds={editableFeeSchedule.providerIds}
        />
        {!editableFeeSchedule.isProviderUcr && (
          <SelectCarriers
            carriersQuery={carriersQuery}
            error={validation.carrierIds.$error}
            onSelect={handleSelectCarriers}
            required
            selectedCarrierIds={new Set(editableFeeSchedule.carrierIds)}
          />
        )}
        <div className="flex w-full gap-x-6">
          <FormFieldSelectMenusDatepicker
            className="flex-1"
            error={validation.startDate.$error}
            label="Effective Date"
            onChange={(date) => {
              onUpdate({ startDate: date ? formatAsISODate(date) : undefined });
            }}
            required
            selected={editableFeeSchedule.startDate ? getLocalDate(editableFeeSchedule.startDate) : undefined}
          />
          <FormFieldSelectMenusDatepicker
            className="flex-1"
            error={validation.endDate.$error}
            label="Expiration Date"
            onChange={(date) => {
              onUpdate({ endDate: date ? formatAsISODate(date) : undefined });
            }}
            selected={editableFeeSchedule.endDate ? getLocalDate(editableFeeSchedule.endDate) : undefined}
          />
        </div>
        {!editableFeeSchedule.isProviderUcr && (
          <div className="flex flex-col gap-y-2">
            <div className="flex items-center text-xs">
              In-Network
              <RequiredAsterisk />
            </div>
            <div className="p-1">
              <Switch
                checked={editableFeeSchedule.inNetwork}
                onChange={(e) => onUpdate({ inNetwork: e.target.checked })}
                required
              >
                <div>{editableFeeSchedule.inNetwork ? "Yes" : "No"}</div>
              </Switch>
            </div>
          </div>
        )}
      </div>
    );
  }
);
