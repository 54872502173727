import { RecurringScheduleVO } from "@libs/api/generated-api";
import {
  LabelledRepeatOption,
  getRecurrenceOptions,
} from "components/ScheduleAppointments/getRecurrenceOptions";
import { isSameRecurrence } from "components/ScheduleAppointments/isSameRecurrence";

// Allows us to go from a recurrence object to the select option value
export const getRepeatValueFromRecurrence = (
  date: string | undefined,
  recurrence: RecurringScheduleVO | undefined
): LabelledRepeatOption["value"] => {
  if (!recurrence || !date) {
    return "NONE";
  }

  const options = getRecurrenceOptions(date);

  const match = options.find((option) => {
    return isSameRecurrence(option.recurrence, recurrence);
  });

  if (match) {
    return match.value;
  }

  return "CUSTOM";
};
