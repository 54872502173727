import { FC } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { PosDevicesSettings } from "components/Settings/Payments/PosDevicesSettings";
import { listPaymentDevices } from "api/settings/payments/queries";

export const POSRoute: FC = () => {
  const { practiceId } = useAccount();
  const [{ data: paymentDevices }] = useApiQueries([
    listPaymentDevices({
      args: {
        practiceId,
        query: { states: ["ACTIVATED", "ACTIVATION_FAILED", "ACTIVATION_IN_PROGRESS", "DEACTIVATED"] },
      },
    }),
  ]);

  return (
    <SettingsPanel title="Point of Sale (POS) Devices" description="Setup and manage your POS devices data">
      <PosDevicesSettings posDevices={paymentDevices} />
    </SettingsPanel>
  );
};
