import { pluralize } from "@libs/utils/pluralize";
import { DonutTooltip } from "components/Dashboard/Charting/DonutTooltip";
import { ReferralPieDatum } from "components/Dashboard/Referrals/ReferralsRoute";

type Props = {
  datum: ReferralPieDatum;
  percent: number;
};

export const ReferralsDonutTooltip: React.FC<Props> = ({ percent, datum }) => {
  return (
    <DonutTooltip color={datum.color} isCurrency={false} label={labelForReferrals(datum)} percent={percent} />
  );
};

const labelForReferrals = (datum: ReferralPieDatum) => {
  return `${datum.label} - ${datum.actualValue} ${pluralize(datum.actualValue, "patient", "patients")}`;
};
