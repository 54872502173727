import { EditorState } from "lexical";
import { $isRootTextContentEmpty } from "@lexical/text";

export const getContent = async (editorState: EditorState | undefined) => {
  if (!editorState) {
    return "";
  }

  const isEmpty = await new Promise((resolve) => {
    editorState.read(() => {
      resolve($isRootTextContentEmpty(false, true));
    });
  });

  return isEmpty ? "" : JSON.stringify(editorState);
};
