import { useEffect, useState } from "react";
import { PerioChartExamEntryVO } from "@libs/api/generated-api";
import { sequenceToVisibility } from "components/Charting/Perio/perioChartUtils";
import { PerioChartSequenceType } from "components/Charting/Perio/PerioTypes";
import { usePerioChart } from "./PerioChartContext";

export const PerioChartHiddenDataWarning: React.FC = () => {
  const { settings, currentExam } = usePerioChart();
  const [hasHiddenData, setHasHiddenData] = useState<boolean>(false);

  useEffect(() => {
    // Determine if there is any hidden fields with data based on the current providers settings.
    const hiddenSequences =
      settings &&
      currentExam &&
      Object.entries(sequenceToVisibility)
        .filter(([_key, value]) => settings[value] === false)
        .map(([key]) => key as PerioChartSequenceType)
        .filter((key) => key !== "AUTOCAL")
        .some((sequenceType) =>
          currentExam.hasSequenceEntries(sequenceType as PerioChartExamEntryVO["perioSequenceType"])
        );

    setHasHiddenData(Boolean(hiddenSequences));
  }, [currentExam, settings, setHasHiddenData]);

  return (
    <div>
      {hasHiddenData && (
        <div
          className={`
            self-center
            px-2
            py-1
            rounded
            bg-orange
            bg-opacity-10
            text-orange
            text-xs
            justify-center
          `}
        >
          Some data might be hidden because of the perio chart settings.
        </div>
      )}
    </div>
  );
};
