import { FC } from "react";
import { useNavigate } from "react-router-dom";
import { FormRequest, FormSummaryVO } from "@libs/api/generated-api";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { getInfiniteFormSummaries } from "api/forms/queries";
import { createForm, archiveForm } from "api/forms/mutations";
import { handleError } from "utils/handleError";
import { paths } from "utils/routing/paths";
import { FormsSettingsPanel } from "components/Settings/Forms/FormsSettingsPanel";
import { useItemModal } from "hooks/useItemModal";

const tableHeaders = [
  { id: "lastName", label: "Name", sortable: true, width: "minmax(11rem, 20rem)" },
  { id: "created", label: "Created", width: "minmax(11rem, 20rem)" },
  { id: "lastPublished", label: "Last Published", width: "minmax(11rem, 20rem)" },
  { id: "procedures", label: "Procedures", width: "minmax(11rem, 20rem)" },
  { id: "status", label: "Status", width: "minmax(11rem, 20rem)" },
  { id: "actions", label: "", width: "1fr" },
];

export const FormsRoute: FC = () => {
  const confirmArchiveModal = useItemModal<FormSummaryVO>(null);
  const { practiceId } = useAccount();
  const navigate = useNavigate();

  const formsQuery = useInfiniteApiQuery(
    getInfiniteFormSummaries({ args: { practiceId, types: ["INTAKE"], pageNumber: 1 } })
  );

  const [createFormMutation, archiveFormMutation] = useApiMutations([createForm, archiveForm]);

  const handleCreateForm = async () => {
    try {
      const data: FormRequest = { title: "Untitled Form", content: [{ content: [] }], type: "INTAKE" };
      const repsonse = await createFormMutation.mutateAsync({ practiceId, data });
      const formId = repsonse.data.data.uuid;

      navigate(paths.editForm({ formId }));
    } catch (error) {
      handleError(error);
    }
  };

  const handleArchiveForm = async (uuid: FormSummaryVO["uuid"]) => {
    try {
      await archiveFormMutation.mutateAsync({ practiceId, uuidOrSlug: uuid });
      confirmArchiveModal.close();
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <FormsSettingsPanel
      title="Patient Forms"
      tableHeaders={tableHeaders}
      onArchiveForm={handleArchiveForm}
      onCreateForm={handleCreateForm}
      formsQuery={formsQuery}
      isArchiving={archiveFormMutation.isLoading}
      isCreating={createFormMutation.isLoading}
      confirmArchiveModal={confirmArchiveModal}
    />
  );
};
