/* eslint-disable @typescript-eslint/no-magic-numbers */
import React, { useMemo } from "react";
import { PerioChartToothConfigVO } from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { PerioChartToothSurfaceType, PerioChartSequenceType } from "./PerioTypes";
import { usePerioChart } from "./PerioChartContext";
import {
  getTabIndexRange,
  MAX_CONTROLS_PER_TOOTH_SEQUENCE,
  getFieldNames,
  FURC_MIDDLE_TEETH_NUMBERS_FACIAL,
  FURC_MIDDLE_TEETH_NUMBERS_LINGUAL,
  FURC_OUTER_TEETH_NUMBERS,
} from "./perioChartUtils";
import { PerioChartToothControlWrapper } from "./PerioChartToothControlWrapper";
import { PerioChartAutoCalRow } from "./PerioChartAutoCalRow";

const ToothControlRow: React.FC<{
  toothConfig: PerioChartToothConfigVO;
  surface: PerioChartToothSurfaceType;
  sequenceType: Exclude<PerioChartSequenceType, "AUTOCAL">;
  isTopTeeth: boolean;
  // eslint-disable-next-line complexity
}> = ({ toothConfig, surface, sequenceType, isTopTeeth }) => {
  const { settings } = usePerioChart();
  const { toothNum } = toothConfig;
  const tabRange =
    useMemo(
      () =>
        settings &&
        getTabIndexRange(
          toothNum,
          surface,
          sequenceType,
          isTopTeeth ? settings.autoAdvance : settings.autoAdvanceMandibular
        ),
      [settings, toothNum, surface, sequenceType, isTopTeeth]
    ) || [];

  const controlCount =
    sequenceType === "MOBILITY"
      ? 1
      : sequenceType === "FURCATION"
        ? (isOneOf(toothNum, FURC_MIDDLE_TEETH_NUMBERS_FACIAL) &&
            surface === PerioChartToothSurfaceType.FACIAL) ||
          (isOneOf(toothNum, FURC_MIDDLE_TEETH_NUMBERS_LINGUAL) &&
            surface === PerioChartToothSurfaceType.LINGUAL)
          ? 1
          : isOneOf(toothNum, FURC_OUTER_TEETH_NUMBERS) && surface === PerioChartToothSurfaceType.LINGUAL
            ? 2
            : MAX_CONTROLS_PER_TOOTH_SEQUENCE
        : MAX_CONTROLS_PER_TOOTH_SEQUENCE;

  const fieldsToUse = getFieldNames(surface, toothNum);

  return (
    <div className="grid grid-cols-3 gap-[3px] mb-0.5 justify-center">
      {controlCount > 1 && (
        <PerioChartToothControlWrapper
          toothConfig={toothConfig}
          sequenceType={sequenceType}
          surface={surface}
          field={fieldsToUse[0]}
          tabIndex={tabRange[0]}
        />
      )}
      {controlCount >= 1 &&
        (controlCount === 2 ? (
          <div />
        ) : (
          <PerioChartToothControlWrapper
            controlCount={controlCount}
            toothConfig={toothConfig}
            sequenceType={sequenceType}
            surface={surface}
            field={fieldsToUse[1]}
            tabIndex={tabRange[1]}
          />
        ))}
      {controlCount > 1 && (
        <PerioChartToothControlWrapper
          toothConfig={toothConfig}
          sequenceType={sequenceType}
          surface={surface}
          field={fieldsToUse[2]}
          tabIndex={tabRange[2]}
        />
      )}
    </div>
  );
};

export type PerioChartHalfToothProps = {
  surface: PerioChartToothSurfaceType;
  toothConfig: PerioChartToothConfigVO;
  perioSequenceTypes: PerioChartSequenceType[];
  isTopTeeth: boolean;
};

export const PerioChartHalfTooth: React.FC<PerioChartHalfToothProps> = ({
  surface,
  toothConfig,
  perioSequenceTypes,
  isTopTeeth,
}) => {
  const { toothNum } = toothConfig;

  return (
    <div className="w-[54px] flex flex-col">
      {perioSequenceTypes.map((sequenceType, index) => {
        // Furcation is not available on teeth 6-11 and 20-29 on both facial and
        // lingual side. Furcation is not available on teeth 4, 5, 12, 13 on
        // facial side.
        const key = `${sequenceType}-${index}`;

        if (
          sequenceType === "FURCATION" &&
          ((toothNum >= 6 && toothNum <= 11) ||
            (isOneOf(toothNum, [4, 5, 12, 13]) && surface === PerioChartToothSurfaceType.FACIAL) ||
            (toothNum >= 20 && toothNum <= 29))
        ) {
          return <div className="mb-0.5 h-4" key={key} />;
        }

        return sequenceType === "AUTOCAL" ? (
          <PerioChartAutoCalRow toothNumber={toothNum} surface={surface} key={key} />
        ) : (
          <ToothControlRow
            key={key}
            toothConfig={toothConfig}
            isTopTeeth={isTopTeeth}
            surface={surface}
            sequenceType={sequenceType}
          />
        );
      })}
    </div>
  );
};
