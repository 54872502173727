import { FC, PropsWithChildren } from "react";
import { AuditLineItemVO, LabCaseVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent } from "components/UI/FlyoverComponents";
import { getLabCase } from "api/lab/queries";
import { Avatar } from "components/UI/Avatar";
import { LoadingOverlayV2 } from "components/UI/LoadingOverlay";

export const LabCaseHistoryFlyover: FC<{
  labCaseUuid: LabCaseVO["uuid"];
  onClose: Func;
}> = ({ labCaseUuid, onClose }) => {
  const { practiceId } = useAccount();

  const [labCaseQuery] = useApiQueries([getLabCase({ args: { practiceId, labCaseUuid } })]);

  return (
    <Flyover clickAwayToClose title="Lab Case History" onClose={onClose}>
      {() => {
        return (
          <FlyoverContent>
            <LoadingOverlayV2 isLoading={labCaseQuery.isInitialLoading}>
              <HistoryList>
                {labCaseQuery.data?.audit?.auditHistory?.map((audit, i) => {
                  return <HistoryItem key={i} audit={audit} />;
                })}
              </HistoryList>
            </LoadingOverlayV2>
          </FlyoverContent>
        );
      }}
    </Flyover>
  );
};

const HistoryList: FC<PropsWithChildren> = ({ children }) => {
  return <div className="flex flex-col gap-y-4">{children}</div>;
};

const HistoryItem: FC<{
  audit: AuditLineItemVO;
}> = ({ audit }) => {
  return (
    <div className="flex gap-x-3 py-2">
      <Avatar name={audit.doneBy?.name.fullDisplayName} />
      <div className="flex flex-col gap-y-1">
        <span className="text-xs text-slate-700">
          {audit.doneBy?.name.fullDisplayName} • {formatISODate(audit.doneAt, "P • p")}
        </span>
        <span className="text-xs font-sansSemiBold whitespace-pre-wrap">{audit.text}</span>
      </div>
    </div>
  );
};
