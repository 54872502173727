import { FC, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as RemoveIcon } from "@libs/assets/icons/cancel-small.svg";
import { Text } from "components/PatientProfile/Billing/Ledger/Text";

export const FamilyMemberHeader: FC<{
  name: ReactNode;
  relationship: ReactNode;
  className?: string;
  onRemove?: Func;
}> = ({ name, relationship, className, onRemove }) => {
  return (
    <div className={cx("flex items-center justify-between py-1 bg-slate-100 pr-2", className)}>
      <div>
        <Text bold>{name}</Text>
        <Text> – {relationship}</Text>
      </div>
      {onRemove && (
        <ButtonIcon
          onClick={onRemove}
          SvgIcon={RemoveIcon}
          size="md"
          tooltip={{ content: "Remove", theme: "SMALL" }}
        />
      )}
    </div>
  );
};
