import { UncollectedByServiceEntryVO } from "@libs/api/generated-api";
import { OutstandingCollectionType } from "components/Dashboard/OutstandingCollections/types";

export const labelForCollectionType = (key: OutstandingCollectionType) => {
  const labelMap: Record<OutstandingCollectionType, string> = {
    patientOwedInvoiceCreated: "Pt Owed",
    insuranceOwedSubmitted: "Ins Owed",
    patientOwedInvoiceNotCreated: "Invoice Not Created",
    insuranceOwedUnsubmitted: "Claims Not Submitted",
  };

  return labelMap[key];
};

export const labelForServiceEntryType = (key: UncollectedByServiceEntryVO["entryType"]) => {
  const labelMap: Record<UncollectedByServiceEntryVO["entryType"], string> = {
    APPOINTMENT: "Appointment",
    ADJUSTMENT: "Patient Account Adjustment",
  };

  return labelMap[key];
};
