import { FC, useMemo } from "react";
import { createSelectStyles } from "@libs/components/UI/selectStyles";
import { SlotPreferences } from "components/ScheduleAppointments/types";
import { ToggleButtonList } from "components/UI/ToggleButtonList";
import {
  beginningOfDay,
  isBeforeMidDay,
  isBeginningOfDay,
  midDay,
  TimeSlotPreference,
  timePreferenceToTimeString,
  formatTimePreference,
  isAfterMidDay,
} from "components/ScheduleAppointments/timeSlotPreference";
import { FormFieldSelect } from "components/UI/FormFieldSelect";

const TimeSlotList = [
  { value: "AM" as const, label: "AM" },
  { value: "PM" as const, label: "PM" },
];

interface Props {
  slotPreferences: SlotPreferences;
  className?: string;
  onUpdateSlotPreferences: (updates: Partial<SlotPreferences>) => void;
  disabled: boolean;
}

type TimeSlotOption = {
  time: TimeSlotPreference;
  label: string;
  value: string;
};

const getTimeSlotOption = (time: TimeSlotPreference) => ({
  time,
  label: formatTimePreference(time),
  value: timePreferenceToTimeString(time),
});

const timeOptionMenuStyles = createSelectStyles<string, TimeSlotOption>({
  control: () => ({ minHeight: "26px" }),
});

export const AppointmentTimeSlotPreferences: FC<Props> = ({
  slotPreferences,
  onUpdateSlotPreferences,
  disabled,
}) => {
  // eslint-disable-next-line complexity
  const timeOptions = useMemo(() => {
    const fromOptions: TimeSlotOption[] = [];
    const toOptions: TimeSlotOption[] = [];
    const hoursOptions = Array.from({ length: 24 }, (_, index) => index);
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    const minutesOptions = [0, 30];

    for (const hours of hoursOptions) {
      if (isBeginningOfDay(slotPreferences.to)) {
        for (const minutes of minutesOptions) {
          fromOptions.push(getTimeSlotOption({ hours, minutes, seconds: 0 }));
        }
      } else if (hours <= slotPreferences.to.hours) {
        for (const minutes of minutesOptions) {
          if (hours !== slotPreferences.to.hours || minutes < slotPreferences.to.minutes) {
            fromOptions.push(getTimeSlotOption({ hours, minutes, seconds: 0 }));
          }
        }
      }

      if (isBeginningOfDay(slotPreferences.from)) {
        for (const minutes of minutesOptions) {
          if (hours !== 0 || minutes !== 0) {
            toOptions.push(getTimeSlotOption({ hours, minutes, seconds: 0 }));
          }
        }
      } else if (hours >= slotPreferences.from.hours) {
        for (const minutes of minutesOptions) {
          if (
            (hours !== slotPreferences.from.hours || minutes > slotPreferences.from.minutes) &&
            (hours !== 0 || minutes !== 0)
          ) {
            toOptions.push(getTimeSlotOption({ hours, minutes, seconds: 0 }));
          }
        }
      }
    }

    toOptions.push(getTimeSlotOption(beginningOfDay));

    return { fromOptions, toOptions };
  }, [slotPreferences.from, slotPreferences.to]);

  const amPmSelection = useMemo(() => {
    const fromValue = isBeforeMidDay(slotPreferences.from) ? "AM" : "PM";
    const toValue = isBeginningOfDay(slotPreferences.to) || isAfterMidDay(slotPreferences.to) ? "PM" : "AM";

    return [fromValue, toValue].every((val) => val === "AM")
      ? "AM"
      : [fromValue, toValue].every((val) => val === "PM")
        ? "PM"
        : undefined;
  }, [slotPreferences.from, slotPreferences.to]);

  const selectedFrom = useMemo(
    () => timePreferenceToTimeString(slotPreferences.from),
    [slotPreferences.from]
  );
  const selectedTo = useMemo(() => timePreferenceToTimeString(slotPreferences.to), [slotPreferences.to]);

  return (
    <div className="flex items-start ml-8">
      <div>
        <div className="mb-2 text-xxs">Time Slot</div>
        <ToggleButtonList
          type="radio"
          disabled={disabled}
          shape="mediumSquare"
          options={TimeSlotList}
          selectedValue={amPmSelection}
          onChange={(e, option) => {
            onUpdateSlotPreferences(
              option.value === "AM"
                ? {
                    from: beginningOfDay,
                    to: midDay,
                  }
                : { from: midDay, to: beginningOfDay }
            );
          }}
        />
      </div>
      <div className="ml-8">
        <div className="mb-2 text-xxs">From</div>
        <FormFieldSelect
          disabled={disabled}
          isClearable={false}
          isSearchable={false}
          styles={timeOptionMenuStyles}
          options={timeOptions.fromOptions}
          value={selectedFrom}
          onChange={(val) => {
            onUpdateSlotPreferences({
              from: val?.time ?? beginningOfDay,
            });
          }}
        />
      </div>
      <div className="ml-1">
        <div className="mb-2 text-xxs">To</div>
        <FormFieldSelect
          disabled={disabled}
          isClearable={false}
          isSearchable={false}
          styles={timeOptionMenuStyles}
          options={timeOptions.toOptions}
          value={selectedTo}
          onChange={(val) => {
            onUpdateSlotPreferences({
              to: val?.time ?? beginningOfDay,
            });
          }}
        />
      </div>
    </div>
  );
};
