import { FC, MouseEvent, useMemo } from "react";
import { FormSelectInputElementVO, FormSelectResponseVO } from "@libs/api/generated-api";
import { isNullish } from "@libs/utils/types";
import { FormSubmissionResponses } from "components/PatientProfile/Forms/types";
import { MultiSelectValues } from "components/PatientProfile/Forms/MultiSelectValues";
import { YesNoList } from "components/UI/YesNoList";

export const SelectExplicitInput: FC<{
  element: FormSelectInputElementVO;
  response?: FormSelectResponseVO;
  onUpdate?: (updater: (last: FormSubmissionResponses) => void) => void;
}> = ({ element, onUpdate, response }) => {
  const edit = Boolean(onUpdate);

  const questions = useMemo(
    () =>
      element.options.map((o) => {
        const optionResponse = response?.responses[o];

        return {
          label: o,
          ...(isNullish(optionResponse) ? undefined : { response: optionResponse }),
        };
      }),
    [element.options, response]
  );

  const readOnlyValues = useMemo(
    () => element.options.filter((o) => response?.responses[o]),
    [element.options, response]
  );

  const handleClick = (_: MouseEvent<HTMLElement>, question: Required<ListItem<typeof questions>>) => {
    onUpdate?.((currentResponses) => {
      const currentValue = currentResponses[element.uuid];

      if (currentValue?.type === "SELECT") {
        if (question.response === currentValue.responses[question.label]) {
          delete currentValue.responses[question.label];
        } else {
          currentValue.responses[question.label] = question.response;
        }
      } else {
        currentResponses[element.uuid] = {
          type: "SELECT",
          responses: {
            [question.label]: question.response,
          },
        };
      }
    });
  };

  return edit ? (
    <YesNoList label={element.title} questions={questions} onOptionClick={handleClick} />
  ) : (
    <div>
      <div className="text-xs mb-2">{element.title}</div>
      <MultiSelectValues values={readOnlyValues} />
    </div>
  );
};
