import { ComponentProps, FC, FormEventHandler, ReactNode } from "react";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";
import { Form } from "@libs/components/UI/Form";
import { ModalPage } from "components/UI/ModalPage";
import { LoadingOverlayV2 } from "components/UI/LoadingOverlay";

export const PaymentLayout: FC<{
  title: ReactNode;
  closeLink: ComponentProps<typeof ModalPage>["closeLink"];
  isLoadingInvoiceList?: boolean;
  invoiceList: ReactNode;
  isLoadingPaymentForm: boolean;
  paymentForm: ReactNode;
  paymentButtons: ReactNode;
  pageBanner?: ReactNode;
  onSubmit: FormEventHandler<HTMLFormElement>;
}> = ({
  title,
  closeLink,
  isLoadingInvoiceList = false,
  invoiceList,
  isLoadingPaymentForm,
  paymentForm,
  paymentButtons,
  pageBanner,
  onSubmit,
}) => {
  return (
    <ModalPage title={title} closeLink={closeLink} className="w-full">
      <ModalContent className="h-full">
        <div className="grid grid-cols-[1fr,min-content,33rem] divide-x h-full">
          <LoadingOverlayV2 isLoading={isLoadingInvoiceList}>
            <div>
              {pageBanner ?? null}
              {invoiceList}
            </div>
          </LoadingOverlayV2>
          <VerticalDivider />
          <LoadingOverlayV2 opaque isLoading={isLoadingPaymentForm}>
            <Form className="flex flex-col h-full" onSubmit={onSubmit}>
              <ModalContent padding="md">{paymentForm}</ModalContent>
              <ModalFooter actions>{paymentButtons}</ModalFooter>
            </Form>
          </LoadingOverlayV2>
        </div>
      </ModalContent>
    </ModalPage>
  );
};
