import React, { FC } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { OutstandingCollectionsChart } from "components/Dashboard/OutstandingCollections/OutstandingCollectionsChart";
import { OutstandingCollectionsTable } from "components/Dashboard/OutstandingCollections/OutstandingCollectionsTable";
import { useInvoiceAgeOptions } from "components/Dashboard/OutstandingCollections/hooks/useInvoiceAgeOptions";
import { DashboardLayout } from "components/Dashboard/DashboardLayout";
import { SelectReportingQuestion } from "components/Dashboard/SelectReportingQuestion";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";
import { getTimeSeriesQuery } from "api/reporting/queries";
import { OutstandingCollectionsQuery } from "utils/routing/dashboard";
import { cxStyles } from "components/Dashboard/DashboardPageHeader";

export const OutstandingCollectionsRoute: FC = () => {
  const invoiceAgeOptions = useInvoiceAgeOptions();
  const { query, updateQuery } = useQueryParams("dashboardPaymentOutstanding");
  const { dashboardQuestion } = usePathParams("dashboardTab");

  const seriesRequest = {
    resolution: "AGING" as const,
    filters: [],
  };
  const { practiceId } = useAccount();
  const [insuranceQuery, invoicedBalanceQuery, unInvoicedBalanceQuery] = useApiQueries([
    getTimeSeriesQuery({
      args: {
        practiceId,
        data: {
          ...seriesRequest,
          metric: "insuranceUncollectedAmount",
          groupBy: "isSubmitted",
        },
      },
    }),
    getTimeSeriesQuery({
      args: {
        practiceId,
        data: {
          ...seriesRequest,
          metric: "invoicedBalance",
        },
      },
    }),
    getTimeSeriesQuery({
      args: {
        practiceId,
        data: {
          ...seriesRequest,
          metric: "uninvoicedBalance",
        },
      },
    }),
  ]);

  const handleRouteStateChange = React.useCallback(
    (updates: Partial<OutstandingCollectionsQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );

  return (
    <DashboardLayout
      className="min-w-[1100px]"
      header={
        <>
          <div className={cxStyles.pageHeader}>
            <SelectReportingQuestion question={dashboardQuestion} />
            <div className="flex items-center space-x-4">
              <div className="text-sm font-sansSemiBold">Invoice Age</div>
              <FormFieldSelect
                isClearable={false}
                className="min-w-32"
                isSearchable={false}
                options={invoiceAgeOptions}
                value={query.dateAgeIndex}
                onChange={(option) => {
                  if (option) {
                    updateQuery("replaceIn", { dateAgeIndex: option.value });
                  }
                }}
              />
            </div>
          </div>

          {query["table.fullScreen"] ? null : (
            <OutstandingCollectionsChart
              isLoading={
                insuranceQuery.isLoading || invoicedBalanceQuery.isLoading || unInvoicedBalanceQuery.isLoading
              }
              dateAgeIndex={query.dateAgeIndex}
              insuranceData={insuranceQuery.data}
              invoicedBalanceData={invoicedBalanceQuery.data}
              unInvoicedBalanceData={unInvoicedBalanceQuery.data}
              onToggleBarSelection={handleRouteStateChange}
            />
          )}
        </>
      }
    >
      <OutstandingCollectionsTable query={query} onUpdateParams={handleRouteStateChange} />
    </DashboardLayout>
  );
};
