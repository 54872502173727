import { useState } from "react";
import { FormNumberInputElementRequest } from "@libs/api/generated-api";
import { max as maxValue, min as minValue } from "@libs/utils/validators";
import { isNullish } from "@libs/utils/types";
import { titleSchema } from "components/Settings/Forms/utils";

export const getNumberSchema = ({ min, max }: { min: number | undefined; max: number | undefined }) => ({
  title: titleSchema,

  min: [
    {
      $v: isNullish(max) ? () => true : maxValue(max),
      $error: "The minimum value must be less than the maximum",
      $ignore: isNullish(max),
    },
  ],
  max: [
    {
      $v: isNullish(min) ? () => true : minValue(min),
      $error: "The maximum value must be greater than the minimum",
      $ignore: isNullish(min),
    },
  ],
});

export const useNumberFormState = (
  element: FormNumberInputElementRequest | (() => FormNumberInputElementRequest)
) => {
  return useState(() => ({ draft: typeof element === "function" ? element() : element }));
};
