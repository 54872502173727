import { Link } from "react-router-dom";
import { titleCaseConstant } from "@libs/utils/casing";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as ExternalLinkIcon } from "@libs/assets/icons/external-link.svg";
import { ReactComponent as CheckIcon } from "@libs/assets/icons/check-circle-1.svg";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel-circle.svg";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { planCxStyles } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/styles";
import { PlanFields } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/usePlanFields";
import { planTypeNames } from "components/PatientProfile/Insurance/utils";
import { paths } from "utils/routing/paths";

interface Props {
  planFields: PlanFields;
}

// eslint-disable-next-line complexity
export const LinkedPlanSection: React.FC<Props> = ({ planFields }) => {
  const sharedProps = {
    edit: false,
    layout: "labelOut" as const,
  };

  return (
    <div className={planCxStyles.fieldsContainer}>
      <FormFieldInput {...sharedProps} label="Group Number" value={planFields.groupNumber ?? EMPTY_CELL} />
      <FormFieldInput
        {...sharedProps}
        label="Group Name"
        value={planFields.groupName ? titleCaseConstant(planFields.groupName) : EMPTY_CELL}
      />
      <FormFieldInput {...sharedProps} label="Nickname" value={planFields.description ?? EMPTY_CELL} />
      <FormFieldInput {...sharedProps} label="Employer" value={planFields.employerName ?? EMPTY_CELL} />
      <FormFieldInput
        {...sharedProps}
        label="Plan Type"
        value={planFields.planType ? planTypeNames[planFields.planType] : EMPTY_CELL}
      />
      <div className="flex flex-col gap-y-1">
        <FormFieldLabel className="text-xs font-sansSemiBold" content="Fee schedule" />
        {planFields.feeSchedule ? (
          <Link
            className={planCxStyles.linkFieldContainer()}
            to={paths.feeScheduleDetails({ feeScheduleId: planFields.feeSchedule.id })}
          >
            {planFields.feeSchedule.name}
            <Icon SvgIcon={ExternalLinkIcon} size="sm" theme="primary" />
          </Link>
        ) : (
          EMPTY_CELL
        )}
      </div>
      <FormFieldInput
        {...sharedProps}
        label="Benefits Renew In"
        value={planFields.renewalMonth ? titleCaseConstant(planFields.renewalMonth) : EMPTY_CELL}
      />
      <FormFieldInput
        {...sharedProps}
        label="Waiting Period"
        value={planFields.waitingPeriodInMonths ? `${planFields.waitingPeriodInMonths} Months` : EMPTY_CELL}
      />
      <FormFieldInput
        {...sharedProps}
        label="Dependent Max Age"
        value={planFields.dependentMaxAge ? `${planFields.dependentMaxAge} Years` : EMPTY_CELL}
      />
      <FormFieldInput
        {...sharedProps}
        label="Coordination of Benefits"
        value={
          planFields.coordinationOfBenefits
            ? planFields.coordinationOfBenefits === "NON_DUPLICTION"
              ? "None"
              : titleCaseConstant(planFields.coordinationOfBenefits)
            : EMPTY_CELL
        }
      />
      <FormFieldInput
        {...sharedProps}
        label="Eligibility Coverage Level"
        value={
          planFields.eligibilityCoverageLevel
            ? titleCaseConstant(planFields.eligibilityCoverageLevel)
            : EMPTY_CELL
        }
      />
      <div className="flex flex-col gap-y-1">
        <FormFieldLabel className="text-xs font-sansSemiBold" content="Out of Network Benefits" />
        <div className={planCxStyles.linkFieldContainer("text-slate-900")}>
          <Icon
            SvgIcon={planFields.outOfNetworkBenefits ? CheckIcon : CancelIcon}
            size="sm"
            theme={planFields.outOfNetworkBenefits ? "success" : "error"}
          />
          {planFields.outOfNetworkBenefits ? "Yes" : "No"}
        </div>
      </div>
      <div className="flex flex-col gap-y-1">
        <FormFieldLabel className="text-xs font-sansSemiBold" content="Missing Tooth Clause" />
        <div className={planCxStyles.linkFieldContainer("text-slate-900")}>
          <Icon
            SvgIcon={planFields.missingToothClause ? CheckIcon : CancelIcon}
            size="sm"
            theme={planFields.missingToothClause ? "success" : "error"}
          />
          {planFields.missingToothClause ? "Yes" : "No"}
        </div>
      </div>
      <FormFieldInput
        {...sharedProps}
        label="Crown/Bridges Paid On"
        value={planFields.crownBridgesPaidOn ? titleCaseConstant(planFields.crownBridgesPaidOn) : EMPTY_CELL}
      />
      <FormFieldInput
        {...sharedProps}
        label="Insurance Representative"
        value={planFields.insuranceRepresentative ?? EMPTY_CELL}
      />
      <div className="flex flex-col gap-y-1">
        <FormFieldLabel className="text-xs font-sansSemiBold" content="Carrier Contacted" />
        <div className={planCxStyles.linkFieldContainer("text-slate-900")}>
          <Icon
            SvgIcon={planFields.carrierContacted ? CheckIcon : CancelIcon}
            size="sm"
            theme={planFields.carrierContacted ? "success" : "error"}
          />
          {planFields.carrierContacted
            ? `${planFields.contactedBy ? `By ${planFields.contactedBy.fullDisplayName}` : "Yes"}`
            : "No"}
        </div>
      </div>
    </div>
  );
};
