import { FC, useMemo } from "react";

import { PatientSummaryVO, ProfileImageVO } from "@libs/api/generated-api";
import { formatPhoneNumber } from "@libs/utils/phone";
import { formatFullNameToInitials, getAgeByDob } from "@libs/utils/formatString";
import { formatISODate } from "@libs/utils/date";
import { isDefined } from "@libs/utils/types";

import { ProfilePhoto } from "components/Shared/ProfilePhoto";
import { genderInitial } from "components/PatientProfile/PatientOverview/utils";
import { useNow } from "hooks/useNow";

interface Props {
  patient: PatientSummaryVO;
  patientImage: ProfileImageVO;
  isHipaaView: boolean;
}

export const PatientCard: FC<Props> = ({ patient, patientImage, isHipaaView }) => {
  const now = useNow();

  const { name, namePronuncation, details } = useMemo(() => {
    const patientName = patient.name.fullDisplayName;
    const patientFields = isHipaaView
      ? {
          age: undefined,
          dob: undefined,
          gender: undefined,
          name: formatFullNameToInitials({ fullName: patientName }),
          namePronuncation: undefined,
          phoneNumber: "(xxx) xxx-xxxx",
        }
      : {
          age: getAgeByDob(now, patient.dob),
          dob: patient.dob ? formatISODate(patient.dob, "MM/dd/yy") : undefined,
          gender: patient.gender ? genderInitial[patient.gender] : undefined,
          name: patientName,
          namePronuncation: patient.namePronunciation,
          phoneNumber: patient.contact.callPhone ? formatPhoneNumber(patient.contact.callPhone) : undefined,
        };
    const ageGender = [patientFields.age, patientFields.gender].filter(isDefined);
    const patientAgeGender = ageGender.length > 0 ? ageGender.join(" ") : undefined;
    const patientDetails = [patientFields.dob, patientAgeGender, patientFields.phoneNumber]
      .filter(isDefined)
      .join(" • ");

    return {
      name: patientFields.name,
      namePronuncation: patientFields.namePronuncation,
      details: patientDetails,
    };
  }, [isHipaaView, patient, now]);

  return (
    <div className="flex items-center gap-x-2 text-xs">
      <div className="h-14 w-14">
        <ProfilePhoto
          userId={patient.id}
          name={patient.name.fullDisplayName}
          imageUrl={patientImage.url}
          isHipaaView={isHipaaView}
        />
      </div>

      <div className="flex-1 flex flex-col">
        <span className="text-sm font-sansSemiBold">{name}</span>
        {namePronuncation && <span className="italic">{namePronuncation}</span>}
        {details}
      </div>
    </div>
  );
};
