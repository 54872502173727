// eslint-disable-next-line unicorn/prefer-node-protocol
import React from "react";
import { useThrottledCallback } from "use-debounce";

import { getHeightAndWidthFromDataUrl } from "@libs/utils/dataUrl";
import { TwainContext } from "components/ImageCapturing/TwainContext";
import { beginXRayImageCaptureMode } from "components/ImageCapturing/twain";

import { logTwainErrorToSentry, TwainError } from "components/ImageCapturing/twainErrors";

import { assertTwainIsReady, useTwain } from "components/ImageCapturing/useTwain";
import {
  importImageFromTwain,
  rotateImageInBuffer,
} from "components/PatientProfile/Imaging/MountRoute/hooks/useDynamsoftUploader";

export const useDynamsoftSensorSampleImage = () => {
  const { twain: webTwain, setTwainLoadError, onImageReadyRef } = React.useContext(TwainContext);
  const { twainInstance, twainSources } = useTwain();
  const { selectIndex, items: sensors } = twainSources ?? {};

  const captureSampleImage = React.useCallback(
    async ({ deviceId }: { deviceId: string }) => {
      assertTwainIsReady(webTwain);

      if (!sensors || !twainInstance.isReady) {
        return undefined;
      }

      const sensorSelectedIndex = sensors.findIndex((item) => item.label === deviceId);

      if (sensorSelectedIndex >= 0 && selectIndex && twainInstance.webTwain) {
        await selectIndex(sensorSelectedIndex);
      }

      const base64Image = await new Promise<string>((resolve) => {
        setTwainLoadError(null);

        onImageReadyRef.current = async () => {
          const imageIndex = webTwain.HowManyImagesInBuffer - 1;
          const imageWidth = webTwain.GetImageWidth(imageIndex);
          const imageHeight = webTwain.GetImageHeight(imageIndex);

          if (imageWidth < imageHeight) {
            await rotateImageInBuffer({
              webTwain,
              indexToProcess: imageIndex,
            });
          }

          const imageBase64Url = await importImageFromTwain({
            webTwain,
            indexToProcess: imageIndex,
            onTwainError: (errorCode: number, errorString: string) => {
              logTwainErrorToSentry(
                new TwainError({
                  errorCode,
                  errorString,
                  api: "ConvertToBase64",
                })
              );
              setTwainLoadError(
                new Error("Failed to collect x-ray", { cause: new Error(`${errorCode}: ${errorString}`) })
              );
            },
          });

          webTwain.CloseSourceAsync();
          webTwain.RemoveAllImages();
          onImageReadyRef.current = null;
          resolve(imageBase64Url);
        };
        beginXRayImageCaptureMode(webTwain);
      });
      const { width, height } = await getHeightAndWidthFromDataUrl(base64Image);

      return { url: base64Image, w: width, h: height };
    },
    [
      onImageReadyRef,
      selectIndex,
      sensors,
      setTwainLoadError,
      twainInstance.isReady,
      twainInstance.webTwain,
      webTwain,
    ]
  );
  const THROTTLED_START_MS = 1500;

  // Prevents spamming of dynamsoft API from user clicks
  const captureSampleImageThrottled = useThrottledCallback(captureSampleImage, THROTTLED_START_MS, {
    trailing: false,
  });

  return {
    captureSampleImage: captureSampleImageThrottled,
  };
};
