import { addMonths, subMonths } from "date-fns";
import { useState } from "react";

export const useMonthNavigation = (selected: Date, monthsToShow: number) => {
  const [openToDate, setOpenToDate] = useState(selected);
  const [openKey, setOpenKey] = useState(0);

  const increaseMonths = () => {
    setOpenToDate(addMonths(openToDate, monthsToShow));
  };

  const decreaseMonths = () => {
    setOpenToDate(subMonths(openToDate, monthsToShow));
  };

  const handleOpenDatepicker = (date: Date) => {
    setOpenToDate(date);
    // This forces the component to re-render whenever the datepicker is opened
    // This is needed to make sure the selected date is always the first date that
    // is displayed first in the list of months.
    setOpenKey((prev) => prev + 1);
  };

  return {
    increaseMonths,
    decreaseMonths,
    handleOpenDatepicker,
    openToDate,
    openKey,
  };
};
