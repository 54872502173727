import React from "react";
import { BaseApiEntryResult, UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";

type InfiniteEntryResult<Entry> = UseInfiniteApiQueryResult<BaseApiEntryResult<Entry>>["data"] | undefined;

export const flattenEntries = <Entry>(data: InfiniteEntryResult<Entry>) =>
  data?.pages.flatMap((item) => item.data.entries) ?? [];

export const useFlattenEntries = <Entry>(data: InfiniteEntryResult<Entry>) => {
  return React.useMemo(() => data?.pages.flatMap((item) => item.data.entries) ?? [], [data?.pages]);
};
