import { FC } from "react";
import { BatchFormResponseRequest, FormSubmissionVO, FormVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Flyover } from "components/UI/Flyover";
import { getPublishedForm } from "api/forms/queries";
import { submitFormResponses } from "api/forms/mutations";
import { EditResponsesForm } from "components/PatientProfile/Forms/EditResponsesForm";
import { handleError } from "utils/handleError";
import { getPatientSummary } from "api/patients/queries";

interface Props {
  title: string;
  uuid: string;
  slug?: NonNullable<FormVO["slug"]>;
  patientId: number;
  responses: FormSubmissionVO["responses"];
  onClose: Func;
}

export const EditResponses: FC<Props> = ({ uuid, slug, responses, onClose, patientId, title }) => {
  const { practiceId } = useAccount();
  const [patientQuery, formQuery] = useApiQueries([
    getPatientSummary({ args: { patientId, practiceId } }),
    getPublishedForm({ args: { practiceId, patientId, uuidOrSlug: uuid } }),
  ]);
  const [updateFormResponsesMutation] = useApiMutations([submitFormResponses]);

  const handleSubmit = (data: BatchFormResponseRequest) => {
    updateFormResponsesMutation.mutate(
      {
        practiceId,
        patientId,
        uuid,
        slug,
        data,
      },
      {
        onError: handleError,
        onSuccess: onClose,
      }
    );
  };

  return (
    <Flyover size="lg" title={title} onClose={onClose}>
      <QueryResult queries={[patientQuery, { isError: formQuery.isError, isLoading: formQuery.isFetching }]}>
        {formQuery.data && patientQuery.data ? (
          <EditResponsesForm
            isSaving={updateFormResponsesMutation.isLoading}
            responses={responses}
            patient={patientQuery.data}
            form={formQuery.data}
            onClose={onClose}
            onSubmit={handleSubmit}
          />
        ) : null}
      </QueryResult>
    </Flyover>
  );
};
