import { FC, useId, useCallback } from "react";

import { UpdateEmailConfigRequest } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";

import { SettingsPanel } from "components/Settings/SettingsPanel";
import { EmailPreferences } from "components/Settings/Communications/EmailPreferences";

import { getEmailConfig } from "api/settings/communications/queries";
import { updateEmailConfig } from "api/settings/communications/mutations";

import { handleError } from "utils/handleError";

export const EmailPreferencesRoute: FC = () => {
  const { practiceId } = useAccount();
  const editing = useBoolean(false);
  const formId = useId();

  const [emailConfigQuery] = useApiQueries([getEmailConfig({ args: { practiceId } })]);

  const [updateEmailConfigMutation] = useApiMutations([updateEmailConfig]);

  const handleUpdateEmails = useCallback(
    (emails: Pick<UpdateEmailConfigRequest, "replyToEmail" | "fromEmail">) => {
      if (!emailConfigQuery.data) {
        return;
      }

      updateEmailConfigMutation.mutate(
        {
          practiceId,
          data: {
            domain: emailConfigQuery.data.domain,
            ...emails,
          },
        },
        {
          onSuccess: editing.off,
          onError: handleError,
        }
      );
    },
    [emailConfigQuery.data, updateEmailConfigMutation, practiceId, editing.off]
  );

  return (
    <QueryResult queries={[emailConfigQuery]}>
      {emailConfigQuery.data ? (
        <SettingsPanel
          title="Email Preferences"
          footer={
            editing.isOn ? (
              <div className="flex items-center justify-center gap-x-2">
                <Button className="min-w-button" onClick={editing.off} theme="secondary">
                  Cancel
                </Button>
                <AsyncButton
                  className="min-w-button"
                  isLoading={updateEmailConfigMutation.isLoading}
                  form={formId}
                  type="submit"
                >
                  Save
                </AsyncButton>
              </div>
            ) : undefined
          }
          onToggleEdit={editing.toggle}
          isEditing={editing.isOn}
        >
          <EmailPreferences
            formId={formId}
            emailConfig={emailConfigQuery.data}
            onUpdateEmails={handleUpdateEmails}
            isEditing={editing.isOn}
          />
        </SettingsPanel>
      ) : null}
    </QueryResult>
  );
};
