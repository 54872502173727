import { FC, FormEvent, useRef, useState, useCallback } from "react";
import { produce } from "immer";
import { flushSync } from "react-dom";

import {
  PatientProcedureVO,
  PatientProcedureInsuranceStatusVO,
  DentalProcedureVO,
} from "@libs/api/generated-api";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useAccount } from "@libs/contexts/AccountContext";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useValidation } from "@libs/hooks/useValidation";

import { FlyoverForm, FlyoverContent, FlyoverFooter } from "components/UI/FlyoverComponents";
import { Divider } from "components/UI/Divider";

import { EditEstimatesSummary } from "components/Charting/EditEstimatesSummary";
import { EditEstimatesPrimary } from "components/Charting/EditEstimatesPrimary";
import { EditEstimatesSecondary } from "components/Charting/EditEstimatesSecondary";

import {
  UpdatePatientProcedureFeeDraft,
  getUpdatePatientProcedureFeeDraft,
  getUpdatePatientProcedureFeeDraftSchema,
  getUpdatePatientProcedureFeeRequest,
} from "components/Charting/patientProcedureUtils";

import { updatePatientProcedureFee, resetPrimaryEstimates } from "api/charting/mutations";
import { scrollToFirstError } from "utils/scrollToFirstError";
import { handleError } from "utils/handleError";

interface Props {
  patientProcedure: PatientProcedureVO;
  patientProcedureInsuranceStatus: PatientProcedureInsuranceStatusVO;
  dentalProcedures: DentalProcedureVO[];
  onSaved: Func;
  onClose: Func;
}

export const EditEstimatesForm: FC<Props> = ({
  patientProcedure,
  patientProcedureInsuranceStatus,
  dentalProcedures,
  onSaved,
  onClose,
}) => {
  const { practiceId } = useAccount();
  const formContainerRef = useRef<HTMLDivElement>(null);
  const [patientProcedureFeeDraft, setPatientProcedureFeeDraft] = useState<UpdatePatientProcedureFeeDraft>(
    () => getUpdatePatientProcedureFeeDraft(patientProcedure)
  );

  const canEditUcrFee =
    !patientProcedureInsuranceStatus.primaryClaimSubmitted &&
    (patientProcedure.ucrRate === 0 || patientProcedure.ucrManualOverride);

  const canEditPreAuth =
    !patientProcedureInsuranceStatus.primaryClaimSubmitted && Boolean(patientProcedure.preAuthStatus);

  const canEditPrimary =
    !patientProcedureInsuranceStatus.primaryClaimSubmitted && !patientProcedure.preAuthStatus;

  const canEditSecondary =
    !patientProcedureInsuranceStatus.secondaryClaimSubmitted &&
    Boolean(patientProcedureInsuranceStatus.secondaryInsuranceExists);

  const canEdit = canEditPreAuth || canEditPrimary || canEditSecondary;

  const [updatePatientProcedureFeeMutation, resetPrimaryEstimatesMutation] = useApiMutations([
    updatePatientProcedureFee,
    resetPrimaryEstimates,
  ]);
  const updatePatientProcedureFeeMutate = updatePatientProcedureFeeMutation.mutate;
  const updatePatientProcedureFeeMutateAsync = updatePatientProcedureFeeMutation.mutateAsync;
  const resetPrimaryEstimatesMutateAsync = resetPrimaryEstimatesMutation.mutateAsync;

  const { validate, result: validationResult } = useValidation(
    patientProcedureFeeDraft,
    getUpdatePatientProcedureFeeDraftSchema(patientProcedureFeeDraft, {
      canEditPreAuth,
      canEditPrimary,
      canEditSecondary,
    })
  );

  const updatePreAuthEstimates = useCallback(
    (updatedProcedure: PatientProcedureVO) => {
      return updatePatientProcedureFeeMutateAsync({
        practiceId,
        patientProcedureId: patientProcedure.id,
        data: getUpdatePatientProcedureFeeRequest(
          produce(getUpdatePatientProcedureFeeDraft(updatedProcedure), (draft) => {
            if (draft.preAuth && patientProcedureFeeDraft.preAuth) {
              // We have to include the pre-auth status and number to update
              draft.preAuth.status = patientProcedureFeeDraft.preAuth.status;
              draft.preAuth.number = patientProcedureFeeDraft.preAuth.number;
              draft.preAuth.insuranceAmount = draft.primaryInsuranceAmount;
              draft.preAuth.patientAmount = draft.primaryPatientAmount;
              draft.preAuth.deductibleAmount = draft.primaryDeductibleAmount;
            }
          })
        ),
      });
    },
    [updatePatientProcedureFeeMutateAsync, practiceId, patientProcedure.id, patientProcedureFeeDraft.preAuth]
  );

  const handleChangeDowngradedDentalProcedure = useCallback(
    async (
      downgradeDentalProcedureId: number | undefined,
      options?: { onSuccess?: Func; onError?: Func }
    ) => {
      try {
        const downgradeResponse = await updatePatientProcedureFeeMutateAsync({
          practiceId,
          patientProcedureId: patientProcedure.id,
          data: getUpdatePatientProcedureFeeRequest(
            produce(patientProcedureFeeDraft, (draft) => {
              // When removing the primary downgraded procedure, we must set to
              // -1 instead of undefined; this is setup in the BE to avoid
              // unintentional nullification from the PUT request
              draft.downgradeDentalProcedureId = downgradeDentalProcedureId ?? -1;

              // If downgrade is updated for a patient procedure with pre-auth,
              // we do not want to include pre-auth in order to re-calculate the
              // patient and insurance amounts; once pre-auth insurance and
              // patient amounts are saved, BE will not re-calculate
              draft.preAuth = undefined;
            })
          ),
        });

        let updatedProcedure = downgradeResponse.data.data;

        if (canEditPreAuth) {
          // https://grindfoundry.atlassian.net/browse/GF-7843
          // We have to update the pre-auth estimates with the primary estimates
          // when there is a change to the downgraded procedure, in order to
          // match the same behavior of primary estimates being saved on a
          // downgrade; this is a temporary solution until BE supports running a
          // dry re-calculation of estimates without saving it to the procedure
          const preAuthResponse = await updatePreAuthEstimates(updatedProcedure);

          updatedProcedure = preAuthResponse.data.data;
        }

        setPatientProcedureFeeDraft(getUpdatePatientProcedureFeeDraft(updatedProcedure));
        onSaved();
        options?.onSuccess?.();
      } catch (error) {
        handleError(error);
      }
    },
    [
      updatePatientProcedureFeeMutateAsync,
      updatePreAuthEstimates,
      onSaved,
      practiceId,
      patientProcedure.id,
      patientProcedureFeeDraft,
      canEditPreAuth,
    ]
  );

  const handleResetPrimaryEstimates = useCallback(
    async (options: { onSuccess: Func }) => {
      try {
        const resetResponse = await resetPrimaryEstimatesMutateAsync({
          practiceId,
          patientProcedureId: patientProcedure.id,
        });

        let updatedProcedure = resetResponse.data.data;

        if (canEditPreAuth) {
          // https://grindfoundry.atlassian.net/browse/GF-7843
          // We have to update the pre-auth estimates with the primary estimates
          // when doing a reset, in order to match the same behavior of primary
          // estimates being saved on reset; this is a temporary solution until
          // BE supports running a dry re-calculation of estimates without
          // saving it to the procedure
          const preAuthResponse = await updatePreAuthEstimates(updatedProcedure);

          updatedProcedure = preAuthResponse.data.data;
        }

        setPatientProcedureFeeDraft(getUpdatePatientProcedureFeeDraft(updatedProcedure));
        onSaved();
        options.onSuccess();
      } catch (error) {
        handleError(error);
      }
    },
    [
      resetPrimaryEstimatesMutateAsync,
      updatePreAuthEstimates,
      onSaved,
      practiceId,
      patientProcedure.id,
      canEditPreAuth,
    ]
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const result = flushSync(() => validate());

      if (result.$isValid) {
        updatePatientProcedureFeeMutate(
          {
            practiceId,
            patientProcedureId: patientProcedure.id,
            data: getUpdatePatientProcedureFeeRequest(patientProcedureFeeDraft),
          },
          {
            onSuccess: () => {
              onSaved();
              onClose();
            },
            onError: handleError,
          }
        );
      } else {
        scrollToFirstError(formContainerRef.current);
      }
    },
    [
      validate,
      updatePatientProcedureFeeMutate,
      onSaved,
      onClose,
      practiceId,
      patientProcedure.id,
      patientProcedureFeeDraft,
    ]
  );

  return (
    <FlyoverForm onSubmit={handleSubmit}>
      <FlyoverContent containerRef={formContainerRef} paddingClassName="flex flex-col gap-y-6 p-6">
        <EditEstimatesSummary
          patientProcedure={patientProcedure}
          patientProcedureFeeDraft={patientProcedureFeeDraft}
          onUpdateFeeDraft={setPatientProcedureFeeDraft}
          canEditUcrFee={canEditUcrFee}
        />

        <Divider className="border-dashed" />

        <EditEstimatesPrimary
          patientProcedure={patientProcedure}
          patientProcedureFeeDraft={patientProcedureFeeDraft}
          dentalProcedures={dentalProcedures}
          onUpdateFeeDraft={setPatientProcedureFeeDraft}
          onResetPrimaryEstimates={handleResetPrimaryEstimates}
          onChangeDowngradedDentalProcedure={handleChangeDowngradedDentalProcedure}
          isResettingPrimaryEstimates={resetPrimaryEstimatesMutation.isLoading}
          isSaving={updatePatientProcedureFeeMutation.isLoading}
          validationResult={validationResult}
          canEditPreAuth={canEditPreAuth}
        />

        {canEditSecondary ? (
          <>
            <Divider className="border-dashed" />

            <EditEstimatesSecondary
              patientProcedureFeeDraft={patientProcedureFeeDraft}
              onUpdateFeeDraft={setPatientProcedureFeeDraft}
              validationResult={validationResult}
            />
          </>
        ) : null}
      </FlyoverContent>

      <FlyoverFooter>
        {canEdit ? (
          <>
            <Button className="min-w-button" onClick={onClose} theme="secondary" size="medium">
              Cancel
            </Button>

            <AsyncButton
              className="min-w-button"
              isLoading={updatePatientProcedureFeeMutation.isLoading}
              size="medium"
              type="submit"
            >
              Save
            </AsyncButton>
          </>
        ) : (
          <Button className="min-w-button" onClick={onClose} size="medium">
            Close
          </Button>
        )}
      </FlyoverFooter>
    </FlyoverForm>
  );
};
