import { FC } from "react";
import { Route } from "react-router-dom";
import { PerioChartRoute } from "components/Charting/Perio/PerioChartRoute";
import { ToothChartRoute } from "components/Charting/ToothChartRoute";
import { ActiveProviderProvider } from "components/Charting/ActiveProviderContext";

import { NotFound } from "components/NotFoundRoute";
import { PatientAppHistoryProvider } from "contexts/PatientLinksContext";
import { usePathParams } from "hooks/usePathParams";
import { PerioCompareRoute } from "components/Charting/Perio/PerioCompare/PerioCompareRoute";
import { SentryRoutes } from "components/UI/SentryRoutes";

export const ChartingRoute: FC = () => {
  const { patientId } = usePathParams("charting");

  return (
    <PatientAppHistoryProvider patientId={patientId} name="charting">
      <ActiveProviderProvider>
        <SentryRoutes>
          <Route path="perio" element={<PerioChartRoute />} />
          <Route path="perio/compare" element={<PerioCompareRoute />} />
          <Route index element={<ToothChartRoute />} />
          <Route path="*" element={<NotFound />} />
        </SentryRoutes>
      </ActiveProviderProvider>
    </PatientAppHistoryProvider>
  );
};
