import React from "react";
import { WorkingHourItemVO } from "@libs/api/generated-api";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { Form } from "@libs/components/UI/Form";
import { EditWorkingHours } from "components/WorkingHours/EditWorkingHours";

export interface WorkingHoursFormProps {
  workingHours: WorkingHourItemVO[];
  isSaving: boolean;
  onChange: (newHours: WorkingHourItemVO[]) => void;
  onAddDay: (dayOfWeek: WorkingHourItemVO["dayOfWeek"]) => void;
  onSave: (workingHours: WorkingHourItemVO[]) => void;
  onCancel: Func;
}
export const WorkingHoursForm: React.FC<WorkingHoursFormProps> = ({
  workingHours,
  isSaving,
  onSave,
  onCancel,
  onChange,
  onAddDay,
}) => {
  return (
    <Form
      onSubmit={(e) => {
        e.preventDefault();
        onSave(workingHours);
      }}
    >
      <EditWorkingHours workingHourItems={workingHours} onChange={onChange} onAddDay={onAddDay} />
      <div className="mt-3 mb-4">
        <Button theme="secondary" size="large" onClick={onCancel} type="button" className="w-24">
          Cancel
        </Button>
        <AsyncButton isLoading={isSaving} type="submit" theme="primary" size="large" className="w-24 ml-3">
          Save
        </AsyncButton>
      </div>
    </Form>
  );
};
