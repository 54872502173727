import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import trainingSessionsImage from "assets/images/onboarding-training-sessions.svg";
import { paths } from "utils/routing/paths";
import { MailToLink } from "components/UI/MailToLink";
import { SetupStep } from "components/Settings/OnboardingSettings/SetupStep";
import { DetailDisclosure } from "components/Settings/OnboardingSettings/DetailDisclosure";
import {
  SettingsHeroContainer,
  SettingsHeroContent,
  SettingsHeroDetails,
  SettingsHeroTitle,
} from "components/Settings/SettingsHeroLayout";
import { SettingsPanel } from "components/Settings/SettingsPanel";

export const OnboardingTrainingSessionsRoute: React.FC = () => {
  const sharedButtonProps = {
    size: "medium" as const,
  };

  return (
    <SettingsPanel title="Training Sessions">
      <SettingsHeroContent className="flex flex-col gap-y-6 max-w-xl mx-auto">
        <SettingsHeroContainer image={<img src={trainingSessionsImage} alt="Laptop with lock" />}>
          <SettingsHeroDetails>
            <SettingsHeroTitle>Training Sessions</SettingsHeroTitle>
            <p>
              Start your onboarding journey by booking in your training sessions below. If you have any
              problems don&apos;t hesitate to contact Archy Support.
            </p>
            <div>
              <MailToLink className="text-xs" email="support@archy.com">
                Contact Support
              </MailToLink>
            </div>
          </SettingsHeroDetails>
        </SettingsHeroContainer>
        <SetupStep
          title="1. Introduction to Archy Dental Software"
          description="Topics include logging in, saving bookmarks, managing employee profiles, navigating the software, scheduling new and existing patients, and utilizing the Help Center."
        >
          <DetailDisclosure icon="clock">1 Hour</DetailDisclosure>
          <DetailDisclosure icon="people">All Staff</DetailDisclosure>
          <ButtonInternalLink
            to={paths.trainingSessionsSchedule({ meetingId: "training1" })}
            {...sharedButtonProps}
          >
            Schedule
          </ButtonInternalLink>
        </SetupStep>
        <SetupStep
          title="2. Clinical and Patient Data Management in Archy"
          description="Topics include opening multiple tabs, managing medical history, handling images, understanding charting, managing cases, treatment planning, and accessing the Help Center."
        >
          <DetailDisclosure icon="clock">1 Hour</DetailDisclosure>
          <DetailDisclosure icon="people">Clinical Staff</DetailDisclosure>
          <ButtonInternalLink
            to={paths.trainingSessionsSchedule({ meetingId: "training2" })}
            {...sharedButtonProps}
          >
            Schedule
          </ButtonInternalLink>
        </SetupStep>
        <SetupStep
          title="3. Clinical and Patient Data Management in Archy"
          description="Topics include adding insurance information, treatment planning with insurance estimating, alternate treatment plans, and editing treatment plans."
        >
          <DetailDisclosure icon="clock">1 Hour</DetailDisclosure>
          <DetailDisclosure icon="people">Insurance and Treatment Planning Staff</DetailDisclosure>
          <ButtonInternalLink
            to={paths.trainingSessionsSchedule({ meetingId: "training3" })}
            {...sharedButtonProps}
          >
            Schedule
          </ButtonInternalLink>
        </SetupStep>
        <SetupStep
          title="4. Billing and Reporting in Archy"
          description="Topics include completing appointments, managing billing, handling payments, logging into a demo environment, and utilizing the Help Center effectively."
        >
          <DetailDisclosure icon="clock">1 Hour</DetailDisclosure>
          <DetailDisclosure icon="people">Billing and Financial Staff</DetailDisclosure>
          <ButtonInternalLink
            to={paths.trainingSessionsSchedule({ meetingId: "training4" })}
            {...sharedButtonProps}
          >
            Schedule
          </ButtonInternalLink>
        </SetupStep>
      </SettingsHeroContent>
    </SettingsPanel>
  );
};
