import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu, ButtonMenuProps } from "@libs/components/UI/ButtonMenu";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";

interface Props extends Pick<ButtonMenuProps, "placement"> {
  className?: string;
  iconClassName?: string;
  onChangeReadStatus: Func;
  isRead?: boolean;
  visibleOnGroupHover?: boolean;
}

export const MessagingMenu: React.FC<Props> = ({
  iconClassName,
  onChangeReadStatus,
  isRead = false,
  visibleOnGroupHover = false,
  placement = "right",
}) => {
  const menu = useBoolean(false);
  const readStatusLabel = `Mark ${isRead ? "Unread" : "Read"}`;

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      onRequestClose={menu.off}
      placement={placement}
      menuContent={
        <div className="w-40">
          <MenuOptionButton
            name={readStatusLabel}
            onClick={(e) => {
              e.stopPropagation();
              onChangeReadStatus();
              menu.off();
            }}
          >
            {readStatusLabel}
          </MenuOptionButton>
        </div>
      }
    >
      {(props) => (
        <ButtonIcon
          {...props}
          SvgIcon={MenuIcon}
          aria-label="Messaging Menu"
          iconClassName={cx(
            "w-8 h-8 rounded hover:bg-slate-100",
            menu.isOn && "bg-slate-100",
            !menu.isOn && visibleOnGroupHover && "invisible group-hover:visible group-hover:animate-fadeIn",
            iconClassName
          )}
          size="sm"
          onClick={(e) => {
            e.stopPropagation();
            props.onMouseDown();
          }}
        />
      )}
    </ButtonMenu>
  );
};
