import { getApiClient } from "@libs/api/clients";
import { DAY_IN_HOURS, HOUR_IN_MINUTES, MINUTE_IN_SECONDS } from "@libs/utils/date";
import { captureException } from "@sentry/react";
import { millisecondsToSeconds } from "date-fns";
import { useCallback, useEffect, useMemo, useState } from "react";
import { LoaderFunctionArgs, redirect, useNavigate } from "react-router-dom";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { Auth } from "aws-amplify";
import type { AmplifyUser } from "@aws-amplify/ui";
import { getTokensForApi, getTokensForAuthCheck } from "@libs/auth/getTokens";

import { parseQueryParams } from "@libs/router/url";
import { useCurriedLoaderData } from "@libs/router/hooks";
import { headers } from "utils/headers";
import { getRedirectToSignedInUrl, paths, routesConfig } from "utils/routing/paths";
import { useQueryParams } from "hooks/useQueryParams";
import { SelectAccountPayload, useAuthChannelListeners } from "hooks/useAuthChannelListeners";
import { PracticeApiClientProvider } from "contexts/PracticeApiClientProvider";
import { SessionIdleTimer } from "components/Main/SessionIdleTimer";
import { MfaSetupPage } from "components/MfaSetup/MfaSetupPage";
import { PracticeRouterContext } from "router/types";
import { getPracticeActivityStorage } from "storage/activity";

export const loader =
  ({ baseUrl, storage }: PracticeRouterContext) =>
  async ({ request }: LoaderFunctionArgs) => {
    const url = new URL(request.url);
    const { parsed: query } = parseQueryParams(routesConfig.mfaSetup.query, url.searchParams);
    const tokens = await getTokensForAuthCheck(storage.localStorage);

    if (!tokens.identity) {
      return redirect(paths.signIn());
    }

    const activityStorage = getPracticeActivityStorage(storage.localStorage);

    if (!activityStorage.isRecentlyActive()) {
      return redirect(paths.signOut());
    }

    const apiClient = getApiClient({
      baseUrl,
      headers,
      onRequestTokens: () => getTokensForApi(storage.localStorage),
    });

    try {
      const response = await apiClient.user.getUserMfa();
      const mfa = response.data.data;

      if (!mfa.mfaEnforcedAt || mfa.mfaEnabled) {
        return redirect(
          paths.selectAccount({
            rememberMe: query.rememberMe,
            lastUserId: query.lastUserId,
            returnUrl: query.returnUrl,
          })
        );
      }

      // We start enforcing 24 hours before the actual enforcement (72 hours after midnight from the day it was turned on)
      const enforcedAtWithGracePeriod =
        mfa.mfaEnforcedAt - DAY_IN_HOURS * HOUR_IN_MINUTES * MINUTE_IN_SECONDS;

      return {
        mfaSetupRequired: millisecondsToSeconds(Date.now()) > enforcedAtWithGracePeriod,
      };
    } catch (e) {
      captureException(e);

      return redirect(paths.signOut({ signOutReason: "GET_USER_MFA_ERROR" }));
    }
  };

export const MfaSetupRoute = () => {
  const { mfaSetupRequired } = useCurriedLoaderData<typeof loader>();
  const navigate = useNavigate();
  const [user, setUser] = useState<AmplifyUser | null>(null);
  const { query } = useQueryParams("mfaSetup");

  const next = useCallback(() => {
    navigate(
      paths.selectAccount({
        returnUrl: query.returnUrl,
        rememberMe: query.rememberMe,
        lastUserId: query.lastUserId,
      })
    );
  }, [navigate, query]);

  const authChannelEvents = useMemo(
    () => ({
      onSelectAccount: (event: SelectAccountPayload) => {
        window.location.assign(getRedirectToSignedInUrl(event.userId, query.lastUserId, query.returnUrl));
      },
      onSignOut: () => {
        window.location.assign(paths.signIn({ returnUrl: query.returnUrl, lastUserId: query.lastUserId }));
      },
    }),
    [query.lastUserId, query.returnUrl]
  );

  useAuthChannelListeners(authChannelEvents);

  usePageTitle("Setup MFA");

  useEffect(() => {
    Auth.currentAuthenticatedUser().then((amplifyUser: AmplifyUser) => {
      setUser(amplifyUser);
    });
  }, []);

  return (
    <PracticeApiClientProvider>
      <MfaSetupPage onNext={next} user={user} mfaSetupRequired={mfaSetupRequired} />
      <SessionIdleTimer />
    </PracticeApiClientProvider>
  );
};
