import { FC } from "react";
import { LedgerV2ClaimSubEntryVO } from "@libs/api/generated-api";
import { Pill } from "@libs/components/UI/Pill";
import {
  colorsByClaimState,
  paymentStatusToPillText,
  stateToPillText,
  themesByClaimInsuranceStatus,
} from "components/Claims/utils";

export const ClaimStateAndPaymentStatusPill: FC<{
  claimSubEntry: LedgerV2ClaimSubEntryVO;
}> = ({ claimSubEntry }) => {
  let color;
  let text;

  if (claimSubEntry.claimState === "COMPLETED") {
    color = themesByClaimInsuranceStatus[claimSubEntry.paymentStatus];
    text = paymentStatusToPillText[claimSubEntry.paymentStatus];
  } else {
    const isInDraftEobPayment = Boolean(claimSubEntry.draftEobPaymentUuid);

    color = colorsByClaimState[isInDraftEobPayment ? "EOB_IN_PROGRESS" : claimSubEntry.claimState];
    text = stateToPillText[isInDraftEobPayment ? "EOB_IN_PROGRESS" : claimSubEntry.claimState];
  }

  return <Pill theme={color}>{text}</Pill>;
};
