import { isSameDay } from "date-fns";
import { useCallback } from "react";
import { getLocalDate } from "@libs/utils/date";
import { DashboardAdjustmentsQuery } from "utils/routing/dashboard";

export const useFocusDateClick = ({
  handleParamsChange,
  focusDate,
}: {
  handleParamsChange: (updates: Partial<DashboardAdjustmentsQuery>) => void;
  focusDate?: Date;
}) => {
  return useCallback(
    ({ date }: { date: string; index: number }) =>
      handleParamsChange({
        focusDate: focusDate && isSameDay(getLocalDate(date), focusDate) ? undefined : getLocalDate(date),
      }),
    [handleParamsChange, focusDate]
  );
};
