import { FC, Dispatch, SetStateAction, useMemo } from "react";
import { produce } from "immer";

import { PatientProcedureVO } from "@libs/api/generated-api";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { formatISODate } from "@libs/utils/date";
import { centsToDollarsString } from "@libs/utils/currency";
import { cx } from "@libs/utils/cx";

import { TooltipLabel } from "components/UI/TooltipLabel";
import { FormFieldCurrencyInput } from "components/UI/FormFieldCurrencyInput";

import { UpdatePatientProcedureFeeDraft } from "components/Charting/patientProcedureUtils";

interface Props {
  patientProcedure: PatientProcedureVO;
  patientProcedureFeeDraft: UpdatePatientProcedureFeeDraft;
  onUpdateFeeDraft: Dispatch<SetStateAction<UpdatePatientProcedureFeeDraft>>;
  canEditUcrFee: boolean;
}

export const EditEstimatesSummary: FC<Props> = ({
  patientProcedure,
  patientProcedureFeeDraft,
  onUpdateFeeDraft,
  canEditUcrFee,
}) => {
  const { feeLastOverrideBy, feeLastOverrideAt, negotiatedRate, finalPatientAmount } = patientProcedure;

  const estimatedBy = useMemo(() => {
    return feeLastOverrideBy && feeLastOverrideAt
      ? `${feeLastOverrideBy.fullDisplayName} on ${formatISODate(feeLastOverrideAt)}`
      : "-";
  }, [feeLastOverrideBy, feeLastOverrideAt]);

  return (
    <div className="flex flex-col gap-y-6">
      <span className="font-sansSemiBold text-sm">Estimates</span>

      <div className="grid grid-cols-2 gap-6">
        <FormFieldInput
          label={
            <div className="pb-2.5">
              <TooltipLabel
                tooltip={{
                  content:
                    "Archy payment estimates for insurance and patient responsibilities are initially calculated based on the information entered for the insurance plan and fee schedule associated with this patient.",
                }}
              >
                Estimate By
              </TooltipLabel>
            </div>
          }
          value={estimatedBy}
          edit={false}
        />

        <FormFieldCurrencyInput
          label={<div className={cx(!canEditUcrFee && "pb-2.5")}>UCR Fee</div>}
          value={patientProcedureFeeDraft.ucrRate}
          onValueChange={(value) =>
            onUpdateFeeDraft((last) =>
              produce(last, (draft) => {
                draft.ucrRate = value ?? "";
              })
            )
          }
          edit={canEditUcrFee}
        />

        <FormFieldCurrencyInput
          label={<div className="pb-2.5">Negotiated Rate</div>}
          value={centsToDollarsString(negotiatedRate)}
          edit={false}
        />

        <FormFieldCurrencyInput
          label={
            <div className="pb-2.5">
              <TooltipLabel tooltip={{ content: "Represents the total amount the patient owes." }}>
                Patient Amount
              </TooltipLabel>
            </div>
          }
          value={centsToDollarsString(finalPatientAmount)}
          edit={false}
        />
      </div>
    </div>
  );
};
