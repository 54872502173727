/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { NavLink } from "react-router-dom";
import { cxTableStyles } from "components/Dashboard/Tables/cxTableStyles";
import { TabText } from "components/UI/TabText";
import { DashboardQuery } from "utils/routing/dashboard";
import { DashboardTableRightControls } from "components/Dashboard/Tables/DashboardTableRightControls";

interface Props {
  tabs: {
    text: string;
    to: string;
  }[];
  children?: React.ReactNode;
  onClickDownload?: Func;
  isDownloading?: boolean;
  onClickPrint?: Func;
  isPrinting?: boolean;
  canDownload?: boolean;
  includePatientSearch?: boolean;
  downloadCsvDisabled?: boolean;
  query: DashboardQuery;
  id: string;
  onUpdateParams: (updates: Partial<DashboardQuery>) => void;
}
export const DashboardTableTabs = ({ tabs, ...rest }: Props) => {
  return (
    <div className={cxTableStyles.tableHeading(true)}>
      <div className={cxTableStyles.tableTabsContainer}>
        {tabs.map((tab) => (
          <NavLink
            key={tab.text}
            end
            className={({ isActive }) => (isActive ? "text-primaryTheme" : undefined)}
            to={tab.to}
          >
            <TabText isSelected={tab.to.split("?")[0] === location.pathname}>{tab.text}</TabText>
          </NavLink>
        ))}
      </div>
      <DashboardTableRightControls {...rest} />
    </div>
  );
};
