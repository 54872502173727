import { FC, FormEvent, useCallback, useMemo } from "react";
import {
  CreatePatientProcedureRequest,
  DentalProcedureVO,
  PatientProcedureVO,
  PatientToothVO,
  ProviderVO,
} from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { isOneOf } from "@libs/utils/isOneOf";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { ModalForm, ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import {
  DraftPatientProcedureRequest,
  draftToSavePatientProcedure,
} from "components/Charting/draftPatientProcedure";
import { getPatientProcedureSchema } from "components/Charting/patientProcedureSchema";
import { AddProcedureForm } from "components/Charting/AddProcedureForm";
import { AddProcedureFormHeaderContentWrapper } from "components/Charting/AddProcedureFormHeader";
import { ProcedureSelector } from "components/ProcedureSelector/ProcedureSelector";

interface Props {
  onCancel: Func;
  onSubmit: (draft: CreatePatientProcedureRequest) => void;
  onSwitchProcedure: (params?: {
    newDentalProcedure?: DentalProcedureVO;
    currentDraft?: DraftPatientProcedureRequest;
  }) => void;
  onUpdateDraft: (updates: Partial<DraftPatientProcedureRequest>) => void;
  dentalProcedure: DentalProcedureVO;
  allDentalProcedures: DentalProcedureVO[];
  patientProcedure: PatientProcedureVO;
  draftPatientProcedure: DraftPatientProcedureRequest;
  validateOptions?: {
    eager?: boolean;
    prosthetic?: boolean;
    provider?: boolean;
  };
  providers: ProviderVO[];
  teeth: PatientToothVO[];
  isSaving: boolean;
  editStatus?: boolean;
}

export const EditPatientProcedureFormDPSwitch: FC<Props> = ({
  onSubmit,
  onSwitchProcedure,
  onUpdateDraft,
  onCancel,
  isSaving,
  dentalProcedure,
  allDentalProcedures,
  patientProcedure,
  draftPatientProcedure,
  validateOptions,
  providers,
  teeth,
  editStatus,
}) => {
  const schema = useMemo(() => {
    return getPatientProcedureSchema(dentalProcedure, draftPatientProcedure, teeth, validateOptions);
  }, [draftPatientProcedure, dentalProcedure, teeth, validateOptions]);

  // validate right away when switching dental procedures
  const { validate, result } = useValidation(draftPatientProcedure, schema, {
    isValidating: true,
  });

  const handleFormSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!validate().$isValid) {
        return;
      }

      const switchRequest = {
        ...draftToSavePatientProcedure(draftPatientProcedure, dentalProcedure, teeth),
        // when switching dental procedure of a patient procedure, the backend cannot create a new
        // patient procedure of status DONE or SCHEDULED so we put PLANNED.
        // Once the patient procedure gets created, the backend will take the exisitng status and re-assign it properly.
        status: isOneOf(patientProcedure.status, ["DONE", "SCHEDULED"])
          ? "PLANNED"
          : draftPatientProcedure.treatmentType,
        dentalProcedureId: dentalProcedure.id,
      };

      onSubmit(switchRequest);
    },
    [validate, onSubmit, draftPatientProcedure, dentalProcedure, patientProcedure.status, teeth]
  );

  return (
    <ModalForm fieldLayout="labelIn" onSubmit={handleFormSubmit}>
      <ModalContent>
        <AddProcedureForm
          patientProcedure={patientProcedure}
          dentalProcedure={dentalProcedure}
          isExpanded={true}
          teeth={teeth}
          draft={draftPatientProcedure}
          validation={result}
          providers={providers}
          editStatus={editStatus}
          validateOptions={validateOptions}
          onUpdateDraft={onUpdateDraft}
          headerContent={
            <AddProcedureFormHeaderContentWrapper>
              <ProcedureSelector
                className="min-w-0 w-full h-9"
                mode="single"
                procedures={allDentalProcedures}
                value={dentalProcedure}
                required={true}
                aria-label="CDT Code"
                onChange={(val) =>
                  onSwitchProcedure({ newDentalProcedure: val, currentDraft: draftPatientProcedure })
                }
              />
              <Button
                className="text-xs"
                theme="link"
                onClick={() => {
                  onSwitchProcedure();
                }}
              >
                Cancel
              </Button>
            </AddProcedureFormHeaderContentWrapper>
          }
        />
      </ModalContent>
      <ModalFooter>
        <Button className="min-w-button" theme="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <AsyncButton className="min-w-button" isLoading={isSaving} type="submit">
          Save
        </AsyncButton>
      </ModalFooter>
    </ModalForm>
  );
};
