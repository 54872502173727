import { FC, ReactNode } from "react";
import { AlertSettingsVO, UpdateAlertSettingsRequest } from "@libs/api/generated-api";
import { AlertSection } from "components/Settings/Notes/AlertSection";
import { AlertCheckboxList } from "components/Settings/Notes/AlertCheckboxList";
import { AlertsSettingsEditor } from "components/Settings/AlertSettingsEditor";

interface Props {
  updateInProgress: ReactNode;
  alertSettings: AlertSettingsVO;
  isSaving: boolean;
  isSaved: boolean;
  onSave: (updates: UpdateAlertSettingsRequest) => void;
}

export const AppointmentTagsPage: FC<Props> = (props) => {
  return (
    <AlertsSettingsEditor {...props}>
      {({ alertSettingsState, updateAlertSettingsState }) => {
        const { enabledAppointmentAutoTagIds } = alertSettingsState;

        return (
          <AlertSection
            title="Auto Tags"
            description="Select the tags you want Archy to automatically attach to appointments. Tags on appointments allow you to quickly see important information on appointment cards and in the huddle."
          >
            <AlertCheckboxList
              onUpdate={(newSet) => updateAlertSettingsState({ enabledAppointmentAutoTagIds: newSet })}
              selectedTags={enabledAppointmentAutoTagIds}
              tagOptions={props.alertSettings.appointmentAutoTags}
            />
          </AlertSection>
        );
      }}
    </AlertsSettingsEditor>
  );
};
