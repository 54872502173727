import { FC } from "react";
import { EmployeeConsolidationVO, MergeEmployeeVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as MergeIcon } from "@libs/assets/icons/merge.svg";
import { ReactComponent as CheckmarkIcon } from "@libs/assets/icons/check.svg";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { LayoutCard } from "@libs/components/UI/LayoutCard";
import {
  TableGrid,
  ActionsRow,
  HeaderCell,
  Row,
  CheckboxCell,
  CheckboxCellProps,
  TextCell,
} from "@libs/components/UI/GridTableComponents";
import EmptyEmployees from "assets/images/empty-people-list.svg";

import { MERGE_SELECTION_SIZE } from "./EmployeeConsolidationModal";
import { formatEmployeeName } from "./utils";

const TASK_START_INDEX = 2;

const cxStyles = {
  headerCell: (index: number) => cx(index > TASK_START_INDEX && "justify-center"),
  row: (merged: boolean) =>
    cx("*:last:border-none", merged && "*:animate-[pulse_1.2s_ease-in-out_forwards] *:bg-greenLight"),
  textCell: (disabled?: boolean) => cx("flex items-center", disabled && "text-greyLight"),
  taskCell: "flex items-center justify-center",
  taskDot: "rounded-full w-2 h-2",
};

const consolidationTableHeader = [
  {
    id: "merge-checkbox",
    text: "",
    width: "3.25rem",
  },
  {
    id: "employee-name",
    text: "Name",
    width: "minmax(max-content, 1fr)",
  },
  {
    id: "job-title",
    text: "Job Title",
    width: "minmax(max-content, 12rem)",
  },
  {
    id: "archy-status",
    text: "Archy",
    width: "4rem",
  },
  {
    id: "gusto-status",
    text: "Gusto",
    width: "4rem",
  },
  {
    id: "task",
    text: "Task",
    width: "4rem",
  },
];

export const EmployeeConsolidationTable: FC<{
  employeeType: keyof EmployeeConsolidationVO;
  employees: MergeEmployeeVO[];
  selectedEmployeeIndexes: Set<number>;
  mergedEmployeeIndexes: Set<number>;
  disableMerge: boolean;
  onMergeClick: Func;
  onEmployeeSelect: CheckboxCellProps["onChange"];
}> = ({
  employeeType,
  employees,
  selectedEmployeeIndexes,
  mergedEmployeeIndexes,
  disableMerge,
  onMergeClick,
  onEmployeeSelect,
}) => {
  return (
    <>
      <p className="font-sansSemiBold mb-2">
        {employeeType === "active" ? "Step 1: Review Active Employees" : "Step 2: Review Archived Employees"}
      </p>
      <p className="mb-5">
        {employees.length
          ? `We've found these ${employeeType} employees. Some are listed on both Archy and Gusto sides, while
          others appear on one side only. Please make sure you merge any duplicate employee profiles so
          they're synced.`
          : `We didn't find any ${employeeType} employees. Please continue to complete onboarding with Gusto.`}
      </p>

      <LayoutCard className="font-sans text-xs text-greyDark">
        <TableGrid columnWidths={consolidationTableHeader.map(({ width }) => width)}>
          <div className="col-span-full sticky -top-5 z-10">
            <ActionsRow contentClassName="justify-between flex items-center" themeClassName="rounded-t">
              <Button
                className="flex items-center gap-x-2 font-sans"
                onClick={onMergeClick}
                disabled={disableMerge}
                theme="link"
              >
                <MergeIcon className="w-5 h-5" />
                Merge employees
              </Button>

              <div className="flex items-center gap-x-5">
                <OnboardLabel platform="Archy" employeeType={employeeType} />
                <OnboardLabel platform="Gusto" employeeType={employeeType} />
              </div>
            </ActionsRow>

            <TableGrid columnWidths={consolidationTableHeader.map(({ width }) => width)}>
              {consolidationTableHeader.map(({ id, text }, index) => (
                <HeaderCell key={id} size="medium" className={cxStyles.headerCell(index)}>
                  {text}
                </HeaderCell>
              ))}
            </TableGrid>
          </div>

          {employees.length ? (
            employees.map((employee, index) => {
              const { jobTitle, archyId, gustoId } = employee;
              const name = formatEmployeeName(employee);
              const isActionable = !archyId || !gustoId;
              const hasMergeEmployeesSelected =
                selectedEmployeeIndexes.size === MERGE_SELECTION_SIZE && !selectedEmployeeIndexes.has(index);

              return (
                <Row
                  key={index}
                  className={cxStyles.row(mergedEmployeeIndexes.has(index))}
                  isSelected={selectedEmployeeIndexes.has(index) || mergedEmployeeIndexes.has(index)}
                >
                  {isActionable ? (
                    <CheckboxCell
                      className="justify-center"
                      value={index}
                      checked={selectedEmployeeIndexes.has(index)}
                      onChange={onEmployeeSelect}
                      disabled={hasMergeEmployeesSelected}
                    />
                  ) : (
                    <TextCell />
                  )}

                  <TextCell className={cxStyles.textCell(hasMergeEmployeesSelected)}>{name}</TextCell>
                  <TextCell className={cxStyles.textCell(hasMergeEmployeesSelected)}>{jobTitle}</TextCell>
                  <TextCell className={cxStyles.taskCell}>
                    <OnboardedIcon onboarded={Boolean(archyId)} />
                  </TextCell>
                  <TextCell className={cxStyles.taskCell}>
                    <OnboardedIcon onboarded={Boolean(gustoId)} />
                  </TextCell>
                  <TextCell className={cxStyles.taskCell}>
                    {isActionable && (
                      <div className={cx(cxStyles.taskDot, archyId ? "bg-orange" : "bg-primaryTheme")} />
                    )}
                  </TextCell>
                </Row>
              );
            })
          ) : (
            <div className="col-span-full flex flex-col items-center gap-y-5 py-10">
              <img alt="No Employees Found" className="w-52 h-52" src={EmptyEmployees} />
              <span className="text-sm">No Employees Found</span>
            </div>
          )}
        </TableGrid>
      </LayoutCard>
    </>
  );
};

const OnboardLabel: FC<{
  platform: "Archy" | "Gusto";
  employeeType: keyof EmployeeConsolidationVO;
}> = ({ platform, employeeType }) => {
  return (
    <div className="flex items-center gap-x-2">
      <div className={cx(platform === "Archy" ? "bg-primaryTheme" : "bg-orange", cxStyles.taskDot)} />
      {employeeType === "active" ? "Onboard" : "Migrate"} to {platform}
    </div>
  );
};

const OnboardedIcon: FC<{ onboarded: boolean }> = ({ onboarded }) => {
  return onboarded ? (
    <CheckmarkIcon className="w-5 h-5 text-green" />
  ) : (
    <CancelIcon className="w-5 h-5 text-greyLighter" />
  );
};
