import React from "react";
import { Route } from "react-router-dom";
import { SentryRoutes } from "components/UI/SentryRoutes";

import { CollectionAdjustmentsTableRoute } from "components/Dashboard/Adjustments/CollectionAdjustmentsTableRoute";
import { InsuranceAdjustmentsTableRoute } from "components/Dashboard/Adjustments/InsuranceAdjustmentsTableRoute";

const TABLE_ROUTES = [
  {
    path: "insurance",
    element: <InsuranceAdjustmentsTableRoute />,
  },
  {
    path: "collection",
    element: <CollectionAdjustmentsTableRoute />,
  },
];

export const AdjustmentTables: React.FC = () => {
  return (
    <div className="h-full w-full flex flex-col min-h-0">
      <SentryRoutes>
        {TABLE_ROUTES.map(({ element, path }) => {
          return <Route key={path} element={element} path={path} />;
        })}

        <Route path="*" element={<div className="text-center p-4">Table not found</div>} />
      </SentryRoutes>
    </div>
  );
};
