import { useCallback } from "react";
import { DentalProcedureVO, PatientToothVO, SurfaceRequirementsVO } from "@libs/api/generated-api";
import { DraftPatientProcedureRequest } from "components/Charting/draftPatientProcedure";
import { SurfaceSelector } from "components/Charting/SurfaceSelector";
import { ToothOptionsList } from "components/Charting/ToothOptionsList";
import { getSurfaceSelectionCount, SurfaceSelection } from "components/Charting/toothSurfaces";
import { SupernumeraryNonPresentForm } from "components/Charting/SupernumeraryNonPresentForm";
import { useIsToothDisabledByDentalProcedure } from "components/Charting/useIsToothDisabledByAreaSelection";
import { isAreaSelectionRequired } from "components/Charting/patientProcedureSchema";

interface Props {
  dentalProcedure: DentalProcedureVO;
  teeth: PatientToothVO[];
  toothSelections: Set<string>;
  surfaceSelections: SurfaceSelection;
  onUpdateDraft: (updates: Partial<DraftPatientProcedureRequest>) => void;
}

const getSurfaceSelectorType = (
  surfaceRequirement: SurfaceRequirementsVO,
  surfaceSelections: SurfaceSelection
): "single" | "multi" => {
  return surfaceRequirement.max === 1 &&
    surfaceRequirement.min === 1 &&
    // A user can select multiple surfaces and then switch the dental procedure
    // to one that requires only one surface. In this case we want the surface
    // selector to act like a checkbox until they have deselected enough
    // surfaces so there is only one surface selected. Then it can begin to act
    // like a radio input.
    getSurfaceSelectionCount(surfaceSelections) === 1
    ? "single"
    : "multi";
};

const getToothSelectorType = (areaSelection: DentalProcedureVO["areaSelection"]): "radio" | "checkbox" => {
  const areaOptions = areaSelection?.options || [];

  return areaOptions.includes("TOOTH") && !areaOptions.includes("TOOTH_RANGE") ? "radio" : "checkbox";
};

export const DentalProcedureToothOptions: React.FC<Props> = ({
  dentalProcedure,
  onUpdateDraft,
  toothSelections,
  teeth,
  surfaceSelections,
}) => {
  const handleUpdateSurface = useCallback(
    (newSelection: SurfaceSelection) => {
      onUpdateDraft({ surfaces: newSelection });
    },
    [onUpdateDraft]
  );
  const isToothDisabled = useIsToothDisabledByDentalProcedure(dentalProcedure);

  return (
    <>
      <div className="flex items-center gap-x-8">
        <div className="flex-none">
          <ToothOptionsList
            type={getToothSelectorType(dentalProcedure.areaSelection)}
            teeth={teeth}
            isToothDisabled={isToothDisabled}
            canDeselect={!isAreaSelectionRequired(dentalProcedure.areaSelection)}
            toothSelections={toothSelections}
            onUpdateDraft={onUpdateDraft}
          />
        </div>

        <div className="h-20">
          {dentalProcedure.areaSelection?.surface && toothSelections.size <= 1 ? (
            <SurfaceSelector
              selection={surfaceSelections}
              selectionType={getSurfaceSelectorType(dentalProcedure.areaSelection.surface, surfaceSelections)}
              onUpdate={handleUpdateSurface}
            />
          ) : (
            <div
              className={`
                text-xs
                text-center
                text-slate-500
                h-full
                flex
                items-center
                justify-center
              `}
            >
              No Surface Selection Required
            </div>
          )}
        </div>
      </div>
      <SupernumeraryNonPresentForm
        type={getToothSelectorType(dentalProcedure.areaSelection)}
        teeth={teeth}
        toothSelections={toothSelections}
        dentalProcedure={dentalProcedure}
        onUpdateDraft={onUpdateDraft}
      />
    </>
  );
};
