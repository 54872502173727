import { ComponentProps, FC, ReactNode } from "react";
import { PatientProcedureVO } from "@libs/api/generated-api";
import designConfig from "@libs/design.config";
import { cx } from "@libs/utils/cx";
import { formatISODate } from "@libs/utils/date";
import { formatCurrency } from "@libs/utils/currency";
import { isOneOf } from "@libs/utils/isOneOf";
import { formatFullNameToInitials } from "@libs/utils/formatString";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon } from "@libs/components/UI/Icon";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as SendFileIcon } from "@libs/assets/icons/send-file.svg";
import { ReactComponent as AccountingIcon } from "@libs/assets/icons/accounting.svg";
import { ReactComponent as LabCaseIcon } from "@libs/assets/icons/lab-case.svg";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { ReactComponent as DeductibleIcon } from "@libs/assets/icons/deductible.svg";
import { ReactComponent as MeatBalls } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import {
  ButtonCell,
  Cell,
  CheckboxCell,
  CheckboxCellProps,
  cxGridTableStyles,
  EMPTY_CELL,
  LinkCell,
  TextCell,
} from "@libs/components/UI/GridTableComponents";
import { Form } from "@libs/components/UI/Form";
import { Select } from "@libs/components/UI/Select";
import { createSelectStyles } from "@libs/components/UI/selectStyles";
import { PreAuthInfo } from "components/Charting/PreAuthLabel";
import { tablePriorityOptions } from "components/Charting/patientProcedureFieldOptions";

import { paths } from "utils/routing/paths";
import { ExpiredCDTCodeIcon } from "components/Charting/ExpiredCDTCodeIcon";
import { isUnscheduledAsapAppt } from "components/ScheduleAppointments/utils";
import { LabCaseStatusIcon, LabCaseStatusMenuButton } from "components/LabCases/LabComponents";
import { RoleGuardHide } from "components/Main/RoleGuard";

interface ProcedureCellProps {
  procedure: PatientProcedureVO;
}

interface ProcedureTextOnlyCellProps extends ProcedureCellProps {
  onClick: (patientProcedure: PatientProcedureVO) => void;
}

const cxStyles = {
  subtotalCell: "font-sansSemiBold text-xs truncate",
};

export const procedureStatusLabelMap: Record<PatientProcedureVO["status"], string> = {
  DONE: "Completed",
  SCHEDULED: "Scheduled",
  EXISTING_OTHER: "Existing Other",
  PLANNED: "Planned",
  EXISTING_CURRENT: "Existing Current",
  REFERRED: "Referred",
  REJECTED: "Rejected",
};

export const ProcedureCheckboxCell: FC<CheckboxCellProps> = (props) => (
  <CheckboxCell {...props} aria-label="Select Procedure Row" />
);

export const ApptDateCell: FC<ProcedureTextOnlyCellProps> = ({ procedure, onClick }) => {
  const isUnscheduled = isUnscheduledAsapAppt(procedure.date);
  const scheduledDate = procedure.date && !isUnscheduled ? formatISODate(procedure.date) : undefined;

  return isOneOf(procedure.status, ["SCHEDULED", "DONE"]) && scheduledDate ? (
    <LinkCell
      className="justify-between text-primaryTheme truncate"
      to={paths.schedule({ date: procedure.date })}
    >
      {scheduledDate}
    </LinkCell>
  ) : (
    <ButtonCell onClick={() => onClick(procedure)}>
      {scheduledDate && isOneOf(procedure.status, ["EXISTING_OTHER", "EXISTING_CURRENT"])
        ? scheduledDate
        : EMPTY_CELL}
    </ButtonCell>
  );
};

export const CreatedDateCell: FC<ProcedureTextOnlyCellProps> = ({ procedure, onClick }) => {
  return <ButtonCell onClick={() => onClick(procedure)}>{formatISODate(procedure.createdAt)}</ButtonCell>;
};

export const ToothSurfaceCell: FC<ProcedureTextOnlyCellProps> = ({ procedure, onClick }) => (
  <ButtonCell onClick={() => onClick(procedure)}>{procedure.procedureArea || EMPTY_CELL}</ButtonCell>
);

export const CDTCodeCell: FC<ProcedureTextOnlyCellProps & { now: Date }> = ({ now, procedure, onClick }) => {
  return (
    <ButtonCell className="flex items-center gap-x-1" onClick={() => onClick(procedure)}>
      {procedure.cdtCode}
      <ExpiredCDTCodeIcon now={now} endDate={procedure.dentalProcedure?.endDate} theme="info" />
    </ButtonCell>
  );
};

export const DescriptionCell: FC<ProcedureTextOnlyCellProps> = ({ procedure, onClick }) => (
  <ButtonCell onClick={() => onClick(procedure)}>{procedure.description}</ButtonCell>
);

export const ProviderCell: FC<ProcedureTextOnlyCellProps> = ({ procedure, onClick }) => {
  const practice = useCurrentPractice();
  const { provider, creditToPractice } = procedure;

  return (
    <ButtonCell onClick={() => onClick(procedure)}>
      {provider ? (
        <FloatingTooltip content={creditToPractice ? practice.name : provider.fullDisplayName} theme="SMALL">
          <span>
            {creditToPractice ? "—" : formatFullNameToInitials({ fullName: provider.fullDisplayName })}
          </span>
        </FloatingTooltip>
      ) : (
        EMPTY_CELL
      )}
    </ButtonCell>
  );
};

export const NegotiatedAmountCell: FC<ProcedureTextOnlyCellProps> = ({ procedure, onClick }) => (
  <ButtonCell onClick={() => onClick(procedure)}>
    {procedure.negotiatedRate ? formatCurrency(procedure.negotiatedRate) : EMPTY_CELL}
  </ButtonCell>
);

export const InsuranceAmountCell: FC<ProcedureTextOnlyCellProps> = ({ procedure, onClick }) => (
  <ButtonCell onClick={() => onClick(procedure)}>
    <div className="flex items-center gap-x-1.5">
      {procedure.finalInsuranceAmount ? formatCurrency(procedure.finalInsuranceAmount) : EMPTY_CELL}
      {procedure.feeCalcResult ? (
        <Icon
          SvgIcon={InfoIcon}
          tooltip={{ content: procedure.feeCalcResult, theme: "MEDIUM" }}
          theme="info"
          size="sm"
        />
      ) : null}
    </div>
  </ButtonCell>
);

export const PatientAmountCell: FC<ProcedureTextOnlyCellProps> = ({ procedure, onClick }) => (
  <ButtonCell onClick={() => onClick(procedure)}>
    {procedure.finalPatientAmount ? formatCurrency(procedure.finalPatientAmount) : EMPTY_CELL}
  </ButtonCell>
);

export const TreatmentTypeCell: FC<ProcedureTextOnlyCellProps> = ({ procedure, onClick }) => (
  <ButtonCell onClick={() => onClick(procedure)}>{procedureStatusLabelMap[procedure.status]}</ButtonCell>
);

// eslint-disable-next-line complexity
export const DeductibleStatusCell: FC<ProcedureTextOnlyCellProps> = ({ procedure, onClick }) => {
  const primaryDeductible = procedure.deductibleAmount === 0 ? undefined : procedure.deductibleAmount;
  const primaryPreAuthDeductible =
    procedure.primaryPreAuthDeductibleAmount && procedure.primaryPreAuthDeductibleAmount !== 0
      ? procedure.primaryPreAuthDeductibleAmount
      : undefined;
  const secondaryDeductible =
    procedure.secondaryDeductibleAmount && procedure.secondaryDeductibleAmount !== 0
      ? procedure.secondaryDeductibleAmount
      : undefined;

  return (
    <TextCell onClick={() => onClick(procedure)}>
      {primaryDeductible || primaryPreAuthDeductible || secondaryDeductible ? (
        <ButtonIcon
          SvgIcon={DeductibleIcon}
          theme="slate700"
          tooltip={{
            content: (
              <div>
                {primaryPreAuthDeductible ? (
                  <div>Prim Pre-Auth Deduc {formatCurrency(primaryPreAuthDeductible)}</div>
                ) : (
                  primaryDeductible && <div>Prim Deduc {formatCurrency(primaryDeductible)}</div>
                )}
                {secondaryDeductible && <div>Sec Deduc {formatCurrency(secondaryDeductible)}</div>}
              </div>
            ),
          }}
        />
      ) : null}
    </TextCell>
  );
};

export const PreAuthStatusCell: FC<ProcedureTextOnlyCellProps> = ({ procedure, onClick }) => {
  return (
    <ButtonCell onClick={() => onClick(procedure)}>
      <PreAuthInfo status={procedure.preAuthStatus} />
    </ButtonCell>
  );
};

export const LabCaseStatusCell: FC<
  ProcedureTextOnlyCellProps &
    Pick<ComponentProps<typeof LabCaseStatusMenuButton>, "onStatusChange" | "labCaseReturnReasons">
> = ({ procedure, onClick, ...labCaseStatusProps }) => {
  return procedure.labCaseStatus ? (
    <LabCaseStatusMenuButton
      className={cx(cxGridTableStyles.cell(), cxGridTableStyles.dataCell, cxGridTableStyles.cellPadding())}
      {...labCaseStatusProps}
    >
      <FloatingTooltip content={sentenceCaseConstant(procedure.labCaseStatus.status)} theme="SMALL">
        <div>
          <LabCaseStatusIcon size="md" status={procedure.labCaseStatus.status} />
        </div>
      </FloatingTooltip>
    </LabCaseStatusMenuButton>
  ) : (
    <ButtonCell onClick={() => onClick(procedure)}>
      {isOneOf(procedure.status, ["PLANNED", "SCHEDULED"]) ? (
        <AddIcon className="w-5 h-5 text-archyBlue-500" />
      ) : (
        EMPTY_CELL
      )}
    </ButtonCell>
  );
};

const priorityMenuStyles = createSelectStyles<number, SelectOption<number>>({
  valueContainer: () => ({
    paddingLeft: "12px",
    paddingTop: "12px",
    paddingBottom: "12px",
    color: designConfig.colors.primaryTheme,
  }),
  singleValue: () => ({
    lineHeight: "1.25rem",
  }),
  menu: () => ({
    top: "calc(100% - 7px)",
    width: "max-content",
  }),
  menuList: () => ({
    display: "flex",
  }),
  indicatorsContainer: () => ({
    display: "none",
  }),
});

export const PriorityCell: FC<
  {
    onPriorityChanged: (patientProcedure: PatientProcedureVO, value: number) => void;
  } & ProcedureTextOnlyCellProps
> = ({ onPriorityChanged, onClick, procedure }) =>
  isOneOf(procedure.status, ["REFERRED", "PLANNED", "SCHEDULED"]) ? (
    <Cell className={cxGridTableStyles.dataCell}>
      <Form
        data-testid="select-procedure-priority-form"
        className="h-full block"
        onSubmit={(e) => e.preventDefault()}
      >
        <Select
          aria-label="Select Procedure Priority"
          className="h-full"
          name="priority"
          options={tablePriorityOptions}
          value={procedure.priority ?? 0}
          onItemSelected={(newValue) => onPriorityChanged(procedure, newValue)}
          isSearchable={false}
          isClearable={false}
          styles={priorityMenuStyles}
        />
      </Form>
    </Cell>
  ) : (
    <ButtonCell onClick={() => onClick(procedure)}>{procedure.priority ?? "-"}</ButtonCell>
  );

export const TotalCell: FC<{
  className?: string;
  children?: ReactNode;
}> = ({ className, children }) => (
  <div
    className={cx(
      `sticky
       bottom-0
       z-10
       bg-white
       border-t
       border-greyLight
       border-b`,
      cxStyles.subtotalCell,
      cxGridTableStyles.cellPadding(),
      className
    )}
  >
    {children}
  </div>
);

export const ActionsCell: FC<{
  procedure: PatientProcedureVO;
  onSendPreAuthClick: (patientProcedure: PatientProcedureVO) => void;
  onEditProcedureClick: (patientProcedure: PatientProcedureVO) => void;
  onEditEstimatesClick: (patientProcedure: PatientProcedureVO) => void;
  onEditLabCaseClick?: (patientProcedure: PatientProcedureVO) => void;
}> = ({ procedure, onSendPreAuthClick, onEditProcedureClick, onEditEstimatesClick, onEditLabCaseClick }) => {
  const menu = useBoolean(false);
  const isSendPreAuthDisabled = !isOneOf(procedure.status, ["SCHEDULED", "PLANNED"]);

  return (
    <div
      className={cx(
        cxGridTableStyles.cell(),
        cxGridTableStyles.dataCell,
        cxGridTableStyles.cellPadding({ horizontal: "none" })
      )}
    >
      <ButtonMenu
        menuContent={
          <div className="text-xs">
            <MenuOptionButton
              className="flex gap-x-2"
              onClick={() => {
                menu.off();
                onEditProcedureClick(procedure);
              }}
            >
              <Icon SvgIcon={EditIcon} size="md" theme="slate700" />
              <span>Edit Procedure</span>
            </MenuOptionButton>
            <RoleGuardHide domain="CLAIMS" action="ACCESS_ALL">
              <MenuOptionButton
                disabled={!isOneOf(procedure.status, ["PLANNED", "SCHEDULED", "DONE"])}
                className="flex gap-x-2"
                onClick={() => {
                  menu.off();
                  onEditEstimatesClick(procedure);
                }}
              >
                <Icon SvgIcon={AccountingIcon} size="md" theme="slate700" />
                <span>Edit Estimates</span>
              </MenuOptionButton>
              <MenuOptionButton
                className="flex gap-x-2"
                disabled={isSendPreAuthDisabled}
                onClick={() => {
                  menu.off();
                  onSendPreAuthClick(procedure);
                }}
              >
                <Icon SvgIcon={SendFileIcon} size="md" theme="slate700" />
                <span>{procedure.preAuthStatus ? "Resend Pre-Auth" : "Send Pre-Auth"}</span>
              </MenuOptionButton>
            </RoleGuardHide>
            {onEditLabCaseClick && (
              <MenuOptionButton
                className="flex gap-x-2"
                onClick={() => {
                  menu.off();
                  onEditLabCaseClick(procedure);
                }}
              >
                <Icon SvgIcon={LabCaseIcon} size="md" theme="slate700" />
                <span>Edit Lab Case</span>
              </MenuOptionButton>
            )}
          </div>
        }
        isOpen={menu.isOn}
        onRequestClose={menu.off}
        onRequestOpen={menu.on}
      >
        {(props) => (
          <ButtonIcon
            {...props}
            SvgIcon={MeatBalls}
            tooltip={{
              theme: "SMALL",
              content: "Procedure Menu",
            }}
          />
        )}
      </ButtonMenu>
    </div>
  );
};

export const SubTotalCell: FC<{
  className?: string;
  children?: ReactNode;
}> = ({ className, children }) => (
  <div className={cx(cxStyles.subtotalCell, cxGridTableStyles.cellPadding(), className)}>{children}</div>
);

const CHECK_COLUMN_WIDTH = "min-content";
const PRIORITY_COLUMN_WIDTH = "min-content";
const CREATED_DATE_COLUMN_WIDTH = "min-content";
const DATE_COLUMN_WIDTH = "min-content";
const TOOTH_COLUMN_WIDTH = "min-content";
const CDT_COLUMN_WIDTH = "min-content";
const DESCRIPTION_COLUMN_WIDTH = "minmax(300px, auto)";
const PROVIDER_COLUMN_WIDTH = "min-content";
const PRICE_COLUMN_WIDTH = "min-content";
const TREATMENT_TYPE_COLUMN_WIDTH = "min-content";
const DEDUCTIONS_COLUMN_WIDTH = "min-content";
const PRE_AUTH_COLUMN_WIDTH = "min-content";
const LAB_CASE_STATUS_COLUMN_WIDTH = "min-content";
const EDIT_COLUMN_WIDTH = "min-content";

export const CheckColumn = { id: "checkbox", width: CHECK_COLUMN_WIDTH, text: "" };
export const DateColumn = {
  id: "date",
  width: DATE_COLUMN_WIDTH,
  text: "Date",
};
export const CreatedDateColumn = {
  id: "planned",
  width: CREATED_DATE_COLUMN_WIDTH,
  text: "Created",
};
export const ProcedureAreaColumn = {
  id: "procedureArea",
  width: TOOTH_COLUMN_WIDTH,
  text: "Tth/Surf",
};
export const CdtCodeColumn = { id: "cdtCode", width: CDT_COLUMN_WIDTH, text: "Code" };
export const DescriptionColumn = {
  id: "description",
  width: DESCRIPTION_COLUMN_WIDTH,
  text: "Procedure Description",
};
export const ProviderColumn = { id: "provider", width: PROVIDER_COLUMN_WIDTH, text: "Prov" };
export const NegFeesColumn = { id: "negFees", width: PRICE_COLUMN_WIDTH, text: "Neg Fees" };
export const InsAmtColumn = { id: "insAmt", width: PRICE_COLUMN_WIDTH, text: "Ins Amt" };
export const PtAmtColumn = { id: "ptAmt", width: PRICE_COLUMN_WIDTH, text: "Pt Amt" };
export const DecuctionsColumn = { id: "deductions", width: DEDUCTIONS_COLUMN_WIDTH, text: "" };
export const PreAuthColumn = { id: "preAuth", width: PRE_AUTH_COLUMN_WIDTH, text: "Pre-Auth" };
export const LabCaseStatusColumn = {
  id: "labCaseStatus",
  width: LAB_CASE_STATUS_COLUMN_WIDTH,
  text: "Lab Case",
};
export const TreatmentTypeColumn = {
  id: "treatmentType",
  width: TREATMENT_TYPE_COLUMN_WIDTH,
  text: "Tx Type",
};
export const EditColumn = { id: "edit", width: EDIT_COLUMN_WIDTH, text: "" };
export const PriorityColumn = {
  width: PRIORITY_COLUMN_WIDTH,
  text: "Priority",
  id: "priority",
};
