import { useMemo } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { getFamilyMembersWithPendingInvoiceEntries } from "api/billing/queries";
import { getFamilyMembersV2Query } from "api/patients/queries";

export const useFamilyMembersWithPendingInvoiceEntries = (patientId: number, practiceId: number) => {
  const [familyMembersWithPendingInvoiceEntriesQuery, familyMembersQuery] = useApiQueries([
    getFamilyMembersWithPendingInvoiceEntries({ args: { patientId, practiceId } }),
    getFamilyMembersV2Query({ args: { practiceId, patientId } }),
  ]);

  return useMemo(
    () =>
      familyMembersQuery.data?.linkedFamilyMembers.filter(
        (fm) => familyMembersWithPendingInvoiceEntriesQuery.data?.includes(fm.memberPatientId)
      ) ?? [],
    [familyMembersQuery.data?.linkedFamilyMembers, familyMembersWithPendingInvoiceEntriesQuery.data]
  );
};
