/* eslint-disable @typescript-eslint/no-magic-numbers */
import { MedicalImageVO } from "@libs/api/generated-api";
import { DEGREES_180, DEGREES_90, SquareDegree } from "@libs/utils/math";
import { isNullish } from "@libs/utils/types";
import {
  FOCUSED_CANVAS_SIZE,
  IMAGE_CELL_SIZE,
} from "components/PatientProfile/Imaging/PatientMountsList/mountLayouts";

// eslint-disable-next-line complexity
const collidesAtInflationValue = (
  image1: MedicalImageVO,
  image2: MedicalImageVO,
  inflationCoefficient: number
) => {
  if (
    isNullish(image1.x) ||
    isNullish(image1.y) ||
    isNullish(image2.x) ||
    isNullish(image2.y) ||
    isNullish(image1.w) ||
    isNullish(image1.h) ||
    isNullish(image2.w) ||
    isNullish(image2.h) ||
    image1.w === 0 ||
    image2.w === 0 ||
    image1.h === 0 ||
    image2.h === 0
  ) {
    throw new Error("Image coordinates not set");
  }

  return (
    image1.x < image2.x + image2.w * inflationCoefficient &&
    image1.x + image1.w * inflationCoefficient > image2.x &&
    image1.y < image2.y + image2.h * inflationCoefficient &&
    image1.h * inflationCoefficient + image1.y > image2.y
  );
};

const getImageWithTransformsAppliedToDimensions = (image: MedicalImageVO) => {
  const { transforms, h, w } = image;

  if (!transforms) {
    return image;
  }

  const rotationDegrees = (transforms.rotationDegrees || 0) as SquareDegree;

  const sizeInverted = Math.abs(rotationDegrees % DEGREES_180) === DEGREES_90;

  return sizeInverted
    ? {
        ...image,
        w: h,
        h: w,
      }
    : image;
};
const hasCollision = (images: MedicalImageVO[], inflationCoefficient: number) => {
  const imagesWithTransformsApplied = images.map(getImageWithTransformsAppliedToDimensions);

  for (const image1 of imagesWithTransformsApplied) {
    for (const image2 of imagesWithTransformsApplied) {
      if (image1 !== image2 && collidesAtInflationValue(image1, image2, inflationCoefficient)) {
        return true;
      }
    }
  }

  return false;
};
const MIN_INFLATION = 0.12;

export const inflateImageSizeWithNoCollisions = (images: MedicalImageVO[]): number => {
  let inflationCoefficient = 1;

  // The max area an item will take up, assuming there are no collisions
  const MAX_AREA = IMAGE_CELL_SIZE.w * IMAGE_CELL_SIZE.h * 3;
  const largestImageArea = images.reduce((prevMax, currentImage) => {
    const imageArea = (currentImage.w ?? 0) * (currentImage.h ?? 0);

    if (prevMax < imageArea) {
      return imageArea;
    }

    return prevMax;
  }, 0);

  if (images.length > 1) {
    while (hasCollision(images, inflationCoefficient) && MIN_INFLATION < inflationCoefficient) {
      inflationCoefficient -= 0.02;
    }
  } else {
    return (IMAGE_CELL_SIZE.w / FOCUSED_CANVAS_SIZE.w) * 3;
  }

  while (!hasCollision(images, inflationCoefficient) && largestImageArea * inflationCoefficient < MAX_AREA) {
    inflationCoefficient += 0.01;
  }

  return inflationCoefficient;
};
