/* eslint-disable @typescript-eslint/naming-convention */
import Skeleton from "react-loading-skeleton";
import useScript from "react-script-hook";
import { useEffect, useRef } from "react";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { useCurrentUser } from "contexts/CurrentUserContext";

type EventScheduledEvent = {
  event: "calendly.event_scheduled";
  payload: {
    event: {
      uri: string;
    };
    invitee: {
      uri: string;
    };
  };
};

// We listen for a post message on the window to know when a scheduling is complete
// more info here on the calendly post messages https://help.calendly.com/hc/en-us/articles/223147027-Embed-options-overview?tab=advanced
type CalendlyMessage =
  | EventScheduledEvent
  | {
      event: "calendly.date_and_time_selected";
      payload: object;
    }
  | {
      event: "calendly.page_height";
      payload: {
        height: string;
      };
    }
  | {
      event: "calendly.event_type_viewed";
      payload: object;
    }
  | {
      event: "calendly.profile_page_viewed";
      payload: object;
    };

const isCalendlyPostMessage = (e: MessageEvent<unknown>): e is MessageEvent<CalendlyMessage> => {
  const calendlyEvent = e as MessageEvent<{ event?: string; payload: unknown }>;

  return Boolean(
    calendlyEvent.origin === "https://calendly.com" &&
      calendlyEvent.data.event &&
      calendlyEvent.data.event.indexOf("calendly.") === 0
  );
};

/**
 * @component
 * @param calendlyLink - a calendly schedulinglink to load the calendly iframe
 * @param defaultAnswers - an array of default answers to prefill the calendly form with. It appears these only work up to the second answer in the calendly form
 * @param onEventScheduled - a callback function to call when a scheduling is complete
 */
export const CalendlyForm: React.FC<{
  calendlyLink: string;
  defaultAnswers?: string[];
  onEventScheduled?: (event: EventScheduledEvent["payload"]) => void;
}> = ({ calendlyLink, defaultAnswers, onEventScheduled }) => {
  const { name, email } = useCurrentUser();
  const practice = useCurrentPractice();

  const [loading, error] = useScript({
    src: "https://assets.calendly.com/assets/external/widget.js",
    checkForExisting: true,
  });

  const hasInitialized = useRef(false);

  const calendlyContainer = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const Calendly = window.Calendly;
    const parentElement = calendlyContainer.current;

    if (!loading && !error && Calendly && parentElement && !hasInitialized.current) {
      const customAnswers = defaultAnswers?.reduce(
        (prev, curr, index) => {
          prev[`a${index + 1}`] = curr;

          return prev;
        },
        {} as Record<string, string>
      );

      Calendly.initInlineWidget({
        url: `${calendlyLink}?hide_landing_page_details=1&hide_gdpr_banner=1`,
        parentElement,
        utm: {
          utmTerm: `${practice.id}`,
          utmContent: practice.name,
        },
        prefill: {
          name: name.fullDisplayName,
          email,
          customAnswers,
        },
      });
      hasInitialized.current = true;
    }
  }, [calendlyLink, defaultAnswers, email, error, loading, name.fullDisplayName, practice]);
  useEffect(() => {
    const onPostMessage = (e: MessageEvent<unknown>) => {
      if (isCalendlyPostMessage(e) && e.data.event === "calendly.event_scheduled") {
        onEventScheduled?.(e.data.payload);
      }
    };

    window.addEventListener("message", onPostMessage);

    return () => window.removeEventListener("message", onPostMessage);
  }, [onEventScheduled]);

  return loading ? (
    <Skeleton className="h-full max-w-3xl" containerClassName="h-full py-12 flex justify-center" />
  ) : error ? (
    <ErrorContent>Failed to load scheduling tool. Try again later</ErrorContent>
  ) : (
    <div className="h-full" ref={calendlyContainer} />
  );
};
