import { FC } from "react";

import { ProviderVO, PatientInsuranceResponse, FamilyMemberVO } from "@libs/api/generated-api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Pill } from "@libs/components/UI/Pill";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus-small.svg";
import { ReactComponent as CalendarIcon } from "@libs/assets/icons/calendar.svg";

import { PatientAppointmentInsurance } from "components/PatientBlurbInfo/PatientAppointmentInsurance";
import { PatientAppointmentGuardians } from "components/PatientBlurbInfo/PatientAppointmentGuardians";
import { PatientPrimaryProvider } from "components/PatientBlurbInfo/PatientPrimaryProvider";

interface Props {
  patientId: number;
  insurance: PatientInsuranceResponse | undefined;
  guardians: FamilyMemberVO[];
  primaryProvider: ProviderVO | undefined;
  isHipaaView?: boolean;
  hasNoAppointments: boolean;
  onShowAppointments: Func;
  onAddAppointment: Func;
}

export const NoUpcomingAppointment: FC<Props> = ({
  patientId,
  insurance,
  guardians,
  primaryProvider,
  isHipaaView = false,
  hasNoAppointments,
  onShowAppointments,
  onAddAppointment,
}) => {
  return (
    <div className="flex flex-col gap-y-3 text-xs">
      <div className="flex flex-col gap-y-1">
        <div className="flex items-center justify-between">
          <span className="font-sansSemiBold leading-6">Next Appointment</span>

          <ButtonIcon
            tooltip={{
              content: hasNoAppointments ? "No Appointments" : "View All Appointments",
              theme: "SMALL",
            }}
            SvgIcon={CalendarIcon}
            onClick={onShowAppointments}
            disabled={hasNoAppointments}
          />
        </div>

        <button type="button" className="w-fit" onClick={onAddAppointment}>
          <Pill className="flex items-center gap-x-1 text-xs" theme="grey">
            Add
            <Icon SvgIcon={PlusIcon} theme="slate700" />
          </Pill>
        </button>
      </div>

      {primaryProvider ? <PatientPrimaryProvider primaryProvider={primaryProvider} /> : null}

      <PatientAppointmentInsurance patientId={patientId} insurance={insurance} />

      {guardians.length > 0 ? (
        <PatientAppointmentGuardians guardians={guardians} isHipaaView={isHipaaView} />
      ) : null}
    </div>
  );
};
