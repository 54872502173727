import { useCallback } from "react";
import { DentalProcedureVO, FeeScheduleVO } from "@libs/api/generated-api";
import { toMap } from "@libs/utils/array";
import { formatCurrency } from "@libs/utils/currency";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { getDentalProceduresQuery } from "api/charting/queries";
import { getFeeScheduleDetails } from "api/feeSchedule/queries";
import { handleError } from "utils/handleError";
import { formatCsvRow, saveCsv } from "utils/csv";

const generateFeeScheduleCsv = ({
  feeScheduleDetails,
  procedures,
}: {
  feeScheduleDetails: FeeScheduleVO;
  procedures: DentalProcedureVO[];
}) => {
  feeScheduleDetails.dentalProcedureFees;

  const header = [formatCsvRow(["CDT Code", "Fee"])];
  const feesByProcedureId = toMap(feeScheduleDetails.dentalProcedureFees ?? [], "procedureId");

  const rows = procedures.map((procedure) => {
    const fee = feesByProcedureId[procedure.id]?.fee;

    return formatCsvRow([procedure.cdtCode, `${fee ? formatCurrency(fee) : ""}`]);
  });

  return [...header, ...rows];
};

export const useDownloadFeeScheduleCSV = ({ feeScheduleId }: { feeScheduleId: number }) => {
  const { practiceId } = useAccount();
  const downloading = useBoolean(false);
  const setDownloading = downloading.set;
  const [feeScheduleDetailsQuery, proceduresQuery] = useApiQueries([
    getFeeScheduleDetails({
      args: { practiceId, feeScheduleId },
      queryOptions: { enabled: false },
    }),
    getDentalProceduresQuery({ args: { practiceId }, queryOptions: { enabled: false } }),
  ]);

  const downloadFeeScheduleCSV = useCallback(async () => {
    setDownloading(true);

    try {
      const [feeScheduleResponse, proceduresResponse] = await Promise.all([
        feeScheduleDetailsQuery.refetch(),
        proceduresQuery.refetch(),
      ]);
      const feeScheduleDetails = feeScheduleResponse.data?.data.data;
      const procedures = proceduresResponse.data?.data.data;

      if (feeScheduleDetails && procedures) {
        const csvRows = generateFeeScheduleCsv({
          feeScheduleDetails,
          procedures,
        });

        saveCsv(csvRows, feeScheduleDetails.name);
      } else {
        const error = feeScheduleDetails
          ? new Error("Procedures not found")
          : new Error("Fee schedule not found");

        throw error;
      }
    } catch (e) {
      handleError(e);
    }

    setDownloading(false);

    return undefined;
  }, [feeScheduleDetailsQuery, proceduresQuery, setDownloading]);

  return { downloadFeeScheduleCSV, isDownloadingCSV: downloading.isOn };
};
