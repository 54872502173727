import { FC } from "react";
import { Link, matchPath, useLocation } from "react-router-dom";
import {
  LastWorktimeVOV2,
  PatientVO,
  PracticeCountersVO,
  PracticeInfoVO,
  ProfileImageVO,
} from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus.svg";
import { ReactComponent as ScheduleIcon } from "@libs/assets/icons/schedule.svg";
import { ReactComponent as DashboardIcon } from "@libs/assets/icons/chart-bar.svg";
import { ReactComponent as ShieldIcon } from "@libs/assets/icons/shield.svg";
import { ReactComponent as PeopleIcon } from "@libs/assets/icons/people.svg";
import { ReactComponent as MessageIcon } from "@libs/assets/icons/messages.svg";
import { ReactComponent as EmailIcon } from "@libs/assets/icons/email.svg";
import { ReactComponent as RxIcon } from "@libs/assets/icons/pharmacy.svg";
import { ReactComponent as LabCaseIcon } from "@libs/assets/icons/lab-case.svg";
import { SuccessPopup } from "components/Patient/SuccessPopup";
import { useMainLinks } from "components/Main/MainLinksContext";
import { routesConfig } from "utils/routing/paths";
import { useItemModal } from "hooks/useItemModal";
import { OfficeChatButton } from "components/OfficeChat/OfficeChatButton";
import { PracticeMenu } from "components/Main/PracticeMenu";
import { ProfileMenu } from "components/Main/ProfileMenu";
import { SearchPatientsModal, useSearchPatientsModal } from "components/Main/SearchPatientsModal";
import { CreatePatient } from "components/Patient/CreatePatient";
import { RoleGuardHide } from "components/Main/RoleGuard";
import { isMigrationRole } from "components/Roles/roleUtils";
import { CurrentUser } from "contexts/CurrentUserContext";
import { PracticeNavIcon } from "components/Main/PracticeNavIcon";

interface Props {
  user: CurrentUser;
  clockData: LastWorktimeVOV2 | undefined;
  profileImage?: ProfileImageVO;
  practice: PracticeInfoVO;
  practiceCounters?: PracticeCountersVO;
  canPrescribe?: boolean;
  erxNotificationCount?: number;
}

// eslint-disable-next-line complexity
export const Navigation: FC<Props> = ({
  user,
  profileImage,
  practice,
  clockData,
  canPrescribe,
  practiceCounters,
  erxNotificationCount,
}) => {
  const { pathname } = useLocation();
  const createPatient = useBoolean(false);
  const { searchPatientsModal, searchShortcut } = useSearchPatientsModal();
  const successModal = useItemModal<PatientVO[]>(null);

  const { links } = useMainLinks();

  // Archy Support users are currently de-activated in Sendbird as we don't want practices messaging them.
  const showOfficeChatButton = user.type !== "SUPPORT_USER" || user.name.shortDisplayName !== "Archy Support";
  const isMigrating = isMigrationRole(user.roleV2);

  return (
    <>
      <div className="flex flex-col justify-between h-full">
        <div>
          <PracticeMenu practiceLogo={practice.logo?.url} practiceName={practice.name} />
          <div className="mt-20 flex flex-col gap-y-2">
            <RoleGuardHide domain="PATIENT_PROFILE" action="ACCESS_ALL">
              <button type="button" onClick={createPatient.on}>
                <PracticeNavIcon
                  tooltip={{ content: "Create Patient", theme: "SMALL" }}
                  Icon={PlusIcon}
                  selected={createPatient.isOn}
                />
              </button>
            </RoleGuardHide>
            <RoleGuardHide domain="PATIENT_PROFILE" action="ACCESS_ALL">
              <button type="button" onClick={searchPatientsModal.on}>
                <PracticeNavIcon
                  tooltip={{ content: `Search Patients (${searchShortcut})`, theme: "SMALL" }}
                  Icon={SearchIcon}
                  selected={searchPatientsModal.isOn}
                />
              </button>
            </RoleGuardHide>
          </div>
          {!isMigrating && (
            <div className="mt-8 flex flex-col gap-y-2">
              <RoleGuardHide domain="APPOINTMENT_MANAGEMENT" action="ACCESS_ALL">
                <Link to={links.schedule}>
                  <PracticeNavIcon
                    tooltip={{ content: "Schedule", theme: "SMALL" }}
                    Icon={ScheduleIcon}
                    selected={Boolean(matchPath({ path: routesConfig.schedule.path, end: false }, pathname))}
                  />
                </Link>
              </RoleGuardHide>

              <RoleGuardHide domain="PATIENT_PROFILE" action="ACCESS_ALL">
                <Link to={links.patients}>
                  <PracticeNavIcon
                    tooltip={{ content: "Patients", theme: "SMALL" }}
                    Icon={PeopleIcon}
                    selected={Boolean(matchPath({ path: routesConfig.patients.path, end: false }, pathname))}
                  />
                </Link>
              </RoleGuardHide>

              <RoleGuardHide domain="COMMUNICATION" action="ACCESS_ALL">
                <Link to={links.messaging}>
                  <PracticeNavIcon
                    notificationCount={practiceCounters?.unreadMessages}
                    tooltip={{ content: "Messaging", theme: "SMALL" }}
                    Icon={MessageIcon}
                    selected={Boolean(matchPath(routesConfig.messaging.path, pathname))}
                  />
                </Link>
              </RoleGuardHide>

              <RoleGuardHide domain="COMMUNICATION" action="BULK_MESSAGE">
                <Link to={links.communications}>
                  <PracticeNavIcon
                    tooltip={{ content: "Communications", theme: "SMALL" }}
                    Icon={EmailIcon}
                    selected={Boolean(
                      matchPath({ path: routesConfig.communications.path, end: false }, pathname)
                    )}
                  />
                </Link>
              </RoleGuardHide>

              <RoleGuardHide domain="CLAIMS" action="ACCESS_ALL">
                <Link to={links.claims}>
                  <PracticeNavIcon
                    tooltip={{ content: "Claims", theme: "SMALL" }}
                    Icon={ShieldIcon}
                    selected={Boolean(matchPath({ path: routesConfig.claims.path, end: false }, pathname))}
                  />
                </Link>
              </RoleGuardHide>

              <RoleGuardHide domain="LAB_CASE" action="ACCESS_ALL">
                <Link to={links.labCases}>
                  <PracticeNavIcon
                    tooltip={{ content: "Lab Cases", theme: "SMALL" }}
                    Icon={LabCaseIcon}
                    selected={Boolean(matchPath(routesConfig.labCases.path, pathname))}
                  />
                </Link>
              </RoleGuardHide>

              <RoleGuardHide domain="REPORTING" action="ACCESS_ALL">
                <Link to={links.dashboard}>
                  <PracticeNavIcon
                    tooltip={{ content: "Reporting", theme: "SMALL" }}
                    Icon={DashboardIcon}
                    selected={Boolean(
                      matchPath({ path: routesConfig.dashboardTab.path, end: false }, pathname)
                    )}
                  />
                </Link>
              </RoleGuardHide>
            </div>
          )}
        </div>

        <div>
          <div className="mb-8 flex flex-col gap-y-2">
            {canPrescribe && (
              <Link to={links.erx}>
                <PracticeNavIcon
                  tooltip={{ content: "eRx", theme: "SMALL" }}
                  notificationCount={erxNotificationCount}
                  selected={Boolean(matchPath(routesConfig.erx.path, pathname))}
                  Icon={RxIcon}
                />
              </Link>
            )}

            {showOfficeChatButton && (
              <RoleGuardHide domain="CHAT" action="ACCESS_ALL">
                <OfficeChatButton />
              </RoleGuardHide>
            )}
          </div>

          <ProfileMenu
            clockData={clockData}
            isMigrationRole={isMigrating}
            name={user.name.shortDisplayName}
            profileImage={profileImage}
          />
        </div>
      </div>
      {createPatient.isOn && (
        <CreatePatient
          onCreated={(patients) => {
            successModal.open(patients);
            createPatient.off();
          }}
          onRequestClose={createPatient.off}
        />
      )}
      {successModal.isOpen && (
        <SuccessPopup patients={successModal.item} onRequestClose={successModal.close} />
      )}
      {searchPatientsModal.isOn && <SearchPatientsModal onRequestClose={searchPatientsModal.off} />}
    </>
  );
};
