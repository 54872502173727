import { FC } from "react";

import { SupportTokenResponse } from "@libs/api/generated-api";
import { AsyncButton } from "@libs/components/UI/AsyncButton";

import { Form } from "@libs/components/UI/Form";
import { PageWrapper, PageWrapperCard } from "components/SignIn/PageWrapper";
import { SupportPracticeSelect } from "components/Main/SupportPracticeSelect";
import { useSupportPracticeSelect } from "components/Main/useSupportPracticeSelect";

export const SupportSelectPracticePage: FC<{
  onPracticeSelected: (response: SupportTokenResponse) => void;
}> = ({ onPracticeSelected }) => {
  const { selectProps, handleIssueSupportToken, isLoadingPractice } = useSupportPracticeSelect({
    onPracticeSelected,
  });

  return (
    <PageWrapper theme="light">
      <PageWrapperCard size="sm">
        <Form className="flex flex-col gap-y-6 w-[21rem]" onSubmit={handleIssueSupportToken}>
          <SupportPracticeSelect label="Practice" {...selectProps} />
          <AsyncButton isLoading={isLoadingPractice} type="submit">
            Enter
          </AsyncButton>
        </Form>
      </PageWrapperCard>
    </PageWrapper>
  );
};
