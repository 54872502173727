import { FC, useCallback } from "react";

import { SupportTokenResponse } from "@libs/api/generated-api";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useStorageContext } from "@libs/contexts/StorageContext";

import { Modal } from "@libs/components/UI/Modal";
import { ModalForm, ModalContent } from "@libs/components/UI/ModalComponents";

import { SupportPracticeSelect } from "components/Main/SupportPracticeSelect";
import { handleSupportAccountSelected } from "components/Main/SelectAccountRoute";
import { useSupportPracticeSelect } from "components/Main/useSupportPracticeSelect";

import { useCurrentUser } from "contexts/CurrentUserContext";
import { signedInRedirect } from "utils/routing/paths";

export const SupportSelectPracticeModal: FC<{
  onRequestClose: Func;
}> = ({ onRequestClose }) => {
  const { localStorage } = useStorageContext();
  const { email } = useCurrentUser();

  const handlePracticeSelected = useCallback(
    (response: SupportTokenResponse) => {
      handleSupportAccountSelected(localStorage, email, response);
      window.location.assign(signedInRedirect);
    },
    [localStorage, email]
  );

  const { selectProps, handleIssueSupportToken, isLoadingPractice } = useSupportPracticeSelect({
    onPracticeSelected: handlePracticeSelected,
  });

  return (
    <Modal centerVertically={false} onClickOutside={onRequestClose} onClose={onRequestClose} size="3xs">
      <ModalForm onSubmit={handleIssueSupportToken}>
        <ModalContent className="flex flex-col items-center gap-y-6" padding="confirm">
          <h3 className="font-sansSemiBold text-lg">Switch Account</h3>
          <SupportPracticeSelect {...selectProps} />
          <div className="flex items-center gap-x-2">
            <Button className="min-w-button" onClick={onRequestClose} theme="secondary">
              Cancel
            </Button>
            <AsyncButton className="min-w-button" isLoading={isLoadingPractice} type="submit">
              Switch
            </AsyncButton>
          </div>
        </ModalContent>
      </ModalForm>
    </Modal>
  );
};
