import React, { useState } from "react";
import { Spinner } from "@libs/components/UI/Spinner";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Button } from "@libs/components/UI/Button";
import { useAttachmentsView } from "components/Claim/Attachments/AttachmentsViewContext";
import { useAttachments } from "components/Claim/Attachments/ClaimAttachmentsContext";
import { ClaimModalFooter } from "components/Claim/ModalFooter";

interface Props {
  onClose: () => void;
}

// eslint-disable-next-line complexity
export const ClaimAttachmentsFooter: React.FC<Props> = ({ onClose }) => {
  const { isInReview, setIsInReview } = useAttachmentsView();
  const { medicalImages, perioCharts, other, narrative, uploadAttachments, claim } = useAttachments();

  const [isUploading, setUploading] = useState(false);

  const canUpload =
    narrative != null ||
    (medicalImages?.length ?? 0) > 0 ||
    (perioCharts?.length ?? 0) > 0 ||
    (other?.length ?? 0) > 0 ||
    // Below condition is to allow complete removal of attachments.
    (claim?.attachments && claim.attachments.length > 0);

  const buttonDisabled = isUploading || !canUpload;

  const tooltip = isUploading ? "Uploading..." : canUpload ? undefined : "Add attachment";

  return (
    <ClaimModalFooter>
      <Button
        className="min-w-button"
        disabled={isUploading}
        onClick={() => {
          if (isInReview) {
            setIsInReview(false);
          } else {
            onClose();
          }
        }}
        theme="secondary"
      >
        {isInReview ? "Back" : "Cancel"}
      </Button>

      <FloatingTooltip content={tooltip} theme="SMALL">
        <div>
          <Button
            className="min-w-button"
            disabled={buttonDisabled}
            onClick={async () => {
              if (isInReview) {
                // Upload and Close
                try {
                  setUploading(true);
                  await uploadAttachments();
                  setUploading(false);
                  onClose();
                } catch {
                  setUploading(false);
                }
              } else {
                // Switch to review.
                setIsInReview(true);
              }
            }}
            theme="primary"
          >
            {isUploading ? (
              <Spinner size="xs" variant="secondary" animation="border" />
            ) : isInReview ? (
              "Upload"
            ) : (
              "Review"
            )}
          </Button>
        </div>
      </FloatingTooltip>
    </ClaimModalFooter>
  );
};
