import { PatientInsuranceResponse } from "@libs/api/generated-api";
import {
  CurrencyCell,
  SectionHeader,
  SectionRow,
  cxTableStyles,
} from "components/PatientProfile/Insurance/InsurancePlans/TableItems";

interface Props {
  insurances: PatientInsuranceResponse[];
}

export const DeductibleRows: React.FC<Props> = ({ insurances }) => {
  return (
    <>
      <SectionHeader
        numOfInsurances={insurances.length}
        subtitles={["Individual", "Family"]}
        title="Deductible"
      />
      <SectionRow
        insurances={insurances}
        renderValue={({ insurancePlan }, index) => {
          return (
            <div className={cxTableStyles.cellMultipleValues} key={`${index}-ded-total`}>
              <CurrencyCell value={insurancePlan?.individualAnnualDeductible} />
              <CurrencyCell value={insurancePlan?.familyAnnualDeductible} />
            </div>
          );
        }}
        title="Total Benefits"
      />
      <SectionRow
        insurances={insurances}
        renderValue={({ patientInsurance }) => {
          return (
            <div className={cxTableStyles.cellMultipleValues} key={`${patientInsurance.id}-ded-remaining`}>
              <CurrencyCell value={patientInsurance.annualDeductibleRemaining} />
              <CurrencyCell value={patientInsurance.subscriber.annualDeductibleRemaining} />
            </div>
          );
        }}
        title="Remaining"
      />
    </>
  );
};
