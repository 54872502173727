import React, { useCallback, useState } from "react";
import { ClaimLineItemData, ClaimLineItemVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatFullNameToInitials } from "@libs/utils/formatString";
import { centsToDollars, dollarsToCents, formatCurrency } from "@libs/utils/currency";
import { useBoolean } from "@libs/hooks/useBoolean";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Cell, CheckboxCell, EMPTY_CELL, Row } from "@libs/components/UI/GridTableComponents";
import { PreAuthInfo } from "components/Charting/PreAuthLabel";
import { ProcedureTableRowRemarksCell } from "components/Claim/ProcedureTableRowRemarksCell";
import {
  ClaimLineItemVOKeys,
  cxStyles,
  isComparisonCell,
  EditableColumnIds,
} from "components/Claim/ProcedureTable";
import { ColumnIds, HeaderData } from "components/Claim/procedureTableUtils";

import { ClaimLineItemActions } from "components/Eob/EobRoute";
import { ProcedureTableCurrencyCell } from "components/Claim/ProcedureTableRowCurrencyCell";
import { ClaimInsuranceOrdinal } from "components/Claims/types";

interface Props {
  claimUuid: string;
  disableActions: boolean;
  headers: HeaderData[];
  isAdditionalPayment: boolean;
  isChecked?: boolean;
  isDetailView: boolean;
  isDraftEobView: boolean;
  isInDraftEobPayment: boolean;
  isLastRow: boolean;
  isSubmitting: boolean;
  isValidating: boolean;
  insuranceOrdinal: ClaimInsuranceOrdinal;
  lineItem: ClaimLineItemVO;
  lineItemIndex: number;
  onAdjustmentAmountChange?:
    | ((lineItemIndex: number, field: string, value: string | undefined) => void)
    | undefined;
  onAdjustmentRemarkChange?:
    | ((
        lineItemIndex: number,
        updates: {
          downgradedCdtCode?: string;
          remarks?: string;
        }
      ) => void)
    | undefined;
  onSelectCheckbox?: ((id: number) => void) | undefined;
  onUpdateGroupedClaimLineItems?:
    | ((
        claimUuid: string,
        action: ClaimLineItemActions,
        options?: {
          hasErrors: boolean;
          lineItemIndex: number;
          updates: ClaimLineItemData;
        }
      ) => void)
    | undefined;
  previousLineItem?: ClaimLineItemVO;
  version: number;
}

export const getAmountString = (amount: number) => {
  return amount === 0 ? "0.00" : centsToDollars(amount).toString();
};

const getErrorMessage = (hasError: boolean, field: ClaimLineItemVOKeys) => {
  if (field !== "deductibleAmount" && field !== "patientAmount") {
    return "";
  }

  return hasError
    ? field === "deductibleAmount"
      ? "Deductible amount cannot be greater than patient amount"
      : "Patient amount cannot be less than deductible amount"
    : "";
};

const canEditCurrencyCell = (headerId: ColumnIds, canEdit: boolean) => {
  return isComparisonCell(headerId) && canEdit;
};

/* eslint-disable complexity */
export const ProcedureTableRow: React.FC<Props> = ({
  claimUuid,
  disableActions,
  headers,
  isAdditionalPayment,
  isChecked,
  isDetailView,
  isDraftEobView,
  isInDraftEobPayment,
  isLastRow,
  isSubmitting,
  isValidating,
  insuranceOrdinal,
  lineItem,
  lineItemIndex,
  onAdjustmentAmountChange,
  onAdjustmentRemarkChange,
  onSelectCheckbox,
  onUpdateGroupedClaimLineItems,
  previousLineItem,
  version,
}) => {
  const [editableData, setEditableData] = useState<Record<EditableColumnIds, string>>({
    deductibleAmount: getAmountString(lineItem.deductibleAmount),
    insuranceAmount: getAmountString(lineItem.insuranceAmount),
    patientAmount: getAmountString(lineItem.patientAmount),
  });

  const hasInputErrors = useBoolean(false);

  const handleUpdateEditableData = useCallback((id: EditableColumnIds, value: string) => {
    setEditableData((last) => ({ ...last, [id]: value }));
  }, []);

  const handleOnFocus = useCallback(
    (id: EditableColumnIds) => {
      // Clear out value when user focuses on input for empty amounts
      if (editableData[id] === "0.00") {
        handleUpdateEditableData(id, "");
      }
    },
    [editableData, handleUpdateEditableData]
  );

  const handleOnBlur = useCallback(
    (id: EditableColumnIds) => {
      // Reset to 0.00 value if input is empty
      if (editableData[id] === "") {
        handleUpdateEditableData(id, "0.00");
      }

      const hasErrors =
        insuranceOrdinal === "PRIMARY" &&
        Number.parseFloat(editableData.deductibleAmount) > Number.parseFloat(editableData.patientAmount);

      if (hasErrors) {
        hasInputErrors.on();
      } else {
        hasInputErrors.off();
      }

      if (onAdjustmentAmountChange) {
        onAdjustmentAmountChange(lineItemIndex, id, editableData[id]);
      } else if (onUpdateGroupedClaimLineItems && hasInputErrors.isOff) {
        onUpdateGroupedClaimLineItems(claimUuid, ClaimLineItemActions.UPDATE, {
          hasErrors,
          lineItemIndex,
          updates: {
            deductibleAmount: dollarsToCents(Number(editableData.deductibleAmount)),
            insuranceAmount: dollarsToCents(Number(editableData.insuranceAmount)),
            patientAmount: dollarsToCents(Number(editableData.patientAmount)),
            patientProcedureId: lineItem.patientProcedureId,
            version,
          },
        });
      }
    },
    [
      claimUuid,
      editableData,
      handleUpdateEditableData,
      hasInputErrors,
      insuranceOrdinal,
      lineItem.patientProcedureId,
      lineItemIndex,
      onAdjustmentAmountChange,
      onUpdateGroupedClaimLineItems,
      version,
    ]
  );

  const isProcessingDetailView = isInDraftEobPayment && !isDraftEobView && isDetailView;

  return (
    <Row>
      {headers.map((header) => {
        const isEditableCurrencyCell = canEditCurrencyCell(
          header.id,
          Boolean(onAdjustmentAmountChange || onUpdateGroupedClaimLineItems)
        );

        return header.id === "checkbox" ? (
          <CheckboxCell
            checked={isChecked}
            className={cx("pl-5", isDraftEobView && "border-b border-b-greyLight")}
            disabled={isSubmitting || isValidating}
            key={header.id}
            onChange={() => onSelectCheckbox && onSelectCheckbox(lineItem.patientProcedureId)}
            styleOptions={{ border: isLastRow, hasPadding: false, hideHover: true }}
            value={lineItem.patientProcedureId}
          />
        ) : (
          <Cell
            borderBottom={isLastRow}
            className={cx(
              cxStyles.cell({
                borderBottom: isDraftEobView,
                borderBottomColor: isLastRow ? "border-b-greyLight" : "border-b-greyLighter",
              }),
              !isEditableCurrencyCell && cxStyles.cellYPadding,
              header.isRightAligned && "justify-end",
              header.id === "remarks" && "justify-center",
              header.isCurrencyCell && isDraftEobView && cxStyles.currencyValueCell
            )}
            key={header.id}
          >
            {header.id === "providerName" ? (
              <FloatingTooltip content={lineItem.providerName?.fullDisplayName || ""} theme="SMALL">
                <span>{formatFullNameToInitials({ fullName: lineItem.providerName?.fullDisplayName })}</span>
              </FloatingTooltip>
            ) : header.id === "procedureArea" ? (
              <span>{lineItem.procedureArea ? lineItem.procedureArea.replaceAll(",", ", ") : "-"}</span>
            ) : header.id === "preAuthStatus" ? (
              <PreAuthInfo status={lineItem.preAuthStatus} />
            ) : header.id === "insuranceCoveragePercent" ? (
              lineItem.insuranceCoveragePercent ? (
                `${lineItem.insuranceCoveragePercent}%`
              ) : (
                EMPTY_CELL
              )
            ) : header.id === "remarks" ? (
              <ProcedureTableRowRemarksCell
                claimLineItem={lineItem}
                claimUuid={claimUuid}
                disableActions={disableActions}
                lineItemIndex={lineItemIndex}
                onAdjustmentRemarkChange={onAdjustmentRemarkChange}
                version={version}
              />
            ) : header.isCurrencyCell ? (
              isEditableCurrencyCell ? (
                <ProcedureTableCurrencyCell
                  disableActions={disableActions}
                  errorMessage={getErrorMessage(hasInputErrors.isOn, header.id)}
                  inputId={header.id as EditableColumnIds}
                  isAdditionalPayment={isAdditionalPayment}
                  onBlur={handleOnBlur}
                  onFocus={handleOnFocus}
                  onValueChange={handleUpdateEditableData}
                  previousValue={previousLineItem ? (previousLineItem[header.id] as number) : undefined}
                  value={editableData[header.id as EditableColumnIds]}
                />
              ) : isProcessingDetailView && isComparisonCell(header.id) ? (
                EMPTY_CELL
              ) : (
                formatCurrency(lineItem[header.id] as number)
              )
            ) : (
              lineItem[header.id] ?? EMPTY_CELL
            )}
          </Cell>
        );
      })}
    </Row>
  );
};
