import { FC } from "react";

import { AutomationActionVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { TableGrid, HeaderCell } from "@libs/components/UI/GridTableComponents";

import { ActionRow, ActionRowProps } from "components/Communications/Automations/ActionRow";

const tableHeaders = [
  { id: "timing", label: "Timing", width: "18rem" },
  { id: "channel", label: "Channel", width: "6rem" },
  { id: "message", label: "Message", width: "1fr" },
  { id: "status", label: "Active", width: "4rem", alignment: "justify-center" },
  { id: "menu", width: "3.25rem" },
];

interface Props extends Pick<ActionRowProps, "onRequestDelete" | "onRequestEdit" | "onToggleActionStatus"> {
  actions: AutomationActionVO[];
}

export const ActionsTable: FC<Props> = ({ actions, ...rest }) => {
  return (
    <TableGrid
      className="border border-slate-200 rounded max-h-full overflow-y-auto"
      columnWidths={tableHeaders.map(({ width }) => width)}
    >
      {tableHeaders.map(({ id, label, alignment }, index) => (
        <HeaderCell
          key={id}
          className={cx("first:rounded-tl", alignment, index === tableHeaders.length - 1 && "rounded-tr")}
          size="medium"
          borderColor="border-slate-200"
        >
          {label}
        </HeaderCell>
      ))}

      {actions.map((action, index) => (
        <ActionRow key={action.uuid} action={action} isLastRow={index === actions.length - 1} {...rest} />
      ))}
    </TableGrid>
  );
};
