import { useMemo } from "react";
import { PatientInsuranceResponse } from "@libs/api/generated-api";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import {
  LinkRow,
  SectionHeader,
  SectionRow,
} from "components/PatientProfile/Insurance/InsurancePlans/TableItems";
import { paths } from "utils/routing/paths";
import { SelectFrequencyLimit } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/SelectFrequencyLimit";

interface Props {
  frequencyLimitationCategoryIds: Set<number>;
  insurances: PatientInsuranceResponse[];
  patientId: number;
}

export const LimitationsRows: React.FC<Props> = ({
  frequencyLimitationCategoryIds,
  insurances,
  patientId,
}) => {
  const limitationServiceNames = useMemo(() => {
    const limitations = new Set(
      insurances.flatMap((insurance) =>
        (insurance.benefitLimitations ?? [])
          .filter(
            (limitation) =>
              limitation.frequencyLimitationCategory?.id &&
              frequencyLimitationCategoryIds.has(limitation.frequencyLimitationCategory.id)
          )
          .map((limitation) => limitation.serviceName)
      )
    );

    return [...limitations].filter(Boolean);
  }, [frequencyLimitationCategoryIds, insurances]);

  return limitationServiceNames.length === 0 ? null : (
    <>
      <SectionHeader
        numOfInsurances={insurances.length}
        subtitles={["Frequency Limit"]}
        title="Limitations"
      />
      {limitationServiceNames.map((serviceName) => (
        <SectionRow
          insurances={insurances}
          key={serviceName}
          renderValue={({ benefitLimitations }, index) => {
            const limitation = benefitLimitations?.find((item) => item.serviceName === serviceName);

            return limitation ? (
              <SelectFrequencyLimit
                editable={false}
                frequency={limitation.frequency ?? {}}
                isSummarizedLimitationView={true}
                key={`${index}-${serviceName}`}
                limitation={limitation}
                onChange={() => {
                  return null;
                }}
              />
            ) : (
              EMPTY_CELL
            );
          }}
          title={serviceName}
        />
      ))}
      <LinkRow
        linkPaths={insurances.map((insurance) =>
          paths.patientInsuranceStep({
            insuranceId: insurance.patientInsurance.id,
            patientId,
            step: "limitations",
          })
        )}
        linkText="All Limitations"
      />
    </>
  );
};
