import { FormVO, InvoiceVO, MigratedTransactionVO } from "@libs/api/generated-api";
import {
  BoolVal,
  NullableBoolVal,
  NumCSV,
  NumVal,
  ParsedParams,
  StrVal,
  withDefault,
  routesConfigHelper,
  StrCSV,
  Enum,
} from "@libs/router/url";
import { SortDirection } from "@libs/components/UI/GridTableComponents";
import {
  AppointmentCriteria,
  OrderBy,
  PatientCriteriaGender,
  PatientCriteriaInsuranceVerified,
  PatientCriteriaStatus,
  SortColumn,
} from "api/patients/queries";
import { ClaimsQueryParams } from "utils/routing/claims";
import { LabCasesQueryParams } from "utils/routing/labCases";
import { patientPanelQueryConfig } from "utils/routing/patientPanel";

export type PatientPageTab =
  | "imaging"
  | "edit"
  | "insurance"
  | "documents"
  | "billing"
  | "charting"
  | "perioCompare"
  | "claims"
  | "lab-cases"
  | "communication"
  | "forms"
  | "medical-history"
  | "erx";

const DEFAULT_SORT_COLUMN: SortColumn = "lastName";
const DEFAULT_ORDER_BY: OrderBy = "ASCENDING";

const PatientsQueryParams = {
  /** @deprecated */
  appointmentCriteria: Enum<AppointmentCriteria>().Val([
    "NO_APPOINTMENT",
    "APPOINTMENT_WITHIN_7_DAYS",
    "APPOINTMENT_WITHIN_15_DAYS",
    "APPOINTMENT_WITHIN_30_DAYS",
  ]),
  orderBy: withDefault(Enum<OrderBy>().Val(["ASCENDING", "DESCENDING"]), DEFAULT_ORDER_BY),
  search: StrVal,
  sortColumn: withDefault(
    Enum<SortColumn>().Val([
      "balanceAmount",
      "firstName",
      "insurance",
      "lastName",
      "nextAppointment",
      "phone",
      "ssn",
    ]),
    DEFAULT_SORT_COLUMN
  ),
  /* eslint-disable @typescript-eslint/naming-convention */
  "patientCriteria.ageGroup.min": NumVal,
  "patientCriteria.ageGroup.max": NumVal,
  "patientCriteria.gender": Enum<PatientCriteriaGender>().Val(["MALE", "FEMALE", "OTHER", "UNKNOWN"]),
  "patientCriteria.genders": Enum<PatientCriteriaGender>().CSV(["MALE", "FEMALE", "OTHER", "UNKNOWN"]),
  "patientCriteria.feeScheduleId": NumVal,
  "patientCriteria.insurancePlanUuid": StrVal,
  "patientCriteria.carrierIds": NumCSV,
  "patientCriteria.primaryInsuranceExpired": NullableBoolVal,
  "patientCriteria.primaryInsuranceVerifyStatuses": Enum<PatientCriteriaInsuranceVerified>().CSV([
    "VERIFIED",
    "NOT_VERIFIED",
    "AUTO_VERIFIED",
    "FAILED",
  ]),
  "patientCriteria.status": Enum<PatientCriteriaStatus>().CSV([
    "PROSPECT",
    "ACTIVE",
    "INACTIVE",
    "ARCHIVED",
    "NONPATIENT",
    "DECEASED",
  ]),
  "patientCriteria.birthdayRange.min": StrVal,
  "patientCriteria.birthdayRange.max": StrVal,
  "patientCriteria.birthdayWithinDays": NumVal,
  "patientCriteria.patientBalanceRange.min": NumVal,
  "patientCriteria.patientBalanceRange.max": NumVal,
  "patientCriteria.insuranceBalanceRange.min": NumVal,
  "patientCriteria.insuranceBalanceRange.max": NumVal,
  "patientCriteria.remainingBenefitAmountRange.min": NumVal,
  "patientCriteria.remainingBenefitAmountRange.max": NumVal,
  "patientCriteria.preferredLanguages": StrCSV,
  "patientCriteria.hasPreferredLanguage": NullableBoolVal,
  "appointmentDateRange.min": StrVal,
  "appointmentDateRange.max": StrVal,
  /* eslint-enable @typescript-eslint/naming-convention */
  appointmentWithinDays: NumVal,
  hasNoAppointment: NullableBoolVal,
};

export const PatientsQueryKeys = Object.keys(PatientsQueryParams) as (keyof typeof PatientsQueryParams)[];

export type CompareImagingSortType = "assignedDate" | "teeth";
export type TeethSearchMode = "CONTAINS_ALL" | "CONTAINS_ANY";

export const ImagingCompareQueryParams = {
  imageIds: NumCSV,
  from: StrVal,
  fromSelection: StrVal,
  teethSearchMode: Enum<TeethSearchMode>().Val(["CONTAINS_ALL", "CONTAINS_ANY"]),
  teeth: StrCSV,
  sortBy: withDefault(Enum<CompareImagingSortType>().Val(["teeth", "assignedDate"]), "assignedDate"),
};

export const PatientPathParams = {
  patientId: NumVal,
};

export const PatientWalletPathParams = {
  walletUuid: StrVal,
};

export const ViewInvoicePathParams = {
  invoiceUuid: StrVal,
};

export const PaymentPathParams = {
  paymentUuid: StrVal,
};

export const PatientTabPathParams = {
  ...PatientPathParams,
  tab: Enum<PatientPageTab>().Val([
    "imaging",
    "insurance",
    "documents",
    "billing",
    "charting",
    "claims",
    "edit",
    "perioCompare",
    "lab-cases",
    "communication",
    "forms",
    "medical-history",
    "erx",
  ]),
};

export const PatientDocumentsQueryParams = {
  docId: NumVal,
  folderId: NumVal,
};

export const CreateInvoiceQueryParams = {
  nextStep: withDefault(Enum<"return" | "collect">().Val(["return", "collect"]), "collect"),
  from: StrVal,
};

export type PatientsQueryKeys = keyof typeof PatientsQueryParams;

export type MountFormat = "sandbox" | "single" | "double" | "quad";
export type MountImageSourceType = "camera" | "x-ray" | "import";

const MountPathParams = { ...PatientPathParams, mountId: NumVal };

const MountQueryParams = {
  deviceId: StrVal,
  format: withDefault(Enum<MountFormat>().Val(["sandbox", "single", "double", "quad"]), "sandbox"),
  selectedImageIds: NumCSV,
  teethSearchMode: Enum<TeethSearchMode>().Val(["CONTAINS_ALL", "CONTAINS_ANY"]),
  teethSearchTeeth: StrCSV,
  hideMeta: withDefault(BoolVal, false),
  import: BoolVal,
  showArchived: BoolVal,
};
const EditImageQueryParams = {
  mountId: NumVal,
  selectedImageId: NumVal,
  imageIds: ImagingCompareQueryParams.imageIds,
  from: StrVal,
  teethSearchMode: ImagingCompareQueryParams.teethSearchMode,
  teeth: ImagingCompareQueryParams.teeth,
  sortBy: ImagingCompareQueryParams.sortBy,
  showArchived: BoolVal,
};

const NewMountQueryParams = {
  deviceId: StrVal,
  from: withDefault(Enum<MountImageSourceType>().Val(["camera", "x-ray", "import"]), "camera"),
};

const MedicalHistoryQueryParams = {
  auditLog: BoolVal,
  editMedicalHistory: Enum<
    Exclude<NonNullable<FormVO["slug"]>, "CONSENT" | "TREATMENT_PLAN_FORM" | "APPOINTMENT_FORM">
  >().Val(["MEDICAL_HISTORY", "DENTAL_HISTORY"]),
};

const PatientInvoicesQueryParams = {
  invoiceStates: Enum<InvoiceVO["state"]>().CSV(["PAID", "FINALIZED", "PARTIALLY_PAID", "VOID"]),
};

export type CollectionMode = "individual" | "family";

const CollectPaymentQueryParams = {
  invoice: StrVal,
  mode: withDefault(Enum<CollectionMode>().Val(["individual", "family"]), "individual"),
  invoiceUuids: StrCSV,
};

const ImportedLedgerQueryParams = {
  types: Enum<MigratedTransactionVO["type"]>().CSV(["ADJUSTMENT", "PAYMENT", "PROCEDURE", "OTHER"]),
  employeeIds: NumCSV,
  searchString: StrVal,
  sortDirection: withDefault(Enum<SortDirection>().Val(["ASCENDING", "DESCENDING"]), "DESCENDING"),
};

export const PatientOverviewQueryParams = {
  recall: BoolVal,
};

export type MountQuery = ParsedParams<typeof MountQueryParams>;
export type MountQueryUpdates = Partial<MountQuery>;

export const PatientRoutesConfig = routesConfigHelper({
  patients: {
    path: "/patients",
    query: {
      ...PatientsQueryParams,
      ...patientPanelQueryConfig,
    },
  },
  patient: {
    path: "/patients/:patientId",
    query: PatientOverviewQueryParams,
    params: PatientPathParams,
  },
  editPatient: {
    path: "/patients/:patientId/edit",
    query: PatientOverviewQueryParams,
    params: PatientPathParams,
  },
  patientClaims: {
    path: "/patients/:patientId/claims",
    query: ClaimsQueryParams,
    params: PatientPathParams,
  },
  patientLabCases: {
    path: "/patients/:patientId/lab-cases",
    query: LabCasesQueryParams,
    params: PatientPathParams,
  },
  patientPreAuths: {
    path: "/patients/:patientId/claims/pre-auths",
    query: ClaimsQueryParams,
    params: PatientPathParams,
  },
  patientTab: {
    path: "/patients/:patientId/:tab",
    params: PatientTabPathParams,
  },
  patientDocuments: {
    path: "/patients/:patientId/documents",
    params: PatientPathParams,
    query: PatientDocumentsQueryParams,
  },
  patientBilling: {
    path: "/patients/:patientId/billing",
    params: PatientPathParams,
  },
  patientInvoices: {
    path: "/patients/:patientId/billing/invoices",
    params: PatientPathParams,
    query: { ...PatientInvoicesQueryParams },
  },
  patientPayments: {
    path: "/patients/:patientId/billing/payments",
    params: PatientPathParams,
  },
  patientForms: {
    path: "/patients/:patientId/forms",
    params: PatientPathParams,
  },
  patientCommunication: {
    path: "/patients/:patientId/communication",
    params: PatientPathParams,
  },
  patientWalletActivity: {
    path: "/patients/:patientId/billing/wallet/:walletUuid/activity",
    params: { ...PatientPathParams, ...PatientWalletPathParams },
    query: { from: StrVal },
  },
  createInvoice: {
    path: "/patients/:patientId/billing/invoice",
    params: { ...PatientPathParams },
    query: { ...CreateInvoiceQueryParams },
  },
  viewInvoice: {
    path: "/patients/:patientId/billing/invoice/:invoiceUuid",
    params: { ...PatientPathParams, ...ViewInvoicePathParams },
    query: { from: StrVal },
  },
  collectPayment: {
    path: "/patients/:patientId/billing/payment/collect",
    params: { ...PatientPathParams },
    query: { from: StrVal, ...CollectPaymentQueryParams },
  },
  viewPayment: {
    path: "/patients/:patientId/billing/payment/:paymentUuid",
    params: { ...PatientPathParams, ...PaymentPathParams },
    query: { from: StrVal },
  },
  editPayment: {
    path: "/patients/:patientId/billing/payment/:paymentUuid/edit",
    params: { ...PatientPathParams, ...PaymentPathParams },
    query: { from: StrVal, ...CollectPaymentQueryParams },
  },
  importedLedger: {
    path: "/patients/:patientId/billing/imported-ledger",
    params: PatientPathParams,
    query: { from: StrVal, ...ImportedLedgerQueryParams },
  },
  medicalHistory: {
    path: "/patients/:patientId/medical-history",
    params: PatientPathParams,
    query: MedicalHistoryQueryParams,
  },

  // Imaging
  mountDetails: {
    path: "/patients/:patientId/imaging/:mountId",
    params: MountPathParams,
    query: { from: StrVal, ...MountQueryParams },
  },

  newMount: {
    path: "/patients/:patientId/imaging/new",
    query: NewMountQueryParams,
    params: PatientPathParams,
  },
  imagingEditImage: {
    path: "/patients/:patientId/imaging/edit",
    params: PatientPathParams,
    query: EditImageQueryParams,
  },
  imagingSettings: {
    path: "/patients/:patientId/imaging/settings",
    params: PatientPathParams,
  },
  imagingCompare: {
    path: "/patients/:patientId/imaging/compare",
    params: PatientPathParams,
    query: ImagingCompareQueryParams,
  },
  imagingCompareSelectImages: {
    path: "/patients/:patientId/imaging/select",
    params: PatientPathParams,
    query: ImagingCompareQueryParams,
  },
});

export type PatientsQuery = Omit<ParsedParams<typeof PatientsQueryParams>, "patientId">;
export type PatientsQueryUpdates = Partial<PatientsQuery>;
export type MountDetailsQuery = ParsedParams<typeof MountQueryParams>;
export type MedicalHistoryQuery = ParsedParams<typeof MedicalHistoryQueryParams>;
export type ImagingCompareQuery = ParsedParams<typeof ImagingCompareQueryParams>;
export type PatientDocumentsQuery = ParsedParams<typeof PatientDocumentsQueryParams>;
export type ImportedLedgerQuery = ParsedParams<typeof ImportedLedgerQueryParams>;
