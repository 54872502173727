import { useState } from "react";
import { FormSelectInputElementRequest } from "@libs/api/generated-api";
import { isObject, isString } from "@libs/utils/types";
import { ValidationResult } from "@libs/hooks/useValidation";
import { titleSchema } from "components/Settings/Forms/utils";

export type EditableOption = { id: string; value: string; new?: boolean };
type MultipleChoiceState = Omit<FormSelectInputElementRequest, "options"> & {
  options: EditableOption[];
};
export type MultipleChoiceFormState = { draft: MultipleChoiceState };

export const cleanMultipleChoiceFormState = (
  formState: MultipleChoiceFormState
): FormSelectInputElementRequest => {
  return {
    ...formState.draft,
    options: formState.draft.options.map((op) => op.value),
  };
};

export const filterEmptyOptions = (request: FormSelectInputElementRequest) => {
  return {
    ...request,
    options: request.options.map((op) => op.trim()).filter(Boolean),
  };
};

export const createOption = (value = "") => ({ id: crypto.randomUUID(), value });
export const createNewOption = () => ({ id: crypto.randomUUID(), value: "", new: true });

export const useMultipleChoiceState = (
  element: FormSelectInputElementRequest | (() => FormSelectInputElementRequest)
) => {
  return useState<MultipleChoiceFormState>(() => {
    const resolvedElement = typeof element === "function" ? element() : element;

    return {
      draft: {
        ...resolvedElement,
        options: resolvedElement.options.length
          ? [...resolvedElement.options.map((op) => createOption(op)), createNewOption()]
          : [createNewOption(), createNewOption(), createNewOption()],
      },
    };
  });
};

const isUniquePropValue = (set: Set<string>, prop: string) => (val: unknown) => {
  if (!isObject(val) || !(prop in val)) {
    return true;
  }

  const propValue = val[prop];

  if (!isString(propValue) || !propValue.trim()) {
    return true;
  }

  return !set.has(propValue);
};

const getOptionsSchema = ({ options }: { options: EditableOption[] }) => {
  const duplicates = new Set<string>();
  const seen = new Set<string>();

  for (const option of options) {
    if (seen.has(option.value)) {
      duplicates.add(option.value);
    } else {
      seen.add(option.value);
    }
  }

  return {
    $validations: [
      {
        $v: (value: unknown) =>
          Array.isArray(value)
            ? (value as EditableOption[]).filter((op) => op.value.trim()).length >= 1
            : true,
        $error: "Must have at least one valid option",
      },
    ],
    $items: [{ $v: isUniquePropValue(duplicates, "value"), $error: "Options must have unique labels" }],
  };
};

export type ChoicesValidation = ValidationResult<EditableOption[], ReturnType<typeof getOptionsSchema>>;

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export const getMultipleChoiceSchema = (params: Parameters<typeof getOptionsSchema>[0]) => {
  return {
    title: titleSchema,
    options: getOptionsSchema(params),
  };
};

export type MultipleChoiceValidationResult = ValidationResult<
  MultipleChoiceState,
  ReturnType<typeof getMultipleChoiceSchema>
>;
