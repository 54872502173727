import React from "react";
import { MedicalImageVO } from "@libs/api/generated-api";
import { getImageStylesWithRotationTransforms } from "components/PatientProfile/Imaging/ImageSidebarNavigation/utils";

export const useContainedTransforms = (
  bounds: { width: number; height: number },
  image: MedicalImageVO,
  options: { canExceedImageSize: boolean }
) => {
  return React.useMemo(() => {
    return getImageStylesWithRotationTransforms(bounds, image, options);
  }, [bounds, image, options]);
};
