import { ClaimLineItemVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatCurrency } from "@libs/utils/currency";
import { Cell, Row } from "@libs/components/UI/GridTableComponents";
import { ClaimLineItemVOKeys, cxStyles, isComparisonCell } from "components/Claim/ProcedureTable";
import { getFieldTotal } from "components/Claim/ProcedureTableFooter";
import { HeaderData } from "components/Claim/procedureTableUtils";

interface Props {
  footerDescriptionIndex: number;
  headers: HeaderData[];
  lineItems: ClaimLineItemVO[];
  previousLineItems: ClaimLineItemVO[];
}

export const ProcedureTableFooterAdditionalSummary: React.FC<Props> = ({
  footerDescriptionIndex,
  headers,
  lineItems,
  previousLineItems,
}) => {
  return (
    <Row>
      {headers.map((header, index) =>
        isComparisonCell(header.id) ? (
          <Cell className={cx("flex", cxStyles.cell({}), cxStyles.currencyValueCell)} key={header.id}>
            <div
              className={cx(
                "flex h-full",
                cxStyles.cellYPadding,
                cxStyles.currencyDividerBorder,
                cxStyles.currencyReadOnly
              )}
            />
            <div
              className={cx(
                "flex justify-end text-orange font-sansSemiBold",
                cxStyles.cellYPadding,
                cxStyles.currencyEdit
              )}
            >
              {formatCurrency(
                getFieldTotal(header.id as ClaimLineItemVOKeys, lineItems) -
                  getFieldTotal(header.id as ClaimLineItemVOKeys, previousLineItems)
              )}
            </div>
          </Cell>
        ) : index === footerDescriptionIndex ? (
          <Cell className={cx("justify-end font-sansSemiBold", cxStyles.cell({}))} key={header.id}>
            Change in Payment
          </Cell>
        ) : (
          <Cell
            className={cx(cxStyles.cell({}), index > footerDescriptionIndex && cxStyles.currencyValueCell)}
            key={header.id}
          />
        )
      )}
    </Row>
  );
};
