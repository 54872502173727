import { useMemo } from "react";
import {
  ContactVO,
  FamilyMemberResponse,
  PatientInsuranceSummaryVO,
  PatientVO,
} from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { getAllInsuranceCarriersQuery } from "api/practiceInsurance/queries";
import { AddFamilyMemberForm } from "components/PatientProfile/PatientOverview/FamilyMembers/AddFamilyMemberForm";
import { PatientFormProps } from "components/Patient/PatientForm";
import { getAllPrimaryInsurances } from "components/PatientProfile/PatientOverview/utils";

interface Props
  extends Pick<
    PatientFormProps,
    "onClearContact" | "onAddContact" | "onEditContact" | "defaultContactOptions" | "contactSelection"
  > {
  familyMembers: FamilyMemberResponse;
  isGuardian: boolean;
  onSelectContact: (patientFirstName: string, matchedPatient: ContactVO) => void;
  onClose: () => void;
  onDirty: Func;
  onCreate: (displayName: string) => void;
  patient: PatientVO;
}

export const AddFamilyMemberFormPanel: React.FC<Props> = ({
  familyMembers,
  isGuardian,
  onClose,
  contactSelection,
  onAddContact,
  onDirty,
  onClearContact,
  onEditContact,
  onSelectContact,
  onCreate,
  patient,
}) => {
  const { practiceId } = useAccount();
  const [{ data: insuranceCarriers }] = useApiQueries([
    getAllInsuranceCarriersQuery({ args: { practiceId } }),
  ]);

  const allPrimaryInsurances = useMemo(() => {
    return getAllPrimaryInsurances(familyMembers);
  }, [familyMembers]);

  const allPrimaryFamilyMembers = useMemo(() => {
    const ptIdsWithInsurance = new Set();

    const filteredPrimaryInsurances: PatientInsuranceSummaryVO[] = [];

    allPrimaryInsurances.forEach((insurance) => {
      if (!ptIdsWithInsurance.has(insurance.subscriber.id)) {
        filteredPrimaryInsurances.push(insurance);
        ptIdsWithInsurance.add(insurance.subscriber.id);
      }
    });

    return filteredPrimaryInsurances.map((primaryPtInsurance) => ({
      name: primaryPtInsurance.subscriber.fullDisplayName,
      patientId: primaryPtInsurance.subscriber.id,
    }));
  }, [allPrimaryInsurances]);

  return (
    <AddFamilyMemberForm
      familyMembersPrimary={allPrimaryFamilyMembers}
      familyMembers={familyMembers}
      insuranceCarriers={insuranceCarriers}
      isGuardian={isGuardian}
      onCreate={onCreate}
      onDirty={onDirty}
      patient={patient}
      contactSelection={contactSelection}
      onClose={onClose}
      onClearContact={onClearContact}
      onAddContact={onAddContact}
      onEditContact={onEditContact}
      onSelectContact={onSelectContact}
    />
  );
};
