import { AlertSettingsVO } from "@libs/api/generated-api";
import { RadioList } from "@libs/components/UI/RadioList";
import { FC } from "react";
import { OptionInput } from "@libs/components/UI/OptionInput";
import iconOnlyUrl from "assets/images/alert-appearance-icon-only.svg";
import noneUrl from "assets/images/alert-appearance-none.svg";
import openPanelUrl from "assets/images/alert-appearance-panel.svg";

interface Props {
  appearance: AlertSettingsVO["appearance"];
  onChange: (newAppearance: AlertSettingsVO["appearance"]) => void;
}

const OptionLabel: FC<{ text: string; imageUrl: string }> = ({ text, imageUrl }) => (
  <>
    <div
      className={`
        cursor-pointer
        border
        rounded
        p-2
        pb-1
        mb-2
        peer-checked:
        peer-checked:bg-archyBlue-50
        hover:peer-checked:bg-archyBlue-50
        peer-checked:border-archyBlue-500
        hover:bg-slate-50
        border-slate-200
      `}
    >
      <img alt={text} src={imageUrl} />
    </div>
    <div className="text-xs">{text}</div>
  </>
);

export const AlertAppearance: FC<Props> = ({ appearance, onChange }) => {
  return (
    <RadioList
      horizontalLayout="normal"
      selectedValue={appearance}
      onChange={(_, option) => onChange(option.value)}
      renderOptionItem={({ children, ...props }) => <OptionInput {...props} customContent={children} />}
      options={[
        {
          value: "SHOW_ALERT",
          label: <OptionLabel imageUrl={openPanelUrl} text="Open Panel" />,
        },
        {
          value: "SHOW_ICON",
          label: <OptionLabel imageUrl={iconOnlyUrl} text="Icon Only" />,
        },
        {
          value: "NONE",
          label: <OptionLabel imageUrl={noneUrl} text="Icon on Hover" />,
        },
      ]}
    />
  );
};
