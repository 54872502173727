import { Route } from "react-router-dom";
import { FC } from "react";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { MainAppHistoryProvider } from "components/Main/MainLinksContext";
import { ScheduleRoute } from "components/ScheduleAppointments/ScheduleRoute";
import { AddPatientAppointmentRoute } from "components/ScheduleAppointments/AddPatientAppointmentRoute";
import { ScheduleAppointmentRoute } from "components/ScheduleAppointments/ScheduleAppointmentRoute";
import { EditAppointmentRoute } from "components/ScheduleAppointments/EditAppointmentRoute";
import { ScheduleBlockRoute } from "components/ScheduleAppointments/ScheduleBlockRoute";
import { EditBlockRoute } from "components/ScheduleAppointments/EditBlockRoute";

import { DailyHuddleRoute } from "components/DailyHuddle/DailyHuddleRoute";
import { ScheduleLinksProvider } from "components/ScheduleAppointments/ScheduleLinksContext";
import { ScheduleAsapList } from "components/ScheduleAppointments/ScheduleAsapList";
import { ScheduleRequestsList } from "components/ScheduleAppointments/ScheduleRequestsList";

export const SchedulingRoutes = [
  {
    index: true,
    element: <ScheduleRoute />,
  },
  {
    path: "add-patient-appointment",
    element: <AddPatientAppointmentRoute />,
  },
  {
    path: "schedule-appointment/:date/:startTime",
    element: <ScheduleAppointmentRoute />,
  },
  {
    path: "asap",
    element: <ScheduleAsapList />,
  },
  {
    path: "requests",
    element: <ScheduleRequestsList />,
  },
  {
    path: "asap/add",
    element: <ScheduleAppointmentRoute />,
  },
  {
    path: "schedule-block/:date/:startTime",
    element: <ScheduleBlockRoute />,
  },
  {
    path: "appointments/:appointmentId/:patientId/edit",
    element: <EditAppointmentRoute />,
  },
  {
    path: "blocks/:blockId/edit/:instanceDate",
    element: <EditBlockRoute />,
    exact: true,
  },
  {
    path: "huddle",
    element: <DailyHuddleRoute />,
  },
];

export const SchedulingApp: FC = () => {
  return (
    <MainAppHistoryProvider name="schedule">
      <ScheduleLinksProvider>
        <SentryRoutes>
          {SchedulingRoutes.map(({ path, element, index }, i) => (
            <Route key={i} path={path} index={index} element={element} />
          ))}
        </SentryRoutes>
      </ScheduleLinksProvider>
    </MainAppHistoryProvider>
  );
};
