import React from "react";
import { WebTwain } from "dwt/dist/types/WebTwain";
import Dynamsoft from "dwt";
import { CapabilityDetails } from "dwt/dist/types/WebTwain.Acquire";
import { usePromise } from "utils/usePromise";
import { handleError } from "utils/handleError";
import { useEnvContext } from "contexts/EnvContext";
import { useImagingHub } from "components/ImageCapturing/ImagingHubContext";
import { NO_DEVICE_INDEX, TwainContext, twainElementId, TwainSource } from "./TwainContext";
import { requestSources, selectSource, createTwain, TWAIN_DEFAULT_RESOLUTION } from "./twain";
import { TwainError } from "./twainErrors";

/*
  useTwain accepts an elementId to mount to and returns all of the interface
  required for intializing Twain, selecting a twain image source, displaying the
  twain UI, and capturing images from the twain source.

  The basic flow is:
  - user (first time only) downloads some dynamsoft drivers
  - user selects a source to capture images from
  - user request to capturing images
  - user captures images

    The hooks guide you through this flow by return more fields as the user gets
    through the process:
  - On mount (when useTwain is first called) a twain instance is created. You
    only get the twainInstance property. This will tell you the state of whether
    twain has initialized.
  - Once twain is initialized the sources will be automatically fetched and you
    will have access to the twainSources field. This provides you with the ui
    states and method calls for managing a UI for loading and choosing a twain
    source.
  - Once camera sources have been returned and the user has selected a source
    index with twainSources.selectIndex, you will get the twainCapture field.
    The user must request to start capturing images with twainCapture.start and
    pass a callback that will get invoked when images get captured.
*/

const TWAIN_TEST_CAM = "DEV - Test Cam";
const TWAIN_TEST_CAM_INDEX = 999;

function useTwainSources() {
  const { REACT_APP_ENVIRONMENT } = useEnvContext();
  const { sources, setSources, hasLoadedSources, twain, selectedIndex, setSelectedIndex } =
    React.useContext(TwainContext);

  const {
    call: loadSources,
    error: requestSourcesError,
    isPending: isRequestingSources,
    clearError: clearSourcesError,
  } = usePromise(requestSources);
  const selectIndex = React.useCallback(
    async (i: number) => {
      if (isTwainReady(twain)) {
        try {
          await selectSource(twain, i);
          setSelectedIndex(i);
        } catch (e) {
          handleError(e);
        }
      }
    },
    [setSelectedIndex, twain]
  );

  React.useEffect(() => {
    if (twain && !hasLoadedSources.current) {
      hasLoadedSources.current = true;

      loadSources(twain)
        .then((items) => {
          const loaded = [...(items ?? [])];

          if (REACT_APP_ENVIRONMENT === "development") {
            loaded.push({ label: TWAIN_TEST_CAM, index: TWAIN_TEST_CAM_INDEX, driverType: "TWAIN" });
          }

          setSources(loaded);
        })
        .catch(() => {
          hasLoadedSources.current = false;
        });
    }
  }, [
    REACT_APP_ENVIRONMENT,
    loadSources,
    hasLoadedSources,
    setSources,
    twain,
    sources,
    setSelectedIndex,
    selectIndex,
  ]);

  return React.useMemo(() => {
    const twainSources = {
      items: sources,
      isRequesting: isRequestingSources,
      clearError: clearSourcesError,
      error: requestSourcesError,
      selectedIndex,
      selectIndex,
    };

    return {
      twainSources,
    };
  }, [clearSourcesError, isRequestingSources, requestSourcesError, selectedIndex, selectIndex, sources]);
}

export interface AcquireSettings {
  useADF?: boolean; // ADF: Automatic Document Feeder
  showUI?: boolean;
  useDuplex?: boolean;
  colorMode?: "BW" | "GRAY" | "RGB";
  autoDiscardBlankPages?: boolean;
  resolution?: number;
  isXRay?: boolean;
}

// Per https://www.dynamsoft.com/web-twain/docs/info/api/WebTwain_Acquire.html#getdevicetype
const deviceTypeNumberToString = {
  [0]: "FAILED",
  [1]: "CAMERA",
  [2]: "FLATBED",
  [3]: "FLATBED_AUTOFEEDER",
  [4]: "AUTOFEEDER",
  [5]: "FLATBED_FEEDER",
  [6]: "FEEDER",
  [7]: "WEBCAM",
} as const;

type TwainDeviceTypeKey = keyof typeof deviceTypeNumberToString;
export type TwainDeviceType = (typeof deviceTypeNumberToString)[TwainDeviceTypeKey];

export interface UseTwain {
  twainInstance: {
    isReady: boolean;
    clearErrors: Func;
    error: Error | null;
    neededDownloadUrl: string | null;
    acquireImage: (settings: AcquireSettings) => Promise<void>;
    getCapabilities: () => Promise<CapabilityDetails[]>;
    getDeviceType: () => TwainDeviceType;
    saveAllAsPdf: () => Promise<Blob>;
    saveAsJpeg: () => Promise<Blob>;
    removeAllImagesFromBuffer: () => void;
    webTwain?: WebTwain;
  };
  twainSources?: {
    items: TwainSource[] | null;
    isRequesting: boolean;
    clearError: Func;
    error: Error | null;
    selectedIndex: number;
    selectIndex: (newIndex: number) => Promise<void>;
  };
  twainCapture?: {
    setCaptureImageError: (error: string) => void;
    error: string;
  };
}

export type TwainSources = ReturnType<typeof useTwainSources>["twainSources"];

const isTwainReady = (twain: WebTwain | undefined): twain is WebTwain => {
  return twain !== undefined;
};

export const isValidDeviceIndex = (index?: number) => {
  return (index ?? NO_DEVICE_INDEX) !== NO_DEVICE_INDEX;
};

export function assertTwainIsReady(twain: WebTwain | undefined): asserts twain is WebTwain {
  if (!isTwainReady(twain)) {
    throw new Error("Twain instance is not ready");
  }
}

function assertDeviceIsSelected(twainSources: TwainSources) {
  if (!isValidDeviceIndex(twainSources.selectedIndex)) {
    throw new Error("A device index must be set before operating on the device");
  }
}

export function useTwain(): UseTwain {
  const { REACT_APP_DWT_HANDSHAKE_CODE, REACT_APP_ENVIRONMENT } = useEnvContext();
  const { hasForcedDynamsoft, status: aisStatus, hasFetchedImagingSettings } = useImagingHub();
  const {
    twain,
    setIsUsingTestCam,
    setNeededDownloadUrl,
    setTwainLoadError,
    neededDownloadUrl,
    setTwain,
    hasTriggeredInit,
    twainLoadError,
    onImageReadyRef,
  } = React.useContext(TwainContext);

  const twainLoaded = isTwainReady(twain);

  const clearTwainError = React.useCallback(() => setTwainLoadError(null), [setTwainLoadError]);
  const load = React.useCallback(async () => {
    hasTriggeredInit.current = true;

    if (aisStatus.isAISAllowed && !hasForcedDynamsoft) {
      setTwainLoadError(new Error("To use Dynamsoft, you must toggle 'Force Dynamsoft' in imaging settings"));

      return;
    }

    try {
      const result = await createTwain({
        appEnv: REACT_APP_ENVIRONMENT,
        twainHandshakeCode: REACT_APP_DWT_HANDSHAKE_CODE,
        elementId: twainElementId,
        onImageCaptured: (...args) => {
          onImageReadyRef.current?.(...args);
        },
        onNotFound: setNeededDownloadUrl,
      });

      // Clear any images from prior captures to free up memory
      result.RemoveAllImages();
      setTwain(result);
    } catch (e) {
      setTwainLoadError(e as Error);
    }
  }, [
    hasTriggeredInit,
    aisStatus.isAISAllowed,
    hasForcedDynamsoft,
    REACT_APP_ENVIRONMENT,
    REACT_APP_DWT_HANDSHAKE_CODE,
    setNeededDownloadUrl,
    setTwain,
    onImageReadyRef,
    setTwainLoadError,
  ]);
  const { twainSources } = useTwainSources();

  React.useEffect(() => {
    if (!hasTriggeredInit.current && hasFetchedImagingSettings) {
      load();
    }
  }, [load, hasTriggeredInit, hasFetchedImagingSettings]);

  return React.useMemo(
    (): UseTwain => ({
      twainInstance: {
        isReady: twainLoaded,
        clearErrors: () => {
          clearTwainError();
          setNeededDownloadUrl(null);
        },
        error: twainLoadError,
        neededDownloadUrl,
        webTwain: twain,
        getCapabilities: () => {
          return new Promise((resolve, reject) => {
            assertTwainIsReady(twain);
            assertDeviceIsSelected(twainSources);
            twain.getCapabilities(
              (capabilities) => resolve(capabilities),
              (errorCode, errorString) => {
                reject(new TwainError({ errorCode, errorString, api: "getCapabilities" }));
              }
            );
          });
        },
        getDeviceType: () => {
          assertTwainIsReady(twain);
          assertDeviceIsSelected(twainSources);

          return deviceTypeNumberToString[twain.GetDeviceType() as TwainDeviceTypeKey];
        },
        removeAllImagesFromBuffer: () => {
          assertTwainIsReady(twain);
          twain.RemoveAllImages();
        },
        saveAllAsPdf: () => {
          assertTwainIsReady(twain);

          return new Promise((resolve, reject) => {
            twain.ConvertToBlob(
              [...Array.from({ length: twain.HowManyImagesInBuffer }).keys()],
              Dynamsoft.DWT.EnumDWT_ImageType.IT_PDF,
              (blob, _indices, _type) => resolve(blob),
              (errorCode, errorString) =>
                reject(new TwainError({ api: "saveAllAsPdf", errorCode, errorString }))
            );
          });
        },
        saveAsJpeg: () => {
          assertTwainIsReady(twain);

          return new Promise((resolve, reject) => {
            twain.ConvertToBlob(
              [...Array.from({ length: twain.HowManyImagesInBuffer }).keys()],
              Dynamsoft.DWT.EnumDWT_ImageType.IT_JPG,
              (blob, _indices, _type) => resolve(blob),
              (errorCode, errorString) =>
                reject(new TwainError({ api: "saveAsJpeg", errorCode, errorString }))
            );
          });
        },
        acquireImage: ({
          showUI = true,
          useADF = false,
          useDuplex = false,
          // autoDiscardBlankPages = false,
          colorMode = "RGB",
          resolution = TWAIN_DEFAULT_RESOLUTION,
        }: AcquireSettings) => {
          assertTwainIsReady(twain);

          return new Promise<void>((resolve, reject) => {
            twain.AcquireImage(
              {
                IfShowUI: showUI,
                IfFeederEnabled: useADF,
                IfDuplexEnabled: useDuplex,
                PixelType: Dynamsoft.DWT.EnumDWT_PixelType[`TWPT_${colorMode}`],
                Resolution: resolution,
                // IfAutoDiscardBlankpages: autoDiscardBlankPages,
              },

              // The success callback is called after "OnPostAllTransfers". See
              // https://www.dynamsoft.com/web-twain/docs/info/api/WebTwain_Acquire.html#onpostalltransfers
              resolve,
              (_, errorCode, errorString) =>
                reject(
                  new TwainError({
                    errorCode,
                    errorString,
                    api: "AcquireImage",
                  })
                )
            );
          });
        },
      },
      twainSources: {
        ...twainSources,
        selectIndex: async (i: number) => {
          if (i === TWAIN_TEST_CAM_INDEX) {
            setIsUsingTestCam(true);
          } else {
            setIsUsingTestCam(false);

            return twainSources.selectIndex(i);
          }

          return undefined;
        },
      },
    }),
    [
      clearTwainError,
      neededDownloadUrl,
      setIsUsingTestCam,
      setNeededDownloadUrl,
      twain,
      twainLoadError,
      twainLoaded,
      twainSources,
    ]
  );
}

export const useLoadedTwainSensors = () => {
  const { twainInstance, twainSources } = useTwain();

  return React.useMemo(() => {
    if (twainInstance.isReady) {
      return twainSources?.items ?? [];
    }

    return [];
  }, [twainInstance.isReady, twainSources?.items]);
};

export const useCloseTwainOnExit = () => {
  const { twain: webTwain } = React.useContext(TwainContext);

  React.useEffect(() => {
    const closeTwainSource = () => {
      webTwain?.CloseSource();
      webTwain?.CloseSourceManager();
    };

    window.addEventListener("beforeunload", closeTwainSource);

    return () => {
      closeTwainSource();
      window.removeEventListener("beforeunload", closeTwainSource);
    };
  }, [webTwain]);
};
