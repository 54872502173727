import { RevenueType } from "components/Dashboard/PracticeProduction/types";

export const labelForRevenueType = (revenueType: RevenueType) => {
  const types: Record<RevenueType, string> = {
    grossProduction: "Gross Production",
    netProduced: "Net Produced",
    grossScheduled: "Gross Scheduled",
    netScheduled: "Net Scheduled",
  };

  return types[revenueType];
};
