import { Dispatch, RefObject, useCallback, useRef, useState } from "react";

/**
 * The purpose of this function is to expose a value that can be mutated multiple times within
 * a render cycle without needing to be re-rendered in-between.
 * @param value The initial value of the ref.
 * @returns A ref, and setter to adjust the ref that will trigger a re-render when the value changes.
 */
export const useRenderableRef = <T>(initialValueFn: () => T): [RefObject<T>, Dispatch<T>, T] => {
  const [latestValue, setLatestValue] = useState(initialValueFn);
  const ref = useRef<T>(latestValue);

  const setState = useCallback((newValue: T) => {
    if (!Object.is(ref.current, newValue)) {
      ref.current = newValue;
      setLatestValue(newValue);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // This is a hack to switch the ref when the value is updated, so the ref can be used effectively as s dependency
  return [ref, setState, latestValue];
};
