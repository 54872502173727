import React, { ReactNode, useMemo } from "react";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { MenuPlacement, StylesConfig } from "react-select";
import { DentalProcedureVO } from "@libs/api/generated-api";
import { FormFieldProps } from "@libs/components/UI/FormField";
import { ProcedureSelectorSingleSelect } from "components/ProcedureSelector/ProcedureSelectorSingleSelect";
import { ProcedureSelectorMultiSelect } from "components/ProcedureSelector/ProcedureSelectorMultiSelect";

export type ProcedureDataCode = {
  label: string;
  value: string;
  data: DentalProcedureVO;
};

export const handleFilterOption = (option: FilterOptionOption<ProcedureDataCode>, searchText: string) => {
  const searchTextLowered = searchText.toLowerCase();

  return (
    option.data.data.cdtCode.toLowerCase().includes(searchTextLowered) ||
    option.data.data.name.toLowerCase().includes(searchTextLowered) ||
    option.data.data.simpleName.toLowerCase().includes(searchTextLowered) ||
    Boolean(option.data.data.laymanTerm?.toLowerCase().includes(searchTextLowered))
  );
};
type SharedProps = {
  display?: SelectDisplayType;
  isClearable?: boolean;
  autoFocus?: boolean;
  openMenuOnFocus?: boolean;
  menuPlacement?: MenuPlacement;
  closeMenuOnSelect?: boolean;
};
type ProcedureSelectorSingle = FormFieldProps &
  SharedProps & {
    mode: "single";
    placeholder?: ReactNode;
    procedures?: DentalProcedureVO[];
    value?: DentalProcedureVO;
    onChange?: (newValue?: DentalProcedureVO) => void;
    styles?: StylesConfig<ProcedureDataCode, false, GroupedSelectOption<string, ProcedureDataCode>>;
  };

type ProcedureSelectorMultiple = FormFieldProps &
  SharedProps & {
    mode?: "multi";
    hideSelectedOptions?: boolean;
    formatOptionLabel?: (procedure: ProcedureDataCode) => ReactNode;
    allowDuplicateSelections?: boolean;
    placeholder?: ReactNode;
    procedures?: DentalProcedureVO[];
    values?: DentalProcedureVO[];
    onChange?: (newValues: DentalProcedureVO[]) => void;
    styles?: StylesConfig<ProcedureDataCode, true, GroupedSelectOption<string, ProcedureDataCode>>;
  };

type ProcedureSelectorProps = ProcedureSelectorSingle | ProcedureSelectorMultiple;

export const ProcedureSelector: React.FC<ProcedureSelectorProps> = (props) => {
  const codes = useMemo<ProcedureDataCode[]>(
    () =>
      props.procedures?.map((pro) => ({
        label: pro.simpleName,
        value: pro.cdtCode,
        data: pro,
      })) || [],
    [props.procedures]
  );

  return props.mode === "single" ? (
    <ProcedureSelectorSingleSelect codes={codes} handleFilterOption={handleFilterOption} {...props} />
  ) : (
    <ProcedureSelectorMultiSelect codes={codes} handleFilterOption={handleFilterOption} {...props} />
  );
};
