import React, { useCallback } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { MedicalImageVO, MountVO, PatientSummaryVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { getFullUrl } from "@libs/utils/location";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ImageLabelTeethModal } from "components/PatientProfile/Imaging/MountRoute/TeethLabeling/ImageLabelTeethModal";

import { getInfiniteMountsQuery, getMount, getPracticeImagingSettings } from "api/imaging/queries";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";
import { paths } from "utils/routing/paths";
import { useAppTheme } from "hooks/useAppTheme";
import { FullScreenPortal } from "components/PatientProfile/Imaging/shared/FullScreenPortal";
import { LoadingDarkRoom } from "components/PatientProfile/Imaging/LoadingDarkRoom";
import { useItemModal } from "hooks/useItemModal";
import { getPatientSummary } from "api/patients/queries";
import { MountImageSidebarRoute } from "components/PatientProfile/Imaging/MountRoute/MountImageSidebarRoute";
import { ImageListContextProvider } from "components/PatientProfile/Imaging/shared/ImageListContext";
import { ImageSandboxRoute } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ImageSandboxRoute";
import {
  MountContextProvider,
  useMountContext,
} from "components/PatientProfile/Imaging/MountRoute/MountContext";

export const LoadedMountPage: React.FC<{
  imageMount: MountVO;
  patient: PatientSummaryVO;
  isOnboardedWithPearl: boolean;
  mountsInfiniteQuery: UseInfiniteApiQueryResult<MountVO[]>;
  onEditImage: (params: Pick<MedicalImageVO, "id">) => void;
}> = ({ imageMount, patient, onEditImage, mountsInfiniteQuery, isOnboardedWithPearl }) => {
  const labelTeeth = useItemModal<MedicalImageVO>(null);
  const params = usePathParams("mountDetails");
  const { patientId } = params;
  const { query } = useQueryParams("mountDetails");
  const { format: mountFormat, hideMeta, showArchived, selectedImageIds } = query;

  const { imageEditQueue } = useMountContext();
  const { flushMountChanges } = imageEditQueue;

  useAppTheme("dark");

  const isShowingMetadata = !hideMeta;
  const handleEditImage = useCallback(
    (image: Pick<MedicalImageVO, "id">) => {
      flushMountChanges();
      onEditImage(image);
    },
    [flushMountChanges, onEditImage]
  );
  const sharedImageListProps = {
    isShowingMetadata,
    imageMount,
    patientId,
    onEditImage: handleEditImage,
    onClickTeeth: labelTeeth.open,
    isOnboardedWithPearl,
    patient,
    mountsInfiniteQuery,
  };

  return (
    <>
      {labelTeeth.isOpen && (
        <ImageLabelTeethModal
          image={labelTeeth.item}
          onClose={labelTeeth.close}
          patientId={patientId}
          mountId={imageMount.id}
        />
      )}
      {mountFormat === "sandbox" ? (
        <ImageSandboxRoute
          {...sharedImageListProps}
          showArchived={showArchived}
          selectedImageIds={selectedImageIds}
        />
      ) : (
        <MountImageSidebarRoute {...sharedImageListProps} {...query} />
      )}
    </>
  );
};

export const MountRoute: React.FC = (props) => {
  const { practiceId } = useAccount();
  const { query } = useQueryParams("mountDetails");
  const { patientId, mountId } = usePathParams("mountDetails");
  const mountsInfiniteQuery = useInfiniteApiQuery(
    getInfiniteMountsQuery({
      args: { pageSize: PAGE_SIZE, pageNumber: 1, patientId, practiceId },
    })
  );
  const [mountQuery, patientQuery, imagingSettingsQuery] = useApiQueries([
    getMount({
      args: {
        practiceId,
        patientId,
        mountId,
        query: {
          includeArchived: true,
        },
      },
    }),
    getPatientSummary({ args: { patientId, practiceId } }),
    getPracticeImagingSettings({ args: { practiceId } }),
  ]);
  const navigate = useNavigate();
  const location = useLocation();
  const handleEditImage = useCallback(
    (image: Pick<MedicalImageVO, "id">) => {
      navigate(
        paths.imagingEditImage(
          { patientId },
          { mountId, selectedImageId: image.id, from: getFullUrl(location), showArchived: query.showArchived }
        )
      );
    },
    [location, mountId, navigate, patientId, query.showArchived]
  );

  return (
    <FullScreenPortal isDark>
      <ImageListContextProvider>
        <QueryResult queries={[mountQuery, patientQuery]} loading={<LoadingDarkRoom />}>
          {mountQuery.data && patientQuery.data && (
            <MountContextProvider
              imageMount={mountQuery.data}
              patientId={patientId}
              showArchived={query.showArchived}
            >
              <LoadedMountPage
                {...props}
                mountsInfiniteQuery={mountsInfiniteQuery}
                isOnboardedWithPearl={Boolean(imagingSettingsQuery.data?.dateOnboardedWithPearl)}
                onEditImage={handleEditImage}
                imageMount={mountQuery.data}
                patient={patientQuery.data}
              />
            </MountContextProvider>
          )}
        </QueryResult>
      </ImageListContextProvider>
    </FullScreenPortal>
  );
};
