import { useCallback, useMemo, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { PatientToothConditionVO, PatientToothVO, UpsertPatientToothRequest } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { Form } from "@libs/components/UI/Form";
import { ToothChartSelections } from "components/Charting/toothChartData";
import {
  mapToPosteriorSurfaces,
  PosteriorSurface,
  Surface,
  SurfaceSelection,
  surfaceSelectorToArray,
} from "components/Charting/toothSurfaces";
import { getToothConditionRequirements } from "api/charting/queries";

import { ToothConditionSurfaceOption } from "components/Charting/ToothConditionSurfaceOption";

interface Props {
  selectedTeeth: ToothChartSelections;
  teeth: PatientToothVO[];
  onApply: (update: UpsertPatientToothRequest[]) => void;
}

const WAIT_TO_UPDATE_SHOWN_MENU = 200;

const getSelectedTeethWithNames = (selections: ToothChartSelections, teeth: PatientToothVO[]) => {
  const selectedTeeth =
    selections.type === "NONE"
      ? []
      : selections.type === "ARCH"
        ? teeth.filter((tooth) => selections.value.has(tooth.arch))
        : selections.type === "QUADRANT"
          ? teeth.filter((tooth) => selections.value.has(tooth.quadrant))
          : [...selections.value].map((toothNum) => teeth.find((tooth) => tooth.toothNum === toothNum));

  return selectedTeeth.filter((tooth) => tooth?.toothName).filter(isDefined);
};

export const ToothConditionsMenu: React.FC<Props> = ({ selectedTeeth, teeth, onApply }) => {
  const ref = useRef<HTMLDivElement | null>(null);
  const [conditionsQuery] = useApiQueries([getToothConditionRequirements()]);

  const selections = useMemo(() => getSelectedTeethWithNames(selectedTeeth, teeth), [selectedTeeth, teeth]);

  const { conditionOptions, conditionSurfaceOptions } = useMemo(() => {
    const options: { value: PatientToothConditionVO["condition"]; label: string }[] = [];
    const surfaceOptions: {
      value: PatientToothConditionVO["condition"];
      label: string;
    }[] = [];

    if (conditionsQuery.data) {
      for (const condition of conditionsQuery.data) {
        if (condition.surfaceRequired) {
          surfaceOptions.push({
            value: condition.condition,
            label: condition.displayName,
          });
        } else {
          options.push({
            value: condition.condition,
            label: condition.displayName,
          });
        }
      }
    }

    return {
      conditionOptions: options,
      conditionSurfaceOptions: surfaceOptions,
    };
  }, [conditionsQuery.data]);

  const { selectedConditions, conditionSurfaceSelections } = useMemo(() => {
    const conditionCount: Partial<Record<PatientToothConditionVO["condition"], number>> = {};
    const conditionSurfaceCount: Partial<
      Record<
        PatientToothConditionVO["condition"],
        { count: number; posteriorSurfaces: PosteriorSurface[]; surface: Surface[] }
      >
    > = {};
    const conditionSurfaceMap: Partial<Record<PatientToothConditionVO["condition"], Surface[]>> = {};

    for (const tooth of selections) {
      for (const condition of tooth.conditions) {
        if (condition.surfaces?.length) {
          const surfaceCount = conditionSurfaceCount[condition.condition];
          const posteriorSurfaces = mapToPosteriorSurfaces(condition.surfaces);

          if (surfaceCount === undefined) {
            conditionSurfaceCount[condition.condition] = {
              surface: condition.surfaces,
              posteriorSurfaces,
              count: 1,
            };
          } else if (surfaceCount.posteriorSurfaces.join("") === posteriorSurfaces.join("")) {
            conditionSurfaceCount[condition.condition] = {
              ...surfaceCount,
              count: surfaceCount.count + 1,
            };
          }
        } else {
          const currentCount = conditionCount[condition.condition];

          conditionCount[condition.condition] = currentCount === undefined ? 1 : currentCount + 1;
        }
      }
    }

    const checkedConditions = Object.keys(conditionCount).filter(
      (conditionName) =>
        conditionCount[conditionName as PatientToothConditionVO["condition"]] === selections.length
    ) as PatientToothConditionVO["condition"][];

    const conditionSurfaces = Object.keys(conditionSurfaceCount) as PatientToothConditionVO["condition"][];

    for (const conditionSurface of conditionSurfaces) {
      const info = conditionSurfaceCount[conditionSurface];

      if (info?.count === selections.length) {
        conditionSurfaceMap[conditionSurface] = info.surface;
      }
    }

    return {
      selectedConditions: new Set<PatientToothConditionVO["condition"]>(checkedConditions),
      conditionSurfaceSelections: conditionSurfaceMap,
    };
  }, [selections]);

  const handleToggleCondition = useCallback(
    (condition: PatientToothConditionVO["condition"], include: boolean) => {
      const updatedTeeth = selections.map((tooth) => {
        const updateConditions =
          include && !tooth.conditions.some((c) => c.condition === condition)
            ? [...tooth.conditions, { condition }]
            : tooth.conditions.filter((c) => c.condition !== condition);

        return {
          state: tooth.state,
          toothNum: tooth.toothNum,
          conditions: updateConditions,
        };
      });

      onApply(updatedTeeth);
    },
    [selections, onApply]
  );

  const handleToggleSurfaceCondition = useCallback(
    (condition: PatientToothConditionVO["condition"], surfaceSelection: SurfaceSelection) => {
      const updatedTeeth = selections.map((tooth) => {
        const surfaces = surfaceSelectorToArray(surfaceSelection, tooth.position);
        let updateConditions: UpsertPatientToothRequest["conditions"] = [];

        if (surfaces.length) {
          updateConditions = tooth.conditions.some((c) => c.condition === condition)
            ? tooth.conditions.map((toothCondition) =>
                toothCondition.condition === condition
                  ? {
                      ...toothCondition,
                      surfaces,
                    }
                  : toothCondition
              )
            : [...tooth.conditions, { condition, surfaces }];
        } else {
          updateConditions = tooth.conditions.filter((c) => c.condition !== condition);
        }

        return {
          state: tooth.state,
          toothNum: tooth.toothNum,
          conditions: updateConditions,
        };
      });

      onApply(updatedTeeth);
    },
    [selections, onApply]
  );

  const [selectedMenu, setSelectedMenu] = useState<PatientToothConditionVO["condition"]>();
  const updateMenu = useDebouncedCallback(setSelectedMenu, WAIT_TO_UPDATE_SHOWN_MENU, {
    leading: false,
    trailing: true,
  });

  return (
    <div ref={ref} className="py-4">
      <QueryResult
        queries={[conditionsQuery]}
        loading={
          <div className="px-4 min-h-28">
            <LoadingContent />
          </div>
        }
      >
        <Form className="px-4">
          <CheckboxList
            label="Add Condition"
            layout="custom"
            optionListClassName="columns-3 space-y-4 pt-4 pb-2"
            verticalLayout="normal"
            selectedValues={selectedConditions}
            onChange={(newSet, _event, option) =>
              handleToggleCondition(option.value, newSet.has(option.value))
            }
            optionClassName="whitespace-nowrap"
            options={conditionOptions}
          >
            {conditionSurfaceOptions.map((option) => (
              <ToothConditionSurfaceOption
                key={option.value}
                menuPortalTarget={ref.current}
                onMouseEnter={updateMenu}
                onMouseLeave={() => updateMenu(undefined)}
                isOpen={selectedMenu === option.value}
                option={option}
                onToggleSurfaceCondition={handleToggleSurfaceCondition}
                selections={conditionSurfaceSelections}
              />
            ))}
          </CheckboxList>
        </Form>
      </QueryResult>
    </div>
  );
};
