import React from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { DashboardLayout } from "components/Dashboard/DashboardLayout";
import { DashboardPageHeader } from "components/Dashboard/DashboardPageHeader";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { getTimeSeriesQuery } from "api/reporting/queries";
import { DashboardAdjustmentsQuery } from "utils/routing/dashboard";
import { AdjustmentsCharts } from "components/Dashboard/Adjustments/AdjustmentsCharts";
import { AdjustmentTables } from "components/Dashboard/Adjustments/AdjustmentsTables";
import { useSelectedTimeWindowQueries } from "components/Dashboard/Adjustments/hooks/useSelectedTimeWindowQueries";

export const AdjustmentsRoute: React.FC = () => {
  const { query, updateQuery } = useQueryParams("dashboardAdjustments");
  const { practiceId } = useAccount();
  const { tableTab } = usePathParams("dashboardAdjustments");

  const dateWindow = useTimeSeriesPageSelections(query);

  const {
    carrierAdjustmentsForSelectedTimePeriodQuery,
    collectionAdjustmentsForSelectedTimePeriodQuery,
    carriersQuery,
    adjustmentTypesQuery,
    invalidTimeSeriesParamsMessage,
    timeSeriesParams,
  } = useSelectedTimeWindowQueries();
  const globalTimeWindowParams = {
    resolution: query.resolution,
    ...timeSeriesParams,
  };
  const [collectionAdjustmentTimeseriesQuery, insuranceAdjustmentTimeseriesQuery] = useApiQueries([
    getTimeSeriesQuery({
      args: {
        practiceId,
        data: {
          ...globalTimeWindowParams,
          filters: [],
          metric: "collectionAdjustmentAmount",
        },
      },
      queryOptions: {
        enabled: !invalidTimeSeriesParamsMessage,
      },
    }),
    getTimeSeriesQuery({
      args: {
        practiceId,
        data: {
          ...globalTimeWindowParams,
          filters: [],
          metric: "insuranceAdjustmentAmount",
        },
      },
      queryOptions: {
        enabled: !invalidTimeSeriesParamsMessage,
      },
    }),
  ]);

  const handleRouteStateChange = React.useCallback(
    (updates: Partial<DashboardAdjustmentsQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );

  return (
    <DashboardLayout
      header={
        <>
          <DashboardPageHeader
            question="adjustments"
            focusDate={query.focusDate}
            resolution={query.resolution}
            dateWindow={dateWindow}
            chartDisplay={query.chartDisplay}
            onUpdateParams={handleRouteStateChange}
          />
          {!query["table.fullScreen"] && (
            <AdjustmentsCharts
              dateWindow={dateWindow}
              collectionAdjustmentTimeseriesQuery={collectionAdjustmentTimeseriesQuery}
              insuranceAdjustmentTimeseriesQuery={insuranceAdjustmentTimeseriesQuery}
              carrierAdjustmentsForSelectedTimePeriodQuery={carrierAdjustmentsForSelectedTimePeriodQuery}
              adjustmentTypesQuery={adjustmentTypesQuery}
              collectionAdjustmentsForSelectedTimePeriodQuery={
                collectionAdjustmentsForSelectedTimePeriodQuery
              }
              disabledMessage={invalidTimeSeriesParamsMessage}
              params={query}
              onUpdateParams={handleRouteStateChange}
              tableTabSelected={tableTab}
              allCarriersQuery={carriersQuery}
            />
          )}
        </>
      }
      className="min-w-[1400px]"
    >
      <AdjustmentTables />
    </DashboardLayout>
  );
};
