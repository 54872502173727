import { Route } from "react-router-dom";
import { FC } from "react";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { PatientProfile } from "components/PatientProfile/PatientProfile";
import { PatientsRoute } from "components/Patients/PatientsRoute";
import { MainAppHistoryProvider } from "components/Main/MainLinksContext";

export const PatientsRoutes = [
  {
    index: true,
    element: <PatientsRoute />,
  },
  {
    path: ":patientId/*",
    element: <PatientProfile />,
  },
];

export const PatientsApp: FC = () => {
  return (
    <MainAppHistoryProvider name="patients">
      <SentryRoutes>
        {PatientsRoutes.map((routeParams, i) => (
          <Route {...routeParams} key={i} />
        ))}
      </SentryRoutes>
    </MainAppHistoryProvider>
  );
};
