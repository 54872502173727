import { cx } from "@libs/utils/cx";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as RefreshIcon } from "@libs/assets/icons/refresh.svg";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";
import { useAppointmentDurationContext } from "components/ScheduleAppointments/AppointmentDurationContext";

interface Props {
  disabled: boolean;
}

const DEFAULT_RIGHT_PX = 58;
const INPUT_CHARACTER_WIDTH_PX = 6;
const getMinPosition = (value: number) => {
  return `${DEFAULT_RIGHT_PX - `${value}`.length * INPUT_CHARACTER_WIDTH_PX}px`;
};

export const DurationField = ({ disabled }: Props) => {
  const duration = useAppointmentDurationContext();

  return (
    <FormFieldNumberInput
      disabled={disabled}
      layout="labelOut"
      label="Appt Duration"
      required
      onValueChange={duration.update}
      value={duration.editable}
      min={0}
      max={1440}
    >
      <div
        style={{
          right: getMinPosition(duration.read),
        }}
        className={cx(
          `text-sm
           leading-4
           absolute
           top-[calc(50%-0.5px)]
           -translate-y-1/2`,
          disabled && "text-greyLight"
        )}
      >
        min
      </div>
      <ButtonIcon
        className="absolute -translate-y-1/2 top-1/2 right-2"
        onClick={duration.calculate}
        tooltip={{
          content: <div className="w-24">Recalculate appt duration per procedures</div>,
          theme: "MEDIUM",
          placement: "top",
        }}
        disabled={disabled}
        SvgIcon={RefreshIcon}
        theme="primary"
      />
    </FormFieldNumberInput>
  );
};
