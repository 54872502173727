import { LabVO } from "@libs/api/generated-api";
import { phoneNumberFormatter } from "@libs/utils/phone";
import { useBoolean } from "@libs/hooks/useBoolean";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as NoteIcon } from "@libs/assets/icons/note-filled.svg";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as ArchiveIcon } from "@libs/assets/icons/archive.svg";
import { ReactComponent as UnarchiveIcon } from "@libs/assets/icons/unarchive.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import {
  ButtonCell,
  EMPTY_CELL,
  HeaderCell,
  IconsCell,
  Row,
  TableGrid,
  TextCell,
} from "@libs/components/UI/GridTableComponents";

import { LabFormIcon } from "components/LabCases/LabComponents";
import { LabInfoCard } from "components/LabCases/LabInfoCard";

export const LabsTable: React.FC<{
  labs: LabVO[];
  onEdit: (labUuid: LabVO["uuid"]) => void;
  onArchive: (labUuid: LabVO["uuid"]) => void;
  onUnarchive: (labUuid: LabVO["uuid"]) => void;
}> = ({ labs, onEdit, onArchive, onUnarchive }) => {
  return (
    <TableGrid columnWidths={["416px", "176px", "176px", "1fr", "auto"]}>
      <HeaderCell size="short">Name</HeaderCell>
      <HeaderCell size="short">Phone</HeaderCell>
      <HeaderCell size="short">Est Case Time</HeaderCell>
      <HeaderCell size="short" />
      <HeaderCell size="short" />
      {labs.map((lab) => (
        <Row highlightOnHover key={lab.uuid}>
          <ButtonCell
            onDoubleClick={() => onEdit(lab.uuid)}
            verticalPadding="slim"
            className="flex items-center h-10"
          >
            <FloatingTooltip content={<LabInfoCard lab={lab} />}>
              <div>{lab.name}</div>
            </FloatingTooltip>
          </ButtonCell>
          <ButtonCell
            onDoubleClick={() => onEdit(lab.uuid)}
            verticalPadding="slim"
            className="flex items-center h-10"
          >
            {lab.phone ? phoneNumberFormatter(lab.phone) : EMPTY_CELL}
          </ButtonCell>
          <ButtonCell
            onDoubleClick={() => onEdit(lab.uuid)}
            verticalPadding="slim"
            className="flex items-center h-10"
          >
            {lab.estimatedCaseTimeInDays ? `${lab.estimatedCaseTimeInDays} days` : EMPTY_CELL}
          </ButtonCell>
          <TextCell
            onDoubleClick={() => onEdit(lab.uuid)}
            verticalPadding="slim"
            className="flex items-center h-10 gap-x-4"
          >
            <div className="w-5">{lab.hasOrderFormFile ? <LabFormIcon labUuid={lab.uuid} /> : undefined}</div>
            <div className="w-5">{lab.notes ? <LabNotesIcon notes={lab.notes} /> : undefined}</div>
          </TextCell>
          <IconsCell verticalPadding="slim" className="flex items-center h-10">
            {lab.isArchived ? (
              <UnarchiveButton onClick={() => onUnarchive(lab.uuid)} />
            ) : (
              <Menu onArchiveClick={() => onArchive(lab.uuid)} onEditClick={() => onEdit(lab.uuid)} />
            )}
          </IconsCell>
        </Row>
      ))}
    </TableGrid>
  );
};

const UnarchiveButton: React.FC<{ onClick: Func }> = ({ onClick }) => {
  return (
    <ButtonIcon
      size="md"
      SvgIcon={UnarchiveIcon}
      tooltip={{ content: "Unarchive", theme: "SMALL" }}
      onClick={onClick}
    />
  );
};

const Menu: React.FC<{ onArchiveClick: Func; onEditClick: Func }> = ({
  onArchiveClick: onArchive,
  onEditClick: onEdit,
}) => {
  const menu = useBoolean(false);

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      menuContent={
        <MenuOptions
          options={createMenuOptions(
            { label: "Edit", value: "edit", SvgIcon: EditIcon },
            { label: "Archive", value: "archive", SvgIcon: ArchiveIcon }
          )}
          onOptionClick={(option) => {
            switch (option.value) {
              case "archive": {
                onArchive();
                break;
              }
              case "edit": {
                onEdit();
                break;
              }
              // no default
            }
          }}
        />
      }
      onRequestClose={menu.off}
    >
      {(props) => {
        return <ButtonIcon {...props} size="md" SvgIcon={MenuIcon} />;
      }}
    </ButtonMenu>
  );
};

const LabNotesIcon: React.FC<{ notes: string }> = ({ notes }) => {
  return (
    <FloatingTooltip content={notes} theme="MEDIUM" placement="left">
      <NoteIcon className="h-5 w-5" />
    </FloatingTooltip>
  );
};
