import { FC } from "react";
import { PayrollLineItemVO, PayrollVO, SubLineItemsVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatCurrency } from "@libs/utils/currency";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { isDefined } from "@libs/utils/types";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as SkipIcon } from "@libs/assets/icons/skip.svg";
import { ReactComponent as PreviewIcon } from "@libs/assets/icons/preview-file.svg";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { formatNumberWithDecimalPlaces } from "@libs/utils/formatNumber";
import { ButtonCell, TextCell, HeaderCell, Row, TableGrid } from "@libs/components/UI/GridTableComponents";
import { useItemModal } from "hooks/useItemModal";
import { PayrollEmployeeCell } from "components/Payroll/PayrollEmployeeCell";
import { PtoModal } from "components/Payroll/PtoModal";
import { EarningsModal } from "components/Payroll/EarningsModal";
import { DeductionsModal } from "components/Payroll/DeductionsModal";
import { TimeSheetModal } from "components/Payroll/TimeSheetModal";

interface Props {
  payroll: PayrollVO;
  showDoubleOvertime: boolean;
  onToggleSkip: (lineItem: PayrollLineItemVO) => void;
  onUpdateSubLineItems: (lineItem: PayrollLineItemVO, updates: Partial<SubLineItemsVO>) => void;
}

const getHeaders = (showDoubleOvertime: boolean) =>
  [
    { id: "employee", label: "Employee", width: "14rem" },
    { id: "payRate", label: "Pay Rate", width: "1fr" },
    { id: "regHours", label: "Reg Hours", width: "1fr" },
    { id: "overtime", label: "Overtime", width: "1fr" },
    showDoubleOvertime ? { id: "doubleOvertime", label: "Double OT", width: "1fr" } : null,
    { id: "status", label: "Status", width: "1fr" },
    { id: "pto", label: "Paid Time Off", width: "min-content" },
    { id: "addtlEarnings", label: "Addtl Earnings", width: "min-content" },
    { id: "deductions", label: "1x Post Tax Ded", width: "min-content" },
    { id: "pay", label: "Gross Pay", width: "1fr" },
    { id: "skip", label: "", width: "min-content" },
    { id: "edit", label: "", width: "min-content" },
  ].filter(isDefined);

const payPeriodMap: Record<PayrollLineItemVO["compensationPeriod"], string> = {
  ["PER_DIEM"]: "day",
  ["PER_HOUR"]: "hr",
  ["PER_MONTH"]: "mo",
  ["PER_YEAR"]: "year",
};

const statusColorClassName: Record<PayrollLineItemVO["status"], string> = {
  APPROVED: "text-green",
  PENDING: "text-orange",
};

const formatPto = (pto: PayrollLineItemVO["subLineItems"]["paidTimeOff"]) => {
  return pto.length
    ? pto.map((ptoItem) => ({
        name: sentenceCaseConstant(ptoItem.type),
        value: formatNumberWithDecimalPlaces(ptoItem.hours),
      }))
    : [{ name: "", value: formatNumberWithDecimalPlaces(0), isDefault: true }];
};

const formatEarnings = (earnings: PayrollLineItemVO["subLineItems"]["additionalEarnings"]) => {
  return earnings.length
    ? earnings.map((earning) => ({
        name: sentenceCaseConstant(earning.type),
        value: formatCurrency(earning.amount),
      }))
    : [{ name: "", value: formatCurrency(0), isDefault: true }];
};

const formatDeductions = (deductions: PayrollLineItemVO["subLineItems"]["postTaxDeductions"]) => {
  return deductions.length
    ? deductions.map((deduction) => ({
        name: deduction.name,
        value: formatCurrency(deduction.amount),
        hasError: Boolean(deduction.errors.length),
      }))
    : [{ name: "", value: formatCurrency(0), isDefault: true, hasError: false }];
};

const SubLineItems: FC<{
  items: { name: string; value: string; hasError?: boolean; isDefault?: boolean }[];
  disabled: boolean;
}> = ({ items, disabled }) => {
  const firstValue = items[0];
  const isDefault = "isDefault" in firstValue && firstValue.isDefault;

  return (
    <div>
      {items.map((item, index) => (
        <div
          className={cx("flex flex-nowrap items-center gap-x-1", item.hasError && "text-red")}
          key={`${item.name}-${index}`}
        >
          <div>{item.value}</div>
          <div>{item.name}</div>
          {item.hasError ? (
            <Icon
              disabled={disabled}
              theme="error"
              size="sm"
              tooltip={{
                content:
                  "This deduction was not processed successfully. Try resaving it or remove it to be able to run this payroll.",
              }}
              SvgIcon={ErrorIcon}
            />
          ) : null}

          {index === items.length - 1 ? (
            isDefault ? (
              <Icon
                disabled={disabled}
                theme="primary"
                size="sm"
                tooltip={{ content: "Add", theme: "SMALL" }}
                SvgIcon={AddIcon}
              />
            ) : (
              <Icon
                disabled={disabled}
                theme="primary"
                size="sm"
                tooltip={{ content: "Edit", theme: "SMALL" }}
                SvgIcon={EditIcon}
              />
            )
          ) : null}
        </div>
      ))}
    </div>
  );
};

export const PayrollLineItemsTable: FC<Props> = ({
  showDoubleOvertime,
  payroll,
  onToggleSkip,
  onUpdateSubLineItems,
}) => {
  const ptoModal = useItemModal<PayrollLineItemVO>(null);
  const earningsModal = useItemModal<PayrollLineItemVO>(null);
  const deductionsModal = useItemModal<PayrollLineItemVO>(null);
  const timeSheetModal = useItemModal<PayrollLineItemVO>(null);
  const lineItems = payroll.lineItems;
  const headers = getHeaders(showDoubleOvertime);

  return (
    <>
      <TableGrid
        className={cx(!lineItems.length && "h-full grid-rows-[min-content_1fr]")}
        columnWidths={headers.map(({ width }) => width)}
      >
        {headers.map((item) => (
          <HeaderCell key={item.id}>{item.label}</HeaderCell>
        ))}
        {lineItems.length ? (
          lineItems.map((lineItem, index) => (
            <Row
              className={cx(
                "*:flex *:items-center",
                lineItem.isSkipped && "*:bg-greyLightest *:text-greyLight"
              )}
              key={`${lineItem.employee.id}-${index}`}
            >
              <PayrollEmployeeCell lineItem={lineItem} />
              <TextCell>
                {formatCurrency(lineItem.compensationAmount)}/{payPeriodMap[lineItem.compensationPeriod]}
              </TextCell>
              <TextCell>{formatNumberWithDecimalPlaces(lineItem.regularHours)}</TextCell>
              <TextCell>{formatNumberWithDecimalPlaces(lineItem.overtimeHours)}</TextCell>
              {showDoubleOvertime ? (
                <TextCell>{formatNumberWithDecimalPlaces(lineItem.doubleOvertimeHours)}</TextCell>
              ) : null}
              <ButtonCell disabled={lineItem.isSkipped} onClick={() => timeSheetModal.open(lineItem)}>
                <span className={lineItem.isSkipped ? "text-red" : statusColorClassName[lineItem.status]}>
                  {lineItem.isSkipped ? "Skipped" : sentenceCaseConstant(lineItem.status)}
                </span>
              </ButtonCell>
              <ButtonCell disabled={lineItem.isSkipped} onClick={() => ptoModal.open(lineItem)}>
                <SubLineItems
                  disabled={lineItem.isSkipped}
                  items={formatPto(lineItem.subLineItems.paidTimeOff)}
                />
              </ButtonCell>
              <ButtonCell disabled={lineItem.isSkipped} onClick={() => earningsModal.open(lineItem)}>
                <SubLineItems
                  disabled={lineItem.isSkipped}
                  items={formatEarnings(lineItem.subLineItems.additionalEarnings)}
                />
              </ButtonCell>
              <ButtonCell disabled={lineItem.isSkipped} onClick={() => deductionsModal.open(lineItem)}>
                <SubLineItems
                  disabled={lineItem.isSkipped}
                  items={formatDeductions(lineItem.subLineItems.postTaxDeductions)}
                />
              </ButtonCell>
              <TextCell>{formatCurrency(lineItem.estimatedGrossPay)}</TextCell>
              <ButtonCell onClick={() => onToggleSkip(lineItem)}>
                {lineItem.isSkipped ? (
                  <Icon
                    className="-scale-x-100"
                    tooltip={{ content: "Un-Skip", theme: "SMALL" }}
                    SvgIcon={SkipIcon}
                    theme="primary"
                  />
                ) : (
                  <Icon theme="primary" tooltip={{ content: "Skip", theme: "SMALL" }} SvgIcon={SkipIcon} />
                )}
              </ButtonCell>
              <ButtonCell disabled={lineItem.isSkipped} onClick={() => timeSheetModal.open(lineItem)}>
                <Icon
                  theme="primary"
                  tooltip={{ content: "View", theme: "SMALL" }}
                  disabled={lineItem.isSkipped}
                  SvgIcon={PreviewIcon}
                />
              </ButtonCell>
            </Row>
          ))
        ) : (
          <div className="flex justify-center items-center col-span-6 text-sm">
            No employees are included in this payroll.
          </div>
        )}
      </TableGrid>
      {ptoModal.isOpen ? (
        <PtoModal
          pto={ptoModal.item.subLineItems.paidTimeOff}
          onClose={ptoModal.close}
          onSave={(updates) => onUpdateSubLineItems(ptoModal.item, { paidTimeOff: updates })}
        />
      ) : null}
      {earningsModal.isOpen ? (
        <EarningsModal
          earnings={earningsModal.item.subLineItems.additionalEarnings}
          onClose={earningsModal.close}
          onSave={(updates) => onUpdateSubLineItems(earningsModal.item, { additionalEarnings: updates })}
        />
      ) : null}
      {deductionsModal.isOpen ? (
        <DeductionsModal
          deductions={deductionsModal.item.subLineItems.postTaxDeductions}
          onClose={deductionsModal.close}
          onSave={(updates) => onUpdateSubLineItems(deductionsModal.item, { postTaxDeductions: updates })}
        />
      ) : null}
      {timeSheetModal.isOpen ? (
        <TimeSheetModal
          onClose={timeSheetModal.close}
          startDate={payroll.periodStartDate}
          endDate={payroll.periodEndDate}
          employee={timeSheetModal.item.employee}
          payrollId={payroll.uuid}
        />
      ) : null}
    </>
  );
};
