/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from "react";

import { ActionModal } from "components/UI/ActionModal";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";

type Props = {
  onClose: Func;
  onSave: (distanceInMm: number) => void;
};

export const CalibrationDistanceModal: React.FC<Props> = ({ onClose, onSave }) => {
  const [distance, setDistance] = React.useState<number | undefined>();

  return (
    <ActionModal
      size="xs"
      handleSave={() => {
        if (distance) {
          onSave(distance);
        }
      }}
      title="Calibrate"
      handleClose={onClose}
      primaryButtonText="Save"
      primaryButtonDisabled={!distance}
    >
      <div className="flex flex-col gap-4 text-xs">
        <div>Enter the actual length of this distance</div>
        <FormFieldNumberInput className="w-28 flex" value={distance} onValueChange={setDistance}>
          <div className="absolute right-2 bottom-2 text-xs">mm</div>
        </FormFieldNumberInput>
      </div>
    </ActionModal>
  );
};
