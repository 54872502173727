import React, { FC } from "react";
import { PracticeVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getPracticeDataQuery } from "api/practice/queries";
import { getEmployeeNames } from "api/employee/queries";
import { updatePractice } from "api/practice/mutations";
import { ContactDetailsForm } from "components/Settings/PracticeSetup/Sections/ContactDetailsForm";
import { handleError } from "utils/handleError";

interface Props {
  isEditing: boolean;
  onDoneEditing: Func;
}

export const ContactDetails: FC<Props> = ({ isEditing, onDoneEditing }) => {
  const { practiceId } = useAccount();
  const [practiceQuery, employeesQuery] = useApiQueries([
    getPracticeDataQuery({ args: { practiceId } }),
    getEmployeeNames({ args: { practiceId, statuses: ["ACTIVE"] } }),
  ]);

  const [updatePracticeMutation] = useApiMutations([updatePractice]);

  const handleSubmit = (updatedPractice: PracticeVO) => {
    updatePracticeMutation.mutate(
      {
        data: updatedPractice,
        practiceId,
      },
      {
        onSuccess: onDoneEditing,
        onError: handleError,
      }
    );
  };

  const commonProps =
    practiceQuery.data && employeesQuery.data
      ? {
          practice: practiceQuery.data,
          employees: employeesQuery.data,
          onCancel: onDoneEditing,
          onSubmit: handleSubmit,
          isSaving: updatePracticeMutation.isLoading,
        }
      : undefined;

  return (
    <QueryResult queries={[practiceQuery, employeesQuery]}>
      {commonProps ? (
        isEditing ? (
          <ContactDetailsForm key="edit" isEditing={true} {...commonProps} />
        ) : (
          <ContactDetailsForm key="read" isEditing={false} {...commonProps} />
        )
      ) : null}
    </QueryResult>
  );
};
