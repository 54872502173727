/* eslint-disable complexity */
import React from "react";
import { Route } from "react-router-dom";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { RecallReportByProcedureTableRoute } from "components/Dashboard/RecallReport/RecallReportByProcedureTableRoute";

import { RecallReportByPatientTableRoute } from "components/Dashboard/RecallReport/RecallReportByPatientTableRoute";

const TABLE_ROUTES = [
  {
    path: "patients",
    element: <RecallReportByPatientTableRoute />,
  },
  {
    path: "procedures",
    element: <RecallReportByProcedureTableRoute />,
  },
];

export const RecallReportTables: React.FC = () => {
  return (
    <div className="h-full w-full flex flex-col min-h-0">
      <SentryRoutes>
        {TABLE_ROUTES.map(({ element, path }) => {
          return <Route key={path} element={element} path={path} />;
        })}

        <Route path="*" element={<div className="text-center p-4">Table not found</div>} />
      </SentryRoutes>
    </div>
  );
};
