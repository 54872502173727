import React, { CSSProperties, useMemo } from "react";
import { MedicalImageVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { applySharpen } from "@libs/utils/math";
import { Spinner } from "@libs/components/UI/Spinner";
import { getFilters, sharpnessId } from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { LazyAnnotationContent } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/components/LazyAnnotationContent";
import { useImageListContext } from "components/PatientProfile/Imaging/shared/ImageListContext";
import { useCachedImage } from "components/PatientProfile/Imaging/hooks/useCachedImage";

type SharpenFilterProps = {
  sharpness?: number;
  imageId?: number;
};

export const SharpenFilter: React.FC<SharpenFilterProps> = ({ sharpness, imageId }) => {
  if (!sharpness || !imageId) {
    return null;
  }

  const kernelMatrix = applySharpen(sharpness);

  return (
    <svg className="hidden">
      <defs>
        <filter id={sharpnessId(imageId)}>
          <feConvolveMatrix order="3" kernelMatrix={kernelMatrix.join(" ")} />
        </filter>
      </defs>
    </svg>
  );
};

export type ImageContentProps<T> = {
  image: T;
  onLoad?: () => void;
  className?: string;
  style?: CSSProperties;
  imageClasses?: string;
  onAnnotationLoaded?: Func;
  disableCache?: boolean;
};

const ImageContentInner = <T extends MedicalImageVO>({
  onLoad,
  image,
  style,
  className,
  imageClasses,
  disableCache = false,
  onAnnotationLoaded,
}: ImageContentProps<T>) => {
  const { layersShown } = useImageListContext();
  const filter = useMemo(() => {
    return layersShown.has("filters") ? getFilters({ id: image.id, filters: image.filters }) : undefined;
  }, [image.filters, image.id, layersShown]);
  const cxImageClasses = cx("object-contain", imageClasses);

  // Cache is disabled for images that are uploading, because they're not yet properly keyed by id, as they haven't yet been assigned a server id
  const imageUrl = useCachedImage({ image, enabled: !disableCache });

  return (
    <div className={cx("draggable", className)} style={style}>
      {imageUrl ? (
        <img
          onLoad={onLoad}
          style={{
            filter,
          }}
          className={cxImageClasses}
          crossOrigin="anonymous"
          src={disableCache ? image.url : imageUrl}
          alt={`Capture ${(image.i ?? 0) + 1}`}
        />
      ) : (
        <div className="w-full h-full flex items-center justify-center">
          <Spinner animation="border" variant="light" />
        </div>
      )}

      {layersShown.has("annotations") && (
        <LazyAnnotationContent
          className={cxImageClasses}
          image={image}
          onAnnotationLoaded={onAnnotationLoaded}
        />
      )}

      {layersShown.has("filters") && (
        <SharpenFilter imageId={image.id} sharpness={image.filters?.sharpness} />
      )}
    </div>
  );
};

export const ImageContent = React.memo(ImageContentInner);
