import { FC } from "react";
import { format } from "date-fns";
import { PayrollVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { formatCurrency } from "@libs/utils/currency";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { EMPTY_CELL, HeaderCell, Row, TableGrid, TextCell } from "@libs/components/UI/GridTableComponents";
import { getDeadlineDate } from "components/Payroll/utils";

interface Props {
  payroll: PayrollVO;
}

const titleTableColumns = [
  { width: "154px", text: "Total Payroll" },
  { width: "154px", text: "Debit Amount" },
  //   { width: "154px", text: "Debit Account" },
  { width: "154px", text: "Debit Date" },
  { width: "154px", text: "Employee Pay Date" },
];

export const PayrollTitleTable: FC<Props> = ({ payroll }) => {
  const practice = useCurrentPractice();

  return (
    <TableGrid
      className="rounded border border-greyLighter w-fit mt-6"
      columnWidths={titleTableColumns.map(({ width }) => width)}
    >
      {titleTableColumns.map(({ text }) => (
        <HeaderCell size="slim" key={text}>
          {text}
        </HeaderCell>
      ))}
      <Row highlightOnHover={false}>
        <TextCell verticalPadding="slim" className="-mb-px">
          {formatCurrency(payroll.breakdown?.totalAmount ?? 0)}
        </TextCell>
        <TextCell verticalPadding="slim" className="-mb-px">
          {formatCurrency(payroll.breakdown?.debitAmount ?? 0)}
        </TextCell>
        {/* <TextCell verticalPadding="slim" className="-mb-px">
          {EMPTY_CELL}
        </TextCell>
         */}
        <TextCell verticalPadding="slim" className="-mb-px">
          {payroll.payrollDeadline
            ? format(getDeadlineDate(practice.timezoneId, payroll.payrollDeadline), "P")
            : EMPTY_CELL}
        </TextCell>
        <TextCell verticalPadding="slim" className="-mb-px">
          {payroll.employeePayDate ? formatISODate(payroll.employeePayDate) : EMPTY_CELL}
        </TextCell>
      </Row>
    </TableGrid>
  );
};
