import { FC, FormEvent, useState } from "react";
import { PatientProcedureVO, TreatmentPlanVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { Button } from "@libs/components/UI/Button";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { Pill } from "@libs/components/UI/Pill";
import { FormFieldMenu } from "@libs/components/UI/FormFieldMenu";
import { Form } from "@libs/components/UI/Form";
import { filterablePatientProcedureStatusOptions } from "components/Charting/patientProcedureFieldOptions";
import { TreatmentPlanMenuOptions } from "components/Charting/TreatmentPlanMenuOptions";

interface Props {
  onSelectTreatmentPlan: (txPlans: TreatmentPlanVO) => void;
  onSelectMasterTxPlan: Func;
  onUpdateStatusFilter: (statuses: PatientProcedureVO["status"][]) => void;
  patientProcedureStatuses: PatientProcedureVO["status"][];
  treatmentPlans: TreatmentPlanVO[] | undefined;
  archivedPlans: TreatmentPlanVO[] | undefined;
  treatmentPlanUuid: string | undefined;
}

const FilterStatusMenu: FC<{
  patientProcedureStatuses: PatientProcedureVO["status"][];
  onUpdateStatusFilter: (statuses: PatientProcedureVO["status"][]) => void;
}> = ({ patientProcedureStatuses, onUpdateStatusFilter }) => {
  const [statuses, setStatuses] = useState(() => new Set(patientProcedureStatuses));
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onUpdateStatusFilter([...statuses]);
  };

  return (
    <Form className="p-4" onSubmit={handleSubmit}>
      <CheckboxList
        layout="vert"
        onChange={setStatuses}
        options={filterablePatientProcedureStatusOptions}
        selectedValues={statuses}
      />
      <Button type="submit" className="w-full mt-4">
        Apply
      </Button>
    </Form>
  );
};

export const ProceduresFilters: FC<Props> = ({
  patientProcedureStatuses,
  onSelectTreatmentPlan,
  onSelectMasterTxPlan,
  onUpdateStatusFilter,
  treatmentPlanUuid,
  treatmentPlans,
  archivedPlans,
}) => {
  const currentPlan = [...(treatmentPlans ?? []), ...(archivedPlans ?? [])].find(
    (tp) => tp.uuid === treatmentPlanUuid
  );

  return (
    <div className="flex items-center gap-x-3">
      <FormFieldMenu
        buttonClassName="w-60"
        buttonContent={
          <div className="font-sansSemiBold truncate">{currentPlan?.name ?? "Master Tx Plan"}</div>
        }
        menuContent={(txTableMenu) => (
          <div className="text-xs p-4">
            <button
              type="button"
              className={cx(
                "hover:text-primaryTheme",
                !treatmentPlanUuid && "text-primaryTheme font-sansSemiBold"
              )}
              onClick={() => {
                txTableMenu.off();
                onSelectMasterTxPlan();
              }}
            >
              Master Tx Plan
            </button>
            <div className="flex gap-x-8 mt-4">
              <TreatmentPlanMenuOptions
                title="Alternate"
                txPlans={treatmentPlans}
                emptyContent={<Pill theme="grey">None Created</Pill>}
                onSelectTxPlan={(txPlan) => {
                  txTableMenu.off();
                  onSelectTreatmentPlan(txPlan);
                }}
              />
              {archivedPlans?.length ? (
                <TreatmentPlanMenuOptions
                  title="Archived"
                  txPlans={archivedPlans}
                  onSelectTxPlan={(txPlan) => {
                    txTableMenu.off();
                    onSelectTreatmentPlan(txPlan);
                  }}
                />
              ) : null}
            </div>
          </div>
        )}
      />

      <FormFieldMenu
        matchTriggerWidth
        buttonContent="Treatment Type"
        buttonClassName="w-60"
        menuContent={(statusFilterMenu) => (
          <div data-testid="tx-type-checkbox-menu">
            <FilterStatusMenu
              patientProcedureStatuses={patientProcedureStatuses}
              onUpdateStatusFilter={(statuses) => {
                statusFilterMenu.off();
                onUpdateStatusFilter([...statuses]);
              }}
            />
          </div>
        )}
      />
    </div>
  );
};
