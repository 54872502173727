import { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { LedgerV2ClaimSubEntryVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getFullUrl } from "@libs/utils/location";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { paths } from "utils/routing/paths";
import { RoleGuardHide } from "components/Main/RoleGuard";

export const ClaimMenu: FC<{ claimUuid: LedgerV2ClaimSubEntryVO["claimUuid"] }> = ({ claimUuid }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <ClaimMenuDropdown onView={() => navigate(paths.claim({ claimUuid }, { from: getFullUrl(location) }))} />
  );
};

const ClaimMenuDropdown: FC<{ onView: Func }> = ({ onView }) => {
  const menu = useBoolean(false);

  return (
    <RoleGuardHide domain="CLAIMS" action="ACCESS_ALL">
      <ButtonMenu
        isOpen={menu.isOn}
        onRequestOpen={menu.on}
        placement="bottom-start"
        menuContent={
          <MenuOptionButton
            onClick={() => {
              onView();
              menu.off();
            }}
          >
            View Claim
          </MenuOptionButton>
        }
        onRequestClose={menu.off}
      >
        {(props) => <ButtonIcon {...props} size="md" SvgIcon={MenuIcon} />}
      </ButtonMenu>
    </RoleGuardHide>
  );
};
