import { Icon } from "@libs/components/UI/Icon";
import { Spinner } from "@libs/components/UI/Spinner";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { ImageContent } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ImageContent";
import {
  ReservedLayoutItem,
  ReservedStatus,
} from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";
import { useContainedTransforms } from "components/PatientProfile/Imaging/hooks/useContainedTransforms";

export const ImageUploading: React.FC<{
  image: ReservedLayoutItem;
  bounds: { width: number; height: number };
  children: React.ReactNode;
}> = ({ image, bounds, children }) => {
  const stylesWithRotation = useContainedTransforms(bounds, image, { canExceedImageSize: false });

  return (
    <div className="flex flex-col border border-greyLight" style={stylesWithRotation.baseStyles}>
      <div className="flex h-full justify-center items-center">
        {image.url && <ImageContent image={image} disableCache />}
        <div className="absolute inset-0 opacity-75 bg-black" />
        {image.reservation.status === ReservedStatus.Uploading && (
          <Spinner variant="light" animation="border" size="md" className="absolute" />
        )}
        {image.reservation.status === ReservedStatus.Failed && (
          <div
            className={`
              absolute
              inset-0
              flex
              flex-col
              justify-center
              items-center
              text-white
              text-xs
              gap-2
            `}
          >
            <Icon SvgIcon={InfoIcon} />
            <div>Upload Failed</div>
            <button type="button" className="text-white underline" onClick={image.uploadImage}>
              Retry
            </button>
          </div>
        )}
      </div>
      {children}
    </div>
  );
};
