import { InsuranceCarrierVO } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { SelectCarriers } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/SelectCarriers";
import { InsurancePlansQuery, InsurancePlansQueryUpdates } from "utils/routing/settings";

interface Props {
  carriersQuery: ApiQueryResult<InsuranceCarrierVO[]>;
  onUpdateFilters: (keyValues: InsurancePlansQueryUpdates) => void;
  queryStateApi: InsurancePlansQuery;
}

export const FiltersFlyoverContent: React.FC<Props> = ({ carriersQuery, onUpdateFilters, queryStateApi }) => {
  return (
    <div className="flex flex-col gap-y-6">
      {carriersQuery.data && (
        <SelectCarriers
          carriersQuery={carriersQuery}
          onSelect={(carrierIds) => onUpdateFilters({ carrierIds })}
          selectedCarrierIds={new Set(queryStateApi.carrierIds)}
        />
      )}
      <div className="flex flex-col gap-y-2">
        <FormFieldLabel className="font-sansSemiBold" content="Patients" />
        <Checkbox
          onChange={() => {
            onUpdateFilters({ hidePlansWithNoPatients: !queryStateApi.hidePlansWithNoPatients });
          }}
          checked={queryStateApi.hidePlansWithNoPatients}
        >
          Hide plans with no patients
        </Checkbox>
      </div>
    </div>
  );
};
