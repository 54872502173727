import React from "react";
import {
  AppointmentBookingResponse,
  ProviderRollupEntryVO,
  DentalProcedureVO,
} from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { ApiQueryResult, UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { AppointmentsCompletedQuery, DashboardQueryWithDateNavigation } from "utils/routing/dashboard";
import { FooterCell } from "components/Dashboard/Tables/FooterCell";
import { useFlattenEntries } from "components/Dashboard/hooks/useFlattenEntries";
import { getTotals } from "components/Dashboard/Tables/utils";
import { DashboardTableFilters } from "components/Dashboard/Tables/DashboardTableFilters";
import { useFilterTokenProps } from "components/Dashboard/hooks/useFilterTokenProps";

import { SelectPatientType } from "components/Dashboard/Tables/SelectPatientType";
import { ProviderFilterOption } from "components/Dashboard/hooks/useProviderRollupQueries";
import { TableSelectProvider } from "components/Dashboard/Tables/TableSelectProvider";
import { AppointmentBookingTableRow } from "components/Dashboard/Tables/AppointmentBooking/AppointmentBookingTableRow";
import { AppointmentBookingType } from "components/Dashboard/AppointmentsCompleted/types";
import { APPT_BOOKING_COLUMNS } from "components/Dashboard/Tables/AppointmentBooking/constants";
import { TableSelectProcedureFilters } from "components/Dashboard/Tables/TableSelectProcedureFilters";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import { DashboardFilterFormFieldSelect } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";
import { DashboardTableHeader } from "components/Dashboard/Tables/DashboardTableHeader";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";
import { SelectHasNextApptFilter } from "components/Dashboard/Tables/SelectHasNextApptFilter";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";

const STATUSES: AppointmentBookingType[] = ["SCHEDULED", "CANCELED", "COMPLETED", "NO_SHOW"];

const APPT_STATUSES = STATUSES.map((value) => ({ value, label: sentenceCaseConstant(value) }));

type ApptBookingFilterType = "appointmentState" | "patientType" | "hasNextAppointment";

export const AppointmentBookingTable: React.FC<{
  tableExpanded: boolean;
  onUpdateParams: (newParams: Partial<AppointmentsCompletedQuery>) => void;
  filtersAvailable?: ApptBookingFilterType[];
  onClickDownload: Func;
  query: DashboardQueryWithDateNavigation & { tableSort: ColumnSortOrder[] };
  selectedProvider?: ProviderRollupEntryVO;
  providerFilterOptions: ProviderFilterOption[];
  debouncedSearch: string;
  isDownloadingTable: boolean;
  patientSearch?: string;
  allDentalProceduresQuery: ApiQueryResult<DentalProcedureVO[]>;
  appointmentBookingInfiniteQuery: UseInfiniteApiQueryResult<AppointmentBookingResponse>;

  // eslint-disable-next-line complexity
}> = ({
  appointmentBookingInfiniteQuery,
  onUpdateParams,
  isDownloadingTable,
  providerFilterOptions,
  allDentalProceduresQuery,
  tableExpanded,
  onClickDownload,
  selectedProvider,
  debouncedSearch,
  query,
  filtersAvailable = ["appointmentState", "patientType", "hasNextAppointment"],
}) => {
  const { data: paginatedData, isLoading } = appointmentBookingInfiniteQuery;

  const totalRows = getInfiniteQueryPagingDetails(paginatedData)?.totalElements;
  const totals = getTotals(paginatedData);

  const rows = useFlattenEntries(paginatedData);
  const isEmpty = !isLoading && rows.length === 0;
  const filterParams = {
    patientSearch: debouncedSearch === "" ? undefined : debouncedSearch,
    filters: query.filters,
  };

  const filterProps = useFilterTokenProps({
    options: filterParams,
    selectedProviderDisplayName: selectedProvider?.provider.fullDisplayName,
    procedures: allDentalProceduresQuery.data,
  });
  const handleSortClick = React.useCallback(
    (tableSort: ColumnSortOrder[]) => {
      onUpdateParams({
        tableSort,
      });
    },
    [onUpdateParams]
  );

  return (
    <div className="h-full w-full flex flex-col min-h-0">
      <DashboardTableHeader
        query={query}
        id="appt-booking-table-header"
        title="Show by Appointment"
        isDownloading={isDownloadingTable}
        onClickDownload={onClickDownload}
        onUpdateParams={onUpdateParams}
      >
        {tableExpanded && (
          <TableSelectProvider
            filters={query.filters}
            onFiltersChanged={(newFilters) => {
              onUpdateParams({
                filters: newFilters,
              });
            }}
            providerFilterOptions={providerFilterOptions}
          />
        )}
        {filtersAvailable.includes("appointmentState") && (
          <DashboardFilterFormFieldSelect
            placeholder="Appt Status"
            dashboardFilterType="appointmentState"
            dashboardFilters={query.filters}
            isSearchable={false}
            options={APPT_STATUSES}
            className="min-w-[140px]"
            onChangeFilters={onUpdateParams}
          />
        )}
        {filtersAvailable.includes("patientType") && (
          <SelectPatientType onUpdateParams={onUpdateParams} filters={query.filters} />
        )}
        {filtersAvailable.includes("hasNextAppointment") && (
          <SelectHasNextApptFilter query={query} onUpdateParams={onUpdateParams} />
        )}
        <TableSelectProcedureFilters
          procedures={allDentalProceduresQuery.data}
          // Values are populated elsewhere in PracticeProductionFilterTokens
          filters={query.filters}
          onFiltersChanged={(updatedFilters) => {
            onUpdateParams({
              filters: updatedFilters,
            });
          }}
        />
      </DashboardTableHeader>
      <DashboardTableFilters {...filterParams}>
        <QueryFilterPills
          {...filterProps}
          numResults={totalRows}
          onUpdateParams={onUpdateParams}
          onClearAll={() => {
            onUpdateParams({ filters: [], patientSearch: undefined });
          }}
        />
      </DashboardTableFilters>
      <DashboardInfiniteTable
        columnWidths={APPT_BOOKING_COLUMNS.map(({ width }) => width)}
        id="patientsTreatedTable"
        isEmpty={isEmpty}
        infiniteQuery={appointmentBookingInfiniteQuery}
        headerRow={
          <DashboardHeaderRow
            headers={APPT_BOOKING_COLUMNS}
            onSortClick={handleSortClick}
            sortOrders={query.tableSort}
          />
        }
        footerRow={
          <>
            <FooterCell>Totals</FooterCell>
            <FooterCell loading={isLoading}>{totals?.distinctCount ?? ""}</FooterCell>
            <FooterCell />
            <FooterCell loading={isLoading}>{totals?.totalDuration ?? ""}</FooterCell>
            <FooterCell className="col-span-4" />
          </>
        }
      >
        {rows.map((item, i) => {
          return (
            <AppointmentBookingTableRow
              key={`${item.patient.id}_${item.appointmentId}-${i}`}
              data={item}
              last={i === rows.length - 1}
            />
          );
        })}
      </DashboardInfiniteTable>
    </div>
  );
};
