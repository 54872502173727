import {
  isSameDay,
  startOfDay,
  startOfYear,
  startOfMonth,
  startOfWeek,
  parseISO,
  endOfWeek,
  endOfMonth,
  endOfYear,
  startOfQuarter,
  endOfQuarter,
} from "date-fns";
import { TimeSeries, TimeSeriesDataPoint } from "@libs/api/generated-api";
import { TimeSeriesResolutionOption } from "utils/routing/dashboard";
import { getSelectedWithIncompletePeriod } from "components/Dashboard/utils/timeSeriesDates";
import { BASE_CHART_MARGINS } from "components/Dashboard/Charting/theme";

export const getSelectedTimePeriodBounds = (
  date: Date,
  resolution: TimeSeriesResolutionOption,
  outerBounds: { startDate: Date; endDate: Date }
) => {
  switch (resolution) {
    case "DAY": {
      return {
        startDate: startOfDay(date),
        endDate: startOfDay(date),
      };
    }
    case "WEEK": {
      return getSelectedWithIncompletePeriod({
        selectedTimeSegment: {
          startDate: startOfWeek(date),
          endDate: endOfWeek(date),
        },
        timeSeriesWindow: outerBounds,
        resolution,
        date,
      });
    }
    case "QUARTER": {
      return getSelectedWithIncompletePeriod({
        selectedTimeSegment: {
          startDate: startOfQuarter(date),
          endDate: startOfDay(endOfQuarter(date)),
        },
        timeSeriesWindow: outerBounds,
        resolution,
        date,
      });
    }
    case "YEAR": {
      return getSelectedWithIncompletePeriod({
        selectedTimeSegment: {
          startDate: startOfYear(date),
          endDate: endOfYear(date),
        },
        timeSeriesWindow: outerBounds,
        resolution,
        date,
      });
    }

    default: {
      // "MONTH" case
      return getSelectedWithIncompletePeriod({
        selectedTimeSegment: {
          startDate: startOfMonth(date),
          endDate: endOfMonth(date),
        },
        timeSeriesWindow: outerBounds,
        resolution,
        date,
      });
    }
  }
};

/**
 * Merge arg 'series' into 'timeline'. Arguments are expected to already be sorted by date.
 * @param {Array} timeline - a superset of the series argument, where series will fill in that data where there are non-zero values
 * @param {Array} series - array of data with values, where not all dates may be represented that are in timeline
 */
export const mergeTimelines = (timeline: TimeSeriesDataPoint[], series: TimeSeriesDataPoint[]) => {
  return timeline.map((item) => {
    const date = parseISO(item.startDate);
    const dataPoint = series.find((dataItem) => {
      return isSameDay(date, parseISO(dataItem.startDate));
    });

    return dataPoint || item;
  });
};

export const DEFAULT_Y_AXIS_MAX_CENTS = 500_000;
export const DEFAULT_Y_AXIS_MAX = 20_000;

// Tries to estimate a reasonable axis maximum while data is loading. If yValues present, returns the max
export const getMaximumYAxis = ({
  yValues,
  resolution,
  isCurrency = true,
}: {
  yValues: number[];
  resolution: TimeSeriesResolutionOption;
  isCurrency?: boolean;
}) => {
  const max = Math.max(
    // Shouldn't have to include collected, but sometimes it is greater than total production
    ...yValues
    // TODO - patient ledger completed - comment back in:
    // ...series.map((item) => item.collected)
  );
  let defaultMaximum = isCurrency ? DEFAULT_Y_AXIS_MAX_CENTS : DEFAULT_Y_AXIS_MAX;

  if (resolution === "DAY" || resolution === "WEEK") {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    defaultMaximum /= 5;
  } else if (resolution === "YEAR") {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    defaultMaximum *= 20;
  }

  return max === 0 ? defaultMaximum : max;
};

export const getMapOfDimensionsFromTimeseries = <T extends string>(
  dimensions: T[],
  timeline: TimeSeriesDataPoint[],
  series?: TimeSeries[]
) => {
  // Set values to an empty timeline
  const stacks: Record<T, TimeSeriesDataPoint[]> = dimensions.reduce(
    (prev, current) => {
      prev[current] = timeline;

      return prev;
    },
    {} as Record<T, TimeSeriesDataPoint[]>
  );

  if (series) {
    // Now populate that timeline
    const dimensionSet = new Set(dimensions);

    for (const seriesItem of series) {
      if (seriesItem.dimension) {
        const dimension: T = seriesItem.dimension as T;

        if (dimensionSet.has(dimension)) {
          stacks[dimension] = mergeTimelines(timeline, seriesItem.dataPoints);
        }
      }
    }
  }

  return stacks;
};

const CENTER_PADDINGS = {
  YEAR: 0.2,
  QUARTER: 0.14,
};

export const getDateResolutionMargins = ({
  resolution,
  width,
}: {
  resolution: TimeSeriesResolutionOption;
  width: number;
}) => {
  const chartMargin = { ...BASE_CHART_MARGINS };
  const axisMargin = { ...BASE_CHART_MARGINS };

  if (resolution === "QUARTER" || resolution === "YEAR") {
    const CENTER_PADDING = width * CENTER_PADDINGS[resolution];

    chartMargin.left = CENTER_PADDING;
    chartMargin.right = CENTER_PADDING;
  }

  return { chartMargin, axisMargin };
};
