import { useState, useEffect } from "react";
import { ToggleButton } from "components/UI/ToggleButton";
import { useLastFocus } from "hooks/useLastFocus";
import { usePerioChartActiveTooth } from "./PerioChartActiveToothContext";
import { usePerioChartControlContext } from "./PerioChartControlContext";

export const PerioChartPlusTen: React.FC = () => {
  const { tabIndex, sequence } = usePerioChartActiveTooth();
  const { controlFeatures, setPlusTen } = usePerioChartControlContext();

  const [active, setActive] = useState(Boolean(controlFeatures.plusTenActive));

  useEffect(() => {
    return controlFeatures.subscribe(() => setActive(controlFeatures.plusTenActive));
  }, [controlFeatures]);

  const lastFocusRef = useLastFocus();

  const canPlusTen = tabIndex !== undefined && sequence !== "GINGMARGIN";

  return (
    <ToggleButton
      type="checkbox"
      disabled={!canPlusTen}
      shape="smallButton"
      theme="secondary"
      onClick={() => {
        lastFocusRef.current?.focus();
        setPlusTen(!active);
      }}
      checked={active}
    >
      +10
    </ToggleButton>
  );
};
