import {
  CheckPayload,
  CollectedPaymentEntryVO,
  EFTPayload,
  ExternalPosPayload,
  JournalEventVO,
  PaymentProfileVO,
} from "@libs/api/generated-api";
import { titleCaseConstant } from "@libs/utils/casing";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { PayerType, PaymentCollectedDataPointKey } from "components/Dashboard/PaymentCollected/types";

export const labelForPaymentCollectedKey = (key: PaymentCollectedDataPointKey): string => {
  const labelMap: Record<PaymentCollectedDataPointKey, string> = {
    INSURANCE_CARRIER: "Insurance",
    PATIENT: "Patient",
  };

  return labelMap[key];
};

export const labelForPayerType = (type: PayerType) => {
  return titleCaseConstant(type);
};

export const labelForPaymentDetails = ({
  checkPayload,
  externalPosPayload,
  eftPayload,
  paymentProfile,
}: {
  checkPayload?: CheckPayload;
  externalPosPayload?: ExternalPosPayload;
  eftPayload?: EFTPayload;
  paymentProfile?: PaymentProfileVO;
}) => {
  return checkPayload?.checkNumber
    ? `${checkPayload.checkNumber}`
    : eftPayload?.eftNumber
      ? `${eftPayload.eftNumber}`
      : externalPosPayload?.cardLastFour
        ? `${externalPosPayload.cardLastFour}`
        : paymentProfile?.card
          ? `${paymentProfile.card.cardLastFour}`
          : EMPTY_CELL;
};

type PaymentMethod = NonNullable<CollectedPaymentEntryVO["paymentMethod"] | JournalEventVO["paymentMethod"]>;
export const labelForPaymentMethod = (method: PaymentMethod) => {
  const labelMap: Record<PaymentMethod, string> = {
    ACH: "ACH",
    CARD: "Card",
    CARE_CREDIT: "CareCredit",
    CASH: "Cash",
    CHECK: "Check",
    EXTERNAL_CARD: "External Card",
    THIRD_PARTY_FINANCING: "Third Party Financing",
    WALLET: "Wallet",
    BALANCE_TRANSFER: "Credit Applied",
  };

  return labelMap[method];
};
