import Skeleton from "react-loading-skeleton";
import { cx } from "@libs/utils/cx";
import { cxTableStyles } from "components/Dashboard/Tables/cxTableStyles";
import { ColumnTextAlign } from "components/Dashboard/Tables/types";

export const FooterCell: React.FC<{
  children?: React.ReactNode;
  className?: string;
  loading?: boolean;
  isPrinting?: boolean;
  align?: ColumnTextAlign;
}> = ({ children, className, loading = false, align = "left", isPrinting = false }) => {
  return (
    <div className={cx(cxTableStyles.footer(align, isPrinting), className)}>
      {loading ? <Skeleton containerClassName="w-full" /> : children}
    </div>
  );
};
