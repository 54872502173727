import { add, endOfDay, sub } from "date-fns";
import React from "react";
import { formatAsISODate } from "@libs/utils/date";
import { useStartOfDayInPracticeTimezone } from "hooks/useStartOfDayInPracticeTimezone";

const BEGINNING_OF_TIME = "1970-01-01";

const INVOICE_AGE_WINDOW = { days: 30 };

export const ALL_TIME_DATE_SEGMENT = {
  startDate: BEGINNING_OF_TIME,
  endDate: formatAsISODate(endOfDay(new Date())),
};
export const useInvoiceAgeOptions = () => {
  const today = useStartOfDayInPracticeTimezone();
  const endDate = add(today, { days: 1 }).getTime();

  return React.useMemo(() => {
    //0-30 is actually thirty one days, including 0 as a day + 30
    const thirtyOneDaysAgo = sub(new Date(endDate), { days: 31 });
    const sixtyDaysAgo = sub(thirtyOneDaysAgo, INVOICE_AGE_WINDOW);
    const ninetyDaysAgo = sub(sixtyDaysAgo, INVOICE_AGE_WINDOW);
    const oneTwentyDaysAgo = sub(ninetyDaysAgo, INVOICE_AGE_WINDOW);

    return [
      {
        label: "0-30 days",
        value: 0,
        startDate: formatAsISODate(thirtyOneDaysAgo),
        endDate: formatAsISODate(new Date(endDate)),
      },
      {
        label: "31-60 days",
        value: 1,
        startDate: formatAsISODate(sixtyDaysAgo),
        endDate: formatAsISODate(thirtyOneDaysAgo),
      },
      {
        label: "61-90 days",
        value: 2,
        startDate: formatAsISODate(ninetyDaysAgo),
        endDate: formatAsISODate(sixtyDaysAgo),
      },
      {
        label: "91-120 days",
        value: 3,
        startDate: formatAsISODate(oneTwentyDaysAgo),
        endDate: formatAsISODate(ninetyDaysAgo),
      },
      {
        label: "121+ days",
        value: 4,
        startDate: BEGINNING_OF_TIME,
        endDate: formatAsISODate(oneTwentyDaysAgo),
      },
      {
        label: "All",
        value: -1,
        ...ALL_TIME_DATE_SEGMENT,
      },
    ];
  }, [endDate]);
};
