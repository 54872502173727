import React from "react";
import { Route } from "react-router-dom";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { ByCarrierTableRoute } from "components/Dashboard/OutstandingCollections/ByCarrierTableRoute";
import { ByPatientAgingTableRoute } from "components/Dashboard/OutstandingCollections/ByPatientAgingTableRoute";
import { OutstandingCollectionsQuery } from "utils/routing/dashboard";
import { ByServiceTableRoute } from "components/Dashboard/OutstandingCollections/ByServiceTableRoute";

const TABLE_ROUTES = [
  {
    path: "carriers",
    element: <ByCarrierTableRoute />,
  },
  {
    path: "service",
    element: <ByServiceTableRoute />,
  },
  {
    path: "patients",
    element: <ByPatientAgingTableRoute />,
  },
];

export const OutstandingCollectionsTable: React.FC<{
  query: OutstandingCollectionsQuery;
  onUpdateParams: (updates: Partial<OutstandingCollectionsQuery>) => void;
}> = () => {
  return (
    <div className="h-full w-full flex flex-col min-h-0">
      <SentryRoutes>
        {TABLE_ROUTES.map(({ element, path }) => {
          return <Route key={path} element={element} path={path} />;
        })}
        <Route path="*" element={<div className="text-center p-4">Table not found</div>} />
      </SentryRoutes>
    </div>
  );
};
