import { FC } from "react";

import { MigratedTransactionVO } from "@libs/api/generated-api";
import { UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { cx } from "@libs/utils/cx";

import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { EmptyContent } from "@libs/components/UI/EmptyContent";

import {
  TableGrid,
  HeaderButtonCell,
  ColumnSortIndicator,
  HeaderCell,
  TextCell,
  Row,
  SortDirection,
} from "@libs/components/UI/GridTableComponents";

import { ImportedLedgerRow } from "components/PatientProfile/Billing/ImportedLedger/ImportedLedgerRow";

import emptyListUrl from "assets/images/empty-list.svg";

export const importedLedgerTableHeaders = [
  { id: "date", label: "Date", width: "7rem" },
  { id: "type", label: "Transaction Type", width: "10rem" },
  { id: "code", label: "Code", width: "7rem" },
  { id: "description", label: "Description", width: "1fr" },
  { id: "notes", label: "Notes", width: "7rem" },
  { id: "provider", label: "Provider", width: "7rem" },
  { id: "amount", label: "Amount", width: "7rem" },
];

const columnWidths = importedLedgerTableHeaders.map(({ width }) => width);

interface Props {
  migratedTransactionsQuery: UseInfiniteApiListQueryResult<MigratedTransactionVO>;
  sortDirection: SortDirection;
  onSortChange: Func;
  onNoteClick: ({ note }: { note: string }) => void;
}

export const ImportedLedgerTable: FC<Props> = ({
  migratedTransactionsQuery,
  sortDirection,
  onSortChange,
  onNoteClick,
}) => {
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: migratedTransactionsQuery });
  const transactions = useFlattenPages(migratedTransactionsQuery.data);

  const headerCellProps = {
    borderColor: "border-slate-200" as const,
    size: "medium" as const,
  };

  return (
    <PersistScrollPosition
      ref={rootRef}
      id="imported-ledger-table"
      className="flex flex-col-reverse overflow-y-auto"
    >
      <ScrollableInfiniteQueryResult
        scrollRef={scrollRef}
        infiniteQuery={migratedTransactionsQuery}
        loading={
          <TableGrid columnWidths={columnWidths}>
            <LoadingRow />
          </TableGrid>
        }
        loadMore={<div />}
        noResults={
          <div className="p-6">
            <EmptyContent text="No Transactions" src={emptyListUrl} alt="No Transactions" size="md" />
          </div>
        }
      >
        <TableGrid columnWidths={columnWidths}>
          {importedLedgerTableHeaders.map(({ id, label }, index) =>
            index === 0 ? (
              <HeaderButtonCell key={id} onClick={onSortChange} {...headerCellProps}>
                <div className="flex items-center gap-x-2">
                  {label}
                  <ColumnSortIndicator direction={sortDirection} />
                </div>
              </HeaderButtonCell>
            ) : (
              <HeaderCell
                key={id}
                className={cx(index === importedLedgerTableHeaders.length - 1 && "justify-end")}
                {...headerCellProps}
              >
                <span className={cx(index === importedLedgerTableHeaders.length - 1 && "pr-2")}>{label}</span>
              </HeaderCell>
            )
          )}

          <div className="flex flex-col-reverse col-span-full">
            {transactions?.map((transaction, index) => (
              <TableGrid key={transaction.id} columnWidths={columnWidths}>
                {index === transactions.length - 1 && migratedTransactionsQuery.hasNextPage ? (
                  <LoadingRow />
                ) : null}

                <ImportedLedgerRow transaction={transaction} onNoteClick={onNoteClick} />
              </TableGrid>
            ))}
          </div>
        </TableGrid>
      </ScrollableInfiniteQueryResult>
    </PersistScrollPosition>
  );
};

const LoadingRow = () => (
  <Row>
    {importedLedgerTableHeaders.map(({ id }) => (
      <TextCell key={id} borderColor="border-slate-200">
        <div className="h-6">
          <LoadingContent />
        </div>
      </TextCell>
    ))}
  </Row>
);
