import { FC, useMemo, useState } from "react";
import { DocumentVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { FormFieldAutoComplete } from "components/UI/FormFieldAutoComplete";
import { searchDocument } from "api/documents/queries";

interface Props {
  practiceId: number;
  userId: number;
  onSelect: (doc: DocumentVO | undefined) => void;
}

export const SearchDocumentAutoComplete: FC<Props> = ({ practiceId, userId, onSelect }) => {
  const [searchString, setSearchString] = useState("");
  const [selectedDocId, setSelectedDocId] = useState<number | undefined>(undefined);

  const [{ data: searchResults, isLoading, refetch }] = useApiQueries([
    searchDocument({
      args: { practiceId, userId, query: { searchString } },
      queryOptions: {
        enabled: false,
      },
    }),
  ]);

  const options = useMemo(() => {
    return (
      searchResults?.map((doc) => ({
        label: `${doc.folderName || ""}/${doc.name || ""}`,
        value: doc.id,
        doc,
      })) ?? []
    );
  }, [searchResults]);

  return (
    <FormFieldAutoComplete
      className="w-96"
      placeholder="Search by document name..."
      value={selectedDocId}
      onSearch={() => refetch()}
      onInputChange={(newValue) => setSearchString(newValue)}
      onChange={(newValue) => {
        setSelectedDocId(newValue?.value);
        onSelect(newValue?.doc);
      }}
      formatOptionLabel={(item, meta) => {
        const isMenu = meta.context === "menu";

        return isMenu ? (
          <div title={item.label} className="max-w-[350px] truncate">
            {item.label}
          </div>
        ) : (
          item.label
        );
      }}
      options={options}
      isLoading={isLoading}
    />
  );
};
