import { FC, KeyboardEvent } from "react";

import { Spinner } from "@libs/components/UI/Spinner";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon } from "@libs/components/UI/Icon";

import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";

export const SearchPatientsInput: FC<{
  searchString: string;
  onChangeSearch: (searchString: string) => void;
  onKeyDownSearch: (e: KeyboardEvent<HTMLInputElement>) => void;
  isSearching: boolean;
}> = ({ searchString, onChangeSearch, onKeyDownSearch, isSearching }) => {
  return (
    <div>
      <div
        className={`
          flex
          items-center
          gap-x-3
          px-3
          py-1
          rounded
          border
          border-slate-300
          hover:border-archyBlue-500
          focus-within:border-archyBlue-500
        `}
      >
        <input
          className={`
            w-full
            h-6
            text-xs
            placeholder:text-xs
            placeholder:text-slate-500
            focus-visible:outline-none
          `}
          placeholder="Search Patients"
          value={searchString}
          onChange={(e) => onChangeSearch(e.target.value)}
          onKeyDown={onKeyDownSearch}
          type="text"
          autoFocus
        />

        {isSearching ? (
          <Spinner size="sm" variant="secondary" animation="border" />
        ) : searchString ? (
          <ButtonIcon
            SvgIcon={CloseIcon}
            tooltip={{ content: "Clear Search", theme: "SMALL" }}
            onClick={() => onChangeSearch("")}
          />
        ) : (
          <Icon SvgIcon={SearchIcon} />
        )}
      </div>
    </div>
  );
};
