import React from "react";
import { usePromise } from "utils/usePromise";
import { captureCameraImage } from "./camera";

/*
  useCaptureCameraImage accepts a MediaStream (video feed) and returns methods for capturing images from the video
  feed.  It also returns the state of an image being captured.
*/

export interface UseCameraCaptureImage {
  capturedImage: Blob | null;
  isCapturingImage: boolean;
  capture: () => Promise<Blob | null>;
  clearCaptureImageError: Func;
  captureImageError: Error | null;
}

export function useCaptureCameraImage(stream?: MediaStream): UseCameraCaptureImage {
  const promise = usePromise(captureCameraImage);
  const {
    call: captureImage,
    result: capturedImage,
    isPending: isCapturingImage,
    error: captureImageError,
    clearError: clearCaptureImageError,
  } = promise;

  const capture = React.useCallback(() => {
    if (stream) {
      return captureImage(stream);
    }

    return Promise.reject("Stream not ready");
  }, [stream, captureImage]);

  return {
    captureImageError,
    isCapturingImage,
    capturedImage,
    capture,
    clearCaptureImageError,
  };
}
