import { FC } from "react";

import { useBoolean } from "@libs/hooks/useBoolean";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";

export interface TemplatePreviewModalProps {
  name: string;
  previewImage: string;
  onApplyTemplate: Func;
  onClose: Func;
}

export const TemplatePreviewModal: FC<TemplatePreviewModalProps> = ({
  name,
  previewImage,
  onApplyTemplate,
  onClose,
}) => {
  const applying = useBoolean(false);

  const handleApplyTemplate = () => {
    applying.on();
    onApplyTemplate();
  };

  return (
    <Modal title={name} onClose={onClose}>
      <ModalContent className="bg-slate-50" padding="lgEven">
        <div className="flex flex-col items-center text-xs">
          <img className="object-contain rounded" src={previewImage} alt={`${name} Preview`} />
        </div>
      </ModalContent>
      <ModalFooter>
        <Button className="min-w-button" onClick={onClose} theme="secondary">
          Close
        </Button>
        <AsyncButton className="min-w-button" onClick={handleApplyTemplate} isLoading={applying.isOn}>
          Apply
        </AsyncButton>
      </ModalFooter>
    </Modal>
  );
};
