import React, { useState } from "react";
import { WorkingHourItemVO } from "@libs/api/generated-api";
import { WorkingHoursForm, WorkingHoursFormProps } from "components/WorkingHours/WorkingHoursForm";

type Props = Pick<WorkingHoursFormProps, "isSaving" | "onSave" | "workingHours" | "onCancel">;

export const PracticeWorkingHoursForm: React.FC<Props> = ({ workingHours, isSaving, onSave, onCancel }) => {
  const [editableHours, setEditableHours] = useState(workingHours);

  const handleAddDay = (dayOfWeek: WorkingHourItemVO["dayOfWeek"]) => {
    setEditableHours((last) => [...last, { dayOfWeek, startTime: "00:00:00", endTime: "00:00:00" }]);
  };

  return (
    <WorkingHoursForm
      workingHours={editableHours}
      onChange={setEditableHours}
      onSave={onSave}
      onCancel={onCancel}
      isSaving={isSaving}
      onAddDay={handleAddDay}
    />
  );
};
