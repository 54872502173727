import { useState } from "react";
import { FamilyMemberResponse, PatientVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as PlusCircle } from "@libs/assets/icons/plus-circle.svg";
import { Panel } from "@libs/components/UI/Panel";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { SuccessFamilyModal } from "components/PatientProfile/PatientOverview/FamilyMembers/SuccessFamilyModal";
import { FamilyMembersTable } from "components/PatientProfile/PatientOverview/FamilyMembers/FamilyMembersTable";
import { useItemModal } from "hooks/useItemModal";
import { AddFamilyMemberFlyover } from "components/PatientProfile/PatientOverview/FamilyMembers/AddFamilyMemberFlyover";

interface Props {
  familyMembersQuery: ApiQueryResult<FamilyMemberResponse>;
  patientId: number;
  patientQuery: ApiQueryResult<PatientVO>;
}

export const Family: React.FC<Props> = ({ familyMembersQuery, patientId, patientQuery }) => {
  const addFamily = useItemModal<{ isGuardian: boolean } | null>(null);
  const successFamilyModal = useBoolean(false);

  const [name, setName] = useState("");
  const handleLinkFamilyMember = (displayName: string) => {
    setName(displayName);
    addFamily.close();
    successFamilyModal.on();
  };

  return (
    <>
      <Panel
        className="h-full pb-6"
        contentClassName="overflow-auto min-h-0 flex-1"
        includePadding={false}
        title={
          <div className="flex items-center gap-x-2">
            Family
            <ButtonIcon
              SvgIcon={PlusCircle}
              theme="primary"
              tooltip={{ content: "Add Family Member", theme: "SMALL" }}
              onClick={() => addFamily.open({ isGuardian: false })}
            />
          </div>
        }
      >
        <QueryResult
          loading={
            <div className="min-h-48">
              <LoadingContent />
            </div>
          }
          queries={[familyMembersQuery, patientQuery]}
        >
          {familyMembersQuery.data && patientQuery.data ? (
            <FamilyMembersTable
              familyMembers={familyMembersQuery.data}
              onAddFamilyMember={() => addFamily.open({ isGuardian: true })}
              patientFirstName={patientQuery.data.personalDetails.firstName}
              patientId={patientId}
            />
          ) : null}
        </QueryResult>
      </Panel>
      {addFamily.isOpen && addFamily.item && patientQuery.data && familyMembersQuery.data ? (
        <AddFamilyMemberFlyover
          familyMembers={familyMembersQuery.data}
          isGuardian={addFamily.item.isGuardian}
          onRequestClose={addFamily.close}
          onCreated={handleLinkFamilyMember}
          patient={patientQuery.data}
        />
      ) : null}
      {successFamilyModal.isOn && (
        <SuccessFamilyModal onRequestClose={successFamilyModal.off} familyMemberName={name} />
      )}
    </>
  );
};
