import React from "react";

import { Link } from "react-router-dom";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as QueueIcon } from "@libs/assets/icons/queue.svg";
import { useScheduleLinks } from "components/ScheduleAppointments/ScheduleLinksContext";

export const ScheduleAsapListButton: React.FC<{
  count?: number;
}> = ({ count = 0 }) => {
  const { links } = useScheduleLinks();

  return (
    <FloatingTooltip content="Requests & ASAP Lists" theme="SMALL">
      <Link className="flex items-center gap-1" to={count > 0 ? links.requests : links.asapList}>
        <Icon SvgIcon={QueueIcon} />
        <div className="text-sm font-sansSemiBold">{count > 0 ? count : ""}</div>
      </Link>
    </FloatingTooltip>
  );
};
