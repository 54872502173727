import { FC, useCallback, useEffect } from "react";

import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { hasFailedPin } from "@libs/utils/hasFailedPin";
import { useAccount } from "@libs/contexts/AccountContext";
import { GustoEmbeddedFlow } from "components/Settings/PracticeSetup/Sections/PayrollInformation/GustoEmbeddedFlow";
import { EmployeePinModal } from "components/Employee/EmployeePinModal";

import { createEmployeeFlow } from "api/payroll/mutations";

import { usePathParams } from "hooks/usePathParams";

import { handleError } from "utils/handleError";
import { paths } from "utils/routing/paths";

export const GustoEmployeeFlowRoute: FC = () => {
  const { practiceId } = useAccount();
  const { id: employeeId } = usePathParams("employeeEmploymentGusto");
  const pinModal = useBoolean(false);

  const [{ mutate: createEmployeeFlowUrl, isLoading: isLoadingIframeUrl, data: response }] = useApiMutations([
    createEmployeeFlow,
  ]);

  const loadIframeUrl = useCallback(() => {
    if (pinModal.isOn) {
      return;
    }

    createEmployeeFlowUrl(
      { practiceId, employeeId, data: { flowType: "EMPLOYEE_SELF_MANAGEMENT" } },
      {
        onError: (error) => {
          if (hasFailedPin(error)) {
            pinModal.on();
          } else {
            handleError(error);
          }
        },
      }
    );
  }, [pinModal, createEmployeeFlowUrl, practiceId, employeeId]);

  useEffect(() => loadIframeUrl(), [loadIframeUrl]);

  return (
    <>
      <GustoEmbeddedFlow
        isLoading={isLoadingIframeUrl}
        iframeUrl={response?.data.data.url}
        closeLink={paths.employeeEmployment({ id: employeeId })}
      />

      {pinModal.isOn ? <EmployeePinModal onPinSuccess={pinModal.off} onClose={pinModal.off} /> : null}
    </>
  );
};
