import { FC, useMemo } from "react";
import { matchPath, Route, useNavigate, useLocation, Link, Navigate } from "react-router-dom";
import { formatISODate, nowInTimezone } from "@libs/utils/date";
import { formatCurrency } from "@libs/utils/currency";
import { isOneOf } from "@libs/utils/isOneOf";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { hasFailedPin } from "@libs/utils/hasFailedPin";
import { Icon } from "@libs/components/UI/Icon";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Banner } from "@libs/components/UI/Banner";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { AlertModal } from "@libs/components/UI/AlertModal";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { paths, routesConfig } from "utils/routing/paths";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";
import { getPayroll } from "api/payroll/queries";
import { LoadingOverlay } from "components/UI/LoadingOverlay";
import { ModalPage } from "components/UI/ModalPage";
import { PayrollTitleTable } from "components/Payroll/PayrollTitleTable";
import { PayrollDetailsEmployeesRoute } from "components/Payroll/PayrollDetailsEmployeesRoute";
import { PayrollDetailsTaxesRoute } from "components/Payroll/PayrollDetailsTaxesRoute";
import { PayrollDetailsCompanyPaysRoute } from "components/Payroll/PayrollDetailsCompanyPaysRoute";
import { NotFound } from "components/NotFoundRoute";
import { TabText } from "components/UI/TabText";
import { cancelPayroll, submitPayroll } from "api/payroll/mutations";
import { handleError } from "utils/handleError";
import {
  formatDeadlineDate,
  getDeadlineDate,
  hasPendingLineItems,
  hasUnresolvedPostTaxDeductions,
} from "components/Payroll/utils";
import { DownloadPayrollReceiptButton } from "components/Payroll/DownloadPayrollReceiptButton";
import {
  PayrollDeductionErrorsBanner,
  PayrollProcessingErrorsBanner,
} from "components/Payroll/PayrollErrorsBanners";
import { useItemModal } from "hooks/useItemModal";
import { EmployeePinModal } from "components/Employee/EmployeePinModal";
import { AccessDeniedContent } from "components/UI/AccessDeniedContent";
import { PinGuard } from "components/UI/PinGuard";

export const getPayrollDetailsRoutes = (
  payrollId: string,
  from: string,
  root: string,
  currentPath: string
) => [
  {
    to: paths.payrollDetail({ payrollId }, { from, root }),
    index: true,
    Component: PayrollDetailsCompanyPaysRoute,
    text: "Company Pays",
    end: true,
    isSelected: Boolean(matchPath(routesConfig.payrollDetail.path, currentPath)),
  },
  {
    to: paths.payrollDetailEmployees({ payrollId }, { from, root }),
    path: "employees",
    Component: PayrollDetailsEmployeesRoute,
    text: "Hours Worked & Take Home",
    end: true,
    isSelected: Boolean(matchPath(routesConfig.payrollDetailEmployees.path, currentPath)),
  },
  {
    to: paths.payrollDetailTaxes({ payrollId }, { from, root }),
    path: "taxes",
    Component: PayrollDetailsTaxesRoute,
    text: "Taxed & Debited",
    end: true,
    isSelected: Boolean(matchPath(routesConfig.payrollDetailTaxes.path, currentPath)),
  },
];

// eslint-disable-next-line complexity
export const PayrollDetailRoute: FC = () => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const practice = useCurrentPractice();
  const { query } = useQueryParams("payrollDetail");
  const { payrollId } = usePathParams("payrollDetail");
  const from = query.from ?? paths.editPayroll({ payrollId });
  const root = query.root ?? paths.payroll();
  const confirmCancelModal = useBoolean(false);
  const finishLaterModal = useBoolean(false);
  const pinModal = useItemModal<Func>(null);

  usePageTitle("Payroll Detail");

  const [payrollQuery] = useApiQueries([getPayroll({ args: { practiceId: practice.id, payrollId } })]);

  const [submitPayrollMutation, cancelPayrollMutation] = useApiMutations([submitPayroll, cancelPayroll]);

  const hasUnresolvedDeductions = useMemo(() => {
    return hasUnresolvedPostTaxDeductions(payrollQuery.data?.lineItems);
  }, [payrollQuery.data]);

  const hasPendingStatus = useMemo(() => {
    return hasPendingLineItems(payrollQuery.data?.lineItems);
  }, [payrollQuery.data]);

  const tabs = useMemo(() => {
    return getPayrollDetailsRoutes(payrollId, from, root, pathname);
  }, [payrollId, from, root, pathname]);

  const loadingStateText = useMemo(() => {
    const isGeneratingPayroll = payrollQuery.data?.status === "CALCULATING";

    return payrollQuery.isStale
      ? "Updating Payroll..."
      : isGeneratingPayroll
        ? "Generating Payroll..."
        : submitPayrollMutation.isLoading
          ? "Submitting Payroll..."
          : cancelPayrollMutation.isLoading
            ? "Cancelling Payroll..."
            : "";
  }, [
    payrollQuery.data,
    payrollQuery.isStale,
    submitPayrollMutation.isLoading,
    cancelPayrollMutation.isLoading,
  ]);

  const debitAmount = payrollQuery.data?.breakdown?.debitAmount;

  const isAfterDeadline = useMemo(() => {
    const payrollDeadline = payrollQuery.data?.payrollDeadline;

    if (!payrollDeadline) {
      return false;
    }

    return nowInTimezone(practice.timezoneId) > getDeadlineDate(practice.timezoneId, payrollDeadline);
  }, [payrollQuery.data, practice.timezoneId]);

  const handleSubmitPayroll = () => {
    submitPayrollMutation.mutate(
      {
        practiceId: practice.id,
        payrollId,
      },
      {
        onError: (err) => {
          if (hasFailedPin(err)) {
            pinModal.open(handleSubmitPayroll);
          } else {
            handleError(err);
          }
        },
      }
    );
  };

  const handleCancelPayroll = () => {
    cancelPayrollMutation.mutate(
      {
        practiceId: practice.id,
        payrollId,
      },
      {
        onError: (err) => {
          if (hasFailedPin(err)) {
            pinModal.open(handleCancelPayroll);
          } else {
            handleError(err);
          }
        },
        onSuccess: () => {
          navigate(root);
        },
      }
    );
  };

  return (
    <>
      <PinGuard
        protectedQueries={[payrollQuery]}
        deniedContent={
          <div className="h-full w-full flex place-content-center">
            <AccessDeniedContent />
          </div>
        }
      >
        <QueryResult queries={[payrollQuery]}>
          {payrollQuery.data ? (
            isOneOf(payrollQuery.data.status, ["CANCELED", "DRAFT"]) ? (
              <Navigate replace to={root} />
            ) : (
              <LoadingOverlay
                overlayClassName="z-20 border border-greyLighter rounded"
                isLoading={Boolean(loadingStateText)}
                opaque={true}
                loadingText={loadingStateText}
                loadingSubText="This may take a few moments."
              >
                <ModalPage
                  className="relative"
                  title={
                    <div>
                      <div className="font-sansSemiBold flex items-center">
                        <h1>
                          {payrollQuery.data.status === "SUBMITTED"
                            ? "Payroll Details"
                            : "Review & Submit Payroll"}
                        </h1>
                        <div className="h-4 w-px bg-greyLight mx-4" />
                        <div className="text-sm">
                          Pay Period {formatISODate(payrollQuery.data.periodStartDate)} -{" "}
                          {formatISODate(payrollQuery.data.periodEndDate)}
                        </div>
                        {isAfterDeadline && payrollQuery.data.status === "SUBMITTED" ? (
                          <DownloadPayrollReceiptButton
                            practiceId={practice.id}
                            payroll={payrollQuery.data}
                          />
                        ) : null}
                      </div>
                      {payrollQuery.data.status === "SUBMITTED" && isAfterDeadline ? null : payrollQuery.data
                          .status === "SUBMITTED" ? (
                        <Banner theme="success" className="text-xs w-fit mt-2.5 mb-4">
                          Payroll is complete. {formatCurrency(debitAmount ?? 0)} will be debited at{" "}
                          {formatDeadlineDate(practice.timezoneId, payrollQuery.data.payrollDeadline)}. Please
                          make sure to have these funds available.
                          <DownloadPayrollReceiptButton
                            practiceId={practice.id}
                            payroll={payrollQuery.data}
                          />
                        </Banner>
                      ) : (
                        <>
                          {/* TODO Figure out what data drives this text and how it changes based on different states */}
                          <p className="text-xs mt-2.5">
                            We saved your progress so you can submit this later. To pay your team on the date
                            below, submit payroll by{" "}
                            {formatDeadlineDate(practice.timezoneId, payrollQuery.data.payrollDeadline)}.
                          </p>
                          <PayrollProcessingErrorsBanner errors={payrollQuery.data.errors} />
                          <PayrollDeductionErrorsBanner lineItems={payrollQuery.data.lineItems} />
                        </>
                      )}

                      <PayrollTitleTable payroll={payrollQuery.data} />
                    </div>
                  }
                  actions={
                    <div className="flex items-center justify-center relative gap-x-3">
                      {payrollQuery.data.isCancelable ? (
                        <>
                          {isAfterDeadline ? null : (
                            <Button
                              className="min-w-button"
                              theme="dangerZone"
                              onClick={confirmCancelModal.on}
                            >
                              Cancel Payroll
                            </Button>
                          )}
                          <ButtonInternalLink className="min-w-button" to={root}>
                            Done
                          </ButtonInternalLink>
                        </>
                      ) : payrollQuery.data.status === "SUBMITTED" ? (
                        <ButtonInternalLink className="min-w-button" to={root}>
                          Done
                        </ButtonInternalLink>
                      ) : (
                        <>
                          <ButtonInternalLink
                            theme="link"
                            className={`
                              text-sm
                              mr-4
                              flex
                              items-center
                              md:mr-16
                              xl:mr-0
                              xl:absolute
                              xl:top-1/2
                              xl:-translate-y-1/2
                              xl:left-1/2
                              xl:-translate-x-[250%]
                            `}
                            to={from}
                          >
                            Edit Payroll Details
                            <Icon className="ml-1" SvgIcon={EditIcon} theme="primary" />
                          </ButtonInternalLink>

                          <Button onClick={finishLaterModal.on} className="min-w-button" theme="secondary">
                            Finish Later
                          </Button>
                          <FloatingTooltip
                            placement="top"
                            content={
                              hasPendingStatus
                                ? "Timesheet status must be approved for all employees before you can continue."
                                : hasUnresolvedDeductions
                                  ? "All deductions must be successfully processed before you can run payroll."
                                  : ""
                            }
                          >
                            <Button
                              className="min-w-button"
                              disabled={hasUnresolvedDeductions || hasPendingStatus}
                              onClick={handleSubmitPayroll}
                            >
                              Submit Payroll
                            </Button>
                          </FloatingTooltip>
                        </>
                      )}
                    </div>
                  }
                  closeLink={root}
                >
                  <div className="flex">
                    {tabs.map((tab) => (
                      <Link to={tab.to} className="block mr-5" key={tab.text}>
                        <TabText isSelected={tab.isSelected}>{tab.text}</TabText>
                      </Link>
                    ))}
                  </div>
                  <SentryRoutes>
                    {tabs.map((tab, i) => (
                      <Route
                        key={i}
                        path={tab.path}
                        index={tab.index}
                        element={payrollQuery.data ? <tab.Component payroll={payrollQuery.data} /> : null}
                      />
                    ))}
                    <Route path="*" element={<NotFound />} />
                  </SentryRoutes>
                </ModalPage>
              </LoadingOverlay>
            )
          ) : null}
        </QueryResult>
        {confirmCancelModal.isOn ? (
          <ConfirmationModal
            primaryText="Are you sure you want to cancel this Payroll?"
            secondaryText="You will lose some of the data generated and stop the scheduled debit on your account."
            onCancel={confirmCancelModal.off}
            onConfirm={() => {
              confirmCancelModal.off();
              handleCancelPayroll();
            }}
          />
        ) : null}

        {finishLaterModal.isOn ? (
          <AlertModal
            primaryText="Finish this payroll later"
            secondaryText="All the changes and edits you’ve made here will be saved for when you resume this payroll."
            onConfirm={() => navigate(root, { replace: true })}
          />
        ) : null}
      </PinGuard>
      {pinModal.isOpen && <EmployeePinModal onPinSuccess={pinModal.item} onClose={pinModal.close} />}
    </>
  );
};
