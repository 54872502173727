import React from "react";
import { DraftEobPaymentVO } from "@libs/api/generated-api";
import { pluralize } from "@libs/utils/pluralize";
import { formatCurrency } from "@libs/utils/currency";
import { UseBooleanResult } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as RemoveIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { ReactComponent as DownCaretIcon } from "@libs/assets/icons/down-caret.svg";
import { ReactComponent as AttachIcon } from "@libs/assets/icons/attach.svg";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { AddClaimsModalContent } from "components/Eob/AddClaimsModalContent";
import { ActionModal } from "components/UI/ActionModal";
import { AttachEobFilesModal } from "components/Eob/AttachEobFilesModal";
import { ItemModal } from "hooks/useItemModal";

interface Props {
  addClaimsModal: ItemModal<string>;
  disableActions: boolean;
  draftEobPayment: DraftEobPaymentVO;
  onAddClaims: () => void;
  onCancelAddClaims: () => void;
  onDeleteEobFile: (eobFileUuid: string) => void;
  onToggleClaim: (claimUuid: string) => void;
  selectedClaimUuids: Set<string>;
  uploadEobFileMenu: UseBooleanResult;
  uploadEobFileModal: ItemModal<string>;
}

const cxStyles = {
  statistic: "font-sansSemiBold text-sm",
};

export const EobPaymentSummary: React.FC<Props> = ({
  addClaimsModal,
  disableActions,
  draftEobPayment,
  onAddClaims,
  onCancelAddClaims,
  onDeleteEobFile,
  onToggleClaim,
  selectedClaimUuids,
  uploadEobFileMenu,
  uploadEobFileModal,
}) => {
  return (
    <>
      <div className="text-greyDark text-xs">
        <div
          className={`
            flex
            justify-between
            h-11
            w-full
            py-3
            px-5
            bg-whiteLight
            border-b
            border-b-greyLighter
          `}
        >
          <div className="flex h-full items-center">
            <div>{draftEobPayment.insuranceCarrier}</div>
            <VerticalDivider className="mx-3" />
            <div className={cxStyles.statistic}>{draftEobPayment.claimPaymentSummaries.length}</div>
            <div className="mx-2">{`${pluralize(
              draftEobPayment.claimPaymentSummaries.length,
              "Claim",
              "Claims"
            )} selected`}</div>
            <ButtonIcon
              disabled={disableActions}
              SvgIcon={AddIcon}
              onClick={() => addClaimsModal.open(draftEobPayment.uuid)}
              theme="primary"
              tooltip={{ content: "Add Claims", theme: "SMALL" }}
            />
            <VerticalDivider className="mx-3" />{" "}
            <div className={cxStyles.statistic}>{draftEobPayment.eobFiles.length}</div>
            <div className="mx-2">{`${pluralize(
              draftEobPayment.eobFiles.length,
              "EOB",
              "EOBs"
            )} attached`}</div>
            {draftEobPayment.eobFiles.length ? (
              <ButtonMenu
                isOpen={uploadEobFileMenu.isOn}
                onRequestClose={uploadEobFileMenu.off}
                onRequestOpen={uploadEobFileMenu.on}
                menuContent={
                  <div className="px-3 py-1.5 flex flex-col text-xs text-greyDark">
                    {draftEobPayment.eobFiles.map((file) => (
                      <div className="flex justify-between py-1.5" key={file.uuid}>
                        <div className="mr-3">{file.name}</div>
                        <button type="button" onClick={() => onDeleteEobFile(file.uuid)}>
                          <RemoveIcon className="h-4 w-4 fill-primaryTheme" />
                        </button>
                      </div>
                    ))}
                    <button
                      type="button"
                      className="flex py-1.5 items-center text-primaryTheme"
                      onClick={() => {
                        uploadEobFileModal.open(draftEobPayment.uuid);
                        uploadEobFileMenu.off();
                      }}
                    >
                      <AttachIcon className="h-4 w-4 fill-primaryTheme mr-1" />
                      <div>Attach</div>
                    </button>
                  </div>
                }
              >
                {(props) => (
                  <ButtonIcon
                    {...props}
                    disabled={disableActions}
                    SvgIcon={DownCaretIcon}
                    size="sm"
                    theme="primary"
                    tooltip={{ theme: "SMALL", content: "View/Upload EOBs" }}
                  />
                )}
              </ButtonMenu>
            ) : (
              <ButtonIcon
                tooltip={{ content: "Attach EOBs", theme: "SMALL" }}
                SvgIcon={AttachIcon}
                onClick={() => uploadEobFileModal.open(draftEobPayment.uuid)}
                theme="primary"
              />
            )}
          </div>
          <div className="flex items-center">
            <div className="flex h-full items-center">
              <div className="mr-2">Total Patient Payment</div>
              <div className={cxStyles.statistic}>{formatCurrency(draftEobPayment.unpaidPatientAmount)}</div>
            </div>
            <VerticalDivider className="mx-3" />
            <div className="flex h-full items-center">
              <div className="mr-2">Total Insurance Payment</div>
              <div className={cxStyles.statistic}>
                {formatCurrency(draftEobPayment.unpaidInsuranceAmount)}
              </div>
            </div>
          </div>
        </div>
      </div>
      {addClaimsModal.isOpen && (
        <ActionModal
          handleCancel={onCancelAddClaims}
          handleClose={addClaimsModal.close}
          handleSave={onAddClaims}
          primaryButtonDisabled={!selectedClaimUuids.size}
          primaryButtonDisabledTooltip={selectedClaimUuids.size ? "" : "Select claim"}
          secondaryTitle={draftEobPayment.insuranceCarrier}
          title="Add Claims"
        >
          <AddClaimsModalContent
            insuranceCarrierId={draftEobPayment.insuranceCarrierId}
            onToggleClaim={onToggleClaim}
            selectedClaimUuids={selectedClaimUuids}
          />
        </ActionModal>
      )}
      {uploadEobFileModal.isOpen ? (
        <AttachEobFilesModal draftEobPayment={draftEobPayment} onRequestClose={uploadEobFileModal.close} />
      ) : null}
    </>
  );
};
