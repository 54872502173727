import { FC } from "react";

import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";

import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";

import {
  SendMessageCampaignDraft,
  SendMessageCampaignValidationResult,
} from "components/Communications/MessagePatients/types";

import { useNow } from "hooks/useNow";

interface Props {
  startDate: SendMessageCampaignDraft["statementDateRange"]["startDate"];
  endDate: SendMessageCampaignDraft["statementDateRange"]["endDate"];
  onDateRangeChange: (dateRange: Partial<SendMessageCampaignDraft["statementDateRange"]>) => void;
  validationResult: SendMessageCampaignValidationResult["statementDateRange"];
}

export const StatementDateRangeField: FC<Props> = ({
  startDate,
  endDate,
  onDateRangeChange,
  validationResult,
}) => {
  const now = useNow();

  return (
    <div className="flex flex-col gap-y-2">
      <FormFieldLabel className="font-sansSemiBold text-xs" content="Statement Date Range" required />

      <div className="flex items-baseline gap-x-2">
        <FormFieldSelectMenusDatepicker
          aria-label="Start Date"
          className="w-32"
          selected={startDate}
          onChange={(date) => onDateRangeChange({ startDate: date })}
          error={validationResult.startDate.$error}
          maxDate={endDate ?? now}
        />

        <span className="text-xs text-black">-</span>

        <FormFieldSelectMenusDatepicker
          aria-label="End Date"
          className="w-32"
          selected={endDate}
          onChange={(date) => onDateRangeChange({ endDate: date })}
          error={validationResult.endDate.$error}
          minDate={startDate}
          maxDate={now}
        />
      </div>
    </div>
  );
};
