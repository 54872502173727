import { FC } from "react";
import { InsuranceCarrierListEntryVO } from "@libs/api/generated-api";
import { UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import {
  ColumnSortIndicator,
  HeaderButtonCell,
  HeaderCell,
  TableGrid,
} from "@libs/components/UI/GridTableComponents";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { InsuranceCarrierRow } from "components/Settings/InsuranceCarriers/Row";
import {
  CarriersSortKeys,
  InsuranceCarrierDetailsQueryUpdates,
  InsuranceCarriersQuery,
} from "utils/routing/settings";

interface InsuranceTableHeader {
  id: string;
  label: string;
  sortColumn?: CarriersSortKeys;
  width: string;
}

const headers: InsuranceTableHeader[] = [
  { id: "name", label: "Name", sortColumn: "name", width: "2fr" },
  { id: "payerId", label: "Payer ID", sortColumn: "payerId", width: "1fr" },
  { id: "insurancePlans", label: "Insurance Plans", sortColumn: "planCount", width: "1fr" },
  { id: "patients", label: "Patients", sortColumn: "patientCount", width: "1fr" },
  { id: "feeSchedules", label: "Fee Schedules", width: "4fr" },
  { id: "inNetwork", label: "In Network", width: "1fr" },
  { id: "actions", label: "", width: "min-content" },
];

interface Props {
  insuranceCarriersInfiniteQuery: UseInfiniteApiListQueryResult<InsuranceCarrierListEntryVO>;
  insuranceCarriersQueryKey: unknown;
  onEdit: (carrierId: number | "new", queryParams?: InsuranceCarrierDetailsQueryUpdates) => void;
  onSort: (sortColumn: CarriersSortKeys) => void;
  query: InsuranceCarriersQuery;
}

export const InsuranceCarriersTable: FC<Props> = ({
  insuranceCarriersInfiniteQuery,
  insuranceCarriersQueryKey,
  onEdit,
  onSort,
  query,
}) => {
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: insuranceCarriersInfiniteQuery });

  const insuranceCarriers = useFlattenPages(insuranceCarriersInfiniteQuery.data);

  return (
    <PersistScrollPosition
      className="min-h-0 col-span-full overflow-y-scroll"
      id="insuranceCarriersTable"
      ref={rootRef}
      resetScrollKey={insuranceCarriersQueryKey}
    >
      <ScrollableInfiniteQueryResult
        infiniteQuery={insuranceCarriersInfiniteQuery}
        loading={<LoadingContent containerClassName="min-h-screen col-span-full" />}
        loadMoreClassName="col-span-full"
        noResults={
          <div className="col-span-full">
            <div className="h-full flex items-center justify-center text-sm">No Insurance Records Found</div>
          </div>
        }
        scrollRef={scrollRef}
      >
        <TableGrid className="min-w-[1024px]" columnWidths={headers.map(({ width }) => width)}>
          {headers.map((header) =>
            header.sortColumn ? (
              <HeaderButtonCell
                key={header.id}
                onClick={() => header.sortColumn && onSort(header.sortColumn)}
                size="short"
              >
                {header.label}
                <ColumnSortIndicator
                  className="ml-2"
                  direction={query.sortColumn === header.sortColumn ? query.orderBy : undefined}
                />
              </HeaderButtonCell>
            ) : (
              <HeaderCell key={header.id} size="short">
                {header.label}
              </HeaderCell>
            )
          )}
          {insuranceCarriers?.map((insuranceCarrier) => {
            return (
              <InsuranceCarrierRow insurance={insuranceCarrier} key={insuranceCarrier.id} onEdit={onEdit} />
            );
          })}
        </TableGrid>
      </ScrollableInfiniteQueryResult>
    </PersistScrollPosition>
  );
};
