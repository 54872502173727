import { CreateFormTaskRequest, SubmitFormTaskRequest, SendFormTaskRequest } from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";

export const createFormTask = makeMutation({
  mutationKey: ["practices", "createFormTask"],
  formatParams: (args: { practiceId: number; patientId: number; data: CreateFormTaskRequest }) => [
    args.practiceId,
    args.patientId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, patientId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "listFormTasks"), { practiceId, patientId }]);
    },
  }),
});

export const submitFormTask = makeMutation({
  mutationKey: ["practices", "submitFormTask"],
  formatParams: (args: { practiceId: number; formTaskUuid: string; data: SubmitFormTaskRequest }) => [
    args.practiceId,
    args.formTaskUuid,
    args.data,
  ],
});

export const archiveFormTask = makeMutation({
  mutationKey: ["practices", "archiveFormTask"],
  formatParams: (args: { practiceId: number; formTaskUuid: string }) => [args.practiceId, args.formTaskUuid],
});

export const resetFormTask = makeMutation({
  mutationKey: ["practices", "resetFormTask"],
  formatParams: (args: { practiceId: number; formTaskUuid: string }) => [args.practiceId, args.formTaskUuid],
});

export const sendFormTasks = makeMutation({
  mutationKey: ["practices", "sendFormTasks"],
  formatParams: (args: { practiceId: number; data: SendFormTaskRequest }) => [args.practiceId, args.data],
});

export const generateFormTaskKioskCode = makeMutation({
  mutationKey: ["practices", "generateFormTaskKioskCode"],
  formatParams: (args: { practiceId: number; formTaskUuids: string[] }) => [
    args.practiceId,
    { formTaskUuids: args.formTaskUuids },
  ],
});
