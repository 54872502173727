import { Route, NavLink } from "react-router-dom";
import { FC, useMemo } from "react";
import { cx } from "@libs/utils/cx";
import { PermissionActionVO } from "@libs/api/generated-api";
import { BoldSpaced } from "@libs/components/UI/BoldSpaced";
import { SentryRoutes } from "components/UI/SentryRoutes";

import { NotFound } from "components/NotFoundRoute";
import { PayrollApp } from "components/Payroll/PayrollApp";
import { LeftAsideTemplate } from "components/UI/LeftAsideTemplate";

import { useEmployeesLinks } from "components/Employee/EmployeesLinksContext";
import { RolesApp } from "components/Roles/RolesApp";
import { EmployeesRoute } from "components/Employee/EmployeesRoute";
import { ElectronicPrescribingRoute } from "components/Erx/ElectronicPrescribingRoute";
import { RoleGuardHide, RoleGuardLock } from "components/Main/RoleGuard";
import { PermissionAction } from "components/Roles/constants";

export const EmployeesManagementApp: FC = () => {
  const employeesLinks = useEmployeesLinks();
  const tabs = useMemo((): {
    label: string;
    path?: string;
    to: string;
    element: JSX.Element;
    index?: boolean;
    end?: boolean;
    domain: PermissionActionVO["domain"];
    action: PermissionAction;
  }[] => {
    return [
      {
        label: "Profiles",
        index: true,
        to: employeesLinks.links.employees,
        domain: "EMPLOYEE_SETTINGS",
        action: "ACCESS_ALL",
        element: (
          <RoleGuardLock domain="EMPLOYEE_SETTINGS" action="ACCESS_ALL">
            <EmployeesRoute />
          </RoleGuardLock>
        ),
        end: true,
      },
      {
        label: "Payroll",
        path: "payrolls/*",
        to: employeesLinks.links.payroll,
        element: (
          <RoleGuardLock domain="EMPLOYEE_SETTINGS" action="RUN_PAYROLL">
            <PayrollApp />
          </RoleGuardLock>
        ),
        domain: "EMPLOYEE_SETTINGS",
        action: "RUN_PAYROLL",
      },
      {
        label: "Roles",
        path: "roles/*",
        to: employeesLinks.links.roles,
        element: (
          <RoleGuardLock domain="EMPLOYEE_SETTINGS" action="EDIT_ROLE">
            <RolesApp />
          </RoleGuardLock>
        ),
        domain: "EMPLOYEE_SETTINGS",
        action: "EDIT_ROLE",
      },
      {
        label: "ePrescribing",
        path: "electronic-prescribing",
        to: employeesLinks.links.electronicPrescribing,
        element: (
          <RoleGuardLock domain="EMPLOYEE_SETTINGS" action="ACCESS_ALL">
            <ElectronicPrescribingRoute />
          </RoleGuardLock>
        ),
        domain: "EMPLOYEE_SETTINGS",
        action: "ACCESS_ALL",
      },
    ];
  }, [
    employeesLinks.links.employees,
    employeesLinks.links.payroll,
    employeesLinks.links.roles,
    employeesLinks.links.electronicPrescribing,
  ]);

  return (
    <LeftAsideTemplate
      asideContent={
        <div className="flex flex-col h-full">
          <div className="flex items-center h-14 px-6 font-sansSemiBold">Employees</div>
          <div className="flex-1 flex flex-col gap-y-2 min-h-0 p-3 overflow-y-auto">
            {tabs.map((tab) => (
              <RoleGuardHide key={tab.to} domain={tab.domain} action={tab.action}>
                <NavLink
                  className={({ isActive }) =>
                    cx(
                      `block
                       hover:bg-slate-200
                       rounded
                       p-3
                       font-sansSemiBold
                       text-sm`,
                      isActive && "text-primaryTheme"
                    )
                  }
                  end={tab.end}
                  to={tab.to}
                >
                  <BoldSpaced>{tab.label}</BoldSpaced>
                </NavLink>
              </RoleGuardHide>
            ))}
          </div>
        </div>
      }
    >
      <SentryRoutes>
        {tabs.map((route, index) => (
          <Route key={index} index={route.index} path={route.path} element={route.element} />
        ))}
        <Route path="*" element={<NotFound />} />
      </SentryRoutes>
    </LeftAsideTemplate>
  );
};
