import { FC, useState } from "react";
import { DocumentVO, FolderVO, UpdateDocumentRequest } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Form } from "@libs/components/UI/Form";
import { Modal } from "@libs/components/UI/Modal";
import { ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { DateWithTooltip } from "components/UI/DateWithTooltip";

interface Props {
  document: DocumentVO;
  folders?: FolderVO[];
  isSaving: boolean;
  onSave: (documentDraft: UpdateDocumentRequest) => void;
  onCloseRequest: Func;
}

export const DocumentModal: FC<Props> = ({ document, folders, isSaving, onSave, onCloseRequest }) => {
  const [documentDraft, setDocumentDraft] = useState<Required<UpdateDocumentRequest>>({
    name: document.name,
    folderId: document.folderId,
  });
  const validation = useValidation(documentDraft, {
    name: { $validations: [{ $v: required, $error: "Document name is required" }] },
  });

  const handleSave = () => {
    const form = validation.validate();

    if (!form.$isValid) {
      return;
    }

    onSave(documentDraft);
  };

  const filenameParts = documentDraft.name.split(".");
  const docExtension = filenameParts.pop(); // pop the extension
  const formId = "document";

  return (
    <Modal title="Edit Document" onClose={onCloseRequest}>
      <ModalContent padding="sm">
        <Form
          id={formId}
          className="flex flex-col gap-y-6"
          onSubmit={(e) => {
            e.preventDefault();
            handleSave();
          }}
        >
          <div className="grid gap-x-9 grid-cols-2 items-center">
            <div className="flex gap-x-2 items-end">
              <FormFieldInput
                label="Name"
                className="grow"
                required
                autoFocus
                autoComplete="off"
                value={filenameParts.join(".")}
                onChange={(e) =>
                  setDocumentDraft((last) => ({
                    ...last,
                    ...(docExtension && { name: `${e.target.value}.${docExtension}` }),
                  }))
                }
                error={validation.result.name.$error}
              />
              <div className="text-xs relative bottom-2">.{docExtension}</div>
            </div>
            <FormFieldSelect
              label="Folder"
              required
              options={(folders || []).map((folder) => ({ label: folder.name, value: folder.id }))}
              value={documentDraft.folderId}
              onItemChanged={(newFolderId) =>
                newFolderId && setDocumentDraft((last) => ({ ...last, folderId: newFolderId }))
              }
            />
          </div>
          <div
            className={`
              flex
              items-center
              py-4
              px-5
              text-sm
              gap-x-3
              outline
              outline-1
              outline-greyLighter
              rounded
            `}
          >
            <div>
              <b>Type:</b> {document.type}
            </div>
            <Divider />
            <div>
              <b>Created on:</b>{" "}
              <DateWithTooltip date={document.metadata?.createdAt || 0} dateAsSeconds={true} format="PPP" />
            </div>
            <Divider />
            <div>
              <b>Created by:</b> {document.metadata?.createdBy?.fullDisplayName}
            </div>
          </div>
        </Form>
      </ModalContent>
      <ModalFooter>
        <Button theme="secondary" className="w-28" onClick={onCloseRequest}>
          Cancel
        </Button>
        <AsyncButton form={formId} type="submit" className="w-28" isLoading={isSaving}>
          Save
        </AsyncButton>
      </ModalFooter>
    </Modal>
  );
};

const Divider: FC = () => <div className="w-px h-4 bg-greyLighter" />;
