import { PayrollLineItemVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as WarningIcon } from "@libs/assets/icons/error.svg";
import { TextCell } from "@libs/components/UI/GridTableComponents";

interface Props {
  lineItem: PayrollLineItemVO;
}

export const PayrollEmployeeCell: React.FC<Props> = ({ lineItem }) => (
  <TextCell className="flex items-center gap-x-2">
    <div className="flex items-center min-h-5 overflow-hidden">
      <p className={cx("truncate", !lineItem.isOnboardedWithGusto && "text-orange")}>
        {lineItem.employee.fullDisplayName}
      </p>
    </div>
    {lineItem.isOnboardedWithGusto ? null : (
      <Icon
        SvgIcon={WarningIcon}
        tooltip={{ content: "Not Onboarded", theme: "SMALL" }}
        theme="warning"
        size="sm"
      />
    )}
  </TextCell>
);
