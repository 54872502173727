import React, { useId, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { CreateScheduleBlockRequest, ScheduleBlockVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { usePick } from "@libs/hooks/usePick";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { MainContent } from "@libs/components/UI/MainContent";
import { getPracticeRoomsQuery } from "api/scheduling/queries";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { paths } from "utils/routing/paths";
import { createScheduleBlock } from "api/scheduling/mutations";
import { handleError } from "utils/handleError";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";
import { EventModalPage } from "components/ScheduleAppointments/EventModalPage";
import { BlockForm } from "components/ScheduleAppointments/BlockForm";
import { EventModalTitleContent } from "components/ScheduleAppointments/EventModalTitleContent";
import { usePatientAppointmentContext } from "contexts/PatientAppointmentContext";

export const ScheduleBlockRoute: React.FC = () => {
  const { practiceId } = useAccount();
  const [savedBlock, setSavedBlock] = useState<null | ScheduleBlockVO>(null);
  const formDirty = useBoolean(false);
  const { query } = useQueryParams("scheduleBlock");
  const { date, startTime } = usePathParams("scheduleBlock");
  const queries = useApiQueries([
    getPracticeProvidersQuery({ args: { practiceId } }),
    getPracticeRoomsQuery({ args: { practiceId } }),
  ]);
  const { patientId, appointmentId } = usePatientAppointmentContext();

  const [createScheduleBlockMutation] = useApiMutations([createScheduleBlock]);
  const [providersQuery, roomsQuery] = queries;
  const backUrl = query.from ?? paths.schedule();

  const handleSubmit = (data: CreateScheduleBlockRequest) => {
    createScheduleBlockMutation.mutate(
      {
        practiceId,
        data,
      },
      {
        onSuccess: (response) => setSavedBlock(response.data.data),
        onError: handleError,
      }
    );
  };

  const blockLink = useMemo(
    () =>
      paths.scheduleAppointment(
        { date, startTime },
        {
          dentistId: query.dentistId,
          hygienistId: query.hygienistId,
          roomId: query.roomId,
          duration: query.duration,
          patientId,
          appointmentId,
          from: query.from,
        }
      ),
    [
      date,
      startTime,
      patientId,
      appointmentId,
      query.from,
      query.dentistId,
      query.hygienistId,
      query.roomId,
      query.duration,
    ]
  );

  const picked = usePick(query, ["dentistId", "hygienistId", "roomId"]);
  const queryFormProps = useMemo(() => {
    const definedIds = [picked.hygienistId, picked.dentistId].filter((val) => val > 0);

    return {
      providerIds: definedIds.length ? definedIds : undefined,
      roomIds: picked.roomId ? [picked.roomId] : undefined,
    };
  }, [picked]);

  const formId = useId();

  return (
    <MainContent>
      <EventModalPage
        formId={formId}
        backUrl={backUrl}
        title={
          <EventModalTitleContent title="Add Time Block">
            <Link to={blockLink} className="text-sm text-primaryTheme font-sansSemiBold">
              Add Appointment
            </Link>
          </EventModalTitleContent>
        }
        hasSavedEvent={Boolean(savedBlock)}
        eventUrl={
          savedBlock
            ? paths.schedule({
                // If the block is only for providers show group by providers view
                groupAppointmentsBy:
                  savedBlock.providers.length && !savedBlock.rooms.length ? "provider" : "room",
                date: savedBlock.date,
              })
            : ""
        }
        isSaving={createScheduleBlockMutation.isLoading}
        isFormValid={true}
        isDirty={formDirty.isOn}
        savedValue={savedBlock}
        savedMessage="Time Block Created"
        promptMessage="Do you want to discard this time block?"
      >
        <QueryResult queries={[providersQuery, roomsQuery]}>
          {providersQuery.data && roomsQuery.data ? (
            <BlockForm
              {...queryFormProps}
              duration={query.duration}
              startTime={startTime}
              date={date}
              formId={formId}
              rooms={roomsQuery.data}
              providers={providersQuery.data}
              onSubmit={handleSubmit}
              onDirty={formDirty.on}
            />
          ) : null}
        </QueryResult>
      </EventModalPage>
    </MainContent>
  );
};
