import { FC, PropsWithChildren } from "react";

import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";
import {
  AttachFilesContent,
  AttachFilesContentProps,
} from "components/UI/AttachModalContent/AttachFilesContent";

interface Props extends AttachFilesContentProps {
  canUploadFiles: boolean;
  isUploading: boolean;
  onUploadFiles: () => Promise<void>;
  onRequestClose: Func;
}

export const AttachFilesModalContent: FC<PropsWithChildren<Props>> = ({
  canUploadFiles,
  isUploading,
  onUploadFiles,
  onRequestClose,
  children,
  ...contentProps
}) => {
  return (
    <>
      <ModalContent className="flex flex-col gap-y-6 px-8 py-6">
        <AttachFilesContent {...contentProps} />
        {children}
      </ModalContent>

      <ModalFooter>
        <Button className="min-w-button" onClick={onRequestClose} theme="secondary">
          Cancel
        </Button>
        <AsyncButton
          className="min-w-button"
          onClick={onUploadFiles}
          isLoading={isUploading}
          disabled={!canUploadFiles}
          theme="primary"
        >
          Upload
        </AsyncButton>
      </ModalFooter>
    </>
  );
};
