import { CollectionAdjustmentVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { formatISODate } from "@libs/utils/date";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Row } from "@libs/components/UI/GridTableComponents";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";
import { paths } from "utils/routing/paths";
import { OpenPatientIcon } from "components/Dashboard/Tables/OpenPatientIcon";
import { PatientNameLink } from "components/Dashboard/Tables/PatientNameLink";

type Props = {
  row: CollectionAdjustmentVO;
  last?: boolean;
};
export const CollectionAdjustmentsTableRow: React.FC<Props> = ({ row, last }) => {
  const { patient, adjustmentDate, adjustmentName, adjustmentNote, collectionAdjustmentAmount } = row;
  const cellProps = { last, to: paths.patient({ patientId: patient.id }) };

  return (
    <Row>
      <DashboardLinkCell {...cellProps}>
        <PatientNameLink patient={patient} />
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{formatISODate(adjustmentDate, "MM/dd/yyyy")}</DashboardLinkCell>

      <DashboardLinkCell {...cellProps}>{adjustmentName}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        <FloatingTooltip content={adjustmentNote} theme="MEDIUM">
          <div className="truncate">{adjustmentNote || ""}</div>
        </FloatingTooltip>
      </DashboardLinkCell>

      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(collectionAdjustmentAmount)}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        <OpenPatientIcon />
      </DashboardLinkCell>
    </Row>
  );
};
