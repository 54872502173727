import { localPoint } from "@visx/event";
import React from "react";

export const useOnRectMouseMoved = (
  onMove: (params: { tooltipTop?: number; tooltipLeft?: number }) => void
) => {
  const onRectMove: React.MouseEventHandler<SVGRectElement> = React.useCallback(
    (e) => {
      const eventSvgCoords = localPoint(e);

      onMove({
        tooltipTop: eventSvgCoords?.y,
        tooltipLeft: eventSvgCoords?.x,
      });
    },
    [onMove]
  );

  return onRectMove;
};
