import { FC, useMemo } from "react";
import { produce } from "immer";
import { FormSelectInputElementRequest, FormVO } from "@libs/api/generated-api";
import { toggleSet } from "@libs/utils/toggleSet";
import { isOneOf } from "@libs/utils/isOneOf";
import { isDefined } from "@libs/utils/types";
import { ReactComponent as MultiselectIcon } from "@libs/assets/icons/checklist.svg";
import { ReactComponent as SingleSelectIcon } from "@libs/assets/icons/single-choice.svg";
import { ReactComponent as YesNoIcon } from "@libs/assets/icons/true-false.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { RequiredCheckbox } from "components/Settings/Forms/RequiredCheckbox";
import { InfoCheckbox } from "components/Settings/Forms/InfoCheckbox";
import { EditChoices } from "components/Settings/Forms/MultipleChoice/EditOptions";
import { Divider } from "components/Settings/Forms/Divider";

import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { MultipleChoiceFormState, MultipleChoiceValidationResult, EditableOption } from "./schema";

const typeSettings: ListItem<FormSelectInputElementRequest["settings"]>[] = [
  "ALLOW_MULTIPLE_SELECTIONS",
  "ENFORCE_EXPLICIT_CONSENT",
];

const TypeOption: FC<{ Icon: IconComponent; title: string; description: string }> = ({
  Icon,
  title,
  description,
}) => (
  <div className="flex items-center gap-x-5">
    <Icon className="w-5 h-5" />
    <div className="flex-1">
      <p>{title}</p>
      <p>{description}</p>
    </div>
  </div>
);

export const MultipleChoiceFormContent: FC<{
  validation: MultipleChoiceValidationResult;
  formState: UseState<MultipleChoiceFormState>;
  formType: FormVO["type"];
}> = ({ formType, validation, formState: [{ draft }, setForm] }) => {
  const type =
    useMemo(() => draft.settings.find((setting) => isOneOf(setting, typeSettings)), [draft.settings]) ?? "";

  const handleSelectType = (value: ListItem<typeof typeSettings> | "") => {
    setForm((last) =>
      produce(last, (next) => {
        next.draft.settings = next.draft.settings.filter((val) => !isOneOf(val, typeSettings));

        if (value) {
          next.draft.settings.push(value);
        }

        if (value === "ENFORCE_EXPLICIT_CONSENT") {
          next.draft.settings = next.draft.settings.filter((val) => val !== "ALLOW_ADDITIONAL_OPTION");
        }
      })
    );
  };

  const handleToggleSetting = (value: ListItem<typeof draft.settings>) => {
    setForm((last) =>
      produce(last, (next) => {
        next.draft.settings = [...toggleSet(new Set(next.draft.settings), value)];
      })
    );
  };

  const handleToggleFormatSetting = (value: ListItem<typeof draft.settings>) => {
    setForm((last) =>
      produce(last, (next) => {
        next.draft.settings = [...toggleSet(new Set(next.draft.settings), value)];
      })
    );
  };

  const addOption = (option: EditableOption) => {
    setForm((last) =>
      produce(last, (next) => {
        next.draft.options.push(option);
      })
    );
  };

  const removeOption = (removeIndex: number) => {
    setForm((last) =>
      produce(last, (next) => {
        next.draft.options = next.draft.options.filter((_, index) => index !== removeIndex);
      })
    );
  };

  const updateOption = (updateIndex: number, newValue: string) => {
    setForm((last) =>
      produce(last, (next) => {
        next.draft.options[updateIndex] = {
          ...next.draft.options[updateIndex],
          value: newValue,
        };
      })
    );
  };

  const sortOptions = (sortedOptions: EditableOption[]) => {
    setForm((last) =>
      produce(last, (next) => {
        next.draft.options = sortedOptions;
      })
    );
  };

  const handleUpdateTitle = (value: string) => {
    setForm((last) =>
      produce(last, (next) => {
        next.draft.title = value;
      })
    );
  };

  const options = [
    draft.tag && isOneOf(draft.tag, ["DENTAL_CONDITIONS", "MEDICAL_CONDITIONS"])
      ? undefined
      : { value: "" as const, label: "Single Select" },
    { value: "ALLOW_MULTIPLE_SELECTIONS" as const, label: "Multiselect" },
    formType === "INTAKE" ? { value: "ENFORCE_EXPLICIT_CONSENT" as const, label: "Explicit" } : undefined,
  ].filter(isDefined);

  return (
    <div className="flex flex-col gap-y-4">
      <FormFieldInput
        label="Question"
        required={true}
        value={draft.title}
        error={validation.title.$error}
        onChange={(e) => handleUpdateTitle(e.target.value)}
      />
      <FormFieldSelect
        label="Response Type"
        value={type}
        isClearable={false}
        isSearchable={false}
        onItemSelected={handleSelectType}
        formatOptionLabel={(option, meta) => {
          if (meta.context === "value") {
            return option.label;
          }

          return option.value === "ALLOW_MULTIPLE_SELECTIONS" ? (
            <TypeOption
              Icon={MultiselectIcon}
              description="Users can select multiple options"
              title={option.label}
            />
          ) : option.value === "ENFORCE_EXPLICIT_CONSENT" ? (
            <TypeOption
              Icon={YesNoIcon}
              description="Users must answer yes or no to every option"
              title={option.label}
            />
          ) : (
            <TypeOption
              Icon={SingleSelectIcon}
              description="Users can only select one option"
              title={option.label}
            />
          );
        }}
        options={options}
      />
      {formType === "INTAKE" ? (
        <RequiredCheckbox
          disabled={type === "ENFORCE_EXPLICIT_CONSENT"}
          checked={draft.settings.includes("REQUIRED") || type === "ENFORCE_EXPLICIT_CONSENT"}
          onChange={() => handleToggleSetting("REQUIRED")}
          tooltip={
            type === "ALLOW_MULTIPLE_SELECTIONS"
              ? "This will make this field required. The patient will have to select at least one option in order to submit the form."
              : type === ""
                ? "This will make this field required. The patient will have to select an option in order to submit the form."
                : ""
          }
        />
      ) : null}
      <InfoCheckbox
        label="Allow other"
        disabled={type === "ENFORCE_EXPLICIT_CONSENT"}
        tooltip={
          type === "ENFORCE_EXPLICIT_CONSENT"
            ? ""
            : "This will allow selecting “other” to provide an alternate response as text."
        }
        checked={draft.settings.includes("ALLOW_ADDITIONAL_OPTION")}
        onChange={() => handleToggleFormatSetting("ALLOW_ADDITIONAL_OPTION")}
      />

      <Divider />
      <EditChoices
        immutable={Boolean(draft.tag)}
        errors={validation.options}
        onSort={sortOptions}
        choices={draft.options}
        onAdd={addOption}
        onRemove={removeOption}
        onUpdate={updateOption}
      />
    </div>
  );
};
