import { format } from "date-fns";
import React, { CSSProperties, useCallback } from "react";
import { MedicalImageVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as ToothIcon } from "@libs/assets/icons/tooth-2.svg";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus-small.svg";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { DatePickerBase } from "components/UI/DatePickerBase";
import { SensorAssignmentMenu } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/SensorAssignmentMenu";
import { ImagingDeviceWithSettings } from "components/PatientProfile/Imaging/hooks/useImagingDevices";

import { IMAGE_METADATA_HEIGHT } from "components/PatientProfile/Imaging/PatientMountsList/mountLayouts";
import { getTeethLabel } from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { ProposedImageChanges } from "components/PatientProfile/Imaging/types/imageEditor";

type Props<T extends MedicalImageVO> = {
  image: T;
  onImageUpdate?: (image: T, params: ProposedImageChanges) => void;
  devices?: ImagingDeviceWithSettings[];
  style?: CSSProperties;
  className?: string;
  onClickTeeth?: (image: MedicalImageVO) => void;
  disabled?: boolean;
};

// We need to memoize this component, because it gets re-rendered mid-showing the selection menu for month/year. If the props and state haven't changed, we don't need this re-render
const ImageViewMetadataDatePicker: React.FC<{
  date?: Date;
  onClose: Func;
  isOpen: boolean;
  onInputClick: Func;
  onChange: (date: Date) => void;
  disabled?: boolean;
}> = React.memo(({ date, onClose, isOpen, onChange, onInputClick, disabled }) => {
  const maxDate = React.useMemo(() => new Date(), []);

  return (
    <DatePickerBase
      popperClassName="z-40"
      className={`
        bg-transparent
        text-white
        w-24
        cursor-pointer
        text-center
        hover:text-primaryTheme
        disabled:text-greyLight
      `}
      selected={date}
      calendarStartDay={0}
      showMonthDropdown
      showYearDropdown
      dateFormat="M/d/yyyy"
      dropdownMode="select"
      onCalendarClose={onClose}
      onInputClick={onInputClick}
      onClickOutside={onClose}
      open={isOpen}
      yearDropdownItemNumber={10}
      maxDate={maxDate}
      scrollableYearDropdown
      onChange={onChange}
      disabled={disabled}
    />
  );
});

// eslint-disable-next-line complexity
export const ImageViewMetadata = <T extends MedicalImageVO>({
  image,
  onImageUpdate,
  devices,
  style,
  className,
  onClickTeeth,
  disabled,
}: Props<T>) => {
  const dateDisplayedISO = image.assignedDate || image.createdDate;
  const dateDisplayed = React.useMemo(() => {
    return dateDisplayedISO ? getLocalDate(dateDisplayedISO) : undefined;
  }, [dateDisplayedISO]);
  const isPrintingComponent = !onClickTeeth;
  const [menuOpen, setMenuOpen] = React.useState<"sensor" | "date" | undefined>();
  const closeMenus = React.useCallback(() => setMenuOpen(undefined), []);
  const turnOnSensorMenu = React.useCallback(() => setMenuOpen("sensor"), []);
  const teethLabel = React.useMemo(() => {
    return getTeethLabel(image.teeth);
  }, [image.teeth]);
  const handleMetadataChanged = React.useCallback(
    (changes: ProposedImageChanges) => {
      if (onImageUpdate) {
        onImageUpdate(image, changes);
      }
    },
    [image, onImageUpdate]
  );
  const handleDateChanged = React.useCallback(
    (date: Date) => {
      closeMenus();

      handleMetadataChanged({
        assignedDate: formatAsISODate(date),
      });
    },
    [closeMenus, handleMetadataChanged]
  );

  const handleSensorChanged = React.useCallback(
    (sensor?: string) => {
      handleMetadataChanged({
        sensor,
      });
    },
    [handleMetadataChanged]
  );
  const handleTeethClick = React.useCallback(() => {
    if (onClickTeeth) {
      onClickTeeth(image);
    }
  }, [image, onClickTeeth]);

  const handleDatepickerInputClick = useCallback(() => {
    if (menuOpen === "date") {
      closeMenus();
    } else {
      setMenuOpen("date");
    }
  }, [closeMenus, menuOpen]);

  if (image.createdDate === undefined) {
    return null;
  }

  return (
    <div
      className={cx(
        `bg-greyMedium
         text-white
         px-1
         rounded-b-md
         flex
         items-center
         gap-1
         w-full
         justify-between`,
        className
      )}
      style={{
        height: IMAGE_METADATA_HEIGHT,
        ...style,
      }}
    >
      <FloatingTooltip
        content={teethLabel ? `Teeth: ${teethLabel}` : onClickTeeth ? "Label Teeth" : "No Teeth Labeled"}
        theme={teethLabel ? "MEDIUM" : "SMALL"}
      >
        {isPrintingComponent ? (
          <div className="px-4 -ml-2 truncate">{teethLabel ?? EMPTY_CELL}</div>
        ) : (
          <button
            type="button"
            className={`
              hover:text-primaryTheme
              disabled:text-greyLight
              px-4
              -ml-2
              truncate
              py-2
              -my-2
              flex
              items-center
            `}
            disabled={disabled}
            onClick={handleTeethClick}
          >
            {teethLabel ?? (
              <>
                <ToothIcon className="h-4 w-4" /> <PlusIcon className="h-3.5 w-3.5" />
              </>
            )}
          </button>
        )}
      </FloatingTooltip>

      <div className="flex">
        <FloatingTooltip
          content={
            image.createdDate && image.createdDate !== image.assignedDate && menuOpen !== "date"
              ? `Original Date: ${format(getLocalDate(image.createdDate), "M/d/yyyy")}`
              : undefined
          }
          theme="MEDIUM"
        >
          <div>
            {isPrintingComponent ? (
              dateDisplayed ? (
                format(dateDisplayed, "MM/dd/yyyy")
              ) : null
            ) : (
              <ImageViewMetadataDatePicker
                disabled={disabled}
                date={dateDisplayed}
                onInputClick={handleDatepickerInputClick}
                onClose={closeMenus}
                isOpen={menuOpen === "date"}
                onChange={handleDateChanged}
              />
            )}
          </div>
        </FloatingTooltip>
        {devices && (
          <SensorAssignmentMenu
            disabled={disabled}
            isOpen={menuOpen === "sensor"}
            onClose={closeMenus}
            sensorSelected={image.sensor}
            onRequestOpen={turnOnSensorMenu}
            onChange={handleSensorChanged}
            devices={devices}
          />
        )}
      </div>
    </div>
  );
};
