import React from "react";
import { UseCameraCaptureState } from "components/ImageCapturing/useCameraCapture";
import { CaptureDevice } from "components/PatientProfile/Imaging/MountRoute/MountHeader/types";
import { useTwain } from "components/ImageCapturing/useTwain";

import { useImagingDevices } from "components/PatientProfile/Imaging/hooks/useImagingDevices";
import { useImagingHub } from "components/ImageCapturing/ImagingHubContext";
import { CaptureMode } from "components/PatientProfile/Imaging/MountRoute/types";

export const useSelectedCaptureDevice = ({
  deviceId,
  cameraCapture,
}: {
  deviceId?: string;
  cameraCapture: UseCameraCaptureState;
}) => {
  const twain = useTwain();
  const { status: hubStatus } = useImagingHub();
  const {
    availableDevicesQuery: { data: archyAgentDevices },
  } = useImagingHub();

  const { devices } = useImagingDevices(cameraCapture);

  const captureDevice: CaptureDevice = React.useMemo(() => {
    const mode: CaptureMode = "EXTERNAL";

    if (!deviceId) {
      return {
        mode,
      };
    }

    if (hubStatus.isRunning && !hubStatus.isAISDisabled) {
      const archyDevice = archyAgentDevices?.find((item) => item.name === deviceId);

      if (archyDevice) {
        return {
          deviceId,
          mode: "X_RAY",
          label: archyDevice.name,
        };
      }
    } else {
      const twainDevice = twain.twainSources?.items?.find((item) => {
        return deviceId === item.label;
      });

      if (twainDevice) {
        return {
          deviceId,
          mode: "X_RAY",
          label: twainDevice.label,
        };
      }
    }

    const photoDevice =
      cameraCapture.cameraSources?.sources?.find((current) => {
        return deviceId === current.deviceId;
      }) ?? undefined;

    return {
      deviceId,
      ...photoDevice,
      mode: photoDevice ? "PHOTO" : "UNKNOWN",
    };
  }, [
    deviceId,
    hubStatus,
    cameraCapture.cameraSources?.sources,
    archyAgentDevices,
    twain.twainSources?.items,
  ]);
  // captureDevice can be initially set in the url, and then user can also choose a more transient value by capturing via other means
  const [selectedCaptureDevice, setSelectedCaptureDevice] = React.useState<CaptureDevice>(captureDevice);

  React.useEffect(() => {
    setSelectedCaptureDevice(captureDevice);
  }, [captureDevice]);

  return {
    selectedCaptureDevice,
    handleSelectedCaptureDeviceChanged: setSelectedCaptureDevice,
    devices,
  };
};
