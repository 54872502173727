import { Pill } from "@libs/components/UI/Pill";
import { FC, useCallback } from "react";
import { ClaimsConfigVO, SuccessResponseClaimReceiverUrlVO } from "@libs/api/generated-api";
import { ReactComponent as CustomerChatIcon } from "@libs/assets/icons/customer-chat.svg";
import { ReactComponent as DentalXChangeIcon } from "@libs/assets/icons/dentalxchange.svg";
import { Link } from "react-router-dom";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import claimsImage from "assets/images/claims.svg";
import { IconCard } from "components/UI/IconCard";
import { MailToLink } from "components/UI/MailToLink";
import { paths } from "utils/routing/paths";
import { getReceiverLoginUrlQuery } from "api/claim/queries";
import { handleError } from "utils/handleError";
import { SettingsHeroContainer, SettingsHeroContent } from "components/Settings/SettingsHeroLayout";

export const DentalXChangeStatus: FC<{ claimsConfig: ClaimsConfigVO }> = ({ claimsConfig }) => {
  const isActive = Boolean(claimsConfig.dentalXChangeUsername);
  const { practiceId } = useAccount();
  const [receiverLoginUrlQuery] = useApiQueries([
    getReceiverLoginUrlQuery({ args: { practiceId }, queryOptions: { enabled: false } }),
  ]);

  const handleOpenDentalXChange = useCallback(async () => {
    try {
      const result = await receiverLoginUrlQuery.refetch<SuccessResponseClaimReceiverUrlVO>();

      if (result.error) {
        handleError(result.error);

        return;
      }

      const loginUrl = result.data!.data.data.loginUrl;

      window.open(loginUrl, "_blank");
    } catch (error) {
      handleError(error);
    }
  }, [receiverLoginUrlQuery]);

  return (
    <SettingsHeroContent className="flex flex-col gap-y-6">
      <SettingsHeroContainer image={<img src={claimsImage} alt="Stack of claims forms" />}>
        <div className="flex flex-col gap-y-2">
          <Pill theme={isActive ? "green" : "grey"} className="self-start">
            {isActive ? "Active" : "Inactive"}
          </Pill>
          <div className="text-xs/4 text-slate-900">
            {isActive ? (
              <span>
                Your DentalXChange account number is{" "}
                <span className="font-sansBold">{claimsConfig.accountNumber}</span>. If you need to log in to
                DentalXChange you can do so via the link below.
              </span>
            ) : (
              "Archy uses DentalXChange as the clearinghouse to submit insurance claims. Complete onboarding to begin sending claims."
            )}
          </div>
          <div className="font-sansSemiBold text-xs/4 text-primaryTheme">
            {isActive ? (
              <AsyncButton
                type="button"
                theme="link"
                isLoading={receiverLoginUrlQuery.isLoading}
                onClick={handleOpenDentalXChange}
              >
                DentalXChange Login
              </AsyncButton>
            ) : (
              <Link to={paths.settingsActivateDentalXChange()}>Activate DentalXChange</Link>
            )}
          </div>
        </div>
      </SettingsHeroContainer>
      <div className="flex items-stretch gap-x-6">
        <IconCard
          className="flex-1"
          SvgIcon={DentalXChangeIcon}
          title="What is DentalXChange?"
          description="DentalXChange is a clearinghouse to send claims to insurance companies."
          layout="stack"
          padding="sm"
        />
        <IconCard
          className="flex-1"
          SvgIcon={CustomerChatIcon}
          title="Need Help?"
          description={
            <span>
              If you are having trouble setting up DentalXChange, please contact{" "}
              <MailToLink email="support@archy.com">Archy Support</MailToLink>.
            </span>
          }
          layout="stack"
          padding="sm"
        />
      </div>
    </SettingsHeroContent>
  );
};
