import React from "react";
import { BASE_CHART_MARGINS } from "components/Dashboard/Charting/theme";

export const useCenteredChartMargins = (width: number) => {
  return React.useMemo(() => {
    const X_PADDING_FACTOR = 0.18;
    const CENTER_PADDING = width * X_PADDING_FACTOR;
    const chartMargin = { ...BASE_CHART_MARGINS };
    const axisMargin = { ...chartMargin };

    chartMargin.left = CENTER_PADDING;
    chartMargin.right = CENTER_PADDING;

    return {
      axisMargin,
      chartMargin,
    };
  }, [width]);
};
