import { FC, ReactNode } from "react";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { TooltipLabel } from "components/UI/TooltipLabel";

export const InfoCheckbox: FC<{
  checked: boolean;
  disabled?: boolean;
  onChange: Func;
  tooltip?: string | JSX.Element | undefined;
  label: ReactNode;
}> = ({ tooltip, label, checked, disabled, onChange }) => {
  return (
    <Checkbox checked={checked} disabled={disabled} onChange={onChange}>
      {tooltip ? <TooltipLabel tooltip={{ content: tooltip }}>{label}</TooltipLabel> : label}
    </Checkbox>
  );
};
