import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import {
  FamilyMemberResponse,
  FamilyMemberVO,
  MultiInvoicePaymentVO,
  PatientVO,
  PaymentCreationRequest,
  PaymentDeviceVO,
  PaymentProfileVO,
  PaymentVO,
  RefundablePaymentVO,
  WalletVO,
} from "@libs/api/generated-api";
import { getFirstItem } from "@libs/utils/array";
import { useObjectState } from "@libs/hooks/useObjectState";
import { getUnixTime } from "date-fns";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { produce } from "immer";
import { useDebounceWithPendingStatus } from "@libs/hooks/useDebounceWithPendingStatus";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { PAGE_SIZE } from "@libs/utils/constants";
import {
  PLACEHOLDER_WALLET_ID,
  WalletWithFamilyMember,
  getPatientWallets,
  getPreferredPaymentMethod,
  isPaymentMethodByCard,
  reconcileWalletsWithFamilyMembers,
} from "components/PatientProfile/Billing/billingUtils";
import {
  CollectionPaymentMethod,
  getPaymentMethodOptions,
  getRefundMethodOptions,
} from "components/PatientProfile/Billing/PaymentMethods/utils";
import { useCollectPaymentFormValidation } from "components/PatientProfile/Billing/Payment/useCollectPaymentFormValidation";
import { createMultiInvoicePayment } from "api/billing/mutations";
import { handleError } from "utils/handleError";
import { listRefundablePayments } from "api/billing/queries";

export type PaymentProfileWithRefundable = {
  paymentProfile: PaymentProfileVO;
  refundableInfo?: {
    payment: PaymentVO;
    refundableAmount: number;
  };
};

const getSelectedPaymentMethod = ({
  isRefund,
  hasRefundablePayments,
  chargeablePaymentProfiles,
  selectedWallet,
  currentPaymentAmount,
}: {
  isRefund: boolean;
  currentPaymentAmount?: number;
  chargeablePaymentProfiles: PaymentProfileWithRefundable[];
  hasRefundablePayments: boolean;
  selectedWallet?: WalletWithFamilyMember;
}) => {
  const hasPaymentProfiles = isRefund ? hasRefundablePayments : Boolean(chargeablePaymentProfiles.length);

  return getPreferredPaymentMethod({
    isRefund,
    hasPaymentProfiles,
    hasWalletBalance: Boolean(selectedWallet && selectedWallet.wallet.balance >= (currentPaymentAmount ?? 0)),
  });
};

const useChargeablePaymentProfiles = ({
  patientId,
  paymentProfilesData,
}: {
  patientId: PatientVO["id"];
  paymentProfilesData: PaymentProfileVO[];
}) => {
  const chargeablePaymentProfiles: PaymentProfileWithRefundable[] = useMemo(() => {
    const sortedPaymentProfiles = [...paymentProfilesData].sort((a, b) =>
      // By order of priority:
      // 1) Patient's cards should be first, family cards next.
      // 2) Default cards should be first, non-default cards next.
      a.owner.id === patientId && b.owner.id !== patientId
        ? -1
        : a.owner.id !== patientId && b.owner.id === patientId
          ? 1
          : a.isDefault && !b.isDefault
            ? -1
            : !a.isDefault && b.isDefault
              ? 1
              : 0
    );

    return sortedPaymentProfiles.map((paymentProfile) => {
      return { paymentProfile };
    });
  }, [patientId, paymentProfilesData]);

  return chargeablePaymentProfiles;
};

export type PaymentDraftUpdateFn = (
  draft: Partial<PaymentDraft>,
  options?: {
    shouldUpdateMultipaymentInvoice?: boolean;
  }
) => void;
export type PaymentDraft = {
  paymentAmount?: number;
  paymentMethod: CollectionPaymentMethod;
  paymentDate?: Date;
  checkPayload?: PaymentCreationRequest["checkPayload"];
  eftPayload?: PaymentCreationRequest["eftPayload"];
  externalPosPayload?: PaymentCreationRequest["externalPosPayload"];
  // paymentProfileWithRefundable?: PaymentProfileWithRefundable;
  selectedPaymentPosUuid?: string;
  selectedWalletUuid?: string;
  note?: string;
  selectedRefundablePayment?: RefundablePaymentVO;
  fullOrPartial: "FULL" | "PARTIAL";
  // Holds the payment amount as a string in dollars. Useful for displaying the amount in the input
  // field while the user is typing floating point numbers. ("5" vs "5." vs "5.0" vs "5.00")
  dollarStringValue?: string;
  selectedPaymentProfileUuid?: string;
};

const getSortedWallets = ({
  patientId,
  practiceId,
  includePlaceholderWallet,
  patientWallets,
  familyMembers,
}: {
  practiceId: number;
  patientId: number;
  includePlaceholderWallet: boolean;
  patientWallets: WalletVO[];
  familyMembers: FamilyMemberVO[];
}) => {
  const wallets = reconcileWalletsWithFamilyMembers({
    wallets: getPatientWallets({
      patientId,
      practiceId,
      patientWallets,
      includePlaceholderWallet,
    }),
    familyMembers,
  });

  wallets.sort((a, b) => {
    // Patient's wallets should be first, family wallets next.
    return a.wallet.patientId === patientId && b.wallet.patientId !== patientId ? -1 : 0;
  });

  return wallets;
};

const MULTI_INVOICE_DEBOUNCE_MS = 800;

/**
 * useCollectPaymentForm handles the state for syncing the payment form with the backend.
 * it is initialized with a multiInvoicePayment result from an initial dry run.  This purely
 * provides the paymentAmount of all invoices and the maxPaymentAmount.
 *
 * An additional multiInvoicePayment fetch is needed on the first render, passing the payment parameter
 * which uses paymentAmount from the original dry run, and populates the payment defaults given
 * whether this is a refund, charge and what payment profiles are available to the user,
 * and whether their wallet balance is sufficient given the payment amount.
 *
 * When an invoice is removed, we must re-intialize the the form, because it may have switched from a refund to a charge
 * or visa versa.
 */
export const useCollectPaymentForm = ({
  patientId,
  practiceId,
  paymentDevices,
  familyMembersData,
  paymentProfilesData,
  patientWallets,
  paymentCreatedDate,
  initialDryrunData,
  includeFamily,
}: {
  patientId: PatientVO["id"];
  practiceId: number;
  paymentDevices: PaymentDeviceVO[];
  familyMembersData: FamilyMemberResponse;
  paymentProfilesData: PaymentProfileVO[];
  patientWallets: WalletVO[];
  paymentCreatedDate?: Date;
  includeFamily?: boolean;
  initialDryrunData: MultiInvoicePaymentVO;
}) => {
  const [multiInvoiceUuids, setMultiInvoiceUuids] = useState<string[]>(() =>
    initialDryrunData.invoices.map((invoice) => invoice.uuid)
  );
  const [maxAmountDue, setMaxAmountDue] = useState<number>(() => initialDryrunData.maxPaymentAmount ?? 0);
  const isRefund = maxAmountDue < 0;
  const [multiInvoicePayment, setMultiInvoicePayment] = useState<MultiInvoicePaymentVO>(initialDryrunData);
  const isInitialized = useRef(false);
  const refundableAmount = maxAmountDue < 0 ? maxAmountDue : undefined;
  const [refundablePaymentsQuery] = useApiQueries([
    listRefundablePayments({
      args: {
        practiceId,
        patientId,
        query: {
          pageNumber: 1,
          pageSize: PAGE_SIZE,
          paymentMethods: paymentDevices.length ? ["STORED_POS", "STORED_PROFILE"] : ["STORED_PROFILE"],
          paymentReferenceTypes: ["INVOICE", "BATCH_PAYMENT"],
          minAmount: Math.abs(refundableAmount ?? 0),
        },
      },
      queryOptions: { enabled: Boolean(refundableAmount) },
    }),
  ]);
  const hasRefundablePayments = Boolean(refundablePaymentsQuery.data?.length);

  const walletsWithFamilyMembers = useMemo(() => {
    return getSortedWallets({
      patientId,
      practiceId,
      familyMembers: familyMembersData.linkedFamilyMembers,
      includePlaceholderWallet: isRefund,
      patientWallets,
    });
  }, [familyMembersData.linkedFamilyMembers, isRefund, patientId, patientWallets, practiceId]);

  const hasWalletBalance = useMemo(
    () =>
      walletsWithFamilyMembers.some((walletWithFamilyMember) => walletWithFamilyMember.wallet.balance > 0),
    [walletsWithFamilyMembers]
  );
  const chargeablePaymentProfiles = useChargeablePaymentProfiles({ patientId, paymentProfilesData });

  const [paymentDraft, updatePaymentDraft] = useObjectState<PaymentDraft>(() => {
    const selectedWallet = getFirstItem(walletsWithFamilyMembers);
    const initialPaymentAmount = initialDryrunData.payment.currencyAmount.amount;

    return {
      paymentAmount: initialPaymentAmount,
      selectedWalletUuid: selectedWallet?.wallet.uuid,
      selectedPaymentPosUuid: getFirstItem(paymentDevices)?.uuid,
      paymentDate: paymentCreatedDate ?? new Date(),
      paymentMethod: getSelectedPaymentMethod({
        isRefund,
        chargeablePaymentProfiles,
        selectedWallet,
        currentPaymentAmount: initialPaymentAmount,
        hasRefundablePayments,
      }),
      selectedPaymentProfileUuid: getFirstItem(chargeablePaymentProfiles)?.paymentProfile.uuid,
      fullOrPartial: "FULL",
      idempotencyUuid: crypto.randomUUID(),
    };
  });
  const selectedWallet = useMemo(
    () =>
      walletsWithFamilyMembers.find((wallet) => wallet.wallet.uuid === paymentDraft.selectedWalletUuid)
        ?.wallet,
    [walletsWithFamilyMembers, paymentDraft.selectedWalletUuid]
  );

  // Runs validation only on the request to determine whether we should fetch new multipayment invoice from server
  const requestValidation = useCollectPaymentFormValidation({
    input: { paymentDraft, maxAmountDue, selectedWallet },
    isValidating: true,
  });
  const [{ mutate: fetchMultiInvoice, mutateAsync: fetchMultiInvoiceAsync, isLoading }] = useApiMutations([
    createMultiInvoicePayment,
  ]);

  const refreshMultiPaymentInvoice = useCallback(
    (
      updatedPaymentDraft: Partial<PaymentDraft>,
      options?: { invoiceUuids?: string[]; includeFamily?: boolean }
    ) => {
      const isValid = requestValidation.validate().$isValid && paymentDraft.paymentAmount !== 0;

      if (!isValid) {
        return;
      }

      fetchMultiInvoice(
        {
          practiceId,
          patientId,
          data: {
            commit: false,
            invoiceUuids: options?.invoiceUuids ?? multiInvoiceUuids,
            includeFamily: options?.includeFamily ?? includeFamily,
            payment: makePaymentCreationRequest({
              ...paymentDraft,
              idempotencyUuid: crypto.randomUUID(),
              paymentAmount: paymentDraft.paymentAmount ?? 0,
              ...updatedPaymentDraft,
            }),
          },
        },
        {
          onSuccess: (result) => {
            setMultiInvoicePayment(result.data.data);
            setMaxAmountDue(result.data.data.maxPaymentAmount ?? 0);
            isInitialized.current = true;
          },
          onError: handleError,
        }
      );
    },
    [
      requestValidation,
      paymentDraft,
      fetchMultiInvoice,
      practiceId,
      patientId,
      multiInvoiceUuids,
      includeFamily,
    ]
  );
  // We debounce the multi invoice request because user may edit the amount, issuing many requests while typing
  const {
    callback: debouncedRefreshMultiPaymentInvoice,
    isPending: isDebouncingMultiInvoiceRequest,
    flush: flushPaymentDraftChanges,
  } = useDebounceWithPendingStatus(refreshMultiPaymentInvoice, MULTI_INVOICE_DEBOUNCE_MS, {
    leading: false,
    trailing: true,
  });

  const handleUpdatePaymentDraft: PaymentDraftUpdateFn = useCallback(
    (newDraft, options) => {
      updatePaymentDraft(newDraft);

      if (options?.shouldUpdateMultipaymentInvoice) {
        debouncedRefreshMultiPaymentInvoice(newDraft);
      }
    },
    [debouncedRefreshMultiPaymentInvoice, updatePaymentDraft]
  );
  const handleRemoveInvoices = useCallback(
    async (removedInvoiceUuids: string[]) => {
      const newMultiInvoicePayment = produce(multiInvoicePayment, (draft) => {
        for (const invoiceUuid of removedInvoiceUuids) {
          const invoiceIndex = draft.invoices.findIndex((invoice) => invoice.uuid === invoiceUuid);

          if (invoiceIndex === -1) {
            break;
          }

          draft.invoices.splice(invoiceIndex, 1);
          draft.allocations?.splice(invoiceIndex, 1);
        }
      });
      const remainingInvoiceUuids = newMultiInvoicePayment.invoices.map((invoice) => invoice.uuid);

      setMultiInvoiceUuids(remainingInvoiceUuids);
      setMultiInvoicePayment(newMultiInvoicePayment);
      isInitialized.current = false;

      try {
        // Need to fetch the new payment amount, now that an invoice was removed
        const updatedMultiInvoice = await fetchMultiInvoiceAsync({
          practiceId,
          patientId,
          data: {
            invoiceUuids: newMultiInvoicePayment.invoices.map((invoice) => invoice.uuid),
            includeFamily,
            commit: false,
          },
        });

        const newMultiInvoice = updatedMultiInvoice.data.data;

        setMaxAmountDue(newMultiInvoice.maxPaymentAmount ?? 0);
        // Re-initialize state as refund status may have changed
        handleUpdatePaymentDraft({
          paymentAmount: newMultiInvoice.payment.currencyAmount.amount,
          fullOrPartial: "FULL",
          dollarStringValue: undefined,
        });
        flushPaymentDraftChanges();
      } catch (e) {
        handleError(e);
      }
    },
    [
      fetchMultiInvoiceAsync,
      flushPaymentDraftChanges,
      handleUpdatePaymentDraft,
      includeFamily,
      multiInvoicePayment,
      patientId,
      practiceId,
    ]
  );

  const handleIncludeFamilyChanged = useCallback(
    (updatedValue: boolean) => {
      isInitialized.current = false;
      refreshMultiPaymentInvoice({}, { includeFamily: updatedValue });
    },
    [refreshMultiPaymentInvoice]
  );

  // Holds the list of payment methods. Those can differ whether the payment is a refund or not.
  const paymentMethodOptions = useMemo(() => {
    return isRefund
      ? getRefundMethodOptions({ showRefundableCards: hasRefundablePayments })
      : getPaymentMethodOptions({
          showWallet: hasWalletBalance,
          showCreditCard: true,
        });
  }, [hasWalletBalance, isRefund, hasRefundablePayments]);

  // Re-initialize the payment form with the preferred payment method,
  // pre-selecting a POS devices, a default credit card, etc.
  // this happens when a paymentAmount switches from refund to charge or vice versa due to invoices being removed, changing the total
  useEffect(() => {
    if (isInitialized.current || refundablePaymentsQuery.isLoading) {
      return;
    }

    const latestRefundablePayments = refundablePaymentsQuery.data ?? [];

    handleUpdatePaymentDraft(
      {
        paymentMethod: getSelectedPaymentMethod({
          isRefund,
          hasRefundablePayments: latestRefundablePayments.length > 0,
          chargeablePaymentProfiles,
          selectedWallet: getFirstItem(walletsWithFamilyMembers),
          currentPaymentAmount: paymentDraft.paymentAmount,
        }),
        selectedWalletUuid: getFirstItem(walletsWithFamilyMembers)?.wallet.uuid,
        selectedPaymentPosUuid: getFirstItem(paymentDevices)?.uuid,
        selectedPaymentProfileUuid: getFirstItem(chargeablePaymentProfiles)?.paymentProfile.uuid,
        selectedRefundablePayment: getFirstItem(latestRefundablePayments),
      },
      {
        shouldUpdateMultipaymentInvoice: true,
      }
    );
  }, [
    chargeablePaymentProfiles,
    handleUpdatePaymentDraft,
    maxAmountDue,
    hasWalletBalance,
    isRefund,
    paymentDevices,
    walletsWithFamilyMembers,
    paymentDraft.paymentAmount,
    refundablePaymentsQuery.isLoading,
    refundablePaymentsQuery.data,
  ]);

  const isLoadingMultiInvoice = isLoading || isDebouncingMultiInvoiceRequest;

  return {
    selectedWallet,
    walletsWithFamilyMembers,
    paymentProfiles: chargeablePaymentProfiles,
    familyMembers: familyMembersData.linkedFamilyMembers,
    paymentDraft,
    handleUpdatePaymentDraft,
    isFetchingMultiInvoice:
      (isInitialized.current && isLoadingMultiInvoice) || refundablePaymentsQuery.isLoading,
    maxAmountDue,
    isRefund,
    paymentMethodOptions,
    multiInvoicePayment,
    isInitializing: !isInitialized.current && (isLoadingMultiInvoice || refundablePaymentsQuery.isLoading),
    multiInvoiceUuids,
    refundablePayments: refundablePaymentsQuery.data ?? [],
    handleRemoveInvoices,
    handleSuccessfulPayment: setMultiInvoicePayment,
    handleIncludeFamilyChanged,
  };
};

export const makePaymentCreationRequest: (
  draft: Omit<PaymentDraft, "paymentAmount" | "fullOrPartial" | "idempotencyUuid"> & {
    paymentAmount: number;
    generateIdempotencyUuid?: boolean;
    idempotencyUuid?: string;
  }
  // eslint-disable-next-line complexity
) => PaymentCreationRequest = ({
  paymentAmount,
  paymentMethod,
  paymentDate,
  checkPayload,
  eftPayload,
  externalPosPayload,
  selectedPaymentPosUuid,
  selectedPaymentProfileUuid,
  selectedRefundablePayment,
  selectedWalletUuid,
  idempotencyUuid,
  note,
}) => {
  const paymentRequest: PaymentCreationRequest = {
    type: paymentAmount >= 0 ? "CHARGE" : "REFUND",
    method: "STORED_PROFILE",
    currencyAmount: {
      currency: "USD",
      amount: paymentAmount,
    },
    idempotencyUuid: idempotencyUuid ?? crypto.randomUUID(),
  };

  if (paymentMethod === "REFUNDABLE_CARD") {
    if (selectedRefundablePayment) {
      // For refunds by card, the payment method used should match the original
      // payment method. If the original payment was by STORED_POS, then the
      // refund method should be by STORED_POS. Same thing for STORED_PROFILE.
      paymentRequest.method = selectedRefundablePayment.payment.method;

      // When the original payment method was "STORED_POS", we need to pass the
      // payment device uuid (any active device will do). `paymentDeviceUuid`
      // should already be set if an active POS is present.
      if (selectedRefundablePayment.payment.method === "STORED_POS") {
        paymentRequest.paymentDeviceUuid = selectedPaymentPosUuid;
      }

      paymentRequest.paymentProfileUuid = selectedRefundablePayment.payment.paymentProfile?.uuid; //paymentProfileWithRefundable.paymentProfile.uuid;
      paymentRequest.chargePaymentUuid = selectedRefundablePayment.payment.uuid;
    }
  } else {
    paymentRequest.method = paymentMethod;

    if (!isPaymentMethodByCard(paymentMethod)) {
      paymentRequest.paymentCreatedAt = paymentDate ? getUnixTime(paymentDate) : undefined;
    }
  }

  if (paymentMethod === "CHECK") {
    paymentRequest.checkPayload = checkPayload;
  }

  if (paymentMethod === "EFT") {
    paymentRequest.eftPayload = eftPayload;
  }

  if (paymentMethod === "EXTERNAL_POS") {
    paymentRequest.externalPosPayload = externalPosPayload;
  }

  if (paymentMethod === "STORED_PROFILE" && selectedPaymentProfileUuid) {
    paymentRequest.paymentProfileUuid = selectedPaymentProfileUuid;
  }

  if (paymentMethod === "STORED_POS") {
    paymentRequest.paymentDeviceUuid = selectedPaymentPosUuid;
  }

  // If the UUID is the placeholder wallet UUID, then the patient's wallet
  // doesn't exist yet. In this case, we want to send an undefined UUID so
  // that the backend will create the wallet for us.
  if (paymentMethod === "WALLET" && selectedWalletUuid !== PLACEHOLDER_WALLET_ID) {
    paymentRequest.walletUuid = selectedWalletUuid;
  }

  if (note) {
    paymentRequest.note = note;
  }

  return paymentRequest;
};
