import React, { createContext, useContext } from "react";
import { noop } from "@libs/utils/noop";
import { DrawLayer } from "components/PatientProfile/Imaging/types/imageEditor";

export interface ImageListContextValue {
  toggleLayer: (layer: DrawLayer) => void;
  layersShown: Set<DrawLayer>;
  setLayersShown: React.Dispatch<React.SetStateAction<Set<DrawLayer>>>;
}

// Currently the mount context  just tracks whether the user is viewing archived or not.
const Context = createContext<ImageListContextValue>({
  layersShown: new Set<DrawLayer>(["annotations", "filters", "pearl"]),
  setLayersShown: noop,
  toggleLayer: noop,
});

Context.displayName = "ImageListContext";
export const ImageListContext = Context;

export const useImageListContext = () => useContext(ImageListContext);

export const ImageListContextProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [layersShown, setLayersShown] = React.useState<Set<DrawLayer>>(
    new Set<DrawLayer>(["annotations", "filters", "pearl"])
  );
  const handleToggleLayer = React.useCallback(
    (layer: DrawLayer) => {
      setLayersShown((prior) => {
        const newLayers = new Set(prior);

        if (newLayers.has(layer)) {
          newLayers.delete(layer);
        } else {
          newLayers.add(layer);
        }

        return newLayers;
      });
    },
    [setLayersShown]
  );
  const contextValue = React.useMemo(() => {
    return {
      layersShown,
      toggleLayer: handleToggleLayer,
      setLayersShown,
    };
  }, [handleToggleLayer, layersShown]);

  return <ImageListContext.Provider value={contextValue}>{children}</ImageListContext.Provider>;
};
