import React, { useMemo } from "react";
import { Route } from "react-router-dom";
import { LayoutCard } from "@libs/components/UI/LayoutCard";
import { SentryRoutes } from "components/UI/SentryRoutes";

import { ActiveProviderProvider } from "components/Charting/ActiveProviderContext";
import { ClaimsTabRoute } from "components/PatientProfile/Claims/ClaimsTabRoute";
import { PreAuthsTabRoute } from "components/PatientProfile/Claims/PreAuthsTabRoute";
import { RoleGuardLock } from "components/Main/RoleGuard";
import { PatientAppHistoryProvider } from "contexts/PatientLinksContext";
import { usePathParams } from "hooks/usePathParams";
import {
  PatientClaimsLinksProvider,
  getDefaultPatientClaimsLinks,
} from "components/PatientProfile/Claims/PatientClaimsLinksContext";

export const PatientClaimsRoute: React.FC = () => {
  const { patientId } = usePathParams("patientClaims");
  const defaultLinks = useMemo(() => {
    return getDefaultPatientClaimsLinks(patientId);
  }, [patientId]);

  return (
    <RoleGuardLock domain="CLAIMS" action="ACCESS_ALL">
      <PatientAppHistoryProvider patientId={patientId} name="claims">
        <PatientClaimsLinksProvider defaultLinks={defaultLinks}>
          <LayoutCard className="flex flex-col h-full">
            <ActiveProviderProvider>
              <SentryRoutes>
                <Route index element={<ClaimsTabRoute />} />
                <Route path="pre-auths" element={<PreAuthsTabRoute />} />
              </SentryRoutes>
            </ActiveProviderProvider>
          </LayoutCard>
        </PatientClaimsLinksProvider>
      </PatientAppHistoryProvider>
    </RoleGuardLock>
  );
};
