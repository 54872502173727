import { FC, FormEvent, FocusEvent, useMemo, useCallback } from "react";

import { PracticeConnectedRemoteSettingsVO, PracticeRemoteSettingsRequest } from "@libs/api/generated-api";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useValidation } from "@libs/hooks/useValidation";
import { ReactComponent as CustomerChatIcon } from "@libs/assets/icons/customer-chat.svg";
import { ReactComponent as AnydeskIcon } from "@libs/assets/icons/anydesk.svg";

import { Form } from "@libs/components/UI/Form";
import serverAccessImage from "assets/images/server-access.svg";

import { ExternalLink } from "components/UI/ExternalLink";
import { Divider } from "components/UI/Divider";

import { usePracticeLogger } from "components/Main/PracticeSegmentContext";

import { useEditServerState } from "hooks/useEditServerState";

import {
  SettingsHeroContainer,
  SettingsHeroDetails,
  SettingsHeroTitle,
} from "components/Settings/SettingsHeroLayout";
import { IconCard } from "components/UI/IconCard";
import {
  CONNECTED_VALUE,
  getConnectedValue,
  getConnectedInputKeys,
  getRemoteSettingsSchema,
  formatRemoteSettingsRequest,
  trackChangesForSegment,
} from "./utils";

interface Props {
  formId: string;
  connectedRemoteSettings: PracticeConnectedRemoteSettingsVO;
  onUpdateRemoteSettings: (data: PracticeRemoteSettingsRequest, options?: { onSuccess?: Func }) => void;
  isEditing: boolean;
}
export const ServerAccess: FC<Props> = ({
  formId,
  connectedRemoteSettings,
  onUpdateRemoteSettings,
  isEditing,
}) => {
  const { track } = usePracticeLogger();
  const remoteSettingsServerState: PracticeRemoteSettingsRequest = useMemo(
    () => ({
      anydeskAddress: connectedRemoteSettings.anydeskAddress,
      anydeskAddress2: connectedRemoteSettings.anydeskAddress2,
      anydeskAddress3: connectedRemoteSettings.anydeskAddress3,
      serverUsername: getConnectedValue(connectedRemoteSettings.hasServerUsername),
      serverPassword: getConnectedValue(connectedRemoteSettings.hasServerPassword),
      pmsUsername: getConnectedValue(connectedRemoteSettings.hasPmsUsername),
      pmsPassword: getConnectedValue(connectedRemoteSettings.hasPmsPassword),
    }),
    [connectedRemoteSettings]
  );

  const [remoteSettings, handleChange] = useEditServerState(remoteSettingsServerState, isEditing);

  const { validate, result } = useValidation(remoteSettings, getRemoteSettingsSchema(remoteSettings));

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!validate().$isValid) {
        return;
      }

      const remoteSettingsRequest = formatRemoteSettingsRequest(remoteSettings);
      const { hasUpdatedAnydeskIds, hasUpdatedCredentials } = trackChangesForSegment(
        connectedRemoteSettings,
        remoteSettingsRequest
      );

      const { anydeskAddress, anydeskAddress2, anydeskAddress3 } = remoteSettingsRequest;
      const handleSuccess = () => {
        if (hasUpdatedAnydeskIds) {
          track(
            { event: "AnyDesk IDs Updated", domains: ["Settings"] },
            {
              properties: {
                anydeskId1: anydeskAddress,
                anydeskId2: anydeskAddress2,
                anydeskId3: anydeskAddress3,
                anydeskIds: [anydeskAddress, anydeskAddress2, anydeskAddress3].join(", "),
              },
            }
          );
        }

        if (hasUpdatedCredentials) {
          track({ event: "Server Access Information Updated", domains: ["Settings"] });
        }
      };

      onUpdateRemoteSettings(remoteSettingsRequest, { onSuccess: handleSuccess });
    },
    [validate, remoteSettings, connectedRemoteSettings, onUpdateRemoteSettings, track]
  );

  const handleConnectedFocus = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const { hasKey, key, value } = getConnectedInputKeys(e);

      if (connectedRemoteSettings[hasKey] && value === CONNECTED_VALUE) {
        handleChange((draft) => (draft[key] = undefined));
      }
    },
    [connectedRemoteSettings, handleChange]
  );

  const handleConnectedBlur = useCallback(
    (e: FocusEvent<HTMLInputElement>) => {
      const { hasKey, key, value } = getConnectedInputKeys(e);

      if (connectedRemoteSettings[hasKey] && !value) {
        handleChange((draft) => (draft[key] = CONNECTED_VALUE));
      }
    },
    [connectedRemoteSettings, handleChange]
  );

  return (
    <Form id={formId} className="flex flex-col gap-y-6 max-w-xl mx-auto" onSubmit={handleSubmit}>
      <SettingsHeroContainer image={<img src={serverAccessImage} alt="Laptop with lock" />}>
        <SettingsHeroDetails>
          <SettingsHeroTitle>Server Access</SettingsHeroTitle>
          Archy requires access to your practice&apos;s existing server for customers converting data from a
          previous PMS. Archy stores this information securely and only uses it to access your previous PMS
          data to bring into Archy.
        </SettingsHeroDetails>
      </SettingsHeroContainer>
      <div className="flex gap-x-6 items-stretch">
        <IconCard
          layout="stack"
          SvgIcon={AnydeskIcon}
          padding="sm"
          title="What is AnyDesk?"
          description={
            <>
              AnyDesk is a remote desktop application that allows our engineers to securely access your
              servers.{" "}
              <ExternalLink className="text-primaryTheme hover:text-secondaryTheme" name="anydeskDownloadUrl">
                Install AnyDesk here
              </ExternalLink>
            </>
          }
          className="flex-1"
        />

        <IconCard
          layout="stack"
          padding="sm"
          SvgIcon={CustomerChatIcon}
          title="Need Help?"
          description={
            <>
              Once you have installed AnyDesk the 9 digit ID should be displayed in red at the top of the
              screen.{" "}
              <ExternalLink
                className="text-primaryTheme hover:text-secondaryTheme"
                target="_blank"
                name="anydeskGuide"
              >
                AnyDesk Help Guide
              </ExternalLink>
            </>
          }
          className="flex-1"
        />
      </div>
      <div className="flex flex-col gap-y-1">
        <h2 className="font-sansSemiBold text-sm">AnyDesk ID</h2>
        <span className="text-xs text-slate-700">
          Install AnyDesk on any computers that Archy may need to access and provide the 9 digit IDs here.
        </span>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <FormFieldInput
          label="AnyDesk ID 1"
          value={remoteSettings.anydeskAddress}
          onChange={(e) => handleChange((draft) => (draft.anydeskAddress = e.target.value))}
          error={result.anydeskAddress.$error}
          edit={isEditing}
        />

        <FormFieldInput
          label="AnyDesk ID 2 (Optional)"
          value={remoteSettings.anydeskAddress2}
          onChange={(e) => handleChange((draft) => (draft.anydeskAddress2 = e.target.value))}
          error={result.anydeskAddress2.$error}
          edit={isEditing}
        />

        <FormFieldInput
          label="AnyDesk ID 3 (Optional)"
          value={remoteSettings.anydeskAddress3}
          onChange={(e) => handleChange((draft) => (draft.anydeskAddress3 = e.target.value))}
          error={result.anydeskAddress3.$error}
          edit={isEditing}
        />
      </div>

      <Divider className="border-dashed" />

      <div className="flex flex-col gap-y-1">
        <h2 className="font-sansSemiBold text-sm">Access Information</h2>
        <span className="text-xs text-slate-700">
          If Archy is converting data from your previous PMS, provide the required usernames and passwords.
        </span>
      </div>

      <div className="grid grid-cols-2 gap-6">
        <FormFieldInput
          label="Server Username"
          data-key="serverUsername"
          data-has-key="hasServerUsername"
          value={remoteSettings.serverUsername}
          onChange={(e) => handleChange((draft) => (draft.serverUsername = e.target.value))}
          onFocus={handleConnectedFocus}
          onBlur={handleConnectedBlur}
          edit={isEditing}
        />

        <FormFieldInput
          label="Server Password"
          aria-label="Server Password"
          data-key="serverPassword"
          data-has-key="hasServerPassword"
          value={remoteSettings.serverPassword}
          onChange={(e) => handleChange((draft) => (draft.serverPassword = e.target.value))}
          onFocus={handleConnectedFocus}
          onBlur={handleConnectedBlur}
          edit={isEditing}
        />

        <FormFieldInput
          label="PMS Username"
          data-key="pmsUsername"
          data-has-key="hasPmsUsername"
          value={remoteSettings.pmsUsername}
          onChange={(e) => handleChange((draft) => (draft.pmsUsername = e.target.value))}
          onFocus={handleConnectedFocus}
          onBlur={handleConnectedBlur}
          edit={isEditing}
        />

        <FormFieldInput
          label="PMS Password"
          aria-label="PMS Password"
          data-key="pmsPassword"
          data-has-key="hasPmsPassword"
          value={remoteSettings.pmsPassword}
          onChange={(e) => handleChange((draft) => (draft.pmsPassword = e.target.value))}
          onFocus={handleConnectedFocus}
          onBlur={handleConnectedBlur}
          edit={isEditing}
        />
      </div>
    </Form>
  );
};
