import { FC, useState } from "react";

import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";

import { getPracticePublicHolidaysQuery, getPracticeCustomHolidaysQuery } from "api/scheduling/queries";

import { useNow } from "hooks/useNow";
import { UsHolidays } from "./components/UsHolidays";
import { UsHolidaysForm } from "./components/UsHolidaysForm";
import { TimeOff } from "./components/TimeOff";
import { EditTimeOff } from "./components/EditTimeOff";

const cxStyles = {
  sectionTitle: "text-sm font-sansSemiBold text-greyDark",
};

export const HolidayCalendar: FC = () => {
  const now = useNow();
  const [publicHolidayYear, setPublicHolidayYear] = useState(() => now.getFullYear().toString());
  const [customHolidayYear, setCustomHolidayYear] = useState(() => now.getFullYear().toString());

  const { practiceId } = useAccount();
  const [publicHolidaysQuery, customHolidaysQuery] = useApiQueries([
    getPracticePublicHolidaysQuery({ args: { practiceId, year: Number(publicHolidayYear) } }),
    getPracticeCustomHolidaysQuery({ args: { practiceId } }),
  ]);

  const editUsHolidays = useBoolean(false);
  const editTimeOff = useBoolean(false);

  return (
    <>
      <div className="flex items-center">
        <div className={cxStyles.sectionTitle}>US Holidays</div>
        <ButtonIcon
          SvgIcon={EditIcon}
          onClick={editUsHolidays.toggle}
          theme="primary"
          className="ml-2.5"
          tooltip={{ content: "Edit US Holidays", theme: "SMALL" }}
        />
      </div>

      <QueryResult queries={[publicHolidaysQuery]}>
        {editUsHolidays.isOff ? (
          <UsHolidays
            publicHolidays={publicHolidaysQuery.data ?? []}
            onYearUpdate={setPublicHolidayYear}
            selectedYear={publicHolidayYear}
          />
        ) : (
          <UsHolidaysForm
            key={publicHolidayYear}
            publicHolidays={publicHolidaysQuery.data ?? []}
            onClose={editUsHolidays.off}
            onYearSelect={setPublicHolidayYear}
            selectedYear={publicHolidayYear}
          />
        )}
      </QueryResult>

      <div className="flex items-center pt-6">
        <div className={cxStyles.sectionTitle}>Practice Time Off</div>
        <ButtonIcon
          SvgIcon={EditIcon}
          onClick={editTimeOff.toggle}
          theme="primary"
          className="ml-2.5"
          tooltip={{ content: "Edit Time Off", theme: "SMALL" }}
        />
      </div>

      <QueryResult queries={[customHolidaysQuery]}>
        {editTimeOff.isOff ? (
          <TimeOff
            customHolidays={customHolidaysQuery.data ?? []}
            selectedYear={customHolidayYear}
            onYearUpdate={setCustomHolidayYear}
          />
        ) : (
          <EditTimeOff
            customHolidays={customHolidaysQuery.data ?? []}
            handleOnEditSection={editTimeOff.off}
            selectedYear={customHolidayYear}
            onYearUpdate={setCustomHolidayYear}
          />
        )}
      </QueryResult>
    </>
  );
};
