import React from "react";
import { MedicalImageVO } from "@libs/api/generated-api";
import { AnnotationContentProps } from "components/PatientProfile/Imaging/types/imageEditor";

const LazyAnnotationContentChildren = React.lazy(
  () => import(/* webpackChunkName: "react-fabric-annotation-content" */ "./AnnotationContent")
);

export const LazyAnnotationContent = <T extends MedicalImageVO>(props: AnnotationContentProps<T>) => {
  return (
    <React.Suspense>
      <LazyAnnotationContentChildren {...props} />
    </React.Suspense>
  );
};
