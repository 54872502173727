import React from "react";

import { cx } from "@libs/utils/cx";

export const DarkRoomHeader: React.FC<{ children: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div
      className={cx(
        `flex
         justify-between
         items-center
         gap-4
         px-5
         border-b-2
         border-black
         select-none
         bg-slate-800
         py-3
         min-h-16`,
        className
      )}
    >
      {children}
    </div>
  );
};
