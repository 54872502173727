import { FC, useEffect, useMemo, useCallback } from "react";
import {
  MessageCampaignUncollectedReportCriteria,
  PatientBalanceAgingRequest,
  CreateStatementRequest,
} from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getInfiniteQueryPagingDetails, getQueryKey } from "@libs/utils/queries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { usePick } from "@libs/hooks/usePick";
import { useAccount } from "@libs/contexts/AccountContext";
import { updatePaginatedEntriesCachedData } from "@libs/utils/queryCache";
import { useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { AlertModal } from "@libs/components/UI/AlertModal";
import { ValuesInFilterSelect } from "components/Dashboard/Tables/ValuesInFilterSelect";
import { GenerateBulkStatementModal } from "components/Dashboard/OutstandingCollections/GenerateBulkStatementModal";
import { FooterCell } from "components/Dashboard/Tables/FooterCell";
import {
  ByPatientAgingRow,
  PatientBalanceAgingEntryVOLoading,
} from "components/Dashboard/OutstandingCollections/ByPatientAgingRow";
import { useQueryParams } from "hooks/useQueryParams";
import { useFilterTokenProps } from "components/Dashboard/hooks/useFilterTokenProps";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import { MAX_PAGE_SIZE, getTotals } from "components/Dashboard/Tables/utils";
import { UNCOLLECTED_BY_PATIENT_AGING_COLUMNS } from "components/Dashboard/OutstandingCollections/constants";
import { DashboardTableTabs } from "components/Dashboard/Tables/DashboardTableTabs";
import { useTableTabProps } from "components/Dashboard/OutstandingCollections/hooks/useTableTabProps";
import { useOutstandingCollectionsSelectedTimeSegment } from "components/Dashboard/OutstandingCollections/hooks/useOutstandingCollectionsSelectedTimeSegment";
import { getPatientBalanceAgingQuery } from "api/reporting/queries";
import { useDownloadOutstandingCollectionsAgingByPatientCSV } from "components/Dashboard/OutstandingCollections/hooks/useDownloadOutstandingCollectionsAgingByPatientCSV";
import { useFlattenEntries } from "components/Dashboard/hooks/useFlattenEntries";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";
import { useSelectRows } from "hooks/useSelectRows";
import { ByPatientAgingBulkActionsRow } from "components/Dashboard/OutstandingCollections/ByPatientAgingBulkActionsRow";
import { MessagePatientsFlyover } from "components/Communications/MessagePatients/MessagePatientsFlyover";
import { createBulkStatement } from "api/billing/mutations";
import { handleError } from "utils/handleError";
import { OutstandingCollectionsQuery } from "utils/routing/dashboard";

// eslint-disable-next-line complexity, max-statements
export const ByPatientAgingTableRoute: FC = () => {
  const queryClient = useQueryClient();
  const { updateQuery, query } = useQueryParams("dashboardPaymentOutstanding");

  const messagePatientsFlyover = useBoolean(false);
  const uninvoicedBalancesModal = useBoolean(false);
  const statementModal = useBoolean(false);
  const statementInProgressModal = useBoolean(false);
  const statementModalOff = statementModal.off;
  const statementInProgressModalOn = statementInProgressModal.on;
  const handleRouteStateChange = useCallback(
    (updates: Partial<OutstandingCollectionsQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );
  const { practiceId } = useAccount();

  const headers = useMemo(() => {
    // splicing because the Latest Statement and Payment Requested columns
    // should not show up in the csv report
    const copy = [...UNCOLLECTED_BY_PATIENT_AGING_COLUMNS];
    const lastStatementIndex = 3;
    const paymentRequestedIndex = 4;

    copy.splice(lastStatementIndex, 0, { label: "Latest Statement", width: "1fr" });
    copy.splice(paymentRequestedIndex, 0, { label: "Payment Requested", width: "1fr" });

    return [{ id: "checkbox", label: "", width: "3.25rem" }, ...copy];
  }, []);

  const sortOrders = query.patientTableSortV2;

  const selectedTimeSegment = useOutstandingCollectionsSelectedTimeSegment();

  const { search, isWaiting } = useDebouncedSearch(query.patientSearch ?? "");
  const outstandingPatientCollectionsAgingRequest: PatientBalanceAgingRequest = useMemo(
    () => ({
      includeTotal: true,
      ...selectedTimeSegment,
      patientSearchString: search,
      sortOrders,
      filters: query.filters,
    }),
    [selectedTimeSegment, search, sortOrders, query.filters]
  );
  const outstandingPatientCollectionsAgingQuery = useInfiniteApiQuery(
    getPatientBalanceAgingQuery({
      args: {
        practiceId,
        pageSize: MAX_PAGE_SIZE,
        pageNumber: 1,
        data: outstandingPatientCollectionsAgingRequest,
      },
    })
  );
  const totals = getTotals(outstandingPatientCollectionsAgingQuery.data);
  const [createBulkStatementMutation] = useApiMutations([createBulkStatement]);
  const createBulkStatementMutate = createBulkStatementMutation.mutate;

  const isLoading =
    isWaiting ||
    outstandingPatientCollectionsAgingQuery.isLoading ||
    outstandingPatientCollectionsAgingQuery.isInitialLoading;

  const totalRows =
    getInfiniteQueryPagingDetails(outstandingPatientCollectionsAgingQuery.data)?.totalElements ?? 0;
  const filterParams = usePick(query, ["filters", "patientSearch"]);
  const filterProps = useFilterTokenProps({ options: filterParams });
  const rows = useFlattenEntries(outstandingPatientCollectionsAgingQuery.data);
  const patientIds = useMemo(() => rows.map((row) => row.patient.id), [rows]);
  const {
    selectedCount,
    selectedRows,
    deselectedRowsFromSelectAll,
    hasAllSelected,
    selectAllRows,
    resetSelectedRows,
    handleCheckboxChange,
  } = useSelectRows(patientIds, { totalItems: totalRows });

  const updateCachedPatientBalanceAgingStatements = useCallback(() => {
    const filter: MessageCampaignUncollectedReportCriteria = {
      type: "REPORTING_UNCOLLECTED",
    };

    if (hasAllSelected && deselectedRowsFromSelectAll.size) {
      filter.deselectedIds = [...deselectedRowsFromSelectAll];
      filter.patientBalanceAgingRequest = outstandingPatientCollectionsAgingRequest;
    } else if (!hasAllSelected && selectedRows.size) {
      filter.selectedIds = [...selectedRows];
    } else {
      filter.patientBalanceAgingRequest = outstandingPatientCollectionsAgingRequest;
    }

    // optimistic update to signal that the statements are being generated
    // the patients loaded into cache.
    updatePaginatedEntriesCachedData<PatientBalanceAgingEntryVOLoading>(
      queryClient,
      {
        queryKey: [
          getQueryKey("practices", "getPatientBalanceAging"),
          { practiceId, data: outstandingPatientCollectionsAgingRequest },
        ],
        exact: false,
      },
      (page) => {
        return produce(page, (draft) => {
          for (const entry of draft.entries) {
            if (
              (hasAllSelected && !deselectedRowsFromSelectAll.has(entry.patient.id)) ||
              (!hasAllSelected && selectedRows.has(entry.patient.id))
            ) {
              if (entry.lastStatement) {
                entry.lastStatement.isCompleted = false;
              } else {
                entry.lastStatement = {
                  uuid: "",
                  date: "",
                  isCompleted: false,
                };
              }
            }
          }
        });
      }
    );

    return filter;
  }, [
    hasAllSelected,
    deselectedRowsFromSelectAll,
    selectedRows,
    outstandingPatientCollectionsAgingRequest,
    queryClient,
    practiceId,
  ]);

  const handleBulkStatementGeneration = useCallback(
    (createStatementRequest: CreateStatementRequest) => {
      const filter = updateCachedPatientBalanceAgingStatements();

      createBulkStatementMutate(
        {
          practiceId,
          data: { createStatementRequest, filter },
        },
        {
          onSuccess: () => {
            statementModalOff();
            statementInProgressModalOn();
          },
          onError: handleError,
        }
      );
    },
    [
      updateCachedPatientBalanceAgingStatements,
      createBulkStatementMutate,
      practiceId,
      statementModalOff,
      statementInProgressModalOn,
    ]
  );

  const handleGenerateStatements = useCallback(
    (createStatementRequest: CreateStatementRequest) => {
      const filter = updateCachedPatientBalanceAgingStatements();

      return new Promise<void>((resolve, reject) => {
        createBulkStatementMutate(
          {
            practiceId,
            data: { createStatementRequest, filter },
          },
          {
            onSuccess: () => resolve(),
            onError: reject,
          }
        );
      });
    },
    [updateCachedPatientBalanceAgingStatements, createBulkStatementMutate, practiceId]
  );

  useEffect(() => {
    resetSelectedRows();
  }, [query.filters, resetSelectedRows]);

  const hasSelectedPatientsWithUninvoicedBalance = useMemo(() => {
    if (hasAllSelected) {
      return (totals?.totalUninvoicedBalance ?? 0) > 0;
    }

    return rows.some((item) => selectedRows.has(item.patient.id) && item.uninvoicedBalance > 0);
  }, [hasAllSelected, rows, selectedRows, totals?.totalUninvoicedBalance]);

  const handleRequestBulkMessage = useCallback(() => {
    if (hasSelectedPatientsWithUninvoicedBalance) {
      uninvoicedBalancesModal.on();

      return;
    }

    messagePatientsFlyover.on();
  }, [hasSelectedPatientsWithUninvoicedBalance, uninvoicedBalancesModal, messagePatientsFlyover]);

  const isEmpty = !isLoading && rows.length === 0;
  const handleSortClick = useCallback(
    (patientTableSortV2: ColumnSortOrder[]) => {
      updateQuery("replaceIn", {
        patientTableSortV2,
      });
    },
    [updateQuery]
  );
  const tableTabProps = useTableTabProps();
  const { isDownloading, downloadCSV } = useDownloadOutstandingCollectionsAgingByPatientCSV({
    outstandingPatientCollectionsAgingQuery,
    selectedTimeSegment,
  });

  return (
    <>
      <DashboardTableTabs
        {...tableTabProps}
        onClickDownload={downloadCSV}
        isDownloading={isDownloading}
        query={query}
        id="outstanding-collections-table-tabs"
      >
        <ValuesInFilterSelect
          placeholder="Uninvoiced Items"
          dashboardFilterType="uninvoicedBalance"
          valuesIn={["0"]}
          aria-label="Uninvoiced Items"
          isSearchable={false}
          dashboardFilters={query.filters}
          onChangeFilters={handleRouteStateChange}
        />
      </DashboardTableTabs>

      <ByPatientAgingBulkActionsRow
        selectedCount={selectedCount}
        total={totalRows}
        onRequestMessagePatients={handleRequestBulkMessage}
        onRequestGenerateStatment={statementModal.on}
        filters={
          filterProps.filters.length ? (
            <QueryFilterPills
              {...filterProps}
              numResults={totalRows}
              onUpdateParams={(updatedQuery) => {
                updateQuery("replaceIn", updatedQuery);
              }}
              onClearAll={() => {
                updateQuery("replaceIn", { filters: [], patientSearch: undefined });
              }}
            />
          ) : null
        }
      />

      <DashboardInfiniteTable
        infiniteQuery={outstandingPatientCollectionsAgingQuery}
        columnWidths={headers.map(({ width }) => width)}
        id="outstandingCollectionsByPatientTable"
        isEmpty={isEmpty}
        headerRow={
          <DashboardHeaderRow
            headers={headers}
            onSortClick={handleSortClick}
            sortOrders={query.patientTableSortV2}
            selectedCount={selectedCount}
            totalRows={totalRows}
            onSelectAllRows={selectAllRows}
            onDeselectAllRows={resetSelectedRows}
          />
        }
        footerRow={
          <>
            <FooterCell>Totals</FooterCell>
            <FooterCell className="col-span-5" />
            {totals ? (
              <>
                <FooterCell loading={isLoading} align="right">
                  {formatCurrency(totals.totalAging30OrLess)}
                </FooterCell>
                <FooterCell loading={isLoading} align="right">
                  {formatCurrency(totals.totalAging31To60)}
                </FooterCell>
                <FooterCell loading={isLoading} align="right">
                  {formatCurrency(totals.totalAging61To90)}
                </FooterCell>
                <FooterCell loading={isLoading} align="right">
                  {formatCurrency(totals.totalAging91To120)}
                </FooterCell>
                <FooterCell loading={isLoading} align="right">
                  {formatCurrency(totals.totalAging121OrMore)}
                </FooterCell>
                <FooterCell loading={isLoading} align="right">
                  {formatCurrency(totals.totalPatientBalance)}
                </FooterCell>
              </>
            ) : (
              <FooterCell loading={isLoading} className="col-span-6" />
            )}
          </>
        }
      >
        {rows.map((row) => {
          return (
            <ByPatientAgingRow
              key={row.patient.id}
              data={row}
              checked={selectedRows.has(row.patient.id)}
              onCheckboxChange={handleCheckboxChange}
            />
          );
        })}
      </DashboardInfiniteTable>
      {uninvoicedBalancesModal.isOn ? (
        <AlertModal
          primaryText="Uninvoiced Balances"
          secondaryText="Some of the selected patients have uninvoiced balances. We recommend creating invoices for all patients before sending this message."
          confirmText="Okay"
          onConfirm={() => {
            uninvoicedBalancesModal.off();
            messagePatientsFlyover.on();
          }}
          size="3xs"
        />
      ) : null}
      {messagePatientsFlyover.isOn ? (
        <MessagePatientsFlyover
          type="REPORTING_UNCOLLECTED"
          criteria={outstandingPatientCollectionsAgingRequest}
          selectionCategory="REPORTING_UNCOLLECTED"
          selectedCount={selectedCount}
          filteredCount={totalRows}
          selectedIds={selectedRows}
          deselectedIds={deselectedRowsFromSelectAll}
          hasAllSelected={hasAllSelected}
          onGenerateStatements={handleGenerateStatements}
          onSendSuccess={statementInProgressModalOn}
          onClose={messagePatientsFlyover.off}
        />
      ) : null}
      {statementModal.isOn ? (
        <GenerateBulkStatementModal
          isSaving={createBulkStatementMutation.isLoading}
          onClose={statementModalOff}
          onSubmit={handleBulkStatementGeneration}
        />
      ) : null}
      {statementInProgressModal.isOn ? (
        <AlertModal
          size="3xs"
          centerVertically
          primaryText="Statements Generating"
          secondaryText="Statements for the selected patients are being generated. This could take some time to complete. You are free to leave this page and come back."
          onConfirm={statementInProgressModal.off}
        />
      ) : null}
    </>
  );
};
