import React, { useEffect, useMemo } from "react";
import { useObjectState } from "@libs/hooks/useObjectState";
import { DAY_IN_MS } from "@libs/utils/date";
import { wrapStorage } from "@libs/storage/wrapStorage";
import { useStorageContext } from "@libs/contexts/StorageContext";
import {
  DEFAULT_LINE_WIDTH,
  EDITOR_COLORS,
} from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/constants";

import { StorageNamespaces } from "storage/namespaces";
import {
  DrawSettings,
  CanvasEditorRef,
  DrawLayer,
  PersistedEditorSettings,
} from "components/PatientProfile/Imaging/types/imageEditor";
import { PearlConfig, PearlTool } from "components/PatientProfile/Imaging/types/pearl";

const DEFAULT_DRAW_STATE: DrawSettings = {
  brushSize: DEFAULT_LINE_WIDTH,
  color: EDITOR_COLORS[0],
};

const PEARL_DEFAULT_CONFIG: PearlConfig = {
  toolsOn: new Set<PearlTool>(["pathologies", "measurements"]),
  layersAvailable: new Set([
    "crown",
    "periapical.radiolucency",
    "filling",
    "caries",
    "margin.discrepancy",
    "implant",
    "root.canal",
    "bridge",
    "calculus",
  ]),
};
const DEFAULT_LAYERS_SHOWN = new Set<DrawLayer>(["annotations", "filters", "pearl"]);

const STORE_VERSION = "v1";
const SETTINGS_KEY = "settings";

const persistedOptions = {
  version: STORE_VERSION,
  expires: DAY_IN_MS,
};

export const usePersistedEditorSettings = ({
  editor,
}: {
  editor: React.MutableRefObject<CanvasEditorRef | null>;
}) => {
  const { localStorage } = useStorageContext();

  const persistedImageEditorSettings = useMemo(
    () =>
      wrapStorage<PersistedEditorSettings, StorageNamespaces>(StorageNamespaces.imageEditor, localStorage),
    [localStorage]
  );

  const persistedSettings = persistedImageEditorSettings.get(SETTINGS_KEY, STORE_VERSION);
  const [drawSettings, setDrawSettingsState] = React.useState<DrawSettings>(
    persistedSettings?.drawSettings ?? DEFAULT_DRAW_STATE
  );
  const [layersShown, updateLayersShown] = React.useState<Set<DrawLayer>>(
    persistedSettings?.layersShown ? new Set(persistedSettings.layersShown) : DEFAULT_LAYERS_SHOWN
  );
  const [pearlConfig, updatePearlConfig] = useObjectState<PearlConfig>(
    persistedSettings?.pearlConfig
      ? {
          layersAvailable: new Set(persistedSettings.pearlConfig.layersAvailable),
          toolsOn: new Set(persistedSettings.pearlConfig.toolsOn),
        }
      : PEARL_DEFAULT_CONFIG
  );

  useEffect(() => {
    queueMicrotask(() => {
      persistedImageEditorSettings.set(
        SETTINGS_KEY,
        {
          drawSettings: {
            ...drawSettings,
            // If user was on select mode, don't persist
            mouseMode: drawSettings.mouseMode === "select" ? undefined : drawSettings.mouseMode,
            drawMode: drawSettings.mouseMode === "select" ? undefined : drawSettings.drawMode,
          },
          layersShown: [...layersShown],
          pearlConfig: {
            layersAvailable: [...pearlConfig.layersAvailable],
            toolsOn: [...pearlConfig.toolsOn],
          },
        },
        persistedOptions
      );
    });
  }, [
    drawSettings,
    layersShown,
    pearlConfig.layersAvailable,
    pearlConfig.toolsOn,
    persistedImageEditorSettings,
  ]);

  const setDrawSettings = React.useCallback(
    (callback: (prior: DrawSettings) => Partial<DrawSettings>) => {
      setDrawSettingsState((prior) => {
        const result: DrawSettings = {
          ...prior,
          ...callback(prior),
        };

        if (editor.current) {
          editor.current.setDrawingConfig({ ...result, calibrationMode: false });
        }

        return result;
      });
    },
    [editor]
  );

  return {
    drawSettings,
    pearlConfig,
    layersShown,
    setDrawSettings,
    updateLayersShown,
    updatePearlConfig,
  };
};
