import { FC } from "react";
import { ProviderVO } from "@libs/api/generated-api";
import { SlotPreferences } from "components/ScheduleAppointments/types";
import { ToggleButtonList } from "components/UI/ToggleButtonList";
import { toggleMap } from "components/ScheduleAppointments/utils";
import { AppointmentTimeSlotPreferences } from "components/ScheduleAppointments/AppointmentTimeSlotPreference";

const DayList = [
  { value: "SUNDAY" as const, label: "S" },
  { value: "MONDAY" as const, label: "M" },
  { value: "TUESDAY" as const, label: "T" },
  { value: "WEDNESDAY" as const, label: "W" },
  { value: "THURSDAY" as const, label: "T" },
  { value: "FRIDAY" as const, label: "F" },
  { value: "SATURDAY" as const, label: "S" },
];

export interface AppointmentSlotFinderPreferencesProps {
  slotPreferences: SlotPreferences;
  dentists: ProviderVO[] | undefined;
  hygienists: ProviderVO[] | undefined;
  className?: string;

  onUpdateSlotPreferences: (updates: Partial<SlotPreferences>) => void;
  children?: React.ReactNode;
  disabled: boolean;
}

export const AppointmentSlotFinderPreferences: FC<AppointmentSlotFinderPreferencesProps> = ({
  slotPreferences,
  dentists,
  hygienists,
  className,
  disabled,

  children,
  onUpdateSlotPreferences,
}) => {
  return (
    <div className={className}>
      <div className="flex">
        <div>
          <div className="mb-2 text-xxs">Days</div>
          <ToggleButtonList
            type="checkbox"
            shape="mediumSquare"
            disabled={disabled}
            options={DayList.map((day) => ({
              label: day.label,
              value: day.value,
              day,
            }))}
            selectedValues={new Set(slotPreferences.days ? Object.keys(slotPreferences.days) : [])}
            onChange={(_newSet, _e, item) => {
              onUpdateSlotPreferences({
                days: toggleMap(slotPreferences.days || {}, item.value, item.day),
              });
            }}
          />
        </div>
        <div>
          <AppointmentTimeSlotPreferences
            disabled={disabled}
            onUpdateSlotPreferences={onUpdateSlotPreferences}
            slotPreferences={slotPreferences}
          />
        </div>
      </div>
      <div className="flex">
        {dentists?.length ? (
          <div className="flex-auto">
            <div className="mt-4 mb-2 text-xxs">Provider</div>

            <ToggleButtonList
              type="checkbox"
              disabled={disabled}
              options={dentists.map((dentist) => ({
                value: dentist.id,
                label: dentist.name.shortDisplayName,
                dentist,
              }))}
              selectedValues={
                new Set(slotPreferences.dentists ? Object.keys(slotPreferences.dentists).map(Number) : [])
              }
              onChange={(_newSet, _e, item) => {
                onUpdateSlotPreferences({
                  dentists: toggleMap(slotPreferences.dentists || {}, item.value, item.dentist),
                });
              }}
            />
          </div>
        ) : null}
        {hygienists?.length ? (
          <div className="flex-auto ml-4">
            <div className="mt-4 mb-2 text-xxs">Hygienist</div>
            <ToggleButtonList
              type="checkbox"
              disabled={disabled}
              options={hygienists.map((hygienist) => ({
                value: hygienist.id,
                label: hygienist.name.shortDisplayName,
                hygienist,
              }))}
              selectedValues={
                new Set(slotPreferences.hygienists ? Object.keys(slotPreferences.hygienists).map(Number) : [])
              }
              onChange={(_newSet, _e, item) => {
                onUpdateSlotPreferences({
                  hygienists: toggleMap(slotPreferences.hygienists || {}, item.value, item.hygienist),
                });
              }}
            />
          </div>
        ) : null}
      </div>
      {children}
    </div>
  );
};
