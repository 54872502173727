import React from "react";
import { isBefore, addMinutes, subMinutes, format, isSameSecond } from "date-fns";
import designConfig from "@libs/design.config";
import { cx } from "@libs/utils/cx";
import { setTimeOnDate } from "@libs/utils/date";
import { Select } from "@libs/components/UI/Select";
import { createSelectStyles, getBaseStyles, mergeSelectStyles } from "@libs/components/UI/selectStyles";

const START_MINUTE_STEP = 15;

const getCustomStartOptions = (startTime: string, endTime: string, duration: number) => {
  const now = new Date();
  let start = setTimeOnDate(now, startTime);
  const end = setTimeOnDate(now, endTime);
  const startLimit = subMinutes(end, duration);
  const options: { value: string; label: string }[] = [];

  while (isBefore(start, startLimit) || isSameSecond(startLimit, start)) {
    options.push({
      value: format(start, "HH:mm:ss"),
      label: format(start, "hh:mm a"),
    });
    start = addMinutes(start, START_MINUTE_STEP);
  }

  return options;
};

interface Props {
  isSelected: boolean;
  onChangeStartTime: (time: string) => void;
  appointmentDurationInMinutes: number;
  startTime: string;
  endTime: string;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  customStartTime?: string;
}

export const SlotIntervalSelect: React.FC<Props> = ({
  isSelected,
  onChangeStartTime,
  startTime,
  appointmentDurationInMinutes,
  endTime,
  onClick,
  customStartTime,
}) => {
  const startOptions = getCustomStartOptions(startTime, endTime, appointmentDurationInMinutes);

  const hasMultipleOptions = startOptions.length > 1;

  const usedStartTime = customStartTime || startOptions[0].value;
  const startTimeDate = setTimeOnDate(new Date(), usedStartTime);
  const endTimeDate = addMinutes(startTimeDate, appointmentDurationInMinutes);

  const customStyles = React.useMemo(() => {
    return mergeSelectStyles(
      getBaseStyles<string, SelectOption<string>, false>(),
      createSelectStyles<string, SelectOption<string>>({
        singleValue: () =>
          isSelected
            ? {
                color: designConfig.colors.primaryTheme,
                fontFamily: `${designConfig.fontFamilies.sansSemiBold.join(", ")} !important`,
              }
            : {},
      })
    );
  }, [isSelected]);

  return (
    <div className="flex strech z-[2] flex-none">
      {hasMultipleOptions ? (
        <Select
          options={startOptions}
          value={usedStartTime}
          isSearchable={false}
          isClearable={false}
          styles={customStyles}
          onMenuOpen={() => onChangeStartTime(usedStartTime)}
          onItemSelected={(val) => onChangeStartTime(val)}
        />
      ) : (
        <button
          type="button"
          onClick={onClick}
          className={cx(isSelected ? "font-sansSemiBold text-xs text-primaryTheme" : "font-sans text-xs")}
        >
          {format(startTimeDate, "hh:mm a")}
        </button>
      )}

      <button type="button" className="text-primaryTheme text-xs -mt-0.5 mx-2" onClick={onClick}>
        -
      </button>
      <button
        type="button"
        onClick={onClick}
        className={cx(isSelected ? "font-sansSemiBold text-xs text-primaryTheme" : "font-sans text-xs")}
      >
        {format(endTimeDate, "hh:mm a")}
      </button>
    </div>
  );
};
