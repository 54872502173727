import React from "react";
import {
  PatientBalanceAgingEntryVO,
  PatientBalanceAgingResponse,
  PatientBalanceAgingTotalVO,
} from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { formatISODate, getLocalDate } from "@libs/utils/date";
import { formatPhoneNumber } from "@libs/utils/phone";
import { formatCurrency } from "@libs/utils/currency";
import { getAbsoluteUrl } from "@libs/utils/location";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { useDownloadQueryPages } from "components/Dashboard/hooks/useDownloadInfiniteQuery";
import { getTotals } from "components/Dashboard/Tables/utils";
import { EMPTY_ROW, formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";
import { UNCOLLECTED_BY_PATIENT_AGING_COLUMNS } from "components/Dashboard/OutstandingCollections/constants";
import { flattenEntries } from "components/Dashboard/hooks/useFlattenEntries";
import { paths } from "utils/routing/paths";
import { useCurrentUrl, useOrigin } from "contexts/OriginContext";

const formatPatientsCollectionsOutstanding = (
  rows: PatientBalanceAgingEntryVO[],
  totals: PatientBalanceAgingTotalVO,
  origin: string
) => {
  const columnTitles = formatCsvRow([
    ...UNCOLLECTED_BY_PATIENT_AGING_COLUMNS.map(({ label }) => label),
    "Patient URL",
  ]);

  const rowsContent = rows.map((row) => {
    const {
      patient,
      patientPhone,
      totalPatientBalance,
      aging121OrMore,
      aging30OrLess,
      aging31To60,
      aging61To90,
      aging91To120,
      nextAppointmentDate,
    } = row;

    return formatCsvRow([
      patient.shortDisplayName,
      patientPhone ? formatPhoneNumber(patientPhone) ?? "" : "",
      nextAppointmentDate ? formatISODate(nextAppointmentDate) : "",
      formatCurrency(aging30OrLess),
      formatCurrency(aging31To60),
      formatCurrency(aging61To90),
      formatCurrency(aging91To120),
      formatCurrency(aging121OrMore),
      formatCurrency(totalPatientBalance),
      getAbsoluteUrl(origin, paths.patient({ patientId: patient.id })),
    ]);
  });

  const totalsContent = formatCsvRow([
    "Totals",
    "",
    "",
    formatCurrency(totals.totalAging30OrLess),
    formatCurrency(totals.totalAging31To60),
    formatCurrency(totals.totalAging61To90),
    formatCurrency(totals.totalAging91To120),
    formatCurrency(totals.totalAging121OrMore),
    formatCurrency(totals.totalPatientBalance),
  ]);

  return [columnTitles, ...rowsContent, EMPTY_ROW, totalsContent];
};

export const useDownloadOutstandingCollectionsAgingByPatientCSV = ({
  outstandingPatientCollectionsAgingQuery,
  selectedTimeSegment,
}: {
  selectedTimeSegment?: {
    startDate: string;
    endDate: string;
  };
  outstandingPatientCollectionsAgingQuery: UseInfiniteApiQueryResult<PatientBalanceAgingResponse>;
}) => {
  const downloading = useBoolean(false);
  const { startDownload: downloadCollectionsByPatient } = useDownloadQueryPages(
    outstandingPatientCollectionsAgingQuery
  );
  const origin = useOrigin();
  const currentUrl = useCurrentUrl();

  return {
    isDownloading: downloading.isOn,
    downloadCSV: React.useCallback(async () => {
      downloading.on();

      const result = await downloadCollectionsByPatient();
      const rows = flattenEntries(result);
      const totals = getTotals(result);

      if (totals) {
        const docMetadata = getArchyCsvMetadata(
          currentUrl,
          selectedTimeSegment
            ? {
                startDate: getLocalDate(selectedTimeSegment.startDate),
                endDate: getLocalDate(selectedTimeSegment.endDate),
              }
            : undefined
        );
        const csvRows = [
          ...docMetadata.headers,
          ...formatPatientsCollectionsOutstanding(rows, totals, origin),
        ];

        saveCsv(csvRows, `OutstandingByPatient_${docMetadata.fileNameTag}`);
      }

      downloading.off();
    }, [downloadCollectionsByPatient, downloading, selectedTimeSegment, origin, currentUrl]),
  };
};
