import { FC, useMemo } from "react";
import { TagVO } from "@libs/api/generated-api";
import { TagMultiSelect } from "components/UI/TagMultiSelect";

export type AppointmentTagIds = {
  customIds: number[];
  excludedAutoIds: number[];
};

interface Props {
  tagIds: number[];
  autoAppointmentIds: number[] | undefined;
  tags: TagVO[];
  onItemsSelected: (newIds: number[]) => void;
}

export const AppointmentTagMultiSelect: FC<Props> = ({
  autoAppointmentIds,
  tagIds,
  tags,
  onItemsSelected,
}) => {
  const { autoIds, autoIdsSet } = useMemo(() => {
    const ids = autoAppointmentIds ?? [];

    return {
      autoIds: ids,
      autoIdsSet: new Set(ids),
    };
  }, [autoAppointmentIds]);
  const selectedTagIds = useMemo(() => {
    // dedupe custom and auto ids
    return [...new Set([...autoIds, ...tagIds])];
  }, [autoIds, tagIds]);

  const handleItemsSelected = (newIds: number[]) => {
    onItemsSelected(newIds.filter((id) => !autoIdsSet.has(id)));
  };

  const canRemoveTag = (tag: TagVO) => !autoIdsSet.has(tag.id);

  return (
    <TagMultiSelect
      selectedTagIds={selectedTagIds}
      tagOptions={tags}
      onItemsSelected={handleItemsSelected}
      canRemoveTag={canRemoveTag}
    />
  );
};
