import React, { FormEvent, useCallback } from "react";
import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useValidation } from "@libs/hooks/useValidation";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { isError } from "@tanstack/react-query";
import { CognitoErrorCode } from "@libs/utils/cognito";
import { useObjectState } from "@libs/hooks/useObjectState";
import { Form } from "@libs/components/UI/Form";
import { PageWrapper, PageWrapperCard } from "components/SignIn/PageWrapper";
import { FormFieldPassword } from "components/UI/FormFieldPassword";
import { SignInProps } from "components/SignIn/MainSignIn";
import { cxFormStyles } from "components/SignIn/SignInForm";
import { getPasswordValidationSchema, SignInErrors } from "components/SignIn/validationUtil";
import { handleError } from "utils/handleError";
import { ChallengeProps } from "components/SignIn/AuthChallenges/utils";
import { useHandleAuthError } from "components/SignIn/hooks/useHandleAuthError";

type NewPasswordProps = Omit<SignInProps, "password" | "email"> & {
  confirmPassword: string;
  newPassword: string;
  newPasswordError: string;
};

type SignInInputType = "confirmPassword" | "email" | "newPassword" | "password";

export const PasswordGuidelines = () => {
  return (
    <div className="font-sans text-sm">
      <span>{"Please set your new password below. Passwords must be "}</span>
      <span className="font-sansSemiBold">{" at least 8 characters long "}</span>
      <span>and must use</span>
      <span className="font-sansSemiBold">
        {" at least 1 uppercase letter, 1 lowercase letter, 1 number and 1 special character."}
      </span>
    </div>
  );
};
export const NewPasswordChallenge: React.FC<ChallengeProps> = ({ authUser, onChallengeComplete }) => {
  const [signInState, updateSignInState] = useObjectState<NewPasswordProps>({
    confirmPassword: "",
    errorMessage: "",
    isLoading: false,
    newPassword: "",
    newPasswordError: "",
  });
  const handleAuthError = useHandleAuthError(updateSignInState);

  usePageTitle("Sign In");

  const firstSignInForm = useValidation(
    {
      confirmPassword: signInState.confirmPassword,
      newPassword: signInState.newPassword,
    },
    {
      confirmPassword: getPasswordValidationSchema({
        password: signInState.confirmPassword,
        comparisonPassword: signInState.newPassword,
        validatePwRules: true,
      }),
      newPassword: getPasswordValidationSchema({
        password: signInState.newPassword,
        comparisonPassword: signInState.confirmPassword,
        validatePwRules: true,
      }),
    }
  );

  const handleInputChange = useCallback(
    (inputName: SignInInputType, value: string) => {
      firstSignInForm.reset();
      updateSignInState({ errorMessage: "", [inputName]: value });
    },
    [firstSignInForm, updateSignInState]
  );

  const handleSetNewPassword = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const validate = firstSignInForm.validate();

      if (!validate.$isValid) {
        return;
      }

      updateSignInState({ isLoading: true });

      try {
        try {
          const signedInUser = (await Auth.completeNewPassword(
            authUser,
            signInState.newPassword
          )) as CognitoUser;

          onChallengeComplete({ signedInUser });
        } catch (error) {
          if (isError(error)) {
            const cognitoError = error as { code?: CognitoErrorCode };

            if (cognitoError.code === "InvalidPasswordException") {
              // This error is returned when new and confirm passwords do not follow guidelines
              updateSignInState({ newPasswordError: error.message, isLoading: false });
            } else {
              // Otherwise, it's a general error
              handleAuthError(error);
              updateSignInState({ isLoading: false });
            }
          } else {
            handleAuthError(error);
            updateSignInState({ isLoading: false });
          }
        }
      } catch (error) {
        handleError(error);

        if (error instanceof Error) {
          updateSignInState({ errorMessage: error.message, isLoading: false });
        }
      }
    },
    [
      firstSignInForm,
      updateSignInState,
      authUser,
      signInState.newPassword,
      onChallengeComplete,
      handleAuthError,
    ]
  );

  return (
    <PageWrapper>
      <PageWrapperCard title="Sign In">
        <Form onSubmit={handleSetNewPassword} className="flex flex-col gap-4">
          <PasswordGuidelines />
          <FormFieldPassword
            displayErrorMessage={firstSignInForm.result.newPassword.$error !== SignInErrors.PASSWORD_MISMATCH}
            error={signInState.newPasswordError || firstSignInForm.result.newPassword.$error}
            layout="labelOut"
            label="New Password"
            name="newPassword"
            onChange={(event) => handleInputChange("newPassword", event.target.value)}
            placeholder="Enter Password"
            value={signInState.newPassword}
          />
          <FormFieldPassword
            error={signInState.newPasswordError || firstSignInForm.result.confirmPassword.$error}
            layout="labelOut"
            label="Confirm Password"
            name="confirmPassword"
            onChange={(event) => handleInputChange("confirmPassword", event.target.value)}
            placeholder="Enter Password"
            value={signInState.confirmPassword}
          />
          <div className="mt-5 flex justify-center">
            <AsyncButton
              className={cxFormStyles.submitButton}
              displayLoadingText={true}
              isLoading={signInState.isLoading}
              type="submit"
            >
              Next
            </AsyncButton>
          </div>
        </Form>
      </PageWrapperCard>
    </PageWrapper>
  );
};
