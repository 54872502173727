import React, { FC, Fragment, useCallback, useMemo } from "react";
import { produce } from "immer";
import { WorkingHourItemVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { addHoursToISOTime, isLeftISOTimeAfterRightISOTime } from "@libs/utils/date";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon } from "@libs/components/UI/Icon";
import { Button } from "@libs/components/UI/Button";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { ReactComponent as Copy } from "@libs/assets/icons/copy.svg";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { ReactComponent as RemoveIcon } from "@libs/assets/icons/minus.svg";
import { Switch } from "@libs/components/UI/Switch";
import {
  groupWorkingHoursByDay,
  convertToWorkingHourItems,
  getMinMaxWorkingHourTime,
  GroupedWorkingHourInterval,
} from "utils/workingHoursUtil";

import { FormFieldTimeRangeMenu } from "components/UI/FormFieldTimeRangeMenu";

export const TIME_STRING_HOURS_END_INDEX = 2;

interface Props {
  workingHourItems: WorkingHourItemVO[];
  onChange: (workingHourItems: WorkingHourItemVO[]) => void;
  onAddDay: (day: WorkingHourItemVO["dayOfWeek"]) => void;
}

export const EditWorkingHours: FC<Props> = ({ workingHourItems, onChange, onAddDay }) => {
  const workingHours = useMemo(() => {
    return groupWorkingHoursByDay(workingHourItems);
  }, [workingHourItems]);

  const isAddTimeDisabled = useCallback(
    (workingDayIndex: number, hoursIndex: number) => {
      const workingHoursOfDay = workingHours[workingDayIndex].hours;
      const lastHoursIndex = workingHoursOfDay.length - 1;
      const isLastTimeSlot = lastHoursIndex === hoursIndex;
      const { endTime: lastEndTime } = workingHoursOfDay[lastHoursIndex];

      // Disable add click if the last time slot ends at 11:00 PM (23:00:00)
      return lastEndTime.slice(0, TIME_STRING_HOURS_END_INDEX) === "23" && isLastTimeSlot;
    },
    [workingHours]
  );

  const onAddClick = useCallback(
    (workingDayIndex: number) => {
      const workingHoursOfDay = workingHours[workingDayIndex].hours;
      const prevWorkingHours = workingHoursOfDay.at(-1) as GroupedWorkingHourInterval;
      const startTime = addHoursToISOTime(prevWorkingHours.endTime, 1);
      let endTime = addHoursToISOTime(startTime, 1);

      if (isLeftISOTimeAfterRightISOTime(startTime, endTime)) {
        endTime = "23:59:00";
      }

      const newWorkingHours = produce(workingHours, (draft) => {
        draft[workingDayIndex].hours.push({
          id: crypto.randomUUID(),
          startTime,
          endTime,
          isSelfBookable: prevWorkingHours.isSelfBookable,
        });
      });

      onChange(convertToWorkingHourItems(newWorkingHours));
    },
    [workingHours, onChange]
  );

  const onRemoveClick = useCallback(
    (workingDayIndex: number, hoursIndex: number) => {
      const newWorkingHours = produce(workingHours, (draft) => {
        draft[workingDayIndex].hours.splice(hoursIndex, 1);
      });

      onChange(convertToWorkingHourItems(newWorkingHours));
    },
    [workingHours, onChange]
  );

  const onCopyAllClick = useCallback(
    (workingDayIndex: number) => {
      const newWorkingHours = produce(workingHours, (draft) => {
        const hoursToCopy = draft[workingDayIndex].hours;

        for (const hours of hoursToCopy) {
          hours.id = crypto.randomUUID();
        }
        for (const dayOfWeek of draft) {
          dayOfWeek.hours = hoursToCopy;
        }
      });

      onChange(convertToWorkingHourItems(newWorkingHours));
    },
    [workingHours, onChange]
  );

  const handleSwitchChange = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, workingDayIndex: number) => {
      const { checked } = event.target;

      if (checked) {
        onAddDay(workingHours[workingDayIndex].dayOfWeek);
      } else {
        const newWorkingHours = produce(workingHours, (draft) => {
          draft[workingDayIndex].open = false;
        });

        onChange(convertToWorkingHourItems(newWorkingHours));
      }
    },
    [workingHours, onAddDay, onChange]
  );

  const handleTimeChange = useCallback(
    (workingDayIndex: number, hoursIndex: number, startTime: string, endTime: string) => {
      const newWorkingHours = produce(workingHours, (draft) => {
        const hours = draft[workingDayIndex].hours;

        hours[hoursIndex].startTime = startTime;
        hours[hoursIndex].endTime = endTime;
      });

      onChange(convertToWorkingHourItems(newWorkingHours));
    },
    [workingHours, onChange]
  );

  const handleSelfBookableChange = useCallback(
    (workingDayIndex: number, hoursIndex: number, isSelfBookable: boolean) => {
      const newWorkingHours = produce(workingHours, (draft) => {
        const hours = draft[workingDayIndex].hours;

        hours[hoursIndex].isSelfBookable = isSelfBookable;
      });

      onChange(convertToWorkingHourItems(newWorkingHours));
    },
    [onChange, workingHours]
  );

  return (
    <div className="flex flex-1 flex-col">
      {workingHours.map((workingDay, workingDayIndex) => {
        return (
          <Fragment key={workingDay.dayOfWeek}>
            <div className="flex items-start">
              <div className="flex items-center mb-3 pt-2.5">
                <div className="flex items-center gap-x-2.5 min-w-20 mr-12">
                  <Switch
                    value={workingDay.dayOfWeek}
                    checked={workingDay.open}
                    onChange={(event) => handleSwitchChange(event, workingDayIndex)}
                  />
                  <p className="text-xs">{workingDay.open ? "Open" : "Closed"}</p>
                </div>
                <div className="w-24 mr-8">
                  <p className={cx("text-xs font-sansSemiBold", !workingDay.open && "text-greyMedium")}>
                    {sentenceCaseConstant(workingDay.dayOfWeek)}
                  </p>
                </div>
              </div>
              <div className="flex-1">
                {workingDay.open &&
                  workingDay.hours.map((hours, hoursIndex) => (
                    <div key={hours.id} className="flex items-center mb-3">
                      <FormFieldTimeRangeMenu
                        startTime={hours.startTime}
                        endTime={hours.endTime}
                        onChange={(value) => {
                          handleTimeChange(workingDayIndex, hoursIndex, value.startTime, value.endTime);
                        }}
                        validation={getMinMaxWorkingHourTime(workingDay.hours, hoursIndex)}
                      />
                      <div className="flex items-center ml-4 gap-4">
                        <Checkbox
                          onChange={(e) =>
                            handleSelfBookableChange(workingDayIndex, hoursIndex, e.target.checked)
                          }
                          checked={hours.isSelfBookable}
                        >
                          Accept Online Bookings
                        </Checkbox>
                        {hoursIndex === workingDay.hours.length - 1 ? (
                          <ButtonIcon
                            SvgIcon={AddIcon}
                            onClick={() => onAddClick(workingDayIndex)}
                            theme="primary"
                            tooltip={{ content: "Add Time Slot", theme: "SMALL" }}
                            disabled={isAddTimeDisabled(workingDayIndex, hoursIndex)}
                          />
                        ) : null}
                        {hoursIndex === 0 ? (
                          <Button
                            theme="link"
                            className="flex items-center text-xs"
                            onClick={() => onCopyAllClick(workingDayIndex)}
                          >
                            <Icon SvgIcon={Copy} theme="primary" />

                            <div className="ml-1">Copy time to all</div>
                          </Button>
                        ) : (
                          <ButtonIcon
                            SvgIcon={RemoveIcon}
                            onClick={() => onRemoveClick(workingDayIndex, hoursIndex)}
                            theme="primary"
                            tooltip={{ content: "Remove Time Slot", theme: "SMALL" }}
                          />
                        )}
                      </div>
                    </div>
                  ))}
              </div>
            </div>
          </Fragment>
        );
      })}
    </div>
  );
};
