import { AppointmentBookingVO } from "@libs/api/generated-api";
import { Row } from "@libs/components/UI/GridTableComponents";
import { AppointmentDateLinkCell } from "components/Dashboard/PracticeProduction/TableItems/AppointmentDateLinkCell";
import { EditAppointmentCell } from "components/Dashboard/PracticeProduction/TableItems/EditAppointmentCell";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";
import { PatientNameLink } from "components/Dashboard/Tables/PatientNameLink";
import { ProceduresCell } from "components/Dashboard/Tables/ProceduresCell";
import { labelForAppointmentBookingType } from "components/Dashboard/utils/labelForAppointmentBookingType";
import { paths } from "utils/routing/paths";

type Props = {
  data: AppointmentBookingVO;
  last?: boolean;
};

export const AppointmentBookingTableRow: React.FC<Props> = ({ last, data }) => {
  const {
    patient,
    provider,
    patientProcedureDisplay,
    appointmentDate,
    appointmentDuration,
    appointmentState,
    appointmentId,
    nextScheduledAppointmentDate,
    nextScheduledAppointmentId,
  } = data;
  const appointmentDetails = {
    appointmentId,
    patientId: patient.id,
  };

  const cellProps = { last, to: paths.patient({ patientId: patient.id }) };

  return (
    <Row>
      <DashboardLinkCell {...cellProps}>
        <PatientNameLink patient={patient} />
      </DashboardLinkCell>
      <AppointmentDateLinkCell {...cellProps} {...appointmentDetails} date={appointmentDate} />
      <DashboardLinkCell {...cellProps}>{labelForAppointmentBookingType(appointmentState)}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{appointmentDuration}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{provider.shortDisplayName}</DashboardLinkCell>
      <ProceduresCell {...cellProps} procedures={patientProcedureDisplay} />
      <AppointmentDateLinkCell
        {...cellProps}
        patientId={patient.id}
        appointmentId={nextScheduledAppointmentId}
        date={nextScheduledAppointmentDate}
      />
      <EditAppointmentCell {...cellProps} {...appointmentDetails} />
    </Row>
  );
};
