import { PatientProcedureVO } from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { ValidAddPatientProcedureStatuses } from "components/ScheduleAppointments/useAppointmentCategoriesAndProcedures";

export const requiresProsthetic = (procedure: PatientProcedureVO) =>
  procedure.dentalProcedure?.prosthetic && !procedure.implant;

export const canScheduleEvery = (procedures: PatientProcedureVO[]) =>
  procedures.every((procedure) => isOneOf(procedure.status, ValidAddPatientProcedureStatuses));
export const canDeleteEvery = (procedures: PatientProcedureVO[]) =>
  procedures.every((procedure) => !["DONE", "SCHEDULED"].includes(procedure.status));

export const canPrioritizeEvery = (procedures: PatientProcedureVO[]) =>
  procedures.every((procedure) => isOneOf(procedure.status, ["PLANNED", "SCHEDULED", "REFERRED"]));
export const canPreAuthEvery = (procedures: PatientProcedureVO[]) =>
  procedures.every(
    (procedure) => isOneOf(procedure.status, ["PLANNED", "SCHEDULED"]) && !requiresProsthetic(procedure)
  );
