import { FC, HTMLAttributes, useCallback, useRef, useState } from "react";
import { Button } from "@libs/components/UI/Button";
import { cx } from "@libs/utils/cx";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ClickOutside } from "@libs/contexts/ClickOutsideListenerContext";
import { Form } from "@libs/components/UI/Form";

interface Props {
  onUpdateTitle: (value: string) => void;
  title: string;
}

const cxStyles = {
  input: `
    h-8
    block
    px-2
    border
    font-sansSemiBold
    text-lg
    leading-[31px]
  `,
};

const ReadOnly: FC<HTMLAttributes<HTMLSpanElement>> = (props) => (
  <span {...props} className={cx("whitespace-pre border-transparent", cxStyles.input, props.className)} />
);

const InputWrapper: FC<HTMLAttributes<HTMLDivElement>> = (props) => (
  <div {...props} className={cx("flex items-center gap-x-1", props.className)} />
);

export const FORM_TITLE_MAX_LENGTH = 50;

export const PageTitleReadOnly: FC<{ title: string; onRequestEdit: Func }> = ({ title, onRequestEdit }) => {
  return (
    <InputWrapper>
      <button type="button" onClick={onRequestEdit} className="block">
        <ReadOnly>{title}</ReadOnly>
      </button>
      <ButtonIcon
        SvgIcon={EditIcon}
        onClick={onRequestEdit}
        tooltip={{ content: "Edit Title", theme: "SMALL" }}
      />
    </InputWrapper>
  );
};

export const PageTitleEdit: FC<Props> = ({ onUpdateTitle, title }) => {
  const saveBtnRef = useRef<HTMLButtonElement | null>(null);
  const [draftTitle, setDraftTitle] = useState(title);

  const submitForm = useCallback(() => {
    saveBtnRef.current?.click();
  }, []);

  return (
    <ClickOutside handler={submitForm}>
      {(tracker) => (
        <Form
          {...tracker}
          className="block"
          onSubmit={(e) => {
            e.preventDefault();

            if (draftTitle.trim()) {
              onUpdateTitle(draftTitle);
            }
          }}
        >
          <InputWrapper>
            <div className="relative">
              <ReadOnly aria-hidden="true">{draftTitle}</ReadOnly>
              <input
                autoFocus
                aria-label="Edit Title"
                value={draftTitle}
                onBlur={submitForm}
                onChange={(e) => {
                  setDraftTitle(e.target.value);
                }}
                maxLength={FORM_TITLE_MAX_LENGTH}
                className={cx(
                  "absolute w-full top-0 left-0 outline-none rounded",
                  draftTitle.trim() ? "border-primaryTheme" : "border-red",
                  cxStyles.input
                )}
              />
            </div>
            <Button ref={saveBtnRef} type="submit" theme="primary" size="small">
              Save
            </Button>
          </InputWrapper>
        </Form>
      )}
    </ClickOutside>
  );
};

export const PageTitleEditor: FC<Props & { isEditing: boolean; onRequestEdit: Func }> = ({
  onUpdateTitle,
  title,
  isEditing,
  onRequestEdit,
}) => {
  return isEditing ? (
    <PageTitleEdit title={title} onUpdateTitle={onUpdateTitle} />
  ) : (
    <PageTitleReadOnly onRequestEdit={onRequestEdit} title={title} />
  );
};
