import { useCallback, useEffect } from "react";
import { Link, matchPath, useLocation, useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import { useDebouncedCallback } from "use-debounce";
import { toast } from "react-toastify";
import { getQueryKey } from "@libs/utils/queries";
import { parseParams } from "@libs/router/url";
import { useAccount } from "@libs/contexts/AccountContext";
import { useNotificationContext } from "contexts/NotificationsContext";
import { useWebSocketContext } from "contexts/WebSocketContext";
import { paths, routesConfig } from "utils/routing/paths";
import { invalidateClaim, invalidateClaimsList } from "api/claim/cache";
import { handleError } from "utils/handleError";
import { WebSocketMessageEvent } from "api/websocket/webSocketMessage";

const ErrorWithLink: React.FC<{ path: string; type: "claim" | "eob" }> = ({ path, type }) => (
  <>
    <div>{type === "claim" ? "A claim" : "An EOB"}&nbsp;failed to send.</div>
    <Link className="text-primaryTheme" to={path}>
      View {type === "claim" ? "Claim" : "EOB"}
    </Link>
  </>
);

const FIVE_HUNDRED_MS = 500;

export const ClaimsBulkNotifier: React.FC = () => {
  const { messages } = useWebSocketContext();
  const { handleError: handleNotificationError } = useNotificationContext();
  const queryClient = useQueryClient();
  const { practiceId } = useAccount();
  const location = useLocation();
  const navigate = useNavigate();

  const handleInvalidateClaimsList = useCallback(() => {
    invalidateClaimsList(queryClient);
  }, [queryClient]);

  const debounceInvalidateClaimsList = useDebouncedCallback(handleInvalidateClaimsList, FIVE_HUNDRED_MS);

  useEffect(() => {
    // eslint-disable-next-line complexity
    const onMessageHandler = (event: WebSocketMessageEvent) => {
      const message = event.detail;

      switch (message.type) {
        case "CLAIM_SUBMISSION": {
          queryClient.invalidateQueries([
            getQueryKey("practices", "getClaim"),
            { practiceId, claimUuid: message.payload.claimUuid },
          ]);

          debounceInvalidateClaimsList();

          if (message.payload.isPreAuth) {
            queryClient.invalidateQueries([getQueryKey("practices", "getPatientProcedures"), { practiceId }]);
            queryClient.invalidateQueries([getQueryKey("practices", "getPatientProcedure"), { practiceId }]);
            queryClient.invalidateQueries([getQueryKey("practices", "getTreatmentPlan"), { practiceId }]);
          }

          if (message.payload.state === "FAILED") {
            handleNotificationError(
              <ErrorWithLink path={paths.claim({ claimUuid: message.payload.claimUuid })} type="claim" />
            );
          }

          break;
        }
        case "CLAIM_VALIDATION": {
          invalidateClaim(queryClient, { practiceId, claimUuid: message.payload.claimUuid });
          queryClient.invalidateQueries([getQueryKey("practices", "getPatientProcedures"), { practiceId }]);
          queryClient.invalidateQueries([getQueryKey("practices", "getPatientProcedure"), { practiceId }]);
          queryClient.invalidateQueries([getQueryKey("practices", "getTreatmentPlan"), { practiceId }]);

          break;
        }
        case "DRAFT_EOB_PAYMENT_SUCCESS": {
          queryClient.invalidateQueries([getQueryKey("practices", "getDraftEobPaymentSummaries")]);
          toast.success("EOB virual card payment completed successfully.");

          const match = matchPath(routesConfig.eob.path, location.pathname);

          if (match) {
            try {
              const { draftEobPaymentUuid } = parseParams(routesConfig.eob.params, match.params);

              if (draftEobPaymentUuid === message.payload.uuid) {
                navigate(paths.eobs());
              }
            } catch (e) {
              handleError(e);
            }
          }

          break;
        }
        case "DRAFT_EOB_PAYMENT_FAILED": {
          queryClient.invalidateQueries([
            getQueryKey("practices", "getDraftEobPaymentSummaries"),
            { practiceId },
          ]);
          queryClient.invalidateQueries([
            getQueryKey("practices", "getDraftEobPayment"),
            { practiceId, draftEobPaymentUuid: message.payload.uuid },
          ]);

          handleNotificationError(
            <ErrorWithLink path={paths.eob({ draftEobPaymentUuid: message.payload.uuid })} type="eob" />
          );
        }
        // No default
      }
    };

    if (messages) {
      messages.addEventListener("message", onMessageHandler);

      return () => {
        messages.removeEventListener("message", onMessageHandler);
      };
    }

    return undefined;
  }, [
    debounceInvalidateClaimsList,
    handleNotificationError,
    navigate,
    location,
    practiceId,
    queryClient,
    messages,
  ]);

  return null;
};
