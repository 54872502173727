import { FC } from "react";

import { useBoolean } from "@libs/hooks/useBoolean";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as OpenLinkIcon } from "@libs/assets/icons/open-profile.svg";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";

import { Modal } from "@libs/components/UI/Modal";

export const GustoTosModal: FC<{ onTosAccept: Func; onRequestClose: Func }> = ({
  onTosAccept,
  onRequestClose,
}) => {
  const acceptTos = useBoolean(false);

  return (
    <Modal onClose={onRequestClose} size="xs">
      <ModalContent className="flex flex-col items-center p-10 pb-6">
        <p className="font-sansSemiBold text-sm mb-3">Terms of Service Agreement</p>

        <p className="font-sansSemiBold text-xs mb-3">
          Before continuing, please ensure that you are an administrator and signatory at the company. You can
          verify this info via the Gusto portal.
        </p>

        <div className="flex items-start">
          <Checkbox className="top-px mr-2.5" checked={acceptTos.isOn} onChange={acceptTos.toggle} />

          <div className="font-sans text-xs">
            <p className="mb-2">
              By accepting the Embedded Terms of Service you authorize Archy to share your data with Gusto to
              obtain payroll services.
            </p>

            <a
              className="flex items-center font-sansSemiBold text-primaryTheme"
              href="https://flows.gusto.com/terms"
              target="_blank"
            >
              View Embedded Terms of Service&nbsp;
              <OpenLinkIcon className="w-5 h-5" />
            </a>
          </div>
        </div>
      </ModalContent>

      <ModalFooter className="border-none" paddingClassName="pb-6">
        <Button className="min-w-button" onClick={onRequestClose} theme="secondary" size="large">
          Cancel
        </Button>
        <Button className="min-w-button" onClick={onTosAccept} disabled={acceptTos.isOff}>
          Accept Terms
        </Button>
      </ModalFooter>
    </Modal>
  );
};
