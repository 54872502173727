import { cx } from "@libs/utils/cx";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Avatar, cxThemes } from "components/UI/Avatar";

interface Props {
  initialsList: { name: string; tooltipContent: string }[];
}

const MAX_NUM_TO_SHOW = 3;

export const FamilyMembersInitialsList: React.FC<Props> = ({ initialsList }) => {
  const hasMoreThanMax = initialsList.length > MAX_NUM_TO_SHOW;

  return (
    <div className="flex items-center py-2">
      {initialsList.slice(0, MAX_NUM_TO_SHOW).map((initialsData, index) => {
        return (
          <FloatingTooltip content={initialsData.tooltipContent} key={index} theme="SMALL">
            <div>
              <Avatar name={initialsData.name} size="sm" />
            </div>
          </FloatingTooltip>
        );
      })}
      {hasMoreThanMax && (
        <div className={cx("flex items-center justify-center h-6 w-6 text-xxs", cxThemes.initials)}>{`+${
          initialsList.length - MAX_NUM_TO_SHOW
        }`}</div>
      )}
    </div>
  );
};
