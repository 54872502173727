import React, { useCallback } from "react";
import { getUnixTime } from "date-fns";
import { ClaimVO } from "@libs/api/generated-api";
import { RadioList } from "@libs/components/UI/RadioList";
import { useAccount } from "@libs/contexts/AccountContext";
import { useAttachments } from "components/Claim/Attachments/ClaimAttachmentsContext";
import { ExternalUpload } from "components/Claim/Attachments/ExternalUpload";
import { SelectMountImagesTable } from "components/UI/SelectMountImages/SelectMountImagesTable";
import { ImageSelector } from "components/Claim/Attachments/ImageSelector";
import { useInfiniteMountsQuery } from "api/imaging/queries";

interface Props {
  claim: ClaimVO;
}

export const ImagesAttachments: React.FC<Props> = ({ claim }) => {
  const { practiceId } = useAccount();
  const patientId = claim.patientName.id;
  const mountsQuery = useInfiniteMountsQuery({ practiceId, patientId, populateImages: true });
  const {
    addMedicalImage,
    medicalImages,
    editMedicalImage,
    removeImage,
    toggleMedicalImageType,
    useArchyImages,
  } = useAttachments();

  const handleAddImage = useCallback(
    (src: string) => {
      addMedicalImage({
        data: src,
        isUploaded: true,
        sourceCreatedAt: getUnixTime(Date.now()),
        type: "PHOTO",
      });
    },
    [addMedicalImage]
  );

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-col h-full min-h-0 mb-6 gap-y-6 bg-white z-0">
        <RadioList
          onChange={toggleMedicalImageType}
          options={[
            { label: "Select Image", value: "true" },
            { label: "Select External Image", value: "false" },
          ]}
          selectedValue={useArchyImages ? "true" : "false"}
        />
        {useArchyImages ? (
          <SelectMountImagesTable
            mountsQuery={mountsQuery}
            patientId={claim.patientName.id}
            className="border-x border-greyLighter"
            showLink
            ImageWrapper={ImageSelector}
          />
        ) : (
          <ExternalUpload
            attachmentType="image"
            canSelectMultiple={true}
            editImage={editMedicalImage}
            images={medicalImages}
            onAddImage={handleAddImage}
            removeImage={removeImage}
            userId={patientId}
          />
        )}
      </div>
    </div>
  );
};
