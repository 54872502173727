import { ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { LayoutCard } from "./LayoutCard";

export interface PanelProps {
  actions?: ReactNode;
  children: ReactNode;
  className?: string;
  contentClassName?: string;
  footer?: ReactNode;
  includePadding?: boolean;
  title?: ReactNode;
  titleBarClassName?: string;
}

export const Panel: React.FC<PanelProps> = ({
  actions,
  children,
  className,
  contentClassName,
  footer,
  includePadding = true,
  title,
  titleBarClassName,
  ...rest
}) => {
  return (
    <LayoutCard className={cx("flex flex-col", className)} {...rest}>
      <div
        className={cx(
          `flex-none
           flex
           flex-row
           items-center
           justify-between
           h-14
           px-6
           border-b
           border-slate-200`,
          titleBarClassName
        )}
      >
        <div className="font-sansSemiBold">{title}</div>

        {actions}
      </div>
      <div className={cx("flex-1 min-h-0", contentClassName, includePadding && "p-6")}>{children}</div>

      {footer ? <div className="px-6 py-4 border-t border-t-slate-200">{footer}</div> : null}
    </LayoutCard>
  );
};
