import { FC, useMemo, useCallback } from "react";

import { Icon } from "@libs/components/UI/Icon";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { useBoolean } from "@libs/hooks/useBoolean";

import { ReactComponent as LockIcon } from "@libs/assets/icons/lock.svg";
import { ReactComponent as VerticalMenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { Row, ButtonCell, IconsCell } from "@libs/components/UI/GridTableComponents";
import { PatientFolder } from "components/Settings/PracticeSetup/Sections/PatientFolders/types";

interface Props {
  folder: PatientFolder;
  onEditFolder: (patientFolder: PatientFolder) => void;
  onDeleteFolder: (customPatientFolderId: number) => void;
}

export const PatientFolderRow: FC<Props> = ({ folder, onEditFolder, onDeleteFolder }) => {
  const menu = useBoolean(false);
  const menuOptions = useMemo(
    () =>
      createMenuOptions(
        {
          value: "edit",
          label: "Edit",
          SvgIcon: EditIcon,
        },
        {
          value: "delete",
          label: "Delete",
          SvgIcon: DeleteIcon,
        }
      ),
    []
  );

  const handleEditFolder = useCallback(() => {
    onEditFolder(folder);
  }, [folder, onEditFolder]);

  const handleDeleteFolder = useCallback(() => {
    onDeleteFolder(folder.id);
  }, [folder.id, onDeleteFolder]);

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "edit": {
          handleEditFolder();
          break;
        }
        case "delete": {
          handleDeleteFolder();
          break;
        }
        default: {
          break;
        }
      }

      menu.off();
    },
    [handleEditFolder, handleDeleteFolder, menu]
  );

  const defaultCellProps = { borderColor: "border-slate-200" as const };
  const buttonCellProps = {
    ...defaultCellProps,
    className: folder.isDefault ? "cursor-default" : undefined,
    onDoubleClick: folder.isDefault ? undefined : handleEditFolder,
  };

  return (
    <Row className="*:flex *:items-center">
      <ButtonCell {...buttonCellProps}>
        <div className="flex items-center gap-x-2">
          {folder.name}
          {folder.isDefault ? (
            <Icon
              SvgIcon={LockIcon}
              tooltip={{
                content: "This folder is required by Archy",
                theme: "MEDIUM",
              }}
              theme="slate600"
              size="sm"
            />
          ) : null}
        </div>
      </ButtonCell>
      <IconsCell {...defaultCellProps}>
        {folder.isDefault ? null : (
          <ButtonMenu
            menuContent={
              <div className="w-36">
                <MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />
              </div>
            }
            onRequestOpen={menu.on}
            onRequestClose={menu.off}
            isOpen={menu.isOn}
            placement="bottom-end"
          >
            {(props) => (
              <ButtonIcon
                SvgIcon={VerticalMenuIcon}
                tooltip={{ content: "Folder Menu", theme: "SMALL" }}
                theme="slate700"
                {...props}
              />
            )}
          </ButtonMenu>
        )}
      </IconsCell>
    </Row>
  );
};
