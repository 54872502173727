import { useCallback } from "react";
import { toast } from "react-toastify";
import { osName } from "react-device-detect";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as CopyIcon } from "@libs/assets/icons/copy.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { ReactComponent as CommunicationsImage } from "assets/components/communications.svg";
import { ExternalLink } from "components/UI/ExternalLink";

interface Props {
  authKey: string;
  onConnect: Func;
  onUpdateAuthKey: (value: string) => void;
}

const cxStyles = {
  stepContainer: "flex gap-x-4",
};

export const Instructions: React.FC<Props> = ({ authKey, onConnect, onUpdateAuthKey }) => {
  const practice = useCurrentPractice();
  const handleCopyKey = useCallback(() => {
    navigator.clipboard.writeText(practice.uuid);

    toast.info("Remote key copied to clipboard.");
  }, [practice.uuid]);

  const copyInstructions = osName === "Mac OS" ? "(Command + V) " : osName === "Windows" ? "(Ctrl + V) " : "";

  return (
    <div
      className={`
        flex
        w-[888px]
        px-12
        py-8
        gap-x-6
        rounded
        border
        border-greyLighter
      `}
    >
      <CommunicationsImage />
      <div className="flex flex-col gap-y-4 text-sm">
        <span className="font-sansSemiBold">Connect to Mango Voice</span>
        <span>Mango Voice can be connected to allow call tracking and management directly from Archy.</span>
        <div className={cxStyles.stepContainer}>
          <span className="font-sansSemiBold">Step 1</span>
          <div className="flex items-center">
            Copy your Remote Key&nbsp;
            <span className="mr-2 font-sansSemiBold">{practice.uuid}</span>
            <ButtonIcon SvgIcon={CopyIcon} onClick={handleCopyKey} />
          </div>
        </div>
        <div className={cxStyles.stepContainer}>
          <span className="font-sansSemiBold">Step 2</span>
          <div>
            Have an authorized user log in to&nbsp;
            <ExternalLink name="mangoAdmin">admin.mangovoice.com</ExternalLink>
          </div>
        </div>
        <div className={cxStyles.stepContainer}>
          <span className="font-sansSemiBold">Step 3</span>
          <div>
            Navigate to&nbsp;
            <span className="font-sansSemiBold">Integrations</span>, find&nbsp;
            <span className="font-sansSemiBold">Archy</span>, and select&nbsp;
            <span className="font-sansSemiBold">Learn More</span>
          </div>
        </div>
        <div className={cxStyles.stepContainer}>
          <span className="font-sansSemiBold">Step 4</span>
          <div>
            Paste your Remote Key {copyInstructions}into the box and click&nbsp;
            <span className="font-sansSemiBold">Create Integration</span>
          </div>
        </div>
        <div className={cxStyles.stepContainer}>
          <span className="font-sansSemiBold">Step 5</span>
          <div>
            Next, navigate to&nbsp;
            <span className="font-sansSemiBold">Authorization</span>
            &nbsp;in Mango (under&nbsp;<span className="font-sansSemiBold">Integrations</span>) and copy the
            Key
          </div>
        </div>
        <div className={cxStyles.stepContainer}>
          <span className="font-sansSemiBold">Step 6</span>
          <div>
            Return to this screen, paste {copyInstructions}the Key below and click&nbsp;
            <span className="font-sansSemiBold">Connect</span>
          </div>
        </div>
        <FormFieldInput
          onChange={(e) => onUpdateAuthKey(e.target.value)}
          placeholder="Paste authorization key"
          value={authKey}
        />
        <Button className="w-28" onClick={onConnect} theme="primary">
          Connect
        </Button>
      </div>
    </div>
  );
};
