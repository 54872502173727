import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { PatientInsuranceVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as MenuVertical } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as ArchiveIcon } from "@libs/assets/icons/archive.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as OneIcon } from "@libs/assets/icons/one.svg";
import { ReactComponent as TwoIcon } from "@libs/assets/icons/two.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { MenuOptions, createMenuOption, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { updatePatientInsuranceOrdinal } from "api/patientInsurance/mutations";
import { ConfirmArchiveModal } from "components/PatientProfile/Insurance/ConfirmArchiveModal";
import { handleError } from "utils/handleError";
import { paths } from "utils/routing/paths";

export const PlanActionMenu: React.FC<{
  hasMultipleInsurances: boolean;
  insurance: PatientInsuranceVO;
  patientId: number;
}> = ({ hasMultipleInsurances, patientId, insurance }) => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const menu = useBoolean(false);
  const setAsPrimaryModal = useBoolean(false);
  const archiveModal = useBoolean(false);

  const [updateInsuranceOrdinalMutation] = useApiMutations([updatePatientInsuranceOrdinal]);

  const handleOrdinalUpdate = useCallback(
    (ordinal: "PRIMARY" | "SECONDARY") => {
      updateInsuranceOrdinalMutation.mutate(
        {
          insuranceId: insurance.id,
          data: {
            ordinal,
          },
          patientId,
          practiceId,
        },
        {
          onError: handleError,
        }
      );
    },
    [insurance.id, patientId, practiceId, updateInsuranceOrdinalMutation]
  );

  const handleNavigateToInsurance = useCallback(() => {
    navigate(
      paths.patientInsuranceStep({
        patientId: insurance.patientId,
        insuranceId: insurance.id,
        step: "info",
      })
    );
  }, [navigate, insurance.id, insurance.patientId]);

  const menuOptions = useMemo(() => {
    const setAsPrimaryOption = createMenuOption({
      disabled: insurance.insuranceOrdinal === "PRIMARY",
      SvgIcon: OneIcon,
      label: "Set as Primary",
      value: "setAsPrimary",
    });
    const setAsSecondaryOption = createMenuOption({
      disabled: insurance.insuranceOrdinal === "SECONDARY",
      SvgIcon: TwoIcon,
      label: "Set as Secondary",
      value: "setAsSecondary",
    });
    const baseOptions = createMenuOptions(
      {
        SvgIcon: EditIcon,
        label: "Edit",
        value: "edit",
      },
      {
        SvgIcon: ArchiveIcon,
        label: "Archive",
        value: "archive",
      }
    );

    return hasMultipleInsurances ? [setAsPrimaryOption, setAsSecondaryOption, ...baseOptions] : baseOptions;
  }, [hasMultipleInsurances, insurance.insuranceOrdinal]);

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "setAsPrimary": {
          setAsPrimaryModal.on();
          break;
        }
        case "setAsSecondary": {
          handleOrdinalUpdate("SECONDARY");
          break;
        }
        case "edit": {
          handleNavigateToInsurance();
          break;
        }
        case "archive": {
          archiveModal.on();
          break;
        }
        default: {
          break;
        }
      }

      menu.off();
    },
    [archiveModal, handleNavigateToInsurance, handleOrdinalUpdate, menu, setAsPrimaryModal]
  );

  return (
    <>
      <ButtonMenu
        isOpen={menu.isOn}
        menuContent={
          <div className="w-44">
            <MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />
          </div>
        }
        onRequestClose={menu.off}
        onRequestOpen={menu.on}
        placement="bottom-end"
      >
        {(props) => <ButtonIcon {...props} size="sm" theme="primary" SvgIcon={MenuVertical} />}
      </ButtonMenu>
      {setAsPrimaryModal.isOn && (
        <ConfirmationModal
          onCancel={setAsPrimaryModal.off}
          onConfirm={() => {
            handleOrdinalUpdate("PRIMARY");
            setAsPrimaryModal.off();
          }}
          primaryText="Are you sure you want to set this as Primary Insurance?"
        />
      )}
      {archiveModal.isOn && (
        <ConfirmArchiveModal
          expirationDate={insurance.expiryDate}
          insuranceId={insurance.id}
          onAction={archiveModal.off}
          patientId={patientId}
          practiceId={practiceId}
        />
      )}
    </>
  );
};
