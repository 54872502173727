import { useCallback, useContext, useEffect, useRef, useState } from "react";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import {
  ClaimOrthoVO,
  ClaimUpdateRequest,
  ClaimVO,
  ErrorResponse,
  HttpResponse,
  PatientInsuranceResponse,
  ProviderVO,
  SuccessResponseClaimVO,
} from "@libs/api/generated-api";
import { SECOND_IN_MS } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { useAccount } from "@libs/contexts/AccountContext";
import { ConfirmationModal, ConfirmationModalProps } from "@libs/components/UI/ConfirmationModal";
import { ClaimDetailsHeader } from "components/Claim/ClaimDetailsHeader";
import {
  getLatestLineItemsGroup,
  isCompletedClaim,
  isDraftClaim,
  isInDraftEobPaymentClaim,
  isPreAuthClaim,
  isSubmittedClaim,
  isSubmittingClaim,
} from "components/Claims/utils";
import { handleError } from "utils/handleError";
import { deleteAttachments, updateClaim, voidClaim } from "api/claim/mutations";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { useItemModal } from "hooks/useItemModal";
import { ClaimFields } from "components/Claim/ClaimFields";
import { getClaimFieldsUpdate, getSelectedClaimFields, isMissingRequirements } from "components/Claim/utils";
import { ClaimFieldValueMap, ClaimField, PrintVersion } from "components/Claims/types";
import { ProcedureTableSection } from "components/Claim/ProcedureTableSection";
import { ClaimPaymentsSection } from "components/Claim/PaymentsSection";
import { PendingEobsSection } from "components/Claim/PendingEobsSection";
import { useSubmitClaim } from "components/Claims/Shared/useSubmitClaim";
import { RequirementsSection } from "components/Claim/RequirementsSection";
import { EobsSection } from "components/Claim/EobsSection";
import { OrthoSection } from "components/Claim/OrthoSection";
import { RemarksSection } from "components/Claim/RemarksSection";
import { DetailsPageFooter } from "components/Claim/DetailsPageFooter";
import { useCreateDraftEobPayment } from "components/Claims/Shared/useCreateDraftEobPayment";
import { useNotificationContext } from "contexts/NotificationsContext";
import { claimConstants } from "components/Claim/claimConstants";
import { printFile } from "utils/files";

interface Props {
  claim: ClaimVO;
  patientInsurances: PatientInsuranceResponse[];
  practiceBillingProviders: ProviderVO[];
  practiceProviders: ProviderVO[];
}

const TEN = 10;
const TEN_SECONDS_IN_MS = SECOND_IN_MS * TEN;

// eslint-disable-next-line complexity, max-statements
export const ClaimDetailsPage: React.FC<Props> = ({
  claim,
  patientInsurances,
  practiceBillingProviders,
  practiceProviders,
}) => {
  const { fetchBlob } = useContext(ApiClientContext);
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const { query } = useQueryParams("claim");
  const from = query.from ?? paths.claims();
  const notifications = useNotificationContext();

  const isPreAuth = isPreAuthClaim(claim);
  const isDraft = isDraftClaim(claim);
  const isSubmitting = isSubmittingClaim(claim);
  const isSubmitted = isSubmittedClaim(claim);
  const isCompleted = isCompletedClaim(claim);
  const isInDraftEobPayment = isInDraftEobPaymentClaim(claim);
  const latestLineItemsGroup = getLatestLineItemsGroup(claim);
  const { sessionStorage } = useStorageContext();
  const actionsDisabled = useBoolean(false);

  const [deleteAttachmentsMutation, updateClaimMutation, voidClaimMutation] = useApiMutations([
    deleteAttachments,
    updateClaim,
    voidClaim,
  ]);

  const confirmationModal = useItemModal<ConfirmationModalProps>(null);

  const updatePromiseRef = useRef<Promise<HttpResponse<SuccessResponseClaimVO, ErrorResponse>> | null>(null);

  const initialSelectedClaimFields = getSelectedClaimFields(claim);
  const [selectedFields, setSelectedFields] = useState<ClaimFieldValueMap>(initialSelectedClaimFields);
  const [selectedLineItems, setSelectedLineItems] = useState<Set<number>>(
    new Set(
      getLatestLineItemsGroup(claim)
        ?.lineItems.filter((lineItem) => lineItem.isIncluded)
        .map((lineItem) => lineItem.patientProcedureId)
    )
  );

  const [eobClippings, setEobClippings] = useState(
    claim.attachments.filter((attachment) => attachment.type === "EOB")
  );
  const [remarks, setRemarks] = useState<string | null>(claim.notes ?? null);
  const [orthoFields, setOrthoFields] = useState<Partial<ClaimOrthoVO>>(claim.ortho || {});

  const handleUpdateClaim = useCallback(
    async (updates: ClaimUpdateRequest) => {
      try {
        if (updatePromiseRef.current) {
          await updatePromiseRef.current;
        }

        const updatePromise = updateClaimMutation.mutateAsync({
          practiceId,
          claimUuid: claim.uuid,
          data: updates,
        });

        updatePromiseRef.current = updatePromise;
        await updatePromise;
      } finally {
        updatePromiseRef.current = null;
      }
    },
    [claim, practiceId, updateClaimMutation]
  );

  const handleUpdateOrthoAndRemarks = useCallback(async () => {
    const existingOrtho: Partial<ClaimOrthoVO> = claim.ortho || {};

    if (
      remarks !== claim.notes ||
      existingOrtho.orthoDate !== orthoFields.orthoDate ||
      existingOrtho.orthoMonthsRemaining !== orthoFields.orthoMonthsRemaining ||
      existingOrtho.orthoMonthsTotal !== orthoFields.orthoMonthsTotal
    ) {
      await handleUpdateClaim({
        notes: remarks || null,
        orthoDate: orthoFields.orthoDate,
        orthoMonthsRemaining: orthoFields.orthoMonthsRemaining,
        orthoMonthsTotal: orthoFields.orthoMonthsTotal,
      });
    }
  }, [
    claim.notes,
    claim.ortho,
    handleUpdateClaim,
    orthoFields.orthoDate,
    orthoFields.orthoMonthsRemaining,
    orthoFields.orthoMonthsTotal,
    remarks,
  ]);

  const awaitSavePromises = useCallback(async () => {
    if (updatePromiseRef.current) {
      await updatePromiseRef.current;
    }

    await handleUpdateOrthoAndRemarks();
  }, [handleUpdateOrthoAndRemarks]);

  const handleExitEditDraft = useCallback(async () => {
    try {
      await awaitSavePromises();
      navigate(from, { replace: true });
    } catch (err) {
      handleError(err);
    }
  }, [awaitSavePromises, from, navigate]);

  const onNavigateBack = useCallback(() => {
    return isDraft
      ? confirmationModal.open({
          onCancel: confirmationModal.close,
          onConfirm: handleExitEditDraft,
          primaryText: "Are you sure you want to leave this as a draft?",
          secondaryText: `Your data is saved and you can submit this ${
            claim.isPreAuth ? "pre-auth " : ""
          }claim later.`,
        })
      : navigate(from, { replace: true });
  }, [claim.isPreAuth, confirmationModal, from, handleExitEditDraft, navigate, isDraft]);

  const handleUpdateClaimField = useCallback(
    (field: ClaimField, val: number) => {
      setSelectedFields((last) => {
        return { ...last, [field]: val };
      });

      const updates = getClaimFieldsUpdate(initialSelectedClaimFields, { ...selectedFields, [field]: val });

      handleUpdateClaim(updates).catch(handleError);
    },
    [handleUpdateClaim, initialSelectedClaimFields, selectedFields]
  );

  const handleSelectCheckbox = useCallback(
    (id: number) => {
      const selectedLineItemsCopy = new Set(selectedLineItems);

      if (selectedLineItemsCopy.has(id)) {
        if (selectedLineItemsCopy.size === 1) {
          toast.error("At least one patient procedure must be selected on the claim.");

          return;
        }

        selectedLineItemsCopy.delete(id);
      } else {
        selectedLineItemsCopy.add(id);
      }

      setSelectedLineItems(new Set(selectedLineItemsCopy));

      handleUpdateClaim({ patientProcedureIds: [...selectedLineItemsCopy] }).catch(handleError);
    },
    [handleUpdateClaim, selectedLineItems]
  );

  const handleRemoveEobClipping = useCallback(
    async ({ attachmentUuid }: { attachmentUuid: string }) => {
      const updatedClippings = eobClippings.filter((clipping) => clipping.uuid !== attachmentUuid);

      setEobClippings(updatedClippings);

      try {
        await deleteAttachmentsMutation.mutateAsync({
          practiceId,
          claimUuid: claim.uuid,
          data: { attachmentUuids: [attachmentUuid] },
        });
      } catch (e) {
        handleError(e);

        return;
      }
    },
    [eobClippings, deleteAttachmentsMutation, practiceId, claim.uuid]
  );

  const deleteAttachment = useCallback(
    async (attachmentUuid: string) => {
      try {
        await deleteAttachmentsMutation.mutateAsync({
          practiceId,
          claimUuid: claim.uuid,
          data: { attachmentUuids: [attachmentUuid] },
        });
      } catch (e) {
        handleError(e);

        return;
      }
    },
    [deleteAttachmentsMutation, practiceId, claim.uuid]
  );

  const voidThisClaim = useCallback(async () => {
    try {
      confirmationModal.close();
      await voidClaimMutation.mutateAsync({
        practiceId,
        claimUuid: claim.uuid,
      });
    } catch (e) {
      handleError(e);
    }
  }, [claim.uuid, confirmationModal, practiceId, voidClaimMutation]);

  const handleVoidClick = useCallback(() => {
    confirmationModal.open({
      onCancel: confirmationModal.close,
      onConfirm: voidThisClaim,
      primaryText: claimConstants.voidConfirmation,
      secondaryText: claimConstants.voidSecondaryConfirmation,
    });
  }, [confirmationModal, voidThisClaim]);

  const handleUpdateOrthoFields = useCallback(
    <T extends keyof ClaimOrthoVO>(prop: T, value: ClaimOrthoVO[T] | undefined) => {
      setOrthoFields((existing) => ({ ...existing, [prop]: value }));
    },
    []
  );

  const { bulkSubmit, replace, skipPayment, submitManually, deleteClaim, isExecutingRequest } =
    useSubmitClaim();

  const handleManuallySubmit = useCallback(async () => {
    try {
      actionsDisabled.on();
      await awaitSavePromises();
      await submitManually(claim.uuid, from);
    } catch (err) {
      handleError(err);
    } finally {
      actionsDisabled.off();
    }
  }, [actionsDisabled, awaitSavePromises, claim.uuid, from, submitManually]);

  const handleManuallySubmitClick = useCallback(() => {
    confirmationModal.open({
      onCancel: confirmationModal.close,
      onConfirm: handleManuallySubmit,
      primaryText: claimConstants.manualSubmitConfirmation,
      secondaryText: claimConstants.manualSubmitSecondaryConfirmation,
    });
  }, [confirmationModal, handleManuallySubmit]);

  const handleSkipPayment = useCallback(async () => {
    try {
      actionsDisabled.on();
      await awaitSavePromises();
      await skipPayment(claim.uuid, from);
    } catch (err) {
      handleError(err);
    } finally {
      actionsDisabled.off();
    }
  }, [actionsDisabled, awaitSavePromises, skipPayment, claim.uuid, from]);

  const handleSkipPaymentClick = useCallback(() => {
    confirmationModal.open({
      onCancel: confirmationModal.close,
      onConfirm: handleSkipPayment,
      primaryText: claimConstants.skipPaymentConfirmation,
      secondaryText: claimConstants.skipPaymentSecondaryConfirmation,
    });
  }, [confirmationModal, handleSkipPayment]);

  const { handleCreateDraftEobPayment } = useCreateDraftEobPayment(from);

  const handleCreateDraftEob = useCallback(() => {
    handleCreateDraftEobPayment(claim.insuranceCarrierId, [claim.uuid]);
  }, [claim.insuranceCarrierId, claim.uuid, handleCreateDraftEobPayment]);

  const handleResubmit = useCallback(async () => {
    try {
      actionsDisabled.on();
      await replace(claim.uuid, from);
    } catch (error) {
      handleError(error);
    } finally {
      actionsDisabled.off();
    }
  }, [actionsDisabled, claim.uuid, from, replace]);

  const handleResubmitClick = useCallback(() => {
    confirmationModal.open({
      onCancel: confirmationModal.close,
      onConfirm: handleResubmit,
      primaryText: claimConstants.resubmitConfirmation,
      secondaryText: claimConstants.resubmitSecondaryConfirmation,
    });
  }, [confirmationModal, handleResubmit]);

  const handleDeleteClick = useCallback(() => {
    confirmationModal.open({
      onCancel: confirmationModal.close,
      onConfirm: () => deleteClaim(claim.uuid, from),
      primaryText: claimConstants.deleteConfirmation,
      secondaryText: claimConstants.deleteSecondaryConfirmation,
    });
  }, [claim.uuid, confirmationModal, deleteClaim, from]);

  const handleDeleteAndCreateClick = useCallback(() => {
    confirmationModal.open({
      onCancel: confirmationModal.close,
      onConfirm: () => {
        sessionStorage.setItem("openCreateClaim", "1");

        deleteClaim(claim.uuid, paths.patientClaims({ patientId: claim.patientName.id }));
      },
      primaryText: claimConstants.deleteConfirmation,
    });
  }, [claim.patientName.id, claim.uuid, confirmationModal, deleteClaim, sessionStorage]);

  const asyncSubmitting = useBoolean(false);
  const handleSubmitClick = useCallback(
    async (overrideNeedsAttachment = false) => {
      try {
        actionsDisabled.on();
        await awaitSavePromises();
      } catch (err) {
        handleError(err);

        return;
      }

      const missingRequirements = isMissingRequirements(
        getLatestLineItemsGroup(claim)?.lineItems ?? [],
        claim.attachments
      );

      if (!overrideNeedsAttachment && missingRequirements) {
        confirmationModal.open({
          onCancel: () => {
            confirmationModal.close();
            actionsDisabled.off();
          },
          onConfirm: () => {
            confirmationModal.close();
            handleSubmitClick(true);
          },
          primaryText: claimConstants.missingRequirementsConfirmation,
          secondaryText: claimConstants.missingRequirementsSecondaryConfirmation,
        });
      } else {
        try {
          asyncSubmitting.on();
          await bulkSubmit({ uuids: [claim.uuid], overrideNeedsAttachment });
        } catch (err) {
          asyncSubmitting.off();
          handleError(err);
        } finally {
          actionsDisabled.off();
        }
      }
    },
    [claim, actionsDisabled, awaitSavePromises, confirmationModal, asyncSubmitting, bulkSubmit]
  );

  useEffect(() => {
    if (asyncSubmitting.isOn && !isSubmitting && isSubmitted) {
      asyncSubmitting.off();
      notifications.handleSuccess("Claim submitted");
    }
  }, [asyncSubmitting, isSubmitted, isSubmitting, notifications]);

  const closeAsyncProcessingClaim = useCallback(() => {
    if (isSubmitting) {
      navigate(from, { replace: true });
      toast.info("Claim is still being submitted. Come back later to check claim status.");
    }
  }, [isSubmitting, from, navigate]);

  useEffect(() => {
    const timeout = asyncSubmitting.isOn
      ? window.setTimeout(() => {
          closeAsyncProcessingClaim();
        }, TEN_SECONDS_IN_MS)
      : 0;

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [asyncSubmitting.isOn, isSubmitting, closeAsyncProcessingClaim, from, navigate]);

  const handlePrint = useCallback(
    async (version: PrintVersion) => {
      try {
        actionsDisabled.on();
        await awaitSavePromises();
      } catch (err) {
        handleError(err);

        return;
      } finally {
        actionsDisabled.off();
      }

      try {
        const blob = await fetchBlob(
          `/practices/${practiceId}/claims/${claim.uuid}/print?printVersion=${version}`
        );

        printFile(blob);
      } catch (err) {
        handleError(err);
      }
    },
    [actionsDisabled, awaitSavePromises, fetchBlob, practiceId, claim.uuid]
  );

  return (
    <>
      <ClaimDetailsHeader claim={claim} from={from} onPrint={handlePrint} onNavigateBack={onNavigateBack} />
      <div className="flex flex-col h-full px-8 py-6 overflow-y-auto">
        <ClaimFields
          claim={claim}
          onSelect={handleUpdateClaimField}
          patientInsurances={patientInsurances}
          practiceBillingProviders={practiceBillingProviders}
          practiceProviders={practiceProviders}
          selectedOptions={getSelectedClaimFields(claim)}
        />
        <ProcedureTableSection
          claim={claim}
          onSelectCheckbox={handleSelectCheckbox}
          selectedLineItems={selectedLineItems}
        />
        {isCompleted && claim.payments.length ? (
          <ClaimPaymentsSection
            claimPayments={claim.payments}
            insuranceAmount={claim.insuranceAmount}
            patientAmount={claim.patientAmount}
          />
        ) : null}
        {claim.insuranceOrdinal !== "PRIMARY" && (
          <PendingEobsSection
            claimUuid={claim.uuid}
            images={eobClippings}
            isDraftClaim={isDraft}
            onRemoveImage={handleRemoveEobClipping}
          />
        )}
        <RequirementsSection
          claimAttachments={[...claim.attachments]}
          claimLineItems={latestLineItemsGroup?.lineItems ?? []}
          claimUuid={claim.uuid}
          deleteAttachment={deleteAttachment}
          isDraftClaim={isDraft}
          supportsDigitalAttachments={claim.insuranceAcceptsElectronicAttachments}
        />
        {isCompleted && (
          <div className="w-[700px]">
            <EobsSection canDownload={true} files={claim.eobFiles} title="Attached EOBs" />
          </div>
        )}
        <RemarksSection remarks={remarks ?? ""} isEditing={isDraft} onChange={setRemarks} />
        {claim.ortho && (
          <OrthoSection isEditing={isDraft} orthoData={orthoFields} onChange={handleUpdateOrthoFields} />
        )}
      </div>
      <DetailsPageFooter
        claim={claim}
        isDraft={isDraft}
        isInDraftEobPayment={isInDraftEobPayment}
        isPreAuth={isPreAuth}
        isPrimaryClaim={claim.insuranceOrdinal === "PRIMARY"}
        isSubmitted={isSubmitted}
        isSubmitting={isSubmitting}
        onCancelClick={onNavigateBack}
        onCreateDraftEobPayment={handleCreateDraftEob}
        onManuallySubmitClick={handleManuallySubmitClick}
        onResubmitClick={handleResubmitClick}
        onSkipPaymentClick={handleSkipPaymentClick}
        onSubmitClick={handleSubmitClick}
        onDeleteClick={handleDeleteClick}
        onDeleteAndCreateClick={handleDeleteAndCreateClick}
        onVoidClick={handleVoidClick}
        disableActions={actionsDisabled.isOn || isExecutingRequest || isSubmitting}
      />
      {confirmationModal.isOpen && (
        <ConfirmationModal
          onCancel={confirmationModal.item.onCancel}
          onConfirm={confirmationModal.item.onConfirm}
          primaryText={confirmationModal.item.primaryText}
          secondaryText={confirmationModal.item.secondaryText}
        />
      )}
    </>
  );
};
