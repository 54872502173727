import { FC, useMemo, useState } from "react";
import { setYear } from "date-fns";
import { CustomHolidayVO } from "@libs/api/generated-api";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { isString } from "@libs/utils/types";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { YearTabs } from "components/Settings/YearTabs";
import { useNow } from "hooks/useNow";
import { EditableCustomHoliday, TimeOffForm } from "./TimeOffForm";
import { TimeOffTable } from "./TimeOffTable";

interface Props {
  isEditing: boolean;
  isSaving: boolean;
  onCancelEdit: Func;
  onRequestEdit: Func;
  onSave: (data: CustomHolidayVO[]) => void;
  customHolidays: CustomHolidayVO[];
}

const getHolidaysForYear = (customHolidays: CustomHolidayVO[], year: string) =>
  customHolidays.filter((item) => getLocalDate(item.startDate).getFullYear().toString() === year);

export const TimeOff: FC<Props> = ({
  customHolidays,
  isEditing,
  isSaving,
  onRequestEdit,
  onCancelEdit,
  onSave,
}) => {
  const now = useNow();
  const [selectedYear, setSelectedYear] = useState(() => now.getFullYear().toString());
  const customHolidaysForYear = useMemo(
    () => getHolidaysForYear(customHolidays, selectedYear),
    [customHolidays, selectedYear]
  );
  const [editableCustomHolidays, setEditableCustomHolidays] = useState<EditableCustomHoliday[]>(
    () => customHolidaysForYear as unknown as EditableCustomHoliday[]
  );
  const handleSave = () => {
    const data = editableCustomHolidays.map(({ id, ...item }) => {
      if (isString(id)) {
        return item;
      }

      return {
        ...item,
        id,
      };
    });
    const otherYears = customHolidays.filter(
      (item) => getLocalDate(item.startDate).getFullYear().toString() !== selectedYear
    );

    onSave([...otherYears, ...data]);
  };

  const handleSelectYear = (year: string) => {
    setSelectedYear(year);
    setEditableCustomHolidays(getHolidaysForYear(customHolidays, year) as unknown as EditableCustomHoliday[]);
  };

  const handleItemChange = (index: number, value: Partial<EditableCustomHoliday>) => {
    setEditableCustomHolidays((last) =>
      last.map((item, currentIndex) => (currentIndex === index ? { ...item, ...value } : item))
    );
  };

  const handleAdd = () => {
    const nowInYear = formatAsISODate(setYear(now, Number(selectedYear)));

    setEditableCustomHolidays((last) => [
      ...last,
      {
        id: crypto.randomUUID(),
        description: "",
        endTime: "00:00:00",
        startTime: "00:00:00",
        endDate: nowInYear,
        startDate: nowInYear,
      },
    ]);
  };

  const handleRemove = (index: number) => {
    setEditableCustomHolidays((last) => last.filter((_, currentIndex) => index !== currentIndex));
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center mb-1 k">
        <p className="text-sm mr-4 font-sansSemiBold">Provider Time Off</p>
        {!isEditing && (
          <ButtonIcon tooltip={{ content: "Edit" }} SvgIcon={EditIcon} onClick={onRequestEdit} />
        )}
      </div>
      <YearTabs selectedTab={selectedYear} onSelect={handleSelectYear} />

      {isEditing ? (
        <TimeOffForm
          selectedYear={selectedYear}
          customHolidays={editableCustomHolidays}
          isSaving={isSaving}
          onCancel={onCancelEdit}
          onSave={handleSave}
          onAddItem={handleAdd}
          onRemoveItem={handleRemove}
          onChangeItem={handleItemChange}
        />
      ) : customHolidaysForYear.length ? (
        <TimeOffTable customHolidays={customHolidaysForYear} />
      ) : (
        <p className="text-xs mb-6">No Time Off Scheduled.</p>
      )}
    </div>
  );
};
