import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { ReactComponent as MessageIcon } from "@libs/assets/icons/messages.svg";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";
import { paths } from "utils/routing/paths";

type Props = {
  last?: boolean;
  to: string;
  patientId: number;
};
export const MessagePatientCell: React.FC<Props> = ({ last, to, patientId }) => {
  return (
    <DashboardLinkCell last={last} to={to}>
      <LinkIcon
        theme="primary"
        SvgIcon={MessageIcon}
        tooltip={{ content: "Send Text", theme: "SMALL" }}
        to={paths.messaging({ patientId })}
        onClick={(e) => {
          e.stopPropagation();
        }}
      />
    </DashboardLinkCell>
  );
};
