import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { FC, useCallback, useMemo, useState } from "react";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { TagVO } from "@libs/api/generated-api";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { isOneOf } from "@libs/utils/isOneOf";
import { isHttpResponseError } from "@libs/utils/isHttpResponseError";
import { lazyDefault } from "@libs/utils/lazyDefault";
import { Panel } from "@libs/components/UI/Panel";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { QueryFilters } from "@libs/components/UI/QueryFilters";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { toFilterComponentsProps } from "@libs/components/UI/queryFilterPillsUtils";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { getTags } from "api/settings/notes/queries";
import { useQueryParams } from "hooks/useQueryParams";
import { useItemModal } from "hooks/useItemModal";
import { TagsTable } from "components/Settings/Notes/TagsTable";
import { toggleTagArchive } from "api/settings/notes/mutations";
import { handleError } from "utils/handleError";
import { FlyoverV2 } from "components/UI/FlyoverV2";
import { NotesTagsQuery } from "components/Settings/Notes/types";
import { getTagValidations, TagValidations } from "components/Settings/Notes/utils";

const FilterTagsFlyover = lazyDefault(
  () => import("components/Settings/Notes/FilterTagsFlyover"),
  "FilterTagsFlyover"
);
const EditTagFlyover = lazyDefault(
  () => import("components/Settings/Notes/EditTagFlyover"),
  "EditTagFlyover"
);
const AddTagFlyover = lazyDefault(() => import("components/Settings/Notes/AddTagFlyover"), "AddTagFlyover");

const getFilterProps = (params: NotesTagsQuery) => {
  return toFilterComponentsProps(params, [
    {
      type: "default",
      prop: "includeArchived",
      format: () => `Show Archived`,
    },
    {
      type: "list",
      prop: "typeFilters",
      format: (val) => sentenceCaseConstant(val),
    },
  ]);
};

export const TagsRoute: FC = () => {
  const unarchiveTagValidations = useItemModal<{ tagId: number; validations: TagValidations }>(null);
  const [validations, setValidations] = useState<TagValidations>();
  const { practiceId } = useAccount();
  const { query, updateQuery } = useQueryParams("noteTags");
  const [tagsQuery] = useApiQueries([
    getTags({
      args: {
        practiceId,
        includeArchived: query.includeArchived,
        typeFilters: query.typeFilters,
      },
    }),
  ]);

  const [toggleTagArchiveMutation] = useApiMutations([toggleTagArchive]);

  const handleQueryParamChange = useCallback(
    (keyValues: Partial<NotesTagsQuery>) => {
      updateQuery("replaceIn", keyValues);
    },
    [updateQuery]
  );

  const openFilterTags = () => {
    updateQuery("replaceIn", { filterTags: true });
  };

  const closeFilterTags = () => {
    updateQuery("replaceIn", { filterTags: false });
  };

  const openAddTag = () => {
    updateQuery("replaceIn", { addTag: true });
  };

  const closeAddTag = () => {
    updateQuery("replaceIn", { addTag: false });
  };

  const openEditTag = (tagId: number) => {
    updateQuery("replaceIn", { ediTag: tagId });
  };

  const closeEditTag = () => {
    updateQuery("replaceIn", { ediTag: undefined });
    setValidations(undefined);
  };

  const handleTagOptionClick = (tag: TagVO, value: "Archive" | "Edit" | "Unarchive") => {
    if (isOneOf(value, ["Archive", "Unarchive"])) {
      toggleTagArchiveMutation.mutate(
        {
          practiceId,
          tagId: tag.id,
          data: {
            ...tag,
            isArchived: value === "Archive",
          },
        },
        {
          onError: (err) => {
            if (isHttpResponseError(err) && value === "Unarchive") {
              const tagValidations = getTagValidations(err);

              if (tagValidations) {
                unarchiveTagValidations.open({ tagId: tag.id, validations: tagValidations });

                return;
              }
            }

            handleError(err);
          },
        }
      );
      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    } else if (value === "Edit") {
      openEditTag(tag.id);
    }
  };

  const filterProps = useMemo(() => getFilterProps(query), [query]);
  const editTag = query.ediTag ? tagsQuery.data?.find((tag) => query.ediTag === tag.id) : undefined;

  return (
    <>
      <Panel
        className="h-full"
        title={
          <div className="flex gap-x-2 items-center">
            Tags
            <ButtonIcon
              tooltip={{ content: "Add Tag", theme: "SMALL" }}
              theme="primary"
              SvgIcon={AddIcon}
              onClick={openAddTag}
            />
          </div>
        }
        includePadding={false}
      >
        <QueryResult queries={[tagsQuery]}>
          {tagsQuery.data ? (
            <div className="h-full flex flex-col">
              <QueryFilters
                queries={[tagsQuery]}
                onUpdateParams={handleQueryParamChange}
                totalElements={tagsQuery.data.length}
                onOpenFlyover={openFilterTags}
                {...filterProps}
              />
              <PersistScrollPosition className="flex-1 min-h-0 overflow-y-auto" id="tags-table">
                <TagsTable onRowOptionClick={handleTagOptionClick} tags={tagsQuery.data} />
              </PersistScrollPosition>
            </div>
          ) : null}
        </QueryResult>
      </Panel>
      <FlyoverV2 onClickAway={closeFilterTags} isOpen={query.filterTags}>
        <FilterTagsFlyover
          onRequestClose={closeFilterTags}
          onApplyFilters={handleQueryParamChange}
          tagTypes={query.typeFilters}
          includeArchived={query.includeArchived}
        />
      </FlyoverV2>
      <FlyoverV2 isOpen={Boolean(editTag)}>
        {editTag ? (
          <EditTagFlyover validations={validations} onRequestClose={closeEditTag} tag={editTag} />
        ) : null}
      </FlyoverV2>
      <FlyoverV2 isOpen={query.addTag}>
        <AddTagFlyover onRequestClose={closeAddTag} />
      </FlyoverV2>

      {unarchiveTagValidations.isOpen ? (
        <ConfirmationModal
          size="2xs"
          primaryText="Tag Conflicts"
          secondaryText="The tag you are trying to unarchive has conflicts with another tag. You will need to edit it before it can be restored."
          confirmText="Okay"
          onCancel={unarchiveTagValidations.close}
          onConfirm={() => {
            setValidations(unarchiveTagValidations.item.validations);
            openEditTag(unarchiveTagValidations.item.tagId);
            unarchiveTagValidations.close();
          }}
        />
      ) : null}
    </>
  );
};
