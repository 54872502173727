import { ReactNode, useMemo } from "react";
import { ContactVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { isDefined } from "@libs/utils/types";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Banner } from "@libs/components/UI/Banner";

const InnerContactBanner: React.FC<{
  className?: string;
  isTextingDisabled: boolean;
  children: ReactNode;
}> = ({ className, children, isTextingDisabled }) => {
  const ignore = useBoolean(false);

  return ignore.isOn ? null : (
    <Banner
      className={cx("rounded text-sm", className)}
      paddingClassName="p-3"
      onRequestClose={isTextingDisabled ? undefined : ignore.on}
      includeIcon={isTextingDisabled}
      theme="info"
    >
      {children}
    </Banner>
  );
};

interface Props {
  contact: ContactVO;
  patientName: string;
  className?: string;
}

export const ContactBanner: React.FC<Props> = ({ className, patientName, contact }) => {
  const redirectedMessage = useMemo(() => {
    return contact.relation === "SELF"
      ? undefined
      : `You have been redirected to ${patientName}'s designated contact person, ${contact.name.fullDisplayName}.`;
  }, [contact, patientName]);
  const textingDisabledMessage = useMemo(() => {
    if (!contact.textPhone) {
      return `This patient has not added a cell phone number.`;
    }

    if (!contact.contactModes.text) {
      return `This patient has not allowed text messages.`;
    }

    return undefined;
  }, [contact]);

  const message = [redirectedMessage, textingDisabledMessage].filter(isDefined).join(" ");

  return message ? (
    <InnerContactBanner isTextingDisabled={Boolean(textingDisabledMessage)} className={className}>
      {message}
    </InnerContactBanner>
  ) : null;
};
