import { FC, FormEvent, useState, useCallback, useMemo } from "react";

import { DentalProcedureVO, ProviderVO, AppointmentCategoryVO } from "@libs/api/generated-api";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { mapSelectionsToOptions } from "@libs/utils/mapSelectOptions";
import { FlyoverForm, FlyoverContent, FlyoverFooter } from "components/UI/FlyoverComponents";
import { BaseFormSection } from "components/UI/FormSection";
import { ProcedureSelector } from "components/ProcedureSelector/ProcedureSelector";

import { FormFieldMultiSelect } from "components/UI/FormFieldMultiSelect";
import { EditFormSettingsProps } from "components/Settings/Forms/types";

interface Props extends EditFormSettingsProps {
  procedures: DentalProcedureVO[];
  providers: ProviderVO[];
  categories: AppointmentCategoryVO[];
}

export const ClinicalNotesSettingsForm: FC<Props> = ({
  form,
  procedures,
  providers,
  categories,
  onUpdateSettings,
  onRequestClose,
  isSaving,
}) => {
  const [settings, setSettings] = useState(() => {
    const { dentalProcedures, clinicalNoteData } = form;

    return {
      clinicalNoteData: {
        providerIds: clinicalNoteData?.providers.map((p) => p.id) ?? [],
        appointmentCategoryIds: clinicalNoteData?.appointmentCategories.map((c) => c.id) ?? [],
      },
      dentalProcedures,
    };
  });

  const providerOptions = useMemo(
    () =>
      mapSelectionsToOptions(providers, form.clinicalNoteData?.providers, (provider) => ({
        value: provider.id,
        label: provider.name.fullDisplayName,
      })),
    [providers, form.clinicalNoteData]
  );

  const categoryOptions = useMemo(
    () =>
      mapSelectionsToOptions(categories, form.clinicalNoteData?.appointmentCategories, (category) => ({
        value: category.id,
        label: category.name,
      })),
    [categories, form.clinicalNoteData]
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const providerSet = new Set(settings.clinicalNoteData.providerIds);
      const categorySet = new Set(settings.clinicalNoteData.appointmentCategoryIds);

      onUpdateSettings({
        dentalProcedures: settings.dentalProcedures,
        clinicalNoteData: {
          providers: providers.filter((p) => providerSet.has(p.id)),
          appointmentCategories: categories.filter((c) => categorySet.has(c.id)),
        },
      });
    },
    [settings, onUpdateSettings, categories, providers]
  );

  return (
    <FlyoverForm onSubmit={handleSubmit} data-testid="edit-form-settings">
      <FlyoverContent className="flex flex-col gap-y-6">
        <BaseFormSection
          title={<span className="text-xs">Providers</span>}
          subTitle="Select which providers use this form, if left blank it will show for all providers."
          className="flex flex-col gap-y-1"
        >
          <FormFieldMultiSelect
            aria-label="Providers"
            options={providerOptions}
            hideSelectedOptions
            value={settings.clinicalNoteData.providerIds}
            onItemsSelected={(value) =>
              setSettings((last) => ({
                ...last,
                clinicalNoteData: { ...last.clinicalNoteData, providerIds: value },
              }))
            }
            isSearchable={true}
          />
        </BaseFormSection>
        <BaseFormSection
          title={<span className="text-xs">Procedures</span>}
          subTitle="Select procedures this form is required for so we can attach it for you"
          className="flex flex-col gap-y-1"
        >
          <ProcedureSelector
            aria-label="Procedures"
            placeholder="Select"
            mode="multi"
            hideSelectedOptions
            allowDuplicateSelections={false}
            procedures={procedures}
            values={settings.dentalProcedures}
            onChange={(values) => setSettings((last) => ({ ...last, dentalProcedures: values }))}
          />
        </BaseFormSection>

        <BaseFormSection
          title={<span className="text-xs">Categories</span>}
          subTitle="Select categories this form is required for so we can attach it for you"
          className="flex flex-col gap-y-1"
        >
          <FormFieldMultiSelect
            aria-label="Categories"
            options={categoryOptions}
            hideSelectedOptions
            value={settings.clinicalNoteData.appointmentCategoryIds}
            onItemsSelected={(value) =>
              setSettings((last) => ({
                ...last,
                clinicalNoteData: { ...last.clinicalNoteData, appointmentCategoryIds: value },
              }))
            }
            isSearchable={true}
          />
        </BaseFormSection>
      </FlyoverContent>

      <FlyoverFooter actions>
        <Button className="min-w-button" onClick={onRequestClose} theme="secondary">
          Cancel
        </Button>
        <AsyncButton className="min-w-button" isLoading={isSaving} theme="primary" type="submit">
          Save
        </AsyncButton>
      </FlyoverFooter>
    </FlyoverForm>
  );
};
