import { FC, ReactNode } from "react";

import { DashboardBulkActionsRow } from "components/Dashboard/Tables/DashboardBulkActionsRow";
import { MessagePatientsButton } from "components/Communications/MessagePatients/MessagePatientsButton";

interface Props {
  selectedCount: number;
  total: number;
  onRequestMessagePatients: Func;
  filters: ReactNode;
}

export const RecallReportByPatientBulkActionsRow: FC<Props> = ({
  selectedCount,
  total,
  onRequestMessagePatients,
  filters,
}) => {
  const bulkMessageDisabled = total === 0 || (filters == null && selectedCount === 0);

  return (
    <DashboardBulkActionsRow
      actions={
        <div className="pr-6 h-6">
          <MessagePatientsButton
            onRequestMessagePatients={onRequestMessagePatients}
            disabled={bulkMessageDisabled}
          />
        </div>
      }
      selectedCount={selectedCount}
      total={total}
      singular="patient"
      plural="patients"
      filters={filters}
    />
  );
};
