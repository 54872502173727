import { FC } from "react";
import { OnboardEmployeeRequest } from "@libs/api/generated-api";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";
import { FormFieldSelectEmergencyRelationship } from "components/UI/FormFieldSelectEmergencyRelationship";
import { SkeletonInputField } from "components/UI/SkeletonInputField";
import { OnChangeHandler } from "./types";

export const EmergencyInfoForm: FC<{
  onboardingData?: OnboardEmployeeRequest;
  onChange: OnChangeHandler;
}> = ({ onboardingData, onChange }) => {
  return (
    <div className="grid grid-cols-3 gap-3">
      {onboardingData ? (
        <>
          <FormFieldInput
            label="Emergency Contact Name"
            onChange={(e) =>
              onChange((draft) => {
                draft.contactDetails.emergencyContact = {
                  ...draft.contactDetails.emergencyContact,
                  name: e.target.value,
                };
              })
            }
            value={onboardingData.contactDetails.emergencyContact?.name}
          />
          <FormFieldPhoneInput
            label="Emergency Contact Number"
            onValueChange={(phone) =>
              onChange((draft) => {
                draft.contactDetails.emergencyContact = {
                  ...draft.contactDetails.emergencyContact,
                  phone,
                };
              })
            }
            value={onboardingData.contactDetails.emergencyContact?.phone}
          />
          <FormFieldSelectEmergencyRelationship
            label="Emergency Contact Relationship"
            onChange={(option) =>
              onChange(
                (draft) =>
                  (draft.contactDetails.emergencyContact = {
                    ...draft.contactDetails.emergencyContact,
                    relationship: option?.value,
                  })
              )
            }
            value={onboardingData.contactDetails.emergencyContact?.relationship}
          />
        </>
      ) : (
        <>
          <SkeletonInputField />
          <SkeletonInputField />
          <SkeletonInputField />
        </>
      )}
    </div>
  );
};
