import { scaleBand } from "@visx/scale";
import React from "react";

export type BarStackGroup<T extends string> = {
  date: string;
  stacks: Partial<BarStackValues<T>>[];
};

export type BarStackValues<T extends string> = Record<T, number>;

export type StackBarFootprint = Record<number, number> & {
  date: string;
};

const getStackTotal = <T extends string>(datum: Partial<BarStackValues<T>>) => {
  const pointValues: Record<string, number | undefined> = datum;
  const dataKeys = Object.keys(pointValues);

  return dataKeys
    .map((key) => {
      return pointValues[key] ?? 0;
    })
    .reduce((prev, curr) => prev + curr, 0);
};

export const useBarGroupFootprint = <T extends string>({
  data,
  xScaleBandwidth,
}: {
  data: BarStackGroup<T>[];
  xScaleBandwidth: number;
}) => {
  return React.useMemo(() => {
    const mockKeys = data[0].stacks.map((_, i) => `${i}`);

    return {
      keys: mockKeys,
      x1Scale: scaleBand<string>({
        domain: mockKeys,
        range: [0, xScaleBandwidth],
        padding: 0.1,
      }),
      // Color won't be used, this is just to not cause typescript to complain when representing <BarGroup/> props
      color: () => "grey",
      data: data.map((group) => {
        const result: StackBarFootprint = {
          date: group.date,
        };

        for (const [index, stack] of group.stacks.entries()) {
          result[index] = getStackTotal(stack);
        }

        return result;
      }),
    };
  }, [data, xScaleBandwidth]);
};
