import { FC, useMemo } from "react";

import { PatientProcedureVO } from "@libs/api/generated-api";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Pill, PillProps } from "@libs/components/UI/Pill";
import { formatISODate } from "@libs/utils/date";

interface Props {
  status: PatientProcedureVO["preAuthStatus"];
  submittedDate: PatientProcedureVO["preAuthSubmittedDate"];
  updatedDate: PatientProcedureVO["preAuthUpdatedDate"];
}

type PreAuthStatusKey =
  | Exclude<NonNullable<PatientProcedureVO["preAuthStatus"]>, "APPROVED_RESENT" | "DENIED_RESENT">
  | "NONE";

const PreAuthStatusPillProps: Record<PreAuthStatusKey, Pick<PillProps, "children" | "theme">> = {
  SENT: {
    children: "Pre-Auth Sent",
    theme: "yellow",
  },
  RESENT: {
    children: "Pre-Auth Resent",
    theme: "magenta",
  },
  APPROVED: {
    children: "Pre-Auth Approved",
    theme: "green",
  },
  DENIED: {
    children: "Pre-Auth Denied",
    theme: "red",
  },
  NONE: {
    children: "No Pre-Auth",
    theme: "slate100",
  },
};

const getDateTooltip = (
  status: Props["status"],
  sentDate: string | undefined,
  processedDate: string | undefined
) => {
  switch (status) {
    case "SENT":
    case "RESENT": {
      return `Sent on ${sentDate}`;
    }
    case "APPROVED_RESENT":
    case "DENIED_RESENT": {
      return `Resent on ${sentDate}\n${
        status === "APPROVED_RESENT" ? "Approved" : "Denied"
      } on ${processedDate}`;
    }
    case "APPROVED":
    case "DENIED": {
      // There may be cases where a practice migrates to Archy with prior
      // pre-auth letters that have not been submitted through our system,
      // resulting in an undefined sentDate (preAuthSubmittedDate)
      return `${sentDate ? `Sent on ${sentDate}\n` : ""}${
        status === "APPROVED" ? "Approved" : "Denied"
      } on ${processedDate}`;
    }
    default: {
      return "";
    }
  }
};

export const PreAuthStatusPill: FC<Props> = ({ status, submittedDate, updatedDate }) => {
  const { pillProps, dateTooltip } = useMemo(() => {
    // Get the prefixed status only (e.g. exclude "_RESENT") for pill props
    const [statusKey] = (status ?? "NONE").split("_") as [PreAuthStatusKey];
    const sentDate = submittedDate ? formatISODate(submittedDate) : undefined;
    const processedDate = updatedDate ? formatISODate(updatedDate) : undefined;

    return {
      pillProps: PreAuthStatusPillProps[statusKey],
      dateTooltip: getDateTooltip(status, sentDate, processedDate),
    };
  }, [status, submittedDate, updatedDate]);

  return (
    <FloatingTooltip content={dateTooltip ? <span className="whitespace-pre-wrap">{dateTooltip}</span> : ""}>
      <Pill {...pillProps} />
    </FloatingTooltip>
  );
};
