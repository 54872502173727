import { FC } from "react";

import { BulkActionsRow } from "@libs/components/UI/GridTableComponents";
import { MessagePatientsButton } from "components/Communications/MessagePatients/MessagePatientsButton";

interface Props {
  selectedCount: number;
  total: number;
  onRequestMessagePatients: Func;
}

export const ScheduleAsapBulkActionsRow: FC<Props> = ({ selectedCount, total, onRequestMessagePatients }) => {
  const bulkActionDisabled = total === 0 || selectedCount === 0;

  return (
    <BulkActionsRow
      contentClassName="flex items-center gap-x-5"
      actions={
        <div className="pr-1">
          <MessagePatientsButton
            onRequestMessagePatients={onRequestMessagePatients}
            disabled={bulkActionDisabled}
          />
        </div>
      }
      selectedCount={selectedCount}
      total={total}
      singular="appointment"
      plural="appointments"
    />
  );
};
