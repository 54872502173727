import { FC } from "react";

import { MessageCampaignSummaryVO } from "@libs/api/generated-api";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";

import { ReactComponent as SendIcon } from "@libs/assets/icons/send.svg";
import { ReactComponent as OpenEnvelopeIcon } from "@libs/assets/icons/open-envelope.svg";
import { ReactComponent as SelectIcon } from "@libs/assets/icons/select.svg";
import { ReactComponent as UnsubscribeIcon } from "@libs/assets/icons/unsubscribe.svg";

import { formatNumberWithCommas } from "@libs/utils/formatNumber";

const cxStyles = {
  tileContainer: `
    flex-1
    flex
    flex-col
    justify-between
    p-3
    rounded
    border
    border-slate-200
    h-[5.75rem]
    text-xs
  `,
  tileIcon: "w-5 h-5 text-slate-700",
};

interface Props {
  summary: MessageCampaignSummaryVO;
}

export const CampaignResults: FC<Props> = ({ summary }) => {
  return (
    <div className="flex flex-col gap-y-2" data-testid="campaign-results">
      <div className="flex items-center gap-x-3">
        <FloatingTooltip
          content="Number of patients who received this email. As email campaigns take time to send this will update over time."
          theme="MEDIUM"
          placement="bottom"
        >
          <div className={cxStyles.tileContainer}>
            <SendIcon className={cxStyles.tileIcon} />
            <span className="text-slate-700">Recipients</span>
            <span className="font-sansSemiBold">{formatNumberWithCommas(summary.sentMessageCount)}</span>
          </div>
        </FloatingTooltip>

        <FloatingTooltip
          content="Percentage of unique recipients who opened the email."
          theme="MEDIUM"
          placement="bottom"
        >
          <div className={cxStyles.tileContainer}>
            <OpenEnvelopeIcon className={cxStyles.tileIcon} />
            <span className="text-slate-700">Open Rate</span>
            <span className="font-sansSemiBold">{summary.openRate}%</span>
          </div>
        </FloatingTooltip>

        <FloatingTooltip
          content="Percentage of unique recipients who clicked a link in the email."
          theme="MEDIUM"
          placement="bottom"
        >
          <div className={cxStyles.tileContainer}>
            <SelectIcon className={cxStyles.tileIcon} />
            <span className="text-slate-700">Click Through</span>
            <span className="font-sansSemiBold">{summary.clickRate}%</span>
          </div>
        </FloatingTooltip>

        <FloatingTooltip
          content="Percentage of recipients who marked the email as spam."
          theme="MEDIUM"
          placement="bottom"
        >
          <div className={cxStyles.tileContainer}>
            <UnsubscribeIcon className={cxStyles.tileIcon} />
            <span className="text-slate-700">Spam Rate</span>
            <span className="font-sansSemiBold">{summary.complaintRate}%</span>
          </div>
        </FloatingTooltip>
      </div>

      {/* TODO: Link to report when available */}
      {/* <div className="flex justify-end">
        <ButtonInternalLink className="flex items-center gap-x-1" to="" theme="link">
          <OpenProfileIcon className="w-5 h-5" />
          Full Report
        </ButtonInternalLink>
      </div> */}
    </div>
  );
};
