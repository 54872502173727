import { useEffect, useMemo, useRef } from "react";
import { YEAR_IN_MS } from "@libs/utils/date";
import { useObjectState } from "@libs/hooks/useObjectState";
import { wrapStorage } from "@libs/storage/wrapStorage";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { useAccount } from "@libs/contexts/AccountContext";
import { StorageNamespaces } from "storage/namespaces";
import { AppointmentGrouping } from "utils/routing/scheduling";

const version = "v1";

export type ScheduleRoomOrder = "name" | "settings";

export type SchedulingConfig = {
  groupAppointmentsBy: AppointmentGrouping;
  hideCanceled: boolean;
  hipaa: boolean;
  orderRoomsBy: ScheduleRoomOrder;
  hiddenProviderIds: number[];
  hiddenRoomIds: number[];
};

const getSchedulingStorage = (practiceId: number, storage: Storage) => {
  const schedulingConfig = wrapStorage<Partial<SchedulingConfig>, StorageNamespaces>(
    StorageNamespaces.scheduling,
    storage,
    {
      practiceId,
    }
  );

  const getSchedulingConfig = (overrides?: Partial<SchedulingConfig>) => {
    const stored = schedulingConfig.get("schedulingConfig", version);

    if (overrides) {
      const merged = { ...stored, ...overrides };

      return schedulingConfig.set("schedulingConfig", merged, {
        version,
        expires: YEAR_IN_MS,
      });
    }

    return stored;
  };

  const setSchedulingConfig = (config: Partial<SchedulingConfig>) => {
    schedulingConfig.set("schedulingConfig", config, {
      version,
      expires: YEAR_IN_MS,
    });
  };

  return {
    getSchedulingConfig,
    setSchedulingConfig,
  };
};

const defaults: SchedulingConfig = {
  groupAppointmentsBy: "room",
  hideCanceled: true,
  hipaa: false,
  orderRoomsBy: "settings",
  hiddenProviderIds: [],
  hiddenRoomIds: [],
};

export const useSchedulingStorage = (overrides?: Partial<SchedulingConfig>) => {
  const { localStorage } = useStorageContext();
  const { practiceId } = useAccount();
  const storage = useMemo(() => getSchedulingStorage(practiceId, localStorage), [practiceId, localStorage]);

  const [scheduleConfig, updateScheduleConfig] = useObjectState(
    () => storage.getSchedulingConfig(overrides) ?? {}
  );
  const ref = useRef(scheduleConfig);

  useEffect(() => {
    // avoid initial set
    if (ref.current !== scheduleConfig) {
      storage.setSchedulingConfig(scheduleConfig);
    }
  }, [scheduleConfig, storage]);

  const config = useMemo(() => ({ ...defaults, ...scheduleConfig }), [scheduleConfig]);

  return [config, updateScheduleConfig] as const;
};
