/* eslint-disable @typescript-eslint/naming-convention */
import designConfig from "@libs/design.config";
import { cx } from "@libs/utils/cx";
import { Select, SelectProps } from "@libs/components/UI/Select";
import { createSelectStyles } from "@libs/components/UI/selectStyles";

export const getFormFieldStyles = <
  V extends SelectOptionValue,
  T extends SelectOption<V>,
  IsMulti extends boolean,
>() => {
  return createSelectStyles<V, T, IsMulti>({
    menu: () => ({
      backgroundColor: designConfig.colors.greyMedium,
      borderRadius: 5,
      fontSize: ".8rem",
    }),
    option: () => ({
      color: designConfig.colors.white,
      backgroundColor: designConfig.colors.greyMedium,
      "&:hover": {
        backgroundColor: designConfig.colors.greyLight,
      },
      "&:active": {
        backgroundColor: designConfig.colors.greyDark,
      },
    }),
    dropdownIndicator: () => ({
      fill: designConfig.colors.white,
    }),
  });
};
export const DarkRoomSelect = <V extends SelectOptionValue, T extends SelectOption<V>>(
  props: SelectProps<V, T>
) => {
  return (
    <Select
      {...props}
      className={cx("bg-black border border-white rounded-md py-2 px-3", props.className)}
      styles={getFormFieldStyles<V, T, boolean>()}
    />
  );
};
