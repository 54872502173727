import React from "react";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { noop } from "@libs/utils/noop";
import { Spinner } from "@libs/components/UI/Spinner";

type VideoPreviewProps = {
  stream?: MediaStream;
  className?: string;
};

export const CameraVideoPreview: React.FC<VideoPreviewProps> = React.memo(({ stream, className }) => {
  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const [videoDimensions, setVideoDimensions] = React.useState({ width: 0, height: 0 });
  const hasRef = useBoolean(false);

  React.useEffect(() => {
    if (hasRef.isOn && videoRef.current) {
      const onLoadMetadata = () => {
        // retrieve dimensions
        videoRef.current?.removeEventListener("loadedmetadata", onLoadMetadata);
        setVideoDimensions({
          width: videoRef.current?.videoWidth ?? 0,
          height: videoRef.current?.videoHeight ?? 0,
        });
      };

      videoRef.current.addEventListener("loadedmetadata", onLoadMetadata, false);

      return () => {
        videoRef.current?.removeEventListener("loadedmetadata", onLoadMetadata);
      };
    }

    return noop;
  }, [hasRef.isOn]);

  if (stream) {
    return (
      <div className={cx("flex flex-col w-full h-full min-h-0", className)}>
        <div
          className="max-h-full m-auto"
          style={{
            aspectRatio: `${videoRef.current?.videoWidth ?? videoDimensions.width} / ${
              videoRef.current?.videoHeight ?? videoDimensions.height
            }`,
          }}
        >
          <video
            playsInline
            autoPlay
            ref={(c) => {
              videoRef.current = c;

              if (c) {
                if (!hasRef.isOn) {
                  hasRef.on();
                }

                c.srcObject = stream;
              }
            }}
            id="videoElement"
          />
        </div>
      </div>
    );
  }

  return (
    <div
      className={`
        absolute
        bottom-0
        left-0
        w-full
        h-full
        flex
        items-center
        justify-center
      `}
    >
      <Spinner size="sm" variant="primary" animation="border" />
    </div>
  );
});
