import { FC, useMemo, useState } from "react";
import { LabVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as ArchiveIcon } from "@libs/assets/icons/archive.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { Button } from "@libs/components/UI/Button";
import { useAccount } from "@libs/contexts/AccountContext";
import { MenuOptions, createMenuOption } from "@libs/components/UI/MenuOptions";
import { Panel } from "@libs/components/UI/Panel";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { EmptyContent } from "@libs/components/UI/EmptyContent";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { LabsTable } from "components/Settings/LabCases/LabsTable";
import { getLabs } from "api/lab/queries";
import { LabFlyover } from "components/LabCases/LabFlyover";
import { useItemModal } from "hooks/useItemModal";
import { archiveLab, unarchiveLab } from "api/lab/mutations";
import { handleError } from "utils/handleError";
import { PanelTitle } from "components/Settings/LabCases/PanelTitle";

import tabletUrl from "assets/images/tablet.svg";

export const LabsSettingsRoute: FC = () => {
  const flyover = useItemModal<LabVO["uuid"] | undefined>(null);
  const { practiceId } = useAccount();
  const [showArchived, setShowArchived] = useState<boolean>(false);
  const [labsQuery] = useApiQueries([getLabs({ args: { practiceId, includeArchived: true } })]);
  const archiveConfirmation = useItemModal<LabVO["uuid"]>(null);
  const [archiveLabMutation, unarchiveLabMutation] = useApiMutations([archiveLab, unarchiveLab]);

  const hasArchivedLabs = useMemo(() => {
    return labsQuery.data?.some((lab) => lab.isArchived) ?? false;
  }, [labsQuery.data]);

  const labs = useMemo(() => {
    return labsQuery.data?.filter((lab) => (showArchived ? true : !lab.isArchived)) ?? [];
  }, [labsQuery.data, showArchived]);

  const handleArchive = (labUuid: LabVO["uuid"]) => {
    archiveLabMutation.mutate(
      { practiceId, labUuid },
      { onError: handleError, onSuccess: archiveConfirmation.close }
    );
  };

  const handleUnarchive = (labUuid: LabVO["uuid"]) => {
    unarchiveLabMutation.mutate({ practiceId, labUuid }, { onError: handleError });
  };

  return (
    <Panel
      className="h-full"
      contentClassName="overflow-y-auto"
      includePadding={false}
      title={<PanelTitle onAdd={() => flyover.open(undefined)}>Labs</PanelTitle>}
      actions={
        <ArchiveMenu
          hasArchivedLabs={hasArchivedLabs}
          showArchived={showArchived}
          toggleShowArchived={() => setShowArchived((prev) => !prev)}
        />
      }
    >
      <QueryResult queries={[labsQuery]}>
        {labs.length === 0 ? (
          <EmptyContent
            text="Add a lab and use it for your Lab Cases."
            alt="No labs."
            src={tabletUrl}
            size="md"
          >
            <Button className="min-w-button" onClick={() => flyover.open(undefined)}>
              Add Lab
            </Button>
          </EmptyContent>
        ) : (
          <LabsTable
            labs={labs}
            onEdit={flyover.open}
            onArchive={archiveConfirmation.open}
            onUnarchive={handleUnarchive}
          />
        )}
      </QueryResult>
      {flyover.isOpen && <LabFlyover labUuid={flyover.item} onSave={flyover.close} onClose={flyover.close} />}
      {archiveConfirmation.isOpen && (
        <ConfirmationModal
          primaryText="Are you sure you want to archive this Lab?"
          secondaryText="It won’t be available for any new lab orders but will remain attached to any existing orders."
          onCancel={archiveConfirmation.close}
          onConfirm={() => {
            handleArchive(archiveConfirmation.item);
          }}
          isConfirming={archiveLabMutation.isLoading}
        />
      )}
    </Panel>
  );
};

const ArchiveMenu: FC<{ hasArchivedLabs: boolean; showArchived: boolean; toggleShowArchived: Func }> = ({
  hasArchivedLabs,
  showArchived,
  toggleShowArchived,
}) => {
  const menu = useBoolean(false);

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      onRequestClose={menu.off}
      menuContent={
        <MenuOptions
          options={[
            createMenuOption({
              label: showArchived ? "Hide Archived" : "Show Archived",
              value: "show-archived",
              SvgIcon: ArchiveIcon,
              disabled: !hasArchivedLabs && !showArchived,
            }),
          ]}
          onOptionClick={(_option) => {
            toggleShowArchived();
            menu.off();
          }}
        />
      }
    >
      {(props) => <ButtonIcon {...props} SvgIcon={MenuIcon} size="lg" theme="primary" />}
    </ButtonMenu>
  );
};
