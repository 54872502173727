import { FC, Fragment, ReactNode, useCallback, useEffect, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useNavigate } from "react-router-dom";
import { useQueryClient } from "@tanstack/react-query";
import {
  EditMultiInvoicePaymentRequest,
  FamilyMemberResponse,
  FamilyMemberVO,
  InvoiceVO,
  MultiInvoicePaymentAllocationVO,
  MultiInvoicePaymentVO,
} from "@libs/api/generated-api";
import { centsToDollars, dollarsToCents, formatCurrency } from "@libs/utils/currency";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { getQueryKey } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { SEARCH_DEBOUNCE_DELAY_MS } from "@libs/utils/constants";
import { replaceCachedItemWithUpdatedItem } from "@libs/utils/queryCache";
import { Button } from "@libs/components/UI/Button";
import { useAccount } from "@libs/contexts/AccountContext";
import { fromUnixTime, getUnixTime } from "date-fns";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { isDifferentValue } from "components/PatientProfile/Billing/Payment/utils";
import { PaymentLayout } from "components/PatientProfile/Billing/PaymentLayout";
import { Text } from "components/PatientProfile/Billing/Ledger/Text";
import { usePathParams } from "hooks/usePathParams";
import { editMultiInvoicePayment } from "api/billing/mutations";
import { getMultiInvoicePayment, getWallets } from "api/billing/queries";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { Col, Row } from "components/PatientProfile/Billing/Ledger/LedgerComponents";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import { InvoiceDescription } from "components/PatientProfile/Billing/Ledger/InvoiceDescription";
import { Note, PaymentAmount, SelectPaymentDate } from "components/PatientProfile/Billing/FormComponents";
import { FormFieldCurrencyInputHandler } from "components/UI/FormFieldCurrencyInput";
import {
  getPaymentMethodToLabel,
  isPaymentEditableChecker,
} from "components/PatientProfile/Billing/billingUtils";
import { handleError } from "utils/handleError";
import { useNotificationContext } from "contexts/NotificationsContext";
import { usePaymentPolling } from "components/PatientProfile/Billing/usePaymentPolling";
import { useFamilyInvoices } from "components/PatientProfile/Billing/useFamilyInvoices";
import { FamilyMemberHeader } from "components/PatientProfile/Billing/FamilyMemberHeader";
import { RoleGuardClick } from "components/Main/RoleGuard";
import { RoleConditionHandler } from "components/Roles/roleUtils";
import { getFamilyMembersV2Query } from "api/patients/queries";
import {
  makePaymentCorrectionRequest,
  useEditPaymentForm,
} from "components/PatientProfile/Billing/Payment/useEditPaymentForm";
import { useEditPaymentFormValidation } from "components/PatientProfile/Billing/Payment/useEditPaymentFormValidation";

const DUMMY_NOTE = "dummy note when {commit: false}";

export const LoadedEditPaymentRoute: FC<{
  originalMultiInvoicePayment: MultiInvoicePaymentVO;
  patientId: number;
  paymentUuid: string;
  familyMembersData: FamilyMemberResponse;
  // eslint-disable-next-line complexity
}> = ({ originalMultiInvoicePayment, patientId, paymentUuid, familyMembersData }) => {
  const { practiceId } = useAccount();
  const {
    query: { from },
  } = useQueryParams("editPayment");
  const navigate = useNavigate();

  const [paymentUuidToPoll, setPaymentUuidToPoll] = useState<string>();
  const [editedMultiInvoicePayment, setEditedMultiInvoicePayment] = useState<MultiInvoicePaymentVO>();
  const {
    familyMembers,
    paymentAmount,
    setPaymentAmount,
    dollarStringValue,
    setDollarStringValue,
    paymentDate,
    setPaymentDate,
    note,
    setNote,
  } = useEditPaymentForm({
    paymentCreatedDate: fromUnixTime(originalMultiInvoicePayment.payment.paymentCreatedAt),
    familyMembersData,
    initialPaymentAmount: originalMultiInvoicePayment.payment.currencyAmount.amount,
    initialNote: originalMultiInvoicePayment.payment.note,
  });

  const [{ mutate, isLoading, variables: requestData }] = useApiMutations([editMultiInvoicePayment]);

  const maxAmountDue = editedMultiInvoicePayment?.maxPaymentAmount ?? 0;
  const isRefund = maxAmountDue < 0;

  const validation = useEditPaymentFormValidation({
    maxAmountDue,
    paymentAmount,
    paymentDate,
    paymentNote: note,
  });

  const notification = useNotificationContext();
  const { isPolling } = usePaymentPolling({
    practiceId,
    paymentUuid: paymentUuidToPoll,
    onSuccess: () => {
      notification.handleSuccess(`${isRefund ? "Refund" : "Payment"} update was successful.`);
      navigate(
        paths.viewPayment(
          {
            patientId,
            paymentUuid,
          },
          { from }
        )
      );
    },
    onError: (paymentGatewayError) => {
      const errorMessageParts = [`Failed to save ${isRefund ? "refund" : "payment"}.`];

      if (paymentGatewayError) {
        errorMessageParts.push(paymentGatewayError);
      }

      notification.handleError(errorMessageParts.join(" "), { autoClose: false });
    },
  });

  const isCommitting = (isLoading && (requestData?.data.commit ?? false)) || isPolling;
  const queryClient = useQueryClient();

  const handleSubmit = useCallback(
    (multiInvoicePaymentRequest: EditMultiInvoicePaymentRequest) => {
      const result = validation.validate();

      // Regardless of whether the payment is being committed, we need to
      // validate the payment amount.
      if (!(result.paymentAmount.$isValid ?? false)) {
        return;
      }

      if (multiInvoicePaymentRequest.commit) {
        if (!result.$isValid) {
          return;
        }
      } else {
        validation.reset();
      }

      mutate(
        {
          practiceId,
          patientId,
          paymentUuid,
          data: multiInvoicePaymentRequest,
        },
        {
          onSuccess: ({ data }) => {
            setEditedMultiInvoicePayment(data.data);

            // If we're committing the payment, set `paymentUuidToPoll` so we can poll for the
            // payment status and check for errors before redirecting to the payment receipt page.
            if (multiInvoicePaymentRequest.commit) {
              setPaymentUuidToPoll(data.data.payment.uuid);
            }

            replaceCachedItemWithUpdatedItem<MultiInvoicePaymentVO>(
              queryClient,
              {
                queryKey: [
                  getQueryKey("practices", "getMultiInvoicePayment"),
                  { practiceId, multiInvoicePaymentUuid: data.data.payment.uuid },
                ],
              },
              data.data
            );
          },
          onError: handleError,
        }
      );
    },
    [mutate, patientId, paymentUuid, practiceId, queryClient, validation]
  );

  const debouncedSubmit = useDebouncedCallback(handleSubmit, SEARCH_DEBOUNCE_DELAY_MS);

  // Generate the payment correction request using the original payment's amount and date to get
  // each of the invoices' allocations. It should only run once after the original payment amount is
  // fetched.
  useEffect(() => {
    mutate(
      {
        practiceId,
        patientId,
        paymentUuid,
        data: {
          commit: false,
          payment: makePaymentCorrectionRequest({
            paymentAmount: originalMultiInvoicePayment.payment.currencyAmount.amount,
            paymentDate: originalMultiInvoicePayment.payment.paymentCreatedAt,
            paymentNote: DUMMY_NOTE,
          }),
        },
      },
      {
        onSuccess: ({ data }) => {
          setEditedMultiInvoicePayment(data.data);
        },
        onError: (e) => {
          handleError(e);
        },
      }
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const paymentEditableChecker: RoleConditionHandler = useCallback(
    (conditions) => isPaymentEditableChecker(originalMultiInvoicePayment.payment, conditions),
    [originalMultiInvoicePayment.payment]
  );

  return (
    <PaymentLayout
      isLoadingPaymentForm={false}
      title={
        <Text size="md" bold>
          {isRefund ? "Edit Refund" : "Edit Payment"}
        </Text>
      }
      closeLink={from ?? paths.patientBilling({ patientId })}
      isLoadingInvoiceList={isLoading && !isCommitting}
      invoiceList={
        <div className="divide-y divide-slate-300">
          <Header />
          {editedMultiInvoicePayment?.invoices && editedMultiInvoicePayment.allocations && (
            <Invoices
              invoices={editedMultiInvoicePayment.invoices}
              allocations={editedMultiInvoicePayment.allocations}
              familyMembers={familyMembers}
            />
          )}
          <Row className="px-6 py-3">
            <Columns
              date={undefined}
              details={
                <Col width="full" justify="right">
                  <Text size="sm" bold>
                    Total {isRefund ? "Refund" : "Payment"}
                  </Text>
                </Col>
              }
              payment={
                <Text size="lg" bold>
                  {formatCurrency(editedMultiInvoicePayment?.payment.currencyAmount.amount ?? 0)}
                </Text>
              }
              remaining={undefined}
            />
          </Row>
        </div>
      }
      paymentForm={
        <div className="flex flex-col gap-y-6">
          <Text bold size="sm">
            {isRefund ? "Refund" : "Payment"} Details
          </Text>

          <div className="grid grid-cols-2 gap-6">
            <div className="flex flex-col gap-y-4">
              <Text bold>Original Amount {isRefund ? "Refunded" : "Collected"}</Text>
              <Text>
                {formatCurrency(Math.abs(originalMultiInvoicePayment.payment.currencyAmount.amount))}
              </Text>
            </div>
            <div className="flex flex-col gap-y-4">
              <Text bold>Payment Method</Text>
              <Text>{getPaymentMethodToLabel(originalMultiInvoicePayment.payment)}</Text>
            </div>
            <div className="col-span-full">
              <EditPaymentForm
                paymentAmount={paymentAmount == null ? paymentAmount : Math.abs(paymentAmount)}
                dollarStringValue={dollarStringValue}
                paymentDate={paymentDate}
                note={note}
                errors={{
                  paymentAmount: validation.result.paymentAmount.$error,
                  paymentDate: validation.result.paymentDate.$error,
                  paymentNote: validation.result.paymentNote.$error,
                }}
                onPaymentAmountChange={(cents) => {
                  const newPaymentAmount = isRefund ? -cents : cents;

                  setPaymentAmount(newPaymentAmount);
                  debouncedSubmit({
                    commit: false,
                    payment: makePaymentCorrectionRequest({
                      paymentAmount: newPaymentAmount,
                      paymentDate: getUnixTime(paymentDate),
                      paymentNote: note ?? DUMMY_NOTE,
                    }),
                  });
                }}
                onDollarStringValueChange={setDollarStringValue}
                onPaymentDateChange={setPaymentDate}
                onNoteChange={setNote}
              />
            </div>
          </div>
        </div>
      }
      paymentButtons={
        <RoleGuardClick domain="BILLING" action="EDIT_PAYMENT" onRoleCondition={paymentEditableChecker}>
          <Button
            type="submit"
            className="min-w-button"
            disabled={isLoading || isCommitting || !(validation.result.$isValid ?? true)}
          >
            Save
          </Button>
        </RoleGuardClick>
      }
      onSubmit={(e) => {
        e.preventDefault();
        handleSubmit({
          commit: true,
          payment: makePaymentCorrectionRequest({
            paymentAmount,
            paymentDate: getUnixTime(paymentDate),
            paymentNote: note ?? "",
          }),
        });
      }}
    />
  );
};

export const Header: FC = () => {
  return (
    <Row className="px-6 py-4">
      <Columns
        date={<Text bold>Date</Text>}
        details={<Text bold>Details</Text>}
        payment={<Text bold>Payment</Text>}
        remaining={<Text bold>Remaining</Text>}
      />
    </Row>
  );
};

const Columns: FC<{
  date: ReactNode;
  details: ReactNode;
  payment: ReactNode;
  remaining: ReactNode;
}> = ({ date, details, payment, remaining }) => {
  return (
    <>
      <Col justify="left" width="lg">
        {date}
      </Col>
      <Col justify="left" width="full">
        {details}
      </Col>
      <Col justify="right" width="lg">
        {payment}
      </Col>
      <Col justify="right" width="lg">
        {remaining}
      </Col>
    </>
  );
};

const Invoices: FC<{
  invoices: InvoiceVO[];
  allocations: MultiInvoicePaymentAllocationVO[];
  familyMembers?: FamilyMemberVO[];
}> = ({ invoices, allocations, familyMembers }) => {
  const { familyMembersById, familyMembersToRender } = useFamilyInvoices({
    invoices,
    allocations,
    familyMembers,
  });

  return (
    <>
      {familyMembersToRender.map((fm) => {
        const familyMemberInvoices = familyMembersById[fm.memberPatientId];

        return (
          <Fragment key={fm.memberPatientId}>
            <FamilyMemberHeader
              className="pl-6"
              name={fm.name.shortDisplayName}
              relationship={fm.relation ? sentenceCaseConstant(fm.relation) : "Undefined relationship"}
            />
            {familyMemberInvoices?.invoices.map(({ invoice, allocation }) => (
              <Invoice key={invoice.uuid} invoice={invoice} allocation={allocation} />
            ))}
          </Fragment>
        );
      })}
    </>
  );
};

const Invoice: FC<{ invoice: InvoiceVO; allocation: MultiInvoicePaymentAllocationVO }> = ({
  invoice,
  allocation,
}) => {
  return (
    <div className="px-6 py-3 fl">
      <Row>
        <Columns
          date={
            <Text bold>
              <DateWithTooltip date={invoice.createdAt} dateAsSeconds format="P" />
            </Text>
          }
          details={<InvoiceDescription invoiceNumber={invoice.invoiceNumber} />}
          payment={<Text bold>{formatCurrency(allocation.totalAmountDue)}</Text>}
          remaining={<Text bold>{formatCurrency(allocation.totalAmountRemaining)}</Text>}
        />
      </Row>
    </div>
  );
};

const EditPaymentForm: FC<{
  /**
   * A non-negative payment amount. For refunds, have the caller change the sign
   * of the payment amount when handling the `onPaymentAmountChange` event.
   */
  paymentAmount: number | undefined;
  dollarStringValue: string | undefined;
  paymentDate: Date;
  note: string | undefined;
  errors: {
    paymentAmount?: string;
    paymentDate?: string;
    paymentNote?: string;
  };
  disabled?: boolean;
  onPaymentAmountChange: (cents: number) => void;
  onDollarStringValueChange: (dollars: string | undefined) => void;
  onPaymentDateChange: (paymentDate: Date) => void;
  onNoteChange: (note: string | undefined) => void;
}> = ({
  paymentAmount,
  dollarStringValue,
  paymentDate,
  note,
  errors,
  disabled,
  onPaymentAmountChange,
  onDollarStringValueChange,
  onPaymentDateChange,
  onNoteChange,
}) => {
  const updateDollarAmount: FormFieldCurrencyInputHandler = useCallback(
    (dollars) => {
      onDollarStringValueChange(dollars);

      // Emit a change event only if the dollar value is different from the
      // payment amount (e.g. "100" vs "100." vs "100.00").
      isDifferentValue(dollars, paymentAmount) && onPaymentAmountChange(dollarsToCents(Number(dollars ?? 0)));
    },
    [onDollarStringValueChange, onPaymentAmountChange, paymentAmount]
  );

  const paymentAmountValue = dollarStringValue ?? (paymentAmount ? centsToDollars(paymentAmount) : undefined);

  return (
    <div className="flex flex-col gap-y-6">
      <div className="grid grid-cols-2 gap-6">
        <PaymentAmount
          value={paymentAmountValue}
          onChange={updateDollarAmount}
          disabled={disabled}
          error={errors.paymentAmount}
        />
        <SelectPaymentDate
          layout="labelOut"
          selected={paymentDate}
          onChange={onPaymentDateChange}
          error={errors.paymentDate}
          required={!disabled}
          disabled={disabled}
        />
        <div className="col-span-2">
          <Note
            value={note}
            onChange={(e) => onNoteChange(e.target.value)}
            error={errors.paymentNote}
            disabled={disabled}
            required
          />
        </div>
      </div>
    </div>
  );
};

export const EditPaymentRoute: React.FC = () => {
  const { practiceId } = useAccount();
  const { patientId, paymentUuid } = usePathParams("editPayment");

  const [originalPaymentQuery, familyMembersQuery, patientWalletsQuery] = useApiQueries([
    getMultiInvoicePayment({ args: { patientId, practiceId, multiInvoicePaymentUuid: paymentUuid } }),
    getFamilyMembersV2Query({ args: { patientId, practiceId } }),
    getWallets({ args: { patientId, practiceId, includeFamily: true, withEmptyBalances: true } }),
  ]);

  return (
    <QueryResult queries={[originalPaymentQuery]}>
      {originalPaymentQuery.data && familyMembersQuery.data && patientWalletsQuery.data && (
        <LoadedEditPaymentRoute
          patientId={patientId}
          paymentUuid={paymentUuid}
          originalMultiInvoicePayment={originalPaymentQuery.data}
          familyMembersData={familyMembersQuery.data}
        />
      )}
    </QueryResult>
  );
};
