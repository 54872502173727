import { FC, useMemo } from "react";
import { format, parseISO } from "date-fns";
import { PatientProcedureVO } from "@libs/api/generated-api";

type PreAuthStatus = NonNullable<PatientProcedureVO["preAuthStatus"]>;
type PreAuthStatusKey = Exclude<PreAuthStatus, "APPROVED_RESENT" | "DENIED_RESENT">;
interface Props {
  status?: PreAuthStatus;
  submittedDate?: PatientProcedureVO["preAuthSubmittedDate"];
  updatedDate?: PatientProcedureVO["preAuthUpdatedDate"];
  className?: string;
}

const PreAuthStyles: Record<PreAuthStatusKey, string> = {
  SENT: "text-orange",
  RESENT: "text-magenta",
  APPROVED: "text-green",
  DENIED: "text-red",
};

export const PreAuthLabelMap: Record<PreAuthStatusKey, string> = {
  SENT: "Sent",
  RESENT: "Resent",
  APPROVED: "Approved",
  DENIED: "Denied",
};

const formatOnDate = (date?: string): string => {
  return date ? ` on ${format(parseISO(date), "MM/dd/yyyy")}` : "";
};

export const PreAuthInfo: FC<Props> = ({ status, submittedDate, updatedDate, className }) => {
  const getPreAuthLabel = useMemo(() => {
    // We only want the prefix of the status for mapping style and label
    const [statusKey] = (status ?? "").split("_") as [PreAuthStatusKey];
    const statusLabel = <span className={PreAuthStyles[statusKey]}>{PreAuthLabelMap[statusKey]}</span>;

    // preAuthSubmittedDate is based on when pre-auth is either sent or resent
    const onSentDate = formatOnDate(submittedDate);
    // preAuthUpdatedDate is based on changes to pre-auth status, number, or amounts
    const onProcessDate = formatOnDate(updatedDate);

    switch (status) {
      case "SENT":
      case "RESENT": {
        return (
          <>
            {statusLabel}
            {onSentDate}
          </>
        );
      }
      case "APPROVED_RESENT":
      case "DENIED_RESENT": {
        return (
          <>
            {statusLabel}
            {onProcessDate} & <span className={PreAuthStyles["RESENT"]}>Resent</span>
            {onSentDate}
          </>
        );
      }
      case "APPROVED":
      case "DENIED": {
        // There may be cases where a practice migrates to Archy with prior pre-auth letters that
        // have not been submitted through our system, resulting in preAuthSubmittedDate as null
        return (
          <>
            {statusLabel}
            {onProcessDate}
            {submittedDate && (
              <>
                {" & "}
                <span className={PreAuthStyles["SENT"]}>Sent</span>
                {onSentDate}
              </>
            )}
          </>
        );
      }
      default: {
        return "-";
      }
    }
  }, [status, submittedDate, updatedDate]);

  return <span className={className}>{getPreAuthLabel}</span>;
};
