import { FC, FormEvent, useState, useCallback } from "react";

import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Modal } from "@libs/components/UI/Modal";
import { ModalForm, ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";

export const SaveFiltersModal: FC<{
  isSavingFilters: boolean;
  onSaveFilters: (name: string) => void;
  onClose: Func;
}> = ({ isSavingFilters, onSaveFilters, onClose }) => {
  const [name, setName] = useState("");

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      onSaveFilters(name);
    },
    [onSaveFilters, name]
  );

  return (
    <Modal title="Save Filters" onClose={onClose} centerVertically={false} size="3xs">
      <ModalForm onSubmit={handleSubmit}>
        <ModalContent padding="lg">
          <FormFieldInput
            aria-label="Saved Filters Name"
            label="Name"
            value={name}
            onChange={(e) => setName(e.target.value)}
            required
          />
        </ModalContent>
        <ModalFooter>
          <Button className="min-w-button" onClick={onClose} theme="secondary">
            Cancel
          </Button>
          <AsyncButton
            aria-label="Saved Filters Button"
            className="min-w-button"
            isLoading={isSavingFilters}
            disabled={!name.trim()}
            type="submit"
          >
            Save
          </AsyncButton>
        </ModalFooter>
      </ModalForm>
    </Modal>
  );
};
