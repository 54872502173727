import React from "react";
import { getLocalDate } from "@libs/utils/date";
import { getBoundsForResolution } from "components/Dashboard/utils/timeSeriesDates";
import { TimeSeriesResolutionOption } from "utils/routing/dashboard";

export const useTimeSegmentForResolution = ({
  windowStart,
  customDayRange,
  resolution,
  includeProjectedDates = false,
}: {
  windowStart: string;
  resolution: TimeSeriesResolutionOption;
  includeProjectedDates?: boolean;
  customDayRange?: {
    startDate: Date;
    endDate: Date;
  };
}) => {
  return React.useMemo(() => {
    if (customDayRange) {
      return {
        ...customDayRange,
      };
    }

    return getBoundsForResolution({
      startDate: getLocalDate(windowStart),
      resolution,
      includeProjectedDates,
    });
  }, [customDayRange, includeProjectedDates, resolution, windowStart]);
};
