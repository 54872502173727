import { FC, useCallback, useMemo } from "react";
import { ProviderVO } from "@libs/api/generated-api";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { mapSelectionsToOptions } from "@libs/utils/mapSelectOptions";
import { FormFieldMultiSelect } from "components/UI/FormFieldMultiSelect";

interface Props {
  savedProviders: ProviderVO[] | undefined;
  providers: ProviderVO[];
  providerIds: Set<number>;
  onChange: (providerIds: Set<number>) => void;
}

export const BlockSelectProviders: FC<Props> = ({ onChange, savedProviders, providerIds, providers }) => {
  const providerOptions = useMemo(() => {
    return [
      { label: "Select All", value: 0 },
      ...mapSelectionsToOptions(providers, savedProviders, (provider) => ({
        label: provider.name.shortDisplayName,
        value: provider.id,
      })),
    ];
  }, [providers, savedProviders]);

  const selectProviders = useCallback(
    (values: number[]) => {
      onChange(new Set(values.includes(0) ? providers.map(({ id }) => id) : values));
    },
    [providers, onChange]
  );

  return (
    <FormFieldMultiSelect
      label="Providers"
      closeMenuOnSelect={false}
      formatOptionLabel={(option) => {
        const { label, value } = option;

        return value === 0 ? (
          <div className="text-xs text-primaryTheme">Select All</div>
        ) : (
          <Checkbox readOnly checked={providerIds.has(value)} className="gap-0.5 pointer-events-none">
            <div>{label}</div>
          </Checkbox>
        );
      }}
      hideSelectedOptions={false}
      onItemsSelected={selectProviders}
      options={providerOptions}
      placeholder="None"
      value={[...providerIds]}
    />
  );
};
