import { useMemo } from "react";
import { FamilyMemberVO, InvoiceVO, MultiInvoicePaymentAllocationVO } from "@libs/api/generated-api";

export const useFamilyInvoices = ({
  invoices,
  allocations,
  familyMembers,
}: {
  invoices: InvoiceVO[];
  allocations: MultiInvoicePaymentAllocationVO[];
  familyMembers?: FamilyMemberVO[];
}) => {
  const invoicesWithAllocations = useMemo(() => {
    return invoices.map((invoice, i) => ({
      invoice,
      allocation: allocations[i],
    }));
  }, [invoices, allocations]);

  const familyMembersById = useMemo(() => {
    if (!familyMembers) {
      return {};
    }

    return familyMembers.reduce(
      (acc, fm) => {
        acc[fm.memberPatientId] = {
          familyMember: fm,
          invoices: invoicesWithAllocations.filter(
            (invoiceWithAllocation) => invoiceWithAllocation.invoice.payer.id === fm.memberPatientId
          ),
        };

        return acc;
      },
      {} as Record<
        number,
        | {
            familyMember: FamilyMemberVO;
            invoices: { invoice: InvoiceVO; allocation: MultiInvoicePaymentAllocationVO }[];
          }
        | undefined
      >
    );
  }, [familyMembers, invoicesWithAllocations]);

  const familyMembersToRender = useMemo(() => {
    if (!familyMembers) {
      return [];
    }

    // Show SELF first
    return familyMembers
      .filter((fm) => familyMembersById[fm.memberPatientId]?.invoices.length)
      .sort((a) => {
        if (a.relation === "SELF") {
          return -1;
        }

        return 0;
      });
  }, [familyMembers, familyMembersById]);

  return {
    familyMembersById,
    familyMembersToRender,
  };
};
