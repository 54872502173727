import React from "react";
import { noop } from "@libs/utils/noop";
import { MediaPermissionsStatus, MediaPermissionsState } from "utils/useMediaPermission";
import { usePromise } from "utils/usePromise";
import { requestCameraSources, subscribeToDeviceChanges } from "./camera";

/*
  useCameraSources will request a users videoinput devices on mount and subscribes
  to devicechange event so that when new devices are connected the device list will
  be updated
*/
export type CameraDevice = {
  deviceId: string;
  label: string;
};
export interface UseCameraSources {
  isRequestingSources: boolean;
  sourcesError: Error | null;
  sources: CameraDevice[] | null;
  clearSourcesError: Func;
}

export function useCameraSources(permissionState: MediaPermissionsState): UseCameraSources {
  const {
    isPending: isRequestingSources,
    call,
    result: sources,
    error: sourcesError,
    clearError: clearSourcesError,
  } = usePromise(requestCameraSources);

  React.useEffect(() => {
    if (permissionState.status === MediaPermissionsStatus.granted) {
      call();
    }

    return permissionState.status === MediaPermissionsStatus.granted
      ? subscribeToDeviceChanges(() => {
          call();
        })
      : noop;
  }, [call, permissionState]);

  return {
    isRequestingSources:
      isRequestingSources ||
      [MediaPermissionsStatus.undetermined, MediaPermissionsStatus.requesting].includes(
        permissionState.status
      ),
    sourcesError,
    sources,
    clearSourcesError,
  };
}
