import { useCallback, useState } from "react";
import { ProviderVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Button } from "@libs/components/UI/Button";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { Form } from "@libs/components/UI/Form";
import { filtersCxStyles } from "components/Claims/Claims/Filters";
import { SimpleAccordian } from "components/UI/SimpleAccordian";
import { ClaimsQuery, ClaimsQueryUpdates } from "utils/routing/claims";

interface Props {
  billingProviders: ProviderVO[] | undefined;
  onFiltersUpdate: (keyValues: ClaimsQueryUpdates) => void;
  onRequestClose: Func;
  queryStateApi: ClaimsQuery;
  treatingProviders: ProviderVO[] | undefined;
}

export const FilterMenuProviders: React.FC<Props> = ({
  billingProviders,
  onFiltersUpdate,
  onRequestClose,
  queryStateApi,
  treatingProviders,
}) => {
  const [selectedBillingProviderIds, setSelectedBillingProviderIds] = useState(
    new Set(queryStateApi.billingProviderIds)
  );
  const [selectedTreatingProviderIds, setSelectedTreatingProviderIds] = useState(
    new Set(queryStateApi.treatingProviderIds)
  );

  const handleApplyFilter = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const filtersToApply: ClaimsQueryUpdates = {
        billingProviderIds: undefined,
        treatingProviderIds: undefined,
      };

      if (selectedBillingProviderIds.size) {
        filtersToApply.billingProviderIds = [...selectedBillingProviderIds];
      }

      if (selectedTreatingProviderIds.size) {
        filtersToApply.treatingProviderIds = [...selectedTreatingProviderIds];
      }

      onFiltersUpdate(filtersToApply);
      onRequestClose();
    },
    [onFiltersUpdate, selectedBillingProviderIds, selectedTreatingProviderIds, onRequestClose]
  );

  const billingFilter = useBoolean(false);
  const treatingFilter = useBoolean(false);

  return (
    <Form className={filtersCxStyles.menuWrapper} onSubmit={handleApplyFilter}>
      <div className={filtersCxStyles.menuContent}>
        <SimpleAccordian
          buttonContent="Billing Provider"
          className="pb-2"
          id="status-filter"
          isOpen={billingFilter.isOn}
          onRequestClose={billingFilter.off}
          onRequestOpen={billingFilter.on}
        >
          <CheckboxList
            layout="vert"
            onChange={(newSet) => setSelectedBillingProviderIds(newSet)}
            options={getProviderOptions(billingProviders)}
            selectedValues={selectedBillingProviderIds}
          />
        </SimpleAccordian>
        <SimpleAccordian
          buttonContent="Treating Provider"
          className="py-2"
          id="status-filter"
          isOpen={treatingFilter.isOn}
          onRequestClose={treatingFilter.off}
          onRequestOpen={treatingFilter.on}
        >
          <CheckboxList
            layout="vert"
            onChange={(newSet) => setSelectedTreatingProviderIds(newSet)}
            options={getProviderOptions(treatingProviders)}
            selectedValues={selectedTreatingProviderIds}
          />
        </SimpleAccordian>
      </div>
      <div className="mt-2 px-4">
        <Button className="w-full" type="submit">
          Apply Filters
        </Button>
      </div>
    </Form>
  );
};

export const getProviderOptions = (practiceProviders: ProviderVO[] | undefined) => {
  if (!practiceProviders) {
    return [];
  }

  return practiceProviders.map((provider) => ({
    label: provider.name.fullDisplayName,
    value: provider.id,
  }));
};
