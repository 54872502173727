import { FC, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AutomationActionRequest, AutomationJourneyVO, EmailConfigVO } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { ReactComponent as PlusCircleIcon } from "@libs/assets/icons/plus-circle.svg";
import { ReactComponent as MessageIcon } from "@libs/assets/icons/messages.svg";
import { ReactComponent as SendIcon } from "@libs/assets/icons/send.svg";
import { ReactComponent as UserIcon } from "@libs/assets/icons/user.svg";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { formatNumberWithCommas } from "@libs/utils/formatNumber";
import { journeyNames, sortActionsByTiming } from "components/Communications/Automations/utils";
import { ActionsTable } from "components/Communications/Automations/ActionsTable";
import { ModalPage } from "components/UI/ModalPage";
import { paths } from "utils/routing/paths";
import { EmailSettingsBanner } from "components/Communications/EmailSettingsBanner";
import { IconCard } from "components/UI/IconCard";

export const AutomationPageContent: FC<{
  journey: AutomationJourneyVO;
  emailConfig: EmailConfigVO;
  from: string;
  onRequestDeleteAction: (actionUuid: string) => void;
  onToggleActionStatus: (actionUuid: string, data: AutomationActionRequest) => void;
}> = ({ journey, emailConfig, from, onRequestDeleteAction, onToggleActionStatus }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { journeyName, actionsSortedByTiming, sentCount, recipientCount } = useMemo(() => {
    const name = journeyNames[journey.type];

    const sortedActions = sortActionsByTiming(journey.actions);

    return {
      ...journey.actions.reduce(
        (acc, action) => ({
          sentCount: acc.sentCount + action.sentCount,
          recipientCount: acc.recipientCount + action.recipientCount,
        }),
        { sentCount: 0, recipientCount: 0 }
      ),

      journeyName: name,
      actionsSortedByTiming: sortedActions,
    };
  }, [journey]);

  return (
    <ModalPage closeLink={from} title={<div className="font-sansSemiBold">{journeyName}</div>}>
      <div
        className={`
          max-w-5xl
          h-full
          mx-auto
          p-6
          flex
          flex-col
          items-stretch
          gap-y-6
        `}
      >
        <EmailSettingsBanner emailConfig={emailConfig} />
        <div>
          <h2 className="font-sansSemiBold text-sm mb-1.5">Overview</h2>
          <div className="grid grid-cols-3 gap-x-6 items-center">
            <IconCard title="Actions" description={`${journey.actions.length}`} SvgIcon={MessageIcon} />

            <IconCard title="Total Sent" description={formatNumberWithCommas(sentCount)} SvgIcon={SendIcon} />

            <IconCard
              title="Recipients"
              description={formatNumberWithCommas(recipientCount)}
              SvgIcon={UserIcon}
            />
          </div>
        </div>

        <div className="flex-1 min-h-0 flex flex-col">
          <div className="flex items-center gap-x-1 mb-1.5">
            <h2 className="font-sansSemiBold text-sm">Messages</h2>
            <LinkIcon
              SvgIcon={PlusCircleIcon}
              tooltip={{ content: "Add Automation", theme: "SMALL" }}
              to={paths.createAutomationAction({ journeyType: journey.type })}
              theme="primary"
            />
          </div>
          <div className="flex-1 min-h-0">
            <ActionsTable
              actions={actionsSortedByTiming}
              onRequestEdit={(actionUuid) =>
                navigate(
                  paths.editAutomationAction(
                    { journeyType: journey.type, actionUuid },
                    { from: getFullUrl(location) }
                  )
                )
              }
              onRequestDelete={onRequestDeleteAction}
              onToggleActionStatus={onToggleActionStatus}
            />
          </div>
        </div>
      </div>
    </ModalPage>
  );
};
