import { useRef } from "react";
import { PatientToothConditionVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { noop } from "@libs/utils/noop";
import { Menu } from "@libs/components/UI/Menu";
import { ReactComponent as RightCaret } from "@libs/assets/icons/right-caret.svg";
import { ToothConditionSurfaceMenu } from "components/Charting/ToothConditionSurfaceMenu";
import { Surface, SurfaceSelection } from "components/Charting/toothSurfaces";

interface Props {
  onMouseEnter: (value: PatientToothConditionVO["condition"]) => void;
  onMouseLeave: Func;
  onToggleSurfaceCondition: (
    condition: PatientToothConditionVO["condition"],
    surfaces: SurfaceSelection
  ) => void;
  menuPortalTarget: Element | null;
  option: {
    value: PatientToothConditionVO["condition"];
    label: string;
  };
  isOpen: boolean;
  selections: Partial<Record<PatientToothConditionVO["condition"], Surface[]>>;
}

export const ToothConditionSurfaceOption: React.FC<Props> = ({
  option,
  selections,
  isOpen,
  menuPortalTarget,
  onMouseEnter,
  onMouseLeave,
  onToggleSurfaceCondition,
}) => {
  const triggerRef = useRef<HTMLButtonElement | null>(null);

  return (
    <div
      className="translate-x-0 h-4"
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex={0}
      onMouseEnter={() => onMouseEnter(option.value)}
      onMouseLeave={onMouseLeave}
    >
      <button
        ref={triggerRef}
        type="button"
        className={cx(
          "text-left flex pl-5 text-xs items-center w-full flex-nowrap",
          selections[option.value] ? "text-primaryTheme" : "",
          isOpen && "bg-greyLightest outline-greyLightest outline-4 outline"
        )}
      >
        {option.label} <RightCaret className="text-primaryTheme ml-auto h-3 w-3" />
      </button>
      {isOpen && (
        <Menu
          portalTarget={menuPortalTarget}
          closeOnOutsideClick={false}
          triggerRef={triggerRef}
          placement="right-start"
          theme="default"
          onRequestClose={noop}
        >
          <ToothConditionSurfaceMenu
            surfaces={selections[option.value] || []}
            onApply={(newSelection) => onToggleSurfaceCondition(option.value, newSelection)}
            className="p-4"
          />
        </Menu>
      )}
    </div>
  );
};
