import { MangoAuthorizationRequest } from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";

export const upsertMangoAuthorization = makeMutation({
  mutationKey: ["practices", "upsertMangoAuthorization"],
  formatParams: (args: { practiceId: number; data: MangoAuthorizationRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getPhoneExtensions"), { practiceId }]);
    },
  }),
});
