import React from "react";
import { ScaleBand, ScaleLinear } from "d3-scale";
import Skeleton from "react-loading-skeleton";
import { BarStackData } from "components/Dashboard/Charting/types";
import { DatapointTotalOptions, getDatapointTotal } from "components/Dashboard/utils/barStacks";

type Props<T extends string> = {
  barWidth: number;
  xScale: ScaleBand<string>;
  yScale: ScaleLinear<number, number, never>;
  yMax: number;
  margins: { top: number; right: number; bottom: number; left: number };
  data: BarStackData<T>[];
  randomize?: boolean;
  dataPointTotalOptions?: DatapointTotalOptions;
};

export const LoadingChart = <T extends string>({
  data,
  xScale,
  yScale,
  yMax,
  barWidth,
  margins,
  randomize,
  dataPointTotalOptions,
}: Props<T>): JSX.Element => {
  const seeds = React.useMemo(() => {
    return data.map((item) => {
      return {
        seed: Math.random(),
        values: item,
      };
    });
  }, [data]);

  return (
    <div className="w-full absolute z-20" style={{ top: margins.top, left: margins.left, height: yMax }}>
      <div className="relative">
        {seeds.map(({ seed, values }, i) => {
          const barX = xScale(values.date) ?? 0;
          const offset = 5;

          const dataBarHeight = yMax - yScale(getDatapointTotal<T>(values, dataPointTotalOptions));
          const barHeight = randomize ? seed * yMax : dataBarHeight > offset ? dataBarHeight : 0;

          return (
            <Skeleton
              containerClassName="absolute"
              className="rounded-none"
              style={{ left: barX, height: barHeight, width: barWidth, top: yMax - barHeight - offset }}
              key={i}
            />
          );
        })}
      </div>
    </div>
  );
};
