import { useApiQueries } from "@libs/hooks/useApiQueries";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useNavigate } from "react-router-dom";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useCallback } from "react";
import { Icon } from "@libs/components/UI/Icon";
import { toast } from "react-toastify";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { AddressInputReadOnly } from "@libs/components/UI/AddressInputReadOnly";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { paths } from "utils/routing/paths";
import { getPracticeDataQuery } from "api/practice/queries";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";
import { createReceiverAccount } from "api/claim/mutations";
import { handleError } from "utils/handleError";
import { usePracticeLogger } from "components/Main/PracticeSegmentContext";

export const ClaimSettingsActivateDentalXChangeRoute: React.FC = () => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const [practiceQuery] = useApiQueries([getPracticeDataQuery({ args: { practiceId } })]);

  const [createReceiverAccountMutation] = useApiMutations([createReceiverAccount]);

  const claimSettingsPath = paths.settingsSection({ section: "claim-settings" });
  const { track } = usePracticeLogger();

  const handleActivateClick = useCallback(() => {
    createReceiverAccountMutation.mutate(
      { practiceId, data: { accountType: "DXC" } },
      {
        onSuccess: () => {
          track({ event: "DentalXChange Onboarded", domains: ["Settings"] });
          navigate(claimSettingsPath, { replace: true });
          toast.success(
            "Please log into DentalXChange and accept the terms and conditions in your account. Log into your DentalXChange account at the link below."
          );
        },
        onError: handleError,
      }
    );
  }, [claimSettingsPath, createReceiverAccountMutation, navigate, practiceId, track]);

  const data = practiceQuery.data;

  return (
    <SettingsPanel
      title="DentalXChange Registration"
      contentClassName="flex flex-col items-center gap-6"
      actions={<LinkIcon SvgIcon={CancelIcon} to={claimSettingsPath} replace={true} theme="primary" />}
      footer={
        <div className="flex items-center justify-center gap-x-2">
          <ButtonInternalLink
            className="min-w-button"
            to={paths.settingsSection({ section: "claim-settings" })}
            replace={true}
            theme="secondary"
          >
            Cancel
          </ButtonInternalLink>
          <AsyncButton
            className="min-w-button"
            isLoading={createReceiverAccountMutation.isLoading}
            type="button"
            disabled={practiceQuery.isLoading}
            onClick={handleActivateClick}
          >
            Activate
          </AsyncButton>
        </div>
      }
    >
      <QueryResult queries={[practiceQuery]}>
        <div className="grid grid-cols-2 max-w-xl mx-auto gap-6 w-full">
          <div className="text-sm font-sansSemiBold col-span-2 mt-6">Confirm Practice Details</div>
          <FormFieldInput label="Practice Owner" value={data?.ownerName} edit={false} />
          <FormFieldInput label="Business Name" value={data?.name} edit={false} />
          <FormFieldInput
            label="Tax ID (EIN)"
            value={data?.taxIdLastFour ? `****${data.taxIdLastFour}` : undefined}
            edit={false}
          />
          <FormFieldPhoneInput label="Phone Number" value={data?.contactDetails.phoneNumber} edit={false} />
          <FormFieldInput label="Email" value={data?.contactDetails.email} edit={false} />
          <AddressInputReadOnly label="Practice Address" address={data?.physicalAddress} />
          <ButtonInternalLink
            className="flex items-center gap-x-1"
            theme="link"
            to={paths.settingsSection({ section: "business-information" })}
          >
            <Icon SvgIcon={EditIcon} />
            Edit
          </ButtonInternalLink>
        </div>
      </QueryResult>
    </SettingsPanel>
  );
};
