import { FC, useMemo } from "react";
import { FormSubmissionVO, FormVO, MedicalHistoryAuditVO } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as LogIcon } from "@libs/assets/icons/readme.svg";
import { Panel } from "@libs/components/UI/Panel";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { EmptyContent } from "@libs/components/UI/EmptyContent";
import { MedicalAuditLog } from "components/PatientProfile/MedicalHistory/MedicalAuditLog";
import { MedicalHistoryQuery } from "utils/routing/patient";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent } from "components/UI/FlyoverComponents";
import { FormResponsePanel } from "components/PatientProfile/Forms/FormResponsePanel";
import { getTaggedResponse } from "components/PatientProfile/Forms/utils";
import { PregnantBanner } from "components/PatientProfile/MedicalHistory/PregnantBanner";
import EmptyMedicalData from "assets/images/empty-medical-data.svg";

type Props = {
  medicalHistoryQuery: ApiQueryResult<FormSubmissionVO>;
  dentalHistoryQuery: ApiQueryResult<FormSubmissionVO>;
  auditLogQuery: ApiQueryResult<MedicalHistoryAuditVO[]>;
  editMedicalHistorySlug: FormVO["slug"];
  auditLog: boolean;
  patientId: number;
  onQueryStateUpdate: (updates: Partial<MedicalHistoryQuery>) => void;
};

export const MedicalHistoryPanel: FC<Props> = ({
  dentalHistoryQuery,
  medicalHistoryQuery,
  editMedicalHistorySlug,
  auditLogQuery,
  auditLog,
  patientId,
  onQueryStateUpdate,
}) => {
  const dueDate = useMemo(() => {
    return medicalHistoryQuery.data
      ? getTaggedResponse(medicalHistoryQuery.data, "PREGNANCY", "DATE", { isConditionalResponse: true })
      : null;
  }, [medicalHistoryQuery.data]);

  return (
    <>
      <Panel
        className="h-full overflow-auto"
        contentClassName="overflow-y-auto"
        includePadding={false}
        title={
          <div className="flex items-center py-2">
            <div>Medical History</div>
            {dueDate?.response ? <PregnantBanner className="ml-5" dueDate={dueDate.response} /> : null}
          </div>
        }
        actions={
          <ButtonIcon
            tooltip={{ content: "View Audit Log", theme: "SMALL" }}
            SvgIcon={LogIcon}
            theme="primary"
            onClick={() => onQueryStateUpdate({ auditLog: true })}
          />
        }
      >
        <div className="h-full flex">
          <div className="flex-1 h-full border-r border-greyLighter">
            <QueryResult queries={[medicalHistoryQuery]}>
              {medicalHistoryQuery.data ? (
                <FormResponsePanel
                  isEditing={editMedicalHistorySlug === "MEDICAL_HISTORY"}
                  onRequestEdit={() => onQueryStateUpdate({ editMedicalHistory: "MEDICAL_HISTORY" })}
                  onCloseEdit={() => onQueryStateUpdate({ editMedicalHistory: undefined })}
                  patientId={patientId}
                  formSubmission={medicalHistoryQuery.data}
                  noResponses={
                    <EmptyContent
                      text="No Recorded Medical History"
                      alt="No Recorded Medical History"
                      src={EmptyMedicalData}
                    />
                  }
                />
              ) : null}
            </QueryResult>
          </div>
          <div className="flex-1 h-full">
            <QueryResult queries={[dentalHistoryQuery]}>
              {dentalHistoryQuery.data ? (
                <FormResponsePanel
                  isEditing={editMedicalHistorySlug === "DENTAL_HISTORY"}
                  onRequestEdit={() => onQueryStateUpdate({ editMedicalHistory: "DENTAL_HISTORY" })}
                  onCloseEdit={() => onQueryStateUpdate({ editMedicalHistory: undefined })}
                  patientId={patientId}
                  formSubmission={dentalHistoryQuery.data}
                  noResponses={
                    <EmptyContent
                      text="No Recorded Dental History"
                      alt="No Recorded Dental History"
                      src={EmptyMedicalData}
                    />
                  }
                />
              ) : null}
            </QueryResult>
          </div>
        </div>
      </Panel>
      {auditLog && (
        <Flyover clickAwayToClose title="Audit Log" onClose={() => onQueryStateUpdate({ auditLog: false })}>
          <FlyoverContent paddingClassName="">
            <QueryResult queries={[auditLogQuery]}>
              {auditLogQuery.data ? <MedicalAuditLog medicalAuditLog={auditLogQuery.data} /> : null}
            </QueryResult>
          </FlyoverContent>
        </Flyover>
      )}
    </>
  );
};
