import React from "react";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";

import { TextProps } from "@visx/text";
import { scaleOrdinal } from "@visx/scale";
import { formatCurrency } from "@libs/utils/currency";
import { DashboardLineChart } from "components/Dashboard/Charting/DashboardLineChart";
import { LineChartLayout } from "components/Dashboard/Charting/LineChartLayout";
import { colorForKey, ColorScheme } from "components/Dashboard/Charting/theme";
import { TimeSeriesResolutionOption } from "components/Dashboard/types";

import { DashboardChartTooltip } from "components/Dashboard/Charting/DashboardChartTooltip";
import { BarStackData, ChartMargin, LineSeriesData } from "components/Dashboard/Charting/types";
import { DashboardChartDisplay, TimeSegment } from "utils/routing/dashboard";
import { BarChartLayout } from "components/Dashboard/Charting/BarChartLayout";
import { VisxBarStacks } from "components/Dashboard/Charting/VisxBarStacks";
import { useYDomain } from "components/Dashboard/Charting/DashboardStackedBarChart";
import { useStackMarginsAndScales } from "components/Dashboard/Charting/hooks/useStackMarginsAndScales";
import { getNumberOfTicks, useDateFormatter } from "components/Dashboard/hooks/useDateFormatter";
import { getTooltipLabel } from "components/Dashboard/Charting/getTooltipLabel";

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "white",
};

export interface Props<T extends string> {
  width: number;
  height: number;
  timeSegment: TimeSegment;
  resolution: TimeSeriesResolutionOption;
  isLoading: boolean;
  data: BarStackData<T>[];
  colorScheme: ColorScheme<T>;
  keys: T[];
  randomizeLoadingBars?: boolean;
  onClickDate: (params: { date: string; index: number }) => void;
  isCurrency?: boolean;
  selectedBarIndex?: number;
  dottedBarIndex?: number;
  labelFormat: (key: T) => string;
  legendOrder?: T[];
  chartMargin?: ChartMargin;
  axisMargin?: ChartMargin;
  chartDisplay: DashboardChartDisplay;
  includeBarTitle?: boolean;
  formatValue?: (val: number) => string;
  xAxisLabel?: {
    text: string;
    labelProps: Partial<TextProps>;
  };
}

export const DashboardTimeseriesChart = <T extends string>({
  data,
  resolution,
  randomizeLoadingBars = true,
  isLoading,
  onClickDate,
  isCurrency = false,
  colorScheme,
  keys,
  width,
  height,
  timeSegment,
  chartMargin,
  axisMargin,
  labelFormat,
  formatValue,
  legendOrder,
  xAxisLabel,
  chartDisplay,
  includeBarTitle = true,
  selectedBarIndex,
  dottedBarIndex,
  ...rest
}: Props<T>) => {
  const formatDate = useDateFormatter({ ...timeSegment, resolution, dataPointCount: data.length });
  const lineSeries: LineSeriesData<T> = React.useMemo(() => {
    const lineSeriesData: LineSeriesData<T> = keys.reduce((prev: LineSeriesData<T>, current) => {
      prev[current] = [];

      return prev;
    }, {} as LineSeriesData<T>);

    for (const datum of data) {
      for (const key of keys) {
        lineSeriesData[key].push({
          date: datum.date,
          value: datum[key],
          key,
        });
      }
    }

    return lineSeriesData;
  }, [data, keys]);
  const colorScale = React.useMemo(() => {
    return scaleOrdinal<T, string>(colorScheme);
  }, [colorScheme]);
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
    useTooltip<BarStackData<T>>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });
  const yDomain = useYDomain({ data, isCurrency, isLoading });
  const marginsAndScales = useStackMarginsAndScales({
    width,
    height,
    data,
    yDomain,
    resolution,
    chartMargin,
    axisMargin,
  });

  const { margins, xScale, yScale, yMax } = marginsAndScales;
  const dataPointCount = data.length;
  const numTicksXAxis = getNumberOfTicks(resolution, dataPointCount);
  const formatTooltipLabel = React.useCallback(
    (date: string) => {
      return getTooltipLabel({
        dateString: date,
        dateWindow: timeSegment,
        resolution,
      });
    },
    [resolution, timeSegment]
  );

  return chartDisplay === "line" ? (
    <LineChartLayout
      width={width}
      height={height}
      innerRef={containerRef}
      colorScale={colorScale}
      keys={keys}
      formatDate={formatDate}
      formatValue={formatValue}
      formatTooltipDate={formatTooltipLabel}
      onClickDate={onClickDate}
      labelFormat={labelFormat}
      isCurrency={isCurrency}
      selectedDateIndex={selectedBarIndex}
      dottedDateIndex={dottedBarIndex}
      numTicksXAxis={numTicksXAxis}
    >
      <DashboardLineChart data={lineSeries} keys={keys} colorScheme={colorScheme} />
    </LineChartLayout>
  ) : (
    <>
      <BarChartLayout
        width={width}
        height={height}
        isLoading={isLoading}
        isFirstLoad={randomizeLoadingBars}
        tooltipOpen={tooltipOpen}
        xAxisLabel={xAxisLabel}
        innerRef={containerRef}
        loadingData={data}
        colorScale={colorScale}
        formatDate={formatDate}
        labelFormat={labelFormat}
        formatValue={formatValue}
        isCurrency={isCurrency}
        numTicksXAxis={numTicksXAxis}
        {...marginsAndScales}
      >
        <VisxBarStacks
          onHideTooltip={hideTooltip}
          onShowTooltip={showTooltip}
          onClickBar={onClickDate}
          isCurrency={isCurrency}
          xScale={xScale}
          yScale={yScale}
          data={data}
          yMax={yMax}
          formatValue={formatValue}
          includeBarTitle={includeBarTitle}
          tooltipData={tooltipData}
          barColorScale={colorScale}
          keys={keys}
          marginBottom={margins.chartMargin.bottom}
          selectedBarIndex={selectedBarIndex}
          dottedBarIndex={dottedBarIndex}
          {...rest}
        />
      </BarChartLayout>

      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
          className="space-y-1 bg-white text-greyDark py-4"
        >
          <DashboardChartTooltip
            dateLabel={formatTooltipLabel(tooltipData.date)}
            formatValue={formatValue ?? (isCurrency ? formatCurrency : undefined)}
            items={(legendOrder ?? keys).map((key) => {
              const value: number = tooltipData[key];

              return {
                value,
                label: labelFormat(key),
                color: colorForKey(colorScheme, key),
              };
            })}
          />
        </TooltipInPortal>
      )}
    </>
  );
};
