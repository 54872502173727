import { FC, useCallback } from "react";
import { DentalProcedureVO, PatientProcedureVO, ProviderVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Modal } from "@libs/components/UI/Modal";
import { getDentalProceduresQuery, getPatientChartV2 } from "api/charting/queries";
import { createPatientProcedures } from "api/charting/mutations";
import { handleError } from "utils/handleError";

import { AddPatientProceduresFormWrapper } from "components/Charting/AddPatientProceduresFormWrapper";
import { DraftPatientProcedureRequest, draftsToPost } from "components/Charting/draftPatientProcedure";

interface Props {
  patientId: number;
  providers: ProviderVO[];
  dentalProcedures: DentalProcedureVO[];
  draftPatientProcedures: DraftPatientProcedureRequest[];
  onCreated: (patients: PatientProcedureVO[]) => void;
  onRequestClose: Func;
}

export const AddPatientProceduresModal: FC<Props> = ({
  onRequestClose,
  patientId,
  providers,
  dentalProcedures,
  draftPatientProcedures,
  onCreated,
}) => {
  const { practiceId } = useAccount();
  const [dentalProceduresQuery, chartV2Query] = useApiQueries([
    getDentalProceduresQuery({ args: { practiceId } }),
    getPatientChartV2({
      args: { patientId, practiceId },
    }),
  ]);

  const [createPatientProceduresMutation] = useApiMutations([createPatientProcedures]);

  const create = createPatientProceduresMutation.mutate;
  const onSubmit = useCallback(
    (drafts: DraftPatientProcedureRequest[], draftDentalProcedures: DentalProcedureVO[]) => {
      if (chartV2Query.data) {
        create(
          {
            practiceId,
            patientId,
            data: {
              patientProcedures: draftsToPost(drafts, draftDentalProcedures, chartV2Query.data),
            },
          },
          {
            onSuccess: (response) => {
              onCreated(response.data.data);
            },
            onError: handleError,
          }
        );
      }
    },
    [onCreated, patientId, practiceId, create, chartV2Query.data]
  );

  return (
    <Modal height={930} title="Add Procedure" onClose={onRequestClose}>
      <QueryResult queries={[chartV2Query, dentalProceduresQuery]}>
        {chartV2Query.data && dentalProceduresQuery.data ? (
          <AddPatientProceduresFormWrapper
            onSubmit={onSubmit}
            onRequestClose={onRequestClose}
            isSaving={createPatientProceduresMutation.isLoading}
            providers={providers}
            teeth={chartV2Query.data}
            allDentalProcedures={dentalProceduresQuery.data}
            dentalProcedures={dentalProcedures}
            draftPatientProcedures={draftPatientProcedures}
          />
        ) : null}
      </QueryResult>
    </Modal>
  );
};
