import { FC, useCallback } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { ReferralsTable } from "components/Settings/Forms/ReferralOptions/ReferralsTable";
import { getOnboardingOptions } from "api/forms/queries";
import { upsertOnboardingOptions } from "api/forms/mutations";
import { handleError } from "utils/handleError";

export const ReferralOptionsRoute: FC = () => {
  const { practiceId } = useAccount();
  const [onboardingOptionsQuery] = useApiQueries([getOnboardingOptions({ args: { practiceId } })]);

  const [upsertOnboardingOptionsMutation] = useApiMutations([upsertOnboardingOptions]);

  const updateOptions = useCallback(
    async (updatedOptions: string[], onSuccess?: Func) => {
      try {
        await upsertOnboardingOptionsMutation.mutateAsync({
          practiceId,
          data: { referralOptions: updatedOptions },
        });
        onSuccess?.();
      } catch (e) {
        handleError(e);
      }
    },
    [practiceId, upsertOnboardingOptionsMutation]
  );

  return (
    <SettingsPanel title="Referral Options">
      <div className="flex flex-col gap-y-6">
        <div className="flex flex-col gap-y-1 text-xs">
          <span className="mb-1">
            Set which items show when the patient is asked how they found your practice.
          </span>
          <QueryResult queries={[onboardingOptionsQuery]}>
            {onboardingOptionsQuery.data && (
              <ReferralsTable
                isSaving={false}
                onboardingOptions={onboardingOptionsQuery.data}
                onUpdateOptions={updateOptions}
              />
            )}
          </QueryResult>
        </div>
      </div>
    </SettingsPanel>
  );
};
