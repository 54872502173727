import { ClaimInsuranceCarrierVO, ProviderVO } from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { FilterComponentProps, toFilterComponentsProps } from "@libs/components/UI/queryFilterPillsUtils";
import { paymentStatusToPillText, stateToPillText } from "components/Claims/utils";
import { ClaimsQuery } from "utils/routing/claims";
import { FilterStatusData } from "components/Claims/types";

type ClaimsFilterQuery = Pick<
  ClaimsQuery,
  | "search"
  | "insuranceCarrierIds"
  | "insuranceOrdinals"
  | "insurancePaymentStatuses"
  | "billingProviderIds"
  | "treatingProviderIds"
  | "states"
  | "statuses"
>;

export type ClaimFilterProps = {
  params: ClaimsFilterQuery;
  filters: FilterComponentProps<ClaimsFilterQuery>[];
  emptyParams: Partial<ClaimsFilterQuery>;
};

export const insuranceCarrierIdToName = (
  value: number,
  insuranceCarriers: ClaimInsuranceCarrierVO[] | undefined
) => {
  return insuranceCarriers?.find((carrier) => carrier.insuranceCarrierId === value)?.name ?? "";
};

export const providerIdToName = (value: number, practiceProviders: ProviderVO[] | undefined) => {
  return practiceProviders?.find((provider) => provider.id === value)?.name.fullDisplayName ?? "";
};

export const getClaimsFilterProps = (
  queryState: ClaimsFilterQuery,
  billingProviders: ProviderVO[] | undefined,
  insuranceCarriers: ClaimInsuranceCarrierVO[] | undefined,
  treatingProviders: ProviderVO[] | undefined
) =>
  toFilterComponentsProps(queryState, [
    {
      type: "default",
      prop: "search",
      format: (val) => `Search: ${val.trim()}`,
    },
    {
      type: "list",
      prop: "insuranceCarrierIds",
      format: (val) => insuranceCarrierIdToName(val, insuranceCarriers),
    },
    {
      prop: "insuranceOrdinals",
      type: "list",
      format: (val) => sentenceCaseConstant(val),
    },
    {
      prop: "insurancePaymentStatuses",
      type: "list",
      format: (val) => paymentStatusToPillText[val],
    },
    {
      prop: "billingProviderIds",
      type: "list",
      format: (val) => providerIdToName(val, billingProviders),
    },
    {
      prop: "treatingProviderIds",
      type: "list",
      format: (val) => providerIdToName(val, treatingProviders),
    },
    {
      prop: "states",
      type: "list",
      format: (val) => stateToPillText[val],
    },
    {
      prop: "statuses",
      type: "list",
      format: (val) => FilterStatusData[val].label,
    },
  ]);
