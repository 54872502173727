import { InsurancePlanVO, PatientInsuranceVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";

export const planTypeNames: { [key in InsurancePlanVO["planType"]]: string } = {
  PPO: "PPO",
  TOA_SOA: "TOA/SOA",
  EPO: "EPO",
  HMO_DHMO: "HMO/DHMO",
  MEDICAID_CHIP: "MEDICAID/CHIP",
  POS: "Point of Service Plan",
  DISCOUNT: "Discount Plans",
  SELF_FUNDED_ERISA: "Self-Funded Plans ERISA",
  DIRECT_REIMBURSEMENT: "Direct Reimbursement",
  FEDERAL: "Federal Plans",
  MEDICARE: "Medicare",
  TRICARE: "Tricare",
};

type Month =
  | "JANUARY"
  | "FEBRUARY"
  | "MARCH"
  | "APRIL"
  | "MAY"
  | "JUNE"
  | "JULY"
  | "AUGUST"
  | "SEPTEMBER"
  | "OCTOBER"
  | "NOVEMBER"
  | "DECEMBER";
export const readableMonth = (month: Month) => {
  const lower = month.toLowerCase();

  return lower.charAt(0).toUpperCase() + lower.slice(1);
};

export const hasDeductiblesMaximumsEntered = (
  patientInsurance: PatientInsuranceVO,
  insurancePlan: InsurancePlanVO
) => {
  return (
    isDefined(insurancePlan.familyAnnualDeductible) ||
    isDefined(insurancePlan.familyAnnualMaximum) ||
    isDefined(patientInsurance.annualDeductibleRemaining) ||
    isDefined(patientInsurance.annualMaximumRemaining) ||
    isDefined(patientInsurance.subscriber.annualDeductibleRemaining) ||
    isDefined(patientInsurance.subscriber.annualMaximumRemaining)
  );
};
