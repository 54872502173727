/* eslint-disable complexity */
import React, {
  ChangeEventHandler,
  InputHTMLAttributes,
  MouseEventHandler,
  KeyboardEventHandler,
  useCallback,
  useRef,
} from "react";
import { cx } from "@libs/utils/cx";

export type ThresholdCondition = "gte" | "lte";

type PerioChartNumericInputProps = InputHTMLAttributes<HTMLInputElement> & {
  valueThreshold?: number;
  thresholdCondition?: ThresholdCondition;
  showPlusOnPositive?: boolean;
  theme?: "default" | "soft";
};

export const PerioChartNumericInput: React.FC<PerioChartNumericInputProps> = ({
  valueThreshold,
  thresholdCondition = "gte",
  value,
  onChange,
  theme = "default",
  onClick,
  onKeyDown,
  showPlusOnPositive,
  ...props
}) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const handleOnChange = useCallback<ChangeEventHandler<HTMLInputElement>>(
    (e) => {
      // Get the most recent digit, and make that the value.
      e.currentTarget.value = e.currentTarget.value.match(/(\d)\D*$/)?.[1] || "";

      if (onChange) {
        onChange(e);
      }
    },
    [onChange]
  );

  const handleOnClick = useCallback<MouseEventHandler<HTMLInputElement>>(
    (e) => {
      inputRef.current?.select();

      if (onClick) {
        onClick(e);
      }
    },
    [onClick]
  );

  const handleKeyDown = useCallback<KeyboardEventHandler<HTMLInputElement>>(
    (e) => {
      if (e.key >= "0" && e.key <= "9") {
        // Append the key to the end so that it triggers a change, the onChange handler will filter
        // it only to the most recent digit, then the focus next will work as expected. This fixes
        // issues with cursor before the current value or replacing with the same value.
        e.currentTarget.value = `${e.currentTarget.value}${e.key}`;
      } else if (e.key.length === 1) {
        // Prevent any other character from being input.
        e.preventDefault();
      }

      if (onKeyDown) {
        onKeyDown(e);
      }
    },
    [onKeyDown]
  );

  const numericValue = Number(value);

  const showPlus = showPlusOnPositive && numericValue > 0;
  const absValue = Math.abs(numericValue);

  const isOverThreshold =
    valueThreshold &&
    !showPlus &&
    ((thresholdCondition === "gte" && absValue >= valueThreshold) ||
      (thresholdCondition === "lte" && absValue <= valueThreshold));
  const boundValue = value != null && !Number.isNaN(numericValue) ? `${showPlus ? "+" : ""}${absValue}` : "";

  return (
    <input
      ref={inputRef}
      value={boundValue}
      {...props}
      className={cx(
        `w-4
         h-4
         border
         border-slate-300
         hover:border-primaryTheme
         disabled:border-slate-100
         disabled:bg-slate-100
         text-center
         text-xs`,
        theme === "default" ? "border-greyLight rounded-sm" : "border-slate-300 rounded-md",
        isOverThreshold ? "text-red" : "text-greyDark",
        props.className
      )}
      onChange={handleOnChange}
      onClick={handleOnClick}
      onKeyDown={handleKeyDown}
    />
  );
};
