import { useState } from "react";
import { FormEditableTextBlockElementRequest } from "@libs/api/generated-api";
import { required } from "@libs/utils/validators";
import { titleSchema } from "components/Settings/Forms/utils";

export const useEditableTextBlockState = (
  element: FormEditableTextBlockElementRequest | (() => FormEditableTextBlockElementRequest)
) => {
  return useState(() => ({ draft: typeof element === "function" ? element() : element }));
};

export const editableTextBlockSchema = {
  title: titleSchema,
  content: [{ $v: required, $error: "A note is required." }],
};
