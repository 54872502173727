import { FC, Fragment, ReactNode, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
  FamilyMemberResponse,
  FamilyMemberVO,
  InvoiceEntryVO,
  InvoiceVO,
  MultiInvoicePaymentAllocationVO,
  MultiInvoicePaymentRequest,
  MultiInvoicePaymentVO,
  PaymentDeviceVO,
  PaymentProfileVO,
  PracticeBillingSettingVO,
  WalletVO,
} from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { useBoolean } from "@libs/hooks/useBoolean";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as MinusIcon } from "@libs/assets/icons/minus.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { isDefined } from "@libs/utils/types";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { CollectPaymentForm } from "components/PatientProfile/Billing/Payment/CollectPaymentForm";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { usePathParams } from "hooks/usePathParams";
import {
  Col,
  InvoiceEntryDescription,
  NoPayments,
  PaymentDescription,
  PracticeAndPatientIds,
  Row,
} from "components/PatientProfile/Billing/Ledger/LedgerComponents";
import { ToggleCaret } from "components/UI/ToggleCaret";
import { Text } from "components/PatientProfile/Billing/Ledger/Text";
import { InvoiceDescription } from "components/PatientProfile/Billing/Ledger/InvoiceDescription";
import { CollapsibleSection } from "components/UI/CollapsibleSection";
import { createMultiInvoicePayment } from "api/billing/mutations";

import { handleError } from "utils/handleError";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import {
  makePaymentCreationRequest,
  useCollectPaymentForm,
} from "components/PatientProfile/Billing/Payment/useCollectPaymentForm";
import { usePaymentPolling } from "components/PatientProfile/Billing/usePaymentPolling";
import { useNotificationContext } from "contexts/NotificationsContext";
import { getPaymentButtonLabel, isPaymentStateFailed } from "components/PatientProfile/Billing/billingUtils";
import { PaymentLayout } from "components/PatientProfile/Billing/PaymentLayout";
import { DetailsTable } from "components/PatientProfile/Billing/Ledger/DetailsTable";
import { invoiceEntryOrLineItemToId } from "components/PatientProfile/Billing/invoiceUtils";
import { ToggleGroup } from "components/UI/ToggleGroup";
import { useFamilyInvoices } from "components/PatientProfile/Billing/useFamilyInvoices";
import { FamilyMemberHeader } from "components/PatientProfile/Billing/FamilyMemberHeader";
import { useFamilyMembersWithPendingInvoiceEntries } from "components/PatientProfile/Billing/useFamilyMembersWithPendingInvoiceEntries";
import { NeedsInvoicingBanner } from "components/PatientProfile/Billing/NeedsInvoicingBanner";
import {
  getInvoicesDryRun,
  getPaymentProfilesByPatientId,
  getPracticeBillingSetting,
  getWallets,
} from "api/billing/queries";
import { listPaymentDevices } from "api/settings/payments/queries";
import { getFamilyMembersV2Query } from "api/patients/queries";
import { useCollectPaymentFormValidation } from "components/PatientProfile/Billing/Payment/useCollectPaymentFormValidation";
import { checkPermission } from "components/Roles/roleUtils";
import { useCurrentUser } from "contexts/CurrentUserContext";

type Props = {
  paymentDevices: PaymentDeviceVO[];
  paymentProfilesData: PaymentProfileVO[];
  familyMembersData: FamilyMemberResponse;
  practiceBillingSettings: PracticeBillingSettingVO;
  patientWallets: WalletVO[];
  initialDryrunData: MultiInvoicePaymentVO;
};

const useTransactionDeniedTooltip = ({ isRefund }: { isRefund: boolean }) => {
  const currentUser = useCurrentUser();

  return useMemo(() => {
    if (isRefund && !checkPermission(currentUser.roleV2, "BILLING", "CREATE_REFUND").isPermitted) {
      return "Your account is not permitted to refund payments";
    }

    return null;
  }, [currentUser.roleV2, isRefund]);
};

// eslint-disable-next-line complexity
const LoadedCollectPaymentRoute: FC<Props> = ({
  paymentDevices,
  paymentProfilesData,
  familyMembersData,
  practiceBillingSettings,
  patientWallets,
  initialDryrunData,
}) => {
  const { practiceId } = useAccount();
  const { patientId } = usePathParams("collectPayment");
  const {
    query: { from, mode },
    updateQuery,
  } = useQueryParams("collectPayment");
  const navigate = useNavigate();

  const [paymentUuidToPoll, setPaymentUuidToPoll] = useState<string>();

  const {
    paymentProfiles,
    walletsWithFamilyMembers,
    paymentMethodOptions,
    paymentDraft,
    maxAmountDue,
    isRefund,
    refundablePayments,
    multiInvoicePayment,
    multiInvoiceUuids,
    handleUpdatePaymentDraft,
    handleRemoveInvoices,
    handleIncludeFamilyChanged,
    handleSuccessfulPayment,
    isFetchingMultiInvoice,
    isInitializing,
    selectedWallet,
  } = useCollectPaymentForm({
    patientId,
    practiceId,
    familyMembersData,
    paymentProfilesData,
    paymentDevices,
    patientWallets,
    initialDryrunData,
    includeFamily: mode === "family",
  });
  const validation = useCollectPaymentFormValidation({
    input: { paymentDraft, maxAmountDue, selectedWallet },
    isValidating: false,
  });

  const [{ mutate: submitMultiInvoice, isLoading, variables: requestData }] = useApiMutations([
    createMultiInvoicePayment,
  ]);
  const familyMembers = familyMembersData.linkedFamilyMembers;

  const familyMembersWithInvoiceables = useFamilyMembersWithPendingInvoiceEntries(patientId, practiceId);

  const notification = useNotificationContext();
  const { isPolling } = usePaymentPolling({
    practiceId,
    paymentUuid: paymentUuidToPoll,
    onSuccess: () => {
      notification.handleSuccess(`${isRefund ? "Refund" : "Payment"} was successful.`);
      navigate(
        paths.viewPayment(
          {
            patientId,
            paymentUuid: paymentUuidToPoll,
          },
          { from }
        )
      );
    },
    onError: (paymentGatewayError) => {
      const errorMessageParts = [`Failed to process ${isRefund ? "refund" : "payment"}.`];

      if (paymentGatewayError) {
        errorMessageParts.push(paymentGatewayError);
      }

      notification.handleError(errorMessageParts.join(" "), { autoClose: false });
    },
  });

  const isCommitting = (isLoading && (requestData?.data.commit ?? false)) || isPolling;

  const handleSubmit = useCallback(
    (multiInvoicePaymentRequest: MultiInvoicePaymentRequest) => {
      // If we're committing the payment, validate the payment form unless the maxAmountDue is $0.
      if (maxAmountDue !== 0 && !validation.validate().$isValid) {
        return;
      }

      if (maxAmountDue === 0) {
        delete multiInvoicePaymentRequest.payment;
      }

      submitMultiInvoice(
        {
          patientId,
          practiceId,
          data: multiInvoicePaymentRequest,
        },
        {
          onSuccess: (result) => {
            const updatedMultiInvoicePayment = result.data.data;

            handleSuccessfulPayment(updatedMultiInvoicePayment);
            // Since we're committing the payment, set the `paymentUuidToPoll` so we can poll for the
            // payment status and check for errors before redirecting to the payment receipt page.
            setPaymentUuidToPoll(updatedMultiInvoicePayment.payment.uuid);
          },
          onError: handleError,
        }
      );
    },
    [handleSuccessfulPayment, maxAmountDue, patientId, practiceId, submitMultiInvoice, validation]
  );

  const disabledActionTooltip = useTransactionDeniedTooltip({ isRefund });

  return (
    <PaymentLayout
      title={
        <div className="flex items-center gap-x-4">
          <div className="w-32">
            <Text size="md" bold>
              {isRefund ? "Issue Refund" : "Collect Payment"}
            </Text>
          </div>
          {familyMembers.length > 1 && (
            <ToggleGroup
              onChange={(e, option) => {
                updateQuery("replaceIn", { mode: option.value });
                handleIncludeFamilyChanged(option.value === "family");
              }}
              selectedValue={mode}
              options={[
                { label: "Individual", value: "individual" },
                { label: "Family", value: "family" },
              ]}
              size="md"
            />
          )}
        </div>
      }
      isLoadingInvoiceList={isInitializing}
      isLoadingPaymentForm={false}
      closeLink={from ?? paths.patientBilling({ patientId })}
      pageBanner={
        <NeedsInvoicingBanner
          patientId={patientId}
          mode={mode}
          familyMembersWithInvoiceables={familyMembersWithInvoiceables}
        />
      }
      invoiceList={
        multiInvoicePayment.allocations && (
          <Invoices
            invoices={multiInvoicePayment.invoices}
            allocations={multiInvoicePayment.allocations}
            familyMembers={familyMembers}
            onRemoveInvoices={handleRemoveInvoices}
          />
        )
      }
      paymentForm={
        maxAmountDue === 0 ? (
          <div className="mt-32">
            <NoPaymentDue
              reason={
                multiInvoicePayment.invoices.length === 0
                  ? "Create an invoice before processing a payment."
                  : "Apply credit to resolve these invoices."
              }
            />
          </div>
        ) : (
          <div className="flex flex-col gap-y-6">
            <Text bold size="sm">
              {isRefund ? "Refund" : "Payment"} Details
            </Text>
            <CollectPaymentForm
              patientId={patientId}
              paymentMethodOptions={paymentMethodOptions}
              paymentDraft={paymentDraft}
              handleUpdatePaymentDraft={handleUpdatePaymentDraft}
              billingSettings={practiceBillingSettings}
              multiInvoicePayment={multiInvoicePayment}
              walletsWithFamilyMembers={walletsWithFamilyMembers}
              paymentProfiles={paymentProfiles.map((profile) => profile.paymentProfile)}
              paymentDevices={paymentDevices}
              validation={validation.result}
              refundablePayments={refundablePayments}
              disabled={isCommitting}
              isRefund={isRefund}
              isLoadingMultiInvoicePayment={isFetchingMultiInvoice}
              maxAmountDue={maxAmountDue}
            />
          </div>
        )
      }
      paymentButtons={
        <FloatingTooltip content={disabledActionTooltip} theme="MEDIUM">
          <AsyncButton
            className="min-w-button"
            isLoading={isFetchingMultiInvoice || isPolling || isLoading}
            disabled={
              Boolean(disabledActionTooltip) ||
              isFetchingMultiInvoice ||
              isLoading ||
              isCommitting ||
              multiInvoicePayment.invoices.length === 0 ||
              validation.result.$isValid === false
            }
            displayLoadingText
            type="submit"
          >
            {multiInvoicePayment.invoices.length === 0
              ? "Charge"
              : maxAmountDue === 0
                ? "Apply Credit"
                : getPaymentButtonLabel({
                    isProcessing: isCommitting,
                    paymentMethod:
                      paymentDraft.paymentMethod === "REFUNDABLE_CARD"
                        ? "STORED_POS"
                        : paymentDraft.paymentMethod,
                    paymentType: isRefund ? "REFUND" : "CHARGE",
                  })}
          </AsyncButton>
        </FloatingTooltip>
      }
      onSubmit={(e) => {
        e.preventDefault();

        if (isDefined(paymentDraft.paymentAmount)) {
          handleSubmit({
            commit: true,
            invoiceUuids: multiInvoiceUuids,
            payment: makePaymentCreationRequest({
              ...paymentDraft,
              paymentAmount: paymentDraft.paymentAmount,
              // Generates a new idempotencyUuid:
              idempotencyUuid: undefined,
            }),
          });
        }
      }}
    />
  );
};

const Invoices: FC<{
  invoices: InvoiceVO[];
  allocations: MultiInvoicePaymentAllocationVO[];
  familyMembers?: FamilyMemberVO[];
  onRemoveInvoices: (invoiceUuid: string[]) => void;
}> = ({ invoices, allocations, familyMembers, onRemoveInvoices }) => {
  const { familyMembersById, familyMembersToRender } = useFamilyInvoices({
    invoices,
    allocations,
    familyMembers,
  });

  return (
    <div className="divide-y divide-slate-300">
      <Row padding="billingHeader">
        <Columns
          expandAction={undefined}
          date={<Text bold>Date</Text>}
          details={<Text bold>Details</Text>}
          amountDue={<Text bold>Amount Due</Text>}
          amountAllocated={<Text bold>Payment</Text>}
          removeAction={undefined}
        />
      </Row>
      {familyMembersToRender.map((fm) => {
        const familyMemberWithInvoices = familyMembersById[fm.memberPatientId];
        const canClearFamilyMember =
          familyMembersToRender.length > 1 && (familyMemberWithInvoices?.invoices.length ?? 0) > 1;

        return (
          <Fragment key={fm.memberPatientId}>
            <FamilyMemberHeader
              className="pl-12"
              name={fm.name.shortDisplayName}
              relationship={fm.relation ? sentenceCaseConstant(fm.relation) : "Undefined relationship"}
              onRemove={
                canClearFamilyMember
                  ? () =>
                      familyMemberWithInvoices?.invoices.length &&
                      onRemoveInvoices(
                        familyMemberWithInvoices.invoices.map((invoice) => invoice.invoice.uuid)
                      )
                  : undefined
              }
            />
            {familyMemberWithInvoices?.invoices.map(({ invoice, allocation }) => (
              <Invoice
                key={invoice.uuid}
                invoice={invoice}
                date={
                  <Text bold>
                    <DateWithTooltip date={invoice.createdAt} dateAsSeconds format="P" />
                  </Text>
                }
                details={<InvoiceDescription invoiceNumber={invoice.invoiceNumber} />}
                creditApplied={
                  allocation.totalCreditAmount > 0 ? (
                    <Text>
                      Pt Credit Applied:{" "}
                      <Text bold color="green">
                        {formatCurrency(allocation.totalCreditAmount)}
                      </Text>
                    </Text>
                  ) : allocation.totalCreditAmount < 0 ? (
                    <Text>Credit Applied</Text>
                  ) : null
                }
                amountDue={<Text bold>{formatCurrency(allocation.totalAmountDue)}</Text>}
                amountAllocated={<Text bold>{formatCurrency(allocation.paymentAllocationAmount)}</Text>}
                removeAction={
                  invoices.length > 1 ? (
                    <ButtonIcon
                      onClick={() => onRemoveInvoices([invoice.uuid])}
                      SvgIcon={MinusIcon}
                      theme="slate700"
                      tooltip={{ content: "Remove", theme: "SMALL" }}
                    />
                  ) : undefined
                }
              />
            ))}
          </Fragment>
        );
      })}
    </div>
  );
};

const Columns: FC<{
  expandAction?: ReactNode;
  date?: ReactNode;
  details: ReactNode;
  amountDue?: ReactNode;
  amountAllocated?: ReactNode;
  removeAction?: ReactNode;
}> = ({ expandAction, date, details, amountDue, amountAllocated, removeAction }) => {
  return (
    <>
      <Col justify="left" width="sm">
        {expandAction}
      </Col>
      <Col justify="left" width="lg" align="center">
        {date}
      </Col>
      <Col justify="left" width="full" align="center">
        {details}
      </Col>
      <Col justify="right" width="lg" nowrap align="center">
        {amountDue}
      </Col>
      <Col justify="right" width="lg" align="center">
        {amountAllocated}
      </Col>
      <Col justify="right" width="sm">
        {removeAction}
      </Col>
    </>
  );
};

const Invoice: FC<{
  invoice: InvoiceVO;
  date: ReactNode;
  details: ReactNode;
  creditApplied: ReactNode;
  amountDue: ReactNode;
  amountAllocated: ReactNode;
  removeAction: ReactNode;
}> = ({ invoice, date, details, creditApplied, amountDue, amountAllocated, removeAction }) => {
  const expand = useBoolean(false);
  const { patientId } = usePathParams("collectPayment");
  const { practiceId } = useAccount();

  return (
    <div className="p-2" key={invoice.uuid}>
      <Row>
        <Columns
          expandAction={<ToggleCaret initialState="closed" size="lg" onToggle={expand.toggle} />}
          date={date}
          details={
            <>
              <Col justify="left" width="full">
                {details}
              </Col>
              <Col justify="right" width="3xl">
                {creditApplied}
              </Col>
            </>
          }
          amountDue={amountDue}
          amountAllocated={amountAllocated}
          removeAction={removeAction}
        />
      </Row>
      <CollapsibleSection isOpen={expand.isOn}>
        <InvoiceExpandedContent
          practiceId={practiceId}
          patientId={patientId}
          invoice={invoice}
          showPaymentMenu={false}
        />
      </CollapsibleSection>
    </div>
  );
};

const NoPaymentDue: FC<{ reason: string }> = ({ reason }) => {
  return (
    <div className="flex flex-col items-center gap-y-4">
      <Text bold size="sm">
        No Payment Due
      </Text>
      <Text size="sm">{reason}</Text>
    </div>
  );
};

const TableRow: FC<{
  date: ReactNode;
  details: ReactNode;
  amount: ReactNode;
}> = ({ date, details, amount }) => {
  return (
    <Row padding="tableRow">
      <Col justify="left" width="lg">
        {date}
      </Col>
      <Col justify="left" width="full">
        {details}
      </Col>
      <Col justify="right" width="lg">
        {amount}
      </Col>
    </Row>
  );
};

const InvoiceExpandedContent: FC<
  PracticeAndPatientIds & { invoice: InvoiceVO; showPaymentMenu: boolean }
> = ({ invoice }) => {
  return (
    <Row>
      <Columns
        details={
          <DetailsTable>
            {invoice.payments.length ? (
              <>
                <TableRow
                  date={<Text bold>{invoice.amount > 0 ? "Payments" : "Refunds"}</Text>}
                  details={undefined}
                  amount={<Text bold>Amount</Text>}
                />
                {invoice.payments.map((payment) => {
                  const paymentFailed = isPaymentStateFailed(payment.state);

                  return (
                    <TableRow
                      key={payment.uuid}
                      date={
                        <Text>
                          <DateWithTooltip date={payment.paymentCreatedAt} dateAsSeconds format="P" />
                        </Text>
                      }
                      details={<PaymentDescription payment={payment} />}
                      amount={
                        <Text color={paymentFailed ? "red" : "green"} bold>
                          {formatCurrency(-payment.currencyAmount.amount)}
                        </Text>
                      }
                    />
                  );
                })}
              </>
            ) : (
              <TableRow
                date={
                  <Text>
                    <NoPayments />
                  </Text>
                }
                details={undefined}
                amount={undefined}
              />
            )}
            <TableRow date={<Text bold>Items</Text>} details={undefined} amount={<Text bold>Amount</Text>} />
            {invoice.entries.map((entry) => (
              <InvoiceEntry key={invoiceEntryOrLineItemToId(entry)} entry={entry} />
            ))}
            {invoice.entries.length > 1 && (
              <TableRow
                date={undefined}
                details={
                  <Col justify="right" align="center" width="full">
                    <Text bold>Total</Text>
                  </Col>
                }
                amount={<Text bold>{formatCurrency(invoice.amount)}</Text>}
              />
            )}
          </DetailsTable>
        }
      />
    </Row>
  );
};

const InvoiceEntry: FC<{ entry: InvoiceEntryVO }> = ({ entry }) => {
  return (
    <TableRow
      date={
        <Text>
          <DateWithTooltip date={entry.createdAt} dateAsSeconds format="P" />
        </Text>
      }
      details={
        <Text>
          <InvoiceEntryDescription entry={entry} />
        </Text>
      }
      amount={<Text>{formatCurrency(entry.amount)}</Text>}
    />
  );
};

// eslint-disable-next-line complexity
export const CollectPaymentRoute = () => {
  const { practiceId } = useAccount();
  const { patientId } = usePathParams("collectPayment");

  const {
    query: { invoice: singleInvoiceUuid, invoiceUuids, mode },
  } = useQueryParams("collectPayment");

  const [
    paymentDevicesQuery,
    paymentProfilesQuery,
    familyMembersQuery,
    initialDryRunQuery,
    billingSettingsQuery,
    patientWalletsQuery,
  ] = useApiQueries([
    listPaymentDevices({ args: { practiceId, query: { states: ["ACTIVATED"] } } }),
    getPaymentProfilesByPatientId({ args: { patientId, practiceId, includeFamily: true } }),
    getFamilyMembersV2Query({ args: { patientId, practiceId } }),
    getInvoicesDryRun({
      args: {
        practiceId,
        patientId,
        data: {
          invoiceUuids: invoiceUuids ?? (singleInvoiceUuid ? [singleInvoiceUuid] : undefined),
          includeFamily: mode === "family",
        },
      },
      queryOptions: {
        refetchOnWindowFocus: false,
        refetchOnMount: true,
      },
    }),
    getPracticeBillingSetting({ args: { practiceId } }),
    getWallets({ args: { patientId, practiceId, includeFamily: true, withEmptyBalances: true } }),
  ]);

  const queries: { isLoading: boolean; isError: boolean }[] = [
    paymentDevicesQuery,
    paymentProfilesQuery,
    familyMembersQuery,
    initialDryRunQuery,
    billingSettingsQuery,
  ];

  return (
    <QueryResult queries={queries}>
      {paymentDevicesQuery.data &&
        paymentProfilesQuery.data &&
        familyMembersQuery.data &&
        initialDryRunQuery.data &&
        billingSettingsQuery.data &&
        patientWalletsQuery.data && (
          <LoadedCollectPaymentRoute
            paymentDevices={paymentDevicesQuery.data}
            paymentProfilesData={paymentProfilesQuery.data}
            familyMembersData={familyMembersQuery.data}
            practiceBillingSettings={billingSettingsQuery.data}
            patientWallets={patientWalletsQuery.data}
            initialDryrunData={initialDryRunQuery.data}
          />
        )}
    </QueryResult>
  );
};
