import { FC } from "react";
import { CustomAdjustmentTypeVO, InvoiceEntryVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import {
  AdjustmentRow,
  EntryRow,
  EntryTable,
} from "components/PatientProfile/Billing/InvoiceEntryComponents";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import {
  getAdjustmentsByProcedureId,
  invoiceEntryOrLineItemToId,
  getPracticeAdjustmentById,
  getPatientProcedureDescription,
} from "components/PatientProfile/Billing/invoiceUtils";

export const InvoiceEntry: FC<{
  entry: InvoiceEntryVO;
  practiceAdjustments?: CustomAdjustmentTypeVO[];
}> = ({ entry, practiceAdjustments }) => {
  return (
    <EntryTable entry={entry} calculatedAdjustments={entry.appointmentAdjustments ?? []}>
      <EntryRow
        className="font-sansSemiBold"
        date={<DateWithTooltip date={entry.createdAt} dateAsSeconds={true} format="P" />}
        type={entry.entryReference.type === "APPOINTMENT" ? "Appt" : "Adjust"}
        description={
          entry.entryReference.type === "APPOINTMENT"
            ? entry.appointmentSnapshot?.providerName ?? ""
            : `${entry.adjustmentName ?? ""}: ${entry.adjustmentNote ?? ""}`
        }
        charges={entry.subtotalAmount}
        adjustments={(entry.appointmentAdjustments ?? []).map((a) => a.amount)}
        calculatedAmount={entry.amount}
      />
      {entry.lineItems.map((lineItem) => {
        const procedureAdjustments = getAdjustmentsByProcedureId(
          Number(lineItem.lineItemReference.id),
          entry.appointmentAdjustments ?? []
        );

        const lineItemDescription = lineItem.patientProcedureSnapshot
          ? getPatientProcedureDescription(lineItem.patientProcedureSnapshot)
          : "";

        return (
          <EntryRow
            key={`line-item-${invoiceEntryOrLineItemToId(lineItem)}`}
            date=""
            type={lineItem.patientProcedureSnapshot?.cdtCode}
            description={lineItemDescription}
            charges={lineItem.subtotalAmount}
            adjustments={procedureAdjustments.map((procAdjust) => procAdjust.amount)}
            calculatedAmount={lineItem.amount}
          />
        );
      })}
      {entry.appointmentAdjustments?.map((adjustment, i) => {
        const practiceAdjustment = getPracticeAdjustmentById(
          adjustment.customAdjustmentTypeId,
          practiceAdjustments ?? []
        );

        return (
          practiceAdjustments &&
          practiceAdjustments.length > 0 && (
            <AdjustmentRow
              isFirstAdjustment={i === 0}
              isLastAdjustment={i === (entry.appointmentAdjustments?.length ?? 0) - 1}
              adjustmentName={practiceAdjustment?.name ?? ""}
              adjustmentValue={formatCurrency(adjustment.amount)}
              totalAdjustments={entry.appointmentAdjustments?.length ?? 0}
            />
          )
        );
      })}
    </EntryTable>
  );
};
