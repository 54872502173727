import React, { FC } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { DashboardLayout } from "components/Dashboard/DashboardLayout";
import { SelectReportingQuestion } from "components/Dashboard/SelectReportingQuestion";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { useQueryParams } from "hooks/useQueryParams";
import { getTimeSeriesQuery } from "api/reporting/queries";
import { OutstandingCollectionsQuery } from "utils/routing/dashboard";
import { RecallReportTables } from "components/Dashboard/RecallReport/RecallReportTables";
import { RecallReportChart } from "components/Dashboard/RecallReport/RecallReportChart";
import { cxStyles } from "components/Dashboard/DashboardPageHeader";
import { useLongAgingOptions } from "components/Dashboard/hooks/useLongAgingOptions";
import { ACTIVE_PATIENT_STATUS_FILTER, NO_NEXT_APPT_FILTER } from "components/Dashboard/utils/filters";
import { RECALL_REPORT_AGING_OPTIONS } from "components/Dashboard/RecallReport/constants";

export const RecallReportRoute: FC = () => {
  const recallOverdueOptions = useLongAgingOptions({
    labels: RECALL_REPORT_AGING_OPTIONS,
    includeFuture: true,
  });
  const { query, updateQuery } = useQueryParams("dashboardRecall");

  const { practiceId } = useAccount();

  const [timeSeriesQuery] = useApiQueries([
    getTimeSeriesQuery({
      args: {
        practiceId,
        data: {
          resolution: "LONG_AGING" as const,
          filters: [NO_NEXT_APPT_FILTER, ACTIVE_PATIENT_STATUS_FILTER],

          metric: "recallPatientCount",
        },
      },
    }),
  ]);

  const handleRouteStateChange = React.useCallback(
    (updates: Partial<OutstandingCollectionsQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );

  return (
    <DashboardLayout
      className="min-w-[1150px]"
      header={
        <>
          <div className={cxStyles.pageHeader}>
            <SelectReportingQuestion question="recall" />
            <div className="flex items-center gap-4">
              <div className="text-sm font-sansSemiBold">Overdue By</div>
              <FormFieldSelect
                aria-label="Select Time Span"
                isClearable={false}
                className="min-w-48"
                isSearchable={false}
                options={recallOverdueOptions}
                value={query.dateAgeIndex}
                onChange={(option) => {
                  if (option) {
                    updateQuery("replaceIn", { dateAgeIndex: option.value });
                  }
                }}
              />
            </div>
          </div>

          {query["table.fullScreen"] ? null : (
            <RecallReportChart
              dateAgeIndex={query.dateAgeIndex}
              timeSeriesQuery={timeSeriesQuery}
              onBarClick={handleRouteStateChange}
            />
          )}
        </>
      }
    >
      <RecallReportTables />
    </DashboardLayout>
  );
};
