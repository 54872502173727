/* eslint-disable max-depth */
import { Sortable } from "react-sortablejs";
import {
  FormBooleanConditionalElementVO,
  FormElementVO,
  FormPageVO,
  FormRequest,
  FormSectionElementVO,
  FormSectionVO,
  FormVO,
} from "@libs/api/generated-api";
import { required } from "@libs/utils/validators";
import { ReactComponent as CalendarIcon } from "@libs/assets/icons/calendar.svg";
import { ReactComponent as MultipleChoiceIcon } from "@libs/assets/icons/checklist.svg";
import { ReactComponent as NumberIcon } from "@libs/assets/icons/numbers-input-form.svg";
import { ReactComponent as SectionIcon } from "@libs/assets/icons/section.svg";
import { ReactComponent as TextBlockIcon } from "@libs/assets/icons/multiline-text.svg";
import { ReactComponent as TextInputIcon } from "@libs/assets/icons/single-line-text-input.svg";
import { ReactComponent as YesNoIcon } from "@libs/assets/icons/true-false.svg";
import { ReactComponent as ConsentIcon } from "@libs/assets/icons/signature.svg";
import { ReactComponent as EditableTextBlockIcon } from "@libs/assets/icons/editable-note.svg";
import { ReactComponent as ListIcon } from "@libs/assets/icons/unordered-list.svg";
import { Draft, produce } from "immer";
import { CurrentElement, FormElement, FormElementRequest } from "components/Settings/Forms/types";

export const titleSchema = [{ $v: required, $error: "A title is required." }];

export const DRAG_FORM_ELEMENT_HANDLE = "drag-form-element-handle";
export const NESTED_SORT_CONTAINER_CLASSNAME = "nested-sort-container";

const isAddingAtLocation = (
  currentElement: CurrentElement | undefined,
  index: number,
  sectionIndex?: number
) => {
  if (currentElement?.flow !== "ADD") {
    return false;
  }

  return currentElement.index === index && currentElement.sectionIndex === sectionIndex;
};

export const getAddingElementAtLocation = (
  currentElement: CurrentElement | undefined,
  placeholderElement: CurrentElement | undefined,
  index: number,
  sectionIndex?: number
) => {
  if (isAddingAtLocation(placeholderElement, index, sectionIndex)) {
    return placeholderElement;
  }

  if (isAddingAtLocation(currentElement, index, sectionIndex)) {
    return currentElement;
  }

  return undefined;
};

export const isElementBeingAdded = (
  currentElement: CurrentElement | undefined,
  placeholderElement: CurrentElement | undefined
) => {
  return Boolean(currentElement?.flow === "ADD") || Boolean(placeholderElement?.flow === "ADD");
};

type ElementMatch = {
  type: "ELEMENT";
  element: FormElement;
  pageIndex: number;
  elementIndex: number;
};

type ConditionalElementMatch = {
  type: "CONDITIONAL_ELEMENT";
  parent: FormElement;
  element: FormBooleanConditionalElementVO;
  pageIndex: number;
  elementIndex: number;
};

type SectionElementMatch = {
  type: "SECTION_ELEMENT";
  section: FormSectionVO;
  element: FormSectionElementVO;
  pageIndex: number;
  elementIndex: number;
  sectionElementIndex: number;
};

type ConditionalSectionElementMatch = {
  type: "CONDITIONAL_SECTION_ELEMENT";
  section: FormSectionVO;
  parent: FormSectionElementVO;
  element: FormBooleanConditionalElementVO;
  pageIndex: number;
  elementIndex: number;
  sectionElementIndex: number;
};

const getMatch = (
  key: "uuid" | "tag",
  value: string,
  pageIndex: number,
  elementIndex: number,
  element: FormElement
): ElementMatch | ConditionalElementMatch | null => {
  if (element[key] === value) {
    return {
      type: "ELEMENT" as const,
      pageIndex,
      elementIndex,
      element,
    };
  }

  if ("conditionalElement" in element && element.conditionalElement?.[key] === value) {
    return {
      type: "CONDITIONAL_ELEMENT" as const,
      pageIndex,
      elementIndex,
      parent: element,
      element: element.conditionalElement,
    };
  }

  return null;
};

const getSectionMatch = (
  key: "uuid" | "tag",
  value: string,
  pageIndex: number,
  elementIndex: number,
  element: FormSectionElementVO,
  section: FormSectionVO,
  sectionElementIndex: number
): SectionElementMatch | ConditionalSectionElementMatch | null => {
  if (element[key] === value) {
    return {
      type: "SECTION_ELEMENT" as const,
      pageIndex,
      elementIndex,
      sectionElementIndex,
      element,
      section,
    };
  }

  if ("conditionalElement" in element && element.conditionalElement?.[key] === value) {
    return {
      type: "CONDITIONAL_SECTION_ELEMENT" as const,
      pageIndex,
      elementIndex,
      sectionElementIndex,
      element: element.conditionalElement,
      parent: element,
      section,
    };
  }

  return null;
};

type FindElementMatch =
  | ElementMatch
  | SectionElementMatch
  | ConditionalElementMatch
  | ConditionalSectionElementMatch
  | null;

export function findElement(form: FormVO, key: "uuid", value: string): FindElementMatch;
export function findElement(
  form: FormVO,
  key: "tag",
  value: NonNullable<FormElementVO["tag"]>
): FindElementMatch;
export function findElement(form: FormVO, key: "uuid" | "tag", value: string): FindElementMatch {
  for (const [pageIndex, page] of form.content.entries()) {
    for (const [elementIndex, element] of page.content.entries()) {
      const match = getMatch(key, value, pageIndex, elementIndex, element);

      if (match) {
        return match;
      }

      if (element.type !== "SECTION") {
        continue;
      }

      for (const [sectionElementIndex, sectionElement] of element.content.entries()) {
        const sectionElementMatch = getSectionMatch(
          key,
          value,
          pageIndex,
          elementIndex,
          sectionElement,
          element,
          sectionElementIndex
        );

        if (sectionElementMatch) {
          return sectionElementMatch;
        }
      }
    }
  }

  return null;
}

export const findElementsByType = <T extends FormElement["type"]>(form: FormVO, type: T) => {
  const elements: FormElement[] = [];

  for (const page of form.content) {
    for (const element of page.content) {
      if (element.type === type) {
        elements.push(element);
      }

      if (element.type !== "SECTION") {
        continue;
      }

      for (const sectionElement of element.content) {
        if (sectionElement.type === type) {
          elements.push(sectionElement);
        }
      }
    }
  }

  return elements as Extract<FormElement, { type: T }>[];
};

type GetFormRequestProps<M extends FormElementRequest, K> = M extends { type: K } ? M : never;

type MapFormElementRequests<K extends string> = {
  [I in K]: GetFormRequestProps<FormElementRequest, I>;
};

const FormElementRequestMap: MapFormElementRequests<FormElementRequest["type"]> = {
  DATE_INPUT: { type: "DATE_INPUT", settings: [], title: "" },
  SELECT_INPUT: { type: "SELECT_INPUT", settings: [], options: [], title: "" },
  NUMBER_INPUT: { type: "NUMBER_INPUT", title: "", settings: [] },
  TEXT_INPUT: { type: "TEXT_INPUT", settings: [], title: "" },
  BOOLEAN_INPUT: { type: "BOOLEAN_INPUT", title: "", settings: [] },
  SECTION: { type: "SECTION", title: "", content: [] },
  TEXT_BLOCK: { type: "TEXT_BLOCK", title: "", content: "" },
  CONSENT: { type: "CONSENT", title: "", content: "", statement: "" },
  EDITABLE_TEXT_BLOCK: { type: "EDITABLE_TEXT_BLOCK", title: "", content: "" },
  PROCEDURE_TABLE: { type: "PROCEDURE_TABLE", title: "", settings: [] },
  APPOINTMENT_DETAILS: { type: "APPOINTMENT_DETAILS", title: "" },
};

export function getFormElement<K extends FormElementRequest["type"]>(
  type: K
): (typeof FormElementRequestMap)[K] {
  return FormElementRequestMap[type];
}

export const isDraggingWithingSection = (e: Sortable.MoveEvent) =>
  e.related.classList.contains(NESTED_SORT_CONTAINER_CLASSNAME) ||
  e.related.parentElement?.classList.contains(NESTED_SORT_CONTAINER_CLASSNAME);

export const PaletteMap = {
  DATE_INPUT: {
    title: "Date",
    description: "Capture a date",
    element: getFormElement("DATE_INPUT"),
    Icon: CalendarIcon,
  },
  SELECT_INPUT: {
    title: "Multiple Choice",
    description: "Allow a user to select from a range of options",
    element: getFormElement("SELECT_INPUT"),
    Icon: MultipleChoiceIcon,
  },
  NUMBER_INPUT: {
    title: "Number",
    description: "Capture a number. You can set a minimum or maximum",
    element: getFormElement("NUMBER_INPUT"),
    Icon: NumberIcon,
  },
  TEXT_INPUT: {
    title: "Text Input",
    description: "Capture a written response",
    element: getFormElement("TEXT_INPUT"),
    Icon: TextInputIcon,
  },
  BOOLEAN_INPUT: {
    title: "Yes or No",
    description: "Capture a yes or no response.",
    element: getFormElement("BOOLEAN_INPUT"),
    Icon: YesNoIcon,
  },
  CONSENT: {
    title: "Consent",
    description: "Request consent to something with a signature or initials",
    element: getFormElement("CONSENT"),
    Icon: ConsentIcon,
  },
  SECTION: {
    title: "Section",
    description: "Group a set of questions together and show them conditionally",
    element: getFormElement("SECTION"),
    Icon: SectionIcon,
  },
  TEXT_BLOCK: {
    title: "Text Block",
    description: "Include a block of written content in your form",
    element: getFormElement("TEXT_BLOCK"),
    Icon: TextBlockIcon,
  },
  EDITABLE_TEXT_BLOCK: {
    title: "Editable Note",
    description: "Prefilled note template which can include smart variables",
    element: getFormElement("EDITABLE_TEXT_BLOCK"),
    Icon: EditableTextBlockIcon,
  },
  PROCEDURE_TABLE: {
    title: "Procedure Table",
    description: "Displays a table of the proposed procedures",
    element: getFormElement("PROCEDURE_TABLE"),
    Icon: ListIcon,
  },
  APPOINTMENT_DETAILS: {
    title: "Appointment Details",
    description: "Displays the appointment details",
    element: getFormElement("APPOINTMENT_DETAILS"),
    Icon: ListIcon,
  },
};

const clearElementUuids = (element: Draft<FormSectionElementVO> | Draft<FormPageVO["content"][number]>) => {
  const deleteUuid = (elementWithUuid?: { uuid: string }) => {
    if (elementWithUuid) {
      delete (elementWithUuid as { uuid?: string }).uuid;
    }
  };

  deleteUuid(element);

  if ("conditionalElement" in element) {
    deleteUuid(element.conditionalElement);
  }
};

const clearUuids = (content: Draft<FormPageVO>[]) => {
  for (const page of content) {
    for (const element of page.content) {
      clearElementUuids(element);

      if (element.type === "SECTION") {
        for (const sectionElement of element.content) {
          clearElementUuids(sectionElement);
        }
      }
    }
  }
};

export const getFormUpdateRequest = (form: FormVO): FormRequest => {
  const { dentalProcedures, clinicalNoteData } = form;
  // FormVO has dentalProcedures as non-nullable DentalProcedureVO[], but
  // FormRequest requires dentalProcedureIds as number[]. After updating the
  // cached FormVO, we make dentalProcedures nullable in order to allow its
  // deletion; and produce the rest of the data for the FormRequest, while
  // mapping dentalProcedures to dentalProcedureIds, and similarly for providers
  // and appointmentCategories within clinicalNoteData.
  const updatedForm = produce(
    form as MakeKeysNullable<FormVO, "dentalProcedures" | "uuid" | "state">,
    (draft) => {
      delete draft.dentalProcedures;
      delete draft.clinicalNoteData;
    }
  );

  return produce(updatedForm as FormRequest, (draft) => {
    draft.dentalProcedureIds = dentalProcedures.map((dp) => dp.id);

    if (clinicalNoteData) {
      draft.clinicalNoteData = {
        providerIds: clinicalNoteData.providers.map((p) => p.id),
        appointmentCategoryIds: clinicalNoteData.appointmentCategories.map((ac) => ac.id),
      };
    }
  });
};

export const getFormDuplicateRequest = (form: FormVO): FormRequest => {
  const updateRequest = getFormUpdateRequest(form) as MakeKeysNullable<
    FormVO,
    "dentalProcedures" | "uuid" | "state"
  >;

  return produce(updateRequest, (draft) => {
    delete draft.uuid;
    delete draft.state;
    clearUuids(draft.content);
  }) as FormRequest;
};
