import { useId } from "react";
import { ContactVO } from "@libs/api/generated-api";
import { useFormContext } from "@libs/contexts/FormContext";
import { FormField } from "@libs/components/UI/FormField";
import { PatientTag } from "components/PatientProfile/PatientOverview/Info/PatientTag";
import { getRelationLabel } from "components/PatientProfile/PatientOverview/utils";

interface Props {
  contact: ContactVO | undefined;
  patientFirstName: string;
}

export const ContactTagFormField: React.FC<Props> = ({ contact, patientFirstName }) => {
  const id = useId();

  const formContext = useFormContext();

  return (
    <FormField edit={false} id={id} label="Contact Person" layout={formContext.fieldLayout}>
      {contact?.relation && contact.relation !== "SELF" ? (
        <PatientTag
          name={contact.name}
          age={contact.age}
          gender={contact.gender}
          relationLabel={getRelationLabel(contact.relation, patientFirstName)}
        />
      ) : (
        "-"
      )}
    </FormField>
  );
};
