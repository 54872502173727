import { useDebouncedCallback } from "use-debounce";
import { EditorState, LexicalEditor } from "lexical";
import { getContent } from "components/UI/RichTextEditor/utils";

const EDITOR_STATE_DEBOUNCE_DELAY = 500;

export type DebouncedEditorStateChangeHandler = (updates: {
  editorState: EditorState;
  editor: LexicalEditor;
  content: string;
}) => void;
export const useDebouncedOnEditorStateChange = (onChange: DebouncedEditorStateChangeHandler) => {
  return useDebouncedCallback(async (editorState: EditorState, editor: LexicalEditor) => {
    onChange({ editorState, editor, content: await getContent(editorState) });
  }, EDITOR_STATE_DEBOUNCE_DELAY);
};
