import { FC, useState } from "react";
import { ButtonLink } from "@libs/components/UI/ButtonLink";
import { Button } from "@libs/components/UI/Button";

export const InstallTwainDrivers: FC<{ downloadUrl: string; isAISWhitelisted?: boolean }> = ({
  downloadUrl,
  isAISWhitelisted,
}) => {
  const [installClicked, setInstallClicked] = useState(false);
  const reloadContent = (
    <div>
      <Button theme="link" onClick={() => location.reload()}>
        Reload this page
      </Button>{" "}
      after the TWAIN drivers are installed.
    </div>
  );

  return (
    <div className="flex flex-col items-center py-9 gap-y-2">
      {isAISWhitelisted ? (
        installClicked ? (
          reloadContent
        ) : (
          <ButtonLink
            theme="link"
            href={downloadUrl}
            className="text-sm"
            onClick={() => setInstallClicked(true)}
          >
            ...or Install Dynamsoft TWAIN drivers
          </ButtonLink>
        )
      ) : (
        <>
          <div className="font-sansSemiBold text-sm">Get Started</div>
          {installClicked ? (
            reloadContent
          ) : (
            <ButtonLink theme="link" href={downloadUrl} onClick={() => setInstallClicked(true)}>
              Download and install TWAIN drivers
            </ButtonLink>
          )}
        </>
      )}
    </div>
  );
};
