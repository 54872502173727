import React from "react";
import { PaymentVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { Spinner } from "@libs/components/UI/Spinner";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import { SummaryBanner, SummaryCell } from "components/PatientProfile/Billing/SummaryBanner";
import { getPaymentMethodToLabel } from "components/PatientProfile/Billing/billingUtils";

interface Props {
  payment: PaymentVO;
}

const PENDING_RECEIPT_NUMBER = "PENDING";

export const InvoicePaymentReceiptSummary: React.FC<Props> = ({ payment }) => {
  const hasSurcharge = payment.surchargeAmount !== 0;

  return (
    <SummaryBanner>
      <SummaryCell header="Receipt #">
        <div className="flex items-center gap-x-2">
          {payment.receiptNumber}
          {payment.receiptNumber === PENDING_RECEIPT_NUMBER && (
            <Spinner animation="border" variant="secondary" size="sm" />
          )}
        </div>
      </SummaryCell>
      <SummaryCell header="Date Paid">
        <DateWithTooltip date={payment.paymentCreatedAt} dateAsSeconds={true} format="P" />
      </SummaryCell>

      <SummaryCell header="Payment Method">{getPaymentMethodToLabel(payment)}</SummaryCell>
      {hasSurcharge ? (
        <>
          <SummaryCell header="Subtotal" className="text-right">
            {formatCurrency(payment.currencyAmount.amount)}
          </SummaryCell>
          <SummaryCell header="Surcharge" className="text-right">
            {formatCurrency(payment.surchargeAmount)}
          </SummaryCell>
          <SummaryCell header="Total" className="text-right">
            {formatCurrency(payment.totalPaymentAmount)}
          </SummaryCell>
        </>
      ) : (
        <SummaryCell header="Amount" className="text-right">
          {formatCurrency(payment.currencyAmount.amount)}
        </SummaryCell>
      )}
    </SummaryBanner>
  );
};
