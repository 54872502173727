import { FC } from "react";
import { FormEditableTextBlockElementRequest, TemplateVariableGroupVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { FormWrapper } from "components/Settings/Forms/FormWrapper";
import { EditElementFormProps } from "components/Settings/Forms/types";
import {
  editableTextBlockSchema,
  useEditableTextBlockState,
} from "components/Settings/Forms/EditableTextBlock/schema";
import { EditableTextBlockFormContent } from "components/Settings/Forms/EditableTextBlock/FormContent";

interface Props extends EditElementFormProps<FormEditableTextBlockElementRequest> {
  templateVariables?: TemplateVariableGroupVO[];
}

export const EditableTextBlockForm: FC<Props> = ({
  element,
  isEditing,
  templateVariables,
  onInvalid,
  onDraftChange,
  onSubmit,
  onSecondaryClick,
}) => {
  const state = useEditableTextBlockState(element);
  const { draft } = state[0];

  const validation = useValidation(draft, editableTextBlockSchema);

  const handleSubmit = () => {
    onSubmit(draft);
    validation.reset();
  };

  return (
    <FormWrapper
      element={draft}
      contentClassName="h-full flex flex-col"
      isEditing={isEditing}
      onValidate={() => validation.validate().$isValid}
      onDraftChange={onDraftChange}
      onInvalid={onInvalid}
      onSubmit={handleSubmit}
      onCancel={onSecondaryClick}
    >
      <EditableTextBlockFormContent
        templateVariables={templateVariables}
        formState={state}
        validation={validation.result}
      />
    </FormWrapper>
  );
};
