import { FC, FormEventHandler, useState, useMemo, useCallback, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { produce } from "immer";

import {
  MessageCampaignVO,
  MessageCampaignSummaryVO,
  TemplateVariableGroupVO,
  PatientListCriteria,
  InsuranceCarrierVO,
  UpdateMessageCampaignRequest,
} from "@libs/api/generated-api";
import { getLocalDate, formatAsISODate } from "@libs/utils/date";
import { useValidation } from "@libs/hooks/useValidation";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getQueryKey } from "@libs/utils/queries";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { Form } from "@libs/components/UI/Form";
import { Banner } from "@libs/components/UI/Banner";
import { FormFieldTemplateVariablesInput } from "components/UI/FormFieldTemplateVariablesInput";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { TimeInput } from "components/UI/TimeInput";
import { ToggleGroup } from "components/UI/ToggleGroup";

import { FormSection } from "components/Communications/Campaigns/Campaign/FormSection";
import { CampaignResults } from "components/Communications/Campaigns/Campaign/CampaignResults";
import { CampaignFilters } from "components/Communications/Campaigns/Campaign/CampaignFilters";
import { CampaignFiltersBanner } from "components/Communications/Campaigns/Campaign/CampaignFiltersBanner";
import { FiltersFlyover } from "components/Communications/Filters/FiltersFlyover";

import {
  getInitialCampaignFormState,
  getCampaignFormSchema,
  getScheduledAtUnixTime,
  getUpdateEmailCampaignRequest,
  hasScheduledAtInPast,
} from "components/Communications/Campaigns/Campaign/utils";

interface Props {
  formId: string;
  campaign: MessageCampaignVO;
  summary: MessageCampaignSummaryVO | undefined;
  templateVariables: TemplateVariableGroupVO[];
  patientListCriteria: PatientListCriteria | undefined;
  patientsFiltered: number | undefined;
  insuranceCarriers: InsuranceCarrierVO[];
  onToggleSendNow: Func;
  onSaveEmailCampaign: (data: UpdateMessageCampaignRequest, options?: { onSuccess?: Func }) => void;
  onTriggerEmailCampaign: Func;
  hasNoFiltersApplied: boolean;
  isApplyingFilters: boolean;
  isEditable: boolean;
  isSendNow: boolean;
}

const EMPTY_SEND_TIME_ERROR = "ERROR";

// eslint-disable-next-line complexity
export const CampaignForm: FC<Props> = ({
  formId,
  campaign,
  summary,
  templateVariables,
  patientListCriteria,
  patientsFiltered,
  insuranceCarriers,
  onToggleSendNow,
  onSaveEmailCampaign,
  onTriggerEmailCampaign,
  hasNoFiltersApplied,
  isApplyingFilters,
  isEditable,
  isSendNow,
}) => {
  const practice = useCurrentPractice();
  const [campaignForm, setCampaignForm] = useState(() =>
    getInitialCampaignFormState(campaign, practice.timezoneId)
  );
  const filtersFlyover = useBoolean(false);
  const queryClient = useQueryClient();

  const { draftScheduledAt, hasSendTimeInPast } = useMemo(() => {
    const scheduledAt =
      !isSendNow && campaignForm.scheduledDate && campaignForm.scheduledTime
        ? getScheduledAtUnixTime(campaignForm.scheduledDate, campaignForm.scheduledTime, practice.timezoneId)
        : undefined;
    const isInPast = !isSendNow && scheduledAt ? hasScheduledAtInPast(scheduledAt) : false;

    return {
      draftScheduledAt: scheduledAt,
      hasSendTimeInPast: isInPast,
    };
  }, [isSendNow, campaignForm.scheduledDate, campaignForm.scheduledTime, practice.timezoneId]);

  const sendTimeError = hasSendTimeInPast ? EMPTY_SEND_TIME_ERROR : undefined;

  const { validate, result: validationResult } = useValidation(
    {
      name: campaignForm.name,
      template: campaignForm.template,
    },
    getCampaignFormSchema()
  );

  const handleTriggerCampaign: FormEventHandler = useCallback(
    (e) => {
      e.preventDefault();

      onTriggerEmailCampaign();
    },
    [onTriggerEmailCampaign]
  );

  const handleUpdateFilterCriteria = useCallback(
    (criteria: PatientListCriteria, options?: { onSuccess?: Func }) => {
      const updatedCampaign = produce(campaign, (draft) => {
        if (draft.filter && draft.filter.type === "PATIENT_LIST") {
          draft.filter.patientListCriteria = criteria;
          // Ensure delete of deprecated appointmentCriteria
          delete draft.filter.patientListCriteria.appointmentCriteria;
        }
      });

      onSaveEmailCampaign(getUpdateEmailCampaignRequest(updatedCampaign), {
        onSuccess: () => {
          queryClient.invalidateQueries([
            getQueryKey("practices", "getMessageCampaign"),
            { practiceId: practice.id, messageCampaignUuid: campaign.uuid },
          ]);
          options?.onSuccess?.();
          filtersFlyover.off();
        },
      });
    },
    [campaign, onSaveEmailCampaign, queryClient, practice.id, filtersFlyover]
  );

  const handleUpdateCampaign = useCallback(
    (updates: Partial<Pick<MessageCampaignVO, "name" | "template" | "scheduledAt">>) => {
      if (!validate().$isValid) {
        return;
      }

      const updatedCampaign = produce(campaign, (draft) => {
        for (const key in updates) {
          switch (key) {
            case "name": {
              draft.name = updates.name;
              break;
            }
            case "template": {
              draft.template = updates.template as string;
              break;
            }
            case "scheduledAt": {
              draft.scheduledAt = updates.scheduledAt;
              break;
            }
            default: {
              break;
            }
          }
        }
      });

      onSaveEmailCampaign(getUpdateEmailCampaignRequest(updatedCampaign));
    },
    [campaign, onSaveEmailCampaign, validate]
  );

  // Auto-update campaign name and template when campaign form state has changed
  useEffect(() => {
    if (campaignForm.name !== campaign.name || campaignForm.template !== campaign.template) {
      handleUpdateCampaign({ name: campaignForm.name, template: campaignForm.template });
    }
  }, [campaign.name, campaign.template, campaignForm.name, campaignForm.template, handleUpdateCampaign]);

  // Auto-update campaign scheduledAt when campaign form state has changed, and
  // scheduledAt is defined with both draft date and time
  useEffect(() => {
    if (!isSendNow && draftScheduledAt && draftScheduledAt !== campaign.scheduledAt && !hasSendTimeInPast) {
      handleUpdateCampaign({ scheduledAt: draftScheduledAt });
    } else if (isSendNow && campaign.scheduledAt) {
      handleUpdateCampaign({ scheduledAt: undefined });
    }
  }, [isSendNow, campaign.scheduledAt, draftScheduledAt, hasSendTimeInPast, handleUpdateCampaign]);

  return (
    <div
      className={`
        flex
        flex-col
        items-center
        justify-center
        p-6
        min-w-[22rem]
        min-h-full
      `}
    >
      <Form id={formId} className="flex flex-col gap-y-6 max-w-xl" onSubmit={handleTriggerCampaign}>
        <FormFieldInput
          className="flex flex-col gap-y-2"
          label="Campaign Name"
          value={campaignForm.name}
          onChange={(e) => setCampaignForm((last) => ({ ...last, name: e.target.value }))}
          error={validationResult.name.$error}
          disabled={!isEditable || hasSendTimeInPast}
          required
        />

        <FormFieldTemplateVariablesInput
          className="flex flex-col gap-y-2"
          label="Subject Line"
          value={campaignForm.template}
          templateVariables={templateVariables}
          onUpdateTemplate={(template) => setCampaignForm((last) => ({ ...last, template }))}
          error={validationResult.template.$error}
          disabled={!isEditable || hasSendTimeInPast}
          required
        />

        <FormSection
          title="Send Time"
          subTitle="Will send using the practice timezone."
          error={hasSendTimeInPast}
          disabled={!isEditable}
          required
        >
          <div
            className={`
              grid
              xl:grid-rows-1
              xl:grid-cols-[auto,auto,1fr]
              items-center
              gap-3
            `}
          >
            <ToggleGroup
              listClassName="min-w-full xl:min-w-fit"
              optionClassName="flex-1"
              selectedValue={isSendNow}
              options={[
                { label: "Send Now", value: true },
                { label: "Scheduled", value: false },
              ]}
              onChange={onToggleSendNow}
              disabled={!isEditable}
              size="md"
            />

            <VerticalDivider className="hidden xl:block" size="lg" />

            <div className="flex items-center gap-x-3">
              <FormFieldSelectMenusDatepicker
                selected={campaignForm.scheduledDate ? getLocalDate(campaignForm.scheduledDate) : undefined}
                onChange={(date) =>
                  setCampaignForm((last) => ({
                    ...last,
                    scheduledDate: date ? formatAsISODate(date) : undefined,
                  }))
                }
                error={sendTimeError}
                displayErrorMessage={false}
                disabled={!isEditable || isSendNow}
                required={!isSendNow}
              />

              <TimeInput
                layout="labelOut"
                value={campaignForm.scheduledTime}
                onChange={(val) => setCampaignForm((last) => ({ ...last, scheduledTime: val }))}
                error={sendTimeError}
                displayErrorMessage={false}
                disabled={!isEditable || isSendNow}
                required={!isSendNow}
              />
            </div>
          </div>
        </FormSection>

        {isEditable && hasSendTimeInPast ? (
          <Banner aria-label="Scheduled In Past" className="rounded text-xs" theme="warning">
            This send time is in the past. You can update the send time or set it to send now.
          </Banner>
        ) : null}

        <FormSection
          title="Send To"
          subTitle="This campaign will send to all patients who match the selected filters at the time of sending."
          disabled={!isEditable || hasSendTimeInPast}
          required
        >
          <CampaignFilters
            patientListCriteria={patientListCriteria}
            insuranceCarriers={insuranceCarriers}
            onOpenFilters={filtersFlyover.on}
            onRemoveFilter={handleUpdateFilterCriteria}
            disabled={!isEditable || hasSendTimeInPast}
          />
        </FormSection>

        {campaign.status === "SENT" && summary ? (
          <FormSection title="Results">
            <CampaignResults summary={summary} />
          </FormSection>
        ) : (
          <CampaignFiltersBanner
            campaignUuid={campaign.uuid}
            patientsFiltered={patientsFiltered}
            hasNoFiltersApplied={hasNoFiltersApplied}
            isLoading={isApplyingFilters}
          />
        )}
      </Form>

      {filtersFlyover.isOn && patientListCriteria ? (
        <FiltersFlyover
          patientListCriteria={patientListCriteria}
          isApplyingFilters={isApplyingFilters}
          onApplyFilters={handleUpdateFilterCriteria}
          onClose={filtersFlyover.off}
        />
      ) : null}
    </div>
  );
};
