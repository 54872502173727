import { FormEventHandler, useCallback, useEffect, useMemo, useState } from "react";
import {
  PaymentCreationRequest,
  PaymentDeviceVO,
  PaymentProfileVO,
  PaymentVO,
  WalletTopupRequest,
} from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { greaterThan, maxLength, required } from "@libs/utils/validators";
import { isOneOf } from "@libs/utils/isOneOf";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { MutationResult } from "@libs/@types/apiMutations";
import { useDebouncedCallback } from "use-debounce";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useObjectState } from "@libs/hooks/useObjectState";
import { getUnixTime } from "date-fns";
import { getPayment } from "api/billing/queries";
import { topupWallet } from "api/billing/mutations";
import { handleError } from "utils/handleError";
import { useNotificationContext } from "contexts/NotificationsContext";
import {
  getPaymentButtonLabel,
  getPreferredPaymentMethod,
} from "components/PatientProfile/Billing/billingUtils";
import { NOTE_MAX_LENGTH } from "components/PatientProfile/Billing/FormComponents";
import {
  CollectionPaymentMethod,
  getPaymentMethodOptions,
} from "components/PatientProfile/Billing/PaymentMethods/utils";
import { WALLET_NOTE_MAX_LENGTH } from "components/PatientProfile/Billing/constants";
import { PaymentDraft } from "components/PatientProfile/Billing/Payment/useCollectPaymentForm";

const DEBOUNCE_UPDATE_MS = 800;

type WalletTopupDraft = PaymentDraft & {
  walletNote: string;
};

const getWalletValidationSchema = (draft: WalletTopupDraft) => {
  return {
    walletNote: {
      $validations: [
        { $v: required, $error: "A description is required." },
        {
          $v: maxLength(WALLET_NOTE_MAX_LENGTH),
          $error: `Description cannot exceed ${WALLET_NOTE_MAX_LENGTH} characters.`,
        },
      ],
    },
    note: {
      $validations: [
        {
          $v: maxLength(NOTE_MAX_LENGTH),
          $error: `Note cannot exceed ${NOTE_MAX_LENGTH} characters.`,
        },
      ],
    },
    checkPayload: {
      $ignore: draft.paymentMethod !== "CHECK",
      bankName: { $validations: [{ $v: required, $error: "A bank name is required." }] },
      checkNumber: { $validations: [{ $v: required, $error: "A check number is required." }] },
    },
    paymentAmount: {
      $validations: [{ $v: greaterThan(0), $error: "Amount should be greater than 0." }],
    },
    paymentDate: {
      $ignore: !isOneOf(draft.paymentMethod, ["CASH", "CHECK"]),
      $validations: [{ $v: required, $error: "A payment date is required." }],
    },
    selectedPaymentProfileUuid: {
      $ignore: draft.paymentMethod !== "STORED_PROFILE",
      $validations: [{ $v: required, $error: "Please select a credit card." }],
    },
    selectedPaymentPosUuid: {
      $ignore: draft.paymentMethod !== "STORED_POS",
      $validations: [{ $v: required, $error: "Please select a payment device." }],
    },
    externalPosPayload: {
      $ignore: draft.paymentMethod !== "EXTERNAL_POS",
      cardLastFour: {
        $validations: [
          { $v: required, $error: "The card's last four digits used for the payment is required." },
        ],
      },
      nameOnCard: {
        $validations: [{ $v: required, $error: "The name on card used for the payment is required." }],
      },
    },
  };
};

const getInitialWalletDraft = ({
  paymentDevices,
  paymentProfiles,
}: {
  paymentProfiles: PaymentProfileVO[];
  paymentDevices: PaymentDeviceVO[];
}): WalletTopupDraft => {
  return {
    fullOrPartial: "FULL",
    paymentMethod: getPreferredPaymentMethod({
      isRefund: false,
      hasPaymentProfiles: Boolean(paymentProfiles.length),
      hasWalletBalance: false,
    }),
    paymentAmount: 0,
    paymentDate: new Date(),
    // Pre-select the default payment profile.
    selectedPaymentProfileUuid: paymentProfiles.find((profile) => profile.isDefault)?.uuid,
    // Pre-select the default POS device.
    selectedPaymentPosUuid: paymentDevices[0]?.uuid,
    note: "",
    walletNote: "",
  };
};

const getWalletTopupRequest = (
  draft: Omit<WalletTopupDraft, "paymentAmount"> & { paymentAmount: number }
): WalletTopupRequest => {
  const paymentRequest: PaymentCreationRequest = {
    type: "CHARGE",
    method: draft.paymentMethod as PaymentCreationRequest["method"],
    currencyAmount: {
      currency: "USD",
      amount: draft.paymentAmount,
    },
    idempotencyUuid: crypto.randomUUID(),
    note: draft.note,
  };

  if (
    draft.paymentDate &&
    isOneOf(draft.paymentMethod, [
      "CARE_CREDIT",
      "EXTERNAL_POS",
      "EFT",
      "THIRD_PARTY_FINANCING",
      "CASH",
      "CHECK",
    ])
  ) {
    paymentRequest.paymentCreatedAt = getUnixTime(draft.paymentDate);
  }

  switch (draft.paymentMethod) {
    case "CHECK": {
      paymentRequest.checkPayload = draft.checkPayload;
      break;
    }
    case "EFT": {
      paymentRequest.eftPayload = draft.eftPayload;
      break;
    }
    case "EXTERNAL_POS": {
      paymentRequest.externalPosPayload = draft.externalPosPayload;
      break;
    }
    case "STORED_PROFILE": {
      paymentRequest.paymentProfileUuid = draft.selectedPaymentProfileUuid;
      break;
    }
    case "STORED_POS": {
      paymentRequest.paymentDeviceUuid = draft.selectedPaymentPosUuid;
      break;
    }
    case "WALLET": {
      paymentRequest.walletUuid = draft.selectedWalletUuid;
      break;
    }
    default: {
      break;
    }
  }

  return {
    paymentCreationRequest: paymentRequest,
    note: draft.walletNote,
  };
};

export const useAddFundsToWalletForm = ({
  patientId,
  practiceId,
  paymentProfiles,
  paymentDevices,
  topupWalletMutate,
  onPaymentCollected,
}: {
  patientId: number;
  onPaymentCollected: (payment: PaymentVO) => void;
  paymentProfiles: PaymentProfileVO[];
  paymentDevices: PaymentDeviceVO[];
  practiceId: number;
  topupWalletMutate: MutationResult<typeof topupWallet>["mutate"];
}) => {
  const debouncing = useBoolean(false);
  const setDebouncing = debouncing.set;
  const notifications = useNotificationContext();
  const [paymentUuid, setPaymentUuid] = useState("");
  const [paymentQuery] = useApiQueries([
    getPayment({ args: { paymentUuid, practiceId }, queryOptions: { enabled: Boolean(paymentUuid) } }),
  ]);
  const [isPaying, setIsPaying] = useState(false);

  const [walletTopupDraft, updateWalletTopupDraft] = useObjectState<WalletTopupDraft>(() =>
    getInitialWalletDraft({ paymentDevices, paymentProfiles })
  );
  const handleUpdateWalletTopupDraft = useCallback(
    (updates: Partial<PaymentDraft>) => {
      setDebouncing(false);
      updateWalletTopupDraft(updates);
    },
    [setDebouncing, updateWalletTopupDraft]
  );

  const debouncedUpdateWallet = useDebouncedCallback(handleUpdateWalletTopupDraft, DEBOUNCE_UPDATE_MS, {
    leading: true,
    trailing: true,
  });
  const updateWalletWithDebounceState = useCallback(
    (updates: Partial<PaymentDraft>) => {
      setDebouncing(true);
      debouncedUpdateWallet(updates);
    },
    [debouncedUpdateWallet, setDebouncing]
  );

  const handlePaymentMethodChange = (newMethod: CollectionPaymentMethod) => {
    if (newMethod !== "REFUNDABLE_CARD") {
      updateWalletTopupDraft({
        paymentMethod: newMethod,
      });
    }
  };

  const paymentMethodOptions = useMemo(
    () =>
      getPaymentMethodOptions({
        showWallet: false,
        showCreditCard: Boolean(paymentProfiles.length > 0),
      }),
    [paymentProfiles]
  );

  const validation = useValidation(walletTopupDraft, getWalletValidationSchema(walletTopupDraft));

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();

    const paymentAmount = walletTopupDraft.paymentAmount;

    const result = validation.validate();

    if (!paymentAmount || !result.$isValid) {
      return;
    }

    const topupPayload = getWalletTopupRequest({ ...walletTopupDraft, paymentAmount });

    setIsPaying(true);
    topupWalletMutate(
      { patientId, practiceId, data: topupPayload },
      {
        onError: (err) => {
          setIsPaying(false);
          handleError(err);
        },
        onSuccess: (response) => {
          response.data.data.payment && setPaymentUuid(response.data.data.payment.uuid);
        },
      }
    );
  };

  useEffect(() => {
    if (!paymentQuery.data) {
      return;
    }

    if (isOneOf(paymentQuery.data.state, ["SETTLED"])) {
      notifications.handleSuccess("Payment was successful.");
      setIsPaying(false);
      onPaymentCollected(paymentQuery.data);
    } else if (isOneOf(paymentQuery.data.state, ["DENIED", "VOID"])) {
      notifications.handleError("Payment failed.");
      setIsPaying(false);
    }
  }, [notifications, onPaymentCollected, paymentQuery.data]);

  return {
    patientId,
    paymentProfiles,
    paymentDevices,
    paymentMethodOptions,
    walletTopupDraft,
    validation,
    isPaying,
    paymentButtonLabel: getPaymentButtonLabel({
      isProcessing: isPaying,
      paymentMethod: walletTopupDraft.paymentMethod,
      paymentType: "CHARGE",
    }),
    isEditingPayment: debouncing.isOn,
    debouncedUpdateWallet: updateWalletWithDebounceState,
    handleWalletDraftChange: updateWalletTopupDraft,
    handlePaymentMethodChange,
    handleSubmit,
  };
};
