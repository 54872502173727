import { createContext, useContext, useState, useMemo, PropsWithChildren } from "react";
import { noop } from "@libs/utils/noop";

interface AttachmentsViewContextValue {
  activeTabIndex: number;
  isInReview: boolean;
  setActiveTabIndex: (index: number) => void;
  setIsInReview: (inReview: boolean) => void;
}

const Context = createContext<AttachmentsViewContextValue>({
  activeTabIndex: 0,
  isInReview: false,
  setActiveTabIndex: noop,
  setIsInReview: noop,
});

Context.displayName = "AttachmentsViewContext";

export const useAttachmentsView = () => useContext(Context);

export const AttachmentsViewProvider: React.FC<PropsWithChildren> = ({ children }) => {
  const [activeTabIndex, setActiveTabIndex] = useState<number>(0);
  const [isInReview, setIsInReview] = useState(false);

  const value = useMemo(
    () => ({
      activeTabIndex,
      isInReview,
      setActiveTabIndex,
      setIsInReview,
    }),
    [activeTabIndex, isInReview]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
