import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { FC, useCallback, useEffect, useRef } from "react";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { usePortalElement } from "@libs/contexts/PortalContext";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { createPortal } from "react-dom";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@libs/utils/queries";
import { useNavigate } from "react-router-dom";
import { SECOND_IN_MS } from "@libs/utils/date";
import { useDebouncedCallback } from "use-debounce";
import { manuallyLoadFromServer } from "@libs/utils/queryCache";
import { Button } from "@libs/components/UI/Button";
import { useBoolean } from "@libs/hooks/useBoolean";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useAccount } from "@libs/contexts/AccountContext";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { getFinixOnboardingFormUrl } from "api/billing/queries";
import { FinixIframeAction } from "utils/iframeMessages";
import { paths } from "utils/routing/paths";
import { usePracticeLogger } from "components/Main/PracticeSegmentContext";

const isFinxiIframeMessageEvent = (
  iframe: HTMLIFrameElement,
  e: MessageEvent<unknown>
): e is MessageEvent<FinixIframeAction> => {
  return iframe.contentWindow === e.source;
};

export const BillingMerchantSetupFinixRoute: FC = () => {
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { track } = usePracticeLogger();
  const { practiceId } = useAccount();
  const isIframeLoaded = useBoolean(false);

  const currentPortal = usePortalElement();
  const [finixOnboardingFormUrlQuery] = useApiQueries([
    // we only ever want to fetch the form once when the page loads to avoid the form
    // from reloading.
    getFinixOnboardingFormUrl({ args: { practiceId }, queryOptions: manuallyLoadFromServer }),
  ]);
  const iframeRef = useRef<HTMLIFrameElement | null>(null);

  // add a delay because even though iframe onLoad event fires
  // the iframe content takes a little longer to load.
  // This makes the UX smoother.
  const handleLoad = useDebouncedCallback(() => {
    isIframeLoaded.on();
  }, SECOND_IN_MS);

  const handleClose = useCallback(() => {
    navigate(paths.settingsSection({ section: "billing-merchant" }), { replace: true });
  }, [navigate]);

  useEffect(() => {
    const handleMessage = (e: MessageEvent) => {
      if (iframeRef.current && isFinxiIframeMessageEvent(iframeRef.current, e)) {
        if (e.data.type === "finix-onboarding-completed") {
          track({ event: "Finix Onboarding Complete", domains: ["Settings"] });
          queryClient.invalidateQueries([
            getQueryKey("practices", "getFinixMerchantIdentity"),
            { practiceId },
          ]);
        }

        handleClose();
      }
    };

    window.addEventListener("message", handleMessage);

    return () => {
      window.removeEventListener("message", handleMessage);
    };
  }, [handleClose, practiceId, track, queryClient]);

  const fetchFormUrl = finixOnboardingFormUrlQuery.refetch;

  useEffect(() => {
    fetchFormUrl();
  }, [fetchFormUrl]);

  return currentPortal
    ? createPortal(
        <div className="absolute z-10 top-0 left-0 w-full h-full">
          {finixOnboardingFormUrlQuery.error ? (
            <div className="bg-white h-full w-full">
              <ErrorContent>
                <div className="flex flex-col items-center gap-y-4">
                  <p>
                    {finixOnboardingFormUrlQuery.error.error.errors?.[0].message ??
                      "Sorry, there was a problem loading the Finix setup form."}
                  </p>
                  <Button className="min-w-button" onClick={handleClose}>
                    OK
                  </Button>
                </div>
              </ErrorContent>
            </div>
          ) : (
            <>
              {finixOnboardingFormUrlQuery.data ? (
                <iframe
                  ref={iframeRef}
                  className="h-full w-full"
                  title="Finix onboarding"
                  src={finixOnboardingFormUrlQuery.data.formUrl}
                  onLoad={handleLoad}
                />
              ) : null}
              {finixOnboardingFormUrlQuery.isLoading || isIframeLoaded.isOff ? (
                <LoadingOverlaySpinner
                  overlayClassName="animate-fadeIn"
                  opaque
                  loadingText="Loading Finix Setup Form"
                />
              ) : null}
            </>
          )}
          <ButtonIcon
            className="absolute z-20 top-8 right-6"
            theme="primary"
            SvgIcon={CancelIcon}
            onClick={handleClose}
          />
        </div>,
        currentPortal
      )
    : null;
};
