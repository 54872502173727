import { FC } from "react";
import {
  AppointmentVO,
  FamilyMemberVO,
  ProviderVO,
  FormSummaryVO,
  FormTaskVO,
  PatientInsuranceResponse,
  PatientSummaryVO,
} from "@libs/api/generated-api";
import { PatientAppointmentDetails } from "components/PatientBlurbInfo/PatientAppointmentDetails";
import { PatientAppointmentInsurance } from "components/PatientBlurbInfo/PatientAppointmentInsurance";
import { AppointmentDetailForms } from "components/PatientBlurbInfo/AppointmentDetailForms";
import { PatientAppointmentGuardians } from "components/PatientBlurbInfo/PatientAppointmentGuardians";
import { PatientPrimaryProvider } from "components/PatientBlurbInfo/PatientPrimaryProvider";
import { AppointmentMenuProps } from "components/ScheduleAppointments/AppointmentMenu";

interface Props {
  appointment: AppointmentVO;
  guardians: FamilyMemberVO[];
  primaryProvider: ProviderVO | undefined;
  forms?: FormSummaryVO[];
  formTasks?: FormTaskVO[];
  insurance?: PatientInsuranceResponse;
  isHipaaView?: boolean;
  isNextAppointment: boolean;
  appointmentActions: AppointmentMenuProps["actions"];
  onUpdateAppointmentState: (appointment: AppointmentVO, newState: AppointmentVO["state"]) => void;
  onAppointmentDeleted?: (params: { patientId: number; appointmentId: number }) => void;
  onViewAppointments: Func;
  patient: PatientSummaryVO;
}

export const PatientAppointment: FC<Props> = ({
  appointment,
  guardians,
  primaryProvider,
  forms,
  formTasks,
  insurance,
  isHipaaView = false,
  isNextAppointment,
  appointmentActions,
  onUpdateAppointmentState,
  onViewAppointments,
  onAppointmentDeleted,
  patient,
}) => {
  return (
    <div className="flex flex-col gap-y-3 pb-3">
      <PatientAppointmentDetails
        appointment={appointment}
        appointmentActions={appointmentActions}
        isNextAppointment={isNextAppointment}
        hasInsurance={Boolean(patient.primaryInsuranceId)}
        onViewAppointments={onViewAppointments}
        onUpdateAppointmentState={onUpdateAppointmentState}
        onAppointmentDeleted={onAppointmentDeleted}
      />

      {forms && formTasks ? (
        <AppointmentDetailForms
          appointment={appointment}
          forms={forms}
          formTasks={formTasks}
          patient={patient}
        />
      ) : null}

      {primaryProvider ? <PatientPrimaryProvider primaryProvider={primaryProvider} /> : null}

      <PatientAppointmentInsurance patientId={patient.id} insurance={insurance} />

      {guardians.length > 0 ? (
        <PatientAppointmentGuardians guardians={guardians} isHipaaView={isHipaaView} />
      ) : null}
    </div>
  );
};
