import React, { useCallback } from "react";
import { PaymentDeviceVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { HeaderCell, Row, TableGrid } from "@libs/components/UI/GridTableComponents";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { PosDeviceModal } from "components/Settings/Payments/PosDeviceModal";
import { PosDeviceRow } from "components/Settings/Payments/PosDeviceRow";
import { deactivatePaymentDevice, deletePaymentDevice } from "api/settings/payments/mutations";
import { useItemModal } from "hooks/useItemModal";
import { handleError } from "utils/handleError";
import { ActivatePosDeviceModal } from "components/Settings/Payments/ActivatePosDeviceModal";

type PosDevicesSettingsProps = {
  posDevices?: PaymentDeviceVO[];
};

export const PosDevicesSettings: React.FC<PosDevicesSettingsProps> = ({ posDevices }) => {
  const { practiceId } = useAccount();
  const showNewPosModal = useBoolean(false);
  const activatePosModal = useItemModal<PaymentDeviceVO>(null);
  const confirmDeleteModal = useItemModal<PaymentDeviceVO>(null);
  const confirmDeactivateModal = useItemModal<PaymentDeviceVO>(null);

  const [
    { mutate: deletePaymentDeviceMutate, isLoading: isDeletingPos },
    { mutate: deactivateMutate, isLoading: isDeactivating },
  ] = useApiMutations([deletePaymentDevice, deactivatePaymentDevice]);

  const handleDelete = useCallback(
    (posDevice: PaymentDeviceVO) => {
      deletePaymentDeviceMutate(
        { practiceId, paymentDeviceUuid: posDevice.uuid },
        { onSuccess: confirmDeleteModal.close, onError: handleError }
      );
    },
    [confirmDeleteModal, deletePaymentDeviceMutate, practiceId]
  );

  const handleDeactivate = useCallback(
    (posDevice: PaymentDeviceVO) => {
      deactivateMutate(
        { practiceId, deviceUuid: posDevice.uuid },
        { onSuccess: confirmDeactivateModal.close, onError: handleError }
      );
    },
    [confirmDeactivateModal.close, deactivateMutate, practiceId]
  );

  const cancelAndDelete = useCallback(
    (posDevice: PaymentDeviceVO) => {
      if (posDevice.state === "CREATED") {
        handleDelete(posDevice);
      }

      activatePosModal.close();
    },
    [activatePosModal, handleDelete]
  );

  return (
    <div className="text-xs">
      <div className="pb-5">
        {posDevices?.length ? (
          <TableGrid
            className="mb-5"
            columnWidths={["224px", "max-content", "max-content", "max-content", "max-content"]}
          >
            <Row>
              <HeaderCell>Name</HeaderCell>
              <HeaderCell>Serial Number</HeaderCell>
              <HeaderCell>Model</HeaderCell>
              <HeaderCell className="col-span-2">Status</HeaderCell>
            </Row>
            {posDevices.map((device) => {
              return (
                <PosDeviceRow
                  key={device.uuid}
                  posDevice={device}
                  isDeleting={isDeletingPos && confirmDeleteModal.item === device}
                  onDelete={confirmDeleteModal.open}
                  onActivate={activatePosModal.open}
                  onDeactivate={confirmDeactivateModal.open}
                />
              );
            })}
          </TableGrid>
        ) : (
          <div className="my-3">No devices registered.</div>
        )}
        <Button theme="link" className="flex gap-x-2 items-center" onClick={showNewPosModal.on}>
          <AddIcon className="w-5 h-5" /> Add POS Device
        </Button>
      </div>
      {showNewPosModal.isOn && (
        <PosDeviceModal onSave={activatePosModal.open} onRequestClose={showNewPosModal.off} />
      )}
      {activatePosModal.isOpen && (
        <ActivatePosDeviceModal
          posDevice={activatePosModal.item}
          onCancel={activatePosModal.item.state === "CREATED" ? cancelAndDelete : activatePosModal.close}
          onActivate={activatePosModal.close}
        />
      )}
      {confirmDeleteModal.isOpen && (
        <ConfirmationModal
          onCancel={confirmDeleteModal.close}
          onConfirm={() => handleDelete(confirmDeleteModal.item)}
          isConfirming={isDeletingPos}
          primaryText={
            <>
              Are you sure you want to remove device{" "}
              <span className="whitespace-nowrap">{`"${confirmDeleteModal.item.name}"`}</span>?
            </>
          }
          secondaryText="You will need to run a new authorization test if you re-add this device later."
        />
      )}
      {confirmDeactivateModal.isOpen && (
        <ConfirmationModal
          onCancel={confirmDeactivateModal.close}
          onConfirm={() => handleDeactivate(confirmDeactivateModal.item)}
          isConfirming={isDeactivating}
          primaryText={
            <>
              Are you sure you want to deactivate device{" "}
              <span className="whitespace-nowrap">{`"${confirmDeactivateModal.item.name}"`}</span>?
            </>
          }
          secondaryText="You will need to run a new authorization test if you try to reactivate this device later."
        />
      )}
    </div>
  );
};
