import { useLocation, useNavigate } from "react-router-dom";
import { MedicalImageVO } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { ReactComponent as CompareIcon } from "@libs/assets/icons/compare.svg";
import { useMenu } from "@libs/components/UI/Menu";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptionLink } from "@libs/components/UI/MenuOptionButton";
import { paths } from "utils/routing/paths";
import { MountDetailsQuery } from "utils/routing/patient";
import { TeethOptionsFilterModal } from "components/PatientProfile/Imaging/MountRoute/TeethLabeling/TeethOptionsFilterModal";
import { MountIconButton } from "components/PatientProfile/Imaging/MountRoute/MountIconButton";

export const CompareImagesMenu: React.FC<{
  patientId: number;
  selectedImages: MedicalImageVO[];
  params: MountDetailsQuery;
  onUpdateParams: (newParams: Partial<MountDetailsQuery>) => void;
  mountId: number;
}> = ({ patientId, selectedImages, params, onUpdateParams, mountId }) => {
  const menu = useMenu<HTMLButtonElement>();
  const location = useLocation();
  const navigate = useNavigate();
  const [firstSelectedImage] = selectedImages.length > 0 ? selectedImages : [undefined];

  const hasTeeth = firstSelectedImage?.teeth && firstSelectedImage.teeth.length > 0;
  const canSearchByTeethSelection = selectedImages.length === 1 && hasTeeth;

  return (
    <>
      <ButtonMenu
        isOpen={menu.isOpen}
        onRequestClose={menu.close}
        onRequestOpen={menu.open}
        placement="right-start"
        menuContent={
          <>
            <MenuOptionLink
              to={paths.imagingCompareSelectImages(
                { patientId },
                { imageIds: selectedImages.map(({ id }) => id ?? -1), from: getFullUrl(location) }
              )}
            >
              Find images by mount
            </MenuOptionLink>
            <MenuOptionLink
              onClick={(e) => {
                if (canSearchByTeethSelection) {
                  menu.close();
                } else {
                  e.preventDefault();
                }
              }}
              to={paths.mountDetails(
                { mountId, patientId },
                { ...params, teethSearchMode: "CONTAINS_ALL", teethSearchTeeth: [] }
              )}
              disabled={!canSearchByTeethSelection}
            >
              Find images by tooth label
            </MenuOptionLink>
          </>
        }
      >
        {(props) => (
          <MountIconButton
            {...props}
            disabled={selectedImages.length === 0}
            SvgIcon={CompareIcon}
            tooltip={{ content: "Compare Images", theme: "SMALL" }}
            onClick={menu.open}
          />
        )}
      </ButtonMenu>
      {params.teethSearchMode && (
        <TeethOptionsFilterModal
          teeth={firstSelectedImage?.teeth ?? []}
          teethSearchMode={params.teethSearchMode}
          teethSearchTeeth={params.teethSearchTeeth ?? []}
          onUpdateParams={onUpdateParams}
          onConfirm={() => {
            navigate(
              paths.imagingCompare(
                { patientId },
                {
                  teethSearchMode: params.teethSearchMode,
                  teeth:
                    params.teethSearchMode === "CONTAINS_ALL"
                      ? firstSelectedImage?.teeth
                      : params.teethSearchTeeth ?? [],
                  imageIds: [firstSelectedImage?.id ?? -1],
                  from: getFullUrl(location),
                }
              ),
              { replace: true }
            );
          }}
          onClose={() => {
            onUpdateParams({ teethSearchMode: undefined, teethSearchTeeth: undefined });
          }}
        />
      )}
    </>
  );
};
