import { FC } from "react";

import { useLocation } from "react-router-dom";
import { FormSummaryVO } from "@libs/api/generated-api";
import { formatISODate, LONG_LOCALIZED_DATE_TIME } from "@libs/utils/date";
import { getFullUrl } from "@libs/utils/location";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as LockIcon } from "@libs/assets/icons/lock.svg";
import { Row, LinkCell, TextCell, EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { FormRowPillsLinkCell } from "components/Settings/Forms/FormRowPillsLinkCell";
import { FormStateBadge } from "components/Settings/Forms/FormStateBadge";
import { FormButtonMenu } from "components/Settings/Forms/FormButtonMenu";

import { paths } from "utils/routing/paths";

interface Props {
  form: FormSummaryVO;
  onRequestArchive: (form: FormSummaryVO) => void;
  onDuplicateForm?: (formUuid: string) => void;
}

export const FormRow: FC<Props> = ({ form, onRequestArchive, onDuplicateForm }) => {
  const location = useLocation();
  const link =
    form.type === "CLINICAL_NOTE"
      ? paths.editClinicalNotesForms({ formId: form.uuid }, { from: getFullUrl(location) })
      : paths.editForm({ formId: form.uuid }, { from: getFullUrl(location) });
  const hasSlug = Boolean(form.slug);

  return (
    <Row key={form.uuid}>
      <LinkCell to={link}>
        <div className="flex items-center gap-x-2 w-full">
          <div className="min-w-0 truncate">{form.title}</div>
          {hasSlug ? (
            <Icon
              SvgIcon={LockIcon}
              tooltip={{
                content:
                  "These forms are required for a patient to sign up with Archy's portal. They can be edited but not deleted.",
                theme: "MEDIUM",
              }}
              iconClassName="text-grey-600"
              size="sm"
            />
          ) : null}
        </div>
      </LinkCell>
      <LinkCell to={link}>{formatISODate(form.dateCreated)}</LinkCell>
      <LinkCell to={link} className="whitespace-nowrap">
        {form.lastPublishedAt ? formatISODate(form.lastPublishedAt, LONG_LOCALIZED_DATE_TIME) : EMPTY_CELL}
      </LinkCell>
      {form.type === "CLINICAL_NOTE" ? (
        <>
          <FormRowPillsLinkCell
            link={link}
            items={form.dentalProcedures}
            idKey="cdtCode"
            formatLabel={(item) => item.simpleName}
          />
          <FormRowPillsLinkCell
            link={link}
            items={form.clinicalNoteData?.appointmentCategories}
            idKey="id"
            formatLabel={(item) => item.name}
          />
          <FormRowPillsLinkCell
            link={link}
            items={form.clinicalNoteData?.providers}
            idKey="id"
            formatLabel={(item) => item.name.shortDisplayName}
          />
        </>
      ) : (
        <FormRowPillsLinkCell
          link={link}
          items={form.dentalProcedures}
          idKey="cdtCode"
          formatLabel={(item) => item.simpleName}
        />
      )}
      <LinkCell to={link}>
        <FormStateBadge formState={form.state} />
      </LinkCell>
      <TextCell className="flex justify-end">
        <div className="flex flex-col justify-center">
          <FormButtonMenu
            formState={form.state}
            toRequestEdit={link}
            onRequestArchive={() => onRequestArchive(form)}
            onDuplicateForm={onDuplicateForm ? () => onDuplicateForm(form.uuid) : undefined}
            hasSlug={hasSlug}
          />
        </div>
      </TextCell>
    </Row>
  );
};
