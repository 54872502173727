import { FC, FormEvent, MutableRefObject, useId, useState } from "react";
import { FormVO, TemplateVariableGroupVO } from "@libs/api/generated-api";
import { toggleSet } from "@libs/utils/toggleSet";
import { isDefined } from "@libs/utils/types";
import { Button } from "@libs/components/UI/Button";
import { ButtonInternalLink, ButtonLink } from "@libs/components/UI/ButtonLink";
import { Form } from "@libs/components/UI/Form";
import { FormStructure } from "components/Settings/Forms/FormStructure";
import {
  CurrentElement,
  FormElementRequest,
  SortableFormElement,
  SortableFormSectionElement,
} from "components/Settings/Forms/types";
import { AddFormElementSection } from "components/Settings/Forms/AddFormElementSection";
import { EmptyFormContent } from "components/Settings/Forms/EmptyFormContent";

interface Props {
  form: FormVO;
  formContentRef: MutableRefObject<HTMLFormElement | null>;
  templateVariables?: TemplateVariableGroupVO[];
  currentElement: CurrentElement | undefined;
  placeholderElement: CurrentElement | undefined;
  previewLink: string;
  onDeleteElement: (id: string) => void;
  onInsertElement: (update: FormElementRequest) => void;
  onPublish: Func;
  onPageSorted: (id: string, elements: SortableFormElement[]) => void;
  onSectionSorted: (id: string, elements: SortableFormSectionElement[]) => void;
  onUpdateElement: (element: FormElementRequest) => void;
  onEditElement: (currentElement?: CurrentElement) => void;
  onCloseElement: Func;
  onUpdateNewElement: (element: FormElementRequest | undefined) => void;
  onInvalidElement: Func;
}

export const EditFormContent: FC<Props> = ({
  form,
  formContentRef,
  templateVariables,
  currentElement,
  placeholderElement,
  previewLink,
  onUpdateNewElement,
  onCloseElement,
  onPageSorted,
  onPublish,
  onSectionSorted,
  onDeleteElement,
  onInsertElement,
  onUpdateElement,
  onEditElement,
  onInvalidElement,
}) => {
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onPublish();
  };

  const [openSections, setOpenSections] = useState(
    () =>
      new Set(
        form.content.flatMap((page) =>
          page.content.map((element) => (element.type === "SECTION" ? element.uuid : null)).filter(isDefined)
        )
      )
  );
  const handleToggleSection = (value: string) => {
    setOpenSections((last) => toggleSet(last, value));
  };

  const structureFormId = useId();

  return (
    <div className="min-h-0 flex-1 flex flex-col">
      <div className="flex flex-row min-h-0 flex-1 bg-offWhite">
        <Form
          ref={formContentRef}
          id={structureFormId}
          className="h-full flex-1 pl-12 pr-6 pb-12 pt-8 overflow-y-auto"
          onSubmit={handleSubmit}
        >
          <FormStructure
            form={form}
            openSections={openSections}
            onToggleSection={handleToggleSection}
            onPageSorted={onPageSorted}
            onSectionSorted={onSectionSorted}
            currentElement={currentElement}
            placeholderElement={placeholderElement}
            onDelete={onDeleteElement}
            onEditElement={onEditElement}
          />
        </Form>
        <div className="flex-none w-[536px] h-full p-6">
          <div className="rounded border border-greyLighter h-full bg-white">
            {currentElement ? (
              <AddFormElementSection
                formType={form.type}
                currentElement={currentElement}
                templateVariables={templateVariables}
                onUpdateNew={onUpdateNewElement}
                onAdd={onInsertElement}
                onEdit={onUpdateElement}
                onInvalidElement={onInvalidElement}
                onRequestClose={onCloseElement}
              />
            ) : (
              <EmptyFormContent />
            )}
          </div>
        </div>
      </div>
      <div className="flex-0 flex border-t border-t-greyLighter">
        <div className="flex flex-1 items-center justify-center py-5 gap-x-6">
          {previewLink.startsWith("/") ? (
            <ButtonInternalLink to={previewLink} className="min-w-button" theme="secondary">
              Preview
            </ButtonInternalLink>
          ) : (
            <ButtonLink target="_blank" href={previewLink} className="min-w-button" theme="secondary">
              Preview
            </ButtonLink>
          )}

          <Button form={structureFormId} type="submit" className="min-w-button" theme="primary">
            Publish
          </Button>
        </div>
        <div className="flex-none w-[512px] h-px" />
      </div>
    </div>
  );
};
