import { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { InvoiceVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getFullUrl } from "@libs/utils/location";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { paths } from "utils/routing/paths";
import { useVoidInvoice } from "components/PatientProfile/Billing/useVoidInvoice";
import { RoleGuardHide } from "components/Main/RoleGuard";
import { PracticeAndPatientIds } from "./LedgerComponents";

export const InvoiceMenu: FC<
  PracticeAndPatientIds & {
    invoiceUuid: InvoiceVO["uuid"];
    canCollect: boolean;
    canRefund: boolean;
    canVoid: boolean;
  }
> = ({ patientId, invoiceUuid, canCollect, canRefund, canVoid }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const voidInvoiceConfirmModal = useBoolean(false);
  const { voidInvoice, isVoiding } = useVoidInvoice({ invoiceUuid });
  const currentLocation = getFullUrl(location);

  return (
    <>
      <InvoiceMenuDropdown
        onView={() => {
          navigate(paths.viewInvoice({ patientId, invoiceUuid }, { from: currentLocation }));
        }}
        onCollectClick={
          canCollect
            ? () => {
                navigate(
                  paths.collectPayment({ patientId }, { from: currentLocation, invoice: invoiceUuid })
                );
              }
            : undefined
        }
        onRefundClick={
          canRefund
            ? () => {
                navigate(
                  paths.collectPayment({ patientId }, { from: currentLocation, invoice: invoiceUuid })
                );
              }
            : undefined
        }
        onVoidClick={canVoid ? voidInvoiceConfirmModal.on : undefined}
      />
      <InvoiceMenuModals
        showVoidConfirmation={voidInvoiceConfirmModal.isOn}
        isVoiding={isVoiding}
        onVoidConfirmed={() => {
          voidInvoice({ onSuccess: voidInvoiceConfirmModal.off });
        }}
        onVoidCancelled={voidInvoiceConfirmModal.off}
      />
    </>
  );
};

const InvoiceMenuDropdown: FC<{
  onView?: Func;
  onCollectClick?: Func;
  onRefundClick?: Func;
  onVoidClick?: Func;
}> = ({ onView, onCollectClick, onRefundClick, onVoidClick }) => {
  const menu = useBoolean(false);

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      placement="bottom-start"
      menuContent={
        <>
          <MenuOptionButton
            onClick={() => {
              onView?.();
              menu.off();
            }}
          >
            View
          </MenuOptionButton>
          {onCollectClick && (
            <RoleGuardHide domain="BILLING" action="COLLECT_MANAGE">
              <MenuOptionButton
                onClick={() => {
                  onCollectClick();
                  menu.off();
                }}
              >
                Collect
              </MenuOptionButton>
            </RoleGuardHide>
          )}
          {onRefundClick && (
            <RoleGuardHide domain="BILLING" action="CREATE_REFUND">
              <MenuOptionButton
                onClick={() => {
                  onRefundClick();
                  menu.off();
                }}
              >
                Refund
              </MenuOptionButton>
            </RoleGuardHide>
          )}
          {onVoidClick && (
            <RoleGuardHide domain="BILLING" action="COLLECT_MANAGE">
              <MenuOptionButton
                onClick={() => {
                  onVoidClick();
                  menu.off();
                }}
              >
                Void
              </MenuOptionButton>
            </RoleGuardHide>
          )}
        </>
      }
      onRequestClose={menu.off}
    >
      {(props) => <ButtonIcon {...props} size="md" SvgIcon={MenuIcon} />}
    </ButtonMenu>
  );
};

const InvoiceMenuModals: FC<{
  showVoidConfirmation: boolean;
  isVoiding: boolean;
  onVoidConfirmed: Func;
  onVoidCancelled: Func;
}> = ({ isVoiding, showVoidConfirmation, onVoidConfirmed, onVoidCancelled }) => {
  return showVoidConfirmation ? (
    <ConfirmationModal
      centerVertically
      size="2xs"
      isConfirming={isVoiding}
      onConfirm={onVoidConfirmed}
      onCancel={onVoidCancelled}
      primaryText="Are you sure you want to void this invoice?"
      secondaryText="This action cannot be undone."
    />
  ) : null;
};
