import { FC, PropsWithChildren, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { LayoutCard } from "@libs/components/UI/LayoutCard";

export const ToothChartRouteLayout: FC<
  PropsWithChildren<{
    tableFullScreen: boolean;
    tables: ReactNode;
    odontogram: ReactNode;
    addProcedures: ReactNode;
  }>
> = ({ tableFullScreen, children, tables, odontogram, addProcedures }) => {
  return (
    <div className="h-full flex flex-col">
      <LayoutCard
        data-testid="odontogram-row"
        className={cx(
          "flex-none max-w-[2040px] z-20 border-b border-b-slate-200",
          tableFullScreen && "hidden"
        )}
      >
        <div className="flex h-full">
          <div className="flex-none w-[56%] pb-5 h-full">{odontogram}</div>
          <div
            className={`
              flex-none
              w-[44%]
              border-l
              border-l-slate-200
              pt-3
              px-5
              pb-5
              h-full
            `}
          >
            {addProcedures}
          </div>
        </div>
      </LayoutCard>
      <LayoutCard data-testid="charting-tables-row" className="flex flex-1 min-h-0 flex-col z-10">
        {tables}
      </LayoutCard>

      {children}
    </div>
  );
};
