import { FC, useCallback, useMemo, useRef } from "react";
import { flip, offset } from "@floating-ui/react-dom";
import { AppointmentCardConfigVO, AppointmentCategoryVO, AppointmentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { isOneOf } from "@libs/utils/isOneOf";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as NoShow } from "@libs/assets/icons/warning.svg";
import { ReactComponent as Diagonals } from "@libs/assets/components/diagonal.svg";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { TREATMENT_PLAN_CATEGORY_COLOR, getDarkColor, getLightColor } from "@libs/domains/scheduling/colors";
import { useBoolean } from "@libs/hooks/useBoolean";
import { handleDoubleClick } from "utils/handleDoubleClick";
import {
  APPOINTMENT_DURATION_FOR_TWO_LINES,
  AppointmentCardDetails,
} from "components/ScheduleAppointments/AppointmentCardDetails";
import { AppointmentMenu, AppointmentMenuProps } from "components/ScheduleAppointments/AppointmentMenu";
import { AppointmentCardTooltip } from "components/ScheduleAppointments/AppointmentCardTooltip";
import { AppointmentStatusButton } from "components/ScheduleAppointments/AppointmentStatusButton";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { sortAppointmentTags } from "components/ScheduleAppointments/tags";

interface Props {
  isHovered?: boolean;
  hideActions?: boolean;
  appointment: AppointmentVO;
  categories?: AppointmentCategoryVO[];
  config: AppointmentCardConfigVO;
  groupingContextDetail?: string;
  hipaaView?: boolean;
  onRequestEditAppointment: (appointment: AppointmentVO) => void;
  onRequestUpdateAppointmentState: (appointment: AppointmentVO, newState: AppointmentVO["state"]) => void;
  onAppointmentDeleted: AppointmentMenuProps["onAppointmentDeleted"];
  selectedCard: number;
}

const cxStateStyles: Record<AppointmentVO["state"], string> = {
  CANCELED: "bg-greyLighter",
  COMPLETED: "bg-greyLighter",
  CONFIRMED: "bg-green",
  IN_PROGRESS: "bg-orange",
  NO_SHOW: "bg-greyLighter",
  ARRIVED: "bg-yellow",
  UNCONFIRMED: "bg-red",
  UNSCHEDULED: "bg-blue",
  READY: "bg-magenta",
  CHECKOUT: "bg-blue",
  REQUESTED: "bg-magenta",
  ["_DELETED"]: "",
};

const GrayStates = ["_DELETED" as const, "NO_SHOW" as const, "COMPLETED" as const, "CANCELED" as const];
const WhiteStates = ["REQUESTED" as const];

export const apptCardCxStyles = {
  container: ({
    selectedAppointmentId,
    appointment,
  }: {
    selectedAppointmentId: number;
    appointment: AppointmentVO;
  }) =>
    cx(
      `rounded
       h-full
       text-xxs
       relative
       left-0.5
       w-[98%]
       bg-white
       z-10`,
      selectedAppointmentId === appointment.id && "outline outline-2 outline-primaryTheme drop-shadow-md",
      isOneOf(appointment.state, GrayStates) ? "text-greyMedium" : "text-greyDark"
    ),
  cardBackgroundColor: (state: AppointmentVO["state"], isState: boolean) =>
    cx(
      "absolute inset-0 rounded",
      isOneOf(state, GrayStates)
        ? "bg-greyLightest"
        : isOneOf(state, WhiteStates)
          ? "bg-white border-2"
          : isState && cxStateStyles[state],
      isState && "opacity-[.15]"
    ),
  statusMenuTrigger: (state: AppointmentVO["state"], hasBottomLeftIndicator: boolean) =>
    cx(
      "absolute z-10 left-0 top-0 w-2",
      cxStateStyles[state],
      hasBottomLeftIndicator ? "h-1/2 rounded-tl" : "h-full rounded-l"
    ),
  bottomLeftIndicator: "absolute z-10 left-0 bottom-0 rounded-bl h-1/2 w-2",
  noShowIndicator: "fill-red right-4 absolute w-4 top-0",
  diagonals: "absolute top-0 left-0 w-full h-full z-20 pointer-events-none",
  body: ({ isMultiLineHeight }: { isMultiLineHeight: boolean }) =>
    cx("absolute top-0 left-0 w-full px-4 flex flex-col h-full", isMultiLineHeight && "py-1"),
};

// eslint-disable-next-line complexity
export const AppointmentCard: FC<Props> = ({
  appointment,
  categories,
  config,
  groupingContextDetail,
  hipaaView = false,
  onAppointmentDeleted,
  onRequestEditAppointment,
  onRequestUpdateAppointmentState,
  selectedCard,
  hideActions,
  isHovered,
}) => {
  const tooltipHovered = useBoolean(false);
  const elRef = useRef<HTMLDivElement | null>(null);
  const isNoShow = appointment.state === "NO_SHOW";

  const isMultiLineHeight = appointment.duration > APPOINTMENT_DURATION_FOR_TWO_LINES;

  const handleRequestEdit = useCallback(() => {
    onRequestEditAppointment(appointment);
  }, [appointment, onRequestEditAppointment]);

  const appointmentCategory = useMemo(
    () =>
      categories?.find((cat) => appointment.categoryName?.id === cat.id) ?? {
        name: "Treatment Plan",
        color: TREATMENT_PLAN_CATEGORY_COLOR,
      },
    [appointment.categoryName?.id, categories]
  );

  // eslint-disable-next-line complexity
  const cardConfigSettings = useMemo(() => {
    const { indicators, background, showDentistColor, fontSize, hidePreferredName } = config;
    const isGrayState = isOneOf(appointment.state, GrayStates);
    const backgroundIsStatus = appointment.state === "UNSCHEDULED" || (!isGrayState && !indicators.length);
    const backgroundIsProvider = background === "PROVIDER" || (!background && indicators[0] === "PROVIDER");
    const providerColor = showDentistColor ? appointment.dentist.color : appointment.provider.color;
    // the appointment color overrides the category color if chosen
    const categoryColor = appointment.color ?? appointmentCategory.color;
    const backgroundColor = backgroundIsProvider
      ? getLightColor(providerColor)
      : getLightColor(categoryColor);

    const backgroundColorStyle = backgroundIsStatus
      ? undefined
      : {
          backgroundColor,
          borderColor: backgroundColor,
        };

    const showBottomLeftIndicator = !isGrayState && background && appointment.state !== "UNSCHEDULED";
    const bottomLeftIndicatorIsProvider = !backgroundIsProvider;
    const bottomLeftIndicator = showBottomLeftIndicator
      ? bottomLeftIndicatorIsProvider
        ? {
            name: showDentistColor
              ? appointment.dentist.name.fullDisplayName
              : appointment.provider.name.fullDisplayName,
            backgroundColorStyle: {
              backgroundColor: getDarkColor(providerColor),
            },
          }
        : {
            name: appointmentCategory.name,
            backgroundColorStyle: { backgroundColor: getDarkColor(categoryColor) },
          }
      : undefined;

    return {
      backgroundIsStatus,
      backgroundColorStyle,
      bottomLeftIndicator,
      patientFontSize: fontSize,
      hidePreferredName: Boolean(hidePreferredName),
    };
  }, [
    config,
    appointment.state,
    appointment.dentist.color,
    appointment.dentist.name,
    appointment.provider.color,
    appointment.provider.name.fullDisplayName,
    appointment.color,
    appointmentCategory.color,
    appointmentCategory.name,
  ]);

  const isRequested = appointment.state === "REQUESTED";

  const hasBottomLeftIndicator = Boolean(cardConfigSettings.bottomLeftIndicator);

  const user = useCurrentUser();

  const tags = useMemo(() => {
    const excludedTagIds = new Set(
      user.type === "EMPLOYEE" ? user.employeeSettings?.excludedTagIds ?? [] : []
    );

    if (appointment.tags) {
      return sortAppointmentTags([
        ...appointment.tags.autoAppointment,
        ...appointment.tags.customAppointment,
        ...appointment.tags.patient,
      ]).filter((tag) => !excludedTagIds.has(tag.id));
    }

    return [];
  }, [user, appointment.tags]);

  return (
    <div
      ref={elRef}
      className={cx(
        "min-h-1",
        apptCardCxStyles.container({ selectedAppointmentId: selectedCard, appointment })
      )}
    >
      <div
        className={apptCardCxStyles.cardBackgroundColor(
          appointment.state,
          cardConfigSettings.backgroundIsStatus
        )}
        style={cardConfigSettings.backgroundColorStyle}
      />
      {appointment.state !== "UNSCHEDULED" && (
        <>
          {isRequested ? (
            <div className={apptCardCxStyles.statusMenuTrigger(appointment.state, hasBottomLeftIndicator)} />
          ) : (
            <AppointmentStatusButton
              appointment={appointment}
              onRequestUpdateAppointmentState={onRequestUpdateAppointmentState}
              hasBottomLeftIndicator={hasBottomLeftIndicator}
            />
          )}
          <div className={cx("right-0 z-10 absolute", isMultiLineHeight ? "top-1" : "top-0")}>
            {hideActions ? (
              <Icon size="sm" SvgIcon={MenuIcon} />
            ) : (
              <AppointmentMenu
                // eslint-disable-next-line @typescript-eslint/no-magic-numbers
                middleware={[flip({ crossAxis: true }), offset(6)]}
                placement="right-start"
                appointment={appointment}
                isOnSchedule
                onAppointmentDeleted={onAppointmentDeleted}
                triggerSize="sm"
              />
            )}
          </div>
          {cardConfigSettings.bottomLeftIndicator && (
            <FloatingTooltip
              content={cardConfigSettings.bottomLeftIndicator.name}
              theme="SMALL"
              placement="bottom"
            >
              <button
                type="button"
                className={apptCardCxStyles.bottomLeftIndicator}
                style={cardConfigSettings.bottomLeftIndicator.backgroundColorStyle}
                onClick={() => onRequestEditAppointment(appointment)}
              />
            </FloatingTooltip>
          )}
        </>
      )}

      {isNoShow && <NoShow className={apptCardCxStyles.noShowIndicator} />}

      {(appointment.state === "CANCELED" || isNoShow) && (
        <Diagonals className={cx("stroke-greyLight stroke-[0.5]", apptCardCxStyles.diagonals)} />
      )}
      <FloatingTooltip
        className="w-full"
        forceOpen={isHovered || tooltipHovered.isOn}
        content={
          <div onMouseEnter={tooltipHovered.on} onMouseLeave={tooltipHovered.off}>
            <AppointmentCardTooltip
              appointment={appointment}
              groupingContextDetail={groupingContextDetail}
              hipaaView={hipaaView}
              tags={tags}
            />
          </div>
        }
        placement="left"
      >
        <div className={apptCardCxStyles.body({ isMultiLineHeight })}>
          <AppointmentCardDetails
            appointment={appointment}
            tags={tags}
            className="text-left w-full"
            groupingContextDetail={groupingContextDetail}
            hipaaView={hipaaView}
            isNoShow={isNoShow}
            patientFontSize={cardConfigSettings.patientFontSize}
            hidePreferredName={cardConfigSettings.hidePreferredName}
            onClick={handleDoubleClick(handleRequestEdit)}
          />

          {isMultiLineHeight ? (
            <button
              type="button"
              onClick={handleDoubleClick(handleRequestEdit)}
              className="w-full flex-grow"
            />
          ) : null}
        </div>
      </FloatingTooltip>
    </div>
  );
};
