import React, { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isOneOf } from "@libs/utils/isOneOf";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { MountVO, PatientSummaryVO, PracticeImagingSettingsVO } from "@libs/api/generated-api";
import { useAccount } from "@libs/contexts/AccountContext";
import { ImagingToolbar } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar";
import { FullScreenPortal } from "components/PatientProfile/Imaging/shared/FullScreenPortal";
import {
  ImageEditorProvider,
  useImageEditorContext,
} from "components/PatientProfile/Imaging/ImageEditor/ImageEditorContext";
import { MedicalImageEditor } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/MedicalImageEditor";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { usePathParams } from "hooks/usePathParams";
import { useImageManipulator } from "components/PatientProfile/Imaging/hooks/useImageManipulator";
import { useToggleImageEditorLayers } from "components/PatientProfile/Imaging/ImageEditor/Tools/useToggleImageEditorLayers";
import { CompareImagesHeader } from "components/PatientProfile/Imaging/CompareImages/CompareImagesHeader";
import { MountMiniHeader } from "components/PatientProfile/Imaging/MountRoute/MountHeader/MountMiniHeader";
import {
  getInfiniteMountsQuery,
  getMedicalImageDetails,
  getMedicalImages,
  getMount,
  getPearlAnalysisForImage,
  getPracticeImagingSettings,
} from "api/imaging/queries";
import { getPatientSummary } from "api/patients/queries";
import { PearlConfig } from "components/PatientProfile/Imaging/types/pearl";
import { RevertChangesModal } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/RevertChangesModal";
import { clearCanvas } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/fabricUtils";
import { PearlImageEditorFlyoverContent } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/PearlFlyover/PearlImageEditorFlyoverContent";
import { EditorTipsContent } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ImagingTipsContent";

export const EditImageRouteContent: React.FC<{
  patientSummaryQuery: ApiQueryResult<PatientSummaryVO>;
  mountQuery: ApiQueryResult<MountVO>;
  imagingSettingsQuery: ApiQueryResult<PracticeImagingSettingsVO>;
}> = ({ mountQuery, patientSummaryQuery, imagingSettingsQuery }) => {
  const { practiceId } = useAccount();
  const { patientId } = usePathParams("imagingEditImage");
  const { query } = useQueryParams("imagingEditImage");
  const navigate = useNavigate();
  const { toggleLayer } = useToggleImageEditorLayers();
  const toolbarMenuOpen = useBoolean(false);
  const revertChangesModal = useBoolean(false);
  const {
    handleImageUpdate,
    imageSelected,
    setDrawSettings,
    editor,
    layersShown,
    pearlConfig,
    imageSensorSetting,
    updatePearlConfig,
    revertImage,
    pearlAnalysis,
  } = useImageEditorContext();
  const { rotateImage, flipImage } = useImageManipulator({
    handleImageUpdate,
    persistToServer: true,
  });

  const handlePearlConfigUpdate = useCallback(
    (config: Partial<PearlConfig>) => {
      setDrawSettings((prior) => ({ ...prior, mouseMode: undefined }));

      toggleLayer("pearl", true);
      updatePearlConfig(config);
    },
    [setDrawSettings, toggleLayer, updatePearlConfig]
  );
  const handleMirror = useCallback(
    (axis: "X" | "Y") => {
      if (imageSelected) {
        flipImage(axis, imageSelected);
      }
    },
    [flipImage, imageSelected]
  );

  const handleRotation = React.useCallback(
    (degrees: number) => {
      const canvas = editor.current?.getInstance();

      if (canvas && imageSelected) {
        canvas.discardActiveObject();
        editor.current?.rotateCanvas(degrees, canvas);
        rotateImage(degrees, imageSelected);
      }
    },
    [editor, imageSelected, rotateImage]
  );
  const handleRevertChanges = useCallback(() => {
    revertChangesModal.off();
    revertImage();

    const canvas = editor.current?.getInstance();

    if (canvas) {
      clearCanvas(canvas);
    }
  }, [editor, revertChangesModal, revertImage]);
  const mountsInfiniteQuery = useInfiniteApiQuery(
    getInfiniteMountsQuery({
      args: { pageSize: PAGE_SIZE, pageNumber: 1, patientId, practiceId },
    })
  );

  const imagesForAI = useMemo(
    () => (imageSelected?.type && isOneOf(imageSelected.type, ["X_RAY", "EXTERNAL"]) ? [imageSelected] : []),
    [imageSelected]
  );

  return (
    <>
      <div className="flex flex-col h-full min-h-0">
        {query.mountId ? (
          <MountMiniHeader
            mountQuery={mountQuery}
            patientSummaryQuery={patientSummaryQuery}
            mountsInfiniteQuery={mountsInfiniteQuery}
          />
        ) : (
          <CompareImagesHeader
            teethSearchMode={query.teethSearchMode}
            teeth={(query.teethSearchMode ? imageSelected?.teeth : query.teeth) ?? []}
            imageCount={query.imageIds?.length ?? 0}
            disabled
            editSelectionsUrl=""
          />
        )}
        <div className="flex h-full min-h-0">
          <ImagingToolbar
            onMirror={handleMirror}
            onRotate={handleRotation}
            onToggleLayer={toggleLayer}
            onMenuToggled={toolbarMenuOpen.set}
            pearlConfig={pearlConfig}
            onUpdatePearlConfig={handlePearlConfigUpdate}
            onRevertChanges={revertChangesModal.on}
            imagesForAI={imagesForAI}
            isOnboardedWithPearl={Boolean(imagingSettingsQuery.data?.dateOnboardedWithPearl)}
            layersShown={layersShown}
            pearlFlyoverContent={
              imageSelected && (
                <PearlImageEditorFlyoverContent
                  pearlConfig={pearlConfig}
                  onUpdatePearlConfig={handlePearlConfigUpdate}
                  patientId={patientId}
                  image={imageSelected}
                  pearlAnalysisResult={pearlAnalysis}
                />
              )
            }
            tipsFlyoverContent={
              <EditorTipsContent
                distanceToolEnabled={Boolean(imageSensorSetting?.calibration?.pixelsPerMM)}
              />
            }
          />
          <MedicalImageEditor
            patientId={patientId}
            closeOnClickOutside={toolbarMenuOpen.isOff}
            onClose={() => {
              if (query.from) {
                navigate(query.from);
              } else {
                navigate(paths.patientTab({ patientId, tab: "imaging" }));
              }
            }}
          />
        </div>
      </div>
      {revertChangesModal.isOn && (
        <RevertChangesModal
          onCancel={revertChangesModal.off}
          onConfirm={handleRevertChanges}
          imageCount={1}
        />
      )}
    </>
  );
};

export const EditImageRoute: React.FC = () => {
  const { query, updateQuery } = useQueryParams("imagingEditImage");
  const { patientId } = usePathParams("imagingEditImage");
  const { imageIds, teethSearchMode, teeth, sortBy, mountId, selectedImageId, showArchived } = query;
  const { practiceId } = useAccount();

  const medicalImagesInfiniteQuery = useInfiniteApiQuery(
    getMedicalImages({
      args: {
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        practiceId,
        patientId,
        imageIds: teethSearchMode ? undefined : imageIds,
        teeth,
        imageTeethSearchMode: teethSearchMode,
        sortColumn: sortBy,
      },
      queryOptions: {
        enabled: Boolean(imageIds || teethSearchMode),
        refetchOnWindowFocus: false,
      },
    })
  );

  const [imageSelectedQuery, mountQuery, patientSummaryQuery, imagingSettingsQuery] = useApiQueries([
    getMedicalImageDetails({
      args: { practiceId, patientId, imageId: selectedImageId ?? -1 },
      queryOptions: { enabled: Boolean(selectedImageId) },
    }),

    getMount({
      args: {
        practiceId,
        patientId,
        mountId: mountId ?? -1,
        query: {
          includeArchived: showArchived,
        },
      },
      queryOptions: { enabled: Boolean(mountId) },
    }),
    getPatientSummary({ args: { patientId, practiceId }, queryOptions: { enabled: Boolean(mountId) } }),
    getPracticeImagingSettings({ args: { practiceId } }),
  ]);

  const [pearlAnalysisQuery] = useApiQueries([
    getPearlAnalysisForImage({
      args: { practiceId, patientId, imageId: selectedImageId ?? -1 },
      queryOptions: {
        enabled: imageSelectedQuery.data?.pearlAnalysis?.status === "DONE",
      },
    }),
  ]);

  return (
    <FullScreenPortal isDark>
      <ImageEditorProvider
        medicalImagesInfiniteQuery={medicalImagesInfiniteQuery}
        imageSelectedQuery={imageSelectedQuery}
        pearlAnalysisQuery={pearlAnalysisQuery}
        mountQuery={mountQuery}
        {...query}
        onChangeSelectedImage={(newImageId) => {
          updateQuery("replaceIn", { selectedImageId: newImageId });
        }}
      >
        <EditImageRouteContent
          patientSummaryQuery={patientSummaryQuery}
          mountQuery={mountQuery}
          imagingSettingsQuery={imagingSettingsQuery}
        />
      </ImageEditorProvider>
    </FullScreenPortal>
  );
};
