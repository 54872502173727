import { KeyboardEvent, useCallback, useMemo, useState } from "react";
import { isDefined, isString } from "@libs/utils/types";
import { isoTime, required } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import {
  addMinutesToISOTime,
  formatISOTimeAsAmPm,
  getISOTimeDiffInMinutes,
  isISOTime,
  isLeftISOTimeAfterRightISOTime,
} from "@libs/utils/date";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { TimeInput } from "components/UI/TimeInput";

type MinMaxConfig = {
  value: string;
  inclusive?: boolean;
  errorMessage?: (time: string) => string;
};

export type TimeRangeMenuValidationOptions = {
  maxTime?: MinMaxConfig;
  minTime?: MinMaxConfig;
  allowZeroDuration?: boolean;
};

const minTime =
  (time: string | undefined, inclusive = false) =>
  (val: unknown) =>
    time === undefined || !isISOTime(time)
      ? true
      : isString(val) && ((inclusive && val === time) || isLeftISOTimeAfterRightISOTime(val, time));

const maxTime =
  (time: string | undefined, inclusive = false) =>
  (val: unknown) =>
    time === undefined || !isISOTime(time)
      ? true
      : isString(val) && ((inclusive && val === time) || isLeftISOTimeAfterRightISOTime(time, val));

const beforeMidnight = (val: unknown) =>
  isString(val) && (val === "00:00:00" ? false : isLeftISOTimeAfterRightISOTime("24:00:00", val));

const getSchema = (startTime: string, options?: TimeRangeMenuValidationOptions) => {
  return {
    startTime: [
      {
        $v: required,
        $error: "Please enter a valid start time.",
      },
      {
        $v: isoTime,
        $error: "Please enter a valid start time.",
      },
      {
        $v: minTime(options?.minTime?.value, options?.minTime?.inclusive),
        $error: options?.minTime
          ? options.minTime.errorMessage?.(options.minTime.value) ??
            `Start time must be${options.minTime.inclusive ? " at or" : ""} after ${formatISOTimeAsAmPm(
              options.minTime.value
            )}.`
          : "",
      },
    ],
    endTime: [
      {
        $v: required,
        $error: "Please enter a valid end time.",
      },
      {
        $v: isoTime,
        $error: "Please enter a valid end time.",
      },
      {
        $v: maxTime(options?.maxTime?.value, options?.maxTime?.inclusive),
        $error: options?.maxTime
          ? options.maxTime.errorMessage?.(options.maxTime.value) ??
            `End time must be${options.maxTime.inclusive ? " at or" : ""} before ${formatISOTimeAsAmPm(
              options.maxTime.value
            )}.`
          : "",
      },
      {
        $v: beforeMidnight,
        $error: "End time must be before 12:00AM.",
      },
      {
        $v: minTime(startTime, options?.allowZeroDuration),
        $error: `End time must be${options?.allowZeroDuration ? " the same or" : ""} after the start time.`,
      },
    ],
  };
};

type BaseTimeRangeMenuProps = {
  startTime: string;
  validation?: TimeRangeMenuValidationOptions;
};

export type AutoCalcEndTimeRangeMenuProps = BaseTimeRangeMenuProps & {
  durationInMinutes: number;
  rangeType: "durationInMinutes";
  onChange: (updates: { durationInMinutes: number; startTime: string }) => void;
};

export type EditEndTimeTimeRangeMenuProps = BaseTimeRangeMenuProps & {
  endTime: string;
  rangeType?: "default";
  onChange: (updates: { endTime: string; startTime: string }) => void;
};

type TimeRangeMenuProps = AutoCalcEndTimeRangeMenuProps | EditEndTimeTimeRangeMenuProps;

export const getEndTime = (props: TimeRangeMenuProps) => {
  return props.rangeType === "durationInMinutes"
    ? isISOTime(props.startTime)
      ? addMinutesToISOTime(props.startTime, props.durationInMinutes)
      : ""
    : props.endTime;
};

export const useTimeRangeMenu = (props: TimeRangeMenuProps & { onRequestClose: Func }) => {
  const durationInMinutes = props.rangeType === "durationInMinutes" ? props.durationInMinutes : undefined;
  const [time, setTime] = useState(() => ({
    start: props.startTime,
    end: getEndTime(props),
  }));

  const handleStartTimeChange = useCallback(
    (value: string) => {
      if (isISOTime(value) && isDefined(durationInMinutes)) {
        setTime({
          start: value,
          end: addMinutesToISOTime(value, durationInMinutes),
        });
      }

      setTime((last) => ({ ...last, start: value }));
    },
    [durationInMinutes]
  );

  const handleEndTimeChange = useCallback((value: string) => {
    setTime((last) => ({ ...last, end: value }));
  }, []);

  const timeRangeSchema = useMemo(
    () => getSchema(time.start, props.validation),
    [time.start, props.validation]
  );

  const { result, validate } = useValidation({ startTime: time.start, endTime: time.end }, timeRangeSchema);

  const handleSave = () => {
    if (validate().$isValid) {
      if (props.rangeType === "durationInMinutes") {
        props.onChange({
          startTime: time.start,
          durationInMinutes: getISOTimeDiffInMinutes(time.end, time.start),
        });
      } else {
        props.onChange({ startTime: time.start, endTime: time.end });
      }

      props.onRequestClose();
    }
  };

  const handleKeyDown = (e: KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      e.preventDefault();
      handleSave();
    }
  };

  return {
    handleKeyDown,
    handleSave,
    handleEndTimeChange,
    handleStartTimeChange,
    time,
    result,
  };
};

export const TimeRangeMenu: React.FC<TimeRangeMenuProps & { onRequestClose: Func }> = (props) => {
  const { handleEndTimeChange, handleKeyDown, handleSave, handleStartTimeChange, time, result } =
    useTimeRangeMenu(props);

  return (
    <div className="pl-4 pb-5 pt-6 pr-16 relative">
      <ButtonIcon
        SvgIcon={CloseIcon}
        theme="primary"
        className="absolute top-3 right-3"
        size="sm"
        onClick={props.onRequestClose}
      />
      <div className="flex items-center mb-4">
        <div className="text-xs whitespace-nowrap w-12">Start</div>
        <TimeInput
          error={result.startTime.$error}
          value={time.start}
          onChange={handleStartTimeChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="flex items-center">
        <div className="text-xs whitespace-nowrap w-12">End</div>
        <TimeInput
          error={result.endTime.$error}
          value={time.end}
          onChange={handleEndTimeChange}
          onKeyDown={handleKeyDown}
        />
      </div>
      <div className="flex justify-center mt-8">
        <Button size="small" onClick={handleSave} className="w-28">
          Save
        </Button>
      </div>
    </div>
  );
};
