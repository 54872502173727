import { useId, useMemo } from "react";

// For generating html ids that will be used by useQuerySelector
export const useIdAttribute = (prefix?: string) => {
  const id = useId();

  return useMemo(() => {
    const elementId = `${prefix ? `${prefix}-` : ""}${id}`;

    return {
      selectorId: CSS.escape(elementId),
      elementId,
    };
  }, [id, prefix]);
};
