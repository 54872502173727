import { useCallback, useMemo } from "react";
import { InsurancePlanVO, PatientInsuranceVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { noop } from "@libs/utils/noop";
import { formatISODate } from "@libs/utils/date";
import { Icon } from "@libs/components/UI/Icon";
import { Spinner } from "@libs/components/UI/Spinner";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { Banner } from "@libs/components/UI/Banner";
import { ActionModal } from "components/UI/ActionModal";

export const WARNING_BANNER_TEXT =
  "Autoverify data is managed by Vyne. Archy is not responsible for the accuracy of this data.";

interface Props {
  disableAutoVerify: boolean;
  insurancePlan: InsurancePlanVO | undefined;
  isAutoEligibleCarrier: boolean;
  isAutoVerifying: boolean;
  isLoading: boolean;
  onClickVerify: ({
    includeCoverage,
    insId,
    requestAutoVerifying,
  }: {
    includeCoverage: boolean;
    insId: number | undefined;
    requestAutoVerifying: boolean;
  }) => Promise<void>;
  onClose: Func;
  patientInsurance: PatientInsuranceVO | undefined;
}

// eslint-disable-next-line complexity
export const VerifyActionModal: React.FC<Props> = ({
  disableAutoVerify,
  insurancePlan,
  isAutoEligibleCarrier,
  isAutoVerifying,
  isLoading,
  onClickVerify,
  onClose,
  patientInsurance,
}) => {
  const coverage = useBoolean(isAutoEligibleCarrier);

  const handleVerify = useCallback(
    (requestAutoVerifying: boolean) => {
      onClickVerify({
        includeCoverage: coverage.isOn,
        insId: patientInsurance?.id,
        requestAutoVerifying,
      });
    },
    [coverage.isOn, onClickVerify, patientInsurance?.id]
  );

  const primaryButtonDisabledTooltip = useMemo(
    () =>
      disableAutoVerify && patientInsurance?.eligibilityLastVerifiedAt
        ? `Can only auto-verify once a day. Last auto-verified on ${formatISODate(
            patientInsurance.eligibilityLastVerifiedAt
          )}.`
        : "",
    [disableAutoVerify, patientInsurance?.eligibilityLastVerifiedAt]
  );

  const secondaryButtonDisabledTooltip = useMemo(
    () => (coverage.isOn && !insurancePlan?.uuid ? "Can only manually verify eligibility" : ""),
    [coverage.isOn, insurancePlan?.uuid]
  );

  return (
    <ActionModal
      displaySecondaryButton={isAutoEligibleCarrier}
      handleCancel={() => handleVerify(false)}
      handleClose={onClose}
      handleSave={() => handleVerify(isAutoEligibleCarrier)}
      isLoading={isLoading}
      primaryButtonDisabled={Boolean(primaryButtonDisabledTooltip) || isAutoVerifying}
      primaryButtonText={isAutoEligibleCarrier ? "Auto-Verify" : "Verify"}
      primaryButtonDisabledTooltip={primaryButtonDisabledTooltip}
      secondaryButtonDisabled={Boolean(secondaryButtonDisabledTooltip) || isAutoVerifying}
      secondaryButtonText={isAutoEligibleCarrier ? "Manually Verify" : undefined}
      secondaryButtonDisabledTooltip={secondaryButtonDisabledTooltip}
      size="2xs"
      title="Verify Insurance"
    >
      <div className="flex flex-col gap-y-6">
        {isAutoEligibleCarrier && (
          <Banner className="text-xs rounded" theme="warning">
            {WARNING_BANNER_TEXT}
          </Banner>
        )}
        <div className="flex flex-col gap-y-3">
          {isAutoVerifying ? (
            <>
              <div className="flex items-center gap-x-1.5 py-2 text-xs">
                <Spinner animation="border" size="md" variant="greyLighter" />
                <span>Eligibility</span>
              </div>
              <div className="flex items-center gap-x-1.5 py-2 text-xs">
                <Spinner animation="border" size="md" variant="greyLighter" />
                <span>Coverage</span>
              </div>
            </>
          ) : (
            <>
              <Checkbox checked={true} className="flex items-center py-2" disabled={true} onChange={noop}>
                <div className="flex items-center justify-between">
                  Eligibility
                  <Icon
                    SvgIcon={InfoIcon}
                    tooltip={{ content: "Eligibility is always verified by default" }}
                  />
                </div>
              </Checkbox>
              <Checkbox
                checked={coverage.isOn}
                className="flex items-center py-2"
                disabled={!insurancePlan?.uuid && !isAutoEligibleCarrier}
                onChange={coverage.toggle}
              >
                <div className="flex items-center justify-between">
                  Coverage
                  {(!insurancePlan?.uuid || isAutoEligibleCarrier) && (
                    <Icon
                      SvgIcon={InfoIcon}
                      tooltip={{
                        content: isAutoEligibleCarrier
                          ? "Auto-Verify eligible carriers will automatically pull in coverage details and verify"
                          : "Insurance plan required to verify coverage",
                      }}
                    />
                  )}
                </div>
              </Checkbox>
            </>
          )}
        </div>
      </div>
    </ActionModal>
  );
};
