import { FC } from "react";
import { DentalProcedureVO } from "@libs/api/generated-api";
import { ReactComponent as ClearIcon } from "@libs/assets/icons/cancel.svg";
import { ExpiredCDTCodeIcon } from "components/Charting/ExpiredCDTCodeIcon";
import { useNow } from "hooks/useNow";

interface Props {
  procedures: DentalProcedureVO[];
  className?: string;
  onRemoveClick?: (procedureId: number, procedureIndex: number) => void;
}

export const ProceduresList: FC<Props> = ({ procedures, className, onRemoveClick }) => {
  const now = useNow();

  return procedures.length ? (
    <div className={className}>
      <div className="font-sansSemiBold text-sm">Procedures</div>
      <div className="mt-4">
        {procedures.map((proc, index) => (
          <div className="text-xs mb-2 flex items-center" key={`${index}-${proc.id}`}>
            <p className="flex items-center gap-x-1">
              <span>
                {proc.cdtCode} {proc.name}
              </span>
              <ExpiredCDTCodeIcon now={now} endDate={proc.endDate} />
            </p>
            {onRemoveClick ? (
              <button className="ml-2" onClick={() => onRemoveClick(proc.id, index)} type="button">
                <ClearIcon className="w-3 h-3 fill-primaryTheme" />
              </button>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  ) : null;
};
