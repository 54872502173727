import { InfiniteData } from "@tanstack/react-query";
import React from "react";
import { ProductionByProcedureResponse } from "@libs/api/generated-api";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { getIsLoadingMore } from "@libs/utils/queries";
import { ApiResponse } from "@libs/@types/api";
import { useAccount } from "@libs/contexts/AccountContext";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { getProductionByProcedureInfiniteQuery } from "api/reporting/queries";
import { getTotals } from "components/Dashboard/Tables/utils";
import { useQueryParams } from "hooks/useQueryParams";
import { dashboardFiltersForTables } from "components/Dashboard/utils/filters";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";
import { TimeSegment } from "utils/routing/dashboard";

export const useProductionByProcedureQuery = ({
  pageSize,
  filters,
  enabled = true,
  sortOrders,
}: {
  pageSize: number;
  enabled?: boolean;
  filters: DashboardFilter[];
  sortOrders: ColumnSortOrder[];
  patientSearch?: string;
  selectedTimeSegment: TimeSegment;
}) => {
  const { practiceId } = useAccount();
  const { query } = useQueryParams("dashboardPracticeProduction");
  const { selectedTimeSegmentParams } = useTimeSeriesPageSelections(query);

  const { search, isWaiting } = useDebouncedSearch(query.patientSearch ?? "");

  const productionByProcedureQuery = useInfiniteApiQuery(
    getProductionByProcedureInfiniteQuery({
      args: {
        practiceId,
        pageSize,
        pageNumber: 1,
        data: {
          includeTotal: true,
          ...selectedTimeSegmentParams,
          filters: dashboardFiltersForTables(filters),
          patientSearchString: search,
          sortOrders,
        },
      },
      queryOptions: {
        enabled,
      },
    })
  );
  const { data, isLoading } = productionByProcedureQuery;
  const isLoadingMore = getIsLoadingMore(productionByProcedureQuery);

  return {
    productionByProcedureQuery,
    isLoadingMore,
    isLoading: isWaiting || isLoading,
    totals: getTotals<ProductionByProcedureResponse>(data),
  };
};

export const useFlattenProductionByProcedurePages = (
  data:
    | InfiniteData<{
        data: ProductionByProcedureResponse;
        apiResponse: ApiResponse<ProductionByProcedureResponse>;
      }>
    | undefined
) => {
  return React.useMemo(() => data?.pages.flatMap((item) => item.data.entries), [data]);
};
