import { FC } from "react";
import { PayrollErrorVO, PayrollLineItemVO } from "@libs/api/generated-api";
import { Banner } from "@libs/components/UI/Banner";
import { hasNotOnboardedLineItems, hasUnresolvedPostTaxDeductions } from "components/Payroll/utils";

interface Props {
  lineItems?: PayrollLineItemVO[];
}

export const PayrollEmployeeOnboardWarningBanner: FC<Props> = ({ lineItems }) => {
  return hasNotOnboardedLineItems(lineItems) ? (
    <Banner theme="warning" className="text-xs mt-2.5 rounded">
      <div>
        <p className="font-sansSemiBold">Some employees are not onboarded</p>
        <p>
          To proceed with this payroll, employees marked as &quot;Not Onboarded&quot; must be either onboarded
          or skipped.
        </p>
      </div>
    </Banner>
  ) : null;
};

export const PayrollDeductionErrorsBanner: FC<Props> = ({ lineItems }) => {
  return hasUnresolvedPostTaxDeductions(lineItems) ? (
    <Banner theme="error" className="text-xs mt-2.5 rounded">
      <div>
        <p className="font-sansSemiBold">Unresolved Post-Tax Deduction Errors</p>
        <p>All deductions must be successfully processed before you can run payroll.</p>
      </div>
    </Banner>
  ) : null;
};

export const PayrollProcessingErrorsBanner: FC<{
  errors: PayrollErrorVO[];
}> = ({ errors }) => {
  return errors.length ? (
    <Banner theme="error" className="text-xs mt-2.5 rounded">
      <div>
        <p className="font-sansSemiBold">Payroll Processing Errors</p>
        <ul className="list-disc list-inside mt-1">
          {errors.map((e, index) => (
            <li key={index} className="mb-1">
              {e.message}
            </li>
          ))}
        </ul>
      </div>
    </Banner>
  ) : null;
};
