import { cx } from "@libs/utils/cx";

export const cxStyles = {
  cell: `
    flex
    items-center
    group-hover:bg-slate-100
    group-hover:cursor-pointer
  `,
  selectedCell: "bg-actionLight",
  expandableCell: (isExpanded: boolean) => cx(isExpanded && "bg-slate-100 font-sansSemiBold"),
  pageWrapper: "flex flex-1 min-h-0 flex-col overflow-y-auto",
};
