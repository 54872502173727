/* eslint-disable @typescript-eslint/naming-convention */
import { useState } from "react";
import { captureException as sentryCaptureException } from "@sentry/react";
import { toast } from "react-toastify";
import { useFinixForm } from "@libs/hooks/useFinixForm";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { PaymentProfileVO } from "@libs/api/generated-api";
import { handleError } from "utils/handleError";
import { createPaymentProfileByToken } from "api/billing/mutations";
import { useEnvContext } from "contexts/EnvContext";

const NEW_CARD_FORM_OPTIONS = {
  placeholders: {
    name: "e.g. John Doe",
    expiration_date: "MM/YY",
    security_code: "123",
  },
  labels: {
    name: "Name on Card",
    number: "Card Number",
    expiration_date: "Expiration Date",
    security_code: "CVC",
  },
};

export const useNewCardForm = ({
  patientId,
  onSavedNewCardHandler,
}: {
  patientId: number;
  onSavedNewCardHandler?: (paymentProfile: PaymentProfileVO) => void;
}) => {
  const { practiceId } = useAccount();
  const [isDefaultCard, setIsDefaultCard] = useState<boolean>(false);
  const [isFsaCard, setIsFsaCard] = useState<boolean>(false);
  const [isSavingToBackend, setIsSavingToBackend] = useState(false);
  const [createPaymentProfileByTokenMutation] = useApiMutations([createPaymentProfileByToken]);

  const createPaymentProfile: FinixFormSubmissionCallback = (error, response) => {
    if (error) {
      sentryCaptureException(new Error("Error occured while submitting credit card details to Finix"));
      toast.error("Oops, something went wrong submitting credit card. Please try again.");

      return;
    }

    setIsSavingToBackend(true);
    createPaymentProfileByTokenMutation.mutate(
      {
        practiceId,
        patientId,
        data: {
          owner: { id: patientId, type: "PATIENT" },
          type: "CARD",
          token: response.data.id,
          gateway: "finix",
          markAsDefault: isDefaultCard,
          cardOptions: { healthBenefit: isFsaCard },
        },
      },
      {
        onError: handleError,
        onSuccess: (data) => {
          onSavedNewCardHandler?.(data.data.data);
        },
        onSettled: () => {
          setIsSavingToBackend(false);
        },
      }
    );
  };

  const { REACT_APP_FINIX_ENVIRONMENT, REACT_APP_FINIX_APPLICATION_ID } = useEnvContext();

  const finixForm = useFinixForm({
    finixEnvironment: REACT_APP_FINIX_ENVIRONMENT,
    applicationId: REACT_APP_FINIX_APPLICATION_ID,
    formSubmissionHandler: createPaymentProfile,
    options: NEW_CARD_FORM_OPTIONS,
  });

  return {
    finixForm,
    isDefaultCard,
    isFsaCard,
    handleChangeFsaCard: setIsFsaCard,
    handleChangeDefaultCard: setIsDefaultCard,
    isSaving: finixForm.isSubmitting || isSavingToBackend,
  };
};
