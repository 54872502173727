import { AppointmentCardConfigVO } from "@libs/api/generated-api";

export const getLabelForFontSize = (size: "STANDARD" | "SMALL") => {
  const FONT_SIZE_LABEL_MAP = {
    STANDARD: "Regular",
    SMALL: "Small",
  };

  return FONT_SIZE_LABEL_MAP[size];
};

export const indicatorLabelMap = {
  CATEGORY: "Category",
  DENTIST: "Dentist",
  HYGIENIST: "Hygienist",
  PROVIDER: "Provider",
  STATUS: "Status",
};

export const getBackgroundLabel = ({
  background,
  indicators,
  isPreview = false,
  showDentistColor,
}: AppointmentCardConfigVO & { isPreview?: boolean }) => {
  // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
  let mapKey: keyof typeof indicatorLabelMap = background ?? indicators[0] ?? "STATUS";

  if (isPreview && mapKey === "PROVIDER") {
    mapKey = showDentistColor ? "DENTIST" : "HYGIENIST";
  }

  return indicatorLabelMap[mapKey];
};
