import { FC } from "react";

import { FormSummaryVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptionLink, MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as CopyIcon } from "@libs/assets/icons/copy.svg";

const cxStyles = {
  menuOptionButton: "gap-x-2",
  menuOptionIcon: "w-5 h-5 text-greyMedium",
};

interface Props {
  formState: FormSummaryVO["state"];
  toRequestEdit: string;
  onRequestArchive: Func;
  onDuplicateForm?: Func;
  hasSlug: boolean;
}

export const FormButtonMenu: FC<Props> = ({
  formState,
  toRequestEdit,
  onRequestArchive,
  onDuplicateForm,
  hasSlug,
}) => {
  const menu = useBoolean(false);

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      onRequestClose={menu.off}
      placement="left"
      menuContent={
        <div className="w-36 divide-y divide-greyLighter">
          <MenuOptionLink className={cxStyles.menuOptionButton} aria-label="Edit Form" to={toRequestEdit}>
            <EditIcon className={cxStyles.menuOptionIcon} />
            Edit
          </MenuOptionLink>
          {onDuplicateForm && (
            <MenuOptionButton
              className={cxStyles.menuOptionButton}
              aria-label="Duplicate Form"
              onClick={() => {
                onDuplicateForm();
                menu.off();
              }}
            >
              <CopyIcon className={cxStyles.menuOptionIcon} />
              Duplicate
            </MenuOptionButton>
          )}

          {hasSlug || formState === "ARCHIVED" ? null : (
            <MenuOptionButton
              className={cxStyles.menuOptionButton}
              aria-label="Delete Form"
              onClick={() => {
                onRequestArchive();
                menu.off();
              }}
            >
              <DeleteIcon className={cxStyles.menuOptionIcon} />
              {formState === "DRAFT" ? "Delete" : "Archive"}
            </MenuOptionButton>
          )}
        </div>
      }
    >
      {(props) => (
        <ButtonIcon SvgIcon={MenuIcon} aria-label="Form Menu" theme="primary" size="sm" {...props} />
      )}
    </ButtonMenu>
  );
};
