import React from "react";
import { CustomAdjustmentTypeVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { useDonutDatumOnClick } from "components/Dashboard/Adjustments/hooks/useDonutDatumOnClick";
import { DashboardDonutChart } from "components/Dashboard/Charting/DashboardDonutChart";
import { CarriersQuery, TimeSeriesQuery } from "components/Dashboard/types";
import { AdjustmentsDonutTooltip } from "components/Dashboard/Adjustments/AdjustmentsDonutTooltip";
import { useAdjustmentQueriesAsPieData } from "components/Dashboard/Adjustments/hooks/useAdjustmentQueriesAsPieData";
import { AdjustmentCategory, DashboardAdjustmentsQuery } from "utils/routing/dashboard";

export type Props = {
  width: number;
  height: number;
  animate?: boolean;
  carrierAdjustmentsForSelectedTimePeriodQuery: TimeSeriesQuery;
  collectionAdjustmentsForSelectedTimePeriodQuery: TimeSeriesQuery;
  allCarriersQuery: CarriersQuery;
  adjustmentTypesQuery: ApiQueryResult<CustomAdjustmentTypeVO[]>;
  tableTabSelected: AdjustmentCategory;
  total: number;
  params: DashboardAdjustmentsQuery;
  onUpdateParams: (updates: Partial<DashboardAdjustmentsQuery>) => void;
};

export const AdjustmentsDonutChart: React.FC<Props> = ({
  carrierAdjustmentsForSelectedTimePeriodQuery,
  collectionAdjustmentsForSelectedTimePeriodQuery,
  allCarriersQuery,
  adjustmentTypesQuery,
  total,
  tableTabSelected,
  onUpdateParams,
  params,
  ...rest
}) => {
  const data = useAdjustmentQueriesAsPieData(
    carrierAdjustmentsForSelectedTimePeriodQuery,
    collectionAdjustmentsForSelectedTimePeriodQuery,
    allCarriersQuery,
    adjustmentTypesQuery,
    params.filters
  );

  const loadError =
    carrierAdjustmentsForSelectedTimePeriodQuery.error ||
    collectionAdjustmentsForSelectedTimePeriodQuery.error;
  const isLoading = [
    carrierAdjustmentsForSelectedTimePeriodQuery,
    collectionAdjustmentsForSelectedTimePeriodQuery,
    allCarriersQuery,
  ].some((item) => item.isLoading || item.isInitialLoading);

  const handleDatumClicked = useDonutDatumOnClick({
    params,
    onUpdateFilters: onUpdateParams,
    tableTabSelected,
  });

  return (
    <DashboardDonutChart
      {...rest}
      animate
      isLoading={isLoading}
      data={data}
      onClickDatum={handleDatumClicked}
      Tooltip={AdjustmentsDonutTooltip}
      error={Boolean(loadError)}
      emptyResultsMessage="No adjustments are in selected time range."
      title="Total Adjustments"
      subtitle={formatCurrency(total)}
    />
  );
};
