import { FC, FormEventHandler, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryFilters } from "@libs/components/UI/QueryFilters";
import { useQueryParams } from "hooks/useQueryParams";
import { getPracticeInsuranceCarriers } from "api/practiceInsurance/queries";
import { paths } from "utils/routing/paths";
import {
  CarriersSortKeys,
  InsuranceCarrierDetailsQueryUpdates,
  InsuranceCarriersQuery,
  InsuranceCarriersQueryUpdates,
} from "utils/routing/settings";
import { getInsuranceCarriersFilterProps } from "components/Settings/InsuranceCarriers/insurancesQuery";
import { InsuranceCarriersTable } from "components/Settings/InsuranceCarriers/Table";
import { SettingsListPanel } from "components/Settings/SettingsListPanel";
import { getInNetworkQueryValue } from "components/Settings/FeeSchedules/FeeSchedulesRoute";
import { QueryFiltersFlyover } from "components/UI/QueryFiltersFlyover";
import { InsuranceCarriersFilterFlyover } from "components/Settings/InsuranceCarriers/FiltersFlyover";

export const InsuranceCarriersRoute: FC = () => {
  const { practiceId } = useAccount();
  const navigate = useNavigate();
  const { query, updateQuery } = useQueryParams("insuranceCarriers");
  const [queryForFilters, setQueryForFilters] = useState<InsuranceCarriersQuery>(query);

  const handleFiltersQueryParamChange = useCallback((keyValues: InsuranceCarriersQueryUpdates) => {
    setQueryForFilters((last) => ({ ...last, ...keyValues }));
  }, []);

  const handleQueryParamChange = useCallback(
    (keyValues: InsuranceCarriersQueryUpdates) => {
      updateQuery("replaceIn", keyValues);
      handleFiltersQueryParamChange(keyValues);
    },
    [handleFiltersQueryParamChange, updateQuery]
  );

  const filterProps = useMemo(() => {
    return getInsuranceCarriersFilterProps(query);
  }, [query]);

  const insuranceCarriersQueryKey = useMemo(
    () => ({
      ...query,
      inNetwork: getInNetworkQueryValue(query.inNetwork),
    }),
    [query]
  );
  const insuranceCarriersInfiniteQuery = useInfiniteApiQuery(
    getPracticeInsuranceCarriers({
      args: { ...insuranceCarriersQueryKey, pageNumber: 1, pageSize: PAGE_SIZE, practiceId },
    })
  );

  const totalElements =
    getInfiniteQueryPagingDetails(insuranceCarriersInfiniteQuery.data)?.totalElements || 0;

  const handleNavigateToDetailsPage = useCallback(
    (carrierId: number | "new", queryParams?: InsuranceCarrierDetailsQueryUpdates) => {
      navigate(paths.insuranceDetails({ carrierId }, queryParams));
    },
    [navigate]
  );

  const handleSort = useCallback(
    (sortColumn: CarriersSortKeys) => {
      if (query.sortColumn !== sortColumn) {
        handleQueryParamChange({ orderBy: "ASCENDING", sortColumn });
      } else if (query.orderBy === "ASCENDING") {
        handleQueryParamChange({ orderBy: "DESCENDING" });
      } else {
        handleQueryParamChange({ orderBy: "ASCENDING" });
      }
    },
    [handleQueryParamChange, query.orderBy, query.sortColumn]
  );

  const flyover = useBoolean(false);
  const closeFlyover = flyover.off;
  const applyFilters: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();

      handleQueryParamChange(queryForFilters);

      closeFlyover();
    },
    [handleQueryParamChange, queryForFilters, closeFlyover]
  );

  return (
    <SettingsListPanel
      title={
        <div className="flex flex-none items-center gap-x-1">
          <span>Insurance Carriers</span>
          <ButtonIcon
            className="p-1"
            SvgIcon={AddIcon}
            onClick={() => handleNavigateToDetailsPage("new")}
            theme="primary"
            tooltip={{ content: "Add Carrier", theme: "SMALL" }}
          />
        </div>
      }
    >
      <QueryFilters
        onOpenFlyover={flyover.on}
        onUpdateParams={handleQueryParamChange}
        queries={[insuranceCarriersInfiniteQuery]}
        searchParamKey="searchString"
        totalElements={totalElements}
        {...filterProps}
      />
      <InsuranceCarriersTable
        insuranceCarriersInfiniteQuery={insuranceCarriersInfiniteQuery}
        insuranceCarriersQueryKey={insuranceCarriersQueryKey}
        onEdit={handleNavigateToDetailsPage}
        onSort={handleSort}
        query={query}
      />
      {flyover.isOn && (
        <QueryFiltersFlyover
          content={
            <InsuranceCarriersFilterFlyover
              onUpdateFilters={handleFiltersQueryParamChange}
              queryState={queryForFilters}
            />
          }
          isSubmitting={insuranceCarriersInfiniteQuery.isFetching}
          onClose={closeFlyover}
          onSubmit={applyFilters}
        />
      )}
    </SettingsListPanel>
  );
};
