import { cx } from "@libs/utils/cx";

type Props = {
  maxWidth: number;
  maxHeight: number;
  children: React.ReactNode;
  className?: string;
  backdrop?: React.ReactNode;
};

// Component will take the maximum amount of space provided, while maintaining its aspect ratio
export const MaxSizeWithAspect: React.FC<Props> = ({
  maxWidth,
  maxHeight,
  children,
  className,
  backdrop,
}) => {
  return (
    <div className="relative w-full h-full flex-1">
      <div className={cx("absolute inset-0 flex justify-center", className)}>
        <div className="w-full">
          {backdrop}
          <div
            className="max-h-full m-auto"
            style={{
              maxWidth,
              maxHeight,
              aspectRatio: `${maxWidth} / ${maxHeight}`,
            }}
          >
            {children}
          </div>
        </div>
      </div>
    </div>
  );
};
