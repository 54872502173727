import React, { useMemo, useState } from "react";
import {
  AppointmentCategoryVO,
  AppointmentRequest,
  PatientProcedureVO,
  PatientSummaryVO,
  PatientToothVO,
  ProviderVO,
  RoomVO,
  TagVO,
} from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { useAppointmentSlotEditor } from "components/ScheduleAppointments/useAppointmentSlotEditor";
import { PreSelections } from "components/ScheduleAppointments/types";
import { AppointmentForm } from "components/ScheduleAppointments/AppointmentForm";
import { AppointmentDurationContext } from "components/ScheduleAppointments/AppointmentDurationContext";
import { useAddAppointmentForm } from "components/ScheduleAppointments/useAddAppointmentForm";
import { AppointmentSlotTab } from "components/ScheduleAppointments/AppointmentSlotTabs";
import { AppointmentFindPatientRow } from "components/ScheduleAppointments/AppointmentFindPatientRow";

interface Props {
  providers: ProviderVO[];
  categories: AppointmentCategoryVO[];
  rooms: RoomVO[];
  teeth: PatientToothVO[] | undefined;
  formId: string;
  initialDuration?: number;
  patientProcedures?: PatientProcedureVO[];
  preSelections?: PreSelections;
  patientId: number;
  tags: TagVO[];
  onSubmit: (appointment: AppointmentRequest) => void;
  onValidationChange: (isValid: boolean) => void;
  onDirty: Func;
  addToAsapList?: boolean;
  initialTab?: AppointmentSlotTab;
  findFromDate?: string;
  patientQuery: ApiQueryResult<PatientSummaryVO>;
  onSelectedPatient: (patientId: number | undefined) => void;
}

export const selectIfOnlyDentist = (providers: ProviderVO[], preSelections: PreSelections | undefined) => {
  const filtered = providers.filter((p) => p.jobCategory === "DENTIST");
  const onlyDentist = filtered.length === 1 ? filtered[0] : null;

  const defaultSelectedDentist = onlyDentist ? { dentistId: onlyDentist.id } : null;

  return defaultSelectedDentist && preSelections
    ? {
        // theoretically any preselections that select a provider id should match
        // the only provider id if there is only one.  However in case the preselection
        // is out of date just use the most up to date data
        ...preSelections,
        ...defaultSelectedDentist,
      }
    : defaultSelectedDentist
      ? {
          roomId: 0,
          hygienistId: 0,
          startTime: "",
          ...defaultSelectedDentist,
        }
      : preSelections;
};

export const AddAppointmentForm: React.FC<Props> = ({
  categories,
  providers,
  rooms,
  formId,
  preSelections,
  patientId,
  initialDuration,
  patientProcedures,
  teeth,
  initialTab,
  tags,
  addToAsapList = false,
  onValidationChange,
  onSubmit,
  onDirty,
  onSelectedPatient,
  findFromDate,
  patientQuery,
}) => {
  const confirmationAndReminders = useBoolean(true);

  const [tagIds, setTagIds] = useState<number[]>([]);

  const {
    procedures,
    selectedCategoryId,
    color,
    hasCustomColor,
    duration,
    selectCategory,
    addProcedures,
    updateDraft,
    switchSavedProcedure,
    switchNewProcedure,
    removeProcedure,
    setColor,
  } = useAddAppointmentForm({
    addToAsapList,
    categories,
    initialDuration,
    patientProcedures,
  });

  const modifiedPreselections = useMemo(
    () => selectIfOnlyDentist(providers, preSelections),
    [providers, preSelections]
  );

  const slotEditor = useAppointmentSlotEditor(modifiedPreselections, duration.read);

  return (
    <AppointmentDurationContext.Provider value={duration}>
      <AppointmentForm
        formId={formId}
        patientId={patientId}
        providers={providers}
        rooms={rooms}
        teeth={teeth}
        categories={categories}
        initialComment=""
        color={color}
        tagIds={tagIds}
        onUpdateTags={setTagIds}
        tags={tags}
        hasCustomColor={hasCustomColor}
        procedures={procedures}
        selectedCategoryId={selectedCategoryId}
        slotEditor={slotEditor}
        onSubmit={onSubmit}
        onValidationChange={onValidationChange}
        onProceduresAdded={addProcedures}
        onColorChange={setColor}
        onSelectCategory={selectCategory}
        onUpdateProcedureDraft={updateDraft}
        onRemoveProcedure={removeProcedure}
        onSavedProcedureSwitch={switchSavedProcedure}
        onNewProcedureSwitch={switchNewProcedure}
        onDirty={onDirty}
        addToAsapList={addToAsapList}
        initialTab={initialTab}
        findFromDate={findFromDate}
        confirmationAndReminders={confirmationAndReminders}
        patientSlot={
          <div className="flex items-center w-3/5 gap-x-5">
            <AppointmentFindPatientRow
              selectedPatientId={patientId}
              initialPatientQuery={patientQuery}
              onSelectedPatient={onSelectedPatient}
            />
            <Checkbox
              checked={confirmationAndReminders.isOn}
              className="top-3.5"
              onChange={confirmationAndReminders.toggle}
            >
              Send auto-reminders
            </Checkbox>
          </div>
        }
      />
    </AppointmentDurationContext.Provider>
  );
};
