import { FC, PropsWithChildren } from "react";
import { FormSummaryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { titleCaseConstant } from "@libs/utils/casing";
import { Badge } from "components/Settings/Forms/Badge";

interface Props {
  formState: FormSummaryVO["state"];
}

const cxColorStyles: Record<FormSummaryVO["state"], string> = {
  DRAFT: "bg-blueLight text-blueDark",
  UNPUBLISHED_CHANGES: "bg-orangeLight text-orange",
  PUBLISHED: "bg-greenLight text-green",
  ARCHIVED: "",
};

export const FormStateBadge: FC<PropsWithChildren<Props>> = ({ formState, children }) => {
  return (
    <Badge className={cx("whitespace-nowrap", cxColorStyles[formState])}>
      {children ?? titleCaseConstant(formState)}
    </Badge>
  );
};
