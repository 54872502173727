import { useCallback, useMemo } from "react";
import { Button } from "@libs/components/UI/Button";
import { FilterStatus, FilterStatusData } from "components/Claims/types";
import { ToggleButtonList } from "components/UI/ToggleButtonList";
import { ClaimsQueryUpdates } from "utils/routing/claims";

interface Props {
  onFiltersUpdate: (keyValues: ClaimsQueryUpdates) => void;
  selectedStatuses: FilterStatus[] | undefined;
  isPreAuth: boolean;
}

export const FilterStatusButtons: React.FC<Props> = ({ onFiltersUpdate, selectedStatuses, isPreAuth }) => {
  const handleClick = useCallback(
    (updatedStatuses: Set<FilterStatus>) => {
      onFiltersUpdate({ statuses: [...updatedStatuses] });
    },
    [onFiltersUpdate]
  );

  const options = useMemo(() => {
    const filterStateOptions = [
      {
        label: FilterStatusData[FilterStatus.NOT_SUBMITTED].label,
        value: FilterStatus.NOT_SUBMITTED,
      },
      {
        label: FilterStatusData[FilterStatus.SUBMITTED].label,
        value: FilterStatus.SUBMITTED,
      },
      {
        label: isPreAuth ? "Received" : FilterStatusData[FilterStatus.COMPLETED].label,
        value: FilterStatus.COMPLETED,
      },
    ];

    if (!isPreAuth) {
      filterStateOptions.push({
        label: FilterStatusData[FilterStatus.VOIDED].label,
        value: FilterStatus.VOIDED,
      });
    }

    return filterStateOptions;
  }, [isPreAuth]);

  return (
    <div className="flex items-center gap-x-3">
      <ToggleButtonList
        layout="custom"
        listClassName="flex flex-row gap-x-1"
        optionClassName="w-20"
        onChange={handleClick}
        options={options}
        selectedValues={new Set(selectedStatuses)}
        type="checkbox"
      />
      <Button
        className="text-xs font-sans"
        disabled={!selectedStatuses?.length}
        onClick={() => handleClick(new Set())}
        theme="link"
      >
        Clear
      </Button>
    </div>
  );
};
