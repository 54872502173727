import { PatientInsuranceResponse } from "@libs/api/generated-api";
import {
  CurrencyCell,
  SectionHeader,
  SectionRow,
  cxTableStyles,
} from "components/PatientProfile/Insurance/InsurancePlans/TableItems";

interface Props {
  insurances: PatientInsuranceResponse[];
}

export const MaximumsRows: React.FC<Props> = ({ insurances }) => {
  return (
    <>
      <SectionHeader
        numOfInsurances={insurances.length}
        subtitles={["Individual", "Family"]}
        title="Maximums"
      />
      <SectionRow
        insurances={insurances}
        renderValue={({ insurancePlan }, index) => {
          return (
            <div className={cxTableStyles.cellMultipleValues} key={`${index}-max-total`}>
              <CurrencyCell value={insurancePlan?.individualAnnualMaximum} />
              <CurrencyCell value={insurancePlan?.familyAnnualMaximum} />
            </div>
          );
        }}
        title="Total Benefits"
      />
      <SectionRow
        insurances={insurances}
        renderValue={({ patientInsurance }) => {
          return (
            <div className={cxTableStyles.cellMultipleValues} key={`${patientInsurance.id}-max-remaining`}>
              <CurrencyCell value={patientInsurance.annualMaximumRemaining} />
              <CurrencyCell value={patientInsurance.subscriber.annualMaximumRemaining} />
            </div>
          );
        }}
        title="Remaining"
      />
    </>
  );
};
