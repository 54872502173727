import { TagVO } from "@libs/api/generated-api";
import { FC } from "react";
import { ReactComponent as MeatBalls } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as LockIcon } from "@libs/assets/icons/lock.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as ArchiveIcon } from "@libs/assets/icons/archive.svg";
import { ReactComponent as UnarchiveIcon } from "@libs/assets/icons/unarchive.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Icon } from "@libs/components/UI/Icon";
import { cx } from "@libs/utils/cx";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { ButtonCell, Row, TextCell } from "@libs/components/UI/GridTableComponents";
import { PillTag } from "components/Notes/PillTag";

const getOptions = (tag: TagVO) =>
  createMenuOptions(
    {
      label: "Edit",
      SvgIcon: EditIcon,
      value: "Edit",
    },
    {
      label: "Archive",
      SvgIcon: ArchiveIcon,
      value: "Archive",
      disabled: Boolean(tag.slug),
    }
  );

export interface TagRowProps {
  tag: TagVO;
  onOptionClick: (tag: TagVO, value: ListItem<ReturnType<typeof getOptions>>["value"] | "Unarchive") => void;
}

export const TagRow: FC<TagRowProps> = ({ tag, onOptionClick }) => {
  const menu = useBoolean(false);
  const options = getOptions(tag);

  const handleDoubleClick = () => {
    onOptionClick(tag, "Edit");
  };

  return (
    <Row>
      <ButtonCell verticalPadding="slim" disabled={tag.isArchived} onClick={handleDoubleClick}>
        <div className={cx("flex justify-start items-center gap-x-2", tag.isArchived && "opacity-35")}>
          <PillTag tag={tag} />
          {tag.slug ? (
            <Icon
              SvgIcon={LockIcon}
              theme="slate500"
              size="sm"
              tooltip={{
                content:
                  "This tag is used by Archy for Auto Alerts, the icon and color can be edited but it cannot be renamed or archived",
              }}
            />
          ) : null}
        </div>
      </ButtonCell>
      <TextCell className="flex justify-end">
        {tag.isArchived ? (
          <ButtonIcon
            tooltip={{ content: "Unarchive", theme: "SMALL" }}
            SvgIcon={UnarchiveIcon}
            onClick={() => onOptionClick(tag, "Unarchive")}
          />
        ) : (
          <ButtonMenu
            isOpen={menu.isOn}
            onRequestOpen={menu.on}
            menuContent={
              <MenuOptions
                options={options}
                onOptionClick={(option) => {
                  onOptionClick(tag, option.value);
                  menu.off();
                }}
              />
            }
            onRequestClose={menu.off}
          >
            {(props) => <ButtonIcon aria-label="Tag Menu" SvgIcon={MeatBalls} {...props} />}
          </ButtonMenu>
        )}
      </TextCell>
    </Row>
  );
};
