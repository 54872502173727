/* eslint-disable @typescript-eslint/no-magic-numbers */
import React from "react";
import useMeasure from "react-use-measure";
import designConfig from "@libs/design.config";
import { useBoolean } from "@libs/hooks/useBoolean";
import { distance } from "@libs/utils/math";
import { MaxSizeWithAspect } from "components/ImageCapturing/Calibration/MaxSizeWithAspect";
import { CalibrationHeader } from "components/ImageCapturing/Calibration/CalibrationHeader";
import { ArchyLine } from "components/PatientProfile/Imaging/types/fabric";
import { CloseEditorButton } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/CloseEditorButton";
import { CalibrationDistanceModal } from "components/ImageCapturing/Calibration/CalibrationDistanceModal";
import { isDistanceTool } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/shapeUtils";
import { LazyReactImageEditor } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/components/LazyReactImageEditor";
import {
  cleanupCanvas,
  setSelectable,
} from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/fabricUtils";
import { CanvasEditorRef, DrawSettings } from "components/PatientProfile/Imaging/types/imageEditor";

type Props = {
  onClose: Func;
  onSave: (pixelsPerMm: number) => void;
  image: {
    url: string;
    w: number;
    h: number;
  };
};

const DEFAULT_DRAW_SETTINGS: DrawSettings = {
  brushSize: 3,
  mouseMode: "draw",
  drawMode: "distance",
  color: designConfig.colors.red,
};

export const ImagingSensorCalibrationDrawing: React.FC<Props> = (props) => {
  const fabricInitialized = useBoolean(false);
  const editor = React.useRef<CanvasEditorRef | null>(null);
  const calibrationModal = useBoolean(false);
  const { image, onClose, onSave } = props;
  const drawnDistanceLine = useBoolean(false);

  const drawnDistanceLineOn = drawnDistanceLine.on;
  const calibrationModalOff = calibrationModal.off;
  const [containerRef, bounds] = useMeasure({ debounce: 50 });
  const { width } = bounds;
  const handleCanvasEvent = React.useCallback(
    (ev: string) => {
      if (ev === "object:added") {
        // Distance has been drawn
        drawnDistanceLineOn();
      }
    },
    [drawnDistanceLineOn]
  );

  React.useEffect(() => {
    if (!editor.current) {
      return;
    }

    const canvas = editor.current.getInstance();

    if (fabricInitialized.isOn && canvas) {
      cleanupCanvas(canvas);
      setSelectable(canvas.getObjects(), false);
      canvas.defaultCursor = "crosshair";

      const changeHandler = editor.current.canvasChangeHandler;

      editor.current.setDrawingConfig({ ...DEFAULT_DRAW_SETTINGS, calibrationMode: true });

      changeHandler.current = handleCanvasEvent;
    }
  }, [fabricInitialized.isOn, handleCanvasEvent]);

  const handleDistanceSaved = React.useCallback(
    (distanceInMm: number) => {
      const canvas = editor.current?.getInstance();

      if (!canvas) {
        return;
      }

      calibrationModalOff();

      const line = canvas.getObjects().find((obj) => obj.type === "line" && isDistanceTool(obj)) as ArchyLine;
      const distanceInPx = distance(line);
      const pxPerMm = distanceInPx / distanceInMm;

      onSave(pxPerMm);
    },
    [calibrationModalOff, onSave]
  );

  return (
    <>
      <CalibrationHeader onClickCalibrate={calibrationModal.on} calibrateDisabled={drawnDistanceLine.isOff} />
      <MaxSizeWithAspect maxWidth={image.w} maxHeight={image.h} className="p-4">
        <div className="bg-black h-full w-full relative" ref={containerRef}>
          <CloseEditorButton onClick={onClose} tooltip="Back to Capture" />
          {width && (
            <LazyReactImageEditor
              ref={editor}
              image={image}
              bounds={bounds}
              onFabricInit={fabricInitialized.set}
            />
          )}
        </div>
      </MaxSizeWithAspect>
      {calibrationModal.isOn && (
        <CalibrationDistanceModal onSave={handleDistanceSaved} onClose={calibrationModalOff} />
      )}
    </>
  );
};
