import { isBefore, add, startOfMonth, startOfQuarter, startOfWeek, startOfYear, isSameDay } from "date-fns";
import React from "react";
import { TimeSeriesDataPoint } from "@libs/api/generated-api";
import { formatAsISODate } from "@libs/utils/date";
import { TimeSeriesResolutionOption } from "utils/routing/dashboard";
import { TimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";

// eslint-disable-next-line complexity
export const generateTimelineForDates = (
  startDate: Date,
  endDate: Date,
  resolution: TimeSeriesResolutionOption
) => {
  let start = startDate;

  const timeline: TimeSeriesDataPoint[] = [];

  switch (resolution) {
    case "MONTH": {
      start = startOfMonth(start);
      break;
    }
    case "QUARTER": {
      start = startOfQuarter(start);
      break;
    }
    case "WEEK": {
      start = startOfWeek(start);
      break;
    }
    case "YEAR": {
      start = startOfYear(start);
      break;
    }
    default:
    // No default
  }

  while (isBefore(start, endDate) || isSameDay(start, endDate)) {
    timeline.push({
      value: 0,
      startDate: formatAsISODate(start),
    });

    switch (resolution) {
      case "DAY": {
        start = add(start, { days: 1 });
        break;
      }
      case "MONTH": {
        start = add(start, { months: 1 });
        break;
      }
      case "WEEK": {
        start = add(start, { weeks: 1 });
        break;
      }
      case "YEAR": {
        start = add(start, { years: 1 });
        break;
      }
      case "QUARTER": {
        start = add(start, { months: 3 });
        break;
      }
      default: {
        break;
      }
    }
  }

  return timeline;
};

export const useTimeseriesTemplateForResolution = (params: {
  resolution: TimeSeriesResolutionOption;
  dateWindow: TimeSeriesPageSelections;
}) => {
  const { startDate, endDate } = params.dateWindow;

  return React.useMemo(() => {
    return generateTimelineForDates(startDate, endDate, params.resolution);
  }, [startDate, endDate, params.resolution]);
};
