import { FC, useState, useCallback } from "react";

import { useAccount } from "@libs/contexts/AccountContext";
import { useApiQueries } from "@libs/hooks/useApiQueries";

import { QueryResult } from "@libs/components/UI/QueryResult";
import { SetupTwilioPhone } from "components/Settings/Communications/MessagingService/SetupTwilioPhone";

import { getTwilioRegistrationStatus, fetchAvailableTwilioPhoneNumbers } from "api/communications/queries";

import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";

export const MessagingServicesSetupTwilioPhoneRoute: FC = () => {
  const { practiceId } = useAccount();
  const { query } = useQueryParams("setupTwilio");
  const from = query.from ?? paths.settingsSection({ section: "messaging-services" });

  const [phoneStartsWith, setPhoneStartsWith] = useState("");

  const [twilioRegistrationStatusQuery, fetchAvailableTwilioPhoneNumbersQuery] = useApiQueries([
    getTwilioRegistrationStatus({ args: { practiceId } }),
    fetchAvailableTwilioPhoneNumbers({
      args: { practiceId, startsWith: phoneStartsWith },
      queryOptions: { enabled: Boolean(phoneStartsWith) },
    }),
  ]);

  const refetchAvailableTwilioPhoneNumbers = fetchAvailableTwilioPhoneNumbersQuery.refetch;

  const handleSearchPhoneNumbers = useCallback(
    (value: string) => {
      if (phoneStartsWith === value) {
        refetchAvailableTwilioPhoneNumbers();

        return;
      }

      setPhoneStartsWith(value);
    },
    [phoneStartsWith, refetchAvailableTwilioPhoneNumbers]
  );

  return (
    <QueryResult queries={[twilioRegistrationStatusQuery]}>
      {twilioRegistrationStatusQuery.data ? (
        <SetupTwilioPhone
          availablePhoneNumbers={fetchAvailableTwilioPhoneNumbersQuery.data}
          onSearchPhoneNumbers={handleSearchPhoneNumbers}
          hasInitiated={twilioRegistrationStatusQuery.data.status !== "PENDING"}
          hasPhoneNumber={Boolean(twilioRegistrationStatusQuery.data.practiceCommsNumber)}
          isSearching={fetchAvailableTwilioPhoneNumbersQuery.isLoading}
          isPhoneOnly={query.phoneOnly}
          from={from}
        />
      ) : null}
    </QueryResult>
  );
};
