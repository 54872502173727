import { FC, MouseEvent } from "react";
import { FormBooleanInputElementVO, FormBooleanResponseVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { Icon } from "@libs/components/UI/Icon";
import { RadioList } from "@libs/components/UI/RadioList";
import { ReactComponent as SubItem } from "@libs/assets/icons/sub-item.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormSubmissionResponses } from "components/PatientProfile/Forms/types";
import { FormInputs } from "components/PatientProfile/Forms/FormInputs";

const Options = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const BooleanInput: FC<{
  element: FormBooleanInputElementVO;
  response?: FormBooleanResponseVO;
  responses: FormSubmissionResponses;
  onUpdate?: (updater: (last: FormSubmissionResponses) => void) => void;
}> = ({ element, onUpdate, response, responses }) => {
  const edit = Boolean(onUpdate);

  const handleYesNoClick = (e: MouseEvent<HTMLElement>, option: ListItem<typeof Options>) => {
    // eslint-disable-next-line complexity
    onUpdate?.((currentResponses) => {
      const currentValue = currentResponses[element.uuid];
      const newResponse: FormBooleanResponseVO = {
        type: "BOOLEAN",
      };

      if (currentValue?.type === "BOOLEAN" && isDefined(currentValue.response)) {
        if (option.value !== currentValue.response) {
          newResponse.response = option.value;
        }
      } else {
        newResponse.response = option.value;
      }

      currentResponses[element.uuid] = newResponse;

      if (element.conditionalElement) {
        const conditionalElementResponse = currentResponses[element.conditionalElement.uuid];

        if (!newResponse.response && conditionalElementResponse) {
          switch (conditionalElementResponse.type) {
            case "DATE":
            case "STRING": {
              conditionalElementResponse.response = "";

              break;
            }
            case "NUMBER":
            case "BOOLEAN": {
              conditionalElementResponse.response = undefined;

              break;
            }
            case "SELECT": {
              currentResponses[element.conditionalElement.uuid] = {
                type: "SELECT",
                responses: {},
              };

              break;
            }
            // No default
          }
        }
      }
    });
  };

  return (
    <>
      {edit ? (
        <RadioList
          options={Options}
          layout="horiz"
          labelTheme="regular"
          selectedValue={response?.response}
          onClick={handleYesNoClick}
          label={element.title}
        />
      ) : (
        <FormFieldInput label={element.title} edit={false} value={response?.response ? "Yes" : "No"} />
      )}
      {response?.response &&
      element.conditionalElement &&
      (edit || responses[element.conditionalElement.uuid]) ? (
        <div className="flex items-start gap-x-2">
          <Icon className="-mt-1" size="lg" SvgIcon={SubItem} />
          <div className="flex-1 flex flex-col gap-y-4">
            <FormInputs onUpdate={onUpdate} element={element.conditionalElement} responses={responses} />
          </div>
        </div>
      ) : null}
    </>
  );
};
