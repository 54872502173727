import { FC } from "react";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { AppointmentCategories } from "components/Settings/Scheduling/Sections/AppointmentCategories/AppointmentCategories";

export const AppointmentCategoriesRoute: FC = () => {
  return (
    <SettingsPanel
      title="Appointment Categories"
      description="Set up appointment categories, you can drag to change the order in which they appear throughout Archy."
      includePadding={false}
    >
      <AppointmentCategories />
    </SettingsPanel>
  );
};
