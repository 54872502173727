import { useBoolean } from "@libs/hooks/useBoolean";
import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import type { AmplifyUser } from "@aws-amplify/ui";
import { MfaSetupComplete } from "components/MfaSetup/MfaSetupComplete";
import { MfaSetupInstructions } from "components/MfaSetup/MfaSetupInstructions";
import { PageWrapper, PageWrapperCard } from "components/SignIn/PageWrapper";

export const MfaSetupPage: FC<{ mfaSetupRequired: boolean; user: AmplifyUser | null; onNext: Func }> = ({
  user,
  mfaSetupRequired,
  onNext,
}) => {
  const verifiedOtp = useBoolean(false);

  return (
    <PageWrapper>
      <PageWrapperCard size="lg" title={verifiedOtp.isOn ? "Sign In Verified!" : "Keep your account secure"}>
        {verifiedOtp.isOn ? (
          <MfaSetupComplete onNext={onNext} />
        ) : user ? (
          <MfaSetupInstructions
            user={user}
            onVerifyComplete={verifiedOtp.on}
            mfaSetupRequired={mfaSetupRequired}
            onSkip={onNext}
          />
        ) : (
          <Skeleton className="h-48" />
        )}
      </PageWrapperCard>
    </PageWrapper>
  );
};
