import React from "react";
import { UnscheduledProcedureEntryVO } from "@libs/api/generated-api";
import { phoneNumberFormatter } from "@libs/utils/phone";
import { formatCurrency } from "@libs/utils/currency";
import { formatISODate } from "@libs/utils/date";
import { EMPTY_CELL, Row } from "@libs/components/UI/GridTableComponents";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";
import { paths } from "utils/routing/paths";
import { AppointmentDateLinkCell } from "components/Dashboard/PracticeProduction/TableItems/AppointmentDateLinkCell";
import { PatientNameLink } from "components/Dashboard/Tables/PatientNameLink";
import { MessagePatientCell } from "components/Dashboard/Tables/MessagePatientCell";
import { TreatmentPlanLink } from "components/Dashboard/Tables/TreatmentPlanLink";
import { TruncatedCell } from "components/Dashboard/Tables/TruncatedCell";

type Props = {
  row: UnscheduledProcedureEntryVO;
  last?: boolean;
};

export const UnscheduledTreatmentByPatientRow: React.FC<Props> = ({ row, last }) => {
  const {
    patient,
    patientPhone,
    carrier,
    nextAppointmentDate,
    nextAppointmentId,
    previousAppointmentDate,
    previousAppointmentId,
    dentalProcedure,
    negotiatedRate,
    priority,
    provider,
    createdDate,
    treatmentPlanUuid,
    procedureArea,
    treatmentPlanName,
  } = row;

  const cellProps = { last, to: paths.patient({ patientId: patient.id }) };
  const lastApptDetails = {
    appointmentId: previousAppointmentId,
    patientId: patient.id,
  };
  const nextApptDetails = {
    appointmentId: nextAppointmentId,
    patientId: patient.id,
  };

  return (
    <Row data-testid={`row-${patient.id}`}>
      <DashboardLinkCell {...cellProps}>
        <TruncatedCell>
          <PatientNameLink patient={patient} />
        </TruncatedCell>
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{createdDate ? formatISODate(createdDate) : null}</DashboardLinkCell>

      <DashboardLinkCell {...cellProps}>
        {patientPhone ? phoneNumberFormatter(patientPhone) : EMPTY_CELL}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        <TruncatedCell tooltip={carrier?.name}>{carrier?.name}</TruncatedCell>
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        <TruncatedCell tooltip={procedureArea}>{procedureArea}</TruncatedCell>
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{dentalProcedure.cdtCode}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        <TruncatedCell tooltip={dentalProcedure.name}>{dentalProcedure.name}</TruncatedCell>
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        <TruncatedCell tooltip={provider.shortDisplayName}>{provider.shortDisplayName}</TruncatedCell>
      </DashboardLinkCell>

      <DashboardLinkCell {...cellProps}>
        <TreatmentPlanLink treatmentPlanUuid={treatmentPlanUuid} patientId={patient.id}>
          {treatmentPlanName}
        </TreatmentPlanLink>
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="center">
        {priority ?? EMPTY_CELL}
      </DashboardLinkCell>
      <AppointmentDateLinkCell {...cellProps} {...lastApptDetails} date={previousAppointmentDate} />
      <AppointmentDateLinkCell {...cellProps} {...nextApptDetails} date={nextAppointmentDate} />
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(negotiatedRate)}
      </DashboardLinkCell>
      <MessagePatientCell {...cellProps} patientId={patient.id} />
    </Row>
  );
};
