import { FC } from "react";
import { Link } from "react-router-dom";

import { TwilioOnboardingStatusVO } from "@libs/api/generated-api";
import { Banner } from "@libs/components/UI/Banner";

import { isMassSmsConfigured } from "components/Communications/utils";

import { paths } from "utils/routing/paths";

interface Props {
  twilioStatus: TwilioOnboardingStatusVO;
}

export const MessagingSettingsBanner: FC<Props> = ({ twilioStatus }) => {
  if (isMassSmsConfigured(twilioStatus)) {
    return null;
  }

  return (
    <Banner className="w-full rounded text-xs" theme="info">
      <span>
        To send mass SMS you will need to&nbsp;
        <Link
          className="font-sansSemiBold text-archyBlue-500"
          to={paths.settingsSection({ section: "messaging-services" })}
        >
          enable messaging services
        </Link>
        .
      </span>
    </Banner>
  );
};
