import { FC } from "react";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { HolidayCalendar } from "components/Settings/PracticeSetup/Sections/HolidayCalendar/HolidayCalendar";

export const PracticeHolidaysRoute: FC = () => {
  return (
    <SettingsPanel
      title="Holiday Calendar"
      description="Configure holiday calendar for your business based on your region"
    >
      <HolidayCalendar />
    </SettingsPanel>
  );
};
