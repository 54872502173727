import { FC } from "react";

import { isOneOf } from "@libs/utils/isOneOf";
import { formatNumberWithCommas } from "@libs/utils/formatNumber";

import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";

import { TooltipLabel } from "components/UI/TooltipLabel";
import { ToggleButton } from "components/UI/ToggleButton";
import { ToggleButtonList } from "components/UI/ToggleButtonList";

import { SendMessageCampaignDraft } from "components/Communications/MessagePatients/types";
import { MAX_SMS_SEND_COUNT, MAX_EMAIL_SEND_COUNT } from "components/Communications/MessagePatients/utils";

interface Props {
  channels: SendMessageCampaignDraft["channels"];
  onChannelsChange: (channels: SendMessageCampaignDraft["channels"]) => void;
  canSendPatientPreferred: boolean;
  canSendSms: boolean;
  canSendEmail: boolean;
  canSendMail?: boolean;
  isMaxSmsSendCount: boolean;
  isMaxEmailSendCount: boolean;
}

export const ChannelsFields: FC<Props> = ({
  channels,
  onChannelsChange,
  canSendPatientPreferred,
  canSendSms,
  canSendEmail,
  canSendMail = false,
  isMaxEmailSendCount,
  isMaxSmsSendCount,
}) => {
  const patientPreferredMaxSendCountContent = `Patient preferred messages can only be sent up to ${MAX_SMS_SEND_COUNT} patients at a time.`;

  return (
    <div className="flex flex-col gap-y-2">
      <FormFieldLabel className="font-sansSemiBold text-xs" content="Channel" required />

      <div className="flex items-center gap-x-2">
        <ToggleButton
          type="checkbox"
          checked={channels.includes("PATIENT_PREFERRED")}
          onChange={() => onChannelsChange(["PATIENT_PREFERRED"])}
          disabled={!canSendPatientPreferred}
          shape="mediumPill"
        >
          <TooltipLabel
            tooltip={{
              content: (
                <span className="whitespace-pre-line">
                  This option will respect the patient&apos;s preferred contact method and send only a SMS or
                  an Email.
                  {isMaxSmsSendCount ? `\n\n${patientPreferredMaxSendCountContent}` : null}
                </span>
              ),
              ariaLabel: isMaxSmsSendCount ? patientPreferredMaxSendCountContent : undefined,
              placement: "bottom",
            }}
            theme="slate700"
            disabled={!canSendPatientPreferred}
          >
            Preferred
          </TooltipLabel>
        </ToggleButton>

        {canSendMail ? null : <VerticalDivider className="mx-1" size="lg" />}

        <ToggleButtonList
          type="checkbox"
          options={[
            {
              label: (
                <TooltipLabel
                  tooltip={{
                    content: isMaxSmsSendCount
                      ? `SMS can only be sent up to ${MAX_SMS_SEND_COUNT} patients at a time.`
                      : "",
                    placement: "bottom",
                  }}
                  theme="slate700"
                  disabled={!canSendSms}
                >
                  SMS
                </TooltipLabel>
              ),
              value: "SMS",
              disabled: !canSendSms,
            },
            {
              label: (
                <TooltipLabel
                  tooltip={{
                    content: isMaxEmailSendCount
                      ? `Email can only be sent up to ${formatNumberWithCommas(
                          MAX_EMAIL_SEND_COUNT
                        )} patients at a time.`
                      : "",
                    placement: "bottom",
                  }}
                  theme="slate700"
                  disabled={!canSendEmail}
                >
                  Email
                </TooltipLabel>
              ),
              value: "EMAIL",
              disabled: !canSendEmail,
            },
          ]}
          selectedValues={
            new Set(channels.filter((channel) => !isOneOf(channel, ["PATIENT_PREFERRED", "MAIL"])))
          }
          onChange={(newSet) => onChannelsChange(newSet.size === 0 ? ["PATIENT_PREFERRED"] : [...newSet])}
          shape="mediumPill"
        />

        {canSendMail ? (
          <>
            <VerticalDivider className="mx-1" size="lg" />

            <ToggleButton
              type="checkbox"
              checked={channels.includes("MAIL")}
              onChange={() => onChannelsChange(["MAIL"])}
              shape="mediumPill"
            >
              Mail
            </ToggleButton>
          </>
        ) : null}
      </div>
    </div>
  );
};
