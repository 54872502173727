import { PerioChartExamEntryVO, PerioChartExamSettingsVO, PerioChartExamVO } from "@libs/api/generated-api";

export enum PerioChartToothSurfaceType {
  FACIAL,
  LINGUAL,
}

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
export type PerioChartControlCount = 1 | 2 | 3;

export type PerioChartAutoAdvance = PerioChartExamSettingsVO["autoAdvance"];

export type PerioChartSequenceType = PerioChartExamEntryVO["perioSequenceType"] | "AUTOCAL";

export type PerioChartVisibilitySettings = Pick<
  PerioChartExamSettingsVO,
  | "showProbingDepth"
  | "showGingivalMargin"
  | "showAutoCAL"
  | "showMGJ"
  | "showMobility"
  | "showFurcation"
  | "showPlaque"
  | "showCalculus"
  | "showBleeding"
  | "showSuppuration"
>;

export type PerioChartThresholdSettings = Pick<
  PerioChartExamSettingsVO,
  | "probingDepthThreshold"
  | "gingivalMarginThreshold"
  | "mgjThreshold"
  | "mobilityThreshold"
  | "furcationThreshold"
  | "autoCALThreshold"
>;

export type PerioChartExamPayload = {
  providerId: number;
  date: string;
  note?: string;
};

export type PerioChartExamInfo = Omit<PerioChartExamVO, "entries">;

export type PerioChartExamEntryField = keyof Pick<
  PerioChartExamEntryVO,
  "mbValue" | "bValue" | "dbValue" | "mlValue" | "lValue" | "dlValue"
>;
