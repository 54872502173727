import { FC, useMemo, useState } from "react";
import { toggleSet } from "@libs/utils/toggleSet";
import { useLocation } from "react-router-dom";
import { SideNavigationTab } from "@libs/components/UI/SideNavigationTab";
import { SettingsNavigationItem, SettingsNestedNavigationItem } from "components/Settings/routes";
import { SettingsNestedNavigation } from "components/Settings/SettingsNavigationItem";

interface Props {
  menu: (SettingsNestedNavigationItem | SettingsNavigationItem)[];
}

const getVisitedSectionId = ({ menu, pathname }: { menu: Props["menu"]; pathname: string }) => {
  const menuItem = menu.find((item) => {
    if (!("children" in item)) {
      return false;
    }

    const subChild = item.children.find((child) => child.to === pathname);

    return Boolean(subChild);
  });

  if (menuItem) {
    return menuItem.label;
  }

  return undefined;
};

const useSettingsNavigation = ({ menu }: Props) => {
  const pathname = useLocation().pathname;
  const visitedSectionId = useMemo(() => {
    return getVisitedSectionId({ menu, pathname });
  }, [menu, pathname]);

  const [openIds, setOpenIds] = useState<Set<string>>(() => {
    return visitedSectionId ? new Set([visitedSectionId]) : new Set();
  });

  const handleToggle = (id: string) => {
    setOpenIds((last) => toggleSet(last, id));
  };

  return {
    visitedSectionId,
    openIds,
    handleToggle,
  };
};

export const SettingsNavigation: FC<Props> = ({ menu }) => {
  const { visitedSectionId, openIds, handleToggle } = useSettingsNavigation({ menu });

  return (
    <div className="h-full flex flex-col">
      <div className="font-sansSemiBold h-14 flex items-center px-6">Settings</div>
      <div className="flex-1 min-h-0 p-3 overflow-y-auto">
        {menu.map((item) => (
          <div key={item.label}>
            {"children" in item ? (
              <SettingsNestedNavigation
                item={item}
                isOpen={openIds.has(item.label)}
                isChildSelected={visitedSectionId === item.label}
                onToggle={() => handleToggle(item.label)}
              />
            ) : (
              <SideNavigationTab to={item.to}>{item.label}</SideNavigationTab>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
