import React from "react";
import { CollectedPaymentByProviderComboEntryVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { EMPTY_CELL, Row } from "@libs/components/UI/GridTableComponents";

import { Cell } from "components/Dashboard/Tables/Cell";
import { SectionHeader } from "components/Dashboard/Tables/SectionHeader";
import { SectionFooter } from "components/Dashboard/Tables/SectionFooter";

type Props = {
  sectionName: string;
  total: number;
  providers: CollectedPaymentByProviderComboEntryVO[];
  last?: boolean;
};
export const ByAssociatedProviderTableSection: React.FC<Props> = ({ providers, sectionName, total }) => {
  return (
    <>
      <SectionHeader
        className={`
          flex
          items-center
          gap-4
          col-span-full
          font-sansSemiBold
          text-xs
        `}
      >
        {sectionName}
      </SectionHeader>
      {providers.map((row, index) => {
        const cellProps = { last: index === providers.length - 1 };

        return (
          <Row key={`${row.provider?.id ?? "unassigned"}-${index}`}>
            <Cell {...cellProps} />
            <Cell {...cellProps}>{row.provider?.fullDisplayName ?? EMPTY_CELL}</Cell>
            <Cell {...cellProps} align="right">
              {row.patientCount}
            </Cell>
            <Cell {...cellProps} align="right">
              {formatCurrency(row.totalInsurancePaymentAmount)}
            </Cell>
            <Cell {...cellProps} align="right">
              {formatCurrency(row.totalPatientPaymentAmount)}
            </Cell>
            <Cell {...cellProps} align="right">
              {formatCurrency(row.totalPaymentAmount)}
            </Cell>
          </Row>
        );
      })}
      <Row>
        <SectionFooter />
        <SectionFooter>{sectionName} Totals</SectionFooter>
        <SectionFooter className="col-span-3" />

        <SectionFooter align="right">{formatCurrency(total)}</SectionFooter>
      </Row>
    </>
  );
};
