import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { formatPhoneNumber } from "@libs/utils/phone";
import { useMemo } from "react";
import { useCurrentUser } from "contexts/CurrentUserContext";

type MeetingID = "training1" | "training2" | "training3" | "training4" | "sensorTesting";

// Test calendly url: https://calendly.com/sholbert-capl/image-sensor-setup
// Test livestorm url: "https://app.livestorm.co/p/128edca0-613a-4ad6-ba7a-5738562655d7/form",

export const useOnboardingMeetings = () => {
  const { phone } = useCurrentUser();
  const practice = useCurrentPractice();

  return useMemo(() => {
    const phoneNum = phone ? formatPhoneNumber(phone) : null;

    const defaultAnswers = [phoneNum ?? "", practice.name];
    const meetings: Record<
      MeetingID,
      | {
          title: string;
          url: string;
          id: MeetingID;
          defaultAnswers?: string[];
          type: "calendly" | "livestorm";
        }
      | undefined
    > = {
      training1: {
        title: "Training 1",
        id: "training1",
        type: "livestorm",
        url: "https://app.livestorm.co/p/91ba9ec5-f008-4627-a66d-90c6b5df72de/form",
      },
      training2: {
        title: "Training 2",
        id: "training2",
        type: "livestorm",
        url: "https://app.livestorm.co/p/e71b89be-7cb5-4f30-b030-988436ab476e/form",
      },
      training3: {
        title: "Training 3",
        url: "https://app.livestorm.co/p/415ff588-1503-45d8-b069-a9e5ac3b7ed2/form",
        id: "training3",
        type: "livestorm",
      },
      training4: {
        title: "Training 4",
        url: "https://app.livestorm.co/p/bf21484b-3b9d-42d0-b7ab-22683edbc89a/form",
        id: "training4",
        type: "livestorm",
      },
      sensorTesting: {
        title: "Archy Imaging Testing",
        url: "https://calendly.com/d/5c2-65f-hyr/archy-imaging-testing",
        defaultAnswers,
        id: "sensorTesting",
        type: "calendly",
      },
    };

    return (mtgId: string) => meetings[mtgId as MeetingID];
  }, [phone, practice.name]);
};
