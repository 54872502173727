import { FC, PropsWithChildren } from "react";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as BackIcon } from "@libs/assets/icons/left-arrow.svg";
import { FlyoverHeader } from "components/UI/FlyoverComponents";

interface Props {
  onRequestClose: Func;
  onBack?: Func;
}

export const FlyoverReelHeader: FC<PropsWithChildren<Props>> = ({ children, onBack, onRequestClose }) => {
  return (
    <FlyoverHeader size="sm" onClose={onRequestClose}>
      <div className="flex items-center gap-x-2">
        {onBack ? (
          <ButtonIcon
            tooltip={{ content: "Back", theme: "SMALL" }}
            theme="primary"
            onClick={onBack}
            SvgIcon={BackIcon}
          />
        ) : null}
        {children}
      </div>
    </FlyoverHeader>
  );
};
