import React from "react";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as AlertIcon } from "@libs/assets/icons/error.svg";

interface Props {
  isAvailable?: boolean;
  text: string;
}

const cxStyles = {
  wrapper: "inline-block relative ml-1",
  tooltip: `
    hidden
    group-hover:block
    top-[calc(100%+6px)]
    left-[calc(50%+2px)]
    -translate-x-1/2
    absolute
    px-2
    py-1
    text-center
    rounded
    z-10
    bg-greyDark
    text-white
    text-xs
    whitespace-nowrap
  `,
  arrowContainer: `
    w-4
    overflow-hidden
    inline-block
    absolute
    top-0
    -translate-y-full
    -translate-x-1/2
    left-1/2
  `,
  arrow: "h-2 w-2 bg-greyDark rotate-45 transform origin-bottom-left",
};

export const RoomOptionContent: React.FC<Props> = ({ isAvailable, text }) => {
  return (
    <span className="flex whitespace-nowrap items-center">
      {text}
      {isAvailable === false ? (
        <span className={cxStyles.wrapper}>
          <Icon
            SvgIcon={AlertIcon}
            size="md"
            theme="error"
            tooltip={{
              theme: "SMALL",
              content: "Room Unavailable",
            }}
          />
          <div className={cxStyles.tooltip}>
            <div className={cxStyles.arrowContainer}>
              <div className={cxStyles.arrow} />
            </div>
            Already booked
          </div>
        </span>
      ) : null}
    </span>
  );
};
