import React, { FormEvent, useCallback, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Auth } from "aws-amplify";
import { useValidation } from "@libs/hooks/useValidation";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { CognitoErrorCode } from "@libs/utils/cognito";
import { PageWrapper, PageWrapperCard } from "components/SignIn/PageWrapper";
import { SignInProps } from "components/SignIn/MainSignIn";
import { ForgotPasswordForm } from "components/SignIn/ForgotPasswordForm";
import { ResetPasswordForm } from "components/SignIn/ResetPasswordForm";
import { handleError } from "utils/handleError";

import {
  getEmailValidationSchema,
  getOtpValidationSchema,
  getPasswordValidationSchema,
} from "components/SignIn/validationUtil";
import { paths } from "utils/routing/paths";
import { useQueryParams } from "hooks/useQueryParams";
import { useSignedOutAuthChannelListeners } from "components/Main/useSignedOutAuthChannelListeners";
import { usePendo } from "components/Main/usePendo";

enum StepType {
  FORGOT_PASSWORD = "forgtoPassword",
  RESET_PASSWORD = "resetPassword",
}

export interface ForgotPasswordProps extends SignInProps {
  cognitoErrorCode: CognitoErrorCode | null;
  confirmPassword: string;
  newPassword: string;
  otp: string;
  step: StepType;
}

const initialState = {
  cognitoErrorCode: null,
  confirmPassword: "",
  email: "",
  errorMessage: "",
  isLoading: false,
  newPassword: "",
  password: "",
  otp: "",
  step: StepType.FORGOT_PASSWORD,
};

export type ForgotPasswordInputType = "confirmPassword" | "email" | "newPassword" | "otp";

// This message is returned when a user has never confirmed their account prior:
const INVALID_ACCOUNT_ERROR = "User password cannot be reset in the current state.";

export const ForgotPassword: React.FC = () => {
  const navigate = useNavigate();
  const { query } = useQueryParams("forgotPassword");

  usePendo({ isSignedIn: false });
  useSignedOutAuthChannelListeners(query);
  usePageTitle("Forgot Password");

  const [forgotPasswordState, setForgotPasswordState] = useState<ForgotPasswordProps>(initialState);

  const updateForgotPasswordState = useCallback((updates: Partial<ForgotPasswordProps>) => {
    setForgotPasswordState((last) => {
      return {
        ...last,
        ...updates,
      };
    });
  }, []);

  const handleGoBack = useCallback(() => {
    if (forgotPasswordState.step === StepType.FORGOT_PASSWORD) {
      navigate(paths.signIn({ returnUrl: query.returnUrl, lastUserId: query.lastUserId }));
    } else {
      updateForgotPasswordState(initialState);
    }
  }, [navigate, forgotPasswordState.step, updateForgotPasswordState, query.returnUrl, query.lastUserId]);

  const handleAuthReqError = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    (error: any) => {
      updateForgotPasswordState({
        // eslint-disable-next-line @typescript-eslint/no-unsafe-member-access
        cognitoErrorCode: (error.code as CognitoErrorCode | null) || ("Unknown" as CognitoErrorCode),
        isLoading: false,
      });
    },
    [updateForgotPasswordState]
  );

  const forgotPasswordForm = useValidation(
    { email: forgotPasswordState.email },
    {
      email: getEmailValidationSchema(forgotPasswordState.email),
    }
  );

  const resetPasswordForm = useValidation(
    {
      confirmPassword: forgotPasswordState.confirmPassword,
      otp: forgotPasswordState.otp,
      newPassword: forgotPasswordState.newPassword,
    },
    {
      confirmPassword: getPasswordValidationSchema({
        password: forgotPasswordState.confirmPassword,
        comparisonPassword: forgotPasswordState.newPassword,
        validatePwRules: true,
      }),
      otp: getOtpValidationSchema(forgotPasswordState.otp),
      newPassword: getPasswordValidationSchema({
        password: forgotPasswordState.newPassword,
        comparisonPassword: forgotPasswordState.confirmPassword,
        validatePwRules: true,
      }),
    }
  );

  const handleInputChange = useCallback(
    (inputName: ForgotPasswordInputType, value: string) => {
      forgotPasswordForm.reset();
      resetPasswordForm.reset();
      updateForgotPasswordState({ cognitoErrorCode: null, errorMessage: "", [inputName]: value });
    },
    [forgotPasswordForm, resetPasswordForm, updateForgotPasswordState]
  );

  const handleForgotPassword = useCallback(
    async (e: FormEvent<HTMLFormElement> | React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.preventDefault();

      const validate = forgotPasswordForm.validate();

      if (!validate.$isValid) {
        return;
      }

      updateForgotPasswordState({ isLoading: true });

      try {
        await Auth.forgotPassword(forgotPasswordState.email);
        updateForgotPasswordState({ isLoading: false, step: StepType.RESET_PASSWORD });
      } catch (error) {
        const cognitoError = error as { code?: CognitoErrorCode; message?: string };

        if (cognitoError.message !== INVALID_ACCOUNT_ERROR) {
          handleError(error);
        }

        handleAuthReqError(error);
      }
    },
    [forgotPasswordForm, updateForgotPasswordState, forgotPasswordState.email, handleAuthReqError]
  );

  const handleResetPassword = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const validate = resetPasswordForm.validate();

      if (!validate.$isValid) {
        return;
      }

      try {
        await Auth.forgotPasswordSubmit(
          forgotPasswordState.email,
          forgotPasswordState.otp,
          forgotPasswordState.newPassword
        );
        updateForgotPasswordState({ isLoading: false });
        navigate(paths.signIn({ returnUrl: query.returnUrl, lastUserId: query.lastUserId }));
      } catch (error) {
        handleAuthReqError(error);
        handleError(error);
      }
    },
    [
      forgotPasswordState.email,
      forgotPasswordState.newPassword,
      forgotPasswordState.otp,
      handleAuthReqError,
      navigate,
      query.returnUrl,
      query.lastUserId,
      resetPasswordForm,
      updateForgotPasswordState,
    ]
  );

  return (
    <PageWrapper handleGoBack={handleGoBack}>
      <PageWrapperCard
        title={forgotPasswordState.step === StepType.FORGOT_PASSWORD ? "Forgot Password?" : "Reset Password"}
      >
        {forgotPasswordState.step === StepType.FORGOT_PASSWORD ? (
          <ForgotPasswordForm
            handleForgotPassword={handleForgotPassword}
            handleInputChange={handleInputChange}
            forgotPasswordState={forgotPasswordState}
            validateResult={forgotPasswordForm.result}
          />
        ) : (
          <ResetPasswordForm
            handleForgotPassword={handleForgotPassword}
            handleInputChange={handleInputChange}
            handleResetPassword={handleResetPassword}
            forgotPasswordState={forgotPasswordState}
            validateResult={resetPasswordForm.result}
          />
        )}
      </PageWrapperCard>
    </PageWrapper>
  );
};
