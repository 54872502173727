import { FC, useEffect, useRef } from "react";
import { cx } from "@libs/utils/cx";

type CxLineClampLines = "line-clamp-3";

/**
 * Given some text will determine if the text content overflows the clamped space. If so,
 * onOverlfowClamp will be called and parent's can decide whether or not to show an "expand"
 * button UI.
 * @param text a string of text to be displayed
 * @param isClamped a boolean value to determine if the text should be clamped or not.
 * @param lines an optional tailwind class for setting the number of lines to clamp to
 * @param preserveWhitespace an optional boolean to say whether text whitespace should be preserved
 * @returns JSX
 *
 * Other notes:
 * Currently the component always assumes you are beginning with isClamped true
 * If you start with it false, it means we don't clamp the text and therefore
 * can't determine whether the text has overflow or not.
 * This can be supported in future versions. Current workaround is that
 * if you start with isClamp false, onOverflowClamp will always fire.
 */

export const ClampText: FC<{
  text: string;
  isClamped: boolean;
  lines?: CxLineClampLines;
  preserveWhitespace?: boolean;
  onOverflowClamp: Func;
}> = ({ text, preserveWhitespace, lines = "line-clamp-3", isClamped, onOverflowClamp }) => {
  const textRef = useRef<HTMLParagraphElement>(null);
  const initialIsClamped = useRef(isClamped);

  useEffect(() => {
    if (
      (textRef.current && textRef.current.scrollHeight > textRef.current.clientHeight) ||
      !initialIsClamped.current
    ) {
      onOverflowClamp();
    }
  }, [text, onOverflowClamp]);

  return (
    <p
      ref={textRef}
      className={cx("break-word", preserveWhitespace && "whitespace-pre-wrap", isClamped && lines)}
    >
      {text.trim()}
    </p>
  );
};
