import { cx } from "@libs/utils/cx";
import { ColumnTextAlign } from "components/Dashboard/Tables/types";

export const cxSharedStyles = {
  cellPadding: "px-5 py-3",
};

const cxAlignText = (align: ColumnTextAlign) => {
  return {
    left: "text-left",
    right: "justify-end text-right",
    center: "justify-center text-center",
  }[align];
};
const cxFooterStyle = (align: ColumnTextAlign = "left", className?: string) => {
  return cx(
    `flex
            bg-transparent
            items-center
            border-t
            border-greyLight
            font-sansSemiBold
            text-xs`,
    cxAlignText(align),
    cxSharedStyles.cellPadding,
    className
  );
};

export const cxTableStyles = {
  cell: (last?: boolean, align: ColumnTextAlign = "left") =>
    cx(
      "text-xs",
      !last && "border-b border-greyLighter flex items-center",
      cxAlignText(align),
      cxSharedStyles.cellPadding
    ),
  tableWrapper: "flex-1 min-h-0 flex flex-col",
  tableContainer: (isPrinting = false, className?: string) =>
    cx("flex-1 print:overflow-y-visible", !isPrinting && "overflow-y-auto", className),
  tableHeadingText: "py-4 inline-block align-baseline font-sansSemiBold text-sm",
  tableHeading: (hasTabs = false) =>
    cx("flex items-center justify-between bg-white", hasTabs ? "pr-5" : "px-5"),
  tableTabsContainer: "flex",
  tableRightControlsContainer: "flex gap-3 py-2",
  header: (align: ColumnTextAlign, isPrinting = false) =>
    cx(
      `bg-white
       flex
       items-center
       gap-2
       border-y
       border-greyLighter
       min-h-14
       text-xs
       font-sansSemiBold`,
      cxAlignText(align),
      !isPrinting && `sticky top-0 z-20`,
      cxSharedStyles.cellPadding
    ),
  footer: (align: ColumnTextAlign = "left", isPrinting = false) =>
    cxFooterStyle(align, isPrinting ? undefined : "sticky bottom-0 z-20"),

  // Do not make the tableFooterContainer sticky. This table can be printed as a PDF and sticky will push the footer to the bottom of the page instead of the bottom of the table (multiple pages).
  tableFooterContainer: "overflow-hidden min-h-10 bg-white",
  sectionFooter: (align: ColumnTextAlign = "left") => cxFooterStyle(align),
  sectionHeader: "sticky top-[56px] z-10 bg-slate-200 px-5 py-1",
};
