import { DailyHuddleOverviewRequest, DailyHuddleRequest } from "@libs/api/generated-api";
import { makeQuery, getNextPageParam, makeInfiniteQuery } from "@libs/utils/queries";
import { fiveMinuteCache } from "@libs/utils/queryCache";

export const getDailyHuddle = makeInfiniteQuery({
  queryKey: ["practices", "getDailyHuddle"],
  formatParams: ({
    practiceId,
    data,
    ...query
  }: {
    practiceId: number;
    data: DailyHuddleRequest;
    pageNumber: number;
    pageSize: number;
  }) => [practiceId, query, data],
  queryOptions: { ...fiveMinuteCache, getNextPageParam },
});

export const getDailyHuddleOverview = makeQuery({
  queryKey: ["practices", "getDailyHuddleOverview"],
  formatParams: (args: { practiceId: number; data: DailyHuddleOverviewRequest }) => [
    args.practiceId,
    args.data,
  ],
  queryOptions: fiveMinuteCache,
});
