import designConfig from "@libs/design.config";

export const DEFAULT_LINE_WIDTH = 5;

export const DRAW_LINE_OPACITY = 0.8;
export const EDITOR_COLOR_BLACK = "#000000";
export const EDITOR_COLORS = [
  designConfig.colors.red,
  designConfig.colors.green,
  designConfig.colors.blue,
  designConfig.colors.orange,
  designConfig.colors.yellow,
  designConfig.colors.magenta,
  EDITOR_COLOR_BLACK,
];
