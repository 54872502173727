import { FC, FormEvent, useCallback, useMemo, useState } from "react";
import { DentalProcedureVO, PatientProcedureVO, PatientToothVO, ProviderVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { ModalForm, ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import {
  DraftPatientProcedureRequest,
  draftToSavePatientProcedure,
  patientProcedureToDraftPatientProcedure,
  SavePatientProcedureUpdate,
} from "components/Charting/draftPatientProcedure";
import { getPatientProcedureSchema } from "components/Charting/patientProcedureSchema";
import { AddProcedureForm } from "components/Charting/AddProcedureForm";
import {
  AddProcedureFormHeaderContent,
  AddProcedureFormHeaderContentWrapper,
} from "components/Charting/AddProcedureFormHeader";
import { useNow } from "hooks/useNow";

interface Props {
  onCancel: Func;
  onSubmit: (draft: SavePatientProcedureUpdate) => void;
  onSwitchProcedure: (params?: {
    newDentalProcedure?: DentalProcedureVO;
    currentDraft?: DraftPatientProcedureRequest;
  }) => void;
  dentalProcedure: DentalProcedureVO;
  patientProcedure: PatientProcedureVO;
  providers: ProviderVO[];
  validateOptions?: {
    eager?: boolean;
    prosthetic?: boolean;
    provider?: boolean;
  };
  teeth: PatientToothVO[];
  isSaving: boolean;
  editStatus?: boolean;
}

export const EditPatientProcedureForm: FC<Props> = ({
  onSubmit,
  onSwitchProcedure,
  dentalProcedure,
  patientProcedure,
  providers,
  validateOptions,
  teeth,
  isSaving,
  onCancel,
  editStatus,
}) => {
  const now = useNow();
  const [draftPatientProcedure, setDraftPatientProcedure] = useState<DraftPatientProcedureRequest>(() =>
    patientProcedureToDraftPatientProcedure(patientProcedure, now)
  );

  const handleUpdateDraft = useCallback((updates: Partial<DraftPatientProcedureRequest>) => {
    setDraftPatientProcedure((last) => {
      return {
        ...last,
        ...updates,
      };
    });
  }, []);

  const schema = useMemo(() => {
    return getPatientProcedureSchema(dentalProcedure, draftPatientProcedure, teeth, validateOptions);
  }, [draftPatientProcedure, dentalProcedure, teeth, validateOptions]);

  const { validate, result } = useValidation(draftPatientProcedure, schema, {
    isValidating: validateOptions?.eager,
  });

  const handleFormSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!validate().$isValid) {
        return;
      }

      onSubmit(draftToSavePatientProcedure(draftPatientProcedure, dentalProcedure, teeth, patientProcedure));
    },
    [validate, onSubmit, draftPatientProcedure, dentalProcedure, teeth, patientProcedure]
  );

  return (
    <ModalForm fieldLayout="labelIn" onSubmit={handleFormSubmit}>
      <ModalContent>
        <AddProcedureForm
          patientProcedure={patientProcedure}
          dentalProcedure={dentalProcedure}
          isExpanded={true}
          teeth={teeth}
          draft={draftPatientProcedure}
          validation={result}
          providers={providers}
          editStatus={editStatus}
          validateOptions={validateOptions}
          onUpdateDraft={handleUpdateDraft}
          headerContent={
            <AddProcedureFormHeaderContentWrapper>
              <AddProcedureFormHeaderContent dentalProcedure={dentalProcedure} />
              <Button
                className="text-xs"
                theme="link"
                onClick={() => {
                  onSwitchProcedure({
                    currentDraft: draftPatientProcedure,
                    newDentalProcedure: dentalProcedure,
                  });
                }}
              >
                Change Procedure
              </Button>
            </AddProcedureFormHeaderContentWrapper>
          }
        />
      </ModalContent>
      <ModalFooter>
        <Button className="min-w-button" theme="secondary" onClick={onCancel}>
          Cancel
        </Button>
        <AsyncButton className="min-w-button" isLoading={isSaving} type="submit">
          Save
        </AsyncButton>
      </ModalFooter>
    </ModalForm>
  );
};
