import { ChangeEvent, FC, useCallback } from "react";
import { FormStringResponseVO, FormTextInputElementVO } from "@libs/api/generated-api";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormSubmissionResponses } from "components/PatientProfile/Forms/types";

export const StringInput: FC<{
  element: FormTextInputElementVO;
  response?: FormStringResponseVO;
  onUpdate?: (updater: (last: FormSubmissionResponses) => void) => void;
}> = ({ element, onUpdate, response }) => {
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onUpdate?.((currentResponses) => {
        currentResponses[element.uuid] = {
          type: "STRING",
          response: e.target.value,
        };
      });
    },
    [onUpdate, element.uuid]
  );

  return (
    <FormFieldInput
      edit={Boolean(onUpdate)}
      value={response?.response}
      label={element.title}
      onChange={handleChange}
    />
  );
};
