import { Fragment, PropsWithChildren, useMemo } from "react";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { templateStringToTemplateVariableNodes } from "components/UI/RichTextEditor/templateVariables";

export const ActionMessage: React.FC<PropsWithChildren<{ smsTemplate?: string; emailTemplate?: string }>> = ({
  smsTemplate,
  emailTemplate,
}) => {
  const templateNodes = useMemo(() => {
    const nodes = smsTemplate
      ? templateStringToTemplateVariableNodes(smsTemplate, [])
      : emailTemplate
        ? templateStringToTemplateVariableNodes(emailTemplate, [])
        : null;

    if (nodes) {
      const p = nodes.root.children[0];

      if (p.type === "paragraph") {
        return p.children;
      }

      return null;
    }

    return null;
  }, [smsTemplate, emailTemplate]);

  const message = templateNodes?.map((node, index) => (
    <Fragment key={index}>
      {node.type === "templateVariable" ? (
        <span
          className="font-sansSemiBold capitalize"
          data-testid="communications-action-row-template-variable"
        >
          {node.text}
        </span>
      ) : node.type === "linebreak" ? (
        "\n"
      ) : node.type === "text" ? (
        node.text
      ) : null}
    </Fragment>
  ));

  return message?.length ? (
    <div className="flex items-center overflow-hidden">
      <FloatingTooltip
        content={<p className="whitespace-pre-line">{message}</p>}
        theme="MEDIUM"
        placement="top"
      >
        <p className="truncate">{message}</p>
      </FloatingTooltip>
    </div>
  ) : (
    "No Message"
  );
};
