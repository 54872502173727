import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AppointmentCategoryVO } from "@libs/api/generated-api";
import {
  AppointmentProcedures,
  TREATMENT_PLAN_CATEGORY_ID,
} from "components/ScheduleAppointments/useAppointmentCategoriesAndProcedures";
import { calculateDuration } from "utils/calculateProceduresDuration";

export const useAppointmentDuration = ({
  procedures,
  initialDuration,
  selectedCategory,
  selectedCategoryId,
}: {
  procedures: AppointmentProcedures;
  initialDuration?: number;
  selectedCategory?: AppointmentCategoryVO;
  selectedCategoryId?: number;
}) => {
  const selectedProceduresList = useMemo(
    () => [...procedures.category, ...procedures.additional],
    [procedures]
  );

  const [duration, setDuration] = useState(() => {
    if (initialDuration) {
      return initialDuration;
    }

    if (selectedCategory) {
      return selectedCategory.duration;
    }

    if (selectedCategoryId === TREATMENT_PLAN_CATEGORY_ID) {
      return calculateDuration(selectedProceduresList);
    }

    return undefined;
  });

  const update = useCallback((value: number | undefined) => {
    setDuration(value);
  }, []);

  const calculate = useCallback(() => {
    setDuration(calculateDuration(selectedProceduresList));
  }, [selectedProceduresList]);

  const lastSelectedCategoryId = useRef(selectedCategoryId);

  useEffect(() => {
    if (!initialDuration && lastSelectedCategoryId.current !== selectedCategoryId) {
      lastSelectedCategoryId.current = selectedCategoryId;

      if (selectedCategory) {
        setDuration(selectedCategory.duration);
      } else if (selectedCategoryId === TREATMENT_PLAN_CATEGORY_ID) {
        calculate();
      }
    }
  }, [selectedCategory, initialDuration, selectedCategoryId, calculate]);

  return useMemo(
    () => ({
      editable: duration,
      read: duration ?? 0,
      calculate,
      update,
    }),
    [duration, calculate, update]
  );
};
