import React from "react";
import { InvoiceVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { SummaryBanner, SummaryCell } from "components/PatientProfile/Billing/SummaryBanner";
import { getInvoiceAmountDue } from "components/PatientProfile/Billing/invoiceUtils";

interface Props {
  invoice: InvoiceVO;
}

export const InvoicePaymentSummary: React.FC<Props> = ({ invoice }) => {
  return (
    <SummaryBanner gridColsClassName="grid-cols-[1fr_auto]">
      <SummaryCell header="Amount Paid" className="text-right">
        {formatCurrency(invoice.amountPaid)}
      </SummaryCell>
      <SummaryCell header="Amount Remaining" className="text-right">
        {formatCurrency(getInvoiceAmountDue(invoice))}
      </SummaryCell>
    </SummaryBanner>
  );
};
