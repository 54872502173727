import { FC, useMemo, useCallback, FormEventHandler, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { InsuranceCarrierVO, PatientSummaryVO, PatientVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { flattenPages, getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { usePick } from "@libs/hooks/usePick";
import { useAccount } from "@libs/contexts/AccountContext";
import { isDefined } from "@libs/utils/types";
import { LayoutCard } from "@libs/components/UI/LayoutCard";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { InputsHeaderRow, Title, TitleContainer } from "@libs/components/UI/GridTableComponents";
import { QueryFilters } from "@libs/components/UI/QueryFilters";
import { keys } from "@libs/utils/object";
import { CreatePatient } from "components/Patient/CreatePatient";
import { SuccessPopup } from "components/Patient/SuccessPopup";
import { getInfinitePatients, OrderBy, SortColumn } from "api/patients/queries";
import { PatientSnapshotLayout } from "components/UI/PatientSnapshotLayout";
import { RECORDS_PER_PAGE } from "utils/words";
import { PatientsTable } from "components/Patients/Table";
import { getAllInsuranceCarriersQuery } from "api/practiceInsurance/queries";
import { getPatientsFilterProps } from "components/Patients/patientsFilterQuery";

import { useQueryParams } from "hooks/useQueryParams";
import { PatientsQuery, PatientsQueryKeys, PatientsQueryUpdates } from "utils/routing/patient";
import { paths } from "utils/routing/paths";
import { useItemModal } from "hooks/useItemModal";
import { useSelectRows } from "hooks/useSelectRows";
import { MessagePatientsFlyover } from "components/Communications/MessagePatients/MessagePatientsFlyover";
import { getFeeScheduleDetails } from "api/feeSchedule/queries";
import { PatientsFiltersFlyoverContent } from "components/Patients/FiltersFlyoverContent";
import { MessagePatientsButton } from "components/Communications/MessagePatients/MessagePatientsButton";
import { QueryFiltersFlyover } from "components/UI/QueryFiltersFlyover";
import { getInsurancePlan } from "api/patientInsurance/queries";
import { usePatientAppointmentQueryState } from "contexts/PatientAppointmentContext";

// eslint-disable-next-line complexity, max-statements
export const PatientsRoute: FC = () => {
  usePageTitle("Patients");

  const { practiceId } = useAccount();
  const navigate = useNavigate();
  const { query, updateQuery, getUrl } = useQueryParams("patients");
  const feeScheduleId = query["patientCriteria.feeScheduleId"] ?? 0;
  const insurancePlanUuid = query["patientCriteria.insurancePlanUuid"] ?? "";
  const [feeScheduleDetailsQuery, insuranceCarriersQuery, insurancePlanQuery] = useApiQueries([
    getFeeScheduleDetails({
      args: { practiceId, feeScheduleId },
      queryOptions: { enabled: Boolean(feeScheduleId) },
    }),
    getAllInsuranceCarriersQuery({ args: { practiceId } }),
    getInsurancePlan({
      args: { practiceId, insurancePlanUuid },
      queryOptions: { enabled: Boolean(insurancePlanUuid) },
    }),
  ]);

  const patientAppointment = usePatientAppointmentQueryState();

  const patientsQueryParams = usePick(query, PatientsQueryKeys);

  const patientsQueryKey = useMemo(() => {
    const queryKey = {
      ...patientsQueryParams,
      searchString: patientsQueryParams.search,
      pageNumber: 1,
      pageSize: RECORDS_PER_PAGE,
    };

    delete queryKey.search;

    return queryKey;
  }, [patientsQueryParams]);

  const patientsQuery = useInfiniteApiQuery(
    getInfinitePatients({
      args: {
        ...patientsQueryKey,
        practiceId,
      },
    })
  );

  const patientIds = useMemo(
    () => flattenPages(patientsQuery.data)?.map((patient) => patient.id),
    [patientsQuery]
  );
  const totalRows = getInfiniteQueryPagingDetails(patientsQuery.data)?.totalElements ?? 0;
  const {
    selectedCount,
    selectedRows,
    deselectedRowsFromSelectAll,
    hasAllSelected,
    selectAllRows,
    resetSelectedRows,
    handleCheckboxChange,
  } = useSelectRows(patientIds, { totalItems: totalRows });

  const patientsFilterProps = useMemo(() => {
    const map = {} as Record<number, InsuranceCarrierVO>;

    insuranceCarriersQuery.data?.forEach((carrier) => {
      map[carrier.id] = carrier;
    });

    return getPatientsFilterProps(
      patientsQueryParams,
      map,
      feeScheduleDetailsQuery.data,
      insurancePlanQuery.data
    );
  }, [
    feeScheduleDetailsQuery.data,
    insuranceCarriersQuery.data,
    insurancePlanQuery.data,
    patientsQueryParams,
  ]);

  const location = useLocation();

  const patientsQueryKeyString = useMemo(() => {
    const searchParams = new URLSearchParams(location.search);
    const searchParamsCopy = new URLSearchParams();
    const paramNames = keys(patientsQueryParams);

    for (const paramName of paramNames) {
      const value = searchParams.get(paramName);

      if (isDefined(value)) {
        searchParamsCopy.append(paramName, value);
      }
    }

    return searchParamsCopy.toString();
  }, [patientsQueryParams, location.search]);

  const messagePatientsFlyover = useBoolean(false);
  const bulkMessageSelectionCategory = useMemo(
    () =>
      patientsQueryKey.appointmentCriteria && patientsQueryKey.appointmentCriteria !== "NO_APPOINTMENT"
        ? "PATIENTS_WITH_APPOINTMENT"
        : "PATIENTS",
    [patientsQueryKey.appointmentCriteria]
  );

  const createPatient = useBoolean(false);
  const successPatientModal = useItemModal<PatientVO[]>(null);
  const handleAddPatient = useCallback(() => {
    createPatient.on();
  }, [createPatient]);

  const [queryForFilters, setQueryForFilters] = useState<PatientsQuery>(query);
  const handleFiltersQueryParamChange = useCallback((keyValues: PatientsQueryUpdates) => {
    setQueryForFilters((last) => ({ ...last, ...keyValues }));
  }, []);

  const handleQueryParamChange = useCallback(
    (keyValues: PatientsQueryUpdates) => {
      updateQuery("replaceIn", keyValues);
      handleFiltersQueryParamChange(keyValues);
      resetSelectedRows();
    },
    [updateQuery, handleFiltersQueryParamChange, resetSelectedRows]
  );

  const handleSortChange = useCallback(
    (orderBy: OrderBy, sortColumn: SortColumn) => {
      updateQuery("replaceIn", { orderBy, sortColumn });
    },
    [updateQuery]
  );

  const handleAddAppointment = useCallback(
    (newPatientId: number) => {
      const url = paths.addPatientAppointment({
        patientId: newPatientId,
        from: getUrl({ patientId: newPatientId }, true),
      });

      navigate(url);
    },
    [navigate, getUrl]
  );

  const onCopyEmail = useCallback((patient: PatientSummaryVO) => {
    if (patient.contact.email) {
      navigator.clipboard.writeText(patient.contact.email);
    }

    toast.info("Copied to clipboard.");
  }, []);

  const flyover = useBoolean(false);
  const closeFlyover = flyover.off;

  const applyFilters: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();

      handleQueryParamChange(queryForFilters);

      closeFlyover();
    },
    [handleQueryParamChange, queryForFilters, closeFlyover]
  );

  return (
    <PatientSnapshotLayout
      onDeleteAppointment={patientAppointment.handleAppointmentDeleted}
      onSelectAppointment={patientAppointment.handleAppointmentSelected}
      appointmentId={patientAppointment.appointmentId}
      patientId={patientAppointment.patientId}
    >
      <div className="flex flex-col h-full">
        <LayoutCard className="flex flex-col flex-1 min-h-0 border-t border-t-greyLighter">
          <QueryResult queries={[insuranceCarriersQuery]}>
            <InputsHeaderRow className="flex items-center">
              <TitleContainer className="flex flex-none items-center gap-x-1">
                <Title>All Patients</Title>
                <ButtonIcon SvgIcon={AddIcon} onClick={handleAddPatient} theme="primary" />
              </TitleContainer>
            </InputsHeaderRow>
            <QueryFilters
              bulkActions={
                <div className="p-1">
                  <MessagePatientsButton
                    disabled={totalRows === 0 || (!patientsFilterProps.filters.length && selectedCount === 0)}
                    onRequestMessagePatients={messagePatientsFlyover.on}
                  />
                </div>
              }
              onOpenFlyover={flyover.on}
              onUpdateParams={handleQueryParamChange}
              queries={[patientsQuery]}
              searchParamKey="search"
              selectedCount={selectedCount}
              totalElements={totalRows}
              {...patientsFilterProps}
            />
          </QueryResult>
          <QueryResult queries={[patientsQuery]}>
            <PatientsTable
              onAddAppointment={handleAddAppointment}
              onCopyEmail={onCopyEmail}
              onRowClick={patientAppointment.handlePatientSelected}
              onSortChange={handleSortChange}
              onSelectAllRows={selectAllRows}
              onDeselectAllRows={resetSelectedRows}
              onCheckboxChange={handleCheckboxChange}
              selectedRows={selectedRows}
              selectedCount={selectedCount}
              totalRows={totalRows}
              patientsQuery={patientsQuery}
              queryStateApi={patientsQueryParams}
              queryKey={patientsQueryKeyString}
            />
          </QueryResult>
        </LayoutCard>
      </div>
      {flyover.isOn && (
        <QueryFiltersFlyover
          content={
            <PatientsFiltersFlyoverContent
              carriersQuery={insuranceCarriersQuery}
              onUpdateFilters={handleFiltersQueryParamChange}
              queryStateApi={queryForFilters}
            />
          }
          isSubmitting={patientsQuery.isFetching}
          onClose={closeFlyover}
          onSubmit={applyFilters}
        />
      )}
      {createPatient.isOn && (
        <CreatePatient
          onCreated={(patients) => {
            successPatientModal.open(patients);
            createPatient.off();
          }}
          onRequestClose={createPatient.off}
        />
      )}
      {successPatientModal.isOpen && (
        <SuccessPopup patients={successPatientModal.item} onRequestClose={successPatientModal.close} />
      )}
      {messagePatientsFlyover.isOn ? (
        <MessagePatientsFlyover
          type="PATIENT_LIST"
          criteria={patientsQueryKey}
          selectionCategory={bulkMessageSelectionCategory}
          selectedCount={selectedCount}
          filteredCount={totalRows}
          selectedIds={selectedRows}
          deselectedIds={deselectedRowsFromSelectAll}
          hasAllSelected={hasAllSelected}
          onClose={messagePatientsFlyover.off}
        />
      ) : null}
    </PatientSnapshotLayout>
  );
};
