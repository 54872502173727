import { FC } from "react";
import { useLocation } from "react-router-dom";

import { getFullUrl } from "@libs/utils/location";
import { pluralize } from "@libs/utils/pluralize";
import { Spinner } from "@libs/components/UI/Spinner";
import { ReactComponent as PeopleIcon } from "@libs/assets/icons/people.svg";
import { ReactComponent as OpenProfileIcon } from "@libs/assets/icons/open-profile.svg";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { formatNumberWithCommas } from "@libs/utils/formatNumber";
import { Banner } from "@libs/components/UI/Banner";

import {
  EMAIL_CAMPAIGN_DAILY_LIMIT,
  hasFilteredCountExceedingDailyLimit,
} from "components/Communications/Campaigns/Campaign/utils";

import { paths } from "utils/routing/paths";

interface Props {
  campaignUuid: string;
  patientsFiltered: number | undefined;
  hasNoFiltersApplied: boolean;
  isLoading: boolean;
}

export const CampaignFiltersBanner: FC<Props> = ({
  campaignUuid,
  patientsFiltered,
  hasNoFiltersApplied,
  isLoading,
}) => {
  const location = useLocation();
  const hasExceededFilteredCountLimit = hasFilteredCountExceedingDailyLimit(patientsFiltered);

  return hasExceededFilteredCountLimit && !hasNoFiltersApplied ? (
    <Banner aria-label="Filter Limit" className="rounded text-xs" theme="error">
      <div className="flex-1">
        This filter currently exceeds the daily limit of {formatNumberWithCommas(EMAIL_CAMPAIGN_DAILY_LIMIT)}{" "}
        patients, please add more filters.
      </div>
    </Banner>
  ) : (
    <Banner aria-label="Filter Group" className="rounded text-xs" Icon={PeopleIcon} theme="info">
      <div className="flex-1 flex items-center gap-x-1">
        {patientsFiltered == null || isLoading ? (
          <Spinner variant="greyLighter" animation="border" size="xs" />
        ) : (
          `${formatNumberWithCommas(patientsFiltered)}${" "}`
        )}
        {pluralize(patientsFiltered ?? 0, "patient", "patients")} currently in this group.
      </div>
      {patientsFiltered && patientsFiltered > 0 ? (
        <LinkIcon
          SvgIcon={OpenProfileIcon}
          tooltip={{ content: "View Group Members", theme: "SMALL" }}
          to={paths.campaignGroup({ campaignUuid }, { from: getFullUrl(location) })}
          theme="primary"
        />
      ) : null}
    </Banner>
  );
};
