import { FC } from "react";

import { DentalProcedureVO } from "@libs/api/generated-api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as PlusCircleIcon } from "@libs/assets/icons/plus-circle.svg";

import { SettingsPanel } from "components/Settings/SettingsPanel";
import { ProcedureCodeCategories } from "components/Settings/Scheduling/Sections/ProcedureDurations/ProcedureCodeCategories";
import { CustomProcedureFlyover } from "components/Settings/Scheduling/Sections/ProcedureDurations/CustomProcedureFlyover";
import { EditProcedureFlyover } from "components/Settings/Scheduling/Sections/ProcedureDurations/EditProcedureFlyover";

import { useItemModal } from "hooks/useItemModal";

export const ProcedureCodesRoute: FC = () => {
  const customProcedureFlyover = useItemModal<{ procedure?: DentalProcedureVO }>(null);
  const editProcedureFlyover = useItemModal<{ procedure: DentalProcedureVO }>(null);

  return (
    <SettingsPanel
      title={
        <div className="flex items-center gap-x-1">
          <span>Procedure Codes</span>
          <ButtonIcon
            SvgIcon={PlusCircleIcon}
            tooltip={{ content: "Add Custom Procedure Code", theme: "SMALL" }}
            onClick={() => customProcedureFlyover.open({})}
            theme="primary"
          />
        </div>
      }
      showScrollBar
    >
      <ProcedureCodeCategories
        onEditCustomProcedureCode={(procedure) => customProcedureFlyover.open({ procedure })}
        onEditProcedureCode={(procedure) => editProcedureFlyover.open({ procedure })}
      />

      {customProcedureFlyover.isOpen && (
        <CustomProcedureFlyover
          procedure={customProcedureFlyover.item.procedure}
          onClose={customProcedureFlyover.close}
        />
      )}

      {editProcedureFlyover.isOpen && (
        <EditProcedureFlyover
          procedure={editProcedureFlyover.item.procedure}
          onClose={editProcedureFlyover.close}
        />
      )}
    </SettingsPanel>
  );
};
