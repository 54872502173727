import { FC } from "react";
import { LabCaseReturnReasonVO, LabCaseVO } from "@libs/api/generated-api";
import { formatPhoneNumber } from "@libs/utils/phone";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { Note, labCaseStatusOptions } from "components/LabCases/LabComponents";
import { CollapsibleSection } from "components/UI/CollapsibleSection";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { FormSection } from "components/LabCases/FormSection";

export const MAX_NOTE_LENGTH = 400;

export const DetailsFormSection: FC<{
  labContact: string | undefined;
  labPhone: string | undefined;
  labOptions: { label: string; value: string }[];
  returnReasonOptions: { label: string; value: string }[];
  note: string;
  selectedLabUuid: string | undefined;
  trackingNumber: LabCaseVO["trackingNumber"];
  estimatedReceiveDate: Date | null;
  status: LabCaseVO["status"] | undefined;
  returnReasonUuid: LabCaseReturnReasonVO["uuid"] | undefined;
  errors: {
    lab?: string;
    trackingNumber?: string;
    returnReason?: string;
    note?: string;
  };
  onSelectedLabUuidChange: (labUuid: LabCaseVO["uuid"] | undefined) => void;
  onTrackingNumberChange: (trackingNumber: LabCaseVO["trackingNumber"] | undefined) => void;
  onEstimatedReceiveDateChange: (date: Date | null) => void;
  onStatusChange: (status: LabCaseVO["status"]) => void;
  onReturnReasonUuidChange: (returnReasonUuid: LabCaseReturnReasonVO["uuid"]) => void;
  onNoteChange: (note: string) => void;
}> = ({
  labContact,
  labPhone,
  labOptions,
  returnReasonOptions,
  selectedLabUuid,
  trackingNumber,
  estimatedReceiveDate,
  status,
  returnReasonUuid,
  note,
  errors,
  onSelectedLabUuidChange,
  onTrackingNumberChange,
  onEstimatedReceiveDateChange,
  onStatusChange,
  onReturnReasonUuidChange,
  onNoteChange,
}) => {
  return (
    <FormSection title="Details" cols="2">
      <div className="flex flex-col gap-y-3 col-span-full">
        <FormFieldSelect
          className="col-span-full"
          label="Select Lab"
          options={labOptions}
          value={selectedLabUuid}
          onItemChanged={onSelectedLabUuidChange}
          error={errors.lab}
        />
        <CollapsibleSection isOpen={Boolean(labContact || labPhone)} className="col-span-full flex flex-col">
          {labContact && <div className="text-xs text-slate-700">Contact: {labContact}</div>}
          {labPhone && <div className="text-xs text-slate-700">Phone: {formatPhoneNumber(labPhone)}</div>}
        </CollapsibleSection>
      </div>
      <FormFieldInput
        label="Tracking Number"
        value={trackingNumber}
        onChange={(e) => onTrackingNumberChange(e.target.value)}
        error={errors.trackingNumber}
      />
      <FormFieldSelectMenusDatepicker
        label="Estimated Receive Date"
        selected={estimatedReceiveDate}
        onChange={onEstimatedReceiveDateChange}
      />
      <FormFieldSelect
        label="Status"
        value={status}
        required
        options={labCaseStatusOptions}
        isClearable={false}
        onItemChanged={(value) => value && onStatusChange(value)}
      />
      {status === "RETURNED" && (
        <FormFieldSelect
          label="Return Reason"
          required
          value={returnReasonUuid}
          options={returnReasonOptions}
          isClearable={false}
          onItemChanged={(value) => value && onReturnReasonUuidChange(value)}
          error={errors.returnReason}
        />
      )}
      <Note className="col-span-full" note={note} max={MAX_NOTE_LENGTH} onChange={onNoteChange} />
    </FormSection>
  );
};
