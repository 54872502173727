import { FC } from "react";
import { AutomationActionVO, EmailConfigVO } from "@libs/api/generated-api";
import { ChannelsFields } from "components/Communications/Automations/ChannelsFields";
import { DraftAutomationAction } from "components/Communications/Automations/types";
import { ToggleButtonList } from "components/UI/ToggleButtonList";
import { BeforeTimeFields } from "components/Communications/Automations/BeforeTimeFields";
import { AutomationActionValidation } from "components/Communications/Automations/utils";
import { TimingField } from "components/Communications/Automations/TimingField";

interface Props {
  emailConfig: EmailConfigVO;
  validation: AutomationActionValidation;
  action: DraftAutomationAction;
  onUpdate: (updates: Partial<DraftAutomationAction>) => void;
}

const options: { label: string; value: AutomationActionVO["event"] }[] = [
  {
    label: "Perio Due",
    value: "PERIO_DUE",
  },
  {
    label: "Prophy Due",
    value: "PROPHY_DUE",
  },
];

export const AutomationActionRecallForm: FC<Props> = ({ emailConfig, validation, action, onUpdate }) => {
  return (
    <>
      <div>
        <ToggleButtonList
          type="radio"
          shape="mediumPill"
          onChange={(_, option) => onUpdate({ event: option.value })}
          selectedValue={action.event}
          options={options}
          label="Event"
          required
        />
      </div>
      <TimingField validation={validation}>
        <BeforeTimeFields validation={validation} action={action} onUpdate={onUpdate} includeTime />
      </TimingField>
      <ChannelsFields
        emailConfig={emailConfig}
        channels={action.channels}
        onChannelsChange={(value) => onUpdate({ channels: value })}
      />
    </>
  );
};
