import React from "react";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useKeyEventCallback } from "@libs/hooks/useKeyEventCallback";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { Button } from "@libs/components/UI/Button";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptions } from "@libs/components/UI/MenuOptions";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";

import { HeaderRightProps } from "components/PatientProfile/Imaging/MountRoute/MountHeader/types";

import { EditImageMetadataModal } from "components/PatientProfile/Imaging/MountRoute/EditImageMetadataModal";
import { cxStyles } from "./styles";
import { HeaderRightViewSelections } from "./HeaderRightViewSelections";

export const HeaderRight: React.FC<HeaderRightProps> = ({
  imageMount,
  onUpdateParams,
  mountFormat,
  isShowingMetadata,
  onClickDone,
  onChangeImage,
  devices,
  showArchived,
  onToggleShowArchived,
  children,
}) => {
  const editMetadataModal = useBoolean(false);

  useKeyEventCallback("keydown", "Escape", editMetadataModal.off);

  const menu = useBoolean(false);
  const isPopulated = (imageMount.images ?? []).some((item) => item.url);

  const archivedCount = React.useMemo(
    () => imageMount.images?.filter((item) => item.isArchived).length ?? 0,
    [imageMount.images]
  );

  return (
    <>
      <div className="flex items-center justify-end mr-3 gap-4 flex-1 relative">
        {children}
        {isPopulated && (
          <div className={cx("items-center gap-4", cxStyles.responsivelyHide("lg"))}>
            <HeaderRightViewSelections
              onUpdateParams={onUpdateParams}
              mountFormat={mountFormat}
              archivedCount={archivedCount}
              mountType={imageMount.name}
              isShowingMetadata={isShowingMetadata}
            />
            <VerticalDivider size="sm" theme="darkBackground" />
            <ButtonMenu
              isOpen={menu.isOn}
              onRequestOpen={menu.on}
              onRequestClose={menu.off}
              placement="bottom-end"
              menuContent={
                <MenuOptions
                  options={[
                    {
                      label: "Edit Image Data",
                      value: "edit-metadata",
                    },
                    {
                      label: showArchived ? "Hide Archived" : "Show Archived",
                      value: "toggle-archived",
                    },
                    {
                      label: isShowingMetadata ? "Hide Metadata" : "Show Metadata",
                      value: "toggle-metadata",
                    },
                  ]}
                  onOptionClick={(option) => {
                    menu.off();

                    switch (option.value) {
                      case "edit-metadata": {
                        editMetadataModal.on();
                        break;
                      }
                      case "toggle-metadata": {
                        // isShowingMetadata is already toggled, because it's the opposite of hiding
                        onUpdateParams({ hideMeta: isShowingMetadata });
                        break;
                      }
                      case "toggle-archived": {
                        onToggleShowArchived();
                        break;
                      }

                      default:
                      // no default
                    }
                  }}
                />
              }
            >
              {(props) => (
                <ButtonIcon SvgIcon={MenuIcon} aria-label="Action Menu" theme="darkBackground" {...props} />
              )}
            </ButtonMenu>
          </div>
        )}
        <Button className="min-w-20" onClick={onClickDone}>
          Done
        </Button>
      </div>
      {editMetadataModal.isOn && (
        <EditImageMetadataModal
          devices={devices}
          onDone={(changes) => {
            editMetadataModal.off();

            if (changes) {
              onChangeImage(changes);
            }
          }}
        />
      )}
    </>
  );
};
