import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { ReactComponent as OpenProfileIcon } from "@libs/assets/icons/open-profile.svg";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { EmptyContent } from "@libs/components/UI/EmptyContent";
import { paths } from "utils/routing/paths";
import { getLatestFormSubmission } from "api/forms/queries";
import EmptyMedicalData from "assets/images/empty-medical-data.svg";
import { ViewResponses } from "components/PatientProfile/Forms/ViewResponses";
import { Divider } from "components/UI/Divider";

import { FlyoverHeader } from "components/UI/FlyoverComponents";

interface Props {
  patientId: number;
  onRequestClose: Func;
}

export const MedicalHistoryPeek: React.FC<Props> = ({ patientId, onRequestClose }) => {
  const { practiceId } = useAccount();

  const [medicalHistoryQuery, dentalHistoryQuery] = useApiQueries([
    getLatestFormSubmission({ args: { patientId, practiceId, uuidOrSlug: "MEDICAL_HISTORY" } }),
    getLatestFormSubmission({ args: { patientId, practiceId, uuidOrSlug: "DENTAL_HISTORY" } }),
  ]);

  const { data: medicalHistory } = medicalHistoryQuery;
  const { data: dentalHistory } = dentalHistoryQuery;

  return (
    <>
      <FlyoverHeader size="sm">
        <div className="flex gap-x-2 items-center justify-between">
          <div className="flex-1">Medical History</div>

          <LinkIcon
            className="text-primaryTheme font-sansSemiBold"
            to={paths.patientTab({ patientId, tab: "medical-history" })}
            onClick={onRequestClose}
            SvgIcon={OpenProfileIcon}
            tooltip={{ content: "View More", theme: "SMALL" }}
          />
        </div>
      </FlyoverHeader>
      <div className="min-h-0 flex-1 overflow-y-auto p-6 gap-y-4 flex flex-col">
        <QueryResult queries={[medicalHistoryQuery]}>
          {medicalHistory && (
            <ViewResponses
              displayDivider={false}
              formSubmission={medicalHistory}
              noResponses={
                <div className="p-2.5 h-full">
                  <EmptyContent
                    fullHeight
                    text="No Recorded Medical History"
                    alt="No Recorded Medical History"
                    src={EmptyMedicalData}
                  />
                </div>
              }
            />
          )}
        </QueryResult>
        <div className="py-1">
          <Divider />
        </div>
        <QueryResult queries={[dentalHistoryQuery]}>
          {dentalHistory && (
            <>
              <span className="text-sm font-sansSemiBold">Dental History</span>
              <ViewResponses
                displayDivider={false}
                formSubmission={dentalHistory}
                noResponses={
                  <div className="p-2.5 h-full">
                    <EmptyContent
                      fullHeight
                      text="No Recorded Dental History"
                      alt="No Recorded Dental History"
                      src={EmptyMedicalData}
                    />
                  </div>
                }
              />
            </>
          )}
        </QueryResult>
      </div>
    </>
  );
};
