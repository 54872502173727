import { useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { ReactComponent as Link } from "@libs/assets/icons/link.svg";
import { paths } from "utils/routing/paths";

export const ClaimLink: React.FC<{
  claim: {
    replacementClaimUuid?: string;
    replacedClaimUuid?: string;
  };
  className?: string;
  from: string;
}> = ({ claim, className, from }) => {
  const menu = useBoolean(false);
  const navigate = useNavigate();

  const navigateToUuid = useCallback(
    (uuid?: string) => {
      navigate(paths.claim({ claimUuid: uuid }, { from }));
    },
    [from, navigate]
  );

  const linkedClaimPath = useMemo(() => {
    const path = claim.replacementClaimUuid || claim.replacedClaimUuid;

    if (!path) {
      return undefined;
    }

    return paths.claim({ claimUuid: path }, { from });
  }, [claim.replacedClaimUuid, claim.replacementClaimUuid, from]);

  if (!linkedClaimPath) {
    return null;
  }

  const isTwoWay = Boolean(claim.replacedClaimUuid && claim.replacementClaimUuid);
  const isOneWay = Boolean(!isTwoWay && (claim.replacedClaimUuid || claim.replacementClaimUuid));

  return isOneWay ? (
    <LinkIcon
      className={cx("h-5 w-5", className)}
      theme="primary"
      SvgIcon={Link}
      to={linkedClaimPath}
      tooltip={{
        content: `View ${claim.replacedClaimUuid ? "Original" : "Replacement"} Claim`,
        theme: "SMALL",
      }}
    />
  ) : (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      onRequestClose={menu.off}
      placement="bottom-start"
      menuContent={
        <>
          <MenuOptionButton
            onClick={() => {
              navigateToUuid(claim.replacementClaimUuid);
              menu.off();
            }}
          >
            Replacement Claim
          </MenuOptionButton>
          <MenuOptionButton
            onClick={() => {
              navigateToUuid(claim.replacedClaimUuid);
              menu.off();
            }}
          >
            Original Claim
          </MenuOptionButton>
        </>
      }
    >
      {(props) => (
        <ButtonIcon
          {...props}
          SvgIcon={Link}
          className={className}
          size="md"
          theme="primary"
          tooltip={{ content: "View Linked Claims", theme: "SMALL" }}
        />
      )}
    </ButtonMenu>
  );
};
