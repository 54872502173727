import React, { useMemo } from "react";
import Skeleton from "react-loading-skeleton";
import { DentalProcedureVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { getDentalProcedureCategories } from "api/charting/queries";

export type Props = {
  onChange: (code: string, procedure?: DentalProcedureVO) => void;
  isClearable: boolean;
  value?: string;
  autoFocus?: boolean;
  className?: string;
  error?: string;
};

export const SelectDentalCategory: React.FC<Props> = ({
  value,
  onChange,
  isClearable,
  className,
  error,
  autoFocus,
}) => {
  const [{ data: categories, isLoading }] = useApiQueries([getDentalProcedureCategories()]);

  const options = useMemo(() => {
    const items = (categories ?? []).map((cat) => ({
      value: cat,
      label: cat,
    }));

    if (value && !items.some((item) => value === item.value)) {
      items.push({ label: value, value });
    }

    return items;
  }, [categories, value]);

  if (isLoading) {
    return <Skeleton containerClassName="w-full" className="h-5" />;
  }

  return (
    <FormFieldSelect
      allowCreateWhileLoading
      autoFocus={autoFocus}
      className={className}
      closeMenuOnSelect
      edit
      display="value"
      error={error}
      inputClassName="px-2 rounded w-full"
      isClearable={isClearable}
      isSearchable
      layout="tableValue"
      onChange={(option) => {
        if (option?.value) {
          onChange(option.value);
        }
      }}
      onCreateOption={(inputValue) => {
        onChange(inputValue);
      }}
      openMenuOnFocus
      options={options}
      value={value}
    />
  );
};
