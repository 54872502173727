import { FC, PropsWithChildren } from "react";
import EmptySchedule from "assets/images/empty-schedule.svg";

export const AppointmentListPrompt: FC<PropsWithChildren> = ({ children }) => {
  return (
    <div className="flex justify-center items-center">
      <div className="flex flex-col justify-center items-center">
        <img alt="Slot" className="w-44 h-44" src={EmptySchedule} />
        <div className="w-40 text-center text-xs mb-2.5 mt-2">{children}</div>
      </div>
    </div>
  );
};
