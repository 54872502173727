import { useCallback, useEffect } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { minutesToMilliseconds } from "date-fns";
import { PayrollMigrationEvent } from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";
import { useAccount } from "@libs/contexts/AccountContext";
import { useWebSocketContext } from "contexts/WebSocketContext";
import { WebSocketMessageEvent } from "api/websocket/webSocketMessage";

const CONSOLIDATION_TIMEOUT_IN_MINUTES = 2;

export const useMigrationFinished = ({
  startListening,
  onComplete,
  onTimeout,
}: {
  startListening: boolean;
  onComplete: (event: PayrollMigrationEvent) => void;
  onTimeout: Func;
}) => {
  const { practiceId } = useAccount();
  const { messages } = useWebSocketContext();
  const queryClient = useQueryClient();

  const invalidateOnboardingStatus = useCallback(() => {
    queryClient.invalidateQueries([getQueryKey("practices", "getCompanyOnboardingStatus"), { practiceId }]);
  }, [queryClient, practiceId]);

  const onMessageHandler = useCallback(
    (event: WebSocketMessageEvent) => {
      const message = event.detail;

      if (message.type === "GUSTO_MIGRATION_FINISHED") {
        onComplete(message.payload);
        invalidateOnboardingStatus();
      }
    },
    [onComplete, invalidateOnboardingStatus]
  );

  useEffect(() => {
    const timeout = startListening
      ? // In case the PayrollMigrationEvent is never sent, or an error occurs
        // during the async tasks for consolidating employees, we fallback to
        // closing the migration modal and invalidating onboarding status after 2
        // minutes. The user should be in the appropriate onboarding state and
        // presented with any potential errors, but will not be presented with the
        // Employee Consolidation Summary modal.
        window.setTimeout(() => {
          invalidateOnboardingStatus();
          onTimeout();
        }, minutesToMilliseconds(CONSOLIDATION_TIMEOUT_IN_MINUTES))
      : 0;

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [invalidateOnboardingStatus, onTimeout, startListening]);

  useEffect(() => {
    if (messages && startListening) {
      messages.addEventListener("message", onMessageHandler);

      return () => {
        messages.removeEventListener("message", onMessageHandler);
      };
    }

    return undefined;
  }, [messages, onMessageHandler, startListening]);
};
