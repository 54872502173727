import React, { useMemo } from "react";
import DatePicker from "react-datepicker";
import { add, isAfter, isSameDay } from "date-fns";
import { cx } from "@libs/utils/cx";
import { getDurationDays } from "@libs/utils/date";
import { Button } from "@libs/components/UI/Button";
import { DatePickerBase } from "components/UI/DatePickerBase";
import { RangeShortcut } from "components/Dashboard/SelectDateRange/useRangeShortcuts";

const cxStyles = {
  button: (selected: boolean) =>
    cx("text-left px-2 py-1 text-xs", selected && "text-primaryTheme bg-slate-50 rounded-md"),
};

export type Props = {
  startDate: Date | null;
  endDate: Date | null;
  onChangeStart: (date: Date | null) => void;
  onChangeEnd: (date: Date | null) => void;
  onCancel: Func;
  onClear: Func;
  onRangeConfirmed: Func;
  shortcutOptions: RangeShortcut[];
  onSelectShortcut: (shortcut: RangeShortcut) => void;
  maxRangeDays?: number;
};
export const MenuContent = React.forwardRef(
  (
    {
      onRangeConfirmed,
      onCancel,
      startDate,
      endDate,
      onChangeEnd,
      onChangeStart,
      onClear,
      maxRangeDays,
      shortcutOptions,
      onSelectShortcut,
    }: Props,
    ref: React.ForwardedRef<DatePicker>
  ) => {
    const confirmDisabled = useMemo(() => {
      if (!startDate || !endDate) {
        return true;
      }

      if (maxRangeDays) {
        return getDurationDays({ start: startDate, end: endDate }) > maxRangeDays;
      }

      return !isSameDay(startDate, endDate) && !isAfter(endDate, startDate);
    }, [endDate, maxRangeDays, startDate]);
    const maxDate = useMemo(() => {
      if (maxRangeDays && startDate) {
        return add(startDate, { days: maxRangeDays });
      }

      return undefined;
    }, [maxRangeDays, startDate]);

    return (
      <div className="flex gap-3 p-4">
        <div className="flex flex-col gap-2">
          {shortcutOptions.map((dayOption) => {
            return (
              <button
                key={dayOption.label}
                type="button"
                className={cxStyles.button(Boolean(dayOption.selected))}
                onClick={() => {
                  onSelectShortcut(dayOption);
                }}
              >
                {dayOption.label}
              </button>
            );
          })}
        </div>
        <div
          className={`
            flex
            flex-col
            justify-between
            pl-2
            border-l
            border-slate-200
            min-w-52
            min-h-[23rem]
          `}
        >
          <DatePickerBase
            inline
            selected={startDate}
            startDate={startDate}
            endDate={endDate}
            selectsRange
            ref={ref}
            onChange={(dates) => {
              if (Array.isArray(dates)) {
                const [start, end] = dates;

                onChangeStart(start);
                onChangeEnd(end);
              }
            }}
            monthsShown={2}
            maxDate={maxDate}
            shouldCloseOnSelect={false}
          />
          <div className="flex justify-between pl-4">
            <Button theme="link" className="text-sm" onClick={onClear}>
              Clear
            </Button>
            <div className="flex gap-3">
              <Button theme="secondary" className="min-w-button" onClick={onCancel}>
                Cancel
              </Button>
              <Button
                disabled={confirmDisabled}
                theme="primary"
                className="min-w-button"
                onClick={onRangeConfirmed}
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </div>
    );
  }
);
