import { FC } from "react";
import { DentalProcedureVO } from "@libs/api/generated-api";
import { ExpiredCDTCodeIcon } from "components/Charting/ExpiredCDTCodeIcon";

interface Props {
  procedure: DentalProcedureVO;
  now: Date;
}

export const ProcedureValue: FC<Props> = ({ procedure, now }) => {
  return (
    <div className="flex items-center gap-x-2">
      <div className="flex items-center gap-x-1">
        {procedure.cdtCode}
        <ExpiredCDTCodeIcon now={now} endDate={procedure.endDate} />
      </div>

      <div className="truncate">
        {procedure.name}
        <span className="pl-2">{procedure.simpleName}</span>
      </div>
    </div>
  );
};
