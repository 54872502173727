import { MessageRequest, MessageReadRequest, MessageRetryRequest } from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";

export const createAndSendMessage = makeMutation({
  mutationKey: ["practices", "createAndSendMessage"],
  formatParams: (args: { patientId: number; practiceId: number; data: MessageRequest }) => [
    args.patientId,
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, patientId }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPatientMessages"),
        { practiceId, patientId },
      ]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPracticeMessages"), { practiceId }]);
    },
  }),
});

export const markAsReadOrUnread = makeMutation({
  mutationKey: ["practices", "markAsReadOrUnread"],
  formatParams: (args: { patientId: number; practiceId: number; data: MessageReadRequest }) => [
    args.patientId,
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getPracticeMessages"), { practiceId }]);
    },
  }),
});

export const retryFailedMessage = makeMutation({
  mutationKey: ["practices", "retryFailedMessage"],
  formatParams: (args: { practiceId: number; messageId: number; data: MessageRetryRequest }) => [
    args.practiceId,
    args.messageId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getPracticeMessages"), { practiceId }]);
    },
  }),
});
