import { FC, FormEvent, useState, useMemo, useCallback } from "react";
import { produce } from "immer";

import { EmployeeVO } from "@libs/api/generated-api";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { isCompleteAddress, isEmptyAddress, getEmptyAddress, addressSchema } from "@libs/utils/address";
import { useValidation } from "@libs/hooks/useValidation";
import { phoneNumber, required } from "@libs/utils/validators";

import { Form } from "@libs/components/UI/Form";
import { FormFieldAddressAutocomplete } from "@libs/components/UI/FormFieldAddressAutocomplete";
import { stateOptions } from "@libs/components/UI/stateOptions";
import { FormFieldSelect } from "@libs/components/UI/FormFieldSelect";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { TooltipLabel } from "components/UI/TooltipLabel";

import { useIsDirty } from "hooks/useIsDirty";
import { useEnvContext } from "contexts/EnvContext";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { useNow } from "hooks/useNow";

interface Props {
  formId: string;
  employee: EmployeeVO;
  isProxy: boolean;
  onSubmit: (employee: EmployeeVO, isUpdated: boolean) => void;
}

export const PrescriberForm: FC<Props> = ({ formId, employee, isProxy, onSubmit }) => {
  const [editableEmployee, setEditableEmployee] = useState(() => employee);
  const { REACT_APP_GOOGLE_API_KEY } = useEnvContext();
  const today = useNow();
  const fillableAddressInputsDisabled = useMemo(
    () =>
      isCompleteAddress(editableEmployee.contactDetails.addressDetails) ||
      isEmptyAddress(editableEmployee.contactDetails.addressDetails),
    [editableEmployee.contactDetails.addressDetails]
  );

  const { validate, result } = useValidation(editableEmployee, {
    employmentDetails: {
      npiNumber: [{ $v: required, $error: "NPI number is required", $ignore: isProxy }],
    },
    personalDetails: {
      dob: [
        {
          $v: required,
          $error: "Date of birth required",
        },
      ],
    },
    contactDetails: {
      addressDetails: addressSchema,
      phone: [
        {
          $v: required,
          $error: "Phone number required",
        },
        {
          $v: phoneNumber,
          $error: "Please enter valid phone number",
        },
      ],
    },
  });

  const { isDirty } = useIsDirty(editableEmployee);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!validate().$isValid) {
        return;
      }

      onSubmit(editableEmployee, isDirty);
    },
    [validate, onSubmit, editableEmployee, isDirty]
  );

  return (
    <Form id={formId} className="grid grid-cols-2 gap-6" onSubmit={handleSubmit}>
      <FormFieldSelectMenusDatepicker
        label="Date of Birth"
        selected={
          editableEmployee.personalDetails.dob
            ? getLocalDate(editableEmployee.personalDetails.dob)
            : undefined
        }
        error={result.personalDetails.dob.$error}
        required
        maxDate={today}
        onChange={(val) => {
          setEditableEmployee((last) =>
            produce(last, (draft) => {
              draft.personalDetails.dob = val ? formatAsISODate(val) : "";
            })
          );
        }}
      />

      <FormFieldPhoneInput
        label="Phone"
        value={editableEmployee.contactDetails.phone}
        required
        error={result.contactDetails.phone.$error}
        onValueChange={(val) => {
          setEditableEmployee((last) =>
            produce(last, (draft) => {
              draft.contactDetails.phone = val;
            })
          );
        }}
      />

      {isProxy ? null : (
        <>
          <FormFieldInput
            label="NPI Number"
            value={editableEmployee.employmentDetails.npiNumber}
            onChange={(e) => {
              const { value } = e.target;

              setEditableEmployee((last) =>
                produce(last, (draft) => {
                  draft.employmentDetails.npiNumber = value.length > 0 ? value : undefined;
                })
              );
            }}
            error={result.employmentDetails.npiNumber.$error}
            required
          />

          <FormFieldInput
            label={
              <TooltipLabel
                tooltip={{ content: "DEA is required if you are prescribing controlled substances" }}
              >
                DEA Number
              </TooltipLabel>
            }
            value={editableEmployee.employmentDetails.deaNumber}
            onChange={(e) => {
              const { value } = e.target;

              setEditableEmployee((last) =>
                produce(last, (draft) => {
                  draft.employmentDetails.deaNumber = value.length > 0 ? value : undefined;
                })
              );
            }}
          />
        </>
      )}

      <FormFieldAddressAutocomplete
        apiKey={REACT_APP_GOOGLE_API_KEY}
        label="Home Address"
        placeholder="Search address..."
        defaultValue={editableEmployee.contactDetails.addressDetails?.address1}
        onSelect={({ address: address1, locality: city, state, zip, country }) => {
          const selectedAddress = { address1, address2: "", city, state, zip, country };

          setEditableEmployee((last) =>
            produce(last, (draft) => {
              draft.contactDetails.addressDetails = selectedAddress;
            })
          );
        }}
        error={result.contactDetails.addressDetails.address1.$error}
        required
      />

      <FormFieldInput
        label="Apt, Suite, Bldg"
        value={editableEmployee.contactDetails.addressDetails?.address2}
        onChange={(e) =>
          setEditableEmployee((last) =>
            produce(last, (draft) => {
              if (draft.contactDetails.addressDetails) {
                draft.contactDetails.addressDetails.address2 = e.target.value;
              } else {
                draft.contactDetails.addressDetails = {
                  ...getEmptyAddress(),
                  address2: e.target.value,
                };
              }
            })
          )
        }
      />

      <FormFieldInput
        label="City"
        value={editableEmployee.contactDetails.addressDetails?.city}
        onChange={(e) =>
          setEditableEmployee((last) =>
            produce(last, (draft) => {
              if (draft.contactDetails.addressDetails) {
                draft.contactDetails.addressDetails.city = e.target.value;
              } else {
                draft.contactDetails.addressDetails = {
                  ...getEmptyAddress(),
                  city: e.target.value,
                };
              }
            })
          )
        }
        error={result.contactDetails.addressDetails.city.$error}
        disabled={fillableAddressInputsDisabled}
        required
      />

      <div className="grid grid-cols-2 gap-6">
        <FormFieldSelect
          label="State"
          className="flex-1"
          options={stateOptions}
          value={editableEmployee.contactDetails.addressDetails?.state}
          onItemSelected={(selectedState) =>
            setEditableEmployee((last) =>
              produce(last, (draft) => {
                if (draft.contactDetails.addressDetails) {
                  draft.contactDetails.addressDetails.state = selectedState;
                } else {
                  draft.contactDetails.addressDetails = {
                    ...getEmptyAddress(),
                    state: selectedState,
                  };
                }
              })
            )
          }
          isSearchable={false}
          error={result.contactDetails.addressDetails.state.$error}
          disabled={fillableAddressInputsDisabled}
          required
        />

        <FormFieldInput
          label="Zip"
          className="flex-1"
          value={editableEmployee.contactDetails.addressDetails?.zip}
          onChange={(e) =>
            setEditableEmployee((last) =>
              produce(last, (draft) => {
                if (draft.contactDetails.addressDetails) {
                  draft.contactDetails.addressDetails.zip = e.target.value;
                } else {
                  draft.contactDetails.addressDetails = {
                    ...getEmptyAddress(),
                    zip: e.target.value,
                  };
                }
              })
            )
          }
          error={result.contactDetails.addressDetails.zip.$error}
          disabled={fillableAddressInputsDisabled}
          required
        />
      </div>
    </Form>
  );
};
