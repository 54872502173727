import { FC } from "react";
import { pluralize } from "@libs/utils/pluralize";
import { formatHours } from "components/EmployeeProfile/Timesheet/utils";

interface Props {
  hoursInDecimal: boolean;
  totalWorkingHours: number;
  totalOvertimeHours: number;
}

export const HoursSummary: FC<Props> = ({ hoursInDecimal, totalWorkingHours, totalOvertimeHours }) => {
  return (
    <div className="flex divide-x divide-greyLight">
      <span className="text-primaryTheme pr-4">
        <div className="font-sansSemiBold text-xs">Regular Hours</div>

        <div className="font-sansSemiBold text-3xl">
          {formatHours(totalWorkingHours, hoursInDecimal)} {pluralize(totalWorkingHours, "hr", "hrs")}
        </div>
      </span>

      <span className="text-magenta pl-4">
        <div className="font-sansSemiBold text-xs">Overtime</div>
        <div className="font-sansSemiBold text-3xl">
          {formatHours(totalOvertimeHours, hoursInDecimal)} {pluralize(totalOvertimeHours, "hr", "hrs")}
        </div>
      </span>
    </div>
  );
};
