import { TickFormatter } from "@visx/axis";
import React from "react";
import { formatNumber } from "@libs/utils/formatString";

export const useYAxisFormatter = ({
  isCurrency,
  formatValue,
}: {
  isCurrency: boolean;
  formatValue?: (val: number) => string;
}) => {
  const formatter: TickFormatter<number> = React.useCallback(
    (value) => {
      return formatValue ? formatValue(value) : formatNumber(value, { isCurrency });
    },
    [isCurrency, formatValue]
  );

  return formatter;
};
