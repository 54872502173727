import { InsuranceCarrierVO, ProviderVO } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { FeeSchedulesQuery, FeeSchedulesQueryUpdates } from "utils/routing/settings";
import { SelectCarriers } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/SelectCarriers";

interface Props {
  carriersQuery: ApiQueryResult<InsuranceCarrierVO[]>;
  onUpdateFilters: (keyValues: FeeSchedulesQueryUpdates) => void;
  providersQuery: ApiQueryResult<ProviderVO[]>;
  queryState: FeeSchedulesQuery;
}

export const FeeSchedulesFilterFlyover: React.FC<Props> = ({
  carriersQuery,
  onUpdateFilters,
  providersQuery,
  queryState,
}) => {
  return (
    <div className="flex flex-col gap-y-6">
      <div className="flex-1 overflow-y-auto">
        <CheckboxList
          label="Providers"
          layout="vert"
          onChange={(newSet) => onUpdateFilters({ providerIds: [...newSet] })}
          options={
            providersQuery.data?.map((provider) => ({
              label: provider.name.fullDisplayName,
              value: provider.id,
            })) ?? []
          }
          selectedValues={new Set(queryState.providerIds)}
        />
      </div>
      <CheckboxList
        label="Type"
        layout="vert"
        onChange={(newSet) => onUpdateFilters({ types: [...newSet] })}
        options={[
          { label: "Fee Schedule", value: "CARRIER" },
          { label: "Provider UCR", value: "PROVIDER_UCR" },
        ]}
        selectedValues={new Set(queryState.types)}
      />
      <CheckboxList
        label="Status"
        layout="vert"
        onChange={(newSet) => onUpdateFilters({ states: [...newSet] })}
        options={[
          { label: "Active", value: "ACTIVE" },
          { label: "Archived", value: "ARCHIVED" },
        ]}
        selectedValues={new Set(queryState.states)}
      />
      <CheckboxList
        label="In Network"
        layout="vert"
        onChange={(newSet) =>
          onUpdateFilters({ inNetwork: [...newSet].map((val) => (val === "inNetwork" ? "true" : "false")) })
        }
        options={[
          { label: "Yes", value: "inNetwork" },
          { label: "No", value: "outOfNetwork" },
        ]}
        selectedValues={
          new Set(queryState.inNetwork?.map((val) => (val === "true" ? "inNetwork" : "outOfNetwork")))
        }
      />
      {carriersQuery.data && (
        <SelectCarriers
          carriersQuery={carriersQuery}
          onSelect={(newSet) => onUpdateFilters({ carrierIds: [...newSet] })}
          selectedCarrierIds={new Set(queryState.carrierIds)}
        />
      )}
    </div>
  );
};
