import { FC } from "react";
import { OnboardEmployeeRequest } from "@libs/api/generated-api";
import { ValidationResult } from "@libs/hooks/useValidation";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldAddressAutocomplete } from "@libs/components/UI/FormFieldAddressAutocomplete";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";
import { SkeletonInputField } from "components/UI/SkeletonInputField";
import { getOnboardingSchema } from "components/Onboarding/OnboardingWizard";
import { createAddressValue } from "api/user/data";
import { useEnvContext } from "contexts/EnvContext";
import { OnChangeHandler } from "./types";

export const ContactInfoForm: FC<{
  onboardingData?: OnboardEmployeeRequest;
  onChange: OnChangeHandler;
  validationResults: ValidationResult<
    OnboardEmployeeRequest["contactDetails"],
    ReturnType<typeof getOnboardingSchema>["contactDetails"]
  >;
  isExcludedFromPayroll: boolean;
}> = ({ onboardingData, onChange, validationResults, isExcludedFromPayroll }) => {
  const { REACT_APP_GOOGLE_API_KEY } = useEnvContext();

  return (
    <div className="grid grid-cols-2 gap-3">
      {onboardingData ? (
        <>
          <FormFieldPhoneInput
            error={validationResults.phone.$error}
            label="Phone Number"
            onValueChange={(phone) => onChange((draft) => (draft.contactDetails.phone = phone))}
            value={onboardingData.contactDetails.phone}
          />
          <FormFieldInput
            error={validationResults.personalEmail.$error}
            required={true}
            label="Personal Email"
            onChange={(e) => onChange((draft) => (draft.contactDetails.personalEmail = e.target.value))}
            value={onboardingData.contactDetails.personalEmail}
          />
          <FormFieldAddressAutocomplete
            required={!isExcludedFromPayroll}
            error={validationResults.addressDetails.address1.$error}
            apiKey={REACT_APP_GOOGLE_API_KEY}
            defaultValue={onboardingData.contactDetails.addressDetails?.address1}
            label="Address"
            onSelect={(location) => {
              if (location.raw) {
                onChange((draft) => {
                  draft.contactDetails.addressDetails = {
                    address1: location.address,
                    city: location.locality,
                    state: location.state,
                    zip: location.zip,
                  };
                });
              } else {
                onChange((draft) => (draft.contactDetails.addressDetails = undefined));
              }
            }}
          />
          <FormFieldInput
            label="Address line 2"
            onChange={(e) =>
              onChange((draft) => {
                draft.contactDetails.addressDetails = createAddressValue({
                  ...draft.contactDetails.addressDetails,
                  address2: e.target.value,
                });
              })
            }
            placeholder="Apartment, Unit, Suite, or Floor #"
            value={onboardingData.contactDetails.addressDetails?.address2 || ""}
          />
        </>
      ) : (
        <>
          <SkeletonInputField />
          <SkeletonInputField />
          <SkeletonInputField />
          <SkeletonInputField />
        </>
      )}
      <div className="col-span-2 grid grid-cols-3 gap-3">
        {onboardingData ? (
          <>
            <FormFieldInput
              required={!isExcludedFromPayroll}
              error={validationResults.addressDetails.city.$error}
              label="City"
              onChange={(e) =>
                onChange(
                  (draft) =>
                    (draft.contactDetails.addressDetails = createAddressValue({
                      ...draft.contactDetails.addressDetails,
                      city: e.target.value,
                    }))
                )
              }
              value={onboardingData.contactDetails.addressDetails?.city || ""}
            />
            <FormFieldInput
              required={!isExcludedFromPayroll}
              error={validationResults.addressDetails.state.$error}
              label="State"
              onChange={(e) =>
                onChange(
                  (draft) =>
                    (draft.contactDetails.addressDetails = createAddressValue({
                      ...draft.contactDetails.addressDetails,
                      state: e.target.value,
                    }))
                )
              }
              value={onboardingData.contactDetails.addressDetails?.state || ""}
            />
            <FormFieldInput
              required={!isExcludedFromPayroll}
              error={validationResults.addressDetails.zip.$error}
              label="Zip"
              onChange={(e) =>
                onChange(
                  (draft) =>
                    (draft.contactDetails.addressDetails = createAddressValue({
                      ...draft.contactDetails.addressDetails,
                      zip: e.target.value,
                    }))
                )
              }
              value={onboardingData.contactDetails.addressDetails?.zip || ""}
            />
          </>
        ) : (
          <>
            <SkeletonInputField />
            <SkeletonInputField />
            <SkeletonInputField />
          </>
        )}
      </div>
    </div>
  );
};
