import React, { FC } from "react";
import { LedgerV2ClaimBalanceTransferSubEntryVO } from "@libs/api/generated-api";
import { formatCurrency, formatCurrencyAsDelta } from "@libs/utils/currency";
import { getLocalDate } from "@libs/utils/date";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import {
  AccordionItem,
  PracticeAndPatientIds,
} from "components/PatientProfile/Billing/Ledger/LedgerComponents";
import { DescriptionSticker } from "components/PatientProfile/Billing/Ledger/DescriptionSticker";
import { ClaimMenu } from "./ClaimMenu";
import { Text } from "./Text";
import { ledgerSubEntryToId } from "./ledgerUtils";
import { usePersistExpandRow } from "./usePersistExpandRow";
import { DetailsTable } from "./DetailsTable";
import {
  ExpandableRow,
  DetailsRow,
  TableRow,
  ProcedureDoubleColumnTableRow,
  DescriptionWithPatientInsuranceRow,
} from "./AppointmentsAndAdjustmentsPage";
import { ProviderInitials } from "./ProviderInitials";

export const ClaimBalanceTransferExpandable: FC<
  PracticeAndPatientIds & {
    claimBalanceTransferSubEntry: LedgerV2ClaimBalanceTransferSubEntryVO;
  }
> = ({ claimBalanceTransferSubEntry }) => {
  const expandRow = usePersistExpandRow(ledgerSubEntryToId(claimBalanceTransferSubEntry));

  return (
    <AccordionItem>
      <ExpandableRow
        itemId={ledgerSubEntryToId(claimBalanceTransferSubEntry)}
        indentation={2}
        date={
          <Text bold>
            <DateWithTooltip date={getLocalDate(claimBalanceTransferSubEntry.timestamp)} format="P" />
          </Text>
        }
        details={
          <DescriptionWithPatientInsuranceRow
            descriptionColumn={<ClaimBalanceTransferDescription />}
            patientColumn={undefined}
            insuranceColumn={undefined}
          />
        }
        amount={undefined}
        outstanding={undefined}
        menu={<ClaimMenu claimUuid={claimBalanceTransferSubEntry.claimUuid} />}
        expandedContent={
          <ClaimBalanceTransferExpanded claimBalanceTransferSubEntry={claimBalanceTransferSubEntry} />
        }
        isExpanded={expandRow.isOn}
        onToggleExpand={expandRow.toggle}
      />
    </AccordionItem>
  );
};

const ClaimBalanceTransferDescription: FC = () => {
  return (
    <div className="flex items-center gap-x-2">
      <DescriptionSticker color="claimBalanceTransfer" />
      <Text bold>Updated Patient Resp</Text>
    </div>
  );
};

const ClaimBalanceTransferProceduresTableHeader: FC = () => {
  return (
    <TableRow
      descriptionColumn={<Text bold>Procedures</Text>}
      patientColumn={<Text bold>Pt Resp Prev/New</Text>}
      insuranceColumn={undefined}
    />
  );
};

const ClaimBalanceTransferExpanded: FC<{
  claimBalanceTransferSubEntry: LedgerV2ClaimBalanceTransferSubEntryVO;
}> = ({ claimBalanceTransferSubEntry }) => {
  const hasTotalsRow = claimBalanceTransferSubEntry.procedures.length > 1;
  const amountChange =
    claimBalanceTransferSubEntry.patientAmount - claimBalanceTransferSubEntry.previousPatientAmount;

  return (
    <>
      <DetailsRow indentation={2}>
        <TableRow
          descriptionColumn={
            <Text>
              Based on {sentenceCaseConstant(claimBalanceTransferSubEntry.insuranceOrdinal)} Claim #
              {claimBalanceTransferSubEntry.claimNumber} – {claimBalanceTransferSubEntry.insuranceCarrier}
            </Text>
          }
          patientColumn={undefined}
          insuranceColumn={undefined}
        />
      </DetailsRow>
      <DetailsRow indentation={2}>
        <DetailsTable>
          <ClaimBalanceTransferProceduresTableHeader />
          {claimBalanceTransferSubEntry.procedures.map((procedure) => (
            <ProcedureDoubleColumnTableRow
              key={procedure.patientProcedureId}
              providerInitials={<ProviderInitials provider={procedure.provider} />}
              cdtCode={<Text>{procedure.cdtCode}</Text>}
              toothName={<Text>{procedure.procedureArea}</Text>}
              procedureName={<Text>{procedure.procedureName}</Text>}
              patientCol1={<Text>{formatCurrency(procedure.previousPatientAmount)}</Text>}
              patientCol2={
                <Text background={hasTotalsRow ? undefined : "orange"}>
                  {formatCurrency(procedure.patientAmount)}
                </Text>
              }
              insuranceCol1={undefined}
              insuranceCol2={undefined}
            />
          ))}
          {hasTotalsRow && (
            <ProcedureDoubleColumnTableRow
              providerInitials={undefined}
              cdtCode={undefined}
              toothName={undefined}
              procedureName={undefined}
              patientCol1={
                <Text bold>{formatCurrency(claimBalanceTransferSubEntry.previousPatientAmount)}</Text>
              }
              patientCol2={
                <Text bold background="orange">
                  {formatCurrency(claimBalanceTransferSubEntry.patientAmount)}
                </Text>
              }
              insuranceCol1={undefined}
              insuranceCol2={undefined}
            />
          )}
          <ProcedureDoubleColumnTableRow
            providerInitials={undefined}
            cdtCode={undefined}
            toothName={undefined}
            procedureName={undefined}
            patientCol1={undefined}
            patientCol2={
              <Text bold color="orange">
                {formatCurrencyAsDelta(amountChange)}
              </Text>
            }
            insuranceCol1={undefined}
            insuranceCol2={undefined}
          />
        </DetailsTable>
      </DetailsRow>
    </>
  );
};
