import { format } from "date-fns";
import { RecurringScheduleVO } from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";
import {
  DaysOfWeekEnum,
  WEEK_IN_DAYS,
  allDays,
  formatISODate,
  getLocalDate,
  weekdays,
} from "@libs/utils/date";
import { listToText } from "@libs/utils/formatString";
import { numberToNth } from "utils/words";
import { isSameArraySet } from "utils/isSameArraySet";

const unitMap: Record<RecurringScheduleVO["recurrenceType"], string> = {
  DAYS_OF_WEEKS: "weeks",
  DAYS_OF_LAST_WEEKS_OF_MONTH: "months",
  DAYS_OF_MONTHS: "months",
  DAYS_OF_WEEKS_OF_MONTH: "months",
  DAYS_OF_YEAR: "years",
  DAYS: "days",
};

const singleUnitMap: Record<RecurringScheduleVO["recurrenceType"], string> = {
  DAYS_OF_WEEKS: "Weekly",
  DAYS_OF_LAST_WEEKS_OF_MONTH: "Monthly",
  DAYS_OF_MONTHS: "Monthly",
  DAYS_OF_WEEKS_OF_MONTH: "Monthly",
  DAYS_OF_YEAR: "Annually",
  DAYS: "Daily",
};

const sortDaysOfTheWeek = (days: DaysOfWeekEnum[]) => {
  const dayIndexMap: Record<DaysOfWeekEnum, number> = {
    SUNDAY: 1,
    MONDAY: 2,
    TUESDAY: 3,
    WEDNESDAY: 4,
    THURSDAY: 5,
    FRIDAY: 6,
    SATURDAY: 7,
  };

  return days.sort((a, b) => dayIndexMap[a] - dayIndexMap[b]);
};

// Given a RecurringScheduleVO object, this function will return a human
// readable string describing the recurrence
// eslint-disable-next-line complexity
export const getRecurrenceLabel = (recurrence: RecurringScheduleVO) => {
  if (
    recurrence.recurrenceType === "DAYS_OF_WEEKS" &&
    isSameArraySet(recurrence.daysOfWeek, weekdays) &&
    recurrence.every === 1
  ) {
    return "Weekdays";
  }

  if (
    recurrence.recurrenceType === "DAYS_OF_WEEKS" &&
    isSameArraySet(recurrence.daysOfWeek, allDays) &&
    recurrence.every === 1
  ) {
    return "Daily";
  }

  const label: string[] = [
    recurrence.every > 1
      ? `Every ${recurrence.every} ${unitMap[recurrence.recurrenceType]}`
      : singleUnitMap[recurrence.recurrenceType],
  ];

  if (recurrence.recurrenceType === "DAYS_OF_WEEKS" && recurrence.daysOfWeek) {
    label.push(
      `on ${
        recurrence.daysOfWeek.length === WEEK_IN_DAYS
          ? "every day of the week"
          : isSameArraySet(recurrence.daysOfWeek, weekdays)
            ? "weekdays"
            : listToText(sortDaysOfTheWeek(recurrence.daysOfWeek).map(sentenceCaseConstant), "and")
      }`
    );
  } else if (
    recurrence.recurrenceType === "DAYS_OF_WEEKS_OF_MONTH" &&
    recurrence.daysOfWeek &&
    recurrence.ordinals
  ) {
    label.push(
      `on the ${numberToNth(recurrence.ordinals[0] + 1)} ${sentenceCaseConstant(recurrence.daysOfWeek[0])}`
    );
  } else if (recurrence.recurrenceType === "DAYS_OF_LAST_WEEKS_OF_MONTH" && recurrence.daysOfWeek) {
    label.push(`on the last ${sentenceCaseConstant(recurrence.daysOfWeek[0])}`);
  } else if (recurrence.recurrenceType === "DAYS_OF_MONTHS" && recurrence.daysOfMonth) {
    label.push(`on the ${numberToNth(recurrence.daysOfMonth[0])}`);
  } else if (recurrence.recurrenceType === "DAYS_OF_YEAR" && recurrence.daysOfYear) {
    const dayOfYear = recurrence.daysOfYear[0];

    label.push(
      `on ${dayOfYear === "--02-29" ? "2/29" : format(getLocalDate(dayOfYear.replace("--", "2023-")), "M/d")}`
    );
  }

  if (recurrence.endDate) {
    label.push(`ending on ${formatISODate(recurrence.endDate)}`);
  }

  return label.join(" ");
};
