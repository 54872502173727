import { FC, useCallback, useMemo, useState } from "react";
import { toast } from "react-toastify";
import {
  DentalProcedureVO,
  PatientChartVisualCondition,
  PatientChartVisualProcedure,
  PatientProcedureVO,
  ProcedureShortcutVO,
  TreatmentPlanVO,
  UpsertPatientToothRequest,
} from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { getValidation } from "@libs/hooks/useValidation";
import { isDefined } from "@libs/utils/types";
import { pluralize } from "@libs/utils/pluralize";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as FullScreen } from "@libs/assets/icons/toggle-full-screen.svg";
import { ReactComponent as ExitFullScreen } from "@libs/assets/icons/shrink.svg";
import { ReactComponent as NoteIcon } from "@libs/assets/icons/note.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { QueryResult } from "@libs/components/UI/QueryResult";
import {
  getAllProcedureShortcutCategories,
  getPatientTreatmentPlansQuery,
  getProcedureShortcutsByCategory,
  getDentalProceduresQuery,
  getPatientChartV2,
} from "api/charting/queries";
import { ProceduresFilters } from "components/Charting/ProceduresFilters";
import { AddProcedures } from "components/Charting/AddProcedures";
import {
  dentalProceduresToDraftPatientProcedures,
  DraftPatientProcedureRequest,
  draftsToPost,
} from "components/Charting/draftPatientProcedure";
import { createPatientProcedures, upsertPatientTeeth } from "api/charting/mutations";
import { handleError } from "utils/handleError";
import { ToothChartSection } from "components/Charting/ToothChartSection";
import { AddPatientProceduresModal } from "components/Charting/AddPatientProceduresModal";
import { getPatientProceduresSchema } from "components/Charting/patientProcedureSchema";
import { ToothChartSelections } from "components/Charting/toothChartData";
import { SelectableProcedureStatus } from "components/Charting/patientProcedureFieldOptions";
import { useActiveProvider } from "components/Charting/ActiveProviderContext";

import { getPracticeProvidersQuery } from "api/practice/queries";
import { useQueryParams } from "hooks/useQueryParams";
import { useItemModal } from "hooks/useItemModal";
import { Surface } from "components/Charting/toothSurfaces";
import { ToothChartRouteLayout } from "components/Charting/ToothChartRouteLayout";
import { usePathParams } from "hooks/usePathParams";
import { TreatmentPlanTableRoute } from "components/Charting/TreatmentPlanTableRoute";
import { PatientProceduresTableRoute } from "components/Charting/PatientProceduresTableRoute";
import { usePatientNotesRouter } from "components/Notes/usePatientNotesRouter";
import { useNow } from "hooks/useNow";

// eslint-disable-next-line complexity
export const ToothChartRoute: FC = () => {
  const now = useNow();
  const { patientId } = usePathParams("charting");
  const { practiceId } = useAccount();
  const { query, updateQuery } = useQueryParams("charting");
  const patientNotes = usePatientNotesRouter();
  const [treatmentType, setTreatmentType] = useState<SelectableProcedureStatus>("PLANNED");
  const [selectedCategory, setSelectedCategory] = useState<ProcedureShortcutVO["category"]>("EXAMS");
  const [addingFromShortcut, setAddingFromShortcut] = useState("");
  const [selectedTeeth, setSelectedTeeth] = useState<ToothChartSelections>({
    type: "NONE",
  });

  const addPatientProceduresModal = useItemModal<{
    draftPatientProcedures: DraftPatientProcedureRequest[];
    dentalProcedures: DentalProcedureVO[];
  }>(null);

  const [
    archivedTreatmentPlansQuery,
    treatmentPlansQuery,
    categoriesQuery,
    shortcutsQuery,
    providersQuery,
    allProceduresQuery,
    chartV2Query,
  ] = useApiQueries([
    getPatientTreatmentPlansQuery({
      args: {
        practiceId,
        patientId,
        includeStates: ["ARCHIVED"],
      },
    }),
    getPatientTreatmentPlansQuery({
      args: {
        practiceId,
        patientId,
        includeStates: ["ACTIVE", "INACTIVE"],
      },
    }),
    getAllProcedureShortcutCategories(),
    getProcedureShortcutsByCategory({ args: { practiceId, category: selectedCategory } }),
    getPracticeProvidersQuery({ args: { practiceId } }),
    getDentalProceduresQuery({ args: { practiceId } }),
    getPatientChartV2({
      args: { patientId, practiceId },
    }),
  ]);

  const { providerId, changeProvider } = useActiveProvider();

  const [addProceduresShortcutMutation, upsertPatientTeethMutation] = useApiMutations([
    createPatientProcedures,
    upsertPatientTeeth,
  ]);

  const handlePatientProceduresCreated = useCallback((patientProcedures: PatientProcedureVO[]) => {
    toast.success(
      pluralize(patientProcedures.length, "Procedure added", `${patientProcedures.length} procedures added`)
    );
    setSelectedTeeth({ type: "NONE" });
  }, []);

  const tryCreatingProcedures = useCallback(
    (
      dentalProcedures: DentalProcedureVO[],
      options?: { onMutate: Func; onSettled: Func; surfaces?: Surface[] }
    ) => {
      if (chartV2Query.data) {
        const drafts = dentalProceduresToDraftPatientProcedures(
          chartV2Query.data,
          dentalProcedures,
          treatmentType,
          providerId,
          selectedTeeth,
          now,
          options?.surfaces
        );

        let expandedDentalProcedures = dentalProcedures;

        if (drafts.length !== dentalProcedures.length) {
          expandedDentalProcedures = drafts
            .map(({ dentalProcedureId }) => dentalProcedures.find((dp) => dp.id === dentalProcedureId))
            .filter(isDefined);
        }

        const schema = getPatientProceduresSchema(expandedDentalProcedures, drafts, chartV2Query.data);
        const result = getValidation(drafts, schema);

        if (result.$isValid) {
          const postItems = draftsToPost(drafts, expandedDentalProcedures, chartV2Query.data);

          options?.onMutate();
          addProceduresShortcutMutation.mutate(
            {
              patientId,
              practiceId,
              data: {
                patientProcedures: postItems,
              },
            },
            {
              onSuccess: (response) => {
                handlePatientProceduresCreated(response.data.data);
              },
              onError: handleError,
              onSettled: options?.onSettled,
            }
          );
        } else {
          addPatientProceduresModal.open({
            draftPatientProcedures: drafts,
            dentalProcedures: expandedDentalProcedures,
          });
        }
      }
    },
    [
      providerId,
      treatmentType,
      selectedTeeth,
      chartV2Query.data,
      handlePatientProceduresCreated,
      addPatientProceduresModal,
      addProceduresShortcutMutation,
      patientId,
      practiceId,
      now,
    ]
  );

  const handleSelectShortcut = useCallback(
    (shortcut: ProcedureShortcutVO) => {
      tryCreatingProcedures(shortcut.procedures, {
        onMutate: () => setAddingFromShortcut(shortcut.uuid),
        onSettled: () => setAddingFromShortcut(""),
        surfaces: shortcut.surfaces,
      });
    },
    [tryCreatingProcedures]
  );

  const handleSelectProcedure = useCallback(
    (dentalProcedure: DentalProcedureVO) => {
      tryCreatingProcedures([dentalProcedure]);
    },
    [tryCreatingProcedures]
  );

  const handleUpdateTeeth = useCallback(
    (teeth: UpsertPatientToothRequest[]) => {
      upsertPatientTeethMutation.mutate(
        { patientId, practiceId, data: { teeth } },
        {
          onError: handleError,
        }
      );
    },
    [practiceId, patientId, upsertPatientTeethMutation]
  );

  const tabsQueries = [treatmentPlansQuery, archivedTreatmentPlansQuery];

  const markings = useMemo(() => {
    return chartV2Query.data?.reduce(
      (next, entry) => {
        next[`${entry.toothNum}`] = entry.visuals;

        return next;
      },
      {} as Record<string, (PatientChartVisualCondition | PatientChartVisualProcedure)[]>
    );
  }, [chartV2Query.data]);

  return (
    <>
      <ToothChartRouteLayout
        odontogram={
          chartV2Query.isLoadingError ? (
            <ErrorContent />
          ) : (
            <ToothChartSection
              patientId={patientId}
              teeth={chartV2Query.data}
              markings={markings}
              isLoading={chartV2Query.isLoading}
              isSaving={upsertPatientTeethMutation.isLoading || chartV2Query.isFetching}
              selectedTeeth={selectedTeeth}
              onSelectTeeth={setSelectedTeeth}
              onUpdateTeeth={handleUpdateTeeth}
            />
          )
        }
        addProcedures={
          <QueryResult queries={[providersQuery, categoriesQuery, chartV2Query, allProceduresQuery]}>
            <AddProcedures
              addingFromShortcut={addingFromShortcut}
              areShortcutsLoading={shortcutsQuery.isLoading}
              providers={providersQuery.data}
              categories={categoriesQuery.data}
              shortcuts={shortcutsQuery.data}
              procedures={allProceduresQuery.data}
              selectedCategory={selectedCategory}
              treatmentType={treatmentType}
              providerId={providerId}
              onSelectProcedure={handleSelectProcedure}
              onProviderChange={changeProvider}
              onTreatmentTypeChange={setTreatmentType}
              onSelectCategory={setSelectedCategory}
              onShortcutClick={handleSelectShortcut}
            />
          </QueryResult>
        }
        tables={
          <>
            <QueryResult queries={tabsQueries}>
              <div
                className={`
                  px-5
                  py-2.5
                  border-b
                  border-slate-200
                  flex
                  items-center
                  flex-none
                  justify-between
                  relative
                  z-30
                `}
              >
                <div className="flex items-center">
                  <ProceduresFilters
                    onSelectTreatmentPlan={(tp: TreatmentPlanVO) => {
                      updateQuery("replaceIn", { treatmentPlanUuid: tp.uuid });
                    }}
                    onSelectMasterTxPlan={() => {
                      updateQuery("replaceIn", { treatmentPlanUuid: undefined });
                    }}
                    onUpdateStatusFilter={(newStatuses) =>
                      updateQuery("replaceIn", { patientProcedureStatuses: newStatuses })
                    }
                    treatmentPlanUuid={query.treatmentPlanUuid}
                    patientProcedureStatuses={query.patientProcedureStatuses}
                    treatmentPlans={treatmentPlansQuery.data}
                    archivedPlans={archivedTreatmentPlansQuery.data}
                  />
                </div>
                <div className="flex items-center gap-x-6">
                  <ButtonIcon
                    SvgIcon={NoteIcon}
                    tooltip={{ content: "Clinical Notes", theme: "SMALL" }}
                    onClick={() => patientNotes.open("list", { patientId }, { clinicalNotes: true })}
                    theme="primary"
                  />
                  <ButtonIcon
                    tooltip={{
                      theme: "SMALL",
                      content: query.tableFullScreen ? "Exit Table Only View" : "Table Only View",
                    }}
                    onClick={() =>
                      updateQuery("replaceIn", {
                        tableFullScreen: !query.tableFullScreen,
                      })
                    }
                    theme="primary"
                    SvgIcon={query.tableFullScreen ? ExitFullScreen : FullScreen}
                  />
                </div>
              </div>
            </QueryResult>
            <div
              className={cx(
                tabsQueries.some((tabQuery) => tabQuery.isLoading) ? "hidden" : "flex-1 min-h-0 flex flex-col"
              )}
            >
              {query.treatmentPlanUuid ? (
                <TreatmentPlanTableRoute treatmentPlanUuid={query.treatmentPlanUuid} />
              ) : (
                <PatientProceduresTableRoute />
              )}
            </div>
          </>
        }
        tableFullScreen={query.tableFullScreen}
      />

      {addPatientProceduresModal.isOpen && providersQuery.data && chartV2Query.data ? (
        <AddPatientProceduresModal
          onRequestClose={addPatientProceduresModal.close}
          onCreated={(data) => {
            handlePatientProceduresCreated(data);
            addPatientProceduresModal.close();
          }}
          dentalProcedures={addPatientProceduresModal.item.dentalProcedures}
          draftPatientProcedures={addPatientProceduresModal.item.draftPatientProcedures}
          providers={providersQuery.data}
          patientId={patientId}
        />
      ) : null}
    </>
  );
};
