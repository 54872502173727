import React, { FC, useMemo } from "react";
import { InboundReferralVO } from "@libs/api/generated-api";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { useQueryParams } from "hooks/useQueryParams";
import { ReferralsQuery } from "utils/routing/dashboard";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { getInboundReferralStats } from "api/reporting/queries";
import { dashboardFiltersForTables } from "components/Dashboard/utils/filters";
import { DashboardLayout } from "components/Dashboard/DashboardLayout";
import { DashboardPageHeader } from "components/Dashboard/DashboardPageHeader";
import { MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";
import { PieDatumColored } from "components/Dashboard/Charting/types";
import { ReferralsTable } from "components/Dashboard/Referrals/ReferralsTable";
import { useDownloadReferralsCsv } from "components/Dashboard/Referrals/hooks/useDownloadReferralsCsv";
import { ReferralsChart } from "components/Dashboard/Referrals/ReferralsChart";
import { getClaimInsuranceCarriersQuery } from "api/patientInsurance/queries";
import { useFlattenEntries } from "components/Dashboard/hooks/useFlattenEntries";

export type ReferralPieDatum = PieDatumColored<{ netProduction: number }>;

export const ReferralsRoute: FC = () => {
  const { practiceId } = useAccount();
  const { query, updateQuery } = useQueryParams("dashboardReferrals");
  const handleRouteStateChange = React.useCallback(
    (updates: Partial<ReferralsQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );

  const dateWindow = useTimeSeriesPageSelections(query);

  const { selectedTimeSegment, selectedTimeSegmentParams } = dateWindow;

  const { search: debouncedSearch } = useDebouncedSearch(query.patientSearch ?? "");

  const [carriersQuery] = useApiQueries([getClaimInsuranceCarriersQuery({ args: { practiceId } })]);

  const inboundReferralStatsFilteredQuery = useInfiniteApiQuery(
    getInboundReferralStats({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: MAX_PAGE_SIZE,
        data: {
          ...selectedTimeSegmentParams,
          filters: dashboardFiltersForTables(query.filters),
          patientSearchString: debouncedSearch,
          sortOrders: query.tableSort,
        },
      },
    })
  );

  const inboundReferralStatsQuery = useInfiniteApiQuery(
    getInboundReferralStats({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: MAX_PAGE_SIZE,
        data: {
          ...selectedTimeSegmentParams,
        },
      },
    })
  );

  const referredByRows = useFlattenEntries<InboundReferralVO>(inboundReferralStatsQuery.data);
  const referralOptions = useMemo(() => {
    return [...new Set(referredByRows.map((row) => row.referredBy))].map((option) => ({
      label: option,
      value: option,
    }));
  }, [referredByRows]);

  const { downloadCSV, isDownloading } = useDownloadReferralsCsv({
    inboundReferralStatsFilteredQuery,
    selectedTimeSegment,
  });

  return (
    <DashboardLayout
      header={
        <>
          <DashboardPageHeader
            question="referrals"
            focusDate={query.focusDate}
            dateWindow={dateWindow}
            onUpdateParams={handleRouteStateChange}
          />
          {!query["table.fullScreen"] && (
            <ReferralsChart
              selectedTimeSegment={selectedTimeSegment}
              inboundReferralStatsQuery={inboundReferralStatsQuery}
              onUpdateParams={handleRouteStateChange}
              query={query}
            />
          )}
        </>
      }
      className="min-w-[1400px]"
    >
      <ReferralsTable
        carriersQuery={carriersQuery}
        inboundReferralStatsFilteredQuery={inboundReferralStatsFilteredQuery}
        isDownloadingTable={isDownloading}
        onClickDownload={downloadCSV}
        onUpdateParams={handleRouteStateChange}
        patientSearch={debouncedSearch}
        query={query}
        referralOptions={referralOptions}
      />
    </DashboardLayout>
  );
};
