import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ClaimLineItemGroupVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { Button } from "@libs/components/UI/Button";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { ClaimModalHeader } from "components/Claim/ModalHeader";
import { ProcedureTable } from "components/Claim/ProcedureTable";
import { ClaimModalFooter } from "components/Claim/ModalFooter";
import { ClaimSection } from "components/Claim/ClaimSection";
import { getProcedureTableHeaders } from "components/Claim/procedureTableUtils";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { getClaimQuery } from "api/claim/queries";

export const sortByVersion = (a: ClaimLineItemGroupVO, b: ClaimLineItemGroupVO) => {
  return b.version - a.version;
};

export const ClaimHistoryRoute: React.FC = () => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const { claimUuid } = usePathParams("claimHistory");
  const { query } = useQueryParams("claimHistory");

  const from = query.from ?? paths.claims();
  const [claimQuery] = useApiQueries([getClaimQuery({ args: { practiceId, claimUuid } })]);

  const { data: claim } = claimQuery;
  const onNavigateBack = useCallback(() => {
    navigate(from);
  }, [from, navigate]);

  const headers = getProcedureTableHeaders({ claim, isAddingAdjustment: false });

  return claim ? (
    <>
      <ClaimModalHeader
        onClose={onNavigateBack}
        data-testid="history-claim-header"
        title="Claim History"
        titleDetails={[`Claim#${claim.claimNumber}`]}
      />
      <span className="px-8 pt-5 text-sm">
        View history of claim activity ordered from most recent to oldest.
      </span>
      <div className="flex flex-1 flex-col px-8 overflow-y-auto">
        {claim.lineItemGroups
          .filter((group) => group.processedAt)
          .sort(sortByVersion)
          .map((group, index) => (
            <ClaimSection
              key={index}
              subTitle={`${group.processedBy ? ` - By ${group.processedBy.fullDisplayName}` : ""}${
                group.processedAt ? ` -  ${formatISODate(group.processedAt)}` : ""
              }`}
              title={
                group.versionType === "PAYMENT"
                  ? "Original Claim"
                  : group.versionType === "ADDITIONAL"
                    ? "Additional Payment/Reduction"
                    : "Claim Adjustment"
              }
            >
              <ProcedureTable
                claim={claim}
                headers={headers}
                lineItems={group.lineItems}
                showTotals={true}
                version={group.version}
              />
            </ClaimSection>
          ))}
      </div>
      <ClaimModalFooter>
        <Button className="w-[120px]" onClick={onNavigateBack} theme="primary">
          Done
        </Button>
      </ClaimModalFooter>
    </>
  ) : null;
};
