import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";

interface Props {
  contactSearchString: string;
  onSearchContact: (value: string) => void;
  onSearchClear: Func;
}

export const ContactSearch: React.FC<Props> = ({ contactSearchString, onSearchContact, onSearchClear }) => {
  return (
    <div className="px-6 pt-6 pb-5 border-b border-transparent">
      <div
        className={`
          flex
          items-center
          gap-x-3
          mb-0.5
          rounded
          border
          bg-white
          focus-within:border-primaryTheme
          hover:border-primaryTheme
          border-greyLighter
        `}
      >
        <input
          type="text"
          className={`
            block
            w-full
            rounded
            pl-3
            h-8
            text-sm
            placeholder:text-greyLight
            focus-visible:outline-none
          `}
          placeholder="Search by name or number"
          value={contactSearchString}
          onChange={(e) => onSearchContact(e.target.value)}
        />

        <div className="pr-3">
          {contactSearchString ? (
            <ButtonIcon SvgIcon={CloseIcon} onClick={onSearchClear} size="md" />
          ) : (
            <SearchIcon className="w-5 h-5 fill-greyMedium" />
          )}
        </div>
      </div>
    </div>
  );
};
