/* eslint-disable react-hooks/rules-of-hooks */
// eslint-disable-next-line unicorn/prefer-node-protocol
import { useImagingHub } from "components/ImageCapturing/ImagingHubContext";
import { useAISSampleImage } from "components/PatientProfile/Imaging/MountRoute/hooks/useAISSampleImage";
import { useDynamsoftSensorSampleImage } from "components/PatientProfile/Imaging/MountRoute/hooks/useDynamsoftSensorSampleImage";

export const useSensorSampleImage = () => {
  const { status: hubStatus } = useImagingHub();

  if (hubStatus.isRunning && !hubStatus.isAISDisabled) {
    return useAISSampleImage();
  }

  return useDynamsoftSensorSampleImage();
};
