import React from "react";

type Props = {
  neededDownloadUrl: string;
};

export const DynamsoftDriverRequired: React.FC<Props> = ({ neededDownloadUrl }) => {
  return (
    <span className="p-4 text-center text-xs">
      To get started with x-rays, please{" "}
      <a
        className="text-primaryTheme font-sansSemiBold hover:opacity-80"
        href={neededDownloadUrl}
        target="_blank"
      >
        download and install the drivers
      </a>{" "}
      and then reload this page
    </span>
  );
};
