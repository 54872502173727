import React from "react";
import { TimeSeriesDataPoint } from "@libs/api/generated-api";
import { mergeTimelines } from "components/Dashboard/Charting/utils";
import { TimeSeriesQuery } from "components/Dashboard/types";

export const useSingleTimeseriesAsStack = (query: TimeSeriesQuery, emptyTimeline: TimeSeriesDataPoint[]) => {
  return React.useMemo(() => {
    if (query.data) {
      const [series] = query.data.timeSeries;

      return mergeTimelines(emptyTimeline, series.dataPoints);
    }

    return emptyTimeline;
  }, [query.data, emptyTimeline]);
};
