import { FC } from "react";

import { useAccount } from "@libs/contexts/AccountContext";
import { useApiQueries } from "@libs/hooks/useApiQueries";

import { QueryResult } from "@libs/components/UI/QueryResult";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { MessagingServices } from "components/Settings/Communications/MessagingService/MessagingServices";

import { getTwilioRegistrationStatus } from "api/communications/queries";

export const MessagingServicesRoute: FC = () => {
  const { practiceId } = useAccount();

  const [twilioRegistrationStatusQuery] = useApiQueries([
    getTwilioRegistrationStatus({ args: { practiceId } }),
  ]);

  return (
    <QueryResult queries={[twilioRegistrationStatusQuery]}>
      {twilioRegistrationStatusQuery.data ? (
        <SettingsPanel title="Messaging Services">
          <MessagingServices twilioOnboardingStatus={twilioRegistrationStatusQuery.data} />
        </SettingsPanel>
      ) : null}
    </QueryResult>
  );
};
