import { FC, FormEvent, useCallback, useMemo, useState } from "react";
import { produce, Draft } from "immer";

import {
  BatchFormResponseRequest,
  FormSectionVO,
  FormSelectInputElementVO,
  FormSelectResponseVO,
  FormSubmissionVO,
  FormVO,
  PatientSummaryVO,
} from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { isDefined, isNullish } from "@libs/utils/types";
import { getAgeByDob } from "@libs/utils/formatString";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { EmptyContent } from "@libs/components/UI/EmptyContent";
import { FlyoverContent, FlyoverFooter, FlyoverForm } from "components/UI/FlyoverComponents";
import { FormStructure } from "components/PatientProfile/Forms/FormStructure";
import { FormSubmissionResponses } from "components/PatientProfile/Forms/types";
import { findElement } from "components/Settings/Forms/utils";
import { filterForm, getAllInputElementIds } from "components/PatientProfile/Forms/utils";
import EmptyMedicalData from "assets/images/empty-medical-data.svg";

import { useNow } from "hooks/useNow";

interface Props {
  isSaving: boolean;
  responses: FormSubmissionVO["responses"];
  form: FormVO;
  patient: PatientSummaryVO;
  onClose: Func;
  onSubmit: (data: BatchFormResponseRequest) => void;
}

const canPatientViewSection = (now: Date, patient: PatientSummaryVO, section: FormSectionVO) => {
  const { conditions } = section;

  if (!conditions) {
    return true;
  }

  const age = getAgeByDob(now, patient.dob);
  const gender = patient.gender;

  const { maxAgeYears, minAgeYears, genders } = conditions;

  const max = maxAgeYears ?? Number.POSITIVE_INFINITY;
  const min = minAgeYears ?? 0;

  const satisfiesAge = isNullish(age) || (min <= age && max > age);
  const safisfiesGender = isNullish(gender) || !genders.length || genders.includes(gender);

  return safisfiesGender && satisfiesAge;
};

const cleanSelectResponse = (response: Draft<FormSelectResponseVO>, element: FormSelectInputElementVO) => {
  // if question no longer accepts other drop the "other" response
  if (isDefined(response.other) && !element.settings.includes("ALLOW_ADDITIONAL_OPTION")) {
    delete response.other;
  }

  const options = new Set(element.options);

  const responseOptionIds = Object.keys(response.responses);

  for (const responseOptionId of responseOptionIds) {
    if (!options.has(responseOptionId)) {
      delete response.responses[responseOptionId];
    }
  }

  // question changes the selection setting need to clean up previous responses
  if (
    !element.settings.includes("ALLOW_MULTIPLE_SELECTIONS") &&
    !element.settings.includes("ENFORCE_EXPLICIT_CONSENT")
  ) {
    // if multiple  selections were made before ignore and prompt user to select a response
    const selectionCount = Object.keys(response.responses).length + (isNullish(response.other) ? 0 : 1);

    if (selectionCount > 1) {
      response.responses = {};
      delete response.other;
    }
  }
};

export const EditResponsesForm: FC<Props> = ({ patient, responses, form, onClose, onSubmit, isSaving }) => {
  const now = useNow();
  const filteredForm = useMemo(() => {
    return filterForm(form, (element) => {
      if (isOneOf(element.type, ["TEXT_BLOCK", "CONSENT"])) {
        return false;
      }

      if (element.type === "SECTION") {
        return canPatientViewSection(now, patient, element);
      }

      return true;
    });
  }, [patient, form, now]);

  const [draftResponses, setDraftResponses] = useState<FormSubmissionResponses>(() =>
    // strip away responses to questions that are not in the form
    produce(responses, (draft) => {
      const formIds = new Set(getAllInputElementIds(filteredForm));
      const responseIds = Object.keys(draft);

      for (const responseId of responseIds) {
        if (formIds.has(responseId)) {
          const response = draft[responseId];

          if (response.type !== "SELECT") {
            continue;
          }

          const match = findElement(filteredForm, "uuid", responseId);
          const element = match?.element;

          if (element?.type !== "SELECT_INPUT") {
            continue;
          }

          cleanSelectResponse(response, element);
        } else {
          delete draft[responseId];
        }
      }
    })
  );

  const handleUpdate = useCallback((updater: (draft: Draft<FormSubmissionResponses>) => void) => {
    setDraftResponses((previousDraft) => produce(previousDraft, (editable) => void updater(editable)));
  }, []);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    onSubmit({
      responses: draftResponses as BatchFormResponseRequest["responses"],
      formPublishedContentUuid: form.publishedContentUuid ?? "",
    });
  };

  const noInputsMessage = useMemo(() => {
    const hasInputs = Boolean(form.content.length);
    const hasMatchingInputs = Boolean(filteredForm.content.length);

    return hasMatchingInputs
      ? ""
      : hasInputs
        ? "No questions match the patient's demographic."
        : "No questions have been added.";
  }, [form.content.length, filteredForm.content.length]);

  return (
    <FlyoverForm onSubmit={handleSubmit}>
      <FlyoverContent>
        {noInputsMessage ? (
          <EmptyContent fullHeight text={noInputsMessage} alt={noInputsMessage} src={EmptyMedicalData} />
        ) : (
          <FormStructure onUpdate={handleUpdate} form={filteredForm} responses={draftResponses} />
        )}
      </FlyoverContent>
      <FlyoverFooter>
        <Button className="min-w-button" type="button" theme="secondary" onClick={onClose}>
          Cancel
        </Button>
        <AsyncButton className="min-w-button" isLoading={isSaving} type="submit">
          Save
        </AsyncButton>
      </FlyoverFooter>
    </FlyoverForm>
  );
};
