import React, { useState } from "react";
import {
  AppointmentCategoryVO,
  AppointmentRequest,
  AppointmentVO,
  PatientToothVO,
  ProviderVO,
  RoomVO,
  TagVO,
} from "@libs/api/generated-api";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useAppointmentSlotEditor } from "components/ScheduleAppointments/useAppointmentSlotEditor";
import { PreSelections } from "components/ScheduleAppointments/types";

import { AppointmentForm } from "components/ScheduleAppointments/AppointmentForm";
import { AppointmentDurationContext } from "components/ScheduleAppointments/AppointmentDurationContext";
import { useEditAppointmentForm } from "./EditAppointmentForm.utils";

interface Props {
  providers: ProviderVO[];
  teeth: PatientToothVO[];
  categories: AppointmentCategoryVO[];
  rooms: RoomVO[];
  formId: string;
  preSelections: PreSelections;
  appointment: AppointmentVO;
  tags: TagVO[];
  onSubmit: (appointment: AppointmentRequest) => void;
  onValidationChange: (isValid: boolean) => void;
  onDirty: Func;
  patientName?: string;
}

export const EditAppointmentForm: React.FC<Props> = ({
  categories,
  providers,
  rooms,
  teeth,
  formId,
  preSelections,
  appointment,
  tags,
  onValidationChange,
  onSubmit,
  onDirty,
  patientName,
}) => {
  const {
    procedures,
    color,
    hasCustomColor,
    selectedCategoryId,
    addProcedures,
    setColor,
    updateDraft,
    switchSavedProcedure,
    switchNewProcedure,
    removeProcedure,
    selectCategory,
    duration,
  } = useEditAppointmentForm({ appointment, categories });

  const [tagIds, setTagIds] = useState<number[]>(
    () => appointment.tags?.customAppointment.map((tag) => tag.id) ?? []
  );

  const confirmationAndReminders = useBoolean(appointment.sendConfirmationAndReminders);

  const slotEditor = useAppointmentSlotEditor(preSelections, duration.read);

  return (
    <AppointmentDurationContext.Provider value={duration}>
      <AppointmentForm
        formId={formId}
        patientId={appointment.patient.id}
        initialComment={appointment.comments ?? ""}
        confirmationAndReminders={confirmationAndReminders}
        addToAsapList={appointment.asap}
        asapPreference={appointment.preference}
        savedAppointment={appointment}
        providers={providers}
        rooms={rooms}
        teeth={teeth}
        categories={categories}
        color={color}
        hasCustomColor={hasCustomColor}
        procedures={procedures}
        selectedCategoryId={selectedCategoryId}
        slotEditor={slotEditor}
        onSubmit={onSubmit}
        onValidationChange={onValidationChange}
        onProceduresAdded={addProcedures}
        onColorChange={setColor}
        onSelectCategory={selectCategory}
        onUpdateProcedureDraft={updateDraft}
        onRemoveProcedure={removeProcedure}
        onSavedProcedureSwitch={switchSavedProcedure}
        onNewProcedureSwitch={switchNewProcedure}
        onDirty={onDirty}
        patientSlot={
          <div className="flex items-center w-3/5 gap-x-5">
            <div className="font-sansSemiBold text-sm">{patientName}</div>
            <Checkbox checked={confirmationAndReminders.isOn} onChange={confirmationAndReminders.toggle}>
              Send auto-reminders
            </Checkbox>
          </div>
        }
        tagIds={tagIds}
        onUpdateTags={setTagIds}
        tags={tags}
        autoTagIds={appointment.tags?.autoAppointment.map((tag) => tag.id)}
      />
    </AppointmentDurationContext.Provider>
  );
};
