import { ApiErrorResponse } from "@libs/@types/api";
import { CONFLICT } from "@libs/utils/statusCodes";

export type TagValidations = Partial<{
  icon: string;
  label: string;
}>;

export const getTagValidations = (err: ApiErrorResponse): TagValidations | undefined => {
  if (err.status === CONFLICT && err.error.errors) {
    const validations: TagValidations = {};
    let hasValidation = false;

    for (const error of err.error.errors) {
      if (error.message === "Tag with the same glyph already exists.") {
        validations.icon = "Another tag already has this icon.";
        hasValidation = true;
      } else if (error.message === "Tag with the same name already exists.") {
        validations.label = "Another tag already has this label.";
        hasValidation = true;
      }
    }

    if (hasValidation) {
      return validations;
    }
  }

  return undefined;
};
