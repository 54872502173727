import React, { useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Button } from "@libs/components/UI/Button";
import { useAccount } from "@libs/contexts/AccountContext";
import { FormFieldSwitch } from "@libs/components/UI/FormFieldSwitch";
import { handleError } from "utils/handleError";
import { getPracticeBillingSetting } from "api/billing/queries";
import { updatePracticeBillingSetting } from "api/billing/mutations";

interface Props {
  editSection: boolean;
  onSave: Func;
  onCancel: Func;
}

export const PatientPortalSettings: React.FC<Props> = ({ editSection, onSave, onCancel }) => {
  const { practiceId } = useAccount();
  const [collectPaymentInfoOnPatientPortal, setCollectPaymentInfoOnPatientPortal] = useState<boolean>();

  const [{ data: billingSettings }] = useApiQueries([getPracticeBillingSetting({ args: { practiceId } })]);

  useEffect(() => {
    if (billingSettings) {
      setCollectPaymentInfoOnPatientPortal(billingSettings.collectPaymentInfoOnPatientPortal);
    }
  }, [billingSettings]);

  const [{ mutate: updateBillingSettings, isLoading: isSaving }] = useApiMutations([
    updatePracticeBillingSetting,
  ]);

  const handleSave = () => {
    updateBillingSettings(
      {
        practiceId,
        data: { collectPaymentInfoOnPatientPortal },
      },
      {
        onError: (err) => handleError(err),
        onSuccess: () => {
          onSave();
        },
      }
    );
  };

  const handleCancel = () => {
    // Reset to original value
    billingSettings &&
      setCollectPaymentInfoOnPatientPortal(billingSettings.collectPaymentInfoOnPatientPortal);
    onCancel();
  };

  return (
    <div className="text-xs">
      <div className="mb-3">
        <div>
          {billingSettings ? (
            <FormFieldSwitch
              label="Collect & save patient payment information on patient portal"
              edit={editSection}
              trueText="Yes"
              falseText="No"
              checked={collectPaymentInfoOnPatientPortal}
              onChange={() => setCollectPaymentInfoOnPatientPortal((last) => !last)}
            />
          ) : (
            <Skeleton className="text-sm" />
          )}
          {editSection && (
            <div className="mt-1">
              Marking this as &quot;Yes&quot; will make the &quot;Payment Information&quot; step in the
              patient onboarding process a mandatory step. Once patients save their credit cards, they will be
              added to the patient&apos;s profile on the practice portal. This will make it easy for the
              practice to collect dues as well as for the patients to make faster online payments.
            </div>
          )}
        </div>
        {editSection && (
          <div className="my-6">
            <Button disabled={isSaving} onClick={handleCancel} className="w-24" theme="secondary">
              Cancel
            </Button>
            <Button disabled={isSaving} onClick={handleSave} className="w-24 ml-3" theme="primary">
              Save
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
