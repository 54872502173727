import React from "react";
import { Route } from "react-router-dom";
import { SentryRoutes } from "components/UI/SentryRoutes";

export const TableRoutes: React.FC<{
  routes: {
    path: string;
    element: JSX.Element;
  }[];
}> = ({ routes }) => {
  return (
    <div className="h-full w-full flex flex-col min-h-0">
      <SentryRoutes>
        {routes.map(({ element, path }) => {
          return <Route key={path} element={element} path={path} />;
        })}

        <Route path="*" element={<div className="text-center p-4">Table not found</div>} />
      </SentryRoutes>
    </div>
  );
};
