import { FC } from "react";
import { Link } from "react-router-dom";
import { EmployeeOnboardingStatusVO } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { ReactComponent as ArrowIcon } from "@libs/assets/icons/right-arrow.svg";
import { Icon } from "@libs/components/UI/Icon";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Banner } from "@libs/components/UI/Banner";
import { paths } from "utils/routing/paths";

export const GustoPartnerBanner: FC<{
  employeeId: number;
  employeePayrollOnboardingQuery: ApiQueryResult<EmployeeOnboardingStatusVO>;
}> = ({ employeeId, employeePayrollOnboardingQuery }) => {
  return (
    <div className="mb-6 min-h-10">
      <QueryResult queries={[employeePayrollOnboardingQuery]}>
        {employeePayrollOnboardingQuery.data?.status && (
          <Banner
            className="rounded"
            theme={employeePayrollOnboardingQuery.data.status === "ONBOARDED" ? "info" : "warning"}
          >
            {employeePayrollOnboardingQuery.data.status === "NOT_ONBOARDED" ? (
              <div className="flex justify-between items-center text-xs flex-1">
                <div className="flex-1">Complete Payroll Information</div>{" "}
                <Link
                  className="font-sansSemiBold text-orangeDark flex items-center gap-x-2"
                  to={paths.employeeEmploymentGusto({ id: employeeId })}
                >
                  Complete
                  <Icon SvgIcon={ArrowIcon} theme="darkOrange" />
                </Link>
              </div>
            ) : employeePayrollOnboardingQuery.data.status === "NEEDS_AUTHENTICATION" ? (
              <div className="text-xs flex-1">
                <div className="font-sansSemiBold">Gusto Authentication Missing</div>
                <div>Ask your administrator to sign back into Gusto from Practice Settings.</div>
              </div>
            ) : (
              <div className="flex justify-between items-center text-xs flex-1">
                <div className="flex-1">Payroll Setup</div>{" "}
                <Link
                  className={`
                    font-sansSemiBold
                    text-primaryTheme
                    flex
                    items-center
                    gap-x-2
                  `}
                  to={paths.employeeEmploymentGusto({ id: employeeId })}
                >
                  View Payroll Profile
                  <Icon SvgIcon={ArrowIcon} theme="primary" />
                </Link>
              </div>
            )}
          </Banner>
        )}
      </QueryResult>
    </div>
  );
};
