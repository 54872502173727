import { InsuranceCarrierVO, ProviderVO } from "@libs/api/generated-api";
import { toFilterComponentsProps } from "@libs/components/UI/queryFilterPillsUtils";
import { FeeSchedulesQuery } from "utils/routing/settings";

const getInsuranceCarrierName = (insuranceCarriers: InsuranceCarrierVO[] | undefined, val: number) => {
  return insuranceCarriers?.find((carrier) => carrier.id === val)?.name ?? "";
};

const getProviderName = (providers: ProviderVO[] | undefined, val: number) => {
  return providers?.find((provider) => provider.id === val)?.name.fullDisplayName ?? "";
};

export const getFeeSchedulesFilterProps = (
  insuranceCarriers: InsuranceCarrierVO[] | undefined,
  providers: ProviderVO[] | undefined,
  queryState: FeeSchedulesQuery
) =>
  toFilterComponentsProps(queryState, [
    {
      type: "list",
      prop: "carrierIds",
      format: (val) => getInsuranceCarrierName(insuranceCarriers, val),
    },
    {
      type: "list",
      prop: "inNetwork",
      format: (val) => (val === "true" ? "In Network" : "Out of Network"),
    },
    {
      type: "list",
      prop: "providerIds",
      format: (val) => getProviderName(providers, val),
    },
    {
      type: "default",
      prop: "searchString",
      format: (val) => `Search: ${val.trim()}`,
    },
    {
      type: "list",
      prop: "states",
      format: (val) => (val === "ACTIVE" ? "Active" : "Archived"),
    },
    {
      type: "list",
      prop: "types",
      format: (val) => (val === "PROVIDER_UCR" ? "Provider UCR" : val === "CARRIER" ? "Fee Schedule" : ""),
    },
  ]);
