import { useCallback } from "react";

import { MigratedTransactionVO } from "@libs/api/generated-api";
import { useAccount } from "@libs/contexts/AccountContext";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getLocalDate, formatISODate } from "@libs/utils/date";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { formatCurrency } from "@libs/utils/currency";

import { SortDirection } from "@libs/components/UI/GridTableComponents";
import { importedLedgerTableHeaders } from "components/PatientProfile/Billing/ImportedLedger/ImportedLedgerTable";
import { useDownloadQueryPages } from "components/Dashboard/hooks/useDownloadInfiniteQuery";
import { MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";
import { formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";

import { listMigratedTransactions } from "api/billing/queries";
import { useCurrentUrl } from "contexts/OriginContext";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { handleError } from "utils/handleError";

const formatToCsv = (data: MigratedTransactionVO[]) => {
  const headers = formatCsvRow(importedLedgerTableHeaders.map(({ label }) => label));
  const rows = data.map((transaction) =>
    formatCsvRow([
      formatISODate(transaction.date),
      sentenceCaseConstant(transaction.type),
      transaction.code ?? "",
      transaction.description,
      transaction.note ?? "",
      transaction.employee?.fullDisplayName ?? "",
      formatCurrency(transaction.amount),
    ])
  );

  return [headers, ...rows];
};

const getSelectedTimeSegment = (data: MigratedTransactionVO[], sortDirection: SortDirection) => {
  const startIndex = sortDirection === "ASCENDING" ? 0 : data.length - 1;
  const startDate = data[startIndex].date;
  const endDate = data[startIndex > 0 ? 0 : data.length - 1].date;

  return {
    startDate: getLocalDate(startDate),
    endDate: getLocalDate(endDate),
  };
};

export const useDownloadImportedLedgerCsv = () => {
  const { practiceId } = useAccount();
  const { patientId } = usePathParams("importedLedger");
  const { query } = useQueryParams("importedLedger");
  const currentUrl = useCurrentUrl();

  const downloading = useBoolean(false);
  const setDownloading = downloading.set;

  const migratedTransactionsQuery = useInfiniteApiQuery(
    listMigratedTransactions({
      args: {
        patientId,
        practiceId,
        types: query.types,
        employeeIds: query.employeeIds,
        searchString: query.searchString,
        sortDirection: query.sortDirection,
        pageNumber: 1,
        pageSize: MAX_PAGE_SIZE,
      },
      queryOptions: { enabled: downloading.isOn },
    })
  );

  const { startDownload } = useDownloadQueryPages(migratedTransactionsQuery);

  const handleDownloadCsv = useCallback(async () => {
    setDownloading(true);

    try {
      const result = await startDownload();
      const data = result.pages.flatMap((item) => item.data);
      const selectedTimeSegment = getSelectedTimeSegment(data, query.sortDirection);
      const metadata = getArchyCsvMetadata(currentUrl, selectedTimeSegment);
      const csvRows = formatToCsv(data);

      saveCsv([...metadata.headers, ...csvRows], `imported-ledger_${patientId}_${metadata.fileNameTag}`);
    } catch (error) {
      handleError(error);
    } finally {
      setDownloading(false);
    }
  }, [setDownloading, startDownload, query.sortDirection, currentUrl, patientId]);

  return {
    downloadCsv: handleDownloadCsv,
    isDownloading: downloading.isOn,
  };
};
