import React from "react";
import { AddressVO, ProfileImageVO } from "@libs/api/generated-api";

interface InvoiceAddress extends React.HTMLAttributes<HTMLDivElement> {
  name: string;
  address?: AddressVO;
  profileImage?: ProfileImageVO["thumbnail"];
}

export const InvoiceAddress: React.FC<InvoiceAddress> = ({ name, address, profileImage, className }) => {
  return (
    <div className={className}>
      <div className="flex h-8 items-center mb-2 gap-x-2">
        {profileImage && (
          <div className="p-1 h-8 w-8 rounded border border-greyLightest">
            <img
              alt={name}
              className="object-contain overflow-hidden"
              src={profileImage.startsWith("http") ? profileImage : `data:image;base64,${profileImage}`}
            />
          </div>
        )}
        <div className="font-sansSemiBold flex-1">{name}</div>
      </div>
      <div className="text-sm">{address?.addressLabel1}</div>
      <div className="text-sm">{address?.addressLabel2}</div>
    </div>
  );
};
