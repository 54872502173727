import { FC, useState } from "react";
import { TemplateVariableGroupVO } from "@libs/api/generated-api";
import { TemplateVariablesEditor } from "components/UI/RichTextEditor/TemplateVariablesEditor";
import { handleError } from "utils/handleError";
import {
  templateStringToTemplateVariableNodes,
  templateVariablesToString,
} from "components/UI/RichTextEditor/templateVariables";

interface Props {
  smsTemplate: string | undefined;
  templateVariables: TemplateVariableGroupVO[];
  onTemplateChange: (smsTemplate: string) => void;
}

const config = {
  editable: true,
  namespace: "SMSEditor",
  onError: handleError,
};

export const SMSEditor: FC<Props> = ({ smsTemplate, templateVariables, onTemplateChange }) => {
  const [convertedMessage] = useState(() =>
    smsTemplate
      ? JSON.stringify(templateStringToTemplateVariableNodes(smsTemplate, templateVariables))
      : smsTemplate
  );

  return (
    <TemplateVariablesEditor
      initialConfig={config}
      ariaLabel="Message"
      fullHeight={false}
      templateVariables={templateVariables}
      initialEditorState={convertedMessage}
      placeholder="Type your message here..."
      onChange={({ content }) => onTemplateChange(templateVariablesToString(content))}
    />
  );
};
