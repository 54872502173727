import { useMemo } from "react";
import { PatientProcedureVO } from "@libs/api/generated-api";

export type RowTotal = Pick<PatientProcedureVO, "negotiatedRate" | "insuranceAmount" | "patientAmount"> & {
  lastId: number;
};

export const NO_PRIORITY = -1;

export const useRowTotals = (patientProcedures: PatientProcedureVO[]) => {
  // eslint-disable-next-line complexity
  return useMemo(() => {
    const subtotals: Record<number, RowTotal | undefined> = {};
    let hasSubtotals = false;
    let total = {
      negotiatedRate: 0,
      insuranceAmount: 0,
      patientAmount: 0,
    };

    for (const proc of patientProcedures) {
      const priority = proc.priority || NO_PRIORITY;

      hasSubtotals = hasSubtotals || priority !== NO_PRIORITY;

      const prioritySubtotal = subtotals[priority];

      subtotals[priority] = prioritySubtotal
        ? {
            lastId: proc.id,
            patientAmount: prioritySubtotal.patientAmount + proc.finalPatientAmount,
            insuranceAmount: prioritySubtotal.insuranceAmount + proc.finalInsuranceAmount,
            negotiatedRate: prioritySubtotal.negotiatedRate + proc.negotiatedRate,
          }
        : {
            lastId: proc.id,
            patientAmount: proc.finalPatientAmount,
            insuranceAmount: proc.finalInsuranceAmount,
            negotiatedRate: proc.negotiatedRate,
          };

      total = {
        negotiatedRate: total.negotiatedRate + proc.negotiatedRate,
        insuranceAmount: total.insuranceAmount + proc.finalInsuranceAmount,
        patientAmount: total.patientAmount + proc.finalPatientAmount,
      };
    }

    return {
      totalRow: hasSubtotals ? total : undefined,
      subtotalRows: hasSubtotals ? subtotals : undefined,
    };
  }, [patientProcedures]);
};
