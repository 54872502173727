import { FC } from "react";
import {
  AddressSelection,
  FormFieldAddressAutocomplete,
} from "@libs/components/UI/FormFieldAddressAutocomplete";
import { FormFieldSelect } from "@libs/components/UI/FormFieldSelect";
import { AddressVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { AddressInputValidation, isCompleteAddress, isEmptyAddress } from "@libs/utils/address";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { stateOptions } from "@libs/components/UI/stateOptions";

interface Props {
  address: AddressVO;
  apiKey: string;
  validation?: AddressInputValidation;
  onChange: (data: AddressVO) => void;
}

export const AddressInput: FC<Props> = ({ validation, address, onChange, apiKey }) => {
  /*
    We want to disable inputs that can be autofilled when selecting an autocomplete address
    For two reasons:
    1. If it is a complete address selection, we don't want the user to be able to edit it
       because the autocomplete data is likely more accurate.
    2. If it is an empty selection, we want to encourage the user to start
       typing into the autocomplete to get a selection.


    The reason it has to be state vs a derived value is that if a user were to fill out
    the address manually, they eventually would have a complete address and the autofillable
    fields would become disabled. We determined whether an address is complete or empty at
    two points: on initialization and on address autocomplete selection.
  */
  const fillableInputsDisabled = useBoolean(() => {
    return isCompleteAddress(address) || isEmptyAddress(address);
  });

  return (
    <>
      <div className="col-span-12">
        <div>
          <FormFieldAddressAutocomplete
            apiKey={apiKey}
            label="Address Location"
            defaultValue={address.address1}
            error={validation?.address1.$error}
            required
            onSelect={(selectedAddress: AddressSelection) => {
              const { address: address1, locality: city, state, zip, country } = selectedAddress;
              const selected = {
                address1,
                // whenever a new address is selected, clear the address2 field
                address2: "",
                city,
                state,
                zip,
                country,
              };

              // determine if the selected address should disable the autofillable fields
              fillableInputsDisabled.set(isCompleteAddress(selected) || isEmptyAddress(selected));

              onChange(selected);
            }}
          />
        </div>
        <p className="text-xs text-greyMedium mt-1.5">
          The State, City and Postal Code is auto-filled after selecting a location from the list.
        </p>
      </div>
      <div className="col-span-12">
        <FormFieldInput
          label="Address - Apartment, Unit, Suite, or Floor #"
          value={address.address2}
          onChange={(e) => {
            onChange({
              ...address,
              address2: e.target.value,
            });
          }}
          disabled={!address}
        />
      </div>
      <div className="col-span-12 grid grid-cols-3 gap-3 mb-6">
        <div className="col-span-1">
          <FormFieldInput
            label="City"
            value={address.city}
            error={fillableInputsDisabled.isOn ? "" : validation?.city.$error}
            required
            onChange={(e) => {
              onChange({
                ...address,
                city: e.target.value,
              });
            }}
            disabled={fillableInputsDisabled.isOn}
          />
        </div>
        <div className="col-span-1">
          <FormFieldSelect
            label="State"
            value={address.state}
            error={fillableInputsDisabled.isOn ? "" : validation?.state.$error}
            required
            onItemSelected={(state) => {
              onChange({
                ...address,
                state,
              });
            }}
            options={stateOptions}
            disabled={fillableInputsDisabled.isOn}
          />
        </div>
        <div className="col-span-1">
          <FormFieldInput
            label="Postal Code"
            value={address.zip}
            error={fillableInputsDisabled.isOn ? "" : validation?.zip.$error}
            required
            onChange={(e) => {
              onChange({
                ...address,
                zip: e.target.value,
              });
            }}
            disabled={fillableInputsDisabled.isOn}
          />
        </div>
      </div>
    </>
  );
};
