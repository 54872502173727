import React, { useState } from "react";
import { WorkingHourItemVO } from "@libs/api/generated-api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ViewWorkingHours } from "components/WorkingHours/ViewWorkingHours";

import { WorkingHoursForm } from "components/WorkingHours/WorkingHoursForm";

interface Props {
  providerWorkingHours: WorkingHourItemVO[];
  practiceWorkingHours: WorkingHourItemVO[];
  isSaving: boolean;
  providerId: number;
  isEditing: boolean;
  onSave: (workingHours: WorkingHourItemVO[]) => void;
  onRequestEdit: Func;
  onCloseEdit: Func;
}

export const WorkingHours: React.FC<Props> = ({
  onRequestEdit,
  providerWorkingHours,
  practiceWorkingHours,
  isEditing,
  onSave,
  onCloseEdit,
  isSaving,
}) => {
  const [editableHours, setEditableHours] = useState(providerWorkingHours);

  const handleAddDay = (dayOfWeek: WorkingHourItemVO["dayOfWeek"]) => {
    const practiceHoursOfDay = practiceWorkingHours.filter(
      (workingHourItem) => workingHourItem.dayOfWeek === dayOfWeek
    );
    const times = practiceHoursOfDay.length
      ? practiceHoursOfDay.map((item) => ({
          dayOfWeek,
          startTime: item.startTime,
          endTime: item.endTime,
        }))
      : [{ dayOfWeek, startTime: "00:00:00", endTime: "00:00:00" }];

    setEditableHours((last) => [...last, ...times]);
  };

  return (
    <div className="flex flex-col">
      <div className="flex items-center mb-2">
        <p className="text-sm font-sansSemiBold mr-4">Provider Working Hours</p>
        {!isEditing && <ButtonIcon SvgIcon={EditIcon} onClick={onRequestEdit} theme="primary" />}
      </div>

      {isEditing ? (
        <WorkingHoursForm
          workingHours={editableHours}
          onChange={setEditableHours}
          onSave={onSave}
          onCancel={onCloseEdit}
          isSaving={isSaving}
          onAddDay={handleAddDay}
        />
      ) : (
        <ViewWorkingHours data={providerWorkingHours} />
      )}
    </div>
  );
};
