import React from "react";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as UserMale } from "@libs/assets/icons/user-male.svg";
import { StoredAccountV1 } from "storage/accounts";
import { MainSignInProps, StepType } from "components/SignIn/MainSignIn";
import { Avatar } from "components/UI/Avatar";

interface Props {
  removeUserFromStorage: (temp: StoredAccountV1, showAccountState: boolean) => void;
  savedAccounts: StoredAccountV1[];
  updateSignInState: (updates: Partial<MainSignInProps>) => void;
}

export const AccountsList: React.FC<Props> = ({
  removeUserFromStorage,
  savedAccounts,
  updateSignInState,
}) => {
  return (
    <>
      <div className="mb-2 text-greyDark font-sansSemiBold text-xl">Choose an account</div>
      {savedAccounts.map((account) => {
        return (
          <div key={account.email} className="pointer">
            <div
              className={`
                flex
                flex-wrap
                justify-between
                w-full
                py-4
                border-b
                border-b-greyLight
              `}
            >
              <button
                type="button"
                className="flex items-center"
                onClick={() =>
                  updateSignInState({ email: account.email, rememberMe: true, step: StepType.SIGN_IN_FORM })
                }
              >
                <Avatar imageUrl={account.profilePic} name={account.fullName} size="lg" />
                <div className="ml-3 text-left">
                  <div className="font-sansSemiBold text-base">{account.fullName || "-"}</div>
                  <div className="text-greyMedium font-sans text-sm">{account.email}</div>
                </div>
              </button>
              <button
                type="button"
                aria-label="Remove"
                className="flex"
                onClick={() => removeUserFromStorage(account, true)}
              >
                <CancelIcon className="h-4 w-4 fill-primaryTheme" />
              </button>
            </div>
          </div>
        );
      })}
      <button
        type="button"
        className={`
          flex
          flex-wrap
          items-center
          w-full
          py-4
          border-b
          border-b-greyLight
          pointer
        `}
        onClick={() => updateSignInState({ email: "", step: StepType.SIGN_IN_FORM })}
      >
        <UserMale className="w-4 h-4 fill-greyMedium" />
        <div className="ml-2 font-sans text-sm">Sign in from another account</div>
      </button>
    </>
  );
};
