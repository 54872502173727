import { ClaimVO, ClaimUpdateRequest, ClaimLineItemVO, ClaimAttachmentVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import {
  ClaimFieldValueMap,
  ClaimField,
  optionValueByInsuranceOrdinal,
  insuranceOrdinalByOptionValue,
} from "components/Claims/types";

export const getSelectedClaimFields = (claim: ClaimVO) => {
  return {
    billingProviderId: claim.billingProviderId,
    insuranceOrdinal: optionValueByInsuranceOrdinal[claim.insuranceOrdinal],
    patientInsuranceId: claim.patientInsuranceId,
    treatingProviderId: claim.treatingProviderId,
  } as ClaimFieldValueMap;
};

export const getClaimFieldsUpdate = (
  initialSelectedClaimFields: ClaimFieldValueMap,
  selectedClaimFields: ClaimFieldValueMap
) => {
  const updates: ClaimUpdateRequest = {};

  const claimFieldKeys = Object.keys(initialSelectedClaimFields) as ClaimField[];

  claimFieldKeys.forEach((key) => {
    const selectedValue = selectedClaimFields[key];

    if (initialSelectedClaimFields[key] !== selectedValue) {
      if (key === "insuranceOrdinal") {
        updates["insuranceOrdinal"] =
          insuranceOrdinalByOptionValue[selectedClaimFields.insuranceOrdinal || 0];
      } else if (isDefined(selectedValue)) {
        updates[key] = selectedValue;
      }
    }
  });

  return updates;
};

export const isMissingRequirements = (lineItems: ClaimLineItemVO[], attachments: ClaimAttachmentVO[]) => {
  const requiredAttachmentTypes = new Set();

  lineItems.forEach((lineItem) =>
    lineItem.requiredAttachmentTypes.forEach((type) => {
      if (type !== "NONE") {
        requiredAttachmentTypes.add(type);
      }
    })
  );

  if (requiredAttachmentTypes.size === 0) {
    return false;
  }

  return Boolean(
    !attachments.length ||
      [...requiredAttachmentTypes].some(
        (requiredType) => !attachments.some((attachment) => attachment.type === requiredType)
      )
  );
};
