import { useMemo } from "react";

const APP_LABELS = {
  dashboard: "Reporting",
  employees: "Employees",
};

type AppType = keyof typeof APP_LABELS;
export const useLastPageQueryParamDetails = ({
  defaultLabel,
  backUrl,
}: {
  defaultLabel: string;
  backUrl: string;
}) => {
  return useMemo(() => {
    const [_, firstPath] = backUrl.split("/");

    return firstPath in APP_LABELS ? APP_LABELS[firstPath as AppType] : defaultLabel;
  }, [backUrl, defaultLabel]);
};
