import { SerializedEditorState, SerializedParagraphNode } from "lexical";
import { SerializedListItemNode, SerializedListNode } from "@lexical/list";
import { LineBreakNode, TextNode } from "components/UI/RichTextEditor/templateVariables";

type ParagraphNode = Omit<SerializedParagraphNode, "type"> & {
  type: "paragraph";
  children: Nodes[];
};
type ListNode = Omit<SerializedListNode, "type" | "children"> & { type: "list"; children: ListItemNode[] };
type ListItemNode = Omit<SerializedListItemNode, "type" | "children"> & {
  type: "listitem";
  children: Nodes[];
};
type Nodes = TextNode | LineBreakNode | ListNode | ParagraphNode | ListItemNode;

const stringifyChildren = (children: Nodes[]) => {
  let text = "";

  for (const node of children) {
    switch (node.type) {
      case "text": {
        text += node.text;
        break;
      }
      case "linebreak": {
        text += "\n";
        break;
      }
      case "paragraph": {
        text += stringifyChildren(node.children);
        break;
      }
      case "list": {
        text += node.children.map((li) => stringifyChildren(li.children)).join(", ");
        break;
      }
      case "listitem": {
        text += stringifyChildren(node.children);
        break;
      }
      default: {
        continue;
      }
    }
  }

  return text;
};

export const resolvedTemplateVariablesToString = (content: string) => {
  try {
    const state = JSON.parse(content) as SerializedEditorState<Nodes>;

    return stringifyChildren(state.root.children);
  } catch {
    return content;
  }
};
