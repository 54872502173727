import { useEffect, useState } from "react";
import { Switch } from "@libs/components/UI/Switch";
import { useLastFocus } from "hooks/useLastFocus";
import { usePerioChartActiveTooth } from "./PerioChartActiveToothContext";
import { usePerioChartControlContext } from "./PerioChartControlContext";

export const PerioChartGingMargPlus: React.FC = () => {
  const { tabIndex } = usePerioChartActiveTooth();
  const { controlFeatures, setGingMargPlus } = usePerioChartControlContext();
  const [active, setActive] = useState(Boolean(controlFeatures.gingMargPlusActive));

  useEffect(() => {
    return controlFeatures.subscribe(() => setActive(controlFeatures.gingMargPlusActive));
  }, [controlFeatures]);

  const lastFocusRef = useLastFocus();

  return (
    <Switch
      disabled={tabIndex === undefined}
      onClick={() => {
        setGingMargPlus(!active);
        lastFocusRef.current?.focus();
      }}
      checked={active}
      labelPosition="left"
      size="lg"
    >
      <span className="text-sm text-primaryTheme font-sansSemiBold">Ging Marg +</span>
    </Switch>
  );
};
