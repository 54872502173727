import { FC, useState, useMemo, useCallback } from "react";
import { FileRejection } from "react-dropzone";
import { produce } from "immer";

import { DraftEobPaymentVO } from "@libs/api/generated-api";
import { SupportedUploadMimeTypes } from "@libs/utils/mimeTypes";
import { formatBytes, listToText } from "@libs/utils/formatString";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { getTempId } from "@libs/utils/tempId";
import { useAccount } from "@libs/contexts/AccountContext";
import { Modal } from "@libs/components/UI/Modal";
import { AttachFilesModalContent } from "components/UI/AttachModalContent/AttachFilesModalContent";
import { AttachScanModalContent } from "components/UI/AttachModalContent/AttachScanModalContent";
import { UploadedModalContent } from "components/UI/AttachModalContent/UploadedModalContent";
import { useAttachModalContent } from "components/UI/AttachModalContent/utils";
import { AttachEobFileRow } from "components/Eob/AttachEobFileRow";

import { uploadEobFile } from "api/claim/mutations";

import { useNotificationContext } from "contexts/NotificationsContext";

import { handleError } from "utils/handleError";

const ALLOWED_MIME_TYPES: SupportedUploadMimeTypes[] = ["image/jpeg", "image/png", "application/pdf"];
const EOB_MIME_TYPES = new Set<string>(ALLOWED_MIME_TYPES);
const ACCEPTED_MIME_EXTENSIONS = [".jpeg", ".png", ".pdf"];
const FILE_SIZE_LIMIT = 10_000_000;

export interface StagedEobFile {
  stagedId: string;
  fileData: File;
}

interface Props {
  draftEobPayment: DraftEobPaymentVO;
  onRequestClose: Func;
}

export const AttachEobFilesModal: FC<Props> = ({ draftEobPayment, onRequestClose }) => {
  const {
    modalProps,
    modalContent,
    handleRequestUpload,
    handleRequestScanDocument,
    showUploadedModalContent,
  } = useAttachModalContent({ title: "Attach EOBs", onRequestClose });

  const [stagedFiles, setStagedFiles] = useState<StagedEobFile[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const { practiceId } = useAccount();
  const notifications = useNotificationContext();
  const [{ mutateAsync: uploadEobFileMutateAsync }] = useApiMutations([uploadEobFile]);

  const acceptedMimeTypesList = useMemo(() => listToText(ACCEPTED_MIME_EXTENSIONS), []);
  const canSelectMultiple = draftEobPayment.claimPaymentSummaries.length > 1;
  const canUploadFiles = stagedFiles.length > 0;

  const handleStageFiles = useCallback(
    (files: File[]) => {
      files.forEach((file) => {
        if (file.size < FILE_SIZE_LIMIT && EOB_MIME_TYPES.has(file.type)) {
          setStagedFiles((last) => [...last, { stagedId: getTempId(), fileData: file }]);
        } else if (!EOB_MIME_TYPES.has(file.type) && file.size >= FILE_SIZE_LIMIT) {
          notifications.handleError(
            `Current file size ${formatBytes(
              file.size
            )}, should be less than 10MB & File type should be of type ${acceptedMimeTypesList}`
          );
        } else if (file.size >= FILE_SIZE_LIMIT) {
          notifications.handleError(`Current file size ${formatBytes(file.size)}, should be less than 10MB`);
        } else if (!EOB_MIME_TYPES.has(file.type)) {
          notifications.handleError(`File type should be of type ${acceptedMimeTypesList}`);
        }
      });
    },
    [acceptedMimeTypesList, notifications]
  );

  const handleDropFiles = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      handleStageFiles(acceptedFiles);
      rejectedFiles.forEach(handleError);
    },
    [handleStageFiles]
  );

  const handleUpdateFile = (stagedId: string, updatedFile: Partial<StagedEobFile>) => {
    setStagedFiles((last) =>
      produce(last, (draft) => {
        const stagedFileIndex = draft.findIndex((file) => file.stagedId === stagedId);

        draft[stagedFileIndex] = { ...draft[stagedFileIndex], ...updatedFile };
      })
    );
  };

  const handleDeleteFile = (stagedId: string) => {
    setStagedFiles((last) =>
      produce(last, (draft) => {
        const stagedFileIndex = draft.findIndex((file) => file.stagedId === stagedId);

        draft.splice(stagedFileIndex, 1);
      })
    );
  };

  const handleUploadFiles = useCallback(async () => {
    setIsUploading(true);

    try {
      await Promise.all(
        stagedFiles.map(async ({ fileData }) => {
          return uploadEobFileMutateAsync({
            practiceId,
            draftEobPaymentUuid: draftEobPayment.uuid,
            data: { file: fileData },
          });
        })
      );
      showUploadedModalContent();
    } catch (error) {
      handleError(error);
    } finally {
      setIsUploading(false);
    }
  }, [stagedFiles, practiceId, draftEobPayment.uuid, uploadEobFileMutateAsync, showUploadedModalContent]);

  return (
    <Modal {...modalProps}>
      {modalContent === "upload" ? (
        <AttachFilesModalContent
          allowedMimeTypes={ALLOWED_MIME_TYPES}
          maxFileSizeByBytes={FILE_SIZE_LIMIT}
          canSelectMultiple={canSelectMultiple}
          canUploadFiles={canUploadFiles}
          isUploading={isUploading}
          onDropFiles={handleDropFiles}
          onUploadFiles={handleUploadFiles}
          onRequestScanDocument={handleRequestScanDocument}
          onRequestClose={onRequestClose}
        >
          {stagedFiles.length > 0 ? (
            <div className="w-full divide-y divide-dashed divide-greyLighter">
              {stagedFiles.map((file) => (
                <AttachEobFileRow
                  key={file.stagedId}
                  file={file}
                  onUpdateFile={handleUpdateFile}
                  onDeleteFile={handleDeleteFile}
                />
              ))}
            </div>
          ) : null}
        </AttachFilesModalContent>
      ) : modalContent === "scan" ? (
        <AttachScanModalContent
          onScanFiles={(files) => handleStageFiles(files)}
          onRequestClose={handleRequestUpload}
          scanAs="PDF"
        />
      ) : (
        <UploadedModalContent onRequestClose={onRequestClose} />
      )}
    </Modal>
  );
};
