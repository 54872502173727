import { TIME_PLACES } from "@libs/utils/date";

export type TimeSlotPreference = {
  hours: number;
  minutes: number;
  seconds: number;
};

export const NOON = 12;

export const endOfDay = {
  hours: 23,
  minutes: 59,
  seconds: 59,
};

export const beginningOfDay = {
  hours: 0,
  minutes: 0,
  seconds: 0,
};

export const midDay = {
  hours: NOON,
  minutes: 0,
  seconds: 0,
};

export const isBeforeMidDay = (time: TimeSlotPreference) => {
  return time.hours < NOON;
};
export const isAfterMidDay = (time: TimeSlotPreference) => {
  return time.hours > NOON || (time.hours === NOON && Boolean(time.minutes || time.seconds));
};

export const isBeginningOfDay = (time: TimeSlotPreference) => {
  return !time.hours && !time.minutes && !time.seconds;
};

export const timePreferenceToTimeString = (value: TimeSlotPreference) => {
  return [value.hours, value.minutes, value.seconds]
    .map((part) => `${part}`.padStart(TIME_PLACES, "0"))
    .join(":");
};

export const formatTimePreference = (value: TimeSlotPreference) => {
  const amPm = value.hours >= NOON ? "PM" : "AM";
  const minutes = `${value.minutes}`.padStart(TIME_PLACES, "0");
  const hours = `${
    amPm === "AM"
      ? value.hours === 0
        ? NOON
        : value.hours
      : value.hours === NOON
        ? NOON
        : value.hours % NOON
  }`;

  return `${hours}:${minutes} ${amPm}`;
};
