import { Outlet } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import { redirectLoader } from "@libs/router/redirectLoader";
import { NoInternetConnection } from "@libs/components/UI/NoInternetConnection";
import { SelectAccountRoute, loader as selectAccountLoader } from "components/Main/SelectAccountRoute";
import { SentryRouteErrorFallback } from "components/Main/SentryRouteErrorFallback";
import { SignOutRoute, loader as signOutLoader } from "components/Main/SignOutRoute";
import { PostSignOutRoute } from "components/Main/PostSignOutRoute";
import { SignedInApp, loader as signedInAppLoader } from "components/Main/SignedInApp";
import { MainSignIn } from "components/SignIn/MainSignIn";
import { authLoader } from "components/SignIn/authLoader";
import { ForgotPassword } from "components/SignIn/ForgotPassword";
import { SupportSignIn } from "components/SignIn/SupportSignIn";
import { OAuthSignIn } from "components/SignIn/OAuthSignIn";
import { paths } from "utils/routing/paths";
import { MfaSetupRoute, loader as mfaSetupLoader } from "components/MfaSetup/MfaSetupRoute";
import { PracticeRouterContext } from "router/types";

export const getRoutes = (context: PracticeRouterContext) => {
  return [
    {
      path: "*",
      element: (
        <>
          <Outlet />
          <ToastContainer
            className="z-20"
            autoClose={5000}
            hideProgressBar={true}
            newestOnTop={true}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
          />
          <NoInternetConnection />
        </>
      ),
      errorElement: <SentryRouteErrorFallback />,
      children: [
        {
          path: "sign-out",
          element: <SignOutRoute />,
          loader: signOutLoader(context),
        },
        {
          path: "post-sign-out",
          element: <PostSignOutRoute />,
        },
        {
          path: "select-account",
          element: <SelectAccountRoute />,
          loader: selectAccountLoader(context),
        },
        {
          path: "mfa-setup",
          element: <MfaSetupRoute />,
          loader: mfaSetupLoader(context),
        },
        {
          path: "auth/sign-in",
          element: <MainSignIn />,
          loader: authLoader(context, "signIn"),
        },
        {
          // Deprecated. User auth/sign-in?resetPassword=1 instead
          path: "auth/first-sign-in",
          loader: redirectLoader(paths.signIn({ resetPassword: true })),
        },
        {
          path: "auth/forgot-password",
          element: <ForgotPassword />,
          loader: authLoader(context, "forgotPassword"),
        },
        {
          path: "auth/support",
          element: <SupportSignIn />,
          loader: authLoader(context, "supportSignIn"),
        },
        {
          path: "oauth/sign-in",
          element: <OAuthSignIn />,
        },
        {
          path: "sign-in",
          loader: redirectLoader(paths.signIn()),
        },
        {
          path: "first-sign-in",
          loader: redirectLoader(paths.firstSignIn()),
        },
        {
          path: "forgot-password",
          loader: redirectLoader(paths.forgotPassword()),
        },
        {
          path: "*",
          element: <SignedInApp />,
          loader: signedInAppLoader(context),
        },
      ],
    },
  ];
};
