import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { ClaimVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { getFullUrl } from "@libs/utils/location";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as PlusCircleIcon } from "@libs/assets/icons/plus-circle.svg";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { isCompletedClaim, isInDraftEobPaymentClaim, isPreAuthClaim } from "components/Claims/utils";
import { ClaimSection } from "components/Claim/ClaimSection";

import { paths } from "utils/routing/paths";
import { ProcedureTable } from "components/Claim/ProcedureTable";
import { getProcedureTableHeaders } from "components/Claim/procedureTableUtils";

interface Props {
  claim: ClaimVO;
  onSelectCheckbox: (id: number) => void;
  selectedLineItems?: Set<number>;
}

export const useProcedureTableSection = ({ claim, ...rest }: Props) => {
  const isPreAuth = isPreAuthClaim(claim);
  const isInDraftEobPayment = isInDraftEobPaymentClaim(claim);
  const isCompleted = isCompletedClaim(claim);

  const canAddAdjustment =
    isCompleted &&
    !isPreAuth &&
    claim.insurancePaymentStatus !== "NOT_SUBMITTED" &&
    !claim.replacementClaimUuid &&
    !claim.draftEobPaymentUuid;

  const lastLineItemsGroupIndex = claim.lineItemGroups.length - 1;

  const canViewHistory = lastLineItemsGroupIndex > 0 && isCompleted;

  const navigate = useNavigate();
  const from = getFullUrl(location);

  const handleClickAddAdjustment = useCallback(() => {
    navigate(paths.claimAdjustment({ claimUuid: claim.uuid }, { from }));
  }, [claim.uuid, from, navigate]);

  const handleClickViewHistory = useCallback(() => {
    navigate(paths.claimHistory({ claimUuid: claim.uuid }, { from }));
  }, [claim.uuid, from, navigate]);

  return {
    canAddAdjustment,
    canViewHistory,
    claim,
    headers: getProcedureTableHeaders({ claim, isAddingAdjustment: false }),
    isCompleted,
    isInDraftEobPayment,
    lastLineItemsGroupIndex,
    onClickAddAdjustment: handleClickAddAdjustment,
    onClickViewHistory: handleClickViewHistory,
    ...rest,
  };
};

export const ProcedureTableSection: React.FC<Props> = (props) => {
  const {
    canAddAdjustment,
    canViewHistory,
    claim,
    headers,
    isCompleted,
    isInDraftEobPayment,
    lastLineItemsGroupIndex,
    onClickAddAdjustment,
    onClickViewHistory,
    onSelectCheckbox,
    selectedLineItems,
  } = useProcedureTableSection(props);

  return (
    <ClaimSection
      subTitle={`(Appointment Date ${formatISODate(claim.serviceDate)})`}
      title="Procedures"
      titleChildren={
        isCompleted && (canAddAdjustment || canViewHistory) ? (
          <div className="flex items-center gap-x-3">
            <FloatingTooltip
              content={
                isInDraftEobPayment
                  ? "Currently processing payment in EOB"
                  : canAddAdjustment
                    ? ""
                    : "Cannot add adjustment for this claim"
              }
            >
              <Button
                className="flex items-center gap-x-1.5"
                disabled={!canAddAdjustment}
                onClick={onClickAddAdjustment}
                type="button"
                theme="link"
              >
                <PlusCircleIcon className="h-4 w-4" />
                Adjustment
              </Button>
            </FloatingTooltip>
            <VerticalDivider size="sm" />
            <FloatingTooltip content={canViewHistory ? "" : "No version history"} theme="SMALL">
              <div>
                <Button disabled={!canViewHistory} onClick={onClickViewHistory} type="button" theme="link">
                  View History
                </Button>
              </div>
            </FloatingTooltip>
          </div>
        ) : undefined
      }
      titleClass="justify-between"
    >
      <ProcedureTable
        claim={claim}
        headers={headers}
        isDetailView={true}
        lineItems={claim.lineItemGroups[lastLineItemsGroupIndex].lineItems}
        onSelectCheckbox={onSelectCheckbox}
        selectedLineItems={selectedLineItems}
        showTotals={!isInDraftEobPayment}
        version={claim.lineItemGroups[lastLineItemsGroupIndex].version}
      />
    </ClaimSection>
  );
};
