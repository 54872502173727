import React, { useCallback, useMemo } from "react";
import { MbscCellClickEvent } from "@mobiscroll/react";
import { differenceInMinutes, parseISO } from "date-fns";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate, useLocation } from "react-router-dom";
import { captureMessage } from "@sentry/react";
import {
  AppointmentRequest,
  AppointmentVO,
  ScheduleBlockVO,
  UpdateScheduleBlockRequest,
} from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { isDefined } from "@libs/utils/types";
import { formatAsISODate, formatAsISOTime, setToDay } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getQueryKey, getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { updateCachedData } from "@libs/utils/queryCache";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as SettingsIcon } from "@libs/assets/icons/settings.svg";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { useAccount } from "@libs/contexts/AccountContext";
import { lazyDefault } from "@libs/utils/lazyDefault";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import {
  deleteScheduleBlock,
  updateAppointment,
  updateRecurringScheduleBlockFuture,
  updateRecurringScheduleBlockInstance,
  updateScheduleBlock,
} from "api/scheduling/mutations";
import {
  getSchedulingConfigQuery,
  getAppointmentCategoriesQuery,
  getPracticeAppoinmentCardsQuery,
  getPracticeCustomHolidaysQuery,
  getPracticePublicHolidaysQuery,
  getPracticeRoomsQuery,
  getProviderOpenHours,
  getWorkingHoursQuery,
  getScheduleBlocks,
  getAppointmentRequestsInfiniteQuery,
  getPracticeAppoinmentHoldsQuery,
  getRoomOpenHoursQuery,
} from "api/scheduling/queries";
import {
  ClickScheduleEvent,
  CreateScheduleEvent,
  UpdatedScheduleEvent,
} from "components/ScheduleAppointments/types";
import { useScheduleDatePickerYearsInView } from "components/ScheduleAppointments/useScheduleDatePickerYearsInView";
import { handleError } from "utils/handleError";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { Schedule } from "components/ScheduleAppointments/Schedule";
import { paths } from "utils/routing/paths";
import { useDeleteAppointment } from "contexts/DeleteAppointmentContext";
import { useItemModal } from "hooks/useItemModal";
import { ScheduleQueriesResult } from "components/ScheduleAppointments/ScheduleQueriesResult";
import { ScheduleTabs } from "components/ScheduleAppointments/ScheduleTabs";
import { ScheduleAsapListButton } from "components/ScheduleAppointments/ScheduleAsapListButton";
import { ScheduleDatePicker } from "components/ScheduleAppointments/ScheduleDatePicker";
import { SelectAppointmentGrouping } from "components/ScheduleAppointments/SelectAppointmentGrouping";
import { ScheduleHeader } from "components/ScheduleAppointments/ScheduleHeader";
import { BlockConfirmDelete } from "components/ScheduleAppointments/BlockConfirmDelete";
import { AppointmentHoldsDrawer } from "components/ScheduleAppointments/AppointmentHoldsDrawer";
import { PatientSnapshotLayout } from "components/UI/PatientSnapshotLayout";
import { usePatientAppointmentQueryState } from "contexts/PatientAppointmentContext";
import { ScheduleAppHistoryProvider } from "components/ScheduleAppointments/ScheduleLinksContext";
import { ScheduleHoldsButton } from "components/ScheduleAppointments/ScheduleHoldsButton";

import { ScheduleIconPillTabs } from "components/ScheduleAppointments/ScheduleIconPillTabs";
import { getAppointmentRequest, getBlockRequest } from "components/ScheduleAppointments/utils";
import { DeleteRecurringBlockModal } from "components/ScheduleAppointments/DeleteRecurringBlockModal";
import { ChangeMode, RecurringBlockModal } from "components/ScheduleAppointments/RecurringBlockModal";
import { useSchedule } from "components/ScheduleAppointments/useSchedule";
import { FlyoverV2 } from "components/UI/FlyoverV2";
import { ScheduleSettingsFlyoverProps } from "components/ScheduleAppointments/ScheduleSettingsFlyover";
import { useScheduleRoute } from "./useScheduleRoute";

interface AddModalArgs {
  roomId?: number;
  hygienistId?: number;
  dentistId?: number;
}

const useAsapListCount = (practiceId: number) => {
  const requestAppointmentsQuery = useInfiniteApiQuery(
    getAppointmentRequestsInfiniteQuery({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: 1,
      },
    })
  );

  const totals = React.useMemo(
    () => getInfiniteQueryPagingDetails(requestAppointmentsQuery.data)?.totalElements,
    [requestAppointmentsQuery.data]
  );

  return totals;
};

const ScheduleSettingsFlyover = lazyDefault(
  () => import("components/ScheduleAppointments/ScheduleSettingsFlyover"),
  "ScheduleSettingsFlyover"
);

// eslint-disable-next-line max-statements
export const ScheduleRoute: React.FC = () => {
  const queryClient = useQueryClient();
  const navigate = useNavigate();
  const location = useLocation();
  const { practiceId } = useAccount();

  const {
    groupAppointmentsBy,
    appointmentId,
    date,
    hipaaView,
    hideCanceled,
    showHolds,
    animateHoldsOpen,
    navigateToAppointment,
    navigateToHold,
    switchAppointment,
    selectGrouping,
    navigateToDate,
    handleFiltersChange,
    toggleHolds,
    hiddenRoomIds,
    hiddenProviderIds,
    orderRoomsBy,
  } = useScheduleRoute();

  const isGroupedByRoom = groupAppointmentsBy === "room";
  const isGroupedByProvider = groupAppointmentsBy === "provider";

  const blockConfirmDelete = useItemModal<ScheduleBlockVO>(null);
  const editRepeatBlockModal = useItemModal<{ updates: UpdateScheduleBlockRequest; blockId: number }>(null);
  const deleteRepeatBlockModal = useItemModal<ScheduleBlockVO>(null);

  usePageTitle("Schedule");

  const asapListCount = useAsapListCount(practiceId);

  // mobiscroll has a bug where if you change the selectedDate
  // prop it scrolls the user up to the top of the schedule.
  // We don't want that. Instead we will always render the same date.
  // The api data will reflect whatever date the user chooses
  // and we will normalize api input into the schedule and will normalize
  // the schedulue output events to make sure the experience still works.
  const renderDate = useMemo(() => new Date(), []);

  const selectedDate = useMemo(() => {
    return parseISO(date);
  }, [date]);

  const scheduleDatePickerYearsInView = useScheduleDatePickerYearsInView();

  const [
    schedulingConfigQuery,
    appointmentsQuery,
    scheduleBlocksQuery,
    roomsQuery,
    roomsOpenHoursQuery,
    providersQuery,
    { data: workingHours },
    { data: customHolidays },
    { data: publicHolidaysForDatePickerYear1 },
    { data: publicHolidaysForDatePickerYear2 },
    providerOpenHoursQuery,
    { data: categories },
    appointmentHoldsQuery,
  ] = useApiQueries([
    getSchedulingConfigQuery({ args: { practiceId } }),
    getPracticeAppoinmentCardsQuery({
      args: {
        date,
        practiceId,
        excludeStates: hideCanceled ? ["NO_SHOW", "CANCELED"] : undefined,
      },
    }),
    getScheduleBlocks({ args: { filterDate: date, practiceId } }),
    getPracticeRoomsQuery({
      args: { practiceId },
    }),
    getRoomOpenHoursQuery({
      args: { practiceId, date },
      queryOptions: { enabled: isGroupedByRoom },
    }),
    getPracticeProvidersQuery({
      args: { practiceId },
    }),
    getWorkingHoursQuery({ args: { practiceId } }),
    getPracticeCustomHolidaysQuery({ args: { practiceId } }),
    // The datepicker needs possibly any two years at a time
    // Because the datepicker shows six months at a time
    // it can show up to two different years at a time
    getPracticePublicHolidaysQuery({
      args: { practiceId, year: scheduleDatePickerYearsInView.yearRange[0] },
      queryOptions: { enabled: Boolean(scheduleDatePickerYearsInView.yearRange[0]) },
    }),
    getPracticePublicHolidaysQuery({
      args: { practiceId, year: scheduleDatePickerYearsInView.yearRange[1] },
      queryOptions: { enabled: Boolean(scheduleDatePickerYearsInView.yearRange[1]) },
    }),
    getProviderOpenHours({
      args: { practiceId, date },
      queryOptions: { enabled: isGroupedByProvider },
    }),
    getAppointmentCategoriesQuery({
      args: { practiceId },
    }),
    getPracticeAppoinmentHoldsQuery({ args: { practiceId } }),
  ]);

  const publicHolidaysForDatePicker = useMemo(() => {
    const year1 = publicHolidaysForDatePickerYear1 || [];
    const year2 = publicHolidaysForDatePickerYear2 || [];

    return [...year1, ...year2];
  }, [publicHolidaysForDatePickerYear1, publicHolidaysForDatePickerYear2]);

  const [
    updateAppointmentMutation,
    updateScheduleBlockMutation,
    deleteScheduleBlockMutation,
    updateRecurringScheduleBlockFutureMutation,
    updateRecurringScheduleBlockInstanceMutation,
  ] = useApiMutations([
    updateAppointment,
    updateScheduleBlock,
    deleteScheduleBlock,
    updateRecurringScheduleBlockFuture,
    updateRecurringScheduleBlockInstance,
  ]);

  const appointments = appointmentsQuery.data;
  const blocks = scheduleBlocksQuery.data;
  const selectedAppointmentId = appointmentId;

  const { requestDeleteAppointment } = useDeleteAppointment();

  const patientAppointment = usePatientAppointmentQueryState();
  const handleAppointmentDeleted = patientAppointment.handleAppointmentDeleted;

  const handleDeleteAppointment = useCallback(
    (appointment: AppointmentVO) => {
      requestDeleteAppointment(appointment, {
        onSuccess: () =>
          handleAppointmentDeleted({ patientId: appointment.patient.id, appointmentId: appointment.id }),
      });
    },
    [requestDeleteAppointment, handleAppointmentDeleted]
  );

  const handleDeleteBlock = (block: ScheduleBlockVO) => {
    deleteScheduleBlockMutation.mutate(
      {
        practiceId,
        blockId: block.id,
      },
      {
        onSuccess: blockConfirmDelete.close,
        onError: handleError,
      }
    );
  };

  const isDentist = useCallback(
    (providerId?: number) => {
      if (providerId === undefined) {
        return false;
      }

      const provider = providersQuery.data?.find((pr) => pr.id === providerId);

      return provider?.jobCategory === "DENTIST";
    },
    [providersQuery.data]
  );

  const mutateAppointment = updateAppointmentMutation.mutate;

  const handleUpdateAppointmentState = useCallback(
    (appointment: AppointmentVO, newState: AppointmentVO["state"]) => {
      mutateAppointment(
        {
          appointmentId: appointment.id,
          practiceId,
          original: {
            state: appointment.state,
            asap: appointment.asap,
            date: appointment.date,
          },
          data: {
            ...getAppointmentRequest(appointment),
            state: newState,
          },
        },
        {
          onError: (err) => handleError(err),
        }
      );
    },
    [mutateAppointment, practiceId]
  );

  const handleEditAppointment = useCallback(
    (editAppointmentId: number, newPatientId: number) => {
      const url = paths.editAppointment(
        { appointmentId: editAppointmentId, patientId: newPatientId },
        { from: getFullUrl(location) }
      );

      navigate(url);
    },
    [navigate, location]
  );

  const handleEditBlock = useCallback(
    (blockId: number, instanceDate: string) => {
      const url = paths.editBlock({ blockId, instanceDate }, { from: getFullUrl(location) });

      navigate(url);
    },
    [navigate, location]
  );

  const handleCellDoubleClick = useCallback(
    (event: MbscCellClickEvent) => {
      const args: AddModalArgs = {};

      if (isGroupedByProvider) {
        const providerId = Number(event.resource);

        if (isDentist(providerId)) {
          args.dentistId = providerId;
        } else {
          args.hygienistId = providerId;
        }
      } else {
        args.roomId = Number(event.resource);
      }

      navigate(
        paths.scheduleAppointment(
          {
            date: formatAsISODate(setToDay(event.date, selectedDate)),
            startTime: formatAsISOTime(event.date),
          },
          { ...args, from: getFullUrl(location), patientId: patientAppointment.patientId }
        )
      );
    },
    [isGroupedByProvider, isDentist, selectedDate, navigate, location, patientAppointment.patientId]
  );

  const blockUpdateMutationOptions = useMemo(
    () => ({
      onError: (err: unknown) => {
        // Because these are drag and drop events via mobiscroll
        // they are essentially optimistic updates. If they don't persist
        // we need to rollback by re-fetching appointments.
        queryClient.invalidateQueries([getQueryKey("practices", "getScheduleBlocks"), { practiceId }]);
        handleError(err);
      },
    }),
    [queryClient, practiceId]
  );

  const mutateBlock = updateScheduleBlockMutation.mutate;
  const handleUpdateAll = useCallback(
    (blockId: number, data: UpdateScheduleBlockRequest) => {
      mutateBlock(
        {
          practiceId,
          blockId,
          data,
        },
        blockUpdateMutationOptions
      );
    },
    [blockUpdateMutationOptions, practiceId, mutateBlock]
  );

  const handleUpdateInstance = (blockId: number, data: UpdateScheduleBlockRequest) => {
    updateRecurringScheduleBlockInstanceMutation.mutate(
      {
        practiceId,
        blockId,
        instanceDate: data.date,
        data,
      },
      blockUpdateMutationOptions
    );
  };

  const handleUpdateFutureItems = (blockId: number, data: UpdateScheduleBlockRequest) => {
    updateRecurringScheduleBlockFutureMutation.mutate(
      {
        practiceId,
        blockId,
        instanceDate: data.date,
        data,
      },
      blockUpdateMutationOptions
    );
  };

  const handleChangeMode = (changeMode: ChangeMode, blockId: number, updates: UpdateScheduleBlockRequest) => {
    if (changeMode === "CHANGE_ALL") {
      handleUpdateAll(blockId, updates);
    } else if (changeMode === "CHANGE_ONE") {
      handleUpdateInstance(blockId, { ...updates, recurrence: undefined });
    } else {
      handleUpdateFutureItems(blockId, updates);
    }
  };

  const openEditRepeat = editRepeatBlockModal.open;
  const handleEventUpdated = useCallback(
    // eslint-disable-next-line max-statements
    (event: UpdatedScheduleEvent) => {
      const scheduleEvent = event.event;

      if (scheduleEvent.cardType === "appointment") {
        const { appointment, resource, end, start } = scheduleEvent;
        let providerId = appointment.provider.id;
        let dentistId = appointment.dentist.id;
        let roomId = appointment.room.id;

        if (isGroupedByProvider) {
          if (isDentist(resource)) {
            providerId = resource;
            dentistId = resource;
          } else {
            providerId = resource;
          }
        } else {
          roomId = resource;
        }

        const args: AppointmentRequest = {
          ...getAppointmentRequest(appointment),
          duration: differenceInMinutes(end, start),
          startTime: formatAsISOTime(start),
          providerId,
          dentistId,
          roomId,
        };

        const original = {
          state: appointment.state,
          asap: appointment.asap,
          date: appointment.date,
        };

        mutateAppointment(
          {
            appointmentId: appointment.id,
            practiceId,
            original,
            data: args,
          },
          {
            onError: (err) => {
              // Because these are drag and drop events via mobiscroll
              // they are essentially optimistic updates. If they don't persist
              // we need to rollback by re-fetching appointments.
              queryClient.invalidateQueries([
                getQueryKey("practices", "getAppointmentCards"),
                { practiceId, date },
              ]);
              handleError(err);
            },
          }
        );
        // adding check here to debug errors happening in prod
        // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      } else if (scheduleEvent.cardType === "block") {
        const { block, resource, end, start } = scheduleEvent;
        const lastResource = event.oldEvent.resource;

        let providerIds = block.providers.map(({ id }) => id);
        let roomIds = block.rooms.map(({ id }) => id).filter(isDefined);

        if (isGroupedByProvider) {
          providerIds = providerIds.filter((id) => id !== lastResource);
          providerIds.push(resource);
          providerIds = [...new Set(providerIds)];
        } else {
          roomIds = roomIds.filter((id) => id !== lastResource);
          roomIds.push(resource);
          roomIds = [...new Set(roomIds)];
        }

        const updates = {
          ...getBlockRequest(block),
          startTime: formatAsISOTime(start),
          endTime: formatAsISOTime(end),
          roomIds,
          providerIds,
        };

        if (block.recurrence) {
          openEditRepeat({ blockId: block.id, updates });
        } else {
          handleUpdateAll(block.id, updates);
        }
      } else {
        // eslint-disable-next-line @typescript-eslint/restrict-template-expressions, @typescript-eslint/no-unsafe-member-access, @typescript-eslint/no-explicit-any
        captureMessage(`An unknown schedule cardType was found ${(scheduleEvent as any).cardType}`, "error");
      }
    },
    [
      isGroupedByProvider,
      mutateAppointment,
      isDentist,
      queryClient,
      practiceId,
      date,
      handleUpdateAll,
      openEditRepeat,
    ]
  );

  const handleEventCreate = useCallback(
    // eslint-disable-next-line max-statements
    (event: CreateScheduleEvent) => {
      const scheduleEvent = event.event;

      if (event.action === "externalDrop" && scheduleEvent.cardType === "hold") {
        const { resource, appointment, start, end } = scheduleEvent;
        const newDate = formatAsISODate(setToDay(start, selectedDate));
        const newStartTime = formatAsISOTime(start);

        const args: { dentistId?: number; providerId?: number; roomId?: number } = {};

        if (isGroupedByProvider) {
          if (isDentist(resource)) {
            args.dentistId = resource;
            args.providerId = resource;
          } else {
            args.providerId = resource;
          }
        } else {
          args.roomId = resource;
        }

        const request: AppointmentRequest = {
          ...getAppointmentRequest(appointment),
          ...args,
          date: newDate,
          startTime: newStartTime,
          state: "UNCONFIRMED",
        };

        const original = {
          state: appointment.state,
          asap: appointment.asap,
          date: appointment.date,
        };

        updateCachedData<AppointmentVO[]>(
          queryClient,
          {
            queryKey: [getQueryKey("practices", "getAppointmentHolds"), { practiceId }],
            exact: true,
          },
          (data) => {
            return data.filter((hold) => hold.id !== appointment.id);
          }
        );

        updateCachedData<AppointmentVO[]>(
          queryClient,
          {
            queryKey: [getQueryKey("practices", "getAppointmentCards"), { practiceId, date: request.date }],
          },
          (data) => {
            const room = args.roomId ? roomsQuery.data?.find((r) => r.id === args.roomId) : appointment.room;

            const dentist = args.dentistId
              ? providersQuery.data?.find((r) => r.id === args.dentistId)
              : undefined;
            const provider = args.providerId
              ? providersQuery.data?.find((r) => r.id === args.providerId)
              : undefined;

            return [
              ...data,
              {
                ...appointment,
                state: "UNCONFIRMED",
                date: request.date,
                startTime: request.startTime,
                endTime: formatAsISOTime(end),
                room: room ?? appointment.room,
                dentist: dentist ?? appointment.dentist,
                provider: provider ?? appointment.provider,
              },
            ];
          }
        );

        switchAppointment({ patientId: appointment.patient.id, appointmentId: appointment.id });

        mutateAppointment(
          {
            appointmentId: appointment.id,
            practiceId,
            original,
            data: request,
          },
          {
            onError: (err) => {
              // Because these are drag and drop events via mobiscroll
              // they are essentially optimistic updates. If they don't persist
              // we need to rollback by re-fetching appointments.
              queryClient.invalidateQueries([
                getQueryKey("practices", "getAppointmentCards"),
                { practiceId, date: request.date },
              ]);
              queryClient.invalidateQueries([
                getQueryKey("practices", "getAppointmentHolds"),
                { practiceId },
              ]);
              handleError(err);
            },
          }
        );

        // prevents the event from being added to the schedule until it's been stored in our DB
        return false;
      }

      if (event.action === "drag") {
        const { resource, start, end } = scheduleEvent;
        const pathParams = {
          date: formatAsISODate(setToDay(start, selectedDate)),
          startTime: formatAsISOTime(start),
        };

        const queryParams: AddModalArgs = {};

        if (isGroupedByProvider) {
          if (isDentist(resource)) {
            queryParams.dentistId = resource;
          } else {
            queryParams.hygienistId = resource;
          }
        } else {
          queryParams.roomId = resource;
        }

        navigate(
          paths.scheduleAppointment(pathParams, {
            ...queryParams,
            duration: differenceInMinutes(end, start),
            from: getFullUrl(location),
            patientId: patientAppointment.patientId,
          })
        );

        // prevents the event from being added to the scehdule until it's been stored in our DB
        return false;
      }

      return true;
    },
    [
      isGroupedByProvider,
      navigate,
      selectedDate,
      isDentist,
      location,
      mutateAppointment,
      practiceId,
      providersQuery.data,
      roomsQuery.data,
      queryClient,
      switchAppointment,
      patientAppointment.patientId,
    ]
  );

  const handleEventClick = useCallback(
    (event: ClickScheduleEvent) => {
      const scheduleEvent = event.event;

      if (scheduleEvent.cardType === "appointment") {
        switchAppointment({
          appointmentId: scheduleEvent.appointment.id,
          patientId: scheduleEvent.appointment.patient.id,
        });
      }
    },
    [switchAppointment]
  );

  const handleHolderClick = useCallback(
    (e: AppointmentVO) => {
      switchAppointment({ patientId: e.patient.id, appointmentId: e.id });
    },
    [switchAppointment]
  );

  const handleHolderDoubleClick = useCallback(
    (e: AppointmentVO) => {
      navigate(
        paths.editAppointment(
          { appointmentId: e.id, patientId: e.patient.id },
          { from: getFullUrl(location) }
        )
      );
    },
    [navigate, location]
  );

  const handleAppointmentSelected = useCallback(
    (appointment: AppointmentVO) => {
      if (appointment.state === "UNSCHEDULED") {
        navigateToHold({ appointmentId: appointment.id, patientId: appointment.patient.id });
      } else {
        navigateToAppointment({
          appointmentId: appointment.id,
          patientId: appointment.patient.id,
          date: appointment.date,
        });
      }
    },
    [navigateToAppointment, navigateToHold]
  );

  const settingsFlyover = useBoolean(false);
  const closeSettingsFlyover = settingsFlyover.off;

  const schedule = useSchedule({
    renderDate,
    groupBy: groupAppointmentsBy,
    providers: providersQuery.data,
    rooms: roomsQuery.data,
    providerOpenHours: providerOpenHoursQuery.data,
    roomOpenHours: roomsOpenHoursQuery.data,
    appointments,
    blocks,
    selectedDate: date,
    hiddenProviderIds,
    hiddenRoomIds,
    orderRoomsBy,
  });

  const filterText = useMemo(() => {
    return groupAppointmentsBy === "room"
      ? `${schedule.selectedRooms.length} of ${schedule.allRooms.length} showing`
      : `${schedule.selectedProviders.length} of ${schedule.allProviders.length} showing`;
  }, [
    groupAppointmentsBy,
    schedule.allProviders.length,
    schedule.allRooms.length,
    schedule.selectedProviders.length,
    schedule.selectedRooms.length,
  ]);

  const applyFilters: ScheduleSettingsFlyoverProps["onSave"] = useCallback(
    (updates) => {
      handleFiltersChange({
        ...updates,
        hiddenProviderIds: [...updates.hiddenProviderIds],
        hiddenRoomIds: [...updates.hiddenRoomIds],
      });
      closeSettingsFlyover();
    },
    [handleFiltersChange, closeSettingsFlyover]
  );

  return (
    <ScheduleAppHistoryProvider name="schedule">
      <PatientSnapshotLayout
        emptyText="No Appointment Selected"
        appointmentId={patientAppointment.appointmentId}
        patientId={patientAppointment.patientId}
        onDeleteAppointment={patientAppointment.handleAppointmentDeleted}
        onSelectAppointment={handleAppointmentSelected}
        onViewAppointment={handleAppointmentSelected}
        hipaaView={hipaaView}
        afterAside={
          showHolds ? (
            <AppointmentHoldsDrawer
              appointmentId={appointmentId}
              hipaaView={hipaaView}
              animateOpen={animateHoldsOpen}
              onHoldClick={handleHolderClick}
              onHoldDoubleClick={handleHolderDoubleClick}
              onHoldDelete={handleDeleteAppointment}
              appointmentHoldsQuery={appointmentHoldsQuery}
              toggleHolds={toggleHolds}
            />
          ) : null
        }
      >
        <div className="flex flex-col h-full w-full">
          <ScheduleHeader>
            <ScheduleTabs className="flex-1" />
            <div className="flex-1 flex justify-center w-full">
              <ScheduleDatePicker
                selected={selectedDate}
                onChange={navigateToDate}
                customHolidays={customHolidays}
                publicHolidays={publicHolidaysForDatePicker}
                workHours={workingHours}
                onUpdateMonthsDisplay={scheduleDatePickerYearsInView.handleMonthsDisplayChange}
              />
            </div>
            <div className="flex-1 flex items-center justify-end w-full gap-x-6">
              <div className="mr-9">
                <ScheduleIconPillTabs>
                  <ScheduleAsapListButton count={asapListCount} />
                  <VerticalDivider size="sm" />
                  <ScheduleHoldsButton
                    count={appointmentHoldsQuery.data?.length}
                    onToggleShowHolds={toggleHolds}
                  />
                </ScheduleIconPillTabs>
              </div>
              <div className="flex items-center gap-x-1.5">
                <div className="flex-1">
                  <SelectAppointmentGrouping
                    className="outline outline-1 outline-greyLighter"
                    groupBy={groupAppointmentsBy}
                    onSelectGrouping={selectGrouping}
                  />
                </div>
                <span className="text-xs">{filterText}</span>
              </div>
              <ButtonIcon aria-label="settings-icon" onClick={settingsFlyover.on} SvgIcon={SettingsIcon} />
            </div>
          </ScheduleHeader>
          <div className="flex-1 oveflow-scroll width-full min-w-0 min-h-0 relative">
            <ScheduleQueriesResult
              isGroupedByRoom={isGroupedByRoom}
              scheduleBlocksQuery={scheduleBlocksQuery}
              schedulingConfigQuery={schedulingConfigQuery}
              appointmentsQuery={appointmentsQuery}
              roomsQuery={roomsQuery}
              providersQuery={providersQuery}
              providerOpenHoursQuery={providerOpenHoursQuery}
              roomsOpenHoursQuery={roomsOpenHoursQuery}
            >
              {schedulingConfigQuery.data && (
                <Schedule
                  hipaaView={hipaaView}
                  config={schedulingConfigQuery.data}
                  categories={categories}
                  queryDate={date}
                  selectedDate={selectedDate}
                  renderDate={renderDate}
                  groupAppointmentsBy={groupAppointmentsBy}
                  selectedAppointmentId={selectedAppointmentId ?? 0}
                  schedule={schedule}
                  onRequestEditAppointment={(appointment: AppointmentVO) => {
                    handleEditAppointment(appointment.id, appointment.patient.id);
                  }}
                  onAppointmentDeleted={handleAppointmentDeleted}
                  onRequestDeleteBlock={(block: ScheduleBlockVO) => {
                    if (block.recurrence) {
                      deleteRepeatBlockModal.open(block);
                    } else if (block.providers.length + block.rooms.length > 1) {
                      blockConfirmDelete.open(block);
                    } else {
                      handleDeleteBlock(block);
                    }
                  }}
                  onRequestEditBlock={(block: ScheduleBlockVO) => {
                    handleEditBlock(block.id, block.date);
                  }}
                  onEventClick={handleEventClick}
                  onCellDoubleClick={handleCellDoubleClick}
                  onEventUpdated={handleEventUpdated}
                  onEventCreate={handleEventCreate}
                  onUpdateAppointmentState={handleUpdateAppointmentState}
                />
              )}
            </ScheduleQueriesResult>
          </div>
        </div>
        <FlyoverV2 isOpen={settingsFlyover.isOn}>
          <ScheduleSettingsFlyover
            hipaa={hipaaView}
            hideCanceled={hideCanceled}
            hiddenRoomIds={hiddenRoomIds}
            hiddenProviderIds={hiddenProviderIds}
            orderRoomsBy={orderRoomsBy}
            onClose={closeSettingsFlyover}
            onSave={applyFilters}
            rooms={schedule.allRooms}
            providers={schedule.allProviders}
          />
        </FlyoverV2>

        {blockConfirmDelete.isOpen ? (
          <BlockConfirmDelete
            groupedBy={groupAppointmentsBy}
            isConfirming={deleteScheduleBlockMutation.isLoading}
            block={blockConfirmDelete.item}
            onCancel={blockConfirmDelete.close}
            onConfirm={() => handleDeleteBlock(blockConfirmDelete.item)}
          />
        ) : editRepeatBlockModal.isOpen ? (
          <RecurringBlockModal
            onConfirm={(changeMode) => {
              handleChangeMode(
                changeMode,
                editRepeatBlockModal.item.blockId,
                editRepeatBlockModal.item.updates
              );
              editRepeatBlockModal.close();
            }}
            onCancel={() => {
              // If cancelled reverse optimistic update
              queryClient.invalidateQueries([getQueryKey("practices", "getScheduleBlocks"), { practiceId }]);
              editRepeatBlockModal.close();
            }}
            onClose={() => {
              // If cancelled reverse optimistic update
              queryClient.invalidateQueries([getQueryKey("practices", "getScheduleBlocks"), { practiceId }]);
              editRepeatBlockModal.close();
            }}
            title="Edit Recurring Block"
          />
        ) : deleteRepeatBlockModal.isOpen ? (
          <DeleteRecurringBlockModal
            block={deleteRepeatBlockModal.item}
            groupedBy={groupAppointmentsBy}
            onConfirm={deleteRepeatBlockModal.close}
            onRequestClose={deleteRepeatBlockModal.close}
          />
        ) : null}
      </PatientSnapshotLayout>
    </ScheduleAppHistoryProvider>
  );
};
