import React, { ReactNode, useMemo } from "react";
import {
  AppointmentCategoryVO,
  AppointmentPatientProcedureVO,
  LabCaseStatusVO,
  NameVO,
  PatientProcedureVO,
  TagVO,
} from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isLeftISODateAfterRightISODate } from "@libs/utils/date";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { Pill } from "@libs/components/UI/Pill";
import { FormFieldColorPicker } from "@libs/components/UI/FormFieldColorPicker";
import { Button } from "@libs/components/UI/Button";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { BaseFormSection } from "components/UI/FormSection";
import { AppointmentCategoryOptions } from "components/ScheduleAppointments/AppointmentCategoryOptions";
import { DurationField } from "components/ScheduleAppointments/DurationField";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";

import { Divider } from "components/UI/Divider";
import { appointmentFormCxStyles } from "components/ScheduleAppointments/AppointmentForm";
import { LabCaseLateWarningIcon } from "components/LabCases/LabCasesPage";
import { TooltipLabel } from "components/UI/TooltipLabel";
import { AppointmentTagMultiSelect } from "components/ScheduleAppointments/AppointmentTagMultiSelect";
import {
  AppointFormProcedurePicker,
  AppointFormProcedurePickerProps,
} from "./AppointmentFormProcedurePicker";

interface Props extends AppointFormProcedurePickerProps {
  appointmentDate: string | undefined;
  categories: AppointmentCategoryVO[];
  savedCategory: NameVO | undefined;
  color: string;
  hasCustomColor: boolean;
  comment: string;
  disabled: boolean;
  onColorChange: (val: string | undefined) => void;
  onCommentChange: (val: string) => void;
  onSelectCategory: (id: number) => void;
  patientSlot: ReactNode;
  selectedCategoryId: number;
  onEditLabCase: (labCaseUuid: LabCaseStatusVO["uuid"]) => void;
  onDeleteProcedureWithLabCase: (procedure: PatientProcedureVO) => void;
  tagIds: number[];
  tags: TagVO[];
  onUpdateTags: (newTagIds: number[]) => void;
  autoTagIds: number[] | undefined;
}

export const AppointmentProceduresAndIconsRow: React.FC<Props> = ({
  appointmentDate,
  categories,
  savedCategory,
  color,
  hasCustomColor,
  comment,
  disabled,
  onAddProcedure,
  onColorChange,
  onCommentChange,
  onEditProcedure,
  onEditEstimates,
  onRemoveProcedure,
  onSelectCategory,
  patientSlot,
  procedures,
  selectedCategoryId,
  teeth,
  tagIds,
  tags,
  onUpdateTags,
  autoTagIds,
  onEditLabCase,
  onDeleteProcedureWithLabCase,
}) => {
  const proceduresWithLabCase = [
    ...procedures.category.filter((procedure) => procedure.labCaseStatus != null && !("draft" in procedure)),
    ...procedures.additional.filter(
      (procedure) => procedure.labCaseStatus != null && !("draft" in procedure)
    ),
  ] as PatientProcedureVO[];

  return (
    <div className={appointmentFormCxStyles.row}>
      <div className={appointmentFormCxStyles.contentContainer}>
        <div className={appointmentFormCxStyles.padding}>{patientSlot}</div>
        <Divider />
        <BaseFormSection className={appointmentFormCxStyles.padding} title="Procedures">
          <FormFieldLabel className="mb-2 text-xs" content="Category" disabled={disabled} required />
          <AppointmentCategoryOptions
            appointmentCategories={categories}
            savedCategory={savedCategory}
            disabled={disabled}
            onSelectCategory={onSelectCategory}
            selectedCategoryId={selectedCategoryId}
          />
          <AppointFormProcedurePicker
            disabled={disabled}
            onAddProcedure={onAddProcedure}
            onEditProcedure={onEditProcedure}
            onEditEstimates={onEditEstimates}
            onRemoveProcedure={onRemoveProcedure}
            procedures={procedures}
            teeth={teeth}
          />
          <FormFieldTextarea
            className="mt-3 w-3/5"
            disabled={disabled}
            label="Comments"
            layout="labelOut"
            onChange={(e) => onCommentChange(e.target.value)}
            placeholder="Please give brief overview of patient’s condition e.g. Broken tooth/ severe tooth pain/ etc."
            value={comment}
          />
        </BaseFormSection>
      </div>
      <VerticalDivider />
      <div
        className={cx(
          "flex flex-col gap-y-12",
          appointmentFormCxStyles.detailsContainer,
          appointmentFormCxStyles.padding
        )}
      >
        <div>
          <FormFieldLabel content="Tags" className="text-xs font-sansSemiBold pb-1" />
          <div>
            <AppointmentTagMultiSelect
              tagIds={tagIds}
              tags={tags}
              onItemsSelected={onUpdateTags}
              autoAppointmentIds={autoTagIds}
            />
          </div>
        </div>
        <div className="w-36 relative">
          <FormFieldColorPicker
            disabled={disabled}
            label={
              <TooltipLabel
                tooltip={{
                  content:
                    "By default the category color is the appointment color but you can choose any color.",
                }}
              >
                Appointment Color
              </TooltipLabel>
            }
            onChange={(val) => onColorChange(val)}
            value={color}
          />
          {hasCustomColor ? (
            <Button
              className="absolute right-5 bottom-2"
              size="small"
              theme="reveal"
              onClick={() => onColorChange(undefined)}
            >
              Reset
            </Button>
          ) : null}
        </div>
        <div className="w-28">
          <DurationField disabled={disabled} />
        </div>
        {proceduresWithLabCase.length > 0 ? (
          <div className="w-48">
            <div className="flex flex-col gap-y-3">
              <FormFieldLabel className="text-xs font-sansSemiBold" content="Lab Cases" />
              {proceduresWithLabCase.map((procedure) => (
                <LabCasePillMenu
                  key={procedure.id}
                  appointmentDate={appointmentDate}
                  procedure={procedure}
                  onEdit={() => {
                    procedure.labCaseStatus?.uuid && onEditLabCase(procedure.labCaseStatus.uuid);
                  }}
                  onDelete={() => {
                    onDeleteProcedureWithLabCase(procedure);
                  }}
                />
              ))}
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
};

const LabCasePillMenu: React.FC<{
  appointmentDate: string | undefined;
  procedure: PatientProcedureVO | AppointmentPatientProcedureVO;
  onEdit: Func;
  onDelete: Func;
}> = ({ procedure, appointmentDate, onEdit, onDelete }) => {
  const menu = useBoolean(false);
  const labCaseMightBeLate = useMemo(
    () =>
      procedure.labCaseStatus?.estimatedReceiveDate && appointmentDate
        ? isLeftISODateAfterRightISODate(procedure.labCaseStatus.estimatedReceiveDate, appointmentDate)
        : false,
    [appointmentDate, procedure.labCaseStatus?.estimatedReceiveDate]
  );

  return (
    <div className="flex items-center gap-x-1">
      <Pill className="w-fit flex items-center gap-x-2" size="md">
        {procedure.displayName}
        <ButtonMenu
          isOpen={menu.isOn}
          onRequestOpen={menu.on}
          onRequestClose={menu.off}
          menuContent={
            <MenuOptions
              options={createMenuOptions(
                {
                  label: "Edit",
                  value: "edit",
                  SvgIcon: EditIcon,
                },
                {
                  label: "Delete",
                  value: "delete",
                  SvgIcon: DeleteIcon,
                }
              )}
              onOptionClick={(option) => {
                switch (option.value) {
                  case "edit": {
                    onEdit();
                    break;
                  }
                  case "delete": {
                    onDelete();
                    break;
                  }
                  // no default
                }

                menu.off();
              }}
            />
          }
        >
          {(buttonProps) => (
            // eslint-disable-next-line react/button-has-type
            <button {...buttonProps}>
              <MenuIcon className="h-5 w-5" />
            </button>
          )}
        </ButtonMenu>
      </Pill>
      {labCaseMightBeLate && <LabCaseLateWarningIcon />}
    </div>
  );
};
