import { FC } from "react";
import { LedgerV2AppointmentEntryVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { Pill, PillProps } from "@libs/components/UI/Pill";

export const PatientRunningBalancePill: FC<{ appointmentEntry: LedgerV2AppointmentEntryVO }> = ({
  appointmentEntry,
}) => {
  const text = `Patient ${formatCurrency(appointmentEntry.patientRunningBalance)}`;
  const theme: PillProps["theme"] =
    appointmentEntry.patientRunningBalance < 0
      ? "orange"
      : appointmentEntry.patientRunningBalance > 0
        ? "red"
        : "green";

  return <Pill theme={theme}>{text}</Pill>;
};
