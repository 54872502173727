import { useMemo } from "react";
import { endOfDay, parseISO, startOfDay } from "date-fns";
import { MbscEventcalendarView } from "@mobiscroll/react";
import { AppointmentVO, ProviderVO, RoomVO, ScheduleBlockVO, TimeRangeVO } from "@libs/api/generated-api";
import designConfig from "@libs/design.config";
import { setToDay } from "@libs/utils/date";
import {
  AppointmentEvent,
  BlockEvent,
  createAppointmentEvent,
  createBlockEvents,
} from "components/ScheduleAppointments/createEvent";
import {
  DateInterval,
  subIntervals,
  workHoursToDateIntervals,
} from "components/ScheduleAppointments/calculateWorkHours";
import { AppointmentGrouping } from "utils/routing/scheduling";
import { ScheduleRoomOrder } from "storage/scheduling";

export interface UseScheduleProps {
  renderDate: Date;
  groupBy: AppointmentGrouping;
  rooms: RoomVO[] | undefined;
  providers: ProviderVO[] | undefined;
  appointments: AppointmentVO[] | undefined;
  providerOpenHours: ProviderVO[] | undefined;
  roomOpenHours: RoomVO[] | undefined;
  selectedDate: string;
  blocks: ScheduleBlockVO[] | undefined;
  hiddenProviderIds: Set<number>;
  hiddenRoomIds: Set<number>;
  orderRoomsBy: ScheduleRoomOrder;
}

const view: MbscEventcalendarView = {
  schedule: {
    type: "day",
    size: 1,
    timeCellStep: 10,
    allDay: false,
  },
};

const setIntervalsToDay = <T extends { end: Date; start: Date }>(interval: T, date: Date) => {
  return {
    ...interval,
    start: setToDay(interval.start, date),
    end: setToDay(interval.end, date),
  };
};

const getArchivedRooms = (
  appointments: AppointmentVO[] | undefined,
  blocks: ScheduleBlockVO[] | undefined
) => {
  const seenIds = new Set<number>();
  const archivedRooms = [
    ...(appointments?.map((app) => app.room).filter((room) => room.status === "ARCHIVED") ?? []),
    ...(blocks?.flatMap((item) => item.rooms.filter((room) => room.status === "ARCHIVED")) ?? []),
  ];
  const uniques: RoomVO[] = [];

  for (const room of archivedRooms) {
    if (!seenIds.has(room.id)) {
      uniques.push(room);
      seenIds.add(room.id);
    }
  }

  return uniques;
};

const getHiddenProviders = (
  appointments: AppointmentVO[] | undefined,
  blocks: ScheduleBlockVO[] | undefined
) => {
  const seenIds = new Set<number>();
  const hiddenProviders = [
    ...(appointments?.map((app) => app.provider).filter((provider) => provider.status !== "ACTIVE") ?? []),
    ...(blocks?.flatMap((item) => item.providers.filter((provider) => provider.status !== "ACTIVE")) ?? []),
  ];
  const uniques: ProviderVO[] = [];

  for (const provider of hiddenProviders) {
    if (!seenIds.has(provider.id)) {
      uniques.push(provider);
      seenIds.add(provider.id);
    }
  }

  return uniques;
};

// Given resouce=providers or rooms and resouceOpenHours=providerOpenHours or roomOpenHours, this provides the styled availability for those constraints on those resources
const getAvailabilityForResource = ({
  resource,
  resourceOpenHours,
  parsedDate,
  renderDate,
}: {
  resource: { id: number }[];
  resourceOpenHours: { id: number; openHours: TimeRangeVO[] }[];
  parsedDate: Date;
  renderDate: Date;
}) => {
  const fullDayInterval = [{ start: startOfDay(parsedDate), end: endOfDay(parsedDate) }];
  let firstMinute: { interval: DateInterval | null; resource: number } = {
    interval: null,
    resource: 0,
  };
  const list = resource.flatMap((provider) => {
    const openHours = resourceOpenHours.find((po) => po.id === provider.id)?.openHours;
    const intervals = openHours ? openHours.map((range) => workHoursToDateIntervals(parsedDate, range)) : [];

    if (intervals.length && (!firstMinute.interval || intervals[0].start < firstMinute.interval.start)) {
      firstMinute = {
        interval: intervals[0],
        resource: provider.id,
      };
    }

    return subIntervals(fullDayInterval, intervals).map((interval) => ({
      ...setIntervalsToDay(interval, renderDate),
      resource: provider.id,
      background: designConfig.colors.grey["200"],
    }));
  });

  return firstMinute.interval
    ? {
        colors: [
          ...list,
          {
            ...setIntervalsToDay(firstMinute.interval, renderDate),
            resource: firstMinute.resource,
            background: "white",
          },
        ],
        hasWorkHours: true,
      }
    : { colors: list, hasWorkHours: false };
};

export interface UseScheduleResult {
  events: (
    | (AppointmentEvent & {
        start: Date;
        end: Date;
      })
    | (BlockEvent & {
        start: Date;
        end: Date;
      })
  )[];
  allRooms: {
    id: number;
    color: string;
    name: string;
  }[];
  selectedRooms: {
    id: number;
    color: string;
    name: string;
  }[];
  allProviders: {
    id: number;
    color: string;
    name: string;
  }[];
  selectedProviders: {
    id: number;
    color: string;
    name: string;
  }[];
  colors: {
    resource: number;
    background: string;
    end: Date;
    start: Date;
  }[];
  hasWorkHours: boolean;
  hasEvents: boolean | undefined;
  view: MbscEventcalendarView;
}

export const useSchedule = ({
  renderDate,
  groupBy,
  rooms,
  providers,
  appointments,
  blocks,
  providerOpenHours,
  roomOpenHours,
  selectedDate,
  hiddenProviderIds,
  hiddenRoomIds,
  orderRoomsBy,
}: UseScheduleProps) => {
  const parsedDate = useMemo(() => parseISO(selectedDate), [selectedDate]);

  const { allProviders, selectedProviders } = useMemo(() => {
    const hiddenProviders = getHiddenProviders(appointments, blocks);
    const combinedProviders = [...(providers ?? []), ...hiddenProviders];

    const mappedAllProviders = combinedProviders.map((item) => ({
      id: item.id,
      color: "#EEF6FC",
      name: item.name.shortDisplayName,
      status: item.status,
    }));

    return {
      allProviders: mappedAllProviders,
      selectedProviders: mappedAllProviders.filter((provider) => !hiddenProviderIds.has(provider.id)),
    };
  }, [appointments, blocks, providers, hiddenProviderIds]);

  const { allRooms, selectedRooms } = useMemo(() => {
    const archivedRooms = getArchivedRooms(appointments, blocks);
    const combinedRooms = [...(rooms ?? []), ...archivedRooms];

    const mappedAllRooms = combinedRooms.map((item) => ({
      name: item.roomName,
      id: item.id,
      color: "#EEF6FC",
      status: item.status,
    }));

    const selectedRoomsUnordered = mappedAllRooms.filter((room) => !hiddenRoomIds.has(room.id));

    return {
      allRooms: mappedAllRooms,
      selectedRooms:
        orderRoomsBy === "settings"
          ? selectedRoomsUnordered
          : selectedRoomsUnordered.sort((a, b) => a.name.localeCompare(b.name)),
    };
  }, [appointments, blocks, rooms, orderRoomsBy, hiddenRoomIds]);

  const appointmentEvents = useMemo(() => {
    return (
      appointments?.map((item) => {
        const event = createAppointmentEvent(item, groupBy);
        const moved = setIntervalsToDay(event, renderDate);

        return moved;
      }) ?? []
    );
  }, [appointments, groupBy, renderDate]);

  const blockEvents = useMemo(() => {
    return (
      blocks?.flatMap((item) => {
        const events = createBlockEvents(item, groupBy);
        const moved = events.map((event) => setIntervalsToDay(event, renderDate));

        return moved;
      }) ?? []
    );
  }, [blocks, groupBy, renderDate]);

  const hasEvents = useMemo(() => {
    return appointments && blocks ? Boolean([...appointments, ...blocks].length) : undefined;
  }, [appointments, blocks]);

  const { colors, hasWorkHours } = useMemo(() => {
    if (groupBy === "room" && roomOpenHours) {
      return getAvailabilityForResource({
        resource: selectedRooms,
        resourceOpenHours: roomOpenHours.map((item) => ({
          openHours: item.openHours ?? [],
          id: item.id,
        })),
        parsedDate,
        renderDate,
      });
    } else if (groupBy === "provider" && providerOpenHours) {
      return getAvailabilityForResource({
        resource: selectedProviders,
        resourceOpenHours: providerOpenHours.map((item) => ({
          openHours: item.openHours ?? [],
          id: item.id,
        })),
        parsedDate,
        renderDate,
      });
    }

    return {
      colors: [],
      hasWorkHours: false,
    };
  }, [groupBy, roomOpenHours, providerOpenHours, selectedRooms, parsedDate, renderDate, selectedProviders]);

  return {
    events: [...appointmentEvents, ...blockEvents],
    allProviders,
    selectedProviders,
    allRooms,
    selectedRooms,
    colors,
    hasWorkHours,
    hasEvents,
    view,
  };
};
