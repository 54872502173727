import { FC, useState, useMemo, useCallback, useEffect, useRef } from "react";
import { addSeconds } from "date-fns";

import { AppointmentVO, FormTaskVO, FormSummaryVO, PatientSummaryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { getLocalDate } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Spinner } from "@libs/components/UI/Spinner";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ToggleButtonList } from "components/UI/ToggleButtonList";
import { AttachFormsButtonMenu } from "components/PatientProfile/Forms/AttachFormsButtonMenu";
import { FormTaskButtonMenu, isExpiredFormTask } from "components/PatientProfile/Forms/FormTaskButtonMenu";

import { useWebSocketContext } from "contexts/WebSocketContext";
import { useNow } from "hooks/useNow";
import { WebSocketMessageEvent } from "api/websocket/webSocketMessage";

const SYNC_FORM_TASKS_TIMEOUT = 30_000;
const SECONDS_APPOINTMENT_CREATED_WITHIN = 15;

const FormTaskStateLabelIndicators: Record<FormTaskVO["state"] | "EXPIRED", Record<string, string>> = {
  PENDING: { content: "Incomplete", color: "bg-red" },
  COMPLETED: { content: "Complete", color: "bg-green" },
  EXPIRED: { content: "Expired", color: "bg-yellow" },
  ARCHIVED: { content: "Archived", color: "bg-greyMedium" },

  // Will never be displayed
  PRINT_ONLY: { content: "Printed", color: "bg-greyMedium" },
};

// When appointments are created or updated, form tasks are asynchronously
// attached or updated (added, removed, or expired), and we wait for a websocket
// event to invalidate our listFormTasks query. Since these tasks are updated
// asyncrhonously, we need to show a syncing indicator until the websocket event
// is either received, or we reach a 30s timeout in case the event is not sent.
const useSyncFormTasks = (appointment: AppointmentVO) => {
  const { messages } = useWebSocketContext();
  const appointmentRef = useRef(appointment);
  const now = useNow();
  const sync = useBoolean(false);
  const startSync = sync.on;

  const onMessageHandler = useCallback(
    (event: WebSocketMessageEvent) => {
      const message = event.detail;

      if (message.type === "FORM_TASK" && message.payload.appointmentId === appointment.id && sync.isOn) {
        sync.off();
      }
    },
    [appointment.id, sync]
  );

  useEffect(() => {
    if (messages && sync.isOn) {
      messages.addEventListener("message", onMessageHandler);

      return () => {
        messages.removeEventListener("message", onMessageHandler);
      };
    }

    return undefined;
  }, [messages, onMessageHandler, sync.isOn]);

  useEffect(() => {
    const timeout = sync.isOn ? window.setTimeout(sync.off, SYNC_FORM_TASKS_TIMEOUT) : 0;

    return () => {
      if (timeout) {
        window.clearTimeout(timeout);
      }
    };
  }, [sync]);

  useEffect(() => {
    const currentAppointment = appointmentRef.current.id === appointment.id;
    const appointmentUpdated = appointmentRef.current.audit?.updatedAt !== appointment.audit?.updatedAt;
    // When an appointment has been recently created within the last 15 seconds,
    // we show a sync indicator until a websocket event is received in order to
    // show if and when form tasks are attached to the appointment.
    const appointmentCreatedWithin15Seconds =
      appointment.audit?.createdAt &&
      !appointment.audit.updatedAt &&
      now < addSeconds(getLocalDate(appointment.audit.createdAt), SECONDS_APPOINTMENT_CREATED_WITHIN);

    if (currentAppointment && (appointmentUpdated || appointmentCreatedWithin15Seconds)) {
      startSync();
    }

    appointmentRef.current = appointment;
  }, [appointment, now, startSync]);

  return sync.isOn;
};

interface Props {
  appointment: AppointmentVO;
  patient: PatientSummaryVO;
  formTasks: FormTaskVO[];
  forms: FormSummaryVO[];
}

export const AppointmentDetailForms: FC<Props> = ({ appointment, patient, formTasks, forms }) => {
  const [selectedFormTaskUuids, setSelectedFormTaskUuids] = useState<Set<FormTaskVO["uuid"]>>(new Set());
  const hasSelectedFormTasks = selectedFormTaskUuids.size > 0;

  const isSyncing = useSyncFormTasks(appointment);

  const selectedFormTasks = useMemo(
    () => formTasks.filter((ft) => selectedFormTaskUuids.has(ft.uuid)),
    [formTasks, selectedFormTaskUuids]
  );

  const formTaskOptions = useMemo(
    () =>
      formTasks.map((ft) => {
        const formTaskState = isExpiredFormTask(ft) ? "EXPIRED" : ft.state;

        return {
          label: (
            <div className="flex items-center gap-x-2">
              <FloatingTooltip content={FormTaskStateLabelIndicators[formTaskState].content} theme="SMALL">
                <div
                  className={cx(
                    "rounded-full aspect-square w-2",
                    FormTaskStateLabelIndicators[formTaskState].color
                  )}
                />
              </FloatingTooltip>
              {ft.form.title}
            </div>
          ),
          value: ft.uuid,
        };
      }),
    [formTasks]
  );

  return (
    <div className="flex flex-col gap-y-1">
      <div className="flex items-center gap-x-1">
        <span className="font-sansSemiBold text-xs leading-6">Forms</span>
        {isSyncing ? <Spinner size="xs" variant="greyLighter" animation="border" /> : null}
      </div>

      <ToggleButtonList
        type="checkbox"
        listClassName="flex items-center flex-wrap gap-1"
        layout="custom"
        shape="pill"
        options={formTaskOptions}
        selectedValues={selectedFormTaskUuids}
        onChange={setSelectedFormTaskUuids}
      >
        <div className="flex items-center gap-x-0.5">
          <AttachFormsButtonMenu
            patientId={patient.id}
            appointment={appointment}
            formTasks={formTasks}
            forms={forms}
            theme="circle"
          />

          {formTasks.length > 0 ? (
            <FormTaskButtonMenu
              patient={patient}
              formTasks={selectedFormTasks}
              disabled={!hasSelectedFormTasks}
              theme="circle"
            />
          ) : null}
        </div>
      </ToggleButtonList>
    </div>
  );
};
