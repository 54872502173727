import React from "react";
import { WorkingHourResponse, WorkingHourVO, WorkingHourTotalVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getAbsoluteUrl } from "@libs/utils/location";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";
import { handleError } from "utils/handleError";
import { useDownloadQueryPages } from "components/Dashboard/hooks/useDownloadInfiniteQuery";
import { getTotals } from "components/Dashboard/Tables/utils";
import { paths } from "utils/routing/paths";
import { TimeSegment } from "utils/routing/dashboard";
import { EMPLOYEE_PAYROLL_TABLE_ROWS } from "components/Dashboard/PayrollHours/constants";
import { useCurrentUrl, useOrigin } from "contexts/OriginContext";

const formatAppointmentDataAsCsvUrl = (params: {
  rows: WorkingHourVO[];
  totals: WorkingHourTotalVO;
  formatWorkingSeconds: (seconds: number) => string;
  origin: string;
}) => {
  const { rows, totals, formatWorkingSeconds, origin } = params;
  const columnTitles = formatCsvRow([
    ...EMPLOYEE_PAYROLL_TABLE_ROWS.map(({ label }) => label).filter(Boolean),
    "Timesheet URL",
  ]);

  const rowsContent = rows.map((row) =>
    formatCsvRow([
      row.employee.shortDisplayName,
      formatWorkingSeconds(row.totalSecondsWorked),
      row.worktimeStatus,
      getAbsoluteUrl(origin, paths.employeeTimesheet({ id: row.employee.id })),
    ])
  );
  const totalsContent = formatCsvRow(["Total", formatWorkingSeconds(totals.totalWorkingSeconds), ""]);

  return [columnTitles, ...rowsContent, totalsContent];
};

export const useDownloadWorkingHoursCSV = ({
  selectedTimeSegment,
  employeeWorkingHoursQuery,
  formatWorkingSeconds,
}: {
  selectedTimeSegment: TimeSegment;
  employeeWorkingHoursQuery: UseInfiniteApiQueryResult<WorkingHourResponse>;
  formatWorkingSeconds: (seconds: number) => string;
}) => {
  const downloading = useBoolean(false);

  const { startDownload: downloadWorkingHours } = useDownloadQueryPages(employeeWorkingHoursQuery);
  const origin = useOrigin();
  const currentUrl = useCurrentUrl();

  return {
    isDownloading: downloading.isOn,
    downloadCSV: React.useCallback(async () => {
      downloading.on();

      const docMetadata = getArchyCsvMetadata(currentUrl, selectedTimeSegment);

      try {
        const result = await downloadWorkingHours();
        const rows = result.pages.flatMap((item) => item.data.entries);
        const totals = getTotals(result);

        if (totals) {
          const csvRows = [
            ...docMetadata.headers,
            ...formatAppointmentDataAsCsvUrl({
              formatWorkingSeconds,
              rows,
              totals,
              origin,
            }),
          ];

          saveCsv(csvRows, `EmployeeWorkingHours_${docMetadata.fileNameTag}`);
        }
      } catch (e) {
        handleError(e);
      }

      downloading.off();
    }, [downloading, selectedTimeSegment, downloadWorkingHours, formatWorkingSeconds, origin, currentUrl]),
  };
};
