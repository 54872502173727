import { FC } from "react";
import { TemplateVariableGroupVO } from "@libs/api/generated-api";
import { DateForm } from "components/Settings/Forms/Date/Form";
import { YesNoForm } from "components/Settings/Forms/YesNo/Form";
import { TextInputForm } from "components/Settings/Forms/Text/Form";
import { EditElementFormProps, FormElementRequest } from "components/Settings/Forms/types";
import { SectionForm } from "components/Settings/Forms/Section/Form";
import { NumberForm } from "components/Settings/Forms/Number/Form";
import { ConsentForm } from "components/Settings/Forms/Consent/Form";
import { MultipleChoiceForm } from "components/Settings/Forms/MultipleChoice/Form";
import { TextBlockForm } from "components/Settings/Forms/TextBlock/Form";
import { EditableTextBlockForm } from "components/Settings/Forms/EditableTextBlock/Form";
import { ProcedureTableForm } from "components/Settings/Forms/ProcedureTable/Form";

interface Props extends EditElementFormProps<FormElementRequest> {
  templateVariables?: TemplateVariableGroupVO[];
  onRequestClose?: Func;
}

// eslint-disable-next-line complexity
export const EditElementSection: FC<Props> = ({
  onSubmit,
  onSecondaryClick,
  onDraftChange,
  onInvalid,
  element,
  formType,
  templateVariables,
  isEditing,
}) => {
  const commonProps = {
    onSubmit,
    onSecondaryClick,
    onDraftChange,
    onInvalid,
    isEditing,
    formType,
  };

  switch (element.type) {
    case "CONSENT": {
      return <ConsentForm {...commonProps} element={element} templateVariables={templateVariables} />;
    }
    case "TEXT_BLOCK": {
      return <TextBlockForm {...commonProps} element={element} templateVariables={templateVariables} />;
    }
    case "SECTION": {
      return <SectionForm {...commonProps} element={element} />;
    }
    case "BOOLEAN_INPUT": {
      return <YesNoForm {...commonProps} element={element} />;
    }
    case "DATE_INPUT": {
      return <DateForm {...commonProps} element={element} />;
    }
    case "NUMBER_INPUT": {
      return <NumberForm {...commonProps} element={element} />;
    }
    case "SELECT_INPUT": {
      return <MultipleChoiceForm {...commonProps} element={element} />;
    }
    case "TEXT_INPUT": {
      return <TextInputForm {...commonProps} element={element} />;
    }
    case "EDITABLE_TEXT_BLOCK": {
      return (
        <EditableTextBlockForm {...commonProps} element={element} templateVariables={templateVariables} />
      );
    }
    case "PROCEDURE_TABLE": {
      return <ProcedureTableForm {...commonProps} element={element} />;
    }
    default: {
      return null;
    }
  }
};
