import { FC } from "react";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent } from "@libs/components/UI/ModalComponents";
import SuccessFamily from "assets/images/success.svg";

interface Props {
  onRequestClose: Func;
  familyMemberName: string;
}

export const SuccessFamilyModal: FC<Props> = ({ onRequestClose, familyMemberName }) => {
  return (
    <Modal size="xs" onClose={onRequestClose}>
      <ModalContent padding="sm">
        <ButtonIcon
          className="absolute top-6 right-8 h-5 w-5"
          SvgIcon={CloseIcon}
          onClick={onRequestClose}
          theme="primary"
          tooltip={{ content: "Close", theme: "SMALL" }}
        />
        <div className="flex flex-col items-center pt-5 pb-2.5">
          <img alt="Success" className="h-40 w-[185px]" src={SuccessFamily} />
          <div
            className={`
              mt-5
              flex
              justify-center
              font-sansSemiBold
              text-base
              text-primaryTheme
            `}
          >
            {familyMemberName ? `${familyMemberName} added as Family Member!` : "Family member added!"}
          </div>
        </div>
      </ModalContent>
    </Modal>
  );
};
