import React from "react";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";

export const useSortHandler = () => {
  return React.useCallback((currentSortOrder: ColumnSortOrder[], newSortField: string): ColumnSortOrder[] => {
    const primarySortField = currentSortOrder[0];
    const newSortOrder = currentSortOrder
      .map((item) => {
        if (item.field === newSortField) {
          return {
            ...item,
            direction:
              primarySortField.field === item.field
                ? item.direction === "ASCENDING"
                  ? "DESCENDING"
                  : "ASCENDING"
                : item.direction,
          };
        }

        return item;
      })
      .sort((a, b) => (b.field === newSortField ? 1 : a.field === newSortField ? -1 : 0));

    return newSortOrder;
  }, []);
};
