import { FC, FormEvent, ReactNode, useEffect } from "react";
import { AlertSettingsVO, UpdateAlertSettingsRequest } from "@libs/api/generated-api";
import { filterMap } from "@libs/utils/array";
import { useObjectState } from "@libs/hooks/useObjectState";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Form, Link } from "react-router-dom";
import { flushSync } from "react-dom";
import { Glyph } from "@libs/components/UI/Glyph";
import { ReactComponent as Clock } from "@libs/assets/icons/clock.svg";
import { Banner } from "@libs/components/UI/Banner";
import { useIsDirty } from "hooks/useIsDirty";
import { Prompt } from "components/UI/Prompt";
import { paths } from "utils/routing/paths";

const savedToState = (alertSettings: AlertSettingsVO) => ({
  appearance: alertSettings.appearance,
  enabledAppointmentAutoTagIds: new Set(
    filterMap(alertSettings.appointmentAutoTags, ({ tag, isEnabled }) => (isEnabled ? tag.id : undefined))
  ),
  enabledPatientAutoTagIds: new Set(
    filterMap(alertSettings.patientAutoTags, ({ tag, isEnabled }) => (isEnabled ? tag.id : undefined))
  ),
});

type EditorState = ReturnType<typeof savedToState>;

interface Props {
  alertSettings: AlertSettingsVO;
  isSaving: boolean;
  isSaved: boolean;
  updateInProgress: ReactNode;
  onSave: (updates: UpdateAlertSettingsRequest) => void;
  children: (params: {
    alertSettingsState: EditorState;
    updateAlertSettingsState: (updates: Partial<EditorState>) => void;
    setAlertSettingsState: (newState: EditorState) => void;
  }) => ReactNode;
}

const stateToSaved = (alertSettingsState: {
  appearance: AlertSettingsVO["appearance"];
  enabledAppointmentAutoTagIds: Set<number>;
  enabledPatientAutoTagIds: Set<number>;
}) => ({
  appearance: alertSettingsState.appearance,
  enabledAppointmentAutoTagIds: [...alertSettingsState.enabledAppointmentAutoTagIds],
  enabledPatientAutoTagIds: [...alertSettingsState.enabledPatientAutoTagIds],
});

export const AlertsSettingsEditor: FC<Props> = ({
  updateInProgress,
  alertSettings,
  onSave,
  isSaving,
  isSaved,
  children,
}) => {
  const [alertSettingsState, updateAlertSettingsState, setAlertSettingsState] = useObjectState(() =>
    savedToState(alertSettings)
  );

  const { isDirty, markClean } = useIsDirty(alertSettingsState);

  const restoreSaved = () => {
    flushSync(() => setAlertSettingsState(savedToState(alertSettings)));
    markClean();
  };

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSave(stateToSaved(alertSettingsState));
  };

  useEffect(() => {
    if (isSaved) {
      markClean();
    }
  }, [isSaved, markClean]);

  return (
    <Form onSubmit={handleSubmit} className="h-full flex flex-col">
      <div className="flex-1 min-h-0 overflow-y-auto p-6">
        <div className="mx-auto max-w-xl">
          <div className="flex flex-col gap-y-6">
            {children({ alertSettingsState, updateAlertSettingsState, setAlertSettingsState })}
            <div className="bg-slate-50 py-2.5 px-3 text-xs">
              <Glyph inline family="EMOJI">
                👋
              </Glyph>
              <span className="pl-2 pr-1 align-middle">
                Want to change a tag color or icon? You can do that and create custom tags in
              </span>
              <Link className="text-primaryTheme align-middle font-sansSemiBold" to={paths.noteTags()}>
                tag settings
              </Link>
            </div>
            {alertSettings.isPatientAutoAlertNoteJobInProgress ? (
              <Banner Icon={Clock} className="text-xs rounded" theme="info">
                {updateInProgress}
              </Banner>
            ) : null}
          </div>
        </div>
      </div>
      <div className="flex-none p-4 border-t border-t-slate-200">
        <div className="flex items-center justify-center gap-x-3">
          <Button disabled={!isDirty} className="min-w-button" onClick={restoreSaved} theme="secondary">
            Cancel
          </Button>
          <AsyncButton
            disabled={!isDirty || alertSettings.isPatientAutoAlertNoteJobInProgress}
            className="min-w-button"
            isLoading={isSaving}
            type="submit"
          >
            Save
          </AsyncButton>
        </div>
        <Prompt
          shouldBlock={() => isDirty}
          message="You have unsaved changes. Are you sure you want to leave?"
        />
      </div>
    </Form>
  );
};
