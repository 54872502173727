import { FC, PropsWithChildren, useRef } from "react";
import { MessageVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { isOneOf } from "@libs/utils/isOneOf";
import { ChatImage } from "components/Messaging/ChatImage";
import { useIsVisible } from "hooks/useIsVisible";

const cxThemes = {
  patient: {
    container: "bg-greyLightest",
    meta: "self-start",
  },
  practice: {
    container: "bg-primaryTheme text-white",
    meta: "self-end text-white",
  },
  autotext: {
    container: "bg-secondaryTheme text-white",
    meta: "self-end text-white",
  },
  scheduled: {
    container: "bg-whiteLight",
    meta: "self-end",
  },
};

type Theme = keyof typeof cxThemes;

const cxStyles = {
  container: (theme: Theme) =>
    cx(
      `relative
       inline-flex
       flex-col
       px-3
       pt-3
       pb-4
       rounded-2xl
       [overflow-wrap:anywhere]
       whitespace-pre-line`,
      cxThemes[theme].container
    ),
  meta: (theme: Theme) => cx("flex items-center gap-x-1 text-xxs text-greyMedium", cxThemes[theme].meta),
};

interface Props extends Pick<MessageVO, "attachmentFolderId" | "attachmentDocumentIds"> {
  message: string;
  displayName: string;
  timestamp: string;
  patientId?: number;
  theme: Theme;
}

export const ChatBubble: FC<PropsWithChildren<Props>> = ({
  message,
  displayName,
  timestamp,
  patientId,
  attachmentFolderId,
  attachmentDocumentIds,
  theme,
  children,
}) => {
  const containerRef = useRef<HTMLDivElement | null>(null);
  const isVisible = useIsVisible(containerRef, { isVisibleOnce: true, enabled: Boolean(attachmentFolderId) });
  const hasImageAttachments =
    patientId && attachmentFolderId && attachmentDocumentIds && attachmentDocumentIds.length > 0;

  return (
    <div data-testid="chat-bubble" ref={containerRef} className={cxStyles.container(theme)}>
      <div className="flex flex-col gap-y-1">
        {hasImageAttachments
          ? attachmentDocumentIds.map((documentId) => (
              <ChatImage
                key={documentId}
                patientId={patientId}
                folderId={attachmentFolderId}
                documentId={documentId}
                altText={`Attachment from ${displayName}`}
                isVisible={isVisible}
              />
            ))
          : null}

        {message ? <p className="text-sm">{message}</p> : null}
      </div>

      <span className={cxStyles.meta(theme)}>
        {isOneOf(theme, ["practice", "autotext", "scheduled"]) ? (
          <span className="font-sansSemiBold">
            {theme === "scheduled" ? "Scheduled to Send at" : displayName}
          </span>
        ) : null}
        {timestamp}
      </span>

      {children}
    </div>
  );
};
