import { useCallback, useState } from "react";
import { filenameToMimeType } from "@libs/utils/mimeTypes";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { prepareFileUpload } from "api/file-uploads/mutations";

export const useFileUpload = () => {
  const [isUploading, setIsUploading] = useState(false);
  const { practiceId } = useAccount();
  const [{ mutateAsync }] = useApiMutations([prepareFileUpload]);

  const handlePrepareFileUpload = useCallback(
    async (contentType: string) => mutateAsync({ practiceId, data: { contentType } }),
    [practiceId, mutateAsync]
  );

  const handleFileUpload = useCallback(
    async (file: File) => {
      const contentType = filenameToMimeType(file.name);

      setIsUploading(true);

      try {
        // First, we need to get a signed URL from the API
        const prepData = await handlePrepareFileUpload(contentType);

        // Then, we can upload the file to the signed URL
        const response = await fetch(prepData.data.data.url, {
          method: "PUT",
          body: file,
        });

        if (!response.ok) {
          throw new Error(
            `Failed to upload file, error ${response.status} (${
              response.statusText
            }): ${await response.text()}`
          );
        }

        return { ...prepData.data.data, contentType };
      } finally {
        setIsUploading(false);
      }
    },
    [handlePrepareFileUpload]
  );

  return { uploadFile: handleFileUpload, isUploading };
};
