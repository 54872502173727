import { useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ClaimLineItemVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { currencyValueToCents, formatCurrency } from "@libs/utils/currency";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Icon } from "@libs/components/UI/Icon";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as WarningIcon } from "@libs/assets/icons/warning.svg";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { ClaimSection } from "components/Claim/ClaimSection";
import { ClaimModalFooter } from "components/Claim/ModalFooter";
import { ClaimModalHeader } from "components/Claim/ModalHeader";
import { ProcedureTable } from "components/Claim/ProcedureTable";
import { createClaimPaymentAdjustment } from "api/claim/mutations";
import { getProcedureTableHeaders } from "components/Claim/procedureTableUtils";
import { handleError } from "utils/handleError";
import { paths } from "utils/routing/paths";
import { getFieldTotal } from "components/Claim/ProcedureTableFooter";
import { getLatestLineItemsGroup } from "components/Claims/utils";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { getClaimQuery } from "api/claim/queries";

export const ClaimAdjustmentRoute: React.FC = () => {
  const { practiceId } = useAccount();
  const { claimUuid } = usePathParams("claimAdjustment");
  const { query } = useQueryParams("claimAdjustment");

  const from = query.from ?? paths.claims();
  const [claimQuery] = useApiQueries([getClaimQuery({ args: { practiceId, claimUuid } })]);
  const { data: claim } = claimQuery;

  const navigate = useNavigate();
  const onNavigateBack = useCallback(() => {
    navigate(from);
  }, [from, navigate]);

  const headers = getProcedureTableHeaders({ claim, isAddingAdjustment: true });

  const [lineItems, setLineItems] = useState<ClaimLineItemVO[]>([]);

  const latestLineItemGroup = getLatestLineItemsGroup(claim);

  useEffect(() => {
    if (latestLineItemGroup && lineItems.length === 0) {
      setLineItems(
        latestLineItemGroup.lineItems.map((lineItem) => {
          return {
            ...lineItem,
            downgradedCdtCode: undefined,
            remarks: undefined,
          };
        })
      );
    }
  }, [claim?.lineItemGroups, latestLineItemGroup, lineItems.length]);

  const handleAdjustmentAmountChange = useCallback(
    (lineItemIndex: number, field: string, value: string | undefined) => {
      const updatedLineItem = { ...lineItems[lineItemIndex], [field]: currencyValueToCents(value) ?? 0 };
      const updatedLineItems = [...lineItems];

      updatedLineItems[lineItemIndex] = updatedLineItem;
      setLineItems(updatedLineItems);
    },
    [lineItems]
  );

  const handleAdjustmentRemarkChange = useCallback(
    (lineItemIndex: number, updates: { downgradedCdtCode?: string; remarks?: string }) => {
      const updatedLineItem = {
        ...lineItems[lineItemIndex],
        downgradedCdtCode: updates.downgradedCdtCode,
        remarks: updates.remarks,
      };
      const updatedLineItems = [...lineItems];

      updatedLineItems[lineItemIndex] = updatedLineItem;
      setLineItems(updatedLineItems);
    },
    [lineItems]
  );

  const [createClaimPaymentAdjustmentMutation] = useApiMutations([createClaimPaymentAdjustment]);

  const isSaving = useBoolean(false);

  const handleCreateClaimPaymentAdjustment = useCallback(async () => {
    isSaving.on();

    try {
      await createClaimPaymentAdjustmentMutation.mutateAsync({
        claimUuid,
        data: { lineItems },
        practiceId,
      });
      navigate(paths.claim({ claimUuid }), { replace: true });
    } catch (err) {
      handleError(err);
    } finally {
      isSaving.off();
    }
  }, [claimUuid, createClaimPaymentAdjustmentMutation, navigate, isSaving, lineItems, practiceId]);

  const initialInsuranceAmountTotal = getFieldTotal("insuranceAmount", latestLineItemGroup?.lineItems ?? []);

  const insuranceAmountErrorMessage = useMemo(() => {
    return initialInsuranceAmountTotal === getFieldTotal("insuranceAmount", lineItems)
      ? ""
      : `Insurance total must equal the initial insurance pay total: ${formatCurrency(
          initialInsuranceAmountTotal
        )}. To record an insurance payment adjustment, process an additional payment through an EOB.`;
  }, [initialInsuranceAmountTotal, lineItems]);

  const saveDisabledTooltip = useMemo(() => {
    return insuranceAmountErrorMessage || (isSaving.isOn ? "Saving..." : "");
  }, [insuranceAmountErrorMessage, isSaving.isOn]);

  return claim && latestLineItemGroup ? (
    <>
      <ClaimModalHeader
        data-testid="adjustments-claim-header"
        onClose={onNavigateBack}
        title="Add Adjustment"
        titleDetails={[`Claim#${claim.claimNumber}`]}
      />
      <span className="max-w-[600px] pl-8 pt-5 text-sm">
        Update the deductible, patient or insurance amount to reflect the final version each party pays. The
        total insurance pay cannot exceed the $ amount input in the EOB. To process an additional insurance
        payment, create a new EOB and attach to the claim.
      </span>
      <div className="flex flex-1 flex-col px-8 overflow-y-auto">
        <ClaimSection subTitle={`(Appointment Date ${formatISODate(claim.serviceDate)})`} title="Procedures">
          <ProcedureTable
            claim={claim}
            headers={headers}
            insuranceAmountErrorMessage={insuranceAmountErrorMessage}
            lineItems={lineItems}
            onAdjustmentAmountChange={handleAdjustmentAmountChange}
            onAdjustmentRemarkChange={handleAdjustmentRemarkChange}
            showTotals={true}
            version={latestLineItemGroup.version + 1}
          />
          {insuranceAmountErrorMessage && (
            <div className="flex justify-end items-center mt-2 gap-x-2 text-red">
              <Icon SvgIcon={WarningIcon} size="sm" theme="error" />
              <span className="max-w-[600px] text-xs">{insuranceAmountErrorMessage}</span>
            </div>
          )}
        </ClaimSection>
      </div>
      <ClaimModalFooter>
        <Button className="w-[120px]" onClick={onNavigateBack} theme="secondary">
          Cancel
        </Button>
        <FloatingTooltip content={saveDisabledTooltip} theme={isSaving.isOn ? "SMALL" : "MEDIUM"}>
          <div>
            <AsyncButton
              className="w-[120px]"
              disabled={Boolean(saveDisabledTooltip)}
              isLoading={isSaving.isOn}
              onClick={handleCreateClaimPaymentAdjustment}
              theme="primary"
            >
              Save
            </AsyncButton>
          </div>
        </FloatingTooltip>
      </ClaimModalFooter>
    </>
  ) : null;
};
