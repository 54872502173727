import { FC, RefObject, Dispatch, SetStateAction, useLayoutEffect } from "react";
import { produce } from "immer";

import { PatientCriteriaVO, InsuranceCarrierVO, PatientListCriteria } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { FilterSection } from "components/Communications/Filters/FilterSection";

import { SelectCarriers } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/SelectCarriers";

interface Props extends Pick<PatientCriteriaVO, "carrierIds"> {
  containerRef: RefObject<HTMLDivElement>;
  insuranceCarriersQuery: ApiQueryResult<InsuranceCarrierVO[]>;
  onUpdatePatientListCriteria: Dispatch<SetStateAction<PatientListCriteria>>;
}

export const InsuranceCarrierFilterSection: FC<Props> = ({
  containerRef,
  carrierIds,
  insuranceCarriersQuery,
  onUpdatePatientListCriteria,
}) => {
  useLayoutEffect(() => {
    const containerElement = containerRef.current;

    // Scroll to the bottom of the container when carriers are selected
    if (carrierIds && carrierIds.length > 0 && containerElement) {
      containerElement.scrollTo({ top: containerElement.scrollHeight });
    }
  }, [carrierIds, containerRef]);

  return (
    <FilterSection
      title="Insurance Carrier"
      dataTestId="insurance-carrier-filter-section"
      isOpen={carrierIds && carrierIds.length > 0}
    >
      <SelectCarriers
        label={null}
        ariaLabel="Insurance Carrier Selection"
        menuPlacement="bottom"
        carriersQuery={insuranceCarriersQuery}
        selectedCarrierIds={new Set(carrierIds)}
        onSelect={(ids) =>
          onUpdatePatientListCriteria((last) =>
            produce(last, (draft) => {
              if (ids.length > 0) {
                if (draft.patientCriteria) {
                  draft.patientCriteria.carrierIds = ids;
                } else {
                  draft.patientCriteria = { carrierIds: ids };
                }
              } else {
                delete draft.patientCriteria?.carrierIds;
              }
            })
          )
        }
      />
    </FilterSection>
  );
};
