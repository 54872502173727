/* eslint-disable @typescript-eslint/naming-convention */
import React, { forwardRef } from "react";
import { format, sub } from "date-fns";
import { CollectedPaymentResponse } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { keys as objectKeys } from "@libs/utils/object";
import { FooterCell } from "components/Dashboard/Tables/FooterCell";
import { SectionHeader } from "components/Dashboard/Tables/SectionHeader";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import { ByPaymentMethodTableRow } from "components/Dashboard/PaymentCollected/ByPaymentMethodTableRow";
import { BY_PAYMENT_TYPE_TABLE_HEADERS } from "components/Dashboard/PaymentCollected/constants";
import { SectionFooter } from "components/Dashboard/Tables/SectionFooter";
import { labelForPayerType, labelForPaymentMethod } from "components/Dashboard/PaymentCollected/utils";
import { usePaymentTypeSections } from "components/Dashboard/PaymentCollected/hooks/usePaymentTypeSections";
import { DashboardPrintHeader } from "components/Dashboard/Tables/DashboardPrintHeader";
import { TimeSegment } from "utils/routing/dashboard";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";

type Props = {
  selectedTimeSegment: TimeSegment;
  collectedPaymentQuery: UseInfiniteApiQueryResult<CollectedPaymentResponse>;
};
export const ByPaymentMethodTable = forwardRef<HTMLDivElement | null, Props>(
  ({ collectedPaymentQuery, selectedTimeSegment }, printRef) => {
    const isPrinting = Boolean(printRef);
    const { data, isLoading } = collectedPaymentQuery;

    const paymentTypeSections = usePaymentTypeSections(data);
    const { keys, totals, sectionMap, subtotalMap } = paymentTypeSections;
    const isEmpty = !isLoading && objectKeys(sectionMap).length === 0;

    return (
      <DashboardInfiniteTable
        infiniteQuery={collectedPaymentQuery}
        ref={printRef}
        isPrinting={isPrinting}
        headerRow={
          <>
            {isPrinting && (
              <div className="mb-4 col-span-full">
                <DashboardPrintHeader>Payment Collected by Payment Method</DashboardPrintHeader>
                <div className="col-span-full text-greyLight">
                  {`${format(selectedTimeSegment.startDate, "MMM do, yyyy")} - ${format(
                    sub(selectedTimeSegment.endDate, { days: 1 }),
                    "MMM do, yyyy"
                  )}`}
                </div>
              </div>
            )}
            <DashboardHeaderRow headers={BY_PAYMENT_TYPE_TABLE_HEADERS} />
          </>
        }
        footerRow={
          <>
            <FooterCell isPrinting={isPrinting} className="col-span-5">
              Total All Payments
            </FooterCell>
            <FooterCell isPrinting={isPrinting} loading={isLoading} align="right">
              {formatCurrency(totals?.totalPaymentAmount ?? 0)}
            </FooterCell>
          </>
        }
        isEmpty={isEmpty}
        id="paymentCollectedByPaymentType"
        columnWidths={BY_PAYMENT_TYPE_TABLE_HEADERS.map(({ width }) => width)}
      >
        {keys.map((key, i) => {
          const values = sectionMap[key];
          const firstItem = values?.[0];

          if (!firstItem) {
            return null;
          }

          const { payerType, paymentMethod } = firstItem;
          const nextSection = sectionMap[keys[i + 1]];
          const showPayerTypeTotal = nextSection?.[0].payerType !== payerType;

          return (
            <React.Fragment key={key}>
              <SectionHeader
                className={`
                  flex
                  items-center
                  gap-4
                  col-span-full
                  font-sansSemiBold
                  text-xs
                `}
              >
                {labelForPayerType(payerType)} - {labelForPaymentMethod(paymentMethod)}
              </SectionHeader>
              {values.map((item) => (
                <ByPaymentMethodTableRow
                  key={`by_payment_type_${i}-${item.paymentUuid}-${item.claimUuid}-${item.invoiceUuid}-${item.patient.id}-${item.payerType}-${item.paymentMethod}-${item.paymentAmount}`}
                  data={item}
                  last={i === values.length - 1}
                />
              ))}
              <SectionFooter className="col-span-4" />
              <SectionFooter>Subtotals</SectionFooter>
              <SectionFooter align="right">{formatCurrency(subtotalMap[key] ?? 0)}</SectionFooter>
              {showPayerTypeTotal && (
                <>
                  <SectionFooter className="col-span-5">
                    {payerType === "PATIENT" ? "Total Patient Payments" : "Total Insurance Payments"}
                  </SectionFooter>
                  <SectionFooter align="right">
                    {payerType === "PATIENT"
                      ? formatCurrency(totals?.totalPatientPaymentAmount ?? 0)
                      : formatCurrency(totals?.totalInsurancePaymentAmount ?? 0)}
                  </SectionFooter>
                </>
              )}
            </React.Fragment>
          );
        })}
      </DashboardInfiniteTable>
    );
  }
);
