import { MedicalImageVO } from "@libs/api/generated-api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as RevertIcon } from "@libs/assets/icons/revert.svg";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ReactComponent as LeftIcon } from "@libs/assets/icons/left-chevron.svg";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import pearlSvgUrl from "assets/images/pearl-logo.svg";
import { LayersTool } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/LayersTool";

import {
  TransformToolProps,
  TransformToolboat,
} from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/TransformToolboat";
import { FiltersToolboat } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/FiltersToolboat";
import { DrawingToolBoats } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/DrawingToolboats";
import { ImagingToolbarFlyoverMenu } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ImagingToolbarFlyoverMenu";
import { DrawLayer } from "components/PatientProfile/Imaging/types/imageEditor";
import { PearlToolboat } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/PearlToolboat";
import { PearlConfig } from "components/PatientProfile/Imaging/types/pearl";
import { ToolBoat } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ToolBoat";
import { ToolbarButton } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ToolbarButton";
import { usePearlAnlaysisCounts } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/hooks/usePearlAnalysisCounts";
import { ImagingToolbarPortalDestination } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ImagingToolbarPortal";

type Props = {
  sectionsDisabled?: Set<"draw" | "filter" | "transform" | "revert">;
  onToggleLayer: (layer: DrawLayer) => void;
  layersShown: Set<DrawLayer>;
  imagesForAI: MedicalImageVO[];
  pearlConfig?: PearlConfig;
  onUpdatePearlConfig?: (config: Partial<PearlConfig>) => void;
  onMenuToggled?: (open: boolean) => void;
  isOnboardedWithPearl: boolean;
  onRevertChanges: () => void;
  pearlFlyoverContent: React.ReactNode;
  tipsFlyoverContent?: React.ReactNode;
  disabledMessage?: string;
  menuAnimation?: "flyover" | "slide-in";
} & TransformToolProps;
export const ImagingToolbar: React.FC<Props> = ({
  sectionsDisabled,
  onRotate,
  onMirror,
  onToggleLayer,
  layersShown,
  imagesForAI,
  onMenuToggled,
  disabledMessage,
  isOnboardedWithPearl,
  pearlConfig,
  onRevertChanges,
  pearlFlyoverContent,
  tipsFlyoverContent,
  onUpdatePearlConfig,
  menuAnimation = "slide-in",
}) => {
  const tipsFlyover = useBoolean(false);
  const pearlFlyover = useBoolean(false);

  const analysisCounts = usePearlAnlaysisCounts(imagesForAI);

  return (
    <div className="flex min-h-0 h-full print:hidden">
      <ImagingToolbarPortalDestination />
      <div
        className={`
          w-14
          flex
          flex-col
          bg-slate-800
          border-b-2
          border-black
          p-2
          gap-2
          overflow-y-auto
        `}
      >
        <LayersTool
          onToggleLayer={onToggleLayer}
          onMenuToggled={onMenuToggled}
          layersShown={layersShown}
          includePearlLayer={analysisCounts.done > 0 && isOnboardedWithPearl}
        />
        {isOnboardedWithPearl && (
          <PearlToolboat
            flyoverOpen={pearlFlyover.isOn || tipsFlyover.isOn}
            onFlyoverToggled={pearlFlyover.toggle}
            disabled={analysisCounts.done === 0}
            onUpdatePearlConfig={onUpdatePearlConfig}
            pearlConfig={pearlConfig}
            disabledMessage={disabledMessage}
          />
        )}

        <DrawingToolBoats onMenuToggled={onMenuToggled} disabledMessage={disabledMessage} />

        <FiltersToolboat onMenuToggled={onMenuToggled} disabledMessage={disabledMessage} />
        <TransformToolboat
          onMirror={onMirror}
          onRotate={onRotate}
          disabled={sectionsDisabled?.has("transform")}
        />
        <ToolBoat>
          <ToolbarButton
            SvgIcon={RevertIcon}
            size="md"
            tooltip={
              sectionsDisabled?.has("revert")
                ? "Select image first"
                : "Revert filters, annotations, and transformations"
            }
            onClick={onRevertChanges}
            disabled={sectionsDisabled?.has("revert")}
          />
        </ToolBoat>
        {tipsFlyoverContent && (
          <ButtonIcon
            className="mt-2 self-center"
            theme="darkBackground"
            SvgIcon={InfoIcon}
            onClick={tipsFlyover.toggle}
          />
        )}
      </div>
      {tipsFlyover.isOn ? (
        <ImagingToolbarFlyoverMenu animationType={menuAnimation} onClose={tipsFlyover.off}>
          {tipsFlyoverContent}
        </ImagingToolbarFlyoverMenu>
      ) : (
        pearlFlyover.isOn && (
          <ImagingToolbarFlyoverMenu
            animationType={menuAnimation}
            onClose={pearlFlyover.off}
            CloseIcon={LeftIcon}
            closeButtonClassName="bg-slate-600 rounded-full p-1"
            title={
              <div className="flex justify-center items-center text-xs gap-1">
                Powered by <img src={pearlSvgUrl} alt="Pearl Logo" />
              </div>
            }
          >
            {imagesForAI.length === 0 ? (
              <div className="text-xs text-center text-slate-400 flex items-center mt-5">
                There are no X-Rays here for analysis
              </div>
            ) : (
              pearlFlyoverContent
            )}
          </ImagingToolbarFlyoverMenu>
        )
      )}
    </div>
  );
};
