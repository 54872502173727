/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-magic-numbers */

import { MedicalImageVO } from "@libs/api/generated-api";
import { half } from "@libs/utils/math";
import _4_bw_2_pa from "assets/images/imaging/mounting-4bw-2pa.svg";
import _4bw from "assets/images/imaging/mounting-4bw.svg";
import pano from "assets/images/imaging/mounting-pano.svg";
import _2bw_pa from "assets/images/imaging/mounting-2bw-pa.svg";
import _4_pa from "assets/images/imaging/mounting-4pa.svg";
import _4_bw_pa from "assets/images/imaging/mounting-4bw-pa.svg";
import _4bw_4pa from "assets/images/imaging/mounting-4bw-4pa.svg";
import _2bw_2pa from "assets/images/imaging/mounting-2bw-2pa.svg";
import _2bw_2pa_1occ from "assets/images/imaging/mounting-2bw-1pa-1occ.svg";
import _2bw_3pa_1occ from "assets/images/imaging/mounting-2bw-3pa-1occ.svg";
import _4bw_3pa_1a from "assets/images/imaging/mounting-4bw-3pa-1pa.svg";

import fmx14 from "assets/images/imaging/mounting-fmx14.svg";
import fmx16 from "assets/images/imaging/mounting-fmx16.svg";
import fmx18 from "assets/images/imaging/mounting-fmx18.svg";
import fmxPerio from "assets/images/imaging/mounting-fmxperio.svg";
import gridIcon from "assets/images/imaging/mounting-grid.svg";
import perioBW from "assets/images/imaging/mounting-perio-bw.svg";

import { inflateImageSizeWithNoCollisions } from "components/PatientProfile/Imaging/PatientMountsList/defaultMountLayout";
import { MountLayoutType } from "api/imaging/imaging-api";

export const IMAGE_METADATA_HEIGHT = 30;

// See svg, we use them as the template for the mount dimensions
export const IMAGE_CELL_SIZE = {
  w: 180,
  h: 120,
};

export const IMAGE_ITEM_SIZE = {
  ...IMAGE_CELL_SIZE,
  h: IMAGE_CELL_SIZE.h + IMAGE_METADATA_HEIGHT,
};

// Items are initially placed in this focused area
export const FOCUSED_CANVAS_SIZE = {
  w: IMAGE_CELL_SIZE.w * 10,
  h: IMAGE_CELL_SIZE.h * 10,
};

// This is the total draggable/droppable area
const CANVAS_SIZE = {
  w: IMAGE_CELL_SIZE.w * 3 * 10,
  h: IMAGE_CELL_SIZE.h * 3 * 10,
};

// We disallow dragging items beyond this boundary
export const CANVAS_BOUNDS = {
  top: 0,
  left: 0,
  w: CANVAS_SIZE.w,
  h: CANVAS_SIZE.h,
};

// Translates SVG or size or coordinate into our canvas size
const translateX = (x: number) => (x * FOCUSED_CANVAS_SIZE.w) / IMAGE_CELL_SIZE.w;
const translateY = (y: number) => (y * FOCUSED_CANVAS_SIZE.h) / IMAGE_CELL_SIZE.h;

export type LayoutConfigType = {
  x: number;
  y: number;
  w: number;
  h: number;
  name?: string;
}[];

const centerItems = (totalLength: number, itemCount: number, itemLength: number) => {
  return (totalLength - itemCount * itemLength) / 2;
};

// Generates the grid view based on height and width provided
const generateGrid = () => {
  const itemAspect = 261 / 177;
  const w = FOCUSED_CANVAS_SIZE.w / 5;
  const h = w / itemAspect;
  const numRows = 4;
  const numColumns = 5;
  const border = 0;
  const values = [];
  const startY = CENTER_ORIGIN.y + centerItems(FOCUSED_CANVAS_SIZE.h, numRows, h + IMAGE_METADATA_HEIGHT);

  for (let i = 0; i < numRows; i++) {
    for (let j = 0; j < numColumns; j++) {
      values.push({
        x: CENTER_ORIGIN.x + j * (w - border),
        y: startY + i * (h - border) + i * IMAGE_METADATA_HEIGHT,
        w,
        h,
      });
    }
  }

  return values;
};

type Size = { w: number; h: number };

const CENTER_ORIGIN = {
  x: half(CANVAS_SIZE.w - FOCUSED_CANVAS_SIZE.w),
  y: half(CANVAS_SIZE.h - FOCUSED_CANVAS_SIZE.h),
};
const generateFMX14 = () => {
  // Values below are those provided in figma.  We then scale them to exactly fit the FOCUSED_CANVAS_SIZE
  // to allow for zooming out and in
  const itemSize = { w: translateX(35), h: translateY(23) };
  const rotatedSize = { h: itemSize.w, w: itemSize.h };
  const rowPadding = 6;
  const rowsSpacing = IMAGE_METADATA_HEIGHT + rowPadding;
  const outerSeparatorX = translateX(4);
  const innerItemsSpacing = IMAGE_METADATA_HEIGHT / 2;
  const startX =
    CENTER_ORIGIN.x +
    half(
      FOCUSED_CANVAS_SIZE.w - outerSeparatorX * 2 - innerItemsSpacing * 2 - itemSize.w * 2 - rotatedSize.w * 3
    );
  const startYOuter = CENTER_ORIGIN.y + half(FOCUSED_CANVAS_SIZE.h - rowsSpacing * 3 - itemSize.h * 4);
  const startYInner = CENTER_ORIGIN.y + half(FOCUSED_CANVAS_SIZE.h - rowsSpacing - rotatedSize.h * 2);

  const items: Size[] = [
    ...Array.from({ length: 4 }).fill(itemSize),
    ...Array.from({ length: 4 }).fill(itemSize),
    ...Array.from({ length: 6 }).fill(rotatedSize),
  ] as Size[];
  const innerItemX = Math.round(startX + itemSize.w + outerSeparatorX);
  const innerItemsIncrement = Math.round(rotatedSize.w + innerItemsSpacing);
  const rightOuterX = Math.round(innerItemX + rotatedSize.w * 3 + innerItemsSpacing * 2 + outerSeparatorX);
  const innerThreeItemsXValues = [
    innerItemX,
    innerItemX + innerItemsIncrement,
    innerItemX + innerItemsIncrement * 2,
  ];
  const xValues = [
    ...Array.from({ length: 4 }).fill(startX),
    ...Array.from({ length: 4 }).fill(rightOuterX),
    ...innerThreeItemsXValues,
    ...innerThreeItemsXValues,
  ] as number[];
  const outerYIncrement = Math.round(itemSize.h + rowsSpacing);
  const bottomYIncrement = Math.round(startYInner + rotatedSize.h + rowsSpacing);
  const outerYValues = [
    startYOuter,
    startYOuter + outerYIncrement,
    startYOuter + outerYIncrement * 2,
    startYOuter + outerYIncrement * 3,
  ];
  const yValues = [
    ...outerYValues,
    ...outerYValues,
    ...Array.from({ length: 3 }).fill(startYInner),
    ...Array.from({ length: 3 }).fill(bottomYIncrement),
  ] as number[];

  const names = [
    "Mx R Molar PA",
    "R Molar BW",
    "R Bicuspid PA",
    "Md R Molar PA",
    "Mx L Molar PA",
    "L Molar BW",
    "L Bicuspid PA",
    "Md L Molar PA",
    "Mx R Canine PA",
    "Mx Ant Central PA",
    "Mx L Canine PA",
    "Md R Canine PA",
    "Md Ant Central PA",
    "Md L Canine PA",
  ];

  return items.map((item, i) => {
    return {
      ...item,
      x: xValues[i],
      y: yValues[i],
      name: names[i],
    };
  });
};

const generatePano = () => {
  // Taken from SVG dimensions pano.svg:
  const aspect = 1512 / 717;
  const marginX = 16;

  const x = CENTER_ORIGIN.x + marginX;
  const w = FOCUSED_CANVAS_SIZE.w - marginX * 2;
  const h = w / aspect;

  const y = CENTER_ORIGIN.y + centerItems(FOCUSED_CANVAS_SIZE.h, 1, h + IMAGE_METADATA_HEIGHT);

  return [{ x, y, w, h }];
};
const generate2BWPA = () => {
  const itemAspect = 88 / 57;
  const marginX = 8;
  const w = half(FOCUSED_CANVAS_SIZE.w - marginX * 3);
  const h = w / itemAspect;
  const y = CENTER_ORIGIN.y + centerItems(FOCUSED_CANVAS_SIZE.h, 1, h + IMAGE_METADATA_HEIGHT);
  const startPosition = {
    x: CENTER_ORIGIN.x + marginX,
    y,
    w,
    h,
  };

  return [
    startPosition,
    {
      ...startPosition,
      x: CENTER_ORIGIN.x + FOCUSED_CANVAS_SIZE.w - marginX - w,
    },
  ];
};

const generate4BW = () => {
  const itemAspect = 37 / 27;
  const middleGap = (FOCUSED_CANVAS_SIZE.w * 64) / (IMAGE_CELL_SIZE.w * 2);
  const marginX = (FOCUSED_CANVAS_SIZE.w * 1.5) / IMAGE_CELL_SIZE.w;
  const w = (FOCUSED_CANVAS_SIZE.w - marginX * 4 - middleGap) / 4;
  const h = w / itemAspect;
  const y = CENTER_ORIGIN.y + centerItems(FOCUSED_CANVAS_SIZE.h, 1, h + IMAGE_METADATA_HEIGHT);
  const startPosition = {
    x: CENTER_ORIGIN.x + marginX,
    y,
    w,
    h,
  };

  return [
    startPosition,
    {
      ...startPosition,
      x: CENTER_ORIGIN.x + marginX * 2 + w,
    },
    {
      ...startPosition,
      x: CENTER_ORIGIN.x + marginX * 2 + w * 2 + middleGap,
    },
    {
      ...startPosition,
      x: CENTER_ORIGIN.x + marginX * 3 + w * 3 + middleGap,
    },
  ];
};
const generate4PA = () => {
  const marginX = FOCUSED_CANVAS_SIZE.w / IMAGE_ITEM_SIZE.w;
  const itemAspect = 44 / 57;
  const w = (FOCUSED_CANVAS_SIZE.w - marginX * 3) / 4;
  const h = w / itemAspect;
  const y = CENTER_ORIGIN.y + centerItems(FOCUSED_CANVAS_SIZE.h, 1, h + IMAGE_METADATA_HEIGHT);

  return Array.from({ length: 4 }).map((_, i) => {
    return {
      w,
      h,
      y,
      x: CENTER_ORIGIN.x + i * (w + marginX),
    };
  });
};

const generate4BWPA = () => {
  const itemAspect = 86 / 55;
  const marginX = translateX(2.5);
  const marginY = translateY(3.5);
  const middleGapX = translateX(3);
  const middleGapY = translateY(4);

  const w = half(FOCUSED_CANVAS_SIZE.w - marginX * 2 - middleGapX);
  const h = w / itemAspect;
  const startPosition = {
    x: CENTER_ORIGIN.x + marginX,
    y: CENTER_ORIGIN.y + marginY,
    h,
    w,
  };

  return [
    startPosition,
    {
      ...startPosition,
      x: CENTER_ORIGIN.x + marginX + w + middleGapX,
    },
    {
      ...startPosition,
      y: CENTER_ORIGIN.y + marginY + h + middleGapY,
    },
    {
      ...startPosition,
      x: CENTER_ORIGIN.x + marginX + w + middleGapX,
      y: CENTER_ORIGIN.y + marginY + h + middleGapY,
    },
  ];
};

const generateBWRow = ({ bitewings, middleGap }: { bitewings: 2 | 4; middleGap: number }) => {
  const itemSize = { w: translateX(35), h: translateY(23) };
  const rotatedSize = { w: itemSize.h, h: itemSize.w };
  const cellPadding = {
    x: translateX(2),
    y: IMAGE_METADATA_HEIGHT + translateY(1),
  };

  const middleRowStartX = half(FOCUSED_CANVAS_SIZE.w - cellPadding.x * 2 - middleGap - itemSize.w * 4);
  const middleRowStartY = CENTER_ORIGIN.y + centerItems(FOCUSED_CANVAS_SIZE.h, 1, itemSize.h);
  const startRow = {
    x: middleRowStartX,
    y: middleRowStartY,
    w: itemSize.w,
    h: itemSize.h,
  };
  const row = [
    { ...startRow, x: CENTER_ORIGIN.x + middleRowStartX },
    {
      ...startRow,
      x: CENTER_ORIGIN.x + middleRowStartX + itemSize.w + cellPadding.x,
    },
    {
      ...startRow,
      x: CENTER_ORIGIN.x + FOCUSED_CANVAS_SIZE.w - itemSize.w * 2 - cellPadding.x - middleRowStartX,
    },
    {
      ...startRow,
      x: CENTER_ORIGIN.x + FOCUSED_CANVAS_SIZE.w - itemSize.w - middleRowStartX,
    },
  ];

  return {
    rotatedSize,
    cellPadding,
    row: bitewings === 2 ? [row[1], row[2]] : row,
  };
};
const generateBW = ({ bitewings, occ }: { bitewings: 2 | 4; occ?: boolean }) => {
  const middleGap = occ ? translateX(39) : translateX(30);
  const { rotatedSize, row } = generateBWRow({ bitewings, middleGap });

  const columnX = CENTER_ORIGIN.x + half(FOCUSED_CANVAS_SIZE.w - rotatedSize.w);
  const colStartY = half(FOCUSED_CANVAS_SIZE.h - rotatedSize.h * 2 - middleGap);
  const startCol = {
    x: columnX,
    y: CENTER_ORIGIN.y + colStartY,
    w: rotatedSize.w,
    h: rotatedSize.h,
  };
  const column = [
    startCol,
    {
      ...(occ
        ? {
            ...startCol,
            x: CENTER_ORIGIN.x + centerItems(FOCUSED_CANVAS_SIZE.w, 1, rotatedSize.h),
            w: rotatedSize.h,
            h: rotatedSize.w,
          }
        : startCol),
      y: CENTER_ORIGIN.y + FOCUSED_CANVAS_SIZE.h - colStartY - rotatedSize.h,
    },
  ];

  return [...row, ...column];
};

const generate4BW_3PA = ({ occ }: { occ: boolean }) => {
  const middleGap = occ ? translateX(73) : translateX(30);
  const { rotatedSize, row, cellPadding } = generateBWRow({
    bitewings: occ ? 2 : 4,
    middleGap,
  });
  const containerPaddingY = translateY(10.5);
  const topRow = [
    {
      ...rotatedSize,
      y: CENTER_ORIGIN.y + containerPaddingY - cellPadding.y,
      x: CENTER_ORIGIN.x + centerItems(FOCUSED_CANVAS_SIZE.w, 1, rotatedSize.w * 3 + cellPadding.x * 2),
    },
  ];

  for (let i = 1; i < 3; i++) {
    const lastRow = topRow[i - 1];

    topRow.push({
      ...lastRow,
      x: lastRow.x + rotatedSize.w + cellPadding.x,
    });
  }

  const bottomItem = {
    ...topRow[1],
    y: CENTER_ORIGIN.y + FOCUSED_CANVAS_SIZE.h - containerPaddingY - rotatedSize.h,
  };

  return [...topRow, bottomItem, ...row];
};
const generate4BW4PA = () => {
  const middleGap = translateX(30);

  const { rotatedSize, row, cellPadding } = generateBWRow({
    bitewings: 4,
    middleGap,
  });

  const colStartY = half(FOCUSED_CANVAS_SIZE.h - 2 * rotatedSize.h - middleGap);
  const columnX = CENTER_ORIGIN.x + half(FOCUSED_CANVAS_SIZE.w - 2 * rotatedSize.w - cellPadding.x);
  const bottomY = CENTER_ORIGIN.y + FOCUSED_CANVAS_SIZE.h - colStartY - rotatedSize.h;
  const startCol = {
    x: columnX,
    y: CENTER_ORIGIN.y + colStartY,
    w: rotatedSize.w,
    h: rotatedSize.h,
  };
  const column = [
    startCol,
    {
      ...startCol,
      x: columnX + cellPadding.x + rotatedSize.w,
    },
    {
      ...startCol,
      y: bottomY,
    },
    {
      ...startCol,
      x: columnX + cellPadding.x + rotatedSize.w,
      y: bottomY,
    },
  ];

  return [...row, ...column];
};

const generateFMX16 = () => {
  const xSpacingMain = translateX(2);
  const ySpacing = IMAGE_METADATA_HEIGHT + 16;
  const cellSize = { w: translateX(23), h: translateY(35) };
  const rotatedSize = { h: cellSize.w, w: cellSize.h };
  const startX = half(FOCUSED_CANVAS_SIZE.w - 7 * cellSize.w - xSpacingMain * 6);
  const topRowStartY = half(FOCUSED_CANVAS_SIZE.h - 2 * cellSize.h - rotatedSize.h - ySpacing * 2);
  const topRow = Array.from({ length: 7 }).map((_, i) => {
    return {
      x: CENTER_ORIGIN.x + startX + i * (cellSize.w + xSpacingMain),
      y: CENTER_ORIGIN.y + topRowStartY,
      ...cellSize,
    };
  });
  const middleRowY = CENTER_ORIGIN.y + half(FOCUSED_CANVAS_SIZE.h - rotatedSize.h);
  const startMiddle = {
    x: CENTER_ORIGIN.x + startX,
    y: middleRowY,
    ...rotatedSize,
  };
  const middleRow = [
    startMiddle,
    {
      ...startMiddle,
      x: CENTER_ORIGIN.x + FOCUSED_CANVAS_SIZE.w - startX - rotatedSize.w,
    },
  ];
  const bottomRowStartY = CENTER_ORIGIN.y + FOCUSED_CANVAS_SIZE.h - topRowStartY - cellSize.h;
  const bottomRow = Array.from({ length: 7 }).map((_, i) => {
    return {
      x: CENTER_ORIGIN.x + startX + i * (cellSize.w + xSpacingMain),
      y: bottomRowStartY,
      ...cellSize,
    };
  });

  return [...topRow, ...middleRow, ...bottomRow];
};

const generateFMX18 = () => {
  const itemSize = { w: translateX(26), h: translateY(17) };
  const rotatedSize = { h: itemSize.w, w: itemSize.h };
  const rowPadding = 6;
  const cellPaddingX = IMAGE_METADATA_HEIGHT / 2;
  const cellPaddingY = IMAGE_METADATA_HEIGHT + rowPadding;
  const innerContentPadding = cellPaddingX * 2;

  const outerItemsStart = {
    x: half(
      FOCUSED_CANVAS_SIZE.w - cellPaddingX * 4 - innerContentPadding * 2 - itemSize.w * 4 - rotatedSize.w * 3
    ),
    y: half(FOCUSED_CANVAS_SIZE.h - cellPaddingY * 2 - itemSize.h * 3),
  };

  // Outer items - first six are the items on the left, second six are the items on the right
  const outerItems = [];

  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < 2; j++) {
      outerItems.push({
        x: CENTER_ORIGIN.x + outerItemsStart.x + j * (itemSize.w + cellPaddingX),
        y: CENTER_ORIGIN.y + outerItemsStart.y + i * (itemSize.h + cellPaddingY),
        ...itemSize,
      });
    }
  }
  for (let i = 0; i < 3; i++) {
    for (let j = 0; j < 2; j++) {
      outerItems.push({
        x:
          CENTER_ORIGIN.x +
          FOCUSED_CANVAS_SIZE.w -
          outerItemsStart.x -
          itemSize.w -
          j * (itemSize.w + (j === 0 ? 0 : cellPaddingX)),
        y: CENTER_ORIGIN.y + outerItemsStart.y + i * (itemSize.h + cellPaddingY),
        ...itemSize,
      });
    }
  }

  const middleGap = translateY(40);

  const innerItemStart = {
    x: half(FOCUSED_CANVAS_SIZE.w - cellPaddingX * 2 - rotatedSize.w * 3),
    y: half(FOCUSED_CANVAS_SIZE.h - middleGap - rotatedSize.h * 2),
  };

  const topRow = Array.from({ length: 3 }).map((_, i) => ({
    x: CENTER_ORIGIN.x + innerItemStart.x + (cellPaddingX + rotatedSize.w) * i,
    y: CENTER_ORIGIN.y + innerItemStart.y,
    ...rotatedSize,
  }));
  const bottomRow = topRow.map((item) => ({
    ...item,
    y: CENTER_ORIGIN.y + FOCUSED_CANVAS_SIZE.h - innerItemStart.y - rotatedSize.h,
  }));

  // Now we have the layout, but need to redefine the order (see GF-3116 for more info)
  const [one, two, fifteen, sixteen, seven, eight, five, six, eighteen, seventeen, three, four] = outerItems;
  const [nine, ten, eleven] = topRow;
  const [fourteen, thirteen, twelve] = bottomRow;

  return [
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelve,
    thirteen,
    fourteen,
    fifteen,
    sixteen,
    seventeen,
    eighteen,
  ];
};

const generateFMXPerio = () => {
  const cellPadding = translateX(1);
  const xContainerPadding = { y: translateY(15.5), x: translateX(5.5) };
  const landscapeCell = { w: translateX(26), h: translateY(17) };
  const portraitCell = { w: translateX(17), h: translateY(26) };

  const portraitCells = [
    {
      y: CENTER_ORIGIN.y + xContainerPadding.y,
      x: CENTER_ORIGIN.x + centerItems(FOCUSED_CANVAS_SIZE.w, 1, portraitCell.w * 3 + cellPadding * 2),
      ...portraitCell,
    },
  ];

  for (let i = 1; i < 3; i++) {
    const prevCell = portraitCells[i - 1];

    portraitCells.push({
      ...prevCell,
      x: portraitCells[i - 1].x + portraitCell.w + cellPadding,
    });
  }
  for (let i = 0; i < 3; i++) {
    const mirrorCell = portraitCells[i];

    portraitCells.push({
      ...mirrorCell,
      y: CENTER_ORIGIN.y + FOCUSED_CANVAS_SIZE.h - xContainerPadding.y - portraitCell.h,
    });
  }

  const yStart =
    CENTER_ORIGIN.y +
    centerItems(FOCUSED_CANVAS_SIZE.h, 1, portraitCell.h + landscapeCell.h * 2 + cellPadding * 2);
  const leftSideCells = [
    {
      x: CENTER_ORIGIN.x + xContainerPadding.x,
      y: yStart,
      ...landscapeCell,
    },
    {
      x: CENTER_ORIGIN.x + landscapeCell.w + xContainerPadding.x + cellPadding,
      y: yStart,
      ...landscapeCell,
    },
  ];

  for (let i = 0; i < 3; i++) {
    leftSideCells.push({
      ...portraitCell,
      y: leftSideCells[0].y + landscapeCell.h + cellPadding + IMAGE_METADATA_HEIGHT,
      x: leftSideCells[0].x + (portraitCell.w + cellPadding) * i,
    });
  }
  for (let i = 0; i < 2; i++) {
    leftSideCells.push({
      ...leftSideCells[i],
      y: leftSideCells[i].y + portraitCell.h + landscapeCell.h + (cellPadding + IMAGE_METADATA_HEIGHT) * 2,
    });
  }

  const rightSideCells = leftSideCells.map((cell) => {
    return {
      ...cell,
      x: CENTER_ORIGIN.x + FOCUSED_CANVAS_SIZE.w - (cell.x + cell.w - CENTER_ORIGIN.x),
    };
  });
  const [one, two, fifteen, sixteen, seventeen, seven, eight] = leftSideCells;
  const [five, six, eighteen, nineteen, twenty, three, four] = rightSideCells;
  const [nine, ten, eleven, fourteen, thirteen, twelve] = portraitCells;

  return [
    one,
    two,
    three,
    four,
    five,
    six,
    seven,
    eight,
    nine,
    ten,
    eleven,
    twelve,
    thirteen,
    fourteen,
    fifteen,
    sixteen,
    seventeen,
    eighteen,
    nineteen,
    twenty,
  ];
};

const generatePerioBW = () => {
  const xSpacingMain = translateX(1);
  const xContainerPadding = translateX(6.5);
  const cellSize = { w: translateX(23), h: translateY(35) };
  const y = CENTER_ORIGIN.y + centerItems(FOCUSED_CANVAS_SIZE.h, 1, cellSize.h);
  const firstCell = {
    x: CENTER_ORIGIN.x + xContainerPadding,
    y,
    ...cellSize,
  };
  const leftCells = [firstCell];

  for (let i = 0; i < 2; i++) {
    leftCells.push({
      ...firstCell,
      x: firstCell.x + (i + 1) * (cellSize.w + xSpacingMain),
    });
  }

  const rightCells = [];
  const lastCell = {
    ...firstCell,
    x: CENTER_ORIGIN.x + FOCUSED_CANVAS_SIZE.w - xContainerPadding - cellSize.w,
  };

  rightCells.push(lastCell);

  for (let i = 0; i < 2; i++) {
    rightCells.push({
      ...lastCell,
      x: lastCell.x - (i + 1) * (cellSize.w + xSpacingMain),
    });
  }

  return [...leftCells, ...rightCells.reverse()];
};

export type MountLayoutConfig = {
  icon: string;
  type: MountLayoutType;
  layout: LayoutConfigType;
  label: string;
};
export const layoutConfigMap: Record<MountLayoutType, MountLayoutConfig | undefined> = {
  Pano: {
    icon: pano,
    type: "Pano",
    layout: generatePano(),
    label: "Pano",
  },
  "2 BW/PA": {
    icon: _2bw_pa,
    type: "2 BW/PA",
    layout: generate2BWPA(),
    label: "2 BW/PA",
  },
  "4 BW": {
    icon: _4bw,
    type: "4 BW",
    layout: generate4BW(),
    label: "4 BW",
  },
  "4 PA": {
    icon: _4_pa,
    type: "4 PA",
    layout: generate4PA(),
    label: "4 PA",
  },
  "4 BW/PA": {
    icon: _4_bw_pa,
    type: "4 BW/PA",
    layout: generate4BWPA(),
    label: "4 BW/PA",
  },
  "4 BW 2 PA": {
    type: "4 BW 2 PA",
    icon: _4_bw_2_pa,
    layout: generateBW({ bitewings: 4 }),
    label: "4 BW, 2 PA",
  },
  "4 BW 4 PA": {
    icon: _4bw_4pa,
    type: "4 BW 4 PA",
    layout: generate4BW4PA(),
    label: "4 BW, 4 PA",
  },
  "4 BW 3 PA 1 PA": {
    icon: _4bw_3pa_1a,
    type: "4 BW 3 PA 1 PA",
    layout: generate4BW_3PA({ occ: false }),
    label: "4 BW, 4 PA",
  },
  "FMX 14": {
    icon: fmx14,
    type: "FMX 14",
    layout: generateFMX14(),
    label: "FMX 14",
  },
  "FMX 16": {
    icon: fmx16,
    type: "FMX 16",
    layout: generateFMX16(),
    label: "FMX 16",
  },
  "FMX 18": {
    icon: fmx18,
    type: "FMX 18",
    layout: generateFMX18(),
    label: "FMX 18",
  },
  "FMX Perio": {
    type: "FMX Perio",
    layout: generateFMXPerio(),
    label: "FMX Perio",
    icon: fmxPerio,
  },
  ["Grid"]: {
    type: "Grid",
    layout: generateGrid(),
    label: "Grid",
    icon: gridIcon,
  },
  ["2 BW 2 PA"]: {
    type: "2 BW 2 PA",
    layout: generateBW({ bitewings: 2 }),
    label: "2 BW, 2 PA",
    icon: _2bw_2pa,
  },
  ["2 BW 1 PA 1 OCC"]: {
    type: "2 BW 1 PA 1 OCC",
    layout: generateBW({ bitewings: 2, occ: true }),
    label: "2 BW, 1 PA, 1 OCC",
    icon: _2bw_2pa_1occ,
  },
  ["2 BW 3 PA 1 OCC"]: {
    type: "2 BW 3 PA 1 OCC",
    label: "2 BW, 3 PA, 1 OCC",
    layout: generate4BW_3PA({ occ: true }),
    icon: _2bw_3pa_1occ,
  },
  ["Perio BW"]: {
    type: "Perio BW",
    layout: generatePerioBW(),
    label: "Perio BW",
    icon: perioBW,
  },
};
export const getLayoutForLayoutName = (
  layoutType?: string, // migrated practices may have any arbitrary mount layout type
  images?: MedicalImageVO[]
): MountLayoutConfig => {
  const layout = layoutType ? layoutConfigMap[layoutType as MountLayoutType] : undefined;
  const loadingLayout = layoutConfigMap["Grid"] as MountLayoutConfig;

  if (layoutType) {
    if (!layout) {
      if (!images || images.length === 0) {
        return loadingLayout;
      }

      // Because we have no layout defined for this image, we use the image width/height
      // and inflate it to fit the canvas without any collisions
      const inflationCoefficient = inflateImageSizeWithNoCollisions(images);

      const fallback = {
        layout: images.map((item) => {
          return {
            x: item.x,
            y: item.y,
            w: (!item.w || item.w === 0 ? IMAGE_CELL_SIZE.w : item.w) * inflationCoefficient,
            h: (!item.h || item.h === 0 ? IMAGE_CELL_SIZE.h : item.h) * inflationCoefficient,
          };
        }),
        icon: "",
      } as MountLayoutConfig;

      return fallback;
    }

    return layout;
  }

  return loadingLayout;
};

export const basicMounts = [
  layoutConfigMap["Grid"],
  layoutConfigMap["Pano"],
  layoutConfigMap["2 BW/PA"],
  layoutConfigMap["Perio BW"],
] as MountLayoutConfig[];

export const basicMounts2 = [
  layoutConfigMap["4 BW"],
  layoutConfigMap["4 PA"],
  layoutConfigMap["4 BW/PA"],
] as MountLayoutConfig[];

export const bitewingMounts = [
  layoutConfigMap["2 BW 2 PA"],
  layoutConfigMap["2 BW 1 PA 1 OCC"],
  layoutConfigMap["2 BW 3 PA 1 OCC"],
  layoutConfigMap["4 BW 2 PA"],
  layoutConfigMap["4 BW 4 PA"],
  layoutConfigMap["4 BW 3 PA 1 PA"],
] as MountLayoutConfig[];

export const fmxMounts = [
  layoutConfigMap["FMX 14"],
  layoutConfigMap["FMX 18"],
  layoutConfigMap["FMX 16"],
  layoutConfigMap["FMX Perio"],
] as MountLayoutConfig[];

export const mountLayoutList = [...basicMounts, ...basicMounts2, ...bitewingMounts, ...fmxMounts];

const sanitizeLayouts = () => {
  for (const key in layoutConfigMap) {
    const layoutItem = layoutConfigMap[key as MountLayoutType];

    if (layoutItem) {
      for (const rect of layoutItem.layout) {
        rect.x = Math.round(rect.x);
        rect.y = Math.round(rect.y);
      }
    }
  }
};

sanitizeLayouts();
