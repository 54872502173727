import {
  CreateCustomAdjustmentTypeRequest,
  CreatePaymentDeviceRequest,
  CustomAdjustmentTypeVO,
  PaymentDeviceVO,
  UpdatePaymentDeviceRequest,
} from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import {
  updateCachedListWithUpdatedItem,
  updateCachedListWithDeletedItem,
  updateCachedListWithCreatedItem,
} from "@libs/utils/queryCache";

export const createPaymentDevice = makeMutation({
  mutationKey: ["practices", "createPaymentDevice"],
  formatParams: (args: { practiceId: number; data: CreatePaymentDeviceRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      // Don't use `updateCachedListWithCreatedItem()` because the POS's state
      // will likely be "CREATED" which might not be desirable to include in
      // cached POS lists if filters were used.
      queryClient.invalidateQueries([getQueryKey("practices", "listPaymentDevices"), { practiceId }]);
    },
  }),
});

export const updatePaymentDevice = makeMutation({
  mutationKey: ["practices", "updatePaymentDevice"],
  formatParams: (args: {
    practiceId: number;
    paymentDeviceUuid: string;
    data: UpdatePaymentDeviceRequest;
  }) => [args.practiceId, args.paymentDeviceUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId }) => {
      updateCachedListWithUpdatedItem(
        queryClient,
        { queryKey: [getQueryKey("practices", "listPaymentDevices"), { practiceId }] },
        data.data.data,
        "uuid"
      );
    },
  }),
});

export const deletePaymentDevice = makeMutation({
  mutationKey: ["practices", "deletePaymentDevice"],
  formatParams: (args: { practiceId: number; paymentDeviceUuid: string }) => [
    args.practiceId,
    args.paymentDeviceUuid,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, paymentDeviceUuid }) => {
      updateCachedListWithDeletedItem<PaymentDeviceVO>()(
        queryClient,
        { queryKey: [getQueryKey("practices", "listPaymentDevices"), { practiceId }] },
        "uuid",
        paymentDeviceUuid
      );
    },
  }),
});

export const activatePaymentDevice = makeMutation({
  mutationKey: ["practices", "activatePaymentDevice"],
  formatParams: (args: { practiceId: number; deviceUuid: string }) => [args.practiceId, args.deviceUuid],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      // Don't use `updateCachedListWithUpdatedItem()` because changing the
      // POS's state might include/exclude it from the POS list if filters were
      // used.
      queryClient.invalidateQueries([getQueryKey("practices", "listPaymentDevices"), { practiceId }]);
    },
  }),
});

export const deactivatePaymentDevice = makeMutation({
  mutationKey: ["practices", "deactivatePaymentDevice"],
  formatParams: (args: { practiceId: number; deviceUuid: string }) => [args.practiceId, args.deviceUuid],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      // Don't use `updateCachedListWithUpdatedItem()` because changing the
      // POS's state might include/exclude it from the POS list if filters were
      // used.
      queryClient.invalidateQueries([getQueryKey("practices", "listPaymentDevices"), { practiceId }]);
    },
  }),
});

export const createAdjustmentType = makeMutation({
  mutationKey: ["practices", "createAdjustmentType"],
  formatParams: (args: { practiceId: number; data: CreateCustomAdjustmentTypeRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId }) => {
      updateCachedListWithCreatedItem({
        queryClient,
        queryFilters: {
          queryKey: [getQueryKey("practices", "getAdjustmentTypesForPractice"), { practiceId }],
        },
        newItem: data.data.data,
        sortOn: "name",
      });
    },
  }),
});

export const archiveAdjustmentType = makeMutation({
  mutationKey: ["practices", "archiveAdjustmentType"],
  formatParams: (args: { practiceId: number; adjustmentTypeId: number }) => [
    args.practiceId,
    args.adjustmentTypeId,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId, adjustmentTypeId }) => {
      updateCachedListWithDeletedItem<CustomAdjustmentTypeVO>()(
        queryClient,
        { queryKey: [getQueryKey("practices", "getAdjustmentTypesForPractice"), { practiceId }] },
        "id",
        adjustmentTypeId
      );
    },
  }),
});
