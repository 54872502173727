import {
  EmployeeConsolidationRequest,
  GustoPracticeFlowRequest,
  GustoEmployeeFlowRequest,
  UpdatePayrollLineItemRequest,
} from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";

export const onboardNewPractice = makeMutation({
  mutationKey: ["practices", "onboardNewPractice"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getCompanyOnboardingStatus"), { practiceId }]);
    },
  }),
});

export const gustoLinkPractice = makeMutation({
  mutationKey: ["practices", "gustoLinkPractice"],
  formatParams: (args: { practiceId: number; code: string }) => [
    args.practiceId,
    { authorizationCode: args.code },
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getCompanyOnboardingStatus"), { practiceId }]);
    },
  }),
});

export const migrateCompany = makeMutation({
  mutationKey: ["practices", "migrateCompany"],
  formatParams: (args: { practiceId: number; data: EmployeeConsolidationRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getCompanyOnboardingStatus"), { practiceId }]);
    },
  }),
});

export const createPracticeFlow = makeMutation({
  mutationKey: ["practices", "createPracticeFlow"],
  formatParams: (args: { practiceId: number; data: GustoPracticeFlowRequest }) => [
    args.practiceId,
    args.data,
  ],
});

export const createEmployeeFlow = makeMutation({
  mutationKey: ["practices", "createEmployeeFlow"],
  formatParams: (args: { practiceId: number; employeeId: number; data: GustoEmployeeFlowRequest }) => [
    args.practiceId,
    args.employeeId,
    args.data,
  ],
});

export const updateLineItem = makeMutation({
  mutationKey: ["practices", "updateLineItem"],
  formatParams: (args: {
    payrollId: string;
    practiceId: number;
    employeeId: number;
    lineItem: UpdatePayrollLineItemRequest;
  }) => [args.practiceId, args.employeeId, args.payrollId, args.lineItem],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, payrollId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getPayrolls"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPayroll"), { practiceId, payrollId }]);
    },
  }),
});

export const calculatePayroll = makeMutation({
  mutationKey: ["practices", "calculatePayroll"],
  formatParams: (args: { payrollId: string; practiceId: number }) => [args.practiceId, args.payrollId],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, payrollId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getPayrolls"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPayroll"), { practiceId, payrollId }]);
    },
  }),
});

export const submitPayroll = makeMutation({
  mutationKey: ["practices", "submitPayroll"],
  formatParams: (args: { payrollId: string; practiceId: number }) => [args.practiceId, args.payrollId],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, payrollId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getPayrolls"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPayroll"), { practiceId, payrollId }]);
    },
  }),
});

export const cancelPayroll = makeMutation({
  mutationKey: ["practices", "cancelPayroll"],
  formatParams: (args: { payrollId: string; practiceId: number }) => [args.practiceId, args.payrollId],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, payrollId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getPayrolls"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPayroll"), { practiceId, payrollId }]);
    },
  }),
});
