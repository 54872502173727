import { FC, useId, useMemo, useCallback } from "react";
import { useNavigate } from "react-router-dom";

import { PracticeVO, EmployeeVO } from "@libs/api/generated-api";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useAccount } from "@libs/contexts/AccountContext";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";

import { ModalPage } from "components/UI/ModalPage";
import { SetupTwilioProgress } from "components/Settings/Communications/MessagingService/SetupTwilioProgress";
import { PracticeDetailsForm } from "components/Settings/Communications/MessagingService/PracticeDetailsForm";
import { OwnerContactDetails } from "components/Settings/Communications/MessagingService/OwnerContactDetails";

import { updatePractice } from "api/practice/mutations";
import { initTwilioSubaccountAndProfile } from "api/communications/mutations";
import { handleError } from "utils/handleError";
import { paths } from "utils/routing/paths";
import { usePracticeLogger } from "components/Main/PracticeSegmentContext";

interface Props {
  practice: PracticeVO;
  owner: EmployeeVO;
  hasInitiated: boolean;
  hasPhoneNumber: boolean;
  from: string;
}

export const SetupTwilioPractice: FC<Props> = ({ practice, owner, hasInitiated, hasPhoneNumber, from }) => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const formId = useId();
  const formDirty = useBoolean(false);
  const confirming = useBoolean(false);
  const setConfirming = confirming.set;
  const { track } = usePracticeLogger();

  const promptMessage = useMemo(
    () => (formDirty.isOn ? "Do you want to discard your changes to the practice?" : undefined),
    [formDirty.isOn]
  );

  const navigateToSetupPhone = useCallback(() => {
    navigate(paths.setupTwilio({ step: "phone" }, { from }), { replace: true });
  }, [navigate, from]);

  const [updatePracticeMutation, initTwilioSubaccountAndProfileMutation] = useApiMutations([
    updatePractice,
    initTwilioSubaccountAndProfile,
  ]);
  const updatePracticeMutateAsync = updatePracticeMutation.mutateAsync;
  const initTwilioSubaccountAndProfileMutateAsync = initTwilioSubaccountAndProfileMutation.mutateAsync;

  const handleSubmit = useCallback(
    async (updatedPractice?: PracticeVO) => {
      // If the practice has already initiated a Twilio subaccount, then we can
      // skip the requests and navigate to the phone step when clicking next
      if (hasInitiated) {
        navigateToSetupPhone();

        return;
      }

      setConfirming(true);

      try {
        // If there have been changes to the practice, then we need to update
        // before initiating the Twilio subaccount and profile, since the BE
        // references its data for sending to Twilio
        if (updatedPractice) {
          await updatePracticeMutateAsync({
            practiceId,
            data: updatedPractice,
          });
        }

        await initTwilioSubaccountAndProfileMutateAsync({
          practiceId,
        });
        track({ event: "Twilio Subaccount Initialized ", domains: ["Settings"] });

        navigateToSetupPhone();
      } catch (error) {
        handleError(error);
      } finally {
        setConfirming(false);
      }
    },
    [
      hasInitiated,
      setConfirming,
      navigateToSetupPhone,
      initTwilioSubaccountAndProfileMutateAsync,
      practiceId,
      track,
      updatePracticeMutateAsync,
    ]
  );

  return (
    <ModalPage
      title={<span className="font-sansSemiBold">Number Selection</span>}
      actions={
        <div className="flex items-center justify-center gap-x-2">
          <ButtonInternalLink className="min-w-button" to={from} theme="secondary" replace>
            Cancel
          </ButtonInternalLink>
          <AsyncButton className="min-w-button" isLoading={confirming.isOn} form={formId} type="submit">
            Next
          </AsyncButton>
        </div>
      }
      promptMessage={promptMessage}
      closeLink={from}
    >
      <div className="h-full p-6 overflow-y-auto">
        <div className="flex flex-col gap-y-6 max-w-xl mx-auto">
          <SetupTwilioProgress step="practice" hasInitiated={hasInitiated} hasPhoneNumber={hasPhoneNumber} />

          <PracticeDetailsForm
            formId={formId}
            practice={practice}
            hasInitiated={hasInitiated}
            onSubmit={handleSubmit}
            onDirty={formDirty.on}
          />

          <OwnerContactDetails owner={owner} hasInitiated={hasInitiated} />
        </div>
      </div>
    </ModalPage>
  );
};
