import { FC } from "react";
import { cx } from "@libs/utils/cx";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus.svg";
import { AddElement, CurrentElement } from "components/Settings/Forms/types";
import { getAddingElementAtLocation, isElementBeingAdded } from "components/Settings/Forms/utils";
import { DraftElementStructure } from "components/Settings/Forms/FormStructureElement";

interface Props {
  onEditElement: (addElement: AddElement) => void;
  isLast?: boolean;
  isDragging: boolean;
  index: number;
  sectionIndex?: number;
  currentElement: CurrentElement | undefined;
  placeholderElement: CurrentElement | undefined;
}

export const ElementButtonInsert: FC<Props> = ({
  isLast,
  isDragging,
  index,
  sectionIndex,
  currentElement,
  placeholderElement,
  onEditElement,
}) => {
  const elementToBeAddedHere = getAddingElementAtLocation(
    currentElement,
    placeholderElement,
    index,
    sectionIndex
  );
  const elementBeingAdded = isElementBeingAdded(currentElement, placeholderElement);

  return isLast ? (
    // This placeholder needs to be here so that dragging to sort
    // still gets even spacing
    <div className="h-4 w-full" />
  ) : elementToBeAddedHere ? (
    <div className="my-4">
      <DraftElementStructure draftElement={elementToBeAddedHere} />
    </div>
  ) : (
    <div className={cx("relative h-4", !isDragging && "group")}>
      <button
        type="button"
        disabled={elementBeingAdded}
        onClick={() => onEditElement({ flow: "ADD", index, sectionIndex })}
        className={cx(
          `bg-primaryTheme
           text-white
           rounded-full
           gap-x-1
           items-center
           justify-center
           h-6
           w-6
           flex
           absolute
           left-1/2
           -translate-x-1/2
           top-1/2
           -translate-y-1/2
           opacity-0`,
          !elementBeingAdded && "group-hover:opacity-100 group-hover:transition-opacity group-hover:delay-100"
        )}
      >
        <PlusIcon className="fill-white transition-transform w-4 h-4" />
      </button>
    </div>
  );
};
