/* eslint-disable complexity */
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { toPng } from "html-to-image";
import { millisecondsToSeconds, parseISO } from "date-fns";
import { ClaimVO } from "@libs/api/generated-api";
import { formatISODate, SECOND_IN_MS } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { RadioList } from "@libs/components/UI/RadioList";
import { PerioChartProvider, usePerioChart } from "components/Charting/Perio/PerioChartContext";
import { PerioChartExamRecord } from "components/Charting/Perio/perioChartExamRecord";
import { ActiveProviderProvider } from "components/Charting/ActiveProviderContext";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { LoadingView } from "components/Claim/Attachments/LoadingView";
import { PerioChart as PerioChartComponent } from "components/Charting/Perio/PerioChart";
import { ExternalUpload } from "components/Claim/Attachments/ExternalUpload";
import { OFFSCREEN_RENDER_ORIGIN } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/shapeUtils";
import { PerioChart, useAttachments } from "./ClaimAttachmentsContext";

interface Props {
  claim: ClaimVO;
}

const PerioChartContainer: React.FC<Props> = ({ claim }) => {
  const { setPerioCharts, perioCharts } = useAttachments();
  const { loadExam, historicalExamInfos, isLoading, examUuid } = usePerioChart();
  const [useArchyCharts, setUseArchyCharts] = useState<boolean | null>(null);
  const hasHistoricalExams = Boolean(!isLoading && historicalExamInfos?.length);
  const [selectedArchyChart, setSelectedArchyChart] = useState<PerioChart | undefined>(undefined);
  const [selectedExternalChart, setSelectedExternalChart] = useState<PerioChart | undefined>(undefined);
  // track date of external uploaded perio chart - archy perio charts already
  // have dates associated with them
  const [examDate, setExamDate] = useState(
    selectedExternalChart
      ? new Date(selectedExternalChart.sourceCreatedAt)
      : new Date(formatISODate(claim.serviceDate))
  );
  const hasChart =
    perioCharts && perioCharts.length > 0 && Boolean(perioCharts[0]?.sourceUuid) && !selectedArchyChart;
  const perioChartRef = useRef<HTMLDivElement>(null);
  const chartToRender = selectedArchyChart || (perioCharts?.[0]?.sourceUuid ? perioCharts[0] : undefined);
  const isChecked = useBoolean(hasChart ?? false);

  // use this to force a loading state when switching between Archy & external
  // charts - without this, until the selectedArchyChart loads, while we are
  // rendering the chart, the page appears "stuck"
  const isArchyChartLoading = useBoolean(isLoading);
  const setArchyLoading = useCallback(() => {
    isArchyChartLoading.on();

    setTimeout(() => {
      isArchyChartLoading.off();
    }, SECOND_IN_MS);
  }, [isArchyChartLoading]);

  // this will happen once on data load b/c `useArchyCharts` is null. if we have
  // perioCharts, sourceUuid will be the indicator of whether it is an Archy
  // perio chart or external chart. use this to determine initial state of page
  useEffect(() => {
    if (!isLoading && useArchyCharts === null && perioCharts) {
      if (perioCharts.length) {
        if (perioCharts[0].sourceUuid) {
          setArchyLoading();
          setSelectedArchyChart(perioCharts[0]);
          setUseArchyCharts(true);
        } else {
          isArchyChartLoading.off();

          setSelectedExternalChart(perioCharts[0]);
          setExamDate(new Date(perioCharts[0].sourceCreatedAt * SECOND_IN_MS));
          setUseArchyCharts(false);
        }
      } else {
        isArchyChartLoading.off();
        setUseArchyCharts(hasHistoricalExams);
      }
    }
  }, [
    claim,
    hasHistoricalExams,
    isArchyChartLoading,
    isLoading,
    perioCharts,
    selectedExternalChart,
    setArchyLoading,
    useArchyCharts,
  ]);

  const handleSelectType = useCallback(
    (useArchy: boolean) => {
      if (useArchy) {
        setArchyLoading();

        if (selectedArchyChart) {
          setPerioCharts([selectedArchyChart]);
        }
      } else {
        isArchyChartLoading.off();

        if (selectedExternalChart) {
          setPerioCharts([selectedExternalChart]);
        }
      }

      setUseArchyCharts(useArchy);
    },
    [isArchyChartLoading, selectedArchyChart, selectedExternalChart, setArchyLoading, setPerioCharts]
  );

  const perioChartHistoryOptions = useMemo(
    () =>
      historicalExamInfos?.map((examInfo) => ({
        label: `${formatISODate(examInfo.date)} | ${examInfo.provider.shortDisplayName}`,
        value: examInfo.uuid,
      })),
    [historicalExamInfos]
  );

  const handleExamChange = (uuid: string) => {
    if (uuid !== examUuid) {
      setArchyLoading();
      setPerioCharts([]);
      setSelectedArchyChart(undefined);
      loadExam(uuid);
      isChecked.off();
    }
  };

  const handleSelectExternalDate = useCallback(
    (newDate: Date | null) => {
      if (newDate) {
        setExamDate(newDate);

        const updatedExternalChart = selectedExternalChart
          ? {
              ...selectedExternalChart,
              data: selectedExternalChart.data,
              sourceCreatedAt: millisecondsToSeconds(newDate.getTime()),
            }
          : undefined;

        setSelectedExternalChart(updatedExternalChart);
        setPerioCharts(updatedExternalChart ? [updatedExternalChart] : []);
      }
    },
    [selectedExternalChart, setPerioCharts]
  );

  const generateImage = useCallback(
    async (perioChartExamRecord: Readonly<PerioChartExamRecord>) => {
      const element = perioChartRef.current as HTMLElement;
      const png = await toPng(element);
      const examInfo = perioChartExamRecord.getExamInfo();
      const createdAt = millisecondsToSeconds(parseISO(examInfo.date).getTime());

      isArchyChartLoading.off();
      setSelectedArchyChart({
        data: png,
        isUploaded: false,
        sourceCreatedAt: createdAt,
        sourceUuid: examInfo.uuid,
        type: "CHART",
      });
    },
    [isArchyChartLoading]
  );

  const handleCheck = (checked: boolean) => {
    setPerioCharts(selectedArchyChart && checked ? [selectedArchyChart] : []);
    isChecked.toggle();
  };

  const handleAddExternalImage = useCallback(
    (data: string) => {
      const perioChartImage = {
        data,
        isUploaded: true,
        sourceCreatedAt: millisecondsToSeconds(examDate.getTime()),
        type: "CHART" as PerioChart["type"],
      };

      if (!selectedExternalChart) {
        setSelectedExternalChart(perioChartImage);
        setPerioCharts([perioChartImage]);
      }
    },
    [examDate, selectedExternalChart, setPerioCharts]
  );

  const handleRemoveImage = useCallback(() => {
    setPerioCharts([]);
    setSelectedExternalChart(undefined);
  }, [setPerioCharts]);

  return (
    <div className="flex flex-col h-full gap-y-6">
      <RadioList
        onChange={() => handleSelectType(!useArchyCharts)}
        options={[
          { disabled: isLoading || !perioChartHistoryOptions?.length, label: "Select Perio", value: true },
          { disabled: isLoading, label: "Select External Exam", value: false },
        ]}
        selectedValue={useArchyCharts ?? undefined}
      />
      {useArchyCharts ? (
        <FormFieldSelect
          className="w-60"
          disabled={!hasHistoricalExams}
          isClearable={false}
          isSearchable={true}
          label="Date of Exam"
          onChange={(option) => option && handleExamChange(option.value)}
          options={perioChartHistoryOptions ?? []}
          value={examUuid}
        />
      ) : (
        <FormFieldSelectMenusDatepicker
          className="w-60"
          disabled={isLoading}
          label="Date of Exam"
          maxDate={new Date()}
          onChange={handleSelectExternalDate}
          required
          selected={examDate}
        />
      )}
      {isArchyChartLoading.isOn ? (
        <LoadingView />
      ) : useArchyCharts ? (
        <>
          {!hasChart && (
            <div style={OFFSCREEN_RENDER_ORIGIN}>
              <PerioChartComponent ref={perioChartRef} onRender={generateImage} />
            </div>
          )}
          {chartToRender && (
            <div className="flex-1 min-h-0 overflow-y-scroll">
              <div className="border rounded relative inline-block">
                <img src={chartToRender.data} alt="Perio Chart" draggable={false} />
                <div className="absolute top-3 right-3 flex flex-row">
                  <label
                    htmlFor="perioChartCheckbox"
                    className="text-xs font-sansSemiBold text-primaryTheme mr-2.5"
                  >
                    {isChecked.isOn ? "Remove selection" : "Select as attachment"}
                  </label>
                  <Checkbox
                    checked={isChecked.isOn}
                    onChange={(event) => handleCheck(event.target.checked)}
                  />
                </div>
              </div>
            </div>
          )}
        </>
      ) : (
        <ExternalUpload
          attachmentType="perio"
          canSelectMultiple={false}
          images={selectedExternalChart ? [selectedExternalChart] : []}
          onAddImage={handleAddExternalImage}
          onRemovePerioChartImage={handleRemoveImage}
          userId={claim.patientName.id}
        />
      )}
    </div>
  );
};

export const PerioChartAttachments: React.FC<Props> = ({ claim }) => {
  const patientId = claim.patientName.id;

  return (
    <ActiveProviderProvider>
      {patientId && (
        <PerioChartProvider patientId={patientId} isPrinting useNavigation={false}>
          <PerioChartContainer claim={claim} />
        </PerioChartProvider>
      )}
    </ActiveProviderProvider>
  );
};
