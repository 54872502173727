import { FC } from "react";
import { FormVO, TemplateVariableGroupVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { FormPalette } from "components/Settings/Forms/FormPalette";
import { FormElementRequest, CurrentElement } from "components/Settings/Forms/types";
import { EditElementSection } from "components/Settings/Forms/EditElementSection";
import { EditElementFormButtons } from "components/Settings/Forms/EditElementFormButtons";

interface Props {
  formType: FormVO["type"];
  currentElement: CurrentElement;
  templateVariables?: TemplateVariableGroupVO[];
  onInvalidElement: Func;
  onUpdateNew: (update: FormElementRequest | undefined) => void;
  onEdit: (newElement: FormElementRequest) => void;
  onAdd: (newElement: FormElementRequest) => void;
  onRequestClose: Func;
}

export const AddFormElementSection: FC<Props> = ({
  currentElement,
  templateVariables,
  formType,
  onRequestClose,
  onUpdateNew,
  onInvalidElement,
  onAdd,
  onEdit,
}) => {
  return (
    <div className="flex flex-col h-full overflow-hidden">
      {currentElement.flow === "ADD" ? (
        <div
          className={cx(
            "flex flex-1 min-h-0 transition-transform w-[200%]",
            currentElement.element && "-translate-x-1/2"
          )}
        >
          <div
            className={cx(
              "h-full flex-1 flex flex-col transition-opacity",
              currentElement.element && "opacity-0"
            )}
          >
            <div className="p-6 overflow-y-auto flex-1 min-h-0">
              <FormPalette
                formType={formType}
                isSection={currentElement.sectionIndex !== undefined}
                onAddElement={onUpdateNew}
              />
            </div>
            <EditElementFormButtons onCancel={onRequestClose} disabled={true} />
          </div>
          {currentElement.element ? (
            <EditElementSection
              onInvalid={onInvalidElement}
              onDraftChange={onUpdateNew}
              onSecondaryClick={onRequestClose}
              formType={formType}
              element={currentElement.element}
              templateVariables={templateVariables}
              isEditing={false}
              onSubmit={onAdd}
            />
          ) : (
            <div className="flex-1" />
          )}
        </div>
      ) : (
        <EditElementSection
          formType={formType}
          onInvalid={onInvalidElement}
          onSecondaryClick={onRequestClose}
          element={currentElement.element}
          templateVariables={templateVariables}
          isEditing={true}
          onSubmit={onEdit}
          // This is needed to reset the form state
          // when switching between editing elements
          // of the same type
          key={currentElement.element.uuid}
        />
      )}
    </div>
  );
};
