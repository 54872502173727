import { Fragment, ReactNode } from "react";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus-circle.svg";
import { useBoolean } from "@libs/hooks/useBoolean";
import { MenuOptionValue, MenuOptions } from "@libs/components/UI/MenuOptions";
import { ValueTypes } from "@libs/components/UI/OptionInput";
import { IconProps } from "@libs/components/UI/Icon";
import { cx } from "@libs/utils/cx";

interface Props<V extends ValueTypes, O extends MenuOptionValue<V>> {
  ids?: V[];
  options: O[] | undefined;
  addTooltip?: IconProps["tooltip"];
  onItemSelected: (option: O) => void;
  renderValue: (id: V) => ReactNode;
  className?: string;
}

export const FlexSelect = <V extends ValueTypes, O extends MenuOptionValue<V>>({
  ids,
  options,
  addTooltip,
  className,
  onItemSelected,
  renderValue,
}: Props<V, O>) => {
  const menu = useBoolean(false);

  return (
    <div className={cx("flex items-center gap-1 flex-wrap", className)}>
      {ids?.map((id) => <Fragment key={id === false ? 0 : id === true ? 1 : id}>{renderValue(id)}</Fragment>)}
      <ButtonMenu
        isOpen={menu.isOn}
        onRequestClose={menu.off}
        onRequestOpen={menu.on}
        placement="bottom-start"
        menuContent={
          <div className="max-h-96 overflow-y-auto min-w-56">
            <MenuOptions
              onOptionClick={(option) => {
                onItemSelected(option);
                menu.off();
              }}
              options={options ?? []}
            />
          </div>
        }
      >
        {(props) => (
          <ButtonIcon SvgIcon={PlusIcon} {...props} disabled={!options?.length} tooltip={addTooltip} />
        )}
      </ButtonMenu>
    </div>
  );
};
