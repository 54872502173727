import { FC } from "react";

import { PatientNoteAuditEntryVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";

interface Props {
  auditEntry: PatientNoteAuditEntryVO;
}

export const NoteListItemAuditEntry: FC<Props> = ({ auditEntry }) => {
  const action = auditEntry.isUnarchiveAction
    ? "Unarchived by"
    : auditEntry.action === "CREATE"
      ? "Created by"
      : auditEntry.action === "UPDATE"
        ? "Updated by"
        : auditEntry.action === "DELETE"
          ? "Archived by"
          : "";
  const actor = auditEntry.doneBy?.name.fullDisplayName ?? "Archy";
  const date = formatISODate(auditEntry.doneAt, "MMM d, yyyy 'at' p");

  return action ? (
    <p className="text-slate-500 text-xxs">
      {action} <span className="font-sansSemiBold">{actor}</span> {date}
    </p>
  ) : null;
};
