import React, { useCallback, useEffect, useId, useMemo, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { AppointmentRequest } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { manuallyLoadFromServer } from "@libs/utils/queryCache";
import { usePick } from "@libs/hooks/usePick";
import { useAccount } from "@libs/contexts/AccountContext";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { QueryResult } from "@libs/components/UI/QueryResult";
import {
  getAppointmentCategoriesQuery,
  getAppointmentQuery,
  getPracticeRoomsQuery,
} from "api/scheduling/queries";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { paths } from "utils/routing/paths";
import { updateAppointment } from "api/scheduling/mutations";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";
import { EventPatientSnapshotLayout } from "components/ScheduleAppointments/EventPatientSnapshotLayout";
import { EventModalPage } from "components/ScheduleAppointments/EventModalPage";
import { useAppointmentRoute } from "components/ScheduleAppointments/useAppointmentRoute";
import { EditAppointmentOverrideKeys } from "utils/routing/scheduling";
import { EditAppointmentForm } from "components/ScheduleAppointments/EditAppointmentForm";
import { EventModalTitleContent } from "components/ScheduleAppointments/EventModalTitleContent";
import { BenefitLimitationIgnoreConfirmationModal } from "components/ScheduleAppointments/BenefitLimitationIgnoreConfirmationModal";
import { getAppointmentMutatedLabel, isUnscheduledAsapAppt } from "components/ScheduleAppointments/utils";
import { useDeleteAppointment } from "contexts/DeleteAppointmentContext";
import { usePatientAppointmentPathState, cleanFromLink } from "contexts/PatientAppointmentContext";
import { getPatientChartV2 } from "api/charting/queries";
import { pathsWithIdReferences } from "components/Main/MainLinksContext";
import { getTags } from "api/settings/notes/queries";

// eslint-disable-next-line complexity, max-statements
export const EditAppointmentRoute: React.FC = () => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const appointmentRoute = useAppointmentRoute();
  const { query } = useQueryParams("editAppointment");
  const { appointmentId, patientId } = usePathParams("editAppointment");

  const { requestDeleteAppointment, isDeleting } = useDeleteAppointment();

  const queries = useApiQueries([
    getAppointmentQuery({
      args: { practiceId, appointmentId, patientId },
      queryOptions: manuallyLoadFromServer,
    }),
    getAppointmentCategoriesQuery({ args: { practiceId } }),
    getPracticeProvidersQuery({ args: { practiceId } }),
    getPracticeRoomsQuery({ args: { practiceId } }),
    getTags({ args: { practiceId } }),
    getPatientChartV2({
      args: { patientId, practiceId },
    }),
  ]);

  const [updateAppointmentMutation] = useApiMutations([updateAppointment]);
  const hasDeletedAppointmentRef = useRef(false);
  const [appointmentQuery, categoriesQuery, providersQuery, roomsQuery, tagsQuery, chartV2Query] = queries;
  const backUrl = query.from ?? paths.schedule();
  const editAppointment = appointmentQuery.data;

  const overrides = usePick(query, EditAppointmentOverrideKeys);

  // eslint-disable-next-line complexity
  const preSelections = useMemo(() => {
    const result = editAppointment
      ? {
          roomId: overrides.roomId || editAppointment.room.id,
          hygienistId:
            overrides.hygienistId ||
            (editAppointment.provider.jobCategory === "HYGIENIST" ? editAppointment.provider.id : 0),
          dentistId: overrides.dentistId || editAppointment.dentist.id,
          date: overrides.date || editAppointment.date,
          startTime: overrides.startTime || editAppointment.startTime,
          patientId: editAppointment.patient.id,
        }
      : {
          ...overrides,
          patientId: 0,
        };

    if (isUnscheduledAsapAppt(editAppointment?.date)) {
      // If it's unscheduled, clear out fields
      return {
        ...result,
        roomId: 0,
        hygienistId: 0,
        dentistId: 0,
        date: undefined,
        startTime: undefined,
      };
    }

    return result;
  }, [overrides, editAppointment]);
  const isRequestedAppointment = editAppointment?.state === "REQUESTED";

  const updateAppointmentMutate = updateAppointmentMutation.mutate;
  const handleSaveError = appointmentRoute.handleSaveError;
  const handleSaveSuccess = appointmentRoute.handleSaveSuccess;
  const handleSubmit = useCallback(
    (data: AppointmentRequest) => {
      if (editAppointment) {
        updateAppointmentMutate(
          {
            appointmentId: editAppointment.id,
            original: {
              state: editAppointment.state,
              date: editAppointment.date,
              asap: editAppointment.asap,
            },
            data: {
              ...data,
              state:
                editAppointment.state === "UNSCHEDULED" || isRequestedAppointment
                  ? "UNCONFIRMED"
                  : editAppointment.state,
            },
            practiceId,
          },
          {
            onSuccess: handleSaveSuccess,
            onError: handleSaveError(data),
          }
        );
      }
    },
    [
      editAppointment,
      updateAppointmentMutate,
      isRequestedAppointment,
      practiceId,
      handleSaveSuccess,
      handleSaveError,
    ]
  );

  const patientAppointment = usePatientAppointmentPathState({ patientId, appointmentId });
  const deleteAppointment = patientAppointment.handleAppointmentDeleted;
  const handleAppointmentDeleted = useCallback(
    (deletedParams: { patientId: number; appointmentId: number }) => {
      deleteAppointment(deletedParams);

      if (deletedParams.appointmentId === appointmentId) {
        hasDeletedAppointmentRef.current = true;

        const newBackUrl = cleanFromLink({
          patientId,
          appointmentId: undefined,
          url: backUrl,
          config: pathsWithIdReferences,
          lastPatientId: patientId,
        });

        navigate(newBackUrl, { replace: true });
      }
    },
    [backUrl, patientId, navigate, appointmentId, deleteAppointment]
  );

  const handleDeleteAppointment = useCallback(() => {
    if (appointmentQuery.data) {
      requestDeleteAppointment(appointmentQuery.data, {
        onSuccess: () => handleAppointmentDeleted({ patientId, appointmentId }),
      });
    }
  }, [appointmentQuery.data, handleAppointmentDeleted, requestDeleteAppointment, patientId, appointmentId]);

  const isSavedAppointmentUnscheduled = isUnscheduledAsapAppt(appointmentRoute.savedAppointment?.date);
  const formId = useId();

  const fetchAppointment = appointmentQuery.refetch;

  useEffect(() => {
    fetchAppointment();
  }, [fetchAppointment]);

  return (
    <EventPatientSnapshotLayout
      from={backUrl}
      patientId={patientId}
      appointmentId={patientAppointment.appointmentId ?? appointmentId}
      onSelectAppointment={patientAppointment.handleAppointmentSelected}
      onDeleteAppointment={handleAppointmentDeleted}
    >
      <QueryResult queries={queries}>
        {appointmentQuery.data &&
        categoriesQuery.data &&
        providersQuery.data &&
        roomsQuery.data &&
        chartV2Query.data &&
        tagsQuery.data ? (
          <EventModalPage
            formId={formId}
            backUrl={backUrl}
            isSaving={updateAppointmentMutation.isLoading}
            isFormValid={appointmentRoute.formValid.isOn}
            hasSavedEvent={Boolean(appointmentRoute.savedAppointment)}
            isUnscheduled={isSavedAppointmentUnscheduled}
            isRequested={isRequestedAppointment}
            eventUrl={appointmentRoute.appointmentUrl}
            title={
              <EventModalTitleContent title={`${isRequestedAppointment ? "Confirm" : "Edit"} Appointment`} />
            }
            isDirty={appointmentRoute.formDirty.isOn}
            savedValue={appointmentRoute.savedAppointment}
            savedMessage={getAppointmentMutatedLabel({
              isUnscheduled: isSavedAppointmentUnscheduled,
              wasCreated: false,
            })}
            shouldUnblock={() => hasDeletedAppointmentRef.current}
            promptMessage="Do you want to discard your changes to this appointment?"
            isDeleting={isDeleting}
            onDelete={handleDeleteAppointment}
          >
            <EditAppointmentForm
              appointment={appointmentQuery.data}
              providers={providersQuery.data}
              categories={categoriesQuery.data}
              rooms={roomsQuery.data}
              teeth={chartV2Query.data}
              preSelections={preSelections}
              onSubmit={handleSubmit}
              formId={formId}
              tags={tagsQuery.data}
              onValidationChange={appointmentRoute.formValid.set}
              onDirty={appointmentRoute.formDirty.on}
              patientName={editAppointment?.patient.shortDisplayName}
            />
            {appointmentRoute.benefitLimitationModal.isOpen ? (
              <BenefitLimitationIgnoreConfirmationModal
                isSaving={updateAppointmentMutation.isLoading}
                onCancel={appointmentRoute.benefitLimitationModal.close}
                onConfirm={handleSubmit}
                {...appointmentRoute.benefitLimitationModal.item}
              />
            ) : null}
          </EventModalPage>
        ) : null}
      </QueryResult>
    </EventPatientSnapshotLayout>
  );
};
