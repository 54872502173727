import { FC } from "react";

import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";

import { ReactComponent as ArchyLogo } from "assets/components/logo-archy.svg";
import { PracticeMenuContent } from "components/Main/PracticeMenuContent";
import { AccountPickerModal } from "components/Main/AccountPickerModal";
import { SupportSelectPracticeModal } from "components/Main/SupportSelectPracticeModal";
import { SoftwareInfoModal } from "components/Main/SoftwareInfoModal";

import { useCurrentUser } from "contexts/CurrentUserContext";

interface Props {
  practiceName: string;
  isOnboarding?: boolean;
  practiceLogo: string | undefined;
}

export const PracticeMenu: FC<Props> = ({ isOnboarding, practiceName, practiceLogo }) => {
  const currentUser = useCurrentUser();
  const practiceMenu = useBoolean(false);
  const practiceMenuOff = practiceMenu.off;
  const accountSwitcher = useBoolean(false);
  const softareInfoModal = useBoolean(false);

  return (
    <>
      <ButtonMenu
        className="text-greyDark flex items-center"
        isOpen={practiceMenu.isOn}
        onRequestClose={practiceMenu.off}
        onRequestOpen={practiceMenu.on}
        menuContent={
          <PracticeMenuContent
            isOnboarding={isOnboarding}
            practiceName={practiceName}
            onOptionClick={practiceMenuOff}
            onSwitchAccount={() => {
              accountSwitcher.on();
              practiceMenuOff();
            }}
            onViewSoftareInfo={softareInfoModal.on}
          />
        }
        floatingOffset={20}
        placement="right-start"
        shadow="heavy"
      >
        {(props) => (
          <button {...props} aria-label="Practice Menu" type="button">
            {practiceLogo ? (
              <img className="size-8 rounded object-contain" src={practiceLogo} alt={practiceName} />
            ) : (
              <ArchyLogo className="size-8 text-secondaryTheme" />
            )}
          </button>
        )}
      </ButtonMenu>
      {accountSwitcher.isOn ? (
        currentUser.type === "SUPPORT_USER" ? (
          <SupportSelectPracticeModal onRequestClose={accountSwitcher.off} />
        ) : (
          <AccountPickerModal onRequestClose={accountSwitcher.off} />
        )
      ) : null}
      {softareInfoModal.isOn ? <SoftwareInfoModal onRequestClose={softareInfoModal.off} /> : null}
    </>
  );
};
