import { FC } from "react";
import { AutomationActionVO, EmailConfigVO } from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { ChannelsFields } from "components/Communications/Automations/ChannelsFields";
import { DraftAutomationAction } from "components/Communications/Automations/types";
import { ToggleButtonList } from "components/UI/ToggleButtonList";
import { WhenOrAfterTimeFields } from "components/Communications/Automations/WhenOrAfterTimeFields";
import { BeforeTimeFields } from "components/Communications/Automations/BeforeTimeFields";
import { AutomationActionValidation } from "components/Communications/Automations/utils";
import { TimingField } from "components/Communications/Automations/TimingField";

interface Props {
  action: DraftAutomationAction;
  emailConfig: EmailConfigVO;
  onUpdate: (updates: Partial<DraftAutomationAction>) => void;
  validation: AutomationActionValidation;
}

const options: { label: string; value: AutomationActionVO["event"] }[] = [
  {
    label: "Appointment Requested",
    value: "APPOINTMENT_REQUESTED",
  },
  {
    label: "Appointment Created",
    value: "APPOINTMENT_CREATED",
  },
  {
    label: "Unconfirmed Appointment",
    value: "UNCONFIRMED_APPOINTMENT",
  },
  {
    label: "Confirmed Appointment",
    value: "CONFIRMED_APPOINTMENT",
  },
];

const BeforeEvents: AutomationActionVO["event"][] = ["UNCONFIRMED_APPOINTMENT", "CONFIRMED_APPOINTMENT"];
const WhenEvents: AutomationActionVO["event"][] = ["APPOINTMENT_REQUESTED", "APPOINTMENT_CREATED"];

export const AutomationActionAppointmentForm: FC<Props> = ({ emailConfig, action, validation, onUpdate }) => {
  return (
    <>
      <div>
        <ToggleButtonList
          type="radio"
          onChange={(_, option) =>
            onUpdate({
              event: option.value,
              eventTime:
                isOneOf(option.value, BeforeEvents) && isOneOf(action.event, WhenEvents)
                  ? "BEFORE"
                  : isOneOf(option.value, WhenEvents) && isOneOf(action.event, BeforeEvents)
                    ? "WHEN"
                    : action.eventTime,
            })
          }
          selectedValue={action.event}
          options={options}
          label="Event"
          required
          shape="mediumPill"
        />
      </div>
      <TimingField validation={validation}>
        {isOneOf(action.event, WhenEvents) ? (
          <WhenOrAfterTimeFields validation={validation} action={action} onUpdate={onUpdate} />
        ) : (
          <BeforeTimeFields validation={validation} action={action} onUpdate={onUpdate} />
        )}
      </TimingField>
      <ChannelsFields
        emailConfig={emailConfig}
        channels={action.channels}
        onChannelsChange={(value) => onUpdate({ channels: value })}
      />
    </>
  );
};
