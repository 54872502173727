import { FC, useMemo } from "react";
import { ReactComponent as UserIcon } from "@libs/assets/icons/user.svg";
import { ReactComponent as InsuranceIcon } from "@libs/assets/icons/insurance.svg";
import { ReactComponent as MedicalHistoryIcon } from "@libs/assets/icons/medical-history.svg";
import { ReactComponent as ImagesIcon } from "@libs/assets/icons/radioactive.svg";
import { ReactComponent as ToothConditionsIcon } from "@libs/assets/icons/tooth-conditions.svg";
import { ReactComponent as AccountingIcon } from "@libs/assets/icons/accounting.svg";
import { ReactComponent as ClaimsIcon } from "@libs/assets/icons/insurance-claim.svg";
import { ReactComponent as LabCaseIcon } from "@libs/assets/icons/lab-case.svg";
import { ReactComponent as PharmacyIcon } from "@libs/assets/icons/pharmacy.svg";
import { ReactComponent as MessagesIcon } from "@libs/assets/icons/messages.svg";
import { ReactComponent as FormIcon } from "@libs/assets/icons/form.svg";
import { ReactComponent as DocumentIcon } from "@libs/assets/icons/google-docs.svg";
import { ReactComponent as NoteIcon } from "@libs/assets/icons/note.svg";
import { AlertSettingsVO } from "@libs/api/generated-api";
import {
  PatientNavButtonLink,
  PatientNavButton,
  PatientNavButtonLinkProps,
  PatientNavButtonProps,
  isPatientNavButtonProps,
} from "components/PatientBlurbInfo/PatientNavButtons";
import type { RouteIds } from "components/PatientProfile/types";
import { usePatientLinks } from "contexts/PatientLinksContext";
import { routesConfig } from "utils/routing/paths";
import { RoleGuardHide } from "components/Main/RoleGuard";
import { patientRoutePermissions } from "components/PatientProfile/routes";
import { PatientNavPeekButton } from "components/PatientBlurbInfo/PatientNavPeekButton";
import { AlertPeekButton } from "components/PatientBlurbInfo/AlertPeekButton";

interface Props {
  showErx?: boolean;
  onPeekClick: (id: RouteIds) => void;
  onOpenNotes: Func;
  onOpenAlerts: Func;
  areNotesOpen: boolean;
  hasAlerts: boolean;
  alertAppearance: AlertSettingsVO["appearance"] | undefined;
}

export const PatientNav: FC<Props> = ({
  showErx = false,
  onPeekClick,
  onOpenNotes,
  onOpenAlerts,
  areNotesOpen,
  hasAlerts,
  alertAppearance,
}) => {
  const patientLinks = usePatientLinks();

  const tabs = useMemo(() => {
    const potentialTabs: (PatientNavButtonLinkProps | PatientNavButtonProps)[] = [
      {
        activePathPatterns: [routesConfig.patient.path, routesConfig.editPatient.path],
        id: "overview",
        label: "Overview",
        icon: UserIcon,
        to: patientLinks.links.overview,
        children: (props: PatientNavButtonLinkProps) => {
          return (
            <PatientNavPeekButton onPeekClick={onPeekClick} id={props.id} label={props.label} to={props.to} />
          );
        },
      },
      {
        id: "insurance",
        label: "Insurance",
        icon: InsuranceIcon,
        to: patientLinks.links.insurance,
        children: (props: PatientNavButtonLinkProps) => {
          return (
            <PatientNavPeekButton onPeekClick={onPeekClick} id={props.id} label={props.label} to={props.to} />
          );
        },
      },
      {
        id: "medical-history",
        icon: MedicalHistoryIcon,
        label: "Med Hx",
        to: patientLinks.links.medicalHistory,
        children: (props: PatientNavButtonLinkProps) => {
          return (
            <PatientNavPeekButton onPeekClick={onPeekClick} id={props.id} label={props.label} to={props.to} />
          );
        },
      },
      {
        id: "imaging",
        icon: ImagesIcon,
        label: "Images",
        to: patientLinks.links.imaging,
      },
      {
        id: "charting",
        label: "Charting",
        icon: ToothConditionsIcon,
        to: patientLinks.links.charting,
      },
      {
        id: "billing",
        icon: AccountingIcon,
        label: "Billing",
        to: patientLinks.links.billing,
      },
      {
        id: "claims",
        label: "Claims",
        icon: ClaimsIcon,
        to: patientLinks.links.claims,
      },
      {
        id: "lab-cases",
        label: "Labs",
        icon: LabCaseIcon,
        to: patientLinks.links.labCases,
      },
      ...(showErx
        ? [
            {
              id: "erx" as const,
              label: "eRx",
              icon: PharmacyIcon,
              to: patientLinks.links.erx,
            },
          ]
        : []),
      {
        id: "communication",
        icon: MessagesIcon,
        label: "Comms",
        to: patientLinks.links.communications,
      },
      {
        id: "forms",
        label: "Forms",
        icon: FormIcon,
        to: patientLinks.links.forms,
      },
      {
        id: "documents",
        label: "Docs",
        icon: DocumentIcon,
        to: patientLinks.links.documents,
      },
      {
        id: "notes",
        label: "Notes",
        icon: NoteIcon,
        onClick: onOpenNotes,
        isActive: areNotesOpen,
        children: hasAlerts ? (
          <AlertPeekButton onOpenAlerts={onOpenAlerts} alertAppearance={alertAppearance} />
        ) : null,
      },
    ];

    return potentialTabs;
  }, [
    patientLinks.links.billing,
    patientLinks.links.charting,
    patientLinks.links.claims,
    patientLinks.links.communications,
    patientLinks.links.documents,
    patientLinks.links.erx,
    patientLinks.links.forms,
    patientLinks.links.imaging,
    patientLinks.links.insurance,
    patientLinks.links.labCases,
    patientLinks.links.medicalHistory,
    patientLinks.links.overview,
    onPeekClick,
    onOpenNotes,
    onOpenAlerts,
    hasAlerts,
    areNotesOpen,
    showErx,
    alertAppearance,
  ]);

  return (
    <div className="flex flex-wrap gap-1">
      {tabs.map((tab) => {
        if (isPatientNavButtonProps(tab)) {
          return <PatientNavButton key={tab.id} {...tab} />;
        }

        const { domain, action } = patientRoutePermissions[tab.id];

        return (
          <RoleGuardHide key={tab.id} domain={domain} action={action}>
            <PatientNavButtonLink areNotesOpen={areNotesOpen} {...tab} />
          </RoleGuardHide>
        );
      })}
    </div>
  );
};
