import { FC } from "react";
import { NavLink } from "react-router-dom";
import { PatientVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { TabText } from "components/UI/TabText";
import { usePatientBillingLinks } from "components/PatientProfile/Billing/PatientBillingLinksContex";
import { getWallets } from "api/billing/queries";
import {
  PLACEHOLDER_WALLET_ID,
  getFallbackPatientWallet,
} from "components/PatientProfile/Billing/billingUtils";

export type BillingTab = "Ledger" | "Invoices" | "Payments" | "Wallet";

export const BillingTabs: FC<{ patientId: PatientVO["id"]; selected: BillingTab }> = ({
  patientId,
  selected,
}) => {
  const patientBillingLinks = usePatientBillingLinks();
  const { practiceId } = useAccount();

  // Find out if the patient has any wallet activity to determine if we should show the Wallet
  // Activity tab.
  const [patientWalletsQuery] = useApiQueries([getWallets({ args: { patientId, practiceId } })]);
  const wallet = getFallbackPatientWallet(patientId, practiceId, patientWalletsQuery.data);
  const showWalletActivityTab = wallet.uuid !== PLACEHOLDER_WALLET_ID;

  const tabs: {
    label: BillingTab;
    path: string;
  }[] = [
    {
      label: "Ledger",
      path: patientBillingLinks.links.patientBilling,
    },
    {
      label: "Invoices",
      path: patientBillingLinks.links.patientInvoices,
    },
    {
      label: "Payments",
      path: patientBillingLinks.links.patientPayments,
    },
  ];

  if (showWalletActivityTab) {
    tabs.push({
      label: "Wallet",
      path: patientBillingLinks.links.patientWalletActivity,
    });
  }

  return (
    <div className="flex pt-2 pl-1">
      {tabs.map((tab) => (
        <NavLink key={tab.label} to={tab.path}>
          <TabText size="base" isSelected={tab.label === selected}>
            {tab.label}
          </TabText>
        </NavLink>
      ))}
    </div>
  );
};
