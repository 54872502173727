import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import { ProcedureShortcutVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { Spinner } from "@libs/components/UI/Spinner";
import { ReactComponent as Edit } from "@libs/assets/icons/edit.svg";
import { ReactComponent as Drag } from "@libs/assets/icons/drag-reorder-2.svg";
import { ReactComponent as Plus } from "@libs/assets/icons/plus.svg";

interface Props {
  shortcut: ProcedureShortcutVO;
  sortHandleClassName: string;
  isDragging: boolean;
  isSelected: boolean;
  isSortable: boolean;
  isEditable: boolean;
  onViewClick: Func;
  onEditClick: Func;
}

export const cxProcedureShortcutStyles = {
  text: "text-xs line-clamp-2 max-h-8 block break-word",
  textWrapper: "p-2 h-12",
  outlineTheme: `
    bg-white
    outline-slate-200
    outline-1
    hover:outline-1
    hover:outline-primaryTheme
  `,
  grid: "grid grid-cols-4 gap-3",
};

const cxStyles = {
  container: ({ isDragging, isSelected }: { isDragging: boolean; isSelected: boolean }) =>
    cx(
      "flex items-stretch rounded",
      !isDragging && "group outline",
      isDragging
        ? "bg-actionLight"
        : isSelected
          ? "outline-1 outline-primaryTheme bg-actionLight"
          : cxProcedureShortcutStyles.outlineTheme
    ),
};

export const EditableShortcut: React.FC<Props> = ({
  sortHandleClassName,
  shortcut,
  isDragging,
  isEditable,
  isSelected,
  isSortable,
  onViewClick,
  onEditClick,
}) => {
  return (
    <div className={cxStyles.container({ isDragging, isSelected })}>
      <div
        className={cx(
          "px-1 flex-none flex items-center justify-center",
          isSortable && sortHandleClassName,
          isSortable && "cursor-move"
        )}
      >
        <Drag className={cx("w-5 h-5", (isDragging || !isSortable) && "invisible")} />
      </div>
      <button
        type="button"
        onClick={onViewClick}
        className={cx("flex-1", cxProcedureShortcutStyles.textWrapper, isDragging && "invisible")}
      >
        <span className={cxProcedureShortcutStyles.text}>{shortcut.name}</span>
      </button>
      <button className="px-2 flex-none" disabled={!isEditable} onClick={onEditClick} type="button">
        <Edit
          className={cx(
            "w-4 h-4 invisible fill-primaryTheme",
            !isDragging && isEditable && "group-hover:visible"
          )}
        />
      </button>
    </div>
  );
};

export const AddShortcut: FC<{ invisible: boolean; onClick: Func }> = ({ invisible, onClick }) => (
  <button
    type="button"
    onClick={onClick}
    className={cx(
      "rounded flex justify-center items-center outline-dashed",
      cxProcedureShortcutStyles.textWrapper,
      cxProcedureShortcutStyles.outlineTheme,
      invisible && "invisible"
    )}
  >
    <Plus className="w-4 h-4" />
  </button>
);

export const ViewShortcut: FC<{
  shortcut: ProcedureShortcutVO;
  isSaving: boolean;
  isDisabled: boolean;
  onClick: Func;
}> = ({ shortcut, isDisabled, isSaving, onClick }) => (
  <button
    type="button"
    disabled={isDisabled}
    className={cx(
      "outline rounded",
      cxProcedureShortcutStyles.textWrapper,
      isDisabled ? "outline-slate-200 text-greyLight outline-1" : cxProcedureShortcutStyles.outlineTheme
    )}
    onClick={onClick}
  >
    {isSaving ? (
      <div className="h-full flex items-center justify-center">
        <Spinner size="sm" variant="secondary" animation="border" />
      </div>
    ) : (
      <span className={cxProcedureShortcutStyles.text}>{shortcut.name}</span>
    )}
  </button>
);

export const SkeletonShortcut: FC = () => (
  <Skeleton inline={true} containerClassName={cxProcedureShortcutStyles.grid} className="h-12" />
);
