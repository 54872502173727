import React, { useCallback } from "react";
import { Icon } from "@libs/components/UI/Icon";
import { produce } from "immer";
import { ReactComponent as ToothPartsIcon } from "@libs/assets/icons/code-and-measurements.svg";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as PearlToggleIcon } from "@libs/assets/icons/tooth-conditions.svg";
import { ReactComponent as ToothMeasurement } from "@libs/assets/icons/tooth-measurement.svg";
import { ReactComponent as RightDrawerIcon } from "@libs/assets/icons/right-chevron.svg";

import { ToolBoat } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ToolBoat";
import { ToolbarButton } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ToolbarButton";
import { PearlConfig, PearlTool } from "components/PatientProfile/Imaging/types/pearl";
import { ImagingToolbarPortal } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ImagingToolbarPortal";

const PEARL_TOOLS: { id: PearlTool; icon: React.FC; tooltip: string }[] = [
  {
    id: "pathologies",
    icon: PearlToggleIcon,
    tooltip: "Toggle pathologies",
  },
  {
    id: "measurements",
    icon: ToothMeasurement,
    tooltip: "Toggle measurements",
  },
  {
    id: "toothParts",
    icon: ToothPartsIcon,
    tooltip: "Toggle tooth parts",
  },
  // {
  //   id: "edit",
  //   icon: EditIcon,
  // },
];

export const PearlToolboat: React.FC<{
  flyoverOpen: boolean;
  onFlyoverToggled: (open: boolean) => void;
  disabled?: boolean;
  pearlConfig?: PearlConfig;
  disabledMessage?: string;
  onUpdatePearlConfig?: (config: Partial<PearlConfig>) => void;
}> = ({
  onFlyoverToggled,
  flyoverOpen,
  disabled = false,
  pearlConfig,
  disabledMessage,
  onUpdatePearlConfig,
}) => {
  const toggleTool = useCallback(
    (tool: PearlTool) => {
      if (!pearlConfig) {
        return;
      }

      onUpdatePearlConfig?.({
        toolsOn: produce(pearlConfig.toolsOn, (draft) => {
          if (pearlConfig.toolsOn.has(tool)) {
            draft.delete(tool);
          } else {
            draft.add(tool);
          }
        }),
      });
    },
    [onUpdatePearlConfig, pearlConfig]
  );
  const sharedProps = { disabled: disabled || !pearlConfig };

  return (
    <ToolBoat>
      <ImagingToolbarPortal>
        {!flyoverOpen && (
          <FloatingTooltip theme="SMALL" content="AI Analysis">
            <button
              className={`
                absolute
                top-[7.1rem]
                text-white
                left-14
                h-8
                hover:bg-slate-700
                bg-slate-800
                rounded-r-md
                z-10
              `}
              onClick={() => onFlyoverToggled(true)}
              type="button"
            >
              <Icon SvgIcon={RightDrawerIcon} />
            </button>
          </FloatingTooltip>
        )}
      </ImagingToolbarPortal>
      {PEARL_TOOLS.map(({ id, icon: SvgIcon, tooltip }) => {
        return (
          <ToolbarButton
            {...sharedProps}
            key={id}
            onClick={() => {
              if (id === "pathologies" && !pearlConfig?.toolsOn.has("pathologies") && !flyoverOpen) {
                onFlyoverToggled(true);
              }

              toggleTool(id);
            }}
            tooltip={disabledMessage ?? tooltip}
            SvgIcon={SvgIcon}
            toggled={!sharedProps.disabled && pearlConfig?.toolsOn.has(id)}
          />
        );
      })}
    </ToolBoat>
  );
};
