import { FC, PropsWithChildren } from "react";
import { Navigate, Route } from "react-router-dom";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { AppointmentsCompletedRoute } from "components/Dashboard/AppointmentsCompleted/AppointmentsCompletedRoute";
import { PracticeProductionRoute } from "components/Dashboard/PracticeProduction/PracticeProductionRoute";
import { OutstandingCollectionsRoute } from "components/Dashboard/OutstandingCollections/OutstandingCollectionsRoute";

import { PaymentCollectedRoute } from "components/Dashboard/PaymentCollected/PaymentCollectedRoute";
import { MainAppHistoryProvider } from "components/Main/MainLinksContext";
import { paths } from "utils/routing/paths";
import { PatientsTreatedRoute } from "components/Dashboard/PatientsTreated/PatientsTreatedRoute";
import { AppointmentDrilldownRoute } from "components/Dashboard/AppointmentDrilldown/AppointmentDrilldownRoute";
import { AdjustmentsRoute } from "components/Dashboard/Adjustments/AdjustmentsRoute";
import { EndOfDayReportRoute } from "components/Dashboard/EndOfDayReport/EndOfDayReportRoute";
import { RecallReportRoute } from "components/Dashboard/RecallReport/RecallReportRoute";
import { UnscheduledTreatmentRoute } from "components/Dashboard/UnscheduledTreatment/UnscheduledTreatmentRoute";
import { ReferralsRoute } from "components/Dashboard/Referrals/ReferralsRoute";
import { PayrollHoursRoute } from "components/Dashboard/PayrollHours/PayrollHoursRoute";
import { PatientWalletRoute } from "components/Dashboard/PatientWallet/PatientWalletRoute";
import { RoleGuardLock } from "components/Main/RoleGuard";
import { PermissionAction } from "components/Roles/constants";

export const DashboardRoleGuard: FC<PropsWithChildren<{ action: PermissionAction }>> = ({
  action,
  children,
}) => (
  <RoleGuardLock domain="REPORTING" action={action}>
    {children}
  </RoleGuardLock>
);

const DASHBOARD_ROUTES = [
  {
    path: "practice-production/*",
    element: (
      <DashboardRoleGuard action="ACCESS_FINANCIAL">
        <PracticeProductionRoute />
      </DashboardRoleGuard>
    ),
  },
  {
    path: "payment-outstanding/*",
    element: (
      <DashboardRoleGuard action="ACCESS_FINANCIAL">
        <OutstandingCollectionsRoute />
      </DashboardRoleGuard>
    ),
  },
  {
    path: "payment-collected/*",
    element: (
      <DashboardRoleGuard action="ACCESS_FINANCIAL">
        <PaymentCollectedRoute />
      </DashboardRoleGuard>
    ),
  },
  {
    path: "patient-wallet",
    element: (
      <DashboardRoleGuard action="ACCESS_FINANCIAL">
        <PatientWalletRoute />
      </DashboardRoleGuard>
    ),
  },
  {
    path: "patients-treated",
    element: (
      <DashboardRoleGuard action="ACCESS_OPERATIONAL">
        <PatientsTreatedRoute />
      </DashboardRoleGuard>
    ),
  },
  {
    path: "appointments-completed",
    element: (
      <DashboardRoleGuard action="ACCESS_OPERATIONAL">
        <AppointmentsCompletedRoute />
      </DashboardRoleGuard>
    ),
  },
  {
    path: "appointments/:apptType",
    element: (
      // `AppointmentDrilldownRoute` holds reports restricted by
      // ACCESS_OPERATIONAL and ACCESS_HYGIENE roles. Here we grant access to
      // all reports (ACCESS_ALL), and then additional RoleGuards within
      // `AppointmentDrilldownRoute` dynamically adjust the permission to allow
      // either Operational or Hygiene reports.
      <DashboardRoleGuard action="ACCESS_ALL">
        <AppointmentDrilldownRoute />
      </DashboardRoleGuard>
    ),
  },
  {
    path: "adjustments/*",
    element: (
      <DashboardRoleGuard action="ACCESS_FINANCIAL">
        <AdjustmentsRoute />
      </DashboardRoleGuard>
    ),
  },
  {
    path: "daily-report",
    element: (
      <DashboardRoleGuard action="ACCESS_FINANCIAL">
        <EndOfDayReportRoute />
      </DashboardRoleGuard>
    ),
  },
  {
    path: "recall/*",
    element: (
      <DashboardRoleGuard action="ACCESS_HYGIENE">
        <RecallReportRoute />
      </DashboardRoleGuard>
    ),
  },
  {
    path: "unscheduled-treatment",
    element: (
      <DashboardRoleGuard action="ACCESS_OPERATIONAL">
        <UnscheduledTreatmentRoute />
      </DashboardRoleGuard>
    ),
  },
  {
    path: "referrals",
    element: (
      <DashboardRoleGuard action="ACCESS_OPERATIONAL">
        <ReferralsRoute />
      </DashboardRoleGuard>
    ),
  },
  {
    path: "payroll/hours",
    element: (
      <DashboardRoleGuard action="ACCESS_ADMINISTRATIVE">
        <PayrollHoursRoute />
      </DashboardRoleGuard>
    ),
  },
];

export const DashboardRoute: FC = () => {
  usePageTitle("Reporting");

  return (
    <MainAppHistoryProvider name="dashboard">
      <SentryRoutes>
        {DASHBOARD_ROUTES.map((route, index) => (
          <Route key={index} path={route.path} element={route.element} />
        ))}
        <Route
          path="*"
          element={<Navigate replace to={paths.dashboardPracticeProduction({ tableTab: "providers" })} />}
        />
      </SentryRoutes>
    </MainAppHistoryProvider>
  );
};
