import { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getFullUrl } from "@libs/utils/location";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { ReactComponent as PlusCircleIcon } from "@libs/assets/icons/plus-circle.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { Panel } from "@libs/components/UI/Panel";
import { QueryResult } from "@libs/components/UI/QueryResult";

import { EmailSettingsBanner } from "components/Communications/EmailSettingsBanner";
import { AutomationJourneys } from "components/Communications/Automations/AutomationJourneys";

import { getCreateEmailCampaignRequest } from "components/Communications/Campaigns/Campaign/utils";

import { getEmailConfig } from "api/settings/communications/queries";
import { listMessageCampaigns } from "api/communications/queries";
import { createMessageCampaign } from "api/communications/mutations";

import { paths } from "utils/routing/paths";
import { handleError } from "utils/handleError";

import { useQueryParams } from "hooks/useQueryParams";

import { CampaignsTable } from "components/Communications/Campaigns/CampaignsTable";
import { CommunicationsRoutesConfig } from "utils/routing/communications";

const DEFAULT_PAGE = CommunicationsRoutesConfig.communications.query.page.defaultValue;
const DEFAULT_PAGE_SIZE = CommunicationsRoutesConfig.communications.query.pageSize.defaultValue;

export const CommunicationsRoute: FC = () => {
  const { practiceId } = useAccount();
  const location = useLocation();
  const navigate = useNavigate();
  const { query, updateQuery } = useQueryParams("communications");

  usePageTitle("Communications");

  const [emailConfigQuery, campaignsQuery] = useApiQueries([
    getEmailConfig({ args: { practiceId } }),
    listMessageCampaigns({
      args: {
        practiceId,
        channels: ["EMAIL"],
        isNamedCampaign: true,
        pageSize: query.pageSize,
        pageNumber: query.page,
      },
    }),
  ]);

  const [createMessageCampaignMutation] = useApiMutations([createMessageCampaign]);

  const handleAddEmailCampaign = async () => {
    try {
      const data = getCreateEmailCampaignRequest();
      const repsonse = await createMessageCampaignMutation.mutateAsync({ practiceId, data });
      const campaignUuid = repsonse.data.data.uuid;

      navigate(paths.campaign({ campaignUuid }, { from: getFullUrl(location) }));
    } catch (error) {
      handleError(error);
    }
  };

  return (
    <Panel title="Communications" className="min-w-[48rem] h-full">
      <div
        className={`
          flex
          flex-col
          items-stretch
          gap-y-6
          w-full
          max-w-5xl
          h-full
          mx-auto
        `}
        data-testid="communications-container"
      >
        <QueryResult queries={[emailConfigQuery]}>
          {emailConfigQuery.data ? (
            <>
              <EmailSettingsBanner emailConfig={emailConfigQuery.data} />

              <div>
                <h2 className="font-sansSemiBold text-sm mb-1.5">Automations</h2>
                <AutomationJourneys />
              </div>

              <div className="flex-1 min-h-0">
                <div className="flex flex-col max-h-full">
                  <div className="flex items-center gap-x-1 mb-1.5">
                    <h2 className="font-sansSemiBold text-sm">Campaigns</h2>
                    <div className="flex items-center min-h-5">
                      <ButtonIcon
                        SvgIcon={PlusCircleIcon}
                        tooltip={{ content: "Add Campaign", theme: "SMALL" }}
                        onClick={handleAddEmailCampaign}
                        loading={createMessageCampaignMutation.isLoading}
                        disabled={
                          !emailConfigQuery.data.domainVerified || !emailConfigQuery.data.replyToEmail
                        }
                        theme="primary"
                      />
                    </div>
                  </div>

                  <CampaignsTable
                    page={query.page}
                    pageSize={query.pageSize}
                    campaignsQuery={campaignsQuery}
                    onPageChange={(page) =>
                      updateQuery("replaceIn", { page: page === DEFAULT_PAGE ? undefined : page })
                    }
                    onPageSizeChange={(newSize) =>
                      updateQuery("replaceIn", {
                        pageSize: newSize === DEFAULT_PAGE_SIZE ? undefined : newSize,
                        page: undefined,
                      })
                    }
                  />
                </div>
              </div>
            </>
          ) : null}
        </QueryResult>
      </div>
    </Panel>
  );
};
