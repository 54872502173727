import { FC, useCallback, useId } from "react";
import { useNavigate } from "react-router-dom";
import { RoleRequest, RoleVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { getPermissionActions, getRoleV2 } from "api/practice/queries";
import { updateRoleV2 } from "api/practice/mutations";
import { handleError } from "utils/handleError";
import { prepareRoleDraftForSubmission, roleToRoleDraft } from "components/Roles/roleUtils";
import { usePathParams } from "hooks/usePathParams";
import { RoleFormContent, RoleFormContentProps } from "components/Roles/RoleFormContent";
import { RoleModalPage } from "components/Roles/RoleModalPage";

const EditRoleForm: FC<
  Pick<RoleFormContentProps, "allPermissions" | "formId" | "onSave"> & {
    role: RoleVO;
  }
> = ({ formId, allPermissions, role, onSave }) => {
  const getDraft = useCallback(() => roleToRoleDraft(allPermissions, role), [role, allPermissions]);

  return (
    <RoleFormContent
      formId={formId}
      getInitialState={getDraft}
      allPermissions={allPermissions}
      viewOnly={role.archyOwned}
      onSave={onSave}
    />
  );
};

export const EditRoleRoute: FC = () => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const { roleId } = usePathParams("roleEdit");
  const [permissionActionsQuery, roleQuery] = useApiQueries([
    getPermissionActions({ args: { practiceId } }),
    getRoleV2({ args: { practiceId, roleId } }),
  ]);

  const [updateRole] = useApiMutations([updateRoleV2]);
  const {
    query: { from },
  } = useQueryParams("roleEdit");
  const formId = useId();

  const closeUrl = from ?? paths.roles();
  const handleSave = (draftRole: RoleRequest) => {
    if (permissionActionsQuery.data) {
      updateRole.mutate(
        {
          roleId,
          practiceId,
          data: prepareRoleDraftForSubmission(permissionActionsQuery.data, draftRole),
        },
        {
          onSuccess: () => navigate(closeUrl),
          onError: handleError,
        }
      );
    }
  };

  return (
    <RoleModalPage
      titleText="Edit Role"
      closeUrl={closeUrl}
      saveText="Save"
      isSaving={updateRole.isLoading}
      formId={formId}
    >
      <QueryResult queries={[permissionActionsQuery, roleQuery]}>
        {permissionActionsQuery.data && roleQuery.data && (
          <EditRoleForm
            formId={formId}
            allPermissions={permissionActionsQuery.data}
            onSave={handleSave}
            role={roleQuery.data}
          />
        )}
      </QueryResult>
    </RoleModalPage>
  );
};
