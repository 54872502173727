import React from "react";
import { SingleValue } from "react-select";
import { NameVO, ProviderVO } from "@libs/api/generated-api";
import { FormFieldLayout } from "@libs/types/form";
import { FormFieldSelect } from "components/UI/FormFieldSelect";

export const ProviderInputSelect: React.FC<{
  label: string;
  value?: NameVO;
  layout?: FormFieldLayout;
  edit: boolean;
  menuPlacement?: "auto" | "top" | "bottom";
  onChange: (
    option: SingleValue<{
      label: string;
      value: number;
      name: NameVO;
    }>
  ) => void;
  providers: ProviderVO[];
}> = ({ layout, label, value, edit, onChange, providers, menuPlacement = "auto" }) => {
  const providerOptions = edit
    ? providers.map((provider) => ({
        label: provider.name.fullDisplayName,
        value: provider.id,
        name: provider.name,
      }))
    : value
      ? [
          {
            label: value.fullDisplayName,
            value: value.id,
            name: value,
          },
        ]
      : [];

  return (
    <FormFieldSelect
      layout={layout}
      label={label}
      display="label"
      value={value?.id}
      options={providerOptions}
      menuPlacement={menuPlacement}
      onChange={onChange}
      edit={edit}
    />
  );
};
