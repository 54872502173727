import { FormEvent, useCallback, useMemo } from "react";
import { DentalProcedureVO, UpsertFrequencyLimitationCategoryRequest } from "@libs/api/generated-api";
import { minArrayLen, required } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import { noop } from "@libs/utils/noop";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Button } from "@libs/components/UI/Button";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useAccount } from "@libs/contexts/AccountContext";
import { createSelectStyles } from "@libs/components/UI/selectStyles";
import { batchUpsertFrequencyLimitationCategories } from "api/practiceInsurance/mutations";
import { EditableFrequencyLimitationCategory } from "components/Settings/InsurancePreferences/InsurancePreferencesRoute";
import { Flyover } from "components/UI/Flyover";
import { FlyoverForm, FlyoverContent, FlyoverFooter } from "components/UI/FlyoverComponents";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { ProcedureDataCode, ProcedureSelector } from "components/ProcedureSelector/ProcedureSelector";
import { DASHBOARD_SELECT_STYLES } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";
import { handleError } from "utils/handleError";
import { DefaultFrequencyToLabelMap } from "components/Settings/InsurancePreferences/Row";

interface Props {
  dentalProcedures: DentalProcedureVO[];
  limitationCategory: EditableFrequencyLimitationCategory;
  onClose: Func;
  unavailableProcecureIds: Set<number>;
}

const limitationSchema = {
  defaultFrequencyLimit: [{ $v: required, $error: "Default frequency required" }],
  dentalProcedures: [{ $v: minArrayLen(1), $error: "Select at least one procedure" }],
  name: [{ $v: required, $error: "Name required" }],
};

export const LimitationFlyover: React.FC<Props> = ({
  dentalProcedures,
  limitationCategory,
  onClose,
  unavailableProcecureIds,
}) => {
  const { practiceId } = useAccount();
  const [draft, updateDraft] = useObjectState<EditableFrequencyLimitationCategory>(limitationCategory);

  const availableDentalProcedures = useMemo(() => {
    const limitationProcedureIds = new Set(
      limitationCategory.dentalProcedures.map((procedure) => procedure.id)
    );

    return dentalProcedures.filter(
      (procedure) => !unavailableProcecureIds.has(procedure.id) || limitationProcedureIds.has(procedure.id)
    );
  }, [dentalProcedures, limitationCategory.dentalProcedures, unavailableProcecureIds]);

  const { validate, result } = useValidation(draft, limitationSchema);

  const [batchUpsertFrequencyLimitationCategoriesMutation] = useApiMutations([
    batchUpsertFrequencyLimitationCategories,
  ]);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!validate().$isValid) {
        return;
      }

      const data = { requests: [draftToPostData(draft)] };

      batchUpsertFrequencyLimitationCategoriesMutation.mutate(
        { practiceId, data },
        { onSuccess: onClose, onError: handleError }
      );
    },
    [batchUpsertFrequencyLimitationCategoriesMutation, draft, onClose, practiceId, validate]
  );

  const customProcedureDropdownStyles = useMemo(
    () =>
      createSelectStyles<string, ProcedureDataCode, true>({
        ...DASHBOARD_SELECT_STYLES,
        option: (provided, props) => {
          const procedureCdtCode = `${props.data.data.cdtCode}`;

          return {
            ...provided,
            cursor: "pointer",
            fontWeight: new Set(draft.dentalProcedures.map((procedure) => procedure.cdtCode)).has(
              procedureCdtCode
            )
              ? "bolder"
              : undefined,
          };
        },
      }),
    [draft.dentalProcedures]
  );

  return (
    <Flyover onClose={onClose} title={limitationCategory.id ? "Edit Service" : "Add Service"}>
      {({ close }) => (
        <FlyoverForm onSubmit={handleSubmit}>
          <FlyoverContent className="flex flex-col gap-y-6 text-xs">
            <span>Procedures assigned to a service share the same limitation frequency.</span>
            <FloatingTooltip
              content={limitationCategory.isDefault ? "Archy default service names cannot be edited" : ""}
              placement="top"
            >
              <FormFieldInput
                disabled={limitationCategory.isDefault}
                error={result.name.$error}
                label="Service"
                onChange={(e) => updateDraft({ name: e.target.value })}
                required
                value={draft.name}
              />
            </FloatingTooltip>
            <ProcedureSelector
              allowDuplicateSelections={false}
              className="min-w-[12rem] z-30"
              closeMenuOnSelect={false}
              error={result.dentalProcedures.$error}
              formatOptionLabel={(procedure: ProcedureDataCode) => {
                return (
                  <div className="flex items-center gap-x-2">
                    <Checkbox
                      checked={new Set(draft.dentalProcedures.map((dp) => dp.cdtCode)).has(procedure.value)}
                      // do nothing - handled in ProcedureSelector onChange
                      onChange={noop}
                    />
                    <div className="flex-1 flex flex-col">
                      <div className="text-xs">
                        <span className="font-sansSemiBold">{procedure.data.cdtCode}</span>{" "}
                        {procedure.data.simpleName}
                      </div>
                      <div className="text-xxs text-slate-700">{procedure.data.name}</div>
                    </div>
                  </div>
                );
              }}
              hideSelectedOptions
              label="Procedures"
              mode="multi"
              onChange={(updatedProcedures) => updateDraft({ dentalProcedures: updatedProcedures })}
              procedures={availableDentalProcedures}
              required
              styles={customProcedureDropdownStyles}
              values={draft.dentalProcedures}
            />
            <FormFieldSelect
              error={result.defaultFrequencyLimit.$error}
              onItemSelected={(val) => {
                updateDraft({ defaultFrequencyLimit: val });
              }}
              options={(
                Object.keys(DefaultFrequencyToLabelMap) as Array<keyof typeof DefaultFrequencyToLabelMap>
              ).map((key) => ({
                label: DefaultFrequencyToLabelMap[key],
                value: key,
              }))}
              label="Default Frequency"
              required
              value={draft.defaultFrequencyLimit}
            />
          </FlyoverContent>
          <FlyoverFooter>
            <Button className="min-w-button" onClick={close} theme="secondary">
              Cancel
            </Button>
            <AsyncButton
              className="min-w-button"
              isLoading={batchUpsertFrequencyLimitationCategoriesMutation.isLoading}
              theme="primary"
              type="submit"
            >
              Save
            </AsyncButton>
          </FlyoverFooter>
        </FlyoverForm>
      )}
    </Flyover>
  );
};

const draftToPostData = (
  draft: EditableFrequencyLimitationCategory
): UpsertFrequencyLimitationCategoryRequest => ({
  id: draft.id,
  name: draft.name,
  defaultFrequencyLimit: draft.defaultFrequencyLimit,
  dentalProcedureIds: draft.dentalProcedures.map((procedure) => procedure.id),
});
