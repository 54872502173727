import { FC, useCallback } from "react";
import { Link } from "react-router-dom";

import { MigratedTransactionVO } from "@libs/api/generated-api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as NoteIcon } from "@libs/assets/icons/note.svg";
import { formatISODate } from "@libs/utils/date";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { formatCurrency } from "@libs/utils/currency";

import { Row, TextCell } from "@libs/components/UI/GridTableComponents";
import { Avatar } from "components/UI/Avatar";

import { paths } from "utils/routing/paths";

interface Props {
  transaction: MigratedTransactionVO;
  onNoteClick: ({ note }: { note: string }) => void;
}

export const ImportedLedgerRow: FC<Props> = ({ transaction, onNoteClick }) => {
  const cellProps = { borderColor: "border-slate-200" as const };

  const handleNoteClick = useCallback(() => {
    if (transaction.note) {
      onNoteClick({ note: transaction.note });
    }
  }, [onNoteClick, transaction.note]);

  return (
    <Row className="*:flex *:items-center" data-testid="imported-ledger-row">
      <TextCell {...cellProps}>{formatISODate(transaction.date)}</TextCell>
      <TextCell {...cellProps}>{sentenceCaseConstant(transaction.type)}</TextCell>
      <TextCell {...cellProps}>{transaction.code}</TextCell>
      <TextCell {...cellProps}>{transaction.description}</TextCell>
      <TextCell {...cellProps}>
        {transaction.note ? (
          <ButtonIcon
            SvgIcon={NoteIcon}
            tooltip={{ content: "Note", theme: "SMALL" }}
            onClick={handleNoteClick}
            theme="slate700"
          />
        ) : null}
      </TextCell>
      <TextCell {...cellProps}>
        {transaction.employee ? (
          <Link to={paths.employee({ id: transaction.employee.id })}>
            <Avatar name={transaction.employee.fullDisplayName} size="sm" />
          </Link>
        ) : (
          <div className="h-6" />
        )}
      </TextCell>
      <TextCell className="justify-end" {...cellProps}>
        {formatCurrency(transaction.amount)}
      </TextCell>
    </Row>
  );
};
