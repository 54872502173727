import { FC, FormEvent, useCallback, useRef, useState } from "react";
import { produce } from "immer";
import { PostTaxDeductionVO } from "@libs/api/generated-api";
import { hasValue, required } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import { centsToCurrencyValue, currencyValueToCents } from "@libs/utils/currency";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon } from "@libs/components/UI/Icon";
import { Button } from "@libs/components/UI/Button";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { ReactComponent as RemoveIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as PlusCircle } from "@libs/assets/icons/plus-circle.svg";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Modal } from "@libs/components/UI/Modal";
import { ModalFooter, ModalContent, ModalForm } from "@libs/components/UI/ModalComponents";
import { FormFieldCurrencyInput } from "components/UI/FormFieldCurrencyInput";

interface Props {
  deductions: PostTaxDeductionVO[];
  onSave: (updated: PostTaxDeductionVO[]) => void;
  onClose: Func;
}

type EditableDeduction = Omit<PostTaxDeductionVO, "amount"> & {
  id: string;
  amount: string;
};

const getEmptyDeduction = (id: number) => ({
  id: `${id}`,
  amount: "",
  courtOrdered: false,
  errors: [],
  name: "",
});

const getSchema = (drafts: EditableDeduction[]) => {
  return {
    $items: drafts.map((d) => ({
      amount: [
        {
          $ignore: !hasValue(d.name),
          $v: required,
          $error: "Please provide an amount.",
        },
      ],
      name: [
        {
          $ignore: !hasValue(d.amount),
          $v: required,
          $error: "Please provide a name.",
        },
      ],
    })),
  };
};

const isDeduction = (deduction: {
  name: string;
  amount: number | undefined;
}): deduction is PostTaxDeductionVO => Boolean(deduction.name && deduction.amount);

export const DeductionsModal: FC<Props> = ({ deductions, onClose, onSave }) => {
  const [draftDeductions, setDraftDeductions] = useState<EditableDeduction[]>(() =>
    deductions.length
      ? deductions.map((deduction, index) => ({
          ...deduction,
          id: `${index}`,
          amount: centsToCurrencyValue(deduction.amount),
        }))
      : [getEmptyDeduction(0)]
  );

  const lastIdRef = useRef(draftDeductions.length - 1);

  const handleAdd = useCallback(() => {
    lastIdRef.current += 1;
    setDraftDeductions((last) => [...last, getEmptyDeduction(lastIdRef.current)]);
  }, []);

  const handleRemove = useCallback((removeIndex: number) => {
    setDraftDeductions((last) => {
      if (last.length === 1) {
        return [{ id: last[0].id, courtOrdered: false, errors: [], name: "", amount: "" }];
      }

      return last.filter((_, index) => index !== removeIndex);
    });
  }, []);

  const { validate, result } = useValidation(draftDeductions, getSchema(draftDeductions));

  const handleSave = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (validate().$isValid) {
        const deductionsToSave = draftDeductions
          .map((dd) => ({
            courtOrdered: dd.courtOrdered,
            amount: currencyValueToCents(dd.amount),
            name: dd.name,
          }))
          .filter(isDeduction);

        onSave(deductionsToSave);
      }
    },
    [onSave, draftDeductions, validate]
  );

  const handleUpdate = useCallback((index: number, update: Partial<EditableDeduction>) => {
    setDraftDeductions((last) =>
      produce(last, (lastDraft) => {
        lastDraft[index] = {
          ...lastDraft[index],
          ...update,
        };
      })
    );
  }, []);

  return (
    <Modal centerVertically={false} size="sm" title="Post Tax Deductions" onClose={onClose}>
      <ModalForm onSubmit={handleSave}>
        <ModalContent padding="lg">
          {draftDeductions.map((dd, index) => (
            <div key={dd.id} className="py-4">
              <div
                className={`
                  gap-6
                  grid
                  grid-cols-3
                  border-t
                  border-t-greyLighter
                  first:pt-0
                  first:border-t-0
                  items-start
                  mb-2.5
                `}
              >
                <FormFieldInput
                  label="Deduction"
                  value={dd.name}
                  error={result.$items[index].name.$error}
                  onChange={(e) => handleUpdate(index, { name: e.target.value })}
                />
                <FormFieldCurrencyInput
                  label="Amount"
                  value={dd.amount}
                  error={result.$items[index].amount.$error}
                  onValueChange={(val) => handleUpdate(index, { amount: val })}
                />
                <div className="self-end pb-2 flex">
                  <ButtonIcon theme="primary" SvgIcon={RemoveIcon} onClick={() => handleRemove(index)} />
                  {dd.errors.length ? (
                    <div className="flex text-red text-xs items-center ml-2">
                      <Icon SvgIcon={ErrorIcon} theme="error" className="mr-1" /> Processing Error
                    </div>
                  ) : null}
                </div>
              </div>
              <Checkbox
                checked={dd.courtOrdered}
                onChange={(e) => handleUpdate(index, { courtOrdered: e.target.checked })}
              >
                Court Ordered
              </Checkbox>
            </div>
          ))}
          <Button onClick={handleAdd} theme="link" className="flex items-center text-xs">
            <Icon SvgIcon={PlusCircle} theme="primary" className="mr-1" /> Add Deduction
          </Button>

          {deductions.some((dd) => dd.errors.length) && (
            <div className="flex items-start mt-6">
              <Icon SvgIcon={ErrorIcon} theme="error" className="mr-1" />
              <p className="text-xs max-w-md">
                All deductions must be successfully processed before you can run payroll. Try saving again to
                clear the existent error or remove the unprocessed deduction.
              </p>
            </div>
          )}
        </ModalContent>
        <ModalFooter>
          <Button onClick={onClose} className="min-w-button" theme="secondary">
            Cancel
          </Button>
          <Button type="submit" className="min-w-button">
            Save
          </Button>
        </ModalFooter>
      </ModalForm>
    </Modal>
  );
};
