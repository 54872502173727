import { ChangeEvent, KeyboardEvent, useCallback, forwardRef, useMemo } from "react";
import { TemplateVariableGroupVO } from "@libs/api/generated-api";
import { passRefs } from "@libs/utils/forms";
import { FormFieldInput, FormFieldInputProps } from "@libs/components/UI/FormFieldInput";
import { TemplateVariablesMenu, useTemplateVariablesMenu } from "components/UI/TemplateVariablesMenu";

interface Props {
  templateVariables: TemplateVariableGroupVO[];
  onUpdateTemplate: (newValue: string) => void;
  value: string | undefined;
}

export const FormFieldTemplateVariablesInput = forwardRef<
  HTMLInputElement,
  Omit<FormFieldInputProps, "value"> & Props
>(({ templateVariables, onUpdateTemplate, onKeyDown, onChange, ...props }, ref) => {
  const { handleFormFieldKeydown, handleVariableSelect, templateMenu } =
    useTemplateVariablesMenu<HTMLInputElement>({
      template: props.value,
      onUpdateTemplate,
    });

  const handleKeyDown = useCallback(
    (e: KeyboardEvent<HTMLInputElement>) => {
      onKeyDown?.(e);
      handleFormFieldKeydown(e);
    },
    [handleFormFieldKeydown, onKeyDown]
  );

  const handleOnChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      onChange?.(e);
      onUpdateTemplate(e.target.value);
    },
    [onChange, onUpdateTemplate]
  );

  const refs: (current: HTMLInputElement) => void = useMemo(
    () => passRefs([ref, templateMenu.triggerRef]),
    [ref, templateMenu.triggerRef]
  );

  return (
    <>
      <FormFieldInput {...props} ref={refs} onKeyDown={handleKeyDown} onChange={handleOnChange} />
      {templateMenu.isOpen ? (
        <TemplateVariablesMenu
          triggerRef={templateMenu.triggerRef}
          onRequestClose={templateMenu.close}
          templateVariables={templateVariables}
          onSelect={handleVariableSelect}
        />
      ) : null}
    </>
  );
});
