import { useQuerySelector } from "@libs/hooks/useQuerySelector";
import { createPortal } from "react-dom";

export const TOOLBAR_PORTAL_ID = "toolbar-portal-destination";
export const ImagingToolbarPortalDestination: React.FC = () => <div id={TOOLBAR_PORTAL_ID} />;

export const ImagingToolbarPortal: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const portalTarget = useQuerySelector(`#${TOOLBAR_PORTAL_ID}`);

  return portalTarget && createPortal(<div>{children}</div>, portalTarget);
};
