import React from "react";
import { differenceInDays } from "date-fns";
import {
  UncollectedByServiceResponse,
  UncollectedByServiceEntryVO,
  UncollectedByServiceTotalVO,
} from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getLocalDate } from "@libs/utils/date";
import { formatCurrency } from "@libs/utils/currency";
import { getAbsoluteUrl } from "@libs/utils/location";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { useDownloadQueryPages } from "components/Dashboard/hooks/useDownloadInfiniteQuery";
import { getTotals } from "components/Dashboard/Tables/utils";
import { EMPTY_ROW, formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";
import { flattenEntries } from "components/Dashboard/hooks/useFlattenEntries";
import { UNCOLLECTED_BY_SERVICE_COLUMNS } from "components/Dashboard/OutstandingCollections/constants";
import { labelForServiceEntryType } from "components/Dashboard/OutstandingCollections/utils";
import { paths } from "utils/routing/paths";
import { useCurrentUrl, useOrigin } from "contexts/OriginContext";

const formatOutstandingByService = (
  rows: UncollectedByServiceEntryVO[],
  totals: UncollectedByServiceTotalVO,
  origin: string
) => {
  const columnTitles = formatCsvRow([
    ...UNCOLLECTED_BY_SERVICE_COLUMNS.map(({ label }) => label),
    "Patient URL",
  ]);
  const rowsContent = rows.map((row) => {
    const {
      patient,
      provider,
      serviceDate,
      entryType,
      patientBalance,
      insuranceBalance,
      outstandingBalance,
      uninvoicedBalance,
      totalPatientBalance,
    } = row;
    const ageInDays = `${differenceInDays(new Date(), getLocalDate(serviceDate)) || ""}`;

    return formatCsvRow([
      patient.fullDisplayName,
      serviceDate,
      labelForServiceEntryType(entryType),
      ageInDays,
      provider?.shortDisplayName ?? "",
      formatCurrency(outstandingBalance),
      formatCurrency(patientBalance),
      formatCurrency(insuranceBalance),
      formatCurrency(uninvoicedBalance),
      formatCurrency(totalPatientBalance),
      getAbsoluteUrl(origin, paths.patient({ patientId: patient.id })),
    ]);
  });
  const totalsContent = formatCsvRow([
    "Totals",
    "",
    "",
    "",
    "",
    formatCurrency(totals.totalOutstandingBalance),
    formatCurrency(totals.totalPatientBalance),
    formatCurrency(totals.totalInsuranceBalance),
    formatCurrency(totals.totalUninvoicedBalance),
    formatCurrency(totals.totalPatientBalance),
  ]);

  return [columnTitles, ...rowsContent, EMPTY_ROW, totalsContent];
};

export const useDownloadOutstandingCollectionsByServiceCSV = ({
  outstandingCollectionsByProviderQuery,
  selectedTimeSegment,
}: {
  selectedTimeSegment?: {
    startDate: string;
    endDate: string;
  };
  outstandingCollectionsByProviderQuery: UseInfiniteApiQueryResult<UncollectedByServiceResponse>;
}) => {
  const downloading = useBoolean(false);
  const { startDownload: downloadCollectionsByPatient } = useDownloadQueryPages(
    outstandingCollectionsByProviderQuery
  );
  const origin = useOrigin();
  const currentUrl = useCurrentUrl();

  return {
    isDownloading: downloading.isOn,
    downloadCSV: React.useCallback(async () => {
      downloading.on();

      const result = await downloadCollectionsByPatient();
      const rows = flattenEntries(result);
      const totals = getTotals(result);

      if (totals) {
        const docMetadata = getArchyCsvMetadata(
          currentUrl,
          selectedTimeSegment
            ? {
                startDate: getLocalDate(selectedTimeSegment.startDate),
                endDate: getLocalDate(selectedTimeSegment.endDate),
              }
            : undefined
        );
        const csvRows = [...docMetadata.headers, ...formatOutstandingByService(rows, totals, origin)];

        saveCsv(csvRows, `OutstandingByService_${docMetadata.fileNameTag}`);
      }

      downloading.off();
    }, [downloadCollectionsByPatient, downloading, selectedTimeSegment, origin, currentUrl]),
  };
};
