import { formatCurrency } from "@libs/utils/currency";
import { toPercent } from "@libs/utils/math";

type Props = {
  percent: number;
  value?: number;
  label: string;
  isCurrency?: boolean;
  color: string;
};

export const DonutTooltip: React.FC<Props> = ({ percent, color, value, label, isCurrency = true }) => {
  return (
    <div className="flex p-1">
      <div
        className={`
          space-y-2
          border-r
          border-r-greyLighter
          mr-2
          pr-2
          last:border-r-0
        `}
      >
        <div>
          {value ? (isCurrency ? formatCurrency(value) : `${value} - `) : ""}
          {toPercent(percent)}%
        </div>
        <div className="flex gap-x-1 items-center justify-center">
          <div className="h-3 w-3" style={{ backgroundColor: color }} />
          <div className="font-normal text-xs">{label}</div>
        </div>
      </div>
    </div>
  );
};
