import React from "react";
import { Button } from "@libs/components/UI/Button";
import EventSaved from "assets/images/confirmed.svg";

interface Props {
  onStay: Func;
  onViewSchedule?: Func;
  message?: string;
  isUnscheduled: boolean;
  primaryButtonText?: string;
}

export const EventSavedModal: React.FC<Props> = ({ onStay, onViewSchedule, message, isUnscheduled }) => {
  return (
    <div className="h-full flex items-center justify-center">
      <div>
        <div className="flex justify-center">
          <img alt="Event Saved" src={EventSaved} />
        </div>
        <div className="flex justify-center font-sansSemiBold text-primaryTheme mb-7">{message}</div>
        <div className="flex items-center justify-center mr-3 mb-5 text-xs">
          {isUnscheduled ? (
            <Button className="w-44" theme="primary" size="large" onClick={onStay}>
              Go back
            </Button>
          ) : (
            <>
              <Button className="w-44" theme="secondary" size="large" onClick={onStay}>
                Stay on current page
              </Button>
              <Button className="w-44 ml-3" theme="primary" size="large" onClick={onViewSchedule}>
                View on Schedule
              </Button>
            </>
          )}
        </div>
      </div>
    </div>
  );
};
