import { FC } from "react";
import {
  FormBooleanInputElementVO,
  FormBooleanResponseVO,
  FormDateInputElementVO,
  FormDateResponseVO,
  FormNumberInputElementVO,
  FormNumberResponseVO,
  FormSelectInputElementVO,
  FormSelectResponseVO,
  FormStringResponseVO,
  FormTextInputElementVO,
  FormEditableTextBlockElementVO,
} from "@libs/api/generated-api";
import { FormSubmissionResponses } from "components/PatientProfile/Forms/types";
import { BooleanInput } from "components/PatientProfile/Forms/BooleanInput";
import { SelectMultipleInput } from "components/PatientProfile/Forms/SelectMultipleInput";
import { SelectExplicitInput } from "components/PatientProfile/Forms/SelectExplicitInput";
import { SelectSingleInput } from "components/PatientProfile/Forms/SelectSingleInput";
import { StringInput } from "components/PatientProfile/Forms/StringInput";
import { NumberInput } from "components/PatientProfile/Forms/NumberInput";
import { DateInput } from "components/PatientProfile/Forms/DateInput";
import { FormInputElement } from "components/Settings/Forms/types";
import { MultilineStringInput } from "components/PatientProfile/Forms/MultilineStringInput";

interface Props {
  element: FormInputElement;
  responses: FormSubmissionResponses;
  onUpdate?: (updater: (last: FormSubmissionResponses) => void) => void;
}

type ResponseMap = {
  EDITABLE_TEXT_BLOCK: {
    type: "EDITABLE_TEXT_BLOCK";
    element: FormEditableTextBlockElementVO;
    response: FormStringResponseVO;
  };
  DATE_INPUT: {
    type: "DATE_INPUT";
    element: FormDateInputElementVO;
    response?: FormDateResponseVO;
  };
  BOOLEAN_INPUT: {
    type: "BOOLEAN_INPUT";
    element: FormBooleanInputElementVO;
    response?: FormBooleanResponseVO;
  };
  SELECT_INPUT: {
    type: "SELECT_INPUT";
    element: FormSelectInputElementVO;
    response?: FormSelectResponseVO;
  };
  NUMBER_INPUT: {
    type: "NUMBER_INPUT";
    element: FormNumberInputElementVO;
    response?: FormNumberResponseVO;
  };
  TEXT_INPUT: {
    type: "TEXT_INPUT";
    element: FormTextInputElementVO;
    response?: FormStringResponseVO;
  };
};

const InputResponseTypeMap = {
  DATE_INPUT: "DATE" as const,
  BOOLEAN_INPUT: "BOOLEAN" as const,
  SELECT_INPUT: "SELECT" as const,
  NUMBER_INPUT: "NUMBER" as const,
  TEXT_INPUT: "STRING" as const,
  EDITABLE_TEXT_BLOCK: "STRING" as const,
};

const getResponseProps = <E extends FormInputElement>({
  element,
  responses,
}: {
  element: E;
  responses: FormSubmissionResponses;
}) => {
  const props = {
    type: element.type,
    element,
  };

  const response = responses[element.uuid];

  if (response && InputResponseTypeMap[element.type] === response.type) {
    return {
      ...props,
      response,
    } as ResponseMap[E["type"]];
  }

  return props as ResponseMap[E["type"]];
};

export const FormInputs: FC<Props> = ({ element, onUpdate, responses }) => {
  const pair = getResponseProps({ element, responses });

  return pair.type === "BOOLEAN_INPUT" ? (
    <BooleanInput element={pair.element} response={pair.response} onUpdate={onUpdate} responses={responses} />
  ) : pair.type === "NUMBER_INPUT" ? (
    <NumberInput element={pair.element} response={pair.response} onUpdate={onUpdate} />
  ) : pair.type === "DATE_INPUT" ? (
    <DateInput element={pair.element} response={pair.response} onUpdate={onUpdate} />
  ) : pair.type === "SELECT_INPUT" ? (
    pair.element.settings.includes("ALLOW_MULTIPLE_SELECTIONS") ? (
      <SelectMultipleInput element={pair.element} response={pair.response} onUpdate={onUpdate} />
    ) : pair.element.settings.includes("ENFORCE_EXPLICIT_CONSENT") ? (
      <SelectExplicitInput element={pair.element} response={pair.response} onUpdate={onUpdate} />
    ) : (
      <SelectSingleInput element={pair.element} response={pair.response} onUpdate={onUpdate} />
    )
  ) : (pair.type === "TEXT_INPUT" && pair.element.settings.includes("MULTILINE")) ||
    pair.type === "EDITABLE_TEXT_BLOCK" ? (
    <MultilineStringInput element={pair.element} response={pair.response} onUpdate={onUpdate} />
  ) : (
    <StringInput element={pair.element} response={pair.response} onUpdate={onUpdate} />
  );
};
