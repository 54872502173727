import React from "react";
import { SingleValue } from "react-select";
import { EmergencyContactDetailsVO } from "@libs/api/generated-api";
import { FormFieldProps } from "@libs/components/UI/FormField";
import { FormFieldSelect } from "components/UI/FormFieldSelect";

export const FormFieldSelectEmergencyRelationship: React.FC<
  FormFieldProps & {
    value: EmergencyContactDetailsVO["relationship"];
    placeholder?: string;
    menuPlacement?: "auto" | "top" | "bottom";
    onChange: (
      option: SingleValue<{
        label: string;
        value: NonNullable<EmergencyContactDetailsVO["relationship"]>;
      }>
    ) => void;
  }
> = ({ layout, label, value, edit = true, onChange, menuPlacement = "auto", placeholder, error }) => {
  return (
    <FormFieldSelect
      layout={layout}
      label={label}
      display="label"
      edit={edit}
      value={value}
      error={error}
      menuPlacement={menuPlacement}
      placeholder={placeholder}
      options={[
        { label: "Spouse", value: "SPOUSE" },
        { label: "Parent", value: "PARENT" },
        { label: "Family Member", value: "FAMILY_MEMBER" },
        { label: "Friend", value: "FRIEND" },
        { label: "Other", value: "OTHER" },
      ]}
      onChange={onChange}
    />
  );
};
