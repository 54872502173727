import { InsurancePlanSummaryVO } from "@libs/api/generated-api";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { Section } from "components/Insurance/Section";
import { planCxStyles } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/styles";
import { PlanFields } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/usePlanFields";
import { LinkedPlanSection } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/CarriersAndPlansTab/LinkedPlanSection";
import { UnlinkedPlanSection } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/CarriersAndPlansTab/UnlinkedPlanSection";

interface Props {
  carrierName: string;

  hasLinkedPlan: boolean;
  insurancePlansInfiniteQuery: UseInfiniteApiQueryResult<InsurancePlanSummaryVO[]>;
  isLinking: boolean;
  onCreateNewPlan: Func;
  onLinkPlan: (planUuid: string) => void;
  onSearch: (search: string) => void;
  planFields: PlanFields;
  searchString: string;
}

export const PlanSection: React.FC<Props> = ({
  carrierName,
  hasLinkedPlan,
  insurancePlansInfiniteQuery,
  isLinking,
  onCreateNewPlan,
  onLinkPlan,
  onSearch,
  planFields,
  searchString,
}) => {
  return (
    <Section className={planCxStyles.section} includePadding={false} title="Plan" useCustomStyling={true}>
      {hasLinkedPlan ? (
        <LinkedPlanSection planFields={planFields} />
      ) : (
        <UnlinkedPlanSection
          carrierName={carrierName}
          insurancePlansInfiniteQuery={insurancePlansInfiniteQuery}
          isLinking={isLinking}
          onCreateNewPlan={onCreateNewPlan}
          onLinkPlan={onLinkPlan}
          onSearch={onSearch}
          searchString={searchString}
        />
      )}
    </Section>
  );
};
