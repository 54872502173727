import React from "react";
import { cx } from "@libs/utils/cx";

export const cxStyles = {
  container: `
    text-xs
    py-2
    px-8
    border
    rounded-md
    font-sansSemiBold
    hover:enabled:opacity-70
    disabled:opacity-50
  `,
  themes: {
    primary: `
    text-archyBlue-300
    border-archyBlue-300
  `,
    secondary: `
    text-white
    border-white`,
  },
};
export type ButtonProps = {
  disabled?: boolean;
  children: React.ReactNode;
  theme?: keyof typeof cxStyles.themes;
};

export const DarkRoomButton = React.forwardRef<
  HTMLButtonElement,
  ButtonProps & React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, children, type, theme = "primary", disabled = false, ...rest }, ref) => (
  <button
    ref={ref}
    {...rest}
    // eslint-disable-next-line react/button-has-type
    type={type}
    disabled={disabled}
    className={cx(cxStyles.container, cxStyles.themes[theme], className)}
  >
    {children}
  </button>
));
