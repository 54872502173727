import React from "react";
import { clampImageDimensions } from "@libs/utils/image";
import { blobToFile } from "@libs/utils/dataUrl";
import { Spinner } from "@libs/components/UI/Spinner";
import { Button } from "@libs/components/UI/Button";
import { Modal } from "@libs/components/UI/Modal";
import { ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import { useCameraStream } from "components/ImageCapturing/useCameraStream";
import { useCaptureCameraImage } from "components/ImageCapturing/useCaptureCameraImage";
import { UseCameraCaptureState } from "components/ImageCapturing/useCameraCapture";
import { useMediaPermissions } from "utils/useMediaPermission";
import { ImageLayoutItem } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";
import { UploadCallback } from "components/PatientProfile/Imaging/MountRoute/types";
import { useImagingDeviceSettings } from "components/PatientProfile/Imaging/hooks/useImagingDeviceSettings";
import { handleError } from "utils/handleError";
import { CameraVideoPreview } from "components/ImageCapturing/CameraVideoPreview";

type Props = {
  cameraCapture: UseCameraCaptureState;
  closeModal: () => void;
  deviceId: string;
  label?: string;
  handleUploadPhoto: UploadCallback;
  getNewUploadSpot: () => ImageLayoutItem;
};

const videoPermission = { video: true };

export const CameraUploadModal: React.FC<Props> = ({
  closeModal,
  handleUploadPhoto,
  deviceId,
  label,
  getNewUploadSpot,
}) => {
  // const { cameraSources } = cameraCapture;
  useMediaPermissions(videoPermission);

  const { cameraSettings } = useImagingDeviceSettings();
  const cameraSetting = React.useMemo(
    () => cameraSettings.find(({ id }) => deviceId === id),
    [cameraSettings, deviceId]
  );

  const { stream, cancelStream, requestStream, isRequestingStream } = useCameraStream(deviceId);
  const captureCameraImage = useCaptureCameraImage(stream);

  const videoRef = React.useRef<HTMLVideoElement | null>(null);
  const [isTakingPhoto, setIsTakingPhoto] = React.useState(false);
  const [imageUrlBlob, setImageUrlBlob] = React.useState<string | null>();
  const onDone = React.useCallback(() => {
    if (videoRef.current) {
      videoRef.current.srcObject = null;
    }

    closeModal();
    cancelStream();
  }, [cancelStream, closeModal]);

  React.useEffect(() => {
    requestStream();
  }, [requestStream]);

  const capture = React.useCallback(async () => {
    setIsTakingPhoto(true);

    const uploadSpot = getNewUploadSpot();
    const blob = await captureCameraImage.capture();

    setIsTakingPhoto(false);

    if (blob) {
      const dimensions1440p = { w: 2560, h: 1440 };
      const resized = await clampImageDimensions(blob, dimensions1440p);

      setImageUrlBlob(resized.url);

      if (resized.blob) {
        try {
          await handleUploadPhoto(
            {
              imageFile: blobToFile(resized.blob, "webcamImage.png"),
              height: resized.dimensions.height,
              width: resized.dimensions.width,
              positionI: uploadSpot.i,
              positionX: uploadSpot.x,
              positionY: uploadSpot.y,
              type: "PHOTO",
            },
            deviceId
          );
        } catch (e) {
          handleError(e);
        }
      } else {
        handleError(new Error("Error resizing dimensions"));
      }

      resized.cleanup();
      setImageUrlBlob(null);
    }
  }, [getNewUploadSpot, captureCameraImage, handleUploadPhoto, deviceId]);

  React.useEffect(() => {
    const onKeyDown = (e: KeyboardEvent) => {
      const SPACEBAR = " ";

      if (e.key === SPACEBAR && !isTakingPhoto && !isRequestingStream) {
        capture();
      }
    };

    document.addEventListener("keydown", onKeyDown);

    return () => {
      document.removeEventListener("keydown", onKeyDown);
    };
  }, [capture, isRequestingStream, isTakingPhoto]);

  return (
    <Modal height={700} title={cameraSetting?.overriddenLabel ?? label} onClose={onDone}>
      <ModalContent padding="lg" className="justify-center flex">
        <CameraVideoPreview
          key={stream?.id}
          className={imageUrlBlob ? "hidden" : undefined}
          stream={stream}
        />

        {imageUrlBlob && (
          <img src={imageUrlBlob} alt="capture-preview" id="preview_image" className="object-contain" />
        )}
      </ModalContent>
      <ModalFooter className="min-h-10">
        {isTakingPhoto || Boolean(imageUrlBlob) ? (
          <Spinner size="sm" variant="primary" animation="border" />
        ) : (
          <Button disabled={isRequestingStream} title="Capture Image (SPACE)" onClick={capture}>
            Acquire Image
          </Button>
        )}
      </ModalFooter>
    </Modal>
  );
};
