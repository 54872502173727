import React from "react";
import { startOfDay } from "date-fns";
import { useReactToPrint } from "react-to-print";
import { useBoolean } from "@libs/hooks/useBoolean";
import { formatLongDayOfYear } from "@libs/utils/date";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as PrinterIcon } from "@libs/assets/icons/send-to-printer.svg";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { flushSync } from "react-dom";
import { Print } from "components/DailyHuddle/Print";
import { HuddleEntryGroupsByDate } from "components/DailyHuddle/types";

export const DailyHuddlePrintButton: React.FC<{
  practice: PracticeInfoVO;
  excludedTagIds: number[];
  entryGroupsByDate: HuddleEntryGroupsByDate;
}> = ({ practice, excludedTagIds, entryGroupsByDate }) => {
  const printRequest = useBoolean(false);
  const dailyHuddleRef = React.useRef<HTMLDivElement>(null);
  const handlePrint = useReactToPrint({
    content: () => dailyHuddleRef.current,
    documentTitle: `Daily Huddle ${formatLongDayOfYear(startOfDay(new Date()))}`,
    pageStyle: "@page { size: letter portrait; margin: 38px; }",
    onAfterPrint: printRequest.off,
  });

  return (
    <>
      <ButtonIcon
        SvgIcon={PrinterIcon}
        onClick={() => {
          flushSync(() => printRequest.on());
          handlePrint();
        }}
      />

      {printRequest.isOn ? (
        <div className="hidden">
          {/* Printable version of the table: */}

          <Print
            excludedTagIds={excludedTagIds}
            entryGroupsByDate={entryGroupsByDate}
            practice={practice}
            ref={dailyHuddleRef}
          />
        </div>
      ) : null}
    </>
  );
};
