import { ReactNode, useState, useMemo } from "react";
import { isOneOf } from "@libs/utils/isOneOf";
import { ModalProps } from "@libs/components/UI/Modal";

type AttachModalContent = "upload" | "scan" | "document" | "uploaded";
type AttachModalProps = {
  title: ReactNode;
  onRequestClose: Func;
};

export const useAttachModalContent = ({ title, onRequestClose }: AttachModalProps) => {
  const [modalContent, setModalContent] = useState<AttachModalContent>("upload");

  const modalProps: Pick<ModalProps, "title" | "size" | "onClose"> = useMemo(
    () => ({
      title:
        modalContent === "upload"
          ? title
          : modalContent === "scan"
            ? "Scan Document"
            : modalContent === "document"
              ? "Select Document"
              : undefined,
      size: modalContent === "uploaded" ? "2xs" : "xs",
      onClose: isOneOf(modalContent, ["scan", "document"]) ? () => setModalContent("upload") : onRequestClose,
    }),
    [modalContent, title, onRequestClose]
  );

  return {
    modalProps,
    modalContent,
    handleRequestUpload: () => setModalContent("upload"),
    handleRequestScanDocument: () => setModalContent("scan"),
    handleRequestSelectDocument: () => setModalContent("document"),
    showUploadedModalContent: () => setModalContent("uploaded"),
  };
};
