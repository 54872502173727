import { FC } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { flushSync } from "react-dom";
import { getFullUrl } from "@libs/utils/location";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";

import { EmailEditor } from "components/Communications/Campaigns/EmailEditor/EmailEditor";

import { getMessageCampaign, getPatientEngagementTemplateVariables } from "api/communications/queries";
import { getUnlayerToken } from "api/settings/communications/queries";

import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";

import { paths } from "utils/routing/paths";
import { updateMessageCampaign } from "api/communications/mutations";
import { handleError } from "utils/handleError";
import { getUpdateEmailCampaignRequest } from "components/Communications/Campaigns/Campaign/utils";

export const EmailEditorRoute: FC = () => {
  const { practiceId } = useAccount();
  const { campaignUuid } = usePathParams("campaignEmail");
  const { query } = useQueryParams("campaignEmail");
  const navigate = useNavigate();
  const location = useLocation();
  const from = query.from ?? paths.communications();

  usePageTitle("Campaign");

  const makeDirty = useBoolean(false);

  const [messageCampaignQuery, unlayerTokenQuery, templateVariablesQuery] = useApiQueries([
    getMessageCampaign({ args: { practiceId, messageCampaignUuid: campaignUuid } }),
    getUnlayerToken({ args: { practiceId } }),
    getPatientEngagementTemplateVariables({
      args: {
        practiceId,
        category: "MESSAGE_CAMPAIGN",
        messageCampaignSelectionCategory: "PATIENTS",
      },
    }),
  ]);

  const [updateMessageCampaignMutation] = useApiMutations([updateMessageCampaign]);

  const handleSaveEmailTemplate = (data: { json: string; html: string }, previewUrl: string | undefined) => {
    const campaign = messageCampaignQuery.data;

    if (campaign) {
      updateMessageCampaignMutation.mutate(
        {
          practiceId,
          messageCampaignUuid: campaignUuid,
          data: getUpdateEmailCampaignRequest(campaign, { emailTemplate: data }),
        },
        {
          onError: handleError,
          onSuccess: () => {
            flushSync(() => {
              makeDirty.off();
            });
            navigate(paths.campaign({ campaignUuid }, { previewUrl }));
          },
        }
      );
    }
  };

  const handleChangeTemplate = () => {
    flushSync(() => {
      makeDirty.off();
    });
    navigate(paths.campaignTemplates({ campaignUuid }, { from: getFullUrl(location) }));
  };

  return (
    <QueryResult queries={[messageCampaignQuery, unlayerTokenQuery, templateVariablesQuery]}>
      {messageCampaignQuery.data && unlayerTokenQuery.data && templateVariablesQuery.data ? (
        <EmailEditor
          emailTemplateJson={messageCampaignQuery.data.emailTemplate?.json}
          unlayerToken={unlayerTokenQuery.data}
          isSaving={updateMessageCampaignMutation.isLoading}
          onDirty={makeDirty.on}
          isDirty={makeDirty.isOn}
          templateVariables={templateVariablesQuery.data}
          onSaveEmailTemplate={handleSaveEmailTemplate}
          onChangeTemplate={handleChangeTemplate}
          from={from}
        />
      ) : null}
    </QueryResult>
  );
};
