import { FC } from "react";
import { CustomAdjustmentTypeVO, InvoiceEntryVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import {
  invoiceEntryOrLineItemToId,
  getAdjustmentsByProcedureId,
  getMaximumDiscounts,
  getPatientProcedureDescription,
} from "components/PatientProfile/Billing/invoiceUtils";
import { checkPermission } from "components/Roles/roleUtils";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { AdjustmentRequestWithDisplayValue, InvoiceAdjustmentFormRow } from "./InvoiceAdjustmentFormRow";
import {
  EntryTable,
  EntryRow,
  RemovableAdjustmentRow,
  AddAdjustmentButtonRow,
} from "./InvoiceEntryComponents";

export const InvoiceEntryEditable: FC<{
  proposedEntry: InvoiceEntryVO;
  proposedEntryAdjustments: AdjustmentRequestWithDisplayValue[];
  calculatedEntry: InvoiceEntryVO | undefined;
  practiceAdjustments?: CustomAdjustmentTypeVO[];
  onNewAdjustment: (adjustment: AdjustmentRequestWithDisplayValue) => void;
  onRemoveAdjustment: (adjustmentIndex: number) => void;
  // eslint-disable-next-line complexity
}> = ({
  proposedEntry,
  proposedEntryAdjustments,
  calculatedEntry,
  practiceAdjustments,
  onNewAdjustment,
  onRemoveAdjustment,
}) => {
  const newAdjustmentEntry = useBoolean(false);
  const { roleV2 } = useCurrentUser();
  const canApplyAdjustments =
    checkPermission(roleV2, "BILLING", "ADD_ADJUSTMENT_CHARGE").isPermitted ||
    checkPermission(roleV2, "BILLING", "ADD_ADJUSTMENT_DISCOUNT").isPermitted;

  const calculatedAdjustments = calculatedEntry?.appointmentAdjustments ?? [];

  return (
    <EntryTable entry={calculatedEntry} calculatedAdjustments={calculatedAdjustments}>
      <EntryRow
        className="font-sansSemiBold"
        date={<DateWithTooltip date={proposedEntry.createdAt} dateAsSeconds={true} format="P" />}
        type={proposedEntry.entryReference.type === "APPOINTMENT" ? "Appt" : "Adjust"}
        description={
          proposedEntry.entryReference.type === "APPOINTMENT"
            ? proposedEntry.appointmentSnapshot?.providerName ?? ""
            : `${proposedEntry.adjustmentName ?? ""}: ${proposedEntry.adjustmentNote ?? ""}`
        }
        charges={proposedEntry.amount}
        adjustments={calculatedAdjustments.map((a) => a.amount)}
        calculatedAmount={calculatedEntry?.amount}
      />
      {proposedEntry.lineItems.map((lineItem, i) => {
        const procedureAdjustments = getAdjustmentsByProcedureId(
          Number(lineItem.lineItemReference.id),
          calculatedAdjustments
        );

        const lineItemDescription = lineItem.patientProcedureSnapshot
          ? getPatientProcedureDescription(lineItem.patientProcedureSnapshot)
          : "";

        return (
          <EntryRow
            key={`line-item-${invoiceEntryOrLineItemToId(lineItem)}`}
            date=""
            type={lineItem.patientProcedureSnapshot?.cdtCode}
            description={lineItemDescription}
            charges={lineItem.amount}
            adjustments={procedureAdjustments.map((procAdjust) => procAdjust.amount)}
            calculatedAmount={calculatedEntry?.lineItems[i].amount}
          />
        );
      })}
      {
        // Only items of type APPOINTMENT can have adjustments applied to them
        proposedEntry.entryReference.type === "APPOINTMENT" && (
          <>
            {proposedEntryAdjustments.map((adjustment, i) => (
              <RemovableAdjustmentRow
                practiceAdjustments={practiceAdjustments}
                key={adjustment.randomId}
                adjustment={adjustment}
                numOfAdjustments={calculatedAdjustments.length}
                onRemoveAdjustment={() => onRemoveAdjustment(i)}
              />
            ))}
            {newAdjustmentEntry.isOn && calculatedEntry && (
              <InvoiceAdjustmentFormRow
                proposedEntry={proposedEntry}
                appointmentId={Number(proposedEntry.entryReference.id)}
                practiceAdjustments={practiceAdjustments}
                maxDiscountCents={getMaximumDiscounts(calculatedEntry)}
                onApply={(adjustment) => {
                  onNewAdjustment(adjustment);
                  newAdjustmentEntry.off();
                }}
                onCancel={newAdjustmentEntry.off}
              />
            )}
            {canApplyAdjustments && (
              <AddAdjustmentButtonRow
                disabled={newAdjustmentEntry.isOn}
                onNewAdjustmentClick={newAdjustmentEntry.on}
              />
            )}
          </>
        )
      }
    </EntryTable>
  );
};
