import React, { FC, ReactNode, useMemo } from "react";
import { MountVO, TransferImagesRequest } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { pluralize } from "@libs/utils/pluralize";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { getPhotosCount } from "components/PatientProfile/Imaging/PatientMountsList/TransferMountFlyover";
import { useInfiniteScrollingSelectComponents } from "hooks/useInfiniteScrollingSelectComponents";

export type ImageTransferData = Pick<TransferImagesRequest, "targetMountId" | "targetPatientId">;

interface Props {
  selectedMount?: MountVO;
  mountsInfiniteQuery: UseInfiniteApiQueryResult<MountVO[]>;
  onChange: (mountId: number) => void;
  hideMountIds?: Set<number>;
  isDark?: boolean;
  label?: string;
  className?: string;
}

export const SelectPatientMountOption: React.FC<{ mount: MountVO }> = ({ mount }) => {
  const photoCount = getPhotosCount(mount);

  return (
    <div className="grid grid-cols-3">
      <div className="truncate">{mount.name === "" ? mount.layout : mount.name}</div>
      <div>{formatISODate(mount.date)}</div>
      <div>{`${photoCount} ${pluralize(photoCount, "image", "images")}`}</div>
    </div>
  );
};

export const SelectPatientMount: FC<Props> = ({
  mountsInfiniteQuery,
  selectedMount,
  hideMountIds,
  onChange,
  label,
  isDark,
  className,
}) => {
  const customComponents = useInfiniteScrollingSelectComponents<
    { label: ReactNode; value: number },
    false,
    number
  >({
    infiniteQuery: mountsInfiniteQuery,
    isDark,
  });
  const mountList = useFlattenPages(mountsInfiniteQuery.data);
  const filteredMountList = useMemo(() => {
    const allMounts = mountList ?? [];

    return hideMountIds ? allMounts.filter((mount) => !hideMountIds.has(mount.id)) : allMounts;
  }, [hideMountIds, mountList]);
  const mountOptions = useMemo(() => {
    let mountsAvailable = filteredMountList;

    if (selectedMount && !filteredMountList.some((mount) => mount.id === selectedMount.id)) {
      mountsAvailable = [selectedMount, ...filteredMountList];
    }

    return mountsAvailable.map((mount) => {
      return {
        label: <SelectPatientMountOption mount={mount} />,
        value: mount.id,
      };
    });
  }, [filteredMountList, selectedMount]);

  return (
    <FormFieldSelect
      components={customComponents}
      display="label"
      edit
      isDark={isDark}
      isClearable={false}
      label={label}
      layout="labelOut"
      className={className}
      onItemSelected={onChange}
      isSearchable={false}
      options={mountOptions}
      placeholder="Select mount"
      value={selectedMount?.id}
    />
  );
};
