import { getNextPageParam, makeInfiniteQuery, makeQuery } from "@libs/utils/queries";
import { oneMinuteCache, fiveMinuteCache } from "@libs/utils/queryCache";

export const listFormTasks = makeQuery({
  queryKey: ["practices", "listFormTasks"],
  formatParams: ({
    practiceId,
    patientId,
    ...query
  }: {
    practiceId: number;
    patientId: number;
    states?: ("PENDING" | "COMPLETED" | "ARCHIVED")[];
    appointmentIds?: number[];
    pageNumber: number;
    pageSize: number;
  }) => [practiceId, patientId, query],
  queryOptions: fiveMinuteCache,
});

export const listInfiniteFormTasks = makeInfiniteQuery({
  queryKey: ["practices", "listFormTasks"],
  formatParams: ({
    practiceId,
    patientId,
    ...query
  }: {
    practiceId: number;
    patientId: number;
    states?: ("PENDING" | "COMPLETED" | "ARCHIVED")[];
    appointmentIds?: number[];
    pageNumber: number;
    pageSize: number;
  }) => [practiceId, patientId, query],
  queryOptions: {
    ...oneMinuteCache,
    getNextPageParam,
  },
});
