import { FC, ChangeEventHandler, useCallback } from "react";
import { PatientSummaryVO } from "@libs/api/generated-api";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { TableGrid, HeaderCheckboxCell } from "@libs/components/UI/GridTableComponents";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import EmptyPatientList from "assets/images/empty-people-list.svg";
import { InifinitePatientsQueryResponse, OrderBy, SortColumn } from "api/patients/queries";
import { TableHeaderCell } from "components/Patients/TableHeaderCell";
import { PatientRow } from "components/Patients/Row";
import { PatientsQuery } from "utils/routing/patient";

interface Props {
  onAddAppointment: (patientId: number) => void;
  onCopyEmail: (patient: PatientSummaryVO) => void;
  onRowClick: (patientId: number) => void;
  onSortChange: (orderBy: OrderBy, sortColumn: SortColumn) => void;
  onSelectAllRows: Func;
  onDeselectAllRows: Func;
  onCheckboxChange: ChangeEventHandler<HTMLInputElement>;
  selectedRows: Set<number>;
  selectedCount: number;
  totalRows: number;
  patientsQuery: InifinitePatientsQueryResponse;
  queryStateApi: PatientsQuery;
  queryKey: string;
}

export interface HeaderItem {
  id: SortColumn | "checkbox" | "menu";
  label: string;
  sortable?: boolean;
  width: string;
}

const tableHeaders: HeaderItem[] = [
  { id: "checkbox", label: "", width: "min-content" },
  { id: "lastName", label: "Name", sortable: true, width: "1fr" },
  { id: "phone", label: "Phone", width: "10rem" },
  { id: "ssn", label: "SSN", width: "10rem" },
  { id: "balanceAmount", label: "Pt Balance", sortable: true, width: "10rem" },
  { id: "insurance", label: "Primary Insurance", width: "1fr" },
  { id: "nextAppointment", label: "Next Appt", sortable: true, width: "10rem" },
  { id: "menu", label: "", width: "min-content" },
];

export const PatientsTable: FC<Props> = ({
  onAddAppointment,
  onCopyEmail,
  onRowClick,
  onSortChange,
  onSelectAllRows,
  onDeselectAllRows,
  onCheckboxChange,
  selectedRows,
  selectedCount,
  totalRows,
  patientsQuery,
  queryStateApi,
  queryKey,
}) => {
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: patientsQuery });
  const patients = useFlattenPages(patientsQuery.data);

  const handleGetScrollId = useCallback(
    ({ id }: { id: string }) => {
      return `${id}-${queryKey}`;
    },
    [queryKey]
  );

  return (
    <PersistScrollPosition
      resetScrollKey={queryKey}
      getScrollId={handleGetScrollId}
      id="patientsTable"
      className="flex-1 min-h-0 overflow-y-auto relative z-0"
      ref={rootRef}
    >
      <ScrollableInfiniteQueryResult
        infiniteQuery={patientsQuery}
        loading={<LoadingContent className="flex rounded-none" />}
        noResults={
          <div className="flex h-full flex-col items-center justify-center">
            <img alt="No Patients Added" src={EmptyPatientList} />
            <span className="mt-5 text-xs">No Patients Added</span>
          </div>
        }
        scrollRef={scrollRef}
      >
        <TableGrid columnWidths={tableHeaders.map(({ width }) => width)}>
          {tableHeaders.map((item) =>
            item.id === "checkbox" ? (
              <HeaderCheckboxCell
                key={item.id}
                selectedCount={selectedCount}
                totalRows={totalRows}
                onSelectAllRows={onSelectAllRows}
                onDeselectAllRows={onDeselectAllRows}
                size="short"
              />
            ) : (
              <TableHeaderCell
                key={item.id}
                headerItem={item}
                onSortChange={onSortChange}
                queryStateApi={queryStateApi}
              />
            )
          )}

          {patients?.map((patient) => (
            <PatientRow
              key={patient.id}
              onAddAppointment={onAddAppointment}
              onCopyEmail={onCopyEmail}
              onRowClick={onRowClick}
              onCheckboxChange={onCheckboxChange}
              checked={selectedRows.has(patient.id)}
              patient={patient}
            />
          ))}
        </TableGrid>
      </ScrollableInfiniteQueryResult>
    </PersistScrollPosition>
  );
};
