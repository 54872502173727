import { required, phoneNumber, url } from "@libs/utils/validators";
import { addressSchema } from "@libs/utils/address";

export const practiceDetailsSchema = {
  name: [
    {
      $v: required,
      $error: "Legal business name is required",
    },
  ],
  businessType: [
    {
      $v: required,
      $error: "Business type is required",
    },
  ],
  contactDetails: {
    phoneNumber: [
      {
        $v: required,
        $error: "Phone number is required",
      },
      {
        $v: phoneNumber,
        $error: "Phone number is invalid",
      },
    ],
  },
  website: [
    {
      $v: required,
      $error: "Website is required",
    },
    {
      $v: url,
      $error: "Website is invalid",
    },
  ],
  physicalAddress: addressSchema,
};
