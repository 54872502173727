import { Auth } from "aws-amplify";
import type { AmplifyUser } from "@aws-amplify/ui";
import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isDefined } from "@libs/utils/types";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Spinner } from "@libs/components/UI/Spinner";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button, cxStyles as buttonStyles } from "@libs/components/UI/Button";
import { Form } from "@libs/components/UI/Form";
import { ArchyQRCode } from "components/UI/ArchyQRCode";
import { handleError } from "utils/handleError";
import { updateUserMfa } from "api/user/mutations";
import { OTPInput } from "components/UI/OTPInput";
import { useMfaChallenge } from "components/SignIn/hooks/useMfaChallenge";
import { paths } from "utils/routing/paths";

const cxStyles = {
  section: "flex flex-col gap-1",
  description: "mt-1",
};

const OTP_LENGTH = 6;

export const MfaSetupInstructions: React.FC<{
  user: AmplifyUser;
  onVerifyComplete: Func;
  onSkip: Func;
  mfaSetupRequired: boolean;
}> = ({ user, onVerifyComplete, onSkip, mfaSetupRequired }) => {
  const [secretCode, setSecretCode] = useState<string | null>(null);

  const { mfaEntryState, handleOtpChange, validation } = useMfaChallenge();
  const submitting = useBoolean(false);
  const submittingOn = submitting.on;
  const submittingOff = submitting.off;
  const qrCode = useMemo(() => {
    const username = user.username;

    if (secretCode && isDefined(username)) {
      return `otpauth://totp/Archy:${user.attributes?.email ?? ""}?secret=${secretCode}&issuer=Archy.com`;
    }

    return undefined;
  }, [secretCode, user]);
  const hasInitiatedSecretCode = useRef(false);

  useEffect(() => {
    const username = user.username;

    if (!hasInitiatedSecretCode.current && !secretCode && isDefined(username)) {
      hasInitiatedSecretCode.current = true;

      Auth.setupTOTP(user).then((generatedCode) => {
        setSecretCode(generatedCode);
      });
    }
  }, [secretCode, user]);

  const [updateUserMfaMutation] = useApiMutations([updateUserMfa]);
  const updateUserMfaMutateAsync = updateUserMfaMutation.mutateAsync;
  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      submittingOn();

      if (validation.validate().$isValid) {
        try {
          // AWS Cognito has an issue with device remembering
          // more details here: https://github.com/aws-amplify/amplify-js/issues/8977#issuecomment-1594786557
          // the TLDR; is that we need to always call rememberDevice during setup, and then call 'forgetDevice' in the first MFA flow if they don't check 'remember device'
          // See MFAChallenge for that flow
          await Auth.rememberDevice();
          await Auth.verifyTotpToken(user, mfaEntryState.otp);

          await updateUserMfaMutateAsync({
            data: {
              mfaEnabled: true,
            },
          });

          onVerifyComplete();

          return;
        } catch (err) {
          handleError(err);
          handleOtpChange("");
        }

        submittingOff();
      }
    },
    [
      submittingOn,
      validation,
      submittingOff,
      user,
      mfaEntryState.otp,
      updateUserMfaMutateAsync,
      onVerifyComplete,
      handleOtpChange,
    ]
  );

  return (
    <div className="flex flex-col gap-6 justify-center">
      <div className="text-center text-xs">
        To ensure we know it&apos;s really you when you log in, you&apos;ll need to enter a code from your
        authenticator app.
      </div>

      <Form className="flex flex-col text-xs" onSubmit={handleSubmit}>
        <div className={cxStyles.section}>
          <div>Step 1</div>
          <div className="text-xs font-sansSemiBold">Download an authenticator app on your phone</div>
          <div className={cxStyles.description}>
            Download an authenticator app, such as Authy, Google Authenticator or Microsoft Authenticator, on
            your phone.
          </div>
        </div>

        <div className="flex gap-4 mt-8">
          <div className={cxStyles.section}>
            <div>Step 2</div>
            <div className="text-xs font-sansSemiBold">Scan the QR code</div>
            <div className={cxStyles.description}>
              Add your Archy account to your authenticator app by scanning the QR code.
            </div>
          </div>
          <div
            className={`
              flex
              items-center
              justify-center
              rounded-md
              border
              min-w-44
              min-h-44
              border-slate-300
            `}
          >
            {qrCode ? (
              <ArchyQRCode value={qrCode} size={150} bgColor="transparent" />
            ) : (
              <Spinner animation="border" variant="primary" />
            )}
          </div>
        </div>
        <div className={cxStyles.section}>
          <div>Step 3</div>
          <div className="flex flex-col gap-2">
            <div className="text-xs font-sansSemiBold">Enter the verification code</div>
            <OTPInput
              disabled={false}
              onChange={handleOtpChange}
              OTPLength={OTP_LENGTH}
              error={Boolean(validation.result.otp.$error || mfaEntryState.errorMessage)}
              otpType="number"
              value={mfaEntryState.otp}
            />
          </div>
        </div>
        <div className="flex mt-6 justify-center">
          <div className="flex flex-col gap-4 items-center">
            <AsyncButton isLoading={submitting.isOn} type="submit" className="block content-fit min-w-button">
              Next
            </AsyncButton>
            {mfaSetupRequired ? (
              <Link className={buttonStyles.themes.link({ disabled: false })} to={paths.signOut()}>
                Sign Out
              </Link>
            ) : (
              <Button theme="link" onClick={onSkip}>
                Skip Until Next Sign In
              </Button>
            )}

            <div className="text-xs mt-4">
              <strong>Need Help?</strong> Contact your practice administrator.
            </div>
          </div>
        </div>
      </Form>
    </div>
  );
};
