import React from "react";
import { DentalProcedureVO } from "@libs/api/generated-api";
import { createSelectStyles } from "@libs/components/UI/selectStyles";
import { getFilterSelectedValues, upsertDashFilter } from "components/Dashboard/utils/filters";
import { ProcedureDataCode, ProcedureSelector } from "components/ProcedureSelector/ProcedureSelector";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";
import { DASHBOARD_SELECT_STYLES } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";

type Props = {
  procedures?: DentalProcedureVO[];
  filters: DashboardFilter[];
  onFiltersChanged: (filters: DashboardFilter[]) => void;
};

export const TableSelectProcedureFilters: React.FC<Props> = ({
  procedures: allProcedures,
  filters,
  onFiltersChanged,
}) => {
  const proceduresSelected = React.useMemo(() => {
    return getFilterSelectedValues({
      filters,
      type: "dentalProcedureId",
    });
  }, [filters]);

  const customProcedureDropdownStyles = React.useMemo(
    () =>
      createSelectStyles<string, ProcedureDataCode, true>({
        ...DASHBOARD_SELECT_STYLES,
        option: (provided, props) => {
          const procedureId = `${props.data.data.id}`;

          return {
            ...provided,
            cursor: "pointer",
            fontWeight: proceduresSelected.has(procedureId) ? "bolder" : undefined,
          };
        },
        menu: (provided) => {
          return {
            ...provided,
            width: "22rem",
          };
        },
      }),
    [proceduresSelected]
  );
  const handleProcedureSelected = React.useCallback(
    (procedures: DentalProcedureVO[]) => {
      const [procedure] = procedures;
      const updatedFilters = upsertDashFilter(
        filters,
        {
          type: "dentalProcedureId",
          values: [`${procedure.id}`],
        },
        {
          mergeWithExisting: true,
          toggle: true,
        }
      );

      onFiltersChanged(updatedFilters);
    },
    [onFiltersChanged, filters]
  );

  return (
    <ProcedureSelector
      procedures={allProcedures}
      // Values are populated elsewhere in QueryFilterPills showing the procedures selected in the filter
      values={undefined}
      mode="multi"
      onChange={handleProcedureSelected}
      className="min-w-[12rem] z-30"
      styles={customProcedureDropdownStyles}
      placeholder="Select Procedures..."
      closeMenuOnSelect={false}
    />
  );
};
