import { FC } from "react";

import { useBoolean } from "@libs/hooks/useBoolean";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";

interface Props {
  name: string;
  description: string;
  previewImage: string;
  onPreviewTemplate?: Func;
  onApplyTemplate: Func;
}

export const TemplateCard: FC<Props> = ({
  name,
  description,
  previewImage,
  onPreviewTemplate,
  onApplyTemplate,
}) => {
  const applying = useBoolean(false);

  const handleApplyTemplate = () => {
    applying.on();
    onApplyTemplate();
  };

  return (
    <div
      className={`
        flex
        max-w-[500px]
        h-64
        rounded
        border
        border-slate-200
        divide-x
        divide-slate-200
        overflow-hidden
      `}
    >
      <div className="flex w-52 bg-slate-50 p-3 rounded-tl rounded-bl text-xxs">
        <img className="object-cover" src={previewImage} alt={`${name} Preview`} />
      </div>

      <div className="flex-1 flex flex-col justify-between gap-y-3 p-6 min-w-44">
        <h3 className="font-sansSemiBold text-sm">{name}</h3>
        <p className="flex-1 text-xs text-slate-700">{description}</p>
        <div className="flex justify-end">
          {onPreviewTemplate ? (
            <Button className="basis-1/2 h-8 text-xs" onClick={onPreviewTemplate} theme="link" size="custom">
              Preview
            </Button>
          ) : null}
          <AsyncButton
            className="basis-1/2 h-8 text-xs"
            onClick={handleApplyTemplate}
            isLoading={applying.isOn}
            size="custom"
          >
            Apply
          </AsyncButton>
        </div>
      </div>
    </div>
  );
};
