import { FC } from "react";
import { LabCaseTypeVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { ButtonCell, HeaderCell, IconsCell, Row, TableGrid } from "@libs/components/UI/GridTableComponents";
import { ProcedurePillsWithOverflow } from "components/LabCases/LabComponents";

export const LabCaseTypesTable: React.FC<{
  labCaseTypes: LabCaseTypeVO[];
  onEdit: (labCaseTypeUuid: LabCaseTypeVO["uuid"]) => void;
  onDelete: (labCaseTypeUuid: LabCaseTypeVO["uuid"]) => void;
}> = ({ labCaseTypes, onEdit, onDelete }) => {
  return (
    <TableGrid columnWidths={["416px", "1fr", "auto"]}>
      <Headers />
      {labCaseTypes.map((labCaseType) => (
        <Row key={labCaseType.uuid} highlightOnHover>
          <ButtonCell
            onDoubleClick={() => onEdit(labCaseType.uuid)}
            verticalPadding="skinny"
            className="flex items-center h-10"
          >
            {labCaseType.name}
          </ButtonCell>
          <ButtonCell
            onDoubleClick={() => onEdit(labCaseType.uuid)}
            verticalPadding="skinny"
            className="flex items-center h-10"
          >
            <ProcedurePillsWithOverflow
              procedures={labCaseType.dentalProcedures.map((proc) => ({
                id: proc.id,
                displayName: proc.simpleName,
                description: proc.name,
              }))}
            />
          </ButtonCell>
          <IconsCell verticalPadding="skinny" className="flex items-center h-10">
            <LabCaseTypeMenu
              onEdit={() => onEdit(labCaseType.uuid)}
              onDelete={labCaseType.name === "Other" ? undefined : () => onDelete(labCaseType.uuid)}
            />
          </IconsCell>
        </Row>
      ))}
    </TableGrid>
  );
};

const Headers: FC = () => (
  <>
    <HeaderCell size="short">Name</HeaderCell>
    <HeaderCell size="short">Procedures</HeaderCell>
    {/* menu column */}
    <HeaderCell size="short" />
  </>
);

const LabCaseTypeMenu: React.FC<{ onEdit: Func; onDelete?: Func }> = ({ onEdit, onDelete }) => {
  const menu = useBoolean(false);

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      menuContent={
        <MenuOptions
          options={createMenuOptions(
            { label: "Edit", value: "edit", SvgIcon: EditIcon },
            { label: "Delete", value: "delete", SvgIcon: DeleteIcon, disabled: !onDelete }
          )}
          onOptionClick={(option) => {
            switch (option.value) {
              case "edit": {
                onEdit();
                break;
              }
              case "delete": {
                onDelete?.();
                break;
              }
              // no default
            }
          }}
        />
      }
      onRequestClose={menu.off}
    >
      {(props) => {
        return <ButtonIcon {...props} size="md" SvgIcon={MenuIcon} />;
      }}
    </ButtonMenu>
  );
};
