import { ClaimsList } from "components/Claims/Claims/ClaimsList";
import { usePatientClaimsTabs } from "components/PatientProfile/Claims/tabs";
import { usePathParams } from "hooks/usePathParams";
import { PatientClaimsAppHistoryProvider } from "components/PatientProfile/Claims/PatientClaimsLinksContext";

export const PreAuthsTabRoute: React.FC = () => {
  const { patientId } = usePathParams("patientClaims");
  const tabs = usePatientClaimsTabs();

  return (
    <PatientClaimsAppHistoryProvider name="preAuths">
      <ClaimsList isPreAuth={true} routeName="patientClaims" patientId={patientId} tabs={tabs} />
    </PatientClaimsAppHistoryProvider>
  );
};
