import { FC, ReactNode } from "react";
import { TreatmentPlanVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { ReactComponent as Active } from "@libs/assets/icons/active.svg";

interface Props {
  title: ReactNode;
  emptyContent?: ReactNode;
  onSelectTxPlan: (txPlan: TreatmentPlanVO) => void;
  txPlanUuid?: string;
  txPlans?: TreatmentPlanVO[];
}

export const TreatmentPlanMenuOptions: FC<Props> = ({
  title,
  emptyContent,
  onSelectTxPlan,
  txPlanUuid,
  txPlans,
}) => {
  return (
    <div className="flex-shrink-0 min-w-52">
      <h5 className="font-sansSemiBold mb-2">{title}</h5>
      <div className="flex flex-col gap-y-4">
        {txPlans?.length
          ? txPlans.map((txPlan) => (
              <button
                className={cx(
                  "flex whitespace-nowrap text-left hover:text-primaryTheme",
                  txPlanUuid === txPlan.uuid && "text-primaryTheme font-sansSemiBold"
                )}
                key={txPlan.uuid}
                type="button"
                onClick={() => {
                  onSelectTxPlan(txPlan);
                }}
              >
                {txPlan.name}{" "}
                {txPlan.state === "ACTIVE" ? <Active className="fill-blue w-4 h-4 ml-2" /> : null}
              </button>
            ))
          : emptyContent}
      </div>
    </div>
  );
};
