import React, { useCallback, useMemo } from "react";
import { isWithinInterval } from "date-fns";
import { SingleValue } from "react-select";
import { YEAR_IN_DAYS, getDurationDays } from "@libs/utils/date";
import { pluralize } from "@libs/utils/pluralize";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { useReportingResolutionOptions } from "components/Dashboard/hooks/useReportingResolutionOptions";
import { TimeSeriesResolutionOption } from "components/Dashboard/types";
import {
  DEFAULT_RESOLUTION,
  DashboardChartDisplay,
  DashboardQueryWithDateNavigation,
} from "utils/routing/dashboard";

import { TimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { SelectDateRange } from "components/Dashboard/SelectDateRange";
import { getMaxDayCountForResolution, isOutOfRange } from "components/Dashboard/utils/timeSeriesDates";

type Props = {
  resolution?: TimeSeriesResolutionOption;
  onUpdate: (update: Partial<DashboardQueryWithDateNavigation>) => void;
  focusDate?: Date;
  dateWindow: TimeSeriesPageSelections;
  chartDisplay?: DashboardChartDisplay;
};

export const SelectDateWindow: React.FC<Props> = ({
  resolution,
  onUpdate,
  focusDate,
  dateWindow,
  chartDisplay,
}) => {
  const resolutionOptions = useReportingResolutionOptions();

  const handleResolutionChanged = React.useCallback(
    (option: SingleValue<SelectOption<TimeSeriesResolutionOption>>) => {
      if (option?.value && option.value !== resolution) {
        onUpdate({
          resolution: option.value === DEFAULT_RESOLUTION ? undefined : option.value,
          focusDate:
            focusDate &&
            isWithinInterval(focusDate, {
              start: dateWindow.startDate,
              end: dateWindow.endDate,
            })
              ? focusDate
              : undefined,
        });
      }
    },
    [dateWindow.endDate, dateWindow.startDate, focusDate, onUpdate, resolution]
  );

  const handleRangeSelected = useCallback(
    (range: { startDate: Date; endDate: Date }) => {
      const params: Partial<DashboardQueryWithDateNavigation> = {
        focusDate: undefined,
        startDate: range.startDate,
        endDate: range.endDate,
      };

      if (
        isOutOfRange({
          ...range,
          resolution,
          chartDisplay,
        })
      ) {
        params.resolution = resolutionOptions.find((option) => {
          return !isOutOfRange({
            ...range,
            resolution: option.value,
            chartDisplay,
          });
        })?.value;
      }

      onUpdate(params);
    },
    [chartDisplay, onUpdate, resolution, resolutionOptions]
  );

  const currentDurationDays = useMemo(
    () => getDurationDays({ start: dateWindow.startDate, end: dateWindow.endDate }),
    [dateWindow.startDate, dateWindow.endDate]
  );

  return (
    <>
      <div className="text-sm font-sansSemiBold">Time Frame</div>
      <SelectDateRange
        id="day-or-range-picker"
        startDate={dateWindow.startDate}
        endDate={dateWindow.endDate}
        className="min-w-48"
        onRangeSelected={handleRangeSelected}
      />
      {resolution && (
        <FormFieldSelect
          isClearable={false}
          className="min-w-24"
          isSearchable={false}
          options={resolutionOptions}
          formatOptionLabel={(option, ctx) => {
            if (ctx.context === "menu") {
              const maxRangeForOption = getMaxDayCountForResolution({
                resolution: option.value,
                chartDisplay,
              });

              if (maxRangeForOption && currentDurationDays > maxRangeForOption) {
                const years = Math.floor(maxRangeForOption / YEAR_IN_DAYS);

                return (
                  <FloatingTooltip
                    content={`Please select a range of ${years} ${pluralize(
                      years,
                      "year",
                      "years"
                    )} or less to enable this view type`}
                  >
                    <div>{option.label}</div>
                  </FloatingTooltip>
                );
              }
            }

            return option.label;
          }}
          isOptionDisabled={(option) => {
            const maxRangeForOption = getMaxDayCountForResolution({ resolution: option.value, chartDisplay });

            return maxRangeForOption ? currentDurationDays > maxRangeForOption : false;
          }}
          value={resolution}
          onChange={handleResolutionChanged}
        />
      )}
    </>
  );
};
