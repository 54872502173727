import { FC, useCallback, useRef, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useDebouncedCallback } from "use-debounce";
import { produce } from "immer";
import {
  FormRequest,
  FormSectionElementRequest,
  FormSectionRequest,
  FormSectionVO,
  FormVO,
} from "@libs/api/generated-api";
import { noop } from "@libs/utils/noop";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isNullish } from "@libs/utils/types";
import { getQueryKey } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { updateCachedData } from "@libs/utils/queryCache";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Spinner } from "@libs/components/UI/Spinner";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { ReactComponent as SettingsIcon } from "@libs/assets/icons/settings.svg";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { getPatientEngagementTemplateVariables } from "api/communications/queries";
import { getDraftForm } from "api/forms/queries";

import { discardDraft, publishForm, updateForm } from "api/forms/mutations";
import { EditFormContent } from "components/Settings/Forms/EditFormContent";
import {
  CurrentElement,
  EditFormSettingsProps,
  FormElementRequest,
  FormSettingsRequest,
  SortableFormElement,
  SortableFormSectionElement,
} from "components/Settings/Forms/types";
import { handleError } from "utils/handleError";
import { useItemModal } from "hooks/useItemModal";
import { PageTitleEditor } from "components/Settings/Forms/PageTitleEditor";
import { EditFormBadge } from "components/Settings/Forms/EditFormBadge";
import { findElement, getFormUpdateRequest } from "components/Settings/Forms/utils";
import { useBlockRouteUntil } from "hooks/useBlockRouteUntil";
import { Badge } from "components/Settings/Forms/Badge";

const TEXT_SAVE_DELAY = 750;

export const EditForm: FC<{
  from: string;
  formId: string;
  SettingsFlyover: FC<EditFormSettingsProps>;
  previewLink: string;
  // eslint-disable-next-line max-statements, complexity
}> = ({ SettingsFlyover, from, formId, previewLink }) => {
  const [{ currentElement, placeholderElement }, setElements] = useState<{
    currentElement?: CurrentElement;
    // this is needed in the case where we are still in the process
    // of persisting a new element but want to focus
    // on another element. It allows us to avoid
    // inserting an unsaved element into the FormVO as an
    // optimistic update.
    placeholderElement?: CurrentElement;
  }>({});

  const queryClient = useQueryClient();
  const isEditingTitle = useBoolean(false);
  const isPublishing = useBoolean(false);
  const isDiscarding = useBoolean(false);
  const settingsFlyover = useBoolean(false);
  const confirmDelete = useItemModal<string>(null);
  const savingDraftRef = useRef<Promise<unknown> | null>(null);

  const practice = useCurrentPractice();

  const queryKey = [
    getQueryKey("practices", "getDraftForm"),
    { practiceId: practice.id, uuidOrSlug: formId },
  ];

  const [formQuery] = useApiQueries([
    getDraftForm({ args: { practiceId: practice.id, uuidOrSlug: formId } }),
  ]);

  const [templateVariablesQuery] = useApiQueries([
    getPatientEngagementTemplateVariables({
      args: { practiceId: practice.id, category: "FORM" },
      // Only enable template variables for non-preset forms (forms without a
      // slug), until BE introduces form categories to determine subset of
      // variables that are applicable to different form use-cases.
      queryOptions: { enabled: formQuery.data && !formQuery.data.slug },
    }),
  ]);

  const [updateFormStructureMutation, discardDraftMutation, publishFormMutation] = useApiMutations([
    updateForm,
    discardDraft,
    publishForm,
  ]);

  const trackDraftPromise = <T extends Promise<unknown>>(promise: T): T => {
    savingDraftRef.current = promise;

    promise
      .finally(() => {
        savingDraftRef.current = null;
      })
      // finally is needed to clear the promise ref but it also creates a new promise
      // that needs to have a catch. The assumption is that promises
      // being passed in are handling logging.
      .catch(noop);

    return promise;
  };

  const handleSaveForm = useDebouncedCallback(
    async (updates: FormVO, options?: { onSuccess?: Func }) => {
      if (formQuery.data) {
        try {
          const response = await trackDraftPromise(
            updateFormStructureMutation.mutateAsync({
              practiceId: practice.id,
              practiceUuid: practice.uuid,
              uuidOrSlug: formId,
              data: getFormUpdateRequest(updates),
            })
          );

          updateCachedData<FormVO>(queryClient, { queryKey }, (currentForm) => {
            return {
              ...currentForm,
              state: response.data.data.state,
            };
          });

          options?.onSuccess?.();
        } catch (err) {
          handleError(err);
          queryClient.invalidateQueries(queryKey);
        }
      }
    },
    TEXT_SAVE_DELAY,
    { leading: false }
  );

  const handleDiscard = async () => {
    if (formQuery.data && currentElement?.isValid !== false) {
      isDiscarding.on();
      setElements({});

      const promise = savingDraftRef.current ?? Promise.resolve();

      await promise;
      discardDraftMutation.mutate(
        {
          practiceId: practice.id,
          practiceUuid: practice.uuid,
          uuidOrSlug: formQuery.data.uuid,
        },
        {
          onSuccess: (response) => {
            updateCachedData<FormVO>(queryClient, { queryKey }, () => {
              return response.data.data;
            });

            if (formContentRef.current) {
              formContentRef.current.scrollTo(0, 0);
            }
          },
          onError: handleError,
          onSettled: isDiscarding.off,
        }
      );
    }
  };

  const handlePublish = async () => {
    if (formQuery.data && currentElement?.isValid !== false) {
      isPublishing.on();
      setElements({});

      const promise = savingDraftRef.current ?? Promise.resolve();

      await promise;
      publishFormMutation.mutate(
        {
          practiceId: practice.id,
          uuid: formQuery.data.uuid,
          slug: formQuery.data.slug,
        },
        {
          onSuccess: (response) => {
            updateCachedData<FormVO>(queryClient, { queryKey }, () => {
              return response.data.data;
            });

            if (formContentRef.current) {
              formContentRef.current.scrollTo(0, 0);
            }
          },
          onError: handleError,
          onSettled: isPublishing.off,
        }
      );
    }
  };

  const handleUpdateTitle = (updates: { title: string }) => {
    isEditingTitle.off();

    if (updates.title === formQuery.data?.title) {
      return;
    }

    const [[_, updateData]] = updateCachedData<FormVO>(queryClient, { queryKey }, (currentForm) => {
      return {
        ...currentForm,
        ...updates,
      };
    });
    const updatedForm = updateData?.data.data;

    if (updatedForm) {
      handleSaveForm(updatedForm);
    }
  };

  const handleUpdateSettings = (updates: FormSettingsRequest) => {
    const [[_, updateData]] = updateCachedData<FormVO>(queryClient, { queryKey }, (currentForm) => {
      return {
        ...currentForm,
        ...updates,
      };
    });
    const updatedForm = updateData?.data.data;

    if (updatedForm) {
      handleSaveForm(updatedForm, {
        onSuccess: settingsFlyover.off,
      });
    }
  };

  const handleSaveElement = async (update: FormVO) => {
    if (formQuery.data) {
      setElements((last) =>
        produce(last, (draft) => {
          if (draft.currentElement) {
            draft.currentElement.isValid = true;
          }
        })
      );

      const elementSavedId = currentElement?.element?.uuid;

      try {
        const response = await trackDraftPromise(
          updateFormStructureMutation.mutateAsync({
            practiceId: practice.id,
            practiceUuid: practice.uuid,
            uuidOrSlug: formQuery.data.uuid,
            data: getFormUpdateRequest(update),
          })
        );

        updateCachedData<FormVO>(queryClient, { queryKey }, () => {
          return response.data.data;
        });
      } catch (err) {
        handleError(err);
      }

      // if the user didn't actually select another element to trigger this save
      // then we can also remove the currentElement as the selected element
      setElements((last) => {
        if (last.placeholderElement && last.placeholderElement.element === last.currentElement?.element) {
          return {};
        }

        if (elementSavedId && elementSavedId === last.currentElement?.element?.uuid) {
          return {};
        }

        return {
          currentElement: last.currentElement,
        };
      });
      savingDraftRef.current = null;
    }
  };

  const handleDeleteElement = async (elementUuid: string) => {
    if (!formQuery.data) {
      return;
    }

    const match = findElement(formQuery.data, "uuid", elementUuid);

    if (!match || match.type === "CONDITIONAL_ELEMENT" || match.type === "CONDITIONAL_SECTION_ELEMENT") {
      return;
    }

    const updatedForm =
      match.type === "ELEMENT"
        ? produce(formQuery.data, (draft) => {
            if (draft.state !== "DRAFT") {
              draft.state = "UNPUBLISHED_CHANGES";
            }

            draft.content[match.pageIndex].content.splice(match.elementIndex, 1);
          })
        : produce(formQuery.data, (draft) => {
            const section = draft.content[match.pageIndex].content[match.elementIndex] as FormSectionVO;

            if (draft.state !== "DRAFT") {
              draft.state = "UNPUBLISHED_CHANGES";
            }

            section.content.splice(match.sectionElementIndex, 1);
          });

    updateCachedData<FormVO>(queryClient, { queryKey }, () => {
      return updatedForm;
    });

    confirmDelete.close();

    if (elementUuid === currentElement?.element?.uuid) {
      setElements((last) => ({ placeholderElement: last.placeholderElement }));
    }

    try {
      await trackDraftPromise(
        updateFormStructureMutation.mutateAsync({
          practiceId: practice.id,
          practiceUuid: practice.uuid,
          uuidOrSlug: formId,
          data: getFormUpdateRequest(updatedForm),
        })
      );
    } catch (err) {
      handleError(err);
      queryClient.invalidateQueries(queryKey);
    }
  };

  const handleUpdateNewElement = useCallback((element: FormElementRequest | undefined) => {
    setElements((last) =>
      produce(last, (draft) => {
        if (draft.currentElement) {
          draft.currentElement.element = element;
        }
      })
    );
  }, []);

  const handleInvalidElement = useCallback(() => {
    setElements((last) =>
      produce(last, (draft) => {
        if (draft.currentElement) {
          draft.currentElement.isValid = false;
        }
      })
    );
  }, []);

  const handleUpdateElement = (update: FormElementRequest) => {
    if (!currentElement || !formQuery.data || currentElement.flow !== "EDIT") {
      return;
    }

    const match = findElement(formQuery.data, "uuid", currentElement.element.uuid);

    if (!match || match.type === "CONDITIONAL_ELEMENT" || match.type === "CONDITIONAL_SECTION_ELEMENT") {
      return;
    }

    const request = formQuery.data;
    const updatedForm =
      match.type === "ELEMENT"
        ? produce(request, (draft: FormRequest) => {
            draft.content[match.pageIndex].content[match.elementIndex] = update;
          })
        : produce(request, (draft) => {
            const section = draft.content[match.pageIndex].content[match.elementIndex] as FormSectionRequest;

            section.content[match.sectionElementIndex] = update as FormSectionElementRequest;
          });

    updateCachedData<FormVO>(queryClient, { queryKey }, () => {
      return updatedForm;
    });

    handleSaveElement(updatedForm);
  };

  const handleInsertElement = (element: FormElementRequest) => {
    if (!currentElement || !formQuery.data || currentElement.flow !== "ADD") {
      return;
    }

    const { index, sectionIndex } = currentElement;

    const request = formQuery.data;

    const updatedForm = produce(request, (draft: FormRequest) => {
      if (isNullish(sectionIndex)) {
        draft.content[0].content.splice(index, 0, element);
      } else {
        const section = draft.content[0].content[index] as FormSectionRequest;

        section.content.splice(sectionIndex, 0, element as FormSectionElementRequest);
      }
    });

    setElements((last) => ({ ...last, placeholderElement: currentElement }));

    handleSaveElement(updatedForm);
  };

  const handleSortPage = (id: string, elements: SortableFormElement[]) => {
    let updated = false;
    const [[_, updateData]] = updateCachedData<FormVO>(queryClient, { queryKey }, (currentForm) => {
      const pageIndex = currentForm.content.findIndex((page) => page.uuid === id);

      const pageElements = currentForm.content[pageIndex].content;

      if (
        pageElements.length === elements.length &&
        pageElements.every((element, index) => element.uuid === elements[index].uuid)
      ) {
        // sort order hasn't changed
        return currentForm;
      }

      updated = true;

      return produce(currentForm, (draft) => {
        draft.content[pageIndex].content = elements.map((el) => {
          if (el.type !== "SECTION") {
            return el;
          }

          // only update the page sort order, and not the nested order.
          // this avoids a problem when dragging an item from the top level
          // into a section a list
          // eslint-disable-next-line max-nested-callbacks
          const existingSection = pageElements.find((existing) => existing.uuid === el.uuid) as
            | FormSectionVO
            | undefined;

          if (existingSection) {
            return {
              ...el,
              content: existingSection.content,
            };
          }

          return el;
        });
      });
    });

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const updatedForm = updated ? updateData?.data.data : undefined;

    // if sorting was a noop, don't save
    if (updatedForm) {
      handleSaveForm(updatedForm);
    }

    return;
  };

  const handleSortSection = (id: string, elements: SortableFormSectionElement[]) => {
    let updated = false;

    const [[_, updateData]] = updateCachedData<FormVO>(queryClient, { queryKey }, (currentForm) => {
      const match = findElement(currentForm, "uuid", id);

      if (!match || match.type !== "ELEMENT") {
        return currentForm;
      }

      const section = match.element as FormSectionVO;

      if (
        section.content.length === elements.length &&
        section.content.every((sectionElement, index) => sectionElement.uuid === elements[index].uuid)
      ) {
        // section sort order hasn't changed
        return currentForm;
      }

      updated = true;

      return produce(currentForm, (draft) => {
        const draftSection = draft.content[match.pageIndex].content[match.elementIndex] as FormSectionVO;

        draftSection.content = elements;
      });
    });

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    const updatedForm = updated ? updateData?.data.data : undefined;

    // if sorting was a noop, don't save
    if (updatedForm) {
      handleSaveForm(updatedForm);
    }
  };

  const selectCurrentElement = useCallback((newElement?: CurrentElement | undefined) => {
    setElements((last) => {
      if (last.currentElement && last.currentElement.isValid === false) {
        return last;
      }

      return {
        ...last,
        currentElement: newElement,
      };
    });
  }, []);

  const handleRequestDeleteElement = useCallback(
    (elementUuid: string) => {
      confirmDelete.open(elementUuid);
    },
    [confirmDelete]
  );

  const draftSaveFinishes = useCallback(() => {
    if (currentElement?.isValid === false) {
      return Promise.reject({});
    }

    return savingDraftRef.current?.then(noop) ?? Promise.resolve();
  }, [currentElement?.isValid]);

  // blocks a user from leaving the page until
  // a draft save is complete and until
  // they have either cancelled an element update
  // or made sure that it is valid
  useBlockRouteUntil(draftSaveFinishes);

  const formContentRef = useRef<HTMLFormElement | null>(null);

  return (
    <QueryResult queries={[formQuery, templateVariablesQuery]}>
      {formQuery.data ? (
        <SettingsPanel
          title={
            <PageTitleEditor
              title={formQuery.data.title}
              isEditing={isEditingTitle.isOn}
              onRequestEdit={isEditingTitle.on}
              onUpdateTitle={(newTitle) => handleUpdateTitle({ title: newTitle })}
            />
          }
          titleBarClassName="min-h-14"
          includePadding={false}
          actions={
            <div className="flex items-center gap-x-3">
              {updateFormStructureMutation.isLoading || isPublishing.isOn || isDiscarding.isOn ? (
                <Badge className="text-blue bg-blueLight flex items-center gap-x-3">
                  Saving <Spinner size="xs" variant="primary" animation="border" />
                </Badge>
              ) : null}

              <EditFormBadge
                discardDisabled={discardDraftMutation.isLoading}
                onDiscard={handleDiscard}
                formState={formQuery.data.state}
              />

              {formQuery.data.slug ? null : (
                <ButtonIcon
                  SvgIcon={SettingsIcon}
                  onClick={settingsFlyover.on}
                  tooltip={{ content: "Settings", theme: "SMALL" }}
                  theme="primary"
                />
              )}

              <LinkIcon
                SvgIcon={CloseIcon}
                tooltip={{ content: "Close", theme: "SMALL" }}
                theme="primary"
                to={from}
              />
            </div>
          }
          contentClassName="flex flex-col relative"
        >
          <EditFormContent
            form={formQuery.data}
            formContentRef={formContentRef}
            templateVariables={templateVariablesQuery.data}
            currentElement={currentElement}
            placeholderElement={placeholderElement}
            previewLink={previewLink}
            onInsertElement={handleInsertElement}
            onPageSorted={handleSortPage}
            onSectionSorted={handleSortSection}
            onDeleteElement={handleRequestDeleteElement}
            onUpdateElement={handleUpdateElement}
            onPublish={handlePublish}
            onInvalidElement={handleInvalidElement}
            onEditElement={selectCurrentElement}
            onUpdateNewElement={handleUpdateNewElement}
            onCloseElement={() => setElements((last) => ({ placeholderElement: last.placeholderElement }))}
          />
          {settingsFlyover.isOn ? (
            <SettingsFlyover
              form={formQuery.data}
              onUpdateSettings={handleUpdateSettings}
              onRequestClose={settingsFlyover.off}
              isSaving={updateFormStructureMutation.isLoading}
            />
          ) : null}
          {confirmDelete.isOpen ? (
            <ConfirmationModal
              primaryText="Are you sure you want to delete?"
              secondaryText="Deleting a question or section will be permanent after the form is published. All previous responses will still be saved."
              onConfirm={() => handleDeleteElement(confirmDelete.item)}
              onCancel={confirmDelete.close}
              size="2xs"
            />
          ) : null}
          {isPublishing.isOn || isDiscarding.isOn ? (
            <LoadingOverlaySpinner
              loadingText={isPublishing.isOn ? "Publishing Changes" : "Discarding Changes"}
            />
          ) : null}
        </SettingsPanel>
      ) : null}
    </QueryResult>
  );
};
