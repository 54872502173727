import { FC, useMemo } from "react";
import { parseISO, format } from "date-fns";
import { PatientSummaryVO } from "@libs/api/generated-api";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus.svg";
import { getAgeByDob } from "@libs/utils/formatString";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { components } from "react-select";
import { createSelectStyles } from "@libs/components/UI/selectStyles";
import { useNow } from "hooks/useNow";
import { getPatientDisplayNameFromPatient } from "utils/names";
import { FormFieldAutoComplete, FormFieldAutoCompleteProps } from "components/UI/FormFieldAutoComplete";

type PatientOption =
  | {
      label: string;
      value: number;
      type: "patient";
      data: PatientSummaryVO;
    }
  | {
      label: string;
      value: number;
      type: "newPatient";
    };

const CREATE_PATIENT_VALUE = -1;

export const FormFieldPatientsAutoComplete: FC<
  Omit<FormFieldAutoCompleteProps<number, PatientOption>, "components" | "formatOptionLabel" | "options"> & {
    options: PatientSummaryVO[] | undefined;
    onCreatePatient?: Func;
    onSearch: (newValue: string) => void;
    styles?: { paddingBottom: string; paddingTop: string };
  }
> = ({ options, styles, onCreatePatient, onItemChanged, ...props }) => {
  const now = useNow();
  const customStyles = useMemo(() => {
    return createSelectStyles<number, PatientOption>({
      option: () => ({
        paddingBottm: styles?.paddingBottom ?? "0.375rem",
        paddingTop: styles?.paddingTop ?? "0.375rem",
      }),
    });
  }, [styles?.paddingBottom, styles?.paddingTop]);

  const searchPatientOptions = useMemo(() => {
    const mappedOptions =
      options?.map((p) => ({
        value: p.id,
        data: p,
        type: "patient" as const,
        label: getPatientDisplayNameFromPatient(now, p),
      })) ?? [];

    if (onCreatePatient) {
      return [
        { value: CREATE_PATIENT_VALUE, type: "newPatient" as const, label: "Create Patient" },
        ...mappedOptions,
      ];
    }

    return mappedOptions;
  }, [options, now, onCreatePatient]);

  const customComponents = useMemo(() => {
    return onCreatePatient
      ? {
          Option: components.Option,
        }
      : undefined;
  }, [onCreatePatient]);

  return (
    <FormFieldAutoComplete
      isClearable
      {...props}
      onItemChanged={(value) => {
        value === CREATE_PATIENT_VALUE ? onCreatePatient?.() : onItemChanged?.(value);
      }}
      components={customComponents}
      options={searchPatientOptions}
      styles={customStyles}
      formatOptionLabel={(item, meta) => {
        const isMenu = meta.context === "menu";

        return isMenu ? (
          item.type === "patient" ? (
            <div className="flex items-center w-full">
              <span className="block">
                <span className="font-sansSemiBold">{item.data.name.fullDisplayName}</span>,{" "}
                {getAgeByDob(now, item.data.dob)}
              </span>
              <span className="ml-auto block text-xxs">
                DOB: {format(parseISO(item.data.dob), "MM/dd/yyyy")}
              </span>
            </div>
          ) : (
            <div className="flex gap-x-2 text-xs">
              <ButtonIcon className="cursor-default" theme="primary" SvgIcon={PlusIcon} size="sm" />
              <span className="text-primaryTheme">New Patient</span>
            </div>
          )
        ) : (
          item.label
        );
      }}
    />
  );
};
