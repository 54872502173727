import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as ExpandTable } from "@libs/assets/icons/toggle-full-screen.svg";
import { ReactComponent as ShrinkTable } from "@libs/assets/icons/shrink.svg";

export const ExpandTableButton: React.FC<{ onClick: Func; toggled: boolean }> = ({ onClick, toggled }) => {
  return (
    <ButtonIcon
      tooltip={{ content: toggled ? "Shrink Table" : "Expand Table", theme: "SMALL" }}
      theme="primary"
      SvgIcon={toggled ? ShrinkTable : ExpandTable}
      onClick={onClick}
    />
  );
};
