import { useCallback, SetStateAction, Dispatch, useMemo, lazy, Suspense } from "react";
import { PatientToothVO, UpsertPatientToothRequest } from "@libs/api/generated-api";
import { noop } from "@libs/utils/noop";
import { useBoolean } from "@libs/hooks/useBoolean";
import { toggleSet } from "@libs/utils/toggleSet";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as ToothIcon } from "@libs/assets/icons/tooth-1.svg";
import { ReactComponent as ToothConditionsIcon } from "@libs/assets/icons/tooth-conditions.svg";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { ChartTabs } from "components/Charting/ChartTabs";
import { ToothStateMenu } from "components/Charting/ToothStateMenu";
import { filterUnnamedTeeth, ToothChartSelections } from "components/Charting/toothChartData";
import { ToothConditionsMenu } from "components/Charting/ToothConditionsMenu";
import { TreatmentTypesInfoMenu } from "components/Charting/TreatmentTypesInfoMenu";
import { TeethMarkings } from "components/Charting/teeth";

interface Props {
  patientId: number;
  isLoading: boolean;
  isSaving: boolean;
  teeth: PatientToothVO[] | undefined;
  markings: Record<string, TeethMarkings[]> | undefined;
  selectedTeeth: ToothChartSelections;
  onSelectTeeth: Dispatch<SetStateAction<ToothChartSelections>>;
  onUpdateTeeth: (updates: UpsertPatientToothRequest[]) => void;
}

const toggleSelection =
  <T extends Exclude<ToothChartSelections, { type: "NONE" }>>(type: T["type"]) =>
  (last: ToothChartSelections, value: SetItem<T["value"]>) => {
    if (last.type !== type) {
      return { type, value: new Set([value]) } as ToothChartSelections;
    }

    const newSet = toggleSet(last.value as Set<SetItem<T["value"]>>, value);

    if (newSet.size === 0) {
      return { type: "NONE" } as ToothChartSelections;
    }

    return { type, value: newSet } as ToothChartSelections;
  };

const ToothChart = lazy(() => {
  const promise = import(/* webpackChunkName: "tooth-chart" */ "components/Charting/ToothChart");

  return promise.then((component) => ({
    default: component.ToothChart,
  }));
});

export const ToothChartSection: React.FC<Props> = ({
  patientId,
  isLoading,
  isSaving,
  teeth,
  markings,
  selectedTeeth,
  onSelectTeeth,
  onUpdateTeeth,
}) => {
  const toothMenu = useBoolean(false);
  const conditionsMenu = useBoolean(false);
  const filteredSelection = useMemo(
    (): ToothChartSelections => (teeth ? filterUnnamedTeeth(selectedTeeth, teeth) : { type: "NONE" }),
    [selectedTeeth, teeth]
  );

  const toggleSelectedTeeth = useCallback(
    (value: number) => {
      onSelectTeeth((last) => {
        return toggleSelection("TOOTH")(last, value);
      });
    },
    [onSelectTeeth]
  );

  const toggleSelectedQuadrant = useCallback(
    (value: PatientToothVO["quadrant"]) => {
      onSelectTeeth((last) => {
        return toggleSelection("QUADRANT")(last, value);
      });
    },
    [onSelectTeeth]
  );

  const toggleSelectedArch = useCallback(
    (value: PatientToothVO["arch"]) => {
      onSelectTeeth((last) => {
        return toggleSelection("ARCH")(last, value);
      });
    },
    [onSelectTeeth]
  );

  const handleApply = useCallback(
    (newTeeth: UpsertPatientToothRequest[]) => {
      onUpdateTeeth(newTeeth);
      toothMenu.off();
      onSelectTeeth({ type: "NONE" });
    },
    [onUpdateTeeth, toothMenu, onSelectTeeth]
  );

  const handleUpdateConditions = useCallback(
    (newTeeth: UpsertPatientToothRequest[]) => {
      onUpdateTeeth(newTeeth);
      conditionsMenu.off();
      onSelectTeeth({ type: "NONE" });
    },
    [onUpdateTeeth, conditionsMenu, onSelectTeeth]
  );

  return (
    <>
      <div className="flex w-full justify-between pl-1 pr-5 pb-3">
        <ChartTabs patientId={patientId} />
        <div className="flex items-center gap-x-6">
          <ButtonMenu
            className="flex"
            placement="bottom-end"
            menuContent={
              teeth ? (
                <ToothConditionsMenu
                  selectedTeeth={filteredSelection}
                  teeth={teeth}
                  onApply={handleUpdateConditions}
                />
              ) : null
            }
            isOpen={conditionsMenu.isOn}
            onRequestClose={conditionsMenu.off}
            onRequestOpen={isLoading ? noop : conditionsMenu.on}
          >
            {(props) => (
              <ButtonIcon
                SvgIcon={ToothConditionsIcon}
                theme="primary"
                {...props}
                disabled={filteredSelection.type === "NONE"}
              />
            )}
          </ButtonMenu>
          <ButtonMenu
            className="flex"
            placement="bottom-end"
            menuContent={<ToothStateMenu selectedTeeth={selectedTeeth} teeth={teeth} onApply={handleApply} />}
            isOpen={toothMenu.isOn}
            onRequestClose={toothMenu.off}
            onRequestOpen={isLoading ? noop : toothMenu.on}
          >
            {(props) => <ButtonIcon theme="primary" SvgIcon={ToothIcon} {...props} />}
          </ButtonMenu>
          <FloatingTooltip content={<TreatmentTypesInfoMenu />}>
            <div>
              <Icon SvgIcon={InfoIcon} theme="primary" />
            </div>
          </FloatingTooltip>
        </div>
      </div>
      <Suspense fallback={<LoadingContent />}>
        <ToothChart
          isLoading={isLoading}
          isSaving={isSaving}
          teeth={teeth}
          markings={markings}
          selectedTeeth={selectedTeeth}
          onToothClick={toggleSelectedTeeth}
          onSelectQuadrant={toggleSelectedQuadrant}
          onSelectArch={toggleSelectedArch}
        />
      </Suspense>
    </>
  );
};
