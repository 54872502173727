import { produce } from "immer";
import { FormTextInputElementRequest, FormVO } from "@libs/api/generated-api";
import { ValidationResult } from "@libs/hooks/useValidation";
import { toggleSet } from "@libs/utils/toggleSet";
import { isOneOf } from "@libs/utils/isOneOf";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { RequiredCheckbox } from "components/Settings/Forms/RequiredCheckbox";
import { titleSchema } from "components/Settings/Forms/utils";

import { FormFieldSelect } from "components/UI/FormFieldSelect";

const textSettings: ListItem<FormTextInputElementRequest["settings"]>[] = ["MULTILINE"];

export const TextInputFormContent = ({
  formState: [{ draft }, setForm],
  validation,
  isLocked,
  formType,
}: {
  formType: FormVO["type"];
  formState: UseState<{ draft: FormTextInputElementRequest }>;
  validation: ValidationResult<FormTextInputElementRequest, { title: typeof titleSchema }>;
  isLocked?: boolean;
}) => {
  const handleToggleSetting = (value: ListItem<typeof draft.settings>) => {
    setForm((last) =>
      produce(last, (next) => {
        next.draft.settings = [...toggleSet(new Set(next.draft.settings), value)];
      })
    );
  };

  const handleSelectType = (value: ListItem<typeof draft.settings> | "") => {
    setForm((last) =>
      produce(last, (next) => {
        next.draft.settings = next.draft.settings.filter((val) => !isOneOf(val, textSettings));

        if (value) {
          next.draft.settings.push(value);
        }
      })
    );
  };

  const handleUpdateTitle = (value: string) => {
    setForm((last) =>
      produce(last, (next) => {
        next.draft.title = value;
      })
    );
  };

  const responseType = draft.settings.find((setting) => isOneOf(setting, ["MULTILINE"])) ?? "";

  return (
    <div className="flex flex-col gap-y-4">
      <FormFieldInput
        label="Question"
        edit={!isLocked}
        required={true}
        value={draft.title}
        error={validation.title.$error}
        onChange={(e) => handleUpdateTitle(e.target.value)}
      />
      <FormFieldSelect
        label="Response Type"
        edit={!isLocked}
        display="label"
        options={[
          { value: "", label: "Short (One Line)" },
          { value: "MULTILINE", label: "Long (Multiple Lines)" },
        ]}
        value={responseType}
        onItemSelected={handleSelectType}
        isClearable={false}
        isSearchable={false}
      />
      {formType === "INTAKE" ? (
        <RequiredCheckbox
          checked={draft.settings.includes("REQUIRED")}
          onChange={() => handleToggleSetting("REQUIRED")}
        />
      ) : null}
    </div>
  );
};
