import { useState } from "react";
import {
  FamilyMemberResponse,
  PaymentCorrectionRequest,
  PaymentProfileVO,
  PaymentVO,
} from "@libs/api/generated-api";
import { PaymentDateType } from "components/PatientProfile/Billing/PaymentMethods/utils";

export type PaymentProfileWithRefundable = {
  paymentProfile: PaymentProfileVO;
  refundableInfo?: {
    payment: PaymentVO;
    refundableAmount: number;
  };
};
export const useEditPaymentForm = ({
  familyMembersData,
  paymentCreatedDate,
  initialPaymentAmount,
  initialNote,
}: {
  familyMembersData: FamilyMemberResponse;
  paymentCreatedDate?: Date;
  initialPaymentAmount?: number;
  initialNote?: string;
}) => {
  // Holds the payment amount
  const [paymentAmount, setPaymentAmount] = useState<number | undefined>(initialPaymentAmount);

  // Holds the payment amount as a string in dollars. Useful for displaying the amount in the input
  // field while the user is typing floating point numbers. ("5" vs "5." vs "5.0" vs "5.00")
  const [dollarStringValue, setDollarStringValue] = useState<string>();
  // Holds the payment date
  const [paymentDate, setPaymentDate] = useState<Date>(() => paymentCreatedDate ?? new Date());

  // The payment note
  const [note, setNote] = useState<string | undefined>(initialNote);

  return {
    familyMembers: familyMembersData.linkedFamilyMembers,
    paymentAmount,
    setPaymentAmount,
    dollarStringValue,
    setDollarStringValue,
    paymentDate,
    setPaymentDate,
    note,
    setNote,
  };
};

export const makePaymentCorrectionRequest: (payment: {
  paymentAmount: number | undefined;
  paymentDate: PaymentDateType;
  paymentNote: string;
}) => PaymentCorrectionRequest = ({ paymentAmount, paymentDate, paymentNote }) => {
  const paymentCorrectionRequest: PaymentCorrectionRequest = {
    note: paymentNote,
  };

  if (paymentAmount) {
    paymentCorrectionRequest.currencyAmount = {
      currency: "USD",
      amount: paymentAmount,
    };
  }

  if (paymentDate) {
    paymentCorrectionRequest.paymentCreatedAt = paymentDate;
  }

  return paymentCorrectionRequest;
};
