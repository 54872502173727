import React from "react";
import { scaleOrdinal } from "@visx/scale";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";

import { TextProps } from "@visx/text";
import { TimeSeriesResponse } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { colorForKey, ColorScheme } from "components/Dashboard/Charting/theme";
import { TimeSeriesResolutionOption } from "components/Dashboard/types";
import { VisxBarStacks } from "components/Dashboard/Charting/VisxBarStacks";
import { DashboardChartTooltip } from "components/Dashboard/Charting/DashboardChartTooltip";
import { getStackTotals } from "components/Dashboard/utils/barStacks";
import { BarStackData, ChartMargin } from "components/Dashboard/Charting/types";
import { useStackMarginsAndScales } from "components/Dashboard/Charting/hooks/useStackMarginsAndScales";
import { BarChartLayout } from "components/Dashboard/Charting/BarChartLayout";

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "white",
};

const MINIMUM_WIDTH = 100;

export interface Props<T extends string> {
  width: number;
  height: number;
  resolution?: TimeSeriesResolutionOption;
  timeSeries?: TimeSeriesResponse;
  isLoading: boolean;
  data: BarStackData<T>[];
  colorScheme: ColorScheme<T>;
  keys: T[];
  randomizeLoadingBars?: boolean;
  onClickBar: (params: { date: string; index: number }) => void;
  isCurrency?: boolean;
  selectedBarIndex?: number;
  dottedBarIndex?: number;
  formatValue?: (val: number) => string;
  labelFormat: (key: T) => string;
  formatDate: (val: string) => string;
  legendOrder?: T[];
  chartMargin?: ChartMargin;
  axisMargin?: ChartMargin;
  xAxisLabel?: {
    text: string;
    labelProps: Partial<TextProps>;
  };
}

const DEFAULT_Y_AXIS_MAX = 2500;
const DEFAULT_AXIS_MAX_CENTS = 2_000_000;

export const useYDomain = <T extends string>({
  data,
  isCurrency,
  isLoading,
}: {
  data: BarStackData<T>[];
  isCurrency: boolean;
  isLoading: boolean;
}) =>
  React.useMemo(() => {
    const min = isLoading ? 0 : Math.min(...getStackTotals(data, { findNegativeTotal: true }));
    const stackMaximum = Math.max(...getStackTotals(data));

    const max =
      isLoading && stackMaximum === 0
        ? isCurrency
          ? DEFAULT_AXIS_MAX_CENTS
          : DEFAULT_Y_AXIS_MAX
        : stackMaximum;

    return { min, max };
  }, [data, isCurrency, isLoading]);

export const DashboardStackedBarChart = <T extends string>({
  data,
  resolution,
  randomizeLoadingBars = true,
  width,
  height,
  isLoading,
  onClickBar,
  isCurrency = false,
  formatValue,
  colorScheme,
  keys,
  formatDate,
  chartMargin,
  axisMargin,
  labelFormat,
  legendOrder,
  xAxisLabel,
  ...rest
}: Props<T>) => {
  const colorScale = React.useMemo(() => {
    return scaleOrdinal<T, string>(colorScheme);
  }, [colorScheme]);
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
    useTooltip<BarStackData<T>>();

  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    // TooltipInPortal is rendered in a separate child of <body /> and positioned
    // with page coordinates which should be updated on scroll. consider using
    // Tooltip or TooltipWithBounds if you don't need to render inside a Portal
    scroll: true,
  });

  // Hide tooltip when new data comes in
  React.useEffect(() => hideTooltip(), [data, hideTooltip]);

  const yDomain = useYDomain({ data, isCurrency, isLoading });
  const marginsAndScales = useStackMarginsAndScales({
    width,
    height,
    data,
    yDomain,
    resolution,
    chartMargin,
    axisMargin,
  });
  const { margins, xScale, yScale, yMax } = marginsAndScales;

  return width < MINIMUM_WIDTH ? null : (
    <>
      <BarChartLayout
        width={width}
        height={height}
        isLoading={isLoading}
        isFirstLoad={randomizeLoadingBars}
        tooltipOpen={tooltipOpen}
        xAxisLabel={xAxisLabel}
        innerRef={containerRef}
        loadingData={data}
        colorScale={colorScale}
        formatDate={formatDate}
        labelFormat={labelFormat}
        isCurrency={isCurrency}
        formatValue={formatValue}
        {...marginsAndScales}
      >
        <VisxBarStacks
          onHideTooltip={hideTooltip}
          onShowTooltip={showTooltip}
          onClickBar={onClickBar}
          isCurrency={isCurrency}
          xScale={xScale}
          yScale={yScale}
          data={data}
          yMax={yMax}
          includeBarTitle={resolution !== "DAY"}
          tooltipData={tooltipData}
          barColorScale={colorScale}
          keys={keys}
          marginBottom={margins.chartMargin.bottom}
          {...rest}
        />
      </BarChartLayout>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
          className="space-y-1 bg-white text-greyDark py-4"
        >
          <DashboardChartTooltip
            formatValue={formatValue ?? (isCurrency ? formatCurrency : undefined)}
            items={(legendOrder ?? keys).map((key) => {
              const value: number = tooltipData[key];

              return {
                value,
                label: labelFormat(key),
                color: colorForKey(colorScheme, key),
              };
            })}
          />
        </TooltipInPortal>
      )}
    </>
  );
};
