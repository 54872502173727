import React, { ReactNode } from "react";
import { components, MultiValueGenericProps, StylesConfig } from "react-select";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { DentalProcedureVO } from "@libs/api/generated-api";
import { FormFieldProps } from "@libs/components/UI/FormField";
import { FormFieldMultiSelect } from "components/UI/FormFieldMultiSelect";
import { ProcedureOption } from "components/ProcedureSelector/ProcedureOption";
import { ProcedureDataCode } from "components/ProcedureSelector/ProcedureSelector";
import { ExpiredCDTCodeIcon } from "components/Charting/ExpiredCDTCodeIcon";
import { useNow } from "hooks/useNow";

const MultiValueLabelComponent: React.FC<
  MultiValueGenericProps<ProcedureDataCode, true, GroupedSelectOption<string, ProcedureDataCode>>
> = (props) => {
  const option = props.data as ProcedureDataCode;
  const now = useNow();

  return (
    <components.MultiValueLabel {...props}>
      <div className="flex items-center gap-x-1">
        <div>{option.label}</div>
        <ExpiredCDTCodeIcon now={now} endDate={option.data.endDate} />
      </div>
    </components.MultiValueLabel>
  );
};

const customComponents = {
  MultiValueLabel: MultiValueLabelComponent,
};

type ProcedureSelectorMultiple = FormFieldProps & {
  codes: ProcedureDataCode[];
  handleFilterOption: (option: FilterOptionOption<ProcedureDataCode>, searchText: string) => boolean;
  onChange?: (newValues: DentalProcedureVO[]) => void;
  placeholder?: ReactNode;
  values?: DentalProcedureVO[];
  openMenuOnFocus?: boolean;
  hideSelectedOptions?: boolean;
  allowDuplicateSelections?: boolean;
  styles?: StylesConfig<ProcedureDataCode, true, GroupedSelectOption<string, ProcedureDataCode>>;
  formatOptionLabel?: (procedure: ProcedureDataCode) => ReactNode;
};

export const ProcedureSelectorMultiSelect: React.FC<ProcedureSelectorMultiple> = ({
  codes,
  handleFilterOption,
  onChange,
  placeholder,
  values,
  formatOptionLabel,
  ...formFieldProps
}) => {
  const now = useNow();
  const selectedValues = values?.map((pro) => pro.cdtCode) || [];

  return (
    <FormFieldMultiSelect
      {...formFieldProps}
      placeholder={placeholder}
      styles={{
        menu: () => ({
          width: "100%",
        }),
        ...formFieldProps.styles,
      }}
      options={codes}
      onChange={(value) => {
        onChange?.(value.map((code) => code.data));
      }}
      value={selectedValues}
      filterOption={handleFilterOption}
      hideSelectedOptions={formFieldProps.hideSelectedOptions ?? false}
      allowDuplicateSelections={formFieldProps.allowDuplicateSelections ?? true}
      formatOptionLabel={
        formatOptionLabel ??
        ((procedure: ProcedureDataCode) => <ProcedureOption procedure={procedure.data} now={now} />)
      }
      components={customComponents}
    />
  );
};
