import React from "react";

import { MedicalImageVO, MountVO } from "@libs/api/generated-api";
import { UseMountUpdater } from "components/PatientProfile/Imaging/MountRoute/hooks/useMountUpdater";

export const useUpdateMountMetadata = ({
  mount,
  isViewingArchived,
  handleMountChanged,
}: {
  mount?: MountVO;
  isViewingArchived: boolean;
  handleMountChanged: UseMountUpdater["handleMountChanged"];
}) => {
  const handleUpdateMountMetadata = React.useCallback(
    (changes: Pick<MedicalImageVO, "assignedDate" | "sensor">) => {
      if (!mount?.images) {
        return;
      }

      handleMountChanged({
        updates: {
          images: mount.images.map((item) => {
            if (!item.isArchived || isViewingArchived) {
              return {
                ...item,
                ...changes,
              };
            }

            return item;
          }),
        },
      });
    },
    [handleMountChanged, isViewingArchived, mount?.images]
  );

  return {
    handleUpdateMountMetadata,
  };
};
