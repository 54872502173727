import { ContactVO, PatientSummaryVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { EditContactPanel } from "components/Patient/EditContactPanel";
import { AddContactPanel } from "components/Patient/AddContactPanel";
import { DirtyFlyover } from "components/UI/DirtyFlyover";

interface Props {
  onClose: Func;
  onMatchSelected: (patient: PatientSummaryVO) => void;
  onContactSelected: (contact: ContactVO) => void;
  contactPatientId?: number;
  patientFirstName: string;
}

export const ContactFlyover: React.FC<Props> = ({
  contactPatientId,
  onMatchSelected,
  onClose,
  ...commonProps
}) => {
  const isDirty = useBoolean(false);

  return (
    <DirtyFlyover isDirty={isDirty.isOn} onClose={onClose} size="md">
      {({ onRequestClose }) =>
        contactPatientId ? (
          <EditContactPanel
            key={contactPatientId}
            onDirty={isDirty.on}
            contactPatientId={contactPatientId}
            {...commonProps}
            onCancel={onRequestClose}
            onMatchSelected={onMatchSelected}
          />
        ) : (
          <AddContactPanel
            onDirty={isDirty.on}
            {...commonProps}
            onCancel={onRequestClose}
            onMatchSelected={onMatchSelected}
          />
        )
      }
    </DirtyFlyover>
  );
};
