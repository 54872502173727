import { FC, useCallback, useEffect, useState } from "react";
import { secondsToMilliseconds } from "date-fns";
import { useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { PaymentDeviceVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { getQueryKey } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { updateCachedListWithUpdatedItem } from "@libs/utils/queryCache";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useAccount } from "@libs/contexts/AccountContext";
import { Banner } from "@libs/components/UI/Banner";
import { Modal } from "@libs/components/UI/Modal";
import { ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import { PosCard } from "components/PatientProfile/Billing/PosCard";
import { activatePaymentDevice } from "api/settings/payments/mutations";
import { handleError } from "utils/handleError";
import { getPaymentDevice } from "api/billing/queries";
import { Divider } from "components/UI/Divider";

interface Props {
  posDevice: PaymentDeviceVO;
  onCancel: (posDevice: PaymentDeviceVO) => void;
  onActivate: (posDevice: PaymentDeviceVO) => void;
}

export const ActivatePosDeviceModal: FC<Props> = ({ posDevice, onActivate, onCancel }) => {
  const { practiceId } = useAccount();
  const [{ mutate: activateDevice, isLoading: isActivating }] = useApiMutations([activatePaymentDevice]);
  const polling = useBoolean(false);
  const [activationError, setActivationError] = useState(false);

  const queryClient = useQueryClient();

  useApiQueries([
    getPaymentDevice({
      args: { practiceId, paymentDeviceUuid: posDevice.uuid },
      queryOptions: {
        enabled: polling.isOn,
        onSuccess: (data) => {
          const updatedPosDevice = data.data.data;

          if (updatedPosDevice.state === "ACTIVATION_IN_PROGRESS") {
            // We're still activating, early exit.
            return;
          }

          polling.off();

          updateCachedListWithUpdatedItem<PaymentDeviceVO>(
            queryClient,
            { queryKey: [getQueryKey("practices", "listPaymentDevices"), { practiceId }] },
            updatedPosDevice,
            "uuid"
          );

          if (updatedPosDevice.state === "ACTIVATED") {
            toast.success(`Device "${updatedPosDevice.name}" successfully activated`);
            onActivate(updatedPosDevice);
          } else {
            toast.error(`Failed to activate device "${updatedPosDevice.name}"`);
            setActivationError(true);
          }
        },
        onError: handleError,
      },
    }),
  ]);

  const activatePos = useCallback(() => {
    activateDevice(
      { practiceId, deviceUuid: posDevice.uuid },
      {
        onError: handleError,
        onSuccess: polling.on,
      }
    );
  }, [activateDevice, polling, posDevice.uuid, practiceId]);

  useEffect(() => {
    let intervalId = 0;

    if (polling.isOn) {
      // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      const REFRESH_INTERNAL_MS = secondsToMilliseconds(2);

      intervalId = window.setInterval(() => {
        queryClient.invalidateQueries([
          getQueryKey("practices", "getPaymentDevice"),
          { practiceId, paymentDeviceUuid: posDevice.uuid },
        ]);
      }, REFRESH_INTERNAL_MS);
    }

    return () => window.clearInterval(intervalId);
  }, [polling.isOn, posDevice.uuid, practiceId, queryClient]);

  return (
    <Modal size="xs" title="Activate POS Device" onClose={() => onCancel(posDevice)}>
      {activationError && (
        <Banner theme="error" className="text-sm">
          Activation for this device failed.
        </Banner>
      )}
      <ModalContent padding="lg" className="flex flex-col gap-y-5">
        {activationError && (
          <>
            <div className="pl-2 text-sm">
              <ul className="list-disc">
                <li>
                  Make sure the device is connected to the internet and check the manual for troubleshooting.
                </li>
              </ul>
            </div>
            <Divider />
          </>
        )}
        <div className="grid grid-cols-[max-content_1fr] gap-x-8">
          <PosCard paymentPos={posDevice} className="h-fit" />
          <div className="text-sm flex flex-col gap-y-3.5 mt-3.5">
            <div>
              To fully validate the activation of this device, a test authorization for $1.00 (1 dollar) is
              required. The charge will be voided immediately after test completion.
            </div>
            <div>Please use a valid credit or debit card to run this test.</div>
          </div>
        </div>
      </ModalContent>
      <ModalFooter>
        <Button theme="secondary" className="w-28" onClick={() => onCancel(posDevice)}>
          Cancel
        </Button>
        <AsyncButton className="w-28" isLoading={isActivating || polling.isOn} onClick={activatePos}>
          Run Test
        </AsyncButton>
      </ModalFooter>
    </Modal>
  );
};
