import React from "react";
import Skeleton from "react-loading-skeleton";
import { ScaleOrdinal } from "d3-scale";
import { cx } from "@libs/utils/cx";
import { noop } from "@libs/utils/noop";
import { ReactComponent as ChartDivider } from "components/Dashboard/assets/ChartDivider.svg";
import { FilteredProvider } from "components/Dashboard/types";
import { ProviderFilterOption } from "components/Dashboard/hooks/useFormattedProviderRollups";
import { FilteredProviderType } from "utils/routing/dashboard/serializedFilter";

type ProviderButtonProps = {
  title: string;
  provider: FilteredProvider;
  selected?: boolean;
  value: number;
  isLoading: boolean;
  color?: string;
  formatValue?: (val: number) => string;
  onClick: (provider: FilteredProvider) => void;
};

const ProviderButton: React.FC<ProviderButtonProps> = ({
  title,
  provider,
  onClick,
  value,
  selected,
  isLoading,
  formatValue,
  color,
}) => {
  const disabled = value === 0 || isLoading;

  return (
    <li className="w-full">
      <button
        type="button"
        onClick={disabled ? noop : () => onClick(provider)}
        disabled={disabled}
        className={cx(
          `px-2
           rounded-md
           h-8
           enabled:hover:opacity-70
           flex
           w-full
           items-center
           justify-between
           gap-x-2`,
          selected && "font-sansSemiBold outline outline-1 outline-primaryTheme"
        )}
      >
        <div className="flex items-center gap-x-1 overflow-hidden">
          {color && <span style={{ backgroundColor: color }} className="flex-none w-3 h-3" />}
          <span className="truncate">{title}</span>
        </div>

        <div className="flex-none">
          {isLoading ? <Skeleton className="w-16" /> : formatValue ? formatValue(value) : value}
        </div>
      </button>
    </li>
  );
};

export const FilterByProvider: React.FC<{
  providerFilterOptions: ProviderFilterOption[];
  isLoading: boolean;
  formatValue?: ProviderButtonProps["formatValue"];
  loadFailed: boolean;
  headerLabel?: string;
  providerLegend?: ScaleOrdinal<string, string, never>;
  onProviderClicked: (provider?: FilteredProvider | undefined) => void;
  providerFilters: { providerId?: number; providerJob?: FilteredProviderType };
}> = ({
  providerFilterOptions,
  isLoading,
  loadFailed,
  providerFilters,
  headerLabel,
  onProviderClicked,
  providerLegend,
  formatValue,
}) => {
  const buttonProps = {
    isLoading,
    onClick: onProviderClicked,
  };

  return (
    <div className="w-[310px] pl-3 pb-6 flex">
      <div className="px-3 text-greyLight">
        <ChartDivider />
      </div>

      <div className="h-full flex items-center justify-center w-full min-w-0">
        {loadFailed ? (
          <div>Providers could not be loaded at this time</div>
        ) : (
          <div
            className={`
              flex
              flex-col
              w-full
              justify-start
              h-full
              overflow-y-auto
              text-sm
            `}
          >
            <div
              className={`
                sticky
                top-0
                px-3
                py-1.5
                bg-white
                flex
                justify-between
                font-sansSemiBold
              `}
            >
              <div>Filter by</div>
              {headerLabel && <div>{headerLabel}</div>}
            </div>
            {providerFilterOptions.map((outerItem) => {
              return (
                <ul
                  key={outerItem.provider.jobCategory ?? "ALL"}
                  className="flex flex-col items-start w-full px-1 my-[1px]"
                >
                  <ProviderButton
                    title={outerItem.title}
                    {...buttonProps}
                    formatValue={formatValue}
                    value={outerItem.value}
                    provider={outerItem.provider}
                    selected={
                      !providerFilters.providerId &&
                      providerFilters.providerJob === outerItem.provider.jobCategory
                    }
                  />
                  {outerItem.items && (
                    <ul className="flex flex-col items-start pl-3 w-full">
                      {outerItem.items.map((innerItem) => {
                        return (
                          <ProviderButton
                            key={innerItem.provider.name?.id}
                            {...buttonProps}
                            title={innerItem.title}
                            provider={innerItem.provider}
                            value={innerItem.value}
                            formatValue={formatValue}
                            color={
                              innerItem.provider.name?.id && providerLegend
                                ? providerLegend(`${innerItem.provider.name.id}`)
                                : undefined
                            }
                            selected={providerFilters.providerId === innerItem.provider.name?.id}
                          />
                        );
                      })}
                    </ul>
                  )}
                </ul>
              );
            })}
          </div>
        )}
      </div>
    </div>
  );
};
