import { FormBooleanConditionalElementRequest } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { dateSchema, useDateFormState } from "components/Settings/Forms/Date/schema";
import { getNumberSchema, useNumberFormState } from "components/Settings/Forms/Number/schema";
import {
  getMultipleChoiceSchema,
  useMultipleChoiceState,
} from "components/Settings/Forms/MultipleChoice/schema";
import { useTextInputState, textInputSchema } from "components/Settings/Forms/Text/schema";

import { getFormElement } from "components/Settings/Forms/utils";

export const useYesNoAdditionalForm = (question: FormBooleanConditionalElementRequest | undefined) => {
  const dateFormState = useDateFormState(() =>
    question?.type === "DATE_INPUT" ? question : getFormElement("DATE_INPUT")
  );
  const numberFormState = useNumberFormState(() =>
    question?.type === "NUMBER_INPUT" ? question : getFormElement("NUMBER_INPUT")
  );
  const textFormState = useTextInputState(() =>
    question?.type === "TEXT_INPUT" ? question : getFormElement("TEXT_INPUT")
  );
  const multipleChoiceFormState = useMultipleChoiceState(() =>
    question?.type === "SELECT_INPUT" ? question : getFormElement("SELECT_INPUT")
  );
  const [{ draft: dateDraft }] = dateFormState;
  const [{ draft: numberDraft }] = numberFormState;
  const [{ draft: textDraft }] = textFormState;
  const [{ draft: multipleChoiceDraft }] = multipleChoiceFormState;

  return [
    {
      type: "DATE_INPUT" as const,
      formState: dateFormState,
      validation: useValidation(dateDraft, dateSchema),
    },
    {
      type: "NUMBER_INPUT" as const,
      formState: numberFormState,
      validation: useValidation(numberDraft, getNumberSchema({ min: numberDraft.min, max: numberDraft.max })),
    },
    {
      type: "TEXT_INPUT" as const,
      formState: textFormState,
      validation: useValidation(textDraft, textInputSchema),
    },
    {
      type: "SELECT_INPUT" as const,
      formState: multipleChoiceFormState,
      validation: useValidation(
        multipleChoiceDraft,
        getMultipleChoiceSchema({ options: multipleChoiceDraft.options })
      ),
    },
  ].find((f) => f.type === question?.type);
};
