import { FC, useCallback } from "react";
import { RouteIds } from "components/PatientProfile/types";
import { PatientInfoPeek } from "components/PatientBlurbInfo/PatientInfoPeek";
import { MedicalHistoryPeek } from "components/PatientBlurbInfo/MedicalHistoryPeek";
import { InsuranceInfoPeek } from "components/PatientBlurbInfo/InsuranceInfoPeek";
import { PATIENT_PEEK_BTN_ATTR } from "components/PatientBlurbInfo/patientPeek";
import { PeekPanel } from "components/PatientBlurbInfo/PeekPanel";

interface Props {
  patientId: number;
  peek: RouteIds | null;
  isOpen: boolean;
  onRequestClose: Func;
}

export const PatientBlubPeeks: FC<Props> = ({ patientId, isOpen, peek, onRequestClose }) => {
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (!(e.target as HTMLElement).closest(`[${PATIENT_PEEK_BTN_ATTR}]`)) {
        onRequestClose();
      }
    },
    [onRequestClose]
  );

  return (
    <PeekPanel isOpen={isOpen} onClickOutside={handleClickOutside}>
      {peek === "overview" ? (
        <PatientInfoPeek patientId={patientId} onRequestClose={onRequestClose} />
      ) : peek === "medical-history" ? (
        <MedicalHistoryPeek patientId={patientId} onRequestClose={onRequestClose} />
      ) : peek === "insurance" ? (
        <InsuranceInfoPeek patientId={patientId} onRequestClose={onRequestClose} />
      ) : null}
    </PeekPanel>
  );
};
