import { forwardRef, MouseEvent } from "react";
import { AppointmentVO, TagVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatFullNameToInitials } from "@libs/utils/formatString";
import { Glyph } from "@libs/components/UI/Glyph";
import { AppointmentTimeSpan } from "components/ScheduleAppointments/AppointmentDetailComponents";

interface Props {
  appointment: AppointmentVO;
  className?: string;
  groupingContextDetail?: string;
  hipaaView: boolean;
  isNoShow: boolean;
  tags: TagVO[];
  patientFontSize: "STANDARD" | "SMALL";
  onClick: (e: MouseEvent) => void;
  hidePreferredName: boolean;
}

const cxStyles = {
  truncate: "truncate w-full",
  patientName: ({
    isMultiLineHeight,
    isNoShow,
    fontSize,
  }: {
    isMultiLineHeight: boolean;
    isNoShow: boolean;
    fontSize: "STANDARD" | "SMALL";
  }) =>
    cx(
      "text-xs font-sansSemiBold truncate w-full",
      isNoShow && "pr-5",
      !isMultiLineHeight && "-mt-0.5",
      fontSize === "STANDARD" ? "text-xs" : "lg:text-xxxs xl:text-xxs"
    ),
};

export const APPOINTMENT_DURATION_FOR_TWO_LINES = 20;

const APPOINTMENT_DURATION_FOR_FOUR_LINES = 40;

export const AppointmentCardDetails = forwardRef<HTMLButtonElement, Props>(
  (
    {
      appointment,
      className,
      groupingContextDetail,
      tags,
      hipaaView,
      onClick,
      patientFontSize,
      hidePreferredName,
    },
    ref
  ) => {
    const { fullDisplayName, shortDisplayName } = appointment.patient;

    const duration = appointment.duration || 0;

    return (
      <button type="button" ref={ref} onClick={onClick} className={className}>
        <div className="flex justify-between">
          <div
            className={cxStyles.patientName({
              isMultiLineHeight: duration > APPOINTMENT_DURATION_FOR_TWO_LINES,
              isNoShow: appointment.state === "NO_SHOW",
              fontSize: patientFontSize,
            })}
          >
            <span className="pr-1 align-middle">
              {hipaaView
                ? formatFullNameToInitials({ fullName: shortDisplayName })
                : hidePreferredName
                  ? shortDisplayName
                  : fullDisplayName}
            </span>
            {tags.map((tag) => (
              <span key={tag.id} className="px-0.5 align-middle">
                <Glyph family={tag.glyph.family} inline>
                  {tag.glyph.value}
                </Glyph>
              </span>
            ))}
          </div>
        </div>

        {duration >= APPOINTMENT_DURATION_FOR_TWO_LINES && appointment.patientProcedures.length > 0 && (
          <div className={cxStyles.truncate}>
            {appointment.patientProcedures.map((item) => item.displayName).join(", ")}
          </div>
        )}
        {duration >= APPOINTMENT_DURATION_FOR_FOUR_LINES && (
          <>
            <div className={cxStyles.truncate}>{groupingContextDetail}</div>
            <AppointmentTimeSpan
              truncate
              duration={appointment.duration}
              date={appointment.date}
              state={appointment.state}
              startTime={appointment.startTime}
              endTime={appointment.endTime}
            />
          </>
        )}
      </button>
    );
  }
);
