import { Route } from "react-router-dom";
import { FC, useMemo } from "react";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { EmployeeGuard } from "components/EmployeeProfile/EmployeeGuard";
import { MainAppHistoryProvider } from "components/Main/MainLinksContext";
import { paths } from "utils/routing/paths";
import { useQueryParams } from "hooks/useQueryParams";
import { EmployeesManagementApp } from "components/Employee/EmployeesManagementApp";
import { EmployeesLinksProvider } from "components/Employee/EmployeesLinksContext";
import { EmployeeProfile } from "components/EmployeeProfile/EmployeeProfile";
import { EmployeeLinksProvider, getDefaultEmployeeLinks } from "components/Employee/EmployeeLinksContext";
import { usePathParams } from "hooks/usePathParams";
import { RoleGuardLock } from "components/Main/RoleGuard";

const EmployeesManagementRouteWrapper: FC = () => {
  return (
    <EmployeesLinksProvider>
      <EmployeesManagementApp />
    </EmployeesLinksProvider>
  );
};

const EmployeeRouteWrapper: FC = () => {
  const { query } = useQueryParams("employee");
  const from = query.from ?? paths.employees();
  const { id: employeeId } = usePathParams("employee");
  const employeeLinks = useMemo(() => getDefaultEmployeeLinks(employeeId), [employeeId]);

  return (
    <EmployeeGuard employeeId={employeeId}>
      <EmployeeLinksProvider defaultLinks={employeeLinks}>
        <EmployeeProfile backUrl={from} />
      </EmployeeLinksProvider>
    </EmployeeGuard>
  );
};

const EmployeesRoutes = [
  {
    path: "profiles/:id/*",
    element: <EmployeeRouteWrapper />,
  },
  {
    path: "*",
    element: (
      <RoleGuardLock domain="EMPLOYEE_SETTINGS" action="ACCESS_ALL">
        <EmployeesManagementRouteWrapper />
      </RoleGuardLock>
    ),
  },
];

export const EmployeesApp: FC = () => {
  return (
    <MainAppHistoryProvider name="employees">
      <SentryRoutes>
        {EmployeesRoutes.map(({ path, element }, i) => (
          <Route key={i} path={path} element={element} />
        ))}
      </SentryRoutes>
    </MainAppHistoryProvider>
  );
};
