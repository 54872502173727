import { CharacterIcon } from "components/UI/CharacterIcon";

export const DurationIcon: React.FC<{ durationType: string; className?: string; disabled?: boolean }> = ({
  durationType,
  className,
  disabled,
  ...props
}) => (
  <CharacterIcon
    size="xs"
    theme={disabled ? "disabled" : durationType === "X" ? "blueLight" : "magentaLight"}
    className={className}
    {...props}
  >
    {durationType === "X" ? "P" : "A"}
  </CharacterIcon>
);
