/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-magic-numbers */
import {
  ImageFilterDetails,
  ImageTransformDetails,
  MedicalImageVO,
  MountVO,
  PatientSummaryVO,
} from "@libs/api/generated-api";
import { DEGREES_180, DEGREES_90, SquareDegree, degreesToRadians, half } from "@libs/utils/math";
import { formatISODate } from "@libs/utils/date";
import { produce } from "immer";
import { sanitizeFilename } from "@libs/utils/sanitize-filename";
import {
  IMAGE_METADATA_HEIGHT,
  LayoutConfigType,
} from "components/PatientProfile/Imaging/PatientMountsList/mountLayouts";
import { ImageLayoutItem } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";
import { MouthLocation } from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/types";
import { MountLayoutType } from "api/imaging/imaging-api";
import { getMountName } from "components/PatientProfile/Imaging/PatientMountsList";

export const MOUNT_IMAGE_MAX_LENGTH = 40;
export const sharpnessId = (id: number) => `sharpen-filter-${id}`;

export const getFilters = (image: { id?: number; filters?: ImageLayoutItem["filters"] }) => {
  if (!image.filters || !image.id || Object.keys(image.filters).length === 0) {
    return undefined;
  }

  let val = "";

  if (image.filters.contrast) {
    const baseContrast = 1 + image.filters.contrast;

    val = `contrast(${baseContrast})`;
  }

  if (image.filters.brightness) {
    const baseBrightness = 1 + image.filters.brightness;

    val = `${val} brightness(${baseBrightness})`;
  }

  if (image.filters.sharpness) {
    val = `${val} url(#${sharpnessId(image.id)})`;
  }

  return val;
};

export const getMirrorTransformStyles = (inverted: boolean, mirror: string[] = []) => {
  let transform = "";
  let mirrorX = false;
  let mirrorY = false;

  if (mirror.length) {
    //When rotated 90  or 270, Y/X are inverted
    mirrorX = inverted ? mirror.includes("Y") : mirror.includes("X");
    mirrorY = inverted ? mirror.includes("X") : mirror.includes("Y");

    transform = `${transform} scale(${mirrorX ? -1 : 1}, ${mirrorY ? -1 : 1})`;
  }

  return { transform, mirrorX, mirrorY };
};

export const getImageTransformStyles = (transforms?: Partial<MedicalImageVO["transforms"]>) => {
  if (!transforms) {
    return {
      transform: undefined,
      sizeInverted: false,
      rotationDegrees: 0 as SquareDegree,
    };
  }

  const rotationDegrees = (transforms.rotationDegrees ?? 0) as SquareDegree;

  const sizeInverted = Math.abs(rotationDegrees % DEGREES_180) === DEGREES_90;
  const rotationApplies = rotationDegrees !== 0;
  let transform = `${getMirrorTransformStyles(sizeInverted, transforms.mirror).transform}`;

  if (rotationApplies) {
    transform = `rotate(${rotationDegrees}deg) ${transform}`;
  }

  return {
    transform: transform === "" ? undefined : transform,
    sizeInverted,
    rotationDegrees,
  };
};

export const OUTLINE_WIDTH = 4;
export const calculateOutlineSize = (scale: number) => OUTLINE_WIDTH / (scale > 1 ? scale : scale);

export const generateOverflowSpot = (lastSpot: LayoutConfigType[number], i: number): ImageLayoutItem => {
  const OVERLAP_OFFSET = 30;

  return {
    ...lastSpot,
    id: -Date.now(),
    x: lastSpot.x + OVERLAP_OFFSET,
    y: lastSpot.y + OVERLAP_OFFSET,
    i,
    sandbox: {
      w: lastSpot.w,
      h: lastSpot.h,
      isPlaceholder: true,
    },
  };
};

// When user uploads too many photos into a mount, we start laying them out in a grid
export const findOveflowSpot = (latestLayout: ImageLayoutItem[]): ImageLayoutItem => {
  const highestIndex = Math.max(...latestLayout.map((item) => item.i));
  const lowestItemLastIndex = latestLayout
    .filter((item) => item.i === highestIndex && !item.sandbox.outOfLayoutBounds)
    .sort((a, b) => b.y - a.y)[0];

  return generateOverflowSpot(
    {
      x: lowestItemLastIndex.x,
      y: lowestItemLastIndex.y,
      w: lowestItemLastIndex.sandbox.w,
      h: lowestItemLastIndex.sandbox.h,
    },
    lowestItemLastIndex.i
  );
};

export const createImageWithRotation = (
  imageUrl: string,
  rotation: number,
  imageSize: { width: number; height: number }
) => {
  return new Promise<string>((resolve) => {
    const img = new Image(imageSize.width, imageSize.height);

    img.addEventListener("load", () => {
      const absDegrees = Math.abs(rotation);
      const flipAxis = absDegrees !== DEGREES_180 && absDegrees !== 0;
      const canvas = document.createElement("canvas");

      canvas.width = flipAxis ? img.height : img.width;
      canvas.height = flipAxis ? img.width : img.height;

      const ctx = canvas.getContext("2d");

      if (ctx) {
        ctx.translate(half(canvas.width, 0), half(canvas.height, 0));
        ctx.rotate(degreesToRadians(rotation));
        ctx.drawImage(img, -half(img.width, 0), -half(img.height, 0));
      }

      resolve(canvas.toDataURL("image/png"));
    });

    img.src = imageUrl;
    img.crossOrigin = "anonymous";
  });
};

/*
Listing 17-32 and K-T in reverse order. So if labeling 10-26, it should show as 10-16, 26-17 rather than 10-26.
*/
const PRIMARY_BOUND = "K";
const PERMANENT_BOUND = 17;

const isOutOfBounds = (tooth: string): boolean => {
  if (Number.isNaN(Number(tooth))) {
    return tooth >= PRIMARY_BOUND;
  }

  return Number(tooth) >= PERMANENT_BOUND;
};

const codePointFor = (letter: string): number => {
  return letter.codePointAt(0) ?? 0;
};
// eslint-disable-next-line complexity
const isConsecutive = (a: string, b: string): boolean => {
  if (
    a.length !== b.length ||
    (Number.isNaN(Number(a)) && !Number.isNaN(Number(b))) ||
    (!Number.isNaN(Number(a)) && Number.isNaN(Number(b)))
  ) {
    return false;
  }

  if (Number.isNaN(Number(a)) && Number.isNaN(Number(b))) {
    return isOutOfBounds(a)
      ? codePointFor(a) - 1 === codePointFor(b)
      : codePointFor(a) === codePointFor(b) - 1;
  }

  return isOutOfBounds(a) ? Number(a) - 1 === Number(b) : Number(a) === Number(b) - 1;
};

export const orderTeethForOdontogram = (teeth: string[]) => {
  return teeth.sort((a, b) => {
    if (Number.isNaN(Number(a)) && Number.isNaN(Number(b))) {
      const result = codePointFor(a) - codePointFor(b);

      if (isOutOfBounds(a) && isOutOfBounds(b)) {
        return -result;
      }

      return result;
    }

    const result = Number(a) - Number(b);

    if (isOutOfBounds(a) && isOutOfBounds(b)) {
      return -result;
    }

    return result;
  });
};

const getConsecutiveRanges = (teeth: string[]): string => {
  const sortedTeeth = orderTeethForOdontogram(teeth);
  let result = "";
  let [start] = sortedTeeth;
  let prev = start;

  for (let i = 1; i < sortedTeeth.length; i++) {
    const current = sortedTeeth[i];

    if (isConsecutive(prev, current)) {
      prev = current;
    } else {
      result += start === prev ? `${start},` : `${start}-${prev},`;
      start = current;
      prev = current;
    }
  }

  result += start === prev ? `${start}` : `${start}-${prev}`;

  return result;
};

export const getTeethLabel = (teeth?: string[]) => {
  if (!teeth || teeth.length === 0) {
    return undefined;
  }

  return getConsecutiveRanges(teeth);
};

export const DEFAULT_TRANSFORMS: ImageTransformDetails = {
  mirror: [],
  rotationDegrees: 0,
};
export const DEFAULT_FILTERS: ImageFilterDetails = {
  sharpness: 0,
  contrast: 0,
  brightness: 0,
};

export const revertImageToDefaults = (image: MedicalImageVO) => ({
  ...image,
  filters: DEFAULT_FILTERS,
  transforms: DEFAULT_TRANSFORMS,
  annotation: "",
});
type MountTypeWithLocation = Exclude<
  MountLayoutType,
  "Pano" | "2 BW/PA" | "Grid" | "FMX / Photos" | "4 PA" | "4 BW/PA"
>;

const locationMap: Record<
  MountTypeWithLocation,
  ((numberInMount: number) => MouthLocation | undefined) | undefined
> = {
  "4 BW": (numberInMount: number) =>
    numberInMount < 3 ? "RIGHT_BW" : numberInMount >= 3 && numberInMount < 5 ? "LEFT_BW" : undefined,
  "4 BW 2 PA": (numberInMount: number) => {
    if (numberInMount < 3) {
      return "RIGHT_BW";
    } else if (numberInMount < 5) {
      return "LEFT_BW";
    } else if (numberInMount === 5) {
      return "UPPER_PA";
    } else if (numberInMount === 6) {
      return "LOWER_PA";
    }

    return undefined;
  },
  "2 BW 2 PA": (numberInMount: number) => {
    switch (numberInMount) {
      case 1: {
        return "RIGHT_BW";
      }
      case 2: {
        return "LEFT_BW";
      }
      case 3: {
        return "UPPER_PA";
      }
      case 4: {
        return "LOWER_PA";
      }
      default: {
        return undefined;
      }
    }
  },
  "4 BW 4 PA": (numberInMount: number) => {
    if (numberInMount < 3) {
      return "RIGHT_BW";
    } else if (numberInMount < 5) {
      return "LEFT_BW";
    } else if (numberInMount < 7) {
      return "UPPER_PA";
    } else if (numberInMount < 9) {
      return "LOWER_PA";
    }

    return undefined;
  },
  "FMX 14": (numberInMount: number) => {
    if (numberInMount < 5) {
      return "RIGHT_BW";
    } else if (numberInMount < 9) {
      return "LEFT_BW";
    } else if (numberInMount < 12) {
      return "UPPER_PA";
    } else if (numberInMount < 15) {
      return "LOWER_PA";
    }

    return undefined;
  },
  "FMX 16": (numberInMount: number) => {
    if (numberInMount === 8) {
      return "RIGHT_BW";
    } else if (numberInMount === 9) {
      return "LEFT_BW";
    } else if (numberInMount < 8) {
      return "UPPER_PA";
    } else if (numberInMount < 16) {
      return "LOWER_PA";
    }

    return undefined;
  },
  "FMX 18": (numberInMount: number) => {
    const rightSide = new Set([1, 2, 7, 8, 15, 16]);
    const leftSide = new Set([3, 4, 5, 6, 17, 18]);
    const top = new Set([9, 10, 11]);
    const bottom = new Set([12, 13, 14]);

    if (rightSide.has(numberInMount)) {
      return "RIGHT_BW";
    } else if (leftSide.has(numberInMount)) {
      return "LEFT_BW";
    } else if (top.has(numberInMount)) {
      return "UPPER_PA";
    } else if (bottom.has(numberInMount)) {
      return "LOWER_PA";
    }

    return undefined;
  },
  "FMX Perio": (numberInMount: number) => {
    const leftSide = new Set([6, 5, 4, 3, 18, 19, 20]);
    const rightSide = new Set([1, 2, 7, 8, 17, 16, 15]);
    const top = new Set([9, 10, 11]);
    const bottom = new Set([12, 13, 14]);

    if (rightSide.has(numberInMount)) {
      return "RIGHT_BW";
    } else if (leftSide.has(numberInMount)) {
      return "LEFT_BW";
    } else if (top.has(numberInMount)) {
      return "UPPER_PA";
    } else if (bottom.has(numberInMount)) {
      return "LOWER_PA";
    }

    return undefined;
  },
  "Perio BW": (numberInMount: number) => {
    if (numberInMount < 4) {
      return "RIGHT_BW";
    } else if (numberInMount < 7) {
      return "LEFT_BW";
    }

    return undefined;
  },
  "2 BW 1 PA 1 OCC": (numberInMount: number) => {
    switch (numberInMount) {
      case 1: {
        return "UPPER_PA";
      }
      case 2: {
        return "LOWER_PA";
      }
      case 3: {
        return "RIGHT_BW";
      }
      case 4: {
        return "LEFT_BW";
      }
      // No default
    }

    return undefined;
  },
  "2 BW 3 PA 1 OCC": (numberInMount: number) => {
    if (numberInMount < 4) {
      return "UPPER_PA";
    }

    switch (numberInMount) {
      case 4: {
        return "LOWER_PA";
      }
      case 5: {
        return "RIGHT_BW";
      }
      case 6: {
        return "LEFT_BW";
      }
      // No default
    }

    return undefined;
  },
  "4 BW 3 PA 1 PA": (numberInMount: number) => {
    if (numberInMount < 4) {
      return "UPPER_PA";
    } else if (numberInMount === 4) {
      return "LOWER_PA";
    } else if (numberInMount < 7) {
      return "RIGHT_BW";
    } else if (numberInMount < 9) {
      return "LEFT_BW";
    }

    return undefined;
  },
};

export const mountItemMatchesLocation = ({
  mountLayout,
  index,
  location,
}: {
  mountLayout: MountLayoutType;
  index: number;
  location: MouthLocation;
}) => {
  const numberInMount = index + 1;
  const getLocationWithMountNumber = locationMap[mountLayout as MountTypeWithLocation];

  if (getLocationWithMountNumber) {
    return getLocationWithMountNumber(numberInMount) === location;
  }

  return false;
};

export const isBottomOrLeftSideOfMouth = (mountLayout: MountLayoutType, index: number) => {
  const numberInMount = index + 1;

  // eslint-disable-next-line default-case
  switch (mountLayout) {
    case "4 BW": {
      return new Set([3, 4]).has(numberInMount);
    }
    case "4 BW 2 PA": {
      return new Set([3, 4, 6]).has(numberInMount);
    }
    case "4 BW 4 PA": {
      return new Set([3, 4, 7, 8]).has(numberInMount);
    }
    case "FMX 14": {
      return new Set([5, 6, 7, 8, 12, 13, 14]).has(numberInMount);
    }
    case "FMX 18": {
      return new Set([3, 4, 5, 6, 12, 13, 14, 17, 18]).has(numberInMount);
    }
    case "FMX 16": {
      return numberInMount > 8;
    }
  }

  return false;
};

export const sortImagesByIndex = (a: MedicalImageVO, b: MedicalImageVO) => (a.i ?? 0) - (b.i ?? 0);

export const getExportedDocumentTag = (imageMount: MountVO, patient: PatientSummaryVO) => {
  return sanitizeFilename(
    `${patient.name.shortDisplayName}_${getMountName(imageMount)}_${formatISODate(imageMount.date)}`
  );
};

const getMaximumBounds = (gridImages: { x: number; y: number; w: number; h: number }[]) => {
  const x = Math.min(...gridImages.map((item) => item.x));
  const y = Math.min(...gridImages.map((item) => item.y));
  const maxX = Math.max(...gridImages.map((item) => item.x + item.w));
  const maxY = Math.max(...gridImages.map((item) => item.y + item.h));

  return { origin: { x, y }, totalArea: { width: maxX - x, height: maxY - y } };
};

// Fits a set of images into a printable area (passed in dimensions)
export const fitMountLayout = ({
  gridImages,
  dimensions,
  selectedImageIds,
  isViewingArchived,
}: {
  gridImages: ImageLayoutItem[];
  dimensions: {
    size: { width: number; height: number };
    headerSize: number;
  };
  isViewingArchived: boolean;
  selectedImageIds: Set<number>;
}) => {
  const fittableSize = {
    ...dimensions.size,
    height: dimensions.size.height - dimensions.headerSize,
  };
  const imagesInGrid = gridImages.filter(
    (item) =>
      Boolean(item.url) &&
      (selectedImageIds.size > 0 ? selectedImageIds.has(item.id) : true) &&
      (isViewingArchived || !item.isArchived)
  );

  const { origin: topLeftOrigin, totalArea } = getMaximumBounds(
    imagesInGrid.map((item) => {
      // If size is inverted due to rotation, need to swap width and height
      const { sizeInverted } = getImageTransformStyles(item.transforms);

      return {
        x: item.x,
        y: item.y,
        w: sizeInverted ? item.sandbox.h : item.sandbox.w,
        h: (sizeInverted ? item.sandbox.w : item.sandbox.h) + IMAGE_METADATA_HEIGHT,
      };
    })
  );

  const widthOverflow = totalArea.width - fittableSize.width;
  const heightOverflow = totalArea.height - fittableSize.height;
  let computedFittedImages = imagesInGrid;

  const computedScale =
    widthOverflow > heightOverflow
      ? Math.abs(fittableSize.width / totalArea.width)
      : Math.abs(fittableSize.height / totalArea.height);

  computedFittedImages = imagesInGrid.map((item) => {
    return produce(item, (draft) => {
      draft.x = (item.x - topLeftOrigin.x) * computedScale;
      draft.y = (item.y - topLeftOrigin.y) * computedScale;
      draft.sandbox.w = item.sandbox.w * computedScale;
      draft.sandbox.h = item.sandbox.h * computedScale;

      return draft;
    });
  });

  const { totalArea: fittedTotalArea } = getMaximumBounds(
    computedFittedImages.map((item) => {
      // If size is inverted due to rotation, need to swap width and height
      const { sizeInverted } = getImageTransformStyles(item.transforms);

      return {
        x: item.x,
        y: item.y,
        w: sizeInverted ? item.sandbox.h : item.sandbox.w,
        h: (sizeInverted ? item.sandbox.w : item.sandbox.h) + IMAGE_METADATA_HEIGHT * computedScale,
      };
    })
  );

  const centeredOrigin = { x: 0, y: 0 };

  if (fittedTotalArea.width < fittableSize.width) {
    centeredOrigin.x = half(fittableSize.width - fittedTotalArea.width);
  }

  if (fittedTotalArea.height < fittableSize.height) {
    centeredOrigin.y = half(fittableSize.height - fittedTotalArea.height);
  }

  return {
    fittedImages: computedFittedImages.map((item) => {
      return produce(item, (draft) => {
        draft.x = item.x + centeredOrigin.x;
        draft.y = item.y + centeredOrigin.y;

        return draft;
      });
    }),
    scale: computedScale,
  };
};
