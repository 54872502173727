import { CollectedPaymentByProviderEntryVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { formatISODate } from "@libs/utils/date";
import { EMPTY_CELL, Row } from "@libs/components/UI/GridTableComponents";
import {
  labelForPayerType,
  labelForPaymentDetails,
  labelForPaymentMethod,
} from "components/Dashboard/PaymentCollected/utils";
import { PatientNameLink } from "components/Dashboard/Tables/PatientNameLink";
import { paths } from "utils/routing/paths";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";

type Props = {
  data: CollectedPaymentByProviderEntryVO;
  last?: boolean;
};

export const ByProviderTableRow: React.FC<Props> = ({ last, data }) => {
  const { provider, patient, paymentDate, payerType, paymentAmount, paymentMethod, associatedProvider } =
    data;
  const cellProps = { last, to: paths.patient({ patientId: patient.id }) };

  return (
    <Row>
      <DashboardLinkCell {...cellProps}>{provider?.fullDisplayName ?? EMPTY_CELL}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        {associatedProvider?.fullDisplayName ?? EMPTY_CELL}
      </DashboardLinkCell>

      <DashboardLinkCell {...cellProps}>
        <PatientNameLink patient={patient} />
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        {paymentDate ? formatISODate(paymentDate, "MM/dd/yyyy") : EMPTY_CELL}{" "}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{labelForPayerType(payerType)}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{labelForPaymentMethod(paymentMethod)}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{labelForPaymentDetails(data)}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(paymentAmount)}
      </DashboardLinkCell>
    </Row>
  );
};
