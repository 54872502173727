/* istanbul ignore file */
import { HeaderData } from "components/Dashboard/Tables/types";

export const END_OF_DAY_REPORT_HEADERS: HeaderData[] = [
  { label: "Patient", width: "auto", sortField: "patientLastName" },
  { label: "Date", width: "8rem" },
  { label: "Provider", width: "auto" },
  { label: "Description", width: "auto" },
  { label: "Amount", width: "minmax(6rem, 8rem)", align: "right" },
];
