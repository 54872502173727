import { FC, ReactNode } from "react";
import { AlertSettingsVO, UpdateAlertSettingsRequest } from "@libs/api/generated-api";
import { AlertSection } from "components/Settings/Notes/AlertSection";
import { AlertCheckboxList } from "components/Settings/Notes/AlertCheckboxList";
import { Divider } from "components/UI/Divider";
import { AlertAppearance } from "components/Settings/Notes/AlertAppearance";
import { AlertsSettingsEditor } from "components/Settings/AlertSettingsEditor";

interface Props {
  alertSettings: AlertSettingsVO;
  isSaving: boolean;
  isSaved: boolean;
  updateInProgress: ReactNode;
  onSave: (updates: UpdateAlertSettingsRequest) => void;
}

export const AlertsPage: FC<Props> = (props) => {
  return (
    <AlertsSettingsEditor {...props}>
      {({ alertSettingsState, updateAlertSettingsState }) => {
        const { appearance, enabledPatientAutoTagIds } = alertSettingsState;

        return (
          <>
            <AlertSection
              title="Alert Appearance"
              description="Any patient note can be set to alert by clicking the bell icon. Specify how you would like alerts to appear when a patient is selected."
            >
              <AlertAppearance
                appearance={appearance}
                onChange={(newAppearance) => updateAlertSettingsState({ appearance: newAppearance })}
              />
            </AlertSection>
            <Divider className="border-dashed" />
            <AlertSection
              title="Auto Alerts"
              description="Archy creates a range of notes automatically for patients when they meet certain criteria. Select which of these auto-notes you would like to have set as alerts by default."
            >
              <AlertCheckboxList
                onUpdate={(newSet) => updateAlertSettingsState({ enabledPatientAutoTagIds: newSet })}
                selectedTags={enabledPatientAutoTagIds}
                tagOptions={props.alertSettings.patientAutoTags}
              />
            </AlertSection>
          </>
        );
      }}
    </AlertsSettingsEditor>
  );
};
