import { FC, Dispatch, SetStateAction } from "react";
import { produce } from "immer";

import { PatientCriteriaVO, PatientListCriteria } from "@libs/api/generated-api";
import { Button } from "@libs/components/UI/Button";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { FormFieldCurrencyInput } from "components/UI/FormFieldCurrencyInput";

import { FilterSection } from "components/Communications/Filters/FilterSection";
import { PatientListCriteriaValidationResult } from "components/Communications/Filters/types";

interface Props extends Pick<PatientCriteriaVO, "insuranceBalanceRange"> {
  validationResult: PatientListCriteriaValidationResult["patientCriteria"]["insuranceBalanceRange"];
  onUpdatePatientListCriteria: Dispatch<SetStateAction<PatientListCriteria>>;
}

export const InsuranceBalanceFilterSection: FC<Props> = ({
  insuranceBalanceRange,
  validationResult,
  onUpdatePatientListCriteria,
}) => (
  <FilterSection
    title="Insurance Balance"
    dataTestId="insurance-balance-filter-section"
    isOpen={Boolean(insuranceBalanceRange?.min != null || insuranceBalanceRange?.max != null)}
    hasError={Boolean(validationResult.min.$error)}
  >
    <div className="flex flex-col gap-y-2">
      <div className="flex items-center gap-x-2">
        <FormFieldCurrencyInput
          aria-label="Min Insurance Balance"
          className="w-24"
          placeholder="Min"
          decimalScale={0}
          value={insuranceBalanceRange?.min ?? ""}
          onValueChange={(value) =>
            onUpdatePatientListCriteria((last) =>
              produce(last, (draft) => {
                const min = value ? Number(value) : undefined;

                if (min == null && draft.patientCriteria?.insuranceBalanceRange?.max == null) {
                  delete draft.patientCriteria?.insuranceBalanceRange;
                } else if (draft.patientCriteria) {
                  draft.patientCriteria.insuranceBalanceRange = {
                    ...draft.patientCriteria.insuranceBalanceRange,
                    min,
                  };
                } else {
                  draft.patientCriteria = { insuranceBalanceRange: { min } };
                }
              })
            )
          }
          error={validationResult.min.$error}
          displayErrorMessage={false}
        />

        <span className="text-xs text-black">-</span>

        <FormFieldCurrencyInput
          aria-label="Max Insurance Balance"
          className="w-24"
          placeholder="Max"
          decimalScale={0}
          value={insuranceBalanceRange?.max ?? ""}
          onValueChange={(value) =>
            onUpdatePatientListCriteria((last) =>
              produce(last, (draft) => {
                const max = value ? Number(value) : undefined;

                if (max == null && draft.patientCriteria?.insuranceBalanceRange?.min == null) {
                  delete draft.patientCriteria?.insuranceBalanceRange;
                } else if (draft.patientCriteria) {
                  draft.patientCriteria.insuranceBalanceRange = {
                    ...draft.patientCriteria.insuranceBalanceRange,
                    max,
                  };
                } else {
                  draft.patientCriteria = { insuranceBalanceRange: { max } };
                }
              })
            )
          }
          error={validationResult.min.$error}
          displayErrorMessage={false}
        />
      </div>

      {validationResult.min.$error ? <FormFieldError>{validationResult.min.$error}</FormFieldError> : null}

      {insuranceBalanceRange?.min != null || insuranceBalanceRange?.max != null ? (
        <Button
          className="w-fit text-xs"
          onClick={() =>
            onUpdatePatientListCriteria((last) =>
              produce(last, (draft) => {
                if (draft.patientCriteria) {
                  delete draft.patientCriteria.insuranceBalanceRange;
                }
              })
            )
          }
          size="custom"
          theme="link"
        >
          Clear
        </Button>
      ) : null}
    </div>
  </FilterSection>
);
