import { sentenceCaseConstant } from "@libs/utils/casing";
import { toFilterComponentsProps } from "@libs/components/UI/queryFilterPillsUtils";
import { LabCasesQuery } from "utils/routing/labCases";

export const getLabCasesFilterProps = (params: Omit<LabCasesQuery, "showArchived">) => {
  return toFilterComponentsProps(params, [
    {
      type: "list",
      prop: "labCaseStatuses",
      format: (val) => sentenceCaseConstant(val),
    },
  ]);
};
