import { MergeEmployeeVO } from "@libs/api/generated-api";

const isMatchedEmployee = (employee: MergeEmployeeVO) => Boolean(employee.archyId && employee.gustoId);

export const formatEmployeeName = (employee: MergeEmployeeVO) => {
  return `${employee.firstName}${employee.middleName ? ` ${employee.middleName} ` : " "}${employee.lastName}`;
};

export const sortMergeEmployees = (employees: MergeEmployeeVO[]) => {
  const mergeEmployees = [...employees];

  mergeEmployees.sort((employeeA, employeeB) => {
    const isMatchedEmployeeA = isMatchedEmployee(employeeA);
    const isMatchedEmployeeB = isMatchedEmployee(employeeB);

    if (isMatchedEmployeeA && !isMatchedEmployeeB) {
      return 1;
    }

    if (!isMatchedEmployeeA && isMatchedEmployeeB) {
      return -1;
    }

    const employeeNameA = formatEmployeeName(employeeA);
    const employeeNameB = formatEmployeeName(employeeB);

    if (employeeNameA > employeeNameB) {
      return 1;
    }

    if (employeeNameA < employeeNameB) {
      return -1;
    }

    return 0;
  });

  return mergeEmployees;
};
