import { FC, FormEvent, useState, useRef, useMemo, useCallback } from "react";
import { flushSync } from "react-dom";

import { DentalProcedureVO, AdaProcedureOverrideRequest } from "@libs/api/generated-api";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldChildrenContainer } from "@libs/components/UI/FormField";
import { CharacterCounter } from "@libs/components/UI/CharacterCounter";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useValidation } from "@libs/hooks/useValidation";

import { Flyover } from "components/UI/Flyover";
import { FlyoverForm, FlyoverContent, FlyoverFooter } from "components/UI/FlyoverComponents";
import { TooltipLabel } from "components/UI/TooltipLabel";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";

import { DurationSection } from "components/Settings/Scheduling/Sections/ProcedureDurations/DurationSection";
import {
  getExpiredCodeMessage,
  procedureSchema,
  CODE_MAX_LENGTH,
  NAME_MAX_LENGTH,
  SIMPLE_NAME_MAX_LENGTH,
  LAYMAN_TERM_MAX_LENGTH,
} from "components/Settings/Scheduling/Sections/ProcedureDurations/utils";

import { overrideAdaProcedure } from "api/practice/mutations";
import { useNow } from "hooks/useNow";
import { scrollToFirstError } from "utils/scrollToFirstError";
import { handleError } from "utils/handleError";

type Props = {
  procedure: DentalProcedureVO;
  onClose: Func;
};

export const EditProcedureFlyover: FC<Props> = ({ procedure, onClose }) => {
  const [procedureOverride, setProcedureOverride] = useState<AdaProcedureOverrideRequest>(() => ({
    simpleName: procedure.simpleName,
    laymanTerm: procedure.laymanTerm,
    duration: procedure.duration,
    isHygiene: procedure.isHygiene,
    creditToPractice: procedure.creditToPractice,
  }));

  const { practiceId } = useAccount();
  const formContainer = useRef<HTMLDivElement>(null);
  const now = useNow();

  const expiredCodeMessage = useMemo(
    () => getExpiredCodeMessage(procedure.endDate, now),
    [procedure.endDate, now]
  );

  const { validate, result: validationResult } = useValidation(procedureOverride, procedureSchema);

  const [overrideAdaProcedureMutation] = useApiMutations([overrideAdaProcedure]);
  const overrideAdaProcedureMutate = overrideAdaProcedureMutation.mutate;

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const result = flushSync(() => validate());

      if (!result.$isValid) {
        scrollToFirstError(formContainer.current);

        return;
      }

      overrideAdaProcedureMutate(
        { practiceId, dentalProcedureId: procedure.id, data: procedureOverride },
        { onSuccess: onClose, onError: handleError }
      );
    },
    [practiceId, procedure.id, procedureOverride, validate, overrideAdaProcedureMutate, onClose]
  );

  return (
    <Flyover title="Edit Procedure Code" onClose={onClose} headerSize="sm" size="md">
      {({ close }) => (
        <FlyoverForm onSubmit={handleSubmit}>
          <FlyoverContent containerRef={formContainer} paddingClassName="flex flex-col gap-y-6 p-6">
            <FormFieldInput
              label={
                expiredCodeMessage ? (
                  <TooltipLabel SvgIcon={ErrorIcon} tooltip={{ content: expiredCodeMessage }} theme="error">
                    Code
                  </TooltipLabel>
                ) : (
                  "Code"
                )
              }
              value={procedure.cdtCode}
              disabled
              required
            >
              <FormFieldChildrenContainer align="center">
                <CharacterCounter currentCount={procedure.cdtCode.length} max={CODE_MAX_LENGTH} />
              </FormFieldChildrenContainer>
            </FormFieldInput>

            <FormFieldTextarea
              label="Description"
              value={procedure.name}
              disableResize
              disabled
              required
              rows={3}
            >
              <FormFieldChildrenContainer align="bottom">
                <CharacterCounter currentCount={procedure.name.length} max={NAME_MAX_LENGTH} />
              </FormFieldChildrenContainer>
            </FormFieldTextarea>

            <FormFieldInput
              label="Simple Name"
              value={procedureOverride.simpleName}
              onChange={(e) => setProcedureOverride((last) => ({ ...last, simpleName: e.target.value }))}
              maxLength={SIMPLE_NAME_MAX_LENGTH}
              error={validationResult.simpleName.$error}
              required
            >
              <FormFieldChildrenContainer align="center">
                <CharacterCounter
                  currentCount={procedureOverride.simpleName.length}
                  max={SIMPLE_NAME_MAX_LENGTH}
                />
              </FormFieldChildrenContainer>
            </FormFieldInput>

            <FormFieldInput
              label={
                <TooltipLabel
                  tooltip={{
                    content:
                      "This name will be used on treatment plans, invoices, receipts, and any patient-facing communications.",
                  }}
                >
                  External Name
                </TooltipLabel>
              }
              value={procedureOverride.laymanTerm}
              onChange={(e) => setProcedureOverride((last) => ({ ...last, laymanTerm: e.target.value }))}
              maxLength={LAYMAN_TERM_MAX_LENGTH}
            >
              <FormFieldChildrenContainer align="center">
                <CharacterCounter
                  currentCount={procedureOverride.laymanTerm?.length ?? 0}
                  max={LAYMAN_TERM_MAX_LENGTH}
                />
              </FormFieldChildrenContainer>
            </FormFieldInput>

            <DurationSection
              duration={procedureOverride.duration}
              onAdd={(durationType) =>
                setProcedureOverride((last) => ({
                  ...last,
                  // Assistant is presented as / and Provider is X
                  duration: `${last.duration}${durationType === "A" ? "/" : "X"}`,
                }))
              }
              onRemove={(index) =>
                setProcedureOverride((last) => ({
                  ...last,
                  duration: last.duration.slice(0, index) + last.duration.slice(index + 1),
                }))
              }
              error={validationResult.duration.$error}
            />

            <div className="flex flex-col gap-y-3">
              <span className="font-sansSemiBold text-xs">Options</span>

              <div className="flex flex-col gap-y-4">
                <Checkbox
                  checked={Boolean(procedureOverride.isHygiene)}
                  onChange={(e) => setProcedureOverride((last) => ({ ...last, isHygiene: e.target.checked }))}
                >
                  <TooltipLabel
                    tooltip={{
                      content: "When selected, this custom procedure can be assigned to a hygienist.",
                    }}
                  >
                    Hygiene Procedure
                  </TooltipLabel>
                </Checkbox>

                <Checkbox
                  checked={Boolean(procedureOverride.creditToPractice)}
                  onChange={(e) =>
                    setProcedureOverride((last) => ({ ...last, creditToPractice: e.target.checked }))
                  }
                >
                  <TooltipLabel
                    tooltip={{
                      content:
                        "When selected, this procedure will not be credited to a specific hygienist or doctor. This will immediately apply to all incomplete procedures.",
                    }}
                  >
                    Credit to Practice
                  </TooltipLabel>
                </Checkbox>
              </div>
            </div>
          </FlyoverContent>

          <FlyoverFooter>
            <Button className="min-w-button" onClick={close} theme="secondary">
              Cancel
            </Button>
            <AsyncButton
              className="min-w-button"
              isLoading={overrideAdaProcedureMutation.isLoading}
              type="submit"
            >
              Save
            </AsyncButton>
          </FlyoverFooter>
        </FlyoverForm>
      )}
    </Flyover>
  );
};
