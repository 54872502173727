import { ValidationResult, useValidation } from "@libs/hooks/useValidation";
import { dayGreaterOrEqualTo, lessOrEqualTo, required } from "@libs/utils/validators";
import { sub } from "date-fns";
import { WalletVO } from "@libs/api/generated-api";
import { PaymentDraft } from "components/PatientProfile/Billing/Payment/useCollectPaymentForm";
import {
  paymentAmountValidator,
  paymentNoteValidator,
} from "components/PatientProfile/Billing/Payment/validation";
import { isPaymentMethodByCard } from "components/PatientProfile/Billing/billingUtils";

type ValidationInput = {
  maxAmountDue: number;
  selectedWallet?: WalletVO;
  paymentDraft: Pick<
    PaymentDraft,
    | "paymentAmount"
    | "paymentDate"
    | "paymentMethod"
    | "note"
    | "selectedPaymentPosUuid"
    | "selectedPaymentProfileUuid"
  >;
};

const MAX_DAYS_PAYMENT_PAST = 7;
const getPaymentFormSchema = ({ paymentDraft, maxAmountDue, selectedWallet }: ValidationInput) => {
  return {
    paymentMethod: [{ $v: required, $error: "Payment Method required" }],
    paymentDate: [
      {
        $v: required,
        $ignore: isPaymentMethodByCard(paymentDraft.paymentMethod),
        $error: "Payment Date required",
      },
      {
        $v: dayGreaterOrEqualTo(sub(new Date(), { days: MAX_DAYS_PAYMENT_PAST })),
        $ignore: isPaymentMethodByCard(paymentDraft.paymentMethod),
        $error: `Payment Date must be within ${MAX_DAYS_PAYMENT_PAST} days`,
      },
    ],
    selectedPaymentProfileUuid: [
      {
        $ignore: paymentDraft.paymentMethod !== "STORED_PROFILE",
        $v: required,
        $error: "Credit Card is required",
      },
    ],
    selectedPaymentPosUuid: [
      {
        $ignore: paymentDraft.paymentMethod !== "STORED_POS",
        $v: required,
        $error: "A POS is required",
      },
    ],
    paymentAmount: [
      ...paymentAmountValidator(maxAmountDue),
      {
        $ignore: paymentDraft.paymentMethod !== "WALLET" || !selectedWallet,
        $v: lessOrEqualTo(selectedWallet?.balance ?? 0),
        $error: "Insufficient wallet funds",
      },
    ],
    note: paymentNoteValidator,
  };
};

export const useCollectPaymentFormValidation = ({
  isValidating = true,
  input,
}: {
  input: ValidationInput;
  isValidating?: boolean;
}) => {
  return useValidation(input.paymentDraft, getPaymentFormSchema(input), { isValidating });
};

export type UsePaymentFormValidationResult = ValidationResult<
  ValidationInput["paymentDraft"],
  ReturnType<typeof getPaymentFormSchema>
>;
