import { FC, PropsWithChildren } from "react";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";

export const PanelTitle: FC<PropsWithChildren & { onAdd: Func }> = ({ children, onAdd: onAdd }) => {
  return (
    <div className="flex gap-x-3 items-center">
      {children} <ButtonIcon SvgIcon={AddIcon} theme="primary" onClick={onAdd} size="lg" />
    </div>
  );
};
