import { FC, PropsWithChildren, useEffect, useRef } from "react";

import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { RadioList } from "@libs/components/UI/RadioList";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { InstallTwainDrivers } from "components/UserDocuments/InstallTwainDrivers";
import { ScannerSettings, useScannerSettings } from "components/UserDocuments/useScannerSettings";

import { useImagingHub } from "components/ImageCapturing/ImagingHubContext";
import { useDynamsoftScanner } from "components/UI/AttachModalContent/hooks/useDynamsoftScanner";
import { useArchyImagingServiceScanner } from "components/UI/AttachModalContent/hooks/useArchyImagingServiceScanner";
import { AISDownloadWarning } from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/AISDownloadWarning";
import { AISUpdateWarning } from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/AISUpdateWarning";

const ColorModeOptions: OptionInputOption<ScannerSettings["colorMode"]>[] = [
  { label: "Black & White", value: "BW" },
  { label: "Gray", value: "GRAY" },
  { label: "Color", value: "RGB" },
];

const SettingOptions = [
  { label: "Document Feeder", value: "USE_FEEDER" },
  { label: "Remove Blank Pages", value: "AUTO_DISCARD_BLANK_PAGES" },
  { label: "2-Sided Scan", value: "USE_DUPLEX" },
];

interface Props {
  filename?: string;
  onScanFiles: (files: File[]) => void | Promise<void>;
  onRequestClose: Func;
  scanAs: "PDF" | "JPG";
  disabled?: boolean;
}

const useScanner = (params: Omit<Props, "disabled" | "children"> & { scannerSettings: ScannerSettings }) => {
  const { status } = useImagingHub();

  const archyScannerParams = useArchyImagingServiceScanner(params);

  if (status.isRunning && !status.isAISDisabled) {
    return archyScannerParams;
  }

  // eslint-disable-next-line react-hooks/rules-of-hooks
  const dynamsoftParams = useDynamsoftScanner(params);

  // Drive users to download the archy imaging service if it's enabled for that practice.
  return {
    ...dynamsoftParams,
    driverDownloadUrl: dynamsoftParams.driverDownloadUrl,
  };
};

export const AttachScanModalContent: FC<PropsWithChildren<Props>> = ({
  filename,
  onScanFiles,
  onRequestClose,
  scanAs,
  disabled,
  children,
}) => {
  const { scannerSettings, setScannerSettings, selectedSettings } = useScannerSettings();
  const { status: aisStatus } = useImagingHub();
  const abortControllerRef = useRef<AbortController | null>(null);

  useEffect(() => {
    return () => {
      // If there is a scan in progress and they leave, we should abort it in AIS
      abortControllerRef.current?.abort();
    };
  }, []);

  const {
    deviceOptions,
    isScanning,
    canScanDocument,
    handleScanDocument,
    driverDownloadUrl,
    isLoadingSources,
  } = useScanner({
    filename,
    onScanFiles,
    onRequestClose,
    scanAs,
    scannerSettings,
  });

  return (
    <>
      <ModalContent className="flex flex-col gap-y-6 px-8 py-6">
        {!aisStatus.isRunning && !aisStatus.isAISDisabled && <AISDownloadWarning />}
        <AISUpdateWarning headerMode={false} />

        {driverDownloadUrl ? (
          <InstallTwainDrivers downloadUrl={driverDownloadUrl} isAISWhitelisted={!aisStatus.isAISDisabled} />
        ) : (
          <>
            {children}

            <FormFieldSelect
              label={<span className="font-sansSemiBold pb-2">Device</span>}
              layout="labelOut"
              placeholder="Select Scanner"
              options={deviceOptions}
              value={scannerSettings.preferredDeviceIndex}
              onItemSelected={(value) => {
                setScannerSettings((last) => ({ ...last, preferredDeviceIndex: value }));
              }}
              isLoading={isLoadingSources}
              loadingMessage={() => "Loading Scanners..."}
              isSearchable={false}
              required
            />

            <RadioList
              label={<span className="font-sansSemiBold pb-1">Color</span>}
              options={ColorModeOptions}
              selectedValue={scannerSettings.colorMode}
              onChange={(_e, option) => setScannerSettings((last) => ({ ...last, colorMode: option.value }))}
            />

            <CheckboxList
              label={<span className="font-sansSemiBold pb-1">Options</span>}
              options={SettingOptions}
              selectedValues={selectedSettings}
              onChange={(updatedSet) =>
                setScannerSettings((last) => ({
                  ...last,
                  useFeeder: updatedSet.has("USE_FEEDER"),
                  autoDiscardBlankPages: updatedSet.has("AUTO_DISCARD_BLANK_PAGES"),
                  useDuplex: updatedSet.has("USE_DUPLEX"),
                }))
              }
            />
          </>
        )}
      </ModalContent>

      <ModalFooter>
        <Button className="min-w-button" onClick={onRequestClose} theme="secondary">
          Cancel
        </Button>
        <AsyncButton
          className="min-w-button"
          onClick={async () => {
            const { promise, abortController } = handleScanDocument();

            abortControllerRef.current = abortController ?? null;

            try {
              await promise;
            } finally {
              abortControllerRef.current = null;
            }
          }}
          isLoading={isScanning}
          disabled={disabled || !canScanDocument}
          theme="primary"
        >
          Scan
        </AsyncButton>
      </ModalFooter>
    </>
  );
};
