/* eslint-disable complexity */
import React from "react";
import { ReportingRecallByPatientRequest } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { usePick } from "@libs/hooks/usePick";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { HeaderCheckboxCell } from "@libs/components/UI/GridTableComponents";
import { useFlattenEntries } from "components/Dashboard/hooks/useFlattenEntries";
import { useFilterTokenProps } from "components/Dashboard/hooks/useFilterTokenProps";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import { HeaderCell } from "components/Dashboard/Tables/HeaderCell";
import {
  RECALL_BY_PATIENT_HEADERS,
  RECALL_REPORT_AGING_OPTIONS,
} from "components/Dashboard/RecallReport/constants";
import { RecallReportQuery } from "utils/routing/dashboard";
import { useSortHandler } from "components/Dashboard/hooks/useSortHandler";
import { RecallReportTableByPatientRow } from "components/Dashboard/RecallReport/RecallReportTableByPatientRow";
import { SelectHasNextApptFilter } from "components/Dashboard/Tables/SelectHasNextApptFilter";
import { useQueryParams } from "hooks/useQueryParams";
import { useSelectedAgingOption } from "components/Dashboard/hooks/useSelectedAgingOption";
import { getRecallByPatientStats } from "api/reporting/queries";
import { MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";
import { DashboardTableTabs } from "components/Dashboard/Tables/DashboardTableTabs";
import { useTableTabProps } from "components/Dashboard/RecallReport/hooks/useTableTabProps";
import { useDownloadRecallByPatientReportCsv } from "components/Dashboard/RecallReport/hooks/useDownloadRecallByPatientReportCsv";
import { PatientStatusFilter } from "components/Dashboard/Tables/PatientStatusFilter";
import { useSelectRows } from "hooks/useSelectRows";
import { RecallReportByPatientBulkActionsRow } from "components/Dashboard/RecallReport/RecallReportByPatientBulkActionsRow";
import { MessagePatientsFlyover } from "components/Communications/MessagePatients/MessagePatientsFlyover";

const headers = [{ id: "checkbox", label: "", width: "3.25rem" }, ...RECALL_BY_PATIENT_HEADERS];

export const RecallReportByPatientTableRoute: React.FC = () => {
  const { query, updateQuery } = useQueryParams("dashboardRecall");
  const { search: debouncedSearch } = useDebouncedSearch(query.patientSearch ?? "");
  const { practiceId } = useAccount();

  const messagePatientsFlyover = useBoolean(false);

  const { startDate, endDate } = useSelectedAgingOption({
    dateAgeIndex: query.dateAgeIndex,
    labels: RECALL_REPORT_AGING_OPTIONS,
    includeFuture: true,
  });
  const { procedureTableSort, filters } = query;
  const recallByPatientRequest: ReportingRecallByPatientRequest = React.useMemo(
    () => ({
      startDate,
      endDate,
      patientSearchString: debouncedSearch,
      includeTotal: true,
      // This is defaulted for the case of the procedures tab. It's not a valid filter however for this tab
      filters,
      sortOrders: procedureTableSort,
    }),
    [startDate, endDate, debouncedSearch, filters, procedureTableSort]
  );
  const recallReportInfiniteQuery = useInfiniteApiQuery(
    getRecallByPatientStats({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: MAX_PAGE_SIZE,
        data: recallByPatientRequest,
      },
    })
  );
  const { data: paginatedData, isLoading } = recallReportInfiniteQuery;

  const selectionParams = usePick(query, ["dateAgeIndex", "filters", "patientSearch"]);
  const filterParams = usePick(query, ["filters", "patientSearch"]);
  const filterProps = useFilterTokenProps({ options: filterParams });
  const totalRows = getInfiniteQueryPagingDetails(paginatedData)?.totalElements ?? 0;
  const rows = useFlattenEntries(paginatedData);
  const patientIds = React.useMemo(() => rows.map((row) => row.patient.id), [rows]);
  const {
    selectedCount,
    selectedRows,
    deselectedRowsFromSelectAll,
    hasAllSelected,
    selectAllRows,
    resetSelectedRows,
    handleCheckboxChange,
  } = useSelectRows(patientIds, { totalItems: totalRows });

  // Reset selected rows when selectionParams change (e.g. dateAgeIndex, patientSearch)
  React.useEffect(() => resetSelectedRows(), [selectionParams, resetSelectedRows]);

  const isEmpty = !isLoading && rows.length === 0;
  const { isDownloading, downloadCSV } = useDownloadRecallByPatientReportCsv({
    recallReportInfiniteQuery,
    startDate,
    endDate,
  });

  const handleRouteStateChange = React.useCallback(
    (updates: Partial<RecallReportQuery>) => {
      updateQuery("replaceIn", updates);
      resetSelectedRows();
    },
    [updateQuery, resetSelectedRows]
  );
  const handleSorting = useSortHandler();
  const handleSortClick = React.useCallback(
    (newSortField: string) => {
      handleRouteStateChange({
        procedureTableSort: handleSorting(procedureTableSort, newSortField),
      });
    },
    [handleRouteStateChange, handleSorting, procedureTableSort]
  );

  const tableTabProps = useTableTabProps({ query, handleRouteStateChange });

  return (
    <>
      <DashboardTableTabs
        {...tableTabProps}
        isDownloading={isDownloading}
        onClickDownload={downloadCSV}
        id="recall-report-filters"
      >
        <PatientStatusFilter query={query} onUpdateParams={handleRouteStateChange} />
        <SelectHasNextApptFilter query={query} onUpdateParams={handleRouteStateChange} />
      </DashboardTableTabs>

      <RecallReportByPatientBulkActionsRow
        selectedCount={selectedCount}
        total={totalRows}
        onRequestMessagePatients={messagePatientsFlyover.on}
        filters={
          filterProps.filters.length ? (
            <QueryFilterPills
              {...filterProps}
              numResults={totalRows}
              onUpdateParams={handleRouteStateChange}
              onClearAll={() => {
                handleRouteStateChange({ filters: [], patientSearch: undefined });
              }}
            />
          ) : null
        }
      />

      <DashboardInfiniteTable
        infiniteQuery={recallReportInfiniteQuery}
        columnWidths={headers.map(({ width }) => width)}
        id="recallReportByPatientTable"
        isEmpty={isEmpty}
        className="rounded-b-md"
        headerRow={headers.map((props) => {
          const sortOrder = procedureTableSort.find((item) => item.field === props.sortField);

          return props.id === "checkbox" ? (
            <HeaderCheckboxCell
              key={props.id}
              className="border-t border-greyLighter"
              selectedCount={selectedCount}
              totalRows={totalRows}
              onSelectAllRows={selectAllRows}
              onDeselectAllRows={resetSelectedRows}
            />
          ) : (
            <HeaderCell
              key={props.label}
              {...props}
              onClick={handleSortClick}
              primarySortField={procedureTableSort[0].field}
              tableSortDirection={sortOrder?.direction}
            />
          );
        })}
      >
        {rows.map((row, i) => {
          const key = `${row.patient.id}`;

          return (
            <RecallReportTableByPatientRow
              key={key}
              row={row}
              last={i === rows.length - 1}
              checked={selectedRows.has(row.patient.id)}
              onCheckboxChange={handleCheckboxChange}
            />
          );
        })}

        {messagePatientsFlyover.isOn ? (
          <MessagePatientsFlyover
            type="REPORTING_RECALL"
            criteria={recallByPatientRequest}
            selectionCategory="REPORTING_RECALL"
            selectedCount={selectedCount}
            filteredCount={totalRows}
            selectedIds={selectedRows}
            deselectedIds={deselectedRowsFromSelectAll}
            hasAllSelected={hasAllSelected}
            onClose={messagePatientsFlyover.off}
          />
        ) : null}
      </DashboardInfiniteTable>
    </>
  );
};
