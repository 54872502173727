import { forwardRef, MouseEvent } from "react";
import { ScheduleBlockVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { APPOINTMENT_DURATION_FOR_TWO_LINES } from "components/ScheduleAppointments/AppointmentCardDetails";

interface Props {
  block: ScheduleBlockVO;
  duration: number;
  timeSpan: string;
  className?: string;
  groupingContextDetail?: string;
  onClick: (e: MouseEvent) => void;
  hipaaView?: boolean;
}

const cxStyles = {
  truncate: "truncate w-full",
};

// Constants required for determining how many detail lines get rendered
const PX_PER_MINUTE = 1.6; // mobiscroll renders this height in px per minute
const LINE_HEIGHT = 14; // text line height within card
const TITLE_LINE_HEIGHT = 16; // title line height is a little larger than the other lines
const VERTICAL_CARD_SPACING = 10; // borders + padding
const MINUTES_FOR_TIME_SPAN = Math.round(
  (LINE_HEIGHT + VERTICAL_CARD_SPACING + TITLE_LINE_HEIGHT) / PX_PER_MINUTE
);
const MINUTES_PER_LINE = LINE_HEIGHT / PX_PER_MINUTE;
const MINUTES_FOR_CONTEXT = Math.round(MINUTES_PER_LINE + MINUTES_FOR_TIME_SPAN);

export const BlockCardDetails = forwardRef<HTMLButtonElement, Props>(
  ({ block, groupingContextDetail, duration, timeSpan, onClick, className }, ref) => {
    const showTimeSpan = duration >= APPOINTMENT_DURATION_FOR_TWO_LINES;
    const showContextDetail = Boolean(duration >= MINUTES_FOR_CONTEXT && groupingContextDetail);
    const remainingMinutesForComments =
      duration - (showContextDetail ? MINUTES_FOR_CONTEXT : MINUTES_FOR_TIME_SPAN);
    const commentLines = Math.floor(remainingMinutesForComments / MINUTES_PER_LINE);

    return (
      <button type="button" ref={ref} onClick={onClick} className={className}>
        <div
          className={cx(
            "text-xs font-sansSemiBold truncate w-full",
            duration <= APPOINTMENT_DURATION_FOR_TWO_LINES && "-mt-0.5"
          )}
        >
          {block.title}
        </div>
        {showTimeSpan && <div className={cxStyles.truncate}>{timeSpan}</div>}
        {showContextDetail ? <div className={cxStyles.truncate}>{groupingContextDetail}</div> : null}
        {commentLines > 0 && block.comments ? (
          <div
            style={{
              display: "-webkit-box",
              WebkitLineClamp: commentLines,
              WebkitBoxOrient: "vertical",
            }}
            className="whitespace-pre-wrap break-words overflow-hidden"
          >
            {block.comments}
          </div>
        ) : null}
      </button>
    );
  }
);
