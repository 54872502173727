import { useCallback, useState } from "react";
import { produce } from "immer";
import { PatientFacingCarrierName } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Switch } from "@libs/components/UI/Switch";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent, FlyoverFooter, FlyoverForm } from "components/UI/FlyoverComponents";
import {
  OnlineBookingSection,
  SelfBookingConfigRequestSaveData,
} from "components/Settings/Scheduling/Sections/OnlineBooking/OnlineBooking";

interface Props {
  isEditing: boolean;
  isSaving: boolean;
  onClose: Func;
  onUpdateConfig: (updatedState: SelfBookingConfigRequestSaveData, onSuccess?: Func) => void;
  patientFacingCarrierNames: PatientFacingCarrierName[];
  selectedIndex: number;
}

const schema = {
  name: [{ $v: required, $error: "Name required" }],
};

export const InsuranceListFlyover: React.FC<Props> = ({
  isEditing,
  isSaving,
  onClose,
  onUpdateConfig,
  patientFacingCarrierNames,
  selectedIndex,
}) => {
  const [insuranceCarrier, setInsuranceCarrier] = useState<PatientFacingCarrierName>(
    patientFacingCarrierNames[selectedIndex] || { inNetwork: false, name: "" }
  );

  const { result, validate } = useValidation(insuranceCarrier, schema);

  const handleSubmit = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (!validate().$isValid) {
        return;
      }

      onUpdateConfig(
        {
          patientFacingCarrierNames: produce(patientFacingCarrierNames, (draft) => {
            draft[selectedIndex] = insuranceCarrier;
          }),
        },
        onClose
      );
    },
    [validate, onUpdateConfig, patientFacingCarrierNames, selectedIndex, insuranceCarrier, onClose]
  );

  return (
    <Flyover
      onClose={onClose}
      size="sm"
      title={isEditing ? "Edit Insurance Carrier" : "Add Insurance Carrier"}
    >
      <FlyoverForm onSubmit={handleSubmit}>
        <FlyoverContent className="text-xs">
          <div className="flex flex-col gap-y-6">
            <OnlineBookingSection title="Carrier Name">
              <FormFieldInput
                edit={true}
                error={result.name.$error}
                onChange={(e) => {
                  setInsuranceCarrier((last) => {
                    return {
                      ...last,
                      name: e.target.value,
                    };
                  });
                }}
                required
                type="text"
                value={insuranceCarrier.name}
              />
            </OnlineBookingSection>
            <OnlineBookingSection title="Display as In Network">
              <div className="p-1 mt-1">
                <Switch
                  checked={insuranceCarrier.inNetwork}
                  name="Display as In Network"
                  onChange={(e) => setInsuranceCarrier((last) => ({ ...last, inNetwork: e.target.checked }))}
                >
                  <div>{insuranceCarrier.inNetwork ? "Yes" : "No"}</div>
                </Switch>
              </div>
            </OnlineBookingSection>
          </div>
        </FlyoverContent>
        <FlyoverFooter>
          <Button className="min-w-button" onClick={onClose} theme="secondary">
            Cancel
          </Button>
          <AsyncButton className="min-w-button" isLoading={isSaving} theme="primary" type="submit">
            Save
          </AsyncButton>
        </FlyoverFooter>
      </FlyoverForm>
    </Flyover>
  );
};
