import { FC } from "react";
import { EmailDnsRecord } from "@libs/api/generated-api";
import { Button } from "@libs/components/UI/Button";
import { EmailDnsRecordRow } from "components/Settings/Communications/EmailDnsRecordRow";

import { FormSection } from "components/Settings/Communications/FormSection";
import { formatCsvRow, saveCsv } from "utils/csv";

interface Props {
  domainDnsConfig: EmailDnsRecord[];
  domain: string;
}

export const EmailDnsRecordsTable: FC<Props> = ({ domainDnsConfig, domain }) => {
  const dnsRecords = domainDnsConfig.map((record) => ({
    ...record,
    name: record.name.replace(`.${domain}`, ""),
  }));

  const handleDownloadCSV = () => {
    saveCsv(
      [
        formatCsvRow(["Type", "Name", "Value"]),
        ...dnsRecords.map((record) => formatCsvRow([record.type, record.name, record.value])),
      ],
      `${domain}-DNS-Records.csv`
    );
  };

  return (
    <FormSection
      title="DNS Records"
      description={
        <>
          The table below shows the DNS records you will need to add to your domain provider. You may need to
          work with your IT provider or contact your domain provider for further support. If your IT provider
          needs these records you can&nbsp;
          <Button onClick={handleDownloadCSV} theme="link" className="text-xs inline items-center gap-x-1">
            download a copy of your DNS Records here
          </Button>
          .
        </>
      }
    >
      <div className="flex flex-col gap-y-3">
        {dnsRecords.map((dnsRecord, index) => (
          <EmailDnsRecordRow key={index} emailDnsRecord={dnsRecord} />
        ))}
      </div>
    </FormSection>
  );
};
