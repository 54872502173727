import { ToggleIcon } from "@libs/components/UI/ToggleIcon";
import { ReactComponent as FilledBellIcon } from "@libs/assets/icons/notification-filled.svg";
import { ReactComponent as BellIcon } from "@libs/assets/icons/notification.svg";
import { FC } from "react";
import { IconProps } from "@libs/components/UI/Icon";

export interface AlertToggleProps {
  isAlert: boolean;
  inactive?: boolean;
  disabled?: boolean;
  tooltip?: IconProps["tooltip"];
  onToggleAlert: (isAlert: boolean) => void;
}

export const AlertToggle: FC<AlertToggleProps> = ({
  disabled,
  inactive,
  isAlert,
  onToggleAlert,
  tooltip,
}) => {
  return (
    <ToggleIcon
      Checked={FilledBellIcon}
      UnChecked={BellIcon}
      checked={isAlert}
      disabled={disabled}
      inactive={inactive}
      tooltip={tooltip}
      onChange={(e) => onToggleAlert(e.target.checked)}
    />
  );
};
