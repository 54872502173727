import { FC, ReactNode } from "react";

interface Props {
  title: string | ReactNode;
  description: string;
  className?: string;
}

export const ElementSummary: FC<Props> = ({ title, description, className }) => {
  return (
    <div className={className}>
      <h5 className="text-sm">{title}</h5>
      <p className="text-xs text-greyMedium">{description}</p>
    </div>
  );
};
