import { FC, useCallback } from "react";
import {
  MultiInvoicePaymentVO,
  PaymentDeviceVO,
  PaymentProfileVO,
  PracticeBillingSettingVO,
  RefundablePaymentVO,
  SurchargeAppliedAmountVO,
} from "@libs/api/generated-api";
import { centsToDollars, dollarsToCents } from "@libs/utils/currency";
import { isDefined } from "@libs/utils/types";
import Skeleton from "react-loading-skeleton";
import { PaymentMethodOption } from "components/PatientProfile/Billing/PaymentMethods/utils";
import {
  Note,
  PartialOrFullAmount,
  PaymentAmount,
  SelectPaymentDate,
} from "components/PatientProfile/Billing/FormComponents";

import { FormFieldCurrencyInputHandler } from "components/UI/FormFieldCurrencyInput";
import { WalletWithFamilyMember } from "components/PatientProfile/Billing/billingUtils";
import { ConfigurePaymentMethod } from "components/PatientProfile/Billing/PaymentMethods/ConfigurePaymentMethod";
import { isDifferentValue } from "components/PatientProfile/Billing/Payment/utils";
import { UsePaymentFormValidationResult } from "components/PatientProfile/Billing/Payment/useCollectPaymentFormValidation";
import { SurchargeSummary } from "components/PatientProfile/Billing/SurchargeSummary";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import {
  FlyoverPanel,
  PatientPaymentMethodsFlyoverReel,
} from "components/PatientProfile/Billing/PatientPaymentMethodsFlyoverReel";
import { useItemModal } from "hooks/useItemModal";
import {
  PaymentDraft,
  PaymentDraftUpdateFn,
} from "components/PatientProfile/Billing/Payment/useCollectPaymentForm";

export const CollectPaymentForm: FC<{
  patientId: number;
  paymentDraft: PaymentDraft;
  handleUpdatePaymentDraft: PaymentDraftUpdateFn;
  /**
   * A non-negative payment amount. For refunds, have the caller change the sign
   * of the payment amount when handling the `onPaymentAmountChange` event.
   */
  billingSettings: PracticeBillingSettingVO;
  surchargeData?: SurchargeAppliedAmountVO;
  multiInvoicePayment?: MultiInvoicePaymentVO;
  walletsWithFamilyMembers: WalletWithFamilyMember[];
  paymentProfiles: PaymentProfileVO[];
  paymentDevices: PaymentDeviceVO[];
  paymentMethodOptions: PaymentMethodOption[];
  validation: UsePaymentFormValidationResult;
  disabled?: boolean;
  isRefund: boolean;
  maxAmountDue: number;
  isLoadingMultiInvoicePayment: boolean;
  refundablePayments: RefundablePaymentVO[];
}> = ({
  patientId,
  paymentDraft,
  handleUpdatePaymentDraft,
  multiInvoicePayment,
  walletsWithFamilyMembers,
  paymentProfiles,
  paymentDevices,
  billingSettings,
  paymentMethodOptions,
  isLoadingMultiInvoicePayment,
  validation,
  disabled,
  isRefund,
  maxAmountDue,
  refundablePayments,
}) => {
  const flyoverReelInitialPanel = useItemModal<FlyoverPanel>(null);
  const paymentAmountAbsoluteValue = isDefined(paymentDraft.paymentAmount)
    ? Math.abs(paymentDraft.paymentAmount)
    : paymentDraft.paymentAmount;

  const updateDollarAmount: FormFieldCurrencyInputHandler = useCallback(
    (dollars) => {
      if (dollars === paymentDraft.dollarStringValue) {
        return;
      }

      const changes: Partial<PaymentDraft> = {
        dollarStringValue: dollars,
      };
      let shouldUpdateMultipaymentInvoice = false;

      // Emit a change event only if the dollar value is different from the
      // payment amount (e.g. "100" vs "100." vs "100.00").
      if (isDifferentValue(dollars, paymentAmountAbsoluteValue)) {
        const cents = dollarsToCents(Number(dollars ?? 0));

        changes.paymentAmount = isRefund ? -cents : cents;
        shouldUpdateMultipaymentInvoice = true;
      }

      handleUpdatePaymentDraft(changes, { shouldUpdateMultipaymentInvoice });
    },
    [paymentDraft.dollarStringValue, paymentAmountAbsoluteValue, handleUpdatePaymentDraft, isRefund]
  );

  const handleNewPaymentProfile = useCallback(
    (paymentProfile: PaymentProfileVO) => {
      handleUpdatePaymentDraft(
        {
          selectedPaymentProfileUuid: paymentProfile.uuid,
          paymentMethod: "STORED_PROFILE",
        },
        { shouldUpdateMultipaymentInvoice: true }
      );
    },
    [handleUpdatePaymentDraft]
  );
  const paymentAmountValue =
    paymentDraft.dollarStringValue ??
    (paymentAmountAbsoluteValue ? centsToDollars(paymentAmountAbsoluteValue) : undefined);

  return (
    <>
      <div className="flex flex-col gap-6">
        <div className="grid grid-cols-2 gap-6">
          <FormFieldSelect
            layout="labelOut"
            label="Method"
            className="w-full max-w-[256px]"
            isSearchable={false}
            onItemSelected={(newPaymentMethod) => {
              handleUpdatePaymentDraft(
                {
                  paymentMethod: newPaymentMethod,
                },
                { shouldUpdateMultipaymentInvoice: true }
              );
            }}
            required
            value={paymentDraft.paymentMethod}
            options={paymentMethodOptions}
            disabled={disabled}
            error={validation.paymentMethod.$error}
          />

          <SelectPaymentDate
            layout="labelOut"
            onChange={(date) => {
              handleUpdatePaymentDraft({
                paymentDate: date ?? undefined,
              });
            }}
            selected={paymentDraft.paymentDate}
            error={validation.paymentDate.$error}
            disabled={disabled}
          />
        </div>
        <ConfigurePaymentMethod
          walletsWithFamilyMembers={walletsWithFamilyMembers}
          paymentProfiles={paymentProfiles}
          paymentDevices={paymentDevices}
          paymentDraft={paymentDraft}
          refundablePayments={refundablePayments}
          handleUpdatePaymentDraft={handleUpdatePaymentDraft}
          patientId={patientId}
          errors={{
            paymentDevice: validation.selectedPaymentPosUuid.$error,
            paymentProfile: validation.selectedPaymentProfileUuid.$error,
          }}
          onClickAddCard={() => {
            flyoverReelInitialPanel.open({
              id: "AddCard",
              onSavedNewCardHandler: handleNewPaymentProfile,
            });
          }}
          disabled={disabled}
        />

        <div className="grid grid-cols-2 gap-6">
          <PartialOrFullAmount
            disabled={disabled}
            value={paymentDraft.fullOrPartial}
            onChange={(value) => {
              const draft: Partial<PaymentDraft> = { fullOrPartial: value };

              if (value === "FULL") {
                draft.paymentAmount = maxAmountDue;
                draft.dollarStringValue = undefined;
              }

              handleUpdatePaymentDraft(draft);
            }}
          />
          <PaymentAmount
            value={paymentAmountValue}
            onChange={updateDollarAmount}
            disabled={paymentDraft.fullOrPartial === "FULL" || disabled}
            error={validation.paymentAmount.$error}
          />
          <div className="col-span-full">
            <Note
              value={paymentDraft.note}
              onChange={(e) => {
                handleUpdatePaymentDraft({
                  note: e.target.value,
                });
              }}
              error={validation.note.$error}
              disabled={disabled}
            />
          </div>
        </div>
        {isLoadingMultiInvoicePayment ? (
          <Skeleton className="w-full h-36" />
        ) : (
          multiInvoicePayment &&
          validation.$isValid !== false && (
            <SurchargeSummary
              multiInvoicePayment={multiInvoicePayment}
              total={multiInvoicePayment.payment.totalPaymentAmount}
              subtotal={multiInvoicePayment.payment.currencyAmount.amount}
              surchargeAmount={multiInvoicePayment.payment.surchargeAmount}
              showSurchargeDisclosure={
                paymentDraft.paymentMethod === "STORED_POS" && billingSettings.passCreditCardFees
              }
            />
          )
        )}
      </div>
      {flyoverReelInitialPanel.item && (
        <PatientPaymentMethodsFlyoverReel
          initialPanel={flyoverReelInitialPanel.item}
          onClose={flyoverReelInitialPanel.close}
          patientId={patientId}
        />
      )}
    </>
  );
};
