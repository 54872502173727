import { FC, useId } from "react";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ColorPickerMenu } from "@libs/components/UI/ColorPickerMenu";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { useBoolean } from "@libs/hooks/useBoolean";
import { FormFieldProps } from "@libs/components/UI/FormField";
import { FormFieldDescription } from "@libs/components/UI/FormFieldDescription";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { Button } from "@libs/components/UI/Button";
import { ColorPickerSelection } from "@libs/components/UI/ColorPickerSelection";

interface Props
  extends Pick<
    FormFieldProps,
    "label" | "description" | "required" | "className" | "disabled" | "error" | "edit" | "displayErrorMessage"
  > {
  value?: string;
  onChange: (newColor: string) => void;
}

export const FormFieldColorPicker: FC<Props> = ({
  value,
  onChange,
  label,
  required,
  disabled,
  error,
  edit = true,
  displayErrorMessage = true,
  description,
  className,
}) => {
  const menu = useBoolean(false);
  const errorId = useId();
  const id = useId();

  return (
    <div className={className}>
      {label ? (
        <FormFieldLabel
          className="font-sansSemiBold pb-1 text-sm md:pb-2 md:text-xs"
          content={label}
          error={error}
          id={id}
          required={required}
          disabled={disabled}
        />
      ) : null}
      {description ? <FormFieldDescription disabled={disabled}>{description}</FormFieldDescription> : null}
      <ButtonMenu
        isOpen={menu.isOn}
        onRequestOpen={menu.on}
        menuContent={
          <ColorPickerMenu
            color={value}
            onSelect={(newColor) => {
              onChange(newColor);
              menu.off();
            }}
            onCancel={menu.off}
          />
        }
        onRequestClose={menu.off}
      >
        {(props) =>
          value ? (
            <button {...props} disabled={disabled || !edit} type="button">
              <ColorPickerSelection color={value} />
            </button>
          ) : (
            <Button theme="tertiary" size="small" {...props} disabled={disabled || !edit}>
              Select Color
            </Button>
          )
        }
      </ButtonMenu>
      <input
        type="hidden"
        value={value}
        id={id}
        aria-invalid={error ? "true" : undefined}
        aria-errormessage={error ? errorId : undefined}
      />
      {error && edit && displayErrorMessage && (
        <FormFieldError id={errorId} indent={false} className="pt-1">
          {error}
        </FormFieldError>
      )}
    </div>
  );
};
