import { FC, useCallback, useMemo } from "react";
import { Link, NavLink, Route } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ReactComponent as LeftArrowIcon } from "@libs/assets/icons/left-arrow.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { AlertModal } from "@libs/components/UI/AlertModal";
import { BoldSpaced } from "@libs/components/UI/BoldSpaced";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { LeftAsideTemplate } from "components/UI/LeftAsideTemplate";
import { paths } from "utils/routing/paths";
import { NotFound } from "components/NotFoundRoute";
import { EmployeeInfo } from "components/EmployeeProfile/EmployeeInfo";
import { EmployeeTimesheet } from "components/EmployeeProfile/EmployeeTimesheet";
import { EmployeeDocuments } from "components/EmployeeProfile/EmployeeDocuments";
import { EmployeeEmployment } from "components/EmployeeProfile/EmployeeEmployment";
import { GustoEmployeeFlowRoute } from "components/EmployeeProfile/EmploymentDetails/GustoEmployeeFlowRoute";
import { useEmployeeLinks } from "components/Employee/EmployeeLinksContext";
import { getEmployee } from "api/employee/queries";
import { getProfileImageQuery } from "api/user/queries";
import { resendEmployeeInvite } from "api/employee/mutations";
import { handleError } from "utils/handleError";
import { EmployeeProfileHeader } from "components/EmployeeProfile/EmployeeProfileHeader";
import { usePathParams } from "hooks/usePathParams";
import { PinGuard } from "components/UI/PinGuard";
import { useLastPageQueryParamDetails } from "hooks/useLastPageQueryParamDetails";
import { ArchyReferral } from "components/EmployeeProfile/ArchyReferral";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { checkPermission, isMigrationRole } from "components/Roles/roleUtils";

interface Props {
  backUrl: string;
}

export const EmployeeProfile: FC<Props> = ({ backUrl }) => {
  const { practiceId } = useAccount();
  const { id: employeeId } = usePathParams("employee");
  const employeeLinks = useEmployeeLinks();
  const backLabel = useLastPageQueryParamDetails({ backUrl, defaultLabel: "Employees" });

  const resetPasswordAlert = useBoolean(false);

  const [employeeQuery, employeeImageQuery] = useApiQueries([
    getEmployee({ args: { employeeId, practiceId } }),
    getProfileImageQuery({ args: { userId: employeeId, practiceId } }),
  ]);

  const currentUser = useCurrentUser();

  const showAllEmployeesLink = checkPermission(
    currentUser.roleV2,
    "EMPLOYEE_SETTINGS",
    "ACCESS_ALL"
  ).isPermitted;

  const tabs = useMemo(() => {
    return [
      {
        label: "Profile",
        index: true,
        to: employeeLinks.links.personalInfo,
        element: <EmployeeInfo />,
        hasPermission: true,
        end: true,
      },
      {
        label: "Employment & Licensing",
        path: "employment",
        to: employeeLinks.links.employment,
        element: <EmployeeEmployment />,
        hasPermission: true,
      },
      {
        label: "Timesheet",
        path: "timesheet",
        to: employeeLinks.links.timesheet,
        element: <EmployeeTimesheet />,
        hasPermission: !isMigrationRole(currentUser.roleV2),
      },
      {
        label: "Documents",
        path: "documents",
        to: employeeLinks.links.documents,
        element: <EmployeeDocuments />,
        hasPermission: true,
      },
      {
        label: "Archy Referral",
        path: "archy-referral",
        to: paths.archyReferral(
          { id: employeeId },
          {
            // eslint-disable-next-line @typescript-eslint/naming-convention
            archy_user_referral: employeeQuery.data?.id,
            archyfname: employeeQuery.data?.personalDetails.fullName,
          }
        ),
        element: <ArchyReferral />,
        hasPermission: true,
      },
    ];
  }, [
    currentUser.roleV2,
    employeeId,
    employeeLinks.links.documents,
    employeeLinks.links.employment,
    employeeLinks.links.personalInfo,
    employeeLinks.links.timesheet,
    employeeQuery.data?.id,
    employeeQuery.data?.personalDetails.fullName,
  ]);

  const [{ mutate: resendInvite, isLoading: isInviteResending }] = useApiMutations([resendEmployeeInvite]);

  const handleResendInvite = useCallback(() => {
    resendInvite({ employeeId, practiceId }, { onError: handleError, onSuccess: resetPasswordAlert.on });
  }, [employeeId, practiceId, resendInvite, resetPasswordAlert.on]);

  return (
    <>
      <PinGuard protectedQueries={[employeeQuery, employeeImageQuery]}>
        <LeftAsideTemplate
          asideContent={
            <QueryResult
              queries={[employeeQuery]}
              loading={
                <div className="h-full px-6 py-3">
                  <LoadingContent />
                </div>
              }
            >
              <div className="flex flex-col h-full">
                {employeeQuery.data && employeeImageQuery.data && (
                  <>
                    {showAllEmployeesLink ? (
                      <div className="flex items-center h-14 px-6">
                        <Link
                          className={`
                            flex
                            items-center
                            gap-x-2
                            font-sansSemiBold
                            text-sm
                            text-slate-900
                          `}
                          replace={true}
                          to={backUrl}
                        >
                          <LeftArrowIcon className="w-5 h-5" />
                          {backLabel}
                        </Link>
                      </div>
                    ) : null}

                    <EmployeeProfileHeader
                      employee={employeeQuery.data}
                      employeeImage={employeeImageQuery.data}
                      isInviteResending={isInviteResending}
                      onResendInviteClick={handleResendInvite}
                    />
                  </>
                )}

                <div className="flex-1 flex flex-col gap-y-2 min-h-0 p-3 overflow-y-auto">
                  {tabs
                    .filter((tab) => tab.hasPermission)
                    .map((tab) => (
                      <NavLink
                        key={tab.to}
                        className={({ isActive }) =>
                          cx(
                            `block
                             hover:bg-slate-200
                             rounded
                             p-3
                             font-sansSemiBold
                             text-sm`,
                            isActive && "text-primaryTheme"
                          )
                        }
                        end={tab.end}
                        to={tab.to}
                      >
                        <BoldSpaced>{tab.label}</BoldSpaced>
                      </NavLink>
                    ))}
                </div>
              </div>
            </QueryResult>
          }
        >
          <SentryRoutes>
            <Route path="employment/gusto" element={<GustoEmployeeFlowRoute />} />
            {tabs
              .filter((tab) => tab.hasPermission)
              .map((tab) => (
                <Route key={tab.to} index={tab.index} path={tab.path} element={tab.element} />
              ))}
            <Route path="*" element={<NotFound />} />
          </SentryRoutes>
        </LeftAsideTemplate>
      </PinGuard>

      {resetPasswordAlert.isOn ? (
        <AlertModal
          primaryText="Reset Link Sent"
          secondaryText="A reset link has been sent to the employee's email"
          confirmText="OK"
          onConfirm={resetPasswordAlert.off}
          size="3xs"
        />
      ) : null}
    </>
  );
};
