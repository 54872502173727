import React, { useCallback } from "react";
import { formatCurrency } from "@libs/utils/currency";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { usePick } from "@libs/hooks/usePick";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { ProductionByAppointmentRow } from "components/Dashboard/PracticeProduction/ProductionByAppointmentRow";
import { FooterCell } from "components/Dashboard/Tables/FooterCell";
import { CellCurrencyValue } from "components/Dashboard/Tables/CellCurrencyValue";
import { useQueryParams } from "hooks/useQueryParams";
import {
  useProductionByAppointmentQuery,
  useFlattenAppointmentPages,
} from "components/Dashboard/PracticeProduction/hooks/useProductionByAppointmentQuery";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { useFilterTokenProps } from "components/Dashboard/hooks/useFilterTokenProps";
import { DashboardTableFilters } from "components/Dashboard/Tables/DashboardTableFilters";
import { PRODUCTION_BY_APPT_COLUMNS } from "components/Dashboard/PracticeProduction/constants";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import { MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";
import { DashboardTableTabs } from "components/Dashboard/Tables/DashboardTableTabs";
import { useProductionTableTabProps } from "components/Dashboard/PracticeProduction/hooks/useProductionTableTabProps";
import { PracticeProductionQuery } from "utils/routing/dashboard";
import { ProductionAppointmentAndProcedureFilters } from "components/Dashboard/PracticeProduction/ProductionAppointmentAndProcedureFilters";
import { useDownloadProductionByAppointmentCSV } from "components/Dashboard/PracticeProduction/hooks/useDownloadProductionByAppointmentCSV";
import { useProviderRollupFilterOptions } from "components/Dashboard/hooks/useProviderRollupFilterOptions";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";

export const ProductionByAppointmentTableRoute: React.FC = () => {
  const { query, updateQuery } = useQueryParams("dashboardPracticeProduction");
  const dateWindow = useTimeSeriesPageSelections(query);
  const { selectedTimeSegment } = dateWindow;
  const { data: providerFilterOptions, selectedProvider } = useProviderRollupFilterOptions({
    metric: "productionAmount",
    dateWindow,
    ...query,
  });

  const filterParams = usePick(query, ["filters", "patientSearch"]);
  const filterProps = useFilterTokenProps({
    options: filterParams,
    selectedProviderDisplayName: selectedProvider?.provider.fullDisplayName,
  });
  const { appointmentInfiniteQuery, isLoading, totals } = useProductionByAppointmentQuery({
    pageSize: MAX_PAGE_SIZE,
    dateWindow,
  });
  const totalRows = getInfiniteQueryPagingDetails(appointmentInfiniteQuery.data)?.totalElements;

  const rows = useFlattenAppointmentPages(appointmentInfiniteQuery.data);
  const { downloadCSV: downloadAppointmentCSV, isDownloading: isDownloadingCsv } =
    useDownloadProductionByAppointmentCSV({
      selectedTimeSegment,
      appointmentInfiniteQuery,
    });

  const isEmpty = !isLoading && rows?.length === 0;

  const handleRouteStateChange = useCallback(
    (updates: Partial<PracticeProductionQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );
  const handleSortClick = React.useCallback(
    (apptTableSort: ColumnSortOrder[]) => {
      handleRouteStateChange({
        apptTableSort,
      });
    },
    [handleRouteStateChange]
  );

  const { tabs } = useProductionTableTabProps({
    query,
  });

  return (
    <>
      <DashboardTableTabs
        tabs={tabs}
        onUpdateParams={handleRouteStateChange}
        onClickDownload={downloadAppointmentCSV}
        isDownloading={isDownloadingCsv}
        query={query}
        id="practice-production-by-appointment"
      >
        <ProductionAppointmentAndProcedureFilters
          tab="appointments"
          tableExpanded={query["table.fullScreen"]}
          providerFilterOptions={providerFilterOptions}
          onFiltersChanged={(filters) => {
            handleRouteStateChange({ filters });
          }}
          filters={query.filters}
        />
      </DashboardTableTabs>
      <DashboardTableFilters {...filterParams}>
        <QueryFilterPills
          {...filterProps}
          numResults={totalRows}
          onUpdateParams={(updatedQuery) => {
            updateQuery("replaceIn", updatedQuery);
          }}
          onClearAll={() => {
            updateQuery("replaceIn", { filters: [], patientSearch: undefined });
          }}
        />
      </DashboardTableFilters>
      <DashboardInfiniteTable
        infiniteQuery={appointmentInfiniteQuery}
        columnWidths={PRODUCTION_BY_APPT_COLUMNS.map(({ width }) => width)}
        id="productionByAppointmentTable"
        isEmpty={isEmpty}
        headerRow={
          <DashboardHeaderRow
            headers={PRODUCTION_BY_APPT_COLUMNS}
            onSortClick={handleSortClick}
            sortOrders={query.apptTableSort}
          />
        }
        footerRow={
          <>
            <FooterCell className="col-span-3" />
            <FooterCell className="flex justify-center">Totals</FooterCell>
            <FooterCell loading={isLoading} align="center">
              <CellCurrencyValue align="right">
                {totals && formatCurrency(totals.totalGrossScheduledAmount)}
              </CellCurrencyValue>
            </FooterCell>
            <FooterCell loading={isLoading} align="center">
              <CellCurrencyValue align="right">
                {totals && formatCurrency(totals.totalGrossProducedAmount)}
              </CellCurrencyValue>
            </FooterCell>
            <FooterCell loading={isLoading} align="center">
              <CellCurrencyValue align="right">
                {totals && formatCurrency(totals.totalProducedAmount)}
              </CellCurrencyValue>
            </FooterCell>
            <FooterCell />
          </>
        }
      >
        {!isLoading &&
          rows?.map((row, i) => {
            return (
              <ProductionByAppointmentRow
                key={`${row.appointmentId}-${row.provider.id}-${row.patient.id}`}
                row={row}
                last={i === rows.length - 1}
              />
            );
          })}
      </DashboardInfiniteTable>
    </>
  );
};
