import { FC, FormEvent, useMemo, useState, useEffect } from "react";
import { produce } from "immer";
import { AddressVO, PracticeVO, ProfileImageVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { getTimezoneName } from "@libs/utils/date";
import { useValidation } from "@libs/hooks/useValidation";
import { getEmptyAddress } from "@libs/utils/address";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Form } from "@libs/components/UI/Form";
import { AddressInputReadOnly } from "@libs/components/UI/AddressInputReadOnly";
import { AddressInput } from "@libs/components/UI/AddressInput";
import { SettingsValue } from "@libs/components/UI/SettingsComponents";
import { ImageUploadPopover } from "components/UI/ImageUploadPopover";
import { getBusinessInformationSchema } from "components/Settings/PracticeSetup/Sections/BusinessInformation/businessInformationSchema";
import { RoleGuardHide } from "components/Main/RoleGuard";
import { useEnvContext } from "contexts/EnvContext";

type Props = {
  isEditing: boolean;
  onCancel: Func;
  onUploadImage: Func;
  onDeleteImage: Func;
  onSave: (data: PracticeVO) => void;
  practice: PracticeVO;
  practiceImage?: ProfileImageVO;
  isSaving: boolean;
};

const cxStyles = {
  control: (edit: boolean) => cx("col-span-6", !edit && "mb-3"),
};

// In order for useValidation to work, a nested object
// that is validated must be initialized with a value
const resetDraft = (
  practice: PracticeVO
): Omit<PracticeVO, "physicalAddress" | "billingAddress"> & {
  physicalAddress: AddressVO;
  billingAddress: AddressVO;
} => ({
  ...practice,
  billingAddress: practice.billingAddress ?? getEmptyAddress(),
  physicalAddress: practice.physicalAddress,
});

// eslint-disable-next-line complexity
export const BusinessInformationForm: FC<Props> = ({
  isEditing,
  onCancel,
  practice,
  practiceImage,
  onDeleteImage,
  onUploadImage,
  onSave,
  isSaving,
}) => {
  const { REACT_APP_GOOGLE_API_KEY } = useEnvContext();
  const [practiceDraft, setPracticeDraft] = useState(() => resetDraft(practice));

  const taxIdPlaceholder = practice.taxIdLastFour ? `****${practice.taxIdLastFour}` : "";

  const schema = useMemo(() => {
    return getBusinessInformationSchema({
      isBillingAddressSame: practiceDraft.isBillingAddressSame,
      taxIdLastFour: practice.taxIdLastFour,
    });
  }, [practiceDraft.isBillingAddressSame, practice.taxIdLastFour]);

  const { result, validate, reset } = useValidation(practiceDraft, schema);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (!validate().$isValid) {
      return;
    }

    onSave({
      ...practiceDraft,
      billingAddress: practiceDraft.isBillingAddressSame ? undefined : practiceDraft.billingAddress,
    });
  };

  // need to reset the form when the user cancels editing
  useEffect(() => {
    if (!isEditing) {
      setPracticeDraft(resetDraft(practice));
      reset();
    }
  }, [isEditing, practice, reset]);

  return (
    <Form fieldLayout="labelIn" onSubmit={handleSubmit}>
      <div className="grid grid-cols-12 gap-3">
        <div className="grid grid-cols-12 gap-3 col-span-8">
          <div className={cxStyles.control(isEditing)}>
            <FormFieldInput
              label="Business Name"
              autoComplete="false"
              edit={isEditing}
              error={result.name.$error}
              value={practiceDraft.name}
              required
              onChange={(e) =>
                setPracticeDraft((last) =>
                  produce(last, (draft) => {
                    draft.name = e.target.value;
                  })
                )
              }
            />
          </div>
          <div className={cxStyles.control(isEditing)}>
            <FormFieldInput
              label="Doing Business As"
              autoComplete="false"
              edit={isEditing}
              value={practiceDraft.doingBusinessAs}
              onChange={(e) =>
                setPracticeDraft((last) =>
                  produce(last, (draft) => {
                    draft.doingBusinessAs = e.target.value;
                  })
                )
              }
            />
          </div>
          <div className={cxStyles.control(isEditing)}>
            <FormFieldInput
              label="Owner Name"
              autoComplete="false"
              edit={isEditing}
              value={practiceDraft.ownerName}
              disabled
            />
          </div>
          <div className={cxStyles.control(isEditing)}>
            <FormFieldInput
              label="Tax ID"
              autoComplete="false"
              edit={isEditing}
              placeholder={taxIdPlaceholder}
              value={isEditing ? practiceDraft.taxId : taxIdPlaceholder}
              maxLength={9}
              // Since taxId is never returned we can only tell if a user has saved a taxId
              // by checking if taxIdLastFour exists. If it does, the field, taxId, is not required.
              // Otherwise, the user must enter a taxId.
              required={!practice.taxIdLastFour}
              error={result.taxId.$error}
              onPaste={(e) =>
                setPracticeDraft((last) =>
                  produce(last, (draft) => {
                    draft.taxId = e.clipboardData.getData("text").replaceAll(/\D/g, "");
                  })
                )
              }
              onChange={(e) =>
                setPracticeDraft((last) =>
                  produce(last, (draft) => {
                    draft.taxId = e.target.value.replaceAll(/\D/g, "");
                  })
                )
              }
            />
          </div>
          <div className={cxStyles.control(isEditing)}>
            <FormFieldInput
              label="Website"
              autoComplete="false"
              edit={isEditing}
              value={practiceDraft.website}
              onChange={(e) =>
                setPracticeDraft((last) =>
                  produce(last, (draft) => {
                    draft.website = e.target.value;
                  })
                )
              }
            />
          </div>
          <div className={cxStyles.control(isEditing)}>
            <FormFieldInput
              label="Time Zone"
              autoComplete="false"
              edit={isEditing}
              value={practiceDraft.timezoneId ? getTimezoneName(practiceDraft.timezoneId) : ""}
              disabled
            />
          </div>
          {!isEditing && (
            <AddressInputReadOnly
              label="Practice Address"
              className={cxStyles.control(false)}
              address={practice.physicalAddress}
            />
          )}
          {!isEditing && (
            <AddressInputReadOnly
              label="Billing Address"
              className={cxStyles.control(false)}
              address={practice.isBillingAddressSame ? practice.physicalAddress : practice.billingAddress}
            />
          )}
        </div>
        <div className="col-span-4 justify-content-center">
          <div
            className={cx(
              `relative
               font-sansSemiBold
               text-greyLight
               border
               rounded
               border-greyLighter
               flex
               bg-whiteLight
               items-center
               text-center`,
              isEditing ? "text-3xl w-40 h-40" : "text-5xl w-56 h-56"
            )}
          >
            {practiceImage?.url ? (
              <img
                src={practiceImage.url}
                draggable={false}
                className="object-contain w-full h-full select-none"
                alt="practice logo"
              />
            ) : (
              <span>Practice Logo</span>
            )}
            <RoleGuardHide domain="PRACTICE_SETTINGS" action="ACCESS_PRACTICE">
              <ImageUploadPopover
                iconClassName="right-1 bottom-1"
                onDeleteImage={onDeleteImage}
                onUploadImage={onUploadImage}
              />
            </RoleGuardHide>
          </div>
        </div>
      </div>

      {isEditing && (
        <div className="grid grid-cols-12 gap-3 col-span-12">
          <div className="col-span-12">
            <SettingsValue>Practice Address</SettingsValue>
          </div>

          <AddressInput
            apiKey={REACT_APP_GOOGLE_API_KEY}
            address={practiceDraft.physicalAddress}
            validation={result.physicalAddress}
            onChange={(address) => {
              setPracticeDraft((state) => ({
                ...state,
                physicalAddress: address,
              }));
            }}
          />
          <div className="col-span-12">
            <SettingsValue>Billing Address (Also used for claims)</SettingsValue>
          </div>
          <div className="col-span-12 mb-4">
            <Checkbox
              checked={Boolean(practiceDraft.isBillingAddressSame)}
              onChange={(e) => {
                setPracticeDraft((state) => ({
                  ...state,
                  isBillingAddressSame: e.target.checked,
                }));
              }}
            >
              Same as practice address
            </Checkbox>
          </div>
          {practiceDraft.isBillingAddressSame ? null : (
            <AddressInput
              apiKey={REACT_APP_GOOGLE_API_KEY}
              address={practiceDraft.billingAddress}
              validation={result.billingAddress}
              onChange={(address) => {
                setPracticeDraft((state) => ({ ...state, billingAddress: address }));
              }}
            />
          )}
        </div>
      )}
      {isEditing && (
        <div className="flex flex-wrap mb-6">
          <Button
            style={{ marginRight: 8, marginBottom: 8 }}
            theme="secondary"
            size="large"
            className="w-[100px]"
            onClick={onCancel}
          >
            Cancel
          </Button>
          <AsyncButton
            isLoading={isSaving}
            style={{ marginBottom: 8 }}
            theme="primary"
            size="large"
            className="w-[100px]"
            type="submit"
          >
            Save
          </AsyncButton>
        </div>
      )}
    </Form>
  );
};
