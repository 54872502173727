import { createContext, useContext } from "react";
import { noop } from "@libs/utils/noop";

export type AppointmentDuration = {
  editable?: number;
  read: number;
  calculate: Func;
  update: (value: number | undefined) => void;
};

const Context = createContext<AppointmentDuration>({
  read: 0,
  calculate: noop,
  update: noop,
});

Context.displayName = "AppointmentDurationContext";
export const AppointmentDurationContext = Context;
export const useAppointmentDurationContext = () => useContext(AppointmentDurationContext);
