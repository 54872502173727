import { FC } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { getMedicalHistoryAuditQuery } from "api/patients/queries";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { MedicalHistoryQuery } from "utils/routing/patient";
import { MedicalHistoryPanel } from "components/PatientProfile/MedicalHistory/MedicalHistoryPanel";
import { getLatestFormSubmission } from "api/forms/queries";

export const MedicalHistoryRoute: FC = () => {
  const { practiceId } = useAccount();
  const { patientId } = usePathParams("patientTab");
  const { query, updateQuery } = useQueryParams("medicalHistory");

  const [medicalHistoryQuery, dentalHistoryQuery, auditLogQuery] = useApiQueries([
    getLatestFormSubmission({ args: { patientId, practiceId, uuidOrSlug: "MEDICAL_HISTORY" } }),
    getLatestFormSubmission({ args: { patientId, practiceId, uuidOrSlug: "DENTAL_HISTORY" } }),
    getMedicalHistoryAuditQuery({
      args: { patientId, practiceId },
      queryOptions: { enabled: query.auditLog },
    }),
  ]);

  const updateQueryState = (updates: Partial<MedicalHistoryQuery>) => {
    updateQuery("replaceIn", updates);
  };

  return (
    <MedicalHistoryPanel
      patientId={patientId}
      editMedicalHistorySlug={query.editMedicalHistory}
      dentalHistoryQuery={dentalHistoryQuery}
      medicalHistoryQuery={medicalHistoryQuery}
      auditLogQuery={auditLogQuery}
      onQueryStateUpdate={updateQueryState}
      auditLog={query.auditLog}
    />
  );
};
