import { useState, useCallback, useMemo } from "react";
import DatePicker from "react-datepicker";
import { differenceInDays, subDays, addDays, addMonths, lastDayOfMonth, isSameDay, isBefore } from "date-fns";
import { PayPeriodVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { formatShortDayOfMonth, formattedDateToISODate, getLocalDate } from "@libs/utils/date";
import { ReactDatePickerNav, ReactDatePickerNavInput } from "components/UI/ReactDatePickerNav";
import { useMonthNavigation } from "components/ScheduleAppointments/useMonthNavigation";
import { TimeSheetDatePickerMenu } from "components/EmployeeProfile/Timesheet/TimeSheetDatePickerMenu";
import { DateRangeTab } from "utils/routing/employees";
import { UseInfinitePayPeriodsQueryResult } from "components/EmployeeProfile/Timesheet/types";

type Props = {
  dateRange: [Date, Date];
  dateRangeTab: DateRangeTab;
  infinitePayPeriodsQuery: UseInfinitePayPeriodsQueryResult;
  onRangeSelected: (range: [Date, Date], tab: DateRangeTab) => void;
};

export const DEFAULT_RANGE_IN_DAYS = 14;

const MONTHS_DISPLAY = 2;

export const handleFormatValue = (value?: string) => {
  if (value) {
    // react datepicker onFormatValue provides us with "MM/DD/YYY" when only start is selected
    // and "MM/DD/YYY - MM/DD/YYY" when both start and end are selected
    const [startStr, endStr] = value.split(" - ");

    let returnValue = `${formatShortDayOfMonth(getLocalDate(formattedDateToISODate(startStr)))} - `;

    if (endStr) {
      returnValue += formatShortDayOfMonth(getLocalDate(formattedDateToISODate(endStr)));
    }

    return returnValue;
  }

  return "";
};

export const useTimeSheetDatePicker = ({
  dateRange,
  dateRangeTab,
  infinitePayPeriodsQuery,
  onRangeSelected,
}: Props) => {
  const hasPayrolls = (infinitePayPeriodsQuery.data?.pages[0].data.length ?? 0) > 0;
  const datepickerModal = useBoolean(false);

  // Need to use local state initiated by query state
  // because we only want to preserve the selected tab state
  // to the query string if the user actually selected a date range
  // in that tab. However, we still need to show the user the different tab
  // panes when clicking on them.
  const [tab, setTab] = useState(dateRangeTab);

  // Need to use local state initiated by query state
  // because we only want to preserve the date range state
  // once the user has chose a beginning and end date.
  // Local state allows us to track them to first select a start
  // date without an end date.
  const [range, setRange] = useState<[Date, Date | null]>(dateRange);

  const [start, end] = range;

  const lastViewableDay = useMemo(() => {
    if (!end) {
      return addDays(start, DEFAULT_RANGE_IN_DAYS);
    }

    return null;
  }, [start, end]);

  const { increaseMonths, decreaseMonths, handleOpenDatepicker, openToDate, openKey } = useMonthNavigation(
    start,
    MONTHS_DISPLAY
  );

  const lastShownDay = useMemo(() => lastDayOfMonth(addMonths(openToDate, MONTHS_DISPLAY - 1)), [openToDate]);

  const disableIncreaseMonths = Boolean(
    lastViewableDay && (isSameDay(lastShownDay, lastViewableDay) || isBefore(lastViewableDay, lastShownDay))
  );

  const handleDateChange = useCallback(
    (value: [Date | null, Date | null]) => {
      if (!value[0]) {
        return;
      }

      setRange([value[0], value[1]]);

      if (value[1]) {
        datepickerModal.off();
        onRangeSelected([value[0], value[1]], "calendar");
      }
    },
    [datepickerModal, onRangeSelected]
  );

  const handleIncreaseRange = useCallback(() => {
    if (!end) {
      return;
    }

    const intervalInDays = differenceInDays(end, start);
    const newRange: [Date, Date] = [addDays(start, intervalInDays + 1), addDays(end, intervalInDays + 1)];

    setRange(newRange);
    onRangeSelected(newRange, tab);
  }, [start, end, onRangeSelected, tab]);

  const handleDecreaseRange = useCallback(() => {
    if (!end) {
      return;
    }

    const intervalInDays = differenceInDays(end, start);
    const newRange: [Date, Date] = [subDays(start, intervalInDays + 1), subDays(end, intervalInDays + 1)];

    setRange(newRange);

    onRangeSelected(newRange, tab);
  }, [start, end, onRangeSelected, tab]);

  const handleSelectPayroll = (payroll: PayPeriodVO) => {
    const newRange: [Date, Date] = [getLocalDate(payroll.startDate), getLocalDate(payroll.endDate)];

    datepickerModal.off();
    setRange(newRange);
    onRangeSelected(newRange, "payPeriod");
  };

  const handleInputClick = () => {
    handleOpenDatepicker(start);

    // always set the tab to the last tab where a date was selected
    // e.g. a user navigates to pay period tab but never selects one.
    // When opening the datepicker again they should be brought to the
    // calendar tab
    setTab(dateRangeTab);
    datepickerModal.on();
  };

  return {
    handleInputClick,
    handleSelectPayroll,
    handleDecreaseRange,
    handleIncreaseRange,
    handleDateChange,
    handleSelectTab: setTab,
    handleClickOutside: datepickerModal.off,
    decreaseMonths,
    increaseMonths,
    disableIncreaseMonths,
    range,
    tab,
    hasPayrolls,
    lastViewableDay,
    isOpen: datepickerModal.isOn,
    openToDate,
    openKey,
  };
};

export const TimeSheetDatePicker: React.FC<Props> = (props) => {
  const {
    handleInputClick,
    handleSelectPayroll,
    handleDecreaseRange,
    handleIncreaseRange,
    handleDateChange,
    handleSelectTab,
    handleClickOutside,
    decreaseMonths,
    increaseMonths,
    disableIncreaseMonths,
    range,
    tab,
    hasPayrolls,
    lastViewableDay,
    isOpen,
    openToDate,
    openKey,
  } = useTimeSheetDatePicker(props);

  return (
    <ReactDatePickerNav
      onNextClick={range[1] ? handleIncreaseRange : undefined}
      onPrevClick={range[1] ? handleDecreaseRange : undefined}
    >
      <DatePicker
        key={openKey}
        selected={range[0]}
        startDate={range[0]}
        endDate={range[1]}
        openToDate={openToDate}
        selectsRange
        maxDate={lastViewableDay}
        onChange={handleDateChange}
        monthsShown={MONTHS_DISPLAY}
        open={isOpen}
        onInputClick={handleInputClick}
        onClickOutside={handleClickOutside}
        customInput={<ReactDatePickerNavInput className="w-60" onFormatValue={handleFormatValue} />}
        calendarStartDay={0}
        calendarClassName={`
          react-datepicker-archy-calendar
          react-datepicker-archy-dropdown-calendar
          react-datepicker-archy-range
          react-datepicker-rangepicker-v1
        `}
        wrapperClassName="react-datepicker-archy-datepicker-wrapper"
        popperClassName={cx(
          `react-datepicker-archy-popper
           react-datepicker-archy-calendar
           react-datepicker-archy-timesheet-calendar-popper`,
          hasPayrolls && "react-datepicker-archy-timesheet-tabs",
          tab === "payPeriod" && "react-datepicker-archy-timesheet-payroll-popper"
        )}
      >
        <TimeSheetDatePickerMenu
          hasPayrolls={hasPayrolls}
          isIncreaseMonthsDisabled={disableIncreaseMonths}
          infinitePayPeriodsQuery={props.infinitePayPeriodsQuery}
          selectedTab={tab}
          selectedRange={range}
          onPayrollSelected={handleSelectPayroll}
          onSelectTab={handleSelectTab}
          onDecreaseMonthsClick={decreaseMonths}
          onIncreaseMonthsClick={increaseMonths}
        />
      </DatePicker>
    </ReactDatePickerNav>
  );
};
