import { AppointmentCategoryVO, NameVO } from "@libs/api/generated-api";
import { mapSelectionsToOptions } from "@libs/utils/mapSelectOptions";
import { identity } from "@libs/utils/functional";
import { ToggleButtonList } from "components/UI/ToggleButtonList";
import { TREATMENT_PLAN_CATEGORY_ID } from "components/ScheduleAppointments/useAppointmentCategoriesAndProcedures";

interface Props {
  appointmentCategories: AppointmentCategoryVO[];
  savedCategory: NameVO | undefined;
  onSelectCategory: (id: number) => void;
  selectedCategoryId: number;
  disabled?: boolean;
}

export const AppointmentCategoryOptions: React.FC<Props> = ({
  appointmentCategories,
  savedCategory,
  onSelectCategory,
  selectedCategoryId,
  disabled,
}) => {
  const options = mapSelectionsToOptions(
    [
      ...appointmentCategories.map((cat) => ({
        value: cat.id,
        label: cat.name,
      })),
      { value: TREATMENT_PLAN_CATEGORY_ID, label: "Treatment Plan" },
    ],
    savedCategory ? { value: savedCategory.id, label: savedCategory.fullDisplayName } : undefined,
    identity
  );

  return (
    <ToggleButtonList
      disabled={disabled}
      type="radio"
      shape="pill"
      selectedValue={selectedCategoryId}
      options={options}
      onChange={(_e, item) => onSelectCategory(item.value)}
    />
  );
};
