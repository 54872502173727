import React, { useMemo } from "react";
import { FeeScheduleListVO, FeeScheduleVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatCurrency } from "@libs/utils/currency";
import { isNullish } from "@libs/utils/types";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ApiQueryResult, UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { flattenPages } from "@libs/utils/queries";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as DropDownIcon } from "@libs/assets/icons/down-chevron.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { RadioList } from "@libs/components/UI/RadioList";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { FormFieldCentsInput } from "components/UI/FormFieldCentsInput";

const cxTableStyles = {
  cell: (align?: "left" | "right") =>
    cx(
      `flex
       items-center
       min-h-10
       px-4
       text-xs
       border-b
       border-b-greyLighter`,
      align === "right" && "justify-end"
    ),
  header: (align?: "left" | "right") =>
    cx(
      `flex
       items-center
       min-h-10
       py-2
       px-4
       text-xs
       font-sansSemiBold
       border-b
       border-b-greyLighter`,
      align === "right" && "justify-end"
    ),
  subheader: `
    py-2
    px-4
    text-xs
    font-sansSemiBold
    border-b
    bg-greyLightest
    col-span-full
  `,
};

export const SubcategoryHeader: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return <div className={cx(cxTableStyles.subheader)}>{children}</div>;
};
export const Cell: React.FC<{
  children: React.ReactNode;
  className?: string;
  textAlign?: "left" | "right";
}> = ({ children, className, textAlign = "left" }) => {
  return <div className={cx(cxTableStyles.cell(textAlign), className)}>{children}</div>;
};

export const CurrencyCell: React.FC<{
  edit: boolean;
  onChange?: (cents?: number) => void;
  value?: number;
}> = ({ edit, onChange, value }) => {
  return (
    <Cell textAlign="right">
      {edit ? (
        <FormFieldCentsInput
          className="w-full"
          edit={edit}
          min={0}
          inputClassName="text-right"
          layout="labelLeft"
          onValueChange={(cents) => {
            if (onChange) {
              onChange(cents);
            }
          }}
          value={value}
        />
      ) : isNullish(value) ? (
        EMPTY_CELL
      ) : (
        formatCurrency(value)
      )}
    </Cell>
  );
};

export const Headers: React.FC<{
  comparedFeeScheduleQuery: ApiQueryResult<FeeScheduleVO>;
  feeScheduleName?: string;
  feeSchedulesQuery: UseInfiniteApiListQueryResult<FeeScheduleListVO>;
  isPracticeUcr: boolean;
  onSelectCompareFeeSchedule: (selected: number) => void;
  selectedCompareFeeScheduleId: number | undefined;
}> = ({
  comparedFeeScheduleQuery,
  feeScheduleName,
  feeSchedulesQuery,
  isPracticeUcr,
  onSelectCompareFeeSchedule,
  selectedCompareFeeScheduleId,
}) => {
  const menu = useBoolean(false);
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: feeSchedulesQuery });
  const feeScheduleOptions = useMemo(() => {
    const feeSchedules = flattenPages(feeSchedulesQuery.data);

    const options =
      feeSchedules?.map((item) => ({
        label: item.name,
        value: item.id,
      })) ?? [];

    return [{ label: "None", value: 0 }, ...options];
  }, [feeSchedulesQuery.data]);

  return (
    <>
      <div className={cxTableStyles.header()}>CDT Code</div>
      <div className={cxTableStyles.header()}>Procedure Name</div>
      <div className={cxTableStyles.header()}>Simple Name</div>
      {!isPracticeUcr && <div className={cxTableStyles.header("right")}>UCR Fees</div>}
      <div className={cx("truncate", cxTableStyles.header("right"))}>{feeScheduleName || "New Fees"}</div>
      <ButtonMenu
        menuContent={
          <div ref={rootRef}>
            <ScrollableInfiniteQueryResult infiniteQuery={feeSchedulesQuery} scrollRef={scrollRef}>
              <RadioList
                className="max-h-56 p-1 overflow-y-auto"
                layout="vert"
                onChange={(_e, value) => {
                  onSelectCompareFeeSchedule(value.value);
                  menu.off();
                }}
                options={feeScheduleOptions}
                optionClassName="px-3 first:pt-2 last:pb-2"
                selectedValue={selectedCompareFeeScheduleId ?? 0}
              />
            </ScrollableInfiniteQueryResult>
          </div>
        }
        isOpen={menu.isOn}
        onRequestClose={menu.off}
        onRequestOpen={menu.on}
        placement="bottom-end"
      >
        {(props) => {
          return (
            <div className={cx("flex items-center gap-x-2", cxTableStyles.header("right"))}>
              {comparedFeeScheduleQuery.data?.name ?? "Compare"}
              <ButtonIcon {...props} SvgIcon={DropDownIcon} onClick={menu.on} />
            </div>
          );
        }}
      </ButtonMenu>
    </>
  );
};
