import { FC } from "react";
import { EditEmploymentDetails } from "components/EmployeeProfile/EmploymentDetails/EditEmploymentDetails";
import { ViewEmploymentDetails } from "components/EmployeeProfile/EmploymentDetails/ViewEmploymentDetails";
import { EmploymentDetailsProps } from "components/EmployeeProfile/types";

interface Props extends EmploymentDetailsProps {
  isEditing: boolean;
  canEditAccess: boolean;
  onDoneEditing: Func;
  onBeginEditing: Func;
}

export const ToggleEmploymentDetails: FC<Props> = ({
  isEditing,
  canEditAccess,
  onBeginEditing,
  onDoneEditing,
  ...props
}) =>
  isEditing ? (
    <EditEmploymentDetails canEditAccess={canEditAccess} onDoneEditing={onDoneEditing} {...props} />
  ) : (
    <ViewEmploymentDetails onBeginEditing={onBeginEditing} {...props} />
  );
