/* eslint-disable complexity */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Options } from "react-select";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { pluralize } from "@libs/utils/pluralize";
import { YEAR_IN_MONTHS } from "@libs/utils/date";
import { isDefined } from "@libs/utils/types";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Cell as BaseCell, EMPTY_CELL, HeaderCell, Row } from "@libs/components/UI/GridTableComponents";
import { ConfirmationIcon } from "components/UI/ConfirmationDisclosure";
import {
  CdtCodeSearch,
  Props as CdtCodeSearchProps,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/CdtCodeSearch";
import { FormFieldCentsInput } from "components/UI/FormFieldCentsInput";
import { cdtCodeToNumber, cdtCodeToString } from "utils/cdtCode";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";
import { BenefitLimitationFormItem } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/useLimitationFields";

export type TableHeaderProps = {
  headers: { text: string; align?: "left" | "center" | "right"; spansTwoColumns?: boolean }[];
};

export const TableHeaders: React.FC<TableHeaderProps> = ({ headers }) => {
  return (
    <Row className="text-xs font-sansSemiBold">
      {headers.map((header) => {
        return (
          <HeaderCell
            size="short"
            key={header.text}
            className={cx(
              "flex items-center",
              header.align === "center" && "justify-center",
              header.align === "right" && "justify-end",
              header.spansTwoColumns && "col-span-2"
            )}
          >
            {header.text}
          </HeaderCell>
        );
      })}
    </Row>
  );
};

const cxAlign = {
  left: "justify-start",
  center: "justify-center",
  right: "justify-end",
};

export type CellAlign = keyof typeof cxAlign;

const cxSize = {
  short: "min-h-4",
  tall: "min-h-10",
};

export type CellProps = {
  children?: React.ReactNode;
  align?: CellAlign;
  loading?: boolean;
  className?: string;
  padX?: boolean;
  size?: keyof typeof cxSize;
};

export const Cell: React.FC<CellProps> = ({
  children,
  align = "left",
  loading = false,
  className,
  padX = true,
  size = "tall",
}) => {
  return (
    <BaseCell
      className={cx(
        "flex flex-row items-center",
        cxAlign[align],
        cxSize[size],
        padX ? "px-2 first:pl-5 last:pr-5" : "pr-5",
        className
      )}
    >
      {loading ? <Skeleton containerClassName="flex-1" className="h-5" /> : children}
    </BaseCell>
  );
};

type CurrencyInputProps = {
  edit?: boolean;
  value?: number;
  placeholder?: string;
  onChange: (cents?: number) => void;
  onSubmit?: () => Promise<unknown>;
  align?: CellProps["align"];
  error?: string;
};

export const EditCurrencyInCentsCell: React.FC<CurrencyInputProps & Omit<CellProps, "children">> = ({
  onChange,
  edit,
  placeholder,
  loading,
  value,
  className,
  onSubmit,
  error,
  align = "left",
}) => {
  const cellProps: Partial<CellProps> = { loading, className, padX: !edit };
  const saving = useBoolean(false);
  const originalValue = React.useRef(value);

  const handleSubmit = React.useCallback(async () => {
    if (onSubmit && originalValue.current !== value && saving.isOff) {
      saving.on();
      await onSubmit();
      originalValue.current = value;
      saving.off();
    }
  }, [onSubmit, saving, originalValue, value]);

  return (
    <Cell {...cellProps} className={cx(className)} align={align}>
      {saving.isOn && <Skeleton containerClassName="flex-1" className="h-5" />}
      <FormFieldCentsInput
        placeholder={placeholder}
        min={0}
        inputClassName={cx(
          "px-2",
          align === "right" && "text-right",
          error && "outline outline-1 outline-red"
        )}
        layout="tableValue"
        className={cx(saving.isOn && "hidden")}
        onValueChange={onChange}
        onKeyUp={(e) => {
          if (e.code === "Enter") {
            handleSubmit();
          }
        }}
        onBlur={handleSubmit}
        edit={edit}
        value={value}
      />
    </Cell>
  );
};

type ProcedureSelectProps = {
  className?: string;
  index: number;
  isEditing: boolean;
  limitation?: BenefitLimitationFormItem;
  onChange: (index: number, limitation: BenefitLimitationFormItem) => void;
};
// eslint-disable-next-line @typescript-eslint/no-magic-numbers
type Option = { label: string; value: 1 | 12 };

const MONTH_YEARS_OPTIONS: Options<Option> = [
  { label: "months", value: 1 },
  { label: "years", value: YEAR_IN_MONTHS },
];

export const ProcedureSelectCell: React.FC<ProcedureSelectProps> = ({
  className,
  index,
  isEditing,
  limitation,
  onChange,
}) => {
  return (
    <Cell className={cx("flex items-center w-full", className)}>
      <div className="flex-1">
        {isEditing && !limitation?.frequencyLimitationCategory?.id ? (
          <div className="flex space-x-2">
            <CdtCodeSearch
              autoFocus={!limitation?.startCdtCodeRange}
              edit={isEditing}
              isClearable={false}
              onChange={(value, procedure) => {
                onChange(index, {
                  ...limitation,
                  startCdtCodeRange: cdtCodeToNumber(value),
                  endCdtCodeRange: cdtCodeToNumber(value),
                  serviceName: procedure?.simpleName ?? limitation?.serviceName,
                });
              }}
              value={cdtCodeToString(limitation?.startCdtCodeRange) ?? ""}
            />
            <CdtCodeSearch
              edit={isEditing}
              isClearable={false}
              onChange={(value, procedure) => {
                onChange(index, {
                  ...limitation,
                  endCdtCodeRange: cdtCodeToNumber(value),
                  serviceName:
                    procedure?.simpleName && value === cdtCodeToString(limitation?.startCdtCodeRange)
                      ? procedure.simpleName
                      : limitation?.serviceName,
                });
              }}
              value={cdtCodeToString(limitation?.endCdtCodeRange) ?? ""}
            />
          </div>
        ) : isDefined(limitation?.startCdtCodeRange) && isDefined(limitation.endCdtCodeRange) ? (
          <div>
            {limitation.startCdtCodeRange === limitation.endCdtCodeRange
              ? cdtCodeToString(limitation.startCdtCodeRange)
              : `${cdtCodeToString(limitation.startCdtCodeRange) ?? ""} to ${
                  cdtCodeToString(limitation.endCdtCodeRange) ?? ""
                }`}
          </div>
        ) : limitation?.frequencyLimitationCategory?.id ? (
          <div>
            {limitation.frequencyLimitationCategory.dentalProcedures.map((proc) => proc.cdtCode).join(", ")}
          </div>
        ) : null}
      </div>
    </Cell>
  );
};

type WaitingPeriodProps = {
  className?: string;
  isEditing: boolean;
  onChange: (numOfMonths: number | undefined) => void;
  waitingPeriodInMonths: number | undefined;
  error?: string;
};

// Defined on the BE
const MAX_MONTHS = 127;
const MAX_YEARS = Math.floor(MAX_MONTHS / YEAR_IN_MONTHS);

export const WaitingPeriodCell: React.FC<WaitingPeriodProps> = ({
  className,
  isEditing,
  error,
  onChange,
  waitingPeriodInMonths,
}) => {
  const useYears = useBoolean(
    isDefined(waitingPeriodInMonths) && waitingPeriodInMonths % YEAR_IN_MONTHS === 0
  );

  const [num, setNum] = useState<number | undefined>(
    useYears.isOn ? (waitingPeriodInMonths ?? 0) / YEAR_IN_MONTHS : waitingPeriodInMonths
  );
  const [unit, setUnit] = useState<Option["value"]>(useYears.isOn ? YEAR_IN_MONTHS : 1);

  // Handle canceling changes
  useEffect(() => {
    if (useYears.isOn) {
      const currNum = (waitingPeriodInMonths ?? 0) / YEAR_IN_MONTHS;

      if (currNum !== num) {
        setNum(currNum);
      }
    } else if (waitingPeriodInMonths !== num) {
      setNum(waitingPeriodInMonths);
    }
  }, [num, useYears.isOn, waitingPeriodInMonths]);

  useEffect(() => {
    if (useYears.isOn && unit === 1) {
      setUnit(YEAR_IN_MONTHS);
    } else if (useYears.isOff && unit === YEAR_IN_MONTHS) {
      setUnit(1);
    }
  }, [unit, useYears.isOff, useYears.isOn]);

  const formatOptionLabelPlural = useCallback(
    (option: Option) => {
      const period = num ?? 0;

      return pluralize(period, option.value === 1 ? "month" : "year", option.label);
    },
    [num]
  );

  const readOnlyLabel = useMemo(() => {
    const selectedOption = MONTH_YEARS_OPTIONS.find((opt) => opt.value === unit);

    return isDefined(num) && selectedOption
      ? `${num} ${formatOptionLabelPlural(selectedOption)}`
      : EMPTY_CELL;
  }, [formatOptionLabelPlural, num, unit]);

  const handleNumChange = useCallback(
    (val: number | undefined) => {
      setNum(val);

      if (isDefined(val)) {
        onChange(useYears.isOn ? val * YEAR_IN_MONTHS : val);
      } else {
        onChange(undefined);
      }
    },
    [onChange, useYears.isOn]
  );

  const handleUnitChange = useCallback(
    (option: Option | null) => {
      if (!option) {
        return;
      }

      setUnit(option.value);

      if (option.value === 1) {
        useYears.off();
      } else {
        useYears.on();
      }

      onChange(option.value * (num ?? 0));
    },
    [num, onChange, useYears]
  );

  return (
    <Cell className={cx("space-x-1", className)}>
      {isEditing ? (
        <>
          <FloatingTooltip content={error} theme="MEDIUM">
            <FormFieldNumberInput
              className="w-16"
              edit={isEditing}
              inputClassName="p-2 rounded w-full"
              max={useYears.isOn ? MAX_YEARS : MAX_MONTHS}
              min={0}
              clamp
              layout="tableValue"
              onValueChange={(val) => {
                handleNumChange(val);
              }}
              value={num}
              displayErrorMessage={false}
              error={error}
            />
          </FloatingTooltip>
          <FormFieldSelect
            className="w-full"
            display="label"
            edit={isEditing}
            inputClassName="px-2 rounded w-full"
            isClearable={false}
            layout="tableValue"
            onChange={handleUnitChange}
            openMenuOnFocus
            options={MONTH_YEARS_OPTIONS}
            formatOptionLabel={formatOptionLabelPlural}
            value={unit}
          />
        </>
      ) : (
        <FormFieldInput
          className="w-full items-center"
          edit={false}
          inputClassName="p-2 rounded w-full"
          layout="tableValue"
          value={readOnlyLabel}
        />
      )}
    </Cell>
  );
};

export const CdtCodeSearchCell: React.FC<CdtCodeSearchProps & Omit<CellProps, "children">> = ({
  loading,
  className,
  edit,
  value,
  onChange,
  isClearable,
  align = "left",
  autoFocus,
  startRange,
  endRange,
  error,
}) => {
  const cellProps = { loading, className };

  return (
    <Cell {...cellProps}>
      <CdtCodeSearch
        align={align}
        isClearable={isClearable}
        error={error}
        autoFocus={autoFocus}
        edit={edit}
        className="w-full"
        startRange={startRange}
        endRange={endRange}
        value={value}
        onChange={onChange}
      />
    </Cell>
  );
};

type NumberInputCellProps = {
  className?: string;
  edit?: boolean;
  value?: number;
  onValueChange: (val: number) => void;
  min?: number;
  max?: number;
  error?: string;
  placeholder?: string;
};
export const NumberInputCell: React.FC<NumberInputCellProps & Omit<CellProps, "children">> = ({
  value,
  onValueChange,
  edit,
  align,
  loading,
  className,
  placeholder,
  min = undefined,
  max = undefined,
  error,
}) => {
  const cellProps = { align, loading, className };

  return (
    <Cell {...cellProps}>
      <FloatingTooltip content={error} theme="MEDIUM">
        <FormFieldNumberInput
          placeholder={placeholder}
          inputClassName="p-2 rounded w-full"
          layout="tableValue"
          min={min}
          max={max}
          clamp
          displayErrorMessage={false}
          error={error}
          onValueChange={(_, { numberValue }) => onValueChange(numberValue)}
          edit={edit}
          value={value}
        />
      </FloatingTooltip>
    </Cell>
  );
};

interface AgeCellProps {
  ageMin: number | undefined;
  ageMax: number | undefined;
  inputClassName: string;
  onChangeMin: (val: number) => void;
  onChangeMax: (val: number) => void;
}

export const AgeInputCell: React.FC<
  Omit<NumberInputCellProps, "onValueChange" | "value"> & AgeCellProps & Omit<CellProps, "children">
> = ({
  ageMin,
  ageMax,
  className,
  edit,
  error,
  inputClassName,
  loading,
  min = undefined,
  onChangeMin,
  onChangeMax,
}) => {
  const cellProps = { loading, className };

  return (
    <Cell {...cellProps}>
      {edit ? (
        <>
          <FormFieldNumberInput
            className={inputClassName}
            edit={edit}
            error={error}
            inputClassName="p-2 rounded w-full min-w-8"
            min={min}
            clamp
            layout="tableValue"
            onValueChange={(_, { numberValue }) => onChangeMin(numberValue)}
            value={ageMin}
          />
          -
          <FormFieldNumberInput
            className={inputClassName}
            edit={edit}
            error={error}
            inputClassName="p-2 rounded w-full min-w-8"
            layout="tableValue"
            min={min}
            clamp
            onValueChange={(_, { numberValue }) => onChangeMax(numberValue)}
            value={ageMax}
          />
        </>
      ) : (
        `${
          ageMin && ageMax
            ? `${ageMin} - ${ageMax}`
            : ageMin
              ? `Min: ${ageMin}`
              : ageMax
                ? `Max: ${ageMax}`
                : ""
        }`
      )}
    </Cell>
  );
};

export const InputCell: React.FC<{
  className?: string;
  value?: string;
  onChange: (val: string) => void;
  edit: boolean;
  min?: number;
  error?: string;
}> = ({ className, value, onChange, edit, error }) => {
  return (
    <Cell className={className}>
      <FormFieldInput
        className="w-full items-center"
        edit={edit}
        error={error}
        inputClassName="p-2 rounded w-full"
        layout="tableValue"
        onChange={(e) => onChange(e.target.value)}
        type="text"
        value={value ?? ""}
      />
    </Cell>
  );
};

export const CheckboxCell: React.FC<
  {
    checked: boolean;
    edit?: boolean;
    onChange: (checked: boolean) => void;
  } & Omit<CellProps, "children">
> = (props) => {
  const { checked, className, edit, loading, onChange } = props;
  const checkboxState = useBoolean(checked);
  const cellProps = { loading, className };

  // Handle canceling changes
  useEffect(() => {
    checked ? checkboxState.on() : checkboxState.off();
  }, [checkboxState, checked]);

  return (
    <Cell {...cellProps}>
      {edit ? (
        <Checkbox
          checked={checkboxState.isOn}
          onChange={(e) => {
            onChange(e.target.checked);
            checkboxState.toggle();
          }}
        />
      ) : (
        <ConfirmationIcon denied={checkboxState.isOff} displayDenied={false} />
      )}
    </Cell>
  );
};

export const FullWidthRow: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Row>
      <Cell className="col-span-full">{children}</Cell>
    </Row>
  );
};
