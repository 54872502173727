import { FC, FormEvent, useEffect, useMemo, useRef } from "react";
import { EmailConfigVO } from "@libs/api/generated-api";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ReactComponent as CircleIcon } from "@libs/assets/icons/circle.svg";
import { ReactComponent as SuccessIcon } from "@libs/assets/icons/check-circle-1.svg";
import { ReactComponent as DisabledIcon } from "@libs/assets/icons/cancel-circle.svg";
import { DAY_IN_HOURS, HOUR_IN_MINUTES, MINUTE_IN_SECONDS, SECOND_IN_MS } from "@libs/utils/date";
import { fromUnixTime } from "date-fns";
import { Icon, IconProps } from "@libs/components/UI/Icon";
import { cx } from "@libs/utils/cx";
import { Form } from "@libs/components/UI/Form";
import { FormSection } from "components/Settings/Communications/FormSection";

interface Props {
  emailConfig: EmailConfigVO;
  onVerifyDomain: (checkingVerification: boolean) => void;
  isVerifyingDomain: boolean;
}

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
const WEEK = 7 * DAY_IN_HOURS * HOUR_IN_MINUTES * MINUTE_IN_SECONDS * SECOND_IN_MS;

const getStatus = (emailConfig: EmailConfigVO) => {
  if (emailConfig.domainVerified) {
    return "Verified";
  }

  if (emailConfig.verifyAttemptAt) {
    const lastAttempt = fromUnixTime(emailConfig.verifyAttemptAt);
    const now = new Date();

    if (now.getTime() - lastAttempt.getTime() > WEEK) {
      return "Failed";
    }

    return "Verifying";
  }

  return "Unverified";
};

const statusContent: Record<
  ReturnType<typeof getStatus>,
  {
    statusClasses: string;
    buttonText?: string;
    icon: IconComponent;
    description: string;
    theme: IconProps["theme"];
  }
> = {
  Verifying: {
    statusClasses: "bg-blueLight text-blueDark border-blueLight",
    buttonText: "Check Verification",
    icon: CircleIcon,
    theme: "primary",
    description:
      "If it has been more than 48 hours please visit your domain provider's help site for tips on how to manage your DNS records in their service.",
  },

  Verified: {
    statusClasses: "bg-greenLight text-greenDark border-greenLight",
    icon: SuccessIcon,
    theme: "success",
    description: "Your domain is now verified and you can send email campaigns from Archy.",
  },

  Failed: {
    statusClasses: "bg-redLight text-redDark border-redLight",
    buttonText: "Reverify",
    icon: DisabledIcon,
    theme: "error",
    description:
      "We were unable to verify your domain. Please check the records you added to your domain provider and try again.",
  },

  Unverified: {
    statusClasses: "bg-slate-100 text-slate-900 border-slate-100",
    buttonText: "Verify",
    icon: DisabledIcon,
    theme: "slate900",
    description:
      "Once you have added the DNS records below to your domain provider, click the verify button.",
  },
};

export const VerifyDomain: FC<Props> = ({ emailConfig, onVerifyDomain, isVerifyingDomain }) => {
  const hasVerifiedDomainRef = useRef(false);

  const status = useMemo(() => getStatus(emailConfig), [emailConfig]);

  const handleVerifyDomain = (e: FormEvent<HTMLFormElement>) => {
    hasVerifiedDomainRef.current = true;
    e.preventDefault();
    onVerifyDomain(false);
  };

  const { description, icon, theme, buttonText, statusClasses } = useMemo(() => {
    return statusContent[status];
  }, [status]);

  // check once on page load once a user is already verifying
  useEffect(() => {
    if (!hasVerifiedDomainRef.current && status === "Verifying") {
      hasVerifiedDomainRef.current = true;
      onVerifyDomain(true);
    }
  }, [status, onVerifyDomain]);

  return (
    <Form className="block" onSubmit={handleVerifyDomain}>
      <FormSection title="Verification" description={description}>
        <div className="flex items-center gap-x-3">
          <div
            data-testid="domainStatus"
            className={cx("rounded p-2 gap-x-1 text-xs border flex items-center", statusClasses)}
          >
            <Icon SvgIcon={icon} size="sm" theme={theme} />
            {status}
          </div>

          {status === "Verified" ? null : (
            <AsyncButton className="min-w-button" size="medium" isLoading={isVerifyingDomain} type="submit">
              {buttonText}
            </AsyncButton>
          )}
        </div>
      </FormSection>
    </Form>
  );
};
