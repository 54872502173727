import { Link } from "react-router-dom";
import { PatientInsuranceResponse, PatientSummaryVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { usePatientRelations } from "@libs/hooks/useRelations";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as VerifiedIcon } from "@libs/assets/icons/verified-badge.svg";
import { ReactComponent as ExternalLinkIcon } from "@libs/assets/icons/external-link.svg";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { SectionHeader, SectionRow } from "components/PatientProfile/Insurance/InsurancePlans/TableItems";
import { paths } from "utils/routing/paths";
import { SubscriberOrSsnValue } from "components/PatientProfile/Insurance/InsurancePlans/SubscriberIdOrSsnValue";
import { readableMonth } from "components/PatientProfile/Insurance/utils";
import {
  isAutoVerified,
  isVerified,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/SubscriberTab/utils";

interface Props {
  insurances: PatientInsuranceResponse[];
  patient: PatientSummaryVO;
}

export const InsuranceDetailsRows: React.FC<Props> = ({ insurances, patient }) => {
  const patientRelations = usePatientRelations();

  return (
    <>
      <SectionHeader title="Patient Insurance Details" />
      <SectionRow
        insurances={insurances}
        renderValue={({ patientInsurance }) => {
          const { eligibilityLastVerifiedAt, eligibilityLastVerifiedBy, eligibilityVerifiedStatus } =
            patientInsurance;

          if (isVerified(eligibilityVerifiedStatus)) {
            return (
              <div className="flex items-center gap-x-2">
                <Icon SvgIcon={VerifiedIcon} theme="success" />
                {`
                  Verified
                  ${
                    eligibilityLastVerifiedBy?.fullDisplayName
                      ? ` by ${eligibilityLastVerifiedBy.fullDisplayName}`
                      : ""
                  }
                  ${eligibilityLastVerifiedAt ? ` on ${formatISODate(eligibilityLastVerifiedAt)}` : ""}`}
              </div>
            );
          } else if (isAutoVerified(eligibilityVerifiedStatus)) {
            return (
              <div className="flex items-center gap-x-2">
                <Icon SvgIcon={VerifiedIcon} theme="primary" />
                {`
                  Verified by Auto-Verify
                  ${eligibilityLastVerifiedAt ? ` on ${formatISODate(eligibilityLastVerifiedAt)}` : ""}`}
              </div>
            );
          }

          return undefined;
        }}
        title="Eligibility Verification"
      />
      <SectionRow
        insurances={insurances}
        renderValue={({ insurancePlan }) => {
          const { benefitLastVerifiedAt, benefitLastVerifiedBy, benefitVerifiedStatus } = insurancePlan ?? {};

          if (isVerified(benefitVerifiedStatus)) {
            return (
              <div className="flex items-center gap-x-2">
                <Icon SvgIcon={VerifiedIcon} theme="success" />
                {`
                  Verified
                  ${
                    benefitLastVerifiedBy?.fullDisplayName
                      ? ` by ${benefitLastVerifiedBy.fullDisplayName}`
                      : ""
                  }${benefitLastVerifiedAt ? ` on ${formatISODate(benefitLastVerifiedAt)}` : ""}`}
              </div>
            );
          } else if (isAutoVerified(benefitVerifiedStatus)) {
            return (
              <div className="flex items-center gap-x-2">
                <Icon SvgIcon={VerifiedIcon} theme="primary" />
                {`
                  Verified by Auto-Verify
                  ${benefitLastVerifiedAt ? ` on ${formatISODate(benefitLastVerifiedAt)}` : ""}`}
              </div>
            );
          }

          return undefined;
        }}
        title="Benefits & Coverage Verification"
      />
      <SectionRow
        insurances={insurances}
        renderValue={({ patientInsurance: { subscriber, type } }) =>
          type === "PRIMARY_SUBSCRIBER" ? (
            patient.name.fullDisplayName
          ) : subscriber.firstName && subscriber.lastName ? (
            <Link className="text-primaryTheme" to={paths.patient({ patientId: subscriber.patientId })}>
              {subscriber.firstName} {subscriber.lastName}
            </Link>
          ) : undefined
        }
        title="Subscriber"
      />
      <SectionRow
        insurances={insurances}
        renderValue={({ patientInsurance }) => {
          return (
            patientRelations.find((item) => item.value === patientInsurance.relationshipWithSubscriber)
              ?.label ?? "Self"
          );
        }}
        title="Relationship to Subscriber"
      />
      <SectionRow
        insurances={insurances}
        renderValue={({ patientInsurance }) =>
          patientInsurance.subscriber.dob ? formatISODate(patientInsurance.subscriber.dob) : EMPTY_CELL
        }
        title="Subscriber Date of Birth"
      />
      <SectionRow
        insurances={insurances}
        renderValue={({ patientInsurance }) => (
          <SubscriberOrSsnValue subscriber={patientInsurance.subscriber} />
        )}
        title="Subscriber ID / SSN"
      />
      <SectionRow
        insurances={insurances}
        renderValue={({ patientInsurance }) => patientInsurance.subscriber.carrier}
        title="Carrier"
      />
      <SectionRow
        insurances={insurances}
        renderValue={({ insurancePlan }) => insurancePlan?.groupNumber ?? EMPTY_CELL}
        title="Group Number"
      />
      <SectionRow
        insurances={insurances}
        renderValue={({ insurancePlan }) => insurancePlan?.groupName ?? EMPTY_CELL}
        title="Group Name"
      />
      <SectionRow
        insurances={insurances}
        renderValue={({ insurancePlan }) => {
          return insurancePlan?.feeSchedule ? (
            <Link
              className="flex items-center gap-x-2 text-primaryTheme"
              to={paths.feeScheduleDetails({ feeScheduleId: insurancePlan.feeSchedule.id })}
            >
              {insurancePlan.feeSchedule.name}
              <Icon SvgIcon={ExternalLinkIcon} size="sm" theme="primary" />
            </Link>
          ) : (
            EMPTY_CELL
          );
        }}
        title="Fee Schedule"
      />
      <SectionRow
        insurances={insurances}
        renderValue={({ insurancePlan }) => {
          if (insurancePlan?.renewalMonth) {
            return readableMonth(insurancePlan.renewalMonth);
          }

          return undefined;
        }}
        title="Benefits Renew In"
      />
    </>
  );
};
