import { FC } from "react";
import { useLocation } from "react-router-dom";
import { getFullUrl } from "@libs/utils/location";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";

import { EditForm } from "components/Settings/Forms/EditForm";
import { ClinicalNotesSettingsFlyover } from "components/Settings/ChartingSettings/ClinicalNotesSettingsFlyover";

export const EditClinicalNoteFormRoute: FC = () => {
  const location = useLocation();
  const { formId } = usePathParams("editClinicalNotesForms");
  const { query } = useQueryParams("editClinicalNotesForms");
  const from = query.from ?? paths.forms();
  const previewLink = paths.clinicalNoteFormPreview({ formId }, { from: getFullUrl(location) });

  return (
    <EditForm
      previewLink={previewLink}
      from={from}
      formId={formId}
      SettingsFlyover={ClinicalNotesSettingsFlyover}
    />
  );
};
