import React from "react";
import { TimeSeriesResolutionOption } from "utils/routing/dashboard";

export const useReportingResolutionOptions = (): SelectOption<TimeSeriesResolutionOption>[] => {
  return React.useMemo(() => {
    const items: {
      value: TimeSeriesResolutionOption;
      label: string;
    }[] = [
      {
        value: "DAY",
        label: "Daily",
      },
      {
        value: "WEEK",
        label: "Weekly",
      },
      {
        value: "MONTH",
        label: "Monthly",
      },
      {
        value: "QUARTER",
        label: "Quarterly",
      },
      {
        value: "YEAR",
        label: "Yearly",
      },
    ];

    return items;
  }, []);
};
