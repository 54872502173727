import React from "react";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { ClaimsList } from "components/Claims/Claims/ClaimsList";
import { ClaimsAppHistoryProvider } from "components/Claims/ClaimsLinksContext";
import { useClaimsTabs } from "components/Claims/Claims/useClaimTabs";

export const ClaimsRoute: React.FC = () => {
  usePageTitle("Claims");

  const tabs = useClaimsTabs();

  return (
    <ClaimsAppHistoryProvider name="claims">
      <ClaimsList routeName="claims" tabs={tabs} />
    </ClaimsAppHistoryProvider>
  );
};
