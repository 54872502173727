import React, { useCallback, useEffect, useState } from "react";
import { ClaimLineItemVO, DentalProcedureVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { useAccount } from "@libs/contexts/AccountContext";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";
import { ProcedureSelector } from "components/ProcedureSelector/ProcedureSelector";
import { getDentalProceduresQuery } from "api/charting/queries";

interface Props {
  claimLineItem: ClaimLineItemVO;
  draftCdtCode: string | undefined;
  draftRemarks: string | undefined;
  handleUpdateCdtCode: (newProcedure: DentalProcedureVO | undefined) => void;
  handleUpdateRemarks: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
  procedureDowngradeChecked: boolean;
  toggleProcedureDowngrade: () => void;
}

export const RemarksModalContent: React.FC<Props> = ({
  claimLineItem,
  draftCdtCode,
  draftRemarks,
  handleUpdateCdtCode,
  handleUpdateRemarks,
  procedureDowngradeChecked,
  toggleProcedureDowngrade,
}) => {
  const { practiceId } = useAccount();
  const [{ data: procedures }] = useApiQueries([getDentalProceduresQuery({ args: { practiceId } })]);
  const [selected, setSelected] = useState<DentalProcedureVO | undefined>();

  const handleChange = useCallback(
    (newProcedure?: DentalProcedureVO | undefined) => {
      setSelected(newProcedure);
      handleUpdateCdtCode(newProcedure);
    },
    [handleUpdateCdtCode]
  );

  useEffect(() => {
    if (procedures && draftCdtCode) {
      const selectedProcedure = procedures.find((procedure) => procedure.cdtCode === draftCdtCode);

      setSelected(selectedProcedure);
    }
  }, [draftCdtCode, procedures]);

  return (
    <div className="flex text-xs flex-col">
      <div className="font-sansSemiBold">Procedure</div>
      <div className="mt-1 mb-3">{`${claimLineItem.procedureCode} ${claimLineItem.procedureDescription}`}</div>
      <FormFieldTextarea
        defaultValue={draftRemarks}
        placeholder="Enter remarks..."
        onChange={(event) => handleUpdateRemarks(event)}
      />
      <div className="mt-6 flex">
        <Checkbox checked={procedureDowngradeChecked} onChange={toggleProcedureDowngrade}>
          Procedure Downgraded
        </Checkbox>
      </div>
      {procedureDowngradeChecked && (
        <ProcedureSelector
          className="mt-3.5"
          label="Procedure"
          layout="labelIn"
          mode="single"
          onChange={handleChange}
          placeholder="Select procedure"
          procedures={procedures}
          value={selected}
        />
      )}
    </div>
  );
};
