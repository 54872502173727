import { FC, Fragment } from "react";
import { FormVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { isDefined } from "@libs/utils/types";
import { FormElementRequest } from "components/Settings/Forms/types";
import { PaletteMap } from "components/Settings/Forms/utils";
import { ElementIcon } from "components/Settings/Forms/ElementIcon";
import { ElementSummary } from "components/Settings/Forms/ElementSummary";

interface Props {
  isSection: boolean;
  onAddElement: (type: FormElementRequest) => void;
  formType: FormVO["type"];
}

export const FormPalette: FC<Props> = ({ formType, onAddElement, isSection }) => {
  const Palette = [
    {
      title: "Question Types",
      elements: [
        formType === "INTAKE" ? PaletteMap.CONSENT : PaletteMap.EDITABLE_TEXT_BLOCK,
        PaletteMap.DATE_INPUT,
        PaletteMap.SELECT_INPUT,
        PaletteMap.NUMBER_INPUT,
        PaletteMap.TEXT_INPUT,
        PaletteMap.BOOLEAN_INPUT,
      ],
    },
    formType === "INTAKE"
      ? {
          title: "Structure",
          elements: [PaletteMap.SECTION, PaletteMap.TEXT_BLOCK],
        }
      : null,
  ].filter(isDefined);

  return (
    <div className="flex flex-col w-full gap-y-6">
      {Palette.map((section, index) => (
        <Fragment key={index}>
          <h6 className="text-sm font-sansSemiBold">{section.title}</h6>
          <div className="flex flex-col w-full gap-y-3">
            {section.elements.map((item, innerIndex) => {
              const disabled = isSection && item.element.type === "SECTION";

              return (
                <button
                  type="button"
                  key={innerIndex}
                  disabled={isSection && item.element.type === "SECTION"}
                  className={cx(
                    `flex
                     w-full
                     text-left
                     items-center
                     border
                     rounded
                     gap-x-5
                     px-5
                     py-2
                     border-greyLighter`,
                    disabled && "opacity-50"
                  )}
                  onClick={() => onAddElement(item.element)}
                >
                  <ElementIcon Icon={item.Icon} />
                  <ElementSummary title={item.title} description={item.description} className="flex-1" />
                </button>
              );
            })}
          </div>
        </Fragment>
      ))}
    </div>
  );
};
