import { addDays, endOfWeek, startOfWeek, subDays } from "date-fns";
import { WEEK_IN_DAYS, formatAsISODate, nowInTimezone } from "@libs/utils/date";
import { useMemo } from "react";
import { Filter } from "@libs/api/generated-api";
import { useQueryParams } from "hooks/useQueryParams";

const TWO = 2;

export const useDailyHuddleQuery = (timezoneId: string) => {
  const { query, updateQuery } = useQueryParams("huddle");

  // endDate is exclusive so we must always add 1 to the actual end date to pass
  // into the query
  const queryDates = useMemo(() => {
    // If we are using query dates, calculate endDate properly
    const dateRangeStartDate = query.dateRange?.[0];

    if (dateRangeStartDate) {
      const dateRangeEndDate = query.dateRange?.[1] ?? dateRangeStartDate;
      const endDate = addDays(dateRangeEndDate, 1);

      return {
        startDate: formatAsISODate(dateRangeStartDate),
        endDate: formatAsISODate(endDate),
      };
    }

    // Using quick date filters w/ default TODAY
    let startDate = nowInTimezone(timezoneId);
    let endDate = nowInTimezone(timezoneId);

    switch (query.quickDateFilter) {
      case "YESTERDAY": {
        startDate = subDays(startDate, 1);
        break;
      }
      case "TOMORROW": {
        startDate = addDays(startDate, 1);
        endDate = addDays(endDate, TWO);

        break;
      }
      case "THIS_WEEK": {
        startDate = startOfWeek(startDate);
        endDate = addDays(endOfWeek(startDate), TWO);

        break;
      }
      case "NEXT_WEEK": {
        const nextWeekDate = addDays(startDate, WEEK_IN_DAYS);

        startDate = startOfWeek(nextWeekDate);
        endDate = addDays(endOfWeek(nextWeekDate), TWO);

        break;
      }
      default: {
        // Default to TODAY
        endDate = addDays(startDate, 1);
        break;
      }
    }

    return { startDate: formatAsISODate(startDate), endDate: formatAsISODate(endDate) };
  }, [timezoneId, query.dateRange, query.quickDateFilter]);

  const otherFilters: Filter[] = useMemo(() => {
    // BE has default status filtering so only send statuses if we have selected
    // any
    const filters = query.statuses?.length ? [{ type: "appointmentState", values: query.statuses }] : [];

    return [
      ...filters,
      { type: "providerId", values: query.providerIds?.map((val) => `${val}`) ?? [] },
      { type: "roomId", values: query.roomIds?.map((val) => `${val}`) ?? [] },
    ];
  }, [query.providerIds, query.roomIds, query.statuses]);

  const dailyHuddleQueryKey = useMemo(() => {
    return {
      data: {
        endDate: queryDates.endDate,
        otherFilters,
        patientSearchString: query.patientName,
        startDate: queryDates.startDate,
        includeTagIds: query.tagIds,
        clinicalNoteStatuses: query.clinicalNoteStatuses,
      },
    };
  }, [
    otherFilters,
    query.patientName,
    queryDates.endDate,
    queryDates.startDate,
    query.clinicalNoteStatuses,
    query.tagIds,
  ]);

  return {
    dailyHuddleQueryKey,
    query,
    updateQuery,
  };
};

export type DailyHuddleQueryKeyData = ReturnType<typeof useDailyHuddleQuery>["dailyHuddleQueryKey"]["data"];
