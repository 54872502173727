import React from "react";
import { EmployeeRolesVO, EmployeeVO } from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { Cell, CheckboxCell, Row } from "@libs/components/UI/GridTableComponents";
import { Avatar } from "components/UI/Avatar";
import { findRoleById } from "components/Roles/roleUtils";

interface Props {
  employee: EmployeeVO;
  roles: EmployeeRolesVO[];
  isSelected: boolean;
  onSelect: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

const cxStyles = {
  cell: "flex items-center pl-3 group-hover:bg-slate-100",
};

export const EmployeeRow: React.FC<Props> = ({ employee, roles, isSelected, onSelect }) => {
  const roleName = findRoleById(employee.roleV2Id, roles)?.role.name;

  return (
    <Row isSelected={isSelected}>
      <CheckboxCell checked={isSelected} className={cxStyles.cell} onChange={onSelect} value={employee.id} />
      <Cell className={cxStyles.cell}>
        <Avatar
          imageUrl={employee.personalDetails.thumbnail}
          name={employee.personalDetails.displayName}
          size="sm"
        />
        <span className="ml-4">{employee.personalDetails.displayName}</span>
      </Cell>
      <Cell className={cxStyles.cell}>{sentenceCaseConstant(employee.employmentDetails.status ?? "")}</Cell>
      <Cell className={cxStyles.cell}>{employee.employmentDetails.jobTitle}</Cell>
      <Cell className={cxStyles.cell}>{roleName}</Cell>
    </Row>
  );
};
