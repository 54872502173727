import React from "react";
import { AppointmentCategoryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Icon } from "@libs/components/UI/Icon";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { Pill } from "@libs/components/UI/Pill";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as DragIcon } from "@libs/assets/icons/drag-reorder-2.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { Cell, TextCell, cxGridTableStyles } from "@libs/components/UI/GridTableComponents";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { ExpiredCDTCodeIcon } from "components/Charting/ExpiredCDTCodeIcon";
import { useNow } from "hooks/useNow";

const cxStyles = {
  cell: cx("flex items-center", cxGridTableStyles.cellPadding()),
  menuText: "ml-3 text-sm",
  pillCell: "flex-wrap gap-y-1.5 gap-x-2",
};

export const AppointmentCategoryRow: React.FC<{
  category: AppointmentCategoryVO;
  onEdit: (category: AppointmentCategoryVO) => void;
  onDelete: (category: AppointmentCategoryVO) => void;
}> = ({ category, onEdit, onDelete }) => {
  const now = useNow();

  const menu = useBoolean(false);
  const deleteModal = useBoolean(false);

  return (
    <>
      <Cell className={cxStyles.cell}>
        <Icon
          SvgIcon={DragIcon}
          className="drag-handle cursor-move"
          tooltip={{ content: "Reorder", theme: "SMALL" }}
        />
      </Cell>
      <Cell className={cxStyles.cell}>
        <div role="img" className="rounded-full w-4 h-4" style={{ backgroundColor: category.color }} />
      </Cell>
      <TextCell className="flex items-center">{category.name}</TextCell>
      <Cell className={cx(cxStyles.pillCell, cxStyles.cell)}>
        {category.procedures.map((procedure, procIndex) => (
          <FloatingTooltip content={procedure.name} key={`${category.id || ""}-${procedure.id}-${procIndex}`}>
            <Pill className="flex items-center gap-x-1">
              {procedure.simpleName}
              <ExpiredCDTCodeIcon now={now} endDate={procedure.endDate} />
            </Pill>
          </FloatingTooltip>
        ))}
      </Cell>
      <Cell className={cx(cxStyles.pillCell, cxStyles.cell)}>
        {category.providers.map((provider) => (
          <Pill key={`${category.id || ""}-${provider.id}`}>{provider.name.shortDisplayName}</Pill>
        ))}
        {category.providers.length === 0 ? <Pill>Any Provider</Pill> : null}
      </Cell>
      <TextCell className="flex items-center">{category.duration} Minutes</TextCell>
      <Cell className={cxStyles.cell}>
        {category.isSelfBookable ? <Pill theme="green">Enabled</Pill> : <Pill theme="grey">Disabled</Pill>}
      </Cell>
      <Cell className={cxStyles.cell}>
        <ButtonMenu
          isOpen={menu.isOn}
          onRequestOpen={menu.on}
          onRequestClose={menu.off}
          placement="left"
          menuContent={
            <div className="w-64">
              <MenuOptionButton
                name="edit"
                onClick={() => {
                  menu.off();
                  onEdit(category);
                }}
              >
                <Icon SvgIcon={EditIcon} />
                <div className={cxStyles.menuText}>Edit</div>
              </MenuOptionButton>
              <MenuOptionButton
                name="delete"
                className="border-t"
                onClick={() => {
                  menu.off();
                  deleteModal.on();
                }}
              >
                <Icon SvgIcon={DeleteIcon} theme="error" />
                <div className={cxStyles.menuText}>Delete</div>
              </MenuOptionButton>
            </div>
          }
        >
          {(props) => <ButtonIcon SvgIcon={MenuIcon} theme="primary" size="md" className="mr-2" {...props} />}
        </ButtonMenu>
      </Cell>
      {deleteModal.isOn && (
        <ConfirmationModal
          size="2xs"
          isConfirming={false}
          confirmText="Delete"
          onCancel={deleteModal.off}
          onConfirm={() => {
            deleteModal.off();
            onDelete(category);
          }}
          primaryText="Delete Category"
          secondaryText="Are you sure you want to delete this category?"
        />
      )}
    </>
  );
};
