import React, { useCallback } from "react";
import { add, format, startOfDay, sub } from "date-fns";
import { JournalEventRequest } from "@libs/api/generated-api";
import {
  endOfDayInTimezone,
  formatAsISODate,
  getLocalDate,
  nowInTimezone,
  startOfDayInTimezone,
} from "@libs/utils/date";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useReactToPrint } from "react-to-print";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { useQueryParams } from "hooks/useQueryParams";
import { getJournalEventsInfiniteQuery } from "api/reporting/queries";
import { MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";
import { DashboardLayout } from "components/Dashboard/DashboardLayout";
import { SelectReportingQuestion } from "components/Dashboard/SelectReportingQuestion";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { EndOfDayReportTable } from "components/Dashboard/EndOfDayReport/EndOfDayReportTable";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { DailyReportQuery } from "utils/routing/dashboard";
import { useDownloadEndOfDayCsv } from "components/Dashboard/EndOfDayReport/hooks/useDownloadEndOfDayCsv";
import { cxStyles } from "components/Dashboard/DashboardPageHeader";
import { EmptyDashboardTable } from "components/Dashboard/Tables/EmptyDashboardTable";
import { useDownloadQueryPages } from "components/Dashboard/hooks/useDownloadInfiniteQuery";
import { handleError } from "utils/handleError";

const MINIMUM_DATE_AGE_DAYS = 15;

export const EndOfDayReportRoute: React.FC = () => {
  const practice = useCurrentPractice();
  const { query, updateQuery } = useQueryParams("dashboardDailyReport");
  const focusDate = React.useMemo(
    () => (query.focusDate ? getLocalDate(query.focusDate) : startOfDay(nowInTimezone(practice.timezoneId))),
    [query.focusDate, practice.timezoneId]
  );
  const dateBounds = React.useMemo(() => {
    const now = new Date();
    const maxDate = endOfDayInTimezone(practice.timezoneId, now);
    const minDate = startOfDayInTimezone(practice.timezoneId, sub(now, { days: MINIMUM_DATE_AGE_DAYS }));

    return {
      maxDate,
      minDate,
    };
  }, [practice.timezoneId]);
  const handleRouteStateChange = React.useCallback(
    (updates: Partial<DailyReportQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );

  const { search: debouncedSearch } = useDebouncedSearch(query.patientSearch ?? "");
  const startDate = formatAsISODate(focusDate);
  const endDate = formatAsISODate(add(focusDate, { days: 1 }));
  const reportParams: JournalEventRequest = {
    startDate,
    endDate,
    patientSearchString: debouncedSearch,
    includeTotal: true,
    // Event type must always be sorted first because we are grouping by event type (aka entry type)
    sortOrders: [{ field: "eventType", direction: "ASCENDING" }, ...query.tableSort],
    filters: query.filters,
  };
  const endOfDayReportInfiniteQuery = useInfiniteApiQuery(
    getJournalEventsInfiniteQuery({
      args: {
        practiceId: practice.id,
        pageNumber: 1,
        pageSize: MAX_PAGE_SIZE,
        data: reportParams,
      },
    })
  );
  const { isDownloading, downloadCSV } = useDownloadEndOfDayCsv({
    date: startDate,
    endOfDayReportInfiniteQuery,
  });

  const [providersQuery] = useApiQueries([getPracticeProvidersQuery({ args: { practiceId: practice.id } })]);

  const printing = useBoolean(false);
  const printingSet = printing.set;
  const printRef = React.useRef<HTMLDivElement>(null);
  const { startDownload } = useDownloadQueryPages(endOfDayReportInfiniteQuery);
  const handlePrintTable = useReactToPrint({
    content: () => printRef.current,
    documentTitle: `Ledger entries ${format(focusDate, "MMMM do, yyyy")}`,
    onAfterPrint: printing.off,
    pageStyle: "@page { size: letter landscape; margin: 1.5rem; }",
  });
  const handlePrintClick = useCallback(async () => {
    try {
      printingSet(true);
      await startDownload();
      handlePrintTable();
    } catch (e) {
      handleError(e);
    } finally {
      printingSet(false);
    }
  }, [handlePrintTable, printingSet, startDownload]);

  const tableProps = {
    providersQuery,
    endOfDayReportInfiniteQuery,
    onUpdateParams: handleRouteStateChange,
    query,
    focusDate,
    onClickDownloadCSV: downloadCSV,
    isDownloading,
    onClickPrint: handlePrintClick,
    isLoadingPrint: printing.isOn,
  };

  return (
    <DashboardLayout
      className="min-w-[900px]"
      contentClassName="flex flex-col"
      header={
        <div className={cxStyles.pageHeader}>
          <SelectReportingQuestion question="daily-report" />
          <div className="flex items-center gap-4">
            <div className="font-sansSemiBold">Day</div>
            <FormFieldSelectMenusDatepicker
              selected={focusDate}
              isClearable={false}
              ariaLabelledBy="Select Day"
              shouldCloseOnSelect={false}
              {...dateBounds}
              onChange={(date) => {
                if (date) {
                  updateQuery("replaceIn", { focusDate: formatAsISODate(date) });
                }
              }}
            />
          </div>
        </div>
      }
    >
      {focusDate < dateBounds.minDate || focusDate > dateBounds.maxDate ? (
        <EmptyDashboardTable className="col-span-full">
          {`Please select a date within the last ${MINIMUM_DATE_AGE_DAYS} days`}
        </EmptyDashboardTable>
      ) : (
        <EndOfDayReportTable {...tableProps} />
      )}
      {printing.isOn && (
        <div className="hidden">
          <EndOfDayReportTable {...tableProps} isLoadingPrint={false} ref={printRef} />
        </div>
      )}
    </DashboardLayout>
  );
};
