import React from "react";
import { PatientSummaryVO } from "@libs/api/generated-api";
import { noop } from "@libs/utils/noop";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { useCameraCapture } from "components/ImageCapturing/useCameraCapture";

import { useSensorImageUploader } from "components/PatientProfile/Imaging/MountRoute/hooks/useSensorImageUploader";
import { usePathParams } from "hooks/usePathParams";
import { useUploadMedicalImages } from "components/PatientProfile/Imaging/MountRoute/hooks/useUploadMedicalImages";
import { uploadMedicalImageV2 } from "api/imaging/mutations";
import { useImagingDevices } from "components/PatientProfile/Imaging/hooks/useImagingDevices";
import { batchPrepareFileUpload } from "api/file-uploads/mutations";
import { MountLayoutType } from "api/imaging/imaging-api";
import { ImageUploadItem } from "components/PatientProfile/Imaging/MountRoute/types";
import { ImageLayoutItem } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";
import { useSelectedCaptureDevice } from "components/PatientProfile/Imaging/MountRoute/hooks/useSelectedCaptureDevice";

export const useCaptureTools = ({
  sandboxImages,
  layout,
  patient,
  deviceId,
}: {
  sandboxImages: ImageLayoutItem[];
  layout: MountLayoutType;
  patient: PatientSummaryVO;
  deviceId?: string;
}) => {
  const { practiceId } = useAccount();
  const { patientId, mountId } = usePathParams("mountDetails");
  const cameraCapture = useCameraCapture();

  const { devices } = useImagingDevices(cameraCapture);
  const [batchPrepareUploadMutation, linkMedicalImageMutation] = useApiMutations([
    batchPrepareFileUpload,
    uploadMedicalImageV2,
  ]);
  const { uploadImages, isUploading } = useUploadMedicalImages({
    practiceId,
    prepareUploadsMutateAsync: batchPrepareUploadMutation.mutateAsync,
    linkMedicalImageMutateAsync: linkMedicalImageMutation.mutateAsync,
  });

  const { selectedCaptureDevice, handleSelectedCaptureDeviceChanged } = useSelectedCaptureDevice({
    deviceId,
    cameraCapture,
  });

  const { startSensorUploader, captureState } = useSensorImageUploader({
    uploadImages,
    mountLayout: layout,
    sandboxImages,
    patient,
    mountId,
  });

  const handleSingleImageUpload = React.useCallback(
    (imageUpload: ImageUploadItem, uploadDeviceId?: string) => {
      return uploadImages({
        patientId,
        mountId,
        images: [imageUpload],
        deviceId: uploadDeviceId,
        onProgress: noop,
      });
    },
    [mountId, patientId, uploadImages]
  );

  return {
    selectedCaptureDevice,
    handleSelectedCaptureDeviceChanged,
    handleSingleImageUpload,
    isUploading,
    devices,
    startSensorUploader,
    captureState,
  };
};
