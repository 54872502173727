import { FC } from "react";

import { useAccount } from "@libs/contexts/AccountContext";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";

import { Flyover } from "components/UI/Flyover";

import { EditEstimatesForm } from "components/Charting/EditEstimatesForm";

import {
  getPatientProcedure,
  getDentalProceduresQuery,
  getPatientProcedureInsuranceStatus,
} from "api/charting/queries";

interface Props {
  patientProcedureId: number;
  onSaved: Func;
  onClose: Func;
}

export const EditEstimatesFlyover: FC<Props> = ({ patientProcedureId, onSaved, onClose }) => {
  const { practiceId } = useAccount();

  const [patientProcedureQuery, patientProcedureInsuranceStatusQuery, dentalProceduresQuery] = useApiQueries([
    getPatientProcedure({ args: { practiceId, patientProcedureId } }),
    getPatientProcedureInsuranceStatus({ args: { practiceId, patientProcedureId } }),
    getDentalProceduresQuery({ args: { practiceId } }),
  ]);

  return (
    <Flyover title="Edit Estimates" onClose={onClose} headerSize="sm" size="md">
      <QueryResult
        queries={[patientProcedureQuery, patientProcedureInsuranceStatusQuery, dentalProceduresQuery]}
      >
        {patientProcedureQuery.data &&
        patientProcedureInsuranceStatusQuery.data &&
        dentalProceduresQuery.data ? (
          <EditEstimatesForm
            patientProcedure={patientProcedureQuery.data}
            patientProcedureInsuranceStatus={patientProcedureInsuranceStatusQuery.data}
            dentalProcedures={dentalProceduresQuery.data}
            onSaved={onSaved}
            onClose={onClose}
          />
        ) : null}
      </QueryResult>
    </Flyover>
  );
};
