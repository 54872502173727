import { PatientAppointmentStatsVO } from "@libs/api/generated-api";
import { getAgeByDob } from "@libs/utils/formatString";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { Row } from "@libs/components/UI/GridTableComponents";
import { PatientNameLink } from "components/Dashboard/Tables/PatientNameLink";
import { paths } from "utils/routing/paths";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";
import { useNow } from "hooks/useNow";

type Props = {
  data: PatientAppointmentStatsVO;
  last?: boolean;
};

export const PatientsTreatedTableRow: React.FC<Props> = ({ last, data }) => {
  const now = useNow();
  const { patient, patientDob, primaryInsuranceCarrier, patientType, completedAppointments } = data;
  const cellProps = { last, to: paths.patient({ patientId: patient.id }) };

  return (
    <Row>
      <DashboardLinkCell {...cellProps}>
        <PatientNameLink patient={patient} />
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{getAgeByDob(now, patientDob)}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{primaryInsuranceCarrier?.name ?? ""}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{sentenceCaseConstant(patientType)}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{completedAppointments}</DashboardLinkCell>
    </Row>
  );
};
