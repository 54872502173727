import { cx } from "@libs/utils/cx";

export type CellProps = {
  children: React.ReactNode;
  className?: string;
  align?: "center" | "left" | "right";
};
export const CellCurrencyValue: React.FC<CellProps> = ({ children, className, align = "left" }) => {
  return (
    <div
      className={cx(
        "flex w-full max-w-[5rem]",
        align === "right" && "justify-end",
        align === "center" && "justify-center",
        className
      )}
    >
      {children}
    </div>
  );
};
