import { PropsWithChildren } from "react";

export const ScheduleHeader: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className={`
        flex
        h-14
        w-full
        justify-between
        items-center
        gap-x-5
        px-5
        bg-white
      `}
    >
      {children}
    </div>
  );
};
