import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/adjust.svg";
import { ToggleButton } from "components/UI/ToggleButton";

type Props = {
  onToggleEdit: Func;
  isEditing: boolean;
};
export const EditPreferencesButton: React.FC<Props> = ({ onToggleEdit, isEditing }) => {
  return (
    <div className="ml-auto">
      <ToggleButton
        type="checkbox"
        baseTextColor="text-primaryTheme"
        contentClassName="text-xs flex items-center font-sansSemiBold"
        onClick={onToggleEdit}
      >
        <Icon SvgIcon={isEditing ? CancelIcon : EditIcon} theme="primary" />
        <div className="ml-2.5">Preferences</div>
      </ToggleButton>
    </div>
  );
};
