import React from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle-filled.svg";
import { ReactComponent as AddIconOutline } from "@libs/assets/icons/plus-circle.svg";
import {
  NewImagePopoverMenu,
  NewImagePopoverMenuProps,
} from "components/PatientProfile/Imaging/PatientMountsList/NewImagePopoverMenu";

import { useLatestAISDevices } from "components/ImageCapturing/ImagingHubContext";

export const NewImagePopoverButton: React.FC<
  Omit<NewImagePopoverMenuProps, "onRequestClose"> & {
    isDarkMode?: boolean;
  }
> = ({ isDarkMode, disabled, ...rest }) => {
  const imageMenu = useBoolean(false);

  useLatestAISDevices(imageMenu.isOn);

  return (
    <div className="flex items-center justify-center w-5 h-5 relative">
      <div className="absolute inset-0 bg-white rounded-full scale-75" />
      <ButtonMenu
        placement="bottom-start"
        className="absolute"
        menuContent={<NewImagePopoverMenu onRequestClose={imageMenu.off} {...rest} />}
        isOpen={imageMenu.isOn}
        onRequestClose={imageMenu.off}
        onRequestOpen={imageMenu.on}
      >
        {(buttonProps) => (
          <ButtonIcon
            {...buttonProps}
            disabled={disabled}
            SvgIcon={isDarkMode ? AddIcon : AddIconOutline}
            size="md"
            theme="primary"
          />
        )}
      </ButtonMenu>
    </div>
  );
};
