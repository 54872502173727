import { useMemo, FC, useEffect } from "react";
import { EmployeeVO, PracticeInfoVO, RoleVO } from "@libs/api/generated-api";
import { getLocalDate, nowInTimezone } from "@libs/utils/date";
import { PracticePendoProvider } from "components/Main/PracticePendoContext";
import { OnboardingApp } from "components/Main/OnboardingApp";
import { SignedInAppRoutes } from "components/Main/SignedInAppRoutes";
import { CurrentUserContext } from "contexts/CurrentUserContext";
import { setSentryEmployeeProperties } from "utils/sentry";

export const hasAccess = (
  accessDate: string | undefined,
  startDate: string | undefined,
  timezoneId: string
) => {
  const date = accessDate ?? startDate;

  return Boolean(date && getLocalDate(date) <= nowInTimezone(timezoneId));
};

export const EmployeeUserProvider: FC<{
  practice: PracticeInfoVO;
  employee: EmployeeVO;
}> = ({ practice, employee }) => {
  const currentUser = useMemo(() => {
    const { id, personalDetails, contactDetails, employmentDetails, employeeSettings, roleV2 } = employee;

    return {
      id,
      type: "EMPLOYEE" as const,
      roleV2: roleV2 as RoleVO,
      name: {
        id,
        shortDisplayName: personalDetails.displayName ?? "",
        fullDisplayName: personalDetails.fullName ?? "",
        firstName: personalDetails.firstName,
        lastName: personalDetails.lastName,
      },
      phone: contactDetails.phone,
      email: contactDetails.workEmail,
      employmentDetails,
      employeeSettings,
    };
  }, [employee]);

  const employeeStatus = employee.employmentDetails.status;
  const employeeAccessDate = employee.employmentDetails.accessDate;
  const employeeStartDate = employee.employmentDetails.startDate;

  const isOnboarding = useMemo(() => {
    return (
      Boolean(employeeStatus && employeeStatus === "PENDING") ||
      !hasAccess(employeeAccessDate, employeeStartDate, practice.timezoneId)
    );
  }, [employeeAccessDate, employeeStartDate, employeeStatus, practice.timezoneId]);

  useEffect(() => {
    setSentryEmployeeProperties(employeeStatus);
  }, [employeeStatus]);

  return (
    <CurrentUserContext.Provider value={currentUser}>
      <PracticePendoProvider user={currentUser} practice={practice}>
        {isOnboarding ? <OnboardingApp /> : <SignedInAppRoutes />}
      </PracticePendoProvider>
    </CurrentUserContext.Provider>
  );
};
