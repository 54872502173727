import { useCallback, useState } from "react";
import { RecentProcedureHistoryItemVO } from "@libs/api/generated-api";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";

export const useHistoryFields = (savedHistory?: RecentProcedureHistoryItemVO[]) => {
  const [historyFields, setHistoryFields] = useState(savedHistory ?? []);

  useSyncOnce(setHistoryFields, savedHistory);

  const handleCancelHistoryChange = useCallback((history: RecentProcedureHistoryItemVO[]) => {
    setHistoryFields(history);
  }, []);

  const handleHistoryChange = useCallback((updated: RecentProcedureHistoryItemVO) => {
    setHistoryFields((last) => {
      return last.map((item) => {
        if (item.groupName === updated.groupName) {
          return updated;
        }

        return item;
      });
    });
  }, []);

  return { historyFields, handleCancelHistoryChange, handleHistoryChange };
};
