import { FC } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { BusinessInformation } from "components/Settings/PracticeSetup/Sections/BusinessInformation/BusinessInformation";
import { checkPermission } from "components/Roles/roleUtils";
import { useCurrentUser } from "contexts/CurrentUserContext";

export const BusinessInformationRoute: FC = () => {
  const isEditing = useBoolean(false);
  const currentUser = useCurrentUser();

  const canEdit = checkPermission(currentUser.roleV2, "PRACTICE_SETTINGS", "ACCESS_PRACTICE").isPermitted;

  return (
    <SettingsPanel
      isEditing={isEditing.isOn}
      title="Business Information"
      description="Basic information associated with your business"
      onToggleEdit={canEdit ? isEditing.toggle : undefined}
    >
      <BusinessInformation isEditing={canEdit && isEditing.isOn} onDoneEditing={isEditing.off} />
    </SettingsPanel>
  );
};
