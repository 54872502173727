import { ScaleSVG } from "@visx/responsive";
import { useTooltip, useTooltipInPortal, defaultStyles } from "@visx/tooltip";
import React from "react";
import { scaleBand, scaleLinear, scaleOrdinal } from "@visx/scale";
import { AxisBottom, AxisLeft, AxisScale, TickFormatter } from "@visx/axis";
import { GridRows } from "@visx/grid";
import { BarStack } from "@visx/shape";
import { localPoint } from "@visx/event";
import { Group } from "@visx/group";
import { Text } from "@visx/text";
import { WorktimeOverviewVO } from "@libs/api/generated-api";
import designConfig from "@libs/design.config";
import { formatShortDayOfMonth, getLocalDate } from "@libs/utils/date";
import { half } from "@libs/utils/math";
import {
  AXIS_COLORS,
  useBottomTickLabelProps,
  useLeftAxisTickProps,
} from "components/Dashboard/Charting/theme";
import { formatHours } from "components/EmployeeProfile/Timesheet/utils";

const tooltipStyles = {
  ...defaultStyles,
  minWidth: 60,
  backgroundColor: "rgba(0,0,0,0.9)",
  color: "white",
};

type WorksheetDataPoint = {
  date: string;
  overtime: number;
  regularHours: number;
};
type Props = {
  width: number;
  height: number;
  data: WorktimeOverviewVO;
  hoursInDecimal: boolean;
};

type DataStackKey = "overtime" | "regularHours";
type TooltipDataPoint = {
  key: DataStackKey;
} & WorksheetDataPoint;

const chartMargin = { top: 38, right: 0, bottom: 38, left: 40 };
const MIN_Y_VALUE = 12;
const TWO_WEEKS_DAYS = 14;
const TOOLTIP_TIMEOUT = 300;
const TEXT_PADDING_BOTTOM = 6;
const TEXT_PADDING_SIDES = 80;
const overviewKeys: DataStackKey[] = ["regularHours", "overtime"];
const colorScale = scaleOrdinal<string, string>({
  domain: overviewKeys,
  range: [designConfig.colors.primaryTheme, designConfig.colors.magenta],
});

export const TimesheetChartContent: React.FC<Props> = ({ height, width, data, hoursInDecimal }) => {
  const { tooltipOpen, tooltipLeft, tooltipTop, tooltipData, hideTooltip, showTooltip } =
    useTooltip<TooltipDataPoint>();
  const { containerRef, TooltipInPortal } = useTooltipInPortal({
    scroll: true,
  });
  const dataPoints: WorksheetDataPoint[] = React.useMemo(() => {
    return data.overview.map((item) => {
      return {
        date: formatShortDayOfMonth(getLocalDate(item.date)),
        overtime: item.overtime,
        regularHours: item.regularHours,
      };
    });
  }, [data]);
  const yMaxValueHours = React.useMemo(() => {
    return Math.max(...dataPoints.map((item) => item.overtime + item.regularHours), MIN_Y_VALUE);
  }, [dataPoints]);

  const xMax = width - chartMargin.left - chartMargin.right;
  const gridXMax = width;
  const yMax = height - chartMargin.top - chartMargin.bottom;
  const xScale = React.useMemo(
    () =>
      scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: dataPoints.map((item) => item.date),
        padding: 0.2,
      }),
    [xMax, dataPoints]
  );
  const yScale = React.useMemo(
    () =>
      scaleLinear<number>({
        range: [yMax, 0],
        round: true,
        domain: [0, yMaxValueHours],
        nice: true,
      }),
    [yMax, yMaxValueHours]
  );
  const bottomAxisLabelProps = useBottomTickLabelProps<string>();
  const leftAxisLabelProps = useLeftAxisTickProps<number>();
  const tooltipTimeout = React.useRef<NodeJS.Timeout | null>(null);
  const formatYAxis: TickFormatter<number> = React.useCallback((value) => {
    return `${value} hr`;
  }, []);

  return (
    <>
      <ScaleSVG width={width} height={height} innerRef={containerRef}>
        <Group top={chartMargin.top} left={chartMargin.left}>
          <AxisLeft<AxisScale<number>>
            scale={yScale}
            numTicks={half(yMaxValueHours)}
            tickFormat={formatYAxis}
            hideTicks
            hideAxisLine
            tickLabelProps={leftAxisLabelProps}
          />
          <GridRows
            scale={yScale}
            width={gridXMax}
            numTicks={half(yMaxValueHours)}
            height={yMax}
            stroke="black"
            strokeOpacity={0.1}
            strokeDasharray="4,3"
          />
          <BarStack<WorksheetDataPoint, string>
            data={dataPoints}
            keys={overviewKeys}
            x={(d) => d.date}
            xScale={xScale}
            yScale={yScale}
            color={colorScale}
          >
            {(barStacks) => {
              return barStacks.map((barStack, i) =>
                barStack.bars.map((bar, j) => {
                  const textY = Math.min(...barStacks.map((item) => item.bars[j].y));

                  return (
                    <React.Fragment key={`bar-stack-${barStack.index}-${bar.index}`}>
                      {i === 0 && textY < yMax && (
                        <Text
                          textAnchor="middle"
                          x={bar.x + half(bar.width)}
                          y={textY - TEXT_PADDING_BOTTOM}
                          width={bar.width + TEXT_PADDING_SIDES}
                          className="text-xs"
                        >
                          {`${formatHours(
                            bar.bar.data.overtime + bar.bar.data.regularHours,
                            hoursInDecimal
                          )} hr`}
                        </Text>
                      )}
                      <rect
                        x={bar.x}
                        y={bar.y}
                        height={bar.height}
                        width={bar.width}
                        fill={bar.color}
                        onMouseLeave={() => {
                          tooltipTimeout.current = setTimeout(() => {
                            hideTooltip();
                          }, TOOLTIP_TIMEOUT);
                        }}
                        onMouseMove={(event) => {
                          if (tooltipTimeout.current) {
                            clearTimeout(tooltipTimeout.current);
                          }

                          const eventSvgCoords = localPoint(event);
                          const left = bar.x + half(bar.width);

                          showTooltip({
                            tooltipData: {
                              key: bar.key as DataStackKey,
                              ...bar.bar.data,
                            },
                            tooltipTop: eventSvgCoords?.y,
                            tooltipLeft: left,
                          });
                        }}
                      />
                    </React.Fragment>
                  );
                })
              );
            }}
          </BarStack>
          <AxisBottom
            top={yMax}
            scale={xScale}
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            numTicks={TWO_WEEKS_DAYS}
            hideTicks
            stroke={AXIS_COLORS.ticks}
            tickLabelProps={bottomAxisLabelProps}
          />
        </Group>
      </ScaleSVG>
      {tooltipOpen && tooltipData && (
        <TooltipInPortal
          top={tooltipTop}
          left={tooltipLeft}
          style={tooltipStyles}
          className="bg-greyDark text-white p-2"
        >
          {formatHours(tooltipData[tooltipData.key], hoursInDecimal)}
        </TooltipInPortal>
      )}
    </>
  );
};
