import React from "react";
import Skeleton from "react-loading-skeleton";
import { ClaimInsuranceCarrierVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { toMap } from "@libs/utils/array";
import { DashboardFilterFormFieldSelect } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";
import { CarriersQuery } from "components/Dashboard/types";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";
import { getFilterSelectedValues } from "components/Dashboard/utils/filters";
import {
  NO_INSURANCE_CARRIER_ID,
  UNKNOWN_INSURANCE_CARRIER_ID,
} from "components/Dashboard/Adjustments/constants";

type Props = {
  onChange: (params: { filters: DashboardFilter[] }) => void;
  className?: string;
  carriersQuery: CarriersQuery;
  filters: DashboardFilter[];
  dashboardFilterType: "insuranceCarrierId" | "primaryInsuranceCarrierId";
  carriersInSelectedSegment?: {
    color: string;
    id: number;
    valueCents: number;
  }[];
};

export const SelectInsuranceCarrierFilters: React.FC<Props> = ({
  onChange,
  className,
  carriersQuery,
  filters,
  dashboardFilterType,
  carriersInSelectedSegment,
}) => {
  const carrierOptions = React.useMemo(() => {
    if (!carriersQuery.data) {
      return [];
    }

    const carriersSelected = getFilterSelectedValues({
      filters,
      type: dashboardFilterType,
    });
    let carriers = carriersQuery.data;

    if (carriersInSelectedSegment) {
      const carrierLookup = toMap(carriersQuery.data, "insuranceCarrierId");

      carriers = carriersInSelectedSegment.map((carrier) => {
        if (carrier.id === NO_INSURANCE_CARRIER_ID) {
          return {
            insuranceCarrierId: NO_INSURANCE_CARRIER_ID,
            name: "None",
          } as ClaimInsuranceCarrierVO;
        }

        return (
          carrierLookup[carrier.id] ??
          ({
            insuranceCarrierId: UNKNOWN_INSURANCE_CARRIER_ID,
            name: "Unknown",
          } as ClaimInsuranceCarrierVO)
        );
      });
    }

    return carriers.map((item) => {
      return {
        value: `${item.insuranceCarrierId}`,
        label: item.name,
        selected: carriersSelected.has(`${item.insuranceCarrierId}`),
      };
    });
  }, [carriersInSelectedSegment, carriersQuery.data, filters, dashboardFilterType]);

  if (carriersQuery.isLoading) {
    return <Skeleton className="w-44 h-6" />;
  }

  return (
    <DashboardFilterFormFieldSelect
      isSearchable
      error={carriersQuery.error ? "Failed to load carriers" : undefined}
      className={cx("w-44", className)}
      options={carrierOptions}
      placeholder="Select Carrier..."
      dashboardFilterType={dashboardFilterType}
      dashboardFilters={filters}
      onChangeFilters={onChange}
    />
  );
};
