import React from "react";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { TimeSeriesResolutionOption } from "components/Dashboard/types";
import { BarStackData, BarStackGroup } from "components/Dashboard/Charting/types";
import { isNowForResolution } from "components/Dashboard/utils/timeSeriesDates";

export const useBarStackDecorators = <T extends string>(
  data: BarStackData<T>[] | BarStackGroup<T>[],
  focusDate?: Date,
  resolution?: TimeSeriesResolutionOption
) => {
  return {
    selectedBarIndex: React.useMemo(
      () => (focusDate ? data.findIndex((item) => item.date === formatAsISODate(focusDate)) : undefined),
      [data, focusDate]
    ),
    dottedBarIndex: React.useMemo(
      () =>
        data.findIndex((item) => {
          return resolution ? isNowForResolution(getLocalDate(item.date), resolution) : undefined;
        }),
      [resolution, data]
    ),
  };
};
