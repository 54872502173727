import { FC, useCallback, useEffect } from "react";

import { GustoPracticeFlowRequest } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { hasFailedPin } from "@libs/utils/hasFailedPin";
import { useAccount } from "@libs/contexts/AccountContext";
import { MainContent } from "@libs/components/UI/MainContent";
import { GustoEmbeddedFlow } from "components/Settings/PracticeSetup/Sections/PayrollInformation/GustoEmbeddedFlow";
import { EmployeePinModal } from "components/Employee/EmployeePinModal";

import { getCompanyOnboardingStatus } from "api/payroll/queries";
import { createPracticeFlow } from "api/payroll/mutations";

import { handleError } from "utils/handleError";
import { paths } from "utils/routing/paths";

export const GustoPracticeFlowRoute: FC = () => {
  const { practiceId } = useAccount();
  const pinModal = useBoolean(false);

  const [{ data: practiceOnboarding, isLoading: isLoadingPracticeOnboarding }] = useApiQueries([
    getCompanyOnboardingStatus({ args: { practiceId } }),
  ]);

  const [{ mutate: createPracticeFlowUrl, isLoading: isLoadingIframeUrl, data: response }] = useApiMutations([
    createPracticeFlow,
  ]);

  const loadIframeUrl = useCallback(() => {
    if (pinModal.isOn || !practiceOnboarding) {
      return;
    }

    const flowTypes: GustoPracticeFlowRequest["flowTypes"] =
      practiceOnboarding.status === "ONBOARDED"
        ? [
            "ADD_ADDRESSES",
            "ADD_BANK_INFO",
            "ADD_EMPLOYEES",
            "FEDERAL_TAX_SETUP",
            "STATE_SETUP",
            "SIGN_ALL_FORMS",
          ]
        : ["COMPANY_ONBOARDING"];

    createPracticeFlowUrl(
      { practiceId, data: { flowTypes } },
      {
        onError: (error) => {
          if (hasFailedPin(error)) {
            pinModal.on();
          } else {
            handleError(error);
          }
        },
      }
    );
  }, [pinModal, practiceOnboarding, createPracticeFlowUrl, practiceId]);

  useEffect(() => loadIframeUrl(), [loadIframeUrl]);

  return (
    <MainContent>
      <GustoEmbeddedFlow
        isLoading={isLoadingPracticeOnboarding || isLoadingIframeUrl}
        iframeUrl={response?.data.data.url}
        closeLink={paths.settingsSection({ section: "payroll-information" })}
      />

      {pinModal.isOn ? <EmployeePinModal onPinSuccess={pinModal.off} onClose={pinModal.off} /> : null}
    </MainContent>
  );
};
