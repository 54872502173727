import React from "react";
import designConfig from "@libs/design.config";
import { isString } from "@libs/utils/types";
import { createSelectStyles } from "@libs/components/UI/selectStyles";
import { FormFieldSelect, FormFieldSelectProps } from "components/UI/FormFieldSelect";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";
import { getFilterSelectedValues, upsertDashFilter } from "components/Dashboard/utils/filters";

export const DASHBOARD_SELECT_STYLES = {
  placeholder: () => ({
    color: designConfig.colors.greyDark,
  }),
};

export type DashboardFilterSelectProps = {
  /** array of all filters */
  dashboardFilters: DashboardFilter[];
  /** filter type that this menu edits */
  dashboardFilterType: DashboardFilter["type"];
  /** onChangeFilters will contain new set of filters after adjustments are made in menu. see upsertDashFilter for how filter will be mutated based on selection */
  onChangeFilters: (params: { filters: DashboardFilter[] }) => void;
  /** Whether filter should be merged with the current filter of its type (true), or overwritten (false)  */
  mergeWithExisting?: boolean;
};

export const useDashboardFilterStyles = <V extends SelectOptionValue, T extends SelectOption<V>>(
  dashboardFilterType: DashboardFilter["type"],
  filters: DashboardFilter[]
) => {
  const styles = React.useMemo(() => {
    const selectedValues = getFilterSelectedValues({
      type: dashboardFilterType,
      filters,
    });

    return createSelectStyles<V, T>({
      option: (provided, optionProps) => {
        const val = optionProps.data.value;
        const searchVal = val === -1 ? null : isString(val) ? val : undefined;

        return {
          ...provided,
          cursor: "pointer",
          fontWeight: searchVal && selectedValues.has(searchVal) ? "bolder" : undefined,
        };
      },
      ...DASHBOARD_SELECT_STYLES,
    });
  }, [dashboardFilterType, filters]);

  return styles;
};

export const DashboardFilterFormFieldSelect = <V extends SelectOptionValue, T extends SelectOption<V>>({
  dashboardFilterType,
  dashboardFilters,
  onChangeFilters,
  mergeWithExisting = true,
  ...rest
}: Omit<FormFieldSelectProps<V, T>, "styles" | "onChange"> & DashboardFilterSelectProps) => {
  const styles = useDashboardFilterStyles<V, T>(dashboardFilterType, dashboardFilters);

  return (
    <FormFieldSelect
      {...rest}
      closeMenuOnSelect={false}
      styles={styles}
      onChange={(option) => {
        onChangeFilters({
          filters: upsertDashFilter(
            dashboardFilters,
            {
              type: dashboardFilterType,
              values: option ? [option.value] : [],
            } as DashboardFilter,
            {
              mergeWithExisting,
              toggle: true,
            }
          ),
        });
      }}
    />
  );
};
