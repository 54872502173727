import { FC } from "react";
import { Route } from "react-router-dom";
import { MainContent } from "@libs/components/UI/MainContent";
import { MainAppHistoryProvider } from "components/Main/MainLinksContext";
import { NotFound } from "components/NotFoundRoute";
import { CommunicationsRoute } from "components/Communications/CommunicationsRoute";
import { AutomationJourneyRoute } from "components/Communications/Automations/AutomationJourneyRoute";
import { CampaignRoute } from "components/Communications/CampaignRoute";
import { CampaignGroupRoute } from "components/Communications/CampaignGroupRoute";
import { EmailEditorRoute } from "components/Communications/EmailEditorRoute";
import { EmailTemplatesRoute } from "components/Communications/EmailTemplatesRoute";

import { CreateAutomationActionRoute } from "components/Communications/Automations/CreateAutomationActionRoute";
import { EditAutomationActionRoute } from "components/Communications/Automations/EditAutomationActionRoute";
import { SentryRoutes } from "components/UI/SentryRoutes";

const CommunicationsRoutes = [
  {
    element: <CommunicationsRoute />,
    index: true,
  },
  {
    element: <AutomationJourneyRoute />,
    path: "automations/:journeyType",
  },
  {
    element: <CreateAutomationActionRoute />,
    path: "automations/:journeyType/actions/create",
  },
  {
    element: <EditAutomationActionRoute />,
    path: "automations/:journeyType/actions/:actionUuid",
  },
  {
    element: <CampaignRoute />,
    path: "campaigns/:campaignUuid",
  },
  {
    element: <CampaignGroupRoute />,
    path: "campaigns/:campaignUuid/group",
  },
  {
    element: <EmailEditorRoute />,
    path: "campaigns/:campaignUuid/email",
  },
  {
    element: <EmailTemplatesRoute />,
    path: "campaigns/:campaignUuid/templates",
  },
];

export const CommunicationsApp: FC = () => {
  return (
    <MainAppHistoryProvider name="communications">
      <MainContent>
        <SentryRoutes>
          {CommunicationsRoutes.map((routeProps, i) => (
            <Route key={i} {...routeProps} />
          ))}
          <Route path="*" element={<NotFound />} />
        </SentryRoutes>
      </MainContent>
    </MainAppHistoryProvider>
  );
};
