import { ChangeEvent, useState, useRef, useCallback, useEffect } from "react";
import { numericRegex } from "@libs/utils/regex";
import { toggleSet } from "@libs/utils/toggleSet";

const selectBetween = <V extends Value>(items: V[], value: V, last: V) => {
  const firstIndex = items.indexOf(last);
  const secondIndex = items.indexOf(value);

  if (firstIndex >= 0 && secondIndex >= 0) {
    if (firstIndex > secondIndex) {
      return items.slice(secondIndex, firstIndex + 1);
    }

    return items.slice(firstIndex, secondIndex + 1);
  }

  return [];
};

type Value = string | number;

export const useSelectRows = <V extends Value>(
  items: V[] | undefined,
  options?: {
    preSelectedItems?: V[];
    totalItems?: number;
  }
) => {
  const [rows, setRows] = useState<Set<V>>(
    options?.preSelectedItems ? new Set(options.preSelectedItems) : new Set()
  );

  const lastSelectedIdRef = useRef<V | null>(null);
  const selectAllRef = useRef(false);
  const deselectedIdsFromSelectAllRef = useRef<Set<V>>(new Set());

  // When selectAll is true (user has clicked the select all checkbox) and
  // options.totalItems is present (total number of items including elements
  // that may have not yet been loaded), we calculate selectedCount by
  // subtracting the number of deselected rows from totalItems; otherwise, we
  // simply return rows.size. For this reason, referencing selectedCount is
  // preferred over selectedRows.size (rows.size).
  const selectedCount =
    selectAllRef.current && options?.totalItems && options.totalItems > 0
      ? options.totalItems - deselectedIdsFromSelectAllRef.current.size
      : rows.size;

  const resetSelectedRows = useCallback(() => {
    lastSelectedIdRef.current = null;
    selectAllRef.current = false;
    deselectedIdsFromSelectAllRef.current.clear();
    setRows(new Set());
  }, []);

  // When user clicks the select all checkbox
  const selectAllRows = useCallback(() => {
    if (items) {
      selectAllRef.current = true;
      setRows(new Set(items));
    }
  }, [items]);

  const handleCheckboxChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const isHoldingShift = (e as ChangeEvent<HTMLInputElement> & { nativeEvent: { shiftKey: boolean } })
        .nativeEvent.shiftKey;
      const value = (numericRegex.test(e.target.value) ? Number(e.target.value) : e.target.value) as V;
      const lastSelectedValue = lastSelectedIdRef.current;

      // When selectAll is true (user has clicked the select all checkbox), we
      // need to keep track of any deselected rows for filtering purposes
      // relating to bulk actions.
      if (selectAllRef.current) {
        if (e.target.checked) {
          deselectedIdsFromSelectAllRef.current.delete(value);
        } else {
          deselectedIdsFromSelectAllRef.current.add(value);
        }
      }

      if (isHoldingShift && lastSelectedValue && value !== lastSelectedValue) {
        const idsToSelect = items ? selectBetween(items, value, lastSelectedValue) : [];

        if (idsToSelect.length) {
          setRows((last) => {
            const next = new Set(last);

            for (const id of idsToSelect) {
              next.add(id);
            }

            return next;
          });
        }

        lastSelectedIdRef.current = value;
      } else {
        setRows((last) => {
          if (value === lastSelectedValue) {
            lastSelectedIdRef.current = null;
          } else if (!last.has(value)) {
            lastSelectedIdRef.current = value;
          }

          return toggleSet(last, value);
        });
      }
    },
    [items]
  );

  // When selectAll is true (user has clicked the select all checkbox) and the
  // number of items has increased from loading more pages, then we set the
  // newly loaded rows to be selected, while maintaining any deselected rows.
  useEffect(() => {
    if (
      items &&
      items.length - deselectedIdsFromSelectAllRef.current.size > rows.size &&
      selectAllRef.current
    ) {
      setRows(new Set(items.filter((id) => !deselectedIdsFromSelectAllRef.current.has(id))));
    }
  }, [items, rows.size]);

  return {
    selectedCount,
    selectedRows: rows,
    deselectedRowsFromSelectAll: deselectedIdsFromSelectAllRef.current,
    hasAllSelected: selectAllRef.current,
    selectAllRows,
    resetSelectedRows,
    setRows,
    handleCheckboxChange,
  };
};
