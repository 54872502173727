import React, { useEffect, useRef } from "react";
import { MountVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { formatISODate } from "@libs/utils/date";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as ExternalLinkIcon } from "@libs/assets/icons/external-link.svg";
import { Pill } from "@libs/components/UI/Pill";
import { ReactComponent as DropDownIcon } from "@libs/assets/icons/down-caret.svg";
import { ButtonCell, Cell, cxGridTableStyles, Row } from "@libs/components/UI/GridTableComponents";

import { findScrollable } from "utils/findScrollable";
import { paths } from "utils/routing/paths";
import { BaseMountImagesTableProps } from "components/UI/SelectMountImages/types";
import { TableMountImageViewerRow } from "components/UI/SelectMountImages/TableMountImageViewerRow";

const HEADER_SIZE = 56;

export const ImagesTableRow: React.FC<
  {
    imageMount: MountVO;
    patientId: number;
  } & BaseMountImagesTableProps
> = ({ imageMount, patientId, selectedImageIds, showLink, ImageWrapper, onClickImage }) => {
  const rowOpen = useBoolean(false);

  const rowRef = useRef<HTMLDivElement>(null);
  const numSelected = React.useMemo(() => {
    return imageMount.images?.filter((image) => selectedImageIds?.has(image.id ?? -1)).length ?? 0;
  }, [imageMount.images, selectedImageIds]);

  useEffect(() => {
    if (rowRef.current && rowOpen.isOn) {
      const firstElementChild = rowRef.current.firstElementChild as HTMLElement | null;
      const scrollableParent = findScrollable(rowRef.current);

      if (firstElementChild && scrollableParent) {
        scrollableParent.scrollTo({
          behavior: "smooth",
          top: firstElementChild.offsetTop - HEADER_SIZE,
        });
      }
    }
  }, [rowRef, rowOpen.isOn]);

  return (
    <>
      <Row className={cx(rowOpen.isOn && "*:bg-slate-100 *:border-b-0")} ref={rowRef}>
        <ButtonCell onClick={rowOpen.toggle}>
          <FloatingTooltip content={rowOpen.isOn ? "Collapse" : "Expand"} theme="SMALL">
            <DropDownIcon
              className={cx("w-4 h-4 text-primaryTheme transition-transform", rowOpen.isOn && "rotate-180")}
            />
          </FloatingTooltip>
        </ButtonCell>
        <ButtonCell onClick={rowOpen.toggle}>{imageMount.name || imageMount.layout}</ButtonCell>
        <ButtonCell onClick={rowOpen.toggle}>{formatISODate(imageMount.date)}</ButtonCell>
        <ButtonCell onClick={rowOpen.toggle}>
          <div className="flex flex-row">
            {imageMount.types?.map((type) => (
              <Pill className="mr-2" key={type}>
                {type}
              </Pill>
            ))}
          </div>
        </ButtonCell>
        {selectedImageIds && (
          <ButtonCell className="flex justify-end" onClick={rowOpen.toggle}>
            {numSelected}
          </ButtonCell>
        )}
        {showLink && (
          <Cell className={cx("flex justify-end items-center px-5", cxGridTableStyles.dataCell)}>
            <ButtonIcon
              SvgIcon={ExternalLinkIcon}
              onClick={(event) => {
                event.stopPropagation();
                window.open(paths.mountDetails({ patientId, mountId: imageMount.id }), "_blank");
              }}
              size="sm"
              theme="primary"
              tooltip={{ content: "Open in Patient Imaging", theme: "SMALL" }}
            />
          </Cell>
        )}
      </Row>

      {rowOpen.isOn && (
        <Row>
          <TableMountImageViewerRow
            onClickImage={onClickImage}
            ImageWrapper={ImageWrapper}
            selectedImageIds={selectedImageIds}
            imageMount={imageMount}
          />
        </Row>
      )}
    </>
  );
};
