import { useMemo } from "react";
import { useClaimsLinks } from "components/Claims/ClaimsLinksContext";

export const useClaimsTabs = () => {
  const { links } = useClaimsLinks();

  return useMemo(
    () => [
      {
        label: "Pre-Auths",
        id: "pre-auths" as const,
        path: links.preAuth,
        exact: true,
      },
      {
        label: "Claims",
        id: "claims" as const,
        path: links.claims,
        exact: true,
      },
      {
        label: "EOBs",
        id: "eob" as const,
        path: links.eob,
        exact: true,
      },
    ],

    [links]
  );
};
