import React from "react";
import {
  CollectedPaymentByProviderComboEntryVO,
  CollectedPaymentTotalVO,
  NameVO,
} from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";
import { TimeSegment } from "utils/routing/dashboard";
import { COLLECTED_BY_ASSOCIATED_PROVIDER_HEADERS } from "components/Dashboard/PaymentCollected/constants";
import { useCurrentUrl } from "contexts/OriginContext";

type InputParams = {
  providerSections: {
    associatedProvider: NameVO | undefined;
    subtotals: CollectedPaymentByProviderComboEntryVO[];
    total: number;
  }[];
  unassigned: {
    subtotals: CollectedPaymentByProviderComboEntryVO[];
    total: number;
  };
  totals?: CollectedPaymentTotalVO;
};

const formatSection = ({
  sectionName,
  providers,
}: {
  sectionName: string;
  providers: CollectedPaymentByProviderComboEntryVO[];
}) => {
  return providers.map(
    ({
      provider,
      patientCount,
      totalInsurancePaymentAmount,
      totalPatientPaymentAmount,
      totalPaymentAmount,
    }) => {
      const row = formatCsvRow([
        sectionName,
        provider?.shortDisplayName ?? "",
        `${patientCount}`,
        formatCurrency(totalInsurancePaymentAmount),
        formatCurrency(totalPatientPaymentAmount),
        formatCurrency(totalPaymentAmount),
      ]);

      return row;
    }
  );
};
const formatProviderDataAsCsvUrl = ({ providerSections, unassigned, totals }: InputParams) => {
  if (!totals) {
    return [];
  }

  const columnTitles = COLLECTED_BY_ASSOCIATED_PROVIDER_HEADERS.map(({ label }) => label).join(",");

  const sections = providerSections.flatMap((combos) => {
    return formatSection({
      sectionName: combos.associatedProvider?.shortDisplayName ?? "",
      providers: combos.subtotals,
    });
  });

  const unassignedSection = formatSection({
    sectionName: "No Associated Provider",
    providers: unassigned.subtotals,
  });

  const totalRow = formatCsvRow([
    "Total",
    "",
    "",
    formatCurrency(totals.totalInsurancePaymentAmount),
    formatCurrency(totals.totalPatientPaymentAmount),
    formatCurrency(totals.totalPaymentAmount),
  ]);

  return [columnTitles, ...sections, ...unassignedSection, totalRow];
};

export const useDownloadCollectedByAssociatedProviderCSV = ({
  selectedTimeSegment,
  providerSections,
  unassigned,
  totals,
}: {
  selectedTimeSegment: TimeSegment;
} & InputParams) => {
  const currentUrl = useCurrentUrl();

  return {
    downloadCSV: React.useCallback(() => {
      const docMetadata = getArchyCsvMetadata(currentUrl, selectedTimeSegment);
      const csvRows = [
        ...docMetadata.headers,
        ...formatProviderDataAsCsvUrl({
          providerSections,
          unassigned,
          totals,
        }),
      ];

      saveCsv(csvRows, `CollectedByAssociatedProvider_${docMetadata.fileNameTag}`);
    }, [selectedTimeSegment, providerSections, unassigned, totals, currentUrl]),
  };
};
