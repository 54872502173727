import React from "react";
import { RangeAdjuster } from "components/PatientProfile/Imaging/ImageEditor/Tools/RangeAdjuster";
import { ReactComponent as SharpenIcon } from "assets/icons/imaging/sharpen.svg";

type Props = {
  onChange: (value: number) => void;
  initialValue?: number;
};

export const SharpnessAdjust: React.FC<Props> = ({ onChange, initialValue }) => {
  const [sharpnessLevel, setSharpnessLevel] = React.useState(initialValue ?? 0);

  const _onChangeSharpness: React.ChangeEventHandler<HTMLInputElement> = (e) => {
    const value = e.target.valueAsNumber;

    setSharpnessLevel(value);
    onChange(value);
  };

  return (
    <RangeAdjuster
      defaultValue={sharpnessLevel}
      value={sharpnessLevel}
      min={0}
      tooltip="Sharpness"
      max={0.3}
      SvgIcon={SharpenIcon}
      step={0.01}
      onChange={_onChangeSharpness}
    />
  );
};
