import { FC, FormEvent, useState } from "react";
import { AutomationJourneyVO } from "@libs/api/generated-api";
import { phoneNumber, required } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";
import { previewSmsAutomation } from "api/communications/mutations";
import { handleError } from "utils/handleError";
import { SendTestModal } from "components/Communications/Automations/SendTestModal";
import { useCurrentUser } from "contexts/CurrentUserContext";

interface Props {
  onRequestClose: Func;
  journeyType: AutomationJourneyVO["type"];
  smsTemplate: string;
}

const schema = [
  { $v: required, $error: "Recipient is required" },
  { $v: phoneNumber, $error: "A valid phone number is required" },
];

export const SendSmsTestModal: FC<Props> = ({ onRequestClose, smsTemplate, journeyType }) => {
  const { practiceId } = useAccount();
  const currentUser = useCurrentUser();
  const [recipient, setRecipient] = useState(() => currentUser.phone ?? "");

  const [previewSmsAutomationMutation] = useApiMutations([previewSmsAutomation]);

  const validation = useValidation(recipient, schema);

  const handleSend = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validation.validate().$isValid) {
      previewSmsAutomationMutation.mutate(
        {
          practiceId,
          journeyType,
          data: { phone: recipient, smsTemplate },
        },
        {
          onSuccess: onRequestClose,
          onError: handleError,
        }
      );
    }
  };

  return (
    <SendTestModal
      title="Send SMS Test"
      onClose={onRequestClose}
      onSubmit={handleSend}
      isSaving={previewSmsAutomationMutation.isLoading}
    >
      <FormFieldPhoneInput
        label="Recipient"
        required
        value={recipient}
        error={validation.result.$error}
        placeholder="Enter phone number..."
        onValueChange={(newValue) => setRecipient(newValue)}
      />
    </SendTestModal>
  );
};
