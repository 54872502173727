import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import { ClaimVO, PatientInsuranceVO } from "@libs/api/generated-api";
import { humanizeConstant, sentenceCaseConstant } from "@libs/utils/casing";
import { isLeftISODateAfterOrEqualRightISODate, formatAsISODate, formatISODate } from "@libs/utils/date";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";

export const InsuranceOrdinalOption: FC<{
  disabled?: boolean;
  insuranceOrdinal: ClaimVO["insuranceOrdinal"];
}> = ({ disabled = false, insuranceOrdinal }) => (
  <FloatingTooltip
    content={
      disabled ? `Primary claim required before creating ${humanizeConstant(insuranceOrdinal)} claim` : ""
    }
  >
    <div>{sentenceCaseConstant(insuranceOrdinal)}</div>
  </FloatingTooltip>
);

export const LoadingOptions: FC<{ content: string }> = ({ content }) => (
  <div className="flex flex-col gap-y-1">
    <FormFieldLabel className="text-xs font-sansSemiBold" content={content} required />
    <div data-testid="loading-option-skeleton">
      <Skeleton className="h-8 w-full border rounded" />
    </div>
  </div>
);

export const PatientInsuranceOption: FC<{ patientInsurance: PatientInsuranceVO }> = ({
  patientInsurance,
}) => {
  return (
    <div className="flex flex-col text-xs">
      <div className="flex">
        <span>{patientInsurance.subscriber.carrier}</span>
        {patientInsurance.insuranceState === "ARCHIVED" ? (
          <>
            &nbsp;&bull;&nbsp;<span>archived</span>
          </>
        ) : null}
        {patientInsurance.expiryDate ? (
          <>
            &nbsp;&bull;&nbsp;
            <span>
              {`${
                isLeftISODateAfterOrEqualRightISODate(
                  patientInsurance.expiryDate,
                  formatAsISODate(new Date())
                )
                  ? "exp date"
                  : "expired"
              } ${formatISODate(patientInsurance.expiryDate)}`}
            </span>
          </>
        ) : null}
      </div>
      <div className="flex">
        <span>{sentenceCaseConstant(patientInsurance.insuranceOrdinal)}</span>&nbsp;&bull;&nbsp;
        <span>{patientInsurance.subscriber.externalMemberId ?? "SSN (Hidden)"}</span>
        &nbsp;&bull;&nbsp;
        <span>{`${patientInsurance.subscriber.firstName} ${patientInsurance.subscriber.lastName}`}</span>
      </div>
    </div>
  );
};
