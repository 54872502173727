import { FC, ReactNode } from "react";

export const FormSection: FC<{ title: ReactNode; description: ReactNode; children: ReactNode }> = ({
  children,
  description,
  title,
}) => (
  <div>
    <div className="font-sansSemiBold text-sm mb-1">{title}</div>
    <p className="text-xs mb-6">{description}</p>
    {children}
  </div>
);
