import { FC, useState, useMemo, useCallback } from "react";

import { DentalProcedureVO } from "@libs/api/generated-api";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { useApiQueries } from "@libs/hooks/useApiQueries";

import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { CharacterIcon } from "components/UI/CharacterIcon";
import {
  CategoryGrid,
  CategoryGridProps,
} from "components/Settings/Scheduling/Sections/ProcedureDurations/CategoryGrid";

import { getDentalProcedureCategories, getDentalProceduresQuery } from "api/charting/queries";

const CUSTOM = "Custom";

interface Props {
  onEditCustomProcedureCode: (procedure: DentalProcedureVO) => void;
  onEditProcedureCode: (procedure: DentalProcedureVO) => void;
}

export const ProcedureCodeCategories: FC<Props> = ({ onEditCustomProcedureCode, onEditProcedureCode }) => {
  const [selectedCategory, setSelectedCategory] = useState("");
  const [search, setSearch] = useState("");
  const { search: debouncedSearch } = useDebouncedSearch(search);

  const { practiceId } = useAccount();
  const [dentalProceduresQuery, dentalProcedureCategoriesQuery] = useApiQueries([
    getDentalProceduresQuery({ args: { practiceId } }),
    getDentalProcedureCategories(),
  ]);

  const filteredProcedures = useMemo(() => {
    return (
      dentalProceduresQuery.data?.filter(({ cdtCode, simpleName, name }) =>
        `${cdtCode} ${simpleName} ${name}`.toLowerCase().includes(debouncedSearch.toLowerCase())
      ) ?? []
    );
  }, [dentalProceduresQuery.data, debouncedSearch]);

  // group dental procedures by category and if available subcategory.
  const byCategory = useMemo(() => {
    const map: Record<string, Pick<CategoryGridProps, "procedures" | "subCategories" | "title"> | undefined> =
      {};

    for (const dp of filteredProcedures) {
      const existingCategory = map[dp.categoryType];

      map[dp.categoryType] = {
        title: dp.categoryType,
        // if the dental procedure doesn't have a subcategory
        // it gets added to the category procedures list
        procedures: dp.subCategory
          ? existingCategory?.procedures || []
          : [...(existingCategory?.procedures || []), dp],
        subCategories: {
          ...existingCategory?.subCategories,
          // if the dental procedure does have a subcategory
          // it gets added to the subcatgories procedures list
          ...(dp.subCategory
            ? {
                [dp.subCategory]: {
                  title: dp.subCategory,
                  procedures: [...(existingCategory?.subCategories[dp.subCategory]?.procedures || []), dp],
                },
              }
            : undefined),
        },
      };
    }

    // Ensure Custom always exists if there is no search string and the procedures have loaded.
    if (!map[CUSTOM] && !debouncedSearch.length && filteredProcedures.length) {
      map[CUSTOM] = {
        title: CUSTOM,
        procedures: [],
        subCategories: {},
      };
    }

    return map;
  }, [filteredProcedures, debouncedSearch.length]);

  const handleEditProcedure = useCallback(
    (procedure: DentalProcedureVO) => {
      procedure.categoryType === CUSTOM && procedure.practiceSpecific
        ? onEditCustomProcedureCode(procedure)
        : onEditProcedureCode(procedure);
    },
    [onEditCustomProcedureCode, onEditProcedureCode]
  );

  return (
    <div className="flex flex-col gap-y-3">
      <div className="flex items-stretch justify-between">
        <div className="flex items-center gap-x-2">
          <CharacterIcon size="sm" theme="magentaLight">
            A
          </CharacterIcon>
          <span className="text-xs">5 Mins of an Assistant</span>

          <CharacterIcon size="sm" theme="blueLight">
            P
          </CharacterIcon>
          <span className="text-xs">5 Mins of a Provider</span>
        </div>

        <FormFieldInput
          className="w-80"
          aria-label="Procedure Search"
          placeholder="Search CDT Code or Procedure Name"
          value={search}
          onChange={(e) => setSearch(e.target.value)}
          Icon={SearchIcon}
        />
      </div>

      {dentalProcedureCategoriesQuery.data?.map((category) => {
        const cat = byCategory[category];

        return cat ? (
          <CategoryGrid
            key={cat.title}
            title={cat.title}
            procedures={cat.procedures}
            subCategories={cat.subCategories}
            isOpen={category === selectedCategory}
            onToggleOpen={(newCategory) =>
              setSelectedCategory((last) => (last === newCategory ? "" : newCategory))
            }
            onEdit={handleEditProcedure}
          />
        ) : null;
      })}
    </div>
  );
};
