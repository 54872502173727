import { FC, PropsWithChildren, forwardRef } from "react";
import { ComponentPropsWithRef } from "@react-spring/web";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { flattenPages } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useAccount } from "@libs/contexts/AccountContext";
import { LayoutCard } from "@libs/components/UI/LayoutCard";

import { HeaderCell, Row, TableGrid } from "@libs/components/UI/GridTableComponents";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { usePathParams } from "hooks/usePathParams";

import { PatientBillingAppHistoryProvider } from "components/PatientProfile/Billing/PatientBillingLinksContex";
import { getWalletActivities } from "api/billing/queries";
import { PLACEHOLDER_WALLET_ID } from "components/PatientProfile/Billing/billingUtils";
import { handleError } from "utils/handleError";
import { WalletActivityRow } from "components/PatientProfile/Billing/WalletActivityRow";
import { getPatientSummary } from "api/patients/queries";
import { LedgerTabsRow } from "./LedgerTabsRow";

const cxStyle = {
  loadingCell: "col-span-5 place-self-center text-xs p-3",
};

const LOADING_MESSAGE = "Loading...";

export const WalletActivityPage: FC = () => {
  const { practiceId } = useAccount();
  const { patientId, walletUuid } = usePathParams("patientWalletActivity");
  const [patientQuery] = useApiQueries([getPatientSummary({ args: { patientId, practiceId } })]);
  const walletActivitiesQuery = useInfiniteApiQuery(
    getWalletActivities({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: 20,
        patientId,
        walletUuid,
      },
      queryOptions: { enabled: walletUuid !== PLACEHOLDER_WALLET_ID, onError: handleError },
    })
  );

  const infiniteScroll = useInfiniteScrollQuery({ infiniteQuery: walletActivitiesQuery });
  const walletActivities = flattenPages(walletActivitiesQuery.data) ?? [];

  return (
    <PatientBillingAppHistoryProvider name="patientWalletActivity">
      <LayoutCard className="relative flex flex-col w-full divide-y">
        <LedgerTabsRow patientId={patientId} practiceId={practiceId} selected="Wallet" />
        <div className="p-5 text-xs border-b">
          <QueryResult queries={[patientQuery]}>
            {`A summary of all pre-payments towards ${
              patientQuery.data?.name.firstName ?? ""
            }'s Wallet and the invoice payments using Wallet funds.`}
          </QueryResult>
        </div>

        <QueryResult queries={[walletActivitiesQuery]} loading={<LoadingOverlaySpinner />}>
          <WalletActivityContainer>
            <TableGrid columnWidths={["auto", "100px", "auto", "auto", "2fr", "1fr", "auto"]}>
              <Row role="rowheader">
                <HeaderCell />
                <HeaderCell>Date</HeaderCell>
                <HeaderCell>Type</HeaderCell>
                <HeaderCell>Method</HeaderCell>
                <HeaderCell>Description</HeaderCell>
                <HeaderCell>Notes</HeaderCell>
                <HeaderCell className="justify-end">Amount</HeaderCell>
              </Row>

              <ScrollableInfiniteQueryResult
                infiniteQuery={walletActivitiesQuery}
                scrollRef={infiniteScroll.scrollRef}
                loading={<div className={cxStyle.loadingCell}>{LOADING_MESSAGE}</div>}
                loadMore={LOADING_MESSAGE}
                loadMoreClassName={cxStyle.loadingCell}
              >
                {walletActivities.map((activity) => (
                  <WalletActivityRow key={activity.id} walletActivity={activity} />
                ))}
              </ScrollableInfiniteQueryResult>
            </TableGrid>
          </WalletActivityContainer>
        </QueryResult>
      </LayoutCard>
    </PatientBillingAppHistoryProvider>
  );
};

const WalletActivityContainer: FC<PropsWithChildren<ComponentPropsWithRef<"div">>> = forwardRef(
  ({ children }, ref) => {
    return (
      <PersistScrollPosition id="wallet-activity-page" ref={ref} className="flex flex-col overflow-y-auto">
        {children}
      </PersistScrollPosition>
    );
  }
);
