// Provides a formatter for dates, given a selected date resolution

import React from "react";
import { toMap } from "@libs/utils/array";
import { LongAgingOption } from "components/Dashboard/hooks/useLongAgingOptions";

// if concise is true, smaller date strings are formatted
export const useLongAgingDateFormatter = (recallOverdueOptions: LongAgingOption[]) => {
  return React.useCallback(
    (val: string) => {
      const mapOfOptions = toMap(recallOverdueOptions, "startDate");

      return mapOfOptions[val]?.shortDisplayLabel ?? "Unknown";
    },
    [recallOverdueOptions]
  );
};
