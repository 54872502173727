import React from "react";
import { TimeSeriesDataPoint } from "@libs/api/generated-api";
import { useInvoiceAgeOptions } from "components/Dashboard/OutstandingCollections/hooks/useInvoiceAgeOptions";

export const useAgingTimeseriesTemplate = () => {
  const invoiceAgeOptions = useInvoiceAgeOptions();

  return React.useMemo(() => {
    const timeline: TimeSeriesDataPoint[] = invoiceAgeOptions
      .filter(({ value }) => value >= 0)
      .map((item) => ({
        startDate: item.startDate,
        value: 0,
      }));

    return {
      insuranceOwedSubmitted: timeline,
      insuranceOwedUnsubmitted: timeline,
      patientOwedInvoiceCreated: timeline,
      patientOwedInvoiceNotCreated: timeline,
    };
  }, [invoiceAgeOptions]);
};
