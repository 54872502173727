import { useCallback, useEffect } from "react";

// Enables light or dark styles for the entire app. Used for imaging experiences
export const useAppTheme = (theme: "light" | "dark") => {
  const toggleClasses = useCallback((addDark: boolean) => {
    if (addDark) {
      document.body.classList.add("dark");
      document.body.classList.add("bg-black");
    } else {
      document.body.classList.remove("dark");
      document.body.classList.remove("bg-black");
    }
  }, []);

  useEffect(() => {
    toggleClasses(theme === "dark");

    return () => {
      toggleClasses(theme !== "dark");
    };
  }, [theme, toggleClasses]);
};
