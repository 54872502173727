import { FC } from "react";
import { CreateCustomAdjustmentTypeRequest, CustomAdjustmentTypeVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { FooterCell, HeaderCell, Row, TableGrid } from "@libs/components/UI/GridTableComponents";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { archiveAdjustmentType, createAdjustmentType } from "api/settings/payments/mutations";
import { handleError } from "utils/handleError";
import { useItemModal } from "hooks/useItemModal";
import { AddCustomAdjustmentModal } from "./AddCustomAdjustmentModal";
import { CustomAdjustmentRow } from "./CustomAdjustmentRow";

export const ADJUSTMENT_ENTRY_TYPE_TO_LABEL: Record<CustomAdjustmentTypeVO["entryType"], string> = {
  CREDIT: "Discount",
  DEBIT: "Additional Charge",
};

export const CustomAdjustmentsSettings: FC<{
  adjustments?: CustomAdjustmentTypeVO[];
}> = ({ adjustments }) => {
  const { practiceId } = useAccount();
  const addCustomAdjustmentModal = useBoolean(false);
  const deleteCustomAdjustmentConfirmationModal = useItemModal<CustomAdjustmentTypeVO>(null);

  const [
    { mutate: createAdjustmentTypeMutate, isLoading: isCreatingNewAdjustment },
    { mutate: archiveAdjustmentTypeMutate, isLoading: isArchiving },
  ] = useApiMutations([createAdjustmentType, archiveAdjustmentType]);

  const handleNewAdjustmentSave = (adjustment: CreateCustomAdjustmentTypeRequest) => {
    createAdjustmentTypeMutate(
      { practiceId, data: adjustment },
      { onError: handleError, onSuccess: addCustomAdjustmentModal.off }
    );
  };

  const handleDeleteCustomAdjustment = (adjustment: CustomAdjustmentTypeVO) => {
    archiveAdjustmentTypeMutate(
      { practiceId, adjustmentTypeId: adjustment.id },
      { onError: handleError, onSuccess: deleteCustomAdjustmentConfirmationModal.close }
    );
  };

  return (
    <div className="flex flex-col gap-y-4">
      <div className="text-sm font-sansSemiBold">Adjustments</div>
      <TableGrid
        className="border rounded"
        columnWidths={["minmax(max-content, 1fr)", "minmax(max-content, 2fr)", "auto"]}
      >
        {["Name", "Category", ""].map((colName) => (
          <HeaderCell key={colName} className="rounded-t" stickyTopClassName="-top-4">
            {colName}
          </HeaderCell>
        ))}
        {adjustments?.map((adjustment, index) => (
          <CustomAdjustmentRow
            key={adjustment.id}
            adjustment={adjustment}
            isLast={index === adjustments.length - 1}
            onArchive={() => deleteCustomAdjustmentConfirmationModal.open(adjustment)}
          />
        ))}
        <Row highlightOnHover={false}>
          <FooterCell className="col-span-3 rounded-b" stickyBottomClassName="-bottom-4">
            <Button theme="link" className="flex items-center gap-x-2" onClick={addCustomAdjustmentModal.on}>
              <AddIcon className="w-5 h-5" />
              Add Adjustment Type
            </Button>
          </FooterCell>
        </Row>
      </TableGrid>
      {addCustomAdjustmentModal.isOn && (
        <AddCustomAdjustmentModal
          onCancel={addCustomAdjustmentModal.off}
          onSave={handleNewAdjustmentSave}
          isCreating={isCreatingNewAdjustment}
        />
      )}
      {deleteCustomAdjustmentConfirmationModal.isOpen && (
        <ConfirmationModal
          onCancel={deleteCustomAdjustmentConfirmationModal.close}
          onConfirm={() => handleDeleteCustomAdjustment(deleteCustomAdjustmentConfirmationModal.item)}
          isConfirming={isArchiving}
          primaryText={`Are you sure you want to delete "${
            deleteCustomAdjustmentConfirmationModal.item.name
          }" (${ADJUSTMENT_ENTRY_TYPE_TO_LABEL[deleteCustomAdjustmentConfirmationModal.item.entryType]})?`}
          secondaryText="It will no longer be available for use, but it will still show on invoices it has been applied to prior to archival."
        />
      )}
    </div>
  );
};
