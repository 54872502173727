import React, { createContext, useContext } from "react";
import { asyncNoop, noop } from "@libs/utils/noop";
import { MedicalImageVO, MountVO } from "@libs/api/generated-api";
import {
  UseImageEditQueue,
  useImageEditQueue,
} from "components/PatientProfile/Imaging/MountRoute/hooks/useImageEditQueue";
import { useCameraCapture } from "components/ImageCapturing/useCameraCapture";
import {
  ImagingDeviceWithSettings,
  useImagingDevices,
} from "components/PatientProfile/Imaging/hooks/useImagingDevices";
import { useUpdateMountMetadata } from "components/PatientProfile/Imaging/MountRoute/hooks/useUpdateMountMetadata";

export interface MountContextValue {
  imageEditQueue: UseImageEditQueue;
  devices: ImagingDeviceWithSettings[];
  handleUpdateMountMetadata: (changes: Pick<MedicalImageVO, "assignedDate" | "sensor">) => void;
}

// Currently the mount context  just tracks whether the user is viewing archived or not.
const Context = createContext<MountContextValue>({
  imageEditQueue: {
    handleImageUpdate: asyncNoop,
    handleImageOriginChanged: noop,
    handleMountChanged: asyncNoop,
    isSavingMount: false,
    revertImage: asyncNoop,
    flushMountChanges: noop,
    debouncedImageChanges: noop as unknown as UseImageEditQueue["debouncedImageChanges"],
  },
  handleUpdateMountMetadata: noop,
  devices: [],
});

Context.displayName = "MountContext";
export const MountContext = Context;

export const useMountContext = () => useContext(MountContext);

export const MountContextProvider: React.FC<{
  children: React.ReactNode;
  imageMount: MountVO;
  patientId: number;
  showArchived: boolean;
}> = ({ children, imageMount, patientId, showArchived }) => {
  const imageEditQueue = useImageEditQueue({
    imageMount,
    patientId,
  });
  const { handleUpdateMountMetadata } = useUpdateMountMetadata({
    mount: imageMount,
    handleMountChanged: imageEditQueue.handleMountChanged,
    isViewingArchived: showArchived,
  });
  const cameraCapture = useCameraCapture();

  const { devices } = useImagingDevices(cameraCapture);

  const mountContextValue = React.useMemo(() => {
    return {
      imageEditQueue,
      devices,
      handleUpdateMountMetadata,
    };
  }, [devices, handleUpdateMountMetadata, imageEditQueue]);

  return <MountContext.Provider value={mountContextValue}>{children}</MountContext.Provider>;
};
