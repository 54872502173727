/* eslint-disable @typescript-eslint/naming-convention */

import {
  AppointmentBookingVO,
  ClaimVO,
  CollectedPaymentEntryVO,
  InvoiceVO,
  InsuranceAdjustmentEntryVO,
  JournalEventVO,
  ReportingRecallEntryVO,
  ReportingRecallByPatientEntryVO,
  Filter,
  ProviderRollupEntryVO,
} from "@libs/api/generated-api";

import { paramsError, parseEnum } from "@libs/router/url";

const ARRAY_DELIMITER = "|";
const VALUE_DELIMITER = ";";
const FILTER_DELIMITER = "~";
const OP_DELIMITER = "*";

export type FilteredProviderType = ProviderRollupEntryVO["jobCategory"];

// Represent filters on ids such as patientId, providerId, etc.
export const REPORTING_ID_FILTER_TYPES = [
  "dentalProcedureId",
  "providerId",
  "associatedProviderId",
  "patientId",
  "primarySubscriberPatientId",
  "insuranceCarrierId",
  "patientInsuranceId",
  "primaryInsuranceCarrierId",
  "nextAppointmentDentalProcedureId",
  "customAdjustmentTypeId",
] as const;

// Complete enumeration of acceptable filter types, see Filter interface in generated-api for complete documentation
export type ReportingFilterByIdType = (typeof REPORTING_ID_FILTER_TYPES)[number];

// Represent numeric filters such as currency or balance
export const REPORTING_NUMERIC_FILTER_TYPES = ["insuranceBalance", "uninvoicedBalance"] as const;
export type ReportingFilterNumericType = (typeof REPORTING_NUMERIC_FILTER_TYPES)[number];

export const REPORTING_BOOL_FILTER_TYPES = [
  "isInvoiced",
  "isScheduled",
  "isSubmitted",
  "hasNextAppointment",
  "hasFirstAppointment",
  "isHygiene",
  "isMasterTreatmentPlan",
] as const;

export type ReportingBooleanFilterType = (typeof REPORTING_BOOL_FILTER_TYPES)[number];
export type ReportingBooleanFilterValue = "true" | "false";

export type DashboardFilterVariant<FilterType extends string, FilterValue extends string | null> = {
  type: FilterType;
  values: FilterValue[];
  /** Operator to use when evaluating the values. Defaults to IN. */
  op?: Filter["op"];
};
export type DashboardNumericFilterVariant = DashboardFilterVariant<ReportingFilterNumericType, string>;
type DashboardIdFilter = DashboardFilterVariant<ReportingFilterByIdType, string | null>;
export type DashboardFilter =
  | DashboardIdFilter
  | DashboardNumericFilterVariant
  | DashboardFilterVariant<ReportingBooleanFilterType, string>
  | DashboardFilterVariant<ReportingBooleanFilterType, ReportingBooleanFilterValue>
  | DashboardFilterVariant<"claimState", ClaimVO["state"]>
  | DashboardFilterVariant<"payerType", CollectedPaymentEntryVO["payerType"]>
  | DashboardFilterVariant<"paymentMethod", CollectedPaymentEntryVO["paymentMethod"]>
  | DashboardFilterVariant<"invoiceState", InvoiceVO["state"]>
  | DashboardFilterVariant<"providerJobCategory", FilteredProviderType>
  | DashboardFilterVariant<"patientType", AppointmentBookingVO["patientType"]>
  | DashboardFilterVariant<"appointmentState", AppointmentBookingVO["appointmentState"]>
  | DashboardFilterVariant<"insuranceAdjustmentState", InsuranceAdjustmentEntryVO["insuranceAdjustmentState"]>
  | DashboardFilterVariant<"eventType", JournalEventVO["eventType"]>
  | DashboardFilterVariant<"patientStatus", ReportingRecallByPatientEntryVO["patientStatus"]>
  | DashboardFilterVariant<"recallType", ReportingRecallEntryVO["recallType"]>
  | DashboardFilterVariant<"treatmentPlanState", "INACTIVE" | "ACTIVE">
  | DashboardFilterVariant<"referredBy", string>;

const SERIALIZED_FILTER_BY_ID_TYPES = new Set(REPORTING_ID_FILTER_TYPES);

export const isIdFilter = (filter: DashboardFilter): filter is DashboardIdFilter =>
  SERIALIZED_FILTER_BY_ID_TYPES.has(filter.type as ReportingFilterByIdType);

const SERIALIZED_FILTER_NUMERIC_TYPES = new Set(REPORTING_NUMERIC_FILTER_TYPES);

const SERIALIZED_FILTER_BOOLEAN_TYPES = new Set(REPORTING_BOOL_FILTER_TYPES);

export const SerializedFilter = {
  get: (value: string) => {
    try {
      const parts = value.split(FILTER_DELIMITER);

      // eslint-disable-next-line complexity, @typescript-eslint/no-unsafe-return
      return parts.map((part) => {
        const [type, valueAndOperator] = part.split(VALUE_DELIMITER);
        const [valueString, op] = valueAndOperator.split(OP_DELIMITER);
        const values = valueString.split(ARRAY_DELIMITER);

        const isIdType = SERIALIZED_FILTER_BY_ID_TYPES.has(type as ReportingFilterByIdType);

        if (isIdType || SERIALIZED_FILTER_NUMERIC_TYPES.has(type as ReportingFilterNumericType)) {
          return {
            type: isIdType
              ? parseEnum(SERIALIZED_FILTER_BY_ID_TYPES, type)
              : parseEnum(SERIALIZED_FILTER_NUMERIC_TYPES, type),
            op,
            values: values.map((v) => {
              // A primary providerId of -1 is used to represent "No Provider"
              // option (in the case where procedures have been credited to
              // practice); otherwise, return null for a value of -1
              if (isIdType && type !== "providerId" && v === "-1") {
                return null;
              }

              return v;
            }),
          };
        } else if (SERIALIZED_FILTER_BOOLEAN_TYPES.has(type as ReportingBooleanFilterType)) {
          return {
            type: parseEnum(SERIALIZED_FILTER_BOOLEAN_TYPES, type),
            values: values as ReportingBooleanFilterValue[],
          };
        }

        switch (type) {
          case "providerJobCategory": {
            return {
              type,
              values: values as FilteredProviderType[],
            };
          }
          case "claimState": {
            return {
              type,
              values: values as JournalEventVO["eventType"][],
            };
          }
          case "eventType": {
            return {
              type,
              values: values as ClaimVO["state"][],
            };
          }
          case "payerType": {
            return {
              type,
              values: values as CollectedPaymentEntryVO["payerType"][],
            };
          }
          case "patientType": {
            return {
              type,
              values: values as AppointmentBookingVO["patientType"][],
            };
          }
          case "paymentMethod": {
            return {
              type,
              values: values as CollectedPaymentEntryVO["paymentMethod"][],
            };
          }
          case "invoiceState": {
            return {
              type: "invoiceState",
              values: values as InvoiceVO["state"][],
            };
          }
          case "appointmentState": {
            return {
              type,
              values: values as AppointmentBookingVO["appointmentState"][],
            };
          }
          case "recallType": {
            return {
              type,
              values: values as ReportingRecallEntryVO["recallType"][],
            };
          }
          case "insuranceAdjustmentState": {
            return {
              type,
              values: values as InsuranceAdjustmentEntryVO["insuranceAdjustmentState"][],
            };
          }
          case "patientStatus": {
            return {
              type,
              values: values as ReportingRecallByPatientEntryVO["patientStatus"][],
            };
          }
          case "treatmentPlanState": {
            return {
              type,
              values: values as ("INACTIVE" | "ACTIVE")[],
            };
          }
          case "referredBy": {
            return {
              type,
              values,
            };
          }
          default: {
            throw paramsError(`Unsupported filter type ${type}`);
          }
        }
      }) as DashboardFilter[];
    } catch {
      throw paramsError(`Error parsing filter value: ${value}`);
    }
  },
  set: (val: DashboardFilter[]) => {
    return val
      .map(({ type, values, op }) => {
        const stringValues = values as (string | null)[];

        const filter = `${type}${VALUE_DELIMITER}${stringValues
          .map((item) => (item === null ? "-1" : item))
          .join(ARRAY_DELIMITER)}`;

        if (op) {
          return `${filter}${OP_DELIMITER}${op}`;
        }

        return filter;
      })
      .join(FILTER_DELIMITER);
  },
};
