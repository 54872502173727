import { FC } from "react";
import { FormProcedureTableElementRequest } from "@libs/api/generated-api";
import { FormWrapper } from "components/Settings/Forms/FormWrapper";
import { EditElementFormProps } from "components/Settings/Forms/types";
import { ProcedureTableFormContent } from "components/Settings/Forms/ProcedureTable/FormContent";
import { useProcdureFormState } from "./schema";

export const ProcedureTableForm: FC<EditElementFormProps<FormProcedureTableElementRequest>> = ({
  element,
  isEditing,
  onInvalid,
  onDraftChange,
  onSecondaryClick,
  onSubmit,
}) => {
  const state = useProcdureFormState(element);
  const [formState] = state;
  const { draft } = formState;

  const handleSubmit = () => {
    onSubmit(draft);
  };

  return (
    <FormWrapper
      element={draft}
      isEditing={isEditing}
      onDraftChange={onDraftChange}
      onSubmit={handleSubmit}
      onCancel={onSecondaryClick}
      onInvalid={onInvalid}
      onValidate={() => true}
    >
      <ProcedureTableFormContent formState={state} />
    </FormWrapper>
  );
};
