import React from "react";
import { addBreadcrumb } from "@sentry/react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getIsLoadingMore } from "@libs/utils/queries";
import { ApiErrorResponse } from "@libs/@types/api";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { handleError } from "utils/handleError";

export const useDownloadQueryPages = <T>(infiniteQuery: UseInfiniteApiQueryResult<T>) => {
  // While downloading, a user may mutate the infinite query. Thus we need to keep
  const downloading = useBoolean(false);
  const downloadCallback = React.useRef<{
    resolve: (value: NonNullable<UseInfiniteApiQueryResult<T>["data"]>) => void;
    reject: (reason?: unknown) => void;
  } | null>(null);
  const isFetching = infiniteQuery.isFetching;
  const needsFetch = getIsLoadingMore(infiniteQuery);
  const fetchNextPage = infiniteQuery.fetchNextPage;
  const currentlyDownloading = downloading.isOn;
  const beginDownload = downloading.on;
  const stopDownload = downloading.off;

  React.useEffect(() => {
    if (currentlyDownloading && !isFetching) {
      if (needsFetch) {
        fetchNextPage().catch((reason: ApiErrorResponse) => {
          stopDownload();

          const [{ apiResponse }] = infiniteQuery.data?.pages ?? [{}];

          addBreadcrumb({
            level: "info",
            category: "archy",
            message: "Export failed",
            data: {
              totalElements: apiResponse?.data.pageDetails?.totalElements,
            },
          });
          handleError(reason);
          downloadCallback.current?.reject(reason);
          downloadCallback.current = null;
        });
      } else if (!infiniteQuery.isStale) {
        stopDownload();

        if (infiniteQuery.data) {
          downloadCallback.current?.resolve(infiniteQuery.data);
          downloadCallback.current = null;
        }
      }
    }
  }, [
    fetchNextPage,
    infiniteQuery.data,
    currentlyDownloading,
    needsFetch,
    stopDownload,
    infiniteQuery.isStale,
    isFetching,
  ]);
  React.useEffect(() => {
    return () => {
      downloadCallback.current?.reject("Component unmounted");
      downloadCallback.current = null;
    };
  }, []);

  return {
    isDownloading: currentlyDownloading,
    startDownload: React.useCallback(() => {
      const result = new Promise<NonNullable<UseInfiniteApiQueryResult<T>["data"]>>((resolve, reject) => {
        if (downloadCallback.current) {
          downloadCallback.current.reject("New download started");
        }

        downloadCallback.current = {
          resolve,
          reject,
        };
        beginDownload();
      });

      return result;
    }, [beginDownload]),
  };
};
