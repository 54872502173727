import { InboundReferralVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { formatCurrency } from "@libs/utils/currency";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { EMPTY_CELL, Row } from "@libs/components/UI/GridTableComponents";
import { PatientNameLink } from "components/Dashboard/Tables/PatientNameLink";
import { paths } from "utils/routing/paths";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";

type Props = {
  data: InboundReferralVO;
};

export const ReferralsTableRow: React.FC<Props> = ({ data }) => {
  const cellProps = { to: paths.patient({ patientId: data.patient.id }) };

  return (
    <Row>
      <DashboardLinkCell {...cellProps}>
        <PatientNameLink patient={data.patient} />
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        {data.insuranceCarrier.name === "None" ? EMPTY_CELL : data.insuranceCarrier.name}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        {data.firstAppointmentDate ? formatISODate(data.firstAppointmentDate) : EMPTY_CELL}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{data.referredBy}</DashboardLinkCell>
      <DashboardLinkCell className="overflow-hidden" {...cellProps}>
        {data.referralInformation ? (
          <FloatingTooltip content={data.referralInformation} placement="top">
            <div className="block truncate">{data.referralInformation}</div>
          </FloatingTooltip>
        ) : (
          EMPTY_CELL
        )}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        {data.firstAppointmentDate ? formatCurrency(data.netProduction) : EMPTY_CELL}
      </DashboardLinkCell>
    </Row>
  );
};
