import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { useQueryParams } from "hooks/useQueryParams";
import { getTimeSeriesQuery } from "api/reporting/queries";
import { getClaimInsuranceCarriersQuery } from "api/patientInsurance/queries";
import { getAdjustmentTypesForPractice } from "api/settings/payments/queries";

export const useSelectedTimeWindowQueries = () => {
  const { query } = useQueryParams("dashboardAdjustments");
  const { practiceId } = useAccount();

  const { selectedTimeSegment, selectedTimeSegmentParams, invalidTimeSeriesParamsMessage, timeSeriesParams } =
    useTimeSeriesPageSelections(query);
  const selectedTimeWindowParams = {
    resolution: query.resolution,
    ...selectedTimeSegmentParams,
  };
  const [
    carrierAdjustmentsForSelectedTimePeriodQuery,
    collectionAdjustmentsForSelectedTimePeriodQuery,
    carriersQuery,
    adjustmentTypesQuery,
  ] = useApiQueries([
    getTimeSeriesQuery({
      args: {
        practiceId,
        data: {
          ...selectedTimeWindowParams,
          filters: [],
          metric: "insuranceAdjustmentAmount",
          groupBy: "insuranceCarrierId",
        },
      },
    }),
    getTimeSeriesQuery({
      args: {
        practiceId,
        data: {
          ...selectedTimeWindowParams,
          filters: [],
          metric: "collectionAdjustmentAmount",
          groupBy: "customAdjustmentTypeId",
        },
      },
    }),
    getClaimInsuranceCarriersQuery({ args: { practiceId } }),
    getAdjustmentTypesForPractice({ args: { practiceId, query: { hideArchived: false } } }),
  ]);

  return {
    carrierAdjustmentsForSelectedTimePeriodQuery,
    collectionAdjustmentsForSelectedTimePeriodQuery,
    carriersQuery,
    adjustmentTypesQuery,
    selectedTimeWindowParams,
    selectedTimeSegment,
    invalidTimeSeriesParamsMessage,
    timeSeriesParams,
  };
};
