import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";

interface Props {
  formId?: string;
  disableNext?: boolean;
  isLoading?: boolean;
  onBack: Func;
  onNext?: Func;
  primaryButtonLabel?: string;
  secondaryButtonLabel?: string;
}

export const InsurancePlanDetailsFooter: React.FC<Props> = ({
  formId,
  disableNext = false,
  isLoading = false,
  onBack,
  onNext,
  primaryButtonLabel = "Save",
  secondaryButtonLabel = "Back",
}) => {
  return (
    <div className="flex items-center justify-center gap-x-2">
      <Button className="min-w-button" disabled={isLoading} onClick={onBack} theme="secondary">
        {secondaryButtonLabel}
      </Button>
      {onNext ? (
        <Button className="min-w-button" disabled={disableNext} onClick={onNext} theme="primary">
          {primaryButtonLabel}
        </Button>
      ) : (
        <AsyncButton
          className="min-w-button"
          disabled={isLoading}
          form={formId}
          isLoading={isLoading}
          theme="primary"
          type="submit"
        >
          {primaryButtonLabel}
        </AsyncButton>
      )}
    </div>
  );
};
