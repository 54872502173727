import { FormFieldInput } from "@libs/components/UI/FormFieldInput";

interface Props {
  allowInbound: boolean;
  extension: string;
}

export const EXTENSION_SELECTION_DIRECTIONS =
  "Enable the extension that should be used to trigger inbound call popovers and place outbound calls on this computer.";

export const Extensions: React.FC<Props> = ({ allowInbound, extension }) => {
  return (
    <div className="flex flex-col gap-y-4 text-sm">
      <div className="font-sansSemiBold">Archy and Mango are connected</div>
      <div>
        {EXTENSION_SELECTION_DIRECTIONS}
        <br />
        <span className="font-sansSemiBold">Note:</span>&nbsp;These settings only apply to this computer.
        Please visit this page from other computers to configure them as well.
      </div>
      <div className="grid grid-cols-2 max-w-md gap-x-4">
        <FormFieldInput edit={false} label="Extension" value={extension} />
        <FormFieldInput
          edit={false}
          label="Inbound Popups"
          value={extension === "None" ? "-" : allowInbound ? "Allowed" : "Not Allowed"}
        />
      </div>
    </div>
  );
};
