import { useState } from "react";
import { Button } from "@libs/components/UI/Button";
import { SurfaceSelector } from "components/Charting/SurfaceSelector";
import { Surface, SurfaceSelection, surfacesToSelector } from "components/Charting/toothSurfaces";

interface Props {
  surfaces: Surface[];
  onApply: (surfaceSelection: SurfaceSelection) => void;
  className?: string;
}

export const ToothConditionSurfaceMenu: React.FC<Props> = ({ surfaces, onApply, className }) => {
  const [selection, setSelection] = useState(() => surfacesToSelector(surfaces));

  return (
    <div className={className}>
      <h6 className="text-xs text-center whitespace-nowrap">Select Surface</h6>
      <SurfaceSelector
        selection={selection}
        selectionType="multi"
        className="w-20 mx-auto mt-8 mb-9"
        onUpdate={setSelection}
      />
      <Button
        type="button"
        size="small"
        className="min-w-button mx-auto block"
        onClick={() => {
          onApply(selection);
        }}
      >
        Apply
      </Button>
    </div>
  );
};
