import React from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { ByPatientTableRoute } from "components/Dashboard/PaymentCollected/ByPatientTableRoute";
import { PaymentCollectedChart } from "components/Dashboard/PaymentCollected/PaymentCollectedChart";
import { ByPaymentMethodTableRoute } from "components/Dashboard/PaymentCollected/ByPaymentMethodTableRoute";
import { useQueryParams } from "hooks/useQueryParams";
import { DashboardLayout } from "components/Dashboard/DashboardLayout";
import { PaymentCollectedQuery } from "utils/routing/dashboard";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { getTimeSeriesQuery } from "api/reporting/queries";
import { DashboardPageHeader } from "components/Dashboard/DashboardPageHeader";
import { TableRoutes } from "components/Dashboard/TableRoutes";
import { ByProviderTableRoute } from "components/Dashboard/PaymentCollected/ByProviderTableRoute";
import { ByAssociatedProviderTableRoute } from "components/Dashboard/PaymentCollected/ByAssociatedProviderTableRoute";

const TABLE_ROUTES = [
  {
    path: "payment-methods",
    element: <ByPaymentMethodTableRoute />,
  },
  {
    path: "patients",
    element: <ByPatientTableRoute />,
  },
  {
    path: "providers",
    element: <ByProviderTableRoute />,
  },
  {
    path: "associated-provider",
    element: <ByAssociatedProviderTableRoute />,
  },
];

export const PaymentCollectedRoute: React.FC = () => {
  const { query, updateQuery } = useQueryParams("dashboardPaymentCollected");

  const dateWindow = useTimeSeriesPageSelections(query);
  const { timeSeriesParams } = dateWindow;

  const { practiceId } = useAccount();
  const [payerQuery] = useApiQueries([
    getTimeSeriesQuery({
      args: {
        practiceId,
        data: {
          resolution: query.resolution,
          filters: [],
          ...timeSeriesParams,
          metric: "paymentAmount",
          groupBy: "payerType",
        },
      },
    }),
  ]);

  const handleRouteStateChange = React.useCallback(
    (updates: Partial<PaymentCollectedQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );

  return (
    <DashboardLayout
      className="min-w-[1400px]"
      contentClassName="flex"
      header={
        <>
          <DashboardPageHeader
            question="payment-collected"
            focusDate={query.focusDate}
            resolution={query.resolution}
            dateWindow={dateWindow}
            chartDisplay={query.chartDisplay}
            onUpdateParams={handleRouteStateChange}
          />

          {query["table.fullScreen"] ? null : (
            <PaymentCollectedChart
              resolution={query.resolution}
              isLoading={payerQuery.isLoading}
              payerData={payerQuery.data}
              onUpdateParams={handleRouteStateChange}
              focusDate={query.focusDate}
              dateWindow={dateWindow}
              chartDisplay={query.chartDisplay}
            />
          )}
        </>
      }
    >
      <TableRoutes routes={TABLE_ROUTES} />
    </DashboardLayout>
  );
};
