import React from "react";
import { ProductionByProviderComboVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { Row } from "@libs/components/UI/GridTableComponents";

import { Cell } from "components/Dashboard/Tables/Cell";
import { SectionHeader } from "components/Dashboard/Tables/SectionHeader";
import { SectionFooter } from "components/Dashboard/Tables/SectionFooter";

type Props = {
  sectionName: string;
  total: number;
  dentists: ProductionByProviderComboVO[];
  last?: boolean;
};
export const ProductionByHygienistTableSection: React.FC<Props> = ({ dentists, sectionName, total }) => {
  return (
    <>
      <SectionHeader
        className={`
          flex
          items-center
          gap-4
          col-span-full
          text-xs
          font-sansSemiBold
        `}
      >
        {sectionName}
      </SectionHeader>
      {dentists.map((row, index) => {
        const cellProps = { last: index === dentists.length - 1 };

        return (
          <Row key={row.dentistName.id}>
            <Cell {...cellProps} />
            <Cell {...cellProps}>{row.dentistName.fullDisplayName}</Cell>
            <Cell {...cellProps} align="right">
              {row.patientCount}
            </Cell>
            <Cell {...cellProps} align="right">
              {formatCurrency(row.productionPerPatient)}
            </Cell>
            <Cell {...cellProps} align="right">
              {formatCurrency(row.totalProducedAmount)}
            </Cell>
          </Row>
        );
      })}
      <Row>
        <SectionFooter />
        <SectionFooter>{sectionName} Totals</SectionFooter>
        <SectionFooter className="col-span-2" />

        <SectionFooter align="right">{formatCurrency(total)}</SectionFooter>
      </Row>
    </>
  );
};
