import { useEffect, useRef } from "react";
import { PaymentVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import {
  isPaymentStateFailed,
  isPaymentStateFinal,
  isPaymentStateSuccessful,
} from "components/PatientProfile/Billing/billingUtils";
import { getPayment } from "api/billing/queries";

export const usePaymentPolling = ({
  practiceId,
  paymentUuid,
  onSuccess,
  onError,
}: {
  practiceId: number;
  paymentUuid?: PaymentVO["uuid"];
  onSuccess: Func;
  onError: (paymentGatewayError?: string) => void;
}) => {
  const [{ data: paymentData }] = useApiQueries([
    getPayment({
      args: { paymentUuid: paymentUuid as PaymentVO["uuid"], practiceId },
      queryOptions: { enabled: paymentUuid != null },
    }),
  ]);

  const completedPayments = useRef(new Set<PaymentVO["uuid"]>());

  useEffect(() => {
    if (
      !paymentUuid ||
      !paymentData ||
      !isPaymentStateFinal(paymentData.state) ||
      // Prevent calling onSuccess/onError multiple times after the payment is finalized.
      completedPayments.current.has(paymentUuid)
    ) {
      return;
    }

    completedPayments.current.add(paymentUuid);

    if (isPaymentStateSuccessful(paymentData.state)) {
      onSuccess();
    } else if (isPaymentStateFailed(paymentData.state)) {
      onError(paymentData.gatewayErrorMessage);
    }
  }, [onError, onSuccess, paymentData, paymentUuid]);

  const paymentState = paymentData?.state ?? "CREATED";

  return {
    isPolling: paymentUuid == null ? false : !isPaymentStateFinal(paymentState),
    paymentState: paymentData?.state ?? "CREATED",
  };
};
