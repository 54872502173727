import { FC } from "react";
import { AppointmentRequest } from "@libs/api/generated-api";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";

interface Props {
  data: AppointmentRequest;
  errorMessage: string;
  isSaving: boolean;
  onCancel: Func;
  onConfirm: (data: AppointmentRequest) => void;
}

export const getTemplateData = ({
  errorMessage,
  data,
  onConfirm,
}: Pick<Props, "errorMessage" | "data" | "onConfirm">) => {
  return {
    handleConfirm: () => onConfirm(data),
    errors: errorMessage.split("\n").slice(1),
  };
};

export const BenefitLimitationIgnoreConfirmationModal: FC<Props> = ({
  data,
  errorMessage,
  isSaving,
  onCancel,
  onConfirm,
}) => {
  const { handleConfirm, errors } = getTemplateData({ data, onConfirm, errorMessage });

  return (
    <ConfirmationModal
      primaryText="Procedure Benefit Limitation Exceeded"
      secondaryText={
        <div className="space-y-3 text-left pt-4">
          <p>This appointment has benefit limitation conflicts for the following procedures:</p>
          <ul className="list-disc list-inside">
            {errors.map((error, index) => (
              <li key={index}>{error}</li>
            ))}
          </ul>
          <p>Do you to still want to save?</p>
        </div>
      }
      isConfirming={isSaving}
      onCancel={onCancel}
      onConfirm={handleConfirm}
    />
  );
};
