import { FamilyMemberVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useFamilyRelations } from "@libs/hooks/useRelations";
import { Icon } from "@libs/components/UI/Icon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as LinkIcon } from "@libs/assets/icons/link.svg";
import { ReactComponent as UnlinkIcon } from "@libs/assets/icons/unlink.svg";
import { ReactComponent as CheckIcon } from "@libs/assets/icons/check.svg";
import { ButtonCell } from "@libs/components/UI/GridTableComponents";
import { cxStyles } from "components/PatientProfile/PatientOverview/FamilyMembers/FamilyMembersTableRow";

interface Props {
  familyMember: FamilyMemberVO;
  isLastRow: boolean;
  isLinked: boolean;
  isSelf: boolean;
  onUnlink: () => void;
  onUpdateRelation: (relation: FamilyMemberVO["relation"]) => void;
  patientFirstName: string;
}

export const FamilyMemebersSelectRelationsCell: React.FC<Props> = ({
  familyMember,
  isLastRow,
  isLinked,
  isSelf,
  onUnlink,
  onUpdateRelation,
  patientFirstName,
}) => {
  const familyRelations = useFamilyRelations();
  const menu = useBoolean(false);

  return (
    <div>
      <ButtonMenu
        isOpen={menu.isOn}
        shadow="heavy"
        menuContent={
          <div className="w-64 text-sm">
            <div className="text-xs font-sansSemiBold p-3">{`Relationship to ${patientFirstName}`}</div>
            {familyRelations.map((relation) => (
              <button
                className={`
                  flex
                  items-center
                  justify-between
                  w-full
                  p-3
                  hover:bg-slate-100
                `}
                key={relation.value}
                onClick={() => {
                  onUpdateRelation(relation.value);
                  menu.off();
                }}
                type="button"
              >
                {relation.label}
                {relation.value === familyMember.relation && <Icon SvgIcon={CheckIcon} size="xs" />}
              </button>
            ))}
            {isLinked && (
              <button
                className={`
                  flex
                  items-center
                  w-full
                  p-3
                  gap-x-1.5
                  border-t
                  border-greyLighter
                  hover:bg-slate-100
                `}
                data-testid="unlink-menu-option"
                onClick={() => {
                  onUnlink();
                  menu.off();
                }}
                type="button"
              >
                <Icon SvgIcon={UnlinkIcon} size="sm" />
                Unlink
              </button>
            )}
          </div>
        }
        onRequestClose={menu.off}
        onRequestOpen={menu.on}
      >
        {(props) => (
          <ButtonCell
            border={!isLastRow}
            className={cx("pl-3 pr-5", cxStyles.cell, cxStyles.cellYPadding)}
            data-testid={`${familyMember.name.shortDisplayName}-link-icon`}
            disabled={isSelf}
            hideHover
            onClick={menu.on}
            {...props}
          >
            <Icon
              disabled={isLinked && isSelf}
              SvgIcon={isLinked ? UnlinkIcon : LinkIcon}
              size="sm"
              theme="primary"
            />
          </ButtonCell>
        )}
      </ButtonMenu>
    </div>
  );
};
