import { FC, useCallback, useMemo } from "react";

import { DosespotEntityVO } from "@libs/api/generated-api";
import { ReactComponent as DoseSpotIcon } from "@libs/assets/icons/dosespot.svg";
import { ReactComponent as CustomerChatIcon } from "@libs/assets/icons/customer-chat.svg";
import { useBoolean } from "@libs/hooks/useBoolean";
import electronicPrescribingImage from "assets/images/electronic-prescribing.svg";

import { SettingsHeroContainer, SettingsHeroContent } from "components/Settings/SettingsHeroLayout";
import { IconCard } from "components/UI/IconCard";
import { MailToLink } from "components/UI/MailToLink";
import { Divider } from "components/UI/Divider";

import { DoseSpotOnboardingStatus } from "components/Erx/DoseSpotOnboardingStatus";
import { PrescribersTable } from "components/Erx/PrescribersTable";
import { PrescriberFlyover } from "components/Erx/PrescriberFlyover";
import { usePracticeLogger } from "components/Main/PracticeSegmentContext";

interface Props {
  prescribers: DosespotEntityVO[];
  isActive: boolean;
}

export const ElectronicPrescribing: FC<Props> = ({ prescribers, isActive }) => {
  const prescriberFlyover = useBoolean(false);
  const { track } = usePracticeLogger();

  const canProxy = useMemo(
    () => prescribers.some(({ type, isConfirmed }) => type === "EMPLOYEE" && isConfirmed),
    [prescribers]
  );

  const handleClinicianCreated = useCallback(() => {
    if (!isActive) {
      // Practice was not active, now they are after the first clinician is created
      track({ event: "DoseSpot Onboarding Complete", domains: ["Employees"] });
    }
  }, [isActive, track]);

  return (
    <SettingsHeroContent className="flex flex-col gap-y-6">
      <SettingsHeroContainer
        image={
          <img
            src={electronicPrescribingImage}
            alt="Device with healthcare professional and prescription bottle"
          />
        }
      >
        <DoseSpotOnboardingStatus
          status={isActive ? "ACTIVE" : "INACTIVE"}
          onAddProvider={prescriberFlyover.on}
        />
      </SettingsHeroContainer>

      <div className="flex items-stretch gap-x-3">
        <IconCard
          className="flex-1"
          SvgIcon={DoseSpotIcon}
          title="What is DoseSpot?"
          description="DoseSpot is ePrescription software enabling clinicians to electronically write and transmit prescriptions to pharmacies."
          layout="stack"
          padding="sm"
        />
        <IconCard
          className="flex-1"
          SvgIcon={CustomerChatIcon}
          title="Need Help?"
          description={
            <span>
              If you are having trouble setting up DoseSpot, please contact{" "}
              <MailToLink email="support@archy.com">Archy Support</MailToLink>.
            </span>
          }
          layout="stack"
          padding="sm"
        />
      </div>

      <Divider className="border-dashed" />

      <PrescribersTable prescribers={prescribers} onAddPrescriber={prescriberFlyover.on} />

      {prescriberFlyover.isOn ? (
        <PrescriberFlyover
          prescribers={prescribers}
          canProxy={canProxy}
          onClose={prescriberFlyover.off}
          onClinicianCreated={handleClinicianCreated}
        />
      ) : null}
    </SettingsHeroContent>
  );
};
