import { useBoolean } from "@libs/hooks/useBoolean";
import React from "react";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as FilterIcon } from "@libs/assets/icons/adjust-filled.svg";
import { ReactComponent as ApertureIcon } from "@libs/assets/icons/lens.svg";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ToolbarButton } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ToolbarButton";
import { ToolBoat } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/ToolBoat";

import { SharpnessAdjust } from "components/PatientProfile/Imaging/ImageEditor/Tools/SharpnessAdjust";
import {
  BrightnessContrastAdjust,
  useBrightnessContrastMouseEvents,
} from "components/PatientProfile/Imaging/ImageEditor/Tools/BrightnessContrastAdjust";
import { useImageEditorContext } from "components/PatientProfile/Imaging/ImageEditor/ImageEditorContext";
import { LensesMenu } from "components/PatientProfile/Imaging/ImageEditor/Tools/LensesMenu";
import { useToggleImageEditorLayers } from "components/PatientProfile/Imaging/ImageEditor/Tools/useToggleImageEditorLayers";

export const FiltersToolboat: React.FC<{
  onMenuToggled?: (open: boolean) => void;
  disabledMessage?: string;
}> = ({ onMenuToggled, disabledMessage }) => {
  const {
    editor,
    drawSettings,
    setDrawSettings,
    handleImageUpdate,
    imageSelected,
    lensApplied,
    setLensApplied,
  } = useImageEditorContext();
  const { toggleLayer } = useToggleImageEditorLayers();
  const sharedProps = { disabled: !imageSelected };
  const filterMenuOpen = useBoolean(false);
  const lensesMenuOpen = useBoolean(false);

  const onChangeSharpness = (val: number) => {
    const canvas = editor.current?.getInstance();

    if (canvas && imageSelected) {
      toggleLayer("filters", true);
      handleImageUpdate(imageSelected, {
        filters: {
          sharpness: val,
        },
      });
    }
  };

  const contrastAdjustProps = useBrightnessContrastMouseEvents({
    initialBrightness: imageSelected?.filters?.brightness,
    initialContrast: imageSelected?.filters?.contrast,
  });
  const lensToggled = lensApplied !== "none" && Boolean(lensApplied);

  return (
    <ToolBoat>
      <ButtonMenu
        menuContent={
          imageSelected && (
            <div className="flex flex-col gap-2 px-4 py-2">
              <SharpnessAdjust onChange={onChangeSharpness} initialValue={imageSelected.filters?.sharpness} />
              <BrightnessContrastAdjust {...contrastAdjustProps} />
              <FloatingTooltip
                content="Click and drag on the image to adjust brightness and contrast in each dimension."
                theme="MEDIUM"
              >
                <div className="flex gap-2 cursor-pointer items-center text-sm mt-1">
                  <Icon SvgIcon={InfoIcon} /> <p>Filter Tips</p>
                </div>
              </FloatingTooltip>
            </div>
          )
        }
        placement="right-end"
        isOpen={filterMenuOpen.isOn}
        onRequestClose={() => {
          onMenuToggled?.(false);
          filterMenuOpen.off();
        }}
        onRequestOpen={() => {
          if (drawSettings.mouseMode !== "filters") {
            filterMenuOpen.on();
          }

          lensesMenuOpen.off();
          onMenuToggled?.(true);
          setLensApplied(undefined);
        }}
      >
        {(props) => (
          <ToolbarButton
            {...props}
            {...sharedProps}
            tooltip={disabledMessage ?? "Toggle Filters (W)"}
            toggled={drawSettings.mouseMode === "filters"}
            onClick={() => {
              setDrawSettings((prior) => ({
                ...prior,
                mouseMode: prior.mouseMode === "filters" ? undefined : "filters",
              }));
            }}
            SvgIcon={FilterIcon}
            size="md"
          />
        )}
      </ButtonMenu>
      <ButtonMenu
        menuContent={<LensesMenu />}
        placement="right-end"
        isOpen={lensesMenuOpen.isOn}
        onRequestClose={() => {
          if (lensApplied === "none") {
            setLensApplied(undefined);
          }

          onMenuToggled?.(false);
          lensesMenuOpen.off();
        }}
        onRequestOpen={() => {
          lensesMenuOpen.on();
          onMenuToggled?.(true);
        }}
      >
        {(props) => (
          <ToolbarButton
            {...props}
            onClick={() => {
              if (lensesMenuOpen.isOff && lensToggled) {
                setLensApplied(undefined);
                lensesMenuOpen.off();
              }
            }}
            toggled={lensToggled}
            {...sharedProps}
            tooltip={disabledMessage ?? "Toggle Lenses"}
            SvgIcon={ApertureIcon}
            size="md"
          />
        )}
      </ButtonMenu>
    </ToolBoat>
  );
};
