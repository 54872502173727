import { FC, useMemo } from "react";
import { PracticeInfoVO } from "@libs/api/generated-api";
import { FieldSchemaValidation } from "@libs/hooks/useValidation";
import { formatAddress } from "@libs/utils/address";

import { QueryResult } from "@libs/components/UI/QueryResult";
import { FilteredTemplateVariablesQuery } from "components/Communications/types";
import { EmailMessageEditor } from "components/Communications/Automations/EmailMessageEditor";
import { EmailSubjectEditor } from "components/Communications/Automations/EmailSubjectEditor";

interface Props {
  emailTemplate: string | undefined;
  subject: string | undefined;
  contentTemplateVariablesQuery: FilteredTemplateVariablesQuery;
  subjectTemplateVariablesQuery: FilteredTemplateVariablesQuery;
  onTemplateChange: (emailTemplate: string) => void;
  onSubjectChange: (subject: string) => void;
  validationResult: {
    emailTemplate: FieldSchemaValidation;
    subject: FieldSchemaValidation;
  };
  practice: PracticeInfoVO;
}

export const EmailMessage: FC<Props> = ({
  emailTemplate,
  subject,
  contentTemplateVariablesQuery,
  subjectTemplateVariablesQuery,
  onTemplateChange,
  onSubjectChange,
  validationResult,
  practice,
}) => {
  const formattedAddress = useMemo(() => formatAddress(practice.addressDetails), [practice.addressDetails]);

  return (
    <div className="flex flex-col items-center gap-y-3 w-full max-h-full">
      <div
        className={`
          bg-white
          rounded
          max-w-xl
          w-full
          drop-shadow-2xl
          flex
          flex-col
          min-h-80
        `}
      >
        <div className="px-3 pt-3 border-b border-b-slate-200 h-11">
          <QueryResult loading={<div />} queries={[subjectTemplateVariablesQuery]}>
            <EmailSubjectEditor
              subject={subject}
              templateVariables={subjectTemplateVariablesQuery.data}
              onSubjectChange={onSubjectChange}
              error={validationResult.subject.$error}
            />
          </QueryResult>
        </div>
        <div className="p-6 min-h-0 flex-1 flex flex-col">
          <div className="flex-none pb-6">
            <img src={practice.logo?.url} alt={`${practice.name} Logo`} className="h-14" />
          </div>
          <div className="flex-1 min-h-0 pb-6">
            <QueryResult loading={<div />} queries={[contentTemplateVariablesQuery]}>
              <EmailMessageEditor
                emailTemplate={emailTemplate}
                templateVariables={contentTemplateVariablesQuery.data}
                onTemplateChange={onTemplateChange}
                error={validationResult.emailTemplate.$error}
              />
            </QueryResult>
          </div>
          <div
            className={`
              text-xs
              flex
              flex-col
              gap-y-0.5
              flex-none
              border-t
              border-t-slate-200
              pt-6
            `}
          >
            <div className="font-sansSemiBold">{practice.name}</div>
            <div>{formattedAddress.lineOne}</div>
            <div>{formattedAddress.lineTwo}</div>
            <div className="text-primaryTheme">Unsubscribe</div>
          </div>
        </div>
      </div>
      <div
        className={`
          text-slate-500
          text-xs
          flex
          items-center
          justify-between
          w-full
          max-w-xl
        `}
      >
        <p>Type a &apos;/&apos; to insert things like patient names</p>
      </div>
    </div>
  );
};
