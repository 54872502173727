import { FC, PropsWithChildren } from "react";
import { Panel } from "@libs/components/UI/Panel";
import DoseSpotLogo from "assets/images/logo-dosespot.svg";

export const DoseSpotPanel: FC<PropsWithChildren> = ({ children }) => {
  return (
    <Panel
      title={
        <div className="flex items-center gap-x-6">
          <div>eRx</div>
          <div className="text-xs font-sans flex items-center gap-x-1">
            <div className="min-w-fit">Powered by</div>
            <img alt="DoseSpot Logo" className="min-w-fit relative top-px" src={DoseSpotLogo} />
          </div>
        </div>
      }
      className="h-full"
    >
      {children}
    </Panel>
  );
};
