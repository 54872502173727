import { FC } from "react";
import { LedgerV2AdjustmentEntryVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { Pill, PillProps } from "@libs/components/UI/Pill";
import { getAdjustmentEntryStatus } from "components/PatientProfile/Billing/Ledger/ledgerUtils";

export const AdjustmentPatientPill: FC<{ adjustmentEntry: LedgerV2AdjustmentEntryVO }> = ({
  adjustmentEntry,
}) => {
  const { isSettled, isCreditAdjustment, isPositiveInvoice } = getAdjustmentEntryStatus(adjustmentEntry);
  let theme: PillProps["theme"];
  let pillText: string;

  if (isSettled) {
    theme = "green";

    // Adjustment is settled, now determine if we show "Paid", "Refunded", or "Pt Credit Applied"
    if (isCreditAdjustment) {
      pillText = isPositiveInvoice ? "Pt Credit Applied" : "Refunded";
    } else {
      pillText = "Paid";
    }
  } else {
    theme = "red";
    pillText = `Patient ${formatCurrency(adjustmentEntry.patientRunningBalance)}`;
  }

  return <Pill theme={theme}>{pillText}</Pill>;
};
