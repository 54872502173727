import React, { useCallback, useMemo } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { ClaimSummaryVO } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { FloatingTooltipProps } from "@libs/components/UI/FloatingTooltip";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { TableGrid } from "@libs/components/UI/GridTableComponents";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { ClaimsTableHeader } from "components/Claims/Claims/TableHeader";
import { ClaimTableRow } from "components/Claims/Claims/TableRow";
import { paths } from "utils/routing/paths";
import { ClaimsQuery, ClaimsQueryUpdates, SortField } from "utils/routing/claims";
import { EmptyClaimsList } from "components/Claims/Shared/EmptyList";
import {
  DisableClaimBulkActions,
  IndeterminateCheckboxState,
  SelectAllType,
} from "components/Claims/Claims/useClaimsTableCheckboxes";

interface Props {
  claims: ClaimSummaryVO[];
  claimsQuery: UseInfiniteApiListQueryResult<ClaimSummaryVO>;
  claimsQueryKey: unknown;
  disableBulkActions: DisableClaimBulkActions;
  expandedRows: Record<string, boolean>;
  from: string;
  indeterminateCheckboxState: IndeterminateCheckboxState;
  isPreAuth: boolean;
  onApplyFilters: (keyValues: ClaimsQueryUpdates) => void;
  onDeselectAll: () => void;
  onRowClick: (claimUuid: string) => void;
  onSelectAll: (selectAllType: SelectAllType) => void;
  onSelectCheckbox: (claim: ClaimSummaryVO) => void;
  pendingClaimsSelected: boolean;
  queryStateApi: ClaimsQuery;
  selectedClaimUuids: Set<string>;
  totalClaims: number;
}

export type ClaimsTableHeaderItem = {
  className?: string;
  id: string;
  label: string;
  sortKey?: SortField;
  tooltip?: Omit<FloatingTooltipProps, "children">;
  width: string;
};

export const ATTACHMENTS_ARE_REQUIRED_TOOLTIP = "Attachments are required in order to submit";

const tableHeaders: ClaimsTableHeaderItem[] = [
  { id: "checkbox", label: "", tooltip: { content: "Bulk Select", theme: "SMALL" }, width: "50px" },
  { id: "dateOfService", label: "DoS", sortKey: "SERVICE_DATE", width: "100px" },
  { id: "submittedDate", label: "Sent On", sortKey: "SUBMITTED_AT", width: "100px" },
  { id: "patientName", label: "Pt Name", sortKey: "PATIENT_LAST_NAME", width: "3fr" },
  { className: "justify-end", id: "UCR_AMOUNT", label: "Claim Amt", width: "120px" },
  { id: "billingProvider", label: "Billing Prov", width: "minmax(min-content, 3fr)" },
  {
    id: "treatingProvider",
    label: "Tx Prov",

    sortKey: "TREATING_PROVIDER_LAST_NAME",
    width: "100px",
  },
  { id: "insuranceCarrier", label: "Ins Carrier", width: "min-content" },
  { id: "planType", label: "Plan Type", width: "100px" },
  { id: "insOrder", label: "Ins Order", width: "100px" },
  {
    id: "missingAttachments",
    label: "Attach Req",
    sortKey: "NEEDS_ATTACHMENT",
    tooltip: { content: ATTACHMENTS_ARE_REQUIRED_TOOLTIP },
    width: "100px",
  },
  { id: "claimStatus", label: "Claim Status", width: "minmax(150px, max-content)" },
  { id: "actionsColumn", label: "", width: "150px" },
];

const preAuthTableHeaders: ClaimsTableHeaderItem[] = [
  { id: "checkbox", label: "", tooltip: { content: "Bulk Select", theme: "SMALL" }, width: "50px" },
  { id: "dateOfService", label: "Created Date", sortKey: "SERVICE_DATE", width: "120px" },
  { id: "submittedDate", label: "Sent On", sortKey: "SUBMITTED_AT", width: "100px" },
  { id: "patientName", label: "Pt Name", sortKey: "PATIENT_LAST_NAME", width: "3fr" },
  { className: "justify-end", id: "UCR_AMOUNT", label: "Pre-Auth Amt", width: "120px" },
  { id: "billingProvider", label: "Billing Prov", width: "3fr" },
  {
    id: "treatingProvider",
    label: "Tx Prov",

    sortKey: "TREATING_PROVIDER_LAST_NAME",
    width: "100px",
  },
  { id: "insuranceCarrier", label: "Ins Carrier", width: "4fr" },
  { id: "planType", label: "Plan Type", width: "100px" },
  { id: "insOrder", label: "Ins Order", width: "100px" },
  {
    id: "missingAttachments",
    label: "Attach Req",
    sortKey: "NEEDS_ATTACHMENT",
    tooltip: { content: ATTACHMENTS_ARE_REQUIRED_TOOLTIP },
    width: "100px",
  },
  { id: "claimStatus", label: "Pre-Auth Status", width: "110px" },
  { id: "actionsColumn", label: "", width: "150px" },
];

export const ClaimsTable: React.FC<Props> = ({
  claims,
  claimsQuery,
  claimsQueryKey,
  disableBulkActions,
  expandedRows,
  from,
  indeterminateCheckboxState,
  isPreAuth,
  onApplyFilters,
  onDeselectAll,
  onRowClick,
  onSelectAll,
  onSelectCheckbox,
  pendingClaimsSelected,
  queryStateApi,
  selectedClaimUuids,
  totalClaims,
}) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: claimsQuery });

  const handleNavigateToClaim = useCallback(
    (claim: ClaimSummaryVO) => {
      const fullUrl = getFullUrl(location);

      navigate(paths.claim({ claimUuid: claim.uuid }, { from: fullUrl }));
    },
    [navigate, location]
  );

  const headersToUse = useMemo(
    () => (claims.some((claim) => claim.isPreAuth) ? preAuthTableHeaders : tableHeaders),
    [claims]
  );

  return (
    <PersistScrollPosition
      id="claimsTable"
      className="h-full overflow-y-auto"
      resetScrollKey={claimsQueryKey}
      ref={rootRef}
    >
      <ScrollableInfiniteQueryResult
        infiniteQuery={claimsQuery}
        noResults={
          <div className="flex-1 h-full">
            <EmptyClaimsList text="No Recorded Claims" wrapperClassName="h-full" />
          </div>
        }
        scrollRef={scrollRef}
      >
        <TableGrid columnWidths={headersToUse.map(({ width }) => width)}>
          <ClaimsTableHeader
            indeterminateCheckboxState={indeterminateCheckboxState}
            isPreAuth={isPreAuth}
            onApplyFilters={onApplyFilters}
            onDeselectAll={onDeselectAll}
            onSelectAll={onSelectAll}
            queryStateApi={queryStateApi}
            selectedClaimUuids={selectedClaimUuids}
            tableHeaders={headersToUse}
            totalClaims={totalClaims}
          />
          {claims.map((claim) => (
            <ClaimTableRow
              claim={claim}
              disableBulkActions={disableBulkActions}
              from={from}
              isExpanded={expandedRows[claim.uuid]}
              key={claim.uuid}
              onSelectCheckbox={onSelectCheckbox}
              onClaimOpen={handleNavigateToClaim}
              onRowClick={onRowClick}
              pendingClaimsSelected={pendingClaimsSelected}
              selectedClaimUuids={selectedClaimUuids}
            />
          ))}
        </TableGrid>
      </ScrollableInfiniteQueryResult>
    </PersistScrollPosition>
  );
};
