import { FC, ImgHTMLAttributes, ReactNode } from "react";
import { cx } from "@libs/utils/cx";

const cxSizeClassName = {
  sm: {
    image: "h-24 w-24",
    text: "text-xs",
    gap: "gap-y-4",
  },
  md: {
    image: "h-60 w-60",
    text: "text-sm",
    gap: "gap-y-6",
  },
};

export interface EmptyContentProps
  extends Omit<ImgHTMLAttributes<HTMLImageElement>, "className" | "alt" | "children"> {
  className?: string;
  text: ReactNode;
  fullHeight?: boolean;
  alt: string;
  size?: keyof typeof cxSizeClassName;
  children?: ReactNode;
}

export const EmptyContent: FC<EmptyContentProps> = ({
  className,
  size = "sm",
  fullHeight,
  text,
  children,
  ...imageAttrs
}) => {
  return (
    <div
      className={cx(
        "flex flex-col items-center",
        cxSizeClassName[size].gap,
        fullHeight && "h-full",
        className
      )}
    >
      <img className={cxSizeClassName[size].image} {...imageAttrs} alt={imageAttrs.alt} />
      <div className={cx("text-center", cxSizeClassName[size].text)}>{text}</div>
      {children}
    </div>
  );
};
