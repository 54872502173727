import { MedicalImageVO } from "@libs/api/generated-api";
import { useCallback, useMemo } from "react";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { pluralize } from "@libs/utils/pluralize";
import { useAccount } from "@libs/contexts/AccountContext";
import { analyzeImagesWithPearl } from "api/imaging/mutations";
import { PearlRequestAnalysisButton } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/PearlFlyover/PearlOrientationWarningModal";
import { usePearlAnlaysisCounts } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/hooks/usePearlAnalysisCounts";
import { handleError } from "utils/handleError";

export const PearlMountFlyoverContent: React.FC<{
  mountId?: number;
  images: MedicalImageVO[];
  patientId: number;
  selectedImageIds?: Set<number>;
}> = ({ images, patientId, mountId, selectedImageIds }) => {
  const { practiceId } = useAccount();
  const analysisCounts = usePearlAnlaysisCounts(images);

  const hasImagesSelected = (selectedImageIds?.size ?? 0) > 0;
  const [analyzePearlMutation] = useApiMutations([analyzeImagesWithPearl]);
  const analyzePearlMutate = analyzePearlMutation.mutate;

  const selectedImagesForSubmission = useMemo(() => {
    return images.filter((image) => {
      if (selectedImageIds && selectedImageIds.size > 0 && !selectedImageIds.has(image.id ?? -1)) {
        return false;
      }

      return !image.pearlAnalysis || image.pearlAnalysis.status === "NOT_SUBMITTED";
    });
  }, [images, selectedImageIds]);

  const handleAnalyzeClicked = useCallback(() => {
    analyzePearlMutate(
      {
        practiceId,
        patientId,
        mountId,
        data: {
          medicalImageIds: selectedImagesForSubmission.map((image) => image.id ?? -1),
        },
      },
      { onError: handleError }
    );
  }, [analyzePearlMutate, selectedImagesForSubmission, mountId, patientId, practiceId]);

  if (analysisCounts.done === images.length) {
    return <div className="mt-16 text-xs font-sansSemiBold text-center">AI analysis complete</div>;
  }

  return (
    <div className="text-white text-xs flex flex-col items-center gap-8 mt-16">
      <div className="flex flex-col gap-1 text-center">
        <p className="font-sansSemiBold">
          {analysisCounts.done} of {images.length} images have AI analysis done.
        </p>
        {!hasImagesSelected && analysisCounts.submitted > 0 && (
          <p className="text-slate-300">
            {analysisCounts.submitted} {pluralize(analysisCounts.submitted, "image", "images")}{" "}
            {pluralize(analysisCounts.submitted, "is", "are")} currently being analyzed.
          </p>
        )}
      </div>

      <div className="flex flex-col gap-4">
        <PearlRequestAnalysisButton
          imageSubmissionCount={selectedImagesForSubmission.length}
          handleProceed={handleAnalyzeClicked}
          isLoading={analyzePearlMutation.isLoading}
        />

        {selectedImagesForSubmission.length > 0 ? (
          <p className="text-center text-slate-300">
            {selectedImagesForSubmission.length}{" "}
            {pluralize(selectedImagesForSubmission.length, "image", "images")} will be submitted for analysis.
            This may take several minutes to complete
          </p>
        ) : (
          hasImagesSelected && (
            <p className="text-center text-slate-300">Selected images are being analyzed</p>
          )
        )}
      </div>
    </div>
  );
};
