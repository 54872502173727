import { FC } from "react";

import { MessageCampaignVO } from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { Pill, PillProps } from "@libs/components/UI/Pill";

interface Props {
  status: MessageCampaignVO["status"];
}

const cxThemeStyles: Record<MessageCampaignVO["status"], PillProps["theme"]> = {
  DRAFT: "blue",
  SCHEDULED: "orange",
  SENT: "green",
  CANCELED: "slate200",
};

export const CampaignStatusPill: FC<Props> = ({ status }) => (
  <Pill theme={cxThemeStyles[status]} size="sm">
    {sentenceCaseConstant(status)}
  </Pill>
);
