import { FC, useCallback, useRef, useState } from "react";
import { useReactToPrint } from "react-to-print";
import { noop } from "@libs/utils/noop";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as SettingsIcon } from "@libs/assets/icons/settings.svg";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { ReactComponent as PrinterIcon } from "@libs/assets/icons/send-to-printer.svg";
import { LayoutCard } from "@libs/components/UI/LayoutCard";
import { ChartTabs } from "components/Charting/ChartTabs";

import { FocusManagerProvider } from "contexts/FocusManagerContext";
import { PerioChartHiddenDataWarning } from "components/Charting/Perio/PerioChartHiddenDataWarning";
import { PerioChartApplyAll } from "components/Charting/Perio/PerioChartApplyAll";
import { PerioChartKeyboardShortcuts } from "components/Charting/Perio/PerioChartKeyboardShortcuts";
import { PerioChartHistoryFlyover } from "components/Charting/Perio/PerioChartHistoryFlyover";
import { PerioChartSettingsFlyover } from "components/Charting/Perio/PerioChartSettingsFlyover";
import { usePathParams } from "hooks/usePathParams";
import { PrintOptionsModal } from "components/Charting/PrintOptionsModal";
import { useQueryParams } from "hooks/useQueryParams";
import { PerioChart } from "./PerioChart";
import { PerioChartProvider, usePerioChart } from "./PerioChartContext";
import { PerioChartEditDetails } from "./PerioChartEditDetails";

import { PerioChartActiveToothProvider } from "./PerioChartActiveToothContext";
import { PerioChartPlusTen } from "./PerioChartPlusTen";
import { PerioChartSkipTooth } from "./PerioChartSkipTooth";
import { PerioChartControlProvider } from "./PerioChartControlContext";
import { PerioChartGingMargPlus } from "./PerioChartGingMargPlus";

type PerioPrintOption = "perio-chart" | "dentition-summary" | "implant-summary" | "notes";

const PRINT_OPTIONS: OptionInputOption<PerioPrintOption>[] = [
  {
    value: "perio-chart",
    label: "Perio Chart",
  },
  {
    value: "dentition-summary",
    label: "Dentition Summary",
  },
  {
    value: "implant-summary",
    label: "Implant Summary",
  },
  {
    value: "notes",
    label: "Notes",
  },
];

export const PerioChartRouteContent: FC<{ patientId: number }> = ({ patientId }) => {
  const perioChartRef = useRef<HTMLDivElement>(null);
  const scrollRef = useRef<HTMLDivElement>(null);
  const { isLoading, settings, printOptions, setPrintOptions, printing } = usePerioChart();

  const printModal = useBoolean(false);
  const handlePrint = useReactToPrint({
    content: () => perioChartRef.current,
    documentTitle: "Perio Chart",
    pageStyle: "@page { size: letter landscape; margin: 25px; }",
    onAfterPrint: printing.off,
  });

  const [currentFlyover, setCurrentFlyover] = useState<"history" | "settings" | undefined>(undefined);
  const closeFlyovers = useCallback(() => setCurrentFlyover(undefined), []);

  return (
    <>
      <LayoutCard className="h-full">
        <div className="flex flex-col h-full w-full">
          <div
            className={`
              grid
              grid-cols-[auto_1fr_auto]
              justify-between
              items-center
              gap-x-3
              pl-1
              pr-6
              border-b
              border-b-slate-200
            `}
          >
            <ChartTabs patientId={patientId} />
            <div className="flex flex-row justify-center items-center">
              <PerioChartEditDetails
                onClickHistory={() => {
                  setCurrentFlyover("history");
                }}
              />
            </div>
            <div className="flex flex-row items-center gap-4">
              <PerioChartKeyboardShortcuts />
              <ButtonIcon
                tooltip={{ theme: "SMALL", content: "Print" }}
                disabled={isLoading}
                SvgIcon={PrinterIcon}
                theme="slate700"
                onClick={isLoading ? noop : printModal.on}
              />
              <ButtonIcon
                disabled={isLoading}
                theme="slate700"
                SvgIcon={SettingsIcon}
                tooltip={{ theme: "SMALL", content: "Settings" }}
                onClick={() => {
                  setCurrentFlyover("settings");
                }}
              />
            </div>
            <div />
            <div className="flex justify-center">
              <PerioChartHiddenDataWarning />
            </div>
          </div>
          <div className="flex-1 min-h-0 w-full py-2 overflow-auto" ref={scrollRef}>
            <div className="flex divide-x divide-slate-300">
              <div className="flex flex-col items-center min-w-fit px-2 flex-1">
                <PerioChart ref={perioChartRef} className="flex-1" scrollRef={scrollRef} />
              </div>
              <div className="flex flex-col items-start min-w-fit px-2">
                <div className="flex flex-col gap-y-6">
                  <div className="flex flex-col items-start gap-y-2">
                    <PerioChartGingMargPlus />
                    <PerioChartPlusTen />
                    <PerioChartSkipTooth />
                  </div>
                  <div className="flex flex-col items-start gap-y-2">
                    <PerioChartApplyAll sequenceType="PLAQUE" />
                    <PerioChartApplyAll sequenceType="CALCULUS" />
                    <PerioChartApplyAll sequenceType="BLEEDING" />
                    <PerioChartApplyAll sequenceType="SUPPURATION" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {currentFlyover === "history" ? (
          <PerioChartHistoryFlyover onClose={closeFlyovers} patientId={patientId} />
        ) : currentFlyover === "settings" && settings ? (
          <PerioChartSettingsFlyover settings={settings} onClose={closeFlyovers} />
        ) : null}
      </LayoutCard>
      {printModal.isOn && (
        <PrintOptionsModal
          onChange={setPrintOptions}
          onClose={printModal.off}
          onPrint={() => {
            printModal.off();
            printing.on();

            // Due to the page needing to register the changes of the print options, we need to wait a bit before printing for the dom to express these changes
            // Ideally this would be implemented with a separate print node (see PerioComparePrinted as an example).
            // However, this would require a lot of refactoring and perio is also such a large component that it could be a heavy render for the purposes of just printing
            queueMicrotask(handlePrint);
          }}
          title="Print Chart"
          options={PRINT_OPTIONS}
          selectedValues={printOptions}
        />
      )}
    </>
  );
};

export const PerioChartRoute: FC = () => {
  const { patientId } = usePathParams("perio");
  const { query } = useQueryParams("perio");

  return (
    <FocusManagerProvider>
      <PerioChartProvider patientId={patientId} initialUuid={query.uuid}>
        <PerioChartActiveToothProvider>
          <PerioChartControlProvider>
            <PerioChartRouteContent patientId={patientId} />
          </PerioChartControlProvider>
        </PerioChartActiveToothProvider>
      </PerioChartProvider>
    </FocusManagerProvider>
  );
};
