import { useMemo } from "react";
import { PatientInsuranceResponse } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { isNullish } from "@libs/utils/types";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { SectionHeader, SectionRow } from "components/PatientProfile/Insurance/InsurancePlans/TableItems";

interface Props {
  insurances: PatientInsuranceResponse[];
}

export const HistoryRows: React.FC<Props> = ({ insurances }) => {
  const procedureNames = useMemo(() => {
    const groupNames = new Set(
      insurances.flatMap((insurance) =>
        insurance.procedureHistory.map((procedureHistoryItem) => procedureHistoryItem.groupName)
      )
    );

    return [...groupNames];
  }, [insurances]);

  return procedureNames.length === 0 ? null : (
    <>
      <SectionHeader title="History" />
      {procedureNames.map((groupName) => {
        return (
          <SectionRow
            insurances={insurances}
            key={groupName}
            renderValue={({ procedureHistory }) => {
              const history = procedureHistory.find((item) => item.groupName === groupName);
              const historyDate = history?.date;

              return <div>{isNullish(historyDate) ? EMPTY_CELL : `${formatISODate(historyDate)}`}</div>;
            }}
            title={groupName}
          />
        );
      })}
    </>
  );
};
