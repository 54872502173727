import { useNavigate } from "react-router-dom";
import { useMemo } from "react";
import { paths } from "utils/routing/paths";
import { SignInPayload, useAuthChannelListeners } from "hooks/useAuthChannelListeners";

export const useSignedOutAuthChannelListeners = (options?: { returnUrl?: string; lastUserId?: number }) => {
  const navigate = useNavigate();

  const authChannelEvents = useMemo(
    () => ({
      onSignIn: (event: SignInPayload) => {
        navigate(
          paths.selectAccount({
            returnUrl: options?.returnUrl,
            lastUserId: options?.lastUserId,
            rememberMe: event.rememberMe,
          })
        );
      },
    }),
    [options?.lastUserId, options?.returnUrl, navigate]
  );

  useAuthChannelListeners(authChannelEvents);
};
