import { FC } from "react";

import { pluralize } from "@libs/utils/pluralize";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";

interface Props {
  selectedCount: number;
  isDeleting: boolean;
  onDelete: Func;
  onCancel: Func;
}

export const DeleteFormsModal: FC<Props> = ({ selectedCount, isDeleting, onDelete, onCancel }) => {
  return (
    <ConfirmationModal
      primaryText={`Delete ${pluralize(selectedCount, "Form", "Forms")}`}
      secondaryText={`Are you sure you want to delete the selected ${pluralize(
        selectedCount,
        "form",
        "forms"
      )}?`}
      confirmText="Delete"
      isConfirming={isDeleting}
      onConfirm={onDelete}
      onCancel={onCancel}
      centerVertically
      size="2xs"
    />
  );
};
