import { createLinksContext } from "contexts/AppLinksContext";
import { paths } from "utils/routing/paths";

const { useLinks, LinksProvider, AppHistoryProvider } = createLinksContext("Claims", {
  eob: paths.eobs(),
  preAuth: paths.preAuths(),
  claims: paths.claims(),
});

export const useClaimsLinks = useLinks;
export const ClaimsLinksProvider = LinksProvider;
export const ClaimsAppHistoryProvider = AppHistoryProvider;
