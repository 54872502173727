import { InsuranceAdjustmentEntryVO } from "@libs/api/generated-api";

import { formatCurrency } from "@libs/utils/currency";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { formatISODate } from "@libs/utils/date";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { ReactComponent as NoteIcon } from "@libs/assets/icons/note.svg";
import { EMPTY_CELL, Row } from "@libs/components/UI/GridTableComponents";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";
import { paths } from "utils/routing/paths";
import { useOrdinalTitles } from "components/Patient/insuranceHooks";
import { SubscriberCell } from "components/Dashboard/Tables/SubscriberCell";

import { PatientNameLink } from "components/Dashboard/Tables/PatientNameLink";

type Props = {
  data: InsuranceAdjustmentEntryVO;
  last?: boolean;
  practiceId: number;
};
export const InsuranceAdjustmentsTableRow: React.FC<Props> = ({ data, last, practiceId }) => {
  const {
    patient,
    serviceDate,
    insuranceAdjustmentState,
    insuranceOrdinal,
    insuranceAdjustmentAmount,
    claimUuid,
    netProduction,
    ucrFee,
    patientInsuranceId,
    primarySubscriberPatient,
  } = data;
  const cellProps = { last, to: paths.patient({ patientId: patient.id }) };
  const ordinalTitles = useOrdinalTitles();

  return (
    <Row>
      <DashboardLinkCell {...cellProps}>
        <PatientNameLink patient={patient} />
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{formatISODate(serviceDate, "MM/dd/yyyy")}</DashboardLinkCell>
      <SubscriberCell
        {...cellProps}
        patientInsuranceId={patientInsuranceId}
        primarySubscriberPatient={primarySubscriberPatient}
        practiceId={practiceId}
      />
      <DashboardLinkCell {...cellProps}>
        {insuranceOrdinal ? ordinalTitles[insuranceOrdinal] : EMPTY_CELL}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{sentenceCaseConstant(insuranceAdjustmentState)}</DashboardLinkCell>

      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(ucrFee)}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(insuranceAdjustmentAmount)}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(netProduction)}
      </DashboardLinkCell>

      <DashboardLinkCell {...cellProps}>
        {claimUuid && (
          <ButtonInternalLink className="hover:opacity-70" theme="link" to={paths.claim({ claimUuid })}>
            <NoteIcon className="w-5 h-5" />
          </ButtonInternalLink>
        )}
      </DashboardLinkCell>
    </Row>
  );
};
