/* istanbul ignore file */
import { HeaderData } from "components/Dashboard/Tables/types";
import { LabelSet } from "components/Dashboard/hooks/useLongAgingOptions";

export const UNSCHEDULED_TREATMENT_HEADERS: HeaderData[] = [
  { label: "Patient Name", width: "minmax(8rem, 1fr)", sortField: "patientLastName" },
  {
    label: "Planned Date",
    width: "8rem",
  },

  { label: "Patient Phone", width: "7.7rem" },
  { label: "Ins Primary Carrier", width: "minmax(9.5rem, 1fr)" },
  { label: "Tth/Surf", width: "7rem" },
  { label: "Code", width: "5rem", sortField: "dentalProcedureCdtCode" },
  { label: "Procedure Description", width: "minmax(12rem,15rem)" },
  { label: "Provider", width: "7rem", sortField: "providerLastName" },
  { label: "Treatment Plan Name", width: "minmax(11rem,13rem)" },
  { label: "Priority", width: "6rem", sortField: "priority", align: "center" },
  {
    label: "Last Appt",
    width: "6rem",
  },
  {
    label: "Next Appt",
    width: "6.5rem",
  },
  { label: "Neg Fees", width: "6.5rem", align: "right" },
  { label: "", width: "3.5rem" },
];

export const UNSCHEDULED_TREATMENT_AGING_OPTIONS: LabelSet[] = [
  {
    label: "< 30 Days Ago",
    shortDisplayLabel: "< 30 days ago",
  },
  {
    label: "31-90 Days Ago",
    shortDisplayLabel: "31-90 days ago",
  },
  {
    label: "91-180 Days Ago",
    shortDisplayLabel: "91-180 days ago",
  },
  {
    label: "181-365 Days Ago",
    shortDisplayLabel: "181-365 days ago",
  },
  {
    label: "365+ Days Ago",
    shortDisplayLabel: "365+ days ago",
  },
];
