import { FC, useId, useMemo, useCallback } from "react";
import { useDebouncedCallback } from "use-debounce";

import { useNavigate } from "react-router-dom";
import {
  MessageCampaignVO,
  MessageCampaignSummaryVO,
  TemplateVariableGroupVO,
  PatientListCriteria,
  InsuranceCarrierVO,
  UpdateMessageCampaignRequest,
} from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as VerticalMenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as CopyIcon } from "@libs/assets/icons/copy.svg";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { useAccount } from "@libs/contexts/AccountContext";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { Panel } from "@libs/components/UI/Panel";

import { CampaignSavingPill } from "components/Communications/Campaigns/Campaign/CampaignSavingPill";
import { CampaignStatusPill } from "components/Communications/Campaigns/CampaignStatusPill";
import { CampaignForm } from "components/Communications/Campaigns/Campaign/CampaignForm";
import { CampaignEmail } from "components/Communications/Campaigns/Campaign/CampaignEmail";

import {
  hasFilteredCountExceedingDailyLimit,
  getCreateEmailCampaignRequest,
  hasScheduledAtInPast,
} from "components/Communications/Campaigns/Campaign/utils";

import {
  createMessageCampaign,
  updateMessageCampaign,
  triggerMessageCampaign,
  previewMessageCampaign,
  cancelMessageCampaign,
} from "api/communications/mutations";

import { handleError } from "utils/handleError";
import { paths } from "utils/routing/paths";

interface Props {
  campaign: MessageCampaignVO;
  summary: MessageCampaignSummaryVO | undefined;
  templateVariables: TemplateVariableGroupVO[];
  patientListCriteria: PatientListCriteria | undefined;
  patientsFiltered: number | undefined;
  insuranceCarriers: InsuranceCarrierVO[];
  emailTemplatePreviewUrl?: string;
  onRequestEmailTemplatePreviewUrl: (previewUrl: string) => void;
  onRequestEditEmail: Func;
  hasNoFiltersApplied: boolean;
  from: string;
}

const SAVE_DELAY = 1000;

// eslint-disable-next-line complexity
export const Campaign: FC<Props> = ({
  campaign,
  summary,
  templateVariables,
  patientListCriteria,
  patientsFiltered,
  insuranceCarriers,
  emailTemplatePreviewUrl,
  onRequestEmailTemplatePreviewUrl,
  onRequestEditEmail,
  hasNoFiltersApplied,
  from,
}) => {
  const { practiceId } = useAccount();
  const navigate = useNavigate();
  const formId = useId();
  const menu = useBoolean(false);
  const sendNow = useBoolean(!campaign.scheduledAt);

  const isEditable = campaign.status === "DRAFT";
  const hasEmailTemplate = Boolean(campaign.emailTemplate?.json && campaign.emailTemplate.html);
  const hasSendTimeInPast = campaign.scheduledAt ? hasScheduledAtInPast(campaign.scheduledAt) : false;
  const hasExceededFilteredCountLimit = hasFilteredCountExceedingDailyLimit(patientsFiltered);
  const hasTriggerDisabled =
    !hasEmailTemplate ||
    !patientsFiltered ||
    (sendNow.isOff && (!campaign.scheduledAt || hasSendTimeInPast)) ||
    hasExceededFilteredCountLimit;

  const [
    createMessageCampaignMutation,
    updateMessageCampaignMutation,
    triggerMessageCampaignMutation,
    cancelMessageCampaignMutation,
    previewMessageCampaignMutation,
  ] = useApiMutations([
    createMessageCampaign,
    updateMessageCampaign,
    triggerMessageCampaign,
    cancelMessageCampaign,
    previewMessageCampaign,
  ]);

  const isCopyingEmailCampaign = createMessageCampaignMutation.isLoading;

  const menuOptions = useMemo(
    () =>
      createMenuOptions(
        {
          label: "Copy",
          value: "copy",
          SvgIcon: CopyIcon,
          isLoading: isCopyingEmailCampaign,
        }
        // TODO: Link to report when available
        // {
        //   label: "Peformance",
        //   value: "performance",
        //   SvgIcon: ChartLineIcon,
        // }
      ),
    [isCopyingEmailCampaign]
  );

  const handleCopyEmailCampaign = useCallback(() => {
    const { name, template, emailTemplate, filter } = campaign;
    const data = getCreateEmailCampaignRequest({
      name: `${name ?? "Untitled Campaign"} (Copy)`,
      template,
      emailTemplate,
      filter,
    });

    createMessageCampaignMutation.mutate(
      {
        practiceId,
        data,
      },
      {
        onSuccess: () => navigate(paths.communications()),
        onError: handleError,
      }
    );
  }, [campaign, practiceId, createMessageCampaignMutation, navigate]);

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "copy": {
          handleCopyEmailCampaign();
          break;
        }
        default: {
          break;
        }
      }
    },
    [handleCopyEmailCampaign]
  );

  const handleSaveEmailCampaign = useDebouncedCallback(
    (data: UpdateMessageCampaignRequest, options?: { onSuccess?: Func }) => {
      updateMessageCampaignMutation.mutate(
        {
          practiceId,
          messageCampaignUuid: campaign.uuid,
          data,
        },
        {
          onSuccess: () => {
            options?.onSuccess?.();
          },
          onError: handleError,
        }
      );
    },
    SAVE_DELAY,
    { leading: false }
  );

  const handleTriggerEmailCampaign = () => {
    triggerMessageCampaignMutation.mutate(
      { practiceId, messageCampaignUuid: campaign.uuid },
      {
        onSuccess: () => navigate(paths.communications()),
        onError: handleError,
      }
    );
  };

  const handleUnscheduleEmailCampaign = () => {
    cancelMessageCampaignMutation.mutate(
      { practiceId, messageCampaignUuid: campaign.uuid },
      { onError: handleError }
    );
  };

  const handleSendPreviewEmail = (recipientEmail: string, options: { onSuccess: Func }) => {
    previewMessageCampaignMutation.mutate(
      {
        practiceId,
        messageCampaignUuid: campaign.uuid,
        data: { email: recipientEmail },
      },
      {
        onSuccess: options.onSuccess,
        onError: handleError,
      }
    );
  };

  return (
    <Panel
      title="Campaign"
      className="min-w-[48rem] h-full"
      contentClassName="flex divide-x divide-slate-200"
      includePadding={false}
      data-testid="campaign-panel"
      actions={
        <div className="flex items-center gap-x-2">
          {updateMessageCampaignMutation.isLoading ? <CampaignSavingPill /> : null}
          <CampaignStatusPill status={campaign.status} />
          <ButtonMenu
            menuContent={
              <div className="w-36">
                <MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />
              </div>
            }
            onRequestOpen={menu.on}
            onRequestClose={menu.off}
            isOpen={menu.isOn}
            placement="bottom-end"
          >
            {(props) => (
              <ButtonIcon
                SvgIcon={VerticalMenuIcon}
                tooltip={{ content: "Campaign Menu", theme: "SMALL" }}
                theme="primary"
                {...props}
              />
            )}
          </ButtonMenu>
        </div>
      }
      footer={
        <div className="flex justify-center gap-x-2">
          <ButtonInternalLink className="min-w-button" to={from} theme="secondary" replace>
            Close
          </ButtonInternalLink>
          {isEditable ? (
            <FloatingTooltip
              content={
                hasExceededFilteredCountLimit
                  ? `You cannot ${
                      sendNow.isOn ? "send" : "schedule"
                    } a campaign that exceeds the maximum amount of recipients`
                  : ""
              }
              theme="MEDIUM"
            >
              <AsyncButton
                aria-label={sendNow.isOn ? "Send" : "Schedule"}
                className="min-w-button"
                isLoading={triggerMessageCampaignMutation.isLoading}
                disabled={hasTriggerDisabled}
                form={formId}
                type="submit"
              >
                {sendNow.isOn ? "Send" : "Schedule"}
              </AsyncButton>
            </FloatingTooltip>
          ) : (
            <AsyncButton
              className="min-w-button"
              onClick={handleUnscheduleEmailCampaign}
              isLoading={cancelMessageCampaignMutation.isLoading}
              disabled={campaign.status !== "SCHEDULED"}
              type="button"
            >
              Unschedule
            </AsyncButton>
          )}
        </div>
      }
    >
      <div className="basis-1/2 overflow-x-hidden overflow-y-auto">
        <CampaignForm
          formId={formId}
          campaign={campaign}
          summary={summary}
          templateVariables={templateVariables}
          patientListCriteria={patientListCriteria}
          patientsFiltered={patientsFiltered}
          insuranceCarriers={insuranceCarriers}
          onToggleSendNow={sendNow.toggle}
          onSaveEmailCampaign={handleSaveEmailCampaign}
          onTriggerEmailCampaign={handleTriggerEmailCampaign}
          hasNoFiltersApplied={hasNoFiltersApplied}
          isApplyingFilters={updateMessageCampaignMutation.isLoading}
          isEditable={isEditable}
          isSendNow={sendNow.isOn}
        />
      </div>
      <div className="basis-1/2 overflow-x-hidden overflow-y-auto bg-slate-50">
        <CampaignEmail
          campaign={campaign}
          emailTemplatePreviewUrl={emailTemplatePreviewUrl}
          onRequestEmailTemplatePreviewUrl={onRequestEmailTemplatePreviewUrl}
          onRequestEditEmail={onRequestEditEmail}
          onSendPreviewEmail={handleSendPreviewEmail}
          hasEmailTemplate={hasEmailTemplate}
          isSendingPreviewEmail={previewMessageCampaignMutation.isLoading}
          isEditable={isEditable && !hasSendTimeInPast}
        />
      </div>
    </Panel>
  );
};
