import React from "react";
import Skeleton from "react-loading-skeleton";
import { FilterOptionOption } from "react-select/dist/declarations/src/filters";
import { DentalProcedureVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { FormFieldLayout } from "@libs/types/form";
import { useAccount } from "@libs/contexts/AccountContext";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { createSelectStyles } from "@libs/components/UI/selectStyles";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { ProcedureOption } from "components/ProcedureSelector/ProcedureOption";
import { cdtCodeToNumber, cdtCodeToString } from "utils/cdtCode";
import { getDentalProceduresQuery } from "api/charting/queries";
import { useNow } from "hooks/useNow";
import { CellAlign } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/TableItems";

export type Props = {
  autoFocus?: boolean;
  align?: CellAlign;
  className?: string;
  edit?: boolean;
  endRange?: number;
  error?: string;
  isClearable?: boolean;
  layout?: FormFieldLayout;
  onChange: (code?: string, procedure?: DentalProcedureVO) => void;
  startRange?: number;
  value?: string;
};

type CdtSearchResult = {
  value: string;
  label: string;
  procedure?: DentalProcedureVO;
};

const handleFilterOption = (option: FilterOptionOption<CdtSearchResult>, searchText: string) => {
  const searchTextLowered = searchText.toLowerCase();

  return (
    !option.data.procedure ||
    option.data.procedure.cdtCode.toLowerCase().includes(searchTextLowered) ||
    option.data.procedure.name.toLowerCase().includes(searchTextLowered) ||
    option.data.procedure.simpleName.toLowerCase().includes(searchTextLowered)
  );
};

const sanitizeCodeEntry = (code: string) => {
  const MAX_LENGTH_CODE = 5;

  const numericString = code.replaceAll(/[Dd]/g, "");

  if (numericString.length <= MAX_LENGTH_CODE - 1) {
    let proposed: string | undefined = `D${numericString}`;
    const numericCode = cdtCodeToNumber(proposed);

    if (numericCode !== undefined && !Number.isNaN(numericCode)) {
      if (proposed.length < MAX_LENGTH_CODE + 1) {
        proposed = cdtCodeToString(numericCode, true);
      }

      return proposed;
    }
  }

  return undefined;
};

export const CdtCodeSearch: React.FC<Props> = ({
  align = "left",
  autoFocus,
  className,
  edit,
  error,
  isClearable = false,
  onChange,
  startRange,
  value,
}) => {
  const { practiceId } = useAccount();
  const now = useNow();
  const [{ data: procedures, isLoading }] = useApiQueries([
    getDentalProceduresQuery({ args: { practiceId }, queryOptions: { enabled: edit } }),
  ]);

  const options = React.useMemo(() => {
    const items: CdtSearchResult[] = (procedures ?? [])
      .filter((item) => {
        if (!item.cdtCode.startsWith("D")) {
          return false;
        }

        const numericCode = cdtCodeToNumber(item.cdtCode);

        if (numericCode === undefined || Number.isNaN(numericCode)) {
          return false;
        }

        return numericCode >= (startRange ?? 0);
      })
      .map((procedure) => ({
        value: procedure.cdtCode,
        label: procedure.cdtCode,
        procedure,
      }));

    if (value && !items.some((item) => item.value === value)) {
      items.push({
        value,
        label: value,
      });
    }

    return items;
  }, [procedures, value, startRange]);

  const customStyles = React.useMemo(
    () =>
      createSelectStyles<string, CdtSearchResult>({
        menu: (provided) => {
          const baseProps = {
            ...provided,
            width: "40rem",
          };

          return align === "right"
            ? {
                ...baseProps,
                right: -8,
              }
            : { ...baseProps, left: -8 };
        },
      }),
    [align]
  );

  return isLoading ? (
    <Skeleton containerClassName="w-full" className="h-8" />
  ) : edit ? (
    <FormFieldSelect
      allowCreateWhileLoading
      autoFocus={autoFocus}
      className={className}
      error={error}
      filterOption={handleFilterOption}
      formatOptionLabel={(option, meta) => {
        if (meta.context === "value") {
          return option.value;
        }

        if (!option.procedure) {
          if (option.value === value) {
            return value;
          }

          const code = sanitizeCodeEntry(option.value);

          return code ? `Use "${code}"` : "No options";
        }

        return <ProcedureOption procedure={option.procedure} now={now} />;
      }}
      inputClassName="px-2 rounded w-full"
      isClearable={isClearable}
      isSearchable
      isValidNewOption={(inputValue) => {
        const sanitized = sanitizeCodeEntry(inputValue);

        return sanitized !== undefined;
      }}
      layout="tableValue"
      onChange={(option) => {
        if (option) {
          onChange(option.value, option.procedure);
        } else {
          onChange(undefined);
        }
      }}
      onCreateOption={(inputValue) => {
        const sanitizedValue = sanitizeCodeEntry(inputValue);

        if (sanitizedValue) {
          onChange(sanitizedValue);
        }
      }}
      openMenuOnFocus
      options={options}
      styles={customStyles}
      value={value}
    />
  ) : (
    <div className={className}>{value ?? EMPTY_CELL}</div>
  );
};
