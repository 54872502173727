import { useLayoutEffect, useEffect, useRef, useState } from "react";
import { noop } from "@libs/utils/noop";
import { getScrollPosition, setScrollPosition } from "storage/scrolling";
import { AppointmentGrouping } from "utils/routing/scheduling";

const scrollClassSelector = ".mbsc-schedule-grid-scroll";
const getScrollContainer = () => document.querySelector(scrollClassSelector);

const MAX_POLL_TIME = 5000;
const POLL_INTERVAL = 100;
const SEVEN_AM_TIME_INDEX = 14;

const pollForScrollElement = (
  scrollContainer: Element | null,
  onSearchForScrollElement: () => HTMLElement | undefined,
  onScrollResolved: Func
) => {
  const startAt = Date.now();
  const intervalId = window.setInterval(() => {
    const scrollElement = onSearchForScrollElement();

    if (scrollContainer && scrollElement) {
      // For elements that may be out of scroll view, there is no easily
      // retrievable scroll left position. Thus, we first scroll the element
      // into view, then use the updated scroll container's scroll left
      scrollElement.scrollIntoView();
      scrollContainer.scrollTo({ left: scrollContainer.scrollLeft, top: scrollElement.offsetTop });
      clearInterval(intervalId);
      onScrollResolved();
    } else if (Date.now() - startAt > MAX_POLL_TIME) {
      clearInterval(intervalId);
      onScrollResolved();
    }
  }, POLL_INTERVAL);

  return intervalId;
};

const getFirstWorkSection = () => {
  const colors = [...document.querySelectorAll(".mbsc-schedule-color")] as HTMLDivElement[];

  return colors.find((el) => el.style.backgroundColor === "white");
};

const getFirstEvent = () => {
  const events = [...document.querySelectorAll(".mbsc-schedule-event")] as HTMLDivElement[];

  return events.length > 0 ? events.sort((a, b) => a.offsetTop - b.offsetTop)[0] : undefined;
};

export const useScheduleScroller = (
  queryDate: string,
  groupAppointmentsBy: AppointmentGrouping,
  onScrollResolved: Func,
  hasWorkHours?: boolean,
  hasEvents?: boolean
) => {
  const [scrollStrategy, setScrollStrategy] = useState<"memory" | "polling">("memory");
  const scrollId = `${queryDate}-${groupAppointmentsBy}`;
  const scrollIdRef = useRef(scrollId);
  const scrollIdHasChangedRef = useRef(false);

  // This effect is used until either we discover there is no scroll position in
  // memory or we have changed the scrollId (i.e. the queryDate or
  // groupAppointmentsBy has changed)
  useLayoutEffect(() => {
    const scrollContainer = getScrollContainer();

    if (!scrollContainer) {
      onScrollResolved();

      return noop;
    }

    if (scrollId !== scrollIdRef.current) {
      scrollIdRef.current = scrollId;
      scrollIdHasChangedRef.current = true;
    }

    if (!scrollIdHasChangedRef.current) {
      const scrollPosition = getScrollPosition(scrollId);

      if (scrollPosition) {
        scrollContainer.scrollTo({ left: scrollPosition.scrollLeft, top: scrollPosition.scrollTop });
        onScrollResolved();

        return () => {
          setScrollPosition(scrollId, scrollContainer.scrollLeft, scrollContainer.scrollTop);
        };
      }

      // On mount, mobiscroll's initial scroll position is in the middle of the
      // scheudle, so we scroll to the top, then begin polling for work hours
      scrollContainer.scrollTo({ top: 0, left: 0 });
    }

    setScrollStrategy("polling");

    return () => {
      setScrollPosition(scrollId, scrollContainer.scrollLeft, scrollContainer.scrollTop);
    };
  }, [scrollStrategy, onScrollResolved, scrollId]);

  // Once the previous effect has determined it's time to start looking for work
  // hours, we start polling the page for the first work hour to scroll the user
  useEffect(() => {
    if (scrollStrategy !== "polling") {
      return noop;
    }

    const scrollContainer = getScrollContainer();
    let intervalId = 0;

    if (!scrollContainer) {
      onScrollResolved();

      return noop;
    }

    if (hasWorkHours) {
      intervalId = pollForScrollElement(scrollContainer, getFirstWorkSection, onScrollResolved);
    } else if (hasWorkHours === false) {
      // If the practice has no work hours, we then begin polling for events to
      // scroll the user to the first event. Otherwise, we scroll the user to 7
      // AM if there are no events.
      if (hasEvents) {
        intervalId = pollForScrollElement(scrollContainer, getFirstEvent, onScrollResolved);
      } else if (hasEvents === false) {
        const times = [...document.querySelectorAll(".mbsc-schedule-time-wrapper")] as HTMLDivElement[];
        const sevenAm = times[SEVEN_AM_TIME_INDEX];

        scrollContainer.scrollTo({ left: scrollContainer.scrollLeft, top: sevenAm.offsetTop });
        onScrollResolved();
      }
    }

    return () => {
      clearInterval(intervalId);
    };
  }, [scrollStrategy, onScrollResolved, scrollId, hasWorkHours, hasEvents]);
};
