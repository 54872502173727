import { FC, Dispatch, SetStateAction, useState, useCallback } from "react";
import { produce } from "immer";

import { PatientCriteriaVO, PatientListCriteria, MonthDayRangeFilter } from "@libs/api/generated-api";
import { stripAllButNumbers } from "@libs/utils/inputFormatting";
import { Button } from "@libs/components/UI/Button";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";
import {
  FormFieldMonthDayInput,
  formatMonthDay,
  fillMonthDay,
  MONTH_DAY_LENGTH,
} from "components/UI/FormFieldMonthDayInput";

import { FilterSection } from "components/Communications/Filters/FilterSection";

type FilterSelection = "WITHIN" | "BETWEEN" | undefined;

interface Props extends Pick<PatientCriteriaVO, "birthdayWithinDays" | "birthdayRange"> {
  onUpdatePatientListCriteria: Dispatch<SetStateAction<PatientListCriteria>>;
}

export const BirthdayFilterSection: FC<Props> = ({
  birthdayWithinDays,
  birthdayRange,
  onUpdatePatientListCriteria,
}) => {
  const [filterSelection, setFilterSelection] = useState<FilterSelection>(() =>
    birthdayWithinDays == null && birthdayRange == null
      ? undefined
      : birthdayWithinDays == null
        ? "BETWEEN"
        : "WITHIN"
  );

  const handleUpdateBirthdayRange = useCallback(
    (partialRange: Partial<MonthDayRangeFilter>) => {
      onUpdatePatientListCriteria((last) =>
        produce(last, (draft) => {
          if (draft.patientCriteria) {
            draft.patientCriteria.birthdayRange = {
              ...draft.patientCriteria.birthdayRange,
              ...partialRange,
            };
          } else {
            draft.patientCriteria = {
              birthdayRange: partialRange,
            };
          }
        })
      );
    },
    [onUpdatePatientListCriteria]
  );

  const handleFillMonthDay = useCallback(
    // eslint-disable-next-line complexity
    (partialRange: Partial<MonthDayRangeFilter>) => {
      const [key, value] = Object.entries(partialRange)[0];
      const otherKey = key === "min" ? "max" : "min";
      const otherValue = birthdayRange?.[otherKey];
      const strippedValue = stripAllButNumbers(value);

      // Do nothing if values are already filled
      if (
        strippedValue.length === MONTH_DAY_LENGTH &&
        otherValue &&
        stripAllButNumbers(otherValue).length === MONTH_DAY_LENGTH
      ) {
        return;
      }

      // Fill value with other value, if value is empty and other value is filled
      if (
        strippedValue.length === 0 &&
        otherValue &&
        stripAllButNumbers(otherValue).length === MONTH_DAY_LENGTH
      ) {
        handleUpdateBirthdayRange({ [key]: otherValue });

        return;
      }

      const filledValue = fillMonthDay(value);
      const range = { [key]: filledValue };

      // Fill other value with filled value, if other value is empty
      if (birthdayRange && (!birthdayRange.min || !birthdayRange.max)) {
        range[otherKey] = filledValue;
      }

      handleUpdateBirthdayRange(range);
    },
    [birthdayRange, handleUpdateBirthdayRange]
  );

  const handleClearBirthdayCriteria = () => {
    onUpdatePatientListCriteria((last) =>
      produce(last, (draft) => {
        if (draft.patientCriteria) {
          delete draft.patientCriteria.birthdayWithinDays;
          delete draft.patientCriteria.birthdayRange;
        }
      })
    );
  };

  return (
    <FilterSection
      title="Birthday"
      dataTestId="birthday-filter-section"
      isOpen={birthdayWithinDays != null || birthdayRange?.min != null || birthdayRange?.max != null}
    >
      <div className="flex flex-col gap-y-2">
        <div className="flex items-center gap-x-2">
          <FormFieldSelect
            aria-label="Birthday Filter Selection"
            className="w-36"
            placeholder="Select..."
            options={[
              { label: "Within Next...", value: "WITHIN" },
              { label: "Between", value: "BETWEEN" },
            ]}
            value={filterSelection}
            onItemSelected={(value) => {
              setFilterSelection(value);
              handleClearBirthdayCriteria();
            }}
            required
          />

          {filterSelection === "WITHIN" ? (
            <FormFieldNumberInput
              aria-label="Birthday Within Days"
              className="w-24"
              value={birthdayWithinDays}
              placeholder="00"
              onChange={(e) =>
                onUpdatePatientListCriteria((last) =>
                  produce(last, (draft) => {
                    const value = e.target.value ? Number(e.target.value) : undefined;

                    if (draft.patientCriteria) {
                      draft.patientCriteria.birthdayWithinDays = value;
                      delete draft.patientCriteria.birthdayRange;
                    } else {
                      draft.patientCriteria = {
                        birthdayWithinDays: value,
                      };
                    }
                  })
                )
              }
            >
              <span
                className={`
                  font-sansSemiBold
                  text-xs
                  text-slate700
                  absolute
                  right-3
                  top-1/2
                  -translate-y-1/2
                `}
              >
                Days
              </span>
            </FormFieldNumberInput>
          ) : filterSelection === "BETWEEN" ? (
            <>
              <FormFieldMonthDayInput
                aria-label="Min Birthday"
                className="w-24"
                value={birthdayRange?.min}
                onChange={(e) =>
                  handleUpdateBirthdayRange({
                    min: e.target.value ? formatMonthDay(e.target.value) : undefined,
                  })
                }
                onBlur={(e) => handleFillMonthDay({ min: e.target.value })}
              />

              <span className="text-xs text-black">-</span>

              <FormFieldMonthDayInput
                aria-label="Max Birthday"
                className="w-24"
                value={birthdayRange?.max}
                onChange={(e) =>
                  handleUpdateBirthdayRange({
                    max: e.target.value ? formatMonthDay(e.target.value) : undefined,
                  })
                }
                onBlur={(e) => handleFillMonthDay({ max: e.target.value })}
              />
            </>
          ) : null}
        </div>

        {filterSelection ? (
          <Button
            className="w-fit text-xs"
            onClick={() => {
              setFilterSelection(undefined);
              handleClearBirthdayCriteria();
            }}
            size="custom"
            theme="link"
          >
            Clear
          </Button>
        ) : null}
      </div>
    </FilterSection>
  );
};
