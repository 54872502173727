import { FC, Fragment } from "react";
import { FamilyMemberVO } from "@libs/api/generated-api";
import { useFullUrl } from "@libs/utils/location";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { Banner } from "@libs/components/UI/Banner";
import { paths } from "utils/routing/paths";
import { Text } from "components/PatientProfile/Billing/Ledger/Text";
import { CollectionMode } from "utils/routing/patient";
import { getFirstAndLastName } from "utils/names";

/**
 * NeedsInvoicingBanner component displays a banner if there are pending invoiceable items for
 * family members. The banner is conditionally rendered based on the provided props.
 *
 * @param patientId - The ID of the patient.
 * @param mode - The collection mode ("individual" or "family").
 * @param familyMembersWithInvoiceables - An array of family members with pending invoiceable items.
 * @returns The NeedsInvoicingBanner component.
 */
export const NeedsInvoicingBanner: FC<{
  patientId: number;
  mode: CollectionMode;
  /**
   * Family members with pending invoiceable items. It may be an empty array.
   */
  familyMembersWithInvoiceables: FamilyMemberVO[];
}> = ({ patientId, mode, familyMembersWithInvoiceables }) => {
  // Don't show the banner if there are no family members with pending
  // invoiceable items.
  if (familyMembersWithInvoiceables.length === 0) {
    return null;
  }

  // When mode is "individual", find out if patient is listed in the
  // familyMembersWithInvoiceables array. If patient is not listed, don't
  // show the banner.
  if (
    mode === "individual" &&
    !familyMembersWithInvoiceables.some((fm) => fm.memberPatientId === patientId)
  ) {
    return null;
  }

  return (
    <Banner theme="warning">
      {mode === "individual" ? (
        <IndividualBannerContent patientId={patientId} />
      ) : (
        <FamilyBannerContent familyMembers={familyMembersWithInvoiceables} />
      )}
    </Banner>
  );
};

const IndividualBannerContent: FC<{ patientId: number }> = ({ patientId }) => {
  const fullUrl = useFullUrl();

  return (
    <Text>
      To collect the full balance, first create an invoice for all completed appointments and/or adjustments.{" "}
      <ButtonInternalLink
        theme="link"
        to={paths.createInvoice({ patientId }, { from: fullUrl, nextStep: "return" })}
      >
        Create Invoice
      </ButtonInternalLink>
    </Text>
  );
};

const FamilyBannerContent: FC<{ familyMembers: FamilyMemberVO[] }> = ({ familyMembers }) => {
  const fullUrl = useFullUrl();

  return (
    <div className="flex flex-col gap-y-2">
      <Text>
        To collect the full family balance, first create invoices for all completed appointments and/or
        adjustments for the following family members:
      </Text>
      <div className="flex gap-x-2 items-center">
        {familyMembers.map((fm, i) => (
          <Fragment key={fm.memberPatientId}>
            {i > 0 && <Text color="slate500">•</Text>}
            <Text>
              <ButtonInternalLink
                theme="link"
                to={paths.createInvoice(
                  { patientId: fm.memberPatientId },
                  { from: fullUrl, nextStep: "return" }
                )}
              >
                {getFirstAndLastName(fm.name)}
              </ButtonInternalLink>
            </Text>
          </Fragment>
        ))}
      </div>
    </div>
  );
};
