import { FC, PropsWithChildren, ReactNode, useEffect, useState } from "react";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { hasFailedPin } from "@libs/utils/hasFailedPin";
import { EmployeePinModal } from "components/Employee/EmployeePinModal";
import { AccessDeniedContent } from "./AccessDeniedContent";

/**
 * A component that protects children elements from rendering if the provided
 * react-query queries fail due to an invalid PIN. `PinGuard` is designed to
 * take a set of pin-protected queries and watch whether those queries fail due
 * to an invalid pin error (the response contains a special error code). If any
 * of the queries fail due to an invalid pin, `PinGuard` will render a PIN form.
 * If the PIN form is successfully submitted, `PinGuard` will remove the
 * pin-protected queries from the cache so they will be re-fetched if needed.
 * Otherwise, `PinGuard` will continue to render the PIN form until the user
 * cancels the PIN form.
 *
 * @param protectedQueries An array of pin-protected queries to watch for failed
 * PINs.
 * @param deniedContent Optional content to render when the user gives up on
 * entering the correct PIN.
 * @param children The children elements to render if the PIN is valid.
 * @returns A component that renders the children elements if the PIN is valid,
 * or a PIN form if the PIN is invalid.
 */
export const PinGuard: FC<
  PropsWithChildren<{
    protectedQueries: ApiQueryResult[];
    deniedContent?: ReactNode;
  }>
> = ({ protectedQueries, deniedContent, children }) => {
  // Assume pin is valid until proven otherwise
  const [isPinValid, setIsPinValid] = useState(true);
  const [hasCancelled, setHasCancelled] = useState(false);

  const handleCancel = () => {
    setHasCancelled(true);
  };

  useEffect(() => {
    // Check if any of the queries are failing due to an invalid pin
    if (protectedQueries.some((query) => hasFailedPin(query.error) && query.fetchStatus !== "fetching")) {
      setIsPinValid(false);
    }
  }, [protectedQueries]);

  return isPinValid ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{children}</>
  ) : hasCancelled ? (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{deniedContent ?? <AccessDeniedContent />}</>
  ) : (
    <EmployeePinModal
      onPinSuccess={() => {
        // Refetch protected queries.
        protectedQueries.forEach((query) => {
          if (hasFailedPin(query.error)) {
            // Remove the query from cache so we don't show previously failed
            // query content while data is being refetched.
            query.remove();
            query.refetch();
          }
        });
        setIsPinValid(true);
      }}
      onClose={handleCancel}
    />
  );
};
