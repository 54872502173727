import { useCallback, useMemo, useState } from "react";
import { DentalProcedureVO, PatientToothVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { isString } from "@libs/utils/types";
import { toggleSet } from "@libs/utils/toggleSet";
import { Icon } from "@libs/components/UI/Icon";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as QuestionIcon } from "@libs/assets/icons/help.svg";
import { ReactComponent as PlusCircleIcon } from "@libs/assets/icons/plus-circle.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { DraftPatientProcedureRequest } from "components/Charting/draftPatientProcedure";
import {
  isValidSupernumeraryToothName,
  isValidToothName,
  getSupernumeraryAdjacentToothName,
} from "components/Charting/teeth";
import { isToothDisabledByDentalProcedure } from "components/Charting/patientProcedureSchema";
import { Chip } from "components/UI/Chip";

interface Props {
  type: "radio" | "checkbox";
  teeth: PatientToothVO[];
  toothSelections: Set<string>;
  dentalProcedure?: DentalProcedureVO;
  title?: string;
  onUpdateDraft?: (updates: Partial<DraftPatientProcedureRequest>) => void;
}

export const SupernumeraryNonPresentForm: React.FC<Props> = ({
  type,
  teeth,
  toothSelections,
  dentalProcedure,
  title = "Supernumerary or Non-present teeth",
  onUpdateDraft,
}) => {
  const teethByToothName = useMemo(
    () =>
      teeth.reduce<Record<string, PatientToothVO | undefined>>((prev, curr) => {
        return { ...prev, [curr.toothName]: curr };
      }, {}),
    [teeth]
  );

  const isSupernumeraryOrNonExistentTooth = useCallback(
    (toothName: string) => !(toothName in teethByToothName),
    [teethByToothName]
  );

  const [supernumeraryTeeth, setSupernumeraryTeeth] = useState(
    () => new Set([...toothSelections].filter(isSupernumeraryOrNonExistentTooth))
  );
  const [newSupernumeraryTooth, setNewSupernumeraryTooth] = useState("");

  const validation = useValidation(newSupernumeraryTooth, [
    {
      $v: (v) => isString(v) && (isValidToothName(v) || isValidSupernumeraryToothName(v)),
      $error: "Invalid tooth name",
    },
    {
      $v: (v) => {
        if (isString(v)) {
          const toothToFind = isValidSupernumeraryToothName(v) ? getSupernumeraryAdjacentToothName(v) : v;
          const patientTooth = teethByToothName[toothToFind];

          return patientTooth
            ? dentalProcedure
              ? !isToothDisabledByDentalProcedure(patientTooth, dentalProcedure)
              : true
            : true;
        }

        return true;
      },
      $error: "Tooth unsupported by dental procedure",
    },
  ]);

  const selectTooth = useCallback(
    (toothName: string) => {
      const newSelection = type === "radio" ? new Set([toothName]) : toggleSet(toothSelections, toothName);

      onUpdateDraft?.({ toothSelections: newSelection });
    },
    [onUpdateDraft, toothSelections, type]
  );

  const unselectTooth = useCallback(
    (toothName: string) => {
      if (!toothSelections.has(toothName)) {
        return;
      }

      const newSelection = type === "radio" ? new Set<string>() : toggleSet(toothSelections, toothName);

      onUpdateDraft?.({ toothSelections: newSelection });
    },
    [onUpdateDraft, toothSelections, type]
  );

  const deleteSupernumeraryTooth = useCallback(
    (supernumeraryTooth: string) => {
      setSupernumeraryTeeth((last) => {
        const copy = new Set(last);

        copy.delete(supernumeraryTooth);

        return copy;
      });
      unselectTooth(supernumeraryTooth);
    },
    [unselectTooth]
  );

  const addSupernumeraryTooth = useCallback(() => {
    if (!newSupernumeraryTooth) {
      return;
    }

    const result = validation.validate();

    if (!result.$isValid) {
      return;
    }

    if (isSupernumeraryOrNonExistentTooth(newSupernumeraryTooth)) {
      setSupernumeraryTeeth((last) => new Set([...last, newSupernumeraryTooth]));
    }

    selectTooth(newSupernumeraryTooth);

    validation.reset();
    setNewSupernumeraryTooth("");
  }, [isSupernumeraryOrNonExistentTooth, newSupernumeraryTooth, selectTooth, validation]);

  return (
    <div className="mt-3">
      <div className="text-xs flex items-center gap-x-1">
        {title}
        <Icon
          SvgIcon={QuestionIcon}
          tooltip={{
            content:
              "For supernumerary teeth, add S to primary teeth [AS-TS] and 50 to permanent teeth [51-82].",
          }}
          theme="primary"
          size="sm"
        />
      </div>
      <div className="flex gap-2 mt-3">
        {supernumeraryTeeth.size > 0 ? (
          <div className="flex flex-wrap gap-2 max-w-xs mt-1.5">
            {[...supernumeraryTeeth].map((supernumeraryTooth) => (
              <Chip
                key={supernumeraryTooth}
                onClick={() => selectTooth(supernumeraryTooth)}
                className="flex flex-none items-center gap-x-2"
                isSelected={toothSelections.has(supernumeraryTooth)}
              >
                {supernumeraryTooth}
                <ButtonIcon
                  onClick={(e) => {
                    e.stopPropagation(); // prevents the pill from being clicked
                    deleteSupernumeraryTooth(supernumeraryTooth);
                  }}
                  size="xs"
                  SvgIcon={CancelIcon}
                />
              </Chip>
            ))}
          </div>
        ) : null}
        <FormFieldInput
          placeholder="Tooth #"
          className="w-40"
          maxLength={2}
          error={validation.result.$error}
          value={newSupernumeraryTooth}
          onChange={(e) => {
            validation.reset();
            setNewSupernumeraryTooth(e.target.value.toUpperCase());
          }}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              e.preventDefault();
              addSupernumeraryTooth();
            }
          }}
        />
        <Button className="self-start p-1.5" onClick={addSupernumeraryTooth} theme="secondary" size="custom">
          <Icon SvgIcon={PlusCircleIcon} />
        </Button>
      </div>
    </div>
  );
};
