import { PerioChartExamEntryVO, PerioChartExamVO } from "@libs/api/generated-api";
import { ChangeTrackedRecord, createChangeTrackedRecord } from "utils/changeTrackedRecord";
import { PerioChartExamInfo } from "./PerioTypes";

export type SequenceType = PerioChartExamEntryVO["perioSequenceType"];

const entrySchema: (keyof PerioChartExamEntryVO)[] = [
  "toothNum",
  "perioSequenceType",
  "mbValue",
  "dbValue",
  "mlValue",
  "dlValue",
  "bValue",
  "lValue",
];

export interface RecordCreatedHandler {
  (record: ChangeTrackedRecord<PerioChartExamEntryVO>): void;
}

/**
 * A collection of ChangeTrackedRecords of exam entries. There can be up to 32 (teeth count) * 10 (sequence count) entries.
 * The entries are lazily created as they are used, as some sequence types won't be used by the provider.
 */
export class PerioChartExamRecord {
  private _entries: ChangeTrackedRecord<PerioChartExamEntryVO>[];
  private _recordCreatedListeners: RecordCreatedHandler[] = [];
  private _entriesChangedListeners: Func[] = [];

  private _perioChartExam: PerioChartExamVO;

  constructor(perioChartExam: PerioChartExamVO) {
    this._perioChartExam = perioChartExam;
    this._entries = perioChartExam.entries.map((entry) => createChangeTrackedRecord(entry, entrySchema));
    for (const record of this._entries) {
      this._subscribeRecord(record);
    }
  }

  public getExamInfo(): PerioChartExamInfo {
    return this._perioChartExam;
  }

  public hasSequenceEntries(sequenceType: SequenceType) {
    return this._entries.some((record) => sequenceType === record.perioSequenceType);
  }

  public getEntry(toothNumber: number, sequenceType: SequenceType) {
    return this._entries.find(
      (record) => sequenceType === record.perioSequenceType && toothNumber === record.toothNum
    );
  }

  public getOrCreateEntry(toothNumber: number, sequenceType: SequenceType) {
    const record = this.getEntry(toothNumber, sequenceType);

    if (record != null) {
      return record;
    }

    return this._createRecord(toothNumber, sequenceType);
  }

  private _createRecord(toothNumber: number, sequenceType: SequenceType) {
    const record = createChangeTrackedRecord<PerioChartExamEntryVO>(
      {
        perioSequenceType: sequenceType,
        toothNum: toothNumber,
      },
      entrySchema
    );

    this._entries.push(record);
    this._subscribeRecord(record);

    this._notifyRecordsCreatedListeners(record);

    return record;
  }

  public getChangedEntries() {
    return this._entries.filter((entry) => entry.isModified());
  }

  public acceptChanges() {
    this._entries.forEach((entry) => entry.acceptChanges());
  }

  public subscribeToRecordsCreated(handler: RecordCreatedHandler) {
    this._recordCreatedListeners.push(handler);

    let unsubscribed = false;

    return () => {
      if (!unsubscribed) {
        this._recordCreatedListeners.splice(this._recordCreatedListeners.indexOf(handler), 1);
        unsubscribed = true;
      }
    };
  }
  public subscribeToEntriesChanged(handler: Func) {
    this._entriesChangedListeners.push(handler);

    let unsubscribed = false;

    return () => {
      if (!unsubscribed) {
        this._entriesChangedListeners.splice(this._entriesChangedListeners.indexOf(handler), 1);
        unsubscribed = true;
      }
    };
  }

  private _notifyRecordsCreatedListeners(record: ChangeTrackedRecord<PerioChartExamEntryVO>) {
    // Iterate from reverse order in case unsubsribes happen while iterating.
    for (let i = this._recordCreatedListeners.length - 1; i >= 0; --i) {
      this._recordCreatedListeners[i](record);
    }
  }
  private _notifyEntriesChangedListeners() {
    for (let i = this._entriesChangedListeners.length - 1; i >= 0; --i) {
      this._entriesChangedListeners[i]();
    }
  }
  private _subscribeRecord(record: ChangeTrackedRecord<PerioChartExamEntryVO>) {
    record.subscribe(() => {
      this._notifyEntriesChangedListeners();
    });
  }
}
