export enum TwainEvent {
  whenReady = "OnWebTwainReady",
  afterEach = "OnPostTransfer",
  afterAll = "OnPostAllTransfers",
  onSourceUIClose = "OnSourceUIClose",
}

/**
 * Generally the flow is that devices should be enumerated by a calling page, displayed to the user
 * for selection, and then a capture should be initiated on the selected device. The calling page
 * will receive image data back as the capture promise resolves, and when all images are captured
 * the calling page will close the selected source.
 */

export type NotFoundCallback = (
  ProductName: string,
  InstallerUrl: string,
  bHTML5: boolean,
  bIE: boolean,
  bSafari: boolean,
  bSSL: boolean,
  strIEVersion: number
) => void;
