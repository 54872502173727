import React from "react";
import { cx } from "@libs/utils/cx";
import { Checkbox } from "@libs/components/UI/Checkbox";

export const CheckboxRow: React.FC<{
  label: React.ReactNode;
  checked: boolean;
  disabled?: boolean;
}> = ({ label, checked, disabled }) => {
  return (
    <div className={cx("flex flex-row space-x-1 pointer-events-none", disabled && "opacity-50")}>
      <Checkbox readOnly checked={checked}>
        {label}
      </Checkbox>
    </div>
  );
};
