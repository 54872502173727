import React from "react";
import {
  UnscheduledProcedureEntryVO,
  UnscheduledProcedureResponse,
  UnscheduledProcedureTotalVO,
} from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getAbsoluteUrl } from "@libs/utils/location";
import { formatCurrency } from "@libs/utils/currency";
import { getLocalDate } from "@libs/utils/date";
import { phoneNumberFormatter } from "@libs/utils/phone";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";
import { handleError } from "utils/handleError";
import { useDownloadQueryPages } from "components/Dashboard/hooks/useDownloadInfiniteQuery";
import { paths } from "utils/routing/paths";
import { UNSCHEDULED_TREATMENT_HEADERS } from "components/Dashboard/UnscheduledTreatment/constants";
import { getTotals } from "components/Dashboard/Tables/utils";
import { useCurrentUrl, useOrigin } from "contexts/OriginContext";

const formatAppointmentDataAsCsvUrl = (params: {
  rows: UnscheduledProcedureEntryVO[];
  totals: UnscheduledProcedureTotalVO;
  origin: string;
}) => {
  const { rows, totals, origin } = params;

  const columnTitles = formatCsvRow([
    ...UNSCHEDULED_TREATMENT_HEADERS.map(({ label }) => label).filter(Boolean),
    "Patient URL",
  ]);
  const rowsContent = rows.map((row) => {
    const {
      patient,
      previousAppointmentDate,
      nextAppointmentDate,
      patientPhone,
      carrier,
      priority,
      dentalProcedure,
      provider,
      treatmentPlanName,
      negotiatedRate,
      procedureArea,
    } = row;

    return formatCsvRow([
      patient.fullDisplayName,
      previousAppointmentDate ?? "",
      patientPhone ? phoneNumberFormatter(patientPhone) : "",
      carrier?.name ?? "",
      procedureArea ?? "",
      dentalProcedure.cdtCode,
      dentalProcedure.name,
      provider.shortDisplayName,
      treatmentPlanName ?? "",
      `${priority ?? ""}`,
      nextAppointmentDate ?? "",
      formatCurrency(negotiatedRate),
      getAbsoluteUrl(origin, paths.patient({ patientId: patient.id })),
    ]);
  });
  const totalsContent = formatCsvRow([
    `Total Patients ${totals.totalDistinctUnscheduledPatients} | Total Procedures ${totals.totalDistinctUnscheduledProcedures}`,
    ...(Array.from({ length: 12 }).fill("") as string[]),
  ]);

  return [columnTitles, ...rowsContent, totalsContent];
};

export const useDownloadUnscheduledTreatmentCsv = (params: {
  startDate: string;
  endDate?: string;
  unscheduledTreatmentQuery: UseInfiniteApiQueryResult<UnscheduledProcedureResponse>;
}) => {
  const downloading = useBoolean(false);

  const { startDownload } = useDownloadQueryPages(params.unscheduledTreatmentQuery);
  const origin = useOrigin();
  const currentUrl = useCurrentUrl();

  return {
    isDownloading: downloading.isOn,
    downloadCSV: React.useCallback(async () => {
      downloading.on();

      try {
        const result = await startDownload();
        const rows = result.pages.flatMap((item) => item.data.entries);
        const totals = getTotals(result);

        if (totals) {
          const docMetadata = getArchyCsvMetadata(currentUrl, {
            startDate: getLocalDate(params.startDate),
            endDate: params.endDate ? getLocalDate(params.endDate) : undefined,
          });
          const csvRows = [
            ...docMetadata.headers,
            ...formatAppointmentDataAsCsvUrl({
              rows,
              totals,
              origin,
            }),
          ];

          saveCsv(csvRows, `UnscheduledTreatment_${docMetadata.fileNameTag}`);
        }
      } catch (e) {
        handleError(e);
      } finally {
        downloading.off();
      }
    }, [downloading, startDownload, params.startDate, params.endDate, origin, currentUrl]),
  };
};
