import { FC } from "react";
import { Link, useLocation } from "react-router-dom";
import { AutomationJourneyVO } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { ReactComponent as BellIcon } from "@libs/assets/icons/notification.svg";
import { ReactComponent as CheckedIcon } from "@libs/assets/icons/checked-checkbox.svg";
import { ReactComponent as BackInTimeIcon } from "@libs/assets/icons/history.svg";
import { ReactComponent as FormIcon } from "@libs/assets/icons/form.svg";
import { paths } from "utils/routing/paths";
import { journeyNames } from "components/Communications/Automations/utils";
import { IconCard } from "components/UI/IconCard";

const journeyItems: {
  type: AutomationJourneyVO["type"];
  title: string;
  description: string;
  SvgIcon: IconComponent;
}[] = [
  {
    type: "APPOINTMENT",
    title: journeyNames.APPOINTMENT,
    description: "Reminder patients about upcoming appointments",
    SvgIcon: BellIcon,
  },
  {
    type: "POST_APPOINTMENT",
    title: journeyNames.POST_APPOINTMENT,
    description: "Follow up post appointment",
    SvgIcon: CheckedIcon,
  },
  {
    type: "RECALL",
    title: journeyNames.RECALL,
    description: "Encourage patients to schedule appointments",
    SvgIcon: BackInTimeIcon,
  },
  {
    type: "FORMS",
    title: journeyNames.FORMS,
    description: "Ensure paperwork is handled in advance",
    SvgIcon: FormIcon,
  },
];

export const AutomationJourneys: FC = () => {
  const location = useLocation();

  return (
    <div className="flex gap-x-6 items-center">
      {journeyItems.map((journeyItem) => {
        return (
          <Link
            key={journeyItem.type}
            to={paths.journey({ journeyType: journeyItem.type }, { from: getFullUrl(location) })}
            className="flex-1 block"
          >
            <IconCard {...journeyItem} />
          </Link>
        );
      })}
    </div>
  );
};
