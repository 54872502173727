import { ChangeEvent, useCallback, useId, useMemo } from "react";
import { DentalProcedureVO, PatientToothVO } from "@libs/api/generated-api";
import { DraftPatientProcedureRequest } from "components/Charting/draftPatientProcedure";
import { ToothOptionsList } from "components/Charting/ToothOptionsList";
import { ToggleButton } from "components/UI/ToggleButton";
import {
  isArchDisabledByDentalProcedure,
  isAreaSelectionRequired,
} from "components/Charting/patientProcedureSchema";
import { useIsToothDisabledByDentalProcedure } from "components/Charting/useIsToothDisabledByAreaSelection";

interface Props {
  dentalProcedure: DentalProcedureVO;
  teeth: PatientToothVO[];
  arch: DraftPatientProcedureRequest["arch"];
  onUpdateDraft: (updates: Partial<DraftPatientProcedureRequest>) => void;
}

export const ArchOptions: React.FC<Props> = ({ dentalProcedure, teeth, arch, onUpdateDraft }) => {
  const toothSelections = useMemo(() => {
    if (!arch) {
      return new Set<string>();
    }

    const toothNames = teeth
      .filter((tooth) => tooth.arch === arch)
      .map((tooth) => tooth.toothName)
      .filter(Boolean);

    return new Set(toothNames);
  }, [arch, teeth]);

  const handleChange = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      const newArch = e.target.value as PatientToothVO["arch"];
      const unselect = !isAreaSelectionRequired(dentalProcedure.areaSelection) && newArch === arch;

      onUpdateDraft({ arch: unselect ? undefined : newArch });
    },
    [onUpdateDraft, arch, dentalProcedure]
  );

  const archRadioName = useId();
  const isToothDisabled = useIsToothDisabledByDentalProcedure(dentalProcedure);

  return (
    <div className="flex items-center">
      <div className="flex-none pr-3">
        <ToggleButton
          type="radio"
          className="mb-3"
          shape="square"
          checked={arch === "ARCH_UPPER"}
          name={archRadioName}
          disabled={isArchDisabledByDentalProcedure("ARCH_UPPER", dentalProcedure)}
          value="ARCH_UPPER"
          onChange={handleChange}
        >
          U
        </ToggleButton>
        <ToggleButton
          type="radio"
          shape="square"
          checked={arch === "ARCH_LOWER"}
          name={archRadioName}
          disabled={isArchDisabledByDentalProcedure("ARCH_LOWER", dentalProcedure)}
          value="ARCH_LOWER"
          onChange={handleChange}
        >
          L
        </ToggleButton>
      </div>
      <ToothOptionsList
        disabled={true}
        type="checkbox"
        teeth={teeth}
        isToothDisabled={isToothDisabled}
        canDeselect={!isAreaSelectionRequired(dentalProcedure.areaSelection)}
        toothSelections={toothSelections}
      />
    </div>
  );
};
