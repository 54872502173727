import { forwardRef } from "react";
import { BaseOptionInputProps, OptionInput } from "@libs/components/UI/OptionInput";
import { Icon, IconProps } from "@libs/components/UI/Icon";
import { cx } from "@libs/utils/cx";

export interface ToggleIconProps
  extends Omit<BaseOptionInputProps, "size">,
    Pick<IconProps, "theme" | "size" | "tooltip"> {
  Checked: IconComponent;
  UnChecked: IconComponent;
  inactive?: boolean;
}

export const ToggleIcon = forwardRef<HTMLInputElement, ToggleIconProps>(
  ({ className, theme, size, tooltip, Checked, UnChecked, ...props }, ref) => {
    return (
      <OptionInput
        className={cx(!props.disabled && !props.inactive && "cursor-pointer", className)}
        customContent={
          <Icon
            disabled={props.disabled}
            theme={theme}
            size={size}
            tooltip={tooltip}
            SvgIcon={props.checked ? Checked : UnChecked}
          />
        }
        ref={ref}
        type="checkbox"
        {...props}
      />
    );
  }
);
