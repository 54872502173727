import { JournalEventVO } from "@libs/api/generated-api";
import { titleCaseConstant } from "@libs/utils/casing";
import { formatCurrency } from "@libs/utils/currency";
import { EndOfDayReportRow } from "components/Dashboard/EndOfDayReport/EndOfDayReportRow";
import { SectionFooter } from "components/Dashboard/Tables/SectionFooter";
import { SectionHeader } from "components/Dashboard/Tables/SectionHeader";

export type EntryTypeGroup = {
  entryType: JournalEventVO["eventType"];
  rows: JournalEventVO[];
  total: number;
};
type Props = {
  group: EntryTypeGroup;
};
export const EndOfDayEntryTypeGroup: React.FC<Props> = ({ group }) => {
  const { entryType, rows, total } = group;

  const title = titleCaseConstant(entryType);

  return (
    <>
      <SectionHeader
        className={`
          flex
          items-center
          gap-4
          col-span-full
          font-sansSemiBold
          text-xs
        `}
      >
        {title}
      </SectionHeader>
      {rows.map((row, i) => {
        const key = `${row.patient.id}-${row.eventDate}-${entryType}-${row.adjustmentName ?? "_"}-${
          row.dentalProcedure?.cdtCode ?? "_"
        }-${row.amount}-${row.paymentMethod ?? "_"}-${row.provider?.id ?? "_"}-${i}`;

        return <EndOfDayReportRow key={key} row={row} last={i === rows.length - 1} />;
      })}
      <SectionFooter className="col-span-3" />
      <SectionFooter className="justify-end">{title} Total</SectionFooter>
      <SectionFooter align="right">{formatCurrency(total)}</SectionFooter>
    </>
  );
};
