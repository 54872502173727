import { PropsWithChildren } from "react";

export const SetupStep: React.FC<PropsWithChildren & { title: string; description: string }> = ({
  description,
  title,
  children,
}) => {
  return (
    <div className="flex rounded-md flex-col border border-slate-200 p-3 gap-2">
      <div className="font-sansSemiBold text-sm">{title}</div>
      <div className="text-xs">{description}</div>
      <div className="flex items-center gap-2">{children}</div>
    </div>
  );
};
