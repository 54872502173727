/* eslint-disable @typescript-eslint/no-magic-numbers */
import {
  add,
  sub,
  startOfDay,
  startOfYear,
  startOfMonth,
  startOfWeek,
  startOfQuarter,
  isSameDay,
  isSameWeek,
  isSameMonth,
  isSameQuarter,
  isSameYear,
  endOfYear,
  endOfQuarter,
  endOfMonth,
  endOfWeek,
  format,
  getDate,
  getMonth,
  getQuarter,
} from "date-fns";
import { WEEK_IN_DAYS, YEAR_IN_DAYS, getDurationDays, getLocalDate } from "@libs/utils/date";
import { half } from "@libs/utils/math";
import { MAX_DAYS_X_AXIS } from "components/Dashboard/Charting/theme";

import { DashboardChartDisplay, TimeSegment, TimeSeriesResolutionOption } from "utils/routing/dashboard";

// How many units we typically project for any chart (2 months, weeks...etc)

export const roundDateForResolution = (date: Date, resolution: TimeSeriesResolutionOption) => {
  switch (resolution) {
    case "DAY": {
      return startOfDay(date);
    }
    case "WEEK": {
      return startOfWeek(date);
    }
    case "MONTH": {
      return startOfMonth(date);
    }
    case "YEAR": {
      return startOfYear(date);
    }
    case "QUARTER": {
      return startOfQuarter(date);
    }
    default:
    //no default
  }

  return date;
};

export const getEnd = (resolution: TimeSeriesResolutionOption, startDate: Date) => {
  const end = add(startDate, { days: MAX_DAYS_X_AXIS - 1 });

  if (resolution !== "DAY") {
    // We don't want to include the first day of the next period, unless it's a day resolution
    return sub(end, { days: 1 });
  }

  return end;
};
export const getDefaultStartFromNow = (now: Date, includeProjectedDates: boolean) => {
  const dayStart = startOfDay(now);

  // Projected will be 15 days before today, and 15 days after today
  // otherwise 30 days before today
  const days = includeProjectedDates ? half(MAX_DAYS_X_AXIS - 1) : MAX_DAYS_X_AXIS - 1;

  return sub(dayStart, { days });
};

/**
 * Given a startDate and a resolution, will return the bounds of that page.
 * @param startDate Where bound should begin
 * @param resolution Timeseries resolution under which data is being viewed (YEAR, MONTH...etc)
 * @param includeProjectedDates Whether we want to show some projected data, in other words future data in the same page when viewing today
 */
export const getBoundsForResolution = ({
  startDate,
  resolution,
}: {
  startDate: Date;
  resolution: TimeSeriesResolutionOption;
  includeProjectedDates?: boolean;
}) => {
  const endDate = getEnd(resolution, startDate);

  return {
    startDate,
    endDate,
  };
};

export const isNowForResolution = (date: Date, resolution: TimeSeriesResolutionOption): boolean => {
  const now = new Date();
  const IS_SAME: Record<TimeSeriesResolutionOption, () => boolean> = {
    DAY: () => isSameDay(date, now),
    WEEK: () => isSameWeek(date, now),
    MONTH: () => isSameMonth(date, now),
    QUARTER: () => isSameQuarter(date, now),
    YEAR: () => isSameYear(date, now),
  };

  return IS_SAME[resolution]();
};

export const RESOLUTION_TO_MAX_DAYS_BAR: Record<TimeSeriesResolutionOption, number> = {
  DAY: YEAR_IN_DAYS + WEEK_IN_DAYS * 4,
  WEEK: YEAR_IN_DAYS * WEEK_IN_DAYS,
  MONTH: -1,
  QUARTER: -1,
  YEAR: -1,
};

export const RESOLUTION_TO_MAX_DAYS_LINE: Record<TimeSeriesResolutionOption, number> = {
  DAY: YEAR_IN_DAYS * 4 + WEEK_IN_DAYS * 4,
  WEEK: -1,
  MONTH: -1,
  QUARTER: -1,
  YEAR: -1,
};

export const getMaxDayCountForResolution = ({
  resolution,
  chartDisplay,
}: {
  resolution: TimeSeriesResolutionOption;
  chartDisplay?: DashboardChartDisplay;
}) => {
  if (!chartDisplay) {
    return undefined;
  }

  const dayCount =
    chartDisplay === "line"
      ? RESOLUTION_TO_MAX_DAYS_LINE[resolution]
      : RESOLUTION_TO_MAX_DAYS_BAR[resolution];

  if (dayCount >= 0) {
    return dayCount;
  }

  return undefined;
};
export const isOutOfRange = ({
  startDate,
  endDate,
  resolution,
  chartDisplay,
}: {
  startDate: Date;
  endDate: Date;
  resolution?: TimeSeriesResolutionOption;
  chartDisplay?: DashboardChartDisplay;
}) => {
  if (!resolution) {
    return false;
  }

  const maxRangeForResolution = getMaxDayCountForResolution({ resolution, chartDisplay });
  const newDurationDays = getDurationDays({ start: startDate, end: endDate });

  return maxRangeForResolution && newDurationDays > maxRangeForResolution;
};

type TimeSeriesParams = {
  resolution: TimeSeriesResolutionOption;
  startDate: Date;
  endDate: Date;
};
export const getDisabledMessageForTimeSeriesParams = ({
  resolution,
  startDate,
  endDate,
}: TimeSeriesParams): string | undefined => {
  const durationDays = getDurationDays({ start: startDate, end: endDate });
  const [maxDaysLine, maxDaysBar] = ["line" as const, "bar" as const].map(
    (chartDisplay) => getMaxDayCountForResolution({ resolution, chartDisplay }) ?? Number.POSITIVE_INFINITY
  );
  const invalid = durationDays > maxDaysLine && durationDays > maxDaysBar;

  if (invalid) {
    return "Please choose a range shorter date range, or select a larger resolution to view chart data";
  }

  return undefined;
};

type DateFunctions = {
  startOfPeriod: (date: Date) => Date;
  endOfPeriod: (date: Date) => Date;
  isSamePeriod: (dateLeft: Date, dateRight: Date) => boolean;
};

export const DATE_FUNCTIONS_BY_RESOLUTION: Record<
  Exclude<TimeSeriesResolutionOption, "DAY">,
  DateFunctions
> = {
  WEEK: {
    startOfPeriod: startOfWeek,
    endOfPeriod: endOfWeek,
    isSamePeriod: isSameWeek,
  },
  MONTH: {
    startOfPeriod: startOfMonth,
    endOfPeriod: endOfMonth,
    isSamePeriod: isSameMonth,
  },
  QUARTER: {
    startOfPeriod: startOfQuarter,
    endOfPeriod: endOfQuarter,
    isSamePeriod: isSameQuarter,
  },
  YEAR: {
    startOfPeriod: startOfYear,
    endOfPeriod: endOfYear,
    isSamePeriod: isSameYear,
  },
};

export const getSelectedWithIncompletePeriod = ({
  date,
  timeSeriesWindow,
  selectedTimeSegment,
  resolution,
}: {
  date: Date;
  timeSeriesWindow: TimeSegment;
  selectedTimeSegment: TimeSegment;
  resolution: TimeSeriesResolutionOption;
}) => {
  if (resolution === "DAY") {
    return selectedTimeSegment;
  }

  const { isSamePeriod } = DATE_FUNCTIONS_BY_RESOLUTION[resolution];

  let startDate = selectedTimeSegment.startDate;
  let endDate = selectedTimeSegment.endDate;

  if (isSamePeriod(timeSeriesWindow.startDate, date) && !isSameDay(startDate, timeSeriesWindow.startDate)) {
    startDate = timeSeriesWindow.startDate;
  }

  if (isSamePeriod(timeSeriesWindow.endDate, date) && !isSameDay(endDate, timeSeriesWindow.endDate)) {
    endDate = timeSeriesWindow.endDate;
  }

  return {
    startDate,
    endDate,
  };
};

type DateSize = "sm" | "md" | "lg";
type FormatterOptions = { size: DateSize; dateWindow: { startDate: Date; endDate: Date } };
// eslint-disable-next-line max-statements, complexity
export const formatDateForResolution = (
  selectedResolution: TimeSeriesResolutionOption,
  dateString: string,
  options: FormatterOptions
): string => {
  const { size, dateWindow } = options;
  const date = getLocalDate(dateString);

  switch (selectedResolution) {
    case "DAY": {
      if (size === "lg") {
        return format(date, "MMMM do");
      } else if (size === "md") {
        return format(date, "MMM do");
      }

      let formatted = format(date, "d");

      if (
        getDate(date) === 1 ||
        isSameDay(dateWindow.startDate, date) ||
        isSameDay(dateWindow.endDate, date)
      ) {
        formatted = format(date, "MMM d");
      }

      return formatted;
    }
    case "WEEK": {
      if (size === "lg") {
        return `Week of ${format(date, "M/d")}`;
      } else if (size === "md") {
        return `Week ${format(date, "M/d")}`;
      }

      return format(date, "M/d");
    }
    case "MONTH": {
      if (size === "lg") {
        return format(date, "MMMM, yyyy");
      } else if (size === "md") {
        return format(date, "MMM yyyy");
      }

      if (getMonth(date) === 0 || isSameMonth(dateWindow.startDate, date)) {
        return format(date, "MMM yyyy");
      }

      return format(date, "MMM");
    }
    case "QUARTER": {
      let formatted = format(date, "QQQ");

      if (getQuarter(date) === 1) {
        formatted = format(date, "QQQ yyyy");
      }

      return formatted;
    }
    case "YEAR": {
      return format(date, "yyyy");
    }
    // No default
  }

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  /* istanbul ignore next */
  return dateString;
};
