import { differenceInYears } from "date-fns";
import { useMemo } from "react";
import { PatientVO, UpdatePatientRequest } from "@libs/api/generated-api";
import { formatAsISODate, formatDate, getLocalDate } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { PatientInfoCard } from "components/PatientProfile/PatientOverview/Info/PatientInfoCard";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { FormFieldUserSsnInput } from "components/UI/FormFieldUserSsnInput";
import { OnChangePatientHandler } from "components/PatientProfile/PatientOverview/types";
import { FormFieldSelectGender } from "components/UI/FormFieldSelectGender";
import { FormFieldSelectPronoun } from "components/UI/FormFieldSelectPronoun";
import { formatInches, heightToInches, inchesToHeightInputValues } from "utils/measurements";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";
import { STATUS_OPTIONS } from "components/Patients/FiltersFlyoverContent";
import { EditPatientValidation } from "components/Patient/formSchemas";
import { useNow } from "hooks/useNow";

export const PatientPersonalInformation: React.FC<{
  isEditing: boolean;
  onChange: OnChangePatientHandler;
  patient: UpdatePatientRequest | PatientVO;
  patientId: number;
  validation?: EditPatientValidation;
}> = ({ isEditing, onChange, patient, patientId, validation }) => {
  const disableSsnUpdates = useBoolean(false);
  const today = useNow();
  const height = useMemo(
    () => inchesToHeightInputValues(patient.personalDetails.heightInInches),
    [patient.personalDetails.heightInInches]
  );

  return (
    <PatientInfoCard title="Personal">
      <FormFieldInput
        required
        edit={isEditing}
        label="First Name"
        error={validation?.firstName.$error}
        value={patient.personalDetails.firstName}
        onChange={(event) => onChange((draft) => (draft.personalDetails.firstName = event.target.value))}
      />
      <FormFieldInput
        label="Middle Name"
        edit={isEditing}
        value={patient.personalDetails.middleName}
        onChange={(event) => onChange((draft) => (draft.personalDetails.middleName = event.target.value))}
      />
      <FormFieldInput
        required
        edit={isEditing}
        label="Last Name"
        error={validation?.lastName.$error}
        value={patient.personalDetails.lastName}
        onChange={(event) => onChange((draft) => (draft.personalDetails.lastName = event.target.value))}
      />
      <FormFieldInput
        label="Preferred Name"
        edit={isEditing}
        value={patient.personalDetails.preferredName}
        onChange={(event) => onChange((draft) => (draft.personalDetails.preferredName = event.target.value))}
      />
      <FormFieldInput
        label="Pronunciation"
        edit={isEditing}
        value={patient.personalDetails.namePronunciation}
        onChange={(event) =>
          onChange((draft) => (draft.personalDetails.namePronunciation = event.target.value))
        }
      />
      <FormFieldSelectMenusDatepicker
        label={isEditing ? "Date of Birth" : "Date of Birth, Age"}
        edit={isEditing}
        required
        error={validation?.dob.$error}
        maxDate={today}
        selected={patient.personalDetails.dob ? getLocalDate(patient.personalDetails.dob) : undefined}
        readOnlyLabel={(date) => `${formatDate(date)}, ${differenceInYears(new Date(), date)}`}
        onChange={(date) =>
          onChange((draft) => (draft.personalDetails.dob = date ? formatAsISODate(date) : ""))
        }
      />
      <FormFieldSelect
        display="label"
        label="Status"
        required
        error={validation?.status.$error}
        value={patient.personalDetails.status}
        options={STATUS_OPTIONS}
        edit={isEditing}
        onChange={(option) => {
          onChange((draft) => (draft.personalDetails.status = option?.value));
        }}
      />
      <FormFieldSelectGender
        label="Gender"
        edit={isEditing}
        value={patient.personalDetails.gender}
        onChange={(option) => onChange((draft) => (draft.personalDetails.gender = option?.value))}
      />
      <FormFieldSelectPronoun
        label="Preferred Pronouns"
        edit={isEditing}
        value={patient.personalDetails.preferredPronouns}
        onChange={(option) => onChange((draft) => (draft.personalDetails.preferredPronouns = option?.value))}
      />
      <div className="flex gap-x-3">
        {isEditing ? (
          <>
            <FormFieldNumberInput
              edit={isEditing}
              label="Feet"
              value={height.feet}
              min={0}
              clamp
              className="flex-1"
              onValueChange={(value) =>
                onChange(
                  (draft) =>
                    (draft.personalDetails.heightInInches = heightToInches({ ...height, feet: value }))
                )
              }
            />
            <FormFieldNumberInput
              edit={isEditing}
              label="Inches"
              value={height.inches}
              min={0}
              max={11}
              clamp
              className="flex-1"
              onValueChange={(value) =>
                onChange(
                  (draft) =>
                    (draft.personalDetails.heightInInches = heightToInches({ ...height, inches: value }))
                )
              }
            />
          </>
        ) : (
          <FormFieldInput
            edit={isEditing}
            label="Height"
            value={formatInches(patient.personalDetails.heightInInches)}
          />
        )}
      </div>
      <FormFieldNumberInput
        edit={isEditing}
        label="Weight (lbs)"
        min={0}
        clamp
        value={patient.personalDetails.weightInPounds}
        onValueChange={(value) => onChange((draft) => (draft.personalDetails.weightInPounds = value))}
      />
      <FormFieldUserSsnInput
        label="SSN"
        userId={patientId}
        disabled={disableSsnUpdates.isOn}
        error={validation?.ssn.$error}
        ssnLastFour={patient.personalDetails.ssnLastFour}
        edit={isEditing}
        value={patient.personalDetails.ssn}
        onValueChange={(value) => {
          onChange((draft) => (draft.personalDetails.ssn = value));
        }}
        onSsnError={() => {
          // prevents the user from inadvertently
          // deleting the patient ssn if the ssn can't load
          disableSsnUpdates.on();
          onChange((draft) => (draft.personalDetails.ssn = undefined));
        }}
      />
    </PatientInfoCard>
  );
};
