/* eslint-disable complexity */
import React from "react";
import { formatCurrency } from "@libs/utils/currency";
import { usePick } from "@libs/hooks/usePick";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { useTableTabsProps } from "components/Dashboard/Adjustments/hooks/useTableTabsProps";
import { useFilterTokenProps } from "components/Dashboard/hooks/useFilterTokenProps";
import { useQueryParams } from "hooks/useQueryParams";
import { DashboardAdjustmentsQuery } from "utils/routing/dashboard";
import { DashboardTableFilters } from "components/Dashboard/Tables/DashboardTableFilters";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import { COLLECTION_ADJUSTMENT_HEADERS } from "components/Dashboard/Adjustments/constants";
import { FooterCell } from "components/Dashboard/Tables/FooterCell";
import { useCollectionAdjustmentsQuery } from "components/Dashboard/Adjustments/hooks/useCollectionAdjustmentsQuery";
import { CollectionAdjustmentsTableRow } from "components/Dashboard/Adjustments/CollectionAdjustmentsTableRow";
import { labelForAdjustmentTypeId } from "components/Dashboard/Adjustments/utils";
import { DashboardFilterFormFieldSelect } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";
import { useSelectedTimeWindowQueries } from "components/Dashboard/Adjustments/hooks/useSelectedTimeWindowQueries";
import { useAdjustmentQueriesAsPieData } from "components/Dashboard/Adjustments/hooks/useAdjustmentQueriesAsPieData";
import { DashboardTableTabs } from "components/Dashboard/Tables/DashboardTableTabs";
import { useDownloadCollectionAdjustmentsCsv } from "components/Dashboard/Adjustments/hooks/useDownloadCollectionAdjustmentsCsv";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";

export const CollectionAdjustmentsTableRoute = () => {
  const { query, updateQuery } = useQueryParams("dashboardAdjustments");
  const handleRouteStateChange = React.useCallback(
    (updates: Partial<DashboardAdjustmentsQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );

  const tableTabProps = useTableTabsProps({ query, handleRouteStateChange });
  const { collectionAdjustmentInfiniteQuery, totals, isLoading, isEmpty, data, totalRows } =
    useCollectionAdjustmentsQuery({
      params: query,
    });
  const {
    selectedTimeSegment,
    collectionAdjustmentsForSelectedTimePeriodQuery,
    carrierAdjustmentsForSelectedTimePeriodQuery,
    carriersQuery,
    adjustmentTypesQuery,
  } = useSelectedTimeWindowQueries();
  const pieData = useAdjustmentQueriesAsPieData(
    carrierAdjustmentsForSelectedTimePeriodQuery,
    collectionAdjustmentsForSelectedTimePeriodQuery,
    carriersQuery,
    adjustmentTypesQuery,
    query.filters
  );

  const handleSortClick = React.useCallback(
    (collectionTableSort: ColumnSortOrder[]) => {
      updateQuery("replaceIn", {
        collectionTableSort,
      });
    },
    [updateQuery]
  );
  const filterParams = usePick(query, ["filters", "patientSearch"]);

  const filterProps = useFilterTokenProps({ options: filterParams });

  const adjustmentTypeOptions = React.useMemo(() => {
    const pieCollectionAdjustments = pieData.filter((item) => item.adjustmentCategory === "collection");

    return pieCollectionAdjustments.map((pieDatum) => {
      const adjustmentTypeId = pieDatum.key;

      return {
        value: adjustmentTypeId,
        label: labelForAdjustmentTypeId(adjustmentTypeId, adjustmentTypesQuery.data),
      };
    });
  }, [pieData, adjustmentTypesQuery.data]);

  const { isDownloading, downloadCSV } = useDownloadCollectionAdjustmentsCsv({
    selectedTimeSegment,
    collectionAdjustmentInfiniteQuery,
  });

  return (
    <>
      <DashboardTableTabs
        {...tableTabProps}
        isDownloading={isDownloading}
        onClickDownload={downloadCSV}
        id="collection-adjustments-table-tabs"
      >
        <DashboardFilterFormFieldSelect
          placeholder="Adjust Type"
          isSearchable={false}
          disabled={adjustmentTypeOptions.length === 0}
          options={adjustmentTypeOptions}
          className="min-w-[140px]"
          dashboardFilters={query.filters}
          dashboardFilterType="customAdjustmentTypeId"
          onChangeFilters={handleRouteStateChange}
        />
      </DashboardTableTabs>
      <DashboardTableFilters {...filterParams}>
        <QueryFilterPills
          {...filterProps}
          numResults={totalRows}
          onUpdateParams={(updatedQuery) => {
            updateQuery("replaceIn", updatedQuery);
          }}
          onClearAll={() => {
            updateQuery("replaceIn", { filters: [], patientSearch: undefined });
          }}
        />
      </DashboardTableFilters>
      <DashboardInfiniteTable
        infiniteQuery={collectionAdjustmentInfiniteQuery}
        columnWidths={COLLECTION_ADJUSTMENT_HEADERS.map(({ width }) => width)}
        id="collectionAdjustmentsTable"
        isEmpty={isEmpty}
        headerRow={
          <DashboardHeaderRow
            headers={COLLECTION_ADJUSTMENT_HEADERS}
            onSortClick={handleSortClick}
            sortOrders={query.collectionTableSort}
          />
        }
        footerRow={
          <>
            <FooterCell>Totals</FooterCell>
            <FooterCell className="col-span-3" />
            <FooterCell loading={isLoading} align="right">
              {totals && formatCurrency(totals.totalCollectionAdjustmentAmount)}
            </FooterCell>
            <FooterCell />
          </>
        }
      >
        {data.map((row, i) => {
          return (
            <CollectionAdjustmentsTableRow
              key={`${row.adjustmentDate}-${i}-${row.patient.id}-${row.collectionAdjustmentAmount}-${
                row.adjustmentNote ?? ""
              }`}
              row={row}
              last={i === data.length - 1}
            />
          );
        })}
      </DashboardInfiniteTable>
    </>
  );
};
