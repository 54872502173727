/* eslint-disable complexity */
import React, { forwardRef, useMemo } from "react";
import { format } from "date-fns";
import {
  JournalEventResponse,
  JournalEventTotalVO,
  JournalEventVO,
  ProviderVO,
} from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { UseInfiniteApiQueryResult, ApiQueryResult } from "@libs/@types/apiQueries";
import { usePick } from "@libs/hooks/usePick";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { useFlattenEntries } from "components/Dashboard/hooks/useFlattenEntries";
import { useFilterTokenProps } from "components/Dashboard/hooks/useFilterTokenProps";
import { DailyReportQuery } from "utils/routing/dashboard";
import { DashboardTableFilters } from "components/Dashboard/Tables/DashboardTableFilters";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";
import { END_OF_DAY_REPORT_HEADERS } from "components/Dashboard/EndOfDayReport/constants";
import { DashboardFilterFormFieldSelect } from "components/Dashboard/Tables/DashboardFilterFormFieldSelect";
import { DashboardTableHeader } from "components/Dashboard/Tables/DashboardTableHeader";
import { useProviderFilterOptions } from "components/Dashboard/hooks/useProviderFilterOptions";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";
import { getTotals } from "components/Dashboard/Tables/utils";
import { groupBy } from "utils/groupBy";
import {
  EndOfDayEntryTypeGroup,
  EntryTypeGroup,
} from "components/Dashboard/EndOfDayReport/EndOfDayEntryTypeGroup";

type Props = {
  endOfDayReportInfiniteQuery: UseInfiniteApiQueryResult<JournalEventResponse>;
  providersQuery: ApiQueryResult<ProviderVO[]>;
  onUpdateParams: (updates: Partial<DailyReportQuery>) => void;
  query: DailyReportQuery;
  focusDate: Date;
  isDownloading: boolean;
  onClickDownloadCSV: Func;
  onClickPrint: Func;
  isLoadingPrint: boolean;
};

const EVENT_TYPE_FILTER_OPTIONS = [
  "PRODUCTION",
  "COLLECTION_ADJUSTMENT",
  "INSURANCE_ADJUSTMENT",
  "PATIENT_PAYMENT",
  "INSURANCE_PAYMENT",
].map((value) => ({ value, label: sentenceCaseConstant(value) }));

const EVENT_TYPE_TO_TOTAL_KEY: Record<JournalEventVO["eventType"], keyof JournalEventTotalVO> = {
  PRODUCTION: "totalGrossProductionAmount",
  COLLECTION_ADJUSTMENT: "totalCollectionAdjustmentAmount",
  INSURANCE_ADJUSTMENT: "totalInsuranceAdjustmentAmount",
  PATIENT_PAYMENT: "totalPatientPaymentAmount",
  INSURANCE_PAYMENT: "totalInsurancePaymentAmount",
};

export const EndOfDayReportTable = forwardRef<HTMLDivElement | null, Props>(
  (
    {
      endOfDayReportInfiniteQuery,
      onUpdateParams,
      providersQuery,
      query,
      focusDate,
      isDownloading,
      onClickDownloadCSV,
      onClickPrint,
      isLoadingPrint,
    },
    printRef
  ) => {
    const isPrinting = Boolean(printRef);
    const { tableSort, filters } = query;
    const { data: paginatedData, isLoading } = endOfDayReportInfiniteQuery;

    const handleSortClick = React.useCallback(
      (newTableSort: ColumnSortOrder[]) => {
        onUpdateParams({
          tableSort: newTableSort,
        });
      },
      [onUpdateParams]
    );
    const filterParams = usePick(query, ["filters", "patientSearch"]);

    const filterProps = useFilterTokenProps({ options: filterParams, providers: providersQuery.data });
    const totalRows = getInfiniteQueryPagingDetails(paginatedData)?.totalElements;
    const rows = useFlattenEntries(paginatedData);

    const groupedRows = useMemo(() => {
      const order = rows.reduce<JournalEventVO["eventType"][]>((acc, row) => {
        if (!acc.includes(row.eventType)) {
          acc.push(row.eventType);
        }

        return acc;
      }, []);
      const groupedByEventType = groupBy(rows, "eventType");
      const totals = getTotals<JournalEventResponse>(paginatedData);

      return order.reduce<EntryTypeGroup[]>((acc, key) => {
        acc.push({
          entryType: key,
          rows: groupedByEventType[key] ?? [],
          total: totals ? totals[EVENT_TYPE_TO_TOTAL_KEY[key]] : 0,
        });

        return acc;
      }, []);
    }, [paginatedData, rows]);

    const isEmpty = !isLoading && rows.length === 0;
    const providerFilterOptions = useProviderFilterOptions(providersQuery.data);

    const tableDate = format(focusDate, "MMMM do, yyyy");
    const tableFilters = (
      <DashboardTableFilters {...filterParams}>
        <QueryFilterPills
          {...filterProps}
          numResults={totalRows}
          onUpdateParams={onUpdateParams}
          onClearAll={() => {
            onUpdateParams({ filters: [], patientSearch: undefined });
          }}
        />
      </DashboardTableFilters>
    );

    return (
      <>
        <DashboardTableHeader
          title={tableDate}
          query={query}
          downloadCsvDisabled={isEmpty}
          id="end-of-day-filters"
          isDownloading={isDownloading}
          onClickDownload={onClickDownloadCSV}
          onUpdateParams={onUpdateParams}
          includeExpandTable={false}
          isPrinting={isLoadingPrint}
          onClickPrint={onClickPrint}
        >
          <DashboardFilterFormFieldSelect
            aria-label="Entry Type"
            placeholder="Entry Type"
            isSearchable={false}
            dashboardFilters={filters}
            dashboardFilterType="eventType"
            options={EVENT_TYPE_FILTER_OPTIONS}
            className="min-w-[170px]"
            onChangeFilters={onUpdateParams}
          />
          <DashboardFilterFormFieldSelect
            placeholder="Provider"
            aria-label="Provider"
            isSearchable={false}
            dashboardFilters={filters}
            dashboardFilterType="providerId"
            options={providerFilterOptions}
            className="min-w-[160px]"
            onChangeFilters={onUpdateParams}
          />
        </DashboardTableHeader>

        {tableFilters}
        <DashboardInfiniteTable
          infiniteQuery={endOfDayReportInfiniteQuery}
          columnWidths={END_OF_DAY_REPORT_HEADERS.map(({ width }) => width)}
          id="endOfDayReportTable"
          isEmpty={isEmpty}
          ref={printRef}
          className="rounded-b-md"
          headerRow={
            <>
              {isPrinting && (
                <>
                  <div className="col-span-full font-sansSemiBold text-sm py-4">
                    Ledger entries for {tableDate}
                  </div>
                  <div className="col-span-full">{tableFilters}</div>
                </>
              )}
              <DashboardHeaderRow
                headers={END_OF_DAY_REPORT_HEADERS}
                onSortClick={handleSortClick}
                sortOrders={tableSort}
              />
            </>
          }
        >
          {groupedRows.map((group) => {
            return <EndOfDayEntryTypeGroup group={group} key={group.entryType} />;
          })}
        </DashboardInfiniteTable>
      </>
    );
  }
);
