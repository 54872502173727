import React from "react";
import { CanvasEditorRef, Props } from "components/PatientProfile/Imaging/types/imageEditor";
import { DarkRoomSkeleton } from "components/PatientProfile/Imaging/MountRoute/DarkRoomSkeleton";

const LazyReactImageEditorChildren = React.lazy(
  () => import(/* webpackChunkName: "react-fabric-image-editor" */ "./ReactImageEditor")
);

export const LazyReactImageEditor: React.FC<Props> = React.forwardRef<CanvasEditorRef, Props>(
  (props, forwardedRef) => {
    return (
      <React.Suspense
        fallback={<DarkRoomSkeleton containerClassName="flex h-full" className="flex-1 h-full" />}
      >
        <LazyReactImageEditorChildren {...props} ref={forwardedRef} />
      </React.Suspense>
    );
  }
);
