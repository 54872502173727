import { Fragment, PropsWithChildren } from "react";
import {
  BenefitCoverageVO,
  BenefitLimitationVO,
  PatientInsuranceResponse,
  RecentProcedureHistoryItemVO,
} from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatCurrency } from "@libs/utils/currency";
import { formatISODate } from "@libs/utils/date";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import {
  useFamilyFeeCalculationValues,
  useIndividualFeeCalculationValues,
} from "components/Patient/insuranceHooks";
import { useLimitationAsString } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/hooks";

const TwoColGrid: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="grid grid-cols-[3fr_4fr] gap-x-3 text-xs">{children}</div>;
};

const ThreeColGrid: React.FC<PropsWithChildren> = ({ children }) => {
  return <div className="grid grid-cols-[4fr_3fr_3fr] gap-x-3 text-xs">{children}</div>;
};

const HeaderCell: React.FC<{ align?: "left" | "right"; value: string }> = ({ align = "left", value }) => {
  return (
    <span className={cx("mb-1 font-sansSemiBold", align === "left" ? "text-left" : "text-right")}>
      {value}
    </span>
  );
};

const ValueCell: React.FC<PropsWithChildren & { align?: "left" | "right"; value?: string }> = ({
  align = "left",
  children,
  value,
}) => {
  return (
    <span className={cx("mt-1", align === "left" ? "text-left" : "text-right")}>{children ?? value}</span>
  );
};

const CurrencyCell: React.FC<{ value: number | undefined }> = ({ value }) => {
  return <span className="mt-1 text-right">{formatCurrency(value ?? 0)}</span>;
};

interface BenefitsTableProps {
  rows: BenefitCoverageVO[];
}

export const BenefitsTable: React.FC<BenefitsTableProps> = ({ rows }) => {
  return (
    <ThreeColGrid>
      <HeaderCell value="Categories" />
      <HeaderCell align="right" value="Coverage" />
      <HeaderCell align="right" value="Copay" />
      {rows.map((coverage) => (
        <Fragment key={coverage.uuid}>
          <ValueCell value={coverage.benefitCategory} />
          <ValueCell align="right" value={`${coverage.percentCoverage} %`} />
          <CurrencyCell value={coverage.copayAmount} />
        </Fragment>
      ))}
    </ThreeColGrid>
  );
};

interface DeductiblesTableProps {
  primaryInsurance: PatientInsuranceResponse;
}

export const DeductiblesTable: React.FC<DeductiblesTableProps> = ({ primaryInsurance }) => {
  const primaryFamilyAnnualMaximums = useFamilyFeeCalculationValues(
    "familyAnnualMaximum",
    "annualMaximumRemaining",
    primaryInsurance
  );

  const primaryFamilyDeductibleValues = useFamilyFeeCalculationValues(
    "familyAnnualDeductible",
    "annualDeductibleRemaining",
    primaryInsurance
  );

  const primaryIndividualAnnualMaximums = useIndividualFeeCalculationValues(
    "individualAnnualMaximum",
    "annualMaximumRemaining",
    primaryInsurance
  );

  const primaryIndividualDeductibles = useIndividualFeeCalculationValues(
    "individualAnnualDeductible",
    "annualDeductibleRemaining",
    primaryInsurance
  );

  return (
    <>
      <ThreeColGrid>
        <HeaderCell value="Annual Max" />
        <HeaderCell align="right" value="Individual" />
        <HeaderCell align="right" value="Family" />

        <ValueCell value="Benefit" />
        <CurrencyCell value={primaryIndividualAnnualMaximums.originalValue} />
        <CurrencyCell value={primaryFamilyAnnualMaximums.originalValue} />

        <ValueCell value="Used" />
        <CurrencyCell value={primaryIndividualAnnualMaximums.used} />
        <CurrencyCell value={primaryFamilyAnnualMaximums.used} />

        <ValueCell value="Remaining" />
        <CurrencyCell value={primaryIndividualAnnualMaximums.remaining} />
        <CurrencyCell value={primaryFamilyAnnualMaximums.remaining} />
      </ThreeColGrid>
      <ThreeColGrid>
        <HeaderCell value="Deductible" />
        <HeaderCell align="right" value="Individual" />
        <HeaderCell align="right" value="Family" />

        <ValueCell value="Benefit" />
        <CurrencyCell value={primaryIndividualDeductibles.originalValue} />
        <CurrencyCell value={primaryFamilyDeductibleValues.originalValue} />

        <ValueCell value="Used" />
        <CurrencyCell value={primaryIndividualDeductibles.used} />
        <CurrencyCell value={primaryFamilyDeductibleValues.used} />

        <ValueCell value="Remaining" />
        <CurrencyCell value={primaryIndividualDeductibles.remaining} />
        <CurrencyCell value={primaryFamilyDeductibleValues.remaining} />
      </ThreeColGrid>
    </>
  );
};

interface LimitationsTableProps {
  rows: BenefitLimitationVO[];
}

export const LimitationString: React.FC<{ displayLimitOnly?: boolean; limitation: BenefitLimitationVO }> = ({
  displayLimitOnly = false,
  limitation,
}) => {
  const limitationStrParts = useLimitationAsString({
    frequency: limitation.frequency ?? {},
    isSummarizedLimitationView: true,
    limitation,
  });

  if (displayLimitOnly) {
    const firstPart = limitationStrParts[0] ?? "";

    return <div>{firstPart.includes("Age") ? EMPTY_CELL : firstPart}</div>;
  }

  return limitationStrParts.length ? (
    <>
      {limitationStrParts.map((strPart, index) => (
        <Fragment key={index}>
          {strPart}
          {index !== limitationStrParts.length - 1 && <br />}
        </Fragment>
      ))}
    </>
  ) : (
    <div>{EMPTY_CELL}</div>
  );
};

export const LimitationsTable: React.FC<LimitationsTableProps> = ({ rows }) => {
  return (
    <TwoColGrid>
      <HeaderCell value="Limitations" />
      <HeaderCell value="" />
      {rows.map((limtation) => (
        <Fragment key={limtation.uuid}>
          <ValueCell value={limtation.serviceName} />
          <ValueCell>
            <LimitationString limitation={limtation} />
          </ValueCell>
        </Fragment>
      ))}
    </TwoColGrid>
  );
};

interface HistoryTableProps {
  rows: RecentProcedureHistoryItemVO[];
}

export const HistoryTable: React.FC<HistoryTableProps> = ({ rows }) => {
  return (
    <TwoColGrid>
      <HeaderCell value="History" />
      <HeaderCell value="Date" />
      {rows.map((history, index) => (
        <Fragment key={index}>
          <ValueCell value={history.groupName} />
          <ValueCell value={history.date ? formatISODate(history.date) : "-"} />
        </Fragment>
      ))}
    </TwoColGrid>
  );
};
