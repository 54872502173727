import { ChangeEventHandler, FC } from "react";

import { DateWorktimeDetails, DateWorktimeVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatISOTimeAsAmPm, formatShortDayOfMonth, getLocalDate } from "@libs/utils/date";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { Icon } from "@libs/components/UI/Icon";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as PlusCircle } from "@libs/assets/icons/plus-circle.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { ButtonCell, TextCell, CheckboxCell, EMPTY_CELL, Row } from "@libs/components/UI/GridTableComponents";
import { TimeSheetTablePopover } from "components/EmployeeProfile/Timesheet/TimeSheetTablePopover";
import { formatHours } from "components/EmployeeProfile/Timesheet/utils";
import { AddClockOutModalProps } from "components/EmployeeProfile/Timesheet/AddClockOutModal";
import { EditClockOutModalProps } from "components/EmployeeProfile/Timesheet/EditClockOutModal";
import { EditClockInModalProps } from "components/EmployeeProfile/Timesheet/EditClockInModal";
import { EditApprovedTimeModalProps } from "components/EmployeeProfile/Timesheet/EditApprovedTimeModal";
import { TimeSheetRowActionsCell } from "components/EmployeeProfile/Timesheet/TimeSheetRowActionsCell";

interface Props {
  hoursForDay: DateWorktimeVO;
  hasEmployeesPermissions: boolean;
  showDoubleOvertime: boolean;
  selectedRows: Set<number>;
  index: number;
  workTime: DateWorktimeDetails;
  hoursInDecimal: boolean;
  employeeId: number;
  selectableRows: number[] | undefined;
  onEditApproval: (params: Omit<EditApprovedTimeModalProps, "onRequestClose" | "employeeId">) => void;
  onAddClockOut: (params: Omit<AddClockOutModalProps, "onRequestClose" | "employeeId">) => void;
  onEditClockOut: (params: Omit<EditClockOutModalProps, "onRequestClose" | "employeeId">) => void;
  onEditClockIn: (params: Omit<EditClockInModalProps, "onRequestClose" | "employeeId">) => void;
  onApproveWorktime: (worktimeId: number) => void;
  onDeleteWorktime: (worktimeId: number) => void;
  onToggleEmployee: ChangeEventHandler<HTMLInputElement>;
}

const cxStatusStyles: Record<DateWorktimeDetails["status"], string> = {
  APPROVED: "text-green",
  PENDING: "text-orange",
};

export const useTimeSheetRow = ({
  selectableRows,
  selectedRows,
  workTime,
  hoursForDay,
  index,
  hoursInDecimal,
  showDoubleOvertime,
  hasEmployeesPermissions,
  employeeId,
}: Pick<
  Props,
  | "selectableRows"
  | "selectedRows"
  | "workTime"
  | "hoursForDay"
  | "index"
  | "hoursInDecimal"
  | "showDoubleOvertime"
  | "hasEmployeesPermissions"
  | "employeeId"
>) => {
  const { id: userId } = useAccount();

  const hasSelections = selectedRows.size > 0;

  const firstRowOnlyProps =
    index === 0
      ? {
          date: formatShortDayOfMonth(getLocalDate(hoursForDay.worktimeDate)),
          regularHours: formatHours(hoursForDay.regularHours, hoursInDecimal),
          overtime: formatHours(hoursForDay.overtime, hoursInDecimal),
          doubleOvertime: formatHours(hoursForDay.doubleOvertime, hoursInDecimal),
        }
      : {
          date: "",
          regularHours: "",
          overtime: "",
          doubleOvertime: "",
        };

  return {
    ...firstRowOnlyProps,
    hasSelections,
    isApprovable: new Set(selectableRows).has(workTime.id),
    userId,
    doubleOvertime: showDoubleOvertime ? firstRowOnlyProps.doubleOvertime : undefined,
    totalHours:
      workTime.clockIn.time && workTime.clockOut.time && workTime.hours !== undefined
        ? formatHours(workTime.hours, hoursInDecimal)
        : EMPTY_CELL,
    clockInTime: formatISOTimeAsAmPm(workTime.clockIn.time),
    clockOutTime: workTime.clockOut.time ? formatISOTimeAsAmPm(workTime.clockOut.time) : "",
    canEditTime: (hasEmployeesPermissions || employeeId === userId) && workTime.status === "PENDING",
    workTimeStatus: sentenceCaseConstant(workTime.status),
  };
};

// eslint-disable-next-line complexity
export const TimeSheetRow: FC<Props> = ({
  hoursForDay,
  hasEmployeesPermissions,
  showDoubleOvertime,
  selectedRows,
  index,
  workTime,
  hoursInDecimal,
  employeeId,
  selectableRows,
  onEditClockIn,
  onEditClockOut,
  onEditApproval,
  onAddClockOut,
  onApproveWorktime,
  onDeleteWorktime,
  onToggleEmployee,
}) => {
  const {
    hasSelections,
    isApprovable,
    date,
    regularHours,
    doubleOvertime,
    overtime,
    totalHours,
    clockInTime,
    clockOutTime,
    workTimeStatus,
    canEditTime,
  } = useTimeSheetRow({
    hoursForDay,
    hasEmployeesPermissions,
    showDoubleOvertime,
    selectedRows,
    index,
    workTime,
    hoursInDecimal,
    employeeId,
    selectableRows,
  });

  return (
    <Row isSelected={selectedRows.has(workTime.id)} key={workTime.id}>
      <TextCell border={!workTime.clockOut.time}>{date}</TextCell>
      <TextCell border={!workTime.clockOut.time} className="font-sansSemiBold">
        {regularHours}
      </TextCell>
      <TextCell border={!workTime.clockOut.time} className="font-sansSemiBold">
        {overtime}
      </TextCell>
      {doubleOvertime === undefined ? null : (
        <TextCell border={!workTime.clockOut.time} className="font-sansSemiBold">
          {doubleOvertime}
        </TextCell>
      )}
      {hasEmployeesPermissions ? (
        <CheckboxCell
          styleOptions={{
            border: !workTime.clockOut.time,
          }}
          value={workTime.id}
          checked={selectedRows.has(workTime.id)}
          onChange={onToggleEmployee}
          disabled={!isApprovable}
        />
      ) : null}
      <TextCell border={!workTime.clockOut.time} className="group">
        {workTime.clockIn.time ? (
          <div className="flex">
            <FloatingTooltip content={<TimeSheetTablePopover type="in" worktime={workTime.clockIn} />}>
              <span className={cx(workTime.isEditedByEmployee && "text-red")}>{clockInTime}</span>
            </FloatingTooltip>
            {canEditTime ? (
              <ButtonIcon
                size="sm"
                className="ml-1 invisible group-hover:visible"
                onClick={() =>
                  onEditClockIn({
                    worktimeId: workTime.id,
                    date: hoursForDay.date,
                    comment: workTime.clockIn.notes ?? "",
                    clockInTime: workTime.clockIn.time,
                    clockOutTime: workTime.clockOut.time,
                  })
                }
                SvgIcon={EditIcon}
              />
            ) : null}
          </div>
        ) : null}
      </TextCell>
      {workTime.clockOut.time ? (
        <TextCell border={!workTime.clockOut.time} className="group">
          <div className="flex">
            <FloatingTooltip content={<TimeSheetTablePopover type="out" worktime={workTime.clockOut} />}>
              <span className={cx(workTime.isEditedByEmployee && "text-red")}>{clockOutTime}</span>
            </FloatingTooltip>
            {canEditTime ? (
              <ButtonIcon
                size="sm"
                className="ml-1 invisible group-hover:visible"
                onClick={() =>
                  onEditClockOut({
                    worktimeId: workTime.id,
                    date: hoursForDay.date,
                    comment: workTime.clockOut.notes ?? "",
                    clockInTime: workTime.clockIn.time,
                    clockOutTime: workTime.clockOut.time,
                  })
                }
                SvgIcon={EditIcon}
              />
            ) : null}
          </div>
        </TextCell>
      ) : (
        <ButtonCell
          border={!workTime.clockOut.time}
          className={cx("flex items-center", hasSelections ? "text-greyLight" : "text-primaryTheme")}
          onClick={() =>
            onAddClockOut({
              date: hoursForDay.worktimeDate,
              clockInTime: workTime.clockIn.time,
            })
          }
        >
          Add Time
          <Icon disabled={hasSelections} SvgIcon={PlusCircle} className="ml-1" theme="primary" />
        </ButtonCell>
      )}
      <TextCell border={!workTime.clockOut.time}>{totalHours}</TextCell>
      {hasEmployeesPermissions ? (
        <TimeSheetRowActionsCell
          workTime={workTime}
          onEditApproval={onEditApproval}
          onApproveWorktime={onApproveWorktime}
          onDeleteWorktime={onDeleteWorktime}
          isApprovable={isApprovable}
          hasSelections={hasSelections}
          hoursForDay={hoursForDay}
        />
      ) : null}
      <TextCell border={!workTime.clockOut.time} className="flex items-center">
        <span className={cxStatusStyles[workTime.status]}>{workTimeStatus}</span>
        {workTime.isEditedByEmployee ? (
          <Icon SvgIcon={ErrorIcon} theme="error" size="sm" className="ml-1" />
        ) : null}
      </TextCell>
    </Row>
  );
};
