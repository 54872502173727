import React from "react";
import { cx } from "@libs/utils/cx";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as DropDownIcon } from "@libs/assets/icons/down-chevron.svg";

export const Expandable: React.FC<{
  title: React.ReactNode;
  children: React.ReactNode;
  className?: string;
  expanded: boolean;
  onToggle: () => void;
}> = ({ children, title, expanded, className, onToggle }) => {
  return (
    <div>
      <button
        type="button"
        className={cx(
          "flex items-center w-full px-2 border-greyLighter",
          expanded ? "font-sansSemiBold border-t border-x rounded-t" : "border rounded",
          className
        )}
        onClick={onToggle}
      >
        <div className="flex-1 text-left">{title}</div>
        <Icon
          className={cx("mx-1.5 transition-transform text-primaryTheme", expanded && "rotate-180")}
          SvgIcon={DropDownIcon}
        />
      </button>
      {expanded ? children : null}
    </div>
  );
};
