import React from "react";
import { scaleBand, scaleLinear } from "@visx/scale";
import { getDateResolutionMargins } from "components/Dashboard/Charting/utils";
import { TimeSeriesResolutionOption } from "utils/routing/dashboard";
import { BarStackData, BarStackGroup, ChartMargin } from "components/Dashboard/Charting/types";

export const useStackMarginsAndScales = <T extends string, K extends BarStackGroup<T> | BarStackData<T>>({
  width,
  height,
  resolution,
  yDomain,
  data,
  chartMargin,
  axisMargin,
}: {
  height: number;
  width: number;
  resolution?: TimeSeriesResolutionOption;
  data: K[];
  yDomain: { min: number; max: number };
  chartMargin?: ChartMargin;
  axisMargin?: ChartMargin;
}) => {
  return React.useMemo(() => {
    const zeroMargin = { top: 0, left: 0, right: 0, bottom: 0 };
    let margins = { chartMargin: zeroMargin, axisMargin: zeroMargin };

    if (chartMargin && axisMargin) {
      margins = { chartMargin, axisMargin };
    } else if (resolution) {
      const computedMargins = getDateResolutionMargins({ width, resolution });

      margins = {
        chartMargin: chartMargin ?? computedMargins.chartMargin,
        axisMargin: axisMargin ?? computedMargins.axisMargin,
      };
    }

    const xMax = width - margins.chartMargin.left - margins.chartMargin.right;
    const gridXMax = width - margins.axisMargin.left - margins.axisMargin.right;
    const yMax = height - margins.chartMargin.top - margins.chartMargin.bottom;
    const yScale = scaleLinear<number>({
      range: [yMax, 0],
      round: true,
      domain: [yDomain.min, yDomain.max],
      nice: true,
    });

    return {
      margins,
      xMax,
      gridXMax,
      yMax,
      xScale: scaleBand<string>({
        range: [0, xMax],
        round: true,
        domain: data.map((item) => `${item.date}`),
        padding: 0.2,
      }),
      yScale,
      yZeroPosition: yScale(0) + margins.axisMargin.top,
    };
  }, [chartMargin, axisMargin, resolution, width, height, yDomain.min, yDomain.max, data]);
};
