import { FC, useMemo, useCallback } from "react";

import { AutomationActionVO, AutomationActionRequest } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as VerticalMenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { Row, ButtonCell, TextCell, IconsCell } from "@libs/components/UI/GridTableComponents";
import { Switch } from "@libs/components/UI/Switch";

import { ActionMessage } from "components/Communications/Automations/ActionMessage";
import {
  CHANNEL_LABELS,
  formatEventTiming,
  getAutomationActionRequest,
} from "components/Communications/Automations/utils";

export interface ActionRowProps {
  action: AutomationActionVO;
  onRequestEdit: (actionUuid: AutomationActionVO["uuid"]) => void;
  onRequestDelete: (actionUuid: AutomationActionVO["uuid"]) => void;
  onToggleActionStatus: (actionUuid: AutomationActionVO["uuid"], data: AutomationActionRequest) => void;
  isLastRow?: boolean;
}

export const ActionRow: FC<ActionRowProps> = ({
  action,
  onRequestEdit,
  onRequestDelete,
  onToggleActionStatus,
  isLastRow = false,
}) => {
  const menu = useBoolean(false);

  const {
    uuid,
    interval,
    intervalType,
    eventTime,
    event,
    channels,
    active,
    smsTemplate,
    emailTemplate,
    customEmailTemplate,
  } = action;

  const menuOptions = useMemo(
    () =>
      createMenuOptions(
        {
          label: "Edit",
          value: "edit",
          SvgIcon: EditIcon,
        },
        {
          label: "Delete",
          value: "delete",
          SvgIcon: DeleteIcon,
        }
      ),
    []
  );

  const handleRequestEdit = useCallback(() => {
    onRequestEdit(uuid);
  }, [uuid, onRequestEdit]);

  const handleToggleActionStatus = useCallback(
    (value: boolean) => {
      onToggleActionStatus(uuid, {
        ...getAutomationActionRequest(action),
        active: value,
      });
    },
    [action, uuid, onToggleActionStatus]
  );

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "edit": {
          handleRequestEdit();
          break;
        }
        case "delete": {
          onRequestDelete(uuid);
          break;
        }
        default: {
          break;
        }
      }

      menu.off();
    },
    [uuid, menu, handleRequestEdit, onRequestDelete]
  );

  const cellProps = { border: !isLastRow, borderColor: "border-slate-200" as const };
  const buttonCellProps = { ...cellProps, onDoubleClick: handleRequestEdit };

  return (
    <Row className="*:flex *:items-center" data-testid="communications-action-row">
      <ButtonCell className="capitalize" {...buttonCellProps}>
        <span className="truncate" data-testid="action-row-timing">
          {formatEventTiming({ interval, intervalType, eventTime, event })}
        </span>
      </ButtonCell>
      <ButtonCell {...buttonCellProps}>
        {channels.map((channel) => CHANNEL_LABELS[channel]).join(", ")}
      </ButtonCell>
      <ButtonCell className="overflow-hidden" {...buttonCellProps}>
        {channels.length === 1 && customEmailTemplate ? (
          "Custom Email Message"
        ) : (
          <ActionMessage smsTemplate={smsTemplate} emailTemplate={emailTemplate} />
        )}
      </ButtonCell>
      <TextCell className="justify-center" {...cellProps}>
        <Switch
          name={`action-${uuid}-active`}
          aria-label={`Action ${active ? "Active" : "Inactive"}`}
          checked={active}
          onChange={(e) => handleToggleActionStatus(e.target.checked)}
        />
      </TextCell>
      <IconsCell {...cellProps}>
        <ButtonMenu
          menuContent={
            <div className="w-36">
              <MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />
            </div>
          }
          onRequestOpen={menu.on}
          onRequestClose={menu.off}
          isOpen={menu.isOn}
          placement="bottom-end"
        >
          {(props) => (
            <ButtonIcon
              SvgIcon={VerticalMenuIcon}
              tooltip={{ content: "Action Menu", theme: "SMALL" }}
              {...props}
            />
          )}
        </ButtonMenu>
      </IconsCell>
    </Row>
  );
};
