import { FC, KeyboardEventHandler, useCallback } from "react";

import { CharacterCounter } from "@libs/components/UI/CharacterCounter";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as SendIcon } from "@libs/assets/icons/send-filled.svg";
import { Form } from "@libs/components/UI/Form";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";

import { useAutoExpand } from "hooks/useAutoExpand";

interface Props {
  message: string;
  maxCharacters: number;
  disabled: boolean;
  onMessageChange: (message: string) => void;
  onSend: Func;
}

export const MessageComposer: FC<Props> = ({ message, disabled, maxCharacters, onMessageChange, onSend }) => {
  const { elRef, styles } = useAutoExpand(message);

  const handleKeyDown: KeyboardEventHandler<HTMLTextAreaElement> = useCallback(
    (e) => {
      if (!e.shiftKey && e.code === "Enter") {
        e.preventDefault();
        onSend();
      }
    },
    [onSend]
  );

  return (
    <div className="flex flex-col gap-y-2 px-6 pb-6">
      <Form className="flex-1">
        <FormFieldTextarea
          ref={elRef}
          style={styles}
          inputClassName="pl-3 pr-12"
          value={message}
          placeholder="Enter message"
          onKeyDown={handleKeyDown}
          onChange={(e) => onMessageChange(e.target.value)}
          rows={1}
          disabled={disabled}
          disableResize
        >
          <ButtonIcon
            className="absolute right-3 top-1/2 -translate-y-1/2"
            SvgIcon={SendIcon}
            onClick={onSend}
            theme="primary"
            size="md"
            disabled={disabled || message.length > maxCharacters}
            tooltip={
              disabled
                ? {
                    content: "Phone number is missing. Update patient contact information and try again.",
                    theme: "MEDIUM",
                  }
                : message.length > maxCharacters
                  ? { content: "Message is too long", theme: "MEDIUM" }
                  : { content: "Send", theme: "SMALL" }
            }
          />
        </FormFieldTextarea>
        <div className="text-xs mt-1 text-right">
          <CharacterCounter currentCount={message.length} max={maxCharacters} />
        </div>
      </Form>
    </div>
  );
};
