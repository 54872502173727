import { useCallback, useState } from "react";
import { Button } from "@libs/components/UI/Button";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { Form } from "@libs/components/UI/Form";
import { filtersCxStyles } from "components/Claims/Claims/Filters";
import { ClaimsQuery, ClaimsQueryUpdates } from "utils/routing/claims";
import { ClaimInsurancePaymentStatus } from "components/Claims/types";

interface Props {
  onFiltersUpdate: (keyValues: ClaimsQueryUpdates) => void;
  onRequestClose: Func;
  queryStateApi: ClaimsQuery;
}

const INSURANCE_STATUS_OPTIONS: { label: string; value: ClaimInsurancePaymentStatus }[] = [
  { label: "Paid", value: "PAID_AS_EXPECTED" },
  { label: "Unpaid", value: "UNPAID" },
  { label: "Overpaid", value: "OVERPAID" },
  { label: "Underpaid", value: "UNDERPAID" },
  { label: "Skipped", value: "NOT_SUBMITTED" },
  { label: "Denied", value: "DENIED" },
];

export const FilterMenuPaymentStatus: React.FC<Props> = ({
  onFiltersUpdate,
  onRequestClose,
  queryStateApi,
}) => {
  const [selectedPaymentStatues, setSelectedPaymentStatuses] = useState(
    new Set(queryStateApi.insurancePaymentStatuses)
  );

  const handleApplyFilter = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      onFiltersUpdate({
        insurancePaymentStatuses: [...selectedPaymentStatues],
      });
      onRequestClose();
    },
    [onFiltersUpdate, onRequestClose, selectedPaymentStatues]
  );

  return (
    <Form className={filtersCxStyles.menuWrapper} onSubmit={handleApplyFilter}>
      <CheckboxList
        className={filtersCxStyles.menuContent}
        layout="vert"
        onChange={(newSet) => setSelectedPaymentStatuses(newSet)}
        options={INSURANCE_STATUS_OPTIONS}
        selectedValues={selectedPaymentStatues}
      />
      <div className="mt-2 px-4">
        <Button className="w-full" type="submit">
          Apply Filters
        </Button>
      </div>
    </Form>
  );
};
