import { cx } from "@libs/utils/cx";

export const ChartContainer: React.FC<{
  children: React.ReactNode;
  className?: string;
  disabledMessage?: string;
}> = ({ children, className, disabledMessage }) => {
  return (
    <div className="p-6 border-b border-slate-400">
      <div
        className={cx(
          `flex
           bg-white
           h-[44vh]
           border
           border-greyLighter
           rounded-md
           pt-6
           px-3`,
          className
        )}
      >
        {disabledMessage ? (
          <div
            className={`
              flex
              flex-1
              flex-col
              items-center
              justify-center
              h-full
              text-center
            `}
          >
            {disabledMessage}
          </div>
        ) : (
          children
        )}
      </div>
    </div>
  );
};
