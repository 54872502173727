import { FC, useCallback, useState } from "react";
import { GroupHeadingProps, OptionProps, components } from "react-select";
import { InsurancePlanSummaryVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useAccount } from "@libs/contexts/AccountContext";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { mergeInsurancePlans } from "api/practiceInsurance/mutations";
import { handleError } from "utils/handleError";

export const MergePlansModal: FC<{
  plansToMerge: InsurancePlanSummaryVO[];
  onMerge: Func;
  onClose: Func;
}> = ({ plansToMerge, onMerge, onClose }) => {
  const { practiceId } = useAccount();
  const [mergeInsurancePlansMutation] = useApiMutations([mergeInsurancePlans]);
  const [selectedPlanUuid, setSelectedPlanUuid] = useState<string>();
  const validation = useValidation(
    { selectedPlanUuid },
    { selectedPlanUuid: { $validations: [{ $v: required }] } }
  );

  const handleMergePlans = useCallback(async () => {
    if (!validation.validate().$isValid) {
      return;
    }

    try {
      selectedPlanUuid &&
        (await mergeInsurancePlansMutation.mutateAsync({
          practiceId,
          data: {
            sourceInsurancePlanUuids: plansToMerge
              .map((plan) => plan.uuid)
              .filter((planUuid) => planUuid !== selectedPlanUuid),
            targetInsurancePlanUuid: selectedPlanUuid,
          },
        }));
      onMerge();
    } catch (err) {
      handleError(err);
    }
  }, [mergeInsurancePlansMutation, onMerge, plansToMerge, practiceId, selectedPlanUuid, validation]);

  return (
    <Modal title="Merge Plans" size="xs" onClose={onClose}>
      <ModalContent padding="lg">
        <div className="flex flex-col gap-y-2">
          <div className="flex flex-col gap-y-1">
            <div className="font-sansSemiBold text-xs">Plan</div>
            <div className="text-xs">
              All patients will be moved onto this plan, this action cannot be undone.
            </div>
          </div>
          <FormFieldSelect<string, PlanOption>
            placeholder="Select plan..."
            value={selectedPlanUuid}
            onChange={(value) => setSelectedPlanUuid(value?.value)}
            components={{
              GroupHeading: PlanOptionHeader,
              Option: PlanOption,
            }}
            // Options with a single option group to create a header
            options={[
              {
                options: plansToMerge.map((plan) => ({
                  label: plan.groupName ?? "N/A",
                  value: plan.uuid,
                  plan,
                })),
              },
            ]}
            error={validation.result.selectedPlanUuid.$error}
          />
        </div>
      </ModalContent>
      <ModalFooter actions>
        <Button className="min-w-button" theme="secondary" onClick={onClose}>
          Cancel
        </Button>
        <AsyncButton
          isLoading={mergeInsurancePlansMutation.isLoading}
          className="min-w-button"
          disabled={
            !(validation.result.$isValid ?? true) ||
            mergeInsurancePlansMutation.isLoading ||
            !selectedPlanUuid
          }
          onClick={handleMergePlans}
        >
          Merge
        </AsyncButton>
      </ModalFooter>
    </Modal>
  );
};

interface PlanOption extends SelectOption<string> {
  plan: InsurancePlanSummaryVO;
}

const PlanOptionHeader = (
  props: GroupHeadingProps<PlanOption, false, GroupedSelectOption<string, PlanOption>>
) => (
  <components.GroupHeading {...props}>
    <div className="font-sansSemiBold text-xxs">
      <PlanOptionColumns
        groupNumber="Group Number"
        groupName="Group Name"
        nickName="Nickname"
        patients="Patients"
      />
    </div>
  </components.GroupHeading>
);

const PlanOption = (props: OptionProps<PlanOption, false, GroupedSelectOption<string, PlanOption>>) => {
  const plan = props.data.plan;

  return (
    <components.Option {...props}>
      <PlanOptionColumns
        groupNumber={plan.groupNumber ?? EMPTY_CELL}
        groupName={plan.groupName ?? EMPTY_CELL}
        nickName={plan.description ?? EMPTY_CELL}
        patients={plan.patientCount}
      />
    </components.Option>
  );
};

const PlanOptionColumns: FC<{
  groupNumber: string;
  groupName: string;
  nickName: string;
  patients: string | number;
}> = ({ groupNumber, groupName, nickName, patients }) => (
  <div className="flex gap-x-2">
    <div className="w-20">{groupNumber}</div>
    <div className="flex-1 truncate" title={groupName}>
      {groupName}
    </div>
    <div className="flex-1 truncate" title={nickName}>
      {nickName}
    </div>
    <div className="w-20">{patients}</div>
  </div>
);
