import { FC } from "react";

import { EmployeeVO } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { hasFailedPin } from "@libs/utils/hasFailedPin";

import { QueryResult } from "@libs/components/UI/QueryResult";
import { PinGuard } from "components/UI/PinGuard";
import { SkeletonInputField } from "components/UI/SkeletonInputField";

import { PrescriberForm } from "components/Erx/PrescriberForm";

interface Props {
  formId: string;
  employeeId: number;
  employeeQuery: ApiQueryResult<EmployeeVO>;
  isProxy: boolean;
  onSubmit: (employee: EmployeeVO, isUpdated: boolean) => void;
}

export const PrescriberFormWrapper: FC<Props> = ({
  formId,
  employeeId,
  employeeQuery,
  isProxy,
  onSubmit,
}) => {
  return (
    <PinGuard key={employeeId} protectedQueries={[employeeQuery]}>
      <QueryResult
        queries={[employeeQuery]}
        loadError={hasFailedPin(employeeQuery.error) ? <div /> : undefined}
        loading={
          <div className="grid grid-cols-2 gap-6">
            {isProxy ? null : (
              <>
                <SkeletonInputField />
                <SkeletonInputField />
              </>
            )}
            <SkeletonInputField />
            <SkeletonInputField />
            <SkeletonInputField />
            <SkeletonInputField />
            <SkeletonInputField />
            <div className="grid grid-cols-2 gap-6">
              <SkeletonInputField />
              <SkeletonInputField />
            </div>
          </div>
        }
      >
        {employeeQuery.data ? (
          <PrescriberForm
            key={employeeId}
            formId={formId}
            employee={employeeQuery.data}
            isProxy={isProxy}
            onSubmit={onSubmit}
          />
        ) : null}
      </QueryResult>
    </PinGuard>
  );
};
