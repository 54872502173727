import { NavLink, matchPath, useLocation } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { TabText } from "components/UI/TabText";
import { paths, routesConfig } from "utils/routing/paths";

interface Props {
  patientId: number;
}

const getTabs = (pathname: string, patientId: number) => [
  {
    to: paths.charting({ patientId }),
    text: "Tooth Chart",
    isSelected: Boolean(matchPath(routesConfig.charting.path, pathname)),
  },
  {
    to: paths.perio({ patientId }),
    text: "Perio Chart",
    isSelected: Boolean(matchPath(routesConfig.perio.path, pathname)),
  },
];

export const ChartTabs: React.FC<Props> = ({ patientId }) => {
  const pathname = useLocation().pathname;
  const tabs = getTabs(pathname, patientId);

  return (
    <div className="flex">
      {tabs.map((tab) => (
        <NavLink key={tab.text} className={cx(tab.isSelected && "text-primaryTheme")} to={tab.to}>
          <TabText isSelected={tab.isSelected}>{tab.text}</TabText>
        </NavLink>
      ))}
    </div>
  );
};
