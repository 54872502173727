import React from "react";
import { DentalProcedureFeeVO, DentalProcedureVO, FeeScheduleListVO } from "@libs/api/generated-api";
import { UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { Pill } from "@libs/components/UI/Pill";
import { Expandable } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/Expandable";
import { FeeCategoryTable } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/FeeCategoryTable";
import { EditableFeeSchedule } from "components/Settings/FeeSchedules/FeeScheduleDetailsPage/useEditableFeeSchedule";
import { getProcedureCounts } from "components/Settings/FeeSchedules/utils/getProcedureCounts";

export const FeeScheduleBreakdown: React.FC<{
  categories: string[];
  categoriesExpanded: Set<string>;
  feeSchedule: EditableFeeSchedule;
  feeSchedulesQuery: UseInfiniteApiListQueryResult<FeeScheduleListVO>;
  onCategoryToggle: (category: string) => void;
  onFeeUpdated: (fee: DentalProcedureFeeVO) => void;
  procedures: DentalProcedureVO[];
}> = React.memo(
  ({
    categories,
    categoriesExpanded,
    feeSchedule,
    feeSchedulesQuery,
    onCategoryToggle,
    onFeeUpdated,
    procedures,
  }) => {
    const { countsTotal, categoryCounts } = React.useMemo(() => {
      return getProcedureCounts(feeSchedule.dentalProcedureFees, procedures);
    }, [feeSchedule.dentalProcedureFees, procedures]);
    const categoriesWithProcedures = React.useMemo(() => {
      return categories.filter((category) => (countsTotal[category] ?? 0) > 0);
    }, [countsTotal, categories]);

    return (
      <div className="flex flex-col gap-y-2">
        {categoriesWithProcedures.map((category) => {
          return (
            <Expandable
              expanded={categoriesExpanded.has(category)}
              key={category}
              onToggle={() => {
                onCategoryToggle(category);
              }}
              title={
                <div className="flex items-center gap-x-1 min-h-10">
                  <span className="px-2">{category}</span>
                  <Pill className="mx-2 font-sans">
                    {categoryCounts[category] ?? 0} / {countsTotal[category] ?? 0}
                  </Pill>
                </div>
              }
            >
              <FeeCategoryTable
                category={category}
                feeSchedulesQuery={feeSchedulesQuery}
                isPracticeUcr={Boolean(feeSchedule.isPracticeUcr)}
                feeScheduleName={feeSchedule.id ? feeSchedule.name : undefined}
                feeScheduleDetailProcedureFees={feeSchedule.dentalProcedureFees}
                onFeeUpdated={onFeeUpdated}
                procedures={procedures}
              />
            </Expandable>
          );
        })}
      </div>
    );
  }
);
