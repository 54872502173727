import { FC, useMemo, useState } from "react";
import { RecurringScheduleVO } from "@libs/api/generated-api";
import { minArrayLen, required } from "@libs/utils/validators";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { useValidation } from "@libs/hooks/useValidation";
import { Button } from "@libs/components/UI/Button";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter } from "@libs/components/UI/ModalComponents";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { ToggleButtonList } from "components/UI/ToggleButtonList";
import { BlockMonthRepeatSelectMenu } from "components/ScheduleAppointments/BlockMonthRepeatSelectMenu";
import { BlockCustomRepeatUnitSelectMenu } from "components/ScheduleAppointments/BlockCustomRepeatUnitSelectMenu";

interface Props {
  recurrence: RecurringScheduleVO | undefined;
  date: string;
  onSave: (customRecurrence: RecurringScheduleVO) => void;
  onRequestClose: Func;
}

const mapToDaysOfMonth: Partial<
  Record<RecurringScheduleVO["recurrenceType"], RecurringScheduleVO["recurrenceType"]>
> = {
  DAYS_OF_LAST_WEEKS_OF_MONTH: "DAYS_OF_MONTHS",
  DAYS_OF_WEEKS_OF_MONTH: "DAYS_OF_MONTHS",
};

const dayOptions = [
  {
    value: "SUNDAY" as const,
    label: "Su",
  },
  {
    value: "MONDAY" as const,
    label: "Mo",
  },
  {
    value: "TUESDAY" as const,
    label: "Tu",
  },
  {
    value: "WEDNESDAY" as const,
    label: "We",
  },
  {
    value: "THURSDAY" as const,
    label: "Th",
  },
  {
    value: "FRIDAY" as const,
    label: "Fr",
  },
  {
    value: "SATURDAY" as const,
    label: "Sa",
  },
];

const endsOptions = [
  {
    value: "NEVER" as const,
    label: "Never",
  },
  {
    value: "ON" as const,
    label: "On",
  },
];

export const BlockCustomRepeatModal: FC<Props> = ({ onRequestClose, onSave, date, recurrence }) => {
  const [draft, setDraft] = useState<RecurringScheduleVO>(recurrence ?? { recurrenceType: "DAYS", every: 1 });
  const [ends, setEnds] = useState<"ON" | "NEVER">(() => (recurrence?.endDate ? "ON" : "NEVER"));
  const schema = useMemo(() => {
    return {
      every: [{ $v: required, $error: "Please provide how often the block should repeat" }],
      daysOfWeek: [
        {
          $v: minArrayLen(1),
          $error: "At least one day must be selected",
          $ignore: draft.recurrenceType !== "DAYS_OF_WEEKS",
        },
      ],
      endDate: [{ $v: required, $error: "An end date is required", $ignore: ends !== "ON" }],
    };
  }, [draft.recurrenceType, ends]);

  const validation = useValidation(draft, schema);

  const endDate = useMemo(() => (draft.endDate ? getLocalDate(draft.endDate) : null), [draft]);

  const handleSubmit = () => {
    if (validation.validate().$isValid) {
      onSave(ends === "NEVER" ? { ...draft, endDate: undefined } : draft);
    }
  };

  return (
    <Modal centerVertically={false} size="2xs" title="Custom" onClose={onRequestClose}>
      <ModalContent className="flex flex-col gap-y-6" padding="sm">
        <div>
          <FormFieldLabel
            error={validation.result.every.$error}
            content="Repeat every"
            required
            edit
            className="pb-1 text-xs"
          />
          <div className="flex gap-x-3">
            <FormFieldNumberInput
              min={1}
              step={1}
              clamp
              className="flex-0 w-12"
              error={validation.result.every.$error}
              displayErrorMessage={false}
              value={draft.every}
              onValueChange={(_, { numberValue }) =>
                setDraft((last) => ({
                  ...last,
                  every: numberValue,
                }))
              }
            />
            <BlockCustomRepeatUnitSelectMenu
              date={date}
              recurrenceType={draft.recurrenceType}
              onChange={(newRecurrence) => {
                setDraft((last) => ({
                  ...newRecurrence,
                  endDate: last.endDate,
                  every: last.every,
                }));
              }}
            />
          </div>
          {validation.result.every.$error ? (
            <FormFieldError className="pt-1">{validation.result.every.$error}</FormFieldError>
          ) : null}
        </div>
        {draft.recurrenceType === "DAYS_OF_WEEKS" ? (
          <div>
            <ToggleButtonList
              type="checkbox"
              shape="mediumPill"
              label="Repeat on"
              error={validation.result.daysOfWeek.$error}
              selectedValues={new Set(draft.daysOfWeek)}
              options={dayOptions}
              onChange={(newSet) =>
                setDraft((last) => ({
                  ...last,
                  daysOfWeek: [...newSet],
                }))
              }
            />
          </div>
        ) : draft.recurrenceType === "DAYS_OF_MONTHS" ||
          mapToDaysOfMonth[draft.recurrenceType] === "DAYS_OF_MONTHS" ? (
          <BlockMonthRepeatSelectMenu
            date={date}
            recurrenceType={draft.recurrenceType}
            onChange={(newRecurrence) => {
              setDraft((last) => ({
                ...newRecurrence,
                endDate: last.endDate,
                every: last.every,
              }));
            }}
          />
        ) : null}
        <ToggleButtonList
          type="radio"
          label="Ends"
          shape="mediumPill"
          selectedValue={ends}
          options={endsOptions}
          onChange={(_, option) => setEnds(option.value)}
        />
        <FormFieldSelectMenusDatepicker
          selected={endDate}
          className="self-start"
          error={validation.result.endDate.$error}
          label="End date"
          required
          minDate={getLocalDate(date)}
          disabled={ends === "NEVER"}
          onChange={(newDate) =>
            setDraft((last) => ({
              ...last,
              endDate: newDate ? formatAsISODate(newDate) : undefined,
            }))
          }
        />
      </ModalContent>

      <ModalFooter>
        <Button theme="secondary" onClick={onRequestClose}>
          Cancel
        </Button>
        <Button onClick={handleSubmit}>Done</Button>
      </ModalFooter>
    </Modal>
  );
};
