import { FC, FormEvent, useState, useEffect, useMemo, useCallback } from "react";

import { AppointmentVO, FormTaskVO, FormSummaryVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Spinner } from "@libs/components/UI/Spinner";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus.svg";
import { ReactComponent as PlusSmallIcon } from "@libs/assets/icons/plus-small.svg";
import { cx } from "@libs/utils/cx";

import { useAccount } from "@libs/contexts/AccountContext";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { Form } from "@libs/components/UI/Form";

import { createFormTask } from "api/formTasks/mutations";

import { handleError } from "utils/handleError";

interface Props {
  patientId: number;
  appointment?: AppointmentVO;
  formTasks?: FormTaskVO[];
  forms: FormSummaryVO[];
  theme?: "circle";
}

export const AttachFormsButtonMenu: FC<Props> = ({ patientId, appointment, formTasks, forms, theme }) => {
  const { practiceId } = useAccount();
  const [searchString, setSearchString] = useState("");
  const { search, isWaiting } = useDebouncedSearch(searchString);
  const [selectedFormUuids, setSelectedFormUuids] = useState<Set<FormSummaryVO["uuid"]>>(new Set());
  const menu = useBoolean(false);

  const [createFormTaskMutation] = useApiMutations([createFormTask]);

  // Reset search and selection states when menu is closed
  useEffect(() => {
    if (menu.isOff) {
      if (searchString) {
        setSearchString("");
      }

      if (selectedFormUuids.size > 0) {
        setSelectedFormUuids(new Set());
      }
    }
  }, [menu.isOff, searchString, selectedFormUuids.size]);

  const formOptions = useMemo(() => {
    const attachedFormUuids = new Set(formTasks?.map((ft) => ft.form.uuid));

    return (
      forms
        // Exclude forms that have already been attached if any
        .filter((form) => !attachedFormUuids.has(form.uuid))
        .sort((a, b) => a.title.localeCompare(b.title))
        .map((form) => ({ label: form.title, value: form.uuid }))
    );
  }, [formTasks, forms]);

  const searchedFormOptions = useMemo(() => {
    const searchStringRegex = new RegExp(search, "i");
    const options = [];

    for (const option of formOptions) {
      if (searchStringRegex.test(option.label)) {
        options.push(option);
      }
    }

    return options;
  }, [search, formOptions]);

  const handleSubmit = useCallback(
    async (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      try {
        await Promise.all(
          [...selectedFormUuids].map((formUuid) =>
            createFormTaskMutation.mutateAsync({
              practiceId,
              patientId,
              data: {
                uuid: crypto.randomUUID(),
                formUuid,
                appointmentId: appointment?.id,
              },
            })
          )
        );

        menu.off();
      } catch (error) {
        handleError(error);
      }
    },
    [selectedFormUuids, createFormTaskMutation, practiceId, patientId, appointment?.id, menu]
  );

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      onRequestClose={menu.off}
      placement="right"
      menuContent={
        <div className="flex flex-col w-64 py-3">
          <div className="px-4 pb-2">
            <div
              className={`
                flex
                items-center
                gap-x-3
                mb-0.5
                rounded
                border
                bg-white
                focus-within:border-primaryTheme
                hover:border-primaryTheme
                border-greyLighter
              `}
            >
              <input
                type="text"
                className={`
                  block
                  w-full
                  rounded
                  pl-3
                  h-8
                  text-xs
                  placeholder:text-greyLight
                  focus-visible:outline-none
                `}
                placeholder="Search forms by name"
                value={searchString}
                onChange={(e) => setSearchString(e.target.value)}
              />

              <div className="pr-3">
                {isWaiting ? (
                  <Spinner size="sm" variant="primary" animation="border" />
                ) : searchString ? (
                  <ButtonIcon SvgIcon={CloseIcon} onClick={() => setSearchString("")} size="md" />
                ) : (
                  <SearchIcon className="w-5 h-5 fill-greyMedium" />
                )}
              </div>
            </div>
          </div>

          <Form onSubmit={handleSubmit}>
            <div className="flex-1 px-4 py-2 max-h-72 overflow-y-auto">
              <CheckboxList
                options={searchedFormOptions}
                selectedValues={selectedFormUuids}
                onChange={setSelectedFormUuids}
                layout="vert"
              />

              {searchedFormOptions.length === 0 ? <span className="text-xs">No forms found</span> : null}
            </div>

            <div className="px-4 pt-2">
              <AsyncButton
                className="w-full"
                isLoading={createFormTaskMutation.isLoading}
                disabled={selectedFormUuids.size === 0}
                type="submit"
                theme="primary"
                size="small"
              >
                Attach
              </AsyncButton>
            </div>
          </Form>
        </div>
      }
    >
      {(props) => (
        <ButtonIcon
          aria-label="Attach Forms Menu"
          className={cx(theme === "circle" && "rounded-full p-0.5 bg-slate-200")}
          SvgIcon={theme === "circle" ? PlusSmallIcon : PlusIcon}
          tooltip={{ content: "Attach Forms", theme: "SMALL" }}
          theme={theme === "circle" ? "slate700" : "primary"}
          {...props}
        />
      )}
    </ButtonMenu>
  );
};
