import React from "react";
import { cx } from "@libs/utils/cx";

type Props = {
  onClick: (color: string) => void;
  color: string;
  className: string;
  isSelected: boolean;
};

export const ColorSwatch: React.FC<Props> = ({ color, isSelected, onClick, className }) => {
  return (
    <button
      type="button"
      className={cx(
        "w-5 h-5 border-2 rounded-sm border-slate-800",
        className,
        isSelected && "outline outline-primaryTheme outline-2"
      )}
      onClick={() => onClick(color)}
    >
      <div style={{ backgroundColor: color }} className="rounded-sm h-full w-full" />
    </button>
  );
};
