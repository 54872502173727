import { FC, FormEventHandler, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { produce } from "immer";
import { fromUnixTime, getUnixTime } from "date-fns";
import { formatInTimeZone } from "date-fns-tz";
import {
  PatientVO,
  PaymentCreationRequest,
  PaymentVO,
  WalletActivityVO,
  WalletRefundRequest,
  WalletVO,
} from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { useValidation } from "@libs/hooks/useValidation";
import { eq, greaterOrEqualTo, lessThan, maxLength } from "@libs/utils/validators";
import { getFirstItem } from "@libs/utils/array";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Modal } from "@libs/components/UI/Modal";
import { Form } from "@libs/components/UI/Form";
import { Banner } from "@libs/components/UI/Banner";
import { ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import { SummaryBanner, SummaryCell } from "components/PatientProfile/Billing/SummaryBanner";
import {
  getPaymentButtonLabel,
  isPaymentMethodByCard,
  isRefundableByCard,
  getFallbackPatientWallet,
  isPlaceholderWallet,
  isPaymentRefundableAmountGreaterThanWalletAmount,
  getMaxRefundableAmount,
  getPaymentMethodToLabel,
} from "components/PatientProfile/Billing/billingUtils";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import { getWalletActivityById, getWallets } from "api/billing/queries";
import { refundWallet } from "api/billing/mutations";
import { handleError } from "utils/handleError";
import { useNotificationContext } from "contexts/NotificationsContext";
import { listPaymentDevices } from "api/settings/payments/queries";
import { NOTE_MAX_LENGTH, Note } from "components/PatientProfile/Billing/FormComponents";
import { RefundMethods } from "./RefundMethods";
import { usePaymentPolling } from "./usePaymentPolling";

type Props = {
  onClose: Func;
  onRefund: Func;
  patientId: PatientVO["id"];
  walletUuid: WalletVO["uuid"];
  walletActivityId: WalletActivityVO["id"];
};

// eslint-disable-next-line complexity
export const RefundPrePaymentModal: FC<Props> = ({
  patientId,
  walletUuid,
  walletActivityId,
  onClose,
  onRefund,
}) => {
  const practice = useCurrentPractice();
  const notification = useNotificationContext();

  const [refundRequest, setRefundRequest] = useState<WalletRefundRequest>(() => ({
    note: "",
    paymentCreationRequest: {
      type: "REFUND",
      // Default using a payment method that doesn't have side-effects, unlike "STORED_POS" which
      // would fetch POS devices unnecessarily if the refund method shouldn't be done by
      // "STORED_POS". The final payment method will be determined when the wallet activity data is
      // eventually fetched.
      method: "CASH",
      currencyAmount: { amount: 0, currency: "USD" },
      idempotencyUuid: crypto.randomUUID(),
      paymentCreatedAt: getUnixTime(new Date()),
    },
  }));

  const [walletActivityQuery, walletsQuery, paymentDevicesQuery] = useApiQueries([
    getWalletActivityById({
      args: {
        practiceId: practice.id,
        patientId,
        walletUuid,
        walletActivityId,
      },
    }),
    getWallets({ args: { patientId, practiceId: practice.id } }),
    listPaymentDevices({
      args: { practiceId: practice.id, query: { states: ["ACTIVATED"] } },
      queryOptions: { enabled: refundRequest.paymentCreationRequest.method === "STORED_POS" },
    }),
  ]);

  const [refundPaymentUuid, setRefundPaymentUuid] = useState<string>();
  const { isPolling: isProcessingPayment } = usePaymentPolling({
    practiceId: practice.id,
    paymentUuid: refundPaymentUuid,
    onSuccess: () => {
      notification.handleSuccess("Refund was successful.");
      onRefund();
    },
    onError: (paymentGatewayError) => {
      const errorMessageParts = ["Failed to process refund."];

      if (paymentGatewayError) {
        errorMessageParts.push(paymentGatewayError);
      }

      notification.handleError(errorMessageParts.join(" "), { autoClose: false });
    },
  });

  const patientWallet = getFallbackPatientWallet(patientId, practice.id, walletsQuery.data);
  const originalPayment = walletActivityQuery.data?.payment;
  const { maxRefundableAmount } = getMaxRefundableAmount(originalPayment, patientWallet);
  const showWalletBalanceLessThanRefundAmountWarning = isPaymentRefundableAmountGreaterThanWalletAmount(
    originalPayment,
    patientWallet
  );

  const validation = useValidation(refundRequest, {
    paymentCreationRequest: {
      type: { $validations: [{ $v: eq("REFUND") }] },
      currencyAmount: {
        // The refund amount is stored as a negative number but we show it as a
        // positive number to the user, so the user-facing error message must
        // say that the amount should be greater than 0.
        amount: {
          $validations: [
            { $v: lessThan(0), $error: "Refund amount must be greater than 0" },
            {
              $v: greaterOrEqualTo(-maxRefundableAmount),
              $error: `Refund amount cannot exceed ${formatCurrency(maxRefundableAmount)}`,
            },
          ],
        },
      },
      note: {
        $validations: [
          {
            $v: maxLength(NOTE_MAX_LENGTH),
            $error: `Note cannot exceed ${NOTE_MAX_LENGTH} characters.`,
          },
        ],
      },
    },
  });

  useSyncOnce(
    ({ payment, wallet }) => {
      const { maxRefundableAmount: refundableAMount } = getMaxRefundableAmount(payment, wallet);

      handleAmountChange(refundableAMount);

      const refundMethodToUse: PaymentVO["method"] = isRefundableByCard(payment) ? payment.method : "CASH";

      handleMethodChange(refundMethodToUse);
      setRefundRequest((last) =>
        produce(last, (draft) => {
          draft.paymentCreationRequest.chargePaymentUuid = payment.uuid;

          const zonedPaymentDate = formatInTimeZone(
            fromUnixTime(payment.paymentCreatedAt),
            practice.timezoneId,
            "P"
          );

          draft.note = `For deposit on ${zonedPaymentDate}`;
        })
      );
    },
    originalPayment && !isPlaceholderWallet(patientWallet)
      ? {
          payment: originalPayment,
          wallet: patientWallet,
        }
      : undefined
  );

  useSyncOnce((posDevices) => {
    const firstActiveDeviceFromPosList = getFirstItem(posDevices);

    if (firstActiveDeviceFromPosList) {
      handlePosDeviceChange(firstActiveDeviceFromPosList.uuid);
    }
  }, paymentDevicesQuery.data);

  const originalPaymentAmount = originalPayment?.currencyAmount.amount;
  const refundablePaymentAmount = originalPayment?.refundableCurrencyAmount?.amount;
  const originalPaymentMethod = originalPayment && getPaymentMethodToLabel(originalPayment);
  const originalPaymentDate = originalPayment?.paymentCreatedAt;

  const [{ mutate: refund, isLoading: isSubmittingRefund }] = useApiMutations([refundWallet]);
  const isProcessingRefund = isSubmittingRefund || isProcessingPayment;

  const handleAmountChange = (cents: number) => {
    setRefundRequest((last) =>
      produce(last, (draft) => {
        draft.paymentCreationRequest.currencyAmount.amount = -cents;
      })
    );
  };

  const handlePosDeviceChange = (
    paymentDeviceUuid: WalletRefundRequest["paymentCreationRequest"]["paymentDeviceUuid"]
  ) => {
    setRefundRequest((last) =>
      produce(last, (draft) => {
        draft.paymentCreationRequest.paymentDeviceUuid = paymentDeviceUuid;
      })
    );
  };

  const handleMethodChange = (method: PaymentVO["method"]) => {
    setRefundRequest((last) =>
      produce(last, (draft) => {
        draft.paymentCreationRequest.method = method;
      })
    );
  };

  const handleCheckPayloadChange = (checkPayload: PaymentCreationRequest["checkPayload"]) =>
    setRefundRequest((last) =>
      produce(last, (draft) => {
        draft.paymentCreationRequest.checkPayload = checkPayload;
      })
    );

  const handleRefundDateChange = (date: PaymentCreationRequest["paymentCreatedAt"]) =>
    setRefundRequest((last) =>
      produce(last, (draft) => {
        draft.paymentCreationRequest.paymentCreatedAt = date;
      })
    );

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();

    const result = validation.validate();

    if (!result.$isValid) {
      return;
    }

    refund(
      { practiceId: practice.id, patientId, walletUuid, data: refundRequest },
      {
        onError: handleError,
        onSuccess: (data) => setRefundPaymentUuid(data.data.data.payment?.uuid),
      }
    );

    return;
  };

  const showNonRefundableCreditCardWarning =
    walletActivityQuery.data?.payment &&
    isPaymentMethodByCard(walletActivityQuery.data.payment.method) &&
    !isRefundableByCard(walletActivityQuery.data.payment);

  return (
    <Modal title="Refund" onClose={onClose}>
      {showNonRefundableCreditCardWarning && (
        <Banner theme="warning" className="text-sm">
          Refunds to a credit card are only available for 30 days after the payment date. Please process a
          refund by cash or check.
        </Banner>
      )}
      {showWalletBalanceLessThanRefundAmountWarning && refundablePaymentAmount && (
        <Banner theme="warning" className="text-sm">
          The Deposit Amount ({formatCurrency(refundablePaymentAmount)}) is greater than the Wallet Balance (
          {formatCurrency(patientWallet.balance)}). The Refundable Amount has been set to{" "}
          {formatCurrency(maxRefundableAmount)}.
        </Banner>
      )}
      <ModalContent padding="lg">
        <QueryResult
          queries={[walletActivityQuery]}
          loading={
            <div className="flex flex-col gap-y-6">
              <Skeleton className="h-16" />
              <Skeleton count={10} />
            </div>
          }
        >
          <Form className="flex flex-col gap-y-6" onSubmit={handleSubmit}>
            <SummaryBanner>
              <SummaryCell header="Deposit Method">{originalPaymentMethod}</SummaryCell>
              <SummaryCell header="Paid On">
                {originalPaymentDate && (
                  <DateWithTooltip date={originalPaymentDate} dateAsSeconds={true} format="P" />
                )}
              </SummaryCell>
              <SummaryCell header="Original Deposit" className="text-right">
                {formatCurrency(originalPaymentAmount ?? 0)}
              </SummaryCell>
              <SummaryCell header="Refundable" className="text-right">
                {formatCurrency(maxRefundableAmount)}
              </SummaryCell>
            </SummaryBanner>
            <RefundMethods
              method={refundRequest.paymentCreationRequest.method}
              paymentProfile={walletActivityQuery.data?.payment?.paymentProfile}
              onMethodChange={handleMethodChange}
              refundAmount={Math.abs(refundRequest.paymentCreationRequest.currencyAmount.amount)}
              onRefundAmountChange={handleAmountChange}
              refundDate={refundRequest.paymentCreationRequest.paymentCreatedAt}
              onRefundDateChange={handleRefundDateChange}
              checkPayload={refundRequest.paymentCreationRequest.checkPayload}
              onCheckChange={handleCheckPayloadChange}
              errors={{ refundAmount: validation.result.paymentCreationRequest.currencyAmount.amount.$error }}
            />
            <Note
              value={refundRequest.paymentCreationRequest.note}
              error={validation.result.paymentCreationRequest.note.$error}
              onChange={(e) =>
                setRefundRequest((last) =>
                  produce(last, (draft) => {
                    draft.paymentCreationRequest.note = e.target.value;
                  })
                )
              }
            />
          </Form>
        </QueryResult>
      </ModalContent>
      <ModalFooter>
        <Button className="min-w-button" theme="secondary" onClick={onClose}>
          Cancel
        </Button>
        <AsyncButton
          isLoading={isProcessingRefund}
          disabled={isProcessingRefund}
          displayLoadingText={true}
          className="min-w-button"
          theme="primary"
          onClick={handleSubmit}
        >
          {getPaymentButtonLabel({
            isProcessing: isProcessingRefund,
            paymentMethod: walletActivityQuery.data?.payment?.method ?? "CASH",
            paymentType: "REFUND",
          })}
        </AsyncButton>
      </ModalFooter>
    </Modal>
  );
};
