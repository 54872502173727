import React, { FormEventHandler, useCallback, useMemo, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { CurrencyInputProps } from "react-currency-input-field";
import { fromUnixTime, getUnixTime } from "date-fns";
import { produce } from "immer";
import { CreateAdjustmentRequest } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { centsToDollars, dollarsToCents } from "@libs/utils/currency";
import { required, notEq } from "@libs/utils/validators";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useAccount } from "@libs/contexts/AccountContext";
import { Form } from "@libs/components/UI/Form";
import { Banner } from "@libs/components/UI/Banner";
import { Modal, ModalProps } from "@libs/components/UI/Modal";
import { ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import { getPatientSummary } from "api/patients/queries";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { FormFieldCurrencyInput } from "components/UI/FormFieldCurrencyInput";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";
import { handleError } from "utils/handleError";
import { getEmployeeNames } from "api/employee/queries";
import { createAdjustment, editAdjustment } from "api/billing/mutations";
import { getAdjustment } from "api/billing/queries";
import { getAdjustmentTypesForPractice } from "api/settings/payments/queries";
import { SkeletonInputField } from "components/UI/SkeletonInputField";
import { FormFieldSelectAdjustments } from "components/PatientProfile/Billing/FormFieldSelectAdjustments";
import { getPatientDisplayNameFromPatient } from "utils/names";
import { useNow } from "hooks/useNow";
import { RoleGuardClick } from "components/Main/RoleGuard";
import { usePermittedAdjustments } from "components/PatientProfile/Billing/billingUtils";

const adjustmentFormSchema = (isEditing: boolean) => ({
  adjustmentDate: [{ $v: required, $error: "Date required" }],
  adjustmentAmount: [
    { $v: required, $error: "Amount required" },
    {
      $v: notEq(0),
      // The wording of the error message says that the amount should be greater
      // than 0 but the validator ensures it's "non-zero" instead of "greater
      // than zero". This is because we submit to the backend the amount value
      // as a negative number for credits but the UI doesn't allow negative
      // values to be entered in the first place, so from the user's perspective
      // non-zero really means greater than zero.
      $error: "Amount must be greater than 0",
      $ignore: isEditing,
    },
  ],
  adjustmentNote: [{ $v: required, $error: "Note required" }],
  customAdjustmentTypeId: [{ $v: notEq(0), $error: "Adjustment Type required" }],
});

const FORM_ID = "adjustment";

const hasAdjustementUuid = <T extends string>(adjustmentUuid?: T): adjustmentUuid is NonNullable<T> =>
  typeof adjustmentUuid === "string";

interface Props extends Pick<ModalProps, "onClose"> {
  patientId: number;
  adjustmentUuid?: string;
  attachedToInvoice: boolean;
  onAdjustmentSave: () => void;
}

// eslint-disable-next-line complexity
export const AdjustmentModal: React.FC<Props> = ({
  patientId,
  adjustmentUuid,
  attachedToInvoice,
  onAdjustmentSave,
  ...rest
}) => {
  const now = useNow();
  const isEditing = hasAdjustementUuid(adjustmentUuid);
  const { practiceId } = useAccount();
  const [currencyInput, setCurrencyInput] = useState("");
  const [adjustmentDraft, setAdjustmentDraft] = useState<CreateAdjustmentRequest>(() => ({
    idempotencyUuid: crypto.randomUUID(), // Ignored by server when editing an adjustment
    currencyAmount: { currency: "USD", amount: 0 },
    note: "",
    orderCreatedAt: getUnixTime(new Date()),
    customAdjustmentTypeId: 0,
  }));

  const [{ data: patientData }, { data: adjustmentData }] = useApiQueries([
    getPatientSummary({ args: { patientId, practiceId } }),
    getAdjustment({
      args: { practiceId, patientId, adjustmentUuid: adjustmentUuid || "" },
      queryOptions: {
        enabled: isEditing,
      },
    }),
  ]);

  /**
   * Populate the adjustment draft with the adjustment to be edited, if any.
   */
  useSyncOnce((data) => {
    setAdjustmentDraft((last) => ({
      ...last,
      currencyAmount: { currency: "USD", amount: data.amount },
      note: data.note ?? "",
      orderCreatedAt: data.adjustmentCreatedAt,
      employeeId: data.employeeId,
      customAdjustmentTypeId: data.customAdjustmentType.id,
    }));
    setCurrencyInput(String(centsToDollars(Math.abs(data.amount))));
  }, adjustmentData);

  const formSchema = useMemo(() => adjustmentFormSchema(isEditing), [isEditing]);

  const [
    { mutate: createAdjustmentMutate, isLoading: isCreatingAdjustment },
    { mutate: editAdjustmentMutate, isLoading: isEditingAdjustment },
  ] = useApiMutations([createAdjustment, editAdjustment]);
  const [isValidating, setIsValidating] = useState(false);
  const adjustmentForm = useValidation(
    {
      adjustmentAmount: adjustmentDraft.currencyAmount.amount,
      adjustmentDate: adjustmentDraft.orderCreatedAt,
      adjustmentNote: adjustmentDraft.note,
      customAdjustmentTypeId: adjustmentDraft.customAdjustmentTypeId,
    },
    formSchema,
    { isValidating }
  );

  const [{ data: employeeNames }, { data: adjustments, isInitialLoading: isLoadingAdjustments }] =
    useApiQueries([
      // Fetch employee of the edited adjustment, if any.
      getEmployeeNames({ args: { practiceId, statuses: ["ACTIVE"] } }),
      getAdjustmentTypesForPractice({
        // When we edit an invoice adjustment, it's possible that its adjustment
        // type is archived but we still want to show it in the dropdown. For
        // that scenario, we need to fetch archived adjustments.
        args: { practiceId, query: { hideArchived: !isEditing } },
      }),
    ]);

  const permittedAdjustments = usePermittedAdjustments(adjustments);

  const employeeOptions = useMemo(() => {
    return (employeeNames ?? []).map((employee) => ({
      label: employee.fullDisplayName,
      value: employee.id,
    }));
  }, [employeeNames]);

  const handleSaveAdjustment: FormEventHandler = useCallback(
    (e) => {
      e.preventDefault();

      const formResults = adjustmentForm.validate();

      if (!formResults.$isValid) {
        setIsValidating(true);

        return;
      }

      if (isEditing) {
        editAdjustmentMutate(
          { practiceId, patientId, adjustmentUuid, data: adjustmentDraft },
          { onSuccess: onAdjustmentSave, onError: handleError }
        );
      } else {
        createAdjustmentMutate(
          { practiceId, patientId, data: adjustmentDraft },
          { onSuccess: onAdjustmentSave, onError: handleError }
        );
      }
    },
    [
      adjustmentDraft,
      adjustmentForm,
      adjustmentUuid,
      createAdjustmentMutate,
      editAdjustmentMutate,
      isEditing,
      onAdjustmentSave,
      patientId,
      practiceId,
    ]
  );

  const handleAmountChange: CurrencyInputProps["onValueChange"] = useCallback(
    (value?: string) => {
      if (!value) {
        setCurrencyInput("");
        setAdjustmentDraft((last) =>
          produce(last, (draft) => {
            draft.currencyAmount.amount = 0;
          })
        );

        return;
      }

      setCurrencyInput(String(value));
      setAdjustmentDraft((last) =>
        produce(last, (draft) => {
          const amount = dollarsToCents(Number(value));

          const selectedAdjustmentType =
            adjustments?.find(
              // eslint-disable-next-line max-nested-callbacks
              (adjust) => adjust.id === last.customAdjustmentTypeId
            )?.entryType ?? "DEBIT";

          draft.currencyAmount.amount = selectedAdjustmentType === "DEBIT" ? amount : -amount;
        })
      );
    },
    [adjustments]
  );

  return (
    <Modal
      title={
        <div className="flex items-center">
          {isEditing ? "Edit" : "Create"} Adjustment
          <span className="mx-3 font-sans">|</span>
          <span className="text-sm">
            {patientData ? getPatientDisplayNameFromPatient(now, patientData) : <Skeleton className="w-64" />}
          </span>
        </div>
      }
      {...rest}
    >
      {attachedToInvoice && (
        <Banner theme="warning" className="text-sm">
          {/* eslint-disable-next-line react/no-unescaped-entities */}
          This adjustment has been invoiced and the amount cannot be changed. If the invoice hasn't been paid,
          void the invoice first.
        </Banner>
      )}
      <ModalContent>
        <Form id={FORM_ID} className="mb-28 px-8 pt-5" onSubmit={handleSaveAdjustment}>
          <div className="grid grid-cols-[2fr_3fr_3fr_2fr] mt-4 gap-x-3">
            <FormFieldSelectMenusDatepicker
              label="Adjustment Date"
              layout="labelIn"
              required={true}
              error={adjustmentForm.result.adjustmentDate.$error}
              selected={fromUnixTime(adjustmentDraft.orderCreatedAt || 0)}
              onChange={(newDate, event) => {
                newDate &&
                  setAdjustmentDraft((last) => ({
                    ...last,
                    orderCreatedAt: getUnixTime(newDate),
                  }));

                // Prevents filter menu from closing (numbs `onRequestClose`)
                event?.stopPropagation();
              }}
            />
            {isLoadingAdjustments ? (
              <SkeletonInputField />
            ) : (
              adjustments &&
              permittedAdjustments && (
                <FormFieldSelectAdjustments
                  adjustments={isEditing ? adjustments : permittedAdjustments}
                  showArchived="ONLY_SELECTED"
                  required={true}
                  value={adjustmentDraft.customAdjustmentTypeId}
                  error={adjustmentForm.result.customAdjustmentTypeId.$error}
                  onChange={(adjustmentOption) => {
                    if (!adjustmentOption) {
                      return;
                    }

                    setAdjustmentDraft((last) =>
                      produce(last, (draft) => {
                        draft.customAdjustmentTypeId = adjustmentOption.value;
                        draft.currencyAmount.amount =
                          adjustmentOption.type === "DEBIT"
                            ? Math.abs(last.currencyAmount.amount)
                            : -Math.abs(last.currencyAmount.amount);
                      })
                    );
                  }}
                />
              )
            )}
            <FormFieldSelect
              value={adjustmentDraft.employeeId}
              placeholder="Search Employee"
              label="Employee Assigned"
              layout="labelIn"
              options={employeeOptions}
              onChange={(item) => setAdjustmentDraft((last) => ({ ...last, employeeId: item?.value }))}
            />
            <FormFieldCurrencyInput
              label="Amount"
              required={true}
              layout="labelIn"
              value={currencyInput}
              placeholder="eg. $100"
              disabled={attachedToInvoice}
              error={adjustmentForm.result.adjustmentAmount.$error}
              onValueChange={handleAmountChange}
            />
          </div>
          <div className="mt-6">
            <FormFieldTextarea
              rows={8}
              maxLength={500}
              label={
                <div>
                  <span className="font-sansSemiBold">Note</span> (This note will appear on the invoice)
                </div>
              }
              required={true}
              placeholder="Enter any important notes related to this adjustment..."
              value={adjustmentDraft.note}
              error={adjustmentForm.result.adjustmentNote.$error}
              onChange={(e) => setAdjustmentDraft((last) => ({ ...last, note: e.target.value }))}
            />
          </div>
        </Form>
      </ModalContent>
      <ModalFooter>
        <RoleGuardClick
          domain="BILLING"
          action={
            adjustmentDraft.currencyAmount.amount < 0 ? "ADD_ADJUSTMENT_DISCOUNT" : "ADD_ADJUSTMENT_CHARGE"
          }
        >
          <AsyncButton
            className="min-w-button"
            form={FORM_ID}
            type="submit"
            isLoading={isCreatingAdjustment || isEditingAdjustment}
            disabled={!(adjustmentForm.result.$isValid ?? true)}
          >
            {isEditing ? "Save" : "Record Adjustment"}
          </AsyncButton>
        </RoleGuardClick>
      </ModalFooter>
    </Modal>
  );
};
