import React, { useCallback, useEffect } from "react";
import { useDebouncedCallback } from "use-debounce";
import { useQueryClient } from "@tanstack/react-query";
import { MedicalImageVO, MountVO } from "@libs/api/generated-api";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { shallowEqual } from "@libs/utils/shallowEqual";
import { updateMedicalImageDetails } from "api/imaging/mutations";
import { updateCachedImage } from "api/imaging/cache";
import {
  DEFAULT_TRANSFORMS,
  getImageTransformStyles,
  revertImageToDefaults,
} from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { handleError } from "utils/handleError";
import { useMountUpdater } from "components/PatientProfile/Imaging/MountRoute/hooks/useMountUpdater";
import { imageHookConfig } from "components/PatientProfile/Imaging/MountRoute/hooks/config";
import { CanvasEditorRef, ProposedImageChanges } from "components/PatientProfile/Imaging/types/imageEditor";
import { ArchyFabricCanvasJSON } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/fabricUtils";

type PendingImageChanges = ProposedImageChanges & { imageId: number };

const getLatestAnnotations = (
  pendingChanges: ProposedImageChanges,
  image: MedicalImageVO,
  editor: CanvasEditorRef | null
) => {
  const rotationTransform = getImageTransformStyles({ ...image.transforms, ...pendingChanges.transforms });
  const { rotationDegrees } = rotationTransform;

  const instance = editor?.getInstance();
  let annotationJSON: string | undefined = undefined;

  if (instance && pendingChanges.newAnnotations) {
    const hasAnnotations = instance.getObjects().length > 0;

    if (hasAnnotations && editor) {
      if (rotationDegrees !== 0) {
        editor.rotateCanvas(-rotationDegrees, instance);
      }

      // We must clone the canvas before exporting, otherwise the `toDatalessJSON` call will issue events to our event handlers that the canvas has changed
      const canvasData = instance.toDatalessJSON(["data"]);
      const viewBox = {
        width: instance.getWidth(),
        height: instance.getHeight(),
      };

      if (rotationDegrees !== 0) {
        editor.rotateCanvas(rotationDegrees, instance);
      }

      const archyCanvas: ArchyFabricCanvasJSON = {
        ...canvasData,
        viewBox,
      };

      annotationJSON = JSON.stringify(archyCanvas);
    } else {
      annotationJSON = "";
    }
  }

  return annotationJSON;
};

export const useImageEditQueue = ({
  imageMount,
  patientId,
  imageEditor,
}: {
  imageMount?: MountVO;
  patientId: number;
  imageEditor?: React.MutableRefObject<CanvasEditorRef | null>;
}) => {
  const mountId = imageMount?.id;

  const editsPromise = React.useRef<undefined | Promise<unknown>>(undefined);
  const {
    handleImageOriginChanged,
    debouncedUpdateMount,
    mountUpdaterPromise,
    handleMountChanged,
    isSavingMount,
  } = useMountUpdater({
    patientId,
    imageMount,
    editsPromise,
  });
  const { practiceId } = useAccount();
  //We accumulate edits with dirty edit state
  const pendingEdits = React.useRef<PendingImageChanges | null>(null);

  // Tracks the request completing for the latest edits
  const [{ mutateAsync: mutateDetails }] = useApiMutations([updateMedicalImageDetails]);
  const queryClient = useQueryClient();

  const updateImageAndPersist = useCallback(
    async ({ image, persistToServer }: { image: MedicalImageVO; persistToServer: boolean }) => {
      if (!image.id) {
        return;
      }

      updateCachedImage(
        queryClient,
        {
          patientId,
          mountId,
          imageId: image.id,
          practiceId,
        },
        image
      );

      try {
        if (persistToServer) {
          editsPromise.current = mutateDetails({
            practiceId,
            patientId,
            imageId: image.id,
            mountId,
            data: image,
          });
          await editsPromise.current;
        }

        pendingEdits.current = null;
      } catch (err) {
        handleError(err);
      }
    },
    [mountId, mutateDetails, patientId, practiceId, queryClient]
  );
  const commitEdits = React.useCallback(
    // eslint-disable-next-line complexity
    async ({
      image,
      pendingChanges,
      persistToServer = true,
      annotationJSON,
    }: {
      image: MedicalImageVO;
      pendingChanges: PendingImageChanges;
      persistToServer?: boolean;
      annotationJSON?: string;
    }) => {
      if (!image.id) {
        return;
      }

      // Wait for prior requests to the mount & image to complete
      await Promise.all([editsPromise.current, mountUpdaterPromise.current]);

      if (
        !shallowEqual(pendingChanges.transforms, image.transforms) ||
        !shallowEqual(pendingChanges.filters, image.filters) ||
        pendingChanges.assignedDate !== image.assignedDate ||
        pendingChanges.sensor !== image.sensor ||
        pendingChanges.newAnnotations
      ) {
        const persisted: MedicalImageVO = {
          filters: {
            ...image.filters,
            ...pendingChanges.filters,
          },
          transforms: {
            ...DEFAULT_TRANSFORMS,
            ...image.transforms,
            ...pendingChanges.transforms,
          },
          assignedDate: pendingChanges.assignedDate ?? image.assignedDate,
          sensor: pendingChanges.sensor ?? image.sensor,
        };

        if (pendingChanges.newAnnotations && imageEditor?.current) {
          annotationJSON = getLatestAnnotations(pendingChanges, image, imageEditor.current);
          persisted.annotation = annotationJSON;
        }

        updateImageAndPersist({ image: { ...persisted, id: image.id }, persistToServer });
      }
    },
    [imageEditor, mountUpdaterPromise, updateImageAndPersist]
  );

  const debouncedImageChanges = useDebouncedCallback(commitEdits, imageHookConfig.imageEditCommitDelayMS, {
    leading: false,
    trailing: true,
  });

  const handleImageUpdate = React.useCallback(
    async (image: MedicalImageVO, params: ProposedImageChanges, persistToServer = true) => {
      if (!image.id) {
        return editsPromise.current;
      }

      if (image.id !== pendingEdits.current?.imageId) {
        // Can't debounce the calls any longer, as the image being edited has changed, flush the prior image changes
        debouncedImageChanges.flush();
      }

      pendingEdits.current = {
        ...pendingEdits.current,
        imageId: image.id,
        newAnnotations: params.newAnnotations || pendingEdits.current?.newAnnotations,
        filters: {
          ...pendingEdits.current?.filters,
          ...params.filters,
        },
        transforms: {
          ...pendingEdits.current?.transforms,
          ...params.transforms,
        },
      };

      const extensions: Partial<MedicalImageVO> = {
        filters: {
          ...image.filters,
          ...pendingEdits.current.filters,
        },
        transforms: {
          ...DEFAULT_TRANSFORMS,
          ...image.transforms,
          ...pendingEdits.current.transforms,
        },
        annotation: image.annotation,
      };

      for (const key of ["assignedDate", "sensor"] as const) {
        if (key in params) {
          pendingEdits.current[key] = params[key];
          extensions[key] = params[key];
        }
      }

      updateCachedImage(
        queryClient,
        {
          patientId,
          mountId,
          imageId: image.id,
          practiceId,
        },
        extensions
      );

      debouncedImageChanges({ image, pendingChanges: pendingEdits.current, persistToServer });

      return editsPromise.current;
    },
    [queryClient, patientId, mountId, practiceId, debouncedImageChanges]
  );

  const revertImage = React.useCallback(
    (image: MedicalImageVO) => {
      debouncedImageChanges.cancel();

      return updateImageAndPersist({
        image: revertImageToDefaults(image),
        persistToServer: true,
      });
    },
    [debouncedImageChanges, updateImageAndPersist]
  );
  const flushMountChanges = useCallback(() => {
    debouncedUpdateMount.flush();
    debouncedImageChanges.flush();
  }, [debouncedImageChanges, debouncedUpdateMount]);

  // Flush the queue when the component unmounts
  useEffect(() => flushMountChanges, [flushMountChanges]);

  return {
    handleImageUpdate,
    handleImageOriginChanged,
    handleMountChanged,
    isSavingMount,
    revertImage,
    flushMountChanges,
    debouncedImageChanges,
  };
};

export type UseImageEditQueue = ReturnType<typeof useImageEditQueue>;
