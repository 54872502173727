import React, { useState, FormEvent, useMemo } from "react";
import { parseISO, setYear } from "date-fns";
import { CustomHolidayVO } from "@libs/api/generated-api";
import { formatAsISODate, getLocalDate, nowInTimezone } from "@libs/utils/date";
import { isString } from "@libs/utils/types";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as RemoveIcon } from "@libs/assets/icons/minus.svg";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { Form } from "@libs/components/UI/Form";
import { SettingsLabel } from "@libs/components/UI/SettingsComponents";
import { handleError } from "utils/handleError";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { upsertCustomHolidays } from "api/scheduling/mutations";
import { TimeInput } from "components/UI/TimeInput";
import { YearTabs } from "components/Settings/YearTabs";

const cxDateTimeStyles = {
  day: "text-xs mb-5",
  weekDay: "text-xs font-sansSemiBold mt-5 mb-3.5",
  row: `
    grid
    grid-cols-[min-content_min-content_1fr]
    gap-x-5
    gap-y-2
    mb-2
  `,
};

type EditableCustomHolidayVO = Omit<CustomHolidayVO, "id"> & {
  id: number | string;
};

type Props = {
  customHolidays: CustomHolidayVO[];
  selectedYear: string;
  onYearUpdate: (year: string) => void;
  handleOnEditSection: () => void;
};
export const EditTimeOff: React.FC<Props> = (props) => {
  const practice = useCurrentPractice();
  const { handleOnEditSection, customHolidays, selectedYear, onYearUpdate } = props;
  const [editableHolidays, setEditableHolidays] = useState<EditableCustomHolidayVO[]>(
    () => customHolidays as unknown as EditableCustomHolidayVO[]
  );
  const [updateCustomHolidaysMutation] = useApiMutations([upsertCustomHolidays]);

  const yearHolidays = useMemo(
    () => editableHolidays.filter((item) => new Date(item.startDate).getFullYear() === Number(selectedYear)),
    [editableHolidays, selectedYear]
  );

  const handleItemChange = (
    value: string | Date | null | number,
    name: keyof CustomHolidayVO,
    id: number | string
  ) => {
    setEditableHolidays((old) => old.map((item) => (item.id === id ? { ...item, [name]: value } : item)));
  };

  const addTimeInterval = () => {
    const id = crypto.randomUUID();

    setEditableHolidays((old) => [
      ...old,
      {
        id,
        endTime: "00:00:00",
        startTime: "00:00:00",
        endDate: formatAsISODate(
          setYear(nowInTimezone(practice.timezoneId), Number.parseInt(selectedYear, 10))
        ),
        startDate: formatAsISODate(
          setYear(nowInTimezone(practice.timezoneId), Number.parseInt(selectedYear, 10))
        ),
      },
    ]);
  };

  const removeTimeInterval = (id: number | string) => {
    setEditableHolidays((old) => old.filter((item) => item.id !== id));
  };

  const handleSavedChanges = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const newCustomHolidays = editableHolidays.map(({ id, ...item }) =>
      isString(id) ? item : { ...item, id }
    );

    updateCustomHolidaysMutation.mutate(
      { practiceId: practice.id, data: newCustomHolidays },
      {
        onSuccess: handleOnEditSection,
        onError: handleError,
      }
    );
  };

  return (
    <Form onSubmit={handleSavedChanges}>
      <YearTabs selectedTab={selectedYear} onSelect={onYearUpdate} tabsClassName="mb-2">
        {yearHolidays.map((yearHoliday, index) => {
          const firstOfYear = getLocalDate(`${selectedYear}-01-01`);
          const endOfYear = getLocalDate(`${selectedYear}-12-31`);

          return (
            <div key={yearHoliday.id} className={cxDateTimeStyles.row}>
              {index === 0 ? (
                <>
                  <SettingsLabel>Closed From</SettingsLabel>
                  <SettingsLabel>Closed Until</SettingsLabel>
                  <SettingsLabel>Description</SettingsLabel>
                </>
              ) : null}

              <div className="flex items-center gap-x-5">
                <FormFieldSelectMenusDatepicker
                  className="min-w-32"
                  layout="labelOut"
                  onChange={(date) => {
                    if (yearHoliday.id) {
                      handleItemChange(date ? formatAsISODate(date) : null, "startDate", yearHoliday.id);
                    }
                  }}
                  minDate={firstOfYear}
                  maxDate={endOfYear}
                  selected={yearHoliday.startDate ? parseISO(yearHoliday.startDate) : null}
                />
                <TimeInput
                  layout="labelOut"
                  value={yearHoliday.startTime}
                  onChange={(value) => {
                    handleItemChange(value, "startTime", yearHoliday.id);
                  }}
                />
              </div>
              <div className="flex items-center gap-x-5">
                <FormFieldSelectMenusDatepicker
                  className="min-w-32"
                  layout="labelOut"
                  minDate={firstOfYear}
                  maxDate={endOfYear}
                  selected={yearHoliday.endDate ? parseISO(yearHoliday.endDate) : null}
                  onChange={(date) => {
                    handleItemChange(date ? formatAsISODate(date) : null, "endDate", yearHoliday.id);
                  }}
                />
                <TimeInput
                  layout="labelOut"
                  value={yearHoliday.endTime}
                  onChange={(value) => {
                    handleItemChange(value, "endTime", yearHoliday.id);
                  }}
                />
              </div>
              <div className="flex flex-row items-center">
                <FormFieldInput
                  value={yearHoliday.description}
                  placeholder="Write Description"
                  layout="labelOut"
                  onChange={(e) => {
                    handleItemChange(e.target.value, "description", yearHoliday.id);
                  }}
                />

                <ButtonIcon
                  type="button"
                  theme="primary"
                  SvgIcon={RemoveIcon}
                  className="ml-4"
                  onClick={() => {
                    removeTimeInterval(yearHoliday.id);
                  }}
                />
              </div>
            </div>
          );
        })}
      </YearTabs>
      <button
        type="button"
        className={`
          mb-5
          flex
          flex-row
          space-x-1
          items-center
          text-primaryTheme
          hover:opacity-70
          text-xs
          font-sansSemiBold
        `}
        onClick={addTimeInterval}
      >
        <AddIcon className="w-5 h-5 cursor-pointer" />
        <div>Add time off</div>
      </button>
      <div className="flex flex-wrap mt-5 pb-2 gap-x-2">
        <Button theme="secondary" size="large" className="w-[100px]" onClick={() => handleOnEditSection()}>
          Cancel
        </Button>
        <Button type="submit" theme="primary" size="large" className="w-[100px]">
          Save
        </Button>
      </div>
    </Form>
  );
};
