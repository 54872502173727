import { makeQuery } from "@libs/utils/queries";
import { oneMinuteCache } from "@libs/utils/queryCache";

export const getIpFilters = makeQuery({
  queryKey: ["practices", "getIpFilters"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: oneMinuteCache,
});

export const getIpAddress = makeQuery({
  queryKey: ["security", "getIpAddress"],
  formatParams: () => [],
  queryOptions: oneMinuteCache,
});

export const getSelfBookableProvidersQuery = makeQuery({
  queryKey: ["public", "getSelfBookableProviders"],
  formatParams: (args: { practiceUuid: string }) => [args.practiceUuid],
  queryOptions: oneMinuteCache,
});
