import { cx } from "@libs/utils/cx";
import { isNumber } from "@libs/utils/types";
import { formatCurrency } from "@libs/utils/currency";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { FormFieldCurrencyInput } from "components/UI/FormFieldCurrencyInput";
import { cxStyles, EditableColumnIds } from "components/Claim/ProcedureTable";

interface Props {
  disableActions: boolean;
  errorMessage?: string;
  inputId: EditableColumnIds;
  isAdditionalPayment: boolean;
  onBlur: (id: EditableColumnIds) => void;
  onFocus: (id: EditableColumnIds) => void;
  onValueChange: (id: EditableColumnIds, value: string) => void;
  previousValue?: number;
  value: string | undefined;
}

// eslint-disable-next-line complexity
export const ProcedureTableCurrencyCell: React.FC<Props> = ({
  disableActions,
  errorMessage,
  inputId,
  isAdditionalPayment,
  onBlur,
  onFocus,
  onValueChange,
  previousValue,
  value,
}) => {
  return (
    <>
      {isAdditionalPayment && isNumber(previousValue) && (
        <div
          className={cx(
            "flex items-center justify-end pr-3 py-1.5",
            cxStyles.cellYPadding,
            cxStyles.currencyDividerBorder,
            cxStyles.currencyReadOnly
          )}
        >
          {formatCurrency(previousValue)}
        </div>
      )}
      <FloatingTooltip content={errorMessage}>
        <div className={cx(cxStyles.currencyEdit, isAdditionalPayment && "pl-3")}>
          <FormFieldCurrencyInput
            disabled={disableActions}
            inputClassName={cx(
              "text-right",
              errorMessage &&
                (inputId === "deductibleAmount" || inputId === "patientAmount") &&
                "border border-red"
            )}
            layout="labelLeft"
            name={inputId}
            onBlur={() => onBlur(inputId)}
            onFocus={() => {
              onFocus(inputId);
            }}
            onValueChange={(val) => {
              onValueChange(inputId, val ?? "0.00");
            }}
            value={value}
          />
        </div>
      </FloatingTooltip>
    </>
  );
};
