import React from "react";

import { cx } from "@libs/utils/cx";
import { useKeyEventCallback } from "@libs/hooks/useKeyEventCallback";
import { ReactComponent as LeftIcon } from "@libs/assets/icons/left-chevron.svg";
import { ReactComponent as RightIcon } from "@libs/assets/icons/right-chevron.svg";
import { ImageCanvas } from "components/PatientProfile/Imaging/ImageEditor/ImageCanvas";
import { useImageEditorContext } from "components/PatientProfile/Imaging/ImageEditor/ImageEditorContext";
import { CloseEditorButton } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/CloseEditorButton";
import { PageDirection } from "components/PatientProfile/Imaging/types/imageEditor";

// eslint-disable-next-line @typescript-eslint/no-magic-numbers
type Props = {
  onClose: Func;
  patientId: number;
  closeOnClickOutside: boolean;
};

const PageButton: React.FC<{
  direction: PageDirection;
  onClick: (direction: PageDirection) => void;
  className: string;
  disabled: boolean;
}> = ({ direction, disabled, onClick, className }) => {
  return (
    <button
      type="button"
      disabled={disabled}
      onClick={() => onClick(direction)}
      className={cx(
        `absolute
         enabled:hover:text-opacity-70
         top-1/2
         -mt-4
         rounded-full
         bg-slate-800
         w-10
         h-10
         flex
         items-center
         justify-center`,
        disabled ? "text-greyLight " : "text-white ",

        className
      )}
    >
      {direction === 1 ? <RightIcon className="w-8 h-8" /> : <LeftIcon className="w-8 h-8" />}
    </button>
  );
};

const NegativeSpace: React.FC<{ onClick?: Func }> = ({ onClick }) => {
  if (!onClick) {
    return <div className="w-16 h-full" />;
  }

  return <button type="button" className="w-16 h-full" onClick={onClick} />;
};

export const MedicalImageEditor: React.FC<Props> = ({ onClose, closeOnClickOutside, patientId }) => {
  const { imageSelected, handlePageChange, hasPrevPage, hasNextPage } = useImageEditorContext();

  useKeyEventCallback("keydown", "Escape", onClose);

  const onKeyUp = React.useCallback(
    (e: KeyboardEvent) => {
      const { key } = e;

      if (["ArrowRight", "ArrowLeft"].includes(key)) {
        e.preventDefault();

        if (key === "ArrowRight" && hasNextPage) {
          handlePageChange(1);
        } else if (key === "ArrowLeft" && hasPrevPage) {
          handlePageChange(-1);
        }
      }
    },
    [hasPrevPage, hasNextPage, handlePageChange]
  );

  React.useEffect(() => {
    document.addEventListener("keyup", onKeyUp);

    return () => {
      document.removeEventListener("keyup", onKeyUp);
    };
  }, [onKeyUp]);

  if (!imageSelected || !imageSelected.h || !imageSelected.w) {
    return null;
  }

  const negativeSpace = <NegativeSpace onClick={closeOnClickOutside ? onClose : undefined} />;

  return (
    <div className="py-1 h-full flex-1 flex min-w-[42rem]">
      {negativeSpace}

      {
        // We pass a key here, because we want to not use 'bounds' of a prior selected image when navigating.  We want to calculate as if we had just opened this editor from scratch
      }
      <ImageCanvas
        patientId={patientId}
        key={imageSelected.id}
        image={imageSelected}
        onClose={closeOnClickOutside ? onClose : undefined}
      >
        <CloseEditorButton onClick={onClose} tooltip="Close Editor" />
        <PageButton direction={-1} onClick={handlePageChange} className="-left-12" disabled={!hasPrevPage} />
        <PageButton direction={1} onClick={handlePageChange} className="-right-12" disabled={!hasNextPage} />
      </ImageCanvas>
      {negativeSpace}
    </div>
  );
};
