import { FC } from "react";
import { OnboardEmployeeRequest } from "@libs/api/generated-api";
import { ValidationResult } from "@libs/hooks/useValidation";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldSelectGender } from "components/UI/FormFieldSelectGender";
import { FormFieldSelectPronoun } from "components/UI/FormFieldSelectPronoun";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { SkeletonInputField } from "components/UI/SkeletonInputField";
import { getOnboardingSchema } from "components/Onboarding/OnboardingWizard";
import { FormFieldUserSsnInput } from "components/UI/FormFieldUserSsnInput";
import { OnChangeHandler } from "./types";

export const PersonalInfoForm: FC<{
  employeeId: number;
  onboardingData?: OnboardEmployeeRequest;
  onChange: OnChangeHandler;
  validationResults: ValidationResult<
    OnboardEmployeeRequest["personalDetails"],
    ReturnType<typeof getOnboardingSchema>["personalDetails"]
  >;
}> = ({ employeeId, onboardingData, onChange, validationResults }) => {
  return (
    <div className="grid grid-cols-2 gap-3">
      {onboardingData ? (
        <>
          <FormFieldInput
            error={validationResults.firstName.$error}
            label="First Name"
            onChange={(e) =>
              onChange((draft) => {
                draft.personalDetails.firstName = e.target.value;
              })
            }
            required
            value={onboardingData.personalDetails.firstName}
          />
          <FormFieldInput
            label="Middle Name"
            onChange={(e) =>
              onChange((draft) => {
                draft.personalDetails.middleName = e.target.value;
              })
            }
            value={onboardingData.personalDetails.middleName}
          />
          <FormFieldInput
            error={validationResults.lastName.$error}
            label="Last Name"
            onChange={(e) =>
              onChange((draft) => {
                draft.personalDetails.lastName = e.target.value;
              })
            }
            required
            value={onboardingData.personalDetails.lastName}
          />
          <FormFieldInput
            label="Preferred Name"
            onChange={(e) =>
              onChange((draft) => {
                draft.personalDetails.preferredName = e.target.value;
              })
            }
            value={onboardingData.personalDetails.preferredName}
          />
          <FormFieldSelectGender
            label="Gender"
            onChange={(option) =>
              onChange((draft) => {
                draft.personalDetails.gender = option?.value;
              })
            }
            value={onboardingData.personalDetails.gender}
          />
          <FormFieldSelectPronoun
            label="Preferred Pronouns"
            onChange={(option) =>
              onChange((draft) => {
                draft.personalDetails.preferredPronouns = option?.value;
              })
            }
            value={onboardingData.personalDetails.preferredPronouns}
          />
          <FormFieldSelectMenusDatepicker
            label="Date of Birth, Age"
            maxDate={new Date()}
            onChange={(date) => {
              onChange((draft) => {
                draft.personalDetails.dob = date ? formatAsISODate(date) : "";
              });
            }}
            selected={
              onboardingData.personalDetails.dob
                ? getLocalDate(onboardingData.personalDetails.dob)
                : undefined
            }
          />
          <FormFieldUserSsnInput
            label="SSN"
            userId={employeeId}
            error={validationResults.ssn.$error}
            onValueChange={(value) =>
              onChange((draft) => {
                draft.personalDetails.ssn = value;
              })
            }
            required
            value={onboardingData.personalDetails.ssn}
            ssnLastFour={onboardingData.personalDetails.ssnLastFour}
          />
        </>
      ) : (
        <>
          <SkeletonInputField />
          <SkeletonInputField />
          <SkeletonInputField />
          <SkeletonInputField />
          <SkeletonInputField />
          <SkeletonInputField />
          <SkeletonInputField />
          <SkeletonInputField />
        </>
      )}
    </div>
  );
};
