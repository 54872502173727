import React from "react";
import { usePromise } from "utils/usePromise";
import { requestCameraStream } from "./camera";

/*
  useCameraStream accepts a deviceId and whether or not the consumer wants to begin streaming the camera feed.
  It returns the state of the request for a camera stream

  It can be called to begin streaming the user's camera feed into an html video element.
*/

export interface UseCameraStream {
  stream?: MediaStream;
  isRequestingStream: boolean;
  requestStreamError: Error | null;
  clearRequestStreamError: Func;
  photoCapabilities?: PhotoCapabilities;
  cancelStream: () => void;
  requestStream: () => void;
}

export function useCameraStream(deviceId: string): UseCameraStream {
  const {
    call: requestStream,
    error: requestStreamError,
    isPending: isRequestingStream,
    result,
    clearError: clearRequestStreamError,
    clearResult: clearStream,
  } = usePromise(requestCameraStream);
  const [hasRequestedCameraStream, setHasRequestedCameraStream] = React.useState(false);

  const shouldRequest = deviceId && hasRequestedCameraStream;

  // On Open:
  React.useEffect(() => {
    if (shouldRequest) {
      requestStream(deviceId);
    }
  }, [requestStream, shouldRequest, deviceId]);

  return React.useMemo(
    () => ({
      stream: result?.stream,
      photoCapabilities: result?.photoCapabilities,
      isRequestingStream,
      requestStreamError,
      clearRequestStreamError,
      cancelStream: () => {
        result?.closeStream();
        clearRequestStreamError();
        clearStream();
      },
      requestStream: () => setHasRequestedCameraStream(true),
    }),
    [result, isRequestingStream, requestStreamError, clearRequestStreamError, clearStream]
  );
}
