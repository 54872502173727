import { FC, useCallback, useMemo, useEffect } from "react";
import { subDays } from "date-fns";
import ParentSize from "@visx/responsive/lib/components/ParentSize";
import { DateWorktimeVO, PayPeriodVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { formatAsISODate, getLocalDate, nowInTimezone } from "@libs/utils/date";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { Panel } from "@libs/components/UI/Panel";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getEmployee, getTimesheetData, getWorktimeOverview } from "api/employee/queries";
import { handleError } from "utils/handleError";
import { approveWorktimeEntries, deleteWorktimeEntry } from "api/employee/mutations";
import { useQueryParams } from "hooks/useQueryParams";
import {
  DEFAULT_RANGE_IN_DAYS,
  TimeSheetDatePicker,
} from "components/EmployeeProfile/Timesheet/TimeSheetDatePicker";
import { ChartPanel } from "components/EmployeeProfile/Timesheet/ChartPanel";
import { TimesheetChartContent } from "components/EmployeeProfile/Timesheet/TimesheetChartContent";
import { usePathParams } from "hooks/usePathParams";
import { TimeSheetTable } from "components/EmployeeProfile/Timesheet/TimeSheetTable";
import { useSelectRows } from "hooks/useSelectRows";
import { getInfinitePayPeriods } from "api/payroll/queries";
import { DateRangeTab } from "utils/routing/employees";
import { practicePaysDoubleOvertime } from "components/Payroll/utils";
import { EmployeeAppHistoryProvider } from "components/Employee/EmployeeLinksContext";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { checkPermission } from "components/Roles/roleUtils";

const isFullDateRange = (val?: [Date | null, Date | null]): val is [Date, Date] => {
  return Boolean(val?.every(isDefined));
};

export const getTimesheetRangeInfo = (
  dateRange: [Date | null, Date | null] | undefined,
  dateRangeTab: DateRangeTab | undefined,
  practiceTimezoneId: string,
  firstPayPeriod: PayPeriodVO | undefined,
  practiceId: number,
  employeeId: number
) => {
  const now = nowInTimezone(practiceTimezoneId);

  // If a payroll exists, and the query tab is payPeriod, or the query tab is
  // undefined and the date range is not set, use the payPeriod tab
  const tab: DateRangeTab =
    firstPayPeriod && (dateRangeTab === "payPeriod" || (dateRangeTab === undefined && !dateRange))
      ? "payPeriod"
      : "calendar";

  //  If the query date range is set, use it.
  //  If the tab is payPeriod and a payroll exists, use the payroll date range.
  // Otherwise, use the default date range.
  const range: [Date, Date] = isFullDateRange(dateRange)
    ? dateRange
    : tab === "payPeriod" && firstPayPeriod
      ? [getLocalDate(firstPayPeriod.startDate), getLocalDate(firstPayPeriod.endDate)]
      : [subDays(now, DEFAULT_RANGE_IN_DAYS), now];

  return {
    dateRangeTab: tab,
    dateRange: range,
    timesheetArgs: {
      practiceId,
      employeeId,
      query: {
        startDate: formatAsISODate(range[0]),
        endDate: formatAsISODate(range[1]),
      },
    },
  };
};

export const getSelectableWorkItems = (worktimes: DateWorktimeVO[] | undefined) =>
  worktimes?.flatMap((item) =>
    item.worktimeDetails
      .filter(
        ({ status, clockIn, clockOut }) => status !== "APPROVED" && Boolean(clockIn.time && clockOut.time)
      )
      .map(({ id }) => id)
  );

export const EmployeeTimesheet: FC = () => {
  const practice = useCurrentPractice();
  const currentUser = useCurrentUser();
  const { id: employeeId } = usePathParams("employeeTimesheet");
  const { query, updateQuery } = useQueryParams("employeeTimesheet");

  const infinitePayPeriodsQuery = useInfiniteApiQuery(
    getInfinitePayPeriods({
      args: {
        practiceId: practice.id,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
      },
    })
  );

  const [employeeQuery] = useApiQueries([
    getEmployee({
      args: { practiceId: practice.id, employeeId },
    }),
  ]);

  const firstPayPeriod = infinitePayPeriodsQuery.data?.pages[0]?.data[0];
  const { dateRange, dateRangeTab, timesheetArgs } = useMemo(
    () =>
      getTimesheetRangeInfo(
        query.dateRange,
        query.dateRangeTab,
        practice.timezoneId,
        firstPayPeriod,
        practice.id,
        employeeId
      ),
    [query.dateRange, firstPayPeriod, practice.timezoneId, query.dateRangeTab, practice.id, employeeId]
  );

  const [timesheetQuery, worktimeQuery] = useApiQueries([
    getTimesheetData({
      args: timesheetArgs,
      queryOptions: {
        enabled: infinitePayPeriodsQuery.isSuccess || infinitePayPeriodsQuery.isError,
      },
    }),
    getWorktimeOverview({
      args: timesheetArgs,
      queryOptions: {
        enabled: infinitePayPeriodsQuery.isSuccess || infinitePayPeriodsQuery.isError,
      },
    }),
  ]);

  const selectableWorktimes = useMemo(
    () => getSelectableWorkItems(timesheetQuery.data),

    [timesheetQuery.data]
  );
  const permissionResults = checkPermission(currentUser.roleV2, "EMPLOYEE_SETTINGS", "ACCESS_ALL");
  const worktimeData = worktimeQuery.data;

  const { selectedRows, resetSelectedRows, handleCheckboxChange, selectAllRows } =
    useSelectRows(selectableWorktimes);

  const [bulkApproveMutation, approveMutation, deleteWorktimeEntryMutation] = useApiMutations([
    approveWorktimeEntries,
    approveWorktimeEntries,
    deleteWorktimeEntry,
  ]);

  const handleApprovals = useCallback(
    (ids: number[]) => {
      bulkApproveMutation.mutate(
        {
          practiceId: practice.id,
          employeeId,
          data: { worktimeIds: ids },
        },
        {
          onSuccess: resetSelectedRows,
          onError: handleError,
        }
      );
    },
    [bulkApproveMutation, employeeId, practice.id, resetSelectedRows]
  );

  const handleApproval = useCallback(
    (id: number) => {
      approveMutation.mutate(
        {
          practiceId: practice.id,
          employeeId,
          data: { worktimeIds: [id] },
        },
        {
          onError: handleError,
        }
      );
    },
    [approveMutation, employeeId, practice.id]
  );

  const handleDeleteWorktimeEntry = useCallback(
    (id: number) => {
      deleteWorktimeEntryMutation.mutate(
        {
          practiceId: practice.id,
          employeeId,
          worktimeId: id,
        },
        {
          onError: handleError,
        }
      );
    },
    [deleteWorktimeEntryMutation, employeeId, practice.id]
  );

  const handleRangeSelected = useCallback(
    (range: [Date, Date], tab: DateRangeTab) => {
      updateQuery("replaceIn", { dateRange: range, dateRangeTab: tab });
    },
    [updateQuery]
  );

  useEffect(() => {
    resetSelectedRows();
  }, [timesheetArgs, resetSelectedRows]);

  return (
    <EmployeeAppHistoryProvider name="timesheet">
      <Panel
        title="Worktime Overview & Timesheet"
        className="h-full"
        titleBarClassName="relative z-30"
        contentClassName="overflow-y-auto"
        actions={
          <QueryResult nonCriticalQueries={[infinitePayPeriodsQuery]}>
            <TimeSheetDatePicker
              dateRange={dateRange}
              dateRangeTab={dateRangeTab}
              onRangeSelected={handleRangeSelected}
              infinitePayPeriodsQuery={infinitePayPeriodsQuery}
            />
          </QueryResult>
        }
      >
        <QueryResult
          queries={[employeeQuery, timesheetQuery, worktimeQuery]}
          nonCriticalQueries={[infinitePayPeriodsQuery]}
        >
          {worktimeData && timesheetQuery.data ? (
            <>
              <ChartPanel
                worktimeOverview={worktimeData}
                hoursInDecimal={query.hoursInDecimal}
                onUpdateHoursFormat={(newHoursInDecimal) =>
                  updateQuery("replaceIn", { hoursInDecimal: newHoursInDecimal })
                }
              />

              <div className="relative">
                <ParentSize>
                  {({ width }: { width: number }) => (
                    <TimesheetChartContent
                      data={worktimeData}
                      hoursInDecimal={query.hoursInDecimal}
                      width={width}
                      height={304}
                    />
                  )}
                </ParentSize>
              </div>

              {selectedRows.size > 0 && permissionResults.isPermitted && (
                <div className="flex justify-end p-3">
                  <AsyncButton
                    isLoading={bulkApproveMutation.isLoading}
                    className="font-sanSemiBold"
                    onClick={() => handleApprovals([...selectedRows])}
                  >
                    Approve Selected
                  </AsyncButton>
                </div>
              )}

              <TimeSheetTable
                showDoubleOvertime={practicePaysDoubleOvertime(practice)}
                timesheet={timesheetQuery.data}
                hoursInDecimal={query.hoursInDecimal}
                employeeId={employeeId}
                onApproveWorktime={handleApproval}
                onDeleteWorktime={handleDeleteWorktimeEntry}
                onSelectAllRows={selectAllRows}
                onDeselectAllRows={resetSelectedRows}
                onToggleEmployee={handleCheckboxChange}
                hasEmployeesPermissions={permissionResults.isPermitted}
                selectedRows={selectedRows}
                selectableRows={selectableWorktimes}
                stickyTopClassName="-top-4"
              />
            </>
          ) : null}
        </QueryResult>
      </Panel>
    </EmployeeAppHistoryProvider>
  );
};
