import React from "react";

import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { CaptureMode } from "components/PatientProfile/Imaging/MountRoute/types";
import { useQueryParams } from "hooks/useQueryParams";
import { HeaderUploadOptions } from "components/PatientProfile/Imaging/MountRoute/MountHeader/HeaderUploadOptions";
import { ModalType } from "./types";

type OnboardProps = {
  mode: CaptureMode;
  handleUploadWithSensor: HeaderUploadOptions["handleUploadWithSensor"];
  onModalToggle: (mode?: ModalType) => void;
  isCapturing: boolean;
};

/*
  When mount is first created, we show this button to capture with the selected device.
*/

export const FirstCapturesButton: React.FC<OnboardProps> = ({
  mode,
  onModalToggle,
  handleUploadWithSensor,
  isCapturing,
}) => {
  const {
    query: { deviceId },
  } = useQueryParams("mountDetails");

  return (
    <AsyncButton
      className="min-w-button"
      disabled={mode === "UNKNOWN" || isCapturing}
      isLoading={isCapturing}
      onClick={() => {
        if (mode === "EXTERNAL") {
          onModalToggle("upload");
        } else if (mode === "PHOTO") {
          onModalToggle("capture");
        } else {
          handleUploadWithSensor({ deviceId: deviceId ?? "" });
        }
      }}
    >
      {mode === "EXTERNAL" ? "Import Images" : "Capture"}
    </AsyncButton>
  );
};
