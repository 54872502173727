import React, { useMemo } from "react";
import { add } from "date-fns";
import { formatAsISODate, nowInTimezone } from "@libs/utils/date";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { TimeSeriesResolutionOption } from "utils/routing/dashboard";
import {
  getDisabledMessageForTimeSeriesParams,
  getDefaultStartFromNow,
  roundDateForResolution,
} from "components/Dashboard/utils/timeSeriesDates";
import { useTimeSegmentForResolution } from "components/Dashboard/hooks/useTimeSegmentForResolution";
import { getSelectedTimePeriodBounds } from "components/Dashboard/Charting/utils";

export const useTimeSeriesPageSelections = ({
  today,
  resolution,
  startDate,
  endDate,
  focusDate,
  includeProjectedDates = false,
}: {
  today?: Date;
  resolution: TimeSeriesResolutionOption;
  startDate?: Date;
  endDate?: Date;
  focusDate?: Date;
  includeProjectedDates?: boolean;
}) => {
  const customDayRange = useMemo(
    () =>
      endDate && startDate
        ? {
            startDate,
            endDate,
          }
        : undefined,
    [endDate, startDate]
  );

  const practice = useCurrentPractice();
  const now = React.useMemo(() => today ?? nowInTimezone(practice.timezoneId), [practice.timezoneId, today]);
  const windowStart = React.useMemo(
    () =>
      formatAsISODate(
        startDate
          ? roundDateForResolution(startDate, resolution)
          : getDefaultStartFromNow(now, includeProjectedDates)
      ),
    [startDate, includeProjectedDates, now, resolution]
  );

  const bounds = useTimeSegmentForResolution({
    windowStart,
    customDayRange,
    resolution,
    includeProjectedDates,
  });
  const selectedTimeSegment = React.useMemo(() => {
    return focusDate
      ? getSelectedTimePeriodBounds(focusDate, resolution, bounds)
      : { startDate: bounds.startDate, endDate: bounds.endDate };
  }, [bounds, focusDate, resolution]);

  return React.useMemo(() => {
    return {
      ...bounds,
      selectedTimeSegment,
      invalidTimeSeriesParamsMessage: getDisabledMessageForTimeSeriesParams({
        resolution,
        ...bounds,
      }),
      // API excludes final day, so for params we add one:
      timeSeriesParams: {
        startDate: formatAsISODate(bounds.startDate),
        endDate: formatAsISODate(add(bounds.endDate, { days: 1 })),
      },
      selectedTimeSegmentParams: {
        startDate: formatAsISODate(selectedTimeSegment.startDate),
        endDate: formatAsISODate(add(selectedTimeSegment.endDate, { days: 1 })),
      },
    };
  }, [bounds, resolution, selectedTimeSegment]);
};

export type TimeSeriesPageSelections = ReturnType<typeof useTimeSeriesPageSelections>;
