import { FC, PropsWithChildren } from "react";
import { useNavigate } from "react-router-dom";
import { useFullUrl } from "@libs/utils/location";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Spinner } from "@libs/components/UI/Spinner";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getLedgerBalanceSummary, getStatementDateRange } from "api/billing/queries";
import { BillingTab, BillingTabs } from "components/PatientProfile/Billing/Ledger/BillingTabs";
import { CollectPaymentButton } from "components/PatientProfile/Billing/Ledger/CollectPaymentButton";
import { paths } from "utils/routing/paths";
import { LedgerMenu } from "./LedgerMenu";

export const LedgerTabsRow: FC<{ patientId: number; practiceId: number; selected: BillingTab }> = ({
  patientId,
  practiceId,
  selected,
}) => {
  const [statementDateRangeQuery, patientBalanceQuery] = useApiQueries([
    getStatementDateRange({ args: { patientId, practiceId } }),
    getLedgerBalanceSummary({ args: { patientId, practiceId } }),
  ]);

  const navigate = useNavigate();
  const fullUrl = useFullUrl();

  return (
    <div className="flex justify-between items-center pr-5">
      <BillingTabs patientId={patientId} selected={selected} />
      <LedgerActions>
        <QueryResult
          queries={[statementDateRangeQuery, patientBalanceQuery]}
          loading={
            <div className="w-full h-full flex">
              <Spinner animation="border" />
            </div>
          }
        >
          <CollectPaymentButton
            onClick={() => {
              navigate(paths.collectPayment({ patientId }, { from: fullUrl, mode: "individual" }));
            }}
          />
          <LedgerMenu patientId={patientId} statementDateRange={statementDateRangeQuery.data} />
        </QueryResult>
      </LedgerActions>
    </div>
  );
};

const LedgerActions: FC<PropsWithChildren> = ({ children }) => {
  return <div className="flex gap-x-6 items-center">{children}</div>;
};
