import { ChangeEventHandler, FC } from "react";
import { CharacterCounter } from "@libs/components/UI/CharacterCounter";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { WALLET_NOTE_MAX_LENGTH } from "components/PatientProfile/Billing/constants";

export const PaymentDescription: FC<{
  value?: string;
  error?: string;
  onChange: ChangeEventHandler<HTMLInputElement>;
}> = ({ value, error, onChange }) => {
  return (
    <div className="flex flex-col max-w-xl">
      <FormFieldInput
        label={<span className="font-sansSemiBold">Description</span>}
        required
        placeholder="Describe the purpose of the payment..."
        value={value}
        onChange={onChange}
        error={error}
      />
      <CharacterCounter
        className="self-end mt-2 text-xs"
        currentCount={(value ?? "").length}
        max={WALLET_NOTE_MAX_LENGTH}
        unit="characters"
      />
    </div>
  );
};
