import { FC, useId, useMemo, useCallback } from "react";
import { useQueryClient } from "@tanstack/react-query";

import { DosespotEntityVO, EmployeeVO } from "@libs/api/generated-api";
import { RadioList } from "@libs/components/UI/RadioList";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useAccount } from "@libs/contexts/AccountContext";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { PAGE_SIZE } from "@libs/utils/constants";

import { Banner } from "@libs/components/UI/Banner";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent, FlyoverFooter } from "components/UI/FlyoverComponents";
import { TooltipLabel } from "components/UI/TooltipLabel";
import { FormFieldSelect } from "components/UI/FormFieldSelect";

import { PrescriberFormWrapper } from "components/Erx/PrescriberFormWrapper";
import {
  getPrescriberCost,
  getUpdateEmployeeRequest,
  updateCacheWithCreatedDoseSpotEntity,
} from "components/Erx/utils";

import { useInfiniteScrollingSelectComponents } from "hooks/useInfiniteScrollingSelectComponents";
import { getEmployee, getInfiniteEmployees } from "api/employee/queries";
import { updateEmployee } from "api/employee/mutations";
import { createClinician } from "api/erx/mutations";
import { handleError } from "utils/handleError";

interface Props {
  prescribers: DosespotEntityVO[];
  canProxy: boolean;
  onClose: Func;
  onClinicianCreated: Func;
}

export const PrescriberFlyover: FC<Props> = ({ prescribers, canProxy, onClose, onClinicianCreated }) => {
  const formId = useId();
  const saving = useBoolean(false);
  const setSaving = saving.set;

  const { practiceId } = useAccount();
  const queryClient = useQueryClient();

  const [{ employeeId, isProxy }, updateSelected] = useObjectState<{
    employeeId: number | undefined;
    isProxy: boolean;
  }>({
    employeeId: undefined,
    isProxy: false,
  });

  const [employeeQuery] = useApiQueries([
    getEmployee({
      args: { practiceId, employeeId: employeeId as number, includeCompensationDetails: true },
      queryOptions: { enabled: Boolean(employeeId) },
    }),
  ]);

  const employeesQuery = useInfiniteApiQuery(
    getInfiniteEmployees({
      args: {
        practiceId,
        statuses: ["ACTIVE"],
        sortColumn: "lastName",
        orderBy: "ASCENDING",
        pageSize: PAGE_SIZE,
        pageNumber: 1,
      },
    })
  );

  const employees = useFlattenPages(employeesQuery.data);

  const employeeSelectComponents = useInfiniteScrollingSelectComponents<SelectOption<number>, false, number>({
    infiniteQuery: employeesQuery,
  });

  const [updateEmployeeMutation, createClinicianMutation] = useApiMutations([
    updateEmployee,
    createClinician,
  ]);
  const updateEmployeeMutateAsync = updateEmployeeMutation.mutateAsync;
  const createClinicianMutateAsync = createClinicianMutation.mutateAsync;

  const { providerOptions, proxyOptions } = useMemo(() => {
    const dentists = [];
    const allEmployees = [];

    if (employees) {
      // Filter out existing prescribers from the list of employees
      const prescriberIds = new Set(prescribers.map(({ id }) => id));
      const nonPrescribingEmployees = employees.filter(({ id }) => !prescriberIds.has(id));

      for (const employee of nonPrescribingEmployees) {
        const { displayName } = employee.personalDetails;

        if (displayName) {
          // Dentist employee is based on display name starting with "Dr." since
          // we do not expose job category (only job title) on EmployeeVO
          if (displayName.startsWith("Dr.")) {
            dentists.push({
              label: displayName,
              value: employee.id,
            });
          }

          allEmployees.push({
            label: displayName,
            value: employee.id,
          });
        }
      }
    }

    return {
      providerOptions: dentists,
      proxyOptions: allEmployees,
    };
  }, [employees, prescribers]);

  const options = useMemo(
    () => (isProxy ? proxyOptions : providerOptions),
    [isProxy, proxyOptions, providerOptions]
  );

  const handleChangeType = useCallback(
    (proxy: boolean) => {
      // When switching type from Proxy (all providers) to Provider (dentist
      // providers), check whether the selected employee is found within the
      // provider options; otherwise, clear the selected provider
      if (!proxy && employeeId && !providerOptions.some(({ value }) => value === employeeId)) {
        updateSelected({ isProxy: proxy, employeeId: undefined });

        return;
      }

      updateSelected({ isProxy: proxy });
    },
    [employeeId, providerOptions, updateSelected]
  );

  const handleSubmit = useCallback(
    async (employee: EmployeeVO, isUpdated: boolean) => {
      setSaving(true);

      try {
        // If there have been changes to the employee, then we need to update
        // them before creating them as a clinician, since the BE references
        // their data for sending to DoseSpot
        if (isUpdated) {
          await updateEmployeeMutateAsync({
            practiceId,
            employeeId: employee.id,
            data: getUpdateEmployeeRequest(employee),
          });
        }

        await createClinicianMutateAsync({
          practiceId,
          employeeId: employee.id,
          data: { createAsProxyPrescriber: isProxy },
        });
        onClinicianCreated();
        updateCacheWithCreatedDoseSpotEntity(queryClient, practiceId, employee, isProxy);

        onClose();
      } catch (error) {
        handleError(error);
      } finally {
        setSaving(false);
      }
    },
    [
      setSaving,
      onClinicianCreated,
      updateEmployeeMutateAsync,
      createClinicianMutateAsync,
      onClose,
      queryClient,
      practiceId,
      isProxy,
    ]
  );

  return (
    <Flyover
      title="Add Prescriber"
      onClose={onClose}
      headerSize="sm"
      size="md"
      dataTestId="prescriber-flyover"
    >
      {({ close }) => (
        <>
          <FlyoverContent paddingClassName="flex flex-col gap-y-6 p-6">
            <Banner className="text-xs rounded" theme="warning">
              Once a provider is added they can only be removed by submitting a support request
            </Banner>

            <div className="grid grid-cols-2 gap-6">
              <RadioList
                label="Type"
                options={[
                  {
                    label: (
                      <TooltipLabel
                        tooltip={{
                          content:
                            "A licensed doctor who is licensed to prescribe medications. The provider must provide valid licensing information which will be verified by DoseSpot before the account is approved.",
                        }}
                      >
                        Provider
                      </TooltipLabel>
                    ),
                    value: false,
                  },
                  {
                    label: (
                      <TooltipLabel
                        tooltip={{
                          content: (
                            <span className="whitespace-pre-line">
                              A staff member who can draft prescriptions on behalf of a provider. The
                              prescriptions will not be sent until they are approved by the prescribing
                              provider.
                              {canProxy
                                ? undefined
                                : "\n\nAt least 1 confirmed provider is required before creating proxy prescribers."}
                            </span>
                          ),
                        }}
                        disabled={!canProxy}
                      >
                        Proxy
                      </TooltipLabel>
                    ),
                    value: true,
                    disabled: !canProxy,
                  },
                ]}
                selectedValue={isProxy}
                onChange={(_e, { value }) => handleChangeType(value)}
              />

              <div className="flex flex-col gap-y-2 text-xs">
                <span className="font-sansSemiBold">Cost</span>
                <TooltipLabel
                  tooltip={{
                    content: `One provider account for DoseSpot is included in your Archy subscription.${
                      canProxy
                        ? " For each additional provider or proxy, you will be charged the following monthly fee."
                        : ""
                    }`,
                  }}
                >
                  {getPrescriberCost({ isProxy, isFirst: prescribers.length === 0 })}
                </TooltipLabel>
              </div>

              <FormFieldSelect
                label="Employee"
                layout="labelOut"
                className="col-span-full"
                placeholder="Select an employee..."
                components={employeeSelectComponents}
                options={options}
                value={employeeId}
                onItemSelected={(value) => updateSelected({ employeeId: value })}
                isClearable={false}
              />

              <div className="col-span-full">
                {employeeId ? (
                  <PrescriberFormWrapper
                    formId={formId}
                    employeeId={employeeId}
                    employeeQuery={employeeQuery}
                    isProxy={isProxy}
                    onSubmit={handleSubmit}
                  />
                ) : null}
              </div>
            </div>
          </FlyoverContent>

          <FlyoverFooter>
            <Button className="min-w-button" onClick={close} theme="secondary">
              Cancel
            </Button>
            <AsyncButton
              className="min-w-button"
              isLoading={saving.isOn}
              inactive={!employeeId}
              form={formId}
              type="submit"
            >
              Save
            </AsyncButton>
          </FlyoverFooter>
        </>
      )}
    </Flyover>
  );
};
