import { useState } from "react";
import { FormTextInputElementRequest } from "@libs/api/generated-api";
import { titleSchema } from "components/Settings/Forms/utils";

export const textInputSchema = {
  title: titleSchema,
};

export const useTextInputState = (
  element: FormTextInputElementRequest | (() => FormTextInputElementRequest)
) => {
  return useState(() => ({ draft: typeof element === "function" ? element() : element }));
};
