import React, { PropsWithChildren } from "react";

export const ScheduleIconPillTabs: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <div
      className={`
        flex
        items-center
        rounded-full
        border
        gap-x-2
        px-3
        py-[7px]
        text-center
        bg-white
        border-greyLighter
      `}
    >
      {children}
    </div>
  );
};
