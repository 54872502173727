import { cx } from "@libs/utils/cx";
import { FormFieldLayout } from "@libs/types/form";
import { FormFieldSelect } from "components/UI/FormFieldSelect";

export type TimeFormat = "clock" | "decimal";

const MENU_OPTIONS: SelectOption<TimeFormat>[] = [
  { value: "clock", label: "Clock" },
  { value: "decimal", label: "Decimal" },
];

export const SelectDecimalVsClock: React.FC<{
  onItemSelected: (value: TimeFormat) => void;
  value: TimeFormat;
  layout?: FormFieldLayout;
  className?: string;
  includeTitle?: boolean;
}> = ({ onItemSelected, value, layout, includeTitle = true, className }) => {
  return (
    <FormFieldSelect
      label={includeTitle ? "Hours Format" : null}
      layout={layout}
      className={cx("w-28", className)}
      isClearable={false}
      isSearchable={false}
      options={MENU_OPTIONS}
      onItemSelected={onItemSelected}
      value={value}
    />
  );
};
