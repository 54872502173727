import { makeQuery } from "@libs/utils/queries";
import { fiveMinuteCache } from "@libs/utils/queryCache";

export const getTags = makeQuery({
  queryKey: ["practices", "getTags"],
  formatParams: ({
    practiceId,
    ...query
  }: {
    practiceId: number;
    typeFilters?: ("ARCHY" | "CUSTOM")[];
    includeArchived?: boolean;
  }) => [practiceId, query],
  queryOptions: fiveMinuteCache,
});

export const getAlertSettings = makeQuery({
  queryKey: ["practices", "getAlertSettings"],
  formatParams: ({ practiceId }: { practiceId: number }) => [practiceId],
  queryOptions: fiveMinuteCache,
});
