import { AppointmentCategoryVO, PatientProcedureVO } from "@libs/api/generated-api";
import {
  TREATMENT_PLAN_CATEGORY_ID,
  useAppointmentCategoriesAndProcedures,
} from "components/ScheduleAppointments/useAppointmentCategoriesAndProcedures";
import { useAppointmentDuration } from "components/ScheduleAppointments/useAppointmentDuration";

export const useAddAppointmentForm = ({
  addToAsapList,
  categories,
  initialDuration,
  patientProcedures,
}: {
  addToAsapList?: boolean;
  categories: AppointmentCategoryVO[];
  initialDuration?: number;
  patientProcedures?: PatientProcedureVO[] | undefined;
}) => {
  const {
    selectedCategoryId,
    selectedCategory,
    procedures,
    color,
    hasCustomColor,
    selectCategory,
    addProcedures,
    removeProcedure,
    switchSavedProcedure,
    switchNewProcedure,
    updateDraft,
    setColor,
  } = useAppointmentCategoriesAndProcedures({
    categories,
    initialCategoryId:
      patientProcedures || addToAsapList || !categories.length
        ? TREATMENT_PLAN_CATEGORY_ID
        : categories[0].id,
    patientProcedures,
  });

  const duration = useAppointmentDuration({
    procedures,
    initialDuration,
    selectedCategory,
    selectedCategoryId,
  });

  return {
    procedures,
    selectedCategoryId,
    selectedCategory,
    color,
    hasCustomColor,
    selectCategory,
    addProcedures,
    updateDraft,
    switchSavedProcedure,
    switchNewProcedure,
    removeProcedure,
    setColor,
    duration,
  };
};
