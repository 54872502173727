import { useNavigate } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { cxGridTableStyles } from "@libs/components/UI/GridTableComponents";
import { cxTableStyles } from "components/Dashboard/Tables/cxTableStyles";

import { CellProps } from "./Cell";

export type LinkCellProps = {
  to: string;
} & CellProps;
export const DashboardLinkCell: React.FC<LinkCellProps> = ({ children, last, align, className, to }) => {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      className={cx("flex", cxTableStyles.cell(last, align), cxGridTableStyles.dataCell, className)}
      onClick={() => {
        navigate(to);
      }}
    >
      {children}
    </button>
  );
};
