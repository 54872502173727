import { ProductionByAppointmentVO } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { Row } from "@libs/components/UI/GridTableComponents";
import { AppointmentDateLinkCell } from "components/Dashboard/PracticeProduction/TableItems/AppointmentDateLinkCell";
import { ProducedVsScheduledCells } from "components/Dashboard/PracticeProduction/TableItems/ProducedVsScheduledCells";
import { EditAppointmentCell } from "components/Dashboard/PracticeProduction/TableItems/EditAppointmentCell";
import { PatientNameLink } from "components/Dashboard/Tables/PatientNameLink";
import { paths } from "utils/routing/paths";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";
import { ProceduresCell } from "components/Dashboard/Tables/ProceduresCell";

type Props = {
  row: ProductionByAppointmentVO;
  last?: boolean;
};
export const ProductionByAppointmentRow: React.FC<Props> = ({ row, last = false }) => {
  const appointmentDetails = {
    appointmentId: row.appointmentId,
    patientId: row.patient.id,
  };

  const cellProps = {
    last,
    to: paths.editAppointment(
      { appointmentId: row.appointmentId, patientId: row.patient.id },
      { from: getFullUrl(location) }
    ),
  };

  return (
    <Row>
      <DashboardLinkCell {...cellProps}>
        <PatientNameLink patient={row.patient} />
      </DashboardLinkCell>
      <AppointmentDateLinkCell {...cellProps} {...appointmentDetails} date={row.productionDate} />
      <DashboardLinkCell {...cellProps}>{row.provider.fullDisplayName}</DashboardLinkCell>

      <ProceduresCell {...cellProps} procedures={row.patientProcedureDisplay} />

      <ProducedVsScheduledCells
        {...cellProps}
        isScheduled={row.isScheduled}
        totalProducedAmount={row.totalProducedAmount}
        totalGrossProducedAmount={row.totalGrossProducedAmount}
        totalGrossScheduledAmount={row.totalGrossScheduledAmount}
      />
      <EditAppointmentCell {...cellProps} {...appointmentDetails} />
    </Row>
  );
};
