import { FamilyMemberVO } from "@libs/api/generated-api";
import { formatFullNameToInitials } from "@libs/utils/formatString";
import { PersonTag } from "components/Shared/PersonTag";
import { getPatientDisplayName } from "utils/names";

interface Props {
  guardians: FamilyMemberVO[];
  isHipaaView: boolean;
}

export const PatientAppointmentGuardians: React.FC<Props> = ({ guardians, isHipaaView }) => {
  return (
    <div className="flex flex-col gap-y-1 text-xs">
      <span className="font-sansSemiBold leading-6">Guardians</span>

      {guardians.map((guardian) => (
        <PersonTag
          displayName={
            isHipaaView
              ? formatFullNameToInitials({ fullName: guardian.name.shortDisplayName })
              : getPatientDisplayName(guardian)
          }
          key={guardian.memberPatientId}
        />
      ))}
    </div>
  );
};
