import React, { FC } from "react";
import { ParentSize } from "@visx/responsive";
import { TimeSeriesResponse } from "@libs/api/generated-api";
import { useFormattedUncollectedAmounts } from "components/Dashboard/OutstandingCollections/hooks/useFormattedUncollectedAmounts";
import { AXIS_LABEL_PROPS, CHART_COLORS, ColorScheme } from "components/Dashboard/Charting/theme";
import { OutstandingCollectionType } from "components/Dashboard/OutstandingCollections/types";
import { useCollectionsChartDateFormatter } from "components/Dashboard/OutstandingCollections/hooks/useCollectionsChartDateFormatter";
import { ChartContainer } from "components/Dashboard/Charting/ChartContainer";
import {
  DashboardStackedBarChart,
  Props as DashboardStackedBarChartProps,
} from "components/Dashboard/Charting/DashboardStackedBarChart";
import { labelForCollectionType } from "components/Dashboard/OutstandingCollections/utils";
import { useCenteredChartMargins } from "components/Dashboard/hooks/useCenteredChartMargins";

type Props = {
  width: number;
  height: number;
  isLoading: boolean;
  dateAgeIndex: number;
  insuranceData?: TimeSeriesResponse;
  invoicedBalanceData?: TimeSeriesResponse;
  unInvoicedBalanceData?: TimeSeriesResponse;
  onToggleBarSelection: ({ dateAgeIndex }: { dateAgeIndex: number }) => void;
};

const outstandingCollectionKeys: OutstandingCollectionType[] = [
  "patientOwedInvoiceNotCreated",
  "insuranceOwedSubmitted",
  "insuranceOwedUnsubmitted",
  "patientOwedInvoiceCreated",
];
const LEGEND_ORDER: OutstandingCollectionType[] = [
  "patientOwedInvoiceCreated",
  "insuranceOwedSubmitted",
  "insuranceOwedUnsubmitted",
  "patientOwedInvoiceNotCreated",
];

const colorScheme: ColorScheme<OutstandingCollectionType> = {
  domain: outstandingCollectionKeys,
  range: [CHART_COLORS.blue, CHART_COLORS.lavender, CHART_COLORS.blueBright, CHART_COLORS.greenBright],
};

export const OutstandingCollectionsChartContent: FC<Props> = ({
  width,
  height,
  isLoading,
  invoicedBalanceData,
  unInvoicedBalanceData,
  insuranceData,
  dateAgeIndex,
  onToggleBarSelection,
}) => {
  const data = useFormattedUncollectedAmounts(
    isLoading ? undefined : insuranceData,
    isLoading ? undefined : invoicedBalanceData,
    isLoading ? undefined : unInvoicedBalanceData
  );

  const formatDate = useCollectionsChartDateFormatter();
  const handleBarClicked = React.useCallback(
    (params: { index: number }) => {
      if (dateAgeIndex === params.index) {
        onToggleBarSelection({ dateAgeIndex: -1 });
      } else {
        onToggleBarSelection({ dateAgeIndex: params.index });
      }
    },
    [dateAgeIndex, onToggleBarSelection]
  );
  const { axisMargin, chartMargin } = useCenteredChartMargins(width);
  const xAxisLabel: DashboardStackedBarChartProps<OutstandingCollectionType>["xAxisLabel"] =
    React.useMemo(() => {
      return {
        text: "Age",
        labelProps: {
          ...AXIS_LABEL_PROPS,
          textAnchor: "end",
          x: 0,
          y: 20,
        },
      };
    }, []);

  return (
    <DashboardStackedBarChart
      data={data}
      randomizeLoadingBars
      chartMargin={chartMargin}
      axisMargin={axisMargin}
      width={width}
      height={height}
      isLoading={isLoading}
      xAxisLabel={xAxisLabel}
      legendOrder={LEGEND_ORDER}
      colorScheme={colorScheme}
      onClickBar={handleBarClicked}
      keys={outstandingCollectionKeys}
      isCurrency
      selectedBarIndex={dateAgeIndex}
      formatDate={formatDate}
      labelFormat={labelForCollectionType}
    />
  );
};

export const OutstandingCollectionsChart: FC<Omit<Props, "width" | "height">> = (props) => {
  return (
    <ChartContainer>
      <ParentSize className="flex-1 relative">
        {({ height, width }: { width: number; height: number }) => {
          return <OutstandingCollectionsChartContent width={width} height={height} {...props} />;
        }}
      </ParentSize>
    </ChartContainer>
  );
};
