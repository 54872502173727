import { format, parseISO } from "date-fns";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { ReactNode } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { AsyncSection } from "@libs/components/UI/AsyncSection";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { getPatientInsuranceQuery } from "api/patientInsurance/queries";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";
import { paths } from "utils/routing/paths";

type TooltipProps = { insuranceId: number; patientId: number; practiceId: number; children?: ReactNode };

const SubscriberTooltipContent: React.FC<TooltipProps> = ({ insuranceId, patientId, practiceId }) => {
  const [{ isLoading, data, isError }] = useApiQueries([
    getPatientInsuranceQuery({
      args: {
        practiceId,
        patientId,
        insuranceId,
        includeBenefitCoverage: false,
        includeInsurancePlan: true,
      },
    }),
  ]);
  const subscriber = data?.patientInsurance.subscriber;
  const groupName = data?.insurancePlan?.groupName;
  const loadingContent = isLoading ? <Skeleton className="w-8" /> : null;

  return (
    <AsyncSection isLoading={false} isLoadingError={isError}>
      <div className="flex flex-col">
        <div>ID: {loadingContent ?? subscriber?.ssnLastFour ?? subscriber?.externalMemberId}</div>
        <div>Group: {loadingContent ?? groupName}</div>
        <div>
          DOB: {loadingContent ?? (subscriber?.dob ? format(parseISO(subscriber.dob), "MM/dd/yyyy") : "-")}
        </div>
      </div>
    </AsyncSection>
  );
};

const SubscriberTooltip: React.FC<TooltipProps> = ({ children, patientId, practiceId, insuranceId }) => {
  return (
    <FloatingTooltip
      content={
        <SubscriberTooltipContent insuranceId={insuranceId} patientId={patientId} practiceId={practiceId} />
      }
      theme="MEDIUM"
    >
      <div>{children}</div>
    </FloatingTooltip>
  );
};

type Props = {
  practiceId: number;
  last?: boolean;
  patientInsuranceId?: number;
  primarySubscriberPatient?: {
    id: number;
    fullDisplayName: string;
  };
  to: string;
};
export const SubscriberCell: React.FC<Props> = ({
  practiceId,
  patientInsuranceId,
  last = false,
  primarySubscriberPatient,
  to,
}) => {
  return (
    <DashboardLinkCell last={last} to={to}>
      {patientInsuranceId && primarySubscriberPatient ? (
        <SubscriberTooltip
          insuranceId={patientInsuranceId}
          patientId={primarySubscriberPatient.id}
          practiceId={practiceId}
        >
          <Link
            className="hover:text-primaryTheme"
            to={paths.patientInsuranceStep({
              insuranceId: patientInsuranceId,
              patientId: primarySubscriberPatient.id,
              step: "carrier",
            })}
          >
            {primarySubscriberPatient.fullDisplayName}
          </Link>
        </SubscriberTooltip>
      ) : (
        EMPTY_CELL
      )}
    </DashboardLinkCell>
  );
};
