import { useCallback } from "react";
import { PatientToothVO } from "@libs/api/generated-api";
import { ToothOptionsList } from "components/Charting/ToothOptionsList";
import { SupernumeraryNonPresentForm } from "components/Charting/SupernumeraryNonPresentForm";
import { DraftPatientProcedureRequest } from "components/Charting/draftPatientProcedure";

interface Props {
  teeth: PatientToothVO[];
  toothSelections: Set<string>;
  onChange: (toothSelections: Set<string>) => void;
}

export const ImageTeethOptions: React.FC<Props> = ({ onChange, toothSelections, teeth }) => {
  const handleUpdates = useCallback(
    (updates: Partial<DraftPatientProcedureRequest>) => {
      if (updates.toothSelections) {
        onChange(updates.toothSelections);
      }
    },
    [onChange]
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex items-center gap-x-8">
        <div className="flex-none">
          <ToothOptionsList
            type="checkbox"
            teeth={teeth}
            canDeselect={false}
            toothSelections={toothSelections}
            onUpdateDraft={handleUpdates}
          />
        </div>
      </div>
      <SupernumeraryNonPresentForm
        type="checkbox"
        title="Supernumerary or other teeth"
        teeth={teeth}
        toothSelections={toothSelections}
        onUpdateDraft={handleUpdates}
      />
    </div>
  );
};
