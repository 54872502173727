import { useCallback, useMemo } from "react";
import { useQueryParams } from "hooks/useQueryParams";
import { OutstandingCollectionsQuery } from "utils/routing/dashboard";
import { paths } from "utils/routing/paths";

export const useTableTabProps = () => {
  const { updateQuery, query } = useQueryParams("dashboardPaymentOutstanding");

  const handleRouteStateChange = useCallback(
    (updates: Partial<OutstandingCollectionsQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );

  return useMemo(() => {
    const queryParams = {
      ...query,
      carrierTableSort: undefined,
      patientTableSort: undefined,
      patientTableSortV2: undefined,
      serviceTableSort: undefined,
      dateAgeIndex: query.dateAgeIndex === -1 ? undefined : query.dateAgeIndex,
      filters: undefined,
    };
    const tabs = [
      {
        text: "Show by Insurance Carrier",
        to: paths.dashboardPaymentOutstanding({ tableTab: "carriers" }, queryParams),
      },
      {
        text: "Show by Service",
        to: paths.dashboardPaymentOutstanding({ tableTab: "service" }, queryParams),
      },
      {
        text: "Show by Patient",
        to: paths.dashboardPaymentOutstanding({ tableTab: "patients" }, queryParams),
      },
    ];

    return {
      onUpdateParams: handleRouteStateChange,
      tabs,
    };
  }, [handleRouteStateChange, query]);
};
