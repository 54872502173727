import "3rd-party/mobiscroll.css";
import { Eventcalendar, MbscCalendarEvent, MbscCellClickEvent, MbscEventClickEvent } from "@mobiscroll/react";
import { isToday } from "date-fns";
import {
  SchedulingConfigVO,
  AppointmentCategoryVO,
  AppointmentVO,
  ScheduleBlockVO,
} from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { DEFAULT_DELAY_IN_MS } from "@libs/components/UI/FloatingTooltip";
import { AppointmentCard } from "components/ScheduleAppointments/AppointmentCard";
import { AppointmentEvent, BlockEvent, HoldEvent } from "components/ScheduleAppointments/createEvent";
import { UseScheduleResult } from "components/ScheduleAppointments/useSchedule";
import { useScheduleScroller } from "components/ScheduleAppointments/useScheduleScroller";
import {
  ClickScheduleEvent,
  CreateScheduleEvent,
  UpdatedScheduleEvent,
} from "components/ScheduleAppointments/types";
import { formatProviderNames } from "components/ScheduleAppointments/utils";
import { AppointmentGrouping } from "utils/routing/scheduling";
import { BlockCard } from "components/ScheduleAppointments/BlockCard";
import { DragToCreateAppointmentCard } from "components/ScheduleAppointments/DragToCreateAppointmentCard";

import { AppointmentMenuProps } from "components/ScheduleAppointments/AppointmentMenu";

interface Props {
  onRequestDeleteBlock: (block: ScheduleBlockVO) => void;
  onRequestEditAppointment: (appointment: AppointmentVO) => void;
  onRequestEditBlock: (block: ScheduleBlockVO) => void;
  onEventClick: (event: ClickScheduleEvent) => void;
  onEventCreate: (event: CreateScheduleEvent) => void;
  onEventUpdated: (event: UpdatedScheduleEvent) => void;
  onCellClick?: (event: MbscCellClickEvent) => void;
  onCellDoubleClick: (event: MbscCellClickEvent) => void;
  onUpdateAppointmentState: (appointment: AppointmentVO, newState: AppointmentVO["state"]) => void;
  onAppointmentDeleted: AppointmentMenuProps["onAppointmentDeleted"];
  queryDate: string;
  hipaaView: boolean;
  renderDate: Date;
  selectedDate: Date;
  groupAppointmentsBy: AppointmentGrouping;
  categories: AppointmentCategoryVO[] | undefined;
  selectedAppointmentId: number;
  config: SchedulingConfigVO;
  schedule: UseScheduleResult;
}

const renderNull = () => null;

const cancelEvent = () => false;

const DELAY_TO_ALLOW_REACHING_TOOLTIP = 100;

type ArchyScheduleEvent =
  | AppointmentEvent
  | BlockEvent
  | HoldEvent
  // This is just here to account for the drag to create even that comes
  // through.
  | (MbscCalendarEvent & { cardType?: never });

export const Schedule: React.FC<Props> = ({
  queryDate,
  renderDate,
  selectedDate,
  groupAppointmentsBy,
  categories,
  selectedAppointmentId,
  config,
  hipaaView,
  schedule,
  onAppointmentDeleted,
  onRequestEditAppointment,
  onRequestDeleteBlock,
  onRequestEditBlock,
  onEventClick,
  onEventCreate,
  onCellClick,
  onCellDoubleClick,
  onUpdateAppointmentState,
  onEventUpdated,
}) => {
  const [eventHoverId, setEventHoverId] = useState<{ id: number; type: "block" | "appointment" } | null>(
    null
  );

  const handleEventMouseEnter = useDebouncedCallback((e: MbscEventClickEvent) => {
    const event = e.event as ArchyScheduleEvent;

    if (event.cardType === "appointment") {
      setEventHoverId({ id: event.appointment.id, type: "appointment" });
    } else if (event.cardType === "block") {
      setEventHoverId({ id: event.block.id, type: "block" });
    } else {
      handleEventMouseOut();
    }
  }, DEFAULT_DELAY_IN_MS);

  // adding a small delay to closing the card tooltip
  // so that a user can move their mouse from a card to
  // the tooltip without the tooltip closing.
  // Once the mouse reaches the tooltip it keeps itself
  // open.
  const clearEventHoverId = useDebouncedCallback(() => {
    setEventHoverId(null);
  }, DELAY_TO_ALLOW_REACHING_TOOLTIP);

  const handleEventMouseOut = () => {
    // if user mouses into and leaves
    // we need to cancel the debounced
    // callback so it doesn't open
    handleEventMouseEnter.cancel();
    clearEventHoverId();
  };

  const scrollResolved = useBoolean(false);

  useScheduleScroller(
    queryDate,
    groupAppointmentsBy,
    scrollResolved.on,
    schedule.hasWorkHours,
    schedule.hasEvents
  );

  const isGroupedByRoom = groupAppointmentsBy === "room";

  return (
    <Eventcalendar
      className={cx(
        "mbsc-practice-schedule",
        isToday(selectedDate) && "today",
        // prevents seeing scrolling jumping around
        scrollResolved.isOff && "opacity-0"
      )}
      theme="ios"
      themeVariant="light"
      colors={schedule.colors}
      showControls={false}
      showEventTooltip={false}
      showToday={false}
      showLabelCount={false}
      dragToMove={true}
      dragToResize={true}
      dragToCreate={true}
      dragTimeStep={schedule.view.schedule?.timeCellStep}
      externalDrop={true}
      view={schedule.view}
      selectedDate={renderDate}
      data={schedule.events}
      resources={groupAppointmentsBy === "provider" ? schedule.selectedProviders : schedule.selectedRooms}
      renderHeader={renderNull}
      renderDay={renderNull}
      renderScheduleEvent={(event) => {
        const scheduleEvent = event.original as ArchyScheduleEvent;

        return scheduleEvent.cardType === "appointment" || scheduleEvent.cardType === "hold" ? (
          <AppointmentCard
            isHovered={
              eventHoverId?.type === "appointment" && eventHoverId.id === scheduleEvent.appointment.id
            }
            appointment={scheduleEvent.appointment}
            categories={categories}
            config={config.appointmentCardConfig}
            groupingContextDetail={
              isGroupedByRoom
                ? formatProviderNames(scheduleEvent.appointment, "shortDisplayName")
                : scheduleEvent.appointment.room.roomName
            }
            hipaaView={hipaaView}
            onAppointmentDeleted={onAppointmentDeleted}
            onRequestEditAppointment={onRequestEditAppointment}
            onRequestUpdateAppointmentState={onUpdateAppointmentState}
            selectedCard={selectedAppointmentId}
          />
        ) : scheduleEvent.cardType === "block" ? (
          <BlockCard
            isHovered={eventHoverId?.type === "block" && eventHoverId.id === scheduleEvent.block.id}
            block={scheduleEvent.block}
            groupingContextDetail={
              isGroupedByRoom
                ? scheduleEvent.block.providers.map((p) => p.name.shortDisplayName).join(", ")
                : scheduleEvent.block.rooms.map((r) => r.roomName).join(", ")
            }
            onRequestDeleteBlock={onRequestDeleteBlock}
            onRequestEditBlock={onRequestEditBlock}
          />
        ) : (
          <DragToCreateAppointmentCard />
        );
      }}
      // handles the case where a user selects an event and
      // hits the delete key. We don't support it
      onEventDelete={cancelEvent}
      onEventHoverIn={handleEventMouseEnter}
      onEventHoverOut={handleEventMouseOut}
      onEventClick={onEventClick}
      onCellClick={onCellClick}
      onCellDoubleClick={onCellDoubleClick}
      onEventUpdated={onEventUpdated}
      onEventCreate={onEventCreate}
    />
  );
};
