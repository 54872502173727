import React from "react";
import { MultiValueProps } from "react-select";
import designConfig from "@libs/design.config";
import { cx } from "@libs/utils/cx";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { mapSelectionsToOptions } from "@libs/utils/mapSelectOptions";
import { createSelectStyles } from "@libs/components/UI/selectStyles";
import { FormFieldMultiSelect } from "components/UI/FormFieldMultiSelect";

const getMultiValueComponent = () => {
  return function MultiValueComponent<
    V extends SelectOptionValue,
    T extends SelectOption<V>,
    MultiSelect extends boolean,
  >(props: MultiValueProps<T, MultiSelect, GroupedSelectOption<V, T>>) {
    if (props.index > 0) {
      return null;
    }

    return (
      <div
        className={`
          overflow-hidden
          whitespace-nowrap
          text-ellipsis
          max-width-[99%]
          pointer-events-none
        `}
      >
        {props
          .getValue()
          .map((item) => item.label)
          .join(", ")}
      </div>
    );
  };
};

const CheckboxRow: React.FC<{
  label: React.ReactNode;
  name: string;
  checked: boolean;
  disabled?: boolean;
  id: string;
}> = ({ label, name, checked, id, disabled }) => {
  return (
    <div className={cx("flex flex-row space-x-1 pointer-events-none", disabled && "opacity-50")}>
      <Checkbox readOnly name={name} id={id} checked={checked} />
      <label htmlFor={id}>{label}</label>
    </div>
  );
};

const customComponents = {
  MultiValue: getMultiValueComponent(),
};

const customStyles = createSelectStyles<number, SelectOption<number>, true>({
  valueContainer: () => ({
    padding: 0,
    flexWrap: "nowrap",
  }),
  multiValue: () => ({
    minWidth: "60%",
  }),
  option: (_, { isFocused }) => ({
    backgroundColor: isFocused ? designConfig.colors.greyLightest : "white",
  }),
});

export type FormFieldSelectProvidersProps = {
  savedProviders?: { id: number; name: { fullDisplayName: string } }[];
  selectedProviderIds?: number[];
  onChange: (providerIds: number[]) => void;
  label?: string;
  error?: string;
  required?: boolean;
  providers: { id: number; name: { fullDisplayName: string } }[];
  className?: string;
};

export const FormFieldSelectProviders: React.FC<FormFieldSelectProvidersProps> = ({
  selectedProviderIds,
  providers,
  savedProviders,
  onChange,
  label,
  required,
  error,
  className,
}) => {
  const selectedProviderSet = React.useMemo(() => new Set(selectedProviderIds), [selectedProviderIds]);

  const options = React.useMemo(
    () =>
      mapSelectionsToOptions(providers, savedProviders, (provider) => ({
        label: provider.name.fullDisplayName,
        value: provider.id,
      })),
    [providers, savedProviders]
  );

  return (
    <FormFieldMultiSelect
      id="select-providers"
      className={className}
      options={options}
      label={label}
      required={required}
      layout="labelIn"
      isSearchable={false}
      hideSelectedOptions={false}
      closeMenuOnSelect={false}
      error={error}
      styles={customStyles}
      placeholder="Select providers..."
      components={customComponents}
      value={selectedProviderIds}
      onChange={(selectedOptions) => onChange(selectedOptions.map((item) => item.value))}
      formatOptionLabel={({ label: optionLabel, value, isDisabled }) => {
        return (
          <CheckboxRow
            name={optionLabel as string}
            label={optionLabel}
            id={`${value}`}
            checked={selectedProviderSet.has(value)}
            disabled={isDisabled}
          />
        );
      }}
    />
  );
};
