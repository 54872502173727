import { FC } from "react";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as RightArrow } from "@libs/assets/icons/right-arrow.svg";

interface Props {
  onClick: Func;
}

export const RecallBanner: FC<Props> = ({ onClick }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={`
        flex
        items-center
        justify-between
        w-full
        px-4
        py-3
        bg-actionLight
        border-b
        border-b-greyLighter
      `}
    >
      <span className="font-sansSemiBold text-xs text-primaryTheme">Recall procedures may be due.</span>
      <FloatingTooltip content="View Recall Info" theme="SMALL">
        <RightArrow className="w-5 h-5 text-primaryTheme" />
      </FloatingTooltip>
    </button>
  );
};
