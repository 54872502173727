import { useState } from "react";
import { MedicalImageVO } from "@libs/api/generated-api";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ActionModal } from "components/UI/ActionModal";
import { updateMedicalImageDetails } from "api/imaging/mutations";
import { handleError } from "utils/handleError";
import { ImageTeethOptions } from "components/PatientProfile/Imaging/MountRoute/TeethLabeling/ImageTeethOptions";
import { getPatientChartV2 } from "api/charting/queries";

type Props = {
  image: MedicalImageVO;
  onClose: Func;
  patientId: number;
  mountId: number;
};

export const ImageLabelTeethModal: React.FC<Props> = ({ onClose, mountId, image, patientId }) => {
  const { practiceId } = useAccount();
  const [{ mutate: mutateDetails, isLoading: isSaving }] = useApiMutations([updateMedicalImageDetails]);
  const [chartV2Query] = useApiQueries([
    getPatientChartV2({
      args: { patientId, practiceId },
    }),
  ]);
  const [toothSelections, setToothSelections] = useState<Set<string>>(new Set(image.teeth));

  return (
    <ActionModal
      handleSave={() => {
        if (!image.id) {
          return;
        }

        mutateDetails(
          {
            mountId,
            practiceId,
            imageId: image.id,
            patientId,
            data: {
              teeth: toothSelections.size > 0 ? [...toothSelections] : [],
            },
          },
          {
            onSuccess: onClose,
            onError: handleError,
          }
        );
      }}
      isLoading={isSaving}
      title="Edit Teeth Label"
      handleClose={onClose}
      primaryButtonText="Save"
    >
      <QueryResult isDark queries={[chartV2Query]}>
        <div className="flex items-center justify-center flex-col">
          {chartV2Query.data && (
            <ImageTeethOptions
              teeth={chartV2Query.data}
              toothSelections={toothSelections}
              onChange={setToothSelections}
            />
          )}
        </div>
      </QueryResult>
    </ActionModal>
  );
};
