import { PatientType } from "components/Dashboard/types";

export const labelForPatientType = (patientType: PatientType) => {
  switch (patientType) {
    case "EXISTING": {
      return "Existing Patients";
    }
    case "NEW": {
      return "New Patients";
    }
    default: {
      return "";
    }
  }
};
