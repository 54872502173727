import React, { useMemo } from "react";
import { PaymentVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { HeaderCell, Row, TableGrid, TextCell } from "@libs/components/UI/GridTableComponents";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import { getPaymentMethodToLabel } from "components/PatientProfile/Billing/billingUtils";

interface Props {
  invoiceAmount: number;
  payments: PaymentVO[];
}

export const PaymentHistory: React.FC<Props> = ({ invoiceAmount, payments }) => {
  let paidSoFar = 0;
  const columnWidths = useMemo(
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    () => Array.from({ length: 4 }, () => "auto"),
    []
  );

  return (
    <div>
      <div className="text-sm font-sansSemiBold">Payment History</div>
      <TableGrid columnWidths={columnWidths}>
        <Row>
          <HeaderCell size="short">Date</HeaderCell>
          <HeaderCell size="short">Payment Method</HeaderCell>
          <HeaderCell size="short" className="justify-end">
            Amount
          </HeaderCell>
          <HeaderCell size="short" className="justify-end">
            Remaining
          </HeaderCell>
        </Row>

        {payments.map((payment) => {
          paidSoFar += payment.currencyAmount.amount;

          return (
            <Row key={payment.uuid} highlightOnHover={false}>
              <TextCell>
                <DateWithTooltip date={payment.paymentCreatedAt} dateAsSeconds={true} format="P" />
              </TextCell>
              <TextCell>{getPaymentMethodToLabel(payment)}</TextCell>
              <TextCell className="text-right">{formatCurrency(payment.currencyAmount.amount)}</TextCell>

              <TextCell className="text-right">{formatCurrency(invoiceAmount - paidSoFar)}</TextCell>
            </Row>
          );
        })}
      </TableGrid>
    </div>
  );
};
