import React, { useMemo } from "react";
import { ClaimSummaryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { FloatingTooltipProps } from "@libs/components/UI/FloatingTooltip";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as ProcessEobIcon } from "@libs/assets/icons/process-eob.svg";
import { ReactComponent as ViewIcon } from "@libs/assets/icons/preview-file.svg";
import { ReactComponent as SubmitIcon } from "@libs/assets/icons/send-file.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { Cell } from "@libs/components/UI/GridTableComponents";
import {
  canProcessAdditionalPaymentForClaim,
  isDraftClaim,
  isInDraftEobPaymentClaim,
  isSubmittedClaim,
  isSubmittingClaim,
} from "components/Claims/utils";
import { useCreateDraftEobPayment } from "components/Claims/Shared/useCreateDraftEobPayment";
import { ATTACHMENTS_ARE_REQUIRED_TOOLTIP } from "components/Claims/Claims/Table";

interface Props {
  cellClassName: string;
  claim: ClaimSummaryVO;
  onClaimOpen: (claim: ClaimSummaryVO) => void;
  onDeleteClick: () => void;
  onSubmit: () => void;
}

export const PROCESSING_EOB_TOOLTIP = "Currently being processed in an EOB.";

// eslint-disable-next-line complexity
export const ClaimActionsCell: React.FC<Props> = ({
  cellClassName,
  claim,
  onClaimOpen,
  onDeleteClick,
  onSubmit,
}) => {
  const { handleCreateDraftEobPayment } = useCreateDraftEobPayment();
  const isDraft = isDraftClaim(claim);
  const isSubmitted = isSubmittedClaim(claim);
  const isInDraftEobPayment = isInDraftEobPaymentClaim(claim);

  const submitDisabledTooltip = useMemo(() => {
    if (claim.needsAttachment) {
      return ATTACHMENTS_ARE_REQUIRED_TOOLTIP;
    } else if (claim.isValidating) {
      return "Validating...";
    } else if (isSubmittingClaim(claim)) {
      return "Submitting...";
    } else if (!isDraft) {
      return "Already sent";
    }

    return "";
  }, [claim, isDraft]);

  const submitTooltip: Omit<FloatingTooltipProps, "children"> = useMemo(() => {
    if (submitDisabledTooltip) {
      return { content: submitDisabledTooltip };
    }

    return { content: "Submit", theme: "SMALL" };
  }, [submitDisabledTooltip]);

  const canProcessEob = isSubmitted && !claim.replacementClaimUuid && !isInDraftEobPayment;
  const canProcessAdditionalPayment = canProcessAdditionalPaymentForClaim(claim);

  const disabledMessage = claim.replacementClaimUuid
    ? "Claims with replacements cannot be processed in an EOB"
    : isInDraftEobPayment
      ? PROCESSING_EOB_TOOLTIP
      : "Only sent or completed claims can be processed in an EOB";

  return (
    <Cell className={cx("justify-center gap-x-4", cellClassName)}>
      <ButtonIcon
        SvgIcon={ViewIcon}
        onClick={() => onClaimOpen(claim)}
        size="md"
        theme="primary"
        tooltip={{ content: "View", theme: "SMALL" }}
      />
      <ButtonIcon
        disabled={Boolean(submitDisabledTooltip)}
        SvgIcon={SubmitIcon}
        onClick={onSubmit}
        size="md"
        theme="primary"
        tooltip={submitTooltip}
      />
      {!claim.isPreAuth && (
        <ButtonIcon
          disabled={!(canProcessEob || canProcessAdditionalPayment)}
          SvgIcon={ProcessEobIcon}
          onClick={() => handleCreateDraftEobPayment(claim.insuranceCarrierId, [claim.uuid])}
          size="md"
          theme="primary"
          tooltip={
            canProcessEob
              ? { content: "Process EOB", theme: "SMALL" }
              : canProcessAdditionalPayment
                ? { content: "Additional Payment", theme: "SMALL" }
                : {
                    content: disabledMessage,
                    theme: "MEDIUM",
                  }
          }
        />
      )}
      <ButtonIcon
        disabled={!isDraft}
        SvgIcon={DeleteIcon}
        onClick={onDeleteClick}
        size="md"
        theme="primary"
        tooltip={
          isDraft
            ? { content: "Delete", theme: "SMALL" }
            : {
                content: `Only draft ${claim.isPreAuth ? "pre-auth " : ""}claims can be removed`,
                theme: "MEDIUM",
              }
        }
      />
    </Cell>
  );
};
