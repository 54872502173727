import { FC } from "react";
import EmptyEditor from "assets/images/empty-form-editor-state.svg";

export const EmptyFormContent: FC = () => {
  return (
    <div
      className={`
        flex
        flex-col
        h-full
        justify-center
        items-center
        p-10
        overflow-y-auto
        gap-y-6
      `}
    >
      <img src={EmptyEditor} alt="Question Editor" className="w-3/4" />
      <p className="text-sm">Tip: You can drag elements in the editor to reorder your form</p>
    </div>
  );
};
