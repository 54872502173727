import { FC, ReactNode, useMemo } from "react";
import { FormSubmissionVO } from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { FormStructure } from "components/PatientProfile/Forms/FormStructure";
import { filterForm } from "components/PatientProfile/Forms/utils";

interface Props {
  displayDivider?: boolean;
  formSubmission: FormSubmissionVO;
  noResponses?: ReactNode;
}

export const ViewResponses: FC<Props> = ({ displayDivider = true, formSubmission, noResponses }) => {
  const responses: Partial<typeof formSubmission.responses> = formSubmission.responses;
  const filteredForm = useMemo(() => {
    return filterForm(formSubmission.form, (element) => {
      if (isOneOf(element.type, ["TEXT_BLOCK", "CONSENT"])) {
        return false;
      }

      if (element.type === "SECTION") {
        return true;
      }

      return Boolean(responses[element.uuid]);
    });
  }, [formSubmission.form, responses]);

  const hasResponses = filteredForm.content.length > 0;

  return hasResponses ? (
    <FormStructure displayDivider={displayDivider} form={filteredForm} responses={responses} />
  ) : (
    // eslint-disable-next-line react/jsx-no-useless-fragment
    <>{noResponses}</>
  );
};
