import { FC, useId } from "react";
import { addMonths, startOfToday } from "date-fns";
import { SIX_MONTHS, THREE_MONTHS } from "@libs/utils/date";
import { Button } from "@libs/components/UI/Button";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { SlotPreferences } from "components/ScheduleAppointments/types";
import { EditPreferencesButton } from "components/ScheduleAppointments/EditPreferencesButton";

export interface AppointmentSlotFinderSearchProps {
  fromDate: Date;
  isEditing: boolean;
  onUpdateSlotPreferences: (updates: Partial<SlotPreferences>) => void;
  onToggleEdit: Func;
}

export const AppointmentSlotFinderSearch: FC<AppointmentSlotFinderSearchProps> = ({
  fromDate,
  isEditing,
  onUpdateSlotPreferences,
  onToggleEdit,
}) => {
  const id = useId();

  return (
    <div className="flex items-center">
      <label className="text-xs font-sansSemiBold block mr-2" htmlFor={id}>
        Show from
      </label>
      <FormFieldSelectMenusDatepicker
        selected={fromDate}
        id={id}
        layout="labelOut"
        onChange={(date) => {
          date && onUpdateSlotPreferences({ fromDate: date });
        }}
        dayClassName={(data) => {
          const today = new Date();
          const yesterday = new Date(today);

          yesterday.setDate(today.getDate() - 1);

          if (yesterday > data) {
            return "grayColor";
          }

          return "";
        }}
        minDate={new Date()}
      />
      <Button
        theme="link"
        className="text-xs ml-3"
        onClick={() => onUpdateSlotPreferences({ fromDate: startOfToday() })}
      >
        Today
      </Button>
      <div className="h-4 w-px mx-3 bg-greyLighter" />
      <Button
        theme="link"
        className="text-xs"
        onClick={() => onUpdateSlotPreferences({ fromDate: addMonths(fromDate, THREE_MONTHS) })}
      >
        +3m
      </Button>
      <div className="h-4 w-px mx-3 bg-greyLighter" />
      <Button
        theme="link"
        className="text-xs"
        onClick={() => onUpdateSlotPreferences({ fromDate: addMonths(fromDate, SIX_MONTHS) })}
      >
        +6m
      </Button>

      <EditPreferencesButton isEditing={isEditing} onToggleEdit={onToggleEdit} />
    </div>
  );
};
