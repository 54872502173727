import { InsuranceCarrierVO } from "@libs/api/generated-api";
import { formatPhoneNumber } from "@libs/utils/phone";
import { SectionHeader } from "components/Dashboard/Tables/SectionHeader";

type Props = {
  carrier: InsuranceCarrierVO;
};

export const CarrierHeaderRow: React.FC<Props> = ({ carrier }) => {
  return (
    <SectionHeader
      className={`
        flex
        items-center
        gap-4
        col-span-full
        font-sansSemiBold
        text-xs
      `}
    >
      <div>{carrier.name}</div>
      {carrier.phone && <div>{formatPhoneNumber(carrier.phone)}</div>}
    </SectionHeader>
  );
};
