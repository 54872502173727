import React, { FC, PropsWithChildren } from "react";
import { cx } from "@libs/utils/cx";
import { ReactComponent as BackIcon } from "@libs/assets/icons/left-arrow.svg";
import { ReactComponent as ArchyLogo } from "@libs/assets/components/logo-archy-with-name.svg";
import { AppVersion } from "components/Main/AppVersion";

export const PageWrapperCard: FC<PropsWithChildren<{ title?: string; size?: "sm" | "md" | "lg" }>> = ({
  size = "md",
  title,
  children,
}) => {
  return (
    <div
      className={cx(
        `flex
         flex-col
         py-4
         px-6
         justify-center
         w-full
         bg-white
         border
         border-slate-200
         rounded-md`,
        size === "sm" && "max-w-sm",
        size === "md" && "max-w-md",
        size === "lg" && "max-w-lg"
      )}
    >
      {title ? <div className="mb-4 font-sansSemiBold text-lg text-center">{title}</div> : null}
      {children}
    </div>
  );
};

export const PageWrapper: React.FC<
  PropsWithChildren<{ handleGoBack?: () => void; theme?: "dark" | "light" }>
> = ({ children, handleGoBack, theme = "dark" }) => {
  return (
    <div className="flex h-screen overflow-y-auto px-8 relative z-0">
      {handleGoBack && (
        <div className="relative">
          <button type="button" className="absolute top-7 left-7 mr-6" onClick={handleGoBack}>
            <BackIcon className="h-7 w-7 fill-primaryTheme" />
          </button>
        </div>
      )}
      <div className="flex flex-col items-center gap-6 pt-6 w-full h-full">
        <div className="h-10">
          <ArchyLogo
            className={cx("w-auto h-full", theme === "dark" ? "text-navy-500" : "text-archyBlue-500")}
          />
        </div>
        <div className="flex-1">{children}</div>
        <div className="w-full py-4 text-center text-xs text-greyLight">
          <AppVersion />
        </div>
      </div>
    </div>
  );
};
