import { InvoiceVO } from "@libs/api/generated-api";

interface Props {
  invoice: InvoiceVO;
}

export const InvoiceNote: React.FC<Props> = ({ invoice }) => {
  return (
    <div>
      <div className="text-sm font-sansSemiBold">Notes</div>
      <div className="text-xs">{invoice.note || <i>Not available</i>}</div>
    </div>
  );
};
