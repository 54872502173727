import { FC, HTMLProps, PropsWithChildren } from "react";
import { cx } from "@libs/utils/cx";

export const PaymentCardButton: FC<
  PropsWithChildren<
    HTMLProps<HTMLButtonElement> & {
      disabled?: boolean;
    }
  >
> = ({ disabled, onClick, children }) => {
  return (
    <button
      type="button"
      onClick={onClick}
      className={cx(!disabled && "hover:bg-slate-100")}
      disabled={disabled}
    >
      {children}
    </button>
  );
};
