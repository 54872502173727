import { useRef } from "react";

/**
 * A hook that returns a ref and a focus handler for an input element. Useful
 * for focusing an input element after a transition ends when autoFocus won't
 * work (causes layout issues).
 *
 * @template T - An element that can be focused.
 * @returns An object containing a ref and a focus handler.
 */
export const useFocusOnce = <T extends HTMLOrSVGElement>() => {
  const focusInitialized = useRef(false);
  const ref = useRef<T>(null);

  /**
   * A function that focuses the input element if it has not already been focused.
   *
   * @returns void
   */
  const handleFocus = (force?: boolean) => {
    if (!focusInitialized.current || force) {
      ref.current?.focus();
      focusInitialized.current = true;
    }
  };

  return { ref, handleFocus };
};
