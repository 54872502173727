import { ApiQueryResult, UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { MountVO, PatientSummaryVO } from "@libs/api/generated-api";
import { noop } from "@libs/utils/noop";

import { QueryResult } from "@libs/components/UI/QueryResult";
import { Backdrop } from "@libs/components/UI/Backdrop";
import { DarkRoomHeader } from "components/PatientProfile/Imaging/DarkRoomHeader";
import { DarkRoomSkeleton } from "components/PatientProfile/Imaging/MountRoute/DarkRoomSkeleton";
import { SelectPatientMount } from "components/PatientProfile/Imaging/MountRoute/SelectPatientMount";

type Props = {
  patientSummaryQuery: ApiQueryResult<PatientSummaryVO>;
  mountsInfiniteQuery: UseInfiniteApiQueryResult<MountVO[]>;
  mountQuery: ApiQueryResult<MountVO>;
};
export const MountMiniHeader: React.FC<Props> = ({
  patientSummaryQuery,
  mountQuery,
  mountsInfiniteQuery,
}) => {
  return (
    <DarkRoomHeader className="relative text-white">
      <div className="flex gap-4">
        <QueryResult
          queries={[patientSummaryQuery, mountQuery]}
          loading={<DarkRoomSkeleton className="w-40" />}
          loadError={<>Unknown Patient</>}
        >
          <div className="flex items-center font-sansSemiBold">
            <div className="truncate max-w-[280px] font-sansSemiBold" data-testid="header-patient-name">
              {patientSummaryQuery.data?.name.shortDisplayName}
            </div>
          </div>
          {mountQuery.data && (
            <SelectPatientMount
              isDark
              mountsInfiniteQuery={mountsInfiniteQuery}
              selectedMount={mountQuery.data}
              className="w-72"
              onChange={noop}
            />
          )}
        </QueryResult>
      </div>
      <Backdrop bgColorClassName="bg-slate-700/50" />
    </DarkRoomHeader>
  );
};
