import { useNavigate } from "react-router-dom";
import React, { useCallback, useContext, useMemo } from "react";
import { ClaimVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { getFullUrl } from "@libs/utils/location";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as PrintIcon } from "@libs/assets/icons/send-to-printer.svg";
import { ReactComponent as DownloadIcon } from "@libs/assets/icons/download.svg";
import { ReactComponent as ViewIcon } from "@libs/assets/icons/preview-file.svg";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { useAccount } from "@libs/contexts/AccountContext";
import { createMenuOptions, MenuOptions } from "@libs/components/UI/MenuOptions";
import { Badge } from "@libs/components/UI/Badge";
import {
  colorsByClaimState,
  isCompletedClaim,
  isDraftClaim,
  isInDraftEobPaymentClaim,
  paymentStatusToPillText,
  preAuthColorsByClaimState,
  preAuthStateToPillText,
  stateToPillText,
  themesByClaimInsuranceStatus,
} from "components/Claims/utils";
import { ClaimModalHeader } from "components/Claim/ModalHeader";
import { paths } from "utils/routing/paths";
import { downloadFile } from "utils/files";
import { handleError } from "utils/handleError";
import { ClaimLink } from "components/Claim/ClaimLink";
import { ClaimError } from "components/Claims/Claims/ClaimError";
import { PrintVersion } from "components/Claims/types";

interface Props {
  claim: ClaimVO;
  from: string;
  onPrint: (version: PrintVersion) => void;
  onNavigateBack: () => void;
}

// eslint-disable-next-line complexity
export const ClaimDetailsHeader: React.FC<Props> = ({ claim, from, onPrint, onNavigateBack }) => {
  const { fetchBlob } = useContext(ApiClientContext);
  const { practiceId } = useAccount();
  const navigate = useNavigate();
  const fullUrl = getFullUrl(location);

  const navigateToEob = useCallback(() => {
    return navigate(paths.eob({ draftEobPaymentUuid: claim.draftEobPaymentUuid }, { from: fullUrl }));
  }, [claim.draftEobPaymentUuid, fullUrl, navigate]);

  const isInDraftEobPayment = isInDraftEobPaymentClaim(claim);
  const isCompleted = isCompletedClaim(claim);
  const isDraft = isDraftClaim(claim);

  const hasClaimError = Boolean(claim.validationError ?? claim.submissionError);

  const printMenu = useBoolean(false);
  const menuOptions = useMemo(
    () =>
      createMenuOptions(
        {
          label: "Print with 2019 claim form",
          value: PrintVersion.TWENTY_NINETEEN,
        },
        {
          label: "Print with 2024 claim form",
          value: PrintVersion.TWENTY_TWENTY_FOUR,
        }
      ),
    []
  );

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      onPrint(option.value);
      printMenu.off();
    },
    [onPrint, printMenu]
  );

  return (
    <ClaimModalHeader
      includeDivider={true}
      onClose={onNavigateBack}
      data-testid="details-claim-header"
      title={claim.isPreAuth ? `Pre-Auth#${claim.claimNumber}` : `Claim#${claim.claimNumber}`}
      titleDetails={[
        formatISODate(claim.serviceDate),
        claim.patientName.shortDisplayName,
        sentenceCaseConstant(claim.insuranceOrdinal),
        claim.insuranceCarrier,
      ]}
    >
      <div className="flex items-center justify-between">
        <div className="flex items-center">
          {claim.isPreAuth ? (
            <Badge
              className="text-sm"
              color={preAuthColorsByClaimState[claim.state]}
              label={preAuthStateToPillText[claim.state]}
            />
          ) : isInDraftEobPayment ? (
            <div className="flex items-center gap-x-2.5">
              <Badge
                className="text-sm"
                color={colorsByClaimState["EOB_IN_PROGRESS"]}
                label={stateToPillText["EOB_IN_PROGRESS"]}
              />
              <ButtonIcon
                SvgIcon={ViewIcon}
                onClick={navigateToEob}
                size="md"
                theme="primary"
                tooltip={{ content: "Open EOB", theme: "SMALL" }}
              />
            </div>
          ) : isCompleted ? (
            <Badge
              className="text-sm"
              color={themesByClaimInsuranceStatus[claim.insurancePaymentStatus]}
              label={paymentStatusToPillText[claim.insurancePaymentStatus]}
            />
          ) : (
            <Badge
              className="text-sm"
              color={colorsByClaimState[claim.state]}
              label={stateToPillText[claim.state]}
            />
          )}
          {hasClaimError && (
            <div className="ml-5">
              <ClaimError
                claimUuid={claim.uuid}
                isDraftClaim={isDraft}
                isValidating={claim.isValidating}
                validationError={claim.validationError}
                submissionError={claim.submissionError}
              />
            </div>
          )}
          <ClaimLink claim={claim} from={from} className="mx-5" />
        </div>
        <div className="flex items-center gap-x-6">
          <ButtonMenu
            isOpen={printMenu.isOn}
            menuContent={
              <div className="text-xs">
                <MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />
              </div>
            }
            onRequestClose={printMenu.off}
            onRequestOpen={printMenu.on}
            placement="bottom-end"
          >
            {(props) => (
              <ButtonIcon
                {...props}
                SvgIcon={PrintIcon}
                size="sm"
                theme="primary"
                tooltip={{ content: "Print", theme: "SMALL" }}
              />
            )}
          </ButtonMenu>

          <ButtonIcon
            onClick={async () => {
              try {
                const blob = await fetchBlob(`/practices/${practiceId}/claims/${claim.uuid}/print`);

                downloadFile(blob, `Claim${claim.claimNumber}.pdf`);
              } catch (e) {
                handleError(e);
              }
            }}
            SvgIcon={DownloadIcon}
            size="sm"
            theme="primary"
            tooltip={{ content: "Download", theme: "SMALL" }}
          />
        </div>
      </div>
    </ClaimModalHeader>
  );
};
