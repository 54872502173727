import { BatchPrepareFileUploadRequest, PrepareFileUploadRequest } from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";

export const prepareFileUpload = makeMutation({
  mutationKey: ["practices", "prepareFileUpload"],
  formatParams: (args: { practiceId: number; data: PrepareFileUploadRequest }) => [
    args.practiceId,
    args.data,
  ],
});

export const batchPrepareFileUpload = makeMutation({
  mutationKey: ["practices", "batchPrepareFileUpload"],
  formatParams: (args: { practiceId: number; data: BatchPrepareFileUploadRequest }) => [
    args.practiceId,
    args.data,
  ],
});
