import { FC, useMemo } from "react";

import { useAccount } from "@libs/contexts/AccountContext";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { flattenPages } from "@libs/utils/queries";
import { PAGE_SIZE } from "@libs/utils/constants";

import { QueryResult } from "@libs/components/UI/QueryResult";
import { SetupTwilioPractice } from "components/Settings/Communications/MessagingService/SetupTwilioPractice";

import { getTwilioRegistrationStatus } from "api/communications/queries";
import { getPracticeDataQuery } from "api/practice/queries";
import { getInfiniteEmployees } from "api/employee/queries";

import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";

export const MessagingServicesSetupTwilioPracticeRoute: FC = () => {
  const { practiceId } = useAccount();
  const { query } = useQueryParams("setupTwilio");
  const from = query.from ?? paths.settingsSection({ section: "messaging-services" });

  const [twilioRegistrationStatusQuery, practiceQuery] = useApiQueries([
    getTwilioRegistrationStatus({ args: { practiceId } }),
    getPracticeDataQuery({ args: { practiceId } }),
  ]);

  const employeesQuery = useInfiniteApiQuery(
    getInfiniteEmployees({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        statuses: ["PENDING", "ACTIVE"],
        isOwner: true,
      },
    })
  );

  const owner = useMemo(() => {
    const owners = flattenPages(employeesQuery.data) ?? [];

    return owners.length > 0 ? owners[0] : undefined;
  }, [employeesQuery.data]);

  return (
    <QueryResult queries={[twilioRegistrationStatusQuery, practiceQuery, employeesQuery]}>
      {twilioRegistrationStatusQuery.data && practiceQuery.data && owner ? (
        <SetupTwilioPractice
          practice={practiceQuery.data}
          owner={owner}
          hasInitiated={twilioRegistrationStatusQuery.data.status !== "PENDING"}
          hasPhoneNumber={Boolean(twilioRegistrationStatusQuery.data.practiceCommsNumber)}
          from={from}
        />
      ) : null}
    </QueryResult>
  );
};
