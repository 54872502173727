import { FC } from "react";
import { Link } from "react-router-dom";

import { EmailConfigVO } from "@libs/api/generated-api";
import { Banner } from "@libs/components/UI/Banner";

import { paths } from "utils/routing/paths";

interface Props {
  emailConfig: EmailConfigVO;
}

export const EmailSettingsBanner: FC<Props> = ({ emailConfig }) => {
  const { domainVerified, replyToEmail } = emailConfig;

  if (domainVerified && replyToEmail) {
    return null;
  }

  const callToActions =
    !domainVerified && !replyToEmail ? (
      <>
        <EnableEmailServicesLink /> and <SpecifyReplyToEmailLink />
      </>
    ) : replyToEmail ? (
      <EnableEmailServicesLink />
    ) : domainVerified ? (
      <SpecifyReplyToEmailLink />
    ) : null;

  return (
    <Banner className="w-full rounded text-xs" theme="info">
      <span>To send emails you will need to {callToActions}.</span>
    </Banner>
  );
};

const EnableEmailServicesLink: FC = () => (
  <Link
    className="font-sansSemiBold text-archyBlue-500"
    to={paths.settingsSection({ section: "email-authentication" })}
  >
    enable email services
  </Link>
);

const SpecifyReplyToEmailLink: FC = () => (
  <Link
    className="font-sansSemiBold text-archyBlue-500"
    to={paths.settingsSection({ section: "email-preferences" })}
  >
    specify a reply to email
  </Link>
);
