import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { MountVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatISODate } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as TransferIcon } from "@libs/assets/icons/transfer.svg";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { Pill } from "@libs/components/UI/Pill";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { useAccount } from "@libs/contexts/AccountContext";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { LayoutCard } from "@libs/components/UI/LayoutCard";
import {
  HeaderCell,
  ButtonCell,
  Row,
  TableGrid,
  cxGridTableStyles,
  IconsCell,
} from "@libs/components/UI/GridTableComponents";
import { ScrollableInfiniteQuery } from "@libs/components/UI/ScrollableInfiniteQuery";
import { EditMountModal } from "components/PatientProfile/Imaging/PatientMountsList/EditMountModal";
import { useCameraCapture } from "components/ImageCapturing/useCameraCapture";

import { paths } from "utils/routing/paths";
import { transferMount, updateMount } from "api/imaging/mutations";
import { handleError } from "utils/handleError";
import { useInfiniteMountsQuery } from "api/imaging/queries";
import { OfflineUploadFlyover } from "components/PatientProfile/Imaging/PatientMountsList/OfflineUploadFlyover";
import { useItemModal } from "hooks/useItemModal";
import { TransferMountFlyover } from "components/PatientProfile/Imaging/PatientMountsList/TransferMountFlyover";
import { TransferConfirmationModal } from "components/PatientProfile/Imaging/PatientMountsList/TransferConfirmationModal";
import { usePathParams } from "hooks/usePathParams";
import { getLayoutForLayoutName } from "components/PatientProfile/Imaging/PatientMountsList/mountLayouts";
import { Header } from "./Header";

export const getMountName = (mount: MountVO) => {
  const layout = getLayoutForLayoutName(mount.layout);

  const name = (mount.name || mount.layout).trim();

  if (name && (name === layout.type || name === layout.label)) {
    return mount.layout;
  }

  return `${name} (${layout.label})`;
};

const tableColumns = [
  {
    text: "",
    width: "0.75rem",
  },
  {
    text: "Mount",
    width: "minmax(13rem,1fr)",
  },
  {
    text: "Date",
    width: "1fr",
  },
  {
    text: "Content",
    width: "1fr",
  },
  {
    text: "",
    width: "44px",
  },
];

interface RowProps {
  item: MountVO;
  onClickEdit: Func;
  onClickTransfer: Func;
  patientId: number;
}

const ImageRow: React.FC<RowProps> = ({ item, onClickEdit, onClickTransfer, patientId }) => {
  const navigate = useNavigate();
  const handleDoubleClick = useCallback(
    (mountId: number) => {
      navigate(paths.mountDetails({ mountId, patientId }));
    },
    [navigate, patientId]
  );

  const menu = useBoolean(false);

  const menuOptions = useMemo(
    () =>
      createMenuOptions(
        {
          label: "Edit",
          SvgIcon: EditIcon,
          value: "edit",
        },
        {
          label: "Transfer",
          SvgIcon: TransferIcon,
          value: "transfer",
        }
      ),
    []
  );

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "edit": {
          onClickEdit();
          break;
        }
        case "transfer": {
          onClickTransfer();
          break;
        }
        default: {
          break;
        }
      }

      menu.off();
    },
    [menu, onClickEdit, onClickTransfer]
  );

  const cellProps = {
    onDoubleClick: () => handleDoubleClick(item.id),
    verticalPadding: "slim" as const,
  };

  return (
    <Row key={item.id}>
      <ButtonCell {...cellProps} />
      <ButtonCell {...cellProps}>{getMountName(item)}</ButtonCell>
      <ButtonCell {...cellProps}>{formatISODate(item.date)}</ButtonCell>
      <ButtonCell {...cellProps}>
        <div className="flex gap-x-1">{item.types?.map((type, idx) => <Pill key={idx}>{type}</Pill>)}</div>
      </ButtonCell>
      <IconsCell
        className={cx("flex items-center justify-center py-2 cursor-pointer", cxGridTableStyles.dataCell)}
      >
        <ButtonMenu
          isOpen={menu.isOn}
          menuContent={
            <div className="w-40">
              <MenuOptions onOptionClick={handleOptionClick} options={menuOptions} />
            </div>
          }
          onRequestClose={menu.off}
          onRequestOpen={menu.on}
          placement="bottom-end"
        >
          {(props) => (
            <ButtonIcon aria-label="mount-list-menu-button" SvgIcon={MenuIcon} theme="slate700" {...props} />
          )}
        </ButtonMenu>
      </IconsCell>
    </Row>
  );
};

export const PatientMountsList: React.FC = () => {
  const cameraCapture = useCameraCapture();
  const offlineCaptureFlyover = useBoolean(false);
  const { practiceId } = useAccount();
  const { patientId } = usePathParams("patientTab");

  const mountsInfiniteQuery = useInfiniteMountsQuery({ practiceId, patientId });

  const { data: imageMountData } = mountsInfiniteQuery;
  const imageMountList = useFlattenPages(imageMountData) ?? [];

  const selectedMount = useItemModal<{ mode: "edit" | "transfer"; mount: MountVO }>(null);
  const confirmationModal = useBoolean(false);
  const [{ mutate: mutateMount, isLoading: isSaving }] = useApiMutations([updateMount]);
  const handleUpdateMountName = useCallback(
    (name: string) => {
      if (selectedMount.item && selectedMount.item.mode === "edit") {
        mutateMount(
          {
            data: { name },
            invalidateCache: true,
            mountId: selectedMount.item.mount.id,
            patientId,
            practiceId,
          },
          {
            onError: handleError,
            onSettled: selectedMount.close,
          }
        );
      }
    },
    [selectedMount.close, selectedMount.item, mutateMount, patientId, practiceId]
  );

  const [transferMountMutation] = useApiMutations([transferMount]);
  const [confirmationNavigateData, setConfirmationNavigateData] = useState<{
    mountId: number;
    patientId: number;
  }>();

  const handleTransferMount = useCallback(
    (mountId: number, targetPatientId: number | undefined) => {
      if (targetPatientId) {
        transferMountMutation.mutate(
          {
            data: {
              mountId,
              sourcePatientId: patientId,
              targetPatientId,
            },
            practiceId,
          },
          {
            onSuccess: () => {
              selectedMount.close();
              confirmationModal.on();
              setConfirmationNavigateData({ mountId, patientId: targetPatientId });
            },
            onError: handleError,
          }
        );
      }
    },
    [confirmationModal, patientId, practiceId, selectedMount, transferMountMutation]
  );

  return (
    <>
      {offlineCaptureFlyover.isOn && <OfflineUploadFlyover onRequestClose={offlineCaptureFlyover.off} />}
      <LayoutCard className="h-full flex flex-col">
        <Header
          cameraCapture={cameraCapture}
          onClickUploadOfflineImages={offlineCaptureFlyover.on}
          patientId={patientId}
        />

        <ScrollableInfiniteQuery infiniteQuery={mountsInfiniteQuery} id="patient-image-mounts">
          {(items) => {
            return items.length === 0 ? (
              <div className="mt-10 flex flex-1 flex-col items-center text-greyMedium">
                <div className="font-sansSemiBold">This patient has no images</div>
                <div>Add your first image by tapping &quot;New Image&quot;</div>
              </div>
            ) : (
              <TableGrid columnWidths={tableColumns.map(({ width }) => width)}>
                {tableColumns.map((item, index) => (
                  <HeaderCell className="sticky bg-white" key={index} size="short" sticky={false}>
                    {item.text}
                  </HeaderCell>
                ))}
                {imageMountList.map((item) => (
                  <ImageRow
                    item={item}
                    key={item.id}
                    onClickEdit={() => selectedMount.open({ mode: "edit", mount: item })}
                    onClickTransfer={() => selectedMount.open({ mode: "transfer", mount: item })}
                    patientId={patientId}
                  />
                ))}
              </TableGrid>
            );
          }}
        </ScrollableInfiniteQuery>
      </LayoutCard>
      {selectedMount.isOpen &&
        (selectedMount.item.mode === "edit" ? (
          <EditMountModal
            isSaving={isSaving}
            mountName={selectedMount.item.mount.name}
            onCancel={selectedMount.close}
            onDone={handleUpdateMountName}
          />
        ) : (
          <TransferMountFlyover
            isLoading={transferMountMutation.isLoading}
            mount={selectedMount.item.mount}
            onClose={selectedMount.close}
            onTransfer={handleTransferMount}
            sourcePatientId={patientId}
          />
        ))}
      {confirmationModal.isOn && confirmationNavigateData && (
        <TransferConfirmationModal
          confirmationNavigateData={confirmationNavigateData}
          onClose={confirmationModal.off}
          transferType="MOUNT"
        />
      )}
    </>
  );
};
