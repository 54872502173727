import { ComponentProps, FC, Fragment, useCallback } from "react";
import { DentalProcedureVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatISODate } from "@libs/utils/date";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as DropDownIcon } from "@libs/assets/icons/down-chevron.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ButtonCell, EMPTY_CELL, HeaderCell, Row, TextCell } from "@libs/components/UI/GridTableComponents";
import { ExpiredCDTCodeIcon } from "components/Charting/ExpiredCDTCodeIcon";
import { useNow } from "hooks/useNow";
import { DurationRenderer } from "components/Settings/Scheduling/Sections/ProcedureDurations/DurationRenderer";
import { CollapsibleSection } from "components/UI/CollapsibleSection";

export interface CategoryGridProps {
  title: string;
  subCategories: Record<string, { title: string; procedures: DentalProcedureVO[] } | undefined>;
  procedures: DentalProcedureVO[];
  isOpen: boolean;
  onToggleOpen: (newCategory: string) => void;
  onEdit: ComponentProps<typeof ProcedureRow>["onEdit"];
}

const ProcedureRow: FC<{
  procedure: DentalProcedureVO;
  now: Date;
  onEdit: (procedure: DentalProcedureVO) => void;
  isLast: boolean;
}> = ({ now, procedure, onEdit, isLast }) => {
  const handleEditClick = useCallback(() => onEdit(procedure), [onEdit, procedure]);

  const cellProps = {
    onDoubleClick: handleEditClick,
    borderColor: "border-slate-200" as const,
    border: !isLast,
  };

  return (
    <Row className="*:flex *:items-center">
      <ButtonCell {...cellProps} className="flex items-center gap-x-1">
        {procedure.cdtCode}
        <ExpiredCDTCodeIcon now={now} endDate={procedure.endDate} />
      </ButtonCell>
      <ButtonCell {...cellProps}>{procedure.name}</ButtonCell>
      <ButtonCell {...cellProps}>{procedure.simpleName}</ButtonCell>
      <ButtonCell {...cellProps}>{procedure.laymanTerm}</ButtonCell>
      <ButtonCell {...cellProps}>
        {procedure.endDate ? formatISODate(procedure.endDate) : EMPTY_CELL}
      </ButtonCell>
      <ButtonCell className="flex flex-nowrap" {...cellProps}>
        <DurationRenderer duration={procedure.duration} showDuration />
      </ButtonCell>
      <ButtonCell className="flex justify-center" onClick={handleEditClick} {...cellProps}>
        <Icon SvgIcon={EditIcon} theme="slate700" size="md" />
      </ButtonCell>
    </Row>
  );
};

export const CategoryGrid: FC<CategoryGridProps> = ({
  title,
  subCategories,
  isOpen,
  procedures,
  onToggleOpen,
  onEdit,
}) => {
  const now = useNow();

  const subCategoryKeys = Object.keys(subCategories);
  const hasGridData = procedures.length > 0 || subCategoryKeys.length > 0;

  const headerCellProps = {
    borderColor: "border-slate-200" as const,
    size: "short" as const,
  };

  return (
    <div>
      <button
        type="button"
        className={cx(
          `font-sansSemiBold
           text-xs
           text-left
           h-10
           pl-5
           pr-3.5
           flex
           justify-between
           items-center
           w-full
           border
           border-slate-200`,
          isOpen ? "rounded-t" : "rounded"
        )}
        onClick={() => onToggleOpen(title)}
      >
        {title}
        <DropDownIcon className={cx("transition-transform w-5 h-5", isOpen && "rotate-180")} />
      </button>
      <CollapsibleSection isOpen={isOpen}>
        <div className="border border-t-0 rounded-b border-slate-200">
          <div className="overflow-y-auto max-h-[450px]">
            {hasGridData ? (
              <div className="grid grid-cols-[9rem_1fr_9rem_1fr_9rem_1fr_3.25rem]">
                <HeaderCell {...headerCellProps}>Code</HeaderCell>
                <HeaderCell {...headerCellProps}>Description</HeaderCell>
                <HeaderCell {...headerCellProps}>Simple Name</HeaderCell>
                <HeaderCell {...headerCellProps}>External Name</HeaderCell>
                <HeaderCell {...headerCellProps}>Expiration</HeaderCell>
                <HeaderCell {...headerCellProps}>Duration</HeaderCell>
                <HeaderCell {...headerCellProps} />
                {procedures.map((procedure, index) => (
                  <ProcedureRow
                    key={procedure.id}
                    procedure={procedure}
                    now={now}
                    onEdit={onEdit}
                    isLast={index === procedures.length - 1}
                  />
                ))}
                {subCategoryKeys.map((subCatName, subCatIndex) => {
                  const subCat = subCategories[subCatName];

                  return (
                    <Fragment key={subCatName}>
                      <Row>
                        <TextCell
                          className="bg-slate-50 text-xs col-span-full"
                          borderColor="border-slate-200"
                        >
                          {subCat?.title}
                        </TextCell>
                      </Row>
                      {subCat?.procedures.map((procedure, index) => (
                        <ProcedureRow
                          key={procedure.id}
                          procedure={procedure}
                          now={now}
                          onEdit={onEdit}
                          isLast={
                            subCatIndex === subCategoryKeys.length - 1 &&
                            index === subCat.procedures.length - 1
                          }
                        />
                      ))}
                    </Fragment>
                  );
                })}
              </div>
            ) : null}
          </div>
        </div>
      </CollapsibleSection>
    </div>
  );
};
