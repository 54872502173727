import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";
import React from "react";
import { MountVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { Cell, HeaderCell, TableGrid } from "@libs/components/UI/GridTableComponents";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import EmptyTreeCards from "assets/images/empty-images.svg";
import { ImagesTableRow } from "components/UI/SelectMountImages/ImagesTableRow";
import { BaseMountImagesTableProps } from "components/UI/SelectMountImages/types";

type Props = {
  mountsQuery: UseInfiniteApiQueryResult<MountVO[]>;
  patientId: number;
  className?: string;
} & BaseMountImagesTableProps;

const COLUMNS = [
  {
    text: "",
    width: "50px",
  },
  {
    text: "Mount",
    width: "1fr",
  },
  {
    text: "Date",
    width: "1fr",
  },
  {
    text: "Content",
    width: "1fr",
  },
  {
    text: "# Selected",
    id: "numSelected",
    width: "90px",
  },
  {
    text: "",
    id: "link",
    width: "50px",
  },
];

const LOADING_ROW_NUMBERS = Array.from({ length: 15 }).map((_, x) => x);

export const SelectMountImagesTable: React.FC<Props> = ({
  mountsQuery,
  patientId,
  className,
  showLink = true,
  selectedImageIds,
  onClickImage,
  ImageWrapper,
}) => {
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: mountsQuery });
  const imageMountList = useFlattenPages(mountsQuery.data) ?? [];
  const headers = React.useMemo(() => {
    return COLUMNS.filter((column) => {
      return (column.id !== "link" || showLink) && (column.id !== "numSelected" || selectedImageIds);
    });
  }, [showLink, selectedImageIds]);

  return (
    <div
      className={cx(
        `flex-1
         min-h-0
         rounded-t
         border-t
         bg-white
         relative
         z-0
         overflow-y-auto`,
        className
      )}
      ref={rootRef}
    >
      <TableGrid columnWidths={headers.map(({ width }) => width)}>
        {headers.map(({ text }, index) => (
          <HeaderCell className="z-[100]" key={index} size="short">
            {text}
          </HeaderCell>
        ))}
        <ScrollableInfiniteQueryResult
          infiniteQuery={mountsQuery}
          loadError={
            <div className="col-span-5">
              <ErrorContent />
            </div>
          }
          loading={LOADING_ROW_NUMBERS.map((key) => (
            <Cell key={key} className="col-span-5 h-10">
              <LoadingContent className="rounded-none" />
            </Cell>
          ))}
          noResults={
            <div className="col-span-full flex flex-col items-center py-10">
              <img alt="No Images" src={EmptyTreeCards} />
              <span className="text-sm mt-5">No medical images available</span>
            </div>
          }
          scrollRef={scrollRef}
        >
          <DndProvider backend={HTML5Backend} context={window}>
            {imageMountList.map((imageMount, index) => (
              <ImagesTableRow
                key={index}
                imageMount={imageMount}
                patientId={patientId}
                selectedImageIds={selectedImageIds}
                showLink={showLink}
                onClickImage={onClickImage}
                ImageWrapper={ImageWrapper}
              />
            ))}
          </DndProvider>
        </ScrollableInfiniteQueryResult>
      </TableGrid>
    </div>
  );
};
