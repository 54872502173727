import { FC } from "react";
import { produce } from "immer";
import { FormProcedureTableElementRequest } from "@libs/api/generated-api";
import { toggleSet } from "@libs/utils/toggleSet";
import { InfoCheckbox } from "components/Settings/Forms/InfoCheckbox";

export const ProcedureTableFormContent: FC<{
  formState: UseState<{ draft: FormProcedureTableElementRequest }>;
}> = ({ formState: [{ draft }, setForm] }) => {
  const handleToggleSetting = (value: ListItem<typeof draft.settings>) => {
    setForm((last) =>
      produce(last, (next) => {
        next.draft.settings = [...toggleSet(new Set(next.draft.settings), value)];
      })
    );
  };

  return (
    <div className="flex flex-col gap-y-4">
      <InfoCheckbox
        label="Display UCR Fee Column"
        checked={draft.settings.includes("DISPLAY_UCR_RATE")}
        onChange={() => handleToggleSetting("DISPLAY_UCR_RATE")}
      />
    </div>
  );
};
