import { FC, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as CheckIcon } from "@libs/assets/icons/check-circle-1.svg";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { paths } from "utils/routing/paths";

interface Props {
  confirmationNavigateData: {
    mountId: number;
    patientId: number;
  };
  onClose: Func;
  transferType: "IMAGES" | "MOUNT";
}

export const TransferConfirmationModal: FC<Props> = ({ confirmationNavigateData, onClose, transferType }) => {
  const navigate = useNavigate();
  const navigateToMount = useCallback(() => {
    navigate(
      paths.mountDetails(confirmationNavigateData, {
        from: paths.patientTab({ patientId: confirmationNavigateData.patientId, tab: "imaging" }),
      })
    );
    onClose();
  }, [confirmationNavigateData, navigate, onClose]);

  return (
    <ConfirmationModal
      cancelText={transferType === "IMAGES" ? "Stay on this Mount" : "Stay on this Patient"}
      confirmText={transferType === "IMAGES" ? "View Transferred Images" : "View Transferred Mount"}
      onCancel={onClose}
      onConfirm={navigateToMount}
      primaryText={
        <div className="flex flex-col items-center">
          <Icon size="lg" SvgIcon={CheckIcon} theme="success" />
          <span>{`${
            transferType === "IMAGES" ? "Images have been transferred." : "Mount has been transferred."
          }`}</span>
        </div>
      }
    />
  );
};
