import { useCallback, useMemo } from "react";
import { FrequencyLimitationCategoryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as HideIcon } from "@libs/assets/icons/hide.svg";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as ShowIcon } from "@libs/assets/icons/eye.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { Pill } from "@libs/components/UI/Pill";
import { createMenuOption, MenuOptions } from "@libs/components/UI/MenuOptions";
import { Cell, Row, cxGridTableStyles } from "@libs/components/UI/GridTableComponents";

interface Props {
  limitationCategory: FrequencyLimitationCategoryVO;
  onClickDelete: Func;
  onClickEdit: Func;
  onClickToggleVisibility: (limitationId: number, isHidden: boolean) => void;
}

const cxStyles = {
  cell: cx("flex items-center py-3", cxGridTableStyles.cellPadding()),
};

export const DefaultFrequencyToLabelMap = {
  X_VISITS_PER_BENEFIT_YEAR: "# per benefit year",
  ONE_VISIT_EVERY_X_YEARS: "Every # years",
  ONE_VISIT_EVERY_X_MONTHS: "Every # months",
  ONE_VISIT_EVERY_X_YEARS_PER_TOOTH: "Every # years per tooth",
  ONE_VISIT_EVERY_X_MONTHS_PER_QUADRANT: "Every # months per quadrant",
  ONE_VISIT_EVERY_X_YEARS_PER_ARCH: "Every # months per arch",
};

export const LimitationCategoryRow: React.FC<Props> = ({
  limitationCategory,
  onClickDelete,
  onClickEdit,
  onClickToggleVisibility,
}) => {
  const limitationProcedures = useMemo(
    () => limitationCategory.dentalProcedures.map((procedure) => procedure.cdtCode).join(", "),
    [limitationCategory.dentalProcedures]
  );
  const defaultFrequencyLimit = limitationCategory.defaultFrequencyLimit ?? "X_VISITS_PER_BENEFIT_YEAR";
  const defaultFrequency = DefaultFrequencyToLabelMap[defaultFrequencyLimit];
  const menu = useBoolean(false);

  const menuOptions = useMemo(() => {
    const editOption = createMenuOption({
      SvgIcon: EditIcon,
      label: "Edit",
      value: "edit",
    });
    const showHideOption = createMenuOption(
      limitationCategory.isHidden
        ? {
            SvgIcon: ShowIcon,
            label: "Show",
            value: "visibilityToggle",
          }
        : {
            SvgIcon: HideIcon,
            label: "Hide",
            value: "visibilityToggle",
          }
    );

    const deleteOption = createMenuOption({
      disabled: limitationCategory.isDefault,
      SvgIcon: DeleteIcon,
      label: "Delete",
      tooltip: {
        content: limitationCategory.isDefault ? "Archy default services can be hidden but not deleted" : "",
        theme: "SMALL",
      },
      value: "delete",
    });

    return limitationCategory.isDefault
      ? [editOption, showHideOption, deleteOption]
      : [editOption, deleteOption];
  }, [limitationCategory.isDefault, limitationCategory.isHidden]);

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "edit": {
          onClickEdit();
          break;
        }
        case "visibilityToggle": {
          onClickToggleVisibility(limitationCategory.id, limitationCategory.isHidden);
          break;
        }
        case "delete": {
          onClickDelete();
          break;
        }
        default: {
          break;
        }
      }

      menu.off();
    },
    [
      limitationCategory.id,
      limitationCategory.isHidden,
      menu,
      onClickDelete,
      onClickEdit,
      onClickToggleVisibility,
    ]
  );

  return (
    <Row aria-label={limitationCategory.name} key={limitationCategory.id}>
      <Cell className={cxStyles.cell}>{limitationCategory.name}</Cell>
      <Cell className={cxStyles.cell}>{limitationProcedures}</Cell>
      <Cell className={cxStyles.cell}>{defaultFrequency}</Cell>
      <Cell className={cxStyles.cell}>
        <Pill theme={limitationCategory.isHidden ? "slate200" : "green"}>
          {limitationCategory.isHidden ? "Hidden" : "Visible"}
        </Pill>
      </Cell>
      <Cell className={cx("flex justify-center pr-4", cxStyles.cell)}>
        <ButtonMenu
          isOpen={menu.isOn}
          menuContent={
            <div className="w-40">
              <MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />
            </div>
          }
          onRequestClose={menu.off}
          onRequestOpen={menu.on}
          placement="bottom-end"
        >
          {(props) => <ButtonIcon {...props} SvgIcon={MenuIcon} />}
        </ButtonMenu>
      </Cell>
    </Row>
  );
};
