import React from "react";
import { ParentSize } from "@visx/responsive";

import { CustomAdjustmentTypeVO } from "@libs/api/generated-api";
import { formatAsISODate } from "@libs/utils/date";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { useAdjustmentsOverTimeAsStacks } from "components/Dashboard/Adjustments/hooks/useAdjustmentsOverTimeAsStacks";
import { ChartContainer } from "components/Dashboard/Charting/ChartContainer";
import { AdjustmentsOverTimeChart } from "components/Dashboard/Adjustments/AdjustmentsOverTimeChart";
import { AdjustmentsDonutChart } from "components/Dashboard/Adjustments/AdustmentsDonutChart";
import { ReactComponent as ChartDivider } from "components/Dashboard/assets/ChartDivider.svg";
import { AdjustmentCategory, DashboardAdjustmentsQuery } from "utils/routing/dashboard";
import { CarriersQuery, TimeSeriesQuery } from "components/Dashboard/types";
import { TimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";

interface Props {
  insuranceAdjustmentTimeseriesQuery: TimeSeriesQuery;
  collectionAdjustmentTimeseriesQuery: TimeSeriesQuery;
  carrierAdjustmentsForSelectedTimePeriodQuery: TimeSeriesQuery;
  collectionAdjustmentsForSelectedTimePeriodQuery: TimeSeriesQuery;
  adjustmentTypesQuery: ApiQueryResult<CustomAdjustmentTypeVO[]>;
  allCarriersQuery: CarriersQuery;
  dateWindow: TimeSeriesPageSelections;
  tableTabSelected: AdjustmentCategory;
  params: DashboardAdjustmentsQuery;
  disabledMessage?: string;
  onUpdateParams: (updates: Partial<DashboardAdjustmentsQuery>) => void;
}
export const AdjustmentsCharts: React.FC<Props> = ({
  insuranceAdjustmentTimeseriesQuery,
  collectionAdjustmentTimeseriesQuery,
  carrierAdjustmentsForSelectedTimePeriodQuery,
  collectionAdjustmentsForSelectedTimePeriodQuery,
  adjustmentTypesQuery,
  allCarriersQuery,
  tableTabSelected,
  params,
  dateWindow,
  disabledMessage,
  onUpdateParams,
}) => {
  const resolution = params.resolution;
  const focusDate = params.focusDate;
  const timeSeriesStackData = useAdjustmentsOverTimeAsStacks({
    resolution,
    insuranceAdjustmentTimeseriesQuery,
    collectionAdjustmentTimeseriesQuery,
    dateWindow,
  });

  const currentlySelectedTotal = React.useMemo(() => {
    if (focusDate) {
      const dayTotals = timeSeriesStackData.data.find((item) => item.date === formatAsISODate(focusDate));

      return dayTotals ? dayTotals.collection + dayTotals.insurance : 0;
    }

    const total = timeSeriesStackData.data.reduce((acc, item) => item.collection + item.insurance + acc, 0);

    return total;
  }, [timeSeriesStackData.data, focusDate]);

  return (
    <ChartContainer disabledMessage={disabledMessage}>
      <ParentSize className="basis-3/4 relative">
        {({ height, width }: { width: number; height: number }) => {
          return (
            <AdjustmentsOverTimeChart
              width={width}
              height={height}
              resolution={resolution}
              timeSegment={dateWindow}
              chartDisplay={params.chartDisplay}
              onUpdateParams={onUpdateParams}
              focusDate={params.focusDate}
              {...timeSeriesStackData}
            />
          );
        }}
      </ParentSize>
      <div className="px-5 pb-6 text-greyLight">
        <ChartDivider />
      </div>
      <ParentSize className="basis-1/4 relative pb-6">
        {({ height, width }: { width: number; height: number }) => {
          return (
            <AdjustmentsDonutChart
              width={width}
              height={height}
              total={currentlySelectedTotal}
              carrierAdjustmentsForSelectedTimePeriodQuery={carrierAdjustmentsForSelectedTimePeriodQuery}
              adjustmentTypesQuery={adjustmentTypesQuery}
              collectionAdjustmentsForSelectedTimePeriodQuery={
                collectionAdjustmentsForSelectedTimePeriodQuery
              }
              params={params}
              onUpdateParams={onUpdateParams}
              tableTabSelected={tableTabSelected}
              allCarriersQuery={allCarriersQuery}
            />
          );
        }}
      </ParentSize>
    </ChartContainer>
  );
};
