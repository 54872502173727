import { FC } from "react";
import { dollarsToCents, formatCurrency } from "@libs/utils/currency";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { FormFieldCurrencyInput } from "components/UI/FormFieldCurrencyInput";
import { FormSection } from "./FormSection";

export const CostFormSection: FC<{
  shippingCost: string | undefined;
  labFee: string | undefined;
  onShippingCostChange: (value: string | undefined) => void;
  onLabFeeChange: (value: string | undefined) => void;
}> = ({ shippingCost, labFee, onShippingCostChange, onLabFeeChange }) => {
  return (
    <FormSection title="Cost" cols="3">
      <FormFieldCurrencyInput label="Shipping" value={shippingCost} onValueChange={onShippingCostChange} />
      <FormFieldCurrencyInput label="Lab Fee" value={labFee} onValueChange={onLabFeeChange} />
      <div className="grid">
        <FormFieldLabel content="Total" className="font-sansSemiBold text-xs" />
        <div className="text-sm font-sansSemiBold">
          {formatCurrency(dollarsToCents(Number(shippingCost ?? 0)) + dollarsToCents(Number(labFee ?? 0)))}
        </div>
      </div>
    </FormSection>
  );
};
