import { useCallback } from "react";
import { formatCurrency } from "@libs/utils/currency";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { getProductionByProviderQuery } from "api/reporting/queries";
import { ProductionByProviderRow } from "components/Dashboard/PracticeProduction/ProductionByProviderRow";
import { FooterCell } from "components/Dashboard/Tables/FooterCell";
import { useQueryParams } from "hooks/useQueryParams";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import {
  NOT_SCHEDULED_FILTER,
  PRODUCTION_BY_PROVIDER_COLUMNS,
} from "components/Dashboard/PracticeProduction/constants";
import { DashboardTable } from "components/Dashboard/Tables/DashboardTable";
import { DashboardTableTabs } from "components/Dashboard/Tables/DashboardTableTabs";
import { useProductionTableTabProps } from "components/Dashboard/PracticeProduction/hooks/useProductionTableTabProps";
import { PracticeProductionQuery } from "utils/routing/dashboard";
import { useDownloadProductionByProviderCSV } from "components/Dashboard/PracticeProduction/hooks/useDownloadProductionByProviderCSV";
import { TableSelectProvider } from "components/Dashboard/Tables/TableSelectProvider";
import { dashboardFiltersForTables } from "components/Dashboard/utils/filters";
import { useProviderRollupFilterOptions } from "components/Dashboard/hooks/useProviderRollupFilterOptions";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";

const useProductionByProviderQuery = () => {
  const { practiceId } = useAccount();

  const { query } = useQueryParams("dashboardPracticeProduction");
  const dateWindow = useTimeSeriesPageSelections({ ...query, includeProjectedDates: true });
  const { selectedTimeSegment, selectedTimeSegmentParams } = dateWindow;
  const [productionByProviderQuery] = useApiQueries([
    getProductionByProviderQuery({
      args: {
        practiceId,
        data: {
          ...selectedTimeSegmentParams,
          filters: [NOT_SCHEDULED_FILTER, ...dashboardFiltersForTables(query.filters)],
        },
      },
    }),
  ]);
  const isLoading = productionByProviderQuery.isLoading;
  const providers = productionByProviderQuery.data?.byProviders;

  return {
    isLoading,
    providers,
    error: productionByProviderQuery.error,
    totals: productionByProviderQuery.data?.total,
    selectedTimeSegment,
    productionByProviderQuery,
    dateWindow,
  };
};

export const ProductionByProviderTable: React.FC = () => {
  const { providers, isLoading, totals, error, selectedTimeSegment, productionByProviderQuery, dateWindow } =
    useProductionByProviderQuery();
  const { query, updateQuery } = useQueryParams("dashboardPracticeProduction");
  const tableExpanded = query["table.fullScreen"];
  const { filters } = query;
  const handleRouteStateChange = useCallback(
    (updates: Partial<PracticeProductionQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );

  const { tabs } = useProductionTableTabProps({
    query,
  });
  const { data: providerFilterOptions } = useProviderRollupFilterOptions({
    metric: "productionAmount",
    dateWindow,
    ...query,
  });

  const { downloadCSV, isDownloading: isDownloadingCsv } = useDownloadProductionByProviderCSV({
    selectedTimeSegment,
    productionByProviderQuery,
  });

  return (
    <>
      <DashboardTableTabs
        tabs={tabs}
        onUpdateParams={handleRouteStateChange}
        onClickDownload={downloadCSV}
        isDownloading={isDownloadingCsv}
        query={query}
        includePatientSearch={false}
        id="practice-production-by-provider"
      >
        <div className="relative flex gap-3">
          {
            // Since they can select provider via the chart, only show provider selection when table full screen
          }
          {tableExpanded && (
            <TableSelectProvider
              filters={filters}
              onFiltersChanged={(newFilters) => {
                handleRouteStateChange({ filters: newFilters });
              }}
              providerFilterOptions={providerFilterOptions}
            />
          )}
        </div>
      </DashboardTableTabs>
      <DashboardTable
        id="practiceProductionByProviderTable"
        isError={Boolean(error)}
        isEmpty={!isLoading && providers?.length === 0}
        columnWidths={PRODUCTION_BY_PROVIDER_COLUMNS.map(({ width }) => width)}
        isLoading={isLoading}
        headerRow={<DashboardHeaderRow headers={PRODUCTION_BY_PROVIDER_COLUMNS} />}
        footerRow={
          <>
            <FooterCell className="col-span-3">Totals</FooterCell>
            <FooterCell loading={isLoading} align="right">
              {totals && formatCurrency(totals.totalProducedAmount)}
            </FooterCell>
          </>
        }
      >
        {providers?.map((item, i) => {
          return (
            <ProductionByProviderRow
              key={`${item.provider.id}`}
              row={item}
              last={i === providers.length - 1}
            />
          );
        })}
      </DashboardTable>
    </>
  );
};
