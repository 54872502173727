import { useMemo } from "react";
import { flip, offset } from "@floating-ui/react-dom";
import { InsurancePlanSummaryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { Spinner } from "@libs/components/UI/Spinner";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Menu, useMenu } from "@libs/components/UI/Menu";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { TableGrid, HeaderCell, Row, ButtonCell } from "@libs/components/UI/GridTableComponents";

interface Props {
  carrierName: string;
  insurancePlansInfiniteQuery: UseInfiniteApiQueryResult<InsurancePlanSummaryVO[]>;
  isLinking: boolean;
  onCreateNewPlan: Func;
  onLinkPlan: (planUuid: string) => void;
  onSearch: (search: string) => void;
  searchString: string;
}

type PlanOption = {
  data: InsurancePlanSummaryVO | undefined;
  label: string;
  value: string;
};

type ColValue = string | number | undefined;

const MenuRow: React.FC<{
  onClick?: Func;
  values: [ColValue, ColValue, ColValue, ColValue, ColValue];
}> = ({ onClick, values }) => {
  return (
    <Row>
      {values.map((item, index) => {
        return (
          <ButtonCell
            border={false}
            className={cx(
              `flex
               items-center
               h-8
               px-1
               py-2
               first:rounded-l
               last:rounded-r
               group-hover:bg-slate-50`,
              index === values.length - 1 ? "justify-end" : "justify-start"
            )}
            key={index}
            onClick={onClick}
          >
            <FloatingTooltip content={index === values.length - 1 ? "" : item} theme="SMALL">
              <span className="truncate">{item}</span>
            </FloatingTooltip>
          </ButtonCell>
        );
      })}
    </Row>
  );
};

const CreateNewOption: React.FC<{ label: string; onCreateNewPlan: Func }> = ({ label, onCreateNewPlan }) => {
  return (
    <Row>
      <ButtonCell
        className={`
          flex
          items-center
          h-8
          px-1
          py-2
          col-span-full
          text-primaryTheme
          group-hover:bg-slate-50
        `}
        border={false}
        onClick={onCreateNewPlan}
      >
        {label}
      </ButtonCell>
    </Row>
  );
};

const MENU_OFFSET = 2;

const searchHeaders = [
  { id: "groupNumber", label: "Group Number", width: "1fr" },
  { id: "planIdentifier", label: "Nickname", width: "1fr" },
  { id: "groupName", label: "Group Name", width: "1fr" },
  { id: "employer", label: "Employer", width: "1fr" },
  { className: "justify-end", id: "patients", label: "Patients", width: "60px" },
];

export const UnlinkedPlanSection: React.FC<Props> = ({
  carrierName,
  insurancePlansInfiniteQuery,
  isLinking,
  onCreateNewPlan,
  onLinkPlan,
  onSearch,
  searchString,
}) => {
  const insurancePlans = useFlattenPages(insurancePlansInfiniteQuery.data);

  const searchPlanOptions: PlanOption[] = useMemo(() => {
    if (!insurancePlans) {
      return [];
    }

    const insurancePlanOptions: PlanOption[] = insurancePlans.map((plan) => {
      return {
        data: plan,
        label: plan.groupName ?? "",
        value: plan.uuid,
      };
    });

    return [
      ...insurancePlanOptions,
      {
        data: undefined,
        label: "Create New Plan",
        value: "create-new-plan",
      },
    ];
  }, [insurancePlans]);

  const { scrollRef } = useInfiniteScrollQuery({ infiniteQuery: insurancePlansInfiniteQuery });
  const menu = useMenu<HTMLInputElement>();

  return (
    <div className="col-span-full">
      {isLinking ? (
        <div className="flex items-center justify-center h-96 w-full">
          <Spinner animation="border" size="panel" variant="primary" />
        </div>
      ) : (
        <div className="col-span-full">
          <FormFieldInput
            Icon={SearchIcon}
            onChange={(e) => {
              if (!menu.isOpen) {
                menu.open();
              }

              onSearch(e.target.value);
            }}
            onFocus={menu.open}
            placeholder="Search by Group Number, Group Name or Employer"
            ref={menu.triggerRef}
            value={searchString}
          />
          {menu.isOpen ? (
            <Menu
              className={`
                flex
                flex-col
                p-1
                max-h-[40vh]
                w-[800px]
                overflow-y-auto
                border-greyLighter
                bg-white
                border
                shadow-main
                rounded
              `}
              matchTriggerWidth={searchPlanOptions.length === 1}
              middleware={[flip({ crossAxis: true }), offset(MENU_OFFSET)]}
              onRequestClose={menu.close}
              placement="bottom-start"
              triggerRef={menu.triggerRef}
            >
              <ScrollableInfiniteQueryResult
                infiniteQuery={insurancePlansInfiniteQuery}
                loading={<LoadingContent containerClassName="min-h-screen col-span-full" />}
                loadMoreClassName="col-span-full"
                noResults={
                  <div className="flex flex-col col-span-full p-1 text-xs">
                    <span className="flex justify-center items-center h-8">
                      No plans found for {carrierName}
                    </span>
                    <CreateNewOption label="Create New Plan" onCreateNewPlan={onCreateNewPlan} />
                  </div>
                }
                scrollRef={scrollRef}
              >
                <TableGrid columnWidths={searchHeaders.map(({ width }) => width)}>
                  {searchHeaders.map((item) => (
                    <HeaderCell
                      className={cx("px-1 py-2 text-xxs font-sansSemiBold", item.className)}
                      border={false}
                      key={item.id}
                      size="slim"
                    >
                      {item.label}
                    </HeaderCell>
                  ))}
                  {searchPlanOptions.map((option, index) => {
                    const { data, label, value } = option;

                    return data ? (
                      <MenuRow
                        key={`${value}-${index}`}
                        onClick={() => {
                          onLinkPlan(value);
                        }}
                        values={[
                          data.groupNumber,
                          data.description,
                          data.groupName,
                          data.employerName,
                          data.patientCount,
                        ]}
                      />
                    ) : (
                      <CreateNewOption key={index} label={label} onCreateNewPlan={onCreateNewPlan} />
                    );
                  })}
                </TableGrid>
              </ScrollableInfiniteQueryResult>
            </Menu>
          ) : null}
        </div>
      )}
    </div>
  );
};
