import { FC } from "react";
import { MainContent } from "@libs/components/UI/MainContent";

import { PatientSnapshotLayout } from "components/UI/PatientSnapshotLayout";
import { usePatientAppointmentQueryState } from "contexts/PatientAppointmentContext";
import { MainAppHistoryProvider } from "components/Main/MainLinksContext";
import { LabCasesPage } from "./LabCasesPage";

export const LabCasesRoute: FC = () => {
  const {
    patientId,
    appointmentId,
    handleAppointmentSelected,
    handleAppointmentDeleted,
    handlePatientSelected,
  } = usePatientAppointmentQueryState();

  return (
    <MainAppHistoryProvider name="labCases">
      <MainContent>
        <PatientSnapshotLayout
          patientId={patientId}
          onDeleteAppointment={handleAppointmentDeleted}
          onSelectAppointment={handleAppointmentSelected}
          appointmentId={appointmentId}
        >
          <LabCasesPage onPatientIdSelected={handlePatientSelected} />
        </PatientSnapshotLayout>
      </MainContent>
    </MainAppHistoryProvider>
  );
};
