import { FC } from "react";
import { cx } from "@libs/utils/cx";
import { formatISODate } from "@libs/utils/date";
import { ReactComponent as Pregnant } from "@libs/assets/icons/pregnant-filled.svg";

interface Props {
  dueDate: string;
  className?: string;
}

export const PregnantBanner: FC<Props> = ({ className, dueDate }) => {
  return (
    <div
      className={cx(
        `bg-greyLightest
         rounded
         px-2.5
         py-1
         text-xs
         font-sansSemiBold
         text-red
         flex
         items-center`,
        className
      )}
    >
      <Pregnant className="w-4 h-4 mr-1" /> Patient is pregnant | Due Date {formatISODate(dueDate)}
    </div>
  );
};
