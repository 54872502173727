import { FC, useCallback, useMemo, useState } from "react";
import { useQueryClient } from "@tanstack/react-query";
import Skeleton from "react-loading-skeleton";
import {
  AppointmentVO,
  CreatePatientProcedureRequest,
  DentalProcedureVO,
  PatientProcedureVO,
  PatientToothVO,
  ProviderVO,
} from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { getQueryKey } from "@libs/utils/queries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent } from "@libs/components/UI/ModalComponents";
import { getDentalProceduresQuery, getPatientChartV2, getPatientProceduresQuery } from "api/charting/queries";
import { switchDentalProcedureOnPatientProcedure, updatePatientProcedure } from "api/charting/mutations";
import { handleError } from "utils/handleError";
import {
  DraftPatientProcedureRequest,
  SavePatientProcedureUpdate,
  stripSelectionsWithDentalProcedure,
} from "components/Charting/draftPatientProcedure";
import { EditPatientProcedureForm } from "components/Charting/EditPatientProceduresForm";
import { EditPatientProcedureFormDPSwitch } from "components/Charting/EditPatientProceduresFormDPSwitch";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { SkeletonInputField } from "components/UI/SkeletonInputField";

interface InnerProps {
  onRequestClose: Func;
  onSwitch: (data: CreatePatientProcedureRequest) => void;
  onSave: (patientProcedureUpdate: SavePatientProcedureUpdate) => void;
  patientProcedure: PatientProcedureVO;
  providers: ProviderVO[];
  validateOptions?: {
    eager?: boolean;
    prosthetic?: boolean;
    provider?: boolean;
  };
  targetTreatmentPlanUuid?: string;
  patientId: number;
  teeth: PatientToothVO[];
  dentalProcedures: DentalProcedureVO[];
  dentalProcedure: DentalProcedureVO;
  isSwitching: boolean;
  isUpdating: boolean;
  editStatus?: boolean;
}

const InnerEditPatientProcedureModal: FC<InnerProps> = ({
  onRequestClose,
  onSave,
  onSwitch,
  patientProcedure,
  providers,
  dentalProcedures,
  validateOptions,
  teeth,
  dentalProcedure,
  isSwitching,
  editStatus,
  isUpdating,
}) => {
  const [draftSwitch, setDraftSwitch] = useState<{
    dentalProcedure?: DentalProcedureVO;
    patientProcedure?: DraftPatientProcedureRequest;
  }>({});

  const handleSwitchDraftDentalProcedure = useCallback(
    (params?: { newDentalProcedure?: DentalProcedureVO; currentDraft?: DraftPatientProcedureRequest }) => {
      if (!params?.newDentalProcedure || !params.currentDraft) {
        setDraftSwitch({});
      } else {
        const stripped = stripSelectionsWithDentalProcedure(
          params.currentDraft,
          teeth,
          params.newDentalProcedure
        );

        // when switching dental procedures
        // the previous pre auth info no longer applies
        delete stripped.preAuthNumber;
        delete stripped.preAuthStatus;
        delete stripped.patientAmount;
        delete stripped.insuranceAmount;
        delete stripped.downgradeDentalProcedureId;
        stripped.downgradeDentalProcedure = false;
        setDraftSwitch({
          dentalProcedure: params.newDentalProcedure,
          patientProcedure: stripped,
        });
      }
    },
    [teeth]
  );

  const handleUpdateDraft = useCallback((updates: Partial<DraftPatientProcedureRequest>) => {
    setDraftSwitch((last) => {
      return {
        dentalProcedure: last.dentalProcedure,
        patientProcedure: {
          ...last.patientProcedure,
          ...updates,
        } as DraftPatientProcedureRequest,
      };
    });
  }, []);

  const commonProps = {
    onCancel: onRequestClose,
    onSwitchProcedure: handleSwitchDraftDentalProcedure,
    allDentalProcedures: dentalProcedures,
    patientProcedure,
    providers,
    teeth,
    validateOptions,
    editStatus,
  };

  return draftSwitch.dentalProcedure && draftSwitch.patientProcedure ? (
    <EditPatientProcedureFormDPSwitch
      {...commonProps}
      onSubmit={onSwitch}
      onUpdateDraft={handleUpdateDraft}
      dentalProcedure={draftSwitch.dentalProcedure}
      draftPatientProcedure={draftSwitch.patientProcedure}
      isSaving={isSwitching}
    />
  ) : (
    <EditPatientProcedureForm
      {...commonProps}
      onSubmit={onSave}
      dentalProcedure={dentalProcedure}
      isSaving={isUpdating}
    />
  );
};

interface Props {
  onRequestClose: Func;
  onSaved: (procedure: PatientProcedureVO) => void;
  patientProcedureId: number;
  patientProcedure?: PatientProcedureVO;
  validateOptions?: {
    eager?: boolean;
    prosthetic?: boolean;
    provider?: boolean;
  };
  targetTreatmentPlanUuid?: string;
  patientId: number;
  appointment?: AppointmentVO;
  editStatus?: boolean;
}

export const EditPatientProcedureModal: FC<Props> = ({
  onRequestClose,
  onSaved,
  patientId,
  appointment,
  validateOptions,
  patientProcedureId,
  patientProcedure,
  targetTreatmentPlanUuid,
  editStatus,
}) => {
  const { practiceId } = useAccount();
  const queryClient = useQueryClient();

  const [dentalProceduresQuery, patientProcedureQuery, providersQuery, chartV2Query] = useApiQueries([
    getDentalProceduresQuery({ args: { practiceId } }),
    getPatientProceduresQuery({
      args: { practiceId, patientId, ids: [patientProcedureId], pageSize: 1, pageNumber: 1 },
      queryOptions: { enabled: !patientProcedure },
    }),
    getPracticeProvidersQuery({ args: { practiceId } }),
    getPatientChartV2({
      args: { patientId, practiceId },
    }),
  ]);

  const thePatientProcedure = patientProcedure || patientProcedureQuery.data?.[0];

  const dentalProcedure = useMemo(() => {
    return dentalProceduresQuery.data?.find((dp) => dp.id === thePatientProcedure?.dentalProcedureId);
  }, [dentalProceduresQuery.data, thePatientProcedure]);

  const [updatePatientProcedureMutation, switchDentalProcedureOnPatientProcedureMutation] = useApiMutations([
    updatePatientProcedure,
    switchDentalProcedureOnPatientProcedure,
  ]);

  const handleSave = useCallback(
    (patientProcedureUpdate: SavePatientProcedureUpdate) => {
      updatePatientProcedureMutation.mutate(
        {
          practiceId,
          patientId,
          patientProcedureId,
          appointment,
          data: {
            ...patientProcedureUpdate,
            targetTreatmentPlanUuid,
          },
        },
        {
          onSuccess: (response) => {
            onSaved(response.data.data);

            const { status, creditToPractice } = response.data.data;

            if (
              thePatientProcedure &&
              thePatientProcedure.creditToPractice !== creditToPractice &&
              status === "DONE"
            ) {
              // Invalidate ledger entry for when credit to practice changes
              queryClient.invalidateQueries([
                getQueryKey("practices", "getLedgerEntryByTypeAndId"),
                {
                  patientId,
                  practiceId,
                  entryId: appointment ? String(appointment.id) : undefined,
                  entryType: "APPOINTMENT",
                },
              ]);
            }
          },
          onError: (error) => {
            handleError(error);
          },
        }
      );
    },
    [
      updatePatientProcedureMutation,
      practiceId,
      patientId,
      patientProcedureId,
      targetTreatmentPlanUuid,
      appointment,
      onSaved,
      thePatientProcedure,
      queryClient,
    ]
  );

  const handleSwitchDentalProcedure = useCallback(
    (data: CreatePatientProcedureRequest) => {
      switchDentalProcedureOnPatientProcedureMutation.mutate(
        {
          practiceId,
          patientId,
          appointment,
          patientProcedureId,
          data: {
            ...data,
            targetTreatmentPlanUuid,
          },
        },
        {
          onSuccess: (response) => {
            onSaved(response.data.data);
          },
          onError: (error) => {
            handleError(error);
          },
        }
      );
    },
    [
      switchDentalProcedureOnPatientProcedureMutation,
      practiceId,
      appointment,
      patientId,
      onSaved,
      patientProcedureId,
      targetTreatmentPlanUuid,
    ]
  );

  return (
    <Modal title="Edit Procedure" onClose={onRequestClose}>
      <QueryResult
        queries={[chartV2Query, dentalProceduresQuery, patientProcedureQuery]}
        loading={<LoadingSkeleton />}
      >
        {chartV2Query.data &&
        dentalProcedure &&
        thePatientProcedure &&
        dentalProceduresQuery.data &&
        providersQuery.data ? (
          <InnerEditPatientProcedureModal
            onRequestClose={onRequestClose}
            providers={providersQuery.data}
            patientId={patientId}
            teeth={chartV2Query.data}
            dentalProcedure={dentalProcedure}
            dentalProcedures={dentalProceduresQuery.data}
            patientProcedure={thePatientProcedure}
            isUpdating={updatePatientProcedureMutation.isLoading}
            isSwitching={switchDentalProcedureOnPatientProcedureMutation.isLoading}
            validateOptions={validateOptions}
            editStatus={editStatus}
            onSave={handleSave}
            onSwitch={handleSwitchDentalProcedure}
          />
        ) : null}
      </QueryResult>
    </Modal>
  );
};

const LoadingSkeleton: FC = () => (
  <ModalContent padding="lg">
    <div data-testid="loading-skeleton" className="grid grid-cols-4 gap-x-3 gap-y-5">
      <Skeleton className="h-16" containerClassName="col-span-4" />
      <SkeletonInputField />
      <SkeletonInputField />
      <SkeletonInputField />
      <SkeletonInputField />
      <SkeletonInputField />
      <SkeletonInputField />
      <SkeletonInputField />
      <SkeletonInputField />
    </div>
  </ModalContent>
);
