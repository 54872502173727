import { FC } from "react";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as RequestMoneyIcon } from "@libs/assets/icons/request-money.svg";
import { Button } from "@libs/components/UI/Button";
import { RoleGuardClick } from "components/Main/RoleGuard";
import { Text } from "./Text";

export const CollectPaymentButton: FC<{ onClick: Func }> = ({ onClick }) => {
  return (
    <RoleGuardClick domain="BILLING" action="COLLECT_MANAGE">
      <Button theme="link" onClick={onClick}>
        <CollectPaymentButtonContent />
      </Button>
    </RoleGuardClick>
  );
};

const CollectPaymentButtonContent: FC = () => {
  return (
    <span className="flex gap-x-2 items-center text-primaryTheme">
      <Icon SvgIcon={RequestMoneyIcon} size="lg" />
      <Text bold size="sm">
        Collect Payment
      </Text>
    </span>
  );
};
