import { FC } from "react";
import { WorktimeVO } from "@libs/api/generated-api";
import { formatISOTimeAsAmPm } from "@libs/utils/date";

interface Props {
  worktime: WorktimeVO;
  type: "in" | "out";
}

const cxStyles = {
  label: "font-sansSemiBold pr-1",
};

const NULL_VALUE = "N/A";

export const TimeSheetTablePopover: FC<Props> = ({ worktime, type }) => {
  return (
    <div className="text-xs">
      <div className="mb-2.5">
        <span className={cxStyles.label}>Original Clock {type} time:</span>
        {worktime.orignalTime ? formatISOTimeAsAmPm(worktime.orignalTime) : NULL_VALUE}
      </div>
      <div>
        <span className={cxStyles.label}>Edited Clock {type} time</span>
        {worktime.submittedTime ? formatISOTimeAsAmPm(worktime.submittedTime) : NULL_VALUE}
      </div>
      <div className="mb-2.5">
        <span className={cxStyles.label}>Comment:</span>
        {worktime.notes || NULL_VALUE}
      </div>
      <div>
        <span className={cxStyles.label}>Approved Clock {type} time:</span>
        {worktime.approvedTime ? formatISOTimeAsAmPm(worktime.approvedTime) : NULL_VALUE}
      </div>
      <div>
        <span className={cxStyles.label}>Comment:</span>
        {worktime.approvalNotes || NULL_VALUE}
      </div>
    </div>
  );
};
