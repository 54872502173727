export const cdtCodeToString = (code?: number | null, padRight = false) => {
  if (code === undefined || code === null) {
    return undefined;
  }

  let stringCode = `${code}`;
  const CODE_MAX_LENGTH = 4;

  while (stringCode.length < CODE_MAX_LENGTH) {
    stringCode = padRight ? `${stringCode}0` : `0${stringCode}`;
  }

  return `D${stringCode}`;
};
export const cdtCodeToNumber = (code?: string | null) => {
  if (code === undefined || code === null || code === "D") {
    return undefined;
  }

  return Number.parseInt(code.replace("D", ""), 10);
};
