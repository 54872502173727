import { FC, useState, useRef, useCallback } from "react";
import { flushSync } from "react-dom";

import { PatientListCriteria } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Button } from "@libs/components/UI/Button";
import { useAccount } from "@libs/contexts/AccountContext";
import { Flyover } from "components/UI/Flyover";
import { FlyoverHeader, FlyoverFooter } from "components/UI/FlyoverComponents";
import { TabText } from "components/UI/TabText";

import { FiltersFlyoverContent } from "components/Communications/Filters/FiltersFlyoverContent";
import { SavedFlyoverContent } from "components/Communications/Filters/SavedFlyoverContent";
import { ApplyMenuButton } from "components/Communications/Filters/ApplyMenuButton";
import { SaveFiltersModal } from "components/Communications/Filters/SaveFiltersModal";

import {
  getPatientListCriteriaSchema,
  getSanitizedPatientListCriteria,
} from "components/Communications/Filters/utils";

import { listSavedFilters } from "api/communications/queries";
import { getAllInsuranceCarriersQuery } from "api/practiceInsurance/queries";
import { createSavedFilter, deleteSavedFilter } from "api/communications/mutations";

import { useNotificationContext } from "contexts/NotificationsContext";

import { handleError } from "utils/handleError";
import { scrollToFirstError } from "utils/scrollToFirstError";

interface Props {
  patientListCriteria: PatientListCriteria;
  isApplyingFilters: boolean;
  onApplyFilters: (criteria: PatientListCriteria, options?: { onSuccess?: Func }) => void;
  onClose: Func;
}

export const FiltersFlyover: FC<Props> = ({
  patientListCriteria,
  isApplyingFilters,
  onApplyFilters,
  onClose,
}) => {
  const [draftPatientListCriteria, setDraftPatientListCriteria] = useState(patientListCriteria);
  const formsContainerRef = useRef<HTMLDivElement>(null);

  const { practiceId } = useAccount();
  const notification = useNotificationContext();

  const savedTab = useBoolean(false);
  const saveModal = useBoolean(false);
  const saving = useBoolean(false);

  const savedFiltersQuery = useInfiniteApiQuery(
    listSavedFilters({
      args: {
        practiceId,
        pageSize: PAGE_SIZE,
        pageNumber: 1,
      },
      queryOptions: {
        enabled: savedTab.isOn,
      },
    })
  );

  const [insuranceCarriersQuery] = useApiQueries([getAllInsuranceCarriersQuery({ args: { practiceId } })]);

  const [createSavedFilterMutation, deleteSavedFilterMutation] = useApiMutations([
    createSavedFilter,
    deleteSavedFilter,
  ]);

  const { validate, result: validationResult } = useValidation(
    draftPatientListCriteria,
    getPatientListCriteriaSchema(draftPatientListCriteria)
  );

  const handleApplyFilters = useCallback(() => {
    const result = flushSync(() => validate());

    if (result.$isValid) {
      onApplyFilters(getSanitizedPatientListCriteria(draftPatientListCriteria));
    } else {
      scrollToFirstError(formsContainerRef.current);
    }
  }, [validate, onApplyFilters, draftPatientListCriteria]);

  const handleRequestSaveFilters = useCallback(() => {
    const result = flushSync(() => validate());

    if (result.$isValid) {
      saveModal.on();
    } else {
      scrollToFirstError(formsContainerRef.current);
    }
  }, [validate, saveModal]);

  const handleSaveFilters = useCallback(
    async (name: string) => {
      saving.on();

      const updatedPatientListCriteria = getSanitizedPatientListCriteria(draftPatientListCriteria);

      try {
        await createSavedFilterMutation.mutateAsync({
          practiceId,
          data: {
            uuid: crypto.randomUUID(),
            name,
            filter: {
              type: "PATIENT_LIST",
              patientListCriteria: updatedPatientListCriteria,
            },
          },
        });

        onApplyFilters(updatedPatientListCriteria, {
          onSuccess: () => {
            saveModal.off();
            notification.handleInfo(`Your filter "${name}" was saved and applied.`);
          },
        });
      } catch (error) {
        handleError(error);
        saving.off();
      }
    },
    [
      createSavedFilterMutation,
      practiceId,
      draftPatientListCriteria,
      onApplyFilters,
      saveModal,
      notification,
      saving,
    ]
  );

  const handleDeleteSavedFilter = (savedFilterUuid: string, options: { onSuccess: Func }) => {
    deleteSavedFilterMutation.mutate(
      { practiceId, savedFilterUuid },
      { onSuccess: options.onSuccess, onError: handleError }
    );
  };

  return (
    <>
      <Flyover onClose={onClose}>
        {({ close }) => (
          <>
            <FlyoverHeader className="px-8" onClose={close} size="custom">
              <div className="flex items-center gap-x-6">
                <button role="tab" type="button" onClick={savedTab.toggle}>
                  <TabText className="-mx-4" paddingY="py-6" isSelected={savedTab.isOff} size="base">
                    Filters
                  </TabText>
                </button>
                <button role="tab" type="button" onClick={savedTab.toggle}>
                  <TabText className="-mx-4" paddingY="py-6" isSelected={savedTab.isOn} size="base">
                    Saved
                  </TabText>
                </button>
              </div>
            </FlyoverHeader>

            {savedTab.isOn ? (
              <SavedFlyoverContent
                savedFiltersQuery={savedFiltersQuery}
                onApplySavedFilter={onApplyFilters}
                onDeleteSavedFilter={handleDeleteSavedFilter}
                isDeletingSavedFilter={deleteSavedFilterMutation.isLoading}
              />
            ) : (
              <>
                <FiltersFlyoverContent
                  containerRef={formsContainerRef}
                  patientListCriteria={draftPatientListCriteria}
                  insuranceCarriersQuery={insuranceCarriersQuery}
                  validationResult={validationResult}
                  onUpdatePatientListCriteria={setDraftPatientListCriteria}
                />
                <FlyoverFooter>
                  <Button className="min-w-button" onClick={close} theme="secondary">
                    Cancel
                  </Button>
                  <ApplyMenuButton
                    className="min-w-button"
                    isApplyingFilters={isApplyingFilters}
                    onApplyFilters={handleApplyFilters}
                    onRequestSaveFilters={handleRequestSaveFilters}
                  />
                </FlyoverFooter>
              </>
            )}
          </>
        )}
      </Flyover>

      {saveModal.isOn ? (
        <SaveFiltersModal
          isSavingFilters={saving.isOn}
          onSaveFilters={handleSaveFilters}
          onClose={saveModal.off}
        />
      ) : null}
    </>
  );
};
