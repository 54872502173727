import { FC, HTMLProps } from "react";
import Skeleton from "react-loading-skeleton";
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

import { DocumentVO, FolderVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";

import { TreeViewFolder } from "./TreeViewFolder";

export type ExpandedFolders = Record<FolderVO["id"], boolean>;

interface TreeView extends HTMLProps<HTMLDivElement> {
  userId: number;
  practiceId: number;
  folders?: FolderVO[];
  expandedFolders: ExpandedFolders;
  isLoading: boolean;
  isFoldersLoading: boolean;
  selectedDocId: number | undefined;
  onFolderClick: (folder: FolderVO) => void;
  onFolderEdit: (folder: FolderVO) => void;
  onDocumentMove: (document: DocumentVO, destinationFolder: FolderVO) => void;
  onDocumentClick: (document: DocumentVO) => void;
  onFolderDelete: (folder: FolderVO) => void;
}

export const TreeView: FC<TreeView> = ({
  userId,
  practiceId,
  folders,
  expandedFolders,
  isLoading,
  isFoldersLoading,
  selectedDocId,
  onFolderClick,
  onFolderEdit,
  onDocumentMove,
  onDocumentClick,
  onFolderDelete,
}) => {
  const hasFolders = (folders ?? []).length > 0;

  return (
    <DndProvider backend={HTML5Backend}>
      <div
        className={cx(
          `flex
           flex-col
           gap-y-2
           p-3
           border-slate-200
           border-r
           text-sm
           overflow-y-auto`,
          isLoading && "opacity-30 relative"
        )}
      >
        {isFoldersLoading ? (
          <Skeleton count={10} className="h-10" />
        ) : hasFolders ? (
          folders?.map((folder) => (
            <TreeViewFolder
              key={folder.id}
              practiceId={practiceId}
              userId={userId}
              folder={folder}
              expanded={expandedFolders[folder.id]}
              selectedDocId={selectedDocId}
              onFolderClick={onFolderClick}
              onFolderEdit={onFolderEdit}
              onFolderDelete={onFolderDelete}
              onDocumentMove={onDocumentMove}
              onDocumentClick={onDocumentClick}
            />
          ))
        ) : (
          <div className="p-3">No Folders</div>
        )}
      </div>
    </DndProvider>
  );
};
