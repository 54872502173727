import { RefObject, useEffect } from "react";
import { EditorRef } from "react-email-editor";

import { useBoolean } from "@libs/hooks/useBoolean";

export const useEditorUpdates = (editorRef: RefObject<EditorRef>, onUpdate?: (data: unknown) => void) => {
  const ready = useBoolean(false);
  const isReady = ready.isOn;

  useEffect(() => {
    if (isReady && onUpdate && editorRef.current?.editor) {
      const { editor } = editorRef.current;

      editor.addEventListener("design:updated", onUpdate);

      return () => {
        editor.removeEventListener("design:updated");
      };
    }

    return undefined;
  }, [isReady, onUpdate, editorRef]);

  return {
    handleEditorReady: ready.on,
  };
};
