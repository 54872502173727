import Skeleton from "react-loading-skeleton";
import { cxTableStyles } from "components/Dashboard/Tables/cxTableStyles";

export const LoadingCells: React.FC<{
  rowCount?: number;
  columnCount: number;
}> = ({
  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  rowCount = 5,
  columnCount,
}) => {
  const count = columnCount * rowCount;

  return (
    <>
      {Array.from({ length: rowCount * columnCount }).map((_, i) => (
        <div className={cxTableStyles.cell(i === count - 1)} key={i}>
          <Skeleton containerClassName="w-full" className="h-3" />
        </div>
      ))}
    </>
  );
};
