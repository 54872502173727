import { FC, ReactNode } from "react";
import { cx } from "@libs/utils/cx";
import { MainContent } from "@libs/components/UI/MainContent";

interface Props {
  contentClassName?: string;
  className?: string;
  header: ReactNode;
  children?: ReactNode;
}
export const DashboardLayout: FC<Props> = ({ className, contentClassName, children, header }) => {
  return (
    <MainContent>
      <div className={cx("h-full flex flex-col bg-white border-b border-slate-400", className)}>
        {header}
        <div className={cx("w-full flex-1 min-h-0", contentClassName)}>{children}</div>
      </div>
    </MainContent>
  );
};
