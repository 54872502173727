import { Dispatch, SetStateAction, useEffect, useRef } from "react";
import { AppointmentSeriesVO, ProviderVO, RoomVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { SlotPreferences, SlotSelections } from "components/ScheduleAppointments/types";

import { AppointmentSlotsResult } from "components/ScheduleAppointments/AppointmentSlotsResult";
import { AppointmentSelectRoom } from "components/ScheduleAppointments/AppointmentSelectRoom";
import { AppointmentSlotFinderSearch } from "components/ScheduleAppointments/AppointmentSlotFinderSearch";
import { AppointmentSlotFinderPreferences } from "components/ScheduleAppointments/AppointmentSlotFinderPreferences";
import { useAppointmentDurationContext } from "components/ScheduleAppointments/AppointmentDurationContext";
import { SelectDentist } from "components/ScheduleAppointments/SelectDentist";
import { appointmentFormCxStyles } from "components/ScheduleAppointments/AppointmentForm";

interface Props {
  slotPreferences: SlotPreferences;
  rooms: RoomVO[];
  dentists: ProviderVO[];
  hygienists: ProviderVO[];
  selections: SlotSelections;
  availableRoomsQuery: ApiQueryResult<RoomVO[]>;
  openSlotsQuery: ApiQueryResult<AppointmentSeriesVO[]>;
  hasChangedPreferences: boolean;
  onFetchOpenSlots: Func;
  onUpdateSlotPreferences: (updates: Partial<SlotPreferences>) => void;
  onSelectedSlotUpdated: Dispatch<SetStateAction<SlotSelections>>;
  disabled: boolean;
}

export const AppointmentSlotFinderRow: React.FC<Props> = ({
  slotPreferences,
  dentists,
  hygienists,
  rooms,
  availableRoomsQuery,
  selections,
  openSlotsQuery,
  hasChangedPreferences,
  onFetchOpenSlots,
  onSelectedSlotUpdated,
  onUpdateSlotPreferences,
  disabled,
}) => {
  const duration = useAppointmentDurationContext();
  const slotPreferencesUI = useBoolean(false);
  const hasRequested = useRef(false);

  useEffect(() => {
    if (duration.read && !hasRequested.current) {
      hasRequested.current = true;
      onFetchOpenSlots();
    }
  }, [onFetchOpenSlots, duration.read]);

  return (
    <div className={appointmentFormCxStyles.row}>
      <div
        className={cx("relative", appointmentFormCxStyles.contentContainer, appointmentFormCxStyles.padding)}
      >
        <AppointmentSlotFinderSearch
          fromDate={slotPreferences.fromDate}
          isEditing={slotPreferencesUI.isOn}
          onToggleEdit={slotPreferencesUI.toggle}
          onUpdateSlotPreferences={onUpdateSlotPreferences}
        />
        {slotPreferencesUI.isOn ? (
          <AppointmentSlotFinderPreferences
            className="mt-4"
            dentists={dentists}
            disabled={disabled}
            hygienists={hygienists}
            onUpdateSlotPreferences={onUpdateSlotPreferences}
            slotPreferences={slotPreferences}
          />
        ) : null}

        <AppointmentSlotsResult
          hasChangedPreferences={hasChangedPreferences}
          onFetchOpenSlots={onFetchOpenSlots}
          onSelectedSlotUpdated={onSelectedSlotUpdated}
          openSlotsQuery={openSlotsQuery}
          selections={selections}
        />
        {disabled ? <div className="opacity-50 bg-white absolute inset-0 z-10" /> : null}
      </div>
      <VerticalDivider />
      <div className={cx(appointmentFormCxStyles.detailsContainer, appointmentFormCxStyles.padding)}>
        {selections.date && selections.startTime && selections.hygienistId ? (
          <SelectDentist
            {...selections}
            date={selections.date}
            durationInMinutes={duration.read}
            onSelectedSlotUpdated={onSelectedSlotUpdated}
          />
        ) : null}
        <AppointmentSelectRoom
          availableRoomsQuery={availableRoomsQuery}
          onSelectedSlotUpdated={onSelectedSlotUpdated}
          rooms={rooms}
          selections={selections}
          disabled={disabled}
        />
      </div>
    </div>
  );
};
