import React from "react";
import { useNavigate } from "react-router-dom";
import { pluralize } from "@libs/utils/pluralize";
import { Spinner } from "@libs/components/UI/Spinner";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { ReactComponent as SaveIcon } from "@libs/assets/icons/save.svg";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";

import { Backdrop } from "@libs/components/UI/Backdrop";
import { DarkRoomHeader } from "components/PatientProfile/Imaging/DarkRoomHeader";
import { MountIconButton } from "components/PatientProfile/Imaging/MountRoute/MountIconButton";
import { CompareImagingSortType, TeethSearchMode } from "utils/routing/patient";
import { getTeethLabel } from "components/PatientProfile/Imaging/MountRoute/image-utils";

type Props = {
  imageCount: number;
  teethSearchMode?: TeethSearchMode;
  teeth: string[];
  children?: React.ReactNode;
  onCloseEditor?: Func;
  onClickSave?: Func;
  isSaving?: boolean;
  onSortImages?: (sortCriteria: CompareImagingSortType) => void;
  sortBy?: CompareImagingSortType;
  editSelectionsUrl: string;
  disabled?: boolean;
  fromUrl?: string;
};

export const CompareImagesHeader: React.FC<Props> = ({
  imageCount,
  children,
  onCloseEditor,
  onClickSave,
  isSaving,
  editSelectionsUrl,
  teethSearchMode,
  teeth,
  fromUrl,
  disabled = false,
}) => {
  const navigate = useNavigate();

  return (
    <DarkRoomHeader className="text-white relative">
      <div
        className={`
          flex
          items-center
          gap-4
          text-sm
          text-center
          relative
          flex-1
          px-5
        `}
      >
        <div className="font-sansSemiBold">Image Compare</div>
        <VerticalDivider size="sm" theme="darkBackground" />
        <div className="flex gap-2 items-center">
          <div className="text-xs">
            {teethSearchMode
              ? `Images with ${getTeethLabel(teeth) ?? ""}`
              : `${imageCount} ${pluralize(imageCount, "selection", `selections`)}`}
          </div>
          <LinkIcon
            tooltip={{ content: "Edit selections", theme: "SMALL" }}
            theme="darkBackground"
            SvgIcon={EditIcon}
            to={editSelectionsUrl}
            replace
          />
        </div>
      </div>
      <div className="px-5">{children}</div>
      <div className="flex-1 flex justify-end items-center px-5 gap-6">
        {isSaving ? (
          <Spinner variant="light" animation="border" size="xs" layout="block" />
        ) : (
          <MountIconButton
            tooltip={{ content: "Save", theme: "SMALL" }}
            onClick={onClickSave}
            disabled={!onClickSave}
            SvgIcon={SaveIcon}
          />
        )}
        {fromUrl ? (
          <ButtonIcon
            tooltip={{ content: "Close", theme: "SMALL" }}
            theme="darkBackground"
            SvgIcon={CloseIcon}
            onClick={() => {
              if (fromUrl) {
                navigate(fromUrl);
              }
            }}
          />
        ) : (
          <div className="w-5" />
        )}
      </div>
      {disabled && <Backdrop onClick={onCloseEditor} bgColorClassName="bg-slate-800/50" />}
    </DarkRoomHeader>
  );
};
