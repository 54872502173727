import React from "react";
import { toMap } from "@libs/utils/array";
import { useQueryParams } from "hooks/useQueryParams";

export const usePaymentCollectedFilters = () => {
  const { query } = useQueryParams("dashboardPaymentCollected");
  const filters = query.filters;

  return React.useMemo(() => {
    const filterMap = toMap(filters, "type");

    return {
      paymentMethod: filterMap.paymentMethod?.values ?? [],
    };
  }, [filters]);
};
