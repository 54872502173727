import { FC, FormEventHandler, useMemo, useState } from "react";
import { addBusinessDays } from "date-fns";
import {
  AppointmentVO,
  DentalProcedureVO,
  LabCaseRequest,
  LabCaseReturnReasonVO,
  LabCaseVO,
  LabVO,
  PatientProcedureVO,
  PatientVO,
} from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { maxLength, minSetSize, required } from "@libs/utils/validators";
import { centsToDollars, dollarsToCents } from "@libs/utils/currency";
import { formatAsISODate, getLocalDate, nowInTimezone } from "@libs/utils/date";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { flattenPages } from "@libs/utils/queries";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";
import { Button } from "@libs/components/UI/Button";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { Pill } from "@libs/components/UI/Pill";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent, FlyoverFooter, FlyoverForm } from "components/UI/FlyoverComponents";
import { createLabCase, updateLabCase } from "api/lab/mutations";
import { handleError } from "utils/handleError";
import { getInfinitePatientProceduresQuery, getPatientProceduresQuery } from "api/charting/queries";
import { LoadingOverlayV2 } from "components/UI/LoadingOverlay";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { PatientSearchFormFieldSelect } from "components/LabCases/LabComponents";
import { getPatientAppointmentsQuery, getPatientSummary, searchForPatients } from "api/patients/queries";
import { useFocusOnce } from "hooks/useFocusOnce";
import { CollapsibleSection } from "components/UI/CollapsibleSection";
import { getLabCase, getLabCaseReturnReasons, getLabs } from "api/lab/queries";
import { useContinuedLoading } from "hooks/useContinuedLoading";
import { formatProviderNames } from "components/ScheduleAppointments/utils";
import { ProceduresPicker, SimpleProcedure } from "./ProceduresPicker";
import { FormSection } from "./FormSection";
import { DetailsFormSection, MAX_NOTE_LENGTH } from "./DetailsFormSection";
import { CostFormSection } from "./CostFormSection";

const makeProcedureOption = (proc: SimpleProcedure) => ({
  // The label is only used for searching, add anything in here that you want to be searchable.
  label: `${proc.cdtCode ?? ""} ${proc.description} ${proc.displayName} ${proc.procedureArea ?? ""}`,
  value: proc.id,
  procedure: proc,
});

const makeAppointmentOption = (appointment: AppointmentVO) => {
  return {
    // The label is only used for searching, add anything in here that you want to be searchable.
    label: `${appointment.date} ${formatProviderNames(appointment, "fullDisplayName")}`,
    value: appointment.id,
    appointment,
  };
};

const MAX_TRACKING_NUMBER_LENGTH = 255;

export const LabCaseFlyover: FC<{
  labCaseUuid?: LabCaseVO["uuid"];
  procedureId?: PatientProcedureVO["id"];
  patientId?: PatientVO["id"];
  onClose: Func;
  onSave: Func;
  // eslint-disable-next-line max-statements, complexity
}> = ({ labCaseUuid, procedureId, patientId, onSave, onClose }) => {
  const isEditing = labCaseUuid != null;
  const practice = useCurrentPractice();
  const [date, setDate] = useState<Date | null>(() => new Date());
  const [selectedPatientId, setSelectedPatientId] = useState<PatientVO["id"] | undefined>(patientId);
  const [selectedAppointmentIds, setSelectedAppointmentIds] = useState<Set<AppointmentVO["id"]>>(
    () => new Set()
  );
  const [selectedProcedureIds, setSelectedProcedureIds] = useState<Set<DentalProcedureVO["id"]>>(() =>
    procedureId ? new Set([procedureId]) : new Set()
  );
  const [selectProceduresFrom, setSelectProceduresFrom] = useState<"appointments" | "treatment-plan">(
    isEditing || procedureId ? "treatment-plan" : "appointments"
  );

  // TODO: Consider using a draft LabCaseRequest object to reduce the number states
  const [originalPatientId, setOriginalPatientId] = useState<PatientVO["id"] | undefined>(patientId);
  const [searchString, setSearchString] = useState<string>("");
  const debouncedSearchString = useDebouncedSearch(searchString);
  const [selectedLabUuid, setSelectedLabUuid] = useState<LabVO["uuid"]>();
  const [estimatedReceiveDate, setEstimatedReceiveDate] = useState<Date | null>(null);
  const [trackingNumber, setTrackingNumber] = useState<LabCaseVO["trackingNumber"]>();
  const [status, setStatus] = useState<LabCaseVO["status"]>("DRAFT");
  const [returnReasonUuid, setReturnReasonUuid] = useState<LabCaseReturnReasonVO["uuid"]>();
  const [shippingCost, setShippingCost] = useState<string>();
  const [labFee, setLabFee] = useState<string>();
  const [note, setNote] = useState<string>("");
  const [selectedLabContact, setSelectedLabContact] = useState<{ contactName: string; phone: string }>();

  // Focus the name field. Called after flyover is shown and its transition ends.
  const { ref: focusRef, handleFocus } = useFocusOnce<HTMLInputElement>();

  const [
    labCaseQuery,
    searchPatientsQuery,
    originalPatientQuery, // used only when editing or when patientId is provided, disabled otherwise
    patientAppointmentsQuery,
    providedPatientProcedureQuery, // used only when editing or when procedureId is provided, disabled otherwise
    labsQuery,
    returnReasonsQuery,
  ] = useApiQueries([
    getLabCase({
      args: { practiceId: practice.id, labCaseUuid: labCaseUuid as string },
      queryOptions: { enabled: isEditing },
    }),
    searchForPatients({
      args: {
        practiceId: practice.id,
        searchString: debouncedSearchString.search,
        pageSize: 10,
        pageNumber: 1,
      },
      queryOptions: {
        enabled: debouncedSearchString.search.length > 0 && selectedPatientId == null,
        keepPreviousData: true,
      },
    }),
    getPatientSummary({
      args: { practiceId: practice.id, patientId: originalPatientId as number },
      queryOptions: { enabled: Boolean(originalPatientId) },
    }),
    getPatientAppointmentsQuery({
      args: {
        practiceId: practice.id,
        patientId: selectedPatientId as number,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        dateMode: "FUTURE",
      },
      queryOptions: { enabled: Boolean(selectedPatientId && selectProceduresFrom === "appointments") },
    }),
    getPatientProceduresQuery({
      args: {
        practiceId: practice.id,
        patientId: selectedPatientId as number,
        ids: [procedureId as number],
        pageNumber: 1,
        pageSize: PAGE_SIZE,
      },
      queryOptions: { enabled: procedureId != null },
    }),
    getLabs({ args: { practiceId: practice.id } }),
    getLabCaseReturnReasons({ args: { practiceId: practice.id } }),
  ]);

  const patientPlannedAndScheduledProceduresQuery = useInfiniteApiQuery(
    getInfinitePatientProceduresQuery({
      args: {
        practiceId: practice.id,
        patientId: selectedPatientId as number,
        includeStatuses: ["PLANNED", "SCHEDULED"],
        pageNumber: 1,
        pageSize: PAGE_SIZE,
      },
      queryOptions: { enabled: Boolean(selectedPatientId && selectProceduresFrom === "treatment-plan") },
    })
  );

  const isInitializing = useContinuedLoading(
    labCaseQuery.isInitialLoading ||
      labsQuery.isInitialLoading ||
      returnReasonsQuery.isInitialLoading ||
      originalPatientQuery.isInitialLoading ||
      providedPatientProcedureQuery.isInitialLoading
  );

  const [createLabCaseMutation, updateLabCaseMutation] = useApiMutations([createLabCase, updateLabCase]);

  const validation = useValidation(
    {
      date,
      selectedPatientId,
      selectedProcedureIds,
      selectedAppointmentIds,
      selectedLabUuid,
      status,
      returnReasonUuid,
      note,
    },
    {
      date: { $validations: [{ $v: required, $error: "Date is required" }] },
      selectedPatientId: { $validations: [{ $v: required, $error: "Patient is required" }] },
      selectedAppointmentIds: {
        $validations: [
          {
            $v: minSetSize(1),
            $error: "Appointment is required",
            $ignore: status === "DRAFT" || selectProceduresFrom === "treatment-plan",
          },
        ],
      },
      selectedProcedureIds: {
        $validations: [
          {
            $v: minSetSize(1),
            $error: "Procedure is required",
            $ignore: status === "DRAFT" && !selectedAppointmentIds.size,
          },
        ],
      },
      selectedLabUuid: {
        $validations: [
          {
            $v: required,
            $error: "Lab is required",
            $ignore: status === "DRAFT",
          },
        ],
      },
      status: { $validations: [{ $v: required, $error: "Status is required" }] },
      returnReasonUuid: {
        $validations: [{ $ignore: status !== "RETURNED", $v: required, $error: "Return reason is required" }],
      },
      trackingNumber: {
        $validations: [
          {
            $v: maxLength(MAX_TRACKING_NUMBER_LENGTH),
            $error: `Tracking Number cannot exceed ${MAX_TRACKING_NUMBER_LENGTH} characters`,
          },
        ],
      },
      note: {
        $validations: [
          { $v: maxLength(MAX_NOTE_LENGTH), $error: `Note cannot exceed ${MAX_NOTE_LENGTH} characters` },
        ],
      },
    }
  );

  const handleSave: FormEventHandler = (e) => {
    e.preventDefault();

    if (!validation.validate().$isValid) {
      return;
    }

    if (selectedPatientId == null) {
      return;
    }

    const payload: LabCaseRequest = {
      patientId: selectedPatientId,
      date: date ? formatAsISODate(date) : formatAsISODate(nowInTimezone(practice.timezoneId)),
      patientProcedureIds: [...selectedProcedureIds],
      labUuid: selectedLabUuid,
      trackingNumber,
      estimatedReceiveDate: estimatedReceiveDate ? formatAsISODate(estimatedReceiveDate) : undefined,
      status,
      notes: note,
      shippingCost: shippingCost ? dollarsToCents(Number(shippingCost)) : undefined,
      labFee: labFee ? dollarsToCents(Number(labFee)) : undefined,
      returnReasonUuid: returnReasonUuid ?? undefined,
    };

    if (isEditing) {
      updateLabCaseMutation.mutate(
        { practiceId: practice.id, labCaseUuid, data: payload },
        { onSuccess: onSave, onError: handleError }
      );
    } else {
      createLabCaseMutation.mutate(
        { practiceId: practice.id, data: payload },
        {
          onSuccess: onSave,
          onError: handleError,
        }
      );
    }
  };

  const appointmentOptions = useMemo(() => {
    return patientAppointmentsQuery.data?.map((appointment) => makeAppointmentOption(appointment)) ?? [];
  }, [patientAppointmentsQuery.data]);

  // If an appointment is selected, select the procedures from the selected appointment. Otherwise
  // show the patient's procedures from his treatment plans.
  const procedureOptions = useMemo(() => {
    if (selectProceduresFrom === "appointments") {
      const appointments = patientAppointmentsQuery.data?.filter((appt) =>
        selectedAppointmentIds.has(appt.id)
      );

      return appointments?.flatMap((appt) => appt.patientProcedures.map(makeProcedureOption)) ?? [];
    }

    const patientPlannedAndScheduledProcedures = flattenPages(patientPlannedAndScheduledProceduresQuery.data);

    return [
      // put the original procedures first (if any, when editing), followed by
      // the planned and scheduled ones.
      ...(labCaseQuery.data?.patientProcedures.map(makeProcedureOption) ?? []), // when editing a lab case with procedures
      ...(providedPatientProcedureQuery.data?.map(makeProcedureOption) ?? []), // when a procedureId is provided
      ...(patientPlannedAndScheduledProcedures?.map(makeProcedureOption) ?? []), // all planned and scheduled procedures
    ];
  }, [
    labCaseQuery.data,
    providedPatientProcedureQuery.data,
    patientAppointmentsQuery.data,
    patientPlannedAndScheduledProceduresQuery.data,
    selectProceduresFrom,
    selectedAppointmentIds,
  ]);

  const labOptions = useMemo(() => {
    return (
      labsQuery.data?.map((lab) => ({
        label: `${lab.name}${lab.estimatedCaseTimeInDays ? ` (${lab.estimatedCaseTimeInDays} days)` : ""}`,
        value: lab.uuid,
      })) ?? []
    );
  }, [labsQuery.data]);

  const returnReasonOptions = useMemo(() => {
    return (
      returnReasonsQuery.data?.map((reason) => ({
        label: reason.reason,
        value: reason.uuid,
      })) ?? []
    );
  }, [returnReasonsQuery.data]);

  const handleResetProceduresPicker = () => {
    setSelectProceduresFrom("appointments");
    setSelectedAppointmentIds(new Set());
    setSelectedProcedureIds(new Set());
  };

  const handleSelectedLabUuidChange = (labUuid: LabVO["uuid"] | undefined) => {
    setSelectedLabUuid(labUuid);

    if (labUuid == null) {
      setEstimatedReceiveDate(null);
      setSelectedLabContact(undefined);

      return;
    }

    // Find lab's estimated receive date and set it.
    const lab = labsQuery.data?.find((l) => l.uuid === labUuid);
    const days = lab?.estimatedCaseTimeInDays ?? 0;

    // add days to today's date
    if (days) {
      setEstimatedReceiveDate(addBusinessDays(nowInTimezone(practice.timezoneId), days));
    }

    setSelectedLabContact({ contactName: lab?.contactPerson ?? "", phone: lab?.phone ?? "" });
  };

  useSyncOnce(
    (labCase) => {
      setDate(getLocalDate(labCase.date));
      setStatus(labCase.status);

      if (labCase.returnReason?.uuid) {
        setReturnReasonUuid(labCase.returnReason.uuid);
      }

      if (labCase.patient.id) {
        setOriginalPatientId(labCase.patient.id);
        setSelectedPatientId(labCase.patient.id);
      }

      if (labCase.patientProcedures.length) {
        setSelectedProcedureIds(new Set(labCase.patientProcedures.map((proc) => proc.id)));
      }

      if (labCase.lab?.uuid) {
        setSelectedLabUuid(labCase.lab.uuid);
      }

      if (labCase.trackingNumber) {
        setTrackingNumber(labCase.trackingNumber);
      }

      if (labCase.estimatedReceiveDate) {
        setEstimatedReceiveDate(new Date(labCase.estimatedReceiveDate));
      }

      if (labCase.shippingCost) {
        setShippingCost(String(centsToDollars(labCase.shippingCost)));
      }

      if (labCase.labFee) {
        setLabFee(String(centsToDollars(labCase.labFee)));
      }

      if (labCase.notes) {
        setNote(labCase.notes);
      }
    },
    labCaseQuery.isFetching ? undefined : labCaseQuery.data
  );

  return (
    <Flyover
      title={`${isEditing ? "Edit" : "New"} Lab Case`}
      onClose={onClose}
      size="md"
      onTransitionEnd={(e, { show }) => {
        show && handleFocus();
      }}
    >
      {/* eslint-disable-next-line complexity */}
      {({ close }) => (
        <FlyoverForm fieldLayout="labelOut" onSubmit={handleSave}>
          <FlyoverContent>
            <LoadingOverlayV2 opaque={isEditing} isLoading={isInitializing}>
              <div className="flex flex-col gap-y-6">
                <FormSection cols="2">
                  {originalPatientId ? (
                    <div className="flex flex-col gap-y-3">
                      <FormFieldLabel content="Patient" className="text-xs font-sansSemiBold" />
                      <div className="text-xs">{originalPatientQuery.data?.name.fullDisplayName}</div>
                    </div>
                  ) : (
                    <PatientSearchFormFieldSelect
                      ref={focusRef}
                      required
                      error={validation.result.selectedPatientId.$error}
                      searchString={searchString}
                      isSearching={
                        searchPatientsQuery.isFetching ||
                        (debouncedSearchString.isWaiting && selectedPatientId == null)
                      }
                      patientSearchResults={searchPatientsQuery.data ?? []}
                      onSearchChange={(search) => {
                        setSelectedPatientId(undefined);
                        setSearchString(search);
                      }}
                      onPatientSelect={(patient) => {
                        setSelectedPatientId(patient.id);
                        setSearchString(patient.name.fullDisplayName);
                        handleResetProceduresPicker();
                      }}
                      onClear={() => {
                        setSelectedPatientId(undefined);
                        setSearchString("");
                        // Re-focus input after clearing its value.
                        handleFocus(true);
                        handleResetProceduresPicker();
                      }}
                    />
                  )}
                  <FormFieldSelectMenusDatepicker
                    label="Date"
                    required={true}
                    selected={date}
                    error={validation.result.date.$error}
                    onChange={setDate}
                  />
                </FormSection>
                {procedureId ? (
                  <div className="flex flex-col gap-y-3">
                    <FormFieldLabel content="Procedures" className="text-xs font-sansSemiBold" />
                    <div className="text-xs flex items-center gap-x-2">
                      {providedPatientProcedureQuery.data?.map((proc) => (
                        <Pill key={proc.id} className="w-fit">
                          {proc.displayName}
                        </Pill>
                      ))}
                    </div>
                  </div>
                ) : (
                  <CollapsibleSection
                    adjustVerticalGap
                    isOpen={selectedPatientId != null}
                    className="col-span-full"
                  >
                    <ProceduresPicker
                      required={status !== "DRAFT"}
                      proceduresInfiniteQuery={patientPlannedAndScheduledProceduresQuery}
                      isLoadingAppointments={patientAppointmentsQuery.isFetching}
                      isLoadingPatientProcedures={
                        patientPlannedAndScheduledProceduresQuery.isFetching ||
                        providedPatientProcedureQuery.isInitialLoading ||
                        labCaseQuery.isInitialLoading
                      }
                      selectProceduresFrom={selectProceduresFrom}
                      appointmentOptions={appointmentOptions}
                      procedureOptions={procedureOptions}
                      selectedAppointmentIds={[...selectedAppointmentIds]}
                      selectedProcedureIds={[...selectedProcedureIds]}
                      onSelectProceduresFromChange={(value) => {
                        setSelectProceduresFrom(value);
                        setSelectedAppointmentIds(new Set());
                        setSelectedProcedureIds(new Set());
                      }}
                      onAppointmentsChange={(ids) => {
                        setSelectedAppointmentIds(new Set(ids));
                        setSelectedProcedureIds(new Set());
                      }}
                      onProceduresChange={(ids) => setSelectedProcedureIds(new Set(ids))}
                      errors={{
                        selectedProcedureIds: validation.result.selectedProcedureIds.$error,
                        selectedAppointmentIds: validation.result.selectedAppointmentIds.$error,
                      }}
                    />
                  </CollapsibleSection>
                )}

                <DetailsFormSection
                  labOptions={labOptions}
                  labContact={selectedLabContact?.contactName}
                  labPhone={selectedLabContact?.phone}
                  returnReasonOptions={returnReasonOptions}
                  selectedLabUuid={selectedLabUuid}
                  trackingNumber={trackingNumber}
                  estimatedReceiveDate={estimatedReceiveDate}
                  status={status}
                  returnReasonUuid={returnReasonUuid}
                  note={note}
                  errors={{
                    lab: validation.result.selectedLabUuid.$error,
                    trackingNumber: validation.result.trackingNumber.$error,
                    returnReason: validation.result.returnReasonUuid.$error,
                    note: validation.result.note.$error,
                  }}
                  onSelectedLabUuidChange={handleSelectedLabUuidChange}
                  onEstimatedReceiveDateChange={setEstimatedReceiveDate}
                  onTrackingNumberChange={setTrackingNumber}
                  onStatusChange={(value) => {
                    setStatus(value);

                    if (value !== "RETURNED") {
                      setReturnReasonUuid(undefined);
                    }
                  }}
                  onReturnReasonUuidChange={setReturnReasonUuid}
                  onNoteChange={setNote}
                />
                <CostFormSection
                  shippingCost={shippingCost}
                  onShippingCostChange={setShippingCost}
                  labFee={labFee}
                  onLabFeeChange={setLabFee}
                />
              </div>
            </LoadingOverlayV2>
          </FlyoverContent>
          <FlyoverFooter>
            <Button className="min-w-button" theme="secondary" onClick={close}>
              Cancel
            </Button>
            <Button
              disabled={
                !(validation.result.$isValid ?? true) ||
                createLabCaseMutation.isLoading ||
                updateLabCaseMutation.isLoading ||
                isInitializing
              }
              className="min-w-button"
              type="submit"
            >
              {isEditing ? "Save" : "Create"}
            </Button>
          </FlyoverFooter>
        </FlyoverForm>
      )}
    </Flyover>
  );
};
