import { FC, ChangeEvent, useState, useMemo } from "react";

import { FormTaskVO, PatientSummaryVO } from "@libs/api/generated-api";
import { UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { TableGrid, HeaderCell } from "@libs/components/UI/GridTableComponents";

import { PatientFormActionRow } from "components/PatientProfile/Forms/PatientFormActionRow";
import { PatientFormRow } from "components/PatientProfile/Forms/PatientFormRow";

const tableHeaders = [
  { id: "checkbox", width: "min-content" },
  { id: "name", label: "Name", width: "1fr" },
  { id: "apptDate", label: "Appt Date", width: "10rem" },
  { id: "lastSent", label: "Last Sent", width: "10rem" },
  { id: "lastSubmitted", label: "Last Submitted", width: "10rem" },
  { id: "inPerson", label: "In-Person", width: "10rem" },
  { id: "status", label: "Status", width: "10rem" },
  { id: "menu", width: "3.25rem" },
];

interface Props {
  patient: PatientSummaryVO;
  formTasksQuery: UseInfiniteApiListQueryResult<FormTaskVO>;
  consentFolderId: number;
}

export const PatientFormsTable: FC<Props> = ({ patient, formTasksQuery, consentFolderId }) => {
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: formTasksQuery });
  const [selectedFormTaskUuids, setSelectedFormTaskUuids] = useState<Set<FormTaskVO["uuid"]>>(new Set());
  const formTasks = useFlattenPages(formTasksQuery.data);

  const hasSelectedFormTasks = selectedFormTaskUuids.size > 0;

  const selectedFormTasks = useMemo(
    () => formTasks?.filter((ft) => selectedFormTaskUuids.has(ft.uuid)) ?? [],
    [formTasks, selectedFormTaskUuids]
  );

  const handleSelectFormTask = (e: ChangeEvent<HTMLInputElement>) => {
    const { checked, value } = e.target;

    if (checked) {
      setSelectedFormTaskUuids((last) => new Set([...last, value]));
    } else {
      setSelectedFormTaskUuids((last) => new Set([...last].filter((selectedUuid) => selectedUuid !== value)));
    }
  };

  return (
    <PersistScrollPosition ref={rootRef} id="patient-forms-table" className="h-full overflow-y-auto">
      <ScrollableInfiniteQueryResult
        scrollRef={scrollRef}
        infiniteQuery={formTasksQuery}
        loading={<LoadingContent className="rounded-none" />}
        loadMore={<LoadingContent className="rounded-none" />}
      >
        <TableGrid columnWidths={tableHeaders.map(({ width }) => width)}>
          <div className="col-span-full sticky top-0 z-10">
            <PatientFormActionRow
              patient={patient}
              formTasks={selectedFormTasks}
              disabled={!hasSelectedFormTasks}
            />

            <TableGrid columnWidths={tableHeaders.map(({ width }) => width)}>
              {tableHeaders.map(({ id, label }) => (
                <HeaderCell key={id} size="medium" sticky={false}>
                  {label ?? <span className="w-4" />}
                </HeaderCell>
              ))}
            </TableGrid>
          </div>

          {formTasks?.map((formTask) => (
            <PatientFormRow
              key={formTask.uuid}
              patient={patient}
              formTask={formTask}
              isSelected={selectedFormTaskUuids.has(formTask.uuid)}
              onSelectFormTask={handleSelectFormTask}
              consentFolderId={consentFolderId}
            />
          ))}
        </TableGrid>
      </ScrollableInfiniteQueryResult>
    </PersistScrollPosition>
  );
};
