import { FC, FormEvent, useRef, useCallback, useMemo, useState } from "react";
import { flushSync } from "react-dom";

import { produce } from "immer";
import { CreateEmployeeRequest, EmployeeJobTitleV2VO, RoleVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Button } from "@libs/components/UI/Button";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { CurrencyInputOnChangeValues } from "react-currency-input-field";
import { isDefined } from "@libs/utils/types";
import { getCreateEmployeeSchema } from "components/Employee/schema";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { EmployeeCategoryOptions, EmploymentTypeOptions } from "components/Employee/data";
import { TooltipLabel } from "components/UI/TooltipLabel";
import { scrollToFirstError } from "utils/scrollToFirstError";
import { FlyoverContent, FlyoverFooter, FlyoverForm, FlyoverHeader } from "components/UI/FlyoverComponents";
import { Divider } from "components/UI/Divider";
import { CompensationField } from "components/Employee/CompensationField";
import { FormFieldFlsaSelect } from "components/Employee/FormFieldFlsaSelect";
import { Section } from "components/EmployeeProfile/Section";
import { sortJobTitles } from "components/Employee/utils";

interface CreateEmployeeFormProps {
  onSubmit: (request: CreateEmployeeRequest) => void;
  onRequestClose: Func;
  assignableRoles: RoleVO[];
  formId: string;
  isSaving: boolean;
  isPayrollSetup: boolean;
  jobTitles: EmployeeJobTitleV2VO[];
}

type DraftCreateEmployee = Omit<CreateEmployeeRequest, "roleV2Id"> & {
  roleV2Id?: number;
};

export const CreateEmployeeForm: FC<CreateEmployeeFormProps> = ({
  formId,
  onSubmit,
  onRequestClose,
  isSaving,
  assignableRoles,
  isPayrollSetup,
  jobTitles,
}) => {
  const jobTitleOptions = useMemo(
    () => sortJobTitles(jobTitles).map((jt) => ({ value: jt.key, label: jt.name })),
    [jobTitles]
  );
  const roleOptions = useMemo(
    () => assignableRoles.map((role) => ({ value: role.roleId, label: role.name })),
    [assignableRoles]
  );

  const formContainerRef = useRef<HTMLDivElement>(null);
  const [amount, setAmount] = useState<Omit<CurrencyInputOnChangeValues, "formatted">>({
    value: "",
    float: 0,
  });
  const [draftEmployee, setDraftEmployee] = useState<DraftCreateEmployee>({
    personalDetails: {
      firstName: "",
      lastName: "",
    },
    contactDetails: {
      workEmail: "",
    },
    employmentDetails: {
      excludeFromPayroll: true,
      employeeCategory: "W2_EMPLOYEE",
      employmentType: "FULL_TIME",
      compensationDetails: {
        compensationType: "FLAT_RATE",
      },
    },
  });

  const isExcludedFromPayroll = Boolean(draftEmployee.employmentDetails.excludeFromPayroll);

  const schema = useMemo(
    () =>
      getCreateEmployeeSchema({
        isExcludedFromPayroll,
        period: draftEmployee.employmentDetails.compensationDetails?.period,
        amount: draftEmployee.employmentDetails.compensationDetails?.amount,
      }),
    [isExcludedFromPayroll, draftEmployee.employmentDetails.compensationDetails]
  );

  const { result, validate } = useValidation(draftEmployee, schema);

  const handleChange = useCallback((updater: (draft: DraftCreateEmployee) => void) => {
    setDraftEmployee((last) => produce(last, (editable) => void updater(editable)));
  }, []);

  const handleAmountChange = useCallback(
    (newAmount: CurrencyInputOnChangeValues | undefined) => {
      handleChange((draft) => {
        if (draft.employmentDetails.compensationDetails) {
          draft.employmentDetails.compensationDetails.amount = isDefined(newAmount?.float)
            ? newAmount.float
            : undefined;
        }
      });
      setAmount(newAmount ?? { value: "", float: 0 });
    },
    [handleChange]
  );

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const validationResult = flushSync(() => validate());

      if (validationResult.$isValid) {
        let data = draftEmployee;

        if (draftEmployee.employmentDetails.excludeFromPayroll) {
          data = produce(draftEmployee, (draft) => {
            delete draft.employmentDetails.compensationDetails;
            delete draft.employmentDetails.employeeCategory;
            delete draft.employmentDetails.employmentType;
            delete draft.employmentDetails.startDate;
            delete draft.employmentDetails.flsaStatus;
          });
        }

        onSubmit(data as CreateEmployeeRequest);
      } else {
        scrollToFirstError(formContainerRef.current);
      }
    },
    [onSubmit, validate, draftEmployee]
  );

  const payrollFieldProps = {
    required: !isExcludedFromPayroll,
    disabled: isExcludedFromPayroll,
  };

  return (
    <FlyoverForm id={formId} onSubmit={handleSubmit} fieldLayout="labelOut">
      <FlyoverHeader size="sm" onClose={onRequestClose}>
        Add Employee
      </FlyoverHeader>
      <FlyoverContent containerRef={formContainerRef}>
        <Section title="Personal">
          <FormFieldInput
            label="First Name"
            required
            error={result.personalDetails.firstName.$error}
            value={draftEmployee.personalDetails.firstName}
            onChange={(e) => handleChange((draft) => (draft.personalDetails.firstName = e.target.value))}
          />
          <FormFieldInput
            label="Last Name"
            required
            error={result.personalDetails.lastName.$error}
            value={draftEmployee.personalDetails.lastName}
            onChange={(e) => handleChange((draft) => (draft.personalDetails.lastName = e.target.value))}
          />
          <FormFieldInput
            type="email"
            required
            label={
              <TooltipLabel
                tooltip={{
                  content: "This email address will receive the invite and be used to log in to Archy.",
                }}
              >
                Work Email
              </TooltipLabel>
            }
            error={result.contactDetails.workEmail.$error}
            value={draftEmployee.contactDetails.workEmail}
            onChange={(e) => handleChange((draft) => (draft.contactDetails.workEmail = e.target.value))}
          />
        </Section>
        <Divider className="my-7 border-dashed" />
        <Section title="Employment">
          <FormFieldSelect
            label="Job Type"
            display="label"
            required
            value={draftEmployee.employmentDetails.jobTitleKey}
            options={jobTitleOptions}
            error={result.employmentDetails.jobTitleKey.$error}
            onChange={(option) => {
              if (option) {
                handleChange((draft) => {
                  draft.employmentDetails.jobTitleKey = option.value;
                  draft.employmentDetails.jobTitle = option.value === "UNKNOWN" ? "" : option.label;
                });
              }
            }}
          />
          <FormFieldInput
            label="Job Title"
            value={draftEmployee.employmentDetails.jobTitle}
            onChange={(e) => handleChange((draft) => (draft.employmentDetails.jobTitle = e.target.value))}
          />

          <FormFieldSelect
            label={
              <TooltipLabel
                tooltip={{
                  content:
                    "An employees role dictates which areas of Archy they can access. These can be modified in Roles.",
                }}
              >
                Role
              </TooltipLabel>
            }
            options={roleOptions}
            value={draftEmployee.roleV2Id}
            onItemSelected={(value) => {
              return handleChange((draft) => {
                draft.roleV2Id = value;
              });
            }}
            required
            error={result.roleV2Id.$error}
          />

          <FormFieldSelectMenusDatepicker
            label={
              <TooltipLabel
                tooltip={{ content: "Date for when this employee will be able to sign in to Archy." }}
              >
                Access Date
              </TooltipLabel>
            }
            placeholderText="MM/DD/YYYY"
            selected={
              draftEmployee.employmentDetails.accessDate
                ? getLocalDate(draftEmployee.employmentDetails.accessDate)
                : null
            }
            onChange={(newDate) =>
              handleChange(
                (draft) => (draft.employmentDetails.accessDate = newDate ? formatAsISODate(newDate) : "")
              )
            }
            error={result.employmentDetails.accessDate.$error}
            required
          />
        </Section>
        {isPayrollSetup ? (
          <>
            <Divider className="my-7 border-dashed" />
            <Section title="Payroll">
              <div>
                <div className="font-sansSemiBold text-xs mb-2">Include in Archy Payroll</div>
                <Checkbox
                  className="p-1"
                  checked={!draftEmployee.employmentDetails.excludeFromPayroll}
                  onChange={(e) =>
                    handleChange((draft) => {
                      draft.employmentDetails.excludeFromPayroll = !e.target.checked;
                    })
                  }
                >
                  Yes
                </Checkbox>
              </div>

              <FormFieldSelectMenusDatepicker
                label="Start Date"
                {...payrollFieldProps}
                error={result.employmentDetails.startDate.$error}
                placeholderText="MM/DD/YYYY"
                selected={
                  draftEmployee.employmentDetails.startDate
                    ? getLocalDate(draftEmployee.employmentDetails.startDate)
                    : null
                }
                onChange={(newDate) =>
                  handleChange(
                    (draft) => (draft.employmentDetails.startDate = newDate ? formatAsISODate(newDate) : "")
                  )
                }
              />
              <FormFieldSelect
                label="Employee Category"
                value={draftEmployee.employmentDetails.employeeCategory}
                {...payrollFieldProps}
                options={EmployeeCategoryOptions}
                error={result.employmentDetails.employeeCategory.$error}
                onItemSelected={(value) =>
                  handleChange((draft) => (draft.employmentDetails.employeeCategory = value))
                }
              />
              <FormFieldSelect
                label="Employee Type"
                value={draftEmployee.employmentDetails.employmentType}
                {...payrollFieldProps}
                options={EmploymentTypeOptions}
                error={result.employmentDetails.employmentType.$error}
                onItemSelected={(value) =>
                  handleChange((draft) => (draft.employmentDetails.employmentType = value))
                }
              />
              <CompensationField
                onAmountChange={(val, name, values) => handleAmountChange(values)}
                onPeriodChange={(val) => {
                  handleChange((draft) => {
                    if (draft.employmentDetails.compensationDetails) {
                      draft.employmentDetails.compensationDetails.period = val;
                    }
                  });
                }}
                isExcludedFromPayroll={isExcludedFromPayroll}
                amount={amount.value}
                period={draftEmployee.employmentDetails.compensationDetails?.period}
                isEditing={true}
                periodError={result.employmentDetails.compensationDetails.period.$error}
                amountError={result.employmentDetails.compensationDetails.amount.$error}
              />
              <FormFieldFlsaSelect
                value={draftEmployee.employmentDetails.flsaStatus}
                error={result.employmentDetails.flsaStatus.$error}
                {...payrollFieldProps}
                onItemSelected={(value) =>
                  handleChange((draft) => (draft.employmentDetails.flsaStatus = value))
                }
              />
            </Section>
          </>
        ) : null}
      </FlyoverContent>
      <FlyoverFooter>
        <Button size="medium" className="min-w-button" theme="secondary" onClick={onRequestClose}>
          Cancel
        </Button>
        <AsyncButton size="medium" className="min-w-button" type="submit" form={formId} isLoading={isSaving}>
          Create
        </AsyncButton>
      </FlyoverFooter>
    </FlyoverForm>
  );
};
