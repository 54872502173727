import React from "react";
import { useNavigate } from "react-router-dom";
import { Icon } from "@libs/components/UI/Icon";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { ReactComponent as SettingIcon } from "@libs/assets/icons/settings.svg";
import { UseCameraCaptureState } from "components/ImageCapturing/useCameraCapture";
import { NewImagePopoverButton } from "components/PatientProfile/Imaging/PatientMountsList/NewImagePopoverButton";
import { paths } from "utils/routing/paths";
import { AISUpdateWarning } from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/AISUpdateWarning";

type Props = {
  cameraCapture: UseCameraCaptureState;
  patientId: number;
  onClickUploadOfflineImages: () => void;
};

export const Header: React.FC<Props> = ({ cameraCapture, patientId, onClickUploadOfflineImages }) => {
  const navigate = useNavigate();

  const sensorDeviceListClick = (item: { deviceId: string }) => {
    navigate(
      paths.newMount(
        { patientId },
        {
          deviceId: item.deviceId,
          from: "x-ray",
        }
      ),
      { replace: true }
    );
  };

  return (
    <div
      className={`
        flex-none
        px-6
        h-14
        flex
        items-center
        justify-between
        border-b
        border-b-greyLighter
        sticky
        bg-white
        z-10
        top-0
        left-0
        w-full
      `}
    >
      <div className="flex items-center">
        <div className="filter-status-div font-sansSemiBold mr-2">Images</div>
        <NewImagePopoverButton
          onSensorClick={sensorDeviceListClick}
          cameraCapture={cameraCapture}
          patientId={patientId}
          onClickUploadOfflineImages={onClickUploadOfflineImages}
          onCameraClick={(item) => {
            navigate(
              paths.newMount(
                { patientId },
                {
                  deviceId: item.deviceId,
                  from: "camera",
                }
              ),
              { replace: true }
            );
          }}
          onImportClick={() => {
            navigate(
              paths.newMount(
                {
                  patientId,
                },
                {
                  from: "import",
                }
              ),
              { replace: true }
            );
          }}
        />
      </div>
      <div className="flex gap-2">
        <AISUpdateWarning headerMode />
        <ButtonInternalLink
          className="flex items-center text-primaryTheme"
          to={paths.imagingSettings({ patientId })}
          theme="link"
          replace
        >
          <Icon
            SvgIcon={SettingIcon}
            size="md"
            theme="primary"
            tooltip={{
              theme: "SMALL",
              content: "Imaging Settings",
            }}
          />
        </ButtonInternalLink>
      </div>
    </div>
  );
};
