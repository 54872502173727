import React, { FC } from "react";
import { useNavigate } from "react-router-dom";
import designConfig from "@libs/design.config";
import { createSelectStyles } from "@libs/components/UI/selectStyles";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { AppointmentDrilldownType, DashboardQuestion } from "utils/routing/dashboard";
import { paths } from "utils/routing/paths";
import {
  ACTIVE_NO_NEXT_APPT_EXCLUDE_HYGIENE_FILTER,
  ACTIVE_NO_NEXT_APPT_FILTER,
} from "components/Dashboard/utils/filters";
import { isRestrictedReportingAccess } from "components/Dashboard/hooks/useReportPermissions";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { checkPermission } from "components/Roles/roleUtils";

interface Props {
  question: DashboardQuestion;
  apptType?: AppointmentDrilldownType;
}

type DropdownValue =
  | "practice-production"
  | "payment-outstanding"
  | "payment-collected"
  | "patient-wallet"
  | "patients-treated"
  | "appointments-completed"
  | "appointments-comprehensive"
  | "appointments-hygiene"
  | "appointments-emergency"
  | "adjustments"
  | "daily-report"
  | "recall"
  | "unscheduled-treatment"
  | "referrals"
  | "payroll-hours-worked";
type DropdownOption = {
  value: DropdownValue;
  label: string;
  apptType?: AppointmentDrilldownType;
};
export const SelectReportingQuestion: FC<Props> = ({ question, apptType }) => {
  const currentUser = useCurrentUser();
  const isRestricted = isRestrictedReportingAccess(currentUser.roleV2);
  const questionOptions: GroupedSelectOptions<string, DropdownOption> = React.useMemo(() => {
    const options: GroupedSelectOptions<string, DropdownOption> = [
      ...(checkPermission(currentUser.roleV2, "REPORTING", "ACCESS_FINANCIAL").isPermitted
        ? [
            {
              label: "Financial",
              options: [
                {
                  value: "practice-production" as const,
                  label: "How much did the practice produce?",
                },
                {
                  value: "payment-collected" as const,
                  label: "How much payment have I collected?",
                },
                {
                  value: "payment-outstanding" as const,
                  label: "What is the aging of my outstanding collections?",
                },
                {
                  value: "adjustments" as const,
                  label: "What are the total production and collection adjustments?",
                },
                {
                  value: "daily-report" as const,
                  label: "What are all the ledger entries for a specific day?",
                },
                {
                  value: "patient-wallet" as const,
                  label: "What is the balance of each patient wallet?",
                },
              ],
            },
          ]
        : []),
      ...(checkPermission(currentUser.roleV2, "REPORTING", "ACCESS_OPERATIONAL").isPermitted
        ? [
            {
              label: "Operational",
              options: [
                {
                  value: "patients-treated" as const,
                  label: "How many patients did the practice treat?",
                },
                {
                  value: "appointments-completed" as const,
                  label: "How many appointments were completed?",
                },
                {
                  value: "appointments-comprehensive" as const,
                  label: "How many comprehensive exams were conducted?",
                },
                {
                  value: "appointments-emergency" as const,
                  label: "How many emergency exams were conducted?",
                },
                {
                  value: "unscheduled-treatment" as const,
                  label: "Which patients have unscheduled treatment?",
                },
                {
                  value: "referrals" as const,
                  label: "How did new patients hear about the practice?",
                },
              ],
            },
          ]
        : []),
      ...(checkPermission(currentUser.roleV2, "REPORTING", "ACCESS_HYGIENE").isPermitted
        ? [
            {
              label: "Hygiene",
              options: [
                {
                  value: "appointments-hygiene" as const,
                  label: "How many hygiene appointments were completed?",
                },
                {
                  value: "recall" as const,
                  label: "Who are my patients with no recall appointment scheduled?",
                },
              ],
            },
          ]
        : []),
      ...(checkPermission(currentUser.roleV2, "REPORTING", "ACCESS_ADMINISTRATIVE").isPermitted
        ? [
            {
              label: "Administrative",
              options: isRestricted
                ? []
                : [
                    {
                      value: "payroll-hours-worked" as const,
                      label: "Payroll: How many hours have my employees worked?",
                    },
                  ],
            },
          ]
        : []),
    ].filter((group) => group.options.length > 0);
    let totalCount = 0;

    return options.map((item) => ({
      label: item.label,
      options: item.options.map((option) => {
        totalCount += 1;

        return {
          ...option,
          label: `${totalCount} - ${option.label}`,
        };
      }),
    }));
  }, [currentUser.roleV2, isRestricted]);

  const navigate = useNavigate();
  const onChangeQuestion = React.useCallback(
    // eslint-disable-next-line complexity
    (selectedQuestion: DropdownOption) => {
      const questionSelected = selectedQuestion.value;
      let path: string | undefined = undefined;

      switch (questionSelected) {
        case "payment-collected": {
          path = paths.dashboardPaymentCollected({ tableTab: "payment-methods" });
          break;
        }
        case "payment-outstanding": {
          path = paths.dashboardPaymentOutstanding({ tableTab: "carriers" });
          break;
        }
        case "patient-wallet": {
          path = paths.dashboardPatientWallet();
          break;
        }
        case "practice-production": {
          path = paths.dashboardPracticeProduction({ tableTab: "providers" });
          break;
        }
        case "adjustments": {
          path = paths.dashboardAdjustments({ tableTab: "insurance" });
          break;
        }
        case "recall": {
          path = paths.dashboardRecall({ tableTab: "patients" }, { filters: ACTIVE_NO_NEXT_APPT_FILTER });
          break;
        }
        case "appointments-hygiene":
        case "appointments-comprehensive":
        case "appointments-emergency": {
          const chosenApptType: AppointmentDrilldownType = questionSelected.split(
            "-"
          )[1] as AppointmentDrilldownType;

          path = paths.dashboardAppointmentDrilldown({ apptType: chosenApptType });
          break;
        }
        case "unscheduled-treatment": {
          path = paths.dashboardUnscheduledTreatment({ filters: ACTIVE_NO_NEXT_APPT_EXCLUDE_HYGIENE_FILTER });
          break;
        }
        case "payroll-hours-worked": {
          path = paths.dashboardPayrollHours({});
          break;
        }
        default: {
          path = paths.dashboardTab({ dashboardQuestion: questionSelected });
          break;
        }
      }

      if (path) {
        navigate(path, { replace: true });
      }
    },
    [navigate]
  );

  const selectedOption: DropdownValue = React.useMemo(() => {
    if (question === "appointments" && apptType) {
      return `appointments-${apptType}` as DropdownValue;
    }

    return question as DropdownValue;
  }, [apptType, question]);

  const customQuestionStyles = React.useMemo(() => {
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    return createSelectStyles<DropdownValue, DropdownOption>({
      singleValue: () => ({
        color: designConfig.colors.primaryTheme,
        fontSize: "1rem",
      }),
    });
  }, []);

  return (
    <div className="flex items-center gap-3">
      <div className="font-sansSemiBold">Reporting</div>
      <FormFieldSelect
        isClearable={false}
        isSearchable
        className="flex-1 min-w-[380px] font-sansSemiBold"
        options={questionOptions}
        value={selectedOption}
        styles={customQuestionStyles}
        onChange={(option) => {
          if (option?.value) {
            onChangeQuestion(option);
          }
        }}
      />
    </div>
  );
};
