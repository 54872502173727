import { useMemo } from "react";
import { add, isSameDay, startOfQuarter, startOfYear, sub } from "date-fns";
import { nowInTimezone } from "@libs/utils/date";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";

export type RangeShortcut = {
  label: string;
  startDate: Date | null;
  endDate: Date | null;
  selected?: boolean;
};

export const useRangeShortcuts = (startDate: Date | null, endDate: Date | null): RangeShortcut[] => {
  const practice = useCurrentPractice();

  return useMemo(() => {
    const now = nowInTimezone(practice.timezoneId);

    const options = [
      {
        label: "Today",
        startDate: now,
        endDate: now,
      },
      {
        label: "Last 7 days",
        endDate: now,
        startDate: sub(now, { days: 7 }),
      },
      {
        label: "Last 15 days",
        endDate: now,
        startDate: sub(now, { days: 15 }),
      },
      {
        label: "Last 30 days",
        endDate: now,
        startDate: sub(now, { days: 30 }),
      },
      {
        label: "Last 3 Months",
        endDate: now,
        startDate: sub(now, { months: 3 }),
      },
      {
        label: "Last 6 Months",
        endDate: now,
        startDate: sub(now, { months: 6 }),
      },
      {
        label: "Quarter to Date",
        endDate: now,
        startDate: startOfQuarter(now),
      },
      {
        label: "Year to Date",
        endDate: now,
        startDate: startOfYear(now),
      },
      {
        label: "Next 30 days",
        endDate: add(now, { days: 30 }),
        startDate: now,
      },
    ];

    const optionsWithSelection = options.map((option) => ({
      ...option,
      selected: Boolean(
        startDate && isSameDay(option.startDate, startDate) && endDate && isSameDay(option.endDate, endDate)
      ),
    }));

    return [
      ...optionsWithSelection,
      {
        label: "Custom Range",
        endDate: null,
        startDate: null,
        selected: !optionsWithSelection.some((option) => option.selected),
      },
    ];
  }, [endDate, practice.timezoneId, startDate]);
};
