import { FC, useCallback, useId } from "react";
import { useNavigate } from "react-router-dom";
import { RoleRequest } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { getPermissionActions } from "api/practice/queries";
import { createRoleV2 } from "api/practice/mutations";
import { handleError } from "utils/handleError";
import { permissionsToRoleDraft, prepareRoleDraftForSubmission } from "components/Roles/roleUtils";
import { RoleFormContent, RoleFormContentProps } from "components/Roles/RoleFormContent";
import { RoleModalPage } from "components/Roles/RoleModalPage";

const CreateRoleForm: FC<Pick<RoleFormContentProps, "allPermissions" | "formId" | "onSave">> = ({
  formId,
  allPermissions,
  onSave,
}) => {
  const getDraft = useCallback(() => permissionsToRoleDraft(allPermissions), [allPermissions]);

  return (
    <RoleFormContent
      formId={formId}
      getInitialState={getDraft}
      allPermissions={allPermissions}
      viewOnly={false}
      onSave={onSave}
    />
  );
};

export const CreateRoleRoute: FC = () => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const [permissionActionsQuery] = useApiQueries([getPermissionActions({ args: { practiceId } })]);
  const [createRole] = useApiMutations([createRoleV2]);
  const {
    query: { from },
  } = useQueryParams("roleCreate");
  const formId = useId();

  const closeUrl = from ?? paths.roles();
  const handleSave = (draftRole: RoleRequest) => {
    if (permissionActionsQuery.data) {
      createRole.mutate(
        {
          practiceId,
          data: prepareRoleDraftForSubmission(permissionActionsQuery.data, draftRole),
        },
        {
          onSuccess: () => navigate(closeUrl),
          onError: handleError,
        }
      );
    }
  };

  return (
    <RoleModalPage
      titleText="Create Role"
      closeUrl={closeUrl}
      saveText="Create"
      isSaving={createRole.isLoading}
      formId={formId}
    >
      <QueryResult queries={[permissionActionsQuery]}>
        {permissionActionsQuery.data && (
          <CreateRoleForm formId={formId} allPermissions={permissionActionsQuery.data} onSave={handleSave} />
        )}
      </QueryResult>
    </RoleModalPage>
  );
};
