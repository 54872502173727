import React from "react";
import { ClaimLineItemData, ClaimLineItemVO, ClaimVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { TableGrid, HeaderCell, Row } from "@libs/components/UI/GridTableComponents";
import { isDraftClaim, isInDraftEobPaymentClaim, isSubmittingClaim } from "components/Claims/utils";
import { ProcedureTableRow } from "components/Claim/ProcedureTableRow";
import { ColumnIds, HeaderData } from "components/Claim/procedureTableUtils";
import { ProcedureTableFooter } from "components/Claim/ProcedureTableFooter";
import { ClaimLineItemActions } from "components/Eob/EobRoute";
import { ProcedureTableFooterAdditionalSummary } from "components/Claim/ProcedureTableFooterAdditionalSummary";

interface Props {
  claim: ClaimVO;
  className?: string;
  disableActions?: boolean;
  headers: HeaderData[];
  insuranceAmountErrorMessage?: string;
  isAdditionalPayment?: boolean;
  isDetailView?: boolean;
  isDraftEobView?: boolean;
  lineItems: ClaimLineItemVO[];
  onAdjustmentAmountChange?: (lineItemIndex: number, field: string, value: string | undefined) => void;
  onAdjustmentRemarkChange?: (
    lineItemIndex: number,
    updates: {
      downgradedCdtCode?: string;
      remarks?: string;
    }
  ) => void;
  onSelectCheckbox?: (id: number) => void;
  onUpdateGroupedClaimLineItems?: (
    claimUuid: string,
    action: ClaimLineItemActions,
    options?: {
      hasErrors: boolean;
      lineItemIndex: number;
      updates: ClaimLineItemData;
    }
  ) => void;
  previousLineItems?: ClaimLineItemVO[];
  selectedLineItems?: Set<number>;
  showTotals: boolean;
  version: number;
}
export type ClaimLineItemVOKeys = keyof ClaimLineItemVO;
export type EditableColumnIds = "deductibleAmount" | "patientAmount" | "insuranceAmount";

// In the footers, this is used for indicating the row is for totals
const DESCRIPTIONS_INDEX = 3;

export const cxStyles = {
  cell: ({
    borderBottom = false,
    borderBottomColor = "border-b-greyLighter",
  }: {
    borderBottom?: boolean;
    borderBottomColor?: string;
  }) =>
    cx(
      "flex items-center h-full px-3 first:pl-5 last:pr-5",
      borderBottom && cx("border-b", borderBottomColor)
    ),
  cellYPadding: "py-1.5",
  currencyDividerBorder: "h-full border-r border-dashed border-greyLighter",
  currencyEdit: "w-[100px]",
  currencyReadOnly: "w-[84px]",
  currencyValueCell: "border-r border-r-greyLighter last:border-r-0",
};

export const isComparisonCell = (field: ColumnIds) => {
  return field === "deductibleAmount" || field === "patientAmount" || field === "insuranceAmount";
};

export const ProcedureTable: React.FC<Props> = ({
  claim,
  className,
  disableActions = false,
  headers,
  insuranceAmountErrorMessage,
  isAdditionalPayment = false,
  isDetailView = false,
  isDraftEobView = false,
  lineItems,
  onAdjustmentAmountChange,
  onAdjustmentRemarkChange,
  onSelectCheckbox,
  onUpdateGroupedClaimLineItems,
  previousLineItems,
  selectedLineItems,
  showTotals,
  version,
}) => {
  const isDraft = isDraftClaim(claim);
  const isSubmitting = isSubmittingClaim(claim);
  const isInDraftEobPayment = isInDraftEobPaymentClaim(claim);
  const footerDescriptionIndex = isDraft ? DESCRIPTIONS_INDEX + 1 : DESCRIPTIONS_INDEX;

  return (
    <div
      className={cx("bg-white rounded border border-b-0 border-greyLighter", className)}
      data-testid={`draft-eob-claim-line-items-table-${claim.claimNumber}`}
    >
      <TableGrid columnWidths={headers.map(({ width }) => width)}>
        <Row>
          {headers.map((header) => {
            const isComparison = isComparisonCell(header.id);

            return (
              <HeaderCell
                className={cx(
                  "bg-transparent",
                  header.isCurrencyCell && isDraftEobView && cxStyles.currencyValueCell,
                  (isComparison && isAdditionalPayment) || header.id === "remarks"
                    ? "justify-center"
                    : header.isCurrencyCell
                      ? "justify-end"
                      : "justify-start"
                )}
                key={header.id}
                size="slim"
                sticky={false}
              >
                {isComparison && isAdditionalPayment ? (
                  <>
                    {`${header.label} Prev /`}&nbsp;
                    <span className="font-sansSemiBold text-orange">New</span>
                  </>
                ) : (
                  header.label
                )}
              </HeaderCell>
            );
          })}
        </Row>
        {lineItems.map((lineItem, index) => (
          <ProcedureTableRow
            claimUuid={claim.uuid}
            disableActions={disableActions}
            headers={headers}
            isAdditionalPayment={isAdditionalPayment}
            isChecked={selectedLineItems ? selectedLineItems.has(lineItem.patientProcedureId) : false}
            isDetailView={isDetailView}
            isDraftEobView={isDraftEobView}
            isInDraftEobPayment={isInDraftEobPayment}
            isLastRow={index === claim.lineItemGroups[0].lineItems.length - 1}
            isSubmitting={isSubmitting}
            isValidating={claim.isValidating}
            key={`${lineItem.patientProcedureId}`}
            insuranceOrdinal={claim.insuranceOrdinal}
            lineItem={lineItem}
            lineItemIndex={index}
            onSelectCheckbox={onSelectCheckbox}
            onAdjustmentAmountChange={onAdjustmentAmountChange}
            onAdjustmentRemarkChange={onAdjustmentRemarkChange}
            onUpdateGroupedClaimLineItems={onUpdateGroupedClaimLineItems}
            previousLineItem={previousLineItems?.[index]}
            version={version}
          />
        ))}
        <ProcedureTableFooter
          headers={headers}
          footerDescriptionIndex={footerDescriptionIndex}
          insuranceTooltip={insuranceAmountErrorMessage}
          isAdditionalPayment={isAdditionalPayment}
          isDraftEobView={isDraftEobView}
          lineItems={lineItems}
          previousLineItems={previousLineItems}
          showTotals={showTotals}
        />
        {isAdditionalPayment && previousLineItems ? (
          <ProcedureTableFooterAdditionalSummary
            footerDescriptionIndex={footerDescriptionIndex}
            headers={headers}
            lineItems={lineItems}
            previousLineItems={previousLineItems}
          />
        ) : null}
      </TableGrid>
    </div>
  );
};
