import { useImagingHub } from "components/ImageCapturing/ImagingHubContext";
import {
  MountUploadParams,
  UseArchyAgentUploader,
  useArchyAgentUploader,
} from "components/PatientProfile/Imaging/MountRoute/hooks/useArchyAgentUploader";
import { useDynamsoftUploader } from "components/PatientProfile/Imaging/MountRoute/hooks/useDynamsoftUploader";

export const useSensorImageUploader = (params: MountUploadParams): UseArchyAgentUploader => {
  const { status: hubStatus } = useImagingHub();

  const aisUploader = useArchyAgentUploader(params);
  const dynamsoftUploader = useDynamsoftUploader(params);

  if (hubStatus.isRunning && !hubStatus.isAISDisabled) {
    return aisUploader;
  }

  return dynamsoftUploader;
};
