import { FC, ReactNode } from "react";

interface Props {
  title: string;
  children?: ReactNode;
}

export const EventModalTitleContent: FC<Props> = ({ children, title }) => {
  return (
    <div className="flex items-center gap-x-6">
      <h1 className="font-sansSemiBold">{title}</h1>
      {children}
    </div>
  );
};
