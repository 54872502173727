import { FC, FormEvent, useState } from "react";
import { produce } from "immer";
import { NameVO, PracticeVO } from "@libs/api/generated-api";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Form } from "@libs/components/UI/Form";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";

interface Props {
  practice: PracticeVO;
  employees: NameVO[];
  isEditing: boolean;
  onCancel: Func;
  onSubmit: (practice: PracticeVO) => void;
  isSaving: boolean;
}

export const ContactDetailsForm: FC<Props> = ({
  practice,
  employees,
  isEditing,
  isSaving,
  onCancel,
  onSubmit,
}) => {
  const [draft, setDraft] = useState(practice);
  const {
    contactDetails: { email, fax, phoneNumber, commsPhone, officeRepresentative, officeRepresentativeId },
  } = draft;

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    onSubmit(draft);
  };

  return (
    <Form onSubmit={handleSubmit} fieldLayout="labelIn">
      <div className="grid grid-cols-3 gap-3">
        <FormFieldPhoneInput
          label="Phone Number"
          value={phoneNumber}
          onValueChange={(value) =>
            setDraft((last) =>
              produce(last, (p) => {
                p.contactDetails.phoneNumber = value;
              })
            )
          }
          edit={isEditing}
        />
        <FormFieldInput
          onChange={(e) =>
            setDraft((last) =>
              produce(last, (p) => {
                p.contactDetails.email = e.target.value;
              })
            )
          }
          label="Email"
          value={email}
          edit={isEditing}
        />
        <FormFieldPhoneInput
          label="Fax"
          placeholder="e.g. (415) 415-4154"
          value={fax}
          onValueChange={(value) => {
            setDraft((last) =>
              produce(last, (p) => {
                p.contactDetails.fax = value;
              })
            );
          }}
          edit={isEditing}
        />
      </div>
      <div className="grid grid-cols-3 gap-3 mt-3">
        <FormFieldSelect
          required
          label="Office Representative"
          options={employees.map((item) => ({
            value: item.id,
            label: item.fullDisplayName,
          }))}
          placeholder="Select Representative"
          onItemChanged={(val) =>
            setDraft((last) =>
              produce(last, (p) => {
                p.contactDetails.officeRepresentativeId = val;
              })
            )
          }
          isSearchable={true}
          display="label"
          value={officeRepresentativeId}
          edit={isEditing}
        />
        <FormFieldInput
          disabled={true}
          label="Office Representative Email"
          value={officeRepresentative?.workEmail}
          edit={isEditing}
        />
        {commsPhone && (
          <FormFieldPhoneInput disabled={true} label="SMS Number" value={commsPhone} edit={isEditing} />
        )}
      </div>
      {isEditing && (
        <div className="flex flex-wrap mt-6 mb-2">
          <Button theme="secondary" size="large" className="w-[100px] mr-2" onClick={onCancel}>
            Cancel
          </Button>
          <AsyncButton isLoading={isSaving} theme="primary" size="large" className="w-[100px]" type="submit">
            Save
          </AsyncButton>
        </div>
      )}
    </Form>
  );
};
