import React from "react";
import { noop } from "@libs/utils/noop";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { PatientInformation } from "components/PatientProfile/PatientOverview/Info/PatientInformation";
import { PatientInformationPanel } from "components/PatientProfile/PatientOverview/Info/PatientInformationPanel";
import { usePathParams } from "hooks/usePathParams";
import { getFamilyMembersV2Query, getPatientQuery } from "api/patients/queries";

export const PatientInformationReadOnlyRoute: React.FC = () => {
  const { patientId } = usePathParams("patient");
  const { practiceId } = useAccount();
  const [patientQuery, familyMembersQuery] = useApiQueries([
    getPatientQuery({ args: { patientId, practiceId, includeContactPatientDetails: true } }),
    getFamilyMembersV2Query({
      args: { patientId, practiceId },
    }),
  ]);

  return (
    <PatientInformationPanel patientId={patientId} isEditing={false}>
      <QueryResult queries={[patientQuery, familyMembersQuery]}>
        {patientQuery.data && familyMembersQuery.data ? (
          <PatientInformation
            isEditing={false}
            familyMembers={familyMembersQuery.data}
            patientId={patientId}
            onboardingState={patientQuery.data.onboardingState}
            updatePatientRequest={{
              ...patientQuery.data,
              additionalInformation: patientQuery.data.additionalInformation ?? {},
            }}
            onChange={noop}
            onAddContact={noop}
          />
        ) : null}
      </QueryResult>
    </PatientInformationPanel>
  );
};
