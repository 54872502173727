import React from "react";
import { JournalEventResponse, JournalEventVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getAbsoluteUrl } from "@libs/utils/location";
import { formatCurrency } from "@libs/utils/currency";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { getLocalDate } from "@libs/utils/date";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";
import { handleError } from "utils/handleError";
import { useDownloadQueryPages } from "components/Dashboard/hooks/useDownloadInfiniteQuery";
import { paths } from "utils/routing/paths";
import { getJournalRowDescription } from "components/Dashboard/EndOfDayReport/utils";
import { END_OF_DAY_REPORT_HEADERS } from "components/Dashboard/EndOfDayReport/constants";
import { useCurrentUrl, useOrigin } from "contexts/OriginContext";

const formatAppointmentDataAsCsvUrl = (params: { rows: JournalEventVO[]; origin: string }) => {
  const { rows, origin } = params;

  const columnTitles = formatCsvRow([
    ...END_OF_DAY_REPORT_HEADERS.map(({ label }) => label).filter(Boolean),
    "Patient URL",
  ]);
  const rowsContent = rows.map((row) => {
    const { patient, eventDate, eventType, provider, amount } = row;
    const description = getJournalRowDescription(row);

    return formatCsvRow([
      patient.fullDisplayName,
      eventDate,
      provider?.fullDisplayName ?? "",
      sentenceCaseConstant(eventType),
      description,
      formatCurrency(amount),
      getAbsoluteUrl(origin, paths.patient({ patientId: patient.id })),
    ]);
  });

  return [columnTitles, ...rowsContent];
};

export const useDownloadEndOfDayCsv = (params: {
  date: string;
  endOfDayReportInfiniteQuery: UseInfiniteApiQueryResult<JournalEventResponse>;
}) => {
  const downloading = useBoolean(false);
  const origin = useOrigin();
  const currentUrl = useCurrentUrl();
  const { startDownload: downloadEndOfDayReport } = useDownloadQueryPages(params.endOfDayReportInfiniteQuery);

  return {
    isDownloading: downloading.isOn,
    downloadCSV: React.useCallback(async () => {
      downloading.on();

      try {
        const result = await downloadEndOfDayReport();
        const rows = result.pages.flatMap((item) => item.data.entries);
        const docMetadata = getArchyCsvMetadata(currentUrl, { startDate: getLocalDate(params.date) });
        const csvRows = [
          ...docMetadata.headers,
          ...formatAppointmentDataAsCsvUrl({
            rows,
            origin,
          }),
        ];

        saveCsv(csvRows, `DailyReport_${docMetadata.fileNameTag}`);
      } catch (e) {
        handleError(e);
      } finally {
        downloading.off();
      }
    }, [downloading, downloadEndOfDayReport, params.date, origin, currentUrl]),
  };
};
