import { useMemo } from "react";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as CompressIcon } from "@libs/assets/icons/compress.svg";
import { Divider } from "components/UI/Divider";
import { CallsToDisplayMap, CallsToDisplayMapKeys } from "components/Mango/types";

interface Props {
  callGroup: CallsToDisplayMapKeys;
  callsToDisplay: CallsToDisplayMap;
  canMinimize: boolean;
  onMinimize: Func;
}

export const CallHeader: React.FC<Props> = ({ callGroup, callsToDisplay, canMinimize, onMinimize }) => {
  const isFirstGroup = useMemo(() => {
    if (callGroup === "inboundCalls") {
      return !Object.values(callsToDisplay.ongoingCalls).length;
    } else if (callGroup === "outboundCalls") {
      return !(
        Object.values(callsToDisplay.ongoingCalls).length || Object.values(callsToDisplay.inboundCalls).length
      );
    }

    return true;
  }, [callGroup, callsToDisplay.inboundCalls, callsToDisplay.ongoingCalls]);

  return (
    <div
      className={`
        flex
        flex-col
        items-start
        py-1
        px-2
        mb-1
        gap-y-3
        text-sm
        font-sansSemiBold
      `}
    >
      {!isFirstGroup && <Divider />}
      <div className="flex justify-between w-full">
        {callGroup === "inboundCalls"
          ? "Inbound Calls"
          : callGroup === "outboundCalls"
            ? "Outbound Calls"
            : "Ongoing Calls"}
        {canMinimize && isFirstGroup ? <ButtonIcon SvgIcon={CompressIcon} onClick={onMinimize} /> : null}
      </div>
    </div>
  );
};
