import React from "react";
import { useTimeseriesTemplateForResolution } from "components/Dashboard/hooks/useTimeseriesTemplateForResolution";
import { TimeSeriesQuery, TimeSeriesResolutionOption } from "components/Dashboard/types";
import { useSingleTimeseriesAsStack } from "components/Dashboard/hooks/useSingleTimeseriesAsStack";
import { BarStackData } from "components/Dashboard/Charting/types";
import { AdjustmentCategory } from "utils/routing/dashboard";
import { TimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { useTimeseriesPreviousData } from "components/Dashboard/Charting/hooks/useTimeseriesPreviousData";

export const useAdjustmentsOverTimeAsStacks = ({
  resolution,
  insuranceAdjustmentTimeseriesQuery,
  collectionAdjustmentTimeseriesQuery,
  dateWindow,
}: {
  dateWindow: TimeSeriesPageSelections;
  resolution: TimeSeriesResolutionOption;
  insuranceAdjustmentTimeseriesQuery: TimeSeriesQuery;
  collectionAdjustmentTimeseriesQuery: TimeSeriesQuery;
}) => {
  const timeline = useTimeseriesTemplateForResolution({ resolution, dateWindow });

  const insuranceAdjustmentStacks = useSingleTimeseriesAsStack(insuranceAdjustmentTimeseriesQuery, timeline);
  const collectionAdjustmentStacks = useSingleTimeseriesAsStack(
    collectionAdjustmentTimeseriesQuery,
    timeline
  );
  const isLoading =
    insuranceAdjustmentTimeseriesQuery.isInitialLoading ||
    insuranceAdjustmentTimeseriesQuery.isLoading ||
    collectionAdjustmentTimeseriesQuery.isInitialLoading ||
    collectionAdjustmentTimeseriesQuery.isLoading;
  const data: BarStackData<AdjustmentCategory>[] = React.useMemo(() => {
    return insuranceAdjustmentStacks.map(({ startDate, value }, i) => ({
      date: startDate,
      insurance: value,
      collection: collectionAdjustmentStacks[i].value,
    }));
  }, [insuranceAdjustmentStacks, collectionAdjustmentStacks]);

  const dataWithLoadingData = useTimeseriesPreviousData({
    data,
    isLoading,
  });

  return {
    ...dataWithLoadingData,
    isLoading,
  };
};
