/* eslint-disable react/no-unescaped-entities */
import { FC } from "react";
import { cx } from "@libs/utils/cx";
import { Button } from "@libs/components/UI/Button";
import { Banner } from "@libs/components/UI/Banner";

export const FinixScriptLoadErrorBanner: FC<{ className?: string }> = ({ className }) => {
  return (
    <Banner theme="error" className={cx("text-xs", className)}>
      <div>
        Unable to access Archy's credit card processor, Finix Payments. Please check if an ad-blocker or
        firewall is restricting access to this site: https://forms.finixpymnts.com/finix.js. Or try again by
        reloading this page. If the problem persists, please contact Archy's Support.{" "}
        <Button theme="link" onClick={() => location.reload()}>
          Reload Page
        </Button>
      </div>
    </Banner>
  );
};
