import { useCallback } from "react";
import { getUnixTime } from "date-fns";
import { ClaimVO } from "@libs/api/generated-api";
import { useAttachments } from "components/Claim/Attachments/ClaimAttachmentsContext";
import { ExternalUpload } from "components/Claim/Attachments/ExternalUpload";

interface Props {
  claim: ClaimVO;
}

export const OtherAttachments: React.FC<Props> = ({ claim }) => {
  const patientId = claim.patientName.id;
  const { addOtherImage, editOtherImage, other, removeImage } = useAttachments();

  const handleAddImage = useCallback(
    (src: string) => {
      addOtherImage({
        data: src,
        isUploaded: true,
        sourceCreatedAt: getUnixTime(Date.now()),
        type: "DIAGNOSTIC",
      });
    },
    [addOtherImage]
  );

  return (
    <div
      className={`
        flex
        flex-col
        h-full
        min-h-0
        pt-0.5
        mb-6
        gap-y-6
        bg-white
        z-0
      `}
    >
      <ExternalUpload
        attachmentType="other"
        canSelectMultiple={true}
        editImage={editOtherImage}
        images={other}
        onAddImage={handleAddImage}
        removeImage={removeImage}
        userId={patientId}
      />
    </div>
  );
};
