import { useDrag, useDrop } from "react-dnd";
import useMeasure from "react-use-measure";
import { MedicalImageVO } from "@libs/api/generated-api";
import { ImageViewMetadata } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ImageViewMetadata";
import { MountFormat } from "utils/routing/patient";
import { ImageWithBottomAttachment } from "components/PatientProfile/Imaging/ImageWithBottomAttachment";
import { ImageDetailProps } from "components/PatientProfile/Imaging/MountRoute/types";
import { cxSidebarNavigationStyles } from "components/PatientProfile/Imaging/ImageSidebarNavigation/cxStyles";

type DropProps<T extends MedicalImageVO> = {
  data: { image?: T; index: number };
  format: MountFormat;
  index: number;
  onImageMounted: (image: T, mountIndex: number) => void;
  canDragFrom: boolean;
  dragDropType: string;
} & ImageDetailProps;

const useDragDrop = <T extends MedicalImageVO>({
  dragDropType,
  index,
  image,
  onImageMounted,
  canDragFrom,
}: {
  dragDropType: string;
  index: number;
  onImageMounted: (image: T, mountIndex: number) => void;
  image?: T;
  canDragFrom: boolean;
}) => {
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [_, drag] = useDrag(
    () => ({
      type: dragDropType,
      item: image,

      canDrag: canDragFrom,
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
        handlerId: monitor.getHandlerId(),
      }),
    }),
    [image, canDragFrom]
  );
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: dragDropType,
      drop: (droppedImage: T) => {
        onImageMounted(droppedImage, index);
      },

      collect: (monitor) => ({
        isOver: monitor.isOver(),
        canDrop: monitor.canDrop(),
      }),
    }),
    [index]
  );

  return { drag, drop, isOver, canDrop };
};

export const ImageDrop = <T extends MedicalImageVO>({
  data,
  onEditImage,
  onImageUpdate,
  onImageMounted,
  index,
  canDragFrom,
  devices,
  isShowingMetadata = false,
  dragDropType,
  onClickTeeth,
}: DropProps<T>) => {
  const [containerRef, bounds] = useMeasure({ debounce: 100, offsetSize: true });
  const { drag, drop, isOver, canDrop } = useDragDrop<T>({
    image: data.image,
    index,
    canDragFrom,
    dragDropType,
    onImageMounted,
  });
  const isActive = canDrop && isOver;

  return (
    <div className="relative">
      <div className="absolute bottom-8 top-0 left-0 right-0" ref={containerRef} />
      <div
        ref={(r) => {
          drop(r);
          drag(r);
        }}
        className={cxSidebarNavigationStyles.imageDrop}
      >
        {isActive ? (
          <div
            className={`
              absolute
              inset-0
              z-30
              w-full
              h-full
              bg-primaryTheme
              opacity-30
            `}
          />
        ) : null}
        {data.image ? (
          <ImageWithBottomAttachment
            bounds={bounds}
            image={data.image}
            onEditImage={onEditImage}
            imageClasses="w-full h-full"
          >
            {isShowingMetadata && (
              <ImageViewMetadata
                image={data.image}
                onImageUpdate={onImageUpdate}
                devices={devices}
                onClickTeeth={onClickTeeth}
              />
            )}
          </ImageWithBottomAttachment>
        ) : (
          <div
            className={`
              flex
              flex-1
              justify-center
              items-center
              border
              h-full
              w-full
              border-dashed
              border-greyMedium
              rounded-lg
              text-greyMedium
              text-sm
            `}
          >
            Drop Image
          </div>
        )}
      </div>
    </div>
  );
};
