import { InsuranceAdjustmentSubtotalVO } from "@libs/api/generated-api";

import { formatCurrency } from "@libs/utils/currency";
import { SectionFooter } from "components/Dashboard/Tables/SectionFooter";

type Props = {
  data: InsuranceAdjustmentSubtotalVO;
};

export const InsuranceAdjustmentsFooterRow: React.FC<Props> = ({ data }) => {
  const { totalAdjustmentAmount, totalNetProduction, totalUcrFee } = data;

  return (
    <>
      <SectionFooter className="col-span-4" />
      <SectionFooter className="justify-end">Carrier Totals</SectionFooter>
      <SectionFooter align="right">{formatCurrency(totalUcrFee)}</SectionFooter>
      <SectionFooter align="right">{formatCurrency(totalAdjustmentAmount)}</SectionFooter>
      <SectionFooter align="right">{formatCurrency(totalNetProduction)}</SectionFooter>
      <SectionFooter />
    </>
  );
};
