import React from "react";
import { PaymentDeviceVO } from "@libs/api/generated-api";
import SunmiP2 from "assets/images/sunmi-p2.svg";
import Link2500 from "assets/images/ingenico-link-2500.svg";
import Lane3000 from "assets/images/ingenico-lane-3000.svg";
import Lane5000 from "assets/images/ingenico-lane-5000.svg";
import Lane7000 from "assets/images/ingenico-lane-7000.svg";
import Lane8000 from "assets/images/ingenico-lane-8000.svg";
import OpaxA920 from "assets/images/pax-a920.svg";

interface Props {
  className: string;
  model: PaymentDeviceVO["model"];
}

export const PosTerminal: React.FC<Props> = ({ model, className }) => {
  switch (model) {
    case "LINK_2500": {
      return <img alt="Link2500 POS" className={className} src={Link2500} />;
    }
    case "LANE_3000": {
      return <img alt="Lane3000 POS" className={className} src={Lane3000} />;
    }
    case "LANE_5000": {
      return <img alt="Lane5000 POS" className={className} src={Lane5000} />;
    }
    case "LANE_7000": {
      return <img alt="Lane7000 POS" className={className} src={Lane7000} />;
    }
    case "LANE_8000": {
      return <img alt="Lane8000 POS" className={className} src={Lane8000} />;
    }
    case "PAX_A920PRO": {
      return <img alt="Pax A920Pro" className={className} src={OpaxA920} />;
    }
    case "SUNMI_P2": {
      return <img alt="Sunmi P2" className={className} src={SunmiP2} />;
    }

    default: {
      return null;
    }
  }
};
