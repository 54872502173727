import { FC, PropsWithChildren, useMemo } from "react";
import { getDarkColor, getLightColor } from "@libs/domains/scheduling/colors";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { cx } from "@libs/utils/cx";

const Circle: FC<PropsWithChildren<{ color: string; className: string }>> = ({
  children,
  color,
  className,
}) => {
  return (
    <div
      aria-label={color}
      className={cx("size-8 rounded-full border-2 border-white border-solid", className)}
      style={{ background: color }}
    >
      {children}
    </div>
  );
};

export const ColorPickerSelection: FC<{ color: string }> = ({ color }) => {
  const lightColor = useMemo(() => getLightColor(color), [color]);
  const darkColor = useMemo(() => getDarkColor(color), [color]);

  return (
    <div className="flex flex-wrap gap-1">
      <Circle color={darkColor} className="relative" />
      <Circle color={lightColor} className="-ml-3 flex items-center justify-center">
        <Icon
          SvgIcon={InfoIcon}
          theme="slate500"
          size="sm"
          tooltip={{
            content:
              "We generate a lighter version of the selected color so that when it's used as a background color, text remains readable.",
          }}
        />
      </Circle>
    </div>
  );
};
