import { FC, useState, useMemo, useCallback } from "react";
import { FileRejection } from "react-dropzone";
import { produce } from "immer";

import { FolderVO } from "@libs/api/generated-api";
import { getTempId } from "@libs/utils/tempId";
import { useAccount } from "@libs/contexts/AccountContext";
import { Modal } from "@libs/components/UI/Modal";
import { DocumentUploaderRequest, useDocumentUploader } from "components/UI/DocumentUploadManager";
import { AttachFilesModalContent } from "components/UI/AttachModalContent/AttachFilesModalContent";
import { UploadDocumentFileRow } from "components/UserDocuments/UploadDocumentFileRow";

import { handleError } from "utils/handleError";

export interface StagedDocumentFile extends MakeKeysNullable<DocumentUploaderRequest, "folderId"> {
  stagedId: string;
}

interface Props {
  userId: number;
  folders: FolderVO[];
  defaultFolderId?: FolderVO["id"];
  onRequestClose: Func;
}

export const UploadDocumentFilesModal: FC<Props> = ({ userId, folders, defaultFolderId, onRequestClose }) => {
  const [stagedFiles, setStagedFiles] = useState<StagedDocumentFile[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const { practiceId } = useAccount();
  const { documentUploader } = useDocumentUploader();

  const canUploadFiles = useMemo(
    () => stagedFiles.length > 0 && stagedFiles.every((file) => file.folderId),
    [stagedFiles]
  );

  const folderOptions = useMemo(
    () => folders.map((folder) => ({ label: folder.name, value: folder.id })),
    [folders]
  );

  const handleStageFiles = useCallback(
    (files: File[]) => {
      setStagedFiles((last) => [
        ...last,
        ...files.map((file) => ({
          stagedId: getTempId(),
          practiceId,
          userId,
          // Set defaultFolderId from selected user documents folder, otherwise
          // set as last added file folder if available
          folderId: defaultFolderId ?? (last.length > 0 ? last.at(-1)?.folderId : undefined),
          filename: file.name,
          fileData: file,
        })),
      ]);
    },
    [practiceId, userId, defaultFolderId]
  );

  const handleDropFiles = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      handleStageFiles(acceptedFiles);
      rejectedFiles.forEach(handleError);
    },
    [handleStageFiles]
  );

  const handleUpdateFile = (stagedId: string, updatedFile: Partial<StagedDocumentFile>) => {
    setStagedFiles((last) =>
      produce(last, (draft) => {
        const stagedFileIndex = draft.findIndex((file) => file.stagedId === stagedId);

        draft[stagedFileIndex] = { ...draft[stagedFileIndex], ...updatedFile };

        // When selecting a folder for a file that has no current folder
        // selected, auto-fill all other unselected files with that folder
        if (!last[stagedFileIndex].folderId && draft[stagedFileIndex].folderId) {
          for (const file of draft) {
            if (!file.folderId) {
              file.folderId = draft[stagedFileIndex].folderId;
            }
          }
        }
      })
    );
  };

  const handleDeleteFile = (stagedId: string) => {
    setStagedFiles((last) =>
      produce(last, (draft) => {
        const stagedFileIndex = draft.findIndex((file) => file.stagedId === stagedId);

        draft.splice(stagedFileIndex, 1);
      })
    );
  };

  const handleUploadFiles = useCallback(async () => {
    setIsUploading(true);

    try {
      await Promise.all(
        stagedFiles.map(async (file) => {
          const documentUploaderRequest = produce(
            file as MakeKeysNullable<StagedDocumentFile, "stagedId">,
            (draft) => {
              delete draft.stagedId;
            }
          ) as DocumentUploaderRequest;

          return documentUploader(documentUploaderRequest);
        })
      );
      onRequestClose();
    } catch (error) {
      handleError(error);
    } finally {
      setIsUploading(false);
    }
  }, [stagedFiles, documentUploader, onRequestClose]);

  return (
    <Modal title="Upload Files" size="xs" onClose={onRequestClose}>
      <AttachFilesModalContent
        canSelectMultiple
        canUploadFiles={canUploadFiles}
        isUploading={isUploading}
        onDropFiles={handleDropFiles}
        onUploadFiles={handleUploadFiles}
        onRequestClose={onRequestClose}
      >
        {stagedFiles.length > 0 ? (
          <div className="w-full divide-y divide-dashed divide-greyLighter">
            {stagedFiles.map((file) => (
              <UploadDocumentFileRow
                key={file.stagedId}
                file={file}
                folderOptions={folderOptions}
                onUpdateFile={handleUpdateFile}
                onDeleteFile={handleDeleteFile}
              />
            ))}
          </div>
        ) : null}
      </AttachFilesModalContent>
    </Modal>
  );
};
