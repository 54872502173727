import { FC, useState } from "react";
import { ContactVO, FamilyMemberResponse, PatientSummaryVO, PatientVO } from "@libs/api/generated-api";
import { FlyoverReel } from "components/UI/FlyoverReel";
import { AddContactPanel } from "components/Patient/AddContactPanel";
import { EditContactPanel } from "components/Patient/EditContactPanel";
import { AddFamilyMemberFormPanel } from "components/PatientProfile/PatientOverview/FamilyMembers/AddFamilyMemberFormPanel";

interface Props {
  onRequestClose: Func;
  onCreated: (displayName: string) => void;
  familyMembers: FamilyMemberResponse;
  isGuardian: boolean;
  patient: PatientVO;
}

type AddFamilyMemberPanel =
  | { id: "add-member"; isDirty?: boolean }
  | { id: "contact"; patientFirstName: string; patientId?: number; isDirty?: boolean };

const initialPanel = { id: "add-member" } as AddFamilyMemberPanel;

export const AddFamilyMemberFlyover: FC<Props> = ({
  onRequestClose,
  onCreated,
  familyMembers,
  isGuardian,
  patient,
}) => {
  const [contactSelection, setContactSelection] = useState<ContactVO>();

  return (
    <FlyoverReel initialPanel={initialPanel} onClose={onRequestClose} size="md">
      {({ panel, pushPanel, goBack, handleClose, markDirty, handleBack, setPanels }) => {
        const selectEditMatch = (matchedPatient: PatientSummaryVO) => {
          setPanels((last) => ({
            ...last,
            list: last.list.map((currentPanel) =>
              currentPanel.id === "contact"
                ? {
                    ...currentPanel,
                    patientFirstName: matchedPatient.name.firstName,
                    patientId: matchedPatient.id,
                  }
                : currentPanel
            ),
          }));
        };

        return panel.id === "add-member" ? (
          <AddFamilyMemberFormPanel
            familyMembers={familyMembers}
            isGuardian={isGuardian}
            patient={patient}
            contactSelection={contactSelection}
            onCreate={onCreated}
            onClearContact={() => {
              markDirty();
              setContactSelection(undefined);
            }}
            onAddContact={(patientFirstName) => pushPanel({ id: "contact", patientFirstName })}
            onEditContact={(patientId, patientFirstName) =>
              pushPanel({ id: "contact", patientId, patientFirstName })
            }
            onSelectContact={(patientFirstName, selection) => {
              markDirty();

              // If the selected contact for the new patient is the same
              // as the current patient that is adding a family member
              // they can be used as a contact and we know their relationship
              // by looking at the family member relationship they chose
              if (selection.name.id === patient.id) {
                setContactSelection(selection);
              } else {
                // because family members are requested in relation to the current patient
                // there is no way we can know how the selected family member contact relates
                // to the new patient so this will force them to have to choose.
                pushPanel({
                  id: "contact",
                  patientId: selection.name.id,
                  patientFirstName,
                });
              }
            }}
            onClose={handleClose}
            onDirty={markDirty}
          />
        ) : panel.patientId ? (
          <EditContactPanel
            key={panel.patientId}
            onCancel={handleBack}
            onDirty={markDirty}
            contactPatientId={panel.patientId}
            onContactSelected={(selection) => {
              setContactSelection(selection);
              goBack();
            }}
            onMatchSelected={selectEditMatch}
            patientFirstName={panel.patientFirstName}
          />
        ) : (
          <AddContactPanel
            onCancel={handleBack}
            onDirty={markDirty}
            onContactSelected={(selection) => {
              setContactSelection(selection);
              goBack();
            }}
            onMatchSelected={selectEditMatch}
            patientFirstName={panel.patientFirstName}
          />
        );
      }}
    </FlyoverReel>
  );
};
