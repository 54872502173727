import { useMemo } from "react";
import { ContactVO, NameVO, PersonalDetailsVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatAddress } from "@libs/utils/address";
import { formatPhoneNumber } from "@libs/utils/phone";
import { Avatar } from "components/UI/Avatar";
import { getPatientDisplayName } from "utils/names";

export type PatientTooltipProps = {
  name: NameVO;
  age: number;
  gender: PersonalDetailsVO["gender"];
  contact?: ContactVO;
  relationLabel?: string;
};

export const PatientTooltip: React.FC<PatientTooltipProps> = ({
  name,
  age,
  gender,
  contact,
  relationLabel,
}) => {
  const hasContactInfo = useMemo(() => contact?.address || contact?.email || contact?.callPhone, [contact]);
  const addressLines = formatAddress(contact?.address);
  const displayName = getPatientDisplayName({ name, age, gender });

  return (
    <div className="flex flex-col w-56" data-testid="contact-tooltip-content">
      <div className={cx("flex items-center gap-x-2", hasContactInfo && "mb-4")}>
        <Avatar name={name.fullDisplayName} />
        <div className="flex flex-col">
          <div className="text-sm">{displayName}</div>
          <div className="text-xs text-greyLight" data-testid="relationship-label">
            {relationLabel}
          </div>
        </div>
      </div>
      {hasContactInfo && (
        <div className="flex flex-col gap-y-2">
          {contact?.callPhone && (
            <div data-testid="contact-info-card-phone">{formatPhoneNumber(contact.callPhone)}</div>
          )}
          {contact?.email && <div data-testid="patient-info-card-email">{contact.email}</div>}
          {contact?.address && (
            <div data-testid="contact-info-card-address">
              {addressLines.lineOne}
              {addressLines.lineTwo ? `, ${addressLines.lineTwo}` : ""}
            </div>
          )}
        </div>
      )}
    </div>
  );
};
