import { useQuery } from "@tanstack/react-query";
import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { MountVO, PatientSummaryVO } from "@libs/api/generated-api";
import { SUCCESS } from "@libs/utils/statusCodes";
import { formatAsISODate, formatISODate, getLocalDate } from "@libs/utils/date";
import { pluralize } from "@libs/utils/pluralize";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isDefined } from "@libs/utils/types";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Spinner } from "@libs/components/UI/Spinner";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as CheckIcon } from "@libs/assets/icons/check.svg";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { ReactComponent as WarningIcon } from "@libs/assets/icons/warning.svg";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useAccount } from "@libs/contexts/AccountContext";
import { ButtonCell, CheckboxCell, Row, TableGrid } from "@libs/components/UI/GridTableComponents";
import { CaptureSessionSearchResult, MountLayoutType } from "api/imaging/imaging-api";
import { ImagingHttpApi } from "api/imaging/imaging-hub";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent, FlyoverFooter, FlyoverForm } from "components/UI/FlyoverComponents";
import { lookupPatientSummary } from "api/patients/queries";

import { SearchPatientsModal } from "components/Main/SearchPatientsModal";
import { batchPrepareFileUpload } from "api/file-uploads/mutations";
import { fetchMountWithId, generateMount, uploadMedicalImageV2 } from "api/imaging/mutations";
import { useUploadMedicalImages } from "components/PatientProfile/Imaging/MountRoute/hooks/useUploadMedicalImages";
import { useImagingDeviceSettings } from "components/PatientProfile/Imaging/hooks/useImagingDeviceSettings";
import { handleError } from "utils/handleError";
import {
  UploadProgressHandler,
  handleSuccessfulCapture,
} from "components/PatientProfile/Imaging/MountRoute/hooks/useArchyAgentUploader";
import { getLayoutForLayoutName } from "components/PatientProfile/Imaging/PatientMountsList/mountLayouts";
import { generateMountDestinations } from "components/PatientProfile/Imaging/MountRoute/hooks/useMountLayout";
import { UploadingImagesModal } from "components/PatientProfile/Imaging/UploadingImagesModal";
import { groupBy } from "utils/groupBy";

type OfflineUploadFlyoverProps = {
  onRequestClose: Func;
};

const useOfflineImagesQuery = (query: { IncludeUploaded?: boolean }) => {
  return useQuery(
    ["archy-imaging-service-api", "search", query],
    () => {
      return ImagingHttpApi.images.search(query).then((res) => {
        if (res.status !== SUCCESS) {
          throw new Error(`Could not fetch offline images, status: ${res.status}`);
        }

        return res.data.data;
      });
    },
    {
      cacheTime: 0,
    }
  );
};

const PatientContent: React.FC<{ session: CaptureSessionSearchResult }> = ({ session }) => {
  const imageCount = session.images?.length ?? 0;
  const dateTaken = session.images?.[0].dateTime;

  return (
    <>
      <div className="font-sansSemiBold">
        {session.firstName} {session.lastName}
        {session.dateOfBirth ? `, ${formatISODate(session.dateOfBirth)}` : ""}
      </div>
      <div>
        {imageCount} {pluralize(imageCount, "image", "images")} on{" "}
        {dateTaken ? formatISODate(dateTaken) : "unknown date"}
      </div>
    </>
  );
};

type MatchedPatients = Record<number, PatientSummaryVO | undefined>;

const OfflineSessionRow: React.FC<{
  session: CaptureSessionSearchResult;
  index: number;
  checked: boolean;
  onClickSearchUnmatchedPatient: (params: { session: CaptureSessionSearchResult; index: number }) => void;
  setSessionsChecked: React.Dispatch<React.SetStateAction<Set<number>>>;
  setMatchedPatients: React.Dispatch<React.SetStateAction<MatchedPatients>>;
  practiceId: number;
  patientMatch?: PatientSummaryVO;
}> = ({
  session,
  checked,
  setSessionsChecked,
  setMatchedPatients,
  onClickSearchUnmatchedPatient,
  index,
  practiceId,
  patientMatch,
}) => {
  const [{ data: autoPatientMatch, isLoading }] = useApiQueries([
    lookupPatientSummary({
      args: {
        firstName: session.firstName,
        lastName: session.lastName,
        practiceId,
        dob: session.dateOfBirth ? formatAsISODate(getLocalDate(session.dateOfBirth)) : "",
      },
    }),
  ]);

  const handleSelectedUpdated = useCallback(
    (newCheckedValue: boolean) => {
      setSessionsChecked((sessionsChecked) => {
        if (newCheckedValue) {
          return new Set([...sessionsChecked, index]);
        }

        return new Set([...sessionsChecked].filter((j) => j !== index));
      });
    },
    [setSessionsChecked, index]
  );

  const toggleChecked = useCallback(() => {
    if (patientMatch) {
      handleSelectedUpdated(!checked);
    }
  }, [checked, handleSelectedUpdated, patientMatch]);
  const handleSearchClicked = useCallback(() => {
    onClickSearchUnmatchedPatient({ session, index });
  }, [onClickSearchUnmatchedPatient, session, index]);

  useEffect(() => {
    if (autoPatientMatch) {
      handleSelectedUpdated(true);
      setMatchedPatients((matchedPatients) => {
        return {
          ...matchedPatients,
          [index]: autoPatientMatch,
        };
      });
    }
  }, [autoPatientMatch, handleSelectedUpdated, setMatchedPatients, index, session]);

  const sharedProps = {
    hideHover: !patientMatch,
    onClick: toggleChecked,
  };

  return (
    <Row>
      <CheckboxCell
        styleOptions={sharedProps}
        disabled={!patientMatch || isLoading}
        value={index}
        checked={checked}
        onChange={toggleChecked}
      />
      <ButtonCell {...sharedProps}>
        <PatientContent session={session} />
      </ButtonCell>
      <ButtonCell {...sharedProps}>
        {isLoading ? (
          <Spinner size="xs" variant="secondary" animation="border" />
        ) : patientMatch ? (
          <Icon size="md" SvgIcon={CheckIcon} className="text-green" />
        ) : (
          <Icon size="md" SvgIcon={WarningIcon} className="text-orange" />
        )}
      </ButtonCell>

      <ButtonCell {...sharedProps} onClick={patientMatch ? sharedProps.onClick : handleSearchClicked}>
        {!patientMatch && !isLoading && <Icon SvgIcon={SearchIcon} />}
      </ButtonCell>
    </Row>
  );
};

const COLUMN_WIDTHS = ["3rem", "auto", "3.1rem", "3.1rem"];

export const OfflineUploadFlyover: React.FC<OfflineUploadFlyoverProps> = ({ onRequestClose }) => {
  const offlineImagesQuery = useOfflineImagesQuery({ IncludeUploaded: false });
  const [sessionsChecked, setSessionsChecked] = React.useState<Set<number>>(new Set([]));
  const { practiceId } = useAccount();
  const [matchedPatients, setMatchedPatients] = React.useState<MatchedPatients>({});
  const searchPatientsModal = useBoolean(false);
  const uploadingModal = useBoolean(false);
  const uploadingModalOn = uploadingModal.on;
  const { getSensorSettings } = useImagingDeviceSettings();

  const [sessionIndexUploading, setSessionIndexUploading] = useState<number | undefined>(undefined);

  const matchingSessionIndex = useRef<number | undefined>(undefined);
  const totalImagesUploading = useRef(0);
  const [batchPrepareUploadMutation, linkMedicalImageMutation, createMountMutation, fetchMount] =
    useApiMutations([batchPrepareFileUpload, uploadMedicalImageV2, generateMount, fetchMountWithId]);

  const { uploadImages } = useUploadMedicalImages({
    practiceId,
    prepareUploadsMutateAsync: batchPrepareUploadMutation.mutateAsync,
    linkMedicalImageMutateAsync: linkMedicalImageMutation.mutateAsync,
  });
  const handlePatientMatchClicked = useCallback(
    (patient: PatientSummaryVO) => {
      setMatchedPatients((priorMatched) => {
        if (
          isDefined(matchingSessionIndex.current) &&
          offlineImagesQuery.data?.[matchingSessionIndex.current]
        ) {
          return {
            ...priorMatched,
            [matchingSessionIndex.current]: patient,
          };
        }

        return priorMatched;
      });
    },
    [offlineImagesQuery.data]
  );
  const handleClickUnmatchedPatient = useCallback(
    ({ index }: { index: number; session: CaptureSessionSearchResult }) => {
      matchingSessionIndex.current = index;
      searchPatientsModal.on();
    },
    [searchPatientsModal]
  );

  const createMountMutateAsync = createMountMutation.mutateAsync;
  const fetchMountAsync = fetchMount.mutateAsync;
  const [uploadPercentComplete, setUploadPercentComplete] = useState<number>(0);
  const [processingPercentComplete, setProcessingPercentComplete] = useState<number>(0);

  const uploadingStats = useMemo(() => {
    const sessions = offlineImagesQuery.data ?? [];
    const sessionsToUpload = sessions.filter((_, i) => sessionsChecked.has(i));
    const uploadingTotalCount = sessionsToUpload.reduce((acc, session) => {
      return acc + (session.images?.length ?? 0);
    }, 0);

    return {
      sessionsToUpload,
      uploadingTotalCount,
    };
  }, [offlineImagesQuery.data, sessionsChecked]);
  const { sessionsToUpload, uploadingTotalCount } = uploadingStats;

  const handleProgress: UploadProgressHandler = useCallback(({ type, value }) => {
    if (type === "upload") {
      setUploadPercentComplete((prev) => prev + value);
    } else {
      setProcessingPercentComplete((prev) => prev + value);
    }
  }, []);
  const handleUpload: React.FormEventHandler<HTMLFormElement> = useCallback(
    // eslint-disable-next-line max-statements
    async (e) => {
      e.preventDefault();
      uploadingModalOn();

      for (const [index, session] of sessionsToUpload.entries()) {
        const matchedPatient = matchedPatients[index];
        const allSensorSettings = getSensorSettings();

        setSessionIndexUploading(index);

        try {
          if (matchedPatient && session.images) {
            const mountId = session.mountId;
            let mount: MountVO | undefined = undefined;

            if (mountId) {
              const {
                data: { data: existingMount },
              } = await fetchMountAsync({
                practiceId,
                patientId: matchedPatient.id,
                mountId,
                query: {
                  includeArchived: true,
                },
              });

              mount = existingMount;
            } else {
              const {
                data: { data: newMount },
              } = await createMountMutateAsync({
                practiceId,
                patientId: matchedPatient.id,
                data: {
                  layout: session.mountLayout ?? "Grid",
                  name: "",
                },
              });

              mount = newMount;
            }

            const mountLayout = mount.layout as MountLayoutType;
            const mountLayoutConfig = getLayoutForLayoutName(mountLayout, mount.images);
            const sandboxImages = generateMountDestinations({
              mount,
              mountLayoutConfig,
            });
            const imagesByDeviceName = groupBy(session.images, "deviceName");
            const totalImageCount = Object.values(imagesByDeviceName).reduce(
              (prev, curr) => prev + (curr?.length ?? 0),
              0
            );

            totalImagesUploading.current = totalImageCount;
            for (const [deviceName, images] of Object.entries(imagesByDeviceName)) {
              // eslint-disable-next-line max-depth
              if (!images) {
                continue;
              }

              const sensorSettings = allSensorSettings.find((s) => s.label === deviceName);

              await handleSuccessfulCapture({
                uploadImages,
                data: images,
                sandboxImages,
                mountLayout,
                deviceId: deviceName,
                sensorSettings,
                mountId: mount.id,
                patientId: matchedPatient.id,
                onProgress: handleProgress,
              });
            }
          }
        } catch (err) {
          handleError(err);
        }
      }
      totalImagesUploading.current = 0;
      setProcessingPercentComplete(0);
      setUploadPercentComplete(0);
      setSessionIndexUploading(undefined);
    },
    [
      uploadingModalOn,
      sessionsToUpload,
      matchedPatients,
      getSensorSettings,
      fetchMountAsync,
      practiceId,
      createMountMutateAsync,
      uploadImages,
      handleProgress,
    ]
  );

  return (
    <>
      <Flyover title="Upload Offline Images" onClose={onRequestClose} size="md">
        <FlyoverForm fieldLayout="labelOut" onSubmit={handleUpload}>
          <FlyoverContent className="text-sm">
            <div>The following patients have images available for upload.</div>
            <TableGrid columnWidths={COLUMN_WIDTHS}>
              {offlineImagesQuery.data?.map((session, i) => {
                return (
                  <OfflineSessionRow
                    key={i}
                    practiceId={practiceId}
                    index={i}
                    session={session}
                    checked={sessionsChecked.has(i)}
                    setSessionsChecked={setSessionsChecked}
                    setMatchedPatients={setMatchedPatients}
                    onClickSearchUnmatchedPatient={handleClickUnmatchedPatient}
                    patientMatch={matchedPatients[i]}
                  />
                );
              })}
            </TableGrid>
          </FlyoverContent>
          <FlyoverFooter>
            <Button className="min-w-button" theme="secondary" onClick={onRequestClose}>
              Cancel
            </Button>
            <AsyncButton
              isLoading={isDefined(sessionIndexUploading)}
              disabled={sessionsChecked.size === 0}
              className="min-w-button"
              type="submit"
            >
              Upload
            </AsyncButton>
          </FlyoverFooter>
        </FlyoverForm>
      </Flyover>
      {searchPatientsModal.isOn && (
        <SearchPatientsModal
          onRequestClose={searchPatientsModal.off}
          onClickPatient={handlePatientMatchClicked}
        />
      )}
      {uploadingModal.isOn && (
        <UploadingImagesModal
          uploadComplete={sessionIndexUploading === undefined}
          percentComplete={
            // eslint-disable-next-line @typescript-eslint/no-magic-numbers
            (uploadPercentComplete + processingPercentComplete) / totalImagesUploading.current / 2
          }
          totalCount={uploadingTotalCount}
          onRequestClose={() => {
            uploadingModal.off();
            onRequestClose();
          }}
        />
      )}
    </>
  );
};
