import { useCallback, useMemo, useRef, useState } from "react";
import { shift, offset } from "@floating-ui/react-dom-interactions";
import { InsurancePlanVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { Menu } from "@libs/components/UI/Menu";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useAccount } from "@libs/contexts/AccountContext";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { ActionModal } from "components/UI/ActionModal";
import { getPracticeFeeScheduleNamesQuery } from "api/feeSchedule/queries";

interface Props {
  autoVerifiedInsurancePlan: InsurancePlanVO;
  onClose: Func;
  onSave: (feeScheduleId: number) => Promise<void>;
}

const MENU_OFFSET = 4;

type FeeScheduleOption = {
  label: string;
  patientCount: number;
  value: number;
};
export const FeeScheduleActionModal: React.FC<Props> = ({ autoVerifiedInsurancePlan, onClose, onSave }) => {
  const { practiceId } = useAccount();
  const [feeScheduleId, setFeeScheduleId] = useState<number>();
  const [searchString, setSearchString] = useState("");

  const [feeSchedulesQuery] = useApiQueries([
    getPracticeFeeScheduleNamesQuery({
      args: { carrierId: autoVerifiedInsurancePlan.carrier.id, practiceId },
    }),
  ]);

  const menu = useBoolean(false);
  const triggerRef = useRef<HTMLInputElement | null>(null);

  const handleSearch = useCallback(
    (value: string) => {
      if (menu.isOff) {
        menu.on();
      }

      setSearchString(value);
      setFeeScheduleId(undefined);
    },
    [menu]
  );

  const options: FeeScheduleOption[] = useMemo(() => {
    return (
      feeSchedulesQuery.data
        ?.map((fs) => ({
          label: fs.name,
          patientCount: fs.patients,
          value: fs.id,
        }))
        .filter((fs) => fs.label.toLowerCase().includes(searchString.toLowerCase())) ?? []
    );
  }, [feeSchedulesQuery.data, searchString]);

  const handleSave = useCallback(() => {
    if (feeSchedulesQuery.data?.length === 1) {
      const firstOption = feeSchedulesQuery.data[0];

      onSave(firstOption.id);
    } else if (feeScheduleId && searchString) {
      onSave(feeScheduleId);
    }
  }, [feeScheduleId, feeSchedulesQuery.data, onSave, searchString]);

  return (
    <ActionModal
      displaySecondaryButton={false}
      disableClose={true}
      handleClose={onClose}
      handleSave={handleSave}
      primaryButtonDisabled={!feeScheduleId && options.length > 1}
      primaryButtonText="Apply"
      size="2xs"
      title="Fee Schedule"
    >
      <div className="flex flex-col gap-y-1 text-xs">
        <FormFieldLabel className="font-sansSemiBold" content="Search Fee Schedule" id="search" />
        <span>This plan doesn&apos;t have a linked fee schedule yet.</span>
        <FormFieldInput
          className="w-full mt-1"
          Icon={SearchIcon}
          id="search"
          onChange={(e) => handleSearch(e.target.value)}
          onFocus={menu.on}
          placeholder="Search fee schedules..."
          ref={triggerRef}
          value={feeSchedulesQuery.data?.length === 1 ? feeSchedulesQuery.data[0].name : searchString}
        />
        {menu.isOn && (
          <Menu
            className={`
              max-h-80
              p-1
              bg-white
              border
              border-slate-200
              rounded
              shadow-main
              overflow-y-auto
            `}
            matchTriggerWidth={true}
            middleware={[shift({ crossAxis: true }), offset(MENU_OFFSET)]}
            onRequestClose={menu.off}
            triggerRef={triggerRef}
            placement="bottom-start"
          >
            {options.length ? (
              <MenuOptions
                options={createMenuOptions(
                  ...options.map((option) => ({
                    label: (
                      <div className="flex flex-col w-full" key={option.value}>
                        <span className="text-xs">{option.label}</span>
                        <span className="text-xxs text-slate-700">{option.patientCount} Patients</span>
                      </div>
                    ),
                    title: option.label,
                    value: option.value,
                  }))
                )}
                onOptionClick={(option) => {
                  setSearchString(option.title);
                  setFeeScheduleId(option.value);
                  menu.off();
                }}
              />
            ) : (
              <span className="flex justify-center px-3 py-2 text-xs">
                No fee schedules matching {searchString}
              </span>
            )}
          </Menu>
        )}
      </div>
    </ActionModal>
  );
};
