import { useCallback, useEffect, useMemo, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { format } from "date-fns";
import { PerioChartExamSettingsVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { toPercent } from "@libs/utils/math";
import { getLocalDate } from "@libs/utils/date";
import { maxLength } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import { Cell, TableGrid, cxGridTableStyles } from "@libs/components/UI/GridTableComponents";
import { PerioChartToothSurfaceType } from "components/Charting/Perio/PerioTypes";
import { PerioChartExamRecord, SequenceType } from "components/Charting/Perio/perioChartExamRecord";
import {
  getExamTeethSummary,
  getFieldNames,
  sequenceToFullTitle,
  sequenceToShortTitle,
  sequenceToThresholdSettings,
} from "components/Charting/Perio/perioChartUtils";
import { cxStyles } from "components/Charting/Perio/PerioChartTeeth";
import { PerioPrintOption, usePerioChart } from "components/Charting/Perio/PerioChartContext";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";

const SUMMARY_SEQUENCE_TYPES: SequenceType[] = [
  "PROBING",
  "GINGMARGIN",
  "PLAQUE",
  "CALCULUS",
  "BLEEDING",
  "SUPPURATION",
];

type Props = {
  settings: PerioChartExamSettingsVO;
  currentExam: PerioChartExamRecord;
  printOptions: Set<PerioPrintOption>;
  isPrinting: boolean;
};

type SummarySection = {
  title: string;
  teeth: {
    count: number;
    percent: number;
  };
  sites: {
    count: number;
    percent: number;
  };
};

const SUBSCRIPTION_DEBOUNCE_MS = 1000;

const getTeethSummary = ({
  sequenceType,
  toothCount,
  siteCount,
  totalTeethCount,
  totalSiteCount,
}: {
  sequenceType: SequenceType;
  toothCount: number;
  siteCount: number;
  totalTeethCount: number;
  totalSiteCount: number;
}): SummarySection => {
  return {
    title:
      sequenceType === "PROBING"
        ? "Probing Depth Alert"
        : sequenceType === "GINGMARGIN"
          ? sequenceToFullTitle[sequenceType]
          : sequenceToShortTitle[sequenceType],
    teeth: {
      count: toothCount,
      percent: totalTeethCount > 0 ? toothCount / totalTeethCount : 0,
    },
    sites: {
      count: siteCount,
      percent: totalTeethCount ? siteCount / totalSiteCount : 0,
    },
  };
};
const useSummarySections = ({ settings, currentExam }: Pick<Props, "currentExam" | "settings">) => {
  const [changeCount, setChangeCount] = useState(0);

  const setChangeCountDebounced = useDebouncedCallback(setChangeCount, SUBSCRIPTION_DEBOUNCE_MS);

  useEffect(() => {
    const unsubscribe = currentExam.subscribeToEntriesChanged(() => {
      setChangeCountDebounced((prevCount) => prevCount + 1);
    });

    return unsubscribe;
  }, [currentExam, setChangeCountDebounced]);

  return useMemo(
    () => {
      const exam = currentExam.getExamInfo();
      const teethConfigs = [...exam.upperToothConfig, ...exam.lowerToothConfig];

      const { totalTeethCount, totalImplantCount, totalSiteCount } = getExamTeethSummary(exam);
      const summaries = SUMMARY_SEQUENCE_TYPES.map((sequenceType) => {
        const siteCountByTooth = teethConfigs
          .filter((toothConfig) => !toothConfig.missing)
          .map((toothConfig) => {
            const { toothNum } = toothConfig;
            const toothInfo = currentExam.getEntry(toothNum, sequenceType);

            const sitesPopulated = [PerioChartToothSurfaceType.FACIAL, PerioChartToothSurfaceType.LINGUAL]
              // eslint-disable-next-line max-nested-callbacks
              .map((surface) => {
                const fields = getFieldNames(surface, toothNum);

                // eslint-disable-next-line max-nested-callbacks
                return fields.filter((field) => {
                  if (sequenceType === "PROBING" || sequenceType === "GINGMARGIN") {
                    const thresholdConfig = sequenceToThresholdSettings[sequenceType];

                    if (thresholdConfig?.setting) {
                      const settingThresholdValue = settings[thresholdConfig.setting];
                      const probingValue = Math.abs(toothInfo?.[field] ?? 0);

                      return probingValue >= settingThresholdValue;
                    }

                    return false;
                  }

                  return Boolean(toothInfo?.[field]);
                }).length;
              })
              // eslint-disable-next-line max-nested-callbacks
              .reduce((acc, curr) => acc + curr, 0);

            return {
              toothConfig,
              sitesPopulated,
            };
          });

        const dentitionSummary = getTeethSummary({
          sequenceType,
          toothCount: siteCountByTooth.filter(({ sitesPopulated }) => sitesPopulated > 0).length,
          siteCount: siteCountByTooth.reduce((acc, { sitesPopulated }) => acc + sitesPopulated, 0),
          totalTeethCount,
          totalSiteCount,
        });
        const implantSummary = getTeethSummary({
          sequenceType,
          toothCount: siteCountByTooth.filter(
            ({ sitesPopulated, toothConfig }) => toothConfig.implant && sitesPopulated > 0
          ).length,
          siteCount: siteCountByTooth.reduce(
            (acc, { sitesPopulated, toothConfig }) => acc + (toothConfig.implant ? sitesPopulated : 0),
            0
          ),
          totalTeethCount: totalImplantCount,
          totalSiteCount,
        });

        return { dentitionSummary, implantSummary, totalImplantCount, totalTeethCount };
      });

      return {
        dentionSummary: summaries.map(({ dentitionSummary }) => dentitionSummary),
        implantSummary: summaries.map(({ implantSummary }) => implantSummary),
        totalImplantCount,
        totalTeethCount,
      };
    },
    // We have lastRecordUpdated as a dependency because we want to recompute the summary sections when they change (debounced)
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [currentExam, settings, changeCount]
  );
};

const COLUMN_WIDTHS = ["1fr", "1fr", "1fr"];

const SummarySectionCell: React.FC<{
  children: React.ReactNode;
  className?: string;
  borderBottom?: boolean;
}> = ({ className, children, borderBottom }) => {
  return (
    <Cell
      borderBottom={borderBottom}
      className={cx("px-3", cxGridTableStyles.cellPadding({ horizontal: "none" }), className)}
    >
      {children}
    </Cell>
  );
};

const SummarySectionItem: React.FC<{ section: SummarySection }> = ({ section }) => {
  return (
    <TableGrid className="w-40 rounded-md border border-slate-300" columnWidths={COLUMN_WIDTHS}>
      <SummarySectionCell className="col-span-full rounded-t-md font-sansSemiBold">
        {section.title}
      </SummarySectionCell>
      <SummarySectionCell>Teeth</SummarySectionCell>
      <SummarySectionCell className="text-right">{section.teeth.count}</SummarySectionCell>
      <SummarySectionCell>{toPercent(section.teeth.percent)}%</SummarySectionCell>
      <SummarySectionCell borderBottom={false}>Sites</SummarySectionCell>
      <SummarySectionCell className="text-right" borderBottom={false}>
        {section.sites.count}
      </SummarySectionCell>
      <SummarySectionCell borderBottom={false}>{toPercent(section.sites.percent)}%</SummarySectionCell>
    </TableGrid>
  );
};

const PerioSection: React.FC<{ children: React.ReactNode; title: string; className?: string }> = ({
  children,
  title,
  className,
}) => {
  return (
    <div className="flex flex-row">
      <div className={cx("mr-4", cxStyles.rowTitleContainer)} />
      <div className={cx("flex flex-col", className)}>
        <div className="font-sansSemiBold text-base my-6">{title}</div>
        {children}
      </div>
    </div>
  );
};

const PerioSummaryTable: React.FC<{ title: string; summaries: SummarySection[]; emptyMessage?: string }> = ({
  title,
  summaries,
  emptyMessage,
}) => {
  return (
    <PerioSection title={title}>
      {emptyMessage ? (
        <div className="text-slate-500 pb-6">{emptyMessage}</div>
      ) : (
        <div className="flex flex-row gap-6 pb-6">
          {summaries.map((section) => {
            return <SummarySectionItem key={section.title} section={section} />;
          })}
        </div>
      )}
    </PerioSection>
  );
};
const NOTE_MAX_LEN = 5000;

const schema = {
  note: [{ $v: maxLength(NOTE_MAX_LEN), $error: `Maximum characters is ${NOTE_MAX_LEN}.`, $validate: true }],
};
const THREE_SECONDS_IN_MILLISECONDS = 3000;

const PerioNotes: React.FC<{ isPrinting: boolean; exam: PerioChartExamRecord }> = ({ isPrinting, exam }) => {
  const { updateExamInfo } = usePerioChart();
  const [note, setNote] = useState<string | undefined>(exam.getExamInfo().note);

  const validation = useValidation({ note }, schema);
  const saveNoteChanges = useCallback(() => {
    if (validation.validate().$isValid) {
      return updateExamInfo({ note });
    }

    return undefined;
  }, [note, updateExamInfo, validation]);
  const saveChangesDebounced = useDebouncedCallback(saveNoteChanges, THREE_SECONDS_IN_MILLISECONDS);

  useEffect(() => {
    return saveChangesDebounced.flush;
  }, [saveChangesDebounced.flush]);

  return (
    <PerioSection title="Notes" className="flex-1">
      {isPrinting ? (
        <div className="text-sm">{note}</div>
      ) : (
        <FormFieldTextarea
          disableResize={true}
          maxLength={NOTE_MAX_LEN}
          rows={8}
          edit={!isPrinting}
          placeholder="Note text..."
          onChange={(e) => {
            const updatedNote = e.currentTarget.value;

            setNote(updatedNote);
            saveChangesDebounced();
          }}
          value={note}
          error={validation.result.note.$error}
        />
      )}
    </PerioSection>
  );
};

const isSettingEnabled = (setting?: boolean) => setting !== false;

export const PerioChartSummaryAndNotes: React.FC<Props> = ({
  settings,
  currentExam,
  printOptions,
  isPrinting,
}) => {
  const { dentionSummary, implantSummary, totalImplantCount } = useSummarySections({ settings, currentExam });
  const exam = currentExam.getExamInfo();
  const shouldShowDentition = isPrinting
    ? printOptions.has("dentition-summary")
    : isSettingEnabled(settings.showDentitionSummary);
  const shouldShowImplantSummary = isPrinting
    ? printOptions.has("implant-summary")
    : isSettingEnabled(settings.showImplantSummary);
  const shouldShowNotes = !isPrinting || printOptions.has("notes");

  return (
    <>
      {shouldShowDentition && <PerioSummaryTable title="Dentition Summary" summaries={dentionSummary} />}
      {shouldShowImplantSummary && (
        <PerioSummaryTable
          title="Implant Summary"
          emptyMessage={
            totalImplantCount === 0
              ? `No implants present when charted on ${format(getLocalDate(exam.date), "MMM d, yyyy")}`
              : undefined
          }
          summaries={implantSummary}
        />
      )}

      {shouldShowNotes && <PerioNotes exam={currentExam} isPrinting={isPrinting} />}
    </>
  );
};
