import { FC, PropsWithChildren } from "react";
import Skeleton from "react-loading-skeleton";
import { EmployeeRolesVO, NameVO, RoleVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon } from "@libs/components/UI/Icon";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as CopyIcon } from "@libs/assets/icons/copy.svg";
import { ReactComponent as LockIcon } from "@libs/assets/icons/lock.svg";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import {
  ButtonCell,
  EMPTY_CELL,
  HeaderCell,
  Row,
  TableGrid,
  TextCell,
} from "@libs/components/UI/GridTableComponents";
import { Avatar } from "components/UI/Avatar";
import { paths } from "utils/routing/paths";
import { OverflowItems } from "components/UI/OverflowItems";

const COLUMNS = ["240px", "1fr", "min-content"];

export type RoleHandler = (role: RoleVO) => void;

export const RolesTable: FC<{
  roles: EmployeeRolesVO[];
  onRowClick: RoleHandler;
  onEditClick: RoleHandler;
  onCopyClick: RoleHandler;
  onDeleteClick: (role: RoleVO, employees: NameVO[]) => void;
}> = ({ roles, ...rest }) => {
  return (
    <TableGrid columnWidths={COLUMNS}>
      <HeadersRow />
      {roles.map((role) => (
        <RoleRow
          key={role.role.roleId}
          role={role.role}
          employees={role.employees}
          canEdit={!role.role.archyOwned}
          canDelete={!role.role.archyOwned}
          {...rest}
        />
      ))}
    </TableGrid>
  );
};

const RoleRow: FC<{
  role: RoleVO;
  employees: NameVO[];
  canEdit: boolean;
  canDelete: boolean;
  onRowClick: RoleHandler;
  onEditClick: RoleHandler;
  onCopyClick: RoleHandler;
  onDeleteClick: (role: RoleVO, employees: NameVO[]) => void;
}> = ({ role, employees, canEdit, canDelete, onRowClick, onEditClick, onCopyClick, onDeleteClick }) => {
  const menu = useBoolean(false);
  const menuOptions = createMenuOptions(
    {
      label: "Edit",
      value: "EDIT",
      SvgIcon: EditIcon,
      disabled: !canEdit,
    },
    {
      label: "Copy",
      value: "COPY",
      SvgIcon: CopyIcon,
    },
    {
      label: "Delete",
      value: "DELETE",
      SvgIcon: DeleteIcon,
      disabled: !canDelete,
    }
  );

  return (
    <Row>
      <ButtonCell onDoubleClick={() => onRowClick(role)}>
        <div className="flex justify-start items-center gap-x-2">
          {role.name}{" "}
          {role.archyOwned ? (
            <Icon
              SvgIcon={LockIcon}
              theme="slate500"
              size="sm"
              tooltip={{
                content:
                  "This role cannot be modified or deleted. To reassign an employee’s role, go to their profile.",
              }}
            />
          ) : null}
        </div>
      </ButtonCell>
      <ButtonCell onDoubleClick={() => onRowClick(role)}>
        {employees.length > 0 ? (
          <OverflowItems
            className="items-center"
            items={employees}
            renderItem={(employeeName) => (
              <div key={employeeName.id}>
                <ButtonInternalLink theme="link" to={paths.employeeEmployment({ id: employeeName.id })}>
                  <Avatar key={employeeName.id} theme="slate" size="sm" name={employeeName.fullDisplayName} />
                </ButtonInternalLink>
              </div>
            )}
            renderOverflow={(overflowItems) => <EmployeeAvatarOverflow employees={overflowItems} />}
          />
        ) : (
          EMPTY_CELL
        )}
      </ButtonCell>
      <Cell>
        <ButtonMenu
          data-testid="role-menu-dropdown"
          placement="bottom-start"
          isOpen={menu.isOn}
          onRequestOpen={menu.on}
          menuContent={
            <MenuOptions
              options={menuOptions}
              onOptionClick={(option) => {
                switch (option.value) {
                  case "EDIT": {
                    onEditClick(role);
                    break;
                  }
                  case "COPY": {
                    onCopyClick(role);
                    break;
                  }
                  case "DELETE": {
                    onDeleteClick(role, employees);
                    break;
                  }
                  // no default
                }

                menu.off();
              }}
            />
          }
          onRequestClose={menu.off}
        >
          {(props) => {
            return <ButtonIcon data-testid="role-menu-button" {...props} SvgIcon={MenuIcon} />;
          }}
        </ButtonMenu>
      </Cell>
    </Row>
  );
};

const EmployeeAvatarOverflow: FC<{ employees: NameVO[] }> = ({ employees }) => {
  return (
    <FloatingTooltip content={<EmployeeOverflowTooltipContent employees={employees} />}>
      <div>+{employees.length}</div>
    </FloatingTooltip>
  );
};

const EmployeeOverflowTooltipContent: FC<{ employees: NameVO[] }> = ({ employees }) => {
  return (
    <ul className="flex flex-col gap-y-1">
      {employees.map((employee) => (
        <li key={employee.id}>
          <ButtonInternalLink to={paths.employeeEmployment({ id: employee.id })} theme="link">
            {employee.fullDisplayName}
          </ButtonInternalLink>
        </li>
      ))}
    </ul>
  );
};

const Cell: FC<PropsWithChildren> = ({ children }) => (
  <TextCell className="flex items-center">{children}</TextCell>
);

const HeadersRow: FC = () => (
  <Row>
    <HeaderCell size="short">Name</HeaderCell>
    <HeaderCell size="short">Employees</HeaderCell>
    <HeaderCell size="short" />
  </Row>
);

export const LoadingSkeleton: FC = () => (
  <div data-testid="loading-skeleton">
    <TableGrid columnWidths={COLUMNS}>
      <HeadersRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
      <SkeletonRow />
    </TableGrid>
  </div>
);

const SkeletonRow: FC = () => (
  <Row>
    <Cell>
      <Skeleton containerClassName="w-full" />
    </Cell>
    <Cell>
      <Skeleton containerClassName="w-full h-6" />
    </Cell>
    <Cell>
      <Icon SvgIcon={MenuIcon} />
    </Cell>
  </Row>
);
