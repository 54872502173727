import { FC, useMemo } from "react";
import { AppointmentCardConfigVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";

import { CheckmarkLabel } from "components/Settings/Scheduling/Sections/AppointmentCardDesign/CheckmarkLabel";
import { AppointmentCardPreview } from "components/Settings/Scheduling/Sections/AppointmentCardDesign/AppointmentCardPreview";
import { Divider } from "components/UI/Divider";
import {
  indicatorLabelMap,
  getBackgroundLabel,
  getLabelForFontSize,
} from "components/Settings/Scheduling/Sections/AppointmentCardDesign/utils";
import { ConfigLabel } from "components/Settings/Scheduling/Sections/AppointmentCardDesign/CardConfigForm";

export const cxCardConfigStyles = {
  formContainer: "flex flex-col h-full",
  layoutContainer: "flex h-full w-full",
  colContainer: "flex-1 flex flex-col p-6 gap-y-6 overflow-y-auto",
  previewContainer: `
    flex-1
    flex
    flex-col
    justify-start
    max-w-xl
    p-6
    items-center
    bg-slate-50
  `,
  previewWrapper: "p-5",
  sectionLabel: "font-sansSemiBold text-sm text-greyDark",
  configLabel: `
    flex
    items-center
    gap-x-3
    h-6
    font-sansSemiBold
    text-xs
    text-greyDark
  `,
  indicatorRow: "flex items-center gap-x-3 min-h-6",
  settingContainer: "flex-1 flex flex-col",
  settingsContainer: "flex",
  tabButton: (selected: boolean) =>
    cx("px-1.5 py-1.5", selected && "outline outline-primaryTheme bg-archyBlue-50  rounded-md"),
};

interface Props {
  config: AppointmentCardConfigVO;
  currentUserName: string | undefined;
}

const SectionHeader: FC<{ title: string; children: React.ReactNode }> = ({ title, children }) => {
  return (
    <div className="flex flex-col gap-y-1">
      <span className={cxCardConfigStyles.sectionLabel}>{title}</span>
      <p className="text-xs">{children}</p>
    </div>
  );
};

const CardCheckmarkLabel: FC<{ hidden?: boolean; label?: string }> = ({ hidden, label }) => (
  <CheckmarkLabel label={label ?? (hidden ? "Hidden" : "Shown")} />
);

export const ColorSettingsHeader: FC = () => (
  <SectionHeader title="Color Settings">
    Choose which colors are indicated on your appointment cards in the schedule.
  </SectionHeader>
);

export const NameSettingsHeader: FC = () => (
  <SectionHeader title="Patient Name Display Settings">
    Choose how the patient’s name appears on the card
  </SectionHeader>
);

export const CardConfig: FC<Props> = ({ config, currentUserName }) => {
  const { indicators, showDentistColor, hidePreferredName, fontSize } = config;
  const orderedIndicators = useMemo(
    () => (indicators.length > 1 ? indicators.sort((a, b) => a.localeCompare(b)) : indicators),
    [indicators]
  );
  const backgroundLabel = useMemo(() => getBackgroundLabel(config), [config]);

  return (
    <div className={cxCardConfigStyles.layoutContainer}>
      <div className={cxCardConfigStyles.colContainer}>
        <ColorSettingsHeader />
        <div className={cxCardConfigStyles.settingsContainer}>
          <div className={cxCardConfigStyles.settingContainer}>
            <ConfigLabel title="Indicators" />
            <div className={cxCardConfigStyles.indicatorRow}>
              {orderedIndicators.length ? (
                orderedIndicators.map((indicator) => (
                  <CardCheckmarkLabel key={indicator} label={indicatorLabelMap[indicator]} />
                ))
              ) : (
                <CardCheckmarkLabel key="STATUS" label={indicatorLabelMap["STATUS"]} />
              )}
            </div>
          </div>
          <div className={cxCardConfigStyles.settingContainer}>
            <ConfigLabel title="Background Color" />
            <div className={cxCardConfigStyles.indicatorRow}>
              <CardCheckmarkLabel label={backgroundLabel} />
            </div>
          </div>
          <div className={cxCardConfigStyles.settingContainer}>
            <ConfigLabel title="Provider Color" />
            <div className={cxCardConfigStyles.indicatorRow}>
              {indicators.includes("PROVIDER") ? (
                <CardCheckmarkLabel label={showDentistColor ? "Dentist" : "Hygienist"} />
              ) : (
                <span className="text-xs">Provider color not shown</span>
              )}
            </div>
          </div>
        </div>
        <Divider className="py-1" />
        <NameSettingsHeader />
        <div className={cxCardConfigStyles.settingsContainer}>
          <div className={cxCardConfigStyles.settingContainer}>
            <ConfigLabel title="Type Size" />
            <div className={cxCardConfigStyles.indicatorRow}>
              <CardCheckmarkLabel label={getLabelForFontSize(fontSize)} />
            </div>
          </div>
          <div className={cxCardConfigStyles.settingContainer}>
            <ConfigLabel title="Preferred Name" />
            <div className={cxCardConfigStyles.indicatorRow}>
              <CardCheckmarkLabel hidden={hidePreferredName} />
            </div>
          </div>
          {/* empty div for responsive flex spacing */}
          <div className={cxCardConfigStyles.settingContainer} />
        </div>
        {/* Replace section above with below when BE is ready */}
        {/* <div className={cxCardConfigStyles.settingsContainer}>
          <div className={cxCardConfigStyles.settingContainer}>
            <ConfigLabel title="Order By" />
            <div className={cxCardConfigStyles.indicatorRow}>
              <CheckmarkLabel label={orderBy === 'first' ? 'First Name' : 'Last Name'} />
            </div>
          </div>
          <div className={cxCardConfigStyles.settingContainer}>
            <ConfigLabel title="Type Size" />
            <div className={cxCardConfigStyles.indicatorRow}>
              <CheckmarkLabel label={getLabelForFontSize(fontSize)} />
            </div>
          </div>
          <div className={cxCardConfigStyles.settingContainer} />
        </div>
        <div className={cxCardConfigStyles.settingsContainer}>
          <div className={cxCardConfigStyles.settingContainer}>
            <ConfigLabel title="Preferred Name" />
            <div className={cxCardConfigStyles.indicatorRow}>
              <CheckmarkLabel hidden={hidePreferredName} />
            </div>
          </div>
          <div className={cxCardConfigStyles.settingContainer}>
            <ConfigLabel title="Name Pronunciation" />
            <div className={cxCardConfigStyles.indicatorRow}>
              <CheckmarkLabel hidden={hideNamePronunciation} />
            </div>
          </div>
          <div className={cxCardConfigStyles.settingContainer} />
        </div>
        <Divider className="py-1" />
        <AppointmentSettingsHeader />
        <div className={cxCardConfigStyles.settingsContainer}>
          <div className={cxCardConfigStyles.settingContainer}>
            <ConfigLabel title="Provider Name" />
            <div className={cxCardConfigStyles.indicatorRow}>
              <CheckmarkLabel hidden={hideProviderName} />
            </div>
          </div>
          <div className={cxCardConfigStyles.settingContainer}>
            <ConfigLabel title="Patient Age" />
            <div className={cxCardConfigStyles.indicatorRow}>
              <CheckmarkLabel hidden={hidePatientAge} />
            </div>
          </div>
          <div className={cxCardConfigStyles.settingContainer} />
        </div>
        */}
      </div>
      <VerticalDivider />
      {/* padding accounts for bottom buttons when in edit mode */}
      <div className={cx("flex-col after:min-h-[79px]", cxCardConfigStyles.previewContainer)}>
        <div className={cxCardConfigStyles.previewWrapper}>
          <AppointmentCardPreview config={config} currentUserName={currentUserName} />
        </div>
      </div>
    </div>
  );
};
