import { cx } from "@libs/utils/cx";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as Close } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as DragIcon } from "@libs/assets/icons/drag-reorder-2.svg";

export interface HolderCardProps {
  name: string;
  duration: string;
  isSelected: boolean;
  onRequestDelete: Func;
  onClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
  onDoubleClick: (e: React.MouseEvent<HTMLButtonElement>) => void;
}

export const HolderCard: React.FC<HolderCardProps> = ({
  isSelected,
  name,
  duration,
  onRequestDelete,
  onClick,
  onDoubleClick,
}) => {
  return (
    <div
      className={cx(
        "w-[334px] px-3 py-2.5 rounded flex items-center border",
        isSelected ? "border-primaryTheme bg-slate-50" : "border-greyLighter bg-white"
      )}
    >
      <div className="flex flex-1 items-center gap-x-3">
        <Icon className="cursor-move" SvgIcon={DragIcon} />
        <button className="text-left flex-1" type="button" onDoubleClick={onDoubleClick} onClick={onClick}>
          <span className="block text-sm truncate max-w-full">{name}</span>

          <span className="block text-greyMedium text-xs">{duration}</span>
        </button>
      </div>

      <ButtonIcon
        SvgIcon={Close}
        tooltip={{ content: "Delete Appointment Hold", theme: "SMALL" }}
        onClick={onRequestDelete}
      />
    </div>
  );
};
