import { FC, useMemo, FormEvent, useRef } from "react";
import { flushSync } from "react-dom";
import { InsuranceCarrierVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { useFamilyRelations } from "@libs/hooks/useRelations";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { PrimarySubscriberFields } from "components/Patient/PrimarySubscriberFields";
import { NewPrimarySubscriberFields } from "components/Patient/NewPrimarySubscriberFields";
import { getCreatePatientSchema } from "components/Patient/formSchemas";
import { CreatePatientFormState } from "components/Patient/formPostData";
import { SelectInsuranceSubscriberType } from "components/Patient/SelectInsuranceSubscriberType";
import {
  useLinkedPrimarySubscriber,
  useLookupPrimarySubscriber,
  usePatientDraft,
} from "components/Patient/hooks";
import { SelectRelationship } from "components/Patient/SelectRelationship";
import { Divider } from "components/UI/Divider";
import { scrollToFirstError } from "utils/scrollToFirstError";
import { FlyoverContent, FlyoverFooter, FlyoverForm, FlyoverHeader } from "components/UI/FlyoverComponents";
import { useIsDirty } from "hooks/useIsDirty";
import { PatientForm, PatientFormProps } from "./PatientForm";

export interface CreatePatientFormProps
  extends Pick<
    PatientFormProps,
    | "onClearContact"
    | "onSelectContact"
    | "onAddContact"
    | "onEditContact"
    | "defaultContactOptions"
    | "contactSelection"
  > {
  insuranceCarriers: InsuranceCarrierVO[] | undefined;
  isPosting: boolean;
  onCancel: Func;
  onDirty: Func;
  onFormSubmit: (update: CreatePatientFormState) => void;
}

export const CreatePatientForm: FC<CreatePatientFormProps> = ({
  insuranceCarriers,
  onFormSubmit,
  onCancel,
  onDirty,
  isPosting,
  ...patientFormProps
}) => {
  const modalContentRef = useRef<HTMLDivElement>(null);
  const [dependentPrimarySubscriber, handleUpdateDependentPrimarySubscriber] = useLinkedPrimarySubscriber();

  const {
    selectedPrimarySubscriber,
    patientDraft,
    primarySubscriber,
    patientMatch,
    handleUpdatePatientDraft,
    handleUpdatePrimarySubscriber,
    handleUpdateSelectedPrimarySubscriber,
  } = usePatientDraft();

  const dependentSubscriberMatch = useLookupPrimarySubscriber(dependentPrimarySubscriber);
  const primarySubscriberMatch = useLookupPrimarySubscriber({
    ssn: primarySubscriber.ssn,
    subscriberId: primarySubscriber.subscriberId,
    subscriberIdType: primarySubscriber.subscriberIdType,
    dob: patientDraft.dob,
    carrierId: primarySubscriber.carrierId,
  });

  const formData: CreatePatientFormState = useMemo(
    () => ({
      dependentPrimarySubscriber,
      primarySubscriber,
      patientDraft,
      selectedPrimarySubscriber,
      patientMatch,
    }),
    [dependentPrimarySubscriber, primarySubscriber, patientDraft, selectedPrimarySubscriber, patientMatch]
  );

  useIsDirty(formData, { onDirty });

  const schema = useMemo(() => {
    return getCreatePatientSchema({
      dependentPrimarySubscriberIdType: dependentPrimarySubscriber.subscriberIdType,
      insuranceSubscriberType: patientDraft.insuranceSubscriberType,
      contactModes: patientDraft.contactModes,
      preferredContactMode: patientDraft.preferredContactMode,
      usingContact: Boolean(patientFormProps.contactSelection),
      primarySubscriberSubscriberIdType: primarySubscriber.subscriberIdType,
    });
  }, [
    dependentPrimarySubscriber.subscriberIdType,
    patientDraft.insuranceSubscriberType,
    patientDraft.contactModes,
    patientDraft.preferredContactMode,
    patientFormProps.contactSelection,
    primarySubscriber.subscriberIdType,
  ]);

  const { result: validation, validate } = useValidation(formData, schema);

  const submitDisabled = useMemo(
    () => Boolean(patientMatch || primarySubscriberMatch || validation.$isValid === false),
    [patientMatch, primarySubscriberMatch, validation.$isValid]
  );
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const result = flushSync(() => validate());

    if (result.$isValid) {
      onFormSubmit(formData);
    } else {
      scrollToFirstError(modalContentRef.current);
    }
  };
  const patientRelations = useFamilyRelations();

  return (
    <FlyoverForm onSubmit={handleSubmit}>
      <FlyoverHeader onClose={onCancel} size="sm">
        New Patient
      </FlyoverHeader>
      <FlyoverContent containerRef={modalContentRef} paddingClassName="py-5 px-8">
        <PatientForm
          onUpdatePatient={handleUpdatePatientDraft}
          patientDraft={patientDraft}
          patientMatch={patientMatch}
          validations={validation.patientDraft}
          {...patientFormProps}
        />

        <Divider className="my-7 border-dashed" />

        <div>
          <div className="pb-6 text-sm font-sansSemiBold">Insurance Information</div>
          <SelectInsuranceSubscriberType
            insuranceSubscriberType={patientDraft.insuranceSubscriberType}
            onUpdate={(type) => handleUpdatePatientDraft({ insuranceSubscriberType: type })}
          />
          {patientDraft.insuranceSubscriberType === "DEPENDENT" && (
            <SelectRelationship
              className="w-64 mt-2"
              patientRelations={patientRelations}
              error={validation.dependentPrimarySubscriber.relationship.$error}
              value={dependentPrimarySubscriber.relationship}
              onItemSelected={(value) => handleUpdateDependentPrimarySubscriber({ relationship: value })}
            />
          )}
        </div>
        {patientDraft.insuranceSubscriberType === "PRIMARY_SUBSCRIBER" ? (
          <PrimarySubscriberFields
            insuranceCarriers={insuranceCarriers}
            onUpdate={handleUpdatePrimarySubscriber}
            primarySubscriber={primarySubscriber}
            subscriberMatch={primarySubscriberMatch}
            validations={validation.primarySubscriber}
          />
        ) : null}
        {patientDraft.insuranceSubscriberType === "DEPENDENT" ? (
          <>
            <Divider className="my-4" />
            <div className="pb-6 text-sm font-sansSemiBold">Add Primary Subscriber</div>
            <NewPrimarySubscriberFields
              dependentPrimarySubscriber={dependentPrimarySubscriber}
              onUpdate={handleUpdateDependentPrimarySubscriber}
              insuranceCarriers={insuranceCarriers}
              selectedPrimarySubscriber={selectedPrimarySubscriber}
              onUpdateSelectedPrimarySubscriber={handleUpdateSelectedPrimarySubscriber}
              subscriberMatch={dependentSubscriberMatch}
              validations={validation}
            />
          </>
        ) : null}
      </FlyoverContent>
      <FlyoverFooter>
        <AsyncButton className="min-w-button" disabled={submitDisabled} isLoading={isPosting} type="submit">
          Create
        </AsyncButton>
      </FlyoverFooter>
    </FlyoverForm>
  );
};
