import { FormSubmissionVO, FormVO } from "@libs/api/generated-api";
import { findElementsByType } from "components/Settings/Forms/utils";
import { resolvedTemplateVariablesToString } from "components/UI/RichTextEditor/resolvedTemplateVariables";

// In the case of editable text blocks, it's lexical js content stored in JSON format
// and the variables are replaced with values at the time the form is requested.
// That content needs to be serialized to a string and treated as a default response
// if the form doesn't already have a submission.
export const assignDefaultEditableTextResponses = (
  form: FormVO,
  submission: FormSubmissionVO | undefined
) => {
  if (!submission) {
    const blocks = findElementsByType(form, "EDITABLE_TEXT_BLOCK");
    const newResponses = {} as FormSubmissionVO["responses"];

    for (const block of blocks) {
      newResponses[block.uuid] = {
        type: "STRING",
        response: resolvedTemplateVariablesToString(block.content),
      };
    }

    return newResponses;
  }

  return submission.responses;
};
