import { PatientProcedureVO, ProcedureShortcutVO, TreatmentPlanVO } from "@libs/api/generated-api";
import { makeQuery, makeQueryOptionalArgs, makeInfiniteQuery, getNextPageParam } from "@libs/utils/queries";
import { oneMinuteCache, cacheForSession } from "@libs/utils/queryCache";

export const getPatientTreatmentPlansQuery = makeQuery({
  queryKey: ["practices", "getPatientTreatmentPlansV2"],
  formatParams: ({
    practiceId,
    patientId,
    includeStates,
  }: {
    practiceId: number;
    patientId: number;
    includeStates?: TreatmentPlanVO["state"][];
  }) => [patientId, practiceId, { includeStates }],
  queryOptions: oneMinuteCache,
});

export const getTreatmentPlanQuery = makeQuery({
  queryKey: ["practices", "getTreatmentPlan"],
  formatParams: ({ practiceId, treatmentPlanUuid }: { practiceId: number; treatmentPlanUuid: string }) => [
    practiceId,
    treatmentPlanUuid,
    {},
  ],
  queryOptions: oneMinuteCache,
});

export const getProcedureShortcutsByCategory = makeQuery({
  queryKey: ["practices", "getProcedureShortcutsByCategory"],
  formatParams: (args: { practiceId: number; category: ProcedureShortcutVO["category"] }) => [
    args.practiceId,
    { category: args.category },
  ],
  queryOptions: cacheForSession,
});

export const getAllProcedureShortcutCategories = makeQueryOptionalArgs({
  queryKey: ["procedureShortcuts", "getAllProcedureShortcutCategories"],
  formatParams: () => [],
  queryOptions: cacheForSession,
});

export const getPatientProceduresQuery = makeQuery({
  queryKey: ["practices", "getPatientProcedures"],
  formatParams: ({
    patientId,
    practiceId,
    ...query
  }: {
    patientId: number;
    practiceId: number;
    includeStatuses?: PatientProcedureVO["status"][];
    treatmentPlanUuid?: string;
    ids?: number[];
    pageNumber: number;
    pageSize: number;
  }) => [patientId, practiceId, query],
  queryOptions: oneMinuteCache,
});

export const getPatientProcedure = makeQuery({
  queryKey: ["practices", "getPatientProcedure"],
  formatParams: ({ practiceId, patientProcedureId }: { practiceId: number; patientProcedureId: number }) => [
    practiceId,
    patientProcedureId,
  ],
  queryOptions: oneMinuteCache,
});

export const getPatientChartV2 = makeQuery({
  queryKey: ["v2", "getPatientChartV2"],
  formatParams: ({ practiceId, patientId }: { practiceId: number; patientId: number }) => [
    practiceId,
    patientId,
  ],
  queryOptions: cacheForSession,
});

export const getInfinitePatientProceduresQuery = makeInfiniteQuery({
  queryKey: ["practices", "getPatientProcedures"],
  formatParams: ({
    patientId,
    practiceId,
    ...query
  }: {
    patientId: number;
    practiceId: number;
    includeStatuses?: PatientProcedureVO["status"][];
    sort?: string[];
    treatmentPlanUuid?: string;
    pageNumber: number;
    pageSize: number;
  }) => [patientId, practiceId, query],
  queryOptions: {
    ...oneMinuteCache,
    getNextPageParam,
  },
});

export const getPerioChartExamSettings = makeQuery({
  queryKey: ["practices", "getPerioChartExamSettings"],
  formatParams: ({ practiceId, providerId }: { practiceId: number; providerId: number }) => [
    practiceId,
    providerId,
  ],
  queryOptions: cacheForSession,
});

export const getPerioChartExams = makeQuery({
  queryKey: ["practices", "getPerioChartExams"],
  formatParams: ({ practiceId, patientId }: { practiceId: number; patientId: number }) => [
    practiceId,
    patientId,
  ],
  queryOptions: cacheForSession,
});

export const getPerioChartExam = makeQuery({
  queryKey: ["practices", "getPerioChartExam"],
  formatParams: ({
    practiceId,
    patientId,
    perioChartExamUuid,
  }: {
    practiceId: number;
    patientId: number;
    perioChartExamUuid: string;
  }) => [practiceId, patientId, perioChartExamUuid],
  queryOptions: cacheForSession,
});

export const getToothConditionRequirements = makeQueryOptionalArgs({
  queryKey: ["toothConditions", "getToothConditionRequirements"],
  formatParams: () => [],
  queryOptions: cacheForSession,
});

export const getDentalProcedureCategories = makeQueryOptionalArgs({
  queryKey: ["dentalProcedures", "getDentalProcedureCategories"],
  formatParams: () => [],
  queryOptions: cacheForSession,
});

export const getDentalProceduresQuery = makeQuery({
  queryKey: ["practices", "getDentalProceduresByPractice"],
  formatParams: ({
    practiceId,
    ...query
  }: {
    practiceId: number;
    category?: string;
    fetchUcrFees?: boolean;
  }) => [practiceId, query],
  queryOptions: cacheForSession,
});

export const getPatientProcedureInsuranceStatus = makeQuery({
  queryKey: ["practices", "getPatientProcedureInsuranceStatus"],
  formatParams: ({ practiceId, patientProcedureId }: { practiceId: number; patientProcedureId: number }) => [
    practiceId,
    patientProcedureId,
  ],
  queryOptions: oneMinuteCache,
});
