/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable complexity */
import React, { useCallback, useEffect, useState } from "react";
import { PerioChartExamEntryVO } from "@libs/api/generated-api";
import { ChangeTrackedRecord } from "utils/changeTrackedRecord";
import { PerioChartNumericInput } from "./PerioChartNumericInput";
import { PerioChartToothSurfaceType } from "./PerioTypes";
import { usePerioChart } from "./PerioChartContext";
import { getFieldNames } from "./perioChartUtils";

const addNumbers = (probingValue?: number, gingMarginValue?: number) => {
  if (probingValue == null || gingMarginValue == null) {
    return undefined;
  }

  // The Ging Margin value multiplied by -1 is to inverse the value stored, since
  // ging margin is negative margins and we show a positive value for it.
  return (probingValue || 0) + (gingMarginValue * -1 || 0);
};

// eslint-disable-next-line complexity
export const PerioChartAutoCalRow: React.FC<{ toothNumber: number; surface: PerioChartToothSurfaceType }> = ({
  toothNumber,
  surface,
}) => {
  const { settings, currentExam } = usePerioChart();

  const [probingEntry, setProbingEntry] = useState<ChangeTrackedRecord<PerioChartExamEntryVO> | undefined>(
    () => currentExam?.getEntry(toothNumber, "PROBING")
  );
  const [gingMarginEntry, setGingMarginEntry] = useState<
    ChangeTrackedRecord<PerioChartExamEntryVO> | undefined
  >(() => currentExam?.getEntry(toothNumber, "GINGMARGIN"));

  const [valueA, setValueA] = useState<number | undefined>();
  const [valueB, setValueB] = useState<number | undefined>();
  const [valueC, setValueC] = useState<number | undefined>();

  const fieldsToUse = getFieldNames(surface, toothNumber);

  const adjustValues = useCallback(() => {
    if (!probingEntry && !gingMarginEntry) {
      setValueA(undefined);
      setValueB(undefined);
      setValueC(undefined);

      return;
    }

    const fieldA = fieldsToUse[0];
    const fieldB = fieldsToUse[1];
    const fieldC = fieldsToUse[2];

    setValueA(addNumbers(probingEntry?.[fieldA], gingMarginEntry?.[fieldA]));
    setValueB(addNumbers(probingEntry?.[fieldB], gingMarginEntry?.[fieldB]));
    setValueC(addNumbers(probingEntry?.[fieldC], gingMarginEntry?.[fieldC]));
  }, [fieldsToUse, gingMarginEntry, probingEntry]);

  useEffect(() => {
    adjustValues();

    return probingEntry?.subscribe(adjustValues);
  }, [adjustValues, probingEntry]);

  useEffect(() => {
    adjustValues();

    return gingMarginEntry?.subscribe(adjustValues);
  }, [adjustValues, gingMarginEntry]);

  useEffect(() => {
    if ((probingEntry && gingMarginEntry) || !currentExam) {
      return undefined;
    }

    // Since it is missing one of the records, we will need to subscribe for exam entry creation
    // until we get both.
    const unsubscribe = currentExam.subscribeToRecordsCreated((record) => {
      if (record.toothNum === toothNumber) {
        if (record.perioSequenceType === "PROBING") {
          setProbingEntry(record);

          if (gingMarginEntry) {
            unsubscribe();
          }
        } else if (record.perioSequenceType === "GINGMARGIN") {
          setGingMarginEntry(record);

          if (probingEntry) {
            unsubscribe();
          }
        }
      }
    });

    return unsubscribe;
  }, [currentExam, gingMarginEntry, probingEntry, toothNumber]);

  const threshold = settings?.autoCALThreshold;

  return (
    <div className="grid grid-cols-3 gap-[3px] mb-0.5 justify-center">
      <PerioChartNumericInput
        disabled={true}
        value={valueA}
        valueThreshold={threshold}
        thresholdCondition="gte"
      />
      <PerioChartNumericInput
        disabled={true}
        value={valueB}
        valueThreshold={threshold}
        thresholdCondition="gte"
      />
      <PerioChartNumericInput
        disabled={true}
        value={valueC}
        valueThreshold={threshold}
        thresholdCondition="gte"
      />
    </div>
  );
};
