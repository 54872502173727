import { FC } from "react";

import { Pill, PillProps } from "@libs/components/UI/Pill";
import { Button } from "@libs/components/UI/Button";

import { SettingsHeroDetails } from "components/Settings/SettingsHeroLayout";
import { MailToLink } from "components/UI/MailToLink";

type Status = "INACTIVE" | "ACTIVE";
type StatusConfig = { label: string; description: string; theme: PillProps["theme"] };

const statusMap: Record<Status, StatusConfig> = {
  INACTIVE: {
    label: "Inactive",
    description:
      "Archy uses DoseSpot to enable electronic prescribing. Your service will become active once you add at least one prescriber below.",
    theme: "grey",
  },
  ACTIVE: {
    label: "Active",
    description: "DoseSpot is now active. If you need to remove a prescriber please contact Archy support.",
    theme: "green",
  },
};

export const DoseSpotOnboardingStatus: FC<{
  status: Status;
  onAddProvider: Func;
}> = ({ status, onAddProvider }) => {
  const { theme, label, description } = statusMap[status];

  return (
    <SettingsHeroDetails>
      <Pill theme={theme} data-testid="dosespot-onboarding-status">
        {label}
      </Pill>
      <p>{description}</p>
      {status === "INACTIVE" ? (
        <Button onClick={onAddProvider} theme="link">
          Add Provider
        </Button>
      ) : (
        <MailToLink email="support@archy.com">Contact Support</MailToLink>
      )}
    </SettingsHeroDetails>
  );
};
