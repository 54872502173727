import { FC, useState } from "react";
import { PayrollMigrationEvent } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as MergeIcon } from "@libs/assets/icons/merge.svg";
import { ModalHeader, ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import { Modal } from "@libs/components/UI/Modal";
import GustoLogo from "assets/images/logo-gusto.svg";
import { EmployeeConsolidationModalContainer } from "./EmployeeConsolidationModalContainer";

import { useMigrationFinished } from "./useMigrationFinished";

type GustoMigrationModalSteps =
  | {
      name: "connected";
    }
  | {
      name: "consolidation";
      isWaitingForMigrationEvent: boolean;
    }
  | {
      name: "summary";
      event: PayrollMigrationEvent;
    };

const cxStyles = {
  listItemBorder: "rounded border border-greyLighter px-10 py-5",
};

export const GustoMigrationModal: FC<{
  onRequestClose: Func;
}> = ({ onRequestClose }) => {
  const [step, setStep] = useState<GustoMigrationModalSteps>({ name: "connected" });

  const handleRequestNext = () => setStep({ name: "consolidation", isWaitingForMigrationEvent: false });

  const handleBeginConsolidation = () => setStep({ name: "consolidation", isWaitingForMigrationEvent: true });

  const showSummary = (event: PayrollMigrationEvent) => setStep({ name: "summary", event });

  useMigrationFinished({
    startListening: step.name === "consolidation" && step.isWaitingForMigrationEvent,
    onComplete: showSummary,
    onTimeout: onRequestClose,
  });

  return step.name === "connected" ? (
    <ConnectedModal onRequestNext={handleRequestNext} />
  ) : step.name === "consolidation" ? (
    <EmployeeConsolidationModalContainer
      isWaitingForMigrationEvent={step.isWaitingForMigrationEvent}
      onBeginConsolidation={handleBeginConsolidation}
      onRequestClose={onRequestClose}
    />
  ) : (
    <SummaryModal
      onboardedToArchy={step.event.onboardedToArchy}
      onboardedToGusto={step.event.onboardedToGusto}
      onRequestClose={onRequestClose}
    />
  );
};

const ConnectedModal: FC<{ onRequestNext: Func }> = ({ onRequestNext }) => (
  <Modal>
    <ModalHeader className="rounded-t bg-actionLight text-greyDark" paddingClassName="px-16 py-8">
      <span className="text-2xl">Congrats!</span>
      <p>
        You have now connected your <img alt="Gusto Logo" className="h-7 inline" src={GustoLogo} /> account
        with Archy!
      </p>
    </ModalHeader>

    <ModalContent className="pt-6 px-16 pb-7 font-sans text-sm text-greyDark">
      <p className="font-sansSemiBold mb-2">
        Your next step to run accurate payrolls is to have an accurate employee list
      </p>
      <p className="mb-8">
        We cross-referenced your existing Gusto and Archy employee lists, so here are a couple of actions you
        might need to take to ensure your consolidated list is accurate:
      </p>

      <ul className="flex flex-col list-decimal gap-y-4">
        <div className={cxStyles.listItemBorder}>
          <li>
            Check for any employee duplicates across Archy and Gusto, and click the{" "}
            <MergeIcon className="w-5 h-5 text-primaryTheme inline" /> icon to merge them. This includes
            employees with different/shortened first or last names on either Archy or Gusto.
          </li>
        </div>

        <div className={cxStyles.listItemBorder}>
          <li>
            Employees only listed on the Gusto side have to be brought into the Archy database. Make sure all
            employees marked “Onboard to Archy” are:
            <br />
            A. Active employees who are payroll eligible, or
            <br />
            B. Inactive employees that still need to be on the employee list for tax purposes.
            <br />
            *Please note, inactive employees on Gusto will later be reactivated through their former Gusto
            account if rehired.
          </li>
        </div>
        <div className={cxStyles.listItemBorder}>
          <li>
            Employees only listed on the Archy side need to onboard onto Gusto in order to receive payroll.
            These employees will receive emails instructing them how to onboard.
          </li>
        </div>
      </ul>
    </ModalContent>

    <ModalFooter>
      <Button className="min-w-button" onClick={onRequestNext}>
        Next
      </Button>
    </ModalFooter>
  </Modal>
);

const SummaryModal: FC<PayrollMigrationEvent & { onRequestClose: Func }> = ({
  onboardedToArchy,
  onboardedToGusto,
  onRequestClose,
}) => {
  const hasOnboardCounts = onboardedToArchy > 0 || onboardedToGusto > 0;

  return (
    <Modal size="xs">
      <ModalHeader className="border-none" onClose={onRequestClose} paddingClassName="pt-6 pr-6" />

      <ModalContent className="text-sm text-center" padding="lg">
        <p className="font-sansSemiBold text-base mb-4">Employee Consolidation Summary</p>

        <div className="flex flex-col items-center gap-y-6">
          {hasOnboardCounts ? (
            <div
              className={`
                border
                border-greyLighter
                rounded
                flex
                flex-col
                w-full
                gap-y-4
                p-6
              `}
            >
              {onboardedToArchy > 0 && <SummaryMessage platform="Archy" count={onboardedToArchy} />}
              {onboardedToGusto > 0 && <SummaryMessage platform="Gusto" count={onboardedToGusto} />}
            </div>
          ) : null}

          <div className={cx(hasOnboardCounts ? "text-left" : "text-center")}>
            <p className="font-sansSemiBold">You&apos;re good to go!</p>
            <p>
              As a final task, check your Payroll Settings with Gusto to confirm you&apos;re accurately setup
              to run payrolls.
            </p>
          </div>

          <Button className="min-w-button" onClick={onRequestClose}>
            Done
          </Button>
        </div>
      </ModalContent>
    </Modal>
  );
};

const SummaryMessage: FC<{
  platform: "Archy" | "Gusto";
  count: number;
}> = ({ platform, count }) => (
  <p className="font-sans text-sm text-greyDark">
    <span className="font-sansSemiBold">{count}</span> employees need to onboard to{" "}
    <span className="font-sansSemiBold">{platform}</span> (invites sent)
  </p>
);
