import React from "react";
import { useLocation } from "react-router-dom";
import { getFullUrl } from "@libs/utils/location";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { useAccount } from "@libs/contexts/AccountContext";
import { ModalFooterButtons } from "@libs/components/UI/ModalComponents";
import { SelectMountImagesTable } from "components/UI/SelectMountImages/SelectMountImagesTable";
import { ModalPage } from "components/UI/ModalPage";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { FullScreenPortal } from "components/PatientProfile/Imaging/shared/FullScreenPortal";
import { ImageLayoutItem } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";
import { useInfiniteMountsQuery } from "api/imaging/queries";

export const SelectMountImagesModalRoute = () => {
  const location = useLocation();
  const { query } = useQueryParams("imagingCompareSelectImages");
  const { patientId } = usePathParams("imagingCompareSelectImages");
  const from = query.from;
  const { practiceId } = useAccount();
  const mountsQuery = useInfiniteMountsQuery({ practiceId, patientId, populateImages: true });
  const [selectedImageIds, setSelectedImageIds] = React.useState<Set<number>>(new Set(query.imageIds));

  const handleImageClick = React.useCallback(
    (image: ImageLayoutItem) => {
      setSelectedImageIds((prior) => {
        const newSelectedImageIds = new Set(prior);

        if (newSelectedImageIds.has(image.id)) {
          newSelectedImageIds.delete(image.id);
        } else {
          newSelectedImageIds.add(image.id);
        }

        return newSelectedImageIds;
      });
    },
    [setSelectedImageIds]
  );
  const backUrl = from ?? paths.patientTab({ patientId, tab: "imaging" });

  return (
    <FullScreenPortal>
      <ModalPage
        title="Choose Comparison Images"
        closeLink={backUrl}
        actions={
          <div className="flex justify-between items-center">
            <div />
            <ModalFooterButtons>
              <ButtonInternalLink className="w-36" replace theme="secondary" to={backUrl}>
                Cancel
              </ButtonInternalLink>
              <ButtonInternalLink
                theme="primary"
                className="w-28"
                replace
                to={paths.imagingCompare(
                  {
                    patientId,
                  },
                  {
                    imageIds: [...selectedImageIds],
                    fromSelection: getFullUrl(location),
                    from: query.from,
                  }
                )}
              >
                Compare
              </ButtonInternalLink>
            </ModalFooterButtons>
            <div className="text-xs font-sansSemiBold">Total Selected {selectedImageIds.size}</div>
          </div>
        }
      >
        <SelectMountImagesTable
          mountsQuery={mountsQuery}
          patientId={patientId}
          onClickImage={handleImageClick}
          selectedImageIds={selectedImageIds}
          showLink={false}
        />
      </ModalPage>
    </FullScreenPortal>
  );
};
