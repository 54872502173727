import { produce } from "immer";
import { useId, useMemo } from "react";
import { FormEditableTextBlockElementRequest, TemplateVariableGroupVO } from "@libs/api/generated-api";
import { ValidationResult } from "@libs/hooks/useValidation";
import { FormField } from "@libs/components/UI/FormField";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";

import { TemplateVariablesEditor } from "components/UI/RichTextEditor/TemplateVariablesEditor";
import { handleError } from "utils/handleError";
import { editableTextBlockSchema } from "./schema";

export const EditableTextBlockFormContent = ({
  formState: [{ draft }, setForm],
  validation,
  templateVariables,
}: {
  templateVariables?: TemplateVariableGroupVO[];
  validation: ValidationResult<FormEditableTextBlockElementRequest, typeof editableTextBlockSchema>;
  formState: UseState<{ draft: FormEditableTextBlockElementRequest }>;
}) => {
  const variables = useMemo(() => templateVariables ?? [], [templateVariables]);
  const contentId = useId();

  return (
    <div className="flex flex-1 min-h-0 flex-col gap-y-4">
      <FormFieldInput
        value={draft.title}
        required={true}
        label="Title"
        error={validation.title.$error}
        onChange={(e) => {
          setForm((last) =>
            produce(last, (next) => {
              next.draft.title = e.target.value;
            })
          );
        }}
        className="flex-0"
      />
      <div className="flex flex-col min-h-0 flex-1 gap-y-1">
        <FormField
          id={contentId}
          layout="labelIn"
          className="flex-1 min-h-0 flex flex-col"
          containerClassName="flex flex-col flex-1 min-h-0 gap-y-2"
          required
          label="Note"
          error={validation.content.$error}
        >
          <TemplateVariablesEditor
            initialEditorState={draft.content}
            initialConfig={{
              editable: true,
              namespace: "CustomForms",
              onError: handleError,
            }}
            className="flex-1 min-h-0 overflow-y-auto px-4 pb-2"
            id={contentId}
            templateVariables={variables}
            onChange={({ content }) => {
              setForm((last) =>
                produce(last, (next) => {
                  next.draft.content = content;
                })
              );
            }}
          />
        </FormField>

        <p className="text-xs text-greyLight">You can type a / (forward slash) to include smart variables.</p>
      </div>
    </div>
  );
};
