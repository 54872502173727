import { useEffect, useMemo, useState } from "react";
import { useScannerSettingsStorage } from "./syncScannerSettingsWithLocalStorage";

export interface ScannerSettings {
  colorMode: "BW" | "GRAY" | "RGB";
  useFeeder: boolean;
  autoDiscardBlankPages: boolean;
  useDuplex: boolean;
  preferredDeviceIndex: number;
}

export const useScannerSettings = () => {
  const { loadSettingsFromLocalStorage, saveSettingsToLocalStorage } = useScannerSettingsStorage();
  const [scannerSettings, setScannerSettings] = useState(() => loadSettingsFromLocalStorage());

  useEffect(() => {
    // When settings change, save changes to local storage
    saveSettingsToLocalStorage(scannerSettings);
  }, [scannerSettings, saveSettingsToLocalStorage]);

  const selectedSettings = useMemo(
    () =>
      new Set([
        ...(scannerSettings.useFeeder ? ["USE_FEEDER"] : []),
        ...(scannerSettings.autoDiscardBlankPages ? ["AUTO_DISCARD_BLANK_PAGES"] : []),
        ...(scannerSettings.useDuplex ? ["USE_DUPLEX"] : []),
      ]),
    [scannerSettings.useFeeder, scannerSettings.autoDiscardBlankPages, scannerSettings.useDuplex]
  );

  return { scannerSettings, setScannerSettings, selectedSettings };
};
