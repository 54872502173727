import { UncollectedByCarrierSubtotalVO } from "@libs/api/generated-api";

import { formatCurrency } from "@libs/utils/currency";
import { SectionFooter } from "components/Dashboard/Tables/SectionFooter";

type Props = {
  data: UncollectedByCarrierSubtotalVO;
};

export const ByCarrierClaimFooterRow: React.FC<Props> = ({ data }) => {
  const { totalClaimAmount, totalInsuranceUncollectedAmount } = data;

  return (
    <>
      <SectionFooter className="col-span-5" />
      <SectionFooter className="justify-end">Carrier Totals</SectionFooter>
      <SectionFooter align="right">{formatCurrency(totalClaimAmount)}</SectionFooter>
      <SectionFooter align="right">{formatCurrency(totalInsuranceUncollectedAmount)}</SectionFooter>
      <SectionFooter className="col-span-2" />
    </>
  );
};
