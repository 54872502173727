/* eslint-disable complexity */
import { useCallback, useContext, useMemo } from "react";
import { ProviderVO, TaxonomyCodeVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { Icon } from "@libs/components/UI/Icon";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useAccount } from "@libs/contexts/AccountContext";
import { FormFieldSwitch } from "@libs/components/UI/FormFieldSwitch";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { ServerErrors, State, UpdateState } from "components/Settings/ClaimsSettings/types";

const billingProviderList: SelectOption<NonNullable<State["billingProviderType"]>>[] = [
  {
    value: "BUSINESS",
    label: "Business",
  },
  {
    value: "INDIVIDUAL",
    label: "Individual",
  },
  {
    value: "TREATING",
    label: "Treating",
  },
];

export const ClaimAutomationSettings: React.FC<{
  isEditing: boolean;
  taxonomyCodes?: TaxonomyCodeVO[];
  practiceProviders?: ProviderVO[];
  serverErrors: ServerErrors;
  onClearServerErrors: () => void;
  onHandleException: (ex: unknown) => void;
  state: State;
  onUpdateState: UpdateState;
}> = ({
  isEditing,
  taxonomyCodes,
  practiceProviders,
  serverErrors,
  onClearServerErrors,
  onHandleException,
  state,
  onUpdateState,
}) => {
  const { practiceId } = useAccount();
  const loadingProvider = useBoolean(false);

  const { httpClient } = useContext(ApiClientContext);

  const isIndividual = state.billingProviderType === "INDIVIDUAL";
  const isBusiness = state.billingProviderType === "BUSINESS";

  const isIndividualOrBusiness = isIndividual || isBusiness;

  const taxnonomyCode = state.taxonomyCode;
  const taxonomyCodeOptions = useMemo(
    () => taxonomyCodes?.map((code) => ({ value: code.code, label: code.description })) || [],
    [taxonomyCodes]
  );

  const billingProviderOptions = useMemo(
    () =>
      practiceProviders
        ?.filter((provider) => provider.jobCategory === "DENTIST")
        .map((provider) => ({ value: provider.id, label: provider.name.fullDisplayName })) || [],
    [practiceProviders]
  );

  const setBillingProviderId = useCallback(
    async (id?: number) => {
      loadingProvider.on();
      onUpdateState("billingProviderId", id);
      onUpdateState("taxonomyCode", undefined);
      onUpdateState("billingProviderNPI", undefined);

      if (id) {
        try {
          const result = await httpClient.practices.getEmployee(practiceId, id);
          const details = result.data.data.employmentDetails;

          onUpdateState("taxonomyCode", details.taxonomyCode);
          onUpdateState("billingProviderNPI", details.npiNumber);
        } catch (ex) {
          onHandleException(ex);
        } finally {
          loadingProvider.off();
        }
      }
    },
    [loadingProvider, onUpdateState, httpClient.practices, practiceId, onHandleException]
  );

  const handleBillingProviderTypeChange = useCallback(
    (value?: State["billingProviderType"]) => {
      onClearServerErrors();
      onUpdateState("billingProviderType", value);
      onUpdateState("billingProviderNPI", undefined);
      onUpdateState("taxonomyCode", undefined);
      onUpdateState("billingProviderId", undefined);
    },
    [onUpdateState, onClearServerErrors]
  );

  const individualBillingProviderError =
    isIndividual &&
    state.billingProviderId &&
    loadingProvider.isOff &&
    (!state.taxonomyCode || !state.billingProviderNPI)
      ? "This employee cannot be added as the billing provider as they do not have a taxonomy code & NPI linked to their profile."
      : undefined;

  const billingProviderError = serverErrors.billingProviderId || individualBillingProviderError;

  return (
    <div>
      <div className="flex text-sm font-sansSemiBold mb-4 gap-x-2 items-center">
        <span>Automation Defaults</span>
        <FloatingTooltip content="Select the default provider type, name, code and NPI number to include on automatically drafted claims. The treating and billing provider can be modified when creating a new claim.">
          <div>
            <Icon SvgIcon={InfoIcon} theme="slate700" />
          </div>
        </FloatingTooltip>
      </div>
      <div className="grid grid-cols-2 gap-x-6 gap-y-6">
        <FormFieldSelect
          label="Billing Provider Type"
          layout="labelOut"
          required
          edit={isEditing}
          options={billingProviderList}
          display="label"
          placeholder="Select"
          onItemChanged={handleBillingProviderTypeChange}
          value={state.billingProviderType}
          error={serverErrors.billingProviderType}
        />
        {isIndividual ? (
          <FormFieldSelect
            label="Billing Provider"
            layout="labelOut"
            required
            edit={isEditing}
            options={billingProviderOptions}
            onItemChanged={setBillingProviderId}
            value={state.billingProviderId}
            display="label"
            placeholder="Select"
            error={billingProviderError}
          />
        ) : (
          <div />
        )}
        {isIndividualOrBusiness && (
          <>
            <FormFieldSelect
              label="Taxonomy Code"
              layout="labelOut"
              required
              edit={isEditing}
              options={taxonomyCodeOptions}
              onItemChanged={(value) => onUpdateState("taxonomyCode", value)}
              value={taxnonomyCode}
              disabled={isIndividual || !state.billingProviderType}
              error={serverErrors.taxonomyCode}
              display="both"
              placeholder="Select"
            />
            <FormFieldInput
              label="Billing Provider NPI"
              layout="labelOut"
              required
              edit={isEditing}
              maxLength={10}
              minLength={10}
              disabled={isIndividual || !state.billingProviderType}
              placeholder="10-digit National Provider Identification number"
              value={state.billingProviderNPI}
              onChange={(e) => onUpdateState("billingProviderNPI", e.target.value)}
              error={serverErrors.billingProviderNPI}
            />
          </>
        )}
        <FormFieldSwitch
          label={
            <div className="flex font-sansSemiBold text-xs gap-x-2 items-center">
              <span>Automatically create Primary claim</span>
              <FloatingTooltip content="Archy will automatically create a claim for an appointment once it is completed and if the patient has an insurance plan attached.">
                <div>
                  <Icon SvgIcon={InfoIcon} theme="slate700" />
                </div>
              </FloatingTooltip>
            </div>
          }
          edit={isEditing}
          checked={Boolean(state.autoCreatePrimary)}
          onChange={(e) => onUpdateState("autoCreatePrimary", e.target.checked)}
        />
        <FormFieldSwitch
          label={
            <div className="flex font-sansSemiBold text-xs gap-x-2 items-center">
              <span>Automatically create Secondary claim</span>
              <FloatingTooltip content="A secondary claim will be created automatically once the primary claim is processed with an EOB.">
                <div>
                  <Icon SvgIcon={InfoIcon} theme="slate700" />
                </div>
              </FloatingTooltip>
            </div>
          }
          edit={isEditing}
          checked={Boolean(state.autoCreateSecondary)}
          onChange={(e) => onUpdateState("autoCreateSecondary", e.target.checked)}
        />
        <FormFieldSwitch
          className="col-span-2"
          label={
            <div className="flex font-sansSemiBold text-xs gap-x-2 items-center">
              <span>Create $0 dollar claims</span>
              <FloatingTooltip content="Determine if claims should automatically be drafted when the total insurance balance is $0.">
                <div>
                  <Icon SvgIcon={InfoIcon} theme="slate700" />
                </div>
              </FloatingTooltip>
            </div>
          }
          edit={isEditing}
          checked={Boolean(state.zeroDollarClaims)}
          onChange={(e) => onUpdateState("zeroDollarClaims", e.target.checked)}
        />
      </div>
    </div>
  );
};
