import { FC, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { FormRequest, FormSummaryVO } from "@libs/api/generated-api";
import { getFullUrl } from "@libs/utils/location";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { ApiClientContext } from "@libs/contexts/ApiClientContext";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getInfiniteFormSummaries } from "api/forms/queries";
import { createForm, archiveForm } from "api/forms/mutations";
import { handleError } from "utils/handleError";
import { paths } from "utils/routing/paths";
import { FormsSettingsPanel } from "components/Settings/Forms/FormsSettingsPanel";
import { useItemModal } from "hooks/useItemModal";
import { getFormDuplicateRequest } from "components/Settings/Forms/utils";
import { FORM_TITLE_MAX_LENGTH } from "components/Settings/Forms/PageTitleEditor";

const tableHeaders = [
  { id: "lastName", label: "Name", sortable: true, width: "minmax(11rem, 20rem)" },
  { id: "created", label: "Created", width: "min-content" },
  { id: "lastPublished", label: "Last Published", width: "min-content" },
  { id: "procedures", label: "Procedures", width: "minmax(8rem, 20rem)" },
  { id: "categories", label: "Categories", width: "minmax(8rem, 20rem)" },
  { id: "providers", label: "Providers", width: "minmax(8rem, 20rem)" },
  { id: "status", label: "Status", width: "min-content" },
  { id: "actions", label: "", width: "1fr" },
];

export const ClinicalNoteFormsRoute: FC = () => {
  const confirmArchiveModal = useItemModal<FormSummaryVO>(null);
  const { practiceId } = useAccount();
  const navigate = useNavigate();
  const location = useLocation();
  const { httpClient } = useContext(ApiClientContext);

  const duplicatingForm = useBoolean(false);
  const formsQuery = useInfiniteApiQuery(
    getInfiniteFormSummaries({ args: { practiceId, types: ["CLINICAL_NOTE"], pageNumber: 1 } })
  );

  const [createFormMutation, archiveFormMutation] = useApiMutations([createForm, archiveForm]);

  const handleCreateForm = async () => {
    try {
      const data: FormRequest = {
        title: "Untitled Clinical Notes Form",
        content: [{ content: [] }],
        type: "CLINICAL_NOTE",
      };
      const repsonse = await createFormMutation.mutateAsync({ practiceId, data });
      const formId = repsonse.data.data.uuid;

      navigate(paths.editClinicalNotesForms({ formId }, { from: getFullUrl(location) }));
    } catch (error) {
      handleError(error);
    }
  };

  const handleArchiveForm = async (uuid: FormSummaryVO["uuid"]) => {
    try {
      await archiveFormMutation.mutateAsync({ practiceId, uuidOrSlug: uuid });
      confirmArchiveModal.close();
    } catch (error) {
      handleError(error);
    }
  };

  const handleDuplicateForm = async (uuid: FormSummaryVO["uuid"]) => {
    try {
      duplicatingForm.on();

      const result = await httpClient.practices.getDraftForm(practiceId, uuid);

      const originalForm = result.data.data;
      const formRequest = getFormDuplicateRequest(originalForm);

      const suffix = " (Copy)";
      const ellipses = "...";
      const maxLengthWithSuffix = FORM_TITLE_MAX_LENGTH - suffix.length - ellipses.length;

      formRequest.title =
        formRequest.title.length > maxLengthWithSuffix
          ? `${formRequest.title.slice(0, maxLengthWithSuffix)}${ellipses}${suffix}`
          : `${formRequest.title}${suffix}`;

      await createFormMutation.mutateAsync({
        practiceId,
        data: formRequest,
      });
    } catch (error) {
      handleError(error);
    } finally {
      duplicatingForm.off();
    }
  };

  return (
    <FormsSettingsPanel
      title="Clinical Note Templates"
      tableHeaders={tableHeaders}
      onArchiveForm={handleArchiveForm}
      onCreateForm={handleCreateForm}
      onDuplicateForm={handleDuplicateForm}
      formsQuery={formsQuery}
      isArchiving={archiveFormMutation.isLoading}
      isCreating={createFormMutation.isLoading || duplicatingForm.isOn}
      confirmArchiveModal={confirmArchiveModal}
    />
  );
};
