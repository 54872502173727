import { FormEvent, useEffect, useRef, useState } from "react";
import { DentalProcedureVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { maxLength, minArrayLen, required } from "@libs/utils/validators";
import { Button } from "@libs/components/UI/Button";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Form } from "@libs/components/UI/Form";
import { ProcedureSelector } from "components/ProcedureSelector/ProcedureSelector";
import { ProceduresList } from "components/Settings/ChartingSettings/ProceduresList";
import { SurfaceSelector } from "components/Charting/SurfaceSelector";
import { Surface, surfaceSelectorToArray, surfacesToSelector } from "components/Charting/toothSurfaces";

export type ProcedureShortcutFormSubmission = {
  name: string;
  procedures: DentalProcedureVO[];
  surfaces?: Surface[];
};

interface Props {
  name?: string;
  procedures?: DentalProcedureVO[];
  surfaces?: Surface[];
  allProcedures: DentalProcedureVO[] | undefined;
  onCancelClick: Func;
  onFormSubmit: (form: ProcedureShortcutFormSubmission) => void;
}

const NAME_MAX_LEN = 40;

const schema = {
  name: [
    { $v: required, $error: "A name is required for your note" },
    { $v: maxLength(NAME_MAX_LEN), $error: `Maximum characters is ${NAME_MAX_LEN}`, $validate: true },
  ],
  procedures: [{ $v: minArrayLen(1), $error: "At least one procedure is required" }],
};

export const ShortcutForm: React.FC<Props> = ({
  name,
  procedures,
  surfaces,
  allProcedures,
  onFormSubmit,
  onCancelClick,
}) => {
  const [localName, setName] = useState(name || "");
  const [localProcedures, setProcedures] = useState(procedures || []);
  const [selection, setSelection] = useState(() => surfacesToSelector(surfaces ?? []));
  const nameRef = useRef<null | HTMLInputElement>(null);
  const formData = {
    name: localName.trim(),
    procedures: localProcedures,
    surfaces: surfaceSelectorToArray(selection, "POSTERIOR"),
  };
  const validation = useValidation(formData, schema);

  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    const result = validation.validate();

    if (result.$isValid) {
      onFormSubmit({
        ...formData,
        surfaces: formData.surfaces.length ? formData.surfaces : undefined,
      });
    }

    e.preventDefault();
  };

  useEffect(() => {
    nameRef.current?.focus();
  }, []);

  return (
    <Form fieldLayout="labelIn" onSubmit={handleSubmit} className="flex flex-col gap-y-4">
      <FormFieldInput
        ref={nameRef}
        name="name"
        label="Quick Select Name"
        required
        error={validation.result.name.$error}
        value={localName}
        onChange={(e) => setName(e.target.value)}
        className="max-w-sm"
      />
      <ProcedureSelector
        required
        procedures={allProcedures}
        label="Quick Select Procedures"
        error={validation.result.procedures.$error}
        onChange={(newProcedures) => setProcedures((last) => [...last, ...newProcedures])}
        className="max-w-sm relative z-20"
        placeholder="Add standard procedures to the quick select"
      />

      <ProceduresList
        procedures={localProcedures}
        onRemoveClick={(procedureId, procedureIndex) =>
          setProcedures((last) =>
            last.filter(({ id }, index) => `${id}-${index}` !== `${procedureId}-${procedureIndex}`)
          )
        }
      />

      <div className="flex flex-col gap-y-4">
        <div className="font-sansSemiBold text-sm">Tooth Surface</div>
        <SurfaceSelector
          className="w-20 my-5"
          selection={selection}
          selectionType="multi"
          onUpdate={setSelection}
        />
      </div>

      <div className="flex gap-x-2">
        <Button className="min-w-24" theme="secondary" type="button" onClick={onCancelClick}>
          Cancel
        </Button>
        <Button className="min-w-24" type="submit">
          Save
        </Button>
      </div>
    </Form>
  );
};
