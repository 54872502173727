import { FC, FormEvent, useState, useRef, useMemo, useCallback } from "react";
import { flushSync } from "react-dom";

import { DentalProcedureVO } from "@libs/api/generated-api";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldChildrenContainer } from "@libs/components/UI/FormField";
import { CharacterCounter } from "@libs/components/UI/CharacterCounter";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useValidation } from "@libs/hooks/useValidation";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";

import { Flyover } from "components/UI/Flyover";
import { FlyoverForm, FlyoverContent, FlyoverFooter } from "components/UI/FlyoverComponents";
import { TooltipLabel } from "components/UI/TooltipLabel";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";

import { DurationSection } from "components/Settings/Scheduling/Sections/ProcedureDurations/DurationSection";
import {
  CustomProcedureRequestState,
  getCustomProcedureRequestState,
  getExpiredCodeMessage,
  customProcedureSchema,
  CODE_MAX_LENGTH,
  NAME_MAX_LENGTH,
  SIMPLE_NAME_MAX_LENGTH,
  LAYMAN_TERM_MAX_LENGTH,
} from "components/Settings/Scheduling/Sections/ProcedureDurations/utils";

import { createCustomProcedure, updateCustomProcedure } from "api/practice/mutations";
import { useNow } from "hooks/useNow";
import { scrollToFirstError } from "utils/scrollToFirstError";
import { handleError } from "utils/handleError";

interface Props {
  procedure?: DentalProcedureVO;
  onClose: Func;
}

export const CustomProcedureFlyover: FC<Props> = ({ procedure, onClose }) => {
  const isEdit = Boolean(procedure);
  const [customProcedure, setCustomProcedure] = useState<CustomProcedureRequestState>(() =>
    getCustomProcedureRequestState(procedure)
  );

  const { practiceId } = useAccount();
  const formContainer = useRef<HTMLDivElement>(null);
  const now = useNow();

  const expiredCodeMessage = useMemo(
    () => getExpiredCodeMessage(customProcedure.endDate, now),
    [customProcedure.endDate, now]
  );

  const { validate, result: validationResult } = useValidation(customProcedure, customProcedureSchema);

  const [createCustomProcedureMutation, updateCustomProcedureMutation] = useApiMutations([
    createCustomProcedure,
    updateCustomProcedure,
  ]);
  const createCustomProcedureMutate = createCustomProcedureMutation.mutate;
  const updateCustomProcedureMutate = updateCustomProcedureMutation.mutate;

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const result = flushSync(() => validate());

      if (!result.$isValid) {
        scrollToFirstError(formContainer.current);

        return;
      }

      if (procedure) {
        updateCustomProcedureMutate(
          { practiceId, dentalProcedureId: procedure.id, data: customProcedure },
          { onSuccess: onClose, onError: handleError }
        );

        return;
      }

      createCustomProcedureMutate(
        { practiceId, data: customProcedure },
        { onSuccess: onClose, onError: handleError }
      );
    },
    [
      procedure,
      practiceId,
      customProcedure,
      validate,
      updateCustomProcedureMutate,
      createCustomProcedureMutate,
      onClose,
    ]
  );

  return (
    <Flyover
      title={`${isEdit ? "Edit" : "Add"} Custom Procedure Code`}
      onClose={onClose}
      headerSize="sm"
      size="md"
    >
      {({ close }) => (
        <FlyoverForm onSubmit={handleSubmit}>
          <FlyoverContent containerRef={formContainer} paddingClassName="flex flex-col gap-y-6 p-6">
            <FormFieldInput
              label={
                expiredCodeMessage ? (
                  <TooltipLabel SvgIcon={ErrorIcon} tooltip={{ content: expiredCodeMessage }} theme="error">
                    Custom Code
                  </TooltipLabel>
                ) : isEdit ? (
                  "Custom Code"
                ) : (
                  <TooltipLabel
                    tooltip={{
                      content:
                        "Custom codes can be up to 10 characters in length and contain letters or numbers. They cannot start with the letter D.",
                    }}
                    disabled={isEdit}
                  >
                    Custom Code
                  </TooltipLabel>
                )
              }
              value={customProcedure.cdtCode}
              onChange={(e) =>
                setCustomProcedure((last) => ({ ...last, cdtCode: e.target.value.toUpperCase() }))
              }
              maxLength={CODE_MAX_LENGTH}
              error={validationResult.cdtCode.$error}
              disabled={isEdit}
              required
            >
              <FormFieldChildrenContainer align="center">
                <CharacterCounter currentCount={customProcedure.cdtCode.length} max={CODE_MAX_LENGTH} />
              </FormFieldChildrenContainer>
            </FormFieldInput>

            <FormFieldTextarea
              label="Description"
              value={customProcedure.name}
              onChange={(e) => setCustomProcedure((last) => ({ ...last, name: e.target.value }))}
              maxLength={NAME_MAX_LENGTH}
              error={validationResult.name.$error}
              disableResize
              required
              rows={3}
            >
              <FormFieldChildrenContainer align="bottom">
                <CharacterCounter currentCount={customProcedure.name.length} max={NAME_MAX_LENGTH} />
              </FormFieldChildrenContainer>
            </FormFieldTextarea>

            <FormFieldInput
              label="Simple Name"
              value={customProcedure.simpleName}
              onChange={(e) => setCustomProcedure((last) => ({ ...last, simpleName: e.target.value }))}
              maxLength={SIMPLE_NAME_MAX_LENGTH}
              error={validationResult.simpleName.$error}
              required
            >
              <FormFieldChildrenContainer align="center">
                <CharacterCounter
                  currentCount={customProcedure.simpleName.length}
                  max={SIMPLE_NAME_MAX_LENGTH}
                />
              </FormFieldChildrenContainer>
            </FormFieldInput>

            <FormFieldInput
              label={
                <TooltipLabel
                  tooltip={{
                    content:
                      "This name will be used on treatment plans, invoices, receipts, and any patient-facing communications.",
                  }}
                >
                  External Name
                </TooltipLabel>
              }
              value={customProcedure.laymanTerm}
              onChange={(e) => setCustomProcedure((last) => ({ ...last, laymanTerm: e.target.value }))}
              maxLength={LAYMAN_TERM_MAX_LENGTH}
            >
              <FormFieldChildrenContainer align="center">
                <CharacterCounter
                  currentCount={customProcedure.laymanTerm?.length ?? 0}
                  max={LAYMAN_TERM_MAX_LENGTH}
                />
              </FormFieldChildrenContainer>
            </FormFieldInput>

            <DurationSection
              duration={customProcedure.duration}
              onAdd={(durationType) =>
                setCustomProcedure((last) => ({
                  ...last,
                  // Assistant is presented as / and Provider is X
                  duration: `${last.duration}${durationType === "A" ? "/" : "X"}`,
                }))
              }
              onRemove={(index) =>
                setCustomProcedure((last) => ({
                  ...last,
                  duration: last.duration.slice(0, index) + last.duration.slice(index + 1),
                }))
              }
              error={validationResult.duration.$error}
            />

            {isEdit ? (
              <FormFieldSelectMenusDatepicker
                label="Expiration Date"
                className="w-40"
                selected={customProcedure.endDate ? getLocalDate(customProcedure.endDate) : undefined}
                onChange={(date) =>
                  setCustomProcedure((last) => ({
                    ...last,
                    endDate: date ? formatAsISODate(date) : undefined,
                  }))
                }
              />
            ) : null}

            <div className="flex flex-col gap-y-3">
              <span className="font-sansSemiBold text-xs">Options</span>

              <div className="flex flex-col gap-y-4">
                <Checkbox
                  checked={Boolean(customProcedure.isHygiene)}
                  onChange={(e) => setCustomProcedure((last) => ({ ...last, isHygiene: e.target.checked }))}
                >
                  <TooltipLabel
                    tooltip={{
                      content: "When selected, this custom procedure can be assigned to a hygienist.",
                    }}
                  >
                    Hygiene Procedure
                  </TooltipLabel>
                </Checkbox>

                <Checkbox
                  checked={Boolean(customProcedure.creditToPractice)}
                  onChange={(e) =>
                    setCustomProcedure((last) => ({ ...last, creditToPractice: e.target.checked }))
                  }
                >
                  <TooltipLabel
                    tooltip={{
                      content:
                        "When selected, this procedure will not be credited to a specific hygienist or doctor. This will immediately apply to all incomplete procedures.",
                    }}
                  >
                    Credit to Practice
                  </TooltipLabel>
                </Checkbox>
              </div>
            </div>
          </FlyoverContent>

          <FlyoverFooter>
            <Button className="min-w-button" onClick={close} theme="secondary">
              Cancel
            </Button>
            <AsyncButton
              className="min-w-button"
              isLoading={createCustomProcedureMutation.isLoading || updateCustomProcedureMutation.isLoading}
              type="submit"
            >
              Save
            </AsyncButton>
          </FlyoverFooter>
        </FlyoverForm>
      )}
    </Flyover>
  );
};
