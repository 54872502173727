import { NavLink } from "react-router-dom";
import { useMemo } from "react";
import { cx } from "@libs/utils/cx";
import { useScheduleLinks } from "components/ScheduleAppointments/ScheduleLinksContext";

export const RequestsASAPTabs: React.FC = () => {
  const { links } = useScheduleLinks();
  const tabs = useMemo(
    () => [
      {
        to: links.requests,
        text: "Requests",
      },
      {
        to: links.asapList,
        text: "ASAP",
      },
    ],
    [links]
  );

  return (
    <div className="flex ml-1 gap-6">
      {tabs.map((tab) => (
        <NavLink
          key={tab.text}
          className={({ isActive }) =>
            cx(
              "font-sansSemiBold relative",
              isActive &&
                `
          text-primaryTheme
          before:bg-primaryTheme
          before:rounded
          before:h-[2px]
          before:absolute
          before:left-0
          before:right-0
          before:-bottom-4
        `
            )
          }
          to={tab.to}
        >
          {tab.text}
        </NavLink>
      ))}
    </div>
  );
};
