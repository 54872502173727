import { makeQuery, getNextPageParam, makeInfiniteQuery } from "@libs/utils/queries";
import { oneMinuteCache, cacheForSession } from "@libs/utils/queryCache";

type FeeScheduleQueryV2 = {
  carrierIds?: number[];
  practiceId: number;
  inNetwork?: boolean;
  maxPageSize?: number;
  orderBy?: string;
  pageNumber: number;
  pageSize: number;
  providerIds?: number[];
  sortColumn?: string;
  states?: ("ACTIVE" | "ARCHIVED")[];
  types?: ("PRACTICE_UCR" | "PROVIDER_UCR" | "CARRIER")[];
  searchString?: string;
};

export const getInfiniteFeeSchedulesV2Query = makeInfiniteQuery({
  queryKey: ["v2", "getFeeSchedulesV2"],
  formatParams: ({ practiceId, ...rest }: FeeScheduleQueryV2) => [practiceId, rest],
  queryOptions: {
    ...oneMinuteCache,
    getNextPageParam,
  },
});

export const getFeeScheduleDetails = makeQuery({
  queryKey: ["practices", "getFeeScheduleDetails"],
  formatParams: ({ practiceId, feeScheduleId }: { practiceId: number; feeScheduleId: number }) => [
    practiceId,
    feeScheduleId,
  ],
  queryOptions: {
    ...oneMinuteCache,
  },
});

export const getPracticeFeeScheduleNamesQuery = makeQuery({
  queryKey: ["practices", "getPracticeFeeScheduleNames"],
  formatParams: ({ practiceId, carrierId }: { practiceId: number; carrierId: number }) => [
    practiceId,
    { carrierId },
  ],
  queryOptions: {
    ...cacheForSession,
  },
});

export const getProvidersWithoutUcrQuery = makeQuery({
  queryKey: ["practices", "getProvidersWithoutUcr"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: {
    ...oneMinuteCache,
  },
});
