import { FC } from "react";

import { PatientSummaryVO } from "@libs/api/generated-api";
import { Pill, PillProps } from "@libs/components/UI/Pill";

const PatientStatusPillProps: Record<PatientSummaryVO["status"], Pick<PillProps, "children" | "theme">> = {
  PROSPECT: {
    children: "Prospect",
    theme: "slate200",
  },
  NONPATIENT: {
    children: "Non Patient",
    theme: "slate200",
  },
  ACTIVE: {
    children: "Active",
    theme: "green",
  },
  ARCHIVED: {
    children: "Archived",
    theme: "red",
  },
  INACTIVE: {
    children: "Inactive",
    theme: "red",
  },
  DECEASED: {
    children: "Deceased",
    theme: "red",
  },
};

export const PatientStatusPill: FC<{ status: PatientSummaryVO["status"] }> = ({ status }) => {
  return <Pill {...PatientStatusPillProps[status]} />;
};
