import { FC } from "react";

import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { Panel } from "@libs/components/UI/Panel";

import { TemplateCard } from "components/Communications/Campaigns/EmailTemplates/TemplateCard";
import {
  TemplatePreviewModal,
  TemplatePreviewModalProps,
} from "components/Communications/Campaigns/EmailTemplates/TemplatePreviewModal";

import { templates } from "components/Communications/Campaigns/EmailTemplates/templates";
import { EmailEditorJSONTemplate } from "components/Communications/Campaigns/EmailEditor/types";

import { useItemModal } from "hooks/useItemModal";

interface Props {
  onApplyTemplate: (jsonTemplate: EmailEditorJSONTemplate) => void;
  from: string;
}

export const EmailTemplates: FC<Props> = ({ onApplyTemplate, from }) => {
  const previewModal =
    useItemModal<Pick<TemplatePreviewModalProps, "name" | "previewImage" | "onApplyTemplate">>(null);

  return (
    <Panel
      title="Select Templates"
      className="h-full"
      contentClassName="flex flex-col items-center overflow-y-auto"
      actions={
        <LinkIcon
          SvgIcon={CloseIcon}
          tooltip={{ content: "Close", theme: "SMALL" }}
          to={from}
          theme="primary"
          replace
        />
      }
    >
      <div className="grid grid-cols-1 lg:grid-cols-2 gap-6 max-w-5xl">
        {templates.map((template) => (
          <TemplateCard
            key={template.id}
            name={template.name}
            description={template.description}
            previewImage={template.previewImage}
            onPreviewTemplate={
              template.id === "blank"
                ? undefined
                : () =>
                    previewModal.open({
                      name: "Template Name",
                      previewImage: template.previewImage,
                      onApplyTemplate: () => onApplyTemplate(template.json),
                    })
            }
            onApplyTemplate={() => onApplyTemplate(template.json)}
          />
        ))}
      </div>

      {previewModal.isOpen ? (
        <TemplatePreviewModal {...previewModal.item} onClose={previewModal.close} />
      ) : null}
    </Panel>
  );
};
