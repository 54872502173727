import { ChangeEvent, FC, FormEventHandler, useCallback, useMemo, useState } from "react";
import { produce } from "immer";
import { MountVO, TransferImagesRequest } from "@libs/api/generated-api";
import { noop } from "@libs/utils/noop";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { RadioList } from "@libs/components/UI/RadioList";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useAccount } from "@libs/contexts/AccountContext";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { FlyoverContent, FlyoverFooter, FlyoverForm } from "components/UI/FlyoverComponents";
import { searchForPatients } from "api/patients/queries";
import { Flyover } from "components/UI/Flyover";
import { TransferPatientSearch } from "components/PatientProfile/Imaging/PatientMountsList/TransferPatientSearch";
import { Divider } from "components/UI/Divider";
import { getInfiniteMountsQuery } from "api/imaging/queries";
import { SelectPatientMount } from "components/PatientProfile/Imaging/MountRoute/SelectPatientMount";

export type ImageTransferData = Pick<TransferImagesRequest, "targetMountId" | "targetPatientId">;

interface Props {
  isLoading: boolean;
  onClose: Func;
  onTransfer: ({ targetMountId, targetPatientId }: ImageTransferData) => void;
  sourceMount: MountVO;
  sourcePatientId: number;
}

const SEARCH_PAGE_SIZE = 10;

type PatientType = "this" | "other";
type MountType = "new" | "existing";

const PATIENT_OPTIONS: OptionInputOption<PatientType>[] = [
  {
    label: "This patient",
    value: "this",
  },
  {
    label: "Other patient",
    value: "other",
  },
];

export const TransferImagesFlyover: FC<Props> = ({
  isLoading,
  onClose,
  onTransfer,
  sourceMount,
  sourcePatientId,
}) => {
  const { practiceId } = useAccount();
  const [requestData, setRequestData] = useState<ImageTransferData>({ targetPatientId: sourcePatientId });
  const [selectedPatientType, setSelectedPatientType] = useState<PatientType>("this");
  const [selectedMountType, setSelectedMountType] = useState<MountType>("new");

  const handleUpdateData = useCallback((updates: Partial<ImageTransferData>) => {
    setRequestData((last) =>
      produce(last, (draft) => {
        return { ...draft, ...updates };
      })
    );
  }, []);

  const [searchString, setSearchString] = useState("");
  const { search: debouncedSearchString } = useDebouncedSearch(searchString);

  const [searchPatientsQuery] = useApiQueries([
    searchForPatients({
      args: {
        pageNumber: 1,
        pageSize: SEARCH_PAGE_SIZE,
        practiceId,
        searchString: debouncedSearchString,
      },
      queryOptions: { enabled: debouncedSearchString.length > 0 },
    }),
  ]);

  const patientSearchResults = (searchPatientsQuery.data ?? []).filter(
    (patient) => patient.id !== sourcePatientId
  );

  const isSearchingPatient = useMemo(
    () => searchPatientsQuery.isLoading || searchPatientsQuery.isFetching,
    [searchPatientsQuery]
  );

  const mountsInfiniteQuery = useInfiniteApiQuery(
    getInfiniteMountsQuery({
      args: { pageSize: PAGE_SIZE, pageNumber: 1, patientId: requestData.targetPatientId, practiceId },
      queryOptions: {
        enabled: Boolean(requestData.targetPatientId),
      },
    })
  );
  const imageMountList = useFlattenPages(mountsInfiniteQuery.data);
  const numOfMounts = getInfiniteQueryPagingDetails(mountsInfiniteQuery.data)?.totalElements ?? 0;
  const hideMountIds = useMemo(() => {
    return new Set((imageMountList ?? []).filter((mount) => mount.id === sourceMount.id).map(({ id }) => id));
  }, [imageMountList, sourceMount.id]);

  const mountTypeOptions: OptionInputOption<MountType>[] = useMemo(
    () => [
      {
        label: "New",
        value: "new",
      },
      {
        disabled: (selectedPatientType === "other" && !requestData.targetPatientId) || numOfMounts === 0,
        label: (
          <FloatingTooltip
            content={
              selectedPatientType === "other" && !requestData.targetPatientId
                ? "Select patient"
                : numOfMounts === 0
                  ? `No existing mounts for ${
                      selectedPatientType === "this" ? `this patient` : `${searchString}`
                    }`
                  : undefined
            }
          >
            <div>Existing</div>
          </FloatingTooltip>
        ),
        value: "existing",
      },
    ],
    [numOfMounts, requestData.targetPatientId, searchString, selectedPatientType]
  );

  const handleSelectPatientType = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === "this") {
        setSearchString("");
        handleUpdateData({ targetPatientId: sourcePatientId });
      } else {
        const updates: Partial<TransferImagesRequest> = {
          targetPatientId: undefined,
        };

        if (selectedMountType === "existing") {
          setSelectedMountType("new");
        }

        handleUpdateData(updates);
      }

      setSelectedPatientType(e.target.value as PatientType);
    },
    [handleUpdateData, selectedMountType, sourcePatientId]
  );

  const handleSelectMountType = useCallback(
    (e: ChangeEvent<HTMLInputElement>) => {
      if (e.target.value === "new") {
        handleUpdateData({ targetMountId: undefined });
      }

      setSelectedMountType(e.target.value === "new" ? "new" : "existing");
    },
    [handleUpdateData]
  );

  const handleSubmit: FormEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      onTransfer(requestData);
    },
    [onTransfer, requestData]
  );
  const selectedMount = useMemo(
    () => imageMountList?.find((mount) => mount.id === requestData.targetMountId),
    [requestData.targetMountId, imageMountList]
  );

  return (
    <Flyover title="Transfer Images" onClose={onClose} size="md">
      {({ close }) => (
        <FlyoverForm className="text-sm" fieldLayout="labelOut" onSubmit={handleSubmit}>
          <FlyoverContent>
            <div className="flex flex-col gap-y-4">
              <RadioList
                label="Choose Patient"
                layout="horiz"
                onChange={handleSelectPatientType}
                options={PATIENT_OPTIONS}
                selectedValue={selectedPatientType}
              />
              {selectedPatientType === "other" && (
                <TransferPatientSearch
                  isSearching={isSearchingPatient}
                  onClearSelected={() => handleUpdateData({ targetPatientId: undefined })}
                  onSearch={setSearchString}
                  onSelect={(patientId: number) => {
                    handleUpdateData({ targetPatientId: patientId });
                  }}
                  patientSearchResults={patientSearchResults}
                  searchString={searchString}
                />
              )}
              <Divider className="border-dashed dark:border-slate-500" />
              <RadioList
                label="Choose Mount"
                layout="horiz"
                onChange={handleSelectMountType}
                options={mountTypeOptions}
                selectedValue={selectedMountType}
              />

              {requestData.targetPatientId &&
                (selectedMountType === "existing" ? (
                  <SelectPatientMount
                    mountsInfiniteQuery={mountsInfiniteQuery}
                    hideMountIds={hideMountIds}
                    isDark
                    selectedMount={selectedMount}
                    label="Select Existing Mount"
                    onChange={(targetMountId) => handleUpdateData({ targetMountId })}
                  />
                ) : (
                  <FormFieldInput
                    edit={false}
                    label="Mount Name"
                    onChange={noop}
                    value={sourceMount.name || sourceMount.layout}
                  />
                ))}
            </div>
          </FlyoverContent>
          <FlyoverFooter>
            <Button className="min-w-button" onClick={close} theme="secondary">
              Cancel
            </Button>
            <AsyncButton
              className="min-w-button"
              disabled={
                !requestData.targetPatientId ||
                (selectedMountType === "existing" && !requestData.targetMountId)
              }
              isLoading={isLoading}
              type="submit"
            >
              Transfer Images
            </AsyncButton>
          </FlyoverFooter>
        </FlyoverForm>
      )}
    </Flyover>
  );
};
