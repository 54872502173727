import { QueryClient } from "@tanstack/react-query";
import { produce } from "immer";
import { MedicalImageVO, MountVO } from "@libs/api/generated-api";
import { getQueryKey } from "@libs/utils/queries";
import { updateCachedData, updatePaginatedCachedData } from "@libs/utils/queryCache";
import { DEFAULT_TRANSFORMS } from "components/PatientProfile/Imaging/MountRoute/image-utils";

export const updateMountCacheEntry = (
  queryClient: QueryClient,
  { practiceId, mountId, patientId }: { practiceId: number; patientId: number; mountId: number },
  updater: (cacheEntry: MountVO) => MountVO
) => {
  updateCachedData<MountVO>(
    queryClient,
    {
      exact: false,
      queryKey: [
        getQueryKey("practices", "getMount"),
        {
          practiceId,
          patientId,
          mountId,
        },
      ],
    },
    updater
  );
  updatePaginatedCachedData<MountVO>(
    queryClient,
    {
      queryKey: [
        getQueryKey("practices", "getMountList"),
        {
          practiceId,
          patientId,
        },
      ],
      exact: false,
    },
    (mounts) => {
      return mounts.map((mount) => {
        if (mount.id === mountId) {
          return updater(mount);
        }

        return mount;
      });
    }
  );
};

export const updateCachedImage = (
  queryClient: QueryClient,
  params: {
    patientId: number;
    practiceId: number;
    mountId?: number;
    imageId: number;
  },
  extensions: Partial<MedicalImageVO>
) => {
  const { patientId, practiceId, mountId, imageId } = params;
  const makeUpdates = (image: MedicalImageVO) => {
    const { transforms, filters, annotation, teeth, pearlAnalysis } = extensions;

    return produce(image, (draft) => {
      draft.filters = {
        ...draft.filters,
        ...filters,
      };
      draft.transforms = {
        ...DEFAULT_TRANSFORMS,
        ...draft.transforms,
        ...transforms,
      };

      if ("annotation" in extensions) {
        draft.annotation = annotation;
      }

      draft.teeth = teeth ?? image.teeth;
      draft.pearlAnalysis = pearlAnalysis ?? image.pearlAnalysis;
      for (const key of ["assignedDate", "sensor"] as const) {
        if (key in extensions) {
          draft[key] = extensions[key];
        }
      }
    });
  };
  const updateImages = (priorImages?: MedicalImageVO[]) => {
    return (
      priorImages?.map((item) => {
        if (item.id === imageId) {
          return makeUpdates(item);
        }

        return item;
      }) ?? []
    );
  };

  if (mountId) {
    // User is editing an image in a mount
    updateMountCacheEntry(queryClient, { patientId, practiceId, mountId }, (mount) => {
      return {
        ...mount,
        images: updateImages(mount.images),
      };
    });
  } else {
    // User is editing images independent of a mount. Decache all fetched mounts
    queryClient.invalidateQueries([getQueryKey("practices", "getMount"), { practiceId, patientId }]);
  }

  updatePaginatedCachedData<MedicalImageVO>(
    queryClient,
    {
      queryKey: [
        getQueryKey("practices", "getMedicalImages"),
        {
          practiceId,
          patientId,
        },
      ],
      exact: false,
    },
    (images) => updateImages(images)
  );
  updateCachedData<MedicalImageVO>(
    queryClient,
    {
      queryKey: [
        getQueryKey("practices", "getMedicalImageDetails"),
        {
          practiceId,
          patientId,
          imageId,
        },
      ],
      exact: false,
    },
    (image) => {
      const updates = makeUpdates(image);

      return {
        ...updates,
        ...extensions,
      };
    }
  );
};
