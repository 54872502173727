import React, { MouseEventHandler, useCallback, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import { ClaimSummaryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatAsISODate, formatISODate } from "@libs/utils/date";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { formatCurrency } from "@libs/utils/currency";
import { formatFullNameToInitials } from "@libs/utils/formatString";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { useAccount } from "@libs/contexts/AccountContext";
import { ButtonCell, CheckboxCell, Row } from "@libs/components/UI/GridTableComponents";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { getClaimQuery } from "api/claim/queries";
import { planTypeNames } from "components/PatientProfile/Insurance/utils";
import { RowClaimDetails } from "components/Claims/Claims/RowClaimDetails";
import { useItemModal } from "hooks/useItemModal";
import { TWO_CLICKS } from "utils/handleDoubleClick";
import { cxStyles } from "components/Claims/styles";
import { ClaimStatePill } from "components/Claims/Claims/ClaimStatePill";
import { useSubmitClaim } from "components/Claims/Shared/useSubmitClaim";
import { ClaimActionsCell, PROCESSING_EOB_TOOLTIP } from "components/Claims/Claims/ActionsCell";
import { DisableClaimBulkActions } from "components/Claims/Claims/useClaimsTableCheckboxes";
import {
  canProcessAdditionalPaymentForClaim,
  isDraftClaim,
  isInDraftEobPaymentClaim,
  isSubmittedClaim,
  isSubmittingClaim,
} from "components/Claims/utils";
import { paths } from "utils/routing/paths";
import { ClaimLink } from "components/Claim/ClaimLink";
import { ClaimError } from "components/Claims/Claims/ClaimError";
import { claimConstants } from "components/Claim/claimConstants";
import { handleError } from "utils/handleError";

interface Props {
  claim: ClaimSummaryVO;
  disableBulkActions: DisableClaimBulkActions;
  from: string;
  isExpanded: boolean;
  onSelectCheckbox: (claim: ClaimSummaryVO) => void;
  onClaimOpen: (claim: ClaimSummaryVO) => void;
  onRowClick: (claimUuid: string) => void;
  pendingClaimsSelected: boolean;
  selectedClaimUuids: Set<string>;
}

const NO_BULK_ACTION_REPLACED_CLAIMS = "Claims with replacements are not eligible for bulk actions";

// eslint-disable-next-line complexity
export const ClaimTableRow: React.FC<Props> = ({
  claim,
  disableBulkActions,
  from,
  isExpanded,
  onSelectCheckbox,
  onClaimOpen,
  onRowClick,
  pendingClaimsSelected,
  selectedClaimUuids,
}) => {
  const { practiceId } = useAccount();

  const [queryClaimUuid, setQueryClaimUuid] = useState<string>("");

  const [{ data: claimDetails }] = useApiQueries([
    getClaimQuery({
      args: { practiceId, claimUuid: queryClaimUuid || "" },
      queryOptions: { enabled: Boolean(queryClaimUuid) },
    }),
  ]);

  const cellClassName = useMemo(() => {
    return cx(
      cxStyles.cell,
      cxStyles.expandableCell(isExpanded),
      selectedClaimUuids.has(claim.uuid) && cxStyles.selectedCell
    );
  }, [claim.uuid, isExpanded, selectedClaimUuids]);

  const canProcessAdditionalPayment = canProcessAdditionalPaymentForClaim(claim);

  const bulkActionTooltip = useMemo(
    () =>
      claim.isPreAuth
        ? "Only draft pre-auth claims are eligible for bulk actions"
        : canProcessAdditionalPayment
          ? ""
          : "Not eligible for bulk submit or bulk process EOB payment",
    [canProcessAdditionalPayment, claim.isPreAuth]
  );

  const isDraft = isDraftClaim(claim);
  const isSubmitting = isSubmittingClaim(claim);
  const isSubmitted = isSubmittedClaim(claim);
  const isInDraftEobPayment = isInDraftEobPaymentClaim(claim);

  const disableCheckboxTooltip = useMemo(() => {
    return isDraft
      ? getPendingClaimTooltip(claim, disableBulkActions, selectedClaimUuids)
      : claim.replacementClaimUuid
        ? NO_BULK_ACTION_REPLACED_CLAIMS
        : isInDraftEobPayment
          ? PROCESSING_EOB_TOOLTIP
          : claim.isValidating
            ? "Validating..."
            : isSubmitting
              ? "Submitting..."
              : isSubmitted
                ? getSubmittedClaimTooltip(claim, pendingClaimsSelected)
                : bulkActionTooltip;
  }, [
    bulkActionTooltip,
    claim,
    disableBulkActions,
    isDraft,
    isInDraftEobPayment,
    isSubmitted,
    isSubmitting,
    pendingClaimsSelected,
    selectedClaimUuids,
  ]);

  const checkboxTooltip = useMemo(() => {
    return (
      disableCheckboxTooltip ||
      (isDraft ? "Select for bulk send" : isSubmitted ? "Select for bulk process EOB" : "")
    );
  }, [disableCheckboxTooltip, isDraft, isSubmitted]);

  const handleRowClick: MouseEventHandler = useCallback(
    (event) => {
      setQueryClaimUuid(claim.uuid);

      switch (event.detail) {
        case TWO_CLICKS: {
          onClaimOpen(claim);

          return;
        }
        default: {
          onRowClick(claim.uuid);

          return;
        }
      }
    },
    [claim, onClaimOpen, onRowClick]
  );

  const patientBillingLink = paths.patientBilling({ patientId: claim.patientName.id || 0 });

  const { bulkSubmit, deleteClaim } = useSubmitClaim();

  const submitClaimModal = useBoolean(false);

  const handleSubmitClick = useCallback(() => {
    submitClaimModal.off();

    bulkSubmit({ uuids: [claim.uuid] }).catch(handleError);
  }, [bulkSubmit, claim.uuid, submitClaimModal]);

  const dismissClaimModal = useItemModal<string>(null);

  const handleDeleteClaim = useCallback(() => {
    deleteClaim(claim.uuid);
    dismissClaimModal.close();
  }, [claim.uuid, deleteClaim, dismissClaimModal]);

  return (
    <Row>
      <FloatingTooltip content={checkboxTooltip} className="z-50">
        <div className="flex">
          <CheckboxCell
            checked={selectedClaimUuids.has(claim.uuid)}
            className={cellClassName}
            disabled={Boolean(disableCheckboxTooltip)}
            onChange={() => onSelectCheckbox(claim)}
            styleOptions={{ verticalPadding: "slim" }}
            value={claim.uuid}
          />
        </div>
      </FloatingTooltip>
      <ButtonCell className={cellClassName} onClick={handleRowClick} verticalPadding="slim">
        {formatISODate(claim.serviceDate)}
      </ButtonCell>
      <ButtonCell className={cellClassName} onClick={handleRowClick} verticalPadding="slim">
        {claim.submittedAt ? formatISODate(formatAsISODate(new Date(claim.submittedAt))) : ""}
      </ButtonCell>
      <ButtonCell
        className={cx("w-full min-w-24", cellClassName)}
        onClick={handleRowClick}
        verticalPadding="slim"
      >
        <FloatingTooltip content="Open Patient Billing" theme="SMALL">
          <Link className="hover:text-primaryTheme" to={patientBillingLink}>
            {claim.patientName.shortDisplayName}
          </Link>
        </FloatingTooltip>
      </ButtonCell>
      <ButtonCell
        className={cx("justify-end", cellClassName)}
        onClick={handleRowClick}
        verticalPadding="slim"
      >
        {formatCurrency(claim.ucrAmount)}
      </ButtonCell>
      <ButtonCell className={cellClassName} onClick={handleRowClick} verticalPadding="slim">
        {claim.billingProviderName.fullDisplayName}
      </ButtonCell>
      <ButtonCell className={cellClassName} onClick={handleRowClick} verticalPadding="slim">
        <FloatingTooltip content={claim.treatingProviderName.fullDisplayName} theme="SMALL">
          <span>{formatFullNameToInitials({ fullName: claim.treatingProviderName.fullDisplayName })}</span>
        </FloatingTooltip>
      </ButtonCell>
      <ButtonCell className={cellClassName} onClick={handleRowClick} verticalPadding="slim">
        <FloatingTooltip content={claim.insuranceCarrier} theme="SMALL">
          <span>{claim.insuranceCarrier}</span>
        </FloatingTooltip>
      </ButtonCell>
      <ButtonCell className={cellClassName} onClick={handleRowClick} verticalPadding="slim">
        {claim.insurancePlanType && planTypeNames[claim.insurancePlanType]}
      </ButtonCell>
      <ButtonCell className={cellClassName} onClick={handleRowClick} verticalPadding="slim">
        {sentenceCaseConstant(claim.insuranceOrdinal)}
      </ButtonCell>
      <ButtonCell className={cellClassName} onClick={handleRowClick} verticalPadding="slim">
        {claim.needsAttachment ? "Yes" : claim.needsAttachment === false ? "No" : "-"}
      </ButtonCell>
      <ButtonCell
        className={cx("p-2 gap-x-2", cellClassName)}
        onClick={handleRowClick}
        verticalPadding="slim"
      >
        <ClaimStatePill claim={claim} />
        <ClaimLink claim={claim} from={from} />
        {(claim.validationError ?? claim.submissionError) && !isSubmitting && (
          <ClaimError
            claimUuid={claim.uuid}
            isDraftClaim={isDraft}
            isValidating={claim.isValidating}
            validationError={claim.validationError}
            submissionError={claim.submissionError}
          />
        )}
      </ButtonCell>
      <ClaimActionsCell
        cellClassName={cellClassName}
        claim={claim}
        onClaimOpen={onClaimOpen}
        onDeleteClick={() => dismissClaimModal.open(claim.uuid)}
        onSubmit={submitClaimModal.on}
      />
      {isExpanded && <RowClaimDetails claim={claimDetails} />}
      {dismissClaimModal.isOpen && (
        <ConfirmationModal
          onCancel={dismissClaimModal.close}
          onConfirm={handleDeleteClaim}
          primaryText={
            claim.isPreAuth ? claimConstants.deletePreauthConfirmation : claimConstants.deleteConfirmation
          }
          secondaryText={
            claim.isPreAuth
              ? claimConstants.deletePreauthSecondaryConfirmation
              : claimConstants.deleteSecondaryConfirmation
          }
        />
      )}
      {submitClaimModal.isOn && (
        <ConfirmationModal
          onCancel={submitClaimModal.off}
          onConfirm={handleSubmitClick}
          primaryText={`Are you sure you want to send this ${claim.isPreAuth ? "pre-auth " : ""}claim?`}
          secondaryText={!claim.isPreAuth && "The claim will be sent to the payer"}
          size="fit"
        />
      )}
    </Row>
  );
};

const getPendingClaimTooltip = (
  claim: ClaimSummaryVO,
  disableBulkActions: DisableClaimBulkActions,
  selectedClaimUuids: Set<string>
) => {
  if (selectedClaimUuids.size > 0 && !selectedClaimUuids.has(claim.uuid) && disableBulkActions.submit) {
    return "Cannot select pending claims for bulk process EOB";
  } else if (claim.needsAttachment) {
    return "Cannot bulk send a claim that is missing required attachments";
  }

  return "";
};

const getSubmittedClaimTooltip = (claim: ClaimSummaryVO, pendingClaimsSelected: boolean) => {
  if (claim.isPreAuth) {
    return "Already sent";
  } else if (pendingClaimsSelected) {
    return "Cannot select sent claims for bulk send";
  }

  return "";
};
