import React, { useCallback, useState } from "react";
import { MedicalImageVO, MountVO, PatientSummaryVO } from "@libs/api/generated-api";
import { downloadUrl } from "@libs/utils/dataUrl";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Spinner } from "@libs/components/UI/Spinner";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as ExportIcon } from "@libs/assets/icons/export.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { isDefined } from "@libs/utils/types";
import { captureException as sentryCaptureException } from "@sentry/react";
import { pluralize } from "@libs/utils/pluralize";
import { sanitizeFilename } from "@libs/utils/sanitize-filename";
import { MountIconButton } from "components/PatientProfile/Imaging/MountRoute/MountIconButton";
import { getMedicalImageDetails } from "api/imaging/queries";
import { handleError } from "utils/handleError";
import { getExportedDocumentTag } from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { imagingUrlCache } from "components/PatientProfile/Imaging/utils/cache";
import { getMountName } from "components/PatientProfile/Imaging/PatientMountsList";

type Props = {
  selectedImages: MedicalImageVO[];
  handleExportMount: () => void;
  handlePrintMount: () => void;
  mount: MountVO;
  patient: PatientSummaryVO;
};

const exportSelected = async (
  mount: MountVO,
  patient: PatientSummaryVO,
  selectedImages: MedicalImageVO[]
) => {
  const images = (selectedImages.length === 0 ? mount.images : selectedImages) ?? [];
  const urls = images.map((image) => imagingUrlCache.getImageUrl(image)).filter(isDefined);
  const [{ default: JSZip }, { default: fileSaver }] = await Promise.all([
    import("jszip"),
    import("file-saver"),
  ]);
  const zip = new JSZip();
  // Asterisks cause an issue with the zip file name on windows, so we remove them if they exist
  const mountName = getMountName(mount);
  const blobs = await Promise.all(
    urls.map(async (url) => {
      const resp = await fetch(url);

      return resp.blob();
    })
  );

  for (const [i, blob] of blobs.entries()) {
    zip.file(sanitizeFilename(`${mountName}_${i}.png`), blob);
  }

  return new Promise((resolve, reject) => {
    zip.generateAsync({ type: "blob" }).then(
      (content) => {
        resolve(null);
        fileSaver.saveAs(content, getExportedDocumentTag(mount, patient));
      },
      (err) => {
        sentryCaptureException(err);
        reject(err);
      }
    );
  });
};

type MenuOption = {
  label: string;
  value: "export-original" | "download-dicom" | "export" | "print";
  isLoading?: boolean;
  disabled?: boolean;
};

const ExportMenuOption: React.FC<{
  option: MenuOption;
  handleOptionClick: (option: MenuOption["value"]) => void;
}> = ({ option, handleOptionClick }) => {
  return (
    <MenuOptionButton
      key={option.label}
      className="select-none flex flex-row gap-2"
      disabled={option.disabled}
      onClick={() => handleOptionClick(option.value)}
    >
      {option.isLoading && <Spinner size="xs" variant="secondary" animation="border" />}
      {option.label}
    </MenuOptionButton>
  );
};

export const ExportMenu: React.FC<Props> = ({
  selectedImages,
  handleExportMount,
  handlePrintMount,
  mount,
  patient,
}) => {
  const { practiceId } = useAccount();
  const [exportTypeLoading, setExportTypeLoading] = useState<MenuOption["value"] | undefined>();
  const selectedImage = selectedImages.length === 1 ? selectedImages[0] : undefined;
  const menu = useBoolean(false);
  const [imageDetailsQuery] = useApiQueries([
    getMedicalImageDetails({
      args: {
        practiceId,
        patientId: patient.id,
        imageId: selectedImage?.id ?? -1,
        query: { populateDicomUrl: true },
      },
      queryOptions: { enabled: false },
    }),
  ]);
  const closeMenu = menu.off;
  const buttonOptions: MenuOption[] = React.useMemo(() => {
    const isMultiSelect = selectedImages.length !== 1;

    return [
      {
        label: `Export Original ${pluralize(selectedImages.length, "Image", "Images")}`,
        value: "export-original" as const,
        isLoading: exportTypeLoading === "export-original",
      },
      {
        label: "Export DICOM Image",
        value: "download-dicom" as const,
        isLoading: imageDetailsQuery.isLoading,
        disabled: isMultiSelect,
      },
      {
        label: selectedImages.length > 0 ? "Export Mount Selections" : "Export Mount",
        value: "export" as const,
      },
      {
        label: selectedImages.length > 0 ? "Print Mount Selections" : "Print Mount",
        value: "print" as const,
      },
    ];
  }, [exportTypeLoading, imageDetailsQuery.isLoading, selectedImages.length]);

  const handleOptionClick = useCallback(
    async (value: MenuOption["value"]) => {
      switch (value) {
        case "print": {
          handlePrintMount();

          break;
        }
        case "export": {
          handleExportMount();

          break;
        }
        case "export-original": {
          try {
            setExportTypeLoading("export-original");
            await (selectedImages.length === 1
              ? downloadUrl(selectedImages[0].url ?? "", getExportedDocumentTag(mount, patient))
              : exportSelected(mount, patient, selectedImages));
          } catch (e) {
            handleError(e);
          } finally {
            setExportTypeLoading(undefined);
          }

          break;
        }
        case "download-dicom": {
          imageDetailsQuery.refetch().then((response) => {
            const url = response.data?.data.data.dicomUrl;

            if (url) {
              try {
                downloadUrl(url, getExportedDocumentTag(mount, patient));
              } catch (e) {
                handleError(e);
              }
            }
          });

          break;
        }
        // No default
      }

      closeMenu();
    },
    [closeMenu, handleExportMount, handlePrintMount, imageDetailsQuery, mount, patient, selectedImages]
  );

  return (
    <ButtonMenu
      className="flex items-center"
      placement="bottom-start"
      menuContent={buttonOptions.map((option) => (
        <ExportMenuOption key={option.value} handleOptionClick={handleOptionClick} option={option} />
      ))}
      isOpen={menu.isOn || imageDetailsQuery.isLoading}
      onRequestClose={menu.off}
      onRequestOpen={menu.on}
    >
      {(buttonProps) => (
        <MountIconButton
          {...buttonProps}
          tooltip={{ content: "Export...", theme: "SMALL" }}
          SvgIcon={ExportIcon}
        />
      )}
    </ButtonMenu>
  );
};
