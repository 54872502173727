import React from "react";
import {
  UncollectedByCarrierEntryVO,
  UncollectedByCarrierResponse,
  UncollectedByCarrierSubtotalVO,
  UncollectedByCarrierTotalVO,
} from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getAbsoluteUrl } from "@libs/utils/location";
import { isNullish } from "@libs/utils/types";
import { getLocalDate } from "@libs/utils/date";
import { formatPhoneNumber } from "@libs/utils/phone";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { useDownloadQueryPages } from "components/Dashboard/hooks/useDownloadInfiniteQuery";
import { getTotals } from "components/Dashboard/Tables/utils";
import { EMPTY_ROW, formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";
import { paths } from "utils/routing/paths";
import { flattenCollectionByCarrier } from "components/Dashboard/utils/flattenCollectionByCarrier";
import { UNCOLLECTED_BY_CARRIER_COLUMNS } from "components/Dashboard/OutstandingCollections/constants";
import { useCurrentUrl, useOrigin } from "contexts/OriginContext";

const formatCarrierCollectionsData = (
  carriers: {
    subtotal: UncollectedByCarrierSubtotalVO;
    rows: UncollectedByCarrierEntryVO[];
  }[],
  totals: UncollectedByCarrierTotalVO,
  origin: string
) => {
  const csvHeaders = formatCsvRow([
    "Carrier Name",
    "Carrier Phone",
    ...UNCOLLECTED_BY_CARRIER_COLUMNS.map(({ label }) => label).filter(Boolean),
    "Claim URL",
  ]);

  const rowsContent = carriers.flatMap(({ subtotal: { carrier }, rows }) => {
    const claimItems = rows.map(
      ({
        patient,
        patientDob,
        primarySubscriberPatient,
        insuranceOrdinal,
        productionDate,
        claimSubmissionDate,
        claimAmount,
        insuranceUncollectedAmount,
        claimAge,
        claimUuid,
      }) => {
        return formatCsvRow([
          carrier.name,
          `${carrier.phone ? ` ${formatPhoneNumber(carrier.phone) ?? ""}` : ""}`,
          patient.fullDisplayName,
          patientDob ?? "",
          primarySubscriberPatient.fullDisplayName,
          insuranceOrdinal,
          productionDate,
          claimSubmissionDate ?? "",
          formatCurrency(claimAmount),
          formatCurrency(insuranceUncollectedAmount),
          isNullish(claimAge) ? "" : `${claimAge}`,
          getAbsoluteUrl(origin, paths.claim({ claimUuid })),
        ]);
      }
    );

    return claimItems;
  });

  const totalsContent = formatCsvRow([
    "",
    "",
    "",
    "",
    "",
    "",
    "",
    "Grand Total:",
    formatCurrency(totals.totalClaimAmount),
    formatCurrency(totals.totalInsuranceUncollectedAmount),
    "",
  ]);

  return [csvHeaders, ...rowsContent, EMPTY_ROW, totalsContent];
};

export const useDownloadOutstandingCollectionsByCarrierCSV = ({
  outstandingCollectionsByCarrierQuery,
  selectedTimeSegment,
}: {
  outstandingCollectionsByCarrierQuery: UseInfiniteApiQueryResult<UncollectedByCarrierResponse>;
  selectedTimeSegment?: { startDate: string; endDate?: string };
}) => {
  const downloading = useBoolean(false);
  const { startDownload: downloadCollectionsByCarrier } = useDownloadQueryPages(
    outstandingCollectionsByCarrierQuery
  );
  const origin = useOrigin();
  const currentUrl = useCurrentUrl();

  return {
    isDownloading: downloading.isOn,
    downloadCSV: React.useCallback(async () => {
      downloading.on();

      const result = await downloadCollectionsByCarrier();
      const rows = flattenCollectionByCarrier<
        UncollectedByCarrierEntryVO,
        UncollectedByCarrierSubtotalVO,
        UncollectedByCarrierResponse
      >(result);
      const totals = getTotals(result);

      if (totals) {
        const docMetadata = getArchyCsvMetadata(
          currentUrl,
          selectedTimeSegment
            ? {
                startDate: getLocalDate(selectedTimeSegment.startDate),
                endDate: selectedTimeSegment.endDate ? getLocalDate(selectedTimeSegment.endDate) : undefined,
              }
            : undefined
        );
        const csvRows = [...docMetadata.headers, ...formatCarrierCollectionsData(rows, totals, origin)];

        saveCsv(csvRows, `OutstandingByCarrier_${docMetadata.fileNameTag}`);
      }

      downloading.off();
    }, [downloadCollectionsByCarrier, downloading, selectedTimeSegment, origin, currentUrl]),
  };
};
