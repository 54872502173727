import { ClinicalNoteFormPreviewRequest, PatientNoteVO } from "@libs/api/generated-api";
import { makeQuery, getNextPageParam, makeInfiniteQuery } from "@libs/utils/queries";
import { fiveMinuteCache, oneMinuteCache } from "@libs/utils/queryCache";

export const getPatientNotesV2 = makeInfiniteQuery({
  queryKey: ["v2", "getPatientNotesV2"],
  formatParams: ({
    practiceId,
    patientId,
    ...query
  }: {
    practiceId: number;
    patientId: number;
    types?: PatientNoteVO["type"][];
    appointmentId?: number;
    maxPageSize?: number;
    pageNumber: number;
    pageSize: number;
    tagIds?: number[];
    includeArchived?: boolean;
  }) => [practiceId, patientId, query],
  queryOptions: {
    getNextPageParam,
    ...fiveMinuteCache,
  },
});

export const getPatientNoteAuditHistory = makeInfiniteQuery({
  queryKey: ["practices", "getPatientNoteAuditHistory"],
  formatParams: ({
    practiceId,
    patientNoteUuid,
    ...query
  }: {
    practiceId: number;
    patientNoteUuid: string;
    maxPageSize?: number;
    pageNumber: number;
    pageSize: number;
  }) => [practiceId, patientNoteUuid, query],
  queryOptions: {
    getNextPageParam,
    ...fiveMinuteCache,
  },
});

export const getClinicalNoteFormPreview = makeQuery({
  queryKey: ["practices", "getClinicalNoteFormPreview"],
  formatParams: ({
    practiceId,
    formUuid,
    data,
  }: {
    practiceId: number;
    formUuid: string;
    data: ClinicalNoteFormPreviewRequest;
  }) => [practiceId, formUuid, data],
  queryOptions: fiveMinuteCache,
});

export const getClinicalNoteRenderedForms = makeQuery({
  queryKey: ["practices", "getClinicalNoteRenderedForms"],
  formatParams: ({
    practiceId,
    patientId,
    ...query
  }: {
    practiceId: number;
    patientId: number;
    noteUuid?: string;
    formUuids?: string[];
  }) => [practiceId, patientId, query],
  queryOptions: oneMinuteCache,
});

export const getPatientNote = makeQuery({
  queryKey: ["practices", "getPatientNote"],
  formatParams: ({
    practiceId,
    patientId,
    patientNoteUuid,
  }: {
    practiceId: number;
    patientId: number;
    patientNoteUuid: string;
  }) => [practiceId, patientId, patientNoteUuid],
  queryOptions: fiveMinuteCache,
});

// This is needed to know whether we even show a button
// for users to open alerts
export const getPatientNoteAlertsCount = makeQuery({
  queryKey: ["practices", "getPatientNoteAlerts"],
  formatParams: ({ practiceId, patientId }: { practiceId: number; patientId: number }) => [
    practiceId,
    patientId,
    { pageNumber: 1, pageSize: 1 },
  ],
  queryOptions: fiveMinuteCache,
});

export const getInfinitePatientNoteAlerts = makeInfiniteQuery({
  queryKey: ["practices", "getPatientNoteAlerts"],
  formatParams: ({
    practiceId,
    patientId,
    pageNumber,
    pageSize,
  }: {
    practiceId: number;
    patientId: number;
    pageNumber: number;
    pageSize: number;
  }) => [practiceId, patientId, { pageNumber, pageSize }],
  queryOptions: fiveMinuteCache,
});

export const getAppointmentClinicalNote = makeQuery({
  queryKey: ["practices", "getAppointmentClinicalNote"],
  formatParams: ({
    practiceId,
    patientId,
    appointmentId,
  }: {
    practiceId: number;
    patientId: number;
    appointmentId: number;
  }) => [practiceId, patientId, appointmentId],
  queryOptions: fiveMinuteCache,
});
