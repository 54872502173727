import { FC, useMemo, PropsWithChildren } from "react";
import { PatientNoteVO } from "@libs/api/generated-api";
import { LONG_DAY_OF_YEAR_FORMAT, formatISODate } from "@libs/utils/date";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as WarningIcon } from "@libs/assets/icons/warning.svg";

const NoteOutOfSync: FC<{
  note: PatientNoteVO;
}> = ({ note }) => {
  const outOfSyncMessage = useMemo(
    () =>
      note.providerOutOfSync
        ? note.patientProceduresOutOfSync
          ? "Provider and Patient Procedures have"
          : "Provider has"
        : note.patientProceduresOutOfSync
          ? "Patient Procedures have"
          : undefined,
    [note]
  );

  return (
    <Icon
      tooltip={{
        content: `${outOfSyncMessage} changed since the last time you updated your note.`,
      }}
      size="sm"
      SvgIcon={WarningIcon}
      theme="error"
    />
  );
};

export const NoteListItemTitle: FC<
  PropsWithChildren<{
    note: PatientNoteVO;
  }>
> = ({ note }) => {
  return (
    <div className="flex items-center gap-x-2 font-sansSemiBold text-sm">
      {note.appointment ? formatISODate(note.appointment.date, LONG_DAY_OF_YEAR_FORMAT) : note.name}
      {!note.manualOverride && (note.providerOutOfSync || note.patientProceduresOutOfSync) ? (
        <NoteOutOfSync note={note} />
      ) : null}
    </div>
  );
};
