import { FC } from "react";
import { EditorProps } from "components/UI/RichTextEditor/types";

interface Props {
  content?: EditorProps["placeholder"];
}

export const Placeholder: FC<Props> = ({ content }) => {
  return content ? (
    <p
      className={`
        absolute
        text-xs
        top-0
        left-0
        text-greyLight
        pointer-events-none
        leading-5
      `}
    >
      {content}
    </p>
  ) : null;
};
