import { NavLink } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { useScheduleLinks } from "components/ScheduleAppointments/ScheduleLinksContext";

export const ScheduleTabs: React.FC<{ className?: string }> = ({ className }) => {
  const { links } = useScheduleLinks();
  const tabs = [
    {
      exact: true,
      label: "Schedule",
      path: links.schedule,
    },
    {
      exact: true,
      label: "Huddle",
      path: links.dailyHuddle,
    },
  ];

  return (
    <div className={cx("flex gap-x-10", className)}>
      {tabs.map((tab, index) => (
        <NavLink
          className={({ isActive }) =>
            cx(
              "relative h-14 flex items-center",
              isActive &&
                `font-sansSemiBold
          text-primaryTheme
          before:bg-primaryTheme
          before:rounded
          before:h-[3px]
          before:absolute
          before:left-0
          before:right-0
          before:bottom-0`
            )
          }
          end
          key={index}
          to={tab.path}
        >
          {tab.label}
        </NavLink>
      ))}
    </div>
  );
};
