import React, { FormEventHandler, useCallback, useMemo, useState } from "react";
import { ContactDetails, EmployeePersonalDetailsVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { hasFailedPin } from "@libs/utils/hasFailedPin";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { useAccount } from "@libs/contexts/AccountContext";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Draft, produce } from "immer";
import { useValidation } from "@libs/hooks/useValidation";
import { flushSync } from "react-dom";
import { updateEmployee } from "api/employee/mutations";
import { useServerErrorHandler } from "hooks/useServerErrorHandler";
import { PersonalDetailsPanel } from "components/EmployeeProfile/PersonalDetails/PersonalDetailsPanel";
import { EmployeePinModal } from "components/Employee/EmployeePinModal";
import { PersonalDetailsForm } from "components/EmployeeProfile/PersonalDetails/PersonalDetailsForm";
import { getEmployeePersonalDetailsSchema } from "components/Employee/schema";

interface EditPersonalDetailsProps {
  employeeId: number;
  personalDetails: EmployeePersonalDetailsVO;
  contactDetails: ContactDetails;
  onDoneEditing: Func;
  isIncludedInPayroll: boolean;
  isActive: boolean;
  isOwner: boolean | undefined;
}

const FORM_ID = "employee-personal-info-form";

export const EditPersonalDetails: React.FC<EditPersonalDetailsProps> = ({
  employeeId,
  personalDetails,
  contactDetails,
  isOwner,
  isIncludedInPayroll,
  isActive,
  onDoneEditing,
}) => {
  const [draftEmployee, setDraftEmployee] = useState(() => ({ personalDetails, contactDetails }));
  const pinModal = useBoolean(false);
  const { practiceId } = useAccount();
  const [{ mutate: employeeMutation, isLoading: isEmployeeSaving }] = useApiMutations([updateEmployee]);
  const { serverErrors, clearServerErrors, handleException } = useServerErrorHandler<{
    [s: string]: string;
  }>();

  const handleChange = useCallback((updater: (draft: Draft<typeof draftEmployee>) => void) => {
    setDraftEmployee((previousDraft) => produce(previousDraft, (editable) => void updater(editable)));
  }, []);

  const schema = useMemo(
    () =>
      getEmployeePersonalDetailsSchema({
        isOwner,
        isIncludedInPayroll,
        isActive,
      }),
    [isIncludedInPayroll, isActive, isOwner]
  );

  const validation = useValidation(draftEmployee, schema);

  const submit = () => {
    clearServerErrors();

    const result = flushSync(() => validation.validate());

    if (result.$isValid) {
      employeeMutation(
        {
          employeeId,
          practiceId,
          data: draftEmployee,
        },
        {
          onSuccess: onDoneEditing,
          onError: (err) => {
            if (hasFailedPin(err)) {
              pinModal.on();
            } else {
              handleException(err);
            }
          },
        }
      );
    }
  };

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    submit();
  };

  return (
    <>
      <PersonalDetailsPanel
        actions={
          <ButtonIcon
            SvgIcon={CancelIcon}
            tooltip={{ content: "Cancel Editing", theme: "SMALL" }}
            onClick={onDoneEditing}
            theme="primary"
          />
        }
        footer={
          <div className="flex items-center justify-center gap-x-3">
            <Button className="min-w-button" theme="secondary" onClick={onDoneEditing}>
              Cancel
            </Button>
            <AsyncButton className="min-w-button" isLoading={isEmployeeSaving} type="submit" form={FORM_ID}>
              Save
            </AsyncButton>
          </div>
        }
      >
        <PersonalDetailsForm
          employee={draftEmployee}
          serverErrors={serverErrors}
          validation={validation.result}
          onSubmit={handleSubmit}
          onChange={handleChange}
          isOwner={isOwner}
          isIncludedInPayroll={isIncludedInPayroll}
          isActive={isActive}
          isEditing
        />
      </PersonalDetailsPanel>
      {pinModal.isOn && (
        <EmployeePinModal
          onPinSuccess={() => {
            pinModal.off();
            submit();
          }}
          onClose={pinModal.off}
        />
      )}
    </>
  );
};
