import { FC, Fragment, KeyboardEventHandler, useState, useRef, useEffect, useCallback } from "react";

import { TemplateVariableGroupVO, TemplateVariableVO } from "@libs/api/generated-api";
import { titleCaseConstant } from "@libs/utils/casing";
import { isOneOf } from "@libs/utils/isOneOf";
import { Menu, useMenu, MenuProps } from "@libs/components/UI/Menu";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";

export const TemplateVariablesMenu: FC<
  MenuProps & {
    templateVariables: TemplateVariableGroupVO[];
    onSelect: (variable: TemplateVariableVO["key"]) => void;
  }
> = ({ templateVariables, onSelect, ...menuProps }) => {
  const menuContentRef = useRef<HTMLDivElement>(null);
  // eslint-disable-next-line complexity
  const handleKeyDown: KeyboardEventHandler = (e) => {
    if (!menuContentRef.current || !menuProps.triggerRef.current) {
      return;
    }

    if (e.key !== "/") {
      e.preventDefault();
    }

    if (isOneOf(e.key, ["/", "Escape", "Backspace"])) {
      e.stopPropagation();
      menuProps.triggerRef.current.focus();
      menuProps.onRequestClose();
    }

    if (isOneOf(e.key, ["ArrowUp", "ArrowDown"])) {
      const menuButtons = menuContentRef.current.querySelectorAll("button");
      const currentIndex = [...menuButtons].indexOf(document.activeElement as HTMLButtonElement);

      if (currentIndex === -1) {
        menuButtons[0].focus();

        return;
      }

      const nextButton =
        e.key === "ArrowUp"
          ? // Arrow up on first button moves to last button, else next up
            menuButtons[currentIndex === 0 ? menuButtons.length - 1 : currentIndex - 1]
          : // Arrow down on last button moves to first button, else next down
            menuButtons[currentIndex === menuButtons.length - 1 ? 0 : currentIndex + 1];

      nextButton.focus();
    }

    if (e.key === "Enter") {
      (document.activeElement as HTMLButtonElement).click();
    }
  };

  return (
    <Menu
      placement="top-start"
      theme="default"
      {...menuProps}
      className="w-80 max-h-96 mt-0.5 -ml-0.5 overflow-y-auto"
    >
      {/* eslint-disable-next-line jsx-a11y/no-static-element-interactions, jsx-a11y/no-noninteractive-tabindex */}
      <div ref={menuContentRef} className="outline-none" onKeyDown={handleKeyDown} tabIndex={0}>
        {templateVariables.map(({ type, variables }, groupIndex) => (
          <Fragment key={type}>
            <p className="font-sansSemiBold text-xxs ml-3 my-2">{titleCaseConstant(type)}</p>
            {variables.map(({ key, exampleValue }, variableIndex) => (
              <MenuOptionButton
                key={key}
                className="outline-none focus:bg-slate-100 last:mb-1"
                onClick={() => onSelect(key)}
                onMouseEnter={(e) => e.currentTarget.focus()}
                autoFocus={groupIndex === 0 && variableIndex === 0}
              >
                <div className="flex flex-col">
                  <span className="text-xs">{titleCaseConstant(key)}</span>
                  <span className="text-xxs text-greyMedium">{exampleValue}</span>
                </div>
              </MenuOptionButton>
            ))}
          </Fragment>
        ))}
      </div>
    </Menu>
  );
};

export const useTemplateVariablesMenu = <T extends HTMLTextAreaElement | HTMLInputElement>({
  template,
  onUpdateTemplate,
}: {
  template?: string;
  onUpdateTemplate: (template: string) => void;
}) => {
  const [templateCursor, setTemplateCursor] = useState<number>();
  const menu = useMenu<T>();

  useEffect(() => {
    // The template cursor is set to the end position of when we insert a
    // template variable into the form field, which is then used to appropriately
    // set the selection range of the form field
    if (
      menu.triggerRef.current &&
      document.activeElement === menu.triggerRef.current &&
      templateCursor !== undefined
    ) {
      menu.triggerRef.current.setSelectionRange(templateCursor, templateCursor);
    }
  }, [menu.triggerRef, templateCursor]);

  const openMenu = menu.open;
  const handleFormFieldKeydown: KeyboardEventHandler = useCallback(
    (e) => {
      if (e.key === "/") {
        e.preventDefault();
        openMenu();
      }
    },
    [openMenu]
  );

  const handleVariableSelect = useCallback(
    (variable: TemplateVariableVO["key"]) => {
      if (menu.triggerRef.current && template != null) {
        const formField = menu.triggerRef.current;
        const prefix = template.slice(0, formField.selectionStart || undefined);
        const suffix = template.slice(formField.selectionEnd || undefined);
        const templateUpdate = `${prefix}{{${variable}}}${suffix}`;
        const templateCursorUpdate = templateUpdate.length - suffix.length;

        onUpdateTemplate(templateUpdate);
        setTemplateCursor(templateCursorUpdate);

        menu.close();
        formField.focus();
      }
    },
    [menu, template, onUpdateTemplate]
  );

  return {
    handleVariableSelect,
    handleFormFieldKeydown,
    templateMenu: menu,
  };
};
