import { FC, PropsWithChildren } from "react";
import { FormSectionConditionsVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { isDefined } from "@libs/utils/types";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Pill } from "@libs/components/UI/Pill";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as DropDownIcon } from "@libs/assets/icons/down-caret.svg";
import { DRAG_FORM_ELEMENT_HANDLE } from "components/Settings/Forms/utils";

export const FormSectionHeader: FC<
  PropsWithChildren<{
    onClick?: Func;
    onDelete?: Func;
    onToggleOpen: Func;
    isAddingElement?: boolean;
    isOpen: boolean;
    isDragging?: boolean;
    isDraggingElement: boolean;
    title: string;
    conditions?: FormSectionConditionsVO;
  }>
> = ({
  title,
  conditions,
  isOpen,
  isDragging,
  isAddingElement,
  isDraggingElement,
  onClick,
  onDelete,
  onToggleOpen,
}) => {
  const conditionsList = [
    conditions?.minAgeYears ? `Over ${conditions.minAgeYears}` : undefined,
    conditions?.maxAgeYears ? `Under ${conditions.maxAgeYears}` : undefined,
    conditions?.genders.length ? conditions.genders.map(sentenceCaseConstant).join(",") : undefined,
  ].filter(isDefined);

  return (
    <div
      className={cx(
        "items-center flex gap-x-2",
        isDraggingElement ? "invisible" : "visible",
        !isDragging && "group"
      )}
    >
      <div className="flex-none h-11 w-5 justify-center items-center flex">
        <ButtonIcon
          className={cx("transition-transform", isOpen ? "-rotate-180" : "")}
          size="sm"
          SvgIcon={DropDownIcon}
          onClick={onToggleOpen}
        />
      </div>
      <button
        type="button"
        className={cx("text-left py-3 flex-1", DRAG_FORM_ELEMENT_HANDLE)}
        onClick={onClick}
      >
        <div className="flex gap-x-2 items-center h-4">
          <h4 className="text-xs">{title}</h4>
          {conditionsList.map((condition) => (
            <Pill className="whitespace-nowrap" theme="grey" key={condition}>
              {condition}
            </Pill>
          ))}
        </div>
      </button>
      {onDelete && !isAddingElement ? (
        <div
          className={`
            flex-none
            justify-center
            items-center
            flex
            opacity-0
            group-hover:opacity-100
            group-hover:transition-opacity
            group-hover:delay-100
          `}
        >
          <ButtonIcon SvgIcon={DeleteIcon} onClick={onDelete} />
        </div>
      ) : null}
    </div>
  );
};
