import { FC, useMemo } from "react";

import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { formatNumberWithCommas } from "@libs/utils/formatNumber";
import { pluralize } from "@libs/utils/pluralize";
import { isOneOf } from "@libs/utils/isOneOf";

import { MessageCampaignFilterType } from "components/Communications/MessagePatients/types";

const TYPES_WITH_APPOINTMENT: Extract<MessageCampaignFilterType, "DAILY_HUDDLE" | "ASAP_LIST">[] = [
  "DAILY_HUDDLE",
  "ASAP_LIST",
];

interface Props {
  type: MessageCampaignFilterType;
  sendCount: number;
  isSending: boolean;
  onSend: Func;
  onCancel: Func;
}

export const SendMessageModal: FC<Props> = ({ type, sendCount, isSending, onSend, onCancel }) => {
  const recipientType = useMemo(
    () =>
      isOneOf(type, TYPES_WITH_APPOINTMENT)
        ? `Patient ${pluralize(sendCount, "Appointment", "Appointments")}`
        : pluralize(sendCount, "Patient", "Patients"),
    [type, sendCount]
  );

  return (
    <ConfirmationModal
      primaryText={`Send Message to ${formatNumberWithCommas(sendCount)} ${recipientType}`}
      secondaryText="Patients are able to unsubscribe from receiving SMS or email at any time. To avoid unsubscribes, make sure your message is relevant and timely."
      confirmText="Send"
      isConfirming={isSending}
      onConfirm={onSend}
      onCancel={onCancel}
      size="3xs"
    />
  );
};
