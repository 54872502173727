import { type fabric } from "fabric";
import { isArchyObject } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/shapeUtils";

export type ArchyFabricCanvasJSON = {
  version: string;
  objects: fabric.Object[];
  viewBox: { width: number; height: number };
};

// Object defaults
const DEFAULT_OBJECT: Partial<fabric.Object> = {
  // borderColor: "red",
  // cornerColor: "green",
  cornerSize: 10,
  originX: "center",
  originY: "center",
  transparentCorners: false,
  centeredRotation: false,
  selectable: false,
  evented: false,
  hasBorders: false,
  hasControls: false,
};

// Called on every object in the canvas
export const initializeObject = (obj: fabric.Object) => {
  // From https://github.com/nhn/tui.image-editor/blob/338d7b18b6b6a52962f042e31e46638d88683c4a/apps/image-editor/src/js/graphics.js#L1245-L1260
  // needed for rotation to work properly
  const { x: left, y: top } = obj.getCenterPoint();

  obj.set({ ...DEFAULT_OBJECT, left, top });
};

export const isSvgStored = (annotation?: string) => Boolean(annotation?.startsWith("<?xml"));

const eventsUsed = [
  "mouse:down",
  "mouse:up",
  "mouse:move",
  "selection:created",
  "object:moving",
  "object:modified",
];

export const cleanupCanvas = (canvas: fabric.Canvas) => {
  canvas.selection = false;
  canvas.isDrawingMode = false;
  for (const event of eventsUsed) {
    canvas.off(event);
  }
};

const setObjectSelectable = (obj: fabric.Object, selectable: boolean) => {
  const archyObject = isArchyObject(obj);

  obj.set({
    selectable,
    evented: selectable,
    hasControls: archyObject ? false : selectable,
    hasBorders: selectable,
  });
};

export const setSelectable = (canvasObjects: fabric.Object[], selectable: boolean) => {
  for (const obj of canvasObjects) {
    setObjectSelectable(obj, selectable);
  }
};

export const clearCanvas = (canvas: fabric.Canvas) => {
  canvas.remove(...canvas.getObjects());
  canvas.requestRenderAll();
};
