import React, { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { pluralize } from "@libs/utils/pluralize";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import Success from "assets/images/success.svg";
import { paths } from "utils/routing/paths";

interface Props {
  numOfClaims: number;
}

export const PaymentSuccessModalContent: React.FC<Props> = ({ numOfClaims }) => {
  const navigate = useNavigate();

  const handleClose = useCallback(() => {
    navigate(paths.eobs(), { replace: true });
  }, [navigate]);

  return (
    <div className="relative">
      <ButtonIcon
        SvgIcon={CloseIcon}
        theme="primary"
        className="absolute top-0 right-0"
        tooltip={{ content: "Close Payment Submitted" }}
        onClick={handleClose}
      />
      <div className="flex justify-center">
        <div
          className={`
            flex
            flex-col
            items-center
            w-[407px]
            p-5
            text-primaryTheme
            font-sansSemiBold
          `}
        >
          <img alt="Payment Processed" src={Success} />
          <div className="my-5 text-base">Payment Processed for</div>
          <div className="mt-1 text-xl">{`${numOfClaims} ${pluralize(numOfClaims, "Claim", "Claims")}`}</div>
        </div>
      </div>
    </div>
  );
};
