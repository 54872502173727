import useMeasure from "react-use-measure";
import React from "react";
import { TransformWrapper } from "react-zoom-pan-pinch";
import { DecimalPlaces, round } from "@libs/utils/math";
import { MountVO } from "@libs/api/generated-api";
import {
  FOCUSED_CANVAS_SIZE,
  CANVAS_BOUNDS,
} from "components/PatientProfile/Imaging/PatientMountsList/mountLayouts";
import {
  ImageSelectMoveProps,
  ImageDetailProps,
  ImageSharedToolbarProps,
} from "components/PatientProfile/Imaging/MountRoute/types";
import { ZoomWidget } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ZoomWidget";
import { PanZoomContent } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/PanZoomContent";
import { ImageSandboxToolbar } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/ImageSandboxToolbar";
import { UseImageEditQueue } from "components/PatientProfile/Imaging/MountRoute/hooks/useImageEditQueue";
import { UseSensorCapture } from "components/PatientProfile/Imaging/MountRoute/hooks/useSensorCapture";
import { useSandboxLayoutContext } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/SandboxLayoutContext";
import { ImageLayoutItem } from "./types";
import { DragLayer } from "./DragLayer";

type Props = {
  patientId: number;
  isShowingMetadata?: boolean;
  sandboxImages: ImageLayoutItem[];
  mount: MountVO;
  imageEditing?: ImageLayoutItem;
  handleMountChanged: UseImageEditQueue["handleMountChanged"];
  captureState: UseSensorCapture["captureState"];
} & ImageDetailProps &
  ImageSelectMoveProps &
  ImageSharedToolbarProps;

const MAX_SCALE_ZOOM = 8;

export const MIN_SCALE = round(
  Math.min(FOCUSED_CANVAS_SIZE.w / CANVAS_BOUNDS.w, FOCUSED_CANVAS_SIZE.h / CANVAS_BOUNDS.h),
  DecimalPlaces.hundredth
);

const CENTER_ANIMATION_MILLISECONDS = 500;
const WHEEL_STEP = 0.05;

export const ImageSandboxLayout: React.FC<Props> = ({
  sandboxImages,
  onEditImage,
  onImageOriginChanged,
  onImageUpdate,
  onClickTeeth,
  selectedImageIds,
  onClickBackground,
  onClickItem,
  patientId,
  devices,
  mount,
  isShowingMetadata = false,
  isOnboardedWithPearl,
  handleMountChanged,
  captureState,
}) => {
  const [ref, bounds] = useMeasure({ debounce: 50 });
  const [isLoaded, setIsLoaded] = React.useState(false);
  const { zoomToImageId, nextCaptureSpot } = useSandboxLayoutContext();

  React.useEffect(() => {
    // Takes a 300 milliseconds to center the contents.  We don't display until this is done.
    // https://prc5.github.io/react-zoom-pan-pinch/?path=/story/docs-props--page#transformwrapper-props
    setTimeout(() => {
      setIsLoaded(true);
    }, CENTER_ANIMATION_MILLISECONDS);
  });

  const minScale = 0.25;

  return (
    <>
      <ImageSandboxToolbar
        mount={mount}
        patientId={patientId}
        selectedImageIds={selectedImageIds}
        handleMountChanged={handleMountChanged}
        isOnboardedWithPearl={isOnboardedWithPearl}
      />

      <div
        ref={ref}
        className={`
          flex
          flex-col
          flex-1
          justify-center
          items-center
          relative
          h-full
          overflow-hidden
          print:-ml-[4rem]
        `}
      >
        {bounds.width > 0 && (
          <TransformWrapper
            initialScale={0.5}
            panning={{ excluded: ["button", "draggable"] }}
            wheel={{ step: WHEEL_STEP }}
            minScale={minScale}
            centerOnInit
            limitToBounds={false}
            doubleClick={{ disabled: true }}
          >
            {(props) => {
              return (
                <>
                  <div className="absolute bottom-4 right-4 z-10">
                    <ZoomWidget
                      zoomIn={props.zoomIn}
                      zoomOut={props.zoomOut}
                      show
                      minScale={minScale}
                      maxScale={MAX_SCALE_ZOOM}
                      centerView={() => props.centerView(minScale)}
                    />
                  </div>
                  <DragLayer isShowingMetadata={isShowingMetadata} />
                  <PanZoomContent
                    onEditImage={onEditImage}
                    images={sandboxImages}
                    onImageUpdate={onImageUpdate}
                    onClickTeeth={onClickTeeth}
                    zoomIn={props.zoomIn}
                    zoomOut={props.zoomOut}
                    zoomToElement={props.zoomToElement}
                    devices={devices}
                    onImageOriginChanged={onImageOriginChanged}
                    onClickItem={onClickItem}
                    selectedImageIds={selectedImageIds}
                    isLoaded={isLoaded}
                    isShowingMetadata={isShowingMetadata}
                    onClickBackground={onClickBackground}
                    captureState={captureState}
                    nextCaptureSpot={nextCaptureSpot}
                    zoomToImageId={zoomToImageId}
                  />
                </>
              );
            }}
          </TransformWrapper>
        )}
      </div>
    </>
  );
};
