import { ClaimVO } from "@libs/api/generated-api";
import { useAccount } from "@libs/contexts/AccountContext";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { useAttachments } from "components/Claim/Attachments/ClaimAttachmentsContext";

import { FormFieldTextarea } from "components/UI/FormFieldTextarea";
import { ClinicalNote } from "components/Claim/Attachments/ClinicalNote";
import { useAutoExpand } from "hooks/useAutoExpand";
import EmptyNotes from "assets/images/empty-list.svg";
import { getAppointmentClinicalNote } from "api/notes/queries";

interface Props {
  claim: ClaimVO;
}

export const NarrativesAttachments: React.FC<Props> = ({ claim }) => {
  const { practiceId } = useAccount();
  const { narrative, setNarrative } = useAttachments();

  const [patientNotesQuery] = useApiQueries([
    getAppointmentClinicalNote({
      args: {
        practiceId,
        patientId: claim.patientName.id || 0,
        appointmentId: claim.appointmentId || 0,
      },
      queryOptions: {
        enabled: Boolean(claim),
      },
    }),
  ]);

  const { elRef, styles } = useAutoExpand(narrative?.data || "");

  return (
    <div className="flex flex-col h-full">
      <div className="flex flex-row h-full w-full pr-4 pb-3 border-greyLighter">
        <div className="flex flex-col flex-[5] mr-4 h-full overflow-y-auto">
          <QueryResult queries={[patientNotesQuery]}>
            {patientNotesQuery.data ? (
              <ClinicalNote note={patientNotesQuery.data} />
            ) : (
              <div className="flex flex-col items-center justify-center h-full">
                <img alt="No Clinical Note" src={EmptyNotes} />
                <span className="text-sm mt-5">No clinical notes available</span>
              </div>
            )}
          </QueryResult>
        </div>

        <VerticalDivider />
        <div className="flex flex-col flex-[4] pt-3 pl-6 pr-4 text-greyDark">
          <div className="text-xs font-sansSemiBold pb-3">
            {narrative?.data ? "Edit Narrative" : "Add a Narrative"}
          </div>
          <FormFieldTextarea
            onChange={(event) => setNarrative(event.target.value)}
            placeholder="Add a narrative..."
            ref={elRef}
            style={styles}
            value={narrative?.data || ""}
            rows={11}
            maxLength={2000}
          />
          <div className="text-xs text-greyMedium text-right mt-2">
            {narrative?.data.length || 0}/2000 characters
          </div>
        </div>
      </div>
    </div>
  );
};
