import { toFilterComponentsProps } from "@libs/components/UI/queryFilterPillsUtils";
import { labelForEmployeeStatus } from "components/Employee/utils";
import { EmployeesQuery, NO_STATUS_FILTER } from "utils/routing/employees";

export const getEmloyeesFilterProps = (queryState: EmployeesQuery) =>
  toFilterComponentsProps(queryState, [
    {
      type: "default",
      prop: "search",
      format: (val) => `Search: ${val.trim()}`,
    },
    {
      type: "list",
      prop: "status",
      format: (val) => {
        return val === NO_STATUS_FILTER ? "" : labelForEmployeeStatus(val);
      },
    },
  ]);
