import { FC } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getEmployee } from "api/employee/queries";
import { EditPersonalDetails } from "components/EmployeeProfile/PersonalDetails/EditPersonalDetails";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { EmployeeAppHistoryProvider } from "components/Employee/EmployeeLinksContext";
import { ViewPersonalDetails } from "components/EmployeeProfile/PersonalDetails/ViewPersonalDetails";

export const EmployeeInfo: FC = () => {
  const { practiceId } = useAccount();
  const { id: employeeId } = usePathParams("employee");
  const [employeeQuery] = useApiQueries([getEmployee({ args: { employeeId, practiceId } })]);
  const { query, updateQuery } = useQueryParams("employee");

  return (
    <EmployeeAppHistoryProvider name="personalInfo">
      <QueryResult queries={[employeeQuery]}>
        {employeeQuery.data &&
          (query.edit ? (
            <EditPersonalDetails
              employeeId={employeeId}
              isOwner={employeeQuery.data.isOwner}
              personalDetails={employeeQuery.data.personalDetails}
              contactDetails={employeeQuery.data.contactDetails}
              isIncludedInPayroll={!employeeQuery.data.employmentDetails.excludeFromPayroll}
              isActive={employeeQuery.data.employmentDetails.status === "ACTIVE"}
              onDoneEditing={() => updateQuery("replaceIn", { edit: false })}
            />
          ) : (
            <ViewPersonalDetails
              employee={employeeQuery.data}
              onBeginEditing={() => updateQuery("replaceIn", { edit: true })}
            />
          ))}
      </QueryResult>
    </EmployeeAppHistoryProvider>
  );
};
