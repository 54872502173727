import { FC } from "react";
import { InfoCheckbox } from "components/Settings/Forms/InfoCheckbox";

export const RequiredCheckbox: FC<{
  disabled?: boolean;
  checked: boolean;
  onChange: Func;
  tooltip?: string;
}> = ({
  checked,
  disabled,
  onChange,
  tooltip = "This will make this field required. The patient will have to respond in order to submit the form.",
}) => {
  return (
    <InfoCheckbox
      disabled={disabled}
      label="Make field required"
      tooltip={tooltip}
      checked={checked}
      onChange={onChange}
    />
  );
};
