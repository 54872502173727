import { FC, FormEvent, useState, useCallback } from "react";
import { UpdateEmailConfigRequest } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { domain } from "@libs/utils/validators";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { Form } from "@libs/components/UI/Form";

import { FormSection } from "components/Settings/Communications/FormSection";

interface Props {
  onGenerateDomain: (domain: UpdateEmailConfigRequest["domain"]) => void;
  isGeneratingDomain: boolean;
}

export const SendingDomain: FC<Props> = ({ onGenerateDomain, isGeneratingDomain }) => {
  const [sendingDomain, setSendingDomain] = useState("");

  const { validate, result } = useValidation(
    { sendingDomain },
    {
      sendingDomain: [
        {
          $v: domain,
          $error: "Please enter a valid domain",
        },
      ],
    }
  );

  const handleGenerateDomain = useCallback(
    (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (!validate().$isValid) {
        return;
      }

      onGenerateDomain(sendingDomain);
    },
    [sendingDomain, validate, onGenerateDomain]
  );

  return (
    <Form className="block" onSubmit={handleGenerateDomain}>
      <FormSection
        title="Sending Domain"
        description="Enter the domain you would like to send emails from here."
      >
        <div className="flex gap-x-3">
          <FormFieldInput
            aria-label="Sending Domain"
            className="flex-1"
            placeholder="example.com"
            value={sendingDomain}
            onChange={(e) => setSendingDomain(e.target.value.trim())}
            error={result.sendingDomain.$error}
          />
          <AsyncButton
            className="w-32"
            size="medium"
            isLoading={isGeneratingDomain}
            disabled={!sendingDomain}
            type="submit"
          >
            Generate
          </AsyncButton>
        </div>
      </FormSection>
    </Form>
  );
};
