import React from "react";
import { cx } from "@libs/utils/cx";
import { Button } from "@libs/components/UI/Button";
import { cxDarkRoomStyles } from "components/PatientProfile/Imaging/shared/cxDarkroomStyles";

type Props = {
  onClickCalibrate: Func;
  calibrateDisabled?: boolean;
};
export const CalibrationHeader: React.FC<Props> = ({ onClickCalibrate, calibrateDisabled }) => {
  return (
    <div className={cx("justify-center bg-slate-800", cxDarkRoomStyles.header)}>
      <div className="flex items-center gap-4 text-sm text-center">
        <div>Measure object, then click Calibrate to enter the actual length</div>
        <Button theme="secondary" disabled={calibrateDisabled} onClick={onClickCalibrate}>
          Calibrate
        </Button>
      </div>
    </div>
  );
};
