import { Navigate, useNavigate } from "react-router-dom";
import { useDebouncedCallback } from "use-debounce";
import { FormEventHandler, useCallback, useEffect, useMemo, useRef } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useValidation } from "@libs/hooks/useValidation";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { Form } from "@libs/components/UI/Form";
import { DetailsModalPage } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/DetailsModalPage";
import { usePathParams } from "hooks/usePathParams";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { getBenefitCoverages, getPatientInsuranceQuery } from "api/patientInsurance/queries";
import { DeductiblesMaxAndCoverageTables } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/DedMaxAndCoverageTab/DeductiblesMaxAndCoverageTables";
import { InsurancePlanDetailsFooter } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/Footer";
import { usePlanFields } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/usePlanFields";
import { getPracticeFeeScheduleNamesQuery } from "api/feeSchedule/queries";
import {
  useInsuranceFields,
  useSubscriberFields,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/useInsuranceFields";
import {
  updateInsurancePlan,
  updatePatientInsurance,
  upsertBenefitCoveragesMutation,
} from "api/patientInsurance/mutations";
import { handleError } from "utils/handleError";
import {
  coveragesSchema,
  getCoverageUpdatePostData,
  useInsuranceAndSubscriberSchema,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/DedMaxAndCoverageTab/formData";
import { PlanNotFound } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/PlanNotFound";
import { useBenefitCoverageFields } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/useBenefitCoverageFields";
import { getPlanUpdateSubmission } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/formUtils";
import { isAutoVerified } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/SubscriberTab/utils";

export const COVERAGE_FORM_ID = "patient-insurance-coverage-form";

const UPDATE_INSURANCE_DEBOUNCE_MS = 900;
const DELAY_TO_REMOVE_PROMPT = 100;

// eslint-disable-next-line complexity, max-statements
export const DeductiblesMaxCoverageRoute: React.FC = () => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();
  const { insuranceId, patientId } = usePathParams("patientInsuranceDetails");
  const { query } = useQueryParams("patientInsuranceDetails");
  const backUrl = query.from ?? paths.patientTab({ patientId, tab: "insurance" });
  const isCreating = insuranceId === "new";
  const [patientInsuranceQuery] = useApiQueries([
    getPatientInsuranceQuery({
      args: {
        includeBenefitCoverage: true,
        includeInsurancePlan: true,
        insuranceId: isCreating ? 0 : insuranceId,
        patientId,
        practiceId,
      },
      queryOptions: { enabled: !isCreating },
    }),
  ]);
  const {
    data: patientInsuranceResponse,
    error: insuranceError,
    isFetched: insuranceFetched,
  } = patientInsuranceQuery;

  const insurancePlan = patientInsuranceResponse?.insurancePlan;
  const planUuid = insurancePlan?.uuid;
  const patientInsurance = patientInsuranceResponse?.patientInsurance;
  const subscriber = patientInsurance?.subscriber;
  const carrierId = insurancePlan?.carrier.id ?? subscriber?.carrierId;
  const [coveragesQuery, feeSchedulesQuery] = useApiQueries([
    getBenefitCoverages({
      args: { practiceId, insurancePlanUuid: insurancePlan?.uuid ?? "" },
      queryOptions: { enabled: Boolean(insurancePlan?.uuid) },
    }),
    getPracticeFeeScheduleNamesQuery({
      args: { carrierId: carrierId ?? 0, practiceId },
      queryOptions: { enabled: Boolean(carrierId) },
    }),
  ]);

  const editing = useBoolean(isCreating);

  const { handlePlanFieldChange, numOfPatients, planFields } = usePlanFields(
    patientInsuranceResponse,
    feeSchedulesQuery.data
  );
  const { handleInsuranceFieldsChange, insuranceFields } = useInsuranceFields(patientInsurance);
  const { handleCancelSubscriberFieldsChange, handleSubscriberFieldsChange, subscriberFields } =
    useSubscriberFields(subscriber);

  const insSchema = useInsuranceAndSubscriberSchema(planFields);
  const insInputData = useMemo(
    () => ({
      ...subscriberFields,
      ...insuranceFields,
    }),
    [insuranceFields, subscriberFields]
  );

  const { result: insValidation, validate: insValidate } = useValidation(insInputData, insSchema);

  const { coverageFields, handleCoverageFieldsChanged } = useBenefitCoverageFields(coveragesQuery.data);

  const coverageData = getCoverageUpdatePostData(coverageFields);
  const {
    result: covValidation,
    validate: covValidate,
    reset: covReset,
  } = useValidation(coverageData, coveragesSchema);

  useEffect(() => {
    if (covValidation.$isValid) {
      covReset();
    }
  }, [covValidation.$isValid, covReset]);

  const turnEditingOff = editing.off;

  const cancelBatchedChanges = useCallback(() => {
    if (coveragesQuery.data) {
      handleCoverageFieldsChanged([...coveragesQuery.data]);
    }

    if (patientInsurance) {
      handleCancelSubscriberFieldsChange(patientInsurance.subscriber);
      handleInsuranceFieldsChange(patientInsurance);
    }

    if (insurancePlan) {
      handlePlanFieldChange(insurancePlan);
    }

    turnEditingOff();
  }, [
    coveragesQuery.data,
    patientInsurance,
    insurancePlan,
    turnEditingOff,
    handleCoverageFieldsChanged,
    handleCancelSubscriberFieldsChange,
    handleInsuranceFieldsChange,
    handlePlanFieldChange,
  ]);

  const [updateInsurancePlanMutation, updatePatientInsuranceMutation, updateBenefitCoveragesMutation] =
    useApiMutations([updateInsurancePlan, updatePatientInsurance, upsertBenefitCoveragesMutation]);

  const submitInsuranceChanges = useCallback(async () => {
    if (!insValidate().$isValid) {
      return;
    }

    const requestData = {
      ...insuranceFields,
      ...subscriberFields,
    };

    delete requestData["subscriber"];

    if (insuranceId !== "new") {
      try {
        await updatePatientInsuranceMutation.mutateAsync({
          patientId,
          practiceId,
          insuranceId,
          data: requestData,
        });
      } catch (e) {
        handleError(e);
      }
    }
  }, [
    insValidate,
    insuranceFields,
    insuranceId,
    patientId,
    practiceId,
    subscriberFields,
    updatePatientInsuranceMutation,
  ]);

  const submitInsuranceChangesDebounced = useDebouncedCallback(
    (resolve: Func, reject: (reason: string) => void) => submitInsuranceChanges().then(resolve).catch(reject),
    UPDATE_INSURANCE_DEBOUNCE_MS,
    { trailing: true, leading: false }
  );

  const lastDebounce = useRef<{ resolve: () => void } | undefined>();
  const lastCommit = useRef<Promise<void> | undefined>();

  const commitInsuranceChanges = useCallback(async () => {
    await lastCommit.current;

    lastCommit.current = new Promise<void>((resolve, reject) => {
      // Must resolve the last debounced call, as it will now be tied to the next request
      lastDebounce.current?.resolve();
      lastDebounce.current = { resolve };

      return submitInsuranceChangesDebounced(resolve, reject);
    });

    return lastCommit.current;
  }, [submitInsuranceChangesDebounced]);

  const saving = useBoolean(false);

  const handleSubmit: FormEventHandler<HTMLFormElement> = useCallback(
    async (e) => {
      e.preventDefault();

      const params = { patientId, practiceId, insuranceId: insuranceId as number };

      if (planUuid) {
        if (!covValidate().$isValid || !insValidate().$isValid) {
          return;
        }

        saving.on();

        try {
          await Promise.all([
            updateInsurancePlanMutation.mutateAsync({
              ...params,
              data: getPlanUpdateSubmission(planFields, planFields.employerName ?? ""),
              insurancePlanUuid: planUuid,
            }),

            updateBenefitCoveragesMutation.mutateAsync({
              ...params,
              data: getCoverageUpdatePostData(coverageFields),
              insurancePlanUuid: planUuid,
            }),
          ]);

          // We need to wait for the totals to be updated in the plan before updating the insurance
          // it will fail some of the time if we don't wait for the above to complete first
          // https://grindfoundry.atlassian.net/browse/GF-3344
          await submitInsuranceChanges();
          editing.off();

          if (isCreating) {
            setTimeout(() => {
              navigate(
                paths.patientInsuranceStep(
                  { insuranceId, patientId, step: "limitations" },
                  { isAdding: true }
                )
              );
            }, DELAY_TO_REMOVE_PROMPT);
          }
        } catch (err) {
          handleError(err);
        }

        saving.off();
      }
    },
    [
      covValidate,
      coverageFields,
      editing,
      navigate,
      insValidate,
      insuranceId,
      isCreating,
      patientId,
      planFields,
      planUuid,
      practiceId,
      saving,
      submitInsuranceChanges,
      updateBenefitCoveragesMutation,
      updateInsurancePlanMutation,
    ]
  );

  const insuranceOrPlanIsAutoVerified = useMemo(() => {
    return (
      isAutoVerified(patientInsurance?.eligibilityVerifiedStatus) ||
      isAutoVerified(insurancePlan?.benefitVerifiedStatus)
    );
  }, [insurancePlan?.benefitVerifiedStatus, patientInsurance?.eligibilityVerifiedStatus]);

  return insuranceId === "new" ? (
    <Navigate replace to={paths.patientInsuranceStep({ insuranceId, patientId, step: "info" })} />
  ) : (
    <DetailsModalPage
      actions={
        editing.isOn && (
          <InsurancePlanDetailsFooter
            formId={COVERAGE_FORM_ID}
            isLoading={
              updateInsurancePlanMutation.isLoading ||
              updatePatientInsuranceMutation.isLoading ||
              updateBenefitCoveragesMutation.isLoading
            }
            onBack={cancelBatchedChanges}
            secondaryButtonLabel="Cancel"
          />
        )
      }
      backUrl={backUrl}
      insuranceId={insuranceId}
      patientId={patientId}
      patientInsuranceQuery={patientInsuranceQuery}
      queries={[coveragesQuery, patientInsuranceQuery]}
      selectedTab="coverage"
    >
      {insuranceFetched && insuranceError ? (
        <ErrorContent />
      ) : insuranceFetched && !insurancePlan ? (
        <PlanNotFound patientId={patientId} insuranceId={insuranceId} />
      ) : (
        <Form fieldLayout="labelIn" id={COVERAGE_FORM_ID} onSubmit={handleSubmit}>
          <DeductiblesMaxAndCoverageTables
            coverageFields={coverageFields}
            coveragesQuery={coveragesQuery}
            editing={editing.isOn}
            insuranceFields={insuranceFields}
            insuranceOrPlanIsAutoVerified={insuranceOrPlanIsAutoVerified}
            insurancePlanUuid={planUuid}
            numOfPatients={numOfPatients}
            onClickEdit={editing.on}
            onCoverageFieldChange={handleCoverageFieldsChanged}
            onInsuranceFieldChange={handleInsuranceFieldsChange}
            onPlanFieldChange={handlePlanFieldChange}
            onSubmitInsuranceChanges={commitInsuranceChanges}
            onSubscriberFieldChange={handleSubscriberFieldsChange}
            planFields={planFields}
            subscriberFields={subscriberFields}
            validation={insValidation}
          />
        </Form>
      )}
    </DetailsModalPage>
  );
};
