import { useState, useMemo, useCallback } from "react";
import { ClaimSummaryVO } from "@libs/api/generated-api";
import {
  canProcessAdditionalPaymentForClaim,
  isDraftClaim,
  isInDraftEobPaymentClaim,
  isSubmittedClaim,
} from "components/Claims/utils";

export interface IndeterminateCheckboxState {
  checked: boolean;
  disabled: boolean;
  indeterminate: boolean;
}

export type SelectAllType = "PENDING" | "SUBMITTED";

export type DisableClaimBulkActions = { processEob: boolean; submit: boolean };

export const useClaimsTableCheckboxes = (claims: ClaimSummaryVO[], isPreAuth: boolean) => {
  const [selectedClaimUuids, setSelectedClaimUuids] = useState<Set<string>>(new Set());
  const [selectedPendingClaimUuids, setSelectedPendingClaimUuids] = useState<Set<string>>(new Set());
  const [selectedSubmittedClaimUuids, setSelectedSubmittedClaimUuids] = useState<Set<string>>(new Set());
  const [selectedCarrierIds, setSelectedCarrierIds] = useState<Set<number>>(new Set());

  const disableBulkActions = useMemo(() => {
    return {
      processEob: selectedSubmittedClaimUuids.size === 0 || selectedCarrierIds.size !== 1,
      submit: selectedPendingClaimUuids.size === 0,
    };
  }, [selectedCarrierIds.size, selectedPendingClaimUuids.size, selectedSubmittedClaimUuids.size]);

  const indeterminateCheckboxState: IndeterminateCheckboxState = useMemo(() => {
    const hasSelectableDrafts = claims.some((claim) => isDraftClaim(claim) && !claim.validationError);

    return {
      checked: Boolean(selectedClaimUuids.size),
      disabled: isPreAuth && !hasSelectableDrafts,
      indeterminate: selectedClaimUuids.size > 0 && selectedClaimUuids.size < claims.length,
    };
  }, [claims, isPreAuth, selectedClaimUuids.size]);

  const handleSelectAllCheckboxes = useCallback(
    (selectAllType: SelectAllType) => {
      if (selectAllType === "PENDING") {
        const claimsToSelect = new Set(
          claims
            // claim.needsAttachment === null if it wasn't validated. Allow
            // attempt of bulk submit that will validate the claim on the BE
            .filter((claim) => isDraftClaim(claim) && claim.needsAttachment !== true)
            .map((claim) => claim.uuid)
        );

        setSelectedPendingClaimUuids(claimsToSelect);
        setSelectedClaimUuids(claimsToSelect);
      } else {
        const claimsToSelect = claims.filter(
          (claim) =>
            isSubmittedClaim(claim) && !claim.replacementClaimUuid && !isInDraftEobPaymentClaim(claim)
        );

        const selectedClaims = new Set(claimsToSelect.map((claim) => claim.uuid));

        setSelectedSubmittedClaimUuids(selectedClaims);
        setSelectedClaimUuids(selectedClaims);
        setSelectedCarrierIds(new Set(claimsToSelect.map((claim) => claim.insuranceCarrierId)));
      }
    },
    [claims]
  );

  const handleDeselectAllCheckboxes = useCallback(() => {
    setSelectedClaimUuids(new Set());
    setSelectedPendingClaimUuids(new Set());
    setSelectedSubmittedClaimUuids(new Set());
    setSelectedCarrierIds(new Set());
  }, [setSelectedClaimUuids]);

  const selectPendingClaim = useCallback(
    (claimUuid: string) => {
      if (selectedSubmittedClaimUuids.size > 0) {
        return;
      }

      const selectedPendingClaimUuidsCopy = new Set(selectedPendingClaimUuids);

      if (selectedPendingClaimUuidsCopy.has(claimUuid)) {
        selectedPendingClaimUuidsCopy.delete(claimUuid);
      } else {
        selectedPendingClaimUuidsCopy.add(claimUuid);
      }

      setSelectedPendingClaimUuids(selectedPendingClaimUuidsCopy);
    },
    [selectedPendingClaimUuids, selectedSubmittedClaimUuids.size]
  );

  const selectSubmittedClaim = useCallback(
    (claimUuid: string) => {
      if (selectedPendingClaimUuids.size > 0) {
        return;
      }

      const selectedSubmittedClaimUuidsCopy = new Set(selectedSubmittedClaimUuids);

      if (selectedSubmittedClaimUuidsCopy.has(claimUuid)) {
        selectedSubmittedClaimUuidsCopy.delete(claimUuid);
      } else {
        selectedSubmittedClaimUuidsCopy.add(claimUuid);
      }

      setSelectedSubmittedClaimUuids(selectedSubmittedClaimUuidsCopy);
    },
    [selectedPendingClaimUuids.size, selectedSubmittedClaimUuids]
  );

  const handleCheckboxSelect = useCallback(
    (claim: ClaimSummaryVO) => {
      const isDraft = isDraftClaim(claim);
      const isSubmitted = isSubmittedClaim(claim);
      const canProcessAdditionalPayment = canProcessAdditionalPaymentForClaim(claim);

      if (isDraft) {
        selectPendingClaim(claim.uuid);
      } else if (isSubmitted || canProcessAdditionalPayment) {
        selectSubmittedClaim(claim.uuid);
      } else {
        // No bulk actions for other claims
        return;
      }

      const selectedClaimUuidsCopy = new Set(selectedClaimUuids);
      const selectedCarrierIdsCopy = new Set(selectedCarrierIds);

      if (selectedClaimUuidsCopy.has(claim.uuid)) {
        selectedClaimUuidsCopy.delete(claim.uuid);

        setSelectedCarrierIds(
          selectedClaimUuidsCopy.size
            ? new Set(
                claims
                  .filter((allClaims) => selectedClaimUuidsCopy.has(allClaims.uuid))
                  .map((allClaims) => allClaims.insuranceCarrierId)
              )
            : new Set()
        );
      } else {
        selectedClaimUuidsCopy.add(claim.uuid);

        setSelectedCarrierIds(selectedCarrierIdsCopy.add(claim.insuranceCarrierId));
      }

      setSelectedClaimUuids(selectedClaimUuidsCopy);
    },
    [claims, selectPendingClaim, selectSubmittedClaim, selectedCarrierIds, selectedClaimUuids]
  );

  return {
    disableBulkActions,
    handleCheckboxSelect,
    handleSelectAllCheckboxes,
    handleDeselectAllCheckboxes,
    indeterminateCheckboxState,
    pendingClaimsSelected: Boolean(selectedPendingClaimUuids.size),
    selectedCarrierIds,
    selectedClaimUuids,
  };
};
