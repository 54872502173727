import { useMemo, FC, PropsWithChildren, useEffect } from "react";
import { AccountContext } from "@libs/contexts/AccountContext";
import { setSentryUserProperties } from "utils/sentry";

export const AccountProvider: FC<
  PropsWithChildren<{
    email: string;
    userId: number;
    practiceId: number;
    practiceUuid: string;
  }>
> = ({ practiceId, userId, email, practiceUuid, children }) => {
  const account = useMemo(() => {
    return {
      practiceId,
      practiceUuid,
      email,
      id: userId,
    };
  }, [practiceId, userId, email, practiceUuid]);

  useEffect(() => {
    setSentryUserProperties(account);
  }, [account]);

  return <AccountContext.Provider value={account}>{children}</AccountContext.Provider>;
};
