import React from "react";
import { SingleValue } from "react-select";
import { PersonalDetailsVO } from "@libs/api/generated-api";
import { FormFieldProps } from "@libs/components/UI/FormField";
import { FormFieldSelect } from "components/UI/FormFieldSelect";

export const FormFieldSelectPronoun: React.FC<
  FormFieldProps & {
    value: PersonalDetailsVO["preferredPronouns"];
    placeholder?: string;
    menuPlacement?: "auto" | "top" | "bottom";
    onChange: (
      option: SingleValue<{
        label: string;
        value: NonNullable<PersonalDetailsVO["preferredPronouns"]>;
      }>
    ) => void;
  }
> = ({ layout, label, value, edit = true, onChange, menuPlacement = "auto", placeholder, error }) => {
  return (
    <FormFieldSelect
      layout={layout}
      label={label}
      display="label"
      edit={edit}
      value={value}
      error={error}
      menuPlacement={menuPlacement}
      placeholder={placeholder}
      options={[
        { label: "He/Him", value: "HE_HIM" },
        { label: "She/Her", value: "SHE_HER" },
        { label: "They/Them", value: "THEY_THEM" },
      ]}
      onChange={onChange}
    />
  );
};
