import { useStorageContext } from "@libs/contexts/StorageContext";
import { wrapStorage } from "@libs/storage/wrapStorage";
import { useMemo } from "react";
import { StorageNamespaces } from "storage/namespaces";

const version = "v1";

let lastAlertedPatientId: undefined | number;

export const trackLastAlertedPatient = (patientId: number) => (lastAlertedPatientId = patientId);

export const getLastAlertedPatient = () => lastAlertedPatientId;

type PatientAlertSnoozeParams = {
  userId: number;
  patientId: number;
  duration: number;
};

const getPatientAlertSnoozeKey = ({
  userId,
  patientId,
}: Pick<PatientAlertSnoozeParams, "userId" | "patientId">) => `${userId}.${patientId}`;

export const getPatientAlertSnoozeStorage = (storage: Storage) => {
  const patientAlertSnoozeStorage = wrapStorage<true, StorageNamespaces>(
    StorageNamespaces.patientAlertSnooze,
    storage
  );

  const snoozePatientAlerts = (params: PatientAlertSnoozeParams) =>
    patientAlertSnoozeStorage.set(getPatientAlertSnoozeKey(params), true, {
      expires: params.duration,
      version,
    });

  const arePatientAlertsSnoozed = (params: Pick<PatientAlertSnoozeParams, "userId" | "patientId">) =>
    patientAlertSnoozeStorage.get(getPatientAlertSnoozeKey(params), version);

  return {
    snoozePatientAlerts,
    arePatientAlertsSnoozed,
  };
};

export const usePatientAlertSnoozeStorage = () => {
  const { localStorage } = useStorageContext();

  return useMemo(() => getPatientAlertSnoozeStorage(localStorage), [localStorage]);
};
