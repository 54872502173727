import React, { FormEvent, useCallback, useMemo } from "react";
import { Auth } from "aws-amplify";
import { CognitoUser } from "amazon-cognito-identity-js";
import { useValidation } from "@libs/hooks/useValidation";
import { useObjectState } from "@libs/hooks/useObjectState";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { useNavigate } from "react-router-dom";
import { SignInErrorMessages } from "@libs/router/signOutReasons";
import { Banner } from "@libs/components/UI/Banner";
import { SignInForm } from "components/SignIn/SignInForm";
import { PageWrapper, PageWrapperCard } from "components/SignIn/PageWrapper";
import { ContentFooter } from "components/SignIn/ContentFooter";
import { AccountsList } from "components/SignIn/AccountsList";
import { getEmailValidationSchema, getPasswordValidationSchema } from "components/SignIn/validationUtil";
import { useQueryParams } from "hooks/useQueryParams";
import { StoredAccountV1, useAccountsStorage } from "storage/accounts";
import { useHandleAuthError } from "components/SignIn/hooks/useHandleAuthError";
import { paths } from "utils/routing/paths";
import { usePracticeActivityStorage } from "storage/activity";
import { postAuthChannelMessage } from "hooks/useAuthChannelListeners";
import { MailToLink } from "components/UI/MailToLink";
import { useSignedOutAuthChannelListeners } from "components/Main/useSignedOutAuthChannelListeners";
import { usePendo } from "components/Main/usePendo";
import { AuthChallenge } from "components/SignIn/AuthChallenges/AuthChallenge";
import { SupportedChallenge, isSupportedChallenge } from "components/SignIn/AuthChallenges/utils";

export enum StepType {
  CHOOSE_ACCOUNT = "chooseAccount",
  SIGN_IN_FORM = "signInForm",
}

export interface SignInProps {
  email: string;
  errorMessage?: string;
  isLoading: boolean;
  password: string;
}

export interface MainSignInProps extends SignInProps {
  rememberMe: boolean;
  savedAccounts: StoredAccountV1[];
  step: StepType;
  bannerErrorMessage: string;
  // Presented when MFA is required:
  userChallenge?: {
    user: CognitoUser;
    challengeName: SupportedChallenge;
  };
}

export type SignInInputType = "email" | "password";

export const MainSignIn: React.FC = () => {
  const navigate = useNavigate();
  const accountsStorage = useAccountsStorage();
  const activityStorage = usePracticeActivityStorage();
  const { query } = useQueryParams("signIn");

  usePendo({ isSignedIn: false });
  useSignedOutAuthChannelListeners(query);

  usePageTitle("Sign In");

  const [signInState, updateSignInState] = useObjectState<MainSignInProps>(() => {
    const savedAccounts = accountsStorage.getSavedAccounts();

    return {
      email: "",
      bannerErrorMessage: query.signOutReason ? SignInErrorMessages[query.signOutReason] : "",
      errorMessage: "",
      isLoading: false,
      password: "",
      rememberMe: false,
      savedAccounts: accountsStorage.getSavedAccounts(),
      step: savedAccounts.length ? StepType.CHOOSE_ACCOUNT : StepType.SIGN_IN_FORM,
    };
  });

  const removeUserFromStorage = useCallback(
    (temp: Partial<StoredAccountV1>, showAccountListStep: boolean) => {
      if (temp.email) {
        const accounts = accountsStorage.removeAccount(temp.email);

        updateSignInState({
          savedAccounts: accounts,
          step: accounts.length > 0 && showAccountListStep ? StepType.CHOOSE_ACCOUNT : StepType.SIGN_IN_FORM,
        });
      }
    },
    [updateSignInState, accountsStorage]
  );

  const signInSchema = useMemo(() => {
    return {
      email: getEmailValidationSchema(signInState.email),
      password: getPasswordValidationSchema({ password: signInState.password }),
    };
  }, [signInState.email, signInState.password]);

  const signInForm = useValidation(
    { email: signInState.email, password: signInState.password },
    signInSchema
  );

  const handleInputChange = useCallback(
    (inputName: SignInInputType, value: string) => {
      signInForm.reset();
      updateSignInState({ errorMessage: "", [inputName]: value, bannerErrorMessage: "" });
    },
    [updateSignInState, signInForm]
  );

  const handleCompleteSignIn = useCallback(() => {
    activityStorage.setRecentlyActive();
    postAuthChannelMessage({
      type: "signIn",
      rememberMe: signInState.rememberMe,
    });
    navigate(
      paths.mfaSetup({
        returnUrl: query.returnUrl,
        lastUserId: query.lastUserId,
        rememberMe: signInState.rememberMe,
      })
    );
  }, [navigate, activityStorage, query.returnUrl, query.lastUserId, signInState.rememberMe]);

  const handleAuthError = useHandleAuthError(updateSignInState);
  const handleSignIn = useCallback(
    // eslint-disable-next-line complexity, max-statements
    async (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      const validate = signInForm.validate();

      if (!validate.$isValid) {
        return;
      }

      updateSignInState({ isLoading: true });

      if (signInState.email && signInState.password) {
        try {
          const authUser = (await Auth.signIn(signInState.email, signInState.password)) as CognitoUser;

          if (authUser.challengeName) {
            if (isSupportedChallenge(authUser.challengeName)) {
              updateSignInState({
                isLoading: false,
                userChallenge: {
                  user: authUser,
                  challengeName: authUser.challengeName,
                },
              });

              return;
            }

            throw new Error("Unsupported Auth Challenge");
          }

          handleCompleteSignIn();
        } catch (error) {
          handleAuthError(error);
          updateSignInState({ isLoading: false });

          return;
        }
      } else {
        updateSignInState({ errorMessage: SignInErrorMessages.INVALID_CREDENTIALS, isLoading: false });
      }
    },
    [
      signInForm,
      handleCompleteSignIn,
      updateSignInState,
      signInState.email,
      signInState.password,
      handleAuthError,
    ]
  );

  return signInState.userChallenge ? (
    <AuthChallenge
      challengeName={signInState.userChallenge.challengeName}
      authUser={signInState.userChallenge.user}
      onChallengeComplete={(result) => {
        if ("error" in result) {
          updateSignInState({
            isLoading: false,
            userChallenge: undefined,
            errorMessage: "Session expired. Please sign in again.",
            password: "",
          });
        } else if ("signedInUser" in result) {
          handleCompleteSignIn();
        }
      }}
    />
  ) : (
    <PageWrapper>
      <PageWrapperCard title="Sign In">
        {signInState.bannerErrorMessage ? (
          <Banner className="text-sm mb-2 rounded" theme="error">
            {signInState.bannerErrorMessage}
          </Banner>
        ) : null}

        {signInState.step === StepType.CHOOSE_ACCOUNT ? (
          <AccountsList
            removeUserFromStorage={removeUserFromStorage}
            savedAccounts={signInState.savedAccounts}
            updateSignInState={updateSignInState}
          />
        ) : (
          <SignInForm
            handleInputChange={handleInputChange}
            handleSignIn={handleSignIn}
            signInState={signInState}
            updateSignInState={updateSignInState}
            validateResult={signInForm.result}
            query={query}
          />
        )}
        <ContentFooter>
          <span>Want to sign up with Archy or book a demo? Get in touch at&nbsp;</span>
          <MailToLink email="hello@archy.com" />
        </ContentFooter>
      </PageWrapperCard>
    </PageWrapper>
  );
};
