import { useMemo, useState, FC, useCallback, FormEvent, PropsWithChildren } from "react";
import { DentalProcedureVO, PatientProcedureVO } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { pluralize } from "@libs/utils/pluralize";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { Button } from "@libs/components/UI/Button";
import { useAccount } from "@libs/contexts/AccountContext";
import { ScrollableInfiniteQuery } from "@libs/components/UI/ScrollableInfiniteQuery";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { Modal } from "@libs/components/UI/Modal";
import { ModalFooter, ModalContent, ModalForm } from "@libs/components/UI/ModalComponents";
import { ProcedureSelector } from "components/ProcedureSelector/ProcedureSelector";
import { FormSection } from "components/UI/FormSection";
import {
  getPatientTreatmentPlansQuery,
  getTreatmentPlanQuery,
  getInfinitePatientProceduresQuery,
  getDentalProceduresQuery,
} from "api/charting/queries";
import {
  AddPatientProceduresHeaderRow,
  AddPatientProceduresTable,
} from "components/ScheduleAppointments/AddPatientProceduresTable";
import { toggleMap } from "components/ScheduleAppointments/utils";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { ValidAddPatientProcedureStatuses } from "components/ScheduleAppointments/useAppointmentCategoriesAndProcedures";

interface Props {
  patientId: number;
  onRequestClose: Func;
  onAddProcedures: (dentalprocedures: DentalProcedureVO[], patientProcedures: PatientProcedureVO[]) => void;
}

const EmptyResults: FC<PropsWithChildren> = ({ children }) => (
  <div className="h-full flex justify-center items-center text-sm">{children}</div>
);

const MASTER_TREATMENT_PLAN = "tp";

export const AddNewProcedureModal: FC<Props> = ({ onRequestClose, onAddProcedures, patientId }) => {
  const { practiceId } = useAccount();
  const [selectedTP, setSelectedTP] = useState<string>("");
  const [selectedDentalProcedures, setSelectedDentalProcedures] = useState<DentalProcedureVO[]>([]);
  const [localSelectedPatientProcedures, setLocalSelectedPatientProcedures] = useState<
    Record<number, PatientProcedureVO | undefined>
  >({});

  const localSelectedPatientProceduresCount = useMemo(
    () => Object.keys(localSelectedPatientProcedures).length,
    [localSelectedPatientProcedures]
  );

  const [dentalProceduresQuery, treatmentPlansQuery] = useApiQueries([
    getDentalProceduresQuery({ args: { practiceId } }),
    getPatientTreatmentPlansQuery({
      args: {
        practiceId,
        patientId,
        includeStates: ["ACTIVE", "INACTIVE"],
      },
    }),
  ]);

  const resolvedTP = useMemo(() => {
    // nothing can be determined until treatment plans have been loaded
    if (!treatmentPlansQuery.data) {
      return "";
    }

    // If the user has selected a tp use that
    if (selectedTP) {
      return selectedTP;
    }

    // If the user has not selected anything use the active TP if it exists.
    const activeTP = treatmentPlansQuery.data.find((tp) => tp.state === "ACTIVE");

    // otherwise use the master TP
    return activeTP?.uuid || MASTER_TREATMENT_PLAN;
  }, [treatmentPlansQuery.data, selectedTP]);

  const [treatmentPlanQuery] = useApiQueries([
    getTreatmentPlanQuery({
      args: {
        practiceId,
        treatmentPlanUuid: resolvedTP,
      },
      queryOptions: {
        enabled: Boolean(resolvedTP) && resolvedTP !== MASTER_TREATMENT_PLAN,
      },
    }),
  ]);

  const masterTPProceduresQuery = useInfiniteApiQuery(
    getInfinitePatientProceduresQuery({
      args: {
        practiceId,
        patientId,
        pageSize: PAGE_SIZE,
        pageNumber: 1,
        includeStatuses: ValidAddPatientProcedureStatuses,
      },
    })
  );

  const handleToggleRow = useCallback((p: PatientProcedureVO) => {
    setLocalSelectedPatientProcedures((last) => {
      return toggleMap(last, p.id, p);
    });
  }, []);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      onAddProcedures(
        selectedDentalProcedures,
        Object.values(localSelectedPatientProcedures).filter(isDefined)
      );
    },
    [onAddProcedures, selectedDentalProcedures, localSelectedPatientProcedures]
  );

  const treatmentPlanProcedures = useMemo(() => {
    return (
      treatmentPlanQuery.data?.patientProcedures.filter((pp) =>
        ValidAddPatientProcedureStatuses.includes(pp.status)
      ) || []
    );
  }, [treatmentPlanQuery.data]);

  const options = useMemo(() => {
    if (!treatmentPlansQuery.data) {
      return [];
    }

    return [
      { value: MASTER_TREATMENT_PLAN, label: "Master TP" },
      ...treatmentPlansQuery.data.map((tp) => ({ value: tp.uuid, label: tp.name })),
    ];
  }, [treatmentPlansQuery.data]);

  const handleProcedureSelectorChange = useCallback((newDentalProcedures: DentalProcedureVO[]) => {
    setSelectedDentalProcedures(newDentalProcedures);
  }, []);

  return (
    <Modal title="Add Procedures" height={900} size="lg" onClose={onRequestClose}>
      <ModalForm onSubmit={handleSubmit}>
        <ModalContent className="flex flex-col">
          <QueryResult
            queries={[dentalProceduresQuery, treatmentPlansQuery, masterTPProceduresQuery]}
            loading={
              <div className="flex-1 px-8">
                <LoadingContent />
              </div>
            }
          >
            <FormSection className="flex-none" title="Select New Procedures">
              <ProcedureSelector
                required={true}
                label="Search and select CDT Code - procedure"
                placeholder="Please choose one or more procedures"
                values={selectedDentalProcedures}
                procedures={dentalProceduresQuery.data}
                onChange={handleProcedureSelectorChange}
              />
            </FormSection>
            <FormSection
              className="flex-1 min-h-0 flex flex-col"
              title="Select Procedures from Treatment Plan"
            >
              <>
                {treatmentPlansQuery.data && resolvedTP ? (
                  <div className="pb-3 flex-none flex justify-between items-end">
                    <FormFieldSelect
                      name="selectTreatmentPlan"
                      required={true}
                      options={options}
                      value={resolvedTP}
                      isClearable={false}
                      isSearchable={false}
                      onItemSelected={setSelectedTP}
                      label="Select Treatment Plan"
                      className="w-1/2"
                    />
                    {localSelectedPatientProceduresCount ? (
                      <div
                        className={`
                          text-xs
                          py-1
                          px-4
                          rounded-xl
                          bg-actionLight
                          text-primaryTheme
                        `}
                      >
                        {pluralize(
                          localSelectedPatientProceduresCount,
                          `${localSelectedPatientProceduresCount} procedure selected`,
                          `${localSelectedPatientProceduresCount} procedures selected`
                        )}
                      </div>
                    ) : null}
                  </div>
                ) : null}
                <div className="flex-1 min-h-0 flex flex-col">
                  <AddPatientProceduresHeaderRow />
                  <div className="border border-greyLighter rounded flex-1 min-h-0">
                    {resolvedTP === MASTER_TREATMENT_PLAN ? (
                      <ScrollableInfiniteQuery
                        persistScroll={false}
                        id="add-master-tp-procedures-scroll"
                        infiniteQuery={masterTPProceduresQuery}
                        rootMargin="0px 0px 400px 0px"
                        noResults={
                          <EmptyResults> The patient has no planned or referred procedures.</EmptyResults>
                        }
                      >
                        {(masterTPProcedures) => (
                          <AddPatientProceduresTable
                            patientProcedures={masterTPProcedures}
                            selectedProcedures={localSelectedPatientProcedures}
                            onToggleRow={handleToggleRow}
                          />
                        )}
                      </ScrollableInfiniteQuery>
                    ) : resolvedTP ? (
                      <QueryResult queries={[treatmentPlanQuery]}>
                        {treatmentPlanQuery.data ? (
                          treatmentPlanProcedures.length ? (
                            <div className="h-full overflow-y-auto">
                              <AddPatientProceduresTable
                                patientProcedures={treatmentPlanProcedures}
                                selectedProcedures={localSelectedPatientProcedures}
                                onToggleRow={handleToggleRow}
                              />
                            </div>
                          ) : (
                            <EmptyResults>
                              The patient has no planned or referred procedures for this treatment plan.
                            </EmptyResults>
                          )
                        ) : null}
                      </QueryResult>
                    ) : null}
                  </div>
                </div>
              </>
            </FormSection>
          </QueryResult>
        </ModalContent>
        <ModalFooter>
          <Button type="submit">Add</Button>
        </ModalFooter>
      </ModalForm>
    </Modal>
  );
};
