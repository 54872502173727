import { FC, useMemo, useCallback } from "react";

import { DocumentVO } from "@libs/api/generated-api";
import { Icon } from "@libs/components/UI/Icon";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { useBoolean } from "@libs/hooks/useBoolean";

import { ReactComponent as DownloadIcon } from "@libs/assets/icons/download.svg";
import { ReactComponent as PrintIcon } from "@libs/assets/icons/send-to-printer.svg";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as ZoomInIcon } from "@libs/assets/icons/zoom-in.svg";
import { ReactComponent as ZoomOutIcon } from "@libs/assets/icons/zoom-out.svg";
import { ReactComponent as ZoomFitIcon } from "@libs/assets/icons/zoom-to-extents.svg";
import { ReactComponent as BackIcon } from "@libs/assets/icons/left-arrow.svg";
import { ReactComponent as ForwardIcon } from "@libs/assets/icons/right-arrow.svg";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";

interface Props {
  document: DocumentVO;
  onZoomIn?: Func;
  onZoomOut?: Func;
  onZoomFit?: Func;
  printUrl?: string;
  downloadUrl?: string;
  onPreviousPage?: () => void;
  onNextPage?: () => void;
  currentPage?: number;
  totalPages?: number;
  onEdit: (document: DocumentVO) => void;
  onDelete: (document: DocumentVO) => void;
  zoomScale?: number;
  showDownload?: boolean;
  showPrint?: boolean;
}

export const PreviewToolbar: FC<Props> = ({
  document,
  printUrl,
  downloadUrl,
  onZoomIn,
  onZoomOut,
  onZoomFit,
  onEdit,
  onDelete,
  onPreviousPage,
  onNextPage,
  currentPage,
  totalPages,
  zoomScale = 1,
  showDownload = true,
  showPrint = true,
}) => {
  const menu = useBoolean(false);
  const menuOptions = useMemo(
    () =>
      createMenuOptions(
        {
          value: "edit",
          label: "Edit",
          SvgIcon: EditIcon,
        },
        {
          value: "delete",
          label: "Delete",
          SvgIcon: DeleteIcon,
        }
      ),
    []
  );

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "edit": {
          onEdit(document);
          break;
        }
        case "delete": {
          onDelete(document);
          break;
        }
        default: {
          break;
        }
      }

      menu.off();
    },
    [document, onEdit, onDelete, menu]
  );

  return (
    <div className="flex items-center justify-between h-11 px-6">
      <span className="font-sansSemiBold">{document.name}</span>
      <div className="flex items-center text-primaryTheme gap-x-8">
        {onPreviousPage && onNextPage && (
          <PaginationWidget
            currentPage={currentPage}
            totalPages={totalPages}
            onPreviousPage={onPreviousPage}
            onNextPage={onNextPage}
          />
        )}
        {onZoomIn && onZoomOut && onZoomFit && (
          <ZoomWidget zoomScale={zoomScale} onZoomIn={onZoomIn} onZoomOut={onZoomOut} onZoomFit={onZoomFit} />
        )}
        <div className="flex items-center gap-x-3">
          {showDownload && (
            <FloatingTooltip content="Download" theme="SMALL">
              <a href={downloadUrl} download={document.name}>
                <Icon SvgIcon={DownloadIcon} />
              </a>
            </FloatingTooltip>
          )}
          {showPrint && (
            <FloatingTooltip content="Print" theme="SMALL">
              <a href={printUrl} target="_blank">
                <Icon SvgIcon={PrintIcon} />
              </a>
            </FloatingTooltip>
          )}

          <ButtonMenu
            menuContent={
              <div className="w-36">
                <MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />
              </div>
            }
            onRequestClose={menu.off}
            onRequestOpen={menu.on}
            isOpen={menu.isOn}
            placement="bottom-end"
          >
            {(props) => (
              <ButtonIcon
                SvgIcon={MenuIcon}
                tooltip={{ content: "Document Menu", theme: "SMALL" }}
                {...props}
              />
            )}
          </ButtonMenu>
        </div>
      </div>
    </div>
  );
};

const ZoomWidget: FC<{ zoomScale: number; onZoomIn: Func; onZoomOut: Func; onZoomFit: Func }> = ({
  zoomScale,
  onZoomIn,
  onZoomOut,
  onZoomFit,
}) => {
  return (
    <div className="flex items-center gap-x-2">
      <div className="flex items-center">
        <ButtonIcon
          tooltip={{ content: "Zoom In", theme: "SMALL" }}
          SvgIcon={ZoomInIcon}
          theme="primary"
          size="md"
          onClick={onZoomIn}
          className="mr-4"
        />

        <ButtonIcon
          tooltip={{ content: "Zoom Out", theme: "SMALL" }}
          SvgIcon={ZoomOutIcon}
          theme="primary"
          size="md"
          onClick={onZoomOut}
          className="mr-2"
        />
      </div>
      <VerticalDivider size="sm" />
      <div className="flex items-center">
        <ButtonIcon
          tooltip={{ content: "Fit Image", theme: "SMALL" }}
          SvgIcon={ZoomFitIcon}
          theme="primary"
          size="md"
          onClick={onZoomFit}
          className="mx-2"
        />

        {/* eslint-disable-next-line @typescript-eslint/no-magic-numbers */}
        <div className="w-11 ml-1">{Math.floor(zoomScale * 100)}%</div>
      </div>
    </div>
  );
};

const PaginationWidget: FC<{
  currentPage?: number;
  onPreviousPage: Func;
  totalPages?: number;
  onNextPage: Func;
}> = ({ currentPage, totalPages, onPreviousPage, onNextPage }) => {
  return (
    <div className="flex items-center gap-x-2">
      <div className="flex items-center">
        <FloatingTooltip content="Previous Page" theme="SMALL">
          <ButtonIcon disabled={currentPage === 1} SvgIcon={BackIcon} onClick={onPreviousPage} />
        </FloatingTooltip>
        <div className="whitespace-nowrap">
          {currentPage} / {totalPages}
        </div>
        <FloatingTooltip content="Next Page" theme="SMALL">
          <ButtonIcon disabled={currentPage === totalPages} SvgIcon={ForwardIcon} onClick={onNextPage} />
        </FloatingTooltip>
      </div>
      <VerticalDivider size="sm" />
    </div>
  );
};
