import { FC, useCallback, useMemo } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { PatientVO, PaymentVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getFullUrl } from "@libs/utils/location";
import { isDefined } from "@libs/utils/types";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { MenuOptions, createMenuOption } from "@libs/components/UI/MenuOptions";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { paths } from "utils/routing/paths";
import { handleError } from "utils/handleError";
import { deleteMultiInvoicePayment } from "api/billing/mutations";
import { useCurrentUser } from "contexts/CurrentUserContext";
import { checkPermission } from "components/Roles/roleUtils";
import { isPaymentEditableChecker } from "components/PatientProfile/Billing/billingUtils";
import { getPayment } from "api/billing/queries";

export const PaymentMenu: FC<{
  patientId: PatientVO["id"];
  paymentUuid: PaymentVO["uuid"];
  isFamilyPayment: boolean;
  canEditPayment: boolean;
  canDeletePayment: boolean;
}> = ({ patientId, paymentUuid, isFamilyPayment, canEditPayment, canDeletePayment }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const { practiceId } = useAccount();
  const [paymentQuery] = useApiQueries([getPayment({ args: { practiceId, paymentUuid } })]);
  const deletePaymentConfirmation = useBoolean(false);
  const { roleV2 } = useCurrentUser();
  const [deletePayment] = useApiMutations([deleteMultiInvoicePayment]);

  const handleViewPayment = useCallback(() => {
    navigate(paths.viewPayment({ patientId, paymentUuid }, { from: getFullUrl(location) }));
  }, [navigate, location, patientId, paymentUuid]);

  const handleEditPayment = useCallback(() => {
    navigate(paths.editPayment({ patientId, paymentUuid }, { from: getFullUrl(location) }));
  }, [navigate, location, patientId, paymentUuid]);

  const handlePaymentDelete = () => {
    deletePayment.mutate(
      { patientId, practiceId, paymentUuid },
      { onError: handleError, onSettled: deletePaymentConfirmation.off }
    );
  };

  const editPermissionResults = useMemo(
    () =>
      checkPermission(roleV2, "BILLING", "EDIT_PAYMENT", (conditions) =>
        isPaymentEditableChecker(paymentQuery.data, conditions)
      ),

    [paymentQuery.data, roleV2]
  );

  const deletePermissionResults = useMemo(
    () =>
      checkPermission(roleV2, "BILLING", "DELETE_PAYMENT", (conditions) =>
        isPaymentEditableChecker(paymentQuery.data, conditions)
      ),
    [paymentQuery.data, roleV2]
  );

  return (
    <>
      <PaymentMenuDropdown
        onViewPayment={handleViewPayment}
        onEditPayment={canEditPayment && editPermissionResults.isPermitted ? handleEditPayment : undefined}
        onDeletePayment={
          canDeletePayment && deletePermissionResults.isPermitted ? deletePaymentConfirmation.on : undefined
        }
      />
      {deletePaymentConfirmation.isOn && (
        <ConfirmationModal
          centerVertically
          onCancel={deletePaymentConfirmation.off}
          isConfirming={deletePayment.isLoading}
          primaryText="Are you sure you want to delete this payment?"
          secondaryText={
            isFamilyPayment
              ? "Deleting this payment may impact invoices of different family members."
              : "Deleting this payment will adjust the invoice's amount due."
          }
          onConfirm={handlePaymentDelete}
        />
      )}
    </>
  );
};

const PaymentMenuDropdown: FC<{
  onViewPayment: Func;
  onEditPayment: Func | undefined;
  onDeletePayment: Func | undefined;
}> = ({ onViewPayment, onEditPayment, onDeletePayment }) => {
  const menu = useBoolean(false);

  const menuOptions = useMemo(() => {
    return [
      createMenuOption({ value: "view", label: "View Receipt" }),
      onEditPayment
        ? createMenuOption({
            value: "edit",
            label: "Edit Payment",
          })
        : null,
      onDeletePayment
        ? createMenuOption({
            value: "delete",
            label: "Delete Payment",
          })
        : null,
    ].filter(isDefined);
  }, [onDeletePayment, onEditPayment]);

  const handleOptionClick = useCallback(
    (option: ListItem<typeof menuOptions>) => {
      switch (option.value) {
        case "view": {
          onViewPayment();
          break;
        }
        case "edit": {
          onEditPayment?.();
          break;
        }
        case "delete": {
          onDeletePayment?.();
          break;
        }

        // no default
      }

      menu.off();
    },
    [menu, onDeletePayment, onEditPayment, onViewPayment]
  );

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      placement="bottom-start"
      menuContent={<MenuOptions options={menuOptions} onOptionClick={handleOptionClick} />}
      onRequestClose={menu.off}
    >
      {(props) => <ButtonIcon {...props} size="md" SvgIcon={MenuIcon} />}
    </ButtonMenu>
  );
};
