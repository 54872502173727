import { FC, useMemo } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";
import { cx } from "@libs/utils/cx";
import {
  allSupportedUploadMimeTypes,
  expandMimeTypes,
  mimeTypesToExtensions,
  AllSupportedUploadMimes,
} from "@libs/utils/mimeTypes";
import { formatBytes } from "@libs/utils/formatString";
import { ReactComponent as UploadIcon } from "@libs/assets/icons/upload.svg";

interface Props {
  onFilesDropped: DropzoneOptions["onDrop"];
  fileMaxSize?: DropzoneOptions["maxSize"];
  acceptedMimeTypes?: AllSupportedUploadMimes[];
}

const DEFAULT_FILE_SIZE_LIMIT = 10_000_000;

export const DropFileZone: FC<Props> = ({
  onFilesDropped: onFileDropped,
  fileMaxSize = DEFAULT_FILE_SIZE_LIMIT,
  acceptedMimeTypes = allSupportedUploadMimeTypes(),
}) => {
  const expandedMimeTypes = useMemo(() => expandMimeTypes(acceptedMimeTypes), [acceptedMimeTypes]);
  const acceptedExtensions = useMemo(() => mimeTypesToExtensions(acceptedMimeTypes), [acceptedMimeTypes]);

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop: onFileDropped,
    maxSize: fileMaxSize,
    accept: expandedMimeTypes,
  });

  return (
    <div
      {...getRootProps()}
      className={cx(
        `py-24
         px-5
         text-greyLight
         outline-dashed
         rounded
         outline-greyLighter
         bg-white`,
        isDragActive ? "outline-4" : "outline"
      )}
    >
      <input {...getInputProps()} />
      <div className="flex flex-col items-center text-sm gap-y-1.5">
        <UploadIcon className="h-6 w-6 text-primaryTheme" />
        <div>Drag and Drop to upload or</div>
        <div className="text-primaryTheme font-sansSemiBold">Browse files</div>
      </div>
      <div className="text-xs mt-3 text-center">
        File size should be less than {formatBytes(fileMaxSize)} &amp; File type should be{" "}
        {acceptedExtensions.join(", ")}.
      </div>
    </div>
  );
};
