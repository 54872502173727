import { QueryClient } from "@tanstack/react-query";
import {
  InsuranceDetailsVO,
  UpdatePatientInsuranceOrdinalRequest,
  InsuranceVerifyRequest,
  UpdatePatientInsuranceRequest,
  UpdatePatientInsuranceStateRequest,
  UpdateInsurancePlanRequest,
  InsurancePlanVO,
  BatchUpsertBenefitCoverageRequest,
  CreateInsurancePlanRequest,
  BatchUpsertBenefitLimitationRequest,
  BatchUpdateRecentProcedureHistoryRequest,
  RecentProcedureHistoryItemVO,
  ErrorResponse,
  BenefitCoverageVO,
  UnlinkInsurancePlanRequest,
  AutoVerifyEligibilityRequest,
  UpdateInsurancePlanFeeScheduleRequest,
} from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import { updateCachedData } from "@libs/utils/queryCache";
import {
  invalidateInsurancesRelatedCaches,
  updateInsuranceCacheWithRelatedItem,
} from "api/patientInsurance/cache";
import { invalidateProceduresAndTreatmentPlan } from "api/charting/mutations";
import { updateSsnCache } from "api/user/cache";

const decacheSsnReferences = (
  queryClient: QueryClient,
  ssnValue: string,
  practiceId: number,
  patientId: number
) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getPatientSummary"), { patientId, practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getPatient"), { patientId, practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getPatients")]);
  queryClient.invalidateQueries([getQueryKey("practices", "lookupPatient")]);
  queryClient.invalidateQueries([getQueryKey("practices", "lookupPatientSummary")]);
  updateSsnCache(ssnValue, practiceId, patientId);
};

export const addPatientInsurance = makeMutation({
  mutationKey: ["practices", "addPatientInsurance"],
  formatParams: (args: { practiceId: number; patientId: number; data: InsuranceDetailsVO }) => [
    args.practiceId,
    args.patientId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { patientId, practiceId, data }) => {
      invalidateInsurancesRelatedCaches(queryClient, { patientId, practiceId });

      if (data.type === "PRIMARY_SUBSCRIBER" && data.primarySubscriber?.ssn) {
        decacheSsnReferences(queryClient, data.primarySubscriber.ssn, practiceId, patientId);
      }
    },
  }),
});

export const unlinkPatientInsuranceFromPlan = makeMutation({
  mutationKey: ["practices", "unlinkPatientInsuranceFromPlan"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    insuranceId: number;
    data: UnlinkInsurancePlanRequest;
  }) => [args.practiceId, args.patientId, args.insuranceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { patientId, practiceId, insuranceId, data }) => {
      invalidateInsurancesRelatedCaches(queryClient, { patientId, practiceId });
      queryClient.invalidateQueries([
        getQueryKey("practices", "getInsurancePlan"),
        { practiceId, insurancePlanUuid: data.insurancePlanUuid },
      ]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPatientInsurance"),
        {
          practiceId,
          patientId,
          insuranceId,
          includeBenefitCoverage: true,
          includeInsurancePlan: true,
        },
      ]);
    },
  }),
});

export const updatePatientInsurance = makeMutation({
  mutationKey: ["practices", "updatePatientInsurance"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    invalidateSsn?: boolean;
    insuranceId: number;
    data: UpdatePatientInsuranceRequest;
  }) => [args.practiceId, args.patientId, args.insuranceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { patientId, practiceId, insuranceId, invalidateSsn, data }) => {
      const patientInsurance = response.data.data;

      if (data.ssn && invalidateSsn) {
        decacheSsnReferences(queryClient, data.ssn, practiceId, patientId);
      }

      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId, patientId });
      queryClient.invalidateQueries([getQueryKey("practices", "getDailyHuddle"), { practiceId }]);
      updateInsuranceCacheWithRelatedItem(
        queryClient,
        { practiceId, patientId, insuranceId },
        "patientInsurance",
        patientInsurance
      );
    },
  }),
});

export const updatePatientInsuranceState = makeMutation({
  mutationKey: ["practices", "updatePatientInsuranceState"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    insuranceId: number;
    data: UpdatePatientInsuranceStateRequest;
  }) => [args.practiceId, args.patientId, args.insuranceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { patientId, practiceId }) => {
      invalidateInsurancesRelatedCaches(queryClient, { patientId, practiceId });
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPatientInsurance"),
        {
          practiceId,
          patientId,
        },
      ]);
    },
  }),
});

export const updatePatientInsuranceOrdinal = makeMutation({
  mutationKey: ["practices", "updatePatientInsuranceOrdinal"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    insuranceId: number;
    data: UpdatePatientInsuranceOrdinalRequest;
  }) => [args.practiceId, args.patientId, args.insuranceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, params) => {
      const { patientId, practiceId, insuranceId } = params;

      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId, patientId });
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPatientInsurances"),
        { practiceId, patientId, insuranceState: ["ACTIVE"] },
      ]);
      queryClient.invalidateQueries([getQueryKey("practices", "getDailyHuddle"), { practiceId }]);

      updateInsuranceCacheWithRelatedItem(
        queryClient,
        { practiceId, patientId, insuranceId },
        "patientInsurance",
        response.data.data
      );
    },
  }),
});

export const verifyPatientInsuranceEligibility = makeMutation({
  mutationKey: ["practices", "verifyPatientInsuranceEligibility"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    insuranceId: number;
    data: InsuranceVerifyRequest;
  }) => [args.practiceId, args.patientId, args.insuranceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, params) => {
      const { patientId, practiceId, insuranceId } = params;
      const latest = response.data.data;

      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId, patientId });
      queryClient.invalidateQueries([getQueryKey("practices", "getDailyHuddle"), { practiceId }]);
      updateInsuranceCacheWithRelatedItem(
        queryClient,
        { practiceId, patientId, insuranceId },
        "patientInsurance",
        latest
      );
    },
  }),
});

export const verifyInsurancePlanBenefit = makeMutation({
  mutationKey: ["practices", "verifyInsurancePlanBenefit"],
  formatParams: (args: {
    practiceId: number;
    insurancePlanUuid: string;
    patientId: number;
    insuranceId: number;
    data: InsuranceVerifyRequest;
  }) => [args.practiceId, args.insurancePlanUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, params) => {
      const { practiceId, patientId, insurancePlanUuid, insuranceId } = params;

      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId, patientId });
      queryClient.invalidateQueries([getQueryKey("practices", "getDailyHuddle"), { practiceId }]);

      updateCachedData<InsurancePlanVO>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getInsurancePlan"), { practiceId, insurancePlanUuid }],
          exact: true,
        },
        () => {
          return response.data.data;
        }
      );
      updateInsuranceCacheWithRelatedItem(
        queryClient,
        { practiceId, patientId, insuranceId },
        "insurancePlan",
        response.data.data
      );
    },
  }),
});

export const autoVerifyEligibility = makeMutation({
  mutationKey: ["practices", "autoVerifyEligibility"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    insuranceId: number;
    data: AutoVerifyEligibilityRequest;
  }) => [args.practiceId, args.patientId, args.insuranceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, params) => {
      const { patientId, practiceId, insuranceId } = params;

      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId, patientId });
      queryClient.invalidateQueries([getQueryKey("practices", "getDailyHuddle"), { practiceId }]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPatientInsurances"),
        { practiceId, patientId },
      ]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPatientInsurance"),
        { practiceId, patientId, insuranceId },
      ]);
    },
  }),
});

export const createInsurancePlan = makeMutation({
  mutationKey: ["practices", "createInsurancePlan"],
  formatParams: (args: { practiceId: number; patientId: number; data: CreateInsurancePlanRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, params) => {
      const { practiceId, patientId } = params;

      invalidateInsurancesRelatedCaches(queryClient, { patientId, practiceId });
    },
  }),
});

export const updateInsurancePlan = makeMutation({
  mutationKey: ["practices", "updateInsurancePlan"],
  formatParams: (args: {
    patientId: number;
    practiceId: number;
    insuranceId: number;
    insurancePlanUuid: string;
    data: UpdateInsurancePlanRequest;
  }) => [args.practiceId, args.insurancePlanUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (responseData, params) => {
      const { practiceId, insuranceId, insurancePlanUuid, patientId } = params;
      const updatedPlan = responseData.data.data;

      queryClient.invalidateQueries([getQueryKey("practices", "getInsurancePlans"), { practiceId }]);
      queryClient.invalidateQueries([getQueryKey("practices", "getDailyHuddle"), { practiceId }]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPatientInsurance"),
        { practiceId, insuranceId, patientId },
      ]);

      updateInsuranceCacheWithRelatedItem(
        queryClient,
        { practiceId, patientId, insuranceId, includeInsurancePlan: true },
        "insurancePlan",
        updatedPlan
      );

      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId, patientId });

      updateCachedData<InsurancePlanVO>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getInsurancePlan"), { practiceId, insurancePlanUuid }],
          exact: true,
        },
        () => {
          return updatedPlan;
        }
      );
    },
  }),
});

export const updateInsurancePlanFeeSchedule = makeMutation({
  mutationKey: ["practices", "updateInsurancePlanFeeSchedule"],
  formatParams: (args: {
    patientId: number;
    practiceId: number;
    insuranceId: number;
    insurancePlanUuid: string;
    data: UpdateInsurancePlanFeeScheduleRequest;
  }) => [args.practiceId, args.insurancePlanUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (responseData, params) => {
      const { practiceId, insuranceId, insurancePlanUuid, patientId } = params;
      const updatedPlan = responseData.data.data;

      queryClient.invalidateQueries([getQueryKey("practices", "getInsurancePlans"), { practiceId }]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPatientInsurance"),
        { practiceId, insuranceId, patientId },
      ]);

      updateInsuranceCacheWithRelatedItem(
        queryClient,
        { practiceId, patientId, insuranceId, includeInsurancePlan: true },
        "insurancePlan",
        updatedPlan
      );

      invalidateProceduresAndTreatmentPlan(queryClient, { practiceId, patientId });

      updateCachedData<InsurancePlanVO>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getInsurancePlan"), { practiceId, insurancePlanUuid }],
          exact: true,
        },
        () => {
          return updatedPlan;
        }
      );
    },
  }),
});

export const linkPatientInsuranceToPlan = makeMutation({
  mutationKey: ["practices", "linkPatientInsuranceToPlan"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    insurancePlanUuid: string;
    insuranceId: number;
  }) => [args.practiceId, args.patientId, args.insuranceId, { insurancePlanUuid: args.insurancePlanUuid }],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { patientId, practiceId, insuranceId }) => {
      const latest = response.data.data;

      invalidateInsurancesRelatedCaches(queryClient, { patientId, practiceId });

      queryClient.invalidateQueries([
        getQueryKey("practices", "getPatientInsurance"),
        {
          practiceId,
          patientId,
        },
      ]);
      updateInsuranceCacheWithRelatedItem(
        queryClient,
        { practiceId, patientId, insuranceId },
        "patientInsurance",
        latest.patientInsurance
      );
      updateInsuranceCacheWithRelatedItem(
        queryClient,
        { practiceId, patientId, insuranceId },
        "insurancePlan",
        latest.insurancePlan
      );
    },
  }),
});

export const upsertBenefitCoveragesMutation = makeMutation({
  mutationKey: ["practices", "upsertBenefitCoverages"],
  formatParams: (args: {
    practiceId: number;
    insurancePlanUuid: string;
    insuranceId: number;
    patientId: number;
    data: BatchUpsertBenefitCoverageRequest;
  }) => [args.practiceId, args.insurancePlanUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, insurancePlanUuid, patientId, insuranceId }) => {
      const benefitCoverages = response.data.data;

      updateCachedData<BenefitCoverageVO[]>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getBenefitCoverages"), { practiceId, insurancePlanUuid }],
          exact: true,
        },
        () => {
          return benefitCoverages;
        }
      );
      updateInsuranceCacheWithRelatedItem(
        queryClient,
        { practiceId, patientId, insuranceId, includeBenefitCoverage: true },
        "benefitCoverages",
        benefitCoverages
      );
    },
  }),
});

export const upsertBenefitLimitations = makeMutation({
  mutationKey: ["practices", "upsertBenefitLimitations"],
  formatParams: (args: {
    practiceId: number;
    insurancePlanUuid: string;
    data: BatchUpsertBenefitLimitationRequest;
  }) => [args.practiceId, args.insurancePlanUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId, insurancePlanUuid }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getGroupedBenefitLimitations"),
        { practiceId, insurancePlanUuid },
      ]);
      queryClient.invalidateQueries([getQueryKey("practices", "getPatientInsurances"), { practiceId }]);
    },
  }),
});

export const isProcedureHistoryConflict = (errorResponse: ErrorResponse) => {
  return errorResponse.errors?.some((item) => item.errorCode === "DATA_CONFLICT");
};

export const upsertProcedureHistory = makeMutation({
  mutationKey: ["practices", "updateRecentProcedureHistoryItem"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    insuranceId: number;
    data: BatchUpdateRecentProcedureHistoryRequest;
  }) => [args.practiceId, args.patientId, args.insuranceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { insuranceId, practiceId, patientId }) => {
      updateCachedData<RecentProcedureHistoryItemVO[]>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getRecentProcedureHistoryItems"),
            { practiceId, insuranceId, patientId },
          ],
          exact: true,
        },
        () => {
          return response.data.data;
        }
      );
    },
    onError: (response, { insuranceId, practiceId, patientId }) => {
      if (isProcedureHistoryConflict(response.error)) {
        queryClient.invalidateQueries([
          getQueryKey("practices", "getRecentProcedureHistoryItems"),
          { practiceId, insuranceId, patientId },
        ]);
      }
    },
  }),
});
