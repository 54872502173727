import { ClaimLineItemVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatCurrency } from "@libs/utils/currency";
import { Cell, EMPTY_CELL, Row } from "@libs/components/UI/GridTableComponents";
import {
  ClaimLineItemVOKeys,
  cxStyles,
  EditableColumnIds,
  isComparisonCell,
} from "components/Claim/ProcedureTable";
import { HeaderData } from "components/Claim/procedureTableUtils";

interface Props {
  footerDescriptionIndex: number;
  headers: HeaderData[];
  insuranceTooltip?: string;
  isAdditionalPayment: boolean;
  isDraftEobView: boolean;
  lineItems: ClaimLineItemVO[];
  previousLineItems: ClaimLineItemVO[] | undefined;
  showTotals: boolean;
}

const EstimateColumnHeaderIdByEditableColumnId: Record<EditableColumnIds, ClaimLineItemVOKeys> = {
  deductibleAmount: "deductibleEstimatedAmount",
  insuranceAmount: "insuranceEstimatedAmount",
  patientAmount: "patientEstimatedAmount",
};

export const getFieldTotal = (field: ClaimLineItemVOKeys, lineItems: ClaimLineItemVO[]) => {
  return (
    lineItems.reduce((acc, current) => {
      return acc + (current[field] as number);
    }, 0) || 0
  );
};

export const ProcedureTableFooter: React.FC<Props> = ({
  footerDescriptionIndex,
  headers,
  insuranceTooltip,
  isAdditionalPayment,
  isDraftEobView,
  lineItems,
  previousLineItems,
  showTotals,
}) => {
  return (
    <Row>
      {headers.map(
        // eslint-disable-next-line complexity
        (header, index) => {
          const errorTooltip = header.id === "insuranceAmount" ? insuranceTooltip : "";
          const showComparisonCell = isComparisonCell(header.id) && isAdditionalPayment && previousLineItems;

          return (
            <Cell
              className={cx(
                "font-sansSemiBold min-h-full",
                cxStyles.cell({
                  borderBottom: isAdditionalPayment,
                  borderBottomColor: "border-b-greyLight",
                }),
                header.isCurrencyCell && isDraftEobView && cxStyles.currencyValueCell,
                !showComparisonCell && cxStyles.cellYPadding,
                (header.isRightAligned || index === footerDescriptionIndex) && "justify-end",
                errorTooltip && "text-red",
                isComparisonCell(header.id) && !isAdditionalPayment && isDraftEobView
                  ? getFieldTotal(header.id as ClaimLineItemVOKeys, lineItems) ===
                    getFieldTotal(
                      EstimateColumnHeaderIdByEditableColumnId[header.id as EditableColumnIds],
                      lineItems
                    )
                    ? "bg-greenLight"
                    : "bg-redLight"
                  : ""
              )}
              key={header.id}
            >
              {index === footerDescriptionIndex ? (
                "Totals"
              ) : header.isCurrencyCell ? (
                showTotals ? (
                  <>
                    {showComparisonCell ? (
                      <div
                        className={cx(
                          "flex items-center justify-end pr-3",
                          cxStyles.cellYPadding,
                          cxStyles.currencyDividerBorder,
                          cxStyles.currencyReadOnly
                        )}
                      >
                        {formatCurrency(getFieldTotal(header.id as ClaimLineItemVOKeys, previousLineItems))}
                      </div>
                    ) : null}
                    <div className={cx("flex justify-end pl-3", cxStyles.currencyEdit)}>
                      {formatCurrency(getFieldTotal(header.id as ClaimLineItemVOKeys, lineItems))}
                    </div>
                  </>
                ) : (
                  "-"
                )
              ) : header.id === "insuranceCoveragePercent" ? (
                EMPTY_CELL
              ) : (
                ""
              )}
            </Cell>
          );
        }
      )}
    </Row>
  );
};
