/* eslint-disable @typescript-eslint/no-magic-numbers */
/* eslint-disable @typescript-eslint/naming-convention */
export const blankTemplate = {
  counters: {
    u_row: 4,
    u_column: 4,
    u_content_text: 3,
    u_content_image: 2,
    u_content_divider: 1,
  },
  body: {
    id: "on6EAR8_Sp",
    rows: [
      {
        id: "W9VDXJ0epo",
        cells: [1],
        columns: [
          {
            id: "RUykI8JLX5",
            contents: [
              {
                id: "hZLFSgYKyZ",
                type: "image",
                values: {
                  containerPadding: "0px",
                  anchor: "",
                  src: {
                    url: "https://email-assets.grindfoundry-preprod.com/1701206534130-Placeholder%20Logo.png",
                    width: 128,
                    height: 56,
                  },
                  textAlign: "center",
                  altText: "Logo",
                  action: {
                    name: "web",
                    values: {
                      href: "",
                      target: "_blank",
                    },
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_image_1",
                    htmlClassNames: "u_content_image",
                  },
                  selectable: true,
                  draggable: true,
                  duplicatable: true,
                  deletable: true,
                  hideable: true,
                },
              },
            ],
            values: {
              _meta: {
                htmlID: "u_column_3",
                htmlClassNames: "u_column",
              },
              padding: "24px",
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: "no-repeat",
            size: "custom",
            position: "center",
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_3",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: "_jKXucb6Qb",
        cells: [1],
        columns: [
          {
            id: "pnlYgqpBb8",
            contents: [],
            values: {
              _meta: {
                htmlID: "u_column_1",
                htmlClassNames: "u_column",
              },
              border: {},
              padding: "24px",
              backgroundColor: "",
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: "no-repeat",
            size: "custom",
            position: "center",
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_1",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
      {
        id: "nHiSziihUN",
        cells: [1],
        columns: [
          {
            id: "lAH0x4vbhH",
            contents: [
              {
                id: "Erb_B3kUQG",
                type: "divider",
                values: {
                  width: "100%",
                  border: {
                    borderTopWidth: "1px",
                    borderTopStyle: "solid",
                    borderTopColor: "#e2e8f0",
                  },
                  textAlign: "center",
                  containerPadding: "24px 24px 0px",
                  anchor: "",
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_divider_1",
                    htmlClassNames: "u_content_divider",
                  },
                  selectable: false,
                  draggable: false,
                  duplicatable: false,
                  deletable: false,
                  hideable: false,
                },
              },
              {
                id: "i7v5ZQhRop",
                type: "text",
                values: {
                  containerPadding: "24px",
                  anchor: "",
                  fontFamily: {
                    url: "https://fonts.googleapis.com/css?family=Open+Sans:400,700",
                    label: "Open Sans",
                    value: "'Open Sans',sans-serif",
                    weights: [400, 700],
                    defaultFont: true,
                  },
                  fontSize: "12px",
                  color: "#64748b",
                  textAlign: "left",
                  lineHeight: "140%",
                  linkStyle: {
                    inherit: true,
                    linkColor: "#0000ee",
                    linkHoverColor: "#0000ee",
                    linkUnderline: true,
                    linkHoverUnderline: true,
                    body: false,
                  },
                  hideDesktop: false,
                  displayCondition: null,
                  _meta: {
                    htmlID: "u_content_text_1",
                    htmlClassNames: "u_content_text",
                  },
                  selectable: false,
                  draggable: false,
                  duplicatable: false,
                  deletable: false,
                  hideable: false,
                  text: '<p style="line-height: 140%;"><strong>{{PRACTICE_NAME}}</strong></p>\n<p style="line-height: 140%;">{{PRACTICE_ADDRESS}}</p>\n<p style="line-height: 140%;">{{PRACTICE_PHONE_NUMBER}}</p>\n<p style="line-height: 140%;"><a rel="noopener" href="{{UNSUBSCRIBE_LINK}}" target="_blank" data-u-link-value="eyJuYW1lIjoid2ViIiwiYXR0cnMiOnsiaHJlZiI6Int7aHJlZn19IiwidGFyZ2V0Ijoie3t0YXJnZXR9fSJ9LCJ2YWx1ZXMiOnsiaHJlZiI6Int7VU5TVUJTQ1JJQkVfTElOS319IiwidGFyZ2V0IjoiX2JsYW5rIn19">Unsubscribe</a></p>',
                },
              },
            ],
            values: {
              _meta: {
                htmlID: "u_column_2",
                htmlClassNames: "u_column",
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: "no-repeat",
            size: "custom",
            position: "center",
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_2",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: false,
          duplicatable: false,
          deletable: false,
          hideable: false,
        },
      },
    ],
    headers: [],
    footers: [],
    values: {
      popupPosition: "center",
      popupWidth: "600px",
      popupHeight: "auto",
      borderRadius: "10px",
      contentAlign: "center",
      contentVerticalAlign: "center",
      contentWidth: 600,
      fontFamily: {
        label: "Open Sans",
        value: "'Open Sans',sans-serif",
        url: "https://fonts.googleapis.com/css?family=Open+Sans:400,700",
        weights: [400, 700],
        defaultFont: true,
      },
      textColor: "#000000",
      popupBackgroundColor: "#FFFFFF",
      popupBackgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "cover",
        position: "center",
      },
      popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
      popupCloseButton_position: "top-right",
      popupCloseButton_backgroundColor: "#DDDDDD",
      popupCloseButton_iconColor: "#000000",
      popupCloseButton_borderRadius: "0px",
      popupCloseButton_margin: "0px",
      popupCloseButton_action: {
        name: "close_popup",
        attrs: {
          onClick: "document.querySelector('.u-popup-container').style.display = 'none';",
        },
      },
      backgroundColor: "#ffffff",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      preheaderText: "",
      linkStyle: {
        body: true,
        linkColor: "#007ed9",
        linkHoverColor: "#0000ee",
        linkUnderline: false,
        linkHoverUnderline: true,
        inherit: false,
      },
      _meta: {
        htmlID: "u_body",
        htmlClassNames: "u_body",
      },
    },
  },
  schemaVersion: 16,
};
