import { startOfDay, subDays } from "date-fns";
import {
  PatientToothVO,
  DentalProcedureVO,
  PatientProcedureVO,
  CreatePatientProcedureRequest,
  UpdatePatientProcedureRequest,
} from "@libs/api/generated-api";
import { formatAsISODate } from "@libs/utils/date";
import { isOneOf } from "@libs/utils/isOneOf";
import { centsToCurrencyValue, currencyValueToCents } from "@libs/utils/currency";
import {
  getSupernumeraryAdjacentToothName,
  isValidSupernumeraryToothName,
  lookupToothName,
} from "components/Charting/teeth";
import { filterUnnamedTeeth, ToothChartSelections } from "components/Charting/toothChartData";
import {
  getSurfaceSelectionCount,
  Surface,
  SurfaceSelection,
  surfaceSelectorToArray,
  surfacesToSelector,
} from "components/Charting/toothSurfaces";
import {
  isArchDisabledByDentalProcedure,
  isAreaTypeDisabledByDentalProcedure,
  isQuadrantDisabledByDentalProcedure,
  isToothDisabledByDentalProcedure,
} from "components/Charting/patientProcedureSchema";

export interface DraftPatientProcedureRequest {
  dentalProcedureId?: PatientProcedureVO["dentalProcedureId"];
  surfaces: SurfaceSelection;
  treatmentType: PatientProcedureVO["status"];
  providerId?: number;
  prognosis?: PatientProcedureVO["prognosis"];
  areaType?: "TOOTH" | "ARCH" | "QUADRANT" | "MOUTH" | "";
  quadrant?: PatientToothVO["quadrant"];
  arch?: PatientToothVO["arch"];
  toothSelections: Set<string>;
  priority?: PatientProcedureVO["priority"];
  implantDate?: string;
  implantInstallType?: "NEW" | "REPLACEMENT";
  preAuthStatus?: PatientProcedureVO["preAuthStatus"];
  preAuthNumber?: PatientProcedureVO["preAuthNumber"];
  insuranceAmount?: string;
  patientAmount?: string;
  downgradeDentalProcedure: boolean;
  downgradeDentalProcedureId?: NonNullable<
    UpdatePatientProcedureRequest["preAuth"]
  >["downgradeDentalProcedureId"];
  date?: string;
  creditToPractice?: boolean;
}

export type SavePatientProcedureUpdate = Pick<
  CreatePatientProcedureRequest,
  | "status"
  | "providerId"
  | "prognosis"
  | "surfaces"
  | "mouthArea"
  | "toothName"
  | "toothRange"
  | "implantDate"
  | "implantInstallType"
  | "priority"
  | "date"
> &
  Pick<UpdatePatientProcedureRequest, "preAuth" | "creditToPractice">;

const createDraftProcedure = (
  options: Partial<DraftPatientProcedureRequest> & {
    treatmentType: CreatePatientProcedureRequest["status"];
    providerId?: number;
  },
  now: Date
) => {
  const draft: DraftPatientProcedureRequest = {
    date: formatAsISODate(getMaxProcedureDate(now)),
    downgradeDentalProcedure: false,
    toothSelections: new Set(),
    surfaces: {
      M: false,
      O: false,
      D: false,
      B: false,
      L: false,
      BV: false,
      LV: false,
    },
    ...options,
  };

  return draft;
};

const createToothDraftProcedure = (
  options: Partial<DraftPatientProcedureRequest> & {
    treatmentType: CreatePatientProcedureRequest["status"];
    providerId?: number;
  },
  toothNumbers: number[],
  teeth: PatientToothVO[],
  dp: DentalProcedureVO,
  now: Date,
  surfaces?: Surface[]
) => {
  const draft = createDraftProcedure(
    {
      ...options,
      areaType: "TOOTH",
      toothSelections: new Set([...toothNumbers].map((num) => lookupToothName(teeth, num))),
    },
    now
  );

  const parsedSurfaces = parseSurfaces(dp, surfaces);

  if (parsedSurfaces) {
    draft.surfaces = parsedSurfaces;
  }

  return draft;
};

const parseSurfaces = (dp: DentalProcedureVO, surfaces?: Surface[]) => {
  const surfaceRequirements = dp.areaSelection?.surface;

  if (surfaces?.length && surfaceRequirements && surfaceRequirements.max >= surfaces.length) {
    return surfacesToSelector(surfaces);
  }

  return null;
};

const shouldCreateProcedurePerTooth = (dp: DentalProcedureVO, selections: ToothChartSelections) =>
  Boolean(
    dp.areaSelection?.options.includes("TOOTH") &&
      !dp.areaSelection.options.includes("TOOTH_RANGE") &&
      selections.type === "TOOTH" &&
      selections.value.size > 1
  );

const shouldCreateProcedurePerQuadrant = (dp: DentalProcedureVO, selections: ToothChartSelections) =>
  Boolean(
    dp.areaSelection?.options.includes("QUADRANT") &&
      selections.type === "QUADRANT" &&
      selections.value.size > 1
  );

const shouldCreateProcedurePerArch = (dp: DentalProcedureVO, selections: ToothChartSelections) =>
  Boolean(
    dp.areaSelection?.options.includes("ARCH") && selections.type === "ARCH" && selections.value.size > 1
  );

/**
 * Takes a user's selections matched with the dental procedure
 * to try and form a valid patient procedures to submit to the api.
 * If they aren't valid selections (regardless of whether it's required or not), the user will
 * have to provide more details. The three exceptions are:
 * 1. The dental procedure has no area options.
 * 2. The dental procedure only has one option, MOUTH
 * 3. The dental procedure only has one option, ARCH and the required
 * position is either ARCH_UPPER or ARCH_LOWER.
 * In those cases, there is only one area selection that makes
 * sense so asking the using to further clarify doesn't make sense.
 * @param teeth The current state of the patient's teeth
 * @param dentalProcedures The dental procedures to assign to the patient
 * @param treatmentType The status of the dental procedure to be performed on the patient
 * @param providerId The provider that will be responsible for the procedure
 * @param chartSelections The area selected on the odontongram that may or may not comply with the dental procedure
 * @returns A list of DraftPatientProcedures
 */
export const dentalProceduresToDraftPatientProcedures = (
  teeth: PatientToothVO[],
  dentalProcedures: DentalProcedureVO[],
  treatmentType: PatientProcedureVO["status"],
  providerId: number | undefined,
  chartSelections: ToothChartSelections,
  now: Date,
  surfaces?: Surface[]
) => {
  // While you can select teeth that have no primary mapping on the tooth chart
  // You can't add procedures that don't have a tooth name so let's ignore them.
  const cleanedSelections = filterUnnamedTeeth(chartSelections, teeth);

  // eslint-disable-next-line complexity
  return dentalProcedures.flatMap((dp) => {
    const basePatientProcedure = {
      dentalProcedureId: dp.id,
      treatmentType,
      providerId: treatmentType === "EXISTING_OTHER" ? undefined : providerId,
      areaType: "" as const,
    };

    const areaSelection = dp.areaSelection;

    if (!areaSelection) {
      return createDraftProcedure(basePatientProcedure, now);
    }

    // If there is only one option select it
    const firstOption = areaSelection.options[0];

    if (areaSelection.options.length === 1 && isOneOf(firstOption, ["MOUTH", "ARCH"])) {
      if (firstOption === "MOUTH") {
        return createDraftProcedure(
          {
            ...basePatientProcedure,
            areaType: firstOption,
          },
          now
        );
      }

      if (areaSelection.position && isOneOf(areaSelection.position, ["ARCH_LOWER", "ARCH_UPPER"])) {
        return createDraftProcedure(
          {
            ...basePatientProcedure,
            areaType: firstOption,
            arch: areaSelection.position,
          },
          now
        );
      }
    }

    if (cleanedSelections.type === "ARCH") {
      if (shouldCreateProcedurePerArch(dp, cleanedSelections)) {
        return [...cleanedSelections.value].map((arch) => {
          return createDraftProcedure(
            {
              ...basePatientProcedure,
              areaType: cleanedSelections.type,
              arch,
            },
            now
          );
        });
      }

      return createDraftProcedure(
        {
          ...basePatientProcedure,
          areaType: cleanedSelections.type,
          arch: [...cleanedSelections.value][0],
        },
        now
      );
    }

    if (cleanedSelections.type === "QUADRANT") {
      if (shouldCreateProcedurePerQuadrant(dp, cleanedSelections)) {
        return [...cleanedSelections.value].map((quadrant) => {
          return createDraftProcedure(
            {
              ...basePatientProcedure,
              areaType: cleanedSelections.type,
              quadrant,
            },
            now
          );
        });
      }

      return createDraftProcedure(
        {
          ...basePatientProcedure,
          areaType: cleanedSelections.type,
          quadrant: [...cleanedSelections.value][0],
        },
        now
      );
    }

    if (cleanedSelections.type === "TOOTH") {
      // If multiple teeth are selected, tooth range is not an option, and tooth is an option, then create
      // a patient procedure for each tooth.
      if (shouldCreateProcedurePerTooth(dp, cleanedSelections)) {
        return [...cleanedSelections.value].map((num) => {
          return createToothDraftProcedure(basePatientProcedure, [num], teeth, dp, now, surfaces);
        });
      }

      return createToothDraftProcedure(
        basePatientProcedure,
        [...cleanedSelections.value],
        teeth,
        dp,
        now,
        surfaces
      );
    }

    const toothOptionsCount = 2;

    // Auto select tooth option if no selections were made and tooth and tooth range are
    // the only procedure options
    if (
      areaSelection.options.length === toothOptionsCount &&
      areaSelection.options.includes("TOOTH") &&
      areaSelection.options.includes("TOOTH_RANGE")
    ) {
      return createDraftProcedure(
        {
          ...basePatientProcedure,
          areaType: "TOOTH",
        },
        now
      );
    }

    return createDraftProcedure(basePatientProcedure, now);
  });
};

export const DEFAULT_SURFACE_SELECTION: SurfaceSelection = {
  M: false,
  O: false,
  D: false,
  B: false,
  L: false,
  BV: false,
  LV: false,
};

/**
 * Converts a saved PatientProcedureVO to a DraftPatientProcedureRequest for a more convenient
 * structre for editing.
 * @param patientProcedure The patient procedure to be converted
 * @returns A DraftPatientProcedureRequest version of the patient procedure
 */
// eslint-disable-next-line complexity
export const patientProcedureToDraftPatientProcedure = (patientProcedure: PatientProcedureVO, now: Date) => {
  const draft: DraftPatientProcedureRequest = {
    treatmentType: patientProcedure.status,
    providerId: patientProcedure.provider?.id,
    date: isOneOf(patientProcedure.status, ["EXISTING_CURRENT", "EXISTING_OTHER"])
      ? patientProcedure.date
      : formatAsISODate(getMaxProcedureDate(now)),
    preAuthNumber: patientProcedure.preAuthNumber,
    patientAmount: centsToCurrencyValue(patientProcedure.patientAmount),
    insuranceAmount: centsToCurrencyValue(patientProcedure.insuranceAmount),
    downgradeDentalProcedure: Boolean(patientProcedure.downgradeDentalProcedureId),
    downgradeDentalProcedureId: patientProcedure.downgradeDentalProcedureId,
    prognosis: patientProcedure.prognosis,
    priority: patientProcedure.priority,
    implantDate: patientProcedure.implant?.implantDate,
    implantInstallType: patientProcedure.implant?.implantInstallType,
    toothSelections: new Set(),
    surfaces: DEFAULT_SURFACE_SELECTION,
    creditToPractice: patientProcedure.creditToPractice,
  };

  const { mouthArea, toothName, toothRange, surfaces } = patientProcedure;

  if (mouthArea) {
    if (mouthArea === "WHOLE_MOUTH") {
      draft.areaType = "MOUTH";
    } else if (
      isOneOf(mouthArea, ["QUAD_LOWER_LEFT", "QUAD_LOWER_RIGHT", "QUAD_UPPER_LEFT", "QUAD_UPPER_RIGHT"])
    ) {
      draft.areaType = "QUADRANT";
      draft.quadrant = mouthArea;
    } else if (isOneOf(mouthArea, ["ARCH_LOWER", "ARCH_UPPER"])) {
      draft.areaType = "ARCH";
      draft.arch = mouthArea;
    }
  } else if (toothName) {
    draft.areaType = "TOOTH";
    draft.toothSelections = new Set([toothName]);

    if (surfaces?.length) {
      draft.surfaces = surfacesToSelector(surfaces);
    }
  } else if (toothRange) {
    draft.areaType = "TOOTH";
    draft.toothSelections = new Set(toothRange.split(","));
  } else {
    draft.areaType = "";
  }

  return draft;
};

/**
 * Converts DraftPatientProcedureRequest to SavePatientProcedureUpdate which can be used
 * to create or update a patient procedure with our API.
 * Note: to create a patient procedure you also need to include the dentalProcedureId.
 * @param draft The editable version of the patient procedure.
 * @param teeth The current state of the patient's teeth
 * @param dentalProcedure The dental procedure associated with the patient procedure.
 * @returns A saveable version of the patient procedure
 */

// eslint-disable-next-line complexity, max-statements
export const draftToSavePatientProcedure = (
  draft: DraftPatientProcedureRequest,
  dentalProcedure: DentalProcedureVO,
  teeth: PatientToothVO[],
  patientProcedure?: PatientProcedureVO
): SavePatientProcedureUpdate => {
  const postItem: SavePatientProcedureUpdate = {
    status: draft.treatmentType,
    creditToPractice: draft.creditToPractice,
  };

  // Covers the case where a user edits a procedure treatment type changing it from anything to EXISTING_OTHER.
  // In that case we want to not save the provider id, because it wasnt done in the practice
  if (draft.treatmentType !== "EXISTING_OTHER") {
    postItem.providerId = draft.providerId;
  }

  if (draft.areaType === "MOUTH") {
    postItem.mouthArea = "WHOLE_MOUTH";
  } else if (draft.areaType === "ARCH" && draft.arch) {
    postItem.mouthArea = draft.arch;
  } else if (draft.areaType === "QUADRANT" && draft.quadrant) {
    postItem.mouthArea = draft.quadrant;
  } else if (draft.areaType === "TOOTH") {
    // The difference between selecting a tooth and tooth range from our UI is
    // indistinguishable since a tooth range can just be the selection of one
    // tooth. The only way we know how the selection must be submitted as a
    // toothName or toothRange is by inspecting the possible options defined in
    // the dental procedure.
    const options = dentalProcedure.areaSelection?.options;

    if (draft.toothSelections.size === 1 && options?.includes("TOOTH")) {
      postItem.toothName = [...draft.toothSelections][0];

      // Determine what surface to select based on the tooth position. If the
      // tooth is a supernumerary one, then we use the position of its adjacent
      // tooth.
      const toothToFind = isValidSupernumeraryToothName(postItem.toothName)
        ? getSupernumeraryAdjacentToothName(postItem.toothName)
        : postItem.toothName;
      const patientTooth = teeth.find((tooth) => tooth.toothName === toothToFind);
      const surfaces = draft.surfaces;

      if (getSurfaceSelectionCount(surfaces) && patientTooth) {
        postItem.surfaces = surfaceSelectorToArray(surfaces, patientTooth.position);
      }
    } else if (draft.toothSelections.size >= 1 && options?.includes("TOOTH_RANGE")) {
      postItem.toothRange = [...draft.toothSelections].sort().join(",");
    }
  }

  if (draft.implantInstallType) {
    postItem.implantInstallType = draft.implantInstallType;
  }

  if (draft.implantDate) {
    postItem.implantDate = draft.implantDate;
  }

  if (draft.priority) {
    postItem.priority = draft.priority;
  }

  if (draft.prognosis) {
    postItem.prognosis = draft.prognosis;
  }

  // if it is an editable treatment type use the user input
  if (isOneOf(draft.treatmentType, ["EXISTING_CURRENT", "EXISTING_OTHER"])) {
    postItem.date = draft.date;
    // if it's an existin patient procedure and the user input of treatment type doesn't
    // match the existing one then don't send up the date.
  } else if (patientProcedure && patientProcedure.status === draft.treatmentType) {
    postItem.date = patientProcedure.date;
  }

  const preAuthStatus = draft.preAuthStatus || patientProcedure?.preAuthStatus;

  if (preAuthStatus) {
    postItem.preAuth = {
      status: preAuthStatus,
      number: draft.preAuthNumber,
      insuranceAmount: currencyValueToCents(draft.insuranceAmount),
      patientAmount: currencyValueToCents(draft.patientAmount),
    };

    if (draft.downgradeDentalProcedure) {
      postItem.preAuth.downgradeDentalProcedureId = draft.downgradeDentalProcedureId;
    }
  }

  return postItem;
};

export const draftsToPost = (
  drafts: DraftPatientProcedureRequest[],
  dentalProcedures: DentalProcedureVO[],
  teeth: PatientToothVO[]
) => {
  return drafts.map((draft, index) => {
    const dentalProcedure = dentalProcedures[index];
    const postItem: CreatePatientProcedureRequest = {
      dentalProcedureId: dentalProcedure.id,
      ...draftToSavePatientProcedure(draft, dentalProcedure, teeth),
    };

    return postItem;
  });
};

export const getMaxProcedureDate = (date: Date) => {
  return startOfDay(date);
};

export const getMaxImplantReplacementDate = (date: Date) => {
  return subDays(startOfDay(date), 1);
};

const stripRestrictedTeeth = (
  toothSelections: Set<string>,
  teeth: PatientToothVO[],
  dentalProcedure: DentalProcedureVO
) => {
  const selectedToothNames = [...toothSelections];
  const supportedToothNames: string[] = [];

  for (const toothName of selectedToothNames) {
    const tooth = teeth.find((t) => t.toothName === toothName);

    if (tooth && !isToothDisabledByDentalProcedure(tooth, dentalProcedure)) {
      supportedToothNames.push(tooth.toothName);
    }
  }

  // if user chose two teet but can only choose one, make them choose which one
  const options = dentalProcedure.areaSelection?.options;

  if (options?.includes("TOOTH") && !options.includes("TOOTH_RANGE") && supportedToothNames.length > 1) {
    return new Set<string>();
  }

  return new Set(supportedToothNames);
};

const stripRestrictedArch = (
  arch: PatientToothVO["arch"] | undefined,
  dentalProcedure: DentalProcedureVO
) => {
  if (arch && !isArchDisabledByDentalProcedure(arch, dentalProcedure)) {
    return arch;
  }

  return undefined;
};

const stripRestrictedQuadrant = (
  quadrant: PatientToothVO["quadrant"] | undefined,
  dentalProcedure: DentalProcedureVO
) => {
  if (quadrant && !isQuadrantDisabledByDentalProcedure(quadrant, dentalProcedure)) {
    return quadrant;
  }

  return undefined;
};

const stripRestricedAreaType = (
  area: DraftPatientProcedureRequest["areaType"] | undefined,
  dentalProcedure: DentalProcedureVO
) => {
  if (area && !isAreaTypeDisabledByDentalProcedure(area, dentalProcedure)) {
    return area;
  }

  // If there is only one option select it if nothing was already selected.
  if (dentalProcedure.areaSelection?.required && dentalProcedure.areaSelection.options.length === 1) {
    return dentalProcedure.areaSelection.options[0] === "TOOTH_RANGE"
      ? "TOOTH"
      : dentalProcedure.areaSelection.options[0];
  }

  return undefined;
};

const stripImplantInfo = (draft: DraftPatientProcedureRequest, dentalProcedure: DentalProcedureVO) => {
  if (dentalProcedure.prosthetic) {
    return {
      implantDate: draft.implantDate,
      implantInstallType: draft.implantInstallType,
    };
  }

  return {
    implantDate: undefined,
    implantInstallType: undefined,
  };
};

// When switching from one dental procedure to another that has
// no surface selection this will deselect all.
const stripRestrictedSurfaces = (surface: SurfaceSelection, dentalProcedure: DentalProcedureVO) => {
  if (dentalProcedure.areaSelection?.surface) {
    return surface;
  }

  return {
    M: false,
    O: false,
    D: false,
    B: false,
    L: false,
    BV: false,
    LV: false,
  };
};

// Handles two cases:
// 1. strip out selections that a user could make on the odontogram but are not compatible
//    with the dental procedure they chose.
// 2. strip out selections that a user made for one dental procedure but do not apply to the
//    dental procedure they are switching to
export const stripSelectionsWithDentalProcedure = (
  draft: DraftPatientProcedureRequest,
  teeth: PatientToothVO[],
  dentalProcedure: DentalProcedureVO
) => ({
  ...draft,
  areaType: stripRestricedAreaType(draft.areaType, dentalProcedure),
  arch: stripRestrictedArch(draft.arch, dentalProcedure),
  quadrant: stripRestrictedQuadrant(draft.quadrant, dentalProcedure),
  toothSelections: stripRestrictedTeeth(draft.toothSelections, teeth, dentalProcedure),
  surface: stripRestrictedSurfaces(draft.surfaces, dentalProcedure),
  ...stripImplantInfo(draft, dentalProcedure),
});
