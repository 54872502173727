import { FC } from "react";
import { Text } from "./Text";
import { DescriptionSticker } from "./DescriptionSticker";

export const InvoiceDescription: FC<{ invoiceNumber: string }> = ({ invoiceNumber }) => {
  return (
    <div className="flex items-center gap-x-2">
      <DescriptionSticker color="invoice" />
      <Text bold>Invoice #{invoiceNumber}</Text>
    </div>
  );
};
