/* eslint-disable max-nested-callbacks */
import {
  createContext,
  useContext,
  useMemo,
  useState,
  useEffect,
  useCallback,
  useRef,
  ReactNode,
} from "react";
import { useQueryClient } from "@tanstack/react-query";
import { useNavigate } from "react-router-dom";
import {
  PatientSummaryVO,
  PerioChartExamEntryVO,
  PerioChartExamSettingsVO,
  PerioChartExamVO,
  UpdatePerioChartExamRequest,
} from "@libs/api/generated-api";
import { asyncNoop, noop } from "@libs/utils/noop";
import { formatAsISODate } from "@libs/utils/date";
import { UseBooleanResult, makeInitialState, useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { updateCachedData } from "@libs/utils/queryCache";
import { ApiResponse } from "@libs/@types/api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { getQueryKey } from "@libs/utils/queries";
import { useAccount } from "@libs/contexts/AccountContext";
import { getPerioChartExam, getPerioChartExams, getPerioChartExamSettings } from "api/charting/queries";
import {
  clonePerioChartExam,
  createPerioChartExam,
  deletePerioChartExam,
  updatePerioChartExam,
  updatePerioChartExamEntries,
  upsertPerioChartExamSettings,
} from "api/charting/mutations";
import { useBlockRouteUntil } from "hooks/useBlockRouteUntil";
import { handleError } from "utils/handleError";
import { useActiveProvider } from "components/Charting/ActiveProviderContext";
import { getPatientSummary } from "api/patients/queries";
import { paths } from "utils/routing/paths";
import { PerioChartExamRecord } from "./perioChartExamRecord";
import { PerioChartExamInfo, PerioChartExamPayload } from "./PerioTypes";

export interface PerioChartContextValue {
  patient?: PatientSummaryVO;
  currentExam?: PerioChartExamRecord;
  historicalExamInfos?: PerioChartExamInfo[];
  settings?: PerioChartExamSettingsVO;
  saveSettings: (settings: PerioChartExamSettingsVO) => Promise<unknown>;
  isSavingSettings: boolean;
  newExam: (data?: PerioChartExamPayload) => Promise<void>;
  loadExam: (recordUuid: string) => void;
  updateExamInfo: (data: UpdatePerioChartExamRequest) => Promise<void>;
  saveExam: () => Promise<void>;
  deleteExams: (examUuids: string[]) => Promise<void>;
  cloneExam: () => Promise<void>;
  printing: UseBooleanResult;
  setPrintOptions: React.Dispatch<React.SetStateAction<Set<PerioPrintOption>>>;
  printOptions: Set<PerioPrintOption>;
  isLoading: boolean;
  isError: boolean;
  examUuid?: string;
}
export const DEFAULT_PRINT_OPTIONS: Set<PerioPrintOption> = new Set([
  "perio-chart",
  "dentition-summary",
  "implant-summary",
  "notes",
]);

const Context = createContext<PerioChartContextValue>({
  printOptions: new Set(DEFAULT_PRINT_OPTIONS),
  setPrintOptions: noop,
  printing: makeInitialState(false),
  settings: undefined,
  saveSettings: asyncNoop,
  newExam: asyncNoop,
  loadExam: noop,
  updateExamInfo: asyncNoop,
  saveExam: asyncNoop,
  deleteExams: asyncNoop,
  isSavingSettings: false,
  cloneExam: asyncNoop,
  isLoading: true,
  isError: false,
});

Context.displayName = "PerioChartContext";

export const usePerioChart = () => useContext(Context);
export type PerioPrintOption = "perio-chart" | "dentition-summary" | "implant-summary" | "notes";

export const PerioChartProvider: React.FC<{
  patientId: number;
  initialUuid?: string;
  useNavigation?: boolean;
  children?: ReactNode;
  isPrinting?: boolean;
  // eslint-disable-next-line complexity
}> = ({ patientId, initialUuid, isPrinting = false, useNavigation = true, children }) => {
  const [currentExam, setCurrentExam] = useState<PerioChartExamRecord | undefined>();
  const [printOptions, setPrintOptions] = useState(DEFAULT_PRINT_OPTIONS);
  const printing = useBoolean(isPrinting);
  const [perioChartExamUuid, setPerioChartExamUuid] = useState<string | undefined>(initialUuid);
  const queryClient = useQueryClient();
  const { practiceId } = useAccount();
  const saveRef = useRef<Promise<ApiResponse<PerioChartExamEntryVO[]>> | undefined>(undefined);
  const navigate = useNavigate();
  const { providerId, providers } = useActiveProvider();

  const examProviderId = currentExam?.getExamInfo()?.provider.id;

  const [patientQuery, settingsQuery, examsHistoryQuery, examQuery] = useApiQueries([
    getPatientSummary({ args: { patientId, practiceId } }),
    getPerioChartExamSettings({
      args: { practiceId, providerId: examProviderId as number },
      queryOptions: {
        enabled: Boolean(examProviderId),
      },
    }),
    getPerioChartExams({ args: { practiceId, patientId } }),
    getPerioChartExam({
      args: {
        practiceId,
        patientId,
        perioChartExamUuid: perioChartExamUuid as string,
      },
      queryOptions: {
        enabled: Boolean(perioChartExamUuid),
      },
    }),
  ]);

  const loadUuid = useCallback(
    (uuid: string, options?: { replace?: boolean }) => {
      if (useNavigation) {
        setCurrentExam(undefined);

        if (options?.replace) {
          navigate(paths.perio({ patientId }, { uuid }), { replace: true });
        } else {
          navigate(paths.perio({ patientId }, { uuid }));
        }
      } else {
        setPerioChartExamUuid(uuid);
      }
    },
    [navigate, patientId, useNavigation]
  );

  const [
    clonePerioCharExamMutation,
    createPerioChartExamMutation,
    deletePerioChartExamMutation,
    updatePerioChartExamMutation,
    updatePerioChartExamEntriesMutation,
    upsertPerioChartExamSettingsMutation,
  ] = useApiMutations([
    clonePerioChartExam,
    createPerioChartExam,
    deletePerioChartExam,
    updatePerioChartExam,
    updatePerioChartExamEntries,
    upsertPerioChartExamSettings,
  ]);

  const isLoading =
    patientQuery.isLoading ||
    settingsQuery.isLoading ||
    examsHistoryQuery.isLoading ||
    examQuery.isLoading ||
    createPerioChartExamMutation.isLoading ||
    deletePerioChartExamMutation.isLoading ||
    (currentExam == null && !printOptions);

  const isError =
    patientQuery.isError ||
    settingsQuery.isError ||
    examsHistoryQuery.isError ||
    examQuery.isError ||
    createPerioChartExamMutation.isError;

  const saveSettings = useCallback(
    (newSettings: PerioChartExamSettingsVO) => {
      if (examProviderId == null) {
        return Promise.resolve();
      }

      return upsertPerioChartExamSettingsMutation.mutateAsync({
        practiceId,
        providerId: examProviderId,
        data: newSettings,
      });
    },
    [practiceId, examProviderId, upsertPerioChartExamSettingsMutation]
  );

  const saveExam = useCallback(async () => {
    if (printing.isOn) {
      return;
    }

    const entries = currentExam?.getChangedEntries();

    if (!entries || entries.length === 0 || !perioChartExamUuid) {
      return;
    }

    // Ensure any inflight save finishes first.
    if (saveRef.current) {
      await saveRef.current;
    }

    const savePromise = updatePerioChartExamEntriesMutation.mutateAsync(
      {
        practiceId,
        patientId,
        perioChartExamUuid,
        data: {
          entries: entries.map((entry) => entry.getRecord()),
        },
      },
      {
        onSuccess: ({ data: { data: savedEntries } }) => {
          savedEntries.forEach((savedEntry) => {
            const existingEntry = entries.find(
              (changedEntry) =>
                changedEntry.toothNum === savedEntry.toothNum &&
                changedEntry.perioSequenceType === savedEntry.perioSequenceType
            );

            if (existingEntry) {
              // Get existing values with potential changes since save.
              const currentValues = Object.entries(existingEntry.getRecord());

              // Set the new record from the backend.
              existingEntry.setData(savedEntry);

              // Replay any changes since last save.
              currentValues.forEach(([key, newValue]) => {
                existingEntry.setValue(key as keyof PerioChartExamEntryVO, newValue);
              });
            }
          });
        },
      }
    );

    saveRef.current = savePromise;

    try {
      await savePromise;
    } finally {
      saveRef.current = undefined;
    }
  }, [
    currentExam,
    patientId,
    perioChartExamUuid,
    practiceId,
    printing.isOn,
    updatePerioChartExamEntriesMutation,
  ]);

  const newExam = useCallback(
    async (data?: PerioChartExamPayload) => {
      if (printing.isOn) {
        return;
      }

      if (!data) {
        if (!providers) {
          return; // Providers haven't been loaded yet.
        }

        let providerIdToUse = providerId;

        if (providerIdToUse === undefined) {
          providerIdToUse = providers[0].id;
        }

        data = {
          providerId: providerIdToUse,
          date: formatAsISODate(new Date()),
        };
      }

      await saveExam();

      const response = await createPerioChartExamMutation.mutateAsync({ practiceId, patientId, data });
      const record = response.data.data;

      loadUuid(record.uuid);
    },
    [
      createPerioChartExamMutation,
      loadUuid,
      patientId,
      practiceId,
      printing.isOn,
      providerId,
      providers,
      saveExam,
    ]
  );

  const safeNewExam = useCallback(
    (data?: PerioChartExamPayload) => {
      newExam(data).catch(handleError);
    },
    [newExam]
  );

  const deleteExams = useCallback(
    async (examUuids: string[]) => {
      try {
        await Promise.all(
          examUuids.map((uuid) =>
            deletePerioChartExamMutation.mutateAsync({
              practiceId,
              patientId,
              perioChartExamUuid: uuid,
            })
          )
        );

        const exams = new Set(examUuids);

        if (exams.has(currentExam?.getExamInfo().uuid ?? "")) {
          const newUuid = examsHistoryQuery.data?.find((historyExam) => !exams.has(historyExam.uuid))?.uuid;

          if (newUuid) {
            loadUuid(newUuid, { replace: true });
          } else {
            await newExam();
          }
        }
      } catch (err) {
        handleError(err);
      }
    },
    [
      currentExam,
      deletePerioChartExamMutation,
      examsHistoryQuery.data,
      loadUuid,
      newExam,
      patientId,
      practiceId,
    ]
  );

  const cloneExam = useCallback(async () => {
    if (!perioChartExamUuid) {
      return;
    }

    await saveExam();

    const response = await clonePerioCharExamMutation.mutateAsync({
      practiceId,
      patientId,
      perioChartExamUuid,
    });

    const record = response.data.data;

    loadUuid(record.uuid);
  }, [clonePerioCharExamMutation, loadUuid, saveExam, patientId, perioChartExamUuid, practiceId]);

  const updateExamInfo = useCallback(
    async (data: UpdatePerioChartExamRequest) => {
      if (!perioChartExamUuid) {
        throw new Error("Perio chart has not been loaded with an exam.");
      }

      await saveExam();
      await updatePerioChartExamMutation.mutateAsync({
        practiceId,
        patientId,
        perioChartExamUuid,
        data,
      });
    },
    [patientId, perioChartExamUuid, practiceId, saveExam, updatePerioChartExamMutation]
  );

  const loadExam = useCallback(
    async (examUuid: string) => {
      try {
        await saveExam();
        loadUuid(examUuid);
      } catch (err) {
        handleError(err);
      }
    },
    [loadUuid, saveExam]
  );

  useEffect(() => {
    if (initialUuid) {
      setPerioChartExamUuid(initialUuid);
    }
  }, [initialUuid]);

  useEffect(() => {
    if (examQuery.data) {
      setCurrentExam(new PerioChartExamRecord(examQuery.data));
    }
  }, [examQuery.data]);

  useEffect(() => {
    // Setup initial exam if there is one, and put it in the cache for immediate fetch.
    if (!perioChartExamUuid && examsHistoryQuery.data && !currentExam && providers) {
      if (examsHistoryQuery.data.length > 0) {
        // Exams request returns the most recent exam, so keep the getPerioChartExam up to date with it.
        const firstExam = examsHistoryQuery.data[0];

        updateCachedData<PerioChartExamVO>(
          queryClient,
          {
            queryKey: [
              getQueryKey("practices", "updatePerioChartExam"),
              { practiceId, patientId, perioChartExamUuid: firstExam.uuid },
            ],
            exact: true,
          },
          () => firstExam
        );
        setPerioChartExamUuid(firstExam.uuid);
      } else {
        safeNewExam();
      }
    }

    // Since this creates a record when there are no exams, if we had
    // more dependencies, then it would create too many records.
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [examsHistoryQuery.data, providers]);

  // Block navigations until the modifications are saved.
  const handleSaveBeforeLeaving = useCallback(() => {
    return saveExam().catch((err) => {
      handleError(err);
      throw err;
    });
  }, [saveExam]);

  useBlockRouteUntil(handleSaveBeforeLeaving);

  const value = useMemo<PerioChartContextValue>(
    () => ({
      patient: patientQuery.data,
      currentExam,
      historicalExamInfos: examsHistoryQuery.data,
      settings: settingsQuery.data,
      saveSettings,
      newExam,
      loadExam,
      updateExamInfo,
      saveExam,
      isSavingSettings: upsertPerioChartExamSettingsMutation.isLoading,
      deleteExams,
      cloneExam,
      isLoading,
      isError,
      printOptions,
      printing,
      setPrintOptions,
      examUuid: perioChartExamUuid,
    }),
    [
      patientQuery.data,
      currentExam,
      examsHistoryQuery.data,
      settingsQuery.data,
      saveSettings,
      printing,
      newExam,
      loadExam,
      updateExamInfo,
      saveExam,
      upsertPerioChartExamSettingsMutation.isLoading,
      deleteExams,
      cloneExam,
      isLoading,
      isError,
      printOptions,
      perioChartExamUuid,
    ]
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
