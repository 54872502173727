import { QueryClient } from "@tanstack/react-query";
import { PatientInsuranceResponse } from "@libs/api/generated-api";
import { updateCachedData } from "@libs/utils/queryCache";
import { getQueryKey } from "@libs/utils/queries";
import { invalidateProceduresAndTreatmentPlan } from "api/charting/mutations";

export const invalidateInsurancesRelatedCaches = (
  queryClient: QueryClient,
  { practiceId, patientId }: { practiceId: number; patientId: number }
) => {
  queryClient.invalidateQueries([
    getQueryKey("practices", "getPatientInsurances"),
    {
      practiceId,
      patientId,
    },
  ]);
  invalidateProceduresAndTreatmentPlan(queryClient, { practiceId, patientId });
  queryClient.invalidateQueries([getQueryKey("v2", "getFamilyMembersV2")]);
  queryClient.invalidateQueries([getQueryKey("practices", "getDailyHuddle"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getClaimsByAppointment"), { practiceId }]);
  queryClient.invalidateQueries([getQueryKey("practices", "getPatientInsurance"), { practiceId, patientId }]);
};

/**
 * updateInsuranceCacheWithRelatedItem updates the cached insurances with the partial set of data that has been updated. This is because insurances can be fetched with a lot of auxillary data attached (benefit coverages, plans...etc), and their presence in the response is controlled by params in the request.
 * @param queryClient The QueryClient in our app
 * @param requestParams The request params for the cache entry you want updated. If only { practiceId, patientId, insuranceId } are passed, then all cached insurances will be updated, regardless of their params (due to exact=false)
 * @param key The key under PatientInsuranceResponse that param value will update
 * @param value The new value to populate in the cache
 * @returns void
 */
export const updateInsuranceCacheWithRelatedItem = <T extends keyof PatientInsuranceResponse>(
  queryClient: QueryClient,
  requestParams: {
    practiceId: number;
    patientId: number;
    insuranceId: number;
    includeBenefitCoverage?: boolean;
    includeBenefitLimitation?: boolean;
    includeInsurancePlan?: boolean;
  },
  key: T,
  value: PatientInsuranceResponse[T]
) => {
  updateCachedData<PatientInsuranceResponse>(
    queryClient,
    {
      queryKey: [getQueryKey("practices", "getPatientInsurance"), requestParams],
      exact: false,
    },
    (data) => {
      return {
        ...data,
        [key]: value,
      };
    }
  );

  const { insuranceId, ...paramsWithoutInsuranceId } = requestParams;

  updateCachedData<PatientInsuranceResponse[]>(
    queryClient,
    {
      queryKey: [getQueryKey("practices", "getPatientInsurances"), paramsWithoutInsuranceId],
      exact: false,
    },
    (data) => {
      return data.map((item) => {
        if (item.patientInsurance.id === insuranceId) {
          return { ...item, [key]: value };
        }

        return item;
      });
    }
  );
};
