import { EmploymentDetailsVO, CompensationDetailsVO } from "@libs/api/generated-api";

export const EmploymentStatusOptions: { label: string; value: NonNullable<EmploymentDetailsVO["status"]> }[] =
  [
    {
      label: "Active",
      value: "ACTIVE",
    },
    {
      label: "Inactive",
      value: "INACTIVE",
    },
    {
      label: "Pending",
      value: "PENDING",
    },
    {
      label: "Furloughed",
      value: "FURLOUGHED",
    },
    {
      label: "Terminated",
      value: "ARCHIVED",
    },
  ];

export const EmployeeCategoryOptions: SelectOption<NonNullable<EmploymentDetailsVO["employeeCategory"]>>[] = [
  { label: "W2 - Employee", value: "W2_EMPLOYEE" },
  { label: "1099 - Independent", value: "_1099_INDEPENDENT", isDisabled: true },
];

export const EmploymentTypeOptions: SelectOption<NonNullable<EmploymentDetailsVO["employmentType"]>>[] = [
  { label: "Full-Time", value: "FULL_TIME" },
  { label: "Part-Time", value: "PART_TIME" },
  { label: "Temporary", value: "TEMPORARY" },
];

export const CompensationPeriodOptions: SelectOption<Required<CompensationDetailsVO>["period"]>[] = [
  { label: "Per Hour", value: "PER_HOUR" },
  { label: "Per Diem", value: "PER_DIEM" },
  { label: "Per Month", value: "PER_MONTH" },
  { label: "Per Year", value: "PER_YEAR" },
];
