import { produce } from "immer";

import { PatientListCriteria, InsuranceCarrierVO } from "@libs/api/generated-api";
import { isDefined, isNullish } from "@libs/utils/types";
import { lessOrEqualTo } from "@libs/utils/validators";
import { formatISODate } from "@libs/utils/date";
import { sentenceCaseConstant, titleCaseConstant } from "@libs/utils/casing";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { formatNumberWithCommas } from "@libs/utils/formatNumber";
import { FilterInput, toFilterComponentsProps } from "@libs/components/UI/queryFilterPillsUtils";

import { flattenKeys } from "@libs/utils/object";
import { formatLocalizedMonthDay } from "components/UI/FormFieldMonthDayInput";

import { PatientCriteriaStatus, PatientCriteriaGender } from "api/patients/queries";

import { PatientsQuery } from "utils/routing/patient";

const NON_FILTER_KEYS = new Set(["maxPageSize", "pageNumber", "pageSize", "sortColumn", "orderBy"]);

export const STATUS_LABELS: Record<PatientCriteriaStatus, string> = {
  ACTIVE: "Active",
  ARCHIVED: "Archived",
  DECEASED: "Deceased",
  INACTIVE: "Inactive",
  NONPATIENT: "Non Patient",
  PROSPECT: "Prospect",
};

export const STATUS_OPTIONS: OptionInputOption<PatientCriteriaStatus>[] = [
  {
    label: "Active",
    value: "ACTIVE",
  },
  {
    label: "Archived",
    value: "ARCHIVED",
  },
  {
    label: "Deceased",
    value: "DECEASED",
  },
  {
    label: "Inactive",
    value: "INACTIVE",
  },
  {
    label: "Non Patient",
    value: "NONPATIENT",
  },
  {
    label: "Prospect",
    value: "PROSPECT",
  },
];

export const GENDER_OPTIONS: OptionInputOption<PatientCriteriaGender>[] = [
  {
    label: "Male",
    value: "MALE",
  },
  {
    label: "Female",
    value: "FEMALE",
  },
  {
    label: "Other",
    value: "OTHER",
  },
];

export const PREFERRED_LANGUAGE_OPTIONS: OptionInputOption<string>[] = [
  { label: "English", value: "ENGLISH" },
  { label: "Arabic", value: "ARABIC" },
  { label: "Bulgarian", value: "BULGARIAN" },
  { label: "Chinese (Simplified)", value: "CHINESE_SIMPLIFIED" },
  { label: "Chinese (Traditional)", value: "CHINESE_TRADITIONAL" },
  { label: "Croatian", value: "CROATIAN" },
  { label: "Czech", value: "CZECH" },
  { label: "Danish", value: "DANISH" },
  { label: "Dutch", value: "DUTCH" },
  { label: "French", value: "FRENCH" },
  { label: "German", value: "GERMAN" },
  { label: "Hebrew", value: "HEBREW" },
  { label: "Hindi", value: "HINDI" },
  { label: "Hungarian", value: "HUNGARIAN" },
  { label: "Indonesian", value: "INDONESIAN" },
  { label: "Italian", value: "ITALIAN" },
  { label: "Japanese", value: "JAPANESE" },
  { label: "Korean", value: "KOREAN" },
  { label: "Polish", value: "POLISH" },
  { label: "Portuguese", value: "PORTUGUESE" },
  { label: "Russian", value: "RUSSIAN" },
  { label: "Spanish", value: "SPANISH" },
  { label: "Swedish", value: "SWEDISH" },
  { label: "Thai", value: "THAI" },
  { label: "Turkish", value: "TURKISH" },
  { label: "Vietnamese", value: "VIETNAMESE" },
];

export const getPatientListCriteriaFilterProps = (
  patientListCriteria: PatientListCriteria,
  insuranceCarriers: InsuranceCarrierVO[]
) => {
  // PatientListCriteria (for MessageCampaignVO and UpdateMessageCampaignRequest
  // filter) is the unflattened equivalent of PatientsQuery (for URL query
  // params used for getPatients query), but we must flatten its keys and cast
  // it as PatientsQuery in order to use toFilterComponentsProps
  const params = flattenKeys(patientListCriteria as unknown as FilterInput) as PatientsQuery;
  const insuranceCarriersMap = {} as Record<number, InsuranceCarrierVO>;

  for (const carrier of insuranceCarriers) {
    insuranceCarriersMap[carrier.id] = carrier;
  }

  return toFilterComponentsProps(params, [
    {
      type: "list",
      prop: "patientCriteria.status",
      format: (val) => sentenceCaseConstant(val),
    },
    {
      type: "default",
      prop: "appointmentWithinDays",
      format: (val) => `Appointment within ${val} Days`,
    },
    {
      type: "combined",
      props: ["appointmentDateRange.min", "appointmentDateRange.max"],
      format: (val) => {
        const min = val["appointmentDateRange.min"];
        const max = val["appointmentDateRange.max"];

        return isDefined(min) && isDefined(max)
          ? min === max
            ? `Appointment ${formatISODate(min)}`
            : `Appointment ${formatISODate(min)} - ${formatISODate(max)}`
          : "";
      },
    },
    {
      type: "default",
      prop: "hasNoAppointment",
      format: (val) => (val === true ? "No Appointment" : ""),
    },
    {
      type: "combined",
      props: ["patientCriteria.ageGroup.min", "patientCriteria.ageGroup.max"],
      format: (val) => {
        const min = val["patientCriteria.ageGroup.min"];
        const max = val["patientCriteria.ageGroup.max"];

        return isDefined(min) && isDefined(max)
          ? min === max
            ? `${min} Years Old`
            : `${min} - ${max} Years Old`
          : isDefined(min)
            ? `${min} Years Old and Above`
            : isDefined(max)
              ? `${max} Years Old and Under`
              : "";
      },
    },
    {
      type: "list",
      prop: "patientCriteria.genders",
      format: (val) => sentenceCaseConstant(val),
    },
    {
      type: "default",
      prop: "patientCriteria.birthdayWithinDays",
      format: (val) => `Birthday within ${val} Days`,
    },
    {
      type: "combined",
      props: ["patientCriteria.birthdayRange.min", "patientCriteria.birthdayRange.max"],
      format: (val) => {
        const min = val["patientCriteria.birthdayRange.min"];
        const max = val["patientCriteria.birthdayRange.max"];

        return isDefined(min) && isDefined(max)
          ? min === max
            ? `Birthday ${formatLocalizedMonthDay(min)}`
            : `Birthday ${formatLocalizedMonthDay(min)} - ${formatLocalizedMonthDay(max)}`
          : "";
      },
    },
    {
      type: "list",
      prop: "patientCriteria.carrierIds",
      format: (val) => insuranceCarriersMap[val].name,
    },
    {
      type: "combined",
      props: ["patientCriteria.patientBalanceRange.min", "patientCriteria.patientBalanceRange.max"],
      format: (val) => {
        const min = val["patientCriteria.patientBalanceRange.min"];
        const max = val["patientCriteria.patientBalanceRange.max"];

        return isDefined(min) && isDefined(max)
          ? min === max
            ? `Patient Balance $${formatNumberWithCommas(min)}`
            : `Patient Balance $${formatNumberWithCommas(min)} - $${formatNumberWithCommas(max)}`
          : isDefined(min)
            ? `Patient Balance Min $${formatNumberWithCommas(min)}`
            : isDefined(max)
              ? `Patient Balance Max $${formatNumberWithCommas(max)}`
              : "";
      },
    },
    {
      type: "combined",
      props: ["patientCriteria.insuranceBalanceRange.min", "patientCriteria.insuranceBalanceRange.max"],
      format: (val) => {
        const min = val["patientCriteria.insuranceBalanceRange.min"];
        const max = val["patientCriteria.insuranceBalanceRange.max"];

        return isDefined(min) && isDefined(max)
          ? min === max
            ? `Insurance Balance $${formatNumberWithCommas(min)}`
            : `Insurance Balance $${formatNumberWithCommas(min)} - $${formatNumberWithCommas(max)}`
          : isDefined(min)
            ? `Insurance Balance Min $${formatNumberWithCommas(min)}`
            : isDefined(max)
              ? `Insurance Balance Max $${formatNumberWithCommas(max)}`
              : "";
      },
    },
    {
      type: "combined",
      props: [
        "patientCriteria.remainingBenefitAmountRange.min",
        "patientCriteria.remainingBenefitAmountRange.max",
      ],
      format: (val) => {
        const min = val["patientCriteria.remainingBenefitAmountRange.min"];
        const max = val["patientCriteria.remainingBenefitAmountRange.max"];

        return isDefined(min) && isDefined(max)
          ? min === max
            ? `Remaining Benefit Amount $${formatNumberWithCommas(min)}`
            : `Remaining Benefit Amount $${formatNumberWithCommas(min)} - $${formatNumberWithCommas(max)}`
          : isDefined(min)
            ? `Remaining Benefit Amount Min $${formatNumberWithCommas(min)}`
            : isDefined(max)
              ? `Remaining Benefit Amount Max $${formatNumberWithCommas(max)}`
              : "";
      },
    },
    {
      type: "list",
      prop: "patientCriteria.preferredLanguages",
      format: (val) => titleCaseConstant(val),
    },
    {
      type: "default",
      prop: "patientCriteria.hasPreferredLanguage",
      format: (val) => (val === false ? "No Preferred Language" : ""),
    },
  ]);
};

export const hasEmptyFilters = (patientListCriteria: PatientListCriteria | undefined) => {
  // In order to determine whether patientListCriteria truly contains no
  // applicable filters, we filter out the undefined values and check if the
  // remaining keys are all non-filter keys
  return patientListCriteria
    ? Object.keys(patientListCriteria)
        .filter((key) => isDefined(patientListCriteria[key as keyof PatientListCriteria]))
        .every((key) => NON_FILTER_KEYS.has(key))
    : true;
};

export const getSanitizedPatientListCriteria = (patientListCriteria: PatientListCriteria) => {
  // Remove patientCriteria from patientListCriteria if it is empty, in order to
  // maintain ability to utilize hasEmptyFilters for PatientListCriteria
  return produce(patientListCriteria, (draft) => {
    if (draft.patientCriteria && Object.keys(draft.patientCriteria).length === 0) {
      delete draft.patientCriteria;
    }
  });
};

export const getPatientListCriteriaSchema = (patientListCriteria: PatientListCriteria) => ({
  patientCriteria: {
    ageGroup: {
      min: [
        {
          $v: lessOrEqualTo(patientListCriteria.patientCriteria?.ageGroup?.max ?? 0),
          $error: "Min must be less than max age",
          $ignore:
            isNullish(patientListCriteria.patientCriteria?.ageGroup?.min) ||
            isNullish(patientListCriteria.patientCriteria.ageGroup.max),
        },
      ],
    },
    patientBalanceRange: {
      min: [
        {
          $v: lessOrEqualTo(patientListCriteria.patientCriteria?.patientBalanceRange?.max ?? 0),
          $error: "Min must be less than max balance",
          $ignore:
            isNullish(patientListCriteria.patientCriteria?.patientBalanceRange?.min) ||
            isNullish(patientListCriteria.patientCriteria.patientBalanceRange.max),
        },
      ],
    },
    insuranceBalanceRange: {
      min: [
        {
          $v: lessOrEqualTo(patientListCriteria.patientCriteria?.insuranceBalanceRange?.max ?? 0),
          $error: "Min must be less than max balance",
          $ignore:
            isNullish(patientListCriteria.patientCriteria?.insuranceBalanceRange?.min) ||
            isNullish(patientListCriteria.patientCriteria.insuranceBalanceRange.max),
        },
      ],
    },
    remainingBenefitAmountRange: {
      min: [
        {
          $v: lessOrEqualTo(patientListCriteria.patientCriteria?.remainingBenefitAmountRange?.max ?? 0),
          $error: "Min must be less than max amount",
          $ignore:
            isNullish(patientListCriteria.patientCriteria?.remainingBenefitAmountRange?.min) ||
            isNullish(patientListCriteria.patientCriteria.remainingBenefitAmountRange.max),
        },
      ],
    },
  },
});
