import { InsurancePlanVO, NameVO, PatientInsuranceVO } from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { formatISODate } from "@libs/utils/date";
import { Icon, IconThemes } from "@libs/components/UI/Icon";
import { ReactComponent as UnverifiedIcon } from "@libs/assets/icons/unverified.svg";
import { ReactComponent as VerifiedIcon } from "@libs/assets/icons/verified-badge.svg";
import { Button } from "@libs/components/UI/Button";
import { FormFieldLabel } from "@libs/components/UI/FormFieldLabel";
import { Banner } from "@libs/components/UI/Banner";
import { Section } from "components/Insurance/Section";
import { planCxStyles } from "components/PatientProfile/Insurance/InsuranceDetailsRoute/Shared/styles";
import {
  isAutoVerified,
  isVerified,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/SubscriberTab/utils";

interface Props {
  canVerify: boolean;
  insurancePlan: InsurancePlanVO | undefined;
  onOpenVerifyModal: Func;
  onOpenUnverifyModal: Func;
  patientInsurance: PatientInsuranceVO | undefined;
  verificationError: string | undefined;
}

export type VerificationStatus = InsurancePlanVO["benefitVerifiedStatus"];

const verificationThemeByType: Record<NonNullable<VerificationStatus>, IconThemes> = {
  AUTO_VERIFIED: "primary",
  FAILED: "error",
  NOT_VERIFIED: "error",
  VERIFIED: "success",
};

const VerificationField: React.FC<{
  label: string;
  verificationAt: string | undefined;
  verificationBy: NameVO | undefined;
  verificationStatus: VerificationStatus;
  // eslint-disable-next-line complexity
}> = ({ label, verificationAt, verificationBy, verificationStatus }) => {
  return (
    <div className="flex flex-col gap-y-1 text-xs">
      <FormFieldLabel className="font-sansSemiBold" content={label} />
      <div className="flex items-center gap-x-1">
        <Icon
          SvgIcon={
            verificationStatus
              ? isOneOf(verificationStatus, ["VERIFIED", "AUTO_VERIFIED"])
                ? VerifiedIcon
                : UnverifiedIcon
              : UnverifiedIcon
          }
          theme={
            verificationStatus === "AUTO_VERIFIED"
              ? "success"
              : verificationStatus
                ? verificationThemeByType[verificationStatus]
                : "error"
          }
        />
        {verificationStatus === "VERIFIED"
          ? `Verified${
              verificationBy?.firstName ? ` by ${verificationBy.firstName} ${verificationBy.lastName}` : ""
            }${verificationAt ? ` on ${formatISODate(verificationAt)}` : ""}`
          : verificationStatus === "AUTO_VERIFIED"
            ? `Verified by Auto-Verify${verificationAt ? ` on ${formatISODate(verificationAt)}` : ""}`
            : "Unverified"}
      </div>
    </div>
  );
};

// eslint-disable-next-line complexity
export const VerificationSection: React.FC<Props> = ({
  canVerify,
  insurancePlan,
  onOpenVerifyModal,
  onOpenUnverifyModal,
  patientInsurance,
  verificationError,
}) => {
  return (
    <Section
      className={planCxStyles.section}
      includePadding={false}
      title="Verification"
      useCustomStyling={true}
    >
      <div className={planCxStyles.fieldsContainer}>
        {verificationError && (
          <Banner className="col-span-2 text-xs rounded" includeIcon theme="error">
            {`Verification Failed: ${verificationError}`}
          </Banner>
        )}
        <VerificationField
          label="Eligibility"
          verificationAt={patientInsurance?.eligibilityLastVerifiedAt}
          verificationBy={patientInsurance?.eligibilityLastVerifiedBy}
          verificationStatus={patientInsurance?.eligibilityVerifiedStatus}
        />
        <VerificationField
          label="Coverage"
          verificationAt={insurancePlan?.benefitLastVerifiedAt}
          verificationBy={insurancePlan?.benefitLastVerifiedBy}
          verificationStatus={insurancePlan?.benefitVerifiedStatus}
        />
        <div className="flex gap-x-2 col-span-2">
          <Button className="min-w-button" disabled={!canVerify} onClick={onOpenVerifyModal} theme="primary">
            Verify
          </Button>
          <Button
            className="min-w-button"
            disabled={
              !isVerified(patientInsurance?.eligibilityVerifiedStatus) &&
              !isVerified(insurancePlan?.benefitVerifiedStatus) &&
              !isAutoVerified(patientInsurance?.eligibilityVerifiedStatus) &&
              !isAutoVerified(insurancePlan?.benefitVerifiedStatus)
            }
            onClick={onOpenUnverifyModal}
            theme="link"
          >
            Unverify
          </Button>
        </div>
      </div>
    </Section>
  );
};
