import { ProviderVO, ClaimInsuranceCarrierVO } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { FormFieldMenu } from "@libs/components/UI/FormFieldMenu";
import { FilterMenuPaymentStatus } from "components/Claims/Claims/FilterMenuPaymentStatus";
import { FilterMenuInsuranceCarriers } from "components/Claims/Claims/FilterMenuInsuranceCarriers";
import { FilterMenuProviders } from "components/Claims/Claims/FilterMenuProviders";
import { filtersCxStyles } from "components/Claims/Claims/Filters";
import { ClaimsQueryUpdates, ClaimsQuery } from "utils/routing/claims";
import { FilterMenuInsuranceOrders } from "components/Claims/Claims/FilterMenuInsuranceOrders";

interface Props {
  billingProvidersQuery: ApiQueryResult<ProviderVO[]>;
  insuranceCarriersQuery: ApiQueryResult<ClaimInsuranceCarrierVO[]>;
  isClaimsDomain: boolean;
  onFiltersUpdate: (keyValues: ClaimsQueryUpdates) => void;
  practiceProvidersQuery: ApiQueryResult<ProviderVO[]>;
  queryStateApi: ClaimsQuery;
  showStatusMenu: boolean;
}

export const FilterMenus: React.FC<Props> = ({
  billingProvidersQuery,
  insuranceCarriersQuery,
  isClaimsDomain,
  onFiltersUpdate,
  practiceProvidersQuery,
  queryStateApi,
  showStatusMenu,
}) => {
  return (
    <div className="flex gap-x-3 relative z-10">
      <FormFieldMenu
        className={filtersCxStyles.button}
        buttonContent="Insurance Order"
        menuContent={(insuranceOrdersMenu) => (
          <FilterMenuInsuranceOrders
            onFiltersUpdate={onFiltersUpdate}
            onRequestClose={insuranceOrdersMenu.off}
            queryStateApi={queryStateApi}
          />
        )}
        matchTriggerWidth
      />
      <FormFieldMenu
        className={filtersCxStyles.button}
        buttonContent="Insurance"
        menuContent={(carriersMenu) => (
          <FilterMenuInsuranceCarriers
            insuranceCarriers={insuranceCarriersQuery.data}
            onFiltersUpdate={onFiltersUpdate}
            onRequestClose={carriersMenu.off}
            queryStateApi={queryStateApi}
          />
        )}
        matchTriggerWidth
      />
      {isClaimsDomain && (
        <FormFieldMenu
          className={filtersCxStyles.button}
          buttonContent="Provider"
          menuContent={(providersMenu) => (
            <FilterMenuProviders
              billingProviders={billingProvidersQuery.data}
              onFiltersUpdate={onFiltersUpdate}
              onRequestClose={providersMenu.off}
              queryStateApi={queryStateApi}
              treatingProviders={practiceProvidersQuery.data?.filter(
                (provider) => provider.jobCategory === "DENTIST"
              )}
            />
          )}
          matchTriggerWidth
        />
      )}
      {showStatusMenu && (
        <FormFieldMenu
          className={filtersCxStyles.button}
          buttonContent="Status"
          menuContent={(statusMenu) => (
            <FilterMenuPaymentStatus
              onFiltersUpdate={onFiltersUpdate}
              onRequestClose={statusMenu.off}
              queryStateApi={queryStateApi}
            />
          )}
          matchTriggerWidth
        />
      )}
    </div>
  );
};
