import { useMemo } from "react";
import { osName } from "react-device-detect";
import { cx } from "@libs/utils/cx";

interface Props {
  attachmentType: "eob" | "image" | "perio" | "other";
  instructionsClassName?: string | undefined;
}

const EOB_UPLOAD_INSTRUCTIONS: Record<string, string[]> = {
  Default: [
    "Open the EOB image and find the information you wish to upload to the claim.",
    "Copy a screenshot of the desired area of the EOB.",
    "Close the image and paste your clipping into the provided pasting area.",
  ],
  // eslint-disable-next-line @typescript-eslint/naming-convention
  "Mac OS": [
    "Open the EOB image and find the information you wish to upload to the claim.",
    "Press Command + Control + Shift + 4 and drag a selection box on the desired area of the EOB.",
    "Close the image and paste your clipping (Command + V) into the provided pasting area.",
  ],
  Windows: [
    "Open the EOB image and find the information you wish to upload to the claim.",
    "Press Windows + Shift + S and drag a selection box on the desired area of the EOB.",
    "Close the image and paste your clipping  (Ctrl + V) into the provided pasting area.",
  ],
};

export const Instructions: React.FC<Props> = ({ attachmentType, instructionsClassName }) => {
  const type = attachmentType === "image" ? "Image" : attachmentType === "perio" ? "Perio" : "Document";

  const instructions = useMemo(() => {
    if (attachmentType === "eob") {
      return EOB_UPLOAD_INSTRUCTIONS[osName];
    }

    return [
      `Open the external ${type} you want to use as an attachment.`,
      osName === "Mac OS"
        ? `Press Command + Control + Shift + 4 and drag a selection box on the desired area of the ${type}.`
        : osName === "Windows"
          ? `Press Windows + Shift + S and drag a selection box on the desired area of the ${type}.`
          : `Copy a screenshot of the desired area of the ${type}.`,
      osName === "Mac OS"
        ? "Paste your clipping (Command + V) into the provided pasting area."
        : osName === "Windows"
          ? "Paste your clipping (Ctrl + V) into the provided pasting area."
          : "Paste your clipping into the provided pasting area.",
    ];
  }, [attachmentType, type]);

  return (
    <div className={cx("flex flex-col gap-y-3 text-greyDark", instructionsClassName)}>
      <div className="text-sm font-sansSemiBold">Instructions</div>
      <ul className="text-xs font-sans">
        {instructions.map((instruction, index) => (
          <li className="list-disc list-inside" key={index}>
            {instruction}
          </li>
        ))}
      </ul>
    </div>
  );
};
