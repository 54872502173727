import { ComponentPropsWithRef, FC } from "react";
import { InvoiceVO } from "@libs/api/generated-api";
import { Pill } from "@libs/components/UI/Pill";

export const InvoiceStatePill: FC<{ invoiceState: InvoiceVO["state"]; invoiceAmount: number }> = ({
  invoiceState,
  invoiceAmount,
}) => {
  const label =
    invoiceAmount < 0 ? invoiceStateToRefundedLabel[invoiceState] : invoiceStateToLabel[invoiceState];

  return <Pill theme={invoiceStateToPillColor[invoiceState]}>{label}</Pill>;
};

const invoiceStateToPillColor: Record<InvoiceVO["state"], ComponentPropsWithRef<typeof Pill>["theme"]> = {
  FINALIZED: "red",
  VOID: "white",
  PARTIALLY_PAID: "orange",
  PAID: "green",
};
const invoiceStateToLabel: Record<InvoiceVO["state"], string> = {
  FINALIZED: "Unpaid",
  PAID: "Paid",
  PARTIALLY_PAID: "Partially Paid",
  VOID: "Void",
};
const invoiceStateToRefundedLabel: Record<InvoiceVO["state"], string> = {
  FINALIZED: "Not Refunded",
  PAID: "Refunded",
  PARTIALLY_PAID: "Partially Refunded",
  VOID: "Void",
};
