import { FC } from "react";

import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as SendIcon } from "@libs/assets/icons/send.svg";

import { RoleGuardClick } from "components/Main/RoleGuard";

interface Props {
  onRequestMessagePatients: Func;
  disabled: boolean;
}

export const MessagePatientsButton: FC<Props> = ({ onRequestMessagePatients, disabled }) => {
  return (
    <RoleGuardClick domain="COMMUNICATION" action="BULK_MESSAGE">
      <ButtonIcon
        SvgIcon={SendIcon}
        tooltip={{ content: "Message Patients", theme: "SMALL" }}
        onClick={onRequestMessagePatients}
        theme="slate700"
        disabled={disabled}
      />
    </RoleGuardClick>
  );
};
