import { FC } from "react";

import { PatientSummaryVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { sentenceCaseConstant, titleCaseConstant } from "@libs/utils/casing";
import { getAgeByDob } from "@libs/utils/formatString";
import { isOneOf } from "@libs/utils/isOneOf";
import { ReactComponent as VerifiedBadgeIcon } from "@libs/assets/icons/verified-badge.svg";
import { ReactComponent as UnverifiedIcon } from "@libs/assets/icons/unverified.svg";
import { Row, TextCell, EMPTY_CELL } from "@libs/components/UI/GridTableComponents";

import { STATUS_LABELS } from "components/Communications/Filters/utils";
import { isInThePast } from "components/Patients/Row";

import { useNow } from "hooks/useNow";

interface Props {
  patient: PatientSummaryVO;
}

export const CampaignGroupRow: FC<Props> = ({ patient }) => {
  const now = useNow();

  return (
    <Row className="*:flex *:items-center">
      <TextCell>
        <span className="pl-1 truncate">{patient.name.fullDisplayName}</span>
      </TextCell>
      <TextCell>
        <span className="truncate">{patient.contact.email ?? EMPTY_CELL}</span>
      </TextCell>
      <TextCell>{getAgeByDob(now, patient.dob)}</TextCell>
      <TextCell>{patient.gender ? sentenceCaseConstant(patient.gender) : EMPTY_CELL}</TextCell>
      <TextCell>{STATUS_LABELS[patient.status]}</TextCell>
      <TextCell>
        <span className="truncate">{patient.primaryInsuranceCarrier ?? EMPTY_CELL}</span>
      </TextCell>
      <TextCell>
        <div className="flex items-center gap-x-2">
          {patient.primaryInsuranceEligibilityVerifiedStatus ? (
            <>
              {isOneOf(patient.primaryInsuranceEligibilityVerifiedStatus, ["VERIFIED", "AUTO_VERIFIED"]) ? (
                <VerifiedBadgeIcon className="w-4 h-4 text-green" />
              ) : isOneOf(patient.primaryInsuranceEligibilityVerifiedStatus, ["NOT_VERIFIED", "FAILED"]) ? (
                <UnverifiedIcon className="w-4 h-4 text-red" />
              ) : null}

              {titleCaseConstant(patient.primaryInsuranceEligibilityVerifiedStatus)}
            </>
          ) : (
            EMPTY_CELL
          )}
        </div>
      </TextCell>
      <TextCell className="justify-end">
        <span className="pr-1">
          {!patient.nextAppointment || isInThePast(patient.nextAppointment)
            ? EMPTY_CELL
            : formatISODate(patient.nextAppointment)}
        </span>
      </TextCell>
    </Row>
  );
};
