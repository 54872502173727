import { FC, useCallback, useEffect } from "react";
import { DropzoneOptions, useDropzone } from "react-dropzone";

import { cx } from "@libs/utils/cx";
import {
  expandMimeTypes,
  allSupportedUploadMimeTypes,
  SupportedUploadMimeTypes,
  mimeTypesToExtensions,
} from "@libs/utils/mimeTypes";
import { ONE_MILLION } from "@libs/utils/math";
import { listToText } from "@libs/utils/formatString";
import { noop } from "@libs/utils/noop";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Button } from "@libs/components/UI/Button";

const MAX_FILE_SIZE = 30_000_000;

const cxStyles = {
  container: (isDragActive: boolean, maxFilesSelected: boolean) =>
    cx(
      `flex
       flex-col
       items-center
       gap-y-2
       py-14
       rounded
       outline-dashed
       outline-greyLighter`,
      isDragActive ? "outline-2" : "outline-1",
      maxFilesSelected ? "cursor-default text-greyLight" : "text-greyMedium"
    ),
};

export interface AttachFilesContentProps {
  allowedMimeTypes?: SupportedUploadMimeTypes[];
  allowPaste?: boolean;
  canSelectMultiple?: boolean;
  maxFilesSelected?: boolean;
  maxFileSizeByBytes?: number;
  onDropFiles: DropzoneOptions["onDrop"];
  onRequestScanDocument?: Func;
  onRequestSelectDocument?: Func;
}

const usePasteFile = ({
  allowPaste,
  onDropFiles,
}: Pick<AttachFilesContentProps, "allowPaste" | "onDropFiles">) => {
  const handlePaste = useCallback(
    (event: ClipboardEvent) => {
      if (!allowPaste) {
        return;
      }

      const dataTransferItem = event.clipboardData?.items[0];

      if (!dataTransferItem) {
        return;
      }

      const file = dataTransferItem.getAsFile();

      if (dataTransferItem.type.indexOf("image") === 0 && file) {
        onDropFiles?.([file], [], event);
      }
    },
    [allowPaste, onDropFiles]
  );

  useEffect(() => {
    document.addEventListener("paste", handlePaste);

    return () => document.removeEventListener("paste", handlePaste);
  }, [handlePaste]);
};

export const AttachFilesContent: FC<AttachFilesContentProps> = ({
  allowedMimeTypes,
  allowPaste = false,
  canSelectMultiple = false,
  maxFilesSelected = false,
  maxFileSizeByBytes,
  onDropFiles,
  onRequestScanDocument,
  onRequestSelectDocument,
}) => {
  const maxSize = maxFileSizeByBytes ?? MAX_FILE_SIZE;
  const hasScanOption = Boolean(onRequestScanDocument);
  const hasDocumentOption = Boolean(onRequestSelectDocument);
  const hasScanAndDocumentOptions = hasScanOption && hasDocumentOption;

  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    accept: expandMimeTypes(allowedMimeTypes ?? allSupportedUploadMimeTypes()),
    maxSize,
    multiple: canSelectMultiple,
    onDrop: onDropFiles,
  });

  usePasteFile({ allowPaste, onDropFiles });

  return (
    <FloatingTooltip
      content={maxFilesSelected ? "Maximum files selected" : ""}
      placement="top-end"
      theme="SMALL"
    >
      <div className={cxStyles.container(isDragActive, maxFilesSelected)} {...getRootProps()}>
        <input disabled={maxFilesSelected} {...getInputProps()} />

        <div className="text-sm">
          <AttachButton
            text="Scan Document"
            onClick={onRequestScanDocument}
            disabled={maxFilesSelected}
            stopPropagation
          />

          {hasScanAndDocumentOptions ? ", " : hasScanOption ? " or " : null}

          <AttachButton text="Upload File" onClick={noop} disabled={maxFilesSelected} />

          {hasDocumentOption ? " or " : null}

          <AttachButton
            text="Select from Archy"
            onClick={onRequestSelectDocument}
            disabled={maxFilesSelected}
            stopPropagation
          />
        </div>

        <div className="text-xs text-center px-10">
          {`You can drag & drop files here${allowedMimeTypes ? " or paste clippings" : ""}, ${
            maxSize / ONE_MILLION
          } MB maximum file size.${
            allowedMimeTypes
              ? ` File type should be ${listToText(mimeTypesToExtensions(allowedMimeTypes), "or")}.`
              : ""
          } `}
        </div>
      </div>
    </FloatingTooltip>
  );
};

const AttachButton: FC<{
  text: string;
  onClick?: Func;
  disabled?: boolean;
  stopPropagation?: boolean;
}> = ({ text, onClick, disabled = false, stopPropagation = false }) => {
  return onClick ? (
    <Button
      onClick={(e) => {
        if (stopPropagation) {
          e.stopPropagation();
        }

        onClick();
      }}
      disabled={disabled}
      theme="link"
    >
      <span className="font-sans">{text}</span>
    </Button>
  ) : null;
};
