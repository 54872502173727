import React from "react";
import { LabelSet, useLongAgingOptions } from "components/Dashboard/hooks/useLongAgingOptions";

export const useSelectedAgingOption = ({
  dateAgeIndex,
  labels,
  includeFuture,
}: {
  dateAgeIndex: number;
  labels: LabelSet[];
  includeFuture: boolean;
}) => {
  const recallOverdueOptions = useLongAgingOptions({ labels, includeFuture });

  return React.useMemo(
    () => recallOverdueOptions.find((item) => item.value === dateAgeIndex) ?? recallOverdueOptions[0],
    [dateAgeIndex, recallOverdueOptions]
  );
};
