import { FC } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { Button } from "@libs/components/UI/Button";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";

import { FormSection } from "components/Settings/Communications/FormSection";

interface Props {
  domain: string;
  isResettingDomain: boolean;
  onResetDomain: Func;
}

export const ChangeDomain: FC<Props> = ({ isResettingDomain, domain, onResetDomain }) => {
  const generateModal = useBoolean(false);

  return (
    <>
      <FormSection
        title="Change Domain"
        description={
          <>
            The above records were generated for <span className="font-sansSemiBold">{domain}</span>. If you
            need to generate new records for a different domain you can do so by clicking below.
          </>
        }
      >
        <Button size="medium" theme="dangerZone" onClick={generateModal.on}>
          Change Domain
        </Button>
      </FormSection>

      {generateModal.isOn ? (
        <ConfirmationModal
          primaryText="Generate New Records"
          secondaryText="Once you generate new records your existing records will no longer be valid. You will not be able to send emails again until the new records are validated."
          confirmText="Continue"
          isConfirming={isResettingDomain}
          onConfirm={onResetDomain}
          onCancel={generateModal.off}
          size="3xs"
        />
      ) : null}
    </>
  );
};
