import { createContext, useContext, FC, PropsWithChildren, useMemo } from "react";

export type FeatureFlags = {
  customAdjustments: boolean;
};

export const defaultFeatureFlags = {
  customAdjustments: false,
};

const Context = createContext<FeatureFlags>(defaultFeatureFlags);

Context.displayName = "FeatureFlagsContext";

export const useFeatureFlagsContext = () => useContext(Context);

export const FeatureFlagsContextProvider: FC<PropsWithChildren> = ({ children }) => {
  // Feature flags can be enabled for different environments with appEnv from
  // const { REACT_APP_ENVIRONMENT } = useEnvContext(), e.g. isOneOf(REACT_APP_ENVIRONMENT, ["development", "preprod"])
  const value = useMemo(
    () => ({
      customAdjustments: true,
    }),
    []
  );

  return <Context.Provider value={value}>{children}</Context.Provider>;
};
