// TODO this should really be a backend API as it is a lot of code to ship to the client
// and requires coordinating 3 different API calls
import { addDays, differenceInDays, endOfDay, isSameDay, parseISO, startOfDay } from "date-fns";
import { WorkingHourItemVO, PublicHolidayVO, CustomHolidayVO } from "@libs/api/generated-api";
import { getLocalDate, formatAsISODate, setTimeOnDate } from "@libs/utils/date";

export interface DateInterval {
  end: Date;
  start: Date;
}

enum WeekDays {
  SUNDAY,
  MONDAY,
  TUESDAY,
  WEDNESDAY,
  THURSDAY,
  FRIDAY,
  SATURDAY,
}

const hasNoOverlap = (startTimeA: number, endTimeA: number, startTimeB: number, endTimeB: number) =>
  startTimeA >= endTimeB || startTimeB >= endTimeA;
const doesCover = (startTimeA: number, endTimeA: number, startTimeB: number, endTimeB: number) =>
  startTimeA <= startTimeB && endTimeA >= endTimeB;

export const subIntervals = (leftIntervals: DateInterval[], rightIntervals: DateInterval[]) => {
  let remainingIntervals = leftIntervals;

  for (const rightInterval of rightIntervals) {
    const rightStartTime = rightInterval.start.getTime();
    const rightEndTime = rightInterval.end.getTime();
    const next = [];

    for (const remainingInterval of remainingIntervals) {
      const remainingStartTime = remainingInterval.start.getTime();
      const remainingEndTime = remainingInterval.end.getTime();

      // not overlapping, keep
      if (hasNoOverlap(rightStartTime, rightEndTime, remainingStartTime, remainingEndTime)) {
        next.push(remainingInterval);
        // completely overlapping, remove
      } else if (doesCover(rightStartTime, rightEndTime, remainingStartTime, remainingEndTime)) {
        continue;
      } else if (rightStartTime <= remainingStartTime && rightEndTime > remainingStartTime) {
        // subtract from beginning
        next.push({ start: rightInterval.end, end: remainingInterval.end });
      } else if (rightEndTime >= remainingEndTime && rightStartTime < remainingEndTime) {
        // subtract from end
        next.push({ start: remainingInterval.start, end: rightInterval.start });
      } else {
        next.push(
          { start: remainingInterval.start, end: rightInterval.start },
          { start: rightInterval.end, end: remainingInterval.end }
        );
      }
    }
    remainingIntervals = next;
  }

  return remainingIntervals;
};

export const workHoursToDateIntervals = (
  date: Date,
  workHours: { endTime: string; startTime: string }
): DateInterval => {
  return { start: setTimeOnDate(date, workHours.startTime), end: setTimeOnDate(date, workHours.endTime) };
};

const holidayToDateIntervals = (holiday: PublicHolidayVO) => {
  const date = parseISO(holiday.date);

  return holiday.isClosed
    ? { isClosed: true, intervals: [] }
    : {
        isClosed: false,
        intervals:
          holiday.timeRanges?.map((range) => ({
            start: setTimeOnDate(date, range.startTime),
            end: setTimeOnDate(date, range.endTime),
          })) || [],
      };
};

const customHolidayToDateIntervals = (holiday: CustomHolidayVO) => {
  const start = getLocalDate(holiday.startDate, holiday.startTime);
  const end = getLocalDate(holiday.endDate, holiday.endTime);

  if (isSameDay(start, end)) {
    return [{ start, end }];
  }

  const endOfStart = endOfDay(start);
  const startOfEnd = startOfDay(end);

  const diffDays = differenceInDays(startOfEnd, endOfStart);
  const inBetweenDays = Array.from({ length: diffDays }).map((_value, index) => {
    const betweenDay = addDays(startOfDay(start), index + 1);

    return {
      start: betweenDay,
      end: endOfDay(betweenDay),
    };
  });

  return [{ start, end: endOfStart }, ...inBetweenDays, { start: startOfEnd, end }];
};

export const workHoursToDayIndexMap = (workHours: WorkingHourItemVO[]) => {
  const map = {} as Record<number, WorkingHourItemVO[] | undefined>;

  for (const item of workHours) {
    const num = WeekDays[item.dayOfWeek];
    const exists = map[num];

    if (exists) {
      exists.push(item);
    } else {
      map[num] = [item];
    }
  }

  return map;
};

export const customHolidaysToDateIntervalsMap = (customHoliday: CustomHolidayVO[]) => {
  const map = {} as Record<string, DateInterval[]>;

  for (const item of customHoliday) {
    const intervals = customHolidayToDateIntervals(item);

    for (const interval of intervals) {
      const key = formatAsISODate(interval.start);
      const exists = map[key];

      // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
      if (exists) {
        exists.push(interval);
      } else {
        map[key] = [interval];
      }
    }
  }

  return map;
};

export const publicHolidaysToDateIntervalsMap = (publicHolidays: PublicHolidayVO[]) => {
  const map = {} as Record<string, { intervals: DateInterval[]; isClosed: boolean }>;

  for (const item of publicHolidays) {
    const { intervals, isClosed } = holidayToDateIntervals(item);
    const key = item.date;
    const exists = map[key];

    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    map[key] = exists
      ? {
          isClosed,
          intervals: [...map[key].intervals, ...intervals],
        }
      : {
          intervals,
          isClosed,
        };
  }

  return map;
};

export const hasWorkHours = (
  date: Date,
  workDayMap: Record<number, WorkingHourItemVO[] | undefined>,
  holidayMap: Record<string, { isClosed: boolean; intervals: DateInterval[] } | undefined>,
  customHolidayMap: Record<string, DateInterval[] | undefined>
) => {
  const dayOfWeek = date.getDay();
  const dayLookup = formatAsISODate(date);
  const holidayHours = holidayMap[dayLookup];
  const workHours = workDayMap[dayOfWeek];
  const customHolidayHours = customHolidayMap[dayLookup];

  if ((holidayHours && holidayHours.isClosed) || !workHours) {
    // if the office is closed for the holiday there are no work hours
    // if there is no holiday and no work hours there are no possible work hours
    return false;
  }

  // if the office is open for the holidays this overrides normal work hours
  let workIntervals =
    holidayHours?.isClosed === false
      ? holidayHours.intervals
      : workHours.map((workTime) => workHoursToDateIntervals(date, workTime));

  if (customHolidayHours) {
    workIntervals = subIntervals(workIntervals, customHolidayHours);

    // If holiday hours canceled out all work intervals there are no work hours
    if (!workIntervals.length) {
      return false;
    }
  }

  // If work intervals exist and holidays didnt cancel them out, then work hours exist
  return true;
};
