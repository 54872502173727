import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as UserIcon } from "@libs/assets/icons/user.svg";

interface Props {
  displayName: string;
}

export const PersonTag: React.FC<Props> = ({ displayName }) => {
  return (
    <div
      className={`
        flex
        items-center
        p-1
        gap-x-1
        w-fit
        text-xs
        text-slate-900
        rounded
        bg-slate-200
      `}
    >
      <Icon SvgIcon={UserIcon} size="sm" />
      <span className="pr-1">{displayName}</span>
    </div>
  );
};
