import { useCallback } from "react";
import { addBreadcrumb } from "@sentry/react";
import { PatientSummaryVO } from "@libs/api/generated-api";
import { DEGREES_180, DEGREES_270, DEGREES_90 } from "@libs/utils/math";
import {
  ImageLayoutItem,
  ReservedStatus,
} from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";
import {
  UploadImagesCallback,
  UploadResult,
} from "components/PatientProfile/Imaging/MountRoute/hooks/useUploadMedicalImages";
import { useImagingDeviceSettings } from "components/PatientProfile/Imaging/hooks/useImagingDeviceSettings";
import { CaptureDeviceSettings } from "components/PatientProfile/Imaging/PatientMountsList/ImagingSettingsModalPage/types";
import { ImagingHttpApi } from "api/imaging/imaging-hub";
import {
  findOveflowSpot,
  mountItemMatchesLocation,
} from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { isAspectCompatible } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/shapeUtils";
import { ImageCaptureResult, MountLayoutType } from "api/imaging/imaging-api";
import { promiseAllProgress } from "utils/promise";
import { useSensorCapture } from "components/PatientProfile/Imaging/MountRoute/hooks/useSensorCapture";
import { fetchImageWithAdjustments } from "components/PatientProfile/Imaging/utils/ais";
import { getSensorSettingsDeviceId } from "components/PatientProfile/Imaging/utils/devices";
import { useImagingHub } from "components/ImageCapturing/ImagingHubContext";
import { useSandboxLayoutContext } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/SandboxLayoutContext";

export type UploadProgressHandler = ({
  type,
  value,
}: {
  type: "processing" | "upload";
  value: number;
  result?: UploadResult;
}) => void;

export type MountUploadParams = {
  mountId: number;
  uploadImages: UploadImagesCallback;
  mountLayout: MountLayoutType;
  sandboxImages: ImageLayoutItem[];
  patient: PatientSummaryVO;
};

// eslint-disable-next-line complexity
const applyImagePostProcessing = async ({
  mountDestination,
  mountLayout,
  sensorSettings,
  image,
}: {
  image: ImageCaptureResult;
  mountDestination: ImageLayoutItem;
  mountLayout: MountLayoutType;
  sensorSettings?: CaptureDeviceSettings;
}) => {
  const { width, height } = image;

  const rotateLocations = sensorSettings?.rotateOnCapture ?? [];
  const needs180Flip = rotateLocations.some((location) =>
    mountItemMatchesLocation({
      mountLayout,
      index: mountDestination.i,
      location,
    })
  );
  const [flipAxis] = sensorSettings?.flipOnCapture ?? [undefined];
  const aspectMatches = isAspectCompatible(
    { width, height },
    { width: mountDestination.sandbox.w, height: mountDestination.sandbox.h }
  );
  const imageResult = await fetchImageWithAdjustments({
    image,
    degrees: aspectMatches
      ? needs180Flip
        ? DEGREES_180
        : undefined
      : needs180Flip
        ? DEGREES_270
        : DEGREES_90,
    flipAxis,
  });
  const position = { i: mountDestination.i, x: mountDestination.x, y: mountDestination.y };

  return {
    ...imageResult,
    position,
  };
};

export const handleSuccessfulCapture = async ({
  data,
  sandboxImages,
  mountLayout,
  uploadImages,
  sensorSettings,
  deviceId,
  mountId,
  patientId,
  onProgress,
}: {
  data: ImageCaptureResult[];
  sensorSettings?: CaptureDeviceSettings;
  deviceId: string;
  patientId: number;
  onProgress: UploadProgressHandler;
} & Omit<MountUploadParams, "patient">) => {
  const firstIndexToUpload = sandboxImages.findIndex((item) => !item.url);
  const imagesToProcess: { image: ImageCaptureResult; mountDestination: ImageLayoutItem }[] = [];

  /*
    `sandboxImages` is an array of layout items, where to place these images in `x,y` coordinate system, until the mount is full.
    Let's say this is a `2 BW / 2 PA`, `sandboxImages` would be of length 4 with the coordinate positions.  If `sandboxImages` has already been populated with 2 images (at index 0 and 1), then `nextInLayout` will be index 2.

    Let's say we captured 4 images. Since the first two items of the mount are already populated,  The final two are "overflow" images though, and `nextInLayout` will be undefined.
    In this case we call `makeOverflowSpot` to find an overflow spot.  This currently just populates in the first position of the mount with every image that overflows.
  */
  for (let imageIndex = 0; imageIndex < data.length; imageIndex++) {
    const nextInLayout = firstIndexToUpload >= 0 ? sandboxImages[firstIndexToUpload + imageIndex] : undefined;

    const nextCaptureSpot = nextInLayout ?? findOveflowSpot(sandboxImages);

    const indexToProcess = sensorSettings?.reverseImport ? data.length - imageIndex - 1 : imageIndex;
    const image = data[indexToProcess];

    imagesToProcess.push({ image, mountDestination: nextCaptureSpot });
  }
  addBreadcrumb({
    level: "info",
    category: "imaging",
    message: "Post Processing Images",
    data: {
      deviceName: deviceId,
      agentType: "AIS",
    },
  });

  const uploads = await promiseAllProgress(
    imagesToProcess.map(({ image, mountDestination }) => {
      return applyImagePostProcessing({
        image,
        mountDestination,
        mountLayout,
        sensorSettings,
      });
    }),
    (value) => {
      onProgress({ type: "processing", value });
    }
  );

  addBreadcrumb({
    level: "info",
    category: "imaging",
    message: "Uploading images",
    data: {
      imageCount: uploads.length,
    },
  });
  await uploadImages({
    images: uploads.map((request, i) => {
      return {
        imageFile: request.image,
        positionI: request.position.i,
        positionX: request.position.x,
        positionY: request.position.y,
        height: request.height,
        width: request.width,
        type: "X_RAY",
        fileName: request.image.name,
        archyImageServiceImageId: imagesToProcess[i].image.id,
      };
    }),
    deviceId,
    mountId,
    patientId,
    onProgress: (value, result) => {
      if (result?.status === "SUCCESS" && result.archyImageServiceImageId) {
        ImagingHttpApi.images.markAsUploaded([result.archyImageServiceImageId]);
      }

      onProgress({ type: "upload", value, result });
    },
  });

  return {
    error: false,
  };
};

export const useArchyAgentUploader = ({ uploadImages, mountLayout, patient, mountId }: MountUploadParams) => {
  const { startSensorCapture, captureState } = useSensorCapture();
  const { getSensorSettings } = useImagingDeviceSettings();
  const { availableDevicesQuery } = useImagingHub();
  const { reserveImageSpot, updateReservedSpot, releaseReservedSpot } = useSandboxLayoutContext();
  const devices = availableDevicesQuery.data;

  const startSensorUploader = useCallback(
    ({ deviceId }: { deviceId: string }) => {
      const selectedDevice = devices?.find((item) => item.name === deviceId);

      if (!selectedDevice) {
        throw new Error("Device not found");
      }

      const allSensorSettings = getSensorSettings();
      const settingsDeviceId = getSensorSettingsDeviceId({
        label: selectedDevice.name,
        driverType: selectedDevice.driverType,
      });
      const sensorSettings: CaptureDeviceSettings | undefined = allSensorSettings.find(
        (item) => item.id === settingsDeviceId
      );
      const reOpenOnCapture = sensorSettings?.autoReopenAfterCapture;

      startSensorCapture({
        deviceId,
        captureInfo: {
          patientId: patient.id,
          firstName: patient.name.firstName,
          lastName: patient.name.lastName,
          dateOfBirth: patient.dob,
          mountLayout,
          mountId,
        },
        sensorSettings,
        onCapture: async ({ result, device, startCapture, stopCapture }) => {
          const reservedSpot = reserveImageSpot();

          if (!reservedSpot) {
            return;
          }

          addBreadcrumb({
            level: "info",
            category: "imaging",
            message: "Post Processing Images",
            data: {
              deviceName: deviceId,
              agentType: "AIS",
            },
          });

          const processedImage = await applyImagePostProcessing({
            image: result,
            mountDestination: reservedSpot,
            mountLayout,
            sensorSettings,
          });

          // Show the image with the raw data while it uploads.
          // this url will be be revoked when the reserved spot is released
          reservedSpot.url = URL.createObjectURL(processedImage.image);
          reservedSpot.w = processedImage.width;
          reservedSpot.h = processedImage.height;
          // Assign a date so the metadata shows up.
          reservedSpot.createdDate = new Date().toISOString();

          reservedSpot.uploadImage = async () => {
            // If upload failed, needs to be updated again to in progress:
            updateReservedSpot(reservedSpot.id, {
              status: ReservedStatus.Uploading,
            });
            addBreadcrumb({
              level: "info",
              category: "imaging",
              message: "Uploading image",
              data: {
                imageCount: 1,
                aisImageId: result.id,
                device,
              },
            });

            // Trigger a re-render in the uploading state.
            const uploadResults = await uploadImages({
              images: [
                {
                  imageFile: processedImage.image,
                  positionI: processedImage.position.i,
                  positionX: processedImage.position.x,
                  positionY: processedImage.position.y,
                  height: processedImage.height,
                  width: processedImage.width,
                  type: "X_RAY",
                },
              ],
              deviceId,
              mountId,
              patientId: patient.id,
              onProgress: (_, uploadResult) => {
                if (uploadResult?.status === "SUCCESS") {
                  ImagingHttpApi.images.markAsUploaded([result.id]);

                  const serverImage = uploadResult.response.data.data;

                  releaseReservedSpot(reservedSpot.id, serverImage);

                  URL.revokeObjectURL(reservedSpot.url!);
                  // Update cache using uploadResult.response.data
                } else if (uploadResult?.status === "FAILED") {
                  updateReservedSpot(reservedSpot.id, {
                    status: ReservedStatus.Failed,
                  });
                }
              },
            });

            // If it failed to prepare uploads, then it just returns []. We need to mark it as failed.
            if (uploadResults.length === 0 && reservedSpot.reservation.status !== ReservedStatus.Failed) {
              updateReservedSpot(reservedSpot.id, {
                status: ReservedStatus.Failed,
              });
            }
          };
          reservedSpot.uploadImage();

          if (reOpenOnCapture) {
            await stopCapture();
            startCapture();
          }
        },
      });
    },
    [
      devices,
      getSensorSettings,
      mountId,
      mountLayout,
      patient.dob,
      patient.id,
      patient.name.firstName,
      patient.name.lastName,
      releaseReservedSpot,
      reserveImageSpot,
      startSensorCapture,
      updateReservedSpot,
      uploadImages,
    ]
  );

  return {
    startSensorUploader,
    captureState,
  };
};

export type UseArchyAgentUploader = ReturnType<typeof useArchyAgentUploader>;
