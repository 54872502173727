import { cx } from "@libs/utils/cx";
import { FC, PropsWithChildren, ReactNode } from "react";

export const SettingsHeroContent: FC<PropsWithChildren<{ className?: string }>> = ({
  children,
  className,
}) => {
  return <div className={cx("max-w-xl mx-auto", className)}>{children}</div>;
};

export const SettingsHeroDescription: FC<PropsWithChildren> = ({ children }) => {
  return <p className="text-xs">{children}</p>;
};

export const SettingsHeroTitle: FC<PropsWithChildren> = ({ children }) => {
  return <h3 className="text-sm font-sansSemiBold">{children}</h3>;
};

export const SettingsHeroDetails: FC<PropsWithChildren> = ({ children }) => {
  return <div className="flex flex-col gap-y-2 items-start text-xs">{children}</div>;
};

export const SettingsHeroContainer: FC<PropsWithChildren<{ image: ReactNode }>> = ({ children, image }) => {
  return (
    <div className="bg-slate-50 flex items-center gap-x-3 py-3 pl-3 pr-6 rounded">
      <div className="flex-none size-[156px]">{image}</div>
      <div className="flex-1">{children}</div>
    </div>
  );
};
