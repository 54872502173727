import React, { useState } from "react";
import { PatientVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Button } from "@libs/components/UI/Button";
import { useAccount } from "@libs/contexts/AccountContext";
import { Banner } from "@libs/components/UI/Banner";
import { handleError } from "utils/handleError";
import { resendPatientInvite } from "api/patients/mutations";

export const ResendInviteBanner: React.FC<{
  patientId: number;
  onboardingState: PatientVO["onboardingState"];
  rounded?: boolean;
}> = ({ patientId, onboardingState, rounded = false }) => {
  const { practiceId } = useAccount();
  const [{ mutate: resendPatientInviteMutate, isLoading: isLoadingResendInvite }] = useApiMutations([
    resendPatientInvite,
  ]);
  const [confirmInviteResent, setConfirmInviteResent] = useState(false);
  const resendInvitation = () => {
    resendPatientInviteMutate(
      { practiceId, patientId },
      { onError: handleError, onSuccess: () => setConfirmInviteResent(true) }
    );
  };

  return (
    <Banner
      className={cx(
        `flex
         w-full
         text-xs
         justify-between
         bg-actionLight
         items-center`,
        rounded && "rounded"
      )}
      paddingClassName="px-4 py-2"
      theme="info"
    >
      <div className="flex-1">Patient Portal Status: {sentenceCaseConstant(onboardingState)}</div>
      {isLoadingResendInvite ? (
        "Resending..."
      ) : confirmInviteResent ? (
        "Invite resent!"
      ) : (
        <Button className="text-primaryTheme font-sansSemiBold" onClick={resendInvitation} theme="link">
          Resend Invite
        </Button>
      )}
    </Banner>
  );
};
