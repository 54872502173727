import { MedicalImageVO } from "@libs/api/generated-api";

export interface ImageLayoutItem extends MedicalImageVO {
  name?: string;
  id: number;
  // index in mount, multiple items may have same index
  i: number;
  x: number;
  y: number;
  sandbox: {
    w: number;
    h: number;
    isPlaceholder: boolean;
    // Set to true if the 'i' for this item is not recognized by the layout it belongs to (it is greater the mountLayout.length)
    outOfLayoutBounds?: boolean;
  };
}

export enum ReservedStatus {
  None,
  Uploading,
  Failed,
}

export interface ReservedLayoutItem extends ImageLayoutItem {
  reservation: {
    status: ReservedStatus;
    // id of image that this image is retaking
    captureSpotId?: number;
  };
  uploadImage?: () => Promise<void>;
}
export const isReservedLayoutItem = (item: ImageLayoutItem): item is ReservedLayoutItem => {
  return "reservation" in item;
};

export type ImageWithZoom = {
  image: ImageLayoutItem;
  zoomScale: number;
};

export interface ReserveImageSpotFunc {
  (): ReservedLayoutItem | undefined;
}
