import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { ActionModal } from "components/UI/ActionModal";

type Props<T extends string> = {
  onClose: Func;
  onPrint: Func;
  options: OptionInputOption<T>[];
  onChange: (
    updatedSet: Set<T>,
    e: React.ChangeEvent<HTMLInputElement>,
    option: OptionInputOption<T>
  ) => void;
  title: string;
  selectedValues: Set<T>;
};
export const PrintOptionsModal = <T extends string>({
  onClose,
  onPrint,
  options,
  onChange,
  title,
  selectedValues,
}: Props<T>) => {
  return (
    <ActionModal
      handleClose={onClose}
      handleSave={onPrint}
      primaryButtonText="Print"
      size="3xs"
      title={title}
    >
      <CheckboxList layout="vert" onChange={onChange} options={options} selectedValues={selectedValues} />
    </ActionModal>
  );
};
