import React from "react";
import {
  BenefitLimitationVO,
  FrequencyLimitationCategoryVO,
  FrequencyLimitVO,
} from "@libs/api/generated-api";
import { YEAR_IN_MONTHS } from "@libs/utils/date";
import { decimalAdjust } from "@libs/utils/math";
import { pluralize } from "@libs/utils/pluralize";

export type DefaultFrequencyEnum = NonNullable<FrequencyLimitationCategoryVO["defaultFrequencyLimit"]>;

// These are hardcoded maps for a BE patch that we currently have where
// we return a default enum value on each FrequencyLimitationCategoryVO.
// Eventually, we should update the BE so these maps are no longer needed.
export const defaultFrequencyToOptionValueMap: Record<DefaultFrequencyEnum, number> = {
  X_VISITS_PER_BENEFIT_YEAR: 2,
  ONE_VISIT_EVERY_X_YEARS: 1,
  ONE_VISIT_EVERY_X_MONTHS: 0,
  ONE_VISIT_EVERY_X_MONTHS_PER_QUADRANT: 0,
  ONE_VISIT_EVERY_X_YEARS_PER_TOOTH: 6,
  ONE_VISIT_EVERY_X_YEARS_PER_ARCH: 7,
};

export const simpleOptions: SimpleFrequencyOption[] = [
  {
    frequencyLimit: {
      durationType: "MONTH",
      frequencyQuantity: 1,
      limitationType: "SERVICE_DATE",
    },
    label: "Every # months",
    value: 0,
  },
  {
    frequencyLimit: {
      durationType: "YEAR",
      frequencyQuantity: 1,
      limitationType: "SERVICE_DATE",
    },
    label: "Every # years",
    value: 1,
  },
  {
    frequencyLimit: {
      durationType: "YEAR",
      durationQuantity: 1,
      limitationType: "BENEFIT_YEAR",
    },
    label: "# per benefit year",
    value: 2,
  },
  {
    frequencyLimit: {
      durationType: "MONTH",
      durationQuantity: 12,
      limitationType: "SERVICE_DATE",
    },
    label: "# in last 12 months",
    value: 3,
  },
  {
    frequencyLimit: {
      durationType: "YEAR",
      frequencyQuantity: 1,
      limitationType: "BENEFIT_YEAR",
    },
    label: "every # benefit years",
    value: 4,
  },
  {
    frequencyLimit: {
      durationType: "LIFETIME",
      durationQuantity: 1,
      limitationType: "BENEFIT_YEAR",
    },
    label: "# per lifetime",
    value: 5,
  },
  {
    frequencyLimit: {
      durationType: "YEAR",
      frequencyQuantity: 1,
      limitationType: "SERVICE_DATE",
      mouthConstraint: "TOOTH",
    },
    label: "Every # years per tooth",
    value: 6,
  },
  {
    frequencyLimit: {
      durationType: "YEAR",
      frequencyQuantity: 1,
      limitationType: "SERVICE_DATE",
      mouthConstraint: "ARCH",
    },
    label: "Every # years per arch",
    value: 7,
  },
];

type ComparableFrequency = Record<string, string | number>;

const optionWithMostKeysMatching = (frequency: ComparableFrequency) => {
  const numMatching = simpleOptions.map((option) => {
    const limit: ComparableFrequency = option.frequencyLimit;

    return Object.keys(option.frequencyLimit).filter((key) => limit[key] === frequency[key]).length;
  });
  const maximumMatch = Math.max(...numMatching);
  const indexOfMax = numMatching.indexOf(Math.max(...numMatching));

  return maximumMatch > 0 ? simpleOptions[indexOfMax] : undefined;
};

export type SimpleFrequencyOption = {
  frequencyLimit: Partial<FrequencyLimitVO>;
  label: string;
  value: number;
};

export const useSimpleFrequencyOptions = (
  frequency: Partial<FrequencyLimitVO>,
  frequencyLimitationCategory: FrequencyLimitationCategoryVO | undefined
): { isCompatible: boolean; selectedValue?: number } => {
  const isNew = Object.keys(frequency).length === 0;

  if (isNew) {
    const defaultValue = frequencyLimitationCategory?.defaultFrequencyLimit
      ? defaultFrequencyToOptionValueMap[frequencyLimitationCategory.defaultFrequencyLimit]
      : 0;

    return { isCompatible: true, selectedValue: defaultValue };
  }

  const matchedOption = optionWithMostKeysMatching(frequency);

  if (
    frequencyLimitationCategory?.defaultFrequencyLimit &&
    defaultFrequencyToOptionValueMap[frequencyLimitationCategory.defaultFrequencyLimit] ===
      matchedOption?.value &&
    frequencyLimitationCategory.defaultFrequencyLimit === "ONE_VISIT_EVERY_X_MONTHS_PER_QUADRANT"
  ) {
    return { isCompatible: false, selectedValue: matchedOption.value };
  }

  return { isCompatible: Boolean(matchedOption), selectedValue: matchedOption?.value };
};

const durationLabel = (durationQuantity = 1, value?: "MONTH" | "QUARTER" | "YEAR" | "LIFETIME") => {
  switch (value) {
    case "MONTH": {
      return pluralize(durationQuantity, "month", "months");
    }
    case "QUARTER": {
      return pluralize(durationQuantity, "quarter", "quarters");
    }
    case "YEAR": {
      return pluralize(durationQuantity, "year", "years");
    }
    default: {
      return "lifetime";
    }
  }
};

export const useDurations = (durationQuantity = 1) => {
  return React.useMemo(() => {
    const order: ("MONTH" | "QUARTER" | "YEAR" | "LIFETIME")[] = ["MONTH", "QUARTER", "YEAR", "LIFETIME"];

    return order.map((value) => ({
      label: durationLabel(durationQuantity, value),
      value,
    }));
  }, [durationQuantity]);
};

type LimitationType = FrequencyLimitVO["limitationType"];

const limitationTypeOptions: Record<LimitationType, string> = {
  BENEFIT_YEAR: "every benefit year",
  CALENDAR_YEAR: "every calendar year",
  SERVICE_DATE: "after service date",
};

export const useLimitationTypeOptions = () => {
  return React.useMemo(
    () =>
      Object.entries(limitationTypeOptions).map(([key, value]) => ({
        label: value,
        value: key,
      })),
    []
  );
};

type MouthConstraintValue = "QUADRANT" | "ARCH" | "TOOTH" | "VISIT" | "SURFACE";

const mouthConstraintLabel = (count = 1, value?: MouthConstraintValue) => {
  return value === "VISIT" || value == null ? pluralize(count, "visit", "visits") : value.toLowerCase();
};

export const useMouthConstraintOptions = (
  selectedFrequencyQuantity = 1
): {
  label: string;
  value: MouthConstraintValue;
}[] => {
  return React.useMemo(() => {
    const order: MouthConstraintValue[] = ["VISIT", "QUADRANT", "ARCH", "TOOTH", "SURFACE"];

    return order.map((value) => ({
      value,
      label:
        value === "VISIT"
          ? mouthConstraintLabel(selectedFrequencyQuantity, value)
          : `per ${mouthConstraintLabel(1, value)}`,
    }));
  }, [selectedFrequencyQuantity]);
};

export const useLimitationAsString = ({
  frequency,
  isSummarizedLimitationView,
  limitation,
}: {
  frequency: Partial<FrequencyLimitVO>;
  isSummarizedLimitationView: boolean;
  limitation?: BenefitLimitationVO | undefined;
}) => {
  // eslint-disable-next-line complexity
  return React.useMemo(() => {
    const { limitationType, frequencyQuantity, durationQuantity, mouthConstraint, durationType } = frequency;

    const limitationStrParts: string[] = [];

    if (frequencyQuantity && Object.keys(frequency).length) {
      const forever = durationType === "LIFETIME";

      let limitationString = `${frequencyQuantity} ${
        mouthConstraint
          ? `per ${mouthConstraintLabel(frequencyQuantity, mouthConstraint)}`
          : mouthConstraintLabel(frequencyQuantity, "VISIT")
      }`;

      limitationString = `${limitationString} per ${
        forever
          ? "lifetime"
          : !durationQuantity || durationQuantity === 1
            ? durationLabel(durationQuantity, durationType)
            : `${durationQuantity} ${durationLabel(durationQuantity, durationType)}`
      }`;

      if (limitationType && !forever && !isSummarizedLimitationView) {
        limitationString = `${limitationString} ${limitationTypeOptions[limitationType]}`;
      }

      limitationStrParts.push(limitationString);
    }

    if (isSummarizedLimitationView && limitation) {
      if (limitation.waitingPeriodInMonths) {
        const years = decimalAdjust("floor", limitation.waitingPeriodInMonths / YEAR_IN_MONTHS, 0);
        const months = limitation.waitingPeriodInMonths % YEAR_IN_MONTHS;

        if (years || months) {
          limitationStrParts.push(`Wait${years ? ` ${years}y` : ""}${months ? ` ${months}m` : ""}`);
        }
      }

      if (limitation.downgradeProcedure?.cdtCode) {
        limitationStrParts.push(`Downgrade ${limitation.downgradeProcedure.cdtCode}`);
      }

      if (limitation.ageMin || limitation.ageMax) {
        let ageStr = limitation.ageMin ? `Age Min${" "}${limitation.ageMin}` : "";

        if (limitation.ageMax) {
          ageStr = `${ageStr.length ? `${ageStr}, ` : ""} Age Max${" "}${limitation.ageMax}`;
        }

        limitationStrParts.push(ageStr);
      }
    }

    return limitationStrParts;
  }, [frequency, isSummarizedLimitationView, limitation]);
};
