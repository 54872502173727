import { FC, FormEventHandler, useState } from "react";
import { DentalProcedureVO, LabCaseTypeRequest, LabCaseTypeVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { minArrayLen, required } from "@libs/utils/validators";
import { pluralize } from "@libs/utils/pluralize";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";
import { Button } from "@libs/components/UI/Button";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { Flyover } from "components/UI/Flyover";
import { FlyoverContent, FlyoverFooter, FlyoverForm } from "components/UI/FlyoverComponents";
import { createLabCaseType, updateLabCaseType } from "api/lab/mutations";
import { handleError } from "utils/handleError";
import { ProcedureSelector } from "components/ProcedureSelector/ProcedureSelector";
import { getDentalProceduresQuery } from "api/charting/queries";
import { useFocusOnce } from "hooks/useFocusOnce";
import { getLabCaseType } from "api/lab/queries";

const MIN_PROCEDURES = 1;

export const LabCaseTypeFlyover: FC<{
  labCaseTypeUuid?: LabCaseTypeVO["uuid"];
  onClose: Func;
  onSave: Func;
}> = ({ labCaseTypeUuid, onSave, onClose }) => {
  const isEditing = labCaseTypeUuid != null;
  const { practiceId } = useAccount();
  const [selectedProcedures, setSelectedProcedures] = useState<DentalProcedureVO[]>([]);
  const [draft, setDraft] = useState<LabCaseTypeRequest>({
    name: "",
    dentalProcedureIds: [],
  });

  // Focus the name field. Called after flyover is shown and its transition ends.
  const { ref: focusRef, handleFocus } = useFocusOnce<HTMLInputElement>();

  const [proceduresQuery, originalLabCaseTypeQuery] = useApiQueries([
    getDentalProceduresQuery({ args: { practiceId } }),
    getLabCaseType({
      args: { practiceId, labCaseTypeUuid: labCaseTypeUuid as string },
      queryOptions: { enabled: isEditing },
    }),
  ]);

  const isInitializing = proceduresQuery.isInitialLoading || originalLabCaseTypeQuery.isInitialLoading;

  const [createLabCaseTypeMutation, updateLabCaseTypeMutation] = useApiMutations([
    createLabCaseType,
    updateLabCaseType,
  ]);

  const validation = useValidation(
    { name: draft.name, dentalProcedures: selectedProcedures },
    {
      name: { $validations: [{ $v: required, $error: "Name is required" }] },
      dentalProcedures: {
        $validations: [
          {
            $v: minArrayLen(MIN_PROCEDURES),
            $error: `At least ${MIN_PROCEDURES} ${pluralize(
              MIN_PROCEDURES,
              "procedure is",
              "procedures are"
            )} required`,
          },
        ],
      },
    }
  );

  const handleUpdateDraft = (update: Partial<LabCaseTypeRequest>) => {
    setDraft((prev) => ({ ...prev, ...update }));
  };

  const handleSave: FormEventHandler = (e) => {
    e.preventDefault();

    if (!validation.validate().$isValid) {
      return;
    }

    if (isEditing) {
      updateLabCaseTypeMutation.mutate(
        {
          practiceId,
          labCaseTypeUuid,
          data: { ...draft, dentalProcedureIds: selectedProcedures.map((proc) => proc.id) },
        },
        { onSuccess: onSave, onError: handleError }
      );
    } else {
      createLabCaseTypeMutation.mutate(
        { practiceId, data: { ...draft, dentalProcedureIds: selectedProcedures.map((proc) => proc.id) } },
        {
          onSuccess: onSave,
          onError: handleError,
        }
      );
    }
  };

  useSyncOnce((labCaseType) => {
    handleUpdateDraft({
      name: labCaseType.name,
      dentalProcedureIds: labCaseType.dentalProcedures.map((proc) => proc.id),
    });
    setSelectedProcedures(labCaseType.dentalProcedures);
  }, originalLabCaseTypeQuery.data);

  return (
    <Flyover
      title={`${isEditing ? "Edit" : "Add"} Lab Case Type`}
      onClose={onClose}
      size="md"
      onTransitionEnd={(e, { show }) => {
        show && handleFocus();
      }}
    >
      {({ close }) => (
        <FlyoverForm fieldLayout="labelOut" onSubmit={handleSave}>
          <FlyoverContent>
            <QueryResult
              queries={[proceduresQuery, originalLabCaseTypeQuery]}
              loading={<LoadingOverlaySpinner />}
            >
              <div className="grid grid-cols-2 gap-6">
                <FormFieldInput
                  label="Name"
                  className="col-span-full"
                  autoComplete="off"
                  required
                  value={draft.name}
                  error={validation.result.name.$error}
                  onChange={(e) => handleUpdateDraft({ name: e.target.value })}
                  ref={focusRef}
                />
                <QueryResult queries={[proceduresQuery]} loading={<div />}>
                  <ProcedureSelector
                    allowDuplicateSelections={false}
                    required
                    mode="multi"
                    label="Select Procedures"
                    className="col-span-full"
                    procedures={proceduresQuery.data}
                    values={selectedProcedures}
                    onChange={setSelectedProcedures}
                    error={validation.result.dentalProcedures.$error}
                  />
                </QueryResult>
              </div>
            </QueryResult>
          </FlyoverContent>
          <FlyoverFooter>
            <Button className="min-w-button" theme="secondary" onClick={close}>
              Cancel
            </Button>
            <Button
              disabled={
                !(validation.result.$isValid ?? true) ||
                createLabCaseTypeMutation.isLoading ||
                updateLabCaseTypeMutation.isLoading ||
                isInitializing
              }
              className="min-w-button"
              type="submit"
            >
              {isEditing ? "Save" : "Create"}
            </Button>
          </FlyoverFooter>
        </FlyoverForm>
      )}
    </Flyover>
  );
};
