import { FC, HTMLProps } from "react";
import { fromUnixTime, getUnixTime } from "date-fns";
import { CurrencyAmount, PaymentCreationRequest, PaymentProfileVO, PaymentVO } from "@libs/api/generated-api";
import { RadioList } from "@libs/components/UI/RadioList";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { CreditCard } from "components/PatientProfile/Billing/CreditCard";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { isPaymentMethodByCard } from "components/PatientProfile/Billing/billingUtils";
import { PaymentAmountField } from "components/PatientProfile/Billing/PaymentAmountField";
import { paymentMethodToLabel } from "components/PatientProfile/Billing/PaymentMethods/utils";

const REFUND_METHOD_OPTIONS: OptionInputOption<
  Exclude<PaymentCreationRequest["method"], "STORED_PROFILE" | "STORED_POS">
>[] = [
  { value: "CASH", label: paymentMethodToLabel.CASH },
  { value: "CHECK", label: paymentMethodToLabel.CHECK },
];

type Props = {
  method: PaymentCreationRequest["method"];
  refundAmount: CurrencyAmount["amount"];
  refundDate: PaymentCreationRequest["paymentCreatedAt"];
  paymentProfile?: PaymentProfileVO;
  checkPayload: PaymentCreationRequest["checkPayload"];
  onMethodChange: (method: PaymentVO["method"]) => void;
  onRefundAmountChange: (cents: number) => void;
  onRefundDateChange: (date: PaymentCreationRequest["paymentCreatedAt"]) => void;
  onCheckChange: (checkPayload: PaymentCreationRequest["checkPayload"]) => void;
  errors?: {
    refundAmount?: string;
    refundDate?: string;
    checkNumber?: string;
  };
};

export const RefundMethods: FC<Props> = ({
  method,
  paymentProfile,
  refundAmount,
  refundDate,
  checkPayload,
  errors,
  onMethodChange,
  onRefundAmountChange,
  onRefundDateChange,
  onCheckChange,
}) => {
  return (
    <>
      <Section>
        <div className="font-sansSemiBold text-xs">Method</div>
        <div className="grid grid-cols-3">
          {isPaymentMethodByCard(method) ? (
            paymentProfile && (
              <CreditCard
                nameOnCard={paymentProfile.billingName ?? ""}
                cardBrand={paymentProfile.card?.cardBrand ?? ""}
                cardLastFour={paymentProfile.card?.cardLastFour ?? ""}
                isSelected={false}
              />
            )
          ) : (
            <RadioList
              layout="horiz"
              selectedValue={method}
              onChange={(_e, value) => onMethodChange(value.value)}
              options={REFUND_METHOD_OPTIONS}
            />
          )}
        </div>
      </Section>

      <Section>
        <div className="font-sansSemiBold text-xs">Details</div>
        <div className="grid grid-cols-3 gap-3">
          <PaymentAmountField
            cents={refundAmount}
            onChange={onRefundAmountChange}
            error={errors?.refundAmount}
            className="w-56"
          />
          {!isPaymentMethodByCard(method) && (
            <FormFieldSelectMenusDatepicker
              label="Refund Date"
              layout="labelIn"
              required={true}
              selected={refundDate ? fromUnixTime(refundDate) : undefined}
              maxDate={new Date()}
              error={errors?.refundDate}
              onChange={(date) => date && onRefundDateChange(getUnixTime(date))}
            />
          )}
          {method === "CHECK" && (
            <FormFieldInput
              label="Check Number"
              layout="labelIn"
              placeholder="XXXXXXXXXXXX"
              value={checkPayload?.checkNumber}
              error={errors?.checkNumber}
              onChange={(event) => {
                onCheckChange({
                  bankName: checkPayload?.bankName ?? "",
                  checkNumber: event.target.value,
                });
              }}
            />
          )}
        </div>
      </Section>
    </>
  );
};

const Section: FC<Pick<HTMLProps<HTMLDivElement>, "children">> = ({ children }) => {
  return <div className="flex flex-col gap-y-3">{children}</div>;
};
