import { ClaimInsuranceCarrierVO, CustomAdjustmentTypeVO } from "@libs/api/generated-api";
import { NO_INSURANCE_CARRIER_ID } from "components/Dashboard/Adjustments/constants";
import { AdjustmentCategory } from "utils/routing/dashboard";

export const labelForAdjustmentCategory = (
  adjustmentCategory: AdjustmentCategory,
  options?: { concise: boolean }
) => {
  const { concise } = options ?? { concise: false };
  const labels: Record<AdjustmentCategory, string> = {
    insurance: "Insurance Adjustment",
    collection: "Collection Adjustment",
  };
  const conciseLabels: Record<AdjustmentCategory, string> = {
    insurance: "Ins Adjust",
    collection: "Collect Adjust",
  };

  return concise ? conciseLabels[adjustmentCategory] : labels[adjustmentCategory];
};

export const labelForAdjustmentTypeId = (
  adjustmentTypeId: string,
  adjustmentTypes?: CustomAdjustmentTypeVO[]
) => {
  // Adjustment Discounts comming from Claims are set to -1.
  if (adjustmentTypeId === "-1") {
    return "Adjustment Discount";
  }

  return (
    (adjustmentTypes ?? []).find((adjustment) => adjustment.id === Number.parseInt(adjustmentTypeId, 10))
      ?.name ?? "Unknown"
  );
};

export const labelForCarrier = (
  carrierLookup: Record<string | number, ClaimInsuranceCarrierVO | undefined>,
  carrierId: number
) => {
  if (carrierId === NO_INSURANCE_CARRIER_ID) {
    return "None";
  }

  return carrierLookup[carrierId]?.name ?? "Unknown";
};
