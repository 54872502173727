import { FC } from "react";
import { SettingsPanel } from "components/Settings/SettingsPanel";

import { ProcedureShortcutSettings } from "components/Settings/ChartingSettings/ProcedureShortcutSettings";

export const QuickSelectOptionsRoute: FC = () => {
  return (
    <SettingsPanel
      title="Quick Select Options"
      description="Set up quick select options for procedures on the Charting page for quicker and easy procedure selection."
    >
      <ProcedureShortcutSettings />
    </SettingsPanel>
  );
};
