import { FC } from "react";
import { FormVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { FormInputs } from "components/PatientProfile/Forms/FormInputs";
import { FormSubmissionResponses } from "components/PatientProfile/Forms/types";
import { isInputElement } from "components/PatientProfile/Forms/utils";

interface Props {
  displayDivider?: boolean;
  form: FormVO;
  responses: FormSubmissionResponses;
  onUpdate?: (updater: (last: FormSubmissionResponses) => void) => void;
}

export const FormStructure: FC<Props> = ({ displayDivider = true, form, responses, onUpdate }) => {
  return (
    <div className="flex flex-col gap-y-6">
      {form.content.flatMap((page) =>
        page.content.map((el) =>
          el.type === "SECTION" ? (
            <div
              key={el.uuid}
              className={cx(displayDivider && "border-b border-dashed border-b-greyLighter pb-6")}
            >
              <h3 className="text-sm font-sansSemiBold mb-4">{el.title}</h3>
              <div className="flex flex-col gap-y-6">
                {el.content.map((sectionEl) =>
                  isInputElement(sectionEl) ? (
                    <FormInputs
                      key={sectionEl.uuid}
                      element={sectionEl}
                      responses={responses}
                      onUpdate={onUpdate}
                    />
                  ) : null
                )}
              </div>
            </div>
          ) : isInputElement(el) ? (
            <FormInputs key={el.uuid} element={el} responses={responses} onUpdate={onUpdate} />
          ) : null
        )
      )}
    </div>
  );
};
