import React, { useState } from "react";
import { useTransformEffect } from "react-zoom-pan-pinch";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as ZoomIcon } from "@libs/assets/icons/zoom-to-extents.svg";
import { ReactComponent as ZoomIn } from "@libs/assets/icons/zoom-in.svg";
import { ReactComponent as ZoomOut } from "@libs/assets/icons/zoom-out.svg";

type MountViewOptionsProps = {
  centerView: Func;
  zoomIn: (step: number) => void;
  zoomOut: (step: number) => void;
  show: boolean;
  minScale: number;
  maxScale: number;
};

type IconButtonProps = {
  children: React.ReactNode;
  onClick: Func;
  title: string;
  disabled?: boolean;
};
export const ViewOptionsButton: React.FC<IconButtonProps> = ({
  children,
  title,
  onClick,
  disabled = false,
}) => {
  return (
    <FloatingTooltip content={title} theme="SMALL">
      <button
        type="button"
        name={title}
        disabled={disabled}
        className={`
          flex
          flex-row
          justify-center
          items-center
          disabled:opacity-75
          disabled:text-greyMedium
          hover:bg-opacity-1
          text-whiteLight
          hover:text-slate-300
          select-none
        `}
        onClick={onClick}
      >
        {children}
      </button>
    </FloatingTooltip>
  );
};

export const ZoomWidget: React.FC<MountViewOptionsProps> = React.memo(
  ({ centerView, zoomIn, zoomOut, show, minScale, maxScale }) => {
    const PERCENT_FACTOR = 100;
    const ZOOM_STEP = 0.25;

    const [zoomScale, setZoomScale] = useState(1);

    useTransformEffect((context) => setZoomScale(context.state.scale));

    if (!show) {
      return null;
    }

    const zoomOutDisabled = zoomScale <= minScale;
    const zoomInDisabled = zoomScale >= maxScale;

    return (
      <div
        className={`
          z-50
          bg-black
          flex
          flex-row
          gap-3
          bg-opacity-60
          round
          rounded-sm
          items-center
          print:hidden
        `}
      >
        <ViewOptionsButton
          disabled={zoomInDisabled}
          onClick={() => zoomIn(ZOOM_STEP)}
          title="Zoom In (z + Mouse Up OR Scroll)"
        >
          <ZoomIn />
        </ViewOptionsButton>
        <ViewOptionsButton
          disabled={zoomOutDisabled}
          onClick={() => zoomOut(ZOOM_STEP)}
          title="Zoom Out (z + Mouse Down OR Scroll)"
        >
          <ZoomOut />
        </ViewOptionsButton>
        <div className="w-[1px] h-6 bg-slate-600" />
        <ViewOptionsButton onClick={() => centerView()} title="Recenter Mount">
          <ZoomIcon />
          <span className="text-xl">{Math.round(zoomScale * PERCENT_FACTOR)}%</span>
        </ViewOptionsButton>
      </div>
    );
  }
);
