import React from "react";
import { SingleValue } from "react-select";
import { PersonalDetailsVO } from "@libs/api/generated-api";
import { FormFieldProps } from "@libs/components/UI/FormField";
import { FormFieldSelect } from "components/UI/FormFieldSelect";

export const FormFieldSelectGender: React.FC<
  FormFieldProps & {
    value: PersonalDetailsVO["gender"];
    placeholder?: string;
    menuPlacement?: "auto" | "top" | "bottom";
    onChange: (
      option: SingleValue<{
        label: string;
        value: NonNullable<PersonalDetailsVO["gender"]>;
      }>
    ) => void;
  }
> = ({
  layout,
  label,
  value,
  edit = true,
  onChange,
  menuPlacement = "auto",
  placeholder,
  required,
  error,
}) => {
  return (
    <FormFieldSelect
      layout={layout}
      label={label}
      display="label"
      edit={edit}
      required={required}
      value={value}
      error={error}
      menuPlacement={menuPlacement}
      placeholder={placeholder}
      options={[
        { label: "Female", value: "FEMALE" },
        { label: "Male", value: "MALE" },
        { label: "Unknown", value: "UNKNOWN" },
        { label: "Other", value: "OTHER" },
      ]}
      onChange={onChange}
    />
  );
};
