import React, { createContext, useContext } from "react";
import { noop } from "@libs/utils/noop";
import { MountVO } from "@libs/api/generated-api";
import {
  UseMountLayout,
  useMountLayout,
} from "components/PatientProfile/Imaging/MountRoute/hooks/useMountLayout";
import { ImageLayoutItem } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/types";

export type SandboxLayoutContextValue = UseMountLayout;

// Currently the mount context  just tracks whether the user is viewing archived or not.
const Context = createContext<SandboxLayoutContextValue>({
  retakingImageId: undefined,
  isFirstCaptureSession: false,
  handleNewCaptureSpotId: noop,
  handleOrderImageOnTop: noop,
  handleLayoutItemClicked: noop,
  nextCaptureSpot: {} as ImageLayoutItem,
  sandboxImages: [],
  reserveImageSpot: () => {
    throw new Error("SandboxLayoutContext has not yet been initialized");
  },
  zoomToImageId: undefined,
  releaseReservedSpot: noop,
  updateReservedSpot: noop,
});

Context.displayName = "SandboxLayoutContext";
export const SandboxLayoutContext = Context;

export const useSandboxLayoutContext = () => useContext(SandboxLayoutContext);

export const SandboxLayoutContextProvider: React.FC<{
  children: React.ReactNode;
  imageMount: MountVO;
  showArchived: boolean;
  selectedImageIds?: number[];
  onCaptureSequenceStatusChanged: (status: "uploading" | "complete") => void;
}> = ({ children, imageMount, selectedImageIds, showArchived, onCaptureSequenceStatusChanged }) => {
  const sandboxLayout = useMountLayout({
    isViewingArchived: showArchived,
    imageMount,
    onCaptureSequenceStatusChanged,
    retakingImageId: selectedImageIds?.length === 1 ? selectedImageIds[0] : undefined,
  });

  return <SandboxLayoutContext.Provider value={sandboxLayout}>{children}</SandboxLayoutContext.Provider>;
};
