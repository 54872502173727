/* eslint-disable @typescript-eslint/no-magic-numbers */
import { PerioChartExamEntryVO, PerioChartToothConfigVO } from "@libs/api/generated-api";
import { ThresholdCondition } from "./PerioChartNumericInput";
import {
  PerioChartAutoAdvance,
  PerioChartThresholdSettings,
  PerioChartToothSurfaceType,
  PerioChartVisibilitySettings,
  PerioChartSequenceType,
  PerioChartExamEntryField,
  PerioChartExamInfo,
} from "./PerioTypes";

export const MAX_CONTROLS_PER_TOOTH_SEQUENCE = 3;
export const MAX_TOOTH_SURFACES = 64;

export const FURC_MIDDLE_TEETH_NUMBERS_FACIAL = [1, 2, 3, 14, 15, 16, 17, 18, 19, 30, 31, 32];
export const FURC_MIDDLE_TEETH_NUMBERS_LINGUAL = [17, 18, 19, 30, 31, 32];
export const FURC_OUTER_TEETH_NUMBERS = [1, 2, 3, 4, 5, 12, 13, 14, 15, 16];

export enum TabDirection {
  FOWARD,
  BACKWARD,
}

export const getTabDirection = (surface: PerioChartToothSurfaceType, autoAdvance: PerioChartAutoAdvance) => {
  if (surface === PerioChartToothSurfaceType.FACIAL) {
    return autoAdvance === "RIGHT" ? TabDirection.FOWARD : TabDirection.BACKWARD;
  }

  return autoAdvance === "RIGHT" ? TabDirection.BACKWARD : TabDirection.FOWARD;
};

/**
 * This helps deal with tab indexes by making translating 1-32 tooth numbers into 1-64 for tab order.
 * @param toothNumber The actual tooth number.
 * @param surface The surface of the tooth.
 */
const getToothSurfaceNumberRightAutoAdvance = (toothNumber: number, surface: PerioChartToothSurfaceType) => {
  if (toothNumber <= 16) {
    if (surface === PerioChartToothSurfaceType.FACIAL) {
      // Tab is left to right.
      return toothNumber;
    }

    // Map 1-16 to be 17 through 32 (In reverse order) for tabbing to go from right to left.
    return 17 + (16 - toothNumber);
  }

  // Map 32-17 to be 33 through 48. Tabbing is left to right.
  if (surface === PerioChartToothSurfaceType.FACIAL) {
    return 33 + (32 - toothNumber);
  }

  // Map 17-32 to be 49 through 64. Tabbing is right to left.
  return 32 + toothNumber;
};

// Tab order is Facial 1 through 16, then lingual 16 through 1, then facial 32 through 17, then lingual 17 through 32.
const getToothSurfaceNumberLeftAutoAdvance = (toothNumber: number, surface: PerioChartToothSurfaceType) => {
  if (toothNumber <= 16) {
    if (surface === PerioChartToothSurfaceType.FACIAL) {
      // Map 17-1 to be 1 through 16 so tab goes form right to left.
      return 17 - toothNumber;
    }

    // Map 1-16 to be 17 through 32 so tab goes from left to right.
    return 16 + toothNumber;
  }

  // Map 32-17 to be 33 through 48. Tabbing right to left.
  if (surface === PerioChartToothSurfaceType.FACIAL) {
    return 16 + toothNumber;
  }

  // Map 32-17 to be 49 through 64. Tabbing is left to right;
  return 49 + (32 - toothNumber);
};

const sequenceToMultiplier: Record<PerioChartSequenceType, number> = {
  PROBING: 0,
  GINGMARGIN: 1,
  AUTOCAL: 2,
  MUCOGINGJUNC: 3,
  MOBILITY: 4,
  FURCATION: 5,
  PLAQUE: 6,
  CALCULUS: 7,
  BLEEDING: 8,
  SUPPURATION: 9,
};

export const getTabIndexRange = (
  toothNumber: number,
  surface: PerioChartToothSurfaceType,
  sequenceType: PerioChartSequenceType,
  autoAdvance: PerioChartAutoAdvance
) => {
  const toothSurfaceNumber =
    autoAdvance === "RIGHT"
      ? getToothSurfaceNumberRightAutoAdvance(toothNumber, surface)
      : getToothSurfaceNumberLeftAutoAdvance(toothNumber, surface);
  const tabIndexesPerSequence = MAX_TOOTH_SURFACES * MAX_CONTROLS_PER_TOOTH_SEQUENCE;
  const sequenceRangeMultiplier = sequenceToMultiplier[sequenceType];
  const startOfSequenceRange = tabIndexesPerSequence * sequenceRangeMultiplier;
  const startingTabIndex = startOfSequenceRange + toothSurfaceNumber * MAX_CONTROLS_PER_TOOTH_SEQUENCE;

  const tabRange = [startingTabIndex, startingTabIndex + 1, startingTabIndex + 2];

  const tabDirection = getTabDirection(surface, autoAdvance);

  if (tabDirection === TabDirection.BACKWARD) {
    tabRange.reverse();
  }

  return tabRange;
};

const facialLeftHalfFields: PerioChartExamEntryField[] = ["dbValue", "bValue", "mbValue"];
const lingualLeftHalfFields: PerioChartExamEntryField[] = ["dlValue", "lValue", "mlValue"];
const facialRightHalfFields: PerioChartExamEntryField[] = ["mbValue", "bValue", "dbValue"];
const lingualRightHalfFields: PerioChartExamEntryField[] = ["mlValue", "lValue", "dlValue"];

export const getFieldNames = (surface: PerioChartToothSurfaceType, toothNumber: number) => {
  let fieldsToUse: PerioChartExamEntryField[];

  if ((toothNumber >= 1 && toothNumber <= 8) || (toothNumber >= 25 && toothNumber <= 32)) {
    fieldsToUse =
      surface === PerioChartToothSurfaceType.FACIAL ? facialLeftHalfFields : lingualLeftHalfFields;
  } else {
    fieldsToUse =
      surface === PerioChartToothSurfaceType.FACIAL ? facialRightHalfFields : lingualRightHalfFields;
  }

  return fieldsToUse;
};

export const SEQUENCE_TYPES_ORDERED: PerioChartSequenceType[] = [
  "PROBING",
  "GINGMARGIN",
  "AUTOCAL",
  "MUCOGINGJUNC",
  "MOBILITY",
  "FURCATION",
  "PLAQUE",
  "CALCULUS",
  "BLEEDING",
  "SUPPURATION",
];

export const sequenceToVisibility: Record<PerioChartSequenceType, keyof PerioChartVisibilitySettings> = {
  BLEEDING: "showBleeding",
  SUPPURATION: "showSuppuration",
  PLAQUE: "showPlaque",
  CALCULUS: "showCalculus",
  PROBING: "showProbingDepth",
  MOBILITY: "showMobility",
  GINGMARGIN: "showGingivalMargin",
  FURCATION: "showFurcation",
  MUCOGINGJUNC: "showMGJ",
  AUTOCAL: "showAutoCAL",
};

export const sequenceToShortTitle: Record<PerioChartSequenceType, string> = {
  BLEEDING: "Bleeding",
  SUPPURATION: "Suppuration",
  PLAQUE: "Plaque",
  AUTOCAL: "Auto CAL",
  CALCULUS: "Calculus",
  PROBING: "Prob Dep",
  MOBILITY: "Mob",
  GINGMARGIN: "Ging Marg",
  FURCATION: "Furc",
  MUCOGINGJUNC: "MGJ",
};

export const sequenceToFullTitle: Record<PerioChartSequenceType, string> = {
  PROBING: "Probing Depth",
  GINGMARGIN: "Gingival Margin",
  AUTOCAL: "Auto CAL",
  MUCOGINGJUNC: "MGJ",
  MOBILITY: "Mobility",
  FURCATION: "Furcation",
  PLAQUE: "Plaque",
  CALCULUS: "Calculus",
  BLEEDING: "Bleeding",
  SUPPURATION: "Suppuration",
};

export const sequenceToThresholdSettings: Record<
  PerioChartSequenceType,
  | {
      setting: keyof PerioChartThresholdSettings;
      condition: ThresholdCondition;
    }
  | undefined
> = {
  PROBING: { setting: "probingDepthThreshold", condition: "gte" },
  GINGMARGIN: { setting: "gingivalMarginThreshold", condition: "gte" },
  AUTOCAL: { setting: "autoCALThreshold", condition: "gte" },
  MUCOGINGJUNC: { setting: "mgjThreshold", condition: "lte" },
  MOBILITY: { setting: "mobilityThreshold", condition: "gte" },
  FURCATION: { setting: "furcationThreshold", condition: "gte" },
  CALCULUS: undefined,
  BLEEDING: undefined,
  PLAQUE: undefined,
  SUPPURATION: undefined,
};

export const getControlInfo = (element?: HTMLElement) => {
  if (!element) {
    return undefined;
  }

  const toothNumber = Number(element.dataset.toothNumber);

  if (Number.isNaN(toothNumber)) {
    // Don't clear out the last focus, otherwise buttons like skip tooth won't work.
    return undefined;
  }

  const sequenceType = element.dataset.sequenceType || undefined;
  const surface = Number(element.dataset.surface);
  const tabIndex = element.tabIndex || undefined;
  const fieldName = element.dataset.fieldName || undefined;

  return {
    toothNum: Number.isNaN(toothNumber) ? undefined : toothNumber,
    sequence: sequenceType as PerioChartExamEntryVO["perioSequenceType"] | undefined,
    surface: Number.isNaN(surface) ? undefined : (surface as PerioChartToothSurfaceType),
    tabIndex,
    fieldName: fieldName as PerioChartExamEntryField | undefined,
  };
};

export type PerioControlInfo = ReturnType<typeof getControlInfo>;

export const getExamTeethSummary = (exam: PerioChartExamInfo) => {
  const teethConfigs = [...exam.upperToothConfig, ...exam.lowerToothConfig];
  const totalTeethCount = teethConfigs.filter((toothConfig) => !toothConfig.missing).length;
  const totalImplantCount = teethConfigs.filter(({ implant }) => implant).length;

  return {
    totalTeethCount,
    totalImplantCount,
    totalSiteCount: totalTeethCount * 6,
  };
};
export const getPerioToothName = (toothConfig: PerioChartToothConfigVO) => {
  return `${toothConfig.label}${toothConfig.implant ? "i" : ""}`;
};
