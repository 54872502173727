import React from "react";
import { ClaimOrthoVO } from "@libs/api/generated-api";
import { formatAsISODate, getLocalDate } from "@libs/utils/date";
import { ClaimSection } from "components/Claim/ClaimSection";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";

interface Props {
  isEditing: boolean;
  orthoData: ClaimOrthoVO;
  onChange?: <T extends keyof ClaimOrthoVO>(prop: T, value: ClaimOrthoVO[T] | undefined) => void;
}

export const OrthoSection: React.FC<Props> = ({ isEditing, orthoData, onChange }) => (
  <ClaimSection title="Ortho">
    <div className="flex flex-row items-center gap-3 max-w-[826px]">
      <FormFieldSelectMenusDatepicker
        label="Date of Placement"
        className="w-[268px]"
        edit={isEditing}
        required
        placeholderText="MM/DD/YYYY"
        selected={orthoData.orthoDate ? getLocalDate(orthoData.orthoDate) : undefined}
        onChange={(newDate) => onChange?.("orthoDate", newDate ? formatAsISODate(newDate) : undefined)}
      />
      <FormFieldNumberInput
        label="Months Total"
        className="w-[268px]"
        edit={isEditing}
        value={orthoData.orthoMonthsTotal || undefined}
        onValueChange={(value) => onChange?.("orthoMonthsTotal", value)}
      />
      <FormFieldNumberInput
        label="Months Remaining"
        className="w-[268px]"
        edit={isEditing}
        value={orthoData.orthoMonthsRemaining || undefined}
        onValueChange={(value) => onChange?.("orthoMonthsRemaining", value)}
      />
    </div>
  </ClaimSection>
);
