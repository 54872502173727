import { useMemo } from "react";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { DashboardQueryWithDateNavigation } from "utils/routing/dashboard";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { dashboardFiltersForTables } from "components/Dashboard/utils/filters";

export const useDashboardTableRequestParams = (query: DashboardQueryWithDateNavigation) => {
  const { search, isWaiting } = useDebouncedSearch(query.patientSearch ?? "");
  const { selectedTimeSegmentParams } = useTimeSeriesPageSelections(query);

  return useMemo(
    () => ({
      isSearchWaiting: isWaiting,
      params: {
        patientSearchString: search,
        ...selectedTimeSegmentParams,
        includeTotal: true,
        filters: dashboardFiltersForTables(query.filters),
      },
    }),
    [isWaiting, search, selectedTimeSegmentParams, query.filters]
  );
};
