import { useValidation } from "@libs/hooks/useValidation";
import { maxLength, required } from "@libs/utils/validators";
import { DraftIpFilter, DraftIpFilterKey } from "components/Settings/Security/IPAuthorization/IPAuthSettings";

export const MAX_DESCRIPTION_LENGTH = 50;
export const MAX_IP_ADDRESS_LENGTH = 2000;

export const useValidateDraftIpFilterForm = (
  ipFilter: DraftIpFilter | undefined,
  selectedUsers: Set<number>
) => {
  const ipFilterForm = useValidation(
    {
      address: ipFilter?.address,
      description: ipFilter?.description,
    },
    {
      address: getRequiredStringFieldSchema("address"),
      description: getRequiredStringFieldSchema("description"),
      hasUsers: {
        $validations: [
          {
            $v: () => ipFilter?.appliesToAll || selectedUsers.size > 0,
            $error: "Must allow access to at least one employee to create filter",
          },
        ],
      },
    }
  );

  return { ipFilterForm };
};

const getRequiredStringFieldSchema = (field: DraftIpFilterKey) => {
  const [readableField, maxFieldLength] =
    field === "description" ? ["Description", MAX_DESCRIPTION_LENGTH] : ["IP Address", MAX_IP_ADDRESS_LENGTH];

  return {
    $validations: [
      { $v: required, $error: `${readableField} required` },
      {
        $v: maxLength(maxFieldLength),
        $error: `${readableField} cannot exceed ${maxFieldLength} characters"`,
      },
    ],
  };
};
