import { format, isAfter, isMatch, isSameDay, isValid, parse } from "date-fns";
import DatePicker from "react-datepicker";
import { FormEvent, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { cx } from "@libs/utils/cx";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getLocalDate } from "@libs/utils/date";
import { useFormContext, useMergeFormContext } from "@libs/contexts/FormContext";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as CalendarIcon } from "@libs/assets/icons/calendar.svg";
import { FormField, FormFieldProps } from "@libs/components/UI/FormField";
import { cxFormFieldStyle } from "@libs/components/UI/formFieldStyle";
import { Menu } from "@libs/components/UI/Menu";
import { cxButtonMenuStyles } from "@libs/components/UI/ButtonMenu";
import { Form } from "@libs/components/UI/Form";
import { MenuContent } from "components/Dashboard/SelectDateRange/MenuContent";
import { RangeShortcut, useRangeShortcuts } from "components/Dashboard/SelectDateRange/useRangeShortcuts";

const DATE_PREFERRED_FORMAT = "M/d/yyyy";

type Props = {
  id: string;
  startDate?: Date;
  endDate?: Date;
  placeholder?: string;
  maxRangeDays?: number;
  onRangeSelected: (range: { startDate: Date; endDate: Date }) => void;
} & Omit<FormFieldProps, "error">;

const getDateFormat = (date: string) => {
  const formats = ["MM/dd/yy", "M/d/yy", DATE_PREFERRED_FORMAT, "MM/dd/yyyy"];

  return formats.find((currFormat) => isMatch(date, currFormat));
};

export const SelectDateRange: React.FC<Props> = ({
  disabled,
  required,
  label,
  edit,
  layout,
  className,
  id,
  placeholder = "Enter Date Range...",
  onRangeSelected,
  startDate: initialStart,
  endDate: initialEnd,
  maxRangeDays,
}) => {
  const menuOpen = useBoolean(false);
  const formContext = useFormContext();
  const fieldId = useEnsureId({ customId: id });
  const [startDate, setStartDate] = useState<Date | null>(initialStart ?? null);
  const [endDate, setEndDate] = useState<Date | null>(initialEnd ?? null);
  const rangeShortcuts = useRangeShortcuts(startDate, endDate);
  const getDateShortcutMatch = useCallback(
    (start: Date | null, end: Date | null) => {
      return rangeShortcuts.find((shortcut) => {
        return (
          start &&
          end &&
          shortcut.startDate &&
          shortcut.endDate &&
          isSameDay(shortcut.startDate, start) &&
          isSameDay(shortcut.endDate, end)
        );
      });
    },
    [rangeShortcuts]
  );
  const [inputText, setInputText] = useState(
    initialStart && initialEnd && !getDateShortcutMatch(initialStart, initialEnd)
      ? `${format(initialStart, DATE_PREFERRED_FORMAT)} - ${format(initialEnd, DATE_PREFERRED_FORMAT)}`
      : ""
  );

  const error = useMemo(() => {
    if (startDate && endDate && !isSameDay(startDate, endDate) && !isAfter(endDate, startDate)) {
      return "Start date must be before end date";
    }

    return undefined;
  }, [endDate, startDate]);

  const mergedFormContext = useMergeFormContext(formContext, { layout });
  const controlStyles = cxFormFieldStyle.control({
    hasIcon: true,
    hasLabel: Boolean(label),
    layout: mergedFormContext.layout,
  });
  const handleClearDates = useCallback(() => {
    setStartDate(null);
    setEndDate(null);
    setInputText("");
  }, []);

  const handleNewDateSelection = useCallback((start: Date, end: Date, shortcut?: RangeShortcut) => {
    if (shortcut) {
      // Placeholder represents the shortcut label
      setInputText("");
    } else if (isSameDay(start, end)) {
      setInputText(format(start, DATE_PREFERRED_FORMAT));
    } else {
      setInputText(`${format(start, DATE_PREFERRED_FORMAT)} - ${format(end, DATE_PREFERRED_FORMAT)}`);
    }
  }, []);
  const datePickerRef = useRef<DatePicker>(null);

  const currentShortcut = useMemo(
    () => getDateShortcutMatch(startDate, endDate),
    [startDate, endDate, getDateShortcutMatch]
  );
  const handleInputChanged = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const text = e.target.value;

      if (text.trim().length === 0) {
        handleClearDates();

        return;
      }

      const [startString, endString] = text.split("-").map((s) => s.trim());

      const startFormat = getDateFormat(startString);
      const endFormat = getDateFormat(endString);
      const beginningOfTime = getLocalDate("1970-01-01");
      const isValidDate = (date: Date) => isValid(date) && isAfter(date, beginningOfTime);

      if (startFormat) {
        const start = parse(startString, startFormat, new Date());

        if (isValidDate(start)) {
          setStartDate((prior) => {
            if (prior && isSameDay(prior, start)) {
              return prior;
            }

            return start;
          });

          if (endFormat) {
            const end = parse(endString, endFormat, new Date());

            if (isValidDate(end)) {
              setEndDate((prior) => {
                if (prior && isSameDay(prior, end)) {
                  return prior;
                }

                return end;
              });
            }
          } else {
            setEndDate(null);
          }
        }
      }

      setInputText(text);
    },
    [handleClearDates]
  );
  const triggerRef = useRef<HTMLInputElement | null>(null);
  const handleRangeConfirmed = useCallback(
    (e?: FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (startDate && endDate && (isAfter(endDate, startDate) || isSameDay(startDate, endDate))) {
        menuOpen.off();
        triggerRef.current?.blur();

        const shortcut = getDateShortcutMatch(startDate, endDate);

        if (!shortcut) {
          setInputText(
            `${format(startDate, DATE_PREFERRED_FORMAT)} - ${format(endDate, DATE_PREFERRED_FORMAT)}`
          );
        }

        onRangeSelected({
          startDate,
          endDate,
        });
      }
    },
    [startDate, endDate, menuOpen, getDateShortcutMatch, onRangeSelected]
  );
  const lastEndDate = useRef<Date | null>(null);

  useEffect(() => {
    if (endDate && lastEndDate.current !== endDate) {
      lastEndDate.current = endDate;

      if (getDateShortcutMatch(startDate, endDate)) {
        // When selecting shortcuts, we navigate to both start and end of the date picker, producing a glitchy effect.
        // this checks if they're selecting a shortcut timespan and disables toggling the end preSelection in that case
        return;
      }

      datePickerRef.current?.setState((prevState) => {
        return {
          ...prevState,
          preSelection: endDate,
        };
      });
    }
  }, [endDate, getDateShortcutMatch, startDate]);

  return (
    <Form onSubmit={handleRangeConfirmed}>
      <FormField
        disabled={disabled}
        required={required}
        label={label}
        error={error}
        edit={edit}
        displayErrorMessage={false}
        layout={mergedFormContext.layout}
        className={className}
        id={fieldId}
      >
        <div className={cxFormFieldStyle.wrapper}>
          <input
            onMouseDown={menuOpen.on}
            ref={triggerRef}
            type="text"
            id={fieldId}
            onBlur={() => {
              if (startDate && endDate) {
                handleNewDateSelection(startDate, endDate, currentShortcut);
              }
            }}
            value={inputText}
            onChange={handleInputChanged}
            disabled={disabled}
            placeholder={currentShortcut?.label ?? placeholder}
            className={cx(
              "cursor-text focus-visible:outline-none",
              currentShortcut ? "placeholder:text-greyDark" : "placeholder:text-greyLight",
              controlStyles
            )}
          />

          <div
            className={cxFormFieldStyle.iconContainer({
              layout: mergedFormContext.layout,
              clickable: true,
            })}
          >
            <FloatingTooltip theme="SMALL" content="Select Day or Range">
              <CalendarIcon role="img" className={cxFormFieldStyle.icon({ disabled })} />
            </FloatingTooltip>
          </div>
          {menuOpen.isOn ? (
            <Menu
              className={cxButtonMenuStyles.container({ includeDarkMode: true })}
              onRequestClose={(e) => {
                if (e?.target instanceof HTMLElement && e.target.id === fieldId) {
                  // Need to test if they clicked input in clicking outside
                  return;
                }

                menuOpen.off();
              }}
              triggerRef={triggerRef}
              placement="bottom-end"
            >
              <MenuContent
                startDate={startDate}
                endDate={endDate}
                ref={datePickerRef}
                maxRangeDays={maxRangeDays}
                onChangeStart={(date) => {
                  setStartDate(date);

                  if (date) {
                    setInputText(`${format(date, DATE_PREFERRED_FORMAT)} - `);
                  }
                }}
                onChangeEnd={(date) => {
                  setEndDate(date);

                  if (date && startDate) {
                    handleNewDateSelection(startDate, date);
                  }
                }}
                onClear={handleClearDates}
                onCancel={menuOpen.off}
                onRangeConfirmed={handleRangeConfirmed}
                onSelectShortcut={(shortcut) => {
                  setStartDate(shortcut.startDate);
                  setEndDate(shortcut.endDate);
                  setInputText("");
                }}
                shortcutOptions={rangeShortcuts}
              />
            </Menu>
          ) : null}
        </div>
      </FormField>
    </Form>
  );
};
