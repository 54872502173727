import React from "react";
import { formatDuration, intervalToDuration } from "date-fns";
import { ReportingRecallByPatientEntryVO } from "@libs/api/generated-api";
import { phoneNumberFormatter } from "@libs/utils/phone";
import { formatCurrency } from "@libs/utils/currency";
import { formatISODate, getLocalDate } from "@libs/utils/date";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { EMPTY_CELL, Row, CheckboxCell } from "@libs/components/UI/GridTableComponents";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";
import { paths } from "utils/routing/paths";
import { AppointmentDateLinkCell } from "components/Dashboard/PracticeProduction/TableItems/AppointmentDateLinkCell";
import { PatientNameLink } from "components/Dashboard/Tables/PatientNameLink";
import { getDaysOverdue } from "components/Dashboard/RecallReport/utils";
import { MessagePatientCell } from "components/Dashboard/Tables/MessagePatientCell";

type Props = {
  row: ReportingRecallByPatientEntryVO;
  last?: boolean;
  checked: boolean;
  onCheckboxChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
};

export const RecallReportTableByPatientRow: React.FC<Props> = ({ row, last, checked, onCheckboxChange }) => {
  const {
    patient,
    patientPhone,
    remainingInsuranceBenefitAmount,
    scheduledAppointmentId,
    scheduledDate,
    previousAppointmentDate,
    previousAppointmentId,
    carrier,
    dueAge,
    dueDate,
    previousDate,
  } = row;

  const cellProps = { last, to: paths.patient({ patientId: patient.id }) };
  const prevApptDetails = {
    appointmentId: previousAppointmentId,
    patientId: patient.id,
  };
  const nextApptDetails = {
    appointmentId: scheduledAppointmentId,
    patientId: patient.id,
  };
  const dueAgeText = React.useMemo(() => {
    const isOverdue = dueAge >= 0;

    const dateDue = getLocalDate(dueDate);
    const durations = intervalToDuration({
      start: isOverdue ? new Date() : dateDue,
      end: isOverdue ? dateDue : new Date(),
    });
    const duration = formatDuration({ ...durations, hours: 0, minutes: 0, seconds: 0 });

    return isOverdue ? `${duration} overdue` : `in ${duration}`;
  }, [dueDate, dueAge]);

  return (
    <Row isSelected={checked} data-testid={`row-${patient.id}`}>
      <CheckboxCell
        styleOptions={{ border: !last }}
        value={patient.id}
        checked={checked}
        onChange={onCheckboxChange}
      />
      <DashboardLinkCell {...cellProps}>
        <div className="truncate">
          <PatientNameLink patient={patient} />
        </div>
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        {patientPhone ? phoneNumberFormatter(patientPhone) : EMPTY_CELL}
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        <div className="truncate">{carrier?.name ?? EMPTY_CELL}</div>
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        {remainingInsuranceBenefitAmount ? formatCurrency(remainingInsuranceBenefitAmount) : EMPTY_CELL}
      </DashboardLinkCell>
      <AppointmentDateLinkCell {...cellProps} {...nextApptDetails} date={scheduledDate} />
      <DashboardLinkCell {...cellProps}>{getDaysOverdue(dueAge)}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        <FloatingTooltip content={dueAgeText} theme="MEDIUM">
          <div>{formatISODate(dueDate, "MM/dd/yyyy")}</div>
        </FloatingTooltip>
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        {previousDate ? formatISODate(previousDate, "MM/dd/yyyy") : EMPTY_CELL}
      </DashboardLinkCell>
      <AppointmentDateLinkCell {...cellProps} {...prevApptDetails} date={previousAppointmentDate} />
      <MessagePatientCell {...cellProps} patientId={patient.id} />
    </Row>
  );
};
