import { useMemo } from "react";
import { TimeSeriesResponse } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { TimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { useTimeseriesTemplateForResolution } from "components/Dashboard/hooks/useTimeseriesTemplateForResolution";
import { TimeSeriesResolutionOption } from "utils/routing/dashboard";
import { useTimeseriesPreviousData } from "components/Dashboard/Charting/hooks/useTimeseriesPreviousData";
import { CHART_COLORS, ColorScheme } from "components/Dashboard/Charting/theme";
import { useTimeseriesAsStacks } from "components/Dashboard/Charting/hooks/useTimeseriesAsStacks";
import { FilteredProviderType } from "utils/routing/dashboard/serializedFilter";

const PROVIDER_KEYS: FilteredProviderType[] = ["DENTIST", "HYGIENIST", "FRONT_OFFICE", "BACK_OFFICE"];

export const useProvidersAsTimeSeriesData = ({
  workingHourTimeseriesQuery,
  resolution,
  dateWindow,
}: {
  workingHourTimeseriesQuery: ApiQueryResult<TimeSeriesResponse>;
  resolution: TimeSeriesResolutionOption;
  dateWindow: TimeSeriesPageSelections;
}) => {
  const isLoading = workingHourTimeseriesQuery.isLoading;
  const timeline = useTimeseriesTemplateForResolution({ resolution, dateWindow });

  const jobCategoryTimeseries = useTimeseriesAsStacks(
    timeline,
    PROVIDER_KEYS,
    workingHourTimeseriesQuery.data
  );

  const { data, isFirstLoad } = useTimeseriesPreviousData({
    data: jobCategoryTimeseries,
    isLoading,
  });

  return useMemo(() => {
    const colorScheme: ColorScheme<FilteredProviderType> = {
      domain: PROVIDER_KEYS,
      range: [CHART_COLORS.blue, CHART_COLORS.lavender, CHART_COLORS.blueBright, CHART_COLORS.greenBright],
    };

    return {
      isLoading,
      data,
      keys: PROVIDER_KEYS,
      colorScheme,
      isFirstLoad,
    };
  }, [isLoading, data, isFirstLoad]);
};
