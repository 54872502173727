import { FC, PropsWithChildren } from "react";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as LockIcon } from "@libs/assets/icons/lock.svg";

interface Props {
  onCancel: Func;
  isLocked?: boolean;
  disabled: boolean;
}

export const EditElementFormButtons: FC<PropsWithChildren<Props>> = ({ isLocked, disabled, onCancel }) => {
  return (
    <div
      className={`
        border-t-greyLighter
        border-t
        items-center
        flex-none
        justify-end
        flex
        px-6
        py-4
      `}
    >
      {isLocked ? (
        <div className="mr-auto flex items-center gap-x-1 text-xs text-greyMedium">
          <LockIcon className="flex-none w-4 h-4 inline-block" />{" "}
          <div>Question required by Archy with limited editing available.</div>
        </div>
      ) : null}
      <Button className="min-w-20 text-sm" theme="link" size="small" onClick={onCancel}>
        Cancel
      </Button>
      <Button disabled={disabled} className="min-w-20" theme="primary" type="submit">
        Save
      </Button>
    </div>
  );
};
