import { FC } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { Rooms } from "components/Settings/PracticeSetup/Sections/Rooms/Rooms";

export const PracticeRoomsRoute: FC = () => {
  const isEditing = useBoolean(false);

  return (
    <SettingsPanel
      isEditing={isEditing.isOn}
      title="Rooms"
      description="Setup your clinic's rooms"
      includePadding={false}
    >
      <Rooms />
    </SettingsPanel>
  );
};
