import React, { FormEventHandler, useMemo, useCallback, useState } from "react";
import { EmploymentDetailsVO, UpdateEmployeeRequest, CompensationDetailsVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { hasFailedPin } from "@libs/utils/hasFailedPin";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { useValidation } from "@libs/hooks/useValidation";
import { useAccount } from "@libs/contexts/AccountContext";
import { CurrencyInputOnChangeValues } from "react-currency-input-field";
import { isDefined } from "@libs/utils/types";
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { MailToLink } from "components/UI/MailToLink";
import { updateEmployee } from "api/employee/mutations";
import { useServerErrorHandler } from "hooks/useServerErrorHandler";
import { useItemModal } from "hooks/useItemModal";
import { EmployeePinModal } from "components/Employee/EmployeePinModal";
import { EmploymentDetailsForm } from "components/EmployeeProfile/EmploymentDetails/EmploymentDetailsForm";
import { getEmploymentSchema } from "components/Employee/schema";
import { EmploymentDetailsFormState, EmploymentDetailsProps } from "components/EmployeeProfile/types";
import { EmploymentPanel } from "components/EmployeeProfile/EmploymentDetails/EmploymentPanel";

interface Props extends EmploymentDetailsProps {
  onDoneEditing: () => void;
  canEditAccess: boolean;
}

const FORM_ID = "employment-form";

// eslint-disable-next-line complexity
export const EditEmploymentDetails: React.FC<Props> = ({
  employeeId,
  employeeRole,
  employmentDetails,
  assignableRoles,
  taxonomyCodes,
  canEditAccess,
  onDoneEditing,
  payrollEnabled,
  employeePayrollOnboardingQuery,
  jobTitles,
}) => {
  const { practiceId } = useAccount();
  const [updateEmployeeMutation] = useApiMutations([updateEmployee]);
  const { serverErrors, clearServerErrors, handleException } = useServerErrorHandler<UpdateEmployeeRequest>();
  const pinModal = useBoolean(false);

  const confirmArchiveModal = useItemModal<{ endDate: string }>(null);
  const confirmActiveModal = useItemModal<{ accessDate: string; startDate?: string }>(null);

  const [formState, setFormState] = useState<EmploymentDetailsFormState>({
    employmentDetails,
    roleV2Id: employeeRole.roleId,
  });

  const handleEmploymentChange = (updates: Partial<EmploymentDetailsVO>) => {
    setFormState((last) => ({ ...last, employmentDetails: { ...last.employmentDetails, ...updates } }));
  };

  const [amount, setAmount] = useState<Omit<CurrencyInputOnChangeValues, "formatted">>(() => ({
    value: isDefined(employmentDetails.compensationDetails?.amount)
      ? `${employmentDetails.compensationDetails.amount}`
      : "",
    float: employmentDetails.compensationDetails?.amount ?? 0,
  }));

  const { roleV2Id: formRoleId, ...formEmploymentDetails } = formState;

  const schema = useMemo(
    () =>
      getEmploymentSchema({
        period: formState.employmentDetails.compensationDetails?.period,
        amount: formState.employmentDetails.compensationDetails?.amount,
        status: formState.employmentDetails.status,
        isExcludedFromPayroll: Boolean(formState.employmentDetails.excludeFromPayroll),
      }),
    [
      formState.employmentDetails.status,
      formState.employmentDetails.excludeFromPayroll,
      formState.employmentDetails.compensationDetails,
    ]
  );

  const validation = useValidation(formState, schema);

  const getFormData: () => UpdateEmployeeRequest = useCallback(() => {
    const formCompensationDetails = formEmploymentDetails.employmentDetails.compensationDetails;
    const formStatus = formEmploymentDetails.employmentDetails.status;
    const formEndDate = formEmploymentDetails.employmentDetails.endDate;

    return {
      employmentDetails: {
        ...formEmploymentDetails.employmentDetails,
        endDate: formStatus !== "ARCHIVED" && Boolean(formEndDate) ? undefined : formEndDate,
        compensationDetails:
          formEmploymentDetails.employmentDetails.excludeFromPayroll || !formCompensationDetails
            ? undefined
            : {
                ...formCompensationDetails,
                amount: formCompensationDetails.amount,
                compensationType: "FLAT_RATE",
              },
      },
      roleV2Id: formRoleId,
    };
  }, [formEmploymentDetails, formRoleId]);

  const handleAmountChange = useCallback(
    (newAmount: CurrencyInputOnChangeValues | undefined) => {
      setFormState((last) => ({
        ...last,
        employmentDetails: {
          ...last.employmentDetails,
          compensationDetails: last.employmentDetails.compensationDetails
            ? {
                ...last.employmentDetails.compensationDetails,
                amount: newAmount?.float ?? undefined,
              }
            : {
                compensationType: "FLAT_RATE",
                amount: newAmount?.float ?? undefined,
              },
        },
      }));
      setAmount(newAmount ?? { value: "", float: 0 });
    },
    [setFormState]
  );

  const handleCompensationChange = useCallback(
    (partial: Partial<CompensationDetailsVO>) =>
      setFormState((last) => ({
        ...last,
        employmentDetails: {
          ...last.employmentDetails,
          compensationDetails: last.employmentDetails.compensationDetails
            ? {
                ...last.employmentDetails.compensationDetails,
                ...partial,
              }
            : {
                ...partial,
                compensationType: "FLAT_RATE",
              },
        },
      })),
    [setFormState]
  );

  const shouldConfirmStatusChange =
    (employmentDetails.status === "ACTIVE" && formState.employmentDetails.status === "ARCHIVED") ||
    (employmentDetails.status === "ARCHIVED" && formState.employmentDetails.status === "ACTIVE");

  const confirmStatusChange = () => {
    if (
      employmentDetails.status === "ACTIVE" &&
      formState.employmentDetails.status === "ARCHIVED" &&
      formState.employmentDetails.endDate
    ) {
      confirmArchiveModal.open({ endDate: formatISODate(formState.employmentDetails.endDate) });
    }

    if (
      employmentDetails.status === "ARCHIVED" &&
      formState.employmentDetails.status === "ACTIVE" &&
      formState.employmentDetails.accessDate
    ) {
      confirmActiveModal.open({
        accessDate: formatISODate(formState.employmentDetails.accessDate),
        startDate: formState.employmentDetails.excludeFromPayroll
          ? undefined
          : formState.employmentDetails.startDate,
      });
    }
  };

  const mutateEmployee = () => {
    if (confirmArchiveModal.isOpen) {
      confirmArchiveModal.close();
    }

    if (confirmActiveModal.isOpen) {
      confirmActiveModal.close();
    }

    const data = getFormData();

    updateEmployeeMutation.mutate(
      {
        employeeId,
        practiceId,
        data,
      },
      {
        onSuccess: onDoneEditing,
        onError: (err) => {
          if (hasFailedPin(err)) {
            pinModal.on();
          } else {
            handleException(err);
          }
        },
      }
    );
  };

  const handleSubmit: FormEventHandler = (e) => {
    e.preventDefault();
    clearServerErrors();

    if (validation.validate().$isValid) {
      if (shouldConfirmStatusChange) {
        confirmStatusChange();

        return;
      }

      mutateEmployee();
    }
  };

  const isArchived = employmentDetails.status === "ARCHIVED";
  // Start date should be disabled if the employee is archived, unless
  // employee needs status changed from archived to active for rehire.
  const disabledStartDate =
    (isArchived && formState.employmentDetails.status !== "ACTIVE") ||
    formState.employmentDetails.excludeFromPayroll;
  // End date should be disabled if employee is already archived, or enabled for
  // archived status only (should be disabled for all other statuses)
  const disabledEndDate =
    isArchived ||
    formState.employmentDetails.status !== "ARCHIVED" ||
    formState.employmentDetails.excludeFromPayroll;

  return (
    <EmploymentPanel
      actions={
        <ButtonIcon
          SvgIcon={CancelIcon}
          tooltip={{ content: "Cancel Editing", theme: "SMALL" }}
          onClick={onDoneEditing}
          theme="primary"
        />
      }
      footer={
        <div className="flex items-center justify-center gap-x-3">
          <Button className="min-w-button" theme="secondary" onClick={onDoneEditing}>
            Cancel
          </Button>
          <AsyncButton
            className="min-w-button"
            isLoading={updateEmployeeMutation.isLoading}
            type="submit"
            form={FORM_ID}
          >
            Save
          </AsyncButton>
        </div>
      }
    >
      <EmploymentDetailsForm
        employeeId={employeeId}
        formId={FORM_ID}
        formState={formState}
        taxonomyCodes={taxonomyCodes}
        assignableRoles={assignableRoles}
        employeeRoleV2={employeeRole}
        validationResult={validation.result}
        serverErrors={serverErrors}
        onEmploymentChange={handleEmploymentChange}
        onRoleChange={(val) => setFormState((last) => ({ ...last, roleV2Id: val }))}
        onCompensationChange={handleCompensationChange}
        onAmountChange={handleAmountChange}
        amount={amount}
        onSubmit={handleSubmit}
        isEditing
        disabledStartDate={disabledStartDate}
        disabledEndDate={disabledEndDate}
        payrollEnabled={payrollEnabled}
        canEditPayrollSettings={payrollEnabled && !employmentDetails.excludeFromPayroll}
        canEditAccess={canEditAccess}
        employeePayrollOnboardingQuery={employeePayrollOnboardingQuery}
        jobTitles={jobTitles}
      />

      {confirmArchiveModal.isOpen ? (
        <ConfirmationModal
          primaryText="Are you sure you want to terminate this employee?"
          secondaryText={
            <span className="flex flex-col gap-y-4">
              They will lose access to the app, and their end date will be {confirmArchiveModal.item.endDate}.
              {payrollEnabled ? (
                <span>
                  Please contact Archy support at <MailToLink email="support@archy.com" /> to run a dismissal
                  payroll for this employee.
                </span>
              ) : null}
            </span>
          }
          onCancel={confirmArchiveModal.close}
          onConfirm={mutateEmployee}
        />
      ) : null}
      {confirmActiveModal.isOpen ? (
        <ConfirmationModal
          primaryText="Are you sure you want to mark this employee as Active?"
          secondaryText={
            payrollEnabled && confirmActiveModal.item.startDate ? (
              <span className="flex flex-col gap-y-4">
                They will regain access to the app.
                <span>
                  To reinstate them into the payroll process with {confirmActiveModal.item.startDate} as their
                  new start date, please contact Archy support at <MailToLink email="support@archy.com" />.
                </span>
              </span>
            ) : (
              `They will regain access to the app and their new start date will be ${confirmActiveModal.item.accessDate}.`
            )
          }
          onCancel={confirmActiveModal.close}
          onConfirm={mutateEmployee}
        />
      ) : null}
      {pinModal.isOn && (
        <EmployeePinModal
          onPinSuccess={() => {
            mutateEmployee();
            pinModal.off();
          }}
          onClose={pinModal.off}
        />
      )}
    </EmploymentPanel>
  );
};
