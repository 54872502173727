import { FC, ReactNode } from "react";
import { FormSubmissionVO } from "@libs/api/generated-api";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as EditIcon } from "@libs/assets/icons/edit.svg";
import { EditResponses } from "components/PatientProfile/Forms/EditResponses";
import { ViewResponses } from "components/PatientProfile/Forms/ViewResponses";

interface Props {
  formSubmission: FormSubmissionVO;
  patientId: number;
  noResponses: ReactNode;
  isEditing: boolean;
  onRequestEdit: Func;
  onCloseEdit: Func;
}

export const FormResponsePanel: FC<Props> = ({
  patientId,
  formSubmission,
  noResponses,
  isEditing,
  onRequestEdit,
  onCloseEdit,
}) => {
  return (
    <div className="px-6 py-3">
      <div className="flex justify-between gap-x-2">
        <span className="text-base font-sansSemiBold">{formSubmission.form.title}</span>
        <ButtonIcon
          tooltip={{ content: "Edit Responses", theme: "SMALL" }}
          SvgIcon={EditIcon}
          theme="primary"
          onClick={onRequestEdit}
        />
      </div>
      <div className="py-6">
        <ViewResponses formSubmission={formSubmission} noResponses={noResponses} />
      </div>
      {isEditing ? (
        <EditResponses
          onClose={onCloseEdit}
          title={formSubmission.form.title}
          uuid={formSubmission.form.uuid}
          slug={formSubmission.form.slug}
          responses={formSubmission.responses}
          patientId={patientId}
        />
      ) : null}
    </div>
  );
};
