import { minutesToMilliseconds } from "date-fns";
import { makeQuery } from "@libs/utils/queries";
import { fiveMinuteCache, oneMinuteCache } from "@libs/utils/queryCache";

export const getFolders = makeQuery({
  queryKey: ["practices", "getFolders"],
  formatParams: (args: { practiceId: number; userId: number }) => [args.practiceId, args.userId],
  queryOptions: {
    ...oneMinuteCache,
  },
});

export const getFolderDocuments = makeQuery({
  queryKey: ["practices", "getFolderDocuments"],
  formatParams: (args: {
    practiceId: number;
    userId: number;
    folderId: number;
    includeMetadata: boolean;
  }) => [args.practiceId, args.userId, args.folderId, { includeMetadata: args.includeMetadata }],
  queryOptions: {
    ...oneMinuteCache,
  },
});

export const searchDocument = makeQuery({
  queryKey: ["practices", "searchDocument"],
  formatParams: (args: { practiceId: number; userId: number; query: { searchString: string } }) => [
    args.practiceId,
    args.userId,
    args.query,
  ],
  queryOptions: {
    ...oneMinuteCache,
  },
});

export const getDocumentUrl = makeQuery({
  queryKey: ["practices", "getPreSignedUrl"],
  formatParams: (args: {
    practiceId: number;
    userId: number;
    folderId: number;
    documentId: number;
    download?: boolean;
  }) => [
    args.practiceId,
    args.userId,
    args.folderId,
    args.documentId,
    { ...(args.download && { download: args.download }) },
  ],
  queryOptions: {
    ...fiveMinuteCache,
    // Pre-signed URLs are only valid for 5 minutes, so auto-refetch a new URL
    // after expiration.
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    refetchInterval: minutesToMilliseconds(5),
  },
});

export const getDocument = makeQuery({
  queryKey: ["practices", "getDocument"],
  formatParams: (args: {
    practiceId: number;
    userId: number;
    documentId: number;
    includeMetadata: boolean;
  }) => [args.practiceId, args.userId, args.documentId, { includeMetadata: args.includeMetadata }],
  queryOptions: {
    ...oneMinuteCache,
  },
});

export const getCustomPatientFolders = makeQuery({
  queryKey: ["practices", "getCustomPatientFolders"],
  formatParams: (args: { practiceId: number }) => [args.practiceId],
  queryOptions: {
    ...oneMinuteCache,
  },
});
