import React from "react";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";
import { ProviderFilterOption } from "components/Dashboard/hooks/useFormattedProviderRollups";
import { useProviderFilterHandler } from "components/Dashboard/PracticeProduction/hooks/useProviderFilterHandler";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { useFilteredProviderCategory } from "components/Dashboard/hooks/useFilteredProviderCategory";

export type Props = {
  providerFilterOptions: ProviderFilterOption[];
  onFiltersChanged: (filters: DashboardFilter[]) => void;
  filters: DashboardFilter[];
};
export const TableSelectProvider: React.FC<Props> = ({
  providerFilterOptions,
  filters,
  onFiltersChanged,
}) => {
  const providerFilters = useFilteredProviderCategory(filters);
  const handleProviderFilterClicked = useProviderFilterHandler({
    onUpdateFilters: ({ filters: newFilters }) => onFiltersChanged(newFilters),
    filters,
  });
  const providerOptions = React.useMemo(() => {
    const items = [];

    for (const category of providerFilterOptions) {
      items.push({
        value: category.provider.jobCategory ?? "ALL",
        provider: category.provider,
        label: category.title,
      });

      for (const { provider } of category.items ?? []) {
        if (provider.name?.id) {
          items.push({
            value: `${provider.name.id}`,
            label: provider.name.shortDisplayName,
            provider,
          });
        }
      }
    }

    return items;
  }, [providerFilterOptions]);

  return (
    <FormFieldSelect
      value={
        providerFilters.providerId
          ? `${providerFilters.providerId}`
          : ((providerFilters.providerJob ?? "ALL") as string)
      }
      isClearable={false}
      placeholder="Select Provider"
      className="min-w-40"
      options={providerOptions}
      closeMenuOnSelect={false}
      onChange={(e) => {
        handleProviderFilterClicked(e?.provider);
      }}
    />
  );
};
