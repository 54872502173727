/* eslint-disable @typescript-eslint/naming-convention */

import { isDefined } from "@libs/utils/types";
import { formatISODate } from "@libs/utils/date";
import { HeaderCell, Row, TableGrid, TextCell } from "@libs/components/UI/GridTableComponents";
import { PerioTrendChangeCell } from "components/Charting/Perio/PerioCompare/PerioTrendChangeCell";

const COLUMNS = [
  {
    label: "Date",
    width: "11rem",
  },
  {
    label: "Worsened 2+ mm",
    width: "1fr",
  },
  {
    label: "Worsened 1mm",
    width: "1fr",
  },
  {
    label: "Stable",
    width: "1fr",
  },
  {
    label: "Improved 1mm",
    width: "1fr",
  },
  {
    label: "Improved 2+ mm",
    width: "1fr",
  },
];

type TableRow = {
  date: string;
  uuid: string;
  values: {
    count: number;
    percent: number;
  }[];
};

const getFindingForCellChange = (delta: number, i: number) => {
  return i === 0 || i === 1
    ? delta > 0
      ? "negative"
      : delta < 0
        ? "positive"
        : undefined
    : // eslint-disable-next-line @typescript-eslint/no-magic-numbers
      i === 3 || i === 4
      ? delta < 0
        ? "negative"
        : delta > 0
          ? "positive"
          : undefined
      : undefined;
};
const DepthChangeTableRow: React.FC<{
  row: TableRow;
  rowIndex: number;
  isLast?: boolean;
  previousRow: TableRow;
}> = ({ row, rowIndex, isLast = false, previousRow }) => {
  const sharedProps = {
    border: !isLast,
    className: "border-r last:border-r-0 text-xs",
  };

  return (
    <Row>
      <TextCell {...sharedProps}>
        {formatISODate(previousRow.date)} - {formatISODate(row.date)}
      </TextCell>
      {row.values.map(({ count, percent }, i) => {
        const prevCount = previousRow.values[i].count;
        const delta = isDefined(prevCount) && prevCount !== 0 ? (count - prevCount) / Math.abs(prevCount) : 0;
        const isEmpty = rowIndex === 0 && i !== row.values.length - 1;
        const finding = getFindingForCellChange(delta, i);

        return (
          <PerioTrendChangeCell
            index={i}
            delta={delta}
            isEmpty={isEmpty}
            percent={percent}
            finding={finding}
            key={i}
            {...sharedProps}
            count={count}
          />
        );
      })}
    </Row>
  );
};

export const PerioDepthTrendTable: React.FC<{
  data: TableRow[];
}> = ({ data }) => {
  return (
    <TableGrid
      className="border border-slate-300 rounded-md"
      columnWidths={COLUMNS.map(({ width }) => width)}
    >
      <Row>
        {COLUMNS.map(({ label }) => (
          <HeaderCell bgColor="transparent" size="short" key={label} className="border-r last:border-r-0">
            {label}
          </HeaderCell>
        ))}
      </Row>
      {data.map((row, i) => {
        if (i === 0) {
          return null;
        }

        return (
          <DepthChangeTableRow
            key={row.uuid}
            rowIndex={i}
            row={row}
            isLast={i === data.length - 1}
            previousRow={data[i - 1]}
          />
        );
      })}
    </TableGrid>
  );
};
