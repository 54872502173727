import { FC, useState, useMemo, useCallback, FormEvent, useEffect, useRef } from "react";
import { flushSync } from "react-dom";
import {
  ContactVO,
  FamilyMemberResponse,
  InsuranceCarrierVO,
  InsuranceDetailsVO,
  PatientSummaryVO,
  PatientVO,
} from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { isDefined } from "@libs/utils/types";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useObjectState } from "@libs/hooks/useObjectState";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useFamilyRelations } from "@libs/hooks/useRelations";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { RadioList } from "@libs/components/UI/RadioList";
import { useAccount } from "@libs/contexts/AccountContext";
import { FormFieldPatientsAutoComplete } from "components/UI/FormFieldPatientsAutoComplete";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { searchForPatients } from "api/patients/queries";
import { PrimarySubscriberFields } from "components/Patient/PrimarySubscriberFields";
import { AddFamilyMemberFormState, getAddFamilyMemberPostData } from "components/Patient/formPostData";
import { getAddFamilyMemberFormSchema } from "components/Patient/formSchemas";
import {
  DEFAULT_INCLUDED_OPTIONS,
  SelectInsuranceSubscriberType,
} from "components/Patient/SelectInsuranceSubscriberType";
import { ExistingPatient, PatientDraft, Permission } from "components/Patient/types";
import { NO_SELECTED_NUMERIC_ID, usePatientDraft } from "components/Patient/hooks";
import { Divider } from "components/UI/Divider";
import { addFamilyMember } from "api/patients/mutations";
import { handleError } from "utils/handleError";
import { PatientForm, PatientFormProps } from "components/Patient/PatientForm";
import { FlyoverContent, FlyoverFooter, FlyoverForm, FlyoverHeader } from "components/UI/FlyoverComponents";
import { scrollToFirstError } from "utils/scrollToFirstError";
import { familyMembersToContactOptions } from "components/Patient/contactModes";
import { useIsDirty } from "hooks/useIsDirty";

interface Props
  extends Pick<
    PatientFormProps,
    "onClearContact" | "onAddContact" | "onEditContact" | "defaultContactOptions" | "contactSelection"
  > {
  familyMembersPrimary: { name: string; patientId: number }[] | undefined;
  familyMembers: FamilyMemberResponse;
  isGuardian: boolean;
  insuranceCarriers: InsuranceCarrierVO[] | undefined;
  onSelectContact: (patientFirstName: string, matchedPatient: ContactVO) => void;
  onCreate: (displayName: string) => void;
  onDirty: Func;
  onClose: Func;
  patient: PatientVO;
}

const INCLUDED_SUBSCRIBER_OPTIONS_WITHOUT_PRIMARY: Set<InsuranceDetailsVO["type"]> = new Set([
  "PRIMARY_SUBSCRIBER",
  "NONE",
]);

// eslint-disable-next-line complexity
export const AddFamilyMemberForm: FC<Props> = ({
  familyMembersPrimary,
  familyMembers,
  insuranceCarriers,
  isGuardian,
  onCreate,
  patient,
  contactSelection,
  onDirty,
  onClose,
  onAddContact,
  onClearContact,
  onEditContact,
  onSelectContact,
}) => {
  const formRef = useRef<HTMLDivElement>(null);
  const { practiceId } = useAccount();
  const useExistingPatient = useBoolean(true);
  const [existingPatientSearch, setExistingPatientSearch] = useState("");
  const [permissions, setPermissions] = useState(new Set<Permission>(["phi"]));
  const [memberPermissions, setMemberPermissions] = useState(
    new Set<Permission>(isGuardian ? ["guardian", "phi"] : ["phi"])
  );
  const [existingPatient, setUpdateExistingPatient] = useObjectState<ExistingPatient>({
    patientId: NO_SELECTED_NUMERIC_ID,
    relationship: undefined,
  });
  const familyRelations = useFamilyRelations();
  const currentPatientId = patient.id || 0;

  const {
    handleUpdatePatientDraft,
    handleUpdatePrimarySubscriber,
    handleUpdateSelectedPrimarySubscriber,
    patientDraft,
    patientMatch,
    primarySubscriber,
    selectedPrimarySubscriber,
  } = usePatientDraft();

  const handleUpdatePermissionsBasedOnRelationship = useCallback(
    (relationship?: PatientDraft["relationship"]) => {
      if (relationship) {
        if (relationship === "CHILD") {
          setPermissions(new Set<Permission>(["guardian", "phi"]));
        } else {
          setPermissions(new Set<Permission>(["phi"]));
        }
      }
    },
    []
  );

  const handleUpdateExistingPatient = useCallback(
    (existingPatientUpdate: Partial<ExistingPatient>) => {
      handleUpdatePermissionsBasedOnRelationship(existingPatientUpdate.relationship);
      setUpdateExistingPatient(existingPatientUpdate);
    },
    [handleUpdatePermissionsBasedOnRelationship, setUpdateExistingPatient]
  );

  const handleUpdatePatient = useCallback(
    (partial: Partial<PatientDraft>) => {
      handleUpdatePermissionsBasedOnRelationship(partial.relationship);
      handleUpdatePatientDraft(partial);
    },
    [handleUpdatePatientDraft, handleUpdatePermissionsBasedOnRelationship]
  );

  const [searchPatientsQuery] = useApiQueries([
    searchForPatients({
      args: {
        pageNumber: 1,
        pageSize: 10,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "patientCriteria.excludePatientIds": [currentPatientId],
        practiceId,
        searchString: existingPatientSearch.replaceAll(/[^\d\sa-z]/gi, ""),
      },
      queryOptions: {
        enabled: false,
      },
    }),
  ]);

  // When a user is attempting to add a new patient vs adding an
  // existing one as a family member, they match the name and
  // dob of an existing patient. By settings the match as the
  // existingPatient.patientId this will allow the autocomplete
  // component to display the matched patient.
  const handleSelectPatientMatch = useCallback(
    (matchedPatient: PatientSummaryVO) => {
      handleUpdateExistingPatient({ patientId: matchedPatient.id, relationship: patientDraft.relationship });
      useExistingPatient.on();
    },
    [handleUpdateExistingPatient, useExistingPatient, patientDraft.relationship]
  );

  const patientSearchOptions = useMemo(() => {
    return existingPatient.patientId && existingPatient.patientId === patientMatch?.id
      ? [patientMatch]
      : searchPatientsQuery.data;
  }, [existingPatient.patientId, patientMatch, searchPatientsQuery.data]);

  const clearSelectedMatchedPatient = useCallback(() => {
    // If the user edits the patient autocomplete,
    // clear out the selected matched patient
    if (patientMatch?.id === existingPatient.patientId) {
      handleUpdateExistingPatient({ patientId: NO_SELECTED_NUMERIC_ID });
    }
  }, [handleUpdateExistingPatient, patientMatch, existingPatient.patientId]);

  // Patient autocomplete handlers
  const fetchSearchPatients = searchPatientsQuery.refetch;
  const handleSearchPatients = useCallback(() => {
    fetchSearchPatients();
  }, [fetchSearchPatients]);

  const handleUpdateSearch = useCallback(
    (newValue: string) => {
      setExistingPatientSearch(newValue);
      clearSelectedMatchedPatient();
    },
    [clearSelectedMatchedPatient]
  );

  // Pick sensible defaults for primary subscriber
  const primaryOptions = useMemo(() => {
    const options =
      familyMembersPrimary?.map((member) => ({
        label: member.name,
        value: member.patientId,
      })) || [];

    return options;
  }, [familyMembersPrimary]);

  const firstPrimaryOption = primaryOptions[0]?.value;

  useEffect(() => {
    if (primaryOptions.length) {
      handleUpdatePatientDraft({ insuranceSubscriberType: "DEPENDENT" });
      handleUpdateSelectedPrimarySubscriber({ patientId: firstPrimaryOption });
    }
  }, [
    primaryOptions.length,
    handleUpdatePatientDraft,
    handleUpdateSelectedPrimarySubscriber,
    firstPrimaryOption,
  ]);

  // form submission and validations
  const formData: AddFamilyMemberFormState = useMemo(() => {
    return {
      currentPatientId,
      existingPatient,
      memberPermissions,
      isExistingPatient: useExistingPatient.isOn,
      patientDraft,
      permissions,
      primarySubscriber,
      selectedPrimarySubscriber,
    };
  }, [
    currentPatientId,
    existingPatient,
    memberPermissions,
    useExistingPatient.isOn,
    patientDraft,
    permissions,
    primarySubscriber,
    selectedPrimarySubscriber,
  ]);

  useIsDirty(formData, { onDirty });

  const schema = useMemo(() => {
    return getAddFamilyMemberFormSchema({
      currentPatientId,
      familyMemberIdsSet: new Set<number>(
        familyMembersPrimary?.map(({ patientId }) => patientId).filter(isDefined) || []
      ),
      insuranceSubscriberType: patientDraft.insuranceSubscriberType,
      isExistingPatient: useExistingPatient.isOn,
      contactModes: patientDraft.contactModes,
      preferredContactMode: patientDraft.preferredContactMode,
      usingContact: Boolean(contactSelection),
      patientMatch,
      primarySubscriberSubscriberIdType: primarySubscriber.subscriberIdType,
      selectedPrimarySubscriberId: selectedPrimarySubscriber.patientId,
    });
  }, [
    currentPatientId,
    familyMembersPrimary,
    patientDraft.insuranceSubscriberType,
    patientDraft.contactModes,
    patientDraft.preferredContactMode,
    useExistingPatient.isOn,
    contactSelection,
    patientMatch,
    primarySubscriber.subscriberIdType,
    selectedPrimarySubscriber.patientId,
  ]);

  const { result: validation, validate } = useValidation(formData, schema);

  const [addFamilyMemberMutation] = useApiMutations([addFamilyMember]);

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      const result = flushSync(() => validate());

      if (
        result.$isValid &&
        (useExistingPatient.isOn || !patientMatch) &&
        !addFamilyMemberMutation.isLoading
      ) {
        addFamilyMemberMutation.mutate(
          {
            member: getAddFamilyMemberPostData({ ...formData, contactSelection }),
            patientId: patient.id,
            practiceId,
          },
          {
            onSuccess: (response) => {
              onCreate(response.data.data.displayName);
            },
            onError: handleError,
          }
        );
      } else {
        scrollToFirstError(formRef.current);
      }
    },
    [
      validate,
      useExistingPatient.isOn,
      patientMatch,
      addFamilyMemberMutation,
      contactSelection,
      formData,
      patient.id,
      practiceId,
      onCreate,
    ]
  );

  return (
    <FlyoverForm className="flex flex-col flex-1 min-h-0 justify-between" onSubmit={handleSubmit}>
      <FlyoverHeader size="sm" onClose={onClose}>
        Add Family Member
      </FlyoverHeader>
      <FlyoverContent containerRef={formRef} paddingClassName="py-5 px-8">
        <RadioList
          className="mb-6"
          label="Family Member is"
          onChange={useExistingPatient.toggle}
          options={[
            { label: "Existing Patient", value: true },
            { label: "New Patient", value: false },
          ]}
          selectedValue={useExistingPatient.isOn}
        />

        {useExistingPatient.isOn ? (
          <div className="grid grid-cols-2 gap-x-3">
            <FormFieldPatientsAutoComplete
              error={validation.existingPatient.patientId.$error}
              label="Patient Name"
              openMenuOnFocus={Boolean(existingPatientSearch.length)}
              onInputChange={handleUpdateSearch}
              onItemChanged={(value) => handleUpdateExistingPatient({ patientId: value })}
              onSearch={handleSearchPatients}
              options={patientSearchOptions}
              required
              value={existingPatient.patientId}
              isLoading={searchPatientsQuery.isLoading}
            />
            <FormFieldSelect
              error={validation.existingPatient.relationship.$error}
              isSearchable={false}
              label={`Relationship to ${patient.personalDetails.firstName || ""}`}
              onItemSelected={(value) => handleUpdateExistingPatient({ relationship: value })}
              options={familyRelations}
              required
              value={existingPatient.relationship}
            />
          </div>
        ) : (
          <>
            <PatientForm
              displayRelationshipSelect
              onSelectPatientMatch={handleSelectPatientMatch}
              onUpdatePatient={handleUpdatePatient}
              patientDraft={patientDraft}
              patientMatch={patientMatch}
              validations={validation.patientDraft}
              defaultContactOptions={() => {
                return familyMembersToContactOptions([
                  ...familyMembers.linkedFamilyMembers,
                  ...familyMembers.unlinkedFamilyMembers,
                ]);
              }}
              onClearContact={onClearContact}
              onSelectContact={(match) => onSelectContact(patientDraft.firstName, match)}
              onAddContact={onAddContact}
              onEditContact={onEditContact}
              contactSelection={contactSelection}
            />

            <Divider className="my-7 border-dashed" />
            <SelectInsuranceSubscriberType
              includedOptions={
                primaryOptions.length ? DEFAULT_INCLUDED_OPTIONS : INCLUDED_SUBSCRIBER_OPTIONS_WITHOUT_PRIMARY
              }
              insuranceSubscriberType={patientDraft.insuranceSubscriberType}
              onUpdate={(type) => handleUpdatePatientDraft({ insuranceSubscriberType: type })}
            />
            {patientDraft.insuranceSubscriberType === "DEPENDENT" ? (
              <>
                <RadioList
                  className="mt-5"
                  label="Select primary subscriber"
                  onChange={(e) => {
                    const selectedPatientId = Number(e.target.value);

                    handleUpdateSelectedPrimarySubscriber({
                      patientId: selectedPatientId,
                      relationship: undefined,
                    });
                  }}
                  options={primaryOptions}
                  required
                  selectedValue={selectedPrimarySubscriber.patientId}
                />
                {selectedPrimarySubscriber.patientId !== NO_SELECTED_NUMERIC_ID &&
                selectedPrimarySubscriber.patientId !== currentPatientId ? (
                  <div className="grid grid-cols-2 gap-5 mt-2">
                    <FormFieldSelect
                      error={validation.selectedPrimarySubscriber.relationship.$error}
                      isClearable={false}
                      isSearchable={false}
                      label="Relationship to Primary Subscriber"
                      onItemSelected={(value) => {
                        handleUpdateSelectedPrimarySubscriber({ relationship: value });
                      }}
                      options={familyRelations}
                      required
                      value={selectedPrimarySubscriber.relationship}
                    />
                  </div>
                ) : null}
              </>
            ) : patientDraft.insuranceSubscriberType === "PRIMARY_SUBSCRIBER" ? (
              <div className="mt-4">
                <PrimarySubscriberFields
                  insuranceCarriers={insuranceCarriers}
                  onUpdate={handleUpdatePrimarySubscriber}
                  primarySubscriber={primarySubscriber}
                  validations={validation.primarySubscriber}
                />
              </div>
            ) : null}
          </>
        )}

        <Divider className="my-7 border-dashed" />
        <div className="mb-4 text-sm font-sansSemiBold">Permissions</div>
        <div className="grid grid-cols-2 gap-3">
          <CheckboxList
            label={patient.personalDetails.fullName}
            options={[
              { value: "guardian", label: "Is Guardian" },
              { value: "phi", label: "Has PHI Access" },
            ]}
            onChange={(newSet) => setPermissions(newSet)}
            selectedValues={permissions}
          />
          <CheckboxList
            label="Family Member"
            onChange={(newSet) => setMemberPermissions(newSet)}
            options={[
              { value: "guardian", label: "Is Guardian" },
              { value: "phi", label: "Has PHI Access" },
            ]}
            selectedValues={memberPermissions}
          />
        </div>
      </FlyoverContent>
      <FlyoverFooter>
        <AsyncButton
          disabled={Boolean(patientMatch && useExistingPatient.isOff)}
          isLoading={addFamilyMemberMutation.isLoading}
          type="submit"
        >
          Add Family Member
        </AsyncButton>
      </FlyoverFooter>
    </FlyoverForm>
  );
};
