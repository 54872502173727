import { FC } from "react";

import { DosespotEntityVO } from "@libs/api/generated-api";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Pill } from "@libs/components/UI/Pill";

import { Row, LinkCell } from "@libs/components/UI/GridTableComponents";

import { getPrescriberCost } from "components/Erx/utils";
import { paths } from "utils/routing/paths";

interface Props {
  prescriber: DosespotEntityVO;
  isFirst: boolean;
}

export const PrescriberRow: FC<Props> = ({ prescriber, isFirst }) => {
  const { id, name, isProxy, isConfirmed } = prescriber;
  const role = isProxy ? "Proxy" : "Provider";
  const cost = getPrescriberCost({ isProxy, isFirst });
  const status = isConfirmed ? "Approved" : "Provisioning";
  const statusTheme = isConfirmed ? "green" : "blue";

  const cellProps = {
    to: paths.employee({ id }),
    borderColor: "border-slate-200" as const,
  };

  return (
    <Row className="*:flex *:items-center">
      <LinkCell {...cellProps}>{name}</LinkCell>
      <LinkCell {...cellProps}>{role}</LinkCell>
      <LinkCell {...cellProps}>{cost}</LinkCell>
      <LinkCell {...cellProps}>
        <FloatingTooltip
          content={
            isConfirmed
              ? undefined
              : "DoseSpot's approval process may take up to 2 business days to complete. Please check back soon."
          }
        >
          <Pill theme={statusTheme}>{status}</Pill>
        </FloatingTooltip>
      </LinkCell>
    </Row>
  );
};
