import { Button } from "@libs/components/UI/Button";
import { FocusDirection, useFocusManager } from "contexts/FocusManagerContext";
import { usePerioChartActiveTooth } from "./PerioChartActiveToothContext";

const MAX_CONTROLS = 3;

export const PerioChartSkipTooth: React.FC = () => {
  const {
    toothNum: currentToothNum,
    surface: currentSurface,
    tabIndex: currentTabIndex,
  } = usePerioChartActiveTooth();
  const { getFocus } = useFocusManager();

  const handleSkipTooth = () => {
    if (!currentTabIndex) {
      return;
    }

    // Loop through three controls until the toothnumber is different. A tooth only has a max
    // of 3 controls per tooth sequence.
    let searchIndex = currentTabIndex;

    for (let i = 0; i < MAX_CONTROLS; ++i) {
      const nextFocus = getFocus(FocusDirection.NEXT, searchIndex);

      if (!nextFocus) {
        return;
      }

      const toothNumber = Number(nextFocus.dataset.toothNumber);
      const surface = Number(nextFocus.dataset.surface);

      if (toothNumber !== currentToothNum || surface !== (currentSurface as number | undefined)) {
        nextFocus.focus();
        nextFocus.select();

        return;
      }

      searchIndex = nextFocus.tabIndex;
    }
  };

  return (
    <Button
      theme="secondary"
      size="small"
      className="whitespace-nowrap"
      disabled={currentTabIndex === undefined}
      onClick={handleSkipTooth}
    >
      Skip Tooth
    </Button>
  );
};
