/* eslint-disable jsx-a11y/click-events-have-key-events */
import { NotificationIndicator } from "@libs/components/UI/NotificationIndicator";
import { cx } from "@libs/utils/cx";

import { MessagingMenu } from "components/Messaging/MessagingMenu";

import { Avatar } from "components/UI/Avatar";

interface Props {
  className?: string;
  contactName: string;
  avatar?: string;
  message: string;
  timestamp: string;
  isSelected: boolean;
  isRead: boolean;
  isSearch?: boolean;
  onSelectContact: Func;
  onChangeReadStatus: Func;
}

const CHAR_REGEX = /^[a-z]$/i;

export const MessagingContact: React.FC<Props> = ({
  contactName,
  avatar,
  message,
  timestamp,
  isSelected,
  isRead,
  isSearch = false,
  onSelectContact,
  onChangeReadStatus,
}) => {
  // contactName could be a phone number so check to see if it's a phone number
  // or a name
  const name = CHAR_REGEX.test(contactName[0]) ? contactName : "";

  return (
    <div
      aria-label={contactName}
      className={cx(
        "flex items-center gap-x-3 rounded p-3",
        isSelected ? "bg-actionLight" : "bg-white hover:bg-slate-100"
      )}
      onClick={onSelectContact}
      role="button"
      tabIndex={0}
    >
      <NotificationIndicator hasNotifications={!isRead}>
        <Avatar imageUrl={avatar} name={name} />
      </NotificationIndicator>

      <div className="flex flex-col overflow-hidden">
        <span className="text-sm truncate">{contactName}</span>
        <span className="flex text-xs text-greyMedium whitespace-nowrap">
          {isSearch ? null : (
            <>
              {message ? (
                <>
                  <span className="truncate">{message}</span>
                  &nbsp;&bull;&nbsp;
                </>
              ) : null}
              {timestamp}
            </>
          )}
        </span>
      </div>

      <div className="flex-1 flex justify-end">
        <MessagingMenu onChangeReadStatus={onChangeReadStatus} isRead={isRead} />
      </div>
    </div>
  );
};
