import { FC, FormEvent, useState } from "react";
import { AutomationJourneyVO } from "@libs/api/generated-api";
import { email, required } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useAccount } from "@libs/contexts/AccountContext";
import { previewEmailAutomation } from "api/communications/mutations";
import { handleError } from "utils/handleError";
import { SendTestModal } from "components/Communications/Automations/SendTestModal";
import { useCurrentUser } from "contexts/CurrentUserContext";

interface Props {
  onRequestClose: Func;
  journeyType: AutomationJourneyVO["type"];
  emailTemplate: string;
  subject: string;
}

const schema = [
  { $v: required, $error: "Recipient is required" },
  { $v: email, $error: "A valid email address is required" },
];

export const SendEmailTestModal: FC<Props> = ({ onRequestClose, emailTemplate, journeyType, subject }) => {
  const { practiceId } = useAccount();
  const currentUser = useCurrentUser();
  const [recipient, setRecipient] = useState(() => currentUser.email);
  const [previewEmailAutomationMutation] = useApiMutations([previewEmailAutomation]);

  const validation = useValidation(recipient, schema);

  const handleSend = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (validation.validate().$isValid) {
      previewEmailAutomationMutation.mutate(
        {
          practiceId,
          journeyType,
          data: { email: recipient, emailTemplate, subject },
        },
        {
          onSuccess: onRequestClose,
          onError: handleError,
        }
      );
    }
  };

  return (
    <SendTestModal
      title="Send Email Test"
      onClose={onRequestClose}
      onSubmit={handleSend}
      isSaving={previewEmailAutomationMutation.isLoading}
    >
      <FormFieldInput
        label="Recipient"
        required
        value={recipient}
        error={validation.result.$error}
        placeholder="Enter email address..."
        type="email"
        onChange={(e) => setRecipient(e.target.value)}
      />
    </SendTestModal>
  );
};
