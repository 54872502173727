import { FC, ReactNode } from "react";
import { cx } from "@libs/utils/cx";

export const SummaryBanner: FC<{ children: ReactNode; gridColsClassName?: string; className?: string }> = ({
  children,
  gridColsClassName,
  className,
}) => {
  return (
    <div
      role="row"
      className={cx(
        "grid bg-slate-50 border-slate-300 rounded border",
        gridColsClassName ?? "grid-flow-col",
        className
      )}
    >
      {children}
    </div>
  );
};

export const SummaryCell: FC<{ header: string; children: string | ReactNode; className?: string }> = ({
  header,
  children,
  className,
}) => {
  return (
    <div role="cell" className={cx("flex flex-col px-4 py-2", className)}>
      <div className="text-xs">{header}</div>
      <div className="font-sansSemiBold">{children}</div>
    </div>
  );
};
