import { FC, useMemo } from "react";
import { produce } from "immer";
import { FormSectionConditionsVO, FormSectionRequest, PersonalDetailsVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isDefined, isNullish } from "@libs/utils/types";
import { max, min } from "@libs/utils/validators";
import { useObjectState } from "@libs/hooks/useObjectState";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormWrapper } from "components/Settings/Forms/FormWrapper";
import { EditElementFormProps } from "components/Settings/Forms/types";
import { FormFieldMultiSelect } from "components/UI/FormFieldMultiSelect";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";
import { titleSchema } from "components/Settings/Forms/utils";

const GenderOptions: { value: NonNullable<PersonalDetailsVO["gender"]>; label: string }[] = [
  {
    value: "FEMALE",
    label: "Female",
  },
  {
    value: "MALE",
    label: "Male",
  },
  {
    value: "OTHER",
    label: "Other",
  },
  {
    value: "UNKNOWN",
    label: "Unknown",
  },
];

export const getSectionSchema = (conditions?: { minAgeYears?: number; maxAgeYears?: number }) => {
  const maxAgeYears = conditions?.maxAgeYears;
  const minAgeYears = conditions?.minAgeYears;
  const ageError =
    isDefined(maxAgeYears) && isDefined(minAgeYears)
      ? `The patient can't be older than ${minAgeYears} and younger than ${maxAgeYears}`
      : "";

  return {
    title: titleSchema,
    conditions: {
      minAgeYears: [
        {
          $v: isNullish(maxAgeYears) ? () => true : max(maxAgeYears),
          $error: ageError,
          $ignore: isNullish(maxAgeYears),
        },
      ],
      maxAgeYears: [
        {
          $v: isNullish(minAgeYears) ? () => true : min(minAgeYears),
          $error: ageError,
          $ignore: isNullish(minAgeYears),
        },
      ],
    },
  };
};

export const SectionForm: FC<EditElementFormProps<FormSectionRequest>> = ({
  element,
  isEditing,
  onInvalid,
  onDraftChange,
  onSecondaryClick,
  onSubmit,
}) => {
  const [draft, updateDraft, setDraft] = useObjectState(element);

  const hasConditions = useBoolean(() => {
    if (!element.conditions) {
      return false;
    }

    const { genders, maxAgeYears, minAgeYears } = element.conditions;

    return genders.length > 0 || isDefined(maxAgeYears) || isDefined(minAgeYears);
  });
  const schema = useMemo(
    () => getSectionSchema(hasConditions.isOn ? draft.conditions : undefined),
    [draft.conditions, hasConditions.isOn]
  );
  const validation = useValidation(draft, schema);

  const handleSubmit = () => {
    onSubmit({
      ...draft,
      conditions: hasConditions.isOn ? draft.conditions : undefined,
    });

    validation.reset();
  };

  const handleUpdateConditions = (updates: Partial<FormSectionConditionsVO>) => {
    setDraft((last) =>
      produce(last, (next) => {
        next.conditions = {
          ...next.conditions,
          ...updates,
          genders: updates.genders ?? next.conditions?.genders ?? [],
        };
      })
    );
  };

  return (
    <FormWrapper
      element={draft}
      isEditing={isEditing}
      onInvalid={onInvalid}
      onValidate={() => validation.validate().$isValid}
      onDraftChange={onDraftChange}
      onSubmit={handleSubmit}
      onCancel={onSecondaryClick}
    >
      <div className="flex flex-col gap-y-4">
        <FormFieldInput
          label="Title"
          value={draft.title}
          required={true}
          error={validation.result.title.$error}
          onChange={(e) => updateDraft({ title: e.target.value })}
        />
        <Checkbox checked={hasConditions.isOn} onChange={hasConditions.toggle}>
          Conditionally show this section
        </Checkbox>
        {hasConditions.isOn ? (
          <>
            <FormFieldNumberInput
              label="Patient is older than (Years)"
              value={draft.conditions?.minAgeYears}
              clamp
              min={0}
              error={validation.result.conditions.minAgeYears.$error}
              onValueChange={(value) => handleUpdateConditions({ minAgeYears: value })}
            />
            <FormFieldNumberInput
              label="Patient is younger than (Years)"
              min={0}
              clamp
              value={draft.conditions?.maxAgeYears}
              error={validation.result.conditions.maxAgeYears.$error}
              onValueChange={(value) => handleUpdateConditions({ maxAgeYears: value })}
            />
            <FormFieldMultiSelect
              label="Patient gender is"
              options={GenderOptions}
              value={draft.conditions?.genders}
              isClearable={false}
              isSearchable={false}
              displayErrorMessage={false}
              onItemsSelected={(values) => handleUpdateConditions({ genders: values })}
            />
          </>
        ) : null}
      </div>
    </FormWrapper>
  );
};
