import React from "react";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { ReactComponent as SensorIcon } from "assets/icons/imaging/sensor.svg";
import { ReactComponent as SensorNotFoundIcon } from "assets/icons/imaging/sensor-not-found.svg";
import { SelectSensor } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/SelectSensor";
import { ImagingDeviceWithSettings } from "components/PatientProfile/Imaging/hooks/useImagingDevices";
import { useImagingDeviceSettings } from "components/PatientProfile/Imaging/hooks/useImagingDeviceSettings";

type Props = {
  isOpen: boolean;
  onClose: Func;
  onRequestOpen: Func;
  sensorSelected?: string;
  devices: ImagingDeviceWithSettings[];
  onChange: (sensor?: string) => void;
  disabled?: boolean;
};
export const SensorAssignmentMenu: React.FC<Props> = ({
  isOpen,
  onRequestOpen,
  onClose,
  sensorSelected,
  onChange,
  devices,
  disabled,
}) => {
  const { sensorSettings, archyServiceSensorSettings } = useImagingDeviceSettings();
  const sensorSettingFound = React.useMemo(() => {
    return [...sensorSettings, ...archyServiceSensorSettings].find(
      (settings) => settings.label === sensorSelected
    );
  }, [archyServiceSensorSettings, sensorSelected, sensorSettings]);

  const sensorLabel = sensorSelected
    ? `${sensorSettingFound?.overriddenLabel ?? sensorSelected}${
        sensorSettingFound?.calibration ? "" : " (Uncalibrated)"
      }`
    : undefined;

  return (
    <ButtonMenu
      className="flex"
      isOpen={isOpen}
      onRequestOpen={onRequestOpen}
      placement="top-end"
      menuContent={
        <div className="flex flex-col gap-2 p-4 min-w-80">
          <div className="font-sansSemiBold text-sm">Sensor Assignment</div>
          <SelectSensor value={sensorSelected} onChange={onChange} devices={devices} />
        </div>
      }
      onRequestClose={onClose}
    >
      {(props) => (
        <ButtonIcon
          {...props}
          SvgIcon={sensorSettingFound ? SensorIcon : SensorNotFoundIcon}
          className="text-white hover:text-primaryTheme"
          tooltip={
            isOpen
              ? undefined
              : {
                  content: sensorLabel ?? "No sensor assigned",
                  theme: "MEDIUM",
                }
          }
          disabled={disabled}
        />
      )}
    </ButtonMenu>
  );
};
