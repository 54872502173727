import { FC } from "react";
import Skeleton from "react-loading-skeleton";
import { SelfBookingConfigRequest } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import {
  OnlineBooking,
  SelfBookingConfigRequestSaveData,
} from "components/Settings/Scheduling/Sections/OnlineBooking/OnlineBooking";
import { getPracticeRoomsQuery, getSchedulingConfigQuery } from "api/scheduling/queries";
import { updateSchedulingConfig } from "api/scheduling/mutations";
import { handleError } from "utils/handleError";
import { getSelfBookableProvidersQuery } from "api/settings/practice/queries";

export const OnlineBookingRoute: FC = () => {
  const practice = useCurrentPractice();

  const [schedulingConfigQuery, selfBookableProvidersQuery, practiceRoomsQuery] = useApiQueries([
    getSchedulingConfigQuery({ args: { practiceId: practice.id } }),
    getSelfBookableProvidersQuery({ args: { practiceUuid: practice.uuid } }),
    getPracticeRoomsQuery({ args: { practiceId: practice.id } }),
  ]);

  const [updateSchedulingConfigMutation] = useApiMutations([updateSchedulingConfig]);

  const handleSave = (data: SelfBookingConfigRequestSaveData, onSuccess: Func | undefined) => {
    if (!schedulingConfigQuery.data) {
      return;
    }

    // eslint-disable-next-line unused-imports/no-unused-vars
    const { appointmentCategories, ...existingConfig } = schedulingConfigQuery.data.selfBookingConfig;

    const request: SelfBookingConfigRequest = {
      ...existingConfig,
      ...data,
    };

    updateSchedulingConfigMutation.mutate(
      {
        practiceId: practice.id,
        data: {
          selfBookingConfig: request,
        },
      },
      {
        onError: handleError,
        onSuccess,
      }
    );
  };

  const selfConfigProvidersCount = selfBookableProvidersQuery.data?.length || 0;

  return (
    <SettingsPanel title="Online Booking" includePadding={false}>
      <QueryResult
        queries={[schedulingConfigQuery, practiceRoomsQuery, selfBookableProvidersQuery]}
        loading={<Skeleton className="w-full h-full" />}
      >
        {schedulingConfigQuery.data && (
          <OnlineBooking
            isSaving={updateSchedulingConfigMutation.isLoading}
            onSave={handleSave}
            providersCount={selfConfigProvidersCount}
            selfBookingConfig={schedulingConfigQuery.data.selfBookingConfig}
          />
        )}
      </QueryResult>
    </SettingsPanel>
  );
};
