import React, { FC } from "react";

import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useAccount } from "@libs/contexts/AccountContext";
import { useQueryParams } from "hooks/useQueryParams";
import { PayrollHoursQuery } from "utils/routing/dashboard";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { getEmployeeWorkingHourStats, getTimeSeriesQuery } from "api/reporting/queries";
import { DashboardLayout } from "components/Dashboard/DashboardLayout";
import { DashboardPageHeader } from "components/Dashboard/DashboardPageHeader";
import { useProviderRollupQueries } from "components/Dashboard/hooks/useProviderRollupQueries";
import { PayrollHoursChart } from "components/Dashboard/PayrollHours/PayrollHoursChart";
import { MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";
import { dashboardFiltersForTables } from "components/Dashboard/utils/filters";
import { PayrollHoursTable } from "components/Dashboard/PayrollHours/PayrollHoursTable";
import { useFilteredProviderCategory } from "components/Dashboard/hooks/useFilteredProviderCategory";
import { useFormattedProviderRollups } from "components/Dashboard/hooks/useFormattedProviderRollups";
import { useProviderFilterHandler } from "components/Dashboard/PracticeProduction/hooks/useProviderFilterHandler";
import { FilterByProvider } from "components/Dashboard/Charting/FilterByProvider";
import { useDownloadWorkingHoursCSV } from "components/Dashboard/PayrollHours/hooks/useDownloadWorkingHoursCSV";
import { useFormatWorkingSeconds } from "components/Dashboard/PayrollHours/hooks/useFormatWorkingSeconds";

export const PayrollHoursRoute: FC = () => {
  const { practiceId } = useAccount();
  const { query, updateQuery } = useQueryParams("dashboardPayrollHours");
  const handleRouteStateChange = React.useCallback(
    (updates: Partial<PayrollHoursQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );
  const dateWindow = useTimeSeriesPageSelections(query);

  const { timeSeriesParams, selectedTimeSegmentParams, selectedTimeSegment } = dateWindow;
  const requestParams = {
    resolution: query.resolution,
  };
  const { providersInTimeWindowQuery, allProvidersQuery } = useProviderRollupQueries({
    metric: "employeeWorkingHour",
    dateWindow,
    filters: undefined,
  });

  const [workingHourTimeseriesQuery, providersInTimeWindowQueryResult, allProvidersQueryResult] =
    useApiQueries([
      getTimeSeriesQuery({
        args: {
          practiceId,
          data: {
            ...requestParams,
            ...timeSeriesParams,
            filters: query.filters,
            metric: "employeeWorkingHour",
            // providerJobCategory or providerId
            groupBy: "providerJobCategory",
          },
        },
      }),
      providersInTimeWindowQuery,
      allProvidersQuery,
    ]);

  const employeeWorkingHoursQuery = useInfiniteApiQuery(
    getEmployeeWorkingHourStats({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: MAX_PAGE_SIZE,
        data: {
          ...selectedTimeSegmentParams,
          sortOrders: query.employeeTableSort,
          filters: dashboardFiltersForTables(query.filters),
          includeTotal: true,
        },
      },
    })
  );
  const providerFilters = useFilteredProviderCategory(query.filters);

  const {
    data: providerFilterOptions,
    isLoading: isLoadingProviderRollups,
    selectedProvider,
  } = useFormattedProviderRollups(providersInTimeWindowQueryResult, allProvidersQueryResult, {
    ...providerFilters,
  });
  const handleProviderFilterClicked = useProviderFilterHandler({
    filters: query.filters,
    onUpdateFilters: handleRouteStateChange,
  });

  const tableExpanded = query["table.fullScreen"];
  const formatWorkingSeconds = useFormatWorkingSeconds(query);
  const { isDownloading, downloadCSV } = useDownloadWorkingHoursCSV({
    employeeWorkingHoursQuery,
    selectedTimeSegment,
    formatWorkingSeconds,
  });

  const sharedProps = {
    formatWorkingSeconds,
    dateWindow,
    onUpdateParams: handleRouteStateChange,
    ...query,
  };

  return (
    <DashboardLayout
      className="min-w-[1400px]"
      header={
        <>
          <DashboardPageHeader question="payroll-hours-worked" {...sharedProps} />
          {!tableExpanded && (
            <PayrollHoursChart {...sharedProps} workingHourTimeseriesQuery={workingHourTimeseriesQuery}>
              <FilterByProvider
                loadFailed={Boolean(allProvidersQueryResult.error || providersInTimeWindowQueryResult.error)}
                providerFilterOptions={providerFilterOptions}
                providerFilters={providerFilters}
                isLoading={isLoadingProviderRollups}
                onProviderClicked={handleProviderFilterClicked}
                formatValue={formatWorkingSeconds}
              />
            </PayrollHoursChart>
          )}
        </>
      }
    >
      <PayrollHoursTable
        query={query}
        onUpdateParams={handleRouteStateChange}
        isFullScreen={tableExpanded}
        onClickDownload={downloadCSV}
        selectedProvider={selectedProvider}
        allProvidersQueryResult={allProvidersQueryResult}
        employeeWorkingHoursQuery={employeeWorkingHoursQuery}
        isDownloadingTable={isDownloading}
        formatWorkingSeconds={formatWorkingSeconds}
        providerFilterOptions={providerFilterOptions}
      />
    </DashboardLayout>
  );
};
