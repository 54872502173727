import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ClaimVO } from "@libs/api/generated-api";
import { useCallback, useMemo } from "react";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Button } from "@libs/components/UI/Button";
import { ClaimModalFooter } from "components/Claim/ModalFooter";

interface Props {
  claim: ClaimVO;
  isDraft: boolean;
  isPrimaryClaim: boolean;
  isSubmitting: boolean;
  disableActions: boolean;
  onCancelClick: Func;
  onSubmitClick: (overrideNeedsAttachment?: boolean) => void;
}

export const FooterPreAuth: React.FC<Props> = ({
  claim,
  isDraft,
  isPrimaryClaim,
  isSubmitting,
  disableActions,
  onCancelClick,
  onSubmitClick,
}) => {
  const disableSubmitTooltip = useMemo(() => {
    const eobClippings = claim.attachments.filter((attachment) => attachment.type === "EOB");

    if (isSubmitting) {
      return "Submitting...";
    } else if (!isPrimaryClaim && !eobClippings.length) {
      return "Non-primary pre-auth claims must have at least one primary pre-auth claim EOB clipping attached";
    }

    return "";
  }, [claim.attachments, isPrimaryClaim, isSubmitting]);

  const disableButtons = useMemo(
    () => Boolean(disableSubmitTooltip) || disableActions,
    [disableSubmitTooltip, disableActions]
  );

  const handleSubmit = useCallback(() => {
    onSubmitClick();
  }, [onSubmitClick]);

  return isDraft || isSubmitting ? (
    <ClaimModalFooter>
      <Button className="w-[120px]" disabled={disableButtons} onClick={onCancelClick} theme="secondary">
        Cancel
      </Button>
      <FloatingTooltip content={disableSubmitTooltip} theme={isSubmitting ? "SMALL" : "MEDIUM"}>
        <div>
          <AsyncButton
            className="w-[120px]"
            disabled={disableButtons}
            isLoading={isSubmitting}
            onClick={handleSubmit}
            theme="primary"
          >
            Submit
          </AsyncButton>
        </div>
      </FloatingTooltip>
    </ClaimModalFooter>
  ) : null;
};
