import { Editor } from "react-email-editor";

import { TemplateVariableGroupVO, UnlayerTokenVO } from "@libs/api/generated-api";
import { sentenceCaseConstant, titleCaseConstant } from "@libs/utils/casing";
import {
  ExportEmailEditorTemplateOptions,
  ExportEmailEditorImageOptions,
  EmailEditorMergeTags,
  isEmailEditorJSONTemplate,
} from "components/Communications/Campaigns/EmailEditor/types";

export const parseEmailTemplateJson = (emailTemplateJson: string) => {
  const json: unknown = JSON.parse(emailTemplateJson);

  return isEmailEditorJSONTemplate(json) ? json : undefined;
};

export const exportEmailEditorTemplate = (
  editor: Editor,
  options?: ExportEmailEditorTemplateOptions
): Promise<{ json: string; html: string }> => {
  return new Promise((resolve) => {
    editor.exportHtml(
      ({ design, html }) => {
        resolve({ json: JSON.stringify(design), html });
      },
      {
        cleanup: true,
        minify: true,
        ...options,
      }
    );
  });
};

export const exportEmailEditorImage = (
  editor: Editor,
  options?: ExportEmailEditorImageOptions
): Promise<string | null> => {
  return new Promise((resolve, reject) => {
    editor.exportImage(
      ({ url, error }) => {
        if (error) {
          reject(error);
        } else {
          resolve(url);
        }
      },
      {
        width: 1024,
        fullPage: true,
        deviceScaleFactor: 1,
        ...options,
      }
    );
  });
};

const getEmailEditorMergeTags = (templateVariables: TemplateVariableGroupVO[]) => {
  const groupedTags: Record<string, unknown> = {};

  for (const { type, variables } of templateVariables) {
    const tags: Record<string, unknown> = {};

    for (const { key, exampleValue } of variables) {
      tags[key] = {
        name: titleCaseConstant(key),
        value: `{{${key}}}`,
        sample: exampleValue,
      };
    }

    groupedTags[type] = {
      name: sentenceCaseConstant(type),
      mergeTags: tags,
    };
  }

  return groupedTags as EmailEditorMergeTags;
};

const getEmailEditorSpecialLinks = () => ({
  onlineBooking: {
    name: "Self Booking Link",
    href: "{{SELF_BOOKING_LINK}}",
    target: "_blank",
  },
});

export const getEmailEditorOptions = ({
  unlayerProjectId,
  unlayerToken,
  templateVariables,
}: {
  unlayerProjectId: string;
  unlayerToken: UnlayerTokenVO;
  templateVariables: TemplateVariableGroupVO[];
}) => ({
  projectId: Number(unlayerProjectId),
  user: {
    id: unlayerToken.userId,
    signature: unlayerToken.userSignature,
  },
  displayMode: "email" as const,
  safeHtml: true,
  mergeTagsConfig: {
    autocompleteTriggerChar: "/",
    sort: false,
  },
  mergeTags: getEmailEditorMergeTags(templateVariables),
  specialLinks: getEmailEditorSpecialLinks(),
});
