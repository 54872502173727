/* eslint-disable @typescript-eslint/no-magic-numbers */
import { PatientToothVO } from "@libs/api/generated-api";

export const getQuadrant = (index: number): PatientToothVO["quadrant"] =>
  index < 8
    ? "QUAD_UPPER_RIGHT"
    : index < 16
      ? "QUAD_UPPER_LEFT"
      : index < 24
        ? "QUAD_LOWER_LEFT"
        : "QUAD_LOWER_RIGHT";

export const getArch = (index: number): PatientToothVO["arch"] => (index < 16 ? "ARCH_UPPER" : "ARCH_LOWER");
