import { ClaimAttachmentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatDate, SECOND_IN_MS } from "@libs/utils/date";
import { titleCaseConstant } from "@libs/utils/casing";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import {
  MedicalImage,
  Narrative,
  OtherImage,
  PerioChart,
} from "components/Claim/Attachments/ClaimAttachmentsContext";

export const attachmentImageCxStyles = {
  groupHeader: "font-sansSemiBold mt-6 mb-3",
  imageContainer: "flex flex-col gap-y-3 w-52",
  imagesContainer: "flex flex-wrap gap-3 max-w-[680px]",
  narrative: "text-greyDark max-w-[680px] whitespace-pre-wrap",
};

const getImageName = (type: ClaimAttachmentVO["type"]) => {
  switch (type) {
    case "XRAY": {
      return "X-Ray";
    }
    case "DIAGNOSTIC": {
      return "Diagnostic Report";
    }
    case "MODEL": {
      return "Dental Models";
    }
    case "RADIOLOGY": {
      return "Radiology Report";
    }
    case "REFERRAL": {
      return "Referral Form";
    }

    default: {
      return titleCaseConstant(type);
    }
  }
};

const ImageName: React.FC<{ canDelete: boolean; onDelete: Func; title: string }> = ({
  canDelete,
  onDelete,
  title,
}) => {
  return (
    <div className="flex items-center justify-between text-xs">
      <span className="overflow-hidden whitespace-nowrap text-ellipsis">{title}</span>
      {canDelete && <ButtonIcon SvgIcon={DeleteIcon} size="sm" onClick={onDelete} theme="primary" />}
    </div>
  );
};

export const AttachmentsSummary: React.FC<{
  deleteAttachment?: (attachmentUuid: string) => Promise<void>;
  isDraftClaim?: boolean;
  medicalImages?: MedicalImage[];
  narrative?: Narrative;
  perioCharts?: PerioChart[];
  other?: OtherImage[];
  // eslint-disable-next-line complexity
}> = ({ deleteAttachment, isDraftClaim = false, medicalImages, narrative, perioCharts, other }) => {
  const hasMedicalImages = medicalImages && medicalImages.length > 0;
  const hasNarratives = Boolean(narrative);
  const hasPerioCharts = perioCharts && perioCharts.length > 0;
  const hasOtherImages = other && other.length > 0;
  const hasAttachments = hasMedicalImages || hasNarratives || hasPerioCharts || hasOtherImages;
  const canDelete = Boolean(deleteAttachment) && isDraftClaim;

  return hasAttachments ? (
    <div className="flex flex-col h-full text-xs">
      {hasPerioCharts && (
        <>
          <div className={cx(attachmentImageCxStyles.groupHeader)}>Perio Chart</div>
          <div className={attachmentImageCxStyles.imageContainer}>
            {perioCharts.map((chart, index) => (
              <div className={attachmentImageCxStyles.imageContainer} key={index}>
                <ImageName
                  canDelete={canDelete}
                  onDelete={() => {
                    if (chart.uuid) {
                      deleteAttachment?.(chart.uuid);
                    }
                  }}
                  title={
                    perioCharts[0].sourceCreatedAt
                      ? `Date of Exam ${formatDate(new Date(perioCharts[0].sourceCreatedAt * SECOND_IN_MS))}`
                      : ""
                  }
                />
                <div className="flex justify-center w-full bg-greyLightest">
                  <img src={chart.data} alt="Perio Chart" className="h-24" />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {hasMedicalImages && (
        <>
          <div className={cx(attachmentImageCxStyles.groupHeader)}>Images</div>
          <div className={attachmentImageCxStyles.imagesContainer}>
            {medicalImages.map((image, index) => (
              <div className={attachmentImageCxStyles.imageContainer} key={index}>
                <ImageName
                  canDelete={canDelete}
                  onDelete={() => {
                    if (image.uuid) {
                      deleteAttachment?.(image.uuid);
                    }
                  }}
                  title={getImageName(image.type)}
                />
                <div className="flex justify-center w-full bg-greyLightest">
                  <img src={image.data} alt={image.type} className="h-24" />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {hasOtherImages && (
        <>
          <div className={cx(attachmentImageCxStyles.groupHeader)}>Other</div>
          <div className={attachmentImageCxStyles.imagesContainer}>
            {other.map((image, index) => (
              <div className={attachmentImageCxStyles.imageContainer} key={index}>
                <ImageName
                  canDelete={canDelete}
                  onDelete={() => {
                    if (image.uuid) {
                      deleteAttachment?.(image.uuid);
                    }
                  }}
                  title={getImageName(image.type)}
                />
                <div className="flex justify-center w-full bg-greyLightest">
                  <img src={image.data} alt={image.type} className="h-24" />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      {hasNarratives && (
        <>
          <div className={cx(attachmentImageCxStyles.groupHeader)}>Narratives</div>
          <div className="flex items-start gap-x-3 text-xs max-w-[680px]">
            <div>{narrative?.data}</div>
            {deleteAttachment && canDelete && (
              <ButtonIcon
                SvgIcon={DeleteIcon}
                size="sm"
                onClick={() => {
                  if (narrative?.uuid) {
                    deleteAttachment(narrative.uuid);
                  }
                }}
                theme="primary"
              />
            )}
          </div>
        </>
      )}
    </div>
  ) : (
    <span className="mt-3 text-xs text-greyDark font-sans">None</span>
  );
};
