import React, { useMemo } from "react";
import { SelectReportingQuestion } from "components/Dashboard/SelectReportingQuestion";
import { SelectDateWindow } from "components/Dashboard/SelectDateWindow";
import {
  AppointmentDrilldownType,
  DashboardQuestion,
  TimeSeriesResolutionOption,
  DashboardQueryWithDateNavigation,
  DashboardChartDisplay,
} from "utils/routing/dashboard";
import { TimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { SelectChartDisplay } from "components/Dashboard/SelectChartDisplay";
import { isOutOfRange } from "components/Dashboard/utils/timeSeriesDates";

type Props = {
  onUpdateParams: (newParams: Partial<DashboardQueryWithDateNavigation>) => void;
  resolution?: TimeSeriesResolutionOption;
  focusDate?: Date;
  question: DashboardQuestion;
  apptType?: AppointmentDrilldownType;
  includeProjectedDates?: boolean;
  dateWindow: TimeSeriesPageSelections;
  allowDateRange?: boolean;
  chartDisplay?: DashboardChartDisplay;
  children?: React.ReactNode;
};

export const cxStyles = {
  pageHeader: "flex justify-between px-6 py-3 border-b border-slate-300",
};

export const DashboardPageHeader: React.FC<Props> = ({
  onUpdateParams,
  resolution,
  focusDate,
  question,
  apptType,
  chartDisplay,
  dateWindow,
  children,
  ...rest
}) => {
  const disabledChartDisplays = useMemo(() => {
    return ["bar" as const, "line" as const].filter((displayOption) => {
      const outOfRange = isOutOfRange({
        startDate: dateWindow.startDate,
        endDate: dateWindow.endDate,
        resolution,
        chartDisplay: displayOption,
      });

      return outOfRange;
    });
  }, [dateWindow.endDate, dateWindow.startDate, resolution]);

  return (
    <div className={cxStyles.pageHeader}>
      <SelectReportingQuestion question={question} apptType={apptType} />
      <div className="flex items-center gap-3">
        <SelectDateWindow
          resolution={resolution}
          focusDate={focusDate}
          onUpdate={onUpdateParams}
          dateWindow={dateWindow}
          chartDisplay={chartDisplay}
          {...rest}
        />
        {children}

        {chartDisplay && (
          <SelectChartDisplay
            onUpdateParams={onUpdateParams}
            chartDisplay={chartDisplay}
            disabledChartDisplays={disabledChartDisplays}
          />
        )}
      </div>
    </div>
  );
};
