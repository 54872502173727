import { MedicalImageVO, PearlImageResponseGetEndpointsResult } from "@libs/api/generated-api";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useCallback } from "react";
import { useAccount } from "@libs/contexts/AccountContext";
import { analyzeImagesWithPearl } from "api/imaging/mutations";
import { PearlConfig } from "components/PatientProfile/Imaging/types/pearl";
import { PearlFlyoverPathologyCheckboxes } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/PearlFlyover/PearlFlyoverPathologyCheckboxes";
import { PearlRequestAnalysisButton } from "components/PatientProfile/Imaging/MountRoute/ImagingToolbar/PearlFlyover/PearlOrientationWarningModal";
import { handleError } from "utils/handleError";

const PearlErrorContent: React.FC<{ errorMessage?: string }> = ({ errorMessage }) => {
  return (
    <div className="text-center text-xs text-slate-300 mt-6">
      {errorMessage
        ? `An occured processing this image${errorMessage ? `: "${errorMessage}"` : ""}`
        : "This image could not be processed at this time"}
    </div>
  );
};

export const PearlImageEditorFlyoverContent: React.FC<{
  image: MedicalImageVO;
  patientId: number;
  pearlConfig?: PearlConfig;
  mountId?: number;
  pearlAnalysisResult?: PearlImageResponseGetEndpointsResult;
  onUpdatePearlConfig?: (config: Partial<PearlConfig>) => void;
}> = ({ patientId, pearlConfig, onUpdatePearlConfig, mountId, image, pearlAnalysisResult }) => {
  const { practiceId } = useAccount();

  const [analyzePearlMutation] = useApiMutations([analyzeImagesWithPearl]);
  const analyzePearlMutate = analyzePearlMutation.mutate;
  const handleAnalyzeClicked = useCallback(() => {
    analyzePearlMutate(
      {
        practiceId,
        patientId,
        mountId,
        data: {
          medicalImageIds: image.id ? [image.id] : [],
        },
      },
      { onError: handleError }
    );
  }, [analyzePearlMutate, image.id, mountId, patientId, practiceId]);

  return image.pearlAnalysis?.status === "DONE" ? (
    <PearlFlyoverPathologyCheckboxes
      pearlConfig={pearlConfig}
      pearlAnalysisResult={pearlAnalysisResult}
      onUpdatePearlConfig={onUpdatePearlConfig}
    />
  ) : image.pearlAnalysis?.errorMessage || image.pearlAnalysis?.status === "REJECTED" ? (
    <PearlErrorContent errorMessage={image.pearlAnalysis.errorMessage} />
  ) : image.pearlAnalysis?.status === "SUBMITTED" ? (
    <div className="text-center text-xs text-slate-300 mt-6">Image is currently being analyzed</div>
  ) : (
    <div className="flex flex-col gap-4">
      <PearlRequestAnalysisButton
        imageSubmissionCount={1}
        handleProceed={handleAnalyzeClicked}
        isLoading={analyzePearlMutation.isLoading}
      />
      <p className="text-center text-xs text-slate-300">This may take several minutes to complete</p>
    </div>
  );
};
