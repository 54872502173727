import React, { FC } from "react";
import { formatCurrency } from "@libs/utils/currency";
import { Col } from "components/PatientProfile/Billing/Ledger/LedgerComponents";
import { Text } from "./Text";
import { TableRow } from "./AppointmentsAndAdjustmentsPage";

export const AppointmentAdjustmentTableRow: FC<{ adjustmentName: string; adjustmentAmount: number }> = ({
  adjustmentName,
  adjustmentAmount,
}) => {
  return (
    <TableRow
      descriptionColumn={
        <Col justify="left" width="full">
          <Text bold>Adjust</Text>
          <Text>
            &nbsp;- {adjustmentName}: {formatCurrency(adjustmentAmount)}
          </Text>
        </Col>
      }
      patientColumn={undefined}
      insuranceColumn={undefined}
    />
  );
};
