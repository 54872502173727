import { ClaimVO } from "@libs/api/generated-api";
import { ClaimLineItemVOKeys } from "components/Claim/ProcedureTable";
import {
  isCompletedClaim,
  isInDraftEobPaymentClaim,
  isPreAuthClaim,
  isSubmittedClaim,
  isVoidedClaim,
} from "components/Claims/utils";

export type ColumnIds = ClaimLineItemVOKeys | "checkbox";

export type HeaderData = {
  id: ColumnIds;
  isCurrencyCell?: boolean;
  isRightAligned?: boolean;
  label: string;
  width: string;
};

const baseTableHeaders: HeaderData[] = [
  { id: "providerName", label: "Prov", width: "max-content" },
  { id: "procedureCode", label: "Code", width: "max-content" },
  { id: "procedureArea", label: "Tth/Surf", width: "max-content" },
  { id: "procedureDescription", label: "Description", width: "1fr" },
];

const feeHeaderData: HeaderData = {
  id: "ucrRate",
  isCurrencyCell: true,
  isRightAligned: true,
  label: "Fee",
  width: "84px",
};

const negotiatedRateHeaderData: HeaderData = {
  id: "negotiatedRate",
  isCurrencyCell: true,
  isRightAligned: true,
  label: "Neg Rate",
  width: "84px",
};

const writeoffHeaderData: HeaderData = {
  id: "writeoffAmount",
  isCurrencyCell: true,
  isRightAligned: true,
  label: "Writeoff",
  width: "84px",
};

const deductibleEstimateHeaderData: HeaderData = {
  id: "deductibleEstimatedAmount",
  isCurrencyCell: true,
  isRightAligned: true,
  label: "Ded Est",
  width: "84px",
};

const coverageHeaderData: HeaderData = {
  id: "insuranceCoveragePercent",
  isRightAligned: true,
  label: "Coverage",
  width: "84px",
};

const patientEstimateHeaderData: HeaderData = {
  id: "patientEstimatedAmount",
  isCurrencyCell: true,
  isRightAligned: true,
  label: "Pt Est",
  width: "84px",
};

const insuranceEstimateHeaderData: HeaderData = {
  id: "insuranceEstimatedAmount",
  isCurrencyCell: true,
  isRightAligned: true,
  label: "Ins Est",
  width: "84px",
};

const remarksHeaderData: HeaderData = { id: "remarks", label: "Remarks", width: "max-content" };

// eslint-disable-next-line complexity
export const getProcedureTableHeaders = ({
  claim,
  isAddingAdjustment,
  isAdditionalPayment,
}: {
  claim: ClaimVO | undefined;
  isAddingAdjustment?: boolean;
  isAdditionalPayment?: boolean;
}): HeaderData[] => {
  if (!claim) {
    return [];
  }

  const isPreAuth = isPreAuthClaim(claim);
  const isSubmitted = isSubmittedClaim(claim);
  const isVoided = isVoidedClaim(claim);
  const isInDraftEobPayment = isInDraftEobPaymentClaim(claim);
  const isCompleted = isCompletedClaim(claim);

  const width = isAdditionalPayment ? "184px" : isAddingAdjustment || isInDraftEobPayment ? "100px" : "84px";

  const deductibleHeaderData: HeaderData = {
    id: "deductibleAmount",
    isCurrencyCell: true,
    isRightAligned: true,
    label: "Ded",
    width,
  };

  const patientResponsibilityHeaderData: HeaderData = {
    id: "patientAmount",
    isCurrencyCell: true,
    isRightAligned: true,
    label: "Pt Resp",
    width,
  };

  const insurancePayHeaderData: HeaderData = {
    id: "insuranceAmount",
    isCurrencyCell: true,
    isRightAligned: true,
    label: "Ins Pay",
    width,
  };

  if (isInDraftEobPayment) {
    return [
      ...baseTableHeaders,
      feeHeaderData,
      negotiatedRateHeaderData,
      writeoffHeaderData,
      deductibleEstimateHeaderData,
      deductibleHeaderData,
      patientEstimateHeaderData,
      patientResponsibilityHeaderData,
      insuranceEstimateHeaderData,
      insurancePayHeaderData,
      remarksHeaderData,
    ] as HeaderData[];
  }

  const nonCompletedCurrencyHeaders: HeaderData[] = [
    feeHeaderData,
    negotiatedRateHeaderData,
    writeoffHeaderData,
    deductibleHeaderData,
    coverageHeaderData,
    patientEstimateHeaderData,
    insuranceEstimateHeaderData,
  ];

  if (isPreAuth) {
    const preAuthHeaders: HeaderData[] = [
      ...baseTableHeaders,
      { id: "preAuthStatus", isRightAligned: true, label: "Pre-Auth Status", width: "110px" } as HeaderData,
      ...nonCompletedCurrencyHeaders,
    ];

    return isSubmitted
      ? preAuthHeaders
      : [{ id: "checkbox", label: "", width: "max-content" } as HeaderData, ...preAuthHeaders];
  } else if (isCompleted || isVoided) {
    return [
      ...baseTableHeaders,
      feeHeaderData,
      negotiatedRateHeaderData,
      deductibleHeaderData,
      patientEstimateHeaderData,
      patientResponsibilityHeaderData,
      insuranceEstimateHeaderData,
      insurancePayHeaderData,
      remarksHeaderData,
    ] as HeaderData[];
  }

  const nonProcessedHeaders: HeaderData[] = [...baseTableHeaders, ...nonCompletedCurrencyHeaders];

  return isSubmitted
    ? nonProcessedHeaders
    : [{ id: "checkbox", label: "", width: "max-content" } as HeaderData, ...nonProcessedHeaders];
};
