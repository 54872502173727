import { useContext, useMemo } from "react";
import { AnimatedAnnotation, AnnotationLineSubject, AxisScale, DataContext } from "@visx/xychart";
import { colors } from "@libs/design.config";
import { half } from "@libs/utils/math";
import { isDefined } from "@libs/utils/types";
import { ClickableAxisLabelHiddenBar } from "components/Dashboard/Charting/ClickableAxisLabelHiddenBar";
import { TIMESERIES_LINECHART_ACCESSORS } from "components/Dashboard/Charting/theme";

export interface Props {
  selectedDateIndex?: number;
  dottedDateIndex?: number;
  onClickDate?: (params: { date: string; index: number }) => void;
}

const getXValue = (xScale: AxisScale, date: string) => {
  return (xScale(date as unknown as number) ?? -1) as number;
};

const getXDomain = (xScale: AxisScale) => xScale.domain() as string[];
const DOT_OFFSET = 4;
const useScales = () => {
  const { xScale, yScale, margin, height, innerHeight } = useContext(DataContext);

  return useMemo(() => {
    // may not be available on initial render
    if (!yScale || !xScale || !height) {
      return null;
    }

    const [yMin, yMax] = yScale.range() as number[];
    const xDomain = getXDomain(xScale);
    let dataPointWidth = 0;

    if (xDomain.length > 1) {
      dataPointWidth = getXValue(xScale, xDomain[1]) - getXValue(xScale, xDomain[0]);
    }

    return {
      yMax,
      yMin,
      yScale,
      xScale,
      dataPointWidth,
      margin,
      height,
      innerHeight,
      xDomain,
      axisBaselineY: height - (margin?.bottom ?? 0),
    };
  }, [xScale, yScale, margin, height, innerHeight]);
};

export const LineChartAnnotations = ({ selectedDateIndex, dottedDateIndex, onClickDate }: Props) => {
  const scales = useScales();
  const todayX = useMemo(() => {
    if (!scales || !dottedDateIndex || dottedDateIndex < 0) {
      return null;
    }

    const dottedDate = scales.xDomain[dottedDateIndex];

    return getXValue(scales.xScale, dottedDate) + half(scales.dataPointWidth);
  }, [dottedDateIndex, scales]);
  const selectedDate = useMemo(() => {
    if (!scales || !isDefined(selectedDateIndex) || selectedDateIndex < 0) {
      return null;
    }

    return scales.xDomain[selectedDateIndex];
  }, [scales, selectedDateIndex]);

  const baseLineProps = useMemo(() => {
    if (!scales) {
      return null;
    }

    const [xMin, xMax] = scales.xScale.range() as number[];

    return {
      x1: xMin,
      x2: xMax,
      y1: scales.yScale(0) as number,
      y2: scales.yScale(0) as number,
    };
  }, [scales]);

  return (
    <>
      {todayX && scales && (
        <circle
          cx={todayX}
          cy={scales.axisBaselineY + half(scales.margin?.bottom ?? 0) + DOT_OFFSET}
          r="2"
          fill={colors.primaryTheme}
        />
      )}

      {scales && <line stroke={colors.greyDark} {...baseLineProps} />}

      {scales &&
        onClickDate &&
        scales.xDomain.map((date, i) => {
          const x = getXValue(scales.xScale, date);

          return (
            <ClickableAxisLabelHiddenBar
              key={date}
              width={scales.dataPointWidth}
              x={x}
              yMax={scales.axisBaselineY}
              onClick={() => {
                onClickDate({ date, index: i });
              }}
              index={i}
            />
          );
        })}

      {selectedDate && (
        <AnimatedAnnotation
          datum={{
            date: selectedDate,
            value: 0, // any number since it's a vertical line
          }}
          {...TIMESERIES_LINECHART_ACCESSORS}
        >
          <AnnotationLineSubject stroke={colors.primaryTheme} strokeWidth={1} />
        </AnimatedAnnotation>
      )}
    </>
  );
};
