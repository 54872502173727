import { Link, useNavigate } from "react-router-dom";
import { MessageVO } from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { Icon, IconProps } from "@libs/components/UI/Icon";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { ReactComponent as PhoneIcon } from "@libs/assets/icons/call.svg";
import { ReactComponent as MessageIcon } from "@libs/assets/icons/messages.svg";
import { ReactComponent as EmailIcon } from "@libs/assets/icons/email.svg";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { ReactComponent as AttachIcon } from "@libs/assets/icons/attach.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as RightArrowIcon } from "@libs/assets/icons/right-arrow.svg";
import { Row, ButtonCell, IconsCell } from "@libs/components/UI/GridTableComponents";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import { paths } from "utils/routing/paths";

const MessageTypeIconProps: Partial<Record<MessageVO["type"], IconProps>> = {
  SMS: { SvgIcon: MessageIcon, tooltip: { content: "SMS", theme: "SMALL" } },
  EMAIL: { SvgIcon: EmailIcon, tooltip: { content: "Email", theme: "SMALL" } },
  CALL: { SvgIcon: PhoneIcon, tooltip: { content: "Call", theme: "SMALL" } },
};

interface Props {
  patientId: number;
  message: MessageVO;
  onRequestCancelScheduledMessage: (message: MessageVO) => void;
}

// eslint-disable-next-line complexity
export const MessageRow: React.FC<Props> = ({ patientId, message, onRequestCancelScheduledMessage }) => {
  const {
    state,
    isOutgoing,
    sender,
    subject,
    content,
    attachmentDocumentIds,
    attachmentFolderId,
    type,
    timestamp,
  } = message;

  const navigate = useNavigate();

  const statusText = isOneOf(state, ["SCHEDULED", "FAILED"])
    ? sentenceCaseConstant(state)
    : isOutgoing
      ? type === "CALL"
        ? "Outbound"
        : "Sent"
      : type === "CALL"
        ? "Inbound"
        : "Received";

  const senderText =
    sender.displayName === "Auto Text" && type === "EMAIL" ? "Automated" : sender.displayName;

  const messageText = type === "EMAIL" && subject ? subject : content;

  const attachmentsCount = attachmentDocumentIds?.length;

  const canCancelScheduledMessage = state === "SCHEDULED";
  const canViewSmsMessage = type === "SMS";

  const buttonCellProps = {
    onDoubleClick: () => navigate(paths.messaging({ patientId })),
    disabled: !canViewSmsMessage,
  };

  return (
    <Row className="*:flex *:items-center">
      <ButtonCell {...buttonCellProps}>
        <DateWithTooltip date={timestamp} dateAsSeconds />
      </ButtonCell>

      <ButtonCell {...buttonCellProps}>
        {type in MessageTypeIconProps ? <Icon {...(MessageTypeIconProps[type] as IconProps)} /> : null}
      </ButtonCell>

      <ButtonCell {...buttonCellProps}>
        {state === "FAILED" ? (
          <div className="flex items-center gap-x-2">
            {statusText}
            <Icon SvgIcon={ErrorIcon} theme="error" size="sm" />
          </div>
        ) : (
          statusText
        )}
      </ButtonCell>

      <ButtonCell {...buttonCellProps}>{senderText}</ButtonCell>

      <ButtonCell {...buttonCellProps} className="overflow-hidden">
        <p className="truncate">{messageText}</p>
      </ButtonCell>

      <ButtonCell {...buttonCellProps}>
        {attachmentsCount ? (
          <Link
            aria-label="Attachments Count"
            className={`
              flex
              items-center
              gap-x-2
              px-3
              h-6
              border
              border-greyLighter
              rounded-full
              text-xs
              hover:opacity-70
            `}
            to={paths.patientDocuments(
              { patientId },
              {
                folderId: attachmentFolderId,
                docId: attachmentDocumentIds[0],
              }
            )}
          >
            <AttachIcon className="w-3 h-3 fill-greyDark" />
            {attachmentsCount}
          </Link>
        ) : null}
      </ButtonCell>

      <IconsCell>
        <div className="flex items-center h-6">
          {canCancelScheduledMessage ? (
            <ButtonIcon
              SvgIcon={DeleteIcon}
              tooltip={{ content: "Cancel Scheduled Message", theme: "SMALL" }}
              onClick={() => onRequestCancelScheduledMessage(message)}
              theme="primary"
            />
          ) : canViewSmsMessage ? (
            <LinkIcon
              className="invisible group-hover:visible"
              SvgIcon={RightArrowIcon}
              tooltip={{ content: "View SMS Message", theme: "SMALL" }}
              to={paths.messaging({ patientId })}
              theme="primary"
            />
          ) : null}
        </div>
      </IconsCell>
    </Row>
  );
};
