import React, { Dispatch, ReactNode, SetStateAction, useEffect, useMemo, useState } from "react";
import { OpenSlotVO, ProviderVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { SlotIntervalSelect } from "components/ScheduleAppointments/SlotIntervalSelect";
import { SlotProviderSelect } from "components/ScheduleAppointments/SlotProviderSelect";
import { SlotSelections } from "components/ScheduleAppointments/types";

const getSlotIntervalId = <T extends { endTime?: string; startTime?: string; date?: string }>({
  startTime,
  endTime,
  date,
}: T) => {
  return !startTime || !endTime || !date ? "" : `${date}-${startTime}-${endTime}`;
};

const getProviderIds = (
  intervalId: string,
  previousSelections: SlotSelections,
  overrides:
    | {
        customStartTime?: string;
        hygienistId?: number;
        dentistId?: number;
      }
    | undefined,
  onlyProvider: ProviderVO | undefined
) => {
  const hasChangedInterval = previousSelections.id !== intervalId;
  let dentistId = previousSelections.dentistId;
  let hygienistId = previousSelections.hygienistId;

  const overrideDentistId =
    overrides?.dentistId || (onlyProvider?.jobCategory === "DENTIST" ? onlyProvider.id : 0);
  const overrideHygienistId =
    overrides?.hygienistId || (onlyProvider?.jobCategory === "HYGIENIST" ? onlyProvider.id : 0);

  // If a user directly selects a dentist use that
  // and remove the hygienist
  if (overrideDentistId) {
    dentistId = overrideDentistId;
    hygienistId = 0;

    // If a user directly selects a hygienist use that
  } else if (overrideHygienistId) {
    hygienistId = overrideHygienistId;

    // If the user is switching to a hygienist from a dentist
    // then clear out the dentist.
    // Otherwise allow the dentistId to persist, because it was
    // selected from a separate menu
    if (previousSelections.dentistId && !previousSelections.hygienistId) {
      dentistId = 0;
    }
  } else if (hasChangedInterval) {
    dentistId = 0;
    hygienistId = 0;
  }

  return {
    dentistId,
    hygienistId,
  };
};

export interface Props {
  openSlots: OpenSlotVO[];
  onSelectedSlotUpdated: Dispatch<SetStateAction<SlotSelections>>;
  appointmentDurationInMinutes: number;
  selection: SlotSelections;
  isLast: boolean;
  children?: ReactNode;
}

export const AppointmentIntervalSlots: React.FC<Props> = ({
  openSlots,
  onSelectedSlotUpdated,
  appointmentDurationInMinutes,
  selection,
  children,
  isLast,
}) => {
  const interval = useMemo(() => {
    const firstSlot = openSlots[0];
    const id = getSlotIntervalId(firstSlot);

    return {
      startTime: firstSlot.startTime,
      endTime: firstSlot.endTime,
      date: firstSlot.date,
      id,
      isSelected: selection.id === id,
    };
  }, [openSlots, selection]);

  const [customStartTime, setCustomStartTime] = useState(interval.startTime);

  const handleSelectInterval = (overrides?: {
    customStartTime?: string;
    hygienistId?: number;
    dentistId?: number;
  }) => {
    // eslint-disable-next-line complexity
    onSelectedSlotUpdated((last) => {
      const onlyProvider = openSlots.length === 1 ? openSlots[0].provider : undefined;
      const { dentistId, hygienistId } = getProviderIds(interval.id, last, overrides, onlyProvider);
      const didToggleOff =
        last.id === interval.id &&
        ((last.dentistId && last.dentistId === overrides?.dentistId) ||
          (last.hygienistId && last.hygienistId === overrides?.hygienistId));

      return {
        id: interval.id,
        endTime: interval.endTime,

        // If the user selects a custom start time use that otherwise
        // use whatever that last selected customStartTime for the current
        // interval
        startTime: overrides?.customStartTime || customStartTime,
        date: didToggleOff ? undefined : interval.date,
        hygienistId: didToggleOff ? 0 : hygienistId,

        // it's possible that changing the selected interval will make these selections
        // unavailable but the user is notified in the ui
        roomId: didToggleOff ? 0 : last.roomId,
        dentistId: didToggleOff ? 0 : dentistId,
      };
    });
  };

  // we keep previous slots data so that the UI height of the slots results
  // doesn't change when updating preferences until new data is returned.
  // This means this component is never unmounted
  // and we need to reset state when the open slots update
  useEffect(() => {
    setCustomStartTime(interval.startTime);
  }, [interval.startTime]);

  return (
    <div
      className={cx(
        "contents",
        !isLast && "*:border-b *:border-b-greyLighter",
        interval.isSelected && "*:bg-actionLight"
      )}
    >
      <div className="py-3 px-5">
        {children}
        <SlotIntervalSelect
          isSelected={interval.isSelected}
          onClick={() => handleSelectInterval()}
          onChangeStartTime={(time) => {
            setCustomStartTime(time);
            handleSelectInterval({ customStartTime: time });
          }}
          customStartTime={customStartTime}
          startTime={interval.startTime}
          appointmentDurationInMinutes={appointmentDurationInMinutes}
          endTime={openSlots[0].endTime}
        />
      </div>

      <SlotProviderSelect
        isFirstRow={Boolean(children)}
        selectedProviderId={interval.isSelected ? selection.hygienistId || selection.dentistId || -1 : -1}
        onSelectProvider={handleSelectInterval}
        openSlots={openSlots}
      />
    </div>
  );
};
