import React from "react";
import { format as dateFnsFormat, formatDistanceStrict, secondsToMilliseconds } from "date-fns";

import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { useNow } from "hooks/useNow";

interface DateWithTooltipProps extends React.HTMLAttributes<HTMLSpanElement> {
  date: Date | number;
  format?: string;
  dateAsSeconds?: boolean;
}

export const DateWithTooltip: React.FC<DateWithTooltipProps> = ({
  date,
  format = "P p",
  dateAsSeconds = false,
  ...rest
}) => {
  if (typeof date === "number" && dateAsSeconds === true) {
    date = secondsToMilliseconds(date);
  }

  const formattedDate = dateFnsFormat(date, format);
  const now = useNow();
  const hoveredDate = formatDistanceStrict(date, now, { addSuffix: true });

  return (
    <FloatingTooltip content={hoveredDate} theme="SMALL">
      <span {...rest}>{formattedDate}</span>
    </FloatingTooltip>
  );
};
