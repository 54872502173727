import { FC, ChangeEvent } from "react";
import { Link } from "react-router-dom";

import { FormTaskVO, PatientSummaryVO } from "@libs/api/generated-api";
import { formatISODate, formatISOTimeAsAmPmShortHr, formatUnixTimestamp } from "@libs/utils/date";
import { isOneOf } from "@libs/utils/isOneOf";
import { Pill, PillProps } from "@libs/components/UI/Pill";
import { ReactComponent as CheckCircleIcon } from "@libs/assets/icons/check-circle-1.svg";
import { ReactComponent as AttachIcon } from "@libs/assets/icons/attach.svg";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { Row, CheckboxCell, TextCell, EMPTY_CELL } from "@libs/components/UI/GridTableComponents";

import { FormTaskButtonMenu, isExpiredFormTask } from "components/PatientProfile/Forms/FormTaskButtonMenu";

import { paths } from "utils/routing/paths";

const FormTaskStatePillProps: Record<
  FormTaskVO["state"] | "EXPIRED",
  Pick<PillProps, "children" | "theme">
> = {
  PENDING: { children: "Incomplete", theme: "red" },
  COMPLETED: { children: "Complete", theme: "green" },
  EXPIRED: { children: "Expired", theme: "yellow" },
  ARCHIVED: { children: "Archived", theme: "grey" },

  // Will never be displayed
  PRINT_ONLY: { children: "Printed", theme: "grey" },
};

interface Props {
  patient: PatientSummaryVO;
  formTask: FormTaskVO;
  isSelected: boolean;
  onSelectFormTask: (e: ChangeEvent<HTMLInputElement>) => void;
  consentFolderId: number;
}

// eslint-disable-next-line complexity
export const PatientFormRow: FC<Props> = ({
  patient,
  formTask,
  isSelected,
  onSelectFormTask,
  consentFolderId,
}) => {
  const practice = useCurrentPractice();
  const {
    uuid,
    form,
    patientId,
    appointmentId,
    appointmentDate,
    appointmentStartTime,
    submittedAt,
    sentAt,
    state,
    documentId,
    treatmentPlanName,
  } = formTask;

  const formTaskState = isExpiredFormTask(formTask) ? "EXPIRED" : state;
  const statusPill = (
    <Pill className="flex items-center gap-x-1" theme={FormTaskStatePillProps[formTaskState].theme} size="sm">
      {isOneOf(formTaskState, ["COMPLETED", "EXPIRED"]) ? <AttachIcon className="w-4 h-4" /> : null}
      {FormTaskStatePillProps[formTaskState].children}
    </Pill>
  );

  return (
    <Row className="*:flex *:items-center">
      <CheckboxCell checked={isSelected} value={uuid} onChange={onSelectFormTask} />
      <TextCell className="overflow-hidden">
        <span className="truncate">{treatmentPlanName ?? form.title}</span>
      </TextCell>
      <TextCell>
        {appointmentId && appointmentDate ? (
          <Link
            to={paths.schedule({
              patientId,
              appointmentId,
              date: appointmentDate,
            })}
          >
            {formatISODate(appointmentDate)}
            {appointmentStartTime ? ` ${formatISOTimeAsAmPmShortHr(appointmentStartTime)}` : null}
          </Link>
        ) : (
          EMPTY_CELL
        )}
      </TextCell>
      <TextCell>{sentAt ? formatUnixTimestamp(sentAt, practice.timezoneId) : EMPTY_CELL}</TextCell>
      <TextCell>{submittedAt ? formatUnixTimestamp(submittedAt, practice.timezoneId) : EMPTY_CELL}</TextCell>
      <TextCell>
        {form.inPersonRequired ? <CheckCircleIcon className="w-4 h-4 text-greyMedium" /> : null}
      </TextCell>
      <TextCell>
        {state === "COMPLETED" && documentId ? (
          <Link to={paths.patientDocuments({ patientId }, { folderId: consentFolderId, docId: documentId })}>
            {statusPill}
          </Link>
        ) : (
          statusPill
        )}
      </TextCell>
      <TextCell>
        <FormTaskButtonMenu patient={patient} formTasks={[formTask]} />
      </TextCell>
    </Row>
  );
};
