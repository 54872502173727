import { startOfMinute } from "date-fns";
import React from "react";
import { startOfDayInTimezone } from "@libs/utils/date";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";

export const useStartOfDayInPracticeTimezone = () => {
  const practice = useCurrentPractice();
  const thisMinuteRounded = startOfMinute(new Date()).getTime();

  return React.useMemo(() => {
    const now = new Date(thisMinuteRounded);

    return startOfDayInTimezone(practice.timezoneId, now);
  }, [thisMinuteRounded, practice.timezoneId]);
};
