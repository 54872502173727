import { FC, useCallback } from "react";
import { CreateTreatmentPlanFormTaskRequest } from "@libs/api/generated-api";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { sendFormTasks } from "api/formTasks/mutations";
import { SendFormsModal } from "components/PatientProfile/Forms/SendFormsModal";
import { handleError } from "utils/handleError";
import { createTreatmentPlanFormTask } from "api/charting/mutations";

type Props = {
  patientId: number;
  formTaskRequest: CreateTreatmentPlanFormTaskRequest;
  onRequestClose: Func;
};

export const SendTreatmentPlanModal: FC<Props> = ({ patientId, formTaskRequest, onRequestClose }) => {
  const { practiceId } = useAccount();
  const [createFormTaskMutation, sendFormTasksMutation] = useApiMutations([
    createTreatmentPlanFormTask,
    sendFormTasks,
  ]);
  const createFormTaskMutateAsync = createFormTaskMutation.mutateAsync;
  const sendFormTasksMutateAsync = sendFormTasksMutation.mutateAsync;

  const handleSendFormTasks = useCallback(
    async (message: string, { phoneOverride, onSuccess }: { phoneOverride?: string; onSuccess: Func }) => {
      try {
        const formTaskResult = await createFormTaskMutateAsync({
          practiceId,
          data: formTaskRequest,
        });

        const formTaskUuidsToSend = [formTaskResult.data.data.uuid];

        await sendFormTasksMutateAsync({
          practiceId,
          data: { formTaskUuids: formTaskUuidsToSend, message, phoneOverride },
        });
        onSuccess();
      } catch (error) {
        handleError(error);
      }
    },
    [practiceId, formTaskRequest, createFormTaskMutateAsync, sendFormTasksMutateAsync]
  );

  return (
    <SendFormsModal
      title="Send Treatment Plan"
      patientId={patientId}
      // Treatment plan forms are not in-person required (for now)
      inPersonFormTitles={[]}
      hasSomeSelectedCompleted={false}
      isSending={createFormTaskMutation.isLoading || sendFormTasksMutation.isLoading}
      onSendForms={handleSendFormTasks}
      onRequestClose={onRequestClose}
    />
  );
};
