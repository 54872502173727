import { FC, useState, useMemo, useCallback } from "react";
import { produce } from "immer";

import { FolderVO, DocumentVO } from "@libs/api/generated-api";
import { useAccount } from "@libs/contexts/AccountContext";
import { Modal } from "@libs/components/UI/Modal";
import { DocumentUploaderRequest, useDocumentUploader } from "components/UI/DocumentUploadManager";
import { AttachScanModalContent } from "components/UI/AttachModalContent/AttachScanModalContent";
import { ScanDocumentFileFields } from "components/UserDocuments/ScanDocumentFileFields";

import { handleError } from "utils/handleError";

export type StagedScanFile = MakeKeysNullable<DocumentUploaderRequest, "folderId" | "fileData">;

interface Props {
  userId: number;
  folders: FolderVO[];
  defaultFolderId?: FolderVO["id"];
  onUploadSuccess: (document: DocumentVO) => void;
  onRequestClose: Func;
}

export const ScanDocumentFileModal: FC<Props> = ({
  userId,
  folders,
  defaultFolderId,
  onUploadSuccess,
  onRequestClose,
}) => {
  const { practiceId } = useAccount();
  const [stagedFile, setStagedFile] = useState<StagedScanFile>({
    practiceId,
    userId,
    folderId: defaultFolderId,
    filename: "",
  });

  const { documentUploader } = useDocumentUploader();

  const canUploadFile = Boolean(stagedFile.filename && stagedFile.folderId);

  const folderOptions = useMemo(
    () => folders.map((folder) => ({ label: folder.name, value: folder.id })),
    [folders]
  );

  const handleUpdateFile = (updatedFile: Partial<StagedScanFile>) => {
    setStagedFile((last) => ({ ...last, ...updatedFile }));
  };

  const handleUploadFiles = useCallback(
    async (files: File[]) => {
      // TODO: Handle multiple files coming out of a scanner
      const [file] = files;

      try {
        const documentUploaderRequest = produce(stagedFile, (draft) => {
          draft.fileData = file;
        }) as DocumentUploaderRequest;

        const document = await documentUploader(documentUploaderRequest);

        onUploadSuccess(document);
        onRequestClose();
      } catch (error) {
        handleError(error);
      }
    },
    [stagedFile, documentUploader, onUploadSuccess, onRequestClose]
  );

  return (
    <Modal title="Scan Document" size="xs" onClose={onRequestClose}>
      <AttachScanModalContent
        filename={stagedFile.filename}
        onScanFiles={handleUploadFiles}
        onRequestClose={onRequestClose}
        disabled={!canUploadFile}
        scanAs="PDF"
      >
        <ScanDocumentFileFields
          file={stagedFile}
          folderOptions={folderOptions}
          onUpdateFile={handleUpdateFile}
        />
      </AttachScanModalContent>
    </Modal>
  );
};
