import React from "react";
import { JournalEventVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { formatISODate } from "@libs/utils/date";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { EMPTY_CELL, Row } from "@libs/components/UI/GridTableComponents";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";
import { paths } from "utils/routing/paths";
import { getJournalRowDescription } from "components/Dashboard/EndOfDayReport/utils";
import { PatientNameLink } from "components/Dashboard/Tables/PatientNameLink";

type Props = {
  row: JournalEventVO;
  last?: boolean;
};

export const EndOfDayReportRow: React.FC<Props> = ({ row, last }) => {
  const { patient, eventDate, eventType, provider, amount } = row;
  const cellProps = { last, to: paths.patient({ patientId: patient.id }) };
  const description = React.useMemo(() => getJournalRowDescription(row), [row]);

  return (
    <Row data-testid={`row-${patient.id}-${eventType}`}>
      <DashboardLinkCell {...cellProps}>
        <PatientNameLink patient={patient} />
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{formatISODate(eventDate, "MM/dd/yyyy")}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>{provider?.fullDisplayName ?? EMPTY_CELL}</DashboardLinkCell>
      <DashboardLinkCell {...cellProps}>
        <FloatingTooltip content={description} theme="MEDIUM">
          <div className="truncate">{description}</div>
        </FloatingTooltip>
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="right">
        {formatCurrency(amount)}
      </DashboardLinkCell>
    </Row>
  );
};
