import {
  FormRequest,
  BatchFormResponseRequest,
  FormVO,
  FormSubmissionVO,
  OnboardingOptionsRequest,
  OnboardingOptionsVO,
} from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import { updateCachedData } from "@libs/utils/queryCache";

export const createForm = makeMutation({
  mutationKey: ["practices", "createForm"],
  formatParams: (args: { practiceId: number; data: FormRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getFormSummaries"), { practiceId }]);
    },
  }),
});

export const updateForm = makeMutation({
  mutationKey: ["practices", "updateForm"],
  formatParams: (args: {
    practiceUuid: string;
    practiceId: number;
    uuidOrSlug: string;
    data: FormRequest;
  }) => [args.practiceId, args.uuidOrSlug, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceUuid, practiceId, uuidOrSlug }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getFormSummaries"), { practiceId }]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getClinicalNoteFormPreview"),
        { practiceId, formUuid: uuidOrSlug },
      ]);
      queryClient.invalidateQueries([
        getQueryKey("public", "getFormPreview"),
        { practiceUuid, formUuid: uuidOrSlug },
      ]);

      // So far all form updates are either done optimistically so no need to invalidate the draft form
      // queryClient.invalidateQueries([getQueryKey("practices", "getDraftForm"), { practiceId, uuidOrSlug }]);
    },
  }),
});

export const archiveForm = makeMutation({
  mutationKey: ["practices", "archiveForm"],
  formatParams: (args: { practiceId: number; uuidOrSlug: string }) => [args.practiceId, args.uuidOrSlug],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getFormSummaries"), { practiceId }]);
    },
  }),
});

export const discardDraft = makeMutation({
  mutationKey: ["practices", "discardDraft"],
  formatParams: (args: { practiceUuid: string; practiceId: number; uuidOrSlug: string }) => [
    args.practiceId,
    args.uuidOrSlug,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceUuid, practiceId, uuidOrSlug }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getFormSummaries"), { practiceId }]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getClinicalNoteFormPreview"),
        { practiceId, formUuid: uuidOrSlug },
      ]);
      queryClient.invalidateQueries([
        getQueryKey("public", "getFormPreview"),
        { practiceUuid, formUuid: uuidOrSlug },
      ]);
    },
  }),
});

export const publishForm = makeMutation({
  mutationKey: ["practices", "publishForm"],
  formatParams: (args: { practiceId: number; uuid: string; slug?: string }) => [args.practiceId, args.uuid],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId, uuid, slug }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getFormSummaries"), { practiceId }]);
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPublishedForm"),
        { practiceId, uuidOrSlug: uuid },
      ]);

      // Agressively invalidate all the queries that could be affected by a form publish.
      // This is because we don't always know which forms are being used by a given query
      queryClient.invalidateQueries([
        getQueryKey("practices", "getClinicalNoteRenderedForms"),
        { practiceId },
      ]);

      if (slug) {
        queryClient.invalidateQueries([
          getQueryKey("practices", "getPublishedForm"),
          { practiceId, uuidOrSlug: slug },
        ]);
      }
    },
  }),
});

export const submitFormResponses = makeMutation({
  mutationKey: ["practices", "submitFormResponses"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    uuid: string;
    slug?: NonNullable<FormVO["slug"]>;
    data: BatchFormResponseRequest;
  }) => [args.practiceId, args.patientId, args.uuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId, uuid, slug, patientId }) => {
      updateCachedData<FormSubmissionVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "getLatestFormSubmission"),
            { practiceId, uuidOrSlug: uuid, patientId },
          ],
          exact: true,
        },
        () => {
          return response.data.data;
        }
      );

      if (slug) {
        updateCachedData<FormSubmissionVO>(
          queryClient,
          {
            queryKey: [
              getQueryKey("practices", "getLatestFormSubmission"),
              { practiceId, uuidOrSlug: slug, patientId },
            ],
            exact: true,
          },
          () => {
            return response.data.data;
          }
        );

        if (isOneOf(slug, ["DENTAL_HISTORY", "MEDICAL_HISTORY"])) {
          queryClient.invalidateQueries([
            getQueryKey("practices", "getMedicalHistoryAudit"),
            { practiceId, patientId },
          ]);
        }
      }
    },
  }),
});

export const upsertOnboardingOptions = makeMutation({
  mutationKey: ["practices", "upsertOnboardingOptions"],
  formatParams: (args: { practiceId: number; data: OnboardingOptionsRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      updateCachedData<OnboardingOptionsVO>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getOnboardingOptions"), { practiceId }],
          exact: true,
        },
        () => {
          return response.data.data;
        }
      );
    },
  }),
});
