import { FC, FormEvent, useState, useCallback } from "react";
import { secondsToHours, hoursToSeconds } from "date-fns";

import { FormVO, DentalProcedureVO } from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { YEAR_IN_HOURS, MONTH_IN_HOURS, WEEK_IN_HOURS, DAY_IN_HOURS } from "@libs/utils/date";
import { required } from "@libs/utils/validators";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Switch } from "@libs/components/UI/Switch";
import { FlyoverForm, FlyoverContent, FlyoverFooter } from "components/UI/FlyoverComponents";
import { BaseFormSection } from "components/UI/FormSection";
import { ToggleButtonList } from "components/UI/ToggleButtonList";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { ProcedureSelector } from "components/ProcedureSelector/ProcedureSelector";

import { EditFormSettingsProps } from "components/Settings/Forms/types";

interface FormSettingsState extends Pick<FormVO, "inPersonRequired" | "dentalProcedures"> {
  hasExpiry: boolean;
  expiryInterval?: number;
  expiryIntervalType: "DAY" | "WEEK" | "MONTH" | "YEAR";
}

const ExpiryOptions = [
  { label: "No Expiry", value: false },
  { label: "Expires After", value: true },
];

const IntervalTypeOptions: SelectOption<FormSettingsState["expiryIntervalType"]>[] = [
  { label: "Days", value: "DAY" },
  { label: "Weeks", value: "WEEK" },
  { label: "Months", value: "MONTH" },
  { label: "Years", value: "YEAR" },
];

const getFormSettingsStateSchema = ({ hasExpiry }: FormSettingsState) => ({
  expiryInterval: [
    {
      $v: required,
      $error: "Expiration interval is required",
      $ignore: !hasExpiry,
    },
  ],
});

const getExpirationInterval = (
  submissionExpirationSeconds: number
): Pick<FormSettingsState, "expiryInterval" | "expiryIntervalType"> => {
  const expirationHours = secondsToHours(submissionExpirationSeconds);

  return expirationHours % YEAR_IN_HOURS === 0
    ? { expiryInterval: expirationHours / YEAR_IN_HOURS, expiryIntervalType: "YEAR" }
    : expirationHours % MONTH_IN_HOURS === 0
      ? { expiryInterval: expirationHours / MONTH_IN_HOURS, expiryIntervalType: "MONTH" }
      : expirationHours % WEEK_IN_HOURS === 0
        ? { expiryInterval: expirationHours / WEEK_IN_HOURS, expiryIntervalType: "WEEK" }
        : { expiryInterval: expirationHours / DAY_IN_HOURS, expiryIntervalType: "DAY" };
};

const getFormSettingsState = (form: FormVO): FormSettingsState => {
  const { submissionExpirationSeconds, inPersonRequired, dentalProcedures } = form;
  const expiration: Pick<FormSettingsState, "hasExpiry" | "expiryInterval" | "expiryIntervalType"> =
    submissionExpirationSeconds == null
      ? { hasExpiry: false, expiryInterval: undefined, expiryIntervalType: "DAY" }
      : { hasExpiry: true, ...getExpirationInterval(submissionExpirationSeconds) };

  return {
    ...expiration,
    inPersonRequired,
    dentalProcedures,
  };
};

const getExpirationInSeconds = (
  expiryInterval: NonNullable<FormSettingsState["expiryInterval"]>,
  expiryIntervalType: FormSettingsState["expiryIntervalType"]
) => {
  return expiryIntervalType === "YEAR"
    ? hoursToSeconds(expiryInterval * YEAR_IN_HOURS)
    : expiryIntervalType === "MONTH"
      ? hoursToSeconds(expiryInterval * MONTH_IN_HOURS)
      : expiryIntervalType === "WEEK"
        ? hoursToSeconds(expiryInterval * WEEK_IN_HOURS)
        : hoursToSeconds(expiryInterval * DAY_IN_HOURS);
};

const getFormSettingsRequest = ({
  hasExpiry,
  expiryInterval,
  expiryIntervalType,
  inPersonRequired,
  dentalProcedures,
}: FormSettingsState) => ({
  submissionExpirationSeconds:
    hasExpiry && expiryInterval ? getExpirationInSeconds(expiryInterval, expiryIntervalType) : undefined,
  inPersonRequired,
  dentalProcedures,
});

export interface EditIntakeFormSettingsProps extends EditFormSettingsProps {
  procedures: DentalProcedureVO[];
}

export const EditFormSettings: FC<EditIntakeFormSettingsProps> = ({
  form,
  procedures,
  onUpdateSettings,
  onRequestClose,
  isSaving,
}) => {
  const [settings, setSettings] = useState(getFormSettingsState(form));

  const validation = useValidation(settings, getFormSettingsStateSchema(settings));

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();

      if (validation.validate().$isValid) {
        onUpdateSettings(getFormSettingsRequest(settings));
      }
    },
    [validation, settings, onUpdateSettings]
  );

  return (
    <FlyoverForm onSubmit={handleSubmit} data-testid="edit-form-settings">
      <FlyoverContent className="flex flex-col gap-y-6">
        <BaseFormSection
          title={<span className="text-xs">Expiration</span>}
          className="flex flex-col gap-y-1"
        >
          <div className="flex items-center gap-x-3">
            <ToggleButtonList
              type="radio"
              layout="custom"
              listClassName="flex items-center gap-x-2"
              optionClassName="h-8 px-3"
              options={ExpiryOptions}
              selectedValue={settings.hasExpiry}
              onChange={(_e, option) => setSettings((last) => ({ ...last, hasExpiry: option.value }))}
              required
            />

            <div className="flex items-center gap-x-2 relative">
              {validation.result.expiryInterval.$error ? (
                <FormFieldError className="absolute left-0 -bottom-1 translate-y-full">
                  {validation.result.expiryInterval.$error}
                </FormFieldError>
              ) : null}

              <FormFieldNumberInput
                aria-label="Expiration Interval"
                className="w-12"
                inputClassName="h-8"
                value={settings.expiryInterval}
                onValueChange={(value) => setSettings((last) => ({ ...last, expiryInterval: value }))}
                disabled={!settings.hasExpiry}
                required={settings.hasExpiry}
                error={validation.result.expiryInterval.$error}
                // Error message is separately positioned absolute to span across row
                displayErrorMessage={false}
                step={1}
                min={1}
                max={99}
                clamp
              />

              <FormFieldSelect
                aria-label="Expiration Interval Type"
                className="w-32"
                options={IntervalTypeOptions}
                value={settings.expiryIntervalType}
                onItemSelected={(value) => setSettings((last) => ({ ...last, expiryIntervalType: value }))}
                disabled={!settings.hasExpiry}
                required={settings.hasExpiry}
                isSearchable={false}
              />
            </div>
          </div>
        </BaseFormSection>

        <BaseFormSection
          title={<span className="text-xs">In-Person</span>}
          subTitle="Does this form need to be completed in the presence of staff?"
          className="flex flex-col gap-y-1"
        >
          <div className="w-fit">
            <Switch
              aria-label="In-Person"
              checked={settings.inPersonRequired}
              onChange={(e) => setSettings((last) => ({ ...last, inPersonRequired: e.target.checked }))}
              size="lg"
            >
              {settings.inPersonRequired ? "Yes" : "No"}
            </Switch>
          </div>
        </BaseFormSection>

        <BaseFormSection
          title={<span className="text-xs">Procedures</span>}
          subTitle="Select procedures this form is required for so we can attach it for you"
          className="flex flex-col gap-y-1"
        >
          <ProcedureSelector
            aria-label="Procedures"
            placeholder="Select"
            procedures={procedures}
            values={settings.dentalProcedures}
            hideSelectedOptions
            allowDuplicateSelections={false}
            onChange={(values) => setSettings((last) => ({ ...last, dentalProcedures: values }))}
          />
        </BaseFormSection>
      </FlyoverContent>

      <FlyoverFooter actions>
        <Button className="min-w-button" onClick={onRequestClose} theme="secondary">
          Cancel
        </Button>
        <AsyncButton className="min-w-button" isLoading={isSaving} theme="primary" type="submit">
          Save
        </AsyncButton>
      </FlyoverFooter>
    </FlyoverForm>
  );
};
