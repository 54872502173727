import { FC, useCallback, useMemo } from "react";
import { FileRejection, useDropzone } from "react-dropzone";
import { toast } from "react-toastify";
import { formatBytes, listToText } from "@libs/utils/formatString";
import { cx } from "@libs/utils/cx";
import { Button } from "@libs/components/UI/Button";
import { mimeTypeMapping } from "utils/files";

const IMAGE_MIME_EXTENSIONS = ["jpeg", "png"];

// eslint-disable-next-line unicorn/numeric-separators-style
const AllowedImageSize = 10000000; // 2e+7 bytes = 10mb.

interface Props {
  onFileDropped: (file: File) => void;
  onRequestCapturePhoto?: Func;
  accept?: string;
}

export const UploadDocument: FC<Props> = ({ onFileDropped, onRequestCapturePhoto, accept = "image/*" }) => {
  const mimeTypes = useMemo(
    () =>
      Object.entries(mimeTypeMapping)
        .filter((entry) => IMAGE_MIME_EXTENSIONS.includes(entry[0]))
        .map((entry) => entry[1]),
    []
  );

  const onDrop = useCallback(
    (accFiles: File[], rejFiles: FileRejection[]) => {
      // Do something with the files
      const mappedAcc = accFiles.map((file) => ({ file }));

      const files = [...mappedAcc, ...rejFiles];

      files.forEach((tempFile) => {
        if (tempFile.file.size < AllowedImageSize && mimeTypes.includes(tempFile.file.type)) {
          onFileDropped(tempFile.file);
        } else if (tempFile.file.size >= AllowedImageSize && !mimeTypes.includes(tempFile.file.type)) {
          toast.error(
            `Current Image size: ${formatBytes(
              tempFile.file.size
            )} is greater than 10MB and Supported image types are: ${IMAGE_MIME_EXTENSIONS.join(", ")}`
          );
        } else if (tempFile.file.size >= AllowedImageSize && mimeTypes.includes(tempFile.file.type)) {
          toast.error(`Current Image size: ${formatBytes(tempFile.file.size)} is greater than 10MB.`);
        } else if (tempFile.file.size < AllowedImageSize && !mimeTypes.includes(tempFile.file.type)) {
          toast.error(`Supported image types are: ${IMAGE_MIME_EXTENSIONS.join(", ")}`);
        }
      });
    },
    [mimeTypes, onFileDropped]
  );

  const { getRootProps, getInputProps, isDragAccept } = useDropzone({
    onDrop,
  });

  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} accept={accept} multiple={false} />
      <div
        className={cx(
          `flex
           flex-col
           items-center
           justify-center
           gap-y-2
           w-96
           h-[20.5rem]
           p-6
           border-2
           border-dashed
           rounded`,
          isDragAccept
            ? "border-primaryTheme dark:border-archyBlue-300 bg-blueLight dark:bg-archyBlue-900"
            : "border-slate-200"
        )}
      >
        <div className="text-sm">
          <Button theme="link">Upload File</Button>
          {onRequestCapturePhoto ? (
            <>
              <span> or </span>
              <Button
                onClick={(e) => {
                  e.stopPropagation();
                  onRequestCapturePhoto();
                }}
                theme="link"
              >
                Take a Photo
              </Button>
            </>
          ) : null}
        </div>
        <div
          className={`
            flex
            flex-col
            gap-y-2
            text-xs
            text-slate-700
            dark:text-slate-300
            text-center
          `}
        >
          <span>You can drag & drop files here, 10 MB maximum file size.</span>
          <span>File type should be {listToText(IMAGE_MIME_EXTENSIONS, "or")}.</span>
        </div>
      </div>
    </div>
  );
};
