import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as SnoozeIcon } from "@libs/assets/icons/snooze.svg";
import { ReactComponent as PlusCircleIcon } from "@libs/assets/icons/plus-circle.svg";
import { FC, useCallback } from "react";
import { PAGE_SIZE } from "@libs/utils/constants";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useAccount } from "@libs/contexts/AccountContext";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { DAY_IN_MS } from "@libs/utils/date";
import { MenuOptions, createMenuOptions } from "@libs/components/UI/MenuOptions";
import { ScrollableInfiniteQuery } from "@libs/components/UI/ScrollableInfiniteQuery";
import { FlyoverContent, FlyoverHeader } from "components/UI/FlyoverComponents";
import { getInfinitePatientNoteAlerts } from "api/notes/queries";
import { getTags } from "api/settings/notes/queries";
import { useToggleAlert } from "components/Notes/useToggleAlert";
import { AlertPanelItem } from "components/Notes/AlertPanelItem";
import { usePatientAlertSnoozeStorage } from "storage/alerts";
import { usePatientNotesRouter } from "components/Notes/usePatientNotesRouter";
import { PeekPanel } from "components/PatientBlurbInfo/PeekPanel";
import { ALERT_PEEK_BTN_ATTR } from "components/PatientBlurbInfo/patientPeek";

interface Props {
  onRequestClose: Func;
  patientId: number;
}

export const AlertsPanelContent: FC<Props> = ({ patientId, onRequestClose }) => {
  const snoozeMenu = useBoolean(false);
  const patientNotes = usePatientNotesRouter();
  const { snoozePatientAlerts } = usePatientAlertSnoozeStorage();

  const { practiceId, id: userId } = useAccount();
  const alertsQuery = useInfiniteApiQuery(
    getInfinitePatientNoteAlerts({
      args: {
        practiceId,
        patientId,
        pageSize: PAGE_SIZE,
        pageNumber: 1,
      },
    })
  );

  const [tagsQuery] = useApiQueries([getTags({ args: { practiceId } })]);

  const { handleToggleAlert } = useToggleAlert({ patientId });

  const clinicalTag = tagsQuery.data?.find((tag) => tag.slug === "CLINICAL");

  const snoozeOptions = createMenuOptions(
    {
      value: 1_800_000,
      label: "30 Minutes",
    },
    {
      value: 3_600_000,
      label: "1 hour",
    },
    {
      value: 14_400_000,
      label: "4 hours",
    },
    {
      value: 28_800_000,
      label: "8 hours",
    },
    {
      value: DAY_IN_MS,
      label: "24 hours",
    }
  );

  const handleClose = useCallback(() => {
    snoozePatientAlerts({ userId, patientId, duration: 300_000 });
    onRequestClose();
  }, [onRequestClose, userId, patientId, snoozePatientAlerts]);

  return (
    <>
      <FlyoverHeader size="sm" onClose={handleClose}>
        <div className="flex items-center">
          <div role="heading" aria-level={1} className="flex gap-x-2 text-base font-sansSemiBold flex-1">
            Alerts{" "}
            <ButtonIcon
              SvgIcon={PlusCircleIcon}
              theme="primary"
              tooltip={{
                content: "Create Alert",
                theme: "SMALL",
              }}
              onClick={() => {
                patientNotes.open("create", { patientId }, { alert: true });
                onRequestClose();
              }}
            />
          </div>

          <ButtonMenu
            isOpen={snoozeMenu.isOn}
            onRequestOpen={snoozeMenu.on}
            placement="bottom-end"
            menuContent={
              <MenuOptions
                options={snoozeOptions}
                onOptionClick={(option) => {
                  snoozeMenu.off();
                  snoozePatientAlerts({ userId, patientId, duration: option.value });
                  onRequestClose();
                }}
              />
            }
            onRequestClose={snoozeMenu.off}
          >
            {(buttonMenuProps) => (
              <ButtonIcon
                tooltip={{ placement: "left", content: "Snooze Alerts", theme: "SMALL" }}
                theme="primary"
                SvgIcon={SnoozeIcon}
                {...buttonMenuProps}
              />
            )}
          </ButtonMenu>
        </div>
      </FlyoverHeader>

      <FlyoverContent paddingClassName="p-0">
        <ScrollableInfiniteQuery infiniteQuery={alertsQuery} id={`patient-alerts-${patientId}`}>
          {(alerts) => (
            <div className="p-3">
              {alerts.map((alert) => (
                <AlertPanelItem
                  key={alert.uuid}
                  clinicalTag={clinicalTag}
                  onToggleAlert={handleToggleAlert}
                  onEditClick={() => {
                    patientNotes.open("edit", { patientId, uuid: alert.uuid });
                    onRequestClose();
                  }}
                  note={alert}
                />
              ))}
            </div>
          )}
        </ScrollableInfiniteQuery>
      </FlyoverContent>
    </>
  );
};

export const AlertsPanel: FC<Props & { isOpen: boolean }> = ({ isOpen, onRequestClose, ...props }) => {
  const handleClickOutside = useCallback(
    (e: MouseEvent) => {
      if (!(e.target as HTMLElement).closest(`[${ALERT_PEEK_BTN_ATTR}]`)) {
        onRequestClose();
      }
    },
    [onRequestClose]
  );

  return (
    <PeekPanel isOpen={isOpen} onClickOutside={handleClickOutside}>
      <AlertsPanelContent onRequestClose={onRequestClose} {...props} />
    </PeekPanel>
  );
};
