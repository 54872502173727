interface Props {
  values: string[];
}

export const MultiSelectValues: React.FC<Props> = ({ values }) => {
  return (
    <div className="flex flex-wrap gap-x-1 gap-y-2">
      {values.map((value, index) => (
        <div
          key={index}
          className={`
            py-1
            px-3
            rounded-2xl
            border
            border-greyLighter
            min-h-6
            flex
            flex-wrap
            items-center
          `}
        >
          <div className="font-sans text-xs">{value}</div>
        </div>
      ))}
    </div>
  );
};
