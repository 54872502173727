import { useEffect } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useExpandedLedgerItemsStorage } from "storage/billing";

/**
 * A hook that returns a `UseBooleanResult` indicating whether a ledger item is
 * expanded and persists the expanded state in localStorage.
 * @param pageName The name of the page to persist the expanded state for.
 * @param itemId The ID of the ledger item to persist the expanded state for.
 * @returns A `UseBooleanResult` indicating whether the ledger item is expanded.
 */

export const usePersistExpandRow = (itemId: string) => {
  const { isLedgerItemExpanded, expandLedgerItem, collapseLedgerItem } = useExpandedLedgerItemsStorage();
  const expandRow = useBoolean(() => isLedgerItemExpanded(itemId));

  useEffect(() => {
    if (expandRow.isOn) {
      expandLedgerItem(itemId);
    } else {
      collapseLedgerItem(itemId);
    }
  }, [collapseLedgerItem, expandLedgerItem, expandRow.isOn, itemId]);

  return expandRow;
};
