import { FC } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { noop } from "@libs/utils/noop";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as CopyIcon } from "@libs/assets/icons/copy.svg";
import { ReactComponent as SendIcon } from "@libs/assets/icons/send.svg";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { isDefined } from "@libs/utils/types";

interface Props {
  emailTemplate: string | undefined;
  smsTemplate: string | undefined;
  onCopySms: Func;
  onSendTest?: Func;
}

export const EmailMessageActions: FC<Props> = ({ emailTemplate, smsTemplate, onCopySms, onSendTest }) => {
  const confirmCopy = useBoolean(false);

  return (
    <>
      <ButtonIcon
        theme="primary"
        tooltip={{
          theme: "SMALL",
          content: smsTemplate ? "Copy SMS Content" : "No SMS Content To Copy",
        }}
        onClick={emailTemplate ? (emailTemplate === smsTemplate ? noop : confirmCopy.on) : onCopySms}
        SvgIcon={CopyIcon}
        disabled={!smsTemplate}
      />
      {isDefined(onSendTest) ? (
        <ButtonIcon
          theme="primary"
          tooltip={
            emailTemplate
              ? { theme: "SMALL", content: "Send Test Email" }
              : { theme: "MEDIUM", content: "Preview is available once you've entered a message." }
          }
          disabled={!emailTemplate}
          onClick={onSendTest}
          SvgIcon={SendIcon}
        />
      ) : null}
      {confirmCopy.isOn ? (
        <ConfirmationModal
          size="3xs"
          primaryText="Copy SMS Content"
          secondaryText="This will copy the SMS content over the current email content. Do you wish to proceed?"
          onCancel={confirmCopy.off}
          confirmText="Yes"
          onConfirm={() => {
            onCopySms();
            confirmCopy.off();
          }}
        />
      ) : null}
    </>
  );
};
