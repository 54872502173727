import { EmployeeJobTitleV2VO, EmploymentDetailsVO } from "@libs/api/generated-api";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { isDefined } from "@libs/utils/types";

export const labelForEmployeeStatus = (status: EmploymentDetailsVO["status"]) => {
  return status === "ARCHIVED" ? "Terminated" : sentenceCaseConstant(status ?? "");
};

const jobTitleOrder: EmployeeJobTitleV2VO["key"][] = [
  "GENERAL_DENTIST",
  "PEDIATRIC_DENTIST",
  "DENTAL_ASSISTANT",
  "DENTURIST",
  "ENDODONTIST",
  "DENTAL_HYGIENIST",
  "REGISTERED_DENTAL_HYGIENIST_INDEPENDENT_PRACTICE",
  "ORTHODONTIST",
  "ORAL_SURGEON",
  "PERIODONTIST",
  "PROSTHODONTIST",
  "UNKNOWN",
];

export const sortJobTitles = (jobTitles: EmployeeJobTitleV2VO[]) => {
  return jobTitleOrder.map((key) => jobTitles.find((jobTitle) => jobTitle.key === key)).filter(isDefined);
};
