import { getUnixTime } from "date-fns";
import { produce } from "immer";
import {
  InvoiceEntryVO,
  InvoiceLineItemVO,
  CreateInvoiceRequest,
  AmountAdjustment,
  InvoiceVO,
  AppointmentAdjustmentVO,
  CustomAdjustmentTypeVO,
  CreateAppointmentAdjustmentRequest,
  PatientProcedureSnapshot,
} from "@libs/api/generated-api";
import { centsToDollars, dollarsToCents } from "@libs/utils/currency";
import { isValueInRange } from "components/UI/CurrencyAdjustmentInput";
import { AdjustmentRequestWithDisplayValue } from "components/PatientProfile/Billing/InvoiceAdjustmentFormRow";
import { InvoiceEntryTotals } from "components/PatientProfile/Billing/InvoiceEntryComponents";

type GenerateInvoicePayloadFunc = (params: {
  patientId: number;
  commit: boolean;
  invoiceableEntries: InvoiceEntryVO[];
  selectedEntries: SelectedEntries;
  amountAdjustments: AmountAdjustmentsByReferenceId;
  appointmentAdjustments?: AdjustmentRequestWithDisplayValue[];
  note?: string;
  dueDate?: Date;
}) => CreateInvoiceRequest;

export type SelectedEntries = Set<string>;

export type AdjustmentSymbols = "$" | "%";
type DiscountOrTaxAdjustmentType = "DISCOUNT" | "TAX";

export type AdjustmentChangeCallback = (
  invoiceEntryOrLineItem: InvoiceEntryVO | InvoiceLineItemVO,
  symbol: AdjustmentSymbols,
  type: DiscountOrTaxAdjustmentType,
  value: string
) => void;

export type AmountAdjustmentForm = Partial<
  AmountAdjustment & {
    discountMin: number;
    discountMax: number;
    discountError: string;
    taxMin: number;
    taxMax: number;
    taxError: string;
  }
>;

export type AmountAdjustmentsByReferenceId = {
  [key: string]: AmountAdjustmentForm | undefined;
};

export const cxStylesInvoiceBanner = {
  bannerStart: "rounded-s border-s",
  bannerSection: "bg-offWhite border-t border-b",
  bannerEnd: "rounded-e border-e mr-5",
};

const isEntrySelected = (selectedItems: SelectedEntries, invoiceableEntry: InvoiceEntryVO) => {
  return selectedItems.has(invoiceEntryOrLineItemToId(invoiceableEntry));
};

export const trimAdjustment = (
  adjustment: AdjustmentRequestWithDisplayValue
): CreateAppointmentAdjustmentRequest => {
  adjustment.procedureAdjustments = adjustment.procedureAdjustments?.filter((proc) => {
    if (proc.adjustmentAmount === 0) {
      delete proc.adjustmentAmount;
    }

    if (proc.adjustmentPercentage === "0") {
      delete proc.adjustmentPercentage;
    }

    // Remove procedure adjustments that don't have an amount or percentage.
    return proc.adjustmentAmount != null || proc.adjustmentPercentage != null;
  });

  // The BE ignores those since they are added by the FE (see types
  // `AdjustmentRequestWithDisplayValue` vs.
  // `CreateAppointmentAdjustmentRequest`), but we might as well not send them.
  delete adjustment.displayValue;
  delete adjustment.adjustmentType;
  delete adjustment.percentOrDollar;
  delete adjustment.applyAdjustmentTo;
  delete adjustment.randomId;
  delete adjustment.defaultProcedures;

  return adjustment;
};

export const generateInvoicePayload: GenerateInvoicePayloadFunc = ({
  patientId,
  commit,
  selectedEntries,
  amountAdjustments,
  appointmentAdjustments,
  invoiceableEntries,
  note,
  dueDate,
}) => {
  return {
    commit,
    payer: { type: "PATIENT", id: patientId },
    entryProposals: generateEntryProposals({ invoiceableEntries, selectedEntries, amountAdjustments }),
    ...(appointmentAdjustments && {
      appointmentAdjustmentRequests: produce(appointmentAdjustments, (draftAdjustments) => {
        draftAdjustments.forEach((a) => trimAdjustment(a));
      }),
    }),
    ...(dueDate && { dueAt: getUnixTime(dueDate) }),
    ...(note && { note }),
  };
};

const generateEntryProposals = ({
  invoiceableEntries,
  selectedEntries,
  amountAdjustments,
}: {
  invoiceableEntries: InvoiceEntryVO[];
  selectedEntries: SelectedEntries;
  amountAdjustments: AmountAdjustmentsByReferenceId;
}) => {
  const entriesForSubmission = invoiceableEntries.filter((entry) => isEntrySelected(selectedEntries, entry));

  return entriesForSubmission.map((invoiceableEntry) => {
    // Only include in `itemProposals` line items that have discount and/or
    // tax adjustments.
    const adjustedLineItems = invoiceableEntry.lineItems
      .filter((lineItem) => hasAdjustments(amountAdjustments, lineItem))
      .map((lineItem) => ({
        lineItemReference: lineItem.lineItemReference,
        amountAdjustment: generateAjustments(amountAdjustments, lineItem),
      }));

    return {
      entryReference: invoiceableEntry.entryReference,
      ledgerOrderUuids: invoiceableEntry.ledgerOrderUuids,
      ...(adjustedLineItems.length && { itemProposals: adjustedLineItems }),
      ...(hasAdjustments(amountAdjustments, invoiceableEntry) && {
        amountAdjustment: generateAjustments(amountAdjustments, invoiceableEntry),
      }),
    };
  });
};

export const generateAjustments = (
  amountAdjustments: AmountAdjustmentsByReferenceId,
  invoiceEntryOrLineItem: InvoiceEntryVO | InvoiceLineItemVO
) => {
  const adjustments = amountAdjustments[invoiceEntryOrLineItemToId(invoiceEntryOrLineItem)];

  return adjustments
    ? {
        ...(adjustments.discountAmount && { discountAmount: dollarsToCents(adjustments.discountAmount) }),
        ...(adjustments.discountPercentage && { discountPercentage: adjustments.discountPercentage }),
        ...(adjustments.taxPercentage && { taxPercentage: adjustments.taxPercentage }),
      }
    : undefined;
};

export const computeNewAdjustments = ({
  previousAdjustments,
  invoiceEntryOrItem,
  symbol,
  type,
  value,
}: {
  previousAdjustments: AmountAdjustmentsByReferenceId;
  invoiceEntryOrItem: Parameters<AdjustmentChangeCallback>["0"];
  symbol: Parameters<AdjustmentChangeCallback>["1"];
  type: Parameters<AdjustmentChangeCallback>["2"];
  value: Parameters<AdjustmentChangeCallback>["3"];
}) => {
  // NOTE: `value` comes in as dollars, not cents (when amount)
  const referenceId = invoiceEntryOrLineItemToId(invoiceEntryOrItem);
  const allAdjustments = { ...previousAdjustments };
  const newAdjustment = { ...allAdjustments[referenceId] };

  let min, max;

  if (type === "TAX") {
    [min, max] = adjustTaxPercentage(newAdjustment, value);
  } else if (symbol === "%") {
    [min, max] = adjustDiscountPercentage(newAdjustment, value);
  } else {
    [min, max] = adjustDiscountAmount(newAdjustment, value, invoiceEntryOrItem);
  }

  const { valid, error } = isValueInRange(value, min, max);

  // Set error if any
  if (type === "TAX") {
    if (valid) {
      delete newAdjustment.taxError;
    } else {
      newAdjustment.taxError = error;
    }
  } else if (valid) {
    delete newAdjustment.discountError;
  } else {
    newAdjustment.discountError = error;
  }

  // Do we have any keys left in `newAdjustment`? If not, remove its entry
  // from `allAdjustments` instead of setting an empty object.
  if (Object.keys(newAdjustment).length) {
    allAdjustments[referenceId] = newAdjustment;
  } else {
    delete allAdjustments[referenceId];
  }

  return allAdjustments;
};

export const hasAdjustments = (
  amountAdjustments: AmountAdjustmentsByReferenceId,
  invoiceEntryOrLineItem: InvoiceEntryVO | InvoiceLineItemVO
) => Boolean(generateAjustments(amountAdjustments, invoiceEntryOrLineItem));

const adjustTaxPercentage = (newAdjustment: AmountAdjustmentForm, value: string) => {
  if (value) {
    newAdjustment.taxPercentage = value;
    newAdjustment.taxMin = 0.01;
    newAdjustment.taxMax = 100;
  } else {
    delete newAdjustment.taxPercentage;
    delete newAdjustment.taxMin;
    delete newAdjustment.taxMax;
  }

  return [newAdjustment.taxMin, newAdjustment.taxMax];
};

const adjustDiscountPercentage = (newAdjustment: AmountAdjustmentForm, value: string) => {
  if (value) {
    newAdjustment.discountPercentage = value;
    newAdjustment.discountMin = 0.01;
    newAdjustment.discountMax = 100;
    delete newAdjustment.discountAmount;
  } else {
    delete newAdjustment.discountPercentage;
    delete newAdjustment.discountMin;
    delete newAdjustment.discountMax;
  }

  return [newAdjustment.discountMin, newAdjustment.discountMax];
};

const adjustDiscountAmount = (
  newAdjustment: AmountAdjustmentForm,
  value: string,
  invoiceEntryOrItem: InvoiceEntryVO | InvoiceLineItemVO
) => {
  if (value) {
    newAdjustment.discountAmount = Number(value);
    newAdjustment.discountMin = 0.01;
    newAdjustment.discountMax = centsToDollars(invoiceEntryOrItem.subtotalAmount);
    delete newAdjustment.discountPercentage;
  } else {
    delete newAdjustment.discountAmount;
    delete newAdjustment.discountMin;
    delete newAdjustment.discountMax;
  }

  return [newAdjustment.discountMin, newAdjustment.discountMax];
};

const isInvoiceEntry = (item: InvoiceEntryVO | InvoiceLineItemVO): item is InvoiceEntryVO => {
  return Boolean((item as InvoiceEntryVO).entryReference);
};

export const invoiceEntryOrLineItemToId = (invoiceEntryOrLineItem: InvoiceEntryVO | InvoiceLineItemVO) => {
  const reference = isInvoiceEntry(invoiceEntryOrLineItem)
    ? invoiceEntryOrLineItem.entryReference
    : invoiceEntryOrLineItem.lineItemReference;

  return `${reference.type}-${reference.id}`;
};

export const getInvoiceAmountDue = (invoice?: InvoiceVO) => {
  if (!invoice || invoice.state === "VOID") {
    return 0;
  }

  return invoice.amount - invoice.amountPaid;
};

export const findMatchingEntryFromInvoice = (entry: InvoiceEntryVO, invoice: InvoiceVO | undefined) => {
  return invoice?.entries.find(
    (invoiceEntry) => invoiceEntryOrLineItemToId(invoiceEntry) === invoiceEntryOrLineItemToId(entry)
  );
};

export const getAdjustmentsByProcedureId = (procedureId: number, adjustments: AppointmentAdjustmentVO[]) =>
  adjustments.map(
    (adjustment) =>
      adjustment.procedureAdjustmentItems?.find((proc) => proc.procedureId === procedureId) ?? {
        amount: 0,
        procedureId,
      }
  );

export const getMaximumDiscounts = (entry: InvoiceEntryVO) => {
  const discounts: {
    [procedureId: string]: number;
    $entryTotal: number;
  } = {
    $entryTotal: entry.amount,
  };

  entry.lineItems.forEach((lineItem) => {
    discounts[lineItem.lineItemReference.id] = lineItem.amount;
  });

  return discounts;
};

export const getPracticeAdjustmentById = (
  adjustmentId: number,
  practiceAdjustments: CustomAdjustmentTypeVO[]
) => practiceAdjustments.find((adjustType) => adjustType.id === adjustmentId);

export const getTotalsToShow = ({ charges, discounts, additionalCharges, total }: InvoiceEntryTotals) => {
  const showDiscounts = typeof discounts === "number" && discounts !== 0;
  const showAdditionalCharges = typeof additionalCharges === "number" && additionalCharges !== 0;
  const showCharges = typeof charges === "number" && (showDiscounts || showAdditionalCharges);
  const showMinus = showCharges && showDiscounts;
  const showPlus = (showCharges || showDiscounts) && showAdditionalCharges;
  const showTotal = typeof total === "number";

  return {
    showDiscounts,
    showAdditionalCharges,
    showCharges,
    showTotal,
    showMinus,
    showPlus,
    showEqual: showCharges,
  };
};

export const getPatientProcedureDescription = (patientProcedureSnapshot: PatientProcedureSnapshot) => {
  const toothName = patientProcedureSnapshot.toothName ? `#${patientProcedureSnapshot.toothName} ` : "";
  const laymanName = patientProcedureSnapshot.laymanName ?? patientProcedureSnapshot.name;

  return toothName + laymanName;
};
