import { Fragment, ChangeEventHandler, useMemo, useCallback } from "react";
import { format } from "date-fns";
import { DailyHuddleAppointmentVO, TagVO } from "@libs/api/generated-api";
import { getLocalDate } from "@libs/utils/date";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { useNavigate } from "react-router-dom";
import { filterMap } from "@libs/utils/array";
import {
  TableGrid,
  HeaderCheckboxCell,
  HeaderCell,
  Row,
  Cell,
} from "@libs/components/UI/GridTableComponents";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { EmptyContent } from "@libs/components/UI/EmptyContent";
import { useQueryParams } from "hooks/useQueryParams";
import { DailyHuddleRow } from "components/DailyHuddle/Row";

import tabletUrl from "assets/images/tablet.svg";
import { noteFlyoverPaths } from "components/Notes/usePatientNotesRouter";
import { HuddleEntryGroupsByDate } from "components/DailyHuddle/types";

interface Props {
  dailyHuddleInfiniteQuery: UseInfiniteApiQueryResult<DailyHuddleAppointmentVO[]>;
  dailyHuddleQueryKey: unknown;
  entryGroupsByDate: HuddleEntryGroupsByDate;
  excludedTagIds: number[];
  currentAppointmentId?: number;
  selectedRows: Set<number>;
  selectedCount: number;
  totalRows: number;
  huddleEntries: DailyHuddleAppointmentVO[] | undefined;
  onSelectAllRows: Func;
  onDeselectAllRows: Func;
  onCheckboxChange: ChangeEventHandler<HTMLInputElement>;
}

const headers = [
  { id: "checkbox", label: "", width: "min-content" },
  { id: "appointment", label: "Appointment", width: "min-content" },
  { id: "name", label: "Name", width: "min-content" },
  { id: "room", label: "Room", width: "min-content" },
  { id: "provider", label: "Provider", width: "min-content" },
  { id: "tasks", label: "Tasks", width: "min-content" },
  { id: "tags", label: "Tags", width: "minmax(min-content, 1fr)" },
  { id: "balance", label: "Balance", width: "min-content" },
  { id: "insurance", label: "Insurance", width: "min-content" },
  { id: "menu", label: "", width: "56px" },
];

export const DailyHuddleTable: React.FC<Props> = ({
  dailyHuddleInfiniteQuery,
  huddleEntries,
  dailyHuddleQueryKey,
  entryGroupsByDate,
  currentAppointmentId,
  selectedRows,
  selectedCount,
  totalRows,
  excludedTagIds,
  onSelectAllRows,
  onDeselectAllRows,
  onCheckboxChange,
}) => {
  const navigate = useNavigate();
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: dailyHuddleInfiniteQuery });

  const { updateQuery, getUrl } = useQueryParams("huddle");

  const tagOrder = useMemo(() => {
    const ICONS_ORDERED_BY_SLUG: NonNullable<TagVO["slug"]>[] = [
      "CLINICAL",
      "NEW_PATIENT",
      "MEDICAL_CONDITION",
      "ALLERGY",
      "PREGNANT",
      "FAMILY_MEMBER_SAME_DAY",
      "SELF_SCHEDULED_APPOINTMENT",
      "PRE_MEDICATION_REQUIRED",
      "XRAY",
      "BIRTHDAY",
      "REPEAT_NO_SHOW",
    ];

    const excludedTagIdsSet = new Set(excludedTagIds);

    const slugsInUse = new Set(
      huddleEntries?.flatMap((entry) =>
        filterMap(entry.tags, (tag) => (excludedTagIdsSet.has(tag.id) ? undefined : tag.slug))
      )
    );

    return ICONS_ORDERED_BY_SLUG.filter((val) => slugsInUse.has(val));
  }, [huddleEntries, excludedTagIds]);

  const handleRowClick = useCallback(
    (appointmentId: number, patientId: number) => {
      updateQuery("replaceIn", {
        appointmentId,
        patientId,
      });
    },
    [updateQuery]
  );

  const handleOpenClinicalNote = useCallback(
    (appointmentId: number, patientId: number, noteUuid: string) => {
      // In order to make sure the patient notes matches the current patient
      // we need to both update the appointmentId/patientId and the patientNotes
      // parameter at the same time. Otherwise another rule kicks in that removes
      // the patientNotes param if the patientNotes patientId doesn't match
      // the current patientId
      const url = getUrl(
        { appointmentId, patientId, patientNotes: noteFlyoverPaths.edit({ patientId, uuid: noteUuid }) },
        true
      );

      navigate(url);
    },
    [getUrl, navigate]
  );

  return (
    <PersistScrollPosition
      className="h-full overflow-y-auto"
      id="huddleTable"
      ref={rootRef}
      resetScrollKey={dailyHuddleQueryKey}
    >
      <TableGrid columnWidths={headers.map(({ width }) => width)}>
        <Row>
          {headers.map((header) => {
            return header.id === "checkbox" ? (
              <HeaderCheckboxCell
                key={header.id}
                selectedCount={selectedCount}
                totalRows={totalRows}
                onSelectAllRows={onSelectAllRows}
                onDeselectAllRows={onDeselectAllRows}
                size="short"
              />
            ) : (
              <HeaderCell key={header.id} size="short">
                {header.label}
              </HeaderCell>
            );
          })}
        </Row>
        <ScrollableInfiniteQueryResult
          infiniteQuery={dailyHuddleInfiniteQuery}
          loading={<LoadingContent containerClassName="min-h-screen col-span-full" />}
          loadMoreClassName="col-span-full"
          noResults={
            <div className="flex justify-center col-span-full mt-10">
              <EmptyContent src={tabletUrl} alt="No Appointments" text="No Appointments" />
            </div>
          }
          scrollRef={scrollRef}
        >
          {Object.keys(entryGroupsByDate).map((date) => (
            <Fragment key={date}>
              <Row>
                <Cell className="col-span-full px-5 py-1 bg-slate-100">
                  {format(new Date(getLocalDate(date)), "LLL d, yyyy")}
                </Cell>
              </Row>
              {entryGroupsByDate[date].map((entry) => (
                <DailyHuddleRow
                  key={entry.appointmentId}
                  entry={entry}
                  currentAppointmentId={currentAppointmentId}
                  checked={selectedRows.has(entry.appointmentId)}
                  tagOrder={tagOrder}
                  excludedTagIds={excludedTagIds}
                  onCheckboxChange={onCheckboxChange}
                  onRowClick={handleRowClick}
                  onOpenClinicalNote={handleOpenClinicalNote}
                />
              ))}
            </Fragment>
          ))}
        </ScrollableInfiniteQueryResult>
      </TableGrid>
    </PersistScrollPosition>
  );
};
