import { FC, useMemo } from "react";
import { format, parseISO } from "date-fns";
import { CustomHolidayVO } from "@libs/api/generated-api";
import { getLocalDate } from "@libs/utils/date";
import { SettingsLabel, SettingsValue } from "@libs/components/UI/SettingsComponents";
import { YearTabs } from "components/Settings/YearTabs";

const cxStyles = {
  holidayField: `
    grid
    gap-11
    w-full
    mb-2.5
    grid-cols-[71px_75px_75px_75px_max-content]
  `,
};

export const TimeOff: FC<{
  customHolidays: CustomHolidayVO[];
  selectedYear: string;
  onYearUpdate: (newYear: string) => void;
}> = ({ customHolidays, selectedYear, onYearUpdate }) => {
  const yearHolidays = useMemo(
    () => customHolidays.filter((item) => new Date(item.startDate).getFullYear() === Number(selectedYear)),
    [customHolidays, selectedYear]
  );

  return (
    <YearTabs selectedTab={selectedYear} onSelect={onYearUpdate} tabsClassName="mb-2">
      <div className={cxStyles.holidayField}>
        <SettingsLabel>Closed From</SettingsLabel>
        <SettingsLabel />
        <SettingsLabel>Closed Until</SettingsLabel>
        <SettingsLabel />
        <SettingsLabel>Description</SettingsLabel>
      </div>
      {yearHolidays.map((holiday) => (
        <div key={holiday.id} className={cxStyles.holidayField}>
          <SettingsValue>{format(parseISO(holiday.startDate), "MMM dd")}</SettingsValue>
          <SettingsValue>
            {format(getLocalDate(holiday.startDate, holiday.startTime), "hh:mm aaa")}
          </SettingsValue>
          <SettingsValue>{format(parseISO(holiday.endDate), "MMM dd")}</SettingsValue>
          <SettingsValue>
            {" "}
            {format(getLocalDate(holiday.endDate, holiday.endTime), "hh:mm aaa")}
          </SettingsValue>
          <SettingsValue> {holiday.description}</SettingsValue>
        </div>
      ))}
    </YearTabs>
  );
};
