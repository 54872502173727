import { useEffect, useMemo, useRef, useState } from "react";
import { YEAR_IN_MS } from "@libs/utils/date";
import { wrapStorage } from "@libs/storage/wrapStorage";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { useAccount } from "@libs/contexts/AccountContext";
import { StorageNamespaces } from "storage/namespaces";

const version = "v1";

export type MangoConfig = {
  allowInbound: boolean;
  ext: string;
};

const getMangoStorage = (practiceId: number, storage: Storage) => {
  const mangoStorage = wrapStorage<MangoConfig, StorageNamespaces>(StorageNamespaces.mango, storage, {
    practiceId,
  });

  const getMangoConfig = () => mangoStorage.get("mango", version);

  const setMangoConfig = (config: MangoConfig) => {
    mangoStorage.set("mango", config, {
      version,
      expires: YEAR_IN_MS,
    });
  };

  return { getMangoConfig, setMangoConfig };
};

export const useMangoStorage = () => {
  const { localStorage } = useStorageContext();
  const { practiceId } = useAccount();

  const storage = useMemo(() => getMangoStorage(practiceId, localStorage), [localStorage, practiceId]);
  const [mangoConfig, setMangoConfig] = useState(() => {
    const config = storage.getMangoConfig();

    return {
      allowInbound: config?.allowInbound ?? true,
      ext: config?.ext ?? "None",
    };
  });

  const ref = useRef(mangoConfig);

  useEffect(() => {
    // avoid initial set
    if (ref.current !== mangoConfig) {
      storage.setMangoConfig(mangoConfig);
    }
  }, [mangoConfig, storage]);

  return [mangoConfig, setMangoConfig] as const;
};
