import { PayPeriodVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatAsISODate, formatISODate } from "@libs/utils/date";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Button } from "@libs/components/UI/Button";
import { ReactComponent as RightCaret } from "@libs/assets/icons/right-caret.svg";
import { ReactComponent as LeftCaret } from "@libs/assets/icons/left-caret.svg";

import { ScrollableInfiniteQuery } from "@libs/components/UI/ScrollableInfiniteQuery";
import { DateRangeTab } from "utils/routing/employees";
import { UseInfinitePayPeriodsQueryResult } from "components/EmployeeProfile/Timesheet/types";

type Props = {
  hasPayrolls: boolean;
  isIncreaseMonthsDisabled: boolean;
  selectedTab: DateRangeTab;
  selectedRange: [Date, Date | null];
  infinitePayPeriodsQuery: UseInfinitePayPeriodsQueryResult;
  onPayrollSelected: (payroll: PayPeriodVO) => void;
  onSelectTab: (tab: DateRangeTab) => void;
  onDecreaseMonthsClick: Func;
  onIncreaseMonthsClick: Func;
};

const cxStyles = {
  tab: (isSelected: boolean) =>
    cx("py-2 block", isSelected ? "text-primaryTheme font-sansSemiBold" : "text-greyLight"),
};

export const TimeSheetDatePickerMenu: React.FC<Props> = ({
  hasPayrolls,
  isIncreaseMonthsDisabled,
  selectedTab,
  selectedRange,
  infinitePayPeriodsQuery,
  onPayrollSelected,
  onDecreaseMonthsClick,
  onIncreaseMonthsClick,
  onSelectTab,
}) => {
  return (
    <>
      {selectedTab === "calendar" ? (
        <div
          className={cx(
            `absolute
             top-0
             w-[504px]
             flex
             justify-between
             items-center
             pt-1.5
             px-6
             h-16`,
            hasPayrolls ? "left-28" : "left-1"
          )}
        >
          <ButtonIcon onClick={onDecreaseMonthsClick} theme="primary" SvgIcon={LeftCaret} />

          <ButtonIcon
            onClick={onIncreaseMonthsClick}
            disabled={isIncreaseMonthsDisabled}
            theme="primary"
            SvgIcon={RightCaret}
          />
        </div>
      ) : (
        <ScrollableInfiniteQuery
          infiniteQuery={infinitePayPeriodsQuery}
          id="payroll-periods"
          className="absolute w-full left-0 pl-28 top-0 pt-6"
        >
          {(items) =>
            items.map((item) => {
              const [start, end] = selectedRange;
              const selected = Boolean(
                end && formatAsISODate(start) === item.startDate && formatAsISODate(end) === item.endDate
              );

              return (
                <button
                  key={item.payrollUuid}
                  className={cx(
                    "hover:bg-slate-100 py-1 pl-6 block text-xs w-full text-left",
                    selected && "font-sansSemiBold"
                  )}
                  type="button"
                  onClick={() => onPayrollSelected(item)}
                >
                  {formatISODate(item.startDate)} - {formatISODate(item.endDate)}
                </button>
              );
            })
          }
        </ScrollableInfiniteQuery>
      )}
      {hasPayrolls ? (
        <div
          className={`
            absolute
            top-0
            w-28
            left-0
            h-full
            pt-10
            px-5
            border-r
            border-r-greyLighter
          `}
        >
          <Button
            theme="link"
            className={cxStyles.tab(selectedTab === "payPeriod")}
            onClick={() => onSelectTab("payPeriod")}
          >
            Pay Period
          </Button>
          <Button
            theme="link"
            className={cxStyles.tab(selectedTab === "calendar")}
            onClick={() => onSelectTab("calendar")}
          >
            Calendar
          </Button>
        </div>
      ) : null}
    </>
  );
};
