import { FC, PropsWithChildren, ReactNode } from "react";

interface Props {
  title: ReactNode;
  description: ReactNode;
}

export const AlertSection: FC<PropsWithChildren<Props>> = ({ children, title, description }) => {
  return (
    <div>
      <div className="text-sm font-sansSemiBold mb-1">{title}</div>
      <p className="text-xs mb-6">{description}</p>
      {children}
    </div>
  );
};
