import { FC } from "react";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { UserAccountVO } from "@libs/api/generated-api";
import { AccountsList } from "components/Main/AccountsList";
import { PageWrapper } from "components/SignIn/PageWrapper";
import { paths } from "utils/routing/paths";

export const SelectAccountPage: FC<{
  accounts: UserAccountVO[];
  onAccountSelected: (account: UserAccountVO) => void;
}> = ({ accounts, onAccountSelected }) => {
  return (
    <PageWrapper>
      <div className="bg-white border border-slate-200 rounded">
        <AccountsList title="Choose Practice" accounts={accounts} onAccountSelected={onAccountSelected} />
        <div className="flex items-center justify-center py-6">
          <ButtonInternalLink theme="link" className="text-sm" to={paths.signOut()}>
            Sign Out
          </ButtonInternalLink>
        </div>
      </div>
    </PageWrapper>
  );
};
