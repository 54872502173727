import { useAttachments } from "components/Claim/Attachments/ClaimAttachmentsContext";
import { AttachmentsSummary } from "components/Claim/Attachments/AttachmentsSummary";

export const ReviewAttachments: React.FC = () => {
  const { perioCharts, medicalImages, narrative, other } = useAttachments();

  return (
    <div className="h-full overflow-y-auto scroll-ml-5">
      <div className="flex flex-col mb-4">
        <div className="text-sm font-sansSemiBold">Selected Attachments</div>
        <AttachmentsSummary
          medicalImages={medicalImages}
          narrative={narrative}
          perioCharts={perioCharts}
          other={other}
        />
      </div>
    </div>
  );
};
