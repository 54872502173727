import { ChangeEvent, FC, useCallback } from "react";
import {
  FormEditableTextBlockElementVO,
  FormStringResponseVO,
  FormTextInputElementVO,
} from "@libs/api/generated-api";
import { FormSubmissionResponses } from "components/PatientProfile/Forms/types";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";
import { useAutoExpand } from "hooks/useAutoExpand";

export const MultilineStringInput: FC<{
  element: FormTextInputElementVO | FormEditableTextBlockElementVO;
  response?: FormStringResponseVO;
  onUpdate?: (updater: (last: FormSubmissionResponses) => void) => void;
}> = ({ element, onUpdate, response }) => {
  const { elRef, styles } = useAutoExpand(response?.response ?? "");
  const handleChange = useCallback(
    (e: ChangeEvent<HTMLTextAreaElement>) => {
      onUpdate?.((currentResponses) => {
        currentResponses[element.uuid] = {
          type: "STRING",
          response: e.target.value,
        };
      });
    },
    [onUpdate, element.uuid]
  );

  return (
    <FormFieldTextarea
      ref={elRef}
      style={styles}
      edit={Boolean(onUpdate)}
      value={response?.response}
      //  "EDITABLE_TEXT_BLOCK" titles are not to be exposed to patients.
      label={element.type === "TEXT_INPUT" ? element.title : undefined}
      onChange={handleChange}
    />
  );
};
