import { add, sub } from "date-fns";
import React from "react";
import { formatAsISODate } from "@libs/utils/date";
import { useStartOfDayInPracticeTimezone } from "hooks/useStartOfDayInPracticeTimezone";

const BEGINNING_OF_TIME = "1970-01-01";

export type LongAgingOption = {
  label: string;
  shortDisplayLabel: string;
  value: number;
  startDate: string;
  endDate?: string;
};

export type LabelSet = {
  label: string;
  shortDisplayLabel: string;
};

export const useLongAgingOptions = ({
  labels,
  includeFuture,
}: {
  labels: LabelSet[];
  includeFuture: boolean;
}) => {
  const today = useStartOfDayInPracticeTimezone();
  const endDate = add(today, { days: 1 }).getTime();

  return React.useMemo((): LongAgingOption[] => {
    // Today is considered overdue, Not Overdue starts at tomorrow
    const tomorrow = add(today, { days: 1 });

    //0-30 is actually thirty one days, including 0 as a day + 30
    const thirtyOneDaysAgo = sub(new Date(endDate), { days: 31 });
    const ninetyDaysAgo = sub(thirtyOneDaysAgo, { days: 60 });
    const oneEightyDaysAgo = sub(ninetyDaysAgo, { days: 90 });
    const threeSixtyFive = sub(new Date(endDate), { days: 366 });
    let dates = [
      {
        startDate: formatAsISODate(tomorrow),
      },
      {
        startDate: formatAsISODate(thirtyOneDaysAgo),
        endDate: formatAsISODate(new Date(endDate)),
      },
      {
        startDate: formatAsISODate(ninetyDaysAgo),
        endDate: formatAsISODate(thirtyOneDaysAgo),
      },
      {
        startDate: formatAsISODate(oneEightyDaysAgo),
        endDate: formatAsISODate(ninetyDaysAgo),
      },
      {
        startDate: formatAsISODate(threeSixtyFive),
        endDate: formatAsISODate(oneEightyDaysAgo),
      },
      {
        startDate: BEGINNING_OF_TIME,
        endDate: formatAsISODate(threeSixtyFive),
      },
    ];

    if (!includeFuture) {
      const [_, ...rest] = dates;

      dates = rest;
    }

    return labels.map((labelSet, index) => ({
      ...labelSet,
      ...dates[index],
      value: index,
    }));
  }, [endDate, includeFuture, labels, today]);
};
