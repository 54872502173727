import { FC, useCallback, useMemo } from "react";
import { RoomVO } from "@libs/api/generated-api";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { mapSelectionsToOptions } from "@libs/utils/mapSelectOptions";
import { FormFieldMultiSelect } from "components/UI/FormFieldMultiSelect";

interface Props {
  rooms: RoomVO[];
  savedRooms: RoomVO[] | undefined;
  roomIds: Set<number>;
  onChange: (roomIds: Set<number>) => void;
}

export const BlockSelectRooms: FC<Props> = ({ savedRooms, roomIds, rooms, onChange }) => {
  const roomOptions = useMemo(() => {
    return [
      { label: "Select All", value: 0 },
      ...mapSelectionsToOptions(rooms, savedRooms, (room) => ({
        label: room.roomName,
        value: room.id,
      })),
    ];
  }, [rooms, savedRooms]);

  const selectRooms = useCallback(
    (values: number[]) => {
      onChange(new Set(values.includes(0) ? rooms.map(({ id }) => id) : values));
    },
    [rooms, onChange]
  );

  return (
    <FormFieldMultiSelect
      label="Rooms"
      closeMenuOnSelect={false}
      formatOptionLabel={(option) => {
        const { label, value } = option;

        return value === 0 ? (
          <div className="text-xs text-primaryTheme">Select All</div>
        ) : (
          <Checkbox readOnly checked={roomIds.has(value)} className="gap-0.5 pointer-events-none">
            <div>{label}</div>
          </Checkbox>
        );
      }}
      hideSelectedOptions={false}
      onItemsSelected={selectRooms}
      options={roomOptions}
      placeholder="None"
      value={[...roomIds]}
    />
  );
};
