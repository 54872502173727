import { GLOBAL_IP } from "components/Settings/Security/IPAuthorization/utils";

export const GlobalAccess = () => <p>All employees can sign in to Archy from anywhere.</p>;

export const GlobalIp = () => (
  <p>
    <span className="font-sansSemiBold">Optional:</span> To allow specific employees to sign in from anywhere
    copy and paste
    <span className="font-sansSemiBold px-1">{GLOBAL_IP}</span>into the IP Address field.
  </p>
);

export const WhiteListGlobalIp = () => (
  <p className="text-sm">Only enabled employees will be able to sign in to Archy.</p>
);

export const FirstIp = () => (
  <p>
    <span className="font-sansSemiBold">IMPORTANT:</span> The first Authorized IP Address you should add is
    your office IP address. Check the{" "}
    <span className="italic">Allow access to all employees from this IP address box</span> to ensure that all
    employees, even ones hired in the future, can access Archy from your office.
  </p>
);

export const AddIpAddresses = () => (
  <p>
    <span className="font-sansSemiBold">Optional:</span> Add Authorized IP Addresses and designate which
    employees can access Archy from those addresses.
  </p>
);

export const AfterAddingIp = () => (
  <p>
    Once you have added one or more Authorized IP Addresses, employees that are signed in to an Unauthorized
    IP Address will immediately be logged out and will only be able to sign in on the Authorized IP Addresses.{" "}
    <a
      className="text-primaryTheme"
      href="https://support.archy.com/hc/en-us/articles/22203590326171-Adding-Authorized-IP-Addresses"
      target="_blank"
    >
      Learn more
    </a>
  </p>
);

export const EmployeeAccess = () => (
  <p>
    Employees will only be able to sign in to Archy from the following IP addresses that they have been
    assigned to.
  </p>
);
