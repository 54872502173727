import { localPoint } from "@visx/event";
import React from "react";

export const useTextMouseMove = (onMove: (params: { tooltipTop?: number; tooltipLeft?: number }) => void) => {
  const onTextMove: React.MouseEventHandler<SVGTextElement> = React.useCallback(
    (e) => {
      const eventSvgCoords = localPoint(e);
      // To show tooltip below the text hovered:
      const OFFSET = 80;

      onMove({
        tooltipTop: eventSvgCoords?.y ? eventSvgCoords.y + OFFSET : undefined,
        tooltipLeft: eventSvgCoords?.x,
      });
    },
    [onMove]
  );

  return onTextMove;
};
