import { InsurancePlanSummaryVO } from "@libs/api/generated-api";
import { UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { Checkbox } from "@libs/components/UI/Checkbox";
import {
  TableGrid,
  HeaderCell,
  ColumnSortIndicator,
  HeaderButtonCell,
} from "@libs/components/UI/GridTableComponents";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { InsurancePlanTableRow } from "components/Settings/InsurancePlans/Row";
import { InsurancePlansQuery, PlansSortKeys } from "utils/routing/settings";

interface Props {
  insurancePlansInfiniteQuery: UseInfiniteApiListQueryResult<InsurancePlanSummaryVO>;
  insurancePlansQueryKey: unknown;
  selectedPlans: Set<string>;
  onCopy: (planUuid: string) => void;
  onEdit: (planUuid: string) => void;
  onSort: (sortColumn: PlansSortKeys) => void;
  onSelectToggle: (planUuid: string) => void;
  onSelectAllToggle: Func;
  query: InsurancePlansQuery;
}

interface InsuranceTableHeader {
  id: string;
  label: string;
  sortColumn?: PlansSortKeys;
  width: string;
}

const headers: InsuranceTableHeader[] = [
  {
    id: "checkbox",
    label: "",
    width: "min-content",
  },
  {
    id: "groupNumber",
    label: "Group Number",
    sortColumn: "groupNumber",
    width: "1fr",
  },
  {
    id: "groupName",
    label: "Group Name",
    sortColumn: "groupName",
    width: "2fr",
  },
  {
    id: "nickname",
    label: "Nickname",
    width: "2fr",
  },
  {
    id: "employerName",
    label: "Employer Name",
    sortColumn: "employerName",
    width: "1fr",
  },
  {
    id: "carrier",
    label: "Carrier",
    width: "min-content",
  },
  {
    id: "patients",
    label: "Patients",
    sortColumn: "patientCount",
    width: "1fr",
  },
  {
    id: "feeSchedule",
    label: "Fee Schedule",
    width: "min-content",
  },
];

export const InsurancePlansTable: React.FC<Props> = ({
  insurancePlansInfiniteQuery,
  insurancePlansQueryKey,
  selectedPlans,
  onCopy,
  onEdit,
  onSort,
  onSelectToggle,
  onSelectAllToggle,
  query,
}) => {
  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: insurancePlansInfiniteQuery });

  const insurancePlans = useFlattenPages(insurancePlansInfiniteQuery.data);

  return (
    <PersistScrollPosition
      className="min-h-0 col-span-full overflow-y-scroll"
      id="feeSchedulesTable"
      ref={rootRef}
      resetScrollKey={insurancePlansQueryKey}
    >
      <ScrollableInfiniteQueryResult
        infiniteQuery={insurancePlansInfiniteQuery}
        loading={<LoadingContent containerClassName="min-h-screen col-span-full" />}
        loadMoreClassName="col-span-full"
        scrollRef={scrollRef}
      >
        <TableGrid className="min-w-[1024px]" columnWidths={headers.map(({ width }) => width)}>
          {headers.map((header) =>
            header.id === "checkbox" ? (
              <HeaderCell key={header.id} size="short">
                <Checkbox
                  checked={selectedPlans.size === insurancePlans?.length}
                  indeterminate={selectedPlans.size > 0 && selectedPlans.size < (insurancePlans?.length ?? 0)}
                  onChange={onSelectAllToggle}
                />
              </HeaderCell>
            ) : header.sortColumn ? (
              <HeaderButtonCell
                key={header.id}
                onClick={() => header.sortColumn && onSort(header.sortColumn)}
                size="short"
              >
                {header.label}
                <ColumnSortIndicator
                  className="ml-2"
                  direction={query.sortColumn === header.sortColumn ? query.sortDirection : undefined}
                />
              </HeaderButtonCell>
            ) : (
              <HeaderCell key={header.id} size="short">
                {header.label}
              </HeaderCell>
            )
          )}
          {insurancePlans?.map((insurancePlan) => {
            return (
              <InsurancePlanTableRow
                insurancePlan={insurancePlan}
                onCopy={onCopy}
                onEdit={onEdit}
                isSelected={selectedPlans.has(insurancePlan.uuid)}
                onSelectToggle={() => onSelectToggle(insurancePlan.uuid)}
                key={insurancePlan.uuid}
              />
            );
          })}
        </TableGrid>
      </ScrollableInfiniteQueryResult>
    </PersistScrollPosition>
  );
};
