/* eslint-disable @typescript-eslint/naming-convention */
import { ReactComponent as CancelIcon } from "@libs/assets/icons/cancel.svg";
import { LinkIcon } from "@libs/components/UI/LinkIcon";
import { useCallback, useMemo } from "react";
import { CalendlyForm } from "components/Settings/OnboardingSettings/CalendlyForm";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { NotFound } from "components/NotFoundRoute";
import { paths } from "utils/routing/paths";
import { useOnboardingMeetings } from "components/Settings/OnboardingSettings/useOnboardingMeetings";
import { usePracticeLogger } from "components/Main/PracticeSegmentContext";

export const OnboardingScheduleSensorTestingRoute: React.FC = () => {
  const getOnboardingMeeting = useOnboardingMeetings();
  const meetingDetails = useMemo(() => getOnboardingMeeting("sensorTesting"), [getOnboardingMeeting]);
  const { track } = usePracticeLogger();

  const handleEventScheduled = useCallback(() => {
    track(
      { event: "Training Scheduled", domains: ["Settings"] },
      { properties: { meetingId: meetingDetails?.id } }
    );
  }, [meetingDetails?.id, track]);

  return meetingDetails ? (
    <SettingsPanel
      actions={
        <LinkIcon
          SvgIcon={CancelIcon}
          to={paths.settingsSection({ section: "sensor-testing" })}
          theme="primary"
        />
      }
      title={`Schedule ${meetingDetails.title}`}
      includePadding={false}
    >
      <CalendlyForm
        calendlyLink={meetingDetails.url}
        defaultAnswers={meetingDetails.defaultAnswers}
        onEventScheduled={handleEventScheduled}
      />
    </SettingsPanel>
  ) : (
    <NotFound />
  );
};
