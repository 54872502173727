import React from "react";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { ClaimsList } from "components/Claims/Claims/ClaimsList";
import { ClaimsAppHistoryProvider } from "components/Claims/ClaimsLinksContext";
import { useClaimsTabs } from "components/Claims/Claims/useClaimTabs";

export const PreAuthsRoute: React.FC = () => {
  usePageTitle("Pre-Auths");

  const tabs = useClaimsTabs();

  return (
    <ClaimsAppHistoryProvider name="preAuth">
      <ClaimsList isPreAuth={true} routeName="claims" tabs={tabs} />
    </ClaimsAppHistoryProvider>
  );
};
