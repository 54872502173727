import React from "react";
import { cx } from "@libs/utils/cx";
import { ReactComponent as DropDownIcon } from "@libs/assets/icons/down-caret.svg";
import { CollapsibleSection } from "components/UI/CollapsibleSection";

interface Props {
  buttonContent: string;
  className?: string;
  children: React.ReactNode;
  disabled?: boolean;
  id?: string;
  isOpen: boolean;
  onRequestClose: Func;
  onRequestOpen: Func;
}

export const SimpleAccordian: React.FC<Props> = ({
  buttonContent,
  className,
  children,
  disabled,
  id,
  isOpen,
  onRequestClose,
  onRequestOpen,
}) => {
  return (
    <div className="relative">
      <div className={className}>
        <button
          className={`
            flex
            items-center
            justify-between
            w-full
            text-xs
            font-sansSemiBold
            text-left
            disabled:text-greyMedium
          `}
          disabled={disabled}
          id={id}
          onClick={isOpen ? onRequestClose : onRequestOpen}
          type="button"
        >
          {buttonContent}
          <DropDownIcon
            className={cx("w-4 h-4 text-primaryTheme transition-transform", isOpen && "rotate-180")}
          />
        </button>
      </div>
      <CollapsibleSection isOpen={isOpen}>{children}</CollapsibleSection>
    </div>
  );
};
