import { useAccount } from "@libs/contexts/AccountContext";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { PatientNoteVO } from "@libs/api/generated-api";
import { updateCachedData, updatePaginatedCachedData } from "@libs/utils/queryCache";
import { getQueryKey } from "@libs/utils/queries";
import { useQueryClient } from "@tanstack/react-query";
import { updatePatientNote } from "api/notes/mutations";
import { handleError } from "utils/handleError";

export const useToggleAlert = ({ patientId }: { patientId: number }) => {
  const { practiceId } = useAccount();

  const queryClient = useQueryClient();

  const [updatePatientNoteMutation] = useApiMutations([updatePatientNote]);

  const handleToggleAlert = (note: PatientNoteVO, isAlert: boolean) => {
    const original = {
      isAlert: note.isAlert,
    };

    updatePaginatedCachedData<PatientNoteVO>(
      queryClient,
      { queryKey: [getQueryKey("v2", "getPatientNotesV2"), { practiceId, patientId }] },
      (cachedNotes) => {
        return cachedNotes.map((cachedNote) =>
          cachedNote.uuid === note.uuid ? { ...note, isAlert } : cachedNote
        );
      }
    );

    if (note.isAlert && !isAlert) {
      updateCachedData<PatientNoteVO[]>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getPatientNoteAlerts"), { practiceId, patientId }],
          exact: true,
        },
        (cachedNotes) => {
          return cachedNotes.filter((cachedNote) => cachedNote.uuid !== note.uuid);
        }
      );

      updatePaginatedCachedData<PatientNoteVO>(
        queryClient,
        {
          queryKey: [getQueryKey("practices", "getPatientNoteAlerts"), { practiceId, patientId }, "infinite"],
        },
        (cachedNotes) => {
          return cachedNotes.filter((cachedNote) => cachedNote.uuid !== note.uuid);
        }
      );
    }

    updatePatientNoteMutation.mutate(
      {
        practiceId,
        patientId,
        patientNoteUuid: note.uuid,
        original,
        data: {
          name: note.name,
          text: note.text,
          status: note.status,
          providerStatus: note.providerStatus,
          tagIds: note.tags.map((tag) => tag.id),
          isAlert,
        },
      },
      {
        onError: (err) => {
          handleError(err);
          queryClient.invalidateQueries([getQueryKey("v2", "getPatientNotesV2"), { practiceId, patientId }]);
          queryClient.invalidateQueries([
            getQueryKey("practices", "getPatientNoteAlerts"),
            { practiceId, patientId },
          ]);
        },
      }
    );
  };

  return { handleToggleAlert };
};
