import { FC, useMemo } from "react";
import { useLocation } from "react-router-dom";

import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as OpenProfileIcon } from "@libs/assets/icons/open-profile.svg";

import { RouteIds } from "components/PatientProfile/types";
import { PATIENT_PEEK_BTN_ATTR } from "components/PatientBlurbInfo/patientPeek";

interface Props {
  id: RouteIds;
  to: string;
  onPeekClick: (id: RouteIds) => void;
  label: string;
}

const patientPeekBtnAttr = { [PATIENT_PEEK_BTN_ATTR]: true };

export const PatientNavPeekButton: FC<Props> = ({ id, label, to, onPeekClick }) => {
  const location = useLocation();
  const isOnTo = useMemo(
    () => (id === "overview" ? location.pathname === to : location.pathname.startsWith(to)),
    [to, id, location.pathname]
  );

  return isOnTo ? null : (
    <button
      type="button"
      className={`
        absolute
        -top-0.5
        -right-0.5
        opacity-0
        flex
        items-center
        justify-center
        p-1
        bg-white
        rounded
        border
        border-grey-300
        hover:bg-slate-200
        group-hover:opacity-100
        transition-all
      `}
      onClick={() => onPeekClick(id)}
      {...patientPeekBtnAttr}
    >
      <Icon tooltip={{ content: `${label} Peek`, theme: "SMALL" }} SvgIcon={OpenProfileIcon} />
    </button>
  );
};
