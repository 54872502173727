import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useFilteredProviderCategory } from "components/Dashboard/hooks/useFilteredProviderCategory";
import { useFormattedProviderRollups } from "components/Dashboard/hooks/useFormattedProviderRollups";
import { useProviderRollupQueries } from "components/Dashboard/hooks/useProviderRollupQueries";
import { TimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { DashboardFilter } from "utils/routing/dashboard/serializedFilter";

// Utility hook that combines the useFilteredProviderCategory, useProviderRollupQueries, and useFormattedProviderRollups hooks
export const useProviderRollupFilterOptions = ({
  filters,
  dateWindow,
  metric,
}: {
  metric: "productionAmount" | "patientCount" | "appointmentCount" | "netProduction";
  filters: DashboardFilter[];
  dateWindow: TimeSeriesPageSelections;
}) => {
  const providerFilters = useFilteredProviderCategory(filters);

  const { providersInTimeWindowQuery, allProvidersQuery } = useProviderRollupQueries({
    dateWindow,
    filters: undefined,
    metric,
  });

  const [providersInTimeWindowQueryResult, allProvidersQueryResult] = useApiQueries([
    providersInTimeWindowQuery,
    allProvidersQuery,
  ]);

  return useFormattedProviderRollups(providersInTimeWindowQueryResult, allProvidersQueryResult, {
    ...providerFilters,
  });
};
