import { FC, Dispatch, SetStateAction } from "react";
import { produce } from "immer";

import { PatientCriteriaVO, PatientListCriteria } from "@libs/api/generated-api";
import { CheckboxList } from "@libs/components/UI/CheckboxList";

import { FilterSection } from "components/Communications/Filters/FilterSection";
import { GENDER_OPTIONS } from "components/Communications/Filters/utils";

interface Props extends Pick<PatientCriteriaVO, "genders"> {
  onUpdatePatientListCriteria: Dispatch<SetStateAction<PatientListCriteria>>;
}

export const GenderFilterSection: FC<Props> = ({ genders, onUpdatePatientListCriteria }) => (
  <FilterSection title="Gender" dataTestId="gender-filter-section" isOpen={genders && genders.length > 0}>
    <CheckboxList
      layout="vert"
      selectedValues={new Set(genders)}
      options={GENDER_OPTIONS}
      onChange={(newSet) =>
        onUpdatePatientListCriteria((last) =>
          produce(last, (draft) => {
            if (newSet.size > 0) {
              if (draft.patientCriteria) {
                draft.patientCriteria.genders = [...newSet];
              } else {
                draft.patientCriteria = { genders: [...newSet] };
              }
            } else {
              delete draft.patientCriteria?.genders;
            }
          })
        )
      }
    />
  </FilterSection>
);
