import React, { useMemo } from "react";
import { PerioChartExamVO, PerioChartToothConfigVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatISODate } from "@libs/utils/date";
import { isDefined } from "@libs/utils/types";
import { CellProps, EMPTY_CELL, Row } from "@libs/components/UI/GridTableComponents";
import { PerioChartToothSurfaceType } from "components/Charting/Perio/PerioTypes";
import { getFieldNames, getPerioToothName } from "components/Charting/Perio/perioChartUtils";

type Props = {
  exams: PerioChartExamVO[];
  sequenceType: "PROBING" | "GINGMARGIN";
};

const Cell: React.FC<CellProps> = ({ children, className, borderBottom = true, borderTop }) => {
  return (
    <div
      role="cell"
      className={cx(
        "flex justify-center py-2 text-xs",
        borderBottom && "border-b border-slate-300",
        borderTop && "border-t border-slate-300",

        className
      )}
    >
      {children}
    </div>
  );
};

type CellEntry = {
  tooth: PerioChartToothConfigVO;
  entry?: PerioChartExamVO["entries"][number];
  previousEntry?: PerioChartExamVO["entries"][number];
};

const VerticalCell: React.FC<{ children?: React.ReactNode; className?: string }> = ({
  children,
  className,
}) => {
  return (
    <div
      style={{ writingMode: "vertical-rl" }}
      className={cx(
        `flex
         justify-center
         items-center
         row-span-full
         text-xs
         border-slate-300
         font-sansSemiBold`,
        className
      )}
    >
      <div className="rotate-180">{children}</div>
    </div>
  );
};
const PerioEntries: React.FC<{
  teeth: PerioChartToothConfigVO[];
  entries: PerioChartExamVO["entries"];
  previousEntries?: PerioChartExamVO["entries"];
  sequenceType: "PROBING" | "GINGMARGIN";
  surface: PerioChartToothSurfaceType;
  borderBottom?: boolean;
}> = ({ teeth, entries, previousEntries, sequenceType, surface, borderBottom }) => {
  const cellEntries: CellEntry[] = useMemo(() => {
    const currentEntries = entries.filter((entry) => entry.perioSequenceType === sequenceType);
    const lastEntries = previousEntries?.filter((entry) => entry.perioSequenceType === sequenceType);

    return teeth.map((tooth) => {
      const toothEntry = currentEntries.find((entry) => entry.toothNum === tooth.toothNum);
      const previousEntry = lastEntries?.find((entry) => entry.toothNum === tooth.toothNum);

      return {
        entry: toothEntry,
        previousEntry,
        tooth,
      };
    });
  }, [sequenceType, entries, previousEntries, teeth]);

  return cellEntries.map((cellEntry, i) => {
    const fields = getFieldNames(surface, cellEntry.tooth.toothNum);

    return (
      <React.Fragment key={i}>
        {fields.map((field, j) => {
          const value = cellEntry.entry?.[field];
          const previousValue = cellEntry.previousEntry?.[field];

          const delta =
            isDefined(value) && isDefined(previousValue) ? Math.abs(value) - Math.abs(previousValue) : 0;

          return (
            <Cell
              borderBottom={borderBottom}
              className={cx(
                delta > 0 ? "bg-redLight" : delta < 0 ? "bg-greenLight" : undefined,
                j === fields.length - 1 && i < cellEntries.length - 1 && "border-r border-slate-300"
              )}
              key={field}
            >
              {isDefined(value) ? Math.abs(value) : EMPTY_CELL}
            </Cell>
          );
        })}
      </React.Fragment>
    );
  });
};

const TableGrid: React.FC<{ numberOfRows: number; children: React.ReactNode; className?: string }> = ({
  numberOfRows,
  children,
  className,
}) => {
  return (
    <div
      className={cx("grid grid-cols-[2rem,6rem,repeat(48,1fr)]", className)}
      style={{ gridTemplateRows: `repeat(${numberOfRows}, minmax(0, 1fr))` }}
    >
      {children}
    </div>
  );
};

const DateCell: React.FC<{ date?: string; borderBottom?: boolean }> = ({ date, borderBottom }) => {
  return (
    <Cell className="border-x border-slate-300" borderBottom={borderBottom}>
      {date ? formatISODate(date) : date}
    </Cell>
  );
};

const ComparisonGrid: React.FC<Props & { isTopTeeth?: boolean }> = ({
  exams,
  isTopTeeth = false,
  sequenceType,
}) => {
  const teeth = useMemo(() => {
    const mostRecentExam = exams.at(-1) as PerioChartExamVO;
    const values = [...(isTopTeeth ? mostRecentExam.upperToothConfig : mostRecentExam.lowerToothConfig)];

    return isTopTeeth ? values : values.reverse();
  }, [isTopTeeth, exams]);

  const examsReversed = useMemo(() => [...exams].reverse(), [exams]);

  return (
    <div className="border border-slate-300 rounded-md">
      <TableGrid numberOfRows={exams.length}>
        <VerticalCell className="border-b border-slate-300">Facial</VerticalCell>
        {exams.map((exam, i) => {
          return (
            <Row key={exam.uuid}>
              <DateCell date={exam.date} />
              <PerioEntries
                surface={PerioChartToothSurfaceType.FACIAL}
                sequenceType={sequenceType}
                teeth={teeth}
                previousEntries={exams[i - 1]?.entries ?? undefined}
                entries={exam.entries}
              />
            </Row>
          );
        })}
      </TableGrid>
      <TableGrid numberOfRows={1}>
        <div className="border-b border-slate-300" />
        <Cell className="border-r border-slate-300" />
        {teeth.map((tooth, i) => {
          return (
            <Cell
              key={tooth.toothNum}
              className={cx(
                "col-span-3 font-sansSemiBold",
                i < teeth.length - 1 && "border-r border-slate-300"
              )}
            >
              {getPerioToothName(tooth)}
            </Cell>
          );
        })}
      </TableGrid>
      <TableGrid numberOfRows={exams.length}>
        <VerticalCell>Lingual</VerticalCell>
        {examsReversed.map((exam, i) => {
          const borderBottom = i < exams.length - 1;

          return (
            <Row key={exam.uuid}>
              <DateCell borderBottom={borderBottom} date={exam.date} />
              <PerioEntries
                surface={PerioChartToothSurfaceType.LINGUAL}
                sequenceType={sequenceType}
                teeth={teeth}
                previousEntries={examsReversed[i + 1]?.entries ?? undefined}
                entries={exam.entries}
                borderBottom={borderBottom}
              />
            </Row>
          );
        })}
      </TableGrid>
    </div>
  );
};

export const PerioValueCompareTable: React.FC<Props> = (props) => {
  return (
    <div className="flex flex-col gap-8">
      <ComparisonGrid {...props} isTopTeeth />
      <ComparisonGrid {...props} />
    </div>
  );
};
