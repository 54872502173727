import { FC, ReactNode } from "react";
import { DentalProcedureVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { ExpiredCDTCodeIcon } from "components/Charting/ExpiredCDTCodeIcon";
import { useNow } from "hooks/useNow";

export const AddProcedureFormHeaderContent: FC<{
  dentalProcedure: DentalProcedureVO;
  className?: string;
  children?: ReactNode;
}> = ({ dentalProcedure, className }) => {
  const now = useNow();

  return (
    <div className={cx("flex-1", className)}>
      <div className="flex gap-x-2 items-center">
        <h3 className="text-sm font-sansSemiBold">{dentalProcedure.cdtCode}</h3>
        <ExpiredCDTCodeIcon now={now} endDate={dentalProcedure.endDate} />
      </div>
      <p className="text-xs">{dentalProcedure.name}</p>
    </div>
  );
};

export const AddProcedureFormHeaderContentWrapper: FC<{ className?: string; children?: ReactNode }> = ({
  children,
  className,
}) => {
  return (
    <div
      className={cx(
        `w-full
         text-left
         py-6
         px-8
         flex
         justify-between
         items-stretch
         gap-x-4`,
        className
      )}
    >
      {children}
    </div>
  );
};
