/* istanbul ignore file */
import React from "react";
import { HeaderData } from "components/Dashboard/Tables/types";

export const INSURANCE_ADJUSTMENT_HEADERS: HeaderData[] = [
  { label: "Patient", width: "1fr", sortField: "patientLastName" },
  { label: "Service Date", width: "12rem", sortField: "appointmentDate" },
  { label: "Subscriber", width: "1fr" },
  { label: "Ins Type", width: "1fr", sortField: "insuranceOrdinal" },
  { label: "Adjust Status", width: "1fr" },
  {
    label: "Fee",
    width: "1fr",
    align: "right",
    tooltip: {
      content: (
        <>
          The fee represents UCR fees for each completed procedure and does{" "}
          <span className="italic font-bold">not</span> take into account the negotiated rates for procedures
          or any additional adjustments.
        </>
      ),
    },
  },
  {
    label: "Adjust",
    width: "1fr",
    align: "right",
    sortField: "insuranceAdjustmentAmount",
    tooltip: {
      content:
        "The amount subtracted from each procedure's UCR rate to reflect negotiated rate for each procedure paid by a specific insurance carrier.",
    },
  },
  {
    label: "Net Produced",
    width: "1fr",
    align: "right",
    tooltip: {
      content:
        "The net production amount of all completed procedures. Net production represents the negotiated rates to reflect the expected payment amount by both the insurance and patient as dictated by each insurance carrier. Additional collection adjustments are not included in the net production totals.",
    },
  },
  { label: "", width: "4rem" },
];

export const COLLECTION_ADJUSTMENT_HEADERS: HeaderData[] = [
  { label: "Patient", width: "1fr", sortField: "patientLastName" },
  { label: "Service Date", width: "12rem", sortField: "adjustmentDate" },
  { label: "Adjust Type", width: "1fr", sortField: "customAdjustmentTypeId" },
  { label: "Adjust Note", width: "minmax(10rem,40rem)" },
  { label: "Adjust Amount", width: "1fr", align: "right" },
  { label: "", width: "4rem" },
];

// Carrier constants for selections
export const NO_INSURANCE_CARRIER_ID = -1;
export const UNKNOWN_INSURANCE_CARRIER_ID = -2;
