import { TabText } from "components/UI/TabText";

interface Props {
  label: string;
  count: number;
  onTabSelect: Func;
  isSelected: boolean;
}

export const MessageTab: React.FC<Props> = ({ label, count, onTabSelect, isSelected }) => {
  const displayLabel = `${label} (${count})`;

  return (
    <button type="button" onClick={onTabSelect}>
      <TabText data-testid="message-tab-text" isSelected={isSelected}>
        {displayLabel}
      </TabText>
    </button>
  );
};
