import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";
import { DurationIcon } from "./DurationIcon";

const FIVE_MINS = 5;

export const DurationRenderer: React.FC<{
  duration: string;
  showDuration?: boolean;
  onRemove?: (index: number) => void;
}> = ({ duration, showDuration, onRemove }) => {
  return (
    <div className="flex flex-wrap gap-0.5">
      {[...duration].map((item, index) => (
        <div key={item + index.toString()} className={onRemove && "group"}>
          {onRemove && (
            <ButtonIcon
              tooltip={{ content: "Remove", theme: "SMALL" }}
              onClick={() => onRemove(index)}
              SvgIcon={CloseIcon}
              theme="primary"
              size="sm"
              className="w-5 h-5 px-0.5 group-hover:block hidden"
              data-testid="duration-remove"
            />
          )}
          <DurationIcon
            key={item + index.toString()}
            durationType={item}
            className={onRemove && "group-hover:hidden"}
            data-testid="duration-icon"
          />
        </div>
      ))}
      {showDuration && duration && (
        <div
          className={`
            text-xs
            font-sansSemiBold
            h-5
            flex
            items-center
            whitespace-nowrap
            pl-2
          `}
        >
          {duration.length * FIVE_MINS} mins
        </div>
      )}
    </div>
  );
};
