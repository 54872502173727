import React, { useCallback, useState } from "react";
import { produce } from "immer";
import { SingleValue } from "react-select";
import {
  BenefitLimitationVO,
  FrequencyLimitationCategoryVO,
  FrequencyLimitVO,
} from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useBoolean } from "@libs/hooks/useBoolean";
import { isNullish } from "@libs/utils/types";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { FormFieldProps } from "@libs/components/UI/FormField";
import { ReactComponent as FrequencyLimitsIcon } from "@libs/assets/icons/advanced-options.svg";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import {
  useDurations,
  useLimitationTypeOptions,
  useMouthConstraintOptions,
  useLimitationAsString,
  simpleOptions,
  useSimpleFrequencyOptions,
  SimpleFrequencyOption,
  defaultFrequencyToOptionValueMap,
} from "components/PatientProfile/Insurance/InsuranceDetailsRoute/LimitationsTab/hooks";
import { FormFieldSelect, FormFieldSelectProps } from "components/UI/FormFieldSelect";
import { FormFieldNumberInput, FormFieldNumberInputProps } from "components/UI/FormFieldNumberInput";

export const LimitationString: React.FC<{ limitationStrParts: string[] }> = ({ limitationStrParts }) => {
  return limitationStrParts.length ? (
    <>
      {limitationStrParts.map((strPart, index) => (
        <React.Fragment key={index}>
          {strPart}
          {index !== limitationStrParts.length - 1 && <br />}
        </React.Fragment>
      ))}
    </>
  ) : (
    <div>{EMPTY_CELL}</div>
  );
};

type RequiredQuantityType = "durationQuantity" | "frequencyQuantity";

const SimpleFrequencyLimitSelect: React.FC<{
  edit?: boolean;
  frequency: Partial<FrequencyLimitVO>;
  frequencyLimitationCategory: FrequencyLimitationCategoryVO | undefined;
  isSummarizedLimitationView: boolean;
  limitation: BenefitLimitationVO | undefined;
  onChange: (value: Partial<FrequencyLimitVO> | null) => void;
  selectedValue?: number;
}> = ({
  edit,
  frequency,
  frequencyLimitationCategory,
  isSummarizedLimitationView,
  limitation,
  onChange,
  selectedValue,
}) => {
  const limitationStrParts = useLimitationAsString({ frequency, isSummarizedLimitationView, limitation });

  const [selectedOptionValue, setSelectedOptionValue] = useState(selectedValue ?? 0);
  const [requiredQuantityType, setRequiredQuantityType] = useState<RequiredQuantityType>(
    isNullish(simpleOptions[selectedOptionValue].frequencyLimit.frequencyQuantity)
      ? "frequencyQuantity"
      : "durationQuantity"
  );
  const [quantityValue, setQuantityValue] = useState(frequency[requiredQuantityType]);

  const handleOnQuantityChange = useCallback(
    (value: number | undefined) => {
      setQuantityValue(value);

      const baseFrequency = getBaseFrequency(simpleOptions[selectedOptionValue], frequencyLimitationCategory);

      onChange({
        ...baseFrequency,
        [requiredQuantityType]: value,
      });
    },
    [frequencyLimitationCategory, onChange, requiredQuantityType, selectedOptionValue]
  );

  const handleOnSelectOption = useCallback(
    (option: SingleValue<SimpleFrequencyOption>) => {
      const optionValue = option?.value ?? 0;

      setSelectedOptionValue(optionValue);

      const requiredQuantity: RequiredQuantityType = isNullish(
        simpleOptions[optionValue].frequencyLimit.frequencyQuantity
      )
        ? "frequencyQuantity"
        : "durationQuantity";

      setRequiredQuantityType(requiredQuantity);

      const baseFrequency = getBaseFrequency(option, frequencyLimitationCategory);

      onChange({
        ...baseFrequency,
        [requiredQuantity]: quantityValue,
      });
    },
    [frequencyLimitationCategory, onChange, quantityValue]
  );

  return edit ? (
    <div className="flex gap-x-1">
      <FormFieldNumberInput
        className="w-8"
        edit={edit}
        inputClassName="p-2 rounded w-full"
        layout="tableValue"
        min={1}
        clamp
        onValueChange={handleOnQuantityChange}
        value={quantityValue}
      />
      <FormFieldSelect
        className="flex-1"
        display="label"
        edit={edit}
        inputClassName="px-2 rounded w-full"
        isClearable={false}
        isSearchable
        layout="tableValue"
        onChange={handleOnSelectOption}
        options={simpleOptions}
        placeholder="Select frequency"
        value={selectedOptionValue}
      />
    </div>
  ) : (
    <LimitationString limitationStrParts={limitationStrParts} />
  );
};

const ComplexFrequencyLimitSelect: React.FC<{
  edit?: boolean;
  frequency: Partial<FrequencyLimitVO>;

  onChange: (value?: Partial<FrequencyLimitVO>) => void;
}> = ({ edit, frequency, onChange }) => {
  const durationOptions = useDurations(frequency.durationQuantity);
  const limitationTypeOptions = useLimitationTypeOptions();
  const limitationString = useLimitationAsString({ frequency, isSummarizedLimitationView: false });
  const mouthConstraintOptions = useMouthConstraintOptions(frequency.frequencyQuantity);
  const sharedProps: Partial<FormFieldProps> = {
    edit,
  };
  const inputProps: Partial<FormFieldNumberInputProps> = {
    className: "w-8",
    min: 1,

    ...sharedProps,
  };
  const selectProps: Partial<FormFieldSelectProps<string, SelectOption<string>> & FormFieldProps> = {
    display: "label",
    isClearable: false,
    openMenuOnFocus: true,
    placeholder: "Select",
    ...sharedProps,
  };

  if (!edit) {
    return <div>{limitationString}</div>;
  }

  return (
    <div className="flex flex-row items-center space-x-1">
      <FormFieldNumberInput
        {...inputProps}
        inputClassName="p-2 rounded w-full"
        min={0}
        clamp
        layout="tableValue"
        onValueChange={(value) => {
          onChange({
            ...frequency,
            frequencyQuantity: value,
          });
        }}
        value={frequency.frequencyQuantity}
      />
      <FormFieldSelect
        {...selectProps}
        inputClassName="px-2 rounded w-full"
        layout="tableValue"
        onChange={(option) => {
          const mouthConstraint = (
            option?.value === "VISIT" ? undefined : option?.value
          ) as Partial<FrequencyLimitVO>["mouthConstraint"];

          onChange({ ...frequency, mouthConstraint });
        }}
        options={mouthConstraintOptions}
        value={frequency.mouthConstraint ?? "VISIT"}
      />
      <div>per</div>
      {frequency.durationType !== "LIFETIME" && (
        <FormFieldNumberInput
          {...inputProps}
          clamp
          inputClassName="p-2 rounded w-full"
          min={1}
          layout="tableValue"
          onValueChange={(value) => {
            onChange({
              ...frequency,
              durationQuantity: value,
            });
          }}
          value={frequency.durationQuantity}
        />
      )}

      <FormFieldSelect
        {...selectProps}
        className={cx(frequency.durationType === "LIFETIME" && "flex-1")}
        inputClassName="px-2 rounded w-full"
        layout="tableValue"
        onChange={(option) => {
          const durationType = option?.value as FrequencyLimitVO["durationType"];
          const limitationType = durationType === "LIFETIME" ? "BENEFIT_YEAR" : frequency.limitationType;
          const durationQuantity = durationType === "LIFETIME" ? 1 : frequency.durationQuantity;

          onChange({ ...frequency, durationType, limitationType, durationQuantity });
        }}
        options={durationOptions}
        value={frequency.durationType}
      />
      {frequency.durationType !== "LIFETIME" && (
        <FormFieldSelect
          {...selectProps}
          className="flex-1"
          inputClassName="px-2 rounded w-full"
          layout="tableValue"
          onChange={(option) => {
            const limitationType = option?.value as FrequencyLimitVO["limitationType"];

            onChange({ ...frequency, limitationType });
          }}
          options={limitationTypeOptions}
          placeholder="Select"
          required
          value={frequency.limitationType}
        />
      )}
    </div>
  );
};

type Props = {
  className?: string;
  editable: boolean;
  frequency: Partial<FrequencyLimitVO>;
  frequencyLimitationCategory?: FrequencyLimitationCategoryVO;
  isSummarizedLimitationView?: boolean;
  limitation?: BenefitLimitationVO | undefined;
  onChange: (value?: Partial<FrequencyLimitVO> | null) => void;
};

export const SelectFrequencyLimit: React.FC<Props> = ({
  className,
  editable,
  frequency,
  frequencyLimitationCategory,
  isSummarizedLimitationView = false,
  limitation,
  onChange,
}) => {
  const { isCompatible, selectedValue } = useSimpleFrequencyOptions(frequency, frequencyLimitationCategory);
  const simpleMenuSelected = useBoolean(isCompatible);

  return (
    <div className={cx("flex flex-row items-center w-full justify-between", className)}>
      <div className="flex-1">
        {simpleMenuSelected.isOn ? (
          <SimpleFrequencyLimitSelect
            edit={editable}
            frequency={frequency}
            frequencyLimitationCategory={frequencyLimitationCategory}
            isSummarizedLimitationView={isSummarizedLimitationView}
            limitation={limitation}
            onChange={onChange}
            selectedValue={selectedValue}
          />
        ) : (
          <ComplexFrequencyLimitSelect edit={editable} frequency={frequency} onChange={onChange} />
        )}
      </div>

      {editable && (
        <ButtonIcon
          className={cx("ml-1 w-5 h-5", isCompatible ? "text-primaryTheme" : "text-greyLight")}
          disabled={!isCompatible}
          SvgIcon={FrequencyLimitsIcon}
          onClick={simpleMenuSelected.toggle}
          theme="primary"
        />
      )}
    </div>
  );
};

const getBaseFrequency = (
  frequencySelectedOption: SingleValue<SimpleFrequencyOption> | undefined,
  frequencyLimitationCategory: FrequencyLimitationCategoryVO | undefined
) => {
  return produce(frequencySelectedOption?.frequencyLimit, (draft) => {
    if (
      draft &&
      frequencyLimitationCategory?.defaultFrequencyLimit &&
      defaultFrequencyToOptionValueMap[frequencyLimitationCategory.defaultFrequencyLimit] ===
        frequencySelectedOption?.value
    ) {
      if (frequencyLimitationCategory.defaultFrequencyLimit === "ONE_VISIT_EVERY_X_YEARS_PER_TOOTH") {
        draft.mouthConstraint = "TOOTH";
      } else if (
        frequencyLimitationCategory.defaultFrequencyLimit === "ONE_VISIT_EVERY_X_MONTHS_PER_QUADRANT"
      ) {
        draft.mouthConstraint = "QUADRANT";
      }
    }
  });
};
