import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { UpdateAlertSettingsRequest } from "@libs/api/generated-api";
import { useQueryClient } from "@tanstack/react-query";
import { getQueryKey } from "@libs/utils/queries";
import { Panel } from "@libs/components/UI/Panel";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getAlertSettings } from "api/settings/notes/queries";
import { updateAlertSettings } from "api/settings/notes/mutations";
import { handleError } from "utils/handleError";
import { AppointmentTagsPage } from "components/Settings/Scheduling/Sections/AppointmentTagsPage";

export const AppointmentTagsRoute = () => {
  const { practiceId } = useAccount();
  const queryClient = useQueryClient();
  const [alertSettingsQuery] = useApiQueries([getAlertSettings({ args: { practiceId } })]);

  const [updateAlertSettingsMutation] = useApiMutations([updateAlertSettings]);

  const handleUpdateAlertSettings = (updates: UpdateAlertSettingsRequest) => {
    updateAlertSettingsMutation.mutate(
      {
        practiceId,
        data: updates,
      },
      {
        onError: handleError,
        onSuccess: () => {
          queryClient.invalidateQueries([getQueryKey("practices", "getAppointment"), { practiceId }]);
          queryClient.invalidateQueries([
            getQueryKey("practices", "getNextPatientAppointment"),
            { practiceId },
          ]);
          queryClient.invalidateQueries([getQueryKey("practices", "getDailyHuddle"), { practiceId }]);
          queryClient.invalidateQueries([getQueryKey("practices", "getAppointmentCards"), { practiceId }]);
        },
      }
    );
  };

  return (
    <Panel className="h-full" title="Appointment Tags" includePadding={false}>
      <QueryResult queries={[alertSettingsQuery]}>
        {alertSettingsQuery.data ? (
          <AppointmentTagsPage
            updateInProgress="Appointment auto tags are being updated."
            isSaving={updateAlertSettingsMutation.isLoading}
            isSaved={updateAlertSettingsMutation.isSuccess}
            alertSettings={alertSettingsQuery.data}
            onSave={handleUpdateAlertSettings}
          />
        ) : null}
      </QueryResult>
    </Panel>
  );
};
