import { PatientSummaryVO, PatientVO } from "@libs/api/generated-api";

import { ApiQueryResult } from "@libs/@types/apiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { useCallback, useMemo, useState } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useBoolean } from "@libs/hooks/useBoolean";
import { BaseFormSection } from "components/UI/FormSection";
import { FormFieldPatientsAutoComplete } from "components/UI/FormFieldPatientsAutoComplete";
import { searchForPatients } from "api/patients/queries";
import { CreatePatient } from "components/Patient/CreatePatient";

interface Props {
  selectedPatientId: number | undefined;
  initialPatientQuery: ApiQueryResult<PatientSummaryVO>;
  onSelectedPatient: (item: number | undefined) => void;
}

export const AppointmentFindPatientRow: React.FC<Props> = ({
  selectedPatientId,
  initialPatientQuery,
  onSelectedPatient,
}) => {
  const createPatient = useBoolean(false);
  const [createdPatient, setCreatedPatient] = useState<null | PatientVO>(null);
  const { practiceId } = useAccount();

  const [patientSearch, setPatientSearch] = useState("");

  const [searchPatientsQuery] = useApiQueries([
    searchForPatients({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: 10,
        searchString: patientSearch,
      },
      queryOptions: {
        enabled: false,
      },
    }),
  ]);

  const refetchSearchPatients = searchPatientsQuery.refetch;
  const handleSearchPatients = useCallback(() => {
    refetchSearchPatients();
  }, [refetchSearchPatients]);

  const handleItemChanged = useCallback(
    (value?: number | undefined) => {
      setCreatedPatient(null);
      onSelectedPatient(value);
    },
    [onSelectedPatient]
  );

  const options = useMemo(() => {
    if (createdPatient) {
      const createdPatientSummary: PatientSummaryVO = {
        id: createdPatient.id,
        name: {
          id: createdPatient.id,
          shortDisplayName: createdPatient.personalDetails.displayName ?? "",
          fullDisplayName: createdPatient.personalDetails.fullName ?? "",
          firstName: createdPatient.personalDetails.firstName,
          lastName: createdPatient.personalDetails.lastName,
        },
        dob: createdPatient.personalDetails.dob,
        status: createdPatient.personalDetails.status ?? "INACTIVE",
        completedVisits: createdPatient.personalDetails.completedVisits ?? 0,
        balanceAmount: createdPatient.balanceAmount,
        gender: createdPatient.personalDetails.gender,
        thumbnail: createdPatient.personalDetails.thumbnail,
        contact: createdPatient.contact,
      };

      return [createdPatientSummary];
    }

    if (patientSearch && searchPatientsQuery.data) {
      return searchPatientsQuery.data;
    }

    if (!patientSearch && initialPatientQuery.data) {
      return [initialPatientQuery.data];
    }

    return [];
  }, [searchPatientsQuery.data, initialPatientQuery.data, patientSearch, createdPatient]);

  return (
    <>
      <BaseFormSection className="flex-1">
        <FormFieldPatientsAutoComplete
          label="Patient"
          layout="labelOut"
          required={true}
          autoFocus={!selectedPatientId && createPatient.isOff}
          openMenuOnFocus={createPatient.isOff}
          error={
            searchPatientsQuery.isError || initialPatientQuery.isError
              ? "Sorry there was a problem searching for the patient"
              : ""
          }
          isLoading={searchPatientsQuery.isLoading}
          placeholder="Search Patient"
          value={selectedPatientId}
          options={options}
          onCreatePatient={createPatient.on}
          onSearch={handleSearchPatients}
          onInputChange={setPatientSearch}
          onItemChanged={handleItemChanged}
        />
      </BaseFormSection>
      {createPatient.isOn && (
        <CreatePatient
          onCreated={(patients) => {
            setCreatedPatient(patients[0]);
            onSelectedPatient(patients[0].id);
            createPatient.off();
          }}
          onRequestClose={createPatient.off}
        />
      )}
    </>
  );
};
