import { FC } from "react";
import { ClaimsConfigVO, ProviderVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as BackIcon } from "@libs/assets/icons/left-arrow.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { Flyover } from "components/UI/Flyover";
import { CreateClaimSelectApptContent } from "components/Claims/Claims/CreateClaimSelectApptContent";
import { CreateClaimContent } from "components/Claims/Claims/CreateClaimContent";
import { getPatientInsurancesQuery } from "api/patientInsurance/queries";
import { CreateClaimContext, AppointmentSummary } from "components/Claims/Claims/createClaimTypes";

interface Props {
  billingProvidersQuery: ApiQueryResult<ProviderVO[]>;
  claimsConfigQuery: ApiQueryResult<ClaimsConfigVO>;
  context: CreateClaimContext;
  onClose: Func;
  onNavigateCreateClaimFlyover: ({
    context,
    selectedAppt,
  }: {
    context: CreateClaimContext;
    selectedAppt?: AppointmentSummary;
  }) => void;
  patientId: number;
  selectedAppointment?: AppointmentSummary;
}

export const CreateClaimFlyover: FC<Props> = ({
  billingProvidersQuery,
  claimsConfigQuery,
  context,
  onClose,
  onNavigateCreateClaimFlyover,
  patientId,
  selectedAppointment,
}) => {
  const { practiceId } = useAccount();
  const [patientInsurancesQuery] = useApiQueries([
    getPatientInsurancesQuery({
      args: {
        insuranceState: ["ACTIVE", "INACTIVE", "ARCHIVED"],
        patientId,
        practiceId,
      },
    }),
  ]);

  return (
    <Flyover
      title={
        context === CreateClaimContext.SELECT ? (
          "Select Appointment"
        ) : (
          <div className="flex items-center gap-x-2">
            <ButtonIcon
              className="p-1"
              onClick={() =>
                onNavigateCreateClaimFlyover({
                  context: CreateClaimContext.SELECT,
                  selectedAppt: undefined,
                })
              }
              SvgIcon={BackIcon}
              theme="primary"
            />
            Create Claim
          </div>
        )
      }
      onClose={onClose}
      size="md"
    >
      {context === CreateClaimContext.SELECT ? (
        <CreateClaimSelectApptContent
          onNavigateToCreateClaim={(selectedAppt: AppointmentSummary) =>
            onNavigateCreateClaimFlyover({ context: CreateClaimContext.CREATE, selectedAppt })
          }
          patientId={patientId}
          patientInsurancesQuery={patientInsurancesQuery}
        />
      ) : selectedAppointment ? (
        <CreateClaimContent
          appointment={selectedAppointment}
          billingProvidersQuery={billingProvidersQuery}
          claimsConfigQuery={claimsConfigQuery}
          onCancel={onClose}
        />
      ) : null}
    </Flyover>
  );
};
