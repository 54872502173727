import { FormEventHandler, useCallback, useMemo, useState } from "react";
import { CurrencyInputProps } from "react-currency-input-field";
import { fromUnixTime, getUnixTime } from "date-fns";
import { ReactDatePickerProps } from "react-datepicker";
import { produce } from "immer";
import Skeleton from "react-loading-skeleton";
import { InvoiceVO, PaymentCorrectionRequest, PaymentVO, WalletVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { centsToDollars, dollarsToCents, formatCurrency } from "@libs/utils/currency";
import { nowInTimezone } from "@libs/utils/date";
import { isOneOf } from "@libs/utils/isOneOf";
import { useValidation } from "@libs/hooks/useValidation";
import { required } from "@libs/utils/validators";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { MutationResult } from "@libs/@types/apiMutations";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useSyncOnce } from "@libs/hooks/useSyncOnce";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { ReactComponent as WalletIcon } from "@libs/assets/icons/wallet.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { Modal } from "@libs/components/UI/Modal";
import { Form } from "@libs/components/UI/Form";
import { ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";
import { getInvoiceByUuid, getPayment, getWallets } from "api/billing/queries";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import { getInvoiceAmountDue } from "components/PatientProfile/Billing/invoiceUtils";

import { FormFieldCurrencyInput } from "components/UI/FormFieldCurrencyInput";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { createPaymentCorrectionRecord } from "api/billing/mutations";
import { handleError } from "utils/handleError";
import { BalanceBox } from "components/PatientProfile/Billing/BalanceBox";
import {
  getFallbackPatientWallet,
  getPaymentMethodToLabel,
  isPaymentEditableChecker,
} from "components/PatientProfile/Billing/billingUtils";
import { Note } from "components/PatientProfile/Billing/FormComponents";
import { RoleGuardClick } from "components/Main/RoleGuard";
import { RoleConditionHandler } from "components/Roles/roleUtils";

interface Props {
  patientId: number;
  paymentUuid: string;
  invoiceUuid: string;
  onSave: Func;
  onClose: Func;
}

const formSchema = {
  currencyAmount: { amount: { $validations: [{ $v: required, $error: "An amount is required." }] } },
  note: { $validations: [{ $v: required, $error: "A note is required." }] },
};

export const useEditInvoicePaymentModal = ({
  patientId,
  paymentUuid,
  invoiceUuid,
  invoiceData,
  paymentData,
  walletData,
  practiceId,
  updatePayment,
  ...rest
}: Props & {
  paymentData?: PaymentVO;
  invoiceData?: InvoiceVO;
  walletData?: WalletVO[];
  practiceId: number;
  updatePayment: MutationResult<typeof createPaymentCorrectionRecord>["mutate"];
}) => {
  const invoiceAmountDue = useMemo(() => getInvoiceAmountDue(invoiceData), [invoiceData]);
  const wallet = getFallbackPatientWallet(patientId, practiceId, walletData);
  const [newAmount, setNewAmount] = useState("");
  const [paymentDraft, setPaymentDraft] = useState<PaymentCorrectionRequest>(() => ({
    paymentUuid,
    note: "",
  }));

  const validation = useValidation(paymentDraft, formSchema);

  const handleAmountChange: CurrencyInputProps["onValueChange"] = (value) => {
    if (!value) {
      setNewAmount("");
      setPaymentDraft((last) =>
        produce(last, (draft) => {
          delete draft.currencyAmount;
        })
      );

      return;
    }

    setNewAmount(value);
    setPaymentDraft((last) =>
      produce(last, (draft) => {
        draft.currencyAmount = { currency: "USD", amount: dollarsToCents(Number(value)) };
      })
    );
  };

  // eslint-disable-next-line @typescript-eslint/no-magic-numbers
  const handleDateChange = (date: Parameters<ReactDatePickerProps["onChange"]>[0]) => {
    date &&
      setPaymentDraft((last) =>
        produce(last, (draft) => {
          draft.paymentCreatedAt = getUnixTime(date);
        })
      );
  };

  const handleCheckPayloadChange = (checkPayload: PaymentCorrectionRequest["checkPayload"]) => {
    setPaymentDraft((last) =>
      produce(last, (draft) => {
        draft.checkPayload = checkPayload;
      })
    );
  };

  const handleEftPayloadChange = (eftPayload: PaymentCorrectionRequest["eftPayload"]) => {
    setPaymentDraft((last) =>
      produce(last, (draft) => {
        draft.eftPayload = eftPayload;
      })
    );
  };

  const handleExternalPosPayloadChange = (
    externalPosPayload: PaymentCorrectionRequest["externalPosPayload"]
  ) => {
    setPaymentDraft((last) =>
      produce(last, (draft) => {
        draft.externalPosPayload = externalPosPayload;
      })
    );
  };

  const handleNoteChange = (note: NonNullable<PaymentCorrectionRequest["note"]>) => {
    setPaymentDraft((last) =>
      produce(last, (draft) => {
        draft.note = note;
      })
    );
  };

  useSyncOnce((data) => {
    const date = fromUnixTime(data.paymentCreatedAt);

    handleDateChange(date);
    handleAmountChange(String(centsToDollars(data.currencyAmount.amount)));
    data.note && handleNoteChange(data.note);

    data.method === "CHECK" && data.checkPayload && handleCheckPayloadChange(data.checkPayload);
    data.method === "EFT" && data.eftPayload && handleEftPayloadChange(data.eftPayload);
    data.method === "EXTERNAL_POS" &&
      data.externalPosPayload &&
      handleExternalPosPayloadChange(data.externalPosPayload);
  }, paymentData);

  const handleSave: FormEventHandler = (event) => {
    event.preventDefault();

    const result = validation.validate();

    if (!result.$isValid) {
      return;
    }

    updatePayment(
      { practiceId, invoiceUuid, data: paymentDraft, patientId },
      { onSuccess: rest.onSave, onError: handleError }
    );
  };

  return {
    ...rest,
    paymentData,
    invoiceData,
    invoiceAmountDue,
    newAmount,
    paymentDraft,
    walletAmount: wallet.balance,
    handleAmountChange,
    handleDateChange,
    handleSave,
    handleCheckPayloadChange,
    handleEftPayloadChange,
    handleExternalPosPayloadChange,
    handleNoteChange,
    validation,
  };
};

const FORM_ID = "edit-payment";

// eslint-disable-next-line complexity
export const EditInvoicePaymentModal: React.FC<Props> = (props) => {
  const practice = useCurrentPractice();
  const [{ data: paymentData }, { data: invoiceData, isLoading: isInvoiceLoading }, { data: walletData }] =
    useApiQueries([
      getPayment({ args: { paymentUuid: props.paymentUuid, practiceId: practice.id } }),
      getInvoiceByUuid({
        args: {
          invoiceUuid: props.invoiceUuid,
          practiceId: practice.id,
          patientId: props.patientId,
          query: { includePayerInfo: true },
        },
      }),
      getWallets({ args: { practiceId: practice.id, patientId: props.patientId } }),
    ]);
  const [{ mutate: updatePayment, isLoading: isUpdatingPayment }] = useApiMutations([
    createPaymentCorrectionRecord,
  ]);

  const {
    onClose,
    invoiceAmountDue,
    newAmount,
    paymentDraft,
    walletAmount,
    handleAmountChange,
    handleDateChange,
    handleSave,
    handleCheckPayloadChange,
    handleEftPayloadChange,
    handleExternalPosPayloadChange,
    handleNoteChange,
    validation,
  } = useEditInvoicePaymentModal({
    ...props,
    practiceId: practice.id,
    paymentData,
    invoiceData,
    walletData,
    updatePayment,
  });

  const paymentEditableChecker: RoleConditionHandler = useCallback(
    (conditions) => isPaymentEditableChecker(paymentData, conditions),
    [paymentData]
  );

  return (
    <Modal size="sm" title="Edit Payment" onClose={onClose}>
      <ModalContent padding="lg">
        <Form id={FORM_ID} className="flex flex-col gap-y-6" onSubmit={handleSave}>
          <div
            className={`
              flex
              bg-greyLightest
              p-2
              items-center
              font-sansSemiBold
              justify-between
              text-xs
            `}
          >
            <div className="flex gap-x-5">
              Invoice #{isInvoiceLoading ? <Skeleton className="w-24" /> : invoiceData?.invoiceNumber}
              <span>|</span>
              {isInvoiceLoading ? (
                <Skeleton className="w-24" />
              ) : (
                <DateWithTooltip format="P" date={invoiceData?.createdAt ?? 0} dateAsSeconds={true} />
              )}
              <span>|</span>
              {isInvoiceLoading ? <Skeleton className="w-24" /> : invoiceData?.payerName}
            </div>
            <span>
              {invoiceData?.state === "PARTIALLY_PAID" && <>(Partial)</>}{" "}
              <span className="text-base">{formatCurrency(invoiceAmountDue)}</span>
            </span>
          </div>
          <div className="flex gap-x-6 font-sansSemiBold">
            <div>
              <div className="text-xs">Original Amount Collected</div>
              <div className="text-sm">{formatCurrency(paymentData?.currencyAmount.amount ?? 0)}</div>
            </div>
            <VerticalDivider size="full" />
            <div>
              <div className="text-xs">Payment Method</div>
              <div className="text-sm">{paymentData && getPaymentMethodToLabel(paymentData)}</div>
            </div>
            {paymentData?.method === "WALLET" && (
              <>
                <VerticalDivider size="full" />
                <BalanceBox
                  leftIcon={<WalletIcon className="h-6 w-6" />}
                  amount={formatCurrency(walletAmount)}
                  label="Patient Wallet Balance"
                  theme="SMALL"
                />
              </>
            )}
          </div>
          <div className="flex flex-col gap-y-3">
            <div className="text-xs font-sansSemiBold">Edit Payment Details</div>
            <div className="grid grid-cols-3 gap-3">
              <FormFieldCurrencyInput
                label="New Amount"
                layout="labelIn"
                required={true}
                error={validation.result.currencyAmount.amount.$error}
                value={newAmount}
                intlConfig={{ locale: "en-US", currency: invoiceData?.currency ?? "USD" }}
                onValueChange={handleAmountChange}
              />
              <FormFieldSelectMenusDatepicker
                label="Payment Date"
                layout="labelIn"
                className={
                  paymentData
                    ? cx(isOneOf(paymentData.method, ["CHECK", "EXTERNAL_POS", "EFT"]) && "col-start-1")
                    : undefined
                }
                required={true}
                selected={fromUnixTime(paymentDraft.paymentCreatedAt ?? 0)}
                maxDate={nowInTimezone(practice.timezoneId)}
                onChange={handleDateChange}
              />
              {paymentData?.method === "EXTERNAL_POS" ? (
                <>
                  <FormFieldInput
                    label="Name on Card"
                    layout="labelIn"
                    placeholder="e.g. John Doe"
                    value={paymentDraft.externalPosPayload?.nameOnCard}
                    onChange={(e) =>
                      handleExternalPosPayloadChange({
                        nameOnCard: e.target.value,
                        cardLastFour: paymentDraft.externalPosPayload?.cardLastFour ?? "",
                      })
                    }
                  />
                  <FormFieldInput
                    label="Card Number (last 4 digits)"
                    layout="labelIn"
                    placeholder="XXXX"
                    value={paymentDraft.externalPosPayload?.cardLastFour}
                    onChange={(e) =>
                      handleExternalPosPayloadChange({
                        cardLastFour: e.target.value,
                        nameOnCard: paymentDraft.externalPosPayload?.nameOnCard ?? "",
                      })
                    }
                  />
                </>
              ) : paymentData?.method === "CHECK" ? (
                <>
                  <FormFieldInput
                    label="Check Number"
                    layout="labelIn"
                    placeholder="XXXXXXXXXXXX"
                    value={paymentDraft.checkPayload?.checkNumber}
                    onChange={(e) =>
                      handleCheckPayloadChange({
                        checkNumber: e.target.value,
                        bankName: paymentDraft.checkPayload?.bankName ?? "",
                      })
                    }
                  />
                  <FormFieldInput
                    label="Bank Name"
                    layout="labelIn"
                    placeholder="e.g. Bank of America"
                    value={paymentDraft.checkPayload?.bankName}
                    onChange={(e) =>
                      handleCheckPayloadChange({
                        bankName: e.target.value,
                        checkNumber: paymentDraft.checkPayload?.checkNumber ?? "",
                      })
                    }
                  />
                </>
              ) : paymentData?.method === "EFT" ? (
                <>
                  <FormFieldInput
                    label="EFT Number"
                    layout="labelIn"
                    placeholder="XXXXXXXXXXXX"
                    value={paymentDraft.eftPayload?.eftNumber}
                    onChange={(e) =>
                      handleEftPayloadChange({
                        eftNumber: e.target.value,
                        bankName: paymentDraft.eftPayload?.bankName ?? "",
                      })
                    }
                  />
                  <FormFieldInput
                    label="Bank Name"
                    layout="labelIn"
                    placeholder="e.g. Bank of America"
                    value={paymentDraft.eftPayload?.bankName}
                    onChange={(e) =>
                      handleEftPayloadChange({
                        eftNumber: paymentDraft.eftPayload?.eftNumber ?? "",
                        bankName: e.target.value,
                      })
                    }
                  />
                </>
              ) : null}
            </div>
          </div>
          <Note
            value={paymentDraft.note}
            error={validation.result.note.$error}
            required
            onChange={(e) => handleNoteChange(e.target.value)}
          />
        </Form>
      </ModalContent>
      <ModalFooter>
        <RoleGuardClick domain="BILLING" action="EDIT_PAYMENT" onRoleCondition={paymentEditableChecker}>
          <AsyncButton isLoading={isUpdatingPayment} className="min-w-button" type="submit" form={FORM_ID}>
            Save
          </AsyncButton>
        </RoleGuardClick>
      </ModalFooter>
    </Modal>
  );
};
