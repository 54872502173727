import { ComponentProps, FC, useCallback } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { DocumentVO, FolderVO } from "@libs/api/generated-api";
import { UserDocuments } from "components/UserDocuments/UserDocuments";
import { useQueryParams } from "hooks/useQueryParams";
import { paths } from "utils/routing/paths";
import { usePathParams } from "hooks/usePathParams";
import { EmployeeAppHistoryProvider } from "components/Employee/EmployeeLinksContext";

export const EmployeeDocuments: FC = () => {
  const { id: employeeId } = usePathParams("employee");
  const navigate = useNavigate();

  const handleDocumentSelectionChange = useCallback(
    // eslint-disable-next-line @typescript-eslint/no-magic-numbers
    (selection: Parameters<ComponentProps<typeof UserDocuments>["onSelectionChange"]>[0]) => {
      updateEmployeeDocumentsSearchQuery({ navigate, employeeId, ...selection });
    },
    [navigate, employeeId]
  );

  const { query } = useQueryParams("employeeDocuments");

  return (
    <EmployeeAppHistoryProvider name="documents">
      <UserDocuments
        userId={employeeId}
        selectedDocId={query.docId}
        selectedFolderId={query.folderId}
        onSelectionChange={handleDocumentSelectionChange}
      />
    </EmployeeAppHistoryProvider>
  );
};

const updateEmployeeDocumentsSearchQuery = ({
  navigate,
  employeeId,
  folderId,
  docId,
}: {
  navigate: NavigateFunction;
  employeeId: number;
  folderId?: FolderVO["id"];
  docId?: DocumentVO["id"];
}) => {
  const urlQuery = {
    ...(docId && { docId }),
    ...(folderId && { folderId }),
  };

  navigate(paths.employeeDocuments({ id: employeeId }, urlQuery), { replace: true });
};
