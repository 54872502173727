import BaseOTPInput from "otp-input-react";
import { cx } from "@libs/utils/cx";

type Props = {
  autoFocus?: boolean;
  disabled?: boolean;
  className?: string;
  inputClassName?: string;
  inputStyles?: React.CSSProperties;
  style?: React.CSSProperties;
  onChange: (otp: string) => void;
  otpType: "any" | "number" | "alpha" | "alphanumeric";
  OTPLength: number;
  secure?: boolean;
  error?: boolean;
  placeholder?: string;
  value: string;
};

const cxStyles = {
  code: "w-10 box-border rounded-[5px]",
};

export const OTPInput: React.FC<Props> = ({ error, className, ...rest }) => {
  return (
    <BaseOTPInput
      disabled={false}
      className={cx("flex gap-2.5", className)}
      inputClassName={cx("border", error ? "border-red" : "border-slate-300", cxStyles.code)}
      // Styles set in `inputStyles` take precedence over
      // `inputClassName` and we need to override the built-in
      // defaults which forces us to style here and in the CSS class.
      inputStyles={{ marginRight: 0, height: "40px" }}
      {...rest}
    />
  );
};
