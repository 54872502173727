import { FC, useState, useMemo, useCallback } from "react";
import { FileRejection } from "react-dropzone";
import { produce } from "immer";

import { FormTaskVO, DocumentVO } from "@libs/api/generated-api";
import { asyncNoop } from "@libs/utils/noop";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { getTempId } from "@libs/utils/tempId";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { Modal } from "@libs/components/UI/Modal";
import { DocumentUploaderRequest, useDocumentUploader } from "components/UI/DocumentUploadManager";
import { AttachFilesModalContent } from "components/UI/AttachModalContent/AttachFilesModalContent";
import { AttachScanModalContent } from "components/UI/AttachModalContent/AttachScanModalContent";
import { AttachDocumentModalContent } from "components/UI/AttachModalContent/AttachDocumentModalContent";
import { UploadedModalContent } from "components/UI/AttachModalContent/UploadedModalContent";
import { useAttachModalContent } from "components/UI/AttachModalContent/utils";
import { AttachFormFileRow } from "components/PatientProfile/Forms/AttachFormFileRow";

import { getFolders } from "api/documents/queries";
import { submitFormTask } from "api/formTasks/mutations";

import { handleError } from "utils/handleError";

export interface StagedFormFile extends MakeKeysNullable<DocumentUploaderRequest, "fileData"> {
  stagedId: string;
  formTaskUuid?: string;
  documentId?: number;
}

interface Props {
  userId: number;
  formTasks: FormTaskVO[];
  onUploadSuccess: Func;
  onRequestClose: Func;
}

export const AttachFormFilesModal: FC<Props> = ({ userId, formTasks, onUploadSuccess, onRequestClose }) => {
  const {
    modalProps,
    modalContent,
    handleRequestUpload,
    handleRequestScanDocument,
    handleRequestSelectDocument,
    showUploadedModalContent,
  } = useAttachModalContent({ title: "Attach Forms", onRequestClose });

  const [stagedFiles, setStagedFiles] = useState<StagedFormFile[]>([]);
  const [isUploading, setIsUploading] = useState(false);

  const { practiceId } = useAccount();
  const [foldersQuery] = useApiQueries([getFolders({ args: { practiceId, userId } })]);
  const [{ mutateAsync: submitFormTaskMutateAsync }] = useApiMutations([submitFormTask]);
  const { documentUploader } = useDocumentUploader();

  const canSelectMultiple = formTasks.length > 1;
  const canUploadFiles = useMemo(
    () => stagedFiles.length > 0 && stagedFiles.every((file) => file.formTaskUuid),
    [stagedFiles]
  );

  const formOptions = useMemo(() => {
    const fileFormTaskUuids = new Set(stagedFiles.map((file) => file.formTaskUuid));

    return formTasks.map((ft) => ({
      label: ft.form.title,
      value: ft.uuid,
      isDisabled: fileFormTaskUuids.has(ft.uuid),
    }));
  }, [formTasks, stagedFiles]);

  const initialFormTaskUuid = formTasks.length === 1 ? formTasks[0].uuid : undefined;

  const consentFolderId = useMemo(
    () => foldersQuery.data?.find((folder) => folder.name === "Consent Forms")?.id,
    [foldersQuery.data]
  );

  const handleStageFiles = useCallback(
    (files: File[]) => {
      if (consentFolderId) {
        setStagedFiles((last) => [
          ...last,
          ...files.map((file) => ({
            stagedId: getTempId(),
            formTaskUuid: initialFormTaskUuid,
            practiceId,
            userId,
            folderId: consentFolderId,
            filename: file.name,
            fileData: file,
          })),
        ]);
      }
    },
    [initialFormTaskUuid, practiceId, userId, consentFolderId]
  );

  const handleStageDocuments = useCallback(
    (documents: DocumentVO[]) => {
      if (consentFolderId) {
        setStagedFiles((last) => [
          ...last,
          ...documents.map((document) => ({
            stagedId: getTempId(),
            formTaskUuid: initialFormTaskUuid,
            practiceId,
            userId,
            folderId: consentFolderId,
            documentId: document.id,
            filename: document.name,
          })),
        ]);
      }
    },
    [initialFormTaskUuid, practiceId, userId, consentFolderId]
  );

  const handleDropFiles = useCallback(
    (acceptedFiles: File[], rejectedFiles: FileRejection[]) => {
      handleStageFiles(acceptedFiles);
      rejectedFiles.forEach(handleError);
    },
    [handleStageFiles]
  );

  const handleUpdateFile = (stagedId: string, updatedFile: Partial<StagedFormFile>) => {
    setStagedFiles((last) =>
      produce(last, (draft) => {
        const stagedFileIndex = draft.findIndex((file) => file.stagedId === stagedId);

        draft[stagedFileIndex] = { ...draft[stagedFileIndex], ...updatedFile };
      })
    );
  };

  const handleDeleteFile = (stagedId: string) => {
    setStagedFiles((last) =>
      produce(last, (draft) => {
        const stagedFileIndex = draft.findIndex((file) => file.stagedId === stagedId);

        draft.splice(stagedFileIndex, 1);
      })
    );
  };

  const handleUploadFiles = useCallback(async () => {
    setIsUploading(true);

    try {
      await Promise.all(
        stagedFiles.map(async (file) => {
          const { formTaskUuid, documentId } = file;
          const documentUploaderRequest = produce(
            file as MakeKeysNullable<StagedFormFile, "stagedId">,
            (draft) => {
              delete draft.stagedId;
              delete draft.formTaskUuid;
              delete draft.documentId;
            }
          ) as DocumentUploaderRequest;

          if (formTaskUuid) {
            // When attaching files to form tasks, they must uploaded first in
            // order to generate an associated documentId
            if (!documentId) {
              const document = await documentUploader(documentUploaderRequest);
              const data = { documentId: document.id };

              return submitFormTaskMutateAsync({ practiceId, formTaskUuid, data });
            }

            // When attaching documents, we use their corresponding documentId
            return submitFormTaskMutateAsync({ practiceId, formTaskUuid, data: { documentId } });
          }

          return asyncNoop;
        })
      );

      onUploadSuccess();
      showUploadedModalContent();
    } catch (error) {
      handleError(error);
    } finally {
      setIsUploading(false);
    }
  }, [
    stagedFiles,
    practiceId,
    documentUploader,
    submitFormTaskMutateAsync,
    onUploadSuccess,
    showUploadedModalContent,
  ]);

  return (
    <Modal {...modalProps}>
      <QueryResult
        queries={[foldersQuery]}
        loading={
          <div className="min-h-64">
            <LoadingContent />
          </div>
        }
      >
        {foldersQuery.data ? (
          modalContent === "upload" ? (
            <AttachFilesModalContent
              canSelectMultiple={canSelectMultiple}
              canUploadFiles={canUploadFiles}
              isUploading={isUploading}
              onDropFiles={handleDropFiles}
              onUploadFiles={handleUploadFiles}
              onRequestScanDocument={handleRequestScanDocument}
              onRequestSelectDocument={handleRequestSelectDocument}
              onRequestClose={onRequestClose}
            >
              {stagedFiles.length > 0 ? (
                <div className="w-full divide-y divide-dashed divide-greyLighter">
                  {stagedFiles.map((file) => (
                    <AttachFormFileRow
                      key={file.stagedId}
                      file={file}
                      formOptions={formOptions}
                      onUpdateFile={handleUpdateFile}
                      onDeleteFile={handleDeleteFile}
                    />
                  ))}
                </div>
              ) : null}
            </AttachFilesModalContent>
          ) : modalContent === "scan" ? (
            <AttachScanModalContent
              onScanFiles={(files) => handleStageFiles(files)}
              onRequestClose={handleRequestUpload}
              scanAs="PDF"
            />
          ) : modalContent === "document" ? (
            <AttachDocumentModalContent
              userId={userId}
              folders={foldersQuery.data}
              canSelectMultiple={canSelectMultiple}
              onSelectDocuments={handleStageDocuments}
              onRequestClose={handleRequestUpload}
            />
          ) : (
            <UploadedModalContent onRequestClose={onRequestClose} />
          )
        ) : null}
      </QueryResult>
    </Modal>
  );
};
