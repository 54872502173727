import React from "react";
import { PracticeVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { handleError } from "utils/handleError";
import { getPracticeDataQuery, getPracticeProfileImage } from "api/practice/queries";
import { deleteProfileImage1, updatePractice, uploadProfileImage1 } from "api/practice/mutations";
import { BusinessInformationForm } from "./BusinessInformationForm";
import { PracticeLogoModal } from "./PracticeLogoModal";

export const BusinessInformation: React.FC<{
  isEditing: boolean;
  onDoneEditing: () => void;
}> = ({ isEditing, onDoneEditing }) => {
  const { practiceId } = useAccount();
  const imageModal = useBoolean(false);

  const [practiceQuery, practiceImageQuery] = useApiQueries([
    getPracticeDataQuery({ args: { practiceId } }),
    getPracticeProfileImage({ args: { practiceId } }),
  ]);

  const [updatePracticeMutation, deletePracticeProfileImage, uploadPracticeProfileImage] = useApiMutations([
    updatePractice,
    deleteProfileImage1,
    uploadProfileImage1,
  ]);

  const handleDeleteImage = () => {
    deletePracticeProfileImage.mutate(
      { practiceId },
      {
        onError: handleError,
      }
    );
  };

  const handleUploadImage = (file: File) => {
    uploadPracticeProfileImage.mutate(
      { practiceId, image: file },
      { onSuccess: imageModal.off, onError: handleError }
    );
  };

  const handleSave = (practice: PracticeVO) => {
    updatePracticeMutation.mutate(
      { data: practice, practiceId },
      {
        onSuccess: onDoneEditing,
        onError: handleError,
      }
    );
  };

  return (
    <>
      <QueryResult queries={[practiceQuery, practiceImageQuery]}>
        {practiceQuery.data && (
          <BusinessInformationForm
            onCancel={onDoneEditing}
            onDeleteImage={handleDeleteImage}
            onUploadImage={imageModal.on}
            onSave={handleSave}
            isSaving={updatePracticeMutation.isLoading}
            isEditing={isEditing}
            practice={practiceQuery.data}
            practiceImage={practiceImageQuery.data}
          />
        )}
      </QueryResult>
      {imageModal.isOn ? (
        <PracticeLogoModal
          onClose={imageModal.off}
          onUploadImage={handleUploadImage}
          isUploading={uploadPracticeProfileImage.isLoading}
        />
      ) : null}
    </>
  );
};
