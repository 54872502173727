import React, { useMemo } from "react";
import { CollectedPaymentByProviderComboEntryVO, NameVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useAccount } from "@libs/contexts/AccountContext";
import { keys } from "@libs/utils/object";
import { FooterCell } from "components/Dashboard/Tables/FooterCell";
import { useQueryParams } from "hooks/useQueryParams";
import { DashboardTableTabs } from "components/Dashboard/Tables/DashboardTableTabs";
import { useTableTabProps } from "components/Dashboard/PaymentCollected/hooks/useTableTabProps";
import { getCollectedPaymentByProviderComboQuery } from "api/reporting/queries";
import { COLLECTED_BY_ASSOCIATED_PROVIDER_HEADERS } from "components/Dashboard/PaymentCollected/constants";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";
import { PaymentCollectedQuery } from "utils/routing/dashboard";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { LoadingCells } from "components/Dashboard/Tables/LoadingCells";
import { DashboardTable } from "components/Dashboard/Tables/DashboardTable";
import { ByAssociatedProviderTableSection } from "components/Dashboard/PaymentCollected/ByAssociatedProviderTableSection";
import { useDownloadCollectedByAssociatedProviderCSV } from "components/Dashboard/PaymentCollected/hooks/useDownloadCollectedByAssociatedProviderCSV";

const sortProvidersAlphabetically = (a?: NameVO, b?: NameVO) => {
  if (!a && !b) {
    return 0;
  }

  if (!b) {
    return -1;
  } else if (!a) {
    return 1;
  }

  return a.lastName.localeCompare(b.lastName);
};
const useAssociatedProviderComboQuery = () => {
  const { practiceId } = useAccount();

  const { query } = useQueryParams("dashboardPracticeProduction");
  const dateWindow = useTimeSeriesPageSelections(query);
  const { selectedTimeSegmentParams } = dateWindow;

  const [collectedPaymentByProviderComboQuery] = useApiQueries([
    getCollectedPaymentByProviderComboQuery({
      args: {
        practiceId,
        data: selectedTimeSegmentParams,
      },
    }),
  ]);
  const isLoading = collectedPaymentByProviderComboQuery.isLoading;
  const providerCombos = collectedPaymentByProviderComboQuery.data?.entries;

  const { providerSections, unassigned } = useMemo(() => {
    const combos = providerCombos ?? [];
    const map: { [id: string]: CollectedPaymentByProviderComboEntryVO[] } = {};
    const unassignedProviders: CollectedPaymentByProviderComboEntryVO[] = [];

    for (const providerCombo of combos) {
      const associatedProvider = providerCombo.associatedProvider;

      if (!associatedProvider?.id) {
        unassignedProviders.push(providerCombo);
        continue;
      } else if (!(associatedProvider.id in map)) {
        map[associatedProvider.id] = [];
      }

      map[associatedProvider.id].push(providerCombo);
    }

    return {
      providerSections: keys(map)
        .map((key) => {
          const [firstCombo] = map[key];

          return {
            associatedProvider: firstCombo.associatedProvider,
            subtotals: map[key],
            total: map[key].reduce((acc, combo) => acc + combo.totalPaymentAmount, 0),
          };
        })
        .sort((a, b) => {
          const [comboA] = a.subtotals;
          const [comboB] = b.subtotals;

          return sortProvidersAlphabetically(comboA.associatedProvider, comboB.associatedProvider);
        }),
      unassigned: {
        subtotals: unassignedProviders.sort((a, b) => {
          return sortProvidersAlphabetically(a.associatedProvider, b.associatedProvider);
        }),
        total: unassignedProviders.reduce((acc, combo) => acc + combo.totalPaymentAmount, 0),
      },
    };
  }, [providerCombos]);

  return {
    isLoading,
    providerSections,
    unassigned,
    error: collectedPaymentByProviderComboQuery.error,
    dateWindow,
    totals: collectedPaymentByProviderComboQuery.data?.total,
    isEmpty: !isLoading && providerCombos?.length === 0,
  };
};

export const ByAssociatedProviderTableRoute: React.FC = () => {
  const { updateQuery, query } = useQueryParams("dashboardPaymentCollected");

  const handleRouteStateChange = React.useCallback(
    (updates: Partial<PaymentCollectedQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );
  const { isLoading, providerSections, unassigned, totals, isEmpty, error, dateWindow } =
    useAssociatedProviderComboQuery();
  const { selectedTimeSegment } = dateWindow;
  const tableTabProps = useTableTabProps();
  const { downloadCSV } = useDownloadCollectedByAssociatedProviderCSV({
    providerSections,
    unassigned,
    totals,
    selectedTimeSegment,
  });

  return (
    <>
      <DashboardTableTabs
        {...tableTabProps}
        onUpdateParams={handleRouteStateChange}
        query={query}
        includePatientSearch={false}
        onClickDownload={downloadCSV}
        id="practice-production-by-hygienist"
      />
      <DashboardTable
        id="productionByProviderComboTable"
        isError={Boolean(error)}
        isEmpty={isEmpty}
        isLoading={isLoading}
        columnWidths={COLLECTED_BY_ASSOCIATED_PROVIDER_HEADERS.map(({ width }) => width)}
        headerRow={<DashboardHeaderRow headers={COLLECTED_BY_ASSOCIATED_PROVIDER_HEADERS} />}
        footerRow={
          <>
            <FooterCell />
            <FooterCell className="col-span-2">Totals</FooterCell>
            <FooterCell loading={isLoading} align="right">
              {totals && formatCurrency(totals.totalInsurancePaymentAmount)}
            </FooterCell>
            <FooterCell loading={isLoading} align="right">
              {totals && formatCurrency(totals.totalPatientPaymentAmount)}
            </FooterCell>
            <FooterCell loading={isLoading} align="right">
              {totals && formatCurrency(totals.totalPaymentAmount)}
            </FooterCell>
          </>
        }
      >
        {isLoading ? (
          <LoadingCells columnCount={COLLECTED_BY_ASSOCIATED_PROVIDER_HEADERS.length} />
        ) : isEmpty ? null : (
          <>
            {providerSections.map((combos) => {
              if (!combos.associatedProvider) {
                return null;
              }

              return (
                <ByAssociatedProviderTableSection
                  key={combos.associatedProvider.id}
                  providers={combos.subtotals}
                  sectionName={combos.associatedProvider.fullDisplayName}
                  total={combos.total}
                />
              );
            })}
            <ByAssociatedProviderTableSection
              providers={unassigned.subtotals}
              sectionName="No Associated Provider"
              total={unassigned.total}
            />
          </>
        )}
      </DashboardTable>
    </>
  );
};
