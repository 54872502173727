import React, { MouseEventHandler, useCallback } from "react";
import { useNavigate } from "react-router-dom";
import { DraftEobPaymentSummaryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatISODate } from "@libs/utils/date";
import { formatCurrency } from "@libs/utils/currency";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { ReactComponent as ContinueIcon } from "@libs/assets/icons/continue.svg";
import { ReactComponent as RefreshIcon } from "@libs/assets/icons/refresh.svg";
import { ReactComponent as WarningIcon } from "@libs/assets/icons/warning.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { ButtonCell, Cell, Row } from "@libs/components/UI/GridTableComponents";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { deleteDraftEobPayment } from "api/claim/mutations";
import { handleError } from "utils/handleError";
import { cxStyles } from "components/Claims/styles";
import { TWO_CLICKS } from "utils/handleDoubleClick";
import { paths } from "utils/routing/paths";
import { useItemModal } from "hooks/useItemModal";

interface Props {
  currentUrl: string;
  draftEobPayment: DraftEobPaymentSummaryVO;
}

export const EobTableRow: React.FC<Props> = ({ currentUrl, draftEobPayment }) => {
  const navigate = useNavigate();
  const { practiceId } = useAccount();

  const handleContinueClick = useCallback(() => {
    navigate(paths.eob({ draftEobPaymentUuid: draftEobPayment.uuid }, { from: currentUrl }));
  }, [draftEobPayment.uuid, navigate, currentUrl]);

  const handleRowClick: MouseEventHandler = useCallback(
    (event) => {
      switch (event.detail) {
        case TWO_CLICKS: {
          handleContinueClick();

          return;
        }
        default: {
          return;
        }
      }
    },
    [handleContinueClick]
  );

  const deleteDraftEobPaymentModal = useItemModal<string>(null);
  const [deleteDraftEobPaymentMutation] = useApiMutations([deleteDraftEobPayment]);

  const handleDeleteEob = useCallback(() => {
    deleteDraftEobPaymentModal.open(draftEobPayment.uuid);
  }, [deleteDraftEobPaymentModal, draftEobPayment.uuid]);

  const handleConfirmDeleteEob = useCallback(() => {
    deleteDraftEobPaymentMutation.mutate(
      { practiceId, draftEobPaymentUuid: draftEobPayment.uuid },
      {
        onError: handleError,
      }
    );
    deleteDraftEobPaymentModal.close();
  }, [deleteDraftEobPaymentModal, deleteDraftEobPaymentMutation, draftEobPayment.uuid, practiceId]);

  const disabled = Boolean(draftEobPayment.claimCount);

  return (
    <Row>
      <ButtonCell className={cxStyles.cell} onClick={handleRowClick}>
        {formatISODate(draftEobPayment.createDate)}
      </ButtonCell>
      <ButtonCell className={cxStyles.cell} onClick={handleRowClick}>
        {draftEobPayment.insuranceCarrier}
      </ButtonCell>
      <ButtonCell className={cxStyles.cell} onClick={handleRowClick}>
        {draftEobPayment.state === "PAYMENT_PENDING" ? (
          <div className="flex items-center gap-x-3">
            <Icon className="px-1.5" SvgIcon={RefreshIcon} size="sm" />
            <span>Payment processing...</span>
          </div>
        ) : draftEobPayment.paymentErrorMessage ? (
          <div className="flex items-center gap-x-3">
            <Icon
              className="px-1.5"
              SvgIcon={WarningIcon}
              size="sm"
              theme="error"
              tooltip={{
                content: `${draftEobPayment.paymentErrorMessage}`,
              }}
            />
            <span className="text-red">Payment failed</span>
          </div>
        ) : null}
      </ButtonCell>
      <ButtonCell className={cxStyles.cell} onClick={handleRowClick}>
        {draftEobPayment.claimCount}
      </ButtonCell>
      <ButtonCell className={cx("justify-end", cxStyles.cell)} onClick={handleRowClick}>
        {formatCurrency(draftEobPayment.unpaidPatientAmount)}
      </ButtonCell>
      <ButtonCell className={cx("justify-end", cxStyles.cell)} onClick={handleRowClick}>
        {formatCurrency(draftEobPayment.unpaidInsuranceAmount)}
      </ButtonCell>
      <Cell className={cx("p-2 gap-x-4 justify-center", cxStyles.cell)}>
        <ButtonIcon
          SvgIcon={ContinueIcon}
          onClick={handleContinueClick}
          size="sm"
          tooltip={{
            content: "Continue",
            theme: "SMALL",
          }}
        />
        <ButtonIcon
          disabled={disabled}
          SvgIcon={DeleteIcon}
          onClick={handleDeleteEob}
          size="sm"
          tooltip={
            disabled
              ? {
                  content: "EOB payment with claims cannot be deleted",
                  theme: "MEDIUM",
                }
              : {
                  content: "Delete",
                  theme: "SMALL",
                }
          }
        />
      </Cell>
      <Cell className={cxStyles.cell} />
      {deleteDraftEobPaymentModal.isOpen && (
        <ConfirmationModal
          onCancel={deleteDraftEobPaymentModal.close}
          onConfirm={handleConfirmDeleteEob}
          primaryText="Are you sure you want to delete this ongoing EOB?"
        />
      )}
    </Row>
  );
};
