import { FC, useMemo } from "react";
import { FamilyMemberResponse } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { lowercase } from "@libs/utils/casing";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { PatientInfoCard } from "components/PatientProfile/PatientOverview/Info/PatientInfoCard";
import { EditablePatient, OnChangePatientHandler } from "components/PatientProfile/PatientOverview/types";
import { FormFieldPhoneInput } from "components/UI/FormFieldPhoneInput";
import { FormFieldContactModes } from "components/Patient/FormFieldContactModes";
import { FormFieldPreferredContactMode } from "components/Patient/FormFieldPreferredContactMode";
import { EMPTY_CONTACT, familyMembersToContactOptions } from "components/Patient/contactModes";
import { ContactTagFormField } from "components/PatientProfile/PatientOverview/Info/ContactTagFormField";
import { EditPatientValidation } from "components/Patient/formSchemas";
import { ContactSearch } from "components/Patient/ContactSearch";
import { AddressFields } from "components/Patient/AddressFields";
import { ContactForList } from "components/PatientProfile/PatientOverview/Info/ContactForList";

interface Props {
  onChange: OnChangePatientHandler;
  onAddContact: (patientId?: number) => void;
  patient: EditablePatient;
  edit: boolean;
  familyMembers: FamilyMemberResponse | undefined;
  patientId: number;
  validation?: EditPatientValidation;
}

export const PatientContactInformation: FC<Props> = ({
  onChange,
  onAddContact,
  patient,
  edit,
  familyMembers,
  patientId,
  validation,
}) => {
  const allFamilyMembers = useMemo(() => {
    return [...(familyMembers?.linkedFamilyMembers ?? []), ...(familyMembers?.unlinkedFamilyMembers ?? [])];
  }, [familyMembers?.linkedFamilyMembers, familyMembers?.unlinkedFamilyMembers]);
  const contact = patient.contact.name.id === patientId ? undefined : patient.contact;

  return (
    <PatientInfoCard layout="cols-6" title="Contact Details">
      {!edit && contact ? (
        <>
          <FormFieldInput
            edit={false}
            label="Contact Methods"
            className="2xl:col-span-2"
            value="Contact Person"
          />
          <FormFieldInput
            edit={false}
            label="Preferred Contact Method"
            className="2xl:col-span-2"
            value="Contact Person"
          />
        </>
      ) : (
        <>
          <FormFieldContactModes
            edit={edit}
            hasContact={Boolean(contact)}
            error={validation?.contactModes.$error}
            contactModes={patient.contactDetails.contactModes}
            className="2xl:col-span-2"
            onChange={(val) => {
              onChange((draft) => {
                draft.contactDetails.contactModes = val;
              });
            }}
          />

          <FormFieldPreferredContactMode
            edit={edit}
            hasContact={Boolean(contact)}
            error={validation?.preferredContactMode.$error}
            contactModes={patient.contactDetails.contactModes}
            // When we aren't in edit mode we want to show the truth of what is coming back from the server.
            // When we are in edit mode, we want to filter out a preferred selection if it is not allowed.
            // In other use cases we update the editable state to unset preferres selection. However
            // UpdatePatientRequest["contactDetails"]["preferredContactMode"] is a non nullable field.
            preferredContactMode={
              edit &&
              !patient.contactDetails.contactModes[lowercase(patient.contactDetails.preferredContactMode)]
                ? undefined
                : patient.contactDetails.preferredContactMode
            }
            className="2xl:col-span-2"
            onChange={(value) => {
              onChange((draft) => {
                draft.contactDetails.preferredContactMode = value;
              });
            }}
          />
        </>
      )}

      <FormFieldInput
        className="2xl:col-span-2"
        edit={edit}
        label="Email"
        error={validation?.email.$error}
        onChange={(event) => onChange((draft) => (draft.contactDetails.email = event.target.value))}
        value={patient.contactDetails.email}
      />
      <FormFieldPhoneInput
        className="2xl:col-span-2"
        Icon={InfoIcon}
        iconTooltip={{
          content: "Reminder: Confirm patient consent before using this phone number for messaging.",
        }}
        edit={edit}
        label="Cell Phone"
        error={validation?.phoneNumber.$error}
        onValueChange={(phone) => onChange((draft) => (draft.contactDetails.phone = phone))}
        value={patient.contactDetails.phone}
      />
      <FormFieldPhoneInput
        className="2xl:col-span-2"
        edit={edit}
        label="Home Phone"
        onValueChange={(phone) => onChange((draft) => (draft.contactDetails.phone2 = phone))}
        value={patient.contactDetails.phone2}
      />
      <FormFieldPhoneInput
        className="2xl:col-span-2"
        edit={edit}
        label="Work Phone"
        onValueChange={(phone) => onChange((draft) => (draft.contactDetails.workPhone = phone))}
        value={patient.contactDetails.workPhone}
      />
      <div
        className={cx(
          `grid
           2xl:grid-cols-4
           xl:grid-cols-2
           grid-cols-1
           gap-x-3
           gap-y-5`,
          edit ? "2xl:col-span-6 xl:col-span-2" : "2xl:col-span-2"
        )}
      >
        <AddressFields
          address={patient.contactDetails.addressDetails ?? null}
          edit={edit}
          onUpdate={(address) =>
            onChange((draft) => (draft.contactDetails.addressDetails = address ?? undefined))
          }
        />
      </div>
      {patient.contactDetails.isDesignatedContact ? (
        <div className="2xl:col-span-2">
          <ContactForList
            edit={edit}
            familyMembers={allFamilyMembers}
            patientId={patientId}
            patientFirstName={patient.personalDetails.firstName}
          />
        </div>
      ) : edit ? (
        <div className="2xl:col-span-6 xl:col-span-2">
          <ContactSearch
            contact={contact}
            onClearContact={() =>
              onChange((draft) => {
                draft.contactDetails.contactRelation = undefined;
                draft.contactDetails.contactPatientId = undefined;
                draft.contact = {
                  ...EMPTY_CONTACT,
                  name: {
                    ...EMPTY_CONTACT.name,
                    id: patientId,
                  },
                  relation: "SELF",
                };
              })
            }
            onAddNewContact={onAddContact}
            onEditContact={onAddContact}
            onSelectContact={(newContact) =>
              onChange((draft) => {
                draft.contactDetails.contactRelation = newContact.relation;
                draft.contactDetails.contactPatientId = newContact.name.id;
                draft.contact = newContact;
              })
            }
            defaultContactOptions={() => {
              return familyMembersToContactOptions(allFamilyMembers, patientId);
            }}
          />
        </div>
      ) : (
        <div className="2xl:col-span-2">
          <ContactTagFormField patientFirstName={patient.personalDetails.firstName} contact={contact} />
        </div>
      )}
    </PatientInfoCard>
  );
};
