import Skeleton from "react-loading-skeleton";
import { ProcedureShortcutCategoryVO, ProcedureShortcutVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { ApiErrorResponse } from "@libs/@types/api";

interface Props {
  selectedCategory: ProcedureShortcutVO["category"];
  categories: ProcedureShortcutCategoryVO[] | undefined;
  isLoading: boolean;
  onSelectCategory: (category: ProcedureShortcutVO["category"]) => void;
  categoriesError: ApiErrorResponse | null;
  className?: string;
}
export const ProcedureShortcutCategoryOptions: React.FC<Props> = ({
  categories,
  selectedCategory,
  isLoading,
  onSelectCategory,
  categoriesError,
  className,
}) => {
  return (
    <div className={cx("flex flex-col", className)}>
      <div className="flex justify-between items-center pl-5 pr-1 mb-2">
        <div className="font-sansSemiBold text-sm">Categories</div>
      </div>
      <div
        className={`
          flex-1
          border-r
          border-r-greyLighter
          min-h-52
          max-h-72
          overflow-y-auto
        `}
      >
        {categories?.map((category) => (
          <button
            type="button"
            className={cx(
              "text-left text-xs pl-5 py-2 w-full hover:bg-slate-100",
              category.category === selectedCategory && "bg-actionLight font-sansSemiBold"
            )}
            key={category.category}
            onClick={() => {
              onSelectCategory(category.category);
            }}
          >
            {category.displayName}
          </button>
        ))}
        {categoriesError ? (
          <div className="text-xs pl-5 mt-2 text-red">
            <p>Sorry, there was an error loading categories.</p>
          </div>
        ) : null}
        {isLoading ? (
          <Skeleton
            inline={true}
            height={16}
            count={10}
            className="block my-4 last:mb-2 first:mt-2"
            containerClassName="block pl-5 pr-2"
          />
        ) : null}
      </div>
    </div>
  );
};
