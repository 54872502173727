import React from "react";
import { ErrorContent } from "@libs/components/UI/ErrorContent";
import { TableGrid } from "@libs/components/UI/GridTableComponents";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { EmptyDashboardTable } from "components/Dashboard/Tables/EmptyDashboardTable";
import { cxTableStyles } from "components/Dashboard/Tables/cxTableStyles";
import { OFFSCREEN_RENDER_ORIGIN } from "components/PatientProfile/Imaging/ImageEditor/FabricEditor/shapeUtils";
import { LoadingCells } from "components/Dashboard/Tables/LoadingCells";

export type BaseDashboardTableProps = {
  id: string;
  children: React.ReactNode;
  headerRow: React.ReactNode;
  footerRow?: React.ReactNode;
  isPrinting?: boolean;
  ref?: React.Ref<HTMLDivElement>;
  columnWidths: string[];
  isEmpty?: boolean;
  className?: string;
  isLoading?: boolean;
};

export const DashboardTable = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement> & BaseDashboardTableProps & { isError: boolean }
>(
  (
    {
      id,
      children,
      footerRow,
      isPrinting = false,
      columnWidths,
      isEmpty = false,
      className,
      isLoading,
      headerRow,
      isError,
    },
    ref
  ) => {
    const columnCount = columnWidths.length;

    return (
      <div
        className={cxTableStyles.tableWrapper}
        style={isPrinting ? OFFSCREEN_RENDER_ORIGIN : undefined}
        ref={ref}
      >
        <PersistScrollPosition id={id} className={cxTableStyles.tableContainer(isPrinting, className)}>
          <TableGrid columnWidths={columnWidths}>
            {headerRow}
            {children}
            {isError && (
              <div className="col-span-full">
                <ErrorContent />
              </div>
            )}
            {isEmpty && <EmptyDashboardTable className="col-span-full">No Records Found</EmptyDashboardTable>}
            {isLoading && <LoadingCells columnCount={columnCount} />}
          </TableGrid>
        </PersistScrollPosition>
        {footerRow && (
          <TableGrid className={cxTableStyles.tableFooterContainer} columnWidths={columnWidths}>
            {isEmpty ? null : footerRow}
          </TableGrid>
        )}
      </div>
    );
  }
);
