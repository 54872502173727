import React, { useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { IPFilterVO } from "@libs/api/generated-api";
import { isHttpResponseError } from "@libs/utils/isHttpResponseError";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { flattenPages } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { isFieldValidationError } from "@libs/utils/isFieldValidationError";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { ReactComponent as HelpIcon } from "@libs/assets/icons/help.svg";
import { ReactComponent as AlertIcon } from "@libs/assets/icons/error.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useAccount } from "@libs/contexts/AccountContext";
import {
  MAX_DESCRIPTION_LENGTH,
  useValidateDraftIpFilterForm,
} from "components/Settings/Security/IPAuthorization/useValidateIpFilterValidationForm";
import { EmployeesTable } from "components/Settings/Security/IPAuthorization/EmployeesTable";
import { DraftIpFilter, DraftIpFilterKey } from "components/Settings/Security/IPAuthorization/IPAuthSettings";
import { ActionModal } from "components/UI/ActionModal";
import { ApiEmployeeQueryParams, getInfiniteEmployees } from "api/employee/queries";
import { useSelectRows } from "hooks/useSelectRows";
import { addIpFilter, updateIpFilter } from "api/settings/practice/mutations";
import { paths } from "utils/routing/paths";
import { handleError } from "utils/handleError";
import { getIpAddress } from "api/settings/practice/queries";
import { ModalBannerMessaging } from "components/Settings/Security/IPAuthorization/ModalBannerMessaging";
import { getPracticeRolesV2 } from "api/practice/queries";

interface Props {
  ipFilter: DraftIpFilter;
  ipFilters: IPFilterVO[] | undefined;
  onClose: (ipFilterToUpdate?: DraftIpFilter) => void;
}

type EmployeesQueryParams = keyof ApiEmployeeQueryParams;
export type EmployeesQueryUpdates = Partial<ApiEmployeeQueryParams>;

const isBlockingSelfError = (err: unknown) =>
  isHttpResponseError(err) &&
  err.error.errors?.[0].message === "The IP filter address will lock your current address out of the system.";

const showBlockingSelfMessage = () =>
  toast.error("You must allow yourself access by selecting your name in the list.");

// eslint-disable-next-line complexity
export const IpFilterModal: React.FC<Props> = ({ ipFilter, onClose, ipFilters }) => {
  const { practiceId } = useAccount();
  const navigate = useNavigate();

  const [draftIpFilter, setDraftIpFilter] = useState(ipFilter);
  const [errors, setErrors] = useState({ address: "", description: "", general: "" });

  const [queryParams, setQueryParams] = useState<EmployeesQueryUpdates>({
    orderBy: "ASCENDING",
    sortColumn: "lastName",
    statuses: ["ACTIVE", "INACTIVE", "PENDING", "FURLOUGHED"],
  });

  const handleQueryParamChange = useCallback((keyValues: EmployeesQueryUpdates) => {
    const keys = Object.keys(keyValues) as EmployeesQueryParams[];

    keys.forEach((key) => {
      setQueryParams((last) => ({ ...last, [key]: keyValues[key] }));
    });
  }, []);

  const [rolesQuery] = useApiQueries([getPracticeRolesV2({ args: { practiceId } })]);

  const employeesQuery = useInfiniteApiQuery(
    getInfiniteEmployees({
      args: { ...queryParams, pageNumber: 1, pageSize: PAGE_SIZE, practiceId },
    })
  );

  const { employees, employeeIds } = useMemo(() => {
    const flattened = flattenPages(employeesQuery.data) || [];

    return {
      employees: flattened,
      employeeIds: flattened.map((empl) => empl.id),
    };
  }, [employeesQuery]);

  const { selectedRows, handleCheckboxChange, resetSelectedRows } = useSelectRows(employeeIds, {
    preSelectedItems: draftIpFilter.userIds,
  });

  const { ipFilterForm } = useValidateDraftIpFilterForm(draftIpFilter, selectedRows);

  const handleFieldChange = useCallback((field: DraftIpFilterKey, value: string | number[]) => {
    setErrors((last) => ({ ...last, [field]: "" }));
    setDraftIpFilter((last) => ({ ...last, [field]: value }));
  }, []);

  const [ipAddressQuery] = useApiQueries([getIpAddress({ args: {} })]);

  const handleClickFillIpAddress = useCallback(() => {
    handleFieldChange("address", ipAddressQuery.data ?? "");
  }, [handleFieldChange, ipAddressQuery.data]);

  const handleToggleApplyToAll = useCallback(() => {
    setDraftIpFilter((last) => ({ ...last, appliesToAll: !last.appliesToAll }));
    resetSelectedRows();
  }, [resetSelectedRows]);

  const [addIpFilterMutation, updateIpFilterMutation] = useApiMutations([addIpFilter, updateIpFilter]);

  const handleResponseErrors = useCallback(
    (error: unknown) => {
      if (isHttpResponseError(error)) {
        (error.error.errors || []).forEach((resErr) => {
          if (isFieldValidationError(resErr)) {
            setErrors({ ...errors, [resErr.field as string]: resErr.message });
          }
        });
      } else {
        handleError(error);
      }
    },
    [errors]
  );

  const handleAddIpFilter = useCallback(() => {
    addIpFilterMutation.mutate(
      {
        data: {
          ...draftIpFilter,
          userIds: draftIpFilter.appliesToAll ? [] : [...selectedRows],
        },
        practiceId,
      },
      {
        onSuccess: () => {
          navigate(paths.settingsSection({ section: "ip-auth" }), { replace: true });
          onClose();
        },
        onError: (err) => {
          if (isBlockingSelfError(err)) {
            showBlockingSelfMessage();
          } else {
            handleResponseErrors(err);
          }
        },
      }
    );
  }, [addIpFilterMutation, draftIpFilter, handleResponseErrors, navigate, onClose, practiceId, selectedRows]);

  const handleEditIpFilter = useCallback(() => {
    if (draftIpFilter.uuid) {
      updateIpFilterMutation.mutate(
        {
          data: {
            ...draftIpFilter,
            userIds: draftIpFilter.appliesToAll ? [] : [...selectedRows],
          },
          ipFilterUuid: draftIpFilter.uuid,
          practiceId,
        },
        {
          onSuccess: () => {
            navigate(paths.settingsSection({ section: "ip-auth" }), { replace: true });
            onClose();
          },
          onError: (err) => {
            if (isBlockingSelfError(err)) {
              showBlockingSelfMessage();
            } else {
              handleResponseErrors(err);
            }
          },
        }
      );
    } else {
      // When editing an IP Filter, it should always have a UUID so this should never happen,
      // but adding it as a fallback.
      setErrors({ ...errors, general: "Something went wrong. Please try again." });
    }
  }, [
    draftIpFilter,
    errors,
    handleResponseErrors,
    navigate,
    onClose,
    practiceId,
    selectedRows,
    updateIpFilterMutation,
  ]);

  const handleSave = useCallback(() => {
    const validate = ipFilterForm.validate();

    if (!validate.$isValid) {
      return;
    }

    if (draftIpFilter.uuid) {
      handleEditIpFilter();
    } else {
      handleAddIpFilter();
    }
  }, [ipFilterForm, draftIpFilter.uuid, handleEditIpFilter, handleAddIpFilter]);

  const disabledSave = ipFilterForm.result.$isValid === false;

  const hasUsersErrorMsg = ipFilterForm.result.hasUsers.$error;

  return (
    <ActionModal
      handleClose={() => onClose()}
      handleSave={handleSave}
      isLoading={updateIpFilterMutation.isLoading || addIpFilterMutation.isLoading}
      primaryButtonText="Save"
      primaryButtonDisabled={disabledSave}
      primaryButtonDisabledTooltip={disabledSave ? "Missing required fields" : ""}
      size="lg"
      title={draftIpFilter.uuid ? "Edit Authorized IP Address" : "Add Authorized IP Address"}
    >
      <div className="flex flex-col min-h-0 h-[65vh] text-greyDark">
        {ipFilters ? (
          <ModalBannerMessaging isEditing={Boolean(draftIpFilter.uuid)} ipFilters={ipFilters} />
        ) : null}
        <div className="grid grid-cols-2 gap-x-4">
          <div className="flex justify-between">
            <div className="flex items-center">
              <span className="mr-1.5 font-sansSemiBold text-sm">Allowed IP</span>
              <FloatingTooltip
                content={
                  <div className="text-greyDark">
                    You can combine multiple single IPv4 and IPv6 addresses, as well as CIDR blocks in this
                    field. Simply add a comma between each item.
                    <br />
                    <br />
                    Example:
                    <br />
                    <span className="text-primaryTheme break-all">
                      211.11.23.123,2001:0db8:3c4d:0015:0000:0000:1a2f:1a2b,211.11.12.0/24
                    </span>
                  </div>
                }
              >
                <HelpIcon className="h-4 w-4 text-primaryTheme" />
              </FloatingTooltip>
            </div>
            <div className="flex items-end text-primaryTheme">
              <button className="mr-1.5 text-xs" onClick={handleClickFillIpAddress} type="button">
                Use my current IP address
              </button>
              <FloatingTooltip content="Only use your current IP address if it is a static address">
                <HelpIcon className="h-4 w-4" />
              </FloatingTooltip>
            </div>
          </div>
        </div>
        <div className="grid grid-cols-2 gap-x-4 mt-3">
          <FormFieldInput
            error={ipFilterForm.result.address.$error || errors.address}
            label="IP Address"
            layout="labelIn"
            onChange={(event) => handleFieldChange("address", event.target.value)}
            placeholder="Single, multiple and CIDR blocks supported. (IPv4, IPV6)"
            required
            value={draftIpFilter.address || ""}
          />
          <FormFieldInput
            error={ipFilterForm.result.description.$error || errors.description}
            label="Description"
            layout="labelIn"
            maxLength={MAX_DESCRIPTION_LENGTH}
            onChange={(event) => handleFieldChange("description", event.target.value)}
            placeholder="Enter a description"
            required
            value={draftIpFilter.description || ""}
          />
        </div>
        <div className="mt-2 text-xs text-greyMedium text-right">
          {draftIpFilter.description.length || 0}/50 characters
        </div>
        <div className="flex flex-col mb-8">
          <Checkbox checked={draftIpFilter.appliesToAll} onChange={handleToggleApplyToAll}>
            Allow access to all employees from this IP address
          </Checkbox>
          {hasUsersErrorMsg && (
            <div className="flex mt-4">
              <AlertIcon className="fill-red w-4 h-4" />
              <div className="flex text-red text-xxs ml-1">{hasUsersErrorMsg}</div>
            </div>
          )}
        </div>
        <EmployeesTable
          disabled={draftIpFilter.appliesToAll}
          employees={employees}
          rolesQuery={rolesQuery}
          employeesQuery={employeesQuery}
          onSelectEmployee={handleCheckboxChange}
          onSort={handleQueryParamChange}
          queryParams={queryParams}
          selectedEmployees={selectedRows}
        />
      </div>
    </ActionModal>
  );
};
