import React, { useCallback } from "react";
import { formatCurrency } from "@libs/utils/currency";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { usePick } from "@libs/hooks/usePick";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { FooterCell } from "components/Dashboard/Tables/FooterCell";
import { CellCurrencyValue } from "components/Dashboard/Tables/CellCurrencyValue";
import { useQueryParams } from "hooks/useQueryParams";
import {
  useFlattenProductionByProcedurePages,
  useProductionByProcedureQuery,
} from "components/Dashboard/PracticeProduction/hooks/useProductionByProcedureQuery";
import { useProductionTableTabProps } from "components/Dashboard/PracticeProduction/hooks/useProductionTableTabProps";
import { ProductionByProcedureRow } from "components/Dashboard/PracticeProduction/ProductionByProcedureRow";
import { useFormattedProviderRollups } from "components/Dashboard/hooks/useFormattedProviderRollups";
import { useFilterTokenProps } from "components/Dashboard/hooks/useFilterTokenProps";
import { getDentalProceduresQuery } from "api/charting/queries";
import { DashboardTableFilters } from "components/Dashboard/Tables/DashboardTableFilters";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { useProviderRollupQueries } from "components/Dashboard/hooks/useProviderRollupQueries";
import { useFilteredProviderCategory } from "components/Dashboard/hooks/useFilteredProviderCategory";
import { PRODUCTION_BY_PROCEDURE_COLUMNS } from "components/Dashboard/PracticeProduction/constants";
import { MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";
import { PracticeProductionQuery } from "utils/routing/dashboard";
import { DashboardTableTabs } from "components/Dashboard/Tables/DashboardTableTabs";
import { ProductionAppointmentAndProcedureFilters } from "components/Dashboard/PracticeProduction/ProductionAppointmentAndProcedureFilters";
import { useDownloadProductionByProcedureCSV } from "components/Dashboard/PracticeProduction/hooks/useDownloadProductionByProcedureCSV";
import { DashboardHeaderRow } from "components/Dashboard/Tables/DashboardHeaderRow";
import { ColumnSortOrder } from "utils/routing/dashboard/serializedSortOrder";
import { DashboardInfiniteTable } from "components/Dashboard/Tables/DashboardInfiniteTable";

// eslint-disable-next-line complexity
export const ProductionByProcedureTableRoute: React.FC = () => {
  const { query, updateQuery } = useQueryParams("dashboardPracticeProduction");
  const dateWindow = useTimeSeriesPageSelections({
    ...query,
    includeProjectedDates: true,
  });
  const { selectedTimeSegment } = dateWindow;
  const { search, isWaiting } = useDebouncedSearch(query.patientSearch ?? "");
  const { productionByProcedureQuery, isLoading, totals } = useProductionByProcedureQuery({
    pageSize: MAX_PAGE_SIZE,
    selectedTimeSegment,
    patientSearch: search,
    sortOrders: query.procedureTableSort,
    filters: query.filters,
  });
  const { downloadCSV, isDownloading: isDownloadingCsv } = useDownloadProductionByProcedureCSV({
    selectedTimeSegment,
    productionByProcedureQuery,
  });
  const providerFilters = useFilteredProviderCategory(query.filters);

  const { providersInTimeWindowQuery, allProvidersQuery } = useProviderRollupQueries({
    metric: "productionAmount",
    dateWindow,
    filters: undefined,
  });

  const { practiceId } = useAccount();
  const [proceduresQuery, providersInTimeWindowQueryResult, allProvidersQueryResult] = useApiQueries([
    getDentalProceduresQuery({ args: { practiceId } }),
    providersInTimeWindowQuery,
    allProvidersQuery,
  ]);
  const filterParams = usePick(query, ["filters", "patientSearch"]);
  const { selectedProvider, data: providerFilterOptions } = useFormattedProviderRollups(
    providersInTimeWindowQueryResult,
    allProvidersQueryResult,
    {
      ...providerFilters,
    }
  );
  const filterProps = useFilterTokenProps({
    options: filterParams,
    selectedProviderDisplayName: selectedProvider?.provider.fullDisplayName,
    procedures: proceduresQuery.data,
  });

  const rows = useFlattenProductionByProcedurePages(productionByProcedureQuery.data);

  const isLoadingTable = isLoading || isWaiting;
  const isEmpty = !isLoadingTable && rows?.length === 0;

  const handleRouteStateChange = useCallback(
    (updates: Partial<PracticeProductionQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );

  const { tabs } = useProductionTableTabProps({
    query,
  });
  const handleSortClick = React.useCallback(
    (procedureTableSort: ColumnSortOrder[]) => {
      updateQuery("replaceIn", {
        procedureTableSort,
      });
    },
    [updateQuery]
  );

  return (
    <>
      <DashboardTableTabs
        tabs={tabs}
        onUpdateParams={handleRouteStateChange}
        onClickDownload={downloadCSV}
        isDownloading={isDownloadingCsv}
        query={query}
        id="practice-production-by-procedure"
      >
        <ProductionAppointmentAndProcedureFilters
          tab="procedures"
          tableExpanded={query["table.fullScreen"]}
          allProcedures={proceduresQuery.data}
          providerFilterOptions={providerFilterOptions}
          onFiltersChanged={(filters) => {
            handleRouteStateChange({ filters });
          }}
          filters={query.filters}
        />
      </DashboardTableTabs>
      <DashboardTableFilters {...filterParams}>
        <QueryFilterPills
          {...filterProps}
          numResults={totals?.distinctCount}
          onUpdateParams={(updatedQuery) => {
            updateQuery("replaceIn", updatedQuery);
          }}
          onClearAll={() => {
            updateQuery("replaceIn", { filters: [], patientSearch: undefined });
          }}
        />
      </DashboardTableFilters>
      <DashboardInfiniteTable
        infiniteQuery={productionByProcedureQuery}
        columnWidths={PRODUCTION_BY_PROCEDURE_COLUMNS.map(({ width }) => width)}
        id="productionByProcedureTable"
        isEmpty={isEmpty}
        headerRow={
          <DashboardHeaderRow
            headers={PRODUCTION_BY_PROCEDURE_COLUMNS}
            onSortClick={handleSortClick}
            sortOrders={query.procedureTableSort}
          />
        }
        footerRow={
          <>
            <FooterCell className="col-span-4" />
            <FooterCell className="flex justify-center">Totals</FooterCell>
            <FooterCell loading={isLoadingTable} align="center">
              <CellCurrencyValue align="right">
                {totals && formatCurrency(totals.totalGrossScheduledAmount)}
              </CellCurrencyValue>
            </FooterCell>
            <FooterCell loading={isLoadingTable} align="center">
              <CellCurrencyValue align="right">
                {totals && formatCurrency(totals.totalGrossProducedAmount)}
              </CellCurrencyValue>
            </FooterCell>
            <FooterCell loading={isLoadingTable} align="center">
              <CellCurrencyValue align="right">
                {totals && formatCurrency(totals.totalProducedAmount)}
              </CellCurrencyValue>
            </FooterCell>
            <FooterCell />
          </>
        }
      >
        {!isLoadingTable &&
          rows?.map((row, i) => {
            return (
              <ProductionByProcedureRow
                key={`${i}-${row.productionDate}-${row.dentalProcedureId}-${row.appointmentId}-${row.provider.id}-${row.patient.id}`}
                row={row}
                last={i === rows.length - 1}
              />
            );
          })}
      </DashboardInfiniteTable>
    </>
  );
};
