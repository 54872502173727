import { Icon } from "@libs/components/UI/Icon";
import { PropsWithChildren } from "react";
import { ReactComponent as ClockIcon } from "@libs/assets/icons/clock.svg";
import { ReactComponent as PeopleIcon } from "@libs/assets/icons/people.svg";

export const DetailDisclosure: React.FC<PropsWithChildren & { icon: "people" | "clock" }> = ({
  icon,
  children,
}) => {
  return (
    <div className="flex gap-1 bg-slate-100 rounded-md p-2 text-xs items-center">
      <Icon className="text-slate-700" SvgIcon={icon === "people" ? PeopleIcon : ClockIcon} />
      {children}
    </div>
  );
};
