import { FC, useState } from "react";
import { PayrollLineItemVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { hasFailedPin } from "@libs/utils/hasFailedPin";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as DownloadIcon } from "@libs/assets/icons/preview-file.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { ButtonCell, Row, TextCell } from "@libs/components/UI/GridTableComponents";
import { PayrollEmployeeCell } from "components/Payroll/PayrollEmployeeCell";
import { printEmployeePayStub } from "api/payroll/queries";
import { handleError } from "utils/handleError";
import { EmployeePinModal } from "components/Employee/EmployeePinModal";

interface Props {
  payrollId: string;
  lineItem: PayrollLineItemVO;
}

export const PayrollDetailsCompanyPaysRow: FC<Props> = ({ payrollId, lineItem }) => {
  const { practiceId } = useAccount();
  const [requestPayStub, setRequestPayStub] = useState(false);
  const pinModal = useBoolean(false);

  const [printEmployeePayStubQuery] = useApiQueries([
    printEmployeePayStub({
      args: { practiceId, employeeId: lineItem.employee.id, payrollId },
      queryOptions: {
        enabled: requestPayStub,
        onSuccess: (data) => {
          window.open(data.data.data, "_target");
        },
        onError: (err) => {
          if (hasFailedPin(err)) {
            pinModal.on();
          } else {
            handleError(err);
          }
        },
      },
    }),
  ]);

  return (
    <Row className="*:flex *:items-center">
      <PayrollEmployeeCell lineItem={lineItem} />
      <TextCell>{formatCurrency(lineItem.breakdown?.grossPay ?? 0)}</TextCell>
      <TextCell>{formatCurrency(lineItem.breakdown?.reimbursements ?? 0)}</TextCell>
      <TextCell>{formatCurrency(lineItem.breakdown?.companyTaxes ?? 0)}</TextCell>
      <TextCell>{formatCurrency(lineItem.breakdown?.companyBenefits ?? 0)}</TextCell>
      <TextCell>{formatCurrency(lineItem.breakdown?.companySubtotal ?? 0)}</TextCell>
      <ButtonCell
        className="flex justify-end"
        onClick={() => setRequestPayStub(true)}
        disabled={!lineItem.payStubDocumentId}
      >
        <Icon
          disabled={!lineItem.payStubDocumentId}
          className="w-fit"
          SvgIcon={DownloadIcon}
          theme="primary"
        />
      </ButtonCell>
      {pinModal.isOn && (
        <EmployeePinModal
          onPinSuccess={() => {
            printEmployeePayStubQuery.refetch();
            pinModal.off();
          }}
          onClose={pinModal.off}
        />
      )}
    </Row>
  );
};
