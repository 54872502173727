import React, { FC, ReactNode } from "react";
import {
  LedgerV2AdjustmentEntryVO,
  LedgerV2EntryPaymentVO,
  LedgerV2InvoiceSubEntryVO,
  PatientVO,
} from "@libs/api/generated-api";
import { currencyValueOrDash, formatCurrency } from "@libs/utils/currency";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import {
  AccordionItem,
  Col,
  PaymentDescription,
  PracticeAndPatientIds,
} from "components/PatientProfile/Billing/Ledger/LedgerComponents";
import { InvoiceCreditAppliedPill } from "components/PatientProfile/Billing/Ledger/InvoiceCreditAppliedPill";
import { isFamilyPayment } from "components/PatientProfile/Billing/billingUtils";
import { InvoiceDescription } from "./InvoiceDescription";
import { InvoiceStatePill } from "./InvoiceStatePill";
import { InvoiceMenu } from "./InvoiceMenu";
import { PaymentMenu } from "./PaymentMenu";
import { Text } from "./Text";
import { isCreditApplied, ledgerSubEntryToId } from "./ledgerUtils";
import { usePersistExpandRow } from "./usePersistExpandRow";
import { DetailsTable } from "./DetailsTable";
import {
  ExpandableRow,
  DetailsRow,
  TableRow,
  ProcedureTableRow,
  DescriptionWithPatientInsuranceRow,
} from "./AppointmentsAndAdjustmentsPage";
import { ProviderInitials } from "./ProviderInitials";
import { AppointmentAdjustmentTableRow } from "./AppointmentAdjustmentTableRow";

export const InvoiceExpandable: FC<
  PracticeAndPatientIds & {
    invoiceSubEntry: LedgerV2InvoiceSubEntryVO;
    // May not be provided when `InvoiceExpandable` is called from `LedgerInvoicesPage`
    adjustmentEntry?: LedgerV2AdjustmentEntryVO;
  }
> = ({ practiceId, patientId, invoiceSubEntry, adjustmentEntry }) => {
  const expandRow = usePersistExpandRow(ledgerSubEntryToId(invoiceSubEntry));

  return (
    <AccordionItem>
      <ExpandableRow
        itemId={ledgerSubEntryToId(invoiceSubEntry)}
        indentation={2}
        date={
          <Text bold>
            <DateWithTooltip date={new Date(invoiceSubEntry.timestamp)} format="P" />
          </Text>
        }
        details={
          <DescriptionWithPatientInsuranceRow
            descriptionColumn={<InvoiceDescription invoiceNumber={invoiceSubEntry.invoiceNumber} />}
            patientColumn={
              // The `InvoiceStatePill` component is used to display the status of an invoice
              // (refunded, paid, partially paid, etc.). However, if the parent `adjustmentEntry` is
              // determined to be an applied credit, we disregard the invoice's status and display a
              // green pill with "Credit Applied" instead. We don't combine the `InvoiceStatePill`
              // and `CreditAppliedPill` components into one because the `InvoiceStatePill` is also
              // used by other pages that don't have the concept of "adjustment".
              adjustmentEntry && isCreditApplied(adjustmentEntry) ? (
                <InvoiceCreditAppliedPill />
              ) : (
                <InvoiceStatePill
                  invoiceAmount={invoiceSubEntry.patientAmount}
                  invoiceState={invoiceSubEntry.invoiceState}
                />
              )
            }
            insuranceColumn={undefined}
          />
        }
        amount={
          <Text bold color={invoiceSubEntry.paymentsForLedgerEntry.length ? "green" : undefined}>
            {currencyValueOrDash(-invoiceSubEntry.collectionAmount)}
          </Text>
        }
        outstanding={undefined}
        menu={
          <InvoiceMenu
            practiceId={practiceId}
            patientId={patientId}
            invoiceUuid={invoiceSubEntry.invoiceUuid}
            // While we could open the collect/refund/void modals from here, we don't want to
            // because on the ledger the user only sees partial invoice data and we wouldn't want
            // them to act on an invoice without seeing the full details.
            canCollect={false}
            canRefund={false}
            canVoid={false}
          />
        }
        expandedContent={
          <>
            <DetailsRow indentation={2}>
              <DetailsTable>
                {invoiceSubEntry.paymentsForLedgerEntry.length ? (
                  <>
                    <PaymentsTableHeader />
                    {invoiceSubEntry.paymentsForLedgerEntry.map((paymentForEntry) => (
                      <EntryPayment
                        patientId={patientId}
                        key={paymentForEntry.paymentUuid}
                        paymentForEntry={paymentForEntry}
                      />
                    ))}
                  </>
                ) : (
                  <TableRow
                    descriptionColumn={
                      <Text>
                        <i>No Payments</i>
                      </Text>
                    }
                    patientColumn={undefined}
                    insuranceColumn={undefined}
                  />
                )}
              </DetailsTable>
            </DetailsRow>
            <DetailsRow indentation={2}>
              {invoiceSubEntry.entryReference.type === "APPOINTMENT" ? (
                <DetailsTable>
                  <TableRow
                    descriptionColumn={<Text bold>Procedures</Text>}
                    patientColumn={<Text bold>Amount</Text>}
                    insuranceColumn={undefined}
                  />
                  {invoiceSubEntry.procedureItems.map((procedure) => (
                    <ProcedureTableRow
                      key={procedure.patientProcedureId}
                      providerInitials={<ProviderInitials provider={procedure.provider} />}
                      cdtCode={<Text>{procedure.cdtCode}</Text>}
                      toothName={<Text>{procedure.procedureArea}</Text>}
                      procedureName={<Text>{procedure.procedureName}</Text>}
                      patientColumn={<Text>{formatCurrency(procedure.patientSubtotalAmount)}</Text>}
                      insuranceColumn={undefined}
                    />
                  ))}
                  {invoiceSubEntry.appointmentAdjustments.map((adjustment) => (
                    <AppointmentAdjustmentTableRow
                      key={adjustment.appointmentId}
                      adjustmentName={adjustment.customAdjustmentType.name}
                      adjustmentAmount={adjustment.amount}
                    />
                  ))}
                  <TableRow
                    descriptionColumn={undefined}
                    patientColumn={
                      <div className="flex gap-x-6">
                        <Text bold>Total</Text>
                        <Text bold>{formatCurrency(invoiceSubEntry.patientAmount)}</Text>
                      </div>
                    }
                    insuranceColumn={undefined}
                  />
                </DetailsTable>
              ) : adjustmentEntry ? (
                <DetailsTable>
                  <AdjustmentTableHeader />
                  <AdjustmentTableRow
                    adjustmentDate={
                      <Text>
                        <DateWithTooltip date={new Date(adjustmentEntry.timestamp)} format="P" />
                      </Text>
                    }
                    adjustmentDescription={<Text>{adjustmentEntry.customAdjustmentType.name}</Text>}
                    adjustmentAmount={<Text>{formatCurrency(invoiceSubEntry.patientAmount)}</Text>}
                  />
                </DetailsTable>
              ) : null}
            </DetailsRow>
          </>
        }
        isExpanded={expandRow.isOn}
        onToggleExpand={expandRow.toggle}
      />
    </AccordionItem>
  );
};

const AdjustmentTableHeader: FC = () => {
  return (
    <TableRow
      descriptionColumn={<Text bold>Adjustment</Text>}
      patientColumn={<Text bold>Amount</Text>}
      insuranceColumn={undefined}
    />
  );
};

const AdjustmentTableRow: FC<{
  adjustmentDate: ReactNode;
  adjustmentDescription: ReactNode;
  adjustmentAmount: ReactNode;
}> = ({ adjustmentDate, adjustmentDescription, adjustmentAmount }) => {
  return (
    <TableRow
      descriptionColumn={
        <>
          <Col justify="left" width="lg">
            {adjustmentDate}
          </Col>
          <Col justify="left" width="full">
            {adjustmentDescription}
          </Col>
        </>
      }
      patientColumn={adjustmentAmount}
      insuranceColumn={undefined}
    />
  );
};

const PaymentTableRow: FC<{
  paymentDate: ReactNode;
  paymentDescription: ReactNode;
  paymentAmount: ReactNode;
  menu: ReactNode;
}> = ({ paymentDate, paymentDescription, paymentAmount, menu }) => {
  return (
    <TableRow
      descriptionColumn={
        <>
          <Col justify="left" width="lg">
            {paymentDate}
          </Col>
          <Col justify="left" width="full">
            {paymentDescription}
          </Col>
        </>
      }
      patientColumn={paymentAmount}
      insuranceColumn={
        <Col justify="left" width="full">
          <div className="pl-2">{menu}</div>
        </Col>
      }
    />
  );
};

const PaymentsTableHeader: FC = () => {
  return (
    <TableRow
      descriptionColumn={<Text bold>Payments</Text>}
      patientColumn={<Text bold>Amount</Text>}
      insuranceColumn={undefined}
    />
  );
};

const EntryPayment: FC<{
  patientId: PatientVO["id"];
  paymentForEntry: LedgerV2EntryPaymentVO;
}> = ({ patientId, paymentForEntry }) => {
  return (
    <PaymentTableRow
      key={paymentForEntry.paymentUuid}
      paymentDate={
        <Text>
          <DateWithTooltip date={paymentForEntry.paymentCreatedAt} dateAsSeconds format="P" />
        </Text>
      }
      paymentDescription={<PaymentDescription payment={paymentForEntry} />}
      paymentAmount={
        <Text bold color="green">
          {formatCurrency(-paymentForEntry.amount)}
        </Text>
      }
      menu={
        paymentForEntry.paymentMethod !== "BALANCE_TRANSFER" && (
          <PaymentMenu
            patientId={patientId}
            paymentUuid={paymentForEntry.batchPaymentUuid ?? paymentForEntry.paymentUuid}
            isFamilyPayment={isFamilyPayment(paymentForEntry)}
            canEditPayment={paymentForEntry.permittedActions.includes("EDIT")}
            canDeletePayment={paymentForEntry.permittedActions.includes("DELETE")}
          />
        )
      }
    />
  );
};
