/* eslint-disable max-nested-callbacks */
import React from "react";
import { BarGroup } from "@visx/shape";
import { ScaleBand, ScaleLinear, ScaleOrdinal } from "d3-scale";

import { UseTooltipParams } from "@visx/tooltip/lib/hooks/useTooltip";

import { config, useSpring } from "@react-spring/web";
import { Group } from "@visx/group";
import { BarGroupBar } from "@visx/shape/lib/types";
import designConfig from "@libs/design.config";
import { cx } from "@libs/utils/cx";
import { isDefined } from "@libs/utils/types";
import { half } from "@libs/utils/math";
import { BarStackGroup } from "components/Dashboard/Charting/types";
import { VisxChartBar } from "components/Dashboard/Charting/VisxChartBar";
import { SelectedBar } from "components/Dashboard/Charting/SelectedBar";
import { ClickableAxisLabelHiddenBar } from "components/Dashboard/Charting/ClickableAxisLabelHiddenBar";
import { useBarGroupFootprint } from "components/Dashboard/Charting/hooks/useBarGroupFootprint";

const TOOLTIP_TIMEOUT = 300;

interface Props<T extends string> {
  yScale: ScaleLinear<number, number, never>;
  xScale: ScaleBand<string>;
  yMax: number;
  data: BarStackGroup<T>[];
  tooltipData?: BarStackGroup<T>;
  onHideTooltip: Func;
  keys: T[];
  selectedGroupIndex?: number;
  dottedGroupIndex?: number;
  marginBottom: number;
  barColorScale: ScaleOrdinal<T, string, never>;
  onShowTooltip: UseTooltipParams<BarStackGroup<T>>["showTooltip"];
  onClickGroup: (params: { date: string; index: number }) => void;
}

const getDate = (d: { date: string }) => d.date;

type StackBarTotal = Record<number, number> & {
  date: string;
};
export const VisxGroupedBarStacks = <T extends string>({
  data,
  xScale,
  yScale,
  keys,
  barColorScale,
  onHideTooltip,
  marginBottom,
  onShowTooltip,
  tooltipData,
  selectedGroupIndex,
  dottedGroupIndex,
  onClickGroup,
  yMax,
}: Props<T>) => {
  const tooltipTimeout = React.useRef<NodeJS.Timeout | null>(null);

  const { scale } = useSpring({
    from: { scale: 0 },
    to: { scale: 1 },
    config: config.default,
  });

  React.useEffect(() => {
    scale.reset();
  }, [data, scale]);

  const barGroupMock = useBarGroupFootprint({ data, xScaleBandwidth: xScale.bandwidth() });

  return (
    <BarGroup<StackBarTotal, string>
      x0={getDate}
      x0Scale={xScale}
      yScale={yScale}
      keys={barGroupMock.keys}
      data={barGroupMock.data}
      color={barGroupMock.color}
      height={yMax}
      x1Scale={barGroupMock.x1Scale}
    >
      {(barGroups) =>
        barGroups.map((barGroup, dateIndex) => {
          const isSelectedGroup = selectedGroupIndex === dateIndex;
          const finalBar = barGroup.bars.at(-1) as BarGroupBar<string>;
          const firstBar = barGroup.bars[0];
          const groupWidth = finalBar.x + finalBar.width - firstBar.x;

          return (
            <Group left={barGroup.x0} key={`bar-group-${barGroup.index}-${barGroup.x0}`}>
              {isSelectedGroup && (
                <SelectedBar x={firstBar.x} barWidth={groupWidth} marginBottom={marginBottom} yMax={yMax} />
              )}
              {dottedGroupIndex === dateIndex && (
                <circle
                  cx={firstBar.x + half(groupWidth)}
                  cy={yMax + half(marginBottom)}
                  r="2"
                  fill={designConfig.colors.primaryTheme}
                />
              )}

              <ClickableAxisLabelHiddenBar
                index={dateIndex}
                yMax={yMax}
                onClick={() => onClickGroup({ date: data[dateIndex].date, index: dateIndex })}
                width={groupWidth}
                x={firstBar.x}
              />
              {barGroup.bars.map((bar, stackIndex) => {
                const dateItem = data[dateIndex];

                const stackItem = dateItem.stacks[stackIndex];

                const stacksShown = keys
                  .filter((key) => isDefined(stackItem[key]) && stackItem[key] !== 0)
                  .map((key) => ({ value: stackItem[key] ?? 0, key }));
                const isHovering = dateItem.date === tooltipData?.date;
                const className = cx("cursor-pointer", isHovering && "opacity-90");

                return stacksShown.map(({ key, value }, i) => {
                  const priorValues = stacksShown
                    .filter((_, j) => j < i)
                    .reduce((prev, curr) => curr.value + prev, 0);
                  const barY = yScale(priorValues);

                  const barHeight = yMax - yScale(value);
                  const isTopStack = i === 0;

                  return (
                    <VisxChartBar
                      key={`bar-group-bar-${key}`}
                      scale={scale}
                      onClick={() => onClickGroup({ date: dateItem.date, index: dateIndex })}
                      barX={bar.x}
                      barY={barY - barHeight}
                      maxY={yMax}
                      className={className}
                      testId={`bar-${dateItem.date}-${key}`}
                      isTopStack={isTopStack}
                      barHeight={barHeight}
                      barWidth={bar.width}
                      color={barColorScale(key)}
                      borderTop={i < stacksShown.length - 1}
                      onHideTooltip={() => {
                        tooltipTimeout.current = setTimeout(() => {
                          onHideTooltip();
                        }, TOOLTIP_TIMEOUT);
                      }}
                      onShowTooltip={({ tooltipTop, tooltipLeft }) => {
                        if (tooltipTimeout.current) {
                          clearTimeout(tooltipTimeout.current);
                        }

                        onShowTooltip({
                          tooltipData: dateItem,
                          tooltipTop,
                          tooltipLeft,
                        });
                      }}
                    />
                  );
                });
              })}
            </Group>
          );
        })
      }
    </BarGroup>
  );
};
