import React, { Dispatch, SetStateAction } from "react";
import { AppointmentSeriesVO } from "@libs/api/generated-api";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { SlotSelections } from "components/ScheduleAppointments/types";
import { useAppointmentDurationContext } from "components/ScheduleAppointments/AppointmentDurationContext";
import { AppointmentList } from "./AppointmentList";

interface Props {
  openSlotsQuery: ApiQueryResult<AppointmentSeriesVO[]>;
  selections: SlotSelections;
  hasChangedPreferences: boolean;
  onFetchOpenSlots: Func;
  onSelectedSlotUpdated: Dispatch<SetStateAction<SlotSelections>>;
}

export const AppointmentSlotsResult: React.FC<Props> = ({
  openSlotsQuery,
  onFetchOpenSlots,
  selections,
  hasChangedPreferences,
  onSelectedSlotUpdated,
}) => {
  const duration = useAppointmentDurationContext();

  return (
    <div className="relative mt-2.5">
      <AppointmentList
        appointmentDurationInMinutes={duration.read}
        openSlotsQuery={openSlotsQuery}
        selections={selections}
        onSelectedSlotUpdated={onSelectedSlotUpdated}
      />
      {hasChangedPreferences || openSlotsQuery.fetchStatus === "fetching" ? (
        <div
          className={`
            absolute
            z-10
            border
            border-greyLighter
            inset-0
            rounded
            bg-white
            pt-12
            flex
            justify-center
          `}
        >
          <AsyncButton
            theme="secondary"
            size="small"
            className="self-start min-w-button"
            isLoading={openSlotsQuery.fetchStatus === "fetching"}
            onClick={() => onFetchOpenSlots()}
          >
            Refresh Slots
          </AsyncButton>
        </div>
      ) : null}
    </div>
  );
};
