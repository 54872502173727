import { FC } from "react";
import { PayrollLineItemVO, PayrollVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { isDefined } from "@libs/utils/types";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { formatNumberWithDecimalPlaces } from "@libs/utils/formatNumber";
import { HeaderCell, Row, TableGrid, TextCell } from "@libs/components/UI/GridTableComponents";
import { PayrollEmployeeCell } from "components/Payroll/PayrollEmployeeCell";
import { practicePaysDoubleOvertime } from "components/Payroll/utils";

interface Props {
  payroll: PayrollVO;
}

const getColumns = (showDoubleOvertime: boolean) =>
  [
    { width: "1fr", text: "Employee" },
    { width: "min-content", text: "Compensation Type" },
    { width: "min-content", text: "Reg Hours" },
    { width: "min-content", text: "Overtime" },
    showDoubleOvertime ? { width: "min-content", text: "Double OT" } : null,
    { width: "min-content", text: "Paid Time Off" },
    { width: "min-content", text: "Total Hours" },
    { width: "min-content", text: "Gross Pay" },
    { width: "min-content", text: "Deductions" },
    { width: "min-content", text: "Reimbursements" },
    { width: "min-content", text: "Employee Taxes" },
    { width: "min-content", text: "Employee Benefits" },
    { width: "min-content", text: "Payment" },
  ].filter(isDefined);

const payPeriodMap: Record<PayrollLineItemVO["compensationPeriod"], string> = {
  ["PER_DIEM"]: "Per Day",
  ["PER_HOUR"]: "Per Hour",
  ["PER_MONTH"]: "Per Month",
  ["PER_YEAR"]: "Per Year",
};

export const PayrollDetailsEmployeesRoute: FC<Props> = ({ payroll }) => {
  const practice = useCurrentPractice();
  const showDoubleOvertime = practicePaysDoubleOvertime(practice);
  const columns = getColumns(showDoubleOvertime);

  return (
    <TableGrid className="border-t border-t-greyLighter" columnWidths={columns.map(({ width }) => width)}>
      {columns.map(({ text }) => (
        <HeaderCell size="medium" key={text}>
          {text}
        </HeaderCell>
      ))}
      {payroll.lineItems.map((lineItem) => (
        <Row key={lineItem.employee.id} className="*:flex *:items-center">
          <PayrollEmployeeCell lineItem={lineItem} />
          <TextCell>{payPeriodMap[lineItem.compensationPeriod]}</TextCell>
          <TextCell>{formatNumberWithDecimalPlaces(lineItem.regularHours)}</TextCell>
          <TextCell>{formatNumberWithDecimalPlaces(lineItem.overtimeHours)}</TextCell>
          {showDoubleOvertime ? (
            <TextCell>{formatNumberWithDecimalPlaces(lineItem.doubleOvertimeHours)}</TextCell>
          ) : null}
          <TextCell>{formatNumberWithDecimalPlaces(lineItem.paidTimeOffHours)}</TextCell>
          <TextCell>{formatNumberWithDecimalPlaces(lineItem.totalHours)}</TextCell>
          <TextCell>{formatCurrency(lineItem.breakdown?.grossPay ?? 0)}</TextCell>
          <TextCell>{formatCurrency(lineItem.breakdown?.deductions ?? 0)}</TextCell>
          <TextCell>{formatCurrency(lineItem.breakdown?.reimbursements ?? 0)}</TextCell>
          <TextCell>{formatCurrency(lineItem.breakdown?.employeeTaxes ?? 0)}</TextCell>
          <TextCell>{formatCurrency(lineItem.breakdown?.employeeBenefits ?? 0)}</TextCell>
          <TextCell>{formatCurrency(lineItem.breakdown?.checkAmount ?? 0)}</TextCell>
        </Row>
      ))}
    </TableGrid>
  );
};
