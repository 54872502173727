import { cx } from "@libs/utils/cx";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as BarIcon } from "@libs/assets/icons/chart-bar.svg";

import { ReactComponent as LineIcon } from "@libs/assets/icons/chart-line.svg";
import { DashboardChartDisplay, DashboardQueryWithChartDisplay } from "utils/routing/dashboard";

type Props = {
  onUpdateParams: (newParams: Partial<DashboardQueryWithChartDisplay>) => void;
  disabledChartDisplays?: DashboardChartDisplay[];
  chartDisplay: DashboardChartDisplay;
};

const cxStyles = {
  button: (selected: boolean) =>
    cx("px-1.5 py-1.5", selected && "outline outline-primaryTheme bg-archyBlue-50  rounded-md"),
};
const DISPLAY_OPTIONS = [
  {
    type: "bar" as const,
    Icon: BarIcon,
  },
  {
    type: "line" as const,
    Icon: LineIcon,
  },
];

export const SelectChartDisplay: React.FC<Props> = ({
  onUpdateParams,
  disabledChartDisplays,
  chartDisplay,
}) => {
  return (
    <div className="flex border border-greyLighter rounded-md">
      {DISPLAY_OPTIONS.map(({ type, Icon }) => {
        const disabled = disabledChartDisplays?.includes(type);
        const content = (
          <ButtonIcon
            key={type}
            size="md"
            SvgIcon={Icon}
            disabled={disabled}
            className={cxStyles.button(chartDisplay === type)}
            onClick={() => onUpdateParams({ chartDisplay: type })}
          />
        );

        return disabled ? (
          <FloatingTooltip content="Select a smaller time frame or a larger resolution to enable this chart type.">
            {content}
          </FloatingTooltip>
        ) : (
          content
        );
      })}
    </div>
  );
};
