import { Route } from "react-router";
import { FC } from "react";
import { NotFound } from "components/NotFoundRoute";
import { EmployeesAppHistoryProvider } from "components/Employee/EmployeesLinksContext";
import { RolesRoute } from "components/Roles/RolesRoute";
import { CreateRoleRoute } from "components/Roles/CreateRoleRoute";
import { EditRoleRoute } from "components/Roles/EditRoleRoute";
import { CopyRoleRoute } from "components/Roles/CopyRoleRoute";
import { SentryRoutes } from "components/UI/SentryRoutes";

export const RolesApp: FC = () => {
  return (
    <EmployeesAppHistoryProvider name="roles">
      <SentryRoutes>
        <Route index={true} element={<RolesRoute />} />
        <Route path="create" element={<CreateRoleRoute />} />
        <Route path=":roleId/edit" element={<EditRoleRoute />} />
        <Route path=":roleId/copy" element={<CopyRoleRoute />} />
        <Route path="*" element={<NotFound />} />
      </SentryRoutes>
    </EmployeesAppHistoryProvider>
  );
};
