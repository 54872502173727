import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as CloseIcon } from "@libs/assets/icons/cancel.svg";

export const CloseEditorButton: React.FC<{ onClick: Func; tooltip: string }> = ({ onClick, tooltip }) => {
  return (
    <FloatingTooltip content={tooltip} theme="SMALL">
      <button
        type="button"
        className={`
          absolute
          -right-12
          top-0
          -mt-4"
          rounded-full
          p-2
          hover:opacity-70
          bg-slate-800
          overflow-hidden
        `}
        onClick={onClick}
      >
        <CloseIcon color="white" />
      </button>
    </FloatingTooltip>
  );
};
