import { TagVO } from "@libs/api/generated-api";
import { FC } from "react";
import { cx } from "@libs/utils/cx";
import { HeaderCell, TableGrid } from "@libs/components/UI/GridTableComponents";
import { TagRow } from "components/Settings/Notes/TagRow";

interface Props {
  tags: TagVO[];
  onRowOptionClick: (tag: TagVO, value: "Archive" | "Unarchive" | "Edit") => void;
}

const headers = [
  { id: "tag", label: "Tag", width: "1fr" },
  { id: "menu", label: "", width: "4rem", textAlign: "center" },
];

export const TagsTable: FC<Props> = ({ tags, onRowOptionClick }) => {
  return (
    <TableGrid columnWidths={headers.map(({ width }) => width)}>
      {headers.map((item) => (
        <HeaderCell className={cx(item.textAlign === "center" ? "justify-center" : "")} key={item.id}>
          {item.label}
        </HeaderCell>
      ))}

      {tags.map((tag) => (
        <TagRow key={tag.id} tag={tag} onOptionClick={onRowOptionClick} />
      ))}
    </TableGrid>
  );
};
