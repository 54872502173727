import Dynamsoft from "dwt";
import { WebTwain } from "dwt/dist/types/WebTwain";
import { DeviceConfiguration } from "dwt/dist/types/WebTwain.Acquire";
import { noop } from "@libs/utils/noop";
import {
  CODE_USER_CANCELLED,
  DWT_IGNORABLE_ERROR_CODES,
  handleTwainError,
  TwainError,
} from "components/ImageCapturing/twainErrors";
import { NotFoundCallback, TwainEvent } from "components/ImageCapturing/types";
import type { EnvValues } from "env";
import { OnImageCaptured, TwainSource } from "./TwainContext";

export type { TwainSource } from "./TwainContext";

export const TWAIN_DEFAULT_RESOLUTION = 150;

const initialize = async (
  appEnv: EnvValues["REACT_APP_ENVIRONMENT"],
  twainHandshakeCode: string,
  elementId: string,
  onNotFound: (url: string) => void
) => {
  // TODO need some way to know if this fails at the very least set a timeout
  return new Promise<void>((resolve) => {
    const { DWT } = Dynamsoft;

    DWT.AutoLoad = false;
    DWT.Containers = [
      {
        WebTwainId: "dwtObject",
        ContainerId: elementId,
        Width: 270,
        Height: 350,
      },
    ];
    // Hides orange loading spinner, transparent pixel, base64:
    // More info: https://www.dynamsoft.com/web-twain/docs/indepth/features/ui.html?ver=latest#loading-bar-and-backdrop
    DWT.CustomizableDisplayInfo.loaderBarSource =
      "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=";

    if (appEnv === "development") {
      // Local dev:
      DWT.organizationID = twainHandshakeCode;
    } else {
      // For prod, preprod
      DWT.handshakeCode = twainHandshakeCode;
    }

    DWT.ResourcesPath = "/dwt-resources";
    DWT.IfAddMD5InUploadHeader = false;
    DWT.IfConfineMaskWithinTheViewer = false;
    DWT.RegisterEvent(TwainEvent.whenReady, () => {
      resolve();
    });

    // NOTE -- We currently are hiding ALL dynamsoft web UI elements via dynamsoft.css. This prevents their grey overlay from randomly popping up on page load.
    // if you want any of their UI to work, must first update that.

    // This below disables their loading spinner from displaying, (not related to dynamsoft.css)
    // See here: https://www.dynamsoft.com/web-twain/docs/indepth/faqs/develop/How-to-customize-the-installation-dialog.html
    const ConfigObject = Dynamsoft as unknown as {
      OnWebTwainNotFoundOnWindowsCallback?: NotFoundCallback;
      OnWebTwainNotFoundOnMacCallback?: NotFoundCallback;
      // eslint-disable-next-line @typescript-eslint/naming-convention
      _show_install_dialog: () => void;
    };

    ConfigObject._show_install_dialog = () => undefined;

    const _onNotFound = (_: string, url: string) => {
      onNotFound(url);
    };

    ConfigObject.OnWebTwainNotFoundOnWindowsCallback = _onNotFound;
    ConfigObject.OnWebTwainNotFoundOnMacCallback = _onNotFound;
    DWT.Load();
  });
};

export const selectSource = async (twain: WebTwain, index: number) => {
  // Note: We could use promises via SelectSourceByIndexAsync instead of SelectSourceByIndex,
  //       but by the time we get here the user will be physically taking the x-ray, so sync
  //       seems appropriate.

  // Current source isn't always defined (https://sentry.io/organizations/grindfoundry/issues/3901427721/), cast it as such.
  const sourceName = twain.CurrentSourceName as string | undefined;

  // Dentimax sensors freak out if they get too many calls at once while already an open source.
  // if dentimax is already selected and it's re-opened we don't want to call selectSource, as it could risk shutting down the machine
  const isDentimax = sourceName?.toLowerCase().includes("dentimax");

  if (!isDentimax || twain.GetSourceNameItems(index) !== twain.CurrentSourceName) {
    if (sourceName) {
      await twain.CloseSourceAsync();
    }

    await twain.SelectSourceByIndexAsync(index);
    await twain.OpenSourceAsync();
  }
};

const LOCAL_CONFIG_KEY = "GF_TWAIN_CONFIG";

export const beginXRayImageCaptureMode = (
  twain: WebTwain,
  pixelType: "grey" | "color" = "grey",
  onCancel?: Func
) => {
  let deviceConfig: DeviceConfiguration = {
    // Required to show the native driver ui
    IfShowUI: true,
    PixelType:
      pixelType === "grey"
        ? Dynamsoft.DWT.EnumDWT_PixelType.TWPT_GRAY
        : Dynamsoft.DWT.EnumDWT_PixelType.TWPT_RGB,
    // TODO Note: this value may be ignored by x-ray sensors but can be further
    // investigated.
    Resolution: TWAIN_DEFAULT_RESOLUTION,
    IfFeederEnabled: false,
    IfDuplexEnabled: false,
    // This is the key to getting a success callback when showUI is true but we
    // do not want to dismiss the native UI between images.
    IfDisableSourceAfterAcquire: false,
    // TODO possibly not necessary, what might we want from here? "camera" info?
    IfGetImageInfo: true,
    // TODO possibly not necessary, what might we want from here? "camera" info?
    IfGetExtImageInfo: true,
    extendedImageInfoQueryLevel: 0,
  };
  const localConfigString = localStorage.getItem(LOCAL_CONFIG_KEY);

  if (localConfigString) {
    // To allow easy testing
    deviceConfig = JSON.parse(localConfigString) as DeviceConfiguration;
  }

  twain.AcquireImage(deviceConfig, noop, (_, errorCode, errorString) => {
    if (!DWT_IGNORABLE_ERROR_CODES.has(errorCode)) {
      handleTwainError(
        new TwainError({
          errorCode,
          errorString,
          api: "AcquireImage",
        })
      );
    } else if (errorCode === CODE_USER_CANCELLED && onCancel) {
      onCancel();
    }

    console.error(`Error acquiring image: ${errorCode} - ${errorString}`);
  });
};

export const requestSources = async (twain: WebTwain): Promise<TwainSource[]> => {
  const includeDeviceDetails = true;

  // Allows access for both 32/64 bit drivers. The VA tech sensor is extremely slow when running 32 bit version on 64 bit machine
  twain.ImageCaptureDriverType = Dynamsoft.DWT.EnumDWT_Driver.TWAIN_AND_TWAIN64;

  const devices = await twain.GetSourceNamesAsync(includeDeviceDetails);

  return devices.map((device, index) => {
    const deviceName =
      typeof device === "string"
        ? device
        : (device.ProductName || `Device ${index}`) + (device.DriverType ? ` (${device.DriverType})` : "");

    return {
      index,
      label: deviceName,
      driverType: typeof device === "string" ? undefined : device.DriverType,
    };
  });
};

export const createTwain = async ({
  appEnv,
  twainHandshakeCode,
  elementId,
  onImageCaptured,
  onNotFound,
}: {
  appEnv: EnvValues["REACT_APP_ENVIRONMENT"];
  twainHandshakeCode: string;
  elementId: string;
  onImageCaptured: OnImageCaptured;
  onNotFound: (downloadUrl: string) => void;
}) => {
  /**
   * Capture an image from the selected device.
   * @returns Promise, resolved when image data becomes available.
   */

  await initialize(appEnv, twainHandshakeCode, elementId, onNotFound);

  const twain = Dynamsoft.DWT.GetWebTwain(elementId);

  twain.Viewer.hide();

  twain.RegisterEvent(TwainEvent.afterEach, (outputInfo: { imageId: number }) => {
    onImageCaptured(twain, TwainEvent.afterEach, outputInfo);
  });

  //https://www.dynamsoft.com/web-twain/docs/indepth/features/ui.html?ver=latest#loading-bar-and-backdrop
  Dynamsoft.DWT.OnWebTwainPreExecute = () => {
    return {};
  };
  Dynamsoft.DWT.OnWebTwainPostExecute = () => {
    return {};
  };

  return twain;
};
