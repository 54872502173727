import { FC, FormEventHandler, useCallback, useMemo, useState } from "react";
import { MountVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { pluralize } from "@libs/utils/pluralize";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { useAccount } from "@libs/contexts/AccountContext";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { FlyoverContent, FlyoverFooter, FlyoverForm } from "components/UI/FlyoverComponents";
import { getMountName } from "components/PatientProfile/Imaging/PatientMountsList";
import { searchForPatients } from "api/patients/queries";
import { Flyover } from "components/UI/Flyover";
import { TransferPatientSearch } from "components/PatientProfile/Imaging/PatientMountsList/TransferPatientSearch";

export const getPhotosCount = (mount: MountVO) => {
  return mount.types?.map((type) => Number.parseInt(type.split(" ")[0])).reduce((a, b) => a + b, 0) ?? 0;
};

const SEARCH_PAGE_SIZE = 10;

interface Props {
  isLoading: boolean;
  mount: MountVO;
  onClose: Func;
  onTransfer: (mountId: number, targetPatientId: number | undefined) => void;
  sourcePatientId: number;
}

export const TransferMountFlyover: FC<Props> = ({
  isLoading,
  mount,
  onClose,
  onTransfer,
  sourcePatientId,
}) => {
  const { practiceId } = useAccount();
  const [targetPatientId, setTargetPatientId] = useState<number>();
  const [searchString, setSearchString] = useState("");
  const { search: debouncedSearchString } = useDebouncedSearch(searchString);

  const [searchPatientsQuery] = useApiQueries([
    searchForPatients({
      args: {
        pageNumber: 1,
        pageSize: SEARCH_PAGE_SIZE,
        practiceId,
        searchString: debouncedSearchString,
      },
      queryOptions: { enabled: debouncedSearchString.length > 0 },
    }),
  ]);

  const patientSearchResults = (searchPatientsQuery.data ?? []).filter(
    (patient) => patient.id !== sourcePatientId
  );

  const isSearching = useMemo(
    () => searchPatientsQuery.isLoading || searchPatientsQuery.isFetching,
    [searchPatientsQuery]
  );

  const photoCount = useMemo(() => getPhotosCount(mount), [mount]);

  const handleSubmit: FormEventHandler = useCallback(
    (e) => {
      e.preventDefault();
      onTransfer(mount.id, targetPatientId);
    },
    [mount, onTransfer, targetPatientId]
  );

  return (
    <Flyover title="Transfer Mount" onClose={onClose} size="md">
      {({ close }) => (
        <FlyoverForm className="text-sm" fieldLayout="labelOut" onSubmit={handleSubmit}>
          <FlyoverContent>
            <div className="flex flex-col gap-y-6">
              <div className="flex">
                <span className="font-sansSemiBold">{getMountName(mount)}</span>&nbsp;&bull;&nbsp;
                {formatISODate(mount.date)}&nbsp;&bull;&nbsp;
                {`${photoCount} ${pluralize(photoCount, "photo", "photos")}`}
              </div>
              <div className="flex flex-col gap-y-2">
                Select a patient to transfer mount to
                <TransferPatientSearch
                  isSearching={isSearching}
                  onClearSelected={() => setTargetPatientId(undefined)}
                  onSearch={setSearchString}
                  onSelect={setTargetPatientId}
                  patientSearchResults={patientSearchResults}
                  searchString={searchString}
                />
              </div>
            </div>
          </FlyoverContent>
          <FlyoverFooter>
            <Button className="min-w-button" onClick={close} theme="secondary">
              Cancel
            </Button>
            <AsyncButton
              className="min-w-button"
              disabled={!targetPatientId}
              isLoading={isLoading}
              type="submit"
            >
              Transfer Mount
            </AsyncButton>
          </FlyoverFooter>
        </FlyoverForm>
      )}
    </Flyover>
  );
};
