import React, { FC } from "react";

import { TimeSeriesResponse } from "@libs/api/generated-api";
import { AppointmentBookingType } from "components/Dashboard/AppointmentsCompleted/types";
import { CHART_COLORS, ColorScheme } from "components/Dashboard/Charting/theme";
import { useTimeseriesTemplateForResolution } from "components/Dashboard/hooks/useTimeseriesTemplateForResolution";
import { TimeSeriesResolutionOption } from "components/Dashboard/types";
import { useBarStackDecorators } from "components/Dashboard/hooks/useBarStackDecorators";
import { useTimeseriesAsStacks } from "components/Dashboard/Charting/hooks/useTimeseriesAsStacks";
import { labelForAppointmentBookingType } from "components/Dashboard/utils/labelForAppointmentBookingType";
import { TimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { DashboardChartDisplay, DashboardQueryWithDateNavigation } from "utils/routing/dashboard";
import { DashboardTimeseriesChart } from "components/Dashboard/Charting/DashboardTimeseriesChart";
import { useFocusDateClick } from "components/Dashboard/hooks/useFocusDateClick";
import { useTimeseriesPreviousData } from "components/Dashboard/Charting/hooks/useTimeseriesPreviousData";

const appointmentBookingKeys: AppointmentBookingType[] = ["NO_SHOW", "SCHEDULED", "COMPLETED", "CANCELED"];
const colorScheme: ColorScheme<AppointmentBookingType> = {
  domain: appointmentBookingKeys,
  range: [CHART_COLORS.blue, CHART_COLORS.lavender, CHART_COLORS.blueBright, CHART_COLORS.greenBright],
};

const useTimeSeriesAppointmentsTreatedData = ({
  resolution,
  isLoading,
  timeSeries,
  dateWindow,
}: {
  isLoading: boolean;
  resolution: TimeSeriesResolutionOption;
  timeSeries?: TimeSeriesResponse;
  dateWindow: TimeSeriesPageSelections;
}) => {
  const timeline = useTimeseriesTemplateForResolution({ resolution, dateWindow });
  const data = useTimeseriesAsStacks(timeline, appointmentBookingKeys, isLoading ? undefined : timeSeries);
  const dataWithLoadingData = useTimeseriesPreviousData({
    data,
    isLoading,
  });

  return {
    ...dataWithLoadingData,
  };
};

export const AppointmentsCompletedChartContent: FC<{
  width: number;
  height: number;
  resolution: TimeSeriesResolutionOption;
  timeSeries?: TimeSeriesResponse;
  isLoading: boolean;
  focusDate?: Date;
  dateWindow: TimeSeriesPageSelections;
  chartDisplay: DashboardChartDisplay;
  onUpdateParams: (update: Partial<DashboardQueryWithDateNavigation>) => void;
}> = React.memo(
  ({
    width,
    height,
    resolution,
    isLoading,
    timeSeries,
    focusDate,
    onUpdateParams,
    dateWindow,
    chartDisplay,
  }) => {
    const { data, isFirstLoad } = useTimeSeriesAppointmentsTreatedData({
      resolution,
      isLoading,
      dateWindow,
      timeSeries,
    });
    const barDecorators = useBarStackDecorators(data, focusDate, resolution);
    const handleDateClicked = useFocusDateClick({ handleParamsChange: onUpdateParams, focusDate });

    return (
      <DashboardTimeseriesChart
        data={data}
        resolution={resolution}
        timeSegment={dateWindow}
        width={width}
        height={height}
        chartDisplay={chartDisplay}
        isLoading={isLoading}
        colorScheme={colorScheme}
        keys={appointmentBookingKeys}
        randomizeLoadingBars={isFirstLoad}
        onClickDate={handleDateClicked}
        isCurrency={false}
        labelFormat={labelForAppointmentBookingType}
        {...barDecorators}
      />
    );
  }
);

AppointmentsCompletedChartContent.displayName = "AppointmentsCompletedChartContent";
