import { FC } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { AppointmentVO, PatientVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getFullUrl } from "@libs/utils/location";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ButtonMenu } from "@libs/components/UI/ButtonMenu";
import { MenuOptionButton } from "@libs/components/UI/MenuOptionButton";
import { ReactComponent as MenuIcon } from "@libs/assets/icons/menu-vertical.svg";
import { paths } from "utils/routing/paths";

export const AppointmentMenu: FC<{
  patientId: PatientVO["id"];
  appointmentId: AppointmentVO["id"];
  canCreateInvoice: boolean;
}> = ({ patientId, appointmentId, canCreateInvoice }) => {
  const navigate = useNavigate();
  const location = useLocation();

  return (
    <AppointmentMenuDropdown
      onEditAppointment={() =>
        navigate(paths.editAppointment({ appointmentId, patientId }, { from: getFullUrl(location) }))
      }
      canCreateInvoice={canCreateInvoice}
      onCreateInvoiceClick={() =>
        navigate(paths.createInvoice({ patientId }, { from: getFullUrl(location) }))
      }
    />
  );
};

const AppointmentMenuDropdown: FC<{
  onEditAppointment: Func;
  canCreateInvoice: boolean;
  onCreateInvoiceClick: Func;
}> = ({ onEditAppointment, canCreateInvoice, onCreateInvoiceClick }) => {
  const menu = useBoolean(false);

  return (
    <ButtonMenu
      isOpen={menu.isOn}
      onRequestOpen={menu.on}
      placement="bottom-start"
      menuContent={
        <>
          <MenuOptionButton
            onClick={() => {
              onEditAppointment();
              menu.off();
            }}
          >
            Edit Appointment
          </MenuOptionButton>
          {canCreateInvoice && (
            <MenuOptionButton
              onClick={() => {
                onCreateInvoiceClick();
                menu.off();
              }}
            >
              Create Invoice
            </MenuOptionButton>
          )}
        </>
      }
      onRequestClose={menu.off}
    >
      {(props) => <ButtonIcon {...props} size="md" SvgIcon={MenuIcon} />}
    </ButtonMenu>
  );
};
