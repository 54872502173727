import React, { FormEventHandler, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FeeScheduleListVO, FeeScheduleVO, ProviderVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { flattenPages, getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as AddIcon } from "@libs/assets/icons/plus-circle.svg";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { QueryFilters } from "@libs/components/UI/QueryFilters";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { paths } from "utils/routing/paths";
import { useQueryParams } from "hooks/useQueryParams";
import {
  FeeSchedulesDetailsQuery,
  FeeSchedulesQuery,
  FeeSchedulesQueryUpdates,
} from "utils/routing/settings";
import { getFeeSchedulesFilterProps } from "components/Settings/FeeSchedules/feeSchedulesQuery";
import { getInfiniteFeeSchedulesV2Query } from "api/feeSchedule/queries";
import { FeeSchedulesTable } from "components/Settings/FeeSchedules/Table";
import { useItemModal } from "hooks/useItemModal";
import { handleError } from "utils/handleError";
import { archiveFeeSchedule, unarchiveFeeSchedule } from "api/feeSchedule/mutations";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { FeeSchedulesFilterFlyover } from "components/Settings/FeeSchedules/FilterFlyover";
import { getAllInsuranceCarriersQuery } from "api/practiceInsurance/queries";
import { FormFieldSelect } from "components/UI/FormFieldSelect";
import { QueryFiltersFlyover } from "components/UI/QueryFiltersFlyover";
import { SettingsListPanel } from "components/Settings/SettingsListPanel";

const PAGE_SIZE = 50;
const TWO = 2;

export const getInNetworkQueryValue = (inNetwork: string[] | undefined) => {
  if (inNetwork === undefined || inNetwork.length === TWO) {
    return undefined;
  }

  return inNetwork[0] === "true";
};

// eslint-disable-next-line max-statements
export const FeeSchedulesRoute: React.FC = () => {
  const { practiceId } = useAccount();
  const navigate = useNavigate();

  const { query, updateQuery } = useQueryParams("feeSchedules");
  const [queryForFilters, setQueryForFilters] = useState<FeeSchedulesQuery>(query);

  const handleFiltersQueryParamChange = useCallback((keyValues: FeeSchedulesQueryUpdates) => {
    setQueryForFilters((last) => ({ ...last, ...keyValues }));
  }, []);

  const initialLoadQueryRef = useRef(false);

  const handleQueryParamChange = useCallback(
    (keyValues: FeeSchedulesQueryUpdates) => {
      updateQuery("replaceIn", keyValues);
      handleFiltersQueryParamChange(keyValues);
    },
    [handleFiltersQueryParamChange, updateQuery]
  );

  // The first time we go to the fee schedules page, we want to set the default
  // query params for states to be ACTIVE. However, if they paste a URL w/
  // queries, we ignore this.
  useEffect(() => {
    if (!query.states && !Object.values(query).some(Boolean) && !initialLoadQueryRef.current) {
      handleQueryParamChange({ states: ["ACTIVE"] });
      initialLoadQueryRef.current = true;
    }
  }, [handleQueryParamChange, query, query.states]);

  // Sanitize inNetwork query param so we can set it properly in filtering
  useEffect(() => {
    if (query.inNetwork) {
      const filtered = new Set(query.inNetwork.filter((val) => val === "true" || val === "false"));

      handleQueryParamChange({ inNetwork: [...filtered] });
    }
  }, [handleQueryParamChange, query.inNetwork]);

  const handleNavigateToDetailsPage = useCallback(
    (feeScheduleId: number | "new", queryParams?: FeeSchedulesDetailsQuery) => {
      navigate(paths.feeScheduleDetails({ feeScheduleId }, queryParams));
    },
    [navigate]
  );

  const [carriersQuery, providersQuery] = useApiQueries([
    getAllInsuranceCarriersQuery({ args: { practiceId } }),
    getPracticeProvidersQuery({
      args: { practiceId },
    }),
  ]);

  const filterProps = useMemo(() => {
    return getFeeSchedulesFilterProps(carriersQuery.data, providersQuery.data, query);
  }, [carriersQuery.data, providersQuery.data, query]);

  const feeSchedulesQueryKey = useMemo(
    () => ({
      ...query,
      inNetwork: getInNetworkQueryValue(query.inNetwork),
      states: query.states as FeeScheduleListVO["state"][],
      types: (query.types
        ? query.types.includes("PROVIDER_UCR")
          ? [...query.types, "PRACTICE_UCR"]
          : query.types
        : undefined) as FeeScheduleListVO["type"][],
      orderBy: "ASCENDING",
    }),
    [query]
  );

  const feeSchedulesQuery = useInfiniteApiQuery(
    getInfiniteFeeSchedulesV2Query({
      args: {
        ...feeSchedulesQueryKey,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        practiceId,
      },
      queryOptions: { keepPreviousData: true },
    })
  );

  const feeSchedules = flattenPages(feeSchedulesQuery.data);

  const totalElements = getInfiniteQueryPagingDetails(feeSchedulesQuery.data)?.totalElements || 0;

  const providersByIdMap = useMemo(() => {
    const providersById: Record<number, ProviderVO | undefined> = {};

    providersQuery.data?.forEach((provider) => {
      providersById[provider.id] = provider;
    });

    return providersById;
  }, [providersQuery.data]);

  const flyover = useBoolean(false);
  const closeFlyover = flyover.off;
  const applyFilters: FormEventHandler<HTMLFormElement> = useCallback(
    (e) => {
      e.preventDefault();

      handleQueryParamChange(queryForFilters);

      closeFlyover();
    },
    [handleQueryParamChange, queryForFilters, closeFlyover]
  );

  const archiveModal = useItemModal<FeeScheduleVO>(null);

  const handleCopyClick = useCallback(
    (feeScheduleToCopyId: number) => {
      handleNavigateToDetailsPage("new", { copyId: feeScheduleToCopyId });
    },
    [handleNavigateToDetailsPage]
  );

  const feeScheduleArchiveOptions = useMemo(() => {
    return (
      feeSchedules
        ?.filter((feeSchedule) => feeSchedule.id !== archiveModal.item?.id)
        .map((feeSchedule) => ({
          label: feeSchedule.name,
          value: feeSchedule.id,
        })) ?? []
    );
  }, [archiveModal.item?.id, feeSchedules]);

  const practiceUcrFeeScheduleId = useMemo(() => {
    return feeSchedules?.[0]?.id;
  }, [feeSchedules]);

  const [replacementFeeScheduleId, setReplacementFeeScheduleId] = useState<number | undefined>(undefined);

  useEffect(() => {
    if (!replacementFeeScheduleId && practiceUcrFeeScheduleId) {
      setReplacementFeeScheduleId(practiceUcrFeeScheduleId);
    }
  }, [practiceUcrFeeScheduleId, replacementFeeScheduleId]);

  const [archiveFeeScheduleMutation, unarchiveFeeScheduleMutation] = useApiMutations([
    archiveFeeSchedule,
    unarchiveFeeSchedule,
  ]);

  const handleArchiveFeeSchedule = useCallback(
    (feeSchedule: FeeScheduleVO, replacementId: number | undefined) => {
      if (replacementId) {
        archiveFeeScheduleMutation.mutate(
          { feeScheduleId: feeSchedule.id, practiceId, data: { replacementFeeScheduleId: replacementId } },
          {
            onError: handleError,
            onSuccess: () => {
              archiveModal.close();
              setReplacementFeeScheduleId(undefined);
            },
          }
        );
      }
    },
    [archiveFeeScheduleMutation, archiveModal, practiceId]
  );

  const handleArchiveClick = useCallback(
    (feeSchedule: FeeScheduleVO) => {
      if (feeSchedule.insurancePlans || feeSchedule.patients) {
        archiveModal.open(feeSchedule);
      } else {
        handleArchiveFeeSchedule(feeSchedule, practiceUcrFeeScheduleId);
      }
    },
    [archiveModal, handleArchiveFeeSchedule, practiceUcrFeeScheduleId]
  );

  const handleUnarchiveClick = useCallback(
    async (feeScheduleId: number) => {
      try {
        await unarchiveFeeScheduleMutation.mutateAsync({ feeScheduleId, practiceId });
      } catch (e) {
        handleError(e);
      }
    },
    [practiceId, unarchiveFeeScheduleMutation]
  );

  return (
    <SettingsListPanel
      title={
        <div className="flex items-center">
          <span>Fee Schedules</span>
          <ButtonIcon
            className="p-1"
            SvgIcon={AddIcon}
            onClick={() => handleNavigateToDetailsPage("new")}
            theme="primary"
          />
        </div>
      }
    >
      <QueryFilters
        onOpenFlyover={flyover.on}
        onUpdateParams={handleQueryParamChange}
        queries={[feeSchedulesQuery]}
        searchParamKey="searchString"
        totalElements={totalElements}
        {...filterProps}
      />
      <QueryResult queries={[providersQuery]}>
        {providersQuery.data && (
          <FeeSchedulesTable
            feeSchedulesQuery={feeSchedulesQuery}
            feeSchedulesQueryKey={feeSchedulesQueryKey}
            onArchive={handleArchiveClick}
            onCopy={handleCopyClick}
            onEdit={handleNavigateToDetailsPage}
            onUnarchive={handleUnarchiveClick}
            providersByIdMap={providersByIdMap}
          />
        )}
      </QueryResult>
      {flyover.isOn && (
        <QueryFiltersFlyover
          content={
            <FeeSchedulesFilterFlyover
              carriersQuery={carriersQuery}
              onUpdateFilters={handleFiltersQueryParamChange}
              providersQuery={providersQuery}
              queryState={queryForFilters}
            />
          }
          isSubmitting={feeSchedulesQuery.isFetching}
          onClose={closeFlyover}
          onSubmit={applyFilters}
        />
      )}
      {archiveModal.isOpen && (
        <ConfirmationModal
          onCancel={() => {
            archiveModal.close();
            setReplacementFeeScheduleId(undefined);
          }}
          isConfirming={archiveFeeScheduleMutation.isLoading}
          onConfirm={() => handleArchiveFeeSchedule(archiveModal.item, replacementFeeScheduleId)}
          primaryText="Archive Fee Schedule"
          secondaryText={`This fee schedule is currently used by ${archiveModal.item.insurancePlans} insurance plans. Which fee schedule would you like these plans to use now?`}
          size="2xs"
          textAlign="text-left"
        >
          <div className="mt-6">
            <FormFieldSelect
              isSearchable={false}
              isClearable={false}
              options={feeScheduleArchiveOptions}
              onItemSelected={(newValue) => setReplacementFeeScheduleId(Number(newValue))}
              value={replacementFeeScheduleId}
            />
          </div>
        </ConfirmationModal>
      )}
    </SettingsListPanel>
  );
};
