import { FC, ReactNode } from "react";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info.svg";

export const PerioChartKeyboardShortcuts: FC = () => (
  <div>
    <FloatingTooltip content={<KeyboardShortcuts />}>
      <div>
        <Icon SvgIcon={InfoIcon} theme="slate700" />
      </div>
    </FloatingTooltip>
  </div>
);

const KEYBOARD_SHORTCUTS = [
  { shortcut: "p", description: "Plaque" },
  { shortcut: "c", description: "Calculus" },
  { shortcut: "s", description: "Suppuration" },
  { shortcut: "b", description: "Bleeding" },
  { shortcut: "+", description: "+10" },
  { shortcut: "g", description: "Toggle Gin Margin +" },
  { shortcut: "tab", description: "Forward" },
  { shortcut: "shift+tab", description: "Backward" },
];

/**
 * Displays a list of keyboard shortcuts and their descriptions.
 */
const KeyboardShortcuts: FC = () => (
  <div className="flex flex-col gap-y-4 p-1">
    <div className="text-xs font-sansSemiBold">Keyboard Shortcuts</div>
    <div className="flex flex-col gap-y-4">
      {KEYBOARD_SHORTCUTS.map((shortcut) => (
        <Shortcut key={shortcut.shortcut} {...shortcut} />
      ))}
    </div>
  </div>
);

/**
 * Displays a single keyboard shortcut and its description.
 */
const Shortcut: FC<{ shortcut: ReactNode; description: ReactNode }> = ({ shortcut, description }) => (
  <div className="text-xs">
    <span className="font-sansSemiBold">{shortcut}</span> = {description}
  </div>
);
