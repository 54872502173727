import React from "react";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { ActionModal } from "components/UI/ActionModal";
import { MountModalSection } from "components/PatientProfile/Imaging/PatientMountsList/MountModalSection";
import { MountLayoutType } from "api/imaging/imaging-api";

type Props = {
  isSaving: boolean;
  mountName?: string;
  onCancel: () => void;
  onDone: (name: string, type?: MountLayoutType) => void;
};

export const EditMountModal: React.FC<Props> = ({ isSaving, mountName = "", onCancel, onDone }) => {
  const [newMountName, setNewMountName] = React.useState<string>(mountName);

  return (
    <ActionModal
      handleClose={onCancel}
      handleSave={() => onDone(newMountName)}
      isLoading={isSaving}
      primaryButtonDisabled={!newMountName.trim()}
      primaryButtonText="Save"
      size="2xs"
      title="Edit Mount"
    >
      <div className="flex flex-col gap-4">
        <MountModalSection title="Name">
          <FormFieldInput
            className="w-full"
            layout="labelOut"
            onChange={(event) => setNewMountName(event.target.value)}
            placeholder="Optional"
            value={newMountName}
          />
        </MountModalSection>
      </div>
    </ActionModal>
  );
};
