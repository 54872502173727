import React from "react";
import { CollectedPaymentEntryVO, CollectedPaymentTotalVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getAbsoluteUrl } from "@libs/utils/location";
import { HeaderData } from "components/Dashboard/Tables/types";
import { useDownloadQueryPages } from "components/Dashboard/hooks/useDownloadInfiniteQuery";
import { getTotals, MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";
import { EMPTY_ROW, formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";
import { handleError } from "utils/handleError";
import { usePaymentCollectedQuery } from "components/Dashboard/PaymentCollected/hooks/usePaymentCollectedQuery";
import { paths } from "utils/routing/paths";
import { useQueryParams } from "hooks/useQueryParams";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { BY_PATIENT_TABLE_HEADERS } from "components/Dashboard/PaymentCollected/constants";
import { useCurrentUrl, useOrigin } from "contexts/OriginContext";

const formatPaymentCollectedAsCsv = (
  rows: CollectedPaymentEntryVO[],
  totals: CollectedPaymentTotalVO,
  headers: HeaderData[],
  origin: string
) => {
  const columnTitles = formatCsvRow([...headers.map(({ label }) => label).filter(Boolean), "Patient URL"]);
  const rowsContent = rows.map((row) =>
    formatCsvRow([
      row.patient.shortDisplayName,
      row.paymentDate,
      row.payerType,
      row.paymentType,
      formatCurrency(row.paymentAmount),
      getAbsoluteUrl(origin, paths.patient({ patientId: row.patient.id })),
    ])
  );

  const totalsContent = formatCsvRow(["", "", "", "Grand Total:", formatCurrency(totals.totalPaymentAmount)]);

  return [columnTitles, ...rowsContent, EMPTY_ROW, totalsContent];
};

export const useDownloadPaymentCollectedByPatientCSV = () => {
  const downloading = useBoolean(false);
  const { query } = useQueryParams("dashboardPaymentCollected");
  const { selectedTimeSegment } = useTimeSeriesPageSelections(query);

  const { collectedPaymentQuery } = usePaymentCollectedQuery({
    pageSize: MAX_PAGE_SIZE,
    enabled: downloading.isOn,
  });
  const { startDownload: downloadPaymentCollecteData } = useDownloadQueryPages(collectedPaymentQuery);
  const origin = useOrigin();
  const currentUrl = useCurrentUrl();

  return {
    isDownloading: downloading.isOn,
    downloadCSV: React.useCallback(async () => {
      downloading.on();

      try {
        const result = await downloadPaymentCollecteData();
        const rows = result.pages.flatMap((item) => item.data.entries);
        const totals = getTotals(result);

        if (totals) {
          const docMetadata = getArchyCsvMetadata(currentUrl, selectedTimeSegment);
          const csvRows = [
            ...docMetadata.headers,
            ...formatPaymentCollectedAsCsv(rows, totals, BY_PATIENT_TABLE_HEADERS, origin),
          ];

          saveCsv(csvRows, `PaymentCollected_${docMetadata.fileNameTag}`);
        }
      } catch (e) {
        handleError(e);
      }

      downloading.off();
    }, [downloading, downloadPaymentCollecteData, selectedTimeSegment, origin, currentUrl]),
  };
};
