import { FC } from "react";
import { MultiValueGenericProps, OptionProps, components } from "react-select";
import { AppointmentVO, DentalProcedureVO, PatientProcedureVO } from "@libs/api/generated-api";
import { formatISODate, formatLongDayOfYear, formatShortAmPmTime, getLocalDate } from "@libs/utils/date";
import { UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { RadioList } from "@libs/components/UI/RadioList";
import { CollapsibleSection } from "components/UI/CollapsibleSection";
import { FormFieldMultiSelect } from "components/UI/FormFieldMultiSelect";
import { useInfiniteScrollingSelectComponents } from "hooks/useInfiniteScrollingSelectComponents";
import { formatProviderNames } from "components/ScheduleAppointments/utils";
import { ProcedurePillsWithOverflow } from "components/LabCases/LabComponents";
import { FormSection } from "components/LabCases/FormSection";

export type SimpleProcedure = {
  id: number;
  cdtCode?: string;
  procedureArea?: string;
  displayName: string;
  description: string;
};

interface ProcedureOption extends SelectOption<number> {
  procedure: SimpleProcedure;
}

interface AppointmentOption extends SelectOption<number> {
  appointment: AppointmentVO;
}

interface CustomMultiValueGenericProps<V extends SelectOptionValue, T extends SelectOption<V>>
  extends MultiValueGenericProps<T, true, GroupedSelectOption<V, T>> {
  data: T;
}

type CustomOptionProps<V extends SelectOptionValue, T extends SelectOption<V>> = OptionProps<
  T,
  true,
  GroupedSelectOption<V, T>
>;

const SelectedProcedureLabel = (props: CustomMultiValueGenericProps<number, ProcedureOption>) => (
  <components.MultiValueLabel {...props}>
    <div className="flex items-center gap-x-1">
      <div>{props.data.procedure.displayName}</div>
    </div>
  </components.MultiValueLabel>
);

const ProcedureMenuOption = (props: CustomOptionProps<number, ProcedureOption>) => (
  <components.Option {...props}>
    <div className="grid grid-cols-[min-content,3rem,1fr] gap-x-4 max-w-md">
      <div>{props.data.procedure.cdtCode}</div>
      <div className="truncate" title={props.data.procedure.procedureArea}>
        {props.data.procedure.procedureArea}
      </div>
      <div className="truncate" title={props.data.procedure.description}>
        {props.data.procedure.description}
      </div>
    </div>
  </components.Option>
);

const SelectedAppointmentLabel = (props: CustomMultiValueGenericProps<number, AppointmentOption>) => (
  <components.MultiValueLabel {...props}>
    <div className="flex items-center gap-x-1">
      <div>{formatISODate(props.data.appointment.date)}</div>
    </div>
  </components.MultiValueLabel>
);

const AppointmentMenuOption = (props: CustomOptionProps<number, AppointmentOption>) => {
  const appointment = props.data.appointment;
  const apptDate = getLocalDate(appointment.date);

  return (
    <components.Option {...props}>
      <div className="flex flex-col gap-y-2 max-w-md">
        <div>
          <span className="font-sansSemiBold">{formatLongDayOfYear(apptDate)}</span> •{" "}
          {formatShortAmPmTime(apptDate)} • {formatProviderNames(appointment, "fullDisplayName")}
        </div>
        <ProcedurePillsWithOverflow procedures={appointment.patientProcedures} />
      </div>
    </components.Option>
  );
};

export const ProceduresPicker: FC<{
  required: boolean;
  proceduresInfiniteQuery: UseInfiniteApiListQueryResult<PatientProcedureVO>;
  isLoadingAppointments: boolean;
  isLoadingPatientProcedures: boolean;
  selectProceduresFrom: "appointments" | "treatment-plan" | undefined;
  selectedAppointmentIds?: AppointmentVO["id"][];
  selectedProcedureIds?: DentalProcedureVO["id"][];
  appointmentOptions: AppointmentOption[];
  procedureOptions: ProcedureOption[];
  errors: { selectedProcedureIds?: string; selectedAppointmentIds?: string };
  onSelectProceduresFromChange: (value: "appointments" | "treatment-plan") => void;
  onAppointmentsChange: (value: AppointmentVO["id"][]) => void;
  onProceduresChange: (value: DentalProcedureVO["id"][]) => void;
}> = ({
  required,
  proceduresInfiniteQuery,
  isLoadingAppointments,
  isLoadingPatientProcedures,
  selectProceduresFrom,
  appointmentOptions,
  procedureOptions,
  selectedAppointmentIds,
  selectedProcedureIds,
  errors,
  onSelectProceduresFromChange,
  onAppointmentsChange,
  onProceduresChange,
}) => {
  const procedureMenuCustomComponents = useInfiniteScrollingSelectComponents<ProcedureOption, true, number>({
    infiniteQuery: proceduresInfiniteQuery,
  });

  return (
    <FormSection title="Procedure" cols="2">
      <div className="flex flex-col gap-y-6 col-span-full">
        {/* div used to keep error message close to radio input to not be affected by parent gap */}
        <div>
          <RadioList
            label="Attach to a Procedure"
            required={required}
            selectedValue={selectProceduresFrom}
            layout="horiz"
            options={[
              { label: "From Appointments", value: "appointments" },
              { label: "From Treatment Plan", value: "treatment-plan" },
            ]}
            onChange={(e, option) => onSelectProceduresFromChange(option.value)}
          />
        </div>
        <CollapsibleSection adjustVerticalGap isOpen={selectProceduresFrom === "appointments"}>
          <FormFieldMultiSelect
            isLoading={isLoadingAppointments}
            label="Select Appointments"
            value={selectedAppointmentIds}
            noOptionsMessage={() => "No upcoming appointments found"}
            options={appointmentOptions}
            error={errors.selectedAppointmentIds}
            required={required}
            onItemsSelected={onAppointmentsChange}
            components={{ MultiValueLabel: SelectedAppointmentLabel, Option: AppointmentMenuOption }}
          />
        </CollapsibleSection>
        <CollapsibleSection
          adjustVerticalGap
          isOpen={Boolean(selectedAppointmentIds?.length) || selectProceduresFrom === "treatment-plan"}
        >
          <FormFieldMultiSelect<number, ProcedureOption>
            label="Select Procedures"
            isLoading={isLoadingPatientProcedures}
            value={selectedProcedureIds}
            options={procedureOptions}
            noOptionsMessage={() => "No planned or scheduled procedures found"}
            required={required || Boolean(selectedAppointmentIds?.length)}
            error={errors.selectedProcedureIds}
            onItemsSelected={onProceduresChange}
            components={{
              ...procedureMenuCustomComponents,
              MultiValueLabel: SelectedProcedureLabel,
              Option: ProcedureMenuOption,
            }}
          />
        </CollapsibleSection>
      </div>
    </FormSection>
  );
};
