import { useCallback, useState } from "react";
import { Button } from "@libs/components/UI/Button";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { OptionInputOption } from "@libs/components/UI/OptionInputList";
import { Form } from "@libs/components/UI/Form";
import { filtersCxStyles } from "components/Claims/Claims/Filters";
import { ClaimInsuranceOrdinal } from "components/Claims/types";
import { ClaimsQuery, ClaimsQueryUpdates } from "utils/routing/claims";

interface Props {
  onFiltersUpdate: (keyValues: ClaimsQueryUpdates) => void;
  onRequestClose: Func;
  queryStateApi: ClaimsQuery;
}

const ORDER_OPTIONS: OptionInputOption<ClaimInsuranceOrdinal>[] = [
  { label: "Primary", value: "PRIMARY" },
  { label: "Secondary", value: "SECONDARY" },
  { label: "Other", value: "OTHER" },
];

export const FilterMenuInsuranceOrders: React.FC<Props> = ({
  onFiltersUpdate,
  onRequestClose,
  queryStateApi,
}) => {
  const [selectedOrders, setSelectedOrders] = useState(new Set(queryStateApi.insuranceOrdinals));

  const handleApplyFilter = useCallback(
    (e: React.FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      onFiltersUpdate({ insuranceOrdinals: [...selectedOrders] });
      onRequestClose();
    },
    [onFiltersUpdate, selectedOrders, onRequestClose]
  );

  return (
    <Form className={filtersCxStyles.menuWrapper} onSubmit={handleApplyFilter}>
      <CheckboxList
        className={filtersCxStyles.menuContent}
        layout="vert"
        onChange={(newSet) => setSelectedOrders(newSet)}
        options={ORDER_OPTIONS}
        selectedValues={selectedOrders}
      />
      <div className="mt-2 px-4">
        <Button className="w-full" type="submit">
          Apply Filters
        </Button>
      </div>
    </Form>
  );
};
