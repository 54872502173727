import React, { ReactNode } from "react";

export const Section: React.FC<{ children?: ReactNode; title: string; className?: string }> = ({
  children,
  title,
  className,
}) => (
  <div className={className}>
    <div className="text-sm font-sansSemiBold mb-6">{title}</div>
    <div className="grid grid-cols-2 gap-6">{children}</div>
  </div>
);
