/* eslint-disable @typescript-eslint/no-explicit-any */
import React from "react";
import { cxTableStyles } from "components/Dashboard/Tables/cxTableStyles";
import { DashboardQuery } from "utils/routing/dashboard";
import { DashboardTableRightControls } from "components/Dashboard/Tables/DashboardTableRightControls";

type Props = {
  title: string;
  children?: React.ReactNode;
  onClickDownload?: Func;
  isDownloading?: boolean;
  onClickPrint?: Func;
  isPrinting?: boolean;
  downloadCsvDisabled?: boolean;
  includePatientSearch?: boolean;
  includeExpandTable?: boolean;
  query?: Partial<Omit<DashboardQuery, "focusDate">>;
  id: string;
  onUpdateParams: (updates: Partial<DashboardQuery>) => void;
};
export const DashboardTableHeader: React.FC<Props> = ({ title, ...rest }) => {
  return (
    <div className={cxTableStyles.tableHeading(false)}>
      <div className={cxTableStyles.tableHeadingText}>{title}</div>
      <DashboardTableRightControls {...rest} />
    </div>
  );
};
