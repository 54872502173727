import { FC, useMemo } from "react";
import { Route } from "react-router-dom";
import { SentryRoutes } from "components/UI/SentryRoutes";
import { ChartingRoute } from "components/Charting/ChartingRoute";
import { NotFound } from "components/NotFoundRoute";
import { PatientErxRoute } from "components/PatientProfile/Erx/PatientErxRoute";
import { PatientDocuments } from "components/PatientProfile/Documents/PatientDocuments";
import { PatientClaimsRoute } from "components/PatientProfile/Claims/PatientClaimsRoute";
import { MedicalHistoryApp } from "components/PatientProfile/MedicalHistory/MedicalHistoryApp";
import { PatientFormsRoute } from "components/PatientProfile/Forms/PatientFormsRoute";
import type { RouteIds } from "components/PatientProfile/types";
import { PatientLabCasesRoute } from "components/PatientProfile/LabCases/PatientLabCasesRoute";
import { RoleGuardLock } from "components/Main/RoleGuard";
import { patientRoutePermissions } from "components/PatientProfile/routes";
import { PatientOverview } from "./PatientOverview";
import { PatientMountsListRoute } from "./Imaging/PatientMountsList/route";
import { PatientInsurancePage } from "./Insurance";
import { PatientCommunicationRoute } from "./Communication/PatientCommunicationRoute";
import { Billing } from "./Billing/Billing";

type RouteConfig = {
  id: RouteIds;
  path?: string;
  index?: boolean;
  element: JSX.Element;
};

export const PatientProfileContent: FC<{ showErx: boolean }> = ({ showErx }) => {
  const patientRoutes = useMemo(() => {
    const availableRoutes: RouteConfig[] = [
      {
        id: "overview",
        index: true,
        element: <PatientOverview />,
      },
      {
        id: "edit",
        path: "edit",
        element: <PatientOverview />,
      },
      {
        id: "medical-history",
        path: "medical-history/*",
        element: <MedicalHistoryApp />,
      },
      {
        id: "insurance",
        path: "insurance/*",
        element: <PatientInsurancePage />,
      },
      {
        id: "claims",
        path: "claims/*",
        element: <PatientClaimsRoute />,
      },
      {
        id: "lab-cases",
        path: "lab-cases",
        element: <PatientLabCasesRoute />,
      },
      {
        id: "charting",
        path: "charting/*",
        element: <ChartingRoute />,
      },
      {
        id: "imaging",
        path: "imaging/*",
        element: <PatientMountsListRoute />,
      },
      {
        id: "documents",
        path: "documents",
        element: <PatientDocuments />,
      },
      {
        id: "forms",
        path: "forms",
        element: <PatientFormsRoute />,
      },
      {
        id: "billing",
        path: "billing/*",
        element: <Billing />,
      },
      {
        id: "communication",
        path: "communication",
        element: <PatientCommunicationRoute />,
      },
      ...(showErx
        ? [
            {
              id: "erx",
              path: "erx",
              element: <PatientErxRoute />,
            } as RouteConfig,
          ]
        : []),
    ];

    return availableRoutes;
  }, [showErx]);

  return (
    <SentryRoutes>
      {patientRoutes.map((routeConfig, i) => {
        const { domain, action } = patientRoutePermissions[routeConfig.id];

        return (
          <Route
            {...routeConfig}
            key={i}
            element={
              <RoleGuardLock key={routeConfig.id} domain={domain} action={action}>
                {routeConfig.element}
              </RoleGuardLock>
            }
          />
        );
      })}
      <Route path="*" element={<NotFound />} />
    </SentryRoutes>
  );
};
