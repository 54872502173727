import { formatCurrency } from "@libs/utils/currency";
import { FloatingTooltip } from "@libs/components/UI/FloatingTooltip";
import { ReactComponent as CompletedIcon } from "@libs/assets/icons/check-circle-1.svg";
import { EMPTY_CELL } from "@libs/components/UI/GridTableComponents";
import { CellCurrencyValue } from "components/Dashboard/Tables/CellCurrencyValue";
import { DashboardLinkCell } from "components/Dashboard/Tables/DashboardLinkCell";

export const ProducedVsScheduledCells: React.FC<{
  last: boolean;
  isScheduled: boolean;
  totalGrossProducedAmount: number;
  totalProducedAmount: number;
  totalGrossScheduledAmount: number;
  to: string;
}> = ({
  last,
  isScheduled,
  totalProducedAmount,
  totalGrossScheduledAmount,
  totalGrossProducedAmount,
  to,
}) => {
  const cellProps = { last, to };

  return (
    <>
      <DashboardLinkCell {...cellProps} align="center">
        <CellCurrencyValue align={isScheduled ? "right" : "center"}>
          {isScheduled ? (
            formatCurrency(totalGrossScheduledAmount)
          ) : (
            <FloatingTooltip delay={750} content="Appointment completed" theme="SMALL">
              <CompletedIcon className="text-green w-5 -my-1" />
            </FloatingTooltip>
          )}
        </CellCurrencyValue>
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="center">
        <CellCurrencyValue align="right">
          {isScheduled ? EMPTY_CELL : formatCurrency(totalGrossProducedAmount)}
        </CellCurrencyValue>
      </DashboardLinkCell>
      <DashboardLinkCell {...cellProps} align="center">
        <CellCurrencyValue align="right">{formatCurrency(totalProducedAmount)}</CellCurrencyValue>
      </DashboardLinkCell>
    </>
  );
};
