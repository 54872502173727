import { FC, useMemo } from "react";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useAccount } from "@libs/contexts/AccountContext";
import { Panel } from "@libs/components/UI/Panel";
import { QueryResult } from "@libs/components/UI/QueryResult";

import { AttachFormsButtonMenu } from "components/PatientProfile/Forms/AttachFormsButtonMenu";
import { PatientFormsTable } from "components/PatientProfile/Forms/PatientFormsTable";

import { getPatientSummary } from "api/patients/queries";
import { getFolders } from "api/documents/queries";
import { listInfiniteFormTasks } from "api/formTasks/queries";
import { getInfiniteFormSummaries } from "api/forms/queries";

import { usePathParams } from "hooks/usePathParams";

import { PatientAppHistoryProvider } from "contexts/PatientLinksContext";

export const PatientFormsRoute: FC = () => {
  const { practiceId } = useAccount();
  const { patientId } = usePathParams("patientForms");

  const [patientQuery, foldersQuery] = useApiQueries([
    getPatientSummary({ args: { patientId, practiceId } }),
    getFolders({ args: { practiceId, userId: patientId } }),
  ]);

  const formsQuery = useInfiniteApiQuery(
    getInfiniteFormSummaries({
      args: { practiceId, pageNumber: 1, publishedOnly: true, types: ["INTAKE"] },
    })
  );

  const formTasksQuery = useInfiniteApiQuery(
    listInfiniteFormTasks({
      args: {
        practiceId,
        patientId,
        states: ["PENDING", "COMPLETED"],
        pageSize: PAGE_SIZE,
        pageNumber: 1,
      },
    })
  );

  const forms = useFlattenPages(formsQuery.data);

  const consentFolderId = useMemo(
    () => foldersQuery.data?.find((folder) => folder.name === "Consent Forms")?.id,
    [foldersQuery]
  );

  return (
    <PatientAppHistoryProvider patientId={patientId} name="forms">
      <Panel
        title="Patient Forms"
        className="h-full overflow-hidden"
        includePadding={false}
        actions={
          <QueryResult queries={[formsQuery]}>
            {forms ? <AttachFormsButtonMenu patientId={patientId} forms={forms} /> : null}
          </QueryResult>
        }
      >
        <QueryResult queries={[patientQuery, foldersQuery]}>
          {patientQuery.data && consentFolderId ? (
            <PatientFormsTable
              patient={patientQuery.data}
              formTasksQuery={formTasksQuery}
              consentFolderId={consentFolderId}
            />
          ) : null}
        </QueryResult>
      </Panel>
    </PatientAppHistoryProvider>
  );
};
