/* eslint-disable @typescript-eslint/naming-convention */
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useBoolean } from "@libs/hooks/useBoolean";
import { formatISODate } from "@libs/utils/date";
import { useObjectState } from "@libs/hooks/useObjectState";
import { eq } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import { useCallback } from "react";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Checkbox } from "@libs/components/UI/Checkbox";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getPracticeImagingSettings } from "api/imaging/queries";
import { onboardPracticeWithPearl } from "api/settings/practice/mutations";
import { SettingsPanel } from "components/Settings/SettingsPanel";
import { BaseFormSection } from "components/UI/FormSection";

const BAA_AGREEMENT = {
  id: "baa" as const,
  label: "BAA",
  url: "https://pearl-legal-agreements.s3.amazonaws.com/BAA_US.html?versionId=EbMMKbk7924N5RS1bT_ATFQEvJuoApVd",
};
const MSA_AGREEMENT = {
  id: "msa" as const,
  label: "MSA",
  url: "https://pearl-legal-agreements.s3.amazonaws.com/BAA_US.html?versionId=EbMMKbk7924N5RS1bT_ATFQEvJuoApVd",
};
const PRIVACY_POLICY_AGREEMENT = {
  id: "privacyPolicy" as const,
  label: "Privacy Policy",
  url: "https://pearl-legal-agreements.s3.amazonaws.com/privacy_policy.html",
};
const PEARL_AGREEMENT_URLS = [BAA_AGREEMENT, MSA_AGREEMENT, PRIVACY_POLICY_AGREEMENT];

const AGREEMENT_URLS_SCHEMA = {
  baa: [{ $v: eq(true), $error: "Please view Pearl's BAA before signing" }],
  msa: [{ $v: eq(true), $error: "Please view Pearl's MSA before signing" }],
  privacyPolicy: [{ $v: eq(true), $error: "Please view Pearl's Privacy Policy before signing" }],
};

export const ImagingPearlSettingsRoute: React.FC = () => {
  const checkboxChecked = useBoolean(false);
  const { practiceId } = useAccount();
  const [onboardPracticeWithPearlMutation] = useApiMutations([onboardPracticeWithPearl]);
  const onboardPracticeWithPearlMutateAsync = onboardPracticeWithPearlMutation.mutateAsync;
  const [practiceImagingSettingsQuery] = useApiQueries([
    getPracticeImagingSettings({ args: { practiceId } }),
  ]);

  const [linksClicked, updateLinksClicked] = useObjectState<{
    baa: boolean;
    msa: boolean;
    privacyPolicy: boolean;
  }>({ baa: false, msa: false, privacyPolicy: false });
  const validation = useValidation(linksClicked, AGREEMENT_URLS_SCHEMA);

  const dateOnboardedWithPearl = practiceImagingSettingsQuery.data?.dateOnboardedWithPearl;
  const handleSubmit = useCallback(() => {
    if (validation.validate().$isValid) {
      onboardPracticeWithPearlMutateAsync({
        practiceId,
        data: {
          baa_url: BAA_AGREEMENT.url,
          msa_url: MSA_AGREEMENT.url,
          privacy_policy_url: PRIVACY_POLICY_AGREEMENT.url,
        },
      });
    }
  }, [onboardPracticeWithPearlMutateAsync, practiceId, validation]);
  const validationFailed = validation.result.$isValid === false;

  return (
    <SettingsPanel isEditing={false} title="Imaging">
      <QueryResult queries={[practiceImagingSettingsQuery]}>
        <div className="flex flex-col gap-8 text-xs">
          <BaseFormSection title="Pearl AI Imaging" className="flex flex-col gap-3">
            <p>
              Archy partners with Pearl to automatically detect pathologic, restorative and anatomical
              features in dental x-rays. Using Pearl can enable quicker radiograph review, easier diagnosis,
              and increase acceptance rates of treatment plans.
            </p>
            {dateOnboardedWithPearl ? (
              <p className="font-sansSemiBold">
                You agreed to Pearl&apos;s terms on {formatISODate(dateOnboardedWithPearl)}.
              </p>
            ) : (
              <p>To enable Pearl you will need to agree to Pearl&apos;s terms and conditions.</p>
            )}
          </BaseFormSection>
          <div className="flex flex-col gap-2">
            {validationFailed && <div className="text-red">Please view agreements before signing</div>}
            <div className="flex divide-x">
              {PEARL_AGREEMENT_URLS.map(({ label, url, id }) => (
                <a
                  key={label}
                  href={url}
                  onClick={() => {
                    updateLinksClicked({ [id]: true });
                  }}
                  target="_blank"
                  className={`
                    font-sansSemiBold
                    text-primaryTheme
                    hover:opacity-70
                    first:pl-0
                    last:pr-0
                    px-4
                  `}
                >
                  {label}
                </a>
              ))}
            </div>
          </div>
          {!dateOnboardedWithPearl && (
            <>
              <Checkbox onChange={checkboxChecked.toggle} checked={checkboxChecked.isOn}>
                I acknowledge that I have read and agree to Pearl&apos;s terms and conditions.
              </Checkbox>
              <div>
                <AsyncButton
                  className="min-w-button"
                  disabled={onboardPracticeWithPearlMutation.isLoading || validationFailed}
                  isLoading={onboardPracticeWithPearlMutation.isLoading}
                  onClick={handleSubmit}
                >
                  Save
                </AsyncButton>
              </div>
            </>
          )}
        </div>
      </QueryResult>
    </SettingsPanel>
  );
};
