import { FC, FormEvent, useMemo } from "react";
import {
  ProviderVO,
  RecurringScheduleVO,
  RoomVO,
  ScheduleBlockVO,
  UpdateScheduleBlockRequest,
} from "@libs/api/generated-api";
import { minSetSize, required } from "@libs/utils/validators";
import { useValidation } from "@libs/hooks/useValidation";
import {
  formatAsISODate,
  addMinutesToISOTime,
  getISOTimeDiffInMinutes,
  getLocalDate,
} from "@libs/utils/date";
import { useObjectState } from "@libs/hooks/useObjectState";
import { ReactComponent as ClockIcon } from "@libs/assets/icons/clock.svg";
import { RequiredAsterisk } from "@libs/components/UI/RequiredAsterisk";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { FormFieldColorPicker } from "@libs/components/UI/FormFieldColorPicker";
import { colorsByName } from "@libs/domains/scheduling/colors";
import { Form } from "@libs/components/UI/Form";
import { Banner } from "@libs/components/UI/Banner";
import { FormFieldTimeRangeMenu } from "components/UI/FormFieldTimeRangeMenu";
import { FormFieldTextarea } from "components/UI/FormFieldTextarea";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { Divider } from "components/UI/Divider";
import { BlockRepeatSelectMenu } from "components/ScheduleAppointments/BlockRepeatSelectMenu";
import { useIsDirty } from "hooks/useIsDirty";
import { BlockSelectProviders } from "components/ScheduleAppointments/BlockSelectProviders";
import { BlockSelectRooms } from "components/ScheduleAppointments/BlockSelectRooms";
import { getUpdatedRecurrence } from "components/ScheduleAppointments/getUpdatedRecurrence";
import { isSameRecurrence } from "components/ScheduleAppointments/isSameRecurrence";

interface Props {
  savedBlock?: ScheduleBlockVO;
  comments?: string;
  duration?: number;
  providerIds?: number[];
  roomIds?: number[];
  startTime: string;
  recurrence?: RecurringScheduleVO;
  endTime?: string;
  date: string;
  color?: string;
  title?: string;
  formId: string;
  rooms: RoomVO[];
  providers: ProviderVO[];
  onSubmit: (data: UpdateScheduleBlockRequest) => void;
  onDirty: Func;
}

const DEFAULT_BLOCK_DURATION = 30;

// eslint-disable-next-line complexity
export const BlockForm: FC<Props> = (props) => {
  const [form, updateForm] = useObjectState(() => ({
    notes: props.comments,
    startTime: props.startTime,
    endTime: props.endTime || addMinutesToISOTime(props.startTime, props.duration ?? DEFAULT_BLOCK_DURATION),
    recurrence: props.recurrence,
    date: props.date || undefined,
    title: props.title ?? "",
    color: props.color ?? colorsByName.Violet.value,
    providerIds: new Set(props.providerIds),
    roomIds: new Set(props.roomIds),
  }));

  const { notes, startTime, endTime, recurrence, date, title, color, providerIds, roomIds } = form;

  const schema = useMemo(() => {
    return {
      date: [{ $v: required, $error: "Date required." }],
      endTime: [{ $v: required, $error: "End time required." }],
      providerIds: [
        {
          $ignore: roomIds.size > 0,
          $v: minSetSize(1),
          $error: "A room or provider must be selected.",
        },
      ],
      roomIds: [
        {
          $ignore: providerIds.size > 0,
          $v: minSetSize(1),
          $error: "A room or provider must be selected.",
        },
      ],
      startTime: [{ $v: required, $error: "Start time required." }],
      title: [{ $v: required, $error: "Title required." }],
    };
  }, [roomIds.size, providerIds.size]);

  useIsDirty(form, { onDirty: props.onDirty });

  const formValidation = useValidation(form, schema);
  const duration = useMemo(() => getISOTimeDiffInMinutes(endTime, startTime), [endTime, startTime]);

  // eslint-disable-next-line complexity
  const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    if (formValidation.validate().$isValid && date) {
      const body: UpdateScheduleBlockRequest = {
        title,
        date,
        startTime,
        endTime,
        color,
      };

      if (notes) {
        body.comments = notes;
      }

      if (roomIds.size) {
        body.roomIds = [...roomIds];
      }

      if (providerIds.size) {
        body.providerIds = [...providerIds];
      }

      if (recurrence) {
        body.recurrence = recurrence;
      }

      props.onSubmit(body);
    }
  };

  const blockSelectionError =
    formValidation.result.roomIds.$error || formValidation.result.providerIds.$error;

  return (
    <Form className="flex flex-col w-[576px] p-6 gap-y-6" id={props.formId} onSubmit={handleSubmit}>
      <div className="text-sm font-sansSemiBold">Details</div>

      <FormFieldInput
        label="Title"
        required
        error={formValidation.result.title.$error}
        onChange={(e) => updateForm({ title: e.target.value })}
        value={title}
      />

      <div className="flex gap-x-3">
        <FormFieldSelectMenusDatepicker
          label="Date"
          required
          error={formValidation.result.date.$error}
          iconSize="md"
          onChange={(newDate) => {
            const nextDate = newDate ? formatAsISODate(newDate) : undefined;

            // If there is no previously saved recurrence or the user has already updated
            // the recurrence, update recurrence as the user updates the start date
            if (recurrence && (!props.recurrence || !isSameRecurrence(props.recurrence, recurrence))) {
              const nextRecurrence = getUpdatedRecurrence({
                newDate: nextDate,
                currentRecurrence: recurrence,
                instanceDate: date,
              });

              updateForm({
                recurrence: nextRecurrence,
                date: nextDate,
              });
            } else {
              // else the user needs to explicitly update the recurrence
              // themselves. By not updating the recurrence automatically
              // the user has the option of saying only apply a date change
              // to one instance vs mandating it applies to all future.
              // If they do choose apply to future we will update the recurrence
              // automatically at that time.
              updateForm({
                date: nextDate,
              });
            }
          }}
          className="flex-1"
          selected={date ? getLocalDate(date) : undefined}
        />

        <FormFieldTimeRangeMenu
          required
          label="Time"
          durationInMinutes={duration}
          error={formValidation.result.startTime.$error || formValidation.result.endTime.$error}
          Icon={ClockIcon}
          iconSize="md"
          onChange={(updates) => {
            updateForm({
              endTime: addMinutesToISOTime(updates.startTime, updates.durationInMinutes),
              startTime: updates.startTime,
            });
          }}
          rangeType="durationInMinutes"
          startTime={startTime}
          className="flex-1"
        />
      </div>
      <div className="flex gap-x-3">
        <div className="flex-1">
          <BlockRepeatSelectMenu
            date={date}
            onChange={(value) => {
              updateForm({
                recurrence: value,
              });
            }}
            recurrence={recurrence}
          />
        </div>
        <FormFieldColorPicker
          required
          label="Time Block Color"
          onChange={(val) => updateForm({ color: val })}
          value={color}
          className="flex-1"
        />
      </div>

      <FormFieldTextarea
        label="Comment"
        onChange={(e) => updateForm({ notes: e.target.value })}
        placeholder="Enter comment"
        value={notes}
      />

      <Divider className="my-1" />
      <div className="flex flex-col gap-y-1">
        <div className="flex text-sm font-sansSemiBold">
          <span>Room &amp; Provider</span>
          <RequiredAsterisk className="block font-sans" />
        </div>
        <div className="text-xs">
          Any providers or rooms selected will not be available in the slot finder or online booking
        </div>
      </div>
      {blockSelectionError && (
        <Banner theme="error" className="text-xs/4">
          {blockSelectionError}
        </Banner>
      )}

      <BlockSelectRooms
        roomIds={roomIds}
        rooms={props.rooms}
        savedRooms={props.savedBlock?.rooms}
        onChange={(values) => updateForm({ roomIds: values })}
      />

      <BlockSelectProviders
        providerIds={providerIds}
        providers={props.providers}
        savedProviders={props.savedBlock?.providers}
        onChange={(values) => updateForm({ providerIds: values })}
      />
    </Form>
  );
};
