import { MutableRefObject, useState, useMemo, useEffect } from "react";

interface UseIsVisibleOptions {
  isVisibleOnce?: boolean;
  enabled?: boolean;
}

export const useIsVisible = (ref: MutableRefObject<HTMLElement | null>, options?: UseIsVisibleOptions) => {
  const [isVisible, setIsVisible] = useState(false);
  const isVisibleOnce = options?.isVisibleOnce ?? false;
  const enabled = options?.enabled ?? true;

  const observer = useMemo(() => new IntersectionObserver(([e]) => setIsVisible(e.isIntersecting)), []);

  useEffect(() => {
    if (ref.current && enabled) {
      observer.observe(ref.current);
    }

    if (isVisibleOnce && isVisible) {
      observer.disconnect();
    }

    return () => observer.disconnect();
  }, [ref, enabled, observer, isVisibleOnce, isVisible]);

  return isVisible;
};
