import { FC, useMemo } from "react";
import { PayrollVO } from "@libs/api/generated-api";
import designConfig from "@libs/design.config";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { toFilterComponentsProps } from "@libs/components/UI/queryFilterPillsUtils";
import { createSelectStyles } from "@libs/components/UI/selectStyles";
import { PayrollFilterQuery, PayrollQuery } from "utils/routing/employees";
import { FormFieldSelect } from "components/UI/FormFieldSelect";

export const getFilterProps = (queryState: PayrollFilterQuery) =>
  toFilterComponentsProps(queryState, [
    {
      type: "default",
      prop: "payrollType",
      format: (val) => sentenceCaseConstant(val),
    },
    {
      type: "default",
      prop: "payrollYear",
      format: (val) => `Year: ${val}`,
    },
  ]);

const PayrollTypeOptions: SelectOption<PayrollVO["type"]>[] = [
  {
    value: "REGULAR",
    label: "Regular",
  },
  {
    value: "OFF_CYCLE",
    label: "Off cycle",
  },
  {
    value: "DISMISSAL",
    label: "Dismissal",
  },
];

const YearOptions: SelectOption<number>[] = Array.from({ length: 20 }, (_, index) => {
  const year = new Date().getFullYear() - index;

  return {
    value: year,
    label: `${year}`,
  };
});

export const PayrollsTitleBar: FC<{
  filters: PayrollFilterQuery;
  totalElements?: number;
  onUpdatePayrolls: (updates: Partial<PayrollQuery>) => void;
}> = ({ filters, totalElements, onUpdatePayrolls }) => {
  const filterProps = useMemo(() => {
    return getFilterProps(filters);
  }, [filters]);

  const typeStyles = useMemo(() => {
    return createSelectStyles<PayrollVO["type"], SelectOption<PayrollVO["type"]>>({
      placeholder: () => ({
        color: designConfig.colors.greyDark,
      }),
    });
  }, []);

  const yearStyles = useMemo(() => {
    return createSelectStyles<number, SelectOption<number>>({
      placeholder: () => ({
        color: designConfig.colors.greyDark,
      }),
    });
  }, []);

  return (
    <div className="border-b border-b-greyLighter py-2 px-5 flex items-center">
      <h2 className="font-sansSemiBold text-sm flex-none">Payroll History</h2>
      <FormFieldSelect
        options={PayrollTypeOptions}
        placeholder="Type"
        styles={typeStyles}
        isSearchable={false}
        isClearable={false}
        // Make visible once off cycle is done
        className="w-60 ml-12 hidden"
        onItemSelected={(val: PayrollVO["type"]) => onUpdatePayrolls({ payrollType: val })}
      />
      <FormFieldSelect
        options={YearOptions}
        placeholder="Year"
        styles={yearStyles}
        isSearchable={false}
        isClearable={false}
        className="w-60 ml-3"
        onItemSelected={(val: number) => onUpdatePayrolls({ payrollYear: val })}
      />
      {filterProps.filters.length ? (
        <div className="ml-6">
          <QueryFilterPills
            numResults={totalElements ?? 0}
            onUpdateParams={onUpdatePayrolls}
            onClearAll={onUpdatePayrolls}
            {...filterProps}
          />
        </div>
      ) : null}
    </div>
  );
};
