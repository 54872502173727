/* eslint-disable @typescript-eslint/naming-convention */
import { useMemo } from "react";
import { InfiniteData } from "@tanstack/react-query";
import { CollectedPaymentEntryVO, CollectedPaymentResponse } from "@libs/api/generated-api";
import { ApiResponse } from "@libs/@types/api";
import { getTotals } from "components/Dashboard/Tables/utils";
import { flattenEntries } from "components/Dashboard/hooks/useFlattenEntries";

const getSectionKey = (data: Pick<CollectedPaymentEntryVO, "paymentMethod" | "payerType">) =>
  `${data.payerType}-${data.paymentMethod}`;

export const getPaymentTypeSections = (
  data:
    | InfiniteData<{
        data: CollectedPaymentResponse;
        apiResponse: ApiResponse<CollectedPaymentResponse>;
      }>
    | undefined
) => {
  const totals = getTotals(data);
  const collectedByPaymentTypeEntries = flattenEntries<CollectedPaymentEntryVO>(data);
  const subtotals = data?.pages.flatMap((item) => item.data.subtotals) ?? [];
  const sectionMap: Record<string, CollectedPaymentEntryVO[] | undefined> = {};
  const keys: string[] = [];

  for (const entry of collectedByPaymentTypeEntries) {
    const key = getSectionKey(entry);
    const value = sectionMap[key];

    if (!value) {
      keys.push(key);
      sectionMap[key] = [];
    }

    sectionMap[key]?.push(entry);
  }

  const subtotalMap = subtotals.reduce(
    (acc, curr) => {
      const key = getSectionKey(curr);

      acc[key] = curr.totalPaymentAmount;

      return acc;
    },
    {} as Record<string, number | undefined>
  );

  return {
    keys,
    sectionMap,
    subtotalMap,
    totals,
  };
};

export const usePaymentTypeSections = (
  data:
    | InfiniteData<{
        data: CollectedPaymentResponse;
        apiResponse: ApiResponse<CollectedPaymentResponse>;
      }>
    | undefined
) => {
  return useMemo(() => getPaymentTypeSections(data), [data]);
};
