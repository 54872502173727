import { useMemo } from "react";
import { Button } from "@libs/components/UI/Button";
import { EmptyContent } from "@libs/components/UI/EmptyContent";
import EmptyEmployeeList from "assets/images/empty-people-list.svg";

interface Props {
  isFiltered: boolean;
  onClearFilters: Func;
}

export const EmptyList: React.FC<Props> = ({ isFiltered, onClearFilters }) => {
  const emptyText = useMemo(() => {
    return isFiltered ? "No employees match the current filters." : "No employees have been setup.";
  }, [isFiltered]);

  return (
    <div className="col-span-8 h-full">
      <EmptyContent fullHeight size="md" text={emptyText} alt="No Employees" src={EmptyEmployeeList}>
        {isFiltered ? (
          <div className="mt-2">
            <Button className="text-sm" theme="link" onClick={onClearFilters}>
              Clear All Filters
            </Button>
          </div>
        ) : null}
      </EmptyContent>
    </div>
  );
};
