import { FC, HTMLProps } from "react";
import { useNavigate } from "react-router-dom";
import { cx } from "@libs/utils/cx";
import { sentenceCaseConstant } from "@libs/utils/casing";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { Button } from "@libs/components/UI/Button";
import { ButtonInternalLink } from "@libs/components/UI/ButtonLink";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ModalPage } from "components/UI/ModalPage";
import { paths } from "utils/routing/paths";
import { getPendingInvoiceEntries } from "api/billing/queries";
import { PracticeAndPatientAddresses } from "components/PatientProfile/Billing/PracticeAndPatientAddresses";
import { getPatientSummary } from "api/patients/queries";
import { FormFieldSelectMenusDatepicker } from "components/UI/FormFieldSelectMenusDatepicker";
import { getAdjustmentTypesForPractice } from "api/settings/payments/queries";
import { useInvoicePreview } from "components/PatientProfile/Billing/useInvoicePreview";
import { handleError } from "utils/handleError";
import { InvoiceTotalRow } from "components/PatientProfile/Billing/InvoiceEntryComponents";
import { usePathParams } from "hooks/usePathParams";
import {
  findMatchingEntryFromInvoice,
  invoiceEntryOrLineItemToId,
} from "components/PatientProfile/Billing/invoiceUtils";
import { useQueryParams } from "hooks/useQueryParams";
import { Note } from "components/PatientProfile/Billing/FormComponents";
import { usePermittedAdjustments } from "components/PatientProfile/Billing/billingUtils";
import { InvoiceEntryEditable } from "./InvoiceEntryEditable";

// eslint-disable-next-line complexity
export const CreateInvoicePage: FC = () => {
  const { patientId } = usePathParams("createInvoice");
  const { query } = useQueryParams("createInvoice");
  const closeLink = query.from ?? paths.patientBilling({ patientId });
  const nextStep = query.nextStep;
  const practice = useCurrentPractice();

  const [patientQuery, invoiceableEntriesQuery, practiceAdjustmentsQuery] = useApiQueries([
    getPatientSummary({ args: { patientId, practiceId: practice.id } }),
    getPendingInvoiceEntries({ args: { patientId, practiceId: practice.id } }),
    getAdjustmentTypesForPractice({ args: { practiceId: practice.id } }),
  ]);

  const navigate = useNavigate();

  const {
    invoiceDraft,
    proposedEntries,
    proposedAdjustments,
    invoiceDueDate,
    invoiceNote,
    handleRemoveEntry,
    handleAddAdjustment,
    handleRemoveAdjustment,
    handleDueDateChange,
    handleNoteChange,
    createInvoice,
    isLoading: isLoadingPreview,
    validation,
  } = useInvoicePreview({
    practiceId: practice.id,
    patientId,
    invoiceableEntries: invoiceableEntriesQuery.isFetching ? undefined : invoiceableEntriesQuery.data,
    onError: handleError,
    onSuccess: () => {
      if (nextStep === "collect") {
        navigate(paths.collectPayment({ patientId }), { replace: true });
      } else {
        navigate(closeLink, { replace: true });
      }
    },
  });

  const isRefund = (invoiceDraft?.amount ?? 0) < 0;
  const createButtonLabel = nextStep === "collect" ? `Create & ${isRefund ? "Refund" : "Collect"}` : "Create";
  const permittedAdjustments = usePermittedAdjustments(practiceAdjustmentsQuery.data);

  return (
    <ModalPage
      title={<span className="font-sansSemiBold">Create Invoice</span>}
      closeLink={closeLink}
      className="flex-1"
      actions={
        <div className="flex gap-x-3 justify-center">
          <ButtonInternalLink className="min-w-button" theme="secondary" to={closeLink}>
            Cancel
          </ButtonInternalLink>
          <Button
            className="min-w-button"
            theme="primary"
            onClick={createInvoice}
            disabled={isLoadingPreview || !proposedEntries?.length || !(validation.result.$isValid ?? true)}
          >
            {createButtonLabel}
          </Button>
        </div>
      }
    >
      <div className="relative h-full w-full">
        <QueryResult
          queries={[patientQuery, invoiceableEntriesQuery]}
          nonCriticalQueries={[practiceAdjustmentsQuery]}
          loading={<LoadingOverlaySpinner />}
        >
          <div className="p-5 flex flex-col gap-y-5 relative h-full w-full">
            {patientQuery.data && (
              <PracticeAndPatientAddresses practiceData={practice} patientData={patientQuery.data} />
            )}
            <FormFieldSelectMenusDatepicker
              id="due-date"
              label="Due Date"
              layout="labelIn"
              className="w-52"
              required={true}
              selected={invoiceDueDate}
              minDate={new Date()}
              onChange={handleDueDateChange}
              error={validation.result.invoiceDueDate.$error}
            />
            {proposedEntries?.map((entry) => {
              const calculatedEntry = findMatchingEntryFromInvoice(entry, invoiceDraft);

              return (
                <div key={invoiceEntryOrLineItemToId(entry)} className="flex flex-col gap-y-2">
                  <EntryHeader
                    canRemove={proposedEntries.length > 1}
                    onRemove={() => handleRemoveEntry(entry)}
                  >
                    {sentenceCaseConstant(entry.entryReference.type)}
                  </EntryHeader>
                  <InvoiceEntryEditable
                    proposedEntry={entry}
                    calculatedEntry={calculatedEntry}
                    practiceAdjustments={permittedAdjustments}
                    proposedEntryAdjustments={proposedAdjustments[invoiceEntryOrLineItemToId(entry)] ?? []}
                    onNewAdjustment={(adjustment) => handleAddAdjustment(entry, adjustment)}
                    onRemoveAdjustment={(adjustmentIndex) => handleRemoveAdjustment(entry, adjustmentIndex)}
                  />
                </div>
              );
            })}
            <InvoiceTotalRow invoiceTotal={invoiceDraft?.amount} />
            <Note
              label={
                <div>
                  <span className="font-sansSemiBold">Note</span>{" "}
                  <span>(This note will appear on the invoice)</span>
                </div>
              }
              placeholder="Enter any important notes related to this invoice..."
              value={invoiceNote}
              onChange={(e) => handleNoteChange(e.target.value)}
              error={validation.result.invoiceNote.$error}
            />
          </div>
        </QueryResult>
      </div>
    </ModalPage>
  );
};

const EntryHeader: FC<HTMLProps<HTMLDivElement> & { onRemove: Func; canRemove: boolean }> = ({
  children,
  canRemove,
  onRemove,
}) => {
  return (
    <div
      className={`
        font-sansSemiBold
        text-sm
        flex
        h-6
        justify-between
        items-center
      `}
    >
      {children}
      <Button
        className={cx("text-sm", canRemove ? "flex items-center gap-x-1" : "hidden")}
        theme="link"
        onClick={onRemove}
      >
        Remove <DeleteIcon className="w-6 h-6" />
      </Button>
    </div>
  );
};
