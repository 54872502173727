import { CollectedPaymentEntryVO } from "@libs/api/generated-api";
import { formatISODate } from "@libs/utils/date";
import { formatCurrency } from "@libs/utils/currency";
import { Row } from "@libs/components/UI/GridTableComponents";
import { Cell } from "components/Dashboard/Tables/Cell";
import { labelForPayerType, labelForPaymentDetails } from "components/Dashboard/PaymentCollected/utils";

type Props = {
  data: CollectedPaymentEntryVO;
  last?: boolean;
};

export const ByPaymentMethodTableRow: React.FC<Props> = ({ last, data }) => {
  const cellProps = { last, hoverEffectsEnabled: true };
  const { payerType, paymentAmount, paymentDate, patient } = data;

  return (
    <Row>
      <Cell {...cellProps} />
      <Cell {...cellProps}>{patient.shortDisplayName}</Cell>
      <Cell {...cellProps}>{formatISODate(paymentDate)}</Cell>
      <Cell {...cellProps}>{labelForPayerType(payerType)}</Cell>
      <Cell {...cellProps}>{labelForPaymentDetails(data)}</Cell>
      <Cell {...cellProps} align="right">
        {formatCurrency(paymentAmount)}
      </Cell>
    </Row>
  );
};
