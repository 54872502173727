import { FC, Dispatch, SetStateAction } from "react";
import { produce } from "immer";

import { PatientCriteriaVO, PatientListCriteria } from "@libs/api/generated-api";
import { Button } from "@libs/components/UI/Button";
import { FormFieldError } from "@libs/components/UI/FormFieldError";
import { FormFieldNumberInput } from "components/UI/FormFieldNumberInput";

import { FilterSection } from "components/Communications/Filters/FilterSection";
import { PatientListCriteriaValidationResult } from "components/Communications/Filters/types";

interface Props extends Pick<PatientCriteriaVO, "ageGroup"> {
  validationResult: PatientListCriteriaValidationResult["patientCriteria"]["ageGroup"];
  onUpdatePatientListCriteria: Dispatch<SetStateAction<PatientListCriteria>>;
}

export const AgeFilterSection: FC<Props> = ({ ageGroup, validationResult, onUpdatePatientListCriteria }) => (
  <FilterSection
    title="Age"
    dataTestId="age-filter-section"
    isOpen={Boolean(ageGroup?.min != null || ageGroup?.max != null)}
    hasError={Boolean(validationResult.min.$error)}
  >
    <div className="flex flex-col gap-y-2">
      <div className="flex items-center gap-x-2">
        <FormFieldNumberInput
          aria-label="Min Years"
          className="w-24"
          value={ageGroup?.min}
          placeholder="Min"
          onChange={(e) =>
            onUpdatePatientListCriteria((last) =>
              produce(last, (draft) => {
                const min = e.target.value ? Number(e.target.value) : undefined;

                if (min == null && draft.patientCriteria?.ageGroup?.max == null) {
                  delete draft.patientCriteria?.ageGroup;
                } else if (draft.patientCriteria) {
                  draft.patientCriteria.ageGroup = { ...draft.patientCriteria.ageGroup, min };
                } else {
                  draft.patientCriteria = { ageGroup: { min } };
                }
              })
            )
          }
          error={validationResult.min.$error}
          displayErrorMessage={false}
        >
          <span
            className={`
              font-sansSemiBold
              text-xs
              text-slate700
              absolute
              right-3
              top-1/2
              -translate-y-1/2
            `}
          >
            Years
          </span>
        </FormFieldNumberInput>

        <span className="text-xs text-black">-</span>

        <FormFieldNumberInput
          aria-label="Max Years"
          className="w-24"
          value={ageGroup?.max}
          placeholder="Max"
          onChange={(e) =>
            onUpdatePatientListCriteria((last) =>
              produce(last, (draft) => {
                const max = e.target.value ? Number(e.target.value) : undefined;

                if (max == null && draft.patientCriteria?.ageGroup?.min == null) {
                  delete draft.patientCriteria?.ageGroup;
                } else if (draft.patientCriteria) {
                  draft.patientCriteria.ageGroup = { ...draft.patientCriteria.ageGroup, max };
                } else {
                  draft.patientCriteria = { ageGroup: { max } };
                }
              })
            )
          }
          error={validationResult.min.$error}
          displayErrorMessage={false}
        >
          <span
            className={`
              font-sansSemiBold
              text-xs
              text-slate700
              absolute
              right-3
              top-1/2
              -translate-y-1/2
            `}
          >
            Years
          </span>
        </FormFieldNumberInput>
      </div>

      {validationResult.min.$error ? <FormFieldError>{validationResult.min.$error}</FormFieldError> : null}

      {ageGroup?.min != null || ageGroup?.max != null ? (
        <Button
          className="w-fit text-xs"
          onClick={() =>
            onUpdatePatientListCriteria((last) =>
              produce(last, (draft) => {
                if (draft.patientCriteria) {
                  delete draft.patientCriteria.ageGroup;
                }
              })
            )
          }
          size="custom"
          theme="link"
        >
          Clear
        </Button>
      ) : null}
    </div>
  </FilterSection>
);
