import React, { useMemo, useRef, useState } from "react";
import { useDebouncedCallback } from "use-debounce";
import { ClaimSummaryVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatCurrency } from "@libs/utils/currency";
import { useBoolean } from "@libs/hooks/useBoolean";
import { formatISODate } from "@libs/utils/date";
import { SEARCH_DEBOUNCE_DELAY_MS } from "@libs/utils/constants";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { Menu } from "@libs/components/UI/Menu";
import { RadioList } from "@libs/components/UI/RadioList";
import { ReactComponent as RemoveIcon } from "@libs/assets/icons/cancel.svg";
import { ReactComponent as UnsubmittedIcon } from "@libs/assets/icons/unsubmitted.svg";
import { ReactComponent as SubmittedIcon } from "@libs/assets/icons/in-progress-submitted.svg";
import { ReactComponent as CompletedIcon } from "@libs/assets/icons/check-circle-1.svg";
import { ReactComponent as DownCaretIcon } from "@libs/assets/icons/down-caret.svg";
import { ReactComponent as UpCaretIcon } from "@libs/assets/icons/up-caret.svg";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useAccount } from "@libs/contexts/AccountContext";
import {
  ButtonCell,
  Cell,
  CheckboxCell,
  HeaderCell,
  Row,
  TableGrid,
} from "@libs/components/UI/GridTableComponents";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";
import { EligibleClaimFilter, getInfinitePaymentEligibleClaimSummaries } from "api/claim/queries";
import EmptyClaims from "assets/images/empty-claims-records.svg";
import { RECORDS_PER_PAGE } from "utils/words";

interface Props {
  insuranceCarrierId: number;
  onToggleClaim: (claimUuid: string) => void;
  selectedClaimUuids: Set<string>;
}

const cxStyles = {
  searchCell: `
    flex
    items-center
    px-3
    border-0
    group-hover:bg-slate-100
    group-hover:cursor-pointer
  `,
  selectedCell: "flex items-center py-2",
  // This is to shift the content beneath the menu so that you don't
  // see the left edge content behind the menu
  shiftHack: "w-[calc(100%-2px)] pl-[1px]",
};

const tableHeaders = {
  searchResults: [
    { id: "checkbox", label: "", width: "50px" },
    { id: "date", label: "Date", width: "3fr" },
    { id: "name", label: "Patient Name", width: "4fr" },
    { id: "subscriberId", label: "Subscriber ID", width: "3fr" },
    { id: "insuranceEst", className: "justify-end", label: "Ins Est", width: "3fr" },
    { id: "type", label: "", width: "50px" },
  ],
  selectedClaims: [
    { id: "date", label: "Date", width: "3fr" },
    { id: "name", label: "Patient Name", width: "4fr" },
    { id: "subscriberId", label: "Subscriber ID", width: "3fr" },
    { id: "insuranceEst", className: "justify-end", label: "Ins Est", width: "3fr" },
    { id: "type", label: "", width: "50px" },
    { id: "remove", label: "", width: "50px" },
  ],
};

const DEFAULT_CLAIM_TYPE_VALUE = "ALL";

export const AddClaimsModalContent: React.FC<Props> = ({
  insuranceCarrierId,
  onToggleClaim,
  selectedClaimUuids,
}) => {
  const { practiceId } = useAccount();

  const [claimType, setClaimType] = useState(DEFAULT_CLAIM_TYPE_VALUE);

  const [searchString, setSearchString] = useState("");

  const queryParams = useMemo(() => {
    let filters = [claimType] as EligibleClaimFilter[];

    if (claimType === "ALL") {
      filters = ["SUBMITTED", "COMPLETED", "PENDING_NON_PRIMARY"];
    }

    return {
      filters,
      insuranceCarrierIds: [insuranceCarrierId],
      pageNumber: 1,
      pageSize: RECORDS_PER_PAGE,
      searchString,
    };
  }, [claimType, insuranceCarrierId, searchString]);

  const claimsQuery = useInfiniteApiQuery(
    getInfinitePaymentEligibleClaimSummaries({
      args: { practiceId, ...queryParams },
    })
  );

  const { rootRef, scrollRef } = useInfiniteScrollQuery({ infiniteQuery: claimsQuery });

  const claimsPages = useMemo(() => {
    return claimsQuery.data?.pages;
  }, [claimsQuery.data?.pages]);

  const claimsData = claimsPages?.flatMap((page) => page.data);

  const claimsByIdMap = useMemo(() => {
    const map = {} as Record<string, ClaimSummaryVO | undefined>;

    claimsData?.forEach((claim) => {
      map[claim.uuid] = claim;
    });

    return map;
  }, [claimsData]);

  const patientsMenu = useBoolean(false);
  const triggerRef = useRef<HTMLInputElement | null>(null);

  const debouncedSearch = useDebouncedCallback(setSearchString, SEARCH_DEBOUNCE_DELAY_MS, {
    leading: true,
  });

  return (
    <div className="flex flex-col text-greyDark h-96">
      <div className="font-sansSemiBold text-sm">Search Claims</div>
      <RadioList
        className="my-3.5"
        onChange={(event: React.ChangeEvent<HTMLInputElement>) => setClaimType(event.target.value)}
        options={[
          {
            value: "PENDING_NON_PRIMARY",
            label: (
              <div className="flex">
                <span>Unsubmitted Secondaries</span>
                <UnsubmittedIcon className="ml-1.5 h-4 w-4 fill-greyLight" />
              </div>
            ),
          },
          {
            value: "SUBMITTED",
            label: (
              <div className="flex">
                <span>Submitted</span>
                <SubmittedIcon className="ml-1.5 h-4 w-4 fill-orange" />
              </div>
            ),
          },
          {
            value: "COMPLETED",
            label: (
              <div className="flex">
                <span>Completed</span>
                <CompletedIcon className="ml-1.5 h-4 w-4 fill-green" />
              </div>
            ),
          },
          {
            value: "ALL",
            label: "All",
          },
        ]}
        selectedValue={claimType}
      />
      <FormFieldInput
        Icon={patientsMenu.isOn ? UpCaretIcon : DownCaretIcon}
        label="Patient Name"
        layout="labelIn"
        onClick={patientsMenu.toggle}
        onChange={(event) => debouncedSearch(event.target.value)}
        placeholder="Search by patient name"
        ref={triggerRef}
        required
      />
      {patientsMenu.isOn && (
        <Menu
          className={`
            bg-white
            border
            shadow-main
            mt-0.5
            max-h-64
            overflow-y-scroll
          `}
          matchTriggerWidth={true}
          onRequestClose={patientsMenu.off}
          triggerRef={triggerRef}
        >
          <div ref={rootRef}>
            <ScrollableInfiniteQueryResult
              infiniteQuery={claimsQuery}
              noResults={
                <div className="flex flex-col items-center justify-center h-full">
                  <img alt="No Recorded Claims" src={EmptyClaims} />
                  <div className="mt-5 text-sm text-greyDark">No Recorded Claims</div>
                </div>
              }
              scrollRef={scrollRef}
            >
              <TableGrid
                className="pb-1.5"
                columnWidths={tableHeaders["searchResults"].map(({ width }) => width)}
              >
                {tableHeaders["searchResults"].map((item) => (
                  <HeaderCell border={false} className={item.className} key={item.id} size="short">
                    {item.label}
                  </HeaderCell>
                ))}
                {claimsData?.map((claim) => {
                  const selected = selectedClaimUuids.has(claim.uuid);

                  return (
                    <Row isSelected={selected} key={claim.uuid}>
                      <CheckboxCell
                        className={cxStyles.searchCell}
                        checked={selected}
                        onChange={() => onToggleClaim(claim.uuid)}
                        styleOptions={{ border: false, verticalPadding: "slim" }}
                        value={claim.uuid}
                      />
                      <ButtonCell
                        border={false}
                        className={cxStyles.searchCell}
                        onClick={() => onToggleClaim(claim.uuid)}
                        verticalPadding="slim"
                      >
                        {formatISODate(claim.serviceDate)}
                      </ButtonCell>
                      <ButtonCell
                        border={false}
                        className={cxStyles.searchCell}
                        onClick={() => onToggleClaim(claim.uuid)}
                        verticalPadding="slim"
                      >
                        {claim.patientName.fullDisplayName}
                      </ButtonCell>
                      <ButtonCell
                        border={false}
                        className={cxStyles.searchCell}
                        onClick={() => onToggleClaim(claim.uuid)}
                        verticalPadding="slim"
                      >
                        {claim.insuranceMemberId}
                      </ButtonCell>
                      <ButtonCell
                        border={false}
                        className={cx("justify-end", cxStyles.searchCell)}
                        onClick={() => onToggleClaim(claim.uuid)}
                        verticalPadding="slim"
                      >
                        {formatCurrency(claim.insuranceAmount)}
                      </ButtonCell>
                      <ButtonCell
                        border={false}
                        className={cxStyles.searchCell}
                        onClick={() => onToggleClaim(claim.uuid)}
                        verticalPadding="slim"
                      >
                        {claim.state === "SUBMITTED" ? (
                          <SubmittedIcon className="h-4 w-4 fill-orange" />
                        ) : claim.state === "COMPLETED" ? (
                          <CompletedIcon className="h-4 w-4 fill-green" />
                        ) : (
                          <UnsubmittedIcon className="h-4 w-4 fill-greyLight" />
                        )}
                      </ButtonCell>
                    </Row>
                  );
                })}
              </TableGrid>
            </ScrollableInfiniteQueryResult>
          </div>
        </Menu>
      )}
      <div className={cx("font-sansSemiBold text-sm mt-6 mb-1", cxStyles.shiftHack)}>
        Selected Claims ({selectedClaimUuids.size})
      </div>
      <div className={cx("overflow-y-scroll mb-4", cxStyles.shiftHack)}>
        <TableGrid columnWidths={tableHeaders["selectedClaims"].map(({ width }) => width)}>
          {tableHeaders["selectedClaims"].map((item) => (
            <Cell className={cx("py-2 font-sansSemiBold", item.className)} key={item.id}>
              {item.label}
            </Cell>
          ))}
          {[...selectedClaimUuids].map((claimUuid) => (
            <Row key={claimUuid}>
              <Cell className={cxStyles.selectedCell}>
                {claimsByIdMap[claimUuid]?.serviceDate
                  ? formatISODate(claimsByIdMap[claimUuid].serviceDate)
                  : null}
              </Cell>
              <Cell className={cxStyles.selectedCell}>
                {claimsByIdMap[claimUuid]?.patientName.fullDisplayName}
              </Cell>
              <Cell className={cxStyles.selectedCell}>
                {claimsByIdMap[claimUuid]?.insuranceMemberId ?? "SSN (Hidden)"}
              </Cell>
              <Cell className={cx("justify-end", cxStyles.selectedCell)}>
                {formatCurrency(claimsByIdMap[claimUuid]?.insuranceAmount ?? 0)}
              </Cell>
              <Cell className={cx("justify-end", cxStyles.selectedCell)}>
                {claimsByIdMap[claimUuid]?.state === "SUBMITTED" ? (
                  <SubmittedIcon className="h-4 w-4 fill-orange" />
                ) : (
                  <CompletedIcon className="h-4 w-4 fill-green" />
                )}
              </Cell>
              <ButtonCell
                className={cx("justify-end group-hover:bg-white", cxStyles.selectedCell)}
                hasPadding={false}
                onClick={() => onToggleClaim(claimUuid)}
                verticalPadding="slim"
              >
                <RemoveIcon className="h-4 w-4 fill-primaryTheme" />
              </ButtonCell>
            </Row>
          ))}
        </TableGrid>
      </div>
    </div>
  );
};
