import React, { FC } from "react";

import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { useAccount } from "@libs/contexts/AccountContext";
import { useDebouncedSearch } from "@libs/hooks/useDebouncedSearch";
import { useQueryParams } from "hooks/useQueryParams";
import { AppointmentsCompletedQuery } from "utils/routing/dashboard";
import { useTimeSeriesPageSelections } from "components/Dashboard/hooks/useTimeSeriesPageSelections";
import { getAppointmentBookingInfiniteQuery, getTimeSeriesQuery } from "api/reporting/queries";
import { dashboardFiltersForCharting, dashboardFiltersForTables } from "components/Dashboard/utils/filters";
import { AppointmentsCompletedChart } from "components/Dashboard/AppointmentsCompleted/AppointmentsCompletedChart";
import { AppointmentBookingTable } from "components/Dashboard/Tables/AppointmentBooking/AppointmentBookingTable";
import { DashboardLayout } from "components/Dashboard/DashboardLayout";
import { DashboardPageHeader } from "components/Dashboard/DashboardPageHeader";
import { FilterByProvider } from "components/Dashboard/Charting/FilterByProvider";
import { useProviderRollupQueries } from "components/Dashboard/hooks/useProviderRollupQueries";
import { useFormattedProviderRollups } from "components/Dashboard/hooks/useFormattedProviderRollups";
import { useFilteredProviderCategory } from "components/Dashboard/hooks/useFilteredProviderCategory";
import { useProviderFilterHandler } from "components/Dashboard/PracticeProduction/hooks/useProviderFilterHandler";
import { useDownloadAppointmentBookingCSV } from "components/Dashboard/Tables/AppointmentBooking/hooks/useDownloadAppointmentBookingCSV";
import { getDentalProceduresQuery } from "api/charting/queries";
import { MAX_PAGE_SIZE } from "components/Dashboard/Tables/utils";

export const AppointmentsCompletedRoute: FC = () => {
  const { practiceId } = useAccount();
  const { query, updateQuery } = useQueryParams("dashboardAppointmentsCompleted");
  const handleRouteStateChange = React.useCallback(
    (updates: Partial<AppointmentsCompletedQuery>) => {
      updateQuery("replaceIn", updates);
    },
    [updateQuery]
  );
  const dateWindow = useTimeSeriesPageSelections(query);
  const { selectedTimeSegment, timeSeriesParams, selectedTimeSegmentParams } = dateWindow;
  const providerFilters = useFilteredProviderCategory(query.filters);

  const handleProviderFilterClicked = useProviderFilterHandler({
    filters: query.filters,
    onUpdateFilters: handleRouteStateChange,
  });

  const requestParams = {
    resolution: query.resolution,
  };
  const { providersInTimeWindowQuery, allProvidersQuery } = useProviderRollupQueries({
    metric: "appointmentCount",
    dateWindow,
    filters: undefined,
  });

  const [
    appointmentsCompletedQuery,
    allDentalProceduresQuery,
    providersInTimeWindowQueryResult,
    allProvidersQueryResult,
  ] = useApiQueries([
    getTimeSeriesQuery({
      args: {
        practiceId,
        data: {
          ...requestParams,
          ...timeSeriesParams,
          filters: dashboardFiltersForCharting(query.filters),
          metric: "appointmentCount",
          groupBy: "appointmentState",
        },
      },
    }),
    getDentalProceduresQuery({ args: { practiceId } }),
    providersInTimeWindowQuery,
    allProvidersQuery,
  ]);
  const {
    data: providerFilterOptions,
    isLoading: isLoadingProviderRollups,
    selectedProvider,
  } = useFormattedProviderRollups(providersInTimeWindowQueryResult, allProvidersQueryResult, {
    ...providerFilters,
  });
  const { search: debouncedSearch } = useDebouncedSearch(query.patientSearch ?? "");
  const appointmentBookingInfiniteQuery = useInfiniteApiQuery(
    getAppointmentBookingInfiniteQuery({
      args: {
        practiceId,
        pageNumber: 1,
        pageSize: MAX_PAGE_SIZE,
        data: {
          ...selectedTimeSegmentParams,
          sortOrders: query.tableSort,
          filters: dashboardFiltersForTables(query.filters),
          includeTotal: true,

          patientSearchString: debouncedSearch,
        },
      },
    })
  );
  const { isDownloading, downloadCSV } = useDownloadAppointmentBookingCSV({
    appointmentBookingInfiniteQuery,
    selectedTimeSegment,
  });

  return (
    <DashboardLayout
      header={
        <>
          <DashboardPageHeader
            question="appointments-completed"
            focusDate={query.focusDate}
            resolution={query.resolution}
            chartDisplay={query.chartDisplay}
            dateWindow={dateWindow}
            onUpdateParams={handleRouteStateChange}
          />
          {!query["table.fullScreen"] && (
            <AppointmentsCompletedChart
              dateWindow={dateWindow}
              resolution={query.resolution}
              filters={query.filters}
              chartDisplay={query.chartDisplay}
              timeSeries={appointmentsCompletedQuery.data}
              isLoading={appointmentsCompletedQuery.isLoading}
              onUpdateParams={handleRouteStateChange}
              focusDate={query.focusDate}
            >
              <FilterByProvider
                loadFailed={Boolean(allProvidersQueryResult.error || providersInTimeWindowQueryResult.error)}
                providerFilterOptions={providerFilterOptions}
                providerFilters={providerFilters}
                isLoading={isLoadingProviderRollups}
                onProviderClicked={handleProviderFilterClicked}
              />
            </AppointmentsCompletedChart>
          )}
        </>
      }
      className="min-w-[1400px]"
    >
      <AppointmentBookingTable
        allDentalProceduresQuery={allDentalProceduresQuery}
        query={query}
        debouncedSearch={debouncedSearch}
        selectedProvider={selectedProvider}
        providerFilterOptions={providerFilterOptions}
        filtersAvailable={["appointmentState", "patientType", "hasNextAppointment"]}
        onUpdateParams={handleRouteStateChange}
        tableExpanded={query["table.fullScreen"]}
        onClickDownload={downloadCSV}
        appointmentBookingInfiniteQuery={appointmentBookingInfiniteQuery}
        isDownloadingTable={isDownloading}
      />
    </DashboardLayout>
  );
};
