import { FC, useEffect, useMemo, useRef } from "react";
import { useTransition, animated, easings } from "@react-spring/web";
import { cx } from "@libs/utils/cx";
import { isDefined } from "@libs/utils/types";
import { resolveRoute } from "components/Notes/usePatientNotesRouter";

import { cxFlyoverWidth } from "components/UI/FlyoverV2";
import { NoteRouteProps } from "components/Notes/types";

const NotesRoute: FC<NoteRouteProps> = ({ url, noteSessionId }) => {
  const Route = useMemo(() => {
    const match = resolveRoute(url);

    return match?.route.Component;
  }, [url]);

  return Route ? <Route url={url} noteSessionId={noteSessionId} /> : null;
};

export const NotesFlyoverContent: FC<Pick<NoteRouteProps, "url">> = ({ url }) => {
  const lastIndexRef = useRef(-1);
  const mountedRef = useRef(false);
  const pathname = url.split("?")[0];
  const lastPathnameRef = useRef(pathname);
  const noteSessionIdRef = useRef(`${Date.now()}`);
  const index = useMemo(() => {
    const match = resolveRoute(pathname);

    return match?.index;
  }, [pathname]);

  const transitions = useTransition(
    url,
    // don't animate if initial render or only the query string changes
    lastPathnameRef.current === pathname || !mountedRef.current || !isDefined(index)
      ? {}
      : {
          from: {
            transform: index - lastIndexRef.current > 0 ? "translateX(100%)" : "translateX(-100%)",
          },
          enter: { transform: "translateX(0%)" },
          leave: {
            transform: index - lastIndexRef.current > 0 ? "translateX(-100%)" : "translateX(100%)",
          },
          config: {
            duration: 300,
            easing: easings.easeOutExpo,
          },
        }
  );

  useEffect(() => {
    mountedRef.current = true;
  }, []);

  useEffect(() => {
    if (isDefined(index)) {
      lastIndexRef.current = index;
    }
  }, [index]);

  useEffect(() => {
    lastPathnameRef.current = pathname;
  }, [pathname]);

  return transitions((style, item) => (
    <animated.div
      className={cx("h-full flex flex-col absolute top-0 left-0", cxFlyoverWidth.md)}
      style={style}
    >
      <NotesRoute url={item} noteSessionId={noteSessionIdRef.current} />
    </animated.div>
  ));
};
