import { FC } from "react";

import { Flyover } from "components/UI/Flyover";
import { FlyoverContent } from "components/UI/FlyoverComponents";

interface Props {
  note: string;
  onClose: Func;
}

export const NoteFlyover: FC<Props> = ({ note, onClose }) => {
  return (
    <Flyover
      title="Note"
      onClose={onClose}
      clickAwayToClose
      headerSize="sm"
      size="md"
      dataTestId="imported-ledger-note-flyover"
    >
      <FlyoverContent paddingClassName="p-6">
        <p className="text-xs whitespace-pre-wrap">{note}</p>
      </FlyoverContent>
    </Flyover>
  );
};
