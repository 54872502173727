import { FC, useCallback, useEffect, useState } from "react";
import { AppointmentVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { FlyoverContent, FlyoverHeader } from "components/UI/FlyoverComponents";
import { HolderSection } from "components/ScheduleAppointments/HolderSection";

export const AppointmentHoldsDrawer: FC<{
  animateOpen: boolean;
  appointmentId?: number;
  hipaaView: boolean;
  appointmentHoldsQuery: ApiQueryResult<AppointmentVO[]>;
  toggleHolds: Func;
  onHoldDelete: (appt: AppointmentVO) => void;
  onHoldClick: (appt: AppointmentVO) => void;
  onHoldDoubleClick: (appt: AppointmentVO) => void;
}> = ({
  animateOpen,
  appointmentHoldsQuery,
  hipaaView,
  appointmentId,
  toggleHolds,
  onHoldDelete,
  onHoldClick,
  onHoldDoubleClick,
}) => {
  const [{ isOpen, isRevealed }, setState] = useState({
    isOpen: !animateOpen,
    isRevealed: false,
  });

  const startClose = useCallback(() => {
    setState((last) => ({ ...last, isOpen: false }));
  }, []);

  useEffect(() => {
    setState((last) => ({ ...last, isOpen: true }));
  }, []);

  return (
    <div
      onTransitionEnd={(e) => {
        if (e.propertyName === "width") {
          if (isOpen) {
            setState((last) => ({ ...last, isRevealed: true }));
          } else {
            toggleHolds();
          }
        }
      }}
      className={cx(
        `flex-none
         h-full
         transition-[width]
         will-change-[width]
         relative`,
        isOpen ? "w-96 shadow-[0px_0px_24px_0px_#0000003D]" : "w-0",
        (!isOpen || !isRevealed) && "overflow-hidden"
      )}
    >
      <div
        className={cx(
          `h-full
           w-96
           flex
           flex-col
           transition-opacity
           bg-white
           border
           border-t-0
           border-greyLighter`,
          isOpen ? "opacity-100" : "opacity-0"
        )}
      >
        <FlyoverHeader size="sm" onClose={startClose}>
          Appointment Holder
        </FlyoverHeader>
        <FlyoverContent paddingClassName="p-6">
          <QueryResult queries={[appointmentHoldsQuery]}>
            <HolderSection
              hipaaView={hipaaView}
              appointmentId={appointmentId}
              appointmentHolds={appointmentHoldsQuery.data}
              onHolderClick={onHoldClick}
              onHolderDoubleClick={onHoldDoubleClick}
              onRequestHolderDelete={onHoldDelete}
            />
          </QueryResult>
        </FlyoverContent>
      </div>
    </div>
  );
};
