import { PropsWithChildren, useEffect, useState } from "react";
import { SelfBookingConfigVO, SelfBookingConfigRequest } from "@libs/api/generated-api";
import { pluralize } from "@libs/utils/pluralize";
import { Icon } from "@libs/components/UI/Icon";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { ReactComponent as CopyIcon } from "@libs/assets/icons/copy.svg";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info-small.svg";
import { RequiredAsterisk } from "@libs/components/UI/RequiredAsterisk";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { Switch } from "@libs/components/UI/Switch";
import { ReactComponent as CommunicationsImage } from "assets/components/communications-1.svg";
import { OnlineBookingSettingsLink } from "components/Settings/Scheduling/Sections/OnlineBooking/OnlineBookingSettingsLink";
import { ReactComponent as AppointmentCategoriesImage } from "assets/components/appointment-categories.svg";
import { ReactComponent as ProviderSchedulingImage } from "assets/components/provider-scheduling.svg";
import { ReactComponent as RoomsImage } from "assets/components/rooms.svg";
import { paths } from "utils/routing/paths";
import { useEnvContext } from "contexts/EnvContext";
import { ToggleButtonList } from "components/UI/ToggleButtonList";
import { ShortListTable } from "components/Settings/Scheduling/Sections/OnlineBooking/ShortListTable";

export type SelfBookingConfigRequestSaveData = Partial<
  Pick<SelfBookingConfigRequest, "allowAsap" | "enabled" | "patientFacingCarrierNames" | "useCustomCarriers">
>;

export const OnlineBookingSection: React.FC<PropsWithChildren & { description?: string; title: string }> = ({
  children,
  description,
  title,
}) => {
  return (
    <div className="flex flex-col gap-y-1 text-xs">
      <div className="flex font-sansSemiBold">
        {title}
        <RequiredAsterisk />
      </div>
      {description && <span>{description}</span>}
      {children}
    </div>
  );
};

export const OnlineBooking: React.FC<{
  isSaving: boolean;
  onSave: (selfBookingConfig: SelfBookingConfigRequestSaveData, onSuccess: Func | undefined) => void;
  providersCount: number;
  selfBookingConfig: SelfBookingConfigVO;
}> = ({ isSaving, onSave, providersCount, selfBookingConfig }) => {
  const { REACT_APP_PATIENT_PORTAL_HOST } = useEnvContext();
  const practice = useCurrentPractice();

  const [state, setState] = useState<SelfBookingConfigRequestSaveData>(() => ({ ...selfBookingConfig }));

  useEffect(() => {
    setState({ ...selfBookingConfig });
  }, [selfBookingConfig]);

  const handleChangeState = (updatedState: SelfBookingConfigRequestSaveData, onSuccess?: Func) => {
    const mergedState = { ...state, ...updatedState };

    setState(mergedState);
    onSave(mergedState, onSuccess);
  };

  const enabled = Boolean(state.enabled);
  const allowAsap = Boolean(state.allowAsap);
  const useCustomCarriers = Boolean(state.useCustomCarriers);

  const portalUrl = `${REACT_APP_PATIENT_PORTAL_HOST}/p/${practice.uuid}/self-booking`;

  const categoriesCount = selfBookingConfig.appointmentCategories.length || 0;

  return (
    <div className="flex h-full">
      <div className="flex-1 flex flex-col p-6 gap-y-6">
        <div className="text-sm font-sansSemiBold">General Settings</div>
        <OnlineBookingSection
          description="Switch this on to allow your patients to book their own appointments online."
          title="Online Booking"
        >
          <div className="p-1 mt-1">
            <Switch
              checked={enabled}
              name="Allow Online Booking"
              onChange={(e) => handleChangeState({ enabled: e.target.checked })}
            >
              <div>{enabled ? "On" : "Off"}</div>
            </Switch>
          </div>
        </OnlineBookingSection>
        {enabled && (
          <>
            <OnlineBookingSection
              description="Allow your patients to add themselves to the ASAP list when booking online."
              title="Allow ASAP"
            >
              <div className="p-1 mt-1">
                <Switch
                  checked={allowAsap}
                  name="Allow ASAP"
                  onChange={(e) => handleChangeState({ allowAsap: e.target.checked })}
                >
                  <div>{allowAsap ? "On" : "Off"}</div>
                </Switch>
              </div>
            </OnlineBookingSection>
            <div
              className={`
                flex
                flex-row
                items-center
                max-w-xl
                px-3
                py-2
                gap-x-3
                text-xs
                rounded
                bg-slate-100
              `}
            >
              <span>
                People can now book appointments by visiting
                <a href={portalUrl} target="_blank" className="text-primaryTheme ml-1 underline">
                  {portalUrl}
                </a>
              </span>
              <ButtonIcon
                className="ml-4"
                SvgIcon={CopyIcon}
                onClick={() => portalUrl && navigator.clipboard.writeText(portalUrl)}
                theme="primary"
                tooltip={{
                  content: "Copy Link",
                  theme: "SMALL",
                }}
              />
            </div>
            <OnlineBookingSection
              description="Set which insurance carriers show when patients are asked to select their provider."
              title="Insurance Options"
            >
              <div className="mt-2">
                <ToggleButtonList
                  onChange={(e) => {
                    handleChangeState({
                      useCustomCarriers: e.target.value === "CUSTOM",
                      patientFacingCarrierNames: selfBookingConfig.patientFacingCarrierNames,
                    });
                  }}
                  options={[
                    {
                      label: (
                        <div className="flex items-center gap-x-1">
                          <span>Complete List</span>
                          <Icon
                            SvgIcon={InfoIcon}
                            tooltip={{
                              content:
                                "Allow patients to select any option from Archy's complete list of insurance carriers. In-network carriers are listed first.",
                            }}
                          />
                        </div>
                      ),
                      value: "ARCHY",
                    },
                    {
                      label: (
                        <div className="flex items-center gap-x-1">
                          <span>Short List</span>
                          <Icon
                            SvgIcon={InfoIcon}
                            tooltip={{
                              content: "Show patients a short list of Insurance Carriers defined by you.",
                            }}
                          />
                        </div>
                      ),
                      value: "CUSTOM",
                    },
                  ]}
                  selectedValue={useCustomCarriers ? "CUSTOM" : "ARCHY"}
                  shape="pill"
                  type="radio"
                />
              </div>
            </OnlineBookingSection>
            {useCustomCarriers && (
              <OnlineBookingSection
                description="Patients will be able to select one of these options or write in their own."
                title="Short List"
              >
                <ShortListTable
                  isSaving={isSaving}
                  selfBookingConfig={selfBookingConfig}
                  onUpdateConfig={handleChangeState}
                />
              </OnlineBookingSection>
            )}
          </>
        )}
      </div>
      <div
        className={`
          flex
          flex-col
          w-[576px]
          p-6
          gap-y-6
          bg-slate-50
          border-l
          border-slate-200
        `}
      >
        <div className="flex flex-col">
          <div className="text-sm font-sansSemiBold">General Settings</div>
          <div className="text-xs">Below are additional settings in Archy you may need to update.</div>
        </div>
        <OnlineBookingSettingsLink
          count={enabled ? categoriesCount : undefined}
          description="Designate which categories are allowed for online booking and whether the appointment can be scheduled or requested."
          Icon={AppointmentCategoriesImage}
          linkName="Appointment Categories"
          linkTo={paths.settingsSection({ section: "appointment-categories" })}
          title={pluralize(categoriesCount, `Category Available`, `Categories Available`)}
        />
        <OnlineBookingSettingsLink
          count={enabled ? providersCount : undefined}
          description="For each provider, designate which of their working hours allow for online booking."
          Icon={ProviderSchedulingImage}
          linkName="Provider Scheduling"
          linkTo={paths.settingsSection({ section: "provider-scheduling" })}
          title={pluralize(providersCount, `Provider Available`, `Providers Available`)}
        />
        <OnlineBookingSettingsLink
          count={undefined}
          description="Set up an automatic message that confirms the appointment request was received."
          Icon={CommunicationsImage}
          linkName="Communications"
          linkTo={paths.communications()}
          title="Communications"
        />
        <OnlineBookingSettingsLink
          count={undefined}
          description="Optionally associate providers with specific rooms. Online booking will prioritize the room assigned to a provider when scheduling the appointment."
          Icon={RoomsImage}
          linkName="Rooms"
          linkTo={paths.settingsSection({ section: "practice-rooms" })}
          title="Rooms"
        />
      </div>
    </div>
  );
};
