import { FC, useCallback, useEffect, useMemo } from "react";
import { EmployeeVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { pluralize } from "@libs/utils/pluralize";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { flattenPages } from "@libs/utils/queries";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { useAccount } from "@libs/contexts/AccountContext";
import { useNavigate } from "react-router-dom";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { getEmployeeOverview, getInfiniteEmployees } from "api/employee/queries";
import { getCompanyOnboardingStatus } from "api/payroll/queries";
import { deleteEmployee } from "api/employee/mutations";
import { CreateEmployeeFormContainer } from "components/Employee/CreateEmployeeFormContainer";
import { handleError } from "utils/handleError";
import { MailToLink } from "components/UI/MailToLink";
import { EmployeesTableSection } from "components/Employee/EmployeesTableSection";
import { useSelectRows } from "hooks/useSelectRows";
import { useQueryParams } from "hooks/useQueryParams";
import { EmployeesQueryUpdates, NO_STATUS_FILTER } from "utils/routing/employees";
import { useItemModal } from "hooks/useItemModal";
import { EmployeesAppHistoryProvider } from "components/Employee/EmployeesLinksContext";
import { getPracticeRolesV2 } from "api/practice/queries";
import { FlyoverV2 } from "components/UI/FlyoverV2";
import { paths } from "utils/routing/paths";

export const EmployeesRoute: FC = () => {
  const navigate = useNavigate();
  const { query, updateQuery } = useQueryParams("employees");

  usePageTitle("Employees");

  const { practiceId } = useAccount();

  const successModal = useItemModal<EmployeeVO>(null);
  const archiveModal = useItemModal<Set<number>>(null);
  const deleteModal = useItemModal<Set<number>>(null);
  const createModal = useBoolean(false);

  const employeesQueryKey = useMemo(() => {
    // We need an easily comparable value for knowing when the employees search has changed
    return {
      ...query,
      statuses: query.status.filter((s) => s !== NO_STATUS_FILTER),
      practiceId,
      searchString: query.search,
    };
  }, [query, practiceId]);

  const [employeeOverviewQuery, { data: payrollOnboarding }, rolesQuery] = useApiQueries([
    getEmployeeOverview({ args: { practiceId } }),
    getCompanyOnboardingStatus({ args: { practiceId } }),
    getPracticeRolesV2({ args: { practiceId } }),
  ]);

  const employeesQuery = useInfiniteApiQuery(
    getInfiniteEmployees({
      args: {
        ...employeesQueryKey,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
      },
    })
  );

  const [{ mutate: mutateDelete, isLoading: isDeletingEmployee }] = useApiMutations([deleteEmployee]);

  const { employeeIds } = useMemo(() => {
    const flattened = flattenPages(employeesQuery.data);

    return {
      employeeIds: flattened?.map((empl) => empl.id),
    };
  }, [employeesQuery]);

  const { selectedRows, resetSelectedRows, handleCheckboxChange } = useSelectRows(employeeIds);

  const handleDeleteEmployee = useCallback(
    (action: "DELETE" | "ARCHIVE", employeesToDelete: Set<number>) => {
      mutateDelete(
        { practiceId, action, ids: [...employeesToDelete] },
        {
          onError: (err) => handleError(err),
          onSuccess: (_, { action: deleteAction }) => {
            resetSelectedRows();

            if (deleteAction === "ARCHIVE") {
              archiveModal.close();
            } else {
              deleteModal.close();
            }
          },
        }
      );
    },
    [deleteModal, archiveModal, mutateDelete, practiceId, resetSelectedRows]
  );

  const handleQueryParamsChange = useCallback(
    (keyValues: EmployeesQueryUpdates) => {
      updateQuery("replaceIn", keyValues);
    },
    [updateQuery]
  );

  // anytime the employees query changes clear selected rows
  useEffect(() => {
    resetSelectedRows();
  }, [employeesQueryKey, resetSelectedRows]);

  return (
    <EmployeesAppHistoryProvider name="employees">
      <div className="h-full">
        <QueryResult queries={[employeeOverviewQuery, rolesQuery]}>
          {employeeOverviewQuery.data && rolesQuery.data ? (
            <EmployeesTableSection
              queryState={query}
              queryKey={employeesQueryKey}
              roles={rolesQuery.data}
              selectedRows={selectedRows}
              onAddEmployee={createModal.on}
              onArchiveEmployees={() => archiveModal.open(selectedRows)}
              onDeleteEmployees={() => deleteModal.open(selectedRows)}
              employeesQuery={employeesQuery}
              employeeOverview={employeeOverviewQuery.data}
              onQueryParamsChange={handleQueryParamsChange}
              onToggleRow={handleCheckboxChange}
            />
          ) : null}
        </QueryResult>
      </div>
      {successModal.isOpen ? (
        <ConfirmationModal
          size="xs"
          primaryText="Employee Created"
          secondaryText="You can now access the employee profile to add new hire documents and edit any information."
          confirmText="View Profile"
          cancelText="Close"
          onCancel={successModal.close}
          onConfirm={() => navigate(paths.employee({ id: successModal.item.id }))}
        />
      ) : null}
      <FlyoverV2 width="md" isOpen={createModal.isOn}>
        <CreateEmployeeFormContainer
          onSave={(newEmployee) => {
            createModal.off();
            successModal.open(newEmployee);
          }}
          onRequestClose={createModal.off}
        />
      </FlyoverV2>
      {deleteModal.isOpen ? (
        <ConfirmationModal
          primaryText={`Are you sure you want to delete ${pluralize(
            deleteModal.item.size,
            "this employee",
            "these employees"
          )}?`}
          secondaryText={`If you confirm, all data linked to ${pluralize(
            deleteModal.item.size,
            "this employee",
            "these employees"
          )} will be permanently deleted and will no longer be accessible to the practice.`}
          onCancel={deleteModal.close}
          onConfirm={() => handleDeleteEmployee("DELETE", deleteModal.item)}
          isConfirming={isDeletingEmployee}
        />
      ) : null}
      {archiveModal.isOpen ? (
        <ConfirmationModal
          primaryText={`Are you sure you want to terminate ${pluralize(
            archiveModal.item.size,
            "this employee",
            "these employees"
          )}?`}
          secondaryText={
            <span className="flex flex-col gap-y-4">
              They will lose access to the app and their end date will be today. If custom end dates are
              needed, perform the termination through their employee profile.
              {payrollOnboarding && payrollOnboarding.status !== "NOT_SETUP" ? (
                <span>
                  Please contact Archy support at <MailToLink email="support@archy.com" /> to run a dismissal
                  payroll for {pluralize(archiveModal.item.size, "this employee", "these employees")}.
                </span>
              ) : null}
            </span>
          }
          onCancel={archiveModal.close}
          onConfirm={() => handleDeleteEmployee("ARCHIVE", archiveModal.item)}
          isConfirming={isDeletingEmployee}
        />
      ) : null}
    </EmployeesAppHistoryProvider>
  );
};
