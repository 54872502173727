import { useCallback } from "react";
import { DentalProcedureVO, PatientToothVO } from "@libs/api/generated-api";
import { isToothDisabledByDentalProcedure } from "components/Charting/patientProcedureSchema";

export const useIsToothDisabledByDentalProcedure = (dentalProcedure: DentalProcedureVO) => {
  return useCallback(
    (tooth: PatientToothVO) => isToothDisabledByDentalProcedure(tooth, dentalProcedure),
    [dentalProcedure]
  );
};
