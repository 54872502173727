import React, { useEffect, useId, useState } from "react";
import { ScheduleBlockVO, UpdateScheduleBlockRequest } from "@libs/api/generated-api";
import { isDefined } from "@libs/utils/types";
import { useBoolean } from "@libs/hooks/useBoolean";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { manuallyLoadFromServer } from "@libs/utils/queryCache";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { MainContent } from "@libs/components/UI/MainContent";
import { getPracticeRoomsQuery, getScheduleBlockInstance } from "api/scheduling/queries";
import { getPracticeProvidersQuery } from "api/practice/queries";
import { paths } from "utils/routing/paths";
import {
  updateScheduleBlock,
  updateRecurringScheduleBlockFuture,
  updateRecurringScheduleBlockInstance,
} from "api/scheduling/mutations";
import { handleError } from "utils/handleError";
import { useQueryParams } from "hooks/useQueryParams";
import { usePathParams } from "hooks/usePathParams";
import { EventModalPage } from "components/ScheduleAppointments/EventModalPage";
import { BlockForm } from "components/ScheduleAppointments/BlockForm";
import { EventModalTitleContent } from "components/ScheduleAppointments/EventModalTitleContent";
import { useItemModal } from "hooks/useItemModal";
import {
  ChangeMode,
  ChangeModeOption,
  RecurringBlockModal,
  changeOptions,
} from "components/ScheduleAppointments/RecurringBlockModal";
import { isSameRecurrence } from "components/ScheduleAppointments/isSameRecurrence";
import { getUpdatedRecurrence } from "components/ScheduleAppointments/getUpdatedRecurrence";

// eslint-disable-next-line complexity
export const EditBlockRoute: React.FC = () => {
  const { practiceId } = useAccount();
  const [savedBlock, setSavedBlock] = useState<null | ScheduleBlockVO>(null);
  const { query } = useQueryParams("editBlock");
  const { blockId, instanceDate } = usePathParams("editBlock");
  const editRepeatBlockModal = useItemModal<{
    options: ChangeModeOption[];
    blockId: number;
    updates: UpdateScheduleBlockRequest;
  }>(null);
  const formDirty = useBoolean(false);
  const queries = useApiQueries([
    getPracticeProvidersQuery({ args: { practiceId } }),
    getPracticeRoomsQuery({ args: { practiceId } }),
    getScheduleBlockInstance({
      args: { practiceId, blockId, instanceDate },
      queryOptions: manuallyLoadFromServer,
    }),
  ]);

  const [providersQuery, roomsQuery, blockQuery] = queries;

  const previouslySavedRecurrence = blockQuery.data?.recurrence;

  const [
    updateScheduleBlockMutation,
    updateRecurringScheduleBlockFutureMutation,
    updateRecurringScheduleBlockInstanceMutation,
  ] = useApiMutations([
    updateScheduleBlock,
    updateRecurringScheduleBlockFuture,
    updateRecurringScheduleBlockInstance,
  ]);

  const backUrl = query.from ?? paths.schedule();

  const handleUpdateAll = (data: UpdateScheduleBlockRequest) => {
    updateScheduleBlockMutation.mutate(
      {
        practiceId,
        blockId,
        data,
      },
      {
        onSuccess: (response) => setSavedBlock(response.data.data),
        onError: handleError,
      }
    );
  };

  const handleUpdateInstance = (data: UpdateScheduleBlockRequest) => {
    updateRecurringScheduleBlockInstanceMutation.mutate(
      {
        practiceId,
        blockId,
        instanceDate,
        data: {
          ...data,
          recurrence: undefined,
        },
      },
      {
        onSuccess: (response) => setSavedBlock(response.data.data),
        onError: handleError,
      }
    );
  };

  const handleUpdateFutureItems = (data: UpdateScheduleBlockRequest) => {
    updateRecurringScheduleBlockFutureMutation.mutate(
      {
        practiceId,
        blockId,
        instanceDate,
        data,
      },
      {
        onSuccess: (response) => setSavedBlock(response.data.data),
        onError: handleError,
      }
    );
  };

  const handleChangeMode = (changeMode: ChangeMode, updates: UpdateScheduleBlockRequest) => {
    if (changeMode === "CHANGE_ALL") {
      handleUpdateAll(updates);
    } else if (changeMode === "CHANGE_ONE") {
      handleUpdateInstance(updates);
    } else {
      const didChangeDay = updates.date !== instanceDate;
      const didChangeRecurrence = !isSameRecurrence(updates.recurrence, previouslySavedRecurrence);

      handleUpdateFutureItems({
        ...updates,
        recurrence:
          didChangeDay && !didChangeRecurrence
            ? getUpdatedRecurrence({
                newDate: updates.date,
                instanceDate,
                currentRecurrence: updates.recurrence,
              })
            : updates.recurrence,
      });
    }
  };

  const handleSubmit = (data: UpdateScheduleBlockRequest) => {
    // If the block previously had a saved recurrence and the update
    // still includes recurrence we need to inspect the updates to determine
    // how the changes should apply or if we need to ask the user
    // how they should apply
    if (previouslySavedRecurrence && data.recurrence) {
      const didChangeDay = data.date !== instanceDate;
      const didChangeRecurrence = !isSameRecurrence(data.recurrence, previouslySavedRecurrence);

      if (didChangeDay && didChangeRecurrence) {
        // if the user changes both the day and recurrence we asssume
        // they want this to apply to future updates only
        handleUpdateFutureItems(data);
      } else {
        let filteredChangeOptions: ChangeModeOption[] = changeOptions;

        if (didChangeDay) {
          filteredChangeOptions = filteredChangeOptions.filter((op) => op.value !== "CHANGE_ALL");
        }

        if (didChangeRecurrence) {
          filteredChangeOptions = filteredChangeOptions.filter((op) => op.value !== "CHANGE_ONE");
        }

        editRepeatBlockModal.open({
          options: filteredChangeOptions,
          blockId,
          updates: data,
        });
      }
    } else {
      handleUpdateAll(data);
    }
  };

  const formId = useId();

  const fetchBlock = blockQuery.refetch;

  useEffect(() => {
    fetchBlock();
  }, [fetchBlock]);

  return (
    <>
      <MainContent>
        <EventModalPage
          formId={formId}
          backUrl={backUrl}
          title={<EventModalTitleContent title="Edit Time Block" />}
          hasSavedEvent={Boolean(savedBlock)}
          eventUrl={
            savedBlock
              ? paths.schedule({
                  // If the block is only for providers show group by providers view
                  groupAppointmentsBy:
                    savedBlock.providers.length && !savedBlock.rooms.length ? "provider" : "room",
                  date: savedBlock.date,
                })
              : ""
          }
          isSaving={
            updateScheduleBlockMutation.isLoading ||
            updateRecurringScheduleBlockFutureMutation.isLoading ||
            updateRecurringScheduleBlockInstanceMutation.isLoading
          }
          isFormValid={true}
          isDirty={formDirty.isOn}
          savedValue={savedBlock}
          savedMessage="Time Block Updated"
          promptMessage="Do you want to discard changes to this time block?"
        >
          <QueryResult queries={[providersQuery, roomsQuery, blockQuery]}>
            {providersQuery.data && roomsQuery.data && blockQuery.data ? (
              <BlockForm
                savedBlock={blockQuery.data}
                comments={blockQuery.data.comments ?? ""}
                providerIds={blockQuery.data.providers.map((p) => p.id)}
                roomIds={blockQuery.data.rooms.map(({ id }) => id).filter(isDefined)}
                startTime={blockQuery.data.startTime}
                endTime={blockQuery.data.endTime}
                date={blockQuery.data.date}
                color={blockQuery.data.color}
                title={blockQuery.data.title}
                recurrence={blockQuery.data.recurrence}
                formId={formId}
                rooms={roomsQuery.data}
                providers={providersQuery.data}
                onSubmit={handleSubmit}
                onDirty={formDirty.on}
              />
            ) : null}
          </QueryResult>
        </EventModalPage>
      </MainContent>
      {editRepeatBlockModal.isOpen ? (
        <RecurringBlockModal
          options={editRepeatBlockModal.item.options}
          title="Edit Recurring Block"
          onClose={editRepeatBlockModal.close}
          onCancel={editRepeatBlockModal.close}
          onConfirm={(changeMode) => {
            handleChangeMode(changeMode, editRepeatBlockModal.item.updates);
            editRepeatBlockModal.close();
          }}
        />
      ) : null}
    </>
  );
};
