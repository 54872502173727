import { FC, useCallback } from "react";
import Skeleton from "react-loading-skeleton";

import { MessageVO, RankedPatientMessageVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatUnixTimestampRelative } from "@libs/utils/date";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { useFlattenPages } from "@libs/hooks/useFlattenPages";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { ReactComponent as DeleteIcon } from "@libs/assets/icons/delete.svg";
import { Button } from "@libs/components/UI/Button";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { ScrollableInfiniteQueryResult } from "@libs/components/UI/ScrollableInfiniteQueryResult";

import { ConversationSeparator } from "components/Messaging/ConversationSeparator";
import { ChatBubble } from "components/Messaging/ChatBubble";

import { MessagingMenu } from "components/Messaging/MessagingMenu";

import EmptyMessages from "assets/images/empty-messages.svg";

import { useNow } from "hooks/useNow";

const DateRelativeLocaleFormat = {
  today: "'Today'",
  tomorrow: "'Tomorrow,' eeee, MMMM do",
  nextWeek: "'Next Week,' eeee, MMMM do",
  yesterday: "'Yesterday'",
  lastWeek: "eeee, MMMM do",
  other: "MMMM d, yyyy",
};

const TimeRelativeLocaleFormat = {
  today: "p",
  tomorrow: "p",
  nextWeek: "p",
  yesterday: "p",
  lastWeek: "p",
  other: "p",
};

const getRelativeDateLabel = (messages: MessageVO[], timestamp: number, now: Date, index: number) => {
  const isLastMessage = index === messages.length - 1;
  const previousTimestamp = isLastMessage ? null : messages[index + 1].timestamp;
  const previousRelativeDate = previousTimestamp
    ? formatUnixTimestampRelative(previousTimestamp, now, DateRelativeLocaleFormat)
    : null;
  const currentRelativeDate = formatUnixTimestampRelative(timestamp, now, DateRelativeLocaleFormat);

  return previousRelativeDate !== currentRelativeDate || isLastMessage ? currentRelativeDate : null;
};

interface Props {
  patientMessagesQuery: UseInfiniteApiQueryResult<MessageVO[]>;
  selectedPatient: RankedPatientMessageVO;
  onRetryFailedMessage: (messageId: number) => void;
  onRequestCancelScheduledMessage: (message: MessageVO) => void;
  onChangeReadStatus: (patienetId: number, isRead: boolean) => void;
}

export const MessagingConversation: FC<Props> = ({
  patientMessagesQuery,
  selectedPatient,
  onRetryFailedMessage,
  onRequestCancelScheduledMessage,
  onChangeReadStatus,
}) => {
  const now = useNow();
  const messages = useFlattenPages(patientMessagesQuery.data);
  const { rootRef, scrollRef } = useInfiniteScrollQuery({
    infiniteQuery: patientMessagesQuery,
  });

  const handleChangeReadStatus = useCallback(
    () => onChangeReadStatus(selectedPatient.patientId, selectedPatient.isRead ?? true),
    [onChangeReadStatus, selectedPatient]
  );

  return (
    <PersistScrollPosition
      ref={rootRef}
      id="messaging-conversation"
      className="flex flex-col-reverse h-full p-6 overflow-y-auto"
    >
      <ScrollableInfiniteQueryResult
        scrollRef={scrollRef}
        infiniteQuery={patientMessagesQuery}
        loading={
          <div className="flex flex-col-reverse h-full *:w-96">
            <Skeleton className="rounded-2xl h-16 my-1" containerClassName="self-end" count={1} />
            <Skeleton className="rounded-2xl h-16 my-1" containerClassName="self-start" count={2} />
            <Skeleton className="rounded-2xl h-16 my-1" containerClassName="self-end" count={1} />
            <Skeleton className="rounded-2xl h-16 my-1" containerClassName="self-start" count={1} />
            <Skeleton className="rounded-2xl h-16 my-1" containerClassName="self-end" count={2} />
          </div>
        }
        loadMore={
          <div className="flex flex-col-reverse h-full *:w-96">
            <Skeleton className="rounded-2xl h-16 my-1" containerClassName="self-end" count={1} />
            <Skeleton className="rounded-2xl h-16 my-1" containerClassName="self-start" count={1} />
          </div>
        }
      >
        {messages && messages.length > 0 ? (
          // eslint-disable-next-line complexity
          messages.map((message, index) => {
            const {
              id,
              timestamp,
              state,
              isOutgoing,
              content,
              sender,
              attachmentFolderId,
              attachmentDocumentIds,
            } = message;

            const relativeDateLabel = getRelativeDateLabel(messages, timestamp, now, index);
            const isFailed = isOutgoing && state === "FAILED";
            const isScheduled = state === "SCHEDULED";
            const isAutoText = Boolean(sender.actionUuid || sender.messageCampaignUuid);
            const outgoingTheme = isAutoText ? "autotext" : "practice";
            const theme = isScheduled ? "scheduled" : isOutgoing ? outgoingTheme : "patient";

            return (
              <div key={id} className="flex flex-col group">
                {relativeDateLabel ? <ConversationSeparator relativeDateLabel={relativeDateLabel} /> : null}

                <div className={cx("relative max-w-[40%] mt-2", isOutgoing && "ml-auto")}>
                  <ChatBubble
                    message={content}
                    displayName={sender.displayName}
                    timestamp={formatUnixTimestampRelative(timestamp, now, TimeRelativeLocaleFormat)}
                    patientId={selectedPatient.patientId}
                    attachmentFolderId={attachmentFolderId}
                    attachmentDocumentIds={attachmentDocumentIds}
                    theme={theme}
                  >
                    <div
                      className={cx(
                        "absolute top-1/2 -translate-y-1/2",
                        isOutgoing ? "-left-4 -translate-x-full" : "-right-4 translate-x-full"
                      )}
                    >
                      {isScheduled ? (
                        <ButtonIcon
                          SvgIcon={DeleteIcon}
                          tooltip={{ content: "Cancel Scheduled Message", theme: "SMALL" }}
                          onClick={() => onRequestCancelScheduledMessage(message)}
                        />
                      ) : (
                        <MessagingMenu
                          onChangeReadStatus={handleChangeReadStatus}
                          isRead={selectedPatient.isRead}
                          visibleOnGroupHover
                          placement={isOutgoing ? "left" : "right"}
                        />
                      )}
                    </div>
                  </ChatBubble>

                  {isFailed ? (
                    <div className="flex items-center justify-end gap-x-2 mt-1">
                      <span className="font-sansSemiBold text-xxs text-red">
                        Failed&nbsp;
                        <Button
                          className="text-red underline"
                          onClick={() => onRetryFailedMessage(id)}
                          theme="link"
                        >
                          Resend
                        </Button>
                      </span>
                      <Icon SvgIcon={ErrorIcon} theme="error" />
                    </div>
                  ) : null}
                </div>
              </div>
            );
          })
        ) : (
          <div className="flex flex-col flex-1 items-center justify-center gap-y-5">
            <img src={EmptyMessages} alt="Start a Conversation" />
            <span className="text-sm">Start a conversation</span>
          </div>
        )}
      </ScrollableInfiniteQueryResult>
    </PersistScrollPosition>
  );
};
