/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useCallback } from "react";
import { useDrop } from "react-dnd";
import { MedicalImageVO } from "@libs/api/generated-api";
import { CANVAS_BOUNDS } from "components/PatientProfile/Imaging/PatientMountsList/mountLayouts";
import { ImageDetailProps, ImageSelectMoveProps } from "components/PatientProfile/Imaging/MountRoute/types";
import { SANDBOX_NAVIGATION } from "components/PatientProfile/Imaging/MountRoute/ImageSandbox/constants";
import { ImageItem, ImageWrapperComponentType } from "./ImageItem";
import { ImageLayoutItem, ImageWithZoom } from "./types";

type Props = {
  images: ImageLayoutItem[];
  scale: number;
  resetTransform?: () => void;
  ImageWrapper?: ImageWrapperComponentType;
  nextCaptureSpot?: ImageLayoutItem;
} & ImageDetailProps &
  ImageSelectMoveProps;

export const ImageList: React.FC<Props> = ({
  onImageOriginChanged,
  images,
  onEditImage,
  onClickItem,
  onClickBackground,
  selectedImageIds,
  nextCaptureSpot,
  ...imageDetailProps
}) => {
  const { scale } = imageDetailProps;
  const [_, drop] = useDrop<ImageWithZoom, unknown, { isDragging: boolean }>(
    () => ({
      accept: SANDBOX_NAVIGATION,
      drop: (item, monitor) => {
        const delta = monitor.getDifferenceFromInitialOffset();

        if (delta) {
          const { image } = item;
          // Delta needs to be scaled by the current scale of the panner
          const x = Math.round(image.x + delta.x / scale);
          const y = Math.round(image.y + delta.y / scale);

          onImageOriginChanged(image, { x, y });
        }

        return undefined;
      },
    }),
    [onImageOriginChanged, scale]
  );

  const containerStyles = React.useMemo(() => {
    return {
      height: `${CANVAS_BOUNDS.h}px`,
      width: `${CANVAS_BOUNDS.w}px`,
    };
  }, []);

  const toggleImageEdits = useCallback(
    (image: Pick<MedicalImageVO, "id">) => {
      const selected = images.find((ele) => ele.id === image.id);

      if (selected) {
        onEditImage(image);
      }
    },
    [images, onEditImage]
  );

  return (
    <div ref={drop}>
      <div
        role="button"
        tabIndex={-1}
        onClick={onClickBackground}
        className="relative active:cursor-grabbing border border-greyMedium"
        style={containerStyles}
      >
        {images.map((item) => (
          <ImageItem
            image={item}
            onEditImage={toggleImageEdits}
            onClickItem={onClickItem}
            key={item.id}
            isSelected={Boolean(selectedImageIds?.has(item.id))}
            isNextUpload={selectedImageIds?.size === 0 && nextCaptureSpot?.i === item.i}
            {...imageDetailProps}
          />
        ))}
      </div>
    </div>
  );
};
