import { QueryClient } from "@tanstack/react-query";
import {
  CreateAdjustmentRequest,
  CreateBulkStatementRequest,
  CreateInvoiceRequest,
  CreatePaymentProfileByTokenRequest,
  CreateStatementRequest,
  EditAdjustmentRequest,
  EditMultiInvoicePaymentRequest,
  FinixTermsAcknowledgementRequest,
  MultiInvoicePaymentRequest,
  MultiInvoicePaymentVO,
  PaymentCorrectionRequest,
  UpdatePaymentProfileRequest,
  UpdatePracticeBillingSettingRequest,
  UpdateWalletRequest,
  WalletEditRequest,
  WalletRefundRequest,
  WalletTopupRequest,
} from "@libs/api/generated-api";
import { makeMutation } from "@libs/utils/mutations";
import { getQueryKey } from "@libs/utils/queries";
import { replaceCachedItemWithUpdatedItem, updateCachedData } from "@libs/utils/queryCache";

export const createInvoice = makeMutation({
  mutationKey: ["practices", "createInvoice"],
  formatParams: (args: { practiceId: number; data: CreateInvoiceRequest }) => [args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { data: { commit } }) => {
      // Invalidate queries only if we actually created (committed) the invoice.
      if (commit) {
        queryClient.invalidateQueries([getQueryKey("practices", "getLedgerBalanceSummary")]);
        queryClient.invalidateQueries([getQueryKey("practices", "getUncollectedByPatient")]);
        queryClient.invalidateQueries([getQueryKey("practices", "getPendingInvoiceEntries")]);
        queryClient.invalidateQueries([
          getQueryKey("practices", "getFamilyMembersWithPendingInvoiceEntries"),
        ]);
        queryClient.invalidateQueries([getQueryKey("practices", "getInvoices")]);

        // Reporting
        queryClient.invalidateQueries([getQueryKey("practices", "getPatientBalanceAging")]);
      }
    },
  }),
});

/**
 * Invalidates relevant queries after create/editing an adjustment.
 * @param queryClient The query client instance.
 * @param ids Optional object containing the updated adjustment UUID, if applicable.
 */
const decachePostAdjustment = (queryClient: QueryClient, ids?: { adjustmentUuid?: string }) => {
  queryClient.invalidateQueries([getQueryKey("practices", "getLedgerBalanceSummary")]);
  queryClient.invalidateQueries([getQueryKey("practices", "getPendingInvoiceEntries")]);
  queryClient.invalidateQueries([getQueryKey("practices", "getFamilyMembersWithPendingInvoiceEntries")]);

  if (ids?.adjustmentUuid != null) {
    queryClient.invalidateQueries([
      getQueryKey("practices", "getAdjustment"),
      { adjustmentUuid: ids.adjustmentUuid },
    ]);
  }

  // Reporting
  queryClient.invalidateQueries([getQueryKey("practices", "getPatientBalanceAging")]);
};

export const createAdjustment = makeMutation({
  mutationKey: ["practices", "createAdjustment"],
  formatParams: (args: { practiceId: number; patientId: number; data: CreateAdjustmentRequest }) => [
    args.practiceId,
    args.patientId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: () => {
      decachePostAdjustment(queryClient);
    },
  }),
});

export const editAdjustment = makeMutation({
  mutationKey: ["practices", "editAdjustment"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    adjustmentUuid: string;
    data: EditAdjustmentRequest;
  }) => [args.practiceId, args.patientId, args.adjustmentUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { practiceId, patientId, adjustmentUuid }) => {
      decachePostAdjustment(queryClient, { adjustmentUuid });
      replaceCachedItemWithUpdatedItem(
        queryClient,
        { queryKey: [getQueryKey("practices", "getAdjustment"), { practiceId, patientId, adjustmentUuid }] },
        data.data.data
      );
    },
  }),
});

export const createPaymentProfileByToken = makeMutation({
  mutationKey: ["practices", "createPaymentProfileByToken"],
  formatParams: (args: {
    patientId: number;
    practiceId: number;
    data: CreatePaymentProfileByTokenRequest;
  }) => [args.patientId, args.practiceId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_, { practiceId }) => {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getAllPaymentProfilesByPatient"),
        { practiceId },
      ]);
    },
  }),
});

export const markPaymentProfileForDeletion = makeMutation({
  mutationKey: ["practices", "markPaymentProfileForDeletion"],
  formatParams: (args: { practiceId: number; paymentProfileId: string; ownerId: number }) => [
    args.ownerId,
    args.practiceId,
    args.paymentProfileId,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: () => {
      queryClient.invalidateQueries([getQueryKey("practices", "getAllPaymentProfilesByPatient")]);
    },
  }),
});

export const updatePaymentProfile = makeMutation({
  mutationKey: ["practices", "updatePaymentProfile"],
  formatParams: (args: {
    patientId: number;
    practiceId: number;
    paymentProfileUuid: string;
    data: UpdatePaymentProfileRequest;
  }) => [args.patientId, args.practiceId, args.paymentProfileUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      // Invalidate _all_ payment profiles of the practice because updating a payment profile might
      // affect some family members but we don't have the patient's family members handy at that
      // point so invalidating everything is just simpler.
      queryClient.invalidateQueries([
        getQueryKey("practices", "getAllPaymentProfilesByPatient"),
        { practiceId },
      ]);
    },
  }),
});

export const acknowledgeTerm = makeMutation({
  mutationKey: ["practices", "acknowledgeTerms"],
  formatParams: (args: { practiceId: number; data: FinixTermsAcknowledgementRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      queryClient.invalidateQueries([getQueryKey("practices", "getFinixOnboardingSummary"), { practiceId }]);
    },
  }),
});

export const updatePracticeBillingSetting = makeMutation({
  mutationKey: ["practices", "updatePracticeBillingSetting"],
  formatParams: (args: { practiceId: number; data: UpdatePracticeBillingSettingRequest }) => [
    args.practiceId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (response, { practiceId }) => {
      queryClient.setQueryData(
        [getQueryKey("practices", "getPracticeBillingSetting"), { practiceId }],
        response
      );
    },
  }),
});

export const voidInvoice = makeMutation({
  mutationKey: ["practices", "voidInvoice"],
  formatParams: (args: { practiceId: number; invoiceUuid: string }) => [args.practiceId, args.invoiceUuid],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { invoiceUuid }) => {
      // Using `setQueriesData(...)` (vs. `setQueryData()`) allows to
      // invalidate all invoice queries that match that invoice UUID, whether
      // queries fetched a full or partial representation of that invoice. The
      // invoice data returned by this endpoint is a full invoice.
      queryClient.setQueriesData([getQueryKey("practices", "getInvoice"), { invoiceUuid }], data);
      queryClient.invalidateQueries([getQueryKey("practices", "getPendingInvoiceEntries")]);
      queryClient.invalidateQueries([getQueryKey("practices", "getFamilyMembersWithPendingInvoiceEntries")]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLedgerEntries")]);
      queryClient.invalidateQueries([getQueryKey("practices", "getLedgerEntryByTypeAndId")]);
    },
  }),
});

export const createPaymentCorrectionRecord = makeMutation({
  mutationKey: ["practices", "createPaymentCorrectionRecord"],
  formatParams: (args: {
    practiceId: number;
    invoiceUuid: string;
    data: PaymentCorrectionRequest;
    patientId: number;
  }) => [args.practiceId, args.invoiceUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, { patientId }) => {
      replaceCachedItemWithUpdatedItem(
        queryClient,
        { queryKey: [getQueryKey("practices", "getPayment"), { paymentUuid: data.data.data.uuid }] },
        data.data.data
      );
      queryClient.invalidateQueries({
        queryKey: [getQueryKey("practices", "getWalletActivities"), { patientId }],
      });
    },
  }),
});

export const topupWallet = makeMutation({
  mutationKey: ["practices", "topupWallet"],
  formatParams: (args: { practiceId: number; patientId: number; data: WalletTopupRequest }) => [
    args.practiceId,
    args.patientId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { patientId, practiceId }) => {
      queryClient.invalidateQueries({
        queryKey: [getQueryKey("practices", "getWalletActivities"), { practiceId, patientId }],
      });
      queryClient.invalidateQueries({
        queryKey: [getQueryKey("practices", "getWallets")],
      });
    },
  }),
});

export const editWalletActivity = makeMutation({
  mutationKey: ["practices", "editWalletActivity"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    walletUuid: string;
    walletActivityId: number;
    data: WalletEditRequest;
  }) => [args.practiceId, args.patientId, args.walletUuid, args.walletActivityId, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { patientId, walletUuid }) => {
      queryClient.invalidateQueries({
        queryKey: [getQueryKey("practices", "getWalletActivities"), { patientId, walletUuid }],
      });
      queryClient.invalidateQueries({
        queryKey: [getQueryKey("practices", "getWallets")],
      });
    },
  }),
});

export const refundWallet = makeMutation({
  mutationKey: ["practices", "refundWallet"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    walletUuid: string;
    data: WalletRefundRequest;
  }) => [args.practiceId, args.patientId, args.walletUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { patientId, walletUuid }) => {
      queryClient.invalidateQueries({
        queryKey: [getQueryKey("practices", "getWalletActivities"), { patientId, walletUuid }],
      });
      queryClient.invalidateQueries({
        queryKey: [getQueryKey("practices", "getWalletActivityById"), { patientId, walletUuid }],
      });
      queryClient.invalidateQueries({
        queryKey: [getQueryKey("practices", "getWallets")],
      });
    },
  }),
});

export const createStatement = makeMutation({
  mutationKey: ["practices", "createStatement"],
  formatParams: (args: { practiceId: number; patientId: number; data: CreateStatementRequest }) => [
    args.practiceId,
    args.patientId,
    args.data,
  ],
});

export const createMultiInvoicePayment = makeMutation({
  mutationKey: ["practices", "createMultiInvoicePayment"],
  formatParams: (args: { practiceId: number; patientId: number; data: MultiInvoicePaymentRequest }) => [
    args.practiceId,
    args.patientId,
    args.data,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (data, variables) => {
      // Updates the dry run data, commit: false from getInvoicesDryRun
      updateCachedData<MultiInvoicePaymentVO>(
        queryClient,
        {
          queryKey: [
            getQueryKey("practices", "createMultiInvoicePayment"),
            {
              ...variables,
              data: {
                ...variables.data,
                commit: false,
              },
            },
          ],
          exact: true,
        },
        () => data.data.data
      );
    },
  }),
});

export const deleteMultiInvoicePayment = makeMutation({
  mutationKey: ["practices", "deleteMultiInvoicePayment"],
  formatParams: (args: { practiceId: number; patientId: number; paymentUuid: string }) => [
    args.practiceId,
    args.patientId,
    args.paymentUuid,
  ],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { patientId }) => {
      // While a payment deletion won't be reflected on the ledger/patient balances immediately
      // (async), we can still invalidate `listPatientPaymentSummaries` which should no longer
      // return the deleted payment.
      queryClient.invalidateQueries({
        queryKey: [getQueryKey("v2", "listPatientPaymentSummaries"), { patientId }],
      });
    },
  }),
});

export const editMultiInvoicePayment = makeMutation({
  mutationKey: ["practices", "editMultiInvoicePayment"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    paymentUuid: string;
    data: EditMultiInvoicePaymentRequest;
  }) => [args.practiceId, args.patientId, args.paymentUuid, args.data],
});

export const createBulkStatement = makeMutation({
  mutationKey: ["practices", "createBulkStatement"],
  formatParams: (args: { practiceId: number; data: CreateBulkStatementRequest }) => [
    args.practiceId,
    args.data,
  ],
});

export const updateWallet = makeMutation({
  mutationKey: ["practices", "updateWallet"],
  formatParams: (args: {
    practiceId: number;
    patientId: number;
    walletUuid: string;
    data: UpdateWalletRequest;
  }) => [args.practiceId, args.patientId, args.walletUuid, args.data],
  mutationOptions: (queryClient) => ({
    onSuccess: (_data, { practiceId }) => {
      queryClient.invalidateQueries({
        // Don't specify the `patientId` so we can invalidate wallets of
        // potential family members. While this invalidates all wallets of the
        // practice instead of just targeting the ones from the family members,
        // this is the simplest way to go about it.
        queryKey: [getQueryKey("practices", "getWallets"), { practiceId }],
      });
    },
  }),
});
