import { FC, useState, useCallback } from "react";
import { getUnixTime } from "date-fns";
import { useDebounce } from "use-debounce";
import { useQueryClient } from "@tanstack/react-query";

import { produce } from "immer";
import { MessageVO } from "@libs/api/generated-api";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE, SEARCH_DEBOUNCE_DELAY_MS } from "@libs/utils/constants";
import { getInfiniteQueryPagingDetails, getQueryKey } from "@libs/utils/queries";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { usePageTitle } from "@libs/hooks/usePageTitle";
import { useAccount } from "@libs/contexts/AccountContext";
import { MainAppHistoryProvider } from "components/Main/MainLinksContext";
import { PatientSnapshotLayout } from "components/UI/PatientSnapshotLayout";
import { Messaging } from "components/Messaging/Messaging";

import { CancelScheduledMessageModal } from "components/PatientProfile/Communication/CancelScheduledMessageModal";

import { getInfinitePracticeMessages, getPracticeMessages, getPatientMessages } from "api/messaging/queries";
import { getPatientSummary, searchForPatients } from "api/patients/queries";
import { createAndSendMessage, markAsReadOrUnread, retryFailedMessage } from "api/messaging/mutations";

import { useItemModal } from "hooks/useItemModal";

import { handleError } from "utils/handleError";
import { usePatientAppointmentQueryState } from "contexts/PatientAppointmentContext";

const DEFAULT_CONTACT_SEARCH_STRING = "";

export const MessagingRoute: FC = () => {
  const queryClient = useQueryClient();
  const { practiceId } = useAccount();
  const [messageDrafts, setMessageDrafts] = useState<{ [patientId: number]: string | undefined }>({});
  const [contactSearchString, setContactSearchString] = useState(DEFAULT_CONTACT_SEARCH_STRING);
  const [contactSearchStringDebounced] = useDebounce(contactSearchString, SEARCH_DEBOUNCE_DELAY_MS);

  const cancelScheduledMessageModal = useItemModal<MessageVO>(null);

  const patientAppointment = usePatientAppointmentQueryState();
  const selectedPatientId = patientAppointment.patientId;

  // practiceContactMessagesQuery retrieves RankedPatientMessageVO[] based on
  // the contact search string, and is rendered by MessagingContactsList
  const practiceContactMessagesQuery = useInfiniteApiQuery(
    getInfinitePracticeMessages({
      args: {
        practiceId,
        type: "SMS",
        searchString: contactSearchStringDebounced,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
      },
    })
  );

  // selectedPatientContactQuery retrieves RankedPatientMessageVO[] based on the
  // selectedPatientId from the patientId query param; this query param is set
  // when 1) the user selects a contact from MessagingContactsList, or 2) the
  // user navigates to /messaging?patientId= for viewing a specific patient's
  // messages, which may not be in the current paging of
  // practiceContactMessagesQuery
  //
  // selectedPatientContactQuery is used to determine whether the
  // selectedPatient has a designated contact and should enable the
  // patientsWithSameContactQuery
  const [selectedPatientContactQuery, patientQuery] = useApiQueries([
    getPracticeMessages({
      args: {
        practiceId,
        type: "SMS",
        patientId: selectedPatientId,
        pageNumber: 1,
        pageSize: 1,
      },
      queryOptions: {
        enabled: Boolean(selectedPatientId),
      },
    }),
    getPatientSummary({
      args: { patientId: selectedPatientId ?? 0, practiceId },
      queryOptions: { enabled: Boolean(selectedPatientId) },
    }),
  ]);

  // patientsWithSameContactQuery retrieves PatientSummaryVO[] based on either
  // the selectedPatient's designated contact or the patient themselves; the
  // MessagingHeader will display other patients who may have the same
  // designated contact
  const [patientsWithSameContactQuery] = useApiQueries([
    searchForPatients({
      args: {
        practiceId,
        // eslint-disable-next-line @typescript-eslint/naming-convention
        "patientCriteria.contactPatientId": patientQuery.data?.contact.name.id,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
      },
      queryOptions: {
        enabled: Boolean(patientQuery.data),
      },
    }),
  ]);

  // patientMessagesQuery retrieves MessageVO[] based on the selectedPatientId,
  // and is enabled once we have a selectedPatientId from the patientId query
  const patientMessagesQuery = useInfiniteApiQuery(
    getPatientMessages({
      args: {
        practiceId,
        type: "SMS",
        patientId: selectedPatientId ?? 0,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
      },
      queryOptions: {
        enabled: Boolean(selectedPatientId),
      },
    })
  );

  const [createAndSendMessageMutation, markAsReadOrUnreadMutation, retryFailedMessageMutation] =
    useApiMutations([createAndSendMessage, markAsReadOrUnread, retryFailedMessage]);

  const markAsRead = (patientId: number) => {
    markAsReadOrUnreadMutation.mutate(
      { patientId, practiceId, data: { isRead: true, type: "SMS" } },
      { onError: handleError }
    );
  };

  const markAsUnread = (patientId: number) => {
    markAsReadOrUnreadMutation.mutate(
      { patientId, practiceId, data: { isRead: false, type: "SMS" } },
      { onError: handleError }
    );
  };

  const handleSearchClear = () => setContactSearchString(DEFAULT_CONTACT_SEARCH_STRING);

  const handleChangeReadStatus = (patientId: number, isRead: boolean) => {
    if (isRead) {
      markAsUnread(patientId);
    } else {
      markAsRead(patientId);
    }
  };

  const handleSendMessage = (patientId: number, message: string) => {
    // Only submit if message is non-empty
    const messageToSend = message.trim();

    if (!messageToSend) {
      return;
    }

    // Remove message draft immediately so the user gets UI feedback that the
    // message is sending.
    setMessageDrafts((last) =>
      produce(last, (draft) => {
        delete draft[patientId];
      })
    );

    createAndSendMessageMutation.mutate(
      { patientId, practiceId, data: { type: "SMS", content: messageToSend } },
      {
        onError: (err) => {
          // Restore original message draft if sending fails to prevent the user
          // from losing their message.
          setMessageDrafts({ ...messageDrafts, [patientId]: message });
          handleError(err);
        },
      }
    );
  };

  const invalidatePatientMessages = useCallback(() => {
    if (selectedPatientId) {
      queryClient.invalidateQueries([
        getQueryKey("practices", "getPatientMessages"),
        { practiceId, patientId: selectedPatientId },
      ]);
    }
  }, [selectedPatientId, practiceId, queryClient]);

  const handleRetryFailedMessage = useCallback(
    (messageId: number) => {
      retryFailedMessageMutation.mutate(
        { practiceId, messageId, data: { timestamp: getUnixTime(Date.now()) } },
        {
          onSuccess: invalidatePatientMessages,
          onError: handleError,
        }
      );
    },
    [retryFailedMessageMutation, practiceId, invalidatePatientMessages]
  );

  // contactCounter will always reflect count based on contact search string
  const contactCounter = getInfiniteQueryPagingDetails(practiceContactMessagesQuery.data)?.totalElements ?? 0;

  usePageTitle(contactCounter ? `Chats (${contactCounter})` : "Chats");

  return (
    <MainAppHistoryProvider name="messaging">
      <PatientSnapshotLayout
        onDeleteAppointment={patientAppointment.handleAppointmentDeleted}
        onSelectAppointment={patientAppointment.handleAppointmentSelected}
        appointmentId={patientAppointment.appointmentId}
        patientId={patientAppointment.patientId}
      >
        <Messaging
          selectedPatientId={selectedPatientId}
          message={messageDrafts[selectedPatientId ?? 0] ?? ""}
          selectedPatientContactQuery={selectedPatientContactQuery}
          practiceContactMessagesQuery={practiceContactMessagesQuery}
          patientsWithSameContactQuery={patientsWithSameContactQuery}
          patientQuery={patientQuery}
          patientMessagesQuery={patientMessagesQuery}
          contactCounter={contactCounter}
          contactSearchString={contactSearchString}
          isSearching={Boolean(contactSearchStringDebounced)}
          onSearchContact={setContactSearchString}
          onSearchClear={handleSearchClear}
          onSelectContact={patientAppointment.handlePatientSelected}
          onChangeReadStatus={handleChangeReadStatus}
          onSendMessage={handleSendMessage}
          onRetryFailedMessage={handleRetryFailedMessage}
          onRequestCancelScheduledMessage={cancelScheduledMessageModal.open}
          onMessageChange={(patientId, message) =>
            setMessageDrafts({ ...messageDrafts, [patientId]: message })
          }
        />

        {cancelScheduledMessageModal.isOpen ? (
          <CancelScheduledMessageModal
            message={cancelScheduledMessageModal.item}
            onRequestClose={cancelScheduledMessageModal.close}
            onCancelSuccess={invalidatePatientMessages}
          />
        ) : null}
      </PatientSnapshotLayout>
    </MainAppHistoryProvider>
  );
};
