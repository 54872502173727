import { useMemo } from "react";
import { AppointmentClaimSummaryVO, ClaimVO } from "@libs/api/generated-api";
import { humanizeConstant } from "@libs/utils/casing";
import { AppointmentSummary, CreateClaimButtonText } from "components/Claims/Claims/createClaimTypes";
import { CreateClaimState } from "components/Claims/Claims/CreateClaimContent";
import { isVoidedClaim } from "components/Claims/utils";

const defaultCreateValues = {
  submitButtonText: CreateClaimButtonText.CREATE,
  warningMessages: [],
  viewClaimUuid: undefined,
};

const getOrdinalWarning = ({
  selectedInsuranceOrdinal,
  selectedPatientInsuranceOrdinal,
  viewMessages,
}: {
  selectedInsuranceOrdinal: ClaimVO["insuranceOrdinal"];
  selectedPatientInsuranceOrdinal?: ClaimVO["insuranceOrdinal"];
  viewMessages: Set<string>;
}) => {
  if (selectedPatientInsuranceOrdinal && selectedInsuranceOrdinal !== selectedPatientInsuranceOrdinal) {
    viewMessages.add(
      "The selected insurance order does not match the patient insurance's order. Review that the carrier and ordinal you have selected are intended before creating a claim."
    );
  }

  return viewMessages;
};

// eslint-disable-next-line complexity
const getWarningsByClaimType = ({
  claims,
  claimType,
  humanizedOrdinal,
  numApptProcIds,
  selectedInsuranceCarrierId,
  viewMessages,
}: {
  claims: AppointmentClaimSummaryVO[];
  claimType: "drafted" | "submitted" | "completed";
  humanizedOrdinal: string;
  numApptProcIds: number;
  selectedInsuranceCarrierId: number;
  viewMessages: Set<string>;
}) => {
  let claimSameCarrierSomeProcs: AppointmentClaimSummaryVO | undefined;
  let claimDiffCarrierAllProcs: AppointmentClaimSummaryVO | undefined;
  let claimDiffCarrierSomeProcs: AppointmentClaimSummaryVO | undefined;

  claims.forEach((claim) => {
    if (claim.insuranceCarrierId === selectedInsuranceCarrierId) {
      if (claim.procedureIds.length !== numApptProcIds) {
        claimSameCarrierSomeProcs = claim;
      }
    } else if (claim.procedureIds.length === numApptProcIds) {
      claimDiffCarrierAllProcs = claim;
    } else {
      claimDiffCarrierSomeProcs = claim;
    }
  });

  if (claimSameCarrierSomeProcs) {
    viewMessages.add(
      `Another ${humanizedOrdinal} claim has already been ${
        claimType === "drafted" ? `${claimType}` : `${claimType} for some of these procedures`
      }. Only unsubmitted procedures will be available to submit.`
    );
  }

  if (claimDiffCarrierAllProcs) {
    viewMessages.add(
      `Another ${humanizedOrdinal} claim has already been ${claimType}.${
        humanizedOrdinal === "primary"
          ? claimType === "completed"
            ? ` Submitting another ${humanizedOrdinal} claim with a different carrier will likely result in having to refund one of the carriers.`
            : claimType === "submitted"
              ? ` Existing claim should be voided within Archy and you need to directly contact the carrier to ensure the claim is voided.`
              : ""
          : ""
      }`
    );
  }

  if (claimDiffCarrierSomeProcs) {
    viewMessages.add(
      `Another ${humanizedOrdinal} claim has already been ${
        claimType === "drafted" ? `${claimType}` : `${claimType} for some of these procedures`
      }.${
        humanizedOrdinal === "primary"
          ? claimType === "completed"
            ? ` Submitting another ${humanizedOrdinal} claim with a different carrier will likely result in having to refund one of the carriers.`
            : claimType === "submitted"
              ? ` Existing claim should be voided within Archy and you need to directly contact the carrier to ensure the claim is voided.`
              : ""
          : ""
      }`
    );
  }

  return viewMessages;
};

const getWarningsForNonPrimaryClaims = ({
  humanizedOrdinal,
  numApptProcIds,
  primaryClaimsData,
  viewMessages,
}: {
  humanizedOrdinal: string;
  numApptProcIds: number;
  primaryClaimsData: {
    drafted: AppointmentClaimSummaryVO[];
    submitted: AppointmentClaimSummaryVO[];
    completed: AppointmentClaimSummaryVO[];
  };
  viewMessages: Set<string>;
}) => {
  if (humanizedOrdinal !== "primary") {
    const processedPrimaryClaimProcIds = new Set<number>();
    const submittedPrimaryClaimProcIds = new Set<number>();

    primaryClaimsData.completed.forEach((claim) =>
      claim.procedureIds.forEach((procId) => processedPrimaryClaimProcIds.add(procId))
    );
    primaryClaimsData.submitted.forEach((claim) =>
      claim.procedureIds.forEach((procId) => submittedPrimaryClaimProcIds.add(procId))
    );

    const processedPrimaryClaimAllProcs = processedPrimaryClaimProcIds.size === numApptProcIds;

    const submittedPrimaryClaimAllProcs = submittedPrimaryClaimProcIds.size === numApptProcIds;

    if (
      (!processedPrimaryClaimAllProcs && primaryClaimsData.completed.length) ||
      (!submittedPrimaryClaimAllProcs && primaryClaimsData.submitted.length)
    ) {
      viewMessages.add(
        `Only procedures that have been submitted on a primary insurance can be submitted on ${humanizedOrdinal} claim.`
      );
    }

    if (!primaryClaimsData.completed.length) {
      if (!primaryClaimsData.submitted.length) {
        viewMessages.add("No primary claims have been submitted.");
      }

      viewMessages.add(
        `Primary claim does not have EOB payment processed. Primary claim will need to be processed or manually submitted in order to submit ${humanizedOrdinal} claim.`
      );
      viewMessages.add(`Primary claim EOB clipping will be required to submit ${humanizedOrdinal} claim.`);
    }
  }

  return viewMessages;
};

export const useCreateClaim = ({
  appointment,
  completedClaims,
  draftClaims,
  state,
  submittedClaims,
}: {
  appointment: AppointmentSummary;
  completedClaims: AppointmentClaimSummaryVO[];
  draftClaims: AppointmentClaimSummaryVO[];
  state: CreateClaimState;
  submittedClaims: AppointmentClaimSummaryVO[];
}): {
  submitButtonText: string;
  warningMessages: string[];
  viewClaimUuid: string | undefined;
} => {
  const appointmentProcedureIds = appointment.patientProcedures.map((proc) => proc.id);

  const humanizedOrdinal = useMemo(
    () => state.insuranceOrdinal && humanizeConstant(state.insuranceOrdinal),
    [state.insuranceOrdinal]
  );

  const claimsData = useMemo(() => {
    const draftClaimsByOrdinal = {
      PRIMARY: [] as AppointmentClaimSummaryVO[],
      SECONDARY: [] as AppointmentClaimSummaryVO[],
      OTHER: [] as AppointmentClaimSummaryVO[],
    };

    draftClaims.forEach((claim) => draftClaimsByOrdinal[claim.insuranceOrdinal].push(claim));

    const submittedClaimsByOrdinal = {
      PRIMARY: [] as AppointmentClaimSummaryVO[],
      SECONDARY: [] as AppointmentClaimSummaryVO[],
      OTHER: [] as AppointmentClaimSummaryVO[],
    };

    submittedClaims.forEach((claim) => submittedClaimsByOrdinal[claim.insuranceOrdinal].push(claim));

    const completedClaimsByOrdinal = {
      PRIMARY: [] as AppointmentClaimSummaryVO[],
      SECONDARY: [] as AppointmentClaimSummaryVO[],
      OTHER: [] as AppointmentClaimSummaryVO[],
    };

    completedClaims
      .filter((claim) => !isVoidedClaim(claim))
      .forEach((claim) => completedClaimsByOrdinal[claim.insuranceOrdinal].push(claim));

    return {
      PRIMARY: {
        drafted: draftClaimsByOrdinal.PRIMARY,
        submitted: submittedClaimsByOrdinal.PRIMARY,
        completed: completedClaimsByOrdinal.PRIMARY,
      },
      SECONDARY: {
        drafted: draftClaimsByOrdinal.SECONDARY,
        submitted: submittedClaimsByOrdinal.SECONDARY,
        completed: completedClaimsByOrdinal.SECONDARY,
      },
      OTHER: {
        drafted: draftClaimsByOrdinal.OTHER,
        submitted: submittedClaimsByOrdinal.OTHER,
        completed: completedClaimsByOrdinal.OTHER,
      },
    };
  }, [completedClaims, draftClaims, submittedClaims]);

  const viewValues = useMemo(() => {
    const viewMessages: Set<string> = new Set();

    let viewUuid: string | undefined = undefined;

    (["drafted", "submitted", "completed"] as const).find((claimState) => {
      if (state.insuranceOrdinal) {
        const claims = claimsData[state.insuranceOrdinal][claimState];
        const claim = claims.find(
          (c) =>
            c.insuranceCarrierId === state.insuranceCarrierId &&
            (claimState === "drafted" ? true : c.procedureIds.length === appointmentProcedureIds.length)
        );

        if (claim) {
          viewMessages.add(
            `Another${
              humanizedOrdinal ? ` ${humanizedOrdinal}` : ""
            } claim has been ${claimState} with the same carrier.`
          );

          viewUuid = claim.claimUuid;
        }

        return claim;
      }

      return false;
    });

    if (viewMessages.size) {
      return {
        submitButtonText: CreateClaimButtonText.VIEW,
        warningMessages: [...viewMessages],
        viewClaimUuid: viewUuid,
      };
    }

    return null;
  }, [
    state.insuranceOrdinal,
    state.insuranceCarrierId,
    claimsData,
    appointmentProcedureIds.length,
    humanizedOrdinal,
  ]);

  const { submitButtonText, warningMessages, viewClaimUuid } = useMemo(() => {
    if (appointment.claims?.length && state.insuranceOrdinal) {
      // If it's a view claim scenario, we can exit early
      if (viewValues) {
        return viewValues;
      }

      const viewMessages: Set<string> = new Set();

      // Otherwise, compile a list of warning messages
      if (humanizedOrdinal) {
        (["completed", "submitted", "drafted"] as const).forEach((claimState) => {
          if (state.insuranceOrdinal && state.insuranceCarrierId) {
            const claims = claimsData[state.insuranceOrdinal][claimState];

            getWarningsByClaimType({
              claims,
              claimType: claimState,
              humanizedOrdinal,
              numApptProcIds: appointmentProcedureIds.length,
              selectedInsuranceCarrierId: state.insuranceCarrierId,
              viewMessages,
            });
          }
        });

        getWarningsForNonPrimaryClaims({
          humanizedOrdinal,
          numApptProcIds: appointmentProcedureIds.length,
          primaryClaimsData: claimsData.PRIMARY,
          viewMessages,
        });
      }

      getOrdinalWarning({
        selectedInsuranceOrdinal: state.insuranceOrdinal,
        selectedPatientInsuranceOrdinal: state.patientInsuranceOrdinal,
        viewMessages,
      });

      return { ...defaultCreateValues, warningMessages: [...viewMessages] };
    }

    return defaultCreateValues;
  }, [
    appointment.claims?.length,
    appointmentProcedureIds.length,
    claimsData,
    humanizedOrdinal,
    state.insuranceCarrierId,
    state.insuranceOrdinal,
    state.patientInsuranceOrdinal,
    viewValues,
  ]);

  return {
    submitButtonText,
    warningMessages,
    viewClaimUuid,
  };
};
