import React from "react";
import { InboundReferralRequest, InboundReferralResponse, InboundReferralVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { formatCurrency } from "@libs/utils/currency";
import { UseInfiniteApiQueryResult } from "@libs/@types/apiQueries";
import { formatCsvRow, getArchyCsvMetadata, saveCsv } from "utils/csv";
import { handleError } from "utils/handleError";
import { useDownloadQueryPages } from "components/Dashboard/hooks/useDownloadInfiniteQuery";
import { TimeSegment } from "utils/routing/dashboard";
import { REFERRALS_COLUMN_DATA } from "components/Dashboard/Referrals/ReferralsTable";
import { HeaderData } from "components/Dashboard/Tables/types";
import { useCurrentUrl } from "contexts/OriginContext";

const formatAppointmentDataAsCsvUrl = (rows: InboundReferralVO[], headers: HeaderData[]) => {
  const columnTitles = formatCsvRow(headers.map(({ label }) => label));

  const rowsContent = rows.map((row) =>
    formatCsvRow([
      row.patient.shortDisplayName,
      row.insuranceCarrier.name,
      row.firstAppointmentDate ?? "",
      row.referredBy,
      row.referralInformation ?? "",
      formatCurrency(row.netProduction),
    ])
  );

  return [columnTitles, ...rowsContent];
};

export const useDownloadReferralsCsv = ({
  inboundReferralStatsFilteredQuery,
  selectedTimeSegment,
}: InboundReferralRequest & {
  inboundReferralStatsFilteredQuery: UseInfiniteApiQueryResult<InboundReferralResponse>;
  selectedTimeSegment: TimeSegment;
}) => {
  const downloading = useBoolean(false);

  const { startDownload } = useDownloadQueryPages(inboundReferralStatsFilteredQuery);
  const currentUrl = useCurrentUrl();

  return {
    isDownloading: downloading.isOn,
    downloadCSV: React.useCallback(async () => {
      downloading.on();

      try {
        const result = await startDownload();
        const rows = result.pages.flatMap((item) => item.data.entries);
        const docMetadata = getArchyCsvMetadata(currentUrl, selectedTimeSegment);

        const csvRows = [
          ...docMetadata.headers,
          ...formatAppointmentDataAsCsvUrl(rows, REFERRALS_COLUMN_DATA),
        ];

        saveCsv(csvRows, `Referrals_${docMetadata.fileNameTag}`);
      } catch (e) {
        handleError(e);
      }

      downloading.off();
    }, [downloading, startDownload, selectedTimeSegment, currentUrl]),
  };
};
