import React, { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { produce, Draft } from "immer";
import { flushSync } from "react-dom";
import {
  FamilyMemberResponse,
  OnboardingOptionsVO,
  PatientVO,
  ProviderVO,
  UpdatePatientRequest,
} from "@libs/api/generated-api";
import { useValidation } from "@libs/hooks/useValidation";
import { Form } from "@libs/components/UI/Form";
import { getEditPatientSchema } from "components/Patient/formSchemas";
import { scrollToFirstError } from "utils/scrollToFirstError";
import { useItemModal } from "hooks/useItemModal";
import { ContactFlyover } from "components/PatientProfile/PatientOverview/Info/ContactFlyover";
import { PatientInformation } from "components/PatientProfile/PatientOverview/Info/PatientInformation";
import { useIsDirty } from "hooks/useIsDirty";

type Props = {
  familyMembers: FamilyMemberResponse;
  formId: string;
  isEditing: boolean;
  onDirty: Func;
  onSave: (draft: UpdatePatientRequest) => void;
  patient: PatientVO;
  onboarding: OnboardingOptionsVO;
  providers: ProviderVO[];
};

export const PatientInformationForm: React.FC<Props> = ({
  familyMembers,
  formId,
  isEditing,
  onDirty,
  onSave,
  patient,
  onboarding,
  providers,
}) => {
  const formRef = useRef<HTMLFormElement>(null);

  const editingContact = useItemModal<{ patientId?: number }>(null);

  const [editablePatient, setEditablePatient] = useState({
    contact: patient.contact,
    contactDetails: patient.contactDetails,
    additionalInformation: patient.additionalInformation ?? {},
    personalDetails: patient.personalDetails,
  });

  const handleChange = useCallback((updater: (draft: Draft<typeof editablePatient>) => void) => {
    setEditablePatient((previousDraft) => produce(previousDraft, (editable) => void updater(editable)));
  }, []);

  const schema = useMemo(
    () =>
      getEditPatientSchema({
        contactModes: editablePatient.contactDetails.contactModes,
        preferredContactMode: editablePatient.contactDetails.preferredContactMode,
        usingContact: Boolean(editablePatient.contact.name.id !== patient.id),
        ignoreContactMode: true,
      }),
    [
      editablePatient.contactDetails.contactModes,
      editablePatient.contact.name.id,
      editablePatient.contactDetails.preferredContactMode,
      patient.id,
    ]
  );

  const validatedValues = useMemo(() => {
    return {
      dob: editablePatient.personalDetails.dob,
      firstName: editablePatient.personalDetails.firstName,
      lastName: editablePatient.personalDetails.lastName,
      status: editablePatient.personalDetails.status,
      ssn: editablePatient.personalDetails.ssn,
      contactModes: editablePatient.contactDetails.contactModes,
      preferredContactMode: editablePatient.contactDetails.preferredContactMode,
      email: editablePatient.contactDetails.email,
      phoneNumber: editablePatient.contactDetails.phone,
    };
  }, [
    editablePatient.personalDetails.dob,
    editablePatient.personalDetails.firstName,
    editablePatient.personalDetails.lastName,
    editablePatient.personalDetails.status,
    editablePatient.personalDetails.ssn,
    editablePatient.contactDetails.contactModes,
    editablePatient.contactDetails.preferredContactMode,
    editablePatient.contactDetails.email,
    editablePatient.contactDetails.phone,
  ]);

  const validation = useValidation(validatedValues, schema);

  const handleSubmit = (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    const result = flushSync(() => validation.validate());

    if (result.$isValid) {
      // contact read only
      const { contact: _, ...data } = editablePatient;

      onSave(data);
    } else {
      scrollToFirstError(formRef.current);
    }
  };

  useIsDirty(editablePatient, { onDirty });

  const resetValidation = validation.reset;

  useEffect(() => {
    if (isEditing) {
      resetValidation();
    }
  }, [isEditing, resetValidation]);

  return (
    <>
      <Form ref={formRef} id={formId} onSubmit={handleSubmit}>
        <PatientInformation
          familyMembers={familyMembers}
          isEditing={true}
          patientId={patient.id}
          onboardingState={patient.onboardingState}
          updatePatientRequest={editablePatient}
          onChange={handleChange}
          validation={validation.result}
          providers={providers}
          onboarding={onboarding}
          onAddContact={(contactPatientId) => editingContact.open({ patientId: contactPatientId })}
        />
      </Form>

      {editingContact.isOpen ? (
        <ContactFlyover
          onMatchSelected={(patientMatch) => editingContact.open({ patientId: patientMatch.id })}
          patientFirstName={patient.personalDetails.firstName}
          onClose={editingContact.close}
          onContactSelected={(selection) => {
            handleChange((draft) => {
              draft.contactDetails.contactPatientId = selection.name.id;
              draft.contactDetails.contactRelation = selection.relation;
              draft.contact = selection;
            });
            editingContact.close();
          }}
          contactPatientId={editingContact.item.patientId}
        />
      ) : null}
    </>
  );
};
