import { FC, ReactNode } from "react";
import { NavLink, matchPath, useLocation } from "react-router-dom";

import { Icon } from "@libs/components/UI/Icon";
import { cx } from "@libs/utils/cx";

import { RouteIds } from "components/PatientProfile/types";

interface PatientNavTabProps {
  label: string;
  icon: IconComponent;
  isActive?: boolean;
}

export interface PatientNavButtonLinkProps extends PatientNavTabProps {
  id: RouteIds;
  to: string;
  activePathPatterns?: string[];
  children?: (props: Omit<PatientNavButtonLinkProps, "children">) => ReactNode;
}

export interface PatientNavButtonProps extends PatientNavTabProps {
  id: string;
  onClick: Func;
  children?: ReactNode;
}

export const isPatientNavButtonProps = (
  props: PatientNavButtonLinkProps | PatientNavButtonProps
): props is PatientNavButtonProps => {
  return "onClick" in props;
};

const PatientNavTab: FC<PatientNavTabProps> = ({ label, icon, isActive }) => {
  return (
    <div
      className={cx(
        `flex
         flex-col
         items-center
         justify-center
         h-[52px]
         w-20
         border
         transition-colors
         rounded`,
        isActive ? "bg-archyBlue-50 border-archyBlue-500" : "bg-slate-50 border-slate-200 hover:bg-slate-200"
      )}
    >
      <Icon SvgIcon={icon} />
      <div className="text-xs text-slate-900">{label}</div>
    </div>
  );
};

export const PatientNavButtonLink: FC<PatientNavButtonLinkProps & { areNotesOpen: boolean }> = (props) => {
  const { label, icon, to, activePathPatterns, children, areNotesOpen, id } = props;
  const location = useLocation();

  return (
    <div className="relative group">
      <NavLink to={to}>
        {({ isActive }) => (
          <PatientNavTab
            label={label}
            icon={icon}
            isActive={
              !areNotesOpen &&
              (activePathPatterns
                ? activePathPatterns.some((pattern) => matchPath(pattern, location.pathname))
                : isActive)
            }
          />
        )}
      </NavLink>
      {children?.({ label, icon, to, activePathPatterns, id }) ?? null}
    </div>
  );
};

export const PatientNavButton: FC<PatientNavButtonProps> = ({ label, icon, onClick, isActive, children }) => {
  return (
    <div className="relative group">
      <button onClick={onClick} type="button" className="relative">
        <PatientNavTab label={label} icon={icon} isActive={isActive} />
      </button>
      {children}
    </div>
  );
};
