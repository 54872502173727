import { produce } from "immer";
import { FormVO, FormPageVO, FormElementVO, FormResponseVO, FormSubmissionVO } from "@libs/api/generated-api";
import { isOneOf } from "@libs/utils/isOneOf";
import { FormElement, FormInputElement } from "components/Settings/Forms/types";
import { findElement } from "components/Settings/Forms/utils";
import { FormSubmissionResponses, ResponseTypeMap } from "components/PatientProfile/Forms/types";

export const filterForm = (form: FormVO, filterElement: (element: FormElement) => boolean) => {
  return produce(form, (draft) => {
    const filteredContent: FormPageVO[] = [];

    for (const page of draft.content) {
      if (!page.content.length) {
        continue;
      }

      const elements: typeof page.content = [];

      for (const element of page.content) {
        if (!filterElement(element)) {
          continue;
        }

        if (element.type === "SECTION") {
          const sectionElements = element.content.filter(filterElement);

          if (sectionElements.length) {
            elements.push({
              ...element,
              content: sectionElements,
            });
          }
        } else {
          elements.push(element);
        }
      }

      if (elements.length) {
        filteredContent.push({
          ...page,
          content: elements,
        });
      }
    }
    draft.content = filteredContent;
  });
};

export const isInputElement = (element: FormElement): element is FormInputElement => {
  return isOneOf(element.type, [
    "BOOLEAN_INPUT",
    "DATE_INPUT",
    "NUMBER_INPUT",
    "SELECT_INPUT",
    "TEXT_INPUT",
    "EDITABLE_TEXT_BLOCK",
  ]);
};

export const getTaggedResponse = <T extends FormResponseVO["type"]>(
  submission: FormSubmissionVO,
  tag: NonNullable<FormElementVO["tag"]>,
  responseType: T,
  options?: { isConditionalResponse?: boolean }
): ResponseTypeMap[T] | null => {
  const match = findElement(submission.form, "tag", tag);
  const element = match?.element;

  const lookupId = options?.isConditionalResponse
    ? element?.type === "BOOLEAN_INPUT"
      ? element.conditionalElement?.uuid
      : undefined
    : element?.uuid;

  const responses: FormSubmissionResponses = submission.responses;

  const response = lookupId ? responses[lookupId] : undefined;

  if (response?.type === responseType) {
    return response as ResponseTypeMap[typeof responseType];
  }

  return null;
};

const collectIds = (element: FormInputElement) => {
  const ids: string[] = [];

  ids.push(element.uuid);

  if (element.type === "BOOLEAN_INPUT" && element.conditionalElement) {
    ids.push(element.conditionalElement.uuid);
  }

  return ids;
};

export const OTHER_VALUE = "$OTHER$";

export const getAllInputElementIds = (form: FormVO) => {
  const ids: string[] = [];

  for (const page of form.content) {
    for (const element of page.content) {
      if (isInputElement(element)) {
        ids.push(...collectIds(element));
      } else if (element.type === "SECTION") {
        for (const sectionElement of element.content) {
          // eslint-disable-next-line max-depth
          if (isInputElement(sectionElement)) {
            ids.push(...collectIds(sectionElement));
          }
        }
      }
    }
  }

  return ids;
};
