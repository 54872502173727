import { FC } from "react";
import { ScheduleBlockVO } from "@libs/api/generated-api";
import { getRecurrenceLabel } from "components/ScheduleAppointments/getRecurrenceLabel";

interface Props {
  block: ScheduleBlockVO;
  timeSpan: string;
  groupingContextDetail?: string;
  hipaaView?: boolean;
}

export const BlockCardTooltip: FC<Props> = ({ block, timeSpan, groupingContextDetail }) => {
  return (
    <div className="flex flex-col gap-y-1">
      <div className="font-sansSemiBold">{block.title}</div>
      <div>{timeSpan}</div>
      {block.recurrence ? <div>{getRecurrenceLabel(block.recurrence)}</div> : null}
      {groupingContextDetail ? <div>{groupingContextDetail}</div> : null}
      {block.comments ? <p className="whitespace-pre-wrap">{block.comments}</p> : null}
    </div>
  );
};
