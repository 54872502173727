import { getUnixTime, millisecondsToSeconds } from "date-fns";
import { zonedTimeToUtc } from "date-fns-tz";

import {
  PatientListCriteria,
  MessageCampaignVO,
  MessageCampaignRequest,
  UpdateMessageCampaignRequest,
} from "@libs/api/generated-api";
import { required } from "@libs/utils/validators";
import { formatUnixTimestamp, ISO_DATE_TIME } from "@libs/utils/date";
import { PAGE_SIZE } from "@libs/utils/constants";
import { unflattenKeys, removeEmptyValues } from "@libs/utils/object";
import { CampaignFormState } from "components/Communications/Campaigns/Campaign/types";

import { PatientsQuery } from "utils/routing/patient";

export const EMAIL_CAMPAIGN_DAILY_LIMIT = 10_000;

export const hasFilteredCountExceedingDailyLimit = (patientsFiltered: number | undefined) =>
  Boolean(patientsFiltered && patientsFiltered > EMAIL_CAMPAIGN_DAILY_LIMIT);

export const convertPatientsUrlQueryParamsToPatientListCriteria = (urlQueryParams: PatientsQuery) => {
  return removeEmptyValues(unflattenKeys(urlQueryParams)) as unknown as PatientListCriteria;
};

export const getInitialCampaignFormState = (
  campaign: MessageCampaignVO,
  timezone: string
): CampaignFormState => {
  const { name, template, scheduledAt } = campaign;

  if (!scheduledAt) {
    return {
      name,
      template,
      scheduledDate: undefined,
      scheduledTime: undefined,
    };
  }

  // Extract scheduled date and time from scheduledAt unix timestamp, that has
  // been converted to an ISO 8601 DateTime in the practice timezone
  const [scheduledDate, scheduledTime] = formatUnixTimestamp(scheduledAt, timezone, ISO_DATE_TIME).split("T");

  return {
    name,
    template,
    scheduledDate,
    scheduledTime,
  };
};

export const getScheduledAtUnixTime = (scheduledDate: string, scheduledTime: string, timezone: string) => {
  // Convert scheduled date and time in the practice timezone to UTC, in order
  // to maintain scheduledAt as a unix timestamp for UpdateMessageCampaignRequest
  const utcDate = zonedTimeToUtc(`${scheduledDate}T${scheduledTime}`, timezone);
  const scheduledAt = getUnixTime(utcDate);

  if (Number.isNaN(scheduledAt)) {
    return undefined;
  }

  return scheduledAt;
};

export const hasScheduledAtInPast = (scheduledAt: number) => {
  // Compare scheduledAt unix timestamp to now as a unix timestamp
  return scheduledAt < millisecondsToSeconds(Date.now());
};

export const getCreateEmailCampaignRequest = (
  overrdies?: Partial<MessageCampaignRequest>
): MessageCampaignRequest => ({
  messageCampaignUuid: crypto.randomUUID(),
  channel: "EMAIL",
  name: "Untitled Campaign",
  template: "Untitled Subject",
  emailTemplate: {
    html: "",
    json: "",
  },
  filter: {
    type: "PATIENT_LIST",
    patientListCriteria: {
      maxPageSize: 50,
      pageSize: PAGE_SIZE,
      pageNumber: 1,
      sortColumn: "lastName",
      orderBy: "ASCENDING",
    },
  },
  ...overrdies,
});

export const getUpdateEmailCampaignRequest = (
  campaign: MessageCampaignVO,
  overrides?: Partial<MessageCampaignVO>
): UpdateMessageCampaignRequest => ({
  name: campaign.name,
  template: campaign.template,
  emailTemplate: campaign.emailTemplate,
  filter: campaign.filter as UpdateMessageCampaignRequest["filter"],
  scheduledAt: campaign.scheduledAt,
  ...overrides,
});

export const getCampaignFormSchema = () => ({
  name: [
    {
      $v: required,
      $error: "Campaign name is required",
    },
  ],
  template: [
    {
      $v: required,
      $error: "Subject line is required",
    },
  ],
});
