import { FC, useRef, useCallback } from "react";
import EmailEditor, { EditorRef, EmailEditorProps, Editor } from "react-email-editor";

import {
  parseEmailTemplateJson,
  exportEmailEditorImage,
} from "components/Communications/Campaigns/EmailEditor/utils";

import { useEnvContext } from "contexts/EnvContext";
import { handleError } from "utils/handleError";

interface Props {
  emailTemplateJson: string;
  emailTemplatePreviewUrl?: string;
  onRequestEmailTemplatePreviewUrl: (previewUrl: string) => void;
}

// EmailEditorPreview is used to generate an initial email template preview url
// by loading the json template into the email editor and exporting the image
export const EmailEditorPreview: FC<Props> = ({
  emailTemplateJson,
  emailTemplatePreviewUrl,
  onRequestEmailTemplatePreviewUrl,
}) => {
  const editorRef = useRef<EditorRef>(null);
  const { REACT_APP_UNLAYER_PROJECT_ID } = useEnvContext();

  const handleLoad: EmailEditorProps["onLoad"] = useCallback(
    (editor: Editor) => {
      const jsonTemplate = parseEmailTemplateJson(emailTemplateJson);

      if (jsonTemplate) {
        editor.loadDesign(jsonTemplate);
      }
    },
    [emailTemplateJson]
  );

  const handleReady: EmailEditorProps["onReady"] = useCallback(async () => {
    // Request email template preview url if it has not yet been initialized
    if (!editorRef.current?.editor || emailTemplatePreviewUrl) {
      return;
    }

    try {
      const previewUrl = await exportEmailEditorImage(editorRef.current.editor);

      if (previewUrl) {
        onRequestEmailTemplatePreviewUrl(previewUrl);
      }
    } catch (error) {
      handleError(error);
    }
  }, [editorRef, emailTemplatePreviewUrl, onRequestEmailTemplatePreviewUrl]);

  return (
    <EmailEditor
      ref={editorRef}
      style={{ display: "none" }}
      options={{
        projectId: Number(REACT_APP_UNLAYER_PROJECT_ID),
        displayMode: "email" as const,
      }}
      onLoad={handleLoad}
      onReady={handleReady}
      minHeight={0}
    />
  );
};
