import React from "react";
import Skeleton from "react-loading-skeleton";
import { Spinner } from "@libs/components/UI/Spinner";
import { Button } from "@libs/components/UI/Button";
import { MenuOptionButton, MenuOptionLink } from "@libs/components/UI/MenuOptionButton";
import { ReactComponent as ErrorIcon } from "@libs/assets/icons/error.svg";
import { CameraDevice } from "components/ImageCapturing/useCameraSource";
import { MOUNT_IMAGE_MAX_LENGTH } from "components/PatientProfile/Imaging/MountRoute/image-utils";
import { useTwain } from "components/ImageCapturing/useTwain";
import { UseCameraCaptureState } from "components/ImageCapturing/useCameraCapture";
import {
  ImagingDeviceWithSettings,
  useImagingDevices,
} from "components/PatientProfile/Imaging/hooks/useImagingDevices";
import { paths } from "utils/routing/paths";
import { useImagingHub } from "components/ImageCapturing/ImagingHubContext";
import { useOfflineImagesQuery } from "components/PatientProfile/Imaging/PatientMountsList/hooks/useOfflineImagesQuery";

type DeviceSectionProps = {
  title: string;
  items: ImagingDeviceWithSettings[];
  onClickItem: (device: ImagingDeviceWithSettings) => void;
  errorTitle: string;
  isLoading?: boolean;
};

const SectionTitle: React.FC<{ children: string }> = ({ children }) => (
  <div className="pb-1 px-2 font-sansSemiBold">{children}</div>
);
const DeviceSection: React.FC<DeviceSectionProps> = ({
  title,
  items,
  onClickItem,
  errorTitle,
  isLoading,
}) => {
  return (
    <div className="py-2">
      <SectionTitle>{title}</SectionTitle>
      <div className="flex flex-col items-start">
        {isLoading && (
          <div className="p-4 flex items-center justify-center">
            <Spinner variant="secondary" animation="grow" size="sm" />
          </div>
        )}
        {!isLoading && items.length === 0 ? (
          <div className="flex flex-row items-center px-2 py-2">
            <ErrorIcon title="Error" className="fill-red w-4" />
            <div className="pl-1" color="text-red">
              {errorTitle}
            </div>
          </div>
        ) : (
          items.map((item, i) => {
            return (
              <MenuOptionButton
                key={i}
                onClick={() => {
                  onClickItem(item);
                }}
              >
                {item.settings.overriddenLabel ?? item.label}
              </MenuOptionButton>
            );
          })
        )}
      </div>
    </div>
  );
};

type XRayProps = {
  items: ImagingDeviceWithSettings[];
  needsDownload: boolean;
  isLoading: boolean;
  onClickItem: (item: ImagingDeviceWithSettings) => void;
  patientId: number;
};

const XRayContent: React.FC<XRayProps> = ({ items, needsDownload, onClickItem, isLoading, patientId }) => {
  const title = "X-Ray Devices";

  if (needsDownload) {
    return (
      <div className="pb-2 pt-3">
        <SectionTitle>{title}</SectionTitle>
        <MenuOptionLink to={paths.imagingSettings({ patientId })}>
          Configure in{" "}
          <span
            className={`
              text-primaryTheme
              dark:text-archyBlue-300
              font-sansSemiBold
              pl-1
            `}
          >
            Imaging Settings
          </span>
        </MenuOptionLink>
      </div>
    );
  }

  return (
    <DeviceSection
      isLoading={isLoading}
      title={title}
      items={items}
      onClickItem={onClickItem}
      errorTitle="No x-ray sensors available"
    />
  );
};

export type NewImagePopoverMenuProps = {
  onSensorClick: (item: { deviceId: string }) => void;
  onCameraClick: (item: CameraDevice) => void;
  onImportClick: () => void;
  onRequestClose: Func;
  cameraCapture: UseCameraCaptureState;
  onCreateNewMount?: () => void;
  onClickUploadOfflineImages?: () => void;
  limitReached?: boolean;
  patientId: number;
  disabled?: boolean;
};

export const NewImagePopoverMenu: React.FC<NewImagePopoverMenuProps> = ({
  limitReached,
  onCreateNewMount,
  patientId,
  onRequestClose,
  cameraCapture,
  onCameraClick,
  onSensorClick,
  onImportClick,
  onClickUploadOfflineImages,
}) => {
  const { twainInstance } = useTwain();
  const { cameraSources } = cameraCapture;

  const { data: offlineSessions } = useOfflineImagesQuery({
    IncludeUploaded: false,
  });
  const { status: hubStatus } = useImagingHub();
  const { devices: allDevices, isLoading } = useImagingDevices(cameraCapture);

  const handleItemClicked = React.useCallback(
    (device: ImagingDeviceWithSettings) => {
      onRequestClose();

      if (device.type === "sensor" || device.type === "archy-sensor") {
        onSensorClick({
          deviceId: device.label,
        });
      } else {
        onCameraClick(device);
      }
    },
    [onRequestClose, onCameraClick, onSensorClick]
  );
  const { cameraDevices, xRayDevices } = React.useMemo(() => {
    return {
      cameraDevices: allDevices.filter(
        ({ produces, settings }) => produces === "Photo" && !(settings.enabled === false)
      ),
      xRayDevices: allDevices.filter(
        ({ produces, settings }) => produces === "X-RAY" && !(settings.enabled === false)
      ),
    };
  }, [allDevices]);

  const aisRunningAndNotDisabled = hubStatus.isRunning && !hubStatus.isAISDisabled;

  return (
    <div className="text-xs">
      {isLoading ? (
        <Skeleton className="w-36 h-40" />
      ) : limitReached ? (
        <div className="p-4 flex flex-col space-y-3 items-center justify-center">
          <div>Image limit reached</div>
          <div className="text-greyMedium">A Mount holds up to {MOUNT_IMAGE_MAX_LENGTH} images</div>
          <Button type="button" size="small" onClick={onCreateNewMount}>
            Create New Mount
          </Button>
        </div>
      ) : (
        <div className="divide-y divide-greyLighter dark:divide-greyMedium">
          <XRayContent
            items={xRayDevices}
            onClickItem={handleItemClicked}
            patientId={patientId}
            isLoading={isLoading}
            needsDownload={aisRunningAndNotDisabled ? false : Boolean(twainInstance.neededDownloadUrl)}
          />

          <DeviceSection
            title="Intraoral Camera Devices"
            items={cameraDevices}
            onClickItem={handleItemClicked}
            errorTitle="No cameras connected"
            isLoading={cameraSources?.isRequestingSources}
          />

          <div className="flex flex-col items-start gap-2">
            <MenuOptionButton
              onClick={() => {
                onRequestClose();
                onImportClick();
              }}
            >
              Import Images
            </MenuOptionButton>
          </div>
          {aisRunningAndNotDisabled && onClickUploadOfflineImages && (
            <MenuOptionButton
              onClick={() => {
                onRequestClose();
                onClickUploadOfflineImages();
              }}
              disabled={(offlineSessions?.length ?? 0) === 0}
            >
              Upload Offline Images
            </MenuOptionButton>
          )}
        </div>
      )}
    </div>
  );
};
