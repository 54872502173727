import { PatientProcedureVO, ProviderVO } from "@libs/api/generated-api";
import { DraftPatientProcedureRequest } from "components/Charting/draftPatientProcedure";

export type SelectableProcedureStatus = "EXISTING_OTHER" | "PLANNED" | "EXISTING_CURRENT" | "REFERRED";

export type ProcedureStatusOption = {
  value: SelectableProcedureStatus;
  label: string;
  isDisabled?: boolean;
};

type PreAuthOption = {
  value: NonNullable<PatientProcedureVO["preAuthStatus"]>;
  label: string;
};

interface ImplantInstallTypeOption {
  value: NonNullable<DraftPatientProcedureRequest["implantInstallType"]>;
  label: string;
}

export const selectablePatientProcedures: ProcedureStatusOption[] = [
  {
    value: "EXISTING_OTHER",
    label: "Existing Other",
  },
  {
    value: "PLANNED",
    label: "Planned",
  },
  {
    value: "EXISTING_CURRENT",
    label: "Existing Current",
  },
  {
    value: "REFERRED",
    label: "Referred",
  },
];

export const filterablePatientProcedureStatusOptions: {
  label: string;
  value: PatientProcedureVO["status"];
}[] = [
  {
    value: "REJECTED",
    label: "Rejected",
  },
  {
    value: "DONE",
    label: "Completed",
  },
  {
    value: "SCHEDULED",
    label: "Scheduled",
  },
  ...selectablePatientProcedures,
];

export const getProcedureStatusOptions = (
  extraOptions?: {
    value: PatientProcedureVO["status"];
    label: string;
    isDisabled?: boolean;
  }[]
) => {
  const options: {
    value: PatientProcedureVO["status"];
    label: string;
    isDisabled?: boolean;
  }[] = [...selectablePatientProcedures];

  if (extraOptions) {
    return [...options, ...extraOptions];
  }

  return options;
};

export const implantInstallTypeOptions: ImplantInstallTypeOption[] = [
  {
    value: "NEW",
    label: "Initial",
  },
  {
    value: "REPLACEMENT",
    label: "Replacement",
  },
];

export const prognosisOptions: { value: NonNullable<PatientProcedureVO["prognosis"]>; label: string }[] = [
  { value: "EXCELLENT", label: "Excellent" },
  { value: "GOOD", label: "Good" },
  { value: "FAIR", label: "Fair" },
  { value: "QUESTIONABLE", label: "Questionable" },
  { value: "POOR", label: "Poor" },
  { value: "HOPELESS", label: "Hopeless" },
];

export const defaultAreaOptions: {
  value: NonNullable<DraftPatientProcedureRequest["areaType"]> | "";
  label: string;
}[] = [
  { value: "TOOTH", label: "Tooth/Surface" },
  { value: "ARCH", label: "Arch" },
  { value: "QUADRANT", label: "Quadrant" },
  { value: "MOUTH", label: "Mouth" },
  { value: "", label: "None" },
];

export const priorityOptions = [
  { value: 1, label: "1" },
  { value: 2, label: "2" },
  { value: 3, label: "3" },
  { value: 4, label: "4" },
  { value: 5, label: "5" },
  { value: 6, label: "6" },
  { value: 7, label: "7" },
  { value: 8, label: "8" },
  { value: 9, label: "9" },
  { value: 10, label: "10" },
  { value: 11, label: "M" },
];

export const tablePriorityOptions = [{ value: 0, label: "-" }, ...priorityOptions];

export const getDentistOptions = (providers: ProviderVO[]) =>
  providers
    .filter((provider) => provider.jobCategory === "DENTIST")
    .map((provider) => ({
      value: provider.id,
      label: provider.name.fullDisplayName,
    }));

export const preAuthOptions: PreAuthOption[] = [
  {
    label: "Approved",
    value: "APPROVED",
  },
  {
    label: "Denied",
    value: "DENIED",
  },
];
