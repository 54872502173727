import { FC, useState, useCallback, useMemo, FormEvent } from "react";
import { AppointmentCardConfigVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as InfoIcon } from "@libs/assets/icons/info-small.svg";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { RadioList } from "@libs/components/UI/RadioList";
import { useAccount } from "@libs/contexts/AccountContext";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { ModalContent, ModalFooterButtons, ModalForm } from "@libs/components/UI/ModalComponents";
import { updateSchedulingConfig } from "api/scheduling/mutations";
import { handleError } from "utils/handleError";

import { AppointmentCardPreview } from "components/Settings/Scheduling/Sections/AppointmentCardDesign/AppointmentCardPreview";
import { Divider } from "components/UI/Divider";
import { ToggleGroup } from "components/UI/ToggleGroup";
import { IndicatorOptions, ShowHideOptions, ProviderOptions, TypeSizeOptions } from "./data";
import { ColorSettingsHeader, NameSettingsHeader, cxCardConfigStyles } from "./CardConfig";

interface Props {
  config: AppointmentCardConfigVO;
  currentUserName: string | undefined;
  onClose: Func;
}

export const ConfigLabel = ({ title, tooltipContent }: { title: string; tooltipContent?: string }) => (
  <span className={cxCardConfigStyles.configLabel}>
    {title}
    {tooltipContent && (
      <Icon
        size="lg"
        SvgIcon={InfoIcon}
        theme="slate700"
        tooltip={{
          content: tooltipContent,
        }}
      />
    )}
  </span>
);

export const CardConfigForm: FC<Props> = ({ config, currentUserName, onClose }) => {
  const [configDraft, setConfigDraft] = useState(config);
  const [updateSchedulingConfigMutation] = useApiMutations([updateSchedulingConfig]);
  const { practiceId } = useAccount();

  const handleSubmit = useCallback(
    (e: FormEvent<HTMLFormElement>) => {
      e.preventDefault();
      updateSchedulingConfigMutation.mutate(
        { practiceId, data: { appointmentCardConfig: configDraft } },
        {
          onSuccess: onClose,
          onError: handleError,
        }
      );
    },
    [updateSchedulingConfigMutation, practiceId, configDraft, onClose]
  );

  const backgroundLabel = useMemo(
    () =>
      configDraft.indicators.length > 1
        ? undefined
        : configDraft.indicators.length === 0
          ? "STATUS"
          : configDraft.indicators[0],
    [configDraft.indicators]
  );

  const handleIndicatorChange = useCallback((newSet: Set<"CATEGORY" | "PROVIDER">) => {
    setConfigDraft((last) => ({
      ...last,
      indicators: [...newSet],
      background: newSet.size > 1 ? last.background ?? "PROVIDER" : undefined,
    }));
  }, []);

  const disableProviderColor = !configDraft.indicators.includes("PROVIDER");

  const providerOptions = ProviderOptions.map((option) => {
    return {
      ...option,
      label: (
        <div className="flex items-center gap-x-1 h-6" key={option.value}>
          {option.label}
          {option.value === "HYGIENIST" && !disableProviderColor && (
            <Icon
              disabled={disableProviderColor}
              size="lg"
              SvgIcon={InfoIcon}
              theme="slate700"
              tooltip={{
                content: "If no hygienist is assigned, dentist color will be used.",
                placement: "right",
              }}
            />
          )}
        </div>
      ),
    };
  });

  return (
    <div className="flex flex-col justify-between h-full">
      <ModalForm className={cxCardConfigStyles.formContainer} onSubmit={handleSubmit}>
        <ModalContent>
          <div className={cxCardConfigStyles.layoutContainer}>
            <div className={cx(cxCardConfigStyles.colContainer)}>
              <ColorSettingsHeader />
              <div className={cxCardConfigStyles.settingsContainer}>
                <div className={cxCardConfigStyles.settingContainer}>
                  <ConfigLabel
                    title="Indicators"
                    tooltipContent="Indicators can be set to show as a color bar on the left hand side or as the background color of the appointment card."
                  />
                  <div className={cxCardConfigStyles.indicatorRow}>
                    <CheckboxList
                      layout="custom"
                      onChange={handleIndicatorChange}
                      optionListClassName="flex items-center gap-x-5"
                      options={IndicatorOptions}
                      selectedValues={new Set(configDraft.indicators)}
                      verticalLayout="compact"
                    />
                  </div>
                </div>
                <div className={cxCardConfigStyles.settingContainer}>
                  <ConfigLabel
                    title="Background Color"
                    tooltipContent={
                      configDraft.indicators.includes("CATEGORY")
                        ? "Choose whether the category or provider color is used for the appointment card background."
                        : "Can only select background color if it is a selected indicator. If no indicators are selected, the background color will be the status color."
                    }
                  />
                  <div className={cxCardConfigStyles.indicatorRow}>
                    <RadioList
                      disabled={configDraft.indicators.length <= 1}
                      layout="custom"
                      onChange={(_e, { value }) => setConfigDraft((last) => ({ ...last, background: value }))}
                      optionListClassName="flex items-center gap-x-5 my-0.5"
                      options={IndicatorOptions}
                      selectedValue={backgroundLabel ?? configDraft.background ?? "STATUS"}
                      verticalLayout="compact"
                    />
                  </div>
                </div>
                <div className={cxCardConfigStyles.settingContainer}>
                  <ConfigLabel
                    title="Provider Color"
                    tooltipContent={
                      disableProviderColor
                        ? "Can only select provider color if it is a selected indicator."
                        : "Choose which provider is used for the indicator color."
                    }
                  />
                  <div className={cxCardConfigStyles.indicatorRow}>
                    <RadioList
                      disabled={disableProviderColor}
                      layout="custom"
                      onChange={(_e, { value }) =>
                        setConfigDraft((last) => ({ ...last, showDentistColor: value === "DENTIST" }))
                      }
                      optionListClassName="flex items-center gap-x-5 my-0.5"
                      options={providerOptions}
                      selectedValue={
                        configDraft.indicators.includes("PROVIDER")
                          ? configDraft.showDentistColor
                            ? "DENTIST"
                            : "HYGIENIST"
                          : "PROVIDER"
                      }
                      verticalLayout="compact"
                    />
                  </div>
                </div>
              </div>
              <Divider className="py-1" />
              <NameSettingsHeader />
              <div className={cxCardConfigStyles.settingsContainer}>
                <div className={cxCardConfigStyles.settingContainer}>
                  <ConfigLabel title="Type Size" />
                  <div className={cxCardConfigStyles.indicatorRow}>
                    <RadioList
                      layout="custom"
                      onChange={(_e, { value }) => setConfigDraft((last) => ({ ...last, fontSize: value }))}
                      optionListClassName="flex items-center gap-x-5 my-0.5"
                      options={TypeSizeOptions}
                      selectedValue={configDraft.fontSize}
                      verticalLayout="compact"
                    />
                  </div>
                </div>
                <div className={cxCardConfigStyles.settingContainer}>
                  <ConfigLabel title="Preferred Name" />
                  <div className={cxCardConfigStyles.indicatorRow}>
                    <ToggleGroup
                      selectedValue={configDraft.hidePreferredName ? "HIDE" : "SHOW"}
                      options={ShowHideOptions}
                      onChange={(_e, { value }) =>
                        setConfigDraft((last) => ({ ...last, hidePreferredName: value === "HIDE" }))
                      }
                      size="md"
                    />
                  </div>
                </div>
                {/* empty div for responsive flex spacing */}
                <div className={cxCardConfigStyles.settingContainer} />
              </div>
              {/* Replace section above with below when BE is ready */}
              {/* <div className={cxCardConfigStyles.settingsContainer}>
                <div className={cxCardConfigStyles.settingContainer}>
                  <ConfigLabel title="Order By" />
                  <div className={cxCardConfigStyles.indicatorRow}>
                    <RadioList
                      layout="custom"
                      onChange={(_e, { value }) => setConfigDraft((last) => ({ ...last, orderBy: value }))}
                      optionListClassName="flex items-center gap-x-5 my-0.5"
                      options={OrderByOptions}
                      selectedValue={configDraft.orderBy}
                      verticalLayout="compact"
                    />
                  </div>
                </div>
                <div className={cxCardConfigStyles.settingContainer}>
                  <ConfigLabel title="Type Size" />
                  <div className={cxCardConfigStyles.indicatorRow}>
                    <RadioList
                      layout="custom"
                      onChange={(_e, { value }) => setConfigDraft((last) => ({ ...last, fontSize: value }))}
                      optionListClassName="flex items-center gap-x-5 my-0.5"
                      options={TypeSizeOptions}
                      selectedValue={configDraft.fontSize}
                      verticalLayout="compact"
                    />
                  </div>
                </div>
                <div className={cxCardConfigStyles.settingContainer} />
              </div>
              <div className={cxCardConfigStyles.settingsContainer}>
                <div className={cxCardConfigStyles.settingContainer}>
                  <ConfigLabel title="Preferred Name" />
                  <div className={cxCardConfigStyles.indicatorRow}>
                    <ToggleGroup
                      selectedValue={configDraft.hidePreferredName ? "HIDE" : "SHOW"}
                      options={ShowHideOptions}
                      onChange={(_e, { value }) =>
                        setConfigDraft((last) => ({ ...last, hidePreferredName: value === "HIDE" }))
                      }
                      size="md"
                    />
                  </div>
                </div>
                <div className={cxCardConfigStyles.settingContainer}>
                  <ConfigLabel title="Name Pronunciation" />
                  <div className={cxCardConfigStyles.indicatorRow}>
                    <ToggleGroup
                      options={ShowHideOptions}
                      onChange={(_e, { value }) =>
                        setConfigDraft((last) => ({ ...last, hideNamePronunciation: value === "HIDE" }))
                      }
                      selectedValue={configDraft.hideNamePronunciation ? "HIDE" : "SHOW"}
                      size="md"
                    />
                  </div>
                </div>
                <div className={cxCardConfigStyles.settingContainer} />
              </div>
              <Divider className="py-1" />
              <AppointmentSettingsHeader />
              <div className={cxCardConfigStyles.settingsContainer}>
                <div className={cxCardConfigStyles.settingContainer}>
                  <ConfigLabel title="Provider Name" />
                  <div className={cxCardConfigStyles.indicatorRow}>
                    <ToggleGroup
                      options={ShowHideOptions}
                      onChange={(_e, { value }) =>
                        setConfigDraft((last) => ({ ...last, hideProviderName: value === "HIDE" }))
                      }
                      selectedValue={configDraft.hideProviderName ? "HIDE" : "SHOW"}
                      size="md"
                    />
                  </div>
                </div>
                <div className={cxCardConfigStyles.settingContainer}>
                  <ConfigLabel title="Patient Name" />
                  <div className={cxCardConfigStyles.indicatorRow}>
                    <ToggleGroup
                      options={ShowHideOptions}
                      onChange={(_e, { value }) =>
                        setConfigDraft((last) => ({ ...last, hidePatientAge: value === "HIDE" }))
                      }
                      selectedValue={configDraft.hidePatientAge ? "HIDE" : "SHOW"}
                      size="md"
                    />
                  </div>
                </div>
                <div className={cxCardConfigStyles.settingContainer} />
              </div> */}
            </div>
            <VerticalDivider />
            <div className={cxCardConfigStyles.previewContainer}>
              <div className={cxCardConfigStyles.previewWrapper}>
                <AppointmentCardPreview config={configDraft} currentUserName={currentUserName} />
              </div>
            </div>
          </div>
        </ModalContent>
        <ModalFooterButtons className="py-5 border-t border-greyLighter">
          <Button className="min-w-button" onClick={onClose} size="large" theme="secondary" type="button">
            Cancel
          </Button>
          <AsyncButton
            className="min-w-button"
            isLoading={updateSchedulingConfigMutation.isLoading}
            size="large"
            theme="primary"
            type="submit"
          >
            Save
          </AsyncButton>
        </ModalFooterButtons>
      </ModalForm>
    </div>
  );
};
