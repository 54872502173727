import React, { useCallback } from "react";
import { formatAsISODate } from "@libs/utils/date";
import { useObjectState } from "@libs/hooks/useObjectState";
import { Button } from "@libs/components/UI/Button";
import { AsyncButton } from "@libs/components/UI/AsyncButton";
import { Modal } from "@libs/components/UI/Modal";
import { ModalContent, ModalFooter, ModalForm } from "@libs/components/UI/ModalComponents";
import {
  FormFieldSelectMenusDatepicker,
  FormFieldSelectMenusDatepickerProps,
} from "components/UI/FormFieldSelectMenusDatepicker";
import { useNow } from "hooks/useNow";

type Props = {
  onClose: Func;
  onSubmit: (params: { startDate: string; endDate: string }) => void;
  isSaving: boolean;
};

type StatmentParamsDraft = {
  startDate: Date | null;
  endDate: Date | null;
};

export const GenerateBulkStatementModal: React.FC<Props> = ({ onClose, onSubmit, isSaving }) => {
  const now = useNow();
  const [draft, onUpdateDraft] = useObjectState<StatmentParamsDraft>({
    startDate: null,
    endDate: null,
  });
  const sharedDatePickerProps: Omit<FormFieldSelectMenusDatepickerProps, "onChange"> = {
    layout: "labelOut",
  };

  const handleSubmit = useCallback(
    (e?: React.FormEvent<HTMLFormElement>) => {
      e?.preventDefault();

      if (draft.startDate && draft.endDate) {
        onSubmit({ startDate: formatAsISODate(draft.startDate), endDate: formatAsISODate(draft.endDate) });
      }
    },
    [draft.endDate, draft.startDate, onSubmit]
  );

  return (
    <Modal onClose={onClose} title="Generate a Statement" size="3xs">
      <ModalForm onSubmit={handleSubmit}>
        <ModalContent padding="sm" className="flex flex-col gap-2 text-xs">
          <p>Select the start and end date for invoices to include in the statement.</p>
          <FormFieldSelectMenusDatepicker
            {...sharedDatePickerProps}
            label="Start Date"
            placeholderText="Select start date"
            selected={draft.startDate}
            maxDate={draft.endDate ?? now}
            onChange={(date) => onUpdateDraft({ startDate: date })}
          />
          <FormFieldSelectMenusDatepicker
            {...sharedDatePickerProps}
            label="End Date"
            placeholderText="Select end date"
            selected={draft.endDate}
            minDate={draft.startDate}
            maxDate={now}
            onChange={(date) => onUpdateDraft({ endDate: date })}
          />
        </ModalContent>
        <ModalFooter actions>
          <Button className="min-w-button" type="button" theme="secondary" onClick={onClose}>
            Cancel
          </Button>
          <AsyncButton
            className="min-w-button"
            isLoading={isSaving}
            type="submit"
            disabled={!draft.startDate || !draft.endDate}
          >
            Generate
          </AsyncButton>
        </ModalFooter>
      </ModalForm>
    </Modal>
  );
};
