import { useCallback } from "react";
import { InvoiceVO } from "@libs/api/generated-api";
import { useApiMutations } from "@libs/hooks/useApiMutations";
import { useAccount } from "@libs/contexts/AccountContext";
import { voidInvoice } from "api/billing/mutations";
import { handleError } from "utils/handleError";

export const useVoidInvoice = ({ invoiceUuid }: { invoiceUuid: InvoiceVO["uuid"] | undefined }) => {
  const { practiceId } = useAccount();
  const [{ mutate: voidInvoiceMutate, isLoading: isVoiding }] = useApiMutations([voidInvoice]);
  const handleVoidInvoice = useCallback(
    ({ onSuccess }: { onSuccess: Func }) => {
      invoiceUuid && voidInvoiceMutate({ practiceId, invoiceUuid }, { onError: handleError, onSuccess });
    },
    [invoiceUuid, practiceId, voidInvoiceMutate]
  );

  return {
    isVoiding,
    voidInvoice: handleVoidInvoice,
  };
};
