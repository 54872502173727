import { useMemo, useCallback, useRef } from "react";
import { nowInTimezone, formatAsISODate } from "@libs/utils/date";
import { useCurrentPractice } from "@libs/contexts/PracticeContext";
import { AppointmentGrouping } from "utils/routing/scheduling";
import { useQueryParams } from "hooks/useQueryParams";
import { useSchedulingStorage, SchedulingConfig } from "storage/scheduling";

export const useScheduleRoute = () => {
  const practice = useCurrentPractice();
  const { query, updateQuery } = useQueryParams("schedule");

  const [scheduleConfig, setSchedulingConfig] = useSchedulingStorage(
    query.groupAppointmentsBy
      ? {
          // this is to support a link into the schedule page to override the
          // the local storage value
          groupAppointmentsBy: query.groupAppointmentsBy,
        }
      : undefined
  );

  const animateHoldsOpenRef = useRef(!query.showHolds);

  const date = useMemo(
    () => query.date ?? formatAsISODate(nowInTimezone(practice.timezoneId)),
    [query.date, practice.timezoneId]
  );

  const switchAppointment = useCallback(
    (appointmentParams: { appointmentId: number | undefined; patientId: number | undefined }) => {
      if (
        query.appointmentId === appointmentParams.appointmentId &&
        query.patientId === appointmentParams.patientId
      ) {
        // if the appointmentId is already there no need to change it
        return;
      }

      updateQuery("replaceIn", appointmentParams);
    },
    [query.appointmentId, query.patientId, updateQuery]
  );

  const navigateToAppointment = useCallback(
    (appointmentParams: { appointmentId: number; patientId: number; date: string }) => {
      updateQuery("replaceIn", appointmentParams);
    },
    [updateQuery]
  );

  const navigateToHold = useCallback(
    (appointmentParams: { appointmentId: number; patientId: number }) => {
      updateQuery("replaceIn", { ...appointmentParams, showHolds: true });
    },
    [updateQuery]
  );

  const navigateToDate = (toDate: Date) => {
    const formattedDate = formatAsISODate(toDate);

    updateQuery("replaceIn", { date: formattedDate });
  };

  const selectGrouping = (groupBy: AppointmentGrouping) => {
    // make the localStorage the new source of truth and clear
    // the query if any
    updateQuery("replaceIn", { groupAppointmentsBy: undefined });
    setSchedulingConfig({ groupAppointmentsBy: groupBy });
  };

  const handleFiltersChange = useCallback(
    (updates: Partial<SchedulingConfig>) => {
      setSchedulingConfig(updates);
    },
    [setSchedulingConfig]
  );

  const toggleHolds = useCallback(() => {
    animateHoldsOpenRef.current = true;
    updateQuery("replaceIn", { showHolds: !query.showHolds });
  }, [updateQuery, query.showHolds]);

  return {
    date,
    appointmentId: query.appointmentId,
    groupAppointmentsBy: scheduleConfig.groupAppointmentsBy,
    hipaaView: scheduleConfig.hipaa,
    hideCanceled: scheduleConfig.hideCanceled,
    showHolds: query.showHolds,
    animateHoldsOpen: animateHoldsOpenRef.current,
    selectGrouping,
    switchAppointment,
    navigateToAppointment,
    navigateToDate,
    toggleHolds,
    handleFiltersChange,
    navigateToHold,
    hiddenRoomIds: new Set(scheduleConfig.hiddenRoomIds),
    orderRoomsBy: scheduleConfig.orderRoomsBy,
    hiddenProviderIds: new Set(scheduleConfig.hiddenProviderIds),
  };
};
