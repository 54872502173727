import { FC } from "react";
import { useBoolean } from "@libs/hooks/useBoolean";
import { UploadPhotoModal } from "components/UI/UploadPhotoModal";
import { Avatar } from "components/UI/Avatar";

interface Props {
  userId: number;
  name: string | undefined;
  imageUrl?: string;
  isHipaaView?: boolean;
}

export const ProfilePhoto: FC<Props> = ({ userId, name, imageUrl, isHipaaView = false }) => {
  const photoUploadModal = useBoolean(false);

  return (
    <>
      <button aria-label="Profile Photo" onClick={photoUploadModal.on} type="button">
        <Avatar imageUrl={imageUrl} isHipaaView={isHipaaView} name={name} size="xl" />
      </button>

      {photoUploadModal.isOn ? (
        <UploadPhotoModal userId={userId} userImageUrl={imageUrl} onRequestClose={photoUploadModal.off} />
      ) : null}
    </>
  );
};
