import { FC } from "react";
import { AddressVO } from "@libs/api/generated-api";
import { cx } from "@libs/utils/cx";
import { formatAddress } from "@libs/utils/address";

interface Props {
  className?: string;
  address?: AddressVO;
  label: string;
}

export const AddressInputReadOnly: FC<Props> = ({ className, address, label }) => {
  const formatted = formatAddress(address);

  return (
    <div className={cx("text-xs text-greyDark", className)}>
      <div>{label}</div>
      <div className="mt-1 font-sansSemiBold">{formatted.lineOne}</div>
      <div className="mt-1 font-sansSemiBold">{formatted.lineTwo}</div>
    </div>
  );
};
