import React, { useCallback, useEffect } from "react";
import { ClaimInsuranceCarrierVO, ClaimSummaryVO, ClaimsConfigVO, ProviderVO } from "@libs/api/generated-api";
import { useBoolean } from "@libs/hooks/useBoolean";
import { getInfiniteQueryPagingDetails } from "@libs/utils/queries";
import { ApiQueryResult, UseInfiniteApiListQueryResult } from "@libs/@types/apiQueries";
import { ButtonIcon } from "@libs/components/UI/ButtonIcon";
import { Icon } from "@libs/components/UI/Icon";
import { ReactComponent as SearchIcon } from "@libs/assets/icons/search.svg";
import { ReactComponent as ProcessEobIcon } from "@libs/assets/icons/process-eob.svg";
import { ReactComponent as PlusIcon } from "@libs/assets/icons/plus-circle.svg";
import { ReactComponent as SubmitIcon } from "@libs/assets/icons/send-file.svg";
import { Button } from "@libs/components/UI/Button";
import { FormFieldInput } from "@libs/components/UI/FormFieldInput";
import { useStorageContext } from "@libs/contexts/StorageContext";
import { ActionsRow, InputsHeaderRow } from "@libs/components/UI/GridTableComponents";
import { LoadingContent } from "@libs/components/UI/LoadingContent";
import { QueryFilterPills } from "@libs/components/UI/QueryFilterPills";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { VerticalDivider } from "@libs/components/UI/VerticalDivider";
import { ItemsCount } from "@libs/components/UI/ItemsCount";
import { ConfirmationModal } from "@libs/components/UI/ConfirmationModal";
import { ClaimsQuery, ClaimsQueryUpdates } from "utils/routing/claims";
import { ClaimFilterProps } from "components/Claims/Claims/claimsQuery";
import { useCreateDraftEobPayment } from "components/Claims/Shared/useCreateDraftEobPayment";
import { FilterStatusButtons } from "components/Claims/Claims/FilterButtons";
import { FilterMenus } from "components/Claims/Claims/FilterMenus";
import { DisableClaimBulkActions } from "components/Claims/Claims/useClaimsTableCheckboxes";
import { useSubmitClaim } from "components/Claims/Shared/useSubmitClaim";
import { CreatePreAuthModal } from "components/PatientProfile/Claims/CreatePreAuthModal";

import { useItemModal } from "hooks/useItemModal";
import { CreateClaimFlyover } from "components/Claims/Claims/CreateClaimFlyover";
import { AppointmentSummary, CreateClaimContext } from "components/Claims/Claims/createClaimTypes";
import { handleError } from "utils/handleError";

interface Props {
  billingProvidersQuery: ApiQueryResult<ProviderVO[]>;
  claimsConfigQuery: ApiQueryResult<ClaimsConfigVO>;
  claims: ClaimSummaryVO[];
  claimsQuery: UseInfiniteApiListQueryResult<ClaimSummaryVO>;
  disableBulkActions: DisableClaimBulkActions;
  filterProps: ClaimFilterProps;
  insuranceCarriersQuery: ApiQueryResult<ClaimInsuranceCarrierVO[]>;
  isClaimsDomain: boolean;
  isPreAuth: boolean;
  onDeselectAll: () => void;
  onFiltersUpdate: (keyValues: ClaimsQueryUpdates) => void;
  onSearch: (value: string) => void;
  patientId?: number;
  practiceProvidersQuery: ApiQueryResult<ProviderVO[]>;
  queryStateApi: ClaimsQuery;
  searchString: string;
  selectedCarrierIds: Set<number>;
  selectedClaimUuids: Set<string>;
}

export const filtersCxStyles = {
  button: "w-60",
  menuContent: "px-4 max-h-96 overflow-y-auto",
  menuWrapper: "mt-0.5 py-4 px-0",
};

// eslint-disable-next-line complexity
export const ClaimFilters: React.FC<Props> = ({
  billingProvidersQuery,
  claimsConfigQuery,
  claims,
  claimsQuery,
  disableBulkActions,
  filterProps,
  insuranceCarriersQuery,
  isClaimsDomain,
  isPreAuth,
  onDeselectAll,
  onFiltersUpdate,
  onSearch,
  patientId,
  practiceProvidersQuery,
  queryStateApi,
  searchString,
  selectedCarrierIds,
  selectedClaimUuids,
}) => {
  const isEmptyList = Boolean(claimsQuery.data && !claims.length);
  const confirmBulkModal = useBoolean(false);
  const createPreAuthModal = useBoolean(false);
  const { sessionStorage } = useStorageContext();

  const createClaimFlyover = useItemModal<{
    context: CreateClaimContext;
    selectedAppt?: AppointmentSummary;
  }>(null);

  const { bulkSubmit } = useSubmitClaim();
  const handleBulkSubmit = useCallback(async () => {
    try {
      await bulkSubmit({ uuids: [...selectedClaimUuids] });
      onDeselectAll();
    } catch (err) {
      handleError(err);
    }

    confirmBulkModal.off();
  }, [bulkSubmit, confirmBulkModal, onDeselectAll, selectedClaimUuids]);

  const { handleCreateDraftEobPayment } = useCreateDraftEobPayment();
  const totalElements = getInfiniteQueryPagingDetails(claimsQuery.data)?.totalElements || 0;

  const preAuthText = isPreAuth ? "pre-auth " : "";

  const handleNavigateCreateClaimFlyover = useCallback(
    ({ context, selectedAppt }: { context: CreateClaimContext; selectedAppt?: AppointmentSummary }) =>
      createClaimFlyover.open({ context, selectedAppt }),
    [createClaimFlyover]
  );

  useEffect(() => {
    if (sessionStorage.getItem("openCreateClaim")) {
      sessionStorage.removeItem("openCreateClaim");
      handleNavigateCreateClaimFlyover({ context: CreateClaimContext.SELECT });
    }
  }, [handleNavigateCreateClaimFlyover, sessionStorage]);

  return (
    <QueryResult
      loading={
        <div className="h-[34px]">
          <LoadingContent />
        </div>
      }
      queries={[billingProvidersQuery, insuranceCarriersQuery, practiceProvidersQuery]}
    >
      <InputsHeaderRow className="flex items-center justify-between z-[28]">
        <div className="flex items-center gap-x-8">
          <FilterMenus
            billingProvidersQuery={billingProvidersQuery}
            insuranceCarriersQuery={insuranceCarriersQuery}
            isClaimsDomain={isClaimsDomain}
            onFiltersUpdate={onFiltersUpdate}
            queryStateApi={queryStateApi}
            practiceProvidersQuery={practiceProvidersQuery}
            showStatusMenu={!isPreAuth}
          />
          <FilterStatusButtons
            onFiltersUpdate={onFiltersUpdate}
            selectedStatuses={filterProps.params.statuses}
            isPreAuth={isPreAuth}
          />
        </div>
        <div>
          {isClaimsDomain ? (
            <FormFieldInput
              className="w-48"
              Icon={SearchIcon}
              onChange={(e) => onSearch(e.target.value)}
              placeholder="Search Patient"
              value={searchString}
            />
          ) : isPreAuth ? (
            <Button
              className="flex items-center gap-x-2 text-sm hover:opacity-70"
              onClick={createPreAuthModal.on}
              size="custom"
              theme="link"
            >
              <Icon size="lg" SvgIcon={PlusIcon} theme="primary" />
              Pre-Auth
            </Button>
          ) : patientId ? (
            <Button
              className="flex items-center gap-x-2 text-sm hover:opacity-70"
              onClick={() => handleNavigateCreateClaimFlyover({ context: CreateClaimContext.SELECT })}
              size="custom"
              theme="link"
            >
              <Icon size="lg" SvgIcon={PlusIcon} theme="primary" />
              Claim
            </Button>
          ) : null}
        </div>
      </InputsHeaderRow>
      <ActionsRow contentClassName="flex items-center gap-x-4">
        {!isEmptyList && (
          <div className="flex items-center gap-x-4">
            <ButtonIcon
              SvgIcon={SubmitIcon}
              disabled={disableBulkActions.submit}
              tooltip={
                selectedClaimUuids.size === 0
                  ? { content: `Select ${preAuthText}draft claim(s) to bulk send` }
                  : disableBulkActions.submit
                    ? {
                        content: `Can only bulk send ${preAuthText}draft claims`,
                      }
                    : { content: "Bulk send", theme: "SMALL" }
              }
              size="sm"
              theme="primary"
              onClick={confirmBulkModal.on}
            />
            {!isPreAuth && (
              <ButtonIcon
                SvgIcon={ProcessEobIcon}
                disabled={disableBulkActions.processEob}
                tooltip={
                  selectedClaimUuids.size === 0
                    ? { content: "Select sent claim(s) to bulk process EOB" }
                    : disableBulkActions.processEob
                      ? {
                          content: "Can only bulk process EOB for sent claims for one insurance carrier",
                        }
                      : { content: "Bulk Process EOB", theme: "SMALL" }
                }
                size="sm"
                theme="primary"
                onClick={() =>
                  handleCreateDraftEobPayment([...selectedCarrierIds][0], [...selectedClaimUuids])
                }
              />
            )}
          </div>
        )}
        {selectedClaimUuids.size ? (
          <ItemsCount selectedCount={selectedClaimUuids.size} total={totalElements} />
        ) : null}
        {filterProps.filters.length > 0 && <VerticalDivider size="sm" />}
        {filterProps.filters.length ? (
          <QueryFilterPills
            {...filterProps}
            numResults={totalElements}
            onClearAll={onFiltersUpdate}
            onUpdateParams={onFiltersUpdate}
          />
        ) : null}
      </ActionsRow>
      {confirmBulkModal.isOn && (
        <ConfirmationModal
          onCancel={confirmBulkModal.off}
          onConfirm={handleBulkSubmit}
          primaryText="Are you sure you want to send these claims?"
          secondaryText="These claims will be sent to the payer."
          size="fit"
        />
      )}
      {createPreAuthModal.isOn && patientId && (
        <CreatePreAuthModal onClose={createPreAuthModal.off} patientId={patientId} />
      )}
      {createClaimFlyover.isOpen && patientId && (
        <CreateClaimFlyover
          billingProvidersQuery={billingProvidersQuery}
          claimsConfigQuery={claimsConfigQuery}
          context={createClaimFlyover.item.context}
          onClose={createClaimFlyover.close}
          onNavigateCreateClaimFlyover={handleNavigateCreateClaimFlyover}
          patientId={patientId}
          selectedAppointment={createClaimFlyover.item.selectedAppt}
        />
      )}
    </QueryResult>
  );
};
