import { cx } from "@libs/utils/cx";
import { useEnsureId } from "@libs/hooks/useEnsureId";
import { Icon } from "@libs/components/UI/Icon";

type Props = {
  defaultValue: number;
  value: number;
  min: number;
  max: number;
  tooltip?: string;
  step: number;
  id?: string;
  disabled?: boolean;
  SvgIcon?: IconComponent;
  onChange: React.ChangeEventHandler<HTMLInputElement>;
};

export const RangeAdjuster: React.FC<Props> = ({
  id,
  defaultValue,
  value,
  tooltip,
  max,
  min,
  step,
  SvgIcon,
  onChange,
  disabled = false,
}) => {
  const fieldId = useEnsureId({ customId: id });

  return (
    <div className="w-24 flex justify-center items-center gap-2">
      {SvgIcon && (
        <Icon disabled={disabled} tooltip={{ content: tooltip, theme: "SMALL" }} SvgIcon={SvgIcon} />
      )}
      <input
        type="range"
        defaultValue={defaultValue}
        value={value}
        min={min}
        max={max}
        disabled={disabled}
        id={fieldId}
        className={cx(
          `w-full
           h-1
           rounded-lg
           range-sm
           appearance-none
           cursor-pointer
           focus:outline-none
           focus:ring-0
           focus:shadow-none
           [&::-webkit-slider-runnable-track]:rounded-full
           [&::-webkit-slider-runnable-track]:bg-slate-800/25
           [&::-webkit-slider-thumb]:appearance-none
           [&::-webkit-slider-thumb]:h-3
           [&::-webkit-slider-thumb]:w-3
           [&::-webkit-slider-thumb]:rounded-full`,
          disabled
            ? "bg-slate-900 [&::-webkit-slider-thumb]:bg-slate-700 accent-slate-700 pointer-events-none"
            : "bg-slate-500 [&::-webkit-slider-thumb]:bg-white accent-white"
        )}
        step={step}
        onChange={onChange}
      />
    </div>
  );
};
