import {
  ComponentPropsWithRef,
  ComponentPropsWithoutRef,
  FC,
  Fragment,
  PropsWithChildren,
  ReactNode,
  forwardRef,
  useEffect,
  useState,
} from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { InvoiceEntryVO, InvoiceVO } from "@libs/api/generated-api";
import { formatCurrency } from "@libs/utils/currency";
import { useInfiniteApiQuery } from "@libs/hooks/useInfiniteApiQuery";
import { PAGE_SIZE } from "@libs/utils/constants";
import { flattenPages } from "@libs/utils/queries";
import { useInfiniteScrollQuery } from "@libs/hooks/useInfiniteScrollQuery";
import { Button } from "@libs/components/UI/Button";
import { CheckboxList } from "@libs/components/UI/CheckboxList";
import { FormFieldMenu } from "@libs/components/UI/FormFieldMenu";
import { LoadingOverlaySpinner } from "@libs/components/UI/LoadingOverlaySpinner";
import { useAccount } from "@libs/contexts/AccountContext";
import { LayoutCard } from "@libs/components/UI/LayoutCard";

import { QueryResult } from "@libs/components/UI/QueryResult";
import { PersistScrollPosition } from "@libs/components/UI/PersistScrollPosition";
import { usePathParams } from "hooks/usePathParams";
import {
  Col,
  InvoiceEntryDescription,
  PaymentDescription,
  PracticeAndPatientIds,
  Row,
} from "components/PatientProfile/Billing/Ledger/LedgerComponents";
import { ToggleCaret } from "components/UI/ToggleCaret";
import { DateWithTooltip } from "components/UI/DateWithTooltip";
import { getInvoicesInfiniteQuery } from "api/billing/queries";
import {
  invoiceEntryOrLineItemToId,
  getInvoiceAmountDue,
} from "components/PatientProfile/Billing/invoiceUtils";
import { paths } from "utils/routing/paths";
import { useQueryParams } from "hooks/useQueryParams";
import { isFamilyPayment, isPaymentStateFailed } from "components/PatientProfile/Billing/billingUtils";
import { useExpandedLedgerItemsStorage } from "storage/billing";
import {
  canCollectInvoice,
  canRefundInvoice,
  canVoidInvoice,
} from "components/PatientProfile/Billing/Ledger/ledgerUtils";
import { PatientBillingAppHistoryProvider } from "components/PatientProfile/Billing/PatientBillingLinksContex";
import { usePersistExpandRow } from "./usePersistExpandRow";
import { DetailsTable } from "./DetailsTable";
import { InvoiceDescription } from "./InvoiceDescription";
import { InvoiceStatePill } from "./InvoiceStatePill";
import { InvoiceMenu } from "./InvoiceMenu";
import { PaymentMenu } from "./PaymentMenu";
import { LoadingMoreIndicator } from "./LoadingMoreIndicator";
import { Text } from "./Text";
import { LedgerTabsRow } from "./LedgerTabsRow";

export const InvoicesPage: FC = () => {
  const { practiceId } = useAccount();
  const { patientId } = usePathParams("patientInvoices");
  const {
    query: { invoiceStates: selectedInvoiceStates },
  } = useQueryParams("patientInvoices");
  const navigate = useNavigate();
  const invoicesQuery = useInfiniteApiQuery(
    getInvoicesInfiniteQuery({
      args: {
        patientId,
        practiceId,
        pageNumber: 1,
        pageSize: PAGE_SIZE,
        includePayments: true,
        states: selectedInvoiceStates,
        includeOrders: true,
      },
    })
  );

  const { scrollRef, rootRef } = useInfiniteScrollQuery({
    infiniteQuery: invoicesQuery,
  });
  const invoiceItems = flattenPages(invoicesQuery.data);

  const { clearLedgerExpandables } = useExpandedLedgerItemsStorage();

  useEffect(() => {
    if (invoicesQuery.isInitialLoading) {
      clearLedgerExpandables();
    }
  }, [clearLedgerExpandables, invoicesQuery.isInitialLoading]);

  return (
    <PatientBillingAppHistoryProvider name="patientInvoices">
      <LayoutCard className="relative flex flex-col w-full divide-y">
        <LedgerTabsRow patientId={patientId} practiceId={practiceId} selected="Invoices" />
        <Row padding="filterRow" full={false}>
          <PaymentsStatusFilterMenu
            selectedInvoiceStates={selectedInvoiceStates}
            onApply={(states) => {
              navigate(paths.patientInvoices({ patientId }, { invoiceStates: [...states] }));
            }}
          />
        </Row>
        <Row padding="billingHeader" full={false}>
          <PaymentsHeaders />
        </Row>
        <QueryResult queries={[invoicesQuery]} loading={<LoadingOverlaySpinner />}>
          <InvoicesContainer ref={rootRef}>
            {invoiceItems?.map((invoice, i) => (
              <Fragment key={invoice.uuid}>
                <InvoiceItem patientId={patientId} practiceId={practiceId} invoice={invoice} />
                {i === invoiceItems.length - 1 && invoicesQuery.hasNextPage && (
                  <LoadingMoreIndicator ref={scrollRef}>
                    <div />
                    <LoadingSkeleton />
                  </LoadingMoreIndicator>
                )}
              </Fragment>
            ))}
          </InvoicesContainer>
        </QueryResult>
      </LayoutCard>
    </PatientBillingAppHistoryProvider>
  );
};

const InvoicesContainer: FC<PropsWithChildren<ComponentPropsWithRef<"div">>> = forwardRef(
  ({ children }, ref) => (
    <PersistScrollPosition
      id="ledger-invoices-page"
      ref={ref}
      className="overflow-y-auto flex flex-col-reverse"
    >
      {children}
    </PersistScrollPosition>
  )
);

// eslint-disable-next-line complexity
const InvoiceItem: FC<PracticeAndPatientIds & { invoice: InvoiceVO } & ComponentPropsWithRef<"div">> = ({
  practiceId,
  patientId,
  invoice,
}) => {
  return (
    <InvoiceItemContainer>
      <ExpandableRow
        itemId={`InvoicesPage-${invoice.uuid}`}
        date={
          <Text bold>
            <DateWithTooltip date={invoice.createdAt} dateAsSeconds format="P" />
          </Text>
        }
        details={<InvoiceDescription invoiceNumber={invoice.invoiceNumber} />}
        status={<InvoiceStatePill invoiceAmount={invoice.amount} invoiceState={invoice.state} />}
        amount={<Text bold>{formatCurrency(getInvoiceAmountDue(invoice))}</Text>}
        menu={
          <InvoiceMenu
            practiceId={practiceId}
            patientId={patientId}
            invoiceUuid={invoice.uuid}
            canCollect={canCollectInvoice(invoice)}
            canRefund={canRefundInvoice(invoice)}
            canVoid={canVoidInvoice(invoice)}
          />
        }
        expandedContent={
          <InvoiceExpandedContent
            patientId={patientId}
            practiceId={practiceId}
            invoice={invoice}
            showPaymentMenu
          />
        }
      />
    </InvoiceItemContainer>
  );
};

export const InvoiceItemContainer: FC<PropsWithChildren> = ({ children }) => (
  <div className="flex flex-col p-2 border-b">{children}</div>
);

const ExpandableRow: FC<{
  itemId: string;
  padding?: ComponentPropsWithoutRef<typeof Row>["padding"];
  date: ReactNode;
  details: ReactNode;
  status: ReactNode;
  amount: ReactNode;
  menu: ReactNode;
  expandedContent: ReactNode;
}> = ({ itemId, date, details, status, amount, menu, expandedContent }) => {
  const expandRow = usePersistExpandRow(itemId);

  return (
    <>
      <Row>
        <Columns
          icon={
            <ToggleCaret
              initialState={expandRow.isOn ? "opened" : "closed"}
              size="lg"
              onToggle={expandRow.toggle}
            />
          }
          date={date}
          details={details}
          status={status}
          amountDue={amount}
          menu={menu}
        />
      </Row>
      {expandRow.isOn && expandedContent}
    </>
  );
};

const Columns: FC<{
  icon: ReactNode;
  date: ReactNode;
  details: ReactNode;
  status: ReactNode;
  amountDue: ReactNode;
  menu: ReactNode;
}> = ({ icon, date, details, status, amountDue, menu }) => {
  return (
    <>
      <Col justify="center" width="xxs">
        {icon}
      </Col>
      <div className="w-4" />
      <Col justify="left" width="lg" align="center">
        {date}
      </Col>
      <Col justify="left" width="full">
        {details}
      </Col>
      <Col justify="right" width="lg">
        {status}
      </Col>
      <Col justify="right" width="xl" align="center">
        {amountDue}
      </Col>
      <div className="w-3" />
      <Col justify="center" width="xxs" align="center">
        {menu}
      </Col>
    </>
  );
};

const TableRow: FC<{
  date: ReactNode;
  details: ReactNode;
  amount: ReactNode;
  menu: ReactNode;
}> = ({ date, details, amount, menu }) => {
  return (
    <Row padding="tableRow">
      <Col justify="left" width="lg">
        {date}
      </Col>
      <Col justify="left" width="full">
        {details}
      </Col>
      <Col justify="right" width="lg">
        {amount}
      </Col>
      <Col justify="right" width="xs">
        {menu}
      </Col>
    </Row>
  );
};

const PaymentsHeaders: FC = () => {
  return (
    <Columns
      icon={undefined}
      date={<Text bold>Date</Text>}
      details={<Text bold>Details</Text>}
      status={undefined}
      amountDue={<Text bold>Amount Due</Text>}
      menu={undefined}
    />
  );
};

type StatusFilters = InvoiceVO["state"];

const PaymentsStatusFilterMenu: FC<{
  selectedInvoiceStates: StatusFilters[] | undefined;
  onApply: (invoiceStates: Set<StatusFilters>) => void;
}> = ({ selectedInvoiceStates, onApply }) => {
  const [selected, setSelected] = useState(() => new Set<StatusFilters>(selectedInvoiceStates));
  const [draftSelection, setDraftSelection] = useState(() => new Set<StatusFilters>(selectedInvoiceStates));

  return (
    <FormFieldMenu
      buttonClassName="w-60"
      buttonContent={draftSelection.size > 0 ? `Status (${draftSelection.size} selected)` : "Status"}
      onRequestOpen={() => {
        setDraftSelection(selected);
      }}
      matchTriggerWidth
      menuContent={(menu) => (
        <div className="p-4 flex flex-col gap-y-6">
          <CheckboxList
            layout="vert"
            onChange={(updatedSet) => setDraftSelection(updatedSet)}
            selectedValues={draftSelection}
            options={[
              { label: "Paid", value: "PAID" },
              { label: "Unpaid", value: "FINALIZED" },
              { label: "Partially Paid", value: "PARTIALLY_PAID" },
              { label: "Void", value: "VOID" },
            ]}
          />
          <Button
            onClick={() => {
              setSelected(draftSelection);
              onApply(draftSelection);
              menu.off();
            }}
            className="min-w-button self-center"
          >
            Apply Filters
          </Button>
        </div>
      )}
      onRequestClose={() => {
        setDraftSelection(selected);
      }}
    />
  );
};

const InvoiceEntry: FC<{ entry: InvoiceEntryVO }> = ({ entry }) => {
  return (
    <TableRow
      date={
        <Text>
          <DateWithTooltip date={entry.createdAt} dateAsSeconds format="P" />
        </Text>
      }
      details={
        <Text>
          <InvoiceEntryDescription entry={entry} />
        </Text>
      }
      amount={<Text>{formatCurrency(entry.amount)}</Text>}
      menu={undefined}
    />
  );
};

const LoadingSkeleton: FC = () => {
  return (
    <InvoiceItemContainer>
      <Row full>
        <Columns
          icon={<Skeleton containerClassName="w-full" />}
          date={<Skeleton containerClassName="w-full pr-2" />}
          details={<Skeleton containerClassName="w-full pr-2" />}
          status={<Skeleton containerClassName="w-full pr-2" />}
          amountDue={<Skeleton containerClassName="w-full" />}
          menu={<Skeleton containerClassName="w-full" />}
        />
      </Row>
    </InvoiceItemContainer>
  );
};

const InvoiceExpandedContent: FC<
  PracticeAndPatientIds & { invoice: InvoiceVO; showPaymentMenu: boolean }
> = ({ invoice, patientId, showPaymentMenu }) => {
  return (
    <Row>
      <Columns
        icon={undefined}
        date={undefined}
        details={
          <DetailsTable>
            {invoice.payments.length ? (
              <>
                <TableRow
                  date={<Text bold>{invoice.amount > 0 ? "Payments" : "Refunds"}</Text>}
                  details={undefined}
                  amount={<Text bold>Amount</Text>}
                  menu={undefined}
                />
                {invoice.payments.map((payment) => {
                  const paymentFailed = isPaymentStateFailed(payment.state);

                  return (
                    <TableRow
                      key={payment.uuid}
                      date={
                        <Text>
                          <DateWithTooltip date={payment.paymentCreatedAt} dateAsSeconds format="P" />
                        </Text>
                      }
                      details={<PaymentDescription payment={payment} />}
                      amount={
                        <Text color={paymentFailed ? "red" : "green"} bold>
                          {formatCurrency(-payment.currencyAmount.amount)}
                        </Text>
                      }
                      menu={
                        showPaymentMenu &&
                        !paymentFailed && (
                          <PaymentMenu
                            patientId={patientId}
                            paymentUuid={payment.batchPaymentUuid ?? payment.uuid}
                            isFamilyPayment={isFamilyPayment(payment)}
                            canEditPayment={payment.permittedActions.includes("EDIT")}
                            canDeletePayment={payment.permittedActions.includes("DELETE")}
                          />
                        )
                      }
                    />
                  );
                })}
              </>
            ) : (
              <TableRow
                date={
                  <Text>
                    <i>No Payments</i>
                  </Text>
                }
                details={undefined}
                amount={undefined}
                menu={undefined}
              />
            )}
            <TableRow
              date={<Text bold>Items</Text>}
              details={undefined}
              amount={<Text bold>Amount</Text>}
              menu={undefined}
            />
            {invoice.entries.map((entry) => (
              <InvoiceEntry key={invoiceEntryOrLineItemToId(entry)} entry={entry} />
            ))}
            {invoice.entries.length > 1 && (
              <TableRow
                date={undefined}
                details={
                  <Col justify="right" align="center" width="full">
                    <Text bold>Total</Text>
                  </Col>
                }
                amount={<Text bold>{formatCurrency(invoice.amount)}</Text>}
                menu={undefined}
              />
            )}
          </DetailsTable>
        }
        status={undefined}
        amountDue={undefined}
        menu={undefined}
      />
    </Row>
  );
};
