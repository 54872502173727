import { FC, useMemo } from "react";

import { EmployeeConsolidationVO } from "@libs/api/generated-api";
import { useApiQueries } from "@libs/hooks/useApiQueries";
import { ApiQueryResult } from "@libs/@types/apiQueries";
import { Button } from "@libs/components/UI/Button";
import { useAccount } from "@libs/contexts/AccountContext";
import { QueryResult } from "@libs/components/UI/QueryResult";
import { ModalFooter, ModalContent } from "@libs/components/UI/ModalComponents";

import { Modal } from "@libs/components/UI/Modal";
import { getEmployeeConsolidation } from "api/payroll/queries";

import { EmployeeConsolidationModalProps, EmployeeConsolidationModal } from "./EmployeeConsolidationModal";
import { sortMergeEmployees } from "./utils";

export const EmployeeConsolidationModalContainer: FC<
  Omit<EmployeeConsolidationModalProps, "employeeConsolidation">
> = ({ isWaitingForMigrationEvent, onBeginConsolidation, onRequestClose }) => {
  const { practiceId } = useAccount();
  const [employeeConsolidationQuery] = useApiQueries([getEmployeeConsolidation({ args: { practiceId } })]);
  const employeeConsolidation = useMemo(
    () =>
      employeeConsolidationQuery.data
        ? {
            active: sortMergeEmployees(employeeConsolidationQuery.data.active),
            archived: sortMergeEmployees(employeeConsolidationQuery.data.archived),
          }
        : undefined,
    [employeeConsolidationQuery.data]
  );

  return employeeConsolidation ? (
    <EmployeeConsolidationModal
      employeeConsolidation={employeeConsolidation}
      isWaitingForMigrationEvent={isWaitingForMigrationEvent}
      onBeginConsolidation={onBeginConsolidation}
      onRequestClose={onRequestClose}
    />
  ) : (
    <EmployeeConsolidationQueryModal
      employeeConsolidationQuery={employeeConsolidationQuery}
      onRequestClose={onRequestClose}
    />
  );
};

const EmployeeConsolidationQueryModal: FC<{
  employeeConsolidationQuery: ApiQueryResult<EmployeeConsolidationVO>;
  onRequestClose: Func;
}> = ({ employeeConsolidationQuery, onRequestClose }) => (
  <Modal onClose={onRequestClose} title="Consolidate Employee List" height={900}>
    <ModalContent padding="lg">
      <QueryResult queries={[employeeConsolidationQuery]} />
    </ModalContent>

    <ModalFooter>
      <Button className="min-w-button" onClick={onRequestClose} theme="secondary">
        Cancel
      </Button>
      <Button className="min-w-button" disabled>
        Next
      </Button>
    </ModalFooter>
  </Modal>
);
