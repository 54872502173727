import React from "react";
import { BarStack } from "@visx/shape";
import { ScaleBand, ScaleLinear, ScaleOrdinal } from "d3-scale";

import { UseTooltipParams } from "@visx/tooltip/lib/hooks/useTooltip";

import { config, useSpring } from "@react-spring/web";
import designConfig from "@libs/design.config";
import { cx } from "@libs/utils/cx";
import { half } from "@libs/utils/math";
import { formatCurrency } from "@libs/utils/currency";
import { formatNumber } from "@libs/utils/formatString";
import { SelectedBar } from "components/Dashboard/Charting/SelectedBar";
import { VisxChartBar } from "components/Dashboard/Charting/VisxChartBar";
import { BarStackData } from "components/Dashboard/Charting/types";
import { ClickableAxisLabelHiddenBar } from "components/Dashboard/Charting/ClickableAxisLabelHiddenBar";
import { MAX_BAR_LABELS_X_ABBREVIATE, MAX_BAR_LABEL_COUNT } from "components/Dashboard/Charting/theme";

const TOOLTIP_TIMEOUT = 300;

interface Props<T extends string> {
  yScale: ScaleLinear<number, number, never>;
  xScale: ScaleBand<string>;
  yMax: number;
  data: BarStackData<T>[];
  tooltipData?: BarStackData<T>;
  onHideTooltip: Func;
  keys: T[];
  selectedBarIndex?: number;
  dottedBarIndex?: number;
  marginBottom: number;
  barColorScale: ScaleOrdinal<T, string, never>;
  onShowTooltip: UseTooltipParams<BarStackData<T>>["showTooltip"];
  onClickBar: (params: { date: string; index: number }) => void;
  isCurrency?: boolean;
  includeBarTitle?: boolean;
  formatValue?: (val: number) => string;
}

export const VisxBarStacks = <T extends string>({
  data,
  keys,
  xScale,
  yScale,
  barColorScale,
  onHideTooltip,
  marginBottom,
  onShowTooltip,
  tooltipData,
  selectedBarIndex,
  onClickBar,
  isCurrency = true,
  includeBarTitle = true,
  formatValue,
  dottedBarIndex,
  yMax,
}: Props<T>) => {
  const tooltipTimeout = React.useRef<NodeJS.Timeout | null>(null);

  const { scale } = useSpring({
    from: { scale: 0 },
    to: { scale: 1 },
    config: config.default,
  });

  React.useEffect(() => {
    scale.reset();
  }, [data, scale]);

  return (
    <BarStack<BarStackData<T>, T>
      data={data}
      keys={keys}
      x={(d) => d.date}
      xScale={xScale}
      yScale={yScale}
      offset="diverging"
      color={barColorScale}
    >
      {(barStacks) => {
        const formatItem = formatValue ?? ((val: number) => `${val}`);

        return barStacks.map((barStack) =>
          // eslint-disable-next-line complexity
          barStack.bars.map((bar) => {
            const maxY = Math.min(...barStacks.map((item) => item.bars[bar.index].y));
            const backdropHeight = barStacks.reduce((prev, curr) => prev + curr.bars[bar.index].height, 0);

            const { date, ...rest } = bar.bar.data;
            const isHovering = date === tooltipData?.date;
            const className = cx("cursor-pointer", isHovering && "opacity-80");
            const isTopStack = barStack.index === 0 && maxY < yMax;
            const sum = Object.values(rest as Record<string, number>).reduce((prev, curr) => prev + curr, 0);
            const isSelectedBar = selectedBarIndex === bar.index;
            const handleClick = () => onClickBar({ date, index: bar.index });

            let sumLabel =
              isTopStack && includeBarTitle && sum !== 0 && data.length < MAX_BAR_LABEL_COUNT
                ? isCurrency && !formatValue
                  ? formatCurrency(sum)
                  : formatItem(sum)
                : undefined;

            // If data length is great, we abbreviate bar titles to not collide
            if (sumLabel && data.length > MAX_BAR_LABELS_X_ABBREVIATE) {
              sumLabel = formatValue ? formatValue(sum) : formatNumber(sum, { isCurrency });
            }

            return (
              <React.Fragment key={`bar-stack-${barStack.index}-${bar.index}`}>
                {isSelectedBar && (
                  <SelectedBar x={bar.x} barWidth={bar.width} marginBottom={marginBottom} yMax={yMax} />
                )}
                {dottedBarIndex === bar.index && (
                  <circle
                    cx={bar.x + half(bar.width)}
                    cy={yMax + half(marginBottom)}
                    r="2"
                    fill={designConfig.colors.primaryTheme}
                  />
                )}

                <ClickableAxisLabelHiddenBar
                  width={bar.width}
                  x={bar.x}
                  yMax={yMax}
                  onClick={handleClick}
                  index={bar.index}
                />

                <VisxChartBar
                  scale={scale}
                  onClick={handleClick}
                  barX={bar.x}
                  barY={bar.y}
                  maxY={maxY}
                  testId={`bar-${date}`}
                  isTopStack={isTopStack}
                  backdropHeight={backdropHeight}
                  label={sumLabel}
                  barHeight={bar.height}
                  barWidth={bar.width}
                  color={bar.color}
                  borderTop={barStack.index < barStacks.length - 1}
                  className={className}
                  onHideTooltip={() => {
                    tooltipTimeout.current = setTimeout(() => {
                      onHideTooltip();
                    }, TOOLTIP_TIMEOUT);
                  }}
                  onShowTooltip={({ tooltipTop, tooltipLeft }) => {
                    if (tooltipTimeout.current) {
                      clearTimeout(tooltipTimeout.current);
                    }

                    onShowTooltip({
                      tooltipData: bar.bar.data,
                      tooltipTop,
                      tooltipLeft,
                    });
                  }}
                />
              </React.Fragment>
            );
          })
        );
      }}
    </BarStack>
  );
};
